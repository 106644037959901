import React from 'react';
import { FaQuestionCircle } from 'react-icons/fa';

import { Text, Tooltip } from 'v3/components';
import theme from 'v3/theme';

export default function EditableContent({
  label,
  display,
  isEditing,
  EditingComponent,
  required,
  tooltip,
}) {
  if (isEditing) {
    return (
      <EditingComponent
        label={required ? `${label} *` : label}
        tooltip={tooltip}
      />
    );
  }

  return (
    <>
      <Text type="label" color="dark">
        {label}
        {!!tooltip && (
          <Tooltip
            content={
              <Text type="label" color="white">
                {tooltip}
              </Text>
            }
          >
            <FaQuestionCircle
              className="ml-1 mb-1"
              style={{ color: theme.colors.light_blue }}
            />
          </Tooltip>
        )}
      </Text>
      <Text type="regular" color="gray" as="p" className="mt-2">
        {display || 'Não informado'}
      </Text>
    </>
  );
}
