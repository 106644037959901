/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import moment from 'moment';
import { Row, Col } from 'react-bootstrap';
import { FaRegCalendar } from 'react-icons/fa';

import Datepicker from 'v3/components/DatePicker';
import Text from 'v3/components/Text';
import Card from 'v3/components/Card';
import Select from 'v3/components/Select';
import InputAddress from 'v3/components/InputAddress';
import Input from 'v3/components/Input';

import { useSnackbar } from 'v3/components/Snackbar';
import { useEffectThrottled, useModal } from 'hooks';
import api from 'services/api';
import { LoadContext, fetchSelectOptions } from '../controller';
import { UpdateClientAddressData } from 'v3/pages/Client/shared/Modals/UpdateAddressData';
import { checkClientAddressData } from 'v3/pages/PreLoad/functions';

const [, useLoad] = LoadContext;

export default function Locale() {
  const load = useLoad();
  const snackbar = useSnackbar();
  const updateClientAddressModal = useModal();
  const [clientCurrentAddress, setClientCurrentAddress] = useState(null);
  const [client, setClient] = useState(null);

  const [disableNumber, setDisableNumber] = useState(false);
  const [disableZipCode, setDisableZipCode] = useState(false);
  const [disableCity, setDisableCity] = useState(false);
  const [disableProvince, setDisableProvince] = useState(false);
  const [disableNeighborhood, setDisableNeighborhood] = useState(false);

  useEffectThrottled(
    () => {
      const origin = moment(load.data?.origin?.date, 'DD/MM/YYYY HH:mm');

      const isBeforeThanNow = origin.isBefore(moment());
      if (isBeforeThanNow) {
        snackbar.show(
          <Text>
            Esta carga não aparecerá no aplicativo pois a hora da coleta já
            passou.
          </Text>,
          {
            type: 'error',
          }
        );
      }
    },
    [load.data?.origin?.date],
    2000
  );

  function handleUpdateAddress(data) {
    load.setData({
      ...load.data,
      origin: {
        ...load.data.origin,
        client: client,
        number: data?.number || '',
        complement: data?.complement || '',
        zip_code: data?.zip_code || '',
        address: data?.address || '',
        neighborhood: data?.neighborhood || '',
        lat: data?.lat || '',
        lng: data?.lng || '',
        city: data?.city_name || '',
        cityModel: data?.city_name
          ? {
              id: data?.city_id,
              name: data?.city_name,
              province: {
                uf: data?.uf_name,
              },
            }
          : undefined,
        province: data?.uf_name,
        formatted_address: data?.formatted_address,
      },
    });
  }

  function handleOriginClientSelection(event) {
    disableAddress({}, null);
    if (event !== null) {
      const currentAddress = event?.addresses.filter(
        address => address.current
      )[0];
      if (checkClientAddressData(currentAddress)) {
        snackbar.show(<Text>Endereço do cliente desatualizado! Complete o cadastro</Text>,{type:'error'})
        setClientCurrentAddress(currentAddress);
        setClient(event);
        return updateClientAddressModal.open();
      } else {
        load.setData({
          ...load.data,
          origin: {
            ...load.data.origin,
            client: event,
            number: currentAddress?.number || '',
            complement: currentAddress?.complement || '',
            zip_code: currentAddress?.zip_code || '',
            address: currentAddress?.address || '',
            neighborhood: currentAddress?.neighborhood || '',
            lat: currentAddress?.lat || '',
            lng: currentAddress?.lng || '',
            city: currentAddress?.city_name || '',
            cityModel: currentAddress?.city_name
              ? {
                  id: currentAddress?.city_id,
                  name: currentAddress?.city_name,
                  province: {
                    uf: currentAddress?.uf_name,
                  },
                }
              : undefined,
            province: currentAddress?.uf_name,
          },
        });
      }
    } else {
      load.setData({
        ...load.data,
        origin: {
          ...load.data.origin,
          client: null,
          number: '',
          complement: '',
          zip_code: '',
          address: '',
          neighborhood: '',
          lat: '',
          lng: '',
          city: '',
          cityModel: null,
          province: '',
        },
      });
    }
  }

  function disableAddress(address, city) {
    setDisableNumber(!!address.number);
    setDisableZipCode(!!address.zip_code);
    setDisableCity(!!city);
    setDisableProvince(!!city?.province);
    setDisableNeighborhood(!!address.neighborhood);
  }

  async function fetchProvinces(search, uf, country) {
    try {
      const response = await api.get('cities', {
        params: { search, uf, country },
      });
      return response.data;
    } catch (ex) {
      return [];
    }
  }

  async function setOriginCity(city, options) {
    let cityModel = city;
    if (options.action === 'create-option') {
      cityModel = {
        name: city.id,
        province: '',
      };
    }

    load.setData({
      ...load.data,
      origin: {
        ...load.data.origin,
        cityModel,
        province: cityModel?.province?.uf || '',
      },
    });
  }

  return (
    <>
      <Card
        className="full-height-card"
        header={
          <Text color="#464E5F" type="header">
            Origem
          </Text>
        }
      >
        <Row>
          <UpdateClientAddressData
            show={updateClientAddressModal.isOpen}
            onSubmit={handleUpdateAddress}
            onClose={() => updateClientAddressModal.close()}
            currentAddressData={clientCurrentAddress}
            countryOptions={load?.countryList}
          />
          <Col lg={12} md={12} xs={12}>
            <Row className="form">
              <Col md={12}>
                <Select
                  label="País *"
                  options={load.countryList}
                  value={load.data.origin?.country}
                  onChange={event => {
                    load.setData({
                      ...load.data,
                      origin: {
                        ...load.data.origin,
                        country: event,
                        country_slug: event?.abbreviation,
                      },
                    });
                  }}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  error={load.errors[`origin.country`]}
                  id="select-pais-origem"
                />
              </Col>
              <Col md={12} xs={12} className="mt-2">
                <Select.Async
                  label="Cliente"
                  onSearch={search =>
                    fetchSelectOptions('persons/customers', { search })
                  }
                  value={load.data.origin?.client}
                  getOptionLabel={option => {
                    let label = `${option?.social_name} - ${option?.cgccpf}`;

                    if (option.city) {
                      label += ` - ${option?.city}`;
                    }

                    return label;
                  }}
                  getOptionValue={option => option?.id}
                  onChange={event => {
                    handleOriginClientSelection(event);
                  }}
                  id="select-cliente-origem"
                />
              </Col>
              <Col md={8}>
                <InputAddress
                  label="Logradouro *"
                  placeholder=""
                  value={load.data.origin?.address}
                  onSelectedAddress={async place => {
                    const [city] = await fetchProvinces(
                      place.city,
                      place.province,
                      place.country_abbreviation
                    );

                    disableAddress(place, city);
                    load.setData({
                      ...load.data,
                      origin: {
                        ...load.data.origin,
                        address: place.address ? place.address : '',
                        number: place?.number ? place.number : '',
                        zip_code: place.zip_code ? place.zip_code : '',
                        cityModel: city,
                        complement: place.name ? place.name : '',
                        neighborhood: place.neighborhood
                          ? place.neighborhood
                          : '',
                        province: city?.province?.uf || '',
                        lat: place.lat,
                        lng: place.lng,
                        formatted_address: place.formatted_address,
                      },
                    });
                  }}
                  onChange={event =>
                    load.setData({
                      ...load.data,
                      origin: {
                        ...load.data.origin,
                        address: event.target.value,
                      },
                    })
                  }
                  types={[]}
                  error={load.errors[`origin.address`]}
                  country={
                    load.data.origin?.country
                      ? load.data.origin?.country?.abbreviation
                      : null
                  }
                  id="select-logradouro-origem"
                />
              </Col>
              <Col md={4}>
                <Input
                  label="Número"
                  disabled={disableNumber}
                  value={load.data.origin?.number}
                  onChange={event =>
                    load.setData({
                      ...load.data,
                      origin: {
                        ...load.data.origin,
                        number: event.target.value,
                      },
                    })
                  }
                  id="input-numero-origem"
                />
              </Col>

              <Col md={4}>
                <Input
                  label="Complemento"
                  value={load.data.origin?.complement}
                  onChange={event =>
                    load.setData({
                      ...load.data,
                      origin: {
                        ...load.data.origin,
                        complement: event.target.value,
                      },
                    })
                  }
                  id="input-complemento-origem"
                />
              </Col>
              <Col md={4}>
                <Input
                  label="Bairro"
                  disabled={disableNeighborhood}
                  value={load.data.origin?.neighborhood}
                  onChange={event =>
                    load.setData({
                      ...load.data,
                      origin: {
                        ...load.data.origin,
                        neighborhood: event.target.value,
                      },
                    })
                  }
                  id="input-bairro-origem"
                />
              </Col>
              <Col md={4}>
                <Input
                  label="CEP"
                  disabled={disableZipCode}
                  value={load.data.origin?.zip_code}
                  onChange={event =>
                    load.setData({
                      ...load.data,
                      origin: {
                        ...load.data.origin,
                        zip_code: event.target.value,
                      },
                    })
                  }
                  error={load.errors[`origin.zip_code`]}
                  id="input-cep-origem"
                />
              </Col>
              <Col xs={8} className="my-3">
                <Select.Async
                  label="Cidade *"
                  onSearch={city =>
                    fetchProvinces(city, '', load.data.origin?.country_slug)
                  }
                  value={load.data.origin?.cityModel}
                  creatable
                  horizontal
                  onChange={(value, options) => setOriginCity(value, options)}
                  getOptionLabel={option =>
                    `${`${option.name} ${
                      option.province?.uf ? `- ${option.province?.uf}` : ''
                    }`}`
                  }
                  getOptionValue={option =>
                    `${`${option.name} ${
                      option.province?.uf ? `- ${option.province?.uf}` : ''
                    }`}`
                  }
                  error={load.errors[`origin.cityModel`]}
                  isDisabled={disableCity}
                  id="select-cidade-origem"
                />
              </Col>
              <Col md={4} className="my-3">
                <Input
                  label="UF *"
                  disabled={disableProvince}
                  value={load.data.origin?.province}
                  onChange={event =>
                    load.setData({
                      ...load.data,
                      origin: {
                        ...load.data.origin,
                        province: event.target.value,
                      },
                    })
                  }
                  error={load.errors[`origin.province`]}
                  id="input-uf-origem"
                />
              </Col>
              <Col md={6}>
                <Input
                  label="Latitude"
                  disabled
                  value={load.data.origin?.lat}
                  error={load.errors[`origin.lat`]}
                  tooltip="Selecione um endereço sugerido para obter dados de latitude e longitude"
                  id="input-latitude-origem"
                />
              </Col>
              <Col md={6}>
                <Input
                  label="Longitude"
                  disabled
                  value={load.data.origin?.lng}
                  error={load.errors[`origin.lng`]}
                  tooltip="Selecione um endereço sugerido para obter dados de latitude e longitude"
                  id="input-longitude-origem"
                />
              </Col>
              <Col md={6}>
                <Datepicker
                  guide
                  value={load.data.origin?.start_schedule}
                  label="Data Início da coleta *"
                  onChange={event =>
                    load.setData({
                      ...load.data,
                      origin: {
                        ...load.data.origin,
                        start_schedule: event.target.value,
                      },
                    })
                  }
                  withTime
                  error={load.errors[`origin.start_schedule`]}
                  id="input-data-inicio-origem"
                />
              </Col>
              <Col md={6}>
                <Input
                  placeholder="Digite uma data"
                  icon={<FaRegCalendar color="#494950" />}
                  label="Data máxima da coleta *"
                  value={load.data.origin?.date}
                  onChange={event =>
                    load.setData({
                      ...load.data,
                      origin: {
                        ...load.data.origin,
                        date: event.target.value,
                      },
                    })
                  }
                  masked
                  mask={[
                    /[0-9]/,
                    /[0-9]/,
                    '/',
                    /[0-9]/,
                    /[0-9]/,
                    '/',
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    ' ',
                    /[0-9]/,
                    /[0-9]/,
                    ':',
                    /[0-9]/,
                    /[0-9]/,
                  ]}
                  error={load.errors[`origin.date`]}
                  id="input-data-maxima-origem"
                />
              </Col>
              <div className="mx-auto mt-2">
                <Text type="label" color="red">
                  {load.errors.origin}
                </Text>
              </div>
            </Row>
          </Col>
        </Row>
      </Card>
    </>
  );
}
