import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import { cooperplaceApi } from 'services/api';
import { useSnackbar } from 'v3/components/Snackbar';
import Text from 'v3/components/Text';

import { useClient } from '../shared/context';
import SharedData from '../shared/Section';
import Inputs from '../shared/Tracking/index';
import Editable from '../shared/Editable';

export default function Tracking() {
  const client = useClient();
  const snackbar = useSnackbar();
  const [isEditing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  async function saveData() {
    setLoading(true);
    if (
      client.data.client.sending_tracking &&
      client.trackingEmailsList.length === 0
    ) {
      snackbar.show(<Text>Informe pelo menos 1 email</Text>, {
        type: 'error',
      });
      setLoading(false);
      return;
    }

    try {
      const response = await cooperplaceApi.put(
        `clients/tracking/${client.data.id}/emails`,
        {
          sending_tracking: client.data.client.sending_tracking,
          emails_tracking: client.data.client?.emails_tracking,
        }
      );
      if (response.data.success) {
        client.setTrackingEmailsList([]);
        client.setPrevClient(client.data);
        snackbar.show(<Text>Salvo com sucesso</Text>, { type: 'success' });
        setEditing(false);
      } else {
        client.setTrackingEmailsList([]);
        client.setData(client.prevClient);
        snackbar.show(
          <Text>{`Erro ao salvar. ${response.data.message}`}</Text>,
          {
            type: 'warning',
          }
        );
        setEditing(false);
      }
    } catch (error) {
      snackbar.show(<Text>Erro ao salvar</Text>, { type: 'error' });
    } finally {
      setLoading(false);
    }
  }

  const trackingMapValue =
    client.data?.client?.sending_tracking === false
      ? 'Não'
      : client.data?.client?.sending_tracking === true
      ? 'Sim'
      : 'Não informado';

  return (
    <SharedData
      Title="Envio de Tracking"
      HeaderRightComponent={
        client?.canEditData && (
          <Editable
            isEditing={isEditing}
            setEditing={setEditing}
            onSave={saveData}
            isLoading={loading}
          />
        )
      }
      loading={client.isFetching}
    >
      {isEditing ? (
        <Inputs />
      ) : (
        <Row>
          <Col xs={12} md={6}>
            <Text type="label" color="dark">
              Recebe Tracking
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {trackingMapValue}
            </Text>
          </Col>
          <Col xs={12} md={12}>
            <Text type="label" color="dark">
              E-mails cadastrados
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {(client?.data?.client?.emails_tracking &&
                (client?.data?.client?.emails_tracking).split(',').map(item => {
                  return (
                    <Text type="regular" color="gray">
                      {item}
                      <br />
                    </Text>
                  );
                })) ||
                'Não informado'}
            </Text>
          </Col>
        </Row>
      )}
    </SharedData>
  );
}
