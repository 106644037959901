import React, { useState, useCallback, useEffect } from 'react';
import moment from 'moment';
import dashboardApi from 'services/apis/dashboard';
import { formatCurrency } from 'v3/utils/formatter';
import { useQuery } from 'hooks';
import api from 'services/api';
import {
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  ResponsiveContainer,
} from 'recharts';
import Card from './Card';
import { DateOptions } from './Card/utils';

const months = [
  'Jan.',
  'Fev.',
  'Mar.',
  'Abr.',
  'Mai.',
  'Jun.',
  'Jul.',
  'Ago.',
  'Set.',
  'Out.',
  'Nov.',
  'Dez.',
];

export default function CargonRevenue({ dashboard, name, id }) {
  const [isFetching, setFetching] = useState(false);
  const [data, setData] = useState([]);

  const queryParams = useQuery();

  function setQueryParams(filters) {
    const { tags = [] } = filters;
    queryParams.delete('tags');

    if (tags) {
      tags.forEach(tag => {
        queryParams.append('tags', tag.id);
      });
    }

    if (tags?.length === 0) {
      queryParams.set('no-tags', true);
    } else {
      queryParams.delete('no-tags');
    }
  }

  async function handleChangeDate(value, setSelectedDate, setValues, values) {
    setSelectedDate(value);
    setValues(old => ({ ...old, date: value }));

    const payload = {
      tags: values?.tags,
      clients: values?.clients,
      negotiators: values?.negotiators,
      pcps: values?.pcps,
      companies: values?.companies,
      costCenters: values?.costCenters,
      sizes: values?.size,
      component: name,
      date: value,
      card_id: id,
    };

    await api.put(`v3/dashboard/${dashboard.id}/parameters`, payload);
  }

  const fetchCargonRevenue = useCallback(async (filters = {}) => {
    try {
      setFetching(true);

      const {
        date,
        tags = [],
        clients = [],
        negotiators = [],
        costCenters = [],
        pcps = [],
        companies = {},
      } = filters;
      setQueryParams(filters);

      const response = await dashboardApi.get('/cargon-revenue', {
        params: {
          tags: tags?.map(tag => tag.id),
          clients: clients?.map(client => client.id),
          negotiators: negotiators?.map(negotiator => negotiator?.id),
          costCenters: costCenters?.map(costCenter => costCenter?.id),
          pcps: pcps?.map(pcp => pcp?.id),
          companies: companies?.id ? [companies?.id] : null,
          start_date: date.value.start_date,
          end_date: date.value.end_date,
        },
      });

      const formattedResponse = response.data?.map((item, index) => ({
        month: months[index],
        sum: item.sum,
      }));

      setData(formattedResponse);
    } catch (ex) {
      // Handle exception
    } finally {
      setFetching(false);
    }
  }, []);

  useEffect(() => {
    const startDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
    queryParams.set('startDate', startDate);
  }, []);

  const numberFormatter = value => {
    return formatCurrency(value);
  };

  return (
    <Card
      title="Faturamento total Cooperplace"
      isLoading={isFetching}
      onFiltersChanged={fetchCargonRevenue}
      dashboard={dashboard}
      name={name}
      dateFilter
      dates={DateOptions.yearly}
      handleChangeDate={handleChangeDate}
      dateFilterOptionLabel={option => option.label}
      dateFilterOptionValue={option => option.value}
      id={id}
      excludeFilterByDate
      filterByCompanyMultiple={false}
      excludeFilterByBranchOffice={true}
      NumericComponent={
        <div className="d-flex justify-content-center text-center flex-column">
          <ResponsiveContainer width="100%" height={380}>
            <BarChart
              data={data}
              margin={{ top: 20, right: 20, left: 65, bottom: 0 }}
            >
              <XAxis dataKey="month" />
              <YAxis tickFormatter={numberFormatter} />
              <Tooltip formatter={numberFormatter} />
              <Bar dataKey="sum" fill="#0BB7AF" name="Total" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      }
    />
  );
}
