/* eslint-disable import/no-unresolved */
import api from 'services/api';
import dealAPI from 'services/apis/deal/index';
import locationAPI from 'services/apis/location';

import { getDistanceInKm } from 'utils';
import { formatDistance, formatDate } from 'utils/formatter';

async function createDeal(negotiator, driver, loadId) {
  const res = await api.get(`negotiators/${loadId}`);
  const negotiators_id = res.data;
  const data = {
    negotiators_ids: negotiators_id,
    driver_id: driver,
    load_id: loadId,
  };

  try {
    const response = await dealAPI.post('deals', data);

    return response.data.filter(
      item =>
        JSON.parse(localStorage.getItem('usuario')).id === item.negotiator_id
    )[0].id;
  } catch (error) {
    // Handle exception
    return false;
  }
}

export async function goToNegotiation(driver, loadId, history) {
  const user = localStorage.getItem('usuario');
  const userId = JSON.parse(user).id;
  const response = await dealAPI.get(
    `deals?negotiator_id=${userId}&driver_id=${driver}&load_id=${loadId}`
  );

  const { data } = response;
  if (data.data.length === 0) {
    const dealId = await createDeal(userId, driver, loadId);
    history.push({
      pathname: `/negociacoes`,
      search: `?deal_id=${dealId}`,
    });
  } else {
    history.push({
      pathname: `/negociacoes`,
      search: `?deal_id=${data.data[0].id}`,
    });
  }
}

async function fetchUserLocation(cpf) {
  try {
    const response = await locationAPI.get(`last/cpf/${cpf}`);

    return response.data.location;
  } catch (ex) {
    return null;
  }
}

export async function fetchVisualizations(loadId) {
  const response = await api.get(`load/visualization/${loadId}`);

  const mapped = await Promise.all(
    response.data.map(
      async ({ id, driver, load, quantity, interested, created_at }) => {
        const vehiclePivot = driver.person && driver.person.vehiclePivot;

        let plate = '-';
        let vehicleType = '-';

        if (vehiclePivot) {
          plate = vehiclePivot.vehicle.plate;
          vehicleType = vehiclePivot.vehicle.vehicleType.name;
        }

        let formattedDistance = '-';
        if (load.origin.lat && load.origin.lng) {
          const location = await fetchUserLocation(driver.cpf);
          let distance = -1;
          if (location) {
            distance = getDistanceInKm(
              {
                latitude: location.latitude,
                longitude: location.longitude,
              },
              { latitude: load.origin.lat, longitude: load.origin.lng }
            );
          }
          formattedDistance = formatDistance(distance);
        }

        const visualized_at = formatDate(created_at);

        return {
          name: driver.username,
          phone: driver.phonenumber,
          verified:
            driver.person &&
            driver.person.driver &&
            driver.person.driver.verified_person,
          rating: driver.person && driver.person.rating,
          distance: formattedDistance,
          id,
          plate,
          vehicleType,
          visualized_at,
          quantity,
          interested,
          driverId: driver.id,
          loadId: load.id,
        };
      }
    )
  );
  return mapped;
}
