import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import { Modal, Text, Button } from 'v3/components';
import TextArea from 'v3/components/TextArea';

export function ReproveRequestModal({
  isOpen,
  onClose,
  requestId,
  reproveRequest,
  loading,
}) {
  const [observation, setObservation] = useState('');

  function handleReproveRequest() {
    reproveRequest(observation, requestId);
    setObservation('');
  }

  return (
    <Modal
      show={isOpen}
      handleClose={onClose}
      heading={
        <Text type="subtitle" color="dark" weight={400}>
          Reprovar solicitação de alteração de cadastro
        </Text>
      }
      body={
        <>
          <Row>
            <Col xs={12} md={12}>
              <TextArea
                label="Informar motivo *"
                value={observation}
                onChange={e => setObservation(e.target?.value)}
                resize={false}
                style={{ height: '10rem' }}
              />
            </Col>
          </Row>

          <Row className="d-flex justify-content-start mt-2">
            <Col xs={12}>
              <Button
                variant="primary"
                onClick={handleReproveRequest}
                loading={loading}
                disabled={!observation}
                title={!observation ? 'Informe um motivo' : ''}
              >
                <Text weight={500}>Enviar</Text>
              </Button>
            </Col>
          </Row>
        </>
      }
    />
  );
}
