import React from 'react';

import Space from 'v3/components/Space';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import { useUser } from './context';

export default function Editable({
  isEditing,
  setEditing,
  onSave,
  isLoading,
  disabled = false,
}) {
  const user = useUser();

  function handleCancel() {
    setEditing(false);
    user.handleCancel();
  }

  if (isEditing) {
    return (
      <Space x="5px">
        <Button variant="secondary" onClick={handleCancel}>
          <Text weight={500} type="regular">
            Cancelar
          </Text>
        </Button>
        <Button variant="success" onClick={onSave} loading={isLoading}>
          <Text weight={500} type="regular">
            Salvar
          </Text>
        </Button>
      </Space>
    );
  }

  return (
    <Button
      variant="secondary"
      onClick={() => setEditing(true)}
      disabled={disabled}
    >
      <Text weight={500} type="regular">
        Editar
      </Text>
    </Button>
  );
}
