import React from 'react';

import { Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import Modal from 'v3/components/Modal';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';

import { useVehicle } from '../../context';

function Created({ isOpen, onClose }) {
  const vehicle = useVehicle();
  const history = useHistory();

  return (
    <>
      <Modal
        show={isOpen}
        onHide={onClose}
        heading={
          <Text type="header" color="dark" weight="500">
            Cadastro de veículo
          </Text>
        }
        body={
          <Row className="mx-0 mt-1">
            <Text weight="400" color="dark">
              Veículo {vehicle.data.plate} cadastrado com sucesso!
            </Text>
          </Row>
        }
        footer={
          <Row md={12} xs={12} className="mr-3 mt-1">
            <Button
              variant="white"
              className="mr-2 mt-1 py-2"
              outlined
              size="sm"
              onClick={() => history.push('/veiculos')}
            >
              <Text weight="500" type="regular">
                Ver listagem
              </Text>
            </Button>

            <Button
              className="mr-2 mt-1 py-2"
              variant="primary"
              size="sm"
              onClick={() => history.push(`/veiculos/${vehicle?.data?.id}`)}
            >
              <Text weight="500" type="regular">
                Ver veículo
              </Text>
            </Button>

            <Button
              variant="success"
              className="mr-2 mt-1 py-2"
              size="sm"
              onClick={() => {
                window.location.reload(false);
              }}
            >
              <Text weight="500" type="regular">
                Novo veículo
              </Text>
            </Button>
          </Row>
        }
      />
    </>
  );
}

export default Created;
