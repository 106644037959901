import React, { useState, useEffect } from 'react';
import moment from 'moment';

import api from 'services/api';
import apiReport from 'services/apis/report';
import { Row, Col } from 'react-bootstrap';
import ApplicationLayout from 'v3/layouts/Application';
import Select from 'v3/components/Select';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import { DatePicker, InfiniteScroll } from 'v3/components';
import { useSnackbar } from 'v3/components/Snackbar';
import { formatDate } from 'v3/utils/formatter';
import { usePermission } from 'hooks';

import FileSaver from 'file-saver';
import Card from './Card';
import AvgCard from './AvgCard';

export default function TimeIndicatos() {
  const snackbar = useSnackbar();

  usePermission('VISUALIZAR_RELATORIO_INDICADORES_TEMPOS_OPERACIONAIS', {
    redirect: true,
  });

  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [travels, setTravels] = useState([]);
  const [avgs, setAvgs] = useState(null);
  const [tags, setTags] = useState([]);
  const [lastPage, setLastPage] = useState(1);

  const [pagination, setPagination] = useState({
    lastPage: 1,
    currentPage: 1,
    total: 0,
  });
  const [dates, setDates] = useState({
    from: formatDate(moment(new Date()).subtract(7, 'day')),
    to: formatDate(new Date()),
  });

  function renderItem(travel) {
    return <Card travel={travel} />;
  }
  async function fetchSelectTags(params) {
    try {
      // params: { search, not_paginated: true },
      const { data } = await api.get('tags', { params });

      return data;
    } catch (err) {
      return [];
    }
  }

  function dateFormatter(date, end) {
    const formattedDate = moment(date.split('/').reverse().join('-')).add(
      3,
      'h'
    );
    if (end) {
      formattedDate.add(1, 'd');
    }
    return formattedDate.format('YYYY-MM-DD hh:mm:ss');
  }

  async function fetchItens(page) {
    
    if (page === 1) {
      setLoading(true);
    }
    try {
      const start_date = dateFormatter(dates.from);
      const end_date = dateFormatter(dates.to, true);

      const response = await apiReport.get(`time-indicators`, {
        params: {
          page,
          start_date,
          end_date,
          tags: tags.map(tag => tag.id),
        },
      });

      const { listItens, avg } = response.data;
      const { data, ...rest } = listItens;

      if (avg[0]) {
        setAvgs(avg[0]);
      }

      setTravels(oldTravels => {
        if (page === 1) {
          return data;
        }

        return [...oldTravels, ...data];
      });

      if (page === 1) {
        setLastPage(rest?.pagination.lastPage);
      }

      setPagination(rest?.pagination);
    } catch (error) {
      snackbar.show(
        error.response?.data?.error ||
          'Algo deu errado, tente novamente mais tarde',
        { type: 'error' }
      );
    } finally {
      setLoading(false);
    }
  }

  function setDate(key, value) {
    setDates(old => {
      return {
        ...old,
        [key]: value,
      };
    });
  }

  async function exportReport() {
    setExportLoading(true);
    try {
      const start_date = dateFormatter(dates.from);
      const end_date = dateFormatter(dates.to, true);

      const response = await apiReport.get(`time-indicators`, {
        params: {
          start_date,
          end_date,
          export: 'true',
          tags: tags.map(tag => tag.id),
        },
        responseType: 'blob',
      });

      FileSaver.saveAs(response.data, response.headers['x-file-name']);
    } catch (error) {
      snackbar.show('Erro ao buscar relatórios', { type: 'error' });
    } finally {
      setExportLoading(false);
    }
  }

  useEffect(() => {
    function onPressedKey(e) {
      if (e.key === 'Enter') {
        fetchItens(pagination.currentPage);
      }
    }

    window.addEventListener('keyup', onPressedKey);

    return () => {
      window.removeEventListener('keyup', onPressedKey);
    };
  });

  return (
    <ApplicationLayout title="Relatório de indicadores de tempos operacionais">
      <Row className="filter d-flex justify-content-start space-between">
        <Col md={2} xs={12}>
          <DatePicker
            guide
            label="Período de *"
            value={dates.from}
            onChange={({ target }) => setDate('from', target.value)}
            labelColor="#fff"
          />
        </Col>
        <Col md={2} xs={12}>
          <DatePicker
            guide
            label="Até *"
            value={dates.to}
            onChange={({ target }) => setDate('to', target.value)}
            labelColor="#fff"
          />
        </Col>
        <Col md={3} xs={12}>
          <Select.Async
            label="Tags *"
            modalHeading="Selecione uma tag"
            modalBodyTitle="Tags:"
            labelColor="#fff"
            horizontal
            value={tags}
            onSearch={search =>
              fetchSelectTags({ search, not_paginated: true })
            }
            onChange={value => (value ? setTags(value) : setTags([]))}
            multiple
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
          />
        </Col>

        <Col lg={2} xs={12} className="vertical bottom">
          <Button
            variant="success"
            loading={loading}
            onClick={() => fetchItens(pagination.currentPage)}
            className="w-100"
          >
            <Text type="regular" weight={500}>
              Filtrar
            </Text>
          </Button>
        </Col>
        <Col lg={2} xs={12} className="vertical bottom">
          <Button
            variant="primary"
            loading={exportLoading}
            onClick={exportReport}
            className="w-100"
          >
            <Text type="regular" weight={500}>
              Exportar
            </Text>
          </Button>
        </Col>
        {avgs && (
          <Row className="avgs d-flex justify-content-center space-between">
            <Col lg={3} xs={12} className="mt-3">
              <AvgCard
                title="Média de tempo de contratação"
                time={avgs.avg_hiring}
              />
            </Col>
            <Col lg={3} xs={12} className="mt-3">
              <AvgCard
                title="Média de tempo de Gerenciadora de Risco"
                time={avgs.avg_risk_manager}
              />
            </Col>
            <Col lg={3} xs={12} className="mt-3">
              <AvgCard
                title="Média de tempo de Cadastro"
                time={avgs.avg_register}
              />
            </Col>
            <Col lg={3} xs={12} className="mt-3">
              <AvgCard
                title="Média de tempo de emissão"
                time={avgs.avg_issuing}
              />
            </Col>
          </Row>
        )}

        <Col md={12} className="mt-3">
          <InfiniteScroll
            data={travels}
            onEndReach={() => {
              fetchItens(pagination.currentPage + 1);
            }}
            hasMore={pagination.currentPage < lastPage}
            scrollThreshold={0.7}
            renderItem={renderItem}
            loading={loading}
          />
        </Col>
      </Row>
    </ApplicationLayout>
  );
}
