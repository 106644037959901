import React, { useEffect } from 'react';
import { Button, Input, Modal, Text } from 'v3/components';

export function ForgotPasswordModal({
  isOpen,
  onClose,
  onSubmit,
  email,
  setEmail,
  loadingRecoverPassword,
}) {
  useEffect(() => {
    if (isOpen) {
      const userStored = JSON.parse(localStorage.getItem('usuario'));
      setEmail(userStored?.email);
    }
  }, [isOpen]);
  return (
    <Modal
      show={isOpen}
      handleClose={onClose}
      heading={
        <Text type="regular" color="dark" weight="500">
          {'Esqueci a senha'}
        </Text>
      }
      body={
        <>
          <Input
            label={'Recuperar senha'}
            placeholder="Insira o e-mail de login para recuperar a senha"
            value={email}
            type="email"
            onChange={event => setEmail(event?.target?.value)}
          />
        </>
      }
      footer={
        <Button
          onClick={onSubmit}
          loading={loadingRecoverPassword}
          disabled={loadingRecoverPassword || !email}
        >
          <Text type="regular">Enviar</Text>
        </Button>
      }
    />
  );
}
