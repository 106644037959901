import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
  } from '@material-ui/core';
import { Button } from 'antd';
  import React from 'react';
import { Link } from 'react-router-dom';

  
  export function PolicyInUseDialog({ open, label, loads, handleClose }) {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title" style={{ color: 'black' }}>
          Atenção!
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ color: '#262525' }}>
            <p className='mb-1'>Não é possível remover <b>{label}</b> pois está sendo utilizado nas seguintes cargas:</p>
            {loads?.map((load) => (
                <div key={load.id}>Carga #{load.load_id}: <Link to={`/cargas/${load.load_id}`}>acessar carga</Link></div>
            ))}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }
  