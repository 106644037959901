export const personTypesOptions = [
  { label: 'Pessoa Física', value: 'pf' },
  { label: 'Pessoa Jurídica', value: 'pj' },
  { label: 'Ambos', value: 'bt' },
];

export const activeStatusOptions = [
  { label: 'ATIVO', value: true },
  { label: 'INATIVO', value: false },
];

export const isVehicleTractionOptions = [
  { label: 'SIM', value: true },
  { label: 'NÃO', value: false },
];

export const phoneMask = [
  '(',
  /[0-9]/,
  /\d/,
  ')',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
