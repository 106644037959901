import api from 'services/api';

export function logout() {
  localStorage.removeItem('currentClientId', null);
  localStorage.removeItem('currentClientName', null);
  localStorage.removeItem('token', null);
  localStorage.removeItem('usuario', null);
  localStorage.removeItem('permissions', null);
  localStorage.removeItem('travelsControlTowerUserColumnOrderPreference');
  localStorage.removeItem('userColumnSizingPreference');
  localStorage.removeItem('travelsControlTowerUserColumnSizingPreference');
  localStorage.removeItem('userColumnOrderPreference');
  localStorage.removeItem('travelsControlTowerUserPerPage');

  window.location.href = '/login';
}

export async function versionUpdate(versionTime) {
  try {
    if (versionTime){
      await api.post('user/version/update', {
        version: versionTime,
      });
      const userJson = JSON.parse(localStorage.getItem('usuario'));
      const response = await api.get(`users/${userJson.id}`);
      localStorage.setItem('version',JSON.stringify(response.data.read_version));
    }
  } catch (ex) {
    //ex
  }
}
