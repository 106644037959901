import styled from 'styled-components';
import theme from 'v3/theme';

export const IconButton = styled.button`
  border: 0;
  background: transparent;
  cursor: pointer;

  display: flex;
  align-items: center;
  gap: 0.25rem;

  &:not(:disabled):hover {
    text-decoration: underline;
  }

  & > svg {
    color: ${props => props.iconColor};
  }

  &:disabled {
    cursor: not-allowed;
    & > span,
    svg {
      color: gray !important;
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  flex-wrap: wrap;
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const AttachmentsHeader = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const AddAttachmentHeaderContainer = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const AttachmentItemsContainer = styled.div`
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const AttachmentItemContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 0.5rem;
  grid-row-gap: 1rem;
  & > button {
    cursor: pointer;
    border: 1px solid transparent;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.7s;

    &:focus {
      border: 1px dashed ${theme.colors.danger};
    }

    &:hover {
      background-color: #e1e1e1;
    }

    & > svg {
      color: ${theme.colors.danger};
    }
  }
`;

export const Backdrop = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;

  > * {
    background-color: transparent;
    transition: opacity 0.3s ease-in;

    &:hover {
      opacity: 0.8;
    }
  }
`;
export const DateTime = styled.div`
  background-color: rgba(0, 0, 0, 0.35);
  padding: 5px;
  position: absolute;
  left: 0;
  bottom: 0;
`;

export const AttachmentDetailsContainer = styled.div`
  display: flex;
  padding: 1rem;
  flex-wrap: wrap;
`;

export const AttachmentDetail = styled.div`
  flex: 1;
`;
