import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import api from '../../../../services/api';

import { Row, Col } from 'react-bootstrap';
import { Input, Select, Toggle } from 'v3/components';
import TextArea from 'v3/components/TextArea';

import { useAggregations } from './context';
import { fetchSelectOptions } from './controller';
import {
  personTypesOptions,
  activeStatusOptions,
  isVehicleTractionOptions,
  phoneMask,
} from './utils';

export default function Data() {
  const aggregation = useAggregations();
  const params = useParams();
  const [personType, setPersonType] = useState('');
  const [isActiveStatus, setIsActiveStatus] = useState();
  const [isVehicleTraction, setVehicleTraction] = useState();
  const [vehicleTypes, setVehicleTypes] = useState();

  const [provinces, setProvinces] = useState();

  const [axisList, setAxisList] = useState();

  function setAggregationOfferValues() {
    setPersonType(aggregation.data.person_type);
    setVehicleTraction(aggregation.data.main_unit_vehicle_only);
    setIsActiveStatus(aggregation.data.active);
  }

  async function getVehicleAxis() {
    try {
      const { data } = await api.get('vehicle-axe?app=true');
      setAxisList(
        data.map(axis => ({
          id: axis.id,
          name: axis.name,
        }))
      );
    } catch (ex) {
      setAxisList([{ name: 'Não encontrado.', id: 'null' }]);
    }
  }

  useEffect(() => {
    if (params.id) {
      setAggregationOfferValues();
    }
    getVehicleAxis();
  }, []);

  function convertLookupToSelectArray(data) {
    try {
      const responseArray = [{ name: 'Todos', id: 'null' }];
      for (let i = 0; i < data.length; i += 1) {
        responseArray.push({ name: data[i].name, id: data[i].id });
      }
      return responseArray;
    } catch (ex) {
      return { name: 'Dados inconsistentes.', id: 'null' };
    }
  }

  async function getVehicleType() {
    try {
      const response = await api.get(`vehicle-types?app=${true}`);
      const convertedToSelectArray = convertLookupToSelectArray(response.data);
      setVehicleTypes(convertedToSelectArray);
    } catch (ex) {
      setVehicleTypes([{ name: 'Não encontrado.', id: 'null' }]);
    }
  }

  useEffect(() => {
    getVehicleType();
  }, []);

  async function getProvinces() {
    const response = await api.get('provinces');
    const provinceData = response.data.map(el => ({
      ...el,
      label: el.name,
      value: el.id,
    }));
    setProvinces(provinceData);
  }

  useEffect(() => {
    getProvinces();
  }, []);

  useEffect(() => {
    if (personType) {
      aggregation.setData({ person_type: personType });
    }
  }, [personType]);

  useEffect(() => {
    aggregation.setData({ active: isActiveStatus });
  }, [isActiveStatus]);

  useEffect(() => {
    aggregation.setData({ main_unit_vehicle_only: isVehicleTraction });
  }, [isVehicleTraction]);

  return (
    <Row>
      <Col xs={12} className="mb-3">
        <Toggle
          label="Tipo de cadastro *"
          defaultValue={personType}
          onSelected={setPersonType}
          options={personTypesOptions}
          error={aggregation.errors?.['data.person_type']}
        />
      </Col>
      <Col xs={6} className="mb-3">
        <Input
          label="Contato (email) *"
          type="email"
          value={aggregation.data?.contact_email}
          onChange={event =>
            aggregation.setData({ contact_email: event.target.value })
          }
          error={aggregation.errors?.['data.contact_email']}
        />
      </Col>
      <Col xs={6} className="mb-3">
        <Input
          label="Telefone *"
          value={aggregation.data?.contact_phone}
          onChange={event =>
            aggregation.setData({ contact_phone: event.target.value })
          }
          masked
          mask={phoneMask}
          guide={false}
          error={aggregation.errors?.['data.contact_phone']}
        />
      </Col>
      <Col xs={6} className="mb-3">
        <Select
          label="Estado *"
          options={provinces}
          placeholder="Selecione um estado"
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          value={aggregation.data?.province}
          onChange={value => {
            aggregation.setData({ province: value });
            aggregation.setData({ province_id: value?.id });
          }}
          error={aggregation.errors?.['data.province_id']}
        />
      </Col>
      <Col xs={6} className="mb-3">
        <Select.Async
          label="Cidade *"
          onSearch={search =>
            fetchSelectOptions(
              `cities-by-province/${aggregation.data.province.id}`,
              {
                search,
              }
            )
          }
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          value={aggregation.data?.city}
          onChange={value => {
            aggregation.setData({ city: value });
            aggregation.setData({ city_id: value?.id });
          }}
          error={aggregation.errors?.['data.city_id']}
        />
      </Col>
      <Col xs={6} className="mb-3">
        <Toggle
          label="Status"
          defaultValue={isActiveStatus}
          onSelected={setIsActiveStatus}
          options={activeStatusOptions}
        />
      </Col>
      <Col xs={6} className="mb-3">
        <Toggle
          label="Apenas cavalo"
          defaultValue={isVehicleTraction}
          onSelected={setVehicleTraction}
          options={isVehicleTractionOptions}
        />
      </Col>
      <Col xs={6} className="mb-3">
        <Select
          label="Tipo de Veiculo *"
          options={vehicleTypes}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          value={aggregation.data?.vehicleType}
          placeholder="Selecione"
          onChange={value => {
            aggregation.setData({ vehicleType: value });
            aggregation.setData({ vehicle_type_id: value?.id });
          }}
          error={aggregation.errors?.['data.vehicle_type_id']}
        />
      </Col>
      <Col xs={6} className="mb-3">
        <Select
          label="Tipo de Eixo *"
          options={axisList}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          value={aggregation.data?.veichleAxi}
          placeholder="Selecione"
          onChange={value => {
            aggregation.setData({ veichleAxi: value });
            aggregation.setData({ vehicle_axi_id: value?.id });
          }}
          error={aggregation.errors?.['data.vehicle_axi_id']}
        />
      </Col>
      <Col xs={6} className="mb-3">
        <Input
          type="number"
          label="Ano mínimo do veículo *"
          value={aggregation.data?.minimum_age}
          onChange={event =>
            aggregation.setData({ minimum_age: event.target.value })
          }
          error={aggregation.errors?.['data.minimum_age']}
        />
      </Col>
      <Col xs={6} className="mb-3">
        <Select.Async
          label="Rastreador"
          onSearch={search => fetchSelectOptions('select/trackers', { search })}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          value={aggregation.data?.tracker}
          onChange={value => {
            aggregation.setData({ tracker: value });
            aggregation.setData({ tracker_id: value?.id });
          }}
        />
      </Col>
      <Col xs={6} className="mb-3">
        <Input
          type="number"
          label="Quantidade de Vagas *"
          value={aggregation.data?.quantity}
          onChange={event =>
            aggregation.setData({ quantity: event.target.value })
          }
          min="1"
          error={aggregation.errors?.['data.quantity']}
        />
      </Col>
      <Col xs={12} className="mb-3">
        <TextArea
          label="Observações"
          value={aggregation.data?.observation}
          onChange={event =>
            aggregation.setData({ observation: event.target.value })
          }
        />
      </Col>
    </Row>
  );
}
