import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Col, Row } from 'react-bootstrap';

import api from 'services/api';

import Modal from 'v3/components/Modal';
import Select from 'v3/components/Select';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import { useSnackbar } from 'v3/components/Snackbar';

import { useShippingCompany } from '../../context';

function AttachedDrivers({ isOpen, onClose, fetchShippingCompanyDrivers }) {
  const shippingCompany = useShippingCompany();
  const params = useParams();
  const snackbar = useSnackbar();

  const [errors, setErrors] = useState('');
  const [driver, setDriver] = useState(null);

  useEffect(() => {
    shippingCompany.setData({ shippingDrivers: [] });
  }, []);

  async function fetchDriver(search) {
    try {
      const response = await api.get('drivers/select', {
        params: { search },
      });

      return response.data;
    } catch {
      snackbar.show(<Text>Erro ao procurar motorista!</Text>, {
        type: 'error',
      });
    }
    return false;
  }

  async function attachDriver(event) {
    event.preventDefault();

    if (
      shippingCompany.data?.shippingDrivers
        ?.map(item => item.driver?.id)
        .includes(driver.id)
    ) {
      setErrors('Esse motorista já foi selecionado');
      return;
    }

    try {
      if (params.id) {
        await api.post(`v3/shipping-company/${params.id}/drivers`, { driver });

        fetchShippingCompanyDrivers();
      } else {
        shippingCompany.setData({
          shippingDrivers: [
            ...shippingCompany.data.shippingDrivers,
            { driver },
          ],
        });
      }
    } catch (error) {
      //
    } finally {
      setDriver('');
      onClose(true);
      setErrors();
    }
  }

  useEffect(() => {
    if (!driver) {
      setErrors('');
    }
  }, [driver]);

  return (
    <>
      <Modal
        show={isOpen}
        onHide={onClose}
        heading={
          <Text color="dark" type="header">
            Vincular motorista
          </Text>
        }
        body={
          <form onSubmit={attachDriver}>
            <Row>
              <Col xs={12}>
                <Select.Async
                  label="Motorista"
                  placeholder="Selecione o motorista..."
                  onSearch={fetchDriver}
                  value={driver}
                  onChange={e => setDriver(e)}
                  getOptionLabel={option => option.social_name}
                  getOptionValue={option => option.id}
                  error={errors}
                />
              </Col>
              <Col
                xs={12}
                className="align-items-center d-flex justify-content-center my-4"
              >
                <Button type="submit" disabled={!driver} className="py-2">
                  <Text type="regular" weight="500" color="white">
                    Adicionar
                  </Text>
                </Button>
              </Col>
            </Row>
          </form>
        }
      />
    </>
  );
}

export default AttachedDrivers;
