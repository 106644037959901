import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from 'components/Card';

export default function CardEmpresa({ company }) {
  if (!company.loadData.attendedLoad) return null;
  const phone = company.loadData.attendedLoad.driverData.phone
    ? company.loadData.attendedLoad.driverData.phone
    : '';

  return (
    <Grid item md={12} xs={12} style={{ marginBottom: '5px' }}>
      <Card bordered={1} bordercolor={'primary'}>
        <Grid container>
          <Grid item md={9} style={{ lineHeight: '24px' }}>
            <strong>CLIENTE: </strong>
            {company.loadData.clientData.social_name &&
              company.loadData.clientData.social_name}
            <br />
            <strong>MOTORISTA: </strong>
            {company.loadData.attendedLoad.driverData.social_name &&
              company.loadData.attendedLoad.driverData.social_name}
            <br />

            {phone && (
              <>
                <strong>TELEFONE / WHATSAPP: </strong>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    'https://api.whatsapp.com/send?phone=55' +
                    phone.replace(/[{()}]|\s+|-/g, '')
                  }
                >
                  {phone}
                </a>
              </>
            )}
          </Grid>
          <Grid item md={3} style={{ lineHeight: '24px' }}>
            <strong>CNPJ: </strong> {company.loadData.clientData.cgccpf}
            <br />
            <strong>CT-e: </strong> {company.cte_number}
            <br />
            <strong>Nº da Viagem: </strong> <a target="_blank"href={(process.env.REACT_APP_REDIRECR_URI)+'/viagens/'+company.loadData.attendedLoad.id}>{company.loadData.attendedLoad.id}</a>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
}
