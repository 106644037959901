import React, { useState, useEffect, useContext, useMemo } from 'react';
import api, { cooperplaceApi } from 'services/api';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { FaExternalLinkAlt } from 'react-icons/fa';
import moment from 'moment';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import Card, { DefaultLoadingBodyComponent } from 'v3/components/Card';
import Select from 'v3/components/Select';
import { useSnackbar } from 'v3/components/Snackbar';
import { useTravel } from 'v3/pages/Travel';
import { canEditTravel } from 'v3/utils/permissions';
import { BlockedTravelContext } from 'contexts/BlockedTravelProvider';
import VerifyVehiclesModal from 'v3/components/VerifyVehiclesModal';
import { useModal, usePermission } from 'hooks';
import {
  APPROVED_GRO_ANALYSIS_SLUG,
  trackedVehicleRequiredFiels,
  validAnttRegex,
  vehicleRequiredFields,
  wagonRequiredFields,
} from 'v3/utils/constants';
import { format } from 'date-fns';
import { UpdateVehicleData } from '../../Register/Modals/UpdateVehicle';
import { UpdateWagonData } from '../../Register/Modals/UpdateWagon';
import { MinimumMarginModal } from '..';

function Vehicle({ isLoading }) {
  const {
    isTravelBlocked,
    isFetchingBenner,
    setIsFetchingBenner,
    isFetchingMargin,
  } = useContext(BlockedTravelContext);
  const snackbar = useSnackbar();
  const travel = useTravel();
  const [plates, setPlates] = useState({
    plate1: {},
    plate2: {},
    plate3: {},
    plate4: {},
  });
  const [hasPermissionToEdit, setPermissionToEdit] = useState();
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState('');
  const [vehicle, setVehicle] = useState(null);
  const [wagon, setWagon] = useState(null);
  const updateVehicleData = useModal();
  const updateWagonData = useModal();
  const hasPermissionToEditVehicle = usePermission('ALTERAR_VEICULOS');
  const hasPermissionToEditWagon = usePermission('EDITAR_IMPLEMENTO');
  const lowerMarginModal = useModal();

  const maskedOptionsPlate = {
    plate1: 'main',
    plate2: 'implement',
    plate3: 'implement2',
    plate4: 'implement3',
  };

  const formatedVehiclesPlates = useMemo(() => {
    const platesFormated = {};
    Object.entries(plates).forEach(([key, value]) => {
      if (value) {
        platesFormated[maskedOptionsPlate[key]] = value;
      }
    });
    return platesFormated;
  }, [plates]);

  useEffect(() => {
    setPermissionToEdit(canEditTravel(travel.data));
  }, [travel.data]);

  useEffect(() => {
    setPlates({
      ...plates,
      plate1: { ...travel.data.vehicle?.[0]?.vehicles },
      plate2: { ...travel.data.vehicle?.[0]?.wagons?.[0] },
      plate3: { ...travel.data.vehicle?.[0]?.wagons?.[1] },
      plate4: { ...travel.data.vehicle?.[0]?.wagons?.[2] },
    });
  }, [editing]);

  async function fetchVehicle(search) {
    try {
      const response = await api.get('vehicle/license_plate', {
        params: { search },
      });
      return response.data;
    } catch (error) {
      return [];
    }
  }

  async function getUpdatedVehicle(vehicleId) {
    const getVehicleUrl = `/vehicles/${vehicleId}`;
    const getVehicle = await api.get(getVehicleUrl);
    return getVehicle.data;
  }

  async function fetchWagons(search) {
    try {
      const response = await api.get('plate/wagons', {
        params: { search },
      });
      return response.data;
    } catch (error) {
      return [];
    }
  }

  async function getUpdatedWagon(wagonId) {
    const getVehicleUrl = `/wagons/${wagonId}`;
    const getVehicle = await cooperplaceApi.get(getVehicleUrl);
    return getVehicle.data;
  }

  async function handleSubmit(submitOptions) {
    setLoading(true);
    try {
      const data = {
        vehicleId: plates.plate1?.id,
        wagonIds: [
          plates?.plate2?.id,
          plates?.plate3?.id,
          plates?.plate4?.id,
        ].filter(Boolean),
      };
      if (!plates.plate1) {
        setLoading(false);
        setErrors('Insira uma placa');
        return;
      }

      if (!submitOptions?.confirmMinimiumMargin) {
        const isMarginAbove = await travel.isMarginAbove({
          mainVehicle: plates.plate1,
        });

        if (isMarginAbove) {
          lowerMarginModal.open();
          return;
        }
      }

      const response = await cooperplaceApi.put(
        `travels/${travel.data.id}/vehicle`,
        data
      );
      const { isOnAnotherTravel } = response.data;

      if (isOnAnotherTravel === true) {
        setLoading(false);
        setErrors(
          `Este Veículo está em outra viagem neste período! N° da viagem: ${response.data.attendedId}`
        );
        return;
      }

      snackbar.show(<Text className="text-center">Veículo alterado!</Text>, {
        type: 'success',
        duration: 5000,
      });
      setLoading(false);
      setEditing(false);
      await travel.fetchTravel();
    } catch (err) {
      const message =
        err?.response?.data?.message || 'Não foi possível alterar o veículo';
      snackbar.show(<Text className="text-center">{message}</Text>, {
        type: 'error',
        duration: 5000,
      });
      setLoading(false);
      setEditing(true);
    }
  }

  const importVehicleFromBenner = async plate => {
    const url = `vehicles/importFromBenner?plate=${plate}`;
    const { data } = await cooperplaceApi.get(url);

    snackbar.show(
      <Text>
        {data?.success ? 'Importado do benner com sucesso' : data?.message}
      </Text>,
      {
        type: data?.success ? 'success' : 'error',
      }
    );
    return data.id;
  };

  const importWagonFromBenner = async plate => {
    const url = `wagons/importFromBenner?plate=${plate}`;
    const { data } = await cooperplaceApi.put(url);
    return data.id;
  };

  function checkVehicleData(data) {
    const validation = vehicleRequiredFields.filter(field => !data[field]);

    const anttNumberValidation = validAnttRegex.test(data?.antt);

    let trackValidation = null;
    const shouldValidatedTracker = data?.tracked;
    if (shouldValidatedTracker) {
      trackValidation = trackedVehicleRequiredFiels.filter(
        field => !data[field]
      );

      return (
        validation?.length > 0 ||
        !anttNumberValidation ||
        trackValidation?.length > 0
      );
    }
    return validation?.length > 0 || !anttNumberValidation;
  }

  function handleUpdateMainPlate(data) {
    setPlates({
      ...plates,
      old_plate1: travel.data?.vehicle?.[0]?.vehicles?.plate,
      plate1: data,
    });
  }

  function handleUpdateWagonsPlate() {
    setPlates({
      ...plates,
      old_plate1: travel.data?.vehicle?.[0]?.vehicles?.plate,
      [wagon.key]: wagon?.wagonData,
    });
  }

  const updateVehicle = async value => {
    try {
      setIsFetchingBenner(true);
      const vehicleId = await importVehicleFromBenner(value.plate);
      const updatedVehicle = await getUpdatedVehicle(vehicleId);
      return {
        data: updatedVehicle,
        message: 'Veículo importado do Benner!',
        success: true,
      };
    } catch (error) {
      return {
        message: 'Veículo não encontrado no Benner!',
        data: value,
        success: false,
      };
    } finally {
      setIsFetchingBenner(false);
    }
  };

  function wagonDataMapper(data) {
    return {
      id: data?.id,
      plate: data?.plate,
      year_manufacture: data?.year_manufacture || data?.yearManufacture,
      year_model: data?.year_model || data?.yearModel,
      chassi: data?.chassi,
      vehicleType: data?.vehicleType,
      vehicle_type_id: data?.vehicle_type_id || data?.vehicleTypeId,
      bodyType: data?.bodyType,
      vehicle_body_type_id:
        data?.vehicle_body_type_id || data?.vehicleBodyTypeId,
      vehicle_implement_type_id:
        data?.vehicle_implement_type_id || data?.vehicleImplementTypeId,
      implementType: data?.implementType,
      renavam: data?.renavam,
      antt_type: data?.antt_type || data?.anttType,
      anttType: data?.anttTypes || data?.anttType,
      antt: data?.antt,
      antt_adherence:
        data?.antt_adherence || data?.anttAdherence
          ? format(
              new Date(
                moment(data?.antt_adherence || data?.anttAdherence).add(
                  1,
                  'day'
                )
              ),
              'yyyy-MM-dd'
            )
          : null,
      city: data?.city
        ? {
            id: data?.city?.id,
            name: data?.city?.name,
            uf: data?.city?.province?.uf,
          }
        : null,
      id_owner: data?.id_owner || data?.idOwner,
      owner: data?.owner || data?.ownerWagon,
      beneficiary: data?.beneficiary || data?.beneficiaryWagon,
      beneficiary_id: data?.beneficiary_id || data?.beneficiaryId,
    };
  }

  function wagonDataToValidate(data) {
    return {
      plate: data?.plate,
      chassi: data?.chassi,
      renavam: data?.renavam,
      year_model: data?.year_model || data?.yearModel,
      year_manufacture: data?.year_manufacture || data?.yearManufacture,
      plate_city_id: data?.city?.id,
      vehicle_type_id: data?.vehicle_type_id || data?.vehicleTypeId,
      vehicle_body_type_id:
        data?.vehicle_body_type_id || data?.vehicleBodyTypeId,
      vehicle_implement_type_id:
        data?.vehicle_implement_type_id || data?.vehicleImplementTypeId,
      vehicle_implement_type_name: data?.implementType?.name,
      antt_type: data?.antt_type || data?.anttType,
      id_owner: data?.id_owner || data?.idOwner,
      beneficiary_id: data?.beneficiary_id || data?.beneficiaryId,
      antt_adherence: data?.antt_adherence || data?.anttAdherence,
      antt: data?.antt,
    };
  }

  function checkWagonData(data) {
    const anttNumberValidation = validAnttRegex.test(data?.antt);
    if (data?.vehicle_implement_type_name === 'Dolly') {
      const validation = wagonRequiredFields
        .filter(field => field !== 'vehicle_body_type_id')
        .filter(field => !data[field]);
      return validation?.length > 0 || !anttNumberValidation;
    }
    const validation = wagonRequiredFields.filter(field => !data[field]);

    return validation?.length > 0 || !anttNumberValidation;
  }
  const handleChangePlates = async (value, label) => {
    // veículo
    if (label === 'plate1') {
      setErrors();
      if (value) {
        const { data, message, success } = await updateVehicle(value);
        snackbar.show(<Text>{message}</Text>, {
          type: success ? 'success' : 'error',
        });
        // caso seja viagem com apólice, validar se veículo bate com requisitos da apólice
        setVehicle({
          id: data?.id,
          plate: data?.plate,
          year_manufacture: data?.year_manufacture,
          year_model: data?.year_model,
          chassi: data?.chassi,
          vehicleType: data?.vehicleType,
          vehicle_type_id: data?.vehicle_type_id,
          vehicle_implement_type: data?.vehicle_implement_type,
          implementType: data?.implementType,
          vehicleBodyType: data?.vehicleBodyType,
          vehicle_body_type_id: data?.vehicle_body_type_id,
          renavam: data?.renavam,
          antt_type: data?.antt_type,
          anttType: data?.anttType,
          antt: data?.antt,
          antt_adherence: data?.antt_adherence
            ? format(
                new Date(moment(data?.antt_adherence).add(1, 'day')),
                'yyyy-MM-dd'
              )
            : null,
          tracked: data?.tracked,
          tracker_code: data?.tracker_code,
          tracker: data?.tracker,
          tracker_type: data?.trackerType,
          city: data?.city
            ? {
                id: data?.city?.id,
                name: data?.city?.name,
                uf: data?.city?.province?.uf,
              }
            : null,
          owner_id: data?.owner_id,
          beneficiary_id: data.beneficiary_id,
          beneficiary: data.beneficiary,
          ownerData: data?.ownerData,
          tags: data?.tags || [],
        });
        if (checkVehicleData(data)) {
          const message = hasPermissionToEditVehicle
            ? 'Cadastro de veículo incompleto'
            : 'Solicite atualização do cadastro do veículo para utilizá-lo na viagem';
          snackbar.show(<Text>{message}</Text>, {
            type: 'warning',
          });
          if (hasPermissionToEditVehicle) {
            return updateVehicleData.open();
          }
          setPlates({
            ...plates,
            old_plate1: travel.data?.vehicleAttended?.vehicle?.plate,
            [label]: null,
          });
          return;
        }
        setPlates({
          ...plates,
          old_plate1: travel.data?.vehicleAttended?.vehicle?.plate,
          [label]: data,
        });

        setPlates({
          ...plates,
          old_plate1: travel.data?.vehicleAttended?.vehicle?.plate,
          [label]: data,
        });
      } else
        setPlates({
          ...plates,
          old_plate1: travel.data?.vehicleAttended?.vehicle?.plate,
          [label]: value,
        });
      return;
    }

    // implementos

    if (value) {
      let updatedWagon = value;
      try {
        setIsFetchingBenner(true);
        const wagonId = await importWagonFromBenner(value?.plate);
        updatedWagon = await getUpdatedWagon(wagonId);
      } catch (error) {
        snackbar.show(<Text>Veículo não encontrado no Benner!</Text>, {
          type: 'error',
        });
      } finally {
        setIsFetchingBenner(false);
      }
      const wagonMapped = wagonDataMapper(updatedWagon);
      setWagon({
        key: label,
        wagonData: wagonMapped,
      });
      const wagonFieldsToValidate = wagonDataToValidate(updatedWagon);
      if (checkWagonData(wagonFieldsToValidate)) {
        const message = hasPermissionToEditWagon
          ? 'Cadastro de implemento incompleto'
          : 'Solicite atualização do cadastro do implemento para utilizá-lo na viagem';
        snackbar.show(<Text>{message}</Text>, {
          type: 'warning',
        });

        if (hasPermissionToEditWagon) {
          return updateWagonData.open();
        }
        setPlates({ ...plates, [label]: null });
      } else {
        setPlates({ ...plates, [label]: updatedWagon });
      }
      setPlates({ ...plates, [label]: updatedWagon });
    } else setPlates({ ...plates, [label]: value });
  };

  function handleConfirmLowerMargin(value) {
    handleSubmit(value);
  }

  if (travel.isLoading) {
    return null;
  }

  return (
    <>
      <UpdateVehicleData
        isOpen={updateVehicleData.isOpen}
        onClose={() => updateVehicleData.close()}
        onSubmit={handleUpdateMainPlate}
        vehicle={vehicle}
        setVehicle={setVehicle}
      />
      <UpdateWagonData
        isOpen={updateWagonData.isOpen}
        onClose={() => updateWagonData.close()}
        onSubmit={handleUpdateWagonsPlate}
        wagon={wagon}
        setWagon={setWagon}
      />
      <Card
        loading={isLoading}
        LoadingBodyComponent={() => (
          <DefaultLoadingBodyComponent linesCount={2} perLine={2} />
        )}
        header={
          <Text color="dark" type="header">
            Veículo
          </Text>
        }
        HeaderRightComponent={
          hasPermissionToEdit && (
            <Row className="form">
              {editing && (
                <Button
                  variant="secondary"
                  loading={loading}
                  className="mr-2"
                  onClick={() => {
                    setEditing(false);
                    setErrors('');
                  }}
                >
                  <Text color="dark" type="regular" weight="500">
                    Cancelar
                  </Text>
                </Button>
              )}
              <Button
                onClick={
                  editing ? () => handleSubmit() : () => setEditing(true)
                }
                variant={editing ? 'primary' : 'secondary'}
                loading={loading || isFetchingBenner || isFetchingMargin}
                className="py-2"
                disabled={
                  isTravelBlocked ||
                  travel?.isDisabledGROAnalysis ||
                  travel?.groAnalysisStatus === APPROVED_GRO_ANALYSIS_SLUG
                }
                title={
                  travel?.isDisabledGROAnalysis
                    ? 'Não é possível edição durante período de análise GRO'
                    : travel?.groAnalysisStatus === APPROVED_GRO_ANALYSIS_SLUG
                    ? 'Não é possível edição após aprovação do GRO'
                    : ''
                }
              >
                <Text
                  color={editing ? 'white' : 'dark'}
                  type="regular"
                  weight="500"
                >
                  {editing ? 'Salvar' : 'Editar'}
                </Text>
              </Button>
            </Row>
          )
        }
      >
        {editing ? (
          <>
            <Row className="form">
              <Col xs={12} md={6}>
                <Select.Async
                  onSearch={fetchVehicle}
                  placeholder=""
                  value={plates.plate1}
                  label="Placa principal *"
                  onChange={value => handleChangePlates(value, 'plate1')}
                  getOptionLabel={option => option.plate}
                  getOptionValue={option => option.id}
                  error={errors}
                  isDisabled={isFetchingBenner}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={6}>
                <Select.Async
                  onSearch={fetchWagons}
                  placeholder=""
                  value={plates.plate2}
                  label="Placa implemento 1"
                  onChange={value => handleChangePlates(value, 'plate2')}
                  getOptionLabel={option => {
                    let label = option.plate;
                    if (option.vehicle) {
                      label += ` - Tração ${option.vehicle.plate}`;
                    }

                    return label;
                  }}
                  getOptionValue={option => option.id}
                  isDisabled={isFetchingBenner}
                />
              </Col>
              <Col xs={12} md={6}>
                <Select.Async
                  onSearch={fetchWagons}
                  placeholder=""
                  value={plates.plate3}
                  label="Placa implemento 2"
                  onChange={value => handleChangePlates(value, 'plate3')}
                  getOptionLabel={option => option.plate}
                  getOptionValue={option => option.id}
                  isDisabled={isFetchingBenner}
                />
              </Col>

              <Col xs={12} md={6}>
                <Select.Async
                  onSearch={fetchWagons}
                  placeholder=""
                  value={plates.plate4}
                  label="Placa implemento 3"
                  onChange={value => handleChangePlates(value, 'plate4')}
                  getOptionLabel={option => option.plate}
                  getOptionValue={option => option.id}
                  isDisabled={isFetchingBenner}
                />
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row>
              <Col xs={12} md={6}>
                <Text type="label" color="dark">
                  Placa principal
                </Text>
                <Link
                  to={
                    travel.data?.vehicle?.[0]?.vehicles?.id &&
                    `/veiculos/${travel.data?.vehicle?.[0]?.vehicles?.id}`
                  }
                >
                  <Text type="regular" color="gray" as="p" className="mt-2">
                    {travel.data?.vehicle?.[0]?.vehicles?.plate ||
                      'Não informada'}
                    {travel.data?.vehicle?.[0]?.vehicles?.plate && (
                      <FaExternalLinkAlt
                        color="#B5B5C3"
                        className="mb-1 ml-2"
                        size={12}
                      />
                    )}
                  </Text>
                </Link>
              </Col>

              <Col xs={12} md={6}>
                <Text type="label" color="dark">
                  Telefone proprietário
                </Text>
                <Text type="regular" color="dark" as="p" className="mt-2">
                  {travel.data?.vehicle?.[0]?.vehicles?.owner?.phone ||
                    'Não informado'}
                </Text>
              </Col>

              {travel.data?.vehicle?.[0]?.wagons &&
                travel.data?.vehicle?.[0]?.wagons.map(wg => (
                  <>
                    <Col xs={12} md={6}>
                      <Text type="label" color="dark">
                        Placa implemento
                      </Text>
                      <Text type="regular" color="gray" as="p" className="mt-2">
                        {wg?.plate}
                      </Text>
                    </Col>
                  </>
                ))}
              <Col xs={12} md={6}>
                <Text type="label" color="dark">
                  Validade RNTRC
                </Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {travel.data?.vehicle?.[0]?.vehicles?.rntrcExpDate
                    ? moment(
                        travel.data?.vehicle?.[0]?.vehicles?.rntrcExpDate,
                        'YYYY-MM-DD'
                      ).format('DD/MM/YYYY')
                    : '-'}
                </Text>
              </Col>
              <Col xs={12} md={6}>
                <Text type="label" color="dark">
                  Validade cronotacógrafo
                </Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {travel.data?.vehicle?.[0]?.vehicles?.chronotachographExpDate
                    ? moment(
                        travel.data?.vehicle?.[0]?.vehicles
                          ?.chronotachographExpDate,
                        'YYYY-MM-DD'
                      ).format('DD/MM/YYYY')
                    : '-'}
                </Text>
              </Col>

              <Col xs={12} md={6}>
                <Text type="label" color="dark">
                  ID do rastreador
                </Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {travel.data?.vehicle?.[0]?.vehicles?.trackerCode ||
                    'Não informado'}
                </Text>
              </Col>

              <Col xs={12} md={6}>
                <Text type="label" color="dark">
                  Login do rastreador
                </Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {travel.data?.vehicle?.[0]?.vehicles?.trackerLogin ||
                    'Não informado'}
                </Text>
              </Col>

              <Col xs={12} md={6}>
                <Text type="label" color="dark">
                  Senha do rastreador
                </Text>

                <Text type="regular" color="gray" as="p" className="mt-2">
                  {travel.data?.vehicle?.[0]?.vehicles?.trackerPassword ||
                    'Não informado'}
                </Text>
              </Col>
              <Col xs={12} md={6}>
                <Text type="label" color="#464E5F">
                  Nível de Serviço
                </Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {travel.data?.vehicle?.[0]?.vehicles
                    ?.descriptionServiceLevel || 'Não informado'}
                </Text>
              </Col>
              <Col xs={12} md={6}>
                <Text type="label" color="dark">
                  Tags
                </Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {travel.data?.vehicle?.[0]?.vehicles?.tags
                    ?.map(tag => tag.name)
                    ?.join(', ') || 'Não informado'}
                </Text>
              </Col>
            </Row>
          </>
        )}
      </Card>
      <VerifyVehiclesModal vehicles={formatedVehiclesPlates} />
      <MinimumMarginModal
        isOpen={lowerMarginModal.isOpen}
        onClose={lowerMarginModal.close}
        confirm={handleConfirmLowerMargin}
      />
    </>
  );
}

export default Vehicle;
