import api from 'services/api';

export async function getVehicles(
  filter,
  page = 1,
  { setVehicles, setLoading, setPagination }
) {
  if (page === 1) setLoading(true);

  try {
    if (filter === '?') filter = '';
    const response = await api.get(`vehicles?${filter}&page=${page}`);
    const { data, ...rest } = response.data;

    setPagination(rest);
    setVehicles(oldVehicles => {
      if (page === 1) {
        return data;
      }
      return [...oldVehicles, ...data];
    });
  } catch (error) {
    // snackbar
  } finally {
    setLoading(false);
  }
}
