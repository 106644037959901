import styled from 'styled-components';
import theme from 'v3/theme';
import Text from 'v3/components/Text';

export const BennerValidationDescriptionsContainer = styled(Text)`
  max-height: 8rem;
  margin-top: 0.5rem;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  scroll-behavior: smooth;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    background: white;
    width: 0.3rem;
    height: 0;
  }
  &:not(:hover)::-webkit-scrollbar-thumb {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: ${theme.colors.medium_gray};
  }

  & > div {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    & > span {
      display: block;
    }
  }
`;
