import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Row, Col } from 'react-bootstrap';

import CheckBox from 'components/CheckBox';

import InputCurrency from 'v3/components/Input/Currency';
import Input from 'v3/components/Input';
import Text from 'v3/components/Text';

import { useSnackbar } from 'v3/components/Snackbar';
import { useRoute } from './context';
import { formatCurrency, currencyWithSymbol } from 'v3/utils/formatter';

const PARAMETERS_LABEL = [
  {
    type: 'insurance_type',
    label: 'insurance_value',
  },
  {
    type: 'gris_type',
    label: 'gris',
  },
  {
    type: 'advalorem_type',
    label: 'ad_valorem',
  },
];

export default function RouteTaxes() {
  const route = useRoute();
  const params = useParams();

  const snackbar = useSnackbar();
  const [isTollPayerClient, setIsTollPayerClient] = useState(false);
  useEffect(() => {
    if (!params.id) {
      route.setData({
        insurance_value: 0,
        gris: 0,
        ad_valorem: 0,
        parameters: {
          insurance_type: 0,
          gris_type: 0,
          advalorem_type: 0,
        },
      });
    }
    if (route?.data?.is_toll_payer_client) {
      setIsTollPayerClient(route?.data?.is_toll_payer_client);
    }
  }, []);

  function handleChangeTypeValue(name, value) {
    route.setData({
      parameters: { ...route.data?.parameters, [name]: value },
    });

    const valueToChange = PARAMETERS_LABEL.find(p => p.type === name)?.label;

    route.setData({ [valueToChange]: 0 });
  }

  function handleToll() {
    setIsTollPayerClient(!isTollPayerClient);
    route.setData({ is_toll_payer_client: !isTollPayerClient });
  }

  const [routeCurrencySymbol, setRouteCurrencySymbol] = useState('');

  useEffect(() => {
    if (route.data.currency) {
      let tempCurrency = route.data.currency.value || route.data.currency;
      setRouteCurrencySymbol(
        currencyWithSymbol.find(currency => currency.currency === tempCurrency)
          ?.symbol
      );
    }
  }, [route?.data?.currency]);

  return (
    <Row>
      <Col md={6} xs={12} className="mb-3">
        {route.data?.parameters?.insurance_type === 0 ? (
          <Input
            type="number"
            label={
              <div className="d-flex justify-content-between mx-1">
                <Text
                  color="#464e5f"
                  type="label"
                  weight="500"
                  style={{ display: 'block' }}
                >
                  Seguro{' '}
                  {route.data?.parameters?.insurance_type === 0
                    ? '%'
                    : routeCurrencySymbol}
                </Text>
                <Text
                  className="ml-3"
                  type="label"
                  clickable
                  color="light_blue"
                  onClick={() =>
                    handleChangeTypeValue(
                      'insurance_type',
                      route.data?.parameters?.insurance_type === 0 ? 1 : 0
                    )
                  }
                >
                  {route.data?.parameters?.insurance_type === 0
                    ? 'Mudar para valor fixo'
                    : 'Mudar para porcentagem'}
                </Text>
              </div>
            }
            value={route?.data?.insurance_value}
            onChange={({ target }) => {
              if (target.value < 0) {
                snackbar.show(
                  <Text>Não utilize valores negativos para o seguro.</Text>,
                  {
                    type: 'error',
                  }
                );

                return;
              }

              if (target.value > 100) {
                snackbar.show(
                  <Text>
                    Não é possível escolher porcentagem maior de 100 para o
                    seguro.
                  </Text>,
                  {
                    type: 'error',
                  }
                );

                return;
              }
              route.setData({ insurance_value: target.value || 0 });
            }}
          />
        ) : (
          <InputCurrency
            label={
              <div className="d-flex justify-content-between mx-1">
                <Text
                  color="#464e5f"
                  type="label"
                  weight="500"
                  style={{ display: 'block' }}
                >
                  Seguro{' '}
                  {route.data?.parameters?.insurance_type === 0
                    ? '%'
                    : routeCurrencySymbol}
                </Text>
                <Text
                  className="ml-3"
                  type="label"
                  clickable
                  color="light_blue"
                  onClick={() =>
                    handleChangeTypeValue(
                      'insurance_type',
                      route.data?.parameters?.insurance_type === 0 ? 1 : 0
                    )
                  }
                >
                  {route.data?.parameters?.insurance_type === 0
                    ? 'Mudar para valor fixo'
                    : 'Mudar para porcentagem'}
                </Text>
              </div>
            }
            value={route?.data?.insurance_value}
            onChange={(_, value) =>
              route.setData({ insurance_value: value || 0 })
            }
            placeholder={routeCurrencySymbol}
          />
        )}
      </Col>

      <Col md={6} xs={12} className="mb-3">
        {route.data?.parameters?.gris_type === 0 ? (
          <Input
            type="number"
            label={
              <div className="d-flex justify-content-between mx-1">
                <Text
                  color="#464e5f"
                  type="label"
                  weight="600"
                  style={{ display: 'block' }}
                >
                  Gris{' '}
                  {route.data?.parameters?.gris_type === 0
                    ? '%'
                    : routeCurrencySymbol}
                </Text>
                <Text
                  className="ml-4"
                  type="label"
                  clickable
                  color="light_blue"
                  onClick={() =>
                    handleChangeTypeValue(
                      'gris_type',
                      route.data?.parameters?.gris_type === 0 ? 1 : 0
                    )
                  }
                >
                  {route.data?.parameters?.gris_type === 0
                    ? 'Mudar para valor fixo'
                    : 'Mudar para porcentagem'}
                </Text>
              </div>
            }
            value={route?.data?.gris}
            onChange={({ target }) => {
              if (target.value < 0) {
                snackbar.show(
                  <Text>Não utilize valores negativos para o gris.</Text>,
                  {
                    type: 'error',
                  }
                );

                return;
              }

              if (target.value > 100) {
                snackbar.show(
                  <Text>
                    Não é possível escolher porcentagem maior de 100 para o
                    gris.
                  </Text>,
                  {
                    type: 'error',
                  }
                );

                return;
              }
              route.setData({ gris: target.value || 0 });
            }}
          />
        ) : (
          <InputCurrency
            label={
              <div className="d-flex justify-content-between mx-1">
                <Text
                  color="#464e5f"
                  type="label"
                  weight="500"
                  style={{ display: 'block' }}
                >
                  Gris {routeCurrencySymbol}
                </Text>
                <Text
                  className="ml-3"
                  type="label"
                  clickable
                  color="light_blue"
                  onClick={() =>
                    handleChangeTypeValue(
                      'gris_type',
                      route.data?.parameters?.gris_type === 0 ? 1 : 0
                    )
                  }
                >
                  {route.data?.parameters?.gris_type === 0
                    ? 'Mudar para valor fixo'
                    : 'Mudar para porcentagem'}
                </Text>
              </div>
            }
            value={route?.data?.gris}
            onChange={(_, value) => route.setData({ gris: value || 0 })}
            placeholder={routeCurrencySymbol}
          />
        )}
      </Col>

      <Col md={6} xs={12} className="mb-3">
        {route.data?.parameters?.advalorem_type === 0 ? (
          <Input
            type="number"
            label={
              <div className="d-flex justify-content-between mx-1">
                <Text color="#464e5f" type="label" weight="500">
                  ADVALOREM{' '}
                  {route.data.parameters?.advalorem_type === 0
                    ? '%'
                    : routeCurrencySymbol}
                </Text>
                <Text
                  className="ml-1"
                  type="label"
                  clickable
                  color="light_blue"
                  onClick={() =>
                    handleChangeTypeValue(
                      'advalorem_type',
                      route.data.parameters?.advalorem_type === 0 ? 1 : 0
                    )
                  }
                >
                  {route.data.parameters?.advalorem_type === 0
                    ? 'Mudar para valor fixo'
                    : 'Mudar para porcentagem'}
                </Text>
              </div>
            }
            value={route.data?.ad_valorem}
            onChange={({ target }) => {
              if (target.value < 0) {
                snackbar.show(
                  <Text>Não utilize valores negativos para o advalorem.</Text>,
                  {
                    type: 'error',
                  }
                );

                return;
              }

              if (target.value > 100) {
                snackbar.show(
                  <Text>
                    Não é possível escolher porcentagem maior de 100 para o
                    advalorem.
                  </Text>,
                  {
                    type: 'error',
                  }
                );

                return;
              }
              route.setData({ ad_valorem: target.value || 0 });
            }}
          />
        ) : (
          <InputCurrency
            label={
              <div className="d-flex justify-content-between mx-1">
                <Text
                  color="#464e5f"
                  type="label"
                  weight="500"
                  style={{ display: 'block' }}
                >
                  ADVALOREM {routeCurrencySymbol}
                </Text>
                <Text
                  className="ml-3"
                  type="label"
                  clickable
                  color="light_blue"
                  onClick={() =>
                    handleChangeTypeValue(
                      'advalorem_type',
                      route.data?.parameters?.advalorem_type === 0 ? 1 : 0
                    )
                  }
                >
                  {route.data?.parameters?.advalorem_type === 0
                    ? 'Mudar para valor fixo'
                    : 'Mudar para porcentagem'}
                </Text>
              </div>
            }
            value={route?.data?.ad_valorem}
            onChange={(_, value) => route.setData({ ad_valorem: value || 0 })}
            placeholder={routeCurrencySymbol}
          />
        )}
      </Col>

      <Col md={6} xs={12} className="mb-3">
        <InputCurrency
          label={`Descarga (${routeCurrencySymbol})`}
          value={route?.data?.discharge_price}
          onChange={(_, value) =>
            route.setData({ discharge_price: value || 0 })
          }
          placeholder={routeCurrencySymbol}
        />
      </Col>

      <Col md={6} xs={12} className="mb-3">
        <InputCurrency
          label={`Taxa de carregamento (${routeCurrencySymbol})`}
          value={route?.data?.loading_charge}
          onChange={(_, value) => route.setData({ loading_charge: value || 0 })}
          placeholder={routeCurrencySymbol}
        />
      </Col>

      <Col xs={12} className="mb-3">
        <InputCurrency
          label={
            <div className="d-flex justify-content-between mx-1">
              <Text
                color="#464e5f"
                type="label"
                weight="500"
                style={{ display: 'block' }}
              >
                PEDÁGIO R$
              </Text>
            </div>
          }
          value={route?.data?.toll_value}
          onChange={(_, value) => route.setData({ toll_value: value || 0 })}
          placeholder="R$"
        />
      </Col>

      <Col xs={12}>
        <CheckBox
          label={`Cliente paga pedágio?`}
          labelStyle={{
            color: '#464e5f',
            fontFamily: 'Poppins',
            textTransform: 'capitalize',
          }}
          checked={route?.data?.is_toll_payer_client}
          value="isTollPayerClient"
          onClick={() => handleToll()}
          inputProps={{
            'aria-label': 'primary checkbox',
          }}
        />
      </Col>
    </Row>
  );
}
