import React, { useMemo, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { FaDownload, FaSearch, FaTrash } from 'react-icons/fa';
import { validatePermission } from 'actions';
import { useModal } from 'hooks';
import { cooperplaceApi } from 'services/api';
import StringBuilder from 'utils/StringBuilder';
import theme from 'v3/theme';
import PDFView from 'v3/components/PDFView';
import { Button, Fallback, Modal, Text } from 'v3/components';
import { AttachmentsModal } from './AttachmentModal';
import { useSnackbar } from 'v3/components/Snackbar';
import { formatDateTime } from 'v3/utils/formatter';

import {
  Backdrop,
  DateTime,
  AttachmentsContainer,
  AttachmentItem,
  AttachmentSection,
  FallbackContainer,
} from './styles';

export function Attachments({ attachments, rangeId, policyId, refetchPolicy }) {
  const hasPermissionToAddAttachment = validatePermission('SALVAR_APOLICE');
  const attachmentModal = useModal();

  const hasMaxAttachments = attachments?.length === 5;

  return (
    <>
      <AttachmentsModal
        isOpen={attachmentModal?.isOpen}
        onClose={() => attachmentModal.close()}
        refetchPolicy={refetchPolicy}
        rangeId={rangeId}
        policyId={policyId}
      />
      <AttachmentSection>
        <Fallback
          component={
            <FallbackContainer>
              <Text color={theme.colors.gray}>
                Não há anexos cadastrados para essa faixa.
              </Text>
              <Button
                onClick={() => attachmentModal.open()}
                className="mt-3"
                disabled={hasPermissionToAddAttachment === false}
              >
                Adicionar
              </Button>
            </FallbackContainer>
          }
          fall={!attachments?.length}
        >
          <AttachmentsContainer>
            <header>
              <Text>Anexos</Text>
              <Button
                onClick={() => attachmentModal.open()}
                className="mt-3"
                disabled={
                  hasPermissionToAddAttachment === false || hasMaxAttachments
                }
                title={
                  hasPermissionToAddAttachment === false
                    ? 'Você não tem permissão para realizar essa ação'
                    : hasMaxAttachments
                    ? 'Máximo de 5 anexos por faixa'
                    : ''
                }
              >
                Adicionar
              </Button>
            </header>
            <div>
              {attachments?.map(attachment => {
                return (
                  <AttachmentItem key={attachment?.id}>
                    <Attachment
                      attachment={attachment}
                      attachments={attachments}
                      rangeId={rangeId}
                      policyId={policyId}
                      refetchPolicy={refetchPolicy}
                    />
                    <Text type="regular" className="mt-2">
                      {attachment?.name}
                    </Text>
                  </AttachmentItem>
                );
              })}
            </div>
          </AttachmentsContainer>
        </Fallback>
      </AttachmentSection>
    </>
  );
}

function Attachment({
  attachment,
  attachments,
  refetchPolicy,
  rangeId,
  policyId,
}) {
  const [isHovering, setHovering] = useState(false);
  const [isShowingModal, setShowingModal] = useState(false);
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);

  const attachmentsWithoutPDF = useMemo(() => {
    return attachments?.filter(item => {
      const filterPDF = item?.url?.split('.');
      if (filterPDF[filterPDF?.length - 1] === 'pdf') return null;
      return item;
    });
  }, [attachments]);

  const [lightbox, setLightbox] = useState({
    isOpen: false,
    attachments: attachmentsWithoutPDF,
    index: 0,
  });

  async function removeAttachment(document) {
    try {
      setLoading(true);
      await cooperplaceApi.delete(
        `policies/${policyId}/ranges/${rangeId}/attachments/${document?.id}`
      );
      refetchPolicy();
    } catch (error) {
      snackbar.show('Não foi possível excluir o anexo', { type: 'error' });
    } finally {
      setLoading(false);
    }
  }

  const lightboxIndexes = useMemo(() => {
    const next = (lightbox.index + 1) % lightbox.attachments?.length;
    const previous =
      (lightbox.index + lightbox.attachments.length - 1) %
      lightbox.attachments.length;
    return { next, previous };
  }, [lightbox.index, lightbox.attachments]);

  function onClickPreview(id) {
    const index = id ? attachmentsWithoutPDF.findIndex(a => a.id === id) : 0;

    setLightbox(prev => ({
      ...prev,
      index,
      attachments: attachmentsWithoutPDF,
      isOpen: true,
    }));
  }

  function handleClick() {
    const fullPathArray = attachment?.url?.split('.');

    if (fullPathArray[fullPathArray?.length - 1] === 'pdf') {
      setShowingModal(true);
    } else {
      onClickPreview(attachment.id);
    }
  }

  function getImagePath(index, alwaysDisplay) {
    const isSameAsIndex =
      lightbox.index === lightboxIndexes.next &&
      lightbox.index === lightboxIndexes.previous;

    if (!alwaysDisplay && isSameAsIndex && lightbox.attachments.length === 1) {
      return null;
    }
    const processedStartPath =
      process.env.REACT_APP_POLICY_RANGES_ATTACHMENTS_STORAGE;

    return `${processedStartPath}${lightbox.attachments[index]?.url}`;
  }

  function getDocumentMetadata() {
    const document = lightbox.attachments[lightbox.index];
    const type = document?.description || 'Sem descrição';
    const createdAt = formatDateTime(document?.createdAt);

    return { type, createdAt };
  }

  const lightboxTitle = useMemo(() => {
    const builder = new StringBuilder(' ');
    const documentMetadata = getDocumentMetadata();
    builder
      .append(`${lightbox.index + 1}/${lightbox.attachments?.length}`)
      .append('|')
      .append(documentMetadata.type)
      .append(`(${documentMetadata.createdAt})`);

    return builder.build();
  }, [lightbox.index, lightbox.attachments]);

  return (
    <div>
      <Modal
        show={isShowingModal}
        dialogClassName="modal-90w"
        onHide={() => setShowingModal(false)}
        heading="Visualizar PDF"
        body={
          <PDFView
            file={`${process.env.REACT_APP_POLICY_RANGES_ATTACHMENTS_STORAGE}${attachment?.url}`}
          />
        }
      />
      <div
        className="position-relative"
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        style={{
          width: 270,
          height: 160,
          background: `url(${
            attachment?.url?.split('.').pop() === 'pdf'
              ? '/images/default-doc.png'
              : attachment?.url?.split('.').pop() === 'xlsx'
              ? '/images/default-excel.png'
              : attachment?.url?.split('.').pop() === 'xls'
              ? '/images/default-excel.png'
              : `${process.env.REACT_APP_POLICY_RANGES_ATTACHMENTS_STORAGE}${attachment?.url}`
          }) center center`,
          backgroundSize: '100%',
        }}
      >
        {lightbox.isOpen && (
          <Lightbox
            mainSrc={getImagePath(lightbox.index, true)}
            nextSrc={getImagePath(lightboxIndexes.next)}
            prevSrc={getImagePath(lightboxIndexes.previous)}
            onCloseRequest={() =>
              setLightbox(prev => ({ ...prev, isOpen: false }))
            }
            onMovePrevRequest={() =>
              setLightbox(prev => ({
                ...prev,
                index: lightboxIndexes.previous,
              }))
            }
            onMoveNextRequest={() =>
              setLightbox(prev => ({
                ...prev,
                index: lightboxIndexes.next,
              }))
            }
            imageTitle={lightboxTitle}
          />
        )}
        {isHovering && (
          <Backdrop>
            {attachment?.url?.split('.').pop() !== 'xlsx' &&
              attachment?.url?.split('.').pop() !== 'xls' && (
                <button type="button" onClick={handleClick}>
                  <FaSearch color="#fff" size={32} />
                </button>
              )}
            <a
              href={`${process.env.REACT_APP_POLICY_RANGES_ATTACHMENTS_STORAGE}${attachment?.url}`}
              download
            >
              <FaDownload color="#fff" size={32} />
            </a>
            <Button
              variant="transparent"
              loading={loading}
              size={0}
              onClick={() => removeAttachment(attachment)}
            >
              <FaTrash size={32} />
            </Button>
          </Backdrop>
        )}

        <DateTime>
          <Text type="label" color="white">
            {formatDateTime(attachment?.createdAt)}
          </Text>
        </DateTime>
      </div>
    </div>
  );
}
