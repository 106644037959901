import React, { useState } from 'react';
import axios from 'axios';
import config from 'config';

import FormControlLabel from '@material-ui/core/FormControlLabel';

import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Card from 'components/Card';
import CardItem from 'components/CardItem';
import Button from 'components/Button';
import IOSSwitch from 'components/Switch';

import moment from 'moment';

export default function CardGerenciadora(props) {
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(
    props.gerenciadora.deleted_at ? false : true
  );

  async function toggleStatus(gerenciadora) {
    //setActive(!active);
    let response = await axios.put(
      config.server + 'risk-manager/' + gerenciadora.id,
      {
        deleted_at: !active ? null : moment(),
      }
    );

    if (response.status === 200) {
      console.log(response.data);
    }
  }

  function handleClickOpen(group) {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  async function confirmOption(group) {
    setOpen(false);
    setActive(!active);
    toggleStatus(group);
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Habilitar gerenciadora'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Deseja habilitar a gerenciadora?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button outline={1} onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button
            outline={1}
            onClick={() => confirmOption(props.gerenciadora)}
            color="primary"
            autoFocus
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container>
        <Grid item lg={6} md={8} sm={10} xs={12}>
          <Card
            style={{
              marginTop: '10px',
            }}
          >
            <Grid container spacing={0} style={{ padding: '10px' }}>
              <Grid item xs={10}>
                <CardItem className="nameClients">
                  {props.gerenciadora.name}
                </CardItem>
              </Grid>
              <Grid
                item
                xs={2}
                container
                direction="row-reverse"
                justify="flex-start"
                alignItems="baseline"
              >
                <FormControlLabel
                  style={{
                    color: '#76838F',
                  }}
                  control={
                    <IOSSwitch
                      checked={active}
                      value={active}
                      onClick={() => handleClickOpen(props.gerenciadora)}
                    />
                  }
                  labelPlacement="start"
                  label={active ? 'ativo' : 'inativo'}
                />
              </Grid>
              <Grid item xs={12}>
                <hr />
                <Link
                  style={{
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    cursor: 'pointer',
                  }}
                  onClick={() => props.handleOpened(props.gerenciadora)}
                >
                  Ver / Editar
                </Link>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
