/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { cooperplaceApi } from 'services/api';
import Fallback from 'v3/components/Fallback';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import Card from 'v3/components/Card';
import theme from 'v3/theme';
import { useSnackbar } from 'v3/components/Snackbar';
import { useImplement } from '../../shared/context';
import { AttachmentsModal } from './Modal';
import { Attachment } from './Attachment';

export function Attachments({ attachments, setAttachments }) {
  const params = useParams();
  const snackbar = useSnackbar();
  const implement = useImplement();

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  async function fetchAttachments() {
    try {
      setLoading(true);
      const response = await cooperplaceApi.get(
        `wagons/${params.id}/attachment`
      );
      setAttachments(response.data);
    } catch (error) {
      setAttachments([]);
    } finally {
      setLoading(false);
    }
  }

  async function removeAttachment(attachmentId) {
    try {
      setLoading(true);
      await cooperplaceApi.delete(
        `wagons/${params.id}/attachment/${attachmentId}`
      );
      fetchAttachments();
    } catch (error) {
      snackbar.show('Não foi possível excluir o anexo', { type: 'error' });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchAttachments();
  }, []);

  return (
    <>
      <AttachmentsModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        fetchAttachments={fetchAttachments}
        setAttachments={setAttachments}
        attachments={attachments}
      />
      <Card
        header={
          <Text color="#464E5F" type="header">
            Anexos
          </Text>
        }
        HeaderRightComponent={
          !!attachments?.length && (
            <Button onClick={() => setShowModal(true)}> Adicionar </Button>
          )
        }
        loading={implement?.loading}
      >
        <>
          <Fallback
            component={
              <Col
                xs={12}
                className="align-items-center d-flex flex-column my-2"
              >
                <Text color={theme.colors.gray}>
                  Não há anexos cadastrados para esse implemento.
                </Text>
                <Button onClick={() => setShowModal(true)} className="mt-3">
                  Adicionar
                </Button>
              </Col>
            }
            fall={!attachments?.length}
          >
            <Row>
              {attachments?.map(attachment => {
                return (
                  <Col
                    md={4}
                    className="d-flex flex-column align-items-center"
                    key={attachment?.id}
                  >
                    <Attachment
                      attachment={attachment}
                      attachments={attachments}
                      removeAttachment={removeAttachment}
                      loading={loading}
                    />
                    <Text type="regular" className="mt-2">
                      {attachment?.type?.replace('_', ' ')}
                    </Text>
                    <Text type="regular" className="mt-2">
                      {attachment?.description}
                    </Text>
                  </Col>
                );
              })}
            </Row>
          </Fallback>
        </>
      </Card>
    </>
  );
}
