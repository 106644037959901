import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { Grid } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import apiReport from 'services/apis/report';
import Snackbar from 'components/Snackbar';
import Button from 'components/Button';
import InfiniteScroll from 'v3/components/InfiniteScroll';
import Card from 'components/Card';
import { validatePermission } from 'actions/index';
import IOSSwitch from 'components/Switch';
import FileSaver from 'file-saver';
import { usePermission } from 'hooks';
import CellNaoAtendida from './CellNaoAtendida';
import ClientSelector from './client-selector';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

let token = 0;

function ReportCargasNaoAtendidas() {
  const [data, setData] = useState([]);
  const [completed, setCompleted] = useState(0);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [pagination, setPagination] = useState({
    lastPage: 1,
    page: 1,
    total: 0,
  });
  const hasPermissionToAcess = usePermission(
    'VISUALIZAR_RELATORIO_CARGAS_NAO_ATENDIDAS'
  );

  useEffect(() => {
    if (!hasPermissionToAcess) window.location.href = '/';
  }, []);

  const query = useQuery();

  const [snackBar, setSnackBar] = useState({
    display: false,
    type: 'error',
    message: '',
  });

  async function getDownload() {
    try {
      setLoadingDownload(true);
      const response = await apiReport.get('load/not-attended/report', {
        params: {
          companies: query.get('companies'),
          start: query.get('start_date') || moment().format('YYYY-MM-DD'),
          end: query.get('end_date') || moment().format('YYYY-MM-DD'),
          status: active ? 'canceled' : 'active',
          cost_center_id: query.get('cost_center_id') || ''
        },
        responseType: 'blob',
      });
      FileSaver.saveAs(response.data, response.headers['x-file-name']);
      setCompleted(true);
    } catch (error) {
      setSnackBar({
        display: true,
        type: 'error',
        message: 'Não foi possivel gerar o arquivo',
      });
    } finally {
      setLoadingDownload(false);
    }
  }

  // Build filter & send to ReportAPI
  async function getData(page = 1) {
    const companies = query.get('companies');
    const start = query.get('start_date') || moment().format('YYYY-MM-DD');
    const end = query.get('end_date') || moment().format('YYYY-MM-DD');
    const status = active ? 'canceled' : 'active';
    const tags = query.getAll('tags');
    const user = query.get('user_id');
    const cost_center_id = query.get('cost_center_id') || '';

    const buildFilter = `${
      tags?.length && `tags=${tags}`
    }&companies=${companies}&cost_center_id=${cost_center_id}&start=${start}&end=${end}&${
      user?.length && `user_id=${user}`
    }&status=${status}&token=${token++}`;
    await fetchData(buildFilter, page);
  }

  // Fetch data & pagination from ReportAPI
  async function fetchData(filters, page) {
    if (page === 1) {
      setLoading(true);
    }
    try {
      const response = await apiReport.get(
        `load/not-attended/data?${filters}&page=${page}`
      );
      const { items, ...rest } = response.data;
      const pagObj = {
        page: rest.page,
        currentPage: rest.currentPage,
        perPage: rest.perPage,
        from: rest.from,
        to: rest.to,
        lastPage: rest.lastPage ? rest.lastPage : pagination.lastPage,
        total: rest.total !== undefined ? rest.total : pagination.total,
      };
      setPagination(pagObj);
      setData(oldData => {
        if (page === 1) {
          return items || [];
        }
        return [...oldData, ...items];
      });
    } catch (error) {
      // snackbar
    } finally {
      setLoading(false);
    }
  }

  // Render card inside each data item (infiniteScroll loop)
  function renderItem(item, index) {
    return <CellNaoAtendida key={index} carga={item} />;
  }

  return (
    <div>
      <Snackbar
        open={snackBar.display}
        type={snackBar.type}
        message={snackBar.message}
        onClose={() => setSnackBar(false)}
      />
      <Dialog
        open={loadingDownload}
        maxWidth="sm"
        fullWidth
        minWidth="sm"
        aria-labelledby="Relatório de viagens"
      >
        <DialogTitle>Relatório</DialogTitle>
        <DialogContent>
          Gerando relatório
          <LinearProgress
            variant="determinate"
            value={completed}
            color="primary"
          />
          <br />
        </DialogContent>
      </Dialog>
      <Card style={{ marginBottom: '10px', overflow: 'visible' }}>
        <Grid container spacing={2}>
          <ClientSelector
            buttonName="filtrar cargas"
            url="/relatorios/cargas-nao-atendidas"
            onClientSelected={() => {}}
            onListReports={() => getData()}
            setSnackBar={setSnackBar}
            hasTags
            hasCostCenter
            hasUser
          />

          <Grid item xs={12}>
            <FormControlLabel
              item
              style={{
                color: '#76838F',
                marginLeft: '0px',
              }}
              control={
                <IOSSwitch
                  checked={!active}
                  value={!active}
                  onClick={() => setActive(!active)}
                />
              }
              label={!active ? 'Em aberto' : 'Canceladas'}
            />
          </Grid>
          <Grid item xs={10}>
            {pagination.total > 0
              ? [
                  <Typography
                    variant="subtitle1"
                    style={{ fontSize: '18px', marginBottom: '10px' }}
                  >
                    Cargas não atendidas{' '}
                    <strong style={{ color: '#1C4B75' }}>
                      {pagination.total}
                    </strong>
                  </Typography>,
                ]
              : ''}
          </Grid>
          <Grid item xs={2}>
            {pagination.total > 0
              ? [
                  validatePermission(
                    'EXPORTAR_RELATORIO_CARGAS_NAO_ATENDIDAS'
                  ) && (
                    <Button
                      disabled={loading}
                      onClick={() => getDownload()}
                      color="success"
                    >
                      Gerar Planilha
                    </Button>
                  ),
                ]
              : ''}
          </Grid>
        </Grid>
      </Card>
      <InfiniteScroll
        loading={loading}
        data={data}
        renderItem={(item, index) => renderItem(item, index)}
        onEndReach={() => {
          getData(pagination.page + 1);
        }}
        hasMore={pagination.page < pagination.lastPage}
        scrollThreshold={0.7}
      />
    </div>
  );
}

export default ReportCargasNaoAtendidas;
