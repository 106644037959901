import React from 'react';

import { Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import Modal from 'v3/components/Modal';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';

function Duplicated({ isOpen, onClose, duplicatedID }) {
  const history = useHistory();

  return (
    <>
      <Modal
        show={isOpen}
        onHide={onClose}
        heading={
          <Text type="header" color="dark" weight="500">
            Carga duplicada!
          </Text>
        }
        body={
          <Row className="mx-0 mt-1">
            <Text weight="400" color="dark">
              Carga da viagem cancelada foi duplicada com sucesso!
            </Text>
          </Row>
        }
        footer={
          <Row md={12} xs={12} className="mr-3 mt-1">
            <Button
              variant="white"
              className="mr-2 mt-1 py-2"
              outlined
              size="sm"
              onClick={() => history.push(`/cargas/${duplicatedID}`)}
            >
              <Text weight="500" type="regular">
                Ver carga
              </Text>
            </Button>

            <Button
              variant="success"
              className="mr-2 mt-1 py-2"
              size="sm"
              onClick={() => {
                onClose();
              }}
            >
              <Text weight="500" type="regular">
                Fechar
              </Text>
            </Button>
          </Row>
        }
      />
    </>
  );
}

export default Duplicated;
