import React from 'react';
import Text from '../Text';
import {
  OccurrenceListItemContainer,
  OccurrencesListContainer,
  StyledTotalPendencyOccurrences,
  TravelOccurrencesListContainer,
} from './styles';
import { FaCheck } from 'react-icons/fa';
import { RiAlertFill } from 'react-icons/ri';

export function TravelOccurrencesList({
  selectedOccurrence,
  setSelectedOccurrence,
  occurrences,
  show,
  onlyList = false,
  totalPendencyOccurrences = null,
}) {
  function handleClick(occurrence) {
    if (
      selectedOccurrence?.id === occurrence.id &&
      !show.endOccurrence &&
      !show.addDescriptionToOccurrence &&
      !show.changeResponsible
    ) {
      setSelectedOccurrence({});
      return;
    } else if (
      !show.endOccurrence &&
      !show.addDescriptionToOccurrence &&
      !show.changeResponsible
    ) {
      setSelectedOccurrence(occurrence);
      return;
    }
  }
  if (onlyList) {
    return (
      <TravelOccurrencesListContainer>
        <Text weight={500} type="body" color="dark">
          Ocorrências pendentes{' '}
          <StyledTotalPendencyOccurrences>
            {totalPendencyOccurrences}
          </StyledTotalPendencyOccurrences>
        </Text>
        <OccurrencesListContainer>
          {occurrences?.map(occurrence => {
            return (
              <OccurrenceListItemContainer
                key={occurrence.id}
                selected={selectedOccurrence?.id === occurrence.id}
                finished={occurrence?.fullUserConclusion?.username}
                clickable={false}
                list={true}
                title={
                  occurrence?.descriptions[occurrence?.descriptions?.length - 1]
                    ?.description
                }
              >
                {occurrence.pendency &&
                !occurrence?.fullUserConclusion?.username ? (
                  <RiAlertFill />
                ) : (
                  <FaCheck />
                )}
                <Text type="regular" color="dark" weight={400}>
                  {
                    occurrence?.descriptions[
                      occurrence?.descriptions?.length - 1
                    ]?.description
                  }
                </Text>
              </OccurrenceListItemContainer>
            );
          })}
        </OccurrencesListContainer>
      </TravelOccurrencesListContainer>
    );
  } else
    return (
      <TravelOccurrencesListContainer>
        <Text weight={500} type="header" color="dark">
          Ocorrências
        </Text>
        <OccurrencesListContainer>
          {occurrences?.map(occurrence => {
            return (
              <OccurrenceListItemContainer
                key={occurrence.id}
                selected={selectedOccurrence?.id === occurrence.id}
                onClick={() => handleClick(occurrence)}
                finished={occurrence.userConclusion}
                clickable={
                  !show.endOccurrence &&
                  !show.addDescriptionToOccurrence &&
                  !show.changeResponsible
                }
                list={false}
              >
                {occurrence.pendency && !occurrence.userConclusion ? (
                  <RiAlertFill />
                ) : (
                  <FaCheck />
                )}
                <Text type="regular" color="dark" weight={400}>
                  {`${
                    occurrence?.descriptions[
                      occurrence?.descriptions?.length - 1
                    ].description
                  }`}
                </Text>

                <Text
                  type="label"
                  color="dark"
                  weight={400}
                  as="p"
                  title={`Id da ocorrência ${occurrence.id}`}
                >
                  {occurrence.id}
                </Text>
              </OccurrenceListItemContainer>
            );
          })}
        </OccurrencesListContainer>
      </TravelOccurrencesListContainer>
    );
}
