import styled, { css } from 'styled-components';
import {
  FaMapMarked,
  FaRoute,
  FaDollarSign,
  FaFileAlt,
  FaTruckLoading,
} from 'react-icons/fa';
import { GrValidate, GrStatusGood } from 'react-icons/gr';
import theme from 'v3/theme';

export const AnchorComponent = styled.div`
  @media (max-width: 1200px) {
    display: none;
  }
  z-index: 8;
  position: fixed;
  top: 310px;
  padding: 15px;
  opacity: 1;
  right: 0;

  > * {
    color: ${theme.colors.gray};
    cursor: pointer;
  }
`;

export const IconBox = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 5px;
  display: flex;
  width: 30px;
  height: 30px;
`;

export const ValueIcon = styled(FaDollarSign)`
  height: 18px;
  width: 18px;
  opacity: 0.6;
  transition: ease-in-out 0.5s;
  ${({ visible }) =>
    visible &&
    css`
      transform: scale(1.25);
      opacity: 1;
      color: ${theme.colors.primary};
    `}
  &:hover {
    opacity: 1;
  }
`;

export const BennerIcon = styled(GrValidate)`
  height: 18px;
  width: 18px;
  opacity: 0.6;
  transition: ease-in-out 0.5s;
  ${({ visible }) =>
    visible &&
    css`
      transform: scale(1.25);
      opacity: 1;
      color: ${theme.colors.primary};
    `}
  &:hover {
    opacity: 1;
  }
`;

export const MapIcon = styled(FaMapMarked)`
  height: 18px;
  width: 18px;
  opacity: 0.6;
  transition: ease-in-out 0.5s;
  ${({ visible }) =>
    visible &&
    css`
      transform: scale(1.25);
      opacity: 1;
      color: ${theme.colors.primary};
    `}
  &:hover {
    opacity: 1;
  }
`;

export const DetailsIcon = styled(FaRoute)`
  height: 18px;
  width: 18px;
  opacity: 0.6;
  transition: ease-in-out 0.5s;
  ${({ visible }) =>
    visible &&
    css`
      transform: scale(1.25);
      opacity: 1;
      color: ${theme.colors.primary};
    `}
  &:hover {
    opacity: 1;
  }
`;

export const MercosulIcon = styled(GrStatusGood)`
  height: 18px;
  width: 18px;
  opacity: 0.6;
  transition: ease-in-out 0.5s;
  ${({ visible }) =>
    visible &&
    css`
      transform: scale(1.25);
      opacity: 1;
      color: ${theme.colors.primary};
    `}
  &:hover {
    opacity: 1;
  }
`;

export const LoadIcon = styled(FaTruckLoading)`
  height: 18px;
  width: 18px;
  opacity: 0.6;
  transition: ease-in-out 0.5s;
  ${({ visible }) =>
    visible &&
    css`
      transform: scale(1.25);
      opacity: 1;
      color: ${theme.colors.primary};
    `}
  &:hover {
    opacity: 1;
  }
`;

export const DocumentsIcon = styled(FaFileAlt)`
  height: 18px;
  width: 18px;
  opacity: 0.6;
  transition: ease-in-out 0.5s;
  ${({ visible }) =>
    visible &&
    css`
      transform: scale(1.25);
      opacity: 1;
      color: ${theme.colors.primary};
    `}
  &:hover {
    opacity: 1;
  }
`;

export const Marker = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${theme.colors.medium_gray};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 7px;
  left: -9px;

  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
    `}

  ${({ isOnScreen }) =>
    isOnScreen &&
    css`
      background-color: ${theme.colors.success};
    `}
`;
