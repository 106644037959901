import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Input, InputAddress, Select, Text } from 'v3/components';
import { validateDateTime } from 'v3/utils/functions';
import { useLoadGro } from '../../context';
import { cooperplaceApi } from 'services/api';
import { fetchSelectOptions } from 'utils/fetches';
import { useSnackbar } from 'v3/components/Snackbar';
import { LoadContext } from 'v3/pages/Load/Register/controller';
const [, useLoad] = LoadContext;
export function Origin() {
  const {
    countryOptions,
    errorsGro,
    setErrorsGro,
  } = useLoadGro();
  const {data,setData} = useLoad()
  const snackbar = useSnackbar();
  const [disableNumber, setDisableNumber] = useState(false);
  const [disableZipCode, setDisableZipCode] = useState(false);
  const [disableCity, setDisableCity] = useState(false);
  const [disableProvince, setDisableProvince] = useState(false);
  const [disableNeighborhood, setDisableNeighborhood] = useState(false);

  async function fetchProvinces(
    search,
    uf,
    country,
    policyProductId = null,
    policyRouteType = 'origin'
  ) {
    try {
      const response = await cooperplaceApi.get('cities', {
        params: { search, uf, country, policyProductId, policyRouteType },
      });
      return response.data;
    } catch (ex) {
      return [];
    }
  }

  function handleOriginClientSelection(event) {
    disableAddress({}, null);
    if (event !== null) {
      const currentAddress = event?.addresses.filter(
        address => address.current
      )[0];

      if (
        !currentAddress ||
        currentAddress?.lat === null ||
        currentAddress?.lng === null
      ) {
        snackbar.show(
          <Text>{`Cadastro do cliente ${event?.social_name} está desatualizado! Atualize o endereço do cliente!`}</Text>,
          { type: 'warning' }
        );
        setData(old => ({
          ...old,
          origin: {
            ...old.origin,
            client: event,
          },
        }));
      } else {
        setData(old => ({
          ...old,
          origin: {
            ...old.origin,
            client: event,
            number: currentAddress?.number || '',
            complement: currentAddress?.complement || '',
            zipCode: currentAddress?.zip_code || '',
            address: currentAddress?.address || '',
            neighborhood: currentAddress?.neighborhood || '',
            lat: currentAddress?.lat || '',
            lng: currentAddress?.lng || '',
            city: currentAddress?.city_name || '',
            cityId: currentAddress?.city_id,
            cityModel: currentAddress?.city_name
              ? {
                  id: currentAddress?.city_id,
                  name: currentAddress?.city_name,
                  province: {
                    uf: currentAddress?.uf_name,
                  },
                }
              : undefined,
            province: currentAddress?.uf_name,
            formattedAddress:
              currentAddress?.formatted_address || currentAddress?.address
          },
        }));
      }
    } else {
      setData(old => ({
        ...old,
        origin: {
          ...old.origin,
          client: null,
          number: '',
          complement: '',
          zipCode: '',
          address: '',
          neighborhood: '',
          lat: '',
          lng: '',
          city: '',
          cityId: null,
          cityModel: null,
          province: '',
        },
      }));
    }
  }

  function disableAddress(address, city) {
    setDisableNumber(address.number ? true : false);
    setDisableZipCode(address.zip_code ? true : false);
    setDisableCity(city ? true : false);
    setDisableProvince(city?.province ? true : false);
    setDisableNeighborhood(address.neighborhood ? true : false);
  }

  return (
    <div>
      <Row className="form">
        <Col md={12} className="mb-2">
          <Text color="dark" type="header">
            Origem
          </Text>
        </Col>
        <Col md={6} xs={12} className="mb-3">
          <Select
            label="País *"
            options={countryOptions?.origin}
            value={data.origin?.country}
            onChange={value => {
              setData(old => ({
                ...old,
                origin: {
                  ...old.origin,
                  country: value,
                  countrySlug: value?.abbreviation,
                  number: '',
                  complement: '',
                  zipCode: '',
                  address: '',
                  neighborhood: '',
                  lat: '',
                  lng: '',
                  city: '',
                  cityModel: null,
                  province: '',
                },
              }));
              disableAddress({}, null);
            }}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            error={errorsGro[`origin.country`]}
            id="select-gro-pais-origem"
          />
        </Col>
        <Col md={6} xs={12} className="mb-3">
          <Select.Async
            label="Cliente"
            onSearch={search =>
              fetchSelectOptions('persons/customers', { search })
            }
            value={data.origin?.client}
            getOptionLabel={option => {
              let label = `${option?.social_name} - ${option?.cgccpf}`;

              if (option.city) {
                label += ` - ${option?.city}`;
              }

              return label;
            }}
            getOptionValue={option => option?.id}
            onChange={event => {
              handleOriginClientSelection(event);
            }}
            id="select-gro-cliente-origem"
          />
        </Col>
        <Col md={8}>
          <InputAddress
            label="Logradouro *"
            placeholder="Busque um endereço"
            value={data.origin?.address}
            onSelectedAddress={async place => {
              const [city] = await fetchProvinces(
                place.city,
                place.province,
                place.country_abbreviation
              );

              disableAddress(place, city);
              setData(old => ({
                ...old,
                origin: {
                  ...old.origin,
                  address: place.address ? place.address : '',
                  number: place?.number ? place.number : '',
                  zipCode: place.zip_code ? place.zip_code : '',
                  cityModel: city,
                  cityId: city?.id,
                  complement: place.name ? place.name : '',
                  neighborhood: place.neighborhood ? place.neighborhood : '',
                  province: city?.province?.uf || '',
                  lat: place.lat,
                  lng: place.lng,
                  formattedAddress: place.formatted_address,
                },
              }));
            }}
            onChange={event =>
              setData(old => ({
                ...old,
                origin: {
                  ...old.origin,
                  address: event.target.value,
                },
              }))
            }
            types={[]}
            error={errorsGro[`origin.address`]}
            country={
              data.origin?.country ? data.origin?.country?.abbreviation : null
            }
            disabled={!data.origin?.country}
            id="select-gro-logradouro-origem"
          />
        </Col>
        <Col md={4}>
          <Input
            label="Número"
            disabled={disableNumber || !data.origin?.country}
            value={data.origin?.number}
            onChange={event =>
              setData(old => ({
                ...old,
                origin: {
                  ...old.origin,
                  number: event.target.value,
                },
              }))
            }
            id="input-gro-numero-origem"
          />
        </Col>

        <Col md={4}>
          <Input
            label="Complemento"
            value={data.origin?.complement}
            onChange={event =>
              setData(old => ({
                ...old,
                origin: {
                  ...old.origin,
                  complement: event.target.value,
                },
              }))
            }
            disabled={!data.origin?.country}
            id="input-gro-complemento-origem"
          />
        </Col>
        <Col md={4}>
          <Input
            label="Bairro"
            disabled={disableNeighborhood || !data.origin?.country}
            value={data.origin?.neighborhood}
            onChange={event =>
              setData(old => ({
                ...old,
                origin: {
                  ...old.origin,
                  neighborhood: event.target.value,
                },
              }))
            }
            id="input-gro-bairro-origem"
          />
        </Col>
        <Col md={4}>
          <Input
            label="CEP"
            disabled={disableZipCode || !data.origin?.country}
            value={data.origin?.zipCode}
            onChange={event =>
              setData(old => ({
                ...old,
                origin: {
                  ...old.origin,
                  zipCode: event.target.value,
                },
              }))
            }
            error={errorsGro[`origin.zipCode`]}
            id="input-gro-cep-origem"
          />
        </Col>
        <Col xs={8} className="my-3">
          <Select.Async
            label="Cidade *"
            onSearch={city =>
              fetchProvinces(city, '', data.origin?.countrySlug)
            }
            value={data.origin?.cityModel}
            horizontal
            onChange={value =>
              setData(old => ({
                ...old,
                origin: {
                  ...old.origin,
                  cityModel: value,
                  cityId: value?.id,
                  province: value?.province?.uf || '',
                },
              }))
            }
            getOptionLabel={option =>
              `${`${option.name} ${
                option.province?.uf ? `- ${option.province?.uf}` : ''
              }`}`
            }
            getOptionValue={option =>
              `${`${option.name} ${
                option.province?.uf ? `- ${option.province?.uf}` : ''
              }`}`
            }
            error={errorsGro[`origin.cityModel`]}
            isDisabled={disableCity || !data.origin?.country}
            id="select-gro-cidade-origem"
          />
        </Col>
        <Col md={4} className="my-3">
          <Input
            label="UF *"
            disabled={disableProvince || !data.origin?.country}
            value={data.origin?.province}
            onChange={event =>
              setData(old => ({
                ...old,
                origin: {
                  ...old.origin,
                  province: event.target.value,
                },
              }))
            }
            error={errorsGro[`origin.province`]}
            id="input-gro-uf-origem"
          />
        </Col>
        <Col md={6}>
          <Input
            label="Latitude"
            disabled={true}
            value={data.origin?.lat}
            error={errorsGro[`origin.lat`]}
            tooltip="Selecione um endereço sugerido para obter dados de latitude e longitude"
            id="input-gro-latitude-origem"
          />
        </Col>
        <Col md={6}>
          <Input
            label="Longitude"
            disabled={true}
            value={data.origin?.lng}
            error={errorsGro[`origin.lng`]}
            tooltip="Selecione um endereço sugerido para obter dados de latitude e longitude"
            id="input-gro-longitude-origem"
          />
        </Col>
        <Col md={6}>
          <Input
            type="datetime-local"
            value={data.origin?.startSchedule}
            label="Data Início da coleta *"
            onChange={event => {
              let isValid = validateDateTime(event?.target?.value);
              if (isValid) {
                setData(old => ({
                  ...old,
                  origin: {
                    ...old.origin,
                    startSchedule: event?.target?.value,
                  },
                }));
                setErrorsGro(old => ({
                  ...old,
                  ['origin.startSchedule']: null,
                }));
              } else {
                setErrorsGro(old => ({
                  ...old,
                  ['origin.startSchedule']: 'Data inválida',
                }));
                setData(old => ({
                  ...old,
                  origin: {
                    ...old.origin,
                    startSchedule: event?.target?.value,
                  },
                }));
              }
            }}
            error={errorsGro[`origin.startSchedule`] || errorsGro.origin}
            id="input-gro-data-inicio-origem"
          />
        </Col>
        <Col md={6}>
          <Input
            type="datetime-local"
            label="Data máxima da coleta *"
            value={data.origin?.date}
            onChange={event => {
              let isValid = validateDateTime(event?.target?.value);
              if (isValid) {
                setData(old => ({
                  ...old,
                  origin: {
                    ...old.origin,
                    date: event?.target?.value,
                  },
                }));
                setErrorsGro(old => ({ ...old, ['origin.date']: null }));
              } else {
                setErrorsGro(old => ({
                  ...old,
                  ['origin.date']: 'Data inválida',
                }));
                setData(old => ({
                  ...old,
                  origin: {
                    ...old.origin,
                    date: event?.target?.value,
                  },
                }));
              }
            }}
            error={errorsGro[`origin.date`]}
            id="input-gro-data-maxima-origem"
          />
        </Col>
      </Row>
    </div>
  );
}
