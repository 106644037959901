import React from 'react';

import './style.css';

function ConversationItem({ deal, click, active }) {
  return (
    <div
      className={`conversation-list-item ${active ? 'active' : ''}`}
      onClick={() => click(deal)}
    >
      <p className="conversation-snippet blue">Carga #{deal.load_id}</p>
      <p className="conversation-snippet blue">
        Filial da Carga {deal?.branch?.name}
      </p>
      <p className="conversation-snippet">{deal.driver_name}</p>
      <p className="conversation-snippet">
        {deal.origin_city}-{deal.origin_province} &rarr;&nbsp;
        {deal.last_destination_city}-{deal.last_destination_province}
      </p>

      {deal.unreadCount ? (
        <div
          title={`${
            deal.unreadCount === 1
              ? `${deal.unreadCount} mensagem não lida`
              : `${deal.unreadCount} mensagens não lidas`
          }`}
          className="notification"
        >
          {deal.unreadCount}
        </div>
      ) : null}
    </div>
  );
}

export default ConversationItem;
