import React, { useState, useEffect } from 'react';

import api from 'services/api';

import { useModal } from 'hooks';

import { Text, Modal } from 'v3/components';
import { useSnackbar } from 'v3/components/Snackbar';

import { useCompany } from '../../shared/context';
import SharedData from '../../shared/Section';
import Inputs from '../../shared/Integration';
import Editable from '../../shared/Editable';

import Fretebras from './Fretebras';
import Truckpad from './Truckpad';

import LogModal from '../../shared/Modals/Logs';

import { validate } from '../../shared/validator';

export default function Integration() {
  const company = useCompany();
  const snackbar = useSnackbar();
  const logsModal = useModal();

  const [isEditing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  async function save() {
    const isValid = await validate(
      'integrations',
      { integrations: company.data.integrations },
      company.setErrors
    );
    if (!isValid) {
      snackbar.show('Verifique os campos obrigatórios!', { type: 'error' });
      return;
    }

    setLoading(true);

    try {
      await api.put(`v3/company/${company.data.id}/integrations`, {
        integrations: company.data.integrations,
      });
      company.setPrevCompany(company.data);
      snackbar.show(<Text>Salvo com sucesso</Text>, { type: 'success' });
    } catch (error) {
      snackbar.show(<Text>Erro ao salvar</Text>, { type: 'error' });
      company.cancelEditing();
    } finally {
      setEditing(false);
      setLoading(false);
    }
  }

  useEffect(() => {
    function onPressedKey(e) {
      if (isEditing) {
        if (e.key === 'Enter') {
          save();
        }
      } else if (e.key === 'Escape') {
        setEditing(false);
      }
    }

    window.addEventListener('keyup', onPressedKey);

    return () => {
      window.removeEventListener('keyup', onPressedKey);
    };
  });

  return (
    <>
      <LogModal isOpen={logsModal.isOpen} onClose={logsModal.close} />
      <SharedData
        Title={
          <>
            <Text type="header" color="dark">
              Integrações
            </Text>

            <Text
              className="ml-2"
              type="label"
              color="gray"
              transform="underline"
              clicklable
              onClick={() => logsModal.open()}
            >
              ver logs
            </Text>
          </>
        }
        HeaderRightComponent={
          <>
            <Editable
              isEditing={isEditing}
              setEditing={setEditing}
              onSave={save}
              isLoading={loading}
            />
          </>
        }
        loading={company.isFetching}
      >
        {isEditing ? (
          <Inputs />
        ) : (
          <>
            <Fretebras />
            <hr />
            <Truckpad />
            <hr />
          </>
        )}
      </SharedData>
    </>
  );
}
