import { Row } from 'react-bootstrap';
import { DataTable, Modal, Text } from 'v3/components';
import React from 'react';

const { default: styled } = require('styled-components');

export const ChecklistContainer = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 0;
  max-height: calc(55px * 3);
  overflow-y: auto;
`;

export const ChecklistItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    display: flex;
    gap: 8px;
    align-items: center;
  }
`;

export const StyledModal = styled(Modal)`
  && .modal-dialog {
    @media only screen and (min-width: 728px) {
      max-width: ${({ $width }) => `${$width} !important`};
    }

    @media only screen and (max-width: 728px) {
      max-width: 100% !important;
    }
  }
  && .modal-title {
    width: 100%;
  }

  && .modal-header {
    align-items: center;
  }

  && .modal-header .close {
    margin: 0;
    margin-left: 20px;
  }
`;

export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledRow = styled(Row)`
  gap: 12px 0;
`;

export function StyledText({
  children,
  bold,
  as = 'div',
  color = '#464E5F',
  style,
}) {
  return (
    <Text
      color={color}
      type="regular"
      weight={bold && 500}
      as={as}
      style={style}
    >
      {children}
    </Text>
  );
}

export const QuestionContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  width: 100%;
  gap: 8px;
`;

export const AnswerContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

export const StyledDataTable = styled(DataTable)`
  && div.table-responsive > table > thead {
    display: none;
  }

  && .table td,
  .table th {
    vertical-align: middle;
  }
`;

export const Backdrop = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;

  > * {
    background-color: transparent;
    transition: opacity 0.3s ease-in;

    &:hover {
      opacity: 0.8;
    }
  }
`;

export const DateTime = styled.div`
  background-color: rgba(0, 0, 0, 0.35);
  padding: 5px;
  position: absolute;
  left: 0;
  bottom: 0;
`;
