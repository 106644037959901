import React, { useState } from 'react';

import Input from 'components/Input';
import Button from 'components/Button';

import axios from 'axios';
import config from './../../../config';

export default function FormCadastroPerfil(props) {
  const [inputName, setInputName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const saveProfile = async () => {
    if (inputName !== '') {
      try {
        setLoading(true);
        console.log(inputName);

        let response = await axios.post(config.server + 'users-profiles', {
          name: inputName
        });
        if (response.status === 200) {
          setInputName('');
          props.setSnackbar({
            type: 'success',
            message: `Perfil ${response.data.name} cadastrado com suscesso!`,
            display: true
          });
          props.toggleDrawer(true);
        }
      } catch (e) {
        console.log(e);
        props.setSnackbar({
          type: 'danger',
          message: e.response.data[0].message,
          display: true
        });
      } finally {
        setLoading(false);
      }
    } else {
      setError(true);
      props.setSnackbar({
        type: 'danger',
        message: 'Preencha o nome do perfil',
        display: true
      });
    }
  };

  return (
    <>
      <div style={{ padding: 15 }}>
        {props.open && (
          <div
            style={{
              position: 'absolute',
              height: '40px',
              width: '40px',
              marginLeft: '-55px',
              borderTopLeftRadius: '20px',
              borderBottomLeftRadius: '20px',
              textAlign: 'center',
              lineHeight: '40px',
              color: '#fff',
              visibility: 'visible',
              backgroundColor: '#5FB1F3',
              cursor: 'pointer'
            }}
            onClick={() => props.toggleDrawer()}
          >
            <i className="fas fa-arrow-right" />
          </div>
        )}

        <h2
          style={{
            padding: 0,
            fontWeight: '400',
            fontSize: '18px',
            color: '#76838F'
          }}
        >
          Adicionar perfil de usuário
        </h2>

        <Input
          uppercase={1}
          label="Nome do perfil"
          value={inputName}
          error={error}
          onChange={e => {
            setInputName(e.target.value);
            setError(false);
          }}
          inputProps={{ 'aria-label': 'Nome' }}
        />

        <br />
        <div
          style={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '15px'
          }}
        >
          <Button onClick={() => saveProfile()} loading={loading}>
            Cadastrar
          </Button>
        </div>
      </div>
    </>
  );
}
