import React, { useState, useEffect } from 'react';

import Card from 'v3/components/Card';
import Text from 'v3/components/Text';
import Input from 'v3/components/Input';
import Upload from 'v3/components/Upload';
import { FaImage } from 'react-icons/fa';

import { Row, Col } from 'react-bootstrap';

import { IssuerContext } from '../controller';

const [, useIssuer] = IssuerContext;

export default function Data() {
  const issuer = useIssuer();

  const [name, setName] = useState();
  const [document, setDocument] = useState();
  const [registration, setRegistration] = useState(); // Inscrição estadual
  const [tradingName, setTradingName] = useState();
  const [phone, setPhone] = useState();
  const [rntrc, setRntrc] = useState();
  const [logo, setLogo] = useState();

  useEffect(() => {
    issuer.setData(old => ({
      ...old,
      name,
      document,
      ie: registration,
      trading_name: tradingName,
      phone,
      rntrc,
      logo,
    }));
  }, [name, document, registration, tradingName, phone, rntrc, logo]);

  return (
    <Card
      header={
        <Text color="#464E5F" type="header">
          Dados do emissor
        </Text>
      }
    >
      <Col>
        <Row>
          <Col className="mb-3">
            <Input
              label="Nome *"
              value={name}
              onChange={event => setName(event.target.value)}
              error={issuer.errors.name}
            />
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <Input
              label="Nome fantasia *"
              value={tradingName}
              onChange={event => setTradingName(event.target.value)}
              error={issuer.errors.trading_name}
            />
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <Input
              label="CNPJ *"
              value={document}
              masked
              mask={[
                /[0-9]/,
                /[0-9]/,
                '.',
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                '.',
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                '/',
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                '-',
                /[0-9]/,
                /[0-9]/,
              ]}
              onChange={event => setDocument(event.target.value)}
              error={issuer.errors.document}
            />
          </Col>
          <Col className="mb-3">
            <Input
              label="Inscrição estadual *"
              value={registration}
              maxlength="14"
              onChange={event => setRegistration(event.target.value)}
              error={issuer.errors.ie}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={6} className="mb-3">
            <Input
              label="RNTRC *"
              value={rntrc}
              maxlength="8"
              onChange={event => setRntrc(event.target.value)}
              error={issuer.errors.rntrc}
            />
          </Col>
          <Col xs={6} className="mb-3">
            <Input
              label="Telefone *"
              value={phone}
              onChange={event => setPhone(event.target.value)}
              error={issuer.errors.phone}
              masked
              mask={[
                '(',
                /[0-9]/,
                /\d/,
                ')',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col cs={12} className="mb-3">
            <Text type="label" color="#464E5F">
              Upload Logo
            </Text>
            <Upload
              sizeIcon={50}
              icon={<FaImage size={50} />}
              onChange={event => setLogo(event.target.files)}
              value={logo}
              id="imgLogo"
              name="logo"
              accept={['.png', '.jpg']}
              label="Arquivo máximo de 1MB"
            />
          </Col>
        </Row>
      </Col>
    </Card>
  );
}
