import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { RiAlertLine, RiEditBoxLine } from 'react-icons/ri';
import { BsArrowReturnRight, BsUpload } from 'react-icons/bs';
import { BadgeActive, Text } from 'v3/components';
import { QuestionOptionItemComponent } from '../components/QuestionOptionItem';
import {
  QuestionDetailContainer,
  QuestionsSectionContainer,
} from '../shared/styles';
import { QuestionItem } from '../components/RegisterComponents/QuestionItem';

export function GeneralInfo({ checklist }) {
  return (
    <Row>
      <Col xs={12} className="mb-4">
        <Text weight={500} color="dark" type="header">
          Dados gerais
        </Text>
      </Col>
      <Col md={4} xs={12}>
        <Text weight={500} color="dark" type="regular">
          Título:
        </Text>
        <Text weight={400} color="dark" type="body" as="p">
          {checklist?.data?.name}
        </Text>
      </Col>
      <Col md={8} xs={12}>
        <Text weight={500} color="dark" type="regular">
          Descrição do checklist:
        </Text>
        <Text weight={400} color="dark" type="body" as="p" style={{ 'word-wrap': 'break-word'}}>
          {checklist?.data?.description}
        </Text>
      </Col>
      <Col md={4} xs={12}>
        <Text weight={500} color="dark" type="regular">
          Tipo de checklist:
        </Text>
        <Text weight={400} color="dark" type="body" as="p">
          {checklist?.data?.checklistType?.name}
        </Text>
      </Col>
      <Col md={4} xs={12}>
        <Text weight={500} color="dark" type="regular">
          Checklist Obrigatório:
        </Text>
        <Text weight={400} color="dark" type="body" as="p">
          {checklist?.data?.required ? 'Sim' : 'Não'}
        </Text>
      </Col>
      <Col md={4} xs={12}>
        <Text weight={500} color="dark" type="regular">
          Motorista responde pelo APP:
        </Text>
        <Text weight={400} color="dark" type="body" as="p">
          {checklist?.data?.availableForDriver ? 'Sim' : 'Não'}
        </Text>
      </Col>
      <Col md={4} xs={12}>
        <Text weight={500} color="dark" type="regular">
          Apresentado no painel logístico:
        </Text>
        <Text weight={400} color="dark" type="body" as="p">
          {checklist?.data?.availableLogisticPanel ? 'Sim' : 'Não'}
        </Text>
      </Col>
      <Col md={4} xs={12}>
        <Text weight={500} color="dark" type="regular">
          Requer assinatura motorista:
        </Text>
        <Text weight={400} color="dark" type="body" as="p">
          {checklist?.data?.requiredDriverSign ? 'Sim' : 'Não'}
        </Text>
      </Col>
      <Col md={4} xs={12}>
        <Text weight={500} color="dark" type="regular">
          Requer assinatura inspetor:
        </Text>
        <Text weight={400} color="dark" type="body" as="p">
          {checklist?.data?.requiredInspectSign ? 'Sim' : 'Não'}
        </Text>
      </Col>
      <Col md={4} xs={12}>
        <Text weight={500} color="dark" type="regular">
          Checklist padrão filial:
        </Text>
        <Text weight={400} color="dark" type="body" as="p">
          {checklist?.data?.default ? 'Sim' : 'Não'}
        </Text>
      </Col>
      <Col md={4} xs={12}>
        <Text weight={500} color="dark" type="regular">
          Status do checklist:
        </Text>
        <span className="d-block">
          <BadgeActive
            pill
            light
            type={checklist?.data?.active ? 'success' : 'error'}
          >
            <Text type="label" weight={500}>
              {checklist?.data?.active ? 'Ativo' : 'Inativo'}
            </Text>
          </BadgeActive>
        </span>
      </Col>
      <Col md={4} xs={12}>
        <Text weight={500} color="dark" type="regular">
          Possui CNPJ(s) vinculado(s)?
        </Text>
        <Text weight={400} color="dark" type="body" as="p">
          {checklist?.data?.clients?.length > 0 ? 'Sim' : 'Não'}
        </Text>
      </Col>
      <Col md={4} xs={12}>
        <Text weight={500} color="dark" type="regular">
          Possui negociação vinculada?
        </Text>
        <Text weight={400} color="dark" type="body" as="p">
          {checklist?.data?.negotiation ? 'Sim' : 'Não'}
        </Text>
      </Col>
      <Col md={4} xs={12}>
        <Text weight={500} color="dark" type="regular">
          Possui veículo(s) vinculado(s)?
        </Text>
        <Text weight={400} color="dark" type="body" as="p">
          {checklist?.data?.vehicleType?.length > 0 ? 'Sim' : 'Não'}
        </Text>
      </Col>
      <Col md={4} xs={12}>
        <Text weight={500} color="dark" type="regular">
          Possui carroceria(s) vinculada(s)?
        </Text>
        <Text weight={400} color="dark" type="body" as="p">
          {checklist?.data?.vehicleType?.length > 0 ? 'Sim' : 'Não'}
        </Text>
      </Col>
    </Row>
  );
}

export function ClientInfo({ checklist }) {
  return (
    <Row>
      <Col xs={12} className="mt-2 mb-4">
        <Text weight={500} color="dark" type="header">
          Dados do cliente
        </Text>
      </Col>
      <Col md={4} xs={12}>
        <Text weight={500} color="dark" type="regular">
          Cliente:
        </Text>
        <Text weight={400} color="dark" type="body" as="p">
          {`${checklist?.data?.clients[0]?.cpfCnpj} - ${checklist?.data?.clients[0]?.socialName}`}
        </Text>
      </Col>
      <Col md={4} xs={12}>
        <Text weight={500} color="dark" type="regular">
          Negociação:
        </Text>

        <Text weight={400} color="dark" type="body" as="p">
          {checklist?.data?.negotiation?.name}
        </Text>
      </Col>
      <Col md={4} xs={12}>
        <Text weight={500} color="dark" type="regular">
          Status Negociação:
        </Text>
        <span className="d-block">
          <BadgeActive
            pill
            light
            type={checklist?.data?.negotiation?.active ? 'success' : 'error'}
          >
            <Text type="label" weight={500}>
              {checklist?.data?.negotiation?.active ? 'Ativa' : 'Inativa'}
            </Text>
          </BadgeActive>
        </span>
      </Col>
    </Row>
  );
}

export function ClientsInfo({ checklist }) {
  return (
    <Row>
      <Col xs={12} className="mt-2 mb-4">
        <Text weight={500} color="dark" type="header">
          Dados dos clientes
        </Text>
      </Col>
      {checklist?.data?.clients?.map(client => {
        return (
          <Col md={4} xs={12} key={client?.personId}>
            <Text weight={400} color="dark" type="body" as="p">
              {`${client?.cpfCnpj} - ${client?.socialName?.toUpperCase()}`}
            </Text>
          </Col>
        );
      })}
    </Row>
  );
}

export function VehiclesInfo({ checklist }) {
  return (
    <Row>
      <Col xs={12} className="mt-2 mb-4">
        <Text weight={500} color="dark" type="header">
          Dados do(s) veículo(s)
        </Text>
      </Col>
      {checklist?.data?.vehicleType?.map(vehicle => {
        return (
          <Col md={4} xs={12} key={vehicle?.id}>
            <Text weight={500} color="dark" type="regular">
              Tipo de veículo
            </Text>
            <Text weight={400} color="dark" type="body" as="p">
              {vehicle?.name}
            </Text>
          </Col>
        );
      })}
    </Row>
  );
}

export function VehiclesBodyInfo({ checklist }) {
  return (
    <Row>
      <Col xs={12} className="mt-2 mb-4">
        <Text weight={500} color="dark" type="header">
          Dados da(s) carroceria(s)
        </Text>
      </Col>
      {checklist?.data?.vehicleBodyType?.map(body => {
        return (
          <Col md={4} xs={12} key={body?.id}>
            <Text weight={500} color="dark" type="regular">
              Tipo de carroceria
            </Text>
            <Text weight={400} color="dark" type="body" as="p">
              {body?.name}
            </Text>
          </Col>
        );
      })}
    </Row>
  );
}

export function ImplementsInfo({ checklist }) {
  return (
    <Row>
      <Col xs={12} className="mt-2 mb-4">
        <Text weight={500} color="dark" type="header">
          Dados do(s) implementos(s)
        </Text>
      </Col>
      {checklist?.data?.implementType?.map(body => {
        return (
          <Col md={4} xs={12} key={body?.id}>
            <Text weight={500} color="dark" type="regular">
              Tipo de implemento
            </Text>
            <Text weight={400} color="dark" type="body" as="p">
              {body?.name}
            </Text>
          </Col>
        );
      })}
    </Row>
  );
}

export function BranchOfficesInfo({ checklist }) {
  return (
    <Row>
      <Col xs={12} className="mt-2 mb-4">
        <Text weight={500} color="dark" type="header">
          Dados da(s) filial(s)
        </Text>
      </Col>
      {checklist?.data?.branchOffices?.map(({ id, name }) => (
        <Col md={4} xs={12} key={id}>
          <Text weight={500} color="dark" type="regular">
            Filial
          </Text>
          <Text weight={400} color="dark" type="body" as="p">
            {name}
          </Text>
        </Col>
      ))}
    </Row>
  );
}

export function QuestionsSection({ checklist }) {
  return (
    <Row>
      <Col xs={12} className="my-2">
        <Text weight={500} color="dark" type="header">
          Lista de perguntas
        </Text>
      </Col>
      <Col md={12}>
        <QuestionsSectionContainer>
          {checklist?.data?.checklistsQuestions?.map(question => {
            return (
              <QuestionDetailContainer key={question?.question?.id}>
                <div className="contentContainer">
                  <QuestionItem question={question?.question} />
                  <div className="questionOptions">
                    {question?.required && (
                      <QuestionOptionItemComponent
                        icon={RiAlertLine}
                        content="Obrigatória"
                      />
                    )}
                    {question?.question?.needAttachment && (
                      <QuestionOptionItemComponent
                        icon={BsUpload}
                        content="Necessário anexo"
                      />
                    )}
                    {question?.question?.subQuestion && (
                      <QuestionOptionItemComponent
                        icon={BsArrowReturnRight}
                        content=" Subpergunta"
                      />
                    )}
                    {question?.question?.createOccurrence && (
                      <QuestionOptionItemComponent
                        content="Gera ocorrência"
                        icon={RiEditBoxLine}
                      />
                    )}
                  </div>
                </div>
              </QuestionDetailContainer>
            );
          })}
        </QuestionsSectionContainer>
      </Col>
    </Row>
  );
}
