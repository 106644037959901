import React, { useEffect, useState } from 'react';

import { Row } from 'react-bootstrap';

import Text from 'v3/components/Text';
import Loader from 'v3/components/Loader';

import { useSnackbar } from 'v3/components/Snackbar';

import api from 'services/api';

import * as Styled from './styles';
import { verifyMostAcessedPermissions } from './validator';

function MostAccessed() {
  const snackbar = useSnackbar();

  const [mostAccessed, setMostAccessed] = useState([]);
  const [loading, setLoading] = useState(false);

  async function getUserMostAccessed() {
    setLoading(true);
    try {
      const response = await api.get(`v3/user/most-accessed`);

      validateMostAcessedPermission(response.data);
    } catch (err) {
      snackbar.show(<Text>Erro ao buscar seus mais acessados</Text>, {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }

  const validateMostAcessedPermission = mostAccessed => {
    const validAcessedRoutes = verifyMostAcessedPermissions(mostAccessed);
    setMostAccessed(validAcessedRoutes);
  };

  useEffect(() => {
    getUserMostAccessed();
  }, []);

  return (
    <>
      <Row
        style={{ marginLeft: '124px', marginTop: '21px' }}
        className="mb-2 mt-2"
      >
        <Text color="primary" weight="700" fontSize="21.14px">
          acesso rápido
        </Text>

        {!loading ? (
          mostAccessed.length > 0 ? (
            mostAccessed.map((accessed, index) => {
              return (
                <Styled.LinkTo
                  href={accessed.path}
                  withoutIcon
                  to={`${accessed.path}`}
                  target="_blank"
                  key={`${accessed.path}-${index}`}
                >
                  <Styled.BadgeLink
                    pill
                    outlined
                    className="ml-3 px-3"
                    variant="secondary"
                  >
                    <Styled.Title color="dark" weight="700">
                      {accessed.name}
                    </Styled.Title>
                  </Styled.BadgeLink>
                </Styled.LinkTo>
              );
            })
          ) : (
            <Text className="ml-4 mt-1">Não há mais acessados ainda!</Text>
          )
        ) : (
          <div className="d-flex justify-content-center mt-1 ml-4">
            <Loader size="md" loading={loading} />
          </div>
        )}
      </Row>
    </>
  );
}

export default MostAccessed;
