/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Text from 'v3/components/Text';
import Modal from 'v3/components/Modal';
import Button from 'v3/components/Button';
import Select from 'v3/components/Select';
import TextArea from 'v3/components/TextArea';
import { useSnackbar } from 'v3/components/Snackbar';

import { cancelReasons } from './types';

export default function CancelLoad({ loadId, show, handleClose, onConfirm }) {
  const history = useHistory();
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [reasonSelected, setReasonSelected] = useState({});
  const [reasonDescription, setReasonDescription] = useState('');
  const [isConfirmButtonDisabled, setConfirmButtonDisabled] = useState(false);

  function close() {
    setReasonSelected({});
    setReasonDescription('');
    handleClose();
  }

  return (
    <Modal
      show={show}
      onHide={close}
      heading="Cancelamento de Carga"
      body={
        <>
          <Select
            label="Motivo"
            value={reasonSelected}
            onChange={event => {
              setReasonSelected(event);
            }}
            options={cancelReasons}
            isDisabled={loading}
          />
          <TextArea
            resizable={false}
            label="Explicação do motivo"
            value={reasonDescription}
            onChange={e => setReasonDescription(e.target.value)}
            disabled={loading}
          />
        </>
      }
      footer={
        <>
          <Button variant="secondary" onClick={close} disabled={loading || isConfirmButtonDisabled}>
            <Text weight={500} type="regular">
              Voltar
            </Text>
          </Button>
          <Button
            variant="primary"
            disabled={isConfirmButtonDisabled || loading}
            loading={loading}
            onClick={() =>
              onConfirm({
                setLoading,
                setConfirmButtonDisabled,
                reasonDescription,
                reasonSelected,
                snackbar,
                history,
                loadId,
              })
            }
          >
            <Text weight={500} type="regular">
              Cancelar carga
            </Text>
          </Button>
        </>
      }
    />
  );
}
