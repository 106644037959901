/* eslint-disable no-shadow */
import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import compareDesc from 'date-fns/compareDesc';
import locationApi from 'services/apis/location';
import api from 'services/api';
import { Row, Col } from 'react-bootstrap';
import { FaBatteryQuarter, FaBatteryHalf, FaBatteryFull } from 'react-icons/fa';
import Text from 'v3/components/Text';
import MapRaw from 'v3/components/Map';
import Marker from 'v3/components/Map/Marker';
import MarkerTemperature from 'v3/components/Map/MarkerTemperature';
import MarkerLetter from 'v3/components/Map/MarkerLetter';
import { useSnackbar } from 'v3/components/Snackbar';
import { useTravel } from 'v3/pages/Travel';
import { formatDateTime, formatNumber } from 'v3/utils/formatter';
import theme from 'v3/theme';
import Progress from './Progress';
import StatusColor from './StatusColor';
import Speed from './Speed';
import Polyline from './Polyline';
import Alerts from '../Alerts';
import Temperature from './Temperature';

export default function Map() {
  const params = useParams();
  const travel = useTravel();
  const snackbar = useSnackbar();
  const [locations, setLocations] = useState([]);
  const [map, setMap] = useState();
  const [maps, setMaps] = useState();
  const [coords, setCoords] = useState();
  const [latLngTemperature, setLatLngTemperature] = useState();
  const [pathEnabled, setPathEnabled] = useState(true);
  const [markersEnabled, setMarkersEnabled] = useState(true);
  const [mapVisible, setMapVisible] = useState(false);
  const [locationSources, setLocationSources] = useState({});
  const [alerts, setAlerts] = useState([]);
  const [lastLocation, setLastLocation] = useState();
  const [polylinePath, setPolylinePath] = useState();

  async function fetchAlert() {
    try {
      const response = await api.get(`travel/${params.id}/alerts`);

      setAlerts(response.data);
    } catch (error) {
      // Handle exception
    }
  }

  useEffect(() => {
    fetchAlert();
  }, []);

  const { origin, destinations } = useMemo(
    () => ({
      origin: travel.data?.loads?.loadOrigins?.[0],
      destinations: travel.data?.loads?.loadDestinations,
    }),
    [travel.data]
  );

  const middleDestinations = useMemo(
    () => destinations?.filter((_, index) => index < destinations?.length - 1),
    [destinations]
  );
  const lastDestination = useMemo(
    () => destinations?.[destinations?.length - 1],
    [destinations]
  );

  useEffect(() => {
    async function fetchTravelLocations() {
      try {
        const origin = travel.data?.loads?.loadOrigins?.[0];

        const lastDestination =
          travel.data?.loads?.loadDestinations?.[
            travel.data.loads?.loadDestinations?.length - 1
          ];

        const response = await locationApi.get(
          `range/cpf/${travel.data?.driver?.cpfCnpj}`,
          {
            params: {
              start: origin?.scheduledTime,
              end: moment(lastDestination?.scheduledTime)
                .add(3, 'days')
                .toDate(),
              attended_id: travel.data.id,
              all_sources: true,
            },
          }
        );

        const locationsFiltered = response.data.locations
          .filter(item => {
            const isValidSpeed = item.speed >= 0;
            if (locationSources.risk_manager_id) {
              return (
                isValidSpeed &&
                item.risk_manager_id === locationSources.risk_manager_id
              );
            }

            return isValidSpeed;
          })
          .map(l => ({
            ...l,
            lat: l?.latitude,
            lng: l?.longitude,
            battery: l?.battery_level,
          }));
        setLocations(locationsFiltered);
        setLastLocation(locationsFiltered.slice(-1)[0]);
      } catch (error) {
        snackbar.show(<Text>Viagem não possui localizações disponíveis</Text>, {
          type: 'warning',
        });
        //
      } finally {
        //
      }
    }

    if (!travel.isLoading && travel.data.id) {
      fetchTravelLocations();
    }
  }, [
    travel.data.driver,
    travel.data?.loads?.loadOrigins,
    travel.data?.loads?.loadDestinations,
    travel.isLoading,
  ]);

  useEffect(() => {
    if (
      maps &&
      map &&
      travel?.data?.loads?.loadOrigins?.length > 0 &&
      travel?.data?.loads?.loadDestinations?.length > 0
    ) {
      const bounds = new maps.LatLngBounds();
      const bound = [
        ...locations,
        travel.data?.loads?.loadOrigins[0],
        ...travel.data?.loads?.loadDestinations,
      ].filter(location => location.lat && location.lng);

      bound.forEach(location => {
        bounds.extend(new maps.LatLng(location.lat, location.lng));
      });

      if (bound?.length === 0) {
        bounds.extend(new maps.LatLng(1.5939188, -54.6107855));
        bounds.extend(new maps.LatLng(-30.3966149, -55.913429));
      }

      map.fitBounds(bounds);

      if (maps?.geometry) {
        setTravelPolyline();
      }
    }
  }, [locations, map, maps]);

  function setTravelPolyline() {
    let isRouteOriginSameAsTravelOrigin = false;
    let areRouteDestinationsSameAsTravelDestinations = false;
    let areRouteDestinationsSameAmountAsTravelDestinations = false;
    if (travel?.data?.loads?.route) {
      isRouteOriginSameAsTravelOrigin = compareOriginsCoords(
        {
          lat: travel?.data?.loads?.route?.origin?.lat,
          lng: travel?.data?.loads?.route?.origin?.lng,
        },
        {
          lat: travel?.data?.loads?.loadOrigins[0]?.lat,
          lng: travel?.data?.loads?.loadOrigins[0]?.lng,
        }
      );
      areRouteDestinationsSameAsTravelDestinations = compareDestinationsCoords(
        travel?.data?.loads?.route?.destinations,
        travel?.data?.loads?.loadDestinations
      );

      areRouteDestinationsSameAmountAsTravelDestinations =
        travel?.data?.loads?.route?.destinations?.length ===
        travel?.data?.loads?.loadDestinations?.length;
    }
    const travelPlacesUpdated = checkIfUpdatedTravelPlaces();
    if (
      travel?.data?.loads?.route?.polyline &&
      isRouteOriginSameAsTravelOrigin &&
      areRouteDestinationsSameAmountAsTravelDestinations &&
      areRouteDestinationsSameAsTravelDestinations &&
      travelPlacesUpdated === false
    ) {
      setPolylinePath(
        maps?.geometry?.encoding?.decodePath(
          travel?.data?.loads?.route?.polyline
        )
      );
    } else if (
      ((travel?.data?.loads?.route?.polyline && travelPlacesUpdated) ||
        travel?.data?.tracking?.polyline) &&
      travel?.data?.tracking?.polyline
    ) {
      setPolylinePath(
        maps?.geometry?.encoding?.decodePath(travel?.data?.tracking?.polyline)
      );
    }
  }

  function checkIfUpdatedTravelPlaces() {
    const wasOriginUpdated = compareDesc(
      new Date(travel?.data?.loads?.loadOrigins[0]?.updatedAt),
      new Date(travel?.data?.loads?.loadOrigins[0]?.createdAt)
    );
    const updatedStatus = -1;
    const someDestinationUpdated = checkDestinationsDates(
      travel?.data?.loads?.loadDestinations
    );
    return wasOriginUpdated === updatedStatus || someDestinationUpdated;
  }

  function checkDestinationsDates(destinations) {
    const destinationsStatus = [];
    const updatedStatus = -1;
    destinations.forEach(destination => {
      return destinationsStatus.push(
        compareDesc(
          new Date(destination.updatedAt),
          new Date(destination.createdAt)
        )
      );
    });

    return destinationsStatus.some(item => item === updatedStatus);
  }

  function compareOriginsCoords(routeOrigins, cargOrigins) {
    const routeCoords = new maps.LatLng(routeOrigins.lat, routeOrigins.lng);
    const cargCoords = new maps.LatLng(cargOrigins.lat, cargOrigins.lng);

    return routeCoords.equals(cargCoords);
  }

  function compareDestinationsCoords(routeDestinations, cargDestinations) {
    const cargDestinationsSize = cargDestinations?.length;
    const comparationsResults = [];
    for (let i = 0; i < cargDestinationsSize; i++) {
      const routeCoords = new maps.LatLng(
        routeDestinations[i]?.lat,
        routeDestinations[i]?.lng
      );
      const cargCoords = new maps.LatLng(
        cargDestinations[i]?.lat,
        cargDestinations[i]?.lng
      );

      comparationsResults.push(routeCoords.equals(cargCoords));
    }
    return comparationsResults.every(item => item === true);
  }

  function onMapLoaded(_map, _maps) {
    setMap(_map);
    setMaps(_maps);
  }

  let geodesicPolyline;

  function afterMapLoadChanges(locs) {
    geodesicPolyline = new maps.Polyline({
      path: polylinePath,
      geodesic: true,
      strokeColor: '#1c4b75',
      strokeOpacity: 0.7,
      strokeWeight: 2,
    });
    return (
      <>
        <Polyline
          map={map}
          maps={maps}
          markers={polylinePath}
          pathEnabled={pathEnabled}
          geodesicPolyline={geodesicPolyline}
        />
        <Polyline
          map={map}
          maps={maps}
          markers={locs}
          pathEnabled={pathEnabled}
          geodesicPolyline={geodesicPolyline}
          strokeColor="#fbad26"
        />
      </>
    );
  }

  useEffect(() => {
    setMapVisible(false);

    setTimeout(() => {
      setMapVisible(true);
    }, 1);
  }, [pathEnabled]);

  if (travel.isLoading) {
    return null;
  }

  function handleClickVelocity(data) {
    if (data) {
      const bounds = new maps.LatLngBounds();

      bounds.extend(new maps.LatLng(data?.latitude, data?.longitude));
      map.fitBounds(bounds);
      map.setZoom(15);
    }
  }
  function handleClickTemperature(data) {
    if (data) {
      const bounds = new maps.LatLngBounds();

      bounds.extend(new maps.LatLng(data?.latitude, data?.longitude));
      map.fitBounds(bounds);
      map.setZoom(15);
    }
  }

  return (
    <>
      <Row>
        <Col>
          <Col style={{ position: 'relative' }}>
            {mapVisible && (
              <MapRaw
                zoom={8}
                height={850}
                width="100%"
                position={
                  travel.data?.loads?.loadOrigins?.[0].lat
                    ? {
                        lat: travel.data?.loads?.loadOrigins[0].lat,
                        lng: travel.data?.loads?.loadOrigins[0].lng,
                      }
                    : { lat: -25.453785, lng: -49.2529808 }
                }
                onGoogleApiLoaded={({ map, maps }) => onMapLoaded(map, maps)}
              >
                {maps && afterMapLoadChanges(locations)}
                {alerts.map(alert => {
                  const metadata = JSON.parse(alert?.metadata);
                  const alertLat = metadata?.location?.latitude;
                  const alertLng = metadata?.location?.longitude;
                  return (
                    <MarkerLetter
                      background="#FFC107"
                      lat={alertLat}
                      lng={alertLng}
                      color="#fff"
                    />
                  );
                })}

                {origin?.lat && origin?.lng && markersEnabled && (
                  <MarkerLetter
                    background="#0bb7af"
                    lat={origin.lat}
                    lng={origin.lng}
                    color="#fff"
                  />
                )}
                {middleDestinations &&
                  markersEnabled &&
                  middleDestinations.map(destinations => (
                    <MarkerLetter
                      background={theme.colors.light_blue}
                      lat={destinations?.lat}
                      lng={destinations?.lng}
                      color="#fff"
                    />
                  ))}
                {lastDestination?.lat &&
                  lastDestination?.lng &&
                  markersEnabled && (
                    <MarkerLetter
                      background="#F64E60"
                      lat={lastDestination.lat}
                      lng={lastDestination.lng}
                      color="#fff"
                    />
                  )}
                {lastLocation?.latitude &&
                  lastLocation?.longitude &&
                  lastLocation?.captured_at &&
                  moment().diff(moment(lastLocation.captured_at), 'hours') <
                  24 ? (
                    <MarkerLetter
                    background={lastLocation?.battery_level ? "app.svg" : "current.svg"}
                    sizeIcon={30}
                    lat={lastLocation?.latitude}
                    lng={lastLocation?.longitude}
                    color="#fff"
                    title="A localização do veículo dentro das 24 horas"
                  />
                ) : lastLocation?.latitude &&
                  lastLocation?.longitude &&
                  lastLocation?.captured_at &&
                  lastLocation?.captured_at &&
                  moment().diff(moment(lastLocation.captured_at), 'hours') >
                    24 ? (
                      <MarkerLetter
                    background={lastLocation?.battery_level ? "app_late.svg" : "current_late.svg"}
                    sizeIcon={30}
                    lat={lastLocation?.latitude}
                    lng={lastLocation?.longitude}
                    color="#fff"
                    title="A localização do veículo excedeu as 24 horas"
                    style={{ cursor: 'help' }}
                  />
                ) : null}
                {markersEnabled && coords?.latitude && coords?.longitude && (
                  <Marker
                    lat={coords?.latitude}
                    lng={coords?.longitude}
                    background="#7337EE"
                    text={
                      <>
                        <div>
                          <Text color="#fff" type="label">
                            {formatDateTime(coords.captured_at)}
                          </Text>
                        </div>
                        <div>
                          <Text color="#fff" type="label">
                            {formatNumber(coords.velocidade) || 0} km/h
                          </Text>
                        </div>
                        <div>
                          <Text color="#fff" type="label">
                            {coords.battery}%
                            {coords.battery > 75 && (
                              <FaBatteryFull
                                size={16}
                                style={{ marginLeft: 5, marginBottom: 3 }}
                              />
                            )}
                            {coords.battery >= 50 && coords.battery <= 75 && (
                              <FaBatteryHalf
                                size={16}
                                style={{ marginLeft: 5, marginBottom: 3 }}
                              />
                            )}
                            {coords.battery >= 0 && coords.battery < 50 && (
                              <FaBatteryQuarter
                                size={16}
                                style={{ marginLeft: 5, marginBottom: 3 }}
                              />
                            )}
                          </Text>
                        </div>
                      </>
                    }
                  />
                )}
                {markersEnabled && latLngTemperature?.latitude && latLngTemperature?.longitude && (
                  <MarkerTemperature
                    lat={latLngTemperature?.latitude}
                    lng={latLngTemperature?.longitude}
                    background="#7337EE"
                    paramsTemperature={latLngTemperature}
                  />
                )}
              </MapRaw>
            )}

            <Progress
              path={value => setPathEnabled(value)}
              markers={value => setMarkersEnabled(value)}
              onChangeSource={value => setLocationSources(value)}
            />
            <StatusColor />
          </Col>
          <div style={{ marginTop: 50 }}>
            {locations?.length < 1 || locations.every(({ speed }) => !speed) ? (
              <Col
                className="d-flex
              justify-content-center align-items-center
              mt-3"
              >
                <Text color="gray">Não temos dados sobre a velocidade</Text>
              </Col>
            ) : (
              <Speed
                latLong={locations}
                setCoords={setCoords}
                handleClickVelocity={data => handleClickVelocity(data)}
              />
            )}
          </div>
          {travel?.data?.loadTemperature?.length > 0 && (
            <Temperature
              handleClickTemperature={handleClickTemperature}
              setLatLngTemperature={setLatLngTemperature}
            />
          )}
          <Alerts alerts={alerts} />
        </Col>
      </Row>
    </>
  );
}
