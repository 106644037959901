import styled, { css } from 'styled-components';
import BootstrapNav from 'react-bootstrap/Nav';
import BootstrapNavbar from 'react-bootstrap/Navbar';
import BootstrapNavDropdown from 'react-bootstrap/NavDropdown';
import theme from '../../theme';

export const Navbar = styled(BootstrapNavbar)`
  background-color: ${theme.colors.dark_blue};
  z-index: 10;
`;

export const NavLink = styled(BootstrapNav.Link)`
  text-transform: uppercase;
  font-size: 13px;
  border-radius: 5px;
  color: ${theme.colors.white};
  padding: 0.5rem 1rem;
  white-space: nowrap;

  &:hover {
    background-color: rgba(255, 255, 255, 0.15);
    color: ${theme.colors.white};
    text-decoration: none;
  }

  ${({ active }) =>
    active &&
    css`
      background-color: rgba(255, 255, 255, 0.15);
    `}

  @media (max-width: 768px) {
    padding: 1rem 1rem;
  }
`;

export const NavDropdownItem = styled(BootstrapNavDropdown)`
  margin-left: 8px;
  margin-right: 8px;

  > a {
    text-transform: uppercase;
    font-size: 13px;
    border-radius: 5px;
    color: ${theme.colors.black} !important;
    transition: background ease-in 0.3s;

    ${({ active }) =>
      active &&
      css`
        background-color: rgba(255, 255, 255, 0.15);
      `}
    &:hover {
      background-color: rgba(255, 255, 255, 0.15);
      color: ${theme.colors.black};
    }

    @media (max-width: 768px) {
      padding: 1rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .dropdown-menu {
    padding: 0;
    max-height: 100px;
    overflow-y: auto;
    > a {
      text-transform: uppercase;
      font-size: 13px;
      padding: 0.5rem 1rem;
      white-space: pre-wrap;
      &:hover {
        border-radius: 5px;
        color: #3333;
      }
    }
  }
`;

export const NavDropdown = styled(BootstrapNavDropdown)`
  > a {
    text-transform: uppercase;
    font-size: 13px;
    border-radius: 5px;
    color: ${theme.colors.white} !important;
    transition: background ease-in 0.3s;

    ${({ active }) =>
      active &&
      css`
        background-color: rgba(255, 255, 255, 0.15);
      `}
    &:hover {
      background-color: rgba(255, 255, 255, 0.15);
      color: ${theme.colors.white};
    }

    @media (max-width: 768px) {
      padding: 1rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .dropdown-menu {
    padding: 0 0 0.75rem;
    overflow-y: auto;
    max-height: 300px;
    width: max-content;
    max-width: 250px;
    overscroll-behavior: none;

    > a {
      text-transform: uppercase;
      font-size: 13px;
      padding: 0.5rem 1rem;

      &:hover {
        border-radius: 5px;
        color: #3333;
      }
    }
  }
  .dropdown-item {
    white-space: pre-line;
  }
`;

export const NotificationUnreadIcon = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 5px;
  background-color: ${theme.colors.error};
  position: absolute;
  top: 10px;
  left: 25px;
`;

export const DefaultContainer = styled.div`
  display: flex;
  width: 50px;
  background-color: ${({ theme: themeProvider }) =>
    themeProvider.newTheme.colors.dark_blue};
`;

export const MenuContainer = styled.div`
  background-color: ${({ theme: themeProvider }) =>
    themeProvider.newTheme.colors.dark_blue};
  width: 50px;
  padding: 16px;
  color: white;
  contain: paint;
  position: absolute;
  z-index: 9;
  transition: 0.2s;
  border-radius: 0 0 20px 0;
  height: max-content;
  padding-bottom: 330px;
  :hover {
    width: 300px;
  }
`;

export const BackDrop = styled.div`
  background-color: ${({ theme: themeProvider }) =>
    themeProvider.newTheme.colors.dark_blue};
  width: 50px;
  min-height: 100vh;
  height: ${({ $heigth }) => $heigth};
  position: absolute;
`;

export const VersionUnreadIcon = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 5px;
  background-color: ${theme.colors.error};
  position: absolute;
  top: 10px;
  left: 25px;
`;
