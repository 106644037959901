import api from 'services/api';
import { validate } from '../shared/validator';

export async function handleCreation(
  data,
  setData,
  setErrors,
  showSnack,
  setLoading,
  setShowModal
) {
  const isValid = await validate(
    'allSchema',
    {
      access: { email: data?.email, password: data?.password },
      data: {
        profile: data.profile,
        username: data.username,
        company: data.company,
        groups: data.groups,
        tags: data.tags,
        costCenters: data.costCenters,
        branchOffices: data?.branchOffices,
        cpf: data?.cpf,
        phonenumber: data?.phonenumber?.match(/\d+/g)
          ? data?.phonenumber
          : null,
        companies: data?.companies,
      },
      clients: data.clients,
      establishments: data.establishments,
      shippingCompanies: data.shipping_companies,
      beneficiary: data.beneficiary
    },
    setErrors
  );
  if (!isValid) {
    showSnack('Verifique os campos obrigatórios!', { type: 'error' });
    return false;
  }

  if (data?.verifyEmail !== data?.email) {
    setErrors({ email: 'E-mail não confere!' });
    showSnack('O e-mail não confere!', { type: 'error' });
    return false;
  }
  if (data.verifyPassword !== data?.password) {
    setErrors({ password: 'Senha não confere' });
    showSnack('A senha não confere!', { type: 'error' });
    return false;
  }

  try {
    setLoading(true);
    const payload = {
      profile_id: data?.profile?.id,
      username: data?.username,
      company_id: data?.company?.id,
      email: data?.email,
      password: data?.password,
      verified: true,
      cpf: data?.cpf,
      phonenumber: data?.phonenumber,
      change_password: data?.change_password,
      branchOfficesId: data?.branchOffices?.map(
        branchOffice => branchOffice.id
      ),
      companiesId: data?.companies?.map(company => company.id),
    };

    const response = await api.post('v3/user/add', {
      data: payload,
      shippingCompaniesId:
        data.shipping_companies?.length > 0
          ? data.shipping_companies?.map(shippingCompany => shippingCompany.id)
          : [],
      beneficiary: data.beneficiary?.length > 0
        ? data.beneficiary?.map(beneficiary => beneficiary.id)
        : [],
      clientsId:
        data.clients?.length > 0 ? data.clients?.map(client => client.id) : [],
      costCentersIds:
        data.costCentersManaged?.length > 0
          ? data.costCentersManaged?.map(costCenter => costCenter.id)
          : [],
      establishments:
        data.establishments?.length > 0
          ? data.establishments?.map(establishment => establishment.id)
          : [],
      groups: data.groups?.length ? data.groups?.map(group => group.id) : [],
      sites: data.sites?.length ? data.sites?.map(site => site.id) : [],
      tags: data.tags?.length ? data.tags.map(item => item.id) : [],
      costCenters: data.costCenters?.length
        ? data.costCenters.map(item => item.id)
        : [],
    });

    setData({ id: response.data.id });
    showSnack('Usuário cadastrado', { type: 'success' });
    setLoading(false);
    setShowModal(true);
    return true;
  } catch (err) {
    setLoading(false);
    if (err?.response?.data?.length) {
      const errors = err.response.data;
      setErrors(old => ({
        ...old,
        ...Object.fromEntries(
          errors?.map(error => [error?.field, error?.message])
        ),
      }));
    }

    showSnack('Erro ao cadastrar usuário!', { type: 'error' });
    return false;
  }
}
