import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import { FaQuestionCircle } from 'react-icons/fa';
import riskManagerAPI from 'services/apis/risk_manager';
import api from 'services/api';
import cooperplaceApi from 'services/apis/cooperplace';
import useModal from 'hooks/useModal';
import { usePermission } from 'hooks';
import theme from 'v3/theme';
import Input from 'v3/components/Input';
import Select from 'v3/components/Select';
import Text from 'v3/components/Text';
import Tooltip from 'v3/components/Tooltip';
import { useSnackbar } from 'v3/components/Snackbar';
import Button from 'v3/components/Button';
import Card from 'v3/components/Card';
import DatePicker from 'v3/components/DatePicker';
import Badge from 'v3/components/Badge';
import { checkDriver } from 'v3/pages/PreLoad/functions';
import VehicleModal from './Vehicle';
import CreateDriverModal from '../Modals/CreateDriver';
import UpdateDriverModal from '../Modals/UpdateDriver';
import { TravelContext, checkIfBlacklisted } from '../controller';
import DriverAttachments from '../../shared/DriverAttachments';

const [, useTravel] = TravelContext;

export default function DriverData() {
  const travel = useTravel();
  const vehicleModal = useModal();
  const createDriverModal = useModal();
  const updateDriverModal = useModal();
  const snackbar = useSnackbar();
  const hasPermission = usePermission('CADASTRAR_MOTORISTAS');

  const [vehicles, setVehicles] = useState();
  const [loading, setLoading] = useState(false);
  const [riskManagerText, setRiskManagerText] = useState('');
  const [hasRiskManagerAproval, setRiskManagerAproval] = useState();
  const [isDisabled, setDisabled] = useState(true);
  const [driver, setDriver] = useState({});

  const [driverAttachments, setDriverAttachments] = useState([]);

  async function fetchDriver(search) {
    try {
      const response = await api.get('drivers/select', { params: { search } });
      return response.data;
    } catch (error) {
      return [];
    }
  }
  async function fetchVehicle(data) {
    try {
      const vehicle = await api.get(`drivers/vehicle/?driverId=${data?.id}`);
      setVehicles(vehicle.data);

      return vehicle.data;
    } catch (error) {
      return [];
    }
  }

  async function handleDriverChange(data) {
    if (data?.id) {
      const attachmentsDriver = await api.get(`driver/${data?.id}/attachments`);
      setDriverAttachments(attachmentsDriver);
    } else {
      setDriverAttachments({});
    }
    const driverVehicles = await fetchVehicle(data);
    if (driverVehicles?.vehicle?.length > 0) vehicleModal.open();

    travel.setData(old => ({
      ...old,
      driver: data,
      passDriverValidation: false,
    }));
  }

  async function checkRiskManager(data) {
    try {
      let risk_manager_id;
      if (data.riskManager?.length > 0)
        risk_manager_id = data.riskManager[0]?.id;
      else risk_manager_id = data.riskManager.id;
      setLoading(true);
      const response = await riskManagerAPI.post('/query', {
        risk_manager_id,
        driver_id: data.driver.user?.id,
        load_id: data.loadId,
      });
      setRiskManagerAproval(response.data);
    } catch (error) {
      if (error.response)
        snackbar.show(<Text>{error.response.data.errors[0]?.message}</Text>, {
          type: 'error',
        });
      else snackbar.show(<Text>Erro ao consultar GR.</Text>, { type: 'error' });
      setRiskManagerAproval('');
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    setRiskManagerAproval('');
  }, [travel.data?.driver, travel.data?.riskManager]);

  async function checkDriverData(data) {
    const onApiErrorCallback = () =>
      snackbar.show(<Text>Não foi possível validar o motorista!</Text>, {
        type: 'error',
      });
    const onBlacklistedCallback = blacklistInfo =>
      snackbar.show(
        <>
          <Text>
            Não foi possível selecionar o motorista pois ele está banido
          </Text>
          {blacklistInfo?.reason && (
            <Text> Razão: {blacklistInfo.reason} </Text>
          )}
        </>,
        {
          type: 'error',
        }
      );
    const isBlackListed = await checkIfBlacklisted(
      data?.cgccpf,
      onBlacklistedCallback,
      onApiErrorCallback
    );
    if (isBlackListed) return;
    if (data) {
      setDriver({
        id: data.id,
        social_name: data.social_name,
        cnh_number: data.cnh_number,
        cnh_exp_date: data.cnh_exp_date,
        phone_cooperplace: data.phone_cooperplace,
        cgccpf: data?.cgccpf,
        payment_method: data.driver?.payment,
        documentsData: {
          cnhFront: { ...data?.frontCnh },
          selfie: { ...data?.driver?.selfie },
        },
      });
    }

    if (checkDriver(data)) {
      return updateDriverModal.open();
    }

    return handleDriverChange(data);
  }

  useEffect(() => {
    if (
      travel.data.origin &&
      travel.data.destinations?.length > 0 &&
      travel.data.shippingCompany &&
      travel.data.vehicle &&
      travel.data.riskManager &&
      travel.data.loadId
    ) {
      if (travel.data.driver?.user) {
        if (travel.data.riskManager?.length > 0) {
          if (travel.data.riskManager[0].register_automation_id !== null) {
            setDisabled(false);
            return;
          }
        } else if (travel.data.riskManager.register_automation_id !== null) {
          setDisabled(false);
          return;
        }
      } else {
        setRiskManagerText(
          'Motorista inválido para consultar gerenciadora de risco'
        );
        setDisabled(true);
        return;
      }
    } else {
      setRiskManagerText(
        'Para realizar uma consulta, selecione uma gerenciadora de risco, motorista, transportadora, carga, origem, destino e veículo.'
      );
      setDisabled(true);
      return;
    }
    setDisabled(true);
    setRiskManagerText('Gerenciadora de Risco sem automação.');
  }, [travel.data]);

  useEffect(() => {
    async function importFromBenner(document) {
      try {
        const url = `drivers/importFromBenner?cpfCnpj=${document}`;
        await cooperplaceApi.get(url);
      } catch (error) {}
    }
    if (travel?.data?.driver) {
      importFromBenner(travel.data.driver?.cgccpf);
    }
  }, [travel?.data?.driver]);
  return (
    <>
      <VehicleModal
        isOpen={vehicleModal.isOpen}
        onClose={vehicleModal.close}
        vehicles={vehicles?.vehicle}
      />
      <CreateDriverModal
        isOpen={createDriverModal.isOpen}
        onClose={createDriverModal.close}
      />
      <UpdateDriverModal
        isOpen={updateDriverModal.isOpen}
        onClose={updateDriverModal.close}
        onSubmit={handleDriverChange}
        driver={driver}
        setDriver={setDriver}
      />
      <Card
        header={
          <Text color="#464E5F" type="header">
            Motorista
          </Text>
        }
      >
        <Row className="form">
          <Col xs={12}>
            <Select.Async
              NoOptionsComponent={() => (
                <Text
                  color="gray"
                  type="regular"
                  style={{ cursor: 'pointer' }}
                  onClick={() => createDriverModal.open()}
                  id="button-nenhum-motorista-encontrado"
                >
                  Nenhum motorista encontrado, clique aqui para cadastrar
                </Text>
              )}
              onSearch={fetchDriver}
              label={travel?.isGROLoad?.id ? 'Nome *' : 'Nome'}
              value={travel.data.driver}
              onChange={data => {
                checkDriverData(data);
              }}
              getOptionLabel={option => option?.social_name}
              getOptionValue={option => option?.id}
              error={travel.errors.driverId || travel.errors['driver.id']}
              id="select-nome-motorista"
            />
            {hasPermission && (
              <Tooltip
                className="mt-1"
                content={
                  <Text type="label">Cadastro simplificado de motorista</Text>
                }
              >
                <Text
                  type="label"
                  weight="500"
                  color="dark_blue"
                  clickable
                  onClick={() => createDriverModal.open()}
                  id="button-novo-motorista"
                >
                  Motorista novo? Clique aqui para cadastrar
                </Text>
              </Tooltip>
            )}
          </Col>
          {hasRiskManagerAproval && (
            <Col xs={12}>
              <Badge
                light
                variant={
                  hasRiskManagerAproval?.status === 'COVERED'
                    ? 'success'
                    : 'error'
                }
              >
                <Text type="label">
                  {hasRiskManagerAproval?.received_response}
                </Text>
              </Badge>
            </Col>
          )}

          <Col md={6} xs={12}>
            <Input
              label="CNH"
              disabled
              value={travel.data.driver ? travel.data.driver?.cnh_number : ''}
              error={travel.errors.document}
            />
          </Col>
          <Col md={6} xs={12}>
            <DatePicker
              guide
              label="Data de expiração"
              placeholder=""
              disabled
              value={
                travel.data.driver?.cnh_exp_date
                  ? moment(
                      travel.data.driver?.cnh_exp_date,
                      'YYYY-MM-DD'
                    ).format('DD/MM/YYYY')
                  : ''
              }
              error={travel.errors.dataCnh}
            />
          </Col>

          <Col
            xs={12}
            className="d-flex align-items-center justify-content-center"
          >
            <Button
              loading={loading}
              disabled={isDisabled}
              onClick={() => checkRiskManager(travel.data)}
              className="mr-3 py-2"
              outlined
            >
              <Text type="regular" weight="500">
                Consultar GR
              </Text>
            </Button>
            {isDisabled && (
              <Tooltip
                content={
                  <Text type="label" color="white">
                    {riskManagerText}
                  </Text>
                }
              >
                <FaQuestionCircle style={{ color: theme.colors.light_blue }} />
              </Tooltip>
            )}
          </Col>
        </Row>
        <Row>
          <DriverAttachments driverAttachments={driverAttachments} />
        </Row>
      </Card>
    </>
  );
}
