import React, { useState, useEffect, useMemo } from 'react';
import { Row, Col } from 'react-bootstrap';

import Text from 'v3/components/Text';
import { useSnackbar } from 'v3/components/Snackbar';
import api from 'services/api';
import { measureOptions } from 'v3/utils/products';
import { useProduct } from '../shared/context';
import { validate } from '../shared/validator';

import SharedData from '../shared/Section';
import Inputs from '../shared/Data';
import Editable from '../shared/Editable';

export default function Data() {
  const product = useProduct();
  const snackbar = useSnackbar();
  const [isEditing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  async function saveData() {
    setLoading(true);
    try {
      const isValid = await validate(
        'data',
        { data: product.data },
        product.setErrors
      );

      if (!isValid) {
        snackbar.show(<Text>Verifique os campos obrigatórios</Text>, {
          type: 'error',
        });
        setLoading(false);
        return;
      }

      const data = {
        name: product.data?.name,
        product_ncm_id: product.data?.productNcm.id,
        ncm: product.data?.ncm,
        unit_measure: product.data?.unit_measure.value,
        points: product.data?.points,
        product_type_id: product.data?.productType?.id,
      };

      await api.put(`v3/product/${product.data.id}/update`, data);
      snackbar.show(<Text>Salvo com sucesso</Text>, { type: 'success' });
      setEditing(false);

      product.updatePrevProduct();
    } catch (error) {
      if (error.response) {
        const formattedErrors = error.response.data.reduce((acc, err) => {
          return { ...acc, [`data.${err.field}`]: err.message };
        }, {});

        product.setErrors(formattedErrors);
      }

      snackbar.show(<Text>Erro ao salvar dados</Text>, { type: 'error' });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (product.data.unit_measure) {
      const measureOption = measureOptions.find(
        option =>
          option.value == product.data.unit_measure ||
          option.value == product.data.unit_measure?.value
      );

      product.setData({
        unit_measure: measureOption,
      });
    }
  }, [product.data.unit_measure]);

  const ncmParentLabel = useMemo(() => {
    let label = '';
    if (product?.data?.productNcm?.chapter) {
      label = `${product?.data?.productNcm?.chapter?.code}${' - '}${
        product?.data?.productNcm?.chapter?.name
      }`;
    } else if (product?.data?.productNcm?.chapterCode?.length > 0) {
      label = `${product?.data?.productNcm?.chapterCode}${' - '}${
        product?.data?.productNcm?.chapterName
      }`;
    }

    return label;
  }, [product?.data?.productNcm]);

  return (
    <SharedData
      Title="Dados do produto"
      HeaderRightComponent={
        <Editable
          isEditing={isEditing}
          setEditing={setEditing}
          onSave={saveData}
          isLoading={loading}
        />
      }
      loading={product.isFetching}
    >
      {isEditing ? (
        <Inputs />
      ) : (
        <Row>
          <Col xs={12}>
            <Text type="label" color="#464E5F">
              Nome
            </Text>
            <Text
              weight={500}
              type="regular"
              color="gray"
              as="p"
              className="mt-2"
            >
              {product?.data?.name || 'Não informado'}
            </Text>
          </Col>
          <Col xs={6}>
            <Text type="label" color="#464E5F">
              Unidade de medida
            </Text>
            <Text
              weight={500}
              type="regular"
              color="gray"
              as="p"
              className="mt-2"
            >
              {product?.data?.unit_measure?.name || 'Não informado'}
            </Text>
          </Col>
          <Col xs={6}>
            <Text type="label" color="#464E5F">
              Pontos
            </Text>
            <Text
              weight={500}
              type="regular"
              color="gray"
              as="p"
              className="mt-2"
            >
              {product?.data?.points || 'Não informado'}
            </Text>
          </Col>
          <Col xs={12}>
            <Text type="label" color="#464E5F">
              Categoria do produto
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {product?.data?.productType?.name ?? 'Não informado'}
            </Text>
          </Col>
          <Col xs={12}>
            <Text type="label" color="#464E5F">
              Produto Ncm
            </Text>
            {ncmParentLabel && (
              <Text
                type="label"
                color="#464E5F"
                as="p"
                className="mt-2 mb-0"
                style={{
                  textAlign: 'justify',
                }}
              >
                {ncmParentLabel}
              </Text>
            )}
            <Text
              weight={500}
              type="regular"
              color="gray"
              as="p"
              className="mt-1"
              style={{
                textAlign: 'justify',
              }}
            >
              {product?.data?.productNcm?.label?.startsWith(
                product?.data?.productNcm?.code || product?.data?.ncm
              )
                ? product?.data?.productNcm?.label
                : product?.data?.productNcm?.code
                ? `${product?.data?.productNcm?.code} - ${product?.data?.productNcm?.name}`
                : 'Não informado'}
            </Text>
          </Col>
        </Row>
      )}
    </SharedData>
  );
}
