/* eslint-disable no-unneeded-ternary */
import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import api from 'services/api';
import { useSnackbar } from 'v3/components/Snackbar';
import Table from 'v3/components/Table';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import useModal from 'hooks/useModal';
import Radio from 'v3/components/Radio';
import SharedData from '../shared/Section';
import Inputs from '../shared/Checklist';
import Editable from '../shared/Editable';
import { useCompany } from '../shared/context';

export default function Checklist() {
  const checklistModal = useModal();
  const params = useParams();

  const company = useCompany();
  const snackbar = useSnackbar();
  const [isEditing, setEditing] = useState(false);

  async function handleChecklistStatus(value) {
    try {
      company.setData({
        requireChecklist: { ...company.data.requireChecklist, value },
      });

      if (params.id) {
        await api.put(`v3/company/${params.id}/status/checklist`, {
          status: value,
        });
      }

      snackbar.show(<Text>Checklist alterado!</Text>, {
        type: 'success',
      });
      company.setPrevCompany(company.data);
    } catch (error) {
      snackbar.show(<Text>Algo deu errado, tente novamente!</Text>, {
        type: 'success',
      });
    }
  }

  async function deleteItem(item) {
    try {
      if (params.id) {
        await api.delete(`v3/company/${params.id}/checklist/${item.id}`);
        company.setData({
          checkList: company.data.checkList.filter(checklist => {
            return checklist.id !== item.id;
          }),
        });
      }

      company.setData({
        checkList: company.data.checkList.filter(checklist => {
          return checklist.title !== item.title;
        }),
      });

      snackbar.show(<Text>Item do checklist removido!</Text>, {
        type: 'success',
      });

      if (
        company.data?.checkList?.length === 1 ||
        company.data?.checkList === undefined
      ) {
        await handleChecklistStatus(false);
      }
      company.setPrevCompany(company.data);
    } catch (error) {
      snackbar.show(<Text>Algo deu errado, tente novamente!</Text>, {
        type: 'error',
      });
    }
  }
  return (
    <SharedData
      Title="Checklist"
      HeaderRightComponent={
        <>
          {company?.data?.checkList !== undefined &&
            company?.data?.checkList.length > 0 && (
              <Editable
                add
                openModal={() => checklistModal.open()}
                isEditing={isEditing}
                setEditing={setEditing}
                isLoading={false}
              />
            )}
        </>
      }
      loading={company.isFetching}
    >
      <Inputs isOpen={checklistModal.isOpen} onClose={checklistModal.close} />
      <>
        {company?.data?.checkList !== undefined &&
        company?.data?.checkList.length > 0 ? (
          <Row>
            <Col xs={12} className="ml-1">
              <Radio.Group
                label="Checklist obrigatório"
                onChange={({ target }) => {
                  handleChecklistStatus(target.value);
                }}
                value={
                  company.data?.requireChecklist?.value == 1 ? true : false
                }
                disabled={!company.hasPermissionToEdit}
                horizontal
              >
                <Radio value>
                  <Text color="dark" type="label">
                    Sim
                  </Text>
                </Radio>
                <Radio value={false}>
                  <Text color="dark" type="label">
                    Não
                  </Text>
                </Radio>
              </Radio.Group>
            </Col>
            <Col>
              <Table striped>
                <thead>
                  <tr>
                    <th>
                      <Text weight="500" color="dark" type="regular">
                        Nome
                      </Text>
                    </th>
                    <th>
                      <Text weight="500" color="dark" type="regular">
                        Ação
                      </Text>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {company?.data?.checkList?.map(checklist => (
                    <tr>
                      <td>
                        <Text type="regular" color="gray">
                          {checklist.title}
                        </Text>
                      </td>
                      <td>
                        <Button
                          variant="error"
                          className="p-2 align-items-center d-flex justify-content-center"
                          style={{ height: 28, width: 28, marginLeft: 5 }}
                          onClick={() => deleteItem(checklist)}
                          disabled={!company.hasPermissionToEdit}
                        >
                          <FaTrash size={16} />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        ) : (
          <Row className="text-center">
            <Col md={12} className="mb-3">
              <Text color="dark" type="regular">
                Não há checklist cadastrados para essa empresa
              </Text>
            </Col>

            <Col md={12}>
              <Button
                onClick={() => checklistModal.open()}
                className="py-2"
                disabled={!company.hasPermissionToEdit}
              >
                <Text color="white" type="regular" weight="500">
                  Adicionar
                </Text>
              </Button>
            </Col>
          </Row>
        )}
      </>
    </SharedData>
  );
}
