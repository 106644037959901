import React from 'react';

import GoogleMapReact from 'google-map-react';

function Mapa({
  height = '600px',
  zoom = 4,
  positions,
  center,
  children,
  ...rest
}) {
  const defaultOptions = {
    mapTypeControl: false,
    zoomControl: true,
    streetViewControl: false,
    draggableCursor: 'default',
    draggingCursor: 'move',
    fullscreenControl: false,
  };

  return (
    <GoogleMapReact
      options={{
        ...defaultOptions,
      }}
      style={{ position: 'relative', height }}
      bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_KEY }}
      defaultCenter={center}
      defaultZoom={zoom}
      {...rest}
      heatmapLibrary
      heatmap={{
        positions,
        options: {
          radius: 20,
          opacity: 0.6,
        },
      }}
    >
      {children}
    </GoogleMapReact>
  );
}

export default Mapa;
