import React, { useState } from 'react';
import { Card, Input, InputAddress, Select, Text } from 'v3/components';
import { usePreLoad } from '../context';
import { Col, Row } from 'react-bootstrap';
import { fetchSelectOptions } from 'utils/fetches';
import { useEffectThrottled, useModal } from 'hooks';
import moment from 'moment';
import api from 'services/api';
import { validateDateTime } from 'v3/utils/functions';
import { checkClientAddressData } from '../../functions';
import { UpdateClientAddressData } from 'v3/pages/Client/shared/Modals/UpdateAddressData';

export function OriginData() {
  const { data, setData, snackbar, errors, countryOptions, setErrors } =
    usePreLoad();
  const [disableNumber, setDisableNumber] = useState(false);
  const [disableZipCode, setDisableZipCode] = useState(false);
  const [disableCity, setDisableCity] = useState(false);
  const [disableProvince, setDisableProvince] = useState(false);
  const [disableNeighborhood, setDisableNeighborhood] = useState(false);
  const defaultCountry = {
    id: 1,
    name: 'Brasil',
    abbreviation: 'br',
    ddi: 55,
  };
  const updateClientAddressModal = useModal();
  const [clientCurrentAddress, setClientCurrentAddress] = useState(null);
  const [client, setClient] = useState(null);
  async function fetchProvinces(search, uf, country) {
    try {
      const response = await api.get('cities', {
        params: { search, uf, country },
      });
      return response.data;
    } catch (ex) {
      return [];
    }
  }

  useEffectThrottled(
    () => {
      const origin = moment(data?.origin?.date);
      const isBeforeThanNow = origin.isBefore(moment());

      if (isBeforeThanNow) {
        snackbar.show(
          <Text>
            Esta carga não aparecerá no aplicativo pois a hora da coleta já
            passou.
          </Text>,
          {
            type: 'error',
          }
        );
      }
    },
    [data?.origin?.date],
    2000
  );

  function handleOriginClientSelection(event) {
    disableAddress({}, null);
    if (event !== null) {
      const currentAddress = event?.addresses.filter(
        address => address.current
      )[0];
      if (checkClientAddressData(currentAddress)) {
        snackbar.show(
          <Text>Endereço do cliente desatualizado! Complete o cadastro</Text>,
          { type: 'error' }
        );
        setClientCurrentAddress(currentAddress);
        setClient(event);
        return updateClientAddressModal.open();
      } else {
        setData(old => ({
          ...old,
          origin: {
            ...old.origin,
            client: event,
            number: currentAddress?.number || '',
            complement: currentAddress?.complement || '',
            zipCode: currentAddress?.zip_code || '',
            address: currentAddress?.address || '',
            neighborhood: currentAddress?.neighborhood || '',
            lat: currentAddress?.lat || '',
            lng: currentAddress?.lng || '',
            city: currentAddress?.city_name || '',
            cityModel: currentAddress?.city_name
              ? {
                  id: currentAddress?.city_id,
                  name: currentAddress?.city_name,
                  province: {
                    uf: currentAddress?.uf_name,
                  },
                }
              : undefined,
            province: currentAddress?.uf_name,
            formattedAddress: currentAddress?.formatted_address,
          },
        }));
      }
    } else {
      setData(old => ({
        ...old,
        origin: {
          ...old.origin,
          client: null,
          number: '',
          complement: '',
          zipCode: '',
          address: '',
          neighborhood: '',
          lat: '',
          lng: '',
          city: '',
          cityModel: null,
          province: '',
          formatted_address:''
        },
      }));
    }
  }

  function disableAddress(address, city) {
    setDisableNumber(address.number ? true : false);
    setDisableZipCode(address.zip_code ? true : false);
    setDisableCity(city ? true : false);
    setDisableProvince(city?.province ? true : false);
    setDisableNeighborhood(address.neighborhood ? true : false);
  }

  async function setOriginCity(city, options) {
    let cityModel = city;
    if (options.action === 'create-option') {
      cityModel = {
        name: city.id,
        province: '',
      };
    }

    setData(old => ({
      ...old,
      origin: {
        ...old.origin,
        cityModel,
        province: cityModel?.province?.uf || '',
      },
    }));
  }
  function handleUpdateAddress(data) {
    setData(old => ({
      ...old,
      origin: {
        ...old.origin,
        client: client,
        address: data?.address || '',
        city: data?.city_name || '',
        cityModel: data?.city_name
          ? {
              id: data?.city_id,
              name: data?.city_name,
              province: {
                uf: data?.uf_name,
              },
            }
          : undefined,
        number: data?.number || '',
        complement: data?.complement || '',
        zipCode: data?.zip_code || '',
        neighborhood: data?.neighborhood || '',
        lat: data?.lat || '',
        lng: data?.lng || '',
        province: data?.uf_name,
        formattedAddress: data?.formatted_address,
      },
    }));
    disableAddress(data, {
      id: data?.city_id,
      name: data?.city_name,
      province: data?.uf_name,
    });
  }

  return (
    <Card
      header={
        <Text color="#464E5F" type="header">
          Origem
        </Text>
      }
      bodyCardPadding="1rem 3rem"
    >
      <Row className="form">
        <UpdateClientAddressData
          show={updateClientAddressModal.isOpen}
          onSubmit={handleUpdateAddress}
          onClose={() => updateClientAddressModal.close()}
          currentAddressData={clientCurrentAddress}
          countryOptions={countryOptions}
        />
        <Col md={6} xs={12} className="mb-3">
          <Select
            label="País *"
            options={countryOptions}
            value={data.origin?.country}
            onChange={value => {
              setData(old => ({
                ...old,
                origin: {
                  ...old.origin,
                  country: value,
                  countrySlug: value?.abbreviation,
                  number: '',
                  complement: '',
                  zipCode: '',
                  address: '',
                  neighborhood: '',
                  lat: '',
                  lng: '',
                  city: '',
                  cityModel: null,
                  province: '',
                },
              }));
            }}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            error={errors[`origin.country`]}
          />
        </Col>
        <Col md={6} xs={12} className="mb-3">
          <Select.Async
            label="Cliente Tomador"
            onSearch={search =>
              fetchSelectOptions('persons/customers', { search })
            }
            value={data.origin?.client}
            getOptionLabel={option => {
              let label = `${option?.social_name} - ${option?.cgccpf}`;

              if (option.city) {
                label += ` - ${option?.city}`;
              }

              return label;
            }}
            getOptionValue={option => option?.id}
            onChange={event => {
              handleOriginClientSelection(event);
            }}
          />
        </Col>
        <Col md={8}>
          <InputAddress
            label="Logradouro *"
            placeholder=""
            value={data.origin?.address}
            onSelectedAddress={async place => {
              const [city] = await fetchProvinces(
                place.city,
                place.province,
                place.country_abbreviation
              );

              disableAddress(place, city);
              setData(old => ({
                ...old,
                origin: {
                  ...old.origin,
                  address: place.address ? place.address : '',
                  number: place?.number ? place.number : '',
                  zipCode: place.zip_code ? place.zip_code : '',
                  cityModel: city,
                  complement: place.name ? place.name : '',
                  neighborhood: place.neighborhood ? place.neighborhood : '',
                  province: city?.province?.uf || '',
                  lat: place.lat,
                  lng: place.lng,
                  formattedAddress: place.formatted_address,
                },
              }));
            }}
            onChange={event =>
              setData(old => ({
                ...old,
                origin: {
                  ...old.origin,
                  address: event.target.value,
                },
              }))
            }
            types={[]}
            error={errors[`origin.address`]}
            country={
              data.origin?.country ? data.origin?.country?.abbreviation : null
            }
          />
        </Col>
        <Col md={4}>
          <Input
            label="Número"
            disabled={disableNumber}
            value={data.origin?.number}
            onChange={event =>
              setData(old => ({
                ...old,
                origin: {
                  ...old.origin,
                  number: event.target.value,
                },
              }))
            }
          />
        </Col>

        <Col md={4}>
          <Input
            label="Complemento"
            value={data.origin?.complement}
            onChange={event =>
              setData(old => ({
                ...old,
                origin: {
                  ...old.origin,
                  complement: event.target.value,
                },
              }))
            }
          />
        </Col>
        <Col md={4}>
          <Input
            label="Bairro"
            disabled={disableNeighborhood}
            value={data.origin?.neighborhood}
            onChange={event =>
              setData(old => ({
                ...old,
                origin: {
                  ...old.origin,
                  neighborhood: event.target.value,
                },
              }))
            }
          />
        </Col>
        <Col md={4}>
          <Input
            label="CEP"
            disabled={disableZipCode}
            value={data.origin?.zipCode}
            onChange={event =>
              setData(old => ({
                ...old,
                origin: {
                  ...old.origin,
                  zipCode: event.target.value,
                },
              }))
            }
            error={errors[`origin.zipCode`]}
          />
        </Col>
        <Col xs={8} className="my-3">
          <Select.Async
            label="Cidade *"
            onSearch={city =>
              fetchProvinces(
                city,
                '',
                data.origin?.countrySlug || defaultCountry?.abbreviation
              )
            }
            value={data.origin?.cityModel}
            creatable
            horizontal
            onChange={(value, options) => setOriginCity(value, options)}
            getOptionLabel={option =>
              `${`${option.name} ${
                option.province?.uf ? `- ${option.province?.uf}` : ''
              }`}`
            }
            getOptionValue={option =>
              `${`${option.name} ${
                option.province?.uf ? `- ${option.province?.uf}` : ''
              }`}`
            }
            error={errors[`origin.cityModel`]}
            isDisabled={disableCity}
          />
        </Col>
        <Col md={4} className="my-3">
          <Input
            label="UF *"
            disabled={disableProvince}
            value={data.origin?.province}
            onChange={event =>
              setData(old => ({
                ...old,
                origin: {
                  ...old.origin,
                  province: event.target.value,
                },
              }))
            }
            error={errors[`origin.province`]}
          />
        </Col>
        <Col md={6}>
          <Input
            label="Latitude"
            disabled={true}
            value={data.origin?.lat}
            error={errors[`origin.lat`]}
            tooltip="Selecione um endereço sugerido para obter dados de latitude e longitude"
          />
        </Col>
        <Col md={6}>
          <Input
            label="Longitude"
            disabled={true}
            value={data.origin?.lng}
            error={errors[`origin.lng`]}
            tooltip="Selecione um endereço sugerido para obter dados de latitude e longitude"
          />
        </Col>
        <Col md={6}>
          <Input
            type="datetime-local"
            value={data.origin?.startSchedule}
            label="Data Início da coleta *"
            onChange={event => {
              let isValid = validateDateTime(event?.target?.value);
              if (isValid) {
                setData(old => ({
                  ...old,
                  origin: {
                    ...old.origin,
                    startSchedule: event?.target?.value,
                  },
                }));
                setErrors(old => ({ ...old, ['origin.startSchedule']: null }));
              } else {
                setErrors(old => ({
                  ...old,
                  ['origin.startSchedule']: 'Data inválida',
                }));
                setData(old => ({
                  ...old,
                  origin: {
                    ...old.origin,
                    startSchedule: event?.target?.value,
                  },
                }));
              }
            }}
            error={errors[`origin.startSchedule`] || errors.origin}
          />
        </Col>
        <Col md={6}>
          <Input
            type="datetime-local"
            label="Data máxima da coleta *"
            value={data.origin?.date}
            onChange={event => {
              let isValid = validateDateTime(event?.target?.value);
              if (isValid) {
                setData(old => ({
                  ...old,
                  origin: {
                    ...old.origin,
                    date: event?.target?.value,
                  },
                }));
                setErrors(old => ({ ...old, ['origin.date']: null }));
              } else {
                setErrors(old => ({
                  ...old,
                  ['origin.date']: 'Data inválida',
                }));
                setData(old => ({
                  ...old,
                  origin: {
                    ...old.origin,
                    date: event?.target?.value,
                  },
                }));
              }
            }}
            error={errors[`origin.date`]}
          />
        </Col>
      </Row>
    </Card>
  );
}
