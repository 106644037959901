import Button from 'v3/components/Button';

const { default: styled } = require('styled-components');

export const AditionalFIeldsContainer = styled.div`
  margin-top: 15px;
  width: 100%;
  .row {
    gap: 4px 0;
  }
`;

export const StyledButton = styled(Button)`
  && {
    ${({ $backgroundColor }) =>
      `background-color: ${$backgroundColor} !important`}
    ${({ $color }) => `color: ${$color} !important`}
    font-weight: 500
  }
`;
