import React, { useState, useEffect } from 'react';

import { Button, Card, Text } from 'v3/components';

import Space from 'v3/components/Space';
import { cooperplaceApi } from 'services/api';
import { useSnackbar } from 'v3/components/Snackbar';
import { RegisterComponent } from '../components/RegisterComponents';
import { QuestionSchema } from '../shared/validator';

export function EditQuestion({ setEditing, data, fetchQuestion }) {
  const snackbar = useSnackbar();
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [questionType, setQuestionType] = useState(null);
  const [question, setQuestion] = useState({
    needAttachment: false,
    fatherQuestion: null,
    hasSubQuestion: false,
    createOccurrence: false,
    active: true,
    answers: [],
    questionDescription: '',
  });

  const [childQuestion, setChildQuestion] = useState({
    hasSubQuestion: false,
    answers: [],
    active: true,
    questionDescription: '',
  });
  const [childQuestionType, setChildQuestionType] = useState(null);
  useEffect(() => {
    setQuestion({
      id: data?.id,
      active: data?.active,
      needAttachment: data?.needAttachment,
      createOccurrence: data?.createOccurrence,
      hasSubQuestion: !!data?.subQuestion,
      questionDescription: data?.question,
      occurrenceSector: data?.occurrenceGroup,
      answers: data?.values?.map(item => {
        return {
          id: item?.id,
          order: item?.order,
          questionId: item?.questionId,
          description: item?.description,
          triggerOccurrence:
            data?.questionOccurrenceValues?.filter(
              sub => sub?.questionValueId === item?.id
            )?.length > 0,
          triggerSubQuestion:
            data?.subQuestion?.subValues?.filter(
              sub => sub?.questionValueId === item?.id
            )?.length > 0,
        };
      }),
    });
    setQuestionType(data?.type);
    setChildQuestionType(data?.subQuestion?.type);
    setChildQuestion({
      active: data?.subQuestion?.active,
      createOccurrence: data?.subQuestion?.createOccurrence,
      questionDescription: data?.subQuestion?.question,
      occurrenceSector: data?.subQuestion?.occurrenceGroup,
      answers:
        data?.subQuestion?.values?.length > 0
          ? data?.subQuestion?.values?.map(item => {
              return {
                id: item?.id,
                order: item?.order,
                questionId: item?.questionId,
                description: item?.description,
                triggerOccurrence:
                  data?.subQuestion?.questionOccurrenceValues?.filter(
                    sub => sub?.questionValueId === item?.id
                  )?.length > 0,
              };
            })
          : [],
    });
  }, [data]);
  async function validate(data, setErrors) {
    setErrors({});
    const errorList = {};

    try {
      await QuestionSchema.validate(data, {
        abortEarly: false,
      });
      setErrors({ ...errorList });

      return true;
    } catch (err) {
      err.inner.forEach(error => {
        errorList[error.path] = error.message;
      });

      setErrors({ ...errorList });

      return false;
    }
  }
  async function handleEdit(data) {
    try {
      setLoading(true);
      const dataToValidate = {
        question: {
          ...data?.question,
          occurrenceSector: data?.question?.occurrenceSector?.id || null,
          occurrenceTrigger:
            data?.question?.createOccurrence &&
            data?.question?.answers?.filter(item => item.triggerOccurrence)
              ?.length > 0
              ? true
              : data?.question?.createOccurrence &&
                data?.question?.answers?.filter(item => item.triggerOccurrence)
                  ?.length <= 0
              ? null
              : false,
          subQuestionTrigger:
            data?.question?.hasSubQuestion &&
            data?.question?.answers?.filter(item => item.triggerSubQuestion)
              ?.length > 0
              ? true
              : data?.question?.hasSubQuestion &&
                data?.question?.answers?.filter(item => item.triggerSubQuestion)
                  ?.length <= 0
              ? null
              : false,
          typeSlug: data?.questionType?.slug,
        },
        questionType: data?.questionType,
        childQuestion: {
          ...data?.childQuestion,
          occurrenceSector: data?.childQuestion?.occurrenceSector?.id || null,
          occurrenceTrigger:
            data?.childQuestion?.createOccurrence &&
            data?.childQuestion?.answers?.filter(item => item.triggerOccurrence)
              ?.length > 0
              ? true
              : data?.childQuestion?.createOccurrence &&
                data?.childQuestion?.answers?.filter(
                  item => item.triggerOccurrence
                )?.length <= 0
              ? null
              : false,
        },
        childQuestionType: data?.childQuestionType,
      };
      const isDataValid = await validate(dataToValidate, setErrors);
      if (isDataValid === false) {
        setLoading(false);
        return false;
      }
      const payload = {
        id: data?.question?.id,
        typeId: data?.questionType?.id,
        active: data?.question?.active,
        question: data?.question?.questionDescription,
        occurrenceSectorId: data?.question?.occurrenceSector?.id || null,
        needAttachment: data?.question?.needAttachment,
        values:
          data?.question?.answers?.length > 0
            ? data?.question?.answers?.map(item => {
                const triggerSubQuestion = item?.triggerSubQuestion;
                delete item.triggerSubQuestion;
                return { ...item, subQuestion: triggerSubQuestion };
              })
            : [],
        subQuestion: data?.question?.hasSubQuestion
          ? {
              typeId: data?.childQuestionType?.id,
              question: data?.childQuestion?.questionDescription,
              occurrenceSectorId:
                data?.childQuestion?.occurrenceSector?.id || null,
              values:
                data?.childQuestion?.answers?.length > 0
                  ? data?.childQuestion?.answers?.map(item => {
                      return { ...item };
                    })
                  : [],
            }
          : null,
      };
      await cooperplaceApi.put(`questions/${payload?.id}`, payload);
      snackbar.show(<Text>Pergunta atualizada com sucesso</Text>, {
        type: 'success',
      });
      await fetchQuestion(payload.id);
      setLoading(false);
      return true;
    } catch (error) {
      let message = 'Erro ao tentar cadastrar pergunta.';
      if (typeof error?.response?.data?.error === 'string') {
        message = error?.response?.data?.error;
      }
      snackbar.show(<Text>{message}</Text>, {
        type: 'error',
      });
      setLoading(false);
    }
  }

  async function handleEditQuestion() {
    const response = await handleEdit({
      question,
      questionType,
      childQuestion,
      childQuestionType,
    });
    if (response === false) {
      //
    } else setEditing(false);
  }

  return (
    <Card
      header={
        <Text color="dark" type="header">
          Dados da pergunta
        </Text>
      }
      HeaderRightComponent={
        <Space x="0.5rem">
          <Button
            variant="secondary"
            onClick={() => setEditing(false)}
            disabled={loading}
          >
            <Text>Cancelar</Text>
          </Button>

          <Button onClick={() => handleEditQuestion()} loading={loading}>
            <Text>Salvar</Text>
          </Button>
        </Space>
      }
    >
      <RegisterComponent
        isRegister={false}
        question={question}
        setQuestion={setQuestion}
        questionType={questionType}
        setQuestionType={setQuestionType}
        childQuestion={childQuestion}
        setChildQuestion={setChildQuestion}
        childQuestionType={childQuestionType}
        setChildQuestionType={setChildQuestionType}
        errors={errors}
        setErrors={setErrors}
      />
    </Card>
  );
}
