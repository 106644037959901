/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */

import React, { useMemo, useState } from 'react';

import { Col, Row } from 'react-bootstrap';

import api from 'services/api';
import { useCurrentUser } from 'hooks';

import Modal from 'v3/components/Modal';
import Select from 'v3/components/Select';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';

import { useUser } from '../context';

export default function ClientsModal({ isOpen, onClose }) {
  const user = useUser();
  const currentUser = useCurrentUser();

  const [clients, setClients] = useState([]);
  const [error, setError] = useState('');

  async function fetchClients(search) {
    const response = await api.get('persons/customers', {
      params: {
        profile_id: currentUser.profile_id,
        company_id: currentUser.company_id,
        search,
      },
    });

    return response.data;
  }

  const firstClientAttached = useMemo(() => {
    return clients.find(client =>
      user.data.clients.some(item => item.id === client.id)
    );
  }, [user.data.clients, clients]);

  function handleModalClose() {
    setError('');
    setClients([]);
    onClose();
  }

  function handleAddClient() {
    if (firstClientAttached) {
      setError(
        `O cliente com o CNPJ ${firstClientAttached.cgccpf} já está vinculado.`
      );
      return;
    }

    user.setData({
      clients: [...user.data.clients, ...clients],
    });

    handleModalClose();
  }

  async function handleAttachClient() {
    if (firstClientAttached) {
      setError(
        `O cliente com o CNPJ ${firstClientAttached.cgccpf} já está vinculado.`
      );
      return;
    }

    try {
      await api.put(`v3/user/${user.data.id}/clients`, {
        clients: clients.map(client => client.id),
      });

      user.setData({
        clients: [...user.data.clients, ...clients],
      });

      handleModalClose();
    } catch (ex) {
      user.showSnackbar('Erro ao adicionar cliente', { type: 'error' });
    }
  }

  function handleAdd() {
    if (user.data.id) handleAttachClient();
    else handleAddClient();
  }

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      heading={
        <Text color="dark" type="header">
          Adicionar clientes
        </Text>
      }
      body={
        <Row>
          <Col xs={12} className="d-flex flex-column align-items-center">
            <Select.Async
              className="mt-3"
              label={
                <Text type="label" weight="500">
                  Clientes
                </Text>
              }
              onSearch={search => fetchClients(search)}
              value={clients}
              error={error}
              getOptionLabel={option =>
                `${option.social_name} - ${option.cgccpf}`
              }
              getOptionValue={option => option.id}
              onChange={value => setClients(value || [])}
              multiple
            />
            <Button
              onClick={handleAdd}
              className="mt-2"
              disabled={clients.length === 0}
            >
              <Text type="regular" weight="500" color="white">
                Adicionar
              </Text>
            </Button>
          </Col>
        </Row>
      }
    />
  );
}
