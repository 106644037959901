import React, { useState } from 'react';
import { Button, Card, Input, InputAddress, Select, Text } from 'v3/components';
import { usePreLoad } from '../context';
import { Col, Row } from 'react-bootstrap';
import { fetchSelectOptions } from 'utils/fetches';
import api from 'services/api';
import { DELIVERY_TYPE_OPTIONS } from 'v3/utils/constants';
import { StyledDestinationNavButton } from '../styles';
import IconButton from 'v3/components/Button/Icon';
import { validateDateTime } from 'v3/utils/functions';
import { useModal } from 'hooks';
import { checkClientAddressData } from '../../functions';
import { UpdateClientAddressData } from 'v3/pages/Client/shared/Modals/UpdateAddressData';

export function DestinationsData() {
  const {
    data,
    setData,
    snackbar,
    errors,
    countryOptions,
    destinationsError,
    setErrors,
  } = usePreLoad();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [disableNumber, setDisableNumber] = useState(false);
  const [disableZipCode, setDisableZipCode] = useState(false);
  const [disableCity, setDisableCity] = useState(false);
  const [disableProvince, setDisableProvince] = useState(false);
  const [disableNeighborhood, setDisableNeighborhood] = useState(false);
  const defaultCountry = {
    id: 1,
    name: 'Brasil',
    abbreviation: 'br',
    ddi: 55,
  };
  const updateClientAddressModal = useModal();
  const [clientCurrentAddress, setClientCurrentAddress] = useState(null);
  const [client, setClient] = useState(null);
  const [clientCurrentAddressIndex, setClientCurrentAddressIndex] =
    useState(null);

  async function fetchProvinces(search, uf, country) {
    try {
      const response = await api.get('cities', {
        params: { search, uf, country },
      });
      return response.data;
    } catch (ex) {
      return [];
    }
  }

  function handleChangeValue(index, key, value) {
    setData(old => ({
      ...old,
      destinations: old.destinations?.map((item, idx) => {
        if (index === idx) {
          return { ...item, [key]: value };
        }

        return item;
      }),
    }));
  }
  function handleDestinationClientSelection(event, index) {
    disableAddress({}, null);
    if (event !== null) {
      const currentAddress = event?.addresses.filter(
        address => address.current
      )[0];
      if (checkClientAddressData(currentAddress)) {
        snackbar.show(
          <Text>Endereço do cliente desatualizado! Complete o cadastro</Text>,
          { type: 'error' }
        );
        setClientCurrentAddressIndex(index);
        setClientCurrentAddress(currentAddress);
        setClient(event);
        return updateClientAddressModal.open();
      } else {
        setData(old => ({
          ...old,
          destinations: old.destinations?.map((item, idx) => {
            if (index === idx) {
              return {
                ...item,
                client: event,
                address: currentAddress?.address,
                number: currentAddress?.number,
                neighborhood: currentAddress?.neighborhood,
                complement: currentAddress?.complement,
                zipCode: currentAddress?.zip_code,
                lat: currentAddress?.lat,
                lng: currentAddress?.lng,
                city: currentAddress?.city_name,
                cityModel: currentAddress?.city_name
                  ? {
                      id: currentAddress?.city_id,
                      name: currentAddress?.city_name,
                      province: {
                        uf: currentAddress?.uf_name,
                      },
                    }
                  : undefined,
                province: currentAddress?.uf_name,
                formattedAddress: currentAddress?.formatted_address,
              };
            }

            return item;
          }),
        }));
      }
    } else {
      setData(old => ({
        ...old,
        destinations: old.destinations?.map((item, idx) => {
          if (index === idx) {
            return {
              ...item,
              client: null,
              address: '',
              number: '',
              neighborhood: '',
              complement: '',
              zipCode: '',
              lat: '',
              lng: '',
              city: '',
              cityModel: null,
              province: '',
              formatted_address:''
            };
          }

          return item;
        }),
      }));
    }
  }

  function disableAddress(address, city) {
    setDisableNumber(address.number ? true : false);
    setDisableZipCode(address.zip_code ? true : false);
    setDisableCity(city ? true : false);
    setDisableProvince(city?.province ? true : false);
    setDisableNeighborhood(address.neighborhood ? true : false);
  }

  function handleDeleteCard(idx) {
    setData(old => ({
      ...old,
      destinations: old.destinations.filter((_, index) => index !== idx),
    }));
    setCurrentIndex(idx - 1);
  }

  async function setDestinationCity(index, city, options) {
    let cityModel = city;
    if (options.action === 'create-option') {
      cityModel = {
        name: city.id,
        province: null,
      };
    }

    if (cityModel) {
      handleChangeValue(index, 'cityModel', cityModel);
      handleChangeValue(index, 'province', cityModel?.province?.uf || '');
    } else {
      handleChangeValue(index, 'cityModel', null);
      handleChangeValue(index, 'province', '');
    }
  }
  function handleGoBack(idx) {
    if (idx < 1) {
      return;
    }
    setCurrentIndex(idx - 1);
  }
  function handleGoToNext(idx) {
    if (idx + 1 > data?.destinations?.length) {
      return;
    }
    setCurrentIndex(idx + 1);
  }
  function handleAddNewDestination() {
    setCurrentIndex(data?.destinations?.length);
    setData(old => ({
      ...old,
      destinations: [
        ...old.destinations,
        {
          address: '',
          type: DELIVERY_TYPE_OPTIONS[1],
          date: '',
          complement: '',
          lat: '',
          lng: '',
          formattedAddress: '',
          neighborhood: '',
          city: '',
          province: '',
          zipCode: '',
          country: {
            id: 1,
            name: 'Brasil',
            abbreviation: 'br',
            ddi: 55,
          },
          number: '',
          startSchedule: '',
          index: data?.destinations?.length + 1,
        },
      ],
    }));
  }
  function handleUpdateAddress(data) {
    setData(old => ({
      ...old,
      destinations: old.destinations?.map((item, idx) => {
        if (clientCurrentAddressIndex === idx) {
          return {
            ...item,
            client: client,
            address: data?.address,
            number: data?.number,
            neighborhood: data?.neighborhood,
            complement: data?.complement,
            zipCode: data?.zip_code,
            lat: data?.lat,
            lng: data?.lng,
            city: data?.city_name,
            cityModel: data?.city_name
              ? {
                  id: data?.city_id,
                  name: data?.city_name,
                  province: {
                    uf: data?.uf_name,
                  },
                }
              : undefined,
            province: data?.uf_name,
            formattedAddress: data?.formatted_address,
          };
        }

        return item;
      }),
    }));
  }
  return (
    <Card
      header={
        <Text color="#464E5F" type="header">
          Destinos
        </Text>
      }
      bodyCardPadding="1rem 3rem"
    >
      <Col md={12} xs={12}>
        <div className="mb-2">
          <Text type="header" color="dark">
            Destino {currentIndex + 1}
          </Text>
        </div>
        <Row>
          <UpdateClientAddressData
            show={updateClientAddressModal.isOpen}
            onSubmit={handleUpdateAddress}
            onClose={() => updateClientAddressModal.close()}
            currentAddressData={clientCurrentAddress}
            countryOptions={countryOptions}
          />
          <Col md={6} xs={12} className="mb-3">
            <Select
              label="País *"
              value={data.destinations[currentIndex]?.country}
              options={countryOptions}
              onChange={event => {
                handleChangeValue(currentIndex, 'country', event);
              }}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              error={errors[`destinations[${currentIndex}].country`]}
            />
          </Col>

          <Col md={6} xs={12} className="mb-3">
            <Select.Async
              label="Cliente Tomador"
              onSearch={search =>
                fetchSelectOptions('persons/customers', { search })
              }
              value={data.destinations[currentIndex]?.client}
              getOptionLabel={option => {
                let label = `${option?.social_name} - ${option?.cgccpf}`;

                if (option.city) {
                  label += ` - ${option?.city}`;
                }

                return label;
              }}
              getOptionValue={option => option?.id}
              onChange={event => {
                handleDestinationClientSelection(event, currentIndex);
              }}
            />
          </Col>
          <Col md={8} xs={12} className="mb-3">
            <InputAddress
              label="Logradouro *"
              placeholder=""
              value={data.destinations[currentIndex]?.address}
              onSelectedAddress={async place => {
                handleChangeValue(currentIndex, 'address', place.address || '');
                const [city] = await fetchProvinces(
                  place.city,
                  place.province,
                  place.country_abbreviation
                );
                disableAddress(place, city);

                handleChangeValue(
                  currentIndex,
                  'number',
                  place.number ? place.number : ''
                );
                handleChangeValue(
                  currentIndex,
                  'zipCode',
                  place.zip_code ? place.zip_code : ''
                );
                handleChangeValue(currentIndex, 'cityModel', city);
                handleChangeValue(
                  currentIndex,
                  'lat',
                  place.lat ? place.lat : ''
                );
                handleChangeValue(
                  currentIndex,
                  'lng',
                  place.lng ? place.lng : ''
                );
                handleChangeValue(
                  currentIndex,
                  'complement',
                  place.name ? place.name : ''
                );
                handleChangeValue(
                  currentIndex,
                  'province',
                  city?.province?.uf || ''
                );
                handleChangeValue(
                  currentIndex,
                  'formattedAddress',
                  place.formatted_address ? place.formatted_address : ''
                );
                handleChangeValue(
                  currentIndex,
                  'neighborhood',
                  place.neighborhood ? place.neighborhood : ''
                );
              }}
              onChange={({ target }) => {
                handleChangeValue(currentIndex, 'address', target.value);
              }}
              types={[]}
              error={errors[`destinations[${currentIndex}].address`]}
              country={
                data?.destinations[currentIndex]?.country
                  ? data?.destinations[currentIndex]?.country.abbreviation
                  : null
              }
            />
          </Col>
          <Col md={4} xs={12} className="mb-3">
            <Input
              label="Número"
              disabled={disableNumber}
              value={data.destinations[currentIndex]?.number}
              onChange={({ target }) => {
                handleChangeValue(currentIndex, 'number', target.value);
              }}
            />
          </Col>
          <Col md={4} xs={12}>
            <Input
              label="Complemento"
              value={data.destinations[currentIndex]?.complement}
              onChange={({ target }) => {
                handleChangeValue(currentIndex, 'complement', target.value);
              }}
            />
          </Col>
          <Col md={4} xs={12} className="mb-3">
            <Input
              label="Bairro"
              disabled={disableNeighborhood}
              value={data.destinations[currentIndex]?.neighborhood}
              onChange={({ target }) => {
                handleChangeValue(currentIndex, 'neighborhood', target.value);
              }}
            />
          </Col>
          <Col md={4} xs={12} className="mb-3">
            <Input
              label="CEP"
              disabled={disableZipCode}
              value={data.destinations[currentIndex]?.zipCode}
              onChange={({ target }) => {
                handleChangeValue(currentIndex, 'zipCode', target.value);
              }}
            />
          </Col>
          <Col md={8} xs={12} className="mb-3">
            <Select.Async
              label="Cidade *"
              onSearch={city =>
                fetchProvinces(
                  city,
                  '',
                  data.destinations[currentIndex]?.country?.abbreviation ||
                    defaultCountry?.abbreviation
                )
              }
              value={data.destinations[currentIndex]?.cityModel}
              horizontal
              creatable
              onChange={(value, options) =>
                setDestinationCity(currentIndex, value, options)
              }
              getOptionLabel={option =>
                `${`${option.name} ${
                  option.province?.uf ? ` - ${option.province?.uf}` : ''
                }`}`
              }
              getOptionValue={option =>
                `${`${option.name} ${
                  option.province?.uf ? `- ${option.province?.uf}` : null
                }`}`
              }
              error={errors[`destinations[${currentIndex}].cityModel`]}
              isDisabled={disableCity}
            />
          </Col>
          <Col md={4} xs={12} className="mb-3">
            <Input
              label="UF *"
              disabled={disableProvince}
              value={data.destinations[currentIndex]?.province}
              onChange={({ target }) => {
                handleChangeValue(currentIndex, 'province', target.value);
              }}
              error={errors[`destinations[${currentIndex}].province`]}
            />
          </Col>
          <Col md={6} className="mb-3">
            <Input
              label="Latitude"
              disabled={true}
              value={data.destinations[currentIndex]?.lat}
              error={errors[`destinations[${currentIndex}].lat`]}
              tooltip="Selecione um endereço sugerido para obter dados de latitude e longitude"
            />
          </Col>
          <Col md={6} className="mb-3">
            <Input
              label="Longitude"
              disabled={true}
              value={data.destinations[currentIndex]?.lng}
              error={errors[`destinations[${currentIndex}].lng`]}
              tooltip="Selecione um endereço sugerido para obter dados de latitude e longitude"
            />
          </Col>
          <Col md={6} xs={12} className="mb-3">
            <Input
              type="datetime-local"
              value={data.destinations[currentIndex]?.startSchedule}
              label="Início da entrega *"
              onChange={({ target }) => {
                let isValid = validateDateTime(target?.value);
                if (isValid) {
                  handleChangeValue(
                    currentIndex,
                    'startSchedule',
                    target.value
                  );
                  setErrors(old => ({
                    ...old,
                    [`destinations[${currentIndex}].startSchedule`]: null,
                  }));
                } else {
                  handleChangeValue(
                    currentIndex,
                    'startSchedule',
                    target.value
                  );
                  setErrors(old => ({
                    ...old,
                    [`destinations[${currentIndex}].startSchedule`]:
                      'Data inválida',
                  }));
                }
              }}
              error={
                errors[`destinations[${currentIndex}].startSchedule`] ||
                errors[`destinations[${currentIndex}]`] ||
                destinationsError[`${currentIndex}.startSchedule`]
              }
            />
          </Col>
          <Col md={6} xs={12} className="mb-3">
            <Input
              type="datetime-local"
              label={
                data?.destinations[currentIndex]?.type?.id === 'collect'
                  ? 'Data máxima da coleta *'
                  : 'Data máxima da entrega *'
              }
              value={data?.destinations[currentIndex]?.date}
              onChange={({ target }) => {
                let isValid = validateDateTime(target?.value);
                if (isValid) {
                  handleChangeValue(currentIndex, 'date', target.value);
                  setErrors(old => ({
                    ...old,
                    [`destinations[${currentIndex}].date`]: null,
                  }));
                } else {
                  handleChangeValue(currentIndex, 'date', target.value);
                  setErrors(old => ({
                    ...old,
                    [`destinations[${currentIndex}].date`]: 'Data inválida',
                  }));
                }
              }}
              error={
                errors[`destinations[${currentIndex}].date`] ||
                destinationsError[`${currentIndex}.date`]
              }
            />
          </Col>

          <Col md={6} xs={12}>
            <Select
              label="Ação *"
              value={data.destinations[currentIndex]?.type}
              onChange={value => {
                handleChangeValue(currentIndex, 'type', value);
              }}
              getOptionLabel={option => option?.name}
              getOptionValue={option => option?.id}
              options={DELIVERY_TYPE_OPTIONS}
              error={errors[`destinations[${currentIndex}].type`]}
            />
          </Col>
          <Col md={6} xs={12} className="mt-4">
            <IconButton
              title="Remover destino"
              style={{ marginTop: 12 }}
              icon="FaTrash"
              variant="error"
              disabled={currentIndex === 0}
              onClick={() => handleDeleteCard(currentIndex)}
            />
          </Col>
        </Row>
      </Col>

      <Row className="form" style={{ justifyContent: 'end' }}>
        <Col
          md={6}
          xs={12}
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
            justifyContent: 'end',
          }}
        >
          <StyledDestinationNavButton
            variant={'secondary'}
            disabled={currentIndex < 1}
            title="Destino anterior"
            onClick={() => handleGoBack(currentIndex)}
          >
            <Text>{'<'}</Text>
          </StyledDestinationNavButton>
          <StyledDestinationNavButton
            variant={'secondary'}
            title="Próximo destino"
            disabled={data?.destinations?.length === currentIndex + 1}
            onClick={() => handleGoToNext(currentIndex)}
          >
            <Text>{'>'}</Text>
          </StyledDestinationNavButton>
          <Button onClick={() => handleAddNewDestination()}>
            <Text weight={500} type="regular">
              Adicionar mais 1 destino
            </Text>
          </Button>
        </Col>
      </Row>
    </Card>
  );
}
