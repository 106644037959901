import React from 'react';

import Loading from 'components/Loading';

import StyledButton from './styles';

function Button({
  color = 'primary',
  children,
  loading,
  type = 'button',
  ...rest
}) {
  return (
    <StyledButton color={color} type={type} {...rest}>
      {loading ? <Loading size={20} /> : children}
    </StyledButton>
  );
}

export default Button;
