import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Row } from 'react-bootstrap';
import api from 'services/api';
import { FaRegCheckCircle } from 'react-icons/fa';

import theme from 'v3/theme';
import Snackbar from 'components/Snackbar';
import ApplicationLayout from 'v3/layouts/Application';
import { Button, Card, Input, Text } from 'v3/components';
import { PasswordCol, StyledIconButton } from './styles';
import { validatePasswordUpdate } from './validator';
import Space from 'v3/components/Space';
import { ForgotPasswordModal } from './ForgotPasswordModal';
import { isEmailValidRegex } from 'v3/utils/constants';

export function UpdatePasswordPage({}) {
  const [time, setTime] = useState(5);
  const [loading, setLoading] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [newPassword, setNewPassord] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPasswordConfirmation, setNewPassordConfirmation] = useState('');
  const [errors, setErrors] = useState({});
  const passwordTooltip = `Mínimo de 8 caracteres, sendo 1 letra maiúscula, 1 letra minúscula e pelo menos 1 caracter especial. Caracteres especiais aceitos ! " # $ % & ' ( ) * + , - . / : ; < = > ? @ [ [ \\ \\ ] ] ^ _ \` { | } ~`;
  const [snackBar, setSnackBar] = useState({
    display: false,
    type: 'error',
    message: '',
  });
  const [passwordVisibility, setPasswordVisibility] = useState({
    old: false,
    new: false,
    confirmation: false,
  });
  useEffect(() => {
    let interval;
    if (updated && time > 0) {
      interval = setInterval(() => {
        setTime(time - 1);
      }, 1000);
    } else if (!updated && time !== 0) {
      clearInterval(interval);
    }
    return () => {
      clearInterval();
    };
  }, [updated, time]);

  useEffect(() => {
    if (time === 0) {
      window.location.reload();
    }
  }, [time]);
  async function handleUpdatePassword() {
    try {
      setLoading(true);
      const isValid = await validatePasswordUpdate(
        { oldPassword, newPassword, newPasswordConfirmation },
        setErrors
      );
      if (!isValid) {
        setLoading(false);

        return;
      }
      const data = { newPassword, newPasswordConfirmation, oldPassword };
      await api.put('v3/user/updatePassword', data);
      setSnackBar({
        type: 'success',
        message: 'Senha atualizada com sucesso',
        display: true,
      });
      setUpdated(true);
      setLoading(false);
    } catch (error) {
      let message =
        error?.response?.data?.message || 'Não foi possível atualizar a senha';
      setSnackBar({
        type: 'error',
        message,
        display: true,
      });
      setLoading(false);
    }
  }

  const [openForgotPasswordModal, setOpenForgotPasswordModal] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [loadingRecoverPassword, setLoadingRecoverPassword] = useState(false);
  const history = useHistory();

  const handleRecoverPassword = useCallback(async () => {
    setLoadingRecoverPassword(true);
    try {
      const isValidEmail = isEmailValidRegex.test(userEmail);
      if (!isValidEmail) {
        setSnackBar({
          type: 'error',
          display: true,
          message: 'Forneça um email válido!',
        });
        return;
      }
      const response = await api.post('forgot-password', {
        email: userEmail,
      });

      if (response.status === 200) {
        setSnackBar({
          type: 'success',
          message: 'Enviamos o link para alteração de senha para seu e-mail.',
          display: true,
        });
        setUserEmail('');
        setTimeout(() => {
          history.push('/login');
        }, 1000);
      }
    } catch (ex) {
      const message = ex?.response
        ? ex?.response.data.message
        : 'Algo deu errado, tente novamente mais tarde.';
      setSnackBar({
        type: 'error',
        display: true,
        message,
      });
    } finally {
      setLoadingRecoverPassword(false);
    }
  }, [userEmail]);

  function handleCloseModal() {
    if (loadingRecoverPassword) {
      return;
    }
    setOpenForgotPasswordModal(false);
  }

  return (
    <ApplicationLayout hideMenu title="Atualizar senha">
      <Snackbar
        open={snackBar.display}
        type={snackBar.type}
        message={snackBar.message}
        onClose={() => setSnackBar(false)}
      />
      {updated ? (
        <Card>
          <div className="text-center">
            <FaRegCheckCircle
              size={26}
              color={theme.colors.success}
              className="mr-2 mb-1"
            />
            <Text type="header" color="success">
              {`Senha atualizada! Você será redirecionado em ${time} segundos...`}
            </Text>
          </div>
        </Card>
      ) : (
        <>
          <ForgotPasswordModal
            isOpen={openForgotPasswordModal}
            onClose={handleCloseModal}
            onSubmit={handleRecoverPassword}
            email={userEmail}
            setEmail={setUserEmail}
            loadingRecoverPassword={loadingRecoverPassword}
          />
          <Card
            header={
              <Text type="header" color="dark">
                Atualização de senha
              </Text>
            }
            HeaderRightComponent={
              <Space x={'0.5rem'}>
                <Button
                  onClick={() => setOpenForgotPasswordModal(true)}
                  disabled={loading}
                  variant={'secondary'}
                >
                  <Text>Esqueci a senha</Text>
                </Button>
                <Button onClick={handleUpdatePassword} loading={loading}>
                  <Text>Salvar</Text>
                </Button>
              </Space>
            }
          >
            <Row>
              <PasswordCol xs={12}>
                <Input
                  type={passwordVisibility?.old ? 'text' : 'password'}
                  label="Senha antiga *"
                  value={oldPassword}
                  onChange={e => setOldPassword(e.target.value)}
                  error={errors?.oldPassword}
                />
                <StyledIconButton
                  variant="primary"
                  onClick={() =>
                    setPasswordVisibility(state => ({
                      ...state,
                      old: !passwordVisibility?.old,
                    }))
                  }
                  icon={passwordVisibility?.old ? 'FaEye' : 'FaEyeSlash'}
                  mb={errors?.oldPassword ? '30px' : '5px'}
                />
              </PasswordCol>

              <PasswordCol xs={12}>
                <Input
                  tooltip={passwordTooltip}
                  type={passwordVisibility?.new ? 'text' : 'password'}
                  label="Sua nova senha *"
                  value={newPassword}
                  onChange={e => setNewPassord(e.target.value)}
                  error={errors?.newPassword}
                />
                <StyledIconButton
                  variant="primary"
                  onClick={() =>
                    setPasswordVisibility(state => ({
                      ...state,
                      new: !passwordVisibility?.new,
                    }))
                  }
                  icon={passwordVisibility?.new ? 'FaEye' : 'FaEyeSlash'}
                  mb={errors?.newPassword ? '30px' : '5px'}
                />
              </PasswordCol>
              <PasswordCol xs={12}>
                <Input
                  label="Confirme sua nova senha *"
                  type={passwordVisibility?.confirmation ? 'text' : 'password'}
                  value={newPasswordConfirmation}
                  onChange={e => setNewPassordConfirmation(e.target.value)}
                  error={errors?.newPasswordConfirmation}
                />
                <StyledIconButton
                  variant="primary"
                  onClick={() =>
                    setPasswordVisibility(state => ({
                      ...state,
                      confirmation: !passwordVisibility?.confirmation,
                    }))
                  }
                  icon={
                    passwordVisibility?.confirmation ? 'FaEye' : 'FaEyeSlash'
                  }
                  mb={errors?.newPasswordConfirmation ? '30px' : '5px'}
                />
              </PasswordCol>
            </Row>
          </Card>
        </>
      )}
    </ApplicationLayout>
  );
}
