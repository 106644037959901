import React from 'react';

import Button from 'components/Button';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {} from './style';

export default function Prompt(props) {
  const [open, setOpen] = React.useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby={props.title}
        aria-describedby={props.text}
        disableBackdropClick={props.disableBackdropClick}
      >
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container spacing={1}>
              <Grid item md={12}>
                {props.text}
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {props.label.map(button => (
            <Button onClick={() => button.action()} color={button.type}>
              {button.name}
            </Button>
          ))}
        </DialogActions>
      </Dialog>
    </>
  );
}
