/* eslint-disable import/no-unresolved */
import api from 'services/api';

export async function fetchAutomations() {
  try {
    const response = await api.get('automations');

    return response.data;
  } catch (ex) {
    return [];
  }
}
