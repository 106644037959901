import React, { useEffect, useState } from 'react';

import { Modal, Text, TreeSelectComponent } from 'v3/components';
import { usePolicyRegister } from '../../context';
import { sanitize } from 'dompurify';
import Space from 'v3/components/Space';
import { fetchNcmProducts } from 'utils/fetches';
import { StyledModalButton } from 'v3/pages/Policy/styles';
import { validateProduct } from '../../utils';

export function ProductsModal() {
  const {
    productsModal,
    setOperations,
    currentOpIndex,
    setCurrentOpIndex,
    currentPdIndex,
    setCurrentPdIndex,
    operations,
    setErrors,
  } = usePolicyRegister();
  const [newProduct, setNewProduct] = useState([]);
  const [productsOptions, setProductsOptions] = useState([]);
  const [fetchingNcm, setFetchingNcm] = useState(false);
  const [error, setError] = useState({});

  function handleAdd() {
    const products = operations[currentOpIndex].products?.map(p => p.products);
    const newProductsCode = newProduct?.map(p => p?.code);
    const isValid = validateProduct(products, newProductsCode);
    if (!isValid) {
      setError({
        product: 'Algum produto informado já está contemplado na operação',
      });
      return;
    }
    setOperations(oldOperations =>
      oldOperations.map((op, index) => {
        if (index === currentOpIndex) {
          return {
            ...op,
            products: [
              ...op?.products,
              {
                products: [...newProduct],
                vehicles: [],
                responsibility_security: [],
                routes: [],
              },
            ],
          };
        } else return op;
      })
    );
    setNewProduct([]);
    setCurrentOpIndex(null);
    setCurrentPdIndex(null);
    setError({});
    setErrors({});
    productsModal.close();
  }

  function handleEdit() {
    const products = operations[currentOpIndex].products
      ?.filter((_, index) => index !== currentPdIndex)
      .map(p => p?.products);
    const newProductsCode = newProduct?.map(p => p?.code);
    const isValid = validateProduct(products, newProductsCode);
    if (!isValid) {
      setError({
        product: 'Algum produto informado já está contemplado na operação',
      });
      return;
    }

    let newState = [...operations];
    newState[currentOpIndex].products[currentPdIndex].products = [
      ...newProduct,
    ];
    setOperations(newState);

    setNewProduct([]);
    setCurrentOpIndex(null);
    setCurrentPdIndex(null);
    setError({});
    productsModal.close();
  }
  
  const convertToTreeData = node => {
    return {
      value: node.id,
      code: node?.code,
      title: `${node?.code} - ${node?.name}`,
      name: node?.name,
      chapterName: node?.chapterName,
      chapterCode: node?.chapterCode,
      children: node.children ? node.children.map(convertToTreeData) : [],
    };
  };

  async function handleSearch(value) {
    try {
      setFetchingNcm(true);
      const response = await fetchNcmProducts(value);
      const processedResponse = response.map(convertToTreeData);
      setProductsOptions(processedResponse);
    } catch (error) {
    } finally {
      setFetchingNcm(false);
    }
  }

  const filterTreeNode = (input, treeNode) => {
    return treeNode;
  };

  function handleCloseModal() {
    setNewProduct([]);
    setCurrentOpIndex(null);
    setCurrentPdIndex(null);
    setError({});
    setErrors({});
    productsModal.close();
  }

  useEffect(() => {
    if (
      currentOpIndex !== null &&
      currentPdIndex !== null &&
      productsModal.isOpen
    ) {
      setNewProduct(
        operations[currentOpIndex].products[currentPdIndex]?.products
      );
    }
  }, [productsModal, currentOpIndex, currentPdIndex]);

  return (
    <Modal
      size="lg"
      show={productsModal.isOpen}
      handleClose={handleCloseModal}
      heading={
        <Text type="body" color="dark" weight="400">
          {currentOpIndex !== null && currentPdIndex !== null
            ? 'Editar produto'
            : 'Novo produto'}
        </Text>
      }
      body={
        <div>
          <TreeSelectComponent
            label="Digite o código ou nome ncm"
            labelInValue
            showSearch
            dropdownStyle={{
              maxHeight: 400,
              overflow: 'auto',
              border: '1px solid #e5eaee',
            }}
            onSearch={inputValue => handleSearch(sanitize(inputValue))}
            filterTreeNode={filterTreeNode}
            treeData={productsOptions}
            placeholder={'Digite para buscar'}
            allowClear={true}
            loading={fetchingNcm}
            multiple
            onBlur={() => setProductsOptions([])}
            value={newProduct?.map(item => {
              return item?.id && item?.chapterCode
                ? {
                    label: (
                      <span>
                        <i>
                          {item.chapterCode}
                          {' - '}
                          {item.chapterName}{' '}
                        </i>
                        <br />

                        <span>
                          {item.code}
                          {' - '}
                          {item.name}
                        </span>
                      </span>
                    ),
                    value: item?.id,
                  }
                : item?.chapter
                ? {
                    label: (
                      <span>
                        <i>
                          {item?.chapter?.code}
                          {' - '}
                          {item?.chapter?.name}{' '}
                        </i>
                        <br />

                        <span>
                          {item?.code}
                          {' - '}
                          {item?.name}
                        </span>
                      </span>
                    ),
                    value: item?.id,
                  }
                : item?.id
                ? {
                    label: (
                      <span>
                        <span>
                          {item?.code} {' - '}
                          {item?.name}
                        </span>
                      </span>
                    ),
                    value: item?.id,
                  }
                : null;
            })}
            onChange={value => {
              if (value?.length === 0 || !value) {
                setNewProduct([]);
              } else {
                let valuesIds = value?.map(item => item.value);
                let filtered = newProduct.filter(item =>
                  valuesIds.includes(item.id)
                );
                setNewProduct(filtered);
              }
            }}
            onSelect={(value, node) => {
              setNewProduct(old => [
                ...old,
                {
                  label: `${node?.code} - ${node?.name}`,
                  id: node?.value,
                  value: node?.value,
                  name: node?.name,
                  code: node?.code,
                  chapterCode: node?.chapterCode,
                  chapterName: node?.chapterName,
                },
              ]);
            }}
            error={error?.product}
          />
        </div>
      }
      footer={
        <Space x={'0.5rem'}>
          <StyledModalButton
            variant="secondary"
            size="sm"
            onClick={handleCloseModal}
          >
            <Text>Cancelar</Text>
          </StyledModalButton>
          <StyledModalButton
            size="sm"
            onClick={
              currentOpIndex !== null && currentPdIndex !== null
                ? handleEdit
                : handleAdd
            }
            disabled={newProduct?.length === 0}
          >
            <Text>
              {currentOpIndex !== null && currentPdIndex !== null
                ? 'Editar'
                : 'Adicionar'}
            </Text>
          </StyledModalButton>
        </Space>
      }
    />
  );
}
