import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import theme from 'v3/theme';


export const Timeline = styled.div`
  width: 100%;
  height: 0;
  border: 1px dashed ${theme.colors.medium_gray};
  margin: 0 5px;
  position: relative;
  display: flex;
  justify-content: flex-end;
`;

Timeline.Last = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${theme.colors.medium_gray};
`;




export const DelayInfoContainer = styled.div`
  display: inline-flex;
  flex: 1;
  gap: 10px;
  background: ${theme.colors.dark_blue};
  color: ${theme.colors.white};
  border-radius: 8px;
  padding: 4px 12px;
  & > span {
    display: flex;
    align-items: center;
  }
`;

export const StyledSpan = styled.span`
title: ${props => props.title};  
cursor: help;
font-size:12px; 

&::before {
  content: '';
  display: inline-block;
  width: 0.9rem;
  margin-right:9px;
  height: 0.8rem;
  border-radius: 50%;
  background-color: ${props => props.color};
  }
`;
export const ColStyled = styled(Col)`
  display: flex;
  flex-direction: Row;
  align-items: center;
  
  justify-content: center;
  margin-bottom: 3%
`;
export const CardShadowBoxStyled = styled(Col)`  
  text-align: center;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 30px; 
  padding: 1.1rem;
  min-height: ${props => props.cardHeight ? props.cardHeight : '265px'};
  margin-top: ${props => props.MarginTop  ? props.MarginTop  : 'auto'};
`;

export const DelayCardStyled = styled.div`
  margin-top: 12px;
`;

export const TravelStatusCardsStyle = styled(Row)`
  margin-left: 1%,;
  flex: 2 0 25%;
`;


