/* eslint-disable import/no-unresolved */
// eslint-disable-next-line no-restricted-globals

import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import { FaCheckCircle, FaStar, FaCircle } from 'react-icons/fa';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Card from 'v3/components/Card';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';

import theme from 'v3/theme';
import { BadgeActive } from 'v3/components';

function CardDriver({ driver }) {
  const history = useHistory();

  const [rating, setRating] = useState();

  function driverEdit(driverId) {
    history.push(`/motoristas/${driverId}`);
  }

  useEffect(() => {
    if (driver.avg_general + driver.avg_schedule + driver.avg_app === null) {
      setRating(0);
    }
    setRating(
      ((driver.avg_general + driver.avg_schedule + driver.avg_app) / 3).toFixed(
        2
      )
    );
  }, []);

  return (
    <Col lg={6} md={6} xs={12}>
      <Card
        header={
          <>
            <Text transform="uppercase" color="dark" type="regular">
              {driver.social_name.substring(0,29)}
              {driver.social_name.length > 29 && '...'}
            </Text>
            {!driver?.deleted_at ? (
              <BadgeActive pill light type="success" className="mr-2">
                <Text type="label">Ativo</Text>
              </BadgeActive>
            ) : (
              <BadgeActive pill light type="error" className="mr-2">
                <Text type="label">Inativo</Text>
              </BadgeActive>
            )}
          </>
        }
        HeaderRightComponent={
          <Button className="py-2" onClick={() => driverEdit(driver.id)}>
            <Text color="white" type="regular" weight="500">
              Detalhes
            </Text>
          </Button>
        }
      >
        <Row>
          <Col lg={4} md={4} xs={6}>
            <Text type="regular" color="gray">
              {driver.app_uninstalled != null && driver.app_uninstalled <= 2 ? (
                <FaCheckCircle size={15} color={theme.colors.success} />)
              : driver.app_uninstalled > 2 ? (
                <FaCheckCircle size={15} color={theme.colors.warning} />)
              :(
                <FaCircle size={15} color={theme.colors.danger} />)
              }
              {driver.app_uninstalled != null && driver.app_uninstalled <= 2 ? (' Ativo no APP')
              : driver.app_uninstalled > 2 ? (' App inativo')
              : ' Inativo no APP'}
            </Text>
          </Col>
          <Col lg={4} md={4} xs={6}>
            <Text type="regular" color="gray">
              {driver.props && driver.props.verified_person ? (
                <FaCheckCircle size={15} color={theme.colors.light_blue} />
              ):
              (
              <FaCircle size={15} color={theme.colors.danger} />)
              }
              {driver.props && driver.props.verified_person ? (' Verificado') : (' Não Verificado')}
            </Text>
          </Col>
        </Row>
        <Row>
          <Col lg={4} md={4} xs={6}>
            <Text type="regular" color="gray">
              {driver.cgccpf}
            </Text>
          </Col>
          <Col lg={4} md={4} xs={6}>
            <Text type="regular" color="gray">
              {driver.phone}
            </Text>
          </Col>
          <Col lg={4} md={4} xs={6}>
            <Text type="regular" color="gray">
              {driver.__meta__.total_travels} Viagens | {rating}
            </Text>
            <FaStar size={12} color="#FFA800" className="p-0 mb-2 ml-1" />
          </Col>
        </Row>
      </Card>
    </Col>
  );
}

export default CardDriver;
