/* eslint-disable import/no-unresolved */
import React, { useMemo } from 'react';
import { FaStar, FaRegStar } from 'react-icons/fa';

import theme from 'v3/theme';

import * as Styled from './styles';

const RATING_QUANTITY_DEFAULT = 5;

function Star({ index, isFilled, onChange }) {
  const rate = useMemo(() => index + 1, [index]);

  let Component = FaRegStar;

  if (isFilled) {
    Component = FaStar;
  }

  return (
    <Styled.Button type="button" onClick={() => onChange(rate)}>
      <Component color={theme.colors.yellow_rating_star} size={20} />
    </Styled.Button>
  );
}

export default function Rating({ rate, onChange }) {
  return (
    <div>
      {new Array(RATING_QUANTITY_DEFAULT).fill(0).map((_, index) => (
        <Star index={index} isFilled={rate >= index + 1} onChange={onChange} />
      ))}
    </div>
  );
}
