import React, { useEffect, useState } from 'react';
import { Button, Text } from 'v3/components';
import { useTravel } from 'v3/pages/Travel';
import Space from 'v3/components/Space';
import { cooperplaceApi } from 'services/api';
import { useSnackbar } from 'v3/components/Snackbar';
import { StyledModal } from './styles';
import { useRiskManager } from '../context';
import { ManualValidationsAudict } from '../components/Audict';
import { ManualValidationsBody } from '../components/ManualValidationsBody';

export function PolicyManualValidationModal() {
  const travel = useTravel();
  const {
    manualValidationModal,
    manualValidationOptions,
    travelManualValidations,
    setTravelManualValidations,
  } = useRiskManager();

  const snackbar = useSnackbar();
  const [previousValues, setPreviousValues] = useState(null);
  const [submittingValidations, setSubmittingValidations] = useState(false);
  const [showAudict, setShowAudict] = useState(false);

  useEffect(() => {
    if (manualValidationModal.isOpen) {
      setPreviousValues(
        travelManualValidations ? { ...travelManualValidations } : null
      );
    }
  }, [manualValidationModal.isOpen]);

  function handleCancel() {
    setTravelManualValidations(previousValues);
    setShowAudict(false);
    manualValidationModal.close();
  }
  async function handleUpdateManualValidations() {
    try {
      setSubmittingValidations(true);
      const response = await cooperplaceApi.put(
        `travels/${travel?.data?.id}/policy-manual-validation`,
        {
          ...travelManualValidations,
        }
      );
      snackbar.show(<Text>Validações manuais atualizada com sucesso!</Text>, {
        type: 'success',
      });
      setTravelManualValidations(response?.data);
      setPreviousValues(response.data);
      setSubmittingValidations(false);
    } catch (error) {
      snackbar.show(<Text>Erro ao atualizar validações manuais!</Text>, {
        type: 'error',
      });
      setSubmittingValidations(false);
    }
  }

  return (
    <StyledModal
      backdrop="static"
      scrollable
      show={manualValidationModal.isOpen}
      handleClose={manualValidationModal.close}
      closeButton={false}
      heading={
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Text type="header" color="dark">
            Validação GRO/Seguros
          </Text>
          <Button onClick={() => setShowAudict(old => !old)} size="sm">
            <Text type="regular">
              {showAudict ? 'Validações' : 'Auditoria'}
            </Text>
          </Button>
        </div>
      }
      body={
        showAudict ? (
          <ManualValidationsAudict logs={travelManualValidations?.logs} />
        ) : (
          <ManualValidationsBody
            manualValidationOptions={manualValidationOptions}
            setTravelManualValidations={setTravelManualValidations}
            travelManualValidations={travelManualValidations}
          />
        )
      }
      footer={
        <Space x="0.5rem" y="0.5rem">
          <Button
            variant="secondary"
            onClick={handleCancel}
            disabled={submittingValidations}
            size="sm"
          >
            <Text type="regular" weight={500}>
              Cancelar
            </Text>
          </Button>
          <Button
            onClick={handleUpdateManualValidations}
            loading={submittingValidations}
            variant="success"
            size="sm"
          >
            <Text type="regular" weight={500}>
              Salvar
            </Text>
          </Button>
        </Space>
      }
    />
  );
}
