import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import { cooperplaceApi } from 'services/api';
import { useSnackbar } from 'v3/components/Snackbar';
import Text from 'v3/components/Text';

import { formatDate, formatNumber, formatPlate } from 'v3/utils/formatter';
import { useImplement } from '../shared/context';
import { validate } from '../shared/validator';
import { Section } from '../shared/Section';
import { Data as Inputs } from '../shared/Data';
import { Editable } from '../shared/Editable';

export function Data() {
  const implement = useImplement();
  const snackbar = useSnackbar();
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  async function saveData() {
    setLoading(true);
    const isDataValid = await validate(
      'edit',
      implement.data,
      implement.setErrors
    );

    if (!isDataValid) {
      snackbar.show(<Text>Verifique os campos obrigatórios!</Text>, {
        type: 'error',
      });
      setLoading(false);
      return;
    }

    try {
      const payload = {
        plate: implement?.data?.plate,
        yearModel: implement?.data?.yearModel,
        yearManufacture: implement?.data?.yearManufacture,
        chassi: implement?.data?.chassi,
        renavam: implement?.data?.renavam,
        antt: implement?.data?.antt,
        crlv: implement?.data?.crlv,
        rntrcExpDate: implement?.data?.rntrcExpDate,
        totalGrossWeight: implement?.data?.totalGrossWeight,
        tareMass: implement?.data?.tareMass,
        cubage: implement?.data?.cubage,
        brand: implement?.data?.brand?.label,
        color: implement?.data?.color?.label,
        plateCityId: implement?.data?.city?.id,
        plateCity: implement?.data?.city?.name,
        plateState: implement?.data?.city?.province?.uf,
        model: implement?.data?.model?.label,
        anttType: implement?.data?.anttType?.id,
        manufacturerId: implement?.data?.brand?.id,
        vehicleTypeId: implement?.data?.vehicleType?.id,
        vehicleBodyTypeId: implement?.data?.bodyType?.id,
        vehicleImplementTypeId: implement?.data?.implementType?.id,
        anttAdherence: implement?.data?.anttAdherence,
        rntrcValidated: implement?.data?.rntrcValidated,
      };
      const { data } = await cooperplaceApi.put(
        `/wagons/${implement?.data?.id}`,
        payload
      );
      implement.setData({ rntrcValidated: data?.rntrcValidated });
      implement.updatePrevImplement({
        ...implement.data,
        rntrcValidated: data?.rntrcValidated,
      });
      snackbar.show(<Text>Implemento salvo com sucesso</Text>, {
        type: 'success',
      });
      setEditing(false);
    } catch (error) {
      snackbar.show(<Text>Erro ao salvar implemento</Text>, { type: 'error' });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Section
      Title="Dados do implemento"
      HeaderRightComponent={
        <Editable
          isEditing={editing}
          setEditing={setEditing}
          onSave={saveData}
          isLoading={loading}
        />
      }
      loading={implement?.loading}
    >
      {editing ? (
        <Inputs />
      ) : (
        <Row>
          <Col xs={6}>
            <Text type="label" color="#464E5F">
              Cidade - Estado
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {implement?.data?.city?.name && implement?.data?.province
                ? `${implement?.data?.city?.name} - ${implement?.data?.province}`
                : implement?.data?.city?.name
                ? implement?.data?.city?.name
                : 'Não informado'}
            </Text>
          </Col>
          <Col xs={6}>
            <Text type="label" color="#464E5F">
              Tipo de Veículo
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {implement?.data?.vehicleType?.name || 'Não informado'}
            </Text>
          </Col>
          <Col xs={6}>
            <Text type="label" color="#464E5F">
              Tipo de Implemento
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {implement?.data?.implementType?.name || 'Não informado'}
            </Text>
          </Col>
          <Col xs={6}>
            <Text type="label" color="#464E5F">
              Tipo de Carroceria
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {implement?.data?.bodyType?.name || 'Não informado'}
            </Text>
          </Col>
          <Col xs={6}>
            <Text type="label" color="#464E5F">
              Nível de serviço
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {implement?.data?.descriptionServiceLevel || 'Não informado'}
            </Text>
          </Col>
          <Col md={6} xs={12}>
            <Text type="label" color="#464E5F">
              Placa
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {formatPlate(implement?.data?.plate)}
            </Text>
          </Col>
          <Col md={6} xs={12}>
            <Text type="label" color="#464E5F">
              Renavam
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {implement?.data?.renavam || 'Não informado'}
            </Text>
          </Col>
          <Col md={6} xs={12}>
            <Text type="label" color="#464E5F">
              Tipo de RNTRC
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {implement?.data?.anttType?.name || 'Não informado'}
            </Text>
          </Col>
          <Col md={6} xs={12}>
            <Text type="label" color="#464E5F">
              RNTRC
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {implement?.data?.antt || 'Não informado'}
            </Text>
          </Col>
          <Col xs={12} md={6}>
            <Text type="label" color="#464E5F">
              Data de emissão RNTRC
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {formatDate(implement?.data?.anttAdherence, { isUTC: true }) ||
                'Não informado'}
            </Text>
          </Col>
          <Col xs={12} md={6}>
            <Text type="label" color="#464E5F">
              Validade RNTRC
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {formatDate(implement?.data?.rntrcExpDate, { isUTC: true }) ||
                'Não informado'}
            </Text>
          </Col>
          <Col xs={12} md={6}>
            <Text type="label" color="#464E5F">
              RNTRC validada pelo GR
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {implement?.data?.rntrcValidated ? 'Sim' : 'Não'}
            </Text>
          </Col>
          <Col xs={12} md={6}>
            <Text type="label" color="#464E5F">
              CRLV
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {implement?.data?.crlv || 'Não informado'}
            </Text>
          </Col>
          <Col md={6} xs={12}>
            <Text type="label" color="#464E5F">
              Chassi
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {implement?.data?.chassi || 'Não informado'}
            </Text>
          </Col>

          <Col md={6} xs={12}>
            <Text type="label" color="#464E5F">
              Marca
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {implement?.data?.brand?.name || 'Não informado'}
            </Text>
          </Col>
          <Col md={6} xs={12}>
            <Text type="label" color="#464E5F">
              Modelo
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {implement?.data?.model?.label || 'Não informado'}
            </Text>
          </Col>
          <Col md={6} xs={12}>
            <Text type="label" color="#464E5F">
              Ano do modelo
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {implement?.data?.yearModel || 'Não informado'}
            </Text>
          </Col>
          <Col md={6} xs={12}>
            <Text type="label" color="#464E5F">
              Ano de fabricação
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {implement?.data?.yearManufacture || 'Não informado'}
            </Text>
          </Col>
          <Col md={6} xs={12}>
            <Text type="label" color="#464E5F">
              Cor
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {implement?.data?.color?.label || 'Não informado'}
            </Text>
          </Col>
          <Col md={6} xs={12}>
            <Text type="label" color="#464E5F">
              PBT (Peso bruto total) em kg
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {implement?.data?.totalGrossWeight
                ? formatNumber(implement?.data?.totalGrossWeight)
                : 'Não informado'}
            </Text>
          </Col>
          <Col md={6} xs={12}>
            <Text type="label" color="#464E5F">
              Tara em kg
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {implement?.data?.tareMass
                ? formatNumber(implement?.data?.tareMass)
                : 'Não Informado'}
            </Text>
          </Col>
          <Col md={6} xs={12}>
            <Text type="label" color="#464E5F">
              Cubagem em m³
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {implement?.data?.cubage
                ? formatNumber(implement?.data?.cubage)
                : 'Não informado'}
            </Text>
          </Col>
          {implement?.data?.cooperativeVehicleType?.cooperative_type && (
            <Col md={6} xs={12}>
              <Text type="label" color="#464E5F">
                Tipo cooperado
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {implement.data.cooperativeVehicleType.cooperative_type}
              </Text>
            </Col>
          )}
        </Row>
      )}
    </Section>
  );
}
