import { QUESTION_TYPES_ALLOWED_TO_REGISTER_ANSWER } from './utils';

const Yup = require('yup');

export const QuestionSchema = Yup.object().shape({
  question: Yup.object().shape({
    needAttachment: Yup.boolean(),
    createOccurrence: Yup.boolean(),
    occurrenceSector: Yup.number().when('createOccurrence', {
      is: true,
      then: Yup.number()
        .required('Informe setor responsável')
        .typeError('Informe setor responsável')
        .transform((value, originalValue) => {
          const convertedValue = Number(value);
          return isNaN(convertedValue) ? undefined : convertedValue;
        }),
      otherwise: Yup.number().nullable(),
    }),
    occurrenceTrigger: Yup.boolean().when('createOccurrence', {
      is: true,
      then: Yup.boolean()
        .required('Informe o(s) gatilhos para ocorrência')
        .typeError('Informe o(s) gatilhos para ocorrência')
        .transform((value, originalValue) => {
          if (value === null) {
            return undefined;
          }
          return value;
        }),
    }),
    subQuestionTrigger: Yup.boolean().when('hasSubQuestion', {
      is: true,
      then: Yup.boolean()
        .required('Informe o(s) gatilhos para subpergunta')
        .typeError('Informe o(s) gatilhos para subpergunta')
        .transform((value, originalValue) => {
          if (value === null) {
            return undefined;
          }
          return value;
        }),
    }),
    active: Yup.boolean().required('Campo obrigatório'),
    questionDescription: Yup.string()
      .required('Informe a pergunta')
      .max(500, 'Máximo de 500 caracteres'),
    answers: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.number(),
          order: Yup.number(),
          description: Yup.string()
            .required('Campo obrigatório')
            .max(255, 'Máximo de 255 caracteres'),
          triggerOccurrence: Yup.boolean(),
          triggerSubquestion: Yup.boolean(),
        })
      )
      .when('typeSlug', {
        is: type =>
          type?.toLowerCase() === 'multiple' ||
          type?.toLowerCase() === 'unique',
        then: Yup.array().min(2, 'Informe no mínimo duas opções de resposta'),
        otherwise: Yup.array().nullable(),
      }),
    hasSubQuestion: Yup.boolean(),
  }),

  questionType: Yup.object().shape({
    id: Yup.number().required('Tipo obrigatório'),
    name: Yup.string(),
  }),
  childQuestion: Yup.object().when('question.hasSubQuestion', {
    is: hasSubQuestion => hasSubQuestion,
    then: Yup.object().shape({
      createOccurrence: Yup.boolean(),
      occurrenceTrigger: Yup.boolean().when('createOccurrence', {
        is: true,
        then: Yup.boolean()
          .required('Informe o(s) gatilhos para ocorrência')
          .typeError('Informe o(s) gatilhos para ocorrência')
          .transform((value, originalValue) => {
            if (value === null) {
              return undefined;
            }
            return value;
          }),
      }),
      questionDescription: Yup.string()
        .required('Campo obrigatório')
        .max(500, 'Máximo de 500 caracteres'),
      answers: Yup.array().of(
        Yup.object().shape({
          id: Yup.number(),
          order: Yup.number(),
          description: Yup.string()
            .required('Campo obrigatório')
            .max(255, 'Máximo de 255 caracteres'),
          triggerOccurrence: Yup.boolean(),
        })
      ),
      occurrenceSector: Yup.number().when('createOccurrence', {
        is: true,
        then: Yup.number()
          .required('Informe setor responsável')
          .typeError('Informe setor responsável')
          .transform((value, originalValue) => {
            const convertedValue = Number(value);
            return isNaN(convertedValue) ? undefined : convertedValue;
          }),
        otherwise: Yup.number().nullable(),
      }),
    }),
    otherwise: Yup.object(),
  }),
  childQuestionType: Yup.object().when('question.hasSubQuestion', {
    is: true,
    then: Yup.object()
      .shape({
        id: Yup.number().required('Tipo obrigatório'),
        name: Yup.string(),
      })
      .required('Tipo obrigatório'),

    otherwise: Yup.object().strip(),
  }),
});
