export const LoadValueTypeOptions = [
  { label: 'Por viagem', value: 'Por viagem' },
  { label: 'Por tonelada', value: 'Por tonelada' },
];

export const NegotiationTypeOptions = [
  { label: 'Frete spot', value: 'Frete spot' },
  { label: 'Frete tabela', value: 'Frete tabela' },
];

export const clientLoadFieldsToSum = [
  'ad_valorem',
  'charge_value',
  'discharge_value',
  'ferry',
  'gris',
  'ICMS',
  'taker_value',
  'toll_value',
];

export const disableFieldConditions = {
  toll_value: ['route_without_toll'],
  discharge_value: [
    'payment_reimbursement',
    'client_not_charge',
    'included_in_freight',
    'client_assumes_cost',
  ],
  grissAd: ['not_selected_griss'],
  ferry: ['not_selected_ferry'],
  charge_value: ['not_selected_fee'],
  icms: ['icms_exempt'],
};

export const inputsLoadConditionsToShow = [
  'not_selected',
  'customer_retains', 
  'not_selected_discharge',
  'client_pays_insurance',
  'receives_client_and_transfers',
  'only_with_receipt',
  'receives_client_and_retains',
  'transfers_to_carrier',
  'not_selected_icms',
  'retains_in_transporter',
];

export const shipmentTransferToShow = [
  'not_selected',
  'not_selected_discharge',
  'receives_client_and_transfers',
  'client_provides_card',
  'transfers_to_carrier',
];

export const shipmentDiscountsToShow = [];
