import React, { useState, useEffect } from 'react';

import { Col, Row } from 'react-bootstrap';

import api from 'services/api';

import { validatePermission } from 'actions/index';
import { useSnackbar } from 'v3/components/Snackbar';

import Modal from 'v3/components/Modal';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import Loader from 'v3/components/Loader';

function ProfileSelection({ isOpen, onClose, selected, setSelected }) {
  const snackbar = useSnackbar();

  const [favoritesOptions, setFavoritesOptions] = useState();
  const [selection, setSelection] = useState(selected);
  const [loading, setLoading] = useState(false);

  async function fetchFavoritesOptions() {
    try {
      setLoading(true);
      const response = await api.get('v3/user/favorites-options');

      return setFavoritesOptions(response.data);
    } catch (error) {
      snackbar.show(<Text>Erro ao buscar favoritos disponíveis</Text>, {
        type: 'error',
      });
      return null;
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchFavoritesOptions();
  }, []);

  useEffect(() => {
    if (selected) {
      setSelection(selected);
    }
  }, [selected]);

  async function handleSaveSelection() {
    setLoading(true);
    try {
      await api.post('v3/user/favorites', { data: selection });
      setSelected(selection);
      onClose();
      snackbar.show(<Text>Adicionado com sucesso</Text>, { type: 'success' });
    } catch (error) {
      snackbar.show(<Text>Erro ao adicionar</Text>, { type: 'error' });
    } finally {
      setLoading(false);
    }
  }

  function handleAddSubtitle(current, value, title) {
    const user_id = JSON.parse(localStorage.getItem('usuario')).id;

    if (selection.some(item => item.subtitle === current)) {
      setSelection(old => {
        return old.filter(item => item.subtitle !== value.subtitle);
      });
    } else if (selection.length === 6) {
      snackbar.show(<Text>Selecionar no máximo 06 favoritos</Text>, {
        type: 'error',
      });
    } else {
      const selectedValue = { ...value, title, user_id };
      delete selectedValue.permission;
      setSelection(old => [...old, selectedValue]);
    }
  }

  return (
    <>
      <Modal
        show={isOpen}
        onHide={onClose}
        size="lg"
        scrollable
        heading={
          <Text color="dark" type="header" weight="500">
            Editar favoritos
          </Text>
        }
        body={
          <Row>
            {favoritesOptions?.map((favs, index) => {
              return (
                <Col
                  md={6}
                  className="d-flex flex-column align-items-start my-2"
                  key={`${favs.id}-${index}`}
                >
                  <>
                    <Text color="dark" type="header" weight="600">
                      {favs.title}
                    </Text>
                    {JSON.parse(favs.parameters).items.map(value => {
                      return (
                        validatePermission(value.permission) && (
                          <div className="d-flex align-items-center my-1">
                            <input
                              onClick={e =>
                                handleAddSubtitle(
                                  e.target.value,
                                  value,
                                  favs.title
                                )
                              }
                              style={{ cursor: 'pointer' }}
                              className="mr-1"
                              checked={selection.some(
                                item => item.subtitle === value.subtitle
                              )}
                              type="radio"
                              value={value.subtitle}
                              horizontal
                            />
                            <Text color="dark" type="regular">
                              {value.subtitle}
                            </Text>
                          </div>
                        )
                      );
                    })}
                  </>
                </Col>
              );
            })}
          </Row>
        }
        footer={
          !loading ? (
            <>
              <Button
                variant="error"
                onClick={() => {
                  onClose();
                }}
                className="mt-4"
              >
                <Text type="regular" weight="500" color="white">
                  Cancelar
                </Text>
              </Button>
              <Button onClick={() => handleSaveSelection()} className="mt-4">
                <Text type="regular" weight="500" color="white">
                  {selected.length === 0
                    ? 'Adicionar favorito'
                    : 'Editar favorito'}
                </Text>
              </Button>
            </>
          ) : (
            <div className="d-flex justify-content-center mt-5">
              <Loader size="md" loading={loading} />
            </div>
          )
        }
      />
    </>
  );
}

export default ProfileSelection;
