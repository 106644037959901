/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import Text from 'v3/components/Text';
import { formatDateTime } from 'v3/utils/formatter';
import { useVehicle } from '../shared/context';
import SharedData from '../shared/Section';
import moment from 'moment';

const BennerStatus = () => {
  const vehicle = useVehicle();
  const currentDate = moment();

  const [listOfProblems, setListOfProblems] = useState([]);
  const [checkingDocuments, setCheckingDocuments] = useState(true);

  function checkVehicleDocumentsStatus() {
    const rntrcExpDate = moment(vehicle.data.rntrc_exp_date);
    const rntrcExpired = rntrcExpDate.isBefore(currentDate);
    const chronotacographExpDate = moment(
      vehicle.data.chronotachograph_exp_date
    );
    const chronotacographExpired = chronotacographExpDate.isBefore(currentDate);
    const insuranceExpDate = moment(vehicle.data.insurance_validity);
    const insuranceExpired = insuranceExpDate.isBefore(currentDate);

    const descriptionProblem = vehicle?.data?.description_status_benner;
    const problemsArray = [];

    if (descriptionProblem && !problemsArray.includes(descriptionProblem)) {
      problemsArray.push(descriptionProblem);
    }

    if (rntrcExpired) {
      vehicle.data.has_problems_benner = true;
      if (!problemsArray.includes('RNTRC vencido')) {
        problemsArray.push('RNTRC vencido');
      }
    }

    if (chronotacographExpired) {
      vehicle.data.has_problems_benner = true;
      if (!problemsArray.includes('Cronotacógrafo vencido')) {
        problemsArray.push('Cronotacógrafo vencido');
      }
    }

    if (insuranceExpired) {
      vehicle.data.has_problems_benner = true;

      if (!problemsArray.includes('Seguro contra terceiros vencido')) {
        problemsArray.push('Seguro contra terceiros vencido');
      }
    }
    setListOfProblems(problemsArray);
    setCheckingDocuments(false);
  }

  useEffect(() => {
    if (!vehicle?.isFetching) {
      checkVehicleDocumentsStatus();
    }
  }, [vehicle?.isFetching]);

  return (
    <SharedData Title="Validação Benner" loading={checkingDocuments}>
      <Row>
        {listOfProblems ? (
          <>
            <Col xs={6}>
              <Text type="label" color="dark">
                Status da Importação
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {' '}
                {listOfProblems
                  ? 'Com problemas'
                  : 'Cadastro sem problemas'}
              </Text>
            </Col>
            <Col xs={6}>
              <Text type="label" color="dark">
                Data da última importação
              </Text>

              <Text type="regular" color="gray" as="p" className="mt-2">
                {' '}
                {formatDateTime(vehicle?.data?.updated_at_status_benner) ||
                  'Não informado'}
              </Text>
            </Col>
            {listOfProblems && (
              <Col xs={12}>
                <Text type="label" color="dark">
                  Descrição
                </Text>

                <Text type="regular" color="gray" as="p" className="mt-2">
                  {' '}
                  {listOfProblems?.length > 0
                    ? listOfProblems.toString().replace(',', ', ')
                    : vehicle.data?.description_status_benner}
                </Text>
              </Col>
            )}
          </>
        ) : (
          <Col xs={12}>
            <Text type="regular" color="gray" as="p" className="mt-2">
              Veículo não importado do Benner
            </Text>
          </Col>
        )}
      </Row>
    </SharedData>
  );
};
export default BennerStatus;
