import Carousel from 'react-bootstrap/Carousel';
import React from 'react'
import { StyledButton } from './styles';

function CarouselVehicleTower() {
    return (
        <Carousel
            fade
            prevIcon={<StyledButton>Anterior</StyledButton>}
            nextIcon={<StyledButton>Próximo</StyledButton>}
            interval={60000}>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src="/images/carousel-vehicle/1.png"
                    alt="slide 1"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src="/images/carousel-vehicle/2.png"
                    alt="slide 2"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src="/images/carousel-vehicle/3.png"
                    alt="slide 3"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src="/images/carousel-vehicle/4.png"
                    alt="slide 4"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src="/images/carousel-vehicle/5.png"
                    alt="slide 5"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src="/images/carousel-vehicle/6.png"
                    alt="slide 6"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src="/images/carousel-vehicle/7.png"
                    alt="slide 7"
                />
            </Carousel.Item>
        </Carousel>
    );
}

export default CarouselVehicleTower;