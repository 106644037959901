import React, { useState } from 'react';

import { useParams } from 'react-router-dom';
import api from 'services/api';

import Modal from 'v3/components/Modal';
import TextArea from 'v3/components/TextArea';
import Button from 'v3/components/Button';
import Upload from 'v3/components/Upload';
import Text from 'v3/components/Text';
import { useSnackbar } from 'v3/components/Snackbar';

function Attachment({ isOpen, onClose, fetchAttachments }) {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState('');
  const [errors, setErrors] = useState({});
  const snackbar = useSnackbar();
  const params = useParams();

  function handleClose() {
    setFiles([]);
    setDescription('');
    setErrors({});
    onClose();
  }

  async function addAttachment() {
    try {
      setLoading(true);

      const attachments = new FormData();

      files.forEach((file, index) => {
        const fileName = file?.name
          .replaceAll('_', '-')
          .split('.')
          .slice(0, -1)
          .join('.');
        const isXLSXSheet =
          file.type.split('.').pop() === 'sheet' ? 'xlsx' : null;
        const isXLSSheet =
          file.type.split('.').pop() === 'ms-excel' ? 'xls' : null;
        let fileNameFormatted = null;
        if (isXLSXSheet) {
          fileNameFormatted = `${Date.now()}${index}.${isXLSXSheet}`;
        } else if (isXLSSheet) {
          fileNameFormatted = `${Date.now()}${index}.${isXLSSheet}`;
        } else
          fileNameFormatted = `${Date.now()}${index}.${
            file.type.split('/')[1]
          }`;
        const name = fileName
          ? `${fileName}-${fileNameFormatted}`
          : fileNameFormatted;
        const newFile = new File([file.blob], name, {
          type: file.type,
        });

        attachments.append('file', newFile);
        attachments.append(`${name}_description`, description || file?.name);
      });

      await api.post(`travel/${params.id}/attachments`, attachments);

      handleClose();
      fetchAttachments();
    } catch (error) {
      snackbar.show('Error ao adicionar documento', { type: 'error' });
    } finally {
      setLoading(false);
    }
  }

  function onFilesChanged({ target }, hasError) {
    setFiles([]);
    setErrors({});

    if (hasError) {
      setErrors({ file: 'Algum arquivo selecionado é maior que 1MB.' });
      return;
    }

    setFiles(
      [...target.files].map(file => {
        return {
          blob: file,
          size: file?.size,
          name: file?.name,
          type: file?.type,
          tempPath: URL.createObjectURL(file),
        };
      })
    );
  }

  return (
    <Modal
      show={isOpen}
      handleClose={handleClose}
      heading={
        <Text type="subtitle" color="dark" weight="400">
          Anexos
        </Text>
      }
      body={
        <div>
          <div className="mb-3">
            <Text>Adicione um arquivo</Text>
            <Upload
              name="file"
              label="Arquivo máximo de 1MB"
              accept=".jpg,.jpeg,.png,.pdf, .xlsx, .xls"
              multiple
              ignoreValidation
              value={files}
              onChange={onFilesChanged}
              error={errors.file}
            />
          </div>

          <TextArea
            label="Descrição"
            resizable={false}
            value={description}
            onChange={event => setDescription(event.target.value)}
          />
          <div className="d-flex flex-column align-items-center mt-3">
            <Button
              loading={loading}
              onClick={addAttachment}
              disabled={!files.length}
            >
              Adicionar
            </Button>
          </div>
        </div>
      }
    />
  );
}

export default Attachment;
