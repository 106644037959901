import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Button from 'v3/components/Button';
import Modal from 'v3/components/Modal';
import Text from 'v3/components/Text';
import { validatePermission } from 'actions/index';
import { useRoute } from '../shared/context';

export default function RegisteredRouteModal({ show }) {
  const history = useHistory();
  const route = useRoute();
  const [hasPermissionToCreate, setPermissionToCreate] = useState();

  useEffect(() => {
    setPermissionToCreate(validatePermission('CADASTRAR_ROTA'));
  }, []);

  return (
    <Modal
      show={show}
      heading={
        <Text color="dark" weight="500" type="header">
          Cadastro de rota
        </Text>
      }
      body={
        <Text color="gray" weight="500">
          Rota cadastrada com sucesso!
        </Text>
      }
      size="lg"
      footer={
        <>
          <Button
            variant="white"
            outlined
            onClick={() => history.push('/rotas')}
            className="py-2"
          >
            <Text type="regular" color="dark" weight="500">
              Ver listagem
            </Text>
          </Button>
          <Button
            variant="primary"
            onClick={() => history.push(`/rotas/${route.data?.id}`)}
            className="py-2"
          >
            <Text type="regular" color="white" weight="500">
              Visualizar rota
            </Text>
          </Button>
          {hasPermissionToCreate && (
            <Button
              variant="success"
              className="py-2"
              onClick={() => window.location.reload(false)}
            >
              <Text type="regular" color="white" weight="500">
                Nova rota
              </Text>
            </Button>
          )}
        </>
      }
    />
  );
}
