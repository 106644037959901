import React from 'react';
import { Text } from 'v3/components';
import { StyledButton, Container, PlusMinusContainer } from './styles';

export default function PlusMinus({
  value,
  onChange,
  title,
  variant = 'primary',
}) {
  return (
    <div className="d-flex">
      <PlusMinusContainer className="d-flex align-items-center">
        <StyledButton
          circle
          variant={variant}
          icon="FaMinus"
          className="d-flex justify-content-center align-items-center mx-2"
          onClick={() => onChange(value - 1)}
          disabled={value <= 0}
        />
        <Text color={value === 0 ? 'medium_gray' : variant} type="subtitle">
          {value}
        </Text>
        <StyledButton
          variant={variant}
          icon="FaPlus"
          circle
          className="d-flex justify-content-center align-items-center mx-2"
          onClick={() => onChange(value + 1)}
        />
      </PlusMinusContainer>
      <Container>
        <div style={{ padding: '12px' }}>
          <Text type="regular" weight={500} color="gray">
            {title}
          </Text>
        </div>
      </Container>
    </div>
  );
}
