import React from 'react';

import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';

import CardItem from 'components/CardItem';
import Card from 'components/Card';

export default function CardModels(props) {
  return (
    <Grid container>
      <Grid item lg={6} md={6} xs={12}>
        <Card
          bordered
          style={{
            marginTop: '10px',
          }}
        >
          <Grid container spacing={2} style={{ padding: '0px' }}>
            <Grid item xs={8}>
              <CardItem className="nameClients" style={{ margin: '15px 0px' }}>
                {props.models.name}
              </CardItem>
            </Grid>
            <Grid item xs={4}>
              <Link
                style={{
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  float: 'right',
                  margin: '15px 0px',
                  cursor: 'pointer',
                }}
                onClick={() => props.handleOpened(props.models)}
              >
                Editar <i className="fas fa-chevron-right"></i>
              </Link>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}
