/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';

import { Row, Col } from 'react-bootstrap';

import Text from 'v3/components/Text';
import Input from 'v3/components/Input';
import Radio from 'v3/components/Radio';
import Button from 'v3/components/Button';
import Card, { DefaultLoadingBodyComponent } from 'v3/components/Card';

import { validatePermission } from 'actions/index';

import * as Controller from './controller';

export default function UserData({ driver, loadingCard }) {
  const [confirmPassword, setConfirmPassword] = useState();
  const [isEditing, setEditing] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [verified, setVerified] = useState(false);
  const [password, setPassword] = useState();
  const [errors, setErrors] = useState({});
  const [token, setToken] = useState();

  const [hasPermissionToEdit, setPermissionToEdit] = useState();
  useState(() => {
    setPermissionToEdit(validatePermission('ALTERAR_USUARIO'));
  }, []);

  useEffect(() => {
    if (driver) {
      setVerified(driver.user_verified || false);
      setToken(driver.user_token);
      setPassword(driver.user_password);
    }
  }, [driver]);

  async function save() {
    try {
      setLoading(true);
      if (!Controller.validate({ password, confirmPassword, setErrors }))
        return;

      const response = await Controller.save({
        phone: driver.phone,
        name: driver.social_name,
        setEditing,
        setLoading,
        password,
        verified,
        driver,
      });

      setEditing(false);
      setToken(response.data.token);
    } catch (ex) {
      // Suppress exception
    } finally {
      setLoading(false);
    }
  }

  return (
    <Card
      loading={loadingCard}
      LoadingBodyComponent={() => (
        <DefaultLoadingBodyComponent linesCount={2} perLine={2} />
      )}
      header={
        <Text color="#464E5F" type="header">
          Dados do usuário
        </Text>
      }
      HeaderRightComponent={
        <div>
          {isEditing && (
            <Button
              onClick={() => setEditing(false)}
              variant="secondary"
              loading={isLoading}
              className="mr-2"
            >
              <Text color="dark" type="regular">
                Cancelar
              </Text>
            </Button>
          )}
          <Button
            variant={isEditing ? 'success' : 'secondary'}
            disabled={!hasPermissionToEdit}
            loading={isLoading}
            onClick={isEditing ? () => save() : () => setEditing(true)}
          >
            <Text weight={500} type="regular">
              {isEditing ? 'Salvar' : 'Editar'}
            </Text>
          </Button>
        </div>
      }
    >
      {isEditing && (
        <Row className="form">
          <Col lg={6} md={6} xs={12}>
            <Input
              label="Senha"
              type="password"
              name="password"
              password
              onChange={event => setPassword(event.target.value)}
              error={errors.password}
            />
          </Col>
          <Col lg={6} md={6} xs={12}>
            <Input
              label="Confirmar senha"
              type="password"
              name="password"
              password
              onChange={event => setConfirmPassword(event.target.value)}
              error={errors.confirmPassword}
            />
          </Col>

          <Col lg={12} md={12} xs={12} className="mb-3">
            <Radio.Group
              label="Verificado"
              onChange={({ target }) => setVerified(target.value)}
              value={verified}
              horizontal
            >
              <Radio value>
                <Text type="label">Sim</Text>
              </Radio>
              <Radio value={false}>
                <Text color="dark" type="label">
                  Não
                </Text>
              </Radio>
            </Radio.Group>
          </Col>
        </Row>
      )}
      {!isEditing && (
        <Row>
          <Col xs={6}>
            <Text type="label" color="#464E5F">
              Senha
            </Text>
            <Text type="password" color="gray" as="p" className="mt-2">
              {token ? '*****' : ''}
            </Text>
          </Col>
          <Col xs={6}>
            <Text type="label" color="#464E5F">
              Token
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {token || '-'}
            </Text>
          </Col>

          <Col xs={12}>
            <Text type="label" color="#464E5F">
              Verificado
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {verified ? 'Sim' : 'Não'}
            </Text>
          </Col>
        </Row>
      )}
    </Card>
  );
}
