import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { fetchProductAndCategorie } from 'utils/fetches';
import { usePermission, useCurrentUser } from 'hooks';
import CheckBox from 'components/CheckBox';
import Select from 'v3/components/Select';
import Text from 'v3/components/Text';
import { useSnackbar } from 'v3/components/Snackbar';
import { useRoute } from './context';
import { fetchVehicles, fetchSelectOptions, fetchLoadType } from './controller';
import { currencyOptions } from 'v3/utils/formatter';
import { getRate } from 'v3/utils/rate';

import { handleChange } from './utils';
import CurrencyInput from 'v3/components/Input/Currency';

export default function LoadData() {
  const [vehicles, setVehicles] = useState([]);
  const [loadType, setLoadType] = useState([]);
  const [inputTag, setInputTag] = useState('');
  const [rateResponse, setRateResponse] = useState(null);
  const [exchange, setExchange] = useState(0);
  const [fixedRate, setFixedRate] = useState(false);
  const user = useCurrentUser();
  const route = useRoute();
  const snackbar = useSnackbar();

  const selectTagsPermission = usePermission('SELECIONAR_TAGS');

  useEffect(() => {
    Promise.all([fetchLoadType(), fetchVehicles()]).then(
      ([loadTypeData, vehiclesData]) => {
        setLoadType(loadTypeData);
        setVehicles(vehiclesData);
      }
    );
  }, []);

  useEffect(() => {
    if (!route?.data?.currency) {
      route.setData({ currency: currencyOptions[0] });
    } else {
      let aux = route.data.currency || route.data.currency.value;
      route.setData({
        currency: aux.value
          ? aux
          : currencyOptions.find(currency => currency.value === aux),
      });
    }
  }, []);

  useEffect(() => {
    if (route?.data?.currency && rateResponse) {
      let tempExchange =
        route?.data?.currency.value === 'BRL'
          ? rateResponse?.buy
          : rateResponse?.sell;
      setExchange(tempExchange);
      route.setData(old => ({
        ...old,
        exchange: tempExchange?.toFixed(2),
      }));
    }
  }, [route?.data?.currency, rateResponse]);

  useEffect(() => {
    async function handleGetRate(travelCurrencyValue) {
      const response = await getRate(travelCurrencyValue);
      if (!response.error) {
        setRateResponse(response);
      } else {
        snackbar.show(<Text>{response.message}</Text>, {
          type: 'error',
        });
      }
    }
    if (route.data.currency && !fixedRate) {
      handleGetRate('USD');
    }
  }, [route?.data?.currency, fixedRate]);

  function tagTreatment(input) {
    if (input) {
      const inputFiltered = input.trimStart().replace(/[^a-zA-Z0-9 ]/g, '');
      setInputTag(inputFiltered);
    } else {
      setInputTag('');
    }
  }

  useEffect(() => {
    handleChange(route);
  }, [route.data?.loadType, route.data?.vehicles]);

  return (
    <Row className="form">
      <Col xs={12}>
        <Select.Async
          label={
            user?.company.product_type === 1 ? 'Cliente *' : 'Transportadora *'
          }
          value={route.data?.person}
          onSearch={search =>
            user?.company.product_type === 1
              ? fetchSelectOptions('persons/customers', { search })
              : fetchSelectOptions('persons/shipping-company', {
                  search,
                  company_id: user.company_id,
                })
          }
          getOptionLabel={option => {
            let label = `${option.social_name} - ${option.cgccpf}`;

            if (option.city) {
              label += ` - ${option.city}`;
            }

            return label;
          }}
          getOptionValue={option => option.id}
          onChange={value => route.setData({ person: value })}
          error={
            route.errors?.['data.person'] || route.errors?.['data.person.id']
          }
        />
      </Col>
      <Col xs={12}>
        <Select
          label="Tipo de Veículo *"
          options={vehicles}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          value={route.data?.vehicles}
          onChange={value => route.setData({ vehicles: value })}
          multiple
          error={route.errors?.['data.vehicles']}
        />
      </Col>
      <Col xs={12}>
        <Select.Async
          label="Produto *"
          onSearch={search => fetchProductAndCategorie(search)}
          value={route.data?.product}
          getOptionLabel={option => `${`${option.name}`}`}
          getOptionValue={option =>
            `${`${option?.id}-${option?.product_type_id}`}`
          }
          onChange={value => route.setData({ product: value })}
          error={
            route.errors?.['data.product'] || route.errors?.['data.product.id']
          }
        />
      </Col>
      <Col xs={12} className="mb-3">
        <Select.Async
          label="Filial Emissora"
          onSearch={search =>
            fetchSelectOptions('issuers', { name: search, not_paginated: true })
          }
          value={route.data?.issuer}
          onChange={value => {
            route.setData({ issuer: value });
          }}
          getOptionLabel={option => `${option.name}-${option.document}`}
          getOptionValue={option => option.id}
          error={route.errors?.['data.issuers']}
        />
      </Col>
      <Col xs={12}>
        <Select.Async
          isDisabled={!selectTagsPermission}
          label="Tags"
          onSearch={search =>
            fetchSelectOptions('tags', {
              search,
              not_paginated: true,
            })
          }
          multiple
          value={route.data?.tags}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          onChange={value => route.setData({ tags: value })}
          onInputChange={e => tagTreatment(e)}
          inputValue={inputTag}
          error={route.errors?.['data.tags']}
        />
      </Col>
      <Col xs={12}>
        <Select
          label="Tipo de Carga *"
          options={loadType}
          getOptionLabel={option => option.nome}
          getOptionValue={option => option.id}
          value={route.data?.loadType}
          onChange={value => route.setData({ loadType: value })}
          error={route.errors?.['data.loadType']}
        />
      </Col>
      <Col xs={12} className="mb-5">
        <Select
          label="Moeda *"
          options={currencyOptions}
          getOptionLabel={option => option.label}
          getOptionValue={option => option.value}
          value={route.data?.currency || route.data?.currency?.value}
          onChange={value => {
            route.setData({ currency: value });
          }}
          error={route.errors?.['data.currency']}
        />
      </Col>
      <Col xs={6} className="mt-4">
        <CheckBox
          label="Fixar câmbio?"
          labelStyle={{
            color: '#464e5f',
            fontFamily: 'Poppins',
            textTransform: 'capitalize',
          }}
          checked={fixedRate}
          value="fixedRate"
          onClick={() => {
            setFixedRate(!fixedRate);
            route.setData({ fixedRate: !fixedRate });
            route.setData({ exchange: exchange });
          }}
          inputProps={{
            'aria-label': 'primary checkbox',
          }}
        />
      </Col>
      <Col xs={6} style={{ position: 'relative', bottom: '0.8rem' }}>
        <CurrencyInput
          label={`Valor do dólar`}
          value={route?.data?.exchange || exchange}
          onChange={(_event, value) => {
            route.setData({ exchange: value });
            setExchange(value);
          }}
          disabled={!fixedRate}
        />
      </Col>
    </Row>
  );
}
