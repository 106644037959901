import * as Yup from 'yup';

export default Yup.object().shape({
  address: Yup.string().required('Informe um endereço'),
  start_schedule: Yup.date()
    .required('Você precisa informar uma data')
    .typeError('Data informada inválida'),
  cityModel: Yup.object()
    .required('Cidade obrigatória')
    .typeError('Cidade inválida'),
  province: Yup.string().required('Informe um UF'),
  scheduled_time: Yup.date()
    .required('Você precisa informar uma data')
    .typeError('Data informada inválida'),
  lat: Yup.number().when('address', {
    is: address => address.length > 0,
    then: Yup.number()
      .required('Selecione um logradouro utilizando o seletor do google')
      .transform((currentValue, originalValue) => {
        return currentValue === null || isNaN(currentValue)
          ? undefined
          : currentValue;
      }),
  }),

  lng: Yup.number().when('address', {
    is: address => address.length > 0,
    then: Yup.number()
      .required('Selecione um logradouro utilizando o seletor do google')
      .transform((currentValue, originalValue) => {
        return currentValue === null || isNaN(currentValue)
          ? undefined
          : currentValue;
      }),
  }),
});
