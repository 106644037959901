import React, { useEffect, useMemo } from 'react';
import { Col } from 'react-bootstrap';
import { useModal } from 'hooks';
import { Card, Text } from 'v3/components';
import { makeConversion } from 'v3/utils/formatter';
import ExtraFieldsModal from '../Modals/ExtraFields';
import { LoadContext } from '../controller';
import {
  CardHeaderComponent,
  ExtraFieldsRow,
  ExtraItemRow,
  FreightItemRow,
  HeaderTotalSummary,
  LabelsRow,
  SummaryRow,
} from './components';
import { ExtraFieldsContainer } from './styles';

export function ShipperDiscounts({ isBRLCurrency, dollarToUse }) {
  const [, useLoad] = LoadContext;
  const load = useLoad();
  const extraFieldsModal = useModal();

  const totalComputed = useMemo(() => {
    let total = 0;
    if (load?.discountsExtraFields?.length) {
      total = load?.discountsExtraFields.reduce((acc, curr) => {
        return acc + Number(curr?.value);
      }, 0);
    }
    if (load?.data?.issueRate) {
      total += Number(load?.data?.issueRate);
    }
    load.setData(old => ({
      ...old,
      total_shipment_discounts: total,
    }));
    return total;
  }, [load?.data?.ferry, load?.discountsExtraFields, load?.data?.issueRate]);
  function convertValue(rawValue) {
    const operation = isBRLCurrency ? '/' : '*';
    const processedValue = +makeConversion(rawValue, operation, dollarToUse);
    return operation === '/'
      ? processedValue.toFixed(4)
      : processedValue.toFixed(2);
  }
  const discountsTags = useMemo(() => {
    let tags = '';
    if (load?.data?.issueRate) {
      tags += 'Taxa de emissão,';
    }

    if (load?.discountsExtraFields?.length > 0) {
      tags += 'Valores extras,';
    }
    return tags;
  }, [load?.discountsExtraFields]);
  const issueRateAccordingToCurrency = useMemo(() => {
    return isBRLCurrency
      ? '65.00'
      : +makeConversion(65.0, '/', dollarToUse).toFixed(4);
  }, [isBRLCurrency, dollarToUse]);

  useEffect(() => {
    if (load?.data?.showDailyRate === false) {
      load.setData(old => ({
        ...old,
        issueRate: issueRateAccordingToCurrency,
      }));
    }
  }, [issueRateAccordingToCurrency, load?.data?.showDailyRate]);
  return (
    <Col xs={12}>
      <ExtraFieldsModal
        isOpen={extraFieldsModal.isOpen}
        onClose={extraFieldsModal.close}
        setExtraFields={load.setDiscountsExtraFields}
        errors={load.errors}
        extraFields={load?.discountsExtraFields}
        dollarToUse={dollarToUse}
        isBRLCurrency={isBRLCurrency}
        loadCurrency={load?.data?.currency?.value === 'BRL' ? 'R$' : '$'}
      />
      <Card containerCardBoxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)">
        <CardHeaderComponent
          label="Descontos ao Transportador"
          isOpen={load?.discountsCardToggle}
          setIsOpen={() =>
            load.setDiscountsCardToggle(!load?.discountsCardToggle)
          }
          tooltipContent="Se caracteriza todo desconto que será feito depois de tirar a margem (Taxa administrativa)."
          totalsSection={
            <HeaderTotalSummary
              label="Valor Descontos"
              totalInReal={
                isBRLCurrency ? totalComputed : convertValue(totalComputed)
              }
              totalInDollar={
                !isBRLCurrency ? totalComputed : convertValue(totalComputed)
              }
            />
          }
          hasTagsRow
          tagsContent={discountsTags}
        />
        {load?.discountsCardToggle && (
          <>
            <LabelsRow />
            <FreightItemRow
              isBRLCurrency={isBRLCurrency}
              dollarToUse={dollarToUse}
              label="Taxa de emissão"
              value={load?.data?.issueRate}
              shouldDisableField
              id="taxa-emissao"
            />
            <>
              {load?.discountsExtraFields?.length > 0 ? (
                <Text type="regular" weight={500}>
                  Valores extras
                </Text>
              ) : null}
              <ExtraFieldsContainer>
                {load?.discountsExtraFields?.map((item, index) => {
                  return (
                    <ExtraItemRow
                      key={`${index}_${new Date().getTime()}`}
                      label={item?.label}
                      value={item?.value}
                      dollarToUse={dollarToUse}
                      isBRLCurrency={isBRLCurrency}
                      onSetExtraFields={() =>
                        load.setDiscountsExtraFields(
                          load?.discountsExtraFields?.filter(
                            (_, idx) => idx !== index
                          )
                        )
                      }
                      showToDriver={item?.show_to_driver}
                      id={`desconto-transportador-${index}`}
                    />
                  );
                })}
              </ExtraFieldsContainer>
            </>
            <SummaryRow
              label="Valor Descontos"
              totalInBRL={
                isBRLCurrency ? totalComputed : convertValue(totalComputed)
              }
              totalInUSD={
                !isBRLCurrency ? totalComputed : convertValue(totalComputed)
              }
              id="desconto"
            />
            <ExtraFieldsRow
              id="button-abrir-valores-extras-desconto"
              openModal={() => extraFieldsModal.open()}
            />
          </>
        )}
      </Card>
    </Col>
  );
}
