import React, { useState } from 'react';
import { Link } from 'react-router-dom/';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';

import { Badge, Button, Card, Modal, Text, Tooltip } from 'v3/components';
import List from 'v3/components/BulletList';
import { DealSpan, VisualizationSpan } from './styles';
import { BsChatDots, BsEye } from 'react-icons/bs';
import { validatePermission } from 'actions';
import { FaHistory } from 'react-icons/fa';
import { formatCurrency } from 'v3/utils/formatter';
import { validateStartSchedule } from 'v3/validators/loadValidators';
import { useSnackbar } from 'v3/components/Snackbar';
import { locationsObject } from './utils';
import { LoadMatchDetails } from './Modals/LoadMatchDetails';
import { useModal } from 'hooks';
import IconButton from 'v3/components/Button/Icon';
import Space from 'v3/components/Space';

export function ListCard({
  preLoad,
  requestLoadModal,
  renewLoadModal,
  setSelectedLoad,
}) {
  const snackbar = useSnackbar();
  const [show, setShow] = useState(false);

  const matchStatusModal = useModal();

  const handleCloseAllDestinations = () => setShow(false);
  const handleShowAllDestinations = () => setShow(true);
  const hasPermissionToCreate = validatePermission('ATENDER_PRECARGA');
  const hasPermissionToRequest = validatePermission('CADASTRAR_VIAGENS');
  const expiredPreLoad =
    !moment(preLoad?.dueDate).isAfter(moment()) && !preLoad?.travel?.id;
  const hasBeenRequested = preLoad?.preTravel?.id
  let loadCurrencyInformation = {
    currencyName: preLoad?.currency,
    currencySymbol: preLoad?.currency === 'BRL' ? 'R$' : '$',
  };
  function snackbarShow(text, typeShow) {
    snackbar.show(<Text className="text-center">{text}</Text>, {
      type: typeShow,
      duration: 5000,
    });
  }
  function validateBranchOffice(
    snackbarShow,
    isBranchOfficeActive,
    requestLoadModal
  ) {
    if (!isBranchOfficeActive) {
      return snackbarShow(
        'Não é possível atender carga com filial não ativa!',
        'error'
      );
    }
    setSelectedLoad(preLoad);
    requestLoadModal.open();
  }
  const Header = () => (
    <div className="d-flex">
      <Text className="mr-2">Pré Carga - {preLoad?.id}</Text>
      {!!preLoad?.deletedAt && (
        <div>
          <Badge
            style={{ padding: '4px 16px' }}
            pill
            light
            type="error"
            className="mr-2"
          >
            <Text type="label">Cancelada</Text>
          </Badge>
        </div>
      )}
      {!!preLoad?.travel?.id && (
        <div>
          <Badge
            style={{ padding: '4px 16px' }}
            pill
            light
            type="success"
            className="mr-2"
          >
            <Text type="label">Atendida</Text>
          </Badge>
        </div>
      )}
      {preLoad?.travel?.attendedStatus?.code === 'BLOCKED' && (
        <div>
          <Badge
            style={{ padding: '4px 16px' }}
            pill
            light
            type="error"
            className="mr-2"
          >
            <Text type="label">Bloqueado</Text>
          </Badge>
        </div>
      )}
      {!preLoad?.travel?.id && expiredPreLoad && (
        <div>
          <Badge
            style={{ padding: '4px 16px' }}
            pill
            light
            type="warning"
            className="mr-2"
          >
            <Text type="label">Vencida</Text>
          </Badge>
        </div>
      )}
    </div>
  );
  const ModalDestinations = () => (
    <Modal
      scrollable
      show={show}
      onHide={handleCloseAllDestinations}
      heading={
        <Text type="header" className="ml-3">
          Todos os destinos
        </Text>
      }
      body={
        <Row md={12}>
          <Col md={6} className="pl-5">
            <List items={locationsObject(true, preLoad)} />
          </Col>
        </Row>
      }
    />
  );
  return (
    <Col xs={12}>
      <Card
        HeaderWrap="no-wrap"
        header={<Header />}
        HeaderRightComponent={
          <Space x={"0.25rem"}>
            <Text type="label" color="gray">
              Criado por {preLoad?.userCreated && preLoad?.userCreated.username}{' '}
              - {moment.utc(preLoad?.createdAt).format('DD/MM/YYYY')}
            </Text>
            {!preLoad?.deletedAt &&
            !preLoad?.travel?.id &&
            hasPermissionToRequest ? (
              <Button
                variant={'success'}
                size="sm"
                disabled={expiredPreLoad || hasBeenRequested}
                title={
                  expiredPreLoad
                    ? 'Pré carga vencida'
                    : hasBeenRequested
                    ? 'Pré Carga já solicitada'
                    : ''
                }
                onClick={() => {
                  const isValidated = validateStartSchedule(
                    preLoad?.dueDate,
                    preLoad?.loadOrigins[0].scheduledTime,
                    preLoad?.loadDestinations[
                      preLoad?.loadDestinations.length - 1
                    ].scheduledTime,
                    snackbarShow
                  );
                  if (isValidated === true) {
                    validateBranchOffice(
                      snackbarShow,
                      preLoad?.branchOffice?.active,
                      requestLoadModal
                    );
                  }
                }}
              >
                <Text color="white" type="regular">
                  Solicitar pré carga
                </Text>
              </Button>
            ) : null}
            <Link to={`/pre-cargas/${preLoad?.id}`}>
              <Button variant="secondary" size='sm'>
                <Text type="regular">Detalhes</Text>
              </Button>
            </Link>
            {hasPermissionToCreate &&
              (!preLoad?.travel || preLoad?.travel?.statusId === 7) && (
                <Tooltip content={<Text>Renovar</Text>}>
                  <Button
                    variant="info"
                    size="sm"
                    onClick={() => {
                      setSelectedLoad(preLoad);
                      renewLoadModal.open();
                    }}
                  >
                    <FaHistory />
                  </Button>
                </Tooltip>
              )}
          </Space>
        }
      >
        <LoadMatchDetails
          isOpen={matchStatusModal.isOpen}
          onClose={() => matchStatusModal.close()}
          loadDataId={preLoad?.id}
        />
        <ModalDestinations />
        <Row>
          <Col lg={6} md={6} xs={12}>
            <Row>
              <Col xs={12} className="load-list-align mb-2">
                <Text className="d-block load-label-align mb-2">Cliente Tomador</Text>
                <Text color="gray" type="regular" className="d-block mb-1">
                  {preLoad?.taker && preLoad?.taker.socialName}
                </Text>
                <Text color="gray" type="regular" className="d-block mb-1">
                  {preLoad?.taker && preLoad?.taker.cpfCnpj}
                </Text>
              </Col>
              <Col xs={12}>
                <List
                  items={locationsObject(
                    false,
                    preLoad,
                    handleShowAllDestinations
                  )}
                />
              </Col>
            </Row>
          </Col>
          <Col lg={6} md={6} xs={12}>
            <Row>
              <Col lg={6} md={6} xs={12} className="mb-3">
                <Text className="d-block load-label-align mb-2">Valor</Text>
                <Text color="gray" type="regular" className="d-block mb-1">
                  {preLoad?.fareCompany
                    ? formatCurrency(
                        preLoad?.fareCompany,
                        loadCurrencyInformation.currencyName,
                        loadCurrencyInformation.currencySymbol
                      )
                    : 'A combinar'}
                </Text>
              </Col>
              <Col lg={6} md={6} xs={12} className="mb-3">
                <Text className="d-block load-label-align mb-2">Produto</Text>
                <Text color="gray" type="regular" className="d-block mb-1">
                  {preLoad?.product && preLoad?.product.name}
                  {' - '}
                </Text>
                <Text color="gray" type="regular" className="d-block mb-1">
                  {preLoad?.loadWeight && preLoad?.loadWeight} Kg
                </Text>
              </Col>
              {preLoad?.idExterno && (
                <Col lg={6} md={6} xs={12} className="mb-3">
                  <Text className="d-block load-label-align mb-2">
                    ID Externo
                  </Text>
                  <Text color="gray" type="regular" className="d-block mb-1">
                    {preLoad?.idExterno}
                  </Text>
                </Col>
              )}
              <Col lg={6} md={6} xs={12} className="mb-3">
                <Button link onClick={() => matchStatusModal.open()}>
                  <Text className="d-block load-label-align mb-2">
                    Status Negociações
                  </Text>
                </Button>
                <Text color="gray" type="regular" className="d-block mb-1">
                  {`Total motoristas notificados: ${
                    preLoad?.totalDriversSentMatch ?? 0
                  }`}
                </Text>
                <Text color="gray" type="regular" className="d-block mb-1">
                  {`Total de visualizações: ${
                    preLoad?.visualizationsCount ?? 0
                  }`}
                </Text>
                <Text color="gray" type="regular" className="d-block mb-1">
                  {`Total de negociações: ${preLoad?.dealsCount ?? 0}`}
                </Text>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </Col>
  );
}
