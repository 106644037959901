import styled from 'styled-components';
import IconButton from 'v3/components/Button/Icon';
import theme from 'v3/theme';

export const StyledButton = styled(IconButton)``;

export const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: ${theme.colors.light_gray};
  border-radius: 0 10px 10px 0;
  width: 70%;
`;

export const PlusMinusContainer = styled.div`
  align-items: center;
  padding: 2px;
  background-color: ${theme.colors.secondary};
  border-radius: 10px 0 0 10px;
`;
