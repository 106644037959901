import React from 'react';
import { FaQuestionCircle } from 'react-icons/fa';
import Label from 'components/Label';
import Checkbox from 'components/CheckBox';
import { Modal, Text, Button } from 'v3/components/';
import Tooltip from 'v3/components/Tooltip';
import {
  ColumnItem,
  ColumnsContainer,
  StyledCard,
  StyledHeader,
} from './styles';

export function ColumnPickerModal({
  isOpen,
  onClose,
  avaliableColumns,
  updateColumns,
  picked,
  setPicked,
  loading,
}) {
  const handleChangeCheckBox = item => {
    const alreadyExist = picked.filter(perm => perm.slug === item.slug);
    if (alreadyExist.length > 0) {
      const updatedColumns = picked.filter(perm => perm.slug !== item.slug);
      setPicked(updatedColumns);
    } else {
      setPicked([...picked, { ...item }]);
    }
  };

  function updatePreferences() {
    updateColumns(picked);
  }
  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      heading={
        <Text type="header" color="dark" weight={500}>
          Seleção de Colunas
        </Text>
      }
      body={
        <ColumnsContainer>
          {avaliableColumns.map((group, index) => {
            return (
              <div key={group.title + index}>
                <StyledCard bodyCardPadding="1rem 0.5rem">
                  <StyledHeader weight={500} color="dark_blue">
                    {group.title}
                  </StyledHeader>

                  <div>
                    {group.itens.map(item => {
                      return (
                        <ColumnItem key={item.id}>
                          <Checkbox
                            key={item.id}
                            checked={picked.some(
                              perm => perm.slug === item.slug
                            )}
                            onChange={() => handleChangeCheckBox(item)}
                            slug={item.slug}
                            id={item.slug}
                            label={item.name}
                          />
                          <Tooltip
                            content={
                              <Text type="label">{item.description}</Text>
                            }
                          >
                            <FaQuestionCircle />
                          </Tooltip>
                        </ColumnItem>
                      );
                    })}
                  </div>
                </StyledCard>
              </div>
            );
          })}
        </ColumnsContainer>
      }
      footer={
        <Button variant="success" loading={loading} onClick={updatePreferences}>
          Aplicar
        </Button>
      }
    />
  );
}
