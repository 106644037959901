import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Col } from 'react-bootstrap';
import { validatePermission } from 'actions/index';
import { cooperplaceApi } from 'services/api';
import { useModal, usePermission } from 'hooks';
import theme from 'v3/theme';
import { formatDate } from 'v3/utils/formatter';
import ApplicationLayout from 'v3/layouts/Application';
import { Text, Button, Fallback, DataTable, BadgeActive } from 'v3/components';
import { useSnackbar } from 'v3/components/Snackbar';
import { DefaultLoadingComponent } from 'v3/components/List';
import { Filter } from './Filter';
import Space from 'v3/components/Space';
import { CreateRouteModal } from '../Modals/CreateRoute';

export function List() {
  usePermission('VISUALIZAR_APOLICE', { redirect: true });
  const hasPermissionToCreatePolicy = validatePermission('SALVAR_APOLICE');
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [policies, setPolicies] = useState([]);
  const [pagination, setPagination] = useState({});
  const [filter, setFilter] = useState({
    name: '',
    person_id: '',
    perPage: 5,
    page: 1,
  });
  const [name, setName] = useState('');
  const [personId, setPersonId] = useState(null);
  const createRouteModal = useModal();

  async function fetchData(params) {
    try {
      setLoading(true);
      const { data } = await cooperplaceApi.get(`policies`, {
        params: {
          name: params?.name,
          person_id: params?.person_id,
          per_page: params.perPage,
          page: params?.page,
        },
      });
      setPolicies(data?.data || []);
      setPagination(data?.meta);
    } catch (error) {
      snackbar.show(<Text>Não foi possível recuperar as apólices.</Text>, {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData(filter);
  }, []);

  const handlePerPageChange = useCallback(
    v => {
      const params = {
        ...filter,
        perPage: v,
      };
      setFilter(params);
      fetchData(params);
    },
    [filter]
  );

  const handlePageChange = useCallback(
    v => {
      const params = {
        ...filter,
        page: v,
      };
      setFilter(params);
      fetchData(params);
    },
    [filter]
  );

  const handleFilter = useCallback(
    newFilters => {
      const params = {
        ...filter,
        ...newFilters,
      };
      setFilter(params);
      fetchData(params);
    },
    [filter, name, personId]
  );

  const style = {
    thead: {
      tr: {
        th: {
          '&:last-child': {
            textAlign: 'right',
          },
        },
      },
    },
    tbody: {
      tr: {
        td: {
          '&:last-child': {
            textAlign: 'right',
          },
        },
      },
    },
  };

  return (
    <>
      <ApplicationLayout
        title="Gerenciamento de apólices"
        RightComponent={
          hasPermissionToCreatePolicy && (
            <Space x="0.5rem">
              <Button
                variant={'secondary'}
                onClick={() => createRouteModal.open()}
              >
                <Text weight={500} type="regular">
                  Nova rota apólice
                </Text>
              </Button>

              <Link to="/apolices/cadastrar">
                <Button>
                  <Text weight={500} color="white" type="regular">
                    Nova apólice
                  </Text>
                </Button>
              </Link>
            </Space>
          )
        }
      >
        <Filter
          onChange={handleFilter}
          name={name}
          setName={setName}
          personId={personId}
          setPersonId={setPersonId}
        />
        <Fallback
          fall={policies?.length === 0}
          component={
            <Col
              xs={12}
              className="pt-5 d-flex justify-content-center align-items-center"
            >
              {loading ? (
                <DefaultLoadingComponent />
              ) : (
                <Button colors={theme.colors.primary} size="md" disabled>
                  Não há itens a serem exibidos
                </Button>
              )}
            </Col>
          }
        >
          <Col className="my-4">
            <DataTable
              data={policies}
              loading={loading}
              total={pagination?.total}
              pages={pagination?.last_page || 1}
              currentPage={filter.page}
              onPageChanged={handlePageChange}
              onPerPageChanged={handlePerPageChange}
              perPageOptions={[{ v: 3 }, { v: 5 }, { v: 10 }]}
              columns={[
                {
                  name: 'Status',
                  render: row => (
                    <>
                      <BadgeActive
                        pill
                        light
                        type={row?.active ? 'success' : 'error'}
                        className="mr-2"
                      >
                        <Text type="label">
                          {row?.active ? 'Ativo' : 'Inativo'}
                        </Text>
                      </BadgeActive>
                      {new Date(row?.dueDate) < new Date() && (
                        <BadgeActive pill light type={'error'} className="mr-2">
                          <Text type="label">Vencida</Text>
                        </BadgeActive>
                      )}
                    </>
                  ),
                },
                { name: 'Nome', selector: row => row?.name },
                {
                  name: 'Vencimento',
                  selector: row => formatDate(row?.dueDate, { isUTC: true }),
                },
                {
                  name: '',
                  render: row => (
                    <Link to={`/apolices/${row?.id}`}>
                      <Button className="py-2">
                        <Text color="white" type="regular" weight="500">
                          Detalhes
                        </Text>
                      </Button>
                    </Link>
                  ),
                },
              ]}
              withFilterOption={false}
              tableLayout="fixed"
              tableStyles={style}
            />
          </Col>
        </Fallback>
      </ApplicationLayout>
      <CreateRouteModal createRoute={createRouteModal} />
    </>
  );
}
