/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';

import { Row, Col } from 'react-bootstrap';

import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import { useSnackbar } from 'v3/components/Snackbar';

import api from 'services/api';

import * as Controller from './controller';

export default function Fretebras({ load, loadId, onGoingTravelStatusId }) {
  const snackbar = useSnackbar();

  const [isForwarded, setForwarded] = useState(false);
  const [isAvailable, setAvailable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [optionsOpened, setOptionsOpened] = useState(false);

  useEffect(() => {
    async function fetchIsAvailable() {
      const available = await Controller.isAvailable(loadId);

      setAvailable(available);
    }

    fetchIsAvailable();
  }, []);

  useEffect(() => {
    async function checkForwarded() {
      try {
        const response = await api.get(
          `integrations/fretebras/isforwarded/${loadId}`
        );

        setForwarded(response.data);
      } catch (error) {
        // catch
      }
    }

    if (optionsOpened) checkForwarded();
  }, [optionsOpened]);

  async function send() {
    setLoading(true);
    const resp = await Controller.send(loadId);
    snackbar.show(<Text>{resp.message}</Text>, {
      type: resp.error ? 'error' : 'success',
    });
    setLoading(false);
    if (resp.error) setForwarded(false);
    else setForwarded(true);
  }

  async function cancel() {
    setLoading(true);
    const resp = await Controller.cancel(loadId);
    snackbar.show(<Text>{resp.message}</Text>, {
      type: resp.error ? 'error' : 'success',
    });
    setLoading(false);
    if (resp.error) setForwarded(true);
    else setForwarded(false);
  }

  if (isAvailable) {
    return (
      <Row md={12}>
        <Col md={6} className="d-flex flex-column">
          <Text type="header" color="dark" weight="600">
            Fretebras
          </Text>
          {!optionsOpened && (
            <Button
              className="mt-2"
              disabled={onGoingTravelStatusId(load?.travel?.statusId)}
              variant="secondary"
              size="sm"
              loading={loading}
              onClick={() => {
                setOptionsOpened(true);
              }}
            >
              <Text weight={500} type="regular">
                Abrir opções
              </Text>
            </Button>
          )}
        </Col>
        {optionsOpened && (
          <>
            <Col md={12}>
              <Button
                className="mt-2"
                disabled={isForwarded}
                variant="success"
                loading={loading}
                onClick={() => send(loadId)}
              >
                <Text weight={500} type="regular">
                  Enviar
                </Text>
              </Button>
              <Button
                disabled={!isForwarded}
                className="ml-2 mt-2"
                variant="error"
                loading={loading}
                onClick={() => cancel(loadId)}
              >
                <Text weight={500} type="regular">
                  Cancelar
                </Text>
              </Button>
            </Col>
          </>
        )}
      </Row>
    );
  }

  return null;
}
