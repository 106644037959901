/* eslint-disable no-plusplus */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Col,
  Dropdown,
  Row,
  DropdownButton as DropdownButtonRaw,
} from 'react-bootstrap';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Spinner from 'react-bootstrap/Spinner';

import { useCurrentUser, useModal, usePermission } from 'hooks';
import api from 'services/api';

import { useSnackbar } from 'v3/components/Snackbar';
import { Text, DropdownButton, Button } from 'v3/components';

import ApplicationLayout from 'v3/layouts/Application';

import { validatePermission } from 'actions';
import IndicatorsModal from './Modals/Indicators';
import AddModal from './Modals/Add';
import EditModal from './Modals/Edit';
import * as Cards from './Cards';

import DashboardContext from './context';

import { DraggableDiv } from './styled';

const restrictedViewCards = ['CargonRevenue'];

const [DashboardProvider] = DashboardContext;
const DROPPABLE = { FIRST: 'first', LAST: 'last' };

const reorder = (
  list,
  otherList,
  startIndex,
  endIndex,
  sourceId,
  droppableId
) => {
  const clone = Array.from(list);
  const [removed] = clone.splice(startIndex, 1);
  clone.splice(endIndex, 0, removed);

  const result = {};
  result[sourceId] = clone;
  result[droppableId] = otherList;

  return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

function mergeColumns(columns) {
  const biggerLength = Math.max(columns.first.length, columns.last.length);
  const temp = new Array(biggerLength * 2).fill(0);
  let hold = 0;
  return temp.map((_, index) => {
    if (index % 2 === 0) {
      return columns.first[hold] || null;
    }

    return columns.last[hold++] || null;
  });
}

export default function Dashboards() {
  const indicatorsModal = useModal();
  const addModal = useModal();
  const editModal = useModal();
  const user = useCurrentUser();
  const snackbar = useSnackbar();
  const history = useHistory();

  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [draggingEnable, setDraggingEnable] = useState(false);
  const [selected, setSelected] = useState(null);
  const [components, setComponents] = useState([]);
  const hasPermission = usePermission('SUPER_ADM');
  const hasPermissionToSeeDashboards = validatePermission(
    'VISUALIZAR_DASHBOARD'
  );

  useEffect(() => {
    if (!hasPermissionToSeeDashboards) {
      snackbar.show(
        <Text>Você não tem permissão para acessar os dashboards!</Text>,
        { type: 'error' }
      );
      setTimeout(() => {
        history.push('/');
      }, 500);
    }
  }, []);

  const componentsArray = useMemo(() => {
    const first = components
      ?.map((item, idx) => {
        if (idx % 2 === 0) return item;
        return null;
      })
      ?.filter(item => !!item);

    const last = components
      ?.map((item, idx) => {
        if (idx % 2 !== 0) return item;
        return null;
      })
      ?.filter(item => !!item);

    return { first, last };
  }, [components]);

  async function fetchDashboards() {
    try {
      setLoading(true);
      const response = await api.get(`v3/dashboard/${user.id}`);
      const { data } = response;
      setOptions(data);
      if (data.length) {
        if (!selected) {
          setSelected(data[0]);
        } else {
          setSelected(data.find(dash => dash.title === selected.title));
        }
      }
    } catch (error) {
      // handle exception
    } finally {
      setLoading(false);
    }
  }

  async function handleDelete() {
    try {
      setLoading(true);

      await api.delete(`v3/dashboard/${selected.id}`);

      snackbar.show(<Text>Dashboard excluída com sucesso!</Text>, {
        type: 'success',
      });
    } catch (ex) {
      snackbar.show(
        <Text>
          Não foi possível alterar os dados, tente novamente mais tarde!
        </Text>,
        { type: 'error' }
      );
    } finally {
      window.location.reload();
      setLoading(false);
    }
  }

  async function refreshDashboard() {
    await fetchDashboards();

    const tempComponents = components;

    setComponents();

    setTimeout(() => {
      setComponents(tempComponents);
    }, 100);
  }

  useEffect(() => {
    if (selected) {
      setComponents(
        JSON.parse(selected?.parameters)?.items?.map(item => ({
          name: item.component,
          card_id: item.card_id,
        }))
      );
    }
  }, [selected]);

  useEffect(() => {
    fetchDashboards();
  }, []);

  function onDragEnd(result) {
    const { source, destination } = result;
    if (!result.destination) {
      return;
    }

    if (
      result.destination.index === result.source.index &&
      source.droppableId === destination.droppableId
    ) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const isFirst = source.droppableId === DROPPABLE.FIRST;
      const items = reorder(
        componentsArray[source.droppableId],
        isFirst ? componentsArray.last : componentsArray.first,
        source.index,
        destination.index,
        isFirst ? DROPPABLE.FIRST : DROPPABLE.LAST,
        isFirst ? DROPPABLE.LAST : DROPPABLE.FIRST
      );
      setComponents(mergeColumns(items));
    } else {
      const columns = move(
        componentsArray[source.droppableId],
        componentsArray[destination.droppableId],
        source,
        destination
      );

      setComponents(mergeColumns(columns));
    }
  }

  function handleReorder() {
    setDraggingEnable(true);
  }

  async function handleOrderSave() {
    try {
      await api.put(`v3/dashboard/${selected.id}/order`, { components });

      snackbar.show(<Text>Nova ordem de dashboard salva com sucesso!</Text>, {
        type: 'success',
      });
    } catch (ex) {
      snackbar.show(
        <Text>
          Não foi possível salvar a nova ordem da dashboard, tente novamente
          mais tarde.
        </Text>,
        { type: 'error' }
      );
    } finally {
      setLoading(false);
      setDraggingEnable(false);
    }
  }

  return (
    <ApplicationLayout
      title={
        <div>
          <Col md={6} xs={12} className="p-0 my-2">
            {loading ? (
              <Spinner animation="border" role="status" />
            ) : (
              <DropdownButtonRaw
                id="dropdown-basic-button"
                title={selected?.title || 'Minha Dashboard'}
              >
                {options?.map(dashboard => (
                  <Dropdown.Item onClick={() => setSelected(dashboard)}>
                    <Text weight={500}>{dashboard?.title}</Text>
                  </Dropdown.Item>
                ))}
                {options.length > 0 && <Dropdown.Divider />}
                <Dropdown.Item
                  className="text-center"
                  onClick={() => addModal.open()}
                >
                  <Text weight={500}>Nova Dashboard</Text>
                </Dropdown.Item>
              </DropdownButtonRaw>
            )}
          </Col>
        </div>
      }
      RightComponent={
        <>
          {!!options.length && (
            <div className="d-flex justify-content-end">
              <Row className="align-items-center">
                {draggingEnable && (
                  <Button
                    variant="primary"
                    onClick={handleOrderSave}
                    className="py-2"
                  >
                    <Text weight={500} color="white" type="regular">
                      Salvar ordem
                    </Text>
                  </Button>
                )}
                {!draggingEnable && (
                  <>
                    <Button variant="primary" onClick={refreshDashboard}>
                      <Text weight={500} color="white" type="regular">
                        Atualizar dados
                      </Text>
                    </Button>

                    <DropdownButton
                      style={{ marginLeft: 10 }}
                      title="Editar dashboard"
                      size="md"
                      variant="success"
                      options={[
                        {
                          title: '  Editar ordenação',
                          onClick: () => handleReorder(),
                          type: 'item',
                        },
                        {
                          title: ' Selecionar indicadores',
                          onClick: () => indicatorsModal.open(),
                          type: 'item',
                        },

                        {
                          title: '  Editar título',
                          onClick: () => editModal.open(),
                          type: 'item',
                        },

                        {
                          type: 'divider',
                        },
                        {
                          title: ' Remover dashboard',
                          onClick: () => handleDelete(),
                          type: 'item',
                        },
                      ]}
                    />
                  </>
                )}
              </Row>
            </div>
          )}
        </>
      }
      contentFluid
    >
      <DashboardProvider value={{ fetchDashboards, draggingEnable }}>
        <AddModal
          isOpen={addModal.isOpen}
          onClose={addModal.close}
          fetchDashboards={fetchDashboards}
          dashboardCount={options.length + 1}
          setSelected={setSelected}
        />
        <EditModal
          isOpen={editModal.isOpen}
          onClose={editModal.close}
          fetchDashboards={fetchDashboards}
          dashboardSelected={selected}
          setSelected={setSelected}
          setOptions={setOptions}
        />
        <IndicatorsModal
          isOpen={indicatorsModal.isOpen}
          onClose={indicatorsModal.close}
          components={components}
          fetchDashboards={fetchDashboards}
          dashboardSelected={selected}
          setSelected={setSelected}
        />
        {draggingEnable ? (
          <Row>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId={DROPPABLE.FIRST}>
                {provided => {
                  return (
                    <Col
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      xs={6}
                    >
                      {componentsArray.first?.map((component, index) => {
                        const Component = Cards[component?.name];

                        if (Component) {
                          return (
                            <Draggable
                              draggableId={component?.card_id}
                              key={component?.card_id}
                              index={index}
                              isDragDisabled={!draggingEnable}
                            >
                              {(draggableProvided, snapshot) => (
                                <div
                                  {...draggableProvided.draggableProps}
                                  {...draggableProvided.dragHandleProps}
                                  ref={draggableProvided.innerRef}
                                >
                                  <DraggableDiv
                                    draggingEnable={draggingEnable}
                                    isDragging={snapshot.isDragging}
                                  >
                                    <Component
                                      dashboard={selected}
                                      id={component?.card_id}
                                      name={component?.name}
                                    />
                                  </DraggableDiv>
                                </div>
                              )}
                            </Draggable>
                          );
                        }
                        return null;
                      })}
                      {provided.placeholder}
                    </Col>
                  );
                }}
              </Droppable>
              <Droppable droppableId={DROPPABLE.LAST}>
                {provided => {
                  return (
                    <Col
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      xs={6}
                    >
                      {componentsArray.last?.map((component, index) => {
                        const Component = Cards[component?.name];

                        if (Component) {
                          return (
                            <Draggable
                              draggableId={component?.card_id}
                              key={component?.card_id}
                              index={index}
                              isDragDisabled={!draggingEnable}
                            >
                              {(draggableProvided, snapshot) => (
                                <div
                                  {...draggableProvided.draggableProps}
                                  {...draggableProvided.dragHandleProps}
                                  ref={draggableProvided.innerRef}
                                >
                                  <DraggableDiv
                                    isDragging={snapshot.isDragging}
                                    draggingEnable={draggingEnable}
                                  >
                                    <Component
                                      dashboard={selected}
                                      id={component?.card_id}
                                      name={component?.name}
                                    />
                                  </DraggableDiv>
                                </div>
                              )}
                            </Draggable>
                          );
                        }
                        return null;
                      })}
                      {provided.placeholder}
                    </Col>
                  );
                }}
              </Droppable>
            </DragDropContext>
          </Row>
        ) : (
          <Row>
            {components?.map(component => {
              const Component = Cards[component?.name];
              const hideComponent =
                restrictedViewCards.includes(component?.name) && !hasPermission;

              if (!hideComponent && Component)
                return (
                  <Component
                    dashboard={selected}
                    id={component?.card_id}
                    name={component?.name}
                  />
                );
              return null;
            })}
          </Row>
        )}
      </DashboardProvider>
    </ApplicationLayout>
  );
}
