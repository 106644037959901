import React from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { Text } from 'v3/components';

export function SectionManagement({
  onEditClick,
  onRemoveClick,
  sectionLabel,
  disabled = false,
}) {
  return (
    <div>
      <button
        type="button"
        className="editBtn"
        onClick={() => {
          onEditClick();
        }}
        disabled={disabled}
      >
        <FaEdit />
        <Text type="medium" weight={500}>
          editar {sectionLabel}
        </Text>
      </button>
      <button
        type="button"
        className="removeBtn"
        onClick={() => {
          onRemoveClick();
        }}
      >
        <FaTrash />
        <Text type="medium" weight={500}>
          remover {sectionLabel}
        </Text>
      </button>
    </div>
  );
}
