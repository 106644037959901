import CheckBox from 'components/CheckBox';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import api from 'services/api';
import {
  fetchBranchOffices,
  fetchCostCenter,
  fetchProductAndCategorie,
  fetchSelectOptions,
} from 'utils/fetches';
import { Input, Select, Text } from 'v3/components';
import CurrencyInput from 'v3/components/Input/Currency';
import TextArea from 'v3/components/TextArea';
import { currencyOptions } from 'v3/utils/formatter';
import { validateDateTime } from 'v3/utils/functions';

export function Inputs({
  data,
  setData,
  preLoad,
  preLoadCurrency,
  loading,
  errors,
  setErrors,
  snackbar
}) {
  const [validatingTaker, setValidatingTaker] = useState(false);
  const loadSymbol = data?.currency?.value === 'BRL' ? 'R$' : '$';


  function setPreLoadData() {
    let date = new Date(preLoad?.dueDate)
    setData({
      id: preLoad?.id,
      taker: {
        id: preLoad?.taker?.id,
        social_name: preLoad?.taker?.socialName,
        cgccpf: preLoad?.taker?.cpfCnpj,
      },
      costCenter: preLoad?.costCenter,
      branchOffice: preLoad?.branchOffice,
      product: preLoad?.product,
      takerValue: preLoad?.takerValue,
      currency: preLoadCurrency,
      dueDate: moment(date).format('YYYY-MM-DDTHH:mm'),
      clientOrderNumber: preLoad?.clientOrderNumber,
      externalInvoiceNumber: preLoad?.externalInvoiceNumber,
      observations: preLoad?.comments,
      searchDriver:preLoad?.searchDriver,
      searchDriverRadius:preLoad?.searchDriverRadius
    });
  }

  useEffect(() => {
    if (preLoad) {
      setPreLoadData();
    }
  }, [preLoad]);

  async function validateClientBlacklist(cpfCnpj) {
    try {
      const response = await api.get(`v3/blacklist-person/?value=${cpfCnpj}`);
      if (response.data.total > 0) {
        snackbar.show(
          <>
            <Text>
              Não é possivel selecionar o Tomador{' '}
              {response.data.data[0].description} - {cpfCnpj} pois ele está
              banido!{' '}
            </Text>
            <Text>Motivo: {response.data.data[0].reason}</Text>
          </>,
          {
            type: 'error',
          }
        );
        setData(old => ({ ...old, client: null }));
      }
      return true;
    } catch (error) {
      snackbar.show(
        <Text>
          Error ao validar o Tomador de CNPJ: {cpfCnpj} na lista de banidos!
        </Text>,
        {
          type: 'error',
        }
      );
      setData(old => ({ ...old, client: null }));
      return false;
    }
  }
  async function handleTakerChange(value) {
    let isClientValid = false;
    setValidatingTaker(true);
    if (value) {
      isClientValid = await validateClientBlacklist(value?.cgccpf);
      if (isClientValid) {
        setData(old => ({ ...old, taker: value }));
      }
    } else {
      setData(old => ({ ...old, taker: null }));
    }
    setValidatingTaker(false);
  }

  return (
    <Row>
      <Col xs={12} md={12} className="mb-3">
        <Select.Async
          label="Cliente Tomador *"
          onSearch={search =>
            fetchSelectOptions('persons/customers', { search })
          }
          value={data?.taker}
          getOptionLabel={option => {
            let label = `${option?.social_name} - ${option?.cgccpf}`;

            if (option.city) {
              label += ` - ${option?.city}`;
            }

            return label;
          }}
          getOptionValue={option => option.id}
          error={errors.taker}
          onChange={handleTakerChange}
          loading={validatingTaker}
        />
      </Col>
      <Col xs={12} md={12} className="mb-3">
        <Select.Async
          label="Centro de custo *"
          onSearch={search => fetchCostCenter({ search })}
          value={data?.costCenter}
          onChange={value => {
            setData(old => ({ ...old, costCenter: value }));
          }}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          error={errors.costCenter}
        />
      </Col>
      <Col xs={12} md={12} className="mb-3">
        <Select.Async
          label="Produto *"
          onSearch={search => fetchProductAndCategorie(search)}
          value={data?.product}
          getOptionLabel={option => {
            if (option?.product_type_name) {
              return `${`${option.name} - ${option.product_type_name}`}`;
            }
            return option.name;
          }}
          getOptionValue={option =>
            `${`${option?.id}-${option?.product_type_id}`}`
          }
          onChange={value => setData(old => ({ ...old, product: value }))}
          error={errors.product}
        />
      </Col>
      <Col md={6} xs={12} className="mb-3">
        <Select.Async
          label="Filial *"
          onSearch={search => fetchBranchOffices(search)}
          value={data?.branchOffice}
          getOptionLabel={option => `${`${option.id} - ${option.name}`}`}
          getOptionValue={option => option?.id}
          onChange={value => setData(old => ({ ...old, branchOffice: value }))}
          error={errors.branchOffice}
        />
      </Col>
      <Col md={6} xs={12} className="mb-3">
        <Input
          type="datetime-local"
          value={data?.dueDate}
          label="Data de vencimento *"
          onChange={event => {
            let isValid = validateDateTime(event?.target?.value);
            if(isValid){
              setData(old => ({ ...old, dueDate: event.target.value }));
              setErrors(old => ({ ...old, dueDate: null }));
            } else {
              setErrors(old => ({ ...old, dueDate: 'Data inválida' }));
              setData(old => ({ ...old, dueDate: event?.target?.value }));
            }
          }}
          error={errors.dueDate}
        />
      </Col>
      <Col md={6} xs={12}>
        <CurrencyInput
          label={`Valor do frete tomador (${loadSymbol || 'R$'})`}
          value={data?.takerValue}
          onChange={(_event, value) => {
            if (_event?.target?.value?.length > 9) {
              return;
            }
            setData(old => ({ ...old, takerValue: value }));
          }}
          error={errors.takerValue}
        />
      </Col>
      <Col md={6} xs={12} className="mb-3">
        <Select
          label="Moeda valor do frete tomador*"
          options={currencyOptions}
          getOptionLabel={option => option.label}
          getOptionValue={option => option.value}
          value={data?.currency}
          onChange={value => {
            setData(old => ({ ...old, currency: value }));
          }}
          disableClear
        />
      </Col>
      <Col xs={12} md={6} className="mb-3">
        <Input
          label="Invoice"
          value={data?.externalInvoiceNumber}
          type={'text'}
          onChange={({ target }) => {
            setData(old => ({
              ...old,
              externalInvoiceNumber: target.value,
            }));
          }}
          disabled={loading}
        />
      </Col>
      <Col md={6} xs={12} className="mb-3">
        <Input
          label="Número do pedido do cliente"
          value={data?.clientOrderNumber}
          onChange={event =>
            setData(old => ({
              ...old,
              clientOrderNumber: event.target.value,
            }))
          }
          error={errors.clientOrderNumber}
          disabled={loading}
        />
      </Col>
      <Col xs={12} className="mb-3">
        <TextArea
          label="Observações"
          resizable={false}
          value={data?.observations}
          onChange={event =>
            setData(old => ({
              ...old,
              observations: event.target.value,
            }))
          }
          disabled={loading}
        />
      </Col>
      <Col md={6} xs={12} className="mb-3">
        <Text as="span" color="dark" type="medium" weight={500}>
          Buscar motoristas no APP
        </Text>
        <CheckBox
          disabled={loading}
          label="Sim"
          labelStyle={{
            textTransform: 'capitalize',
            color: '#464E5F',
          }}
          value={data?.searchDriver}
          checked={data?.searchDriver === 1 || data?.searchDriver === true}
          onChange={(e, checked) => {
            if (checked === false) {
              setData(old => ({
                ...old,
                searchDriverRadius: '0',
              }));
              setErrors(old => ({ ...old, searchDriverRadius: null }));
            } else {
              setData(old => ({
                ...old,
                searchDriverRadius: 500,
              }));
            }
            setData(old => ({
              ...old,
              searchDriver: checked,
            }));
          }}
        />
      </Col>
      <Col md={6} xs={12} className="mb-3">
        <Input
          label={
            data?.searchDriver
              ? 'Raio de busca de motorista (Km)*'
              : 'Raio de busca de motorista (Km)'
          }
          tooltip="Máximo de 500km"
          value={data?.searchDriverRadius}
          onKeyDown={evt =>
            ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
          }
          onChange={event => {
            if (
              Number(event?.target?.value) > 500 ||
              Number(event?.target?.value) < 0 ||
              event?.target?.value.length > 6
            ) {
              return;
            }
            setData(old => ({
              ...old,
              searchDriverRadius: event.target.value,
            }));
          }}
          error={errors.searchDriverRadius}
          type="number"
          min="1"
          max="500"
          disabled={
            loading ||
            data?.searchDriver === false ||
            data?.searchDriver === null
          }
        />
      </Col>
    </Row>
  );
}
