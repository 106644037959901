/* eslint-disable import/no-unresolved */
import React, { useCallback } from 'react';

import { Col, Row } from 'react-bootstrap';

import Card, { DefaultLoadingBodyComponent } from 'v3/components/Card';
import Text from 'v3/components/Text';

import Button from 'v3/components/Button';
import useModal from 'hooks/useModal';

import Modal from './Modal';

const EmergencyContact = ({ driver, getDriver, loadingCard }) => {
  const modal = useModal();

  const renderItems = useCallback(() => {
    return (
      <>
        <Row>
          <Col md={12} xs={12}>
            <Text type="label" color="#464E5F">
              Nome
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {driver?.driverEmergencyContact?.name || 'Não informado'}
            </Text>
          </Col>

          <Col md={6} xs={12}>
            <Text type="label" color="#464E5F">
              Telefone
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {driver?.driverEmergencyContact?.phone || 'Não informado'}
            </Text>
          </Col>

          <Col md={6} xs={12}>
            <Text type="label" color="#464E5F">
              Email
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {driver?.driverEmergencyContact?.email || 'Não informado'}
            </Text>
          </Col>
        </Row>
      </>
    );
  }, [driver]);

  return (
    <Card
      header={
        <Text color="#464E5F" type="header">
          Contato de emergência
        </Text>
      }
      HeaderRightComponent={
        <Button onClick={() => modal.open()} variant="secondary">
          <Text weight={500} type="regular" color="dark">
            Editar
          </Text>
        </Button>
      }
      loading={loadingCard}
      LoadingBodyComponent={() => (
        <DefaultLoadingBodyComponent linesCount={1} perLine={1} />
      )}
    >
      <Modal
        isOpen={modal.isOpen}
        onClose={modal.close}
        driver={driver}
        getDriver={getDriver}
      />
      {renderItems()}
    </Card>
  );
};

export default EmergencyContact;
