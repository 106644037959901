/* eslint-disable import/no-unresolved */
import api, { cooperplaceApi } from 'services/api';
import notificationApi from 'services/apis/notification';
import cargonApi from 'services/apis/cargon';
import dealApi from 'services/apis/deal';
import { createContext } from 'utils/react-helpers';
import TravelSchema from './validator';

export async function fetchSelectOptions(url, params = {}) {
  try {
    const { data } = await api.get(url, { params });

    return data;
  } catch (err) {
    return [];
  }
}

export const TravelContext = createContext({
  name: 'Add Travel',
  strict: false,
});

async function validate(values, setErrors) {
  const { data, loadGRORangeData } = values;
  const errorList = {};
  const dataObject = {
    clientId: data.client?.value ? data.client?.value : data.client?.id,
    currency: data.currency,
    value: +data.value,
    vehicleId: data.vehicle?.main?.id,
    shippingCompanyId: data?.shippingCompany?.id,
    origin: data?.origin?.address,
    destination: data?.destinations[0]?.address,
    destinations: data?.destinations,
    costCenter: data?.costCenter,
    balance: data?.balance,
    advance_money_percentage_external: Number(
      data?.advance_money_percentage_external
    ),
    advance_money_percentage: Number(data?.advance_money_percentage),
    company: data?.company?.name,
    fleetVehicleType: data?.vehicle?.main?.fleetType?.name,
    loadId: data?.loadId,
    riskManager: data?.riskManager,
    driver: data?.driver,
    riskManagerSearch: data?.loadGROData?.riskManagerSearch,
    monitoringGroups: data?.loadGROData?.groupEquipment,
    techTracker: data?.loadGROData?.techTracker,
    modelTracker: data?.loadGROData?.modelTracker,
    trackerNumber: data?.loadGROData?.trackerNumber,
    immobilizer: data?.loadGROData?.immobilizer,
    immobilizerNumber: data?.loadGROData?.immobilizerNumber,
    locator: data?.loadGROData?.locator,
    locatorNumber: data?.loadGROData?.locatorNumber,
    baitsAmount: data?.loadGROData?.groupEquipment?.bait_quantity,
    baits: [
      data?.loadGROData?.bait1,
      data?.loadGROData?.bait2,
      data?.loadGROData?.bait3,
      data?.loadGROData?.bait4,
    ].filter(Boolean),
    baitsNumber: [
      data?.loadGROData?.baitNumber1,
      data?.loadGROData?.baitNumber2,
      data?.loadGROData?.baitNumber3,
      data?.loadGROData?.baitNumber4,
    ].filter(Boolean),
  };

  try {
    await TravelSchema.validate(dataObject, {
      abortEarly: false,
      context: {
        riskManager: data?.riskManager,
        shouldInformDriver: !!data?.loadGROData?.id,
        shouldInformRiskManager:
          !!data?.loadGROData?.id ||
          (data?.loadGROData?.id &&
            loadGRORangeData?.groups?.find(
              group => group.code === 'manager_tracker'
            )?.items?.length > 0),
        shouldInformRiskManagerSearch:
          !!data?.loadGROData?.id &&
          loadGRORangeData?.groups?.find(
            group => group.code === 'manager_query'
          )?.items?.length > 0,
        shouldInformMonitoringGroup:
          loadGRORangeData?.equipments_groups?.length > 0,
        shouldInformTrackerTech:
          data?.loadGROData?.groupEquipment?.tecnologies?.length > 0,
        shouldInformTrackerModel:
          data?.loadGROData?.groupEquipment?.equipments?.length > 0,
        shouldInformImmobilizer:
          data?.loadGROData?.groupEquipment?.immobilizers?.length > 0,
        shouldInformLocator:
          data?.loadGROData?.groupEquipment?.locators?.length > 0,
        shouldInformBaits: data?.loadGROData?.groupEquipment?.baits?.length > 0,
      },
    });
    setErrors({ ...errorList });

    return true;
  } catch (err) {
    err.inner.forEach(error => {
      errorList[error.path] = error.message;
    });

    setErrors({ ...errorList });

    return false;
  }
}

async function deleteLoadInTruckpad(id) {
  try {
    await api.post(`integrations/truckpad/load/${id}/cancel`);

    return {
      error: false,
      message: 'Carga cancelada na Truckpad com sucesso.',
    };
  } catch (err) {
    const message = 'Não foi possível cancelar a carga na Truckpad.';
    return { error: true, message };
  }
}

export async function checkIfBlacklisted(
  cgccpf,
  onBlacklistedCallback,
  onApiErrorCallback
) {
  let res;
  try {
    res = await api.get(`v3/blacklist-person?value=${cgccpf}`);
  } catch {
    onApiErrorCallback();
  } finally {
    const blacklistInfo = res?.data?.data?.[0];
    const isBlacklisted = blacklistInfo != null;
    if (isBlacklisted) {
      onBlacklistedCallback(blacklistInfo);
    }
    return isBlacklisted;
  }
}

async function deleteLoadInFretebras(id) {
  try {
    await api.post(`integrations/fretebras/load/${id}/cancel`);

    return {
      error: false,
      message: 'Carga cancelada na Fretebras com sucesso.',
    };
  } catch (err) {
    const message = 'Não foi possível cancelar a carga na Fretebras.';
    return { error: true, message };
  }
}

async function deleteLoadInCargon(id) {
  try {
    await cargonApi.post(`/load/cancel`, { load_id: id });

    return {
      error: false,
      message: 'Carga cancelada na Cargon com sucesso.',
    };
  } catch (err) {
    const message = 'Não foi possível cancelar a carga na Cargon.';
    return { error: true, message };
  }
}

async function validateShippingCompany(travel, shippingCompany) {
  const url = `shipping-company/importFromBenner?cpfCnpj=${shippingCompany.cgccpf}&travelId=${travel}`;
  await cooperplaceApi.get(url);
}

async function validateVehicle(dataPayload) {
  const destination =
    dataPayload?.destinations?.[dataPayload.destinations.length - 1];
  const payload = {
    originScheduledTime: dataPayload?.origin?.start_schedule,
    destinationScheduledTime: destination?.scheduled_time,
    vehicleId: dataPayload?.vehicle?.main?.id,
  };
  const response = await api.post('/travel-data/verifyVehicle', payload);
  const { attendedId, isOnAnotherTravel } = response.data;
  return { attendedId, isOnAnotherTravel };
}

async function validateDriver(dataPayload) {
  const validation = {
    travels: [],
    isOnAnotherTravel: false,
  };

  const destination =
    dataPayload?.destinations?.[dataPayload.destinations.length - 1];
  const payload = {
    originScheduledTime: dataPayload?.origin?.start_schedule,
    destinationScheduledTime: destination?.scheduled_time,
    driverId: dataPayload?.driver?.id,
  };

  const response = await cooperplaceApi.get('travels/validateDriver', {
    params: payload,
  });

  const { travels } = response.data;
  validation.travels = travels;
  validation.isOnAnotherTravel = !!travels.length;
  return validation;
}

async function updateShipmentDiscounts(data) {
  const payload = data.shipmentDiscounts.map(discount => ({
    id: discount?.id,
    label: discount.label.trim(),
    manual: 1,
    value: discount.value,
    delete: discount?.delete,
    show_to_driver: discount.show_to_driver,
    loadId: data.loadId,
  }));

  payload.push({
    id: data?.issueRateId,
    label: 'Taxa de emissão',
    manual: 0,
    value: data.issueRate,
    show_to_driver: 0,
    loadId: data?.loadId,
  });

  return cooperplaceApi.put('carg-shipment-discounts', {
    discountsExtraFields: payload,
  });
}

async function updateShipmentTransfers(data) {
  const payload = data.shipmentTransfer.map(transfer => ({
    id: transfer?.id,
    label: transfer.label.trim(),
    manual: 1,
    value: transfer.value,
    delete: transfer?.delete,
    show_to_driver: transfer.show_to_driver,
    loadId: data?.loadId,
  }));

  return cooperplaceApi.put('carg-shipment-transfers', {
    transferExtraFields: payload,
  });
}

export async function handleCreation({
  data,
  setData,
  setErrors,
  showSnackbar,
  setIsLoading,
  setModalName,
  margins,
  confirmMinimiumMargin,
  passDriverValidation,
  loadGRORangeData,
}) {
  setIsLoading(true);
  if (!(await validate({ data, loadGRORangeData }, setErrors))) {
    showSnackbar('Verifique os campos obrigatórios!', { type: 'error' });
    setIsLoading(false);
    return false;
  }

  if (
    Number(data?.advance_money_percentage || 0) >
    Number(margins?.advance_money_percentage_external || 0)
  ) {
    setErrors({
      advance_money_percentage: `% de adiantamento negociado deve ser menor que o % máxima de adiantamento no ERP`,
    });
    setIsLoading(false);
    return false;
  }

  const {
    isOnAnotherTravel: vehicleIsOnAnotherTravel,
    attendedId: vehicleAttendedId,
  } = await validateVehicle(data);

  if (vehicleIsOnAnotherTravel === true) {
    setErrors({
      vehicleId: `Este veículo está em outra viagem neste período! N° da viagem: ${vehicleAttendedId}`,
    });
    setIsLoading(false);
    return;
  }

  const {
    isOnAnotherTravel: driverIsOnAnotherTravel,
    travels: travelsWithDriver,
  } = await validateDriver(data);

  if (driverIsOnAnotherTravel) {
    setIsLoading(false);
    if (travelsWithDriver.length >= 2) {
      showSnackbar(
        `O motorista está vinculado nas seguintes viagens nesse período: "${travelsWithDriver.join(
          ', '
        )}"`,
        { type: 'error', duration: 6000 }
      );
      return;
    }
    if (!passDriverValidation) {
      setErrors({ travelsWithDriver });
      setModalName('ConflictDriver');
      return;
    }
  }

  if (data.loadId && !data.checklistConfirmed) {
    setModalName('CheckList');
    setIsLoading(false);
    return false;
  }

  if (!confirmMinimiumMargin && data.margin < margins.benner_margin) {
    setModalName('MinimumMargin');
    setIsLoading(false);
    return;
  }

  try {
    setIsLoading(true);

    await Promise.all([
      updateShipmentDiscounts(data),
      updateShipmentTransfers(data),
    ]);

    const travel = {
      load: {
        id: data?.loadId,
        productId: data?.loadProductId,
        personClientId: data.client.id,
        currency: data.currency?.value || data?.currency,
        exchange: +data?.exchange || 0,
        pcpObservation: data?.pcp_observation,
        advanceMoneyPercentageExternal:
          margins?.advance_money_percentage_external,
        advanceMoneyPercentage: +data?.advance_money_percentage,
        totalShipmentTransfer: +data?.total_shipment_transfer,
        totalShipmentDiscounts: +data?.total_shipment_discounts,
        fareCompany: +data?.editableFareCompany,
        totalInputs: +data?.editableTotalInputs,
        loadOrigins: {
          id: data?.origin?.id,
          address: data?.origin?.address,
          number: data?.origin?.number,
          complement: data?.origin?.complement,
          zipCode: data?.origin?.zip_code,
          lat: data?.origin?.lat,
          lng: data?.origin?.lng,
          cityId: data?.origin?.cityModel.id || null,
          city: data?.origin?.cityModel.name,
          province: data?.origin?.province,
          neighborhood: data?.origin?.neighborhood,
          formattedAddress: data?.origin?.formatted_address,
          scheduledTime: data?.origin?.scheduled_time,
          startSchedule: data?.origin?.start_schedule,
          countrySlug:
            data?.origin?.country_slug || data?.origin?.country_abbreviation,
          clientId: data?.origin?.client?.id,
        },
        loadDestinations: data.destinations.map(item => ({
          id: item?.id,
          action: item?.action,
          address: item?.address,
          number: item?.number,
          complement: item?.complement,
          zipCode: item?.zip_code,
          lat: item?.lat,
          lng: item?.lng,
          cityId: item?.cityModel?.id || null,
          city: item?.cityModel?.name,
          province: item?.province,
          neighborhood: item?.neighborhood,
          formattedAddress: item?.formatted_address,
          scheduledTime: item?.scheduled_time,
          startSchedule: item?.start_schedule,
          countrySlug: item?.country_slug || item?.country_abbreviation,
          clientId: item?.client?.id,
        })),
      },
      driverId: data.driver?.id || null,
      balance: +data?.balance,
      vehicleId: data.vehicle?.main?.id || null,
      shippingCompanyId: data.shippingCompany.id,
      costCenterId: data.costCenter?.id || null,
      travelNumber: data?.load?.id_externo || data?.travelNumber,
      negotiatorComments: data.negotiator_comments,
      negotiatorObservation: data?.negotiator_observation,
      margin: +data?.margin,
      bennerMargin: margins?.benner_margin,
      wagonIds: [
        data.vehicle?.implement?.id || null,
        data.vehicle?.implement2?.id || null,
        data.vehicle?.implement3?.id || null,
      ].filter(Boolean),
      tagsIds: data?.tags?.map(tag => tag?.id) || [],
      policyGroupEquipamentId: data?.loadGROData?.groupEquipment?.id,
      policyId: data?.loadGROData?.policyId,
      checklistType: data?.loadGROData?.checklistType,
      riskManagerSearchId: data?.loadGROData?.riskManagerSearch?.id,
      riskManagerId: data?.riskManager?.id,
      trackerId: data?.loadGROData?.techTracker?.id || data?.tracker?.id,
      trackerTypeId: data?.loadGROData?.modelTracker?.id,
      trackerNumber: data?.loadGROData?.trackerNumber,
      trackerImmobilizerId: data?.loadGROData?.immobilizer?.id,
      immobilizerNumber: data?.loadGROData?.immobilizerNumber,
      locatorNumber: data?.loadGROData?.locatorNumber,
      trackerLocatorId: data?.loadGROData?.locator?.id,
      policyOperationId: data?.isGROLoad?.operation?.id,
      baits: [
        {
          id: data?.loadGROData?.bait1?.id,
          number: data?.loadGROData?.baitNumber1,
        },
        {
          id: data?.loadGROData?.bait2?.id,
          number: data?.loadGROData?.baitNumber2,
        },
        {
          id: data?.loadGROData?.bait3?.id,
          number: data?.loadGROData?.baitNumber3,
        },
        {
          id: data?.loadGROData?.bait4?.id,
          number: data?.loadGROData?.baitNumber4,
        },
      ].filter(item => item?.id),
    };

    const response = await cooperplaceApi.post('/travels', travel);
    try {
      await validateShippingCompany(response.data.id, data.shippingCompany);
    } catch (error) {}

    const attachments = new FormData();

    data.attachments.forEach((item, index) => {
      const fileName = item.file.name
        .replaceAll('_', '-')
        .split('.')
        .slice(0, -1)
        .join('.');
      const fileNameFormatted = `${Date.now()}${index}.${
        item.file?.type.split('/')[1]
      }`;
      const name = fileName
        ? `${fileName}-${fileNameFormatted}`
        : fileNameFormatted;

      const newFile = new File([item.file.blob], name, {
        type: item.file.type,
      });

      attachments.append('file', newFile);
      attachments.append(`${name}_description`, item.description);
    });

    await api.post(`travel/${response.data.id}/attachments`, attachments);

    if (data.loadId) {
      try {
        deleteLoadInTruckpad(data?.loadId);
        deleteLoadInFretebras(data?.loadId);
        deleteLoadInCargon(data?.loadId);
        dealApi.post(`deals/close/${data?.loadId}/load_id`);
        notificationApi.post('create', {
          title:
            data.fiscalData.status_id === 3
              ? '📑 Documentação emitida'
              : '🚛 Hora de partir!',
          body:
            data.fiscalData.status_id === 3
              ? 'A documentação da sua viagem foi gerada. Lembre-se de sempre portar os documentos físicos. Dirija com segurança e boa viagem!'
              : 'Você recebeu uma nova ordem de coleta, toque para ver mais detalhes. Boa viagem!',
          type: 'APP_NOTIFICATION',
          users_id: [data.driver.id],
          payload: { action: 'OPEN_COLLECT_ORDER' },
        });
      } catch (error) {
        //
      }
    }

    setData(old => ({
      ...old,
      registeredTravel: response.data.id,
    }));

    setModalName('CreatedTravel');

    return true;
  } catch (err) {
    const message = err?.response?.data?.message
      ? err?.response?.data?.message
      : err.response?.data?.error
      ? err.response?.data?.error
      : '';
    showSnackbar(`Erro ao cadastrar viagem! ${message}`, {
      type: 'error',
    });
    return false;
  } finally {
    setIsLoading(false);
    setModalName('');
  }
}
