import React, { useState, useEffect } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Avatar from '@material-ui/core/Avatar';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import EditIcon from '@material-ui/icons/EditRounded';
import { makeStyles } from '@material-ui/core/styles';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import api from 'services/api';
import ComponentAsyncSelect from 'components/ComponentAsyncSelect';
import Input from 'components/Input';
import Label from 'components/Label';
import Card from 'components/Card';
import Button from 'components/Button';
import Snackbar from 'components/Snackbar';
import TextField from 'components/TextField';
import InfoMessage from 'components/InfoMessage';
import Datepicker from 'v3/components/DatePicker';
import Prompt from 'components/Prompt';
import InputMaskCurrency, {
  conformCurrency,
} from 'components/InputMask/Currency';
import { validatePermission } from 'actions/index';
import { convertToDatabase } from 'utils/number';
import { formatDateTime } from 'utils/formatter';
import { usePermission } from 'hooks';
import { Container } from './style';

const statusOptions = [
  {
    label: 'Cobrando',
    value: 'Cobrando',
  },
  {
    label: 'Pago em Atraso',
    value: 'Pago em Atraso',
  },
  {
    label: 'Pago no Prazo',
    value: 'Pago no Prazo',
  },
  {
    label: 'Cancelada',
    value: 'Cancelada',
  },
];

const useStyles = makeStyles(theme => ({
  edit: {
    color: '#76838F',
    width: '30px',
    height: '30px',
    fontSize: '10px',
    border: '2px solid #76838F',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#ccc',
    },
  },
  editIcon: {
    fontSize: '14px',
  },
}));

function Fatura(props) {
  const location = useLocation();
  const classes = useStyles();

  const [cteOptions, setCteOptions] = useState([]);
  const [client, setClient] = useState('');
  const [date, setDate] = useState('');
  const [ctes, setCtes] = useState([]);
  const [invoice, setInvoice] = useState('');
  const [travel, setTravel] = useState([]);
  const [nfs, setNfs] = useState();
  const [note, setNote] = useState('');
  const [value, setValue] = useState('');
  const [isDisabled, setIsDisabled] = useState();
  const [comment, setComment] = useState();
  const [error, setError] = useState(false);
  const [status, setStatus] = useState('');
  const [log, setLog] = useState([]);
  const [valid, setValid] = useState(false);
  const [snackBar, setSnackBar] = useState({
    display: false,
    type: 'error',
    message: '',
  });
  const [billing, setBilling] = useState({});
  const [loading, setLoading] = useState(false);
  const [travelData, setTravelData] = useState([]);

  const params = useParams();
  const jsonUser = localStorage.getItem('usuario');
  const user = JSON.parse(jsonUser);
  const pathnames = window.location.pathname.split('/').filter(Boolean);

  usePermission(
    pathnames[1] === 'cadastrar' ? 'CADASTRAR_FATURA' : 'EDITAR_FATURA',
    {
      redirect: true,
    }
  );

  const schema = Yup.object().shape({
    invoice_number: Yup.string().required('Número da fatura obrigatório.'),
    travelData: Yup.array()
      .required('Você precisa vincular pelo menos um CT-e.')
      .of(
        Yup.object().shape({
          cte: Yup.array().of(
            Yup.object().shape({
              label: Yup.string().required('CTE obrigaório.'),
            })
          ),
        })
      ),
    bill_number: Yup.string().required('Nota fiscal obrigatório.'),
    invoice_amount: Yup.string().required('Valor da fatura obrigatório.'),
    person_client_id: Yup.string().required('Cliente obrigatório.'),
    status: Yup.string().required('Status obrigatório.'),
  });

  async function createInvoice() {
    const due_date = moment(date, 'DD/MM/YYYY');
    const emitted = travelData.flatMap(el => {
      return el.cte.map(c => {
        return c.value;
      });
    });
    const data = {
      invoice_number: invoice,
      travelData,
      emitted_id: emitted,
      bill_number: nfs && nfs.toString(),
      invoice_amount: convertToDatabase(value),
      note: note && note,
      due_date,
      person_client_id: client.value && client.value,
      status: status.value && status.value,
    };
    !data.note && delete data.note;
    !data.nfs && delete data.nfs;

    try {
      if (!moment(due_date).isValid()) {
        setSnackBar({
          type: 'error',
          message: 'Data Inválida',
          display: true,
        });
        return;
      }

      await schema.validate({ ...data });
      if (params.id) {
        await api.put(`billing-travel-data/${params.id}`, data);
        setSnackBar({
          display: true,
          type: 'success',
          message: 'Fatura editada',
        });
      } else {
        await api.post('billing-travel-data', data);
        setSnackBar({
          display: true,
          type: 'success',
          message: 'Fatura salva',
        });
      }

      setValid(true);
    } catch (err) {
      console.log(err);
      setSnackBar({
        display: true,
        type: 'error',
        message: err.message || 'Algo deu errado.',
      });
    }
  }

  const history = useHistory();

  useEffect(() => {
    async function getCte() {
      const params = new URLSearchParams();
      travel.forEach(item => {
        params.append('travel_number', item.label);
      });
      const response = await api.get(
        `cte-travel-data/get-cte?${params.toString()}`
      );

      if (response.data && response.data.length > 0) {
        const options = response.data
          .map(travel => ({
            value: travel.id,
            label: travel.cte_number,
          }))
          .filter(data => {
            if (travelData.length > 0) {
              if (
                travelData.find(op =>
                  op.cte.find(cte => data.value === cte.value)
                )
              ) {
                return false;
              }
              return true;
            }
            return true;
          });
        setCteOptions(options);
      } else {
        setCteOptions([]);
      }
    }
    if (travel && travel.length > 0 && travel[0]) getCte();
  }, [travel]);

  useEffect(() => {
    if (params.id) {
      getInvoice();
      if (validatePermission('EDITAR_FATURA')) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    }
  }, []);

  async function getInvoice() {
    setLoading(true);

    try {
      const response = await api.get(`billing-travel-data/${params.id}`);
      if (response.status === 200) {
        const [data] = response.data;
        setBilling(data);
        setClient({
          value: data.client.id,
          label: data.client.social_name,
        });
        setInvoice(data.invoice_number);

        setDate(moment(data.due_date, 'YYYY-MM-DD').format('DD-MM-YYYY'));
        setStatus({
          value: data.status,
          label: data.status,
        });
        const newTravelData = data.invoiceTravels
          .map(item => ({
            travel: [{ label: item.travel_number, value: item.travel_number }],
            cte: [{ label: item.cte_number, value: item.value }],
          }))
          .reduce((acc, item) => {
            function isSameTravel(compare) {
              return compare.travel[0].value === item.travel[0].value;
            }

            if (acc.some(isSameTravel)) {
              return acc.map(compare =>
                isSameTravel(compare)
                  ? { ...compare, cte: [...compare.cte, ...item.cte] }
                  : compare
              );
            }

            acc.push(item);

            return acc;
          }, []);

        setTravelData(newTravelData);

        const dataCTes = data.emitteds.map(emitted => ({
          value: emitted.id,
          label: data.cte_number ? data.cte_number : emitted.cte_number,
        }));
        setCteOptions(dataCTes);
        setNfs(data.bill_number);
        const conformedValue = conformCurrency(
          data.invoice_amount.toString().split('.').join(',')
        );
        setValue(conformedValue);
        setNote(data.note ? data.note : '');
        setLog(data.comments);
      }
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }

  async function saveLog() {
    if (!validatePermission('ADICIONAR_COMENTARIO_FATURA')) {
      setSnackBar({
        type: 'error',
        message: 'Não possui permissão para adicionar comentários',
        display: true,
      });
      return;
    }
    try {
      if (!comment) {
        setSnackBar({
          type: 'error',
          message: 'Por favor, insira um comentário',
          display: true,
        });
        return;
      }
      const newComment = {
        billing_id: params.id,
        comment,
      };
      await api.post(`billing-comment`, newComment);

      setSnackBar({
        type: 'success',
        message: 'Novo comentário criado!',
        display: true,
      });
      setLog([
        {
          ...newComment,
          user: {
            username: JSON.parse(localStorage.getItem('usuario')).username,
            email: JSON.parse(localStorage.getItem('usuario')).email,
          },
        },
        ...log,
      ]);
      setComment('');
    } catch (ex) {
      setSnackBar({
        type: 'error',
        message: 'Erro ao criar comentário',
        display: true,
      });
    }
  }

  if (error) {
    return (
      <Grid container justify="center" style={{ paddingTop: '100px' }}>
        <Box item xs={12} md={8} lg={6} sm={12}>
          <InfoMessage
            icon="report"
            width="auto"
            snackColor="#eb5544"
            message={`Não foi possível obter a fatura ${params.id}.`}
          />
        </Box>
      </Grid>
    );
  }

  async function approve() {
    try {
      await api.put(`billing-travel-data/${params.id}`, {
        approved_at: new Date(),
      });

      await getInvoice();

      setSnackBar({
        type: 'success',
        message: 'Fatura aprovada com sucesso.',
        display: true,
      });
    } catch (ex) {
      setSnackBar({
        type: 'error',
        message: 'Não foi possível aprovar essa fatura.',
        display: true,
      });
    }
  }

  function editTravelRow(index) {
    const currentTravelData = travelData.find((_, idx) => idx === index);
    setTravel(currentTravelData.travel);
    setCtes(
      currentTravelData.cte.map(el => ({
        label: el.label,
        value: el.value,
      }))
    );
    deleteTravelRow(index);
  }

  function deleteTravelRow(index) {
    setTravelData(travelData.filter((_, idx) => idx !== index));
  }

  function addTravelData() {
    if (!travel[0]) {
      setSnackBar({
        type: 'error',
        message: 'Selecione uma viagem para adicionar um CT-e',
        display: true,
      });
      return;
    }

    if (ctes.length === 0) {
      setSnackBar({
        type: 'error',
        message: 'Adicione pelo menos um CT-e.',
        display: true,
      });

      return;
    }

    const unchecked = new Set([
      ...travelData,
      {
        travel: [
          {
            label: travel[0].label,
            value: travel[0].label,
          },
        ],
        cte: ctes,
      },
    ]);

    const checkDuplicateTravel = unchecked
      .map(item => ({
        travel: item.travel,
        cte: item.cte,
      }))
      .reduce((acc, item) => {
        function isSameTravel(compare) {
          return compare.travel[0].value === item.travel[0].value;
        }

        if (acc.some(isSameTravel)) {
          return acc.map(compare =>
            isSameTravel(compare)
              ? {
                  ...compare,
                  cte: Array.from(
                    [...compare.cte, ...item.cte]
                      .reduce((m, t) => m.set(t.value, t), new Map())
                      .values()
                  ),
                }
              : compare
          );
        }

        acc.push(item);

        return acc;
      }, []);

    setTravelData(checkDuplicateTravel);
    setTravel([]);
    setCtes([]);
    setCteOptions([]);
  }

  function valueTreatment(input) {
    if (input.length > 0 && !input.match(/[a-z]/i)) {
      return input;
    }

    return '';
  }
  return (
    <>
      {valid && (
        <Prompt
          title="Fatura cadastrada com sucesso!"
          text="E agora? Deseja ir para listagem de faturas ou cadastrar nova fatura?"
          label={[
            {
              name: 'Ver Faturas',
              action: () => history.push('/faturas?0'),
              type: 'success',
            },
            {
              name: 'Adicionar Fatura',
              url: location.pathname,
              action: () => {
                window.location.reload();
              },
              type: 'primary',
            },
          ]}
        />
      )}
      <Grid container lg={6}>
        <Snackbar
          open={snackBar.display}
          type={snackBar.type}
          message={snackBar.message}
          onClose={() => setSnackBar(false)}
        />
        <Card form style={{ overflow: 'visible' }}>
          <Grid item lg={12}>
            <Label>Cliente*</Label>
            <ComponentAsyncSelect
              route={`persons/customers?profile_id=${user.profile_id}&user_id=${user.id}&company_id=${user.company_id}&search=`}
              label="Digite o nome ou cnpj"
              defaultValue={client}
              onChange={event => setClient(event)}
              isDisabled={isDisabled}
            />
          </Grid>
          <Grid item lg={12}>
            <Grid item lg={12}>
              <Input
                label="Número da fatura*"
                name="invoice_number"
                value={invoice}
                onChange={event => setInvoice(event.target.value)}
                disabled={isDisabled}
              />
            </Grid>
            <Grid item lg={6}>
              <Label>Data de vencimento *</Label>
              <Datepicker
                value={date}
                onChange={event => setDate(event.target.value)}
                disabled={isDisabled}
                placeholder=""
              />
            </Grid>
            <Grid item lg={4}>
              <Label>Status*</Label>
              <Select
                placeholder=""
                value={status}
                options={statusOptions}
                onChange={event => setStatus(event)}
                isDisabled={isDisabled}
              />
            </Grid>
            <Grid item lg={12}>
              <Label>Número da viagem*</Label>
              <ComponentAsyncSelect
                label="Busque uma viagem"
                route="cte-travel-data?perPage=10&search="
                defaultValue={travel}
                onChange={v => setTravel([v])}
                isDisabled={isDisabled}
                nested="data"
              />
            </Grid>
            <Grid item lg={12}>
              <Label>CTE*</Label>
              <CreatableSelect
                placeholder=""
                noOptionsMessage={() => (
                  <label style={{ padding: 0, margin: 0 }}>Adicionar CTE</label>
                )}
                name="cte_number"
                value={ctes}
                options={cteOptions}
                onChange={setCtes}
                isMulti
                isDisabled={isDisabled}
                onInputChange={e => valueTreatment(e)}
              />
            </Grid>
            <Grid
              item
              xs={5}
              md={12}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '10px',
              }}
            >
              <Button
                disabled={ctes.length === 0}
                color="primary"
                onClick={() => addTravelData()}
              >
                {`Adicionar CT-e${ctes && ctes.length > 1 ? 's' : ''}`}
              </Button>
            </Grid>
            <Grid item md={12}>
              <Table>
                <TableHead>
                  {travelData.length > 0 && (
                    <TableRow>
                      <TableCell>Número da viagem</TableCell>
                      <TableCell>CT-es</TableCell>
                    </TableRow>
                  )}
                </TableHead>
                <TableBody>
                  {travelData &&
                    travelData.map((el, index) => (
                      <TableRow key={index}>
                        <TableCell>{el.travel[0].label}</TableCell>
                        <TableCell>
                          {el.cte.map(x => x.label).join(', ')}
                        </TableCell>
                        <TableCell>
                          <Avatar
                            item
                            onClick={() => editTravelRow(index)}
                            className={classes.edit}
                          >
                            <EditIcon className={classes.editIcon} />
                          </Avatar>
                        </TableCell>
                        <TableCell>
                          <Avatar
                            item
                            onClick={() => deleteTravelRow(index)}
                            className={classes.edit}
                          >
                            <DeleteForeverOutlinedIcon
                              className={classes.editIcon}
                            />
                          </Avatar>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Grid>
            <Grid item lg={12}>
              <InputMaskCurrency
                value={value}
                label="Valor da Fatura (R$)*"
                onChange={e => setValue(e.target.value)}
                disabled={isDisabled}
              />
            </Grid>
            <Grid item lg={12}>
              <Input
                label="Notas Fiscais *"
                name="invoice_amount"
                type="text"
                value={nfs}
                onChange={event => setNfs(event.target.value)}
                disabled={isDisabled}
              />
            </Grid>
            <Grid item lg={12}>
              <TextField
                label="Descrição"
                name="note"
                value={note}
                onChange={event => setNote(event.target.value)}
                type="textarea"
                style={{ resize: 'none' }}
                disabled={isDisabled}
              />
            </Grid>
            <Container
              style={{ padding: '15px 0', textAlign: 'center' }}
              container
              direction="row"
              justify="flex-end"
              alignItems="flex-start"
            >
              <Button outline onClick={() => history.push('/faturas')}>
                Cancelar
              </Button>
              <Button
                onClick={createInvoice}
                color="success"
                disabled={isDisabled}
              >
                {params.id ? 'Salvar' : 'Cadastrar'}
              </Button>
            </Container>
          </Grid>
        </Card>
      </Grid>
      <br />

      {params.id &&
        (validatePermission('APROVAR_FATURA') ? (
          <>
            <Grid container lg={6} md={12}>
              {!loading && (
                <Card form>
                  {billing.approved_at ? (
                    <Label>
                      Aprovada em {formatDateTime(billing.approved_at)}
                    </Label>
                  ) : (
                    <Button onClick={approve} disabled={billing.approved_at}>
                      Aprovar
                    </Button>
                  )}
                </Card>
              )}
            </Grid>
            <br />
          </>
        ) : (
          billing.approved_at && (
            <>
              <Grid container lg={6} md={12}>
                {!loading && (
                  <Card form>
                    {billing.approved_at && (
                      <Label>
                        Aprovada em {formatDateTime(billing.approved_at)}
                      </Label>
                    )}
                  </Card>
                )}
              </Grid>
              <br />
            </>
          )
        ))}

      {validatePermission('ADICIONAR_COMENTARIO_FATURA') && (
        <>
          <Grid container lg={6}>
            {params.id && (
              <Grid item lg={12}>
                <Card form style={{ overflow: 'visible' }}>
                  <TextField
                    label="Adicione um comentário"
                    name="comment"
                    value={comment}
                    onChange={event => setComment(event.target.value)}
                    type="textarea"
                    style={{ resize: 'none' }}
                  />
                  <Link
                    onClick={() => saveLog()}
                    style={{
                      textTransform: 'uppercase',
                      pointerEvents: validatePermission(
                        'ADICIONAR_COMENTARIO_FATURA'
                      )
                        ? 'auto'
                        : 'none',
                    }}
                  >
                    <strong>Adicionar ao feed</strong>
                  </Link>
                </Card>
              </Grid>
            )}
          </Grid>
          <br />
        </>
      )}

      <Grid container spacing={2}>
        <Grid item xs={12} md={8} lg={6} sm={12}>
          {log.map((log, index) => (
            <Card
              style={{
                marginBottom: '10px',
              }}
              key={index}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={8} lg={8}>
                      <h3
                        style={{
                          marginBottom: '0px',
                          marginTop: '10px',
                        }}
                      >
                        {log.user ? log.user.username : ''}
                      </h3>

                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                        style={{ marginBottom: '0px' }}
                      >
                        {log.user ? log.user.email : ''}
                      </Typography>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                        style={{ marginBottom: '5px' }}
                      >
                        {`${moment(log.created_at).format(
                          'DD/MM/YYYY HH:mm'
                        )}hrs`}
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        {log.comment}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          ))}
        </Grid>
      </Grid>
    </>
  );
}

export default Fatura;
