import React, { useState, useEffect, useMemo } from 'react';

import { Col, Row } from 'react-bootstrap';
import { FaCheck } from 'react-icons/fa';

import api from 'services/api';

import Modal from 'v3/components/Modal';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import TextArea from 'v3/components/TextArea';

import { TravelContext } from '../../controller';
import { StyledButton } from '../../styles';

const [, useTravel] = TravelContext;

function Checklist({ isOpen, onClose }) {
  const travel = useTravel();

  const [observation, setObservation] = useState('');
  const [checkListCompany, setCheckListCompany] = useState([]);
  const [companyRequired, setCompanyRequired] = useState();

  const [checkListClient, setCheckListClient] = useState([]);
  const [clientRequired, setClientRequired] = useState();

  async function fetchChecklist() {
    try {
      const response = await api.get(
        `load/checklist/${travel.data.loadId}/${travel.data.client?.id}/${travel.data?.vehicle.main.id}`
      );

      if (!response.data) {
        travel.setData(old => ({
          ...old,
          checklistConfirmed: true,
        }));
        return;
      }

      travel.setModalName('CheckList');

      setCheckListCompany(response.data[0][0].checkList);
      setCompanyRequired(!!response.data[0][0].required_checklist);

      setCheckListClient(response.data[1][0].companyPerson.checklists);
      setClientRequired(
        !!response.data[1][0].settings.filter(
          setting => setting.type === 'REQUIRED_CHECKLIST'
        )
      );
    } catch (error) {
      //
    }
  }

  const allChecklistChecked = useMemo(() => {
    const client = checkListCompany.every(
      checklist => checklist.checked === true
    );
    const company = checkListClient.every(
      checklist => checklist.checked === true
    );

    if (client && company) {
      return true;
    }

    return false;
  }, [checkListCompany, checkListClient]);

  async function handleConfirmChecklist() {
    try {
      if (travel.data.loadId && travel.data.invalidLoad) {
        const data = {
          load_id: travel.data.loadId,
          person_id: travel.data.client?.id,
          vehicle_id: travel.data?.vehicle.main.id,
          observation,
          answers: [...checkListClient, ...checkListCompany],
        };
        await api.post(`load/checklist`, data);
      }

      travel.setData(old => ({
        ...old,
        checklistConfirmed: true,
      }));
    } catch (error) {
      //
    } finally {
      onClose(true);
      travel.setModalName('');
    }
  }

  useEffect(() => {
    if (
      !travel.data.invalidLoad &&
      travel.data.client?.id &&
      travel.data.vehicle?.main?.id
    ) {
      fetchChecklist();
    }
  }, [
    travel.data?.loadId,
    travel.data.client?.id,
    travel.data.vehicle?.main?.id,
  ]);

  function toogleChecklistCompany(id) {
    setCheckListCompany(old =>
      old.map(checklist => {
        if (checklist.id === id) {
          return {
            ...checklist,
            checked: !checklist.checked,
          };
        }
        return checklist;
      })
    );
  }

  function toogleChecklistClient(id) {
    setCheckListClient(old =>
      old.map(checklist => {
        if (checklist.id === id) {
          return {
            ...checklist,
            checked: !checklist.checked,
          };
        }
        return checklist;
      })
    );
  }

  return (
    <>
      <Modal
        show={isOpen}
        handleClose={onClose}
        heading={
          <>
            <Text type="header" color="dark" weight="500">
              Checklist
            </Text>
          </>
        }
        size="lg"
        body={
          <>
            <Row className="p-3 mx-2 form">
              <Text type="regular" color="dark" weight="400">
                É necessário confirmar o checklist para continuar o cadastro da
                viagem
              </Text>

              <Col
                md={12}
                className="d-flex flex-row align-content-center justify-content-center"
              >
                <div className="mt-3 mb-3 d-flex">
                  <div className="mr-5">
                    <Text type="header" color="dark" weight="400">
                      Checklist do cliente
                    </Text>
                    <div className="mt-1 d-flex flex-column text-center">
                      {checkListClient.length > 0 ? (
                        checkListClient?.map(checklist => (
                          <div className="d-flex flex-row justify-content-between mb-1">
                            <Text
                              type="regular"
                              color="dark"
                              weight="400"
                              transform="uppercase"
                            >
                              {checklist.name}
                            </Text>
                            <div>
                              <StyledButton
                                type="button"
                                onClick={() =>
                                  toogleChecklistClient(checklist.id)
                                }
                              >
                                <FaCheck
                                  size={14}
                                  color={
                                    checklist.checked ? '#0bb7af' : '#e600'
                                  }
                                />
                              </StyledButton>
                            </div>
                          </div>
                        ))
                      ) : (
                        <Text type="regular" color="dark" weight="400">
                          Sem items
                        </Text>
                      )}
                    </div>
                  </div>
                  <div className="ml-5">
                    <Text type="header" color="dark" weight="400">
                      Checklist da empresa
                    </Text>
                    <div className="mt-1 d-flex flex-column text-center">
                      {checkListCompany.length > 0 ? (
                        checkListCompany?.map(checklist => (
                          <div className="d-flex flex-row justify-content-between mb-1">
                            <Text
                              type="regular"
                              color="dark"
                              weight="400"
                              transform="uppercase"
                            >
                              {checklist.title}
                            </Text>
                            <div>
                              <StyledButton
                                type="button"
                                onClick={() => {
                                  toogleChecklistCompany(checklist.id);
                                }}
                              >
                                <FaCheck
                                  size={14}
                                  color={
                                    checklist.checked ? '#0bb7af' : '#e600'
                                  }
                                />
                              </StyledButton>
                            </div>
                          </div>
                        ))
                      ) : (
                        <Text type="regular" color="dark" weight="400">
                          Sem items
                        </Text>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
              <TextArea
                label="Observação"
                value={observation}
                onChange={e => setObservation(e.target.value)}
              />
              <Col md={12} className="mt-5 mb-3 d-flex justify-content-center">
                {(clientRequired || companyRequired) && (
                  <Text type="regular" color="dark" weight="400">
                    Não é possível pular o checklist
                  </Text>
                )}

                {!clientRequired && !companyRequired && (
                  <Text type="regular" color="dark" weight="400">
                    Esse checklist é opcional.
                  </Text>
                )}
              </Col>

              <Col md={12} className="mt-2 mb-3 d-flex justify-content-center">
                {!clientRequired && !companyRequired && (
                  <Button
                    variant="error"
                    className="mr-3"
                    onClick={() => {
                      travel.setData(old => ({
                        ...old,
                        checklistConfirmed: true,
                      }));
                      travel.setModalName('');
                      onClose(true);
                    }}
                  >
                    <Text type="regular" color="white" weight="500">
                      Pular
                    </Text>
                  </Button>
                )}

                <Button
                  variant="success"
                  onClick={() => handleConfirmChecklist()}
                  disabled={!allChecklistChecked}
                  className="py-2"
                >
                  <Text type="regular" color="white" weight="500">
                    Confirmar
                  </Text>
                </Button>
              </Col>
            </Row>
          </>
        }
      />
    </>
  );
}

export default Checklist;
