import { Row } from 'react-bootstrap';
import styled from 'styled-components';
import theme from 'v3/theme';

export const QuestionsSectionContainer = styled.div`
  margin-bottom: 1rem;
  max-height: 500px;
  overflow-y: auto;
  scroll-behavior: smooth;
  :not(:hover)::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
  :not(:hover)::-webkit-scrollbar-track {
    opacity: 0;
  }
  :not(:hover)::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
  }
  :hover::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
  :hover::-webkit-scrollbar-track {
    background: ${theme.colors.light_gray};
    opacity: 0.5;
  }
  :hover::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border-radius: 10px;
  }
`;

export const QuestionDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  cursor: default;
  border-top: 2px solid ${theme.colors.medium_gray};

  padding: 1rem 0;
  margin: 1rem 0;
  & > div.contentContainer {
    width: 100%;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;

    & > div:first-child {
      flex: 1;
    }

    & > div.questionOptions {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      min-width: 175px;
    }
  }
`;

export const QuestionOptionItem = styled.span`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  & > svg {
    width: 1.5rem;
    height: 1.5rem;
    color: ${theme.colors.checklist_gray};
  }
`;

export const StyledAuditRow = styled(Row)`
  max-height: 80dvh;
  overflow-y: auto;
  scroll-behavior: smooth;
  :not(:hover)::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
  :not(:hover)::-webkit-scrollbar-track {
    opacity: 0;
  }
  :not(:hover)::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
  }
  :hover::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
  :hover::-webkit-scrollbar-track {
    background: ${theme.colors.light_gray};
    opacity: 0.5;
  }
  :hover::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border-radius: 10px;
  }
`;

export const AuditItemHeader = styled.header`
  display: flex;
  align-items: center;
`;

export const AuditToggleButton = styled.button`
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LogItem = styled.div`
  padding: 0.5rem;
`;
