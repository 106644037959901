import styled, { css } from 'styled-components';
import { SnackbarContent } from '@material-ui/core';

import { withoutProps } from 'services/utils';

export const Message = styled.span``;

export const Icon = styled.span`
  svg {

  }
`;

export const StyledInfoMessage = styled(
  withoutProps('snackColor')(SnackbarContent)
)`
  && {
    ${({ snackColor, width, textAlign }) =>
      css`
        background-color: ${snackColor};
        width: ${width};
        text-align: ${textAlign};
        color:#fff;
      `}
  }
`;
