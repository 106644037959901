import styled from 'styled-components';
import { Text } from 'v3/components';
import theme from 'v3/theme';

export const ToggleDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const FooterButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 0.5rem;

  @media (max-width: 640px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

export const StyledMonitoringGroupHeader = styled.span`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  & > svg {
    color: ${theme.colors.light_blue};
  }
`;
export const ToolTipLabel = styled(Text)`
  font-size: 13px;
  margin-bottom: 10px;
  margin-top: 3px;
  display: flex;
  text-align: auto;
  white-space: pre-wrap;
`;
