import React, { useState } from 'react';
import moment from 'moment';
import api from 'services/api';
import apiReport from 'services/apis/report';
import ApplicationLayout from 'v3/layouts/Application';
import Select from 'v3/components/Select';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import { Row, Col } from 'react-bootstrap';
import { useSnackbar } from 'v3/components/Snackbar';
import { formatDate } from 'v3/utils/formatter';
import { DatePicker, InfiniteScroll } from 'v3/components';
import FileSaver from 'file-saver';
import { usePermission } from 'hooks';
import Card from './Card';

export default function LoadsAttendedUser() {
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [loadsAttended, setLoadsAttended] = useState([]);
  const [pagination, setPagination] = useState({
    lastPage: 1,
    currentPage: 1,
    total: 0,
  });
  const [dates, setDates] = useState({
    from: formatDate(moment(new Date()).subtract(1, 'month')),
    to: formatDate(new Date()),
  });

  const [users, setUsers] = useState([]);
  const [tags, setTags] = useState([]);
  usePermission('VISUALIZAR_RELATORIOS_DE_CARGAS_ATENDIDAS_POR_USUARIO', {
    redirect: true,
  });

  async function fetchSelectOptions(params) {
    try {
      const { data } = await api.get('user/select', { params });

      return data;
    } catch (err) {
      return [];
    }
  }
  async function fetchSelectTags(params) {
    try {
      const { data } = await api.get('tags', { params });

      return data;
    } catch (err) {
      return [];
    }
  }

  function renderItem(loadAttended) {
    return <Card loadAttended={loadAttended} />;
  }

  function dateFormatter(date, end) {
    const formattedDate = moment(date.split('/').reverse().join('-')).add(
      3,
      'h'
    );
    if (end) {
      formattedDate.add(1, 'd');
    }
    return formattedDate.format('YYYY-MM-DD hh:mm:ss');
  }

  async function fetchLoadsAttended(page = 1) {
    if (page === 1) {
      setLoading(true);
    }
    try {
      const start = dateFormatter(dates.from);
      const end = dateFormatter(dates.to, true);

      const response = await apiReport.get(`load-attended-user-data`, {
        params: {
          page,
          start,
          end,
          attendant_id: users.map(user => user.id),
          tags: tags.map(tag => tag.id),
        },
      });

      const { items, ...rest } = response.data;

      setPagination(rest);
      setLoadsAttended(oldData => {
        if (page === 1) {
          return items;
        }
        return [...oldData, ...items];
      });
    } catch (error) {
      snackbar.show(
        error.response?.data?.error ||
          'Algo deu errado, tente novamente mais tarde',
        { type: 'error' }
      );
    } finally {
      setLoading(false);
    }
  }

  function setDate(key, value) {
    setDates(old => {
      return {
        ...old,
        [key]: value,
      };
    });
  }

  async function exportReport() {
    setExportLoading(true);
    try {
      const start = dateFormatter(dates.from);
      const end = dateFormatter(dates.to, true);

      const response = await apiReport.get(`load-attended-user-report`, {
        params: {
          start,
          end,
          attendant_id: users.map(user => user.id),
          tags: tags.map(tag => tag.id),
        },
        responseType: 'blob',
      });

      FileSaver.saveAs(response.data, response.headers['x-file-name']);
    } catch (error) {
      snackbar.show('Erro ao buscar relatórios', { type: 'error' });
    } finally {
      setExportLoading(false);
    }
  }

  return (
    <ApplicationLayout title="Cargas Atendidas por Usuário">
      <Row className="filter d-flex justify-content-center space-between">
        <Col md={2} xs={12}>
          <DatePicker
            guide
            label="Período de *"
            value={dates.from}
            onChange={({ target }) => setDate('from', target.value)}
            labelColor="#fff"
          />
        </Col>
        <Col md={2} xs={12}>
          <DatePicker
            guide
            label="Até *"
            value={dates.to}
            onChange={({ target }) => setDate('to', target.value)}
            labelColor="#fff"
          />
        </Col>
        <Col md={3} xs={12}>
          <Select.Async
            label="Atendentes"
            modalHeading="Selecione um usuário"
            modalBodyTitle="Usuários:"
            labelColor="#fff"
            horizontal
            value={users}
            onSearch={search => fetchSelectOptions({ search })}
            onChange={value => (value ? setUsers(value) : setUsers([]))}
            multiple
            getOptionLabel={option => {
              let label = `${option?.username}`;

              if (option?.cpf) {
                label += ` - ${option?.cpf}`;
              }

              return label;
            }}
            getOptionValue={option => option.id}
          />
        </Col>
        <Col md={3} xs={12}>
          <Select.Async
            label="Tags"
            modalHeading="Selecione uma tag"
            modalBodyTitle="Tags:"
            labelColor="#fff"
            horizontal
            value={tags}
            onSearch={search =>
              fetchSelectTags({ search, not_paginated: true })
            }
            onChange={value => (value ? setTags(value) : setTags([]))}
            multiple
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
          />
        </Col>
        <Col lg={1} xs={12} className="vertical bottom">
          <Button
            disabled={!dates.from || !dates.to}
            variant="success"
            loading={loading}
            onClick={() => fetchLoadsAttended()}
            className=""
          >
            <Text type="regular" weight={500}>
              Filtrar
            </Text>
          </Button>
        </Col>
        <Col lg={1} xs={12} className="vertical bottom">
          <Button
            disabled={!dates.from || !dates.to}
            variant="primary"
            loading={exportLoading}
            onClick={exportReport}
            className=""
          >
            <Text type="regular" weight={500}>
              Exportar
            </Text>
          </Button>
        </Col>
        <Col md={10} className="mt-4">
          <InfiniteScroll
            data={loadsAttended}
            onEndReach={() => {
              fetchLoadsAttended(pagination.currentPage + 1);
            }}
            hasMore={pagination.currentPage < pagination.lastPage}
            scrollThreshold={0.7}
            renderItem={renderItem}
            loading={loading}
          />
        </Col>
      </Row>
    </ApplicationLayout>
  );
}
