import styled, { css } from 'styled-components';

import Table from 'react-bootstrap/Table';
import theme from '../../theme';

export const StyledTable = styled(Table)`
  font-family: 'Poppins';
  overflow: hidden !important;
  table-layout: ${props => props.tableLayout ?? 'auto'};

  > thead {
    border: 2px solid #fff;
  }

  > tbody {
    border: 2px solid #fff;
  }

  > thead > tr {
    border-top: none !important;
    ${({ striped }) =>
      striped
        ? css`
            border: none;
          `
        : css`
            border-bottom: 1px solid #f2f2f2;
          `};
  }

  tr:first-child > td {
    border-top: 0;
  }

  > tbody > tr:nth-child(odd) {
    ${({ striped }) =>
      striped
        ? css`
            background: ${theme.colors.light_gray};
          `
        : css``}
  }
  > tbody > tr:hover {
    background: #f9f9f9;
  }
  > tbody > tr > td {
    color: ${({ color = 'black' }) => theme.colors[color]};
  }
  ${({ customStyles }) => customStyles && css(customStyles)};
`;
