import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useSnackbar } from 'v3/components/Snackbar';
import Modal from 'v3/components/Modal';
import TextArea from 'v3/components/TextArea';
import Button from 'v3/components/Button';
import Upload from 'v3/components/Upload';
import Text from 'v3/components/Text';
import Select from 'v3/components/Select';
import DatePicker from 'v3/components/DatePicker';
import { cooperplaceApi } from 'services/api';
import { wagonsDocumentTypes } from 'v3/utils/documentTypes';

export function AttachmentsModal({
  isOpen,
  onClose,
  attachments,
  setAttachments,
  fetchAttachments,
}) {
  const params = useParams();
  const snackbar = useSnackbar();
  const [files, setFiles] = useState([]);
  const [description, setDescription] = useState('');
  const [documentType, setDocumentType] = useState();
  const [dueDate, setDueDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  function handleClose() {
    setFiles([]);
    setErrors({});
    setDocumentType('');
    setDueDate('');
    setDescription('');
    onClose();
  }

  function isDateValid(value) {
    const date = moment(value, 'DD/MM/YYYY');
    return (
      value?.split('/')?.length === 3 &&
      date.isValid() &&
      date.isAfter(moment())
    );
  }

  function addAttachment() {
    setAttachments([...attachments, ...files]);
    handleClose();
  }

  async function updateAttachment() {
    try {
      if (params.id) {
        setLoading(true);
        const attachmentsFiles = new FormData();
        files.forEach((file, index) => {
          const fileName = file.file.name
            .replaceAll('_', '-')
            .split('.')
            .slice(0, -1)
            .join('.');
          const fileNameFormatted = `${Date.now()}${index}.${
            file.file.type.split('/')[1]
          }`;
          const name = fileName
            ? `${fileName}-${fileNameFormatted}`
            : fileNameFormatted;
          const newFile = new File([file.file.blob], name, {
            type: file.file.type,
          });
          const type = documentType.label;
          const due = moment(dueDate, 'DD/MM/YYYY').format();
          attachmentsFiles.append('file', newFile);
          attachmentsFiles.append('type', type);
          attachmentsFiles.append('description', description);
          attachmentsFiles.append('dueDate', due);
        });
        await cooperplaceApi.post(
          `wagons/${params.id}/attachment`,
          attachmentsFiles
        );

        handleClose();
        fetchAttachments();
      } else {
        setAttachments([...attachments, ...files]);

        setLoading(false);
        handleClose();
      }
    } catch (error) {
      snackbar.show(<Text>Algo deu errado. Tente novamente mais tarde.</Text>, {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }

  function handleDueDateChange(dateString) {
    setDueDate(dateString);
    setErrors(prev => ({
      ...prev,
      dueDate: !isDateValid(dateString)
        ? 'O documento não pode ser vencido.'
        : null,
    }));
  }

  function onFilesChanged({ target }, hasError) {
    setFiles([]);
    setErrors({});

    if (hasError) {
      setErrors({ file: 'Algum arquivo selecionado é maior que 1MB.' });
      return;
    }

    setFiles(
      [...target.files].map(file => {
        return {
          file: {
            blob: file,
            size: file?.size,
            name: file?.name,
            type: file?.type,
            tempPath: URL.createObjectURL(file),
          },
          description: description || file.name,
        };
      })
    );
  }

  return (
    <Modal
      show={isOpen}
      handleClose={handleClose}
      heading={
        <Text type="subtitle" color="dark" weight="400">
          Anexos
        </Text>
      }
      body={
        <div>
          <div className="mb-3">
            <Text>Adicione um arquivo</Text>
            <Upload
              name="file"
              label="Arquivo máximo de 1MB"
              accept=".jpg,.jpeg,.png,.pdf"
              value={files}
              multiple
              onChange={onFilesChanged}
              error={errors.file}
              ignoreValidation
            />
          </div>
          <div className="mb-3">
            <Select
              options={wagonsDocumentTypes}
              value={documentType}
              onChange={value => setDocumentType(value)}
              getOptionLabel={option => option.label}
              getOptionValue={option => option.value}
            />
          </div>
          <div className="mb-3">
            <DatePicker
              value={dueDate}
              onChange={e => handleDueDateChange(e.target.value)}
              label="Data de vencimento *"
              error={errors.dueDate}
            />
          </div>
          <TextArea
            label="Descrição"
            resizable={false}
            value={description}
            onChange={event => setDescription(event.target.value)}
          />
          <div className="d-flex flex-column align-items-center mt-3">
            <Button
              disabled={!files.length || !isDateValid(dueDate)}
              onClick={params.id ? updateAttachment : addAttachment}
              loading={loading}
            >
              <Text type="regular" weight={500}>
                Adicionar
              </Text>
            </Button>
          </div>
        </div>
      }
    />
  );
}
