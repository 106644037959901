import React from 'react';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';

import Card from 'components/Card';
import { number_format } from 'functions';

function CellNaoAtendida(props) {
  return (
    <Card style={{ marginBottom: '10px' }} bordered>
      <Grid container>
        <Grid item xs={4}>
          <Typography variant="body2" component="p" gutterBottom>
            <strong>ID</strong>
          </Typography>
          <Typography variant="caption" gutterBottom>
            {props.carga.id}
          </Typography>
          <Typography variant="body2" gutterBottom>
            <strong>Cliente</strong>
          </Typography>
          <Typography variant="caption" component="p" gutterBottom>
            {props.carga.social_name}
          </Typography>
          <Typography variant="caption" component="p" gutterBottom>
            {props.carga.cgccpf}
          </Typography>
        </Grid>

        <Grid item xs>
          <Typography variant="body2" gutterBottom>
            <strong>Data de criação</strong>
          </Typography>
          <Typography variant="caption" component="p" gutterBottom>
            {moment(props.carga.created_at).format('DD/MM/YYYY HH:mm')}
          </Typography>
          <Typography variant="body2" gutterBottom>
            <strong>Valor</strong>
          </Typography>
          <Typography variant="caption" component="p" gutterBottom>
            {number_format(props.carga.fare_company, 2, ',', '.')}
          </Typography>
        </Grid>

        {props.carga.deleted_at && (
          <>
            <Grid item xs>
              <Typography variant="body2" gutterBottom>
                <strong>Data de cancelamento</strong>
              </Typography>
              <Typography variant="caption" component="p" gutterBottom>
                {moment(props.carga.deleted_at).format('DD/MM/YYYY HH:mm')}
              </Typography>

              <Typography variant="body2" gutterBottom>
                <strong>Usuário que cancelou</strong>
              </Typography>
              <Typography variant="caption" component="p" gutterBottom>
                {props.carga.user_deleted_name}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="body2" gutterBottom>
                <strong>Tipo do cancelamento</strong>
              </Typography>
              <Typography variant="caption" component="p" gutterBottom>
                {props.carga.deleted_reason || 'Não informado'}
              </Typography>

              <Typography variant="body2" gutterBottom>
                <strong>Motivo do cancelamento</strong>
              </Typography>
              <Typography variant="caption" component="p" gutterBottom>
                {props.carga.deleted_reason_description || 'Não informado'}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    </Card>
  );
}

export default CellNaoAtendida;
