/* eslint-disable import/no-unresolved */
import React from 'react';
import api from 'services/api';
import Text from 'v3/components/Text';

function showSnackbar(snackbar, text, options) {
  snackbar.show(<Text> {text} </Text>, options);
}

export async function getPaymentMethods(
  page = 1,
  { setPaymentMethods, setLoading, setPagination, snackbar }
) {
  if (page === 1) setLoading(true);
  try {
    const response = await api.get(`payment/methods?page=${page}`);
    const { data, ...rest } = response.data;

    setPagination(rest);
    setPaymentMethods(oldPaymentMethods => {
      if (page === 1) {
        return data;
      }
      return [...oldPaymentMethods, ...data];
    });
  } catch (error) {
    showSnackbar(snackbar, `Erro ao carregar formas de pagamento`, {
      type: 'error',
    });
  } finally {
    setLoading(false);
  }
}
