/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Col, Row } from 'react-bootstrap';

import api from 'services/api';

import Modal from 'v3/components/Modal';
import Select from 'v3/components/Select';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';

import { useUser } from '../../context';

function ProfileSelection({ isOpen, onClose }) {
  const user = useUser();
  const history = useHistory();

  const [profileList, setProfileList] = useState({});

  useEffect(() => {
    async function fetchProfiles() {
      try {
        const response = await api.get('users-profiles');

        setProfileList(response.data);
      } catch (error) {
        //
      }
    }

    fetchProfiles();
  }, []);

  function handleSelection() {
    onClose(true);
  }
  return (
    <>
      <Modal
        onEscapeKeyDown={() => {
          history.goBack();
        }}
        show={isOpen}
        onHide={onClose}
        size="lg"
        closeButton={false}
        backdrop="static"
        heading={
          <Text color="dark" type="body">
            Selecione o perfil do usuário que será criado antes de avançar.
          </Text>
        }
        body={
          <Row>
            <Col xs={12} className="d-flex flex-column align-items-center my-2">
              <Select
                className="mt-3"
                label={
                  <Text type="label" weight="500">
                    Perfil
                  </Text>
                }
                options={profileList}
                value={user.data.profile}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                onChange={value => {
                  user.setData({ profile: value });
                }}
              />
              <Button
                onClick={handleSelection}
                className="mt-4"
                disabled={!user.data.profile}
              >
                <Text type="regular" weight="500" color="white">
                  Adicionar
                </Text>
              </Button>
            </Col>
          </Row>
        }
      />
    </>
  );
}

export default ProfileSelection;
