/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */

import React, { useMemo, useState } from 'react';

import { Col, Row } from 'react-bootstrap';

import { cooperplaceApi } from 'services/api';

import Modal from 'v3/components/Modal';
import Select from 'v3/components/Select';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';

import { useUser } from '../context';
import { fetchCostCenters } from '../controller';

export default function CostCentersManagedModal({ isOpen, onClose }) {
  const user = useUser();

  const [costCentersManaged, setCostCentersManaged] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const isCostCenterAlreadyRelated = useMemo(() => {
    return costCentersManaged.find(costCenter =>
      user.data.costCentersManaged?.some(item => item.id === costCenter.id)
    );
  }, [user.data.costCentersManaged, costCentersManaged]);

  function handleModalClose() {
    setError('');
    setCostCentersManaged([]);
    onClose();
  }

  function handleAddCostCenterManaged() {
    if (isCostCenterAlreadyRelated) {
      setError(
        `O centro de custo ${isCostCenterAlreadyRelated.name} já está vinculado.`
      );
      return;
    }

    user.setData({
      costCentersManaged: [
        ...user.data.costCentersManaged,
        ...costCentersManaged,
      ],
    });

    handleModalClose();
  }

  async function handleAttachCostCenterManaged() {
    if (isCostCenterAlreadyRelated) {
      setError(
        `O centro de custo ${isCostCenterAlreadyRelated.name} já está vinculado.`
      );
      return;
    }

    try {
      setLoading(true);
      await cooperplaceApi.put(`users/${user.data.id}/addCostCenterManaged`, {
        costCentersIds: costCentersManaged.map(costCenter => costCenter.id),
      });

      user.setData({
        costCentersManaged: [
          ...user.data.costCentersManaged,
          ...costCentersManaged,
        ],
      });

      handleModalClose();
    } catch (ex) {
      user.showSnackbar('Erro ao adicionar centro de custo', { type: 'error' });
    } finally {
      setLoading(false);
    }
  }

  function handleAdd() {
    if (user.data.id) handleAttachCostCenterManaged();
    else handleAddCostCenterManaged();
  }

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      heading={
        <Text color="dark" type="header">
          Adicionar centros de custo
        </Text>
      }
      body={
        <Row>
          <Col xs={12} className="d-flex flex-column align-items-center">
            <Select.Async
              className="mt-3"
              label={
                <Text type="label" weight="500">
                  Centros de Custo
                </Text>
              }
              onSearch={search => fetchCostCenters({ search, listAll: true })}
              value={costCentersManaged}
              error={error}
              getOptionLabel={option => `${option.name}`}
              getOptionValue={option => option.id}
              onChange={value => setCostCentersManaged(value || [])}
              multiple
            />
            <Button
              onClick={handleAdd}
              className="mt-2"
              disabled={costCentersManaged.length === 0}
              loading={loading}
            >
              <Text type="regular" weight="500" color="white">
                Adicionar
              </Text>
            </Button>
          </Col>
        </Row>
      }
    />
  );
}
