import moment from 'moment';
import {
  isBeforeThanStartDest,
  isBeforeThanStartOrigin,
  isDateInMoreThanMinutesFromNow,
  isEndDestAValidDate,
  isEndOriginAValidDate,
  isStartDestAValidDate,
  isStartOriginAValidDate,
} from 'v3/pages/PreLoad/validator';
import * as Yup from 'yup';

export const LoadDataGroSchema = Yup.object().shape({
  loadCreationNumber: Yup.string().required(
    'Informe a quantidade a ser criada!'
  ),
  client: Yup.object()
    .required('Cliente obrigatório')
    .typeError('Cliente obrigatório'),
  operation: Yup.object()
    .required(
      'Deve ser selecionado alguma operação para prosseguir com o cadastro da carga!'
    )
    .typeError(
      'Deve ser selecionado alguma operação para prosseguir com o cadastro da carga!'
    ),
  costCenter: Yup.object()
    .required('Centro de custo obrigatório')
    .typeError('Centro de custo inválido'),
  product: Yup.object()
    .required(
      'Deve ser selecionado algum produto para prosseguir com o cadastro da carga!'
    )
    .typeError(
      'Deve ser selecionado algum produto para prosseguir com o cadastro da carga!'
    ),
  branchOffice: Yup.object()
    .required('Filial é obrigatória')
    .typeError('Filial é obrigatória'),
  negotiation: Yup.object()
    .required('Negociação é obrigatória')
    .typeError('Negociação é obrigatória'),
  negotiator: Yup.array()
    .of(Yup.object())
    .min(1, 'Negociador obrigatório')
    .required('Negociador é obrigatório')
    .typeError('Negociador é obrigatório'),
  country: Yup.object()
    .required('País é obrigatório')
    .typeError('País é obrigatório'),
  phone: Yup.string()
    .transform(value => {
      if (!value) {
        return undefined;
      }
      return value.replace('_', '');
    })
    .matches(/^(\(\d{2}\)\d{5}-\d{4}|\d{11})$/, 'Formato incorreto!')
    .required('Telefone obrigatório!'),
  dueDate: Yup.string()
    .nullable()
    .required('Informe uma data')
    .transform(original => {
      if (!original) {
        return undefined;
      }
      return original;
    })
    .test({
      name: 'dueDate',
      test(value, ctx) {
        if (!value) {
          return ctx.createError({ message: 'Informe uma data!' });
        }
        const isValidDate =
          /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/.test(value) ||
          /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/.test(value);
        if (!isValidDate) {
          return ctx.createError({ message: 'Formato inválido' });
        }
        const inputDate = moment(value, 'YYYY-MM-DDTHH:mm');
        const currentDate = moment(new Date(), 'YYYY-MM-DDTHH:mm');
        if (inputDate.isBefore(currentDate)) {
          return ctx.createError({
            message: 'Data não pode ser anterior/igual à data de hoje',
          });
        }
        return true;
      },
    }),
});

export const LocationsGroSchema = Yup.object().shape({
  origin: Yup.object()
    .shape({
      address: Yup.string()
        .required('Endereço obrigatório')
        .typeError('Endereço obrigatório'),
      cityModel: Yup.object()
        .required('Cidade obrigatória')
        .typeError('Cidade obrigatória'),
      province: Yup.string()
        .required('UF obrigatório')
        .typeError('UF obrigatório'),
      date: Yup.string()
        .required('Data máxima obrigatória')
        .typeError('Data máxima obrigatória')
        .test('is-end-a-valid-date', 'Data máxima da coleta inválida', value =>
          isEndOriginAValidDate(value)
        )
        .test(
          'is-end-date-more-than-30-mins-from-now',
          'Data máxima da coleta precisa ser no mínimo daqui a 30 minutos',
          value => isDateInMoreThanMinutesFromNow(30, value)
        ),
      country: Yup.object()
        .shape({
          id: Yup.number(),
        })
        .required('País obrigatório')
        .typeError('País inválido'),
      zipCode: Yup.string(),
      startSchedule: Yup.string()
        .required('Data início obrigatória')
        .typeError('Data início inválida')
        .test('is-start-a-valid-date', 'Data de início inválida', value =>
          isStartOriginAValidDate(value)
        )
        .test(
          'is-end-date-more-than-30-mins-from-now',
          'Data máxima da coleta precisa ser no mínimo daqui a 30 minutos',
          value => isDateInMoreThanMinutesFromNow(30, value)
        ),
      lat: Yup.number()
        .required('Selecione um logradouro utilizando o seletor do google')
        .transform((currentValue, originalValue) => {
          return originalValue === '' ? undefined : currentValue;
        }),

      lng: Yup.number()
        .required('Selecione um logradouro utilizando o seletor do google')
        .transform((currentValue, originalValue) => {
          return originalValue === '' ? undefined : currentValue;
        }),
    })
    .test(
      'is-before-than-start-schedule',
      'Data início da coleta não pode ser maior que Data máxima da coleta',
      value => isBeforeThanStartOrigin(value)
    ),
  destinations: Yup.array().of(
    Yup.object()
      .shape({
        address: Yup.string()
          .required('Endereço obrigatório')
          .typeError('Endereço inválido'),
        cityModel: Yup.object()
          .required('Cidade obrigatória')
          .typeError('Cidade inválida'),
        province: Yup.string()
          .required('Estado obrigatório')
          .typeError('Estado inválido'),
        date: Yup.string()
          .required('Data máxima obrigatória')
          .typeError('Data máxima inválida')
          .test('is-end-a-valid-date', 'Data máxima inválida', value =>
            isEndDestAValidDate(value)
          )
          .test(
            'is-end-date-more-than-30-mins-from-now',
            'Data máxima precisa ser no mínimo daqui a 30 minutos',
            value => isDateInMoreThanMinutesFromNow(30, value)
          ),
        type: Yup.object()
          .required('Ação obrigatório')
          .typeError('Ação inválida'),
        country: Yup.object()
          .required('País obrigatório')
          .typeError('País inválido'),
        startSchedule: Yup.string()
          .required('Data início obrigatória')
          .typeError('Data início inválida')
          .test(
            'is-start-a-valid-date',
            'Data de início inválida',
            isStartDestAValidDate
          )
          .test(
            'is-start-schedule-more-than-30-mins-from-now',
            'Data início precisa ser no mínimo daqui a 30 minutos',
            value => isDateInMoreThanMinutesFromNow(30, value)
          ),
        lat: Yup.number()
          .required('Selecione um logradouro utilizando o seletor do google')
          .transform((currentValue, originalValue) => {
            return originalValue === '' ? undefined : currentValue;
          }),

        lng: Yup.number()
          .required('Selecione um logradouro utilizando o seletor do google')
          .transform((currentValue, originalValue) => {
            return originalValue === '' ? undefined : currentValue;
          }),
      })
      .test(
        'is-before-than-start-schedule',
        'Data início não pode ser maior que Data máxima',
        value => isBeforeThanStartDest(value)
      )
  ),
});

export const VehicleDataSchema = Yup.object().shape({
  vehicleTypes: Yup.array()
    .nullable()
    .of(Yup.object())
    .min(1, 'Selecione pelo menos um veículo!')
    .required('Selecione pelo menos um veículo!'),
  vehicleBodies: Yup.array()
    .nullable()
    .required('Selecione pelo menos um tipo de carroceria!')
    .of(Yup.object())
    .min(1, 'Selecione pelo menos um tipo de carroceria!'),
  vehicleImplements: Yup.mixed().when('vehicleTypes', {
    is: value => value?.filter(e => e.id === 18)?.length > 0,
    then: Yup.array()
      .min(1, 'Selecione pelo menos um implemento!')
      .typeError('Selecione pelo menos um implemento!'),
    otherwise: Yup.mixed(),
  }),
  managerTrackerOptions: Yup.array().optional().nullable(),
  managerTracker: Yup.array().when('managerTrackerOptions', {
    is: value => value?.length > 0,
    then: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.number().required('Informe uma gerenciadora'),
        })
      )
      .min(1, 'Informe no mínimo uma gerenciadora')
      .typeError('Informe uma gerenciadora')
      .required('Informe uma gerenciadora'),
    otherwise: Yup.array().nullable().optional(),
  }),
  disposition: Yup.object()
    .required('Disposição da carga obrigatório')
    .nullable(),
  weight: Yup.number()
    .transform(value => {
      if (!value) {
        return undefined;
      }
      return Number(value);
    })
    .positive('Peso precisa ser um valor positivo')
    .required('Peso obrigatório'),
  pallets: Yup.mixed().when('disposition', {
    is: value => value && value?.name?.toLowerCase() === 'paletizada',
    then: Yup.number()
      .positive('Pallets precisa ser um valor positivo')
      .max(128, 'Máximo de pallet é 128')
      .required('Número de pallets obrigatório')
      .typeError('Número de pallets obrigatório'),
    otherwise: Yup.mixed().nullable(),
  }),
  cargoValue: Yup.number()
    .transform(value => {
      if (!value) {
        return undefined;
      }
      if (Number(value) === 0) {
        return undefined;
      }
      return Number(value);
    })
    .required('Informe valor da carga!')
    .typeError('Informe valor da carga'),
});

export const FreightValuesSchema = Yup.object().shape({
  taker_value: Yup.string().required(' ').typeError(' '),
  load_value_type: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .default('null')
    .required('Selecione um Tipo')
    .typeError('Tipo obrigatório'),
  negotiation_type: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .default('null')
    .required('Selecione uma Negociação')
    .typeError('Negociação obrigatória'),
});
