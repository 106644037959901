import React, { useState } from 'react';
import axios from 'axios';
import config from 'config';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Card from 'components/Card';
import Button from 'components/Button';
import IOSSwitch from 'components/Switch';
import CardItem from 'components/CardItem';

export default function CardGrupo(props) {
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(props.group.status);

  async function toggleStatus(group) {
    await axios.put(`${config.server}groups/${group.id}`, {
      status: !active,
    });
  }

  function handleClickOpen(group) {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  async function confirmOption(group) {
    setOpen(false);
    setActive(!active);
    toggleStatus(group);
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Habilitar grupo</DialogTitle>
        <DialogContent>
          <DialogContentText>Deseja habilitar o grupo?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button outline={1} onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button
            outline={1}
            onClick={() => confirmOption(props.group)}
            color="primary"
            autoFocus
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container>
        <Grid item lg={6} md={8} sm={10} xs={12}>
          <Card
            style={{
              marginTop: '10px',
            }}
          >
            <Grid container spacing={0} style={{ padding: '10px' }}>
              <Grid item xs={10}>
                <CardItem className="nameClients">{props.group.name}</CardItem>
              </Grid>
              <Grid
                item
                xs={2}
                container
                direction="row-reverse"
                justify="flex-start"
                alignItems="baseline"
              >
                <FormControlLabel
                  style={{
                    color: '#76838F',
                  }}
                  control={
                    <IOSSwitch
                      checked={active}
                      value={active}
                      onClick={() => handleClickOpen(props.group)}
                    />
                  }
                  labelPlacement="start"
                  label={active ? 'ativo' : 'inativo'}
                />
              </Grid>
              <Grid item xs={12}>
                <hr />
                <Link
                  style={{
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    cursor: 'pointer',
                  }}
                  onClick={() => props.handleOpened(props.group)}
                >
                  Ver / Editar
                </Link>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
