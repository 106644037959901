import styled from 'styled-components';
import { Col } from 'react-bootstrap';
import { Text } from 'v3/components';
import { Marker } from 'react-simple-maps';
import theme from 'v3/theme';

export const SVGMapsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 10px;
  padding-top: 1rem;
`;

export const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
`;

export const StyledTextTitle = styled(Text)`
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
`;

export const MapContainer = styled.span`
  width: inherit;
  @media screen and (max-width: 767px) and (min-width: 579px) {
    display: inline-block;
    margin-bottom: 2rem;
  }
`;

export const WithoutTrackerContainer = styled(Text)`
  color: ${props => props.theme.colors.dark};
  & > span {
    color: ${props => props.theme.colors.danger};
    font-weight: bold;
    cursor: pointer;
  }
`;

export const CurrentFilter = styled.span`
  font-weight: bold;
  color: ${props => props.theme.colors.dark};
`;

export const LatamLabelContainer = styled.div`
  width: fit-content;
  padding: 1rem;
  position: relative;
  height: 130px;
  margin-left: auto;
  margin-top: -8.5rem;
  font-size: 1rem;

  & > div {
    display: flex;
    gap: 0.25rem;
  }
  @media screen and (max-width: 578px) {
    display: none;
  }
  @media screen and (max-width: 1099px) and (min-width: 768px) {
    display: none;
  }
`;

export const BrazilLabelContainer = styled.div`
  z-index: -1;
  width: fit-content;
  padding: 1rem;
  position: relative;
  height: 130px;
  margin-right: auto;
  margin-top: -8.5rem;
  font-size: 1rem;
  & > div {
    display: flex;
    gap: 0.25rem;
  }
  @media screen and (max-width: 578px) {
    display: none;
  }
  @media screen and (max-width: 1099px) and (min-width: 768px) {
    display: none;
  }
`;

export const StyledColorBoxSpan = styled.span`
  &::before {
    content: '';
    display: inline-block;
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 6px;
    background-color: ${props => props.color};
  }
`;

export const StyledMarker = styled(Marker)`
  font-size: 0.25rem;
  font-weight: bold;
  & > text {
    fill: ${theme.colors.gray};
    font-size: smaller;
  }
`;
