import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';
import moment from 'moment';
import { endOfDay, isBefore, set, startOfDay, subDays } from 'date-fns';
import { ThreeDots } from 'react-loader-spinner';
import {
  FaClock,
  FaExclamationCircle,
  FaMapMarkerAlt,
  FaPodcast,
} from 'react-icons/fa';
import FileSaver from 'file-saver';
import { useQuery, useModal } from 'hooks';
import { validatePermission } from 'actions';
import api, { cooperplaceApi } from 'services/api';
import apiReport from 'services/apis/report';
import Snackbar from 'components/Snackbar';
import theme from 'v3/theme';
import { formatDateTime } from 'v3/utils/formatter';
import ApplicationLayout from 'v3/layouts/Application';
import { useSnackbar } from 'v3/components/Snackbar';
import {
  Badge,
  Card,
  Fallback,
  LinearProgressBar,
  Tooltip,
  Text,
  Button,
} from 'v3/components';
import { OccurrencesModal } from 'v3/components/OccurrencesModal';
import ContractPendingMessage from 'v3/components/ContractPendingMessage';
import { ColumnPickerModal } from './Modals/ColumnPicker';
import {
  avaliableColumnsWithDefinitions,
  getCustomSortFunction,
  trackingStatusColor,
} from './MuiTable/utils';
import { StyledCellSpan, StyledOccurrenceCel } from './MuiTable/styles';
import { CardShadowBoxStyled, TravelStatusCardsStyle } from './Card/style';
import { Filter } from './Filter';
import { MuiTable } from './MuiTable';
import { fetchAvailableColumns, retrieveUserDefinitions } from './service';
import { validateIntervalOfDate } from './validation';
import Indicators from './Indicators';
import MapCluster from './MapCluster';
import { setQueryParams } from './utils';
import ColumnTemperature from './ColumnTemperature';
import { DestinationsModal } from './Modals/Destinations';
import RouteStation from './RouteStation';

export default function ReportTravelTracking() {
  const history = useHistory();
  const queryParams = useQuery();
  const scrollView = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    history.replace({ search: queryParams.toString() });
  };
  const [travelsMapStatus, setTravelsMapStatus] = useState();
  const [branchOffices, setBranchOffices] = useState();
  const [filterTravelVehicle, setFilterTravelVehicle] = useState('');
  const filteredVehicles = useMemo(() => {
    if (filterTravelVehicle > 0 && travelsMapStatus) {
      const filtered = travelsMapStatus.filter(
        t => Number(t.travelId) === Number(filterTravelVehicle)
      );
      return filtered;
    }
    return travelsMapStatus;
  }, [filterTravelVehicle, travelsMapStatus]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({});
  const [fetching, setFetching] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [fetchingTravelOccurrences, setFetchingTravelOccurrences] =
    useState(false);
  const [toggleFilters, setToggleFilters] = useState(false);
  const [travelsStatus, setTravelsStatus] = useState([]);
  const [toggleMaps, setToggleMaps] = useState(false);
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
  const [qtdAdvancedFilters, setQtdAdvancedFilters] = useState(0);
  const [travels, setTravels] = useState([]);
  const [checked, setChecked] = useState(false);
  const [loadingIndicators, setLoadingIndicators] = useState(true);
  const [snackBar, setSnackBar] = useState({
    display: false,
    type: 'error',
    message: '',
  });
  const [pagination, setPagination] = useState({
    lastPage: 1,
    currentPage: 1,
    total: 0,
  });
  const [perPage, setPerPage] = useState(10);
  const [savingNewOccurrence, setSavingNewOccurrence] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [destination, setDestination] = useState(null);

  const snackbar = useSnackbar();

  const hasPermissionToSeeDashboards = validatePermission(
    'VISUALIZAR_ACOMPANHAMENTO_VIAGEM'
  );

  useEffect(() => {
    if (!hasPermissionToSeeDashboards) {
      snackbar.show(
        <Text>
          Você não tem permissão para acessar o acompanhamento de viagem!
        </Text>,
        { type: 'error' }
      );
      setTimeout(() => {
        history.push('/');
      }, 500);
    }
  }, []);

  const columnPickerModal = useModal();
  const destinationModel = useModal();
  const occurrencesModal = useModal();
  const [travelId, setTravelId] = useState(null);
  const [bennerTravelId, setBennerTravelId] = useState(null);
  const [avaliableColumns, setAvaliableColumns] = useState([]);
  const [loadingUserColumns, setLoadingUserColumns] = useState(false);
  const [userColumns, setUserColumns] = useState([]);
  const [picked, setPicked] = useState(avaliableColumnsWithDefinitions);

  const [columnUppercase, setColumnUppercase] = useState(false);

  async function readColumnUppercase() {
    let colUppercase = await JSON.parse(
      localStorage.getItem('travelsControlTowerUserColumnUppercase')
    );
    if (!colUppercase) {
      colUppercase = false;
    }
    setColumnUppercase(colUppercase);
    return colUppercase;
  }

  const openInNewTab = url => {
    window.open(url, '_blank', 'noreferrer');
  };
  const showPercentage = travel => {
    if (isNaN(travel.trackingStatusPercentage)) {
      return false;
    }

    return travel.statusCode === 'ST2' || travel.statusCode === 'ST3';
  };
  const userColumnsSlugs = useMemo(
    () => userColumns.map(item => item.slug),
    [userColumns]
  );

  function capitalize(value) {
    let colUppercase = JSON.parse(
      localStorage.getItem('travelsControlTowerUserColumnUppercase')
    );
    if (!colUppercase) {
      colUppercase = false;
    }
    let result = value;
    if (colUppercase) {
      result = result.toUpperCase();
    }
    return result;
  }

  function openModalDestination(travel) {
    setDestination(travel);
    destinationModel.open()
  }

  const mappedColumns = useMemo(() => {
    const processedColumns =
      userColumnsSlugs.length > 0
        ? avaliableColumnsWithDefinitions
          .filter(
            item =>
              userColumnsSlugs.includes(item.slug) ||
              item.slug === 'detailsButton' ||
              item.slug === 'occurrences' ||
              item.slug === 'margins'
          )
          .map(column => {
            return {
              columnStyles: column.columnStyles,
              size: column.size,
              component: column.component,
              sortingFn: (rowA, rowB, columnId) => {
                const customFunction = getCustomSortFunction(
                  rowA,
                  rowB,
                  columnId
                );
                return customFunction(rowA, rowB);
              },
              Cell: ({ cell, row }) => {
                const value = column.format
                  ? column.format(cell.getValue())
                  : cell.getValue();
                const title = `${column.title}: ${value}`;
                const dateLimit = subDays(new Date(), 1);
                const capturedAt = new Date(row.original?.locationCapturedAt);
                const isOlderThanDateLimit = isBefore(capturedAt, dateLimit);
                const isTravelWithOlderTrackerPosition =
                  isOlderThanDateLimit && row.original?.locationCapturedAt;
                return column.component === 'Text' ? (
                  <StyledCellSpan
                    title={title}
                    style={column.componentStyles}
                  >
                    {value}
                  </StyledCellSpan>
                ) : column.component === 'trackingStatusName' ? (
                  row.original?.trackingStatusSlug ? (
                    <>
                      <Badge
                        pill
                        variant={
                          trackingStatusColor[
                          row.original?.trackingStatusSlug
                          ]
                        }
                      >
                        <Text
                          type="regular"
                          fontSize="0.75rem"
                          color="white"
                          title="status da viagem"
                        >
                          {row.original?.trackingStatusName}
                        </Text>
                      </Badge>
                      {row.original?.trackingLog ? (
                        <Tooltip
                          style={{
                            backgroundColor: '#FFF',
                            color: '#3c3c3c',
                          }}
                          content={row.original?.trackingLog}
                        >
                          <FaExclamationCircle className="ml-2" />
                        </Tooltip>
                      ) : null}
                    </>
                  ) : (
                    <Text
                      type="regular"
                      fontSize="0.75rem"
                      color="gray"
                      title="Status da Viagem"
                    >
                      Não informado
                    </Text>
                  )
                ) : column.component === 'trackingStatusPercentage' ? (
                  showPercentage(row.original) ? (
                    <div className="d-flex align-items-center justify-content-center">
                      <div style={{ width: '100%' }}>
                        <LinearProgressBar
                          value={row.original?.trackingStatusPercentage}
                          color={
                            row.original?.isTravelDelayed
                              ? 'error'
                              : 'success'
                          }
                        />
                        <div className="d-flex align-items-center justify-content-center mt-2">
                          <Text
                            fontSize="0.75rem"
                            title={column.title}
                          >{`${Math.round(
                            row.original?.trackingStatusPercentage > 0 &&
                              row.original?.trackingStatusPercentage <= 100
                              ? row.original?.trackingStatusPercentage
                              : 0
                          )}%`}</Text>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <StyledCellSpan title={title}>
                      Não informado
                    </StyledCellSpan>
                  )
                ) : column.component === 'lastPositionDescription' ? (
                  row.original?.lastPositionDescription &&
                    row.original?.locationCapturedAt ? (
                    <span
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '0.5rem',
                      }}
                      title={title}
                    >
                      <StyledCellSpan>
                        <FaMapMarkerAlt color={theme.colors.gray} size={14} />{' '}
                        {value}
                      </StyledCellSpan>

                      <StyledCellSpan>
                        <FaClock color={theme.colors.gray} size={14} />{' '}
                        {formatDateTime(row.original.locationCapturedAt)}
                      </StyledCellSpan>

                      {row.original?.locationCapturedAt &&
                        isTravelWithOlderTrackerPosition ? (
                        <>
                          <StyledCellSpan>
                            <FaExclamationCircle
                              color={theme.colors.danger}
                              size={14}
                            />{' '}
                            Última localização excedeu 24 horas
                          </StyledCellSpan>
                        </>
                      ) : null}

                      {row.original?.battery_level && (
                        <>
                          <StyledCellSpan>
                            <FaPodcast
                              color={theme.colors.success}
                              size={14}
                            />{' '}
                            {row.original?.battery_level == 2
                              ? 'Posição via Rastreador'
                              : row.original?.battery_level == 1
                                ? 'Posição via App'
                                : ''}
                          </StyledCellSpan>
                        </>
                      )}
                    </span>
                  ) : (
                    <StyledCellSpan>Não informado</StyledCellSpan>
                  )
                ) : column.component === 'Link' ? (
                  <Link to={column.to(value)} title={title}>
                    {value}
                  </Link>
                ) : column.component === 'occurencesComponent' ? (
                  <StyledCellSpan
                    onClick={() =>
                      openOccurrencesModal(
                        row?.original?.travel_id,
                        row?.original?.externalId
                      )
                    }
                    clickable
                  >
                    <StyledOccurrenceCel
                      color={theme.colors.success}
                      title="Ocorrências concluídas"
                    >
                      {row?.original?.travelFinishedOccurrences ?? '0'}
                    </StyledOccurrenceCel>
                    {' : '}
                    <StyledOccurrenceCel
                      color={theme.colors.danger}
                      title="Ocorrências pendentes"
                    >
                      {row?.original?.travelPendencyOccurrences ?? '0'}
                    </StyledOccurrenceCel>
                  </StyledCellSpan>
                ) : column.component === 'lastPositionDescription' ? (
                  row.original?.lastPositionDescription &&
                    row.original?.locationCapturedAt ? (
                    <span
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '0.5rem',
                      }}
                      title={title}
                    >
                      <StyledCellSpan>
                        <FaMapMarkerAlt color={theme.colors.gray} size={14} />{' '}
                        {value}
                      </StyledCellSpan>

                      <StyledCellSpan>
                        <FaClock color={theme.colors.gray} size={14} />{' '}
                        {formatDateTime(row.original.locationCapturedAt)}
                      </StyledCellSpan>

                      {row.original?.locationCapturedAt &&
                        isTravelWithOlderTrackerPosition ? (
                        <>
                          <StyledCellSpan>
                            <FaExclamationCircle
                              color={theme.colors.danger}
                              size={14}
                            />{' '}
                            Última posição do rastreador excedeu o período de
                            24 horas
                          </StyledCellSpan>
                        </>
                      ) : null}
                    </span>
                  ) : (
                    <StyledCellSpan>Não informado</StyledCellSpan>
                  )
                ) : column.component === 'Link' ? (
                  <Link to={column.to(value)} title={title}>
                    {value}
                  </Link>
                ) : column.component === 'occurencesComponent' ? (
                  <StyledCellSpan
                    onClick={() =>
                      openOccurrencesModal(
                        row?.original?.travel_id,
                        row?.original?.externalId
                      )
                    }
                    clickable
                  >
                    <StyledOccurrenceCel
                      color={theme.colors.success}
                      title="Ocorrências concluídas"
                    >
                      {row?.original?.travelFinishedOccurrences ?? '0'}
                    </StyledOccurrenceCel>
                    {' : '}
                    <StyledOccurrenceCel
                      color={theme.colors.danger}
                      title="Ocorrências pendentes"
                    >
                      {row?.original?.travelPendencyOccurrences ?? '0'}
                    </StyledOccurrenceCel>
                  </StyledCellSpan>
                ) : column.component === 'marginsComponent' ? (
                  <StyledCellSpan>
                    <StyledOccurrenceCel
                      color={theme.colors.danger}
                      title="Margens reprovadas"
                    >
                      {row?.original?.travelReprovedMargins >= 1
                        ? capitalize('Reprovada')
                        : null}
                    </StyledOccurrenceCel>
                    <StyledOccurrenceCel
                      color={theme.colors.warning}
                      title="Margens pendentes"
                    >
                      {row?.original?.travelPendingMargins >= 1
                        ? capitalize('Pendente')
                        : null}
                    </StyledOccurrenceCel>
                    <StyledOccurrenceCel
                      color={theme.colors.success}
                      title="Margens Aprovadas"
                    >
                      {row?.original?.travelApprovedMargins >= 1
                        ? capitalize('Aprovada')
                        : null}
                    </StyledOccurrenceCel>
                    <StyledOccurrenceCel
                      color={theme.colors.success}
                      title="Margens Aprovadas"
                    >
                      {row?.original?.travelApprovedMargins < 1 &&
                        row?.original?.travelPendingMargins < 1 &&
                        row?.original?.travelReprovedMargins < 1 &&
                        'Margem ok'}
                    </StyledOccurrenceCel>
                  </StyledCellSpan>
                ) : column.component === 'temperature' ? (
                  <span
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '0.5rem',
                    }}
                    title={title}
                  >
                    <ColumnTemperature paramsTemperature={row?.original} />
                  </span>
                ) : column.component === 'Destinations' ? (
                  <Button onClick={() => openModalDestination({
                    loadId: row.original?.id,
                    travelId: row.original?.travel_id,
                    externalId: row.original?.externalId,
                  })} title="Visualizar coleta, aduanas e destinos da viagem">
                    <Text type="regular" fontSize="0.75rem" color="white">
                      Visualizar destinos
                    </Text>
                  </Button>
                ) : column.component === 'DetailsButton' ? (
                  <Button
                    onClick={() =>
                      openInNewTab(`/viagens/${row.original?.travel_id}`)
                    }
                  >
                    <Text type="regular" fontSize="0.75rem" color="white">
                      Detalhes
                    </Text>
                  </Button>
                ) : column.component === 'RouteStation' ? (
                  <>{ value ? value.map(station => 
                      <RouteStation
                        title={station.name}
                        style={column.componentStyles}
                        station={station}
                      />
                    ) : 'Nenhum Posto Próximo a Rota' }</>
                ) : null;
              },
              order: column.order,
              header: column.title,
              accessorKey: column.slug,
            };
          })
        : [];
    return processedColumns;
  }, [userColumnsSlugs]);

  function openColumnModal() {
    columnPickerModal.open();
  }

  const closeColumnModal = useCallback(
    updated => {
      if (updated) {
        columnPickerModal.close();
      } else {
        setPicked(userColumns);
        columnPickerModal.close();
      }
    },
    [userColumns]
  );

  const closeDestinationModal = useCallback(
    updated => {
      destinationModel.close();
    },
    [userColumns]
  );

  const updateColumns = useCallback(
    async chosenColumns => {
      const previousColumns = [...userColumns];
      try {
        setLoadingUserColumns(true);
        await cooperplaceApi.post(`/user/definitions`, {
          type: 'tower_control_columns',
          definition: JSON.stringify(chosenColumns),
        });
        await fetchTravels();
        setUserColumns([...chosenColumns]);
        setPicked([...chosenColumns]);
        closeColumnModal(true);
      } catch (error) {
        setUserColumns(previousColumns);
        setPicked(previousColumns);
        closeColumnModal(true);
      } finally {
        setLoadingUserColumns(false);
      }
    },
    [userColumns]
  );

  function showError(message) {
    setSnackBar({
      display: true,
      type: 'error',
      message,
    });
  }

  async function getDownload() {
    setLoadingDownload(true);

    const hasLoadDatePeriod =
      queryParams.get('firstPeriod') && queryParams.get('secondPeriod');

    const hasArrivalDatePeriod =
      queryParams.get('firstArrivalPeriod') &&
      queryParams.get('secondArrivalPeriod');

    const firstPeriod = hasLoadDatePeriod
      ? queryParams.get('firstPeriod')
      : hasArrivalDatePeriod
        ? queryParams.get('firstArrivalPeriod')
        : null;

    const secondPeriod = hasLoadDatePeriod
      ? queryParams.get('secondPeriod')
      : hasArrivalDatePeriod
        ? queryParams.get('secondArrivalPeriod')
        : null;

    const { valid, message } = validateIntervalOfDate(
      firstPeriod,
      secondPeriod
    );

    if (!valid) {
      showError(message);
      setLoadingDownload(false);
      return;
    }

    try {
      const response = await apiReport.get(`/travels/report?${queryParams}`, {
        responseType: 'blob',
      });
      FileSaver.saveAs(response.data, response.headers['x-file-name']);
    } catch (error) {
      setSnackBar({
        display: true,
        type: 'error',
        message: 'Não foi possivel gerar o arquivo',
      });
    } finally {
      setLoadingDownload(false);
    }
  }

  async function handleColumnUppercaseChange() {
    const parseColumnUppercase = await readColumnUppercase();
    localStorage.setItem(
      'travelsControlTowerUserColumnUppercase',
      JSON.stringify(!parseColumnUppercase)
    );
    window.location.reload();
  }

  async function fetchTravels(page = 1) {
    try {
      setFetching(true);

      let getPerPage = await JSON.parse(
        localStorage.getItem('travelsControlTowerUserPerPage')
      );

      if (!getPerPage) {
        getPerPage = 10;
      }

      setPerPage(getPerPage);

      const response = await apiReport.get(
        `travels/data?page=${page}&perPage=${getPerPage}&${queryParams}`
      );

      const { data, perPage, total } = response.data;

      if (page === 1) {
        setTravels(data);
        setPagination(total, perPage);
      } else {
        setTravels(data);
      }
    } catch (error) {
      setSnackBar({
        display: true,
        type: 'error',
        message: 'Erro ao buscar relatórios',
      });
    } finally {
      setLoading(false);
      setFetching(false);
    }
  }

  function openOccurrencesModal(clickedTravelId, clickedBennerTravelId) {
    setTravelId(clickedTravelId);
    setBennerTravelId(clickedBennerTravelId);
    setFetchingTravelOccurrences(true);
    occurrencesModal.open();
  }

  const closeOccurrencesModal = useCallback(async () => {
    if (savingNewOccurrence || loadingUpdate) {
      return;
    }
    try {
      setFetchingTravelOccurrences(true);
      const response = await apiReport.get(
        `travels/data?cooperplaceTravelId=${travelId}`
      );
      await getTravelStatus();
      const updatedTravels = travels.map(travel => {
        if (travel.travel_id === travelId) {
          return response.data.data[0];
        }
        return travel;
      });

      setTravels(updatedTravels);
      setTravelId(null);
      occurrencesModal.close();
    } catch {
      setTravelId(null);
      occurrencesModal.close();
    } finally {
      setFetchingTravelOccurrences(false);
    }
  }, [travels, travelId, loadingUpdate, savingNewOccurrence]);

  async function getUserColumnsPreferences() {
    const data = await retrieveUserDefinitions();
    if (data.length) {
      setUserColumns(JSON.parse(data[0].definition));
      setPicked(JSON.parse(data[0].definition));
    } else {
      setUserColumns(avaliableColumnsWithDefinitions);
    }
  }

  async function getColumns() {
    const response = await fetchAvailableColumns();
    setAvaliableColumns(response);
  }

  async function getTravelStatus() {
    try {
      setLoadingIndicators(true);
      const response = await apiReport.get(`/travels/location/?${queryParams}`);
      setTravelsStatus(response.data.indicators);
      if (response.data.pendingContractsIds?.length > 0) {
        snackbar.show(
          <ContractPendingMessage
            travelIds={response.data.pendingContractsIds}
          />,
          {
            type: 'warning',
            duration: 20000,
            showClose: true,
          }
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingIndicators(false);
    }
  }

  async function getBranchOffices() {
    const { data } = await api.get('branch-offices', { params: { isRouteStation: true } });
    setBranchOffices(data);
  }

  async function getTravelsMapStatus() {
    const { data } = await apiReport.get(`/travels/map/?${queryParams}`);
    setTravelsMapStatus(data);
  }

  async function fetchData() {
    setLoading(true);

    const hasLoadDatePeriod =
      queryParams.get('firstPeriod') && queryParams.get('secondPeriod');

    const hasArrivalDatePeriod =
      queryParams.get('firstArrivalPeriod') &&
      queryParams.get('secondArrivalPeriod');

    const firstPeriod = hasLoadDatePeriod
      ? queryParams.get('firstPeriod')
      : hasArrivalDatePeriod
        ? queryParams.get('firstArrivalPeriod')
        : null;

    const secondPeriod = hasLoadDatePeriod
      ? queryParams.get('secondPeriod')
      : hasArrivalDatePeriod
        ? queryParams.get('secondArrivalPeriod')
        : null;

    const { valid, message } = validateIntervalOfDate(
      firstPeriod,
      secondPeriod
    );

    if (!valid) {
      showError(message);
      showError(message);
      setLoading(false);
      setLoadingIndicators(false);
      return;
    }

    setChecked(false);
    getBranchOffices();
    getTravelStatus();
    getTravelsMapStatus();
    fetchTravels();
  }

  const handleCloseAdvancedFilters = () => setShowAdvancedFilters(false);
  const handleShowAdvancedFilters = () => setShowAdvancedFilters(true);

  function handleTitleAdvancedFilters() {
    let title = 'Filtro avançado';
    if (qtdAdvancedFilters) {
      title += ` (${qtdAdvancedFilters})`;
    }
    return title;
  }

  async function retrieveUserFilterPreferences() {
    try {
      const { data } = await cooperplaceApi.get(
        `/user/definitions?types[]=tower_control_filters`
      );
      if (data.length) {
        const userFilters = JSON.parse(data[0].definition);
        const startQuery = setQueryParams(userFilters, queryParams);
        history.replace({ search: startQuery.toString() });
        setFilters(userFilters);
      } else {
        queryParams.set(
          'firstPeriod',
          startOfDay(subDays(new Date(), 60, new Date())).toISOString()
        );
        queryParams.set(
          'secondPeriod',
          endOfDay(new Date(moment())).toISOString()
        );
        history.replace({ search: queryParams.toString() });
        setFilters({
          firstPeriod: moment().subtract(60, 'days').format('DD/MM/YYYY'),
          secondPeriod: moment().format('DD/MM/YYYY'),
        });
      }
    } catch (error) {
      setSnackBar({
        display: true,
        type: 'error',
        message: 'Não foi possível recuperar os filtros do usuário',
      });
    }
  }
  async function fetchUserFilterPreferences() {
    await retrieveUserFilterPreferences();
    fetchData();
  }

  useEffect(() => {
    fetchUserFilterPreferences();
    getColumns();
    getUserColumnsPreferences();
  }, []);

  return (
    <>
      <ApplicationLayout
        title="Acompanhento de viagens"
        contentFluid
        isOld
        onlyCenterComponent
        fontSize="20px"
      >
        <ColumnPickerModal
          isOpen={columnPickerModal.isOpen}
          onClose={closeColumnModal}
          avaliableColumns={avaliableColumns}
          updateColumns={updateColumns}
          picked={picked}
          setPicked={setPicked}
          loading={loadingUserColumns}
        />
        <DestinationsModal
          destination={destination}
          isOpen={destinationModel.isOpen}
          onClose={closeDestinationModal}
        />
        <Snackbar
          open={snackBar.display}
          type={snackBar.type}
          message={snackBar.message}
          onClose={() => setSnackBar(false)}
        />

        <Row className="d-flex">
          <Col lg={6}>
            <Filter
              handleTitleAdvancedFilters={handleTitleAdvancedFilters}
              handleCloseAdvancedFilters={handleCloseAdvancedFilters}
              handleShowAdvancedFilters={handleShowAdvancedFilters}
              showAdvancedFilters={showAdvancedFilters}
              loading={loading}
              setLoading={setLoading}
              fetchData={fetchData}
              getDownload={getDownload}
              loadingDownload={loadingDownload}
              setQtdAdvancedFilters={setQtdAdvancedFilters}
              toggleFilters={toggleFilters}
              setToggleFilters={setToggleFilters}
              filters={filters}
              setFilters={setFilters}
              openColumnModal={openColumnModal}
            />
          </Col>
          <Col lg={4}>
            <MapCluster
              travelsMapStatus={filteredVehicles}
              toggleMaps={toggleMaps}
              branchOffices={branchOffices}
              queryParams={queryParams.get('routeStations') ? queryParams.get('routeStations') : null}
              setToggleMaps={setToggleMaps}
              loading={loading}
              filterTravelVehicle={filterTravelVehicle}
              setFilterTravelVehicle={setFilterTravelVehicle}
              checked={checked}
              setChecked={setChecked}
            />
          </Col>
        </Row>
        <Fallback
          fall={travelsStatus?.length === 0}
          component={
            <Col className="pb-5 d-flex justify-content-center align-items-center">
              <Button colors={theme.colors.primary} size="md" disabled>
                Não há indicadores a serem exibidos
              </Button>
            </Col>
          }
        >
          {loadingIndicators ? (
            <TravelStatusCardsStyle>
              <Col md={12}>
                <CardShadowBoxStyled
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <ThreeDots
                    type="ThreeDots"
                    color="#00BFFF"
                    height={80}
                    width={80}
                    wrapperStyle={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  />
                </CardShadowBoxStyled>
              </Col>
            </TravelStatusCardsStyle>
          ) : (
            <Indicators travelsStatus={travelsStatus} fetchData={fetchData} />
          )}
        </Fallback>
        <Fallback
          fall={travels?.length === 0}
          component={
            <Col className="pb-5 d-flex justify-content-center align-items-center">
              <Button colors={theme.colors.primary} size="md" disabled>
                Não há itens a serem exibidos
              </Button>
            </Col>
          }
        >
          <div className="d-flex">
            <div className="ml-auto">
              <Button
                variant={columnUppercase ? 'primary' : 'success'}
                onClick={() => handleColumnUppercaseChange()}
                size="sm"
                className="ml-1"
              >
                <Text
                  weight={500}
                  color="white"
                  type="regular"
                  fontSize="0.75rem"
                >
                  A^
                </Text>
              </Button>
              <Button
                variant="primary"
                loading={loadingDownload}
                onClick={() => getDownload()}
                size="sm"
                className="ml-1"
              >
                <Text type="regular" weight={500} fontSize="0.75rem">
                  Exportar
                </Text>
              </Button>
              <Button
                variant="primary"
                onClick={() => openColumnModal()}
                size="sm"
                className="ml-1"
              >
                <Text
                  weight={500}
                  color="white"
                  type="regular"
                  fontSize="0.75rem"
                >
                  Editar Colunas
                </Text>
              </Button>
            </div>
          </div>
          <Row className="mt-2">
            <Card loading={fetching} bodyCardPadding="0.3rem 1rem 1rem">
              <MuiTable
                travels={travels}
                fetchTravels={fetchTravels}
                pagination={pagination}
                perPage={perPage}
                setPagination={setPagination}
                fetching={fetching}
                mappedColumns={mappedColumns}
                setFilterTravelVehicle={setFilterTravelVehicle}
                scrollView={scrollView}
                setChecked={setChecked}
                userColumnsSlugs={userColumnsSlugs}
              />
            </Card>
          </Row>
        </Fallback>
        <OccurrencesModal
          isOpen={occurrencesModal.isOpen}
          onClose={closeOccurrencesModal}
          travelId={travelId}
          bennerTravelId={bennerTravelId}
          fetchingTravelOccurrences={fetchingTravelOccurrences}
          setFetchingTravelOccurrences={setFetchingTravelOccurrences}
          savingNewOccurrence={savingNewOccurrence}
          setSavingNewOccurrence={setSavingNewOccurrence}
          loadingUpdate={loadingUpdate}
          setLoadingUpdate={setLoadingUpdate}
        />
      </ApplicationLayout>
    </>
  );
}
