import React, { useState, useEffect } from 'react';
import { useParams, Link , useHistory} from 'react-router-dom';
import { Row } from 'react-bootstrap';

import Text from 'v3/components/Text';
import Card from 'v3/components/Card';
import Button from 'v3/components/Button';
import List from 'v3/components/List';
import { useSnackbar } from 'v3/components/Snackbar';
import ApplicationLayout from 'v3/layouts/Application';

import api from 'services/api';
import { usePermission } from 'hooks';

import {
  YEARS_OPTIONS,
  AXYS_RANGE,
} from '../shared/utils';

import Edit from './Edit';
import Data from './Data';

export default function MinimunFreightTableDetails() {
  const hasPermissionToEdit = usePermission('ATUALIZAR_TABELA_FRETE_MINIMO');
  const history = useHistory();
  const snackbar = useSnackbar();
  const params = useParams();

  const [item, setItem] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(true);
  const [editing, setEditing] = useState(false);
  const [loadTypeOptions, setLoadTypeOptions] = useState([]);

  const [newLoadType, setNewLoadType] = useState();
  const [newYear, setNewYear] = useState();
  const [newChargePrice, setNewChargePrice] = useState();
  const [newValue, setNewValue] = useState();
  const [newQtyAxys, setNewQtyAxys] = useState();
  usePermission('VISUALIZAR_TABELA_FRETE_MINIMO', { redirect: true });

  async function getMinimumFreightTableItem() {
    try {
      setLoading(true);
      const response = await api.get(`v3/antt-data/${params.id}`);
      const name = loadTypeOptions.find((item)=>item.id === response.data.load_type)?.nome;
      const data = response.data;
      setItem({...data, name});
      setNewLoadType({nome:name,id:response.data.load_type});
      setNewYear({label:String(response.data?.year), value:response.data?.year});
      setNewChargePrice(response.data?.charge_price);
      setNewValue(response.data?.value);
      setNewQtyAxys(response.data?.qty_axys);
      setError(false);
    } catch (err) {
      setItem({});
      setError(true);
      snackbar.show(<Text>Algo deu errado, tente novamente mais tarde</Text>, {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }

  async function fetchLoadTypeOptions(){
    try{
      const { data } = await api.get('v3/load-types');
      setLoadTypeOptions(data);
      return;
    } catch(err){
      return;
    }
  }

  useEffect(() => {
    fetchLoadTypeOptions();
  }, []);
  
  useEffect(() => {
    getMinimumFreightTableItem();
  }, [loadTypeOptions]);

  async function handleSubmit() {
    const axys = Number(newQtyAxys);
    if (!AXYS_RANGE.includes(axys)) {
      snackbar.show(
        <Text>
          Quantidade de Eixos deve ser um dos seguintes valores: 2, 3, 4, 5, 6,
          7 ou 9
        </Text>,
        {
          type: 'error',
        }
      );
      return;
    }
    const year = Number(newYear?.value) || Number(newYear);
    if (year === 0) {
      snackbar.show(<Text>O ano vigente é obrigatório!</Text>, {
        type: 'error',
      });
      return;
    }

    if (newLoadType === null) {
      snackbar.show(<Text>O tipo de carga é obrigatório!</Text>, {
        type: 'error',
      });
      return;
    }
    const loadTypeInt = newLoadType?.id;

    try {
      setLoading(true);
      await api.put(`v3/antt-data/${params.id}`, {
        load_type: loadTypeInt,
        year: year,
        value: newValue,
        charge_price: newChargePrice,
        qty_axys: newQtyAxys,
      });
      snackbar.show(<Text>Registro editado com sucesso!</Text>, {
        type: 'success',
      });
    } catch (error) {
      if (error) {
        snackbar.show(<Text>Erro ao atualizar registro. Tente novamente</Text>, {
          type: 'error',
        });
      }
    } finally {
      setLoading(false);
      setEditing(false);
      history.push({ pathname: `/tabela-frete-minimo-antt`});
    }
  }
  return (
    <>
      <ApplicationLayout
        title="Detalhes do item da tabela de frete mínimo"
        RightComponent={
          <div className="text-right">
            <Link to={`/tabela-frete-minimo-antt/`}>
            <Button
              variant="secondary"
            >
              <Text weight={500} type="regular">
                Voltar
              </Text>
            </Button>
            </Link>
          </div>
        }
      >
        <Row>
          <Card
            header={
              <Text color="#464E5F" type="header">
                {item?.name}
              </Text>
            }
            HeaderRightComponent={
              hasPermissionToEdit && (
                <div>
                  {editing && (
                    <Button
                      onClick={() => setEditing(false)}
                      variant="secondary"
                      loading={loading}
                      className="mr-2"
                    >
                      <Text color="dark" type="regular">
                        Cancelar
                      </Text>
                    </Button>
                  )}
                  <Button
                    onClick={
                      editing ? () => handleSubmit() : () => setEditing(true)
                    }
                    variant={editing ? 'primary' : 'secondary'}
                    loading={loading}
                  >
                    <Text
                      color={editing ? 'white' : 'dark'}
                      weight={500}
                      type="regular"
                    >
                      {editing ? 'Salvar' : 'Editar'}
                    </Text>
                  </Button>
                </div>
              )
            }
          >
            <Edit editing={editing} newYear={newYear} YEARS_OPTIONS={YEARS_OPTIONS} setNewYear={setNewYear} newLoadType={newLoadType} loadTypeOptions={loadTypeOptions} setNewLoadType={setNewLoadType} newValue={newValue} setNewValue={setNewValue} newChargePrice={newChargePrice} setNewChargePrice={setNewChargePrice} newQtyAxys={newQtyAxys} setNewQtyAxys={setNewQtyAxys}/>
            
            <Data loading={loading} editing={editing} error={error} item={item} />
            {loading && <List loading={loading} data={item} />}
          </Card>
        </Row>
      </ApplicationLayout>
    </>
  );
}
