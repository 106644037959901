/* eslint-disable import/no-unresolved */
import api from 'services/api';

export async function getIssuer(id) {
  try {
    const response = await api.get(`issuers/${id}`);

    return response.data;
  } catch (err) {
    return { error: 'Erro ao buscar emissor' };
  }
}

export async function fetchTaxSituations() {
  try {
    const { data: response } = await api.get('issuer-tax-situations');
    return response;
  } catch (err) {
    return [];
  }
}
