import React, { useLayoutEffect } from 'react';

import { Row, Col, Container } from 'react-bootstrap';
import ApplicationLayout from 'v3/layouts/Application';
import api from 'services/api';
import { VerticalRule } from './style';

import Favorites from './Favorites/Favorites';
import Recents from './Recents/Recents';
import MostAccessed from './MostAccessed/MostAccessed';

import config from '../../../config';

function Home() {
  async function savePushUserId() {
    const isPushNotificationsEnabled = localStorage.getItem(
      'isPushNotificationsEnabled'
    );
    const updatePushUserId = localStorage.getItem('updatePushUserId');
    if (isPushNotificationsEnabled && updatePushUserId) {
      const oneSignalPlayerId = localStorage.getItem('oneSignalPlayerId');
      const { push_user_id } = JSON.parse(localStorage.getItem('usuario'));
      if (oneSignalPlayerId !== push_user_id) {
        if (oneSignalPlayerId) {
          await api.put('user/add-user-id', {
            push_user_id: oneSignalPlayerId,
          });
          localStorage.setItem('updatePushUserId', false);
        }
      }
    }
  }

  useLayoutEffect(() => {
    savePushUserId();
  }, []);

  return (
    <div style={{ height: '83vh' }}>
      <ApplicationLayout title="Home" isOld />
      <div style={{ paddingLeft: '50px', height: '100%' }}>
        <Row
          style={{
            backgroundColor: '#E5E5E5',
            maxHeight: '77px',
            margin: 0,
          }}
        >
          <MostAccessed />
        </Row>
        <Row
          style={{ backgroundColor: '#FAFAFA', height: '100%' }}
          className="m-0"
        >
          <Container className="d-flex flex-row">
            <Col md={5}>
              <Favorites />
            </Col>
            <Col className="col-md-2 d-flex justify-content-center align-center mt-5">
              <VerticalRule />
            </Col>
            <Col md={5}>
              <Recents />
            </Col>
          </Container>
        </Row>
        <span
          style={{
            position: 'fixed',
            right: '20px',
            bottom: '2px',
            color: '#111',
          }}
        >
          VERSÃO {config.versionNumber}
        </span>
      </div>
    </div>
  );
}

export default Home;
