/* eslint-disable import/no-unresolved */
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';

import Text from 'v3/components/Text';
import Select from 'v3/components/Select';
import Input from 'v3/components/Input';
import Button from 'v3/components/Button';
import Toggle from 'v3/components/Toggle';
import Modal from 'v3/components/Modal';
import DatePicker from 'v3/components/DatePicker';

import api from 'services/api';
import useQuery from 'hooks/useQuery';
import { removeDuplicates } from 'v3/utils/array';
import { validatePermission } from 'actions';
import {
  fetchCities,
  fetchCostCenter,
  fetchUsers,
  fetchUser,
  fetchCustomers,
  fetchCustomer,
} from 'utils/fetches';
import { fetchVehicles } from '../Register/Vehicle/controller';
import {
  LOAD_FILTER_DATE_OPTIONS,
  LOAD_FILTER_SORT_OPTIONS,
  LOAD_STATUS_OPTIONS,
} from 'v3/utils/constants';

export default function LoadFilter({
  setFinalFilter,
  showAdvancedFilters,
  handleClose,
  setQtdAdvancedFilters,
  resultsTotal,
  isFetching,
}) {
  const history = useHistory();
  const query = useQuery();

  const [loadingTagsFromParam, setLoadingTagsFromParam] = useState(false);
  const [errors, setErrors] = useState({});
  const [inputTag, setInputTag] = useState('');

  const [selectedTags, setSelectedTags] = useState([]);
  const [origin, setOrigin] = useState();
  const [destination, setDestination] = useState();
  const [firstRender, setFirstRender] = useState(true);

  function parseDate(value) {
    if (moment(value, 'DD/MM/YYYY').isValid()) {
      return moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }

    return null;
  }

  const [advancedFilters, setAdvancedFilters] = useState({
    cgccpf: null,
    startDate: LOAD_FILTER_DATE_OPTIONS[0].value,
    customers: [],
    endDate: null,
    status: null,
    loadId: null,
    order: null,
    externalId: null,
    expired: 0,
    vehicleTypesIds: [],
    costCenter: [],
    clientOrderNumber: null,
    pcp: [],
  });

  const [vehicleTypes, setVehicleTypes] = useState([]);

  const hasPermissionToSelectTags = useMemo(
    () => validatePermission('SELECIONAR_TAGS'),
    []
  );

  function syncTags(tags) {
    query.delete('tags');

    if (tags) {
      tags.forEach(tag => {
        query.append('tags', tag.id);
      });
    }

    setSelectedTags(tags);
  }
  async function fetchTagsById() {
    const tagsId = query.getAll('tags');

    const tags = await Promise.all(
      tagsId
        .filter(tagId => !selectedTags?.find(tag => tag.id === tagId))
        .map(async tagId => {
          const responseTag = await api.get(`tags/${tagId}`);
          return responseTag.data;
        })
    );

    return tags;
  }

  async function fetchUserTags() {
    try {
      const response = await api.get('user-tags');

      const tags = response.data.map(tag => ({
        id: tag.tag_id,
        name: tag.name.name,
      }));

      return tags;
    } catch (ex) {
      return [];
    } finally {
      setLoadingTagsFromParam(false);
    }
  }

  async function fetchAllTags() {
    const userTags = await fetchUserTags();
    const tags = await fetchTagsById();

    syncTags(removeDuplicates([...userTags, ...tags]));
  }

  function applyQueryParameters() {
    const queryParams = query.toString();
    setFinalFilter(queryParams);
    history.replace({ search: queryParams });
  }

  async function initializeDefaultQuery() {
    if (!query.has('startDate')) {
      query.set('startDate', advancedFilters.startDate);
    }

    if (!query.has('expired')) {
      query.set('expired', advancedFilters.expired);
    }

    if (query.has('no-tags')) {
      applyQueryParameters();
      return;
    }
    fetchAllTags().then(applyQueryParameters);
  }

  function buildLocationFromQueryParams(key) {
    const province = query.get(`${key}ProvinceName`);
    if (province) {
      const location = {
        name: province,
      };

      const city = query.get(`${key}CityName`);
      if (city) {
        location.province = { uf: location.name };
        location.name = city;
      }

      return location;
    }

    return null;
  }

  function handleAdvancedFilter(key, value, process = v => v) {
    setAdvancedFilters(previous => ({ ...previous, [key]: value }));

    const processedValue = process(value);
    if (processedValue) {
      if (Array.isArray(processedValue)) {
        query.delete(key);
        processedValue.forEach(processed => {
          query.append(key, processed);
        });
      } else {
        query.set(key, processedValue);
      }
    } else {
      query.delete(key);
    }
  }

  async function handleParamCostCenter(costCenters) {
    if (costCenters && costCenters.length > 0) {
      const allCCs = [];
      await Promise.all(
        costCenters.map(async cc => {
          const responseCostCenter = await api.get(`v3/cost-center/${cc}`);
          if (responseCostCenter?.data?.id) {
            allCCs.push(responseCostCenter?.data);
          }
        })
      );
      setAdvancedFilters(old => ({
        ...old,
        costCenter: allCCs,
      }));
    }
  }

  async function handleParamPcp(pcps) {
    if (pcps && pcps.length > 0) {
      const finalPcpData = [];
      await Promise.all(
        pcps.map(async item => {
          const responseUser = await fetchUser(item);
          if (responseUser?.id) {
            finalPcpData.push(responseUser);
          }
        })
      );
      setAdvancedFilters(old => ({
        ...old,
        pcp: finalPcpData,
      }));
    }
  }

  async function handleParamsCustomers(customers) {
    if (customers && customers.length > 0) {
      const finalCustomersData = [];
      await Promise.all(
        customers.map(async item => {
          const responseUser = await fetchCustomer(item);
          if (responseUser?.id) {
            finalCustomersData.push(responseUser);
          }
        })
      );
      setAdvancedFilters(old => ({
        ...old,
        customers: finalCustomersData,
      }));
    }
  }

  async function getQueryParams() {
    setOrigin(buildLocationFromQueryParams('origin'));
    setDestination(buildLocationFromQueryParams('destination'));

    if (query.has('customers[]')) {
      handleParamsCustomers(query.getAll('customers[]'));
    } else {
      query.delete('customers[]');
    }

    if (query.has('startDate')) {
      handleAdvancedFilter(
        'startDate',
        moment(query.get('startDate')).format('DD/MM/YYYY'),
        parseDate
      );
    }

    if (query.has('endDate')) {
      handleAdvancedFilter(
        'endDate',
        moment(query.get('endDate')).format('DD/MM/YYYY'),
        parseDate
      );
    }

    if (query.has('status')) {
      handleAdvancedFilter(
        'status',
        { label: query.get('status') },
        value => value.label
      );
    }

    if (query.has('loadId')) {
      handleAdvancedFilter('loadId', query.get('loadId'));
    }

    if (query.has('order')) {
      const order = LOAD_FILTER_SORT_OPTIONS.find(
        item => item.value === query.get('order')
      );
      handleAdvancedFilter('order', order, value => value.value);
    }

    if (query.has('externalId')) {
      handleAdvancedFilter('externalId', query.get('externalId'));
    }

    if (query.has('expired')) {
      handleAdvancedFilter('expired', query.get('expired'));
    }

    if (query.get('costCenterIds[]')) {
      handleParamCostCenter(query.getAll('costCenterIds[]'));
    } else query.delete('costCenterIds[]');

    if (query.get('pcp[]')) {
      handleParamPcp(query.getAll('pcp[]'));
    } else {
      query.delete('pcp[]');
    }
  }

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
      handleAdvancedFilter(
        'startDate',
        moment().subtract(3, 'days').format('DD/MM/YYYY'),
        parseDate
      );
      initializeDefaultQuery();
    } else if (!hasPermissionToSelectTags) {
      if (!query.has('no-tags')) {
        fetchAllTags().then(applyQueryParameters);
      }
      applyQueryParameters();
    } else if (!query.has('no-tags'))
      fetchTagsById().then(tags => {
        syncTags(tags);
        applyQueryParameters();
      });

    getQueryParams();
  }, []);

  useEffect(() => {
    const isNotEmpty = value =>
      value !== null && value !== undefined && value !== '' && value !== 0;

    const validateAdvancedFilters = {
      cgcCpf: advancedFilters?.cgccpf,
      startDate: advancedFilters?.startDate,
      endDate: advancedFilters?.endDate,
      status: advancedFilters?.status?.label,
      vehicleTypeIds: advancedFilters?.vehicleTypesIds?.length,
      order: advancedFilters?.order,
      loadId: advancedFilters?.loadId,
      externalId: advancedFilters?.externalId,
      costCenter: advancedFilters?.costCenter?.length,
      customers: advancedFilters?.customers?.length,
      pcp: advancedFilters?.pcp?.length,
      clientOrderNumber: advancedFilters?.clientOrderNumber,
      expired: advancedFilters?.expired in [0, 1, '0', '1'] ? 'check' : '',
    };

    setQtdAdvancedFilters(
      Object.values(validateAdvancedFilters).filter(isNotEmpty).length
    );
  }, [advancedFilters]);

  useEffect(() => {
    if (showAdvancedFilters) fetchVehicles().then(setVehicleTypes);
  }, [showAdvancedFilters]);

  async function fetchTags(search) {
    try {
      const response = await api.get('tags', { params: { search } });
      return response.data.data;
    } catch (ex) {
      return [];
    }
  }

  function tagTreatment(input) {
    if (input) {
      const inputFiltered = input.trimStart().replace(/[^a-zA-Z0-9 ]/g, '');
      setInputTag(inputFiltered);
    } else {
      setInputTag('');
    }
  }

  function setError(field, error) {
    setErrors(prev => ({ ...prev, [field]: error }));
  }
  useEffect(() => {
    if (advancedFilters.startDate && advancedFilters.endDate) {
      const startDate = moment(advancedFilters.startDate, 'DD/MM/YYYY', true);
      const endDate = moment(advancedFilters.endDate, 'DD/MM/YYYY', true);

      if (startDate.isAfter(endDate)) {
        setError(
          'startDate',
          'A data de ínicio precisa ser menor que a data final.'
        );
        setError(
          'endDate',
          'A data de fim deve ser maior que a data de início.'
        );
      }
    }
  }, [advancedFilters.startDate, advancedFilters.endDate]);

  return (
    <>
      <Modal
        size="lg"
        show={showAdvancedFilters}
        backdrop="static"
        heading="Filtro Avançado"
        animation
        onHide={handleClose}
        style={{ height: '100%' }}
        body={
          <Row className="p-1 form">
            <Col xs={12}>
              <Select.Async
                label="Cliente Tomador CPF/CNPJ"
                multiple
                onSearch={search => fetchCustomers({ search })}
                value={advancedFilters.customers}
                onChange={value => {
                  if (Array.isArray(value)) {
                    query.delete('customers[]');
                    value
                      .map(i => i.id)
                      .forEach(processed => {
                        query.append('customers[]', processed);
                      });
                  } else {
                    query.delete('customers[]');
                  }
                  setAdvancedFilters(old => ({
                    ...old,
                    customers: value,
                  }));
                }}
                getOptionLabel={option => {
                  let label = `${option?.social_name} - ${option?.cgccpf}`;
                  if (option.city) {
                    label += ` - ${option?.city}`;
                  }
                  return label;
                }}
                getOptionValue={option => option.id}
                id="select-filtro-nome-cliente"
              />
            </Col>
            <Col xs={12}>
              <Select.Async
                label="PCP"
                multiple
                onSearch={search => fetchUsers(search)}
                value={advancedFilters.pcp}
                onChange={value => {
                  if (Array.isArray(value)) {
                    query.delete('pcp[]');
                    value
                      .map(i => i.id)
                      .forEach(processed => {
                        query.append('pcp[]', processed);
                      });
                  } else {
                    query.delete('pcp[]');
                  }
                  setAdvancedFilters(old => ({
                    ...old,
                    pcp: value,
                  }));
                }}
                getOptionLabel={option => option.username}
                getOptionValue={option => option.id}
                id="select-filtro-pcp"
              />
            </Col>
            <Col md={4} xs={12}>
              <DatePicker
                value={advancedFilters.startDate}
                onChange={e => {
                  handleAdvancedFilter('startDate', e.target.value, parseDate);
                  setError('startDate', null);
                  setError('endDate', null);
                }}
                label="Período de"
                error={errors.startDate}
                id="input-filtro-periodo-inicio"
              />
            </Col>
            <Col md={4} xs={12}>
              <DatePicker
                value={advancedFilters.endDate}
                onChange={e => {
                  handleAdvancedFilter('endDate', e.target.value, parseDate);
                  setError('endDate', null);
                  setError('startDate', null);
                }}
                label="Até"
                error={errors.endDate}
                id="input-filtro-periodo-fim"
              />
            </Col>
            <Col md={4} xs={12}>
              <Select
                label="Status"
                value={advancedFilters.status}
                options={LOAD_STATUS_OPTIONS}
                getOptionValue={option => option.label}
                onChange={value =>
                  handleAdvancedFilter('status', value, v => v?.label)
                }
                id="select-filtro-status"
              />
            </Col>
            <Col md={6} xs={12}>
              <Select
                label="Tipo de veículo"
                value={advancedFilters.vehicleTypesIds}
                multiple
                options={vehicleTypes}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                onChange={value => {
                  if (Array.isArray(value)) {
                    query.delete('vehicleTypesIds[]');
                    value
                      .map(i => i.id)
                      .forEach(processed => {
                        query.append('vehicleTypesIds[]', processed);
                      });
                  } else {
                    query.delete('vehicleTypesIds[]');
                  }
                  setAdvancedFilters(old => ({
                    ...old,
                    vehicleTypesIds: value,
                  }));
                }}
                id="select-filtro-tipo-veiculo"
              />
            </Col>
            <Col md={6} xs={12}>
              <Select.Async
                label="Centro de custo"
                multiple
                onSearch={search => fetchCostCenter({ search })}
                value={advancedFilters.costCenter}
                onChange={value => {
                  if (Array.isArray(value)) {
                    query.delete('costCenterIds[]');
                    value
                      .map(i => i.id)
                      .forEach(processed =>
                        query.append('costCenterIds[]', processed)
                      );
                  } else {
                    query.delete('costCenterIds[]');
                  }
                  setAdvancedFilters(old => ({ ...old, costCenter: value }));
                }}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                id="select-filtro-centro-custo"
              />
            </Col>
            <Col md={4} xs={12}>
              <Select
                label="Ordenação"
                value={advancedFilters.order}
                options={LOAD_FILTER_SORT_OPTIONS}
                getOptionLabel={option => option.label}
                getOptionValue={option => option.value}
                onChange={value =>
                  handleAdvancedFilter('order', value, v => v?.value)
                }
                id="select-filtro-ordenacao"
              />
            </Col>
            <Col md={4} xs={12}>
              <Input
                label="Número da carga"
                type="text"
                value={advancedFilters.loadId}
                onChange={e => {
                  if (e.target.value.match('^[0-9]*$')) {
                    handleAdvancedFilter('loadId', e.target.value);
                  }
                }}
                id="input-filtro-numero-carga"
              />
            </Col>
            <Col md={4} xs={12}>
              <Input
                label="ID Externo"
                type="text"
                value={advancedFilters.externalId}
                onChange={e => {
                  handleAdvancedFilter('externalId', e.target.value);
                }}
                id="input-filtro-id-externo"
              />
            </Col>

            <Col md={6} xs={12}>
              <Input
                label="Número Pedido"
                type="text"
                value={advancedFilters.clientOrderNumber}
                onChange={e => {
                  handleAdvancedFilter('clientOrderNumber', e.target.value);
                }}
                id="input-filtro-numero-pedido"
              />
            </Col>
            <Col md={4} xs={12}>
              <Toggle
                buttonSize="sm"
                defaultValue={0}
                label="Cargas vencidas"
                value={advancedFilters.expired}
                options={[
                  {
                    label: 'Exibir',
                    value: 1,
                    id: 'filtro-carga-vencida-exibir',
                  },
                  {
                    label: 'Ocultar',
                    value: 0,
                    id: 'filtro-carga-vencida-ocultar',
                  },
                ]}
                onSelected={value => handleAdvancedFilter('expired', value)}
              />
            </Col>
          </Row>
        }
        footer={
          <Row style={{ justifyContent: 'flex-end' }}>
            <Button
              variant="primary"
              onClick={() => {
                applyQueryParameters();
                handleClose();
              }}
              id="button-filtro-filtrar"
            >
              <Text weight={500} type="regular">
                Filtrar
              </Text>
            </Button>
          </Row>
        }
      />
      <Row className="filter">
        <Col md={2} xs={12}>
          <Toggle
            label="Coleta agendada há"
            labelColor="#fff"
            value={advancedFilters.startDate}
            defaultValue={advancedFilters.startDate}
            options={LOAD_FILTER_DATE_OPTIONS}
            onSelected={value => {
              handleAdvancedFilter('startDate', value, parseDate);
              setError('startDate', null);
              handleAdvancedFilter('endDate', null);
            }}
          />
        </Col>
        <Col lg={3} xs={12}>
          <Select.Async
            multiple
            label="Tags"
            modalHeading="Adicione uma tag"
            modalBodyTitle="Tags:"
            onSearch={fetchTags}
            value={selectedTags}
            horizontal
            onChange={value => syncTags(value)}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            isDisabled={
              hasPermissionToSelectTags
                ? loadingTagsFromParam
                : !hasPermissionToSelectTags
            }
            onInputChange={e => tagTreatment(e)}
            inputValue={inputTag}
            labelColor="#fff"
            id="select-filtro-tags"
          />
        </Col>
        <Col lg={3} xs={12}>
          <Select.Async
            label="Origem"
            onSearch={fetchCities}
            value={origin}
            onChange={value => {
              setOrigin(value);

              if (value) {
                if (value.province) {
                  query.set('originProvinceName', value.province.uf);
                  query.set('originCityName', value.name);
                } else {
                  query.set('originProvinceName', value.uf);
                }
              } else {
                query.delete('originProvinceName');
                query.delete('originCityName');
              }
            }}
            getOptionLabel={option => {
              if (option.province) {
                return `${option.name}, ${option.province.uf}`;
              }

              return `Estado de ${option.name}`;
            }}
            getOptionValue={option => option.id}
            labelColor="#fff"
            id="select-filtro-origem"
          />
        </Col>
        <Col lg={3} xs={12}>
          <Select.Async
            label="Destino"
            onSearch={fetchCities}
            value={destination}
            onChange={value => {
              setDestination(value);

              if (value) {
                if (value.province) {
                  query.set('destinationProvinceName', value.province.uf);
                  query.set('destinationCityName', value.name);
                } else {
                  query.set('destinationProvinceName', value.uf);
                }
              } else {
                query.delete('destinationProvinceName');
                query.delete('destinationCityName');
              }
            }}
            getOptionLabel={option => {
              if (option.province) {
                return `${option.name}, ${option.province.uf}`;
              }

              return `Estado de ${option.name}`;
            }}
            getOptionValue={option => option.id}
            labelColor="#fff"
            id="select-filtro-destino"
          />
        </Col>
        <Col lg={1} xs={12} className="vertical bottom">
          <Button
            id="button-aplicar-filtro"
            variant="success"
            onClick={() => applyQueryParameters()}
          >
            <Text type="regular" weight={500}>
              Filtrar
            </Text>
          </Button>
        </Col>
        <Col>
          {!isFetching && !!resultsTotal && (
            <div className="text-right">
              <Text
                id="label-quantidade-cargas"
                type="regular"
                color="white"
                weight="300"
              >
                {resultsTotal} cargas
              </Text>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
}
