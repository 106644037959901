import moment from 'moment';
import { endOfDay, startOfDay } from 'date-fns';
import api from 'services/api';

export const deliveryTimeStatusOptions = [
  {
    label: 'No prazo',
    id: 1,
    slug: "('EARLY_ON_TARGET', 'ON_SCHEDULE_FOR_COLLECT', 'ON_TARGET_SCHEDULE', 'ON_SCHEDULE', 'ON_SCHEDULE_AND_TARGET')",
  },
  {
    label: 'Fora do prazo',
    id: 2,
    slug: "('LATE_FOR_COLLECT', 'LATE_ON_TARGET', 'LATE_ON_SCHEDULE')",
  },
  {
    label: 'Sem rastreamento',
    id: 3,
    slug: "('CURRENT_POSITION_NOT_AVAILABLE')",
  },
];

export const fleetTypeOptions = [
  { label: 'Própria', id: '1' },
  { label: 'Não integra frota', id: '0' },
];

export const aggregationOptions = [
  { label: 'Terceiro', id: 'Terceiro' },
  { label: 'Agregado', id: 'Agregado' },
];

export async function onSearchNegotiator(search) {
  try {
    const response = await api.get('user/name', {
      params: { negotiator: search, isControlTower: true },
    });
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function onSearchUser(search) {
  try {
    const response = await api.get('user/name', {
      params: { search, isControlTower: true },
    });
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function fetchSelectTags(params) {
  try {
    const { data } = await api.get('tags', { params });
    return data;
  } catch (err) {
    return [];
  }
}

export async function handleRetrieveTags(tags) {
  try {
    if (tags && tags.length > 0) {
      const promises = tags.map(async item => {
        const response = await api.get(`tags/${item}`);
        if (response.status === 200) {
          return response.data;
        }
      });

      const data = await Promise.all(promises);
      return data.filter(Boolean);
    }
  } catch (error) {
    return [];
  }

  return [];
}

export async function getProvinces() {
  const response = await api.get('provinces');
  return response.data.map(province => ({
    ...province,
    label: `${province.uf} (${province.name})`,
    value: province.id,
  }));
}

export async function fetchCities(search) {
  try {
    const response = await api.get('cities', {
      params: { search },
    });
    return response.data;
  } catch (ex) {
    return [];
  }
}

export async function fetchCountries(setCountries) {
  try {
    const { data } = await api.get('countries');
    setCountries(data);
  } catch (err) {
    setCountries([]);
  }
}

export async function fetchDriver(search) {
  try {
    const response = await api.get('drivers/select', { params: { search } });
    return response.data;
  } catch (ex) {
    return [];
  }
}

export async function fetchShippingCompanies(search) {
  try {
    const response = await api.get('persons/shipping-companies', {
      params: { search },
    });
    return response.data;
  } catch (error) {
    return [];
  }
}

export function setQueryParams(filters, query) {
  if (filters?.firstPeriod?.length) {
    query.set(
      'firstPeriod',
      startOfDay(
        new Date(moment(filters?.firstPeriod, 'DD/MM/YYYY'))
      ).toISOString()
    );
  }
  if (filters?.secondPeriod?.length) {
    query.set(
      'secondPeriod',
      endOfDay(
        new Date(moment(filters?.secondPeriod, 'DD/MM/YYYY'))
      ).toISOString()
    );
  }
  if (filters?.pcps?.length) {
    query.set('pcps', filters?.pcps.map(pcp => pcp.id).join(','));
  }
  if (filters?.negotiators?.length) {
    query.set(
      'negotiators',
      filters?.negotiators.map(negotiator => negotiator.id).join(',')
    );
  }
  if (filters?.costCenter?.length) {
    query.set('costCenter', filters?.costCenter.map(cc => cc.id).join(','));
  }
  if (filters?.tags?.length) {
    query.set('tags', filters?.tags.map(tag => tag.id).join(';'));
  }
  if (filters?.originCountrySlug?.length) {
    query.set(
      'originCountrySlug',
      filters?.originCountrySlug.map(c => c.country_slug).join(',')
    );
  }
  if (filters?.destinationsCountrySlug?.length) {
    query.set(
      'destinationsCountrySlug',
      filters?.destinationsCountrySlug.map(c => c.country_slug).join(',')
    );
  }
  if (filters?.client?.length) {
    query.set('client', filters?.client?.map(c => c.id).join(','));
  }
  if (filters?.initialProvince?.length) {
    query.set(
      'initialProvince',
      `('${filters?.initialProvince.map(i => i.uf).join(`','`)}')`
    );
  }
  if (filters?.lastProvince?.length) {
    query.set(
      'lastProvince',
      `('${filters?.lastProvince.map(i => i.uf).join(`','`)}')`
    );
  }
  if (filters?.loadId?.length) {
    query.set('loadId', filters?.loadId);
  }
  if (filters?.cooperplaceTravelId?.length) {
    query.set('cooperplaceTravelId', filters?.cooperplaceTravelId);
  }
  if (filters?.originCity?.length) {
    query.set(
      'originCityName',
      `('${filters?.originCity?.map(i => i.name).join(`','`)}')`
    );
    query.set('originCityId', filters?.originCity?.map(c => c.id).join(','));
  }
  if (filters?.destinationCity?.length) {
    query.set(
      'destinationCityName',
      `('${filters?.destinationCity?.map(i => i.name).join(`','`)}')`
    );
    query.set(
      'destinationCityId',
      filters?.destinationCity?.map(c => c.id).join(',')
    );
  }
  if (filters?.travelStatus?.id) {
    query.set('travelStatus', filters?.travelStatus?.id);
  }

  if (filters?.vehicleType?.length) {
    query.set('vehicleType', filters?.vehicleType?.map(c => c.id).join(','));
  }

  if (filters?.vehicleSegment?.length) {
    query.set(
      'vehicleSegment',
      filters?.vehicleSegment?.map(c => c.id).join(',')
    );
  }

  if (filters?.driver?.length) {
    query.set('driverId', filters?.driver?.map(c => c.id).join(','));
    query.set(
      'driverName',
      `('${filters?.driver?.map(i => i.social_name).join(`','`)}')`
    );
  }
  if (filters?.plate?.length) {
    query.set(
      'plate',
      filters?.plate?.map(pl => pl.plate).join(';')
    );
  }
  if (filters?.wagonPlate?.length) {
    query.set(
      'wagonPlate',
      filters?.wagonPlate?.map(wp => wp.plate).join(';')
    );
  }
  if (filters?.originClient?.length) {
    query.set(
      'originClient',
      filters?.originClient?.map(oc => oc.id).join(',')
    );
  }
  if (filters?.destinationClient?.length) {
    query.set(
      'destinationClient',
      filters?.destinationClient?.map(oc => oc.id).join(',')
    );
  }
  if (filters?.shippingCompany?.length) {
    query.set(
      'shippingCompany',
      filters?.shippingCompany?.map(c => c.id).join(',')
    );
    query.set('shippingCompanyName', filters?.shippingCompany?.label);
  }
  if (filters?.bennerTravelId?.length) {
    query.set('bennerTravelId', filters?.bennerTravelId);
  }
  if (filters?.cteCrtNumber?.length) {
    query.set('cteCrtNumber', filters?.cteCrtNumber);
  }
  if (filters?.nfeNumber?.length) {
    query.set('nfeNumber', filters?.nfeNumber);
  }
  if (filters?.fleetType?.id) {
    query.set('fleetType', filters?.fleetType?.id);
  }
  if (filters?.isTracked === 1 || filters?.isTracked === 0) {
    query.set('isTracked', filters?.isTracked);
  }
  if (filters?.deliveryTimeStatus?.slug) {
    query.set('deliveryTimeStatus', filters?.deliveryTimeStatus?.slug);
  }
  if (filters?.firstArrivalPeriod?.length) {
    query.set(
      'firstArrivalPeriod',
      startOfDay(
        new Date(moment(filters?.firstArrivalPeriod, 'DD/MM/YYYY'))
      ).toISOString()
    );
  }
  if (filters?.secondArrivalPeriod?.length) {
    query.set(
      'secondArrivalPeriod',
      endOfDay(
        new Date(moment(filters?.secondArrivalPeriod, 'DD/MM/YYYY'))
      ).toISOString()
    );
  }
  if (filters?.fleetGroup?.length) {
    query.set('fleetGroup', filters?.fleetGroup.map(item => item.id).join(','));
  }
  if (filters?.aggregation?.slug) {
    query.set('aggregation', filters?.aggregation?.slug);
  }

  if (filters?.clientOrderNumber) {
    query.set('clientOrderNumber', filters?.clientOrderNumber);
  }
  if (filters?.companies?.length) {
    query.set('companies', filters?.companies?.map(oc => oc.id).join(','));
  }

  return query;
}
