import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'v3/components/Snackbar';
import Text from 'v3/components/Text';
import api from 'services/api';
import { usePermission } from 'hooks';
import Details from '../Details';
import Register from '../Register';
import { CompanyProvider } from './context';

export default function CompanyShared() {
  const snackbar = useSnackbar();
  const dispatch = useDispatch();

  const [company, setCompany] = useState({
    integrations: [],
    clients: [],
    riskManagers: [],
  });
  const [isFetching, setFetching] = useState(false);
  const [errors, setErrors] = useState({});
  const [prevCompany, setPrevCompany] = useState({});
  const hasPermissionToEdit = usePermission('ALTERAR_EMPRESAS');
  const params = useParams();
  function showSnackbar(text, options) {
    snackbar.show(<Text> {text} </Text>, options);
  }
  useEffect(() => {
    async function fetchCompany() {
      try {
        setFetching(true);

        const response = await api.get(`v3/company/${params.id}`);
        setCompany(previous => ({ ...previous, ...response.data }));
        setPrevCompany(response.data);
      } catch (ex) {
        snackbar.show(<Text>Não foi possível carregar essa empresa</Text>, {
          type: 'error',
        });
      } finally {
        setFetching(false);
      }
    }

    if (params.id) {
      fetchCompany();
    }
  }, [params.id]);

  function setData(data) {
    setCompany(prev => ({ ...prev, ...data }));
  }
  function cancelEditing(setEditing) {
    setCompany(prevCompany);
    if (setEditing) setEditing(false);
  }

  useEffect(() => {
    if (params.id) {
      dispatch({
        type: 'VIEW_INSTANCE',
        instance: company.name,
      });
    }
  }, [company]);

  return (
    <CompanyProvider
      value={{
        data: company,
        setData,
        isFetching,
        errors,
        setErrors,
        showSnackbar,
        cancelEditing,
        setPrevCompany,
        hasPermissionToEdit
      }}
    >
      {params.id ? <Details /> : <Register />}
    </CompanyProvider>
  );
}
