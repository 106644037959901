import React, { useState, useEffect } from 'react';
import useWindowResize from 'hooks/useWindowResize';
import ApplicationLayout from 'v3/layouts/Application';
import api from 'services/api';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import Text from 'v3/components/Text';
import Modal from 'v3/components/Modal';
import Button from 'v3/components/Button';
import Input from 'v3/components/Input';
import { useSnackbar } from 'v3/components/Snackbar';
import { SaveContainer } from 'v3/layouts/Application/styles';
import { usePermission } from 'hooks';
import Data from './Data';
import Address from './Address';
import Series from './Series';
import Icms from './Icms';
import Mdfe from './Mdfe';
import { IssuerContext, saveIssuer, fetchTaxSituations } from './controller';

const [AddIssuerProvider] = IssuerContext;

export default function IssuerRegister() {
  const history = useHistory();
  usePermission('CADASTRAR_EMISSORES', { redirect: true });

  const snackbar = useSnackbar();
  function showSnackbar(text, options) {
    snackbar.show(<Text> {text} </Text>, options);
  }

  const [saveContainerOffset, setSaveContainerOffset] = useState(0);

  // Context resources
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const value = {
    data,
    setData,
    errors,
    setErrors,
  };

  const [taxSituations, setTaxSituations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [addProductModal, setAddProductModal] = useState(false);
  const [registeredIssuer, setRegisteredIssuer] = useState('');
  const [product, setProduct] = useState();
  const [ncm, setNcm] = useState();

  useWindowResize(() => {
    const header = document.getElementById('header-container');
    const originDistanceToRight =
      window.pageXOffset + header.getBoundingClientRect().right;
    const headerWidth = header.getBoundingClientRect().width;

    const SAVE_CONTAINER_PADDING = 0;
    const distanceToRight =
      originDistanceToRight - headerWidth + SAVE_CONTAINER_PADDING;
    setSaveContainerOffset(distanceToRight);
  }, []);

  async function getTaxSituations() {
    const response = await fetchTaxSituations();
    setTaxSituations(response);
  }

  async function handleAddProduct() {
    try {
      const response = await api.post(`product-types/`, {
        name: product,
        ncm,
      });
      snackbar.show(<Text>Produto cadastrado com sucesso</Text>, {
        type: 'success',
        duration: 1000,
      });
      return response;
    } catch (err) {
      snackbar.show(<Text>Erro ao cadastrar produto</Text>, {
        type: 'error',
        duration: 1000,
      });
      return err;
    }
  }

  useEffect(() => {
    getTaxSituations();
  }, []);

  return (
    <>
      <Modal
        size="md"
        show={addProductModal}
        onHide={() => setAddProductModal(false)}
        heading="Cadastro de produto"
        body={
          <>
            <Row>
              <Col>
                <Input
                  label="Nome do Produto *"
                  value={product}
                  onChange={event => setProduct(event.target.value)}
                />
              </Col>
              <Col>
                <Input
                  type="number"
                  label="NCM *"
                  value={ncm}
                  onChange={event => setNcm(event.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: '15px',
                }}
              >
                <Button
                  onClick={() => {
                    handleAddProduct();
                    setAddProductModal(false);
                  }}
                >
                  Cadastrar
                </Button>
              </div>
            </Row>
          </>
        }
      />
      <Modal
        size="md"
        show={showModal}
        onHide={() => setShowModal(false)}
        heading="Cadastro de emissores"
        body={`Emissor ${registeredIssuer.name} cadastrado com sucesso!`}
        footer={
          <>
            <Button
              variant="white"
              outlined
              onClick={() => history.push('/emissores')}
            >
              <Text>Ver listagem</Text>
            </Button>

            <Button
              variant="success"
              onClick={() => {
                window.location.reload(false);
              }}
            >
              <Text>Novo</Text>
            </Button>
            <Button
              variant="primary"
              onClick={() => history.push(`/emissores/${registeredIssuer.id}`)}
            >
              <Text>Visualizar</Text>
            </Button>
          </>
        }
      />
      <ApplicationLayout
        title="Cadastro de emissor"
        RightComponent={
          <SaveContainer style={{ right: saveContainerOffset }}>
            <Button
              variant="secondary"
              onClick={() => history.push('/emissores')}
            >
              <Text weight={500} color="black" type="regular">
                Cancelar
              </Text>
            </Button>

            <Button
              variant="success"
              onClick={() =>
                saveIssuer(
                  data,
                  setErrors,
                  showSnackbar,
                  setLoading,
                  setShowModal,
                  setRegisteredIssuer
                )
              }
              loading={loading}
            >
              <Text weight={500} color="white" type="regular">
                Salvar
              </Text>
            </Button>
          </SaveContainer>
        }
      >
        <AddIssuerProvider value={value}>
          <Row>
            <Col xs={6}>
              <Row>
                <Col>
                  <Data />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Series />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Mdfe />
                </Col>
              </Row>
            </Col>
            <Col xs={6}>
              <Row>
                <Col>
                  <Address />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Icms
                    taxSituations={taxSituations}
                    setAddProductModal={setAddProductModal}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </AddIssuerProvider>
      </ApplicationLayout>
    </>
  );
}
