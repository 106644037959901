/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import ModalRaw from 'v3/components/Modal';
import Text from 'v3/components/Text';
import { PrePreparedCard } from '../styles';
import { CityMap } from '../../Cards/Card';

function Modal({
  isOpen,
  onClose,
  data,
  mapKey = 'destination',
  cityKey = 'destination_city',
}) {
  const [focused, setFocused] = useState('');
  const [selected, setSelected] = useState([]);

  return (
    <>
      <ModalRaw
        scrollablefocused
        show={isOpen}
        onHide={onClose}
        size="xl"
        heading={
          <Row>
            <Col xs={12}>
              <Text type="header" color="dark">
                {mapKey === 'destination' ? 'Origens' : 'Destinos'} mais
                frequentes
              </Text>
            </Col>
            <Col xs={12}>
              <Text type="body" color="gray">
                {mapKey === 'destination'
                  ? 'para os destinos com maior demanda'
                  : 'das origens com maior demanda'}
              </Text>
            </Col>
          </Row>
        }
        body={
          <Row>
            <Col md={8} xs={12}>
              <Row>
                {data?.map(city => (
                  <Col xs={3} className="mb-3">
                    <PrePreparedCard
                      focused={focused === city[cityKey]}
                      onClick={() => {
                        setFocused(city[cityKey]);
                        setSelected(
                          mapKey === 'destination'
                            ? city?.origins
                            : city?.destinations
                        );
                      }}
                    >
                      <Row className="p-2">
                        <Col className="mb-3">
                          <Text weight="600" color="#494747">
                            {city[cityKey]}
                          </Text>
                        </Col>
                        {mapKey === 'destination'
                          ? city?.origins.map(value => (
                              <Col xs={12} className="mb-2">
                                <Text
                                  color="gray"
                                  type="label"
                                  style={{ width: '100%' }}
                                >
                                  {value.percent.toFixed()}%{' '}
                                </Text>
                                <Text color="medium_gray" type="label">
                                  {value.origin_city} ({value.origin_value})
                                </Text>
                              </Col>
                            ))
                          : city?.destinations.map(value => (
                              <Col xs={12} className="mb-2">
                                <Text
                                  color="gray"
                                  type="label"
                                  style={{ width: '100%' }}
                                >
                                  {value.percent.toFixed()}%{' '}
                                </Text>
                                <Text color="medium_gray" type="label">
                                  {value.destination_city} (
                                  {value.destination_value})
                                </Text>
                              </Col>
                            ))}
                      </Row>
                    </PrePreparedCard>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col
              md={4}
              xs={12}
              style={{
                height: 550,
              }}
            >
              <CityMap
                data={selected}
                latKey={
                  mapKey === 'destination'
                    ? 'origin_latitude'
                    : 'destination_latitude'
                }
                lonKey={
                  mapKey === 'destination'
                    ? 'origin_longitude'
                    : 'destination_longitude'
                }
                zoom={3}
                height={550}
                width="100%"
                position={{ lat: -15.7801, lng: -47.9292 }}
              />
            </Col>
          </Row>
        }
      />
    </>
  );
}

export default Modal;
