import React, { useState, useEffect, useMemo, useContext } from 'react';
import api, { cooperplaceApi } from 'services/api';
import { Link } from 'react-router-dom';
import { formatDate } from 'utils/formatter';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { Row, Col } from 'react-bootstrap';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import Card, { DefaultLoadingBodyComponent } from 'v3/components/Card';
import Select from 'v3/components/Select';
import Modal from 'v3/components/Modal';
import { useSnackbar } from 'v3/components/Snackbar';
import { useTravel } from 'v3/pages/Travel';
import { BlockedTravelContext } from 'contexts/BlockedTravelProvider';
import { canEditTravel } from 'v3/utils/permissions';
import { APPROVED_GRO_ANALYSIS_SLUG } from 'v3/utils/constants';
import { useModal } from 'hooks';
import { WhatsAppIcon } from './styles';
import ModalVehicle from './Vehicle';
import DriverAttachments from '../DriverAttachments';
import ConflictDriverModal from '../Modal/ConflictDriver';

function Driver() {
  const { isTravelBlocked } = useContext(BlockedTravelContext);
  const snackbar = useSnackbar();
  const travel = useTravel();

  const [driver, setDriver] = useState();
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [modalVehicle, setModalVehicle] = useState(false);
  const [vehicles, setVehicles] = useState();
  const [driverAttachments, setDriverAttachments] = useState([]);
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState('');
  const [hasPermissionToEdit, setPermissionToEdit] = useState();
  const [validateDriverProps, setValidateDriverProps] = useState({
    conflictTravels: [],
    driver: {},
  });
  const conflictDriverModal = useModal();

  function checkDriverPaymentMethod() {
    if (travel.data?.driverData) {
      setPaymentMethod(travel.data.driverData?.payment?.name);
    } else if (travel.data?.shipperProps) {
      setPaymentMethod(travel.data?.shipperProps?.payment?.name);
    }
  }

  useEffect(() => {
    setPermissionToEdit(canEditTravel(travel.data));
    checkDriverPaymentMethod();
  }, [travel.data]);

  useEffect(() => {
    async function fetchAttachments(driver_id) {
      const attachmentsDriver = await api.get(
        `driver/${driver_id}/attachments`
      );

      setDriverAttachments(attachmentsDriver);
    }

    if (travel.data.driverId) fetchAttachments(travel.data.driverId);
  }, [travel.data.driverId]);

  async function fetchDriver(search) {
    try {
      const response = await api.get('drivers/select', { params: { search } });
      return response.data;
    } catch (error) {
      return [];
    }
  }

  async function fetchVehicle(driver_id) {
    try {
      const vehicle = await api.get(`drivers/vehicle/?driverId=${driver_id}`);
      setVehicles(vehicle.data.vehicle);
    } catch (error) {
      // Handle exeception
    }
  }

  async function handleSubmit({ passDriverValidation = false } = {}) {
    if (!driver) {
      setErrors('Insira um motorista');
      return;
    }

    setLoading(true);
    try {
      const destination =
        travel?.data?.loads?.loadDestinations[
          travel?.data?.loads?.loadDestinations.length - 1
        ];
      const payload = {
        driverId: driver.id,
        destinationScheduledTime: destination?.scheduledTime,
        originScheduledTime: travel?.data?.loads?.loadOrigins[0].startSchedule,
        travelId: travel?.data?.id,
        passValidation: passDriverValidation,
      };

      setValidateDriverProps({
        conflictTravels: [],
        driver: {},
      });

      const {
        data: { conflictTravels, success },
      } = await cooperplaceApi.put('travels/updateDriver', payload);

      if (success && conflictTravels.length === 0) {
        snackbar.show(
          <Text className="text-center">Motorista alterado!</Text>,
          {
            type: 'success',
            duration: 5000,
          }
        );
        travel.fetchTravel();
        setEditing(false);
        fetchVehicle(driver.id);
        setModalVehicle(true);
        setErrors('');
        return;
      }

      if (success && conflictTravels.length === 1) {
        setValidateDriverProps(old => ({ ...old, conflictTravels, driver }));
        conflictDriverModal.open();
        return;
      }

      if (success && conflictTravels.length >= 2) {
        const errorMessage = `O motorista está vinculado nas seguintes viagens nesse período: "${conflictTravels.join(
          ', '
        )}"`;
        setErrors(errorMessage);
        snackbar.show(<Text className="text-center">{errorMessage}</Text>, {
          type: 'error',
          duration: 5000,
        });
      }
    } catch (err) {
      snackbar.show(
        <Text className="text-center">Falha ao atualizar motorista!</Text>,
        {
          type: 'error',
          duration: 5000,
        }
      );
    } finally {
      setLoading(false);
    }
  }

  const driverPhone = useMemo(() => {
    const assertedPhone =
      travel.data.driver?.phoneCooperplace ||
      travel.data.driver?.user?.phonenumber;

    let whatsappPhone = assertedPhone?.replace(/\D/g, '');

    const PHONE_LENGTH_WITHOUT_DDI = 13;
    if (whatsappPhone?.length <= PHONE_LENGTH_WITHOUT_DDI) {
      whatsappPhone = '55'.concat(whatsappPhone);
    }

    return {
      raw: assertedPhone,
      whatsapp: whatsappPhone,
    };
  }, [travel.data.driver]);

  useEffect(() => {
    setDriver(travel?.data?.driver);
  }, [travel?.data?.driver?.id]);

  if (travel.isLoading) {
    return null;
  }

  return (
    <>
      <Modal
        size="md"
        heading="Veículo"
        centered
        scrollable
        show={modalVehicle}
        onHide={() => setModalVehicle(false)}
        body={
          <ModalVehicle
            vehicles={vehicles}
            onHide={() => setModalVehicle(false)}
          />
        }
      />
      {validateDriverProps?.conflictTravels.length > 0 && (
        <ConflictDriverModal
          isOpen={conflictDriverModal.isOpen}
          onClose={conflictDriverModal.close}
          onConfirm={handleSubmit}
          travels={validateDriverProps?.conflictTravels}
          travelData={{ driver: validateDriverProps.driver }}
        />
      )}
      <Card
        loading={travel.isLoading}
        LoadingBodyComponent={() => (
          <DefaultLoadingBodyComponent linesCount={3} perLine={2} />
        )}
        header={
          <Text color="dark" type="header">
            Motorista
          </Text>
        }
        HeaderRightComponent={
          hasPermissionToEdit && (
            <Row className="form">
              {editing && (
                <Button
                  variant="secondary"
                  loading={loading}
                  className="mr-2 py-2"
                  onClick={() => setEditing(false)}
                >
                  <Text color="dark" type="regular" weight="500">
                    Cancelar
                  </Text>
                </Button>
              )}
              <Button
                disabled={
                  isTravelBlocked ||
                  travel?.isDisabledGROAnalysis ||
                  travel?.groAnalysisStatus === APPROVED_GRO_ANALYSIS_SLUG
                }
                onClick={
                  editing ? () => handleSubmit() : () => setEditing(true)
                }
                variant={editing ? 'primary' : 'secondary'}
                loading={loading}
                className="py-2"
                title={
                  travel?.isDisabledGROAnalysis
                    ? 'Não é possível edição durante período de análise GRO'
                    : travel?.groAnalysisStatus === APPROVED_GRO_ANALYSIS_SLUG
                    ? 'Não é possível edição após aprovação do GRO'
                    : ''
                }
              >
                <Text
                  color={editing ? 'white' : 'dark'}
                  type="regular"
                  weight="500"
                >
                  {editing ? 'Salvar' : 'Editar'}
                </Text>
              </Button>
            </Row>
          )
        }
      >
        {editing ? (
          <Row className="form">
            <Col xs={12}>
              <Select.Async
                onSearch={fetchDriver}
                value={driver}
                label="Motorista *"
                onChange={async value => {
                  travel.setData(prev => ({
                    ...prev,
                    oldDriver: !!travel.data?.driver,
                  }));
                  setDriver(value);
                }}
                getOptionLabel={option =>
                  option?.socialName ?? option?.social_name
                }
                getOptionValue={option => option?.id}
                error={errors}
              />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col xs={12}>
              <Text type="label" color="dark">
                Motorista
              </Text>

              <Link
                to={
                  travel.data.driver?.socialName &&
                  `/motoristas/${travel.data?.driver?.id}`
                }
              >
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {travel.data.driver?.socialName || 'Não informado'}

                  {travel.data.driver?.socialName && (
                    <FaExternalLinkAlt
                      color="#B5B5C3"
                      className="mb-1 ml-2"
                      size={12}
                    />
                  )}
                </Text>
              </Link>
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={12} md={6}>
            <Text type="label" color="dark">
              {travel.data.driver?.isInternational ? 'Documento' : 'CPF'}
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {travel.data.driver?.cpfCnpj || 'Não informado'}
            </Text>
          </Col>

          <Col xs={12} md={6}>
            <Text type="label" color="dark">
              Telefone
            </Text>
            <a
              href={`https://api.whatsapp.com/send?l=pt-BR&phone=${driverPhone.whatsapp}`}
              target="_blank"
              rel="noreferrer"
            >
              <div>
                <WhatsAppIcon size={18} />
                <Text type="regular" color="gray">
                  {driverPhone.raw || 'Não informado'}
                </Text>
              </div>
            </a>
          </Col>

          <Col xs={12} md={6}>
            <Text type="label" color="dark">
              CNH
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {travel.data.driver?.cnhNumber || 'Não informado'}
            </Text>
          </Col>

          <Col xs={12} md={6}>
            <Text type="label" color="dark">
              Data de expiração
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {formatDate(travel.data.driver?.cnhExpDate) || 'Não informado'}
            </Text>
          </Col>
          <Col xs={12} md={6}>
            <Text type="label" color="dark">
              Validade cadastro
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {travel.data?.personVerifications
                ? formatDate(travel.data?.personVerifications?.dueDate)
                : 'Não informado'}
            </Text>
          </Col>
          <Col xs={12} md={6}>
            <Text type="label" color="dark">
              Nível de serviço
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {travel.data?.driverData?.descriptionServiceLevel ||
                'Não informado'}
            </Text>
          </Col>
          {travel.data.personVerifications?.dueDate && (
            <Col xs={12} md={6}>
              <Text type="label" color="dark">
                Validado por
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {travel.data?.personVerifications?.verifiedByUser?.username ||
                  'Não informado'}
              </Text>
            </Col>
          )}
          <Col xs={12} md={6}>
            <Text type="label" color="dark">
              Tags
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {travel.data.driverData?.tags?.map(tag => tag.name)?.join(', ') ||
                'Não informado'}
            </Text>
          </Col>
          <Col xs={12} md={12}>
            <Text type="label" color="dark">
              Método de pagamento
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {paymentMethod || 'Não informado'}
            </Text>
          </Col>
          <Col md={12}>
            <DriverAttachments
              driverAttachments={driverAttachments || { data: {} }}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default Driver;
