import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Card, Text } from 'v3/components';
import theme from 'v3/theme';

import { useClientTravelDetail } from './context';

export function TravelInfoCard() {
  const { data, isFetching } = useClientTravelDetail();
  return (
    <Card
      header={
        <Text type="header" color={theme.colors.dark}>
          Informações da viagem
        </Text>
      }
      loading={isFetching}
    >
      <Row>
        <Col md={6} xs={12}>
          <Text type="label" color="#464E5F">
            Empresa
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {data?.company?.name?.toUpperCase() || 'Não informado'}
          </Text>
        </Col>
        <Col md={6} xs={12}>
          <Text type="label" color="#464E5F">
            Placa Principal
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {data?.vehicle?.[0]?.vehicles?.plate || 'Não informado'}
          </Text>
        </Col>
        {data?.vehicle?.[0]?.wagons?.length > 0 &&
          data?.vehicle?.[0]?.wagons[0]?.plate && (
            <Col md={6} xs={12}>
              <Text type="label" color="#464E5F">
                Placa Implemento 1
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {data?.vehicle?.[0]?.wagons?.[0]?.plate || 'Não informado'}
              </Text>
            </Col>
          )}
        {data?.vehicle?.[0]?.wagons?.length > 0 &&
          data?.vehicle?.[0]?.wagons[1]?.plate && (
            <Col md={6} xs={12}>
              <Text type="label" color="#464E5F">
                Placa Implemento 2
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {data?.vehicle?.[0]?.wagons?.[1]?.plate || 'Não informado'}
              </Text>
            </Col>
          )}
        {data?.vehicle?.[0]?.wagons?.length > 0 &&
          data?.vehicle?.[0]?.wagons[2]?.plate && (
            <Col md={6} xs={12}>
              <Text type="label" color="#464E5F">
                Placa Implemento 3
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {data?.vehicle?.[0]?.wagons?.[2]?.plate || 'Não informado'}
              </Text>
            </Col>
          )}
        <Col md={6} xs={12}>
          <Text type="label" color="#464E5F">
            Motorista
          </Text>

          <Text type="regular" color="gray" as="p" className="mt-2">
            {data?.driver?.socialName?.toUpperCase()}
          </Text>
        </Col>
        <Col md={6} xs={12}>
          <Text type="label" color="#464E5F">
            CPF
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {data?.driver?.cpfCnpj}
          </Text>
        </Col>
      </Row>
    </Card>
  );
}
