import React, { useState } from 'react';

import { Row, Col } from 'react-bootstrap';

import api, { cooperplaceApi } from 'services/api';

import Text from 'v3/components/Text';
import Select from 'v3/components/Select';

import { useSnackbar } from 'v3/components/Snackbar';
import { useTravel } from 'v3/pages/Travel';

import { Line, StyledButton } from './styles';

function Vehicle({ vehicles, onHide }) {
  const snackbar = useSnackbar();
  const travel = useTravel();

  const [errors, setErrors] = useState();
  const [loading, setLoading] = useState(false);

  const [plates, setPlates] = useState({
    plate1: '',
    plate2: '',
    plate3: '',
    plate4: '',
  });

  async function fetchVehicle(search) {
    try {
      const response = await api.get('vehicle/license_plate', {
        params: { search },
      });
      return response.data;
    } catch (error) {
      return [];
    }
  }

  async function handleSubmit(vehicle) {
    setLoading(true);

    try {
      const data = {
        vehicle: vehicle.vehicle.id,
        wagons: [
          vehicle.vehicle?.wagons[0]?.id,
          vehicle.vehicle?.wagons[1]?.id,
          vehicle.vehicle?.wagons[2]?.id,
        ],
      };

      await cooperplaceApi.put(`travels/${travel.data.id}/vehicle`, data);

      snackbar.show(<Text>Veículo selecionado! </Text>, {
        type: 'success',
      });
    } catch (error) {
      snackbar.show(<Text>Erro ao selecionar veículo</Text>, {
        type: 'error',
      });
    } finally {
      setLoading(false);
      onHide();
      await travel.fetchTravel();

      //
    }
  }

  async function handleSubmitVehicle() {
    if (!plates.plate1) {
      setErrors('Insira uma placa');
      return;
    }

    try {
      const data = {
        ...plates,
        plate1: plates.plate1?.id,
        wagons: [plates?.plate2?.id, plates?.plate3?.id, plates?.plate4?.id],
      };

      await cooperplaceApi.put(`travel/${travel.data.id}/vehicle`, data);

      snackbar.show(<Text className="text-center">Veículo alterado!</Text>, {
        type: 'success',
        duration: 5000,
      });
    } catch (err) {
      snackbar.show(
        <Text className="text-center">Não foi possível alterar o veículo</Text>,
        {
          type: 'error',
          duration: 5000,
        }
      );
    } finally {
      onHide();
      travel.fetchTravel();
    }
  }

  return (
    <>
      {vehicles?.length > 0 ? (
        <>
          <Row md={12} className="px-5 align-items-center">
            <Col md={12}>
              <Text type="regular" color="dark" weight="400">
                Qual desses veículos realizará a viagem?
              </Text>
            </Col>

            {vehicles?.map(vehicle => (
              <>
                <Col md={12} className="mt-2">
                  <Row md={12} className="d-flex justify-content-space-between">
                    <Col md={6} className="mt-2">
                      <Text type="regular" color="dark">
                        {vehicle.vehicle.plate}
                      </Text>
                    </Col>

                    <Col md={6} className="mt-1 d-flex justify-content-end">
                      <StyledButton
                        variant="success"
                        type="regular"
                        loading={loading}
                      >
                        <Text
                          color="white"
                          weight="500"
                          onClick={() => handleSubmit(vehicle)}
                        >
                          Escolher
                        </Text>
                      </StyledButton>
                    </Col>
                  </Row>

                  <Row md={12} className="mb-2">
                    <Col md={12}>
                      <Text type="regular" color="gray">
                        {vehicle.vehicle?.vehicleType?.name}
                      </Text>
                    </Col>
                    <Col md={12}>
                      <Text type="regular" color="gray">
                        {vehicle.vehicle.axisType?.name}
                      </Text>
                    </Col>
                  </Row>

                  {vehicle.vehicle.wagons?.map(wagon => (
                    <Row md={12} className="mb-2">
                      <Col md={12}>
                        <Text type="regular" color="dark">
                          Implementos
                        </Text>
                      </Col>
                      <Col md={12}>
                        <Text type="regular" color="gray">
                          {wagon.plate}
                        </Text>
                      </Col>
                    </Row>
                  ))}
                </Col>
                <Line />
              </>
            ))}
          </Row>
        </>
      ) : (
        <>
          <Row md={12} className="px-5 align-items-center mb-5">
            <Col md={12}>
              <Text type="regular" color="dark" weight="400">
                Esse motorista não possui veículo vinculado, adicione um!
              </Text>
            </Col>

            <Col md={12} className="mt-2">
              <Select.Async
                onSearch={fetchVehicle}
                placeholder=""
                value={plates.plate1}
                label="Placa principal"
                onChange={value => setPlates({ ...plates, plate1: value })}
                getOptionLabel={option => option.plate}
                getOptionValue={option => option.id}
                error={errors}
              />
            </Col>

            <Col
              md={12}
              className="mt-1 mx-auto align-items-center d-flex justify-content-center"
            >
              <StyledButton variant="success" type="regular">
                <Text
                  color="white"
                  weight="500"
                  onClick={() => handleSubmitVehicle()}
                >
                  Escolher
                </Text>
              </StyledButton>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default Vehicle;
