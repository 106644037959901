import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import apiReport from 'services/apis/report';
import useQuery from 'hooks/useQuery';
import ApplicationLayout from 'v3/layouts/Application';
import { useSnackbar } from 'v3/components/Snackbar';
import { InfiniteScroll } from 'v3/components';
import Text from 'v3/components/Text';
import FileSaver from 'file-saver';
import { usePermission } from 'hooks';
import TravelCard from './Card';
import TravelsArrivalFilter from './Filter';

function TravelsArrival() {
  const snackbar = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [travels, setTravels] = useState([]);
  const [pagination, setPagination] = useState({
    lastPage: 1,
    currentPage: 1,
    total: 0,
  });
  const queryParams = useQuery();
  const [lastPage, setLastPage] = useState(1);
  usePermission('VISUALIZAR_RELATORIO_DE_PREVISAO_DE_CHEGADA_DE_VIAGENS', {
    redirect: true,
  });

  async function fetchTravels(page = 1) {
    if (page === 1) {
      setLoading(true);
    }
    try {
      const response = await apiReport.get(
        `travels-arrival?${queryParams}&page=${page}`
      );
      const { data, ...rest } = response.data;
      rest?.lastPage && setLastPage(rest?.lastPage);
      setPagination(rest);
      setTravels(oldTravels => {
        if (page === 1) {
          return data;
        }
        return [...oldTravels, ...data];
      });
    } catch (error) {
      setTravels([]);
      const message =
        error?.response?.data?.error ||
        'Ocorreu um erro. Tente novamente mais tarde';
      snackbar.show(<Text>{`${message}`}</Text>, { type: 'error' });
    } finally {
      setLoading(false);
    }
  }

  async function exportReport() {
    setExportLoading(true);
    try {
      const response = await apiReport.get(`travels-arrival-report`, {
        params: {
          start_date: queryParams.get('start_date'),
          end_date: queryParams.get('end_date'),
          province: queryParams.get('province'),
          cost_center: queryParams.get('cost_center') || null,
          travel_tags: queryParams.get('travel_tags') || null,
          load_tags: queryParams.get('load_tags') || null,
        },
        responseType: 'blob',
      });
      FileSaver.saveAs(response.data, response.headers['x-file-name']);
    } catch (error) {
      const message =
        error?.response?.data?.error || error?.response?.status === 400
          ? 'Informe todas as datas corretamente'
          : 'Ocorreu um erro. Tente novamente mais tarde';
      snackbar.show(<Text>{`${message}`}</Text>, { type: 'error' });
    } finally {
      setExportLoading(false);
    }
  }

  function renderItem(travel) {
    return <TravelCard key={travel?.id} travel={travel} />;
  }

  return (
    <ApplicationLayout title="Previsão de chegada de viagens">
      <Row className="d-flex flex-column justify-content-center space-between">
        <TravelsArrivalFilter
          onChange={fetchTravels}
          exportReport={exportReport}
          exportLoading={exportLoading}
          loading={loading}
        />
        <Col className="mt-4">
          <InfiniteScroll
            data={travels}
            onEndReach={() => fetchTravels(pagination.currentPage + 1)}
            hasMore={pagination.currentPage < lastPage}
            scrollThreshold={0.7}
            loading={loading}
            renderItem={renderItem}
          />
        </Col>
      </Row>
    </ApplicationLayout>
  );
}

export default TravelsArrival;
