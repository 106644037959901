import React from 'react';
import { formatCurrency } from 'v3/utils/formatter';
import { Link } from 'react-router-dom';

import { Row, Col } from 'react-bootstrap';

import Text from 'v3/components/Text';
import Card from 'v3/components/Card';

function AvgCard({ title, time }) {
 
  return (
    <Card>
        <Row>
          <Col xs={12} >
            
              <Text weight={400} color="secundary" type="regular">
                {title}
              </Text>
            
          </Col>
          <Col xs={12} >
            
              <h2 weight={400} color="secundary" type="regular">
                {time}
              </h2>
            
          </Col>
          
        </Row>
      </Card>
    
  );
}

export default AvgCard;
