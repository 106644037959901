import React from 'react';
import { Nav, Tab, Col } from 'react-bootstrap';
import ApplicationLayout from 'v3/layouts/Application';
import { usePermission } from 'hooks';
import Clients from './Clients';
import Shippers from './Shippers';
import Drivers from './Drivers';
import Vehicles from './Vehicles';
import Travels from './Travels';
import Loads from './Loads';
import { Card, TabLink, ActiveTabContent, TabPanel } from './styles';

function Reports() {
  usePermission('VISUALIZAR_RELATORIOS_INTERNOS', { redirect: true });

  return (
    <ApplicationLayout>
      <Col className="d-flex justify-content-center align-items-center mt-4">
        <Card>
          <Tab.Container id="progress-tabs" defaultActiveKey="loads">
            <Nav>
              <TabLink eventKey="loads">Cargas</TabLink>
              <TabLink eventKey="clients">Clientes</TabLink>
              <TabLink eventKey="drivers">Motoristas</TabLink>
              <TabLink eventKey="travels">Viagens</TabLink>
              <TabLink eventKey="vehicles">Veículos</TabLink>
              <TabLink eventKey="shippers">Transportadoras</TabLink>
            </Nav>
            <ActiveTabContent>
              <TabPanel eventKey="loads">
                <Loads />
              </TabPanel>
              <TabPanel eventKey="clients">
                <Clients />
              </TabPanel>
              <TabPanel eventKey="drivers">
                <Drivers />
              </TabPanel>
              <TabPanel eventKey="travels">
                <Travels />
              </TabPanel>
              <TabPanel eventKey="vehicles">
                <Vehicles />
              </TabPanel>
              <TabPanel eventKey="shippers">
                <Shippers />
              </TabPanel>
            </ActiveTabContent>
          </Tab.Container>
        </Card>
      </Col>
    </ApplicationLayout>
  );
}

export default Reports;
