import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { validatePermission } from 'actions';
import ApplicationLayout from 'v3/layouts/Application';
import Space from 'v3/components/Space';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import { usePermission } from 'hooks';
import { RegisterInfo } from 'v3/components/RegisterInfo';
import { useSnackbar } from 'v3/components/Snackbar';
import { Data } from './Data';
import { OwnerData } from './Owner';
import { BennerStatus } from './BennerStatus';
import { Attachments } from './Attachments';
import { useImplement } from '../shared/context';

export function ImplementDetails() {
  const history = useHistory();
  const implement = useImplement();
  const snackbar = useSnackbar();
  const [attachments, setAttachments] = useState([]);
  const hasPermissionToCreate = validatePermission('CADASTRAR_IMPLEMENTO');

  usePermission('VISUALIZAR_IMPLEMENTO', {
    redirect: true,
  });

  useEffect(() => {
    snackbar.show(
      <Text>
        Alterações de dados cadastrais devem ser solicitadas na viagem no card
        &quot;Solicitação de Ajuste de Cadastro&quot;.
      </Text>,
      { type: 'warning' }
    );
  }, []);

  return (
    <ApplicationLayout
      title="Implemento"
      RightComponent={
        <Space x="0.5rem">
          <Button variant="secondary" onClick={() => history.goBack()}>
            <Text color="dark" weight={500} type="regular">
              Voltar
            </Text>
          </Button>
          {hasPermissionToCreate ? (
            <Button
              variant="primary"
              onClick={() => history.push('/implementos/cadastrar')}
            >
              <Text color="white" weight={500} type="regular">
                Novo Implemento
              </Text>
            </Button>
          ) : null}
        </Space>
      }
    >
      <Row>
        <Col xs={12}>
          <RegisterInfo loading={implement?.loading} />
        </Col>
        <Col md={6} xs={12}>
          <Data />
        </Col>
        <Col md={6} xs={12}>
          <OwnerData />
          <BennerStatus />
        </Col>
        <Col md={6} xs={12} />
      </Row>
      <Row>
        <Col md={12}>
          <Attachments
            attachments={attachments}
            setAttachments={setAttachments}
          />
        </Col>
      </Row>
    </ApplicationLayout>
  );
}
