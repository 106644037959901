import React from 'react';

import { StyledCheckbox, StyledFormControlLabel } from './styles';

export default function CheckBox({
  label,
  value,
  slug,
  name,
  labelStyle,
  labelColor = '#464E5F',
  checkedColor,
  labelWeight = 400,
  ...rest
}) {
  return (
    <>
      <StyledFormControlLabel
        style={labelStyle}
        labelColor={labelColor}
        labelWeight={labelWeight}
        control={
          <StyledCheckbox
            $checkedColor={checkedColor}
            color="primary"
            value={`${value}`}
            id={`perm_${slug}`}
            {...rest}
          />
        }
        label={`${label}`}
      />
    </>
  );
}
