import React, { useRef, useState, useMemo } from 'react';
import GoogleMapReact from 'google-map-react';
import useSupercluster from 'use-supercluster';
import { Row, Col } from 'react-bootstrap';
import { MarkerLetter, Text, Button } from 'v3/components';
import theme from 'v3/theme';
import { MapsContainer, StyledClusterMarker } from './styles';
import MapDetailCard from './MapDetailCard';
import CardSubtitleVehicleTower from '../CardSubtitleVehicleTower';

const ClusterMarker = ({ children }) => children;

export default function MapClusterVehicleTower({
  vehiclesData,
  toggleMaps,
  setToggleMaps,
  loading,
  checked,
  setChecked,
}) {
  const [detail, setDetail] = useState([]);
  const mapRef = useRef();
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(0);
  const points = useMemo(() => {
    if (vehiclesData) {
      const pVehicles = vehiclesData
        ?.filter(v => {
          return v?.latitude && v?.longitude;
        })
        ?.map(vehicle => ({
          type: 'Feature',
          properties: {
            cluster: false,
            id: vehicle.vehicleId,
            category: vehicle.vehicleStatus,
          },
          geometry: {
            type: 'Point',
            coordinates: [
              parseFloat(vehicle?.longitude),
              parseFloat(vehicle?.latitude),
            ],
          },
        }));
      return [...pVehicles];
    } else {
      return [];
    }
  }, [vehiclesData]);
  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 75, minZoom: 0, maxZoom: 20, minPoints: 2 },
  });

  function retrieveVehicle(id) {
    const vehicle = vehiclesData.filter(v => v.vehicleId === id);
    setChecked(true);
    setDetail(vehicle[0]);
  }
  function handleChangeColor(category) {
    const colorMap = {
      inTravel: theme.colors.light_blue,
      emptyVehicle: theme.colors.success,
      inTravelNotTracked: theme.colors.yellow,
      emptyVehicleNotTracked: theme.colors.orange,
    };
    return colorMap[category] || '';
  }
  return (
    <MapsContainer>
      <Row className="d-flex align-items-center">
        <Col>
          <Button onClick={() => setToggleMaps(!toggleMaps)}>
            <Text type="regular" weight={500} fontSize="0.75rem">
              {toggleMaps ? 'Ver mapa' : 'Minimizar'}
            </Text>
          </Button>
        </Col>
      </Row>
      <>
        {toggleMaps === false && loading === false ? (
          <>
            <Row>
              <Col>
                <div style={{ height: '50vh', position: 'relative' }}>
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: process.env.REACT_APP_GOOGLE_MAPS_KEY,
                    }}
                    defaultCenter={{ lat: -14.235, lng: -51.9253 }}
                    defaultZoom={0}
                    draggable={true}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map }) => {
                      mapRef.current = map;
                    }}
                    onClick={() => setChecked(false)}
                    onChange={({ zoom, bounds }) => {
                      setZoom(zoom);
                      setBounds([
                        bounds.nw.lng,
                        bounds.se.lat,
                        bounds.se.lng,
                        bounds.nw.lat,
                      ]);
                    }}
                  >
                    {clusters.map(cluster => {
                      const [longitude, latitude] =
                        cluster.geometry.coordinates;
                      const {
                        cluster: isCluster,
                        point_count: pointCount,
                        category,
                      } = cluster.properties;
                      if (isCluster) {
                        return (
                          <ClusterMarker
                            key={`cluster-${cluster.id}`}
                            lat={latitude}
                            lng={longitude}
                          >
                            <StyledClusterMarker
                              style={{
                                width: `${
                                  10 + (pointCount / points?.length) * 20
                                }px`,
                                height: `${
                                  10 + (pointCount / points?.length) * 20
                                }px`,
                              }}
                              onClick={() => {
                                const expansionZoom = Math.min(
                                  supercluster.getClusterExpansionZoom(
                                    cluster.id
                                  ),
                                  20
                                );
                                mapRef.current.setZoom(expansionZoom);
                                mapRef.current.panTo({
                                  lat: latitude,
                                  lng: longitude,
                                });
                              }}
                            >
                              {pointCount}
                            </StyledClusterMarker>
                          </ClusterMarker>
                        );
                      }
                      return (
                        <MarkerLetter
                          key={cluster.properties.id}
                          background={handleChangeColor(category)}
                          lat={latitude}
                          lng={longitude}
                          color="#fff"
                          onClick={() => {
                            retrieveVehicle(cluster.properties.id);
                          }}
                        />
                      );
                    })}
                  </GoogleMapReact>
                  <Col>
                    {checked ? (
                      <>
                        <MapDetailCard
                          load={detail}
                          checked={checked}
                        ></MapDetailCard>
                      </>
                    ) : null}
                  </Col>
                </div>
              </Col>
            </Row>
            <CardSubtitleVehicleTower />
          </>
        ) : (
          <></>
        )}
      </>
    </MapsContainer>
  );
}
