export const measureOptions = [
  {
    name: 'Unidades',
    value: 'U',
  },
  {
    name: 'Metros',
    value: 'M',
  },
  {
    name: 'Litros',
    value: 'L',
  },
  {
    name: 'Quilos',
    value: 'K',
  },
];