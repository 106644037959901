/* eslint-disable import/no-unresolved */
import React, { useContext, useMemo, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Text from 'v3/components/Text';
import Card, { DefaultLoadingBodyComponent } from 'v3/components/Card';
import { useTravel } from 'v3/pages/Travel';
import { formatDateTime } from 'v3/utils/formatter';
import Button from 'v3/components/Button';
import { cooperplaceApi } from 'services/api';
import { useSnackbar } from 'v3/components/Snackbar';
import { usePermission } from 'hooks';
import { BlockedTravelContext } from 'contexts/BlockedTravelProvider';
import * as S from './styles';

function PreSMContent({ data }) {
  const trackStatus = useMemo(() => {
    return data?.lastLog?.logStatus || 'Viagem não possui SM ativa';
  }, [data]);

  if (!data) {
    return (
      <Col xs={12}>
        <Text type="regular" color="gray" as="p" className="mt-2">
          Falha ao consultar informações de rastreio da viagem
        </Text>
      </Col>
    );
  }

  return (
    <>
      <Col md={6} xs={12}>
        <Text type="label" color="dark">
          Status do rastreamento
        </Text>
        <Text type="regular" color="gray" as="p" className="mt-2">
          {trackStatus}
        </Text>
      </Col>
      <Col md={6} xs={12}>
        <>
          <Text type="label" color="dark">
            Data da última alteração
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {data?.lastLog?.updatedAt
              ? formatDateTime(data?.lastLog?.updatedAt)
              : 'Não informado'}
          </Text>
        </>
      </Col>
      <Col md={6} xs={12}>
        <Text type="label" color="dark">
          Data da última localização
        </Text>

        <Text type="regular" color="gray" as="p" className="mt-2">
          {data?.lastLocation?.capturedAt
            ? formatDateTime(data?.lastLocation?.capturedAt)
            : 'Veículo sem posição'}
        </Text>
      </Col>
      <Col md={6} xs={12}>
        <Text type="label" color="dark">
          Código Solicitação
        </Text>

        <Text type="regular" color="gray" as="p" className="mt-2">
          {data?.presm?.codSM || 'Não informado'}
        </Text>
      </Col>
      {data?.lastLog?.message && data?.lastLog?.message?.length > 0 && (
        <Col xs={12}>
          <Text type="label" color="dark">
            Problemas encontrados
          </Text>
          <S.List className="pl-3">
            {data?.lastLog?.message?.map(error => (
              <li>
                <Text
                  type="regular"
                  color="gray"
                  as="p"
                  className="d-block mb-2"
                >
                  {error}
                </Text>
              </li>
            ))}
          </S.List>
        </Col>
      )}
      {data?.inconsistencies && data?.inconsistencies.length > 0 && (
        <Col xs={12}>
          <Text type="label" color="dark">
            Inconsistências raster
          </Text>
          <S.List className="pl-3">
            {data?.inconsistencies.map(inconsistencie => (
              <li>
                <Text
                  type="regular"
                  color="gray"
                  as="p"
                  className="d-block mb-2"
                >
                  {inconsistencie.message}
                </Text>
              </li>
            ))}
          </S.List>
        </Col>
      )}
    </>
  );
}

function PreSMStatus() {
  const { isTravelBlocked } = useContext(BlockedTravelContext);
  const { data, preSM, fetchPreSM, retrieveOccurrences, isLoading, fetchFeed } =
    useTravel();
  const [loading, setLoading] = useState(false);
  const showPreSMPermission = usePermission('VISUALIZAR_PRESM');
  const createPreSMPermission = usePermission('SOLICITAR_PRESM');
  const snackbar = useSnackbar();

  async function onClick() {
    setLoading(true);
    try {
      await cooperplaceApi.post('/presm', {
        id: data.id,
      });

      snackbar.show(<Text>Rastreio com a raster solicitado</Text>, {
        type: 'success',
      });
    } catch (err) {
      let message = 'Não foi possível soliciar rastreio';
      if (err.response && err.response.data.message) {
        message = err.response.data.message;
      }

      snackbar.show(<Text>{message}</Text>, {
        type: 'error',
      });
    }
    await Promise.all([fetchFeed(), fetchPreSM(), retrieveOccurrences()]);
    setLoading(false);
  }

  async function onCancelClick() {
    setLoading(true);
    try {
      await cooperplaceApi.delete(`/presm/${data.id}`);

      snackbar.show(<Text>SM cancelada com sucesso</Text>, {
        type: 'success',
      });
    } catch (err) {
      let message = 'Não foi possível cancelar a SM';
      if (err.response && err.response.data.message) {
        message = err.response.data.message;
      }

      snackbar.show(<Text>{message}</Text>, {
        type: 'error',
      });
    }

    setLoading(false);
    await Promise.all([fetchFeed(), fetchPreSM(), retrieveOccurrences()]);
  }

  async function onFinalizeClick() {
    setLoading(true);
    try {
      await cooperplaceApi.post(`/presm/${data.id}/finalize`);

      snackbar.show(<Text>SM finalizada com sucesso</Text>, {
        type: 'success',
      });
    } catch (err) {
      let message = 'Não foi possível finalizar a SM';
      if (err.response && err.response.data.message) {
        message = err.response.data.message;
      }

      snackbar.show(<Text>{message}</Text>, {
        type: 'error',
      });
    }
    await Promise.all([fetchFeed(), fetchPreSM(), retrieveOccurrences()]);
    setLoading(false);
  }

  const smActive = useMemo(() => {
    return preSM?.presm?.status === 1;
  }, [preSM]);

  const showBtn = useMemo(() => {
    const finished = [5, 6, 7, 10];
    if (finished.includes(data.status_id)) {
      return false;
    }

    if (!createPreSMPermission) {
      return false;
    }

    if (!preSM?.presm) {
      return true;
    }

    if (preSM?.presm?.status !== 1) {
      return true;
    }

    return false;
  }, [data, preSM]);

  if (!showPreSMPermission) return <></>;

  return (
    <Card
      loading={isLoading}
      LoadingBodyComponent={() => (
        <DefaultLoadingBodyComponent linesCount={4} perLine={2} />
      )}
      header={
        <Text color="dark" type="header">
          Rastreamento viagem
        </Text>
      }
      HeaderRightComponent={
        <>
          {showBtn ? (
            <div>
              <Button
                variant="primary"
                className="mr-2"
                onClick={onClick}
                loading={loading}
                disabled={isTravelBlocked}
              >
                <Text color="white" type="regular" weight="500">
                  Solicitar rastreamento
                </Text>
              </Button>
            </div>
          ) : null}
          {smActive ? (
            <div>
              <Button
                variant="danger"
                className="mr-2"
                onClick={onCancelClick}
                loading={loading}
              >
                <Text color="white" type="regular" weight="500">
                  Cancelar
                </Text>
              </Button>
              <Button
                variant="success"
                onClick={onFinalizeClick}
                loading={loading}
              >
                <Text color="white" type="regular" weight="500">
                  Finalizar
                </Text>
              </Button>
            </div>
          ) : null}
        </>
      }
    >
      <Row>
        <Col xs={12}>
          <div className="container-fluid">
            <Row className="form">
              <PreSMContent data={preSM} />
            </Row>
          </div>
        </Col>
      </Row>
    </Card>
  );
}

export default PreSMStatus;
