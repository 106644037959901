import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import { cooperplaceApi } from 'services/api';
import { downloadPDF, downloadXml } from 'utils/filedownload';

import Badge from 'v3/components/Badge';
import DropdownButton from 'v3/components/DropdownButton';
import { useSnackbar } from 'v3/components/Snackbar';
import Text from 'v3/components/Text';

import { CTE_STATUS_VARIANTS } from 'v3/utils/constants';
import { formatDateTime } from 'v3/utils/formatter';

export function Mdfe({
  mdfe,
  setClosingMdfe,
  setCancelingMdfe,
  setShowConfirmationModal,
}) {
  const [isDownloading, setDownloading] = useState(false);
  const snackbar = useSnackbar();

  async function downloadMdfeXml(id) {
    try {
      setDownloading(true);
      const response = await cooperplaceApi.get(
        `files/getMdfeCte?id=${id}&fileExtension=xml&documentType=mdfe`
      );
      downloadXml(response.data.content, `XML MDF-e ${id}.xml`);
    } catch (error) {
      snackbar.show('Chave do MDF-e inválida', { type: 'error' });
    } finally {
      setDownloading(false);
    }
  }

  async function downloadMdfePdf(id) {
    try {
      setDownloading(true);
      const response = await cooperplaceApi.get(
        `files/getMdfeCte?id=${id}&fileExtension=pdf&documentType=mdfe`
      );
      downloadPDF(response.data.content, 'MDF-e', id);
    } catch (error) {
      snackbar.show('Chave do MDF-e inválida', { type: 'error' });
    } finally {
      setDownloading(false);
    }
  }

  return (
    <div key={mdfe.id} className="mb-3">
      <Text as="div" color="dark" type="body" weight={500} className="mb-4">
        MDF-e {mdfe.number}
        {mdfe.status.name && (
          <Badge
            pill
            light
            size="sm"
            className="ml-2"
            variant={CTE_STATUS_VARIANTS[mdfe.status.id]}
          >
            {mdfe.status.name}
          </Badge>
        )}
      </Text>

      <Row className="d-flex justify-content-between">
        <Col xs={2}>
          <Text as="div" color="dark" type="regular" className="mb-3">
            Data de emissão
          </Text>
          <Text color="gray" type="body">
            {formatDateTime(mdfe.dateEmitted)}
          </Text>
        </Col>

        <Col className="d-flex justify-content-end">
          <DropdownButton
            style={{ display: 'inline', marginLeft: 10 }}
            title="Opções"
            variant="secondary"
            className="ml-2 py-2"
            loading={isDownloading}
            options={[
              {
                title: 'Baixar XML',
                onClick: () => downloadMdfeXml(mdfe.id),
                type: 'item',
              },
              {
                title: 'Baixar PDF',
                onClick: () => downloadMdfePdf(mdfe.id),
                type: 'item',
              },
              {
                title: 'Encerrar',
                onClick: () => {
                  setClosingMdfe(mdfe.id);
                  setShowConfirmationModal(true);
                },
                disabled: mdfe.status?.code !== 3,
                type: 'item',
              },
              {
                title: 'Cancelar',
                onClick: () => {
                  setCancelingMdfe(mdfe.id);
                  setShowConfirmationModal(true);
                },
                type: 'item',
                disabled: mdfe.status?.code !== 3,
              },
            ]}
          />
        </Col>
      </Row>
    </div>
  );
}
