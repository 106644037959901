import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';

import api from 'services/api';
import { Card, Text, Button, DatePicker } from 'v3/components';
import { useSnackbar } from 'v3/components/Snackbar';

import { formatDate, parseDate, parseErrors } from 'v3/utils/formatter';
import { usePermission } from 'hooks';

export default function Verification({ loadingCard }) {
  const params = useParams();
  const snackbar = useSnackbar();

  const hasPermission = usePermission('EDITAR_CONFIMACAO_CADASTRO_PESSOA');

  const [isEditing, setEditing] = useState(false);
  const [isInFlight, setInFlight] = useState(false);
  const [data, setData] = useState(null);
  const [dueDate, setDueDate] = useState('');
  const [errors, setErrors] = useState({});

  function updateIncomingData(incomingData) {
    setData(incomingData);
    if (incomingData) {
      setDueDate(formatDate(incomingData.due_date));
    }
  }

  async function fetchVerification() {
    try {
      const response = await api.get(`person/${params.id}/verification`);

      updateIncomingData(response.data);
    } catch (ex) {
      // Handle exception
    }
  }

  useEffect(() => {
    fetchVerification();
  }, [params.id]);

  async function onSubmit(event) {
    event.preventDefault();

    const pastDate = moment().isAfter(
      dueDate,
      moment(new Date(), 'DD/MM/YYYY')
    );

    if (pastDate) {
      snackbar.show(<Text>Insira uma data válida</Text>, { type: 'warning' });
      return;
    }

    setInFlight(true);

    try {
      const response = await api.post(`person/${params.id}/verification`, {
        due_date: parseDate(dueDate),
      });

      updateIncomingData(response.data);

      setEditing(false);
    } catch (ex) {
      if (ex.response) {
        const parsedErrors = parseErrors(ex.response.data, {
          getPath: error => error.field,
        });
        setErrors(parsedErrors);
      }
    } finally {
      setInFlight(false);
    }
  }

  function onCancel() {
    setDueDate(formatDate(data.due_date));
    setEditing(false);
  }

  function onEdit() {
    setDueDate(formatDate(data.due_date));
    setEditing(true);
  }

  return (
    <form onSubmit={onSubmit}>
      <Card
        loading={loadingCard}
        header={
          <Text color="#464E5F" type="header">
            Verificação de cadastro
          </Text>
        }
        HeaderRightComponent={
          isEditing ? (
            <div>
              <Button onClick={onCancel} variant="secondary" className="mr-2">
                <Text color="dark" type="regular">
                  Cancelar
                </Text>
              </Button>
              <Button variant="success" loading={isInFlight} type="submit">
                <Text weight={500} type="regular">
                  Salvar
                </Text>
              </Button>
            </div>
          ) : (
            hasPermission && (
              <Button variant="secondary" onClick={onEdit}>
                <Text weight={500} type="regular">
                  Editar
                </Text>
              </Button>
            )
          )
        }
      >
        <Row>
          {data && (
            <>
              <Col xs={12} md={6}>
                <Text type="label" color="dark">
                  Verificado em
                </Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {formatDate(data.created_at)}
                </Text>
              </Col>
              <Col xs={12} md={6}>
                <Text type="label" color="dark">
                  Verificado por
                </Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {data.verifiedByUser?.username}
                </Text>
              </Col>
            </>
          )}
          <Col xs={12} md={6}>
            <Text type="label" color="dark">
              Valido até
            </Text>
            {isEditing ? (
              <DatePicker
                guide
                value={dueDate}
                containerStyle={{ marginTop: 8 }}
                onChange={({ target }) => setDueDate(target.value)}
                error={errors.due_date}
              />
            ) : data ? (
              <Text type="regular" color="gray" as="p">
                {formatDate(data.due_date)}
              </Text>
            ) : (
              <Text type="regular" color="gray" as="p">
                Esse cadastro ainda não foi verificado.
              </Text>
            )}
          </Col>
        </Row>
      </Card>
    </form>
  );
}
