import React, { useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useParams } from 'react-router-dom';

import Input from 'v3/components/Input';

import { useCostCenter } from '../context';

export default function CostCenterData() {
  const costCenter = useCostCenter();
  const params = useParams();

  useEffect(() => {
    if (!params.id)
      costCenter.setData({
        name: null,
        externalId: null,
        identifier: null,
      });
  }, []);

  return (
    <Row className="form">
      <Col xs={12} md={6}>
        <Input
          label="Nome"
          value={costCenter?.data?.name}
          onChange={e => costCenter.setData({ name: e.target.value })}
          error={costCenter.errors['data.name']}
        />
      </Col>
      <Col xs={12} md={6}>
        <Input
          label="Id externo"
          value={costCenter?.data?.externalId}
          onChange={e => costCenter.setData({ externalId: e.target.value })}
          error={costCenter.errors['data.externalId']}
        />
      </Col>
      <Col xs={12} md={6}>
        <Input
          label="Identificador"
          value={costCenter?.data?.identifier}
          onChange={e => costCenter.setData({ identifier: e.target.value })}
          error={costCenter.errors['data.identifier']}
          guide={false}
          masked
          mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
        />
      </Col>
    </Row>
  );
}
