import React from 'react';

import {
  LeftButton,
  LeftText,
  RightButton,
  RightText,
  Container,
} from './styles';

function SwitchOption({
  value,
  activeText = 'Ativo',
  inactiveText = 'Inativo',
  ...props
}) {
  return (
    <Container>
      <LeftButton active={value} {...props}>
        <LeftText type="label">{activeText}</LeftText>
      </LeftButton>
      <RightButton active={value} {...props}>
        <RightText type="label">{inactiveText}</RightText>
      </RightButton>
    </Container>
  );
}

export default SwitchOption;
