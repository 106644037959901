// Peguei esse código que o Lon fez e adaptei para poder
// ser usado na exportação por csv
// /\ Otavio /\

export default response => {
  try {
    return {
      maxSpeed: response.maxSpeed,
      qtySpeed: response.qtyAbove,
    };
  } catch (ex) {
    console.log(ex);
    return false;
  }
};
