import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { sanitize } from 'dompurify';

import { cooperplaceApi } from 'services/api';

import { fetchSelectOptions } from 'utils/fetches';

import CheckBox from 'components/CheckBox';
import { Modal, Text, Button, Select } from 'v3/components';
import { useSnackbar } from 'v3/components/Snackbar';
import Space from 'v3/components/Space';
import { GRORouteSchema } from '../validators';

export function CreateRouteModal({ createRoute }) {
  const [routeType, setRouteType] = useState({
    origin: '',
    destination: '',
  });
  const [origin, setOrigin] = useState();
  const [destination, setDestination] = useState();
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  async function validate(data, setErrors) {
    const errorList = {};
    try {
      await GRORouteSchema.validate(data, { abortEarly: false });
      setErrors({ ...errorList });
      return true;
    } catch (err) {
      if (err.inner) {
        err.inner.forEach(error => {
          errorList[error.path] = error.message;
        });
        setErrors({ ...errorList });
        return false;
      }
    }
  }

  function getLegName(leg = 'origin') {
    if (leg === 'origin') {
      switch (routeType?.origin) {
        case 'country':
          return origin?.name;
        case 'province':
          return `${origin?.name}/${origin?.uf}`;
        case 'city':
          return `${origin?.name}/${origin?.province?.uf}`;
        default:
          return origin?.name;
      }
    } else {
      switch (routeType?.destination) {
        case 'country':
          return destination?.name;
        case 'province':
          return `${destination?.name}/${destination?.uf}`;
        case 'city':
          return `${destination?.name}/${destination?.province?.uf}`;
        default:
          return destination?.name;
      }
    }
  }

  async function handleSaveRoute() {
    try {
      setLoading(true);
      const originName = getLegName();
      const destinationName = getLegName('destination');
      const payload = {
        name: `${originName} - ${destinationName}`,
        origin: {
          id: origin?.id,
          type: routeType?.origin,
        },
        destination: {
          id: destination?.id,
          type: routeType?.destination,
        },
      };
      const isValid = await validate(payload, setErrors);
      if (!isValid) {
        snackbar.show(<Text color="dark">Verifique campos obrigatórios</Text>, {
          type: 'error',
        });
        setLoading(false);
        return;
      }
      await cooperplaceApi.post('gro/routes', payload);
      snackbar.show(<Text color="dark">Rota cadastrada com sucesso!</Text>, {
        type: 'success',
      });
      setLoading(false);
      setRouteType({
        origin: '',
        destination: '',
      });
      setOrigin();
      setDestination();
      createRoute.close();
    } catch (error) {
      let message =
        error?.response?.data?.error || 'Erro ao tentar cadastrar rota';
      snackbar.show(<Text>{message}</Text>, {
        type: 'error',
        showClose: true,
      });
      setLoading(false);
    }
  }

  function handleCloseModal() {
    setOrigin();
    setDestination();
    setRouteType({
      origin: '',
      destination: '',
    });
    setErrors({});
    createRoute.close();
  }

  return (
    <Modal
      size="lg"
      show={createRoute.isOpen}
      handleClose={handleCloseModal}
      heading={
        <Text type="body" color="dark" weight="400">
          Criação de rota apólice
        </Text>
      }
      body={
        <Row>
          <Col md={6} xs={12}>
            <div>
              <Text as="span" color="dark" type="regular" weight={500}>
                Tipo de origem
              </Text>
              <div>
                <CheckBox
                  checked={routeType?.origin === 'country'}
                  onChange={() => {
                    setOrigin();
                    setRouteType(old => ({
                      ...old,
                      origin: routeType?.origin === 'country' ? '' : 'country',
                    }));
                  }}
                  label={'País'}
                />
              </div>

              <div>
                <CheckBox
                  checked={routeType?.origin === 'province'}
                  onChange={() => {
                    setOrigin();
                    setRouteType(old => ({
                      ...old,
                      origin:
                        routeType?.origin === 'province' ? '' : 'province',
                    }));
                  }}
                  label={'Estado'}
                />
              </div>
              <div>
                <CheckBox
                  checked={routeType?.origin === 'city'}
                  onChange={() => {
                    setOrigin();
                    setRouteType(old => ({
                      ...old,
                      origin: routeType?.origin === 'city' ? '' : 'city',
                    }));
                  }}
                  label={'Cidade'}
                />
              </div>
            </div>
          </Col>
          <Col md={6} xs={12}>
            <div>
              <Text as="span" color="dark" type="regular" weight={500}>
                Tipo de destino
              </Text>
              <div>
                <CheckBox
                  checked={routeType?.destination === 'country'}
                  onChange={() => {
                    setDestination();
                    setRouteType(old => ({
                      ...old,
                      destination:
                        routeType?.destination === 'country' ? '' : 'country',
                    }));
                  }}
                  label={'País'}
                />
              </div>

              <div>
                <CheckBox
                  checked={routeType?.destination === 'province'}
                  onChange={() => {
                    setDestination();
                    setRouteType(old => ({
                      ...old,
                      destination:
                        routeType?.destination === 'province' ? '' : 'province',
                    }));
                  }}
                  label={'Estado'}
                />
              </div>
              <div>
                <CheckBox
                  checked={routeType?.destination === 'city'}
                  onChange={() => {
                    setDestination();
                    setRouteType(old => ({
                      ...old,
                      destination:
                        routeType?.destination === 'city' ? '' : 'city',
                    }));
                  }}
                  label={'Cidade'}
                />
              </div>
            </div>
          </Col>
          <Col md={6} sm={12}>
            <Select.Async
              label="Origem"
              placeholder="Busque uma origem"
              onSearch={search =>
                fetchSelectOptions(
                  routeType?.origin === 'country'
                    ? 'countries'
                    : routeType?.origin === 'province'
                    ? 'provinces'
                    : routeType?.origin === 'city'
                    ? 'cities'
                    : '',
                  { search }
                )
              }
              getOptionLabel={option => {
                if (routeType?.origin === 'country') {
                  return option?.name;
                } else if (routeType?.origin === 'province') {
                  return `${option?.name} - ${option?.uf}`;
                } else if (routeType?.origin === 'city') {
                  return `${option?.name} - ${option?.province?.uf}`;
                }
              }}
              getOptionValue={option => option?.id}
              onChange={value => setOrigin(value)}
              value={origin}
              isDisabled={!routeType.origin}
              error={errors['origin.id']}
            />
          </Col>
          <Col md={6} sm={12}>
            <Select.Async
              label="Destino"
              placeholder="Busque um destino"
              onSearch={search =>
                fetchSelectOptions(
                  routeType?.destination === 'country'
                    ? 'countries'
                    : routeType?.destination === 'province'
                    ? 'provinces'
                    : routeType?.destination === 'city'
                    ? 'cities'
                    : '',
                  { search }
                )
              }
              getOptionLabel={option => {
                if (routeType?.destination === 'country') {
                  return option?.name;
                } else if (routeType?.destination === 'province') {
                  return `${option?.name} - ${option?.uf}`;
                } else if (routeType?.destination === 'city') {
                  return `${option?.name} - ${option?.province?.uf}`;
                }
              }}
              getOptionValue={option => option?.id}
              onChange={value => setDestination(value)}
              value={destination}
              isDisabled={!routeType.destination}
              error={errors['destination.id']}
            />
          </Col>
        </Row>
      }
      footer={
        <Space>
          <Button
            size="sm"
            onClick={handleSaveRoute}
            disabled={loading}
            loading={loading}
          >
            <Text>Cadastrar</Text>
          </Button>
        </Space>
      }
    />
  );
}
