import styled from 'styled-components';
import { Modal } from 'v3/components';

export const StyledModal = styled(Modal)`
  && .modal-title {
    width: 100%;
  }
  && .modal-header {
    border-bottom: 1px solid transparent;
  }
`;

export const StyledManualItemsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-auto-flow: column;
  grid-row-gap: 0.5rem;

  .innerSection {
    & > div {
      margin-left: 1rem;
    }
  }

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-auto-flow: row;
  }
`;

export const StyledManualValidationCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  & > label {
    width: fit-content;
  }
`;

export const StyledPolicyItemsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-auto-flow: column;
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-auto-flow: row;
  }
`;

export const StyledPolicyItemStatus = styled.span`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  & > svg {
    width: 1rem;
    height: 1rem;
    color: ${props =>
      props.itemStatus === 'ok'
        ? props.theme.colors.success
        : props.itemStatus === 'pendency'
        ? props.theme.colors.warning
        : props.theme.colors.danger};
  }
`;
