import * as Yup from 'yup';

export const dataSchema = Yup.object().shape({
  name: Yup.string().required('Nome obrigatório').typeError('Nome obrigatório'),
  externalId: Yup.string().nullable(),
  identifier: Yup.number().nullable(),
});

export const costCenterSchema = Yup.object().shape({
  data: dataSchema,
});

export async function validate(field, data, setErrors) {
  const errorList = {};
  try {
    if (field === 'allSchema')
      await costCenterSchema.validate(data, {
        abortEarly: false,
      });
    else {
      await costCenterSchema.validateAt(field, data, {
        abortEarly: false,
      });
    }

    return true;
  } catch (err) {
    err.inner.forEach(error => {
      errorList[error.path] = error?.message;
    });

    return false;
  } finally {
    setErrors(errorList);
  }
}
