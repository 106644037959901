import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { default as ReactSelect } from 'react-select';

import Select from 'components/Select';
import Input from 'components/Input';
import Label from 'components/Label';
import DatePicker from 'components/DatePicker';
import Button from 'components/Button';

import moment from 'moment';

import api from 'services/api';

import { CSVLink } from 'react-csv';

let i = 0;

const statuses = [
  {
    text: 'Todos',
    value: 'Todos',
  },
  {
    text: 'Cobrando',
    value: 'Cobrando',
  },
  {
    text: 'Pago em Atraso',
    value: 'Pago em Atraso',
  },
  {
    text: 'Pago no Prazo',
    value: 'Pago no Prazo',
  },
  {
    text: 'Cancelada',
    value: 'Cancelada',
  },
];

export default function FilterFinanceiro(props) {
  const queryParams = useQuery();

  const currentCompanyId = useSelector(state => state.currentCompany);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [inputBill, setInputBill] = useState('');
  const [inputInvoice, setInputInvoice] = useState('');
  const [cteNumber, setCteNumber] = useState('');
  const [travelFinancialValue, setTravelFinancialValue] = useState(
    statuses[0].text
  );
  const [companyList, setCompanyList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState([]);

  const [dataFinal, setDataFinal] = useState([]);
  const [fieldFinal, setFieldFinal] = useState([]);
  const [fileName, setFileName] = useState('');

  const fileExtensionCSV = '.csv';
  const tituloPagina = 'Faturas_';

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const history = useHistory();
  useEffect(() => {
    getCompanies();
    if (queryParams.get('companies')) {
      getSelectedCompanies(queryParams.getAll('companies'));
    }
  }, []);

  function handleChange(event) {
    setTravelFinancialValue(event.target.value);
  }

  async function filterFinancas(event) {
    event.preventDefault();

    let filters = '';
    if (moment(startDate).isValid())
      filters += startDate
        ? `start_date=${moment(startDate).format('YYYY-MM-DD')}`
        : '';
    if (moment(endDate).isValid())
      filters += endDate
        ? `&end_date=${moment(endDate).format('YYYY-MM-DD')}`
        : '';
    if (selectedCompany) {
      selectedCompany.forEach(company => {
        if (company) {
          filters += `&companies=${company.value}`;
        }
      });
    }
    filters += inputBill !== '' ? `&bill_number=${inputBill}` : '';
    filters += inputInvoice !== '' ? `&invoice_number=${inputInvoice}` : '';
    filters += travelFinancialValue ? `&status=${travelFinancialValue}` : '';
    filters += cteNumber && `&cte_number=${cteNumber}`;
    filters += `&${i}`;

    try {
      // await getCSV();
    } catch (ex) {}

    history.push({ search: filters });
    props.getFinancas(filters);
    i++;
  }

  async function getCSV() {
    let filters = '';

    if (moment(startDate).isValid())
      filters += startDate
        ? `start_date=${moment(startDate).format('YYYY-MM-DD')}`
        : '';
    if (moment(endDate).isValid())
      filters += endDate
        ? `&end_date=${moment(endDate).format('YYYY-MM-DD')}`
        : '';
    filters += inputBill !== '' ? `&bill_number=${inputBill}` : '';
    filters += inputInvoice !== '' ? `&invoice_number=${inputInvoice}` : '';
    filters += travelFinancialValue ? `&status=${travelFinancialValue}` : '';
    filters += cteNumber && `&cte_number=${cteNumber}`;
    // if (selectedCompany) {
    //   selectedCompany.forEach(company => {
    //     filters += `&companies=${company.value}`;
    //   });
    // }
    filters += `&${i}`;

    const response = await api.get(
      `billing-travel-data/forCSV/${currentCompanyId}?${filters}`
    );
    const { fields } = response.data;

    const { data } = response;

    const dados = data.map(el => {
      let pos = {};
      if (el.vencimento != null) {
        pos = { vencimento: el.vencimento.split('T')[0] };
      }
      return { ...el, ...pos };
    });

    setFieldFinal(fields);
    setDataFinal(dados);
  }

  function generateFileName(data) {
    return (
      tituloPagina +
      moment(data.created_at).format('YYYYMMDDHHmmssZ') +
      fileExtensionCSV
    );
  }

  async function getSelectedCompanies(id) {
    const usuario = localStorage.getItem('usuario');
    const jsonU = JSON.parse(usuario);
    try {
      const response = await api.get(`clients/user/${jsonU.id}`);
      if (response.data.clients.length === 0) {
        console.error('Nenhum cliente cadastrado em sua conta');
      } else {
        const companies = response.data.clients
          .filter(item => id.includes(`${item.id}`))
          .map(item => ({
            label: item.social_name,
            value: item.id,
            cgccpf: item.cgccpf,
          }));
        setSelectedCompany(companies);
      }
    } catch (err) {
      console.error(`Erro:${err}`);
    }
  }

  async function getCompanies() {
    const usuario = localStorage.getItem('usuario');
    const jsonU = JSON.parse(usuario);
    try {
      const response = await api.get(`clients/user/${jsonU.id}`);
      if (response.data.clients.length === 0) {
        console.error('Nenhum cliente cadastrado em sua conta');
      } else {
        const companies = response.data.clients.map((item, index) => {
          return {
            label: item.social_name,
            value: item.id,
            cgccpf: item.cgccpf,
          };
        });
        setCompanyList(companies);
      }
    } catch (err) {
      console.error(`Erro:${err}`);
    }
  }

  return (
    <form
      onSubmit={filterFinancas}
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      <div>
        <Label>Tomador</Label>
        <ReactSelect
          placeholder="Selecione"
          value={selectedCompany}
          isMulti
          onChange={value => setSelectedCompany(value)}
          options={companyList}
          getOptionLabel={option => `${option.label} - ${option.cgccpf}`}
        />
        <Label htmlFor="from">Período de / até</Label>
        <DatePicker
          name="from"
          direction="left"
          format="DD/MM/YYYY"
          style={{ width: '50%', padding: 0 }}
          value={startDate}
          onChange={setStartDate}
        />
        <DatePicker
          direction="right"
          format="DD/MM/YYYY"
          style={{ width: '50%', padding: 0 }}
          value={endDate}
          onChange={setEndDate}
        />
      </div>
      <Select
        value={travelFinancialValue}
        label="Status"
        name="status"
        onChange={handleChange}
        options={statuses}
        itemTitle="text"
      />
      <Input
        value={inputBill}
        label="Nota fiscal"
        onChange={e => setInputBill(e.target.value)}
        margin="normal"
        variant="outlined"
      />

      <Input
        value={inputInvoice}
        onChange={e => setInputInvoice(e.target.value)}
        margin="normal"
        label="N° Fatura"
        variant="outlined"
      />

      <Input
        value={cteNumber}
        onChange={e => setCteNumber(e.target.value)}
        margin="normal"
        label="N° do CTe"
        variant="outlined"
      />
      <div
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '15px',
        }}
      >
        <Button type="submit">Filtrar</Button>
      </div>

      <div
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '15px',
        }}
      >
        {/* <Button
          type="button"
          // className={classes.btnExportCSV}
          disabled={dataFinal.length === 0}
          style={{ backgroundColor: '#00BC97', borderColor: '#00BC97' }}
        >
          <CSVLink
            data={dataFinal}
            headers={fieldFinal}
            style={{ color: '#fff' }}
            separator={';'}
            filename={fileName}
            onClick={event => {
              setFileName(generateFileName(new Date()));
              return !(dataFinal.length === 0);
            }}
          >
            Exportar p/ CSV
          </CSVLink>
        </Button> */}
      </div>
    </form>
  );
}
