import React, { useState, useEffect, useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';
import { cooperplaceApi } from 'services/api';
import { Button, Card, DropdownButton, Text } from 'v3/components';
import { useSnackbar } from 'v3/components/Snackbar';
import ApplicationLayout from 'v3/layouts/Application';
import { Badge } from 'v3/pages/Load/List/Card/styles';
import { currencyOptions } from 'v3/utils/formatter';
import { validatePermission } from 'actions';
import { Data } from './Data';
import { VehicleData } from './Vehicle';
import LocaleData from './Locale';
import RenewPreLoad from '../List/Modals/RenewPreLoad';
import CancelLoad from 'v3/pages/Load/Details/ModalCancelLoad';
import { confirmCancelOption } from '../List/Modals/cancelPreLoadController';
import { usePermission } from 'hooks';
import { RequestLoad } from '../List/Modals/RequestLoad';
import moment from 'moment';
import { DriversMatch } from './DriversMatch';

export function PreLoadDetail() {
  usePermission('VISUALIZAR_PRECARGA', { redirect: true });
  const params = useParams();
  const history = useHistory();
  const snackbar = useSnackbar();
  const hasPermissionToEdit = validatePermission('CRIAR_PRECARGA');
  const [loading, setLoading] = useState(false);
  const [preLoad, setPreLoad] = useState();
  const [drivers, setDrivers] = useState([]);
  const [fetchError, setFetchError] = useState({ status: null, message: null });
  const [showRenewalModal, setShowRenewalModal] = useState(false);
  const [cancelPreLoadModal, setCancelPreLoadModal] = useState(false);
  const [requestLoadModal, setShowRequestLoadModal] = useState(false);
  const preLoadCurrency = currencyOptions.find(
    item => item.value === preLoad?.currency
  );
  const expiredPreLoad =
    !moment(preLoad?.dueDate).isAfter(moment()) && !preLoad?.travel?.id;
  const hasBeenRequested = preLoad?.preTravel?.id;
  const shouldDisableEditing =
    expiredPreLoad || hasBeenRequested || preLoad?.deletedAt;

  const disabledTitle =
    preLoad?.preTravel?.id && !preLoad?.travel?.id
      ? 'Não é possível editar pré cargas solicitadas'
      : preLoad?.preTravel?.id && preLoad?.travel?.id
      ? 'Não é possível editar pré cargas atendidas'
      : '';

  const getPreLoad = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await cooperplaceApi.get(`pre-loads/${params?.id}`);
      setPreLoad(data);
      const { data: driverData } = await cooperplaceApi.get(
        `loads/${params?.id}/visualizations`
      );
      setDrivers(driverData);
    } catch (error) {
      setFetchError({
        status: error?.response?.status,
        message: error?.response?.message,
      });
      snackbar.show(<Text>Erro ao recuperar pré carga!</Text>);
    } finally {
      setLoading(false);
    }
  }, [params?.id]);

  useEffect(() => {
    if (params?.id) {
      getPreLoad();
    }
  }, [params]);
  return (
    <ApplicationLayout
      title="Detalhes da pré carga"
      RightComponent={
        <>
          <Button
            variant="secondary"
            onClick={() => history.goBack()}
            className="ml-2"
          >
            <Text weight={500} type="regular">
              Voltar
            </Text>
          </Button>
          <DropdownButton
            disabled={loading}
            style={{ display: 'inline', marginLeft: 10 }}
            title="Opções"
            variant="primary"
            className="ml-2"
            options={[
              {
                title: 'Solicitar Pré Carga',
                onClick: () => setShowRequestLoadModal(true),
                disabled: !hasPermissionToEdit || shouldDisableEditing,
                type: 'item',
              },
              {
                title: 'Renovar Pré Carga',
                onClick: () => setShowRenewalModal(true),
                disabled: !hasPermissionToEdit || hasBeenRequested,
                type: 'item',
              },
              {
                title: 'Cancelar Pré Carga',
                onClick: () => setCancelPreLoadModal(true),
                disabled:
                  !hasPermissionToEdit ||
                  hasBeenRequested ||
                  preLoad?.deletedAt,
                type: 'item',
              },
              {
                type: 'divider',
              },
              {
                title: 'Nova Pré Carga',
                onClick: () => history.push('/pre-cargas/cadastrar'),
                disabled: !hasPermissionToEdit,
                type: 'item',
              },
            ]}
          />
        </>
      }
    >
      <RequestLoad
        loadId={preLoad?.id}
        onClose={() => setShowRequestLoadModal(false)}
        show={requestLoadModal}
        fetchPreLoads={getPreLoad}
      />
      <RenewPreLoad
        preLoad={preLoad}
        handleClose={() => setShowRenewalModal(false)}
        show={showRenewalModal}
      />
      <CancelLoad
        loadId={preLoad?.id}
        handleClose={() => setCancelPreLoadModal(false)}
        onConfirm={confirmCancelOption}
        show={cancelPreLoadModal}
      />
      <Row>
        <Col md={12}>
          <Card>
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <Text color="#464E5F" type="header">
                  Número {preLoad?.id}
                </Text>
                {!!preLoad?.deletedAt && (
                  <div>
                    <Badge pill light type="error" className="ml-2">
                      <Text type="label">Cancelada</Text>
                    </Badge>
                  </div>
                )}
                {preLoad?.preTravel?.id && !preLoad?.travel ? (
                  <div>
                    <Badge pill light type="warning" className="ml-2">
                      <Text type="label" weight={500}>
                        Em processo de atendimento
                      </Text>
                    </Badge>
                  </div>
                ) : null}
                {preLoad?.travel ? (
                  <div>
                    <Badge pill light type="success" className="ml-2">
                      <Text type="label" weight={500}>
                        Atendida
                      </Text>
                    </Badge>
                  </div>
                ) : null}
              </div>
              <Text type="label" color="gray" className="pr-2">
                Criado por {preLoad?.userCreated?.username}
              </Text>
            </div>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={6} xs={12}>
          <Data
            preLoad={preLoad}
            preLoadCurrency={preLoadCurrency}
            hasPermissionToEdit={hasPermissionToEdit}
            getPreLoad={getPreLoad}
            loading={loading}
            shouldDisableEditing={shouldDisableEditing}
            disabledTitle={disabledTitle}
          />
        </Col>
        <Col md={6}>
          <VehicleData
            preLoad={preLoad}
            hasPermissionToEdit={hasPermissionToEdit}
            getPreLoad={getPreLoad}
            loading={loading}
            shouldDisableEditing={shouldDisableEditing}
            disabledTitle={disabledTitle}
          />
        </Col>
        <Col md={12}>
          <DriversMatch loading={loading} drivers={drivers} />
        </Col>
        <Col md={12}>
          <LocaleData
            preLoad={preLoad}
            hasPermissionToEdit={hasPermissionToEdit}
            loadId={preLoad?.id}
            loading={loading}
            shouldDisableEditing={shouldDisableEditing}
            disabledTitle={disabledTitle}
            getPreLoad={getPreLoad}
          />
        </Col>
      </Row>
    </ApplicationLayout>
  );
}
