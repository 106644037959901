import Card from 'components/Badge';
import { CloseButton } from 'react-bootstrap';
import styled from 'styled-components';
import { Text } from 'v3/components';
import theme from 'v3/theme';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

export const MapsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 10px;
  padding-top: 1rem;
`;

export const StyledClusterMarker = styled.div`
  color: #fff;
  background: #1978c8;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CardStyled = styled(Card)`
  position: absolute;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1) !important;
  background: white;
  opacity: 91%;
  z-index: 1;
  border-radius: 10px !important;
  margin-top: -12rem;
  width: inherit;
  max-height: 150px;
  overflow: scroll;
  scroll-behavior: smooth;
  ::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
  ::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.light_gray};
    opacity: 0.5;
  }
  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.gray};
    border-radius: 10px;
  }
  @media (max-width: 425px) {
    font-size: 1px !important;
    margin-top: 2% !important;
  }

  @media (max-width: 1440px) {
    font-size: 1px;
  }
`;

export const TextView = styled(Text)`
  padding-left: 2%;
  cursor: pointer;
  color: ${theme.colors.light_blue};
`;

export const OpenInNewIconStyled = styled(OpenInNewIcon)`
  width: 15px !important;
`;

export const CloseButtonStyled = styled(CloseButton)`
  margin-top: -10%;
  @media (max-width: 425px) {
    margin-top: -80%;
  }
`;
