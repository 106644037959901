import { object, string, date, array } from 'yup';
import moment from 'moment';

import 'v3/utils/yup';

export default object({
  travelNumber: string().required('Número da viagem é obrigatório.'),
  cteNumber: string()
    .required('Número do CT-e é obrigatório.')
    .test('unique', 'Número do CT-e deve ser único.', function test(value) {
      const { emitteds, cteNumberOld } = this.options.context;
      if (cteNumberOld === value) return true;
      return !emitteds.some(cte => cte.cte_number === value);
    }),
  issuedAt: date()
    .transform((_value, originalValue) =>
      moment(originalValue, 'DD/MM/YYYY HH:mm').toDate()
    )
    .required('Data de emissão do CT-e é obrigatória.')
    .typeError('Data de emissão do CT-e é obrigatória.'),
  invoices: array()
    .required('Você deve inserir pelo menos uma nota fiscal.')
    .of(
      object({
        key: string()
          .required('Você deve preenchar a chave dessa nota fiscal.')
          .test(
            'unique',
            'Chave da nota fiscal deve ser única.',
            function test(value) {
              const { emitteds } = this.options.context;
              return !emitteds.some(cte =>
                cte.invoicesCte.some(invoice => invoice.key === value)
              );
            }
          ),
      }).uniqueProperty('key', 'Chave da nota fiscal deve ser única.')
    ),
  destination: object()
    .required('Destino é obrigatório.')
    .typeError('Destino é obrigatório.'),
});
