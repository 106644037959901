import React, { useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import Modal from 'v3/components/Modal';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import { cooperplaceApi } from 'services/api';
import { Signature } from 'v3/components';
import './signature.css';

function AddSignature({
  isOpen,
  onClose,
  signature,
  setLoading,
  snackbar,
  getSignature,
}) {
  const canvasRef = useRef(null);
  const sendSignature = async () => {
    try {
      setLoading(true);
      const arrayPoints = canvasRef.current.toData();
      if (!arrayPoints.length) {
        await cooperplaceApi.delete('users/signature');
        await getSignature();
        onClose();
        return;
      }

      const base64 = canvasRef.current.toDataURL();
      await cooperplaceApi.post('users/signature', {
        base64,
      });
      await getSignature();
      onClose();
    } catch (error) {
      snackbar.show(
        <Text>
          {error?.response?.data?.message || 'Erro ao obter assinatura'}
        </Text>,
        { type: 'error' }
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        show={isOpen}
        onHide={onClose}
        heading={
          <Text color="dark" type="header">
            Assinatura
          </Text>
        }
        body={
          <Row>
            <Col xs={12}>
              <Signature ref={canvasRef} signature={signature} />
            </Col>
            <Col
              xs={12}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Button onClick={sendSignature} className="my-2">
                <Text type="regular" weight="500" color="white">
                  Enviar
                </Text>
              </Button>
            </Col>
          </Row>
        }
      />
    </>
  );
}

export default AddSignature;
