import React, { useState } from 'react';
import { useModal } from 'hooks';
import { Card, Fallback, Button, Text } from 'v3/components';
import { TravelOccurrencesList } from 'v3/components/TravelOccurrencesList';
import { OccurrencesModal } from 'v3/components/OccurrencesModal';
import { Col } from 'react-bootstrap';
import theme from 'v3/theme';
import { useTravel } from '../..';

export function TravelOccurrences() {
  const travel = useTravel();
  const occurrencesModal = useModal();
  const [loading, setLoading] = useState(false);
  const [savingNewOccurrence, setSavingNewOccurrence] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  function openOccurrencesModal() {
    return occurrencesModal.open();
  }
  async function closeOccurrencesModal() {
    if (savingNewOccurrence || loadingUpdate) {
      return;
    }
    try {
      setLoading(true);
      await travel.retrieveOccurrences();
    } catch {
    } finally {
      setLoading(false);
    }

    return occurrencesModal.close();
  }

  const totalPendency = travel?.travelOccurrences?.reduce((acc = 0, curr) => {
    if (curr?.pendency === true && !curr?.fullUserConclusion) {
      acc += 1;
      return acc;
    }
    return acc;
  }, 0);

  return (
    <Card
      loading={travel.isLoading}
      header={
        <Text color="dark" type="header">
          Ocorrências
        </Text>
      }
      HeaderRightComponent={
        <Button onClick={() => openOccurrencesModal()}>
          <Text color="white">Visualizar</Text>
        </Button>
      }
    >
      <Fallback
        fall={travel?.travelOccurrences.length === 0}
        component={
          <Col className="pt-2 d-flex justify-content-center align-items-center">
            <Button colors={theme.colors.primary} size="md" disabled>
              Não há itens a serem exibidos
            </Button>
          </Col>
        }
      >
        <TravelOccurrencesList
          onlyList
          totalPendencyOccurrences={totalPendency || 0}
          occurrences={travel?.travelOccurrences}
        />
      </Fallback>
      <OccurrencesModal
        isOpen={occurrencesModal.isOpen}
        onClose={closeOccurrencesModal}
        travelId={travel?.data?.id}
        bennerTravelId={travel?.data?.travel_number}
        fetchingTravelOccurrences={loading}
        setFetchingTravelOccurrences={setLoading}
        savingNewOccurrence={savingNewOccurrence}
        setSavingNewOccurrence={setSavingNewOccurrence}
        loadingUpdate={loadingUpdate}
        setLoadingUpdate={setLoadingUpdate}
      />
    </Card>
  );
}
