import { endOfDay, startOfDay } from 'date-fns';
import moment from 'moment';

export const currentRegionOptions = [
  { label: 'SUL', value: 'Sul' },
  { label: 'SUDESTE', value: 'Sudeste' },
  { label: 'NORTE', value: 'Norte' },
  { label: 'NORDESTE', value: 'Nordeste' },
  { label: 'CENTRO OESTE', value: 'Centro-Oeste' },
  { label: 'LATAM', value: 'Exterior' },
];

export const currentProvinceOptions = [
  { label: 'SUL' },
  { label: 'SULDESTE' },
  { label: 'NORTE' },
  { label: 'NORDESTE' },
  { label: 'CENTRO OESTE' },
];

export const serviceLevelOptions = [
  {
    label: 'TERCEIRO',
    value: 0,
  },
  {
    label: 'AGREGADO',
    value: 1,
  },
];

export const vehiclesAvailability = [
  {
    label: 'EM VIAGEM',
    value: 0,
  },
  {
    label: 'VAZIO',
    value: 1,
  },
];

export const isTrackedOptions = [
  {
    label: 'SEM RASTREIO',
    value: 0,
  },
  {
    label: 'COM RASTREIO',
    value: 1,
  },
];

export const fleetTypeOptions = [
  { label: 'Própria', value: 1 },
  { label: 'Não integra frota', value: 0 },
];

export const availableCountries = [
  'brasil',
  'argentina',
  'chile',
  'uruguai',
  'paraguai',
];

export const aggregationOptions = [
  { label: 'Terceiro', id: 'Terceiro' },
  { label: 'Agregado', id: 'Agregado' },
];

export function isDatePeriodValid(startDate, endDate) {
  if (moment(startDate).isAfter(endDate)) {
    return {
      valid: false,
      message: 'Data inicial não pode ser maior que data final',
    };
  }
  let today = endOfDay(new Date(moment())).toISOString();
  if (moment(endDate).isAfter(moment(today))) {
    return {
      valid: false,
      message: 'Data final não pode ser maior que data de hoje',
    };
  }

  return {
    valid: true,
  };
}

export function setQueryParams(filters, query) {
  if (filters?.currentCity?.length) {
    query.set('currentCity', filters?.currentCity.map(v => v.id).join(','));
  }
  if (filters?.currentCountry?.length) {
    query.set(
      'currentCountry',
      filters?.currentCountry.map(v => v.id).join(',')
    );
  }
  if (filters?.currentProvince?.length) {
    query.set(
      'currentProvince',
      filters?.currentProvince.map(value => value.id).join(',')
    );
  }
  if (filters?.currentRegion?.length) {
    query.set(
      'currentRegion',
      `('` + filters?.currentRegion.map(value => value.value).join(`','`) + `')`
    );
  }
  if (filters?.driver?.length) {
    query.set('driver', filters?.driver.map(v => v.id).join(','));
  }
  if (filters?.fleetType?.length) {
    query.set(
      'fleetType',
      `('` + filters?.fleetType.map(value => value.value).join(`','`) + `')`
    );
  }
  if (filters?.lastCity?.length) {
    query.set('lastCity', filters?.lastCity.map(v => v.id).join(','));
  }
  if (filters?.lastExternalId?.length) {
    query.set('lastExternalId', filters?.lastExternalId);
  }
  if (filters?.lastLoad?.length) {
    query.set('lastLoad', filters?.lastLoad);
  }
  if (filters?.lastNegotiator?.length) {
    query.set(
      'lastNegotiator',
      filters?.lastNegotiator.map(v => v.id).join(',')
    );
  }
  if (filters?.lastProvince?.length) {
    query.set(
      'lastProvince',
      `('` + filters?.lastProvince.map(value => value.uf).join(`','`) + `')`
    );
  }
  if (filters?.lastTaker?.length) {
    query.set('lastTaker', filters?.lastTaker.map(v => v.id).join(','));
  }
  if (filters?.lastTravel?.length) {
    query.set('lastTravel', filters?.lastTravel);
  }
  if (filters?.lastTravelCostCenter?.length) {
    query.set(
      'lastTravelCostCenter',
      filters?.lastTravelCostCenter.map(v => v.id).join(',')
    );
  }
  if (filters?.pcp?.length) {
    query.set('pcp', filters?.pcp.map(v => v.id).join(','));
  }
  if (filters?.plate?.length) {
    query.set('plate', filters?.plate.map(v => v.id).join(','));
  }
  if (filters?.serviceLevel?.length) {
    query.set(
      'serviceLevel',
      `('` + filters?.serviceLevel.map(value => value.value).join(`','`) + `')`
    );
  }
  if (filters?.shippingCompany?.length) {
    query.set(
      'shippingCompany',
      filters?.shippingCompany.map(v => v.id).join(',')
    );
  }
  if (filters?.vehicleAvailability?.label) {
    query.set('vehicleAvailability', filters?.vehicleAvailability.value);
  }
  if (filters?.isTracked?.label) {
    query.set('isTracked', filters?.isTracked.value);
  }
  if (filters?.lastTravelDateBegin?.length) {
    query.set(
      'lastTravelDateBegin',
      startOfDay(
        new Date(moment(filters?.lastTravelDateBegin, 'DD/MM/YYYY'))
      ).toISOString()
    );
  }
  if (filters?.lastTravelDateEnd?.length) {
    query.set(
      'lastTravelDateEnd',
      endOfDay(
        new Date(moment(filters?.lastTravelDateEnd, 'DD/MM/YYYY'))
      ).toISOString()
    );
  }
  if (filters?.companies?.length) {
    query.set('companies', filters?.companies.map(v => v.id).join(','));
  }
  return query;
}
