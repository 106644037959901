/* eslint-disable import/no-unresolved */
import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

import useQuery from 'hooks/useQuery';
import useCurrentUser from 'hooks/useCurrentUser';
import dealAPI, { createSocketConnection } from 'services/apis/deal';
import ConversationList from '../conversationList/index';
import Messenger from '../messenger/index';
import ConversationSearch from '../conversationSearch/index';
import { useSnackbar } from 'v3/components/Snackbar';

import './style.css';
import { RequestLoad } from 'v3/pages/PreLoad/List/Modals/RequestLoad';
import { useModal } from 'hooks';

import { Text } from 'v3/components';

function Chat() {
  const queryParams = useQuery();
  const history = useHistory();
  const snackbar = useSnackbar();

  const requestPreLoadModal = useModal();
  const [requestPreLoad, setRequestPreLoad] = useState({
    id: null,
    currency: null,
  });
  const [messages, setMessages] = useState();
  const [dealList, setDealList] = useState([]);
  const [selectedDeal, setSelectedDeal] = useState();
  const [currentDeal, setCurrentDeal] = useState(null);
  const currentUser = useCurrentUser();
  const [isOnlyActive, setOnlyActive] = useState(true);
  const [listReload, setListReload] = useState(null)
  const [loadNumber, setLoadNumber] = useState('');
  const [pagination, setPagination] = useState({
    total: 0,
    lastPage: 0,
    currentPage: 0,
  });
  const [iniciateButton, setIniciateButton] = useState();

  function markDealNewMessage(incoming, newMessage) {
    setDealList(oldDealList =>
      oldDealList.map(deal => {
        if (deal.id === incoming) {
          return {
            ...deal,
            unreadCount: newMessage ? (deal.unreadCount || 0) + 1 : 0,
          };
        }
        return deal;
      })
    );
  }

  function setupQueryParams() {
    history.push({ pathname: 'negociacoes', search: queryParams.toString() });
  }

  async function getNegotiationMessages(deal) {

    try {
      const response = await dealAPI.get(`messages?deal_id=${deal.id}`);
      const inicButton = await dealAPI.get(
        `deals/showDealButton/${deal.driver_id}/${deal.load_id}`
      );

      setIniciateButton(inicButton.data);
      setMessages(response.data);

    } catch (error) {
      snackbar.show(<Text>Não foi possivel exibir a negociação</Text>, {
        type: 'error',
      });
      setIniciateButton(null);
      setMessages(null);
    }
  }

  function openConversationItem(deal) {
    window.scrollTo(0, 1000);

    if (currentDeal === deal.id) {
      return;
    }
    queryParams.set('deal_id', deal.id);
    setSelectedDeal(deal);
    getNegotiationMessages(deal);
    setupQueryParams();
    setCurrentDeal(deal.id);

    markDealNewMessage(deal.id, false);
  }

  useEffect(() => {
    const dealId = +queryParams.get('deal_id');

    if (dealId && dealList) {
      const found = dealList.find(deal => deal.id === dealId);
      if (found) openConversationItem(found);
    }
  }, [queryParams, currentDeal, dealList]);

  useEffect(() => {
    const socket = createSocketConnection();

    socket.on('reloadList', args => {
      if (args.receiver_id === currentUser.id) {
        setListReload(args.deal_id)
      }
    })

    socket.on('message', incomingMessage => {
      if (incomingMessage.receiver_id === currentUser.id) {
        markDealNewMessage(incomingMessage.deal_id, true);
      }

      if (selectedDeal) {
        if (selectedDeal.id !== incomingMessage.deal_id) {
          return;
        }

        setMessages(oldMessages => [
          ...oldMessages.filter(oldMessage => oldMessage.id),
          incomingMessage,
        ]);
        socket.emit('read', incomingMessage);
        markDealNewMessage(selectedDeal.id, false);
      }
    });

    return () => {
      socket.removeEventListener('message');
      socket.removeEventListener('reloadList');
    };
  }, [selectedDeal, setMessages, currentDeal]);

  const filterNegotiations = useCallback(
    async (page = 1) => {
      try {
        const response = await dealAPI.get(`deals`, {
          params: {
            negotiator_id: currentUser.id,
            load_id: loadNumber || null,
            only_active: !!isOnlyActive,
            page,
          },
        });
        setDealList(oldDealList =>
          page === 1
            ? response.data.data
            : [...oldDealList, ...response.data.data]
        );
        setPagination(response.data.pagination);
      } catch (error) {
        setDealList([]);
      }
    },
    [currentUser, loadNumber, isOnlyActive]
  );

  useEffect(() => {
    const timeout = setTimeout(filterNegotiations, 200);

    return () => {
      clearTimeout(timeout);
    };

  }, [isOnlyActive, loadNumber, listReload]);

  function handleStatusSelected(param) {
    setOnlyActive(param);
  }

  function renderNegotiations(negotiationsQty) {
    return (
      <Grid container className="chat">
        <Grid item md={4} xs={12}>
          <RequestLoad
            loadId={requestPreLoad?.id}
            show={requestPreLoadModal.isOpen}
            onClose={() => {
              requestPreLoadModal.close();
              setRequestPreLoad(null);
            }}
            fetchPreLoads={() => {
              queryParams.delete('deal_id');
              setupQueryParams();
              setSelectedDeal(null);
              setCurrentDeal(null);
              setMessages();
              setRequestPreLoad({ id: null, currency: null })
              filterNegotiations();
            }}
          />
          <ConversationSearch
            handleStatusSelected={handleStatusSelected}
            setLoadNumber={setLoadNumber}
          />

          <div className="sidebar">
            {negotiationsQty ? (
              <ConversationList
                pagination={pagination}
                filterNegotiations={filterNegotiations}
                deals={dealList}
                click={openConversationItem}
                selected={selectedDeal || {}}
              />
            ) : (
              <Grid
                container
                item
                md={12}
                justifyContent="center"
                style={{ marginTop: '20px' }}
              >
                <div className="conversation-list-item">
                  <p className="conversation-snippet">
                    Não foram encontradas negociações para você.
                  </p>
                </div>
              </Grid>
            )}
          </div>
        </Grid>
        <Grid item md={8} xs={12} className="box-chat">
          <Messenger
            getNegotiationMessages={getNegotiationMessages}
            messages={messages}
            setMessages={setMessages}
            deal={selectedDeal}
            setDeal={setSelectedDeal}
            iniciateButton={iniciateButton}
            setIniciateButton={setIniciateButton}
            requestPreLoad={requestPreLoad}
            setRequestPreLoad={setRequestPreLoad}
            requestPreLoadModal={requestPreLoadModal}
          />
        </Grid>
      </Grid>
    );
  }

  return <>{renderNegotiations(dealList ? dealList.length : 0)}</>;
}

export default Chat;
