/* eslint-disable import/no-unresolved */
import React, { useState, useMemo } from 'react';

import { useTravel } from 'v3/pages/Travel';
import { useSnackbar } from 'v3/components/Snackbar';
import api from 'services/api';

import Modal from 'v3/components/Modal';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import Rating from 'v3/components/Rating';

import ratingImage from './rating.png';
import * as Styled from './styles';

export default function DriverRating({
  isOpen,
  onClose,
}) {
  const [rates, setRates] = useState({
    general: 0,
    app: 0,
    schedules: 0,
  });
  const [isInFlight, setInFlight] = useState(false);

  const travel = useTravel();
  const snackbar = useSnackbar();

  const isCompleted = useMemo(
    () => Object.values(rates).every(rate => rate > 0),
    [rates]
  );

  async function sendDriverRating() {
    try {
      setInFlight(true);
      await api.post(`driver-rating/${travel.data.id}`, {
        rating_general: rates.general,
        rating_schedules: rates.schedules,
        rating_app: rates.app,
      });

      snackbar.show(<Text>Viagem finalizada com sucesso</Text>, {
        type: 'success',
      });
    } catch (ex) {
      if (ex?.response?.status == 403) {
        snackbar.show(<Text>{ex?.response?.data}</Text>, {
          type: 'error',
        });
      } else {
        snackbar.show(<Text>Não foi possível finalizar essa viagem</Text>, {
          type: 'error',
        });
      }
    } finally {
      onClose(false);
      setInFlight(false);
    }
  }

  return (
    <Modal
      show={isOpen}
      handleClose={() => onClose(true)}
      heading={
        <Text type="header" color="dark" weight="500">
          Finalizar viagem
        </Text>
      }
      body={
        <div className="d-flex flex-column align-items-center">
          <Text>Para finalizar essa viagem avalie o motorista</Text>
          <div className="d-flex align-items-center justify-content-center">
            <Styled.Image
              src={ratingImage}
              alt="Imagem de avaliação do motorista"
            />
            <div>
              <div className="mb-4">
                <Text as="div" type="regular" color="gray">
                  De modo geral, como foi a viagem?
                </Text>
                <Rating
                  rate={rates.general}
                  onChange={rate =>
                    setRates(prev => ({ ...prev, general: rate }))
                  }
                />
              </div>
              <div className="mb-4">
                <Text as="div" type="regular" color="gray">
                  Todas as etapas do app foram realizadas?
                </Text>
                <Rating
                  rate={rates.app}
                  onChange={rate => setRates(prev => ({ ...prev, app: rate }))}
                />
              </div>
              <div className="mb-4">
                <Text as="div" type="regular" color="gray">
                  Como foi o cumprimento dos horários?
                </Text>
                <Rating
                  rate={rates.schedules}
                  onChange={rate =>
                    setRates(prev => ({ ...prev, schedules: rate }))
                  }
                />
              </div>
            </div>
          </div>
          <Button
            loading={isInFlight}
            disabled={!isCompleted}
            onClick={sendDriverRating}
            className="py-2"
          >
            <Text type="regular" weight="500">
              Finalizar viagem
            </Text>
          </Button>
        </div>
      }
    />
  );
}
