import * as Yup from 'yup';
import { csvColumns } from './csvColumns';

const rqr = 'é obrigatório';
const num = 'deve ser numérico';

function emptyStringToNull(value, originalValue) {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null;
  }
  return value;
}

export const schema = Yup.array().of(
  Yup.object().shape({
    takerName: Yup.string().required(`Campo '${csvColumns.takerName}' ${rqr}.`),
    takerCNPJ: Yup.string().required(`Campo '${csvColumns.takerCNPJ}' ${rqr}.`),
    takerIsInternational: Yup.string()
      .nullable()
      .matches(/^$|[01]/, `Campo '${csvColumns.takerIsInternational}'.`, {
        excludeEmptyString: true,
      }),
    negotiatorEmail: Yup.string().required(
      `Campo '${csvColumns.negotiatorEmail}' ${rqr}.`
    ),
    negotiatorName: Yup.string().required(
      `Campo '${csvColumns.negotiatorName}' ${rqr}.`
    ),
    product: Yup.string().required(
      `Campo '${csvColumns.product}' ${rqr}.`
    ),
    crawledLoad: Yup.string().matches(
      /^$|[01]/,
      `Campo '${csvColumns.crawledLoad}'.`
    ),
    tolledLoad: Yup.string().matches(
      /^$|[01]/,
      `Campo '${csvColumns.tolledLoad}'.`
    ),
    vehicleTypes: Yup.string()
      .matches(/^[0-9,]+$/, `Campo '${csvColumns.vehicleTypes}' ${num}`)
      .required(`Campo '${csvColumns.vehicleTypes}' ${rqr}.`),
    bodyTypes: Yup.string()
      .matches(/^[0-9,]+$/, `Campo '${csvColumns.bodyTypes}' ${num}`)
      .required(`Campo '${csvColumns.bodyTypes}' ${rqr}.`),
    loadDisposition: Yup.string()
      .matches(/^[0-9,]+$/, `Campo '${csvColumns.loadDisposition}' ${num}`)
      .required(`Campo '${csvColumns.loadDisposition}' ${rqr}.`),
    palletNumber: Yup.string().matches(
      /^(\s*|\d+)$/,
      `Campo '${csvColumns.palletNumber}' ${num}.`
    ),
    loadWeight: Yup.string()
      .matches(/^(\s*|\d+)$/, `Campo '${csvColumns.loadWeight}' ${num}.`)
      .required(`Campo '${csvColumns.loadWeight}' ${rqr}.`),
    loadLiquidWeight: Yup.string()
      .matches(/^(\s*|\d+)$/, `Campo '${csvColumns.loadLiquidWeight}' ${num}.`),
    loadVolume: Yup.string().matches(
      /^(\s*|\d+)$/,
      `Campo '${csvColumns.loadVolume}' ${num}.`
    ),
    loadValue: Yup.string()
      .transform(emptyStringToNull)
      .nullable()
      .matches(/^\d+(,\d+)?$/, `Campo '${csvColumns.loadValue}' ${num}.`),
    fareCompany: Yup.string()
      .transform(emptyStringToNull)
      .nullable()
      .matches(/^\d+(,\d+)?$/, `Campo '${csvColumns.fareCompany}' ${num}.`),
    dueDate: Yup.string().required(`Campo '${csvColumns.dueDate}' ${rqr}.`),
    contactInfo: Yup.string().required(
      `Campo '${csvColumns.contactInfo}' ${rqr}.`
    ),
    originsAddress: Yup.string()
      .transform(emptyStringToNull)
      .nullable()
      .required(`Campo '${csvColumns.originsAddress}' ${rqr}.`),
    originsNumber: Yup.string().matches(
      /^(\s*|\d+)$/,
      `Campo '${csvColumns.originsNumber}' ${num}.`
    ),
    originsCity: Yup.string()
      .transform(emptyStringToNull)
      .nullable()
      .required(`Campo '${csvColumns.originsCity}' ${rqr}.`)
      .test(
        'isMatchingRegex',
        `Campo '${csvColumns.originsCity}' inválido.`,
        value => {
          return /^[A-ZÀ-ŸA-zÀ-ÿ'\s-]+$/gi.test(value);
        }
      ),
    originsProvince: Yup.string()
      .transform(emptyStringToNull)
      .nullable()
      .required(`Campo '${csvColumns.originsProvince}' ${rqr}.`),
    originsScheduledDate: Yup.string()
      .transform(emptyStringToNull)
      .nullable()
      .required(`Campo '${csvColumns.originsScheduledDate}' ${rqr}.`),
    destinationsAddress: Yup.string()
      .transform(emptyStringToNull)
      .nullable()
      .required(`Campo '${csvColumns.destinationsAddress}' ${rqr}.`),
    destinationsNumber: Yup.string().matches(
      /^(\s*|\d+)$/,
      `Campo '${csvColumns.destinationsNumber}' ${num}.`
    ),
    destinationsCity: Yup.string()
      .transform(emptyStringToNull)
      .nullable()
      .required(`Campo '${csvColumns.destinationsCity}' ${rqr}.`)
      .test(
        'isMatchingRegex',
        `Campo '${csvColumns.originsCity}' inválido.`,
        value => {
          return /^[A-ZÀ-ŸA-zÀ-ÿ'\s-]+$/gi.test(value);
        }
      ),
    destinationsProvince: Yup.string()
      .transform(emptyStringToNull)
      .nullable()
      .required(`Campo '${csvColumns.destinationsProvince}' ${rqr}.`),
    destinationsScheduledDate: Yup.string()
      .transform(emptyStringToNull)
      .nullable()
      .required(`Campo '${csvColumns.destinationsScheduledDate}' ${rqr}.`),
    costCenterId: Yup.string()
      .matches(/^[0-9,]+$/, `Campo '${csvColumns.costCenterId}' ${num}`)
      .required(`Campo '${csvColumns.costCenterId}' ${num}.`),
  })
);
