import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Row, Col } from 'react-bootstrap';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';

import { formatPlate } from 'v3/utils/formatter';
import Radio from 'v3/components/Radio';
import api from '../../../../../services/api';

export function AggregatedVehiclesCard({ aggregation, onChangeStatus }) {
  const [open, setOpen] = useState(false);
  const [bodyOptions, setBodyOptions] = useState([]);
  function handleClose() {
    setOpen(false);
  }

  function handleOpen() {
    setOpen(true);
  }

  async function confirmOption() {
    onChangeStatus(aggregation);
    handleClose();
  }
  useEffect(() => {
    async function fetchBodies() {
      const response = await api.get(`body-types?app=${true}`);
      setBodyOptions(response.data);
    }
    fetchBodies();
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title" style={{ color: 'black' }}>
          Atenção!
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ color: '#888' }}>
            Deseja {aggregation.active ? 'desativar ' : 'ativar '} veículo?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button outline={1} onClick={handleClose} variant="secondary">
            Cancelar
          </Button>
          <Button
            outline={1}
            onClick={() => confirmOption()}
            variant="success"
            autoFocus
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      <Row>
        <Col xs={4}>
          <Text type="label" color="#464E5F">
            Placa
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {formatPlate(aggregation?.vehicle?.plate)}
          </Text>
        </Col>
        <Col xs={4}>
          <Text type="label" color="#464E5F">
            Tipo de Carroceria
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {(bodyOptions &&
              bodyOptions.find(
                body => body.id === aggregation?.vehicle?.vehicleBodyTypeId
              )?.name) ||
              'Não informado'}
          </Text>
        </Col>
        <Col xs={4}>
          <Radio.Group
            label="Veículo ativo"
            onChange={() => handleOpen()}
            value={aggregation?.active ? 'Ativo' : 'Inativo'}
            horizontal
          >
            <Radio value="Ativo">
              <Text color="dark" type="label">
                Ativo
              </Text>
            </Radio>
            <Radio value="Inativo">
              <Text color="dark" type="label">
                Inativo
              </Text>
            </Radio>
          </Radio.Group>
        </Col>
      </Row>
    </>
  );
}
