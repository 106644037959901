import styled from 'styled-components';

export const RouteStationText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  color: gray;
  font-size: 0.8rem;
  white-space: pre-wrap;
  cursor: text;
`;

export const RouteStationCheckout = styled.span`
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: green;
    border-radius: 50%;
    margin-right: 5px;
`;

