import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledCardHeader = styled.header`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr auto;
  grid-row-gap: 0.5rem;
  grid-column-gap: 0.5rem;
  .arrowButton {
    background: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .label {
    justify-self: center;
    display: flex;
    align-items: center;
    margin: 0;
    gap: 0.25rem;
  }
  .tagsRow {
    grid-column: span 3;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex-wrap: wrap;
  }
  @media (max-width: 576px) {
    display: flex;
    flex-direction: column;
  }
`;

export const HeaderTotalSummaryContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  & p {
    margin: 0;
  }
  & p:not(:first-child) {
    justify-self: end;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    & p {
      justify-self: start;
    }
    & p:not(:first-child) {
      justify-self: start;
    }
  }
  @media (max-width: 576px) {
    grid-template-columns: 2fr repeat(2, 1fr);
  }
`;

export const LabelsRowCol = styled(Col)`
  text-align: left;
`;

export const StyledCurrencyCol = styled(Col)`
  display: flex;
  gap: 0.25rem;
`;

export const StyledToggleCurrency = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledActionButton = styled.button`
  border: 0;
  cursor: pointer;
  background: transparent;
  padding: 0.5rem;
  display: flex;
  align-items: center;
`;

export const ExtraFieldsContainer = styled.div`
  all: unset;
  max-height: 90px;
  overflow-y: scroll;
  padding: 0.25rem;
  ::-webkit-scrollbar {
    width: 0.5rem;
    height: 0rem;
  }
  ::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.light_gray};
    opacity: 0.5;
  }
  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.gray};
    border-radius: 10px;
  }
`;

export const StyledExtraItemRow = styled(Row)`
  display: flex;
  align-items: center;

  margin-top: 0.5rem;

  & > div:last-child {
    display: flex;
    justify-content: flex-start;
    & > button {
      cursor: pointer;
      padding: 0.25rem 0.5rem;
      background: transparent;
      display: flex;
      align-items: center;
    }
  }
`;

export const CalculatorWrap = styled.div`
  flex: 0 0 35px;
  margin-left: 8px;
`;