import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Button, Input, Select, Text } from 'v3/components';

export function Filter({
  onRefresh,
  onFilterChange,
  statusOptions,
  loading,
  setLoading
}) {
  const [initFilter, setInitFilter] = useState(false);
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [cpf, setCpf] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [status, setStatus] = useState('');
  let bouncy = null;

  function onApplyFilters() {
    onFilterChange({
      id,
      name,
      cpf,
      email,
      phone,
      status
    });
  }

  useEffect(() => {
    if (bouncy) {
      clearInterval(bouncy);
    }
    setLoading(true);
    bouncy = setInterval(() => {
      clearInterval(bouncy);
      if (initFilter) {
        onApplyFilters();
      } else {
        setInitFilter(true);
      }
    }, 1500);

    return () => {
      clearInterval(bouncy);
    };
  }, [ id, name, cpf, email, phone, status ]);

  useEffect(() => {
    function onPressedKey(e) {
      if (e.key === 'Enter') {
        onApplyFilters();
      }
    }
    window.addEventListener('keyup', onPressedKey);
    return () => {
      window.removeEventListener('keyup', onPressedKey);
    };
  });

  return (
    <>
      <Row className="my-4">
        <Col md={1} sm={2}>
          <Input
            label="ID do Usuario"
            labelColor="#fff"
            value={id}
            onChange={e => {
              setId(e.target.value);
            }}
          />
        </Col>
        <Col md={2} sm={4}>
          <Input
            label="Nome do Motorista"
            labelColor="#fff"
            value={name}
            onChange={e => {
              setName(e.target.value);
            }}
          />
        </Col>
        <Col md={2} sm={4}>
          <Input
            label="CPF"
            labelColor="#fff"
            value={cpf}
            onChange={e => {
              setCpf(e.target.value);
            }}
          />
        </Col>
        <Col md={2} sm={4}>
          <Input
            label="Telefone"
            labelColor="#fff"
            value={phone}
            onChange={e => {
              setPhone(e.target.value);
            }}
          />
        </Col>
        <Col md={2} sm={4}>
          <Input
            label="E-mail"
            labelColor="#fff"
            value={email}
            onChange={e => {
              setEmail(e.target.value);
            }}
          />
        </Col>
        <Col md={2} sm={4}>
          <Select
            labelWhiteSpace="nowrap"
            label="Status"
            labelTextColor="#fff"
            modalHeading="Todos"
            modalBodyTitle="Logs:"
            disableClear="true"
            disableSearch="true"
            horizontal
            value={status}
            onChange={e => setStatus(e)}
            getOptionLabel={option => option?.name}
            getOptionValue={option => option?.name}
            options={statusOptions}
          />
        </Col>
        <Col md={1} sm={12} style={{ display: 'flex', alignItems: 'end' }}>
          <Button
            loading={loading}
            style={{ flex: 1 }}
            variant="success"
            onClick={() => onRefresh()}
          >
            <Text type="regular" weight={500}>
              Atualizar
            </Text>
          </Button>
        </Col>
      </Row>
    </>
  );
}
