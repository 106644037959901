import React, { useMemo, useState } from 'react';
import { Modal, Text } from 'v3/components';
import { StyledMarginContainer, StyledMarginText } from '../styles';
import Lightbox from 'react-image-lightbox';
import PDFView from 'v3/components/PDFView';
import { FaDownload, FaSearch } from 'react-icons/fa';
import { formatDateTime } from 'v3/utils/formatter';
import * as Styled from './styles';
import StringBuilder from 'utils/StringBuilder';
import { Backdrop, DateTime } from '../../styles';

export function AuditDetail({ selected }) {
  if (!selected?.id) {
    return <></>;
  }
  return (
    <Styled.AuditDetailContainer>
      <Styled.MainSection>
        <Styled.SummarySection>
          <div>
            <Text type="label" color="dark" weight="500" as="p">
              Comparativo de Margem
            </Text>
            <StyledMarginContainer>
              <StyledMarginText type="regular" color="dark">
                Mínima{' '}
                <span>
                  {selected?.imported_margin}
                  {'%'}
                </span>
              </StyledMarginText>

              <StyledMarginText type="regular" color="dark">
                Viagem{' '}
                <span>
                  {selected?.calculated_margin}
                  {'%'}
                </span>
              </StyledMarginText>
            </StyledMarginContainer>
          </div>
          <div>
            <Text type="label" color="dark" weight="500" as="p">
              Justificativa Liberação Margem
            </Text>
            <StyledMarginText type="regular" color="dark">
              {selected?.justification?.name}
            </StyledMarginText>
          </div>
        </Styled.SummarySection>
        <div>
          {selected?.attachment_id && (
            <>
              <Text type="label" color="dark" weight="500" as="p">
                Anexo
              </Text>
              <AttachmentDetails
                attachment={selected?.attachment}
                attachments={[selected?.attachment]}
              />
            </>
          )}
        </div>
      </Styled.MainSection>
      <div className="mt-4">
        <Text type="label" color="dark" weight={500} as={'p'}>
          Observação
        </Text>
        <Text type="regular" color="dark">
          {selected?.description}
        </Text>
      </div>
    </Styled.AuditDetailContainer>
  );
}

function AttachmentDetails({ attachment, attachments }) {
  const [isHovering, setHovering] = useState(false);
  const [isShowingModal, setShowingModal] = useState(false);
  const customReactModalStyle = {
    overlay: {
      zIndex: 2000,
    },
  };

  const attachmentsWithoutPDF = useMemo(() => {
    return attachments.filter(item => {
      const filterPDF = item?.full_path?.split('.');
      if (filterPDF[filterPDF?.length - 1] === 'pdf') return null;
      return item;
    });
  }, [attachments]);

  const [lightbox, setLightbox] = useState({
    isOpen: false,
    attachments: attachmentsWithoutPDF,
    index: 0,
  });

  const lightboxIndexes = useMemo(() => {
    const next = (lightbox.index + 1) % lightbox.attachments?.length;
    const previous =
      (lightbox.index + lightbox.attachments?.length - 1) %
      lightbox.attachments?.length;
    return { next, previous };
  }, [lightbox.index, lightbox.attachments]);

  function onClickPreview(id) {
    const index = id ? attachmentsWithoutPDF.findIndex(a => a.id === id) : 0;

    setLightbox(prev => ({
      ...prev,
      index,
      attachments: attachmentsWithoutPDF,
      isOpen: true,
    }));
  }

  function handleClick() {
    const fullPathArray = attachment?.full_path?.split('.');

    if (fullPathArray[fullPathArray?.length - 1] === 'pdf') {
      setShowingModal(true);
    } else {
      onClickPreview(attachment.id);
    }
  }

  function getImagePath(index, alwaysDisplay) {
    const isSameAsIndex =
      lightbox.index === lightboxIndexes.next &&
      lightbox.index === lightboxIndexes.previous;

    if (
      !alwaysDisplay &&
      isSameAsIndex &&
      lightbox?.attachments?.length === 1
    ) {
      return null;
    }

    return lightbox.attachments[index]?.full_path;
  }

  function getDocumentMetadata() {
    const document = lightbox.attachments[lightbox.index];
    const type = document?.description || 'Sem descrição';
    const createdAt = formatDateTime(document?.created_at);

    return { type, createdAt };
  }

  const lightboxTitle = useMemo(() => {
    const builder = new StringBuilder(' ');
    const documentMetadata = getDocumentMetadata();
    builder
      .append(`${lightbox.index + 1}/${lightbox.attachments?.length}`)
      .append('|')
      .append(documentMetadata?.type)
      .append(`(${documentMetadata?.createdAt})`);

    return builder.build();
  }, [lightbox.index, lightbox?.attachments]);

  return (
    <div>
      <Modal
        show={isShowingModal}
        dialogClassName="modal-90w"
        onHide={() => setShowingModal(false)}
        heading="Visualizar PDF"
        body={<PDFView file={attachment?.full_path} />}
      />
      <div
        className="position-relative"
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        style={{
          width: 270,
          height: 160,
          background: `url(${
            attachment?.full_path?.split('.').pop() === 'pdf'
              ? '/images/default-doc.png'
              : attachment?.full_path?.split('.').pop() === 'xlsx'
              ? '/images/default-excel.png'
              : attachment?.full_path?.split('.').pop() === 'xls'
              ? '/images/default-excel.png'
              : attachment?.full_path
          }) center center`,
          backgroundSize: '100%',
        }}
      >
        {lightbox.isOpen && (
          <Lightbox
            mainSrc={getImagePath(lightbox?.index, true)}
            nextSrc={getImagePath(lightboxIndexes?.next)}
            prevSrc={getImagePath(lightboxIndexes?.previous)}
            onCloseRequest={() =>
              setLightbox(prev => ({ ...prev, isOpen: false }))
            }
            onMovePrevRequest={() =>
              setLightbox(prev => ({
                ...prev,
                index: lightboxIndexes.previous,
              }))
            }
            onMoveNextRequest={() =>
              setLightbox(prev => ({
                ...prev,
                index: lightboxIndexes.next,
              }))
            }
            imageTitle={lightboxTitle}
            reactModalStyle={customReactModalStyle}
          />
        )}
        {isHovering && (
          <Backdrop>
            {attachment?.full_path?.split('.').pop() !== 'xlsx' &&
              attachment?.full_path?.split('.').pop() !== 'xls' && (
                <button type="button" onClick={handleClick}>
                  <FaSearch color="#fff" size={32} />
                </button>
              )}
            <a href={attachment?.full_path} download>
              <FaDownload color="#fff" size={32} />
            </a>
          </Backdrop>
        )}

        <DateTime>
          <Text type="label" color="white">
            {formatDateTime(attachment?.created_at)}
          </Text>
        </DateTime>
      </div>
    </div>
  );
}
