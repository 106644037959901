/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';

import { validatePermission } from 'actions/index';
import { Row, Col } from 'react-bootstrap';

import Text from 'v3/components/Text';
import Select from 'v3/components/Select';
import Accordion from 'v3/components/Accordion';
import { useSnackbar } from 'v3/components/Snackbar';
import { DefaultLoadingBodyComponent } from 'v3/components/Card';

import { StyledButton } from './styles';
import * as Controller from './controller';

export default function Automations({
  load,
  loadId,
  getLoad,
  automations,
  loadingCard,
}) {
  const [selectedAutomations, setSelectedAutomations] = useState();
  const [isEditing, setEditing] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [options, setOptions] = useState(false);
  const snackbar = useSnackbar();

  const [hasPermissionToEdit, setPermissionToEdit] = useState();
  useState(() => {
    setPermissionToEdit(validatePermission('EDITAR_CARGAS'));
  }, []);

  useEffect(() => {
    setSelectedAutomations(automations);
  }, [automations]);

  async function save() {
    try {
      setLoading(true);
      await Controller.updateAutomations(selectedAutomations, loadId);
      getLoad();
    } catch (e) {
      snackbar.show(<Text>Erro ao atualizar automações.</Text>, {
        type: 'error',
      });
    } finally {
      setLoading(false);
      setEditing(false);
    }
  }

  useEffect(() => {
    async function getLoadAutomations() {
      try {
        setOptions(await Controller.getLoadAutomations());
      } catch (error) {
        // Handle exception
      }
    }
    getLoadAutomations();
  }, []);

  return (
    <Col xs={12}>
      <Accordion
        loading={loadingCard}
        title="Automações"
        closedText="Ver automações"
        closeButtonText="Fechar"
        cardProps={{
          loading: loadingCard,
          LoadingBodyComponent: () => (
            <DefaultLoadingBodyComponent linesCount={1} perLine={1} />
          ),
        }}
      >
        <Row className="form">
          <Col lg={6} md={6} xs={12}>
            <Select
              isDisabled={!isEditing}
              label="Automações"
              value={selectedAutomations}
              onChange={value => setSelectedAutomations(value)}
              multiple
              options={options}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
            />
          </Col>
          <Col xs={6}>
            {hasPermissionToEdit && !load?.travel && (
              <StyledButton
                onClick={isEditing ? save : () => setEditing(true)}
                variant={isEditing ? 'success' : 'secondary'}
                loading={isLoading}
              >
                <Text weight={500} type="regular">
                  {isEditing ? 'Salvar' : 'Editar'}
                </Text>
              </StyledButton>
            )}
          </Col>
        </Row>
      </Accordion>
    </Col>
  );
}
