import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useSnackbar } from 'v3/components/Snackbar';
import Text from 'v3/components/Text';

import api from 'services/api';

import Details from '../Details';
import Register from '../Register';

import { ClientProvider } from './context';
import { validatePermission } from 'actions';

const SETTINGS_SLUGS = ['OBSDEFAULT', 'PRIORITY_FLEET_LOAD_DURATION'];

export default function ClientShared() {
  const snackbar = useSnackbar();
  const dispatch = useDispatch();

  const hasPermissionToEdit = validatePermission('EDITAR_CLIENTES');

  const [client, setClient] = useState({ priorityFleetVehicles: [] });
  const [isFetching, setFetching] = useState(false);
  const [errors, setErrors] = useState({});
  const [prevClient, setPrevClient] = useState({});
  const [trackingEmailsList, setTrackingEmailsList] = useState([]);

  const params = useParams();

  function showSnackbar(text, options) {
    snackbar.show(<Text> {text} </Text>, options);
  }

  function setData(data) {
    setClient(prev => ({ ...prev, ...data }));
  }

  useEffect(() => {
    async function fetchClient() {
      try {
        setFetching(true);
        const response = await api.get(`v3/client/${params.id}`);

        const { settings } = response.data;

        SETTINGS_SLUGS.forEach(slug => {
          if (!settings.find(item => item.type === slug)) {
            settings.push({
              person_id: response.data.id,
              type: slug,
              value: '',
              company_id: response.data.company_id,
            });
          }
        });

        setData({
          ...response.data,
          settings,
        });

        setPrevClient(prev => ({
          ...prev,
          ...response.data,
          settings,
        }));
      } catch (ex) {
        snackbar.show(<Text>Não foi possível carregar esse cliente</Text>, {
          type: 'error',
        });
      } finally {
        setFetching(false);
      }
    }

    if (params.id) {
      fetchClient();
    }
  }, [params.id]);

  useEffect(() => {
    if (params.id) {
      dispatch({
        type: 'VIEW_INSTANCE',
        instance: client.label,
      });
    }
  }, [client]);

  return (
    <ClientProvider
      value={{
        data: client,
        setData,
        isFetching,
        errors,
        setErrors,
        showSnackbar,
        prevClient,
        setPrevClient,
        trackingEmailsList,
        setTrackingEmailsList,
        canEditData: hasPermissionToEdit,
      }}
    >
      {params.id ? <Details /> : <Register />}
    </ClientProvider>
  );
}
