/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, NavDropdown, Navbar, Nav, NavLink } from 'react-bootstrap';
import { FaBell, FaBullhorn, FaFileAlt } from 'react-icons/fa';
import { validatePermission } from 'actions/index';
import Text from '../Text';
import Tooltip from '../Tooltip';
import Notifications, { useNotificationsController } from './Notifications';
import * as Controller from './controller';
import * as Styled from './styles';
import Company from './Company';

export default function Menu() {
  const [versionState, setVersionState] = useState(
    JSON.parse(localStorage.getItem('version'))
  );
  const versionTime = `${process.env.REACT_APP_BUILD_NUMBER}`;
  const { unread, fetchUnreadNotifications } = useNotificationsController();

  const app_version = `${process.env.REACT_APP_BUILD_NUMBER || 'dev'}`;
  const user = JSON.parse(localStorage.getItem('usuario'));
  useEffect(() => {
    fetchUnreadNotifications();
  }, []);

  return (
    <>
      <Styled.Navbar sticky="top" collapseOnSelect expand="lg">
        <Navbar.Brand id="menu-inicio" href="/" className="mr-3">
          <img
            src="/images/Logo-Cooperplace-Branca.png"
            alt="logo escrito Cooperplace"
            className="mb-1"
            width="150px"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className="nav-header">
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-end',
              gap: 4,
              alignItems: 'center',
            }}
          >
            {validatePermission('VER_ATUALIZACOES') && (
              <Dropdown>
                <Tooltip
                  content={
                    <Text type="label">
                      Confira as ultimas atualizações do sistema
                    </Text>
                  }
                  placement="bottom"
                >
                  <NavLink
                    onClick={() => {
                      setVersionState(versionTime);
                      Controller.versionUpdate(versionTime);
                    }}
                    target="_blank"
                    href="https://coopercarga.sharepoint.com/sites/cooperplacenews?OR=Teams-HL&CT=1706613390339&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiI0OS8yNDAxMDQxOTExNCIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D"
                  >
                    <FaBullhorn color="#fff" />
                    {app_version !== versionState ? (
                      <Styled.VersionUnreadIcon />
                    ) : (
                      ''
                    )}
                  </NavLink>
                </Tooltip>
              </Dropdown>
            )}
            {validatePermission('REVISAR_DOCUMENTOS') && (
              <Tooltip
                content={<Text type="label">Revisar documentos</Text>}
                placement="bottom"
              >
                <NavLink as={Link} to="/revisar-documentos">
                  <FaFileAlt color="#fff" />
                </NavLink>
              </Tooltip>
            )}
            <Dropdown>
              <Dropdown.Toggle
                as={React.forwardRef(({ onClick }, ref) => (
                  <NavLink ref={ref} onClick={onClick}>
                    <FaBell color="#fff" />
                    {unread && <Styled.NotificationUnreadIcon />}
                  </NavLink>
                ))}
              />
              <Dropdown.Menu as={Notifications} />
            </Dropdown>
            <Company />
            <Nav>
              <Styled.NavDropdown
                title={user?.username ? user.username.split(' ')[0] : 'Conta'}
                className="nav-header profile"
              >
                <NavDropdown.Item as={Link} to="/conta">
                  <span>Ver perfil</span>
                </NavDropdown.Item>

                <NavDropdown.Item as={Link} onClick={Controller.logout} to="#">
                  <span>Sair</span>
                </NavDropdown.Item>
              </Styled.NavDropdown>
            </Nav>
          </div>
        </Navbar.Collapse>
      </Styled.Navbar>
    </>
  );
}
