import React, { forwardRef } from 'react';

import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import Input from 'v3/components/Input';

const currencyMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: '.',
  allowDecimal: true,
  decimalSymbol: ',',
  decimalLimit: 2,
  integerLimit: 7,
  allowNegative: false,
  allowLeadingZeroes: true,
};

export const currencyMask = createNumberMask({ ...currencyMaskOptions });

function CurrencyInput({ mask = currencyMask, ...props }, ref) {
  return <Input ref={ref} {...props} masked mask={mask} />;
}

export default forwardRef(CurrencyInput);
