import React, { useState, useEffect } from 'react';

import Card from 'v3/components/Card';
import Text from 'v3/components/Text';
import Input from 'v3/components/Input';
import Table from 'v3/components/Table';
import Button from 'v3/components/Button';

import { Row, Col } from 'react-bootstrap';
import { FaTrash, FaPencilAlt } from 'react-icons/fa';

import { validate } from './controller';
import { IssuerContext } from '../controller';

const [, useIssuer] = IssuerContext;

export default function Mdfe() {
  const issuer = useIssuer();

  const [series, setSeries] = useState();
  const [number, setNumber] = useState();

  const [seriesList, setSeriesList] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    issuer.setData(old => ({ ...old, mdfe_series: seriesList }));
  }, [seriesList]);

  const addSeries = async () => {
    issuer.setErrors(old => ({ ...old, mdfe_series: null }));
    if (!(await validate({ series, number }, setErrors))) {
      return false;
    }

    setSeriesList(old => [...old, { series, number }]);
    setSeries('');
    setNumber('');

    return true;
  };

  const handleRemoveSeries = index => {
    const aux = [...seriesList];
    aux.splice(index, 1);

    setSeriesList(aux);
  };

  const handleEditSeries = index => {
    const item = seriesList[index];
    setSeries(item.series);
    setNumber(item.number);

    handleRemoveSeries(index);
  };

  return (
    <Card header={<Text type="header">MDFe</Text>}>
      <Col>
        <Row className="d-flex align-items-start">
          <Col xs={4}>
            <Input
              label="Série *"
              value={series}
              onChange={event => setSeries(event.target.value)}
              error={issuer.errors.mdfe_series || errors.series}
            />
          </Col>
          <Col xs={4}>
            <Input
              label="Número *"
              value={number}
              onChange={event => setNumber(event.target.value)}
              error={errors.number}
            />
          </Col>
          <Col xs={4}>
            <Button
              variant="primary"
              className="w-100 mb-4"
              onClick={async () => addSeries()}
              style={{ marginTop: '35px' }}
            >
              <Text weight={600} type="regular">
                Adicionar
              </Text>
            </Button>
          </Col>
        </Row>
        <Row>
          {seriesList.length > 0 && (
            <Table responsive>
              <thead>
                <tr>
                  <th>
                    <Text type="label" weight="500">
                      Série
                    </Text>
                  </th>
                  <th className="text-center">
                    <Text type="label" weight="500">
                      Número
                    </Text>
                  </th>
                  <th className="text-center">
                    <Text type="label" weight="500">
                      Ação
                    </Text>
                  </th>
                </tr>
              </thead>
              <tbody>
                {seriesList.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <Text type="regular" weight="400">
                        {item.series}
                      </Text>
                    </td>
                    <td width="240" className="text-center">
                      <Text type="regular">{item.number}</Text>
                    </td>
                    <td width="120" className="text-center">
                      <Button
                        variant="primary"
                        size="sm"
                        className="mr-1"
                        onClick={() => handleEditSeries(index)}
                      >
                        <FaPencilAlt />
                      </Button>
                      <Button
                        variant="error"
                        size="sm"
                        onClick={() => handleRemoveSeries(index)}
                      >
                        <FaTrash />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Row>
      </Col>
    </Card>
  );
}
