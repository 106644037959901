import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Select from 'react-select';
import Async from 'react-select/async';
import moment from 'moment';
import { number_format, downloadCSV } from 'functions';
import { validatePermission } from 'actions/index';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import LinearProgress from '@material-ui/core/LinearProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import api from 'services/api';
import InfiniteScroll from 'components/InfiniteScroll';
import InfoMessage from 'components/InfoMessage';
import Label from 'components/Label';
import Snackbar from 'components/Snackbar';
import Card from 'components/Card';
import Button from 'components/Button';
import CardCobranca from './CardCobranca';
import FilterSelector from './FilterSelector';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

let token = 1;

const months = [
  { label: 'Janeiro', value: 1 },
  { label: 'Fevereiro', value: 2 },
  { label: 'Março', value: 3 },
  { label: 'Abril', value: 4 },
  { label: 'Maio', value: 5 },
  { label: 'Junho', value: 6 },
  { label: 'Julho', value: 7 },
  { label: 'Agosto', value: 8 },
  { label: 'Setembro', value: 9 },
  { label: 'Outubro', value: 10 },
  { label: 'Novembro', value: 11 },
  { label: 'Dezembro', value: 12 },
];

function ReportCobrancas() {
  const history = useHistory();
  const query = useQuery();

  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [clients, setClients] = useState([]);
  const [selectedTag, setSelectedTag] = useState({});
  const [clientValues, setClientValues] = useState([]);
  const [isAllClients, setIsAllClients] = useState(false);
  const [isByClient, setIsByClient] = useState(false);
  const [isByTag, setIsByTag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clientChecked, setClientChecked] = useState([]);
  const [companyChecked, setCompanyChecked] = useState([]);
  const [loadingBilling, setLoadingBilling] = useState(false);
  const [loadingExportBilling, setLoadingExportBilling] = useState(false);
  const [total, setTotal] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState();
  const [selectedYear, setSelectedYear] = useState('2021');
  const [loadsCount, setLoadsCount] = useState();
  const [loadsValue, setLoadsValue] = useState();
  const [loadsCanceled, setLoadsCanceled] = useState({
    qty: 0,
    sum: 0,
  });
  const [travelsCount, setTravelsCount] = useState();
  const [travelsValue, setTravelsValue] = useState();
  const [showTotals, setShowTotals] = useState(false);
  const [errors, setErrors] = useState({});
  const [isClientSelectorHidden, setIsClientSelectorHidden] = useState(true);
  const [isTagSelectorHidden, setIsTagSelectorHidden] = useState(true);
  const [completed, setCompleted] = useState(0);
  const [data, setData] = useState([]);
  const [snackBar, setSnackBar] = useState({
    display: false,
    type: 'error',
    message: '',
  });
  const [filter, setFilter] = useState('');

  const hasPermissionToAcess = validatePermission('VISUALIZAR_COBRANCAS');

  useEffect(() => {
    if (!hasPermissionToAcess) window.location.href = '/';
  }, []);

  useEffect(() => {
    getCompanies();
    getClients();
  }, []);

  useEffect(() => {
    if (query.get('company_id')) {
      getSelectedCompany(query.get('company_id'));
    }
    if (query.get('month')) {
      getSelectedMonth(query.get('month'));
    }
    if (query.get('year')) {
      setSelectedYear(query.get('year'));
    } else {
      query.set('year', selectedYear);
    }

    if (query.getAll('clients')) {
      setClientChecked(query.getAll('clients'));
    }
  }, []);

  useEffect(() => {
    if (clients) {
      setClientValues(clients.filter(client => clients.includes(client.id)));
    }
  }, [clients]);

  useEffect(() => {
    if (clients.length && clientChecked.length) {
      setClientValues(
        clients.filter(client => clientChecked.includes(client.id))
      );
    }
  }, [clientChecked, clients]);

  useEffect(() => {
    if (data.length > 0 && !loadingExportBilling) {
      processDataCSV(data);
    }
  }, [loadingExportBilling, data]);

  async function getReport() {
    setLoadingBilling(true);
    if (!Number(query.get('company_id'))) {
      setSnackBar({
        display: true,
        type: 'error',
        message: `Selecione uma empresa`,
      });
      setLoadingBilling(false);
      return;
    }

    if (!Number(query.get('month'))) {
      setSnackBar({
        display: true,
        type: 'error',
        message: `Insira o mês`,
      });
      setLoadingBilling(false);
      return;
    }

    if (!Number(query.get('year'))) {
      setSnackBar({
        display: true,
        type: 'error',
        message: `Insira um ano`,
      });
      setLoadingBilling(false);
      return;
    }

    if (!isAllClients && !isByClient && !isByTag) {
      setSnackBar({
        display: true,
        type: 'error',
        message: `Escolha uma opção de filtro`,
      });
      setLoadingBilling(false);
      return;
    }

    if (isByClient && clientChecked.length === 0) {
      setSnackBar({
        display: true,
        type: 'error',
        message: `Selecione pelo menos um cliente`,
      });
      setLoadingBilling(false);
      return;
    }

    if (isByTag && !selectedTag) {
      setSnackBar({
        display: true,
        type: 'error',
        message: `Selecione uma tag`,
      });
      setLoadingBilling(false);
      return;
    }

    let clientsByTag;
    if (isByTag) {
      clientsByTag = await api.get('tag-clients', {
        params: { tag: selectedTag.value, token },
      });

      clientsByTag.data.forEach(client => query.append('clients', client.id));

      setupQueryParams();
    }

    token++;

    const start_date = moment(
      `${query.get('year')}-${selectedMonth}`,
      'YYYY-MM'
    );

    const filterParams = new URLSearchParams();
    filterParams.set(
      'start_date',
      moment(start_date).startOf('month').toISOString()
    );
    filterParams.set(
      'end_date',
      moment(start_date).endOf('month').toISOString()
    );
    filterParams.set('clients', query.getAll('clients'));
    filterParams.set('company_id', query.get('company_id'));

    if (selectedTag?.value) {
      filterParams.set('tag', selectedTag.value);
    }

    setFilter(filterParams.toString());

    const response = await api.get('billing-totals', {
      params: {
        clients: query.getAll('clients'),
        company_id: query.get('company_id'),
        tag: selectedTag?.value || undefined,
        start_date: moment(start_date).startOf('month').toISOString(),
        end_date: moment(start_date).endOf('month').toISOString(),
        token,
      },
    });

    if (response.data) {
      const { data } = response;
      setShowTotals(true);
      setLoadsCount(data.loads_qty);
      setLoadsValue(data.loads_fare_company_sum || 0);
      setTravelsCount(data.travels_qty);
      setTravelsValue(data.travels_balance_sum || 0);
      setLoadsCanceled({
        qty: data.loads_canceled_qty,
        sum: data.loads_canceled_fare_company_sum,
      });
    } else {
      setShowTotals(false);
      setLoadsCount(0);
      setLoadsValue(0);
      setTravelsCount(0);
      setTravelsValue(0);
      setLoadsCanceled({
        qty: 0,
        sum: 0,
      });
    }

    setLoadingBilling(false);
  }

  async function getSelectedCompany(id) {
    try {
      const response = await api.get(`company/${id}`);
      setSelectedCompany(response.data[0]);
    } catch (ex) {
      // Ignore exeception
    }
  }

  function getSelectedMonth(i) {
    setSelectedMonth(months[i - 1]);
  }

  function setupQueryParams() {
    const pathname = 'cobrancas';
    history.push({ pathname, search: query.toString() });
  }

  const promiseFilterTag = async search => {
    if (search.length > 3) {
      const response = await api.get('load-tags', {
        params: { search, company_id: query.get('company_id') },
      });

      return response.data.map(el => ({
        value: el.id,
        label: el.name,
      }));
    }
  };

  function handleSelectCompany(selected) {
    setSelectedCompany(selected);
    if (selected) {
      const mapped = selected.map(item => item.id);
      setCompanyChecked(mapped);
      query.set('company_id', mapped.join(','));
    } else {
      query.delete('company_id');
      setCompanyChecked([]);
    }
    setupQueryParams();
  }

  const handleSelectMonth = (_, selectedMonth) => {
    setSelectedMonth(selectedMonth);
    if (selectedMonth) {
      query.set('month', selectedMonth);
      const start_date = moment(
        `${query.get('year')}-${selectedMonth}`,
        'YYYY-MM'
      );

      query.set(
        'start_date',
        moment(start_date).startOf('month').toISOString()
      );
      query.set('end_date', moment(start_date).endOf('month').toISOString());
    } else {
      query.delete('month');
    }
    setupQueryParams();
  };

  const handleSelectYear = (_, selectedYear) => {
    setSelectedYear(selectedYear);
    if (selectedYear) {
      query.set('year', selectedYear);
    } else {
      query.delete('year');
    }
    setupQueryParams();
  };

  async function getCompanies() {
    try {
      setLoading(true);
      const response = await api.get('company');
      setCompanies(response.data);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  function handleAllClientsOption() {
    setIsClientSelectorHidden(true);
    setIsTagSelectorHidden(true);
    setIsAllClients(true);
    setIsByClient(false);
    setIsByTag(false);

    setClientValues([]);
    setClientChecked([]);
    setSelectedTag(null);
    query.delete('clients');
    setupQueryParams();
  }

  function handleByClientOption() {
    setIsClientSelectorHidden(false);
    setIsTagSelectorHidden(true);
    setIsAllClients(false);
    setIsByClient(true);
    setIsByTag(false);

    setClientValues([]);
    setClientChecked([]);
    setSelectedTag(null);
    query.delete('clients');
    setupQueryParams();
  }

  function handleByTag() {
    setIsClientSelectorHidden(true);
    setIsTagSelectorHidden(false);
    setIsAllClients(false);
    setIsByClient(false);
    setIsByTag(true);

    setClientValues([]);
    setClientChecked([]);
    query.delete('clients');
    setupQueryParams();
  }

  function handleNoneOption() {
    setIsClientSelectorHidden(true);
    setIsTagSelectorHidden(true);
    setIsAllClients(false);
    setIsByClient(false);
    setIsByTag(false);

    setClientValues([]);
    setClientChecked([]);
    setSelectedTag();
    query.delete('clients');
    query.set('year', selectedYear);
    setupQueryParams();
  }

  function handleSelectClient(selected) {
    setClientValues(selected);

    if (selected) {
      const mapped = selected.map(item => {
        query.append('clients', item.id);
        return item.id;
      });
      setClientChecked(mapped);
    } else {
      query.delete('clients');
      setClientChecked([]);
    }

    setupQueryParams();
  }

  async function getClients() {
    setLoading(true);
    const usuario = localStorage.getItem('usuario');
    const jsonU = JSON.parse(usuario);
    const response = await api.get(`clients/user/${jsonU.id}`);
    if (response.status === 200) {
      setClients(response.data.clients);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }

  function handleFilterOptionSelected(option) {
    if (option === 1) {
      handleAllClientsOption();
    } else if (option === 2) {
      handleByClientOption();
    } else if (option === 3) {
      handleByTag();
    } else {
      handleNoneOption();
    }
  }
  function processDataCSV(dataLoaded = []) {
    const csv = dataLoaded.map(el => ({
      Tomador: el.social_name,
      'Qtd cargas lançadas': el.loads_qty,
      'Total cargas lançadas': el.loads_fare_company_sum || 0,
      'Qtd cargas canceladas': el.loads_canceled_qty,
      'Total cargas canceladas': el.loads_canceled_fare_company_sum || 0,
      'Qtd viagens': el.travels_qty,
      'Total viagens': el.travels_balance_sum || 0,
    }));

    const csvData = csv.map(el => Object.values(el));

    const header =
      'Tomador;Qtd cargas lançadas;Total cargas lançadas;Qtd cargas canceladas;Total cargas canceladas;Qtd viagens;Total viagens';

    downloadCSV(
      header,
      csvData,
      `cobrancas-${moment().format('DD-MM-YYYY')}.csv`,
      'text/csv;encoding:utf-8'
    );
  }

  async function nextPage(page = 1) {
    const start_date = moment(
      `${query.get('year')}-${selectedMonth}`,
      'YYYY-MM'
    );

    try {
      const response = await api.get('report-billings', {
        params: {
          clients: query.getAll('clients'),
          company_id: query.get('company_id'),
          tag: selectedTag?.value || undefined,
          start_date: moment(start_date).startOf('month').toISOString(),
          end_date: moment(start_date).endOf('month').toISOString(),
          page,
        },
      });

      setData(oldData => [...oldData, ...response.data.data]);
      setCompleted((response.data.page / response.data.lastPage) * 100);
    } catch (e) {}
  }

  async function loadExportData(page = 1) {
    setCompleted(0);
    setData([]);

    if (!isAllClients && !isByClient && !isByTag) {
      setSnackBar({
        display: true,
        type: 'error',
        message: `Escolha uma opção de filtro`,
      });
      setLoadingBilling(false);
      return;
    }

    if (isByClient && clientChecked.length === 0) {
      setSnackBar({
        display: true,
        type: 'error',
        message: `Selecione pelo menos um cliente`,
      });
      setLoadingBilling(false);
      return;
    }

    const start_date = moment(
      `${query.get('year')}-${selectedMonth}`,
      'YYYY-MM'
    );

    try {
      const response = await api.get('report-billings', {
        params: {
          clients: !isAllClients ? query.getAll('clients') : undefined,
          company_id: query.get('company_id'),
          tag: selectedTag?.value || undefined,
          start_date: moment(start_date).startOf('month').toISOString(),
          end_date: moment(start_date).endOf('month').toISOString(),
          page,
        },
      });

      if (response.data.total === 0) {
        setSnackBar({
          display: true,
          type: 'error',
          message: 'Sem resultados para o período selecionado',
        });

        return;
      }

      setLoadingExportBilling(true);
      const ids = Array.from(
        { length: response.data.lastPage },
        (v, k) => k + 1
      );
      await ids.reduce((p, x) => p.then(_ => nextPage(x)), Promise.resolve());

      setLoadingExportBilling(false);
    } catch (e) {}
  }

  return (
    <>
      <Snackbar
        open={snackBar.display}
        type={snackBar.type}
        message={snackBar.message}
        onClose={() => setSnackBar(false)}
      />
      <Dialog
        open={loadingExportBilling}
        maxWidth="sm"
        fullWidth
        minWidth="sm"
        aria-labelledby="Relatório de cobranças"
      >
        <DialogTitle>Relatório</DialogTitle>
        <DialogContent>
          Gerando relatório
          <LinearProgress
            variant="determinate"
            value={completed}
            color="primary"
          />
          <br />
        </DialogContent>
      </Dialog>

      <Card
        style={{ marginTop: '10px', marginBottom: '10px', overflow: 'visible' }}
      >
        <Grid container spacing={2}>
          <Grid item lg={10} md={10} xs={12}>
            <Label className="textLabel">
              {companies.length && companyChecked.length === 0
                ? 'Selecione uma Empresa'
                : 'Empresa Selecionada'}
            </Label>
            <Select
              options={companies}
              placeholder="Selecione"
              getOptionLabel={option => `${option.name}`}
              getOptionValue={option => option.id}
              value={selectedCompany}
              onChange={e => {
                handleSelectCompany([e]);
                setErrors({ ...errors, companies: '' });
              }}
              loading={loading}
            />
            {errors.companies && (
              <p className="help is-danger">{errors.companies}</p>
            )}
          </Grid>

          <Grid item lg={5} md={5} xs={12}>
            <Label className="textLabel">Selecione um ano</Label>

            <ToggleButtonGroup
              value={selectedYear}
              exclusive
              onChange={handleSelectYear}
            >
              <ToggleButton value="2020">2020</ToggleButton>
              <ToggleButton value="2021">2021</ToggleButton>
            </ToggleButtonGroup>

            {errors.year && <p className="help is-danger">{errors.year}</p>}
          </Grid>

          <Grid item lg={10} md={10} xs={12}>
            <Label className="textLabel">Selecione um mês</Label>

            <ToggleButtonGroup
              value={selectedMonth}
              exclusive
              onChange={handleSelectMonth}
              className="toogleExtratoCobrancas"
            >
              <ToggleButton value="1" style={{ left: '-1px' }}>
                Janeiro
              </ToggleButton>
              <ToggleButton value="2" style={{ left: '-1px' }}>
                Fevereiro
              </ToggleButton>
              <ToggleButton value="3" style={{ left: '-1px' }}>
                Março
              </ToggleButton>
              <ToggleButton value="4">Abril</ToggleButton>
              <ToggleButton value="5">Maio</ToggleButton>
              <ToggleButton value="6">Junho</ToggleButton>
              <ToggleButton value="7">Julho</ToggleButton>
              <ToggleButton value="8">Agosto</ToggleButton>
              <ToggleButton value="9">Setembro</ToggleButton>
              <ToggleButton value="10">Outubro</ToggleButton>
              <ToggleButton value="11">Novembro</ToggleButton>
              <ToggleButton value="12">Dezembro</ToggleButton>
            </ToggleButtonGroup>
            {errors.month && <p className="help is-danger">{errors.month}</p>}
          </Grid>

          <Grid item lg={10} md={10} xs={12}>
            <Label className="textLabel">Filtrar por</Label>
            <FilterSelector
              handleFilterOptionSelected={handleFilterOptionSelected}
            />
          </Grid>

          <Grid item lg={10} md={10} xs={12} hidden={isClientSelectorHidden}>
            <Label className="textLabel">
              {clients.length && clientChecked.length === 0
                ? 'Selecione um Cliente'
                : 'Clientes Selecionados'}
            </Label>
            <Select
              options={clients}
              isMulti
              placeholder="Selecione"
              getOptionLabel={option =>
                `${option.social_name} - ${option.cgccpf}`
              }
              getOptionValue={option => option.id}
              value={clientValues}
              onChange={handleSelectClient}
            />
          </Grid>

          <Grid item lg={10} md={10} xs={12} hidden={isTagSelectorHidden}>
            <Label className="textLabel">
              {selectedTag === undefined
                ? 'Selecione uma tag'
                : 'Tag selecionada'}
            </Label>
            <Async
              noOptionsMessage={() => (
                <label style={{ padding: 0, margin: 0 }}>Digite uma tag</label>
              )}
              placeholder="Selecione"
              loadOptions={promiseFilterTag}
              loadingMessage={() => (
                <label style={{ padding: 0, margin: 0 }}>Carregando...</label>
              )}
              name="tag"
              value={selectedTag}
              onChange={setSelectedTag}
            />
          </Grid>

          <Grid item xs={12} style={{ marginTop: '15px' }}>
            <Button
              onClick={() => {
                setupQueryParams();
                getReport();
              }}
              loading={loadingBilling}
              style={{ marginRight: '5px' }}
            >
              Listar cobranças
            </Button>
            {total > 0
              ? [
                  validatePermission('EXPORTAR_RELATORIO_COBRANCAS') && (
                    <Button
                      color="success"
                      disabled={loadingExportBilling}
                      onClick={() => loadExportData()}
                    >
                      Exportar
                    </Button>
                  ),
                ]
              : ''}
          </Grid>
        </Grid>
      </Card>
      {showTotals && (
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Card
              bordered
              borderhexcolor="#1C4B75"
              form
              style={{
                overflow: 'auto',
                marginBottom: '10px',
              }}
            >
              <Typography variant="subtitle1" gutterBottom>
                Cargas lançadas
              </Typography>
              <Grid container spacing={1}>
                <Grid item>
                  <Typography variant="h4" gutterBottom>
                    {loadsCount}
                  </Typography>
                  <Typography
                    variant="h4"
                    gutterBottom
                    style={{ fontSize: '20px' }}
                  >
                    <strong>R$ {number_format(loadsValue, 2, ',', '.')}</strong>
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card
              bordered
              borderhexcolor="#fed76e"
              form
              style={{
                overflow: 'auto',
                marginBottom: '10px',
              }}
            >
              <Typography variant="subtitle1" gutterBottom>
                Cargas canceladas
              </Typography>
              <Grid container spacing={1}>
                <Grid item>
                  <Typography variant="h4" gutterBottom>
                    {loadsCanceled.qty}
                  </Typography>
                  <Typography
                    variant="h4"
                    gutterBottom
                    style={{ fontSize: '20px' }}
                  >
                    <strong>
                      R$ {number_format(loadsCanceled.sum, 2, ',', '.')}
                    </strong>
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card
              bordered
              borderhexcolor="#00BC97"
              form
              style={{ overflow: 'auto', marginBottom: '10px' }}
            >
              <Typography variant="subtitle1" gutterBottom>
                Cargas atendidas
              </Typography>
              <Grid sm={6}>
                <Typography variant="h4" gutterBottom>
                  {travelsCount}
                </Typography>
                <Typography
                  variant="h4"
                  gutterBottom
                  style={{ fontSize: '20px' }}
                >
                  <strong>
                    R$ {number_format(travelsValue, 2, ',', '.')}{' '}
                  </strong>
                </Typography>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      )}
      <InfiniteScroll
        endpoint="billing"
        filter={filter}
        onDataFetched={data => setTotal(data.pagination.total)}
        placeholderHeight={145}
        renderItem={(item, index) => (
          <CardCobranca
            hasTravels={travelsCount > 0}
            key={index}
            cliente={item}
            company={query.get('company_id')}
            month={Number(query.get('month'))}
            year={query.get('year')}
          />
        )}
        initialMessage={
          <Grid
            container
            item
            xs={12}
            justify="center"
            style={{ marginTop: '20px' }}
          >
            <InfoMessage
              icon="arrow-right"
              width="auto"
              snackColor="#8c8c8c"
              message="Preencha os filtros para visualizar as informações."
            />
          </Grid>
        }
        endMessage={
          <Grid
            container
            item
            xs={12}
            justify="center"
            style={{ marginTop: '20px' }}
          >
            <InfoMessage
              icon="doneall"
              width="auto"
              snackColor="#8c8c8c"
              message="Não há mais itens a serem exibidos."
            />
          </Grid>
        }
      />
    </>
  );
}

export default ReportCobrancas;
