import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom/';

import ApplicationLayout from 'v3/layouts/Application';
import { ListCard } from './Card';
import { useModal, usePermission, useQuery } from 'hooks';
import { RequestLoad as RequestLoadModal } from './Modals/RequestLoad';
import RenewPreLoad from './Modals/RenewPreLoad';
import { Filter } from './Filter';
import { Button, InfiniteScroll, Text } from 'v3/components';
import Space from 'v3/components/Space';
import { cooperplaceApi } from 'services/api';
import { validatePermission } from 'actions';
import { LOAD_FILTER_DATE_OPTIONS } from 'v3/utils/constants';
import moment from 'moment';
import CSVImporter from '../CSVImporter';

export function List() {
  usePermission('VISUALIZAR_PRECARGA', { redirect: true });
  const hasPermissionToCreate = validatePermission('CRIAR_PRECARGA');
  const history = useHistory();
  const queryParams = useQuery();
  const [selectedLoad, setSelectedLoad] = useState(null);
  const requestLoadModal = useModal();
  const renewLoadModal = useModal();
  const advancedFiltersModal = useModal();
  const [qtdAdvancedFilters, setQtdAdvancedFilters] = useState(0);
  const [preLoads, setPreLoads] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({});
  const [showCSVImporter, setShowCSVImporter] = useState(false);

  const fetchPreLoads = useCallback(async (params = '', page = 1) => {
    try {
      if (page === 1) setLoading(true);
      const response = await cooperplaceApi.get(
        `pre-loads?page=${page}&${params}`
      );
      const { data, meta } = response.data;
      if (page === 1) {
        setPreLoads(data);
      } else {
        setPreLoads(old => [...old, ...data]);
      }
      setPagination(meta);
    } catch (error) {
      //
    } finally {
      setLoading(false);
    }
  }, []);
  function parseDate(value) {
    if (moment(value, 'DD/MM/YYYY').isValid()) {
      return moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }

    return null;
  }

  useEffect(() => {
    if(!queryParams.get('startDate')){
      fetchPreLoads(`startDate=${parseDate(LOAD_FILTER_DATE_OPTIONS[0].value)}`,1);
    } else {
      fetchPreLoads(queryParams, 1);
    }
  }, []);

  function handleTitleAdvancedFilters() {
    let title = 'Filtro avançado';
    if (qtdAdvancedFilters) {
      title += ` (${qtdAdvancedFilters})`;
    }
    return title;
  }

  function renderItem(item) {
    return (
      <ListCard
        requestLoadModal={requestLoadModal}
        renewLoadModal={renewLoadModal}
        setSelectedLoad={setSelectedLoad}
        preLoad={item}
      />
    );
  }

  function handleFilter() {
    fetchPreLoads(queryParams, 1);
  }

  const handleCloseCSVImporter = () => setShowCSVImporter(false);
  const handleShowCSVImporter = () => setShowCSVImporter(true);

  return (
    <ApplicationLayout
      title={'Pré Carga'}
      RightComponent={
        <Space x={'0.5rem'}>
          <Button
            variant={'secondary'}
            onClick={() => advancedFiltersModal.open()}
          >
            <Text weight={500} color="black" type="regular">
              {handleTitleAdvancedFilters()}
            </Text>
          </Button>
          {hasPermissionToCreate ? (
            <>
              <Button
              id="button-importar-csv"
              variant="info"
              onClick={handleShowCSVImporter}
            >
              <Text weight={500} color="white" type="regular">
                Importar CSV
              </Text>
            </Button> 
            <Button onClick={() => history.push('/pre-cargas/cadastrar')}>
              <Text weight={500} color="white" type="regular">
                Cadastrar
              </Text>
            </Button>
            </>
          ) : null}
        </Space>
      }
    >
      <Filter
        isFetching={loading}
        resultsTotal={pagination?.total || 0}
        showAdvancedFilters={advancedFiltersModal.isOpen}
        setQtdAdvancedFilters={setQtdAdvancedFilters}
        handleCloseAdvancedFilters={() => advancedFiltersModal.close()}
        handleFilter={handleFilter}
      />
      <InfiniteScroll
        loading={loading}
        data={preLoads}
        renderItem={renderItem}
        onEndReach={() =>
          fetchPreLoads(queryParams, pagination.current_page + 1)
        }
        hasMore={pagination.current_page < pagination.last_page}
        scrollThreshold={0.7}
      />
      <CSVImporter
        showModal={showCSVImporter}
        closeModal={handleCloseCSVImporter}
      />
      <RequestLoadModal
        loadId={selectedLoad?.id}
        show={requestLoadModal.isOpen}
        onClose={() => requestLoadModal.close()}
        fetchPreLoads={fetchPreLoads}
      />
      <RenewPreLoad
        preLoad={selectedLoad}
        show={renewLoadModal.isOpen}
        handleClose={() => renewLoadModal.close()}
        fetchPreLoads={fetchPreLoads}
      />
    </ApplicationLayout>
  );
}
