import { usePermission } from 'hooks';
import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import api, { cooperplaceApi } from 'services/api';
import { Button, Card, Link, Select, Text } from 'v3/components';
import { useSnackbar } from 'v3/components/Snackbar';
import TextArea from 'v3/components/TextArea';
import { useTravel } from 'v3/pages/Travel';

function Showing({ status }) {
  return (
    <Row>
      <Col md={12}>
        <Text type="label" color="dark">
          Situação
        </Text>
        <Text type="regular" color="gray" as="p" className="mt-2">
          {status?.approvedStatus?.name || 'Não há registro'}
        </Text>
      </Col>
      <Col md={12} className="mb-3">
        <Text type="label" color="dark">
          Usuário
        </Text>
        <div className="d-flex flex-column">
          {status?.userCreated?.id ? (
            <Link target="_blank" to={`/usuarios/${status?.userCreated?.id}`}>
              <Text type="regular" color="gray">
                {status?.userCreated?.username}
              </Text>
            </Link>
          ) : (
            <Text type="regular" color="gray">
              Não há registro
            </Text>
          )}
        </div>
      </Col>
    </Row>
  );
}

function Editing({ value, setValue, options, observation, setObservation }) {
  return (
    <Row>
      <Col md={12}>
        <Select
          label="Situação do cadastro"
          getOptionLabel={({ name }) => name}
          getOptionValue={({ slug }) => slug}
          value={value}
          options={options}
          onChange={setValue}
        />
      </Col>
      <Col md={12}>
        <TextArea
          label="Observação"
          value={observation}
          onChange={({ target: { value: observationValue } }) =>
            setObservation(observationValue)
          }
        />
      </Col>
    </Row>
  );
}

export default function ApprovedCard() {
  const travel = useTravel();
  const [value, setValue] = useState();
  const [observation, setObservation] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [options, setOptions] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [status, setStatus] = useState();
  const snackbar = useSnackbar();
  const canEdit = usePermission('ALTERAR_VALIDACAO_CADASTRAL');

  const fetchOptions = async () => {
    try {
      setIsFetching(true);
      const {
        data: {
          result: { typesData, currentStatus },
        },
      } = await cooperplaceApi.get('approved-travel/types', {
        params: { travelId: travel?.data?.id },
      });
      setOptions(typesData);
      setStatus(currentStatus);
    } catch (error) {
      snackbar.show(<Text>Erro ao trazer opções de aprovação da viagem</Text>, {
        type: 'error',
      });
    } finally {
      setIsFetching(false);
    }
  };

  const saveOption = async () => {
    try {
      setIsFetching(true);
      await cooperplaceApi.post('approved-travel', {
        option: value?.slug,
        travelId: travel?.data?.id,
        observation,
      });

      if (value?.slug === 'REGISTER_APPROVAL') {
        await api.post(`travel-logs/${travel?.data?.id}`, {
          attended_id: travel?.data?.id,
          automatic: false,
          event_type_id: 8,
          event_id: 38,
          identifier: value?.slug,
          obs: `Aprovado cadastro${observation ? ` - ${observation}` : ''}`,
        });
      }

      if (value?.slug === 'REGISTER_DECLINE') {
        await api.post(`travel-logs/${travel?.data?.id}`, {
          attended_id: travel?.data?.id,
          automatic: false,
          event_type_id: 8,
          event_id: 39,
          identifier: value?.slug,
          obs: `Reprovado cadastro${observation ? ` - ${observation}` : ''}`,
        });
      }

      if (value?.slug === 'REGISTER_ANALYSIS') {
        await api.post(`travel-logs/${travel?.data?.id}`, {
          attended_id: travel?.data?.id,
          automatic: false,
          event_type_id: 8,
          event_id: 88,
          identifier: value?.slug,
          obs: `Iniciado análise cadastro${
            observation ? ` - ${observation}` : ''
          }`,
        });
      }
      setObservation('');
      await fetchOptions();
      await travel.fetchTravel();
    } catch (error) {
      snackbar.show(
        <Text>
          {error?.response?.data?.message ||
            'Erro ao trazer opções de aprovação da viagem'}
        </Text>,
        {
          type: 'error',
        }
      );
    } finally {
      setIsFetching(false);
      setIsEditing(false);
    }
  };

  const openEdit = async () => {
    setIsEditing(true);
    await fetchOptions();
  };

  const handleButton = () => (isEditing ? saveOption() : openEdit());

  const handleCancel = () => {
    setValue();
    setIsEditing(false);
  };

  const isEditable = useMemo(() => {
    if (!canEdit) return false;
    const statusLimit = travel?.statuses?.find(
      ({ code }) => code === 'ENDED_LOADING'
    );

    if (!travel?.data?.travelStatus?.order || !statusLimit) {
      return false;
    }

    return travel?.data?.travelStatus?.order <= statusLimit?.order;
  }, [travel?.statuses, travel?.data?.travelStatus?.order]);

  useEffect(() => {
    if (travel?.data?.id) {
      fetchOptions();
    }
  }, [
    travel?.data?.shipper?.cgccpf,
    travel?.data?.driverData?.id,
    travel?.data?.vehicles,
  ]);

  useEffect(() => {
    setValue(travel?.data?.registerType);
  }, [travel?.data?.registerType?.id]);

  return (
    <Card
      loading={travel.isLoading || travel.isFetching}
      header={
        <Row>
          <Col xs={12} className="mb-2">
            <Text color="dark" type="header">
              Validação cadastral
            </Text>
          </Col>
        </Row>
      }
      HeaderRightComponent={
        <div>
          {isEditing && (
            <Button
              loading={isFetching}
              onClick={handleCancel}
              variant="secondary"
              className="mr-2"
            >
              <Text color="dark" type="regular" weight="500">
                Cancelar
              </Text>
            </Button>
          )}
          <Button
            loading={isFetching}
            disabled={(isEditing && !value?.slug) || !isEditable}
            onClick={handleButton}
          >
            {`${isEditing ? 'Salvar' : 'Editar'}`}
          </Button>
        </div>
      }
    >
      {isEditing ? (
        <Editing
          value={value}
          setValue={setValue}
          options={options}
          observation={observation}
          setObservation={setObservation}
        />
      ) : (
        <Showing status={status} />
      )}
    </Card>
  );
}
