import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import api from 'services/api';
import moment from 'moment';

import Text from 'v3/components/Text';
import { useSnackbar } from 'v3/components/Snackbar';
import { usePermission } from 'hooks';
import { useCompany } from '../shared/context';
import SharedData from '../shared/Section';
import Inputs from '../shared/Data';
import Editable from '../shared/Editable';
import { validate } from '../shared/validator';

export default function Data() {
  const company = useCompany();
  const params = useParams();
  const snackbar = useSnackbar();
  const [isEditing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const hasPermission = usePermission('SUPER_ADM');
  async function saveData() {
    setLoading(true);
    try {
      const isValid = await validate(
        'data',
        {
          data: {
            name: company.data.name,
            priorityFleetDuration: company.data.priorityFleetDuration,
            can_issue: company.data.can_issue,
            issuers: company.data.issuers,
            client_type: company.data.client_type,
            product_type: company.data.product_type,
          },
        },
        company.setErrors
      );

      if (!isValid) {
        snackbar.show('Verifique os campos obrigatórios!', {
          type: 'error',
        });
        return;
      }

      await api.put(`v3/company/${params.id}/data`, {
        name: company.data.name,
        priorityFleetDuration: moment
          .duration(company.data.priorityFleetDuration?.minutes_formatted)
          .asMinutes(),
        can_issue: company.data.can_issue,
        client_type: company.data.client_type,
        product_type: company.data.product_type,
        issuers: company.data.can_issue ? company.data.issuers : [],
        status: company.data.status,
      });
      company.setPrevCompany(company.data);
      snackbar.show(<Text>Salvo com sucesso</Text>, { type: 'success' });
      setEditing(false);
    } catch (error) {
      snackbar.show(
        <Text>
          {error?.response?.data[0]?.message
            ? error?.response?.data[0]?.message
            : 'Erro ao salvar, tente novamente mais tarde!'}
        </Text>,
        {
          type: 'error',
        }
      );
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    function onPressedKey(e) {
      if (isEditing) {
        if (e.key === 'Enter') {
          saveData();
        }
      } else if (e.key === 'Escape') {
        setEditing(false);
      }
    }

    window.addEventListener('keyup', onPressedKey);

    return () => {
      window.removeEventListener('keyup', onPressedKey);
    };
  });

  return (
    <SharedData
      Title="Dados da empresa"
      HeaderRightComponent={
        <Editable
          isEditing={isEditing}
          setEditing={setEditing}
          onSave={saveData}
          isLoading={loading}
        />
      }
      loading={company.isFetching}
    >
      {isEditing ? (
        <Inputs />
      ) : (
        <>
          <Row>
            <Col>
              <Text type="label" color="dark">
                Nome
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {company.data.name}
              </Text>
            </Col>
          </Row>
          <Row>
            <Col>
              <Text type="label" color="dark">
                Duração da frota prioritária
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {company.data.priorityFleetDuration?.minutes_formatted}
              </Text>
            </Col>
          </Row>
          <Row>
            <Col>
              <Text type="label" color="dark">
                Emite CT-e
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {company.data.can_issue ? (
                  <Text type="regular" color="gray" as="p" className="mt-2">
                    Sim
                  </Text>
                ) : (
                  <Text type="regular" color="gray" as="p" className="mt-2">
                    Não
                  </Text>
                )}
              </Text>
            </Col>
          </Row>
          {company.data.can_issue && company.data.issuers && (
            <Row>
              <Col>
                <Text type="label" color="dark">
                  {company.data.issuers.map(item => item.name).length === 1 ? (
                    <Text type="label" color="dark">
                      Emissor
                    </Text>
                  ) : (
                    <Text type="label" color="dark">
                      Emissores
                    </Text>
                  )}
                </Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {company.data.issuers.map(item => item.name).join(', ') ||
                    'Não informado'}
                </Text>
              </Col>
            </Row>
          )}
          {hasPermission && (
            <Row>
              <Col>
                <Text type="label" color="dark">
                  Tipo do Cliente
                </Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {(company.data.client_type === 1 && 'Transportadora') ||
                    (company.data.client_type === 2 && 'Indústria') ||
                    'Não informado'}
                </Text>
              </Col>
              <Col>
                <Text type="label" color="dark">
                  Tipo do produto
                </Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {(company.data.product_type === 1 && 'Transporte') ||
                    (company.data.product_type === 2 && 'Plataforma') ||
                    'Não informado'}
                </Text>
              </Col>
            </Row>
          )}
          <Row className="d-flex flex-column">
            <Col md={6} className="mb-2">
              <Text type="label" color="dark">
                Status
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {company.data.status ? 'Ativo' : 'Inativo'}
              </Text>
            </Col>
          </Row>
        </>
      )}
    </SharedData>
  );
}
