import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

import dashboardApi from 'services/apis/dashboard';

import { Text, Modal } from 'v3/components';

export function ChangeRequestDetailsModal({
  isOpen,
  onClose,
  request,
  fields,
}) {
  const [loading, setLoading] = useState(false);
  const [requestDetails, setRequestDetails] = useState([]);
  const requestId = request?.id;

  const fieldsAndValues = requestDetails?.fields?.map(field => {
    return {
      field: fields?.find(item => item.column_name === field?.field)?.label,
      value: field?.value,
    };
  });

  function handleClose() {
    onClose();
  }

  useEffect(() => {
    async function handleGetRequestDetails() {
      try {
        setLoading(true);
        const response = await dashboardApi.get(
          `travels-request-change/${requestId}/fields`
        );
        setRequestDetails(response.data);
      } catch (error) {
        setRequestDetails([]);
      } finally {
        setLoading(false);
      }
    }
    if (requestId) {
      handleGetRequestDetails();
    }
  }, [requestId]);

  return (
    <Modal
      show={isOpen}
      handleClose={handleClose}
      heading={
        <Text type="subtitle" color="dark" weight="400">
          Detalhes da solicitação de alteração de cadastro
        </Text>
      }
      body={
        <>
          {!loading ? (
            <>
              <Row className="mb-4">
                <Col xs={12} md={12}>
                  <Text type="regular" color="dark">
                    {request?.userCreated?.username}: Solicitação de ajuste de{' '}
                    {request?.type}
                  </Text>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12}>
                  <Text type="label" color="dark">
                    Identificador{' '}
                  </Text>
                  <Text type="regular" color="dark" as="p" className="mt-2">
                    {requestDetails?.identifier}
                  </Text>
                </Col>
              </Row>
              {fieldsAndValues?.map(item => (
                <Row key={item?.field + item?.value}>
                  <Col xs={12} md={6}>
                    <Text type="label" color="dark">
                      Campo
                    </Text>
                    <Text type="regular" color="dark" as="p" className="mt-2">
                      {item?.field}
                    </Text>
                  </Col>
                  <Col xs={12} md={6}>
                    <Text type="label" color="dark">
                      Valor
                    </Text>
                    <Text type="regular" color="dark" as="p" className="mt-2">
                      {item?.value}
                    </Text>
                  </Col>
                </Row>
              ))}
              {request?.observation ? (
                <Row className="mt-2">
                  <Col xs={12} md={12}>
                    <Text type="label" color="dark">
                      Observação{' '}
                    </Text>
                    <Text type="regular" color="dark" as="p" className="mt-2">
                      {request?.observation}
                    </Text>
                  </Col>
                </Row>
              ) : null}
            </>
          ) : null}
        </>
      }
    />
  );
}
