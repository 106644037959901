import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import { useParams } from 'react-router-dom';
import { FaPencilAlt, FaTrash } from 'react-icons/fa';
import { useSnackbar } from 'v3/components/Snackbar';
import api from 'services/api';

import Card from 'v3/components/Card';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import Fallback from 'v3/components/Fallback';
import Badge from 'v3/components/Badge';
import Table from 'v3/components/Table';

import theme from 'v3/theme';
import AddressesModal from '../Modals/Addresses';

import { useClient } from '../context';

export default function ClientAddress() {
  const client = useClient();
  const params = useParams();
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  async function removeAddress(address) {
    try {
      setLoading(true);
      const response = await api.delete(
        `v3/client/${client.data.id}/address/${address.id}`
      );
      client.setData({ addresses: response.data });
      client.setPrevClient({ addresses: response.data });
    } catch (error) {
      snackbar.show(<Text>Erro ao excluir o endereço</Text>, { type: 'error' });
    } finally {
      setLoading(false);
    }
  }
  function detachAddress(address) {
    client.setData({
      ...client.data,
      addresses: client.data.addresses.filter(item => item !== address),
    });
    client.setPrevClient({
      ...client.data,
      addresses: client.data.addresses.filter(item => item !== address),
    });
  }

  function handleRemoveAddress(address) {
    if (params.id) removeAddress(address);
    else {
      detachAddress(address);
    }
  }

  useEffect(() => {
    if (!params.id) client.setData({ addresses: [] });
  }, []);

  return (
    <>
      <AddressesModal
        isOpen={showModal}
        setOpen={setShowModal}
        onClose={() => {
          client.setData({ ...client.prevClient, editingCurrentAddress: {} });
          setShowModal(false);
        }}
        editingAddress={client.data?.editingCurrentAddress}
      />
      <Card
        loading={client.isFetching || loading}
        header={
          <Text type="header" color="dark">
            Endereços *
          </Text>
        }
        HeaderRightComponent={
          !!client.data?.addresses?.length &&
          client?.canEditData && (
            <Button
              className="py-2"
              onClick={() => {
                setShowModal(true);
                client.setPrevClient(client.data);
                client.setData({ editingCurrentAddress: {} });
              }}
            >
              <Text type="regular" weight="500" color="white">
                Adicionar
              </Text>
            </Button>
          )
        }
      >
        <Fallback
          fall={!client.data?.addresses?.length}
          component={
            <>
              <Col className="align-items-center d-flex flex-column mb-2">
                <Text color={theme.colors.error} type="body">
                  {client.errors.addresses}
                </Text>
              </Col>

              <Col
                xs={12}
                className="align-items-center d-flex flex-column my-2"
              >
                <Text color={theme.colors.dark} type="regular">
                  Não há endereços cadastrados neste cliente.
                </Text>
                {client?.canEditData && (
                  <Button
                    className="mt-4 py-2"
                    onClick={() => {
                      client.setPrevClient(client.data);
                      setShowModal(true);
                    }}
                  >
                    <Text type="regular" weight="500" color="white">
                      Adicionar
                    </Text>
                  </Button>
                )}
              </Col>
            </>
          }
        >
          <Table striped>
            <thead>
              <tr>
                <th>
                  <Text weight={500} type="regular" color="dark">
                    Endereços
                  </Text>
                </th>
                <th>
                  <Text weight={500} type="regular" color={theme.colors.dark}>
                    Bairro
                  </Text>
                </th>
                <th>
                  <Text type="regular" weight={500} color="dark">
                    Cidade
                  </Text>
                </th>
                <th className="text-center">
                  <Text type="regular" weight={500} color="dark">
                    Ação
                  </Text>
                </th>
              </tr>
            </thead>
            <tbody>
              {client.data?.addresses
                ?.sort(
                  (a, b) => (a.current !== b.current && !a.current ? 1 : -1) // ordena com prioridade pros que tem current true
                )
                .map((item, idx) => (
                  <tr>
                    <td className="d-flex space-between">
                      <Text type="regular" color="dark">
                        {item.address}
                      </Text>
                      {item.current && (
                        <div className="ml-1">
                          <Badge
                            light
                            pill
                            variant="success"
                            className="ml-2"
                            size="sm"
                          >
                            <Text type="label">Principal</Text>
                          </Badge>
                        </div>
                      )}
                    </td>
                    <td>
                      <Text className="mr-5" type="regular" color="gray">
                        {item?.neighborhood}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray">
                        {`${item?.city_name} - ${
                          item.uf_name || item.province
                        }`}
                      </Text>
                    </td>
                    <td width="120" className="text-center">
                      <Button
                        variant="secondary"
                        size="sm"
                        className="mr-1"
                        onClick={() => {
                          client.setData({
                            addresses: client.data.addresses.filter(
                              address => address !== item
                            ),
                            editingCurrentAddress: item,
                          });
                          client.setPrevClient(client.data);
                          setShowModal(true);
                        }}
                        disabled={client?.canEditData === false}
                      >
                        <FaPencilAlt size={16} color="#464E5F" />
                      </Button>
                      <Button
                        variant="error"
                        size="sm"
                        onClick={() => handleRemoveAddress(item)}
                        disabled={item.current || client?.canEditData === false}
                      >
                        <FaTrash size={16} />
                      </Button>
                      {Object.keys(client.errors)?.filter(key =>
                        key.startsWith(`addresses[${idx}]`)
                      )?.length > 0 ? (
                        <Text as="p" color="danger" type="label">
                          Erro no endereço!
                        </Text>
                      ) : null}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Fallback>
      </Card>
    </>
  );
}
