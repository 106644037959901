import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FaTrash, FaPencilAlt } from 'react-icons/fa';

import api, { cooperplaceApi } from 'services/api';
import { fetchOnlyCities, fetchBrands } from 'utils/fetches';

import { useSnackbar } from 'v3/components/Snackbar';
import { Input, Select, Radio, Text, Button, Modal } from 'v3/components';
import { useTravel } from 'v3/pages/Travel';
import {
  requestTypeOptions,
  allSelectWithOptionsFields,
  allRadioFields,
  allSelectAsyncCityFields,
} from './utils';

export function RequestModal({
  isOpen,
  onClose,
  platesAvailable,
  fetchRequests,
  driverFields,
  shippingCompanyFields,
  vehicleFields,
}) {
  const travel = useTravel();
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [requestType, setRequestType] = useState(requestTypeOptions[0]);
  const [identifier, setIdentifier] = useState(null);
  const [fieldsToChange, setFieldsToChange] = useState([]);
  const [currentField, setCurrentField] = useState(null);
  const [currentValue, setCurrentValue] = useState('');
  const [radioValue, setRadioValue] = useState(null);
  const [brandValue, setBrandValue] = useState({});
  const [modelOptions, setModelOptions] = useState([]);

  function handleClose() {
    setIdentifier(null);
    setFieldsToChange([]);
    setCurrentField(null);
    setCurrentValue('');
    setBrandValue(null);
    onClose();
  }

  async function addChangeRequest() {
    try {
      setLoading(true);
      const fieldsToChangeProcessed = fieldsToChange?.map(item => {
        return {
          field: item?.field?.column_name,
          value: item?.value,
        };
      });
      const changeRequest = {
        travelId: travel?.data?.id,
        status: 'Pendente',
        type: requestType?.value,
        identifier:
          requestType?.value === 1
            ? identifier?.label
            : requestType?.value === 2
            ? `${travel?.data?.driverData?.social_name} - ${travel?.data?.driverData?.cgccpf}`
            : `${travel?.data?.shipper?.social_name} - ${travel?.data?.shipper?.cgccpf}`,
        fields: fieldsToChangeProcessed,
      };
      await cooperplaceApi.post(`load-request-change`, changeRequest);
      snackbar.show(
        <Text weight={500} color="dark">
          Solicitação enviada com sucesso!
        </Text>,
        {
          type: 'success',
        }
      );
      setTimeout(() => {
        handleClose();
        fetchRequests();
      }, 1000);
    } catch (error) {
      snackbar.show(
        <Text weight={500} color="dark">
          Error ao cadastrar solicitação
        </Text>,
        {
          type: 'error',
        }
      );
    } finally {
      setLoading(false);
    }
  }

  function handleAddFieldToRequest() {
    const fieldAndValue = {
      field: currentField,
      value: currentValue?.label || currentValue?.name || currentValue,
    };
    setFieldsToChange([...fieldsToChange, fieldAndValue]);
    setCurrentField(null);
    setCurrentValue('');
  }

  function handleDelete(item) {
    setFieldsToChange(fieldsToChange.filter(e => e !== item));
  }

  function handleEdit(item) {
    handleDelete(item);
    setCurrentField(item.field);
  }

  async function handleChangeBrand(value) {
    try {
      const response = await api.get(`v3/models/${value?.id}`);
      setModelOptions(response.data);
    } catch (error) {
      setModelOptions([]);
    }

    setBrandValue(value);
    setCurrentValue('');
  }

  return (
    <Modal
      show={isOpen}
      handleClose={handleClose}
      heading={
        <Text type="subtitle" color="dark" weight="400">
          Solicitação de Alteração de Cadastro
        </Text>
      }
      body={
        <>
          <div>
            <Row>
              <Col xs={12} md={6}>
                <Select
                  label="Alterar cadastro de"
                  options={requestTypeOptions}
                  value={requestType}
                  onChange={e => {
                    setRequestType(e);
                    setCurrentField(null);
                    setFieldsToChange([]);
                  }}
                />
              </Col>
              <Col xs={12} md={6}>
                {requestType?.value === 1 ? (
                  <Select
                    label="Identificação por Placa do Veículo"
                    options={platesAvailable}
                    getOptionLabel={option => option?.label}
                    getOptionValue={option => option?.value}
                    value={identifier}
                    onChange={e => setIdentifier(e)}
                  />
                ) : requestType?.value === 2 ? (
                  <Input
                    label="Identificação Motorista"
                    value={`${travel?.data?.driverData?.social_name} - ${travel?.data?.driverData?.cgccpf}`}
                    disabled={true}
                  />
                ) : requestType?.value === 3 ? (
                  <Input
                    label="Identificação Transportadora"
                    value={`${travel?.data?.shipper?.social_name} - ${travel?.data?.shipper?.cgccpf}`}
                    disabled={true}
                  />
                ) : null}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs={12} md={6}>
                <Select
                  label="Campo"
                  options={
                    requestType?.value === 1
                      ? vehicleFields
                      : requestType?.value === 2
                      ? driverFields
                      : shippingCompanyFields
                  }
                  getOptionLabel={option => option?.label}
                  getOptionValue={option => option?.column_name}
                  value={currentField}
                  onChange={e => {
                    setCurrentField(e);
                    setCurrentValue('');
                  }}
                  isDisabled={requestType?.value === 1 && !identifier}
                />
              </Col>

              {Object.keys(allSelectWithOptionsFields).includes(
                currentField?.column_name
              ) ? (
                <Col xs={12} md={6}>
                  <Select
                    label={
                      allSelectWithOptionsFields[currentField?.column_name]
                        ?.label
                    }
                    value={currentValue}
                    options={
                      allSelectWithOptionsFields[currentField?.column_name]
                        ?.options
                    }
                    getOptionLabel={option => option?.label}
                    getOptionValue={option => option?.id}
                    onChange={e => setCurrentValue(e)}
                  />
                </Col>
              ) : Object.keys(allRadioFields).includes(
                  currentField?.column_name
                ) ? (
                <Col xs={12} md={6}>
                  <Radio.Group
                    label={allRadioFields[currentField?.column_name]?.label}
                    onChange={({ target }) => {
                      setRadioValue(target.value);
                      setCurrentValue(target.value ? 'Sim' : 'Não');
                    }}
                    value={radioValue}
                    horizontal
                  >
                    <Radio value>
                      <Text color="dark" type="label">
                        Sim
                      </Text>
                    </Radio>
                    <Radio value={false}>
                      <Text color="dark" type="label">
                        Não
                      </Text>
                    </Radio>
                  </Radio.Group>
                </Col>
              ) : Object.keys(allSelectAsyncCityFields).includes(
                  currentField?.column_name
                ) ? (
                <Col xs={12} md={6}>
                  <Select.Async
                    label={
                      allSelectAsyncCityFields[currentField?.column_name]?.label
                    }
                    onSearch={fetchOnlyCities}
                    value={currentValue}
                    onChange={value => setCurrentValue(value)}
                    getOptionLabel={option =>
                      `${option.name}, ${option.province.uf}`
                    }
                    getOptionValue={option => option.id}
                  />
                </Col>
              ) : currentField?.column_name === 'brand' ? (
                <Col xs={12} md={6}>
                  <Select.Async
                    label="Marca"
                    onSearch={fetchBrands}
                    value={currentValue}
                    onChange={value => setCurrentValue(value)}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                    removeInputMin
                    placeholder="Busque uma marca"
                  />
                </Col>
              ) : currentField?.column_name === 'model' ? (
                <>
                  <Col md={6}>
                    <Select.Async
                      label="Busque uma marca primeiro"
                      onSearch={fetchBrands}
                      value={brandValue}
                      onChange={handleChangeBrand}
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.id}
                      removeInputMin
                    />
                  </Col>
                  <Col md={6} className="mt-2">
                    <Select
                      label="Modelo"
                      options={modelOptions}
                      value={currentValue}
                      onChange={value => setCurrentValue(value)}
                      getOptionLabel={option => option.label}
                      getOptionValue={option => option.label}
                      placeholder="Selecione um modelo"
                    />
                  </Col>
                </>
              ) : currentField ? (
                <Col xs={12} md={6}>
                  <Input
                    label={currentField?.label}
                    value={currentValue}
                    onChange={e => setCurrentValue(e.target.value)}
                  />
                </Col>
              ) : null}
            </Row>

            <Col xs={12} className="d-flex flex-column align-items-center mt-3">
              <Button
                onClick={handleAddFieldToRequest}
                variant="secondary"
                disabled={!currentField || !currentValue}
              >
                <Text color="dark" weight={500}>
                  Adicionar
                </Text>
              </Button>
            </Col>
          </div>
          <Row>
            <Col xs={12}>
              {fieldsToChange?.map(field => (
                <>
                  <Row className="mt-3">
                    <Col md={6}>
                      {field?.field?.label} - {field?.value}
                    </Col>
                    <Col className="d-flex justify-content-end align-items-center mb-3">
                      <Button
                        variant="secondary"
                        size="sm"
                        onClick={() => handleEdit(field)}
                      >
                        <FaPencilAlt />
                      </Button>
                      <Button
                        variant="error"
                        size="sm"
                        className="mx-1"
                        onClick={() => handleDelete(field)}
                      >
                        <FaTrash />
                      </Button>
                    </Col>
                  </Row>
                </>
              ))}
            </Col>
            {fieldsToChange?.length ? (
              <Col
                xs={12}
                className="d-flex flex-column align-items-center mt-3"
              >
                <Button
                  variant="primary"
                  onClick={addChangeRequest}
                  loading={loading}
                >
                  <Text color="white" weight={500}>
                    Enviar
                  </Text>
                </Button>
              </Col>
            ) : null}
          </Row>
        </>
      }
    />
  );
}
