import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';

import Text from 'v3/components/Text';
import api from 'services/api';
import { useSnackbar } from 'v3/components/Snackbar';
import { useValidation, useModal } from 'hooks';
import { parseErrors } from 'v3/utils/formatter';
import moment from 'moment';
import SwitchOption from 'v3/components/SwitchOption';
import SharedData from '../shared/Section';
import Inputs from '../shared/Data/index';
import Editable from '../shared/Editable';
import { useShippingCompany } from '../shared/context';
import { dataSchema } from '../shared/validator';
import Modal from '../shared/Modals/PaymentMethod';

export default function Data() {
  const shippingCompany = useShippingCompany();
  const snackbar = useSnackbar();
  const [isEditing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, { validate }] = useValidation(dataSchema, {
    pathPrefix: 'data.',
  });
  const [active, setActive] = useState(!shippingCompany.deleted_at);
  const isCompany = shippingCompany?.data?.personType?.name === 'Jurídica';
  const isDriver = !!shippingCompany?.data?.is_driver;
  const paymentMethod = useModal();

  useEffect(() => {
    shippingCompany.setErrors(errors);
  }, [errors]);

  useEffect(() => {
    if (shippingCompany.data.deleted_at) {
      setActive(false);
    } else setActive(true);
  }, [shippingCompany]);

  async function saveData() {
    setLoading(true);

    try {
      const data = {
        type_id: shippingCompany.data.personType.name === 'Jurídica' ? 3 : 2,
        cgccpf: shippingCompany.data.cgccpf,
        ie: shippingCompany.data.ie || null,
        social_name: shippingCompany.data.social_name,
        fantasy_name: shippingCompany.data.fantasy_name || null,
        phone: shippingCompany.data.phone,
        phone_unmasked: shippingCompany.data.phone.replace(/[^0-9]+/g, ''),
        email: shippingCompany.data.email,
        is_driver: shippingCompany.data.is_driver || null,
        rntrc_number: shippingCompany?.data?.rntrc_number || null,
        rntrc_exp_date:
          moment(shippingCompany.data?.rntrc_exp_date, 'DD/MM/YYYY').toDate() ||
          null,
      };

      await api.put(`v3/shipping-company/${shippingCompany.data.id}/data`, {
        data,
      });

      shippingCompany.setPrevShippingCompany(shippingCompany.data);
      snackbar.show(<Text>Salvo com sucesso</Text>, { type: 'success' });
      setLoading(false);
      setEditing(false);
      shippingCompany.setErrors({});
    } catch (error) {
      let message = '';
      if (error.response) {
        const parsedErrors = parseErrors(error.response.data, {
          getPath: e => e.field,
        });
        shippingCompany.setErrors(parsedErrors);
        message = error.response?.data.message;
      }

      snackbar.show(<Text>{message || 'Erro ao salvar'}</Text>, {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }

  async function handleSubmit() {
    setLoading(true);
    const [isValid] = await validate(shippingCompany.data);

    if (!isValid) {
      snackbar.show(<Text>Verifique os campos obrigatórios</Text>, {
        type: 'error',
      });
      setLoading(false);
      return;
    }

    if (
      !shippingCompany?.data?.shippingCompanies?.payment?.id &&
      shippingCompany.data.is_driver
    ) {
      paymentMethod.open();
    } else {
      saveData();
    }
  }

  async function toggleActive() {
    try {
      await api.put(`/persons/${shippingCompany.data.id}`, {
        cgccpf: shippingCompany.data.cgccpf,
        deleted_at: active ? moment() : null,
      });
      shippingCompany.data.deleted_at = active ? moment() : null;
      setActive(old => !old);
      shippingCompany.setPrevShippingCompany(shippingCompany.data);
    } catch {
      snackbar.show(<Text> Erro ao alterar transportadora! </Text>, {
        type: 'error',
      });
    }
  }

  return (
    <SharedData
      Title="Dados da transportadora"
      HeaderRightComponent={
        <Editable
          isEditing={isEditing}
          setEditing={setEditing}
          onSave={handleSubmit}
          isLoading={loading}
        />
      }
      loading={shippingCompany.isFetching}
    >
      <Modal
        isOpen={paymentMethod.isOpen}
        onClose={paymentMethod.close}
        saveData={saveData}
        setUpperLoading={setLoading}
      />
      {isEditing ? (
        <Inputs />
      ) : (
        <Row>
          <Col xs={12} md={12} as="p">
            <SwitchOption value={active} onClick={() => toggleActive()} />
          </Col>
          <Col xs={12}>
            <Text type="label" color="dark">
              Tipo de Pessoa
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {shippingCompany.data?.personType?.name || 'Não informado'}
            </Text>
          </Col>
          <Col xs={12} md={6}>
            <Text type="label" color="dark">
              {isCompany ? 'CNPJ' : 'CPF'}
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {shippingCompany?.data?.cgccpf || 'Não informado'}
            </Text>
          </Col>
          {isCompany ? (
            <Col xs={12} md={6}>
              <Text type="label" color="dark">
                Inscrição Estadual
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {shippingCompany?.data?.ie || 'Não informado'}
              </Text>
            </Col>
          ) : null}
          <Col xs={12} md={6}>
            <Text type="label" color="dark">
              {isCompany ? 'Razão Social' : 'Nome'}
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {shippingCompany?.data?.social_name || 'Não informado'}
            </Text>
          </Col>
          {isCompany ? (
            <Col xs={12} md={6}>
              <Text type="label" color="dark">
                Nome fantasia
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {shippingCompany?.data?.fantasy_name || 'Não informado'}
              </Text>
            </Col>
          ) : null}
          <Col md={6} xs={12}>
            <Text type="label" color="dark">
              RNTRC
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {shippingCompany?.data?.rntrc_number || 'Não informado'}
            </Text>
          </Col>
          <Col md={6} xs={12}>
            <Text type="label" color="dark">
              Validade RNTRC
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {shippingCompany?.data?.rntrc_exp_date || 'Não informado'}
            </Text>
          </Col>
          <Col md={6} xs={12}>
            <Text type="label" color="dark">
              É motorista?
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {isDriver ? 'Sim' : 'Não'}
            </Text>
          </Col>
          <Col md={6} xs={12}>
            <Text type="label" color="dark">
              Nível de serviço
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {shippingCompany?.data?.shippingCompanies?.description_service_level || 'Não informado'}
            </Text>
          </Col>
          <Col xs={12}>
            <Text type="label" color="dark">
              Telefone
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {shippingCompany?.data?.phone || 'Não informado'}
            </Text>
          </Col>
          <Col xs={12}>
            <Text type="label" color="dark">
              E-mail
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {shippingCompany?.data?.email || 'Não informado'}
            </Text>
          </Col>
        </Row>
      )}
    </SharedData>
  );
}
