export const INCREMENT = 'INCREMENT';
export const DECREMENT = 'DECREMENT';
export const RESET = 'RESET';
export const BRANCH_OFFICE = 'BRANCH_OFFICE';
export const COST_CENTER = 'COST_CENTER';
export const VIEW_INSTANCE = 'VIEW_INSTANCE';
export const DATA_TRAVEL = 'DATA_TRAVEL';

export function increaseCount() {
  return { type: INCREMENT };
}

export function decreaseCount() {
  return { type: DECREMENT };
}

export function resetCount() {
  return { type: RESET };
}

export function dataTravel(payload) {
  return { type: DATA_TRAVEL, payload };
}

export function validatePermission(permission) {
  const permissions = JSON.parse(localStorage.getItem('permissions') || '[]');
  if (Array.isArray(permission)) {
    if (permissions) {
      const hasPermission = permission.find(slug =>
        permissions.some(item => item.slug === slug && item.active)
      );
      return !!hasPermission;
    }
  } else {
    if (permissions) {
      const item = permissions.find(
        item => item.slug === permission && item.active
      );
      if (item) {
        return item.active;
      }
    }
  }
  return false;
}
export function currentBranchOffice(costCenter) {
  return {
    type: BRANCH_OFFICE,
    value: costCenter.branch_office_id,
    name: costCenter.branch_office_name,
  };
}
export function currentCostCenter(costCenter) {
  return {
    type: COST_CENTER,
    value: costCenter.id,
    name: costCenter.name,
  };
}
