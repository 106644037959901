import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import IconButton from 'v3/components/Button/Icon';

export const PasswordCol = styled(Col)`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
`;

export const StyledIconButton = styled(IconButton)`
  align-self: end;
  margin-bottom: ${props => props.mb};
`;
