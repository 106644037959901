import styled from 'styled-components';
import { TreeSelect } from 'antd';
import theme from 'v3/theme';
import Text from '../Text';

export const Container = styled.div`
  width: 100%;
`;

export const Label = styled(Text)`
  font-size: 0.8125rem;
  margin-bottom: 10px;
  margin-top: 0.1875rem;
  display: flex;
`;

export const SingleValueTreeSelect = styled(TreeSelect)`
  width: 100%;
  min-height: 44px;
  height: auto;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  border-radius: 5px;
  border: ${props =>
    props.error ? '1px solid #f64e60' : '1px solid transparent'};

  .ant-select-selection-placeholder {
    color: ${theme.colors.placeholder};
    font-family: 'Poppins';
  }

  .ant-select-selector {
    border: 1px solid #e5eaee !important;
    min-height: 44px;
    display: flex;
    align-items: center;
  }
  .ant-select-selection-item {
    box-sizing: border-box;
    white-space: pre-wrap;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    background-color: #e3e4e7;
    color: ${theme.colors.dark};
    margin: 0.5rem 1rem 0.5rem 0.25rem;
    padding: 0.275rem 0.5rem !important;
    border-radius: 15px;
    text-align: justify;
  }
  .ant-select-selection-item > span > span {
    color: #000;
  }
`;

export const MultiValueTreeSelect = styled(TreeSelect)`
  width: 100%;
  outline: 0;
  min-height: 44px;
  height: auto;
  margin-bottom: 0.5rem;

  border-radius: 5px;
  border: ${props =>
    props.error ? '1px solid #f64e60' : '1px solid transparent'};

  .ant-select-selector {
    border: 1px solid #e5eaee !important;
    min-height: 44px;
  }

  .ant-select-selection-placeholder {
    color: ${theme.colors.placeholder};
    font-family: 'Poppins';
    margin-left: 0.25rem;
  }

  .ant-select-selection-overflow {
    margin-right: 1rem;
    flex: 1;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    padding: 0.275rem 0.25rem;
    max-height: ${props =>
      props.selectValuesContainerMaxHeight
        ? props.selectValuesContainerMaxHeight
        : 'auto'};
    overflow-y: ${props =>
      props.selectValuesContainerOverflowY
        ? props.selectValuesContainerOverflowY
        : 'hidden'};
  }
  .ant-select-selection-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    background-color: #e3e4e7;
    margin: 2px;
    padding: 5px 8px;
    border-radius: 15px;
  }
  .ant-select-selection-item-content {
    color: ${theme.colors.dark};
  }
  .ant-select-selection-item-content > span > span {
    color: #000;
  }
  .ant-select-selection-item,
  .ant-select-selection-item-content {
    white-space: pre-wrap;
  }

  .ant-select-arrow {
    padding: 0.5rem;
  }
  .ant-select-clear {
    display: block;
    width: 2rem;
    height: 2rem;
  }
  .ant-select-arrow::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 24px;
    left: -0.15rem;
    border-left: 1px solid #cccccc !important;
  }
`;

export const NotFoundContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  color: ${theme.colors.placeholder};
`;
