import React, { useEffect, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useModal } from 'hooks';
import { Select, Input, Text, Tooltip, Card } from 'v3/components';
import theme from 'v3/theme';

import {
  LoadValueTypeOptions,
  NegotiationTypeOptions,
  disableFieldConditions,
  inputsLoadConditionsToShow,
  shipmentTransferToShow,
} from '../../Register/PriceData/utils';

import { currencyOptions, makeConversion } from 'v3/utils/formatter';
import {
  ExtraFieldsContainer,
  StyledActionButton,
  StyledCurrencyCol,
  StyledToggleCurrency,
} from '../../Register/PriceData/styles';
import {
  CardHeaderComponent,
  ExtraFieldsRow,
  ExtraItemRow,
  FreightItemRow,
  HeaderTotalSummary,
  LabelsRow,
  ObservationsContainer,
  SummaryRow,
} from '../../Register/PriceData/components';
import { FaDollarSign, FaQuestionCircle } from 'react-icons/fa';
import LoadExtraFieldsTravelEditing from 'v3/pages/Travel/shared/Prices/loadExtraFieldsModal';

export function FreightEditing({
  showDailyRate,
  setShowDailyRate,
  rateResponse,
  isBRLCurrency,
  dollarToUse,
  exchange,
  setExchange,
  exchangeDay,
  setExchangeDay,
  currencyExchange,
  setCurrencyExchange,
  loadValueCalculationType,
  setLoadValueCalculationType,
  negotiatioValueType,
  setNegotiationType,
  clientFreightOptions,
  cargoValue,
  takerValue,
  setTakerValue,
  toll,
  setToll,
  tollCode,
  setTollCode,
  discharge,
  setDischarge,
  dischargeCode,
  setDischargeCode,
  griss,
  setGriss,
  grissPercentage,
  setGrissPercentage,
  grissAdCode,
  setGrissAdCode,
  adValorem,
  setAdValorem,
  adValoremPercentage,
  setAdValoremPercentage,
  charge,
  setCharge,
  chargeCode,
  setChargeCode,
  ferry,
  setFerry,
  ferryCode,
  setFerryCode,
  icms,
  setIcms,
  icmsPercentage,
  setIcmsPercentage,
  icmsCode,
  setIcmsCode,
  clientExtraFields,
  setClientExtraFields,
  totalTakerMemo,
  cooperInsurance,
  setCooperInsurance,
  cooperInsurancePercentage,
  setCooperInsurancePercentage,
  inputsExtraFields,
  setInputsExtraFields,
  setTotalInputs,
  totalInputs,
  pcpObs,
  setPcpObs,
  setTotalShipperTransfer,
  shipperTransferExtraFields,
  setShipperTransferExtraFields,
  shipperDiscountsExtraFields,
  setShipperDiscountsExtraFields,
  setTotalDiscounts,
  totalDiscounts,
  totalShipperTransfer,
  errors,
  issueRate,
  clientCardToggle,
  setClientCardToggle,
  inputsCardToggle,
  setInputsCardToggle,
  discountsCardToggle,
  setDiscountsCardToggle,
  transferCardToggle,
  setTransferCardToggle,
  freightMargin,
  negotiatedCardToggle,
  setNegotiatedCardToggle,
  attendedInfo,
  totalItems,
}) {
  return (
    <>
      <PreFreightFieldsEditing
        showDailyRate={showDailyRate}
        rateResponse={rateResponse}
        exchange={exchange}
        setExchange={setExchange}
        exchangeDay={exchangeDay}
        setExchangeDay={setExchangeDay}
        currencyExchange={currencyExchange}
        setCurrencyExchange={setCurrencyExchange}
        loadValueCalculationType={loadValueCalculationType}
        setLoadValueCalculationType={setLoadValueCalculationType}
        negotiatioValueType={negotiatioValueType}
        setNegotiationType={setNegotiationType}
        errors={errors}
      />
      <Row>
        <ClientCardEditing
          showDailyRate={showDailyRate}
          setShowDailyRate={setShowDailyRate}
          isBRLCurrency={isBRLCurrency}
          dollarToUse={dollarToUse}
          exchange={exchange}
          exchangeDay={exchangeDay}
          cargoValue={cargoValue}
          takerValue={takerValue}
          setTakerValue={setTakerValue}
          toll={toll}
          setToll={setToll}
          tollCode={tollCode}
          setTollCode={setTollCode}
          discharge={discharge}
          setDischarge={setDischarge}
          dischargeCode={dischargeCode}
          setDischargeCode={setDischargeCode}
          clientFreightOptions={clientFreightOptions}
          griss={griss}
          setGriss={setGriss}
          grissPercentage={grissPercentage}
          setGrissPercentage={setGrissPercentage}
          grissAdCode={grissAdCode}
          setGrissAdCode={setGrissAdCode}
          adValorem={adValorem}
          setAdValorem={setAdValorem}
          adValoremPercentage={adValoremPercentage}
          setAdValoremPercentage={setAdValoremPercentage}
          charge={charge}
          setCharge={setCharge}
          chargeCode={chargeCode}
          setChargeCode={setChargeCode}
          ferry={ferry}
          setFerry={setFerry}
          ferryCode={ferryCode}
          setFerryCode={setFerryCode}
          icms={icms}
          setIcms={setIcms}
          icmsPercentage={icmsPercentage}
          setIcmsPercentage={setIcmsPercentage}
          icmsCode={icmsCode}
          setIcmsCode={setIcmsCode}
          totalTakerMemo={totalTakerMemo}
          clientExtraFields={clientExtraFields}
          setClientExtraFields={setClientExtraFields}
          currencyExchange={currencyExchange}
          errors={errors}
          clientCardToggle={clientCardToggle}
          setClientCardToggle={setClientCardToggle}
          totalItems={totalItems}
        />
        <InputsCard
          showDailyRate={showDailyRate}
          isBRLCurrency={isBRLCurrency}
          dollarToUse={dollarToUse}
          cooperInsurance={cooperInsurance}
          setCooperInsurance={setCooperInsurance}
          cooperInsurancePercentage={cooperInsurancePercentage}
          setCooperInsurancePercentage={setCooperInsurancePercentage}
          inputsExtraFields={inputsExtraFields}
          setInputsExtraFields={setInputsExtraFields}
          cargoValue={cargoValue}
          takerValue={takerValue}
          toll={toll}
          discharge={discharge}
          griss={griss}
          grissPercentage={grissPercentage}
          adValorem={adValorem}
          adValoremPercentage={adValoremPercentage}
          charge={charge}
          ferry={ferry}
          icms={icms}
          icmsPercentage={icmsPercentage}
          tollCode={tollCode}
          dischargeCode={dischargeCode}
          grissAdCode={grissAdCode}
          chargeCode={chargeCode}
          ferryCode={ferryCode}
          icmsCode={icmsCode}
          setTotalInputs={setTotalInputs}
          totalComputed={totalInputs}
          currencyExchange={currencyExchange}
          inputsCardToggle={inputsCardToggle}
          setInputsCardToggle={setInputsCardToggle}
          attendedInfo={attendedInfo}
        />
        <NegotiatedValuesEditing
          isBRLCurrency={isBRLCurrency}
          dollarToUse={dollarToUse}
          freightMargin={freightMargin}
          negotiatedCardToggle={negotiatedCardToggle}
          setNegotiatedCardToggle={setNegotiatedCardToggle}
        />
        <ShipperDiscountsCard
          shipperDiscountsExtraFields={shipperDiscountsExtraFields}
          setShipperDiscountsExtraFields={setShipperDiscountsExtraFields}
          isBRLCurrency={isBRLCurrency}
          dollarToUse={dollarToUse}
          ferry={ferry}
          ferryCode={ferryCode}
          setTotalDiscounts={setTotalDiscounts}
          totalComputed={totalDiscounts}
          currencyExchange={currencyExchange}
          issueRate={issueRate}
          discountsCardToggle={discountsCardToggle}
          setDiscountsCardToggle={setDiscountsCardToggle}
        />
        <ShipperTransferCard
          isBRLCurrency={isBRLCurrency}
          dollarToUse={dollarToUse}
          cargoValue={cargoValue}
          takerValue={takerValue}
          toll={toll}
          discharge={discharge}
          griss={griss}
          grissPercentage={grissPercentage}
          adValorem={adValorem}
          adValoremPercentage={adValoremPercentage}
          charge={charge}
          ferry={ferry}
          tollCode={tollCode}
          dischargeCode={dischargeCode}
          grissAdCode={grissAdCode}
          chargeCode={chargeCode}
          ferryCode={ferryCode}
          shipperTransferExtraFields={shipperTransferExtraFields}
          setShipperTransferExtraFields={setShipperTransferExtraFields}
          setTotalShipperTransfer={setTotalShipperTransfer}
          currencyExchange={currencyExchange}
          pcpObs={pcpObs}
          setPcpObs={setPcpObs}
          errors={errors}
          transferCardToggle={transferCardToggle}
          setTransferCardToggle={setTransferCardToggle}
          totalComputed={totalShipperTransfer}
        />
      </Row>
    </>
  );
}

function PreFreightFieldsEditing({
  showDailyRate,
  exchange,
  setExchange,
  exchangeDay,
  setExchangeDay,
  currencyExchange,
  setCurrencyExchange,
  loadValueCalculationType,
  setLoadValueCalculationType,
  negotiatioValueType,
  setNegotiationType,
  rateResponse,
  errors,
}) {
  const currencyType =
    currencyExchange?.value === 'BRL'
      ? '(Compra)'
      : currencyExchange?.value === 'USD'
      ? '(Venda)'
      : '';

  return (
    <Row className="my-2">
      <StyledCurrencyCol sm={12} md={showDailyRate ? 4 : 3}>
        <Input
          label="Moeda"
          value={exchange || 0}
          onChange={e => setExchange(e.target.value)}
          masked
          mask={[/\d/, '.', /\d/, /\d/, /\d/, /\d/]}
          error={errors?.exchange}
          id="input-moeda"
        />
        {showDailyRate && (
          <Input
            id="input-moeda-dia"
            label="Moeda dia"
            value={exchangeDay}
            disabled
          />
        )}
      </StyledCurrencyCol>

      <Col sm={12} md={showDailyRate ? 2 : 3}>
        <Select
          label={`Câmbio ${currencyType}`}
          options={currencyOptions}
          value={currencyExchange}
          getOptionLabel={option => option.label}
          getOptionValue={option => option.value}
          onChange={value => {
            setCurrencyExchange(value);
            let toggleValue =
              value?.value === 'BRL'
                ? rateResponse?.buy
                : value?.value === 'USD'
                ? rateResponse?.sell
                : 0;

            setExchangeDay(toggleValue);
          }}
          disableClear={true}
          isDisabled={true}
          id="select-cambio"
        />
      </Col>
      <Col sm={12} md={showDailyRate ? 2 : 3}>
        <Select
          label="Tipo valor carga *"
          value={loadValueCalculationType}
          options={LoadValueTypeOptions}
          getOptionLabel={option => option.label}
          getOptionValue={option => option.value}
          onChange={event => {
            setLoadValueCalculationType(event);
          }}
          error={errors?.loadType}
          id="select-tipo-valor-carga"
        />
      </Col>
      <Col sm={12} md={showDailyRate ? 4 : 3}>
        <Select
          label="Negociação *"
          value={negotiatioValueType}
          options={NegotiationTypeOptions}
          getOptionLabel={option => option.label}
          getOptionValue={option => option.value}
          onChange={event => {
            setNegotiationType(event);
          }}
          error={errors?.negotiationType}
          id="select-negociacao-frete"
        />
      </Col>
    </Row>
  );
}

function ClientCardEditing({
  showDailyRate,
  setShowDailyRate,
  isBRLCurrency,
  dollarToUse,
  cargoValue,
  takerValue,
  setTakerValue,
  toll,
  setToll,
  tollCode,
  setTollCode,
  discharge,
  setDischarge,
  dischargeCode,
  setDischargeCode,
  griss,
  setGriss,
  grissPercentage,
  setGrissPercentage,
  grissAdCode,
  setGrissAdCode,
  clientFreightOptions,
  adValorem,
  setAdValorem,
  adValoremPercentage,
  setAdValoremPercentage,
  charge,
  setCharge,
  chargeCode,
  setChargeCode,
  ferry,
  setFerry,
  ferryCode,
  setFerryCode,
  icms,
  setIcms,
  icmsPercentage,
  setIcmsPercentage,
  icmsCode,
  setIcmsCode,
  totalTakerMemo,
  clientExtraFields,
  setClientExtraFields,
  currencyExchange,
  errors,
  clientCardToggle,
  setClientCardToggle,
  totalItems,
}) {
  const extraFieldsModal = useModal();

  function convertValue(rawValue) {
    const operation = isBRLCurrency ? '/' : '*';
    const processedValue = +makeConversion(rawValue, operation, dollarToUse);
    return operation === '/'
      ? processedValue.toFixed(4)
      : processedValue.toFixed(2);
  }
  const shouldDisableTollField = disableFieldConditions?.toll_value?.includes(
    tollCode?.code
  );
  const shouldDisableDischargeField =
    disableFieldConditions?.discharge_value?.includes(dischargeCode?.code);
  const shouldDisableFerryField = disableFieldConditions?.ferry?.includes(
    ferryCode?.code
  );
  const shouldDisableGrissAdField = disableFieldConditions?.grissAd?.includes(
    grissAdCode?.code
  );
  const shouldDisableICMSField = disableFieldConditions?.icms?.includes(
    icmsCode?.code
  );
  const shouldDisableChargeField =
    disableFieldConditions?.charge_value?.includes(chargeCode?.code);
  useEffect(() => {
    if (shouldDisableTollField) {
      setToll('0');
    }
    if (shouldDisableDischargeField) {
      setDischarge('0');
    }
    if (shouldDisableFerryField) {
      setFerry('0');
    }
    if (shouldDisableGrissAdField) {
      setGrissPercentage('0');
      setAdValoremPercentage('0');
      setGriss('0');
      setAdValorem('0');
    }
    if (shouldDisableICMSField) {
      setIcmsPercentage('0');
      setIcms('0');
    }
    if (shouldDisableChargeField) {
      setCharge('0');
    }
  }, [
    shouldDisableTollField,
    shouldDisableFerryField,
    shouldDisableGrissAdField,
    shouldDisableDischargeField,
    shouldDisableICMSField,
    shouldDisableChargeField,
  ]);
  const receiveClientTags = useMemo(() => {
    let tags = '';
    if (Number(takerValue) > 0) {
      tags += 'Frete base,';
    }
    if (Number(toll) > 0) {
      tags += 'Pedágio,';
    }
    if (Number(discharge) > 0) {
      tags += 'Descarga,';
    }
    if (Number(charge) > 0) {
      tags += 'Taxa de Carregamento,';
    }
    if (Number(griss) > 0) {
      tags += 'Gris,';
    }
    if (Number(adValorem) > 0) {
      tags += 'Ad Valorem,';
    }
    if (Number(ferry) > 0) {
      tags += 'Balsa,';
    }
    if (Number(icms) > 0) {
      tags += 'ICMS,';
    }
    if (clientExtraFields?.filter(item => item?.delete !== true)?.length > 0) {
      tags += 'Valores extras,';
    }
    return tags;
  }, [
    takerValue,
    toll,
    discharge,
    griss,
    adValorem,
    charge,
    ferry,
    icms,
    clientExtraFields,
  ]);
  return (
    <Col xs={12}>
      <Card containerCardBoxShadow={'0px 0px 10px rgba(0, 0, 0, 0.1)'}>
        <CardHeaderComponent
          label={'Receber Cliente Tomador'}
          isOpen={clientCardToggle}
          setIsOpen={() => setClientCardToggle(!clientCardToggle)}
          rightSection={
            <StyledToggleCurrency>
              <StyledActionButton
                onClick={() => setShowDailyRate(!showDailyRate)}
                title={showDailyRate ? 'Cotação do dia' : 'Cotação informada'}
              >
                <FaDollarSign
                  title={showDailyRate ? 'Cotação do dia' : 'Cotação informada'}
                />
              </StyledActionButton>
              <Tooltip
                content={
                  <Text type="label" color="white">
                    {
                      'Alterna entre valores da cotação do dólar no dia e a cotação informada no campo Moeda.'
                    }
                  </Text>
                }
              >
                <FaQuestionCircle style={{ color: theme.colors.light_blue }} />
              </Tooltip>
            </StyledToggleCurrency>
          }
          tooltipContent={
            'Todo o valor que é recebido pelo cliente deve ser destacado separadamente. Após preencher todos os campos irá chegar no Valor Cliente Tomador automaticamente '
          }
          totalsSection={
            <HeaderTotalSummary
              label={'Valor Cliente Tomador'}
              totalInReal={
                isBRLCurrency ? totalTakerMemo : convertValue(totalTakerMemo)
              }
              totalInDollar={
                !isBRLCurrency ? totalTakerMemo : convertValue(totalTakerMemo)
              }
            />
          }
          hasTagsRow
          tagsContent={receiveClientTags}
        />
        {clientCardToggle && (
          <>
            <LabelsRow />
            <FreightItemRow
              label={'Valor frete base*'}
              value={takerValue}
              onChangeValue={(_event, value) => setTakerValue(value)}
              isBRLCurrency={isBRLCurrency}
              dollarToUse={dollarToUse}
              isRequired
              errorField={errors?.takerValue}
              showCalculator
              id="frete-base"
            />
            <FreightItemRow
              label={'Pedágio'}
              value={toll}
              onChangeValue={(_event, value) => setToll(value)}
              isBRLCurrency={isBRLCurrency}
              dollarToUse={dollarToUse}
              selectOptions={clientFreightOptions?.toll}
              selectValue={tollCode}
              onChangeSelectValue={value => setTollCode(value)}
              shouldDisableField={shouldDisableTollField}
              showCalculator
              id="pedagio"
            />
            <FreightItemRow
              label={'Descarga'}
              value={discharge}
              onChangeValue={(_event, value) => {
                setDischarge(value);
              }}
              isBRLCurrency={isBRLCurrency}
              dollarToUse={dollarToUse}
              selectOptions={clientFreightOptions?.discharge}
              onChangeSelectValue={value => {
                setDischargeCode(value);
              }}
              selectValue={dischargeCode}
              shouldDisableField={shouldDisableDischargeField}
              showCalculator
              id="descarga"
            />
            <FreightItemRow
              label={'Gris'}
              percentageValueReference={cargoValue}
              percentageValue={grissPercentage || '0'}
              onChangePercentageValue={(_event, value) => {
                if (cargoValue) {
                  let computedValue = (
                    (Number(value) / 100) *
                    cargoValue
                  ).toFixed(2);
                  setGrissPercentage(value);
                  setGriss(computedValue);
                }
              }}
              value={griss}
              onChangeValue={(_event, value) => {
                if (cargoValue) {
                  let computedValue = (
                    (Number(value) / cargoValue) *
                    100
                  ).toFixed(2);
                  setGrissPercentage(computedValue);
                  setGriss(value);
                }
              }}
              isBRLCurrency={isBRLCurrency}
              dollarToUse={dollarToUse}
              selectOptions={clientFreightOptions?.griss_ad_valorem}
              onChangeSelectValue={value => {
                setGrissAdCode(value);
              }}
              selectValue={grissAdCode}
              hasTooltip
              tooltipContent="Griss / AD Valorem (Seguro): Valor aproximado, pode variar de acordo com o valor do produto na Nota Fiscal"
              shouldDisableField={shouldDisableGrissAdField}
              showCalculator
              id="gris"
            />
            <FreightItemRow
              label={'Ad Valorem'}
              decimalDigits={3}
              percentageValueReference={cargoValue}
              percentageValue={adValoremPercentage || '0'}
              onChangePercentageValue={(_event, value) => {
                if (cargoValue) {
                  let computedValue = (
                    (Number(value) / 100) *
                    cargoValue
                  ).toFixed(2);
                  setAdValoremPercentage(value);
                  setAdValorem(computedValue);
                }
              }}
              value={adValorem}
              onChangeValue={(_event, value) => {
                if (cargoValue) {
                  let computedValue = (
                    (Number(value) / cargoValue) *
                    100
                  ).toFixed(3);
                  setAdValorem(value);
                  setAdValoremPercentage(computedValue);
                }
              }}
              isBRLCurrency={isBRLCurrency}
              dollarToUse={dollarToUse}
              shouldDisableField={shouldDisableGrissAdField}
              showCalculator
              id="ad-valorem"
            />
            <FreightItemRow
              label={'Taxa de carregamento'}
              value={charge}
              onChangeValue={(_event, value) => {
                setCharge(value);
              }}
              isBRLCurrency={isBRLCurrency}
              dollarToUse={dollarToUse}
              selectOptions={clientFreightOptions?.loading_fee}
              onChangeSelectValue={value => {
                setChargeCode(value);
              }}
              selectValue={chargeCode}
              shouldDisableField={shouldDisableChargeField}
              showCalculator
              id="taxa-carregamento"
            />
            <FreightItemRow
              label={'Balsa'}
              value={ferry}
              onChangeValue={(_event, value) => {
                setFerry(value);
              }}
              isBRLCurrency={isBRLCurrency}
              dollarToUse={dollarToUse}
              selectOptions={clientFreightOptions?.ferry}
              onChangeSelectValue={value => {
                setFerryCode(value);
              }}
              selectValue={ferryCode}
              shouldDisableField={shouldDisableFerryField}
              showCalculator
              id="balsa"
            />
            <FreightItemRow
              label={'ICMS'}
              percentageValueReference={totalItems}
              percentageValue={icmsPercentage || '0'}
              onChangePercentageValue={(_event, value) => {
                if (totalItems) {
                  let computedValue = Number(
                    totalItems / (1 - value / 100) - totalItems
                  )?.toFixed(2);
                  setIcmsPercentage(value);
                  setIcms(computedValue);
                }
              }}
              value={icms}
              onChangeValue={(_event, value) => {
                if (totalItems) {
                  let computedValue = Number(
                    100 *
                      (1 -
                        (totalItems / (totalItems + Number(value)))?.toFixed(2))
                  )?.toFixed(2);
                  setIcmsPercentage(computedValue);
                  setIcms(value);
                }
              }}
              isBRLCurrency={isBRLCurrency}
              dollarToUse={dollarToUse}
              selectOptions={clientFreightOptions?.icms}
              onChangeSelectValue={value => {
                setIcmsCode(value);
              }}
              selectValue={icmsCode}
              shouldDisableField={shouldDisableICMSField}
              showCalculator
              id="icms"
            />
            <>
              {clientExtraFields?.filter(item => item?.delete !== true).length >
              0 ? (
                <Text type="regular" weight={500}>
                  Valores extras
                </Text>
              ) : null}
              <ExtraFieldsContainer>
                {clientExtraFields?.map((item, index) => {
                  if (item?.delete) {
                    return;
                  } else
                    return (
                      <ExtraItemRow
                        key={`${index}_${new Date().getTime()}`}
                        label={item?.label}
                        value={item?.value}
                        dollarToUse={dollarToUse}
                        isBRLCurrency={isBRLCurrency}
                        onSetExtraFields={() => {
                          let updated = [];
                          clientExtraFields.forEach((item, idx) => {
                            if (item?.id && idx === index) {
                              updated.push({ ...item, delete: true });
                            } else if (idx !== index) {
                              updated.push(item);
                            }
                          });
                          setClientExtraFields(updated);
                        }}
                        showToDriver={item?.show_to_drivers}
                        id={`valor-extra-${index}`}
                      />
                    );
                })}
              </ExtraFieldsContainer>
              <SummaryRow
                label={'Valor Cliente Tomador'}
                totalInBRL={
                  isBRLCurrency ? totalTakerMemo : convertValue(totalTakerMemo)
                }
                totalInUSD={
                  !isBRLCurrency ? totalTakerMemo : convertValue(totalTakerMemo)
                }
              />
              <ExtraFieldsRow
                id="button-abrir-valores-extras-valor-tomador"
                openModal={() => extraFieldsModal.open()}
              />
              <LoadExtraFieldsTravelEditing
                extraFields={clientExtraFields}
                isOpen={extraFieldsModal.isOpen}
                onClose={extraFieldsModal.close}
                setExtraFields={setClientExtraFields}
                dollarToUse={dollarToUse}
                isBRLCurrency={isBRLCurrency}
                isReceiveClientExtra={true}
                loadCurrency={currencyExchange?.value === 'BRL' ? 'R$' : '$'}
              />
            </>
          </>
        )}
      </Card>
    </Col>
  );
}

function InputsCard({
  cooperInsurance,
  setCooperInsurance,
  cooperInsurancePercentage,
  setCooperInsurancePercentage,
  inputsExtraFields,
  setInputsExtraFields,
  isBRLCurrency,
  dollarToUse,
  cargoValue,
  takerValue,
  toll,
  discharge,
  griss,
  grissPercentage,
  adValorem,
  adValoremPercentage,
  charge,
  ferry,
  icms,
  icmsPercentage,
  tollCode,
  dischargeCode,
  grissAdCode,
  chargeCode,
  ferryCode,
  icmsCode,
  setTotalInputs,
  totalComputed,
  currencyExchange,
  inputsCardToggle,
  setInputsCardToggle,
  attendedInfo,
}) {
  const inputsExtraFieldsModal = useModal();
  function convertValue(rawValue) {
    const operation = isBRLCurrency ? '/' : '*';
    const processedValue = +makeConversion(rawValue, operation, dollarToUse);
    return operation === '/'
      ? processedValue.toFixed(4)
      : processedValue.toFixed(2);
  }
  const shouldUseToll =
    inputsLoadConditionsToShow?.includes(tollCode?.code) && toll > 0;
  const shouldUseDischarge =
    inputsLoadConditionsToShow?.includes(dischargeCode?.code) && discharge > 0;
  const shouldUseGrisAd = inputsLoadConditionsToShow?.includes(
    grissAdCode?.code
  );

  const shouldUseCharge =
    inputsLoadConditionsToShow?.includes(chargeCode?.code) && charge > 0;
  const shouldUseFerry =
    inputsLoadConditionsToShow?.includes(ferryCode?.code) && ferry > 0;
  const shouldUseICMS =
    inputsLoadConditionsToShow?.includes(icmsCode?.code) && icms > 0;
  const totalInputs = useMemo(() => {
    let total = 0;
    if (inputsExtraFields?.length) {
      total = inputsExtraFields.reduce((acc, curr) => {
        if (curr?.delete) {
          return acc;
        } else return acc + Number(curr?.value);
      }, 0);
    }
    if (shouldUseToll) {
      total += Number(toll);
    }
    if (shouldUseDischarge) {
      total += Number(discharge);
    }
    if (shouldUseGrisAd && griss !== undefined) {
      total += Number(griss);
    }
    if (
      shouldUseGrisAd &&
      adValorem !== undefined &&
      (attendedInfo.isAttended === false ||
        attendedInfo?.serviceLevel !== 'Agregado Ouro')
    ) {
      total += Number(adValorem);
    }
    if (shouldUseCharge) {
      total += Number(charge);
    }
    if (shouldUseFerry) {
      total += Number(ferry);
    }
    if (shouldUseICMS) {
      total += Number(icms);
    }
    if (cooperInsurance) {
      total += Number(cooperInsurance);
    }
    return total;
  }, [
    inputsExtraFields,
    cooperInsurance,
    toll,
    discharge,
    griss,
    adValorem,
    charge,
    ferry,
    icms,
    shouldUseToll,
    shouldUseCharge,
    shouldUseDischarge,
    shouldUseFerry,
    shouldUseGrisAd,
    shouldUseICMS,
    attendedInfo,
  ]);
  const inputsTag = useMemo(() => {
    let tags = '';
    if (Number(cooperInsurance) > 0) {
      tags += 'Seguro Coopercarga,';
    }
    if (shouldUseToll) {
      tags += 'Pedágio,';
    }
    if (shouldUseDischarge) {
      tags += 'Descarga,';
    }
    if (shouldUseCharge) {
      tags += 'Taxa de Carregamento,';
    }
    if (shouldUseGrisAd) {
      tags += 'Gris,';
    }
    if (shouldUseGrisAd) {
      tags += 'Ad Valorem,';
    }
    if (shouldUseFerry) {
      tags += 'Balsa,';
    }
    if (shouldUseICMS) {
      tags += 'ICMS,';
    }
    if (inputsExtraFields?.filter(item => item?.delete !== true)?.length > 0) {
      tags += 'Valores Extra';
    }
    return tags;
  }, [
    cooperInsurance,
    inputsExtraFields,
    shouldUseToll,
    shouldUseDischarge,
    shouldUseGrisAd,
    shouldUseCharge,
    shouldUseFerry,
    shouldUseICMS,
    attendedInfo,
  ]);
  useEffect(() => {
    setTotalInputs(totalInputs);
  }, [totalInputs]);
  return (
    <Col xs={12}>
      <Card containerCardBoxShadow={'0px 0px 10px rgba(0, 0, 0, 0.1)'}>
        <CardHeaderComponent
          label={'Descontos e Insumos pré taxa'}
          isOpen={inputsCardToggle}
          setIsOpen={() => setInputsCardToggle(!inputsCardToggle)}
          tooltipContent={
            'Todo valor que precisa descontar antes de tirar a taxa (margem) é alocado neste campo, assim, é chegado no percentual correto para desconto de taxa administrativa (Margem)'
          }
          totalsSection={
            <HeaderTotalSummary
              label={'Valor Insumos'}
              totalInReal={
                isBRLCurrency ? totalComputed : convertValue(totalComputed)
              }
              totalInDollar={
                !isBRLCurrency ? totalComputed : convertValue(totalComputed)
              }
            />
          }
          hasTagsRow
          tagsContent={inputsTag}
        />
        {inputsCardToggle && (
          <>
            <LabelsRow />
            <FreightItemRow
              label={'Seguro Coopercarga'}
              percentageValueReference={cargoValue}
              percentageValue={cooperInsurancePercentage || '0'}
              onChangePercentageValue={(_event, value) => {
                if (cargoValue) {
                  let computedValue = (
                    (Number(value) / 100) *
                    cargoValue
                  ).toFixed(2);
                  setCooperInsurance(computedValue);
                  setCooperInsurancePercentage(value);
                }
              }}
              value={cooperInsurance || '0'}
              onChangeValue={(_event, value) => {
                if (cargoValue) {
                  let computedValue = (
                    (Number(value) / cargoValue) *
                    100
                  ).toFixed(2);
                  setCooperInsurance(value);
                  setCooperInsurancePercentage(computedValue);
                }
              }}
              isBRLCurrency={isBRLCurrency}
              dollarToUse={dollarToUse}
              hasTooltip={true}
              tooltipContent="Valor aproximado, pode variar de acordo com o valor do produto na Nota Fiscal"
              showCalculator
              id="desconto-seguro-coopercarga"
            />
            {shouldUseToll && (
              <FreightItemRow
                isBRLCurrency={isBRLCurrency}
                dollarToUse={dollarToUse}
                label={'Pedágio'}
                value={toll}
                shouldDisableField={true}
                id="desconto-pedagio"
              />
            )}
            {shouldUseDischarge && (
              <FreightItemRow
                isBRLCurrency={isBRLCurrency}
                dollarToUse={dollarToUse}
                label={'Descarga'}
                value={discharge}
                shouldDisableField={true}
                id="desconto-descarga"
              />
            )}
            {shouldUseGrisAd && (
              <>
                <FreightItemRow
                  isBRLCurrency={isBRLCurrency}
                  dollarToUse={dollarToUse}
                  percentageValueReference={cargoValue}
                  label={'Griss'}
                  value={griss}
                  percentageValue={grissPercentage || '0'}
                  hasTooltip={true}
                  tooltipContent="Valor aproximado, pode variar de acordo com o valor do produto na Nota Fiscal"
                  shouldDisableField={true}
                  id="desconto-gris"
                />
              </>
            )}

            {shouldUseGrisAd &&
            attendedInfo.isAttended === true &&
            attendedInfo?.serviceLevel?.toLowerCase() === 'agregado ouro' ? (
              <>
                <FreightItemRow
                  isBRLCurrency={isBRLCurrency}
                  dollarToUse={dollarToUse}
                  label={'AD Valorem'}
                  percentageValueReference={cargoValue}
                  value={adValorem}
                  percentageValue={adValoremPercentage || '0'}
                  shouldDisableField={true}
                  id="desconto-ad-valorem"
                />
                <FreightItemRow
                  isBRLCurrency={isBRLCurrency}
                  dollarToUse={dollarToUse}
                  label={'AD Valorem'}
                  percentageValueReference={cargoValue}
                  value={adValorem}
                  percentageValue={adValoremPercentage || '0'}
                  shouldDisableField={true}
                  wasValueRemovedFromTotal={true}
                  id="desconto-ad-valorem"
                />
              </>
            ) : shouldUseGrisAd ? (
              <FreightItemRow
                isBRLCurrency={isBRLCurrency}
                dollarToUse={dollarToUse}
                label={'AD Valorem'}
                percentageValueReference={cargoValue}
                value={adValorem}
                percentageValue={adValoremPercentage || '0'}
                shouldDisableField={true}
                id="desconto-ad-valorem"
              />
            ) : null}
            {shouldUseCharge && (
              <FreightItemRow
                isBRLCurrency={isBRLCurrency}
                dollarToUse={dollarToUse}
                label={'Taxa de carregamento'}
                value={charge}
                shouldDisableField={true}
                id="desconto-taxa-carregamento"
              />
            )}
            {shouldUseICMS && (
              <FreightItemRow
                isBRLCurrency={isBRLCurrency}
                dollarToUse={dollarToUse}
                percentageValueReference={takerValue}
                label={'ICMS'}
                value={icms}
                percentageValue={icmsPercentage || '0'}
                shouldDisableField={true}
                id="desconto-icms"
              />
            )}
            {inputsExtraFields
              ?.filter(item => item?.delete !== true)
              .map((item, index) => {
                return (
                  <ExtraItemRow
                    key={`${index}_${new Date().getTime()}`}
                    label={item?.label}
                    value={item?.value}
                    dollarToUse={dollarToUse}
                    isBRLCurrency={isBRLCurrency}
                    onSetExtraFields={() => {
                      let updated = [];
                      inputsExtraFields.forEach((item, idx) => {
                        if (item?.id && idx === index) {
                          updated.push({ ...item, delete: true });
                        } else if (idx !== index) {
                          updated.push(item);
                        }
                      });
                      setInputsExtraFields(updated);
                    }}
                    showToDriver={item?.show_to_driver}
                    id={`valor-extra-descontos-${index}`}
                  />
                );
              })}
            <SummaryRow
              label={'Valor Insumos'}
              totalInBRL={
                isBRLCurrency ? totalComputed : convertValue(totalComputed)
              }
              totalInUSD={
                !isBRLCurrency ? totalComputed : convertValue(totalComputed)
              }
              id="valor-insumo"
            />
            <ExtraFieldsRow
              id="button-abrir-valores-extras-valor-insumos"
              openModal={() => inputsExtraFieldsModal.open()}
            />
          </>
        )}
      </Card>
      <LoadExtraFieldsTravelEditing
        extraFields={inputsExtraFields}
        isOpen={inputsExtraFieldsModal.isOpen}
        onClose={inputsExtraFieldsModal.close}
        setExtraFields={setInputsExtraFields}
        dollarToUse={dollarToUse}
        isBRLCurrency={isBRLCurrency}
        loadCurrency={currencyExchange?.value === 'BRL' ? 'R$' : '$'}
      />
    </Col>
  );
}

function ShipperTransferCard({
  shipperTransferExtraFields,
  setShipperTransferExtraFields,
  pcpObs,
  setPcpObs,
  isBRLCurrency,
  dollarToUse,
  cargoValue,
  takerValue,
  toll,
  discharge,
  griss,
  grissPercentage,
  adValorem,
  adValoremPercentage,
  charge,
  ferry,
  tollCode,
  dischargeCode,
  grissAdCode,
  chargeCode,
  ferryCode,
  setTotalShipperTransfer,
  currencyExchange,
  errors,
  totalComputed,
  transferCardToggle,
  setTransferCardToggle,
}) {
  const shipperTransferExtraFieldsModal = useModal();
  function handleOpenClientExtraFields() {
    return shipperTransferExtraFieldsModal.open();
  }
  function convertValue(rawValue) {
    const operation = isBRLCurrency ? '/' : '*';
    const processedValue = +makeConversion(rawValue, operation, dollarToUse);
    return operation === '/'
      ? processedValue.toFixed(4)
      : processedValue.toFixed(2);
  }
  const shouldUseToll =
    shipmentTransferToShow?.includes(tollCode?.code) && toll > 0;
  const shouldUseDischarge =
    shipmentTransferToShow?.includes(dischargeCode?.code) && discharge > 0;
  const shouldUseGrisAd = shipmentTransferToShow?.includes(grissAdCode?.code);
  const shouldUseCharge =
    shipmentTransferToShow?.includes(chargeCode?.code) && charge > 0;
  const shouldUseFerry =
    shipmentTransferToShow?.includes(ferryCode?.code) && ferry > 0;
  const totalTransfer = useMemo(() => {
    let total = 0;
    if (shipperTransferExtraFields?.length) {
      total = shipperTransferExtraFields.reduce((acc, curr) => {
        if (curr?.delete) {
          return acc;
        } else return acc + Number(curr?.value);
      }, 0);
    }
    if (shouldUseToll && tollCode?.code !== 'client_provides_card') {
      total += Number(toll);
    }
    if (shouldUseDischarge) {
      total += Number(discharge);
    }
    if (shouldUseGrisAd) {
      total += Number(griss) + Number(adValorem);
    }
    if (shouldUseCharge) {
      total += Number(charge);
    }
    if (shouldUseFerry) {
      total += Number(ferry);
    }
    return total;
  }, [
    shipperTransferExtraFields,
    toll,
    discharge,
    griss,
    adValorem,
    charge,
    ferry,
    shouldUseToll,
    shouldUseCharge,
    shouldUseDischarge,
    shouldUseFerry,
    shouldUseGrisAd,
  ]);
  useEffect(() => {
    setTotalShipperTransfer(totalTransfer);
  }, [totalTransfer]);
  const transferTags = useMemo(() => {
    let tags = '';

    if (shouldUseToll && tollCode?.code !== 'client_provides_card') {
      tags += 'Pedágio,';
    }
    if (shouldUseDischarge) {
      tags += 'Descarga,';
    }
    if (shouldUseCharge) {
      tags += 'Taxa de Carregamento,';
    }
    if (shouldUseGrisAd) {
      tags += 'Gris,';
    }
    if (shouldUseGrisAd) {
      tags += 'Ad Valorem,';
    }
    if (shouldUseFerry) {
      tags += 'Balsa,';
    }
    if (
      shipperTransferExtraFields?.filter(item => item?.delete !== true)
        ?.length > 0
    ) {
      tags += 'Valores Extra';
    }
    return tags;
  }, [
    shipperTransferExtraFields,
    shouldUseToll,
    shouldUseDischarge,
    shouldUseGrisAd,
    shouldUseCharge,
    shouldUseFerry,
  ]);
  return (
    <Col xs={12}>
      <Card containerCardBoxShadow={'0px 0px 10px rgba(0, 0, 0, 0.1)'}>
        <CardHeaderComponent
          label={'Repasse ao Transportador'}
          tooltipContent={
            'Valores a serem repassados ao transportador conforme itens definidos, não contempla taxa administrativa sobre o valor.'
          }
          isOpen={transferCardToggle}
          setIsOpen={() => setTransferCardToggle(!transferCardToggle)}
          totalsSection={
            <HeaderTotalSummary
              label={'Crédito Transportador'}
              totalInReal={
                isBRLCurrency ? totalComputed : convertValue(totalComputed)
              }
              totalInDollar={
                !isBRLCurrency ? totalComputed : convertValue(totalComputed)
              }
            />
          }
          hasTagsRow
          tagsContent={transferTags}
        />
        {transferCardToggle && (
          <>
            <LabelsRow />
            {shouldUseToll && (
              <FreightItemRow
                isBRLCurrency={isBRLCurrency}
                dollarToUse={dollarToUse}
                label={'Pedágio'}
                value={toll}
                shouldDisableField={true}
                id="credito-pedagio"
              />
            )}
            {shouldUseDischarge && (
              <FreightItemRow
                isBRLCurrency={isBRLCurrency}
                dollarToUse={dollarToUse}
                label={'Descarga'}
                value={discharge}
                shouldDisableField={true}
                id="credito-descarga"
              />
            )}
            {shouldUseGrisAd && (
              <>
                <FreightItemRow
                  isBRLCurrency={isBRLCurrency}
                  dollarToUse={dollarToUse}
                  percentageValueReference={cargoValue}
                  label={'Griss'}
                  value={griss}
                  percentageValue={grissPercentage || '0'}
                  hasTooltip={true}
                  tooltipContent="Valor aproximado, pode variar de acordo com o valor do produto na Nota Fiscal"
                  shouldDisableField={true}
                  id="credito-gris"
                />
              </>
            )}
            {shouldUseGrisAd && (
              <FreightItemRow
                isBRLCurrency={isBRLCurrency}
                dollarToUse={dollarToUse}
                label={'AD Valorem'}
                percentageValueReference={cargoValue}
                value={adValorem}
                percentageValue={adValoremPercentage || '0'}
                shouldDisableField={true}
                id="credito-ad-valorem"
              />
            )}
            {shouldUseCharge && (
              <FreightItemRow
                isBRLCurrency={isBRLCurrency}
                dollarToUse={dollarToUse}
                label={'Taxa de carregamento'}
                value={charge}
                shouldDisableField={true}
                id="credito-taxa-carregamento"
              />
            )}
            {shouldUseFerry && (
              <FreightItemRow
                isBRLCurrency={isBRLCurrency}
                dollarToUse={dollarToUse}
                label={'Balsa'}
                value={ferry}
                shouldDisableField={true}
                id="credito-balsa"
              />
            )}

            {shipperTransferExtraFields?.filter(item => item?.delete !== true)
              ?.length > 0 ? (
              <Text type="medium" weight={500}>
                Valores extras
              </Text>
            ) : null}
            <ExtraFieldsContainer>
              {shipperTransferExtraFields?.map((item, index) => {
                if (item?.delete) {
                  return;
                } else
                  return (
                    <ExtraItemRow
                      key={`${index}_${new Date().getTime()}`}
                      label={item?.label}
                      value={item?.value}
                      dollarToUse={dollarToUse}
                      isBRLCurrency={isBRLCurrency}
                      onSetExtraFields={() => {
                        let updated = [];
                        shipperTransferExtraFields.forEach((item, idx) => {
                          if (item?.id && idx === index) {
                            updated.push({ ...item, delete: true });
                          } else if (idx !== index) {
                            updated.push(item);
                          }
                        });
                        setShipperTransferExtraFields(updated);
                      }}
                      showToDriver={item?.show_to_driver}
                      id={`credito-extra-${index}`}
                    />
                  );
              })}
            </ExtraFieldsContainer>

            <SummaryRow
              label={'Valor crédito'}
              totalInBRL={
                isBRLCurrency ? totalTransfer : convertValue(totalTransfer)
              }
              totalInUSD={
                !isBRLCurrency ? totalTransfer : convertValue(totalTransfer)
              }
              id="valor-crédito"
            />
            <ExtraFieldsRow
              id="button-abrir-valores-extras-valor-credito"
              openModal={handleOpenClientExtraFields}
            />
            <ObservationsContainer
              pcpLabel="Observações PCP"
              pcpValue={pcpObs}
              onChangePcpValue={event => setPcpObs(event.target.value)}
              pcpError={errors?.pcp_observation}
              id="observacoes"
            />
          </>
        )}
      </Card>
      <LoadExtraFieldsTravelEditing
        isOpen={shipperTransferExtraFieldsModal.isOpen}
        onClose={shipperTransferExtraFieldsModal.close}
        setExtraFields={setShipperTransferExtraFields}
        extraFields={shipperTransferExtraFields}
        dollarToUse={dollarToUse}
        isBRLCurrency={isBRLCurrency}
        loadCurrency={currencyExchange?.value === 'BRL' ? 'R$' : '$'}
      />
    </Col>
  );
}

function ShipperDiscountsCard({
  shipperDiscountsExtraFields,
  setShipperDiscountsExtraFields,
  isBRLCurrency,
  dollarToUse,
  ferry,
  ferryCode,
  setTotalDiscounts,
  totalComputed,
  currencyExchange,
  issueRate,
  discountsCardToggle,
  setDiscountsCardToggle,
}) {
  const shipperDiscountsExtraFieldsModal = useModal();
  function handleOpenClientExtraFields() {
    return shipperDiscountsExtraFieldsModal.open();
  }
  function convertValue(rawValue) {
    const operation = isBRLCurrency ? '/' : '*';
    const processedValue = +makeConversion(rawValue, operation, dollarToUse);
    return operation === '/'
      ? processedValue.toFixed(4)
      : processedValue.toFixed(2);
  }

  const totalDiscounts = useMemo(() => {
    let total = 0;
    if (shipperDiscountsExtraFields?.length) {
      total = shipperDiscountsExtraFields.reduce((acc, curr) => {
        if (curr?.delete) {
          return acc;
        } else return acc + Number(curr?.value);
      }, 0);
    }

    if (!!issueRate) {
      total += Number(issueRate);
    }

    return total;
  }, [shipperDiscountsExtraFields, ferry]);
  const discountsTags = useMemo(() => {
    let tags = '';
    if (issueRate) {
      tags += 'Taxa de emissão,';
    }
    if (
      shipperDiscountsExtraFields?.filter(item => item?.delete !== true)
        ?.length > 0
    ) {
      tags += 'Valores extras,';
    }
    return tags;
  }, [shipperDiscountsExtraFields]);
  useEffect(() => {
    setTotalDiscounts(totalDiscounts);
  }, [totalDiscounts]);
  return (
    <Col xs={12}>
      <Card containerCardBoxShadow={'0px 0px 10px rgba(0, 0, 0, 0.1)'}>
        <CardHeaderComponent
          label={'Descontos ao Transportador'}
          isOpen={discountsCardToggle}
          setIsOpen={() => setDiscountsCardToggle(!discountsCardToggle)}
          tooltipContent={
            'Se caracteriza todo desconto que será feito depois de tirar a margem (Taxa administrativa).'
          }
          totalsSection={
            <HeaderTotalSummary
              label={'Valor Descontos'}
              totalInReal={
                isBRLCurrency ? totalComputed : convertValue(totalComputed)
              }
              totalInDollar={
                !isBRLCurrency ? totalComputed : convertValue(totalComputed)
              }
            />
          }
          hasTagsRow
          tagsContent={discountsTags}
        />
        {discountsCardToggle && (
          <>
            <LabelsRow />
            {issueRate ? (
              <FreightItemRow
                isBRLCurrency={isBRLCurrency}
                dollarToUse={dollarToUse}
                label={'Taxa de emissão'}
                value={issueRate}
                shouldDisableField={true}
                id="taxa-emissao"
              />
            ) : null}

            {shipperDiscountsExtraFields?.filter(item => item?.delete !== true)
              ?.length > 0 ? (
              <Text type="medium" weight={500}>
                Valores extras
              </Text>
            ) : null}
            <ExtraFieldsContainer>
              {shipperDiscountsExtraFields?.map((item, index) => {
                if (item?.delete) {
                  return;
                } else
                  return (
                    <ExtraItemRow
                      key={index}
                      label={item?.label}
                      value={item?.value}
                      dollarToUse={dollarToUse}
                      isBRLCurrency={isBRLCurrency}
                      onSetExtraFields={() => {
                        let updated = [];
                        shipperDiscountsExtraFields.forEach((item, idx) => {
                          if (item?.id && idx === index) {
                            updated.push({ ...item, delete: true });
                          } else if (idx !== index) {
                            updated.push(item);
                          }
                        });
                        setShipperDiscountsExtraFields(updated);
                      }}
                      showToDriver={item?.show_to_driver}
                      id={`desconto-transportador-${index}`}
                    />
                  );
              })}
            </ExtraFieldsContainer>
            <SummaryRow
              label={'Valor descontos'}
              totalInBRL={
                isBRLCurrency ? totalDiscounts : convertValue(totalDiscounts)
              }
              totalInUSD={
                !isBRLCurrency ? totalDiscounts : convertValue(totalDiscounts)
              }
              id="desconto"
            />
            <ExtraFieldsRow
              id="button-abrir-valores-extras-desconto"
              openModal={handleOpenClientExtraFields}
            />
          </>
        )}
      </Card>
      <LoadExtraFieldsTravelEditing
        isOpen={shipperDiscountsExtraFieldsModal.isOpen}
        onClose={shipperDiscountsExtraFieldsModal.close}
        setExtraFields={setShipperDiscountsExtraFields}
        extraFields={shipperDiscountsExtraFields}
        dollarToUse={dollarToUse}
        isBRLCurrency={isBRLCurrency}
        loadCurrency={currencyExchange?.value === 'BRL' ? 'R$' : '$'}
      />
    </Col>
  );
}

function NegotiatedValuesEditing({
  negotiatedCardToggle,
  setNegotiatedCardToggle,
  freightMargin,
  isBRLCurrency,
  dollarToUse,
}) {
  function convertValue(rawValue) {
    const operation = isBRLCurrency ? '/' : '*';
    const processedValue = +makeConversion(rawValue, operation, dollarToUse);
    return operation === '/'
      ? processedValue.toFixed(4)
      : processedValue.toFixed(2);
  }
  return (
    <Col xs={12}>
      <Card containerCardBoxShadow={'0px 0px 10px rgba(0, 0, 0, 0.1)'}>
        <CardHeaderComponent
          label="Valores negociados"
          isOpen={negotiatedCardToggle}
          setIsOpen={() => setNegotiatedCardToggle(!negotiatedCardToggle)}
          tooltipContent="Valores negociados com transportador e cliente a partir dos valores principais"
          totalsSection={
            <HeaderTotalSummary
              label={'Frete tirar margem'}
              totalInReal={
                isBRLCurrency ? freightMargin : convertValue(freightMargin)
              }
              totalInDollar={
                !isBRLCurrency ? freightMargin : convertValue(freightMargin)
              }
            />
          }
        />
        {negotiatedCardToggle && (
          <>
            <LabelsRow />
            <FreightItemRow
              label={'Frete Tirar Margem'}
              value={freightMargin}
              isBRLCurrency={isBRLCurrency}
              dollarToUse={dollarToUse}
              shouldDisableField={true}
              hasTooltip
              tooltipContent="Base de cálculo para Frete Tirar Margem
(Valor Cliente Tomador - Valor Insumos)
"
              id="frete-tirar-margem"
            />
          </>
        )}
      </Card>
    </Col>
  );
}
