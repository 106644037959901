/* eslint-disable import/no-unresolved */
import React, { useState, useCallback, useEffect } from 'react';

import { Col, Row } from 'react-bootstrap';

import Card from 'v3/components/Card';
import Text from 'v3/components/Text';

import Button from 'v3/components/Button';
import useModal from 'hooks/useModal';

import Modal from './Modal';

const EmergencyContact = ({ setEmergencyContactData }) => {
  const modal = useModal();

  const [emergencyContact, setEmergencyContact] = useState({
    name: '',
    email: '',
    phone: '',
  });

  useEffect(() => {
    setEmergencyContactData(emergencyContact);
  }, [emergencyContact]);

  const renderItems = useCallback(() => {
    return (
      <>
        <Row>
          <Col md={12} xs={12}>
            <Text type="label" color="#464E5F">
              Nome
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {emergencyContact?.name || 'Não informado'}
            </Text>
          </Col>

          <Col md={6} xs={12}>
            <Text type="label" color="#464E5F">
              Telefone
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {emergencyContact?.phone || 'Não informado'}
            </Text>
          </Col>

          <Col md={6} xs={12}>
            <Text type="label" color="#464E5F">
              Email
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {emergencyContact?.email || 'Não informado'}
            </Text>
          </Col>
        </Row>
      </>
    );
  }, [emergencyContact]);

  return (
    <Card
      header={
        <Text color="#464E5F" type="header">
          Contato de emergência
        </Text>
      }
      HeaderRightComponent={
        <Button onClick={() => modal.open()}>
          <Text weight={600} type="regular">
            {!emergencyContact.name ? 'Adicionar' : 'Editar'}
          </Text>
        </Button>
      }
    >
      <Modal
        isOpen={modal.isOpen}
        onClose={modal.close}
        emergencyContact={emergencyContact}
        setEmergencyContact={setEmergencyContact}
      />
      {renderItems()}
    </Card>
  );
};

export default EmergencyContact;
