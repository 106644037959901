import styled from 'styled-components';
import theme from 'v3/theme';
import Input from 'v3/components/Input';

export const DeleteIcon = styled.button`
  border-radius: 5px;
  background: ${theme.colors.error};
  padding: 0 5px 5px;
`;

export const InputNumber = styled(Input)`
  -moz-appearance: textfield;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
