import styled from 'styled-components';
import { Row } from 'react-bootstrap';
import theme from '../../../theme';

export const SaveContainer = styled.div`
  background-color: ${theme.colors.dark_blue};
  padding: 15px;
  z-index: 998;
  position: fixed;
  top: 50px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  > button:first-of-type {
    margin-right: 10px;
  }
`;
