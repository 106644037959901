const getDigitsFromValue = (value = '') =>
  value.replace(/(-(?!\d))|[^0-9|]/g, '') || '';

const removeLeadingZeros = number => number.replace(/^0+([0-9]+)/, '$1');

function padDigits(digits) {
  const desiredLength = 3;
  const actualLength = digits.length;

  if (actualLength >= desiredLength) {
    return digits;
  }

  const amountToAdd = desiredLength - actualLength;
  const padding = '0'.repeat(amountToAdd);
  return padding + digits;
}

function addDecimalToNumber(number, separator, decimalDigits) {
  const centsStartingPosition = number.length - decimalDigits;
  const dollars = removeLeadingZeros(
    number.substring(0, centsStartingPosition)
  );
  const cents = number.substring(centsStartingPosition);

  if (cents.length < decimalDigits) {
    cents = '0'.repeat(decimalDigits - cents.length) + cents;
  }

  return dollars + separator + cents;
}

export function toCurrency(value, separator = '.', decimalDigits = 2) {
  const digits = getDigitsFromValue(value);
  const digitsWithPadding = padDigits(digits);

  return addDecimalToNumber(digitsWithPadding, separator, decimalDigits);
}
