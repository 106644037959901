/* eslint-disable import/no-unresolved */
import api from 'services/api';

export async function fetchItems(id) {
  try {
    const response = await api.get(`company/checklist/${id}`);
    return response.data;
  } catch (ex) {
    return [];
  }
}

async function createItems(items, id) {
  try {
    if (items.length === 0) {
      return;
    }
    await api.post(`company/checklist/${id}`, { items });
  } catch (ex) {
    // Handle exception
  }
}

async function updateItems(items) {
  try {
    if (items.length === 0) {
      return;
    }
    await api.put('company/checklist/update', { items });
  } catch (ex) {
    // Handle exception
  }
}
export async function syncItems({ items, id }, { setInFlight }) {
  setInFlight(true);
  const created = items
    .filter(item => !item.created_at)
    .map(item => item.title);

  const unsync = items
    .filter(item => item.dirty && item.created_at)
    .map(item => ({ id: item.id, title: item.title }));

  await createItems(created, id);
  await updateItems(unsync);

  setInFlight(false);
}
export async function deleteItem(id) {
  try {
    await api.delete(`company/checklist/${id}`);
  } catch (ex) {
    // Handle exception
  }
}
