import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import { KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons';
import { Text } from 'v3/components';
import { TravelStatusCard } from 'v3/components/TravelStatusCard';
import {
  CardShadowBoxStyled,
  ColStyled,
  DelayCardStyled,
  DelayInfoContainer,
  StyledSpan,
  TravelStatusCardsStyle,
} from '../Card/style';

export default function Indicators({ travelsStatus, fetchData }) {
  const [active, setActive] = useState(true);

  function onClick() {
    setActive(!active);
  }
  return (
    <>
      <Text
        fontSize={18}
        className="d-block mb-2"
        onClick={onClick}
        style={{
          cursor: 'pointer',
        }}
      >
        {active ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        Indicadores
      </Text>
      {active ? (
        <>
          <TravelStatusCardsStyle className="d-flex">
            <Col md={4}>
              <CardShadowBoxStyled>
                <ColStyled md={12}>
                  <Text fontSize={15}>PRÉ CARREGAMENTO</Text>
                </ColStyled>
                {travelsStatus?.map(i => {
                  const { title } = i;
                  const colunmName = i.columnName;
                  const { inTimeCount } = i;
                  const { delayedCount } = i;
                  const { noRasterCount } = i;
                  const { statusType } = i;
                  if (title === 'Pré Carregamento') {
                    return (
                      <TravelStatusCard
                        description={colunmName}
                        onTime={inTimeCount}
                        delayed={delayedCount}
                        noRaster={noRasterCount}
                        show
                        showMdfe={false}
                        fetchData={fetchData}
                        indicator={statusType}
                        ocurrences={false}
                        margins={false}
                        key={`${title}-${colunmName}`}
                      />
                    );
                  }
                })}
              </CardShadowBoxStyled>
            </Col>
            <Col md={4}>

              <CardShadowBoxStyled cardHeight={'188px'}  >
                <ColStyled md={12}>
                  <Text fontSize={15}>EM VIAGEM</Text>
                </ColStyled>
                {travelsStatus?.map(i => {
                  const { title } = i;
                  const colunmName = i.columnName;
                  const { inTimeCount } = i;
                  const { delayedCount } = i;
                  const { noRasterCount } = i;
                  const { statusType } = i;
                  if (title === 'Em viagem') {
                    return (
                      <TravelStatusCard
                        description={colunmName}
                        onTime={inTimeCount}
                        delayed={delayedCount}
                        noRaster={noRasterCount}
                        show
                        showMdfe={false}
                        fetchData={fetchData}
                        indicator={statusType}
                        ocurrences={false}
                        margins={false}
                        key={`${title}-${colunmName}`}
                      />
                    );
                  }
                })}
              </CardShadowBoxStyled>

              <CardShadowBoxStyled cardHeight={'fit-content'} MarginTop={'0.5rem'} >
                <ColStyled md={12}>
                  <Text fontSize={15}>PENDÊNCIAS</Text>
                </ColStyled>
                {travelsStatus?.map(i => {
                  const { title } = i;
                  const colunmName = i.columnName;
                  const { statusType } = i;
                  const { finishedOccurrency } = i;
                  const { pendingOccurrency } = i;
                  if (title === 'Em viagem 1') {
                    return (
                      <TravelStatusCard
                        description={colunmName}
                        onTime={finishedOccurrency}
                        noRaster={pendingOccurrency}
                        fetchData={fetchData}
                        indicator={statusType}
                        show={true}
                        showMdfe={false}
                        ocurrences={true}
                        margins={false}
                        key={`${title}-${colunmName}`}
                      />)
                  }
                })}
                {travelsStatus?.map(i => {
                  const { title } = i;
                  const colunmName = i.columnName;
                  const { statusType } = i;
                  const { reprovedMargin } = i;
                  const { pendingMargin } = i;
                  if (title === 'Em viagem 2') {
                    return (
                      <TravelStatusCard
                        description={colunmName}
                        delayed={reprovedMargin}
                        noRaster={pendingMargin}
                        fetchData={fetchData}
                        indicator={statusType}
                        show={true}
                        showMdfe={false}
                        ocurrences={false}
                        margins={true}
                        key={`${title}-${colunmName}`}
                      />)
                  }
                })}
                {travelsStatus?.map(i => {
                  const { title } = i;
                  const colunmName = i.columnName;
                  const { statusType } = i;
                  const { finishedOccurrency } = i;
                  const { pendingOccurrency } = i;
                  if (title === 'Em viagem 3') {
                    return (
                      <TravelStatusCard
                        description={colunmName}
                        onTime={finishedOccurrency}
                        noRaster={pendingOccurrency}
                        fetchData={fetchData}
                        indicator={statusType}
                        show={true}
                        showMdfe={false}
                        ocurrences={true}
                        margins={false}
                        key={`${title}-${colunmName}`}
                      />)
                  }
                })}
              </CardShadowBoxStyled>

            </Col>

            <Col md={4}>
              <CardShadowBoxStyled>
                <ColStyled md={12}>
                  <Text fontSize={15}>PRÉ E PÓS VENDA</Text>
                </ColStyled>
                {travelsStatus?.map(i => {
                  const {
                    title,
                    inTimeCount,
                    delayedCount,
                    noRasterCount,
                    statusType,
                    customIndicator,
                  } = i;
                  const colunmName = i.columnName;
                  if (title === 'Pós Venda') {
                    return (
                      <TravelStatusCard
                        description={colunmName}
                        onTime={inTimeCount}
                        delayed={delayedCount}
                        noRaster={noRasterCount}
                        show
                        fetchData={fetchData}
                        indicator={statusType}
                        showMdfe={false}
                        ocurrences={false}
                        margins={false}
                        key={`${title}-${colunmName}`}
                      />
                    );
                  }
                  if (title === 'Pós Venda - 1 Coluna') {
                    if (customIndicator !== undefined) {
                      return (
                        <TravelStatusCard
                          description={colunmName}
                          customIndicator={customIndicator}
                          fetchData={fetchData}
                          indicator={statusType}
                          key={`${title}-${colunmName}`}
                        />
                      );
                    }

                    return (
                      <TravelStatusCard
                        description={colunmName}
                        onTime={inTimeCount}
                        delayed={delayedCount}
                        noRaster={noRasterCount}
                        fetchData={fetchData}
                        indicator={statusType}
                        show={false}
                        showMdfe={false}
                        ocurrences={false}
                        margins={false}
                        key={`${title}-${colunmName}`}
                      />
                    );
                  }
                  if (title === 'Pós Venda - 2 Coluna') {
                    return (
                      <TravelStatusCard
                        description={colunmName}
                        onTime={inTimeCount}
                        delayed={delayedCount}
                        noRaster={noRasterCount}
                        fetchData={fetchData}
                        indicator={statusType}
                        show={false}
                        showMdfe={true}
                        ocurrences={false}
                        margins={false}
                        key={`${title}-${colunmName}`}
                      />
                    );
                  }
                })}
              </CardShadowBoxStyled>
            </Col>
          </TravelStatusCardsStyle>
          <DelayCardStyled>
            <DelayInfoContainer>
              <StyledSpan color="green" title="No prazo">
                No prazo
              </StyledSpan>
              <StyledSpan color="red" title="Fora do prazo">
                Fora do prazo
              </StyledSpan>
              <StyledSpan color="orange" title="Sem rastreamento">
                Sem rastreamento
              </StyledSpan>
              <StyledSpan color="blue" title="Autorizado">
                Autorizado
              </StyledSpan>
              <StyledSpan color="purple" title="Autorizado +15 Dias">
                Autorizado + 15 Dias
              </StyledSpan>
            </DelayInfoContainer>
          </DelayCardStyled>
        </>
      ) : null}
    </>
  );
}
