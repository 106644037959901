import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'hooks';
import {
  fetchCities,
  fetchCostCenter,
  fetchCustomer,
  fetchCustomers,
  fetchUser,
  fetchUsers,
  fetchVehiclesTypes,
} from 'utils/fetches';
import {
  Button,
  DatePicker,
  Input,
  Modal,
  Select,
  Text,
  Toggle,
} from 'v3/components';

import {
  LOAD_FILTER_DATE_OPTIONS,
  LOAD_FILTER_SORT_OPTIONS,
  LOAD_STATUS_OPTIONS,
} from 'v3/utils/constants';
import moment from 'moment';
import api from 'services/api';

export function Filter({
  isFetching,
  resultsTotal,
  showAdvancedFilters,
  setQtdAdvancedFilters,
  handleCloseAdvancedFilters,
  handleFilter,
}) {
  const query = useQuery();
  const history = useHistory();
  const [advancedFilters, setAdvancedFilters] = useState({
    startDate: LOAD_FILTER_DATE_OPTIONS[0].value,
    endDate: null,
    status: null,
    loadId: null,
    order: null,
    externalId: null,
    expired: '0',
    vehicleTypesIds: [],
    costCenter: [],
    clientOrderNumber: null,
    pcp: [],
  });
  const [origin, setOrigin] = useState();
  const [destination, setDestination] = useState();
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [loadingOptions, setLoadingOptions] = useState(true);
  const [errors, setErrors] = useState({});

  function setError(field, error) {
    setErrors(prev => ({ ...prev, [field]: error }));
  }

  useEffect(() => {
    if (advancedFilters.startDate && advancedFilters.endDate) {
      const startDate = moment(advancedFilters.startDate, 'DD/MM/YYYY', true);
      const endDate = moment(advancedFilters.endDate, 'DD/MM/YYYY', true);

      if (startDate.isAfter(endDate)) {
        setError(
          'startDate',
          'A data de ínicio precisa ser menor que a data final.'
        );
        setError(
          'endDate',
          'A data de fim deve ser maior que a data de início.'
        );
      }
    }
  }, [advancedFilters.startDate, advancedFilters.endDate]);
  async function fetchOptions() {
    try {
      setLoadingOptions(true);
      const response = await fetchVehiclesTypes();
      setVehicleTypes(response);
    } catch (error) {
    } finally {
      setLoadingOptions(false);
    }
  }

  useEffect(() => {
    fetchOptions();
  }, []);

  useEffect(() => {
    const isNotEmpty = value =>
      value !== null && value !== undefined && value !== '' && value !== 0;

    const validateAdvancedFilters = {
      startDate: advancedFilters?.startDate,
      endDate: advancedFilters?.endDate,
      status: advancedFilters?.status?.label,
      vehicleTypeIds: advancedFilters?.vehicleTypesIds?.length,
      order: advancedFilters?.order,
      loadId: advancedFilters?.loadId,
      externalId: advancedFilters?.externalId,
      costCenter: advancedFilters?.costCenter?.length,
      pcp: advancedFilters?.pcp?.length,
      customers: advancedFilters?.customers?.length,
      clientOrderNumber: advancedFilters?.clientOrderNumber,
      expired: advancedFilters?.expired in ['0', '1'] ? 'check' : '',
    };

    setQtdAdvancedFilters(
      Object.values(validateAdvancedFilters).filter(isNotEmpty).length
    );
  }, [advancedFilters]);

  useEffect(() => {
    if (origin || destination || advancedFilters) {
      applyQueryParameters();
    }
  }, [advancedFilters, origin, destination]);

  useEffect(() => {
    if (!loadingOptions) {
      getQueryParams();
    }
  }, [loadingOptions]);

  function parseDate(value) {
    if (moment(value, 'DD/MM/YYYY').isValid()) {
      return moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }

    return null;
  }

  function applyQueryParameters() {
    const queryParams = query.toString();
    history.replace({ search: queryParams });
  }

  async function handleParamPcp(pcps) {
    if (pcps && pcps.length > 0) {
      const finalPcpData = [];
      await Promise.all(
        pcps.map(async item => {
          const responseUser = await fetchUser(item);
          if (responseUser?.id) {
            finalPcpData.push(responseUser);
          }
        })
      );

      setAdvancedFilters(old => ({
        ...old,
        pcp: finalPcpData,
      }));
    }
  }
  async function handleParamsCustomers(customers) {
    if (customers && customers.length > 0) {
      const finalCustomersData = [];
      await Promise.all(
        customers.map(async item => {
          const responseUser = await fetchCustomer(item);
          if (responseUser?.id) {
            finalCustomersData.push(responseUser);
          }
        })
      );
      setAdvancedFilters(old => ({
        ...old,
        customers: finalCustomersData,
      }));
    }
  }

  async function handleParamCostCenter(costCenters) {
    if (costCenters && costCenters.length > 0) {
      const allCCs = [];
      await Promise.all(
        costCenters.map(async cc => {
          const responseCostCenter = await api.get(`v3/cost-center/${cc}`);
          if (responseCostCenter?.data?.id) {
            allCCs.push(responseCostCenter?.data);
          }
        })
      );
      setAdvancedFilters(old => ({
        ...old,
        costCenter: allCCs,
      }));
    }
  }

  async function getQueryParams() {
    if (query.has('startDate')) {
      setAdvancedFilters(old => ({
        ...old,
        startDate: moment(query.get('startDate')).format('DD/MM/YYYY'),
      }));
    }

    if (query.has('endDate')) {
      setAdvancedFilters(old => ({
        ...old,
        endDate: moment(query.get('endDate')).format('DD/MM/YYYY'),
      }));
    }

    if (query.has('status')) {
      setAdvancedFilters(old => ({
        ...old,
        status: { label: query.get('status') },
      }));
    }

    if (query.has('loadId')) {
      setAdvancedFilters(old => ({ ...old, loadId: query.get('loadId') }));
    }

    if (query.has('order')) {
      const order = LOAD_FILTER_SORT_OPTIONS.find(
        item => item.value === query.get('order')
      );
      setAdvancedFilters(old => ({ ...old, order: order }));
    }

    if (query.has('externalId')) {
      setAdvancedFilters(old => ({
        ...old,
        externalId: query.get('externalId'),
      }));
    }

    if (query.has('expired')) {
      setAdvancedFilters(old => ({ ...old, expired: query.get('expired') }));
    }

    if (query.has('vehicleTypesIds[]')) {
      const selectedVehiclesIds = query.getAll('vehicleTypesIds[]').map(Number);
      const selectedVehiclesTypes = vehicleTypes.filter(item =>
        selectedVehiclesIds.includes(item.id)
      );
      setAdvancedFilters(old => ({
        ...old,
        vehicleTypesIds: selectedVehiclesTypes,
      }));
    }

    if (query.has('costCenterIds[]')) {
      await handleParamCostCenter(query.getAll('costCenterIds[]'));
    }
    if (query.has('customers[]')) {
      await handleParamsCustomers(query.getAll('customers[]'));
    } else {
      query.delete('customers[]');
    }

    if (query.has('originCityName') && query.has('originCityId')) {
      setOrigin({
        id: query.get('originCityId'),
        name: query.get('originCityName'),
      });
    }
    if (query.has('originProvinceName') && query.has('originProvinceId')) {
      setOrigin(old => ({
        ...old,
        province: {
          id: query.get('originProvinceId'),
          uf: query.get('originProvinceName'),
        },
      }));
    }

    if (query.has('destinationCityName') && query.has('destinationCityId')) {
      setDestination({
        id: query.get('destinationCityId'),
        name: query.get('destinationCityName'),
      });
    }
    if (query.has('destinationProvinceName') && query.has('destinationProvinceId')) {
      setDestination(old => ({
        ...old,
        province: {
          id: query.get('destinationProvinceId'),
          uf: query.get('destinationProvinceName'),
        },
      }));
    }


    if (query.get('pcp[]')) {
      handleParamPcp(query.getAll('pcp[]'));
    } else {
      query.delete('pcp[]');
    }
  }

  return (
    <>
      <Modal
        scrollable
        size="lg"
        backdrop="static"
        heading="Filtro Avançado"
        onHide={handleCloseAdvancedFilters}
        show={showAdvancedFilters}
        body={
          <Row>
            <Col xs={12} className="mb-2">
              <Select.Async
                label="Cliente Tomador CPF/CNPJ"
                multiple
                onSearch={search => fetchCustomers({ search })}
                value={advancedFilters.customers}
                onChange={value => {
                  if (Array.isArray(value)) {
                    query.delete('customers[]');
                    value
                      .map(i => i.id)
                      .forEach(processed =>
                        query.append('customers[]', processed)
                      );
                  } else {
                    query.delete('customers[]');
                  }
                  setAdvancedFilters(old => ({ ...old, customers: value }));
                }}
                getOptionLabel={option => {
                  let label = `${option?.social_name} - ${option?.cgccpf}`;
                  if (option.city) {
                    label += ` - ${option?.city}`;
                  }
                  return label;
                }}
                getOptionValue={option => option.id}
              />
            </Col>
            <Col xs={12}>
              <Select.Async
                label="PCP"
                multiple
                onSearch={search => fetchUsers(search)}
                value={advancedFilters.pcp}
                onChange={value => {
                  if (Array.isArray(value)) {
                    query.delete('pcp[]');
                    value
                      .map(i => i.id)
                      .forEach(processed => query.append('pcp[]', processed));
                  } else {
                    query.delete('pcp[]');
                  }
                  setAdvancedFilters(old => ({ ...old, pcp: value }));
                }}
                getOptionLabel={option => option.username}
                getOptionValue={option => option.id}
              />
            </Col>
            <Col md={4} xs={12} className="mb-2">
              <DatePicker
                value={advancedFilters?.startDate}
                onChange={e => {
                  if (e?.target?.value) {
                    query.set('startDate', parseDate(e?.target?.value));
                  } else {
                    query.delete('startDate');
                  }
                  setAdvancedFilters(old => ({
                    ...old,
                    startDate: e?.target?.value,
                  }));
                  setError('startDate', null);
                  setError('endDate', null);
                }}
                label="Período de"
                error={errors?.startDate}
              />
            </Col>
            <Col md={4} xs={12} className="mb-2">
              <DatePicker
                value={advancedFilters?.endDate}
                onChange={e => {
                  if (e?.target?.value) {
                    query.set('endDate', parseDate(e?.target?.value));
                  } else {
                    query.delete('endDate');
                  }
                  setAdvancedFilters(old => ({
                    ...old,
                    endDate: e?.target?.value,
                  }));
                  setError('startDate', null);
                  setError('endDate', null);
                }}
                label="Até"
                error={errors?.endDate}
              />
            </Col>
            <Col md={4} xs={12} className="mb-2">
              <Select
                label="Status"
                value={advancedFilters?.status}
                options={LOAD_STATUS_OPTIONS}
                getOptionValue={option => option.label}
                onChange={value => {
                  if (value) {
                    query.set('status', value?.label);
                  } else {
                    query.delete('status');
                  }
                  setAdvancedFilters(old => ({ ...old, status: value }));
                }}
              />
            </Col>
            <Col md={4} xs={12} className="mb-2">
              <Select
                label="Tipo de veículo"
                value={advancedFilters.vehicleTypesIds}
                multiple
                options={vehicleTypes}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                onChange={value => {
                  if (Array.isArray(value)) {
                    query.delete('vehicleTypesIds[]');
                    value
                      .map(i => i.id)
                      .forEach(processed => {
                        query.append('vehicleTypesIds[]', processed);
                      });
                  } else {
                    query.delete('vehicleTypesIds[]');
                  }
                  setAdvancedFilters(old => ({
                    ...old,
                    vehicleTypesIds: value,
                  }));
                }}
              />
            </Col>
            <Col md={4} xs={12} className="mb-2">
              <Select
                label="Ordenação"
                value={advancedFilters?.order}
                options={LOAD_FILTER_SORT_OPTIONS}
                getOptionLabel={option => option.label}
                getOptionValue={option => option.value}
                onChange={value => {
                  if (value) {
                    query.set('order', value?.value);
                  } else {
                    query.delete('order');
                  }
                  setAdvancedFilters(old => ({ ...old, order: value }));
                }}
              />
            </Col>
            <Col md={4} xs={12} className="mb-2">
              <Input
                label="Número da carga"
                type="number"
                value={advancedFilters?.loadId}
                onKeyDown={evt =>
                  ['e', 'E', '+', '-', '.'].includes(evt.key) &&
                  evt.preventDefault()
                }
                onChange={e => {
                  if (e.target.value) {
                    query.set('loadId', e?.target?.value);
                  } else {
                    query.delete('loadId');
                  }
                  setAdvancedFilters(old => ({
                    ...old,
                    loadId: e.target.value,
                  }));
                }}
              />
            </Col>

            <Col md={4} xs={12} className="mb-2">
              <Input
                label="ID Externo"
                type="text"
                value={advancedFilters?.externalId}
                onChange={e => {
                  if (e?.target?.value) {
                    query.set('externalId', e.target.value);
                  } else {
                    query.delete('externalId');
                  }
                  setAdvancedFilters(old => ({
                    ...old,
                    externalId: e.target.value,
                  }));
                }}
              />
            </Col>
            <Col md={6} xs={12} className="mb-2">
              <Select.Async
                label="Centro de custo"
                multiple
                onSearch={search => fetchCostCenter({ search })}
                value={advancedFilters?.costCenter}
                onChange={value => {
                  if (Array.isArray(value)) {
                    query.delete('costCenterIds[]');
                    value
                      .map(i => i.id)
                      .forEach(processed =>
                        query.append('costCenterIds[]', processed)
                      );
                  } else {
                    query.delete('costCenterIds[]');
                  }
                  setAdvancedFilters(old => ({ ...old, costCenter: value }));
                }}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
              />
            </Col>
            <Col md={6} xs={12} className="mb-2">
              <Input
                label="Número Pedido Cliente"
                type="text"
                value={advancedFilters?.clientOrderNumber}
                onChange={e => {
                  if (e?.target?.value?.length > 25) {
                    return;
                  }
                  if (e.target.value) {
                    query.set('clientOrderNumber', e.target.value);
                  } else {
                    query.delete('clientOrderNumber');
                  }
                  setAdvancedFilters(old => ({
                    ...old,
                    clientOrderNumber: e.target.value,
                  }));
                }}
              />
            </Col>
            <Col md={4} xs={12} className="mb-2">
              <Toggle
                buttonSize="sm"
                defaultValue={advancedFilters.expired}
                label="Pré Cargas vencidas"
                value={advancedFilters.expired}
                options={[
                  { label: 'Exibir', value: '1' },
                  { label: 'Ocultar', value: '0' },
                ]}
                onSelected={value => {
                  query.set('expired', value);
                  setAdvancedFilters(old => ({ ...old, expired: value }));
                }}
              />
            </Col>
          </Row>
        }
        footer={
          <Row style={{ justifyContent: 'flex-end' }}>
            <Button
              variant="success"
              onClick={() => {
                handleFilter();
                handleCloseAdvancedFilters();
              }}
            >
              <Text weight={500} type="regular">
                Filtrar
              </Text>
            </Button>
          </Row>
        }
      />
      <Row style={{ marginBottom: '1rem' }}>
        <Col md={3} xs={12}>
          <Toggle
            label="Coleta agendada há"
            labelColor={'#fff'}
            value={advancedFilters?.startDate}
            options={LOAD_FILTER_DATE_OPTIONS}
            onSelected={value => {
              if (value) {
                query.set('startDate', parseDate(value));
              } else {
                query.delete('startDate');
              }
              setAdvancedFilters(old => ({
                ...old,
                startDate: value,
                endDate: null,
              }));
            }}
          />
        </Col>
        <Col md={4} xs={12}>
          <Select.Async
            label="Origem"
            onSearch={fetchCities}
            value={origin}
            onChange={value => {
              setOrigin(value);
              if (value) {
                query.set('originCityId', value.id);
                query.set('originCityName', value.name);
                if (value?.province) {
                  query.set('originProvinceName', value?.province?.uf);
                  query.set('originProvinceId', value?.province?.id);
                }
              } else {
                query.delete('originCityName');
                query.delete('originCityId');
                query.delete('originProvinceName');
                query.delete('originProvinceId');
              }
            }}
            getOptionLabel={option => {
              if (option.province) {
                return `${option.name}, ${option.province.uf}`;
              }

              return `${option.name}`;
            }}
            getOptionValue={option => option.id}
            labelColor="#fff"
          />
        </Col>
        <Col md={4} xs={12}>
          <Select.Async
            label="Destino"
            onSearch={fetchCities}
            value={destination}
            onChange={value => {
              setDestination(value);

              if (value) {
                query.set('destinationCityName', value.name);
                query.set('destinationCityId', value.id);
                if (value?.province) {
                  query.set('destinationProvinceName', value?.province?.uf);
                  query.set('destinationProvinceId', value?.province?.id);
                }
              } else {
                query.delete('destinationCityName');
                query.delete('destinationCityId');
                query.delete('destinationProvinceName');
                query.delete('destinationProvinceId');
              }
            }}
            getOptionLabel={option => {
              if (option.province) {
                return `${option.name}, ${option.province.uf}`;
              }

              return `${option.name}`;
            }}
            getOptionValue={option => option.id}
            labelColor="#fff"
          />
        </Col>
        <Col md={1} xs={12} style={{ display: 'flex', alignItems: 'flex-end' }}>
          <Button variant="success" onClick={() => handleFilter()}>
            <Text type="regular" weight={500}>
              Filtrar
            </Text>
          </Button>
        </Col>
        <Col
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: 0,
          }}
        >
          {!isFetching && !!resultsTotal && (
            <Text type="regular" color="white" weight="300">
              {resultsTotal} pré cargas
            </Text>
          )}
        </Col>
      </Row>
    </>
  );
}
