import React, { useEffect, useState } from 'react';

import Text from 'v3/components/Text';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';
import { validatePermission } from 'actions';
import { SwitchOption } from 'v3/components';

import api from 'services/api';
import Snackbar from 'v3/components/Snackbar';

export default function PersonalData({ driver, isBrazilian }) {
  const [active, setActive] = useState(driver.deleted_at ? false : true);

  function formatDate(date) {
    if (date) return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
    return 'Sem data';
  }

  async function toggleActive() {
    try {
      await api.put(`/persons/${driver.id}`, {
        cgccpf: driver.cgccpf,
        deleted_at: active ? moment() : null,
      });

      setActive(old => !old);
      driver.deleted_at = active ? moment() : null;
    } catch {
      Snackbar.show(<Text> Erro ao alterar motorista! </Text>, {
        type: 'error',
      });
    }
  }

  useEffect(() => {
    if (driver.deleted_at) {
      setActive(false);
    } else setActive(true);
  }, []);

  return (
    <Row>
      {validatePermission('ATIVAR_MOTORISTAS') && (
        <Col xs={12} md={12} as="p">
          <SwitchOption value={active} onClick={() => toggleActive()} />
        </Col>
      )}
      <Col xs={12}>
        <Text type="label">Estrangeiro</Text>

        <Text type="regular" color="gray" as="p" className="mt-2">
          {driver && driver.is_international ? 'Sim' : 'Não'}
        </Text>
      </Col>
      <Col xs={12}>
        <Text type="label">Nome</Text>

        <Text type="regular" color="gray" as="p" className="mt-2">
          {driver && driver.social_name}
        </Text>
      </Col>
      <Col lg={6} md={6} xs={12}>
        <Text type="label">{isBrazilian ? 'CPF' : 'Documento'}</Text>

        <Text type="regular" color="gray" as="p" className="mt-2">
          {driver && driver.cgccpf}
        </Text>
      </Col>
      <Col lg={6} md={6} xs={12}>
        <Text type="label">Data de nascimento</Text>

        <Text type="regular" color="gray" as="p" className="mt-2">
          {(driver && formatDate(driver.birthdate)) || '-'}
        </Text>
      </Col>
      <Col xs={12}>
        <Text type="label">Cidade natal</Text>

        <Text type="regular" color="gray" as="p" className="mt-2">
          {driver && driver.born_city ? driver.born_city.name : '-'}
        </Text>
      </Col>
      <Col lg={6} md={6} xs={12}>
        <Text type="label">RG</Text>

        <Text type="regular" color="gray" as="p" className="mt-2">
          {(driver && driver.rg) || '-'}
        </Text>
      </Col>
      <Col lg={6} md={6} xs={12}>
        <Text type="label">Orgão emissor / UF</Text>

        <Text type="regular" color="gray" as="p" className="mt-2">
          {(driver && driver.rg_emissor) || '-'}
        </Text>
      </Col>
      <Col lg={6} md={6} xs={12}>
        <Text type="label">Data de emissão RG</Text>

        <Text type="regular" color="gray" as="p" className="mt-2">
          {(driver && formatDate(driver.rg_dispatch_date)) || '-'}
        </Text>
      </Col>
      <Col xs={12}>
        <Text type="label">Mãe</Text>

        <Text type="regular" color="gray" as="p" className="mt-2">
          {(driver && driver.mothers_name) || '-'}
        </Text>
      </Col>
      <Col xs={12}>
        <Text type="label">Pai</Text>

        <Text type="regular" color="gray" as="p" className="mt-2">
          {(driver && driver.father_name) || '-'}
        </Text>
      </Col>

      <Col lg={6} md={6} xs={12}>
        <Text type="label">Estado civil</Text>

        <Text type="regular" color="gray" as="p" className="mt-2">
          {(driver && driver.civilStatus && driver.civilStatus.name) || '-'}
        </Text>
      </Col>
      <Col lg={6} md={6} xs={12}>
        <Text type="label">PIS</Text>

        <Text type="regular" color="gray" as="p" className="mt-2">
          {(driver && driver.pispasep) || '-'}
        </Text>
      </Col>
      <Col lg={6} md={6} xs={12}>
        <Text type="label">Quantidade de viagens</Text>
        <Text type="regular" color="gray" as="p" className="mt-2">
          {typeof driver?.driver?.travels_quantity === 'number' ? driver?.driver?.travels_quantity : 'Não informado'}
        </Text>
      </Col>
      {driver && driver.driverTags.length > 0 && (
        <Col xs={12}>
          <Text type="label">Tags</Text>

          <Text type="regular" color="gray" as="p" className="mt-2">
            {driver.driverTags.map(tag => tag.name).join(', ')}
          </Text>
        </Col>
      )}
    </Row>
  );
}
