const Yup = require('yup');

const schema = Yup.object().shape({
  serie: Yup.number()
    .typeError('Série obrigatória')
    .required('Série obrigatória'),
  number: Yup.number()
    .typeError('Série obrigatória')
    .required('Número obrigatório'),
});

const lengthSchema = Yup.object().shape({
  list: Yup.array().min(1).required('Informe pelo menos uma série!'),
});

export default schema;
export { lengthSchema };
