import React, { useState, useCallback, useEffect, useMemo } from 'react';
import dashboardApi from 'services/apis/dashboard';
import { Text, Select } from 'v3/components';
import { formatCurrency } from 'v3/utils/formatter';
import { useQuery } from 'hooks';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';

import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import api from 'services/api';
import { DateOptions } from './Card/utils';
import Card, { RightWingNumber, Description } from './Card';

const months = [
  { name: 'janeiro', number: 1 },
  { name: 'fevereiro', number: 2 },
  { name: 'março', number: 3 },
  { name: 'abril', number: 4 },
  { name: 'maio', number: 5 },
  { name: 'junho', number: 6 },
  { name: 'julho', number: 7 },
  { name: 'agosto', number: 8 },
  { name: 'setembro', number: 9 },
  { name: 'outubro', number: 10 },
  { name: 'novembro', number: 11 },
  { name: 'dezembro', number: 12 },
];

const COLORS = [
  '#0088FE',
  '#FF0000',
  '#943619',
  '#36F5EA',
  '#FFF61C',
  '#FF80B5',
  '#73C164',
  '#F57E10',
  '#52FA63',
  // '#5552FA',
];

export default function RevenuesPerClient({ dashboard, name, id }) {
  const [isFetching, setFetching] = useState(false);
  const [clients, setClients] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [currentDate, setDate] = useState({});
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [error, setError] = useState({});

  const queryParams = useQuery();

  function setQueryParams(filters) {
    const { tags = [] } = filters;
    queryParams.delete('tags');

    if (tags) {
      tags.forEach(tag => {
        queryParams.append('tags', tag.id);
      });
    }

    if (tags?.length === 0) {
      queryParams.set('no-tags', true);
    } else {
      queryParams.delete('no-tags');
    }
  }

  const MonthsArray = useMemo(() => {
    let monthsArray = [];
    if (currentDate.label === 'Mês atual') {
      monthsArray = [
        moment().subtract(1, 'months').format('M'),
        moment().format('M'),
      ];
    }
    if (currentDate.label === 'Últimos três meses') {
      monthsArray = new Array(3).fill(0).map((_, index) =>
        moment()
          .subtract(index + 1, 'months')
          .format('M')
      );
    }
    if (currentDate.label === 'Últimos seis meses') {
      monthsArray = new Array(6).fill(0).map((_, index) =>
        moment()
          .subtract(index + 1, 'months')
          .format('M')
      );
    }

    return monthsArray;
  }, [currentDate]);

  async function handleChangeDate(value, setSelectedDate, setValues, values) {
    setSelectedDate(value);
    setValues(old => ({ ...old, date: value }));
    const payload = {
      clients,
      sizes: values.size,
      component: name,
      date: value,
      card_id: id,
    };
    setDate(value);

    await api.put(`v3/dashboard/${dashboard.id}/parameters`, payload);
  }

  useEffect(() => {
    const parsed = JSON.parse(dashboard?.parameters);
    const currentClients = parsed.items.find(
      item => item?.card_id === id
    )?.clients;
    if (currentClients) {
      setClients(currentClients);
    }
  }, []);

  function handleResponse(data) {
    const array = [];

    data.forEach(date => {
      const client = clients.find(
        clientItem => clientItem.id === date[0].client_id
      ).label;
      array.push(
        MonthsArray.map(month => {
          return (
            date
              .map(item => ({
                ...item,
                monthName: months[month - 1].name,
                client,
              }))
              ?.find(item => {
                return `${item?.month}` === month;
              }) || {
              sum_per_client: 0,
              client,
              monthName: months[month - 1].name,
            }
          );
        })
      );
    });
    if (currentDate.label === 'Mês atual') {
      return array;
    }
    return array.map(item => item.reverse());
  }

  function sumarize(data) {
    const sumArray = data.flatMap(item => item);

    let sum = 0;

    sumArray.forEach(item => {
      sum += item.sum_per_client;
    });

    setTotalRevenue(sum);
  }

  const fetchRevenuesPerClient = useCallback(
    async (filters = {}) => {
      if (!clients?.length) {
        setError({ revenuesPerClient: 'Escolha ao menos um cliente!' });
        return;
      }

      try {
        const { date = {}, companies } = filters;
        setFetching(true);
        setQueryParams(filters);

        setDate(date);

        const response = await dashboardApi.get('revenues-per-client', {
          params: {
            clients: clients?.map(client => client.id),
            start_date: date.value?.start_date,
            end_date: date.value?.end_date,
            companies: companies?.id,
          },
        });
        sumarize(response.data);
        setChartData(handleResponse(response.data));
        setError({});
      } catch (ex) {
        // Handle exception
      } finally {
        setFetching(false);
      }
    },
    [clients, currentDate]
  );

  async function onSearchClients(search) {
    try {
      const response = await api.get('persons/customers', {
        params: { search },
      });
      return response.data;
    } catch (ex) {
      return [];
    }
  }

  async function handleAddClient(value) {
    const payload = {
      clients: value || [],
      component: name,
      date: currentDate,
      card_id: id,
    };

    await api.put(`v3/dashboard/${dashboard.id}/parameters`, payload);
  }

  return (
    <Card
      title="Faturamento por cliente"
      isLoading={isFetching}
      onFiltersChanged={fetchRevenuesPerClient}
      dashboard={dashboard}
      name={name}
      clients={clients}
      dateFilter
      id={id}
      dates={DateOptions.trimestral}
      handleChangeDate={handleChangeDate}
      dateFilterOptionLabel={option => option.label}
      dateFilterOptionValue={option => option.value}
      showClients={false}
      excludeFilterByBranchOffice={true}
      setDate={setDate}
      filterByCompanyMultiple={false}
      onlyCompanyFilter
      NumericComponent={
        <Row>
          <Col md={6}>
            <Select.Async
              value={clients}
              onSearch={onSearchClients}
              error={error.revenuesPerClient}
              onChange={value => {
                setClients(value);
                handleAddClient(value);
              }}
              multiple
              getOptionLabel={option => {
                let label = `${option?.social_name} - ${option?.cgccpf}`;

                if (option?.city) {
                  label += ` - ${option?.city}`;
                }

                return label;
              }}
              getOptionValue={option => option.id}
            />
          </Col>
          <Col md={6} className="mb-3">
            <div className="d-flex justify-content-center text-center flex-column">
              <RightWingNumber>{formatCurrency(totalRevenue)}</RightWingNumber>
              <Description>{currentDate?.label}</Description>
            </div>
          </Col>
          {clients?.length > 0 && (
            <Col className="mt-3">
              <Text>
                <ResponsiveContainer width="100%" height={300}>
                  <ScatterChart
                    width={400}
                    height={300}
                    margin={{
                      top: 10,
                      right: 10,
                      bottom: 10,
                      left: 10,
                    }}
                  >
                    <CartesianGrid />
                    <XAxis
                      name="mês"
                      dataKey="monthName"
                      allowDuplicatedCategory={false}
                    />
                    <YAxis type="number" dataKey="sum_per_client" />
                    <ZAxis type="number" range={[100]} />
                    <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                    <Legend />
                    {chartData?.map((chart, index) => {
                      return (
                        <Scatter
                          name={chart[0].client}
                          data={chart}
                          fill={COLORS[index % COLORS.length]}
                          line
                          shape="dot"
                        />
                      );
                    })}
                  </ScatterChart>
                </ResponsiveContainer>
              </Text>
            </Col>
          )}
        </Row>
      }
    />
  );
}
