import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { useModal, usePermission } from 'hooks';
import { cooperplaceApi } from 'services/api';
import ApplicationLayout from 'v3/layouts/Application';
import { Button, Card, DataTable, Fallback, Text } from 'v3/components';
import { useSnackbar } from 'v3/components/Snackbar';
import { DefaultLoadingComponent } from 'v3/components/List';
import { validatePermission } from 'actions';
import { UserContext } from 'contexts/UserContext';
import { RegisterModal } from './Modals/RegisterModal';
import { validateBusinessData } from '../shared/validator';

export function DefaultMinimumMargin() {
  const { selectedCompany } = useContext(UserContext);
  usePermission('VISUALIZAR_MARGEM_ADIANTAMENTO_MINIMO', { redirect: true });
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [errors, setErrors] = useState({});
  const [availableBusiness, setAvailableBusiness] = useState([]);
  const [businessMargins, setBusinessMargins] = useState([]);
  const [serviceLevels, setServiceLevels] = useState([]);
  const [pagination, setPagination] = useState({});
  const [pageOptions, setPageOptions] = useState({
    page: 1,
    perPage: 5,
  });
  const hasPermissionToManipulateValues = validatePermission(
    'ALTERAR_MARGEM_ADIANTAMENTO_MINIMO'
  );

  const [businessValues, setBusinessValues] = useState({
    business: null,
    serviceLevels: {
      ouro: {
        margin: '0',
        advance: '0',
      },
      agregado: {
        margin: '0',
        advance: '0',
      },
      terceiro: {
        margin: '0',
        advance: '0',
      },
    },
  });

  const snackbar = useSnackbar();

  const registerModal = useModal();
  function handleOpenRegisterModal() {
    return registerModal.open();
  }
  function handleCloseRegisterModal() {
    setErrors({});
    setBusinessValues({
      business: null,
      serviceLevels: {
        ouro: {
          margin: '0',
          advance: '0',
        },
        agregado: {
          margin: '0',
          advance: '0',
        },
        terceiro: {
          margin: '0',
          advance: '0',
        },
      },
    });
    registerModal.close();
  }

  function handleEditBusiness(selectedBusiness) {
    setEditing(true);
    transformToState(selectedBusiness);
    registerModal.open();
  }

  const fetchAvailableBusiness = useCallback(async (search = '') => {
    try {
      const { data } = await cooperplaceApi.get(
        `/businesses/for-select?available=true&search=${search}`
      );
      setAvailableBusiness(data);
      return;
    } catch (error) {
      setAvailableBusiness([]);
    }
  }, []);

  const fetchBusinessMargins = useCallback(async params => {
    try {
      setLoading(true);
      const { data } = await cooperplaceApi.get(
        `/businesses?page=${params?.page}&perPage=${params.perPage}`
      );
      setBusinessMargins(data?.data);
      setPagination(data?.meta);
      return;
    } catch (error) {
      setBusinessMargins([]);
      setPagination({});
      return;
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchAvailableServiceLevels = useCallback(async () => {
    try {
      const { data } = await cooperplaceApi.get(`service-levels`);
      setServiceLevels(data);
      return;
    } catch (error) {
      setServiceLevels([]);
    }
  }, []);

  async function fetchInitialData() {
    try {
      Promise.all([
        fetchAvailableBusiness(),
        fetchAvailableServiceLevels(),
        fetchBusinessMargins(pageOptions),
      ]);
    } catch (error) {
      snackbar.show(
        <Text>
          Não foi possível carregar todas as opções de filtro. Recarregue a
          página.
        </Text>,
        {
          type: 'error',
          showClose: true,
        }
      );
    }
  }

  const transformToPayload = businessValues => {
    const { business, serviceLevels } = businessValues;

    const payload = [];

    Object.keys(serviceLevels).forEach(levelKey => {
      const level = serviceLevels[levelKey];

      payload.push({
        value: parseFloat(level.margin),
        type: 'MARGEM_MINIMA',
        servicesLevelsId: getServiceLevelId(levelKey),
        businessesId: business ? business.id : null,
      });

      payload.push({
        value: parseFloat(level.advance),
        type: 'MAXIMO_ADIANTAMENTO',
        servicesLevelsId: getServiceLevelId(levelKey),
        businessesId: business ? business.id : null,
      });
    });

    return payload;
  };

  const getServiceLevelId = levelKey => {
    switch (levelKey) {
      case 'ouro':
        return serviceLevels?.find(item => item?.name?.toLowerCase() === 'ouro')
          ?.id;
      case 'agregado':
        return serviceLevels?.find(
          item => item?.name?.toLowerCase() === 'agregado'
        )?.id;
      case 'terceiro':
        return serviceLevels?.find(
          item => item?.name?.toLowerCase() === 'terceiro'
        )?.id;
      default:
        return null;
    }
  };

  function getServiceLevelKey(serviceLevelId) {
    const serviceLevelKey = serviceLevels.find(
      item => item.id === serviceLevelId
    )?.name;
    return serviceLevelKey?.toLowerCase();
  }

  const transformToState = rowValue => {
    const newState = {
      serviceLevels: {
        ouro: {
          margin: '0',
          advance: '0',
        },
        agregado: {
          margin: '0',
          advance: '0',
        },
        terceiro: {
          margin: '0',
          advance: '0',
        },
      },
    };

    const { businessMargins } = rowValue;

    businessMargins.forEach(item => {
      const levelKey = getServiceLevelKey(item.servicesLevelId);
      const serviceLevel = newState.serviceLevels[levelKey];

      if (item.type === 'MARGEM_MINIMA') {
        serviceLevel.margin = item.value;
      } else if (item.type === 'MAXIMO_ADIANTAMENTO') {
        serviceLevel.advance = item.value;
      }
    });

    const business = {
      id: rowValue?.id,
      name: rowValue?.name,
    };

    setBusinessValues({
      business: { ...business },
      serviceLevels: { ...newState.serviceLevels },
    });
  };

  async function handleCreate(
    method = 'post',
    url = 'businesses',
    successMessage = 'Valores do negócio cadastrado com sucesso!',
    errorMessage = 'Erro ao tentar cadastrar valores para negócio'
  ) {
    try {
      setLoading(true);
      const isValid = await validateBusinessData(
        {
          business: businessValues?.business,
          serviceLevels: { ...businessValues?.serviceLevels },
        },
        setErrors
      );
      if (!isValid) {
        snackbar.show(<Text>Verifique campos obrigatórios</Text>, {
          type: 'error',
        });
        return false;
      }
      const businessMargins = transformToPayload(businessValues);
      if (method === 'post') {
        await cooperplaceApi.post(url, {
          businessMargins,
        });
      }
      if (method === 'put') {
        await cooperplaceApi.put(url, {
          businessMargins,
        });
      }

      await fetchBusinessMargins(pageOptions);
      if (method === 'post') {
        await fetchAvailableBusiness();
      }
      snackbar.show(<Text>{successMessage}</Text>, {
        type: 'success',
      });
      handleCloseRegisterModal();
    } catch (error) {
      const message = error?.response?.data?.error || errorMessage;
      snackbar.show(<Text weight={500}>{message}</Text>, {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }

  function onPageChanged(value) {
    const params = {
      ...pageOptions,
      page: value,
    };
    setPageOptions(params);
    fetchBusinessMargins(params);
  }

  function onPerPageChanged(value) {
    const params = {
      ...pageOptions,
      perPage: value,
    };
    setPageOptions(params);
    fetchBusinessMargins(params);
  }

  useEffect(() => {
    fetchInitialData();
  }, [selectedCompany.id]);

  return (
    <ApplicationLayout
      title="Margem e adiantamento de frete padrão"
      RightComponent={
        hasPermissionToManipulateValues &&
        availableBusiness.length > 0 && (
          <Button variant="secondary" onClick={handleOpenRegisterModal}>
            <Text weight={500}>Novo negócio</Text>
          </Button>
        )
      }
    >
      <RegisterModal
        showModal={registerModal.isOpen}
        handleCloseModal={handleCloseRegisterModal}
        businessValues={businessValues}
        setBusinessValues={setBusinessValues}
        availableBusiness={availableBusiness}
        errors={errors}
        setErrors={setErrors}
        handleCreate={handleCreate}
        loading={loading}
        editing={editing}
        setEditing={setEditing}
        hasPermissionToManipulateValues={hasPermissionToManipulateValues}
      />
      <Fallback
        fall={loading || businessMargins?.length === 0}
        component={
          <Col
            xs={12}
            className="flex-column align-items-center d-flex justify-content-center"
            style={{ marginTop: '10rem' }}
          >
            {loading ? (
              <DefaultLoadingComponent />
            ) : (
              <Button
                onClick={
                  hasPermissionToManipulateValues
                    ? () => handleOpenRegisterModal()
                    : () => {}
                }
              >
                <Text>Sem registros</Text>
              </Button>
            )}
          </Col>
        }
      >
        <Card bodyCardPadding="1rem" className="my-3">
          <DataTable
            data={businessMargins}
            columns={[
              { name: 'Código', selector: row => row?.id },
              { name: 'Negócio', selector: row => row?.name },
              {
                name: '',
                render: row => (
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      className="mx-1"
                      onClick={() => handleEditBusiness(row)}
                      title={
                        hasPermissionToManipulateValues
                          ? 'Editar negócio'
                          : 'Visualizar negócio'
                      }
                      disabled={false}
                    >
                      <Text>
                        {hasPermissionToManipulateValues
                          ? 'Editar'
                          : 'Visualizar'}
                      </Text>
                    </Button>
                  </div>
                ),
              },
            ]}
            withFilterOption={false}
            total={pagination?.total}
            pages={pagination?.last_page || 1}
            currentPage={pagination?.current_page}
            defaultPerPage={pageOptions?.perPage}
            onPageChanged={onPageChanged}
            onPerPageChanged={onPerPageChanged}
          />
        </Card>
      </Fallback>
    </ApplicationLayout>
  );
}
