import CheckBox from 'components/CheckBox';
import React from 'react';
import { FaQuestionCircle } from 'react-icons/fa';
import { Text, Tooltip } from 'v3/components';
import theme from 'v3/theme';
import {
  StyledManualItemsContainer,
  StyledManualValidationCheckboxContainer,
} from '../Modals/styles';

export function ManualValidationsBody({
  manualValidationOptions,
  travelManualValidations,
  setTravelManualValidations,
}) {
  return (
    <StyledManualItemsContainer>
      <div>
        <StyledManualValidationCheckboxContainer>
          <CheckBox
            disabled={!manualValidationOptions?.driverRdo}
            checked={travelManualValidations?.RDO}
            onChange={(_, isChecked) =>
              setTravelManualValidations(old => ({
                ...old,
                RDO: isChecked,
              }))
            }
            label="Rdo motorista"
          />
        </StyledManualValidationCheckboxContainer>
        <StyledManualValidationCheckboxContainer>
          <CheckBox
            disabled={!manualValidationOptions?.thirdRestrictionStatus}
            checked={travelManualValidations?.restritionThird}
            onChange={(_, isChecked) =>
              setTravelManualValidations(old => ({
                ...old,
                restritionThird: isChecked,
              }))
            }
            label="Restrição terceiro"
          />
        </StyledManualValidationCheckboxContainer>
        <StyledManualValidationCheckboxContainer>
          <CheckBox
            disabled={!manualValidationOptions?.vehicleStatus?.tractionAgeMax}
            checked={travelManualValidations?.ageTractionMax}
            onChange={(_, isChecked) =>
              setTravelManualValidations(old => ({
                ...old,
                ageTractionMax: isChecked,
              }))
            }
            label="Idade máxima do veículo de tração"
          />
          <Tooltip
            content={
              <Text
                type="label"
                color="white"
                style={{ whiteSpace: 'pre-wrap' }}
              >
                {`${manualValidationOptions?.vehicleStatus?.tractionAgeMax} ano(s)`}
              </Text>
            }
          >
            <FaQuestionCircle
              className="mb-1"
              style={{ color: theme.colors.light_blue }}
            />
          </Tooltip>
        </StyledManualValidationCheckboxContainer>
        <StyledManualValidationCheckboxContainer>
          <CheckBox
            disabled={!manualValidationOptions?.vehicleStatus?.wagonsAgeMax}
            checked={travelManualValidations?.ageImplementMax}
            onChange={(_, isChecked) =>
              setTravelManualValidations(old => ({
                ...old,
                ageImplementMax: isChecked,
              }))
            }
            label="Idade máxima dos implementos"
          />
          <Tooltip
            content={
              <Text
                type="label"
                color="white"
                style={{ whiteSpace: 'pre-wrap' }}
              >
                {`${manualValidationOptions?.vehicleStatus?.wagonsAgeMax} ano(s)`}
              </Text>
            }
          >
            <FaQuestionCircle
              className="mb-1"
              style={{ color: theme.colors.light_blue }}
            />
          </Tooltip>
        </StyledManualValidationCheckboxContainer>
        <StyledManualValidationCheckboxContainer>
          <CheckBox
            disabled={!manualValidationOptions?.driverMinTravelStatus}
            checked={travelManualValidations?.driverTravelMin}
            onChange={(_, isChecked) =>
              setTravelManualValidations(old => ({
                ...old,
                driverTravelMin: isChecked,
              }))
            }
            label="Quantidade mínima de embarques do motorista"
          />
          <Tooltip
            content={
              <Text
                type="label"
                color="white"
                style={{ whiteSpace: 'pre-wrap' }}
              >
                {`${manualValidationOptions?.driverMinTravelStatus} embarque(s)`}
              </Text>
            }
          >
            <FaQuestionCircle
              className="mb-1"
              style={{ color: theme.colors.light_blue }}
            />
          </Tooltip>
        </StyledManualValidationCheckboxContainer>
      </div>
      <div className="innerSection">
        <Text as="p">Pesquisa e consulta</Text>

        <StyledManualValidationCheckboxContainer>
          <CheckBox
            disabled={
              !manualValidationOptions?.searchAndConsultingStatus?.driver
            }
            checked={travelManualValidations?.searchDriver}
            onChange={(_, isChecked) =>
              setTravelManualValidations(old => ({
                ...old,
                searchDriver: isChecked,
              }))
            }
            label="Motorista"
          />
        </StyledManualValidationCheckboxContainer>
        <StyledManualValidationCheckboxContainer>
          <CheckBox
            disabled={
              !manualValidationOptions?.searchAndConsultingStatus?.vehicle
            }
            checked={travelManualValidations?.searchVehicle}
            onChange={(_, isChecked) =>
              setTravelManualValidations(old => ({
                ...old,
                searchVehicle: isChecked,
              }))
            }
            label="Veículo"
          />
        </StyledManualValidationCheckboxContainer>
        <StyledManualValidationCheckboxContainer>
          <CheckBox
            disabled={
              !manualValidationOptions?.searchAndConsultingStatus?.wagons
            }
            checked={travelManualValidations?.searchImplement}
            onChange={(_, isChecked) =>
              setTravelManualValidations(old => ({
                ...old,
                searchImplement: isChecked,
              }))
            }
            label="Implementos"
          />
        </StyledManualValidationCheckboxContainer>
      </div>
      <div className="innerSection">
        <Text as="p">Grupo de equipamentos</Text>
        <StyledManualValidationCheckboxContainer>
          <CheckBox
            disabled={
              !manualValidationOptions?.isTrackerModelRequired?.required
            }
            checked={travelManualValidations?.tracker}
            onChange={(_, isChecked) =>
              setTravelManualValidations(old => ({
                ...old,
                tracker: isChecked,
              }))
            }
            label="Modelo Rastreador"
          />
          {manualValidationOptions?.isTrackerModelRequired?.required ? (
            <Tooltip
              content={
                <Text
                  type="label"
                  color="white"
                  style={{ whiteSpace: 'pre-wrap' }}
                >
                  {manualValidationOptions?.isTrackerModelRequired?.tooltip}
                </Text>
              }
            >
              <FaQuestionCircle
                className="mb-1"
                style={{ color: theme.colors.light_blue }}
              />
            </Tooltip>
          ) : null}
        </StyledManualValidationCheckboxContainer>
        <StyledManualValidationCheckboxContainer>
          <CheckBox
            disabled={!manualValidationOptions?.isImmobilizerRequired?.required}
            checked={travelManualValidations?.immobilizer}
            onChange={(_, isChecked) =>
              setTravelManualValidations(old => ({
                ...old,
                immobilizer: isChecked,
              }))
            }
            label="Modelo Imobilizador"
          />
          {manualValidationOptions?.isImmobilizerRequired?.required ? (
            <Tooltip
              content={
                <Text
                  type="label"
                  color="white"
                  style={{ whiteSpace: 'pre-wrap' }}
                >
                  {manualValidationOptions?.isImmobilizerRequired?.tooltip}
                </Text>
              }
            >
              <FaQuestionCircle
                className="mb-1"
                style={{ color: theme.colors.light_blue }}
              />
            </Tooltip>
          ) : null}
        </StyledManualValidationCheckboxContainer>
        <StyledManualValidationCheckboxContainer>
          <CheckBox
            disabled={!manualValidationOptions?.isLocatorRequired?.required}
            checked={travelManualValidations?.locator}
            onChange={(_, isChecked) =>
              setTravelManualValidations(old => ({
                ...old,
                locator: isChecked,
              }))
            }
            label="Modelo Localizador"
          />
          {manualValidationOptions?.isLocatorRequired?.required ? (
            <Tooltip
              content={
                <Text
                  type="label"
                  color="white"
                  style={{ whiteSpace: 'pre-wrap' }}
                >
                  {manualValidationOptions?.isLocatorRequired?.tooltip}
                </Text>
              }
            >
              <FaQuestionCircle
                className="mb-1"
                style={{ color: theme.colors.light_blue }}
              />
            </Tooltip>
          ) : null}
        </StyledManualValidationCheckboxContainer>
        <StyledManualValidationCheckboxContainer>
          <CheckBox
            disabled={!manualValidationOptions?.isBaitRequired?.required}
            checked={travelManualValidations?.bait}
            onChange={(_, isChecked) =>
              setTravelManualValidations(old => ({
                ...old,
                bait: isChecked,
              }))
            }
            label="Isca"
          />
          {manualValidationOptions?.isBaitRequired?.required ? (
            <Tooltip
              content={
                <Text
                  type="label"
                  color="white"
                  style={{ whiteSpace: 'pre-wrap' }}
                >
                  {manualValidationOptions?.isBaitRequired?.tooltip}
                </Text>
              }
            >
              <FaQuestionCircle
                className="mb-1"
                style={{ color: theme.colors.light_blue }}
              />
            </Tooltip>
          ) : null}
        </StyledManualValidationCheckboxContainer>
      </div>
      <div className="innerSection">
        <Text as="p">Gerenciadoras</Text>
        <StyledManualValidationCheckboxContainer>
          <CheckBox
            disabled={
              !manualValidationOptions?.riskManagerLogisticMirrorStatus
                ?.required
            }
            checked={travelManualValidations?.mirroringLogistic}
            onChange={(_, isChecked) =>
              setTravelManualValidations(old => ({
                ...old,
                mirroringLogistic: isChecked,
              }))
            }
            label="Espelhamento Logístico"
          />
          {manualValidationOptions?.riskManagerLogisticMirrorStatus
            ?.required ? (
            <Tooltip
              content={
                <Text
                  type="label"
                  color="white"
                  style={{ whiteSpace: 'pre-wrap' }}
                >
                  {
                    manualValidationOptions?.riskManagerLogisticMirrorStatus
                      ?.tooltip
                  }
                </Text>
              }
            >
              <FaQuestionCircle
                className="mb-1"
                style={{ color: theme.colors.light_blue }}
              />
            </Tooltip>
          ) : null}
        </StyledManualValidationCheckboxContainer>
        <StyledManualValidationCheckboxContainer>
          <CheckBox
            disabled={
              !manualValidationOptions?.riskManagementCreateChecklistStatus
                ?.required
            }
            checked={travelManualValidations?.riskManagementCreateChecklist}
            onChange={(_, isChecked) =>
              setTravelManualValidations(old => ({
                ...old,
                riskManagementCreateChecklist: isChecked,
              }))
            }
            label="Gr para realizar checklist"
          />
          {manualValidationOptions?.riskManagementCreateChecklistStatus
            ?.required ? (
            <Tooltip
              content={
                <Text
                  type="label"
                  color="white"
                  style={{ whiteSpace: 'pre-wrap' }}
                >
                  {
                    manualValidationOptions?.riskManagementCreateChecklistStatus
                      ?.tooltip
                  }
                </Text>
              }
            >
              <FaQuestionCircle
                className="mb-1"
                style={{ color: theme.colors.light_blue }}
              />
            </Tooltip>
          ) : null}
        </StyledManualValidationCheckboxContainer>
        <StyledManualValidationCheckboxContainer>
          <CheckBox
            disabled={
              !manualValidationOptions?.riskManagementValidityChecklistStatus
                ?.required
            }
            checked={travelManualValidations?.riskManagementValidityChecklist}
            onChange={(_, isChecked) =>
              setTravelManualValidations(old => ({
                ...old,
                riskManagementValidityChecklist: isChecked,
              }))
            }
            label="Gr para validar o checklist"
          />
          {manualValidationOptions?.riskManagementValidityChecklistStatus
            ?.required ? (
            <Tooltip
              content={
                <Text
                  type="label"
                  color="white"
                  style={{ whiteSpace: 'pre-wrap' }}
                >
                  {
                    manualValidationOptions
                      ?.riskManagementValidityChecklistStatus?.tooltip
                  }
                </Text>
              }
            >
              <FaQuestionCircle
                className="mb-1"
                style={{ color: theme.colors.light_blue }}
              />
            </Tooltip>
          ) : null}
        </StyledManualValidationCheckboxContainer>
      </div>
      <div>
        <StyledManualValidationCheckboxContainer>
          {manualValidationOptions?.securityProfileStatus?.required ? (
            <Tooltip
              content={
                <Text
                  type="label"
                  color="white"
                  style={{ whiteSpace: 'pre-wrap' }}
                >
                  {manualValidationOptions?.securityProfileStatus?.tooltip}
                </Text>
              }
            >
              <FaQuestionCircle
                className="mb-1"
                style={{ color: theme.colors.light_blue }}
              />
            </Tooltip>
          ) : null}
        </StyledManualValidationCheckboxContainer>
        <StyledManualValidationCheckboxContainer>
          <CheckBox
            checked={travelManualValidations?.validityChecklist}
            onChange={(_, isChecked) =>
              setTravelManualValidations(old => ({
                ...old,
                validityChecklist: isChecked,
              }))
            }
            label="Validade do checklist"
          />
          {manualValidationOptions?.checklistValidity ? (
            <Tooltip
              content={
                <Text type="label" color="white">
                  {manualValidationOptions?.checklistValidity}
                </Text>
              }
            >
              <FaQuestionCircle
                className="mb-1"
                style={{ color: theme.colors.light_blue }}
              />
            </Tooltip>
          ) : null}
        </StyledManualValidationCheckboxContainer>
        <StyledManualValidationCheckboxContainer>
          <CheckBox
            checked={travelManualValidations?.validityContactDriver}
            onChange={(_, isChecked) =>
              setTravelManualValidations(old => ({
                ...old,
                validityContactDriver: isChecked,
              }))
            }
            label="Validação de contato de motorista"
          />
        </StyledManualValidationCheckboxContainer>
        <StyledManualValidationCheckboxContainer>
          <CheckBox
            checked={travelManualValidations?.driverGuide}
            onChange={(_, isChecked) =>
              setTravelManualValidations(old => ({
                ...old,
                driverGuide: isChecked,
              }))
            }
            label="Orientação ao condutor"
          />
          {manualValidationOptions?.driverGuide ? (
            <Tooltip
              content={
                <Text type="label" color="white">
                  {manualValidationOptions?.driverGuide}
                </Text>
              }
            >
              <FaQuestionCircle
                className="mb-1"
                style={{ color: theme.colors.light_blue }}
              />
            </Tooltip>
          ) : null}
        </StyledManualValidationCheckboxContainer>
        <StyledManualValidationCheckboxContainer>
          <CheckBox
            disabled={
              !manualValidationOptions?.riskManagerLogisctSMStatus?.required
            }
            checked={travelManualValidations?.logisticSm}
            onChange={(_, isChecked) =>
              setTravelManualValidations(old => ({
                ...old,
                logisticSm: isChecked,
              }))
            }
            label="SM logística"
          />
          {manualValidationOptions?.riskManagerLogisctSMStatus?.required ? (
            <Tooltip
              content={
                <Text
                  type="label"
                  color="white"
                  style={{ whiteSpace: 'pre-wrap' }}
                >
                  {manualValidationOptions?.riskManagerLogisctSMStatus?.tooltip}
                </Text>
              }
            >
              <FaQuestionCircle
                className="mb-1"
                style={{ color: theme.colors.light_blue }}
              />
            </Tooltip>
          ) : null}
        </StyledManualValidationCheckboxContainer>
      </div>
    </StyledManualItemsContainer>
  );
}
