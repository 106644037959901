import React from 'react';
import { Card } from 'v3/components';

import ApplicationLayout from 'v3/layouts/Application';

export function LoadingPage({ isLoading }) {
  return (
    <ApplicationLayout hideMenu>
      <Card loading={isLoading}></Card>
    </ApplicationLayout>
  );
}
