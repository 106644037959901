import styled from 'styled-components';

import { BsStarFill, BsXCircleFill } from 'react-icons/bs';
import { IoMdCheckmarkCircle } from 'react-icons/io';
import theme from '../../../../theme';

export const VerifiedIcon = styled(IoMdCheckmarkCircle)`
  color: ${theme.colors.primary};
  margin-bottom: 10px;
  margin-left: 5px;
`;

export const CheckIcon = styled(IoMdCheckmarkCircle)`
  color: ${theme.colors.success};
`;

export const StarIcon = styled(BsStarFill)`
  color: ${theme.colors.yellow_rating_star};
  margin-left: 5px;
  margin-top: -5px;
`;

export const XIcon = styled(BsXCircleFill)`
  color: ${theme.colors.error};
`;

export const CenteredTd = styled.td`
  text-align: center;
`;
