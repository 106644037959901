import React, { useCallback, useEffect, useState } from 'react';
import { FaClock } from 'react-icons/fa';
import theme from 'v3/theme';
import { useHistory, Link } from 'react-router-dom';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';

import { cooperplaceApi } from 'services/api';
import apiReport from 'services/apis/report';

import ApplicationLayout from 'v3/layouts/Application';
import { BadgeActive, Button, DataTable, Fallback, Text } from 'v3/components';
import { useSnackbar } from 'v3/components/Snackbar';

import { Filter } from './Filter';
import { useQuery } from 'hooks';
import { validateIntervalOfDate } from './Filter/utils';
import FileSaver from 'file-saver';
import {
  DefaultEmptyComponent,
  DefaultLoadingComponent,
} from 'v3/components/List';
import { HeaderSpace } from './styles';

export function ClientTravelList() {
  const snackbar = useSnackbar();
  const history = useHistory();
  const query = useQuery();
  const [isAdvancedModalOpen, setIsAdvancedModalOpen] = useState(false);
  const [qtdAdvancedFilters, setQtdAdvancedFilters] = useState(0);
  const [clientTravels, setClientTravels] = useState([]);
  const [pagination, setPagination] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [waitingTime, setWaitingTime] = useState();
  const [destinationWaitingTime, setDestinationWaitingTime] = useState();

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      if (query.has('createdStart') && query.has('createdEnd')) {
        const firstPeriod = query.get('createdStart');
        const secondPeriod = query.get('createdEnd');

        const { valid, message } = validateIntervalOfDate(
          firstPeriod,
          secondPeriod
        );

        if (!valid) {
          snackbar.show(<Text>{message}</Text>, {
            type: 'error',
          });
          setLoading(false);
          return;
        }
      }
      const { data } = await cooperplaceApi.get(`/clients/travels?${query}`);
      setClientTravels(data?.data || []);
      setPagination(data?.meta);
    } catch (error) {
      snackbar.show(<Text>Não foi possível recuperar as viagens.</Text>, {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [query]);

  function handleTitleAdvancedFilters() {
    let title = 'Filtro avançado';
    if (qtdAdvancedFilters) {
      title += ` (${qtdAdvancedFilters})`;
    }
    return title;
  }

  function onPageChanged(value) {
    query.set('page', value);
    history.replace({ search: query.toString() });

    fetchData();
  }

  function onPerPageChanged(value) {
    query.set('page', 1);
    query.set('limit', value);
    history.replace({ search: query.toString() });

    fetchData();
  }

  useEffect(() => {
    if (query.has('createdStart') === false) {
      query.set(
        'createdStart',
        moment().subtract(3, 'days').format('YYYY-MM-DD 00:00:00')
      );
    }
    query.set('limit', 10);
    query.set('page', 1);

    history.replace({ search: query.toString() });
    fetchData();
  }, []);

  useEffect(() => {
    if (clientTravels?.length === 0) {
      query.set('limit', 10);
      query.set('page', 1);
      history.replace({ search: query.toString() });
    }
    calcWaitingTime(clientTravels);
    calcDestinationWaitingTime(clientTravels);
  }, [clientTravels]);

  async function handleExportData() {
    try {
      setLoadingExport(true);
      const params = {
        startDate: query.get('createdStart'),
        endDate: query.get('createdEnd'),
        cnpjClient: query.getAll('clientId[]').map(Number),
        nNfe: query.get('nfeNumber'),
        nOrder: query.get('clientOrderNumber'),
        travelStatus: query.getAll('statusId[]').map(Number),
        nCteCrt: query.get('cteNumber'),
        nInvoice: query.get('externalInvoiceNumber'),
      };
      const response = await apiReport.get(`/report_travel_details`, {
        params: {
          ...params,
        },
        responseType: 'blob',
      });

      FileSaver.saveAs(response.data, response.headers['x-file-name']);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingExport(false);
    }
  }

  function calcWaitingTime(clientTravels) {
    const updatedWaitingTimes = clientTravels.map(client => {
      const effectiveTime = client.loads.loadOrigins[0].effectiveTime;
      const arrivalTime = client.loads.loadOrigins[0].arrivalTime;
      if (effectiveTime && arrivalTime) {
        const diff = moment(effectiveTime).diff(moment(arrivalTime), 'minutes');
        if (diff < 0) {
          return null;
        }
        const hours = parseInt(diff / 60, 10);
        const minutes = diff % 60;
        const formattedTime = `${hours < 10 ? '0' : ''}${hours}:${
          minutes < 10 ? '0' : ''
        }${minutes}`;
        return formattedTime;
      }
      return null;
    });

    setWaitingTime(updatedWaitingTimes);
  }

  function calcDestinationWaitingTime(clientTravels) {
    const updatedWaitingTimes = clientTravels.map(client => {
      const effectiveTime = client.loads.loadDestinations.map(
        destination => destination.effectiveTime
      );
      const arrivalTime = client.loads.loadDestinations.map(
        destination => destination.arrivalTime
      );

      if (effectiveTime[0] && arrivalTime[0]) {
        let formatDiff = 0;
        arrivalTime.map((arrival, index) => {
          if (arrival && effectiveTime[index]) {
            const diff = moment(effectiveTime[index]).diff(
              moment(arrival),
              'minutes'
            );
            return (formatDiff += diff);
          }
        });

        if (formatDiff < 0) {
          return null;
        }
        const hours = parseInt(formatDiff / 60, 10);
        const minutes = formatDiff % 60;
        const formattedTime = `${hours < 10 ? '0' : ''}${hours}:${
          minutes < 10 ? '0' : ''
        }${minutes}`;
        return formattedTime;
      }
      return null;
    });
    setDestinationWaitingTime(updatedWaitingTimes);
  }

  return (
    <>
      <ApplicationLayout
        title="Viagens"
        RightComponent={
          <HeaderSpace>
            <Button
              variant={'success'}
              onClick={() => fetchData()}
              loading={loading}
              disabled={loading || loadingExport}
            >
              <Text weight={500}>Filtrar</Text>
            </Button>
            <Button
              variant={'secondary'}
              onClick={() => setIsAdvancedModalOpen(true)}
              disabled={loading || loadingExport}
            >
              <Text weight={500}>{handleTitleAdvancedFilters()}</Text>
            </Button>
            <Button
              variant={'secondary'}
              onClick={handleExportData}
              loading={loadingExport}
              disabled={loadingExport || loading}
            >
              <Text weight={500}>Exportar</Text>
            </Button>
          </HeaderSpace>
        }
      >
        <Filter
          isAdvancedModalOpen={isAdvancedModalOpen}
          setIsAdvancedModalOpen={setIsAdvancedModalOpen}
          setQtdAdvancedFilters={setQtdAdvancedFilters}
          fetchData={fetchData}
        />
      </ApplicationLayout>
      <Fallback
        fall={clientTravels?.length === 0 || loading}
        component={
          loading ? (
            <DefaultLoadingComponent />
          ) : !loading && clientTravels?.length === 0 ? (
            <DefaultEmptyComponent />
          ) : null
        }
      >
        <Row style={{ padding: '0 2rem 0 50px', margin: 0 }}>
          <Col className="my-5">
            <DataTable
              data={clientTravels}
              loading={loading}
              perPageOptions={[{ v: 10 }, { v: 30 }, { v: 50 }]}
              total={pagination?.total}
              pages={pagination?.last_page || 1}
              currentPage={pagination?.current_page}
              onPageChanged={onPageChanged}
              onPerPageChanged={onPerPageChanged}
              columns={[
                {
                  name: 'Status',
                  selector: row => (
                    <BadgeActive
                      pill
                      variant={row?.travelStatus?.name}
                      className="mr-2"
                      size="sm"
                    >
                      <Text type="regular">
                        {row?.travelStatus?.name?.toUpperCase() ||
                          'Não informado'}
                      </Text>
                    </BadgeActive>
                  ),
                },
                {
                  name: 'CNPJ Cliente',
                  selector: row => (
                    <Text
                      title={`${row?.loads?.taker?.cpfCnpj} - ${row?.loads?.taker?.socialName}`}
                      type="regular"
                    >
                      {row?.loads?.taker?.cpfCnpj}
                    </Text>
                  ),
                },
                {
                  name: 'Incluído em',
                  selector: row => (
                    <Text type="regular">
                      {moment.utc(row?.createdAt).format('DD/MM/YYYY')}
                    </Text>
                  ),
                },
                {
                  name: 'NFs',
                  selector: row => (
                    <Text
                      title={row?.loads?.loadNfs
                        ?.map(nf => nf?.nfNumber)
                        ?.join(', ')}
                      type="regular"
                      style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                    >
                      {row?.loads?.loadNfs?.length >= 2 &&
                      String(row?.loads?.loadNfs[0]?.nfNumber)?.localeCompare(
                        row?.loads?.loadNfs[row?.loads?.loadNfs?.length - 1]
                          ?.nfNumber
                      ) !== 0
                        ? `${row?.loads?.loadNfs[0]?.nfNumber} à ${
                            row?.loads?.loadNfs[row?.loads?.loadNfs?.length - 1]
                              ?.nfNumber
                          }`
                        : row?.loads?.loadNfs?.length > 0
                        ? `${row?.loads?.loadNfs[0]?.nfNumber}
                          `
                        : '-'}
                    </Text>
                  ),
                },
                {
                  name: 'Origem',
                  selector: row => (
                    <Text type="regular">{`${row?.loads?.loadOrigins[0]?.city?.toUpperCase()} - ${row?.loads?.loadOrigins[0]?.province?.toUpperCase()}`}</Text>
                  ),
                },
                {
                  name: 'Destino',
                  selector: row => (
                    <Text type="regular">
                      {`${row?.loads?.loadDestinations[
                        row?.loads?.loadDestinations?.length - 1
                      ]?.city?.toUpperCase()} - ${row?.loads?.loadDestinations[
                        row?.loads?.loadDestinations?.length - 1
                      ]?.province?.toUpperCase()}`}
                    </Text>
                  ),
                },
                {
                  name: 'Carregamento',
                  selector: row => (
                    <Col className="row align-items-center">
                      <FaClock
                        color={theme.colors.dark}
                        className="mr-1"
                        size={'1rem'}
                      />
                      <Text type="regular">
                        {row &&
                        row.loads.loadOrigins[0].arrivalTime &&
                        row.loads.loadOrigins[0].effectiveTime
                          ? waitingTime[clientTravels.indexOf(row)] || 'N/A'
                          : 'N/A'}
                      </Text>
                    </Col>
                  ),
                },
                {
                  name: 'Descarregamento',

                  selector: row => (
                    <Col className="row align-items-center" md="10">
                      <FaClock
                        color={theme.colors.dark}
                        className="mr-2"
                        size={'1rem'}
                      />
                      <Text type="regular">
                        {row.travelStatus.code == 'ST6' &&
                        row.loads.loadDestinations[0].arrivalTime &&
                        row.loads.loadDestinations[0].effectiveTime
                          ? destinationWaitingTime[
                              clientTravels.indexOf(row)
                            ] || 'N/A'
                          : 'N/A'}{' '}
                      </Text>
                    </Col>
                  ),
                },
                {
                  selector: row => (
                    <Link
                      to={{
                        pathname: `/painel-logistico/${row?.id}`,
                        state: { search: query.toString() },
                      }}
                    >
                      <Button size="sm">
                        <Text>Detalhes</Text>
                      </Button>
                    </Link>
                  ),
                },
              ]}
              defaultPerPage={pagination?.per_page}
              withFilterOption={false}
            />
          </Col>
        </Row>
      </Fallback>
    </>
  );
}
