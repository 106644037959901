import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Fallback, Text } from 'v3/components';
import { Col } from 'react-bootstrap';
import theme from 'v3/theme';
import { cooperplaceApi } from 'services/api';
import { useSnackbar } from 'v3/components/Snackbar';
import { useUser } from '../shared/context';
import AddSignature from '../shared/Modals/AddSignature';

export function Signature() {
  const user = useUser();
  const [openModal, setOpenModal] = useState(false);
  const [signature, setSignature] = useState(null);
  const [loading, setLoading] = useState(false);
  const snackbar = useSnackbar();

  const getSignature = useCallback(async () => {
    setLoading(true);
    try {
      const {
        data: { base64 },
      } = await cooperplaceApi.get(`users/signature`);
      setSignature(base64);
    } catch (error) {
      snackbar.show(
        <Text>
          {error?.response?.data?.message || 'Erro ao obter assinatura'}
        </Text>,
        { type: 'error' }
      );
      setSignature('');
    } finally {
      setLoading(false);
    }
  }, [user.data.id]);

  useEffect(() => {
    getSignature();
  }, [user.data.id]);

  return (
    <>
      {openModal && (
        <AddSignature
          isOpen={openModal}
          onClose={() => setOpenModal(false)}
          signature={signature}
          setLoading={setLoading}
          snackbar={snackbar}
          getSignature={getSignature}
        />
      )}
      <Card
        loading={user.isFetching || loading}
        header={
          <Text type="header" color="dark">
            Assinatura
          </Text>
        }
        HeaderRightComponent={
          <>
            {signature && (
              <Button onClick={() => setOpenModal(true)}>
                <Text type="regular" weight="500" color="white">
                  Editar
                </Text>
              </Button>
            )}
          </>
        }
      >
        <Fallback
          fall={!signature}
          component={
            <Col xs={12} className="align-items-center d-flex flex-column my-2">
              <Text color={theme.colors.dark}>
                Não há assinatura cadastrada nesse usuário.
              </Text>
              <Button className="mt-4" onClick={() => setOpenModal(true)}>
                <Text type="regular" weight="500" color="white">
                  Adicionar
                </Text>
              </Button>
            </Col>
          }
        >
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              width={500}
              height={200}
              src={signature}
              alt="Assinatura do usuário"
            />
          </div>
        </Fallback>
      </Card>
    </>
  );
}
