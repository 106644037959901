import React, {
  useEffect,
  useImperativeHandle,
  forwardRef,
  useState,
} from 'react';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

function StatusSelector(
  { handleStatusSelected, initialDate, handleDateChange, ...props },
  ref
) {
  const [selected, setSelected] = useState(1);

  useEffect(() => {
    handleStatusSelected(selected);
  }, [selected]);

  useImperativeHandle(
    ref,
    () => ({
      setSelected,
    }),
    [setSelected]
  );

  function handleSelected(event, value) {
    setSelected(value);
  }

  return (
    <ToggleButtonGroup
      value={selected}
      onChange={handleSelected}
      size="large"
      exclusive
      className="toggleDeal"
    >
      <ToggleButton value={0}>Todas</ToggleButton>
      <ToggleButton value={1}>Ativas</ToggleButton>
    </ToggleButtonGroup>
  );
}

export default forwardRef(StatusSelector);
