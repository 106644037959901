import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import api from 'services/api';
import { useParams } from 'react-router-dom';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import Fallback from 'v3/components/Fallback';
import Badge from 'v3/components/Badge';
import Card from 'v3/components/Card';
import Table from 'v3/components/Table';
import theme from 'v3/theme';
import { FaPencilAlt, FaTrash } from 'react-icons/fa';
import { useSnackbar } from 'v3/components/Snackbar';
import PriceOrderModal from '../Modals/PriceOrderModal';
import { useCompany } from '../context';

export default function PriceOrder() {
  const company = useCompany();
  const params = useParams();
  const snackbar = useSnackbar();
  const [showModal, setShowModal] = useState(false);

  const formatError = error => {
    let errorMessage = '';
    if (error?.response?.data?.errors) {
      error.response.data.errors.forEach(message => {
        errorMessage += message;
      });
    } else if (error?.response?.data?.error) {
      errorMessage = error.response.data.error;
    } else {
      errorMessage = 'Erro ao atualizar campos';
    }
    return errorMessage;
  };

  useEffect(() => {
    if (!params.id)
      company.setData({
        pricingOrder: [],
      });
  }, []);

  async function handleRemovePriceOrder(priceOrder) {
    if (params.id) {
      try {
        await api.delete(`v3/company/${priceOrder.id}/remove-price-order`);

        company.setData({
          pricingOrder: company.data.pricingOrder.filter(
            item => item !== priceOrder
          ),
        });
        snackbar.show(<Text>Ordem de preços atualizadas com sucesso!</Text>, {
          type: 'success',
        });
      } catch (error) {
        snackbar.show(<Text>{formatError(error)}</Text>, { type: 'error' });
      }
    }
  }

  return (
    <>
      <PriceOrderModal
        isOpen={showModal}
        onClose={() => {
          company.cancelEditing();
          company.setData({ editingCurrentPriceOrder: null });
          setShowModal(false);
        }}
        setShowModal={setShowModal}
      />
      <Card
        loading={company.isFetching}
        header={
          <Text type="header" color="dark">
            Ordem de preços
          </Text>
        }
        HeaderRightComponent={
          company.data?.pricingOrder?.length > 0 && (
            <Button
              className="py-2"
              onClick={() => {
                setShowModal(true);
                company.setPrevCompany(company.data);
              }}
              disabled={!company.hasPermissionToEdit}
            >
              <Text type="regular" weight="500" color="white">
                Adicionar
              </Text>
            </Button>
          )
        }
      >
        <Row>
          <Fallback
            fall={!company.data?.pricingOrder?.length}
            component={
              <Col xs={12} className="d-flex flex-column align-items-center">
                <Text color={theme.colors.dark} type="regular">
                  Não há ordens de preço cadastradas para essa empresa.
                </Text>
                <Button
                  className="mt-3 py-2"
                  onClick={() => {
                    setShowModal(true);
                  }}
                  disabled={!company.hasPermissionToEdit}
                >
                  <Text type="regular" weight="500" color="white">
                    Adicionar
                  </Text>
                </Button>
              </Col>
            }
          >
            <Table striped>
              <thead>
                <tr>
                  <th>
                    <Text weight={500} type="regular" color="dark">
                      Nome
                    </Text>
                  </th>
                  <th>
                    <Text weight={500} type="regular" color={theme.colors.dark}>
                      Transportadoras
                    </Text>
                  </th>
                  <th className="text-center">
                    <Text type="regular" weight={500} color="dark">
                      Ação
                    </Text>
                  </th>
                </tr>
              </thead>
              <tbody>
                {company.data?.pricingOrder
                  ?.sort((a, b) => a.order - b.order)
                  .map(item => (
                    <tr>
                      <td>
                        <Text type="regular" color="dark">
                          {item.name}
                        </Text>
                        {!item.active && (
                          <div className="ml-1">
                            <Badge
                              light
                              pill
                              variant="error"
                              className="ml-2"
                              size="sm"
                            >
                              <Text type="label">Inativo</Text>
                            </Badge>
                          </div>
                        )}
                      </td>
                      <td>
                        <Text className="m-5" type="regular" color="gray">
                          {item?.order_shipping_companies?.length}
                        </Text>
                      </td>
                      <td width="120" className="text-center">
                        <Button
                          variant="secondary"
                          size="sm"
                          className="mr-1"
                          onClick={() => {
                            company.setData({
                              pricingOrder: company.data.pricingOrder.filter(
                                priceOrder => priceOrder !== item
                              ),
                              editingCurrentPriceOrder: item,
                            });
                            company.setPrevCompany(company.data);
                            setShowModal(true);
                          }}
                          disabled={!company.hasPermissionToEdit}
                        >
                          <FaPencilAlt size={16} color="#464E5F" />
                        </Button>
                        <Button
                          variant="error"
                          size="sm"
                          onClick={() => handleRemovePriceOrder(item)}
                          disabled={item.current || !company.hasPermissionToEdit}
                        >
                          <FaTrash size={16} />
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Fallback>
        </Row>
      </Card>
    </>
  );
}
