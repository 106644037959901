import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useHistory, Link, useParams } from 'react-router-dom';
import { validatePermission } from 'actions';

import ApplicationLayout from 'v3/layouts/Application';
import Space from 'v3/components/Space';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import Tooltip from 'v3/components/Tooltip';

import { useProduct } from '../shared/context';

import Data from './Data';

export default function ProductDetails() {
  const history = useHistory();
  const product = useProduct();
  const params = useParams();
  const [hasPermissionToEdit, setPermissionToEdit] = useState(true);

  useEffect(() => {
    function validateProductPermission() {
      setPermissionToEdit(validatePermission('ALTERAR_PRODUTOS'));
    }
    validateProductPermission();
  }, []);

  useEffect(() => {
    if (!hasPermissionToEdit) {
      product.showSnackbar('Você não tem permissão para editar produto', {
        type: 'error',
      });
      history.push('/');
    }
  }, [hasPermissionToEdit]);

  return (
    <ApplicationLayout
      title="Produtos"
      RightComponent={
        <Space x="8px">
          <Button variant="secondary" onClick={() => history.goBack()}>
            <Text type="regular" color="dark" weight="semiBold">
              Voltar
            </Text>
          </Button>
          <Link to="cadastrar">
            <Button>
              <Text type="regular" color="white" weight="semiBold">
                Novo produto
              </Text>
            </Button>
          </Link>
        </Space>
      }
    >
      <Row>
        <Col md={6} xs={12}>
          <Data />
        </Col>
      </Row>
    </ApplicationLayout>
  );
}
