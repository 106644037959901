import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import moment from 'moment';

import Card from 'components/Card';
import Button from 'components/Button';

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import api from 'services/api';
import { usePermission } from 'hooks';
import Mapa from './Mapa';

const filterOptions = [
  { label: 'Todos os veiculos', value: 'all' },
  { label: 'Tipo de veículo', value: 'vehicleType' },
  { label: 'Tipo da carroceria', value: 'bodyType' },
];

export default function MapaDeCalor() {
  const [bodyType, setBodyType] = useState();
  const [bodyTypes, setBodyTypes] = useState();
  const [vehicleType, setVehicleType] = useState();
  const [vehicleTypes, setVehicleTypes] = useState();
  const [filter, setFilter] = useState();
  const [vehicles, setVehicles] = useState([]);
  const [center, setCenter] = useState({});
  const [locations, setLocations] = useState([]);
  const [message, setMessage] = useState({ error: '', warning: '' });
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState();
  usePermission('VISUALIZAR_MAPA_DE_CALOR_DE_VEICULOS', { redirect: true });

  async function getBodyTypes() {
    try {
      const response = await api.get(`body-types?app=${true}`);
      const types = response.data.map(type => ({
        label: type.name,
        value: type.id,
      }));
      setBodyTypes(types);
    } catch (ex) {
      setBodyTypes([{ text: 'Não encontrado.', value: 'null' }]);
    }
  }

  async function getVehicleTypes() {
    try {
      const response = await api.get(`vehicle-types?app=${true}`);
      const types = response.data.map(type => ({
        label: type.name,
        value: type.id,
      }));
      setVehicleTypes(types);
    } catch (ex) {
      setVehicleTypes([{ text: 'Não encontrado.', value: 'null' }]);
    }
  }

  useEffect(() => {
    getBodyTypes();
    getVehicleTypes();
  }, []);

  const visibleMap = useMemo(() => {
    if (vehicles.length > 0) {
      return true;
    }

    return false;
  }, [vehicles]);

  async function handleGetVehicles() {
    if (filter?.value === undefined) {
      setMessage({ error: 'Preencha o filtro, por favor' });
      return;
    }

    if (filter?.value === 'bodyType' && !bodyType) {
      return;
    }
    if (filter.value === 'vehicleType' && !vehicleType) {
      return;
    }

    try {
      setMessage({ error: null, warning: null });
      setLoading(true);
      setDate(new Date());

      let pages = 1;
      let url = `control-tower-heat-map?`;
      if (bodyType) url += `bodyType=${bodyType.value}&`;
      if (vehicleType) url += `vehicleType=${vehicleType.value}&`;
      url += `all=${Boolean(filter.value === 'all')}`;

      const response = await api.get(`${url}&page=${pages}`);

      if (response.data.total > 0) {
        const { lastPage } = response.data;

        const pagesArray = [];
        // eslint-disable-next-line no-plusplus
        for (pages; pages <= lastPage; pages++) {
          pagesArray.push(pages);
        }

        const data = await Promise.all(
          pagesArray.map(async page => {
            const perPage = await api.get(`${url}&page=${page}`);

            const { data } = perPage.data;

            const formattedValues = data.map(vehicle => {
              const { drivers, ...rest } = vehicle;

              const { user } = drivers[0];

              return {
                driver: drivers,
                user,
                ...rest,
              };
            });

            return formattedValues;
          })
        );

        const flatedVehicles = data.flat().filter(({ user }) => user);

        const locales = flatedVehicles.map(({ user }) => ({
          lat: user.latitude,
          lng: user.longitude,
        }));

        let newCenter = locales.filter(
          loc => loc.lat !== null && loc.lng !== null
        )[0];
        if (!newCenter) newCenter = { lat: -15.793889, lng: -47.882778 };

        setCenter(newCenter);
        setLocations(locales);
        setVehicles(flatedVehicles);
        setMessage({ error: null, warning: null });
      } else {
        setVehicles([]);

        setMessage({ warning: 'Nenhum veículo encontrado' });
      }
    } catch (err) {
      setMessage({ error: 'Algo deu errado na busca.' });
    } finally {
      setLoading(false);
    }
  }

  function handleChangeFilterOption(event) {
    setFilter(event);
    setBodyType(null);
    setVehicleType(null);
    setVehicles([]);
    setLocations([]);
    setDate(null);
  }

  return (
    <Card style={{ overflow: 'visible', marginBottom: '15px' }}>
      <Grid container>
        <Grid item xs={12}>
          <label htmlFor="bodyType">Filtrar por</label>
        </Grid>
        <Grid item xs={12}>
          <Select
            id="typeFilter"
            name="typeFilter"
            options={filterOptions}
            placeholder="Selecione"
            getOptionLabel={option => `${option.label}`}
            getOptionValue={option => option.value}
            value={filter}
            onChange={event => handleChangeFilterOption(event)}
            isDisabled={loading}
          />
        </Grid>
        {filter && filter.value === 'bodyType' && (
          <>
            <Grid item xs={12} className="mt-4">
              <label htmlFor="bodyType">Tipo da carroceria</label>
            </Grid>
            <Grid item xs={12}>
              <Select
                id="bodyType"
                name="bodyType"
                options={bodyTypes}
                placeholder="Selecione"
                getOptionLabel={option => `${option.label}`}
                getOptionValue={option => option.value}
                value={bodyType}
                onChange={event => setBodyType(event)}
                isDisabled={loading}
              />
            </Grid>
          </>
        )}
        {filter && filter.value === 'vehicleType' && (
          <>
            <Grid item xs={12} className="mt-4">
              <label htmlFor="vehicleType">Tipo de Veículo</label>
            </Grid>
            <Grid item xs={12}>
              <Select
                id="vehicleType"
                name="vehicleType"
                options={vehicleTypes}
                placeholder="Selecione"
                getOptionLabel={option => `${option.label}`}
                getOptionValue={option => option.value}
                value={vehicleType}
                onChange={event => setVehicleType(event)}
                isDisabled={loading}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Grid container>
        <Grid item xs={12} className="mt-4 d-flex justify-content-center">
          <Button disabled={loading} onClick={() => handleGetVehicles()}>
            Filtrar
          </Button>
        </Grid>
      </Grid>
      {date && !loading && (
        <Grid container>
          <Grid item xs={12} className="mt-4 d-flex justify-content-center">
            Atualizado em {moment(date).format('LLL')}
          </Grid>
          {date && !loading && (
            <Grid item xs={12} className="mt-4 d-flex justify-content-center">
              Total de veículos: {vehicles.length}
            </Grid>
          )}
        </Grid>
      )}
      <Grid container>
        <Grid item xs={12} className="mt-4">
          {visibleMap && !loading && (
            <Mapa positions={locations} center={center} />
          )}
        </Grid>
        {}
        {message.warning && (
          <Grid item xs={12} className="mt-4 d-flex justify-content-center">
            <h4>{message.warning}</h4>
          </Grid>
        )}
        {message.error && (
          <Grid item xs={12} className="mt-4 d-flex justify-content-center">
            {message.error}
          </Grid>
        )}
      </Grid>
      {loading && (
        <Grid item xs={12} className="mt-4 d-flex justify-content-center">
          <CircularProgress />
        </Grid>
      )}
    </Card>
  );
}
