import React from 'react';

import { Link } from 'react-router-dom';
import { Col } from 'react-bootstrap';

import CardRaw from 'v3/components/Card';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import Tooltip from 'v3/components/Tooltip';

function Card({ blacklistItem }) {
  return (
    <Col md={6}>
      <CardRaw
        header={
          <Tooltip
            content={<Text type="body">{blacklistItem.description}</Text>}
            placement="top"
          >
            <Text
              className="w-50"
              truncate
              transform="uppercase"
              color="dark"
              type="regular"
            >
              {blacklistItem.description}
            </Text>
          </Tooltip>
        }
        HeaderRightComponent={
          <div>
            <Link to={`/blacklist/${blacklistItem.id}`}>
              <Button className="py-2">
                <Text color="white" type="regular" weight="500">
                  Detalhes
                </Text>
              </Button>
            </Link>
          </div>
        }
      >
        <Col className="mb-3 p-0">
          <Text color="dark" type="regular" weight="400">
            {blacklistItem.value}
          </Text>
        </Col>
        {blacklistItem.reason && <Col className="p-0">
          <Text color="gray" as="h1" fontSize="16px" type="regular">
            Motivo: 
          </Text>
          <Text color="gray" className="text-break" type="regular">
            {blacklistItem.reason}
          </Text>
        </Col>}
      </CardRaw>
    </Col>
  );
}

export default Card;
