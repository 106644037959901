import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';
import {
  Input,
  Toggle,
  Modal,
  Select,
  DatePicker,
  Button,
  Text,
} from 'v3/components';
import { useQuery } from 'hooks';
import { DATE_OPTIONS, forbiddenTravelStatusCode } from './utils';
import api from 'services/api';
import { fetchTravelStatusOptions } from 'utils/fetches';
import { useSnackbar } from 'v3/components/Snackbar';
import { FilterRow } from '../styles';

export function Filter({
  isAdvancedModalOpen,
  setIsAdvancedModalOpen,
  setQtdAdvancedFilters,
  fetchData,
}) {
  const query = useQuery();
  const history = useHistory();
  const snackbar = useSnackbar();
  const [createdDate, setCreatedDate] = useState();
  const [clientCnpj, setClientCnpj] = useState([]);
  const [nfNumber, setNfNumber] = useState('');
  const [clientOrderNumber, setClientOrderNumber] = useState('');
  const [advancedFilters, setAdvancedFilters] = useState({
    travelStatus: [],
    cteCrtNumber: '',
    externalInvoiceNumber: '',
    createdStart: '',
    createdEnd: '',
  });
  const [clientsList, setClientsList] = useState([]);
  const [travelStatusOptions, setTravelStatusOptions] = useState([]);

  useEffect(() => {
    const isNotEmpty = value =>
      value !== null &&
      value !== undefined &&
      value !== '' &&
      value !== '0' &&
      value?.length > 0;

    let filters = {
      travelStatus:
        advancedFilters?.travelStatus?.length > 0
          ? advancedFilters?.travelStatus
          : [],
      cteCrtNumber: advancedFilters?.cteCrtNumber || '',
      externalInvoiceNumber: advancedFilters?.externalInvoiceNumber || '',
      createdStart: advancedFilters?.createdStart || '',
      createdEnd: advancedFilters?.createdEnd || '',
    };
    setQtdAdvancedFilters(Object.values(filters).filter(isNotEmpty).length);
  }, [advancedFilters]);

  useEffect(() => {
    getTravelStatusOptions();
  }, []);
  async function getTravelStatusOptions() {
    try {
      const status = await fetchTravelStatusOptions();
      const processedStatus = status.filter(
        s => forbiddenTravelStatusCode.includes(s.code) === false
      );
      setTravelStatusOptions(processedStatus);
    } catch (error) {
      snackbar.show(
        <Text>
          Não foi possível carregar todas as opções de filtro. Recarregue a
          página.
        </Text>,
        {
          type: 'error',
        }
      );
    }
  }

  async function getClients() {
    const usuario = localStorage.getItem('usuario');
    const jsonU = JSON.parse(usuario);
    try {
      const response = await api.get(`users/${jsonU.id}`);
      if (response.data.clients.length === 0) {
        console.error('Nenhum cliente cadastrado em sua conta');
      } else {
        const clients = response.data.clients.map(item => {
          return {
            label: item.social_name,
            value: item.id,
            cgccpf: item.cgccpf,
          };
        });
        setClientsList(clients);
      }
    } catch (err) {
      console.error(`Erro:${err}`);
    }
  }
  useEffect(() => {
    getClients();
    if (query.has('createdStart') === false) {
      query.set(
        'createdStart',
        moment().subtract(3, 'days').format('YYYY-MM-DD 00:00:00')
      );
    }

    query.set('limit', 10);
    query.set('page', 1);

    history.replace({ search: query.toString() });
  }, []);
  useEffect(() => {
    const timeout = setTimeout(() => {
      history.replace({ search: query.toString() });
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [advancedFilters, createdDate, clientCnpj, nfNumber, clientOrderNumber]);

  function handleClose() {
    setIsAdvancedModalOpen(false);
  }

  function getQueryParams() {
    if (query.has('createdStart') && query.has('createdEnd')) {
      setAdvancedFilters(old => ({
        ...old,
        createdStart: moment(query.get('createdStart')).format('YYYY-MM-DD'),
        createdEnd: moment(query.get('createdEnd')).format('YYYY-MM-DD'),
      }));
    }
    if (query.has('createdStart') && query.has('createdEnd') === false) {
      setCreatedDate(
        moment(query.get('createdStart')).format('YYYY-MM-DD 00:00:00')
      );
    }
    if (query.has('cteNumber')) {
      setAdvancedFilters(old => ({
        ...old,
        cteCrtNumber: query.get('cteNumber'),
      }));
    }
    if (query.has('externalInvoiceNumber')) {
      setAdvancedFilters(old => ({
        ...old,
        externalInvoiceNumber: query.get('externalInvoiceNumber'),
      }));
    }
    if (query.has('statusId[]')) {
      const ids = query.getAll('statusId[]').map(Number);
      const selectedStatus = travelStatusOptions.filter(status =>
        ids.includes(status.id)
      );
      setAdvancedFilters(old => ({
        ...old,
        travelStatus: [...selectedStatus],
      }));
    }
    if (query.has('clientId[]')) {
      const ids = query.getAll('clientId[]').map(Number);
      const selectedClients = clientsList.filter(client =>
        ids.includes(client?.value)
      );
      setClientCnpj(selectedClients);
    }

    if (query.has('nfeNumber')) {
      setNfNumber(query.get('nfeNumber'));
    }
    if (query.has('clientOrderNumber')) {
      setClientOrderNumber(query.get('clientOrderNumber'));
    }
  }
  useEffect(() => {
    if (travelStatusOptions?.length > 0) getQueryParams();
  }, [clientsList, isAdvancedModalOpen, travelStatusOptions]);

  function searchUserClients(search) {
    if (!search) {
      return clientsList;
    }

    const client = search?.toLowerCase();

    return clientsList.filter(
      s =>
        s.label.toLowerCase().search(client) >= 0 ||
        String(s.cgccpf).search(client) >= 0
    );
  }

  function searchTravelStatus(search) {
    if (!search) {
      return travelStatusOptions;
    }

    const status = search.toLowerCase();
    return travelStatusOptions.filter(
      s => s.name.toLowerCase().search(status) >= 0
    );
  }

  return (
    <>
      <Modal
        show={isAdvancedModalOpen}
        heading={'Filtro avançado'}
        onHide={() => setIsAdvancedModalOpen(false)}
        body={
          <Row>
            <Col md={4} xs={12} sm={12} className="mb-3">
              <Select.Async
                label="Status da Viagem"
                multiple
                horizontal
                value={advancedFilters?.travelStatus}
                onChange={value => {
                  if (value?.length > 0) {
                    query.delete('statusId[]');
                    value.forEach(item => {
                      query.append('statusId[]', item?.id);
                    });
                  } else {
                    query.delete('statusId[]');
                  }
                  setAdvancedFilters(old => ({
                    ...old,
                    travelStatus: value,
                  }));
                }}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                options={travelStatusOptions}
                modalHeading="Selecione um status"
                modalBodyTitle="Status:"
                onSearch={search => searchTravelStatus(search)}
              />
            </Col>
            <Col md={4} xs={12} sm={12} className="mb-3">
              <Input
                label="Nº CT-e / CRT"
                value={advancedFilters?.cteCrtNumber}
                placeholder="Informe"
                onChange={e => {
                  if (e.target?.value) {
                    query.set('cteNumber', e.target?.value);
                  } else {
                    query.delete('cteNumber');
                  }
                  setAdvancedFilters(old => ({
                    ...old,
                    cteCrtNumber: e.target?.value,
                  }));
                }}
              />
            </Col>
            <Col md={4} xs={12} sm={12} className="mb-3">
              <Input
                label="Nº Invoice"
                value={advancedFilters?.externalInvoiceNumber}
                placeholder="Informe"
                onChange={e => {
                  if (e.target?.value) {
                    query.set('externalInvoiceNumber', e.target?.value);
                  } else {
                    query.delete('externalInvoiceNumber');
                  }
                  setAdvancedFilters(old => ({
                    ...old,
                    externalInvoiceNumber: e.target?.value,
                  }));
                }}
              />
            </Col>
            <Col md={4} xs={12} sm={12} className="mb-3">
              <Input
                type="date"
                label="Viagem criada em"
                value={advancedFilters?.createdStart}
                onChange={e => {
                  if (e.target?.value) {
                    setCreatedDate(null);
                    query.set(
                      'createdStart',
                      moment(e.target?.value, 'YYYY-MM-DD').isValid()
                        ? moment(e.target?.value, 'YYYY-MM-DD')?.format(
                            'YYYY-MM-DD 00:00:00'
                          )
                        : ''
                    );
                  } else {
                    query.delete('createdStart');
                  }
                  setAdvancedFilters(old => ({
                    ...old,
                    createdStart: e.target?.value,
                  }));
                }}
              />
            </Col>
            <Col md={4} xs={12} sm={12} className="mb-3">
              <Input
                type="date"
                label="Até"
                value={advancedFilters?.createdEnd}
                onChange={e => {
                  if (e.target?.value) {
                    setCreatedDate(null);
                    query.set(
                      'createdEnd',
                      moment(e.target?.value, 'YYYY-MM-DD').isValid()
                        ? moment(e.target?.value, 'YYYY-MM-DD')?.format(
                            'YYYY-MM-DD 00:00:00'
                          )
                        : ''
                    );
                  } else {
                    query.delete('createdEnd');
                  }
                  setAdvancedFilters(old => ({
                    ...old,
                    createdEnd: e.target?.value,
                  }));
                }}
              />
            </Col>
          </Row>
        }
        footer={
          <Row style={{ justifyContent: 'flex-end' }}>
            <Button
              variant="primary"
              onClick={() => {
                fetchData();
                handleClose();
              }}
            >
              <Text weight={500} type="regular">
                Filtrar
              </Text>
            </Button>
          </Row>
        }
      />
      <FilterRow className="mt-4">
        <Col md={3} xs={12} sm={12}>
          <Toggle
            label="Criado em até"
            labelColor="#fff"
            value={createdDate}
            options={DATE_OPTIONS}
            onSelected={value => {
              if (value) {
                query.delete('createdEnd');
                setAdvancedFilters(old => ({
                  ...old,
                  createdEnd: '',
                  createdStart: '',
                }));
                query.set('createdStart', value);
              } else {
                query.delete('createdStart');
              }
              setCreatedDate(value);
            }}
          />
        </Col>

        <Col sm={12} md={3}>
          <Select.Async
            label="CNPJ Cliente"
            labelColor="#fff"
            modalHeading="Selecione um cliente"
            modalBodyTitle="Clientes:"
            multiple
            horizontal
            value={clientCnpj}
            onChange={value => {
              if (value?.length > 0) {
                query.delete('clientId[]');
                value.forEach(item => {
                  query.append('clientId[]', item?.value);
                });
              } else {
                query.delete('clientId[]');
              }
              setClientCnpj(value);
            }}
            onSearch={search => searchUserClients(search)}
            getOptionLabel={option => {
              if (option?.label && option?.cgccpf) {
                return `${option?.cgccpf} - ${option.label}`;
              } else return option.label;
            }}
            getOptionValue={option => option.value}
            options={clientsList}
          />
        </Col>
        <Col sm={12} md={3}>
          <Input
            label="Nota Fiscal"
            labelColor="#fff"
            value={nfNumber}
            placeholder="Informe"
            onChange={e => {
              if (e.target?.value) {
                query.set('nfeNumber', e.target?.value);
              } else {
                query.delete('nfeNumber');
              }
              setNfNumber(e?.target?.value);
            }}
          />
        </Col>
        <Col sm={12} md={3}>
          <Input
            label="Número Pedido"
            labelColor="#fff"
            value={clientOrderNumber}
            placeholder="Informe"
            onChange={e => {
              if (e.target?.value) {
                query.set('clientOrderNumber', e.target?.value);
              } else {
                query.delete('clientOrderNumber');
              }
              setClientOrderNumber(e?.target?.value);
            }}
          />
        </Col>
      </FilterRow>
    </>
  );
}
