const Yup = require('yup');

function isPriceRangeValid(value) {
  if (
    (value.charge_type === 2 || value.charge_type === 5) &&
    value.priceRanges.some(
      range =>
        !range.inicial_amount ||
        !range.final_amount ||
        !range.value ||
        !range.external_value
    )
  ) {
    return false;
  }

  return true;
}

export const dataSchema = Yup.object().shape({
  person: Yup.object()
    .required('Campo obrigatório')
    .shape({
      id: Yup.number().required('Campo obrigatório'),
    })
    .typeError('Campo obrigatório'),
  vehicles: Yup.array()
    .required('Tipo de veículo obrigatório')
    .of(
      Yup.object().shape({
        id: Yup.number().required('Escolha ao menos um tipo de veículo'),
      })
    )
    .typeError('Tipo do veículo obrigatório'),
  product: Yup.object()
    .required('Produto obrigatório')
    .typeError('Tipo do produto obrigatório'),
  tags: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number(),
      })
    )
    .nullable()
    .notRequired(),
  distance: Yup.string().nullable().notRequired(),
  loadType: Yup.object().required('Tipo de carga obrigatório'),
  currency: Yup.object().required('Moeda obrigatória'),
});

export const routesSchema = Yup.object().shape({
  origin: Yup.object()
    .required('Endereço de origem obrigatório')
    .shape({
      city: Yup.string().required('Selecione um endereço de origem válido'),
      country_slug: Yup.string().required('País de origem obrigatório'),
    })
    .typeError('Endereço de origem obrigatório'),
  destinations: Yup.array().of(
    Yup.object().shape({
      city: Yup.string().required('Selecione um endereço de destino válido'),
      action: Yup.boolean().required('Ação obrigatória'),
      country_slug: Yup.string().required('País de destino obrigatório'),
    })
  ),
});

export const chargeSchema = Yup.object()
  .shape({
    charge_type: Yup.number().required('Método de cobrança obrigatório'),
    charge_price: Yup.number()
      .required('Valor obrigatório')
      .min(1, 'Valor insuficiente')
      .typeError('Valor obrigatório'),
    priceRanges: Yup.array().of(Yup.object()),
  })
  .test(
    'is-price-range-valid',
    'É necessário preencher todas as faixas de preços',
    value => isPriceRangeValid(value)
  );

export const taxesSchema = Yup.object().shape({
  insurance_value: Yup.string().nullable().typeError('Tipo invalido'),
  gris: Yup.string().nullable().typeError('Tipo invalido'),
  ad_valorem: Yup.string().nullable().typeError('Tipo invalido'),
  discharge_price: Yup.string().nullable().typeError('Tipo invalido'),
  toll_value: Yup.string().nullable().typeError('Tipo invalido'),
  loading_charge: Yup.string().nullable().typeError('Tipo invalido'),
});

export const routeSchema = Yup.object().shape({
  route: routesSchema,
  data: dataSchema,
  charge: chargeSchema,
  taxes: taxesSchema,
});

export async function validate(field, data, setErrors, context) {
  const errorList = {};
  try {
    if (field === 'allSchema') {
      await routeSchema.validate(data, {
        abortEarly: false,
        context,
      });
      return true;
    } else {
      await routeSchema.validateAt(field, data, {
        abortEarly: false,
        context,
      });
      return true;
    }
  } catch (err) {
    err.inner.forEach(error => {
      errorList[error.path] = error?.message;
    });
    return false;
  } finally {
    setErrors(errorList);
  }
}
