import React, { forwardRef } from 'react';

import { StyledCard } from './styles';

/**
 * @param bordered {bool} - Adiciona uma borda na esquerda do componente
 * @param bordercolor - {string} - Adiciona uma cor a borda da esquerda [success, danger, warning, primary, gray, orange]
 * @param form - {bool} - adiciona padding quando o card esta em um formulário
 *
 * @visibleName Card
 */

function Card(
  { form, bordered, backgroundcolor, bordercolor, borderhexcolor, ...rest },
  ref
) {
  return (
    <StyledCard
      form={form}
      bordered={bordered}
      backgroundcolor={backgroundcolor}
      bordercolor={bordercolor}
      borderhexcolor={borderhexcolor}
      ref={ref}
      {...rest}
    />
  );
}

export default forwardRef(Card);
