import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { validatePermission } from 'actions/index';
import Text from 'v3/components/Text';
import Input from 'v3/components/Input';
import Select from 'v3/components/Select';
import Badge from 'v3/components/Badge';
import Radio from 'v3/components/Radio';
import { useSnackbar } from 'v3/components/Snackbar';
import { BsCalendar } from 'react-icons/bs';
import moment from 'moment';

import api from 'services/api';
import { fetchTrackerTypes } from 'utils/fetches';
import CheckBox from 'components/CheckBox';
import { useVehicle } from '../context';
import {
  fetchCities,
  fetchBrands,
  fetchAnttTypes,
  fetchSelectOptions,
  colors,
} from '../controller';

export default function MainInputs() {
  const vehicle = useVehicle();
  const [vehicles, setVehicles] = useState({});
  const [axisOptions, setAxisOptions] = useState({});
  const [allAxisOptions, setAllAxisOptions] = useState([]);
  const [countries, setCountries] = useState({});
  const [fuels, setFuels] = useState();
  const [anttTypes, setAnttTypes] = useState();
  const [trackerTypes, setTrackerTypes] = useState({});
  const [trackers, setTrackers] = useState();
  const [uniqueAxis, setUniqueAxis] = useState(false);
  const [modelOptions, setModelOptions] = useState([]);
  const [tagRegisterPermitted] = useState(validatePermission('CRIAR_TAGS'));
  const hasPermissionToAlterRntrcValidated = validatePermission(
    'VALIDAR_RNTRC_VEICULO_IMPLEMENTO'
  );
  const snackbar = useSnackbar();
  const params = useParams();

  const [rntrcExpDate, setRntrcExpDate] = useState(null);
  const [chronotachographExpDate, setChronotachographExpDate] = useState(null);

  useEffect(() => {
    if (vehicle.data?.rntrc_exp_date) {
      setRntrcExpDate(
        moment(vehicle.data?.rntrc_exp_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
      );
    }
    if (vehicle.data?.chronotachograph_exp_date) {
      setChronotachographExpDate(
        moment(vehicle.data?.chronotachograph_exp_date, 'YYYY-MM-DD').format(
          'DD/MM/YYYY'
        )
      );
    }
  }, []);

  useEffect(() => {
    vehicle.setData({ rntrc_exp_date: rntrcExpDate });
  }, [rntrcExpDate]);

  useEffect(() => {
    vehicle.setData({ chronotachograph_exp_date: chronotachographExpDate });
  }, [chronotachographExpDate]);

  useEffect(() => {
    if (!params.id)
      vehicle.setData({
        city: null,
        type: null,
        axis: null,
        plate: null,
        renavam: null,
        antt: null,
        chassi: null,
        brand: null,
        model: null,
        year: null,
        color: null,
        fuel: null,
        tracker: null,
        tags: null,
        province: null,
        anttType: null,
        tracker_type: null,
        isTracked: false,
        crlv: null,
        rntrc_exp_date: null,
        chronotachograph_number: null,
        chronotachograph_exp_date: null,
        antt_adherence: null,
        RNTRC_validated: false,
      });
  }, []);

  useEffect(() => {
    if (!vehicle.data.isTracked)
      vehicle.setData({
        tracker: null,
        tracker_type: null,
        tracker_code: null,
        tracker_login: null,
        tracker_password: null,
      });
    else if (!vehicle.data.tracker) vehicle.setData({ tracker_type: null });
  }, [vehicle.data.isTracked, vehicle.data.tracker]);

  useEffect(() => {
    async function fetchTopTrackerTypes(tracker) {
      try {
        const response = await api.get('select/tracker-types', {
          params: {
            tracker: tracker.id,
          },
        });
        setTrackerTypes(response.data);
      } catch (error) {
        // Ignore exception
      }
    }
    if (vehicle.data?.tracker) {
      fetchTopTrackerTypes(vehicle.data.tracker);
    }
  }, [vehicle.data.tracker]);

  useEffect(() => {
    async function fetchTrackers() {
      const response = await api.get('tracker/App');
      setTrackers(response.data);
    }
    fetchTrackers();
  }, []);

  useEffect(() => {
    fetchAnttTypes(setAnttTypes);
  }, []);

  useEffect(() => {
    async function fetchFuels() {
      const response = await api.get('fuel');
      setFuels(response.data);
    }
    fetchFuels();
  }, []);

  useEffect(() => {
    async function fetchVehicles() {
      try {
        const response = await api.get('vehicle-types', {
          params: { app: true },
        });
        setVehicles(
          response.data?.filter(
            item => item.name !== 'Semi Reboque' && item.name !== 'Dolly'
          )
        );
      } catch (error) {
        // handle exception
      }
    }
    fetchVehicles();
  }, []);

  useEffect(() => {
    async function fetchVehicleAxis() {
      try {
        const response = await api.get('vehicle-axe', {
          params: { app: true },
        });
        setAllAxisOptions(response.data);
        setAxisOptions(response.data);
        if (vehicle.data.type?.id === 18 || !vehicle.data.type?.id) {
          setUniqueAxis(false);
        } else if (vehicle.data.type.id === 1) {
          if (vehicle.data.axis !== null && vehicle.data.axis?.id <= 3) {
            setUniqueAxis(false);
            vehicle.setData({ axis: null });
          }
          setAxisOptions(response.data.filter(axe => axe.id > 3));
        } else if (vehicle.data.type.id === 4) {
          if (
            vehicle.data.axis !== null &&
            (vehicle.data.axis?.id === 1 || vehicle.data.axis.id > 3)
          ) {
            setUniqueAxis(false);
            vehicle.setData({ axis: null });
          }
          setAxisOptions(response.data.filter(axe => axe.id > 1 && axe.id < 4));
        } else if (
          vehicle.data.type.id === 3 ||
          vehicle.data.type.id === 5 ||
          vehicle.data.type.id === 11 ||
          vehicle.data.type.id === 12
        ) {
          setUniqueAxis(true);
          vehicle.setData({ axis: response.data[0] });
          setAxisOptions([response.data[0]]);
        }
      } catch (error) {
        // handle exception
      }
    }

    if (allAxisOptions.length === 0) {
      fetchVehicleAxis();
    } else if (vehicle.data.type?.id === 18 || !vehicle.data.type?.id) {
      setUniqueAxis(false);
      setAxisOptions(allAxisOptions);
    } else if (vehicle.data.type.id === 1) {
      if (vehicle.data.axis !== null && vehicle.data.axis?.id <= 3) {
        setUniqueAxis(false);
        vehicle.setData({ axis: null });
      }
      setAxisOptions(allAxisOptions.filter(axe => axe.id > 3));
    } else if (vehicle.data.type.id === 4) {
      if (
        vehicle.data.axis !== null &&
        (vehicle.data.axis?.id === 1 || vehicle.data.axis.id > 3)
      ) {
        setUniqueAxis(false);
        vehicle.setData({ axis: null });
      }
      setAxisOptions(allAxisOptions.filter(axe => axe.id > 1 && axe.id < 4));
    } else if (
      vehicle.data.type.id === 3 ||
      vehicle.data.type.id === 5 ||
      vehicle.data.type.id === 11 ||
      vehicle.data.type.id === 12
    ) {
      setUniqueAxis(true);
      vehicle.setData({ axis: allAxisOptions[0] });
      setAxisOptions([allAxisOptions[0]]);
    }
  }, [vehicle.data.type]);

  useEffect(() => {
    if (vehicle.data?.type?.id !== 18) {
      vehicle.setData({ implement: null, implements: null });
    }
  }, [vehicle.data.type]);

  useEffect(() => {
    async function fetchCountries() {
      try {
        const response = await api.get('countries');
        setCountries(response.data);
        if (!params.id)
          vehicle.setData({
            country: response.data.find(value => value.id === 1),
          });
      } catch (err) {
        // handle exception
      }
    }
    fetchCountries();
  }, []);

  async function handleTagCreate(value) {
    let tagValue = value;
    if (tagRegisterPermitted) {
      if (tagValue) {
        tagValue = tagValue.trim();
        if (tagValue.length >= 3) {
          try {
            const response = await api.post('tags', { name: tagValue });
            if (response.status === 200) {
              const newTag = {
                id: response.data.id,
                name: tagValue,
              };
              if (vehicle.data.tags !== null)
                vehicle.setData({ tags: [...vehicle.data.tags, newTag] });
              else {
                vehicle.setData({ tags: [newTag] });
              }
            }
          } catch (err) {
            // Handle exception
          }
        }
      }
    } else {
      snackbar.show(<Text>Usuário sem permissão para criar tags</Text>, {
        type: 'error',
      });
    }
  }

  async function fetchModels(search) {
    if (vehicle.data?.brand?.id) {
      try {
        const response = await api.get(
          `models/${vehicle.data.brand.id}/select?search=${search}`
        );
        return response.data;
      } catch (error) {
        return [];
      }
    }
    snackbar.show(<Text>Selecione uma marca primeiro</Text>, { type: `error` });
    return [];
  }

  async function handleChangeBrand(value) {
    try {
      if (value?.id) {
        const response = await api.get(`v3/models/${value.id}`);
        setModelOptions(response.data);
      } else {
        setModelOptions([]);
      }
    } catch (error) {
      if (value?.id)
        snackbar.show(<Text>Erro ao buscar modelos</Text>, { type: 'error' });
    }

    vehicle.setData({ brand: value });
  }

  return (
    <Row className="form">
      <Col md={6} xs={12}>
        <Select
          label="País *"
          options={countries}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          value={vehicle.data.country}
          onChange={value => vehicle.setData({ country: value })}
          error={vehicle.errors?.country}
        />
      </Col>
      <Col xs={12}>
        <Select.Async
          label="Cidade *"
          onSearch={fetchCities}
          value={vehicle.data.city}
          getOptionLabel={option => `${option.name}, ${option.province.uf}`}
          getOptionValue={option => option.id}
          onChange={value =>
            vehicle.setData({
              city: value,
              province: value?.province,
            })
          }
          error={vehicle.errors?.city}
        />
      </Col>
      <Col md={6} xs={12}>
        <Select
          label="Tipo do veículo *"
          options={vehicles}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          value={vehicle.data.type}
          onChange={value => vehicle.setData({ type: value })}
          error={vehicle.errors?.type}
        />
      </Col>
      <Col md={6} xs={12}>
        <Select
          label={params.id ? 'Tração *' : 'Tração'}
          isDisabled={uniqueAxis}
          options={axisOptions}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          value={vehicle.data.axis}
          onChange={value => vehicle.setData({ axis: value })}
          error={vehicle.errors?.axis}
        />
      </Col>
      <Col md={6} xs={12}>
        <Input
          label="Placa *"
          value={vehicle.data.plate}
          onChange={event =>
            event.target.value.length > 7
              ? snackbar.show(<Text>Limite de dígitos para placa</Text>, {
                  type: 'error',
                })
              : vehicle.setData({ plate: event.target.value.toUpperCase() })
          }
          error={vehicle.errors?.plate}
        />
      </Col>
      <Col md={6} xs={12}>
        <Input
          label="Renavam *"
          type="number"
          value={vehicle.data.renavam}
          onChange={event =>
            event.target.value.length > 11
              ? snackbar.show(<Text> Limite de dígitos para Renavam </Text>, {
                  type: 'error',
                })
              : vehicle.setData({ renavam: event.target.value })
          }
          error={vehicle.errors?.renavam}
        />
      </Col>
      <Col md={6} xs={12}>
        <Select
          label="Tipo de RNTRC *"
          options={anttTypes}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          value={vehicle.data.anttType}
          onChange={value => vehicle.setData({ anttType: value })}
          error={vehicle.errors?.anttType}
        />
      </Col>

      <Col md={6} xs={12}>
        <Input
          label="RNTRC *"
          type="number"
          value={vehicle.data.antt}
          onChange={event => vehicle.setData({ antt: event.target.value })}
          error={vehicle.errors?.antt}
        />
      </Col>
      <Col md={6} xs={12}>
        <Input
          label="Data de emissão RNTRC *"
          type="date"
          value={vehicle?.data?.antt_adherence}
          onChange={event =>
            vehicle.setData({
              antt_adherence: event.target.value,
            })
          }
          error={vehicle.errors?.antt_adherence}
        />
      </Col>

      <Col md={6} xs={12}>
        <Input
          icon={<BsCalendar color="#464E5F" />}
          label="Validade RNTRC"
          value={rntrcExpDate}
          onChange={event => setRntrcExpDate(event.target.value)}
          masked
          mask={[
            /[0-9]/,
            /[0-9]/,
            '/',
            /[0-9]/,
            /[0-9]/,
            '/',
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
          ]}
          error={vehicle.errors?.rntrc_exp_date}
        />
      </Col>
      {hasPermissionToAlterRntrcValidated ? (
        <Col>
          <Text as="span" color="dark" type="label" weight={500}>
            RNTRC Validada?
          </Text>
          <div>
            <CheckBox
              checked={vehicle.data.RNTRC_validated}
              onChange={e =>
                vehicle.setData({ RNTRC_validated: e.target.checked })
              }
              label="Sim"
            />
          </div>
        </Col>
      ) : null}

      <Col md={6} xs={12}>
        <Input
          label="CRLV"
          type="number"
          value={vehicle.data.crlv}
          onChange={event => vehicle.setData({ crlv: event.target.value })}
          error={vehicle.errors?.crlv}
        />
      </Col>

      <Col md={6} xs={12}>
        <Input
          label="Cronotacógrafo"
          type="number"
          value={vehicle.data.chronotachograph_number}
          onChange={event =>
            vehicle.setData({ chronotachograph_number: event.target.value })
          }
          error={vehicle.errors?.chronotachograph_number}
        />
      </Col>

      <Col md={6} xs={12}>
        <Input
          icon={<BsCalendar color="#464E5F" />}
          label="Validade cronotacógrafo"
          value={chronotachographExpDate}
          onChange={event => setChronotachographExpDate(event.target.value)}
          masked
          mask={[
            /[0-9]/,
            /[0-9]/,
            '/',
            /[0-9]/,
            /[0-9]/,
            '/',
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
          ]}
          error={vehicle.errors?.chronotachograph_exp_date}
        />
      </Col>

      <Col md={6} xs={12}>
        <Input
          label="Chassi *"
          value={vehicle.data.chassi}
          onChange={event =>
            event.target.value.length > 20
              ? snackbar.show(<Text> Limite de dígitos para Chassi </Text>, {
                  type: 'error',
                })
              : vehicle.setData({ chassi: event.target.value })
          }
          error={vehicle.errors?.chassi}
        />
      </Col>
      <Col md={6} xs={12}>
        <Select.Async
          label="Marca"
          onSearch={fetchBrands}
          value={vehicle.data.brand}
          onChange={handleChangeBrand}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          error={vehicle.errors?.brand}
          removeInputMin
        />
      </Col>

      <Col md={6} xs={12}>
        <Select.Async
          label="Modelo"
          onSearch={fetchModels}
          value={vehicle.data.model}
          options={modelOptions}
          onChange={value => vehicle.setData({ model: value })}
          getOptionLabel={option => option.label}
          getOptionValue={option => option.label}
          error={vehicle.errors?.model}
        />
      </Col>
      <Col md={6} xs={12}>
        <Input
          label="Ano do modelo *"
          type="number"
          value={vehicle.data.year_model}
          onChange={event =>
            event.target.value.length > 4
              ? snackbar.show(<Text> Limite de dígitos para Ano </Text>, {
                  type: 'error',
                })
              : vehicle.setData({ year_model: event.target.value })
          }
          error={vehicle.errors?.year_model}
        />
      </Col>
      <Col md={6} xs={12}>
        <Input
          label="Ano de fabricação *"
          type="number"
          value={vehicle.data.year_manufacture}
          onChange={event =>
            event.target.value.length > 4
              ? snackbar.show(<Text> Limite de dígitos para Ano </Text>, {
                  type: 'error',
                })
              : vehicle.setData({ year_manufacture: event.target.value })
          }
          error={vehicle.errors?.year_manufacture}
        />
      </Col>
      <Col md={6} xs={12}>
        <Select
          label="Cor"
          options={colors}
          getOptionLabel={option => option.label}
          getOptionValue={option => option.value}
          value={vehicle.data.color}
          onChange={value => vehicle.setData({ color: value })}
          error={vehicle.errors?.color}
        />
      </Col>
      <Col md={6} xs={12}>
        <Radio.Group
          label="Rastreado *"
          onChange={({ target }) =>
            vehicle.setData({ isTracked: target.value })
          }
          value={vehicle.data.isTracked}
          horizontal
          error={vehicle.errors.isTracked}
        >
          <Radio value>
            <Text color="dark" type="label">
              Sim
            </Text>
          </Radio>
          <Radio value={false}>
            <Text color="dark" type="label">
              Não
            </Text>
          </Radio>
        </Radio.Group>
        {vehicle.errors?.isTracked && (
          <Badge className="mt-1" light variant="error">
            Campo obrigatório
          </Badge>
        )}
      </Col>
      <Col md={6} xs={12}>
        <Select
          placeholder={
            vehicle.data.isTracked ? 'Selecione...' : 'Veículo não rastreado'
          }
          isDisabled={!vehicle.data.isTracked}
          label={`Marca rastreador ${vehicle.data.isTracked ? '*' : ''}`}
          options={trackers}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          value={vehicle.data.isTracked ? vehicle.data.tracker : null}
          onChange={value =>
            vehicle.setData({ tracker: value, tracker_type: null })
          }
          error={vehicle.errors?.tracker}
        />
      </Col>

      <Col>
        <Select.Async
          onSearch={search => fetchTrackerTypes(search, vehicle.data.tracker)}
          value={vehicle.data.tracker ? vehicle.data.tracker_type : null}
          onChange={value => vehicle.setData({ tracker_type: value })}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          label="Tipo de rastreador"
          placeholder={
            vehicle.data.isTracked
              ? vehicle.data.tracker
                ? 'Selecione...'
                : 'Veículo sem rastreador'
              : 'Veículo não rastreado'
          }
          options={trackerTypes}
          isDisabled={!vehicle.data.isTracked || !vehicle.data.tracker}
          error={vehicle.errors?.tracker_type}
          NoOptionsComponent={() => (
            <Text>Nenhum tipo cadastrado para o rastreador informado</Text>
          )}
        />
      </Col>

      <Col md={6} xs={12}>
        <Input
          placeholder={
            vehicle.data.isTracked ? 'Digite...' : 'Veículo não rastreado'
          }
          disabled={!vehicle.data.isTracked}
          label="ID do rastreador"
          value={vehicle.data.tracker_code}
          onChange={event =>
            vehicle.setData({ tracker_code: event.target.value })
          }
          error={vehicle.errors?.tracker_code}
        />
      </Col>

      <Col md={6} xs={12}>
        <Input
          disabled={!vehicle.data.isTracked}
          label="Login do rastreador"
          value={vehicle.data.tracker_login}
          onChange={event =>
            vehicle.setData({ tracker_login: event.target.value })
          }
          error={vehicle.errors?.tracker_login}
        />
      </Col>

      <Col md={6} xs={12}>
        <Input
          disabled={!vehicle.data.isTracked}
          label="Senha do rastreador"
          value={vehicle.data.tracker_password}
          onChange={event =>
            vehicle.setData({ tracker_password: event.target.value })
          }
          error={vehicle.errors?.tracker_password}
        />
      </Col>

      <Col md={6} xs={12}>
        <Select
          label="Combustível"
          options={fuels}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          value={vehicle.data.fuel}
          onChange={value => vehicle.setData({ fuel: value })}
          error={vehicle.errors?.fuel}
        />
      </Col>
      <Col xs={12}>
        <Select.Async
          label="Tags"
          creatable
          onCreateOption={handleTagCreate}
          multiple
          value={vehicle.data.tags}
          horizontal
          modalHeading="Adicione uma tag"
          modalBodyTitle="Tags:"
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          onChange={event => vehicle.setData({ tags: event })}
          onSearch={search =>
            fetchSelectOptions('tags', {
              search,
              not_paginated: true,
            })
          }
          error={vehicle.errors?.tags}
        />
      </Col>
    </Row>
  );
}
