import moment from 'moment';
import 'moment/locale/pt-br';

const currencyFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
  maximumFractionDigits: 2,
});

const numberFormatter = new Intl.NumberFormat('pt-BR');

const customCurrencies = [
  'AOA',
  'BOB',
  'ARS',
  'CLP',
  'COU',
  'ECS',
  'PYG',
  'PEN',
  'UYU',
  'USD',
];

export function formatCurrency(value, currency = 'BRL', symbol, digits = 2) {
  const customNumberFormat = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: digits,
  });
  const formatted = customNumberFormat.format(value);

  if (customCurrencies.includes(currency)) {
    return `${symbol} ${formatted.replace(/[^\d,]/g, '')}`;
  }
  return formatted;
}

export function formatNumber(value) {
  return numberFormatter.format(value);
}

export function formatDateTime(value) {
  if (!value) {
    return 'Não informado';
  }

  return moment(value).format('DD/MM/YYYY [às] HH:mm');
}

export function formatDate(value) {
  if (!value) {
    return 'Não informado';
  }

  return moment(value).format('DD/MM/YYYY');
}

export function formatDateTimeComplete(value, withoutHour) {
  if (!value) {
    return 'Data não informada';
  }
  if (withoutHour) {
    return moment(value).format('DD [de] MMMM');
  }

  return moment(value).format('DD [de] MMMM [às] HH:mm');
}

export function formatTime(value) {
  return moment(value).format('HH:mm');
}

export function formatDateCustom(value, format) {
  return moment(value).format(format);
}

export function formatDateFromNow(value) {
  return moment(value).fromNow();
}

export function formatDateToNow(value) {
  return moment(value).toNow();
}

export function formatDistance(distanceInKm, fallback = '-') {
  if (distanceInKm <= 0) {
    return fallback;
  }

  if (distanceInKm < 1) {
    const distanceInMeters = distanceInKm * 1000;
    const formatted = formatNumber(distanceInMeters.toFixed());

    return `${formatted} metros`;
  }

  const formatted = formatNumber(distanceInKm.toFixed());
  return `${formatted} km`;
}

export function capitalize(value) {
  if (typeof value !== 'string') return value;
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function capitalizeFullname(value) {
  if (!value) {
    return '';
  }

  return value
    .split(' ')
    .map(word =>
      word.length > 2
        ? word.replace(/(^\w{1})/g, match => match.toUpperCase())
        : word
    )
    .join(' ');
}
