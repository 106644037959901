import moment from 'moment';
import api, { cooperplaceApi } from 'services/api';
import { DELIVERY_TYPE_OPTIONS } from 'v3/utils/constants';

export async function fetchProvinces(search, uf, country) {
  try {
    const response = await api.get('cities', {
      params: { search, uf, country },
    });
    return response.data;
  } catch (ex) {
    return [];
  }
}

export function getOrigins(originsRawData, countryList) {
  if (originsRawData) {
    const formatted_origins = originsRawData.map(origin => ({
      ...origin,
      country: countryList.find(
        element => element.abbreviation === origin.countrySlug
      ),
      zipCode: origin.zipCode || '',
      number: origin.number || '',
      neighborhood: origin.neighborhood || '',
      complement: origin.complement || '',
      action: DELIVERY_TYPE_OPTIONS.find(({ id }) => id === 'collect'),
      scheduledTime: origin.scheduledTime
        ? moment(new Date(origin.scheduledTime)).format('YYYY-MM-DDTHH:mm')
        : '',
      formattedAddress: origin.formattedAddress,
      startSchedule: origin.startSchedule
        ? moment(new Date(origin.startSchedule)).format('YYYY-MM-DDTHH:mm')
        : '',
      type: 'origin',
    }));

    return formatted_origins;
  }
}
export function getDestinations(destinationsRawData, countryList) {
  if (destinationsRawData) {
    const formatted_destinations = destinationsRawData.map(destination => ({
      ...destination,
      country: countryList.find(
        element => element.abbreviation === destination.countrySlug
      ),
      zipCode: destination.zipCode || '',
      number: destination.number || '',
      neighborhood: destination.neighborhood || '',
      complement: destination.complement || '',
      action: DELIVERY_TYPE_OPTIONS.find(
        ({ id }) => id === destination.action.toLowerCase()
      ),
      scheduledTime: destination.scheduledTime
        ? moment(new Date(destination.scheduledTime)).format('YYYY-MM-DDTHH:mm')
        : '',
      startSchedule: destination.startSchedule
        ? moment(new Date(destination.startSchedule)).format('YYYY-MM-DDTHH:mm')
        : '',
      type: 'destination',
    }));

    return formatted_destinations;
  }
}

export async function deleteDestination(destinationId, loadId){
  try {
    await cooperplaceApi.delete(
      `loads/${loadId}/destinations/${destinationId}`
    );
    return true
  } catch (error) {
    return false
  }
}

export async function save(location) {
  try {
    const clientId = location?.client?.id || null;
    delete location?.client;
    const reassign_location = {
      ...location,
      city: location.cityModel ? location.cityModel.name : location.cityModel,
      cityId: location.cityModel?.id || null,
      province: location.province,
      clientId,
      action: location.action.id,
      country_slug: location.country.country_slug,
    };

    if (reassign_location.type === 'origin') delete reassign_location.action;

    delete reassign_location.status;

    delete reassign_location.formatted;
    delete reassign_location.country;
    delete reassign_location.translatedAction;
    delete reassign_location.cityModel;
    let response;
    if (reassign_location.type === 'origin') {
      delete reassign_location.type;
      response = await cooperplaceApi.put(
        `loads/${location?.loadId}/origin`,
        reassign_location
      );
    } else {
      delete reassign_location.type;
      response = await cooperplaceApi.put(
        `loads/${location?.loadId}/destinations/${location.id}`,
        reassign_location
      );
    }

    return {
      success: `local de ${
        location.type === 'origin' ? 'Origem' : 'Destino'
      } atualizado`,
      data: response.data,
    };
  } catch (err) {
    return { error: 'Não foi possível atualizar local' };
  }
}

export function validate(location, origins, destinations) {
  let errors = { hasError: false };

  if (!location.country) errors.country = 'País obrigatório.';
  if (!location.address) errors.address = 'Endereço Obrigatório.';
  if (!location.cityModel) errors.cityModel = 'Cidade obrigatória.';
  if (!location.province) errors.province = 'UF obrigatório.';
  if (!location.scheduledTime)
    errors.scheduledTime = 'Data Máxima obrigatória.';
  if (!location.action) errors.action = 'Campo obrigatório.';
  if (!location.startSchedule)
    errors.startSchedule = 'Data Início obrigatória.';
  if (!location.lat)
    errors.lat = 'Selecione um logradouro utilizando o seletor do google';
  if (!location.lng)
    errors.lng = 'Selecione um logradouro utilizando o seletor do google';

  const scheduled_time = moment(location.scheduledTime);
  const start_schedule = moment(location.startSchedule);

  if (scheduled_time.isBefore(start_schedule)) {
    errors.startSchedule =
      'A data de início de coleta deve ser anterior à data máxima de coleta.';
  }

  const date_error =
    location.type === 'origin'
      ? validateOriginDate(scheduled_time, destinations)
      : validateDestinationDate(scheduled_time, origins);

  errors = { ...errors, ...date_error };

  errors.hasError = Object.keys(errors).length > 1;

  return errors;
}

function validateOriginDate(scheduled_time, destinations) {
  const invalid_date = destinations.some(item => {
    const date = moment(item.scheduledTime);

    return moment(date).isBefore(scheduled_time);
  });
  const is_retroactive_date = moment(scheduled_time).isBefore(
    moment(new Date(), 'YYYY-MM-DDTHH:mm')
  );

  return is_retroactive_date
    ? {
        dateError:
          'Data máxima da coleta informada não pode ser anterior ao dia/horário atual.',
      }
    : invalid_date
    ? {
        dateError: 'Data de origem da coleta maior que alguma data de destino.',
      }
    : {};
}

function validateDestinationDate(scheduled_time, origins) {
  const invalid_date = origins.some(item => {
    const date = moment(item.scheduledTime);
    return moment(scheduled_time).isBefore(date);
  });
  const is_retroactive_date = moment(scheduled_time).isBefore(
    moment(new Date(), 'YYYY-MM-DDTHH:mm')
  );

  return is_retroactive_date
    ? {
        dateError:
          'Data máxima do destino informada não pode ser anterior ao dia/horário atual.',
      }
    : invalid_date
    ? {
        dateError: 'Data do destino menor que a data de origem.',
      }
    : {};
}
