import React from 'react';
import Link from '@material-ui/core/Link';
import { Col, Row } from 'react-bootstrap';
import { Text } from 'v3/components';
import { CardStyled, OpenInNewIconStyled, TextView } from './styles';


function MapDetailCard(props) {
  const moment = require('moment');

  const openInNewTab = url => {
    window.open(url, '_blank', 'noreferrer');
  };
  function formatDate(date) {
    const newDate = moment(date).format('DD/MM/YYYY - HH:mm:ss');
    return newDate;
  }
  return (
    <CardStyled>
      <Col md={12}>
        <Row md={12} lg={12} className="mt-2">
          <Col md={3}>
            <Text fontSize={12} weight={500}>
              Motorista:
              <br></br>
              {props?.load?.driveName || 'Não informado'}
            </Text>
          </Col>
          <Col md={3}>
            <Text fontSize={12} weight={500}>
              Telefone:
              <br></br>
            </Text>
            <Link
              href={`https://api.whatsapp.com/send?phone=55${props?.load?.phone?.replace(/^(\+)|\D/g, '$1'
              )}`}
              target="_blank"
            >
              <TextView>{props?.load?.phone || 'Não informado'}</TextView>
            </Link>
          </Col>
          <Col md={3}>
            <Text fontSize={12} weight={500}>
              Cliente tomador:
              <br></br>
              {props?.load?.lastravelTakerName || 'Não informado'}
            </Text>
          </Col>
          <Col md={3}>
            <Text fontSize={12} weight={500}>
              Endereço entrega da última viagem:
              <br></br>
              {props?.load?.lastTravelDeliveryAdress || 'Não informado'}
            </Text>
          </Col>
        </Row>
        <Row md={12} lg={12} className="mt-2">
          <Col md={3}>
            <Text fontSize={12} weight={500}>
              Tipo veículo de tração:
              <br></br>
              {props?.load?.tractionVehicleType || 'Não informado'}
            </Text>
          </Col>
          <Col md={3}>
            <Text fontSize={12} weight={500}>
              Placa veículo de tração:
              <br></br>
              {props?.load?.tractionPlate || 'Não informado'}
            </Text>
          </Col>
          <Col md={3}>
            <Text fontSize={12} weight={500}>
              Tipo dos implementos:
              <br></br>
              {props?.load?.vehicleType || 'Não informado'}
            </Text>
          </Col>
          <Col md={3}>
            <Text fontSize={12} weight={500}>
              Placa implementos:
              <br></br>
              {props?.load?.wagonslate || 'Não informado'}
            </Text>
          </Col>
        </Row>
        <Row md={12} className="mt-2">
          <Col md={3}>
            <Text fontSize={12} weight={500}>
              Última viagem (Benner):
              <br></br>
              {props?.load?.lastExternalTravelNumber || 'Não informado'}
            </Text>
          </Col>
          <Col md={3}>
            <Text fontSize={12} weight={500}>
              Última viagem (Cooperplace):
              <br></br>
              {props?.load?.lastTravelNumber || 'Não informado'}
            </Text>
            <TextView
              onClick={() => openInNewTab(`/viagens/${props?.load?.lastTravelNumber}`)}
            >
              Ver
            </TextView>
            <OpenInNewIconStyled></OpenInNewIconStyled>
          </Col>
          <Col md={3}>
            <Text fontSize={12} weight={500}>
              Data última posição:
              <br></br>
              {formatDate(props?.load?.lastDateInfo) || 'Não informado'}
            </Text>
          </Col>
          <Col md={3}>
            <Text fontSize={12} weight={500}>
              ́Localização última posição:
              <br></br>
              {props?.load?.lastRasterPosition || 'Não informado'}
            </Text>
          </Col>
        </Row>
      </Col>
    </CardStyled>
  );
}

export default MapDetailCard;
