/* eslint-disable no-restricted-globals */
/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import api, { cooperplaceApi } from 'services/api';
import useQuery from 'hooks/useQuery';
import { CPF_MASK } from 'utils/masks';

import Select from 'v3/components/Select';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import Input from 'v3/components/Input';

function Filter({ onChange }) {
  const query = useQuery();
  const history = useHistory();

  const [companies, setCompanies] = useState();
  const [profileList, setProfileList] = useState();
  const [filter, setFilter] = useState({});

  const userLogged = localStorage.getItem('usuario');
  const jsonUser = JSON.parse(userLogged);

  async function fetchProfiles() {
    try {
      const response = await api.get('users-profiles');

      setProfileList(response.data);
    } catch (error) {
      //
    }
  }

  async function fetchCompanies() {
    try {
      const response = await cooperplaceApi.get('user/companies');

      setCompanies(response.data.companies);
    } catch (error) {
      //
    }
  }

  async function getQuery() {
    if (query.has('company_id')) {
      const company_id = query.get('company_id');
      const company_name = query.get('company_name');

      setFilter(old => ({
        ...old,
        company: { id: company_id, name: company_name },
      }));
    }

    if (query.has('profile_id')) {
      const profile_id = query.get('profile_id');
      const profile_name = query.get('profile_name');

      setFilter(old => ({
        ...old,
        profile: { id: profile_id, name: profile_name },
      }));
    }

    if (query.has('username')) {
      const userQuery = query.get('username');

      setFilter(old => ({ ...old, username: userQuery }));
    }
  }

  useEffect(() => {
    fetchCompanies();
    fetchProfiles();
    getQuery();
    onChange(1);
  }, []);

  useEffect(() => {
    history.replace({ search: query.toString() });
  }, [filter]);

  function applyMask(value) {
    const isCPF = value.length === 11 && !isNaN(+value);

    if (isCPF) {
      return CPF_MASK;
    }

    return false;
  }

  return (
    <Row className="mb-4  d-flex align-items-center" style={{ width: '97%' }}>
      <Col md={3} xs={12}>
        <Select
          labelTextColor="#fff"
          label="Perfil"
          options={profileList}
          value={filter.profile}
          placeholder=""
          onChange={value => {
            if (value === null) {
              query.delete('profile_id');
              query.delete('profile_name');
            } else {
              query.set('profile_id', value?.id);
              query.set('profile_name', value?.name);
            }

            setFilter(old => ({ ...old, profile: value }));
          }}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
        />
      </Col>

      <Col xs={12} md={4}>
        <Input
          labelColor="#fff"
          label="Usuário"
          placeholder="Nome, CPF ou Email"
          value={filter.username}
          onChange={event => {
            if (event.target.value === null || event.target.value === '') {
              query.delete('username');
            }
            query.set('username', event.target.value);

            setFilter(old => ({ ...old, username: event.target.value }));
          }}
          masked
          mask={applyMask}
          guide={false}
        />
      </Col>
      {jsonUser.profile_id === 1 && (
        <Col xs={12} md={4}>
          <Select
            labelTextColor="#fff"
            label="Empresa"
            options={companies}
            value={filter.company}
            placeholder=""
            onChange={value => {
              if (value === null) {
                query.delete('company_id');
                query.delete('company_name');
              } else {
                query.set('company_id', value?.id);
                query.set('company_name', value?.name);
              }
              setFilter(old => ({ ...old, company: value }));
            }}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
          />
        </Col>
      )}
      <Col xs={12} md={1} style={{ marginTop: 30 }}>
        <Button variant="success" onClick={() => onChange(1)}>
          <Text type="regular" weight={500}>
            Filtrar
          </Text>
        </Button>
      </Col>
    </Row>
  );
}

export default Filter;
