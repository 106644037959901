import * as Yup from 'yup';

import { plateValidator } from 'utils/vehicle';
import { isValid, isBefore, parse, addYears, isAfter } from 'date-fns';

export const ownerSchema = Yup.object().shape({
  owner: Yup.object()
    .shape({
      id: Yup.number().required(),
    })
    .typeError('Proprietário obrigatório'),
  beneficiary: Yup.object()
    .shape({
      id: Yup.number().required(),
    })
    .typeError(' obrigatório'),
  anttOwner: Yup.object()
    .shape({
      id: Yup.number().required('Proprietário do RNTRC é obrigatório'),
    })
    .typeError('Proprietário do RNTRC é obrigatório'),
});

export const mainSchema = Yup.object().shape({
  antt: Yup.string()
    .nullable()
    .required('RNTRC obrigatório')
    .typeError('RNTRC obrigatório'),
  anttType: Yup.object()
    .shape({
      id: Yup.number().required(),
    })
    .typeError('Tipo de RNTRC obrigatório'),
  rntrc_exp_date: Yup.string().nullable(),
  chronotachograph_exp_date: Yup.string().nullable(),
  chronotachograph_number: Yup.string().nullable(),
  axis: Yup.object()
    .shape({
      id: Yup.number().required(),
    })
    .typeError('Tração obrigatória'),
  brand: Yup.object()
    .shape({
      id: Yup.number().nullable(),
    })
    .nullable()
    .typeError('Marca inválida'),
  city: Yup.object()
    .shape({
      id: Yup.number().required('Cidade obrigatória'),
    })
    .typeError('Cidade obrigatória'),
  color: Yup.object()
    .shape({
      value: Yup.string().nullable(),
    })
    .nullable(),
  country: Yup.object()
    .shape({
      id: Yup.number().required(),
    })
    .typeError('País obrigatório'),
  fuel: Yup.object()
    .shape({
      id: Yup.number().nullable(),
    })
    .nullable(),
  isTracked: Yup.boolean().nullable(),
  tracker_login: Yup.string().nullable(),
  tracker_password: Yup.string().nullable(),
  model: Yup.object()
    .shape({
      label: Yup.string().nullable(),
    })
    .nullable(),
  plate: Yup.string()
    .required('Placa obrigatória')
    .nullable()
    .when('country', {
      is: value => ['ar', 'br', 'py', 'uy'].includes(value?.abbreviation),
      then: Yup.string().test('plateValidation', 'Placa inválida', value =>
        plateValidator(value)
      ),
    })
    .typeError('Placa obrigatória'),
  province: Yup.object()
    .shape({
      id: Yup.number().required(),
    })
    .typeError('Estado obrigatório'),
  tags: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number(),
      })
    )
    .nullable()
    .notRequired(),
  tracker: Yup.object()
    .shape({
      id: Yup.number().nullable(),
    })
    .nullable()
    .when('isTracked', {
      is: value => value,
      then: Yup.object()
        .shape({
          id: Yup.number().required(),
        })
        .nullable()
        .required('Rastreador obrigatório'),
    }),
  tracker_code: Yup.string()
    .nullable(),
  tracker_type: Yup.object()
    .nullable()
    .shape({
      id: Yup.number(),
    }),
  type: Yup.object()
    .shape({
      id: Yup.number().required(),
    })
    .typeError('Tipo de veículo obrigatório'),
  renavam: Yup.number()
    .transform(original => {
      if (!original) {
        return undefined;
      }
      return original;
    })
    .required('Informe o renavam'),
  year_model: Yup.string().nullable().required('Informe ano do modelo'),
  year_manufacture: Yup.string()
    .nullable()
    .required('Informe ano de fabricação'),
  chassi: Yup.string().nullable().required('Informe o chassi'),
});

export const dataSchema = Yup.object().shape({
  tare_mass: Yup.number()
    .positive('Tara precisa ser um valor positivo')
    .required('Tara obrigatório')
    .typeError('Tara obrigatória')
    .integer('Tara precisa ser um valor inteiro'),
  total_gross_weight: Yup.number()
    .positive('Peso precisa ser um valor positivo')
    .required('Peso obrigatório')
    .typeError('Peso obrigatório')
    .integer('Peso precisa ser um valor inteiro'),
  cubage: Yup.number()
    .nullable()
    .integer('Cubagem precisa ser um valor inteiro')
    .transform((currentValue, originalValue) => {
      return originalValue === '' ? null : currentValue;
    }),
  capacity: Yup.number()
    .nullable()
    .integer('Pallets precisa ser um valor inteiro')
    .transform((currentValue, originalValue) => {
      return originalValue === '' ? null : currentValue;
    }),
});

export const implementsSchema = Yup.object().shape({
  implement: Yup.object()
    .shape({
      id: Yup.number(),
    })
    .nullable()
    .typeError('Tipo de implemento inválido'),
  implements: Yup.mixed()
    .when('$vehicleType', {
      is: value => value.qty_plates === 4,
      then: Yup.array()
        .of(
          Yup.object()
            .nullable()
            .shape({
              identifier: Yup.object().nullable().shape({
                id: Yup.number(),
                plate: Yup.string(),
                vehicle: Yup.object(),
                vehicle_id: Yup.number(),
              }),
            })
        )
        .compact(),
    })
    .nullable(),
  body: Yup.object()
    .shape({
      id: Yup.number().required(),
    })
    .typeError('Tipo de carroceria obrigatório'),
});

function whiteSpace(value) {
  if (value) {
    const onlySpace = value.toString().replace(/\s/g, '');
    return !!onlySpace;
  }

  return true;
}

function validValidateDate(value) {
  if (value) {
    let dDay;
    let dMonth;
    let dYear;
    if (value.indexOf('-') !== -1) {
      const [year, month, day] = value.substring(0, 10).split('-');
      dDay = day;
      dMonth = month;
      dYear = year;
    } else {
      const [day, month, year] = value.split('/');
      dDay = day;
      dMonth = month;
      dYear = year;
    }

    if (!Number(dYear) && !Number(dMonth) && !Number(dDay)) {
      return false;
    }

    const date = parse(
      `${Number(dYear)}-${Number(dMonth)}-${Number(dDay)}`,
      'yyyy-MM-dd',
      new Date()
    );

    const valid = isValid(date);

    if (!valid) {
      return false;
    }

    const isGreatter = isAfter(date, new Date());

    return isGreatter;
  }

  return true;
}

function expireDate(value, time) {
  if (value) {
    const maxDate = addYears(new Date(), time);

    let dDay;
    let dMonth;
    let dYear;
    if (value.indexOf('-') !== -1) {
      const [year, month, day] = value.substring(0, 10).split('-');
      dDay = day;
      dMonth = month;
      dYear = year;
    } else {
      const [day, month, year] = value.split('/');
      dDay = day;
      dMonth = month;
      dYear = year;
    }

    if (!Number(dYear) && !Number(dMonth) && !Number(dDay)) {
      return false;
    }

    const date = parse(
      `${Number(dYear)}-${Number(dMonth)}-${Number(dDay)}`,
      'yyyy-MM-dd',
      new Date()
    );

    if (!date) {
      return false;
    }

    return isBefore(date, maxDate);
  }
  return true;
}

const schema = Yup.object().shape({
  antt: Yup.string().nullable().required('RNTRC obrigatório'),
  antt_adherence: Yup.string()
    .nullable()
    .test('not-empty', 'Informe uma data', value => {
      if (!value) {
        return false;
      }
      return true;
    })
    .test('valid-date', 'Formato inválido', value => {
      return /^\d{4}\-\d{2}\-\d{2}$/.test(value);
    })
    .test(
      'valid-past-or-present-date',
      ({ originalValue }) => {
        return originalValue !== undefined &&
          originalValue !== '' &&
          originalValue !== null
          ? 'Data não pode ser posterior à data de hoje'
          : 'Informe uma data';
      },
      value => {
        if (!value) {
          return false;
        }

        const inputDate = new Date(value);
        const currentDate = new Date();
        return inputDate <= currentDate;
      }
    ),
  anttType: Yup.object()
    .shape({
      id: Yup.number(),
    })
    .required('Informe tipo RNTRC')
    .nullable(),
  axis: Yup.object()
    .shape({
      id: Yup.number().required().nullable(),
    })
    .nullable(),
  body: Yup.object()
    .shape({
      id: Yup.number(),
    })
    .required('Informe tipo de carroceria')
    .nullable(),

  brand: Yup.object()
    .shape({
      id: Yup.number().nullable(),
    })
    .nullable(),
  chassi: Yup.string().nullable().required('Informe o chassi'),
  city: Yup.object()
    .shape({
      id: Yup.number(),
    })
    .required('Cidade obrigatória')
    .nullable(),
  color: Yup.object()
    .shape({
      value: Yup.string().nullable(),
    })
    .nullable(),
  country: Yup.object()
    .shape({
      id: Yup.number().required(),
    })
    .typeError('País obrigatório'),
  drivers: Yup.array().of(
    Yup.object().shape({
      value: Yup.number(),
    })
  ),
  fuel: Yup.object()
    .shape({
      id: Yup.number().nullable(),
    })
    .nullable(),
  implements: Yup.object().shape({
      identifier: Yup.object().shape({
        id: Yup.number(),
        plate: Yup.string(),
        vehicle: Yup.object(),
        vehicle_id: Yup.number(),
      })})
    .nullable(),
  isTracked: Yup.boolean().required('Campo obrigatório'),
  model: Yup.object()
    .shape({
      label: Yup.string().nullable(),
    })
    .nullable(),
  owner: Yup.object()
    .shape({
      id: Yup.number(),
    })
    .required('Informe o proprietário')
    .nullable(),
  beneficiary: Yup.object()
    .shape({
      id: Yup.number().required(),
    })
    .typeError('Beneficiário obrigatório'),
  plate: Yup.string()
    .required('Placa obrigatória')
    .nullable()
    .when('country', {
      is: value => ['ar', 'br', 'py', 'uy'].includes(value?.abbreviation),
      then: Yup.string()
        .nullable()
        .required('Placa obrigatória')
        .test('plateValidation', 'Placa inválida', value =>
          plateValidator(value)
        ),
    })
    .typeError('Placa obrigatória'),
  province: Yup.object()
    .shape({
      id: Yup.number().required().nullable(),
    })
    .nullable(),
  tags: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number(),
      })
    )
    .nullable()
    .notRequired(),
  tare_mass: Yup.number().nullable(),
  total_gross_weight: Yup.number().nullable(),
  cubage: Yup.number()
    .nullable()
    .integer('Cubagem precisa ser um valor inteiro')
    .transform((currentValue, originalValue) => {
      return originalValue === '' ? null : currentValue;
    }),
  capacity: Yup.number()
    .nullable()
    .integer('Pallets precisa ser um valor inteiro')
    .transform((currentValue, originalValue) => {
      return originalValue === '' ? null : currentValue;
    }),
  tracker: Yup.object()
    .shape({
      id: Yup.number().nullable(),
    })
    .nullable()
    .when('isTracked', {
      is: value => value,
      then: Yup.object()
        .nullable()
        .shape({
          id: Yup.number(),
        })
        .typeError('Marca rastreador obrigatório')
        .required('Marca Rastreador obrigatório'),
    }),
  tracker_code: Yup.string()
    .nullable(),
  tracker_type: Yup.object()
    .nullable()
    .shape({
      id: Yup.number(),
    }),
  tollTags: Yup.array().of(
    Yup.object().shape({
      number: Yup.string().when('$tollTags', {
        is: value => value.length > 0,
        then: Yup.string().required('Número de tag obrigatório'),
      }),
      tollTag: Yup.object()
        .shape({
          id: Yup.number().when('$tollTags', {
            is: value => value.length > 0,
            then: Yup.number().required('Tag de pedágio obrigatório'),
          }),
        })
        .typeError('Empresa de pedágio obrigatória'),
    })
  ),
  type: Yup.object()
    .shape({
      id: Yup.number(),
      name: Yup.string(),
    })
    .required('Informe o tipo de veículo')
    .nullable(),
  implement: Yup.object()
    .shape({
      id: Yup.number(),
    })
    .when('type', {
      is: type => type?.name === 'Cavalo Mecânico',
      then: Yup.object()
        .shape({
          id: Yup.number(),
        })
        .nullable()
        .required('Tipo de implemento obrigatório'),
      otherwise: Yup.object()
        .shape({
          id: Yup.number().optional(),
        })
        .nullable(),
    }),
  crlv: Yup.number()
    .positive('CRLV deve conter somente números')
    .nullable()
    .test('white-space-field', 'Campo inválido', value => whiteSpace(value)),
  rntrc_exp_date: Yup.string()
    .nullable()
    .test('valid-date', 'Validade expirada', value => validValidateDate(value))
    .test('exp-date', 'Válidade máxima 5 anos', value => expireDate(value, 5)),
  chronotachograph_number: Yup.number()
    .positive('Cronotacógrafo deve conter somente números')
    .nullable()
    .test('white-space-field', 'Campo inválido', value => whiteSpace(value)),
  chronotachograph_exp_date: Yup.string()
    .nullable()
    .when('chronotachograph_number', {
      is: value => value,
      then: Yup.string().required('Cronotacógrafo preenchido sem validade'),
    })
    .test('valid-date', 'Validade expirada', value => validValidateDate(value))
    .test('exp-date', 'Válidade máxima 2 anos', value => expireDate(value, 2)),
  renavam: Yup.number()
    .transform(original => {
      if (!original) {
        return undefined;
      }
      return original;
    })
    .required('Informe o renavam'),
  year_model: Yup.string().nullable().required('Informe ano do modelo'),
  year_manufacture: Yup.string()
    .nullable()
    .required('Informe ano de fabricação'),
});

export default schema;
