import React, { useMemo, useState, useEffect } from 'react';
import { cooperplaceApi } from 'services/api';
import apiReport from 'services/apis/report';
import FileSaver from 'file-saver';
import moment from 'moment';
import ApplicationLayoutVehicleControlTower from 'v3/layouts/Application/HeaderVechicleControlTower';
import MapClusterVehicleTower from 'v3/components/MapClusterVehicleTower';
import { useSnackbar } from 'v3/components/Snackbar';
import { Text } from 'v3/components';
import { Col, Row } from 'react-bootstrap';
import { useQuery } from 'hooks';
import { DefaultLoadingComponent } from 'v3/components/List';
import { endOfDay, startOfDay, subDays } from 'date-fns';
import { useHistory } from 'react-router-dom';
import ContractPendingMessage from 'v3/components/ContractPendingMessage';
import SVGMaps from './SVGMaps';
import { Grid } from './Grid';
import { isDatePeriodValid, setQueryParams } from './Filter/utils';
import { Filter } from './Filter';

export default function VehicleControlTower() {
  const snackbar = useSnackbar();
  const history = useHistory();
  const query = useQuery();
  const [loading, setLoading] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [filters, setFilters] = useState({});
  const [toggleFilters, setToggleFilters] = useState(false);
  const [toggleMaps, setToggleMaps] = useState(false);
  const [toggleSVGMaps, setToggleSVGMaps] = useState(false);
  const [checked, setChecked] = useState(false);
  const [svgVehiclesData, setSVGVehiclesData] = useState({});
  const [vehiclesData, setVehiclesData] = useState([]);
  const [location, setLocation] = useState('');
  const [isTracked, setIsTracked] = useState(null);
  const filteredVehicles = useMemo(() => {
    if (location.length > 0) {
      const filtered = vehiclesData.filter(
        vehicle =>
          vehicle?.currentProvinceName?.toLocaleLowerCase() ===
            location?.toLocaleLowerCase() ||
          vehicle?.currentCountryName?.toLocaleLowerCase() ===
            location?.toLocaleLowerCase()
      );

      return filtered;
    }
    if (isTracked !== null) {
      return vehiclesData.filter(vehicle => vehicle?.noRaster === !isTracked);
    }
    return vehiclesData;
  }, [location, vehiclesData, isTracked]);

  async function fetchData() {
    try {
      setLoading(true);
      setLocation('');
      setIsTracked(null);
      setChecked(false);
      const initialDate = query.get('lastTravelDateBegin');
      const finalDate = query.get('lastTravelDateEnd');
      const { valid, message } = isDatePeriodValid(initialDate, finalDate);

      if (!valid) {
        snackbar.show(<Text>{message}</Text>, { type: 'error' });
        return;
      }
      const response = await apiReport.get(`/vehicles/location?${query}`);
      const pendingContracts = response.data.vehicles.filter(
        vehicle => vehicle.totalContractPending > 0
      );

      if (pendingContracts.length > 0) {
        const ids = pendingContracts.map(vehicle => vehicle.vehicleId);
        snackbar.show(<ContractPendingMessage vehicleIds={ids} />, {
          type: 'warning',
          duration: 20000,
          showClose: true,
        });
      }
      setVehiclesData(response.data.vehicles);
      setSVGVehiclesData(response.data.locations);
    } catch (error) {
      snackbar.show(<Text>Erro ao recuperar veículos.</Text>, {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }

  async function getDownload() {
    setLoadingDownload(true);
    setLocation('');
    setIsTracked(null);
    setChecked(false);
    const initialDate = query.get('lastTravelDateBegin');
    const finalDate = query.get('lastTravelDateEnd');
    const { valid, message } = isDatePeriodValid(initialDate, finalDate);

    if (!valid) {
      snackbar.show(<Text>{message}</Text>, { type: 'error' });
      return;
    }

    try {
      const response = await apiReport.get(
        `/vehicles/location/report?${query}`,
        {
          responseType: 'blob',
        }
      );
      FileSaver.saveAs(response.data, response.headers['x-file-name']);
    } catch (error) {
      snackbar.show(<Text>Não foi possivel gerar o arquivo.</Text>, {
        type: 'error',
      });
    } finally {
      setLoadingDownload(false);
    }
  }

  async function retrieveUserFilterPreferences() {
    try {
      setLoading(true);
      const { data } = await cooperplaceApi.get(
        `/user/definitions?types[]=vehicle_tower_control_filters`
      );
      if (data.length) {
        const userFilters = JSON.parse(data[0].definition);
        const startQuery = setQueryParams(userFilters, query);
        history.replace({ search: startQuery.toString() });
        setFilters(userFilters);
      } else {
        query.set(
          'lastTravelDateBegin',
          startOfDay(subDays(new Date(), 30, new Date())).toISOString()
        );
        query.set(
          'lastTravelDateEnd',
          endOfDay(new Date(moment())).toISOString()
        );
        history.replace({ search: query.toString() });
        setFilters({
          lastTravelDateBegin: moment()
            .subtract(30, 'days')
            .format('DD/MM/YYYY'),
          lastTravelDateEnd: moment().format('DD/MM/YYYY'),
        });
      }
    } catch (error) {
      snackbar.show(
        <Text>Não foi possível recuperar os filtros do usuário.</Text>,
        {
          type: 'error',
        }
      );
    } finally {
      setLoading(false);
    }
  }

  async function getUserFilters() {
    await retrieveUserFilterPreferences();
    fetchData();
  }

  useEffect(() => {
    getUserFilters();
  }, []);

  return (
    <>
      <ApplicationLayoutVehicleControlTower
        title="Acompanhento de veículos"
        contentFluid
        isOld
        onlyCenterComponent
      >
        <Row md={12} style={{ paddingLeft: '50px' }} className="mb-2">
          <Filter
            filters={filters}
            setFilters={setFilters}
            toggleFilters={toggleFilters}
            setToggleFilters={setToggleFilters}
            loading={loading}
            setLoading={setLoading}
            fetchData={fetchData}
            loadingDownload={loadingDownload}
            getDownload={getDownload}
          />
        </Row>
        <Row style={{ paddingLeft: '50px' }} md={12} sm={12}>
          <Col md={6} lg={6} sm={12} xs={12}>
            <SVGMaps
              toggleSVGMaps={toggleSVGMaps}
              setToggleSVGMaps={setToggleSVGMaps}
              loading={loading}
              location={location}
              setLocation={setLocation}
              svgVehiclesData={svgVehiclesData}
              isTracked={isTracked}
              setIsTracked={setIsTracked}
              setChecked={setChecked}
            />
          </Col>
          <Col md={6} lg={6} sm={12} xs={12} className="mt-2">
            <MapClusterVehicleTower
              toggleMaps={toggleMaps}
              setToggleMaps={setToggleMaps}
              vehiclesData={filteredVehicles}
              loading={loading}
              checked={checked}
              setChecked={setChecked}
            />
          </Col>
        </Row>

        <div style={{ paddingLeft: '50px' }}>
          {loading ? (
            <DefaultLoadingComponent />
          ) : (
            <Grid vehicleData={filteredVehicles} />
          )}
        </div>
      </ApplicationLayoutVehicleControlTower>
    </>
  );
}
