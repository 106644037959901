export function handleEdit(route, setBelowMinimumFreightTablePrice, saveData){
    if (route?.data?.charge_type?.value === 3) {
      if (
        route?.data?.loading_charge <
          route?.minimumFreightTableItem?.charge_price ||
        route?.data?.discharge_price <
          route?.minimumFreightTableItem?.charge_price ||
        route?.data?.charge_price <
          Number(route?.data?.distance * route?.minimumFreightTableItem?.value)
      ) {
        setBelowMinimumFreightTablePrice(true);
      } else {
        setBelowMinimumFreightTablePrice(false);
        saveData();
      }
    } else if (route?.data?.charge_type?.value === 1) {
      if (
        route?.data?.loading_charge <
          route?.minimumFreightTableItem?.charge_price ||
        route?.data?.discharge_price <
          route?.minimumFreightTableItem?.charge_price ||
        Number(route?.data?.charge_price) <
          route?.minimumFreightTableItem?.value
      ) {
        setBelowMinimumFreightTablePrice(true);
      } else {
        setBelowMinimumFreightTablePrice(false);
        saveData();
      }
    } else {
      saveData();
    }
  }