import React, { useState, useRef } from 'react';

import CardRastreador from './CardRastreador';
import Grid from '@material-ui/core/Grid';

import Fab from 'components/Fab';

import Snackbar from 'components/Snackbar';
import Input from 'components/Input';
import Button from 'components/Button';
import Sidebar from 'components/Sidebar';
import InfoMessage from 'components/InfoMessage';
import InfiniteScroll from 'components/InfiniteScroll';

import api from 'services/api';

function Rastreadores() {
  const inputEl = useRef(null);
  const [filter, setFilter] = useState('');
  const [rastreadorName, setRastreadorName] = useState('');
  const [rastreadorId, setRastreadorId] = useState('');

  const [snackbar, setSnackbar] = useState({
    display: false,
    type: 'info',
    message: '',
  });

  function handleOpened(rastreador) {
    setRastreadorName(rastreador.name);
    setRastreadorId(rastreador.id);

    inputEl.current.toggle();
  }

  async function onSubmit(e) {
    e.preventDefault();
    let name = rastreadorName;
    let id = rastreadorId;

    try {
      if (!id) {
        let response = await api.post('trackers', {
          name,
        });

        if (response.status === 200) {
          if (response.data.error) {
            setSnackbar({
              display: true,
              type: 'error',
              message: response.data.error,
            });
          } else {
            setSnackbar({
              display: true,
              type: 'success',
              message: 'Rastreador ' + name + ' adicionado',
            });
            setFilter('');
            setFilter(' ');
          }
        } else {
        }
      } else {
        let response = await api.put('trackers/' + id, {
          name,
        });
        if (response.status === 200) {
          setSnackbar({
            display: true,
            type: 'success',
            message: 'Rastreador salvo',
          });
          setFilter('');
          setFilter(' ');
        }
      }
    } catch (e) {
      let responses = e.response.error;

      setSnackbar({
        display: true,
        type: 'error',
        message: responses,
      });

      return true;
    }
  }
  return (
    <>
      <Snackbar
        open={snackbar.display}
        type={snackbar.type}
        message={snackbar.message}
        onClose={() => setSnackbar(false)}
      />
      <Fab
        aria-label="Adicionar rastreador"
        style={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: '1',
          border: '2px solid rgb(33, 150, 243)',
          paddingRight: '20px',
          paddingLeft: '20px',
          color: 'rgb(255, 255, 255)',
          height: '36px',

          borderRadius: '18px',
        }}
        onClick={() => {
          setRastreadorName('');
          setRastreadorId(null);
          inputEl.current.toggle();
        }}
      >
        Adicionar rastreador
      </Fab>

      <InfiniteScroll
        endpoint="trackers"
        filter={filter}
        initialFetch
        placeholderHeight={145}
        placeholderWidth="50%"
        renderItem={(item, index) => (
          <CardRastreador
            handleOpened={handleOpened}
            key={index}
            rastreador={item}
          />
        )}
        endMessage={
          <Grid
            container
            item
            xs={12}
            justify="center"
            style={{ marginTop: '20px' }}
          >
            <InfoMessage
              icon="doneall"
              width="auto"
              snackColor="#8c8c8c"
              message="Não há mais itens a serem exibidos."
            />
          </Grid>
        }
      />
      <Sidebar
        ref={inputEl}
        closeButton={true}
        iconInit={false}
        title={rastreadorId ? 'Editar rastreador' : 'Novo rastreador'}
      >
        <form onSubmit={onSubmit}>
          <Input
            label="Nome do rastreador"
            value={rastreadorName}
            onChange={e => {
              setRastreadorName(e.target.value);
            }}
          />
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '15px',
            }}
          >
            <Button type="submit">
              {rastreadorId ? 'Salvar' : 'Adicionar'}
            </Button>
          </div>
        </form>
      </Sidebar>
    </>
  );
}

export default Rastreadores;
