import React from 'react';
import theme from 'v3/theme';
import { Card, Text } from 'v3/components';
import { useClientTravelDetail } from './context';
import { HeaderContainer } from './styles';

export function HeaderCard() {
  const { data, isFetching } = useClientTravelDetail();
  return (
    <Card loading={isFetching}>
      <HeaderContainer>
        <Text
          type="header"
          color={theme.colors.dark}
        >{`Viagem - ${data?.id}`}</Text>

        <Text
          type="header"
          color={theme.colors.dark}
        >{`CNPJ Cliente - ${data?.loads?.taker?.cpfCnpj}`}</Text>

        <Text
          type="header"
          color={theme.colors.dark}
        >{`Status - ${data?.travelStatus?.name}`}</Text>
      </HeaderContainer>
    </Card>
  );
}
