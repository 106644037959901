import React, { useState, useMemo } from 'react';
import { FaUnlink } from 'react-icons/fa';
import { Col, Row } from 'react-bootstrap';
import api from 'services/api';
import Card from 'v3/components/Card';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import Fallback from 'v3/components/Fallback';
import DataTable from 'v3/components/DataTable';
import theme from 'v3/theme';
import ClientsModal from './Modals/ClientsModal';
import { useUser } from './context';

export default function UserClients() {
  const user = useUser();
  const [search, setSearch] = useState('');
  const [perPage, setPerPage] = useState(5);
  const [page, setPage] = useState(1);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const clientsDataTable = useMemo(() => {
    const offset = (page - 1) * perPage;
    const clients = user.data?.clients
      .filter(
        item =>
          item.social_name.toLowerCase().includes(search.toLowerCase()) ||
          item.cgccpf.includes(search)
      )
      .slice(offset, perPage + offset);

    if (clients.length === 0 && page > 1) {
      setPage(page - 1);
    }
    return clients;
  }, [user.data?.clients, page, perPage, search]);

  function handleDeleteClient(clientId) {
    user.setData({
      clients: user.data?.clients.filter(client => client.id !== clientId),
    });
  }

  const isEditing = useMemo(() => {
    return !!user.data?.id;
  }, [user.data?.id]);

  async function handleDettachClient(clientId) {
    try {
      setLoading(true);
      await api.delete(`v3/user/${user.data.id}/clients/${clientId}`);

      user.setData({
        clients: user.data?.clients.filter(client => client.id !== clientId),
      });
    } catch (error) {
      user.showSnackbar('Erro ao excluir transportadora', { type: 'error' });
      // handle exeption
    } finally {
      setLoading(false);
    }
  }

  function handleRemoveClient(clientId) {
    if (isEditing) handleDettachClient(clientId);
    else handleDeleteClient(clientId);
  }

  return (
    <>
      <ClientsModal isOpen={show} onClose={() => setShow(false)} />
      <Card
        loading={user.isFetching}
        header={
          <Text type="header" color="dark">
            Clientes
          </Text>
        }
        HeaderRightComponent={
          <>
            {!user.data?.clients?.length || (
              <Button
                onClick={() => {
                  user.updatePrevUser();
                  setShow(true);
                }}
                disabled={!user.hasPermissionToEdit}
              >
                <Text type="regular" weight="500" color="white">
                  Adicionar
                </Text>
              </Button>
            )}
          </>
        }
      >
        <Row>
          <Fallback
            fall={!user.data?.clients?.length}
            component={
              <Col
                xs={12}
                className="align-items-center d-flex flex-column my-2"
              >
                <Text color={theme.colors.dark}>
                  Não há clientes cadastrados nesse usuário.
                </Text>
                <Button
                  className="mt-4"
                  onClick={() => {
                    user.updatePrevUser();
                    setShow(true);
                  }}
                  disabled={!user.hasPermissionToEdit}
                >
                  <Text type="regular" weight="500" color="white">
                    Adicionar
                  </Text>
                </Button>
              </Col>
            }
          >
            <Col>
              <DataTable
                data={clientsDataTable}
                total={user.data?.clients?.length}
                pages={Math.ceil(user.data?.clients?.length / perPage)}
                currentPage={page}
                onPageChanged={setPage}
                onSearchChanged={setSearch}
                onPerPageChanged={v => setPerPage(+v)}
                loading={loading}
                searchPlaceholder="Razão social / CNPJ"
                columns={[
                  { name: 'Clientes', selector: row => row.social_name },
                  {
                    name: 'CPF/CNPJ',
                    selector: row => row.cgccpf,
                  },
                  {
                    name: 'Ação',
                    render: row => (
                      <Button
                        variant="error"
                        size="sm"
                        className="mx-1"
                        onClick={() => handleRemoveClient(row.id)}
                        disabled={!user.hasPermissionToEdit}
                      >
                        <Text>
                          <FaUnlink />
                        </Text>
                      </Button>
                    ),
                  },
                ]}
              />
            </Col>
          </Fallback>
        </Row>
      </Card>
    </>
  );
}
