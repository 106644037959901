import React from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { Link as RouterLink } from 'react-router-dom';

import theme from 'v3/theme';

import Text from '../Text';

export default function Link({ children, to, withoutIcon, ...props }) {
  return (
    <RouterLink to={to} style={{ textDecoration: 'none' }} {...props}>
      <Text type="label" color="light_blue" decoration="none">
        {children}
      </Text>

      {!withoutIcon && (
        <FaExternalLinkAlt
          color={theme.colors.light_blue}
          className="ml-2"
          size={10}
        />
      )}
    </RouterLink>
  );
}
