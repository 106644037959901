import React from 'react';

import { useHistory, Link } from 'react-router-dom';

import { Row, Col } from 'react-bootstrap';

import ApplicationLayout from 'v3/layouts/Application';
import Space from 'v3/components/Space';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';

import Data from './Data';
import Settings from './Settings';
import Automations from './Automations';
import Addresses from '../shared/Addresses/index';
import PriorityFleet from '../shared/PriorityFleet/index';
import Checklist from './Checklist';
import Activities from './Activities';
import ButtonImportNegotiations from './ButtonImportNegotiations';
import BennerStatus from './BennerStatus/BennerStatus';
import Tracking from './Tracking';
import { usePermission } from 'hooks';
import { validatePermission } from 'actions';

export default function ClientDetails() {
  const hasPermissionToCreate = validatePermission('CADASTRAR_CLIENTES');
  const hasPermissionToImportNegotiations = validatePermission(
    'IMPORTAR_NEGOCIACOES_CLIENTE_BENNER'
  );
  usePermission('VISUALIZAR_CLIENTES', { redirect: true });
  const history = useHistory();

  return (
    <ApplicationLayout
      title="Cliente"
      RightComponent={
        <Space x="4px">
          <Button variant="secondary" onClick={() => history.goBack()}>
            <Text color="dark" weight={500} type="regular">
              Voltar
            </Text>
          </Button>
          {hasPermissionToCreate && (
            <Link to="cadastrar">
              <Button>
                <Text color="white" weight={500} type="regular">
                  Novo cliente
                </Text>
              </Button>
            </Link>
          )}
          {hasPermissionToImportNegotiations && <ButtonImportNegotiations />}
        </Space>
      }
    >
      <Row>
        <Col xs={12} md={6}>
          <Data />
          <Tracking />
        </Col>
        <Col xs={12} md={6}>
          <Settings />
          <Automations />
          <BennerStatus />
        </Col>
        <Col md={12}>
          <Addresses />
          <PriorityFleet />
        </Col>
        <Col xs={12} md={6}>
          <Checklist />
        </Col>
        <Col xs={12} md={6}>
          <Activities />
        </Col>
      </Row>
    </ApplicationLayout>
  );
}
