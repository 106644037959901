import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import useQuery from 'hooks/useQuery';

import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import Input from 'v3/components/Input';

function Filter({ onChange }) {
  const query = useQuery();
  const history = useHistory();

  const [filter, setFilter] = useState({});

  useEffect(() => {
    const timeout = setTimeout(() => {
      history.replace({ search: query.toString() });
    }, 150);

    return () => {
      clearTimeout(timeout);
    };
  }, [filter]);

  return (
    <Row className=" d-flex align-items-center mb-4">
      <Col>
        <Input
          label="Produto"
          labelColor="#fff"
          value={filter.search}
          placeholder="Digite o nome do produto"
          onChange={({ target }) => {
            if (target.value === null || target.value === '') {
              query.delete('search');
            } else {
              query.set('search', target.value);
            }
            setFilter(old => ({ ...old, search: target.value }));
          }}
        />
      </Col>

      <Col style={{ marginTop: '28px' }}>
        <Button
          variant="success"
          onClick={() => {
            onChange();
          }}
        >
          <Text type="regular" weight={500}>
            Filtrar
          </Text>
        </Button>
      </Col>
    </Row>
  );
}

export default Filter;
