import React from 'react';

import { Link } from 'react-router-dom';

import { Col } from 'react-bootstrap';

import CardRaw from 'v3/components/Card';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import Badge from 'v3/components/Badge';
import Tooltip from 'v3/components/Tooltip';
import { personTypesOptions } from '../../shared/utils';

function Card({ aggregation }) {
  let person = personTypesOptions.find(
    p => p.value === aggregation.person_type
  )?.label;
  if (person === 'ambos') {
    person = 'Pessoa Física ou Jurídica';
  }
  return (
    <Col md={6}>
      <CardRaw
        header={
          <div>
            <Tooltip
              content={
                <Text type="label">
                  {' '}
                  {aggregation.city.name} - {aggregation.province.name}
                </Text>
              }
              placement="top"
            >
              <Text transform="uppercase" color="dark" type="regular">
                {' '}
                {aggregation.city.name} - {aggregation.province.name}
              </Text>
            </Tooltip>
            <Badge pill className="ml-3 py-1 px-3" variant="secondary">
              <Text type="label" color="dark">
                {' '}
                {aggregation.quantity} vagas
              </Text>
            </Badge>
          </div>
        }
        HeaderRightComponent={
          <Link to={`/agregamentos/${aggregation.id}`}>
            <Button className="py-2">
              <Text color="white" type="regular" weight="500">
                Detalhes
              </Text>
            </Button>
          </Link>
        }
      >
        {aggregation?.company?.name && (
          <Col className="mb-2">
            <Text color="dark" type="regular" weight="400">
              {aggregation.company.name}
            </Text>
          </Col>
        )}
        <Col className="mb-2">
          <Text color="dark" type="regular" weight="400">
            {aggregation?.vehicleType?.name}
          </Text>
        </Col>
        <Col className="mb-2">
          <Text color="dark" type="regular" weight="400">
            {aggregation?.axisType?.name}
          </Text>
        </Col>
        <Col className="mb-2">
          <Text color="dark" type="regular" weight="400">
            Ano mínimo {aggregation?.minimum_age}
          </Text>
        </Col>
        {aggregation?.tracker?.name && (
          <Col className="mb-2">
            <Text color="dark" type="regular" weight="400">
              Rastreador {aggregation?.tracker?.name}
            </Text>
          </Col>
        )}
        <Col className="mb-2">
          <Text color="dark" type="regular" weight="400">
            {person}
          </Text>
        </Col>
        {aggregation?.observation && (
          <Col className="mb-2">
            <Text color="dark" type="regular" weight="400">
              Observações: {aggregation?.observation}
            </Text>
          </Col>
        )}
      </CardRaw>
    </Col>
  );
}

export default Card;
