import React from 'react';

import { number_format } from '../../../functions';

import moment from 'moment';

import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import Card from 'components/Card';

const CardExtrato = props => {
  function formatDate(date) {
    if (date === 'Invalid date') {
      return 'Não ocorreu';
    }
    return date;
  }

  return (
    <Card
      style={{ marginBottom: '10px' }}
      bordered
      borderhexcolor={props.status_color}
    >
      <Grid container>
        <Grid item xs={5}>
          <Typography variant="caption" component="p" gutterBottom>
            <strong>Origem: </strong>
          </Typography>
          <Typography variant="body2" gutterBottom>
            {props.travel.origins[0].formatted}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography variant="caption" component="p" gutterBottom>
            <strong>Destino: </strong>
          </Typography>
          <Typography variant="body2" gutterBottom>
            {
              props.travel.destinations[props.travel.destinations.length - 1]
                .formatted
            }
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="caption" component="p" gutterBottom>
            <strong>Valor</strong>
          </Typography>
          <Typography variant="body2" gutterBottom>
            {`R$ ${number_format(props.travel.balance, 2, ',', '.')}`}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography variant="caption" component="p" gutterBottom>
            <strong>Motorista: </strong>
          </Typography>
          <Typography variant="body2" gutterBottom>
            {props.travel.driver.social_name}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography variant="caption" component="p" gutterBottom>
            <strong>Tomador: </strong>
          </Typography>
          <Typography variant="body2" gutterBottom>
            {props.travel.load.client.social_name}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="caption" component="p" gutterBottom>
            <strong>Ordem de coleta: </strong>
          </Typography>
          <Typography variant="body2" gutterBottom>
            {formatDate(
              moment(props.travel.created_at).format('DD/MM/YYYY HH:mm')
            )}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography variant="caption" gutterBottom>
            <strong>Número da viagem: </strong>
          </Typography>
          <Typography variant="body2" gutterBottom>
            {props.travel.id}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography variant="caption" gutterBottom>
            <strong>Número da carga</strong>
          </Typography>
          <Typography variant="body2" gutterBottom>
            {props.travel.load_id}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

export default CardExtrato;
