import React, { useCallback, useContext, useEffect, useState } from 'react';
import moment from 'moment';
import api, { cooperplaceApi } from 'services/api';
import { Fallback, Modal, Text } from 'v3/components';
import { useSnackbar } from 'v3/components/Snackbar';
import { OccurrencesAditionalFieldsContext } from 'contexts/OccurrencesAditionalFields';
import { DefaultLoadingComponent, DefaultEmptyComponent } from '../List';
import { TravelOccurrencesList } from '../TravelOccurrencesList';
import { AddNewOccurrence } from './AddNewOccurrence';
import { EndOccurrence } from './EndOccurrence';
import { AddDescriptionToOccurrence } from './AddDescriptionToOccurrence';
import { ChangePersonResponsible } from './ChangePersonResponsible';
import { OccurrenceInfo } from './OccurrenceInfo';
import { ActionButtons } from './ActionButtons';
import { SectionContainer } from './styles';
import {
  validateNewOccurrence,
  validateUpdateOccurrence,
  validateChangeResponsible,
} from './utils';

export function OccurrencesModal({
  isOpen,
  onClose,
  travelId,
  bennerTravelId,
  fetchingTravelOccurrences,
  setFetchingTravelOccurrences,
  savingNewOccurrence,
  setSavingNewOccurrence,
  loadingUpdate,
  setLoadingUpdate,
}) {
  const { selectedOccurrence, setSelectedOccurrence } = useContext(
    OccurrencesAditionalFieldsContext
  );
  const [travelOccurrences, setTravelOccurrences] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [occurrencesOptions, setOccurrencesOptions] = useState([]);
  const [groupOptions, setGroupsOptions] = useState([]);
  const [occurrenceType, setOccurrenceType] = useState([]);
  const [responsible, setResponsible] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [description, setDescription] = useState('');
  const [occurrenceValue, setOccurrenceValue] = useState(null);
  const [files, setFiles] = useState([]);

  const [show, setShow] = useState({
    addNewOccurrence: false,
    addDescriptionToOccurrence: false,
    endOccurrence: false,
    changeResponsible: false,
  });
  const [pendency, setPendency] = useState(false);
  const [displayClient, setDisplayClient] = useState(false);

  const snackbar = useSnackbar();

  function handleModalClose() {
    setOccurrenceType();
    setResponsible();
    setStartDate('');
    setEndDate('');
    setPendency();
    setDisplayClient();
    setDescription('');
    setOccurrenceValue(null);
    setSelectedOccurrence({});
    setShow({
      addNewOccurrence: false,
      addDescriptionToOccurrence: false,
      endOccurrence: false,
      changeResponsible: false,
    });

    setErrors({});
    onClose();
  }

  async function getOccurrencesTypesOptions() {
    try {
      setLoading(true);
      const response = await cooperplaceApi.get('occurrences/types');
      setOccurrencesOptions(response.data.message);
    } catch (error) {
      setOccurrencesOptions([]);
    } finally {
      setLoading(false);
    }
  }
  async function getGroupOptions() {
    try {
      setLoading(true);
      const { data } = await api.get('groups', {
        params: {
          onlyActive: true,
        },
      });
      setGroupsOptions(data);
    } catch (error) {
      setGroupsOptions([]);
    } finally {
      setLoading(false);
    }
  }
  async function fetchTravelOccurrences() {
    try {
      setFetchingTravelOccurrences(true);
      const response = await cooperplaceApi.get(`occurrences/logs/${travelId}`);
      setTravelOccurrences(response.data.message);
      setFetchingTravelOccurrences(false);
    } catch (error) {
      setFetchingTravelOccurrences(false);
      setTravelOccurrences([]);
    }
  }

  function modalTitle() {
    if (travelId && bennerTravelId) {
      return `Ocorrências da Viagem: ${travelId} - ${bennerTravelId}`;
    }
    if (travelId && !bennerTravelId) {
      return `Ocorrências da Viagem: ${travelId}`;
    }
  }

  useEffect(() => {
    if (travelId) {
      fetchTravelOccurrences();
    }
  }, [travelId, isOpen]);

  useEffect(() => {
    if (isOpen) {
      getOccurrencesTypesOptions();
      getGroupOptions();
    }
  }, [isOpen]);

  function modalTitle() {
    if (travelId && bennerTravelId) {
      return `Ocorrências da Viagem: ${travelId} - ${bennerTravelId}`;
    }
    if (travelId && !bennerTravelId) {
      return `Ocorrências da Viagem: ${travelId}`;
    }
  }

  async function processAttachments(files) {
    const attachments = new FormData();
    files.forEach((file, index) => {
      if (file?.file?.length > 1) {
        file.file.forEach((f, idx) => {
          const fileName = f?.name
            .replaceAll('_', '-')
            .split('.')
            .slice(0, -1)
            .join('.');
          const isXLSXSheet =
            f?.type.split('.').pop() === 'sheet' ? 'xlsx' : null;
          const isXLSSheet =
            f?.type.split('.').pop() === 'ms-excel' ? 'xls' : null;
          let fileNameFormatted = null;
          if (isXLSXSheet) {
            fileNameFormatted = `${Date.now()}${idx}:${
              file.description
            }.${isXLSXSheet}`;
          } else if (isXLSSheet) {
            fileNameFormatted = `${Date.now()}${idx}:${
              file.description
            }.${isXLSSheet}`;
          } else
            fileNameFormatted = `${Date.now()}${idx}:${file.description}.${
              f?.type.split('/')[1]
            }`;
          const name = fileName
            ? `${fileName}-${fileNameFormatted}`
            : `${fileNameFormatted}`;
          const newFile = new File([f?.blob], name, {
            type: f?.type,
          });

          attachments.append('file', newFile);
        });
      } else {
        const fileName = file?.file?.[0]?.name
          .replaceAll('_', '-')
          .split('.')
          .slice(0, -1)
          .join('.');
        const isXLSXSheet =
          file?.file?.[0]?.type.split('.').pop() === 'sheet' ? 'xlsx' : null;
        const isXLSSheet =
          file?.file?.[0]?.type.split('.').pop() === 'ms-excel' ? 'xls' : null;
        let fileNameFormatted = null;
        if (isXLSXSheet) {
          fileNameFormatted = `${Date.now()}${index}:${
            file.description
          }.${isXLSXSheet}`;
        } else if (isXLSSheet) {
          fileNameFormatted = `${Date.now()}${index}:${
            file.description
          }.${isXLSSheet}`;
        } else
          fileNameFormatted = `${Date.now()}${index}:${file.description}.${
            file?.file?.[0]?.type.split('/')[1]
          }`;
        const name = fileName
          ? `${fileName}-${fileNameFormatted}`
          : `${fileNameFormatted}`;
        const newFile = new File([file?.file?.[0]?.blob], name, {
          type: file?.file?.[0]?.type,
        });

        attachments.append('file', newFile);
      }
    });
    return attachments;
  }

  const handleAddAttchmentsDirectly = useCallback(
    async attachments => {
      try {
        setLoadingUpdate(true);
        const newAttachments = await processAttachments(attachments);
        await cooperplaceApi.post(
          `occurrence-media/${selectedOccurrence.id}`,
          newAttachments
        );
        await fetchTravelOccurrences();
        const response = await cooperplaceApi.get(
          `occurrences/${selectedOccurrence.id}`
        );
        setSelectedOccurrence(response.data.message[0]);
      } catch (error) {
        snackbar.show(<Text>Não foi possível incluir o anexo</Text>, {
          type: 'error',
        });
        handleModalClose();
      } finally {
        setLoadingUpdate(false);
      }
    },
    [selectedOccurrence]
  );

  const handleSaveNewOccurrence = useCallback(async () => {
    try {
      setSavingNewOccurrence(true);
      const isDataValid = await validateNewOccurrence(
        occurrenceType,
        responsible,
        startDate,
        endDate,
        description,
        setErrors
      );
      if (isDataValid === false) {
        setSavingNewOccurrence(false);
        return false;
      }
      const shouldSendAttachments = files.length > 0;
      let occurrenceAttachments;
      if (shouldSendAttachments) {
        occurrenceAttachments = await processAttachments(files);
      }

      const { data } = await cooperplaceApi.post(`occurrences/${travelId}`, {
        startDate: moment(startDate, 'DD/MM/YYYY HH:mm:ss').toISOString(),
        endDate: endDate
          ? moment(endDate, 'DD/MM/YYYY HH:mm:ss').toISOString()
          : null,
        groupIds: responsible.map(group => group.id),
        pendency: pendency ?? false,
        displayClient: displayClient ?? false,
        description,
        typeId: occurrenceType.id,
        value: occurrenceValue,
      });

      if (shouldSendAttachments) {
        await cooperplaceApi.post(
          `occurrence-media/${data.occurrenceId}`,
          occurrenceAttachments
        );
      }

      await fetchTravelOccurrences();
      setOccurrenceType([]);
      setResponsible([]);
      setStartDate('');
      setEndDate('');
      setPendency();
      setDisplayClient();
      setDescription('');
      setOccurrenceValue(null);
      setFiles([]);
      setShow(old => ({ ...old, addNewOccurrence: false }));
      setSavingNewOccurrence(false);
    } catch (error) {
      const message = error?.response?.data?.message;
      setOccurrenceType([]);
      setResponsible([]);
      setStartDate('');
      setEndDate('');
      setPendency();
      setDisplayClient();
      setDescription('');
      setFiles([]);
      setOccurrenceValue(null);
      setShow(old => ({ ...old, addNewOccurrence: false }));
      setSavingNewOccurrence(false);
      snackbar.show(
        <Text>{message || 'Não foi possível salvar a ocorrência'}</Text>,
        {
          type: 'error',
        }
      );
      handleModalClose();
    }
  }, [
    responsible,
    startDate,
    endDate,
    pendency,
    displayClient,
    occurrenceType,
    description,
    occurrenceValue,
    errors,
    files,
  ]);

  const handleAddEndOccurrenceInfo = useCallback(async () => {
    try {
      setLoadingUpdate(true);
      const isDataValid = await validateUpdateOccurrence({
        description,
        startDate,
        endDate,
        setErrors,
      });
      if (isDataValid === false) {
        setLoadingUpdate(false);
        return false;
      }
      const shouldSendAttachments = files.length > 0;
      let occurrenceAttachments;
      if (shouldSendAttachments) {
        occurrenceAttachments = await processAttachments(files);
      }
      await cooperplaceApi.put(`occurrences/${selectedOccurrence.id}`, {
        startDate: moment(startDate, 'DD/MM/YYYY HH:mm:ss').toISOString(),
        endDate: endDate
          ? moment(endDate, 'DD/MM/YYYY HH:mm:ss').toISOString()
          : null,
        description,
        value: occurrenceValue,
        logType: 'concluir',
      });
      if (shouldSendAttachments) {
        await cooperplaceApi.post(
          `occurrence-media/${selectedOccurrence.id}`,
          occurrenceAttachments
        );
      }
      setStartDate('');
      setEndDate('');
      setDescription('');
      setOccurrenceValue(null);
      setFiles([]);
      setShow(old => ({ ...old, endOccurrence: false }));
      await fetchTravelOccurrences();
      const response = await cooperplaceApi.get(
        `occurrences/${selectedOccurrence.id}`
      );
      setSelectedOccurrence(response.data.message[0]);
      setLoadingUpdate(false);
    } catch (error) {
      const message = error?.response?.data?.message;
      setStartDate('');
      setEndDate('');
      setDescription('');
      setOccurrenceValue(null);
      setFiles([]);
      setShow(old => ({ ...old, endOccurrence: false }));
      setLoadingUpdate(false);
      snackbar.show(
        <Text>{message || 'Não foi possível concluir a ocorrência'}</Text>,
        {
          type: 'error',
        }
      );
      handleModalClose();
    }
  }, [
    description,
    startDate,
    endDate,
    occurrenceValue,
    selectedOccurrence,
    files,
  ]);

  const handleChangeResponsibleData = useCallback(async () => {
    try {
      setLoadingUpdate(true);
      const isDataValid = await validateChangeResponsible({
        description,
        responsible,
        setErrors,
      });
      if (isDataValid === false) {
        setLoadingUpdate(false);
        return false;
      }
      await cooperplaceApi.put(`occurrences/${selectedOccurrence.id}`, {
        groupIds: responsible.map(group => group.id),
        description,
        logType: 'alterar',
      });
      const response = await cooperplaceApi.get(
        `occurrences/${selectedOccurrence.id}`
      );
      setSelectedOccurrence(response.data.message[0]);
      setResponsible([]);
      setDescription('');
      setShow(old => ({ ...old, changeResponsible: false }));
      await fetchTravelOccurrences();
      setLoadingUpdate(false);
    } catch (error) {
      setResponsible([]);
      setDescription('');
      setShow(old => ({ ...old, changeResponsible: false }));
      setLoadingUpdate(false);
      snackbar.show(<Text>Não foi possível alterar o responsável</Text>, {
        type: 'error',
      });
    }
  }, [description, responsible, selectedOccurrence]);

  const handleAddNewDescription = useCallback(async () => {
    try {
      setLoadingUpdate(true);
      const isDataValid = await validateUpdateOccurrence({
        description,
        startDate,
        endDate,
        setErrors,
      });
      if (isDataValid === false) {
        setLoadingUpdate(false);
        return false;
      }
      const shouldSendAttachments = files.length > 0;
      let occurrenceAttachments;
      if (shouldSendAttachments) {
        occurrenceAttachments = await processAttachments(files);
      }
      await cooperplaceApi.put(`occurrences/${selectedOccurrence.id}`, {
        startDate: moment(startDate, 'DD/MM/YYYY HH:mm:ss').toISOString(),
        endDate: endDate
          ? moment(endDate, 'DD/MM/YYYY HH:mm:ss').toISOString()
          : null,
        description,
        value: occurrenceValue,
        logType: 'alterar',
      });
      if (shouldSendAttachments) {
        await cooperplaceApi.post(
          `occurrence-media/${selectedOccurrence.id}`,
          occurrenceAttachments
        );
      }
      const response = await cooperplaceApi.get(
        `occurrences/${selectedOccurrence.id}`
      );
      setSelectedOccurrence(response.data.message[0]);
      setStartDate('');
      setEndDate('');
      setDescription('');
      setOccurrenceValue(null);
      setFiles([]);
      setShow(old => ({ ...old, addDescriptionToOccurrence: false }));
      await fetchTravelOccurrences();
      setLoadingUpdate(false);
    } catch (error) {
      const message = error?.response?.data?.message;
      setStartDate('');
      setEndDate('');
      setDescription('');
      setOccurrenceValue(null);
      setFiles([]);
      setShow(old => ({ ...old, addDescriptionToOccurrence: false }));
      setLoadingUpdate(false);
      snackbar.show(
        <Text>{message || 'Não foi possível adicionar descrição'}</Text>,
        {
          type: 'error',
        }
      );
      handleModalClose();
    }
  }, [
    description,
    startDate,
    endDate,
    occurrenceValue,
    selectedOccurrence,
    files,
  ]);

  return (
    <Modal
      show={isOpen}
      handleClose={handleModalClose}
      heading={
        <Text type="regular" color="dark" weight="500">
          {modalTitle()}
        </Text>
      }
      body={
        <>
          {show.addNewOccurrence ? (
            <AddNewOccurrence
              endDate={endDate}
              startDate={startDate}
              occurrenceType={occurrenceType}
              setOccurrenceType={setOccurrenceType}
              occurrencesOptions={occurrencesOptions}
              responsible={responsible}
              setResponsible={setResponsible}
              setEndDate={setEndDate}
              setStartDate={setStartDate}
              description={description}
              setDescription={setDescription}
              occurrenceValue={occurrenceValue}
              setOccurrenceValue={setOccurrenceValue}
              setShow={setShow}
              handleSaveNewOccurrence={handleSaveNewOccurrence}
              savingNewOccurrence={savingNewOccurrence}
              pendency={pendency}
              setPendency={setPendency}
              displayClient={displayClient}
              setDisplayClient={setDisplayClient}
              errors={errors}
              setErrors={setErrors}
              addButton
              files={files}
              setFiles={setFiles}
              groupOptions={groupOptions}
            />
          ) : (
            <>
              {!show.addNewOccurrence &&
              !show.addDescriptionToOccurrence &&
              !show.endOccurrence &&
              !show.changeResponsible ? (
                <ActionButtons
                  show={show}
                  setShow={setShow}
                  selectedOccurrence={selectedOccurrence}
                  setSelectedOccurrence={setSelectedOccurrence}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  loading={loading}
                  occurrenceValue={occurrenceValue}
                  setOccurrenceValue={setOccurrenceValue}
                />
              ) : null}
              <Fallback
                fall={
                  fetchingTravelOccurrences || travelOccurrences.length === 0
                }
                component={
                  fetchingTravelOccurrences ? (
                    <DefaultLoadingComponent />
                  ) : !fetchingTravelOccurrences &&
                    travelOccurrences.length === 0 ? (
                    <DefaultEmptyComponent />
                  ) : null
                }
              >
                <SectionContainer>
                  <TravelOccurrencesList
                    selectedOccurrence={selectedOccurrence}
                    setSelectedOccurrence={setSelectedOccurrence}
                    occurrences={travelOccurrences}
                    show={show}
                  />
                  {selectedOccurrence?.id && (
                    <>
                      <OccurrenceInfo
                        selectedOccurrence={selectedOccurrence}
                        show={show}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        endDate={endDate}
                        errors={errors}
                        loadingUpdate={loadingUpdate}
                        savingNewOccurrence={savingNewOccurrence}
                        setOccurrenceValue={setOccurrenceValue}
                        occurrenceValue={occurrenceValue}
                        handleAddAttchmentsDirectly={
                          handleAddAttchmentsDirectly
                        }
                      />
                    </>
                  )}
                  {show.endOccurrence ? (
                    <EndOccurrence
                      description={description}
                      setDescription={setDescription}
                      setShow={setShow}
                      errors={errors}
                      setErrors={setErrors}
                      handleAddEndOccurrenceInfo={handleAddEndOccurrenceInfo}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                      loadingUpdate={loadingUpdate}
                      files={files}
                      setFiles={setFiles}
                    />
                  ) : show.addDescriptionToOccurrence ? (
                    <AddDescriptionToOccurrence
                      description={description}
                      setDescription={setDescription}
                      setShow={setShow}
                      errors={errors}
                      setErrors={setErrors}
                      startDate={startDate}
                      setStartDate={setStartDate}
                      endDate={endDate}
                      setEndDate={setEndDate}
                      handleAddNewDescription={handleAddNewDescription}
                      loadingUpdate={loadingUpdate}
                      files={files}
                      setFiles={setFiles}
                    />
                  ) : show.changeResponsible ? (
                    <ChangePersonResponsible
                      responsible={responsible}
                      setResponsible={setResponsible}
                      description={description}
                      setDescription={setDescription}
                      setShow={setShow}
                      errors={errors}
                      setErrors={setErrors}
                      handleChangeResponsibleData={handleChangeResponsibleData}
                      loadingUpdate={loadingUpdate}
                      groupOptions={groupOptions}
                    />
                  ) : null}
                </SectionContainer>
              </Fallback>
            </>
          )}
        </>
      }
    />
  );
}
