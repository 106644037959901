import React from 'react';

import { Link } from 'react-router-dom';

import { Col } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa';

import CardRaw from 'v3/components/Card';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import Badge from 'v3/components/Badge';
import Tooltip from 'v3/components/Tooltip';
import theme from 'v3/theme';
import { BadgeActive } from 'v3/components';

function Card({ user }) {
  return (
    <Col xs={12} md={6}>
      <CardRaw
        header={
          <div>
            <Tooltip
              content={
                <Text type="label">
                  {user?.username}
                  {user.verified && ', verificado'}
                </Text>
              }
              placement="top"
            >
              <Text transform="uppercase" color="dark" type="regular">
                {user?.username.substring(0,20)}
                {user?.username.length > 20 && '...'}
              </Text>
            </Tooltip>
            {user.verified && (
              <Tooltip
                placement="top"
                content={<Text>Usuário verificado</Text>}
              >
                <FaCheckCircle
                  className="ml-1"
                  style={{ marginBottom: 12 }}
                  color={theme.colors.light_blue}
                  size={13}
                />
              </Tooltip>
            )}
            <Badge pill className="ml-3 py-1 px-3" variant="secondary">
              <Text type="label" color="dark">
                {user.profile.name}
              </Text>
            </Badge>
            {user.active ? (
              <BadgeActive pill light type="success" className="ml-1">
                <Text type="label">Ativo</Text>
              </BadgeActive>
            ) : (
              <BadgeActive pill light type="error" className="ml-1">
                <Text type="label">Inativo</Text>
              </BadgeActive>
            )}
          </div>
        }
        HeaderRightComponent={
          <Link to={`/usuarios/${user.id}`}>
            <Button className="py-2">
              <Text color="white" type="regular" weight="500">
                Detalhes
              </Text>
            </Button>
          </Link>
        }
      >
        {user.profile.name !== 'Motorista' && (
          <Text color="dark" type="regular">
            {user?.email}
          </Text>
        )}
        {user.profile.name === 'Motorista' && (
          <Text color="dark" type="regular">
            {user?.phonenumber} <pr className="ml-1 mr-1">|</pr> {user?.cpf}
          </Text>
        )}
      </CardRaw>
    </Col>
  );
}

export default Card;
