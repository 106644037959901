import React, { useState, useEffect } from 'react';

import { Row, Col } from 'react-bootstrap';
import { PHONE_MASK, CPF_MASK, CNPJ_MASK } from 'utils/masks';
import api from 'services/api';
import { useValidation } from 'hooks';
import Radio from 'v3/components/Radio';
import { useSnackbar } from 'v3/components/Snackbar';
import Modal from 'v3/components/Modal';
import Button from 'v3/components/Button';
import Input from 'v3/components/Input';
import Text from 'v3/components/Text';
import { TravelContext } from '../../controller';

import { travelShippingCompanyDetailsSchema } from '../../../../ShippingCompany/shared/validator';

const [, useTravel] = TravelContext;

function ShippingCompanyDetails({
  isOpen,
  onClose,
  shippingCompany,
  setShippingCompany,
}) {
  const travel = useTravel();

  const [loading, setLoading] = useState(false);
  const snackbar = useSnackbar();
  const [errors, setErrors] = useState({});

  const [validationErrors, { validate }] = useValidation(
    travelShippingCompanyDetailsSchema
  );

  useEffect(() => {
    setErrors(validationErrors);
  }, [validationErrors]);

  async function verifySchema() {
    try {
      await travelShippingCompanyDetailsSchema.validate(
        {
          type_id: shippingCompany.personType?.name === 'Jurídica' ? 3 : 2,
          cgccpf: shippingCompany.cgccpf,
          cgccpf_unmasked: shippingCompany?.cgccpf?.replace(/[^0-9]+/g, ''),
          social_name: shippingCompany.social_name,
          phone: shippingCompany.phone,
          phone_unmasked: shippingCompany?.phone?.replace(/[^0-9]+/g, ''),
        },
        { abortEarly: false }
      );
    } catch (error) {
      const errorResponse = {};

      if (error.inner) {
        error.inner.forEach(error => {
          errorResponse[error.path] = error.message;
        });
        setErrors(errorResponse);
      }
    }
  }

  useEffect(() => {
    if (shippingCompany) {
      verifySchema();
    }
  }, [shippingCompany]);

  async function saveData() {
    const data = {
      type_id: shippingCompany.personType?.name === 'Jurídica' ? 3 : 2,
      cgccpf: shippingCompany.cgccpf,
      social_name: shippingCompany.social_name,
      phone: shippingCompany.phone,
      phone_unmasked: shippingCompany.phone.replace(/[^0-9]+/g, ''),
    };

    const [isValid] = await validate({
      cgccpf_unmasked: shippingCompany.cgccpf.replace(/[^0-9]+/g, ''),
      ...data,
    });

    if (!isValid) {
      snackbar.show(<Text>Verifique os campos obrigatórios</Text>, {
        type: 'error',
      });
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      await api.put(
        `v3/shipping-company/${shippingCompany.id}/data/create-travel`,
        {
          data,
        }
      );
      travel.setData(old => ({ ...old, shippingCompany }));
      snackbar.show(<Text>Salvo com sucesso</Text>, { type: 'success' });
      onClose();
    } catch (error) {
      snackbar.show(<Text>Erro ao editar transportadora</Text>, {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Modal
        size="lg"
        show={isOpen}
        onHide={onClose}
        heading={
          <Text type="header" color="dark" weight="500">
            Editar transportadora
          </Text>
        }
        body={
          <Row>
            <Col md={6} xs={12} className="mb-3">
              <Radio.Group
                label={
                  <Text color="dark" type="label">
                    Tipo de pessoa
                  </Text>
                }
                onChange={event => {
                  setShippingCompany({
                    ...shippingCompany,
                    personType: {
                      name: event.target.value,
                    },
                  });
                }}
                value={shippingCompany?.personType?.name}
                horizontal
              >
                <Radio value="Jurídica">
                  <Text color="dark" type="label">
                    Jurídica
                  </Text>
                </Radio>
                <Radio value="Física">
                  <Text color="dark" type="label">
                    Física
                  </Text>
                </Radio>
              </Radio.Group>
            </Col>
            <Col xs={12} className="mb-3">
              <Input
                label={
                  shippingCompany?.personType?.name === 'Jurídica'
                    ? 'Razão social'
                    : 'Nome'
                }
                value={shippingCompany?.social_name}
                onChange={event => {
                  setShippingCompany({
                    ...shippingCompany,
                    social_name: event?.target?.value,
                  });
                }}
                error={errors.social_name}
              />
            </Col>
            <Col md={6} xs={12} className="mb-3">
              <Input
                label={
                  shippingCompany?.personType?.name === 'Jurídica'
                    ? 'CNPJ'
                    : 'CPF'
                }
                value={shippingCompany?.cgccpf}
                onChange={event => {
                  setShippingCompany({
                    ...shippingCompany,
                    cgccpf: event?.target?.value,
                  });
                }}
                masked
                mask={
                  shippingCompany?.personType?.name === 'Jurídica'
                    ? CNPJ_MASK
                    : CPF_MASK
                }
                error={errors.cgccpf_unmasked}
              />
            </Col>
            <Col md={6} xs={12}>
              <Input
                label="Telefone"
                value={shippingCompany?.phone}
                onChange={event => {
                  setShippingCompany({
                    ...shippingCompany,
                    phone: event?.target?.value,
                  });
                }}
                masked
                mask={PHONE_MASK}
                error={errors.phone_unmasked}
              />
            </Col>
            <Col className="text-center my-3">
              <Button
                className="py-2"
                disabled={
                  !shippingCompany?.social_name ||
                  !shippingCompany?.phone ||
                  !shippingCompany?.cgccpf ||
                  !shippingCompany?.personType
                }
                onClick={() => saveData()}
                loading={loading}
              >
                <Text type="regular" weight={500}>
                  Salvar
                </Text>
              </Button>
            </Col>
          </Row>
        }
      />
    </>
  );
}

export default ShippingCompanyDetails;
