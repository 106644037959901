import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useQuery from 'hooks/useQuery';
import moment from 'moment';

import { Row, Col } from 'react-bootstrap';
import Select from 'v3/components/Select';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import DatePicker from 'v3/components/DatePicker';

import api from 'services/api';

export default function Filter({ onChange }) {
  const query = useQuery();
  const history = useHistory();

  const [provinces, setProvinces] = useState();

  const [filters, setFilters] = useState({});
  const [selectedProvince, setSelectedProvince] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  async function getProvinces() {
    const response = await api.get('provinces');
    const provinceData = response.data.map(item => ({
      ...item,
      name: item.name,
      id: item.id,
    }));
    setProvinces(provinceData);
  }

  useEffect(() => {
    getProvinces();
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      history.replace({ search: query.toString() });
    }, 150);

    return () => {
      clearTimeout(timeout);
    };
  }, [filters]);

  return (
    <Row className=" d-flex align-items-center mb-4">
      <Col>
        <Select
          options={provinces}
          placeholder="Selecione um estado"
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          value={selectedProvince}
          onChange={value => {
            if (value === null) {
              query.delete('province');
            } else {
              query.set('province', value.id);
            }
            setFilters(old => ({ ...old, province: value?.id }));
            setSelectedProvince(value);
          }}
        />
      </Col>
      <Col>
        <DatePicker
          placeholder="Período de"
          value={startDate}
          format="DD/MM/YYYY"
          onChange={event => {
            if (event.target.value === null || event.target.value == '') {
              query.delete('first_date');
            } else if (moment(event.target.value, 'DD/MM/YYYY').isValid()) {
              query.set(
                'first_date',
                moment(event.target.value, 'DD/MM/YYYY').format('YYYY-MM-DD')
              );
            }
            setFilters(old => ({
              ...old,
              first_date: event.target.value,
            }));
            setStartDate(event.target.value);
          }}
        />
      </Col>
      <Col>
        <DatePicker
          value={endDate}
          onChange={event => {
            if (event.target.value === null || event.target.value == '') {
              query.delete('end_date');
            } else if (moment(event.target.value, 'DD/MM/YYYY').isValid()) {
              query.set(
                'end_date',
                moment(event.target.value, 'DD/MM/YYYY').format('YYYY-MM-DD')
              );
            }
            setFilters(old => ({ ...old, end_date: event.target.value }));
            setEndDate(event.target.value);
          }}
          placeholder="até"
        />
      </Col>

      <Col>
        <Button
          variant="success"
          onClick={() => {
            onChange();
          }}
        >
          <Text type="regular" weight={500}>
            Filtrar
          </Text>
        </Button>
      </Col>
    </Row>
  );
}
