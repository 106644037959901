/* eslint-disable no-unused-expressions */
import React, { useState, useRef, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import api from 'services/api';
import theme from 'v3/theme';

import Input from 'v3/components/Input';
import Text from 'v3/components/Text';
import Modal from 'v3/components/Modal';

import { useSnackbar } from 'v3/components/Snackbar';

import { useClient } from '../context';

export default function Checklist({ isOpen, onClose, handleChecklistStatus }) {
  const inputRef = useRef(null);
  const params = useParams();
  const snackbar = useSnackbar();

  const client = useClient();

  const [open, setOpen] = useState(false);
  const [item, setItem] = useState('');
  async function handleSave(e) {
    e.preventDefault(e);

    if (!item) {
      snackbar.show(<Text>Preencha o campo!</Text>, { type: 'error' });
      return;
    }

    if (client.data?.companyPerson?.checklists?.some(c => c.name === item)) {
      snackbar.show(<Text>Item já existe nesse checklist!</Text>, {
        type: 'error',
      });
      return;
    }

    try {
      if (params.id) {
        const response = await api.post(`v3/client/${params.id}/checklist`, {
          checklist: item,
        });

        client.setData({
          companyPerson: {
            checklists: client.data.companyPerson?.checklists
              ? [
                  ...client.data.companyPerson?.checklists,
                  {
                    name: response.data.name,
                    id: response.data.id,
                    client_id: response.data.id.client_id,
                  },
                ]
              : [{ name: item }],
          },
        });
      } else {
        client.setData({
          companyPerson: {
            checklists: client.data.companyPerson?.checklists
              ? [...client.data.companyPerson?.checklists, { name: item }]
              : [{ name: item }],
          },
        });
      }

      setItem('');
      inputRef.current.focus();

      handleChecklistStatus(true, false);

      snackbar.show(<Text>Checklist adicionado com sucesso!</Text>, {
        type: 'success',
      });
    } catch (error) {
      snackbar.show(<Text>Algo deu errado, tente novamente!</Text>, {
        type: 'error',
      });
    }
  }
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (open) {
      inputRef.current.focus();
    }
  }, [open]);

  return (
    <>
      <Modal
        show={isOpen || open}
        handleClose={onClose || handleClose}
        heading={
          <Text color="dark" type="header">
            Checklist
          </Text>
        }
        centered
        body={
          <Row>
            <Col md={12} className="mt-2 mb-4">
              <form onSubmit={e => handleSave(e)}>
                <Input
                  ref={inputRef}
                  label="Item do checklist"
                  rightIcon={<FaPlus color="#fff" />}
                  rightIconColor={theme.colors.primary}
                  rightIconAction={e => handleSave(e)}
                  value={item}
                  onChange={({ target }) => setItem(target.value)}
                />
              </form>
            </Col>
          </Row>
        }
      />
    </>
  );
}
