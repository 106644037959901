import { useEffect, useState } from 'react';

export default function useMemoThrottled(fn, deps, initialValue, DELAY = 800) {
  const [memoized, setMemoized] = useState(initialValue);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setMemoized(fn());
    }, DELAY);

    return () => {
      clearTimeout(timeout);
    };
  }, deps);

  return memoized;
}
