import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Button, Card, Fallback, Text } from 'v3/components';
import theme from 'v3/theme';
import Space from 'v3/components/Space';
import TextArea from 'v3/components/TextArea';
import { cooperplaceApi } from 'services/api';
import { useClientTravelDetail } from './context';
import { useSnackbar } from 'v3/components/Snackbar';
import {
  FeedbackItemContainer,
  FeedbackMarker,
  FeedbackTray,
  FeedbacksContainer,
} from './styles';
import { capitalizeFullname, formatTime } from 'v3/utils/formatter';
import {
  DefaultEmptyComponent,
  DefaultLoadingComponent,
} from 'v3/components/List';
import { FeedbackSchema } from './validator';

export function FeedbackCard() {
  const snackbar = useSnackbar();
  const [newFeedback, setNewFeedback] = useState('');
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const { data, isFetching, refetchTravel, feedbacks } =
    useClientTravelDetail();
  const [error, setError] = useState([]);

  async function validateData(data, setError) {
    const errorList = {};
    const dataObject = {
      newFeedback: data,
    };
    try {
      await FeedbackSchema.validate(dataObject, {
        abortEarly: false,
      });
      setError({ ...errorList });

      return true;
    } catch (err) {
      err.inner.forEach(error => {
        errorList[error.path] = error.message;
      });

      setError({ ...errorList });

      return false;
    }
  }

  async function submitFeedback() {
    try {
      setLoading(true);
      const isValid = await validateData(newFeedback, setError);
      if (!isValid) {
        snackbar.show(
          <Text type="regular">{'Informe um feedback válido'}</Text>,
          {
            type: 'error',
          }
        );
        return;
      }
      const response = await cooperplaceApi.post('clients/travels/feedback', {
        attendedId: data?.id,
        feedback: newFeedback,
      });
      snackbar.show(<Text type="regular">{response?.data?.message}</Text>, {
        type: response?.data?.success ? 'success' : 'warning',
      });
      await refetchTravel();
      setEditing(false);
      setNewFeedback('');
    } catch (error) {
      snackbar.show(
        <Text type="regular">{error?.response?.data?.message}</Text>,
        {
          type: 'error',
        }
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <Card
      header={
        <Text type="header" color={theme.colors.dark}>
          Feedback do Cliente
        </Text>
      }
      loading={loading || isFetching}
      HeaderRightComponent={
        editing ? (
          <Space x={'0.5rem'}>
            <Button
              variant={'secondary'}
              onClick={() => {
                setEditing(false);
                setNewFeedback('');
                setError({});
              }}
              disabled={loading || isFetching}
            >
              <Text type="regular">Cancelar</Text>
            </Button>
            <Button
              onClick={submitFeedback}
              loading={loading}
              disabled={loading || isFetching}
            >
              <Text type="regular">Salvar</Text>
            </Button>
          </Space>
        ) : (
          <Button variant={'secondary'} onClick={() => setEditing(true)}>
            <Text type="regular" weight={500}>
              Adicionar
            </Text>
          </Button>
        )
      }
    >
      <Row>
        <Col xs={12}>
          {editing && (
            <TextArea
              label="Feedback"
              value={newFeedback}
              onChange={e => setNewFeedback(e.target.value)}
              error={error?.newFeedback}
            />
          )}
          <Fallback
            fall={Object.keys(feedbacks)?.length === 0 || isFetching || loading}
            component={
              loading || isFetching ? (
                <DefaultLoadingComponent></DefaultLoadingComponent>
              ) : (
                <DefaultEmptyComponent />
              )
            }
          >
            <FeedbacksContainer>
              {Object.entries(feedbacks).map(([date, item]) => (
                <FeedbackItemContainer>
                  <div className="mb-1">
                    <Text weight={500} color="dark">
                      {date}
                    </Text>
                  </div>
                  <FeedbackMarker style={{ marginBottom: 5 }} />

                  <FeedbackTray>
                    {item.map(feedItem => (
                      <div className="mt-1 pl-4">
                        <div className="mb-2">
                          <Text weight={500} color="dark">
                            {formatTime(feedItem.createdAt)}
                          </Text>
                          {' - '}
                          <Text type="label" color="gray">
                            Criado por{' '}
                            {capitalizeFullname(feedItem.user?.username)}
                          </Text>
                        </div>
                        <Text
                          as="div"
                          type="regular"
                          color="gray"
                          className="mt-1"
                          wordwrap
                          style={{ textAlign: 'justify' }}
                        >
                          {feedItem?.feedback}
                        </Text>

                        <hr />
                      </div>
                    ))}
                  </FeedbackTray>
                  <div className="mb-3">
                    <FeedbackMarker isLast />
                  </div>
                </FeedbackItemContainer>
              ))}
            </FeedbacksContainer>
          </Fallback>
        </Col>
      </Row>
    </Card>
  );
}
