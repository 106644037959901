import React, { useMemo, useState } from 'react';

import Input from '..';
import { toCurrency } from './helpers';

export default function CurrencyInput({
  onChange,
  separator,
  decimalDigits = 2,
  value: propValue,
  ...props
}) {
  
  const [value, setValue] = useState('');

  const computedValue = useMemo(() => propValue || value, [propValue, value]);

  function handleChange(event) {
    const valueAsCurrency = toCurrency(
      event.target.value,
      separator,
      decimalDigits
    );
    setValue(valueAsCurrency);

    if (onChange) {
      event.persist();
      onChange(event, valueAsCurrency);
    }
  }

  return <Input {...props} onChange={handleChange} value={computedValue} />;
}
