import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Row, Col } from 'react-bootstrap';

import api, { cooperplaceApi } from 'services/api';

import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import TextArea from 'v3/components/TextArea';
import Select from 'v3/components/Select';
import { useSnackbar } from 'v3/components/Snackbar';

function Add({ fetchTravel, loadCostCenter, loading, setLoading }) {
  const snackbar = useSnackbar();
  const params = useParams();

  const [errors, setErrors] = useState();

  const [type, setType] = useState();
  const [types, setTypes] = useState();

  const [event, setEvent] = useState();
  const [events, setEvents] = useState();
  const [allEvents, setAllEvents] = useState();

  const [description, setDescription] = useState();
  const mercosulCostCentersExternalIds = [881, 882, 883];
  const isLoadMercosulCostCenter = mercosulCostCentersExternalIds.includes(
    Number(loadCostCenter)
  );
  const isAddingGRApprovalFeed = event?.identifier === 'GR_APPROVAL';

  function validate() {
    const errors = { hasErrors: false };
    try {
      if (!type) {
        errors.type = 'Informe o tipo';
        errors.hasErrors = true;
      }

      if (!event) {
        errors.event = 'Informe o evento';
        errors.hasErrors = true;
      }
    } catch (ex) {}

    return errors;
  }

  useEffect(() => {
    async function fetchEvents() {
      try {
        const response = await api.get(`travel/events`);

        setEvents(response.data);
        setAllEvents(response.data);
      } catch (error) {
        //
      }
    }

    async function fetchTypes() {
      try {
        const response = await api.get(`travel-event-types`);

        setTypes(response.data);
      } catch (error) {
        //
      }
    }

    fetchTypes();
    fetchEvents();
  }, []);

  useEffect(() => {
    async function fetchEvents() {
      if (allEvents && type) {
        const listEvents = allEvents.filter(event => {
          return event.category_id === type.id;
        });
        setEvents(listEvents);
      }
      setEvent(null);
    }
    fetchEvents();
  }, [type]);

  async function handleSubmit() {
    setLoading(true);
    const err = validate();
    setErrors(err);

    if (err.hasErrors) {
      setLoading(false);
      return;
    }

    try {
      let obs = event.name;
      if (description) {
        obs += ` - ${description}`;
      }

      const log = {
        event_type_id: type.id,
        automatic: false,
        attended_id: params.id,
        obs,
        identifier: event.identifier,
        event_id: event.id,
      };

      await api.post(`travel-logs/${params.id}`, log);
      if (isLoadMercosulCostCenter && isAddingGRApprovalFeed) {
        await cooperplaceApi.post(`/travels/latam`, {
          attendedId: params.id,
        });
      }

      snackbar.show(<Text>Evento adicionado ao feed.</Text>, {
        type: 'success',
      });
    } catch (error) {
      snackbar.show(<Text>Erro ao adicionar evento ao feed.</Text>, {
        type: 'error',
      });
    } finally {
      setLoading(false);
      fetchTravel();
      setType('');
      setEvent('');
      setDescription('');
    }
  }

  return (
    <Row md={12} className="px-5">
      <Col md={12} className="mb-2">
        <Select
          label="Tipo"
          value={type}
          options={types}
          onChange={value => setType(value)}
          getOptionLabel={option => `${option.name}`}
          getOptionValue={option => option.id}
          error={errors?.type}
        />
      </Col>

      <Col md={12} className="mb-2">
        <Select
          label="Evento"
          options={events}
          value={event}
          onChange={value => setEvent(value)}
          getOptionLabel={option => `${option.name}`}
          getOptionValue={option => option.id}
          error={errors?.event}
          isDisabled={!type}
        />
      </Col>

      <Col md={12} className="mb-2">
        <TextArea
          label="Descrição"
          value={description}
          onChange={({ target }) => setDescription(target.value)}
          resizable={false}
        />
      </Col>

      <Col
        className="d-flex justify-content-center align-items-center mb-4"
        md={12}
      >
        <Button onClick={handleSubmit} className="mt-2 py-2" loading={loading}>
          <Text type="regular" weight="600">
            Adicionar
          </Text>
        </Button>
      </Col>
    </Row>
  );
}

export default Add;
