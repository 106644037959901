import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import ApplicationLayout from 'v3/layouts/Application';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import useWindowResize from 'hooks/useWindowResize';
import useModal from 'hooks/useModal';
import api, { cooperplaceApi } from 'services/api';
import { usePermission } from 'hooks';
import Section from '../shared/Section';
import Data from '../shared/Data/index';
import Tracking from '../shared/Tracking/index';
import Settings from '../shared/Settings/index';
import Automations from '../shared/Automations/index';
import Addresses from '../shared/Addresses/index';
import PriorityFleet from '../shared/PriorityFleet/index';
import Checklist from '../Details/Checklist';
import Activities from '../shared/Activities/index';
import CreatedClientModal from './Modal/index';
import { validate } from '../shared/validator';
import { useClient } from '../shared/context';
import * as Styled from './styles';

export default function ClientRegister() {
  usePermission('CADASTRAR_CLIENTES', { redirect: true });
  const history = useHistory();
  const client = useClient();
  const createdClientModal = useModal();

  const [isLoading, setLoading] = useState(false);
  const [saveContainerOffset, setSaveContainerOffset] = useState(0);

  async function handleCreation(setData, setErrors) {
    setLoading(true);
    const isValid = await validate(
      'allSchema',
      {
        data: client.data,
        settings: client.data.settings,
        addresses: client.data.addresses,
        tracking: {
          sending_tracking: client.data.client.sending_tracking,
          emails_tracking: client.data.client.emails_tracking,
        },
      },
      client.setErrors
    );
    if (!isValid) {
      client.showSnackbar('Verifique os campos obrigatórios', {
        type: 'error',
      });
      setLoading(false);
      return;
    }
    try {
      setLoading(true);
      const payload = {
        data: {
          type_id: client.data?.personType?.name === 'Física' ? 2 : 3,
          is_international: client.data.is_international,
          is_rewards_member: client.data.is_rewards_member,
          social_name: client.data.social_name,
          cgccpf: client.data.cgccpf,
          phone: client.data.phone,
          ie: client.data.ie,
          fantasy_name: client.data.fantasy_name,
          is_client: true,
          is_taker: true,
          email: client.data.email,
          taker_role_id: client.data?.takerRole?.code,
          client_type_id: client.data?.clientType?.id,
          credit_limit: client.data?.credit_limit || 1000000,
        },
        tags: client.data.clientTags,
        settings: client.data.settings,
        automations: client.data.clientAutomations,
        addresses: client.data?.addresses,
        priorityFleetVehicles: client.data?.priorityFleetVehicles,
        checklists: client.data?.companyPerson?.checklists,
        cfops: client.data.cfops,
      };

      const response = await api.post('v3/client', payload);
      await cooperplaceApi.put(`clients/tracking/${response.data.id}/emails`, {
        sending_tracking: client.data.client.sending_tracking,
        emails_tracking: client.data.client.emails_tracking,
      });

      client.showSnackbar('Cliente cadastrado', { type: 'success' });
      setLoading(false);
      client.setData({ id: response.data.id });
      createdClientModal.open();
    } catch (err) {
      let message = err?.response?.data?.message;
      setLoading(false);
      if (err.response) {
        const errors = err?.response?.data;
        if (Array.isArray(errors)) {
          setErrors(old => ({
            ...old,
            ...Object.fromEntries(
              errors?.map(error => [error.field, error.message])
            ),
          }));
          message = errors[0]?.message;
        } else {
          message = errors.error;
          setErrors(errors.error);
        }
      }

      client.showSnackbar(message || 'Erro ao cadastrar cliente!', {
        type: 'error',
      });
    }
  }

  useWindowResize(() => {
    const header = document.getElementById('header-container');
    const originDistanceToRight =
      window.pageXOffset + header.getBoundingClientRect().right;
    const headerWidth = header.getBoundingClientRect().width;

    const SAVE_CONTAINER_PADDING = 0;
    const distanceToRight =
      originDistanceToRight - headerWidth + SAVE_CONTAINER_PADDING;
    setSaveContainerOffset(distanceToRight);
  }, []);
  return (
    <ApplicationLayout
      title="Cliente"
      RightComponent={
        <Styled.SaveContainer style={{ right: saveContainerOffset }}>
          <Button variant="secondary" onClick={() => history.push('/clientes')}>
            <Text weight={500} color="black" type="regular">
              Voltar
            </Text>
          </Button>

          <Button
            variant="success"
            onClick={() => {
              handleCreation(client.setData, client.setErrors);
            }}
            loading={isLoading}
          >
            <Text weight={500} color="white" type="regular">
              Salvar
            </Text>
          </Button>
        </Styled.SaveContainer>
      }
    >
      <CreatedClientModal
        isOpen={createdClientModal.isOpen}
        onClose={createdClientModal.close}
      />
      <Row className="row-eq-height">
        <Col xs={12} md={6}>
          <Section Title="Dados gerais">
            <Data />
          </Section>
        </Col>
        <Col xs={12} md={6}>
          <Section Title="Configuração">
            <Settings />
          </Section>
          <Section Title="Automações">
            <Automations />
          </Section>
          <Section Title="Envio de Tracking">
            <Tracking />
          </Section>
        </Col>
        <Col md={12}>
          <Addresses />
          <PriorityFleet />
        </Col>
        <Col xs={12} md={6}>
          <Checklist />
        </Col>
        <Col xs={12} md={6}>
          <Section Title="Tipo da atividade" className="full-height-card">
            <Activities />
          </Section>
        </Col>
      </Row>
    </ApplicationLayout>
  );
}
