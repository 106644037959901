import React, { useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Document, Page, pdfjs } from 'react-pdf';

import Button from 'components/Button';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// console.log(pdfjs.GlobalWorkerOptions.workerSrc);
// pdfjs.GlobalWorkerOptions.workerSrc = `https://cors-anywhere.herokuapp.com/https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = '/js/pdf.worker.min.js';

function ModalDocumentoPdf(props) {
  const [pageNumber, setPageNumber] = useState(null);
  const [numPages, setNumPages] = useState(null);

  const handleClose = () => {
    props.close(false);
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function onSourceError(e) {
    console.log(e);
  }

  return (
    <Dialog
      open={props.open ? true : false}
      onClose={handleClose}
      maxWidth={'xl'}
    >
      <DialogTitle>{'Documento'}</DialogTitle>
      <DialogContent>
        <Document
          style={{ width: '100%' }}
          file={props.documento}
          onLoadError={console.error}
          onLoadSuccess={onDocumentLoadSuccess}
          onSourceError={onSourceError}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </Document>
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="count"
          onClick={() => handleClose()}
          color="primary"
          autoFocus
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalDocumentoPdf;
