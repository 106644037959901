import styled from 'styled-components';

export const AttachmentSection = styled.div`
  padding: 0.5rem;
  border-radius: 6px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-top: 1rem;
`;

export const FallbackContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 2rem;
`;

export const AttachmentsContainer = styled.div`
  & > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  & > div {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-evenly;
    padding: 1rem;
  }
`;

export const AttachmentItem = styled.div`
  flex: 0;
  display: flex;
  flex-direction: column;
`;

export const Backdrop = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;

  > * {
    background-color: transparent;
    transition: opacity 0.3s ease-in;

    &:hover {
      opacity: 0.8;
    }
  }
`;
export const DateTime = styled.div`
  background-color: rgba(0, 0, 0, 0.35);
  padding: 5px;
  position: absolute;
  left: 0;
  bottom: 0;
`;
