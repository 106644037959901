export function downloadPDF(pdf, type, id) {
  const linkSource = `data:application/pdf;base64,${pdf}`;
  const downloadLink = document.createElement('a');
  const fileName = `PDF ${type} ${id}.pdf`;

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

export function downloadXml(xml, fileName) {
  const linkSource = `data:application/xml;base64,${xml}`;
  const downloadLink = document.createElement('a');

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}