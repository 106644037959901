import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';

import { Modal, Text } from 'v3/components';
import { FaEye, FaEyeSlash, FaWhatsapp } from 'react-icons/fa';
import { BsChat, BsChatDots } from 'react-icons/bs';
import {
  StyledDriverDiv,
  StyledDriverMatchCol,
  StyledMatchContactDiv,
  StyledMatchStatusDiv,
} from '../styles';
import theme from 'v3/theme';
import { cooperplaceApi } from 'services/api';
import {
  DefaultEmptyComponent,
  DefaultLoadingComponent,
} from 'v3/components/List';

export function LoadMatchDetails({ isOpen, onClose, loadDataId }) {
  const [loading, setLoading] = useState(true);
  const [drivers, setDrivers] = useState([]);
  async function handleFetchLoadVisualizations() {
    try {
      const { data } = await cooperplaceApi.get(
        `/loads/${loadDataId}/visualizations`
      );
      setDrivers(data);
    } catch (error) {
      setDrivers([]);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    if (isOpen && loadDataId) {
      handleFetchLoadVisualizations();
    }
  }, [isOpen, loadDataId]);
  return (
    <Modal
      scrollable
      size="md"
      show={isOpen}
      onHide={onClose}
      heading={
        <Text type="subheader" color="dark" weight="500">
          Detalhes negociações pré-carga {loadDataId}
        </Text>
      }
      body={
        loading ? (
          <DefaultLoadingComponent />
        ) : (
          <Row>
            {drivers?.length > 0 ? (
              drivers?.map(driver => {
                return (
                  <DriverDetailsCard driver={driver} key={driver.userId} />
                );
              })
            ) : (
              <DefaultEmptyComponent />
            )}
          </Row>
        )
      }
    />
  );
}

export function DriverDetailsCard({ driver }) {
  const phone =
    driver?.phone?.replace(/\D+/g, '') ?? driver?.phone2?.replace(/\D+/g, '');
  return (
    <StyledDriverMatchCol md={6}>
      <StyledDriverDiv>
        <Text type="label" color="#464E5F">
          Motorista
        </Text>
        <Text type="regular" color="gray" as="p" className="mt-2">
          {driver?.username}
        </Text>
      </StyledDriverDiv>
      <StyledMatchStatusDiv>
        <Text type="label" color="#464E5F">
          Status
        </Text>
        <Text type="regular" color="gray" as="p" className="mt-2">
          <span
            title={`${
              driver?.quantity > 0
                ? 'Motorista visualizou oferta '+driver?.quantity+' vezes'
                : 'Motorista ainda não visualizou oferta'
            }`}
          >
            {driver?.quantity > 0 ? (
              <FaEye size={16} />
            ) : (
              <FaEyeSlash size={16} />
            )}
          </span>
          <span
            title={`${
              driver?.interested
                ? 'Motorista indicou interesse na oferta'
                : driver?.quantity > 0
                ? 'Motorista não se interessou na oferta'
                : ''
            }`}
          >
            {driver?.interested ? (
              <BsChatDots size={16} />
            ) : (
              <BsChat size={16} />
            )}
          </span>
        </Text>
      </StyledMatchStatusDiv>
      <StyledMatchContactDiv>
        <Text type="label" color="#464E5F">
          Contato
        </Text>
        <Text type="regular" color="gray" as="p" className="mt-2">
          {phone ? (
            <a
              href={`https://api.whatsapp.com/send?l=pt-BR&phone=${phone}`}
              target="_blank"
              rel="noreferrer"
            >
              <FaWhatsapp color={theme.colors.success} size={16} />
            </a>
          ) : (
            'Não informado'
          )}
        </Text>
      </StyledMatchContactDiv>
    </StyledDriverMatchCol>
  );
}
