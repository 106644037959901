import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from 'v3/components/Button';
import Modal from 'v3/components/Modal';
import Text from 'v3/components/Text';
import { validatePermission } from 'actions/index';
import { useUser } from '../shared/context';

export default function RegisteredUserModal({ show }) {
  const history = useHistory();
  const user = useUser();
  const [hasPermissionToCreate, setPermissionToCreate] = useState();

  useState(() => {
    setPermissionToCreate(validatePermission('CADASTRAR_USUARIO'));
  }, []);

  return (
    <Modal
      show={show}
      heading="Cadastro de usuário"
      body={
        <>
          <p>Usuário {user.data?.username} cadastrado com sucesso!</p>
        </>
      }
      closeButton={false}
      backdrop="static"
      size="lg"
      footer={
        <>
          <Button
            variant="white"
            outlined
            onClick={() => history.push('/usuarios')}
          >
            <Text type="regular">Ver listagem</Text>
          </Button>
          <Button
            variant="primary"
            onClick={() => history.push(`/usuarios/${user.data?.id}`)}
          >
            <Text type="regular">Visualizar usuário</Text>
          </Button>
          {hasPermissionToCreate && (
            <Button
              variant="success"
              onClick={() => window.location.reload(false)}
            >
              <Text type="regular">Novo usuário</Text>
            </Button>
          )}
        </>
      }
    />
  );
}
