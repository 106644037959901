export const CNPJ_MASK = [
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];

export const PHONE_MASK = [
  '(',
  /[0-9]/,
  /\d/,
  ')',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const CPF_MASK = [
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];

export const BR_PHONE_MASK = [
  '(',
  /[0-9]/,
  /\d/,
  ')',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const CNPJ_DIGITS_LENGTH = 14;
export const CNPJ_LENGTH = 18;
export const CPF_DIGITS_LENGTH = 11;
export const CPF_LENGTH = 14;

export function onlyDigits(string) {
  return string.replace(/[^0-9]/gi, '');
}
