/* eslint-disable no-restricted-globals */
/* eslint-disable consistent-return */
import React, { useEffect, useRef } from 'react';

import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import useQuery from 'hooks/useQuery';
import { CNPJ_MASK, CPF_MASK } from 'utils/masks';

import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import Input from 'v3/components/Input';

function Filter({ fetchShippingCompanies }) {
  const query = useQuery();
  const history = useHistory();

  const nameRef = useRef(null);
  const cgccpfRef = useRef(null);

  useEffect(() => {
    fetchShippingCompanies();
  }, [history.location.search]);

  function onFilterChange(key, value) {
    if (value) {
      query.set(key, value);
    } else {
      query.delete(key);
    }
  }

  useEffect(() => {
    function onPressedKey(e) {
      if (e.key === 'Enter') {
        history.push({ search: query.toString() });
      }
    }
    window.addEventListener('keyup', onPressedKey);

    return () => {
      window.removeEventListener('keyup', onPressedKey);
    };
  });

  useEffect(() => {
    if (nameRef.current) {
      nameRef.current.value = query.get('name');
    }
    if (cgccpfRef.current) {
      cgccpfRef.current.value = query.get('cgccpf');
    }
  }, [query.toString()]);

  function applyMask(rawValue, options) {
    const regex = new RegExp(options.placeholderChar, 'g');
    if (rawValue.replace(regex, '').length > CPF_MASK.length) {
      return CNPJ_MASK;
    }

    return CPF_MASK;
  }

  return (
    <Row className="mb-4 ml-3 d-flex align-items-center">
      <Col md={5} xs={12}>
        <Input
          label="Transportadora"
          labelColor="#fff"
          placeholder="Digite o nome..."
          onChange={({ target }) => {
            onFilterChange('name', target.value);
          }}
          ref={nameRef}
        />
      </Col>
      <Col md={5} xs={12}>
        <Input
          label="CPF/CNPJ"
          labelColor="#fff"
          placeholder="Digite o CPF ou CNPJ..."
          onChange={({ target }) => onFilterChange('cgccpf', target.value)}
          ref={cgccpfRef}
          masked
          guide={false}
          mask={applyMask}
        />
      </Col>
      <Col md={2} style={{ marginTop: 30 }}>
        <Button
          variant="success"
          onClick={() => history.push({ search: query.toString() })}
        >
          <Text type="regular" weight={500}>
            Filtrar
          </Text>
        </Button>
      </Col>
    </Row>
  );
}

export default Filter;
