import React, { useCallback } from 'react';

import { useDropzone } from 'react-dropzone';

import Loading from 'components/Loading';

import { Container } from './styles';
import cloudIcon from './cloud-computing.png';

function FileInput({
  value = {},
  label,
  accept = '*',
  handleDrop,
  alwaysLabel,
  loading = false,
}) {
  const onDrop = useCallback(handleDrop, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <Container {...getRootProps()}>
      <input {...getInputProps()} accept={accept} />
      <img src={cloudIcon} alt="Cloud Icon" />
      {alwaysLabel && value.name && <p>{label}</p>}
      {loading ? <Loading color="#666" /> : <p>{value.name || label}</p>}
    </Container>
  );
}

export default FileInput;
