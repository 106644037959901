/* eslint-disable import/no-unresolved */
import React from 'react';
import { Link } from 'react-router-dom';
import { BsFillCaretDownFill } from 'react-icons/bs';
import Dropdown from 'react-bootstrap/Dropdown';

import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import { Spinner } from 'react-bootstrap';

const CustomToggle = React.forwardRef(
  ({ children, onClick, variant, size, disabled }, ref) => (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link href="#" ref={ref}>
      <Button
        onClick={e => {
          e.preventDefault();
          onClick(e);
        }}
        variant={variant}
        size={size}
        disabled={disabled}
      >
        <Text type="regular" weigth={500}>
          {children} <BsFillCaretDownFill />
        </Text>
      </Button>
    </Link>
  )
);

const ItemText = ({ text, Wrapper }) => {
  return Wrapper ? (
    <Wrapper>
      <Text>{text}</Text>
    </Wrapper>
  ) : (
    <Text>{text}</Text>
  );
};

export default function DropdownButton({
  children,
  title,
  variant,
  size = 'md',
  loading,
  disabled,
  options,
  ...props
}) {
  return (
    <Dropdown {...props} className="nav-header">
      <Dropdown.Toggle
        as={React.forwardRef((buttonProps, ref) => (
          <CustomToggle
            variant={variant}
            size={size}
            disabled={disabled}
            {...buttonProps}
            ref={ref}
          />
        ))}
      >
        {loading ? (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        ) : (
          title
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu className="p-0">
        {options.map(option =>
          option.type === 'divider' ? (
            <Dropdown.Divider className="m-0" />
          ) : option.type === 'item' ? (
            <Dropdown.Item
              className="text-uppercase h6 m-0"
              onClick={option.onClick}
              disabled={option.disabled}
              id={option.id}
            >
              <ItemText text={option.title} Wrapper={option.wrapper} />
            </Dropdown.Item>
          ) : (
            <Dropdown.ItemText className="text-uppercase h6 m-0">
              <Text> {title} </Text>
            </Dropdown.ItemText>
          )
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}
