import React, { useState } from 'react';
import Button from '../Button';
import Text from '../Text';
import Input from '../Input';
import cooperplaceApi from '../../../services/apis/cooperplace';
import { useSnackbar } from 'v3/components/Snackbar';
import ModalRaw from 'v3/components/Modal';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

export default function BennerButton({
  endpoint,
  title,
  label,
  field,
  redirectTo,
  itemToImport = null,
  items = null,
  httpMethod = 'get',
}) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('');
  const snackbar = useSnackbar();
  const history = useHistory();
  const mask = [
    /[0-9]/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
  ];

  async function handleClick() {
    setLoading(true);

    try {
      const url = `${endpoint}?${field}=${value}`;
      let response;
      if (httpMethod === 'get') {
        response = await cooperplaceApi.get(url);
      } else if (httpMethod === 'put') {
        response = await cooperplaceApi.put(url);
      }
      const { data } = response;
      snackbar.show(
        <Text> {data?.message || 'Importado do Benner com sucesso'} </Text>,
        { type: 'success' }
      );

      setOpen(false);
      setValue('');
      history.push(`${redirectTo}/${response?.data?.id}`);
    } catch (error) {
      const { data } = error.response;
      let message = data?.error || 'Ocorreu um erro ao importar do Benner.';

      snackbar.show(<Text> {message} </Text>, { type: 'error' });
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Button
        variant="secondary"
        onClick={() => {
          setOpen(true);
        }}
        className="ml-2 mr-2"
      >
        <Text color="dark" weight={500} type="regular">
          Importar do Benner
        </Text>
      </Button>

      <ModalRaw
        size="lg"
        backdrop="static"
        show={open}
        handleClose={() => {
          setOpen(false);
          setValue('');
        }}
        heading={
          <Text type="header" color="dark">
            {title}
          </Text>
        }
        body={
          <>
            <Text type="regular" color="dark">
              {label}
            </Text>
            <Input
              type="text"
              // className="form-control"
              placeholder={label}
              value={value}
              onChange={e => {
                setValue(e.target.value);
              }}
              masked={endpoint === 'drivers/importFromBenner'}
              mask={endpoint === 'drivers/importFromBenner' ? mask : []}
            ></Input>
            {itemToImport ? (
              <Row>
                <Col md={12} xs={12} className="mt-4 mb-2">
                  <Text color="dark">
                    {`Itens obrigatórios no Benner para importação de ${itemToImport}:`}
                  </Text>
                </Col>
                {items.map((item, index) => {
                  return (
                    <Col xs={12} md={12} key={index + item}>
                      <Text color="dark" fontSize="14px">
                        {item}
                      </Text>
                    </Col>
                  );
                })}
              </Row>
            ) : null}
          </>
        }
        footer={
          <Row>
            <Col md={6} xs={12}>
              <Button
                className="py-2"
                onClick={() => handleClick()}
                loading={loading}
                disabled={value === ''}
              >
                <Text weight="500" type="regular">
                  Importar
                </Text>
              </Button>
            </Col>
          </Row>
        }
      ></ModalRaw>
    </>
  );
}
