import api from 'services/api';
import { capitalize } from 'v3/utils/formatter';

export async function fetchRiskManagers(search) {
  try {
    const response = await api.get('risk-manager-for-select', {
      params: { search },
    });

    return response.data.map(riskManager => ({
      ...riskManager,
      name: capitalize(riskManager.name),
    }));
  } catch (ex) {
    return [];
  }
}

export async function fetchTrackers(search) {
  try {
    const response = await api.get('select/trackers', { params: { search } });

    return response.data.map(tracker => ({
      ...tracker,
      name: capitalize(tracker.name),
    }));
  } catch (ex) {
    return [];
  }
}

export const RISK_MANAGER_STATUSES = [
  { value: 'WAITING', label: 'Aguardando checklist' },
  { value: 'ON_GOING', label: 'Checklist em andamento' },
  { value: 'REPROVED', label: 'Checklist reprovado' },
  { value: 'APPROVED', label: 'Checklist aprovado' },
  { value: 'WAIT_SCHEDULE', label: 'Checklist aguardando agendamento'}
];

export const RISK_MANAGER_STATUSES_OBJECT = Object.values(
  RISK_MANAGER_STATUSES
).reduce((acc, item) => ({ ...acc, [item.value]: item }), {});
