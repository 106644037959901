export const CF_MASK = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];

export const CF_SLUGS = ['CF_COMPLEMENTAR', 'CF_MANUAL'];