import styled from 'styled-components';
import theme from 'v3/theme';

export const StepsContainer = styled.div`
  display: flex;
  flex-direction:column;
  align-items: center;
  gap:0.25rem;
`;

export const ProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StepContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Line = styled.div`
  flex: 1;
  min-width: 1.25rem;
  height:2px;
  background-color: ${props =>
    props.completed ? theme.colors.lighter_green : 'lightgray'};
`;

export const Step = styled.div`
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  background-color: ${props =>
    props.completed ? theme.colors.lighter_green : 'lightgray'};
`;
