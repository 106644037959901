import styled from 'styled-components';
import { Card, Text } from 'v3/components';
import theme from 'v3/theme';

export const ColumnsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  max-height: 800px;
  padding-bottom: 1rem;
  grid-column-gap: 0.25rem;
  overflow: auto;
  scroll-behavior: smooth;
  ::-webkit-scrollbar {
    width: 0.45rem;
    height: 0.5rem;
  }
  ::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.light_gray};
    opacity: 0.5;
  }
  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.gray};
    border-radius: 10px;
  }
  & > div {
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 1920px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 1439px) {
    grid-template-columns: 1fr;
  }
`;

export const StyledHeader = styled(Text)`
  text-transform: uppercase !important;
  font-weight: 500;
  color: #464e5f;
  margin-bottom: 0.5rem;
`;

export const StyledCard = styled(Card)`
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1);
  & > div {
    display: flex;
    flex-direction: column;
  }
`;

export const ColumnItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > svg {
    cursor: help;
    color: ${theme.colors.light_blue};
  }
`;
