import React, { useState, useCallback, useContext } from 'react';
import { cooperplaceApi } from 'services/api';
import { Col } from 'react-bootstrap';
import { useModal } from 'hooks';

import theme from 'v3/theme';
import { Button, Card, Fallback, Text } from 'v3/components';
import { useSnackbar } from 'v3/components/Snackbar';

import { BlockedTravelContext } from 'contexts/BlockedTravelProvider';
import { useTravel } from '../..';
import { ContractDetailsModal } from './ContractDetailsModal';
import { ContractsList } from './ContractsList';

export function FreightContracts() {
  const { isTravelBlocked } = useContext(BlockedTravelContext);
  const travel = useTravel();
  const snackbar = useSnackbar();
  const contractDetailsModal = useModal();
  const [selectedContractId, setSelectedContractId] = useState(null);
  const selectedContract = travel?.travelFreightContracts?.filter(
    contract => contract.id === selectedContractId
  )[0];
  const [loading, setLoading] = useState(false);

  const handleShowContract = useCallback(contractId => {
    setSelectedContractId(contractId);
    contractDetailsModal.open();
  }, []);

  const handleCloseContractModal = useCallback(() => {
    setSelectedContractId(null);
    contractDetailsModal.close();
  }, []);

  async function handleUpdateContracts() {
    try {
      setLoading(true);
      const response = await cooperplaceApi.post(
        `freight/importContracts/${travel?.data?.id}`,
        {
          travelNumber: travel.data?.loads?.externalId,
        }
      );
      const message =
        response?.data?.message ?? 'Contratos atualizados com sucesso';
      snackbar.show(<Text>{message}</Text>, {
        type: response.data.success ? 'success' : 'error',
      });
      if (response.data.success) {
        await travel.getTravelFreightContracts();
      }
    } catch (error) {
      const { data } = error.response;
      const message =
        data.message ?? 'Ocorreu um erro ao atualizar os contratos';
      snackbar.show(<Text>{message}</Text>, {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }
  return (
    <>
      <ContractDetailsModal
        freightContract={selectedContract}
        isOpen={contractDetailsModal.isOpen}
        onClose={handleCloseContractModal}
      />
      <Card
        loading={travel.isLoading}
        header={
          <Text color={theme.colors.dark} type="header">
            Contratos de Fretes
          </Text>
        }
        HeaderRightComponent={
          <Button
            variant="primary"
            onClick={handleUpdateContracts}
            loading={loading}
            disabled={isTravelBlocked}
          >
            <Text color="white">Atualizar contratos</Text>
          </Button>
        }
      >
        <Fallback
          component={
            <Col xs={12} className="align-items-center d-flex flex-column my-2">
              <Text color={theme.colors.gray}>
                Não há contratos de frete cadastrados para essa viagem.
              </Text>
            </Col>
          }
          fall={travel?.travelFreightContracts?.length === 0 || loading}
        >
          <ContractsList
            freightContracts={travel?.travelFreightContracts}
            handleOpenModal={handleShowContract}
          />
        </Fallback>
      </Card>
    </>
  );
}
