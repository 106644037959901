import { useMemo, useState } from 'react';

export default function usePagination({
  data: propsData = [],
  initialPage = 1,
  initialPerPage = 5,
  manual = false,
  onManualSearch,
}) {
  const [perPage, setPerPage] = useState(initialPerPage);
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [total, setTotal] = useState(0);
  const [lastPage, setLastPage] = useState(initialPage);
  const [search, setSearch] = useState('');

  const data = useMemo(() => {
    if (manual) {
      const offset = (currentPage - 1) * perPage;
      let paginated = propsData;

      if (search && onManualSearch) {
        paginated = paginated.filter((item, index) =>
          onManualSearch(search, item, index)
        );
      }

      const currentLastPage = Math.ceil(paginated.length / perPage);
      if (currentLastPage && lastPage > currentLastPage) {
        setCurrentPage(currentLastPage);
      }

      setTotal(paginated.length);
      setLastPage(currentLastPage);

      return paginated.slice(offset, perPage + offset);
    }

    return propsData;
  }, [propsData, perPage, currentPage, search, manual, onManualSearch]);

  function nextPage() {
    setCurrentPage(oldPage => oldPage + 1);
  }

  function previousPage() {
    setCurrentPage(oldPage => oldPage - 1);
  }

  return {
    perPage,
    currentPage,
    search,
    lastPage,
    total,
    data,
    setCurrentPage,
    setPerPage,
    setSearch,
    setTotal,
    setLastPage,
    nextPage,
    previousPage,
  };
}
