import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom/';
import ApplicationLayout from 'v3/layouts/Application';
import { validatePermission } from 'actions';
import { Button, Card, Text } from 'v3/components';
import Space from 'v3/components/Space';
import { useModal } from 'hooks';
import { cooperplaceApi } from 'services/api';
import { useChecklist } from '../shared/context';
import {
  BranchOfficesInfo,
  ClientInfo,
  ClientsInfo,
  GeneralInfo,
  ImplementsInfo,
  QuestionsSection,
  VehiclesBodyInfo,
  VehiclesInfo,
} from './components';
import { AuditModal } from './AuditModal';
import { ChecklistData } from '../shared/Data';
import { CheckListSchema } from '../components/RegisterComponents/validator';

export function Details() {
  const params = useParams();
  const history = useHistory();
  const checklist = useChecklist();
  const hasPermissionToView = validatePermission('VISUALIZAR_CHECKLIST');
  const hasPermissionToEdit = validatePermission('CADASTRAR_CHECKLIST');
  const auditModal = useModal();
  const processedLogs = checklist?.data?.checkListLogs?.map(log => {
    return {
      ...log,
      show: false,
    };
  });
  const [editing, setEditing] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);

  useEffect(() => {
    if (!hasPermissionToView) {
      checklist.showSnackbar(
        'Você não tem permissão para visualizar checklists',
        { type: 'error' }
      );
      history.push('/');
    }
  }, [hasPermissionToView]);
  function handleCancel() {
    checklist.handleCancel();
    setEditing(false);
  }
  async function validate(data, setErrors) {
    const errorList = {};
    const dataObject = {
      name: data?.checklistInfo?.name,
      description: data?.checklistInfo?.description,
      type: data?.checklistInfo?.checklistType?.id,
      questions: data?.questions?.map(item => {
        return {
          checklist_id: item?.id,
          order: item?.order,
          required: item?.required,
        };
      }),
      clients: data?.checklistInfo?.clients,
      negotiation: data?.checklistInfo?.negotiation,
      vehicleType: data?.checklistInfo?.vehicleType,
      vehicleBodyType: data?.checklistInfo?.vehicleBodyType,
      implementType: data?.checklistInfo?.implementType,
      branchOffices: data?.checklistInfo?.branchOffices,
      hasDollyImplement: data?.checklistInfo?.hasDollyImplement,
    };
    try {
      await CheckListSchema.validate(dataObject, {
        abortEarly: false,
      });
      setErrors({ ...errorList });

      return true;
    } catch (err) {
      err.inner.forEach(error => {
        errorList[error.path] = error.message;
      });
      setErrors({ ...errorList });

      return false;
    }
  }
  async function handleUpdateChecklist(questions) {
    if (loadingEdit) {
      return;
    }
    try {
      setLoadingEdit(true);
      const hasDollyImplement = !!checklist?.data?.implementType.find(
        ({ name }) => name === 'Dolly'
      );
      const dataToValidate = { ...checklist?.data, hasDollyImplement };
      const isDataValid = await validate(
        { checklistInfo: dataToValidate, questions },
        checklist.setErrors
      );
      if (!isDataValid) {
        setLoadingEdit(false);
        return;
      }
      const payload = {
        require: checklist?.data?.required || false,
        default: checklist?.data?.default || false,
        active: checklist?.data?.active || false,
        name: checklist?.data?.name,
        description: checklist?.data?.description,
        checkTypeId: checklist?.data?.checklistType?.id,
        cnpjClientsId: checklist?.data?.clients?.map(item => item?.id),
        negotiationId: checklist?.data?.negotiation?.id,
        vehiclesTypesId: checklist?.data?.vehicleType?.map(item => item?.id),
        vehiclesBodyId: checklist?.data?.vehicleBodyType?.map(item => item?.id),
        vehiclesImplementsId: checklist?.data?.implementType?.map(
          item => item?.id
        ),
        questions: questions?.map(question => {
          return {
            questionId: question?.id,
            required: question?.required || false,
            order: question?.order,
          };
        }),
        availableForDriver: !!checklist?.data?.availableForDriver,
        branchOffices: checklist?.data?.branchOffices?.map(item => item?.id),
        requiredDriverSign: !!checklist?.data?.requiredDriverSign,
        requiredInspectSign: !!checklist?.data?.requiredInspectSign,
        availableLogisticPanel: !!checklist?.data?.availableLogisticPanel,
      };
      await cooperplaceApi.put(`checklists/${params?.id}`, payload);
      await checklist.fetchChecklist(params?.id);
      checklist.showSnackbar('Checklist atualizado com sucesso!', {
        type: 'success',
      });
      setTimeout(() => {
        setLoadingEdit(false);
        setEditing(false);
      }, 500);
    } catch (error) {
      const message =
        error?.response?.data?.error ||
        'Não foi possível atualizar o checklist';
      checklist.showSnackbar(`${message}`, {
        type: 'error',
      });
      checklist.handleCancel();
      setLoadingEdit(false);
    }
  }
  return (
    <ApplicationLayout
      title="Checklist"
      RightComponent={
        !editing && (
          <Space x="0.5rem">
            <Button
              variant="secondary"
              onClick={() => history.goBack()}
              disabled={checklist.loading}
            >
              <Text type="regular" weight={500}>
                Voltar
              </Text>
            </Button>
            <Button
              onClick={() => history.push('/checklist/checklists/cadastrar')}
              disabled={checklist.loading}
            >
              <Text type="regular" weight={500}>
                Novo checklist
              </Text>
            </Button>
          </Space>
        )
      }
    >
      {editing ? (
        <ChecklistData
          handleSave={handleUpdateChecklist}
          isLoading={loadingEdit}
          handleCancel={handleCancel}
        />
      ) : (
        <>
          <Card
            header={
              <Text
                color="dark"
                type="header"
              >{`Checklist ${checklist?.data?.id}`}</Text>
            }
            HeaderRightComponent={
              <div style={{ display: 'flex', gap: 8 }}>
                <Button onClick={() => auditModal.open()}>
                  <Text type="regular" weight={500}>
                    Auditoria
                  </Text>
                </Button>
                {hasPermissionToEdit && (
                  <Button
                    variant="secondary"
                    weight={500}
                    loading={checklist.loading}
                    onClick={() => setEditing(true)}
                  >
                    <Text type="regular">Editar</Text>
                  </Button>
                )}
              </div>
            }
            loading={checklist?.loading}
          >
            <GeneralInfo checklist={checklist} />
            {checklist?.data?.branchOffices?.length > 0 && (
              <BranchOfficesInfo checklist={checklist} />
            )}
            {checklist?.data?.clients?.length === 1 && (
              <ClientInfo checklist={checklist} />
            )}
            {checklist?.data?.clients?.length > 1 && (
              <ClientsInfo checklist={checklist} />
            )}
            {checklist?.data?.vehicleType?.length > 0 && (
              <VehiclesInfo checklist={checklist} />
            )}
            {checklist?.data?.vehicleBodyType?.length > 0 && (
              <VehiclesBodyInfo checklist={checklist} />
            )}
            {checklist?.data?.implementType?.length > 0 && (
              <ImplementsInfo checklist={checklist} />
            )}
            <QuestionsSection checklist={checklist} />
          </Card>
          <AuditModal
            isOpen={auditModal.isOpen}
            onClose={() => auditModal.close()}
            logs={processedLogs}
          />
        </>
      )}
    </ApplicationLayout>
  );
}
