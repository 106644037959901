import styled from 'styled-components';

export const Backdrop = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;

  > * {
    background-color: transparent;
    transition: opacity 0.3s ease-in;

    &:hover {
      opacity: 0.8;
    }
  }
`;
export const ListAttachment = styled.div`
width: 100%;
list-style: none;
display: flex;
justify-content: space-between;
align-items: center;
border: ${props =>
    !props.hasExpiredAttachment
      ? '2px solid #FFFF00'
      : null};

span{
  padding:2rem;
  button {
    background-color:transparent;
    margin:0 0.5rem;
  }
}

span:last-child{
  display:flex;
}

`;

export const DateTime = styled.div`
  background-color: rgba(0, 0, 0, 0.35);
  padding: 5px;
  position: absolute;
  left: 0;
  bottom: 0;
`;

export const ImageRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;

  > * {
    margin-bottom: 15px;

    &: first-child {
      margin-left: 45px;
    }

    &:not(:last-child) {
      margin-right: 100px;
    }
  }
`;
