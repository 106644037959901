import styled from 'styled-components';

export const Container = styled.div`
  width: 671px;
  height: 28px;
  border-radius: 5px 0px 0px 0px;
  background: #fff;
`;

export const SubContainer = styled.div`
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  display: flex;
`;

export const BoxContainer = styled.div`
  align-items: center;
  display: flex;
  align-content: center;
  margin-top: 7px;
  margin-left: 8px;
`;

export const OriginBox = styled.div`
  width: 10px;
  height: 10px;
  margin-right: 8px;
  margin-left: 8px;
  background: #0bb7af;
`;

export const DestinationBox = styled.div`
  width: 10px;
  height: 10px;
  margin-right: 8px;
  margin-left: 8px;

  background: #266ca8;
`;

export const FinalBox = styled.div`
  width: 10px;
  height: 10px;
  margin-right: 8px;
  margin-left: 8px;
  background: #f64e60;
`;