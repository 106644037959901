/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect, useMemo } from 'react';
import { Row, Col } from 'react-bootstrap';
import { formatDateTime } from 'v3/utils/formatter';
import Lightbox from 'react-image-lightbox';
import { FaSearch, FaTrash } from 'react-icons/fa';
import Fallback from 'v3/components/Fallback';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import Card from 'v3/components/Card';
import Modal from 'v3/components/Modal';
import PDFView from 'v3/components/PDFView';
import StringBuilder from 'utils/StringBuilder';
import theme from 'v3/theme';

import AttachmentModal from '../Modals/Attachment';
import * as Styled from './styles';

import { TravelContext } from '../controller';

const [, useTravel] = TravelContext;

export default function Attachments() {
  const travel = useTravel();

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    travel.setData(old => ({ ...old, attachments: [] }));
  }, []);

  return (
    <>
      <AttachmentModal isOpen={showModal} onClose={() => setShowModal(false)} />
      <Card
        header={
          <Text color="#464E5F" type="header">
            Anexos
          </Text>
        }
        HeaderRightComponent={
          !!travel?.data?.attachments?.length && (
            <Button
              id="button-adicionar-anexo"
              onClick={() => setShowModal(true)}
            >
              {' '}
              Adicionar{' '}
            </Button>
          )
        }
      >
        <>
          <Fallback
            component={
              <Col
                xs={12}
                className="align-items-center d-flex flex-column my-2"
              >
                <Text color={theme.colors.gray}>
                  Não há anexos cadastrados para essa viagem.
                </Text>
                <Button
                  id="button-adicionar-anexo"
                  onClick={() => setShowModal(true)}
                  className="mt-3"
                >
                  Adicionar
                </Button>
              </Col>
            }
            fall={!travel?.data?.attachments?.length}
          >
            <Row>
              {travel?.data?.attachments?.map((attachment, index) => (
                <Col
                  md={6}
                  className="d-flex flex-column align-items-center mb-2"
                >
                  <Attachment attachment={attachment} index={index} />
                  <Text
                    id={`label-descricao-arquivo-${index}`}
                    type="regular"
                    className="mt-2"
                  >
                    {attachment.description}
                  </Text>
                </Col>
              ))}
            </Row>
          </Fallback>
        </>
      </Card>
    </>
  );
}

function Attachment({ attachment, index }) {
  const travel = useTravel();
  const [isHovering, setHovering] = useState(false);
  const [isShowingModal, setShowingModal] = useState(false);

  const attachmentsWithoutPDF = useMemo(() => {
    return travel.data.attachments?.filter(item => {
      const filterPDF = item.file.type?.split('/');
      if (filterPDF[filterPDF?.length - 1] === 'pdf') return null;
      return item;
    });
  }, [travel.data.attachments]);

  const [lightbox, setLightbox] = useState({
    isOpen: false,
    attachments: attachmentsWithoutPDF,
    index: 0,
  });

  function removeAttachment(attachment) {
    travel.setData(old => ({
      ...old,
      attachments: [
        ...travel.data.attachments.filter(item => item !== attachment),
      ],
    }));
  }

  const lightboxIndexes = useMemo(() => {
    const next = (lightbox.index + 1) % lightbox.attachments?.length;
    const previous =
      (lightbox.index + lightbox.attachments.length - 1) %
      lightbox.attachments.length;
    return { next, previous };
  }, [lightbox.index, lightbox.attachments]);

  function onClickPreview(file) {
    const index = file ? attachmentsWithoutPDF.findIndex(a => a === file) : 0;

    setLightbox(prev => ({
      ...prev,
      index,
      attachments: attachmentsWithoutPDF,
      isOpen: true,
    }));
  }

  function handleClick() {
    const fullPathArray = attachment.file.type?.split('/');

    if (fullPathArray?.[fullPathArray?.length - 1] === 'pdf') {
      setShowingModal(true);
    } else {
      onClickPreview(attachment);
    }
  }

  function getImagePath(index, alwaysDisplay) {
    const isSameAsIndex =
      lightbox.index === lightboxIndexes.next &&
      lightbox.index === lightboxIndexes.previous;

    if (!alwaysDisplay && isSameAsIndex && lightbox.attachments.length === 1) {
      return null;
    }
    return lightbox.attachments[index]?.file.tempPath;
  }

  function getDocumentMetadata() {
    const document = lightbox.attachments[lightbox.index];
    const type = '';
    const createdAt = formatDateTime(document?.created_at);

    return { type, createdAt };
  }

  const lightboxTitle = useMemo(() => {
    const builder = new StringBuilder(' ');
    const documentMetadata = getDocumentMetadata();
    builder
      .append(`${lightbox.index + 1}/${lightbox.attachments?.length}`)
      .append('|')
      .append(documentMetadata.type)
      .append(`(${documentMetadata.createdAt})`);

    return builder.build();
  }, [lightbox.index, lightbox.attachments]);

  return (
    <div>
      <Modal
        show={isShowingModal}
        dialogClassName="modal-90w"
        onHide={() => setShowingModal(false)}
        heading="Visualizar PDF"
        body={<PDFView file={attachment.file.tempPath} />}
      />
      <div
        className="position-relative"
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        style={{
          width: 240,
          height: 130,
          background: `url(${
            attachment.file.type?.split('/')?.pop() === 'pdf'
              ? '/images/default-doc.png'
              : attachment.file.tempPath
          }) center center`,
          backgroundSize: '100%',
        }}
      >
        {lightbox.isOpen && (
          <Lightbox
            mainSrc={getImagePath(lightbox.index, true)}
            nextSrc={getImagePath(lightboxIndexes.next)}
            prevSrc={getImagePath(lightboxIndexes.previous)}
            onCloseRequest={() =>
              setLightbox(prev => ({ ...prev, isOpen: false }))
            }
            onMovePrevRequest={() =>
              setLightbox(prev => ({
                ...prev,
                index: lightboxIndexes.previous,
              }))
            }
            onMoveNextRequest={() =>
              setLightbox(prev => ({
                ...prev,
                index: lightboxIndexes.next,
              }))
            }
            imageTitle={lightboxTitle}
          />
        )}
        {isHovering && (
          <Styled.Backdrop>
            <button
              id={`button-abrir-anexo-${index}`}
              type="button"
              onClick={handleClick}
            >
              <FaSearch color="#fff" size={28} />
            </button>

            <Button
              variant="transparent"
              size={0}
              onClick={() => removeAttachment(attachment)}
              id={`button-excluir-anexo-${index}`}
            >
              <FaTrash size={28} />
            </Button>
          </Styled.Backdrop>
        )}

        <Styled.DateTime>
          <Text type="label" color="white">
            {formatDateTime(attachment.created_at)}
          </Text>
        </Styled.DateTime>
      </div>
    </div>
  );
}
