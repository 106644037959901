/* eslint-disable react/jsx-no-bind */
import React, { useState, useMemo, useEffect } from 'react';

import api from 'services/api';
import { Row, Col } from 'react-bootstrap';
import { Modal, Text, Select, Input, Button } from 'v3/components';
import CurrencyInput from 'v3/components/Input/Currency';
import { useSnackbar } from 'v3/components/Snackbar';
import { useValidation } from 'hooks';
import { useCompany } from '../context';
import { goalSchema } from '../Goal/validator';

export default function AddClientModal({ isOpen, onClose, setShowModal }) {
  const company = useCompany();

  const [errors, { validate, setErrors }] = useValidation(goalSchema);

  const [month, setMonth] = useState();

  const [goal, setGoal] = useState();

  const [overGoal, setOverGoal] = useState();

  const [year, setYear] = useState();
  const snackbar = useSnackbar();
  const isEditing = useMemo(() => !!company.data.id, [company.data.id]);

  const [currency] = useState({
    currency: 'BRL',
    currency_symbol: 'R$',
  });

  const MonthOptions = [
    { label: 'Janeiro', value: 0 },
    { label: 'Fevereiro', value: 1 },
    { label: 'Março', value: 2 },
    { label: 'Abril', value: 3 },
    { label: 'Maio', value: 4 },
    { label: 'Junho', value: 5 },
    { label: 'Julho', value: 6 },
    { label: 'Agosto', value: 7 },
    { label: 'Setembro', value: 8 },
    { label: 'Outubro', value: 9 },
    { label: 'Novembro', value: 10 },
    { label: 'Dezembro', value: 11 },
  ];

  useEffect(() => {
    setMonth(MonthOptions[company.data.editingCurrentGoal?.month]);
    setGoal(company.data.editingCurrentGoal?.goal);
    setOverGoal(company.data.editingCurrentGoal?.over_goal);
    setYear(company.data.editingCurrentGoal?.year);
  }, [company.data.editingCurrentGoal]);

  function handleClose() {
    onClose();
    setErrors('');
    setMonth(null);
    setGoal(null);
    setOverGoal(null);
    setYear(null);

    if (company.data.editingCurrentGoal) {
      company.setData({
        goals: [...company.data.goals, company.data.editingCurrentGoal],
        editingCurrentGoal: null,
      });
    }
  }

  async function handleAddGoal() {
    const addGoal = {
      month: month?.value,
      year,
      goal,
      over_goal: overGoal,
      id: company.data.editingCurrentGoal?.id || undefined,
    };

    let validateRegister = false;

    company.data.goals.forEach(goalIndex => {
      if (
        goalIndex.month === addGoal.month &&
        goalIndex.year === addGoal.year
      ) {
        snackbar.show(<Text>Erro a meta já existe</Text>, {
          type: 'error',
        });
        setErrors({
          month: 'Mês já existe.',
          year: 'Ano já existe.',
        });

        validateRegister = true;
      }
    });

    if (validateRegister) {
      return;
    }

    const [isValid] = await validate(addGoal);

    if (isValid !== true) {
      snackbar.show(<Text>Erro ao cadastrar meta</Text>, {
        type: 'error',
      });
    } else if (!isEditing) {
      company.setData({
        goals: [...company.data.goals, addGoal],
        editingCurrentGoal: null,
      });
      setShowModal(false);
      setMonth(null);
      setGoal(null);
      setOverGoal(null);
      setYear(null);
    } else {
      try {
        const response = await api.post(
          `v3/company/${company.data.id}/goal`,
          addGoal
        );

        if (response.status === 200) {
          const updated = company.data.goals.find(
            dataGoal => dataGoal.id === response.data.id
          );

          const updatedIndex = company.data.goals.findIndex(
            dataGoal => dataGoal.id === response.data.id
          );

          company.data.goals[updatedIndex] = response.data;

          if (updated === undefined) {
            company.setData({
              goals: [...company.data.goals, response.data],
              editingCurrentGoal: null,
            });
          }

          setShowModal(false);
          setMonth(null);
          setGoal(null);
          setOverGoal(null);
          setYear(null);

          snackbar.show(<Text>Meta cadastrada com sucesso!</Text>, {
            type: 'success',
          });
        }
      } catch (err) {
        snackbar.show(<Text>Erro ao cadastrar meta</Text>, {
          type: 'error',
        });
      } finally {
        setShowModal(false);
      }
    }
  }
  return (
    <Modal
      size="lg"
      show={isOpen}
      handleClose={handleClose}
      heading={
        <Text type="header" color="dark">
          Metas
        </Text>
      }
      body={
        <Row>
          <Col
            xs={6}
            className="align-items-center d-flex flex-column justify-content-center mb-3"
          >
            <Select
              options={MonthOptions}
              label="Mês"
              value={month}
              horizontal
              getOptionLabel={option => option.label}
              getOptionValue={option => option.value}
              onChange={value => setMonth(value)}
              error={errors.month}
            />
          </Col>
          <Col
            xs={6}
            className="align-items-center d-flex flex-column justify-content-center mb-3"
          >
            <Input
              type="number"
              label="Ano"
              value={year}
              onChange={e => setYear(e.target.value)}
              error={errors.year}
            />
          </Col>
          <Col
            xs={6}
            className="align-items-center d-flex flex-column justify-content-center mt-3"
          >
            <CurrencyInput
              label={`Meta (${currency?.currency_symbol || 'R$'})`}
              value={goal}
              onChange={(_event, value) => setGoal(value)}
              error={errors.goal}
            />
          </Col>
          <Col
            xs={6}
            className="align-items-center d-flex flex-column justify-content-center mt-3"
          >
            <CurrencyInput
              label={`Super Meta (${currency?.currency_symbol || 'R$'})`}
              value={overGoal}
              onChange={(_event, value) => setOverGoal(value)}
              error={errors.over_goal}
            />
          </Col>

          <Col
            xs={12}
            className="align-items-center d-flex flex-column justify-content-center mt-3"
          >
            <Button
              variant="success"
              size="md"
              onClick={() => {
                handleAddGoal();
              }}
              style={{
                border: errors?.select ? '1px solid red' : 'transparent',
                marginBottom: errors?.select ? 23 : null,
              }}
            >
              <Text type="regular" weight="500" color="white">
                Salvar
              </Text>
            </Button>
          </Col>
        </Row>
      }
    />
  );
}
