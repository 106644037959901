import { useEffect } from 'react';

export default function useEffectThrottled(fn, deps, DELAY = 800) {
  useEffect(() => {
    const timeout = setTimeout(() => {
      fn();
    }, DELAY);

    return () => {
      clearTimeout(timeout);
    };
  }, deps);
}
