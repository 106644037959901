// eslint-disable-next-line import/no-unresolved
import api from 'services/api';
import schema from './validator';

export async function fetchProvinces() {
  try {
    const response = await api.get('provinces');
    return response.data;
  } catch (ex) {
    return [];
  }
}

export async function fetchClients(search) {
  try {
    const response = await api.get('persons/customers', {
      params: { search },
    });
    return response.data;
  } catch (err) {
    throw new Error(err.message);
  }
}

export async function fetchProducts(search) {
  try {
    const response = await api.get('product-types?', { params: { search } });
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function validateAdd(data, setErrors) {
  const errorList = {};
  try {
    await schema.validate(data, { abortEarly: false });
    setErrors({ ...errorList });
    return true;
  } catch (err) {
    err.inner.forEach(error => {
      errorList[error.path] = error.message;
    });

    setErrors({ ...errorList });
    return false;
  }
}

export async function saveIcms({ id, ...rest }) {
  try {
    const response = await api.put(`issuers/icms/${id}`, { ...rest });

    return response.data;
  } catch (err) {
    const message = err.response.data[0]
      ? err.response.data[0].message
      : err.response.data.message;

    return { error: message || 'Não foi possível atualizar dados de ICMS' };
  }
}
