import styled, { css } from 'styled-components';

import { Tabs, Tab, TableRow, TableCell, TableBody } from '@material-ui/core';

export const StyledTabs = styled(Tabs)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  border-bottom: 1px solid ${({ theme }) => theme.colors.background};

  > div {
    overflow: auto !important;

    > span {
      background-color: ${({ theme }) => theme.colors.primary};

      width: 120px;
      height: 3px;
    }
  }
`;

export const StyledTab = styled(Tab).attrs({ disableRipple: true })`
  && {
    min-width: 120px !important;
  }

  > span {
    font-size: 1rem;
    font-weight: 600;

    ${({ active, theme }) =>
      active &&
      css`
        color: ${theme.colors.primary};
      `}
  }
`;

export const StyledTableBody = styled(TableBody)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.background};
`;

export const StyledTableRow = styled(TableRow)`
  &:nth-child(even) {
    background-color: ${({ theme }) => theme.colors.background};
  }

  > th,
  td {
    border-bottom: none;

    color: ${({ theme }) => theme.colors.secondary};
    font-weight: 300;

    padding-bottom: 10px;
    padding-top: 10px;
  }
`;

export const StyledTableCell = styled(TableCell)``;
