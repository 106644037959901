import api from 'services/api';

// Usado por: Empresa *
export async function fetchCompanies(search) {
  try {
    const { data: response } = await api.get(`company?search=${search}`);
    return response;
  } catch (error) {
    return [];
  }
}

export async function fetchBranchOffices(params) {
  try {
    const { data } = await api.get('branch-offices', {
      params,
    });
    return data;
  } catch (error) {
    return [];
  }
}

export async function fetchCostCenters(params) {
  try {
    const { data: response } = await api.get('v3/cost-center', {
      params,
    });
    return response;
  } catch (error) {
    return [];
  }
}

// Usado por: tags
export async function fetchSelectOptions(url, params = {}) {
  try {
    const { data } = await api.get(url, { params });

    return data;
  } catch (err) {
    return [];
  }
}
