import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Text } from 'v3/components';
import { CardStyled, OpenInNewIconStyled, TextView } from './styles';
import Link from '@material-ui/core/Link';

function DetailsCard(props) {
  const moment = require('moment');

  const openInNewTab = url => {
    window.open(url, '_blank', 'noreferrer');
  };

  function convertToKm(distanceInMeters) {
    const distanceInKm = distanceInMeters / 1000;
    return distanceInKm.toFixed(0);
  }

  function formatDate(date) {
    const newDate = moment(date).format('DD/MM/YYYY - HH:mm:ss');
    return newDate;
  }

  function formatTime(seconds) {
    const totalMinutes = Math.floor(seconds / 60);
    const hours = Math.floor(totalMinutes / 60);
    return hours;
  }

  return (
    <>
      <CardStyled>
        {props?.load?.type === 'travel' ? (
          <Col md={12}>
            <Row md={12} lg={12} className="mt-2">
              <Col md={3}>
                <Text fontSize={12} weight={500}>
                  N° Viagem:
                  <br></br>
                  {props?.load?.travelId || 'Não informado'}
                </Text>
                <TextView
                  onClick={() => openInNewTab(`/viagens/${props.load.travelId}`)}
                >
                  Ver
                </TextView>
                <OpenInNewIconStyled></OpenInNewIconStyled>
              </Col>
              <Col md={3}>
                <Text fontSize={12} weight={500}>
                  Tipo veículo de tração:
                  <br></br>
                  {props?.load?.vehicles_types || 'Não informado'}
                </Text>
              </Col>
              <Col md={3}>
                <Text fontSize={12} weight={500}>
                  Tipo dos implementos:
                  <br></br>
                  {props?.load?.vehicles_implement_types || 'Não informado'}
                </Text>
              </Col>
              <Col md={3}>
                <Text fontSize={12} weight={500}>
                  Motorista:
                  <br></br>
                  {props?.load?.social_name || 'Não informado'}
                </Text>
              </Col>
            </Row>
            <Row md={12} lg={12} className="mt-2">
              <Col md={3}>
                <Text fontSize={12} weight={500}>
                  N° Viagem Externa:
                  <br></br>
                  {props?.load?.externalId || 'Não informado'}
                </Text>
              </Col>
              <Col md={3}>
                <Text fontSize={12} weight={500}>
                  Placa veículo de tração:
                  <br></br>
                  {props?.load?.plate || 'Não informado'}
                </Text>
              </Col>
              <Col md={3}>
                <Text fontSize={12} weight={500}>
                  Placa implementos:
                  <br></br>
                  {props?.load?.wagons_plate || 'Não informado'}
                </Text>
              </Col>
              <Col md={3}>
                <Text fontSize={12} weight={500}>
                  Telefone:
                  <br></br>
                </Text>
                <Link
                  href={`https://api.whatsapp.com/send?phone=55${props?.load?.phone?.replace(
                    /^(\+)|\D/g,
                    '$1'
                  )}`}
                  target="_blank"
                >
                  <TextView>{props?.load?.phone || 'Não informado'}</TextView>
                </Link>
              </Col>
            </Row>
            <Row md={12} className="mt-2">
              <Col md={3}>
                <Text fontSize={12} weight={500}>
                  UF inicio:
                  <br></br> {props?.load?.firstOriginProvince || 'Não informado'}
                </Text>
              </Col>
              <Col md={3}>
                <Text fontSize={12} weight={500}>
                  Cidade de Início:
                  <br></br> {props?.load?.firstOriginCity || 'Não informado'}
                </Text>
              </Col>
              <Col md={3}>
                <Text fontSize={12} weight={500}>
                  Data última posição:
                  <br></br>
                  {formatDate(props?.load?.captured_at) || 'Não informado'}
                </Text>
              </Col>
              <Col md={3}>
                <Text fontSize={12} weight={500}>
                  Tempo de chegada estimada:
                  <br></br>
                  {formatTime(props?.load?.approximate_arrival_time) + ' hrs' ||
                    'Não informado'}
                </Text>
              </Col>
            </Row>
            <Row md={12} className="mt-2">
              <Col md={3}>
                <Text fontSize={12} weight={500}>
                  UF destino:
                  <br></br>{' '}
                  {props?.load?.lastDestinationProvince || 'Não informado'}
                </Text>
              </Col>
              <Col md={3}>
                <Text fontSize={12} weight={500}>
                  Cidade destino:
                  <br></br> {props?.load?.lastDestinationCity || 'Não informado'}
                </Text>
              </Col>
              <Col md={3}>
                <Text fontSize={12} weight={500}>
                  Status atual viagem:
                  <br></br>
                  {props?.load?.name || 'Não informado'}
                </Text>
              </Col>
              <Col md={3}>
                <Text fontSize={12} weight={500}>
                  Cliente tomador:
                  <br></br>
                  {props?.load?.takerClient || 'Não informado'}
                </Text>
              </Col>
            </Row>
            <Row md={12} className="mt-2">
              <Col md={3}>
                <Text fontSize={12} weight={500}>
                  Distância total:
                  <br></br>
                  {convertToKm(props?.load?.total_distance_in_meters) + ' Kms' ||
                    'Não informado'}
                </Text>
              </Col>
              <Col md={3}>
                <Text fontSize={12} weight={500}>
                  Distância restante estimada:
                  <br></br>
                  {convertToKm(props?.load?.remaining_distance_in_meters) +
                    ' Kms' || 'Não informado'}
                </Text>
              </Col>
              <Col>
                <Text fontSize={12} weight={500}>
                  ́Localização última posição:
                  <br></br>
                  {props?.load?.description || 'Não informado'}
                </Text>
              </Col>
              <Col md={3}>
                <Text fontSize={12} weight={500}>
                  ́Temperatura 1:
                  <br></br>
                  {props?.load?.temperature1 && props?.load?.temperature1 !== 'N' ? props?.load?.temperature1 + '°C' : 'Não informado'}
                </Text>
              </Col>
              <Col md={3}>
                <Text fontSize={12} weight={500}>
                  ́Temperatura 2:
                  <br></br>
                  {props?.load?.temperature2 && props?.load?.temperature2 !== 'N' ? props?.load?.temperature2 + '°C' : 'Não informado'}
                </Text>
              </Col>
              <Col md={3}>
                <Text fontSize={12} weight={500}>
                  ́Temperatura 3:
                  <br></br>
                  {props?.load?.temperature3 && props?.load?.temperature3 !== 'N' ? props?.load?.temperature3 + '°C' : 'Não informado'}
                </Text>
              </Col>
              <Col md={3}>
                <Text fontSize={12} weight={500}>
                  ́Temperatura 4:
                  <br></br>
                  {props?.load?.temperature4 && props?.load?.temperature4 !== 'N' ? props?.load?.temperature4 + '°C' : 'Não informado'}
                </Text>
              </Col>
              <Col md={3}>
                <Text fontSize={12} weight={500}>
                  ́Media atual:
                  <br></br>
                  {props?.load?.averageTemperature && props?.load?.averageTemperature!== 'N' ? props?.load?.averageTemperature + '°C' : 'Não informado'}
                </Text>
              </Col>
            </Row>
          </Col>
        ) : (
          <Col md={12}>
            <Row md={12} lg={12} className="mt-2">
              <Text fontSize={14} weight={700}>
                {props?.load?.name}
              </Text>
            </Row>
            <Row md={12} lg={12}>
              <Text fontSize={12} weight={500}>
                {props?.load?.cgc}
              </Text>
            </Row>
            <Row md={12} lg={12} className="mt-2">
              <Text fontSize={12} weight={500}>
                Endereço:<br />
                {props?.load?.address && `${props?.load?.address}`}
                {props?.load?.number && `, ${props?.load?.number}`}
                {props?.load?.complement && ` - ${props?.load?.complement}`}
                {props?.load?.neighborhood && ` - ${props?.load?.neighborhood}`}
                {props?.load?.city && ` - ${props?.load?.city}`}
                {props?.load?.province && ` - ${props?.load?.province}`}
                {props?.load?.country && ` - ${props?.load?.country}`}
              </Text>
            </Row>
          </Col>
        )}
      </CardStyled>
    </>
  );
}

export default DetailsCard;
