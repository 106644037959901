const Yup = require('yup');

export const dataSchema = Yup.object().shape({
  justification: Yup.object()
    .required('Justificativa obrigatória')
    .typeError('Justificativa obrigatória'),

  obs: Yup.string().required('Observação obrigatória'),
});

export async function validateMarginSchema(data, setErrors) {
  const errorList = {};
  try {
    await dataSchema.validate(data, {
      abortEarly: false,
    });

    return true;
  } catch (err) {
    if (err.inner) {
      err.inner.forEach(error => {
        errorList[error.path] = error?.message;
      });
    }
    return false;
  } finally {
    setErrors(errorList);
  }
}
