import React, { useState } from 'react';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';

import { cooperplaceApi } from 'services/api';

import Modal from 'v3/components/Modal';
import Select from 'v3/components/Select';
import TextArea from 'v3/components/TextArea';
import DatePicker from 'v3/components/DatePicker';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import { useSnackbar } from 'v3/components/Snackbar';

import { useVehicle } from '../../context';

export const RenewMaintenancesModal = ({ isOpen, onClose, maintenance }) => {
  const vehicle = useVehicle();
  const snackbar = useSnackbar();
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState('');
  const [maintenanceDate, setMaintenanceDate] = useState('');
  const [maintenanceDueDate, setMaintenanceDueDate] = useState('');

  function isDateValid(value) {
    const date = moment(value, 'DD/MM/YYYY');
    return (
      date.isValid() && date.isAfter(moment(maintenanceDate, 'DD/MM/YYYY'))
    );
  }

  function handleDueDateChange(dateString) {
    setMaintenanceDueDate(dateString);
    dateString
      ? setErrors(prev => ({
          ...prev,
          maintenanceDueDate: !isDateValid(dateString)
            ? 'O vencimento não pode ser antes da data de realização da manutenção.'
            : null,
        }))
      : setErrors(prev => ({
          ...prev,
          maintenanceDueDate: null,
        }));
  }

  function handleDateChange(dateString) {
    setMaintenanceDate(dateString);
    dateString
      ? setErrors(prev => ({
          ...prev,
          maintenanceDate: !moment(dateString, 'DD/MM/YYYY').isValid()
            ? 'Digite uma data válida!'
            : moment(dateString, 'DD/MM/YYYY').isAfter(moment())
            ? 'Data de realização não pode ser posterior ao dia de hoje!'
            : null,
        }))
      : setErrors(prev => ({
          ...prev,
          maintenanceDate: null,
        }));
  }

  async function handleRenewMaintenance(event) {
    event.preventDefault();
    try {
      setLoading(true);
      const response = await cooperplaceApi.put(
        `/vehicles/maintenances/${maintenance.id}`,
        {
          vehicleType: maintenance.vehicleType,
          plate: maintenance.plate,
          description: description,
          date: moment(maintenanceDate, 'DD/MM/YYYY'),
          dueDate: moment(maintenanceDueDate, 'DD/MM/YYYY') || null,
        }
      );
      const message = response?.data?.message;
      snackbar.show(<Text>{message}</Text>, {
        type: 'success',
      });
      return;
    } catch (error) {
      snackbar.show(
        <Text>
          Ocorreu um erro ao tentar salvar manutenção. Tente novamente mais
          tarde.
        </Text>,
        {
          type: 'error',
        }
      );
      return;
    } finally {
      setDescription('');
      setMaintenanceDate('');
      setMaintenanceDueDate('');
      setLoading(false);
      onClose(true);
      setErrors({});
      vehicle.fetchVehicle();
    }
  }
  return (
    <>
      <Modal
        show={isOpen}
        onHide={onClose}
        heading={
          <Text color="dark" type="header">
            Renovar manutenção
          </Text>
        }
        body={
          <form onSubmit={handleRenewMaintenance}>
            <Row>
              <Col xs={12} md={12}>
                <Text type="regular" color="#464E5F" weight={500}>
                  Placa
                </Text>

                <Text type="regular" color="dark" as="p" className="mt-2">
                  {maintenance?.plate} -{' '}
                  {maintenance.vehicleType === 'MAIN'
                    ? 'Veículo Principal'
                    : 'Carreta'}
                </Text>
              </Col>
              <Col xs={6} md={6} className="mt-4">
                <DatePicker
                  label="Data de realização *"
                  value={maintenanceDate}
                  onChange={e => handleDateChange(e.target.value)}
                  error={errors?.maintenanceDate}
                />
              </Col>
              <Col xs={6} md={6} className="mt-4">
                <DatePicker
                  label="Data de vencimento"
                  value={maintenanceDueDate}
                  onChange={e => handleDueDateChange(e.target.value)}
                  error={errors?.maintenanceDueDate}
                  disabled={maintenanceDate === ''}
                />
              </Col>
              <Col xs={12} md={12} className="mt-4">
                <TextArea
                  label="Descrição *"
                  resizable={false}
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                />
              </Col>
              <Col className="d-flex justify-content-center mt-4">
                <Button
                  variant="primary"
                  disabled={description === '' || maintenanceDate === ''}
                  loading={loading}
                >
                  <Text color="white" weight={500} type="regular">
                    Salvar
                  </Text>
                </Button>
              </Col>
            </Row>
          </form>
        }
      ></Modal>
    </>
  );
};
