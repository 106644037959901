import styled from 'styled-components';
import theme from 'v3/theme';
import Text from '../Text';

export const TravelOccurrencesListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const OccurrencesListContainer = styled.div`
  max-height: 10rem;
  overflow: scroll;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  border: 1px solid #d1d7dc;
  border-radius: 6px;
  padding: 0.25rem 0.5rem;
  :not(:hover)::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
  :not(:hover)::-webkit-scrollbar-track {
    opacity: 0;
  }
  :not(:hover)::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
  }
  :hover::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
  :hover::-webkit-scrollbar-track {
    background: ${theme.colors.light_gray};
    opacity: 0.5;
  }
  :hover::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border-radius: 10px;
  }
`;

export const OccurrenceListItemContainer = styled.div`
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  gap: 0.25rem;
  align-items: center;
  background: ${props => (props.selected ? '#E8E8E8' : 'transparent')};
  padding: 0.25rem;
  border-radius: 6px;
  cursor: ${props =>
    props.clickable ? 'pointer' : props.list ? 'help' : 'not-allowed'};

  & > svg {
    color: ${props =>
      props.finished ? props.theme.colors.success : props.theme.colors.orange};
    min-width: 1rem;
  }

  & > span {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  & > p:last-child {
    margin-left: auto;
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const StyledTotalPendencyOccurrences = styled.span`
  padding: 0.25rem 0.5rem;
  background: ${props => props.theme.colors.orange};
  color: white;
  border-radius: 6px;
`;
