import React from 'react';
import { Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Button, Card, Fallback, Text } from 'v3/components';
import Space from 'v3/components/Space';
import ApplicationLayout from 'v3/layouts/Application';
import { usePermission } from 'hooks';
import { Header } from './components/Header';
import { PolicyRangesContainer, SectionHeader } from '../styles';
import { RegisterSection } from './components/ranges/Register';
import { usePolicyRegister } from './context';
import { MAX_OP } from './utils';
import { InsertButton } from './components/ranges/InsertButton';

export function RegisterPolicy() {
  const history = useHistory();
  const {
    loading,
    handleCreate,
    hasRangeValueError,
    operationModal,
    operations,
    errors,
    policyClients,
  } = usePolicyRegister();
  usePermission('SALVAR_APOLICE', { redirect: true });

  return (
    <ApplicationLayout
      title="Apólice"
      RightComponent={
        <Space x="0.5rem">
          <Button
            variant="secondary"
            onClick={() => history.push('/apolices')}
            disabled={loading}
          >
            <Text weight={500} color="black" type="regular">
              Cancelar
            </Text>
          </Button>

          <Button
            variant="success"
            onClick={() => handleCreate()}
            disabled={hasRangeValueError || loading}
            loading={loading}
          >
            <Text weight={500} color="white" type="regular">
              Salvar
            </Text>
          </Button>
        </Space>
      }
    >
      <Card
        loading={loading}
        header={
          <div style={{ width: '100%', textAlign: 'center' }}>
            <Text type="header" color="dark">
              Composição Apólice
            </Text>
          </div>
        }
      >
        <Header />

        <PolicyRangesContainer>
          <SectionHeader>
            <Text weight={500} color="dark" type="regular">
              Operações
            </Text>
            <InsertButton
              title={`${
                operations?.length === MAX_OP
                  ? 'Limite máximo de operações atingido'
                  : policyClients?.length === 0 || !policyClients
                  ? 'Informe primeiro dados dos tomadores'
                  : 'Clique para inserir uma nova operação'
              }`}
              label=" Inserir operação"
              onClick={() => operationModal.open()}
              disabled={
                operations?.length === MAX_OP ||
                !policyClients ||
                policyClients?.length === 0
              }
            />
          </SectionHeader>
          <Fallback
            fall={operations?.length === 0 || errors?.operations}
            component={
              <Col
                xs={12}
                className="pt-5 d-flex justify-content-center align-items-center"
              >
                {errors?.operations ? (
                  <Text weight={500} color="danger">
                    Informe no mínimo uma operação
                  </Text>
                ) : (
                  <Button size="md" disabled>
                    Não há operações cadastradas
                  </Button>
                )}
              </Col>
            }
          >
            <RegisterSection />
          </Fallback>
        </PolicyRangesContainer>
      </Card>
    </ApplicationLayout>
  );
}
