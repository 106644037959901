import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useParams } from 'react-router-dom';

import Select from 'v3/components/Select';

import api from 'services/api';

import { useShippingCompany } from '../context';

export default function ShippingCompanyPaymentMethod({ error }) {
  const shippingCompany = useShippingCompany();
  const params = useParams();

  const [paymentMethodList, setPaymentMethodList] = useState();

  useEffect(() => {
    if (!params.id) {
      shippingCompany.setData({
        paymentMethod: null,
      });
    } else {
      //
    }
  }, []);

  async function fetchPaymentMethodList() {
    try {
      const response = await api.get('payment/methods?initialFetch=true');

      setPaymentMethodList(response.data);
    } catch (err) {
      // error
    }
  }

  useEffect(() => {
    fetchPaymentMethodList();
  }, []);

  async function fetchPaymentMethods(search) {
    try {
      const response = await api.get('payment/methods', { params: { search } });
      return response.data;
    } catch (ex) {
      return [];
    }
  }

  return (
    <Row className="form">
      <Col md={12}>
        <Select.Async
          options={paymentMethodList}
          label="Método de pagamento"
          onSearch={fetchPaymentMethods}
          value={shippingCompany.data?.shippingCompanies?.payment}
          onChange={value => {
            shippingCompany.setData({
              shippingCompanies: {
                ...shippingCompany.data.shippingCompanies,
                payment: value,
              },
            });
          }}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          error={error}
        />
      </Col>
    </Row>
  );
}
