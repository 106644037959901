import React from 'react';
import { Row } from 'react-bootstrap';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  SortableAnswerColContainer,
  SortableAnswerHandle,
  SortableAnswerRemoveButton,
} from './styles';
import { Input } from 'v3/components';
import { LOGICAL_QUESTION_SLUG } from '../../shared/utils';

export function SortableAnswer({
  answer,
  index,
  setQuestion,
  question,
  handleRemoveAnswer,
  questionType,
  errors,
}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: answer?.id });
  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
  };
  function handleOnChange(value, index) {
    const updatedAnswers = question?.answers?.map((item, idx) => {
      if (idx === index) {
        return {
          ...item,
          description: value,
        };
      } else return item;
    });
    setQuestion(old => ({ ...old, answers: [...updatedAnswers] }));
  }

  return (
    <Row ref={setNodeRef} style={style} className="my-2">
      <SortableAnswerColContainer md={10} sm={12}>
        <SortableAnswerHandle
          {...attributes}
          {...listeners}
          title="Clique e arraste para ordenar"
        >
          <svg viewBox="0 0 20 20" width="20" transform="rotate(90)">
            <path d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z"></path>
          </svg>
        </SortableAnswerHandle>
        <Input
          value={answer?.description}
          onChange={e => handleOnChange(e.target.value, index)}
          disabled={questionType?.slug === LOGICAL_QUESTION_SLUG}
          placeholder={'Informe opção resposta'}
          error={errors?.[`question.answers[${index}].description`]}
        />
        <SortableAnswerRemoveButton
          variant="error"
          icon="FaTrash"
          color="white"
          title="Remover alternativa"
          onClick={() => handleRemoveAnswer(index, question?.answers)}
          disabled={questionType?.slug === LOGICAL_QUESTION_SLUG}
        />
      </SortableAnswerColContainer>
    </Row>
  );
}
