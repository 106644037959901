export const driverDocumentTypes = [
  { label: 'OUTROS_DOCUMENTOS', value: 1 },
  { label: 'RG', value: 2 },
  { label: 'LIBERACAO_GR', value: 3 },
  { label: 'COMPROVANTE_ENDERECO', value: 4 },
  { label: 'CNH', value: 5 },
  { label: 'CARTOES', value: 6 },
];

export const shippingCompanyDocumentTypes = [
  { label: 'ALVARA SANITARIO', value: 1 },
  { label: 'ALVARA FUNCIONAMENTO', value: 2 },
  { label: 'DEDETIZACAO', value: 3 },
  { label: 'CONSULTA ANTT', value: 4 },
  { label: 'EXTRATO ANTT', value: 5 },
  { label: 'COMPROVANTE DE ENDERECO', value: 6 },
  { label: 'CRV VEICULOS', value: 7 },
];

export const shippingCompanyDocumentTypesInBrazilianPortuguese = {
  ALVARA_SANITARIO: 'ALVARÁ SANITÁRIO',
  ALVARA_FUNCIONAMENTO: 'ALVARÁ FUNCIONAMENTO',
  DEDETIZACAO: 'DEDETIZAÇÃO',
  CONSULTA_ANTT: 'CONSULTA ANTT',
  EXTRATO_ANTT: 'EXTRATO ANTT',
  COMPROVANTE_DE_ENDERECO: 'COMPROVANTE DE ENDEREÇO',
  CRV_VEICULOS: 'CRV VEÍCULOS',
};

export const vehicleDocumentTypes = [
  { label: 'LICENCIAMENTO', value: 1 },
  { label: 'TACOGRAFO', value: 2 },
  { label: 'DEDETIZACAO', value: 3 },
  { label: 'PERMISSO', value: 4 },
];

export const vehicleDocumentTypesInBrazilianPortuguese = {
  LICENCIAMENTO: 'LICENCIAMENTO',
  TACOGRAFO: 'TACÓGRAFO',
  DEDETIZACAO: 'DEDETIZAÇÃO',
  PERMISSO: 'PERMISSO',
};
export const driverDocumentTypesConst = {
  OUTROS_DOCUMENTOS: 'OUTROS DOCUMENTOS',
  RG: 'RG',
  LIBERACAO_GR: 'LIBERAÇÃO GR',
  COMPROVANTE_ENDERECO: 'COMPROVANTE ENDEREÇO',
};

export const wagonsDocumentTypes = [
  { label: 'LICENCIAMENTO', value: 1 },
  { label: 'LIBERAÇÃO', value: 2 },
  { label: 'OUTROS', value: 3 },
];
