import React, { useContext, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Text from 'v3/components/Text';
import { useSnackbar } from 'v3/components/Snackbar';
import api from 'services/api';
import { HiOutlineExternalLink } from 'react-icons/hi';
import { UserContext } from 'contexts/UserContext';
import { useUser } from '../shared/context';
import { validate } from '../shared/validator';
import SharedData from '../shared/Section';
import Inputs from '../shared/Data';
import Editable from '../shared/Editable';

export default function Data() {
  const { getCompaniesUser } = useContext(UserContext);
  const user = useUser();
  const snackbar = useSnackbar();
  const [isEditing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  async function saveData() {
    setLoading(true);

    try {
      const isValid = await validate(
        'data',
        { data: user.data },
        user.setErrors
      );

      if (!isValid) {
        snackbar.show(<Text>Verifique os campos obrigatórios</Text>, {
          type: 'error',
        });
        setLoading(false);
        return;
      }

      // Se for motorista
      if (user.data.profile.name === 'Motorista') {
        user.data.groups = null;
        if (!user.data.cpf) {
          snackbar.show(<Text>CPF Obrigatório para Perfil de Motorista</Text>, {
            type: 'error',
          });
          return;
        }
      } else {
        // Caso não seja perfil de motorista, então não tem CPF
        user.data.cpf = null;
      }
      const payload = {
        profile_id: user.data.profile?.id,
        username: user.data.username,
        phonenumber: user.data?.phonenumber?.match(/\d+/g)
          ? user.data?.phonenumber
          : null,
        cpf: user.data.cpf,
        active: user.data.active,
        groups: user.data.groups
          ? user.data.groups.map(groups => groups.id)
          : [],
        costCenters: user.data.costCenters
          ? user.data.costCenters.map(cc => cc.id)
          : [],
        branchOfficesId: user?.data?.branchOffices?.reduce((acc, cur) => {
          acc.push(cur.id);
          return acc;
        }, []),
        tags: user.data.tags ? user.data.tags.map(tag => tag.id) : [],
        companiesId: user.data?.companies.map(company => company.id),
      };

      await api.put(`v3/user/${user.data.id}/data`, payload);
      snackbar.show(<Text>Salvo com sucesso</Text>, { type: 'success' });
      setEditing(false);

      user.updatePrevUser();
      getCompaniesUser();
      await user.fetchUser();
    } catch (error) {
      if (error?.response?.data?.length) {
        const formattedErrors = error.response.data.reduce((acc, err) => {
          return { ...acc, [`data.${err.field}`]: err.message };
        }, {});

        user.setErrors(formattedErrors);
      }

      snackbar.show(<Text>Erro ao salvar dados</Text>, { type: 'error' });
    } finally {
      setLoading(false);
    }
  }

  return (
    <SharedData
      Title="Dados do usuário"
      HeaderRightComponent={
        <Editable
          isEditing={isEditing}
          setEditing={setEditing}
          onSave={saveData}
          isLoading={loading}
          disabled={!user.hasPermissionToEdit}
        />
      }
      loading={user.isFetching}
    >
      {isEditing ? (
        <Inputs />
      ) : (
        <Row>
          <Col xs={12}>
            <Text type="label" color="#464E5F">
              Perfil do usuário
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {user.data?.profile?.name || 'Não informado'}
            </Text>
          </Col>
          <Col xs={12}>
            <Text type="label" color="#464E5F">
              Nome
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {user?.data?.profile?.name === 'Motorista' &&
              user?.data?.person?.id ? (
                <Link to={`/motoristas/${user.data?.person?.id}`}>
                  <Text type="regular" color="light_blue">
                    {user?.data?.username}
                  </Text>
                  <HiOutlineExternalLink size={12} className="ml-2" />
                </Link>
              ) : (
                user.data?.username || 'Não informado'
              )}
            </Text>
          </Col>
          {user?.data?.profile?.name === 'Motorista' && (
            <Col xs={12}>
              <Text type="label" color="#464E5F">
                Telefone
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {user.data?.phonenumber?.match(/\d+/g)
                  ? user.data?.phonenumber
                  : 'Não informado'}
              </Text>
            </Col>
          )}
          {user?.data?.profile?.name === 'Motorista' && (
            <Col xs={12}>
              <Text type="label" color="#464E5F">
                CPF
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {user?.data?.cpf || 'Não informado'}
              </Text>
            </Col>
          )}
          {(user?.data?.companies?.length > 0 || user.data?.company?.name) && (
            <Col xs={12}>
              <Text type="label" color="#464E5F">
                {user?.data?.companies?.length > 1 ? 'Empresas' : 'Empresa'}
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {user.data?.companies.length > 0
                  ? user.data?.companies.map(company => company.name).join(', ')
                  : user.data?.company?.name}
              </Text>
            </Col>
          )}
          {user?.data?.profile?.name !== 'Motorista' &&
            user.data?.branchOffices?.length > 0 && (
              <Col xs={12}>
                <Text type="label" color="#464E5F">
                  Filiais
                </Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {user.data?.branchOffices
                    .map(branchOffice => branchOffice.name)
                    .join(', ')}
                </Text>
              </Col>
            )}
          <Col xs={12}>
            <Text type="label" color="#464E5F">
              Centros de custo
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {user.data?.costCenters?.map(cc => cc.name).join(', ') ||
                'Não informado'}
            </Text>
          </Col>
          {user.data?.profile?.id !== 2 && user.data?.groups?.length > 0 && (
            <Col xs={12}>
              <Text type="label" color="#464E5F">
                Grupos de usuário
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {user.data.groups.map(group => group.name).join(', ')}
              </Text>
            </Col>
          )}
          {user.data?.tags?.length > 0 && (
            <Col xs={12}>
              <Text type="label" color="#464E5F">
                Tags
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {user.data.tags.map(tag => tag.name).join(', ')}
              </Text>
            </Col>
          )}
          <Col xs={12}>
            <Text type="label" color="dark">
              Status
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {user.data.active ? 'Ativo' : 'Inativo'}
            </Text>
          </Col>
        </Row>
      )}
    </SharedData>
  );
}
