import React, { useState } from 'react';
import { Button, Text } from 'v3/components';
import { useSnackbar } from 'v3/components/Snackbar';
import apiReport from 'services/apis/report';

import FileSaver from 'file-saver';

export default function ContractPendingMessage({ vehicleIds, travelIds }) {
  const snackbar = useSnackbar();

  const [loading, setLoading] = useState(false);

  async function handleClick() {
    setLoading(true);
    try {
      const response = await apiReport.post(
        '/pending-contracts/report',
        {
          vehicleIds: vehicleIds ? vehicleIds.join(';') : undefined,
          travelIds: travelIds ? travelIds.join(';') : undefined,
        },
        {
          responseType: 'blob',
        }
      );
      FileSaver.saveAs(response.data, response.headers['x-file-name']);
    } catch (err) {
      snackbar.show(
        <Text>Falha ao gerar relatório de viagens sem contratos</Text>,
        {
          type: 'error',
          duration: 5000,
        }
      );
    }
    setLoading(false);
  }

  return (
    <>
      <Text>
        <b>Atenção!</b> Viagens exibidas apresentam valores de margem e
        rentabilidade inconsistentes. Importe manualmente os contratos para
        garantir a precisão das informações. Para identificar as viagens que não
        possuem contratos originados do sistema benner clique no botão exportar.
      </Text>
      <Text className="d-block text-right mt-2">
        <Button size="sm" onClick={handleClick} loading={loading}>
          {loading ? 'Baixando arquivo, aguarde...' : 'Exportar'}
        </Button>
      </Text>
    </>
  );
}
