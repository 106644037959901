import styled, { css } from 'styled-components';

import { Select as MaterialSelect } from '@material-ui/core';

import { withoutProps } from 'services/utils';

export const StyledSelect = styled(
  withoutProps('disableOutline', 'disableArrow')(MaterialSelect)
)`
  width: 100%;
  height: 40px;
  box-sizing: border-box;

  > div {
    color: ${({ theme }) => theme.colors.secondary};

    padding: 10px;

    border-radius: ${({ theme }) => theme.borderRadius.input} !important;
    border: 1px solid #e2e5ec;

    transition: border 0.3s ease;

    &:hover {
      border: 1px solid ${props => props.theme.colors.secondary};
    }

    :focus {
      background-color: transparent;
    }

    ${({ disableOutline }) =>
      disableOutline &&
      css`
        border: none;

        &:hover {
          border: none;
        }
      `}
  }

  .MuiSvgIcon-root {
    right: 10px;

    ${({ disableArrow }) =>
      disableArrow &&
      css`
        visibility: hidden;
      `}
  }

  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ItemTitle = styled.p`
  color: ${({ theme }) => theme.colors.secondary};
  text-overflow: ellipsis;
  overflow: hidden;

  margin-bottom: 0;
  margin-top: 0;

  width: 100%;

  ${({ companySelector }) =>
    companySelector &&
    css`
      text-decoration: underline;
      text-transform: uppercase;
    `}

  ${({ noUnderline }) =>
    noUnderline &&
    css`
      text-decoration: none;
    `}
`;

export const ItemSubtitle = styled.span`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 0.9rem;

  margin-top: 5px;
`;
