import React from 'react';

import { Text } from 'v3/components';
import { formatDateTime } from 'v3/utils/formatter';
import { SectionContainer } from '../../styles';
import {
  DescriptionItemContainer,
  OccurrenceDescriptionsContainer,
} from '../styles';

export function OccurrenceDescriptions({
  descriptions,
  label = ' Descrição Ocorrência',
}) {
  return (
    <SectionContainer>
      <Text weight={500} type="header" color="dark">
        {label}
      </Text>
      <OccurrenceDescriptionsContainer>
        {descriptions?.map(description => {
          return (
            <DescriptionItemContainer>
              <span>
                <Text
                  type="regular"
                  color="dark"
                  weight={500}
                >{`${formatDateTime(description?.createdAt)} ${
                  description?.fullUserCreated?.username ??
                  description?.fullUserCreatedUsername
                }: `}</Text>
                <Text
                  type="regular"
                  color="dark"
                  weight={400}
                  style={{ whiteSpace: 'pre-line' }}
                >
                  {description.description}
                </Text>
              </span>
              <span>
                <Text type="regular" color="dark" weight={500}>{`Log ${
                  description?.fullStatus?.name ?? description?.fullStatusName
                }: `}</Text>
                <Text type="regular" color="dark" weight={400}>
                  {description?.log}
                </Text>
              </span>
            </DescriptionItemContainer>
          );
        })}
      </OccurrenceDescriptionsContainer>
    </SectionContainer>
  );
}
