
import styled from 'styled-components';
import BootstrapNavDropdown from 'react-bootstrap/NavDropdown';

export const StyledCalculadorMenu = styled(BootstrapNavDropdown)`
  padding: 0;
  &.nav-item > a {
    padding: 0;
    &:after {
      display: none;
    }
  }
  .dropdown-menu {
    z-index: 995;
    &:before {
      content: '';
      position: absolute;
      top: 14px;
      width: 12px;
      height: 12px;
      background-color: white;
      transform: rotate(45deg);
    }

    &[data-popper-placement='right-start'] {
      &:before {
        left: -6px;
        border-left: 1px solid rgba(0, 0, 0, 0.15);
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      }
    }

    &[data-popper-placement='left-start'] {
      &:before {
        right: -6px;
        border-right: 1px solid rgba(0, 0, 0, 0.15);
        border-top: 1px solid rgba(0, 0, 0, 0.15);
      }
    }
  }

  .dropdown-menu {
    padding: 0;
  }
`;

export const StyledCalculador = styled.form`
  position: relative;
  padding: 8px;
  width: 240px;
`;
