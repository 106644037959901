/* eslint-disable no-restricted-globals */
/* eslint-disable consistent-return */
import React, { useState, useEffect, useMemo } from 'react';

import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import api from 'services/api';
import useQuery from 'hooks/useQuery';
import {
  CNPJ_DIGITS_LENGTH,
  CNPJ_MASK,
  CPF_DIGITS_LENGTH,
  CPF_MASK,
  onlyDigits,
} from 'utils/masks';
import { validatePermission } from 'actions/index';

import Select from 'v3/components/Select';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import Input from 'v3/components/Input';

function Filter({ onChange }) {
  const query = useQuery();
  const history = useHistory();

  const [filter, setFilter] = useState({});
  const [syncedTags, setSyncedTags] = useState(false);
  const hasPermissionToSelectTags = useMemo(
    () => validatePermission('SELECIONAR_TAGS'),
    []
  );

  function syncTags(tags) {
    query.delete('tag_name');
    query.delete('tag_id');

    if (tags) {
      tags.forEach(tag => {
        query.append('tag_id', tag.id);
        query.append('tag_name', tag.name);
      });
    }

    setFilter(old => ({ ...old, tags }));
  }

  async function getQuery() {
    const response = await api.get('user-tags');

    if (response.data) {
      const mappedTags = response.data.map(tag => ({
        id: tag.tag_id,
        name: tag.name?.name,
      }));

      syncTags(mappedTags);
    }

    if (query.has('tag_id') && query.has('tag_name')) {
      const tag_id = query.getAll('tag_id');
      const tag_name = query.getAll('tag_name');

      const mappedTags = tag_id.map((x, i) => {
        return {
          id: x,
          name: tag_name[i],
        };
      });

      setFilter(old => ({
        ...old,
        tags: mappedTags,
      }));
    }

    if (query.has('search')) {
      const search = query.get('search');
      setFilter(old => ({ ...old, search }));
    }
    setTimeout(() => {
      setSyncedTags(true);
    }, 300);
  }

  useEffect(() => {
    getQuery();
  }, []);

  useEffect(() => {
    onChange();
  }, [syncedTags]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      history.replace({ search: query.toString() });
    }, 150);

    return () => {
      clearTimeout(timeout);
    };
  }, [filter]);

  function applyMask(value) {
    if (onlyDigits(value).length === CNPJ_DIGITS_LENGTH) {
      return CNPJ_MASK;
    }

    if (onlyDigits(value).length === CPF_DIGITS_LENGTH) {
      return CPF_MASK;
    }

    return false;
  }

  async function fetchTags(search) {
    try {
      const response = await api.get('tags', { params: { search } });
      return response.data.data;
    } catch (ex) {
      return [];
    }
  }

  return (
    <Row
      className="mb-4 ml-3 d-flex align-items-center"
      style={{ width: '97%' }}
    >
      <Col md={6} xs={12} style={{ marginLeft: 4 }}>
        <Input
          label="Cliente"
          labelColor="#fff"
          value={filter.search}
          placeholder="Nome, Razão social, CPF, CNPJ ou Email"
          onChange={({ target }) => {
            if (target.value === null || target.value === '') {
              query.delete('search');
            } else {
              query.set('search', target.value);
            }
            setFilter(old => ({ ...old, search: target.value }));
          }}
          masked
          mask={applyMask}
          guide={false}
        />
      </Col>
      <Col md={5} xs={12} style={{ marginLeft: -2 }}>
        <Select.Async
          multiple
          label="Tags"
          modalHeading="Adicione uma tag"
          modalBodyTitle="Tags:"
          onSearch={fetchTags}
          value={filter?.tags}
          horizontal
          onChange={value => syncTags(value)}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          labelColor="#fff"
          isDisabled={!hasPermissionToSelectTags}
        />
      </Col>

      <Col md={1} style={{ marginTop: 30, marginRight: -6 }}>
        <Button
          variant="success"
          onClick={() => {
            onChange();
          }}
          className="py-2 p-0"
          style={{ width: 75 }}
        >
          <Text type="regular" weight={500}>
            Filtrar
          </Text>
        </Button>
      </Col>
    </Row>
  );
}

export default Filter;
