import api from 'services/api';

export async function save({ password, verified, driver, phone, name }) {
  const data = {
    password,
    verified,
    phone,
    name,
  };

  return api.put(`driver/updateUserData/${driver.cgccpf}`, data);
}

export function validate({ password, confirmPassword, setErrors }) {
  const err = {};

  if (!password || !confirmPassword) {
    err.password = 'Digite uma senha';
    err.confirmPassword = 'Digite uma senha';
    err.hasErrors = true;
  }

  if (password !== confirmPassword) {
    err.confirmPassword = 'As senhas não são iguais';
    err.hasErrors = true;
  }
  setErrors(err);
  return !Object.keys(err).length;
}
