/* eslint-disable import/no-unresolved */
import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import api from 'services/api';
import Fallback from 'v3/components/Fallback';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import theme from 'v3/theme';
import { useSnackbar } from 'v3/components/Snackbar';
import { useTravel } from 'v3/pages/Travel';
import { BlockedTravelContext } from 'contexts/BlockedTravelProvider';
import { GRAttachmentsModal } from './AttachmentModal';
import { Attachment } from './Attachment';

export function GRAttachments({
  attachments,
  setAttachments,
  permissionToUploadGRAttachment,
}) {
  const { isTravelBlocked } = useContext(BlockedTravelContext);
  const params = useParams();
  const travel = useTravel();

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const snackbar = useSnackbar();

  async function fetchAttachments() {
    try {
      setLoading(true);
      const response = await api.get(`travel/${params.id}/attachments`);
      setAttachments(
        response.data.filter(attachment => attachment.type === 'rotograma')
      );
    } catch (error) {
      setAttachments([]);
    } finally {
      setLoading(false);
    }
  }

  async function removeAttachment(attachment) {
    try {
      setLoading(true);
      await api.delete(`travel/attachment/${attachment.id}`);
      snackbar.show(
        <Text weight={500} color="dark">
          Rotograma excluído com sucesso
        </Text>,
        {
          type: 'success',
        }
      );

      travel.data.rotogramaFirstVisualization = null;

      await fetchAttachments();
    } catch (error) {
      snackbar.show(
        <Text weight={500} color="dark">
          Não foi possível excluir o anexo
        </Text>,
        { type: 'error' }
      );
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchAttachments();
  }, []);

  return (
    <>
      <GRAttachmentsModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        fetchAttachments={fetchAttachments}
        setAttachments={setAttachments}
        attachments={attachments}
      />
      <Col xs={12}>
        <Fallback
          component={
            <Col xs={12} className="d-flex flex-column align-items-center">
              <Text color={theme.colors.gray} type="label" as="p">
                Não há rotograma cadastrado para essa viagem.
              </Text>
              {permissionToUploadGRAttachment ? (
                <Button
                  onClick={() => setShowModal(true)}
                  size="sm"
                  disabled={
                    !travel.data.driver ||
                    isTravelBlocked ||
                    travel?.isDisabledGROAnalysis
                  }
                  title={
                    !travel.data.driver
                      ? 'Necessário adicionar motorista na viagem'
                      : travel?.isDisabledGROAnalysis
                      ? 'Não é possível edição durante período de análise GRO'
                      : 'Anexar rotograma na viagem'
                  }
                >
                  Adicionar
                </Button>
              ) : null}
            </Col>
          }
          fall={!attachments?.length}
        >
          <Row>
            {attachments?.map(attachment => {
              return (
                <Col md={4}>
                  <Attachment
                    attachment={attachment}
                    attachments={attachments}
                    permissionToUploadGRAttachment={
                      permissionToUploadGRAttachment
                    }
                    removeAttachment={removeAttachment}
                    loading={loading}
                    disabled={travel?.isDisabledGROAnalysis}
                  />
                  <Text type="regular" className="mt-2">
                    {attachment?.description}
                  </Text>
                </Col>
              );
            })}
          </Row>
        </Fallback>
      </Col>
    </>
  );
}
