import React, { useEffect, useState } from 'react';

import { Text, Button, Modal } from 'v3/components';
import Upload from 'v3/components/Upload';

export function MarginLiberationAttachmentModal({
  isOpen,
  handleClose,
  setFile,
  file,
}) {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  async function addAttachment() {
    handleClose();
  }

  function onFileChanged({ target }, hasError) {
    setFile([]);
    setErrors({});
    if (hasError) {
      setErrors({ file: 'O arquivo selecionado é maior que 1MB' });
      return;
    }
    setFile(
      [...target.files].map(file => {
        return {
          blob: file,
          size: file?.size,
          name: file?.name,
          type: file?.type,
          tempPath: URL.createObjectURL(file),
        };
      })
    );
  }
  return (
    <Modal
      show={isOpen}
      handleClose={handleClose}
      heading={
        <Text type="subtitle" color="dark" weight="400">
          Anexo
        </Text>
      }
      body={
        <div>
          <div className="mb-3">
            <Text>Adicione um arquivo</Text>
            <Upload
              name="file"
              label="Arquivo máximo de 1MB"
              accept=".jpg,.jpeg,.png,.pdf, .xlsx, .xls"
              multiple
              ignoreValidation
              value={file}
              onChange={onFileChanged}
              error={errors.file}
            />
          </div>
          <div className="d-flex flex-column align-items-center mt-3">
            <Button
              loading={loading}
              onClick={addAttachment}
              disabled={!file.length}
            >
              Adicionar
            </Button>
          </div>
        </div>
      }
    />
  );
}
