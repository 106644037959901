/* eslint-disable import/no-unresolved */
import React, { useMemo, useState } from 'react';
import { FaSearch, FaDownload } from 'react-icons/fa';

import { capitalize, formatDateTime } from 'v3/utils/formatter';

import Text from 'v3/components/Text';
import Modal from 'v3/components/Modal';
import PDFView from 'v3/components/PDFView';
import { AttachmentDetailsContainer } from 'v3/pages/Travel/Details/Documents/Attachment/styles';
import { Backdrop, DateTime } from './styles';

const AttachmentLabel = {
  accept: 'Aceito por: ',
  reject: 'Rejeitado por: ',
  resend: 'Solicitado reenvio por: ',
};

export function AttachmentComponent({ document, onClickPreview }) {
  const [isHovering, setHovering] = useState(false);
  const [isShowingModal, setShowingModal] = useState(false);

  const attachment = useMemo(
    () => ({
      ...document.attachments[0],
      obs: document.obs_data,
      nf_number: document.nf_number,
    }),
    [document]
  );

  function handleClick() {
    if (document.isDocument) {
      setShowingModal(true);
    } else {
      onClickPreview();
    }
  }

  return (
    <div>
      <Modal
        show={isShowingModal}
        onHide={() => setShowingModal(false)}
        dialogClassName="modal-90w"
        heading={
          <Text color="dark" type="header" weight="500">
            Visualizar PDF
          </Text>
        }
        body={<PDFView file={attachment.path} />}
      />
      {!!attachment.nf_number && (
        <Text type="label" color="dark">
          Nota fiscal {attachment.nf_number}
        </Text>
      )}
      <div
        className="position-relative"
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        style={{
          width: 270,
          height: 160,
          background: `url(${
            document.isDocument ? '/images/default-doc.png' : attachment.path
          }) center center`,
          backgroundSize: '100%',
        }}
      >
        {isHovering && (
          <Backdrop>
            <button type="button" onClick={handleClick}>
              <FaSearch color="#fff" size={32} />
            </button>
            <a href={attachment.path} download>
              <FaDownload color="#fff" size={32} />
            </a>
          </Backdrop>
        )}
        <DateTime>
          <Text type="label" color="white">
            {formatDateTime(document.created_at)}
          </Text>
        </DateTime>
      </div>

      <AttachmentDetailsContainer>
        <Text type="label" color="dark">
          Incluído por: {attachment?.userCreated?.username}
        </Text>
        {attachment?.userUpdated && attachment?.status !== 'pending' && (
          <Text type="label" color="dark">
            {AttachmentLabel[attachment.status]}
            {capitalize(attachment?.userUpdated?.username)} em{' '}
            {formatDateTime(attachment?.updated_at)}
          </Text>
        )}
      </AttachmentDetailsContainer>
    </div>
  );
}
