import { Row } from "react-bootstrap";
import styled from 'styled-components';
import { Button } from "v3/components";
import { ListItem } from '@material-ui/core';

export const RowStyled = styled(Row)`
  margin-top: 3%;
`;

export const ButtonNewPanel = styled(Button)`
margin-top: 2em;
margin-left: 2em;
`;

export const ScrollDiv = styled.div`
overflow-y: auto;
max-height: calc(90vh - 280px);
width: 100%;
`

export const ButtonStyled = styled(Button)`
  margin-right: 0.5rem;
`;

