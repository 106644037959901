import React, { useState, useEffect } from 'react';

import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import api from 'services/api';

import {
  fetchBranchOffices,
  fetchUserCompaniesSelectOptions,
  fetchUserCostCentersByCompany,
} from 'utils/fetches';

import {
  Text,
  Select,
  Modal,
  Button,
  Toggle,
  DatePicker,
  Radio,
} from 'v3/components';
import { DefaultLoadingComponent } from 'v3/components/List';
import { AppConfigContext } from 'antd/es/app/context';

const DATE_OPTIONS = [
  {
    label: '1 dia',
    value: moment().subtract(1, 'days').startOf('day').toISOString(),
  },
  {
    label: '3 dias',
    value: moment().subtract(3, 'days').startOf('day').toISOString(),
  },
  {
    label: '7 dias',
    value: moment().subtract(7, 'days').startOf('day').toISOString(),
  },
  {
    label: '15 dias',
    value: moment().subtract(15, 'days').startOf('day').toISOString(),
  },
  {
    label: '30 dias',
    value: moment().subtract(30, 'days').startOf('day').toISOString(),
  },
  {
    label: '60 dias',
    value: moment().subtract(60, 'days').startOf('day').toISOString(),
  },
];

const MERCOSUL_DATE_OPTIONS = [
  {
    label: '1 dias',
    value: moment().add(1, 'days').startOf('day').toISOString(),
  },
  {
    label: '3 dias',
    value: moment().add(3, 'days').startOf('day').toISOString(),
  },
  {
    label: '7 dias',
    value: moment().add(7, 'days').startOf('day').toISOString(),
  },
  {
    label: '15 dias',
    value: moment().add(15, 'days').startOf('day').toISOString(),
  },
  {
    label: '30 dias',
    value: moment().add(30, 'days').startOf('day').toISOString(),
  },
  {
    label: '60 dias',
    value: moment().add(60, 'days').startOf('day').toISOString(),
  },
];

function Filter({
  isOpen,
  onClose,
  dashboard,
  name,
  values,
  id,
  setValues,
  mercosulDashboard = false,
  excludeFilterByDate = false,
  excludeFilterByNegotiator = false,
  excludeFilterByCompany = false,
  filterByCompanyMultiple = true,
  onlyCompanyFilter = false,
  excludeFilterByClients = false,
  excludeFilterByTags = false,
  excludeFilterByBranchOffice = false,
  excludeFilterByVehicleTypes = false,
  excludeFilterByVehicleBodies = false,
  filterByVehicleTypesMultiple = true,
  filterByVehicleBodiesMultiple = true,
}) {
  const [tags, setTags] = useState([]);
  const [clients, setClients] = useState([]);
  const [start_date, setStartDate] = useState();
  const [endDatePeriod, setEndDatePeriod] = useState();
  const [startDatePeriod, setStartDatePeriod] = useState();
  const [costCenters, setCostCenters] = useState([]);
  const [branches, setBranches] = useState([]);
  const [negotiators, setNegotiators] = useState([]);
  const [pcps, setPcps] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [filterByPeriod, setFilterByPeriod] = useState(false);
  const [errors, setErrors] = useState({});
  const [userCompaniesOptions, setUserCompaniesOptions] = useState([]);
  const [loadingFilters, setLoadingFilters] = useState(false);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [vehicleTypesOptions, setVehicleTypesOptions] = useState ([]);
  const [vehicleBodies, setVehicleBodies] = useState ([]);
  const [vehicleBodiesOptions, setVehicleBodiesOptions] = useState ([]);

  async function onSearchTag(search) {
    try {
      const response = await api.get('tags', { params: { search } });
      return response.data.data;
    } catch (ex) {
      return [];
    }
  }

  async function onSearchNegotiator(search) {
    try {
      const response = await api.get('user/name', {
        params: { negotiator: search, isControlTower: true },
      });
      return response.data;
    } catch (error) {
      return [];
    }
  }

  async function onSearchUser(search) {
    try {
      const response = await api.get('user/name', {
        params: { search, isControlTower: true },
      });
      return response.data;
    } catch (error) {
      return [];
    }
  }

  async function onSearchClients(search) {
    try {
      const response = await api.get('persons/customers', {
        params: { search },
      });
      return response.data;
    } catch (ex) {
      return [];
    }
  }

  async function onSubmit() {
    try {
      const data = {
        tags: tags || [],
        clients: clients || [],
        negotiators: negotiators || [],
        costCenters: costCenters || [],
        branches: branches || [],
        pcps: pcps || [],
        companies: companies || [],
        vehicleTypes: vehicleTypes || [],
        vehicleBodies: vehicleBodies || [],
        sizes: values.sizes,
        component: name,
        start_date: filterByPeriod
          ? `${`${moment(startDatePeriod, 'DD/MM/YYYY').format(
              'YYYY-MM-DD'
            )}T00:00:00.000Z`}`
          : start_date,
        end_date: filterByPeriod
          ? `${`${moment(endDatePeriod, 'DD/MM/YYYY').format(
              'YYYY-MM-DD'
            )}T23:59:59.000Z`}`
          : undefined,
        card_id: id,
        filterByPeriod: filterByPeriod ? 1 : 0,
      };

      setValues(old => ({
        ...old,
        clients,
        negotiators,
        costCenters,
        branches,
        pcps,
        companies,
        tags,
        vehicleTypes,
        vehicleBodies,
        start_date: filterByPeriod
          ? `${`${moment(startDatePeriod, 'DD/MM/YYYY').format(
              'YYYY-MM-DD'
            )}T00:00:00.000Z`}`
          : start_date,
        end_date: filterByPeriod
          ? `${`${moment(endDatePeriod, 'DD/MM/YYYY').format(
              'YYYY-MM-DD'
            )}T23:59:59.000Z`}`
          : undefined,
        filterByPeriod,
      }));
      onClose();

      await api.put(`v3/dashboard/${dashboard.id}/parameters`, data);
    } catch (error) {
      //
    }
  }

  function isDateValid(start, end) {
    return !moment(start).isAfter(end);
  }

  function isPeriodValid(start, end) {
    return moment(end).diff(start, 'days') <= 30;
  }

  function handleEndDateChange(dateString) {
    setEndDatePeriod(dateString);
    const startDate = moment(startDatePeriod, 'DD/MM/YYYY').format(
      'YYYY-MM-DD'
    );
    const endDate = moment(dateString, 'DD/MM/YYYY').format('YYYY-MM-DD');
    dateString
      ? setErrors(prev => ({
          ...prev,
          endDatePeriodError: !isDateValid(startDate, endDate)
            ? 'Data inicial não pode ser maior que data final'
            : !isPeriodValid(startDate, endDate)
            ? 'Período máximo de 30 dias!'
            : null,
        }))
      : setErrors(prev => ({
          ...prev,
          endDatePeriodError: null,
        }));
  }

  async function getUserCompanies() {
    try {
      const response = await fetchUserCompaniesSelectOptions();
      setUserCompaniesOptions(response);
    } catch (error) {
    } finally {
      setLoadingFilters(false);
    }
  }

  async function getVehicleTypes() {
    try {
      const response = await api.get(`vehicle-types?app=${true}`);
      setVehicleTypesOptions(response.data);
    } catch (error) {
    } finally {
      setLoadingFilters(false);
    }
  }

  async function getVehicleBodies() {
    try {
      const response = await api.get('body-types');
      setVehicleBodiesOptions(response.data);
    } catch (error) {
    } finally {
      setLoadingFilters(false);
    }
  }

  useEffect(() => {
    if (isOpen) {
      setFilterByPeriod(values?.filterByPeriod);
      setStartDate(values?.start_date || DATE_OPTIONS[1].value);
      setStartDatePeriod(
        moment(values?.start_date).add(1, 'days').format('DD/MM/YYYY')
      );
      setEndDatePeriod(moment(values?.end_date).format('DD/MM/YYYY'));
      setTags(values?.tags || []);
      setClients(values?.clients || []);
      setNegotiators(values?.negotiators || []);
      setCostCenters(values?.costCenters || []);
      setPcps(values?.pcps || []);
      setCompanies(values?.companies || []);
      setBranches(values?.branches || []);
      setVehicleTypes(values?.vehicleTypes || []);
      setVehicleBodies(values?.vehicleBodies || []);
      if (excludeFilterByVehicleTypes === false) {
        setLoadingFilters(true);
        getVehicleTypes();
      }
      if (excludeFilterByVehicleBodies === false) {
        setLoadingFilters(true);
        getVehicleBodies();
      }
      if (excludeFilterByCompany === false) {
        setLoadingFilters(true);
        getUserCompanies();
      }
    }
  }, [isOpen]);

  return (
    <>
      <Modal
        show={isOpen}
        onHide={onClose}
        backdrop="static"
        heading={
          <Text color="dark" type="subheader" weight="bold">
            Filtros
          </Text>
        }
        body={
          loadingFilters ? (
            <DefaultLoadingComponent />
          ) : onlyCompanyFilter ? (
            <Row>
              <Col xs={12}>
                <Select
                  label="Empresa"
                  options={userCompaniesOptions}
                  value={companies}
                  onChange={value => setCompanies(value)}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  multiple={filterByCompanyMultiple}
                />
              </Col>
            </Row>
          ) : (
            <>
              {!excludeFilterByClients ? (
                <Row>
                  <Col xs={12} className="mb-3">
                    <Select.Async
                      label="Clientes"
                      value={clients}
                      onSearch={onSearchClients}
                      onChange={value => setClients(value)}
                      multiple
                      getOptionLabel={option => {
                        let label = `${option.social_name} - ${option.cgccpf}`;

                        if (option.city) {
                          label += ` - ${option.city}`;
                        }

                        return label;
                      }}
                      getOptionValue={option => option.id}
                    />
                  </Col>
                </Row>
              ) : null}
              {!excludeFilterByNegotiator ? (
                <Row>
                  <Col xs={12} className="mb-3">
                    <Select.Async
                      label="Negociador"
                      value={negotiators}
                      onSearch={onSearchNegotiator}
                      onChange={value => setNegotiators(value)}
                      multiple
                      getOptionLabel={option => {
                        let label = `${option.username}`;
                        if (option?.cpf) {
                          label += ` - ${option?.cpf}`;
                        }
                        return label;
                      }}
                      getOptionValue={option => option.id}
                    />
                  </Col>
                </Row>
              ) : null}
              <Row>
                <Col xs={6} className="mb-3">
                  <Select.Async
                    label="PCP"
                    value={pcps}
                    onSearch={onSearchUser}
                    onChange={value => setPcps(value)}
                    multiple
                    getOptionLabel={option => {
                      let label = `${option.username}`;
                      if (option?.cpf) {
                        label += ` - ${option?.cpf}`;
                      }
                      return label;
                    }}
                    getOptionValue={option => option.id}
                  />
                </Col>
                <Col xs={6} className="mb-3">
                  <Select.Async
                    label="Centro de Custo da Carga"
                    value={costCenters}
                    onSearch={fetchUserCostCentersByCompany}
                    onChange={value => setCostCenters(value)}
                    multiple
                    getOptionLabel={option => {
                      const label = `${option?.name}`;
                      return label;
                    }}
                    getOptionValue={option => option.id}
                  />
                </Col>
              </Row>
              <Row>
                {!excludeFilterByBranchOffice && (
                  <Col xs={6} className="mb-3">
                    <Select.Async
                      label="Filial"
                      value={branches}
                      onSearch={fetchBranchOffices}
                      onChange={value => setBranches(value)}
                      multiple
                      getOptionLabel={option =>
                        `${`${option.id} - ${option.name}`}`
                      }
                      getOptionValue={option => option.id}
                    />
                  </Col>
                )}
                {!excludeFilterByTags && (
                  <Col md={6} xs={12} className="mb-3">
                    <Select.Async
                      removeInputMin
                      label="Tags"
                      value={tags}
                      onSearch={onSearchTag}
                      onChange={value => setTags(value)}
                      multiple
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.id}
                    />
                  </Col>
                )}
                {!excludeFilterByVehicleTypes && (
                  <Col md={6} xs={12} className="mb-3">
                    <Select
                      label="Tipo Veículo de Tração"
                      options={vehicleTypesOptions}
                      value={vehicleTypes}
                      onChange={value => setVehicleTypes(value)}
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.id}
                      multiple={filterByVehicleTypesMultiple}
                    />
                  </Col>
                )}
                {!excludeFilterByVehicleBodies && (
                  <Col md={6} xs={12} className="mb-3">
                    <Select
                      label="Tipos de carroceria"
                      options={vehicleBodiesOptions}
                      value={vehicleBodies}
                      onChange={value => setVehicleBodies(value)}
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.id}
                      multiple={filterByVehicleBodiesMultiple}
                    />
                  </Col>
                )}
              </Row>
              <Row>
                {!excludeFilterByCompany && (
                  <Col xs={6}>
                    <Select
                      label="Empresa"
                      options={userCompaniesOptions}
                      value={companies}
                      onChange={value => setCompanies(value)}
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.id}
                      multiple={filterByCompanyMultiple}
                    />
                  </Col>
                )}
              </Row>

              {!excludeFilterByDate && (
                <>
                  <Row>
                    <Col xs={12} className="mb-3">
                      <Radio.Group
                        label="Filtrar por período específico"
                        onChange={() => {
                          setFilterByPeriod(!filterByPeriod);
                          setStartDate('');
                          setEndDatePeriod('');
                          setStartDatePeriod('');
                        }}
                        value={filterByPeriod}
                        horizontal
                      >
                        <Radio value>
                          <Text color="dark" type="label">
                            Sim
                          </Text>
                        </Radio>
                        <Radio value={false}>
                          <Text color="dark" type="label">
                            Não
                          </Text>
                        </Radio>
                      </Radio.Group>
                    </Col>
                  </Row>
                  {!filterByPeriod ? (
                    <Row>
                      <Col xs={12} className="mb-3">
                        <Toggle
                          label="Filtrar pelo período de"
                          labelColor="gray"
                          value={start_date}
                          defaultValue={start_date}
                          options={
                            !mercosulDashboard
                              ? DATE_OPTIONS
                              : MERCOSUL_DATE_OPTIONS
                          }
                          onSelected={value => {
                            setStartDate(value);
                          }}
                        />
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col xs={6}>
                        <DatePicker
                          guide
                          label="Período de"
                          value={startDatePeriod}
                          format="DD/MM/YYYY"
                          onChange={({ target }) => {
                            setStartDatePeriod(
                              target.value ? target.value : ''
                            );
                            setEndDatePeriod('');
                          }}
                          labelColor="gray"
                        />
                      </Col>
                      <Col xs={6}>
                        <DatePicker
                          disabled={!startDatePeriod}
                          guide
                          label="Até "
                          value={endDatePeriod}
                          format="DD/MM/YYYY"
                          onChange={({ target }) => {
                            handleEndDateChange(target?.value);
                          }}
                          labelColor="gray"
                          error={errors?.endDatePeriodError}
                        />
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </>
          )
        }
        footer={
          <Button onClick={() => onSubmit()} className="py-2">
            <Text color="white" type="regular" weight="500">
              Filtrar
            </Text>
          </Button>
        }
      />
    </>
  );
}

export default Filter;
