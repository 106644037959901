import api from 'services/api';

export const countries = [
  { value: 'ar', label: 'Argentina', ddi: '54' },
  { value: 'bo', label: 'Bolívia', ddi: '591' },
  { value: 'br', label: 'Brasil', ddi: '55' },
  { value: 'cl', label: 'Chile', ddi: '56' },
  { value: 'co', label: 'Colômbia', ddi: '57' },
  { value: 'ec', label: 'Equador', ddi: '593' },
  { value: 'py', label: 'Paraguai', ddi: '595' },
  { value: 'pe', label: 'Peru', ddi: '51' },
  { value: 'uy', label: 'Uruguai', ddi: '598' },
  { value: 'ao', label: 'Angola', ddi: '244' },
];

export async function fetchCountries(setCountries) {
  try {
    const { data } = await api.get('countries');
    setCountries(data);
  } catch (err) {
    setCountries([]);
  }
}
