import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { RiAlertFill } from 'react-icons/ri';
import { FaCheck } from 'react-icons/fa';
import theme from 'v3/theme';
import { Card, Text, Modal, Fallback, DatePicker } from 'v3/components';
import {
  OccurrenceListItemContainer,
  OccurrencesListContainer,
  TravelOccurrencesListContainer,
} from 'v3/components/TravelOccurrencesList/styles';
import { useClientTravelDetail } from './context';
import {
  DefaultEmptyComponent,
  DefaultLoadingComponent,
} from 'v3/components/List';
import InputCurrency from 'v3/components/Input/Currency/index';

import {
  AttachmentDetail,
  AttachmentDetailsContainer,
  AttachmentsHeader,
  SectionContainer,
} from 'v3/components/OccurrencesModal/styles';
import {
  DescriptionItemContainer,
  DetailsContainer,
  HeaderContainer,
  OccurrenceDescriptionsContainer,
  PendencyLabel,
  TitleContainer,
} from 'v3/components/OccurrencesModal/OccurrenceInfo/styles';
import { formatCurrency, formatDateTime } from 'v3/utils/formatter';
import { AditionalFIeldsContainer } from 'v3/components/OccurrencesModal/OccurrenceInfo/OccurrenceGeneralInfo/style';
import OccurencesAditionalFieldsContainer from 'v3/components/OccurrencesModal/OccurrenceInfo/OccurrenceGeneralInfo/OccurencesAditionalFieldsContainer';
import { OccurrencesAditionalFieldsContext } from 'contexts/OccurrencesAditionalFields';
import { cooperplaceApi } from 'services/api';
import { OccurrenceAttachment } from 'v3/components/OccurrencesModal/ShowAttachments';
import { OccurrenceMainDetailsContainer } from './styles';

export function OccurrencesCard() {
  const { data, isFetching } = useClientTravelDetail();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { selectedOccurrence, setSelectedOccurrence } = useContext(
    OccurrencesAditionalFieldsContext
  );
  return (
    <>
      <Card
        header={
          <Text type="header" color={theme.colors.dark}>
            Ocorrências
          </Text>
        }
        loading={isFetching}
      >
        <OccurrencesList
          setIsModalOpen={setIsModalOpen}
          setSelectedOccurrence={setSelectedOccurrence}
          selectedOccurrence={selectedOccurrence}
        />
      </Card>
      <OccurrencesModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        setSelectedOccurrence={setSelectedOccurrence}
        selectedOccurrence={selectedOccurrence}
      />
    </>
  );
}

function OccurrencesList({
  setIsModalOpen,
  setSelectedOccurrence,
  onlyList = false,
  selectedOccurrence,
}) {
  const { data, isFetching } = useClientTravelDetail();

  return (
    <Fallback
      fall={isFetching || data?.occurrences?.length === 0}
      component={
        isFetching ? (
          <DefaultLoadingComponent />
        ) : !isFetching && data?.occurrences?.length === 0 ? (
          <DefaultEmptyComponent />
        ) : null
      }
    >
      <TravelOccurrencesListContainer>
        <OccurrencesListContainer>
          {data?.occurrences?.map(occurrence => {
            return (
              <OccurrenceListItemContainer
                key={occurrence.id}
                selected={selectedOccurrence?.id === occurrence.id}
                finished={occurrence?.fullUserConclusion?.username}
                clickable={true}
                list={true}
                title={occurrence?.descriptions[0]?.description}
                onClick={() => {
                  if (onlyList) {
                    setSelectedOccurrence(occurrence);
                  } else {
                    setSelectedOccurrence(occurrence);
                    setIsModalOpen(true);
                  }
                }}
              >
                {occurrence.pendency &&
                !occurrence?.fullUserConclusion?.username ? (
                  <RiAlertFill />
                ) : (
                  <FaCheck />
                )}
                <Text type="regular" color="dark" weight={400}>
                  {occurrence?.descriptions[0]?.description}
                </Text>
              </OccurrenceListItemContainer>
            );
          })}
        </OccurrencesListContainer>
      </TravelOccurrencesListContainer>
    </Fallback>
  );
}

function OccurrencesModal({
  isModalOpen,
  setIsModalOpen,
  setSelectedOccurrence,
  selectedOccurrence,
}) {
  const { data, isFetching } = useClientTravelDetail();
  function handleModalClose() {
    setSelectedOccurrence({});
    setIsModalOpen(false);
  }
  return (
    <Modal
      show={isModalOpen}
      heading={'Ocorrências'}
      handleClose={handleModalClose}
      body={
        <Fallback
          fall={isFetching || data?.occurrences?.length === 0}
          component={
            isFetching ? (
              <DefaultLoadingComponent />
            ) : !isFetching && data?.occurrences?.length === 0 ? (
              <DefaultEmptyComponent />
            ) : null
          }
        >
          <SectionContainer>
            <OccurrencesList
              setIsModalOpen={setIsModalOpen}
              setSelectedOccurrence={setSelectedOccurrence}
              onlyList
              selectedOccurrence={selectedOccurrence}
            />
            {selectedOccurrence?.id && (
              <>
                <SectionContainer>
                  <HeaderContainer>
                    <TitleContainer>
                      <Text weight={500} type="header" color="dark">
                        Informações da Ocorrência
                      </Text>
                      {selectedOccurrence?.pendency &&
                        !selectedOccurrence?.fullUserConclusion?.username && (
                          <PendencyLabel>Pendente</PendencyLabel>
                        )}
                    </TitleContainer>
                  </HeaderContainer>
                  <DetailsContainer>
                    <OccurrenceMainDetailsContainer>
                      <Row className="d-flex flex-column">
                        <Col>
                          <Text type="regular" color="dark" weight={500}>
                            Data inclusão:{' '}
                          </Text>
                          <Text type="regular" color="dark" weight={400}>
                            {formatDateTime(selectedOccurrence?.createdAt)}
                          </Text>
                        </Col>
                        <Col>
                          <Text type="regular" color="dark" weight={500}>
                            Tipo:{' '}
                          </Text>
                          <Text type="regular" color="dark" weight={400}>
                            {selectedOccurrence?.fullOccurrenceType?.name}
                          </Text>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4} sm={12}>
                          <InputCurrency
                            label="Valor"
                            value={formatCurrency(selectedOccurrence?.value)}
                            disabled
                          />
                        </Col>
                        <Col md={4} sm={12}>
                          <DatePicker
                            disabled
                            value={formatDateTime(selectedOccurrence.startDate)}
                            label="Início Ocorrência"
                            withTime
                          />
                        </Col>
                        <Col md={4} sm={12}>
                          <DatePicker
                            placeholder=""
                            disabled
                            value={formatDateTime(selectedOccurrence.endDate)}
                            label="Término Ocorrência"
                            withTime
                          />
                        </Col>
                      </Row>
                    </OccurrenceMainDetailsContainer>
                    <AditionalFIeldsContainer>
                      <Row>
                        <OccurencesAditionalFieldsContainer />
                      </Row>
                    </AditionalFIeldsContainer>
                  </DetailsContainer>
                </SectionContainer>
                <OccurrenceDescriptionsSection
                  descriptions={selectedOccurrence?.descriptions}
                />
                <OccurrenceAttachmentsSection
                  occurrenceId={selectedOccurrence?.id}
                />
              </>
            )}
          </SectionContainer>
        </Fallback>
      }
    />
  );
}

function OccurrenceDescriptionsSection({ descriptions }) {
  return (
    <SectionContainer>
      <Text weight={500} type="header" color="dark">
        Descrição Ocorrência
      </Text>
      <OccurrenceDescriptionsContainer>
        {descriptions.map(description => {
          return (
            <DescriptionItemContainer>
              <span>
                <Text
                  type="regular"
                  color="dark"
                  weight={500}
                >{`${formatDateTime(description?.createdAt)}: `}</Text>
                <Text
                  type="regular"
                  color="dark"
                  weight={400}
                  style={{ whiteSpace: 'pre-line' }}
                >
                  {description?.description}
                </Text>
              </span>
            </DescriptionItemContainer>
          );
        })}
      </OccurrenceDescriptionsContainer>
    </SectionContainer>
  );
}

function OccurrenceAttachmentsSection({ occurrenceId }) {
  const [attachments, setAttachments] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchAttachments = useCallback(async () => {
    try {
      setLoading(true);
      const response = await cooperplaceApi.get(
        `occurrence-media/${occurrenceId}`
      );
      setAttachments(response.data);
    } catch (error) {
      //
    } finally {
      setLoading(false);
    }
  }, [occurrenceId]);

  useEffect(() => {
    fetchAttachments();
  }, [occurrenceId]);

  return (
    <>
      <SectionContainer>
        <AttachmentsHeader>
          <Text type="header" color="dark" weight={500}>
            Anexos
          </Text>
        </AttachmentsHeader>
        <Fallback
          component={
            <Col xs={12} className="align-items-center d-flex flex-column my-2">
              <Text color={theme.colors.gray}>
                Não há anexos cadastrados para essa ocorrência.
              </Text>
            </Col>
          }
          fall={!attachments?.length || loading}
        >
          <OccurrenceDescriptionsContainer>
            <AttachmentDetailsContainer>
              {attachments?.map(attachment => {
                return (
                  <AttachmentDetail>
                    <OccurrenceAttachment
                      attachment={attachment}
                      attachments={attachments}
                      occurrenceId={occurrenceId}
                      refetchAttachments={fetchAttachments}
                      hasPermissionToRemove={false}
                    />
                    <Text
                      type="regular"
                      className="mt-2"
                      style={{ 'max-width': '75%', 'word-wrap': 'break-word' }}
                    >
                      {attachment?.media?.description ||
                        attachment?.media?.name}
                    </Text>
                  </AttachmentDetail>
                );
              })}
            </AttachmentDetailsContainer>
          </OccurrenceDescriptionsContainer>
        </Fallback>
      </SectionContainer>
    </>
  );
}
