import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';

import moment from 'moment';

import Card from 'components/Card';
import { number_format } from 'functions';
import { formatCurrency, formatNumber } from 'v3/utils/formatter';
import { SignalCellularNullTwoTone } from '@material-ui/icons';

function CellAtendida(props) {
  const [ctes, setCtes] = useState(
    props.carga.ctes ? props.carga.ctes.replace(/[()]/g, '').split(',') : null
  );
  const [nfs, setNfs] = useState(
    props.carga.nfs ? props.carga.nfs.replace(/[()]/g, '').split(',') : null
  );

  function generateAddress(type) {
    if (type === 'origem') {
      return (
        props.carga.origem_address +
        (props.carga.origem_number ? `, ${props.carga.origem_number}` : '') +
        (props.carga.origem_complement
          ? `- ${props.carga.origem_complement}`
          : '')
      );
    }
    if (type === 'destino') {
      return (
        props.carga.destino_address +
        (props.carga.destino_number ? `, ${props.carga.destino_number}` : '') +
        (props.carga.destino_complement
          ? `- ${props.carga.destino_complement}`
          : '')
      );
    }
    return '';
  }
  return (
    <Card
      style={{ marginBottom: '10px' }}
      bordered
      borderhexcolor={props.carga.status_color}
    >
      <Grid container>
        <Grid item xs>
          <Typography variant="caption" component="p" gutterBottom>
            <strong>ID</strong>
          </Typography>
          <Typography variant="body2" gutterBottom>
            {props.carga.id}
          </Typography>
          <Typography variant="body2" gutterBottom>
            <strong>Tomador / CNPJ</strong>
          </Typography>
          <Typography variant="caption" component="p" gutterBottom>
            {props.carga.client_name}
          </Typography>
          <Typography variant="caption" component="p" gutterBottom>
            {props.carga.client_cgccpf}
          </Typography>
          <Typography variant="body2" gutterBottom>
            <strong>Motorista / CPF</strong>
          </Typography>
          <Typography variant="caption" component="p" gutterBottom>
            {props.carga.driver_name}
          </Typography>
          <Typography variant="caption" component="p" gutterBottom>
            {props.carga.driver_cgccpf}
          </Typography>
          <Typography variant="body2" gutterBottom>
            <strong>Transportadora</strong>
          </Typography>
          {props.carga.shipper_name && (
            <>
              <Typography variant="caption" component="p" gutterBottom>
                {props.carga.shipper_name}
              </Typography>
              <Typography variant="caption" component="p" gutterBottom>
                {props.carga.shipper_cgccpf}
              </Typography>
            </>
          )}
        </Grid>
        <Grid item xs>
          <Typography variant="body2" gutterBottom>
            <strong>Coleta</strong>
          </Typography>
          {props.carga.origem_id && (
            <>
              <Typography variant="caption" component="p" gutterBottom>
                {generateAddress('origem')}
              </Typography>

              <Typography variant="caption" component="p" gutterBottom>
                <strong>Agendado:</strong>{' '}
                {moment(props.carga.origem_scheduled_time).isValid()
                  ? ` ${moment(props.carga.origem_scheduled_time).format(
                      'DD/MM/YYYY HH:mm'
                    )}`
                  : ' - '}
              </Typography>

              <Typography variant="caption" component="p" gutterBottom>
                <strong>Efetivo:</strong>{' '}
                {moment(props.carga.origem_arrival_time).isValid()
                  ? ` ${moment(props.carga.origem_arrival_time).format(
                      'DD/MM/YYYY HH:mm'
                    )}`
                  : ' - '}
              </Typography>
            </>
          )}

          <Typography variant="body2" gutterBottom>
            <strong>Entrega</strong>
          </Typography>
          {props.carga.destino_id && (
            <>
              <Typography variant="caption" component="p" gutterBottom>
                {generateAddress('destino')}
              </Typography>

              <Typography variant="caption" component="p" gutterBottom>
                <strong>Agendado:</strong>{' '}
                {moment(props.carga.destino_scheduled_time).isValid()
                  ? moment(props.carga.destino_scheduled_time).format(
                      'DD/MM/YYYY HH:mm'
                    )
                  : ' - '}
              </Typography>

              <Typography variant="caption" component="p" gutterBottom>
                <strong>Efetivo:</strong>{' '}
                {moment(props.carga.destino_arrival_time).isValid()
                  ? moment(props.carga.destino_arrival_time).format(
                      'DD/MM/YYYY HH:mm'
                    )
                  : ' - '}
              </Typography>
            </>
          )}
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2" gutterBottom>
            <strong>Data de criação</strong>
          </Typography>
          <Typography variant="caption" component="p" gutterBottom>
            {moment(props.carga.load_created_at).format('DD/MM/YYYY HH:mm')}
          </Typography>
          <Typography variant="caption" component="p" gutterBottom>
            <strong>Criador da viagem</strong>
          </Typography>
          <Typography variant="body2" gutterBottom>
            {props.carga?.user_created_username || 'Não informado'}
          </Typography>
          <Typography variant="caption" component="p" gutterBottom>
            <strong>N° da viagem</strong>
          </Typography>
          <Typography variant="body2" gutterBottom>
            {props.carga.load_travel_number}
          </Typography>
          <Typography variant="body2" gutterBottom>
            <strong>CT-e</strong>
          </Typography>
          <Typography variant="caption" component="p" gutterBottom>
            {ctes &&
              ctes.map((el, index) => {
                if (index < ctes.length - 1)
                  return <span key={el}>{el}, </span>;
                return <span key={el}>{el} </span>;
              })}
          </Typography>
          <Typography variant="body2" gutterBottom>
            <strong>NFs</strong>
          </Typography>
          <Typography variant="caption" component="p" gutterBottom>
            {nfs &&
              nfs.map((el, index) => {
                if (index < nfs.length - 1) return <span key={el}>{el}, </span>;
                return <span key={el}>{el} </span>;
              })}
          </Typography>
          <>
            <Typography variant="body2" gutterBottom>
              <strong>Cliente paga pedágio?</strong>
            </Typography>
            <Typography variant="caption" gutterBottom>
              {props?.carga?.is_toll_payer_client ? 'Sim' : 'Não'}
            </Typography>
          </>
        </Grid>

        <Grid item xs={2}>
          <Typography variant="body2" gutterBottom>
            <strong>Valor</strong>
          </Typography>
          <Typography variant="caption" component="p" gutterBottom>
            R$ {number_format(props.carga.load_balance, 2, ',', '.')}
          </Typography>

          <Typography variant="body2" gutterBottom>
            <strong>Status</strong>
          </Typography>

          <Chip
            variant="outlined"
            size="small"
            label={props.carga.status_name}
          />

          <Typography variant="body2" gutterBottom>
            <strong>Peso da carga</strong>
          </Typography>
          <Typography variant="caption" component="p" gutterBottom>
            {number_format(props.carga.load_weight, '.')} kg
          </Typography>

          <Typography variant="body2" gutterBottom>
            <strong>Cubagem da carga</strong>
          </Typography>
          <Typography variant="caption" component="p" gutterBottom>
            {number_format(props.carga.load_volume, '.')} m3
          </Typography>

          <>
            <Typography variant="body2" gutterBottom>
              <strong>Valor do pedágio pago</strong>
            </Typography>
            <Typography variant="caption" component="p" gutterBottom>
              {formatCurrency(props?.carga?.toll_value)}
            </Typography>
          </>
        </Grid>
      </Grid>
    </Card>
  );
}

export default CellAtendida;
