import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Button, Input, InputAddress, Select, Text } from 'v3/components';
import { StyledDestinationNavButton } from 'v3/pages/PreLoad/Register/styles';
import { useLoadGro } from '../../context';
import IconButton from 'v3/components/Button/Icon';
import { cooperplaceApi } from 'services/api';
import { useSnackbar } from 'v3/components/Snackbar';
import { fetchSelectOptions } from 'utils/fetches';
import { validateDateTime } from 'v3/utils/functions';
import { DELIVERY_TYPE_OPTIONS } from 'v3/utils/constants';
import { LoadContext } from 'v3/pages/Load/Register/controller';

const [, useLoad] = LoadContext;

export function Destinations() {
  const { countryOptions, errorsGro, setErrorsGro, destinationsError } =
    useLoadGro();
  const { data, setData } = useLoad();
  const snackbar = useSnackbar();
  function handleGoBack(idx) {
    if (idx < 1) {
      return;
    }
    setCurrentIndex(idx - 1);
  }
  function handleGoToNext(idx) {
    if (idx + 1 > data?.destinations?.length) {
      return;
    }
    setCurrentIndex(idx + 1);
  }
  const [currentIndex, setCurrentIndex] = useState(0);
  const [disableNumber, setDisableNumber] = useState(false);
  const [disableZipCode, setDisableZipCode] = useState(false);
  const [disableCity, setDisableCity] = useState(false);
  const [disableProvince, setDisableProvince] = useState(false);
  const [disableNeighborhood, setDisableNeighborhood] = useState(false);

  async function fetchProvinces(
    search,
    uf,
    country,
    policyProductId = null,
    policyRouteType = 'origin'
  ) {
    try {
      const response = await cooperplaceApi.get('cities', {
        params: { search, uf, country, policyProductId, policyRouteType },
      });
      return response.data;
    } catch (ex) {
      return [];
    }
  }

  function handleChangeValue(index, key, value) {
    setData(old => ({
      ...old,
      destinations: old.destinations?.map((item, idx) => {
        if (index === idx) {
          return { ...item, [key]: value };
        }

        return item;
      }),
    }));
  }

  function handleDestinationClientSelection(event, index) {
    disableAddress({}, null);
    if (event !== null) {
      const currentAddress = event?.addresses.filter(
        address => address.current
      )[0];

      if (
        !currentAddress ||
        currentAddress?.lat === null ||
        currentAddress?.lng === null
      ) {
        handleChangeValue(index, 'client', event);
        snackbar.show(
          <Text>{`Cadastro do cliente ${event?.social_name} está desatualizado! Atualize o endereço do cliente!`}</Text>,
          { type: 'warning' }
        );
      } else {
        setData(old => ({
          ...old,
          destinations: old.destinations?.map((item, idx) => {
            if (currentIndex === idx) {
              return {
                ...item,
                client: event,
                address: currentAddress?.address,
                number: currentAddress?.number,
                zipCode: currentAddress?.zip_code,
                city: currentAddress?.city_name,
                lat: currentAddress?.lat,
                lng: currentAddress?.lng,
                complement: currentAddress?.complement,
                province: currentAddress?.uf_name,
                formattedAddress:
                  currentAddress?.formatted_address || currentAddress?.address,
                neighborhood: currentAddress?.neighborhood,
                cityId: currentAddress?.city_id,
                cityModel: currentAddress?.city_name
                  ? {
                      id: currentAddress?.city_id,
                      name: currentAddress?.city_name,
                      province: {
                        uf: currentAddress?.uf_name,
                      },
                    }
                  : undefined,
              };
            }
            return item;
          }),
        }));
      }
    } else {
      setData(old => ({
        ...old,
        destinations: old.destinations?.map((item, idx) => {
          if (currentIndex === idx) {
            return {
              ...item,
              client: event,
              address: '',
              number: '',
              zipCode: '',
              cityModel: null,
              cityId: null,
              lat: '',
              lng: '',
              complement: '',
              province: '',
              formattedAddress: '',
              neighborhood: '',
            };
          }

          return item;
        }),
      }));
    }
  }

  function disableAddress(address, city) {
    setDisableNumber(address.number ? true : false);
    setDisableZipCode(address.zip_code ? true : false);
    setDisableCity(city ? true : false);
    setDisableProvince(city?.province ? true : false);
    setDisableNeighborhood(address.neighborhood ? true : false);
  }

  function handleDeleteCard(idx) {
    setData(old => ({
      ...old,
      destinations: old.destinations.filter((_, index) => index !== idx),
    }));
    setCurrentIndex(idx - 1);
  }

  async function setDestinationCity(index, city) {
    setData(old => ({
      ...old,
      destinations: old.destinations?.map((item, idx) => {
        if (index === idx) {
          return {
            ...item,
            cityId: city?.city_id,
            cityModel: city?.city_name
              ? {
                  id: city?.city_id,
                  name: city?.city_name,
                  province: {
                    uf: city?.uf_name,
                  },
                }
              : null,
          };
        }
        return item;
      }),
    }));

  }
  function handleAddNewDestination() {
    setCurrentIndex(data?.destinations?.length);
    setData(old => ({
      ...old,
      destinations: [
        ...old.destinations,
        {
          address: '',
          type: DELIVERY_TYPE_OPTIONS[1],
          date: '',
          complement: '',
          lat: '',
          lng: '',
          formattedAddress: '',
          neighborhood: '',
          city: '',
          province: '',
          zipCode: '',
          country: {
            id: 1,
            name: 'Brasil',
            abbreviation: 'br',
            ddi: 55,
          },
          number: '',
          startSchedule: '',
          index: data?.destinations?.length + 1,
        },
      ],
    }));
  }
  return (
    <div>
      <Row>
        <Col md={12} className="mb-2">
          <Text color="dark" type="header">
            Destinos
          </Text>
        </Col>
        <Col md={12}>
          <div className="mb-2">
            <Text type="header" color="dark">
              Destino {currentIndex + 1}
            </Text>
          </div>
          <Row>
            <Col md={6} xs={12} className="mb-3">
              <Select
                label="País *"
                value={data?.destinations[currentIndex]?.country}
                options={countryOptions?.destination}
                onChange={value => {
                  setData(old => ({
                    ...old,
                    destinations: old.destinations?.map((item, idx) => {
                      if (currentIndex === idx) {
                        return {
                          ...item,
                          country: value,
                          countrySlug: value?.abbreviation,
                          address: '',
                          number: '',
                          zipCode: '',
                          cityModel: null,
                          lat: '',
                          lng: '',
                          complement: '',
                          province: '',
                          formattedAddress: '',
                          neighborhood: '',
                        };
                      }

                      return item;
                    }),
                  }));
                }}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                error={errorsGro[`destinations[${currentIndex}].country`]}
                id={`select-gro-pais-destino-${currentIndex}`}
              />
            </Col>

            <Col md={6} xs={12} className="mb-3">
              <Select.Async
                label="Cliente"
                onSearch={search =>
                  fetchSelectOptions('persons/customers', { search })
                }
                value={data?.destinations[currentIndex]?.client}
                getOptionLabel={option => {
                  let label = `${option?.social_name} - ${option?.cgccpf}`;

                  if (option.city) {
                    label += ` - ${option?.city}`;
                  }

                  return label;
                }}
                getOptionValue={option => option?.id}
                onChange={event => {
                  handleDestinationClientSelection(event, currentIndex);
                }}
                id={`select-gro-cliente-destino-${currentIndex}`}
              />
            </Col>
            <Col md={8} xs={12} className="mb-3">
              <InputAddress
                label="Logradouro *"
                placeholder=""
                value={data?.destinations[currentIndex]?.address}
                onSelectedAddress={async place => {
                  const [city] = await fetchProvinces(
                    place.city,
                    place.province,
                    place.country_abbreviation
                  );

                  disableAddress(place, city);
                  setData(old => ({
                    ...old,
                    destinations: old.destinations?.map((item, idx) => {
                      if (currentIndex === idx) {
                        return {
                          ...item,
                          address: place.address || '',
                          number: place.number ? place.number : '',
                          zipCode: place.zip_code ? place.zip_code : '',
                          cityModel: city,
                          cityId: city?.id,
                          lat: place.lat ? place.lat : '',
                          lng: place.lng ? place.lng : '',
                          complement: place.name ? place.name : '',
                          province: city?.province?.uf || '',
                          formattedAddress: place.formatted_address
                            ? place.formatted_address
                            : '',
                          neighborhood: place.neighborhood
                            ? place.neighborhood
                            : '',
                        };
                      }

                      return item;
                    }),
                  }));
                }}
                onChange={({ target }) => {
                  handleChangeValue(currentIndex, 'address', target.value);
                }}
                types={[]}
                error={errorsGro[`destinations[${currentIndex}].address`]}
                country={
                  data?.destinations[currentIndex]?.country
                    ? data?.destinations[currentIndex]?.country.abbreviation
                    : null
                }
                disabled={!data?.destinations[currentIndex]?.country}
                id={`select-gro-logradouro-destino-${currentIndex}`}
              />
            </Col>
            <Col md={4} xs={12} className="mb-3">
              <Input
                label="Número"
                disabled={
                  disableNumber || !data?.destinations[currentIndex]?.country
                }
                value={data?.destinations[currentIndex]?.number}
                onChange={({ target }) => {
                  handleChangeValue(currentIndex, 'number', target.value);
                }}
                id={`input-gro-numero-destino-${currentIndex}`}
              />
            </Col>
            <Col md={4} xs={12}>
              <Input
                label="Complemento"
                value={data?.destinations[currentIndex]?.complement}
                onChange={({ target }) => {
                  handleChangeValue(currentIndex, 'complement', target.value);
                }}
                disabled={!data?.destinations[currentIndex]?.country}
                id={`input-gro-complemento-destino-${currentIndex}`}
              />
            </Col>
            <Col md={4} xs={12} className="mb-3">
              <Input
                label="Bairro"
                disabled={
                  disableNeighborhood ||
                  !data?.destinations[currentIndex]?.country
                }
                value={data?.destinations[currentIndex]?.neighborhood}
                onChange={({ target }) => {
                  handleChangeValue(currentIndex, 'neighborhood', target.value);
                }}
                id={`input-gro-bairro-destino-${currentIndex}`}
              />
            </Col>
            <Col md={4} xs={12} className="mb-3">
              <Input
                label="CEP"
                disabled={
                  disableZipCode || !data?.destinations[currentIndex]?.country
                }
                value={data?.destinations[currentIndex]?.zipCode}
                onChange={({ target }) => {
                  handleChangeValue(currentIndex, 'zipCode', target.value);
                }}
                id={`input-gro-cep-destino-${currentIndex}`}
              />
            </Col>
            <Col md={8} xs={12} className="mb-3">
              <Select.Async
                label="Cidade *"
                onSearch={city =>
                  fetchProvinces(
                    city,
                    '',
                    data?.destinations[currentIndex]?.country?.abbreviation
                  )
                }
                value={data?.destinations[currentIndex]?.cityModel}
                horizontal
                onChange={value => setDestinationCity(currentIndex, value)}
                getOptionLabel={option =>
                  `${`${option.name} ${
                    option.province?.uf ? ` - ${option.province?.uf}` : ''
                  }`}`
                }
                getOptionValue={option =>
                  `${`${option.name} ${
                    option.province?.uf ? `- ${option.province?.uf}` : null
                  }`}`
                }
                error={errorsGro[`destinations[${currentIndex}].cityModel`]}
                isDisabled={
                  disableCity || !data?.destinations[currentIndex]?.country
                }
                id={`select-gro-cidade-destino-${currentIndex}`}
              />
            </Col>
            <Col md={4} xs={12} className="mb-3">
              <Input
                label="UF *"
                disabled={
                  disableProvince || !data?.destinations[currentIndex]?.country
                }
                value={data?.destinations[currentIndex]?.province}
                onChange={({ target }) => {
                  handleChangeValue(currentIndex, 'province', target.value);
                }}
                error={errorsGro[`destinations[${currentIndex}].province`]}
                id={`input-gro-uf-destino-${currentIndex}`}
              />
            </Col>
            <Col md={6} className="mb-3">
              <Input
                label="Latitude"
                disabled={true}
                value={data?.destinations[currentIndex]?.lat}
                error={errorsGro[`destinations[${currentIndex}].lat`]}
                tooltip="Selecione um endereço sugerido para obter dados de latitude e longitude"
                id={`input-gro-latitude-destino-${currentIndex}`}
              />
            </Col>
            <Col md={6} className="mb-3">
              <Input
                label="Longitude"
                disabled={true}
                value={data?.destinations[currentIndex]?.lng}
                error={errorsGro[`destinations[${currentIndex}].lng`]}
                tooltip="Selecione um endereço sugerido para obter dados de latitude e longitude"
                id={`input-gro-longitude-destino-${currentIndex}`}
              />
            </Col>
            <Col md={6} xs={12} className="mb-3">
              <Input
                type="datetime-local"
                value={data?.destinations[currentIndex]?.startSchedule}
                label="Início da entrega *"
                onChange={({ target }) => {
                  let isValid = validateDateTime(target?.value);
                  if (isValid) {
                    handleChangeValue(
                      currentIndex,
                      'startSchedule',
                      target.value
                    );
                    setErrorsGro(old => ({
                      ...old,
                      [`destinations[${currentIndex}].startSchedule`]: null,
                    }));
                  } else {
                    handleChangeValue(
                      currentIndex,
                      'startSchedule',
                      target.value
                    );
                    setErrorsGro(old => ({
                      ...old,
                      [`destinations[${currentIndex}].startSchedule`]:
                        'Data inválida',
                    }));
                  }
                }}
                error={
                  errorsGro[`destinations[${currentIndex}].startSchedule`] ||
                  errorsGro[`destinations[${currentIndex}]`] ||
                  destinationsError[`${currentIndex}.startSchedule`]
                }
                id={`input-gro-data-inicio-destino-${currentIndex}`}
              />
            </Col>
            <Col md={6} xs={12} className="mb-3">
              <Input
                type="datetime-local"
                label={
                  data?.destinations[currentIndex]?.type?.id === 'collect'
                    ? 'Data máxima da coleta *'
                    : 'Data máxima da entrega *'
                }
                value={data?.destinations[currentIndex]?.date}
                onChange={({ target }) => {
                  let isValid = validateDateTime(target?.value);
                  if (isValid) {
                    handleChangeValue(currentIndex, 'date', target.value);
                    setErrorsGro(old => ({
                      ...old,
                      [`destinations[${currentIndex}].date`]: null,
                    }));
                  } else {
                    handleChangeValue(currentIndex, 'date', target.value);
                    setErrorsGro(old => ({
                      ...old,
                      [`destinations[${currentIndex}].date`]: 'Data inválida',
                    }));
                  }
                }}
                error={
                  errorsGro[`destinations[${currentIndex}].date`] ||
                  destinationsError[`${currentIndex}.date`]
                }
                id={`input-gro-data-maxima-destino-${currentIndex}`}
              />
            </Col>

            <Col md={6} xs={12}>
              <Select
                label="Ação *"
                value={data?.destinations[currentIndex]?.type}
                onChange={value => {
                  handleChangeValue(currentIndex, 'type', value);
                }}
                getOptionLabel={option => option?.name}
                getOptionValue={option => option?.id}
                options={DELIVERY_TYPE_OPTIONS}
                error={errorsGro[`destinations[${currentIndex}].type`]}
                id={`select-gro-acao-destino-${currentIndex}`}
              />
            </Col>
            <Col md={6} xs={12} className="mt-4">
              <IconButton
                title="Remover destino"
                style={{ marginTop: 12 }}
                icon="FaTrash"
                variant="error"
                disabled={currentIndex === 0}
                onClick={() => handleDeleteCard(currentIndex)}
                id={`button-gro-deletar-destino-${currentIndex}`}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="form" style={{ justifyContent: 'end' }}>
        <Col
          md={6}
          xs={12}
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
            justifyContent: 'end',
          }}
        >
          <StyledDestinationNavButton
            variant={'secondary'}
            disabled={currentIndex < 1}
            title="Destino anterior"
            onClick={() => handleGoBack(currentIndex)}
            id="button-gro-destino-anterior"
          >
            <Text>{'<'}</Text>
          </StyledDestinationNavButton>
          <StyledDestinationNavButton
            variant={'secondary'}
            title="Próximo destino"
            disabled={data?.destinations?.length === currentIndex + 1}
            onClick={() => handleGoToNext(currentIndex)}
            id="button-gro-destino-posterior"
          >
            <Text>{'>'}</Text>
          </StyledDestinationNavButton>
          <Button
            onClick={() => handleAddNewDestination()}
            id="button-gro-adicionar-destino"
          >
            <Text weight={500} type="regular">
              Adicionar mais 1 destino
            </Text>
          </Button>
        </Col>
      </Row>
    </div>
  );
}
