import React from 'react';

import { FaExclamationTriangle } from 'react-icons/fa';

import { Col, Container } from 'react-bootstrap';

import Button from 'v3/components/Button';
import Text from 'v3/components/Text';

export class ErrorBoundary extends React.Component {
  state = {
    error: false,
    errorMessage: '',
    knowMore: false,
  };

  static getDerivedStateFromError(error) {
    return { error: true };
  }

  async componentDidCatch(error, errorInfo) {
    this.setState({ errorMessage: errorInfo.componentStack });
  }

  render() {
    if (this.state.error) {
      return (
        <>
          <div
            className="App"
            style={{
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Container className="mb-5">
              <Col md={12} className="mb-5 pb-4 text-center">
                <img
                  style={{ height: 40, width: 180, marginTop: '5rem' }}
                  src="/images/Logo-Cooperplace.png"
                  alt="Cooperplace"
                />
              </Col>

              <Col md={12}>
                <FaExclamationTriangle size={70} color="#F64E60" />
              </Col>
              <Col md={12} className="mt-1 mb-1">
                <Text type="title" color="#F64E60" weight="600">
                  Um erro inesperado ocorreu.
                </Text>
              </Col>
              <Col md={12} className="mt-5 mb-2">
                <Text type="header" color="primary">
                  Pedimos desculpas por qualquer inconveniente que isso tenha causado!
                </Text>
              </Col>
              <Col md={12} className="mt-2 mb-2">
                <Text type="regular" color="primary">
                  Para entrar em contato conosco, utilize os meios abaixo:
                </Text>
              </Col>
              <Col md={12} className="mt-2 mb-2">
                <Text type="regular" color="primary">
                  Contato para colaboradores:{' '}
                  <a href="https://coopercarga.agidesk.com/br/servicos/suporte-geral-cooperplace">
                    <Text type="regular" weight="600">
                      Suporte Portal AGIDESK
                    </Text>
                  </a>
                  ;
                </Text>
              </Col>
              <Col md={12} className="mt-2 mb-2">
                <Text type="regular" color="primary">
                  Demais usuários:{' '}
                  <a href="mailto:gti@coopercarga.com.br">
                    <Text type="regular" weight="600">
                      gti@coopercarga.com.br
                    </Text>
                  </a>
                  ;
                </Text>
              </Col>
              <Col md={12} className="mt-5 mb-2">
                <a href={process.env.REACT_APP_ISSUING_FRONTEND_URL}>
                  <Button size="sm">
                    <Text type="regular" color="white" weight="600">
                      Ir para tela inicial
                    </Text>
                  </Button>
                </a>
              </Col>
              <Col md={12}>
                <Text type="little" color="primary">
                  <a
                    href="#"
                    onClick={() => {
                      this.setState({ knowMore: true });
                    }}
                  >
                    Saiba mais
                  </a>
                </Text>
              </Col>
              <Col md={12}>
                {this.state.errorMessage && this.state.knowMore && (
                  <Text color="#B5B5C3" type="little">
                    {this.state.errorMessage}
                  </Text>
                )}
              </Col>
            </Container>
          </div>
        </>
      );
    } else {
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
