import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

import api from 'services/api';

import { fetchCostCenter } from 'utils/fetches';

import Select from 'v3/components/Select';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import DatePicker from 'v3/components/DatePicker';

import moment from 'moment';

import useQuery from 'hooks/useQuery';

function TravelsArrivalFilter({
  onChange,
  exportReport,
  exportLoading,
  loading,
}) {
  const history = useHistory();
  const queryParams = useQuery();
  const [filters, setFilters] = useState({});
  const [error, setError] = useState({});
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState(null);
  const [loadTags, setLoadTags] = useState([]);
  const [travelTags, setTravelTags] = useState([]);
  const [costCenter, setCostCenter] = useState('');
  const [provinceOptions, setProvinceOptions] = useState({});
  const [selectedProvince, setSelectedProvince] = useState('');

  async function getProvinces() {
    try {
      const response = await api.get('provinces');
      const provinceData = response.data.map(province => {
        return {
          name: `${province?.name} - ${province?.uf}`,
          id: province?.id,
          value: province?.uf,
        };
      });
      setProvinceOptions(provinceData);
    } catch (error) {}
  }

  useEffect(() => {
    getProvinces();
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      history.replace({ search: queryParams.toString() });
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [filters]);

  async function fetchSelectTags(params) {
    try {
      const { data } = await api.get('tags', { params });
      return data;
    } catch (err) {
      return [];
    }
  }

  function handleFilter() {
    let hasError = false;
    if (startDate) {
      try {
        if (!/^\d\d[./-]\d\d[./-]\d\d\d\d$/.test(startDate)) {
          throw new Error('');
        }
        if (!moment(startDate, 'DD/MM/YYYY').isValid()) {
          throw new Error('');
        }
      } catch (ex) {
        queryParams.delete('start_date');
        setError({ ...error, start_date: 'Data inválida' });
        hasError = true;
      }
    } else queryParams.delete('start_date');

    if (endDate) {
      try {
        if (!/^\d\d[./-]\d\d[./-]\d\d\d\d$/.test(endDate)) {
          throw new Error('');
        }
        if (!moment(endDate, 'DD/MM/YYYY').isValid()) {
          throw new Error('');
        }
      } catch (ex) {
        queryParams.delete('end_date');
        setError({ ...error, end_date: 'Data inválida' });
        hasError = true;
      }
    } else queryParams.delete('end_date');

    if (!hasError) {
      onChange();
    }
  }
  return (
    <>
      <Row className="d-flex justify-content-center">
        <Col md={2} xs={12}>
          <DatePicker
            value={startDate}
            onChange={e => {
              setStartDate(e.target?.value);
              setError({ ...error, start_date: '' });
              setFilters(old => ({ ...old, start_date: e.target?.value }));
              if (e.target.value === null || e.target.value == '') {
                queryParams.delete('start_date');
              } else {
                queryParams.set(
                  'start_date',
                  moment(e.target.value, 'DD/MM/YYYY').format('YYYY-MM-DD')
                );
              }
            }}
            label="Período de *"
            error={error.start_date}
            labelColor="white"
          />
        </Col>
        <Col md={2} xs={12}>
          <DatePicker
            value={endDate}
            onChange={e => {
              setEndDate(e.target?.value);
              setError({ ...error, end_date: '' });
              setFilters(old => ({ ...old, end_date: e.target?.value }));
              if (e.target.value === null || e.target.value == '') {
                queryParams.delete('end_date');
              } else {
                queryParams.set(
                  'end_date',
                  moment(e.target.value, 'DD/MM/YYYY').format('YYYY-MM-DD')
                );
              }
            }}
            label="Até *"
            error={error.end_date}
            labelColor="white"
          />
        </Col>
        <Col md={2} xs={12}>
          <Select
            label="UF de descarga"
            options={provinceOptions}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            value={selectedProvince}
            onChange={value => {
              setFilters(old => ({ ...old, province: value?.value }));
              if (value === '' || value === null) {
                queryParams.delete('province');
              } else {
                queryParams.set('province', value?.value);
              }
              setSelectedProvince(value);
            }}
            labelTextColor="white"
          />
        </Col>
        <Col md={3} xs={12}>
          <Select.Async
            label="Tags de Carga"
            modalHeading="Selecione uma tag"
            modalBodyTitle="Tags:"
            value={loadTags}
            onSearch={search =>
              fetchSelectTags({ search, not_paginated: true })
            }
            onChange={value => {
              setFilters(old => ({ ...old, loadTags: value || null }));
              if (value) {
                queryParams.set(
                  'load_tags',
                  value.map(loadTag => loadTag.id).toString()
                );
              } else {
                queryParams.delete('load_tags');
              }
              setLoadTags(value);
            }}
            multiple
            horizontal
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            labelColor="#fff"
          />
        </Col>
        <Col md={3} xs={12}>
          <Select.Async
            label="Tags de Viagem"
            modalHeading="Selecione uma tag"
            modalBodyTitle="Tags:"
            value={travelTags}
            onSearch={search =>
              fetchSelectTags({ search, not_paginated: true })
            }
            onChange={value => {
              setFilters(old => ({ ...old, travelTags: value || null }));
              if (value) {
                queryParams.set(
                  'travel_tags',
                  value.map(travelTags => travelTags.id).toString()
                );
              } else {
                queryParams.delete('travel_tags');
              }
              setTravelTags(value);
            }}
            multiple
            horizontal
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            labelColor="#fff"
          />
        </Col>
        <Col md={3}>
          <Select.Async
            label="Centro de custo"
            onSearch={search => fetchCostCenter({ search })}
            value={costCenter}
            onChange={value => {
              setFilters(old => ({ ...old, cost_center: value?.id }));
              if (value) {
                queryParams.set('cost_center', value?.id);
              } else {
                queryParams.delete('cost_center');
              }
              setCostCenter(value);
            }}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            labelColor="#fff"
          />
        </Col>

        <Col md={1} xs={12} className="mt-4">
          <Button
            variant="primary"
            onClick={() => handleFilter()}
            loading={loading}
            height="fit-content"
            className="mt-1"
          >
            <Text type="regular" weight="500">
              Filtrar
            </Text>
          </Button>
        </Col>
        <Col md={1} xs={12} className="mt-4">
          <Button
            variant="success"
            onClick={() => exportReport()}
            loading={exportLoading}
            height="fit-content"
            className="mt-1"
          >
            <Text type="regular" weight="500">
              Exportar
            </Text>
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default TravelsArrivalFilter;
