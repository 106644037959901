import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import ApplicationLayout from 'v3/layouts/Application';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';

import useWindowResize from 'hooks/useWindowResize';

import { usePermission } from 'hooks';
import Section from '../shared/Section';
import DataInputs from '../shared/Data/Inputs';
import MainInputs from '../shared/Main/Inputs';
import OwnerInputs from '../shared/Owner/Inputs';
import ImplementsInputs from '../shared/Implements/Inputs';
import Drivers from '../shared/Drivers';
import TollTags from './TollTags';
import { useVehicle } from '../shared/context';

import { handleCreation } from './controller';

import * as Styled from './styles';

export default function VehicleRegister() {
  const history = useHistory();
  const vehicle = useVehicle();

  usePermission('CADASTRAR_VEICULOS', { redirect: true });

  const [isLoading, setLoading] = useState(false);
  // Transform into into a hook
  const [saveContainerOffset, setSaveContainerOffset] = useState(0);

  useWindowResize(() => {
    const header = document.getElementById('header-container');
    const originDistanceToRight =
      window.pageXOffset + header.getBoundingClientRect().right;
    const headerWidth = header.getBoundingClientRect().width;

    const SAVE_CONTAINER_PADDING = 0;
    const distanceToRight =
      originDistanceToRight - headerWidth + SAVE_CONTAINER_PADDING;
    setSaveContainerOffset(distanceToRight);
  }, []);

  return (
    <ApplicationLayout
      title="Cadastrar veículo"
      RightComponent={
        <Styled.SaveContainer style={{ right: saveContainerOffset }}>
          <Button variant="secondary" onClick={() => history.push('/veiculos')}>
            <Text weight={500} color="black" type="regular">
              Voltar
            </Text>
          </Button>

          <Button
            variant="success"
            onClick={() => {
              handleCreation(
                vehicle.data,
                vehicle.setData,
                vehicle.setErrors,
                vehicle.showSnackbar,
                setLoading
              );
            }}
            loading={isLoading}
          >
            <Text weight={500} color="white" type="regular">
              Salvar veículo
            </Text>
          </Button>
        </Styled.SaveContainer>
      }
    >
      <Row>
        <Col xs={12} md={6}>
          <Section Title="Veículo tração">
            <MainInputs />
          </Section>
          <Drivers />
          <TollTags />
        </Col>
        <Col xs={12} md={6}>
          <Section Title="Implementos">
            <ImplementsInputs />
          </Section>
          <Section Title="Conjunto">
            <DataInputs />
          </Section>
          <Section Title="Proprietário">
            <OwnerInputs />
          </Section>
        </Col>
      </Row>
    </ApplicationLayout>
  );
}
