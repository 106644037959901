/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect, useMemo, useContext } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { FaCheck } from 'react-icons/fa';
import api, { cooperplaceApi } from 'services/api';
import locationAPI from 'services/apis/location';
import { formatDateTime } from 'utils/formatter';
import { usePermission, useModal } from 'hooks';
import SwitchOption from 'v3/components/SwitchOption';
import Modal from 'v3/components/Modal';
import Button from 'v3/components/Button';
import { useSnackbar } from 'v3/components/Snackbar';
import { validatePermission } from 'actions/index';
import { useTravel } from 'v3/pages/Travel';
import Text from 'v3/components/Text';
import Select from 'v3/components/Select';
import { BlockedTravelContext } from 'contexts/BlockedTravelProvider';
import DriverRatingModal from './Modals/DriverRating';
import CancelledModal from './Modals/Cancelled';
import DuplicatedModal from './Modals/Duplicated';

import {
  ProgressCard,
  TabLink,
  ActiveTabContent,
  TabPanel,
  Marker,
} from './styles';

const STATUS_CODE = {
  IN_TRAVEL: 'ST3',
  DOCS_ISSUED: 'DOCS_ISSUED',
  FINISHED: 'ST6',
  CANCELLED: 'ST7',
  OVERFLOWED: 'ST10',
};

export const IMPORT_PAYMENTS_VALID_TRAVEL_STATUS = [3, 5]; // em viagem e em análise

export default function Progress({ onChangeSource, path, markers }) {
  const { isTravelBlocked, setTravelStatus } = useContext(BlockedTravelContext);
  const travel = useTravel();
  const params = useParams();
  const duplicatedModal = useModal();
  const snackbar = useSnackbar();
  const statusModal = useModal();
  const cteModal = useModal();
  const driverRatingModal = useModal();
  const cancelledModal = useModal();
  const { openIssuingModal } = travel;
  const hasReturnStatusPermission = usePermission('VOLTAR_STATUS_VIAGEM');
  const [sources, setSources] = useState([]);
  const [source, setSource] = useState({});
  const [switchTracker, setSwitchTracker] = useState(true);
  const [switchTrajectory, setSwitchTrajectory] = useState(true);
  const [duplicatedID, setDuplicatedID] = useState(null);
  const [optionsWithTravelBlocked, setOptionsWithTravelBlocked] = useState([]);

  const FINISHED_TRAVEL_STATUS_ID = 6;

  const havePermissionToChangeStatus = useMemo(
    () => validatePermission('ALTERAR_VIAGEM_STATUS'),
    []
  );

  function onStatusModalClose(confirm) {
    statusModal.close();
    if (!confirm) {
      travel.status.setStatus(travel.status.prevStatus);
      return;
    }
    if (travel.status.status.name !== 'Transbordado') {
      travel.status.setPrevStatus(travel.status.status);
    }
  }

  useEffect(() => {
    travel.status.fetchSteps();
  }, [params.id, cteModal.isOpen]);

  useEffect(() => {
    async function getTrackers() {
      const start = travel.data.createdAt;

      const lastDestination =
        travel.data.loadDestinations?.[travel.data.loadDestinations?.length];

      let end = lastDestination?.scheduledTime;

      const FINISHED_STATUS = [6, 7, 10];

      if (!FINISHED_STATUS.includes(travel.data.statusId)) {
        end = moment(lastDestination?.scheduledTime).add(3, 'days').toDate();
      }

      if (lastDestination?.effectiveTime) {
        end = lastDestination?.effectiveTime;
      }

      try {
        const response = await locationAPI.get(
          `range/cpf/${travel.data.driver?.cpfCnpj}/risk-managers`,
          {
            params: {
              start,
              end,
              attended_id: travel.data.id,
            },
          }
        );

        setSources(response.data.sources);
        setSource(response.data.sources[0]);
      } catch (ex) {
        // Handle exception
      }
    }

    if (!travel.isLoading) getTrackers();
  }, [
    travel.isLoading,
    travel.data.id,
    travel.data.createdAt,
    travel.data.loadDestinations,
    travel.data.statusId,
    travel.data.driver,
  ]);

  useEffect(() => {
    travel.status.setPrevStatus(travel.status.status);
    travel.status.setStatus(travel.data.travelStatus);
  }, [travel.data]);

  async function verifyTranshipmentChecklist() {
    try {
      const { data } = await cooperplaceApi.put(
        `travel-checklist/travel/${travel.data.id}`
      );

      if (data?.checklist?.required) {
        travel.setTranshipmentChecklist(data?.checklist);
        travel.transhipmentChecklistModal.open();
      } else {
        await travel.status.changeStatus();
      }
    } catch (error) {
      snackbar.show(<Text>Erro ao buscar checklist de transbordo</Text>, {
        type: 'error',
      });
    }
  }

  async function handleStatusChange(confirm) {
    onStatusModalClose(confirm);

    // Não deixar voltar o status para um status anterior;
    if (!hasReturnStatusPermission) {
      if (travel.status.status?.order < travel.status.prevStatus?.order) {
        snackbar.show(
          <Text>Não é possível voltar para um status anterior</Text>,
          {
            type: 'error',
          }
        );

        travel.status.setStatus(travel.status.prevStatus);
        return;
      }
    }

    // Cuidados para caso a viagem já esteja em status de finalizada/cancelada/transbordada
    if (
      travel.data.travelStatus.code === 'ST6' ||
      travel.data.travelStatus.code === 'ST7' ||
      travel.data.travelStatus.code === 'ST10'
    ) {
      if (travel.data.userCreated !== 183) {
        snackbar.show(
          <Text>
            {`Viagem ${
              travel.data.travelStatus.code === 'ST7'
                ? 'cancelada'
                : travel.data.travelStatus.code === 'ST6'
                ? 'finalizada'
                : 'transbordada'
            }, portanto, não é possível alterar o status.`}
          </Text>,
          { type: 'error', display: true }
        );
        travel.status.setStatus(travel.status.prevStatus);
        return;
      }
    }

    /// Tratamento para mudança de status em viagem
    if (travel.status.status.code === STATUS_CODE.DOCS_ISSUED) {
      snackbar.show(
        <Text>
          É necessário registrar um CT-e para alterar o status para em viagem
        </Text>,
        {
          type: 'error',
        }
      );
      travel.status.setStatus(travel.status.prevStatus);
      return;
    }

    if (travel.status.status.code === STATUS_CODE.OVERFLOWED) {
      await verifyTranshipmentChecklist();
      return;
    }

    // Mudança do status da viagem no back-end
    await travel.status.changeStatus();
  }

  function getModalBody() {
    return (
      <>
        <div className="text-center pt-4">
          <Text color="gray">
            {`Você tem certeza que gostaria de mudar o status para ${travel.status.status?.name?.toLowerCase()}?`}
          </Text>
        </div>
        <div className="text-center pt-4">
          <Button
            className="mr-1"
            variant="error"
            onClick={() => onStatusModalClose(false)}
          >
            Não
          </Button>
          <Button
            className="ml-1"
            variant="success"
            onClick={() => handleStatusChange(true)}
          >
            Sim
          </Button>
        </div>
      </>
    );
  }

  async function onStatusChange(value) {
    if (
      travel.data?.riskManagerStatus !== 'APPROVED' &&
      value.code.includes(['ST9'])
    ) {
      snackbar.show(
        <Text>
          Não é possível atualizar o status da viagem para "Aguardando emissão"
          enquanto a GR não liberar a viagem!
        </Text>,
        {
          type: 'error',
          duration: 80000,
        }
      );

      return;
    }

    if (travel.status.status.id === value.id) {
      return;
    }

    if (travel.status.status.code === STATUS_CODE.CANCELLED) {
      snackbar.show(
        <Text>Não é possível alterar o status de uma viagem cancelada.</Text>,
        {
          type: 'error',
        }
      );

      return;
    }

    const docsIssuedStatus = travel?.statuses?.find(
      s => s.code === STATUS_CODE.DOCS_ISSUED
    );

    if (value.code === STATUS_CODE.DOCS_ISSUED) {
      openIssuingModal();
    } else if (value.code === STATUS_CODE.FINISHED) {
      if (travel.data?.emitteds?.length) {
        driverRatingModal.open();
      } else {
        snackbar.show(
          <Text>
            Você não pode finalizar uma viagem cujo CT-e não foi emitido.
            Informe o CT-e ou cancele ela.
          </Text>,
          { type: 'error' }
        );
        return;
      }
    } else if (value.code === STATUS_CODE.CANCELLED) {
      if (
        [STATUS_CODE.OVERFLOWED, STATUS_CODE.FINISHED].includes(
          travel.data.travelStatus.code
        )
      ) {
        snackbar.show(
          <Text>
            Você não pode cancelar essa viagem pois ela já foi finalizada.
          </Text>,
          { type: 'error' }
        );

        return;
      }

      cancelledModal.open();
    } else if (
      value.order > docsIssuedStatus.order &&
      !travel.data?.emitteds?.length
    ) {
      snackbar.show(
        <Text>
          Você não pode alterar para esse status sem informar o CT-e. Para
          informar, altere o status para Documentação emitida.
        </Text>,
        { type: 'error' }
      );
    } else {
      statusModal.open();
    }

    travel.status.setPrevStatus(travel.status.status);
    travel.status.setStatus(value);
  }
  async function updateStatusDriverRatingModal() {
    try {
      await api.put(`travel-data/updateStatus/${travel.data.id}`, {
        value: FINISHED_TRAVEL_STATUS_ID,
      });
      return true;
    } catch (ex) {
      if (ex?.response?.status === 403) {
        snackbar.show(<Text>{ex?.response?.data}</Text>, {
          type: 'error',
        });
      } else {
        snackbar.show(<Text>Não foi possível finalizar essa viagem</Text>, {
          type: 'error',
        });
      }
      return false;
    }
  }

  const lastStep = travel.status.steps.find(
    step => step.code === travel.status.status?.code
  );

  useEffect(() => {
    setTravelStatus(travel.status.status || {});
  }, [travel.status.status]);

  useEffect(() => {
    if (isTravelBlocked && travel?.statuses) {
      const availableStatusWithTravelBlocked = travel.statuses.filter(
        option => option.name === 'Cancelado' || option.name === 'Bloqueado'
      );
      setOptionsWithTravelBlocked(availableStatusWithTravelBlocked);
    }
  }, [isTravelBlocked, travel?.statuses]);

  useEffect(() => {
    setTravelStatus(travel.status.status || {});
  }, [travel.status.status]);

  useEffect(() => {
    if (isTravelBlocked && travel?.statuses) {
      const availableStatusWithTravelBlocked = travel.statuses.filter(
        option => option.name === 'Cancelado' || option.name === 'Bloqueado'
      );
      setOptionsWithTravelBlocked(availableStatusWithTravelBlocked);
    }
  }, [isTravelBlocked, travel?.statuses]);

  return (
    <>
      <Modal
        scrollable
        show={statusModal.isOpen}
        handleClose={() => onStatusModalClose()}
        heading={
          <Text type="header" color="dark" weight="500">
            Alterar status
          </Text>
        }
        body={getModalBody()}
      />
      <DriverRatingModal
        isOpen={driverRatingModal.isOpen}
        onClose={async isCancelled => {
          driverRatingModal.close();
          let confirm = true;
          if (isCancelled) {
            travel.status.setStatus(travel.data.travelStatus);
          } else {
            travel.fetchPreSM();
            travel.fetchFeed();
            travel.status.fetchSteps();
            travel.data.travelStatus.id = travel.status.status.id;
            const validNextStatus = await updateStatusDriverRatingModal();
            if (!validNextStatus) {
              confirm = false;
            }
          }

          if (confirm) {
            travel.status.fetchSteps();
          }
          onStatusModalClose(confirm);
        }}
      />

      <CancelledModal
        isOpen={cancelledModal.isOpen}
        onClose={isCancelled => {
          onStatusModalClose();
          cancelledModal.close();

          if (isCancelled) {
            travel.status.setStatus(travel.data.travelStatus);
          } else {
            travel.fetchPreSM();
            travel.fetchFeed();
          }

          if (duplicatedID) {
            duplicatedModal.open();
          }
        }}
        setDuplicatedID={setDuplicatedID}
      />
      <DuplicatedModal
        isOpen={duplicatedModal.isOpen}
        onClose={duplicatedModal.close}
        setDuplicatedID={setDuplicatedID}
        duplicatedID={duplicatedID}
      />
      <ProgressCard>
        <Tab.Container id="progress-tabs" defaultActiveKey="progress">
          <Nav>
            <TabLink eventKey="progress">Progresso da viagem</TabLink>
            <TabLink eventKey="options">Opções</TabLink>
          </Nav>
          <ActiveTabContent>
            <TabPanel eventKey="progress">
              <Select
                label="Alterar status"
                disableClear
                value={travel.status.status}
                options={
                  isTravelBlocked
                    ? optionsWithTravelBlocked
                    : travel.statuses.filter(({ code }) => code !== 'BLOCKED')
                }
                getOptionLabel={option => option?.name}
                getOptionValue={option => option?.id}
                onChange={onStatusChange}
                isDisabled={
                  !travel.data?.driver ||
                  !travel.data?.vehicle?.[0] ||
                  !havePermissionToChangeStatus
                }
              />
              <div className="mt-4">
                {travel.status.steps.map((item, index) => {
                  const completed =
                    (item.completed && item.order <= lastStep?.order) ||
                    item.id === travel.data.statusId;

                  return (
                    <div className="d-flex pl-2">
                      <div>
                        {index < travel.status.steps.length - 1 && (
                          <Marker.Tray completed={completed} />
                        )}
                      </div>
                      <div className="position-relative pl-4">
                        <Marker
                          isLast={index === travel.status.steps.length - 1}
                          completed={completed}
                        >
                          {completed && <FaCheck color="#fff" size={9} />}
                        </Marker>
                        <Text>{item.name}</Text>
                        {completed && (
                          <Text color="gray" as="p" type="label">
                            {item.completed
                              ? formatDateTime(item.completed)
                              : formatDateTime(travel.data.updatedAt)}
                          </Text>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </TabPanel>

            <TabPanel eventKey="options">
              <Text type="label">Fonte das informações</Text>
              <Select
                value={source}
                options={sources}
                getOptionLabel={option => option?.label}
                getOptionValue={option => option?.label}
                onChange={event => {
                  setSource(event);
                  onChangeSource(event);
                }}
              />
              <div style={{ paddingTop: '10px' }}>
                <Text type="label"> Exibir marcadores </Text>
                <SwitchOption
                  activeText="Sim"
                  inactiveText="Não"
                  value={switchTracker}
                  onClick={() => {
                    setSwitchTracker(old => !old);
                    markers(!switchTracker);
                  }}
                />
              </div>
              <div style={{ paddingTop: '25px' }}>
                <Text type="label">Exibir Trajetória</Text>
                <SwitchOption
                  activeText="Sim"
                  inactiveText="Não"
                  value={switchTrajectory}
                  onClick={() => {
                    setSwitchTrajectory(old => !old);
                    path(!switchTrajectory);
                  }}
                />
              </div>
            </TabPanel>
          </ActiveTabContent>
        </Tab.Container>
      </ProgressCard>
    </>
  );
}
