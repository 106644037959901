import React, { useState } from 'react';
import { sanitize } from 'dompurify';

import { Modal, Text, Input, InputAddress } from 'v3/components';
import Space from 'v3/components/Space';
import { usePolicyRegister } from '../../context';
import { fetchByCityAndProvince, fetchCities } from 'utils/fetches';
import { RiskAreaSchema } from '../../../validators';
import { useSnackbar } from 'v3/components/Snackbar';
import { StyledModalButton } from 'v3/pages/Policy/styles';

export function RiskAreaModal() {
  const {
    riskAreaModal,
    operations,
    setOperations,
    currentOpIndex,
    setCurrentOpIndex,
    currentPdIndex,
    setCurrentPdIndex,
    currentVehicleIndex,
    setCurrentVehicleIndex,
    currentRangeIndex,
    setCurrentRangeIndex,
  } = usePolicyRegister();
  const snackbar = useSnackbar();
  const [newRiskArea, setNewRiskArea] = useState({ city: {}, radius: null });
  const [errors, setErrors] = useState({});
  async function handleAdd() {
    const validation = await validate(newRiskArea, setErrors);

    if (
      !validation?.valid &&
      newRiskArea?.city?.name &&
      validation?.errors['city.id']
    ) {
      snackbar.show(
        <Text color="dark">
          Cidade não cadastrada na base Cooperplace, solicite cadastro.
        </Text>,
        {
          type: 'error',
          showClose: true,
        }
      );
      setErrors(old => ({ ['city.id']: old['city.id'] }));
      return;
    } else if (!validation?.valid) {
      snackbar.show(<Text color="dark">Verifique campos obrigatórios.</Text>, {
        type: 'error',
        showClose: true,
      });
      return;
    }

    let newState = [...operations];
    newState[currentOpIndex].products[currentPdIndex].vehicles[
      currentVehicleIndex
    ].ranges[currentRangeIndex].riskAreas = [
      ...newState[currentOpIndex].products[currentPdIndex].vehicles[
        currentVehicleIndex
      ].ranges[currentRangeIndex].riskAreas,
      newRiskArea,
    ];
    setOperations(newState);
    setNewRiskArea({});
    setCurrentOpIndex(null);
    setCurrentPdIndex(null);
    setCurrentVehicleIndex(null);
    setCurrentRangeIndex(null);
    riskAreaModal.close();
  }
  function handleCloseModal() {
    setNewRiskArea({});
    setErrors({});
    setCurrentOpIndex(null);
    setCurrentPdIndex(null);
    setCurrentVehicleIndex(null);
    setCurrentRangeIndex(null);
    riskAreaModal.close();
  }
  async function validate(data, setErrors) {
    const errorList = {};
    try {
      await RiskAreaSchema.validate(data, { abortEarly: false });
      setErrors({ ...errorList });
      return { valid: true, errors: errorList };
    } catch (err) {
      if (err.inner) {
        err.inner.forEach(error => {
          errorList[error.path] = error.message;
        });

        setErrors({ ...errorList });
        return { valid: false, errors: errorList };
      }
    }
  }
  return (
    <Modal
      size="lg"
      show={riskAreaModal.isOpen}
      handleClose={handleCloseModal}
      heading={
        <Text type="body" color="dark" weight="400">
          Inclusão de área de risco
        </Text>
      }
      body={
        <div>
          <InputAddress
            label="Município *"
            typesRestriction={['(cities)']}
            placeholder={'Busque um município...'}
            removeCountryRestriction
            onSelectedAddress={async place => {
              const [city] = await fetchByCityAndProvince(
                place.city,
                place.province
              );
              setNewRiskArea(old => ({
                ...old,
                city: {
                  id: city?.id,
                  lat: place?.lat,
                  lng: place?.lng,
                  name: city?.name || place?.name,
                },
              }));
            }}
            error={errors['city.name'] || errors['city.id']}
          />
          <Input
            label={'Raio (km) *'}
            value={newRiskArea?.radius}
            onChange={e => {
              let radio = e?.target?.value;
              setNewRiskArea(old => ({ ...old, radius: radio }));
            }}
            error={errors?.radius}
          />
          <div style={{ display: 'flex', gap: '0.5rem' }}>
            <Input
              label="Latitude *"
              disabled={true}
              tooltip="Selecione um município sugerido para obter dados de latitude e longitude"
              value={newRiskArea?.city?.lat}
              error={errors['city.lat']}
            />
            <Input
              label="Longitude *"
              disabled={true}
              tooltip="Selecione um município sugerido para obter dados de latitude e longitude"
              value={newRiskArea?.city?.lng}
              error={errors['city.lng']}
            />
          </div>
        </div>
      }
      footer={
        <Space>
          <StyledModalButton size="sm" onClick={handleAdd}>
            <Text>Adicionar</Text>
          </StyledModalButton>
        </Space>
      }
    />
  );
}
