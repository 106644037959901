import styled from 'styled-components'
import {Col} from 'react-bootstrap'

export const DealSpan = styled.span`
  border-left: 1px gray solid;
  position: relative;
  padding-left: 10px;
`;

export const VisualizationSpan = styled.span`
  padding-right: 10px;
`;

export const StyledDriverMatchCol = styled(Col)`
  display:flex;
  align-items: center;
`;

export const StyledDriverDiv = styled.div`
  flex:1;
`;

export const StyledMatchStatusDiv = styled.div`
  text-align: center;
  padding:1rem;
  & > p {
    display:flex;
    justify-content: space-between;

    & > span {
      cursor: help;
    }
  }
`;

export const StyledMatchContactDiv = styled.div`
  text-align: center;
  padding: 1rem;

  a {
    cursor: pointer;
  }
`;
