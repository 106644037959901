import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSnackbar } from 'v3/components/Snackbar';
import { cooperplaceApi } from 'services/api';
import { InfiniteScroll } from 'v3/components';
import { Text } from 'v3/components';
import { Button } from 'v3/components';
import { MatchVehiclesCard } from './VehiclesCard';
import ApplicationLayout from 'v3/layouts/Application';
import { validatePermission } from 'actions';

export function ListMatches() {
  const params = useParams();
  const history = useHistory();
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [matches, setMatches] = useState([]);
  const [timeValue, setTimeValue] = useState(500);
  const [pagination, setPagination] = useState({
    last_page: 1,
    current_page: 1,
    total: 0,
  });

  const hasPermissionToVisualize = validatePermission(
    'VISUALIZAR_AGREGAMENTOS'
  );

  useEffect(() => {
    if (!hasPermissionToVisualize) {
      snackbar.show('Você não tem permissão para buscar veículos', {
        type: 'error',
      });
      history.push('/');
    }
  }, [hasPermissionToVisualize]);

  async function fetchMatches(page = 1) {
    if (page === 1) {
      setLoading(true);
    }
    try {
      const { data } = await cooperplaceApi.get(
        `aggregation/${params.id}/match`
      );
      const response = data.data;
      setPagination(data.meta);
      setMatches(oldMatches => {
        if (page === 1) {
          return response;
        }
        return [...oldMatches, ...response];
      });
    } catch (error) {
      snackbar.show(
        <Text>
          Erro ao buscar veículos compatíveis. Tente novamente mais tarde.
        </Text>,
        {
          type: 'error',
        }
      );
    } finally {
      setTimeout(() => {
        setLoading(false);
        setTimeValue(0);
      }, timeValue);
    }
  }

  useEffect(() => {
    fetchMatches();
  }, []);

  function renderItem(match) {
    return <MatchVehiclesCard match={match} key={match.id} />;
  }

  return (
    <ApplicationLayout
      title="Busca de veículos"
      RightComponent={
        <>
          <Button
            className="py-2"
            onClick={() => history.goBack()}
            variant="secondary"
          >
            <Text weight={500} type="regular">
              Voltar
            </Text>
          </Button>
        </>
      }
    >
      <InfiniteScroll
        data={matches}
        onEndReach={() => {
          fetchMatches(pagination.current_page + 1);
        }}
        hasMore={pagination.current_page < pagination.last_page}
        scrollThreshold={0.7}
        renderItem={renderItem}
        loading={loading}
      />
    </ApplicationLayout>
  );
}
