import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import api from 'services/api';
import Text from 'v3/components/Text';

import { useSnackbar } from 'v3/components/Snackbar';
import { useShippingCompany } from '../shared/context';

import SharedData from '../shared/Section';
import Inputs from '../shared/PaymentMethod/index';
import Editable from '../shared/Editable';

export default function PaymentMethod() {
  const shippingCompany = useShippingCompany();
  const snackbar = useSnackbar();
  const [isEditing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  async function handleSubmit() {
    setLoading(true);

    if (
      !shippingCompany?.data?.shippingCompanies?.payment?.id &&
      shippingCompany.data.is_driver
    ) {
      setError('Método de pagamento obrigatório');
      snackbar.show(<Text>Adicione um método de pagamento</Text>, {
        type: 'error',
      });
      setLoading(false);
      return;
    }

    try {
      await api.put(
        `v3/shipping-company/payment/${shippingCompany?.data?.id}`,
        {
          paymentMethod: shippingCompany.data?.shippingCompanies?.payment?.id,
        }
      );
      shippingCompany.setPrevShippingCompany(shippingCompany?.data);
      snackbar.show(<Text>Salvo com sucesso</Text>, { type: 'success' });
      setLoading(false);
      setEditing(false);
      setError('');
    } catch (err) {
      snackbar.show(<Text>Erro ao salvar</Text>, { type: 'error' });
    } finally {
      setLoading(false);
    }
  }

  return (
    <SharedData
      Title="Métodos de pagamento"
      HeaderRightComponent={
        <Editable
          isEditing={isEditing}
          setEditing={setEditing}
          onSave={handleSubmit}
          isLoading={loading}
        />
      }
      loading={shippingCompany.isFetching}
    >
      {isEditing ? (
        <Inputs error={error} />
      ) : (
        <Row>
          <Col xs={12}>
            <Text type="regular" color="#494950" as="p" className="mt-2">
              {shippingCompany.data?.shippingCompanies?.payment?.name ||
                'Não informado'}
            </Text>
          </Col>
        </Row>
      )}
    </SharedData>
  );
}
