import React, { useState, useCallback, useEffect } from 'react';

import moment from 'moment';

import dashboardApi from 'services/apis/dashboard';
import { Table, Text, Link, Button } from 'v3/components';
import { formatDateTime } from 'v3/utils/formatter';
import { useQuery } from 'hooks';
import Card, { Number, Description, SeeAll } from './Card';

export default function LoadsCancelled({ dashboard, name, id }) {
  const [isFetching, setFetching] = useState(false);
  const [data, setData] = useState({});
  const [filter, setFilter] = useState({});
  const [currentPage, setCurrentPage] = useState({});
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [daysFromNow, setDaysFromNow] = useState();
  const queryParams = useQuery();

  useEffect(() => {
    setDaysFromNow(moment(new Date()).diff(filter.start_date, 'days'));
  }, [filter.start_date]);

  function setQueryParams(filters) {
    const { tags = [], start_date, end_date } = filters;

    queryParams.delete('tags');

    if (tags) {
      tags.forEach(tag => {
        queryParams.append('tags', tag.id);
      });
    }

    if (start_date) {
      queryParams.set('start_date', start_date);
    }
    if (end_date) {
      queryParams.set('end_date', end_date);
    }

    queryParams.set('only_expired', 1);
    queryParams.set('expired', 1);
    queryParams.set('status', 'Não atendidas');

    if (tags?.length === 0) {
      queryParams.set('no-tags', true);
    } else {
      queryParams.delete('no-tags');
    }
  }

  const fetchLoadsCancelled = useCallback(async (filters = {}) => {
    try {
      setLoading(true);
      const {
        tags = [],
        clients = [],
        start_date,
        end_date,
        page = 1,
        costCenters = [],
        pcps = [],
        companies = [],
      } = filters;

      if (page === 1) {
        setFetching(true);
      }

      setQueryParams(filters);
      setFilter(filters);
      setCurrentPage(+page);

      const response = await dashboardApi.get('loads-cancelled-controller', {
        params: {
          tags: tags?.map(tag => tag.id),
          clients: clients?.map(client => client.id),
          start_date,
          costCenters: costCenters?.map(costCenter => costCenter?.id),
          pcps: pcps?.map(pcp => pcp?.id),
          companies: companies?.map(company => company?.id),
          end_date: end_date || undefined,
          page,
        },
      });

      setPagination(response.data?.last?.pagination);

      if (page === 1) {
        setData(response?.data);
      } else {
        setData(oldData => ({
          ...oldData,
          last: {
            ...oldData.last,
            data: [...oldData.last.data, ...response.data.last.data],
          },
        }));
      }
    } catch (error) {
      // Handle exeception
    } finally {
      setFetching(false);
      setLoading(false);
    }
  }, []);

  return (
    <Card
      title="Cargas canceladas"
      isLoading={isFetching}
      fetchFn={fetchLoadsCancelled}
      onFiltersChanged={fetchLoadsCancelled}
      dashboard={dashboard}
      name={name}
      excludeFilterByBranchOffice={true}
      id={id}
      excludeFilterByNegotiator
      ListComponent={
        <>
          <Table>
            <thead>
              <tr>
                <th>
                  <Text weight="500" color="dark">
                    Centro de custo
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Empresa
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Tags da carga
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Nº Pedido Cliente
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Carga
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Vencimento
                  </Text>
                </th>

                <th>
                  <Text weight="500" color="dark">
                    Origem
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Destino
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Cliente
                  </Text>
                </th>
              </tr>
            </thead>
            <tbody>
              {data.last?.data?.map(expiredLoad => {
                const title = `Cliente ${expiredLoad.social_name} - ${expiredLoad.cgccpf}`;
                return (
                  <tr>
                    <td>
                      <Text type="regular" color="gray">
                        {expiredLoad?.cost_center || '-'}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray">
                        {expiredLoad?.company_name || '-'}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray">
                        {expiredLoad?.load_tags || '-'}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray">
                        {expiredLoad?.client_order_number || '-'}
                      </Text>
                    </td>
                    <td>
                      <Link
                        to={`/cargas/${expiredLoad.id}`}
                        title={title}
                        target="_blank"
                      >
                        {expiredLoad.id}
                      </Link>
                    </td>
                    <td>
                      <Text type="regular" color="gray">
                        {formatDateTime(expiredLoad.due_date)}
                      </Text>
                    </td>

                    <td>
                      <Text type="regular" color="dark" truncate>
                        {`${expiredLoad?.city_origin} - ${expiredLoad.uf_origin}`}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="dark" truncate>
                        {`${expiredLoad?.city_destination} - ${expiredLoad.uf_destination}`}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray" truncate>
                        {expiredLoad.social_name}
                      </Text>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {pagination?.lastPage > 1 && pagination?.to < pagination?.total && (
            <div className="text-center mt-3 col-12">
              <Button
                className="mt-2 ml-auto align-item-center"
                onClick={() => {
                  fetchLoadsCancelled({
                    page: currentPage + 1,
                    tags: filter?.tags,
                    clients: filter?.clients,
                    start_date: filter?.start_date,
                    end_date: filter?.end_date,
                    costCenters: filter?.costCenters,
                    pcps: filter?.pcps,
                    companies: filter?.companies,
                  });
                }}
                loading={loading}
              >
                <Text type="regular" color="white" truncate>
                  Ver mais
                </Text>
              </Button>
            </div>
          )}

          {!!data?.total && (
            <SeeAll
              to={{
                pathname: '/cargas',
                search: queryParams.toString(),
              }}
            >
              ver todas as {data?.total} cargas
            </SeeAll>
          )}
        </>
      }
      NumericComponent={
        <div className="d-flex justify-content-center text-center flex-column">
          <Number color="error">{data?.total}</Number>
          <Description>
            canceladas
            {daysFromNow > 1
              ? ` nos últimos ${daysFromNow} dias`
              : ` no último dia`}
          </Description>
        </div>
      }
    />
  );
}
