import React, { useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useParams } from 'react-router-dom';

import Input from 'v3/components/Input';
import TextArea from 'v3/components/TextArea';

import { useClient } from '../context';

export default function ClientSettings() {
  const client = useClient();
  const params = useParams();

  useEffect(() => {
    if (!params.id)
      client.setData({
        settings: [
          {
            type: 'OBSDEFAULT',
            value: '',
          },
          { type: 'PRIORITY_FLEET_LOAD_DURATION', value: '' },
          {
            type: 'REQUIRED_CHECKLIST',
            value: '0',
          },
        ],
      });
  }, []);

  function handleTextChange(event) {
    client.setData({
      settings: client.data.settings?.map(item => {
        if (item.type === 'OBSDEFAULT') {
          return { ...item, value: event.target.value };
        }
        return item;
      }),
    });
  }

  function handlePriorityFleetChange(event) {
    client.setData({
      settings: client.data.settings?.map(item => {
        if (item.type === 'PRIORITY_FLEET_LOAD_DURATION') {
          return { ...item, value: event.target.value };
        }
        return item;
      }),
    });
  }

  return (
    <Row className="form">
      <Col xs={12} className="mb-3">
        <TextArea
          label="Observações da carga"
          resizable={false}
          value={
            client.data.settings?.find(item => item.type === 'OBSDEFAULT')
              ?.value
          }
          onChange={handleTextChange}
        />
      </Col>
      <Col md={6}>
        <Input
          label="Duração da frota proritária"
          type="text"
          masked
          mask={[/^([0-9])/, /([0-9])/, ':', /[0-5]/, /[0-9]/]}
          onChange={handlePriorityFleetChange}
          value={
            client.data.settings?.find(
              item => item.type === 'PRIORITY_FLEET_LOAD_DURATION'
            )?.value
          }
          error={
            client.errors['settings[1].value'] ||
            client.errors['settings[0].value'] ||
            client.errors['settings[2].value']
          }
        />
      </Col>
    </Row>
  );
}
