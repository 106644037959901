/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';

import api from 'services/api';

import { useSnackbar } from 'v3/components/Snackbar';
import ModalRaw from 'v3/components/Modal';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import Indicators from '../IndicatorsBody';

import { options } from '../../utils';

function Modal({
  isOpen,
  onClose,
  dashboardSelected,
  setSelected,
  components,
}) {
  const [loading, setLoading] = useState(false);
  const snackbar = useSnackbar();
  const [personalizedOptions, setOptions] = useState(
    options
      .flatMap(component => component.parameters.items)
      ?.map(item => ({ ...item, value: 0 }))
      .reduce((acc, current) => {
        const x = acc.find(item => item.component === current.component);
        if (!x) {
          return acc.concat([current]);
        }
        return acc;
      }, [])
      .map(option => {
        if (components)
          components.forEach(component => {
            if (component?.name === option.component) option.value += 1;
          });
        return option || null;
      })
  );

  useEffect(() => {
    setOptions(
      options
        .flatMap(component => component.parameters.items)
        ?.map(item => ({ ...item, value: 0 }))
        .reduce((acc, current) => {
          const x = acc.find(item => item.component === current.component);
          if (!x) {
            return acc.concat([current]);
          }
          return acc;
        }, [])
        .map(option => {
          if (components)
            components.forEach(component => {
              if (component?.name === option.component) option.value += 1;
            });
          return option;
        })
    );
  }, [components]);

  function handleAdjustComponents() {
    const componentArray = [];
    personalizedOptions.forEach(component => {
      for (let index = 0; index < component.value; index++) {
        componentArray.push({
          component: component.component,
          title: component.title,
        });
      }
    });
    return componentArray;
  }
  async function handleSaveDashboard() {
    try {
      setLoading(true);
      const response = await api.put(
        `v3/dashboard/${dashboardSelected.id}/indicators`,
        {
          components: handleAdjustComponents(),
        }
      );
      setSelected(response.data);
      snackbar.show(<Text>Dashboard editada com sucesso!</Text>, {
        type: 'success',
      });
    } catch (error) {
      snackbar.show(
        <Text>
          Não foi possível alterar os dados, tente novamente mais tarde!
        </Text>,
        { type: 'error' }
      );
    } finally {
      setLoading(false);
      onClose();
    }
  }

  return (
    <>
      <ModalRaw
        scrollable
        show={isOpen}
        onHide={onClose}
        size="lg"
        heading={
          <Text type="header" color="dark">
            Editar Dashboard
          </Text>
        }
        body={
          <Indicators
            personalizedDashboardOptions={personalizedOptions}
            setPersonalizedDashboardOptions={setOptions}
          />
        }
        footer={
          <>
            <Button
              disabled={!personalizedOptions.find(item => item.value > 0)}
              className="py-2"
              onClick={handleSaveDashboard}
              loading={loading}
              variant="success"
            >
              <Text weight="500" type="regular">
                Salvar
              </Text>
            </Button>
          </>
        }
      />
    </>
  );
}

export default Modal;
