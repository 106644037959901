import React from 'react';

import { Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import Modal from 'v3/components/Modal';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import { useRasterBranches } from '../../shared/context';

function Created({ isOpen, onClose }) {
  const rasterBranches = useRasterBranches();
  const history = useHistory();

  return (
    <>
      <Modal
        size="lg"
        show={isOpen}
        onHide={onClose}
        heading="Cadastro filiais raster"
        body={
          <Row className="mx-0 mt-1">
            <Text weight="400" color="dark">
              Filial {rasterBranches.data.name} cadastrado com sucesso!
            </Text>
          </Row>
        }
        footer={
          <Row md={12} xs={12} className="mr-3 mt-1">
            <Button
              variant="white"
              className="mr-2 mt-1"
              outlined
              size="sm"
              onClick={() => history.push('/filiais-raster')}
            >
              <Text weight="500">Ver listagem</Text>
            </Button>

            <Button
              className="mr-2 mt-1"
              variant="primary"
              size="sm"
              onClick={() =>
                history.push(`/filiais-raster/${rasterBranches?.data?.id}`)
              }
            >
              <Text weight="500">Visualizar</Text>
            </Button>

            <Button
              variant="success"
              className="mr-2 mt-1"
              size="sm"
              onClick={() => {
                window.location.reload(false);
              }}
            >
              <Text weight="500">Cadastrar novo</Text>
            </Button>
          </Row>
        }
      />
    </>
  );
}

export default Created;
