const Yup = require('yup');

const schema = Yup.object().shape({
  serie: Yup.number()
    .required('Série obrigatória')
    .typeError('Série obrigatória'),
  number: Yup.number()
    .required('Número obrigatório')
    .typeError('Número obrigatório'),
});

export default schema;
