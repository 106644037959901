import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import ApplicationLayout from 'v3/layouts/Application';
import { parseErrors } from 'v3/utils/formatter';
import Button from 'v3/components/Button';
import { useSnackbar } from 'v3/components/Snackbar';
import Text from 'v3/components/Text';
import { useValidation, usePermission } from 'hooks';
import useWindowResize from 'hooks/useWindowResize';
import api from 'services/api';
import moment from 'moment';
import { validatePermission } from 'actions/index';
import RegisteredModal from './Modals';
import Section from '../shared/Section';
import Data from '../shared/Data/index';
import PaymentMethod from '../shared/PaymentMethod/index';
import Address from '../shared/Address/index';
import AttachedDrivers from '../shared/AttachedDrivers';
import AttachedVehicles from '../shared/AttachedVehicles';
import { shippingCompanyRegistrationSchema } from '../shared/validator';
import { useShippingCompany } from '../shared/context';

import * as Styled from './styles';

export default function ShippingCompanyRegister() {
  const history = useHistory();
  const snackbar = useSnackbar();
  const shippingCompany = useShippingCompany();

  const [isLoading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [saveContainerOffset, setSaveContainerOffset] = useState(0);

  const [errors, { validate }] = useValidation(
    shippingCompanyRegistrationSchema
  );
  usePermission('CADASTRAR_TRANSPORTADORA', { redirect: true });
  const hasPermissionToCreate = validatePermission('CADASTRAR_TRANSPORTADORA');

  useEffect(() => {
    shippingCompany.setErrors(errors);
  }, [errors]);

  async function handleCreation() {
    setLoading(true);

    try {
      shippingCompany.data.payment_method =
        shippingCompany.data?.shippingCompanies?.payment?.id;
      const [isValid] = await validate({
        data: shippingCompany.data,
        address: shippingCompany.data?.addresses?.[0],
      });

      if (!isValid) {
        snackbar.show(<Text>Verifique os campos obrigatórios</Text>, {
          type: 'error',
        });
        setLoading(false);
        return;
      }

      const payload = {
        data: {
          type_id: shippingCompany.data.personType.name === 'Jurídica' ? 3 : 2,
          cgccpf: shippingCompany.data.cgccpf,
          ie: shippingCompany.data.ie || null,
          social_name: shippingCompany.data.social_name,
          fantasy_name: shippingCompany.data.fantasy_name || null,
          phone: shippingCompany.data.phone,
          phone_unmasked: shippingCompany.data.phone.replace(/[^0-9]+/g, ''),
          email: shippingCompany.data.email,
          is_driver: !!shippingCompany.data?.is_driver,
          rntrc_number: shippingCompany?.data?.rntrc_number || null,
          rntrc_exp_date:
            moment(
              shippingCompany.data?.rntrc_exp_date,
              'DD/MM/YYYY'
            ).toDate() || null,
        },
        address: {
          address: shippingCompany.data?.addresses?.[0]?.address,
          city_name: shippingCompany.data?.addresses?.[0]?.city_name,
          uf_name: shippingCompany.data?.addresses?.[0]?.uf_name,
          neighborhood: shippingCompany.data?.addresses?.[0]?.neighborhood,
          number: shippingCompany.data?.addresses?.[0]?.number,
          zip_code: shippingCompany.data?.addresses?.[0]?.zip_code,
          complement: shippingCompany.data?.addresses?.[0]?.complement,
          country_id: shippingCompany.data?.addresses?.[0]?.country?.id,
        },
        payment_method_id: shippingCompany.data?.shippingCompanies?.payment?.id,
        drivers: shippingCompany.data?.shippingDrivers.map(
          driver => driver.driver.id
        ),
        vehicles: shippingCompany.data?.vehicles,
      };

      const response = await api.post('v3/shipping-company', payload);

      shippingCompany.showSnackbar('Transportadora cadastrada', {
        type: 'success',
      });

      setLoading(false);

      shippingCompany.setData({ id: response.data.id });
      if (response.data.id) {
        setOpenModal(true);
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.data && Array.isArray(error?.response?.data)) {
        const parsedErrors = parseErrors(error?.response?.data, {
          getPath: e => e.field,
        });
        shippingCompany.setErrors(parsedErrors);
      } else {
        shippingCompany.showSnackbar('Erro ao cadastrar transportadora!', {
          type: 'error',
        });
      }
    }
  }

  useWindowResize(() => {
    const header = document.getElementById('header-container');
    const originDistanceToRight =
      window.pageXOffset + header.getBoundingClientRect().right;
    const headerWidth = header.getBoundingClientRect().width;

    const SAVE_CONTAINER_PADDING = 0;
    const distanceToRight =
      originDistanceToRight - headerWidth + SAVE_CONTAINER_PADDING;
    setSaveContainerOffset(distanceToRight);
  }, []);

  return (
    <>
      <RegisteredModal isOpen={openModal} onClose={() => setOpenModal(false)} />
      <ApplicationLayout
        title="Transportadora"
        RightComponent={
          <Styled.SaveContainer style={{ right: saveContainerOffset }}>
            <Button
              variant="secondary"
              onClick={() => history.push('/transportadoras')}
            >
              <Text weight={500} color="black" type="regular">
                Voltar
              </Text>
            </Button>

            <Button
              variant="success"
              onClick={() => {
                handleCreation();
              }}
              loading={isLoading}
              disabled={!hasPermissionToCreate}
            >
              <Text weight={500} color="white" type="regular">
                Salvar
              </Text>
            </Button>
          </Styled.SaveContainer>
        }
      >
        <Row>
          <Col md={6}>
            <Section Title="Dados da transportadora">
              <Data />
            </Section>
            <Section Title="Métodos de pagamento">
              <PaymentMethod
                error={shippingCompany?.errors?.['data.payment_method']}
              />
            </Section>
          </Col>
          <Col md={6}>
            <Address />
            <AttachedVehicles />
          </Col>
          <Col md={12}>
            <AttachedDrivers />
          </Col>
        </Row>
      </ApplicationLayout>
    </>
  );
}
