/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FaPercentage, FaTrash, FaPencilAlt } from 'react-icons/fa';

import Card from 'v3/components/Card';
import Text from 'v3/components/Text';
import Table from 'v3/components/Table';
import Radio from 'v3/components/Radio';
import Button from 'v3/components/Button';
import Select from 'v3/components/Select';
import TextArea from 'v3/components/TextArea';
import { useSnackbar } from 'v3/components/Snackbar';
import InputCurrency from 'v3/components/Input/Currency';

import {
  fetchProvinces,
  fetchClients,
  fetchProducts,
  saveIcms,
  validateAdd,
} from './controller';

export default function Icms({
  issuer,
  taxSituations,
  handleGetIssuer,
  setAddingNcm,
  setAddProductModal,
  canEditIssuers,
}) {
  const snackbar = useSnackbar();

  const [provinces, setProvinces] = useState();
  const [fromProvince, setFromProvince] = useState();
  const [toProvince, setToProvince] = useState();
  const [rate, setRate] = useState();
  const [client, setClient] = useState();
  const [product, setProduct] = useState();
  const [legalText, setLegalText] = useState();
  const [taxBaseReduction, setTaxBaseReduction] = useState(0);
  const [isExempt, setExempt] = useState(false);
  const [taxSituation, setTaxSituation] = useState();
  const [list, setList] = useState([]);
  const [errors, setErrors] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [icmsId, setIcmsId] = useState();
  const [unchangedIcmsList, setUnchangedIcmsList] = useState([]);

  async function getProvinces() {
    const response = await fetchProvinces();
    setProvinces(response);
  }

  useEffect(() => {
    getProvinces();
  }, []);

  async function handleAddIcms() {
    if (!(await validateAdd({ fromProvince, toProvince, rate }, setErrors)))
      return false;

    setList(oldList => [
      ...oldList,
      {
        client,
        product,
        fromProvince,
        toProvince,
        rate,
        id: icmsId,
        taxBaseReduction,
        legalText,
        isExempt,
        taxSituation,
      },
    ]);

    setClient(null);
    setProduct(null);
    setFromProvince(null);
    setToProvince(null);
    setRate('0.00');
    setIcmsId(null);
    setTaxBaseReduction('0.00');
    setLegalText('');
    setExempt(false);
    setTaxSituation(null);

    return true;
  }

  function handleEditIcms(index) {
    setIcmsId(list[index].id);
    setFromProvince(
      provinces.find(
        province =>
          province.id === (list[index].uf_from || list[index].fromProvince.id)
      )
    );
    setToProvince(
      provinces.find(
        province =>
          province.id === (list[index].uf_to || list[index].toProvince.id)
      )
    );
    setRate(list[index].rate);
    setTaxBaseReduction(list[index].taxBaseReduction);
    setLegalText(list[index].legalText);
    setExempt(list[index].isExempt);
    setTaxSituation(list[index].taxSituation);
    setClient(list[index].client);
    setProduct(list[index].product);

    setList(list.filter((_, idx) => idx !== index));
  }

  function handleRemoveIcms(index) {
    setList(list.filter((_, idx) => idx !== index));
  }

  async function handleSaveIcms() {
    const formatted = list.map(item => ({
      client_id: item.client?.id,
      product_id: item.product?.id,
      uf_from: item.fromProvince.id,
      uf_to: item.toProvince.id,
      value: Number(item.rate.replace(',', '.')),
      id: item.id,
      tax_base_reduction: item.taxBaseReduction
        ? Number(item.taxBaseReduction.replace(',', '.'))
        : 0,
      legal_text: item.legalText,
      is_exempt: item.isExempt,
      tax_situation: item.taxSituation?.id,
    }));
    const response = await saveIcms({ id: issuer.id, icms: formatted });

    if (!response.error) {
      snackbar.show(<Text>Dados atualizados</Text>, {
        type: 'success',
      });
      setList([]);
      setUnchangedIcmsList([]);
      handleGetIssuer();
      setIsEdit(false);
    } else {
      snackbar.show(<Text>{response.error}</Text>, {
        type: 'error',
      });
    }
  }

  function handleEditIcmsList() {
    setUnchangedIcmsList(list);
    setIsEdit(!isEdit);
  }

  function handleCancelEditIcmsList() {
    setList(unchangedIcmsList);
    setIsEdit(!isEdit);
  }

  function numberToString(value) {
    return (
      value?.toFixed(2).toString().replace('.', ',').padStart(5, '0') ?? '00,00'
    );
  }

  function settingProduct(event) {
    if (event?.ncm) {
      setProduct(event);
    } else {
      setAddingNcm(event);
    }
  }

  useEffect(() => {
    if (issuer && issuer?.icms?.length > 0) {
      const formatted = issuer.icms.map(item => {
        const formattedRate = numberToString(item.value);
        const formattedTaxBaseSituation = numberToString(
          item.tax_base_reduction
        );

        return {
          client: item.client,
          product: item.product,
          fromProvince: item.provinceFrom,
          toProvince: item.provinceTo,
          rate: formattedRate,
          taxBaseReduction: formattedTaxBaseSituation,
          legalText: item.legal_text,
          isExempt: item.is_exempt,
          taxSituation: taxSituations.find(x => x.id === item.tax_situation),
        };
      });
      setList(formatted);
    }
  }, [issuer, taxSituations]);

  return (
    <Card
      header={
        <Text color="#464E5F" type="header">
          Exceções de ICMS
        </Text>
      }
      HeaderRightComponent={
        <div>
          {isEdit && (
            <Button
              variant="secondary"
              onClick={() => handleCancelEditIcmsList()}
              className="mr-2"
            >
              <Text weight={500} type="regular">
                Cancelar
              </Text>
            </Button>
          )}
          <Button
            variant={isEdit ? 'success' : 'secondary'}
            onClick={() => (isEdit ? handleSaveIcms() : handleEditIcmsList())}
            disabled={!canEditIssuers}
          >
            <Text weight={500} type="regular">
              {isEdit ? 'Salvar' : 'Editar'}
            </Text>
          </Button>
        </div>
      }
    >
      {isEdit && (
        <>
          <Row>
            <Col xs={12}>
              <Select.Async
                label="Tomador"
                onSearch={search => fetchClients(search)}
                value={client}
                getOptionLabel={option => {
                  let label = `${option.social_name} - ${option.cgccpf}`;

                  if (option.city) {
                    label += ` - ${option.city}`;
                  }

                  return label;
                }}
                getOptionValue={option => option.id}
                onChange={event => setClient(event)}
              />
            </Col>
            <Col xs={8}>
              <Select.Async
                label="Produto"
                onSearch={search => fetchProducts(search)}
                value={product}
                getOptionLabel={option => {
                  const label = `${
                    option.ncm ? option.ncm : `NCM não informado`
                  } - ${option.name}`;
                  return label;
                }}
                getOptionValue={option => option.id}
                onChange={event => settingProduct(event)}
              />
            </Col>
            <Col xs={4}>
              <Button
                style={{ marginTop: '35px' }}
                onClick={() => setAddProductModal(true)}
                size="sm"
                variant="primary"
              >
                Cadastrar produto
              </Button>
            </Col>
            <Col xs={6}>
              <Select
                label="Origem *"
                options={provinces}
                value={fromProvince}
                onChange={value => setFromProvince(value)}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                error={errors && errors.fromProvince}
                isDisabled={!isEdit}
              />
            </Col>
            <Col xs={6}>
              <Select
                label="Destino *"
                options={provinces}
                value={toProvince}
                onChange={value => setToProvince(value)}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                error={errors && errors.toProvince}
                isDisabled={!isEdit}
              />
            </Col>
            <Col xs={6}>
              <InputCurrency
                rightIcon={<FaPercentage color="#464E5F" />}
                label="Alíquota *"
                value={rate}
                onChange={(_event, value) => setRate(value)}
                guide={false}
                error={errors && errors.rate}
                disabled={!isEdit}
              />
            </Col>
            <Col xs={6}>
              <InputCurrency
                rightIcon={<FaPercentage color="#464E5F" />}
                label="Redução da BC"
                value={taxBaseReduction}
                onChange={(_event, value) => setTaxBaseReduction(value)}
                guide={false}
                disabled={!isEdit}
              />
            </Col>
            <Col xs={12}>
              <TextArea
                resizable={false}
                label="Texto legal"
                value={legalText}
                onChange={event => setLegalText(event.target.value)}
              />
            </Col>
            <Col xs={12}>
              <Select
                label="Situação tributária"
                options={taxSituations}
                value={taxSituation}
                onChange={value => setTaxSituation(value)}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                isDisabled={!isEdit}
              />
            </Col>
            <Col xs={6}>
              <Radio.Group
                label="Isento"
                onChange={({ target }) => setExempt(target.value)}
                value={isExempt}
                horizontal
              >
                <Radio value>
                  <Text color="dark" type="label">
                    Sim
                  </Text>
                </Radio>
                <Radio value={false}>
                  <Text color="dark" type="label">
                    Não
                  </Text>
                </Radio>
              </Radio.Group>
            </Col>
            <Col xs={6} className="d-flex justify-content-end">
              <Button
                className="mt-1"
                variant="primary"
                onClick={() => handleAddIcms()}
                disabled={!isEdit}
              >
                <Text weight={600} type="regular">
                  Adicionar
                </Text>
              </Button>
            </Col>
          </Row>
        </>
      )}
      <Row>
        <Col xs={12}>
          {list.length > 0 && (
            <Table responsive>
              <thead>
                <tr>
                  <th>
                    <Text type="label" weight="500">
                      Cliente
                    </Text>
                  </th>
                  <th>
                    <Text type="label" weight="500">
                      Produto
                    </Text>
                  </th>
                  <th>
                    <Text type="label" weight="500">
                      Origem
                    </Text>
                  </th>
                  <th>
                    <Text type="label" weight="500">
                      Destino
                    </Text>
                  </th>
                  <th>
                    <Text type="label" weight="500">
                      Alíquota
                    </Text>
                  </th>
                  <th>
                    <Text type="label" weight="500">
                      Redução da BC
                    </Text>
                  </th>
                  <th>
                    <Text type="label" weight="500">
                      Isento
                    </Text>
                  </th>
                  <th>
                    <Text type="label" weight="500">
                      Ação
                    </Text>
                  </th>
                </tr>
              </thead>
              <tbody>
                {list.map((item, index) => (
                  <tr key={item.id}>
                    <td>
                      <Text type="regular" weight="400">
                        {item.client
                          ? `${item.client.social_name}`
                          : `Nenhum cliente cadastrado`}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" weight="400">
                        {item.product
                          ? `${
                              item.product.ncm
                                ? item.product.ncm
                                : `NCM não informado`
                            } - ${item.product.name}`
                          : `Nenhum produto cadastrado`}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" weight="400">
                        {item.fromProvince.name}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" weight="400">
                        {item.toProvince.name}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" weight="400">
                        {item.rate}%
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" weight="400">
                        {item.taxBaseReduction ? `${item.taxBaseReduction}` : 0}
                        %
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" weight="400">
                        {item.isExempt ? 'Sim' : 'Não'}
                      </Text>
                    </td>
                    <td width="120" className="text-center">
                      <Button
                        variant="primary"
                        size="xs"
                        className="mr-1"
                        onClick={() => handleEditIcms(index)}
                        disabled={!isEdit}
                      >
                        <FaPencilAlt />
                      </Button>
                      <Button
                        variant="error"
                        size="xs"
                        className="mr-1"
                        onClick={() => handleRemoveIcms(index)}
                        disabled={!isEdit}
                      >
                        <FaTrash />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Col>
        {list.length === 0 && (
          <Text type="regular" weight="400" className="mt-4">
            Não há Exceções de ICMS adicionadas.
          </Text>
        )}
        <Col />
      </Row>
    </Card>
  );
}
