/* eslint-disable import/no-unresolved */
import styled from 'styled-components';
import { FaShieldAlt, FaBan } from 'react-icons/fa';

import theme from 'v3/theme';

export const ShieldIcon = styled(FaShieldAlt)`
  color: ${theme.colors.success};
  margin-right: 5px;
`;

export const BanIcon = styled(FaBan)`
  color: ${theme.colors.error};
  margin-right: 5px;
`;
