import styled, { css } from 'styled-components';
import { Tab, Nav } from 'react-bootstrap';
import theme from 'v3/theme';
import Text from 'v3/components/Text';

export const ProgressCard = styled.div`
  min-height: 725px;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  display: inline-block;
  position: absolute;
  left: 25px;
  top: 26px;
  opacity: 0.8;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }
`;

export const ActiveTabContent = styled(Tab.Content)`
  padding: 20px 20px;
`;

export const Marker = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${theme.colors.medium_gray};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: -9px;
  margin-top: 3px;

  ${({ completed }) =>
    completed &&
    css`
      background-color: ${theme.colors.success};
    `}
  ${({ isLast }) =>
    isLast &&
    css`
      left: -7px;
    `};
`;

Marker.Tray = styled.div`
  margin-top: 3px !important;

  border-left: 2px dashed ${theme.colors.medium_gray};
  height: 60px;
  width: 0;
  margin: 0 auto;

  ${({ completed }) =>
    completed &&
    css`
      border-color: ${theme.colors.success};
    `}
`;

export const TabPanel = styled(Tab.Pane)``;

export const TabLink = styled(Nav.Link)`
  padding: 15px 30px;
  color: #464e5f;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  background-color: #ffffff;
  border-bottom: 1px solid #f2f2f2;
  transition: all 0.1s ease-in;

  &:hover {
    background-color: #f2f2f2;
    color: #464e5f;
    border-bottom-color: #f2f2f2;
  }

  &.active {
    background-color: #266ca8;
    color: #ffffff;
    border-bottom-color: #266ca8;
  }
`;

export const CteDisplay = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding-top: 20px;
`;

export const CteText = styled(Text)`
  display: block;
`;
