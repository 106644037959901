/* eslint-disable import/no-unresolved */
import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { cooperplaceApi } from 'services/api';
import useWindowResize from 'hooks/useWindowResize';
import useModal from 'hooks/useModal';
import { useSnackbar } from 'v3/components/Snackbar';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import ApplicationLayout from 'v3/layouts/Application';
import { currencyOptions, makeConversion } from 'v3/utils/formatter';
import { BlockedTravelContext } from 'contexts/BlockedTravelProvider';
import { validatePolicyValidity } from 'v3/validators/loadValidators';
import CreatedTravelModal from './Modals/CreatedTravel';
import HasTravelsModal from './Modals/HasTravel';
import CheckListModal from './Modals/Checklist';
import { MinimumMarginModal } from '../shared';
import Load from './Load';
import Shipper from './Shipper';
import Driver from './Driver';
import Locale from './Locale';
import Vehicle from './Vehicle';
import Attachments from './Attachments';
import { TravelContext, handleCreation } from './controller';
import * as Styled from './styles';
import { LoadFreightValues } from './LoadFreightValues';
import { GROCard } from './GRO';
import ConflictDriverModal from '../shared/Modal/ConflictDriver';

const [AddTravelProvider] = TravelContext;

export default function TravelRegister() {
  const {
    isFetchingBenner,
    setItensToFetchMargin,
    margins,
    isFetchingMargin,
    setItemsToSetDefaultValues,
  } = useContext(BlockedTravelContext);
  const checkListModal = useModal();
  const hasTravelsModal = useModal();
  const createdTravelModal = useModal();
  const minimumFreightValueModal = useModal();
  const conflictDriverModal = useModal();

  const history = useHistory();
  const snackbar = useSnackbar();
  const params = useParams();

  const [data, setData] = useState({
    origin: null,
    destinations: [],
  });
  const [isGROLoad, setIsGROLoad] = useState(null);
  const [fetchingLoadGRORange, setFetchingLoadGRORange] = useState(false);
  const [loadGRORangeData, setLoadGRORangeData] = useState(null);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [modalName, setModalName] = useState('');

  const [saveContainerOffset, setSaveContainerOffset] = useState(0);

  const [showTollCard, setShowTollCard] = useState(false);

  useWindowResize(() => {
    const header = document.getElementById('header-container');
    const originDistanceToRight =
      window.pageXOffset + header.getBoundingClientRect().right;
    const headerWidth = header.getBoundingClientRect().width;

    const SAVE_CONTAINER_PADDING = 0;
    const distanceToRight =
      originDistanceToRight - headerWidth + SAVE_CONTAINER_PADDING;
    setSaveContainerOffset(distanceToRight);
  }, []);

  function showSnackbar(text, options) {
    snackbar.show(<Text> {text} </Text>, options);
  }

  async function fetchLoadGRORange(policyId, rangeId) {
    try {
      setFetchingLoadGRORange(true);
      const response = await cooperplaceApi.get(
        `policies/${policyId}/ranges/${rangeId}`
      );
      setLoadGRORangeData(response.data);
      setFetchingLoadGRORange(false);
      return response.data;
    } catch (error) {
      showSnackbar(
        'Erro ao recuperar detalhes da apólice associada à carga. Tente novamente mais tarde',
        { type: 'error' }
      );
      setFetchingLoadGRORange(false);
      return history.push('/viagens');
    }
  }

  useEffect(() => {
    async function fetchLoad() {
      if (params.loadId) {
        try {
          const cooperLoad = await cooperplaceApi.get(`loads/${params.loadId}`);

          const loadData = cooperLoad.data;

          if (
            loadData.loadPolicy?.id &&
            loadData?.loadPolicy?.policy?.dueDate
          ) {
            const isValid = validatePolicyValidity(
              loadData?.loadPolicy?.policy?.dueDate
            );
            if (!isValid) {
              snackbar.show(
                <Text>
                  Não é possível atender carga atrelada à apólice vencida! Entre
                  em contato com o setor de GRO
                </Text>,
                {
                  type: 'error',
                }
              );
              setIsLoading(false);

              return history.push('/viagens');
            }
          }

          if (loadData && loadData.deletedAt) {
            showSnackbar('Não é possível atender uma carga cancelada!', {
              type: 'error',
            });
          } else {
            const issueRateItem = loadData?.shipmentDiscounts?.find(
              item => item.label.toLowerCase() === 'taxa de emissão'
            );

            const dollarValue = loadData.freightExchange;
            const isBRLCurrency = loadData.currency === 'BRL';
            const valuesCurrency = loadData.currency;
            const [origin] = loadData.loadOrigins;
            setData(old => ({
              ...old,
              origin: {
                ...origin,
                country_slug: origin?.countrySlug,
                zip_code: origin?.zipCode,
                formatted_address: origin?.formattedAddress,
                start_schedule: origin?.startSchedule,
                scheduled_time: origin?.scheduledTime,
                cityModel: origin.cityModel || {
                  name: origin.city,
                },
              },
              destinations: loadData.loadDestinations.map(destination => ({
                ...destination,
                country_slug: destination?.countrySlug,
                zip_code: destination?.zipCode,
                formatted_address: destination?.formattedAddress,
                start_schedule: destination?.startSchedule,
                scheduled_time: destination?.scheduledTime,
                cityModel: destination.cityModel || {
                  name: destination.city,
                },
              })),
              exchange: loadData.exchange,
              currency: currencyOptions.find(
                currency => currency.value === loadData?.loadCurrency
              ),
              loadId: loadData.id,
              loadProductId: loadData?.productId,
              client: loadData.taker,
              tags: loadData.tags,
              riskManager: loadData.riskManagers
                ? loadData.riskManagers[0]
                : null,
              tracker: loadData.riskManager,
              userCreated: loadData.userCreated,
              costCenter: loadData.costCenter,
              cargo_value: loadData.cargoValue,
              advance_money_percentage_external:
                loadData.advanceMoneyPercentageExternal || 0,
              advance_money_percentage: loadData?.advanceMoneyPercentage || 0,
              toll_value: loadData.tollValue,
              tollObservation: loadData?.tollObservation,
              discharge_value: loadData.dischargeValue,
              dischargeObservation: loadData?.dischargeObservation,
              ferry: loadData.ferry,
              ferryObservation: loadData?.ferryObservation,
              fare_company: loadData?.fareCompany,
              editableFareCompany: loadData?.fareCompany,
              charge_value: loadData?.chargeValue,
              chargeObservation: loadData?.chargeObservation,
              gris: loadData?.gris,
              total_inputs: loadData?.totalInputs,
              editableTotalInputs: loadData?.totalInputs,
              total_taker_value: loadData?.totalTakerValue,
              ad_valorem: loadData?.adValorem,
              grissAdObservation: loadData?.grissAdObservation,
              pcp_observation: loadData.pcpObservation,
              isBRLCurrency,
              load_value_type: loadData?.loadValueType,
              negotiation_type: loadData?.negotiationType,
              freight_values_currency: valuesCurrency,
              freight_values_exchange: loadData.freightExchange,
              exchange_day: loadData?.exchangeDay,
              total_shipment_transfer: loadData?.totalShipmentTransfer,
              total_shipment_discounts: loadData?.totalShipmentDiscounts,
              issueRateId: issueRateItem?.id,
              issueRate: issueRateItem?.value,
              shipmentDiscounts: loadData?.shipmentDiscounts
                ?.filter(item => item.label.toLowerCase() !== 'taxa de emissão')
                .map(item => ({
                  ...item,
                  value:
                    valuesCurrency === 'BRL'
                      ? item.value
                      : +makeConversion(item.value, '*', dollarValue).toFixed(
                          2
                        ),
                })),
              shipmentTransfer: loadData.shipmentTransfers.map(item => ({
                ...item,
                value:
                  valuesCurrency === 'BRL'
                    ? item.value
                    : +makeConversion(item.value, '*', dollarValue).toFixed(2),
              })),
              company: loadData?.company,
              loadGROData: loadData?.loadPolicy?.id
                ? {
                    policyId: loadData?.loadPolicy?.policyId,
                    techTracker: null,
                    modelTracker: null,
                    trackerNumber: null,
                    immobilizer: null,
                    immobilizerNumber: null,
                    locator: null,
                    locatorNumber: null,
                    bait1: null,
                    baitNumber1: null,
                    bait2: null,
                    baitNumber2: null,
                    bait3: null,
                    baitNumber3: null,
                    bait4: null,
                    baitNumber4: null,
                    groupEquipment: null,
                    riskManagerSearch: null,
                  }
                : null,
            }));
            setIsGROLoad(
              loadData?.loadPolicy?.id ? loadData?.loadPolicy : null
            );
          }
          if (loadData?.loadPolicy?.id) {
            const rgDetails = await fetchLoadGRORange(
              loadData?.loadPolicy?.policyId,
              loadData?.loadPolicy?.policyRangeId
            );
            setData(old => ({
              ...old,
              loadGROData: {
                ...old?.loadGROData,
                checklistType: rgDetails?.checklist_tracker,
              },
            }));
          }

          setIsLoading(false);
        } catch (ex) {
          // Handle exception
          setIsLoading(false);
        }
      }
    }
    fetchLoad();
  }, [params.loadId]);

  const value = {
    data,
    setData,
    showTollCard,
    setShowTollCard,
    setIsLoading,
    errors,
    modalName,
    setModalName,
    isGROLoad,
    setIsGROLoad,
    loadGRORangeData,
    fetchingLoadGRORange,
  };

  const saveTravel = ({
    confirmMinimiumMargin = data?.confirmMinimiumMargin,
    passDriverValidation = data?.passDriverValidation,
  }) => {
    handleCreation({
      data: { ...data, isGROLoad },
      setData,
      setErrors,
      showSnackbar,
      setIsLoading,
      setModalName,
      margins,
      confirmMinimiumMargin,
      loadGRORangeData,
      passDriverValidation,
    });
  };

  useEffect(() => {
    if (modalName === 'CheckList') {
      checkListModal.open();
      return;
    }
    if (modalName === 'DriverHasTravels') {
      hasTravelsModal.open();
      return;
    }
    if (modalName === 'CreatedTravel') {
      createdTravelModal.open();
    }
    if (modalName === 'MinimumMargin') {
      minimumFreightValueModal.open();
    }
    if (modalName === 'ConflictDriver') {
      conflictDriverModal.open();
    }
  }, [modalName]);

  useEffect(() => {
    setItensToFetchMargin(prevState => ({
      ...prevState,
      loadId: data?.loadId,
      origin: data?.origin,
      destinations: data?.destinations,
      shippingCompany: data?.shippingCompany?.shippingCompanies,
      mainVehicle: data?.vehicle?.main,
    }));
  }, [
    data?.loadId,
    data?.origin,
    data?.destinations,
    data?.shippingCompany,
    data?.vehicle?.main,
  ]);

  useEffect(() => {
    if (data?.costCenter?.businessId)
      setItemsToSetDefaultValues(old => ({
        ...old,
        costCenterBusinessId: data?.costCenter?.businessId,
      }));
  }, [data?.costCenter]);

  return (
    <ApplicationLayout
      title="Cadastro de viagem"
      RightComponent={
        <Styled.SaveContainer style={{ right: saveContainerOffset }}>
          <Button
            id="button-cancelar-viagem"
            variant="secondary"
            onClick={() => history.push('/viagens')}
          >
            <Text weight={500} color="dark" type="regular">
              Cancelar
            </Text>
          </Button>
          <Button
            id="button-salvar-viagem"
            variant="success"
            onClick={saveTravel}
            loading={isLoading || isFetchingBenner || isFetchingMargin}
            disabled={data.invalidLoad}
          >
            <Text weight={500} color="white" type="regular">
              Salvar viagem
            </Text>
          </Button>
        </Styled.SaveContainer>
      }
    >
      <AddTravelProvider value={value}>
        <CheckListModal
          isOpen={checkListModal.isOpen}
          onClose={checkListModal.close}
        />
        <HasTravelsModal
          isOpen={hasTravelsModal.isOpen}
          onClose={hasTravelsModal.close}
        />
        <CreatedTravelModal
          isOpen={createdTravelModal.isOpen}
          onClose={createdTravelModal.close}
          notClosed={false}
        />
        <MinimumMarginModal
          isOpen={minimumFreightValueModal.isOpen}
          onClose={() => {
            setData(old => ({ ...old, confirmMinimiumMargin: false }));
            minimumFreightValueModal.close();
            setModalName('');
            setErrors({});
          }}
          confirm={({ confirmMinimiumMargin }) => {
            setData(old => ({ ...old, confirmMinimiumMargin }));
            minimumFreightValueModal.close();
            saveTravel({ confirmMinimiumMargin });
          }}
          isNewTravel
        />
        {errors?.travelsWithDriver?.length > 0 && (
          <ConflictDriverModal
            isOpen={conflictDriverModal.isOpen}
            onClose={() => {
              setData(old => ({ ...old, passDriverValidation: false }));
              conflictDriverModal.close();
              setModalName('');
              setErrors({});
            }}
            onConfirm={({ passDriverValidation }) => {
              setData(old => ({ ...old, passDriverValidation }));
              conflictDriverModal.close();
              saveTravel({ passDriverValidation });
            }}
            travels={errors.travelsWithDriver}
            travelData={data}
          />
        )}

        <Row>
          <Col md={6} xs={12}>
            <Load />
            <Locale />
          </Col>
          <Col md={6} xs={12}>
            <Shipper />
            <Driver />
            <Vehicle />
            <GROCard />
            <Attachments />
          </Col>
          <Col md={12} xs={12}>
            <LoadFreightValues />
          </Col>
        </Row>
      </AddTravelProvider>
    </ApplicationLayout>
  );
}
