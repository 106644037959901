/* eslint-disable import/no-unresolved */
import React, { useEffect, useState, useMemo } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import moment from 'moment';

import Card from 'v3/components/Card';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import DatePicker from 'v3/components/DatePicker';
import Loader from 'v3/components/Loader';

import { Driver } from 'services/api';
import { formatDate, formatTime } from 'utils/formatter';

export default function WorkJourney({ cgccpf: document }) {
  const [events, setEvents] = useState([]);
  const [search, setSearch] = useState(formatDate(new Date()));
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchWorkJourneyEvents() {
      setLoading(true);

      try {
        const today = moment(search, 'DD/MM/YYYY');

        const data = await Driver.WorkJourney.fetchEventsByDriver({
          document,
          start: today.startOf('day').toDate(),
          end: today.endOf('day').toDate(),
          perPage: 0,
        });

        setEvents(data);
      } catch (ex) {
        // Handle exception
      } finally {
        setLoading(false);
      }
    }

    fetchWorkJourneyEvents();
  }, [search]);

  const nextDate = useMemo(
    () => formatDate(moment(search, 'DD/MM/YYYY').add(1, 'day').toDate()),
    [search]
  );

  const previousDate = useMemo(
    () => formatDate(moment(search, 'DD/MM/YYYY').subtract(1, 'day').toDate()),
    [search]
  );

  const isToday = useMemo(
    () => moment(search, 'DD/MM/YYYY').isSame(moment(), 'day'),
    [search]
  );

  return (
    <div>
      <div className="d-flex w-100 justify-content-between align-items-center">
        <Button onClick={() => setSearch(previousDate)}>{'<'}</Button>
        <DatePicker
          guide
          value={search}
          containerStyle={{ width: 'auto', marginBottom: 0 }}
          onChange={({ target }) => setSearch(target.value)}
        />
        <Button disabled={isToday} onClick={() => setSearch(nextDate)}>
          {'>'}
        </Button>
      </div>
      {loading ? (
        <Loader size="md" loading={loading} />
      ) : events.length ? (
        events.map(({ sent_at, event }) => (
          <>
            <Text>
              {formatTime(sent_at)} - {event.name}
            </Text>
            <hr />
          </>
        ))
      ) : (
        <Text type="regular" className="my-3">
          Nenhum evento enviado.
        </Text>
      )}
    </div>
  );
}
