import React, { useCallback, useEffect, useState, useMemo } from 'react';

import theme from 'v3/theme';

import { Link } from 'react-router-dom';
import { FaList, FaTv } from 'react-icons/fa';
import { Col, Row } from 'react-bootstrap';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import moment from 'moment';
import MapRaw from 'v3/components/Map';
import MarkerLetter from 'v3/components/Map/MarkerLetter';

import { useModal } from 'hooks';
import api from 'services/api';

import {
  Card,
  Text,
  Toggle,
  Badge,
  DropdownButton,
  Tooltip as TooltipComponent,
  Select,
} from 'v3/components';
import DashboardContext from '../../context';
import FilterModal from '../../Modals/Filter';
import { StyledNumeric } from './styles';

const [, useDashboard] = DashboardContext;

const OPTIONS = {
  NUMERIC: {
    id: 'NUMERIC',
    icon: <FaTv />,
  },
  LIST: {
    id: 'LIST',
    icon: <FaList />,
  },
};

const LIST_OPTIONS = Object.values(OPTIONS);

export default function DashboardCard({
  name,
  dates,
  title,
  startDate = false,
  isLoading,
  dashboard,
  hideFilter,
  dateFilter,
  ListComponent,
  refetchInterval = 300000,
  onFiltersChanged,
  NumericComponent,
  showClients = true,
  handleChangeDate,
  dateFilterOptionLabel,
  dateFilterOptionValue,
  id,
  clients,
  setDate,
  mercosulDashboard = false,
  excludeFilterByDate = false,
  excludeFilterByNegotiator = false,
  filterByCompanyMultiple = true,
  excludeFilterByCompany = false,
  excludeFilterByVehicleTypes = true,
  excludeFilterByVehicleBodies = true,
  onlyCompanyFilter = false,
  excludeFilterByClients = false,
  excludeFilterByTags = false,
  excludeFilterByBranchOffice = false,
  CustomFilterModal,
}) {
  const modal = useModal();
  const dashboardContext = useDashboard();
  const [view, setView] = useState(OPTIONS.NUMERIC.id);
  const [isFetched, setFetched] = useState(false);
  const [values, setValues] = useState([]);
  const [chart, setChart] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dates?.[0]);

  function onChangeView(value) {
    setView(value);
  }

  const dispatchFiltersChanged = useCallback(() => {
    if (onFiltersChanged) {
      onFiltersChanged({
        tags: values.tags,
        clients: values.clients || clients,
        negotiators: values?.negotiators,
        costCenters: values?.costCenters,
        branches: values?.branches,
        pcps: values?.pcps,
        companies: values?.companies,
        vehicleTypes: values?.vehicleTypes,
        vehicleBodies: values?.vehicleBodies,
        date: values.date,
        start_date: values.start_date,
        end_date: values?.filterByPeriod ? values?.end_date : undefined,
      });
    }
  }, [onFiltersChanged, values]);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatchFiltersChanged();
    }, refetchInterval);

    return () => {
      clearInterval(interval);
    };
  }, [dispatchFiltersChanged]);

  useEffect(() => {
    if (values.date && !startDate) setSelectedDate(values.date);
  }, [values.date]);

  async function handleParameters() {
    try {
      let currentTags;
      let currentSizes;
      let currentDate;
      let currentEndDate;
      let currentClients;
      let currentNegotiators;
      let currentCostCenters;
      let currentBranches;
      let currentPcps;
      let currentCompanies;
      let currentVehicleTypes;
      let currentVehicleBodies;
      let card_id;
      let date;
      let filterByPeriod;

      if (dashboard?.parameters) {
        const parsed = JSON.parse(dashboard?.parameters);

        date = parsed.items.find(item => item?.card_id === id).date;
        card_id = parsed.items.find(item => item?.card_id === id).card_id;
        currentTags = parsed.items.find(item => item?.card_id === id).tags;
        currentClients = parsed.items.find(
          item => item?.card_id === id
        ).clients;
        currentNegotiators = parsed.items.find(
          item => item?.card_id === id
        ).negotiators;
        currentCostCenters = parsed.items.find(
          item => item?.card_id === id
        ).costCenters;
        currentBranches = parsed.items.find(
          item => item?.card_id === id
        ).branches;
        currentPcps = parsed.items.find(item => item?.card_id === id).pcps;
        currentCompanies = parsed.items.find(
          item => item?.card_id === id
        ).companies;
        currentVehicleTypes = parsed.items.find(item => item?.card_id === id).vehicleTypes;
        currentVehicleBodies = parsed.items.find(item => item?.card_id === id).vehicleBodies;
        currentDate = parsed.items.find(
          item => item?.card_id === id
        ).start_date;
        currentSizes = parsed.items.find(item => item?.card_id === id).sizes;
        currentEndDate = parsed.items.find(
          item => item?.card_id === id
        )?.end_date;
        filterByPeriod = parsed.items.find(
          item => item?.card_id === id
        )?.filterByPeriod;
      }

      setValues({
        tags: currentTags || [],
        clients: currentClients || [],
        negotiators: currentNegotiators || [],
        costCenters: currentCostCenters || [],
        branches: currentBranches || [],
        pcps: currentPcps || [],
        companies: currentCompanies || [],
        vehicleTypes: currentVehicleTypes || [],
        vehicleBodies: currentVehicleBodies || [],
        sizes: currentSizes || 6,
        date: date || selectedDate,
        start_date:
          currentDate ||
          moment().subtract(3, 'days').startOf('day').toISOString(),
        card_id,
        end_date: currentEndDate || null,
        filterByPeriod: !!filterByPeriod,
      });
      setDate(date || selectedDate);
    } catch (ex) {
      // Handle exception
    }
  }

  useEffect(() => {
    if (name === 'CargonAdherence') {
      setChart(true);
    }
  }, []);

  async function onSizeChange(value, dashboard_id, card_id) {
    setValues(old => ({ ...old, sizes: value }));
    const data = {
      start_date: values.start_date,
      sizes: value,
      component: name,
      date: values.date,
      card_id,
    };

    await api.put(`v3/dashboard/${dashboard_id}/parameters`, data);

    setValues(old => ({ ...old, sizes: value }));
  }

  const options = useMemo(() => {
    if (!hideFilter)
      return [
        {
          title: '1/1',
          onClick: () => onSizeChange(12, dashboard.id, id),
          type: 'item',
        },

        {
          title: '1/2',
          onClick: () => onSizeChange(6, dashboard.id, id),
          type: 'item',
        },
        {
          title: '1/3',
          onClick: () => onSizeChange(4, dashboard.id, id),
          type: 'item',
        },
        {
          type: 'divider',
        },
        {
          title: 'Filtro',
          onClick: () => modal.open(),
          type: 'item',
        },
      ];

    return [
      {
        title: '1/1',
        onClick: () => onSizeChange(12, dashboard.id, id),
        type: 'item',
      },

      {
        title: '1/2',
        onClick: () => onSizeChange(6, dashboard.id, id),
        type: 'item',
      },
      {
        title: '1/3',
        onClick: () => onSizeChange(4, dashboard.id, id),
        type: 'item',
      },
    ];
  }, [dashboard, id]);

  useEffect(() => {
    setFetched(true);
    handleParameters();
  }, [id]);

  useEffect(() => {
    if (isFetched) {
      dispatchFiltersChanged();
    }
  }, [isFetched, values.card_id, dispatchFiltersChanged]);

  function CardBody() {
    return (
      <>
        {CustomFilterModal?.Component ? (
          <CustomFilterModal.Component
            onFilter={CustomFilterModal.onFilter}
            initialFilter={CustomFilterModal.filter}
            modalHook={modal}
          />
        ) : (
          <FilterModal
            isOpen={modal.isOpen}
            onClose={modal.close}
            name={name}
            dashboard={dashboard}
            values={values}
            setValues={setValues}
            dateFilter={dateFilter}
            selectedDate={selectedDate}
            mercosulDashboard={mercosulDashboard}
            excludeFilterByDate={excludeFilterByDate}
            excludeFilterByNegotiator={excludeFilterByNegotiator}
            excludeFilterByCompany={excludeFilterByCompany}
            excludeFilterByVehicleTypes={excludeFilterByVehicleTypes}
            excludeFilterByVehicleBodies={excludeFilterByVehicleBodies}
            filterByCompanyMultiple={filterByCompanyMultiple}
            onlyCompanyFilter={onlyCompanyFilter}
            excludeFilterByClients={excludeFilterByClients}
            excludeFilterByTags={excludeFilterByTags}
            excludeFilterByBranchOffice={excludeFilterByBranchOffice}
            id={id}
          />
        )}
        <Card
          loading={isLoading}
          header={
            <div className="d-flex flex-column">
              <Text color="gray" type="header" weight="bold">
                {title}
              </Text>
              <div className="mt-1 mb-1">
                {showClients &&
                  values?.clients?.map(filter => (
                    <Badge size="sm" pill variant="secondary" className="mr-1">
                      <Text type="small" weight="normal">
                        {filter?.social_name}
                      </Text>
                    </Badge>
                  ))}
                {values?.tags?.map(filter => (
                  <Badge size="sm" pill variant="secondary" className="mr-1">
                    <Text type="small" weight="normal">
                      {filter?.name}
                    </Text>
                  </Badge>
                ))}
              </div>
            </div>
          }
          HeaderRightComponent={
            <div className="d-flex align-items-center justify-content">
              {!chart && (
                <>
                  {dateFilter ? (
                    <div className="selectDate">
                      <Select
                        label=""
                        value={selectedDate}
                        onChange={event =>
                          handleChangeDate(
                            event,
                            setSelectedDate,
                            setValues,
                            values
                          )
                        }
                        getOptionLabel={dateFilterOptionLabel}
                        getOptionValue={dateFilterOptionValue}
                        labelColor="#fff"
                        disableClear
                        options={dates}
                      />
                    </div>
                  ) : (
                    <Toggle
                      options={LIST_OPTIONS}
                      getOptionText={option => option.icon}
                      getOptionValue={option => option.id}
                      value={view}
                      onSelected={onChangeView}
                    />
                  )}

                  <DropdownButton
                    disabled={dashboardContext?.draggingEnable}
                    style={{ marginLeft: 10 }}
                    title=""
                    size="md"
                    variant="secondary"
                    options={options}
                  />
                </>
              )}
            </div>
          }
        >
          {view === OPTIONS.NUMERIC.id ? NumericComponent : ListComponent}
        </Card>
      </>
    );
  }

  return dashboardContext?.draggingEnable ? (
    <div>
      <CardBody />
    </div>
  ) : (
    <Col md={values.sizes} xs={12}>
      <CardBody />
    </Col>
  );
}

export function Number({ children, color = 'success', size }) {
  let font;
  if (size === 3) {
    font = '33px';
  } else {
    font = '80px';
  }

  return (
    <Text fontSize={font} color={color} weight="bold">
      {children}
    </Text>
  );
}

export function RightWingNumber({ children, color = 'success' }) {
  return (
    <Text fontSize="50px" color={color} weight="bold">
      {children}
    </Text>
  );
}

export function Description({ children, openModal, modal, clickable }) {
  return (
    <Text
      color={openModal ? 'dark_blue' : 'gray'}
      onClick={() => clickable && modal.open()}
      clickable={clickable}
    >
      {children}
    </Text>
  );
}

export function SeeAll({ to, children }) {
  return (
    <div className="text-center mt-3">
      <Link to={to} style={{ textDecoration: 'none' }} target="_blank">
        <Text color="primary">{children}</Text>
      </Link>
    </div>
  );
}

const COLORS = [
  '#0088FE',
  '#5552FA',
  '#FF80B5',
  '#36F5EA',
  '#73C164',
  '#FF0000',
  '#FFF61C',
  '#F57E10',
  '#52FA63',
  '#943619',
];

export function CityPieChart({ data, valueKey, cityKey }) {
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    ...rest
  }) => {
    const radius = (innerRadius + outerRadius) * 0.93;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill={theme.colors.medium_gray}
        textAnchor={x > cx ? 'start' : 'end'}
        fontSize="12"
      >
        {rest[cityKey]}
      </text>
    );
  };

  return (
    <Row>
      <Col>
        {data?.map(city => (
          <Row>
            <Text color="gray" type="regular">
              {city.percent.toFixed()}% - {city[cityKey]}
              <Text color="medium_gray"> ({city[valueKey]})</Text>
            </Text>
          </Row>
        ))}
      </Col>
      <Col>
        <PieChart width={420} height={250}>
          <Pie
            data={data}
            cx={230}
            cy={120}
            innerRadius={30}
            outerRadius={80}
            paddingAngle={0.5}
            dataKey={valueKey}
            nameKey={cityKey}
            label={renderCustomizedLabel}
          >
            {data?.map((entry, index) => (
              <Cell fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </Col>
    </Row>
  );
}

export function CityMap({ data, latKey, lonKey, height }) {
  return (
    <Col style={{ height }}>
      <MapRaw
        zoom={4}
        height={height}
        width="100%"
        position={{ lat: -15.7802, lng: -47.9292 }}
      >
        {data?.map((city, index) => (
          <MarkerLetter
            background={COLORS[index % COLORS.length]}
            letter={index + 1}
            lat={city[latKey]}
            lng={city[lonKey]}
          />
        ))}
      </MapRaw>
    </Col>
  );
}

export function CityMapQuantLoad({ data, latKey, lonKey, height }) {
  return (
    <Col style={{ height }}>
      <MapRaw
        zoom={4}
        height={height}
        width="100%"
        position={{ lat: -15.7802, lng: -47.9292 }}
      >
        {data?.map((city, index) => (
          <MarkerLetter
            background={COLORS[index % COLORS.length]}
            letter={city.origin_value}
            lat={city[latKey]}
            lng={city[lonKey]}
          />
        ))}
      </MapRaw>
    </Col>
  );
}

export function NumericList({ data, description }) {
  return (
    <StyledNumeric>
      {data?.map(value => (
        <Col
          md={12 / data.length}
          className="d-flex justify-content-center text-center flex-column"
        >
          <TooltipComponent content={<Text>{value.tooltip}</Text>}>
            <Number size={data.length} color={value.color}>
              {value.total}
            </Number>
          </TooltipComponent>
          <Description>{value.description}</Description>
        </Col>
      ))}
      {description && (
        <Col xs={12} className="text-center mt-5">
          {description}
        </Col>
      )}
    </StyledNumeric>
  );
}
