import React, { useState, useEffect, useMemo } from 'react';
import { FaRegClock } from 'react-icons/fa';
import moment from 'moment';

import api from 'services/api';
import { formatTime, formatDateTimeComplete } from 'utils/formatter';
import { groupBy } from 'v3/utils/array';

import { Row, Col } from 'react-bootstrap';

import Card, { DefaultLoadingBodyComponent } from 'v3/components/Card';
import Text from 'v3/components/Text';

import { useTravel } from 'v3/pages/Travel';

export default function DriverJourney() {
  const travel = useTravel();

  const [data, setData] = useState({});

  const end = useMemo(() => {
    const lastDestination =
      travel.data?.loads?.loadDestinations?.[
        travel.data?.loads?.loadDestinations?.length - 1
      ];

    if (lastDestination) {
      let finishedDate = lastDestination.scheduledTime;
      const FINISHED_STATUSES = [6, 7, 10];
      if (!FINISHED_STATUSES.includes(travel.data.statusId)) {
        finishedDate = moment(lastDestination.scheduledTime)
          .add(3, 'days')
          .toDate();
      }

      if (lastDestination.effectiveTime) {
        finishedDate = lastDestination.effectiveTime;
      }

      return finishedDate;
    }

    return new Date();
  }, [travel.data?.loads?.loadDestinations, travel.data.statusId]);

  useEffect(() => {
    const { driver } = travel.data;

    async function fetchData() {
      const start = travel.data.createdAt;

      try {
        const params = { document: driver.cpfCnpj, perPage: 0 };

        if (start) {
          params.start = start;
        }

        if (end) {
          params.end = end;
        }

        const response = await api.get('driver/journey', {
          params,
        });

        const items = response.data;
        setData(
          groupBy(items, item => formatDateTimeComplete(item.sent_at, true))
        );
      } catch (ex) {
        // Handle exception
      }
    }

    if (driver?.cpfCnpj) {
      fetchData();
    }
  }, [travel.data.driver, travel.data.createdAt, end]);

  return (
    <>
      <Card
        loading={travel.isLoading}
        LoadingBodyComponent={() => (
          <DefaultLoadingBodyComponent linesCount={2} perLine={2} />
        )}
        header={<Text color="dark">Jornada do motorista</Text>}
      >
        {Object.entries(data).length > 0 ? (
          Object.entries(data).map(([key, events]) => (
            <Row>
              <Col xs={12} className="mb-1 mt-4">
                <Text color="dark" type="regular" weight="500">
                  {key}
                </Text>
              </Col>
              {events.map(event => (
                <Col xs={12} className="mt-1 align-items-center">
                  <FaRegClock color="#0BB7AF" />
                  <Text color="success" type="regular" className="pl-2">
                    {formatTime(event.sent_at)}
                    <Text color="gray" type="regular">
                      {' '}
                      - {event.event.name}
                    </Text>
                  </Text>
                </Col>
              ))}
            </Row>
          ))
        ) : (
          <Text color="gray" type="regular">
            Essa viagem ainda não possui items da jornada
          </Text>
        )}
      </Card>
    </>
  );
}
