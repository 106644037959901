import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Card, Text } from 'v3/components';
import { DefaultLoadingBodyComponent } from 'v3/components/Card';
import { useModal, usePermission, useValidation } from 'hooks';
import {
  APPROVED_GRO_ANALYSIS_SLUG,
  STATUS_DOCS_ISSUED_ORDER,
  UNDER_GRO_ANALYSIS_SLUG,
} from 'v3/utils/constants';
import { Col, Row } from 'react-bootstrap';
import { BlockedTravelContext } from 'contexts/BlockedTravelProvider';
import { cooperplaceApi } from 'services/api';
import { useSnackbar } from 'v3/components/Snackbar';
import { useTravel } from '../..';
import { RiskManagerCard } from './policyRiskManagerDetails';
import { RiskManagerProvider } from './context';
import { PolicyManualValidationModal } from './Modals/policyManualValidation';
import { FeedModal } from './Modals/feedModal';
import { RiskManagerEditingCard } from './policyRiskManagerEditing';
import { policyRiskManagerSchema, riskManagerSchema } from '../validator';
import { extractGroupOptions } from '../../Register/GRO/utils';
import { GROAnalysisModal } from './Modals/groAnalysis';

export default function PolicyRiskManager() {
  const travel = useTravel();
  const groAnalysisModal = useModal();
  const feedModal = useModal();
  const manualValidationModal = useModal();
  const snackbar = useSnackbar();
  const { isTravelBlocked } = useContext(BlockedTravelContext);

  const [isEditing, setEditing] = useState(false);
  const [isInFlight, setInFlight] = useState(false);
  const [errors, { validate }] = useValidation(riskManagerSchema);
  const [policyErrors, { validate: validatePolicy }] = useValidation(
    policyRiskManagerSchema
  );

  const hasEditPermission = usePermission('ALTERAR_VIAGEM');
  const hasEditPermissionRiskManager = usePermission(
    'EDITAR_GERENCIADORA_DE_RISCO_VIAGEM'
  );
  const [selectedMonitoringGroup, setSelectedMonitoringGroup] = useState(null);
  const [rangeOptions, setRangeOptions] = useState(null);
  const [responsibilitySecurityOptions, setResponsibilitySecurityOptions] =
    useState(null);
  const [loadingRange, setLoadingRange] = useState(false);
  const [travelManualValidations, setTravelManualValidations] = useState(null);
  async function handleRetrieveRangeOptions() {
    try {
      setLoadingRange(true);
      const response = await cooperplaceApi.get(
        `/policies/${travel?.data?.policy?.policyId}/ranges/${travel?.data?.policy?.policyRangeId}`
      );
      setRangeOptions(response?.data);
      if (
        travel?.data?.policy?.policyGroupEquipamentId &&
        response?.data?.equipments_groups?.length > 0
      ) {
        const groupEq = response?.data?.equipments_groups?.find(
          group => group.id === travel?.data?.policy?.policyGroupEquipamentId
        );
        setSelectedMonitoringGroup(groupEq);
      }
      setLoadingRange(false);
    } catch (error) {
      snackbar.show(
        <Text>Não foi possível recuperar opções da faixa de valor</Text>,
        { type: 'error' }
      );
      setLoadingRange(false);
    }
  }
  async function handleRetrieveTravelManualValidations() {
    try {
      const response = await cooperplaceApi.get(
        `travels/${travel?.data?.id}/policy-manual-validation`
      );
      setTravelManualValidations(response?.data);
    } catch (error) {
      if (error?.response?.status === 404) {
        setTravelManualValidations({ logs: [] });
      }
      if (error?.response?.status !== 404)
        snackbar.show(
          <Text>Erro ao recuperar status das validações manuais</Text>,
          {
            type: 'error',
          }
        );
    }
  }
  useEffect(() => {
    if (
      travel?.data?.policy?.id &&
      (isEditing || manualValidationModal.isOpen)
    ) {
      handleRetrieveRangeOptions();
    }
  }, [travel?.data?.policy, isEditing, manualValidationModal.isOpen]);

  useEffect(() => {
    if (travel?.data?.id && travel?.data?.policy?.id) {
      handleRetrieveTravelManualValidations();
    }
    if (travel?.data?.policy?.policyProduct?.id) {
      setResponsibilitySecurityOptions(
        travel?.data?.policy?.policyProduct?.responsabilities
      );
    }
  }, [travel?.data?.id, travel?.data?.policy?.id]);

  const hasntMonitoringGroups = rangeOptions?.equipments_groups?.length === 0;
  const manualValidationOptions = useMemo(() => {
    const searchAndConsultingStatus = {
      driver: false,
      vehicle: false,
      wagons: false,
    };
    let thirdRestrictionStatus = false;
    const vehicleStatus = {
      tractionAgeMax: false,
      wagonsAgeMax: false,
    };
    let driverMinTravelStatus = false;
    let driverRdo = false;

    let securityProfileStatus = { required: false, tooltip: [] };
    let riskManagerTrackerStatus = { required: false, tooltip: [] };
    let riskManagerLogisctSMStatus = { required: false, tooltip: [] };
    let riskManagerLogisticMirrorStatus = { required: false, tooltip: [] };
    let riskManagementValidityChecklistStatus = {
      required: false,
      tooltip: [],
    };
    let riskManagementCreateChecklistStatus = { required: false, tooltip: [] };

    let isTrackerRequired = { required: false, tooltip: [] };
    let isTrackerModelRequired = { required: false, tooltip: [] };
    let isImmobilizerRequired = { required: false, tooltip: [] };
    let isLocatorRequired = { required: false, tooltip: [] };
    let isBaitRequired = { required: false, tooltip: [] };
    let isMonitoringGroupRequired = { required: false, tooltip: [] };
    let rangeMonitoringGroup = null;
    let baitAmount = null;

    let checklistValidity = null;
    let driverGuide = null;

    if (rangeOptions?.equipments_groups?.length > 0) {
      isMonitoringGroupRequired = true;
      rangeMonitoringGroup = rangeOptions?.equipments_groups?.find(
        group => group.id === travel?.data?.policy?.policyGroupEquipamentId
      );
    }

    if (isMonitoringGroupRequired) {
      baitAmount = rangeMonitoringGroup?.bait_quantity;
      if (rangeMonitoringGroup?.baits?.length > 0) {
        isBaitRequired = {
          required: true,
          tooltip: rangeMonitoringGroup?.baits
            ?.map(bt => bt.name.toUpperCase())
            .join(`\n`),
        };
      }
      if (rangeMonitoringGroup?.tecnologies?.length > 0) {
        isTrackerRequired = {
          required: true,
          tooltip: rangeMonitoringGroup?.tecnologies
            ?.map(bt => bt.name.toUpperCase())
            .join(`\n`),
        };
      }
      if (rangeMonitoringGroup?.equipments?.length > 0) {
        isTrackerModelRequired = {
          required: true,
          tooltip: rangeMonitoringGroup?.equipments
            ?.map(bt => bt.name.toUpperCase())
            .join(`\n`),
        };
      }
      if (rangeMonitoringGroup?.locators?.length > 0) {
        isLocatorRequired = {
          required: true,
          tooltip: rangeMonitoringGroup?.locators
            ?.map(bt => bt.name.toUpperCase())
            .join(`\n`),
        };
      }
      if (rangeMonitoringGroup?.immobilizers?.length > 0) {
        isImmobilizerRequired = {
          required: true,
          tooltip: rangeMonitoringGroup?.immobilizers
            ?.map(bt => bt.name.toUpperCase())
            .join(`\n`),
        };
      }
    }

    if (rangeOptions?.groups?.length > 0) {
      const managerTracker = extractGroupOptions(
        rangeOptions?.groups,
        'manager_tracker'
      );
      riskManagerTrackerStatus = {
        required: managerTracker?.length > 0,
        tooltip: managerTracker
          ?.map(item => item?.name?.toUpperCase())
          .join(`\n`),
      };
      const managerMirror = extractGroupOptions(
        rangeOptions?.groups,
        'manager_mirror'
      );
      riskManagerLogisticMirrorStatus = {
        required: managerMirror?.length > 0,
        tooltip: managerMirror
          ?.map(item => item?.name?.toUpperCase())
          .join(`\n`),
      };

      const managerLogisticSM = extractGroupOptions(
        rangeOptions?.groups,
        'logistic_sm'
      );
      riskManagerLogisctSMStatus = {
        required: managerLogisticSM?.length > 0,
        tooltip: managerLogisticSM
          ?.map(item => item?.name?.toUpperCase())
          .join(`\n`),
      };

      const securityProfile = extractGroupOptions(
        rangeOptions?.groups,
        'security_profile'
      );
      securityProfileStatus = {
        required: securityProfile?.length > 0,
        tooltip: securityProfile
          ?.map(item => item?.name?.toUpperCase())
          .join(`\n`),
      };
      const riskManagementValidityChecklist = extractGroupOptions(
        rangeOptions?.groups,
        'validity_check_manager'
      );
      riskManagementValidityChecklistStatus = {
        required: riskManagementValidityChecklist?.length > 0,
        tooltip: riskManagementValidityChecklist
          ?.map(item => item?.name?.toUpperCase())
          .join(`\n`),
      };
      const riskManagerCreateChecklist = extractGroupOptions(
        rangeOptions?.groups,
        'check_manager'
      );
      riskManagementCreateChecklistStatus = {
        required: riskManagerCreateChecklist?.length > 0,
        tooltip: riskManagerCreateChecklist
          ?.map(item => item?.name?.toUpperCase())
          .join(`\n`),
      };
      const searchConsultingOptions = rangeOptions?.groups?.find(
        gp => gp.code === 'search_consulting'
      )?.items;
      searchAndConsultingStatus.driver = !!searchConsultingOptions?.find(
        op => op.name === 'Motorista'
      );
      searchAndConsultingStatus.vehicle = !!searchConsultingOptions?.find(
        op => op.name === 'Veículo Tração'
      );
      searchAndConsultingStatus.wagons = !!searchConsultingOptions?.find(
        op => op.name === 'Implemento'
      );
      const rdo = rangeOptions?.groups?.find(op => op.code === 'rdo')?.items;
      const hasRdoDriver =
        rdo?.length > 0 ? rdo?.find(op => op.name === 'Motorista') : null;
      driverRdo = !!hasRdoDriver;
    }

    thirdRestrictionStatus = rangeOptions?.restrition_third;
    vehicleStatus.tractionAgeMax = rangeOptions?.age_traction_max;
    vehicleStatus.wagonsAgeMax = rangeOptions?.age_implement_max;
    driverMinTravelStatus = rangeOptions?.driver_travel_min;
    checklistValidity = rangeOptions?.checklist_validity;
    driverGuide = rangeOptions?.driver_guide;
    return {
      riskManagementValidityChecklistStatus,
      riskManagementCreateChecklistStatus,
      riskManagerLogisctSMStatus,
      riskManagerLogisticMirrorStatus,
      riskManagerTrackerStatus,
      securityProfileStatus,
      searchAndConsultingStatus,
      thirdRestrictionStatus,
      vehicleStatus,
      driverMinTravelStatus,
      isTrackerRequired,
      isTrackerModelRequired,
      isImmobilizerRequired,
      isLocatorRequired,
      isBaitRequired,
      baitAmount,
      driverRdo,
      checklistValidity,
      driverGuide,
    };
  }, [rangeOptions, travel?.data?.policy?.policyGroupEquipamentId]);

  const processedRangeOptions = useMemo(() => {
    let securityProfileStatus = { required: false, options: [] };
    let riskManagerTrackerStatus = { required: false, options: [] };
    let riskManagerSearchStatus = { required: false, options: [] };
    let riskManagerLogisctSMStatus = { required: false, options: [] };
    let riskManagerLogisticMirrorStatus = { required: false, options: [] };
    if (rangeOptions?.groups?.length > 0) {
      const managerQuery = extractGroupOptions(
        rangeOptions?.groups,
        'manager_query'
      );
      riskManagerSearchStatus = {
        required: managerQuery?.length > 0,
        options: managerQuery,
      };
      const managerTracker = extractGroupOptions(
        rangeOptions?.groups,
        'manager_tracker'
      );
      riskManagerTrackerStatus = {
        required: managerTracker?.length > 0,
        options: managerTracker,
      };
      const managerMirror = extractGroupOptions(
        rangeOptions?.groups,
        'manager_mirror'
      );
      riskManagerLogisticMirrorStatus = {
        required: managerMirror?.length > 0,
        options: managerMirror,
      };

      const managerLogisticSM = extractGroupOptions(
        rangeOptions?.groups,
        'logistic_sm'
      );
      riskManagerLogisctSMStatus = {
        required: managerLogisticSM?.length > 0,
        options: managerLogisticSM,
      };
      const securityProfile = extractGroupOptions(
        rangeOptions?.groups,
        'security_profile'
      );
      securityProfileStatus = {
        required: securityProfile?.length > 0,
        options: securityProfile,
      };
    }
    return {
      riskManagerLogisctSMStatus,
      riskManagerLogisticMirrorStatus,
      riskManagerSearchStatus,
      riskManagerTrackerStatus,
      securityProfileStatus,
    };
  }, [rangeOptions]);

  const monitoringGroupFields = useMemo(() => {
    let isTrackerRequired = false;
    let isTrackerModelRequired = false;
    let isImmobilizerRequired = false;
    let isLocatorRequired = false;
    let isBaitRequired = false;
    const baitAmount = selectedMonitoringGroup?.bait_quantity;

    if (selectedMonitoringGroup?.baits?.length > 0) {
      isBaitRequired = true;
    }
    if (selectedMonitoringGroup?.tecnologies?.length > 0) {
      isTrackerRequired = true;
    }
    if (selectedMonitoringGroup?.equipments?.length > 0) {
      isTrackerModelRequired = true;
    }
    if (selectedMonitoringGroup?.locators?.length > 0) {
      isLocatorRequired = true;
    }
    if (selectedMonitoringGroup?.immobilizers?.length > 0) {
      isImmobilizerRequired = true;
    }
    return {
      isTrackerRequired,
      isTrackerModelRequired,
      isImmobilizerRequired,
      isLocatorRequired,
      isBaitRequired,
      baitAmount,
    };
  }, [selectedMonitoringGroup]);

  const isEditable = useMemo(() => {
    if (travel.data.travelStatus) {
      return (
        hasEditPermission &&
        travel.data.travelStatus.order < STATUS_DOCS_ISSUED_ORDER
      );
    }

    return false;
  }, [travel.data.travelStatus, hasEditPermission]);

  async function handleSubmitRiskManagerPolicyData() {
    try {
      setInFlight(true);
      const payloadToValidate = {
        ...travel?.data,
        baits: travel?.data?.baits?.map(bait => {
          return {
            id: bait?.tracker?.id,
          };
        }),
        baitsNumber: travel?.data?.baits?.map(bait => {
          return bait?.number;
        }),
      };

      const [isValid, data] = await validatePolicy(payloadToValidate, {
        context: {
          shouldInformResponsibilitySecurity: true,
          shouldInformSecurityProfile:
            processedRangeOptions?.securityProfileStatus?.required,
          shouldInformRiskManager:
            processedRangeOptions?.riskManagerTrackerStatus?.required,
          shouldInformRiskManagerSearch:
            processedRangeOptions?.riskManagerSearchStatus?.required,
          shouldInformRiskManagerLogistic:
            processedRangeOptions?.riskManagerLogisctSMStatus?.required,
          shouldInformRiskManagerMirror:
            processedRangeOptions?.riskManagerLogisticMirrorStatus?.required,
          shouldInformMonitoringGroup: !hasntMonitoringGroups,
          shouldInformTrackerTech:
            !hasntMonitoringGroups && monitoringGroupFields?.isTrackerRequired,
          shouldInformTrackerModel:
            !hasntMonitoringGroups &&
            monitoringGroupFields?.isTrackerModelRequired,
          shouldInformImmobilizer:
            !hasntMonitoringGroups &&
            monitoringGroupFields?.isImmobilizerRequired,
          shouldInformLocator:
            !hasntMonitoringGroups && monitoringGroupFields?.isLocatorRequired,
          shouldInformBaits:
            !hasntMonitoringGroups && monitoringGroupFields?.isBaitRequired,
          baitsAmount: monitoringGroupFields?.baitAmount,
        },
      });

      if (!isValid) {
        snackbar.show(
          <Text>Verifique campos obrigatórios no gerenciamento de risco!</Text>,
          {
            type: 'error',
          }
        );
        return;
      }

      await cooperplaceApi.put(`travels/${travel.data.id}/risk-manager`, {
        trackerId: data?.tracker?.id || null,
        trackerTypeId: data?.trackerType?.id || null,
        trackerNumber: data?.trackerNumber || null,
        trackerImmobilizerId: data?.trackerImmobilizer?.id || null,
        immobilizerNumber: data?.immobilizerNumber || null,
        trackerLocatorId: data?.trackerLocator?.id || null,
        locatorNumber: data?.locatorNumber || null,
        responsibilitySecurityId: data?.responsibilitySecurity?.id || null,
        securityProfileId: data?.securityProfile?.id || null,
        riskManagerSearchId: data?.riskManagerSearch?.id || null,
        riskManagerId: data?.riskManager?.id || null,
        riskManagerApprovalCode: data?.riskManagerApprovalCode || null,
        riskManagerMonitoringRequest:
          data?.riskManagerMonitoringRequest || null,
        riskManagerLogisticSmId: data?.riskManagerLogisticSm?.id || null,
        riskCodeLogisticSm: data?.riskCodeLogisticSm || null,
        riskManagerLogisticMirroringId:
          data?.riskManagerLogisticMirroring?.id || null,
        riskManagerStatus: data?.riskManagerStatus || null,
        riskManagerObservation: data?.riskManagerObservation || null,
        endorsement: data?.endorsement || null,
        baits: travel?.data?.baits?.some(bait => bait?.tracker?.id)
          ? travel?.data?.baits?.map(bait => {
              return {
                id: bait?.tracker?.id || null,
                number: bait?.number || '',
              };
            })
          : [],
      });

      snackbar.show(<Text>Dados da viagem atualizados com sucesso.</Text>, {
        type: 'success',
      });

      setEditing(false);
      await travel.fetchTravel();
      await travel.fetchPreSM();
    } catch (error) {
      const message =
        error?.response?.data?.message || 'Erro ao atualizar dados';
      snackbar.show(<Text>{message}</Text>, { type: 'error' });
    } finally {
      setInFlight(false);
    }
  }
  async function handleSubmitRiskManagerData() {
    try {
      setInFlight(true);
      const [isValid, data] = await validate(travel.data, {
        context: {
          riskManagers: travel.data.loads?.riskManagers || [],
        },
      });
      if (!isValid) {
        snackbar.show(
          <Text>Verifique campos obrigatórios no gerenciamento de risco!</Text>,
          {
            type: 'error',
          }
        );
        return;
      }

      await cooperplaceApi.put(`travels/${travel.data.id}/risk-manager`, {
        riskManagerId: data?.riskManager?.id || null,
        trackerId: data?.tracker?.id || null,
        riskManagerApprovalCode: data?.riskManagerApprovalCode || null,
        riskManagerMonitoringRequest:
          data?.riskManagerMonitoringRequest || null,
        riskManagerStatus: travel.data?.riskManagerStatus || null,
        baitNumber: data?.loads.baitNumber || null,
        riskManagerObservation: data?.riskManagerObservation || null,
        securityProfileId: data?.securityProfile?.id,
        endorsement: data?.endorsement || null,
      });

      snackbar.show(<Text>Dados da viagem atualizados com sucesso.</Text>, {
        type: 'success',
      });

      setEditing(false);
      await travel.fetchTravel();
      await travel.fetchPreSM();
    } catch (error) {
      const message =
        error?.response?.data?.message || 'Erro ao atualizar dados';
      snackbar.show(<Text>{message}</Text>, { type: 'error' });
    } finally {
      setInFlight(false);
    }
  }

  function onButtonClick() {
    if (isEditing && travel?.data?.policy?.id) {
      handleSubmitRiskManagerPolicyData();
    } else if (isEditing) {
      handleSubmitRiskManagerData();
    } else {
      setEditing(true);
    }
  }
  function handleCancelEdit() {
    setEditing(false);
    travel.handleCancel();
  }
  async function handleInitGROAnalysis(slug = UNDER_GRO_ANALYSIS_SLUG) {
    try {
      setInFlight(true);
      if (travel?.data?.riskApproved === slug) {
        snackbar.show(<Text>Informe um status diferente do atual!</Text>, {
          type: 'warning',
        });
        setInFlight(false);
        return;
      }
      await cooperplaceApi.put(
        `/travels/${travel?.data?.id}/update-risk-manager-status`,
        {
          status: slug,
        }
      );
      snackbar.show(<Text>Status da análise atualizado com sucesso</Text>, {
        type: 'success',
      });
      setInFlight(false);
      await travel.fetchTravel();
      groAnalysisModal.close();
    } catch (error) {
      const message =
        error?.response?.data?.message || 'Erro ao iniciar análise';
      snackbar.show(<Text>{message}</Text>, { type: 'error' });
      setInFlight(false);
    }
  }

  return (
    <RiskManagerProvider
      value={{
        groAnalysisModal,
        feedModal,
        manualValidationModal,
        hasEditPermissionRiskManager,
        isEditing,
        errors,
        policyErrors,
        selectedMonitoringGroup,
        rangeOptions,
        hasntMonitoringGroups,
        loadingRange,
        processedRangeOptions,
        monitoringGroupFields,
        manualValidationOptions,
        travelManualValidations,
        setTravelManualValidations,
        handleInitGROAnalysis,
        isInFlight,
        responsibilitySecurityOptions,
      }}
    >
      <GROAnalysisModal />
      <FeedModal />
      <PolicyManualValidationModal />
      <Card
        loading={travel.isLoading}
        LoadingBodyComponent={() => (
          <DefaultLoadingBodyComponent linesCount={6} perLine={4} />
        )}
        header={
          <Row>
            <Col xs={12} className="mb-2">
              <Text color="dark" type="header">
                Gerenciamento de risco
              </Text>
            </Col>
          </Row>
        }
        HeaderRightComponent={
          isEditable ? (
            <div>
              {!isEditing && hasEditPermissionRiskManager ? (
                <Button
                  title={
                    travel?.isDisabledGROAnalysis
                      ? 'Análise já foi inicializada'
                      : 'Clique para inicializar processo de análise GRO'
                  }
                  variant="secondary"
                  loading={isInFlight}
                  className="py-2 mr-2"
                  onClick={() => groAnalysisModal.open()}
                >
                  <Text color="dark" type="regular" weight="500">
                    Análise GR
                  </Text>
                </Button>
              ) : null}
              {isEditing ? (
                <Button
                  variant="secondary"
                  disabled={isInFlight}
                  className="py-2 mr-2"
                  onClick={() => handleCancelEdit()}
                >
                  <Text color="dark" type="regular" weight="500">
                    Cancelar
                  </Text>
                </Button>
              ) : null}
              <Button
                disabled={
                  !travel.data?.driver ||
                  !travel.data?.vehicle?.[0] ||
                  isTravelBlocked ||
                  travel?.isDisabledGROAnalysis ||
                  travel?.groAnalysisStatus === APPROVED_GRO_ANALYSIS_SLUG
                }
                onClick={onButtonClick}
                variant={isEditing ? 'primary' : 'secondary'}
                loading={isInFlight}
                className="py-2"
                title={
                  travel?.isDisabledGROAnalysis
                    ? 'Não é possível edição durante período de análise GRO'
                    : travel?.groAnalysisStatus === APPROVED_GRO_ANALYSIS_SLUG
                    ? 'Não é possível edição após aprovação do GRO'
                    : ''
                }
              >
                <Text
                  color={isEditing ? 'white' : 'dark'}
                  weight="500"
                  type="regular"
                >
                  {isEditing ? 'Salvar' : 'Editar'}
                </Text>
              </Button>
            </div>
          ) : null
        }
      >
        {!isEditing ? <RiskManagerCard /> : <RiskManagerEditingCard />}
      </Card>
    </RiskManagerProvider>
  );
}
