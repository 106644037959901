import styled, { css } from 'styled-components';
import theme from '../../theme';

export const Div = styled.div`
  left: 50%;
  z-index: 9999;
  margin: 0 auto;
  min-width: 550px;
  position: fixed;
  border-radius: 0 0 5px 5px;
  padding: ${({ size }) => theme.spacing.buttonX[size]} 25px;
  background-color: ${({ type }) => theme.colors.button[type].light.background};
  color: ${({ type }) => theme.colors.button[type].light.text};
  transition: transform 0.2s ease-in-out;

  ${({ isVisible }) =>
    isVisible
      ? css`
          transform: translate(-50%, 0);
        `
      : css`
          transform: translate(-50%, -100%);
        `}
`;

export const Content = styled.div``;

export const Close = styled.button`
  position: relative;
  float: right;
  background: transparent;
  top: -25px;
  right: -16px;
  font-size: 22px;
  color: #464e5f;
`;
