/* eslint-disable import/no-unresolved */
import api from 'services/api';

export async function updateAutomations(selectedAutomations, loadId) {
  try {
    const data = {
      automations: selectedAutomations
        ? selectedAutomations.map(automation => automation.id)
        : [],
    };
    await api.put(`loads/update-automations/${loadId}`, data);
  } catch (error) {
    // Handle exception
  }
}

export async function getLoadAutomations() {
  try {
    const response = await api.get('automations');
    return response.data;
  } catch (error) {
    // Handle exception
    return false;
  }
}
