import React from 'react';

import Text from 'v3/components/Text';
import { formatCurrency, formatCustomCurrency } from 'v3/utils/formatter';

export default function Data({ loading, editing, error, item, LOAD_TYPE }) {
  return (
    <>
      {!loading && !editing && !error && (
        <>
          <Text type="label" color="#464E5F">
            Ano Vigente
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {item?.year}
          </Text>
          <Text type="label" color="#464E5F">
            Tipo de Carga
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {item?.name}
          </Text>
          <Text type="label" color="#464E5F">
            Valor de Deslocamento
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {formatCustomCurrency(Number(item?.value), 'BRL', 'R$')}
          </Text>
          <Text type="label" color="#464E5F">
            Valor de Carga e Descarga
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {formatCurrency(Number(item?.charge_price), 'BRL', 'R$')}
          </Text>
          <Text type="label" color="#464E5F">
            Número de Eixos
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {item?.qty_axys}
          </Text>
        </>
      )}
    </>
  );
}
