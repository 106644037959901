import styled from 'styled-components';

export const LiItem = styled.li`
  width: 100%;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: ${props =>
        !props.hasExpiredAttachment
            ? '2px solid #fff829'
            : null};

  span{
    padding:2rem;
    button {
      background-color:transparent;
      margin:0 0.5rem;
    }
  }

  span:last-child{
    display:flex;
  }
  
`;
