import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from 'v3/components/Button';
import Modal from 'v3/components/Modal';
import Text from 'v3/components/Text';
import { validatePermission } from 'actions/index';
import { useProduct } from '../shared/context';

export default function RegisteredProductModal({ show }) {
  const history = useHistory();
  const product = useProduct();
  const [hasPermissionToCreate, setPermissionToCreate] = useState();

  useState(() => {
    setPermissionToCreate(validatePermission('CADASTRAR_PRODUTOS'));
  }, []);

  return (
    <Modal
      show={show}
      heading="Cadastro de produto"
      body={
        <>
          <p>Produto {product.data?.name} cadastrado com sucesso!</p>
        </>
      }
      closeButton={false}
      backdrop="static"
      size="lg"
      footer={
        <>
          <Button
            variant="white"
            outlined
            onClick={() => history.push('/produtos')}
          >
            <Text type="regular">Ver listagem</Text>
          </Button>
          <Button
            variant="primary"
            onClick={() => history.push(`/produtos/${product.data?.id}`)}
          >
            <Text type="regular">Visualizar produto</Text>
          </Button>
          {hasPermissionToCreate && (
            <Button
              variant="success"
              onClick={() => window.location.reload(false)}
            >
              <Text type="regular">Novo produto</Text>
            </Button>
          )}
        </>
      }
    />
  );
}
