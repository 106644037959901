import React, { useEffect } from 'react';

import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { validatePermission } from 'actions/index';

import useModal from 'hooks/useModal';
import theme from 'v3/theme';

import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import Fallback from 'v3/components/Fallback';

import SharedData from '../Section';
import Modal from '../Modals/Address';

import { useShippingCompany } from '../context';

export default function ShippingCompanyAddress() {
  const shippingCompany = useShippingCompany();
  const params = useParams();
  const modal = useModal();
  const hasPermissionToEdit = validatePermission('EDITAR_TRANSPORTADORA');

  useEffect(() => {
    if (!params.id) shippingCompany.setData({});
  }, []);

  return (
    <SharedData
      Title={params.id ? 'Endereço *' : 'Endereço'}
      HeaderRightComponent={
        <>
          {shippingCompany.data?.addresses?.[0]?.address && (
            <Button
              className="py-2"
              variant="secondary"
              onClick={() => {
                modal.open();
              }}
              disabled={!hasPermissionToEdit}
            >
              <Text type="regular" weight="500">
                Editar
              </Text>
            </Button>
          )}
        </>
      }
      loading={shippingCompany.isFetching}
    >
      <Modal isOpen={modal.isOpen} onClose={modal.close} />
      <Fallback
        fall={!shippingCompany.data?.addresses?.[0]?.address}
        component={
          <Col xs={12} className="align-items-center d-flex flex-column my-2">
            <Text color={theme.colors.dark} type="regular" weight="400">
              Não há endereço cadastrado para essa transportadora
            </Text>
            {Object.keys(shippingCompany?.errors || {}).some(item =>
              item.includes('address.')
            ) && (
              <div className="mt-2">
                <Text type="regular" color="error">
                  Adicione um endereço
                </Text>
              </div>
            )}
            <Button
              className="mt-3 py-2"
              onClick={() => {
                modal.open();
              }}
            >
              <Text type="regular" weight="500" color="white">
                Adicionar
              </Text>
            </Button>
          </Col>
        }
      >
        <Row>
          <Col xs={12}>
            <Text type="label">País</Text>

            <Text type="regular" color="gray" as="p" className="mt-2">
              {shippingCompany?.data?.addresses?.[0]?.country?.name ||
                'Não informado'}
            </Text>
          </Col>

          <Col xs={12}>
            <Text type="label">Endereço</Text>

            <Text type="regular" color="gray" as="p" className="mt-2">
              {shippingCompany?.data?.addresses?.[0]?.address ||
                'Não informado'}
            </Text>
          </Col>

          <Col xs={12} md={6}>
            <Text type="label">Número</Text>

            <Text type="regular" color="gray" as="p" className="mt-2">
              {shippingCompany?.data?.addresses?.[0]?.number || 'Não informado'}
            </Text>
          </Col>
          <Col xs={12} md={6}>
            <Text type="label">Complemento</Text>

            <Text type="regular" color="gray" as="p" className="mt-2">
              {shippingCompany?.data?.addresses?.[0]?.complement ||
                'Não informado'}
            </Text>
          </Col>

          <Col xs={12} md={6}>
            <Text type="label">Bairro</Text>

            <Text type="regular" color="gray" as="p" className="mt-2">
              {shippingCompany?.data?.addresses?.[0]?.neighborhood ||
                'Não informado'}
            </Text>
          </Col>

          <Col xs={12} md={6}>
            <Text type="label">CEP</Text>

            <Text type="regular" color="gray" as="p" className="mt-2">
              {shippingCompany?.data?.addresses?.[0]?.zip_code ||
                'Não informado'}
            </Text>
          </Col>

          <Col xs={12}>
            <Text type="label">Cidade</Text>

            <Text type="regular" color="gray" as="p" className="mt-2">
              {`${shippingCompany?.data?.addresses?.[0]?.city_name} -  ${shippingCompany?.data?.addresses?.[0]?.uf_name} ` ||
                'Não informado'}
            </Text>
          </Col>
        </Row>
      </Fallback>
    </SharedData>
  );
}
