import api from 'services/api';

export async function fetchTags(name) {
  if (name.length > 3) {
    try {
      const response = await api.get(`tags?&search=${name}`);
      return response.data.data;
    } catch (err) {
      return [];
    }
  }

  return [];
}
