import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import useWindowResize from 'hooks/useWindowResize';
import useModal from 'hooks/useModal';
import ApplicationLayout from 'v3/layouts/Application';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import { usePermission } from 'hooks';
import Section from '../shared/Section';
import Access from '../shared/Access';
import Clients from '../shared/Clients';
import { UserCostCentersManaged } from '../shared/CostCentersManaged';
import Data from '../shared/Data';
import ShippingCompanies from '../shared/ShippingCompanies';
import RegisteredModal from './RegisteredModal';
import Establishments from '../shared/Establishments';
import ProfileSelectionModal from '../shared/Modals/ProfileSelection';
import Beneficiary from '../shared/Beneficiary'
import { useUser } from '../shared/context';
import { handleCreation } from './controller';
import * as Styled from './styles';

export default function UserRegister() {
  usePermission('CADASTRAR_USUARIO', {
    redirect: true,
  });
  const history = useHistory();
  const user = useUser();
  const params = useParams();
  const profileSelectionModal = useModal();
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [saveContainerOffset, setSaveContainerOffset] = useState(0);

  useWindowResize(() => {
    const header = document.getElementById('header-container');
    const originDistanceToRight =
      window.pageXOffset + header.getBoundingClientRect().right;
    const headerWidth = header.getBoundingClientRect().width;

    const SAVE_CONTAINER_PADDING = 0;
    const distanceToRight =
      originDistanceToRight - headerWidth + SAVE_CONTAINER_PADDING;
    setSaveContainerOffset(distanceToRight);
  }, []);

  useEffect(() => {
    if (!params.clone_id) {
      profileSelectionModal.open();
    }
  }, []);

  return (
    <>
      <ProfileSelectionModal
        isOpen={profileSelectionModal.isOpen}
        onClose={profileSelectionModal.close}
      />
      <ApplicationLayout
        title="Cadastrar usuário"
        RightComponent={
          <Styled.SaveContainer style={{ right: saveContainerOffset }}>
            <Button
              variant="secondary"
              onClick={() => history.push('/usuarios')}
            >
              <Text weight={500} color="black" type="regular">
                Voltar
              </Text>
            </Button>

            <Button
              variant="success"
              onClick={() => {
                handleCreation(
                  user.data,
                  user.setData,
                  user.setErrors,
                  user.showSnackbar,
                  setLoading,
                  setShowModal
                );
              }}
              loading={isLoading}
            >
              <Text weight={500} color="white" type="regular">
                Salvar
              </Text>
            </Button>
          </Styled.SaveContainer>
        }
      >
        <RegisteredModal show={showModal} />
        <Row>
          <Col xs={12} md={6}>
            <Section Title="Dados do usuário">
              <Data />
            </Section>
          </Col>
          <Col xs={12} md={6}>
            <Section Title="Dados de acesso">
              <Access />
            </Section>
          </Col>

          {user.data.profile?.id !== 2 && (
            <Col xs={12} md={12}>
              <ShippingCompanies />
              <Beneficiary />
              <Clients />
              <UserCostCentersManaged />
              <Establishments />
            </Col>
          )}
        </Row>
      </ApplicationLayout>
    </>
  );
}
