import moment from 'moment';

export function validatePolicyValidity(dueDate, snackbarShow = false) {
  const currentDate = moment();
  const policyDate = moment(dueDate);
  const isValid = policyDate.isAfter(currentDate);

  if (!isValid && snackbarShow !== false) {
    return snackbarShow(
      'Não é possível atender carga atrelada à apólice vencida! Entre em contato com o setor de GRO',
      'error'
    );
  }
  if (!isValid) {
    return false;
  }

  return isValid;
}

export function validateStartSchedule(
  due_date,
  origin_schedule,
  destination_schedule,
  snackbarShow
) {
  const currentMoment = moment();
  const hourOriginSchedule = moment(origin_schedule).subtract(30, 'minute');

  if (hourOriginSchedule.isBefore(currentMoment)) {
    return snackbarShow(
      'Não foi possivel atender a carga. a coleta deve ser realizada com até 30 minutos de antecedência',
      'error'
    );
  }
  if (moment(destination_schedule).isBefore(currentMoment)) {
    return snackbarShow(
      'Não é possível atender carga com data de destino anterior à data atual!',
      'error'
    );
  }
  if (moment(due_date).isBefore(currentMoment)) {
    return snackbarShow(
      'Não é possível atender carga com vencimento da carga anterior à data atual!',
      'error'
    );
  }
  return true;
}

export function validateBranchOffice(
  history,
  snackbarShow,
  isBranchOfficeActive,
  loadId
) {
  if (!isBranchOfficeActive) {
    return snackbarShow(
      'Não é possível atender carga com filial não ativa!',
      'error'
    );
  }
  return history.push(`/viagens/cadastrar/${loadId}`);
}
