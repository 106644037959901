// eslint-disable-next-line import/no-unresolved
import api from 'services/api';

export async function fetchCities(search) {
  try {
    const response = await api.get('cities', { params: { search } });
    return response.data;
  } catch (ex) {
    return [];
  }
}

export async function save({ id, ...rest }) {
  try {
    const response = await api.put(`issuers/address/${id}`, { ...rest });

    return response.data;
  } catch (err) {
    const message = err.response.data[0]
      ? err.response.data[0].message
      : err.response.data.message;

    return { error: message || 'Não foi possível atualizar dados do endereço' };
  }
}
