import React, { forwardRef } from 'react';

import { StyledInput } from './style';

function TextArea({ resizable = false, minHeight = '85px', ...props }) {
  return (
    <StyledInput
      {...props}
      inputAs="textarea"
      resizable={resizable}
      minHeight={minHeight}
    />
  );
}

export default forwardRef(TextArea);
