import React from 'react';
import * as Styled from './styles';

export default function Tab({ eventKey, title, children }) {
  return (
    <Styled.BSTab eventKey={eventKey} title={title}>
      {children}
    </Styled.BSTab>
  );
}
