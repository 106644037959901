import React from 'react';
import { Row, Col } from 'react-bootstrap';
import CardRaw from 'v3/components/Card';
import Text from 'v3/components/Text';
import { formatPlate } from 'v3/utils/formatter';

export function MatchVehiclesCard({ match }) {
  return (
    <>
      <Col md={6}>
        <CardRaw header={<Text>Veículo compatível</Text>}>
          <Row>
            <Col xs={4}>
              <Text type="label" color="#464E5F">
                Placa
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {formatPlate(match?.plate)}
              </Text>
            </Col>
            <Col xs={4}>
              <Text type="label" color="#464E5F">
                Quantidade de eixos
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {match?.veichleAxi?.name || 'Não informado'}
              </Text>
            </Col>
            <Col xs={4}>
              <Text type="label" color="#464E5F">
                Tipo de veículo
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {match?.vehicleType?.name || 'Não informado'}
              </Text>
            </Col>
            <Col xs={4}>
              <Text type="label" color="#464E5F">
                Nome do dono
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {match?.owner?.socialName || 'Não informado'}
              </Text>
            </Col>
            <Col xs={4}>
              <Text type="label" color="#464E5F">
                Telefone do dono
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                <a
                  rel="noopener noreferrer"
                  href={`https://api.whatsapp.com/send?phone=55${match?.owner.phone.replace(
                    /^(\+)|\D/g,
                    '$1'
                  )}`}
                  target="_blank"
                >
                  <i
                    className="fab fa-whatsapp"
                    style={{ color: '#3ebe4f', fontSize: '20px' }}
                  />{' '}
                  <strong>{match?.owner.phone}</strong>
                </a>
              </Text>
            </Col>
            <Col xs={4}>
              <Text type="label" color="#464E5F">
                Tipo do implemento
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {match?.vehicleBodyType?.name || 'Não informado'}
              </Text>
            </Col>
          </Row>
        </CardRaw>
      </Col>
    </>
  );
}
