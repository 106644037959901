import { Button, Modal, Text } from 'v3/components';
import React from 'react';

function MinimumMargin({ isOpen, onClose, confirm, isNewTravel = false }) {
  return (
    <Modal
      backdropClassName="backdrop-occurrences-aditional-field"
      centered
      heading={<Text type="header">Margem abaixo do mínimo estipulado</Text>}
      handleClose={onClose}
      body={
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            margin: '10px 0',
          }}
        >
          <Text type="regular">
            A margem está abaixo do mínimo estipulado pelo ERP a viagem será
            {`${isNewTravel ? ' criada' : ' atualizada'}`} no status:
            <Text type="span" color="red">
              {' '}
              Bloqueado
            </Text>
          </Text>
          <Text type="regular">
            Deseja {`${isNewTravel ? ' criar' : ' atualizar'}`} a viagem mesmo
            assim?
          </Text>
        </div>
      }
      footer={
        <div style={{ display: 'flex', gap: 8 }}>
          <Button onClick={onClose} variant="secondary">
            Cancelar
          </Button>
          <Button
            onClick={() => {
              confirm({ confirmMinimiumMargin: true });
              onClose();
            }}
          >
            Confirmar
          </Button>
        </div>
      }
      show={isOpen}
    />
  );
}

export default MinimumMargin;
