import React, { useEffect, useState } from 'react';

import { Modal, Text, Select } from 'v3/components';
import { usePolicyRegister } from '../../context';
import Space from 'v3/components/Space';
import { StyledModalButton } from 'v3/pages/Policy/styles';
import { validateVehicle, vehicleMapper } from '../../utils';

export function VehiclesModal() {
  const {
    vehicleModal,
    operations,
    setOperations,
    currentOpIndex,
    currentPdIndex,
    currentVehicleIndex,
    setCurrentOpIndex,
    setCurrentPdIndex,
    setCurrentVehicleIndex,
    vehiclesOptions,
    setErrors,
  } = usePolicyRegister();
  const [newVehicles, setNewVehicles] = useState([]);
  const [error, setError] = useState({});

  function handleAdd() {
    let newState = [...operations];

    let vehicles = newState[currentOpIndex].products[currentPdIndex].vehicles
      ?.map(vehicle => vehicle.vehicles)
      .flatMap(item => item)
      ?.map(item => item.name);
    let newVehiclesOptions = newVehicles?.map(vehicle => vehicle.name);
    const isValid = validateVehicle(vehicles, newVehiclesOptions);
    if (!isValid) {
      setError({
        vehicle:
          'Algum veículo já está incluído na apólice referente a esta categoria de produto.',
      });
      return;
    }

    newState[currentOpIndex].products[currentPdIndex] = {
      ...newState[currentOpIndex].products[currentPdIndex],
      vehicles: [
        ...newState[currentOpIndex].products[currentPdIndex]?.vehicles,
        { vehicles: [...newVehicles], ranges: [] },
      ],
    };

    setOperations(newState);
    setCurrentOpIndex(null);
    setCurrentPdIndex(null);
    setCurrentVehicleIndex(null);
    setNewVehicles([]);
    setError({});
    setErrors({});
    vehicleModal.close();
  }

  function handleEdit() {
    let newVehiclesOptions = newVehicles?.map(vehicle => vehicle.name);

    let newState = JSON.parse(JSON.stringify(operations));
    newState[currentOpIndex].products[currentPdIndex].vehicles[
      currentVehicleIndex
    ].vehicles = [];

    let vehicles = newState[currentOpIndex].products[currentPdIndex].vehicles
      ?.map(vehicle => vehicle.vehicles)
      .flatMap(item => item)
      ?.map(item => item.name);
    const isValid = validateVehicle(vehicles, newVehiclesOptions);
    if (!isValid) {
      setError({
        vehicle:
          'Algum veículo já está incluído na apólice referente a esta categoria de produto.',
      });
      return;
    }

    newState[currentOpIndex].products[currentPdIndex].vehicles[
      currentVehicleIndex
    ].vehicles = [...newVehicles];
    setOperations(newState);
    setCurrentOpIndex(null);
    setCurrentPdIndex(null);
    setCurrentVehicleIndex(null);
    setNewVehicles([]);
    setError({});
    vehicleModal.close();
  }

  function handleCloseVehicleModal() {
    setError({});
    setNewVehicles([]);
    setCurrentOpIndex(null);
    setCurrentPdIndex(null);
    setCurrentVehicleIndex(null);
    vehicleModal.close();
  }

  useEffect(() => {
    if (
      currentOpIndex !== null &&
      currentPdIndex !== null &&
      currentVehicleIndex !== null &&
      vehicleModal.isOpen
    ) {
      setNewVehicles(
        operations[currentOpIndex].products[currentPdIndex].vehicles[
          currentVehicleIndex
        ].vehicles
      );
    }
  }, [vehicleModal, currentOpIndex, currentPdIndex, currentVehicleIndex]);

  function getVehicleLabelValue(option) {
    let labelType = vehicleMapper[option?.type];
    return `${option?.name} - ${labelType}`;
  }
  function handleVehicleSearch(search){
    if(!search){
      return vehiclesOptions
    }
    const parsedSearch = search.toLowerCase();
    return vehiclesOptions.filter(vehicle => vehicle?.name?.toLowerCase().search(parsedSearch) >= 0)
  }
  return (
    <Modal
      size="lg"
      show={vehicleModal.isOpen}
      handleClose={handleCloseVehicleModal}
      heading={
        <Text type="body" color="dark" weight="400">
          {currentOpIndex !== null &&
          currentPdIndex !== null &&
          currentVehicleIndex !== null
            ? 'Editar veículo'
            : 'Novo veículo'}
        </Text>
      }
      body={
        <div>
          <Select.Async
            closeMenuOnSelect={false}
            label="Veículos *"
            placeholder="Selecione o(s) veículo(s)"
            options={vehiclesOptions}
            getOptionLabel={option => getVehicleLabelValue(option)}
            getOptionValue={option => getVehicleLabelValue(option)}
            value={newVehicles}
            onChange={value => setNewVehicles(value)}
            multiple
            error={error?.vehicle}
            onSearch={handleVehicleSearch}
          />
        </div>
      }
      footer={
        <Space x={'0.5rem'}>
          <StyledModalButton
            variant="secondary"
            size="sm"
            onClick={handleCloseVehicleModal}
          >
            <Text>Cancelar</Text>
          </StyledModalButton>
          <StyledModalButton
            size="sm"
            onClick={
              currentOpIndex !== null &&
              currentPdIndex !== null &&
              currentVehicleIndex !== null
                ? handleEdit
                : handleAdd
            }
            disabled={newVehicles?.length === 0 || newVehicles === null}
          >
            <Text>
              {currentOpIndex !== null &&
              currentPdIndex !== null &&
              currentVehicleIndex !== null
                ? 'Editar'
                : 'Adicionar'}
            </Text>
          </StyledModalButton>
        </Space>
      }
    />
  );
}
