import { cooperplaceApi } from 'services/api';
import { validate } from '../shared/validator';

export async function handleCreation(
  data,
  setErrors,
  showSnackbar,
  setLoading,
  history
) {
  const isValid = await validate(
    'allSchema',
    {
      data: {
        ...data,
        province_id: data?.province?.id,
        city_id: data?.city?.id,
        vehicle_type_id:
          data?.vehicle_type_id === 'null' ? null : +data?.vehicle_type_id,
        vehicle_axi_id: +data?.vehicle_axi_id,
      },
    },
    setErrors
  );

  if (!isValid) {
    showSnackbar('Verifique os campos obrigatórios!', { type: 'error' });
    return false;
  }
  try {
    setLoading(true);
    const payload = {
      vehicle_type_id:
        data?.vehicle_type_id === 'null' ||
        data?.vehicle_type_id?.value === 'null'
          ? null
          : +data?.vehicle_type_id?.value || +data?.vehicle_type_id,
      minimum_age: +data?.minimum_age,
      desired_province_id: +data?.province?.id || +data?.province,
      desired_city_id: +data?.city?.id || +data?.city,
      tracker_id: +data?.tracker_id || null,
      person_type: data?.person_type,
      main_unit_vehicle_only: data?.main_unit_vehicle_only,
      observation: data?.observation,
      contact_phone: data?.contact_phone,
      contact_email: data?.contact_email,
      quantity: +data?.quantity,
      active: data?.active,
      axis_type_id: +data?.vehicle_axi_id?.value || +data?.vehicle_axi_id,
    };
    await cooperplaceApi.post('aggregation-offer', payload);
    showSnackbar('Oferta de agregamento criada com sucesso!', {
      type: 'success',
    });
    setLoading(false);
    history.push(`/agregamentos`);
  } catch (error) {
    const message = error.response.data[0]
      ? 'Verifique os campos obrigatórios!'
      : error.message;
    showSnackbar(`${message}`, {
      type: 'error',
    });
    setLoading(false);
  }
}
