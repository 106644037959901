import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import InfoMessage from 'components/InfoMessage';
import InfiniteScroll from 'components/InfiniteScroll';
import Sidebar from 'components/Sidebar';
import Button from 'components/Button';
import Input from 'components/Input';
import Snackbar from 'components/Snackbar';

import { makeStyles } from '@material-ui/core/styles';
import CardTag from './CardTag';
import FormCadastroTag from './FormCadastroTag';

const drawerWidth = 330;

const useStyles = makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: '0',
    paddingTop: '80px',
    overflow: 'initial',
  },
  drawerPaper: {
    width: drawerWidth,
    paddingTop: '80px',
    zIndex: '0',
    overflow: 'initial',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    width: `calc(100% - ${0}px)`,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
}));

export default function PermissoesUsuarios() {
  const classes = useStyles();

  const history = useHistory();

  const [total, setTotal] = useState('');
  const sidebarAdd = useRef(null);
  const [filter, setFilter] = useState('');
  const [tagName, setTagName] = useState('');
  const [isValidNewOption, setIsValidNewOption] = useState(false);
  const [snackbar, setSnackbar] = useState({
    display: false,
    type: 'error',
    message: '',
  });

  const toggleDrawer = () => {
    sidebarAdd.current.toggle();
  };

  function setupFilter() {
    let filter = '';
    filter += `&search=${tagName}`;

    history.push({ search: filter });
    setFilter(filter);
  }

  function handleSubmit(event) {
    event.preventDefault();

    setupFilter();
  }

  function handleTagInput(input) {
    if (input) {
      const inputFiltered = input
        .trimStart()
        .replace(/[^a-zA-Z0-9 ]/g, '')
        .toLowerCase();
      setTagName(inputFiltered);
      setIsValidNewOption(inputFiltered.trim().length >= 3);
    } else {
      setTagName('');
      setIsValidNewOption(false);
    }
  }

  return (
    <>
      <div aria-label="Lista de tags" style={{ height: '60vh' }}>
        <InfiniteScroll
          endpoint="tags"
          filter={filter}
          initialFetch={false}
          onDataFetched={data => setTotal(data.pagination.total)}
          placeholderHeight={145}
          renderItem={(item, index) => <CardTag tag={item} />}
          endMessage={
            <>
              {total === 0 && (
                <Grid
                  container
                  item
                  xs={12}
                  justify="center"
                  style={{ marginTop: '20px' }}
                >
                  <InfoMessage
                    icon="arrow-right"
                    width="auto"
                    snackColor="#8c8c8c"
                    message="A busca não retornou nenhum resultado, revise os filtros"
                  />
                </Grid>
              )}

              {total === undefined && (
                <Grid
                  container
                  item
                  xs={12}
                  justify="center"
                  style={{ marginTop: '20px' }}
                >
                  <InfoMessage
                    icon="arrow-right"
                    width="auto"
                    snackColor="#8c8c8c"
                    message="Preencha os filtros para visualizar o relatório"
                  />
                </Grid>
              )}
            </>
          }
        />

        {total > 0 && (
          <Grid
            container
            item
            xs={12}
            justify="center"
            style={{ marginTop: '20px' }}
          >
            <InfoMessage
              icon="arrow-right"
              width="auto"
              snackColor="#8c8c8c"
              message="Sem mais itens para exibição."
            />
          </Grid>
        )}
      </div>

      <Sidebar
        title="Filtros"
        init
        extraButtons={[
          {
            icon: 'fa-plus',
            action: toggleDrawer,
            title: 'Cadastrar tag',
          },
        ]}
      >
        <form
          onSubmit={e => {
            handleSubmit(e);
          }}
        >
          <Input
            value={tagName}
            label="Nome da tag"
            onChange={e => {
              handleTagInput(e.target.value);
            }}
          />

          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '15px',
            }}
          >
            <Button disabled={!isValidNewOption} type="submit">
              Filtrar
            </Button>
          </div>
        </form>
      </Sidebar>

      <Sidebar
        ref={sidebarAdd}
        closeButton
        iconInit={false}
        title="Cadastrar tag"
      >
        <FormCadastroTag setSnackbar={setSnackbar} />
      </Sidebar>

      <Snackbar
        open={snackbar.display}
        type={snackbar.type}
        message={snackbar.message}
        onClose={() => setSnackbar(false)}
        duration={5000}
      />
    </>
  );
}
