/* eslint-disable import/no-unresolved */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTravel } from 'v3/pages/Travel';
import { useParams } from 'react-router-dom';
import useModal from 'hooks/useModal';
import {
  formatTime,
  pluralize,
  capitalize,
  capitalizeFullname,
} from 'v3/utils/formatter';
import { validatePermission } from 'actions/index';
import Card, { DefaultLoadingBodyComponent } from 'v3/components/Card';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import Fallback from 'v3/components/Fallback';
import Lightbox from 'v3/components/Lightbox';
import Modal from 'v3/components/Modal';
import { BlockedTravelContext } from 'contexts/BlockedTravelProvider';
import ModalAdd from './Add';
import * as Styled from './styles';

export default function Feed() {
  const { isTravelBlocked } = useContext(BlockedTravelContext);
  const params = useParams();
  const travel = useTravel();
  const lightbox = useModal();

  const [loading, setLoading] = useState(false);
  const [modalFeed, setModalFeed] = useState(false);
  const [indexAttachment, setIndexAttachment] = useState(0);

  const hasPermissionToEdit = useMemo(
    () => validatePermission('CADASTRAR_VIAGEM_LOG'),
    []
  );

  useEffect(() => {
    travel.fetchFeed();
  }, [params.id, travel.isLoading]);

  const loadCostCenterExternalId = travel?.data?.loads?.costCenter?.externalId;
  return (
    <>
      <Modal
        size="md"
        heading={
          <Text color="dark" type="header">
            Adicionar um Feed
          </Text>
        }
        centered
        scrollable
        show={modalFeed}
        body={
          <ModalAdd
            fetchTravel={travel.fetchTravel}
            loadCostCenter={loadCostCenterExternalId}
            loading={loading}
            setLoading={setLoading}
          />
        }
        handleClose={loading ? '' : () => setModalFeed(false)}
      />

      <Card
        loading={travel.isLoading}
        LoadingBodyComponent={() => (
          <DefaultLoadingBodyComponent linesCount={2} perLine={2} />
        )}
        header={
          <>
            <Text type="header" color="dark">
              Feed da viagem
            </Text>

            <Button
              disabled={
                !travel.data?.driver ||
                !travel.data?.vehicle?.[0] ||
                !hasPermissionToEdit ||
                isTravelBlocked
              }
              variant="secondary"
              onClick={() => setModalFeed(true)}
              className="py-2"
            >
              <Text type="regular" weight="500">
                Adicionar
              </Text>
            </Button>
          </>
        }
      >
        <Fallback
          fall={travel.feed.length === 0}
          component={<Text>Nenhum evento enviado</Text>}
        >
          {Object.entries(travel.feed).map(([date, item]) => (
            <Styled.Container>
              <div className="mb-1">
                <Text weight={500} color="dark">
                  {date}
                </Text>
              </div>
              <Styled.Marker style={{ marginBottom: 5 }} />

              <Styled.Tray>
                {item.map(feedItem => (
                  <div className="mt-1 pl-4">
                    <div className="mb-2">
                      <Text weight={500} color="dark">
                        {formatTime(feedItem.log_time)}
                      </Text>
                      {' - '}
                      <Text weight={500} color="dark">
                        {capitalize(feedItem.event_type_name)}
                      </Text>
                    </div>
                    {feedItem.statusFrom?.name && feedItem.statusTo?.name ? (
                      <Text type="regular" color="gray">
                        {feedItem.statusTo?.name !== 'Ordem de coleta' ? (
                          <>
                            De {feedItem.statusFrom?.name} para{' '}
                            {feedItem.statusTo?.name}
                            <p>{feedItem?.obs}</p>
                          </>
                        ) : (
                          <> Em {feedItem.statusTo?.name}</>
                        )}
                      </Text>
                    ) : (
                      <Text
                        as="div"
                        type="regular"
                        color="gray"
                        className="mt-1"
                        wordwrap
                      >
                        {!!feedItem.refusedTravel?.refused &&
                          `A viagem foi recusada pela transportadora ${feedItem.refusedTravel.person.social_name}, o motivo foi: `}
                        {feedItem.obs.split('\n').map(message => (
                          <Text
                            wordwrap
                            as="div"
                            type="regular"
                            color="gray"
                            className="mt-1"
                          >
                            {message}
                          </Text>
                        ))}
                      </Text>
                    )}

                    <Text as="div" type="label" color="gray">
                      Criado por {capitalizeFullname(feedItem.user_name)}
                    </Text>
                    <hr />

                    {!!feedItem.media?.length && (
                      <>
                        <Styled.AttachmentButton
                          type="button"
                          onClick={lightbox.open}
                          className="mt-1"
                          onClickCapture={() => setIndexAttachment(feedItem.id)}
                        >
                          <Text color="primary">
                            Visualizar{' '}
                            {pluralize('anexo', feedItem.media.length)}
                          </Text>
                        </Styled.AttachmentButton>
                        {indexAttachment === feedItem.id && (
                          <Lightbox
                            images={feedItem.media}
                            isOpen={lightbox.isOpen}
                            onClose={lightbox.close}
                            getImageSrc={image => image.full_path}
                          />
                        )}
                      </>
                    )}
                  </div>
                ))}
              </Styled.Tray>
              <div className="mb-3">
                <Styled.Marker isLast />
              </div>
            </Styled.Container>
          ))}
        </Fallback>
      </Card>
    </>
  );
}
