import React, { useState, useCallback, useEffect } from 'react';

import { Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import Modal from 'v3/components/Modal';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import Link from 'v3/components/Link';

import api from 'services/api';

const INTEGRATIONS = {
  coopercarga_api: 'Benner',
};

const ENTITIES = {
  shipping_company: {
    url: '/transportadoras',
    title: 'Transportadora',
    property: 'cgccpf',
  },
  vehicle: {
    url: '/veiculos',
    title: 'Veículo',
    property: 'plate',
  },
  driver: {
    url: '/motoristas',
    title: 'Motorista',
    property: 'cgccpf',
  },
};

function Logs({ isOpen, onClose }) {
  const params = useParams();

  const [logs, setLogs] = useState([]);

  async function fetchLogs() {
    try {
      const response = await api.get(
        `v3/company/integrations-logs/${params.id}`
      );

      setLogs(response.data);
    } catch (error) {
      //
    }
  }

  useEffect(() => {
    fetchLogs();
  }, []);

  const handlingEntity = useCallback(
    entity => {
      const payload = JSON.parse(entity.payload);
      const isSuccessful = !!entity.status;

      const integration = Object.entries(INTEGRATIONS).find(([key]) =>
        payload?.automation?.endpoint.includes(key)
      );

      if (!integration) {
        return
      }

      const [, integrationName] = integration

      const [property, entityType] = Object.entries(ENTITIES).find(([key]) =>
        entity?.payload?.includes(key)
      );
      const url = `${entityType?.url}/${payload?.data?.[property]?.id}`;

      return (
        <Col xs={12} className="mb-3">
          <div>
            <Text color="dark" type="header">
              {integrationName}
            </Text>
          </div>
          <Text color={isSuccessful ? 'success' : 'error'} weight="500">
            {isSuccessful ? 'Integrado com sucesso' : 'Erro ao integrar '}
          </Text>
          <div>
            <Link to={url} target="_blank">
              <Text type="regular">
                {entityType?.title} -{' '}
                {payload?.data?.[property]?.[entityType?.property]}
              </Text>
            </Link>
          </div>
          {!isSuccessful && (
            <div>
              <Text type="label">
                {payload?.response?.details?.Mensagem ||
                  'Campo necessário para integração faltante'}
              </Text>
            </div>
          )}
        </Col>
      );
    },
    [logs]
  );

  return (
    <>
      <Modal
        show={isOpen}
        onHide={onClose}
        heading={
          <Text type="subheader" color="dark">
            Logs de integração
          </Text>
        }
        body={
          <Row className="mx-0 mt-1">
            {logs.length ? (
              logs.map(log => handlingEntity(log))
            ) : (
              <Text type="body" color="dark">
                Essa empresa não possui logs
              </Text>
            )}
          </Row>
        }
        footer={
          <Row md={12}>
            <Button
              className="py-2"
              variant="primary"
              onClick={() => onClose()}
            >
              <Text weight="500">Fechar</Text>
            </Button>
          </Row>
        }
      />
    </>
  );
}

export default Logs;
