import React, { useState, useRef, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import InfoMessage from 'components/InfoMessage';
import InfiniteScroll from 'components/InfiniteScroll';
import Snackbar from 'components/Snackbar';

import { makeStyles } from '@material-ui/core/styles';
import api from 'services/api';
import CardMotorista from './CardMotorista/CardMotorista';

const drawerWidth = 330;

const useStyles = makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: '0',
    paddingTop: '80px',
    overflow: 'initial',
  },
  drawerPaper: {
    width: drawerWidth,
    paddingTop: '80px',
    zIndex: '0',
    overflow: 'initial',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    width: `calc(100% - ${0}px)`,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
}));

export default function PermissoesUsuarios() {
  const classes = useStyles();

  const scrollRef = useRef(null);

  const history = useHistory();

  const params = useParams();

  const [total, setTotal] = useState('');
  const [manufacturer, setManufacturer] = useState('');
  const sidebarAdd = useRef(null);
  const [filter, setFilter] = useState('');
  const [tagName, setTagName] = useState('');
  const [snackbar, setSnackbar] = useState({
    display: false,
    type: 'error',
    message: '',
  });

  async function getShippingCompany() {
    const shipping_company = await api.get(`persons/${params.id}`);
    setManufacturer(shipping_company.data.social_name);
  }

  useEffect(() => {
    getShippingCompany();
  }, []);

  return (
    <>
      <div aria-label="Lista de tags" style={{ height: '60vh' }}>
        Lista de motoristas de {manufacturer}
        <InfiniteScroll
          ref={scrollRef}
          endpoint="shipping-companies/drivers"
          filter={`shipping_company_id=${params.id}&paginated=1`}
          initialFetch
          onDataFetched={data => setTotal(data.pagination.total)}
          placeholderHeight={145}
          renderItem={item => <CardMotorista driver={item} />}
          endMessage={
            <>
              {total === 0 && (
                <Grid
                  container
                  item
                  xs={12}
                  justify="center"
                  style={{ marginTop: '20px' }}
                >
                  <InfoMessage
                    icon="arrow-right"
                    width="auto"
                    snackColor="#8c8c8c"
                    message="A busca não retornou nenhum resultado, revise os filtros"
                  />
                </Grid>
              )}

              {total === undefined && (
                <Grid
                  container
                  item
                  xs={12}
                  justify="center"
                  style={{ marginTop: '20px' }}
                >
                  <InfoMessage
                    icon="arrow-right"
                    width="auto"
                    snackColor="#8c8c8c"
                    message="Preencha os filtros para visualizar o relatório"
                  />
                </Grid>
              )}
            </>
          }
        />
        {total > 0 && (
          <Grid
            container
            item
            xs={12}
            justify="center"
            style={{ marginTop: '20px' }}
          >
            <InfoMessage
              icon="arrow-right"
              width="auto"
              snackColor="#8c8c8c"
              message="Sem mais itens para exibição."
            />
          </Grid>
        )}
      </div>
      <Snackbar
        open={snackbar.display}
        type={snackbar.type}
        message={snackbar.message}
        onClose={() => setSnackbar(false)}
        duration={5000}
      />
    </>
  );
}
