import React from 'react';

import { StyledCardNf } from './styles';

export default function CardNf({
  lg = 12,
  md = 12,
  sm = 12,
  xs = 12,
  children,
  ...props
}) {
  return (
    <StyledCardNf lg={lg} md={md} sm={sm} xs={xs} {...props}>
      {children}
    </StyledCardNf>
  );
}
