import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import api from 'services/api';

import Snackbar from 'components/Snackbar';
import { Button, Card, Text, Input } from 'v3/components';
import Space from 'v3/components/Space';
import ApplicationLayout from 'v3/layouts/Application';
import { validateAccount } from './validator';
import { FaExclamationCircle } from 'react-icons/fa';

export function Account() {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [errorFetchingUser, setErrorFetchingUser] = useState(false);
  const [errors, setErrors] = useState({});
  const [accountData, setAccountData] = useState({
    name: '',
    email: '',
    phone: '',
    cpf: '',
    newPassword: '',
    newPasswordConfirmation: '',
    oldPassword: '',
  });
  const [snackBar, setSnackBar] = useState({
    display: false,
    type: 'error',
    message: '',
  });
  const passwordTooltip = `Mínimo de 8 caracteres, sendo 1 letra maiúscula, 1 letra minúscula e pelo menos 1 caracter especial. Caracteres especiais aceitos ! " # $ % & ' ( ) * + , - . / : ; < = > ? @ [ [ \\ \\ ] ] ^ _ \` { | } ~`;

  async function getAccount() {
    try {
      const response = await api.get('user/forEditAccount');
      setAccountData({
        name: response.data.username,
        email: response.data.email,
        phone: response.data.phonenumber
          ? response.data.phonenumber.replace(/[^0-9]/g, '').trim()
          : '',
        cpf: response.data.cpf
          ? response.data.cpf.replace(/[^0-9]/g, '').trim()
          : '',
      });
    } catch (error) {
      setSnackBar({
        type: 'error',
        message: 'Não foi possível recuperar dados do usuário',
        display: true,
      });
      setErrorFetchingUser(true);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    getAccount();
  }, []);

  async function handleSaveAccount() {
    try {
      const isValid = await validateAccount({ ...accountData }, setErrors);
      if (!isValid) {
        return;
      }
      const data = {
        name: accountData.name,
        email: accountData.email,
        phone: accountData.phone
          ? accountData.phone.replace(/[^0-9]/g, '').trim()
          : '',
        cpf: accountData.cpf
          ? accountData.cpf.replace(/[^0-9]/g, '').trim()
          : '',
        oldPassword: accountData.oldPassword,
        newPassword: accountData.newPassword,
        newPasswordConfirmation: accountData.newPasswordConfirmation,
      };
      const response = await api.put('user/editAccount', data);
      if (response.status === 200) {
        setSnackBar({
          type: 'success',
          message: 'Dados pessoais alterados',
          display: true,
        });
        setAccountData(old => ({
          ...old,
          oldPassword: '',
        }));

        return;
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        setSnackBar({
          type: 'error',
          message: error?.response?.data?.message ?? 'Senha antiga inválida',
          display: true,
        });
      } else {
        setSnackBar({
          type: 'error',
          message: 'Não foi possível alterar os dados pessoais',
          display: true,
        });
      }
    }
  }

  if (errorFetchingUser) {
    return (
      <ApplicationLayout>
        <Card>
          <div className="text-center">
            <FaExclamationCircle
              size={26}
              color="#F64E60"
              className="mr-2 mb-1"
            />
            <Text type="header" color="error">
              Erro ao recuperar usuário
            </Text>
          </div>
        </Card>
      </ApplicationLayout>
    );
  }

  return (
    <ApplicationLayout
      title="Editar Conta"
      RightComponent={
        <Space x={'0.5rem'}>
          <Button variant="secondary" onClick={() => history.push('/')}>
            <Text>Voltar</Text>
          </Button>
          <Button onClick={handleSaveAccount}>
            <Text>Salvar</Text>
          </Button>
        </Space>
      }
    >
      <Card
        header={
          <Text type="header" color="dark">
            Dados Pessoais
          </Text>
        }
        loading={loading}
      >
        <Snackbar
          open={snackBar.display}
          type={snackBar.type}
          message={snackBar.message}
          onClose={() => setSnackBar(false)}
        />
        <Row className="mb-3">
          <Col className="mb-3" md={6} xs={12}>
            <Input
              label="Nome *"
              type="text"
              value={accountData?.name}
              onChange={e =>
                setAccountData(old => ({ ...old, name: e.target.value }))
              }
              error={errors?.name}
            />
          </Col>
          <Col className="mb-3" md={6} xs={12}>
            <Input label="Email" disabled value={accountData?.email} />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col className="mb-3" md={6} xs={12}>
            <Input
              label="Telefone *"
              value={accountData?.phone}
              onChange={e =>
                setAccountData(old => ({ ...old, phone: e.target.value }))
              }
              masked
              mask={[
                '(',
                /[0-9]/,
                /\d/,
                ')',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
              ]}
              error={errors?.phone}
            />
          </Col>
          <Col className="mb-3" md={6} xs={12}>
            <Input
              label="CPF *"
              value={accountData?.cpf}
              onChange={e =>
                setAccountData(old => ({ ...old, cpf: e.target.value }))
              }
              masked
              mask={[
                /[0-9]/,
                /\d/,
                /\d/,
                '.',
                /\d/,
                /\d/,
                /\d/,
                '.',
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
              ]}
              error={errors?.cpf}
            />
          </Col>
        </Row>
      </Card>
      <Card
        header={
          <Text type="header" color="dark">
            Senha
          </Text>
        }
        loading={loading}
      >
        <Row className="mb-3">
          <Col md={6} xs={12} className="mb-3">
            <Input
              label="Senha Antiga"
              type="password"
              value={accountData?.oldPassword}
              onChange={e =>
                setAccountData(old => ({ ...old, oldPassword: e.target.value }))
              }
              autoComplete="new-password"
              minLength={8}
              error={errors?.oldPassword}
            />
          </Col>
          <Col md={6} xs={12} className="mb-3">
            <Input
              label="Nova Senha *"
              tooltip={passwordTooltip}
              type="password"
              value={accountData?.newPassword}
              onChange={e =>
                setAccountData(old => ({ ...old, newPassword: e.target.value }))
              }
              autoComplete="new-password"
              minLength={8}
              error={errors?.newPassword}
            />
          </Col>
          <Col md={6} xs={12} className="mb-3">
            <Input
              label="Confirme a Nova Senha *"
              type="password"
              value={accountData?.newPasswordConfirmation}
              onChange={e =>
                setAccountData(old => ({
                  ...old,
                  newPasswordConfirmation: e.target.value,
                }))
              }
              error={errors?.newPasswordConfirmation}
            />
          </Col>
        </Row>
      </Card>
    </ApplicationLayout>
  );
}
