/* eslint-disable consistent-return */
import React, { useState, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { CgChevronDoubleRight } from 'react-icons/cg';

import api from 'services/api';
import { percentage } from 'utils/number';

import { useEffectThrottled } from 'hooks';

import {
  Modal,
  Text,
  Input,
  Button,
  InfiniteScroll,
  Tooltip,
} from 'v3/components';
import { useSnackbar } from 'v3/components/Snackbar';

import theme from 'v3/theme';
import { getChargeMethodById } from 'v3/utils/chargingMethods';

import {
  currencyOptions,
  currencyWithSymbol,
  formatCharge,
  formatCurrency,
} from 'v3/utils/formatter';
import { LoadContext, fetchCountries } from '../controller';
import { Container, StyledRow } from './styles';

const [, useLoad] = LoadContext;

export const currency = {
  currency: 'BRL',
  currency_symbol: 'R$',
};

const typeOptions = [
  { name: 'Coleta', id: 'collect' },
  { name: 'Entrega', id: 'delivery' },
  { name: 'Aduaneiro', id: 'customhouse' },
];

function Route({ isOpen, onClose, client }) {
  const snackbar = useSnackbar();
  const load = useLoad();

  const [filter, setFilter] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [routes, setRoutes] = useState({});
  const [selected, setSelected] = useState({});
  const [routeParameters, setRouteParameters] = useState(null);

  const [pagination, setPagination] = useState({
    lastPage: 1,
    page: 1,
    total: 0,
  });

  async function fetchData(page = 1) {
    if (page === 1) setLoading(true);
    try {
      const response = await api.get('v3/route', {
        params: {
          page,
          clients: [client?.id],
          cities: filter?.main || null,
        },
      });

      const { data, ...rest } = response.data;

      if (data.length) {
        load.setData(old => ({ ...old, hasRoutes: true }));
      }

      setPagination(rest);
      setRoutes(old => {
        if (page === 1) {
          return data;
        }

        return [...old, ...data];
      });
    } catch (error) {
      snackbar.show(<Text>Algo deu errado, tente novamente mais tarde</Text>, {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }

  const handlingDestinations = useCallback(item => {
    const destinations = item?.split(',');

    if (destinations?.length) {
      return (
        <>
          {destinations?.map(d => d).length > 1 && (
            <>
              <Text type="medium" color="gray">
                [{destinations.map(d => d).length - 1} PARADA]{' '}
              </Text>
              <CgChevronDoubleRight color={theme.colors.primary} size={20} />
            </>
          )}
          <Text type="regular" color="gray">
            {destinations[0]}
          </Text>
        </>
      );
    }
  }, []);

  function renderItem(item) {
    return (
      <Container
        onClick={() => setSelected(item)}
        selected={selected.id === item.id}
      >
        <div className="col-1">
          <Text weight={500} type="regular" color="dark">
            #{item?.id}
          </Text>
        </div>

        <div className="col-4">
          <Text type="regular" color="gray">
            {item?.origin_address},{' '}
            {item.origin_number && `${item.origin_number},  `}
            {item.origin_city}/{item.origin_province}
          </Text>
          <CgChevronDoubleRight color={theme.colors.primary} size={20} />

          {handlingDestinations(item?.destinations)}
        </div>

        <div className="col-1">
          <Text type="regular" color="gray">
            {formatCharge(
              +item?.charge_price,
              item.charge_type,
              item?.currency,
              currencyWithSymbol.find(elem => elem?.currency === item?.currency)
                ?.symbol
            ) || 'Não informado'}{' '}
          </Text>
        </div>

        <div className="col-2">
          <Text type="regular" color="gray">
            {getChargeMethodById(item?.charge_type)?.label}
          </Text>
        </div>

        <div className="col-1">
          <Text type="regular" color="gray">
            {item?.product_name}
          </Text>
        </div>

        <div className="col-1">
          <Tooltip content={<Text type="label">{item?.vehicle_types}</Text>}>
            <Text type="regular" color="gray" truncate>
              {item?.vehicle_types}
            </Text>
          </Tooltip>
        </div>

        <div className="col-1">
          <Tooltip content={<Text type="label">{item?.tags}</Text>}>
            <Text type="regular" color="gray" truncate>
              {item?.tags}
            </Text>
          </Tooltip>
        </div>

        <div className="col-1">
          <Link to={`/rotas/${item?.id}`}>
            <Text type="regular" color="light_blue">
              Ver
            </Text>
          </Link>
        </div>
      </Container>
    );
  }

  async function handleSelected() {
    setLoadingButton(true);
    try {
      const response = await api.get(`/v3/route/${selected.id}`);
      let price = false;
      let external_value = response.data?.external_value;
      // Por quilômetro rodado
      if (response.data?.charge_type === 1) {
        price = response.data?.kilometers * response.data?.charge_price;
        external_value =
          response.data?.kilometers * response.data?.external_value;
      }

      // Por entrega
      if (response.data?.charge_type === 7) {
        price =
          response.data?.destinations.length * response.data?.charge_price;
        external_value =
          response.data?.destinations.length * response.data?.external_value;
      }

      const routeParametersParsed = JSON.parse(response.data.parameters);
      let grissPercentage = null;
      let adPercentage = null;
      setRouteParameters(routeParametersParsed);

      if (routeParametersParsed.gris_type === 0) {
        grissPercentage = response?.data?.gris;
      }
      if (routeParametersParsed.advalorem_type === 0) {
        adPercentage = response?.data?.ad_valorem;
      }
      let currencyDetails = {};
      if (response?.data?.currency) {
        currencyDetails = currencyOptions.find(
          item => item.value === response?.data?.currency
        );
      }
      load.setData(old => ({
        ...old,
        routes: response.data,
        gris_percentage: grissPercentage,
        ad_valorem_percentage: adPercentage,
        insurance: response.data?.insurance_value ?? (0.0).toFixed(2),
        chargeType: response.data?.charge_type,
        discharge: response.data?.discharge_price,
        charge: response.data?.loading_charge,
        toll_value: response.data?.toll_value ?? (0.0).toFixed(2),
        isTollIncluded: response.data?.is_toll_payer_client,
        clientPrice: price || response.data?.charge_price,
        freight: external_value,
        charge_price_without_destination_calc: response.data?.charge_price,
        external_value_without_destination_calc: response.data?.external_value,
        selectedVehicles: response.data.vehicles,
        tags: response.data.tags,
        product: {
          name: response.data?.product?.name,
          id: response.data?.product?.id,
          product_type_name: response.data?.product?.productType?.name,
          product_type_id: response.data?.product?.productType?.id,
        },
        origin: {
          ...response.data.origin,
          country: load?.countryList.find(
            e =>
              e.abbreviation ===
              response.data.origin?.country_slug.toLowerCase()
          ),
        },
        destinations: response.data.destinations.map(dest => ({
          ...dest,
          type: dest.action ? typeOptions[1] : typeOptions[0],
          country: load?.countryList.find(
            element => element.abbreviation === dest?.country_slug.toLowerCase()
          ),
        })),
        currency: currencyDetails,
        freight_exchange: response?.data?.exchange,
      }));
    } catch (error) {
      //
    } finally {
      setLoadingButton(false);
      onClose();
      snackbar.show(<Text>Rota selecionada, dados alterados!</Text>, {
        type: 'success',
      });
    }
  }

  function handlePriceByTon() {
    try {
      if (load.data?.routes?.charge_type === 2 && !!load.data?.routes) {
        const weight = Number(load.data.weight) / 1000;

        const range = load.data?.routes?.priceRanges.find(
          ranges =>
            Number(weight) >= ranges?.inicial_amount &&
            Number(weight) <= ranges?.final_amount
        );
        if (range) {
          load.setData(old => ({
            ...old,
            charge_price: Number(weight) * Number(Number(range.value)),
            external_value: range.external_value
              ? Number(range.external_value) * Number(weight)
              : 0,
            gris: percentage(
              load.data?.gris,
              Number(weight) * Number(range.value)
            ),
            insurance_value: percentage(
              load.data?.insurance_value,
              Number(weight) * Number(range.value)
            ),
            ad_valorem: percentage(
              load.data?.ad_valorem,
              Number(weight) * Number(range.value)
            ),
          }));

          snackbar.show(
            <Text>
              A rota selecionada tem o preço por tonelada, alterado valor do
              frete baseado no peso da carga!
            </Text>,
            {
              type: 'warning',
            }
          );
        } else {
          load.setData(old => ({
            ...old,
            charge_price: load.data?.routes?.charge_price,
            external_value: load.data?.routes?.external_value,
          }));
        }
      }
    } catch (error) {
      //
    }
  }

  useEffectThrottled(
    () => {
      handlePriceByTon();
    },
    [load.data?.weight],
    1000
  );

  useEffect(() => {
    if (client?.id) fetchData();
  }, [client?.id]);

  useEffectThrottled(
    () => {
      if (filter && isOpen) {
        fetchData();
      }
    },
    [filter.main],
    1000
  );

  useEffectThrottled(
    () => {
      if (load.charge_price) {
        load.setData(old => ({
          ...old,
          charge_price: load.data.charge_price.toFixed(2),
        }));
      }
    },
    [load.charge_price],
    1000
  );

  async function handleValues() {
    if (load?.data?.cargoValue && !!load.data?.routes) {
      let newGris,
        newAdValorem,
        grisPercentage,
        adPercentage = 0;

      try {
        if (routeParameters.gris_type === 0) {
          newGris = percentage(load.data?.routes?.gris, load.data?.cargoValue);
          grisPercentage = load.data?.routes?.gris;
        } else {
          newGris = load.data?.routes.gris;
        }
        if (routeParameters.advalorem_type === 0) {
          newAdValorem = percentage(
            load.data?.routes?.ad_valorem,
            load.data?.cargoValue
          );
          adPercentage = load.data?.routes?.ad_valorem;
        } else {
          newAdValorem = load.data?.routes.ad_valorem;
        }

        load.setData(old => ({
          ...old,
          gris: newGris,
          ad_valorem: newAdValorem,
          gris_percentage: grisPercentage,
          ad_valorem_percentage: adPercentage,
        }));

        snackbar.show(
          <Text>
            {`Alterado valores de Gris (${formatCurrency(
              newGris
            )}) e ADVALOREM (${formatCurrency(
              newAdValorem
            )}) pelos valores da rota!`}
          </Text>,
          {
            type: 'warning',
          }
        );
      } catch (error) {
        //
      }
    }
  }
  useEffectThrottled(
    () => {
      if (load?.data?.action_griss_advalorem?.code !== 'not_selected_griss')
        handleValues();
    },
    [load?.data?.cargoValue, load?.data?.action_griss_advalorem],
    1000
  );

  function handleNetWeight() {
    try {
      const netWeight = Number(load.data.liquidWeight) / 1000;

      const range = load.data?.routes?.priceRanges.find(
        ranges =>
          Number(netWeight) >= ranges?.inicial_amount &&
          Number(netWeight) <= ranges?.final_amount
      );
      if (range) {
        load.setData(old => ({
          ...old,
          charge_price: Number(netWeight) * Number(range.value),
          external_value: Number(netWeight) * Number(range.external_value),
          gris: percentage(
            load.data?.gris,
            Number(netWeight) * Number(range.value)
          ),
          insurance_value: percentage(
            load.data?.insurance_value,
            Number(netWeight) * Number(range.value)
          ),
          ad_valorem: percentage(
            load.data?.ad_valorem,
            Number(netWeight) * Number(range.value)
          ),
        }));

        snackbar.show(
          <Text>
            A rota selecionada tem o preço por tonelada, alterado valor do frete
            baseado no peso da carga!
          </Text>,
          {
            type: 'warning',
          }
        );
      } else {
        load.setData(old => ({
          ...old,
          charge_price: load.data?.routes?.charge_price,
          external_value: load.data?.routes?.external_value,
        }));
      }
    } catch (error) {
      //
    }
  }

  useEffect(() => {
    if (load.data.routes && load.data.routes.charge_type === 5) {
      handleNetWeight();
    }
  }, [load.data?.liquidWeight]);

  function handlePallets() {
    if (load.data.pallets !== '' && load.data.pallets !== '0') {
      const tempChargePrice =
        Number(load.data.pallets) * Number(load.data.routes.charge_price);
      const tempExternal =
        Number(load.data.pallets) * Number(load.data.routes.external_value);
      load.setData(old => ({
        ...old,
        charge_price: tempChargePrice,
        external_value: tempExternal,
      }));
    } else {
      load.setData(old => ({
        ...old,
        charge_price: load.data?.routes?.charge_price,
        external_value: load.data?.routes?.external_value,
      }));
    }
  }

  useEffect(() => {
    if (load.data.routes && load.data.routes.charge_type === 8) {
      handlePallets();
    }
  }, [load.data?.liquidWeight]);

  function handleCommodityValueType() {
    const chargePrice =
      (Number(load.data.routes.charge_price) / 100) *
      Number(load.data.cargoValue);
    const externalValue =
      (Number(load.data.routes.external_value) / 100) *
      Number(load.data.cargoValue);
    load.setData(old => ({
      ...old,
      charge_price: chargePrice,
      external_value: externalValue,
    }));
  }

  useEffect(() => {
    if (load.data.routes && load.data.routes.charge_type === 6) {
      handleCommodityValueType();
    }
  }, [load.data.cargoValue]);

  return (
    <Modal
      className="modal-xl"
      show={isOpen}
      centered
      handleClose={onClose}
      heading={
        <Text type="header" color="dark" weight="500">
          Selecione uma Rota
        </Text>
      }
      body={
        <StyledRow id="scrollableDiv" className="row">
          <div className="col-4 mt-4 mb-3">
            <Input
              placeholder="Pesquise aqui por uma rota"
              value={filter.main}
              onChange={({ target }) => {
                setFilter(old => ({ ...old, main: target.value }));
              }}
            />
          </div>

          <div className="col-8 mt-4 mb-3">
            <Link to="/rotas/cadastrar">
              <Button className="py-2 float-right mt-1">
                <Text color="white" weight="500" type="regular">
                  Cadastrar uma nova rota
                </Text>
              </Button>
            </Link>
          </div>

          <div className="col-12 my-3">
            {!loading && (
              <div className="d-flex text-center">
                <div className="col-1">
                  <Text weight={500} type="header" color="dark">
                    Nº
                  </Text>
                </div>

                <div className="col-4">
                  <Text weight={500} type="subheader" color={theme.colors.dark}>
                    Rota
                  </Text>
                </div>

                <div className="col-1">
                  <Text weight={500} type="subheader" color="gray">
                    Valor tomador
                  </Text>
                </div>

                <div className="col-2">
                  <Text weight={500} type="subheader" color="gray">
                    Método
                  </Text>
                </div>

                <div className="col-1">
                  <Text weight={500} type="subheader" color="gray">
                    Produto
                  </Text>
                </div>

                <div className="col-1">
                  <Text weight={500} type="subheader" color="gray">
                    Veículo
                  </Text>
                </div>

                <div className="col-1">
                  <Text weight={500} type="subheader" color="gray">
                    Tags
                  </Text>
                </div>

                <div className="col-1">
                  <Text weight={500} type="subheader" color="gray">
                    Ação
                  </Text>
                </div>
              </div>
            )}
          </div>
          <div className="col-12">
            <InfiniteScroll
              data={routes}
              onEndReach={() => {
                fetchData(pagination.page + 1);
              }}
              hasMore={pagination.page < pagination.lastPage}
              scrollThreshold={0.7}
              renderItem={renderItem}
              loading={loading}
              scrollableTarget="scrollableDiv"
            />
          </div>
        </StyledRow>
      }
      footer={
        <div>
          <Button
            className="py-2 mr-3"
            variant="white"
            onClick={() => {
              onClose();
              load.setData(old => ({ ...old, routes: null }));
            }}
          >
            <Text weight="500" type="regular">
              Cancelar
            </Text>
          </Button>
          <Button
            disabled={!selected?.id}
            className="py-2"
            variant="primary"
            onClick={() => {
              handleSelected();
            }}
            loading={loadingButton}
          >
            <Text weight="500" type="regular">
              Salvar
            </Text>
          </Button>
        </div>
      }
    />
  );
}

export default Route;
