import React from 'react';

import Space from 'v3/components/Space';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';

import { usePermission } from 'hooks';
import { useCompany } from '../context';

export default function Editable({
  isEditing,
  setEditing,
  onSave,
  isLoading,
  add,
  openModal,
}) {
  const company = useCompany();

  const hasPermission = usePermission('ALTERAR_EMPRESAS');

  function handleCancel() {
    company.cancelEditing(setEditing);
  }

  if (!hasPermission) return null;

  if (isEditing) {
    return (
      <Space x="5px">
        <Button variant="secondary" onClick={handleCancel} className="py-2">
          <Text color="dark" type="regular">
            Cancelar
          </Text>
        </Button>
        <Button
          variant="success"
          onClick={onSave}
          loading={isLoading}
          className="py-2"
        >
          <Text color="white" type="regular">
            Salvar
          </Text>
        </Button>
      </Space>
    );
  }

  return (
    <>
      {add ? (
        <Button variant="primary" onClick={() => openModal()} className="py-2">
          <Text color="white" weight="500" type="regular">
            Adicionar
          </Text>
        </Button>
      ) : (
        <Button
          variant="secondary"
          onClick={() => setEditing(true)}
          className="py-2"
        >
          <Text color="dark" weight="500" type="regular">
            Editar
          </Text>
        </Button>
      )}
    </>
  );
}
