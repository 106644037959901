import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import ApplicationLayout from 'v3/layouts/Application';
import { Button, Text, InfiniteScroll } from 'v3/components';
import { useSnackbar } from 'v3/components/Snackbar';
import api from 'services/api';
import { useQuery, usePermission } from 'hooks';

import Filter from './Filter';
import Card from './Card';

export default function Product() {
  const queryParams = useQuery();
  const snackbar = useSnackbar();
  usePermission('VISUALIZAR_PRODUTOS', { redirect: true });

  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [pagination, setPagination] = useState({
    lastPage: 1,
    page: 1,
    total: 0,
  });

  async function fetchProducts(page = 1) {
    if (page === 1) setLoading(true);

    try {
      const response = await api.get(`v3/product?${queryParams}&page=${page}`);
      const { data, ...rest } = response.data;
      setPagination(rest);
      setProducts(oldProducts => {
        if (page === 1) {
          return data;
        }
        return [...oldProducts, ...data];
      });
    } catch (error) {
      snackbar.show('Erro ao buscar produto', { type: 'error' });
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {

    if(!queryParams.get('search')){
      fetchProducts();
    }
  }, [queryParams]);

  function renderItem(product) {
    return <Card product={product} />;
  }

  useEffect(() => {
    function onPressedKey(e) {
      if (e.key === 'Enter') {
        fetchProducts();
      }
    }

    window.addEventListener('keyup', onPressedKey);

    return () => {
      window.removeEventListener('keyup', onPressedKey);
    };
  });

  return (
    <ApplicationLayout
      title="Produtos"
      RightComponent={
        <Link to="/produtos/cadastrar">
          <Button variant="primary">
            <Text weight={500} color="white" type="regular">
              Novo produto
            </Text>
          </Button>
        </Link>
      }
    >
      <Filter onChange={fetchProducts} />
      <InfiniteScroll
        data={products}
        onEndReach={() => {
          fetchProducts(pagination.page + 1);
        }}
        hasMore={pagination.page < pagination.lastPage}
        scrollThreshold={0.7}
        renderItem={renderItem}
        loading={loading}
      />
    </ApplicationLayout>
  );
}
