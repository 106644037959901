import { makeStyles } from '@material-ui/core/styles';
import { Col } from 'react-bootstrap';
import styled, { keyframes } from 'styled-components';


export const TreeViewStyle = makeStyles({
  treeView: {
    border: '1px solid #ccc',
    borderRadius: '4px',
    height: '800px ', // Change the height to 100%
    flexGrow: 1,
    maxWidth: 400,
    overflowY: 'auto',
    marginTop: '2%'
  },
  overlay: { // add the overlay class
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    maxWidth: '408px',
    backgroundColor: 'rgba(0, 0, 0, 0.1)', // semi-transparent background
    zIndex: 1 // to make sure the overlay is above the tree items
  },
});

export const workspaceWrapperStyle = {
  display: 'flex',
  flexDirection: 'column',
};

export const StyledCol = styled(Col) `
display: flex;
justify-content: center;
align-items: center;
`;

export const StyledImg = styled.img`
width: 100%;
    margin-left: 150%;
    margin-top: 100%;
`;

const bounceAnimation = keyframes`
  0%, 100% { transform: scale(0.0); }
  50% { transform: scale(1.0); }
`;

export const LoadingSpinner = styled.div`
  width: 40px;
  height: 40px;
  position: relative;
  margin: 50px auto;
  
  &::before, &::after {
    content: "";
    width: 100%;
    height: 100%;    
    border-radius: 50%;
    background-color: #333;
    opacity: 0.6;
    position: absolute;
    top: 15rem;
    left: 0;
    animation: ${bounceAnimation} 2.0s infinite ease-in-out;
  }
  
  &::after {
    animation-delay: -1.0s;
  }
`;

