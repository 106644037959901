import React from 'react';

import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

import CardItem from 'components/CardItem';
import Card from 'components/Card';

export default function CardTag(props) {
  const history = useHistory();

  function tagDetail() {
    history.push(`tag/${props.tag.id}/${props.tag.name}`);
  }

  return (
    <Grid container>
      <Grid item lg={6} md={6} xs={12}>
        <Card
          bordered
          style={{
            marginTop: '10px',
          }}
        >
          <Grid container spacing={2} style={{ padding: '0px' }}>
            <Grid item xs={8}>
              <CardItem className="nameClients" style={{ margin: '15px 0px' }}>
                {props.tag.name}{' '}
              </CardItem>
            </Grid>
            <Grid item xs={4}>
              <Link
                style={{
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  float: 'right',
                  margin: '15px 0px',
                  cursor: 'pointer',
                }}
                onClick={() => tagDetail()}
              >
                Ver detalhes <i className="fas fa-chevron-right"></i>
              </Link>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}
