import React from 'react';

import { StyledTable } from './styles';

export default function Table({
  striped,
  hover,
  children,
  tableStyles,
  ...rest
}) {
  return (
    <div className="table-responsive">
      <StyledTable
        striped={striped}
        hover={hover}
        {...rest}
        customStyles={tableStyles}
      >
        {children}
      </StyledTable>
    </div>
  );
}
