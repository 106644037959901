/* eslint-disable no-unneeded-ternary */
import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import api from 'services/api';
import { useSnackbar } from 'v3/components/Snackbar';
import Table from 'v3/components/Table';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import useModal from 'hooks/useModal';
import SharedData from '../shared/Section';
import Inputs from '../shared/RiskManager';
import Editable from '../shared/Editable';
import { useCompany } from '../shared/context';

export default function RiskManager() {
  const riskManagerModal = useModal();
  const params = useParams();

  const company = useCompany();
  const snackbar = useSnackbar();
  const [isEditing, setEditing] = useState(false);
  const [fetching, setFetching] = useState(false);

  async function handleRiskManagers() {
    try {
      setFetching(true);
      if (params.id) {
        const response = await api.get(
          `v3/company/${params.id}/riskManager/list`
        );
        company.setData({
          riskManagers: response.data,
        });
      }
    } catch (error) {
      snackbar.show(
        <Text>Não foi possível mostrar as gerenciadoras de risco</Text>,
        {
          type: 'error',
        }
      );
    } finally {
      setFetching(false);
    }
  }

  useEffect(() => {
    if (params.id) {
      handleRiskManagers();
    }
  }, [params.id]);

  async function deleteRiskManager(item) {
    try {
      setFetching(true);
      if (params.id) {
        await api.delete(`v3/company/${params.id}/riskManager/${item.id}`);

        company.setData({
          riskManagers: company.data.riskManagers.filter(riskManager => {
            return riskManager.name !== item.name;
          }),
        });
      }
      company.setData({
        riskManagers: company.data.riskManagers.filter(riskManager => {
          return riskManager.name !== item.name;
        }),
      });
      snackbar.show(<Text>Gerenciadora de risco removida!</Text>, {
        type: 'success',
      });

      company.setPrevCompany(company.data);
    } catch (error) {
      snackbar.show(<Text>Algo deu errado, tente novamente!</Text>, {
        type: 'error',
      });
    } finally {
      setFetching(false);
    }
  }
  return (
    <SharedData
      Title="Gerenciadoras de risco"
      HeaderRightComponent={
        <>
          {company?.data?.riskManagers !== undefined &&
            company?.data?.riskManagers.length > 0 && (
              <Editable
                add
                openModal={() => riskManagerModal.open()}
                isEditing={isEditing}
                setEditing={setEditing}
                isLoading={fetching}
              />
            )}
        </>
      }
      loading={company.isFetching}
    >
      <Inputs
        isOpen={riskManagerModal.isOpen}
        onClose={riskManagerModal.close}
        isLoading={fetching}
      />
      <>
        {company?.data?.riskManagers !== undefined &&
        company?.data?.riskManagers.length > 0 ? (
          <Row>
            <Col>
              <Table striped>
                <thead>
                  <tr>
                    <th>
                      <Text weight="500" color="dark" type="regular">
                        Nome
                      </Text>
                    </th>
                    <th>
                      <Text weight="500" color="dark" type="regular">
                        Ação
                      </Text>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {company?.data?.riskManagers?.map(riskManager => (
                    <tr>
                      <td>
                        <Text type="regular" color="gray">
                          {riskManager.name}
                        </Text>
                      </td>
                      <td width="120" className="text-center">
                        <Button
                          variant="error"
                          size="sm"
                          className="p-2 align-items-center d-flex justify-content-center"
                          style={{ height: 28, width: 28, marginLeft: 5 }}
                          onClick={() => deleteRiskManager(riskManager)}
                          loading={fetching}
                          disabled={!company.hasPermissionToEdit}
                        >
                          <FaTrash size={16} />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        ) : (
          <Row className="text-center">
            <Col md={12} className="mb-3">
              <Text color="dark" type="regular">
                Não há gerencidoras de risco cadastradas para essa empresa
              </Text>
            </Col>

            <Col md={12}>
              <Button
                onClick={() => riskManagerModal.open()}
                className="py-2"
                disabled={!company.hasPermissionToEdit}
              >
                <Text color="white" type="regular" weight="500">
                  Adicionar
                </Text>
              </Button>
            </Col>
          </Row>
        )}
      </>
    </SharedData>
  );
}
