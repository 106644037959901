import api from 'services/api';

export async function fetchCities(search) {
  try {
    const response = await api.get('cities', { params: { search } });
    return response.data;
  } catch (ex) {
    return [];
  }
}
