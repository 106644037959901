/* eslint-disable import/no-unresolved */
import styled, { css } from 'styled-components';
import { Col as BootstrapCol, Col, Row } from 'react-bootstrap';

import theme from 'v3/theme';

export const ChecklistContainer = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 0;
  max-height: calc(55px * 3);
  overflow-y: auto;
`;

export const ChecklistItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    display: flex;
    gap: 8px;
    align-items: center;
  }
`;

export const Marker = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${theme.colors.medium_gray};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 4px;
  left: -9px;

  ${({ progress, completed }) =>
    completed &&
    progress &&
    css`
      border-color: ${theme.colors.success};
      background-color: ${theme.colors.success};
    `}

  ${({ progress, completed }) =>
    progress &&
    !completed &&
    css`
      border-color: ${theme.colors.orange};
      background-color: ${theme.colors.orange};
    `}

  ${({ progress, completed }) =>
    !progress &&
    !completed &&
    css`
      border-color: ${theme.colors.medium_gray};
      background-color: ${theme.colors.medium_gray};
    `}
`;

Marker.Tray = styled.div`
  border-left: 2px dashed ${theme.colors.medium_gray};
  height: 100px;
  width: 0;
  margin: 0 auto;

  ${({ progress, completed }) =>
    completed &&
    progress &&
    css`
      border-color: ${theme.colors.success};
      background-color: ${theme.colors.white};
    `}

  ${({ progress, completed }) =>
    progress &&
    !completed &&
    css`
      border-color: ${theme.colors.orange};
      background-color: ${theme.colors.white};
    `}

  ${({ progress, completed }) =>
    !progress &&
    !completed &&
    css`
      border-color: ${theme.colors.medium_gray};
      background-color: ${theme.colors.white};
    `}
`;

export const Schedules = styled(BootstrapCol)`
  padding: 15px;
  border-radius: 5px;
  border: 1px solid ${theme.colors.secondary};
  margin-bottom: 35px;

  ${({ progress, completed }) =>
    completed &&
    progress &&
    css`
      border-color: ${theme.colors.light_green};
      background-color: ${theme.colors.light_green};
    `}

  ${({ progress, completed }) =>
    progress &&
    !completed &&
    css`
      border-color: ${theme.colors.yellow_indicator};
      background-color: ${theme.colors.yellow_indicator};
    `}

    ${({ progress, completed }) =>
    !progress &&
    !completed &&
    css`
      border-color: ${theme.colors.white};
      background-color: ${theme.colors.white};
    `}
`;

export const TrackingContainer = styled(Row)`
  padding: 0.5rem;
`;

export const StyledTrackingCol = styled(Col)`
  padding: 1rem;
  display: flex;
  align-items: flex-start;
  overflow-x: auto;
  justify-content: center;
  img:first-child {
    width: 4rem;
    height: 4rem;
  }
  img:last-child {
    width: 5rem;
  }
  @media (max-width: 1200px) {
    justify-content: start;
  }
`;

export const StyledTrackDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 0.25rem;
  & > div {
    display: flex;
  }
`;

export const OccurrenceMainDetailsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const FeedbacksContainer = styled.div`
  margin-top: 1rem;
  padding: 1rem 1.25rem;
  max-height: 32rem;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  :not(:hover)::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
  :not(:hover)::-webkit-scrollbar-track {
    opacity: 0;
  }
  :not(:hover)::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
  }
  :hover::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
  :hover::-webkit-scrollbar-track {
    background: ${theme.colors.light_gray};
    opacity: 0.5;
  }
  :hover::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border-radius: 10px;
  }
`;

export const FeedbackItemContainer = styled.div`
  padding: 1rem 0 0 0;
  position: relative;
`;

export const FeedbackMarker = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: ${theme.colors.medium_gray};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;

  ${({ isLast }) =>
    isLast &&
    css`
      margin-top: -6px;
      margin-bottom: 20px;
    `};
`;

export const FeedbackTray = styled.div`
  margin-top: 1rem !important;
  border-left: 2px dashed ${theme.colors.medium_gray};
  height: 90%;
  width: 100%;
  margin-left: 7px;
`;
