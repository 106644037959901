import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { useSnackbar } from 'v3/components/Snackbar';
import Text from 'v3/components/Text';
import { validatePermission } from 'actions';
import Button from 'v3/components/Button';
import { cooperplaceApi } from 'services/api';
import Section from '../shared/Section';
import Editable from '../shared/Editable';
import Inputs from '../shared/Data';
import { personTypesOptions } from '../shared/utils';
import { useAggregations } from '../shared/context';
import { validate } from '../shared/validator';

function Data() {
  const aggregation = useAggregations();
  const history = useHistory();
  const snackbar = useSnackbar();
  const [isEditing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  let person = personTypesOptions.find(
    p => p.value === aggregation?.data?.person_type
  )?.label;
  if (person === 'ambos') {
    person = 'Pessoa Física ou Jurídica';
  }
  const hasPermissionToVisualize = validatePermission(
    'VISUALIZAR_AGREGAMENTOS'
  );

  async function saveData() {
    setLoading(true);
    try {
      const isValid = await validate(
        'data',
        { data: aggregation.data },
        aggregation.setErrors
      );
      if (!isValid) {
        snackbar.show(<Text>Verifique os campos obrigatórios</Text>, {
          type: 'error',
        });
        setLoading(false);
        return;
      }
      const payload = {
        vehicle_type_id:
          aggregation.data?.vehicle_type_id === 'null'
            ? null
            : +aggregation.data?.vehicle_type_id,
        minimum_age: +aggregation.data?.minimum_age,
        desired_province_id:
          +aggregation.data?.province?.id || +aggregation.data?.province,
        desired_city_id: +aggregation.data?.city?.id || +aggregation.data?.city,
        tracker_id: +aggregation.data?.tracker_id || null,
        person_type: aggregation.data?.person_type,
        main_unit_vehicle_only: aggregation.data?.main_unit_vehicle_only,
        observation: aggregation.data?.observation,
        contact_phone: aggregation.data?.contact_phone,
        contact_email: aggregation.data?.contact_email,
        quantity: +aggregation.data?.quantity,
        active: aggregation.data?.active,
        axis_type_id: +aggregation.data?.vehicle_axi_id,
      };
      await cooperplaceApi.put(
        `aggregation-offer/${aggregation.data.id}`,
        payload
      );
      setEditing(false);

      snackbar.show(
        <Text>Oferta de agregamento atualizada com sucesso!</Text>,
        { type: 'success' }
      );
      setLoading(false);

      setTimeout(() => {
        history.push(`/agregamentos/${aggregation.data.id}`);
      }, 1000);
    } catch (error) {
      snackbar.show(<Text>Erro ao salvar dados.</Text>, { type: 'error' });
      setLoading(false);
    }
  }

  return (
    <Section
      Title="Dados do agregamento"
      HeaderRightComponent={
        <Editable
          isEditing={isEditing}
          setEditing={setEditing}
          onSave={saveData}
          isLoading={loading}
        />
      }
      loading={aggregation.isFetching}
    >
      {isEditing ? (
        <Inputs />
      ) : (
        <Row>
          {aggregation?.data?.company?.name && (
            <Col xs={6}>
              <Text type="label" color="#464E5F">
                Empresa
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {aggregation.data.company.name}
              </Text>
            </Col>
          )}
          <Col xs={aggregation?.data?.company?.name ? 6 : 12}>
            <Text type="label" color="#464E5F">
              Tipo de Cadastro
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {person || 'Não informado'}
            </Text>
          </Col>
          <Col xs={6}>
            <Text type="label" color="#464E5F">
              Contato
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {aggregation?.data?.contact_email || 'Não informado'}
            </Text>
          </Col>
          <Col xs={6}>
            <Text type="label" color="#464E5F">
              Telefone
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {aggregation?.data?.contact_phone || 'Não informado'}
            </Text>
          </Col>
          <Col xs={6}>
            <Text type="label" color="#464E5F">
              Cidade - Estado
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {aggregation?.data?.city?.name &&
              aggregation?.data?.province?.name
                ? `${aggregation?.data?.city?.name} - ${aggregation?.data?.province?.name}`
                : 'Não informado'}
            </Text>
          </Col>
          <Col xs={6}>
            <Text type="label" color="#464E5F">
              Status
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {aggregation?.data?.active
                ? 'Ativo'
                : 'Inativo' || 'Não informado'}
            </Text>
          </Col>
          <Col xs={6}>
            <Text type="label" color="#464E5F">
              Apenas cavalo
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {aggregation?.data?.main_unit_vehicle_only
                ? 'Sim'
                : 'Não' || 'Não informado'}
            </Text>
          </Col>
          <Col xs={6}>
            <Text type="label" color="#464E5F">
              Vagas
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {aggregation?.data?.quantity || 'Não informado'}
            </Text>
          </Col>
          <Col xs={6}>
            <Text type="label" color="#464E5F">
              Tipo de Veículo
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {aggregation?.data?.vehicleType?.name || 'Não informado'}
            </Text>
          </Col>
          <Col xs={6}>
            <Text type="label" color="#464E5F">
              Tipo de Eixo
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {aggregation?.data?.axisType?.name ||
                aggregation?.data?.veichleAxi?.name ||
                'Não informado'}
            </Text>
          </Col>
          <Col xs={6}>
            <Text type="label" color="#464E5F">
              Ano mínimo do veículo
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {aggregation?.data?.minimum_age || 'Não informado'}
            </Text>
          </Col>
          <Col xs={6}>
            <Text type="label" color="#464E5F">
              Rastreador
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {aggregation?.data?.tracker?.name || 'Não informado'}
            </Text>
          </Col>
          {aggregation.data?.observation && (
            <Col xs={6}>
              <Text type="label" color="#464E5F">
                Observações
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {aggregation?.data?.observation}
              </Text>
            </Col>
          )}
          {hasPermissionToVisualize && (
            <Col xs={6}>
              <Button
                variant="secondary"
                onClick={() =>
                  history.push(`/agregamentos/${aggregation?.data?.id}/busca`)
                }
              >
                <Text weight={500} type="regular">
                  Busca de veículos
                </Text>
              </Button>
            </Col>
          )}
          {hasPermissionToVisualize && (
            <Col xs={6}>
              <Button
                variant="secondary"
                onClick={() =>
                  history.push(
                    `/agregamentos/${aggregation?.data?.id}/veiculos`
                  )
                }
              >
                <Text weight={500} type="regular">
                  Veículos agregados
                </Text>
              </Button>
            </Col>
          )}
        </Row>
      )}
    </Section>
  );
}

export default Data;
