import React, { useMemo, useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useSnackbar } from 'v3/components/Snackbar';
import Text from 'v3/components/Text';

import { validatePermission } from 'actions/index';

export default function usePermission(permission, options = {}) {
  const { redirect = false } = options;

  const snackbar = useSnackbar();
  const history = useHistory();
  const hasPermission = useMemo(
    () => validatePermission(permission),
    [permission]
  );

  useLayoutEffect(() => {
    if (!hasPermission && redirect) {
      snackbar.show(
        <Text>Você não tem permissão para entrar nessa página.</Text>,
        { type: 'error' }
      );
      history.replace('/');
    }
  }, [hasPermission, redirect]);

  return hasPermission;
}
