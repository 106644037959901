import React, { useRef, useEffect, useState } from 'react';

import { Col, Row } from 'react-bootstrap';
import { FaPhoneAlt } from 'react-icons/fa';

import ModalRaw from 'v3/components/Modal';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import Input from 'v3/components/Input';

function Modal({ isOpen, onClose, setEmergencyContact }) {
  const inputRef = useRef();

  const [data, setData] = useState({});
  const [error, setError] = useState('');

  useEffect(() => {
    if (isOpen) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  function handleForm() {
    if (
      (data.name && !data.phone) ||
      (data.phone && !data.name) ||
      (data.email && !data.name)
    ) {
      setError('Preencha os campos necessários!');
      return;
    }
    setEmergencyContact(data);
    onClose();
  }

  return (
    <>
      <ModalRaw
        show={isOpen}
        onHide={onClose}
        heading={
          <Text type="header" color="dark">
            Cadastro de contato de emergência
          </Text>
        }
        body={
          <Row className="mx-0 mt-1">
            <Col md={12} className="mb-3">
              <Input
                ref={inputRef}
                label="Nome *"
                value={data?.name}
                onChange={({ target }) =>
                  setData({
                    ...data,
                    name: target.value,
                  })
                }
                error={!!error && !data.name}
              />
            </Col>
            <Col md={6} className="mb-3">
              <Input
                icon={<FaPhoneAlt color="#464E5F" />}
                value={data?.phone}
                onChange={({ target }) =>
                  setData({
                    ...data,
                    phone: target.value,
                  })
                }
                guide={false}
                masked
                mask={[
                  '(',
                  /[0-9]/,
                  /\d/,
                  ')',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                label="Telefone *"
                error={!!error && !data.phone}
              />
            </Col>
            <Col md={6} className="mb-3">
              <Input
                label="Email"
                value={data?.email}
                onChange={({ target }) =>
                  setData({
                    ...data,
                    email: target.value,
                  })
                }
              />
            </Col>

            <Col md={12} xs={12}>
              <Text color="error" type="label">
                {error}
              </Text>
            </Col>
          </Row>
        }
        footer={
          <Row>
            <Col md={6} xs={12}>
              <Button className="py-2" onClick={() => handleForm()}>
                <Text weight="500" type="regular">
                  Salvar
                </Text>
              </Button>
            </Col>
          </Row>
        }
      />
    </>
  );
}

export default Modal;
