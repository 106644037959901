import React, { useState, useEffect, useCallback } from 'react';
import { KeyboardArrowUp as ArrowUpIcon } from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import SwipeableViews from 'react-swipeable-views';
import { Table, Grid, Box, Hidden, Card } from '@material-ui/core';
import PropTypes from 'prop-types';

import FileInput from 'components/FileInput';
import Snackbar from 'components/Snackbar';
import Button from 'components/Button';
import api from 'services/api';
import moment from 'moment';
import { validatePermission } from 'actions/index';
import Loading from 'components/Loading';
import config from '../../config';
import {
  StyledTabs,
  StyledTab,
  StyledTableBody,
  StyledTableRow,
  StyledTableCell,
  CloseButton,
  CloseButtonWrapper,
} from './styles';

import TabDocumentosFinanceiro from './TabDocumentosFinanceiro';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box p={3} style={{ paddingLeft: 0, paddingRight: 0 }}>
        {children}
      </Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function TabsDetalhesFinanceiro(props) {
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const [invoiceFile, setInvoiceFile] = useState({});
  const [billetFile, setBilletFile] = useState({});
  const [sending, setSending] = useState(false);

  const [faturasList, setFaturasList] = useState([]);
  const [boletosList, setBoletosList] = useState([]);
  const [cteList, setCteList] = useState([]);
  const [ediList, setEdiList] = useState([]);
  const [ediFile, setEdiFile] = useState({});

  const [snackbar, setSnackbar] = useState({
    display: false,
    type: 'error',
    message: '',
  });

  useEffect(() => {
    setFaturasList(props.faturasList);
    if (props.boletosList !== false) setBoletosList(props.boletosList);
    setCteList(props.cteList);
    setEdiList(props.ediList);
  }, [props.faturasList, props.boletosList, props.cteList, props.ediList]);

  const uploadFile = useCallback(
    async (type, file) => {
      setSending(true);

      const data = new FormData();
      data.append('document_file', file);

      try {
        let response;
        if (type === 'edi') {
          response = await api.post(
            `${type}-billing-travel/upload/${props.attendedId}`,
            data
          );
        } else {
          response = await api.post(
            `${type}-billing-travel/upload/${props.attendedId}/${props.cteNumber}`,
            data
          );
        }

        return response.data;
      } catch {
        if (file.size > 1090000) {
          setSnackbar({
            display: true,
            type: 'error',
            message: 'Selecione um arquivo menor do que 1MB',
          });
          return;
        }
        setSnackbar({
          display: true,
          type: 'error',
          message: 'Algo deu errado, tente novamente mais tarde.',
        });
        // Error message
      } finally {
        setSending(false);
      }
    },
    [props.attendedId]
  );

  const handleInvoiceUpload = useCallback(async () => {
    if (!invoiceFile.name) {
      return;
    }

    const invoice = await uploadFile('invoice', invoiceFile);
    if (invoice) {
      setSnackbar({
        display: true,
        type: 'success',
        message: 'Fatura enviada com sucesso.',
      });

      setFaturasList([invoice, ...faturasList]);
      setInvoiceFile({});
    }
  }, [invoiceFile, faturasList]);

  const handleBilletUpload = useCallback(async () => {
    if (!billetFile.name) {
      return;
    }

    const billet = await uploadFile('billet', billetFile);
    if (billet) {
      setSnackbar({
        display: true,
        type: 'success',
        message: 'Boleto enviado com sucesso.',
      });

      setBoletosList([billet, ...boletosList]);

      setBilletFile({});
    }
  }, [billetFile, boletosList]);
  const handleEdiUpload = useCallback(async () => {
    if (!ediFile.name) {
      return;
    }

    const edi = await uploadFile('edi', ediFile);
    if (edi) {
      setSnackbar({
        display: true,
        type: 'success',
        message: 'Edi enviado com sucesso.',
      });

      setEdiList([edi, ...ediList]);
      setEdiFile({});
    }
  }, [ediFile, ediList]);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  return (
    <>
      <Snackbar
        open={snackbar.display}
        type={snackbar.type}
        message={snackbar.message}
        onClose={() => setSnackbar({ ...snackbar, display: false })}
      />

      <Card>
        <StyledTabs value={value} onChange={handleChange}>
          <StyledTab
            label="Documentos"
            {...a11yProps(0)}
            active={value === 0}
          />
          <StyledTab label="Faturas" {...a11yProps(1)} active={value === 1} />
          <StyledTab label="Boletos" {...a11yProps(2)} active={value === 2} />
          <StyledTab label="EDI" {...a11yProps(3)} active={value === 3} />
        </StyledTabs>
        <SwipeableViews
          index={value}
          onChangeIndex={index => handleChange(null, index)}
          style={{ marginTop: 50 }}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            {cteList &&
              cteList.map((cte, index) => (
                <TabDocumentosFinanceiro cte={cte} key={index} />
              ))}
            {!cteList.length && (
              <div style={{ width: '100%', textAlign: 'center' }}>
                <Loading size={40} />
              </div>
            )}
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            {validatePermission('UPLOAD_FATURA_FINANCEIRO') && (
              <Grid
                container
                alignItems="center"
                direction="column"
                style={{ marginBottom: 25 }}
              >
                <FileInput
                  label="Upload da fatura"
                  handleDrop={file => setInvoiceFile(...file)}
                  value={invoiceFile}
                  loading={sending}
                />
                <p style={{ textAlign: 'center' }}>Tamanho maximo 1MB</p>

                {invoiceFile.name && (
                  <Button
                    color="success"
                    style={{ marginTop: 5 }}
                    onClick={handleInvoiceUpload}
                    loading={sending}
                  >
                    Upload
                  </Button>
                )}
              </Grid>
            )}
            <Table>
              <StyledTableBody>
                {faturasList &&
                  faturasList.map((faturas, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell component="th" scope="row">
                        {faturas.doc_filename}
                        <Hidden lgUp>
                          <br />
                          {moment(faturas.created_at).format(
                            'DD/MM/YYYY HH:mm'
                          )}
                        </Hidden>
                      </StyledTableCell>
                      <Hidden mdDown>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          align="center"
                        >
                          {moment(faturas.created_at).format(
                            'DD/MM/YYYY HH:mm'
                          )}
                        </StyledTableCell>
                      </Hidden>
                      <StyledTableCell align="right">
                        <a
                          href={`${
                            config.baseUrlBillingDocuments + faturas.doc_path
                          }/${faturas.doc_filename}`}
                        >
                          <Button color="success">Download</Button>
                        </a>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </StyledTableBody>
            </Table>
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            {validatePermission('UPLOAD_BOLETO_FINANCEIRO_VIAGEM') && (
              <Grid
                container
                alignItems="center"
                direction="column"
                style={{ marginBottom: 25 }}
              >
                <FileInput
                  label="Upload do boleto"
                  handleDrop={file => setBilletFile(...file)}
                  value={billetFile}
                  loading={sending}
                />
                <p style={{ textAlign: 'center' }}>Tamanho maximo 1MB</p>

                {billetFile.name && (
                  <Button
                    color="success"
                    style={{ marginTop: 5 }}
                    onClick={handleBilletUpload}
                    loading={sending}
                  >
                    Upload
                  </Button>
                )}
              </Grid>
            )}
            <Table>
              <StyledTableBody>
                {boletosList &&
                  boletosList.map(boletos => (
                    <StyledTableRow key={boletos.name}>
                      <StyledTableCell component="th" scope="row">
                        {boletos.doc_filename}
                        <Hidden lgUp>
                          <br />
                          {moment(boletos.created_at).format(
                            'DD/MM/YYYY HH:mm'
                          )}
                        </Hidden>
                      </StyledTableCell>
                      <Hidden mdDown>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          align="center"
                        >
                          {moment(boletos.created_at).format(
                            'DD/MM/YYYY HH:mm'
                          )}
                        </StyledTableCell>
                      </Hidden>
                      <StyledTableCell align="right">
                        <a
                          href={`${
                            config.baseUrlBillingDocuments + boletos.doc_path
                          }/${boletos.doc_filename}`}
                        >
                          <Button color="success">Download</Button>
                        </a>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </StyledTableBody>
            </Table>
          </TabPanel>
          <TabPanel value={value} index={3} dir={theme.direction}>
            {validatePermission('UPLOAD_FATURA_FINANCEIRO') && (
              <Grid
                container
                alignItems="center"
                direction="column"
                style={{ marginBottom: 25 }}
              >
                <FileInput
                  label="Upload do EDI"
                  handleDrop={file => setEdiFile(...file)}
                  value={ediFile}
                  loading={sending}
                />
                <p style={{ textAlign: 'center' }}>Tamanho maximo 1MB</p>

                {ediFile.name && (
                  <Button
                    color="success"
                    style={{ marginTop: 5 }}
                    onClick={handleEdiUpload}
                    loading={sending}
                  >
                    Upload
                  </Button>
                )}
              </Grid>
            )}
            <Table>
              <StyledTableBody>
                {ediList &&
                  ediList.map(edi => (
                    <StyledTableRow key={edi.name}>
                      <StyledTableCell component="th" scope="row">
                        {edi.doc_filename}
                        <Hidden lgUp>
                          <br />
                          {moment(edi.created_at).format('DD/MM/YYYY HH:mm')}
                        </Hidden>
                      </StyledTableCell>
                      <Hidden mdDown>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          align="center"
                        >
                          {moment(edi.created_at).format('DD/MM/YYYY HH:mm')}
                        </StyledTableCell>
                      </Hidden>
                      <StyledTableCell align="right">
                        <a
                          href={`${
                            config.baseUrlBillingDocuments + edi.doc_path
                          }/${edi.doc_filename}`}
                        >
                          <Button color="success">Download</Button>
                        </a>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </StyledTableBody>
            </Table>
          </TabPanel>
        </SwipeableViews>
        <CloseButtonWrapper>
          <CloseButton onClick={props.handleClose}>
            <ArrowUpIcon />
            <span>Fechar</span>
          </CloseButton>
        </CloseButtonWrapper>
      </Card>
    </>
  );
}
