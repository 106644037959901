import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import { number_format } from 'functions';

import Card from 'components/Card';

export default function CardCobranca({
  cliente,
  company,
  month,
  year,
  hasTravels,
}) {
  return (
    <Card bordered style={{ marginBottom: '10px' }}>
      <Grid container spacing={2}>
        <Grid item lg={4} md={4} xs={12}>
          <Typography variant="caption" component="p" gutterBottom>
            <strong>Cliente</strong>
          </Typography>
          <Typography variant="body2" gutterBottom>
            {cliente.social_name}
          </Typography>
          <Typography variant="caption" component="p" gutterBottom>
            <strong>CNPJ</strong>
          </Typography>
          <Typography variant="body2" gutterBottom>
            {cliente.cgccpf}
          </Typography>
        </Grid>
        <Grid item lg md xs={12}>
          <Typography variant="caption" component="p" gutterBottom>
            <strong>Cargas lançadas</strong>
          </Typography>
          <Typography variant="body2" gutterBottom>
            {cliente.total_cargas}
          </Typography>
          <Typography variant="caption" component="p" gutterBottom>
            <strong>Total</strong>
          </Typography>
          <Typography variant="body2" gutterBottom>
            R$ {number_format(cliente.valor_total_cargas, 2, ',', '.')}
          </Typography>
        </Grid>

        <Grid item lg md xs={12}>
          <Typography variant="caption" component="p" gutterBottom>
            <strong>Viagens realizadas</strong>
          </Typography>
          <Typography variant="body2" gutterBottom>
            {cliente.total_viagens}
          </Typography>
          <Typography variant="caption" component="p" gutterBottom>
            <strong>Total</strong>
          </Typography>
          <Typography variant="body2" gutterBottom>
            R$ {number_format(cliente.valor_total_viagens, 2, ',', '.')}
          </Typography>
        </Grid>
        {hasTravels && (
          <Grid item lg={2} md={2} xs={12} align="center">
            <div style={{ marginTop: '15%' }}>
              <Link
                style={{
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                }}
                to={`/relatorios/cobrancas/${company}/${cliente.person_client_id}/${month}/${year}`}
              >
                Ver mais
              </Link>
            </div>
          </Grid>
        )}
      </Grid>
    </Card>
  );
}
