import React from 'react';

import { StyledLoading } from './styles';

function Loading({ color = 'primary', size = 22, thickness = 5, ...props }) {
  return (
    <StyledLoading thickness={thickness} size={size} color={color} {...props} />
  );
}

export default Loading;
