import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { cooperplaceApi } from 'services/api';
import { usePermission } from 'hooks';
import theme from 'v3/theme';
import ApplicationLayout from 'v3/layouts/Application';
import { DataTable, Fallback, Text, Button, BadgeActive } from 'v3/components';
import { useSnackbar } from 'v3/components/Snackbar';
import { Col } from 'react-bootstrap';
import Filter from './Filter';

export default function List() {
  usePermission('GERENCIAR_RASTER_BRANCHES', {
    redirect: true,
  });

  const snackbar = useSnackbar();
  const history = useHistory();

  const [branches, setBranches] = useState([]);
  const [pagination, setPagination] = useState();
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    name: '',
    code: '',
    costCenters: [],
    perPage: 5,
    page: 1,
  });

  async function fetchData(params) {
    try {
      setLoading(true);
      const { data } = await cooperplaceApi.get(`/raster/branches`, {
        params: {
          name: params?.name,
          code: params?.code,
          cost_centers: params?.costCenters.map(cost => cost.id),
          per_page: params.perPage,
          page: params.page,
        },
      });
      setBranches(data?.data || []);
      setPagination(data?.meta);
    } catch (error) {
      setBranches([]);
      snackbar.show(
        <Text>Não foi possível recuperar as filiais da raster.</Text>,
        {
          type: 'error',
        }
      );
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData(filter);
  }, []);

  function onPageChanged(value) {
    const params = {
      ...filter,
      page: value,
    };
    setFilter(params);
    fetchData(params);
  }

  function onPerPageChanged(value) {
    const params = {
      ...filter,
      perPage: value,
    };
    setFilter(params);
    fetchData(params);
  }

  function onFilterChange(newFilters) {
    const params = {
      ...filter,
      ...newFilters,
    };
    setFilter(params);
    fetchData(params);
  }

  return (
    <>
      <ApplicationLayout
        title="Gerenciamento de filiais Raster"
        RightComponent={
          <Button variant="secondary" className="ml-2 mr-2">
            <Text
              color="dark"
              weight={500}
              type="regular"
              onClick={() => {
                history.push('/filiais-raster/cadastrar');
              }}
            >
              Nova Filial Raster
            </Text>
          </Button>
        }
      >
        <Filter onFilterChange={onFilterChange} />
        <Fallback
          fall={branches?.length === 0}
          component={
            <Col
              xs={12}
              className="pt-5 d-flex justify-content-center align-items-center"
            >
              <Button colors={theme.colors.primary} size="md" disabled>
                Não há itens a serem exibidos
              </Button>
            </Col>
          }
        >
          <Col className="my-4">
            <DataTable
              data={branches}
              loading={loading}
              total={pagination?.total}
              pages={pagination?.last_page || 1}
              currentPage={filter.page}
              onPageChanged={onPageChanged}
              onPerPageChanged={onPerPageChanged}
              columns={[
                {
                  name: 'Status',
                  selector: row =>
                    !row?.deleted_at ? (
                      <BadgeActive pill light type="success" className="mr-2">
                        <Text type="label">Ativo</Text>
                      </BadgeActive>
                    ) : (
                      <BadgeActive pill light type="error" className="mr-2">
                        <Text type="label">Inativo</Text>
                      </BadgeActive>
                    ),
                },
                {
                  name: 'CNPJ',
                  selector: row => row?.document,
                },
                {
                  name: 'Código filial Raster',
                  selector: row => row?.code,
                },
                {
                  name: 'Razão',
                  selector: row => row?.name,
                },
                {
                  selector: row => (
                    <Button
                      onClick={() => {
                        history.push(`/filiais-raster/${row.id}`);
                      }}
                    >
                      visualizar
                    </Button>
                  ),
                },
              ]}
              withFilterOption={false}
            />
          </Col>
        </Fallback>
      </ApplicationLayout>
    </>
  );
}
