import React, { useEffect, useState } from 'react';

import { Row, Col } from 'react-bootstrap';

import { useQuery } from 'hooks';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import { Input } from 'v3/components';
import { useHistory } from 'react-router-dom';

export default function SecurityProfileFilter({
  fetchData,
  loadingImport,
  loadingImportRaster,
}) {
  const queryParams = useQuery();
  const history = useHistory();
  const [code, setCode] = useState('');
  const [name, setName] = useState('');

  useEffect(() => {
    function onPressedKey(e) {
      if (e.key === 'Enter') {
        fetchData();
      }
    }
    window.addEventListener('keyup', onPressedKey);
    return () => {
      window.removeEventListener('keyup', onPressedKey);
    };
  });

  useEffect(() => {
    if (queryParams.has('code')) {
      setCode(queryParams.get('code'));
    }
    if (queryParams.has('name')) {
      setName(queryParams.get('name'));
    }
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      history.replace({ search: queryParams.toString() });
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [code, name]);

  return (
    <Row className="form d-flex align-items-center my-4">
      <Col>
        <Input
          label="Código"
          type="number"
          labelColor="#fff"
          value={code}
          onChange={e => {
            if (e.target.value) {
              setCode(e.target.value);
              queryParams.set('code', e.target.value);
            } else {
              setCode(null);
              queryParams.delete('code');
            }
          }}
        />
      </Col>
      <Col>
        <Input
          label="Nome"
          labelColor="#fff"
          value={name}
          onChange={e => {
            if (e.target.value) {
              setName(e.target.value);
              queryParams.set('name', e.target.value);
            } else {
              setName(e.target.value);
              queryParams.delete('name');
            }
          }}
        />
      </Col>
      <Col style={{ marginTop: '28px' }}>
        <Button
          variant="success"
          onClick={() => fetchData()}
          disabled={loadingImport || loadingImportRaster}
        >
          <Text type="regular" weight={500}>
            Filtrar
          </Text>
        </Button>
      </Col>
    </Row>
  );
}
