import React, { useState } from 'react';

import Input from 'v3/components/Input';
import Select from 'v3/components/Select';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import { Row, Col } from 'react-bootstrap';

import { fetchTags } from './controller';

function Filter({ fetch }) {
  const [socialName, setSocialName] = useState('');
  const [cgccpf, setCgccpf] = useState('');
  const [tags, setTags] = useState([]);

  const [inputTag, setInputTag] = useState('');

  function tagTreatment(input) {
    if (input) {
      const inputFiltered = input.trimStart().replace(/[^a-zA-Z0-9 ]/g, '');
      setInputTag(inputFiltered);
    } else {
      setInputTag('');
    }
  }

  return (
    <>
      <form
        onSubmit={event => {
          event.preventDefault();
          fetch({
            name: socialName,
            document: cgccpf,
            tags: tags ? tags.map(tag => tag.id) : '',
          });
        }}
      >
        <Row className="filter">
          <Col lg={4} md={4} xs={12}>
            <Input
              label="Nome"
              value={socialName}
              onChange={event => setSocialName(event.target.value)}
              labelColor="#fff"
            />
          </Col>
          <Col lg={4} md={4} xs={12}>
            <Select.Async
              label="Tags"
              value={tags}
              multiple
              onSearch={fetchTags}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              onChange={value => setTags(value || [])}
              onInputChange={e => tagTreatment(e)}
              inputValue={inputTag}
              labelColor="#fff"
            />
          </Col>
          <Col lg={2} md={2} xs={12}>
            <Input
              label="CPF"
              value={cgccpf}
              onChange={event => setCgccpf(event.target.value)}
              masked
              guide={false}
              labelColor="#fff"
              mask={[
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                '.',
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                '.',
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                '-',
                /[0-9]/,
                /[0-9]/,
              ]}
            />
          </Col>
          <Col lg={2} xs={12} className="vertical bottom">
            <Button type="submit" variant="success">
              <Text type="regular">Filtrar</Text>
            </Button>
          </Col>
        </Row>
      </form>
    </>
  );
}

export default Filter;
