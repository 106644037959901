import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button, Text } from 'v3/components';

export function MercosulDeallocateTravelModal({
  showDeallocateModal,
  handleCloseDeallocateModal,
  handleDeallocateTravel,
}) {
  return (
    <Modal show={showDeallocateModal} onHide={handleCloseDeallocateModal}>
      <Modal.Header closeButton onClick={handleCloseDeallocateModal}>
        <Modal.Title>
          <Text type="subtitle" color="dark">
            Confirmação de desalocação de viagem
          </Text>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Text type="body" color="dark">
          Deseja desalocar viagem?
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleDeallocateTravel}>
          <Text weight={500} type="regular">
            Sim
          </Text>
        </Button>
        <Button variant="secondary" onClick={handleCloseDeallocateModal}>
          <Text weight={500} type="regular">
            Não
          </Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
