import React from 'react';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { conformToMask } from 'react-text-mask';

import InputMask from '../index';

const currencyMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: '.',
  allowDecimal: true,
  decimalSymbol: ',',
  decimalLimit: 2,
  integerLimit: 8,
  allowNegative: false,
  allowLeadingZeroes: false,
};

export const currencyMask = createNumberMask({ ...currencyMaskOptions });

export function conformCurrency(value) {
  const { conformedValue } = conformToMask(value, currencyMask, {
    guide: false,
  });
  return conformedValue;
}

export default function InputMaskCurrencty(props) {
  return <InputMask {...props} mask={currencyMask} type="text" />;
}
