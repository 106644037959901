import React, { useState } from 'react';
import api from 'services/api';
import apiReport from 'services/apis/report';
import { Row, Col } from 'react-bootstrap';
import ApplicationLayout from 'v3/layouts/Application';
import Select from 'v3/components/Select';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import Input from 'v3/components/Input';
import { useSnackbar } from 'v3/components/Snackbar';
import { usePermission } from 'hooks';
import Card from './Card';

function Nfs() {
  const snackbar = useSnackbar();
  const [sender, setSender] = useState('');
  const [receiver, setReceiver] = useState('');
  const [number, setNumber] = useState('');
  const [nfs, setNfs] = useState();
  const [loading, setLoading] = useState(false);
  usePermission('VISUALIZAR_RASTREIO_DE_NOTA_FISCAL', { redirect: true })

  async function fetchClients(search) {
    try {
      const response = await api.get('persons/customers', {
        params: { search },
      });
      return response.data;
    } catch (ex) {
      return [];
    }
  }

  async function handleFilter() {
    if (!sender || !number) {
      snackbar.show(<Text>Preencha os filtros</Text>, {
        type: 'error',
      });
      return;
    }

    try {
      setLoading(true);

      const response = await apiReport.get('nf', {
        params: {
          nf_number: number,
          sender_id: sender?.id,
          receiver: receiver?.id,
        },
      });

      setNfs(response.data);
    } catch (error) {
      snackbar.show(
        <Text>
          {error?.response?.status === 404
            ? 'Nenhum dado encontrado com esse filtro.'
            : 'Algo deu errado, tente novamente mais tarde'}
        </Text>,
        {
          type: 'error',
        }
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <ApplicationLayout title="Rastreio de nota fiscal">
      <Row className="filter justify-content-center">
        <Col md={2} xs={12}>
          <Input
            label="Número da nota *"
            value={number}
            onChange={e => setNumber(e.target.value)}
            labelColor="#fff"
          />
        </Col>
        <Col md={4} xs={12}>
          <Select.Async
            label="Remetente *"
            onSearch={fetchClients}
            value={sender}
            onChange={value => setSender(value)}
            getOptionLabel={option => option.label}
            getOptionValue={option => option.id}
            labelColor="#fff"
          />
        </Col>
        <Col md={4} xs={12}>
          <Select.Async
            label="Destinatário"
            onSearch={fetchClients}
            value={receiver}
            onChange={value => setReceiver(value)}
            getOptionLabel={option => option.label}
            getOptionValue={option => option.id}
            labelColor="#fff"
          />
        </Col>
        <Col lg={2} xs={12} className="vertical bottom">
          <Button
            variant="success"
            loading={loading}
            onClick={() => handleFilter()}
            className="w-100"
          >
            <Text type="regular" weight={500}>
              Filtrar
            </Text>
          </Button>
        </Col>
        {nfs && <Card nfs={nfs} />}
      </Row>
    </ApplicationLayout>
  );
}

export default Nfs;
