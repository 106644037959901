import styled from 'styled-components';

export const StyledButtonEdit = styled.button`
  color: #77838f;
  background: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1.2px solid #77838f;
  text-align: center;
  padding: 4px 0px;
  cursor: pointer;

  &:hover {
    background: #f1f4f5;
  }

  svg {
    font-size: 1.1rem;
  }
`;
