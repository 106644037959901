import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { cooperplaceApi } from 'services/api';

import { useSnackbar } from 'v3/components/Snackbar';
import Text from 'v3/components/Text';

import { AggregationsProvider } from './context';

import { RegisterAggregationOffer as Register } from '../RegisterOffer';
import { AggregationDetails as Details } from '../Details';

export default function AggregationShared() {
  const snackbar = useSnackbar();
  const params = useParams();

  const [aggregation, setAggregation] = useState({});
  const [prevAggregation, setPrevAggregation] = useState(aggregation);
  const [isFetching, setIsFetching] = useState(false);
  const [errors, setErrors] = useState({});

  function showSnackbar(text, options) {
    snackbar.show(<Text>{text}</Text>, options);
  }

  useEffect(() => {
    async function fetchAggregation() {
      try {
        const response = await cooperplaceApi.get(
          `aggregation-offer/${params.id}`
        );
        const data = await response.data;
        setAggregation(data);
        setPrevAggregation(data);
      } catch (error) {
        showSnackbar(
          'Ocorreu um erro ao carregar as informações da oferta de agregamento',
          { type: 'error' }
        );
      } finally {
        setIsFetching(false);
      }
    }
    if (params.id) {
      fetchAggregation();
    }
  }, []);

  function setData(data) {
    setAggregation(prev => ({ ...prev, ...data }));
  }

  function updatePrevAggregation() {
    setPrevAggregation(aggregation);
  }

  function handleCancel() {
    setAggregation(aggregation);
  }

  return (
    <AggregationsProvider
      value={{
        data: aggregation,
        setData,
        isFetching,
        errors,
        setErrors,
        showSnackbar,
        handleCancel,
        updatePrevAggregation,
        prevAggregation,
      }}
    >
      {params.id ? <Details /> : <Register />}
    </AggregationsProvider>
  );
}
