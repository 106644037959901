import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import api from 'services/api';
import Snackbar from 'components/Snackbar';
import Select from 'components/Select';
import Button from 'components/Button';
import InfiniteScroll from 'components/InfiniteScroll';
import InfoMessage from 'components/InfoMessage';
import Card from 'components/Card';
import { number_format, downloadCSV } from 'functions';
import { validatePermission } from 'actions/index';
import { usePermission } from 'hooks';
import CellFatura from './CellFatura';
import ClientSelector from '../client-selector';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

let token = 0;
function ReportFaturas() {
  const query = useQuery();

  const [filter, setFilter] = useState('');
  const [data, setData] = useState([]);
  const [total, setTotal] = useState([]);
  const [completed, setCompleted] = useState(0);
  const [loading, setLoading] = useState(false);
  const [travelFinancialValue, setTravelFinancialValue] = useState('Todos');
  const statuses = [
    {
      text: 'Todos',
      value: 'Todos',
    },
    {
      text: 'Cobrando',
      value: 'Cobrando',
    },
    {
      text: 'Pago em Atraso',
      value: 'Pago em Atraso',
    },
    {
      text: 'Pago no Prazo',
      value: 'Pago no Prazo',
    },
    {
      text: 'Cancelada',
      value: 'Cancelada',
    },
  ];

  const [snackBar, setSnackBar] = useState({
    display: false,
    type: 'error',
    message: '',
  });

  const hasPermissionToAcess = usePermission('VISUALIZAR_RELATORIO_FATURAS');

  useEffect(() => {
    if (!hasPermissionToAcess) window.location.href = '/';
  }, []);

  function handleChange(event) {
    setTravelFinancialValue(event.target.value);
  }

  async function nextPage(page = 1) {
    const filters = `${
      `report-invoices?companies=${query.get('companies')}` + '&start_date='
    }${query.get('start_date') || moment().format('YYYY-MM-DD')}&end_date=${
      query.get('end_date') || moment().format('YYYY-MM-DD')
    }${
      travelFinancialValue !== 'Todos' ? `&status=${travelFinancialValue}` : ''
    }&page=${page}`;

    try {
      const response = await api.get(filters);
      setData(oldData => [...oldData, ...response.data.data]);
      setCompleted((response.data.page / response.data.lastPage) * 100);
    } catch (e) {}
  }

  async function loadExportData(page = 1) {
    setCompleted(0);

    setData([]);

    if (
      query.get('companies') === undefined ||
      query.get('companies') === null ||
      !query.get('companies')
    ) {
      setSnackBar({
        display: true,
        type: 'error',
        message: 'Selecione um cliente',
      });
      setLoading(false);
      return;
    }

    const filters = `${
      `report-invoices?companies=${query.get('companies')}` + '&start_date='
    }${query.get('start_date') || moment().format('YYYY-MM-DD')}&end_date=${
      query.get('end_date') || moment().format('YYYY-MM-DD')
    }${
      travelFinancialValue !== 'Todos' ? `&status=${travelFinancialValue}` : ''
    }&page=${page}`;

    try {
      const response = await api.get(filters);
      if (response.data.total === 0) {
        setSnackBar({
          display: true,
          type: 'error',
          message: 'Sem resultados para o período selecionado',
        });

        return;
      }

      setLoading(true);
      const ids = Array.from(
        { length: response.data.lastPage },
        (v, k) => k + 1
      );
      await ids.reduce((p, x) => p.then(_ => nextPage(x)), Promise.resolve());
      setLoading(false);
    } catch (e) {}
  }

  useEffect(() => {
    if (data.length > 0 && !loading) {
      processDataCSV(data);
    }
  }, [loading, data]);

  function processDataCSV(dataLoaded = []) {
    let csvData = [];

    const csv = dataLoaded.map(el => {
      return {
        Tomador: el.client.social_name,
        CNPJ: el.client.cgccpf,
        Fatura: el.invoice_number || '',
        'CTE / NFS': el.invoiceTravels
          ? el.invoiceTravels.map(el => el.cte_number).join(', ')
          : '',
        nf: el.nfs,
        Valor: number_format(el.invoice_amount, 2, ',', '.'),
        Status: el.status,
        Vencimento: el.due_date ? moment(el.due_date).format('DD/MM/YYYY') : '',
        'Data do pagamento':
          el.status === 'Pago em Atraso' || el.status === 'Pago no Prazo'
            ? el.updated_at
            : '',
        'Validado faturamento': el.approved_at
          ? moment(el.approved_at).format('DD/MM/YYYY')
          : '',
        'Emissao da Fatura': el.created_at
          ? moment(el.created_at).format('DD/MM/YYYY')
          : '',
      };
    });

    csvData = csv.map(el => {
      return Object.values(el);
    });

    const header =
      'Tomador;CNPJ;Fatura;CTE/NFS;nf;Valor;Status;Vencimento;Data do pagamento;Validado faturamento;Emissao da Fatura';
    downloadCSV(
      header,
      csvData,
      `faturas-${moment().format('DD-MM-YYYY')}.csv`,
      'text/csv;encoding:utf-8'
    );
  }

  async function getReport(clients = '', startDate, endDate, company_id) {
    const filters = `companies=${clients}&start_date=${startDate}&end_date=${endDate}&company_id=${company_id}${
      travelFinancialValue !== 'Todos' ? `&status=${travelFinancialValue}` : ''
    }&token=${token++}`;

    setFilter(filters);
  }

  return (
    <div>
      <Snackbar
        open={snackBar.display}
        type={snackBar.type}
        message={snackBar.message}
        onClose={() => setSnackBar(false)}
      />
      <Dialog
        open={loading}
        maxWidth="sm"
        fullWidth
        minWidth="sm"
        aria-labelledby="Relatório de viagens"
      >
        <DialogTitle>Relatório</DialogTitle>
        <DialogContent>
          Gerando relatório
          <LinearProgress
            variant="determinate"
            value={completed}
            color="primary"
          />
          <br />
        </DialogContent>
      </Dialog>
      <Card style={{ marginBottom: '10px', overflow: 'visible' }}>
        <Grid container spacing={2}>
          <ClientSelector
            url="/relatorios/financeiro"
            buttonName="Listar Faturas"
            onClientSelected={selected => {
              // setHasCompanies(selected.length > 0);
            }}
            onListReports={getReport}
            pathname="financeiro"
            setSnackBar={setSnackBar}
          />
          <Grid item xs={10}>
            <Select
              value={travelFinancialValue}
              label="Status"
              name="status"
              onChange={handleChange}
              options={statuses}
              itemTitle="text"
            />
            {total > 0
              ? [
                  <Typography
                    variant="subtitle1"
                    style={{ fontSize: '18px', marginBottom: '10px' }}
                  >
                    Faturas{' '}
                    <strong style={{ color: '#1C4B75' }}>{total}</strong>
                  </Typography>,
                ]
              : ''}
          </Grid>
          <Grid item xs={2}>
            {total > 0
              ? [
                  validatePermission('EXPORTAR_RELATORIO_FATURAS') && (
                    <Button
                      color="success"
                      disabled={loading}
                      onClick={() => loadExportData()}
                    >
                      Exportar em CSV
                    </Button>
                  ),
                ]
              : ''}
          </Grid>
        </Grid>
      </Card>
      <div aria-label="Relatório de financeiro" style={{ height: '60vh' }}>
        <InfiniteScroll
          endpoint="report-invoices"
          filter={filter}
          initialFetch={false}
          onDataFetched={data => setTotal(data.pagination.total)}
          placeholderHeight={145}
          renderItem={(item, index) => <CellFatura key={index} fatura={item} />}
          endMessage={
            filter >= null ? (
              <Grid
                container
                item
                xs={12}
                justify="center"
                style={{ marginTop: '20px' }}
              >
                <InfoMessage
                  icon="arrow-right"
                  width="auto"
                  snackColor="#8c8c8c"
                  message="Preencha os filtros para visualizar o relatório"
                />
              </Grid>
            ) : (
              <Grid
                container
                item
                xs={12}
                justify="center"
                style={{ marginTop: '20px' }}
              >
                <InfoMessage
                  icon="arrow-right"
                  width="auto"
                  snackColor="#8c8c8c"
                  message="A busca não retornou nenhum resultado, revise os filtros"
                />
              </Grid>
            )
          }
        />

        {total > 0 && (
          <Grid
            container
            item
            xs={12}
            justify="center"
            style={{ marginTop: '20px' }}
          >
            <InfoMessage
              icon="arrow-right"
              width="auto"
              snackColor="#8c8c8c"
              message="Sem mais itens para exibição."
            />
          </Grid>
        )}
      </div>
    </div>
  );
}

export default ReportFaturas;
