import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import IconButton from 'v3/components/Button/Icon';
import theme from 'v3/theme';

export const QuestionGeneralInfoContainer = styled(Col)`
  display: flex;
  justify-content: space-between;

  & > div > span:first-child {
    display: block;
    margin-bottom: 10px;
  }
`;

export const AddAnswerButtonContainer = styled(Col)`
  display: flex;
  align-items: center;
`;

export const AddAnswerButton = styled.button`
  border: 0;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  &:hover:not(:disabled) {
    text-decoration: underline;
  }
  & > svg {
    color: ${theme.colors.success};
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const SortableAnswerColContainer = styled(Col)`
  display: flex;
  gap: 0.25rem;
`;

export const SortableAnswerHandle = styled.button`
  border: 1px solid transparent;
  cursor: grab !important;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  &:focus {
    border: 1px solid ${theme.colors.medium_gray};
  }
`;

export const SortableAnswerRemoveButton = styled(IconButton)`
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  &:focus {
    border: 1px solid ${theme.colors.light_red};
  }
`;
