export const content = [
  [
    'Tomador exemplo',
    '00.000.000/0000-00',
    '0',
    'Negociador exemplo',
    'negociador@exemplo.com.br',
    '',
    '1',
    '1',
    '',
    '',
    '',
    '10',
    '10000',
    '10000',
    '100',
    '',
    '',
    'dd/mm/aaaa',
    'hh:mm',
    '',
    'tag 1,tag 2,tag 3',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'dd/mm/aaaa',
    'hh:mm',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'dd/mm/aaaa',
    'hh:mm',
    'dd/mm/aaaa',
    'hh:mm',
    'dd/mm/aaaa',
    'hh:mm',
    '9,1',
    '',
    '0',
    'CARGA_FECHADA',
    '',
    '',
    '',
    '',
    '0',
    '',
    '0',
    '',
    '0',
    '',
    '0',
    '',
    '0',
    '0',
  ],
];
