export const geoData = {
  type: 'Topology',
  objects: {
    estados: {
      type: 'GeometryCollection',
      bbox: [
        -73.97741220933203, -33.74579986956992, -34.81727575186676,
        5.24981966486962,
      ],
      geometries: [
        {
          type: 'Polygon',
          properties: {
            name: 'Acre',
            uf: 'AC',
            color: '#ACC995',
            bgColor: '#7AA77F',
          },
          id: 'AC',
          arcs: [[0, 1, 2]],
        },
        {
          type: 'Polygon',
          properties: {
            name: 'Alagoas',
            uf: 'AL',
            color: '#C9B495',
            bgColor: '#A69373',
          },
          id: 'AL',
          arcs: [[3, 4, 5, 6]],
        },
        {
          type: 'Polygon',
          properties: {
            name: 'Amazonas',
            uf: 'AM',
            color: '#ACC995',
            bgColor: '#7AA77F',
          },
          id: 'AM',
          arcs: [[7, 8, 9, 10, -1, 11]],
        },
        {
          type: 'Polygon',
          properties: {
            name: 'Amapá',
            uf: 'AP',
            color: '#ACC995',
            bgColor: '#7AA77F',
          },
          id: 'AP',
          arcs: [[12, 13]],
        },
        {
          type: 'Polygon',
          properties: {
            name: 'Bahia',
            uf: 'BA',
            color: '#C9B495',
            bgColor: '#A69373',
          },
          id: 'BA',
          arcs: [[-6, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]],
        },
        {
          type: 'Polygon',
          properties: {
            name: 'Ceará',
            uf: 'CE',
            color: '#C9B495',
            bgColor: '#A69373',
          },
          id: 'CE',
          arcs: [[24, 25, 26, 27, 28, 29]],
        },
        {
          type: 'Polygon',
          properties: {
            name: 'Distrito Federal',
            uf: 'DF',
            color: '#B8BA66',
            bgColor: '#9E9F53',
          },
          id: 'DF',
          arcs: [[30]],
        },
        {
          type: 'Polygon',
          properties: {
            name: 'Espírito Santo',
            uf: 'ES',
            color: '#C99598',
            bgColor: '#B57F82',
          },
          id: 'ES',
          arcs: [[31, 32, -17, 33]],
        },
        {
          type: 'Polygon',
          properties: {
            name: 'Goiás',
            uf: 'GO',
            color: '#B8BA66',
            bgColor: '#9E9F53',
          },
          id: 'GO',
          arcs: [[34, -21, 35, 36, 37, 38, 39]],
        },
        {
          type: 'Polygon',
          properties: {
            name: 'Maranhão',
            uf: 'MA',
            color: '#C9B495',
            bgColor: '#A69373',
          },
          id: 'MA',
          arcs: [[40, 41, 42, 43]],
        },
        {
          type: 'Polygon',
          properties: {
            name: 'Minas Gerais',
            uf: 'MG',
            color: '#C99598',
            bgColor: '#B57F82',
          },
          id: 'MG',
          arcs: [[-18, -33, 44, 45, 46, -38, -36, -20, 47]],
        },
        {
          type: 'Polygon',
          properties: {
            name: 'Mato Grosso do Sul',
            uf: 'MS',
            color: '#B8BA66',
            bgColor: '#9E9F53',
          },
          id: 'MS',
          arcs: [[-39, -47, 48, 49, 50, 51]],
        },
        {
          type: 'Polygon',
          properties: {
            name: 'Mato Grosso',
            uf: 'MT',
            color: '#B8BA66',
            bgColor: '#9E9F53',
          },
          id: 'MT',
          arcs: [[52, -10, 53, 54, -40, -52, 55]],
        },
        {
          type: 'Polygon',
          properties: {
            name: 'Pará',
            uf: 'PA',
            color: '#ACC995',
            bgColor: '#7AA77F',
          },
          id: 'PA',
          arcs: [[-41, 56, -54, -9, 57, 58, -13, 59]],
        },
        {
          type: 'Polygon',
          properties: {
            name: 'Paraíba',
            uf: 'PB',
            color: '#C9B495',
            bgColor: '#A69373',
          },
          id: 'PB',
          arcs: [[60, 61, -26, 62]],
        },
        {
          type: 'Polygon',
          properties: {
            name: 'Pernambuco',
            uf: 'PE',
            color: '#C9B495',
            bgColor: '#A69373',
          },
          id: 'PE',
          arcs: [[63, -7, -24, 64, -27, -62]],
        },
        {
          type: 'Polygon',
          properties: {
            name: 'Piauí',
            uf: 'PI',
            color: '#C9B495',
            bgColor: '#A69373',
          },
          id: 'PI',
          arcs: [[65, -28, -65, -23, -43]],
        },
        {
          type: 'Polygon',
          properties: {
            name: 'Paraná',
            uf: 'PR',
            color: '#959DC9',
            bgColor: '#6E77A0',
          },
          id: 'PR',
          arcs: [[66, 67, 68, 69, -50]],
        },
        {
          type: 'Polygon',
          properties: {
            name: 'Rio de Janeiro',
            uf: 'RJ',
            color: '#C99598',
            bgColor: '#B57F82',
          },
          id: 'RJ',
          arcs: [[70, -45, -32, 71]],
        },
        {
          type: 'Polygon',
          properties: {
            name: 'Rio Grande do Norte',
            uf: 'RN',
            color: '#C9B495',
            bgColor: '#A69373',
          },
          id: 'RN',
          arcs: [[72, -63, -25]],
        },
        {
          type: 'Polygon',
          properties: {
            name: 'Rondônia',
            uf: 'RO',
            color: '#ACC995',
            bgColor: '#7AA77F',
          },
          id: 'RO',
          arcs: [[-11, -53, 73, -2]],
        },
        {
          type: 'Polygon',
          properties: {
            name: 'Roraima',
            uf: 'RR',
            color: '#ACC995',
            bgColor: '#7AA77F',
          },
          id: 'RR',
          arcs: [[-58, -8, 74]],
        },
        {
          type: 'Polygon',
          properties: {
            name: 'Rio Grande do Sul',
            uf: 'RS',
            color: '#959DC9',
            bgColor: '#6E77A0',
          },
          id: 'RS',
          arcs: [[75, 76]],
        },
        {
          type: 'Polygon',
          properties: {
            name: 'Santa Catarina',
            uf: 'SC',
            color: '#959DC9',
            bgColor: '#6E77A0',
          },
          id: 'SC',
          arcs: [[-69, 77, -76, 78]],
        },
        {
          type: 'Polygon',
          properties: {
            name: 'Sergipe',
            uf: 'SE',
            color: '#C9B495',
            bgColor: '#A69373',
          },
          id: 'SE',
          arcs: [[-5, 79, -15]],
        },
        {
          type: 'Polygon',
          properties: {
            name: 'São Paulo',
            uf: 'SP',
            color: '#C99598',
            bgColor: '#B57F82',
          },
          id: 'SP',
          arcs: [[80, -67, -49, -46, -71]],
        },
        {
          type: 'Polygon',
          properties: {
            name: 'Tocantins',
            uf: 'TO',
            color: '#ACC995',
            bgColor: '#7AA77F',
          },
          id: 'TO',
          arcs: [[-22, -35, -55, -57, -44]],
        },
      ],
    },
  },
  arcs: [
    [
      [44, 6830],
      [888, -264],
      [899, -430],
    ],
    [
      [1831, 6136],
      [53, -21],
    ],
    [
      [1884, 6115],
      [-496, -311],
      [-531, 28],
      [4, 388],
      [-163, -136],
      [-231, 11],
      [-54, 142],
      [-214, 6],
      [78, 87],
      [-155, 194],
      [-60, 108],
      [-1, 33],
      [-60, 45],
      [-1, 64],
      [49, 0],
      [-5, 56],
    ],
    [
      [9922, 6376],
      [-59, -94],
      [-100, -112],
      [-79, -103],
      [-63, -55],
      [1, -12],
      [-26, -38],
    ],
    [
      [9596, 5962],
      [-14, 24],
      [-29, -3],
      [-1, 23],
      [-18, 18],
      [-15, -8],
      [-64, 49],
      [-2, 22],
      [-265, 130],
    ],
    [
      [9188, 6217],
      [-59, 46],
    ],
    [
      [9129, 6263],
      [111, 120],
      [215, -137],
      [14, 29],
      [52, -1],
      [19, -19],
      [167, 129],
      [65, -17],
      [46, 25],
      [104, -16],
    ],
    [
      [2703, 9217],
      [168, -91],
      [120, -557],
      [-61, -114],
      [260, -204],
      [-27, 191],
      [108, 89],
      [122, -104],
      [101, 52],
      [-29, 45],
      [90, 192],
      [294, 7],
    ],
    [
      [3849, 8723],
      [7, -174],
      [141, -189],
      [502, -284],
      [-524, -1167],
      [62, -132],
    ],
    [
      [4037, 6777],
      [-63, -376],
      [-799, -6],
    ],
    [
      [3175, 6395],
      [-154, 8],
      [-180, 203],
      [-183, -1],
      [-181, -239],
      [-160, -3],
      [2, -131],
      [-360, 5],
      [-128, -101],
    ],
    [
      [44, 6830],
      [27, 97],
      [141, 67],
      [-33, 110],
      [118, 239],
      [340, 179],
      [388, 28],
      [140, 845],
      [-157, 212],
      [-4, 196],
      [228, 5],
      [-1, 115],
      [-175, -2],
      [0, 173],
      [416, 3],
      [191, 112],
      [91, -88],
      [5, -169],
      [395, -133],
      [549, 398],
    ],
    [
      [6087, 8820],
      [-403, -354],
      [13, -101],
      [-173, -13],
      [-324, 622],
      [-188, 125],
      [-105, 0],
      [-34, 187],
    ],
    [
      [4873, 9286],
      [56, 3],
      [56, -74],
      [101, -6],
      [60, 48],
      [124, -3],
      [26, -40],
      [92, 0],
      [383, 582],
      [147, -550],
      [241, -178],
      [-6, -154],
      [-66, -94],
    ],
    [
      [9188, 6217],
      [4, -118],
      [41, -7],
      [23, -89],
      [-27, -12],
      [0, -83],
      [-46, -14],
      [-8, 15],
      [-39, -2],
      [-17, -28],
      [66, -136],
      [138, -69],
    ],
    [
      [9323, 5674],
      [-231, -360],
      [-50, 33],
      [-95, -89],
      [-30, -389],
      [51, -283],
      [-113, -360],
      [41, -104],
      [-34, -52],
      [-61, -27],
      [-40, -91],
    ],
    [
      [8761, 3952],
      [-156, 102],
    ],
    [
      [8605, 4054],
      [20, 51],
      [-108, 94],
      [32, 122],
      [59, 9],
      [78, 221],
    ],
    [
      [8686, 4551],
      [-1086, 452],
      [-466, -253],
    ],
    [
      [7134, 4750],
      [3, 94],
    ],
    [
      [7137, 4844],
      [-57, 487],
    ],
    [
      [7080, 5331],
      [-81, 420],
      [192, 273],
    ],
    [
      [7191, 6024],
      [145, -146],
      [284, 48],
      [119, 154],
      [-47, 124],
      [94, 73],
      [184, -90],
      [134, 93],
      [92, 0],
      [159, 154],
    ],
    [
      [8355, 6434],
      [133, -126],
      [-2, -70],
      [89, -2],
      [251, 232],
      [190, -118],
      [36, 34],
      [59, -48],
      [-17, -30],
      [35, -43],
    ],
    [
      [9365, 7418],
      [-85, -34],
      [-239, -361],
    ],
    [
      [9041, 7023],
      [-50, -152],
      [58, -87],
      [-44, -118],
    ],
    [
      [9005, 6666],
      [-107, 0],
      [-142, 107],
      [-220, -16],
    ],
    [
      [8536, 6757],
      [40, 160],
      [-78, 32],
      [-88, 447],
      [-72, 510],
    ],
    [
      [8338, 7906],
      [-128, 50],
      [128, -50],
    ],
    [
      [8338, 7906],
      [333, 19],
      [395, -220],
      [147, -163],
      [151, -84],
      [1, -40],
    ],
    [
      [6809, 4538],
      [-247, 0],
      [-1, 139],
      [248, -1],
      [0, -138],
    ],
    [
      [8439, 3189],
      [-202, 48],
      [1, 74],
      [-44, 12],
    ],
    [
      [8194, 3323],
      [25, 149],
      [102, 4],
      [125, 237],
      [-41, 54],
      [31, 73],
      [-67, 107],
      [59, 53],
      [51, -2],
      [40, 55],
      [86, 1],
    ],
    [
      [8761, 3952],
      [-35, -114],
      [28, -142],
      [-28, -77],
      [-70, -49],
      [-38, -113],
      [-86, -149],
      [-55, -3],
      [-38, -116],
    ],
    [
      [5974, 5372],
      [319, -97],
      [787, 56],
    ],
    [
      [7137, 4844],
      [-208, -33],
      [8, -228],
      [-128, -45],
    ],
    [
      [6809, 4538],
      [0, 138],
      [-248, 1],
      [1, -139],
      [247, 0],
    ],
    [
      [6809, 4538],
      [7, -515],
      [-149, -121],
      [-100, 48],
      [-194, -2],
      [-98, -84],
      [-232, -6],
      [-161, -198],
    ],
    [
      [5882, 3660],
      [-484, 217],
      [-63, 162],
    ],
    [
      [5335, 4039],
      [-42, 92],
      [61, 205],
      [189, 243],
      [111, 18],
      [56, 192],
      [132, 22],
      [132, 561],
    ],
    [
      [6494, 7329],
      [254, 158],
      [170, 251],
      [207, 649],
    ],
    [
      [7125, 8387],
      [333, -125],
      [88, -218],
      [107, -36],
      [117, 75],
      [266, -131],
      [175, 2],
    ],
    [
      [8211, 7954],
      [-298, -387],
      [17, -624],
      [-142, -57],
      [-38, 63],
      [-118, -43],
      [-124, -131],
      [-225, -89],
      [-129, -304],
      [37, -358],
    ],
    [
      [7191, 6024],
      [-148, 36],
      [-48, 105],
      [-118, 179],
      [62, 164],
      [82, 13],
      [-14, 113],
      [-130, -30],
      [-161, 194],
      [78, 250],
      [-34, 200],
      [-266, 81],
    ],
    [
      [8194, 3323],
      [-19, -45],
      [-34, 1],
      [-68, -172],
      [14, -23],
      [-199, -95],
      [-67, 29],
      [-358, -110],
    ],
    [
      [7463, 2908],
      [-407, -128],
      [-283, 748],
      [-898, 17],
    ],
    [
      [5875, 3545],
      [7, 115],
    ],
    [
      [7134, 4750],
      [467, 250],
      [1085, -449],
    ],
    [
      [5875, 3545],
      [-380, -578],
      [-163, -105],
    ],
    [
      [5332, 2862],
      [-126, -89],
      [-93, -129],
      [-75, -154],
    ],
    [
      [5038, 2490],
      [-113, 35],
      [-168, -25],
      [-124, 443],
      [-541, 16],
      [7, 318],
      [-83, 259],
      [199, 521],
    ],
    [
      [4215, 4057],
      [199, 152],
      [186, 44],
      [219, -125],
      [255, 6],
      [107, 112],
      [-1, -117],
      [-96, -91],
      [251, 1],
    ],
    [
      [3397, 5141],
      [213, 358],
      [2, 332],
      [-439, 5],
      [2, 559],
    ],
    [
      [4037, 6777],
      [360, -535],
      [1668, -112],
    ],
    [
      [6065, 6130],
      [-131, -409],
      [40, -349],
    ],
    [
      [4215, 4057],
      [-217, 169],
      [0, 257],
      [-473, -1],
      [-3, 196],
      [-100, 112],
      [84, -4],
      [-5, 124],
      [-33, 149],
      [-71, 82],
    ],
    [
      [6494, 7329],
      [-53, -48],
      [143, -59],
      [-139, -293],
      [-76, -20],
      [-89, -202],
      [56, -59],
      [-271, -518],
    ],
    [
      [3849, 8723],
      [6, 259],
    ],
    [
      [3855, 8982],
      [444, 162],
      [287, 10],
      [-11, 134],
      [298, -2],
    ],
    [
      [6087, 8820],
      [40, -84],
      [96, 8],
      [298, -157],
      [5, -63],
      [120, -42],
      [77, 60],
      [402, -155],
    ],
    [
      [9960, 6990],
      [39, -272],
    ],
    [
      [9999, 6718],
      [-63, 39],
      [-105, -17],
      [-15, -49],
      [-119, -45],
      [-62, 10],
      [-87, -35],
      [-7, -55],
      [-97, -44],
      [-84, 97],
      [92, 122],
      [-80, 49],
      [-177, -118],
      [-190, -6],
    ],
    [
      [9041, 7023],
      [118, -34],
      [216, 129],
      [24, -39],
      [-79, -138],
      [198, -78],
      [83, 131],
      [359, -4],
    ],
    [
      [9999, 6718],
      [-77, -342],
    ],
    [
      [8355, 6434],
      [181, 152],
      [-1, 62],
      [-43, 16],
      [1, 75],
      [43, 18],
    ],
    [
      [8211, 7954],
      [127, -48],
    ],
    [
      [5332, 2862],
      [851, -121],
      [143, -388],
      [301, -170],
    ],
    [
      [6627, 2183],
      [-147, -192],
    ],
    [
      [6480, 1991],
      [-146, 0],
      [-90, -58],
      [-97, 48],
      [-174, -2],
      [-35, -51],
      [-138, -19],
      [-30, -85],
      [-577, 95],
    ],
    [
      [5193, 1919],
      [-79, 159],
      [-163, 12],
      [87, 400],
    ],
    [
      [7465, 2663],
      [-31, 91],
      [163, 59],
      [-21, 50],
      [-69, -22],
      [-44, 67],
    ],
    [
      [8439, 3189],
      [-36, -55],
      [23, -120],
      [-63, -36],
      [-85, -28],
      [-110, -92],
      [30, -33],
      [-40, -56],
      [-254, -6],
      [-143, -22],
      [-67, 34],
      [-193, -67],
      [11, -31],
      [-47, -14],
    ],
    [
      [9365, 7418],
      [161, -68],
      [182, 9],
      [152, -44],
      [100, -325],
    ],
    [
      [3397, 5141],
      [-92, 49],
      [-212, -8],
      [-79, 90],
      [-419, 197],
      [-101, -39],
      [-281, 219],
      [1, 464],
      [-330, 2],
    ],
    [
      [2703, 9217],
      [-4, 55],
      [-149, -1],
      [-57, 298],
      [-163, 167],
      [23, 19],
      [49, -43],
      [117, -7],
      [33, -50],
      [204, 9],
      [51, -85],
      [69, 20],
      [-29, 72],
      [127, 52],
      [73, -19],
      [338, 175],
      [-3, 116],
      [183, 4],
      [6, -185],
      [93, -36],
      [4, -150],
      [-90, -206],
      [5, -163],
      [51, -18],
      [1, -108],
      [129, -130],
      [91, -21],
    ],
    [
      [5143, 1686],
      [528, -63],
      [306, -244],
      [184, -48],
      [-45, -133],
      [68, -60],
    ],
    [
      [6184, 1138],
      [-135, -303],
      [-133, -178],
      [-99, -87],
      [-222, -158],
      [-1, 77],
      [49, 0],
      [120, 85],
      [185, 255],
      [-129, 25],
      [-123, -212],
      [-152, -152],
      [40, -23],
      [0, -60],
      [-59, -68],
      [-52, -150],
      [-56, -69],
      [-157, -120],
      [-40, 34],
      [30, 90],
      [102, 123],
      [55, -33],
      [53, 110],
      [-45, 29],
      [-109, -69],
      [-594, 445],
      [-114, -51],
      [-363, 270],
      [448, 491],
      [460, 247],
    ],
    [
      [6480, 1991],
      [30, -65],
      [-49, -122],
      [25, -167],
      [-2, -126],
      [-41, -168],
      [-259, -205],
    ],
    [
      [5143, 1686],
      [50, 233],
    ],
    [
      [9596, 5962],
      [-7, -11],
      [-28, 0],
      [-96, -66],
      [-142, -211],
    ],
    [
      [7465, 2663],
      [-175, -74],
      [14, -25],
      [-36, -24],
      [-59, 19],
      [-73, -11],
      [-207, -105],
      [-302, -260],
    ],
  ],
  transform: {
    scale: [0.003916405286275154, 0.0038999519486388173],
    translate: [-73.97741220933203, -33.74579986956992],
  },
};
