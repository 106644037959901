import styled from 'styled-components';

import theme from 'v3/theme';

export const SaveContainer = styled.div`
  background-color: ${theme.colors.dark_blue};
  z-index: 998;
  position: fixed;
  top: 50px;
  padding: 15px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  > button:first-of-type {
    margin: 0 5px;
  }
`;
