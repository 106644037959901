import React, { useCallback } from 'react';

function Stepper({ currentStep, steps }) {
  const renderCurrentStep = useCallback(() => {
    const step = steps.find(item => item.step === currentStep) || {};

    return step.component || null;
  });

  return <>{renderCurrentStep()}</>;
}

export default Stepper;
