import React, { useContext, useEffect, useState } from 'react';
import Text from 'v3/components/Text';
import { UserContext } from 'contexts/UserContext';
import { useParams, useLocation } from 'react-router-dom';
import { cooperplaceApi } from 'services/api';
import { TransparentSelect } from './style';

function Company() {
  const params = useParams();
  const location = useLocation();
  const {
    setEnabledToSelect,
    enabledToSelect,
    companies,
    selectedCompany,
    setSelectedCompany,
    setChangedCompany,
  } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const PAGES_DISABLED_TO_SWAP_COMPANY = [
    '/cargas/cadastrar',
    '/viagens/cadastrar',
  ];

  const handleChangeCompany = async value => {
    setIsLoading(true);
    try {
      const {
        data: { token },
      } = await cooperplaceApi.put(`/user/company/${value.id}`);
      localStorage.setItem('token', token);
      setSelectedCompany(value);
      setChangedCompany(true);
    } catch (error) {
      // error handler
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const isDisabledPage = PAGES_DISABLED_TO_SWAP_COMPANY.includes(
      location?.pathname
    );
    setEnabledToSelect(!params?.loadId && !params?.id && !isDisabledPage);
  }, [params]);

  return enabledToSelect ? (
    <TransparentSelect
      disableClear
      options={companies}
      getOptionLabel={option => option.name}
      getOptionValue={option => option.id}
      value={selectedCompany}
      onChange={handleChangeCompany}
      loading={isLoading}
      menuFontColor="white"
    />
  ) : selectedCompany?.name ? (
    <Text
      type="medium"
      style={{ position: 'relative', padding: '0.5rem' }}
      fontSize="13px"
      color="white"
    >
      {selectedCompany.name.toUpperCase()}
    </Text>
  ) : null;
}

export default Company;
