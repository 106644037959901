import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { Grid } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from 'components/Button';
import Snackbar from 'components/Snackbar';
import InfiniteScroll from 'components/InfiniteScroll';
import InfoMessage from 'components/InfoMessage';
import Card from 'components/Card';
import { validatePermission } from 'actions/index';
import { downloadCSV } from 'functions';
import api from 'services/api';
import ClientSelector from '../relatorios/client-selector/index';
import CardViagem from './CardViagem';
import { usePermission } from "hooks";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

let token = 0;
function ReportComPassos() {
  const query = useQuery();
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState('');
  const [total, setTotal] = useState('');
  const [completed, setCompleted] = useState(0);
  const [loading, setLoading] = useState(false);
  const [snackBar, setSnackBar] = useState({
    display: false,
    type: 'error',
    message: '',
  });
  const hasPermissionToAcess = usePermission('VIAGEM_COM_PASSOS');

  useEffect(() => {
    if (!hasPermissionToAcess) window.location.href = '/';
  }, []);

  function formatDate(date) {
    if (date === 'Invalid date') {
      return 'Não ocorreu';
    }
    return date;
  }

  useEffect(() => {
    if (data.length > 0 && !loading) {
      processDataCSV(data);
    }
  }, [loading, data]);

  async function loadData(clients, startDate, endDate, company_id) {
    const queryParams = new URLSearchParams();

    queryParams.set('companies', clients);
    queryParams.set('start_date', startDate);
    queryParams.set('end_date', endDate);
    queryParams.set('company_id', company_id);
    queryParams.set('token', token++);

    setFilter(queryParams.toString());
  }

  async function nextPage(page = 1) {
    const start_date = query.get('start_date') || new Date();
    const end_date = query.get('end_date') || new Date();

    const response = await api.get('report-attended-with-steps', {
      params: {
        companies: query.get('companies'),
        company_id: query.get('company_id'),
        page,
        start_date,
        end_date,
      },
    });

    if (response.status === 200) {
      setData(oldData => [...oldData, ...response.data.data]);
      setCompleted((response.data.page / response.data.lastPage) * 100);
    }
  }

  async function loadExportData(page = 1) {
    setCompleted(0);

    setData([]);

    if (
      query.get('companies') === undefined ||
      query.get('companies') === null ||
      !query.get('companies')
    ) {
      setSnackBar({
        display: true,
        type: 'error',
        message: 'Selecione um cliente',
      });
      setLoading(false);
      return;
    }

    const start_date = query.get('start_date') || new Date();
    const end_date = query.get('end_date') || new Date();

    const response = await api.get('report-attended-with-steps', {
      params: {
        companies: query.get('companies'),
        company_id: query.get('company_id'),
        page,
        start_date,
        end_date,
      },
    });

    if (response.status === 200) {
      if (response.data.total === 0) {
        setSnackBar({
          display: true,
          type: 'error',
          message: 'Sem resultados para o período selecionado',
        });

        return;
      }

      setLoading(true);
      const ids = Array.from(
        { length: response.data.lastPage },
        (v, k) => k + 1
      );
      await ids.reduce((p, x) => p.then(_ => nextPage(x)), Promise.resolve());
      setLoading(false);
    }
  }

  function processDataCSV(dataLoaded = []) {
    let csvData = [];

    const csv = dataLoaded.map(el => {
      const steps = {};
      el.logs.forEach(log => {
        const status = log.obs ? log.obs.split(' ') : false;
        if (status) {
          const step = status[status.length - 1]
            .replace('.', '')
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase();

          steps[step] = {
            date: log.created_at,
            user: log.userCreated.username,
            user_type: log.userCreated.profile.name,
          };
        }
      });

      el.steps = steps;

      return {
        id: el.id,
        'Número da viagem': el.travel_number || '',
        Transportadora: (el.shipper && el.shipper.social_name) || '',
        Tomador: el.load.client.social_name || '',
        CNPJ: el.load.client.cgccpf || '',
        Motorista: el.driver.social_name || '',
        'Ordem de coleta': el.created_at || '',
        Carregamento: el.steps.carregamento
          ? formatDate(
              moment(el.steps.carregamento.date).format('DD/MM/YYYY HH:mm')
            )
          : '',
        'Usuário (carregamento)': el.steps.carregamento
          ? `${el.steps.carregamento.user} (${el.steps.carregamento.user_type})`
          : '',
        Emissão: el.steps.emissao
          ? formatDate(moment(el.steps.emissao.date).format('DD/MM/YYYY HH:mm'))
          : '',
        'Usuário (emissão)': el.steps.emissao
          ? `${el.steps.emissao.user} (${el.steps.emissao.user_type})`
          : '',
        Descarregamento: el.steps.descarregamento
          ? formatDate(
              moment(el.steps.descarregamento.date).format('DD/MM/YYYY HH:mm')
            )
          : '',
        'Usuário (descarregamento)': el.steps.descarregamento
          ? `${el.steps.descarregamento.user} (${el.steps.descarregamento.user_type})`
          : '',
        'Em análise': el.steps.analise
          ? formatDate(moment(el.steps.analise.date).format('DD/MM/YYYY HH:mm'))
          : '',
        'Usuário (análise)': el.steps.analise
          ? `${el.steps.analise.user} (${el.steps.analise.user_type})`
          : '',
        Cancelado: el.steps.cancelado
          ? formatDate(
              moment(el.steps.cancelado.date).format('DD/MM/YYYY HH:mm')
            )
          : '',
        'Usuário (cancelado)': el.steps.cancelado
          ? `${el.steps.cancelado.user} (${el.steps.cancelado.user_type})`
          : '',
        'Documentos enviados': el.att_created
          ? `${formatDate(
              moment(el.att_created).format('DD/MM/YYYY HH:mm')
            )} (${el.att_user})`
          : 'Não',
      };
    });

    csvData = csv.map(el => {
      return Object.values(el);
    });

    const header =
      'id viagem;Número da viagem;Transportadora;Cliente;CNPJ;Motorista;Ordem de coleta;Carregamento;Usuário (Carregamento);Emissão;Usuário (Emissão);Descarregamento;Usuário (descarregamento);Em análise; Usuário(análise);Cancelado; Usuário(cancelado);Documentos enviados';
    downloadCSV(
      header,
      csvData,
      `viagens-com-passos${moment().format('DD-MM-YYYY')}.csv`,
      'text/csv;encoding:utf-8'
    );
  }

  return (
    <>
      <Snackbar
        open={snackBar.display}
        type={snackBar.type}
        message={snackBar.message}
        onClose={() => setSnackBar(false)}
      />
      <Dialog
        open={loading}
        maxWidth="sm"
        fullWidth
        minWidth="sm"
        aria-labelledby="Relatório de viagens"
      >
        <DialogTitle>Relatório</DialogTitle>
        <DialogContent>
          Gerando relatório
          <LinearProgress
            variant="determinate"
            value={completed}
            color="primary"
          />
          <br />
        </DialogContent>
      </Dialog>
      <Card style={{ marginBottom: '10px', overflow: 'visible' }}>
        <Grid container spacing={2}>
          <ClientSelector
            onClientSelected={item => {}}
            onListReports={loadData}
            url="/relatorios/viagem-com-passos"
            pathname="viagem-com-passos"
          />
        </Grid>

        <Grid item xs={10}>
          {total > 0 && [
            <Typography
              variant="subtitle1"
              style={{ fontSize: '18px', marginBottom: '10px' }}
            >
              Número de viagens{' '}
              <strong style={{ color: '#1C4B75' }}>{total}</strong>
            </Typography>,
          ]}
        </Grid>
      </Card>

      <div
        aria-label="Relatório de cargas atendidas"
        style={{ height: '60vh' }}
      >
        <Card style={{ marginBottom: '10px' }}>
          {validatePermission('EXPORTAR_RELATORIO_COM_PASSOS') && (
            <Button
              style={{ marginTop: '25px' }}
              disabled={loading}
              onClick={() => loadExportData()}
            >
              Exportar
            </Button>
          )}
        </Card>
        <InfiniteScroll
          endpoint="report-attended-with-steps"
          initialFetch={false}
          onDataFetched={data => {
            setTotal(data.pagination.total);
          }}
          filter={filter}
          placeholderHeight={145}
          renderItem={travel => <CardViagem travel={travel} />}
          endMessage={
            <>
              {total === 0 && (
                <Grid
                  container
                  item
                  xs={12}
                  justify="center"
                  style={{ marginTop: '20px' }}
                >
                  <InfoMessage
                    icon="arrow-right"
                    width="auto"
                    snackColor="#8c8c8c"
                    message="A busca não retornou nenhum resultado, revise os filtros"
                  />
                </Grid>
              )}

              {total === undefined && (
                <Grid
                  container
                  item
                  xs={12}
                  justify="center"
                  style={{ marginTop: '20px' }}
                >
                  <InfoMessage
                    icon="arrow-right"
                    width="auto"
                    snackColor="#8c8c8c"
                    message="Preencha os filtros para visualizar o relatório"
                  />
                </Grid>
              )}
            </>
          }
        />

        {total > 0 && (
          <Grid
            container
            item
            xs={12}
            justify="center"
            style={{ marginTop: '20px' }}
          >
            <InfoMessage
              icon="arrow-right"
              width="auto"
              snackColor="#8c8c8c"
              message="Sem mais itens para exibição."
            />
          </Grid>
        )}
      </div>
    </>
  );
}

export default ReportComPassos;
