/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
import React, { useEffect, useMemo, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { Row, Col } from 'react-bootstrap';
import api from 'services/api';
import { Modal, Button, Text, Input, Select, Radio } from 'v3/components';
import { useSnackbar } from 'v3/components/Snackbar';

import { CPF_MASK, BR_PHONE_MASK } from 'utils/masks';
import { updateDriverSchema } from '../../validator';
import { formatDateTime } from 'v3/utils/formatter';
import StringBuilder from 'utils/StringBuilder';
import PDFView from 'v3/components/PDFView';
import { Backdrop, DateTime, StyledFilesCol } from './styles';
import { FaDownload, FaSearch } from 'react-icons/fa';
import { Attachment } from 'v3/components/Attachment';

function UpdateDriver({ isOpen, onClose, onSubmit, driver, setDriver }) {
  const snackbar = useSnackbar();
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(null);
  const [errors, setErrors] = useState({});
  const hasDocumentsData =
    driver?.documentsData?.cnhFront &&
    Object.keys(driver?.documentsData?.cnhFront)?.length > 0 &&
    driver?.documentsData?.selfie &&
    Object.keys(driver?.documentsData?.selfie)?.length > 0;

  async function fetchPaymentMethods(search) {
    try {
      const response = await api.get('payment/methods', { params: { search } });

      setPaymentMethods(response.data.data);
    } catch (ex) {
      //
    }
  }

  async function verifySchema() {
    try {
      await updateDriverSchema.validate(
        {
          social_name: driver.social_name,
          cpf_unmasked: driver.cgccpf.replace(/\D/g, '').trim(),
          phone_cooperplace: driver.phone_cooperplace,
          payment_method: driver.payment_method?.id,
        },
        { abortEarly: false }
      );
    } catch (error) {
      const errorResponse = {};

      if (error.inner) {
        error.inner.forEach(error => {
          errorResponse[error.path] = error.message;
        });
        setErrors(errorResponse);
      }
    }
  }

  useEffect(() => {
    if (driver.social_name) {
      verifySchema();
    }
  }, [driver]);

  async function handleSubmit() {
    try {
      setLoading(true);
      const data = {
        id: driver.id,
        social_name: driver.social_name,
        cpf_unmasked: driver.cgccpf.replace(/\D/g, '').trim(),
        phone_cooperplace: driver.phone_cooperplace,
        payment_method: driver.payment_method?.id,
      };
      await updateDriverSchema.validate(data, { abortEarly: false });
      const response = await api.put('v3/travel/driver', {
        driver: { ...data, cgccpf: driver.cgccpf, validated: validated },
      });

      onSubmit(response.data);

      snackbar.show(<Text>Motorista cadastrado com sucesso.</Text>, {
        type: 'success',
      });

      setLoading(false);
      setErrors();
      onClose();
    } catch (error) {
      setLoading(false);
      const errorResponse = {};

      if (error.inner) {
        error.inner.forEach(error => {
          errorResponse[error.path] = error.message;
        });
        setErrors(errorResponse);
        return;
      }

      snackbar.show(<Text>{error.response.data[0].message || 'a'}</Text>, {
        type: 'error',
      });
      return [];
    }
  }

  useEffect(() => {
    if(isOpen)
    fetchPaymentMethods();
  }, [isOpen]);

  return (
    <>
      <Modal
        show={isOpen}
        onHide={onClose}
        heading={
          <Text type="header" color="dark" weight="500">
            Atualizar dados do motorista
          </Text>
        }
        body={
          <>
            <Row className="mx-0 mt-1">
              <Col md={12} className="mb-3">
                <Input
                  label="Nome *"
                  value={driver?.social_name}
                  onChange={({ target }) =>
                    setDriver({ ...driver, social_name: target.value })
                  }
                  error={errors?.social_name}
                />
              </Col>

              <Col md={6} xs={12} className="mb-3">
                <Input
                  value={driver?.cgccpf}
                  onChange={({ target }) =>
                    setDriver({ ...driver, cgccpf: target.value })
                  }
                  label="CPF *"
                  masked
                  mask={CPF_MASK}
                  error={errors?.cpf_unmasked}
                />
              </Col>

              <Col xs={12} md={6} className="mb-3">
                <Input
                  label="Telefone(cooperplace) *"
                  value={driver?.phone_cooperplace}
                  onChange={({ target }) =>
                    setDriver({ ...driver, phone_cooperplace: target.value })
                  }
                  masked
                  mask={BR_PHONE_MASK}
                  error={errors?.phone_cooperplace}
                  guide={false}
                />
              </Col>

              <Col md={12} className="mb-3">
                <Select
                  label="Método de pagamento *"
                  onSearch={fetchPaymentMethods}
                  value={driver?.payment_method}
                  options={paymentMethods}
                  onChange={target => {
                    setDriver({ ...driver, payment_method: target });
                  }}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  error={errors?.payment_method}
                />
              </Col>
              {hasDocumentsData ? (
                <>
                  <StyledFilesCol>
                    <Text type="label" color="dark" weight={500}>
                      Documentos Motorista
                    </Text>
                    <div>
                      {driver?.documentsData?.cnhFront?.full_path.indexOf('pdf') > 0 ? (
                      <Attachment
                      attachment={{
                        id: driver?.documentsData?.cnhFront?.id,
                        full_path: driver?.documentsData?.cnhFront?.full_path,
                        due_date: driver?.documentsData?.cnhFront?.created_at,
                      }}
                      attachments={[driver.frontCnh]}
                      loading={false}
                      />
                      ) : <DriverDocumentsAttachment
                      file={[driver?.documentsData?.cnhFront]}/>
                      }

                      <DriverDocumentsAttachment
                        file={[driver?.documentsData?.selfie]}
                      />
                    </div>
                  </StyledFilesCol>
                  <Col xs={12}>
                    <Radio.Group
                      label={'Validar documentos do Motorista'}
                      onChange={({ target }) => {
                        setValidated(target.value);
                      }}
                      value={validated}
                      horizontal
                    >
                      <Radio value>
                        <Text color="dark" type="label">
                          Validar
                        </Text>
                      </Radio>
                      <Radio value={false}>
                        <Text color="dark" type="label">
                          Recusar
                        </Text>
                      </Radio>
                    </Radio.Group>
                  </Col>
                </>
              ) : (
                <Col className="my-2">
                  <Text type="label" color="dark" weight={500}>
                    Documentos Motorista
                  </Text>
                  <Text type="regular" as="p">
                    Motorista não realizou validação de perfil.
                  </Text>
                </Col>
              )}
            </Row>
            <Col
              md={12}
              className="mb-3 d-flex align-items-center justify-content-center"
            >
              <Button
                className="mt-1 py-2"
                onClick={() => handleSubmit()}
                loading={loading}
              >
                <Text weight="500" type="regular">
                  Salvar
                </Text>
              </Button>
            </Col>
          </>
        }
      />
    </>
  );
}

export default UpdateDriver;

function buildFullPath(attachmentData) {
  let path = '';
  if (attachmentData?.container?.toLowerCase() === 'stcoopertech') {
    path = `${process.env.REACT_APP_BASE_STORAGE_URL}coopertech/${attachmentData?.path}/${attachmentData?.name}`;
  } else {
    path = attachmentData?.full_path;
  }
  return path;
}

export function DriverDocumentsAttachment({ file }) {
  let fullPathAttachment = buildFullPath(file[0]);
  let attachment = { ...file[0], full_path: fullPathAttachment };
  const customReactModalStyle = {
    overlay: {
      zIndex: 2000,
    },
  };
  const [isHovering, setHovering] = useState(false);
  const [isShowingModal, setShowingModal] = useState(false);

  const attachmentsWithoutPDF = useMemo(() => {
    if (attachment)
      return file?.filter(item => {
        if (item.name) {
          const filterPDF = item?.name?.split('.');
          if (filterPDF[filterPDF?.length - 1] === 'pdf') return null;
          return item;
        }
      });
    return [];
  }, [attachment]);

  const [lightbox, setLightbox] = useState({
    isOpen: false,
    attachments: attachmentsWithoutPDF,
    index: 0,
  });

  const lightboxIndexes = useMemo(() => {
    const next = (lightbox.index + 1) % lightbox.attachments?.length;
    const previous =
      (lightbox.index + lightbox.attachments.length - 1) %
      lightbox.attachments.length;
    return { next, previous };
  }, [lightbox.index, lightbox.attachments]);

  function onClickPreview(id) {
    const index = id ? attachmentsWithoutPDF.findIndex(a => a.id === id) : 0;

    setLightbox(prev => ({
      ...prev,
      index,
      attachments: attachmentsWithoutPDF,
      isOpen: true,
    }));
  }

  function handleClick() {
    const fullPathArray = attachment?.name?.split('.');

    if (
      fullPathArray?.length &&
      fullPathArray[fullPathArray?.length - 1] === 'pdf'
    ) {
      setShowingModal(true);
    } else {
      onClickPreview(attachment?.id);
    }
  }

  function getImagePath(index, alwaysDisplay) {
    const isSameAsIndex =
      lightbox.index === lightboxIndexes.next &&
      lightbox.index === lightboxIndexes.previous;

    if (!alwaysDisplay && isSameAsIndex && lightbox.attachments.length === 1) {
      return null;
    }

    return lightbox.attachments[index]?.full_path;
  }

  function getDocumentMetadata() {
    const document = lightbox.attachments[lightbox.index];
    const type = document?.description || 'Sem descrição';
    const createdAt = formatDateTime(document?.createdAt);

    return { type, createdAt };
  }

  const lightboxTitle = useMemo(() => {
    const builder = new StringBuilder(' ');
    const documentMetadata = getDocumentMetadata();
    builder
      .append(`${lightbox.index + 1}/${lightbox.attachments?.length}`)
      .append('|')
      .append(documentMetadata.type)
      .append(`(${documentMetadata.createdAt})`);

    return builder.build();
  }, [lightbox.index, lightbox.attachments]);

  return (
    <div>
      <Modal
        show={isShowingModal}
        onHide={() => setShowingModal(false)}
        heading="Visualizar PDF"
        body={<PDFView file={attachment[0]?.full_path} />}
      />
      <div
        className="position-relative"
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        style={{
          width: 240,
          height: 120,
          background: `url(${attachment?.full_path}) center center no-repeat`,
          backgroundSize: 'cover',
        }}
      >
        {lightbox.isOpen && (
          <Lightbox
            mainSrc={getImagePath(lightbox.index, true)}
            nextSrc={getImagePath(lightboxIndexes.next)}
            prevSrc={getImagePath(lightboxIndexes.previous)}
            onCloseRequest={() =>
              setLightbox(prev => ({ ...prev, isOpen: false }))
            }
            onMovePrevRequest={() =>
              setLightbox(prev => ({
                ...prev,
                index: lightboxIndexes.previous,
              }))
            }
            onMoveNextRequest={() =>
              setLightbox(prev => ({
                ...prev,
                index: lightboxIndexes.next,
              }))
            }
            imageTitle={lightboxTitle}
            reactModalStyle={customReactModalStyle}
          />
        )}
        {isHovering && (
          <Backdrop>
            <button type="button" onClick={handleClick}>
              <FaSearch color="#fff" size={32} />
            </button>

            <a
              href={attachment?.full_path}
              rel="noopener noreferrer"
              target="_blank"
              download
            >
              <FaDownload color="#fff" size={32} />
            </a>
          </Backdrop>
        )}

        <DateTime>
          <Text type="label" color="white">
            {formatDateTime(attachment?.created_at)}
          </Text>
        </DateTime>
      </div>
    </div>
  );
}
