import React from 'react';

import Space from 'v3/components/Space';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import { useRasterBranches } from '../context';

export default function Editable({ isEditing, setEditing, onSave, isLoading }) {
  const rasterBranches = useRasterBranches();

  function handleCancel() {
    setEditing(false);
    rasterBranches.setData(rasterBranches.prevBranch);
  }

  if (isEditing) {
    return (
      <Space x="5px">
        <Button variant="secondary" onClick={handleCancel} className="py-2">
          <Text type="regular" color="dark" weight="500">
            Cancelar
          </Text>
        </Button>
        <Button
          variant="primary"
          onClick={onSave}
          loading={isLoading}
          className="py-2"
        >
          <Text type="regular" color="white" weight="500">
            Salvar
          </Text>
        </Button>
      </Space>
    );
  }

  return (
    <>
      <Button
        variant="secondary"
        onClick={() => setEditing(true)}
        className="py-2"
      >
        <Text color="dark" weight="500" type="regular">
          Editar
        </Text>
      </Button>
    </>
  );
}
