/* eslint-disable import/no-unresolved */
import React, { useEffect, useMemo, useRef, useState } from 'react';

import Card from 'v3/components/Card';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import IconButton from 'v3/components/Button/Icon';
import Map from 'v3/components/Map';
import Marker from 'v3/components/Map/MarkerLetter';

import useModal from 'hooks/useModal';
import theme from 'v3/theme';
import { formatDateTime, formatAddress } from 'v3/utils/formatter';

import { TravelContext } from '../controller';
import Modal from './Modal';
import { DESTINATION_ACTIONS } from './controller';
import * as Styled from './styles';

const [, useTravel] = TravelContext;

export default function LocaleData() {
  const travel = useTravel();
  const modal = useModal();

  const [locationType, setLocationType] = useState('origin');
  const [updateData, setUpdateData] = useState();
  const [updateIndex, setUpdateIndex] = useState();

  const refs = useRef({ map: null, maps: null });

  const hasOrigin = !!travel.data.origin;
  const isEmpty = !hasOrigin && !travel.data.destinations.length;

  const addButtonText = hasOrigin ? 'destino' : 'origem';

  const locations = useMemo(
    () =>
      [travel.data.origin, ...travel.data.destinations].filter(item => !!item),
    [travel.data.origin, travel.data.destinations]
  );

  function editLocation(data, type, index) {
    setUpdateData(data);
    setLocationType(type);
    setUpdateIndex(index);

    modal.open();
  }

  function removeOrigin() {
    travel.setData(prev => ({ ...prev, origin: null }));
  }

  function removeDestination(index) {
    travel.setData(prev => ({
      ...prev,
      destinations: prev.destinations.filter((_, i) => i !== index),
    }));
  }

  function addLocation() {
    setUpdateIndex(null);
    setUpdateData(null);
    setLocationType(hasOrigin ? 'destinations' : 'origin');

    modal.open();
  }
  function getMapBounds() {
    const { maps } = refs.current;
    if (maps) {
      const bounds = new maps.LatLngBounds();
      locations.forEach(location => {
        bounds.extend(new maps.LatLng(location.lat, location.lng));
      });

      return bounds;
    }

    return null;
  }

  useEffect(() => {
    const bounds = getMapBounds();
    if (refs.current.map) {
      refs.current.map.fitBounds(bounds);
    }
  }, [travel.data.origin, travel.data.destinations]);

  function onGoogleAPILoaded(map, maps) {
    if (map) {
      refs.current.map = map;
      refs.current.maps = maps;

      setTimeout(() => {
        const bounds = getMapBounds();

        map.fitBounds(bounds);
      }, 100);
    }
  }

  return (
    <>
      <Modal
        updateData={updateData}
        setUpdateData={setUpdateData}
        updateIndex={updateIndex}
        locationType={locationType}
        isOpen={modal.isOpen}
        onClose={modal.close}
      />
      <Card
        header={
          <Text color="#464E5F" type="header">
            Origem / Destino
          </Text>
        }
      >
        {isEmpty && (
          <div className="text-center">
            <Text color="gray" type="regular">
              Essa viagem ainda não possui origem e destinos
            </Text>
          </div>
        )}
        {locations.length > 0 && (
          <Map
            height={215}
            yesIWantToUseGoogleMapApiInternals
            position={{
              lat: travel.data.origin?.lat,
              lng: travel.data.origin?.lng,
            }}
            onGoogleApiLoaded={({ map, maps }) => onGoogleAPILoaded(map, maps)}
          >
            {travel.data.origin && (
              <Marker
                background={theme.colors.light_blue}
                color="#fff"
                lat={travel.data.origin.lat}
                lng={travel.data.origin.lng}
                letter="C"
              />
            )}
            {travel.data.destinations
              .filter(destination => destination.lat && destination.lng)
              .map(destination => (
                <Marker
                  background={theme.colors.light_blue}
                  color="#fff"
                  lng={destination.lng}
                  lat={destination.lat}
                  letter={DESTINATION_ACTIONS.find(
                    action => destination.action === action.id
                  )
                    ?.name.charAt(0)
                    .toUpperCase()}
                />
              ))}
          </Map>
        )}
        <div className="mt-4">
          {travel.data.origin && (
            <div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <Styled.Bullet />
                  <Text color="dark">Coleta</Text>
                </div>
                <Styled.IconsContainer className="d-flex align-items-center">
                  <IconButton
                    icon="FaPencilAlt"
                    color={theme.colors.dark}
                    variant="secondary"
                    onClick={() => editLocation(travel.data.origin, 'origin')}
                    id="button-editar-coleta"
                  />
                  <IconButton
                    icon="FaTrash"
                    variant="error"
                    onClick={removeOrigin}
                    id="button-remover-coleta"
                  />
                </Styled.IconsContainer>
              </div>
              <Styled.Dashed>
                <Text type="regular" color="gray">
                  {formatAddress({
                    address: travel.data.origin?.address,
                    number: travel?.data?.origin?.number,
                    complement: travel?.data?.origin?.complement,
                    neighborhood: travel?.data?.origin?.neighborhood,
                    city: travel?.data?.origin?.city,
                    province: travel?.data?.origin?.province,
                    zip_code: travel?.data?.origin?.zip_code,
                  })}
                </Text>
                <Text type="regular" color="gray">
                  {!!travel.data?.origin?.start_schedule &&
                    `${formatDateTime(travel.data.origin.start_schedule)} até `}
                  {formatDateTime(travel.data.origin.scheduled_time)}
                </Text>
              </Styled.Dashed>
            </div>
          )}
          {travel.data.destinations.map((destination, index) => (
            <div key={String(index)}>
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <Styled.Bullet filled />
                  <Text color="dark">
                    {
                      DESTINATION_ACTIONS.find(
                        ({ id }) => id === destination?.action?.toLowerCase()
                      )?.name
                    }
                  </Text>
                </div>
                <Styled.IconsContainer className="d-flex align-items-center">
                  <IconButton
                    icon="FaPencilAlt"
                    color={theme.colors.dark}
                    variant="secondary"
                    onClick={() =>
                      editLocation(destination, 'destinations', index)
                    }
                    id={`button-editar-destino-${index}`}
                  />
                  <IconButton
                    icon="FaTrash"
                    variant="error"
                    onClick={() => removeDestination(index)}
                    id={`button-remover-destino-${index}`}
                  />
                </Styled.IconsContainer>
              </div>
              <Styled.Dashed>
                <Text type="regular" color="gray">
                  {formatAddress({
                    address: destination?.address,
                    number: destination?.number,
                    complement: destination?.complement,
                    neighborhood: destination?.neighborhood,
                    city: destination?.city,
                    province: destination?.province,
                    zip_code: destination?.zip_code,
                  })}
                </Text>
                <Text type="regular" color="gray">
                  {!!destination.start_schedule &&
                    `${formatDateTime(destination?.start_schedule)} até `}
                  {formatDateTime(destination.scheduled_time)}
                </Text>
              </Styled.Dashed>
            </div>
          ))}
        </div>
        <div className="d-flex justify-content-center mt-3">
          <Text type="regular" color="error">
            {travel.errors.destination}
          </Text>
        </div>

        <div className="d-flex justify-content-center mt-1">
          <Text type="regular" color="error">
            {travel.errors.origin}
          </Text>
        </div>

        <div className="d-flex justify-content-center mt-3">
          <Button onClick={addLocation} className="py-2">
            <Text type="regular" weight="500">
              Adicionar {addButtonText}
            </Text>
          </Button>
        </div>
      </Card>
    </>
  );
}
