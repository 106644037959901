import React from 'react';
import ReactSelect from 'react-select';
import { FaQuestionCircle } from 'react-icons/fa';
import theme from 'v3/theme';
import Text from '../Text';
import Loader from '../Loader';
import Async from './Async';
import * as Styled from './styles';
import Tooltip from '../Tooltip';

export default function Select({
  label,
  labelTextColor = '#464e5f',
  labelWhiteSpace = 'normal',
  menuFontSize = '14px',
  error,
  placeholder = 'Selecione...',
  onChange,
  value,
  options = [],
  multiple,
  onBlur,
  disableClear = false,
  disableSearch = false,
  isDisabled = false,
  getOptionLabel,
  getOptionValue,
  styles = {},
  components = {},
  noOptionsMessageText = 'Nenhuma opção encontrada',
  loading = false,
  className,
  menuFontColor = '#494950',
  menuStyle,
  tooltip = '',
  tooltipPlacement = 'top',
  tooltipAlign = 'left',
  toolTipWhiteSpace = 'normal',
  closeMenuOnSelect = true,
  id,
}) {
  const styledSelect = {
    singleValue: provided => ({
      ...provided,
      color: menuFontColor,
      fontSize: menuFontSize,
    }),
    menu: provided => ({
      ...provided,
      fontSize: menuFontSize,
      ...menuStyle,
    }),
    ...styles,
  };

  return (
    <Styled.Container className={className} style={styles.containerStyle}>
      {label && (
        <div className="d-flex w-100">
          <Styled.Label
            color={labelTextColor}
            type="label"
            weight="500"
            labelWhiteSpace={labelWhiteSpace}
          >
            {label}
          </Styled.Label>
          {tooltip.length > 0 && (
            <Tooltip
              placement={tooltipPlacement}
              content={
                <Styled.ToolTipLabel
                  tooltipAlign={tooltipAlign}
                  toolTipWhiteSpace={toolTipWhiteSpace}
                  type="label"
                  color="white"
                >
                  {tooltip}
                </Styled.ToolTipLabel>
              }
            >
              <FaQuestionCircle
                className="ml-2 mt-1"
                style={{ color: theme.colors.light_blue }}
              />
            </Tooltip>
          )}
        </div>
      )}
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <>
          <ReactSelect
            styles={styledSelect}
            isDisabled={isDisabled}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            options={options}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            isClearable={disableClear ? false : !!value}
            isSearchable={!disableSearch}
            isMulti={multiple}
            noOptionsMessage={() => <Text>{noOptionsMessageText}</Text>}
            onBlur={onBlur}
            closeMenuOnSelect={closeMenuOnSelect}
            components={{
              Control: ({ innerRef, innerProps, children }) => (
                <Styled.Control
                  className="select-control"
                  innerRef={innerRef}
                  {...innerProps}
                  error={error}
                >
                  {children}
                </Styled.Control>
              ),
              ValueContainer: ({ innerRef, innerProps, children }) => (
                <Styled.ValueContainer innerRef={innerRef} {...innerProps}>
                  {children}
                </Styled.ValueContainer>
              ),
              MultiValue: ({
                children,
                components: Components,
                removeProps,
              }) => (
                <Styled.MultiValue>
                  <Text type="label">{children}</Text>
                  <Styled.MultiValueRemove {...removeProps}>
                    <Components.Remove />
                  </Styled.MultiValueRemove>
                </Styled.MultiValue>
              ),
              Placeholder: ({ innerRef, ...innerProps }) => (
                <Styled.Placeholder innerRef={innerRef} {...innerProps} />
              ),
              ...components,
            }}
            inputId={id}
            instanceId={id}
          />
          {error && (
            <Styled.ErrorText color="error" type="little">
              {error}
            </Styled.ErrorText>
          )}
        </>
      )}
    </Styled.Container>
  );
}

Select.Async = Async;
