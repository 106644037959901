import React from 'react';
import { Link } from 'react-router-dom';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Stack,
} from '@mui/material';
import { Text } from 'v3/components';

export function ColumnContextMenu({
  open,
  onClose,
  activeColumn,
  setFilterTravelVehicle,
  scrollView,
  setChecked,
}) {
  function locateOnMap() {
    setFilterTravelVehicle(activeColumn.travel_id);
    setChecked(false);
    scrollView();
    onClose();
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Stack
          sx={{
            width: '100%',
            minWidth: { xs: '300px', sm: '360px', md: '400px' },
            gap: '0.25rem',
          }}
        >
          {
            <>
              <Text
                type="regular"
                fontSize={'0.75rem'}
                color="gray"
                as={'p'}
                className="my-1"
              >
                Visualizar <Link to={`/cargas/${activeColumn.id}`}>Carga</Link>
              </Text>
              <Text
                className="my-1"
                type="regular"
                fontSize={'0.75rem'}
                color="gray"
                as={'p'}
              >
                Visualizar{' '}
                <Link to={`/viagens/${activeColumn.travel_id}`}>Viagem</Link>
              </Text>
              {activeColumn.trackingStatusName !== 'Sem cálculo inicial' ? (
                <Text
                  className="my-1"
                  type="regular"
                  fontSize={'0.75rem'}
                  color="gray"
                  as={'p'}
                  onClick={() => locateOnMap()}
                >
                  <Link to={`#`}>Localizar no mapa</Link>
                </Text>
              ) : null}
              <Divider />
              <Text
                type="regular"
                fontSize={'0.75rem'}
                color="gray"
                as={'p'}
                className="my-1"
              >
                Visualizar cadastro do{' '}
                <Link to={`/transportadoras/${activeColumn.shipping_company}`}>
                  Transportador
                </Link>
              </Text>
              <Text
                type="regular"
                fontSize={'0.75rem'}
                color="gray"
                as={'p'}
                className="my-1"
              >
                Visualizar cadastro do{' '}
                <Link to={`/motoristas/${activeColumn.driver_id}`}>
                  Motorista
                </Link>
              </Text>
              <Text
                type="regular"
                fontSize={'0.75rem'}
                color="gray"
                as={'p'}
                className="my-1"
              >
                Visualizar cadastro do{' '}
                <Link to={`/clientes/${activeColumn.clientId}`}>Cliente Tomador</Link>
              </Text>
              <Text
                type="regular"
                fontSize={'0.75rem'}
                color="gray"
                as={'p'}
                className="my-1"
              >
                Visualizar cadastro do{' '}
                <Link to={`/veiculos/${activeColumn.mainVehicleId}`}>
                  Veículo Tração
                </Link>
              </Text>
              {activeColumn?.wagons_ids
                ? activeColumn.wagons_ids
                    .split(',')
                    .map(Number)
                    .map((wagonId, index) => {
                      return (
                        <Text
                          type="regular"
                          fontSize={'0.75rem'}
                          color="gray"
                          as={'p'}
                          className="my-1"
                        >
                          Visualizar cadastro do{' '}
                          <Link to={`/implementos/${wagonId}`}>
                            Implemento {index + 1}
                          </Link>
                        </Text>
                      );
                    })
                : null}
            </>
          }
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: '1rem' }}>
        <Button onClick={onClose}>Fechar</Button>
      </DialogActions>
    </Dialog>
  );
}
