import { validateVehicleWithPolicy } from '../../utils';

const Yup = require('yup');

export const schema = Yup.object().shape({
  driverMinTravels: Yup.number().nullable().when('$shouldInformDriver', {
    is: true,
    then: Yup.number().required(),
    otherwise: Yup.number().optional().nullable(),
  }),
  driver: Yup.object()
    .shape({
      id: Yup.number().nullable(),
    })
    .nullable()
    .when('$shouldInformDriver', {
      is: true,
      then: Yup.object()
        .shape({
          id: Yup.number().required('Motorista obrigatória'),
        })
        .required('Motorista obrigatória')
        .test(
          'compatible-with-range',
          'Motorista não tem número mínimo de viagens',
          function (value) {
            const { driverMinTravels } = this.parent;
            return (
              (value?.travelsQuantity ?? value?.travels_quantity) >=
              driverMinTravels
            );
          }
        ),
    }),
  responsibilitySecurity: Yup.object()
    .shape({
      id: Yup.number().nullable(),
    })
    .nullable()
    .when('$shouldInformResponsibilitySecurity', {
      is: true,
      then: Yup.object()
        .shape({
          id: Yup.number()
            .required('Responsabilidade seguro obrigatório')
            .typeError('Responsabilidade seguro obrigatório'),
        })
        .nullable()
        .required('Responsabilidade seguro obrigatório')
        .typeError('Responsabilidade seguro obrigatório'),
      otherwise: Yup.object()
        .shape({
          id: Yup.number().nullable().optional(),
        })
        .nullable(),
    }),
  securityProfile: Yup.object()
    .shape({
      id: Yup.number().nullable(),
    })
    .nullable()
    .when('$shouldInformSecurityProfile', {
      is: true,
      then: Yup.object()
        .shape({
          id: Yup.number()
            .required('Perfil de segurança obrigatório')
            .typeError('Perfil de segurança obrigatório'),
        })
        .nullable()
        .required('Perfil de segurança obrigatório')
        .typeError('Perfil de segurança obrigatório'),
      otherwise: Yup.object()
        .shape({
          id: Yup.number().nullable().optional(),
        })
        .nullable(),
    }),
  riskManagerSearch: Yup.object()
    .shape({
      id: Yup.number().nullable(),
    })
    .nullable()
    .when('$shouldInformRiskManagerSearch', {
      is: true,
      then: Yup.object()
        .shape({
          id: Yup.number()
            .required('Gerenciadora para pesquisa/consulta obrigatória')
            .typeError('Gerenciadora para pesquisa/consulta obrigatória'),
        })
        .nullable()
        .required('Gerenciadora para pesquisa/consulta obrigatória')
        .typeError('Gerenciadora para pesquisa/consulta obrigatória'),
      otherwise: Yup.object()
        .shape({
          id: Yup.number().nullable().optional(),
        })
        .nullable(),
    }),
  riskManager: Yup.object()
    .shape({
      id: Yup.number().nullable(),
    })
    .nullable()
    .when('$shouldInformRiskManager', {
      is: true,
      then: Yup.object()
        .shape({
          id: Yup.number()
            .required('Gerenciadora para rastreamento obrigatória')
            .typeError('Gerenciadora para rastreamento obrigatória'),
        })
        .nullable()
        .required('Gerenciadora para rastreamento obrigatória')
        .typeError('Gerenciadora para rastreamento obrigatória'),
      otherwise: Yup.object()
        .shape({
          id: Yup.number().nullable().optional(),
        })
        .nullable(),
    }),
  riskManagerMirror: Yup.object()
    .shape({
      id: Yup.number().nullable(),
    })
    .nullable()
    .when('$shouldInformRiskManagerMirror', {
      is: true,
      then: Yup.object()
        .shape({
          id: Yup.number()
            .required('Espelhamento logístico obrigatório')
            .typeError('Espelhamento logístico obrigatório'),
        })
        .nullable()
        .required('Espelhamento logístico obrigatório')
        .typeError('Espelhamento logístico obrigatório'),
      otherwise: Yup.object()
        .shape({
          id: Yup.number().nullable().optional(),
        })
        .nullable(),
    }),
  riskManagerLogistic: Yup.object()
    .shape({
      id: Yup.number().nullable(),
    })
    .nullable()
    .when('$shouldInformRiskManagerLogistic', {
      is: true,
      then: Yup.object()
        .shape({
          id: Yup.number()
            .required('Gerenciadora de risco para SM logística obrigatória')
            .typeError('Gerenciadora de risco para SM logística obrigatória'),
        })
        .nullable()
        .required('Gerenciadora de risco para SM logística obrigatória')
        .typeError('Gerenciadora de risco para SM logística obrigatória'),
      otherwise: Yup.object()
        .shape({
          id: Yup.number().nullable().optional(),
        })
        .nullable(),
    }),
  monitoringGroups: Yup.object()
    .shape({
      id: Yup.number().nullable(),
    })
    .nullable()
    .when('$shouldInformMonitoringGroup', {
      is: true,
      then: Yup.object()
        .shape({
          id: Yup.number()
            .required('Grupo de monitoramento obrigatória')
            .typeError('Grupo de monitoramento obrigatória'),
        })
        .nullable()
        .required('Grupo de monitoramento obrigatória')
        .typeError('Grupo de monitoramento obrigatória'),
      otherwise: Yup.object()
        .shape({
          id: Yup.number().nullable().optional(),
        })
        .nullable(),
    }),
  techTracker: Yup.object()
    .shape({
      id: Yup.number().nullable(),
    })
    .nullable()
    .when('$shouldInformTrackerTech', {
      is: true,
      then: Yup.object()
        .shape({
          id: Yup.number()
            .required('Tecnologia rastreador obrigatória')
            .typeError('Tecnologia rastreador obrigatória'),
        })
        .nullable()
        .required('Tecnologia rastreador obrigatória')
        .typeError('Tecnologia rastreador obrigatória'),
      otherwise: Yup.object()
        .shape({
          id: Yup.number().nullable().optional(),
        })
        .nullable(),
    }),
  modelTracker: Yup.object()
    .shape({
      id: Yup.number().nullable(),
    })
    .nullable()
    .when('$shouldInformTrackerModel', {
      is: true,
      then: Yup.object()
        .shape({
          id: Yup.number()
            .required('Modelo rastreador obrigatório')
            .typeError('Modelo rastreador obrigatório'),
        })
        .nullable()
        .required('Modelo rastreador obrigatório')
        .typeError('Modelo rastreador obrigatório'),
      otherwise: Yup.object()
        .shape({
          id: Yup.number().nullable().optional(),
        })
        .nullable(),
    }),
  locator: Yup.object()
    .shape({
      id: Yup.number().nullable(),
    })
    .nullable()
    .when('$shouldInformLocator', {
      is: true,
      then: Yup.object()
        .shape({
          id: Yup.number()
            .required('Localizador obrigatório')
            .typeError('Localizador obrigatório'),
        })
        .nullable()
        .required('Localizador obrigatório')
        .typeError('Localizador obrigatório'),
      otherwise: Yup.object()
        .shape({
          id: Yup.number().nullable().optional(),
        })
        .nullable(),
    }),
  immobilizer: Yup.object()
    .shape({
      id: Yup.number().nullable(),
    })
    .nullable()
    .when('$shouldInformImmobilizer', {
      is: true,
      then: Yup.object()
        .shape({
          id: Yup.number()
            .required('Imobilizador obrigatório')
            .typeError('Imobilizador obrigatório'),
        })
        .nullable()
        .required('Imobilizador obrigatório')
        .typeError('Imobilizador obrigatório'),
      otherwise: Yup.object()
        .shape({
          id: Yup.number().nullable().optional(),
        })
        .nullable(),
    }),
  baits: Yup.array()
    .optional()
    .nullable()
    .when(['$shouldInformBaits', '$baitsAmount'], {
      is: ($shouldInformBaits, $baitsAmount) =>
        $shouldInformBaits && $baitsAmount > 0,
      then: Yup.array()
        .nullable()
        .of(
          Yup.object()
            .shape({
              id: Yup.number()
                .required('Informe uma isca')
                .typeError('Informe uma isca'),
            })
            .nullable()
            .required('Informe uma isca')
            .typeError('Informe uma isca')
        )
        .required('Informe uma isca')
        .min(Yup.ref('$baitsAmount'), 'Informe todas as iscas')
        .typeError('Informe uma isca'),
    }),
  tractionMaxAge: Yup.number().nullable().when('$shouldInformVehicle', {
    is: true,
    then: Yup.number().required(),
    otherwise: Yup.number().nullable(),
  }),
  vehicle: Yup.object()
    .shape({
      id: Yup.number().nullable(),
    })
    .nullable()
    .when('$shouldInformVehicle', {
      is: true,
      then: Yup.object()
        .shape({
          id: Yup.number()
            .required('Veículo obrigatório')
            .typeError('Veículo obrigatório'),
        })
        .nullable()
        .required('Veículo obrigatório')
        .typeError('Veículo obrigatório')
        .test(
          'compatible-with-range',
          'Veículo idade superior ao permitido apólice',
          function (value) {
            const { tractionMaxAge } = this.parent;
            return validateVehicleWithPolicy(
              value?.yearManufacture || value?.year_manufacture,
              tractionMaxAge,
              value?.RNTRC_validated || value?.RNTRCValidated
            );
          }
        ),
      otherwise: Yup.object()
        .shape({
          id: Yup.number().nullable().optional(),
        })
        .nullable(),
    }),
  wagonMaxAge: Yup.number().nullable().when('$shouldInformWagons', {
    is: true,
    then: Yup.number().required(),
    otherwise: Yup.number().nullable(),
  }),
  wagons: Yup.array()
    .optional()
    .nullable()
    .when(['$shouldInformWagons', '$wagonsAmount'], {
      is: ($shouldInformWagons, $wagonsAmount) =>
        $shouldInformWagons && $wagonsAmount > 0,
      then: Yup.array()
        .nullable()
        .of(
          Yup.object()
            .shape({
              id: Yup.number()
                .required('Informe um implemento')
                .typeError('Informe um implemento'),
            })
            .typeError('Informe um implemento')
        )
        .required('Informe um implemento')
        .min(Yup.ref('$wagonsAmount'), 'Informe todos os implementos')
        .typeError('Informe um implemento')
        .test('compatible-with-range', function (value) {
          const { wagonMaxAge } = this.parent;
          const invalidIndex = value?.findIndex(item => {
            return !validateVehicleWithPolicy(
              item.yearManufacture || item.year_manufacture,
              wagonMaxAge,
              item.RNTRC_validated
            );
          });
          return (
            invalidIndex === -1 ||
            this.createError({
              path: `${this.path}[${invalidIndex}]`,
              message: `Implemento idade superior ao permitido na apólice`,
            })
          );
        }),
    }),
});
