import React, { useState, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import Fab from 'components/Fab';
import Snackbar from 'components/Snackbar';
import Input from 'components/Input';
import Button from 'components/Button';
import Sidebar from 'components/Sidebar';
import InfoMessage from 'components/InfoMessage';
import InfiniteScroll from 'components/InfiniteScroll';
import api from 'services/api';
import { usePermission } from 'hooks';
import CardGerenciadora from './CardGerenciadora';

function GerenciadorasRisco() {
  const inputEl = useRef(null);
  const [filter, setFilter] = useState('');
  const [gerenciadoraName, setGerenciadoraName] = useState('');
  const [gerenciadoraId, setGerenciadoraId] = useState('');
  const [snackbar, setSnackbar] = useState({
    display: false,
    type: 'info',
    message: '',
  });

  usePermission('VISUALIZAR_GERENCIADORAS_DE_RISCO', { redirect: true });

  function handleOpened(gerenciadora) {
    setGerenciadoraName(gerenciadora.name);
    setGerenciadoraId(gerenciadora.id);

    inputEl.current.toggle();
  }

  async function onSubmit(e) {
    e.preventDefault();
    let name = gerenciadoraName;
    let id = gerenciadoraId;

    try {
      if (!id) {
        let response = await api.post('risk-manager', {
          name,
        });

        if (response.status === 200) {
          if (response.data.error) {
            setSnackbar({
              display: true,
              type: 'error',
              message: response.data.error,
            });
          } else {
            setSnackbar({
              display: true,
              type: 'success',
              message: 'Gerenciadora ' + name + ' adicionada',
            });
            setFilter('');
            setFilter(' ');
          }
        } else {
        }
      } else {
        let response = await api.put('risk-manager/' + id, {
          name,
        });
        if (response.status === 200) {
          setSnackbar({
            display: true,
            type: 'success',
            message: 'Gerenciadora salvo',
          });
          setFilter('');
          setFilter(' ');
        }
      }
    } catch (e) {
      let responses = e.response.error;

      setSnackbar({
        display: true,
        type: 'error',
        message: responses,
      });

      return true;
    }
  }
  return (
    <>
      <Snackbar
        open={snackbar.display}
        type={snackbar.type}
        message={snackbar.message}
        onClose={() => setSnackbar(false)}
      />
      <Fab
        aria-label="Adicionar gerenciadora"
        style={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: '1',
          border: '2px solid rgb(33, 150, 243)',
          paddingRight: '20px',
          paddingLeft: '20px',
          color: 'rgb(255, 255, 255)',
          height: '36px',

          borderRadius: '18px',
        }}
        onClick={() => {
          setGerenciadoraName('');
          setGerenciadoraId(null);
          inputEl.current.toggle();
        }}
      >
        Adicionar gerenciadora
      </Fab>

      <InfiniteScroll
        endpoint="risk-manager"
        filter={filter}
        initialFetch
        placeholderHeight={145}
        placeholderWidth="50%"
        renderItem={(item, index) => (
          <CardGerenciadora
            handleOpened={handleOpened}
            key={index}
            gerenciadora={item}
            open={false}
          />
        )}
        endMessage={
          <Grid
            container
            item
            xs={12}
            justify="center"
            style={{ marginTop: '20px' }}
          >
            <InfoMessage
              icon="doneall"
              width="auto"
              snackColor="#8c8c8c"
              message="Não há mais itens a serem exibidos."
            />
          </Grid>
        }
      />
      <Sidebar
        ref={inputEl}
        closeButton={true}
        iconInit={false}
        title={gerenciadoraId ? 'Editar gerenciadora' : 'Nova gerenciadora'}
      >
        <form onSubmit={onSubmit}>
          <Input
            label="Nome da gerenciadora"
            value={gerenciadoraName}
            onChange={e => {
              setGerenciadoraName(e.target.value);
            }}
          />
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '15px',
            }}
          >
            <Button type="submit">
              {gerenciadoraId ? 'Salvar' : 'Adicionar'}
            </Button>
          </div>
        </form>
      </Sidebar>
    </>
  );
}

export default GerenciadorasRisco;
