import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { Text } from 'v3/components';
import { InsertNewButton } from 'v3/pages/Policy/styles';

export function InsertButton({ title, label, ...rest }) {
  return (
    <InsertNewButton title={title} {...rest}>
      <FaPlus />
      <Text type="regular" weight={500} color={'dark'} as="span">
        {label}
      </Text>
    </InsertNewButton>
  );
}
