import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

export default function useBot() {
  const history = useHistory();

  const serialized = useMemo(() => localStorage.getItem('bot') || null, []);

  if (!serialized) {
    localStorage.removeItem('currentClientId', null);
    localStorage.removeItem('currentClientName', null);
    localStorage.removeItem('token', null);
    localStorage.removeItem('usuario', null);

    history.push('/login');
  }

  return JSON.parse(serialized);
}
