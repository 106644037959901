/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';

import { AiFillStar } from 'react-icons/ai';
import { ImPlus } from 'react-icons/im';

import { Row, Col } from 'react-bootstrap';

import api from 'services/api';

import Loader from 'v3/components/Loader';
import Text from 'v3/components/Text';
import Link from 'v3/components/Link';

import useModal from 'hooks/useModal';
import { useSnackbar } from 'v3/components/Snackbar';
import FavoritesModal from './FavoritesModal';

import * as Styled from './styles';

import { verifyMostAcessedPermissions } from '../MostAccessed/validator';

function Favorites() {
  const favoritesModal = useModal();
  const snackbar = useSnackbar();

  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);

  async function getUserFavorites() {
    setLoading(true);
    try {
      const user_id = JSON.parse(localStorage.getItem('usuario')).id;
      const response = await api.get(`v3/user/favorites/${user_id}`);
      validateFavoritesPermission(response.data);
    } catch (err) {
      snackbar.show(<Text>Erro ao buscar seus favoritos</Text>, {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }

  const validateFavoritesPermission = favorite => {
    const validFavoritesRoutes = verifyMostAcessedPermissions(favorite);
    setSelected(validFavoritesRoutes);
  };

  useEffect(() => {
    getUserFavorites();
  }, []);

  return (
    <>
      <FavoritesModal
        selected={selected}
        setSelected={setSelected}
        isOpen={favoritesModal.isOpen}
        onClose={favoritesModal.close}
      />

      <Row className="d-flex flex-column mt-5 mb-2">
        <Col className="d-flex flex-row mb-4">
          <AiFillStar size={40} color="#73C164" className="mt-3 mr-1" />
          <div className="d-flex flex-column">
            <Text color="primary" weight="700" fontSize="36px">
              Favoritos
            </Text>

            {!loading && (
              <div
                style={{ cursor: 'pointer' }}
                className="p-0 mt-n2"
                onClick={() => {
                  favoritesModal.open();
                }}
              >
                <Text color="success">
                  {selected?.length === 0 ? 'Adicionar' : 'Editar'}
                </Text>
              </div>
            )}
          </div>
        </Col>
      </Row>
      {!loading ? (
        <>
          <Row>
            {selected.length === 0 && (
              <Col>
                <Styled.AddingWrapper
                  onClick={() => {
                    favoritesModal.open();
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <Col className="d-flex flex-row mt-n1">
                    <div>
                      <ImPlus size={30} color="dark" className="mt-1 mr-3" />
                    </div>
                    <div className="d-flex flex-column">
                      <Styled.Title
                        color="dark"
                        weight="700"
                        // className="mb-1"
                      >
                        adicionar
                      </Styled.Title>
                      <div>
                        <Styled.Title color="dark" weight="700">
                          favorito
                        </Styled.Title>
                      </div>
                    </div>
                  </Col>
                  <Col />
                </Styled.AddingWrapper>
              </Col>
            )}

            {selected?.map((item, index) => {
              return (
                <Col key={`${item.path}-${index}`}>
                  <Link
                    withoutIcon
                    className="mt-2"
                    to={`${item.path}`}
                    target="_blank"
                  >
                    <Styled.Wrapper>
                      <Styled.Title color="primary" weight="700">
                        {item.subtitle}
                      </Styled.Title>
                      <Text type="label">{item.path}</Text>
                    </Styled.Wrapper>
                  </Link>
                </Col>
              );
            })}
          </Row>
        </>
      ) : (
        <div className="d-flex justify-content-center mt-4">
          <Loader size="md" loading={loading} />
        </div>
      )}
    </>
  );
}

export default Favorites;
