import React from 'react';
import { Text } from 'v3/components';
import {
  AnswerContainer,
  AnswersContainer,
  QuestionItemHeader,
  SubQuestionContainer,
} from './styles';

export function QuestionItem({ question }) {
  return (
    <div>
      <QuestionItemHeader>
        <span>{question?.order ? `${question?.order}.` : ''}</span>
        <Text type="body" color="dark">
          {question?.question}
        </Text>
      </QuestionItemHeader>
      <AnswersContainer>
        {question?.values?.map(answer => {
          return (
            <AnswerContainer key={answer?.id}>
              <Text type="body" color="dark">
                {answer?.description}
              </Text>
            </AnswerContainer>
          );
        })}
      </AnswersContainer>
      {question?.subQuestion && (
        <SubQuestionContainer>
          <Text type="body" color="dark">
            {question?.subQuestion?.question}
          </Text>
          {question?.subQuestion?.values ? (
            <AnswersContainer>
              {question?.subQuestion?.values?.map(item => {
                return (
                  <AnswerContainer>
                    <Text type="body" color="dark">
                      {item?.description}
                    </Text>
                  </AnswerContainer>
                );
              })}
            </AnswersContainer>
          ) : null}
        </SubQuestionContainer>
      )}
    </div>
  );
}
