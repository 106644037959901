/* eslint-disable import/no-unresolved */
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';

import Text from 'v3/components/Text';

export default function DetailsText({ issuer }) {
  return (
    <Row>
      <Col xs={12}>
        <Text type="label">Nome</Text>
        <Text type="regular" color="gray" as="p" className="mt-2">
          {issuer && issuer.name}
        </Text>
      </Col>
      <Col xs={12}>
        <Text type="label">Nome fantasia</Text>
        <Text type="regular" color="gray" as="p" className="mt-2">
          {issuer && issuer.trading_name}
        </Text>
      </Col>
      <Col xs={6}>
        <Text type="label">CNPJ</Text>
        <Text type="regular" color="gray" as="p" className="mt-2">
          {issuer && issuer.document}
        </Text>
      </Col>
      <Col xs={6}>
        <Text type="label">Inscrição estadual</Text>
        <Text type="regular" color="gray" as="p" className="mt-2">
          {issuer && issuer.ie}
        </Text>
      </Col>
      <Col xs={6}>
        <Text type="label">RNTRC</Text>
        <Text type="regular" color="gray" as="p" className="mt-2">
          {issuer && issuer.rntrc ? issuer.rntrc : '-'}
        </Text>
      </Col>
      <Col xs={6}>
        <Text type="label">Telefone</Text>
        <Text type="regular" color="gray" as="p" className="mt-2">
          {issuer && issuer.phone}
        </Text>
      </Col>
      {issuer.logo ? (
        <Col xs={6}>
          <Text type="label">Logo</Text>
          <Row>
            <Image
              src={issuer.logo.full_path}
              style={{
                maxWidth: '100%',
              }}
            />
          </Row>
        </Col>
      ) : (
        <Text type="label" color="gray">
          Sem logo
        </Text>
      )}
    </Row>
  );
}
