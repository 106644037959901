import * as Yup from 'yup';

export const dataSchema = Yup.object({
  shipper: Yup.object().typeError('Você precisa informar uma transportadora.'),
  tags: Yup.array().typeError(),
});

export const riskManagerSchema = Yup.object({
  riskManager: Yup.mixed().when('$riskManagers', {
    is: riskManagers => !!riskManagers.length,
    then: Yup.object().typeError(
      'Você precisa informar uma gerenciadora de risco'
    ),
  }),
  riskManagerApprovalCode: Yup.string().nullable(),
  riskManagerStatus: Yup.string().required(
    'Você precisa informar o status do gerenciamento de risco'
  ),
  riskManagerObservation: Yup.string()
    .max(100, 'Máximo de 100 caracteres')
    .nullable(),
  endorsement: Yup.string().max(800, 'Máximo de 800 caracteres').nullable(),
});

export const policyRiskManagerSchema = Yup.object({
  responsibilitySecurity: Yup.object()
  .typeError('Você precisa informar uma responsabilidade seguro')
  .nullable(),
  securityProfile: Yup.object().typeError(
    'Você precisa informar um perfil de segurança'
  ).nullable(),
  riskManager: Yup.object()
  .typeError(
    'Você precisa informar uma gerenciadora de risco rastreamento'
  ).nullable(),
  riskManagerSearch: Yup.mixed().when('$shouldInformRiskManagerSearch', {
    is: true,
    then: Yup.object().typeError(
      'Você precisa informar uma gerenciadora de risco cadastro/consulta'
    ),
  }),
  riskManagerLogisticSm: Yup.mixed().when('$shouldInformRiskManagerLogistic', {
    is: true,
    then: Yup.object().typeError(
      'Você precisa informar uma gerenciadora de risco para SM Logística'
    ),
  }),
  riskManagerLogisticMirroring: Yup.mixed().when(
    '$shouldInformRiskManagerMirror',
    {
      is: true,
      then: Yup.object().typeError(
        'Você precisa informar um espelhamento logístico'
      ),
    }
  ),
  riskManagerApprovalCode: Yup.string().nullable(),
  riskManagerStatus: Yup.string().required(
    'Você precisa informar o status do gerenciamento de risco'
  ),
  tracker: Yup.mixed().when(
    ['$shouldInformMonitoringGroup', '$shouldInformTrackerTech'],
    {
      is: ($shouldInformMonitoringGroup, $shouldInformTrackerTech) =>
        $shouldInformMonitoringGroup && $shouldInformTrackerTech,
      then: Yup.object()
        .required('Você precisa informar tecnologia do rastreador')
        .typeError('Você precisa informar tecnologia do rastreador'),
    }
  ),
  trackerType: Yup.object()
    .typeError('Você precisa informar modelo do rastreador')
    .nullable(),
  trackerNumber: Yup.string()
    .nullable(),
  trackerLocator: Yup.object()
    .shape({
      id: Yup.number().nullable(),
    })
    .nullable()
    .when('$shouldInformLocator', {
      is: true,
      then: Yup.object()
        .shape({
          id: Yup.number()
            .required('Localizador obrigatório')
            .typeError('Localizador obrigatório'),
        })
        .nullable()
        .required('Localizador obrigatório')
        .typeError('Localizador obrigatório'),
      otherwise: Yup.object()
        .shape({
          id: Yup.number().nullable().optional(),
        })
        .nullable(),
    }),
  locatorNumber: Yup.string()
    .nullable(),
  trackerImmobilizer: Yup.object()
    .shape({
      id: Yup.number().nullable(),
    })
    .nullable()
    .when('$shouldInformImmobilizer', {
      is: true,
      then: Yup.object()
        .shape({
          id: Yup.number()
            .required('Imobilizador obrigatório')
            .typeError('Imobilizador obrigatório'),
        })
        .nullable()
        .required('Imobilizador obrigatório')
        .typeError('Imobilizador obrigatório'),
      otherwise: Yup.object()
        .shape({
          id: Yup.number().nullable().optional(),
        })
        .nullable(),
    }),
  immobilizerNumber: Yup.string()
    .nullable(),
  baits: Yup.array()
    .optional()
    .nullable()
    .when(['$shouldInformBaits', '$baitsAmount'], {
      is: ($shouldInformBaits, $baitsAmount) =>
        $shouldInformBaits && $baitsAmount > 0,
      then: Yup.array()
        .nullable()
        .of(
          Yup.object()
            .shape({
              id: Yup.number()
                .required('Informe uma isca')
                .typeError('Informe uma isca'),
            })
            .nullable()
            .required('Informe uma isca')
            .typeError('Informe uma isca')
        )
        .required('Informe uma isca')
        .min(Yup.ref('$baitsAmount'), 'Informe todas as iscas')
        .typeError('Informe uma isca'),
    }),
  baitsNumber: Yup.array()
    .of(Yup.string().nullable())
    .nullable(),
  riskManagerObservation: Yup.string()
    .max(1000, 'Máximo de 1000 caracteres')
    .nullable()
    .typeError('Máximo de 1000 caracteres'),

  endorsement: Yup.string().max(800, 'Máximo de 800 caracteres').nullable(),
});
