import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { FaTrash, FaPencilAlt } from 'react-icons/fa';
import { Row, Col, Table } from 'react-bootstrap';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { ValidationError } from 'yup';

import Text from 'v3/components/Text';
import Select from 'v3/components/Select';
import Input from 'v3/components/Input';
import DatePicker from 'v3/components/DatePicker';
import { useSnackbar } from 'v3/components/Snackbar';
import { useTravel } from 'v3/pages/Travel';
import Modal from 'v3/components/Modal';
import Button from 'v3/components/Button';

import api from 'services/api';
import useErrors from 'hooks/useErrors';
import { formatDateTime } from 'v3/utils/formatter';

import { CteText } from '../../Map/Progress/styles';
import schema from './schema';

export default function Cte({ showModal, handleClose, status }) {
  const snackbar = useSnackbar();
  const travel = useTravel();

  const [cteList, setCteList] = useState([]);

  const [travelNumber, setTravelNumber] = useState(travel.data?.travelNumber);
  const [cteNumber, setCteNumber] = useState('');
  const [issuedAt, setIssuedAt] = useState('');
  const [invoices, setInvoices] = useState([]);
  const [destination, setDestination] = useState('');

  const [errors, { getErrorsFromYup, clearErrors }] = useErrors({});

  const [isInFlight, setInFlight] = useState(false);

  useEffect(() => {
    setTravelNumber(travel.data.travelNumber);
  }, [travel.data.travelNumber]);

  async function handleAddCte() {
    try {
      const validated = await schema.validate(
        {
          travelNumber,
          cteNumber,
          issuedAt,
          invoices,
          destination,
        },
        {
          abortEarly: false,
          context: { cteList, emitteds: travel.data.emitteds },
        }
      );

      setCteList(previous => [
        ...previous,
        {
          ...validated,
          issuedAt: moment.utc(issuedAt, 'DD/MM/YYYY HH:mm').toISOString(),
        },
      ]);

      setDestination('');
      setCteNumber('');
      setIssuedAt('');
      setInvoices([]);
    } catch (ex) {
      if (ex instanceof ValidationError) {
        getErrorsFromYup(ex.inner);
      }
    }
  }

  async function submitCteList() {
    try {
      setInFlight(true);

      const documentsIssuedStatus = travel.statuses.find(
        item => item.code === 'DOCS_ISSUED'
      );
      const assertedStatus =
        status.order < documentsIssuedStatus.order
          ? documentsIssuedStatus
          : status;

      await api.put(`travel-data/updateStatusWithCTe/${travel?.data.id}`, {
        status: assertedStatus,
        travelNumber,
        cteList,
      });

      travel.setData(prev => ({ ...prev, status }));

      snackbar.show(<Text>Lista de CT-e adicionada com sucesso.</Text>, {
        type: 'success',
      });

      setCteList([]);
      clearErrors();
      handleClose();

      await travel.fetchTravel();
    } catch (ex) {
      let message = 'Não foi possível cadastrar a lista de CT-e.';
      if (ex.response) {
        const { error } = ex.response.data;

        if (error && typeof error === 'string') {
          message = error;
        }
      }

      snackbar.show(<Text>{message}</Text>, {
        type: 'error',
      });
    } finally {
      setInFlight(false);
    }
  }

  function handleDelete(item) {
    setCteList(cteList.filter(e => e !== item));
  }

  function handleEdit(item) {
    handleDelete(item);

    setDestination(item.destination);
    setCteNumber(item.cteNumber);
    setTravelNumber(item.travelNumber);
    setInvoices(item.invoices);
    setIssuedAt(item.issuedAt);
  }

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      handleClose={handleClose}
      heading={
        <Text type="subheader" color="dark" weight="500">
          Adicionar CT-e
        </Text>
      }
      body={
        <Row>
          <Col xs={12} className="mb-3">
            <Input
              value={travelNumber}
              disabled={!!travel.data.travelNumber}
              label="Nº da viagem"
              onChange={event => setTravelNumber(event.target.value)}
              error={errors.travelNumber}
            />
          </Col>
          <Col xs={6} className="mb-3">
            <Input
              value={cteNumber}
              label="Nº do Documento"
              onChange={event => setCteNumber(event.target.value)}
              error={errors.cteNumber}
            />
          </Col>
          <Col className="mb-3">
            <DatePicker
              value={issuedAt}
              label="Data de emissão"
              placeholder="__/__/____ __:__"
              guide
              withTime
              onChange={event => setIssuedAt(event.target.value)}
              error={errors.issuedAt}
            />
          </Col>
          <Col xs={12} className="mb-3">
            <Text type="label">Notas fiscais</Text>
            <AsyncCreatableSelect
              value={invoices}
              noOptionsMessage={() => <Text>Digite uma nota fiscal</Text>}
              formatCreateLabel={value => <Text>{`Criar "${value}"`} </Text>}
              placeholder={null}
              isMulti
              createOptionPosition="first"
              onChange={value => setInvoices(value)}
            />
            {!!errors.invoices && (
              <Text type="little" color="error">
                {errors.invoices}
              </Text>
            )}
          </Col>
          {!!invoices?.length && (
            <Col xs={12}>
              <Table>
                <thead>
                  <tr>
                    <th>
                      <Text type="label">Nº da Nota</Text>
                    </th>
                    <th>
                      <Text type="label">Chave</Text>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {invoices.map((invoice, index) => (
                    <tr>
                      <td>
                        <Text type="label">{invoice.value}</Text>
                      </td>
                      <td>
                        <Input
                          value={invoice?.key}
                          onChange={event =>
                            setInvoices(
                              invoices.map((item, i) => {
                                if (i === index) {
                                  return { ...item, key: event.target.value };
                                }

                                return item;
                              })
                            )
                          }
                          error={errors[`invoices[${index}].key`]}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          )}
          <Col xs={12} className="mb-3">
            <Select
              placeholder={null}
              value={destination}
              label="Destino"
              options={travel.data?.loads?.loadDestinations}
              getOptionLabel={option => option.formatted}
              getOptionValue={option => option.id}
              onChange={item => setDestination(item)}
              error={errors.destination}
            />
          </Col>
          <Col xs={12} className="text-center mb-3">
            <Button variant="secondary" onClick={handleAddCte} className="py-2">
              <Text type="regular" weight="500">
                Adicionar
              </Text>
            </Button>
          </Col>

          {!!cteList.length && (
            <>
              <Col xs={12} className="pt-4">
                <Text>Adicionados a viagem</Text>
              </Col>
              <Col xs={12}>
                {cteList.map(cte => (
                  <>
                    <Row className="mt-3">
                      <Col>
                        <CteText weigth={600}>CT-e {cte?.cteNumber}</CteText>
                      </Col>
                      <Col className="d-flex justify-content-end align-items-center mb-3">
                        <Button
                          variant="secondary"
                          size="sm"
                          onClick={() => handleEdit(cte)}
                        >
                          <FaPencilAlt />
                        </Button>
                        <Button
                          variant="error"
                          size="sm"
                          className="mx-1"
                          onClick={() => handleDelete(cte)}
                        >
                          <FaTrash />
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <CteText type="label" color="gray">
                          Emitido em{' '}
                          {formatDateTime(cte.issuedAt, { isUTC: true })}
                        </CteText>
                        {cte.invoices.map(invoice => (
                          <>
                            <CteText color="gray" type="label" className="mt-2">
                              Nota Fiscal {invoice.label}
                            </CteText>
                            {invoice.key && (
                              <CteText color="gray" type="label">
                                Chave {invoice.key}
                              </CteText>
                            )}
                          </>
                        ))}
                      </Col>
                    </Row>
                  </>
                ))}
              </Col>
              <Col xs={12} className="pt-4 text-center">
                <Button
                  variant="primary"
                  onClick={submitCteList}
                  loading={isInFlight}
                  className="my-2"
                >
                  <Text type="regular" weight="500">
                    Salvar
                  </Text>
                </Button>
              </Col>
            </>
          )}
        </Row>
      }
    />
  );
}
