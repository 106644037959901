import React, { useState, useEffect } from 'react';

import { useParams, Link } from 'react-router-dom';

import api from 'services/api';

import PropTypes from 'prop-types';

import { useTheme } from '@material-ui/core';
import { Table } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Tabs } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Chip from '@material-ui/core/Chip';

import { StyledTab } from './styles';
import { StyledTableBody } from './styles';
import { StyledTableRow } from './styles';
import { StyledTableCell } from './styles';

import ComponentAsyncSelect from 'components/ComponentAsyncSelect';
import Button from 'components/Button';
import Card from 'components/Card';
import Snackbar from 'components/Snackbar';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box p={3} style={{ paddingLeft: 0, paddingRight: 0 }}>
        {children}
      </Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function TagDetail() {
  const params = useParams();
  const theme = useTheme();

  const [value, setValue] = useState(0);

  const [dialogInfo, setDialogInfo] = useState('');

  const [open, setOpen] = useState(false);

  const [users, setUsers] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [clients, setClients] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [travelCount, setTravelCount] = useState([]);
  const [loadCount, setLoadCount] = useState([]);

  const [travelStatusCount, setTravelStatusCount] = useState({});
  const [loadStatusCount, setLoadStatusCount] = useState({});

  const [selectedUser, setSelectedUser] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState([]);
  const [selectedClient, setSelectedClient] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState([]);

  const [snackBar, setSnackBar] = useState({
    display: false,
    type: 'error',
    message: '',
  });

  useEffect(() => {
    getTagDetails();
  }, []);

  async function getTagDetails() {
    const response = await api.get(`tags-details/${params.tag_id}`);
    if (response.data && response.data.length > 0) {
      const res = response.data[0];
      setUsers(res.users);
      setVehicles(res.vehicles);
      setClients(res.clients);
      setDrivers(res.drivers);
      setTravelCount(
        res.__meta__.travel_collection_letter +
          res.__meta__.travel_ongoing +
          res.__meta__.travel_ongoing
      );
      setLoadCount(res.__meta__.load_count);
      setLoadStatusCount(
        countLoadsStatus(res.loads, res.__meta__.load_cancelled)
      );
      setTravelStatusCount({
        ordemDeColeta: res.__meta__.travel_collection_letter,
        emViagem: res.__meta__.travel_ongoing,
        canceladas: res.__meta__.travel_ongoing,
      });
    }
  }

  function countLoadsStatus(loads, cancelled) {
    let qtd_load_attended = 0,
      qtd_load_not_attended = 0;

    loads.map(item => {
      if (item.__meta__.load_attended === 0) qtd_load_not_attended++;
      else qtd_load_attended++;
    });

    return {
      attended: qtd_load_attended,
      not_attended: qtd_load_not_attended,
      cancelled: cancelled,
    };
  }

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  async function confirmOption() {
    try {
      const res = await api.post(
        `${dialogInfo.url}/${params.tag_id}`,
        dialogInfo.body
      );
      if (res.status === 200) {
        getTagDetails();
        setSnackBar({
          type: 'success',
          message: `${dialogInfo.type} ${dialogInfo.name} removido da tag`,
          display: true,
        });
        setOpen(false);
      }
    } catch (err) {
      setSnackBar({
        type: 'error',
        message: `Não foi possível remover o usuário ${dialogInfo.name} da tag`,
        display: true,
      });
    }
  }

  const addUsers = async () => {
    if (selectedUser && selectedUser.length > 0) {
      const listUserId = selectedUser.map(user => {
        return user.id;
      });
      const dataUser = {
        tag: params.tag_id,
        users: listUserId,
      };

      try {
        const res = await api.post(`tags-users`, dataUser);

        if (res.status === 200) {
          getTagDetails();
          setSnackBar({
            type: 'success',
            message: `Tag ${params.tag_name} adicionada ${
              listUserId.length > 1 ? 'aos usuários' : 'ao usuário'
            }`,
            display: true,
          });

          setSelectedUser([]);
        }
      } catch (err) {
        setSnackBar({
          type: 'error',
          message: `Não foi possível adicionar a tag ${
            listUserId.length > 1 ? 'aos usuários' : 'ao usuário'
          }`,
          display: true,
        });
      }
    } else {
      setSnackBar({
        type: 'error',
        message: `Selecione pelo menos um usuário`,
        display: true,
      });
    }
  };

  const addClients = async () => {
    if (selectedClient && selectedClient.length > 0) {
      const listClientId = selectedClient.map(client => {
        return client.id;
      });

      const dataClient = {
        tag: params.tag_id,
        clients: listClientId,
      };

      try {
        await api.post(`tags-clients`, dataClient);

        getTagDetails();
        setSnackBar({
          type: 'success',
          message: `Tag ${params.tag_name} adicionada ${
            listClientId.length > 1 ? 'aos clientes' : 'ao cliente'
          }`,
          display: true,
        });

        setSelectedClient([]);
      } catch (err) {
        setSnackBar({
          type: 'error',
          message: `Não foi possível adicionar a tag ${
            listClientId.length > 1 ? 'aos clientes' : 'ao clientes'
          }`,
          display: true,
        });
      }
    } else {
      setSnackBar({
        type: 'error',
        message: `Selecione pelo menos um cliente`,
        display: true,
      });
    }
  };

  const addVehicles = async () => {
    if (selectedVehicle && selectedVehicle.length > 0) {
      // console.log(selectedVehicle);
      const listVehicleId = selectedVehicle.map(vehicle => {
        return vehicle.id;
      });
      const dataVehicle = {
        tag: params.tag_id,
        vehicles: listVehicleId,
      };

      try {
        const res = await api.post(`tags-vehicles`, dataVehicle);

        if (res.status === 200) {
          getTagDetails();
          setSnackBar({
            type: 'success',
            message: `Tag ${params.tag_name} adicionada ${
              listVehicleId.length > 1 ? 'aos veículos' : 'ao veículo'
            }`,
            display: true,
          });

          setSelectedVehicle([]);
        }
      } catch (err) {
        setSnackBar({
          type: 'error',
          message: `Não foi possível adicionar a tag ${
            listVehicleId.length > 1 ? 'aos veículos' : 'ao veículo'
          }`,
          display: true,
        });
      }
    } else {
      setSnackBar({
        type: 'error',
        message: `Selecione pelo menos um veículo`,
        display: true,
      });
    }
  };

  const addDrivers = async () => {
    if (selectedDriver && selectedDriver.length > 0) {
      const idDriverList = selectedDriver.map(driver => {
        return driver.id;
      });

      const dataDriver = {
        tag: params.tag_id,
        drivers: idDriverList,
      };

      try {
        const res = await api.post(`tags-driver`, dataDriver);

        if (res.status === 200) {
          getTagDetails();
          setSnackBar({
            type: 'success',
            message: `Tag ${params.tag_name} adicionada ${
              idDriverList.length > 1 ? 'aos motoristas' : 'ao motrista'
            }`,
            display: true,
          });

          setSelectedDriver([]);
        }
      } catch (err) {
        setSnackBar({
          type: 'error',
          message: `Não foi possível adicionar a tag ${
            idDriverList.length > 1 ? 'aos motoristas' : 'ao motorista'
          }`,
          display: true,
        });
      }
    } else {
      setSnackBar({
        type: 'error',
        message: `Selecione pelo menos um motirista`,
        display: true,
      });
    }
  };

  return (
    <Grid container>
      <Snackbar
        open={snackBar.display}
        type={snackBar.type}
        message={snackBar.message}
        onClose={() => setSnackBar(false)}
      ></Snackbar>
      <Dialog
        open={open}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Cancelamento de Tag</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Deseja realmente remover a tag `}
            <b>{params.tag_name}</b>
            {` do ${dialogInfo.type}`} <b>{dialogInfo.name}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            outline={1}
            onClick={() => {
              setOpen(false);
            }}
            color="primary"
          >
            Não
          </Button>
          <Button
            onClick={() => {
              confirmOption();
            }}
            color="success"
            autoFocus
          >
            Sim
          </Button>
        </DialogActions>
      </Dialog>
      <Grid item xs={12} md={12} lg={12}>
        <Card
          style={{
            marginBottom: '10px',
          }}
        >
          <Grid container>
            <Grid item xs={12} style={{ marginBottom: '30px' }}>
              <h3 className="textTitle">{params.tag_name}</h3>
              <hr />
            </Grid>

            <Grid item xs={12}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                indicatorColor="#fff"
                textColor="primary"
              >
                <StyledTab
                  style={{ 'padding-top': '12px' }}
                  label={[
                    <Chip
                      style={{
                        marginBottom: '10px',
                      }}
                      className={'bgChip'}
                      label={users.length ? users.length : '0'}
                    />,
                    'Usuários',
                  ]}
                  {...a11yProps(0)}
                  active={value === 0}
                ></StyledTab>
                <StyledTab
                  style={{ 'padding-top': '12px' }}
                  label={[
                    <Chip
                      style={{
                        marginBottom: '10px',
                      }}
                      className={'bgChip'}
                      label={vehicles.length ? vehicles.length : '0'}
                    />,
                    'Veículos',
                  ]}
                  {...a11yProps(1)}
                  active={value === 1}
                ></StyledTab>
                <StyledTab
                  style={{ 'padding-top': '12px' }}
                  label={[
                    <Chip
                      style={{
                        marginBottom: '10px',
                      }}
                      className={'bgChip'}
                      label={clients.length ? clients.length : '0'}
                    />,
                    'Cliente',
                  ]}
                  {...a11yProps(2)}
                  active={value === 2}
                ></StyledTab>
                <StyledTab
                  style={{ 'padding-top': '12px' }}
                  label={[
                    <Chip
                      style={{
                        marginBottom: '10px',
                      }}
                      className={'bgChip'}
                      label={drivers.length ? drivers.length : '0'}
                    />,
                    'Motoristas',
                  ]}
                  {...a11yProps(3)}
                  active={value === 3}
                ></StyledTab>
                <StyledTab
                  style={{ 'padding-top': '12px' }}
                  label={[
                    <Chip
                      style={{
                        marginBottom: '10px',
                      }}
                      className={'bgChip'}
                      label={loadCount ? loadCount : '0'}
                    />,
                    'Cargas',
                  ]}
                  {...a11yProps(4)}
                  active={value === 4}
                ></StyledTab>
                <StyledTab
                  style={{ 'padding-top': '12px' }}
                  label={[
                    <Chip
                      style={{
                        marginBottom: '10px',
                      }}
                      className={'bgChip'}
                      label={travelCount ? travelCount : '0'}
                    />,
                    'Viagens',
                  ]}
                  {...a11yProps(5)}
                  active={value === 5}
                ></StyledTab>
              </Tabs>
            </Grid>
          </Grid>
        </Card>
        <Card style={{ overflow: 'visible' }}>
          <TabPanel value={value} index={0} dir={theme.direction}>
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <ComponentAsyncSelect
                  route={`user/name?user=`}
                  label=""
                  optionLabel="username"
                  optionValue="id"
                  onChange={event => setSelectedUser(event)}
                  isMulti
                  defaultValue={selectedUser}
                  placeholder="Insira o nome ou CNPJ"
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  color="success"
                  onClick={() => {
                    // addClients();
                    addUsers();
                  }}
                >
                  Adicionar usuários
                </Button>
              </Grid>

              <Grid item xs={12}>
                <Table>
                  <StyledTableBody>
                    {users &&
                      users.map(user => (
                        <StyledTableRow>
                          <StyledTableCell>
                            {user.username ? user.username : ''}
                          </StyledTableCell>
                          <StyledTableCell>
                            {user.email ? user.email : ''}
                          </StyledTableCell>
                          <StyledTableCell>
                            {user.cpf ? user.cpf : ''}
                          </StyledTableCell>
                          <StyledTableCell>
                            <IconButton
                              style={{
                                padding: '5px',
                                margin: '1px',
                              }}
                              onClick={() => {
                                setOpen(true);
                                setDialogInfo({
                                  ...user,
                                  type: 'usuário',
                                  name: user.username,
                                  url: 'tag-user',
                                  body: {
                                    userId: user.id,
                                  },
                                });
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </StyledTableBody>
                </Table>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <ComponentAsyncSelect
                  route={`vehicle/license_plate?search=`}
                  label=""
                  optionLabel="plate"
                  optionValue="id"
                  onChange={event => setSelectedVehicle(event)}
                  isMulti
                  defaultValue={selectedVehicle}
                  placeholder="Insira as placas dos veículos"
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  color="success"
                  onClick={() => {
                    addVehicles();
                  }}
                >
                  Adicionar veículos
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Table>
                  <StyledTableBody>
                    {vehicles &&
                      vehicles.map(vehicle => (
                        <StyledTableRow>
                          <StyledTableCell>
                            {vehicle.plate ? vehicle.plate : ''}
                          </StyledTableCell>
                          <StyledTableCell>
                            {vehicle.vehicleType
                              ? vehicle.vehicleType.name
                              : ''}
                          </StyledTableCell>
                          <StyledTableCell>
                            {vehicle.axisType ? vehicle.axisType.name : ''}
                          </StyledTableCell>
                          <StyledTableCell>
                            {vehicle.implementType
                              ? vehicle.implementType.name
                              : ''}
                          </StyledTableCell>
                          <StyledTableCell>
                            {vehicle.vehicleBodyType
                              ? vehicle.vehicleBodyType.name
                              : ''}
                          </StyledTableCell>
                          <StyledTableCell>
                            <IconButton
                              style={{
                                padding: '5px',
                                margin: '1px',
                              }}
                              onClick={() => {
                                console.log(vehicle);
                                setOpen(true);
                                setDialogInfo({
                                  ...vehicle,
                                  type: 'veículo',
                                  name: vehicle.plate,
                                  url: 'tag-vehicle',
                                  body: {
                                    vehicleId: vehicle.id,
                                  },
                                });
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </StyledTableBody>
                </Table>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <ComponentAsyncSelect
                  route={`persons/customers?search=`}
                  label=""
                  placeholder="Insira o nome ou CNPJ"
                  optionValue="id"
                  onChange={event => setSelectedClient(event)}
                  isMulti
                  defaultValue={selectedClient}
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  color="success"
                  onClick={() => {
                    addClients();
                  }}
                >
                  Adicionar clientes
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Table>
                  <StyledTableBody>
                    {clients &&
                      clients.map(client => (
                        <StyledTableRow>
                          <StyledTableCell>
                            {client.social_name ? client.social_name : ''}
                          </StyledTableCell>
                          <StyledTableCell>
                            {client.email ? client.email : ''}
                          </StyledTableCell>
                          <StyledTableCell>
                            {client.cgccpf ? client.cgccpf : ''}
                          </StyledTableCell>
                          <StyledTableCell>
                            <IconButton
                              style={{
                                padding: '5px',
                                margin: '1px',
                              }}
                              onClick={() => {
                                setOpen(true);
                                setDialogInfo({
                                  ...client,
                                  type: 'cliente',
                                  name: client.social_name,
                                  url: 'tag-client',
                                  body: {
                                    clientId: client.pivot.client_id,
                                  },
                                });
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </StyledTableBody>
                </Table>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={3} dir={theme.direction}>
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <ComponentAsyncSelect
                  route={`driver/name?search=`}
                  label=""
                  optionLabel="social_name"
                  optionValue="id"
                  onChange={event => setSelectedDriver(event)}
                  isMulti
                  defaultValue={selectedDriver}
                  placeholder="Insira os motoristas"
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  color="success"
                  onClick={() => {
                    addDrivers();
                  }}
                >
                  Adicionar motoristas
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Table>
                  <StyledTableBody>
                    {drivers &&
                      drivers.map(driver => (
                        <>
                          <StyledTableRow>
                            <StyledTableCell>
                              {driver.social_name ? driver.social_name : ''}
                            </StyledTableCell>
                            <StyledTableCell>
                              {driver.email ? driver.email : ''}
                            </StyledTableCell>
                            <StyledTableCell>
                              {driver.cgccpf ? driver.cgccpf : ''}
                            </StyledTableCell>

                            <StyledTableCell>
                              <IconButton
                                style={{
                                  padding: '5px',
                                  margin: '1px',
                                }}
                                onClick={() => {
                                  setOpen(true);
                                  setDialogInfo({
                                    ...driver,
                                    type: 'motorista',
                                    name: driver.social_name,
                                    url: 'tag-driver',
                                    body: {
                                      driverId: driver.id,
                                    },
                                  });
                                }}
                              >
                                <CloseIcon />
                              </IconButton>
                            </StyledTableCell>
                          </StyledTableRow>
                        </>
                      ))}
                  </StyledTableBody>
                </Table>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={4} dir={theme.direction}>
            <Grid container>
              <Grid item xs={4} style={{ textAlign: 'center' }}>
                <Chip
                  style={{ marginRight: '10px' }}
                  label={
                    loadStatusCount.attended ? loadStatusCount.attended : '0'
                  }
                />
                <Link
                  style={{
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    cursor: 'pointer',
                  }}
                  target="_blank"
                  to={`/cargas?status=Não%20atendidas&order=coleta-crescente&tags=${params.tag_id}`}
                >
                  Cargas atendidas
                </Link>
              </Grid>
              <Grid item xs={4} style={{ textAlign: 'center' }}>
                <Chip
                  style={{ marginRight: '10px' }}
                  label={
                    loadStatusCount.not_attended
                      ? loadStatusCount.not_attended
                      : '0'
                  }
                />
                <Link
                  style={{
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    cursor: 'pointer',
                  }}
                  target="_blank"
                  to={`/cargas?status=Não%20atendidas&order=coleta-crescente&tags=${params.tag_id}`}
                >
                  Cargas não atendidas
                </Link>
              </Grid>
              <Grid item xs={4} style={{ textAlign: 'center' }}>
                <Chip
                  style={{ marginRight: '10px' }}
                  label={
                    loadStatusCount.cancelled ? loadStatusCount.cancelled : '0'
                  }
                />
                <Link
                  style={{
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    cursor: 'pointer',
                  }}
                  target="_blank"
                  to={`/cargas?status=Canceladas&order=coleta-crescente&attended=true&tags=${params.tag_id}`}
                >
                  Cargas canceladas
                </Link>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={5} dir={theme.direction}>
            <Grid container>
              <Grid item xs={12} lg={4} md={4} style={{ textAlign: 'center' }}>
                <Chip
                  style={{ marginRight: '10px' }}
                  label={
                    travelStatusCount.ordemDeColeta
                      ? travelStatusCount.ordemDeColeta
                      : '0'
                  }
                />
                <Link
                  style={{
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    cursor: 'pointer',
                  }}
                  target="_blank"
                  to={`/viagens?status=2&tags=${params.tag_id}`}
                >
                  Ordem de coleta
                </Link>
              </Grid>
              <Grid item xs={12} lg={4} md={4} style={{ textAlign: 'center' }}>
                <Chip
                  style={{ marginRight: '10px' }}
                  label={
                    travelStatusCount.emViagem
                      ? travelStatusCount.emViagem
                      : '0'
                  }
                />
                <Link
                  style={{
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    cursor: 'pointer',
                  }}
                  target="_blank"
                  to={`/viagens?status=3&tags=${params.tag_id}`}
                >
                  Em viagem
                </Link>
              </Grid>
              <Grid item xs={12} lg={4} md={4} style={{ textAlign: 'center' }}>
                <Chip
                  style={{ marginRight: '10px' }}
                  label={
                    travelStatusCount.canceladas
                      ? travelStatusCount.canceladas
                      : '0'
                  }
                />
                <Link
                  style={{
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    cursor: 'pointer',
                  }}
                  target="_blank"
                  to={`/viagens?status=6&tags=${params.tag_id}`}
                >
                  Finalizada
                </Link>
              </Grid>
            </Grid>
          </TabPanel>
        </Card>
      </Grid>
    </Grid>
  );
}
