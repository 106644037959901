import moment from 'moment';

export const rangeFieldsSlugMapper = {
  security_profile: 'Perfil de segurança',
  manager_query: 'Gerenciadora para cadastro / consulta',
  manager_tracker: 'Gerenciadora para rastreamento',
  manager_mirror: 'Espelhamento logístico',
  logistic_sm: 'Gerenciadora de risco para sm logística',
};

export function requiredObjectToArray(requiredObj) {
  const toArray = Object.entries(requiredObj).map(([key, value]) => {
    return { code: key, status: value };
  });

  return toArray;
}

export function extractRequiredFields(rangeData) {
  const fields = {};

  fields.age_traction_max = !!rangeData?.age_traction_max;
  fields.min_time_antt = !!rangeData?.min_time_antt;
  fields.age_implement_max = !!rangeData?.age_implement_max;
  fields.driver_travel_min = !!rangeData?.driver_travel_min;

  if (rangeData?.groups?.length > 0) {
    rangeData.groups.forEach(group => {
      fields[group.code] = !!group.items;
    });
  }

  if (rangeData?.equipments_groups?.length > 0) {
    fields.monitoring_groups = true;
  }

  return fields;
}

export function validateDriverWithPolicy(
  loadTravelDriverTravelsQuantity,
  newRangeDriverMinTravel
) {
  if (newRangeDriverMinTravel === 0) {
    return true;
  }
  return loadTravelDriverTravelsQuantity >= newRangeDriverMinTravel;
}

function getYearDiff(year) {
  const currentDate = moment().startOf('year');
  const tractionYears = moment().startOf('year').set('year', year);
  return currentDate.diff(tractionYears, 'years');
}

export function validateVehicleWithPolicy(
  loadTravelVehicleYear,
  newRangeVehicleMaxAge,
  alreadyValidated
) {
  if (newRangeVehicleMaxAge === 0 || alreadyValidated) {
    return true;
  }
  return getYearDiff(loadTravelVehicleYear) <= newRangeVehicleMaxAge;
}

export function buildRangeFieldsMessage(newFields) {
  const newFieldsToArray = requiredObjectToArray(newFields)
    .filter(item => item.status)
    ?.map(item => item.code);

  return newFieldsToArray
    .map(item => rangeFieldsSlugMapper[item])
    .filter(item => item)
    .join(', ');
}

export function appendToMessage(condition, label, message) {
  let newMessage = message;
  if (condition) {
    newMessage += `, ${label}`;
    return newMessage;
  }
  return newMessage;
}
