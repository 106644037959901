import { createTheme } from '@material-ui/core/styles';
import newTheme from 'v3/theme';

const theme = createTheme({
  text: {
    sizes: {
      title: '32px',
      subtitle: '24px',
      header: '18px',
      body: '16px',
      label: '12px',
      little: '10px',
    },
  },
  colors: {
    primary: '#1C4B75',
    secondary: '#7A8994',
    secondary_light: '#D8DDE0',
    background: '#F1F4F5',
    error_light: '#FFF3F3',
    error: '#F55753',
    danger: '#F44336',
    success: '#00BC97',
    success_light: '#F2FCFA',
    warning: '#fed76e',
    orange: '#FF9800',
    gray: '#707070',
  },
  borderRadius: {
    input: '3px',
    button: '20px',
  },
  shadow: '0 1px 1px rgba(0, 0, 0, 0.16)',
  newTheme,
});

export default theme;
