import React, { useState } from 'react';
import { Accordion as StyledAccordion, Button, Text } from './styles';
import Card from '../Card';

export default function AccordionComponent({
  title, // Título do Accordion
  children, // Texto que aparece quando o accordion é aberto
  closedText = 'Visualizar', // Texto que aparece em qualquer momento para ser clicado
  closeButtonText = 'Fechar', // Texto no botão para fechar
  loading,
  buttonLoading = false,
  buttonProps = { variant: 'primary', outlined: true },
  cardProps,
  trigger = () => {},
  ...props
}) {
  const [state, setState] = useState(true);
  return (
    <StyledAccordion {...props}>
      <Card
        {...cardProps}
        header={
          <>
            <Text>{title}</Text>

            <StyledAccordion.Toggle
              loading={buttonLoading}
              onClick={() => {
                setState(!state);
                if (state) trigger();
              }}
              as={Button}
              eventKey="0"
              {...buttonProps}
            >
              {state ? closedText : closeButtonText}
            </StyledAccordion.Toggle>
          </>
        }
      >
        <StyledAccordion.Collapse eventKey="0">
          <div>{children}</div>
        </StyledAccordion.Collapse>
      </Card>
    </StyledAccordion>
  );
}
