import api from ".";

export async function fetchCountries(setCountries) {
    try {
      const { data } = await api.get('countries');
      setCountries(data);
    } catch (err) {
      setCountries([]);
    }
}
