/* eslint-disable no-extra-boolean-cast */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import useQuery from 'hooks/useQuery';

import { validatePermission } from 'actions/index';
import api from 'services/api';

import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import ApplicationLayout from 'v3/layouts/Application';
import InfiniteScroll from 'v3/components/InfiniteScroll';
import { useSnackbar } from 'v3/components/Snackbar';
import BennerButton from 'v3/components/BennerButton';

import Filter from './Filter';
import Card from './Card';
import { usePermission } from 'hooks';

function List() {
  const snackbar = useSnackbar();
  const query = useQuery();

  const [loading, setLoading] = useState(false);
  const [timeValue, setTimeValue] = useState(500);
  const [clients, setClients] = useState([]);
  const [pagination, setPagination] = useState({
    lastPage: 1,
    page: 1,
    total: 0,
  });

  const hasPermissionToCreate = validatePermission('CADASTRAR_CLIENTES');
  const hasPermissionToImportFromBenner = validatePermission(
    'IMPORTAR_CLIENTE_BENNER'
  );

  usePermission('VISUALIZAR_CLIENTES', { redirect: true });

  async function fetchClients(page = 1) {
    if (page === 1) {
      setLoading(true);
    }
    try {
      const response = await api.get(`v3/client?${query}&page=${page}`);

      const { data, ...rest } = response.data;

      setPagination(rest);

      setClients(oldClients => {
        if (page === 1) {
          return data;
        }

        return [...oldClients, ...data];
      });
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        'Algo deu errado, tentar novamente mais tarde';
      snackbar.show(<Text>{message}</Text>, {
        type: 'error',
      });
    } finally {
      setTimeout(() => {
        setLoading(false);
        setTimeValue(0);
      }, timeValue);
    }
  }

  function renderItem(client) {
    return <Card client={client} />;
  }

  useEffect(() => {
    function onPressedKey(e) {
      if (e.key === 'Enter') {
        fetchClients();
      }
    }

    window.addEventListener('keyup', onPressedKey);

    return () => {
      window.removeEventListener('keyup', onPressedKey);
    };
  });

  return (
    <ApplicationLayout
      title="Clientes"
      RightComponent={
        <>
          {hasPermissionToImportFromBenner ? (
            <BennerButton
              endpoint={'clients/importFromBenner'}
              title={'Importar Cliente'}
              label={'CPF/CNPJ'}
              field={'cpfCnpj'}
              redirectTo={'/clientes'}
              itemToImport={null}
              items={null}
            />
          ) : null}

          {hasPermissionToCreate && (
            <>
              <Link to="/clientes/cadastrar">
                <Button variant="primary">
                  <Text weight={500} color="white" type="regular">
                    Novo cliente
                  </Text>
                </Button>
              </Link>
            </>
          )}
        </>
      }
    >
      <Filter onChange={fetchClients} />
      <InfiniteScroll
        loading={loading}
        data={clients}
        renderItem={renderItem}
        onEndReach={() => {
          fetchClients(pagination.page + 1);
        }}
        hasMore={pagination.page < pagination.lastPage}
        scrollThreshold={0.7}
      />
    </ApplicationLayout>
  );
}

export default List;
