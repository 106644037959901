import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { FaUnlink } from 'react-icons/fa';

import {
  useEffectThrottled,
  useModal,
  usePagination,
  useMemoThrottled,
} from 'hooks';

import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import Fallback from 'v3/components/Fallback';
import Card from 'v3/components/Card';
import DataTable from 'v3/components/DataTable';
import { useSnackbar } from 'v3/components/Snackbar';

import api from 'services/api';

import theme from 'v3/theme';
import Modal from '../Modals/AttachedDrivers';

import { useShippingCompany } from '../context';

export default function AttachedDrivers() {
  const shippingCompany = useShippingCompany();
  const params = useParams();
  const snackbar = useSnackbar();
  const modal = useModal();

  const [isFetching, setFetching] = useState(false);
  const [fetched, setFetched] = useState(false);

  const isEditing = useMemo(() => !!params?.id, [params]);

  const onManualSearch = useCallback(
    (search, item) =>
      item?.driver?.cgccpf?.includes(search || '') ||
      item?.driver?.social_name
        ?.toLowerCase()
        ?.includes(search?.toLowerCase() || ''),
    []
  );

  const shippingDrivers = useMemo(() => {
    if (shippingCompany.data?.shippingDrivers) {
      return shippingCompany.data.shippingDrivers;
    }

    return [];
  }, [shippingCompany.data?.shippingDrivers]);

  const pagination = usePagination({
    data: shippingDrivers,
    manual: !isEditing,
    onManualSearch,
  });

  async function fetchShippingCompanyDrivers(
    currentPage = pagination.currentPage
  ) {
    try {
      setFetching(true);
      const response = await api.get(
        `v3/shipping-company/${params.id}/all-drivers`,
        {
          params: {
            search: pagination.search,
            perPage: pagination.perPage,
            page: currentPage,
          },
        }
      );
      pagination.setPerPage(response.data.perPage);
      pagination.setLastPage(response.data.lastPage);
      pagination.setTotal(response.data.total);

      const assertCurrentPage =
        response.lastPage && response.lastPage < currentPage
          ? response.lastPage
          : currentPage;

      pagination.setCurrentPage(assertCurrentPage || 1);

      shippingCompany.setData({ shippingDrivers: response.data.data });
      setFetched(true);
    } catch (error) {
      snackbar.show(
        <Text> Não foi possível listar os motoristas vinculados.</Text>,
        { type: 'error' }
      );
    } finally {
      setFetching(false);
    }
  }
  async function detachDriver(id) {
    if (params.id) {
      try {
        await api.delete(`v3/shipping-company/${params.id}/drivers/${id}`);

        const isLastItem = pagination.data.length === 1;

        fetchShippingCompanyDrivers(
          isLastItem ? pagination.currentPage - 1 : pagination.currentPage
        );
      } catch (ex) {
        snackbar.show(
          <Text>Não foi possível desvincular esse motorista.</Text>,
          {
            type: 'error',
          }
        );
      }
    } else
      shippingCompany.setData({
        shippingDrivers: shippingCompany.data?.shippingDrivers?.filter(
          item => item.driver?.id !== id
        ),
      });
  }

  useEffectThrottled(
    () => {
      if (isEditing) {
        fetchShippingCompanyDrivers();
      }
    },
    [isEditing, pagination.perPage, pagination.search, pagination.currentPage],
    300
  );

  const fallback = useMemoThrottled(
    () => {
      const defaultCondition =
        pagination.data.length === 0 &&
        pagination.currentPage === 1 &&
        !pagination.search;

      if (isEditing) {
        return fetched && defaultCondition;
      }

      return defaultCondition;
    },
    [pagination.data, pagination.currentPage, pagination.search, fetched],
    false
  );

  return (
    <>
      <Modal
        fetchShippingCompanyDrivers={fetchShippingCompanyDrivers}
        isOpen={modal.isOpen}
        onClose={() => {
          modal.close();
        }}
      />

      <Card
        loading={isFetching && !fetched}
        header={
          <Text type="header" color="dark">
            Motoristas vinculados
          </Text>
        }
        HeaderRightComponent={
          !fallback && (
            <Button
              onClick={() => {
                modal.open();
              }}
              className="py-2"
            >
              <Text type="regular" weight="500" color="white">
                Adicionar
              </Text>
            </Button>
          )
        }
      >
        <Fallback
          fall={fallback}
          component={
            <Col xs={12} className="align-items-center d-flex flex-column my-2">
              <Text color={theme.colors.dark} type="regular">
                Não há motoristas vinculados nessa transportadora.
              </Text>
              <Button
                className="mt-4 py-2"
                onClick={() => {
                  modal.open();
                }}
              >
                <Text type="regular" weight="500" color="white">
                  Adicionar
                </Text>
              </Button>
            </Col>
          }
        >
          <Row className="form">
            <Col md={12}>
              <DataTable
                data={pagination.data}
                total={pagination.total}
                pages={pagination.lastPage}
                onPageChanged={pagination.setCurrentPage}
                onSearchChanged={pagination.setSearch}
                onPerPageChanged={pagination.setPerPage}
                loading={isFetching}
                searchPlaceholder="Nome ou CPF"
                columns={[
                  {
                    name: 'Motorista',
                    selector: row =>
                      row?.driver?.social_name || 'Não informado',
                  },
                  {
                    name: 'CPF',
                    selector: row => row?.driver?.cgccpf || 'Não informado',
                  },
                  {
                    name: 'Telefone',
                    selector: row => row?.driver?.phone || 'Não informado',
                  },

                  {
                    name: 'Ação',
                    render: row => (
                      <Button
                        variant="error"
                        size="sm"
                        className="mx-1"
                        onClick={() => detachDriver(row.driver?.id)}
                      >
                        <>
                          <FaUnlink />
                        </>
                      </Button>
                    ),
                  },
                ]}
              />
            </Col>
          </Row>
        </Fallback>
      </Card>
    </>
  );
}
