import React, { useContext } from 'react';
import { Button, Text } from 'v3/components';
import { Row } from 'react-bootstrap';
import { useTravel } from 'v3/pages/Travel';
import { validatePermission } from 'actions';
import { BlockedTravelContext } from 'contexts/BlockedTravelProvider';
import {
  APPROVED_GRO_ANALYSIS_SLUG,
  UNDER_GRO_ANALYSIS_SLUG,
} from 'v3/utils/constants';
import { FooterButtonsContainer } from '../../styles';
import { FooterDetails } from '../../components/FooterDetails';
import { GRORiskManagerDetails } from './policyRiskManagerDetails';
import { HeaderDetails } from '../../components/HeaderDetails';
import { useRiskManager } from '../../context';

export function GRORiskManagerDetailsCard() {
  const { feedModal, manualValidationModal, hasEditPermissionRiskManager } =
    useRiskManager();
  const { isTravelBlocked } = useContext(BlockedTravelContext);
  const travel = useTravel();
  const hasPermissionToEdit = validatePermission('CADASTRAR_VIAGEM_LOG');
  const hasPermissionToEditWhileAnalysis = validatePermission(
    'EDITAR_CAMPOS_EM_VALIDACAO_GR'
  );

  return (
    <>
      <Row>
        <HeaderDetails />
        <GRORiskManagerDetails />
        <FooterDetails />
        <FooterButtonsContainer>
          {hasEditPermissionRiskManager ? (
            <>
              <Button
                variant="secondary"
                onClick={() => feedModal.open()}
                disabled={
                  !travel.data?.driver ||
                  !travel.data?.vehicle?.[0] ||
                  !hasPermissionToEdit ||
                  isTravelBlocked
                }
              >
                <Text type="regular" weight="500">
                  Incluir Feed
                </Text>
              </Button>

              <Button
                disabled={
                  (travel?.groAnalysisStatus === UNDER_GRO_ANALYSIS_SLUG &&
                    !hasPermissionToEditWhileAnalysis) ||
                  travel?.groAnalysisStatus === APPROVED_GRO_ANALYSIS_SLUG
                }
                title={
                  travel?.groAnalysisStatus === UNDER_GRO_ANALYSIS_SLUG &&
                  !hasPermissionToEditWhileAnalysis
                    ? 'Não é possível editar campos enquanto GR estiver em análise'
                    : travel?.groAnalysisStatus === APPROVED_GRO_ANALYSIS_SLUG
                    ? 'Não é possível realizar validações manuais após aprovação do GRO'
                    : undefined
                }
                variant="secondary"
                onClick={() => manualValidationModal.open()}
              >
                <Text type="regular" weight="500">
                  Validações manuais
                </Text>
              </Button>
            </>
          ) : null}
        </FooterButtonsContainer>
      </Row>
    </>
  );
}
