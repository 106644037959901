/* eslint-disable import/no-unresolved */
import React from 'react';
import api from 'services/api';
import Text from 'v3/components/Text';

function showSnackbar(snackbar, text, options) {
  snackbar.show(<Text> {text} </Text>, options);
}

export async function getIssuers({
  name,
  document,
  page = 1,
  setIssuers,
  setLoading,
  setPagination,
  snackbar,
}) {
  if (page === 1) setLoading(true);
  try {
    let filter = name ? `name=${name}` : '';
    filter += document ? `&document=${document}` : '';
    const response = await api.get(`issuers?${filter}&page=${page}`);
    const { data, ...rest } = response.data;

    setPagination(rest);
    setIssuers(oldIssuers => {
      if (page === 1) {
        return data;
      }
      return [...oldIssuers, ...data];
    });
  } catch (error) {
    showSnackbar(snackbar, `Erro ao carregar emissores`, {
      type: 'error',
    });
  } finally {
    setLoading(false);
  }
}
