import styled, { css } from 'styled-components';

const StyledButton = styled.button`
  background-color: ${({ theme, color }) => theme.colors[color]};

  border-radius: ${({ theme }) => theme.borderRadius.button};

  cursor: pointer;

  color: #fff;
  font-size: 0.875rem;
  text-transform: uppercase;

  padding: 7px 25px;

  /*min-width: 120px;*/

  border: 2px solid ${({ theme, color }) => theme.colors[color]};

  box-shadow: ${({ theme }) => theme.shadow};

  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.8;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;

      opacity: 0.7;

      &:hover {
        opacity: 0.7;
      }
    `}

  ${({ outline }) =>
    outline &&
    css`
      background-color: transparent;

      color: ${({ theme }) => theme.colors.secondary};
      border: 2px solid ${({ theme, color }) => theme.colors.secondary};
      transition: background-color 0.3s ease;

      &:hover {
        background-color: ${({ theme, color }) => theme.colors[color]}11;
      }
    `}
`;

export default StyledButton;
