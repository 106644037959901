import React, { forwardRef, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import SignatureCanvas from 'react-signature-canvas';
import Button from '../Button';
import Text from '../Text';

const Signature = forwardRef(({ signature }, ref) => {
  const undoCanvas = () => {
    if (ref?.current?.toData && ref?.current?.fromData) {
      const data = ref.current.toData();
      data.pop();
      ref.current.fromData(data);
    }
  };

  const cleanCanvas = () => {
    if (ref?.current?.clear) {
      ref.current.clear();
    }
  };

  const drawCanvas = () => {
    ref.current.fromDataURL(signature, { width: 500, height: 200 });
    const data = ref.current.toData();
    ref.current.fromData(data);
  };

  useEffect(() => {
    if (ref?.current?.fromDataURL && ref?.current?.fromData && signature) {
      drawCanvas();
    }
  }, [signature]);

  if (!ref) return null;

  return (
    <Row>
      <Col xs={12} className="d-flex flex-column align-items-center">
        <SignatureCanvas
          ref={ref}
          canvasProps={{
            width: 500,
            height: 200,
            className: 'sigCanvas',
          }}
        />
      </Col>
      <Col
        xs={12}
        style={{ display: 'flex', gap: 8, justifyContent: 'center' }}
      >
        <Button onClick={undoCanvas} className="my-2">
          <Text type="regular" weight="500" color="white">
            Desfazer
          </Text>
        </Button>
        <Button variant="danger" onClick={cleanCanvas} className="my-2">
          <Text type="regular" weight="500" color="white">
            Limpar
          </Text>
        </Button>
      </Col>
    </Row>
  );
});

export default Signature;
