import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { validatePermission } from 'actions';

import ApplicationLayout from 'v3/layouts/Application';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';

import Data from './Data';
import * as Styled from './style';

import { useAggregations } from '../shared/context';

export function AggregationDetails() {
  const history = useHistory();
  const aggregation = useAggregations();
  const [saveContainerOffset, setSaveContainerOffset] = useState(0);
  const [hasPermissionToEdit, setHasPermissionToEdit] = useState(
    validatePermission('EDITAR_OFERTAS_AGREGAMENTOS')
  );

  useEffect(() => {
    if (!hasPermissionToEdit) {
      aggregation.showSnackbar(
        'Você não tem permissão para editar essa oferta',
        {
          type: 'error',
        }
      );
      history.push('/');
    }
  }, [hasPermissionToEdit]);

  return (
    <ApplicationLayout
      title="Ofertas de Agregamentos"
      RightComponent={
        <Styled.SaveContainer style={{ right: saveContainerOffset }}>
          <Button
            variant="secondary"
            onClick={() => history.push('/agregamentos')}
          >
            <Text type="regular" color="dark" weight="semiBold">
              Voltar
            </Text>
          </Button>

          <Button onClick={() => history.push('/agregamentos/cadastrar')}>
            <Text type="regular" color="white" weight="semiBold">
              Novo agregamento
            </Text>
          </Button>
        </Styled.SaveContainer>
      }
    >
      <Row style={{ display: 'flex', justifyContent: 'center' }}>
        <Col md={6}>
          <Data />
        </Col>
      </Row>
    </ApplicationLayout>
  );
}
