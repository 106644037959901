import styled, { css } from 'styled-components';
import Button from 'react-bootstrap/Button';

import theme from 'v3/theme';

import Text from '../Text';

export const Container = styled.div`
  text-align: center;

  display: flex;
  align-items: center;
  flex-direction: row;
  border-radius: 5px 5px 5px 5px;
`;

export const RightButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  border-radius: 0px 5px 5px 0px;

  background-color: #fff !important;
  border: 1px solid #8f8f9b !important;
  border-left-color: #fff !important;

  ${props =>
    !props.active &&
    css`
      border: 1px solid ${theme.colors.error} !important;
      background-color: ${theme.colors.error} !important;
      box-shadow: none !important;
      ${RightText} {
        color: #fff;
      }
    `}
`;

export const LeftButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  border-radius: 5px 0px 0px 5px;

  background-color: #fff !important;
  border: 1px solid #8f8f9b !important;
  border-right-color: #fff !important;

  ${props =>
    props.active &&
    css`
      border: 1px solid ${theme.colors.success} !important;
      background-color: ${theme.colors.success} !important;
      box-shadow: none !important;
      ${LeftText} {
        color: #fff;
      }
    `}
`;

export const RightText = styled(Text)`
  color: #494950;
`;

export const LeftText = styled(Text)`
  color: #494950;
`;
