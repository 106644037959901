import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import ApplicationLayout from 'v3/layouts/Application';
import {
  BadgeActive,
  Button,
  Card,
  DataTable,
  Fallback,
  Text,
} from 'v3/components';
import Space from 'v3/components/Space';
import { QUESTION_TYPES_ALLOWED_TO_HAVE_ANSWER_OPTION } from '../shared/utils';
import { validatePermission } from 'actions';
import { EditQuestion } from './Edit';
import theme from 'v3/theme';
import { FaArrowDown, FaArrowUp, FaExclamationCircle } from 'react-icons/fa';
import { formatDateTime } from 'v3/utils/formatter';
import {
  LogItemContainer,
  LogItemContent,
  LogItemHeader,
  LogsContainer,
} from './styles';
import { cooperplaceApi } from 'services/api';

export function QuestionDetails() {
  const params = useParams();
  const [toggleLogs, setToggleLogs] = useState({});
  const [editing, setEditing] = useState(false);
  const [data, setData] = useState({ values: [], subQuestion: { values: [] } });
  const [loading, setLoading] = useState(false);
  const [fetchingQuestionError, setFetchingQuestionError] = useState({
    error: false,
    message: '',
  });
  const history = useHistory();
  const hasPermissionToCreateQuestion = validatePermission(
    'CADASTRAR_PERGUNTAS'
  );
  const processedParentAnswers = data?.values?.map(item => {
    return {
      id: item?.id,
      order: item?.order,
      questionId: item?.questionId,
      description: item?.description,
      triggerOccurrence:
        data?.questionOccurrenceValues?.filter(
          sub => sub?.questionValueId === item?.id
        )?.length > 0,
      triggerSubQuestion:
        data?.subQuestion?.subValues?.filter(
          sub => sub?.questionValueId === item?.id
        )?.length > 0,
    };
  });
  const processedChildAnswers = data?.subQuestion?.values?.map(item => {
    return {
      id: item?.id,
      order: item?.order,
      questionId: item?.questionId,
      description: item?.description,
      triggerOccurrence:
        data?.subQuestion?.questionOccurrenceValues?.filter(
          sub => sub?.questionValueId === item?.id
        )?.length > 0,
    };
  });

  useEffect(() => {
    if (params?.id) {
      fetchQuestion(params?.id);
    }
  }, [params?.id]);

  useEffect(() => {
    if (!loading && data?.logs) {
      const toggleStatus = data?.logs?.map((_, idx) => {
        return false;
      });

      setToggleLogs([...toggleStatus]);
    }
  }, [data, loading]);

  function handleToggleLog(index) {
    const updated = toggleLogs?.map((_, idx) => {
      if (idx === index) {
        return !toggleLogs[idx];
      } else return toggleLogs[idx];
    });
    setToggleLogs(updated);
  }

  async function fetchQuestion(id) {
    try {
      setLoading(true);
      const { data } = await cooperplaceApi.get(`questions/${id}`);
      setData(data);
    } catch (error) {
      let message =
        error?.response?.data?.error ||
        'Problema ao recuperar pergunta. Tente mais tarde';
      setFetchingQuestionError({ error: true }, message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <ApplicationLayout title="Detalhes da pergunta">
      {editing ? (
        <EditQuestion
          data={data}
          editing={editing}
          setEditing={setEditing}
          fetchQuestion={fetchQuestion}
        />
      ) : fetchingQuestionError?.error && !loading && !editing ? (
        <Card>
          <div className="text-center">
            <FaExclamationCircle
              size={26}
              color="#F64E60"
              className="mr-2 mb-1"
            />
            <Text type="header" color="error">
              {fetchingQuestionError?.message}
            </Text>
          </div>
        </Card>
      ) : (
        <>
          <Card
            header={
              <Text color="dark" type="header">
                Dados da pergunta
              </Text>
            }
            HeaderRightComponent={
              <Space x="0.5rem">
                <Button variant={'secondary'} onClick={() => history.goBack()}>
                  <Text>Voltar</Text>
                </Button>
                {hasPermissionToCreateQuestion && (
                  <Button onClick={() => setEditing(true)}>
                    <Text>Editar</Text>
                  </Button>
                )}
              </Space>
            }
            loading={loading}
          >
            <Row>
              <Col md={12}>
                <Text type="regular" weight={500} color="dark">
                  Descrição
                </Text>
                <Text type="body" color="gray" as="p" className="mt-2">
                  {data?.question}
                </Text>
              </Col>
              <Col md={4} sm={12}>
                <Text type="regular" weight={500} color="dark">
                  Tipo de pergunta
                </Text>
                <Text type="body" color="gray" as="p" className="mt-2">
                  {data?.type?.name}
                </Text>
              </Col>
              <Col md={4} sm={12}>
                <Text type="regular" weight={500} color="dark">
                  Exige anexo na resposta
                </Text>
                <Text type="body" color="gray" as="p" className="mt-2">
                  {data?.needAttachment ? 'Sim' : 'Não'}
                </Text>
              </Col>
              <Col md={4} sm={12}>
                <Text type="regular" weight={500} color="dark">
                  Status da pergunta
                </Text>
                <Text type="body" color="gray" as="p" className="mt-2">
                  <BadgeActive
                    pill
                    variant={
                      data?.active
                        ? 'success'
                        : data?.active === false
                        ? 'danger'
                        : 'success'
                    }
                  >
                    <Text type="body">
                      {data?.active ? 'Ativa' : 'Inativa'}
                    </Text>
                  </BadgeActive>
                </Text>
              </Col>
              <Col md={4} sm={12}>
                <Text type="regular" weight={500} color="dark">
                  Gera ocorrência?
                </Text>
                <Text type="body" color="gray" as="p" className="mt-2">
                  {data?.createOccurrence ? 'Sim' : 'Não'}
                </Text>
              </Col>
              {data?.createOccurrence && (
                <Col md={4} sm={12}>
                  <Text type="regular" weight={500} color="dark">
                    Setor responsável
                  </Text>
                  <Text type="body" color="gray" as="p" className="mt-2">
                    {data?.occurrenceGroup?.name}
                  </Text>
                </Col>
              )}
              <Col md={4} sm={12}>
                <Text type="regular" weight={500} color="dark">
                  Possui subpergunta?
                </Text>
                <Text type="body" color="gray" as="p" className="mt-2">
                  {data?.subQuestion?.id ? 'Sim' : 'Não'}
                </Text>
              </Col>
              {QUESTION_TYPES_ALLOWED_TO_HAVE_ANSWER_OPTION?.includes(
                data?.type?.slug
              ) && (
                <Col md={12}>
                  <Text type="regular" weight={500} color="dark">
                    Possíveis respostas
                  </Text>
                  <DataTable
                    withFilterOption={false}
                    withPagination={false}
                    columns={[
                      {
                        name: 'Opção',
                        selector: row => (
                          <Text title={row?.description} type="body">
                            {row?.description}
                          </Text>
                        ),
                      },
                      {
                        name: 'Gera Ocorrência?',
                        title: 'Opção de resposta dispara uma ocorrência',
                        selector: row => (
                          <Text
                            title={`Opção de resposta ${
                              row?.triggerOccurrence ? '' : 'não '
                            }dispara ocorrência`}
                            type="body"
                          >
                            {row?.triggerOccurrence ? 'Sim' : 'Não'}
                          </Text>
                        ),
                      },
                      {
                        name: 'Gera Subpergunta?',
                        title: 'Opção de resposta associada à subpergunta',
                        selector: row => (
                          <Text
                            title={`Opção de resposta ${
                              row?.triggerSubQuestion ? '' : 'não'
                            } dispara subpergunta`}
                            type="body"
                          >
                            {row?.triggerSubQuestion ? 'Sim' : 'Não'}
                          </Text>
                        ),
                      },
                    ]}
                    data={processedParentAnswers}
                  />
                </Col>
              )}
            </Row>
          </Card>
          {data?.subQuestion && (
            <Card
              header={
                <Text color="dark" type="header">
                  Dados Subpergunta
                </Text>
              }
            >
              <Row>
                <Col md={12}>
                  <Text type="regular" weight={500} color="dark">
                    Descrição
                  </Text>
                  <Text type="body" color="gray" as="p" className="mt-2">
                    {data?.subQuestion?.question}
                  </Text>
                </Col>
                <Col md={4} sm={12}>
                  <Text type="regular" weight={500} color="dark">
                    Tipo de pergunta
                  </Text>
                  <Text type="body" color="gray" as="p" className="mt-2">
                    {data?.subQuestion?.type?.name}
                  </Text>
                </Col>
                <Col md={4} sm={12}>
                  <Text type="regular" weight={500} color="dark">
                    Status da pergunta
                  </Text>
                  <Text type="body" color="gray" as="p" className="mt-2">
                    <BadgeActive
                      pill
                      variant={data?.subQuestion?.active ? 'success' : 'danger'}
                    >
                      <Text type="body">
                        {data?.subQuestion?.active ? 'Ativa' : 'Inativa'}
                      </Text>
                    </BadgeActive>
                  </Text>
                </Col>
                <Col md={4} sm={12}>
                  <Text type="regular" weight={500} color="dark">
                    Gera ocorrência?
                  </Text>
                  <Text type="body" color="gray" as="p" className="mt-2">
                    {data?.subQuestion?.createOccurrence ? 'Sim' : 'Não'}
                  </Text>
                </Col>
                {data?.subQuestion?.createOccurrence && (
                  <Col md={4} sm={12}>
                    <Text type="regular" weight={500} color="dark">
                      Setor responsável
                    </Text>
                    <Text type="body" color="gray" as="p" className="mt-2">
                      {data?.subQuestion?.occurrenceSector?.name}
                    </Text>
                  </Col>
                )}
                {QUESTION_TYPES_ALLOWED_TO_HAVE_ANSWER_OPTION?.includes(
                  data?.subQuestion?.type?.slug
                ) && (
                  <Col md={12}>
                    <Text type="regular" weight={500} color="dark">
                      Possíveis respostas
                    </Text>
                    <DataTable
                      withFilterOption={false}
                      withPagination={false}
                      columns={[
                        {
                          name: 'Opção',
                          selector: row => (
                            <Text title={row?.description} type="body">
                              {row?.description}
                            </Text>
                          ),
                        },
                        {
                          name: 'Gera Ocorrência?',
                          title: 'Opção de resposta dispara uma ocorrência',
                          selector: row => (
                            <Text
                              title={`Opção de resposta ${
                                row?.triggerOccurrence ? '' : 'não '
                              }dispara ocorrência`}
                              type="body"
                            >
                              {row?.triggerOccurrence ? 'Sim' : 'Não'}
                            </Text>
                          ),
                        },
                      ]}
                      data={processedChildAnswers}
                    />
                  </Col>
                )}
              </Row>
            </Card>
          )}
          <Card
            header={
              <Text color="dark" type="header">
                Auditoria
              </Text>
            }
          >
            <Fallback
              component={
                <Col
                  xs={12}
                  className="align-items-center d-flex flex-column my-2"
                >
                  <Text color={theme.colors.gray}>
                    Não há registros de alterações para essa pergunta.
                  </Text>
                </Col>
              }
              fall={!data?.logs?.length === 0}
            >
              <LogsContainer>
                {data?.logs?.map((item, index) => {
                  return (
                    <LogItemContainer key={item.id}>
                      <LogItemHeader
                        onClick={() => handleToggleLog(index)}
                        title={
                          toggleLogs[index]
                            ? ''
                            : 'Clique para ver mais detalhes'
                        }
                      >
                        {toggleLogs[index] ? <FaArrowUp /> : <FaArrowDown />}
                        <Text>{formatDateTime(item?.createdAt)}</Text>
                        {' - '}
                        <Text>{`${item?.obs} `}</Text>
                      </LogItemHeader>
                      {toggleLogs[index] && (
                        <LogItemContent
                          color="dark"
                          as="p"
                          style={{ whiteSpace: 'pre' }}
                        >
                          {item?.description}
                        </LogItemContent>
                      )}
                    </LogItemContainer>
                  );
                })}
              </LogsContainer>
            </Fallback>
          </Card>
        </>
      )}
    </ApplicationLayout>
  );
}
