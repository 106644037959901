import { Col, Row } from 'react-bootstrap';
import Button from 'v3/components/Button';
import styled from 'styled-components';

export const RowStyled = styled(Row)`
  margin-top: 3%;
`;

export const ColStyled = styled(Col)`
display: flex;
justify-content: end;
margin-top: 2%;
`;

export const ButtonCancel = styled(Button)`
margin-right: 2%;
`;