import styled from 'styled-components';
import { Text } from 'v3/components';
import theme from 'v3/theme';

export const LogsContainer = styled.div`
  max-height: 400px;
  overflow-y: scroll;
  scroll-behavior: smooth;
  :not(:hover)::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
  :not(:hover)::-webkit-scrollbar-track {
    opacity: 0;
  }
  :not(:hover)::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
  }
  :hover::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
  :hover::-webkit-scrollbar-track {
    background: ${theme.colors.light_gray};
    opacity: 0.5;
  }
  :hover::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border-radius: 10px;
  }
`;

export const LogItemContainer = styled.div`
  margin: 1rem 0;
  border-bottom: 1px solid #f2f2f2;
`;
export const LogItemHeader = styled.header`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 0.5rem;

  cursor: pointer;
`;
export const LogItemContent = styled(Text)`
  padding: 0.5rem;
`;
