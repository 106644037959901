import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Row, Col } from 'react-bootstrap';

import { Select } from 'v3/components';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import Input from 'v3/components/Input';
import CurrencyInput from 'v3/components/Input/Currency';

import { FaTrash } from 'react-icons/fa';

import { chargingMethods } from 'v3/utils/chargingMethods';
import { useSnackbar } from 'v3/components/Snackbar';
import { useRoute } from './context';
import { currencyWithSymbol } from 'v3/utils/formatter';

export default function RouteCharge() {
  const route = useRoute();
  const params = useParams();
  const inputsRef = useRef([]);

  const snackbar = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [routeCurrencySymbol, setRouteCurrencySymbol] = useState('');

  useEffect(() => {
    if (route.data.currency) {
      let tempCurrency = route.data.currency.value || route.data.currency;
      setRouteCurrencySymbol(
        currencyWithSymbol.find(currency => currency.currency === tempCurrency)
          ?.symbol
      );
    }
  }, [route?.data?.currency]);

  useEffect(() => {
    if (!params.id) {
      route.setData({
        charge_type: null,
        charge_price: null,
        external_value: null,
        priceRanges: [
          {
            inicial_amount: null,
            final_amount: null,
            value: null,
            external_value: null,
          },
        ],
      });
    }
  }, []);

  useEffect(() => {
    switch (route.data?.charge_type?.value) {
      case 3:
        route.setData({
          charge_price:
            route.minimumFreightTableItem?.value * Number(route.data?.distance),
        });
        break;
      case 1:
        route.setData({ charge_price: route.minimumFreightTableItem?.value });
        break;
      default:
        route.setData({ charge_price: route.data?.charge_price });
        break;
    }
  }, [
    route.minimumFreightTableItem?.value,
    route.data?.charge_type,
    route.data?.distance,
  ]);

  async function handleRemovePriceRange(priceRangeIndex) {
    setLoading(true);
    try {
      const filteredPriceRanges = route.data.priceRanges?.filter(
        (_, index) => index !== priceRangeIndex
      );

      route.setData({
        priceRanges: filteredPriceRanges,
      });

      filteredPriceRanges.forEach((item, idx) => {
        if (!item) {
          inputsRef.current[idx].refs.input.value = null;
        }
      });
      setLoading(false);
    } catch (error) {
      snackbar.show(<Text>Erro ao deletar</Text>, { type: 'error' });
      setLoading(false);
    }
  }

  function handleAddPriceRange() {
    route.setData({
      priceRanges: [
        ...route.data?.priceRanges,
        {
          inicial_amount: null,
          final_amount: null,
          value: null,
          external_value: null,
        },
      ],
    });
  }

  function handleChangeValue(index, key, value) {
    if (value < 0) {
      snackbar.show(<Text>Não utilize valores negativos.</Text>, {
        type: 'error',
      });

      return;
    }
    route.setData({
      priceRanges: route.data.priceRanges.map((item, idx) => {
        if (index === idx) {
          return { ...item, [key]: value };
        }

        return item;
      }),
    });
  }

  return (
    <Row>
      <Col xs={12} className="mb-3">
        <Select
          label="Método de cobrança *"
          value={route?.data?.charge_type}
          onChange={value => {
            route.setData({ charge_type: value });
            route.setErrors({});
          }}
          getOptionLabel={option => option?.label}
          getOptionValue={option => option?.value}
          options={chargingMethods}
          error={route.errors?.['charge.charge_type']}
        />
      </Col>
      <>
        {route.data?.charge_type?.value === 2 ||
        route.data?.charge_type?.value === 5
          ? route?.data?.priceRanges?.map((_, index) => {
              return (
                <>
                  <Col xs={6} className="mt-2">
                    <Input
                      ref={ref => {
                        inputsRef.current[index] = ref;
                      }}
                      type="number"
                      label="De (ton)*"
                      value={route.data?.priceRanges[index].inicial_amount}
                      onChange={event => {
                        handleChangeValue(
                          index,
                          'inicial_amount',
                          event.target.value
                        );
                      }}
                      error={
                        route.errors?.[
                          `charge.priceRanges[${index}].inicial_amount`
                        ]
                      }
                    />
                  </Col>
                  <Col xs={6} className="mt-2">
                    <Input
                      ref={ref => {
                        inputsRef.current[index] = ref;
                      }}
                      type="number"
                      label="Até (ton)*"
                      value={route.data?.priceRanges[index].final_amount}
                      onChange={event => {
                        handleChangeValue(
                          index,
                          'final_amount',
                          event.target.value
                        );
                      }}
                      error={
                        route.errors?.[
                          `charge.priceRanges[${index}].final_amount`
                        ]
                      }
                    />
                  </Col>
                  <Col xs={6} className="mt-2">
                    <CurrencyInput
                      label={`Valor tomador (${routeCurrencySymbol}/ton) *`}
                      value={route.data?.priceRanges[index].value}
                      onChange={event => {
                        handleChangeValue(index, 'value', event.target.value);
                      }}
                      error={
                        route.errors?.[
                          `charge.priceRanges[${index}].value` ||
                            'charge.charge_price'
                        ]
                      }
                    />
                  </Col>
                  <Col xs={6} className="mt-2">
                    <CurrencyInput
                      label={`Valor terceiro (${routeCurrencySymbol}/ton) *`}
                      value={route.data?.priceRanges[index].external_value}
                      onChange={event =>
                        handleChangeValue(
                          index,
                          'external_value',
                          event.target.value
                        )
                      }
                      error={
                        route.errors?.[
                          `charge.priceRanges[${index}].external_value` ||
                            'charge.charge_price'
                        ]
                      }
                    />
                  </Col>
                  {route.data.priceRanges.length > 1 &&
                    route.data.priceRanges.length !== 1 && (
                      <Col xs={12} className="mb-4">
                        <Button
                          variant="error"
                          size="sm"
                          onClick={() => handleRemovePriceRange(index)}
                          className="mt-3"
                        >
                          <FaTrash />
                        </Button>
                      </Col>
                    )}
                  {route.data.priceRanges.length - 1 === index ? (
                    <Row className="mt-2 ml-2">
                      <Text
                        type="label"
                        clickable
                        color="light_blue"
                        onClick={handleAddPriceRange}
                      >
                        Adicionar uma faixa de preço
                      </Text>
                    </Row>
                  ) : null}
                </>
              );
            })
          : null}
        <div className="col-12 mt-2 text-center">
          <Text color="error" type="label" weight="500">
            {route.errors?.charge}
          </Text>
        </div>
      </>
      {route.data?.charge_type?.value === 1 && (
        <>
          <Col xs={4}>
            <Text type="label" color="#464E5F">
              Distância (Km)
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {`${route?.data?.distance} Km` || 'Não informado'}
            </Text>
          </Col>
          <Col xs={4}>
            <CurrencyInput
              label={`Valor tomador (${routeCurrencySymbol}/Km) *`}
              value={
                route.data?.charge_price || route.minimumFreightTableItem?.value
              }
              onChange={(_event, value) =>
                route.setData({ charge_price: value || undefined })
              }
              error={route.errors?.['charge.charge_price']}
            />
          </Col>
          <Col xs={4}>
            <CurrencyInput
              label={`Valor terceiro (${routeCurrencySymbol}/Km) *`}
              value={route.data?.external_value}
              onChange={(_event, value) =>
                route.setData({ external_value: value || undefined })
              }
              error={route.errors?.['charge.external_value']}
            />
          </Col>
        </>
      )}
      {route.data?.charge_type?.value === 3 && (
        <>
          <Col md={12}>
            <CurrencyInput
              label={`Valor tomador (${routeCurrencySymbol}) *`}
              value={
                route.data?.charge_price ||
                route.minimumFreightTableItem?.value *
                  Number(route.data?.distance)
              }
              onChange={(_event, value) =>
                route.setData({ charge_price: value || undefined })
              }
              error={route.errors?.['charge.charge_price']}
            />
          </Col>
          <Col md={12} className="mt-4">
            <CurrencyInput
              label={`Valor terceiro (${routeCurrencySymbol}) *`}
              value={route.data?.external_value}
              onChange={(_event, value) =>
                route.setData({ external_value: value || undefined })
              }
              error={route.errors?.['charge.external_value']}
            />
          </Col>
        </>
      )}
      {route.data?.charge_type?.value === 4 && (
        <>
          <Col md={12}>
            <CurrencyInput
              label={`Valor tomador (${routeCurrencySymbol}/m³) *`}
              value={route.data?.charge_price}
              onChange={(_event, value) =>
                route.setData({ charge_price: value || undefined })
              }
              error={route.errors?.['charge.charge_price']}
            />
          </Col>
          <Col md={12} className="mt-4">
            <CurrencyInput
              label={`Valor terceiro (${routeCurrencySymbol}/m³) *`}
              value={route.data?.external_value}
              onChange={(_event, value) =>
                route.setData({ external_value: value || undefined })
              }
              error={route.errors?.['charge.external_value']}
            />
          </Col>
        </>
      )}
      {route.data?.charge_type?.value === 6 && (
        <>
          <Col md={12}>
            <CurrencyInput
              label="Valor tomador %/mercadoria *"
              value={route.data?.charge_price}
              onChange={(_event, value) =>
                route.setData({ charge_price: value || undefined })
              }
              error={route.errors?.['charge.charge_price']}
            />
          </Col>
          <Col md={12} className="mt-4">
            <CurrencyInput
              label="Valor terceiro %/mercadoria *"
              value={route.data?.external_value}
              onChange={(_event, value) =>
                route.setData({ external_value: value || undefined })
              }
              error={route.errors?.['charge.external_value']}
            />
          </Col>
        </>
      )}
      {route.data?.charge_type?.value === 7 && (
        <>
          <Col md={12}>
            <CurrencyInput
              label={`Valor tomador (${routeCurrencySymbol}/entrega) *`}
              value={route.data?.charge_price}
              onChange={(_event, value) =>
                route.setData({ charge_price: value || undefined })
              }
              error={route.errors?.['charge.charge_price']}
            />
          </Col>
          <Col md={12} className="mt-4">
            <CurrencyInput
              label={`Valor terceiro (${routeCurrencySymbol}/entrega) *`}
              value={route.data?.external_value}
              onChange={(_event, value) =>
                route.setData({ external_value: value || undefined })
              }
              error={route.errors?.['charge.external_value']}
            />
          </Col>
        </>
      )}
      {route.data?.charge_type?.value === 8 && (
        <>
          <Col md={12}>
            <CurrencyInput
              label={`Valor tomador (${routeCurrencySymbol}/pallet) *`}
              value={route.data?.charge_price}
              onChange={(_event, value) =>
                route.setData({ charge_price: value || undefined })
              }
              error={route.errors?.['charge.charge_price']}
            />
          </Col>
          <Col md={12} className="mt-4">
            <CurrencyInput
              label={`Valor terceiro (${routeCurrencySymbol}/entrega) *`}
              value={route.data?.external_value}
              onChange={(_event, value) =>
                route.setData({ external_value: value || undefined })
              }
              error={route.errors?.['charge.external_value']}
            />
          </Col>
        </>
      )}
    </Row>
  );
}
