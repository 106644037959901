import React, { useState, useEffect } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
//import Card from '@material-ui/core/Card';

import Skeleton from '@material-ui/lab/Skeleton';

import Card from 'components/Card';

import axios from 'axios';
import moment from 'moment';
import config from 'config';

export default function Notificacoes() {
  const [read, setRead] = useState(true);
  const [loading, setLoading] = useState(true);
  const [notificationList, setNotificationList] = useState([]);

  useEffect(() => {
    listNotifications();
  }, []);

  function handleRead() {
    setRead(!read);
    listNotifications();
  }

  async function listNotifications() {
    setLoading(true);

    let filters = read ? '' : '&read=false';
    let response = await axios.get(
      config.server + 'user-notifications?page=' + 1 + filters
    );

    if (response.status === 200) {
      setNotificationList(response.data.data);
      setLoading(false);
    }
  }

  return (
    <>
      Listar lidos
      <Checkbox
        checked={read}
        onClick={() => handleRead()}
        value="read"
        inputProps={{
          'aria-label': 'primary checkbox',
        }}
      />
      {loading && (
        <>
          <Skeleton variant="rect" width="100%" height={118} />
          <br />
          <Skeleton variant="rect" width="100%" height={118} />
          <br />
          <Skeleton variant="rect" width="100%" height={118} />
          <br />
          <Skeleton variant="rect" width="100%" height={118} />
          <br />
          <Skeleton variant="rect" width="100%" height={118} />
          <br />
        </>
      )}
      {!loading &&
        notificationList &&
        notificationList.map((element, index) => {
          return (
            <Card key={index} href="#">
              <h5 className="mb-1">{element.title}</h5>
              <small>
                {moment(element.created_at).format('DD/MM/YYYY HH:mm')}
              </small>
            </Card>
          );
        })}
    </>
  );
}
