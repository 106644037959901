import moment from 'moment';

const is2019 = moment().subtract(1, 'year').format('YYYY') === '2019';

const yearlyOptions = [
  {
    label: moment().format('YYYY'),
    value: {
      start_date: moment().startOf('year').format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
    },
  },
  {
    label: moment().subtract(1, 'year').format('YYYY'),
    value: {
      start_date: moment()
        .subtract(1, 'year')
        .startOf('year')
        .format('YYYY-MM-DD'),
      end_date: moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'),
    },
  },
];

export const DateOptions = {
  actualDate: [
    {
      label: 'No mês',
      value: moment().startOf('month').format('YYYY-MM-DD'),
    },
    {
      label: 'Hoje',
      value: moment().startOf('day').format('YYYY-MM-DD'),
    },
  ],

  trimestral: [
    {
      label: 'Mês atual',
      value: {
        start_date: moment().startOf('month').format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
      },
    },
    {
      label: 'Últimos três meses',
      value: {
        start_date: moment()
          .subtract(3, 'months')
          .startOf('month')
          .format('YYYY-MM-DD'),
        end_date: moment()
          .subtract(1, 'months')
          .endOf('month')
          .format('YYYY-MM-DD'),
      },
    },
    {
      label: 'Últimos seis meses',
      value: {
        start_date: moment()
          .subtract(6, 'months')
          .startOf('month')
          .format('YYYY-MM-DD'),
        end_date: moment()
          .subtract(1, 'months')
          .endOf('month')
          .format('YYYY-MM-DD'),
      },
    },
  ],

  monthly: [
    {
      label: 'Janeiro',
      value: {
        start_date: moment().startOf('year').format('YYYY-MM-DD'),
        end_date: moment()
          .endOf('year')
          .subtract(11, 'month')
          .format('YYYY-MM-DD'),
      },
    },
    {
      label: 'Fevereiro',
      value: {
        start_date: moment()
          .startOf('year')
          .add(1, 'month')
          .format('YYYY-MM-DD'),
        end_date: moment()
          .endOf('year')
          .subtract(10, 'month')
          .format('YYYY-MM-DD'),
      },
    },
    {
      label: 'Março',
      value: {
        start_date: moment()
          .startOf('year')
          .add(2, 'month')
          .format('YYYY-MM-DD'),
        end_date: moment()
          .endOf('year')
          .subtract(9, 'month')
          .format('YYYY-MM-DD'),
      },
    },
    {
      label: 'Abril',
      value: {
        start_date: moment()
          .startOf('year')
          .add(3, 'month')
          .format('YYYY-MM-DD'),
        end_date: moment()
          .endOf('year')
          .subtract(8, 'month')
          .format('YYYY-MM-DD'),
      },
    },
    {
      label: 'Maio',
      value: {
        start_date: moment()
          .startOf('year')
          .add(4, 'month')
          .format('YYYY-MM-DD'),
        end_date: moment()
          .endOf('year')
          .subtract(7, 'month')
          .format('YYYY-MM-DD'),
      },
    },
    {
      label: 'Junho',
      value: {
        start_date: moment()
          .startOf('year')
          .add(5, 'month')
          .format('YYYY-MM-DD'),
        end_date: moment()
          .endOf('year')
          .subtract(6, 'month')
          .format('YYYY-MM-DD'),
      },
    },
    {
      label: 'Julho',
      value: {
        start_date: moment()
          .startOf('year')
          .add(6, 'month')
          .format('YYYY-MM-DD'),
        end_date: moment()
          .endOf('year')
          .subtract(5, 'month')
          .format('YYYY-MM-DD'),
      },
    },
    {
      label: 'Agosto',
      value: {
        start_date: moment()
          .startOf('year')
          .add(7, 'month')
          .format('YYYY-MM-DD'),
        end_date: moment()
          .endOf('year')
          .subtract(4, 'month')
          .format('YYYY-MM-DD'),
      },
    },
    {
      label: 'Setembro',
      value: {
        start_date: moment()
          .startOf('year')
          .add(8, 'month')
          .format('YYYY-MM-DD'),
        end_date: moment()
          .endOf('year')
          .subtract(3, 'month')
          .format('YYYY-MM-DD'),
      },
    },
    {
      label: 'Outubro',
      value: {
        start_date: moment()
          .startOf('year')
          .add(9, 'month')
          .format('YYYY-MM-DD'),
        end_date: moment()
          .endOf('year')
          .subtract(2, 'month')
          .format('YYYY-MM-DD'),
      },
    },
    {
      label: 'Novembro',
      value: {
        start_date: moment()
          .startOf('year')
          .add(10, 'month')
          .format('YYYY-MM-DD'),
        end_date: moment()
          .endOf('year')
          .subtract(1, 'month')
          .format('YYYY-MM-DD'),
      },
    },
    {
      label: 'Dezembro',
      value: {
        start_date: moment()
          .startOf('year')
          .add(11, 'month')
          .format('YYYY-MM-DD'),
        end_date: moment().endOf('year').format('YYYY-MM-DD'),
      },
    },
  ],

  yearly: is2019
    ? [
        ...yearlyOptions,
        {
          label: moment().subtract(2, 'year').format('YYYY'),
          value: {
            start_date: moment()
              .subtract(2, 'year')
              .startOf('year')
              .format('YYYY-MM-DD'),
            end_date: moment()
              .subtract(2, 'year')
              .endOf('year')
              .format('YYYY-MM-DD'),
          },
        },
      ]
    : yearlyOptions,
};
