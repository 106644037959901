import React, { forwardRef, useState} from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

import { handleDelete } from '../../controller';

const Transition = forwardRef(function Transition(props, ref) {
  return (
    <>
      <Slide direction="up" ref={ref} {...props} />
    </>
  );
});

export default function ConfirmDelete(props) {
const history = useHistory();
  const [open, setOpen] = useState(true);
  const handleUpdate = async () => {
    await handleDelete(
      props.id,
      props.snackbar 
    );
    props.setRemoveItem(false);
    setOpen(false);
    history.push('/blacklist', true);
  };

  const handleClose = () => {
    props.setRemoveItem(false);
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        <DialogTitle>Atenção!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Deseja realmente remover item da blacklist?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Não</Button>
          <Button onClick={handleUpdate}>Sim</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
