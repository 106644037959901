import React from 'react';
import Label from 'components/Label';
import { StyledTextField } from './styles';

function TextField({ name, label, error, multiline = 'true', ...props }) {
  return (
    <>
      <Label htmlFor={name}>{label}</Label>
      <StyledTextField
        id={name}
        name={name}
        rows={4}
        rowsMax={4}
        multiline={true}
        error={error}
        {...props}
      />
    </>
  );
}

export default TextField;
