import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useSnackbar } from 'v3/components/Snackbar';
import Text from 'v3/components/Text';
import { cooperplaceApi } from 'services/api';

import { usePermission } from 'hooks';
import Details from '../Details';
import Register from '../Register';
import { RasterBranchesProvider } from './context';

export default function RasterBranchesShared() {
  usePermission('GERENCIAR_RASTER_BRANCHES', {
    redirect: true,
  });
  const snackbar = useSnackbar();

  const [branch, setBranch] = useState({});
  const [isFetching, setFetching] = useState(false);
  const [errors, setErrors] = useState({});
  const [prevBranch, setPrevBranch] = useState({});
  const [rasterBranchesExternal, setRasterBranchesExternal] = useState([]);

  const params = useParams();

  function setData(data) {
    setBranch(prev => ({ ...prev, ...data }));
  }

  async function fetchRasterBranches() {
    try {
      const { data } = await cooperplaceApi.get(
        '/raster/branches/raster-branches'
      );
      const branches = data.branches || [];
      setRasterBranchesExternal(branches);
      return branches;
    } catch (err) {
      setRasterBranchesExternal([]);
      snackbar.show('Falha ao buscar filiais na raster', {
        type: 'error',
      });
      return [];
    }
  }

  async function fetchRasterBranch() {
    try {
      setFetching(true);
      const response = await cooperplaceApi.get(
        `/raster/branches/${params.id}`
      );

      const externalBranches = await fetchRasterBranches();

      const data = {
        ...response.data?.branch,
        code: externalBranches.find(
          b => parseInt(b.code, 10) === response.data?.branch?.code
        ),
      };

      setData(data);

      setPrevBranch(prev => ({
        ...prev,
        ...data,
      }));
    } catch (ex) {
      snackbar.show(<Text>Não foi possível carregar filial da raster</Text>, {
        type: 'error',
      });
    } finally {
      setFetching(false);
    }
  }

  useEffect(() => {
    if (params.id) {
      fetchRasterBranch();
    } else {
      fetchRasterBranches();
    }
  }, [params.id]);

  return (
    <RasterBranchesProvider
      value={{
        data: branch,
        rasterBranchesExternal,
        setData,
        isFetching,
        errors,
        setErrors,
        prevBranch,
        setPrevBranch,
      }}
    >
      {params.id ? <Details /> : <Register />}
    </RasterBranchesProvider>
  );
}
