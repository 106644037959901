import React, { useState } from 'react';

import { validatePermission } from 'actions/index';

import Input from 'components/Input';
import Button from 'components/Button';

import api from 'services/api';

export default function FormCadastroTag(props) {
  const [name, setName] = useState('');
  const [isValidNewOption, setIsValidNewOption] = useState(false);
  const [tagRegisterPermitted, setTagRegisterPermitted] = useState(
    validatePermission('CRIAR_TAGS')
  );

  async function saveTag() {
    try {
      const response = await api.post('tags', { name });

      if (response.status === 200) {
        setName('');
        props.setSnackbar({
          type: 'success',
          message: 'Tag cadastrada com sucesso.',
          display: true,
        });
        return response.data;
      }
    } catch (err) {
      props.setSnackbar({
        type: 'error',
        message: err.response.data.error,
        display: true,
      });

      return err.response;
    }
  }

  function handleTagInput(input) {
    if (input) {
      const inputFiltered = input
        .trimStart()
        .replace(/[^a-zA-Z0-9 ]/g, '')
        .toLowerCase();
      setName(inputFiltered);
      setIsValidNewOption(inputFiltered.trim().length >= 3);
    } else {
      setName('');
      setIsValidNewOption(false);
    }
  }

  return (
    <>
      <Input
        uppercase={1}
        label="Nome da tag"
        value={name}
        onChange={e => {
          handleTagInput(e.target.value);
        }}
        inputProps={{ 'aria-label': 'Nome' }}
      />

      <br />
      <div
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '15px',
        }}
      >
        <Button
          disabled={!isValidNewOption || !tagRegisterPermitted}
          onClick={() => saveTag()}
        >
          Cadastrar
        </Button>
      </div>
    </>
  );
}
