import React, { useState, useEffect, useCallback } from 'react';

import Lightbox from 'react-image-lightbox';

import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import Button from 'components/Button';
import AttachmentTile from 'components/attachmentTile/AttachmentTile';

import config from 'config';
import api from 'services/api';
import moment from 'moment';

import ModalDocumentoPdf from 'components/ModalDocumentoPdf';
import parserDacte from './xmlParser';
import layout from './layout-dacte';
import { DocumentsContainer, DocumentCTE, DocumentType } from './styles';

export default function TabDocumentosFinanceiro(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const [expanded, setExpanded] = React.useState(false);
  const [state, setState] = useState({
    imgList: [],
    result_obs: [],
    result: [],
    errorMessage: '',
  });

  const [cte, setCte] = useState([]);
  const [xml, setXml] = useState('');
  const [result, setResult] = useState([]);

  useEffect(() => {
    setCte(props.cte);
  }, [props.faturasList, props.boletosList, props.cte]);

  const openLightBox = useCallback(async (indexId = -1) => {
    setIsOpen(true);

    if (indexId === -1) return;
    setPhotoIndex(indexId);
    setIsOpen(true);
  }, []);

  const openPdfPreview = useCallback(async doc => {
    setPdfDocument(doc);
    setOpenPdf(true);
  }, []);

  function handleExpandClick(cte) {
    setExpanded(!expanded);
    getAttachments(cte);
  }

  function handleImageTitle(type_obs) {
    const type = type_obs.toLowerCase();

    switch (type) {
      case 'cte':
        return 'CT-e';
      case 'canhoto':
        return 'Canhoto';
      case 'others':
        return 'Outros';
      case 'obs_file':
        return 'Anexos';
      default:
        return 'Imagem em Anexo';
    }
  }

  function handleCreatedTime(imgObj) {
    try {
      if (!moment(imgObj.created_at).isValid()) return;
      return ` (${moment(imgObj.created_at).format('DD-MM-YYYY HH:mm')}) `;
    } catch (ex) {}
  }

  async function getAttachments(cte) {
    const responseCTE = await api.get(`travel-attachment/${cte.id}`);

    if (responseCTE.status === 200) {
      const responseCTEdata = responseCTE.data;
      const imgList = !responseCTEdata
        ? []
        : responseCTEdata.filter(item => {
            if (
              (item.type_obs === 'cte' ||
                item.type_obs === 'canhoto' ||
                item.type_obs === 'others') &&
              item.attachments.length > 0
            ) {
              return true;
            }
            return false;
          });

      const images = imgList.map((item, index) => {
        const fileExt = item.attachments[0].doc_filename
          .split('.')
          .pop()
          .toLowerCase();

        const isDocument = !!(
          fileExt !== 'jpg' &&
          fileExt !== 'jpeg' &&
          fileExt !== 'png' &&
          fileExt !== 'gif'
        );

        const dataImg = {
          id: item.id,
          type_obs: item.type_obs,
          obs_data: item.obs_data,
          name: item.type_obs,
          attachment_id: item.attachments[0].id,
          status: item.attachments[0].status,
          isDocument,
          default_img_url: isDocument ? '/images/default-doc.png' : '',
          img_url: `${config.baseUrlDocuments + item.attachments[0].doc_path}/${
            item.attachments[0].doc_filename
          }`,
          created_at: item.created_at,
        };
        return dataImg;
      });

      const obs_canhoto = images.filter(
        element => element.type_obs === 'canhoto'
      );
      const obs_others = images.filter(
        element => element.type_obs === 'others'
      );
      const obs_cte = images.filter(element => element.type_obs === 'cte');

      const result_obs = [...obs_cte, ...obs_canhoto, ...obs_others];

      const result = images.reduce(function (r, a) {
        r[a.type_obs] = r[a.type_obs] || [];
        r[a.type_obs].push(a);
        return r;
      }, Object.create(null));

      setState({
        imgList: images,
        result_obs,
        result,
        errorMessage:
          imgList.length === 0 ? 'Nenhum documento para exibir' : '',
      });

      setResult(result);
    } else {
      setState({
        errorMessage: 'Nenhum documento para exibir',
      });
    }
  }

  function downloadXmlFile(invoice_number, xml_data) {
    const element = document.createElement('a');
    const file = new Blob([xml_data], {
      type: 'text/plain',
    });
    element.href = URL.createObjectURL(file);
    element.download = `${invoice_number}.xml`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  function xmlFileParser(invoice_number, xml_data) {
    const parser = new DOMParser();
    const xml = parser.parseFromString(xml_data, 'text/xml');

    const data = layout(parserDacte(xml));
    const myWindow = window.open(
      'DACTE',
      '_blank ',
      'height=400,width=600,scrollbars=1'
    );
    myWindow.document.write('<html><head><title>DACTE</title>');
    myWindow.document.write('</head><body>');
    myWindow.document.write(data);
    myWindow.document.write('</body></html>');

    myWindow.document.close(); // missing code

    myWindow.onload = function () {
      myWindow.focus(); // necessary for IE >= 10
      myWindow.print();
    };
  }

  const [openPdf, setOpenPdf] = React.useState(false);
  const [pdfDocument, setPdfDocument] = useState('');

  const closePdf = useCallback(() => {
    setOpenPdf(false);
  }, [openPdf]);

  async function getXML() {
    try {
      if (cte.id) {
        const response = await api.get(`loads-emitted/xml/${cte.id}`);
        setXml(response.data);
      }
    } catch (err) {
      // Handle exception
    }
  }

  useEffect(() => {
    getXML();
  }, [cte]);

  return (
    <>
      <ModalDocumentoPdf
        documento={pdfDocument}
        open={openPdf}
        close={closePdf}
      />
      <DocumentsContainer>
        <Grid
          item
          container
          xs={12}
          justify="space-between"
          alignItems="center"
          style={{
            // padding: '5px 5px',
            '@media (min-width: 768px)': {
              padding: '5px',
            },
            '@media (max-width: 768px)': {
              padding: '0',
            },
          }}
        >
          <Grid item style={{ paddingLeft: '15px' }}>
            <DocumentCTE>CT-e:</DocumentCTE> {cte.cte_number}
            <Hidden lgUp>
              <br />
              {moment(cte.created_at).format('DD/MM/YYYY HH:mm')}
            </Hidden>
          </Grid>
          <Hidden mdDown>
            <Grid item>
              {moment(cte.created_at).format('DD/MM/YYYY HH:mm')}
            </Grid>
          </Hidden>
          <Grid item style={{ padding: '5px' }}>
            {xml && (
              <>
                <Button onClick={() => downloadXmlFile(cte.cte_number, xml)}>
                  XML / CT-e
                </Button>
                <Button onClick={() => xmlFileParser(cte.cte_number, xml)}>
                  DACTE
                </Button>
              </>
            )}
            <Button color="success" onClick={() => handleExpandClick(cte)}>
              {!expanded ? 'Anexos' : 'Fechar'}
            </Button>
          </Grid>
        </Grid>
        <Grid container hidden={!expanded} style={{ marginTop: 30 }}>
          {result.cte &&
            result.cte.map((tile, index) => (
              <AttachmentTile
                openLightBox={openLightBox}
                openPdfPreview={openPdfPreview}
                imgObj={tile}
                isDocument={tile.isDocument}
                src={tile.isDocument ? tile.default_img_url : tile.img_url}
                path={tile.img_url}
                indexId={index}
                created_time={tile.created_at}
              />
            ))}
        </Grid>
        <Grid container hidden={!expanded} direction="column">
          <DocumentType>Canhoto</DocumentType>
          <div style={{ display: 'flex' }}>
            {result.canhoto &&
              result.canhoto.map((tile, index) => (
                <AttachmentTile
                  openLightBox={openLightBox}
                  openPdfPreview={openPdfPreview}
                  imgObj={tile}
                  isDocument={tile.isDocument}
                  src={tile.isDocument ? tile.default_img_url : tile.img_url}
                  path={tile.img_url}
                  key={index}
                  indexId={
                    result.cte !== undefined ? result.cte.length + index : index
                  }
                  created_time={tile.created_at}
                />
              ))}
          </div>
        </Grid>
        <Grid
          container
          hidden={!expanded}
          direction="column"
          style={{ marginBottom: 30 }}
        >
          <DocumentType>Outros</DocumentType>
          <div style={{ display: 'flex' }}>
            {result.others &&
              result.others.map((tile, index) => (
                <AttachmentTile
                  openLightBox={openLightBox}
                  openPdfPreview={openPdfPreview}
                  imgObj={tile}
                  key={index}
                  isDocument={tile.isDocument}
                  src={tile.isDocument ? tile.default_img_url : tile.img_url}
                  path={tile.img_url}
                  indexId={
                    result.cte !== undefined
                      ? result.canhoto !== undefined
                        ? result.canhoto.length + result.cte.length + index
                        : result.cte.length + index
                      : result.canhoto !== undefined
                      ? result.canhoto.length + index
                      : index
                  }
                  created_time={tile.created_at}
                />
              ))}
          </div>
        </Grid>
      </DocumentsContainer>

      {isOpen && (
        <Lightbox
          mainSrc={state.result_obs[photoIndex].img_url}
          nextSrc={
            state.result_obs[(photoIndex + 1) % state.result_obs.length].img_url
          }
          prevSrc={
            state.result_obs[
              (photoIndex + state.result_obs.length - 1) %
                state.result_obs.length
            ].img_url
          }
          imageTitle={`${photoIndex + 1}/${
            state.result_obs.length
          } | ${handleImageTitle(
            state.result_obs[photoIndex].type_obs
          )}${handleCreatedTime(state.result_obs[photoIndex])}`}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + state.result_obs.length - 1) %
                state.result_obs.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % state.result_obs.length)
          }
        />
      )}
    </>
  );
}
