import React, { useState } from 'react';
import { useSnackbar } from 'v3/components/Snackbar';
import Modal from 'v3/components/Modal';
import Button from 'v3/components/Button';
import Upload from 'v3/components/Upload';
import Text from 'v3/components/Text';
import { Input } from 'v3/components';
import { cooperplaceApi } from 'services/api';
import { attachmentSchema } from 'v3/pages/Policy/validators';

export function AttachmentsModal({
  isOpen,
  onClose,
  refetchPolicy,
  policyId,
  rangeId,
}) {
  const snackbar = useSnackbar();
  const [files, setFiles] = useState([]);
  const [fileDescription, setFileDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  function handleClose() {
    setFiles([]);
    setErrors({});
    setFileDescription('');
    onClose();
  }

  async function validate(data, setErrors) {
    const errorList = {};
    try {
      await attachmentSchema.validate(data, {
        abortEarly: false,
      });
      setErrors({ ...errorList });
      return true;
    } catch (err) {
      err.inner.forEach(error => {
        errorList[error.path] = error.message;
      });
      setErrors({ ...errorList });
      return false;
    }
  }

  async function uploadAttachment() {
    try {
      setLoading(true);
      const isDataValid = await validate({ name: fileDescription }, setErrors);
      if (isDataValid === false) {
        setLoading(false);

        return;
      }
      const attachmentsFiles = new FormData();

      files.forEach((file, index) => {
        const fileName = file.file.name
          .replaceAll('_', '-')
          .split('.')
          .slice(0, -1)
          .join('.');
        const isXLSXSheet =
          file.file.type.split('.').pop() === 'sheet' ? 'xlsx' : null;
        const isXLSSheet =
          file.file.type.split('.').pop() === 'ms-excel' ? 'xls' : null;
        let fileNameFormatted = null;
        if (isXLSXSheet) {
          fileNameFormatted = `${index}.${isXLSXSheet}`;
        } else if (isXLSSheet) {
          fileNameFormatted = `${index}.${isXLSSheet}`;
        } else fileNameFormatted = `${index}.${file?.file.type.split('/')[1]}`;
        const name = fileName
          ? `${fileName}-${fileNameFormatted}`
          : fileNameFormatted;
        const newFile = new File([file?.file.blob], name, {
          type: file?.file.type,
        });
        attachmentsFiles.append('file', newFile);
        attachmentsFiles.append('description', fileDescription);
      });

      await cooperplaceApi.post(
        `policies/${policyId}/ranges/${rangeId}/attachments`,
        attachmentsFiles,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      handleClose();
      refetchPolicy();
    } catch (error) {
      snackbar.show(<Text>Algo deu errado. Tente novamente mais tarde.</Text>, {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }

  function onFilesChanged({ target }, hasError) {
    setFiles([]);
    setErrors({});

    if (hasError) {
      setErrors({ file: 'Algum arquivo selecionado é maior que 1MB.' });
      return;
    }

    setFiles(
      [...target.files].map(file => {
        return {
          file: {
            blob: file,
            size: file?.size,
            name: file?.name,
            type: file?.type,
            tempPath: URL.createObjectURL(file),
          },
          description: file.name,
        };
      })
    );
  }

  return (
    <Modal
      show={isOpen}
      handleClose={handleClose}
      heading={
        <Text type="subtitle" color="dark" weight="400">
          Anexos
        </Text>
      }
      body={
        <div>
          <div className="mb-3">
            <Text>Adicione um arquivo</Text>
            <Upload
              name="file"
              label="Arquivo máximo de 1MB"
              accept=".jpg,.jpeg,.png,.pdf, .xls, .xlsx"
              value={files}
              onChange={onFilesChanged}
              error={errors.file}
              ignoreValidation
              multiple={false}
            />
          </div>
          <div>
            <Input
              label="Nome *"
              value={fileDescription}
              onChange={e => setFileDescription(e.target.value)}
              error={errors?.name}
            />
          </div>
          <div className="d-flex flex-column align-items-center mt-3">
            <Button
              disabled={!files.length}
              onClick={uploadAttachment}
              loading={loading}
            >
              <Text type="regular" weight={500}>
                Adicionar
              </Text>
            </Button>
          </div>
        </div>
      }
    />
  );
}
