import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Select from 'v3/components/Select';
import api from 'services/api';
import { useModal, usePermission } from 'hooks';
import { Text, Tooltip } from 'v3/components';
import { RegisterWagon } from 'v3/pages/Travel/Register/Modals/RegisterWagon';
import { useVehicle } from '../context';

export default function VehicleImplements() {
  const vehicle = useVehicle();
  const params = useParams();
  const registerWagon = useModal();
  const hasPermissionToRegisterWagons = usePermission('CADASTRAR_IMPLEMENTO');
  const [prevImplement, setPrevImplement] = useState(
    vehicle.prevVehicle.implement
  );
  const [implementOptions, setImplementOptions] = useState([]);
  const [bodyOptions, setBodyOptions] = useState([]);
  const [currentWagonRegister, setCurrentWagonRegister] = useState('');

  async function fetchWagons(search) {
    try {
      const response = await api.get('plate/wagons', {
        params: { search },
      });
      return response.data;
    } catch (error) {
      return [];
    }
  }

  function handleRegisterWagon(key) {
    setCurrentWagonRegister(key);
    return registerWagon.open();
  }

  function handleChangeImplement(value, index) {
    vehicle.setData({
      implements: vehicle.data.implements.map((item, idx) => {
        if (idx === index)
          return {
            ...item,
            identifier: {
              id: value?.id,
              plate: value?.plate,
            },
          };
        return item;
      }),
    });
  }

  function sendResponse({ response }) {
    const {
      data: { plate, id },
    } = response;
    handleChangeImplement({ plate, id }, currentWagonRegister);
  }

  useEffect(() => {
    if (!params.id)
      vehicle.setData({
        implement: '',
        body: '',
        implements: [],
      });
  }, []);

  useEffect(() => {
    async function fetchImplements() {
      const response = await api.get(`vehicle-implement-types?app=${true}`);
      setImplementOptions(
        response.data?.filter(item => item?.name !== 'Dolly')
      );
    }
    fetchImplements();
  }, []);

  useEffect(() => {
    async function fetchBodies() {
      const response = await api.get(`body-types?app=${true}`);
      setBodyOptions(response.data);
    }
    fetchBodies();
  }, []);

  useEffect(() => {
    if (
      !params.id ||
      prevImplement?.id !== vehicle.data.implement?.id ||
      (vehicle?.data?.implement?.id && vehicle?.data?.implements?.length === 0)
    ) {
      const iimplements = new Array(vehicle.data.implement?.qty_plates);
      if (vehicle.data.implement) iimplements.fill({ identifier: '' });
      vehicle.setData({
        implements: iimplements,
      });
    }
  }, [vehicle.data.implement]);

  useEffect(() => {
    if (!params.id) {
      vehicle.setData({ implement: null });
    }
  }, [vehicle.data.type]);

  return (
    <>
      {vehicle.data.type?.qty_plates === 4 ? (
        <Row className="form">
          <Col md={6} xs={12}>
            <Select
              label="Tipo de implemento *"
              value={vehicle.data.implement}
              options={implementOptions}
              getOptionLabel={option =>
                `${option.name} - ${option.qty_axis} eixos`
              }
              getOptionValue={option => option.id}
              onChange={value => {
                setPrevImplement(vehicle.data.implement);
                vehicle.setData({ implement: value });
              }}
              error={vehicle.errors?.implement}
            />
          </Col>
          <Col md={6} xs={12}>
            <Select
              label="Tipo de carroceria *"
              value={vehicle.data.body}
              options={bodyOptions}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              onChange={value => vehicle.setData({ body: value })}
              error={vehicle.errors?.body}
            />
          </Col>
          {vehicle.data.implement &&
            vehicle.data.implements?.map((_, index) => (
              <>
                <Col md={6} xs={12}>
                  <Select.Async
                    onSearch={fetchWagons}
                    label={`Placa implemento ${index + 1}`}
                    placeholder=""
                    value={vehicle.data.implements[index]?.identifier}
                    onChange={value => handleChangeImplement(value, index)}
                    getOptionLabel={option => {
                      let label = option.plate;
                      if (option.vehicle) {
                        label += ` - Tração ${option.vehicle.plate}`;
                      }
                      return label;
                    }}
                    getOptionValue={option => option.id}
                  />
                  {hasPermissionToRegisterWagons && (
                    <Tooltip
                      content={
                        <Text type="label">
                          Cadastro simplificado de implemento
                        </Text>
                      }
                    >
                      <Text
                        type="label"
                        weight="500"
                        color="dark_blue"
                        clickable
                        onClick={() => {
                          handleRegisterWagon(index);
                        }}
                      >
                        Implemento novo? Clique aqui para cadastrar
                      </Text>
                    </Tooltip>
                  )}
                </Col>
              </>
            ))}
        </Row>
      ) : (
        <Row className="form">
          <Col xs={12}>
            <Select
              label="Tipo de carroceria *"
              value={vehicle.data.body}
              options={bodyOptions}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              onChange={value => {
                vehicle.setData({ body: value });
              }}
              error={vehicle.errors?.body}
            />
          </Col>
        </Row>
      )}
      <RegisterWagon
        isOpen={registerWagon.isOpen}
        onClose={() => registerWagon.close()}
        wagonKey={currentWagonRegister}
        sendResponse={sendResponse}
      />
    </>
  );
}
