import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import api from 'services/api';
import { useSnackbar } from 'v3/components/Snackbar';
import Text from 'v3/components/Text';
import { formatCurrency } from 'v3/utils/formatter';

import { SwitchOption } from 'v3/components';
import moment from 'moment';
import { useClient } from '../shared/context';
import { validate } from '../shared/validator';
import SharedData from '../shared/Section';
import Inputs from '../shared/Data/index';
import Editable from '../shared/Editable';

export default function Data() {
  const client = useClient();
  const snackbar = useSnackbar();
  const [isEditing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(!client.deleted_at);

  const currency = {
    currency: 'BRL',
    currency_symbol: 'R$',
  };

  useEffect(() => {
    if (client.data.deleted_at) {
      setActive(false);
    } else setActive(true);
  }, [client]);

  async function saveData() {
    setLoading(true);

    const isValid = await validate(
      'data',
      { data: client.data },
      client.setErrors
    );
    if (!isValid) {
      snackbar.show(<Text>Verifique os campos obrigatórios</Text>, {
        type: 'error',
      });
      setLoading(false);
      return;
    }

    try {
      const data = {
        type_id: client.data.personType.name === 'Jurídica' ? 3 : 2,
        is_international: client.data.is_international,
        is_rewards_member: client.data.is_rewards_member,
        cgccpf: client.data.cgccpf,
        ie: client.data.ie || null,
        social_name: client.data.social_name,
        fantasy_name: client.data.fantasy_name || null,
        phone: client.data.phone,
        phone_unmasked: client.data.is_international
          ? client.data.phone
          : client.data.phone.replace(/[^0-9]+/g, ''),
        email: client.data.email,
        taker_role_id: client.data.takerRole?.code,
        credit_limit: client.data?.credit_limit,
      };

      await api.put(`v3/client/${client.data.id}/data`, {
        data,
        tags: client.data.clientTags.map(tag => tag.id),
      });
      client.setPrevClient(client.data);
      snackbar.show(<Text>Salvo com sucesso</Text>, { type: 'success' });
      setEditing(false);
    } catch (error) {
      let message = '';
      if (error.response && error.response.data) {
        message = error.response.data.message;
      }
      snackbar.show(<Text>{message || 'Erro ao salvar'}</Text>, {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }

  const isCompany = client?.data?.personType?.name === 'Jurídica';

  async function toggleActive() {
    if (client?.canEditData === false) {
      return;
    }
    try {
      await api.put(`/persons/${client.data.id}`, {
        cgccpf: client.data.cgccpf,
        deleted_at: active ? moment() : null,
      });
      client.data.deleted_at = active ? moment() : null;
      setActive(old => !old);
      client.setPrevClient(client.data);
    } catch {
      snackbar.show(<Text> Erro ao alterar cliente! </Text>, {
        type: 'error',
      });
    }
  }

  return (
    <SharedData
      Title="Dados gerais"
      HeaderRightComponent={
        client?.canEditData && (
          <Editable
            isEditing={isEditing}
            setEditing={setEditing}
            onSave={saveData}
            isLoading={loading}
          />
        )
      }
      loading={client.isFetching}
    >
      {isEditing ? (
        <Inputs />
      ) : (
        <Row>
          <Col xs={12} md={12} as="p">
            <SwitchOption value={active} onClick={() => toggleActive()} />
          </Col>
          <Col xs={12} md={6}>
            <Text type="label" color="dark">
              Tipo de Pessoa
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {client.data?.personType?.name || 'Não informado'}
            </Text>
          </Col>
          <Col xs={12} md={6}>
            <Text type="label" color="dark">
              Estrangeiro
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {(!client?.data?.is_international && 'Não') ||
                (client?.data?.is_international && 'Sim') ||
                'Não informado'}
            </Text>
          </Col>
          <Col xs={12} md={12}>
            <Text type="label" color="dark">
              Estabelecimento
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {(!client?.data?.is_rewards_member && 'Não') ||
                (client?.data?.is_rewards_member && 'Sim') ||
                'Não informado'}
            </Text>
          </Col>
          {client.data.is_international ? (
            <Col xs={12} md={12}>
              <Text type="label" color="dark">
                Documento
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {client?.data?.cgccpf || 'Não informado'}
              </Text>
            </Col>
          ) : (
            <Col xs={12} md={6}>
              <Text type="label" color="dark">
                {isCompany ? 'CNPJ' : 'CPF'}
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {client?.data?.cgccpf || 'Não informado'}
              </Text>
            </Col>
          )}
          {isCompany ? (
            <Col xs={12} md={6}>
              <Text type="label" color="dark">
                Inscrição Estadual
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {client?.data?.ie || 'Não informado'}
              </Text>
            </Col>
          ) : null}
          <Col xs={12} md={12}>
            <Text type="label" color="dark">
              {isCompany ? 'Razão Social' : 'Nome'}
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {client?.data?.social_name || 'Não informado'}
            </Text>
          </Col>
          {isCompany ? (
            <Col xs={12} md={12}>
              <Text type="label" color="dark">
                Nome fantasia
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {client?.data?.fantasy_name || 'Não informado'}
              </Text>
            </Col>
          ) : null}
          <Col xs={12} md={12}>
            <Text type="label" color="dark">
              Telefone
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {client?.data?.phone || 'Não informado'}
            </Text>
          </Col>
          <Col xs={12} md={12}>
            <Text type="label" color="dark">
              E-mail
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {client?.data?.email || 'Não informado'}
            </Text>
          </Col>
          {isCompany && (
            <Col xs={12} md={12}>
              <Text type="label" color="dark">
                Contribuinte ICMS
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {client?.data?.takerRole?.label ||
                  client?.data?.takerRole?.name ||
                  'Não informado'}
              </Text>
            </Col>
          )}
          {client.data?.clientTags?.length > 0 && (
            <Col xs={12}>
              <Text type="label" color="dark">
                Tags
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {client?.data?.clientTags.map(tag => tag.name).join(', ')}
              </Text>
            </Col>
          )}
          <Col xs={12}>
            <Text type="label" color="dark">
              Limite de Crédito Disponível
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {typeof client?.data?.credit_limit !== 'undefined' &&
              client.data.credit_limit !== null
                ? formatCurrency(
                    Math.max(Number(client.data.credit_limit), 0),
                    currency?.currency,
                    currency?.currency_symbol
                  )
                : 'Não informado'}
            </Text>
          </Col>
        </Row>
      )}
    </SharedData>
  );
}
