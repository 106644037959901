/* eslint-disable eqeqeq */
import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import Text from 'v3/components/Text';
import { useSnackbar } from 'v3/components/Snackbar';
import api from 'services/api';

import { FaTrash } from 'react-icons/fa';

import Button from 'v3/components/Button';
import Fallback from 'v3/components/Fallback';
import Table from 'v3/components/Table';
import useModal from 'hooks/useModal';
import Radio from 'v3/components/Radio';
import theme from 'v3/theme';
import Editable from '../shared/Editable';
import Modal from '../shared/Checklist';
import SharedData from '../shared/Section';
import { useClient } from '../shared/context';

export default function Checklist() {
  const client = useClient();
  const snackbar = useSnackbar();
  const checklistModal = useModal();

  const [isEditing, setEditing] = useState(false);
  const [loading] = useState(false);
  const params = useParams();

  async function handleChecklistStatus(valueStatus, shouldNotShow = true) {
    try {
      if (params.id) {
        await api.put(`v3/client/${params.id}/status/checklist`, {
          status: valueStatus,
        });

        if (shouldNotShow) {
          snackbar.show(<Text>Checklist alterado!</Text>, {
            type: 'success',
          });
        }
      }
      client.setData({
        settings: client.data.settings.map(setting => {
          if (setting?.type === 'REQUIRED_CHECKLIST') {
            return { ...setting, value: valueStatus ? '1' : '0' };
          }
          return setting;
        }),
      });
    } catch (error) {
      snackbar.show(<Text>Algo deu errado, tente novamente!</Text>, {
        type: 'error',
      });
    }
  }
  async function handleDelete(item) {
    try {
      if (params.id) {
        client.setData({
          companyPerson: {
            checklists: client.data.companyPerson.checklists.filter(
              checklist => {
                return checklist.id !== item.id;
              }
            ),
          },
        });

        await api.delete(`v3/client/${params.id}/checklist/${item.id}`);
      }

      client.setData({
        companyPerson: {
          checklists: client.data.companyPerson.checklists.filter(checklist => {
            return checklist.name !== item.name;
          }),
        },
      });

      snackbar.show(<Text>Item do checklist removido!</Text>, {
        type: 'success',
      });

      if (
        client.data?.companyPerson.checklists?.length === 1 ||
        client.data?.companyPerson.checklists === undefined
      ) {
        await handleChecklistStatus(false);
      }
    } catch (error) {
      snackbar.show(<Text>Algo deu errado, tente novamente!</Text>, {
        type: 'error',
      });
    }
  }

  return (
    <SharedData
      Title="Checklist"
      HeaderRightComponent={
        <>
          {client.data?.companyPerson?.checklists !== undefined &&
            client.data?.companyPerson?.checklists.length > 0 &&
            client?.canEditData && (
              <Editable
                openModal={() => checklistModal.open()}
                isEditing={isEditing}
                setEditing={setEditing}
                isLoading={loading}
                add
              />
            )}
        </>
      }
      loading={client.isFetching}
    >
      <Modal
        isOpen={checklistModal.isOpen}
        onClose={checklistModal.close}
        handleChecklistStatus={handleChecklistStatus}
      />
      <Fallback
        fall={!client.data?.companyPerson?.checklists.length > 0}
        component={
          <Col xs={12} className="align-items-center d-flex flex-column my-2">
            <Text color={theme.colors.dark} type="regular" weight="400">
              Não há checklist cadastrados para esse cliente
            </Text>
            {client?.canEditData && (
              <Button
                className="mt-3 py-2"
                onClick={() => {
                  checklistModal.open();
                }}
              >
                <Text type="regular" weight="500" color="white">
                  Adicionar
                </Text>
              </Button>
            )}
          </Col>
        }
      >
        <Row className="form">
          <Col md={12}>
            <Row>
              <Col xs={12} className="ml-1">
                <Radio.Group
                  label={
                    <Text color="dark" type="label">
                      Checklist obrigatório{' '}
                    </Text>
                  }
                  onChange={({ target }) => {
                    if (client?.canEditData)
                      handleChecklistStatus(target.value);
                    else return;
                  }}
                  value={
                    client.data?.settings?.find(
                      setting => setting.type === 'REQUIRED_CHECKLIST'
                    )?.value == 1
                  }
                  horizontal
                >
                  <Radio value>
                    <Text color="dark" type="label">
                      Sim
                    </Text>
                  </Radio>
                  <Radio value={false}>
                    <Text color="dark" type="label">
                      Não
                    </Text>
                  </Radio>
                </Radio.Group>
              </Col>
              <Col>
                <Table striped>
                  <thead>
                    <tr>
                      <th>
                        <Text weight="500" color="dark" type="regular">
                          Nome
                        </Text>
                      </th>
                      <th>
                        <Text weight="500" color="dark" type="regular">
                          Ação
                        </Text>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {client.data?.companyPerson?.checklists?.map(checklist => (
                      <tr>
                        <td>
                          <Text type="regular" color="gray">
                            {checklist.name}
                          </Text>
                        </td>
                        <td>
                          <Button
                            variant="error"
                            className="p-2 align-items-center d-flex justify-content-center"
                            style={{ height: 28, width: 28, marginLeft: 5 }}
                            onClick={() => handleDelete(checklist)}
                            disabled={client?.canEditData === false}
                          >
                            <FaTrash size={16} />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Col>
        </Row>
      </Fallback>
    </SharedData>
  );
}
