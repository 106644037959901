/* eslint-disable no-unused-expressions */
import React, { useState, useRef, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import api from 'services/api';
import theme from 'v3/theme';

import Input from 'v3/components/Input';
import Text from 'v3/components/Text';
import Modal from 'v3/components/Modal';

import { useSnackbar } from 'v3/components/Snackbar';

import { useCompany } from '../context';

export default function Checklist({ isOpen, onClose }) {
  const inputRef = useRef(null);
  const params = useParams();
  const snackbar = useSnackbar();

  const company = useCompany();

  const [open, setOpen] = useState(false);
  const [item, setItem] = useState('');

  async function handleSave(e) {
    e.preventDefault(e);

    if (!item) {
      snackbar.show(<Text>Preencha o campo!</Text>, { type: 'error' });
      return;
    }

    if (company.data?.checkList?.some(c => c.title === item)) {
      snackbar.show(<Text>Item já existe nesse checklist!</Text>, {
        type: 'error',
      });
      return;
    }

    try {
      company.setData({ required_checklist: true });

      if (params.id) {
        const response = await api.post(`v3/company/${params.id}/checklist`, {
          checklist: item,
        });

        company.setData({
          checkList: [
            ...company.data.checkList,
            {
              title: response.data.title,
              id: response.data.id,
              company_id: response.data.id.company_id,
            },
          ],
        });
        snackbar.show(<Text>Checklist adicionado com sucesso!</Text>, {
          type: 'success',
        });
      } else {
        company.setData({
          checkList: company.data.checkList
            ? [...company.data.checkList, { title: item }]
            : [{ title: item }],
        });
      }
      company.setPrevCompany(company.data);
      setItem('');
      inputRef.current.focus();
    } catch (error) {
      snackbar.show(<Text>Algo deu errado, tente novamente!</Text>, {
        type: 'error',
      });
    }
  }
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (open) {
      inputRef.current.focus();
    }
  }, [open]);

  return (
    <>
      <Modal
        show={isOpen || open}
        handleClose={onClose || handleClose}
        heading="Checklist"
        centered
        body={
          <Row className="m-3">
            <Col md={12}>
              <form onSubmit={e => handleSave(e)}>
                <Input
                  ref={inputRef}
                  label="Item do checklist"
                  rightIcon={<FaPlus color="#fff" />}
                  rightIconColor={theme.colors.primary}
                  rightIconAction={e => handleSave(e)}
                  value={item}
                  onChange={({ target }) => setItem(target.value)}
                />
              </form>
            </Col>
          </Row>
        }
      />
    </>
  );
}
