import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { Modal, Text } from 'v3/components';
import { formatDateTime } from 'v3/utils/formatter';
import {
  AuditItemHeader,
  AuditToggleButton,
  LogItem,
  StyledAuditRow,
} from '../shared/styles';

export function AuditModal({ isOpen, onClose, logs }) {
  const [processedLogs, setProcessedLogs] = useState([]);
  useEffect(() => {
    if (isOpen) {
      setProcessedLogs(logs);
    }
  }, [isOpen]);
  return (
    <Modal
      show={isOpen}
      handleClose={onClose}
      heading={
        <Text color="dark" type="header">
          Auditoria
        </Text>
      }
      body={
        <StyledAuditRow>
          {processedLogs?.map((log, index) => {
            return (
              <Col key={log?.id} md={12} className="my-2">
                <div>
                  <AuditItemHeader>
                    <AuditToggleButton
                      title={log?.show ? 'Ocultar detalhes' : 'Ver detalhes'}
                      onClick={() => {
                        const updated = processedLogs.map((l, idx) => {
                          if (idx === index) {
                            return {
                              ...l,
                              show: !l?.show,
                            };
                          } else return l;
                        });
                        setProcessedLogs(updated);
                      }}
                    >
                      {log?.show ? <FaArrowUp /> : <FaArrowDown />}
                    </AuditToggleButton>
                    <Text color="dark" weight={500}>
                      {formatDateTime(log?.createdAt)}
                    </Text>
                    <Text color="dark">
                      {': '} {log?.obs}
                    </Text>
                  </AuditItemHeader>
                  {log?.show && (
                    <LogItem>
                      <Text as="p" style={{ whiteSpace: 'pre' }}>
                        {log?.Detalhes?.trim()}
                      </Text>
                    </LogItem>
                  )}
                </div>
              </Col>
            );
          })}
        </StyledAuditRow>
      }
    />
  );
}
