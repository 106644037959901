/* eslint-disable consistent-return */
/* eslint-disable import/no-unresolved */
import React from 'react';
import moment from 'moment';

import Text from 'v3/components/Text';

import api, { cooperplaceApi } from 'services/api';

export async function renewLoad({
  setConfirmButtonDisabled,
  setSuccessfullyRenovated,
  reasonSelected,
  newDueDate,
  snackbar,
  load,
  setLoading,
  selectedNewBranch,
  setNewBranchError,
  isBranchActive,
}) {
  if (!load?.id) {
    return;
  }

  let errorMessage = '';

  if (!moment(newDueDate, 'DD/MM/YYYY HH:mm').isValid()) {
    errorMessage = 'Por favor, selecione uma data válida.';
  }

  if (!isBranchActive && !selectedNewBranch?.id) {
    errorMessage = 'Por favor, selecione uma filial.';
    setNewBranchError(errorMessage);
  }

  if (errorMessage) {
    snackbar.show(<Text>{errorMessage}</Text>, {
      type: 'error',
    });
    setConfirmButtonDisabled(false);
    return;
  }
  try {
    setLoading(true);
    const response = await cooperplaceApi.post(`loads/${load?.id}/renew`, {
      cancelRefLoad: load?.atendida ? 0 : 1,
      deletedReason: load?.atendida ? '' : reasonSelected.value,
      dueDate: moment(newDueDate, 'DD/MM/YYYY HH:mm'),
      branchOfficeId: selectedNewBranch?.id,
    });

    setConfirmButtonDisabled(false);
    setSuccessfullyRenovated(true);

    return response.data;
  } catch (err) {
    let message = 'Não foi possível renovar a carga.'
    if (err.response?.status === 400 && err.response?.data?.message) {
      message = err.response?.data?.message;
    }

    snackbar.show(<Text>{message}</Text>, {
      type: 'error',
    });

    setConfirmButtonDisabled(false);
  } finally {
    setLoading(false);
  }
}
