import styled from 'styled-components';

export const UlItem = styled.ul`
  text-align: center;
  div {
    margin-bottom: 1rem;
  }
  span[id='text'] {
    margin-top: 2rem !important;
    display: block;
  }
`;
