/* eslint-disable import/no-unresolved */

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import api from 'services/api';
import Col from 'react-bootstrap/Col';
import Card from 'v3/components/Card';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import SwitchOption from 'v3/components/SwitchOption';

import { validatePermission } from 'actions/index';

function CardPaymentMethod({ paymentMethod, snackbar }) {
  const history = useHistory();

  const [hasPermissionToEdit, setPermissionToEdit] = useState();
  useState(() => {
    setPermissionToEdit(validatePermission('EDITAR_FORMAS_DE_PAGAMENTO'));
  }, []);

  const [active, setActive] = useState(paymentMethod.active);

  async function toggleActive() {
    try {
      const newActiveState = !active;
      await api.put(`/payment/methods/${paymentMethod.id}/toggle`, {
        active: newActiveState,
      });
      setActive(newActiveState);
    } catch {
      snackbar.show(<Text> Erro ao alterar método de pagamento </Text>, {
        type: 'error',
      });
    }
  }

  return (
    <Col lg={6} md={6} xs={12}>
      <Card
        className="full-height-card"
        header={
          <Text
            color="#464E5F"
            type="header"
            className="col-9"
            style={{ textOverflow: 'ellipsis' }}
          >
            {paymentMethod.name}
          </Text>
        }
        HeaderRightComponent={
          <Button
            className="col-3"
            variant="secondary"
            onClick={() =>
              history.push(`/formas-de-pagamento/${paymentMethod.id}`)
            }
          >
            <Text color="dark">Detalhes</Text>
          </Button>
        }
      >
        <div className="container">
          <div className="row justify-content-end">
            {hasPermissionToEdit && (
              <div className="col-3">
                <SwitchOption value={active} onClick={toggleActive} />
              </div>
            )}
          </div>
        </div>
      </Card>
    </Col>
  );
}

export default CardPaymentMethod;
