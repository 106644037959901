import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Typography, Box } from '@material-ui/core';

import SwipeableViews from 'react-swipeable-views';
import Card from 'components/Card';

import AttachmentTile from 'components/attachmentTile/AttachmentTile';
import config from 'config';

import ModalDocumentoPdf from 'components/ModalDocumentoPdf';

import { StyledTabs, StyledTab } from './styles';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

export default function TabsEmpresa(props) {
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  const [openPdf, setOpenPdf] = React.useState(false);
  const [pdfDocument, setPdfDocument] = React.useState('');

  let openPdfPreview = React.useCallback(async doc => {
    setPdfDocument(doc);
    setOpenPdf(true);
  }, []);

  const closePdf = React.useCallback(() => {
    setOpenPdf(false);
  }, [openPdf]);

  return (
    <div style={{ marginBottom: '10px', position: 'relative' }}>
      <ModalDocumentoPdf
        documento={pdfDocument}
        open={openPdf}
        close={closePdf}
      />

      <Card>
        <StyledTabs
          value={value}
          onChange={handleChange}
          style={{ position: 'relative' }}
        >
          <StyledTab label="Documentos a validar" {...a11yProps(0)} />
        </StyledTabs>
        <SwipeableViews
          index={value}
          style={{ position: 'relative' }}
          onChangeIndex={index => handleChange(null, index)}
        >
          <TabPanel value={value} index={0} style={{ shadow: 'none' }}>
            <Grid container>
              {props.company.travelData &&
                props.company.travelData.map((element, index) => {
                  if (element.attachmentsData.length === 0) {
                    return null;
                  }

                  let fileExt = element.attachmentsData[0].doc_filename
                    ? element.attachmentsData[0].doc_filename
                        .split('.')
                        .pop()
                        .toLowerCase()
                    : 'png';
                  let isDocument =
                    fileExt !== 'jpg' &&
                    fileExt !== 'jpeg' &&
                    fileExt !== 'png' &&
                    fileExt !== 'gif'
                      ? true
                      : false;

                  const img = {
                    ...element.attachmentsData[0],
                    isDocument,
                    default_img_url: isDocument
                      ? '/images/default-doc.png'
                      : '',
                    img_url:
                      config.baseUrlDocuments +
                      element.attachmentsData[0].doc_path +
                      '/' +
                      element.attachmentsData[0].doc_filename,
                  };

                  return (
                    <AttachmentTile
                      setSnackbar={props.setSnackbar}
                      key={index}
                      openLightBox={() => props.openLightbox(img.img_url)}
                      openPdfPreview={() => props.openPdfPreview(img.img_url)}
                      imgObj={img}
                      validationOptions={true}
                      status={img.status}
                      id={img.id}
                      created_time={img.created_at}
                    />
                  );
                })}
            </Grid>
          </TabPanel>
        </SwipeableViews>
      </Card>
    </div>
  );
}
