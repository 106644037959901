import React, { useState, useCallback } from 'react';
import dashboardApi from 'services/apis/dashboard';
import Card from './Card';
import MapRaw from 'v3/components/Map';
import MarkerLetter from 'v3/components/Map/MarkerLetter';
import { Table, Text, Button } from 'v3/components';
import { formatPlate, formatDateTime } from 'v3/utils/formatter';

export default function MercosulPlates({ dashboard, name, id }) {
  const [isFetching, setFetching] = useState(false);
  const [travels, setTravels] = useState({});
  const [filter, setFilter] = useState({});
  const [currentPage, setCurrentPage] = useState({});
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchPlates = useCallback(async (filters = {}) => {
    setLoading(true);
    const {
      tags = [],
      clients = [],
      negotiators = [],
      costCenters = [],
      pcps = [],
      companies = [],
      page = 1,
      start_date,
      end_date,
    } = filters;
    try {
      if (page === 1) {
        setFetching(true);
      }

      setFilter(filters);
      setCurrentPage(+page);

      const response = await dashboardApi.get('/fetch-mercosul-finish-in', {
        params: {
          tags: tags?.map(tag => tag.id),
          clients: clients?.map(client => client.id),
          start_date,
          negotiators: negotiators?.map(negotiator => negotiator?.id),
          costCenters: costCenters?.map(costCenter => costCenter?.id),
          pcps: pcps?.map(pcp => pcp?.id),
          companies: companies?.map(company => company?.id),
          end_date: end_date || undefined,
          page,
        },
      });

      setPagination(response.data?.travels?.pagination);

      if (page === 1) {
        setTravels(response.data?.travels?.data);
      } else {
        setTravels(oldTravels => ({
          ...oldTravels,
          last: {
            ...oldTravels.last,
            travels: [...oldTravels.last.data, ...response.data.last.data],
          },
        }));
      }
    } catch (err) {
      //
    } finally {
      setFetching(false);
      setLoading(false);
    }
  }, []);

  return (
    <Card
      title={'Previsão de disponibilidade de placas (Mercosul)'}
      isLoading={isFetching}
      fetchFn={fetchPlates}
      onFiltersChanged={fetchPlates}
      dashboard={dashboard}
      name={name}
      id={id}
      excludeFilterByBranchOffice={true}
      mercosulDashboard
      ListComponent={
        <>
          <Table striped>
            <thead>
              <tr>
                <th>
                  <Text weight={500} color={'dark'}>
                    Placa
                  </Text>
                </th>
                <th>
                  <Text weight={500} color={'dark'}>
                    Nome Motorista
                  </Text>
                </th>
                <th>
                  <Text weight={500} color={'dark'}>
                    Status Mercosul
                  </Text>
                </th>
                <th>
                  <Text weight={500} color={'dark'}>
                    Previsão de finalização (data da última entrega)
                  </Text>
                </th>
                <th>
                  <Text weight={500} color={'dark'}>
                    Última log inserida na viagem
                  </Text>
                </th>
              </tr>
            </thead>
            <tbody>
              {travels && travels.length >= 1 ? (
                travels.map(travel => {
                  return (
                    <>
                      <tr key={travel.id}>
                        <td>
                          <Text type="regular" color="gray">
                            {formatPlate(travel?.plate) || 'Não informado'}
                          </Text>
                        </td>
                        <td>
                          <Text type="regular" color="gray">
                            {travel?.social_name || 'Não informado'}
                          </Text>
                        </td>
                        <td>
                          <Text type="regular" color="gray">
                            {travel?.status_mercosul || 'Não informado'}
                          </Text>
                        </td>
                        <td>
                          <Text type="regular" color="gray">
                            {formatDateTime(travel.scheduled_time) ||
                              formatDateTime(travel.scheduled_time) ||
                              'Não informado'}
                          </Text>
                        </td>
                        <td>
                          <Text type="regular" color="gray">
                            {travel?.last_log_travel || 'Não informado'}
                          </Text>
                        </td>
                      </tr>
                    </>
                  );
                })
              ) : (
                <>
                  <tr>
                    <td>
                      <Text type="regular" color="gray">
                        {' '}
                        Não informado{' '}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray">
                        {' '}
                        Não informado{' '}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray">
                        {' '}
                        Não informado{' '}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray">
                        {' '}
                        Não informado{' '}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray">
                        {' '}
                        Não informado{' '}
                      </Text>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </Table>
          {pagination?.lastPage > 1 && pagination?.to < pagination?.total && (
            <div className="text-center mt-3 col-12">
              <Button
                className="mt-2 ml-auto align-item-center"
                onClick={() => {
                  fetchPlates({
                    page: currentPage + 1,
                    tags: filter?.tags,
                    clients: filter?.clients,
                    start_date: filter?.start_date,
                    end_date: filter?.end_date,
                    costCenters: filter?.costCenters,
                    pcps: filter?.pcps,
                    negotiators: filter?.negotiators,
                    companies: filter?.companies,
                  });
                }}
                loading={loading}
              >
                <Text type="regular" color="white" truncate>
                  Ver mais
                </Text>
              </Button>
            </div>
          )}
        </>
      }
      NumericComponent={
        <div className="d-flex justify-content-center text-center flex-column">
          <MapRaw
            zoom={4}
            height={450}
            width="100%"
            position={{ lat: -15.7802, lng: -47.9292 }}
          >
            {travels && travels.length >= 1
              ? travels.map((travel, index) => {
                  return (
                    <MarkerLetter
                      letter={index + 1}
                      background={'#d9534f'}
                      lat={travel.lat_destination}
                      lng={travel.long_destination}
                    />
                  );
                })
              : null}
          </MapRaw>
        </div>
      }
    />
  );
}
