import { Slide } from '@material-ui/core';
import Button from 'v3/components/Button';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React from 'react';
import { Col } from 'react-bootstrap';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function DialogContracts({ open, handleConfirm, handleClose }) {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <DialogTitle>Contratos de Fretes</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Essa viagem possui contratos importados do benner, caso optar por
          alterar o número da viagem os contratos serão excluídos.
          <br /> <b>Deseja continuar?</b>
        </DialogContentText>
      </DialogContent>
      <div className="d-flex justify-content-center mb-3">
        <Col className="flex-grow-0">
          <Button variant="danger" onClick={handleClose}>
            Não
          </Button>
        </Col>
        <Col className="flex-grow-0">
          <Button variant="success" onClick={handleConfirm}>
            Sim
          </Button>
        </Col>
      </div>
    </Dialog>
  );
}
