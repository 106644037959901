import React from 'react';

import Loading from 'components/Loading';

export default function Fallback({
  fall,
  component = <Loading />,
  children,
}) {
  if (fall) {
    return component;
  }

  return children;
}
