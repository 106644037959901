/* eslint-disable import/no-unresolved */
import React from 'react';
import { Row, Col } from 'react-bootstrap';

import Text from 'v3/components/Text';

export default function DetailsText({ issuer }) {
  return (
    <Row>
      <Col xs={12}>
        <Text type="label">Endereço</Text>
        <Text type="regular" color="gray" as="p" className="mt-2">
          {issuer && issuer.address}
        </Text>
      </Col>
      <Col xs={6}>
        <Text type="label">Número</Text>
        <Text type="regular" color="gray" as="p" className="mt-2">
          {issuer && issuer.number}
        </Text>
      </Col>
      <Col xs={6}>
        <Text type="label">Complemento</Text>
        <Text type="regular" color="gray" as="p" className="mt-2">
          {issuer && issuer.complement}
        </Text>
      </Col>
      <Col xs={6}>
        <Text type="label">Bairro</Text>
        <Text type="regular" color="gray" as="p" className="mt-2">
          {issuer && issuer.neighborhood}
        </Text>
      </Col>
      <Col xs={6}>
        <Text type="label">CEP</Text>
        <Text type="regular" color="gray" as="p" className="mt-2">
          {issuer && issuer.postal_code}
        </Text>
      </Col>
      <Col xs={12}>
        <Text type="label">Cidade</Text>
        <Text type="regular" color="gray" as="p" className="mt-2">
          {issuer && issuer.city
            ? `${issuer.city.name}, ${issuer.city.province.uf}`
            : ''}
        </Text>
      </Col>
    </Row>
  );
}
