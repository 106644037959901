import React, { useMemo } from 'react';

import Text from 'v3/components/Text';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function AddressData({
  driver,
  driver: { addresses },
  country_name,
}) {
  const address = useMemo(() => {
    return {
      street: addresses[0]?.address || driver?.address_address,
      number: addresses[0]?.number || driver?.address_number,
      complement: addresses[0]?.complement || driver?.address_complement,
      neighborhood: addresses[0]?.neighborhood || driver?.address_neighborhood,
      zip_code: addresses[0]?.zip_code || driver?.address_zip_code,
      city: addresses[0]?.city_name || driver?.city_name,
      uf_name: addresses[0]?.uf_name || driver?.address_province_uf,
    };
  }, [driver, addresses]);
  return (
    <Row>
      <Col xs={12}>
        <Text type="label">País</Text>
        <Text type="regular" color="gray" as="p" className="mt-2">
          {country_name || 'Sem país'}
        </Text>
      </Col>
      <Col xs={12}>
        <Text type="label">Endereço</Text>
        <Text type="regular" color="gray" as="p" className="mt-2">
          {address?.street || 'Sem endereço'}
        </Text>
      </Col>
      <Col lg={6} md={6} xs={12}>
        <Text type="label">Número</Text>

        <Text type="regular" color="gray" as="p" className="mt-2">
          {address?.number || 'Sem número'}
        </Text>
      </Col>
      <Col lg={6} md={6} xs={12}>
        <Text type="label">Complemento</Text>

        <Text type="regular" color="gray" as="p" className="mt-2">
          {address?.complement || 'Sem complemento'}
        </Text>
      </Col>
      <Col lg={6} md={6} xs={12}>
        <Text type="label">Bairro</Text>

        <Text type="regular" color="gray" as="p" className="mt-2">
          {address?.neighborhood || 'Sem bairro'}
        </Text>
      </Col>
      <Col lg={6} md={6} xs={12}>
        <Text type="label">CEP</Text>

        <Text type="regular" color="gray" as="p" className="mt-2">
          {address.zip_code || 'Sem CEP'}
        </Text>
      </Col>
      <Col xs={12}>
        <Text type="label">Cidade</Text>

        <Text type="regular" color="gray" as="p" className="mt-2">
          {address?.city
            ? `${address?.city} - ${address?.uf_name}`
            : 'Sem cidade'}
        </Text>
      </Col>
      <Col lg={6} md={6} xs={12}>
        <Text type="label">Tipo de moradia</Text>

        <Text type="regular" color="gray" as="p" className="mt-2">
          {driver?.property_type || 'Sem tipo'}
        </Text>
      </Col>
      <Col lg={6} md={6} xs={12}>
        <Text type="label">Tipo de endereço</Text>

        <Text type="regular" color="gray" as="p" className="mt-2">
          {driver?.property_status || 'Sem tipo'}
        </Text>
      </Col>
    </Row>
  );
}
