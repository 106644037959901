import React, { useState } from 'react';

import Input from 'components/Input';
import Label from 'components/Label';
import Button from 'components/Button';
import Checkbox from '@material-ui/core/Checkbox';

export default function FilterContato(props) {
  const [name, setName] = useState('');
  const [read, setRead] = useState('');

  function filterContatos(event) {
    event.preventDefault();

    let filters = '';
    filters += name && name !== 'null' ? '&name=' + name : '';
    filters += read && read !== 'null' ? '&read=' + read : '';

    props.getContatos(filters);
  }

  function handleRead() {
    setRead(!read);
  }

  return (
    <form
      onSubmit={filterContatos}
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      <div>
        <Checkbox
          checked={read}
          onClick={() => handleRead()}
          value="read"
          inputProps={{
            'aria-label': 'primary checkbox'
          }}
        />
        <Label htmlFor="from" style={{ display: 'inline-block' }}>
          {'Mostrar apenas lidos'}
        </Label>
      </div>

      <Input
        value={name}
        label="Nome"
        onChange={e => setName(e.target.value)}
        margin="normal"
        variant="outlined"
      />

      <div
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '15px'
        }}
      >
        <Button type="submit">Filtrar</Button>
      </div>
    </form>
  );
}
