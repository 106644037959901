/* eslint-disable import/no-unresolved */
import styled, { css } from 'styled-components';
import theme from 'v3/theme';

export const Container = styled.div`
  margin-right: 10px;
`;

export const Label = styled.label`
  font-weight: 500;
  display: inline-flex;
  vertical-align: top;
  align-items: center;
  cursor: pointer;
  margin-bottom: 0;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: initial;
      opacity: 0.75;
    `}
`;

export const Input = styled.input`
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  width: 1px;
  white-space: nowrap;
  position: absolute;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  margin: -1px;
  padding: 0px;
  overflow: hidden;

  &:checked + div[type='radio'] {
    background-color: ${theme.colors.light_blue};
    border: none;
  }

  + div[type='radio'] {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${theme.colors.gray};
    transition: background-color 120ms ease 0s, box-shadow 250ms ease 0s;
    background-color: #fff;
  }
`;

export const Wrapper = styled.div`
  margin-left: 10px;
`;
