import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';
import { validatePermission } from 'actions';

import ApplicationLayout from 'v3/layouts/Application';
import Space from 'v3/components/Space';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';

import { useRoute } from '../shared/context';
import { handleChange } from '../shared/utils';

import Data from './Data';
import Route from './Route';
import Charge from './Charge';
import Taxes from './Taxes';
import Feed from './Feed';

export default function RouteDetails() {
  const history = useHistory();
  const route = useRoute();
  const [hasPermissionToEdit, setPermissionToEdit] = useState(true);
  const [hasPermissionToEditRoute, setPermissionToEditRoute] = useState(false);

  useEffect(() => {
    setPermissionToEditRoute(validatePermission('ALTERAR_ORIGEM_DESTINO_ROTA'));
  }, []);

  useEffect(() => {
    function validateUserPermission() {
      setPermissionToEdit(validatePermission('EDITAR_ROTA'));
    }
    validateUserPermission();
  }, []);

  useEffect(() => {
    if (!hasPermissionToEdit) {
      route.showSnackbar('Você não tem permissão para editar rota', {
        type: 'error',
      });
      history.push('/');
    }
  }, [hasPermissionToEdit]);

  useEffect(() => {
    handleChange(route);
  }, []);

  useEffect(() => {
    handleChange(route);
  }, [route.data?.loadType, route.data?.vehicles]);

  return (
    <ApplicationLayout
      title="Rotas"
      RightComponent={
        <Space x="8px">
          <Link to="/rotas">
            <Button variant="secondary" onClick={() => history.goBack()}>
              <Text type="regular" color="dark" weight="semiBold">
                Voltar
              </Text>
            </Button>
          </Link>
          <Link to="cadastrar">
            <Button>
              <Text type="regular" color="white" weight="semiBold">
                Nova rota
              </Text>
            </Button>
          </Link>
        </Space>
      }
    >
      <Row>
        <Col md={6} xs={12}>
          <Data />
          <Charge />
          <Feed />
        </Col>
        <Col md={6} xs={12}>
          <Route hasPermissionToEditRoute={hasPermissionToEditRoute} />
          <Taxes />
        </Col>
      </Row>
    </ApplicationLayout>
  );
}
