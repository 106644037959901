/* eslint-disable import/no-unresolved */
import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import Text from 'v3/components/Text';
import Table from 'v3/components/Table';
import Badge from 'v3/components/Badge';
import { FaStar } from 'react-icons/fa';

export default function LastTravels({ lastTravels }) {
  function formatCityProvince(obj) {
    if (obj) {
      return `${
        obj.city && obj.province
          ? `${obj.city} - ${obj.province}`
          : obj.city
          ? obj.city
          : obj.province
          ? obj.province
          : ''
      }`;
    }

    return '';
  }
  function formatDate(date) {
    const isValid = moment(date).isValid();
    return isValid ? moment(date).format('DD/MM/YYYY') : '';
  }

  return (
    <Table>
      <thead>
        <tr>
          <th className="text-center">
            <Text type="label" weight="500">
              Avaliação
            </Text>
          </th>
          <th className="text-center">
            <Text type="label" weight="500">
              N° da viagem
            </Text>
          </th>
          <th>
            <Text type="label" weight="500">
              Origem
            </Text>
          </th>
          <th>
            <Text type="label" weight="500">
              Destino
            </Text>
          </th>
          <th className="text-center">
            <Text type="label" weight="500">
              Status
            </Text>
          </th>
        </tr>
      </thead>
      <tbody>
        {lastTravels.map(travel => {
          const [origin] = travel.originList;
          const lastDestination =
            travel.destinationList[travel.destinationList.length - 1];

          return (
            <tr>
              <td className="text-center">
                <Text type="regular">
                  {travel.ratings !== null
                    ? (
                        (travel?.ratings?.rating_app +
                          travel?.ratings?.rating_general +
                          travel?.ratings?.rating_schedules) /
                        3
                      ).toFixed(2)
                    : 'Sem avaliação'}
                </Text>
                {travel.ratings && (
                  <FaStar size={12} color="#FFA800" className="p-0 mb-2 ml-1" />
                )}
              </td>
              <td className="text-center">
                <Link
                  to={`/viagens/${travel.attended_id}`}
                  key={travel.attended_id}
                >
                  <Text type="regular" color="primary">
                    {travel.attended_id}
                  </Text>
                </Link>
              </td>
              <td>
                {origin && (
                  <>
                    <Text type="regular">{formatCityProvince(origin)}</Text>
                    <br />
                    <Text type="regular" color="gray">
                      {formatDate(origin.scheduled_time)}
                    </Text>
                  </>
                )}
              </td>
              <td>
                {lastDestination && (
                  <>
                    <Text type="regular">
                      {formatCityProvince(lastDestination)}
                    </Text>
                    <br />
                    <Text type="regular" color="gray">
                      {formatDate(lastDestination.scheduled_time)}
                    </Text>
                  </>
                )}
              </td>
              <td width="200" className="text-center">
                <Badge variant={travel.status?.name}>
                  <Text type="regular" color="#fff">
                    {travel?.status?.name}
                  </Text>
                </Badge>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}
