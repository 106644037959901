const Yup = require('yup');

export const editLoadFreightSchema = Yup.object().shape({
  negotiatorObs: Yup.string().max(1000, 'Máximo 1000 caracteres').nullable(),
  balance: Yup.number().when(['fleetVehicleType'], {
    is: fleetVehicleType => fleetVehicleType === 'Próprio',
    then: Yup.number().notRequired(),
    otherwise: Yup.number()
      .moreThan(0, 'Valor de frete obrigatório')
      .required('Valor de frete obrigatório'),
  }),
  advanceMoneyPercentage: Yup.number().when(
    'advance_money_percentage_external',
    advance_money_percentage_external => {
      if (advance_money_percentage_external) {
        return Yup.number()
          .lessThan(
            advance_money_percentage_external + 1,
            'Valor deve ser menor que porcentagem do benner'
          )
          .typeError('Valor deve ser menor que porcentagem do benner');
      }
    }
  ),
});
