import React from 'react';
import { formatDateTime } from 'v3/utils/formatter';
import { Card, Text } from 'v3/components';
import { StyledTrackDiv, StyledTrackingCol, TrackingContainer } from './styles';
import { useClientTravelDetail } from './context';
import { DefaultLoadingBodyComponent } from 'v3/components/Card';
import { FaExclamationCircle } from 'react-icons/fa';
import theme from 'v3/theme';
import { differenceInHours, format } from 'date-fns';

const collectConcludedStatus = [
  'RELEASED_COLLECTION',
  'ST8',
  'ST3',
  'ST4',
  'ST5',
  'ST6',
  'ENDED_LOADING',
  'ST9',
  'DOCS_ISSUED',
];

const loadingConcludedStatus = [
  'ST3',
  'ST4',
  'ST5',
  'ST6',
  'ST8',
  'ENDED_LOADING',
  'ST9',
  'DOCS_ISSUED',
];

const loadingInProgressStatus = ['RELEASED_COLLECTION'];

const transitConcludedStatus = ['ST3', 'ST4', 'ST5', 'ST6'];

const transitInProgressStatus = ['ST8', 'ENDED_LOADING', 'ST9', 'DOCS_ISSUED'];

const customHouseOriginStatus = ['ST3', 'ST4', 'ST5', 'ST6'];
const customHouseDestinationStatus = ['ST3', 'ST4', 'ST5', 'ST6', 'ST8'];

const dischargingConcludedStatus = ['ST4', 'ST5', 'ST6'];
const dischargingInProgress = ['ST3'];

const concludedStatus = ['ST6'];
const concludedInProgress = ['ST5'];

const finishedTravel = ['ST10', 'ST7', 'BLOCKED']

export function TrackingCard() {
  const { data, isFetching } = useClientTravelDetail();
  const hasOriginArrivalTime = data?.loads?.loadOrigins?.[0]?.arrivalTime;
  const hasOriginEffectiveTime = data?.loads?.loadOrigins?.[0]?.effectiveTime;

  const hasLastDestinationArrivalTime =
    data?.loads?.loadDestinations?.[data?.loads?.loadDestinations?.length - 1]
      ?.arrivalTime;
  const hasLastDestinationEffectiveTime =
    data?.loads?.loadDestinations?.[data?.loads?.loadDestinations?.length - 1]
      ?.effectiveTime;
  const isCollectConcluded = collectConcludedStatus.includes(
    data?.travelStatus?.code
  );
  const isLoadingConcluded = loadingConcludedStatus.includes(
    data?.travelStatus?.code
  );
  const isLoadingInProgress = loadingInProgressStatus.includes(
    data?.travelStatus?.code
  );
  const isTransitConcluded = transitConcludedStatus.includes(
    data?.travelStatus?.code
  );
  const isInTransitInProgress = transitInProgressStatus.includes(
    data?.travelStatus?.code
  );

  const hasOriginCustomHouse =
    data?.statusTracking?.find(item => item.code === 'custom_house_origin')?.ative ===
    true;
  const hasDestinationCustomHouse =
    data?.statusTracking?.find(item => item.code === 'custom_house_destination')
      ?.ative === true;

  const isDischargingConclued = dischargingConcludedStatus.includes(
    data?.travelStatus?.code
  );
  const isDischargingInProgress = dischargingInProgress.includes(
    data?.travelStatus?.code
  );
  const isConcluded = concludedStatus.includes(data?.travelStatus?.code);
  const isConcludedInProgress = concludedInProgress.includes(
    data?.travelStatus?.code
  );
  const collectTracking = data?.statusTracking?.find(item => item.code === 'collect');
  const loadingTracking = data?.statusTracking?.find(item => item.code === 'loading');
  const travelingTracking = data?.statusTracking?.find(
    item => item.code === 'traveling'
  );
  const customHouseOriginTracking = data?.statusTracking?.find(
    item => item.code === 'custom_house_origin'
  );
  const customHouseDestinationTracking = data?.statusTracking?.find(
    item => item.code === 'custom_house_destination'
  );
  const customHouseTravelingTracking = data?.statusTracking?.find(
    item => item.code === 'custom_house_traveling'
  );
  const dischargingTracking = data?.statusTracking?.find(
    item => item.code === 'unloading'
  );
  const finishedTracking = data?.statusTracking?.find(
    item => item.code === 'finished'
  );

  const isCustomHouseOriginConcluded =
    customHouseOriginTracking?.finished &&
    customHouseOriginStatus.includes(data?.travelStatus?.code);
  const isCustomHouseOriginInProgress = customHouseOriginStatus.includes(
    data?.travelStatus?.code
  );

  const isCustomHouseDestinationConcluded =
    customHouseDestinationTracking?.finished &&
    customHouseOriginStatus.includes(data?.travelStatus?.code);
  const isCustomHouseDestinationInProgress =
    customHouseOriginTracking?.finished &&
    customHouseDestinationStatus.includes(data?.travelStatus?.code);

  function getInTransitLineIcon() {
    if (hasOriginCustomHouse) {
      if (isTransitConcluded && customHouseOriginTracking?.finished) {
        return 'line-active.svg';
      } else if (isTransitConcluded && isCustomHouseOriginInProgress) {
        return 'line-progress.svg';
      } else {
        return 'line-disabled.svg';
      }
    } else {
      if (isTransitConcluded && isDischargingConclued) {
        return 'line-active.svg';
      } else if (isTransitConcluded && isDischargingInProgress) {
        return 'line-progress.svg';
      } else {
        return 'line-disabled.svg';
      }
    }
  }
  function getCustomHouseOriginLineIcon() {
    if (hasDestinationCustomHouse) {
      if (isCustomHouseOriginConcluded && isCustomHouseDestinationConcluded) {
        return 'line-active.svg';
      } else if (
        isCustomHouseOriginConcluded &&
        isCustomHouseDestinationInProgress
      ) {
        return 'line-progress.svg';
      } else {
        return 'line-disabled.svg';
      }
    } else {
      if (isCustomHouseOriginConcluded && customHouseTravelingTracking?.date) {
        return 'line-active.svg';
      } else if (
        customHouseOriginTracking?.date &&
        customHouseDestinationStatus.includes(data?.travelStatus?.code)
      ) {
        return 'line-progress.svg';
      } else {
        return 'line-disabled.svg';
      }
    }
  }
  function getCustomHouseDestinationLineIcon() {
    if (customHouseTravelingTracking?.date) {
      return 'line-active.svg';
    } else if (
      isCustomHouseOriginConcluded &&
      isCustomHouseDestinationConcluded
    ) {
      return 'line-progress.svg';
    }
    return 'line-disabled.svg';
  }
  function getCustomHouseTransitLineIcon() {
    if (isDischargingConclued) {
      return 'line-active.svg';
    } else if (
      isDischargingInProgress &&
      customHouseTravelingTracking?.finished
    ) {
      return 'line-progress.svg';
    } else return 'line-disabled.svg';
  }

  const firstCustomHouseLabel = `Aduana ${
    customHouseDestinationTracking?.ative ? 'Origem' : ''
  }`;

  const locationTracking =
  data?.location == 0
  ? ' '
  : data?.location == 1
  ? 'Ultima localização obtida via rastreador próximo a'
  : 'Ultima localização obtida via aplicativo próximo a';

  return (
    <Card
      loading={isFetching}
      LoadingBodyComponent={() => (
        <DefaultLoadingBodyComponent linesCount={4} perLine={2} />
      )}
      bodyCardPadding="1rem"
    >
      <div className="d-flex align-items-center justify-content-between">
        <Text type="medium" style={{fontStyle: 'italic'}}>
        {!!data?.travelLocation?.length && `${locationTracking} ${data?.travelLocation[0]?.locationInformation}`}
        </Text>
        {data?.tracking?.distanceCoveredInMeters > 0 && data?.tracking?.remainingDistanceInMeters > 0 ?
        <Text type="medium">Distancia Efetuada: {(data?.tracking?.distanceCoveredInMeters/1000).toFixed(0)} Km</Text>
        :''}
        {data?.tracking?.distanceCoveredInMeters > 0 && data?.tracking?.remainingDistanceInMeters > 0 ?
        <Text type="medium">Distancia Restante: {(data?.tracking?.remainingDistanceInMeters/1000).toFixed(0)} Km</Text>
        :''}
      </div>
      {differenceInHours(new Date(),new Date(data?.travelLocation?.length ? data?.travelLocation[0]?.capturedAt : '')) >= 24 &&
      <div className="d-flex align-items-center justify-content-between">
      <Text>
        <FaExclamationCircle color={theme.colors.danger}size={14}/>
        {' '} Última localização excedeu 24 horas
        </Text>
      </div>
      }
      <TrackingContainer>
        <StyledTrackingCol>
          <TrackingItem
            label={'Coleta'}
            icon={`${
              isCollectConcluded
                ? 'icon-collect.svg'
                : 'icon-collect-disabled.svg'
            }`}
            lineIcon={`${
              isCollectConcluded && isLoadingConcluded
                ? 'line-active.svg'
                : isCollectConcluded && isLoadingInProgress
                ? 'line-progress.svg'
                : 'line-disabled.svg'
            }`}
            date={hasOriginArrivalTime ?? collectTracking?.date}
            stepFinished={isCollectConcluded}
          />

          <TrackingItem
            label={'Carregamento'}
            icon={`${
              isLoadingConcluded
                ? 'icon-charging.svg'
                : 'icon-charging-disabled.svg'
            }`}
            lineIcon={`${
              isLoadingConcluded && isTransitConcluded
                ? 'line-active.svg'
                : isLoadingConcluded && isInTransitInProgress
                ? 'line-progress.svg'
                : 'line-disabled.svg'
            }`}
            date={hasOriginEffectiveTime ?? loadingTracking?.date}
            stepFinished={isLoadingConcluded}
            approximateArrivalTime={(!isLoadingConcluded && !finishedTravel.includes(data?.travelStatus?.code)) ? data?.tracking?.approximateArrivalTime : null}
            updatedAt={data?.tracking?.updatedAt}
          />

          <TrackingItem
            label={'Trânsito'}
            icon={`${
              isTransitConcluded
                ? 'icon-transit.svg'
                : 'icon-transit-disabled.svg'
            }`}
            date={travelingTracking?.date}
            lineIcon={getInTransitLineIcon()}
            stepFinished={isTransitConcluded}
          />

          {hasOriginCustomHouse && (
            <TrackingItem
              label={firstCustomHouseLabel}
              icon={`${
                isCustomHouseOriginConcluded
                  ? 'icon-in-customhouse.svg'
                  : 'icon-in-customhouse-disabled.svg'
              }`}
              date={customHouseOriginTracking?.date}
              lineIcon={getCustomHouseOriginLineIcon()}
              stepFinished={isCustomHouseOriginConcluded}
            />
          )}

          {hasDestinationCustomHouse && (
            <TrackingItem
              label={'Aduana Destino'}
              icon={`${
                isCustomHouseDestinationConcluded
                  ? 'icon-in-customhouse.svg'
                  : 'icon-in-customhouse-disabled.svg'
              }`}
              date={customHouseDestinationTracking?.date}
              lineIcon={getCustomHouseDestinationLineIcon()}
              stepFinished={isCustomHouseDestinationConcluded}
            />
          )}

          {(hasOriginCustomHouse || hasDestinationCustomHouse) && (
            <TrackingItem
              label={'Trânsito'}
              icon={`${
                customHouseTravelingTracking?.finished
                  ? 'icon-transit.svg'
                  : 'icon-transit-disabled.svg'
              }`}
              date={customHouseTravelingTracking?.date}
              lineIcon={getCustomHouseTransitLineIcon()}
              stepFinished={customHouseTravelingTracking?.finished}
            />
          )}

          <TrackingItem
            label={'Descarregamento'}
            icon={`${
              isDischargingConclued
                ? 'icon-discharging.svg'
                : 'icon-discharging-disabled.svg'
            }`}
            date={hasLastDestinationArrivalTime ?? dischargingTracking?.date}
            lineIcon={`${
              isDischargingConclued &&
              (isConcluded || hasLastDestinationEffectiveTime)
                ? 'line-active.svg'
                : isDischargingConclued && isConcludedInProgress
                ? 'line-progress.svg'
                : 'line-disabled.svg'
            }`}
            stepFinished={isDischargingConclued}
            approximateArrivalTime={isLoadingConcluded ? data?.tracking?.approximateArrivalTime : null}
            updatedAt={data?.tracking?.updatedAt}
          />

          <FinishedDeliveryTrack
            label={'Entrega Concluída'}
            icon={`${
              isConcluded || hasLastDestinationEffectiveTime
                ? 'icon-concluded.svg'
                : 'icon-concluded-disabled.svg'
            }`}
            date={hasLastDestinationEffectiveTime ?? finishedTracking?.date}
            stepFinished={isConcluded || hasLastDestinationEffectiveTime}
          />
        </StyledTrackingCol>
      </TrackingContainer>
    </Card>
  );
}

function TrackingItem({ label, icon, date, lineIcon, stepFinished, approximateArrivalTime, updatedAt }) {
  return (
    <StyledTrackDiv>
      <Text type="medium">{label}</Text>
      <div>
        <img
          src={`${process.env.REACT_APP_REDIRECR_URI}/images/travel-notify/${icon}`}
        />
        <img
          src={`${process.env.REACT_APP_REDIRECR_URI}/images/travel-notify/${lineIcon}`}
        />
      </div>
      {approximateArrivalTime > 0 && !stepFinished ? (
        <div style={{display:'flex', flexDirection:'column'}}>
          <Text type="medium">Previsão de Chegada</Text>
          <Text type="medium">{formatDateTime(updatedAt,{add:{type:'seconds',time:approximateArrivalTime}})}</Text>
        </div>
      ) : date !== undefined && stepFinished ? (
        <Text type="medium">{formatDateTime(date)}</Text>
      ) : (
        <Text type="medium" style={{ opacity: stepFinished ? 1 : 0 }}>{`${
          stepFinished ? 'Não informado' : '-'
        }`}</Text>
      )}
    </StyledTrackDiv>
  );
}

function FinishedDeliveryTrack({ label, icon, date, stepFinished }) {
  return (
    <StyledTrackDiv>
      <Text type="medium" style={{ whiteSpace: 'nowrap' }}>
        {label}
      </Text>
      <div>
        <img
          src={`${process.env.REACT_APP_REDIRECR_URI}/images/travel-notify/${icon}`}
        />
        <img
          style={{ opacity: 0 }}
          src={`${process.env.REACT_APP_REDIRECR_URI}/images/travel-notify/line-disabled.svg`}
        />
      </div>
      {date !== undefined && stepFinished ? (
        <Text type="medium">{formatDateTime(date)}</Text>
      ) : (
        <Text type="medium" style={{ opacity: stepFinished ? 1 : 0 }}>{`${
          stepFinished ? 'Não informado' : '-'
        }`}</Text>
      )}
    </StyledTrackDiv>
  );
}
