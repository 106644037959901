import Button from '../Button';
import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { HiOutlineZoomIn, HiZoomOut } from 'react-icons/hi';
import { Box } from '@material-ui/core';
import { Row } from 'react-bootstrap';

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
export default function PDFView({ file }) {
  const [pagesQty, setPagesQty] = useState(0);
  const [zoomInOut, setZoomInOut] = useState(1);

  function onDocumentLoadSuccess(data) {
    setPagesQty(data.numPages);
  }

  const zoomIncremet = () => {
    setZoomInOut(zoomInOut + 0.2);
  };

  const zoomDecremet = () => {
    setZoomInOut(zoomInOut - 0.2);
  };

  return (
    <Document
      style={{ width: '100%' }}
      file={file}
      onLoadError={console.log}
      onLoadSuccess={onDocumentLoadSuccess}
      onSourceError={console.log}
    >
      <Box direction="row">
        <Row>
          <Button style={{ marginLeft: 20 }} onClick={() => zoomIncremet()}>
            Zoom In
            <HiOutlineZoomIn
              onPress={() => zoomIncremet()}
              name="arrow-down"
              size={26}
            />
          </Button>

          <Button style={{ marginLeft: 50 }} onClick={() => zoomDecremet()}>
            Zoom Out
            <HiZoomOut
              onPress={() => zoomDecremet()}
              name="arrow-down"
              size={26}
            />
          </Button>
        </Row>
      </Box>
      {Array.from(new Array(pagesQty), (el, index) => (
        <Page
          scale={zoomInOut}
          key={`page_${index + 1}`}
          pageNumber={index + 1}
        />
      ))}
    </Document>
  );
}
