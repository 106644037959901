import React from 'react';

import Card from 'components/Card';
import Input from 'components/Input';

import StatusSelector from './statusSelector';

function ConversationSearch({ handleStatusSelected, setLoadNumber, ...props }) {
  return (
    <>
      <StatusSelector handleStatusSelected={handleStatusSelected} />
      <div className="search">
        <Input
          label="Número da Carga"
          onChange={e => setLoadNumber(e.target.value)}
        />
      </div>
    </>
  );
}

export default ConversationSearch;
