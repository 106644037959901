import React from 'react';

import { WiThermometer } from "react-icons/wi"
import { formatDateTime } from 'v3/utils/formatter';

import theme from 'v3/theme';

import { FaClock } from 'react-icons/fa';

import { StyledCellSpan } from '../MuiTable/styles';

function ColumnTemperature({ paramsTemperature }) {
  return (
    <>
      {paramsTemperature?.temperature1 && paramsTemperature?.temperature1 !== 'N' ? (
        <>
          <StyledCellSpan>
            <WiThermometer color={theme.colors.gray} size={22} />{' '}
            {`${paramsTemperature?.temperature1}°C`}{'  '}

            <FaClock color={theme.colors.gray} size={14} />{' '}
            {formatDateTime(paramsTemperature?.datetimeTemperature1)}
          </StyledCellSpan>
        </>
      ) : (
        <StyledCellSpan>
          Temperatura 1: Não informado
        </StyledCellSpan>)}
      {paramsTemperature?.temperature2 && paramsTemperature?.temperature2 !== 'N' ? (
        <>
          <StyledCellSpan>
            <WiThermometer color={theme.colors.gray} size={22} />{' '}
            {`${paramsTemperature?.temperature2}°C`}{'  '}

            <FaClock color={theme.colors.gray} size={14} />{' '}
            {formatDateTime(paramsTemperature?.datetimeTemperature2)}
          </StyledCellSpan>
        </>
      ) : (
        <StyledCellSpan>
          Temperatura 2: Não informado
        </StyledCellSpan>)}
      {paramsTemperature?.temperature3 && paramsTemperature?.temperature3 !== 'N' ? (
        <>
          <StyledCellSpan>
            <WiThermometer color={theme.colors.gray} size={22} />{' '}
            {`${paramsTemperature?.temperature3}°C`}{'  '}

            <FaClock color={theme.colors.gray} size={14} />{' '}
            {formatDateTime(paramsTemperature?.datetimeTemperature3)}
          </StyledCellSpan>
        </>
      ) : (
        <StyledCellSpan>
          Temperatura 3: Não informado
        </StyledCellSpan>)}
      {paramsTemperature?.temperature4 && paramsTemperature?.temperature4 !== 'N' ? (
        <>
          <StyledCellSpan>
            <WiThermometer color={theme.colors.gray} size={22} />{' '}
            {`${paramsTemperature?.temperature4}°C`}{'  '}

            <FaClock color={theme.colors.gray} size={14} />{' '}
            {formatDateTime(paramsTemperature?.datetimeTemperature4)}
          </StyledCellSpan>
        </>
      ) : (
        <StyledCellSpan>
          Temperatura 4: Não informado
        </StyledCellSpan>)}
      {paramsTemperature?.averageTemperature && paramsTemperature?.averageTemperature !== 'N' ? (
        <>
          <StyledCellSpan>
            <WiThermometer color={theme.colors.gray} size={22} />{' '}
            {`Media atual: ${paramsTemperature?.averageTemperature}°C`}{'  '}

            <FaClock color={theme.colors.gray} size={14} />{' '}
            {formatDateTime(paramsTemperature?.averageTemperatureCreatedAt)}
          </StyledCellSpan>
        </>
      ) : (<></>)}
    </>
  );
}

export default ColumnTemperature;
