import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import ApplicationLayout from 'v3/layouts/Application';
import { useSnackbar } from 'v3/components/Snackbar';
import Space from 'v3/components/Space';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import { validatePermission } from 'actions/index';
import { RegisterInfo } from 'v3/components/RegisterInfo';
import Data from './Data';
import PaymentMethod from './PaymentMethod';
import LastTravels from './LastTravels';
import Address from '../shared/Address';
import AttachedDrivers from '../shared/AttachedDrivers/index';
import AttachedVehicles from '../shared/AttachedVehicles';
import Attachments from './Attachments';
import BennerStatus from './BennerStatus';
import { useShippingCompany } from '../shared/context';

export default function ShippingCompanyDetails() {
  const [attachments, setAttachments] = useState([]);
  const shippingCompany = useShippingCompany();
  const hasPermissionToCreate = validatePermission('CADASTRAR_TRANSPORTADORA');

  const snackbar = useSnackbar();
  useEffect(() => {
    snackbar.show(
      <Text>
        Alterações de dados cadastrais devem ser solicitadas na viagem no card
        "Solicitação de Ajuste de Cadastro".
      </Text>,
      { type: 'warning' }
    );
  }, []);

  return (
    <ApplicationLayout
      title="Transportadora"
      RightComponent={
        <Space x="4px">
          <Link to="/transportadoras">
            <Button variant="secondary">
              <Text color="dark" weight={500} type="regular">
                Voltar
              </Text>
            </Button>
          </Link>

          {hasPermissionToCreate && (
            <Link to="cadastrar">
              <Button>
                <Text color="white" weight={500} type="regular">
                  Nova transportadora
                </Text>
              </Button>
            </Link>
          )}
        </Space>
      }
    >
      <Row>
        <Col xs={12}>
          <RegisterInfo loading={shippingCompany.isFetching} />
        </Col>
        <Col xs={12} md={6}>
          <Data />
          <PaymentMethod />
        </Col>
        <Col xs={12} md={6}>
          <Address />
          <AttachedVehicles />
          <BennerStatus />
        </Col>
        <Col md={12}>
          <AttachedDrivers />
          <LastTravels />
        </Col>
        <Col md={12}>
          <Attachments
            attachments={attachments}
            setAttachments={setAttachments}
          />
        </Col>
      </Row>
    </ApplicationLayout>
  );
}
