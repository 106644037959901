/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-expressions */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';

import { Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { fetchCostCenter } from 'utils/fetches';

import Input from 'v3/components/Input';
import TextArea from 'v3/components/TextArea';
import Select from 'v3/components/Select';
import Text from 'v3/components/Text';
import { useSnackbar } from 'v3/components/Snackbar';
import Card from 'v3/components/Card';

import { validatePermission } from 'actions';
import api, { cooperplaceApi } from 'services/api';

import {
  currencyOptions,
  formatCurrency,
  makeConversion,
} from 'v3/utils/formatter';
import { Link } from 'v3/components';
import { validatePolicyValidity } from 'v3/validators/loadValidators';
import { fetchSelectOptions, TravelContext } from '../controller';
import * as Controller from './controller';

const [, useTravel] = TravelContext;

export default function LoadData() {
  const travel = useTravel();
  const params = useParams();
  const snackbar = useSnackbar();

  const [errors, setErrors] = useState('');
  const [inputTag, setInputTag] = useState('');

  const hasPermissionToSelectTags = validatePermission('SELECIONAR_TAGS');
  const hasPermissionToCreateTags = validatePermission('CRIAR_TAGS');
  const travelCurrency = travel?.data?.currency;
  const travelCurrencyStatus = travelCurrency === 'BRL' ? 'Compra' : 'Venda';

  useEffect(() => {
    async function fetchUserTags() {
      if (!params.loadId) {
        const userTags = await Controller.fetchTags();
        travel.setData(old => ({ ...old, tags: userTags }));
      }
    }
    fetchUserTags();
  }, [params, travel.isLoading]);

  function tagTreatment(input) {
    if (input) {
      const inputFiltered = input.trimStart().replace(/[^a-zA-Z0-9 ]/g, '');
      setInputTag(inputFiltered);
    } else {
      setInputTag('');
    }
  }

  async function handleTagCreate(tagValue) {
    if (hasPermissionToCreateTags) {
      if (tagValue) {
        // eslint-disable-next-line no-param-reassign
        tagValue = tagValue.trim();
        if (tagValue.length >= 3) {
          try {
            const response = await api.post('tags', { name: tagValue });
            if (response.status === 200) {
              const newTag = {
                id: response.data.id,
                name: tagValue,
              };
              setInputTag('');
              travel.setData(old => ({
                ...old,
                tags: travel.data.tags
                  ? [...travel.data.tags, newTag]
                  : [newTag],
              }));
            }
          } catch (err) {
            // handle exception
          }
        }
      }
    } else {
      snackbar.show(<Text>Usuário sem permissão para criar tags</Text>, {
        type: 'error',
      });
    }
  }

  async function handleImportLoadData() {
    try {
      if (travel && travel.data && travel.data.loadId) {
        travel.setIsLoading(true);
        const responseLoad = await cooperplaceApi.get(
          `loads/${travel.data.loadId}`
        );
        const loadData = responseLoad.data;
        if (!loadData) {
          setErrors('Carga inválida');
          travel.setData(old => ({
            ...old,
            invalidLoad: true,
          }));
          travel.setIsLoading(false);

          throw new Error();
        }
        if (loadData.travel) {
          snackbar.show(
            <Text>
              Carga já está sendo usada em outra viagem! Não é possível atender
              mesma carga em mais de uma viagem!
            </Text>,
            {
              type: 'error',
            }
          );
          travel.setData(old => ({
            ...old,
            loadId: '',
          }));
          setErrors('Carga em uso');
          travel.setIsLoading(false);

          return;
        }

        if (loadData.deletedAt) {
          snackbar.show(
            <Text>Não é possível atender uma carga cancelada!</Text>,
            {
              type: 'error',
            }
          );
          setErrors('Carga cancelada');
          travel.setIsLoading(false);

          return;
        }
        if (loadData.loadPolicy?.id && loadData?.loadPolicy?.policy?.dueDate) {
          const isValid = validatePolicyValidity(
            loadData?.loadPolicy?.policy?.dueDate
          );
          if (!isValid) {
            snackbar.show(
              <Text>
                Não é possível atender carga atrelada à apólice vencida! Entre
                em contato com o setor de GRO
              </Text>,
              {
                type: 'error',
              }
            );
            setErrors('Carga com apólice vencida');
            travel.setIsLoading(false);

            return;
          }
        }
        travel.setIsGROLoad(loadData?.travel ?? null);
        const issueRateItem = loadData?.shipmentDiscounts?.find(
          item => item.label.toLowerCase() === 'taxa de emissão'
        );

        const dollarValue = loadData.freightExchange;
        const isBRLCurrency = loadData.currency === 'BRL';
        const valuesCurrency = loadData.currency;
        const [origin] = loadData.loadOrigins;
        travel.setData(old => ({
          ...old,
          origin: {
            ...origin,
            cityModel: origin.cityModel || {
              name: origin.city,
            },
          },
          destinations: loadData.loadDestinations.map(destination => ({
            ...destination,
            cityModel: destination.cityModel || {
              name: destination.city,
            },
          })),
          exchange: loadData.exchange,
          currency: currencyOptions.find(
            currency => currency.value === loadData?.loadCurrency
          ),
          loadId: loadData.id,
          client: loadData.taker,
          tags: loadData.tags,
          riskManager: loadData.riskManagers ? loadData.riskManagers[0] : null,
          tracker: loadData.riskManager,
          invalidLoad: false,
          userCreated: loadData.userCreated,
          costCenter: loadData.costCenter,
          cargo_value: loadData.cargoValue,
          advance_money_percentage_external:
            loadData.advanceMoneyPercentageExternal || 0,
          advance_money_percentage: loadData?.advanceMoneyPercentage || 0,
          toll_value: loadData.tollValue,
          tollObservation: loadData?.tollObservation,
          discharge_value: loadData.dischargeValue,
          dischargeObservation: loadData?.dischargeObservation,
          ferry: loadData.ferry,
          ferryObservation: loadData?.ferryObservation,
          fare_company: loadData?.fareCompany,
          editableFareCompany: loadData?.fareCompany,
          charge_value: loadData?.chargeValue,
          chargeObservation: loadData?.chargeObservation,
          gris: loadData?.gris,
          total_inputs: loadData?.totalInputs,
          editableTotalInputs: loadData?.totalInputs,
          total_taker_value: loadData?.totalTakerValue,
          ad_valorem: loadData?.adValorem,
          grissAdObservation: loadData?.grissAdObservation,
          pcp_observation: loadData.pcpObservation,
          isBRLCurrency,
          load_value_type: loadData?.loadValueType,
          negotiation_type: loadData?.negotiationType,
          freight_values_currency: valuesCurrency,
          freight_values_exchange: loadData.freightExchange,
          exchange_day: loadData?.exchangeDay,
          total_shipment_transfer: loadData?.totalShipmentTransfer,
          total_shipment_discounts: loadData?.totalShipmentDiscounts,
          issueRateId: issueRateItem?.id,
          issueRate: issueRateItem?.value,
          shipmentDiscounts: loadData?.shipmentDiscounts
            ?.filter(item => item.label.toLowerCase() !== 'taxa de emissão')
            .map(item => ({
              ...item,
              value:
                valuesCurrency === 'BRL'
                  ? item.value
                  : +makeConversion(item.value, '*', dollarValue).toFixed(2),
            })),
          shipmentTransfer: loadData.shipmentTransfers.map(item => ({
            ...item,
            value:
              valuesCurrency === 'BRL'
                ? item.value
                : +makeConversion(item.value, '*', dollarValue).toFixed(2),
          })),
          company: loadData?.company,
        }));
        travel.setIsGROLoad(
          loadData?.loadPolicy?.id ? loadData?.loadPolicy : null
        );
        setErrors('');
      } else
        snackbar.show(
          <Text>
            Você deve escrever o número da carga para importar seus dados
          </Text>,
          {
            type: 'error',
          }
        );
      travel.setIsLoading(false);
    } catch (error) {
      snackbar.show(<Text>Carga inválida</Text>, {
        type: 'error',
      });
      travel.setIsLoading(false);
    }
  }

  useEffect(() => {
    if (!travel.data?.loadId || travel.data?.loadId === undefined) {
      travel.setData(old => ({
        ...old,
        invalidLoad: false,
      }));
    }
  }, [travel.data.loadId]);

  async function fetchUser(id) {
    try {
      const response = await api.get(`cost-centers/user/${id}`);
      travel.setData(old => ({
        ...old,
        user: response.data,
        costCenter: response.data?.costCenters[0],
      }));
    } catch (ex) {
      snackbar.show('Não foi possível carregar esse usuário', {
        type: 'error',
      });
    }
  }

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('usuario'));
    fetchUser(user.id);
  }, []);

  return (
    <Card
      header={
        <Text color="#464E5F" type="header">
          Dados da carga
        </Text>
      }
      HeaderRightComponent={
        travel.data?.loadId && travel?.data?.loadGROData?.policyId ? (
          <Link
            to={`/apolices/${travel?.data?.loadGROData?.policyId}`}
            target="_blank"
          >
            <Text className="ml-2" type="label" color="gray">
              ver apólice
            </Text>
          </Link>
        ) : null
      }
    >
      <Row className="form">
        <Col xs={12}>
          <Input
            label="N° da carga *"
            type="text"
            masked
            disabled={params.loadId}
            guide={false}
            mask={[
              /[0-9]/,
              /[0-9]/,
              /[0-9]/,
              /[0-9]/,
              /[0-9]/,
              /[0-9]/,
              /[0-9]/,
              /[0-9]/,
            ]}
            value={travel.data.loadId}
            onChange={({ target }) => {
              travel.setData(old => ({
                ...old,
                loadId: target.value,
                client: null,
                costCenter: null,
                currency: null,
                advance_money_percentage: 0,
                advance_money_percentage_external: 0,
                origin: null,
                destinations: [],
                exchange: 0,
                riskManager: null,
                riskManagers: [],
                tags: [],
                tracker: null,
                userCreated: null,
                cargo_value: null,
                toll_value: null,
                tollObservation: null,
                discharge_value: null,
                dischargeObservation: null,
                ferry: null,
                ferryObservation: null,
                fare_company: null,
                editableFareCompany: null,
                charge_value: null,
                chargeObservation: null,
                gris: null,
                total_inputs: null,
                editableTotalInputs: null,
                total_taker_value: null,
                ad_valorem: null,
                grissAdObservation: null,
                pcp_observation: '',
                load_value_type: null,
                negotiation_type: null,
                freight_values_currency: null,
                freight_values_exchange: null,
                exchange_day: null,
                total_shipment_transfer: null,
                total_shipment_discounts: null,
                issueRateId: null,
                issueRate: null,
                shipmentDiscounts: [],
                shipmentTransfer: [],
                company: null,
              }));
              travel.setIsGROLoad(null);
              if (!target.value.length < 1) {
                travel.setData(old => ({ ...old, negotiator_comments: '' }));
              }
            }}
            onBlur={handleImportLoadData}
            error={errors || travel.errors.loadId}
            id="input-numero-carga"
          />
        </Col>
        <Col xs={12}>
          <Select.Async
            label={
              <Text type="label" weight="500">
                Cliente Tomador *
              </Text>
            }
            onSearch={search =>
              fetchSelectOptions('persons/customers', { search })
            }
            value={travel.data.client}
            getOptionLabel={option => {
              let label = option?.socialName
                ? `${option.socialName} - ${option.cpfCnpj}`
                : `${option.social_name} - ${option.cgccpf}`;

              if (option.city) {
                label += ` - ${option.city}`;
              }
              return label;
            }}
            getOptionValue={option => option.id}
            onChange={item => travel.setData(old => ({ ...old, client: item }))}
            error={travel.errors.clientId}
            isDisabled={travel?.data?.loadId}
            id="select-cliente"
          />
        </Col>
        {travel?.isGROLoad?.operation && travel?.isGROLoad?.policy && (
          <Col xs={12} style={{ marginBottom: 0 }}>
            <Text type="label" color="#464E5F" weight="500">
              Apólice - Operação
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to={`/apolices/${travel?.isGROLoad?.policy?.id}`}
              >
                {`${travel.isGROLoad.policy.name} - ${travel.isGROLoad.operation.name}`}
              </Link>
            </Text>
          </Col>
        )}
        <Col md={6} xs={12}>
          <Select
            label="Moeda *"
            options={currencyOptions}
            getOptionLabel={option => option.label}
            getOptionValue={option => option.value}
            value={travel.data?.currency}
            onChange={value =>
              travel.setData(old => ({ ...old, currency: value }))
            }
            error={travel.errors.currency}
            isDisabled={travel?.data?.loadId}
            disableClear
            id="select-moeda"
          />
        </Col>
        <Col md={6}>
          {travel?.data?.exchange ? (
            <>
              <Text
                type="label"
                color="dark"
                weight="500"
                style={{ textAlign: 'center' }}
              >
                {`Cotação (Valor de ${travelCurrencyStatus})`}
              </Text>

              <Text
                type="regular"
                color="gray"
                as="p"
                fontSize="16px"
                style={{ marginTop: '1rem' }}
                id={`label-cotacao-${travelCurrencyStatus.toLocaleLowerCase()}`}
              >
                {formatCurrency(travel?.data?.exchange, 'USD', 'R$')}
              </Text>
            </>
          ) : null}
        </Col>
        <Col md={6}>
          {travel?.data?.cargo_value && travel?.data?.loadGROData?.policyId ? (
            <>
              <Text
                type="label"
                color="dark"
                weight="500"
                style={{ textAlign: 'center' }}
              >
                Valor da mercadoria
              </Text>

              <Text
                type="regular"
                color="gray"
                as="p"
                fontSize="16px"
                style={{ marginTop: '1rem' }}
              >
                {formatCurrency(
                  travel?.data?.cargo_value,
                  travel?.data?.isBRLCurrency ? 'BRL' : 'USD',
                  travel?.data?.isBRLCurrency ? 'R$' : '$'
                )}
              </Text>
            </>
          ) : null}
        </Col>
        <Col md={travel?.data?.loadGROData?.policyId ? 6 : 12} xs={12}>
          <Select.Async
            label="Centro de custo *"
            onSearch={search => fetchCostCenter({ search })}
            value={travel?.data?.costCenter}
            onChange={value =>
              travel.setData(old => ({
                ...old,
                costCenter: value,
              }))
            }
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            error={travel.errors.costCenter}
            isDisabled={travel?.data?.loadId}
            id="select-centro-custo"
          />
        </Col>
        <Col xs={12}>
          <Select.Async
            isDisabled={!hasPermissionToSelectTags}
            label="Tags"
            onSearch={search =>
              fetchSelectOptions('tags', {
                search,
                not_paginated: true,
              })
            }
            multiple
            value={travel.data.tags}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            onChange={item => travel.setData(old => ({ ...old, tags: item }))}
            onInputChange={e => tagTreatment(e)}
            inputValue={inputTag}
            onCreateOption={handleTagCreate}
            creatable
            id="select-tags"
          />
        </Col>
        <Col xs={12}>
          <div
            onClick={() =>
              !travel.data.loadId &&
              snackbar.show(
                <Text>
                  Campo apenas será habilitado quando tiver o número da carga
                </Text>,
                {
                  type: 'warning',
                }
              )
            }
          >
            <TextArea
              label="Observações da negociação"
              type="text"
              value={travel.data.negotiator_comments}
              onChange={({ target }) =>
                travel.setData(old => ({
                  ...old,
                  negotiator_comments: target.value,
                }))
              }
              disabled={!travel.data.loadId}
              id="textarea-observacoes-negociacao"
            />
          </div>
        </Col>
      </Row>
    </Card>
  );
}
