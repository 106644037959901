const Yup = require('yup');

export const NewOccurrencesSchema = Yup.object().shape({
  occurrenceType: Yup.object()
    .required('Tipo de ocorrência obrigatório')
    .typeError('Tipo de ocorrência obrigatório'),
  responsible: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number().required(),
      })
    )
    .min(1)
    .max(5, 'Máximo de 5 grupos')
    .required()
    .typeError('Grupo Responsável obrigatório'),
  description: Yup.string()
    .required('Descrição obrigatória')
    .max(2000, 'Máximo de 2000 caracteres'),
  startDate: Yup.string()
    .required('Forneça uma data válida')
    .test('validDateTime', 'Forneça uma data válida', value => {
      const regex =
        /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/\d{4} (0\d|1\d|2[0-3]):([0-5]\d)$|^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/\d{4} às (0\d|1\d|2[0-3]):([0-5]\d)$/;

      return regex.test(value);
    }),
  endDate: Yup.string()
    .nullable()
    .test('validDateTime', 'Forneça uma data válida', value => {
      if (value === null || value === undefined || value === '') {
        return true;
      }
      const regex =
        /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/\d{4} (0\d|1\d|2[0-3]):([0-5]\d)$|^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/\d{4} às (0\d|1\d|2[0-3]):([0-5]\d)$/;
      if (!regex.test(value)) {
        return false;
      }
      return true;
    }),
});

export const DescriptionSchema = Yup.object().shape({
  description: Yup.string()
    .required('Descrição obrigatória')
    .max(2000, 'Máximo de 2000 caracteres'),
  startDate: Yup.string()
    .required('Forneça uma data válida')
    .test('validDateTime', 'Forneça uma data válida', value => {
      const regex =
        /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/\d{4} (0\d|1\d|2[0-3]):([0-5]\d)$|^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/\d{4} às (0\d|1\d|2[0-3]):([0-5]\d)$/;

      return regex.test(value);
    }),
  endDate: Yup.string()
    .nullable()
    .test('validDateTime', 'Forneça uma data válida', value => {
      if (value === null || value === undefined || value === '') {
        return true;
      }
      const regex =
        /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/\d{4} (0\d|1\d|2[0-3]):([0-5]\d)$|^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/\d{4} às (0\d|1\d|2[0-3]):([0-5]\d)$/;
      if (!regex.test(value)) {
        return false;
      }
      return true;
    }),
});

export const ChangeResponsibleSchema = Yup.object().shape({
  responsible: Yup.array()
    .of(Yup.object().shape({ id: Yup.number().required() }))
    .max(5, 'Máximo de 5 grupos')
    .required('Grupo Responsável obrigatório')
    .typeError('Grupo Responsável obrigatório'),
  description: Yup.string()
    .required('Descrição obrigatória')
    .max(2000, 'Máximo de 2000 caracteres'),
});

export const AttachmentDescriptionSchema = Yup.object().shape({
  description: Yup.string()
    .max(127, 'Máximo de 127 caracteres')
    .required('Descrição obrigatória'),
});
