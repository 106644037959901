import { conformWeight } from 'components/InputMask/Weight';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Text } from 'v3/components';


export function VehicleDetails({ preLoad }) {
  return (
    <Row>
      <Col xs={12}>
        <Text type="label" color="#464E5F">
          Veículos
        </Text>
        <Text type="regular" color="gray" as="p" className="mt-2">
          {preLoad?.vehicleTypes
            ? preLoad?.vehicleTypes?.map(type => type?.name).join(', ')
            : 'Não informado'}
        </Text>
      </Col>
      {preLoad?.vehicleImplementTypes?.length > 0 && (
        <Col xs={12}>
          <Text type="label" color="#464E5F">
            Tipo de implemento
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {preLoad?.vehicleImplementTypes?.map(type => type.name).join(', ')}
          </Text>
        </Col>
      )}
      <Col xs={12}>
        <Text type="label" color="#464E5F">
          Tipo de carroceria
        </Text>
        <Text type="regular" color="gray" as="p" className="mt-2">
          {preLoad?.vehicleBodyTypes
            ? preLoad?.vehicleBodyTypes?.map(type => type.name).join(', ')
            : 'Não informado'}
        </Text>
      </Col>
      <Col md={6} xs={12}>
        <Text type="label" color="#464E5F">
          Disposição da carga
        </Text>
        <Text type="regular" color="gray" as="p" className="mt-2">
          {preLoad?.disposition ? preLoad?.disposition?.name : 'Não informado'}
        </Text>
      </Col>
      <Col md={6} xs={12}>
        <Text type="label" color="#464E5F">
          Peso bruto
        </Text>
        <Text type="regular" color="gray" as="p" className="mt-2">
          {preLoad?.loadWeight && preLoad?.loadWeight
            ? conformWeight(preLoad?.loadWeight?.toString().split('.').join(','))
            : 'Não informado'}
        </Text>
      </Col>
    </Row>
  );
}
