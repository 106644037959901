import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { conformToMask } from 'react-text-mask';
import moment from 'moment';
import 'moment/locale/pt-br';

const numberFormatter = new Intl.NumberFormat('pt-BR');

const currencyMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: '.',
  allowDecimal: true,
  decimalSymbol: ',',
  decimalLimit: 2,
  integerLimit: 7,
  allowNegative: false,
  allowLeadingZeroes: false,
};

const currencyMask = createNumberMask({ ...currencyMaskOptions });

export function makeConversion(value, op, exchangeRate) {
  if (exchangeRate === 0) {
    return 0;
  }
  let aux = 0;
  op === '/' ? (aux = value / exchangeRate) : (aux = value * exchangeRate);
  if (isNaN(aux)) {
    return 0;
  }
  return aux;
}

const customCurrencies = [
  'AOA',
  'BOB',
  'ARS',
  'CLP',
  'COU',
  'ECS',
  'PYG',
  'PEN',
  'UYU',
  'USD',
];

export const currencyOptions = [
  { label: 'Real Brasileiro', value: 'BRL' },
  { label: 'Dólar Americano', value: 'USD' },
];

export const currencyWithSymbol = [
  { currency: 'BRL', symbol: 'R$' },
  { currency: 'USD', symbol: '$' },
];

export const conversionParams = {
  $: { symbol: 'R$', currencyName: 'BRL', operation: '*' },
  R$: { symbol: '$', currencyName: 'USD', operation: '/' },
};

export function conformCurrency(value) {
  const { conformedValue } = conformToMask(value, currencyMask, {
    guide: false,
  });
  return conformedValue;
}

export function formatCurrency(value, currency, symbol) {
  if (value === 0 || value === null || value === undefined || isNaN(value)) {
    if (currency === 'BRL') return 'R$0,00';
    if (currency === 'USD') return '$0,0000';
    return 'R$0,00';
  }

  const formatted =
    currency === 'USD'
      ? new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency,
          maximumFractionDigits: 4,
          minimumFractionDigits: 4,
        }).format(value)
      : currency === 'BRL'
      ? new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency,
          maximumFractionDigits: 2,
        }).format(value)
      : new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          maximumFractionDigits: 2,
        }).format(value);

  if (customCurrencies.includes(currency)) {
    return `${symbol} ${formatted.replace(/[^\d,\\.]/g, '')}`;
  }

  return formatted;
}

const customCurrencyFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
  maximumFractionDigits: 4,
});

export function formatCustomCurrency(value, currency, symbol) {
  const formatted = customCurrencyFormatter.format(value);

  if (customCurrencies.includes(currency)) {
    return `${symbol} ${formatted.replace(/[^\d,]/g, '')}`;
  }
  return formatted;
}

export function formatNumber(value) {
  return numberFormatter.format(value);
}

export function convertToDatabase(value) {
  if (!value) {
    return 0;
  }

  return value.toString().split('.').join('').split(',').join('.');
}

export function convertNumberToDatabase(value) {
  if (!value) {
    return 0;
  }

  return value.toString().replace(',', '.');
}

export function convertFromDatabase(value) {
  if (!value) {
    return null;
  }

  return value.toString().split('.').join(',');
}

export function pluralize(str, quantity, strPluralized) {
  if (quantity !== 1) {
    return strPluralized || str.concat('s');
  }

  return str;
}

export function formatDateTime(value = new Date(), options = {}) {
  if (!value) {
    return 'Não informado';
  }

  const { isUTC = false, add } = options;

  if (isUTC) {
    return moment(value).utc().format('DD/MM/YYYY [às] HH:mm');
  }

  if(add) {
    return moment(value).add(add.type,add.time).format('DD/MM/YYYY [às] HH:mm');
  }
  return moment(value).format('DD/MM/YYYY [às] HH:mm');
}

export function formatDate(value = new Date(), options = {}) {
  if (!value) {
    return 'Não informado';
  }

  const { isUTC = false } = options;

  if (isUTC) {
    return moment(value).utc().format('DD/MM/YYYY');
  }

  return moment(value).format('DD/MM/YYYY');
}

export function formatDateTimeLong(value) {
  if (!value) {
    return 'Data não informada';
  }

  return moment(value).format('DD [de] MMMM [às] HH:mm');
}

export function formatDateShort(value) {
  if (!value) {
    return 'Não informado';
  }

  const date = moment(value);
  const year = new Date().getFullYear();
  if (year !== date.year()) {
    return date.format('DD MMM YY').toUpperCase();
  }

  return moment(value).format('DD MMM').toUpperCase();
}

export function formatTime(value) {
  if (!value) {
    return 'Não informado';
  }

  return moment(value).format('HH:mm');
}

export function formatDateCustom(value, format) {
  if (!value) {
    return null;
  }

  return moment(value).format(format);
}

export function formatDateFromNow(value) {
  return moment(value).fromNow();
}

export function formatDateToNow(value) {
  return moment(value).toNow(true);
}

export function capitalize(value) {
  if (typeof value !== 'string') return value;

  const output = value.toLowerCase();
  return output.charAt(0).toUpperCase() + output.slice(1);
}

export function capitalizeFullname(value) {
  if (!value) {
    return '';
  }

  return value
    .toLowerCase()
    .split(' ')
    .map(word =>
      word.length >= 2
        ? word.replace(/(^\w{1})/g, match => match.toUpperCase())
        : word
    )
    .join(' ');
}

export function formatPlate(plate) {
  if (!plate) {
    return '-';
  }

  if (plate.charAt(4).match(/[0-9]/)) {
    return plate.replace(/^([a-zA-Z]{3})([0-9]{4})$/i, '$1-$2').toUpperCase();
  }

  return plate.toUpperCase();
}

export function formatBoolean(value) {
  return value ? 'Sim' : 'Não';
}

export function formatAddress({
  address,
  number,
  complement,
  neighborhood,
  city,
  province,
  zip_code,
  country_slug,
  zipCode = null,
  countrySlug = null,
}) {
  const builder = [];
  if (address) {
    builder.push(address);

    if (number) {
      builder.push(`, ${number}`);
    }

    if (complement) {
      builder.push(` - ${complement}`);
    }

    if (neighborhood) {
      builder.push(` - ${neighborhood},`);
    }
  }

  if (city) {
    builder.push(` ${city}`);

    if (province) {
      builder.push(` - ${province}`);
    }

    if (zip_code) {
      builder.push(`, ${zip_code}`);
    } else if (zipCode) {
      builder.push(`, ${zipCode}`);
    }
  }

  if (country_slug) {
    builder.push(` - ${country_slug.toUpperCase()}`);
  } else if (countrySlug) {
    builder.push(` - ${countrySlug.toUpperCase()}`);
  }

  return builder.join('');
}

export function parseErrors(errors, options = {}) {
  const { prefix, getPath = error => error.path } = options;

  if (Array.isArray(errors)) {
    return errors.reduce((acc, error) => {
      const output = acc;
      let path = getPath(error);
      if (prefix) {
        path = prefix + path;
      }
      output[path] = error.message;
      return output;
    }, {});
  }
  if (typeof errors === 'string') {
    return { path: errors };
  }
}

export function parseDate(date, format = 'DD/MM/YYYY') {
  return moment(date, format).toDate();
}

export function percentageMask(num) {
  const percentage = numberFormatter.format(Number(num).toFixed(2));
  return `${percentage}%`;
}

export function formatCharge(
  charge_price,
  charge_type,
  currency,
  currency_symbol
) {
  if (charge_type == 6) {
    return percentageMask(charge_price);
  }
  return formatCurrency(charge_price, currency, currency_symbol);
}

export function formatPercentage(value) {
  const num = new Intl.NumberFormat('pt-BR', {
    style: 'percent',
    minimumFractionDigits: 1,
    maximumFractionDigits: 2,
  }).format(value);
  return num;
}
