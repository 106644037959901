import React, { useEffect, useMemo } from 'react';
import { Col } from 'react-bootstrap';
import { Card, Text, Tooltip } from 'v3/components';
import { FaQuestionCircle, FaDollarSign } from 'react-icons/fa';
import { useModal } from 'hooks';
import theme from 'v3/theme';
import { makeConversion } from 'v3/utils/formatter';
import {
  CardHeaderComponent,
  ExtraFieldsRow,
  ExtraItemRow,
  FreightItemRow,
  HeaderTotalSummary,
  LabelsRow,
  SummaryRow,
} from './components';
import { LoadContext } from '../controller';
import ExtraFieldsModal from '../Modals/ExtraFields';
import {
  ExtraFieldsContainer,
  StyledActionButton,
  StyledToggleCurrency,
} from './styles';
import { clientLoadFieldsToSum, disableFieldConditions } from './utils';

export function ReceiveClientCard({
  isBRLCurrency,
  dollarToUse,
  freightFieldsOptions,
  totalItems,
}) {
  const [, useLoad] = LoadContext;
  const load = useLoad();
  const extraFieldsModal = useModal();

  const shouldDisableTollField = disableFieldConditions?.toll_value?.includes(
    load?.data?.action_toll?.code
  );
  const shouldDisableDischargeField =
    disableFieldConditions?.discharge_value?.includes(
      load?.data?.action_discharge?.code
    );
  const shouldDisableFerryField = disableFieldConditions?.ferry?.includes(
    load?.data?.action_ferry?.code
  );
  const shouldDisableGrissAdField = disableFieldConditions?.grissAd?.includes(
    load?.data?.action_griss_advalorem?.code
  );
  const shouldDisableICMSField = disableFieldConditions?.icms?.includes(
    load?.data?.action_icms?.code
  );
  const shouldDisableChargeField =
    disableFieldConditions?.charge_value?.includes(
      load?.data?.action_charge?.code
    );
  useEffect(() => {
    if (shouldDisableTollField) {
      load.setData(old => ({
        ...old,
        toll_value: '0',
      }));
    }
    if (shouldDisableDischargeField) {
      load.setData(old => ({
        ...old,
        discharge_value: '0',
      }));
    }
    if (shouldDisableFerryField) {
      load.setData(old => ({
        ...old,
        ferry: '0',
      }));
    }
    if (shouldDisableGrissAdField) {
      load.setData(old => ({
        ...old,
        gris: '0',
        ad_valorem: '0',
        gris_percentage: '0',
        ad_valorem_percentage: '0',
      }));
    }
    if (shouldDisableICMSField) {
      load.setData(old => ({
        ...old,
        ICMS: '0',
        icms_percentage: '0',
      }));
    }
    if (shouldDisableChargeField) {
      load.setData(old => ({
        ...old,
        charge_value: '0',
      }));
    }
  }, [
    shouldDisableTollField,
    shouldDisableFerryField,
    shouldDisableGrissAdField,
    shouldDisableDischargeField,
    shouldDisableICMSField,
    shouldDisableChargeField,
  ]);
  const totalComputed = useMemo(() => {
    let total = 0;
    if (load?.extraFields?.length) {
      total = load?.extraFields.reduce((acc, curr) => {
        return acc + Number(curr?.value);
      }, 0);
    }
    if (load?.data) {
      clientLoadFieldsToSum.forEach(field => {
        total += Number(load?.data[field]);
      });
    }
    if (load?.data?.action_toll?.code === 'client_provides_card') {
      total -= Number(load?.data?.toll_value);
    }
    load.setData(old => ({
      ...old,
      total_taker_value: total,
    }));

    return total;
  }, [
    load?.data?.taker_value,
    load?.data?.action_toll,
    load?.data?.toll_value,
    load?.data?.discharge_value,
    load?.data?.gris,
    load?.data?.ad_valorem,
    load?.data?.charge_value,
    load?.data?.ferry,
    load?.data?.ICMS,
    load?.extraFields,
  ]);

  const receiveClientTags = useMemo(() => {
    let tags = '';
    if (Number(load?.data?.taker_value) > 0) {
      tags += 'Frete base,';
    }
    if (
      Number(load?.data?.toll_value) > 0 &&
      load?.data?.action_toll?.code !== 'client_provides_card'
    ) {
      tags += 'Pedágio,';
    }
    if (Number(load?.data?.discharge_value) > 0) {
      tags += 'Descarga,';
    }
    if (Number(load?.data?.charge_value) > 0) {
      tags += 'Taxa de Carregamento,';
    }
    if (Number(load?.data?.gris) > 0) {
      tags += 'Gris,';
    }
    if (Number(load?.data?.ad_valorem) > 0) {
      tags += 'Ad Valorem,';
    }
    if (Number(load?.data?.ferry) > 0) {
      tags += 'Balsa,';
    }
    if (Number(load?.data?.ICMS) > 0) {
      tags += 'ICMS,';
    }
    if (load?.extraFields?.length > 0) {
      tags += 'Valores extras,';
    }
    return tags;
  }, [
    load?.data?.taker_value,
    load?.data?.toll_value,
    load?.data?.action_toll,
    load?.data?.discharge_value,
    load?.data?.gris,
    load?.data?.ad_valorem,
    load?.data?.charge_value,
    load?.data?.ferry,
    load?.data?.ICMS,
    load?.extraFields,
  ]);
  function convertValue(rawValue) {
    const operation = isBRLCurrency ? '/' : '*';
    const processedValue = +makeConversion(rawValue, operation, dollarToUse);
    return operation === '/'
      ? processedValue.toFixed(4)
      : processedValue.toFixed(2);
  }

  return (
    <Col xs={12}>
      <ExtraFieldsModal
        isOpen={extraFieldsModal.isOpen}
        onClose={extraFieldsModal.close}
        setExtraFields={load.setExtraFields}
        errors={load.errors}
        extraFields={load?.extraFields}
        isReceiveClientExtra
        isBRLCurrency={isBRLCurrency}
        dollarToUse={dollarToUse}
        loadCurrency={load?.data?.currency?.value === 'BRL' ? 'R$' : '$'}
      />
      <Card containerCardBoxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)">
        <CardHeaderComponent
          label="Receber Cliente Tomador"
          isOpen={load?.clientCardToggle}
          setIsOpen={() => load.setClientCardToggle(!load?.clientCardToggle)}
          rightSection={
            <StyledToggleCurrency>
              <StyledActionButton
                onClick={() =>
                  load.setData(old => ({
                    ...old,
                    showDailyRate: !load?.data?.showDailyRate,
                  }))
                }
                title={
                  load?.data?.showDailyRate
                    ? 'Cotação do dia'
                    : 'Cotação informada'
                }
              >
                <FaDollarSign
                  title={
                    load?.data?.showDailyRate
                      ? 'Cotação do dia'
                      : 'Cotação informada'
                  }
                />
              </StyledActionButton>
              <Tooltip
                content={
                  <Text type="label" color="white">
                    Alterna entre valores da cotação do dólar no dia e a cotação
                    informada no campo Moeda.
                  </Text>
                }
              >
                <FaQuestionCircle style={{ color: theme.colors.light_blue }} />
              </Tooltip>
            </StyledToggleCurrency>
          }
          tooltipContent="Todo o valor que é recebido pelo cliente deve ser destacado separadamente. Após preencher todos os campos irá chegar no Valor Tomador automaticamente "
          totalsSection={
            <HeaderTotalSummary
              label="Valor Cliente Tomador"
              totalInReal={
                isBRLCurrency ? totalComputed : convertValue(totalComputed)
              }
              totalInDollar={
                !isBRLCurrency ? totalComputed : convertValue(totalComputed)
              }
            />
          }
          hasTagsRow
          tagsContent={receiveClientTags}
        />
        {load?.clientCardToggle && (
          <>
            <LabelsRow />
            <FreightItemRow
              label="Valor frete base*"
              value={load?.data?.taker_value}
              onChangeValue={(_event, value) =>
                load.setData(old => ({
                  ...old,
                  taker_value: value,
                }))
              }
              isBRLCurrency={isBRLCurrency}
              dollarToUse={dollarToUse}
              isRequired
              errorField={load?.errors?.taker_value}
              showCalculator
              id="frete-base"
            />
            <FreightItemRow
              label="Pedágio"
              value={load?.data?.toll_value}
              onChangeValue={(_event, value) => {
                load.setData(old => ({
                  ...old,
                  toll_value: value,
                }));
              }}
              isBRLCurrency={isBRLCurrency}
              dollarToUse={dollarToUse}
              selectOptions={freightFieldsOptions?.toll}
              onChangeSelectValue={value => {
                load.setData(old => ({
                  ...old,
                  action_toll: value,
                }));
              }}
              selectValue={load?.data?.action_toll}
              shouldDisableField={shouldDisableTollField}
              showCalculator
              id="pedagio"
            />
            <FreightItemRow
              label="Descarga"
              value={load?.data?.discharge_value}
              onChangeValue={(_event, value) => {
                load.setData(old => ({
                  ...old,
                  discharge_value: value,
                }));
              }}
              isBRLCurrency={isBRLCurrency}
              dollarToUse={dollarToUse}
              selectOptions={freightFieldsOptions?.discharge}
              onChangeSelectValue={value => {
                load.setData(old => ({
                  ...old,
                  action_discharge: value,
                }));
              }}
              selectValue={load?.data?.action_discharge}
              shouldDisableField={shouldDisableDischargeField}
              showCalculator
              id="descarga"
            />
            <FreightItemRow
              label="Gris"
              percentageValueReference={load?.data?.cargoValue}
              percentageValue={load?.data?.gris_percentage || '0'}
              onChangePercentageValue={(_event, value) => {
                if (load?.data?.cargoValue) {
                  const computedValue = (
                    (Number(value) / 100) *
                    load?.data?.cargoValue
                  ).toFixed(2);
                  load.setData(old => ({
                    ...old,
                    gris_percentage: value,
                    gris: computedValue,
                  }));
                }
              }}
              value={load?.data?.gris}
              onChangeValue={(_event, value) => {
                if (load?.data?.cargoValue) {
                  const computedValue = (
                    (Number(value) / load?.data?.cargoValue) *
                    100
                  ).toFixed(2);
                  load.setData(old => ({
                    ...old,
                    gris: value,
                    gris_percentage: computedValue,
                  }));
                }
              }}
              isBRLCurrency={isBRLCurrency}
              dollarToUse={dollarToUse}
              selectOptions={freightFieldsOptions?.griss_ad_valorem}
              onChangeSelectValue={value => {
                load.setData(old => ({
                  ...old,
                  action_griss_advalorem: value,
                }));
              }}
              selectValue={load?.data?.action_griss_advalorem}
              hasTooltip
              tooltipContent="Griss / AD Valorem (Seguro): Valor aproximado, pode variar de acordo com o valor do produto na Nota Fiscal"
              shouldDisableField={shouldDisableGrissAdField}
              showCalculator
              id="gris"
            />
            <FreightItemRow
              label="Ad Valorem"
              decimalDigits={3}
              percentageValueReference={load?.data?.cargoValue}
              percentageValue={load?.data?.ad_valorem_percentage || '0'}
              onChangePercentageValue={(_event, value) => {
                if (load?.data?.cargoValue) {
                  const computedValue = (
                    (Number(value) / 100) *
                    load?.data?.cargoValue
                  ).toFixed(2);
                  load.setData(old => ({
                    ...old,
                    ad_valorem_percentage: value,
                    ad_valorem: computedValue,
                  }));
                }
              }}
              value={load?.data?.ad_valorem}
              onChangeValue={(_event, value) => {
                if (load?.data?.cargoValue) {
                  const computedValue = (
                    (Number(value) / load?.data?.cargoValue) *
                    100
                  ).toFixed(3);
                  load.setData(old => ({
                    ...old,
                    ad_valorem: value,
                    ad_valorem_percentage: computedValue,
                  }));
                }
              }}
              isBRLCurrency={isBRLCurrency}
              dollarToUse={dollarToUse}
              shouldDisableField={shouldDisableGrissAdField}
              showCalculator
              id="ad-valorem"
            />
            <FreightItemRow
              label="Taxa de carregamento"
              value={load?.data?.charge_value}
              onChangeValue={(_event, value) => {
                load.setData(old => ({
                  ...old,
                  charge_value: value,
                }));
              }}
              isBRLCurrency={isBRLCurrency}
              dollarToUse={dollarToUse}
              selectOptions={freightFieldsOptions?.loading_fee}
              onChangeSelectValue={value => {
                load.setData(old => ({
                  ...old,
                  action_charge: value,
                }));
              }}
              selectValue={load?.data?.action_charge}
              shouldDisableField={shouldDisableChargeField}
              showCalculator
              id="taxa-carregamento"
            />
            <FreightItemRow
              label="Balsa"
              value={load?.data?.ferry}
              onChangeValue={(_event, value) => {
                load.setData(old => ({
                  ...old,
                  ferry: value,
                }));
              }}
              isBRLCurrency={isBRLCurrency}
              dollarToUse={dollarToUse}
              selectOptions={freightFieldsOptions?.ferry}
              onChangeSelectValue={value => {
                load.setData(old => ({
                  ...old,
                  action_ferry: value,
                }));
              }}
              selectValue={load?.data?.action_ferry}
              shouldDisableField={shouldDisableFerryField}
              showCalculator
              id="balsa"
            />
            <FreightItemRow
              label="ICMS"
              onChangePercentageValue={(_event, value) => {
                if (totalItems) {
                  const computedValue = Number(
                    totalItems / (1 - value / 100) - totalItems
                  )?.toFixed(2);

                  load.setData(old => ({
                    ...old,
                    icms_percentage: value,
                    ICMS: computedValue,
                  }));
                }
              }}
              percentageValueReference={totalItems}
              percentageValue={load?.data?.icms_percentage || '0'}
              value={load?.data?.ICMS}
              onChangeValue={(_event, value) => {
                if (totalItems) {
                  const computedValue = Number(
                    100 *
                      (1 -
                        (totalItems / (totalItems + Number(value)))?.toFixed(2))
                  )?.toFixed(2);
                  load.setData(old => ({
                    ...old,
                    ICMS: value,
                    icms_percentage: computedValue,
                  }));
                }
              }}
              isBRLCurrency={isBRLCurrency}
              dollarToUse={dollarToUse}
              selectOptions={freightFieldsOptions?.icms}
              onChangeSelectValue={value => {
                load.setData(old => ({
                  ...old,
                  action_icms: value,
                }));
              }}
              selectValue={load?.data?.action_icms}
              shouldDisableField={shouldDisableICMSField}
              showCalculator
              id="icms"
            />
            <>
              {load?.extraFields?.length > 0 ? (
                <Text type="regular" weight={500}>
                  Valores extras
                </Text>
              ) : null}
              <ExtraFieldsContainer>
                {load?.extraFields?.map((item, index) => {
                  return (
                    <ExtraItemRow
                      key={`${index}_${new Date().getTime()}`}
                      label={item?.label}
                      value={item?.value}
                      dollarToUse={dollarToUse}
                      isBRLCurrency={isBRLCurrency}
                      onSetExtraFields={() =>
                        load.setExtraFields(
                          load?.extraFields?.filter((_, idx) => idx !== index)
                        )
                      }
                      showToDriver={item?.show_to_drivers}
                      id={`valor-extra-${index}`}
                    />
                  );
                })}
              </ExtraFieldsContainer>
            </>
            <SummaryRow
              label="Valor Cliente Tomador"
              totalInBRL={
                isBRLCurrency ? totalComputed : convertValue(totalComputed)
              }
              totalInUSD={
                !isBRLCurrency ? totalComputed : convertValue(totalComputed)
              }
              id="valor-tomador"
            />
            <ExtraFieldsRow
              id="button-abrir-valores-extras-valor-tomador"
              openModal={() => extraFieldsModal.open()}
            />
          </>
        )}
      </Card>
    </Col>
  );
}
