import React, { useState, useCallback, useEffect } from 'react';
import dashboardApi from 'services/apis/dashboard';
import { Table, Text, Link, Button } from 'v3/components';
import { formatCurrency, formatPlate } from 'v3/utils/formatter';
import moment from 'moment';
import { useQuery } from 'hooks';
import Card, { Number, Description, SeeAll } from './Card';

export default function AlteredTravels({ dashboard, name, id }) {
  const [filter, setFilter] = useState({});
  const [currentPage, setCurrentPage] = useState({});
  const [pagination, setPagination] = useState({});
  const [data, setData] = useState({});
  const [isFetching, setFetching] = useState(false);
  const [daysFromNow, setDaysFromNow] = useState();
  const [loading, setLoading] = useState(false);

  const queryParams = useQuery();

  useEffect(() => {
    setDaysFromNow(moment(new Date()).diff(filter.start_date, 'days'));
  }, [filter.start_date]);

  function setQueryParams(filters) {
    const { tags = [], start_date, end_date, clients, costCenters } = filters;

    queryParams.delete('tags');

    if (tags) {
      tags.forEach(tag => {
        queryParams.append('tags', tag.id);
      });
    }

    if (start_date) {
      queryParams.set('start_date', start_date);
    }

    if (end_date) {
      queryParams.set('end_date', end_date);
    }

    if (tags?.length === 0) {
      queryParams.set('no-tags', true);
    } else {
      queryParams.delete('no-tags');
    }

    if (clients?.length > 0) {
      clients.forEach(client => {
        queryParams.append('companies', client?.id);
      });
    }

    if (costCenters?.length > 0) {
      costCenters.forEach(cc => {
        queryParams.append('cost_center', cc?.id);
      });
    }
  }

  const fetchAlteredTravels = useCallback(async (filters = {}) => {
    try {
      setLoading(true);

      const {
        tags = [],
        clients = [],
        branches = [],
        negotiators = [],
        costCenters = [],
        pcps = [],
        companies = [],
        page = 1,
        start_date,
        end_date,
      } = filters;

      if (page === 1) {
        setFetching(true);
      }

      setQueryParams(filters);
      setFilter(filters);
      setCurrentPage(+page);

      const response = await dashboardApi.get('/altered-travels', {
        params: {
          tags: tags?.map(tag => tag.id),
          clients: clients?.map(client => client.id),
          branches: branches?.map(branch => branch.id),
          start_date,
          negotiators: negotiators?.map(negotiator => negotiator?.id),
          costCenters: costCenters?.map(costCenter => costCenter?.id),
          pcps: pcps?.map(pcp => pcp?.id),
          companies: companies?.map(company => company?.id),
          end_date: end_date || undefined,
          page,
        },
      });

      setPagination(response.data?.last?.pagination);

      if (page === 1) {
        setData(response.data);
      } else {
        setData(oldData => ({
          ...oldData,
          last: {
            ...oldData.last,
            data: [...oldData.last.data, ...response.data.last.data],
          },
        }));
      }
    } catch (error) {
      // comment
    } finally {
      setFetching(false);
      setLoading(false);
    }
  }, []);

  return (
    <>
      <Card
        title="Viagens alteradas"
        isLoading={isFetching}
        fetchFn={fetchAlteredTravels}
        onFiltersChanged={fetchAlteredTravels}
        dashboard={dashboard}
        name={name}
        id={id}
        ListComponent={
          <>
            <Table striped>
              <thead>
                <tr>
                  <th>
                    <Text weight="500" color="dark">
                      Nº viagem
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Nº carga
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Centro de Custo
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Cliente Tomador
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Valor da carga
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Origem
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Último Destino
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      É rastreado?
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Placa Tração
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Placas Implementos
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Motorista
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Último log da viagem
                    </Text>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.last?.data?.map(travel => {
                  const title = [
                    `Cliente ${travel.social_name} - ${travel.cgccpf}`,
                    `Motorista ${travel.driver_social_name}`,
                    `Placa veículo tração ${formatPlate(travel.plate)}`,
                  ].join('\n');

                  return (
                    <tr>
                      <td>
                        <Link
                          to={`/viagens/${travel.travelId}`}
                          target="_blank"
                          title={title}
                        >
                          {travel.travelId}
                        </Link>
                      </td>
                      <td>
                        <Link to={`/cargas/${travel.loadId}`} target="_blank">
                          {travel.loadId}
                        </Link>
                      </td>
                      <td>
                        <Text type="regular" color="gray">
                          {travel?.cost_center_name ?? '-'}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="gray">
                          {travel?.social_name && travel?.cgccpf
                            ? `${travel?.social_name} - ${travel?.cgccpf}`
                            : '-'}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="gray">
                          {travel?.cargo_value
                            ? formatCurrency(
                                travel?.cargo_value,
                                travel?.load_currency
                              )
                            : '-'}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="gray">
                          {travel.city_origin && travel?.uf_origin
                            ? `${travel?.city_origin} - ${travel?.uf_origin}`
                            : '-'}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="gray">
                          {travel.city_destination && travel?.uf_destination
                            ? `${travel?.city_destination} - ${travel?.uf_destination}`
                            : '-'}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="gray">
                          {travel?.crawled_load ? 'Sim' : 'Não'}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="dark" truncate>
                          {travel?.plate ?? '-'}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="dark" truncate>
                          {travel?.wagons_plate ?? '-'}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="gray" truncate>
                          {travel.driver_social_name && travel?.driver_document
                            ? `${travel?.driver_social_name} - ${travel?.driver_document}`
                            : '-'}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="gray">
                          {travel?.last_travel_log ?? '-'}
                        </Text>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {pagination?.lastPage > 1 && pagination?.to < pagination?.total && (
              <div className="text-center mt-3 col-12">
                <Button
                  className="mt-2 ml-auto align-item-center"
                  onClick={() => {
                    fetchAlteredTravels({
                      page: currentPage + 1,
                      tags: filter?.tags,
                      clients: filter?.clients,
                      start_date: filter?.start_date,
                      end_date: filter?.end_date,
                      costCenters: filter?.costCenters,
                      pcps: filter?.pcps,
                      negotiators: filter?.negotiators,
                      companies: filter?.companies,
                      branches: filter?.branches,
                    });
                  }}
                  loading={loading}
                >
                  <Text type="regular" color="white" truncate>
                    Ver mais
                  </Text>
                </Button>
              </div>
            )}
            {!!data?.total && (
              <SeeAll
                to={{
                  pathname: '/viagens',
                  search: queryParams.toString(),
                }}
              >
                ver todas as {data?.total} viagens
              </SeeAll>
            )}
          </>
        }
        NumericComponent={
          <div className="d-flex justify-content-center text-center flex-column">
            <Number color="success">{data?.total}</Number>
            <Description>
              viagens alteradas
              {daysFromNow > 1
                ? ` nos últimos ${daysFromNow} dias`
                : ` no último dia`}
            </Description>
          </div>
        }
      />
    </>
  );
}
