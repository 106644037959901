import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';

import { Row, Col } from 'react-bootstrap';

import api from 'services/api';

import { Select, Input, Text } from 'v3/components';

import {
  fetchBodies,
  fetchImplements,
  fetchVehiclesTypes,
} from 'utils/fetches';
import { format } from 'date-fns';
import { validatePermission } from 'actions';
import CheckBox from 'components/CheckBox';
import { useImplement } from './context';
import {
  fetchCities,
  fetchAnttTypes,
  fetchWagonsBrands,
  colors,
} from './utils';

export function Data() {
  const implement = useImplement();
  const params = useParams();
  const [anttTypes, setAnttTypes] = useState([]);
  const [rntrcExpDate, setRntrcExpDate] = useState('');
  const [modelsOptions, setModelsOptions] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [vehicleImplementTypes, setVehicleImplementTypes] = useState([]);
  const [vehicleBodyTypes, setVehicleBodyTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const hasPermissionToAlterRntrcValidated = validatePermission(
    'VALIDAR_RNTRC_VEICULO_IMPLEMENTO'
  );

  useEffect(() => {
    if (!params.id)
      implement.setData({
        city: null,
        plate: null,
        renavam: null,
        anttType: null,
        antt: null,
        rntrcExpDate: null,
        crlv: null,
        chassi: null,
        brand: null,
        model: null,
        yearModel: null,
        yearManufacture: null,
        color: null,
        totalGrossWeight: null,
        tareMass: null,
        cubage: null,
        vehicleType: null,
        bodyType: null,
        implementType: null,
        anttAdherence: null,
        rntrcValidated: false,
      });
    else {
      setRntrcExpDate(
        implement.data?.rntrcExpDate
          ? format(
              new Date(moment(implement.data?.rntrcExpDate).add(1, 'day')),
              'yyyy-MM-dd'
            )
          : null
      );
    }
  }, []);

  async function fetchOptions() {
    try {
      setLoading(true);
      let [vehicleTypesData, vehiclesBodyTypesData, implementsData] =
        await Promise.all([
          fetchVehiclesTypes(),
          fetchBodies(),
          fetchImplements(),
        ]);
      vehicleTypesData = vehicleTypesData.filter(
        vtype => vtype?.name?.toLowerCase() === 'semi reboque'
      );
      setVehicleTypes(vehicleTypesData);
      setVehicleBodyTypes(vehiclesBodyTypesData);
      setVehicleImplementTypes(implementsData);
    } catch (error) {
      //
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchAnttTypes(setAnttTypes);
    fetchOptions();
  }, []);

  async function handleChangeBrand(value) {
    try {
      if (value?.id) {
        const response = await api.get(`v3/models/${value.id}`);
        setModelsOptions(response.data);
      }
    } catch (error) {
      if (value?.id)
        implement.showSnackbar('Erro ao buscar modelos', { type: 'error' });
    }

    implement.setData({ brand: value });
  }

  async function fetchModels(search) {
    if (implement.data.brand?.id) {
      try {
        const response = await api.get(
          `models/${implement.data.brand.id}/select?search=${search}`
        );
        return response.data;
      } catch (error) {
        return [];
      }
    }
    implement.showSnackbar('Selecione uma marca primeiro', {
      type: `error`,
    });

    return [];
  }

  function handleRntrcExpDate(value) {
    implement.setData({
      rntrcExpDate: moment(value, 'YYYY/MM/DD').format(),
    });
    setRntrcExpDate(value);
  }
  const hasSelectDollyImplementType =
    implement?.data?.implementType?.name === 'Dolly';
  return (
    <Row className="form">
      <Col md={12}>
        <Select.Async
          label="Cidade *"
          value={implement.data?.city}
          onSearch={fetchCities}
          getOptionLabel={option => `${option?.name}, ${option?.province?.uf}`}
          getOptionValue={option => option?.id}
          onChange={value => {
            implement.setData({
              city: value,
            });
          }}
          error={implement.errors.city}
        />
      </Col>
      <Col className="my-2" md={6} xs={12}>
        <Select
          label="Tipo do veículo *"
          options={vehicleTypes}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          value={implement?.data?.vehicleType}
          onChange={value =>
            implement.setData({
              vehicleType: value,
              vehicle_type_id: value?.id,
            })
          }
          error={implement.errors?.vehicleType}
        />
      </Col>
      <Col md={6} xs={12} className="my-2">
        <Select
          label="Tipo de implemento *"
          options={vehicleImplementTypes}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          value={implement?.data?.implementType}
          onChange={value => {
            if (value?.name === 'Dolly') {
              implement.setData({
                implementType: value,
                vehicle_implement_type_id: value?.id,
                bodyType: null,
                vehicle_body_type_id: null,
              });
              implement.setErrors(old => ({ ...old, bodyType: null }));
            }
            implement.setData({
              implementType: value,
              vehicle_implement_type_id: value?.id,
            });
          }}
          error={implement.errors?.implementType}
        />
      </Col>
      <Col md={6} xs={12} className="my-2">
        <Select
          label="Tipo do carroceria *"
          options={vehicleBodyTypes}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          value={implement?.data?.bodyType}
          onChange={value =>
            implement.setData({
              bodyType: value,
              vehicle_body_type_id: value?.id,
            })
          }
          error={implement.errors?.bodyType}
          isDisabled={hasSelectDollyImplementType}
        />
      </Col>
      <Col md={6} className="my-2" xs={12}>
        <Input
          label="Placa *"
          value={implement.data.plate}
          onChange={event => implement.setData({ plate: event.target.value })}
          error={implement.errors.plate}
        />
      </Col>
      <Col md={6} className="my-2" xs={12}>
        <Input
          label="Renavam *"
          type="number"
          min={0}
          value={implement.data.renavam}
          onChange={event => implement.setData({ renavam: event.target.value })}
          error={implement.errors?.renavam}
        />
      </Col>
      <Col md={6} className="my-2" xs={12}>
        <Select
          label="Tipo de RNTRC *"
          options={anttTypes}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          value={implement.data.anttType}
          onChange={value => implement.setData({ anttType: value })}
          error={implement.errors.anttType}
        />
      </Col>
      <Col md={6} className="my-2" xs={12}>
        <Input
          label="RNTRC *"
          type="number"
          min={0}
          value={implement.data.antt}
          onChange={event => implement.setData({ antt: event.target.value })}
          error={implement.errors.antt}
        />
      </Col>
      <Col md={6} className="my-2" xs={12}>
        <Input
          label="Data de emissão RNTRC *"
          type="date"
          value={implement.data.anttAdherence}
          onChange={event =>
            implement.setData({ anttAdherence: event.target.value })
          }
          error={implement.errors?.anttAdherence}
        />
      </Col>
      <Col md={6} className="my-2" xs={12}>
        <Input
          label="Validade RNTRC"
          type="date"
          value={rntrcExpDate}
          onChange={event => handleRntrcExpDate(event.target.value)}
          error={implement.errors.rntrcExpDate}
        />
      </Col>
      {hasPermissionToAlterRntrcValidated ? (
        <Col sm={12} md={6} className="my-2">
          <Text as="span" color="dark" type="label" weight={500}>
            RNTRC Validada?
          </Text>
          <div>
            <CheckBox
              checked={implement.data.rntrcValidated}
              onChange={e =>
                implement.setData({ rntrcValidated: e.target.checked })
              }
              label="Sim"
            />
          </div>
        </Col>
      ) : null}

      <Col md={6} className="my-2" xs={12}>
        <Input
          label="CRLV"
          type="number"
          min={0}
          value={implement.data.crlv}
          onChange={event => implement.setData({ crlv: event.target.value })}
          error={implement.errors.crlv}
        />
      </Col>
      <Col md={6} className="my-2" xs={12}>
        <Input
          label="Chassi *"
          value={implement.data.chassi}
          onChange={event =>
            event.target.value.length > 20
              ? implement.showSnackbar('Limite de dígitos para Chassi', {
                  type: 'error',
                })
              : implement.setData({
                  chassi: event.target.value,
                })
          }
          error={implement.errors?.chassi}
        />
      </Col>
      <Col md={6} className="my-2" xs={12}>
        <Select.Async
          label="Marca"
          onSearch={fetchWagonsBrands}
          value={implement.data.brand}
          onChange={value => handleChangeBrand(value)}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
        />
      </Col>
      <Col md={6} className="my-2" xs={12}>
        <Select.Async
          label="Modelo"
          onSearch={search => fetchModels(search)}
          value={implement.data?.model}
          options={modelsOptions}
          onChange={value => implement.setData({ model: value })}
          getOptionLabel={option => option.label}
          getOptionValue={option => option.label}
        />
      </Col>
      <Col md={6} className="my-2" xs={12}>
        <Input
          label="Ano do modelo *"
          type="number"
          min={0}
          value={implement.data?.yearModel}
          onChange={event =>
            event.target.value.length > 4
              ? implement.showSnackbar('Limite de dígitos para Ano ', {
                  type: 'error',
                })
              : implement.setData({
                  yearModel: event.target.value,
                })
          }
          error={implement.errors?.yearModel}
        />
      </Col>
      <Col md={6} className="my-2" xs={12}>
        <Input
          label="Ano de fabricação *"
          type="number"
          min={0}
          value={implement.data?.yearManufacture}
          onChange={event =>
            event.target.value.length > 4
              ? implement.showSnackbar('Limite de dígitos para Ano', {
                  type: 'error',
                })
              : implement.setData({
                  yearManufacture: event.target.value,
                })
          }
          error={implement.errors?.yearManufacture}
        />
      </Col>
      <Col md={6} className="my-2" xs={12}>
        <Select
          label="Cor"
          options={colors}
          getOptionLabel={option => option.label}
          getOptionValue={option => option.value}
          value={implement.data?.color}
          onChange={value => {
            implement.setData({ color: value });
          }}
        />
      </Col>
      <Col md={6} className="my-2" xs={12}>
        <Input
          label="PBT (Peso bruto total) em kg"
          type="number"
          min={0}
          value={implement.data?.totalGrossWeight}
          onChange={event =>
            implement.setData({
              totalGrossWeight: event.target.value,
            })
          }
          placeholder="25500"
        />
      </Col>
      <Col md={6} className="my-2" xs={12}>
        <Input
          label="Tara em kg"
          type="number"
          min={0}
          value={implement.data?.tareMass}
          onChange={event =>
            implement.setData({
              tareMass: event.target.value,
            })
          }
          placeholder="17440"
        />
      </Col>
      <Col md={6} className="my-2" xs={12}>
        <Input
          label="Cubagem em m³"
          type="number"
          min={0}
          value={implement.data?.cubage}
          onChange={event =>
            implement.setData({
              cubage: event.target.value,
            })
          }
          placeholder="90"
        />
      </Col>
    </Row>
  );
}
