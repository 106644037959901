import React, { useMemo } from 'react';
import { Col } from 'react-bootstrap';
import { makeConversion } from 'v3/utils/formatter';
import { Card } from 'v3/components';
import {
  CardHeaderComponent,
  FreightItemRow,
  HeaderTotalSummary,
  LabelsRow,
} from './components';
import { LoadContext } from '../controller';

export function NegotiatedValues({ isBRLCurrency, dollarToUse }) {
  const [, useLoad] = LoadContext;
  const load = useLoad();
  const freightMargin = useMemo(() => {
    let total = 0;
    total =
      (Number(load?.data?.total_taker_value) || 0) -
      (Number(load?.data?.total_inputs) || 0);
    load.setData(old => ({
      ...old,
      fare_company: total,
    }));
    return total;
  }, [load?.data?.total_taker_value, load?.data?.total_inputs]);
  function convertValue(rawValue) {
    const operation = isBRLCurrency ? '/' : '*';
    const processedValue = +makeConversion(rawValue, operation, dollarToUse);
    return operation === '/'
      ? processedValue.toFixed(4)
      : processedValue.toFixed(2);
  }
  return (
    <Col xs={12}>
      <Card containerCardBoxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)">
        <CardHeaderComponent
          label="Valores negociados"
          isOpen={load?.negotiatedCardToggle}
          setIsOpen={() =>
            load.setNegotiatedCardToggle(!load?.negotiatedCardToggle)
          }
          tooltipContent="Valores negociados com transportador e
cliente a partir dos valores principais"
          totalsSection={
            <HeaderTotalSummary
              label="Frete tirar margem"
              totalInReal={
                isBRLCurrency ? freightMargin : convertValue(freightMargin)
              }
              totalInDollar={
                !isBRLCurrency ? freightMargin : convertValue(freightMargin)
              }
            />
          }
        />
        {load?.negotiatedCardToggle && (
          <>
            <LabelsRow />
            <FreightItemRow
              label="Frete Tirar Margem"
              value={load?.data?.fare_company}
              isBRLCurrency={isBRLCurrency}
              dollarToUse={dollarToUse}
              shouldDisableField
              hasTooltip
              tooltipContent="Base de cálculo para Frete Tirar Margem (Valor Tomador - Valor Insumos)"
              id="frete-tirar-margem"
            />
          </>
        )}
      </Card>
    </Col>
  );
}
