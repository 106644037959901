import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import Select from 'v3/components/Select';
import api from 'services/api';
import Section from '../Section';

import { useClient } from '../context';

export default function ClientActivities() {
  const client = useClient();

  const [typesList, setTypesList] = useState([]);

  async function fetchClientTypes() {
    try {
      const response = await api.get('v3/client-types', {
        params: { only_ordinary: 1 },
      });
      return setTypesList(response.data);
    } catch (error) {
      return [];
    }
  }

  useEffect(() => {
    fetchClientTypes();
  }, []);

  return (
    <Row className="form">
      <Col md={12}>
        <Select
          label="Tipo"
          options={typesList}
          value={client.data.clientType}
          onChange={value =>
            client.setData({
              clientType: value,
            })
          }
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
        />
      </Col>
    </Row>
    // </Section>
  );
}
