const Yup = require('yup');

export const dataSchema = Yup.object().shape({
  name: Yup.string().required('Nome obrigatório').nullable(),
  priorityFleetDuration: Yup.object()
    .shape({
      minutes_formatted: Yup.string(),
    })
    .nullable(),
  can_issue: Yup.boolean(),
  issuers: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number(),
      })
    )
    .when('can_issue', {
      is: true,
      then: Yup.array().required('Emissores é um campo obrigatório'),
    })
    .nullable(),
  client_type: Yup.number().required('Tipo de cliente obrigatória').nullable(),
  product_type: Yup.number().required('Tipo do produto obrigatório').nullable(),
});

export const integrationsSchema = Yup.array().of(
  Yup.object().shape({
    client_id: Yup.string()
      .nullable()
      .when('slug', {
        is: value => value === 'FRETEBRAS',
        then: Yup.string()
          .required('ID obrigatória')
          .typeError('ID obrigatória'),
      }),
    password: Yup.string()
      .nullable()
      .when('slug', {
        is: value => value === 'FRETEBRAS',
        then: Yup.string()
          .required('Senha obrigatória')
          .typeError('Senha obrigatória'),
      }),
    username: Yup.string()
      .nullable()
      .when('slug', {
        is: value => value === 'FRETEBRAS',
        then: Yup.string()
          .required('Nome de usuário obrigatório')
          .typeError('Nome de usuário obrigatório'),
      }),
    cnpj_cpf: Yup.string()
      .nullable(),
    client_secret: Yup.string().required('Token obrigatório'),
  })
);

export const priceOrderSchema = Yup.array().of(
  Yup.object().shape({
    name: Yup.string(),
  })
);

export const checklistSchema = Yup.object().shape({
  checklist: Yup.array().of(
    Yup.object().shape({
      title: Yup.string(),
    })
  ),
  required_checklist: Yup.boolean(),
});

export const priorityFleetSchema = Yup.object().shape({
  data: Yup.array().of(
    Yup.object().shape({
      id: Yup.number(),
    })
  ),
});

export const clientSchema = Yup.array().of(
  Yup.object().shape({
    id: Yup.number(),
  })
);

export const companySchema = Yup.object().shape({
  checklist: checklistSchema,
  data: dataSchema,
  clients: clientSchema,
  integrations: integrationsSchema,
  pricingOrder: priceOrderSchema,
  priorityFleetVehicles: priorityFleetSchema,
});

export const riskManagers = Yup.object().shape({
  data: Yup.array().of(
    Yup.object().shape({
      id: Yup.number(),
    })
  ),
});

export async function validate(field, data, setErrors) {
  const errorList = {};

  try {
    if (field === 'allSchema')
      await companySchema.validate(data, {
        abortEarly: false,
      });
    else
      await companySchema.validateAt(field, data, {
        abortEarly: false,
      });

    return true;
  } catch (err) {
    err.inner.forEach(error => {
      errorList[error.path] = error?.message;
    });

    return false;
  } finally {
    setErrors(errorList);
  }
}
