import React, { useState, useCallback } from 'react';
import dashboardApi from 'services/apis/dashboard';
import { Table, Text, Link } from 'v3/components';
import { formatDateTime, formatCurrency } from 'v3/utils/formatter';
import api from 'services/api';
import { useQuery } from 'hooks';
import Card, { Number, Description, SeeAll } from './Card';
import { DateOptions } from './Card/utils';

export default function DailyRevenues({ dashboard, name, id }) {
  const [isFetching, setFetching] = useState(false);
  const [dateLabel, setDateLabel] = useState('');
  const [data, setData] = useState({});

  const queryParams = useQuery();

  function setQueryParams(filters) {
    const { tags = [] } = filters;
    queryParams.delete('tags');

    if (tags) {
      tags.forEach(tag => {
        queryParams.append('tags', tag.id);
      });
    }

    if (tags?.length === 0) {
      queryParams.set('no-tags', true);
    } else {
      queryParams.delete('no-tags');
    }
  }

  const fetchDailyRevenues = useCallback(async (filters = {}) => {
    try {
      setFetching(true);

      const {
        tags = [],
        clients = [],
        date: start_date,
        negotiators = [],
        pcps = [],
        costCenters = [],
        companies = {},
      } = filters;

      setDateLabel(start_date.label);
      setQueryParams(filters);

      const response = await dashboardApi.get('company-revenues', {
        params: {
          tags: tags?.map(tag => tag.id),
          clients: clients?.map(client => client.id),
          start_date: start_date?.value,
          negotiators: negotiators?.map(negotiator => negotiator?.id),
          costCenters: costCenters?.map(costCenter => costCenter?.id),
          pcps: pcps?.map(pcp => pcp?.id),
          companies: companies?.id ? [companies?.id] : null,
        },
      });

      setData(response.data);
    } catch (ex) {
      // Handle exception
    } finally {
      setFetching(false);
    }
  }, []);

  async function handleChangeDate(value, setSelectedDate, setValues, values) {
    setSelectedDate(value);
    setValues(old => ({ ...old, date: value }));

    const payload = {
      tags: values.tags,
      clients: values.clients,
      sizes: values.size,
      date: value,
      component: name,
      card_id: values.card_id,
      negotiators: values?.negotiators,
      costCenters: values?.costCenters,
      pcps: values?.pcps,
      companies: values?.companies,
    };

    await api.put(`v3/dashboard/${dashboard.id}/parameters`, payload);
  }

  return (
    <Card
      title="Faturamento atual da Empresa"
      isLoading={isFetching}
      onFiltersChanged={fetchDailyRevenues}
      dashboard={dashboard}
      name={name}
      id={id}
      dateFilter
      dates={DateOptions.actualDate}
      excludeFilterByDate
      excludeFilterByBranchOffice={true}
      handleChangeDate={handleChangeDate}
      dateFilterOptionLabel={option => option.label}
      dateFilterOptionValue={option => option.value}
      filterByCompanyMultiple={false}
      ListComponent={
        <>
          <Table striped>
            <thead>
              <tr>
                <th>
                  <Text weight="500" color="dark">
                    Centro de custo
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Tags da carga
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Carga
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Criado em
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Origem
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Destino
                  </Text>
                </th>
              </tr>
            </thead>
            <tbody>
              {data.last?.map(load => {
                const title = `Cliente ${load.social_name} - ${load.cgccpf}`;

                return (
                  <tr>
                    <td>
                      <Text type="regular" color="gray">
                        {load.cost_center || '-'}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray">
                        {load.load_tags || '-'}
                      </Text>
                    </td>
                    <td>
                      <Link
                        to={`/cargas/${load.id}`}
                        title={title}
                        target="_blank"
                      >
                        {load.id}
                      </Link>
                    </td>
                    <td>
                      <Text type="regular" color="gray">
                        {formatDateTime(load.created_at)}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray">
                        {load.city_origin} - {load.uf_origin}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray">
                        {load.city_destination} - {load.uf_destination}
                      </Text>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {!!data?.total && (
            <SeeAll
              to={{ pathname: '/cargas', search: queryParams.toString() }}
            >
              ver todas as {data?.total} cargas
            </SeeAll>
          )}
        </>
      }
      NumericComponent={
        <div className="d-flex justify-content-center text-center flex-column">
          <Number>{formatCurrency(data?.sum)}</Number>
          <Description>{dateLabel}</Description>
        </div>
      }
    />
  );
}
