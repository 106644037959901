import styled from 'styled-components';
import theme from 'v3/theme';

import { FaQuestionCircle } from 'react-icons/fa';

export const QuestionIcon = styled(FaQuestionCircle)`
  position: absolute;
  right: 50px;
  top: 15px;
  color: ${theme.colors.light_blue};
  z-index: 1;
`;
