import * as Yup from 'yup';

const DataSchema = Yup.object().shape({
  name: Yup.string().required('Nome obrigatório'),
  phone: Yup.string().required('Telefone obrigatório'),
  cpf: Yup.string().required('Cpf obrigatório'),
  oldPassword: Yup.string().required('Forneça a senha antiga'),
  newPassword: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!\"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~])[A-Za-z0-9!\"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]{8,}$/,
      'A senha precisa ter no mínimo 8 caracteres, 1 letra maiúscula, 1 letra minúscula, e um caracter especial'
    )
    .required('Informe a nova senha'),
  newPasswordConfirmation: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'As senhas precisam ser iguais')
    .required('Confirme a senha'),
});

export async function validateAccount(data, setErrors) {
  const errorList = {};
  try {
    await DataSchema.validate(data, {
      abortEarly: false,
    });
    return true;
  } catch (err) {
    err.inner.forEach(error => {
      errorList[error.path] = error?.message;
    });

    return false;
  } finally {
    setErrors(errorList);
  }
}
