import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { Button, Fallback, Modal, Text } from 'v3/components';
import { DefaultLoadingComponent } from 'v3/components/List';
import { cooperplaceApi } from 'services/api';
import { useSnackbar } from 'v3/components/Snackbar';
import { RegisterFilter } from './RegisterFilter';
import { AvailableQuestions } from './AvailableQuestions';
import { SelectedQuestions } from './SelectedQuestions';
import { StyledInfinityScroll } from './styles';

export function QuestionsModal({ isOpen, onClose, setQuestions, questions }) {
  const snackbar = useSnackbar();
  const [availableQuestions, setAvailableQuestions] = useState([]);
  const [questionTypes, setQuestionTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedQuestions, setSelectedQuestions] = useState(questions);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    last_page: 1,
  });
  const questionsIds = questions?.map(item => item.id);
  const selectedQuestionsIds = selectedQuestions?.map(item => item?.id);

  async function fetchQuestionTypes() {
    try {
      const { data } = await cooperplaceApi.get('questions-types');
      setQuestionTypes(data);
    } catch (error) {
      snackbar.show(
        <Text>
          Não foi possível carregar todas as opções de seleções. Recarregue a
          página.
        </Text>,
        {
          type: 'error',
          showClose: true,
        }
      );
    }
  }

  async function fetchAvailableQuestions(params) {
    try {
      if (params?.page === 1) {
        setLoading(true);
      }
      const { data } = await cooperplaceApi.get('checklists-questions', {
        params: {
          page: params?.page,
          limit: params?.limit,
          question: params?.name,
          typeId: params?.questionType?.map(item => item.id),
        },
      });

      const filtered = data?.data?.map(item => {
        if (
          questionsIds?.includes(item?.id) ||
          selectedQuestionsIds?.includes(item?.id)
        ) {
          return {
            ...item,
            disabled: true,
          };
        }
        return item;
      });
      setAvailableQuestions(old => {
        if (params?.page === 1) {
          return filtered;
        }
        return [...old, ...filtered];
      });
      setPagination(data?.meta);
    } catch (error) {
      // catcher
    } finally {
      setLoading(false);
    }
  }

  function renderItem(item, index) {
    return (
      <AvailableQuestions
        available={item}
        setAvailableQuestions={setAvailableQuestions}
        index={index}
        setSelectedQuestions={setSelectedQuestions}
      />
    );
  }

  function onFilterChange(newFilters) {
    const params = {
      ...newFilters,
      page: 1,
      limit: pagination?.per_page,
    };
    fetchAvailableQuestions(params);
  }

  function handleClose(mappedMerged) {
    setAvailableQuestions([]);
    setSelectedQuestions(mappedMerged || questions);
    onClose();
  }

  function handleSelect() {
    setQuestions(() => {
      const mappedMerged = selectedQuestions
        ?.filter((obj, index, self) => {
          const indexOfFirstOccurrence = self.findIndex(o => o.id === obj.id);
          return indexOfFirstOccurrence === index;
        })
        .map((item, idx) => {
          return {
            ...item,
            order: idx + 1,
          };
        });
      handleClose(mappedMerged);
      return mappedMerged;
    });
  }

  useEffect(() => {
    if (isOpen) {
      fetchAvailableQuestions(pagination);
      fetchQuestionTypes();
    }
  }, [isOpen]);

  useEffect(() => {
    setSelectedQuestions(questions);
  }, [questions]);

  return (
    <Modal
      show={isOpen}
      handleClose={handleClose}
      heading={
        <Text type="regular" color="dark" weight="500">
          Seleção de perguntas
        </Text>
      }
      body={
        <>
          <RegisterFilter
            onFilterChange={onFilterChange}
            typeOptions={questionTypes}
          />
          <Fallback
            fall={availableQuestions?.length === 0}
            component={
              loading ? (
                <DefaultLoadingComponent />
              ) : (
                <Col xs={12} className="d-flex justify-content-center my-5">
                  <Button disabled>
                    <Text>Nenhum registro encontrado.</Text>
                  </Button>
                </Col>
              )
            }
          >
            <Text type="header" color="dark" weight={500}>
              Perguntas disponíveis
            </Text>
            <StyledInfinityScroll
              loading={loading}
              data={availableQuestions}
              renderItem={(item, index) => renderItem(item, index)}
              onEndReach={() =>
                fetchAvailableQuestions({
                  page: pagination?.current_page + 1,
                  limit: pagination?.per_page,
                })
              }
              hasMore={
                pagination?.current_page < pagination?.last_page && !loading
              }
              scrollThreshold={0.7}
              height={350}
            />
          </Fallback>
          <SelectedQuestions
            setSelectedQuestions={setSelectedQuestions}
            selectedQuestions={selectedQuestions}
            setAvailableQuestions={setAvailableQuestions}
          />
        </>
      }
      footer={
        <Button
          disabled={selectedQuestions?.length === 0}
          onClick={handleSelect}
        >
          <Text>Salvar</Text>
        </Button>
      }
    />
  );
}
