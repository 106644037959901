import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useParams } from 'react-router-dom';

import api from 'services/api';

import { useEffectThrottled, useModal } from 'hooks';
import { FaUnlink } from 'react-icons/fa';

import Button from 'v3/components/Button';
import Card from 'v3/components/Card';
import DataTable from 'v3/components/DataTable';
import Fallback from 'v3/components/Fallback';
import Text from 'v3/components/Text';
import { useSnackbar } from 'v3/components/Snackbar';
import { useCompany } from '../context';
import PriorityModal from '../Modals/PriorityModal';

import theme from '../../../../theme';

export default function PriorityFleet() {
  const company = useCompany();
  const params = useParams();
  const snackbar = useSnackbar();
  const priorityModal = useModal();
  const [isFetching, setFetching] = useState(false);
  const [modalName, setModalName] = useState('');
  const [search, setSearch] = useState('');
  const [perPage, setPerPage] = useState(5);
  const [page, setPage] = useState(1);
  const [fallback, setFallback] = useState(false);
  const [registeredFleet, setRegisteredFleet] = useState({
    data: [],
    total: 0,
    lastPage: 1,
  });

  useEffect(() => {
    if (modalName === 'PriorityModal') {
      priorityModal.open();
    }
  }, [modalName]);

  async function fleetSearch(currentPage = page) {
    try {
      setFetching(true);
      const response = await api.get(
        `v3/company/${params.id}/priority-fleet-vehicles`,
        {
          params: { search, perPage, page: currentPage },
        }
      );
      company.setData({ priorityFleetVehicles: response.data });
      company.setPrevCompany(prev => ({
        ...prev,
        priorityFleetVehicles: response.data,
      }));
    } catch (error) {
      snackbar.show(
        <Text> Não foi possível listar os veículos vinculados.</Text>,
        { type: 'error' }
      );
    } finally {
      setFetching(false);
    }
  }

  useEffect(() => {
    if (params.id) {
      fleetSearch();
    }
  }, []);

  async function detachFleet(vehicle) {
    try {
      if (params.id) {
        await api.delete(`v3/company/${company.data.id}/vehicle/${vehicle}`);
        fleetSearch(
          company.data.priorityFleetVehicles?.data?.length !== 1
            ? page
            : page - 1
        );
      }
    } catch (ex) {
      snackbar.show(<Text>Não foi possível desvincular esse veículo.</Text>, {
        type: 'error',
      });
    }
    if (!params.id)
      company.setData({
        priorityFleetVehicles: {
          ...company.data.priorityFleetVehicles,
          data: company.data.priorityFleetVehicles.data.filter(
            item => item.id !== vehicle
          ),
        },
      });
  }

  useEffect(() => {
    if (!params.id) {
      const newData = company.data?.priorityFleetVehicles?.data
        .filter(
          item =>
            item.plate.includes(search.toUpperCase()) ||
            item.driverList?.social_name
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            item.driverList?.cgccpf.includes(search)
        )
        .slice((page - 1) * perPage, perPage + (page - 1) * perPage);
      if (newData?.length === 0 && page > 1) setPage(page - 1);
      setRegisteredFleet({
        data: newData,
        total: company.data?.priorityFleetVehicles?.data?.length,
        lastPage: Math.ceil(
          company.data?.priorityFleetVehicles?.data?.length / perPage || 1
        ),
      });
    }
  }, [search, perPage, page, company.data?.priorityFleetVehicles]);

  useEffectThrottled(
    () => {
      if (params.id) {
        fleetSearch();
      }
    },
    [search, perPage, page],
    300
  );

  function openModal() {
    setModalName('PriorityModal');
  }

  useEffect(() => {
    if (!params.id) {
      company.setData({
        priorityFleetVehicles: {
          data: [],
          total: company.data?.priorityFleetVehicles?.data?.length,
          page: 1,
          lastPage: 1,
          perPage: 5,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (
      company?.data?.priorityFleetVehicles?.data.length === 0 &&
      page === 1 &&
      !search
    ) {
      setFallback(true);
      return;
    }
    setFallback(false);
  }, [company?.data?.priorityFleetVehicles?.data]);

  return (
    <>
      <PriorityModal
        fleetSearch={fleetSearch}
        isOpen={priorityModal.isOpen}
        onClose={() => {
          priorityModal.close();
          setModalName('');
        }}
      />

      <Card
        header={
          <Text type="header" color="dark">
            Frota Prioritária
          </Text>
        }
        HeaderRightComponent={
          !fallback && (
            <Button
              className="py-2"
              onClick={() => {
                openModal();
              }}
              disabled={!company.hasPermissionToEdit}
            >
              <Text type="regular" weight="500" color="white">
                Adicionar
              </Text>
            </Button>
          )
        }
      >
        <Row>
          <Col>
            <Fallback
              fall={fallback}
              component={
                <Col
                  xs={12}
                  className="flex-column align-items-center d-flex justify-content-center my-2"
                >
                  <Text color={theme.colors.gray} type="regular">
                    Não há veículos cadastrados para essa empresa.
                  </Text>
                  <Button
                    className="mt-3 py-2"
                    onClick={() => {
                      openModal();
                    }}
                    disabled={!company.hasPermissionToEdit}
                  >
                    <Text type="regular" weight="500" color="white">
                      Adicionar
                    </Text>
                  </Button>
                </Col>
              }
            >
              <DataTable
                data={
                  params.id
                    ? company.data?.priorityFleetVehicles?.data
                    : registeredFleet.data
                }
                total={
                  params.id
                    ? company.data?.priorityFleetVehicles?.total
                    : registeredFleet.total
                }
                pages={
                  params.id
                    ? company.data?.priorityFleetVehicles?.lastPage
                    : registeredFleet.lastPage
                }
                currentPage={
                  params.id ? company.data?.priorityFleetVehicles?.page : page
                }
                onPageChanged={setPage}
                onSearchChanged={setSearch}
                onPerPageChanged={setPerPage}
                loading={isFetching}
                searchPlaceholder="Motorista / CPF / Placa"
                columns={[
                  { name: 'Placa', selector: row => row.plate },
                  {
                    name: 'Motorista',
                    selector: row =>
                      row.driverList?.social_name || 'Sem motorista',
                  },
                  {
                    name: 'Documento',
                    selector: row => row.driverList?.cgccpf || 'Sem CPF',
                  },

                  {
                    name: 'Telefone',
                    selector: row => row.driverList?.phone || 'Sem telefone',
                  },
                  {
                    name: 'Proprietário(a)',
                    selector: row =>
                      row.owner?.social_name || 'Sem proprietário',
                  },
                  {
                    name: 'Ação',
                    render: row => (
                      <Button
                        variant="error"
                        size="sm"
                        className="mx-1"
                        onClick={() => detachFleet(row.id)}
                        disabled={!company.hasPermissionToEdit}
                      >
                        <Text>
                          <FaUnlink />
                        </Text>
                      </Button>
                    ),
                  },
                ]}
              />
            </Fallback>
          </Col>
        </Row>
      </Card>
    </>
  );
}
