import api from 'services/api';
import { fetchCountries } from 'services/api/Locations';
import {
  fetchAnttTypes,
  fetchPaymentMethods,
  fetchTrackers,
  fetchVehiclesTypes,
  fetchBodies,
  fetchAxis,
  fetchFuel,
  fetchImplements,
  fetchAllProvinces,
} from 'utils/fetches';
import { colors } from 'v3/pages/Vehicle/shared/controller';

let countriesOptions = [];
let anttTypesRntrcTypesOptions = [];
let paymentMethodsOptions = [];
let trackersOptions = [];
let vehicleTypesOptions = [];
let vehicleBodiesOptions = [];
let vehicleAxisOptions = [];
let fuelOptions = [];
let implementTypesOptions = [];
let provincesOptions = [];

function processValue(value) {
  const tempValue = value.map(v => {
    return {
      id: v.id,
      label: v.name,
    };
  });
  return tempValue;
}

fetchAllProvinces().then(value => {
  let processedValue = processValue(value);
  provincesOptions.push(...processedValue);
});

fetchImplements().then(value => {
  let processedValue = processValue(value);
  implementTypesOptions.push(...processedValue);
});

fetchFuel().then(value => {
  let processedValue = processValue(value);
  fuelOptions.push(...processedValue);
});

fetchAxis().then(value => {
  let processedValue = processValue(value);
  vehicleAxisOptions.push(...processedValue);
});

fetchBodies().then(value => {
  let processedValue = processValue(value);
  vehicleBodiesOptions.push(...processedValue);
});

fetchVehiclesTypes().then(value => {
  let processedValue = processValue(value);
  vehicleTypesOptions.push(...processedValue);
});

fetchTrackers().then(value => {
  let processedValue = processValue(value);
  trackersOptions.push(...processedValue);
});

fetchPaymentMethods().then(value => {
  let processedValue = processValue(value);
  paymentMethodsOptions.push(...processedValue);
});

fetchAnttTypes().then(value => {
  let processedValue = processValue(value);
  anttTypesRntrcTypesOptions.push(...processedValue);
});

fetchCountries().then(value => {
  let processedValue = processValue(value);
  countriesOptions.push(...processedValue);
});

const civilStatusList = [
  {
    value: 3,
    label: 'Solteiro',
  },
  {
    value: 2,
    label: 'Casado',
  },
  {
    value: 1003,
    label: 'Divorciado',
  },

  {
    value: 1,
    label: 'Viúvo',
  },
];

const propertyTypeList = [
  { value: 'casa', label: 'Casa' },
  { value: 'apartamento', label: 'Apartamento' },
  { value: 'zona rural', label: 'Zona rural' },
];

const propertyStatusList = [
  { value: 'proprio', label: 'Próprio' },
  { value: 'alugado', label: 'Alugado' },
  { value: 'financiado', label: 'Financiado' },
  { value: 'outros', label: 'Outros' },
];

const cnhTypes = [
  { value: 'A', label: 'A' },
  { value: 'AB', label: 'AB' },
  { value: 'AC', label: 'AC' },
  { value: 'AD', label: 'AD' },
  { value: 'AE', label: 'AE' },
  { value: 'B', label: 'B' },
  { value: 'C', label: 'C' },
  { value: 'D', label: 'D' },
  { value: 'E', label: 'E' },
];

const genderOptions = [
  { value: 'F', label: 'Feminino' },
  { value: 'M', label: 'Masculino' },
];

export const requestTypeOptions = [
  { label: 'Veículo', value: 1 },
  { label: 'Motorista', value: 2 },
  { label: 'Transportadora', value: 3 },
];

export const allSelectWithOptionsFields = {
  rntrc_type: {
    options: anttTypesRntrcTypesOptions,
    label: 'Tipo RNTRC',
  },
  country_id: {
    options: countriesOptions,
    label: 'País',
  },
  payment_method: {
    options: paymentMethodsOptions,
    label: 'Método de Pagamento',
  },
  antt_type: {
    options: anttTypesRntrcTypesOptions,
    label: 'Tipo ANTT',
  },
  tracker_id: {
    options: trackersOptions,
    label: 'Rastreador',
  },
  vehicle_type_id: {
    options: vehicleTypesOptions,
    label: 'Tipo de Veículo',
  },
  vehicle_body_type_id: {
    options: vehicleBodiesOptions,
    label: 'Tipo carroceria',
  },
  vehicle_axis_id: {
    options: vehicleAxisOptions,
    label: 'Quantidade de Eixos',
  },
  fuel_id: {
    options: fuelOptions,
    label: 'Combustível',
  },
  color: {
    options: colors,
    label: 'Cor',
  },
  vehicle_implement_type: {
    options: implementTypesOptions,
    label: 'Tipo implemento',
  },
  rg_emissor: {
    options: provincesOptions,
    label: 'UF Emissor',
  },
  province_id: {
    options: provincesOptions,
    label: 'Estado',
  },
  born_province_id: {
    options: provincesOptions,
    label: 'Estado de Nascimento',
  },
  civil_state_id: {
    options: civilStatusList,
    label: 'Estado Civil',
  },
  civil_status: {
    options: civilStatusList,
    label: 'Status Civil',
  },
  property_type: {
    options: propertyTypeList,
    label: 'Tipo Propriedade',
  },
  property_status: {
    options: propertyStatusList,
    label: 'Status Propriedade',
  },
  cnh_type: {
    options: cnhTypes,
    label: 'Tipo CNH',
  },
  gender: {
    options: genderOptions,
    label: 'Gênero',
  },
};

export const allRadioFields = {
  mopp: {
    label: 'Mopp',
  },
  exclusive: {
    label: 'Exclusivo',
  },
  tracked: {
    label: 'Monitorado',
  },
};

export const allSelectAsyncCityFields = {
  city: {
    label: 'Cidade',
  },
  born_city_id: {
    label: 'Cidade de Nascimento',
  },
  cnh_dispatch_city: {
    label: 'Cidade de Emissão CNH',
  },
  plate_city: {
    label: 'Cidade da Placa',
  },
};
