import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  DatePicker,
  Input,
  Link,
  Modal,
  Radio,
  Select,
  Table,
  Text,
} from 'v3/components';
import { DefaultLoadingComponent } from 'v3/components/List';
import { Col, Row } from 'react-bootstrap';
import dashboardApi from 'services/apis/dashboard';
import { formatDateTime } from 'v3/utils/formatter';
import {
  fetchBodies,
  fetchCustomers,
  fetchImplements,
  fetchUserCompaniesSelectOptions,
  fetchUserCostCentersByCompany,
  fetchUsers,
  fetchVehiclesTypes,
} from 'utils/fetches';
import { useSnackbar } from 'v3/components/Snackbar';
import * as Yup from 'yup';
import moment from 'moment';
import Card, { Description, Number, SeeAll } from './Card';

const formatDate = value => moment(value, 'DD-MM-YYYY');

const filterSchema = Yup.object().shape({
  startDate: Yup.string()
    .test('validDateTime', 'Forneça uma data válida', function (value) {
      if (value?.length) {
        return formatDate(value).isValid();
      }
      return true;
    })
    .test(
      'isValidBetween',
      'Data inicial deve ser menor do que data final',
      function (value) {
        const { endDate } = this.parent;
        if (!value && !endDate) {
          return true;
        }

        if (!value || !endDate) {
          return false;
        }
        if (formatDate(value).isValid() && formatDate(endDate).isValid()) {
          return formatDate(value).isBefore(formatDate(endDate));
        }
        return true;
      }
    ),
  endDate: Yup.string()
    .test('validDateTime', 'Forneça uma data válida', function (value) {
      if (value?.length) {
        return formatDate(value).isValid();
      }
      return true;
    })
    .test(
      'isValidBetween',
      'Data final deve ser maior do que data inicial',
      function (value) {
        const { startDate } = this.parent;
        if (!value && !startDate) {
          return true;
        }

        if (!value || !startDate) {
          return false;
        }
        if (formatDate(value).isValid() && formatDate(startDate).isValid()) {
          return formatDate(value).isAfter(formatDate(startDate));
        }
        return true;
      }
    ),
});

function FilterModal({
  onFilter = () => {},
  modalHook,
  initialFilter,
  ...props
}) {
  const snackbar = useSnackbar();
  const [loadingFilters, setLoadingFilters] = useState(false);
  const [errors, setErrors] = useState();
  const [filter, setFilters] = useState({
    duePreLoads: 'SHOW',
    ...initialFilter,
  });
  const [options, setOptions] = useState({
    company: [],
    vehicleTypes: [],
    implementTypes: [],
    bodyTypes: [],
    status: [
      { label: 'Todos', value: 'ALL' },
      { label: 'Atendidas', value: 'ATTENDED' },
      { label: 'Não atendidas', value: 'NOT_ATTENDED' },
      { label: 'Canceladas', value: 'CANCELLED' },
    ],
  });

  const fetchCompanies = async () => {
    const company = await fetchUserCompaniesSelectOptions();
    setOptions(old => ({ ...old, company }));
  };

  const fetchVehicles = async () => {
    const vehicleTypes = await fetchVehiclesTypes();
    setOptions(old => ({ ...old, vehicleTypes }));
  };

  const fetchImplementTypes = async () => {
    setLoadingFilters(true);
    const implementTypes = await fetchImplements();
    setOptions(old => ({ ...old, implementTypes }));
  };

  const fetchBodyTypes = async () => {
    setLoadingFilters(true);
    const bodyTypes = await fetchBodies();
    setOptions(old => ({ ...old, bodyTypes }));
  };

  const fetchInitial = async () => {
    try {
      setLoadingFilters(true);
      await Promise.all([
        fetchCompanies(),
        fetchVehicles(),
        fetchImplementTypes(),
        fetchBodyTypes(),
      ]);
    } catch (error) {
      snackbar.show(
        <Text>
          Ocorreu um erro ao trazer as opções iniciais, favor recarregar a
          página
        </Text>,
        { type: 'error' }
      );
    } finally {
      setLoadingFilters(false);
    }
  };

  const handleChange = (value, key) => {
    setFilters(old => ({ ...old, [key]: value }));
    setErrors();
  };

  const submitFilter = async () => {
    const errorList = {};
    try {
      await filterSchema.validate(filter, {
        abortEarly: false,
      });
      onFilter(filter);
      modalHook.close();
    } catch (error) {
      if (error?.inner?.length) {
        error.inner.forEach(e => {
          errorList[e.path] = e?.message;
        });
      }
    } finally {
      setErrors(errorList);
    }
  };

  useEffect(() => {
    fetchInitial();
  }, []);

  return (
    <Modal
      body={
        loadingFilters ? (
          <DefaultLoadingComponent />
        ) : (
          <>
            <Row>
              <Col xs={12} md={6} className="mb-3">
                <Select
                  label="Empresa"
                  multiple
                  options={options.company}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  value={filter?.companies}
                  onChange={value => handleChange(value, 'companies')}
                />
              </Col>
              <Col xs={12} md={6} className="mb-3">
                <Input
                  onChange={({ target: { value } }) =>
                    handleChange(value && Math.abs(value), 'orderNumber')
                  }
                  label="Nº pedido do cliente"
                  value={filter?.orderNumber}
                  type="number"
                />
              </Col>
              <Col xs={12} md={6} className="mb-3">
                <Select.Async
                  onSearch={search => fetchUserCostCentersByCompany(search)}
                  getOptionLabel={option => option?.name}
                  getOptionValue={option => option?.id}
                  label="Centro de custo da carga"
                  multiple
                  onChange={value => handleChange(value, 'costCenters')}
                  value={filter?.costCenters}
                />
              </Col>
              <Col xs={12} md={6} className="mb-3">
                <Select.Async
                  onSearch={search => fetchCustomers({ search })}
                  getOptionLabel={option => {
                    let label = `${option?.social_name} - ${option?.cgccpf}`;
                    if (option.city) {
                      label += ` - ${option?.city}`;
                    }
                    return label;
                  }}
                  getOptionValue={option => option.id}
                  label="Cliente"
                  multiple
                  onChange={value => handleChange(value, 'clients')}
                  value={filter?.clients}
                />
              </Col>
              <Col xs={12} md={6} className="mb-3">
                <Select.Async
                  onSearch={search => fetchUsers(search)}
                  label="PCP"
                  getOptionLabel={option => {
                    let label = `${option.username}`;
                    if (option?.cpf) {
                      label += ` - ${option?.cpf}`;
                    }
                    return label;
                  }}
                  getOptionValue={option => option.id}
                  multiple
                  onChange={value => handleChange(value, 'pcps')}
                  value={filter?.pcps}
                />
              </Col>
              <Col xs={12} md={6} className="mb-3">
                <Select
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  options={options.vehicleTypes}
                  label="Tipo de veículo"
                  multiple
                  onChange={value => handleChange(value, 'vehicleTypes')}
                  value={filter?.vehicleTypes}
                />
              </Col>
              <Col xs={12} md={6} className="mb-3">
                <Select
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  options={options.implementTypes}
                  label="Tipo de implemento"
                  multiple
                  onChange={value => handleChange(value, 'implementTypes')}
                  value={filter?.implementTypes}
                />
              </Col>
              <Col xs={12} md={6} className="mb-3">
                <Select
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  options={options.bodyTypes}
                  label="Tipo de carroceria"
                  multiple
                  onChange={value => handleChange(value, 'bodyTypes')}
                  value={filter?.bodyTypes}
                />
              </Col>
              <Col md={6} xs={12} className="mb-3">
                <Select
                  options={options.status}
                  getOptionLabel={option => option.label}
                  getOptionValue={option => option.value}
                  label="Status"
                  onChange={value => handleChange(value, 'status')}
                  value={filter?.status}
                />
              </Col>
              <Col xs={12} md={6} className="mb-3">
                <Radio.Group
                  label="Pré cargas vencidas"
                  horizontal
                  onChange={({ target: { value } }) =>
                    handleChange(value, 'duePreLoads')
                  }
                  value={filter?.duePreLoads}
                >
                  <Radio value="SHOW">
                    <Text color="dark" type="label">
                      Exibir
                    </Text>
                  </Radio>
                  <Radio value="DONT_SHOW">
                    <Text color="dark" type="label">
                      Ocultar
                    </Text>
                  </Radio>
                </Radio.Group>
              </Col>
              <Col xs={12} md={6}>
                <DatePicker
                  onChange={({ target: { value } }) =>
                    handleChange(value, 'startDate')
                  }
                  guide
                  label="Período de"
                  format="DD/MM/YYYY"
                  value={filter?.startDate}
                  error={errors?.startDate}
                />
              </Col>
              <Col xs={12} md={6}>
                <DatePicker
                  onChange={({ target: { value } }) =>
                    handleChange(value, 'endDate')
                  }
                  guide
                  label="Até "
                  format="DD/MM/YYYY"
                  value={filter?.endDate}
                  error={errors?.endDate}
                />
              </Col>
            </Row>
          </>
        )
      }
      footer={
        <Button onClick={submitFilter} className="py-2">
          <Text color="white" type="regular" weight="500">
            Filtrar
          </Text>
        </Button>
      }
      heading={
        <Text color="dark" type="subheader" weight="bold">
          Filtros
        </Text>
      }
      show={modalHook.isOpen}
      onHide={modalHook.close}
      {...props}
    />
  );
}

export default function PreLoads({ dashboard, name, id }) {
  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  const [filter, setFilter] = useState();
  const [currentPage, setCurrentPage] = useState();
  const [pagination, setPagination] = useState();

  const filterMapper = filterProp => ({
    bodyTypes: filterProp?.bodyTypes?.map(body => body.id) ?? [],
    clients: filterProp?.clients?.map(client => client.id) ?? [],
    companies: filterProp?.companies?.map(company => company.id) ?? [],
    costCenters:
      filterProp?.costCenters?.map(costCenter => costCenter.id) ?? [],
    duePreLoads: filterProp?.duePreLoads,
    endDate: moment(filterProp?.endDate, 'DD/MM/YYYY').toISOString(),
    implementTypes:
      filterProp?.implementTypes?.map(implement => implement.id) ?? [],
    orderNumber: filterProp?.orderNumber,
    pcps: filterProp?.pcps?.map(pcp => pcp.id) ?? [],
    startDate: moment(filterProp?.startDate, 'DD/MM/YYYY').toISOString(),
    status: filterProp?.status?.value,
    vehicleTypes:
      filterProp?.vehicleTypes?.map(vehicleType => vehicleType.id) ?? [],
  });

  const fetchPreLoads = useCallback(async (filters = {}) => {
    try {
      setIsLoading(true);
      const { page = 1 } = filters;
      if (page === 1) {
        setIsFetching(true);
      }
      const filterMapped = filterMapper(filters);
      setCurrentPage(+page);
      const response = await dashboardApi.get('pre-loads', {
        params: {
          page,
          ...filterMapped,
        },
      });
      setPagination(response.data.last.pagination);
      if (page === 1) {
        setData(response.data);
      } else {
        setData(oldData => ({
          ...oldData,
          last: {
            ...oldData.last,
            data: [...oldData.last.data, ...response.data.last.data],
          },
        }));
      }
    } catch (error) {
      // Handle exception
    } finally {
      setIsFetching(false);
      setIsLoading(false);
    }
  }, []);

  const onFilter = filterProp => setFilter(filterProp);

  useEffect(() => {
    fetchPreLoads(filter);
  }, [filter]);

  return (
    <Card
      title="Pré cargas"
      isLoading={isFetching}
      dashboard={dashboard}
      name={name}
      id={id}
      CustomFilterModal={{
        Component: FilterModal,
        onFilter,
        filter,
      }}
      ListComponent={
        <>
          <Table striped>
            <thead>
              <tr>
                <th>
                  <Text weight="500" color="dark">
                    Nº Pré carga
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Empresa
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Status
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Centro de custo
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Razão Social - CPF/CNPJ
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Origem
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Destino
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Data de Vencimento da carga
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    N Pedido Cliente
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Veículo
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Carroceria
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Implemento
                  </Text>
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.last?.data?.map(preLoad => (
                <tr key={preLoad.loadId}>
                  <th>
                    <Link to={`/pre-cargas/${preLoad.loadId}`} target="_blank">
                      {preLoad.loadId}
                    </Link>
                  </th>
                  <th>
                    <Text type="regular" color="gray">
                      {preLoad.company}
                    </Text>
                  </th>
                  <th>
                    <Text type="regular" color="gray">
                      {preLoad.status}
                    </Text>
                  </th>
                  <th>
                    <Text type="regular" color="gray">
                      {preLoad.costCenter}
                    </Text>
                  </th>

                  <th>
                    <Text type="regular" color="gray">
                      {`${preLoad.clientSocialName} - ${preLoad.clientCgccpf}`}
                    </Text>
                  </th>
                  <th>
                    <Text type="regular" color="gray">
                      {preLoad.origin}
                    </Text>
                  </th>
                  <th>
                    <Text type="regular" color="gray">
                      {preLoad.destination}
                    </Text>
                  </th>
                  <th>
                    <Text type="regular" color="gray">
                      {formatDateTime(preLoad.dueDate)}
                    </Text>
                  </th>
                  <th>
                    <Text type="regular" color="gray">
                      {preLoad.orderNumber || 'Não cadastrado'}
                    </Text>
                  </th>
                  <th>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      {preLoad.vehiclesTypes?.length &&
                        preLoad.vehiclesTypes.split(',').map(vehicle => (
                          <Text type="regular" color="gray">
                            {vehicle}
                          </Text>
                        ))}
                    </div>
                  </th>
                  <th>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      {preLoad.bodyTypes?.length &&
                        preLoad.bodyTypes.split(',').map(bodyType => (
                          <Text type="regular" color="gray">
                            {bodyType}
                          </Text>
                        ))}
                    </div>
                  </th>
                  <th>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      {preLoad.implementTypes?.length &&
                        preLoad.implementTypes.split(',').map(implement => (
                          <Text type="regular" color="gray">
                            {implement}
                          </Text>
                        ))}
                    </div>
                  </th>
                </tr>
              ))}
            </tbody>
          </Table>
          {pagination?.lastPage > 1 && pagination?.to < pagination?.total && (
            <div className="text-center mt-3 col-12">
              <Button
                className="mt-2 ml-auto align-item-center"
                onClick={() => {
                  fetchPreLoads({ ...filter, page: currentPage + 1 });
                }}
                loading={isLoading}
              >
                <Text type="regular" color="white" truncate>
                  Ver mais
                </Text>
              </Button>
            </div>
          )}

          {!!data?.total && (
            <SeeAll to={{ pathname: '/pre-cargas' }}>
              ver todas as {data?.total} cargas
            </SeeAll>
          )}
        </>
      }
      NumericComponent={
        <div className="d-flex justify-content-center text-center flex-column">
          <Number color="success">{data?.total || 0}</Number>
          <Description>
            {filter?.startDate && filter?.endDate
              ? `de ${filter?.startDate} à ${filter?.endDate}`
              : 'Em todo período'}
          </Description>
        </div>
      }
    >
      TESTE
    </Card>
  );
}
