/* eslint-disable import/no-unresolved */
import React from 'react';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import moment from 'moment';

function formatCityProvince(obj) {
  return `${
    obj.city && obj.province
      ? `${obj.city} - ${obj.province}`
      : obj.city
      ? obj.city
      : obj.province
      ? obj.province
      : 'Sem localização'
  }`;
}

export function locationsObject(showAllDestinations = true, preLoad, handleShow) {
  const destinationsToDisplay = showAllDestinations
    ? preLoad.loadDestinations
    : preLoad.loadDestinations?.slice(-1);

  const items = [
    ...preLoad?.loadOrigins?.map(item => ({
      header: (
        <div className="d-flex flex-row justify-content-between">
          <Text>Origem</Text>
        </div>
      ),
      body: (
        <>
          <Text className="my-2" color="gray" type="regular">
            {formatCityProvince(item)}
          </Text>
          <Text color="gray" type="regular">
            {moment(item?.scheduledTime).format('DD/MM/YYYY HH:mm')}
          </Text>
        </>
      ),
      filledCircle: false,
    })),
  ];

  if (!showAllDestinations && preLoad.loadDestinations.length > 1)
    items.push({
      header: (
        <Button onClick={handleShow} link>
          <Text type="regular">
            {preLoad.loadDestinations.length - 1} destinos
          </Text>
        </Button>
      ),
      link: true,
    });

  items.push(
    ...destinationsToDisplay.map(item => ({
      header: (
        <div className="d-flex flex-row justify-content-between">
          <Text>
            {item?.action?.toLowerCase() === 'customhouse'
              ? 'Aduaneiro'
              : 'Destino'}
          </Text>
        </div>
      ),
      body: (
        <>
          <Text className="my-2" color="gray" type="regular">
            {formatCityProvince(item)}
          </Text>
          <Text color="gray" type="regular">
            {moment(item.scheduledTime).format('DD/MM/YYYY HH:mm')}
          </Text>
        </>
      ),
      filledCircle: true,
    }))
  );
  return items;
}
