import React from 'react';

import theme from 'v3/theme';
import Text from '../../Text';

import * as Styled from './styles';

import { formatDateTime, formatNumber } from 'v3/utils/formatter';
import { Col, Row } from 'react-bootstrap';

function MarkerTemperature({ paramsTemperature, background = theme.colors.primary }) {

  return (
    <Styled.Container background={background}>
      <Row>
        <Col lg={12} md={12} xs={12} style={{ marginBottom: '5px', marginTop: '5px' }}>
          {paramsTemperature?.Temperatura1 && (
            <Text color="#fff" type="label">
              Temperatura 1: {formatNumber(paramsTemperature?.Temperatura1) || 0} °C  {formatDateTime(paramsTemperature?.datetimeTemperature1)}
            </Text>
          )}
        </Col>
        <Col lg={12} md={12} xs={12} style={{ marginBottom: '5px' }}>
          {paramsTemperature?.Temperatura2 && (
            <Text color="#fff" type="label">
              Temperatura 2: {formatNumber(paramsTemperature?.Temperatura2) || 0} °C  {formatDateTime(paramsTemperature?.datetimeTemperature2)}
            </Text>
          )}
        </Col>
        <Col lg={12} md={12} xs={12} style={{ marginBottom: '5px' }}>
          {paramsTemperature?.Temperatura3 && (
            <Text color="#fff" type="label">
              Temperatura 3: {formatNumber(paramsTemperature?.Temperatura3) || 0} °C  {formatDateTime(paramsTemperature?.datetimeTemperature3)}
            </Text>
          )}
        </Col>
        <Col lg={12} md={12} xs={12} style={{ marginBottom: '5px' }}>
          {paramsTemperature?.Temperatura4 && (
            <Text color="#fff" type="label">
              Temperatura 4: {formatNumber(paramsTemperature?.Temperatura4) || 0} °C  {formatDateTime(paramsTemperature?.datetimeTemperature4)}
            </Text>
          )}
        </Col>
      </Row>
    </Styled.Container>
  );
}

export default MarkerTemperature;
