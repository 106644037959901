import { Row } from 'react-bootstrap';
import styled from 'styled-components';
import { Link } from 'v3/components/';

import theme from 'v3/theme';

export const Check = styled.div`
  position: relative;
  z-index: 1;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  line-height: 1;
  text-align: center;
  background-color: ${theme.colors.medium_gray};
  > svg {
    display: none;
  }
`;

export const TimeLine = styled.div`
  position: absolute;
  height: 100%;
  left: 0;
  text-align: center;
  &:after {
    position: absolute;
    top: 0;
    border-left: 2px dashed ${theme.colors.medium_gray};
    content: '';
    margin-left: -1px;
    height: 100%;
    z-index: 0;
  }
`;

export const DestinationTimes = styled(Row)`
  border-radius: 8px;
  padding: 20px 0;
  height: 100%;
`;

export const DestinationWrap = styled(Row)`
  padding-left: 24px;
  ${({ completed, progress }) =>
    `
        ${
          completed &&
          `
            ${Check} {
                background-color: ${theme.colors.success};
                > svg {
                    display: inline-block;
                }
            }
                
            ${TimeLine} {
                &:after {
                    border-color: ${theme.colors.success};
                }
            }
            ${DestinationTimes} {
                background-color: ${theme.colors.light_green};
            }
        `
        };

        ${
          progress &&
          `
            ${Check} {
                background-color: ${theme.colors.orange};
            }
            ${TimeLine} {
                &:after {
                    border-color: ${theme.colors.orange};
                }
            }
            ${DestinationTimes} {
                background-color: ${theme.colors.yellow_indicator};
            }
        `
        };
    `}
`;

export const TravelLink = styled(Link)`
  > span {
    font-weight: 500 !important;
  }
`;
