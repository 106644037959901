import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Select from 'react-select';
import Async from 'react-select/async';
import moment from 'moment';
import DatePicker from 'components/DatePicker';
import { validatePermission } from 'actions/index';
import {
  Grid,
  LinearProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import api from 'services/api';
import Label from 'components/Label';
import Snackbar from 'components/Snackbar';
import Card from 'components/Card';
import Button from 'components/Button';
import InfoMessage from 'components/InfoMessage';
import InfiniteScroll from 'components/InfiniteScroll';
import { downloadCSV } from 'functions';
import { usePermission } from 'hooks';
import Filter from './Filter';
import CellAdherence from './CellAdherence';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ReportAdherence() {
  const history = useHistory();
  const query = useQuery();
  const [loadedCompany, setLoadedCompany] = useState(false);
  const [loadedClients, setLoadedClients] = useState(false);
  const [loadedTags, setLoadedTags] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [clients, setClients] = useState([]);
  const [selectedTag, setSelectedTag] = useState({});
  const [clientValues, setClientValues] = useState([]);
  const [isAllClients, setIsAllClients] = useState(false);
  const [isByClient, setIsByClient] = useState(false);
  const [isByTag, setIsByTag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSummary, setLoadingSummary] = useState(false);
  const [summary, setSummary] = useState(null);
  const [clientChecked, setClientChecked] = useState([]);
  const [companyChecked, setCompanyChecked] = useState([]);
  const [loadingAdherence, setLoadingAdherence] = useState(false);
  const [loadingExportAdherence, setLoadingExportAdherence] = useState(false);
  const [total, setTotal] = useState(0);
  const [errors, setErrors] = useState({});
  const [isClientSelectorHidden, setIsClientSelectorHidden] = useState(true);
  const [isTagSelectorHidden, setIsTagSelectorHidden] = useState(true);
  const [completed, setCompleted] = useState(0);
  const [start_date, setStartDate] = useState(
    moment(query.get('start_date')).isValid()
      ? query.get('start_date')
      : moment().format('YYYY-MM-DD')
  );
  const [end_date, setEndDate] = useState(
    moment(query.get('end_date')).isValid()
      ? query.get('end_date')
      : moment().format('YYYY-MM-DD')
  );
  const [dataCSV, setDataCSV] = useState([]);
  const [bodyAux, setBodyAux] = useState({
    page: 1,
    type_filter: query.get('type_filter') || '',
    company_id: query.get('company_id') || '',
    start_date: moment(query.get('start_date')).isValid()
      ? query.get('start_date')
      : moment().format('YYYY-MM-DD'),
    end_date: moment(query.get('end_date')).isValid()
      ? query.get('end_date')
      : moment().format('YYYY-MM-DD'),
    client_ids: '',
    tag_ids: '',
  });
  const [body, setBody] = useState(null);
  const [snackBar, setSnackBar] = useState({
    display: false,
    type: 'error',
    message: '',
    duration: 5000,
  });

  const [hasPermissionToViewAllCompanies, setPermissionToViewAllCompanies] =
    useState();

  useState(() => {
    setPermissionToViewAllCompanies(
      validatePermission('VISUALIZAR_TODAS_AS_EMPRESAS')
    );
  }, []);

  usePermission('VISUALIZAR_RELATORIO_ADERENCIA', { redirect: true });

  function handleQueryCompany(data) {
    if (query.get('company_id')) {
      const companyId = Number(query.get('company_id'));
      const filtered = data.filter(
        function (e) {
          for (const x in this) {
            if (this[x] === e.id) return e;
          }
        },
        [companyId]
      );
      setBodyAux({
        ...bodyAux,
        company_id: companyId,
      });
      setCompanyChecked(companyId);
      setSelectedCompany(filtered);
    }
  }
  function handleQueryClients(data) {
    if (query.get('client_ids')) {
      const clientIds = query
        .get('client_ids')
        .split(',')
        .map(item => Number(item));
      const filtered = data.filter(function (e) {
        for (const x in this) {
          if (this[x] === e.id) return e;
        }
      }, clientIds);
      setBodyAux({
        ...bodyAux,
        client_ids: clientIds,
      });
      setClientValues(filtered);
      setClientChecked(clientIds);
    } else {
      setClientValues([]);
      setClientChecked([]);
    }
  }
  function handleQueryTags(data) {
    if (query.get('tag_ids')) {
      const tagIds = query
        .get('tag_ids')
        .split(',')
        .map(item => Number(item));
      let filtered = data.filter(function (e) {
        for (const x in this) {
          if (this[x] === e.id) return e;
        }
      }, tagIds);
      filtered = filtered.map(e => {
        return { value: e.id, label: e.name };
      });
      setBodyAux({
        ...bodyAux,
        tag_ids: tagIds,
      });
      setSelectedTag(filtered);
    } else {
      setSelectedTag(null);
    }
  }

  useEffect(() => {
    getCompanies();
    getClients();
    getTags();
  }, []);

  async function getSelectedCompany(id) {
    try {
      const response = await api.get(`company/${id}`);
      setSelectedCompany(response.data[0]);
    } catch (ex) {
      console.log(ex);
    }
  }

  const promiseFilterTag = async inputValue => {
    if (inputValue.length > 3) {
      const response = await api.get(`load-tags?&search=${inputValue}`);

      const res = response.data.map(el => {
        return {
          value: el.id,
          label: el.name,
        };
      });
      return res;
    }
  };

  async function getCompanies() {
    try {
      setLoading(true);
      const response = await api.get('company');
      setCompanies(response.data);
      handleQueryCompany(response.data);
      setLoading(false);
    } finally {
      setLoading(false);
      setLoadedCompany(true);
    }
  }

  async function getClients() {
    setLoading(true);
    const usuario = localStorage.getItem('usuario');
    const jsonU = JSON.parse(usuario);
    const response = await api.get(`clients/user/${jsonU.id}`);
    if (response.status === 200) {
      setClients(response.data.clients);
      handleQueryClients(response.data.clients);
      setLoading(false);
      setLoadedClients(true);
    } else {
      setLoading(false);
    }
  }

  async function getTags() {
    setLoading(true);
    const response = await api.get(`load-tags`);
    if (response.status === 200) {
      handleQueryTags(response.data);
      setLoading(false);
      setLoadedTags(true);
    } else {
      setLoading(false);
    }
  }

  function handleAllClientsOption() {
    setIsClientSelectorHidden(true);
    setIsTagSelectorHidden(true);
    setIsAllClients(true);
    setIsByClient(false);
    setIsByTag(false);

    setClientValues([]);
    setClientChecked([]);
    setSelectedTag(null);
  }

  function handleByClientOption() {
    setIsClientSelectorHidden(false);
    setIsTagSelectorHidden(true);
    setIsAllClients(false);
    setIsByClient(true);
    setIsByTag(false);

    setClientValues([]);
    setClientChecked([]);
    setSelectedTag(null);
  }

  function handleByTag() {
    setIsClientSelectorHidden(true);
    setIsTagSelectorHidden(false);
    setIsAllClients(false);
    setIsByClient(false);
    setIsByTag(true);

    setClientValues([]);
    setClientChecked([]);
  }

  function handleNoneOption() {
    setIsClientSelectorHidden(true);
    setIsTagSelectorHidden(true);
    setIsAllClients(false);
    setIsByClient(false);
    setIsByTag(false);

    setClientValues([]);
    setClientChecked([]);
    setSelectedTag(null);
  }

  // Limita o range de data em até 15 dias
  function verifyDaysLimit(start, end) {
    start = moment(start).hours(0).minute(0);
    end = moment(end).hours(23).minute(59);
    const limit = 15;
    const daysDiff = moment.duration(moment(end).diff(moment(start))).asDays();
    if (daysDiff > limit) {
      return false;
    }
    return true;
  }

  function handleSelectedStart(selected) {
    setStartDate(selected);

    if (selected) {
      const startDate = moment(selected).format('YYYY-MM-DD');
      let endDate = moment(bodyAux.end_date).format('YYYY-MM-DD');

      if (moment(startDate).isAfter(moment(endDate))) {
        endDate = moment(startDate).format('YYYY-MM-DD');
        setEndDate(endDate);
        setBodyAux({
          ...bodyAux,
          start_date: startDate,
          end_date: endDate,
        });
      } else if (!verifyDaysLimit(startDate, endDate)) {
        setSnackBar({
          display: true,
          type: 'warning',
          message:
            'Campo Data Final alterado; o limite de pesquisa é de até 15 dias',
          duration: 10000,
        });
        const new_end = moment(startDate).add('days', 14).format('YYYY-MM-DD');
        setEndDate(new_end);
        setBodyAux({
          ...bodyAux,
          start_date: startDate,
          end_date: new_end,
        });
      } else {
        setBodyAux({
          ...bodyAux,
          start_date: startDate,
        });
      }
    }
  }

  function handleSelectedEnd(selected) {
    setEndDate(selected);

    if (selected) {
      let startDate = moment(bodyAux.start_date).format('YYYY-MM-DD');
      const endDate = moment(selected).format('YYYY-MM-DD');

      if (moment(endDate).isBefore(moment(startDate))) {
        startDate = moment(endDate).format('YYYY-MM-DD');
        setStartDate(startDate);
        setBodyAux({
          ...bodyAux,
          start_date: startDate,
          end_date: endDate,
        });
      } else if (!verifyDaysLimit(startDate, endDate)) {
        setSnackBar({
          display: true,
          type: 'warning',
          message:
            'Campo Data Inicial alterado; o limite de pesquisa é de até 15 dias',
          duration: 10000,
        });
        const new_start = moment(endDate)
          .subtract('days', 14)
          .format('YYYY-MM-DD');
        setStartDate(new_start);
        setBodyAux({
          ...bodyAux,
          end_date: endDate,
          start_date: new_start,
        });
      } else {
        setBodyAux({
          ...bodyAux,
          end_date: endDate,
        });
      }
    }
  }

  function handleSelectClient(selected) {
    setClientValues(selected);

    if (selected) {
      const mapped = selected.map(item => item.id);
      setClientChecked(mapped);
      setBodyAux({
        ...bodyAux,
        client_ids: mapped,
      });
    } else {
      setClientChecked([]);
      setBodyAux({
        ...bodyAux,
        client_ids: '',
      });
    }
  }

  function handleSelectCompany(selected) {
    setSelectedCompany(selected);
    if (selected) {
      const mapped = selected.map(item => item.id);

      setBodyAux({ ...bodyAux, company_id: mapped });
      getSelectedCompany(mapped);
    }
  }

  async function getUserCompany() {
    const { company_id } = JSON.parse(localStorage.getItem('usuario'));
    const response = await api.get(`company/${company_id}`);
    setSelectedCompany(response.data);
    handleSelectCompany([response.data]);
  }

  useState(() => {
    if (!hasPermissionToViewAllCompanies) {
      getUserCompany();
    }
  }, []);

  function handleSelectTag(selected) {
    setSelectedTag(selected);
    if (selected) {
      const mapped = selected.map(item => item.value);
      setBodyAux({ ...bodyAux, tag_ids: mapped });
    } else {
      setBodyAux({ ...bodyAux, tag_ids: [] });
    }
  }

  function handleFilterOptionSelected(option) {
    if (option === 1) {
      handleAllClientsOption();
      setBodyAux({
        ...bodyAux,
        type_filter: 1,
        tag_ids: '',
        client_ids: '',
      });
    } else if (option === 2) {
      handleByClientOption();
      setBodyAux({
        ...bodyAux,
        type_filter: 2,
        tag_ids: '',
      });
    } else if (option === 3) {
      handleByTag();
      setBodyAux({
        ...bodyAux,
        type_filter: 3,
        client_ids: '',
      });
    } else {
      handleNoneOption();
    }
  }

  function validationFilter() {
    if (!bodyAux.company_id) {
      setSnackBar({
        display: true,
        type: 'error',
        message: `Selecione uma empresa`,
      });
      setLoadingAdherence(false);
      return false;
    }

    if (!isAllClients && !isByClient && !isByTag) {
      setSnackBar({
        display: true,
        type: 'error',
        message: `Escolha uma opção de filtro`,
      });
      setLoadingAdherence(false);
      return false;
    }

    if (isByClient && !bodyAux.client_ids) {
      setSnackBar({
        display: true,
        type: 'error',
        message: `Selecione pelo menos um cliente`,
      });
      setLoadingAdherence(false);
      return false;
    }

    if (isByTag && !selectedTag) {
      setSnackBar({
        display: true,
        type: 'error',
        message: `Selecione uma tag`,
      });
      setLoadingAdherence(false);
      return false;
    }
    if (!bodyAux.start_date) {
      setSnackBar({
        display: true,
        type: 'error',
        message: `Selecione uma data de início`,
      });
      setLoadingAdherence(false);
      return false;
    }

    if (!bodyAux.end_date) {
      setSnackBar({
        display: true,
        type: 'error',
        message: `Selecione uma data final`,
      });
      setLoadingAdherence(false);
      return false;
    }
    return true;
  }

  let token;
  async function getReport() {
    setLoadingAdherence(true);

    if (!validationFilter()) return;

    token++;

    try {
      setBody({
        ...bodyAux,
        client_ids: bodyAux.client_ids ? bodyAux.client_ids.join(',') : null,
        tag_ids: bodyAux.tag_ids ? bodyAux.tag_ids.join(',') : null,
        type_filter: Number(bodyAux.type_filter),
        company_id: Number(bodyAux.company_id),
      });
    } catch (ex) {
      console.log('ex', ex);
      if (ex) {
        setSnackBar({
          display: true,
          type: 'error',
          message: 'Filtro inválido',
        });

        setLoadingAdherence(false);
        return;
      }
    } finally {
      setLoadingAdherence(false);
    }
  }

  useEffect(() => {
    if (body) getSummary();
  }, [body]);

  async function getSummary() {
    setLoadingSummary(true);
    try {
      const responseSummary = await api.post('adherence/summary', body);
      if (responseSummary.status === 200) {
        const dataSummary = responseSummary.data[0];
        setSummary({
          totalVisualizations: dataSummary.totalVisualizations,
          totalInterested: dataSummary.totalInterested,
          totalLoads: dataSummary.totalLoads,
        });
      }
    } catch (ex) {
      setSummary({ totalVisualizations: 0, totalInterested: 0, totalLoads: 0 });
      setSnackBar({
        display: true,
        type: 'error',
        message: 'Erro ao obter sumário.',
      });
    } finally {
      setLoadingSummary(false);
    }
  }

  async function onDataFetched(data) {
    setTotal(data.pagination.total);
  }

  function onDataFailure({ error, message }) {
    if (message.response) {
      const { data } = message.response;
      setSnackBar({
        type: 'error',
        message: data ? data.message : 'Erro ao emitir relatório.',
        display: true,
      });
    }
  }

  async function exportReport() {
    setLoadingExportAdherence(true);
    try {
      if (validationFilter()) {
        token++;
        const bodyCSV = {
          ...bodyAux,
          client_ids: bodyAux.client_ids ? bodyAux.client_ids.join(',') : null,
          tag_ids: bodyAux.tag_ids ? bodyAux.tag_ids.join(',') : null,
          type_filter: Number(bodyAux.type_filter),
          company_id: Number(bodyAux.company_id),
        };
        const response = await api.post('adherence/forCSV', bodyCSV);

        if (response.status === 200) {
          if (response.data.total === 0) {
            setSnackBar({
              display: true,
              type: 'error',
              message: 'Sem resultados para o período selecionado',
            });
            return;
          }
          const ids = Array.from(
            { length: response.data.lastPage },
            (v, k) => k + 1
          );
          await ids.reduce(
            (p, x) => p.then(_ => nextPage(x)),
            Promise.resolve()
          );
        }
      }
    } catch (ex) {
      if (ex.response) {
        const { data } = ex.response;
        setSnackBar({
          type: 'error',
          message: data ? data.message : 'Erro ao emitir relatório.',
          display: true,
        });
      }

      setLoadingExportAdherence(false);
      return;
    } finally {
      setLoadingExportAdherence(false);
    }
  }

  async function processDataCSV(dataLoaded = []) {
    let csvData = [];
    const csv = await Promise.all(
      dataLoaded.map(async el => {
        return {
          Carga: el.id,
          Cliente: el.client_social_name,
          'Cliente CNPJ': el.client_cgccpf,
          Empresa: el.company_name,
          'Origem cidade': el.origin_city,
          'Origem estado': el.origin_province,
          'Destino cidade': el.destination_city,
          'Destino estado': el.destination_province,
          Atendida: el.atendida,
          'Visualizações Carga': el.qtd_visualizations_load,
          Interessados: el.qtd_interested_load,
          Motorista: el.driver_username,
          'Motorista Telefone': el.driver_phonenumber,
          'Motorista Interessado': el.driver_interested,
          'Motorista Visualizações': el.driver_qtd_visualizations,
        };
      })
    );
    const filtered = csv.filter(e => !e.no_travels);
    csvData = filtered.map(el => {
      return Object.values(el);
    });
    const header =
      'Carga;Cliente;Cliente CNPJ;Empresa;Origem cidade;Origem estado;Destino cidade;Destino estado;Atendida;Visualizações Carga;Interessados;Motorista;Motorista Telefone;Motorista Interessado;Motorista Visualizações';
    downloadCSV(
      header,
      csvData,
      `relatorio-aderencia-${moment().format('DD-MM-YYYY-HH-mm')}.csv`,
      'text/csv;encoding:utf-8'
    );

    setSnackBar({
      display: true,
      type: 'success',
      message: 'Relatório exportado, verifique seus downloads',
    });
  }

  async function nextPage(page = 1) {
    const bodyCSV = {
      ...bodyAux,
      client_ids: bodyAux.client_ids ? bodyAux.client_ids.join(',') : null,
      tag_ids: bodyAux.tag_ids ? bodyAux.tag_ids.join(',') : null,
      type_filter: Number(bodyAux.type_filter),
      company_id: Number(bodyAux.company_id),
    };
    const response = await api.post('adherence/forCSV', {
      ...bodyCSV,
      page,
    });
    if (response.status === 200) {
      setDataCSV(oldData => [...oldData, ...response.data.data]);
      setCompleted((response.data.page / response.data.lastPage) * 100);
    }
  }

  useEffect(() => {
    async function process(data) {
      await processDataCSV(data);
    }

    if (dataCSV.length > 0 && !loading && completed === 100) {
      process(dataCSV);
    }
  }, [loading, dataCSV, completed]);

  useEffect(() => {
    if (loadedCompany && loadedClients && loadedTags) {
      query.set('company_id', bodyAux.company_id);
      query.set('type_filter', bodyAux.type_filter);
      query.set('start_date', bodyAux.start_date);
      query.set('end_date', bodyAux.end_date);
      query.set('client_ids', bodyAux.client_ids);
      query.set('tag_ids', bodyAux.tag_ids);
      setupQueryParams();
    }
  }, [bodyAux]);

  function setupQueryParams() {
    history.push({
      pathname: '/relatorios/aderencia',
      search: query.toString(),
    });
  }

  return (
    <>
      <Snackbar
        open={snackBar.display}
        type={snackBar.type}
        message={snackBar.message}
        duration={snackBar.duration}
        onClose={() => setSnackBar(false)}
      />
      <Dialog
        open={loadingExportAdherence}
        maxWidth="sm"
        fullWidth
        minWidth="sm"
        aria-labelledby="Relatório de adêrencia"
      >
        <DialogTitle>Relatório</DialogTitle>
        <DialogContent>
          Gerando relatório
          <LinearProgress
            variant="determinate"
            value={completed}
            color="primary"
          />
          <br />
        </DialogContent>
      </Dialog>

      <Card
        style={{ marginTop: '10px', marginBottom: '10px', overflow: 'visible' }}
      >
        <Grid container spacing={2} justify="center" alignItems="center">
          {hasPermissionToViewAllCompanies && (
            <Grid item lg={10} md={10} xs={12}>
              <Label className="textLabel">
                {companies.length && companyChecked.length === 0
                  ? 'Selecione uma Empresa'
                  : 'Empresa Selecionada'}
              </Label>
              <Select
                options={companies}
                placeholder="Selecione"
                getOptionLabel={option => `${option.name}`}
                getOptionValue={option => option.id}
                value={selectedCompany}
                onChange={e => {
                  handleSelectCompany([e]);
                  setErrors({ ...errors, companies: '' });
                }}
                loading={loading}
              />
              {errors.companies && (
                <p className="help is-danger">{errors.companies}</p>
              )}
            </Grid>
          )}

          <Grid
            container
            lg={10}
            md={10}
            xs={10}
            direction="row"
            justify="space-around"
            alignItems="center"
          >
            <Grid item lg={3} md={3} xs={3}>
              <DatePicker
                label="Data Inicial"
                name="start"
                format="DD/MM/YYYY"
                style={{ width: '100%', padding: 0 }}
                value={start_date}
                onChange={handleSelectedStart}
              />
            </Grid>
            <Grid item lg={3} md={3} xs={3}>
              <DatePicker
                label="Data Final"
                name="end"
                format="DD/MM/YYYY"
                style={{ width: '100%', padding: 0 }}
                value={end_date}
                onChange={handleSelectedEnd}
              />
            </Grid>
          </Grid>

          <Grid item lg={10} md={10} xs={12}>
            <Label className="textLabel">Filtrar por</Label>
            <Filter
              currentValue={bodyAux.type_filter}
              handleFilterOptionSelected={handleFilterOptionSelected}
            />
          </Grid>

          <Grid item lg={10} md={10} xs={12} hidden={isClientSelectorHidden}>
            <Label className="textLabel">
              {clients.length && clientChecked.length === 0
                ? 'Selecione um Cliente'
                : 'Clientes Selecionados'}
            </Label>
            <Select
              options={clients}
              isMulti
              placeholder="Selecione"
              getOptionLabel={option =>
                `${option.social_name} - ${option.cgccpf}`
              }
              getOptionValue={option => option.id}
              value={clientValues}
              onChange={handleSelectClient}
            />
          </Grid>

          <Grid item lg={10} md={10} xs={12} hidden={isTagSelectorHidden}>
            <Label className="textLabel">
              {!selectedTag ? 'Selecione uma tag' : 'Tag selecionada'}
            </Label>
            <Async
              noOptionsMessage={() => (
                <label style={{ padding: 0, margin: 0 }}>Digite uma tag</label>
              )}
              isMulti
              placeholder="Selecione"
              loadOptions={promiseFilterTag}
              loadingMessage={() => (
                <label style={{ padding: 0, margin: 0 }}>Carregando...</label>
              )}
              name="tag"
              value={selectedTag}
              onChange={handleSelectTag}
            />
          </Grid>

          <Grid
            container
            item
            xs={12}
            justify="center"
            alignItems="center"
            style={{ marginTop: '15px' }}
          >
            <Grid item lg={2} xs={4} md={4}>
              <Button
                onClick={() => {
                  getReport();
                }}
                loading={loadingAdherence}
                style={{ marginRight: '5px' }}
                disabled={!loadedCompany || !loadedClients || !loadedTags}
              >
                Listar adêrencia
              </Button>
            </Grid>
            <Grid item lg={2} xs={4} md={4}>
              {total > 0
                ? [
                    validatePermission('EXPORTAR_RELATORIO_ADERENCIA') && (
                      <Button
                        color="success"
                        onClick={() => exportReport()}
                        disabled={
                          loading ||
                          !loadedCompany ||
                          !loadedClients ||
                          !loadedTags
                        }
                      >
                        Exportar em CSV
                      </Button>
                    ),
                  ]
                : ''}
            </Grid>
            <Grid item lg={6} xs={4} md={4} />
          </Grid>
        </Grid>
      </Card>
      {loadingSummary ? (
        <>
          <Grid container xs={12} spacing={1}>
            <Grid item xs={4}>
              <Skeleton height={145} />
            </Grid>
            <Grid item xs={4}>
              <Skeleton height={145} />
            </Grid>
            <Grid item xs={4}>
              <Skeleton height={145} />
            </Grid>
          </Grid>
        </>
      ) : (
        summary && (
          <Grid
            container
            xs={12}
            style={{ marginTop: '20px', marginBottom: '30px' }}
            spacing={1}
          >
            <Grid item xs={4}>
              <Card bordered={1} borderhexcolor="#1C4B75">
                <Typography variant="caption" content="p" gutterBottom>
                  TOTAL DE CARGAS
                </Typography>
                <Typography variant="body2" gutterBottom>
                  <strong style={{ fontSize: '16pt' }}>
                    {summary.totalLoads}
                  </strong>
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card bordered={1} borderhexcolor="#00bc97">
                <Typography variant="caption" content="p" gutterBottom>
                  TOTAL DE VISUALIZAÇÕES
                </Typography>
                <Typography variant="body2" gutterBottom>
                  <strong style={{ fontSize: '16pt' }}>
                    {summary.totalVisualizations}
                  </strong>
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card bordered={1} borderhexcolor="#00bc97">
                <Typography variant="caption" content="p" gutterBottom>
                  TOTAL DE INTERESSES
                </Typography>
                <Typography variant="body2" gutterBottom>
                  <strong style={{ fontSize: '16pt' }}>
                    {summary.totalInterested}
                  </strong>
                </Typography>
              </Card>
            </Grid>
          </Grid>
        )
      )}
      <Grid>
        <div aria-label="Relatório de aderência">
          <InfiniteScroll
            endpoint="adherence/list"
            filter=""
            body={body}
            initialFetch={false}
            onDataFetched={onDataFetched}
            onDataFailure={onDataFailure}
            placeholderHeight={140}
            renderItem={(item, index) => (
              <CellAdherence key={index} adherence={{ ...item, key: index }} />
            )}
            endMessage={
              body !== null ? (
                total > 0 && (
                  <Grid
                    container
                    item
                    xs={12}
                    justify="center"
                    style={{ marginTop: '20px' }}
                  >
                    <InfoMessage
                      icon="arrow-right"
                      width="auto"
                      snackColor="#8c8c8c"
                      message="Sem mais itens para exibição."
                    />
                  </Grid>
                )
              ) : (
                <Grid
                  container
                  item
                  xs={12}
                  justify="center"
                  style={{ marginTop: '20px' }}
                >
                  <InfoMessage
                    icon="arrow-right"
                    width="auto"
                    snackColor="#8c8c8c"
                    message="Preencha os filtros para visualizar o relatório"
                  />
                </Grid>
              )
            }
          />
          {total === 0 && body !== null && (
            <Grid
              container
              item
              xs={12}
              justify="center"
              style={{ marginTop: '20px' }}
            >
              <InfoMessage
                icon="arrow-right"
                width="auto"
                snackColor="#8c8c8c"
                message="A busca não retornou resultados, revise os filtros"
              />
            </Grid>
          )}
        </div>
      </Grid>
    </>
  );
}

export default ReportAdherence;
