import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ApplicationLayout from 'v3/layouts/Application';
import { Button, Text, InfiniteScroll } from 'v3/components';
import { useSnackbar } from 'v3/components/Snackbar';
import api from 'services/api';
import { useQuery, usePermission } from 'hooks';
import Filter from './Filter';
import Card from './Card';

export default function Company() {
  const queryParams = useQuery();
  const snackbar = useSnackbar();
  usePermission('VISUALIZAR_EMPRESAS', { redirect: true });
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [pagination, setPagination] = useState({
    lastPage: 1,
    page: 1,
    total: 0,
  });
  const hasPermissionToCreateCompany = usePermission('CADASTRAR_EMPRESAS');

  async function fetchCompanies(page = 1) {
    if (page === 1) setLoading(true);

    try {
      const response = await api.get(`v3/company?${queryParams}&page=${page}`);

      const { data, ...rest } = response.data;

      setPagination(rest);
      setCompanies(oldCompanies => {
        if (page === 1) {
          return data;
        }
        return [...oldCompanies, ...data];
      });
    } catch (error) {
      snackbar.show('Erro ao buscar empresa', { type: 'error' });
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchCompanies();
  }, []);

  function renderItem(company) {
    return <Card company={company} />;
  }

  useEffect(() => {
    function onPressedKey(e) {
      if (e.key === 'Enter') {
        fetchCompanies();
      }
    }

    window.addEventListener('keyup', onPressedKey);

    return () => {
      window.removeEventListener('keyup', onPressedKey);
    };
  });

  return (
    <ApplicationLayout
      title="Empresas"
      RightComponent={
        <Link to="/empresas/cadastrar">
          {hasPermissionToCreateCompany && (
            <Button variant="primary">
              <Text weight={500} color="white" type="regular">
                Nova empresa
              </Text>
            </Button>
          )}
        </Link>
      }
    >
      <Filter onChange={fetchCompanies} />
      <InfiniteScroll
        data={companies}
        onEndReach={() => {
          fetchCompanies(pagination.page + 1);
        }}
        hasMore={pagination.page < pagination.lastPage}
        scrollThreshold={0.7}
        renderItem={renderItem}
        loading={loading}
      />
    </ApplicationLayout>
  );
}
