// import * as layoutDacte from './layout-dacte.html';
/*
 modelo
    serie
    numero
    data e hora de emissao

    chave de acesso

    Cód fiscal de operações
    Consulta de autenticidade

    Protocolo de autorização de uso

    Início da prestação
    Término da prestação

    ** Remetente
    Nome remetente
    ENdereço
    Município
    CNPJ/CPF
    UF
    IE
    Fone (não tinha)

    ** Destinatário
    Nome destinatário
    Endereço destinatário
    Município
    CPF/CNPJ
    UF
    IE

    ** Expeditor
    Nome Expeditor
    Endereço expeditor
    Município
    CPF/CNPJ
    UF
    IE

    ** Recebedor
    Nome recebedor
    Endereço recebedor
    Município
    CPF/CNPJ
    UF
    CEP
    IE
    País

    ** Tomador do serviço
    Nome tomador
    Endereço
    CPF/CNPJ
    IE
    UF
    País
    CEP

    Produto predominante
    Valor da carga

    ** QTD carga
    Peso base calculo
    PEso bruto
    Qtde VOlumes

    Frete Valor
    Pedagio
    Serv. Adicional
    Valor total da prestação do serviço
    Valor a receber

    Situação tributária (Isenção)

    NFe

    Obs gerais
    RNTRC da empresa
*/

const layout = obj => {
  return `
  <html>
  <head>
    <style>
      body {
        width: 750px;
      }

      span{
        font-size:11px;
      }

      .titulo {
        width: 100%;
        border: 1px solid #000;
        border-radius: 10px;
        float: left;
        height: 72px;
      }
      .titulo .logo {
        width: 30%;
        float: left;
        font-size: 10px;
      }
      .titulo .titulo-logo {
        width: 70%;
        float: left;
        font-size: 8px;
        margin-top:10px;
        text-align: center;
      }

      .assinatura {
        position: relative;
      }

      /*.titulo .logo {
        width: 35%;
        float: left;
      }
      .titulo .titulo-logo {
        width: 65%;
        float: left;
      }*/

      .peso-base-calculo div{
        height: 22px;
        border-bottom: 1px solid #000;
      }

      .peso-base-calculo div h5{
        font-size:10px;
        text-align:center;
        padding: 0;
      }

      .peso-base-calculo div:last-child{
        border:0 !important;
      }

      .peso-base-calculo div span{
        font-size:8px;
      }

      .header {
        width: 100%;
        float: left;
        border: 1px solid #000;
        border-radius: 10px;
      }

      .campos {
        border: 1px solid #000;
        border-radius: 10px;
        margin-top: 2px;
      }

      .campos div {
        border-bottom: 1px solid #000;
        height: 30px;
      }
      .campos div:last-child {
        border: 0;
      }

      .dacte-topo {
        border: 1px solid #000;
        height: 40px;
      }

      .dacte-topo div {
        width: 25%;
        font-size: 12px;
        text-align: center;
        float: left;
        border: 0 !important;
        height: 25px;
      }

      h4,
      h3,
      h5,
      h6 {
        text-transform: uppercase;
        font-size: 12px;
        font-family: 'Tahoma';
        font-weight: normal;
        padding: 2px;
        margin: 0;
      }

      h6 {
        font-size: 8px;
      }

      h3 {
        font-size: 8px;
      }

      h4 {
        text-align: center;
      }

      .header .termo {
        padding: 5px;
        text-align: center;
        font-size: 9px;
        font-family: 'Tahoma';
        border-bottom: 1px solid #000;
      }

      .parte1 {
        width: 100%;
      }

      .inicio-termino {
        text-align: center;
      }

      .inicio-termino div div {
        display: inline;
        border-bottom: 1px solid #000;
      }

      .parte1 > div {
        width: 25%;
        float: left;
        height: 60px;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        border-right: 1px solid #000;
      }
      .parte1 > div:last-child {
        border-right: 0;
      }

      .parte1 div div {
        height: 30px;
        border-bottom: 1px solid #000;
      }

      .parte1 div div:last-child {
        border-bottom: 0;
      }

      .parte2 > .tipo-cte {
        width: 33%;
        float: left;
      }

      .parte2 > .tipo-cte span{
        display: block;
        text-align:center;
      }

      .inicio-prestacao {
      }

      .campos {
        float: left;
        width: 100%;
        height: 160px;
      }

      .barcode {
        width: 100%;
        float: left;
        height: 60px;
      }

      .campos div {
        height: 40px;
      }

      .campos-insc .campos-inner {
        float: left;
        width: 50%;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        height: 30px;
      }

      .campos-insc .campos-inner:first-child {
        border-right: 1px solid #000;
        border-bottom: 0;
      }

      .campos-insc .campos-inner h3 {
        font-size: 8px;
      }

      .parte6 {
        border: 1px solid #000;
        border-radius: 10px;
        float: left;
        width: 100%;
        height: 44px;
      }
      .parte6 .col {
        width: 20%;
        float: left;
        height: 44px;
        border-right: 1px solid #000;
      }

      .tipo-cte .campos {
        /* border: 1px solid #000;*/
        border-radius: 10px;
      }

      .parte2 > .barcode-parte {
        width: 50%;
        margin-left: 3px;
        float: left;
      }

      .barcode {
        height: 50px;
        border: 1px solid #000;
        border-radius: 10px;
      }

      .chave-acesso {
        width: 100%;
        border: 1px solid #000;
        border-radius: 10px;
        float: left;
      }

      .chave-acesso span, .chave-acesso h6{
        font-weight: bolder;
        display: inherit;
        width: 100%;
        text-align: center;
      }

      .consulta-chave {
        width: 100%;
        border: 1px solid #000;
        border-radius: 10px;
        float: left;
        font-size: 12px;
        height: 40px;
      }

      .protocolo {
        width: 100%;
        border: 1px solid #000;
        border-radius: 10px;
        float: left;
        height: 30px;
      }

      .barcode-parte div {
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        /* border: 1px solid #000;*/
        border-radius: 10px;
      }
      .dacte-campos {
        width: 100%;
        border: 1px solid #000;
        border-radius: 10px;
        float: left;
      }
      .dacte-campos div {
        width: 17%;
        float: left;
        border-radius: 0;
        border-right: 1px solid #000;
        height: 40px;
      }
      .dacte-campos div span{
        width:100%;
        text-align:center;
        font-weight:bolder;
        display:block;
      }

      .dacte-campos div:last-child {
        border: 0;
      }

      .dacte-campos div h3 {
        font-size: 8px;
      }

      .parte2 > .qr-code-parte {
        width: 14%;
        float: left;
        margin-top:40px;
      }

      .qr-code-parte svg{
        width: 90%;
        margin-left: 16%;
        position: relative;
      }

      .parte2 {
        width: 100%;
        float: left;
        margin-top: 2px;
      }

      .parte3 .expedidor,
      .parte3 .recebedor {
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        border: 1px solid #000;
        float: left;
        height: 30px;
        border-radius: 10px;
      }

      .parte3,
      .parte4 {
        border-radius: 10px;
        width: 100%;
        float: left;
        margin-top: 2px;
      }

      .parte4 > div {
        width: 50%;
        float: left;
        border: 1px solid #000;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        border-radius: 10px;
      }

      .parte4 .remetente .remetente-inner {
        width: 70%;
        float: left;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
      }

      .remetente-inner {
        width: 75%;
        float: left;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
      }

      .parte4 .remetente .remetente-inner:last-child {
        width: 25%;
        float: left;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
      }

      .parte5 {
        width: 100%;
        float: left;
        border: 1px solid #000;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        border-radius: 10px;
      }

      .parte5 .produto-predominante,
      .parte5 .outras-caracteristicas,
      .parte5 .valor-total {
        width: 35%;
        float: left;
      }
      .parte5 .valor-total {
        width: 30%;
      }

      .qtd-carga{
        width:5% !important;
        height:44px;
        text-align:center;
        border-right: 1px solid #000;
      }

      .parte3 .expedidor {
        width: 50%;
        float: left;
      }

      .parte3 .recebedor {
        width: 50%;
        float: left;
      }

      .parte7 {
        border: 1px solid #000;
        border-radius: 10px;
        width: 100%;
        float: left;
        margin-top: 2px;
        height:80px;
      }

      .parte7 .componentes-valor {
        border-bottom: 1px solid #000;
      }

      .componentes-prestacao-servico div {
        width: 18%;
        float: left;
      }

      .componentes-prestacao-servico div:last-child {
        width: 28%;
      }

      .componentes-prestacao-servico .valor-total h5 {
        padding: 0;
        margin: 0;
        font-size: 8px;
        padding-top: 2px;
      }

      .componentes-prestacao-servico .valor-total div {
        border-bottom: 1px solid #000;
        width: 100%;
        height: 35px;
      }

      .componentes-prestacao-servico .valor-total div:last-child {
        border-bottom: 1px;
      }

      .componentes-prestacao-servico .nome-frete {
        border-right: 1px solid #000;
        height: 60px;
      }

      .nome-frete h3{
        padding:0;
      }

      .parte8 {
        border-radius: 10px;
        border: 1px solid #000;
        float: left;
        width: 100%;
        margin-top: 2px;
        height: 44px;
      }

      .informacoes-imposto h4 {
        border-bottom: 1px solid #000;
      }

      .informacoes-imposto .informacoes-imposto-dado {
        width: 50%;
        float: left;
      }

      .informacoes-imposto-dado div {
        width: 25%;
        float: left;
        height: 25px;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        border-right: 1px solid #000;
      }

      .informacoes-imposto-dado div:last-child {
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        border: 0;
      }

      .informacoes-imposto-dado div:first-child {
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        border-left: 1px solid #000;
      }
      .informacoes-imposto-dado div h5 {
        font-weight: normal;
        padding: 0;
        margin: 0;
        font-size: 12px;
      }

      .parte9 {
        height: 75px;
      }

      .parte9 .doc {
        width: 50%;
        float: left;
        height: 55px;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        border-right: 1px solid #000;
      }

      .parte9 .doc:last-child {
        border: 0;
      }

      .parte9 .documentos-originarios h4 {
        border-bottom: 1px solid #000;
      }

      .parte10 .previsao-fluxo-da-carga {
        height: 50px;
      }

      .parte10 .previsao-fluxo-da-carga h4 {
        border-bottom: 1px solid #000;
      }

      .parte9,
      .parte10,
      .parte11,
      .parte12,
      .parte13 {
        float: left;
        border: 1px solid #000;
        border-radius: 10px;
        width: 100%;
        margin-top: 2px;
      }
      .parte12 .informacoes-especificadas h4 {
        border-bottom: 1px solid #000;
      }

      .parte12 .informacoes-especificadas {
        height: 46px;
      }

      .parte13 {
        height: 65px;
      }

      .parte13 .uso-exclusivo-emissor {
        width: 70%;
        float: left;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        border-right: 1px solid #000;
        height: 65px;
      }

      .parte13 .reservado-fisco {
        width: 30%;
        float: left;
        height: 90px;
      }

      .parte13 .reservado-fisco h4 {
        border-bottom: 1px solid #000;
      }
      .uso-exclusivo-emissor h4 {
        border-bottom: 1px solid #000;
      }

      .componentes-prestacao-servico .nome-frete {
        padding: 2px;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
      }

      .componentes-prestacao-servico .nome-frete h5:first-child {
        width: 50%;
        text-align: left;
        float: left;
        padding: 0;
        margin: 0;
        text-transform: uppercase;
      }

      .componentes-prestacao-servico .nome-frete h5 {
        width: 50%;
        text-align: right;
        font-size: 10px;
        float: left;
        padding: 0;
        margin: 0;
        text-transform: uppercase;
      }

      .observacoes-gerais {
        height: 60px;
      }

      .observacoes-gerais h4 {
        border-bottom: 1px solid #000;
      }
    </style>
  </head>
  <body>
    <div class="header">
      <div class="termo">
        DECLARO QUE RECEBI OS VOLUMES DESTE CONHECIMENTO EM PERFEITO ESTADO PELO
        QUE DOU POR CUMPRIDO O PRESENTE CONTRATO DE TRANSPORTE
      </div>
      <div class="parte1">
        <div>
          <div>
            <h3>Nome</h3>
          </div>
          <div>
            <h3>RG</h3>
          </div>
        </div>

        <div class="assinatura">
          <h3
            style="resize: both; text-align: center; position: absolute; width: 100%; bottom: 0;"
          >
            ASSINATURA / CARIMBO
          </h3>
        </div>

        <div class="inicio-termino">
          <div>
            <h3>TÉRMINO DA PRESTAÇÃO - DATA/HORA</h3>
            <div>
              <div
                style="width: 48%;
                float: left;
                border-bottom: 1px solid #000;
                margin-left: 1%;
                margin-top: -18px;
                box-sizing: border-box;
                -moz-box-sizing: border-box;
                -webkit-box-sizing: border-box;"
              ></div>
              <div
                style="width: 48%;
                float: left;
                border-bottom: 1px solid #000;
                margin-left: 1%;
                margin-top: -18px;
                box-sizing: border-box;
                -moz-box-sizing: border-box;
                -webkit-box-sizing: border-box;"
              ></div>
            </div>
          </div>
          <div>
            <h3>INÍCIO DA PRESTAÇÃO - DATA/HORA</h3>
            <div>
              <div
                style="width: 48%;
              float: left;
              border-bottom: 1px solid #000;
              margin-left: 1%;
              margin-top: -18px;
              box-sizing: border-box;
              -moz-box-sizing: border-box;
              -webkit-box-sizing: border-box;"
              ></div>
              <div
                style="width: 48%;
              float: left;
              border-bottom: 1px solid #000;
              margin-left: 1%;
              margin-top: -18px;
              box-sizing: border-box;
              -moz-box-sizing: border-box;
              -webkit-box-sizing: border-box;"
              ></div>
            </div>
          </div>
        </div>
        <div>
          <div>
            CT-e
            <h3>Nº DOCUMENTO <span>${obj.nCT}</span></h3>
          </div>
          <div>Série <span>${obj.serie}</span></div>
        </div>
      </div>
    </div>
    <br />
    <div
      style="border-bottom: 1px dashed black; float: left; height: 10px; width: 100%; margin-bottom: 5px;"
    ></div>

    <div class="parte2">
      <div class="tipo-cte">
        <div class="titulo">
          <div class="logo">
            <img alt="Coopercarga" width="60" style="margin-top: 34px; margin-left: 6px;" src="https://www.coopercarga.com.br/app/midia/img/logo.png" />
          </div>
          <div class="titulo-logo">
            ${obj.emiXnome}<br />
            ${obj.emitLogr}, ${obj.emitNo} - sala 01 - ${obj.emitXbairro}<br />
            ${obj.emitCep} - ${obj.emitXMun} - ${obj.emitUf} 1 / 1 ${obj.emitFone}
            ${obj.emitCnpj}
          </div>
        </div>
        <div class="campos">
          <div>
            <h3>TIPO DO CT-E</h3>
            <span>${obj.tpCTe}</span>
          </div>
          <div>
            <h3>Tipo do serviço</h3>
            <span>${obj.tipoServico}</span>
          </div>
          <div class="campos-insc">
            <div class="campos-inner">
              <h3>Indicador do CT-E Globalizado</h3>
              <div style="border:0;">
                <div style="width:10px; height:10px; border:1px solid #000; float:left;"></div>
<div style="float:left; font-size: 10px; padding-left: 5px; border:0;">Sim</div>
                <div style="width:10px; height:10px; border:1px solid #000; float:left; font-size:12px; text-align:center;">x</div> <div style="float:left; font-size: 10px; padding-left: 5px; border:0;">Não</div>
              </div>
              <span>${obj.indicadorCTEGlobalizado}</span>
            </div>
            <div class="campos-inner">
              <h3>Inf. do CT-e Globalizado</h3>
              <span>${obj.infCTEGlobalizado}</span>
            </div>
          </div>

          <div>
            <h3>CÓD. FISCAL DE OPERAÇÕES E PRESTAÇÕES - NAT. DA OPERAÇÃO</h3>
            <span>${obj.cfop} ${obj.natOp}</span>
          </div>
        </div>
      </div>
      <div class="barcode-parte">
        <div class="dacte-topo">
          <div style="width:50%; font-size: 10px;">
            <h3>DACTE</h3>
            Documento Auxiliar do Conhecimento de Transporte Eletrônico
          </div>
          <div>
            <h3>Modal</h3>
            <span>${obj.modal}</span>
          </div>
          <div>FL</div>
        </div>
        <div class="dacte-campos">
          <div>
            <h3>Modelo</h3>
            <span>${obj.modelo}</span>
          </div>
          <div style="width:11%;">
            <h3>Série</h3>
            <span>${obj.serie}</span>
          </div>
          <div style="width:11%;">
            <h3>Número</h3>
            <span>${obj.nCT}</span>
          </div>
          <div style="width:28%;">
            <h3>Data e hora de emissão</h3>
            <span>${obj.dhEmi}</span>
          </div>
          <div style="width:32%;">
            <h3>
              Insc. SUframa Destinatário
            </h3>
            <span>${obj.inscSuframaDestinatario}</span>
          </div>
        </div>
        <div class="barcode">
        ${obj.barcode}
        </div>
        <div class="chave-acesso">
          <h3>Chave de acesso</h3>
          <span>${obj.chCTe}</span>
          <h6 style="font-size:6px;">
            Consulta em http// www.cte.fazenda.gov.br/portal
          </h6>
        </div>
        <div class="consulta-chave">
          Consulta de autenticidade no portal nacional do CT-e, no site da Sefaz
          Autorizadora, ou em http://www.cte.fazenda.gov.br/portal
        </div>
        <div class="protocolo">
          <h6>Protocolo de Autorização de Uso</h6>
          <span>${obj.nProt}</span>
        </div>
      </div>
      <div class="qr-code-parte">${obj.qrCTe}</div>
    </div>

    <div class="parte3">
      <div class="expedidor">
        <div class="inicio-prestacao">
          <h3>Início da Prestação</h3>
          <span>${obj.cMunIni} ${obj.xMunIni} ${obj.UFIni}</span>
        </div>
        <div class="remetente"></div>
      </div>
      <div class="recebedor">
        <div class="termino-prestacao">
          <h3>Término da Prestação</h3>
          <span>${obj.cMunFim} ${obj.xMunFim} ${obj.UFFim}</span>
        </div>
      </div>
    </div>

    <div class="parte4">
      <div class="remetente">
        <h3>Remetente <span>${obj.xNomeRem}</span></h3>
        <h3>Endereço <span>${obj.xLgrRem} ${obj.nroRem}</span></h3>

        <div class="remetente-inner">
          <h3>Município <span>${obj.cMunRem}</span></h3>
          <h3>CNPJ/CPF <span>${obj.CNPJRem}</span></h3>
          <h3>IE <span>${obj.IERem}</span></h3>
        </div>
        <div class="remetente-inner">
          <h3>CEP <span>${obj.CEPRem}</span></h3>
          <h3>UF <span>${obj.UFRem}</span></h3>
          <h3>FONE <span>${obj.foneRem}</span></h3>
        </div>
      </div>
      <div class="remetente">
        <h3>Destinatário <span>${obj.xNomeDest}</span></h3>
        <h3>Endereço <span>${obj.xLgrDest} ${obj.nroDest}</span></h3>

        <div class="remetente-inner">
          <h3>Município <span>${obj.xMunDest}</span></h3>
          <h3>CNPJ/CPF <span>${obj.CNPJDest}</span></h3>
          <h3>IE <span>${obj.IEDest}</span></h3>
        </div>
        <div class="remetente-inner">
          <h3>CEP <span>${obj.CEPDest}</span></h3>
          <h3>UF <span${obj.UFDest}></span></h3>
          <h3>FONE <span>${obj.foneDest}</span></h3>
        </div>
      </div>
    </div>

    <div class="parte4 expedidor">
      <div class="remetente">
        <h3>Expedidor <span>${obj.xNomeExped}</span></h3>
        <h3>Endereço <span>${obj.xLgrExped} ${obj.nroExped}</span></h3>

        <div class="remetente-inner">
          <h3>Município <span>${obj.xMunExped}</span></h3>
          <h3>CNPJ/CPF <span>${obj.CNPJExped}</span></h3>
          <h3>IE <span>${obj.IeExped}</span></h3>
        </div>
        <div class="remetente-inner">
          <h3>CEP <span>${obj.CEPExped}</span></h3>
          <h3>UF <span>${obj.UFExped}</span></h3>
          <h3>FONE <span>${obj.foneExped}</span></h3>
        </div>
      </div>
      <div class="remetente">
        <h3>Recebedor <span>${obj.xNomeReceb}</span></h3>
        <h3>Endereço <span>${obj.xLgrReceb} ${obj.nroReceb}</span></h3>

        <div class="remetente-inner">
          <h3>Município <span>${obj.xMunReceb}</span></h3>
          <h3>CNPJ/CPF <span>${obj.CNPJReceb}</span></h3>
          <h3>IE <span>${obj.IEReceb}</span></h3>
        </div>
        <div class="remetente-inner">
          <h3>CEP <span>${obj.CEPReceb}</span></h3>
          <h3>UF <span>${obj.UFReceb}</span></h3>
          <h3>FONE <span>${obj.foneReceb}</span></h3>
        </div>
      </div>
    </div>

    <div class="parte5">
      <div class="produto-predominante">
        <h3>Produto predominante</h3>
        <span>${obj.proPred}</span>
      </div>
      <div class="outras-caracteristicas">
        <h3>Outras características</h3>
        <span>${obj.outrasCaracteristicas}</span>
      </div>
      <div class="valor-total">
        <h3>Valor total da carga</h3>
        <span>${obj.vCarga}</span>
      </div>
    </div>

    <div class="parte6">
      <div class="col qtd-carga">
        <h3>
          QTD <br />
          CARGA
        </h3>
      </div>
      <div class="col peso-base-calculo">
        <div>
          <h5>PESO BASE CALCULO</h5>
          <span>${obj.qCargaBaseCalculo}</span>
        </div>
        <div>
          <h5>QTDE VOLUMES</h5>
          <span>${obj.qCargaQtdVolumes}</span>
        </div>
      </div>
      <div class="col peso-bruto">
        <div>
          <h5>PESO BRUTO</h5>
          <span>${obj.qCargaPesoBruto}</span>
        </div>
      </div>
      <div class="col"></div>
      <div class="col"></div>
    </div>

    <div class="parte7">
      <div class="componentes-valor">
        <h4>COMPONENTES DO VALOR DA PRESTAÇÃO DE SERVIÇO</h4>
      </div>
      <div class="componentes-prestacao-servico">
        <div class="nome-frete">
          <h5>Nome <span></span></h5>
          <h5>Valor <span></span></h5>
          <h3>${obj.xNomePrest0} <span>${obj.vComp0}</span></h3>
          <h3>${obj.xNomePrest1} <span>${obj.vComp1}</span></h3>
          <h3>${obj.xNomePrest2} <span>${obj.vComp2}</span></h3>
        </div>
        <div class="nome-frete">
          <h5>Nome</h5>
          <h5>Valor</h5>
          <h3>${obj.xNomePrest3} <span>${obj.vComp3}</span></h3>
        </div>
        <div class="nome-frete">
          <h5>Nome</h5>
          <h5>Valor</h5>
        </div>
        <div class="nome-frete">
          <h5>Nome</h5>
          <h5>Valor</h5>
        </div>
        <div class="valor-total">
          <div>
            <h5>Valor total da prestação do serviço</h5>
            <span>${obj.vTPrest}</span>
          </div>
          <div>
            <h5>Valor a receber</h5>
            <span>${obj.vRec}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="parte8">
      <div class="informacoes-imposto">
        <h4>INFORMAÇÕES RELATIVAS AO IMPOSTO</h4>
        <div>
          <div class="informacoes-imposto-dado">
            <h3>Situação Tributária</h3>
            <span></span>
          </div>
          <div class="informacoes-imposto-dado">
            <div>
              <h3>Base de Cálculo</h3>
              <span></span>
            </div>
            <div>
              <h3>ALIQ.ICMS</h3>
              <span>${obj.aliqIcms}</span>
            </div>
            <div>
              <h3>VLR ICMs / VLR ICMs ST</h3>
              <span>${obj.vlrIcms}</span>
            </div>
            <div>
              <h3>VLR ICMs / VLR ICMs ST</h3>
              <span></span>
            </div>
          </div>
        </div>
      </div>
      <div class="informacoes-tributo">
        <div class="situacao-tributaria"></div>
        <div class="base-calculo"></div>
        <div class="aliq-icms"></div>
        <div class="vlr-icms"></div>
        <div class="percentual-bc"></div>
      </div>
    </div>

    <div class="parte9">
      <div class="documentos-originarios">
        <h4>Documentos Originários</h4>
      </div>
      <div class="doc">
        <h3>TP DOC</h3><span>${obj.chave0}</span>
        <h3 style="text-transform: initial;">NFe</h3><span>${obj.dPrev0}</span>
      </div>
      <div class="doc">
        <h3>TP DOC</h3><span>${obj.chave1}</span>
        <h3>NFe</h3><span>${obj.dPrev1}</span>
      </div>
    </div>

    <div class="parte10">
      <div class="previsao-fluxo-da-carga">
        <h4>Previsão do Fluxo da carga</h4>
        <span>${obj.prevFluxo}</span>
      </div>
    </div>

    <div class="parte11">
      <div class="observacoes-gerais">
        <h4>Observações gerais</h4>
        <span style="font-size:9px;">${obj.xObs}</span>
      </div>
    </div>

    <div class="parte12">
      <div class="informacoes-especificadas">
        <h4>INFORMAÇÕES ESPECÍFICAS DO MODAL RODOVIÁRIO</h4>
        <div>
          <h3>RNTRC da empresa</h3>
          <span>${obj.Rntrc}</span>
        </div>
      </div>
    </div>

    <div class="parte13">
      <div class="uso-exclusivo-emissor">
        <h4>Uso exclusivo do emissor do CT-e</h4>
        <div></div>
      </div>
      <div class="reservado-fisco">
        <h4>RESERVADO AO FISCO</h4>
        <div></div>
      </div>
    </div>
  </body>
  </html>
  `;
};

export default layout;
