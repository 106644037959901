import React, { useState, useCallback } from 'react';
import dashboardApi from 'services/apis/dashboard';
import { Table, Text, Link, Button } from 'v3/components';
import api from 'services/api';
import Card, { CityMapQuantLoad, SeeAll } from './Card';
import { useQuery } from 'hooks';
import { formatDateTime } from 'v3/utils/formatter';

export default function LoadOriginsAvailableMap({ dashboard, name, id }) {
  const [dataMap, setDataMap] = useState([]);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({});
  const [filter, setFilter] = useState({});

  const [currentPage, setCurrentPage] = useState({});

  const queryParams = useQuery();

  async function handleChangeDate(value, setSelectedDate, setValues, values) {
    setSelectedDate(value);
    setValues(old => ({ ...old, date: value }));

    const payload = {
      tags: values?.tags,
      clients: values?.clients,
      pcps: values?.pcps,
      costCenter: values?.costCenter,
      sizes: values?.size,
      component: name,
      date: value,
      card_id: id,
    };

    await api.put(`v3/dashboard/${dashboard.id}/parameters`, payload);
  }

  function setQueryParams(filters) {
    const { tags = [] } = filters;
    queryParams.delete('tags');

    if (tags) {
      tags.forEach(tag => {
        queryParams.append('tags', tag.id);
      });
    }

    if (tags?.length === 0) {
      queryParams.set('no-tags', true);
    } else {
      queryParams.delete('no-tags');
    }
  }

  const fetchLoadOriginsAvailableMap = useCallback(async (filters = {}) => {
    try {
      setLoading(true);
      const {
        tags = [],
        clients = [],
        page = 1,
        costCenters = [],
        pcps = [],
      } = filters;

      setQueryParams(filters);
      setFilter(filters);
      setCurrentPage(+page);

      const response = await dashboardApi.get('load-origins-available-map', {
        params: {
          tags: tags?.map(tag => tag.id),
          clients: clients?.map(client => client.id),
          page,
          costCenters: costCenters?.map(costCenter => costCenter?.id),
          pcps: pcps?.map(pcp => pcp?.id),
        },
      });

      setPagination(response.data?.last?.pagination);
      if (page === 1) {
        setData(response.data);
      } else {
        setData(oldData => ({
          ...oldData,
          last: {
            ...oldData.last,
            data: [...oldData.last.data, ...response.data.last.data],
          },
        }));
      }

      setDataMap(response.data.citys);
    } catch (error) {
      // adding a comment because will is gonna kill me if i dont
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <>
      <Card
        title="Origens de cargas disponíveis"
        isLoading={loading}
        fetchFn={fetchLoadOriginsAvailableMap}
        onFiltersChanged={fetchLoadOriginsAvailableMap}
        dashboard={dashboard}
        name={name}
        id={id}
        excludeFilterByDate={true}
        excludeFilterByNegotiator={true}
        excludeFilterByBranchOffice={true}
        handleChangeDate={handleChangeDate}
        dateFilterOptionLabel={option => option.label}
        dateFilterOptionValue={option => option.value}
        excludeFilterByCompany
        ListComponent={
          <>
            <Table striped>
              <thead>
                <tr>
                  <th>
                    <Text weight="500" color="dark">
                      Centro de custo
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Tags da carga
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Carga
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Carregamento
                    </Text>
                  </th>

                  <th>
                    <Text weight="500" color="dark">
                      Origem
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Destino
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Cliente
                    </Text>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.last?.data?.map(load => {
                  const title = `Cliente ${load.social_name} - ${load.cgccpf}`;
                  return (
                    <tr>
                      <td>
                        <Text type="regular" color="gray">
                          {load?.cost_center || '-'}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="gray">
                          {load?.load_tags || '-'}
                        </Text>
                      </td>
                      <td>
                        <Link
                          to={`/cargas/${load.id}`}
                          title={title}
                          target="_blank"
                        >
                          {load.id}
                        </Link>
                      </td>
                      <td>
                        <Text type="regular" color="gray">
                          {formatDateTime(load.scheduled_origin)}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="gray" truncate>
                          {load.city_origin} - {load.uf_origin}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="gray" truncate>
                          {load.city_destination} - {load.uf_destination}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="gray" truncate>
                          {load.social_name}
                        </Text>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {pagination?.lastPage > 1 && pagination?.to < pagination?.total && (
              <div className="text-center mt-3 col-12">
                <Button
                  className="mt-2 ml-auto align-item-center"
                  onClick={() => {
                    fetchLoadOriginsAvailableMap({
                      page: currentPage + 1,
                      tags: filter.tags,
                      clients: filter.clients,
                      costCenters: filter?.costCenters,
                      pcps: filter?.pcps,
                      negotiators: filter?.negotiators,
                    });
                  }}
                  loading={loading}
                >
                  <Text type="regular" color="white" truncate>
                    Ver mais
                  </Text>
                </Button>
              </div>
            )}
            {!!data?.total && (
              <SeeAll
                to={{ pathname: '/cargas', search: queryParams.toString() }}
              >
                ver todas as {data?.total} cargas
              </SeeAll>
            )}
          </>
        }
        NumericComponent={
          <>
            <CityMapQuantLoad
              data={dataMap}
              height={450}
              latKey="origin_latitude"
              lonKey="origin_longitude"
            />
          </>
        }
      />
    </>
  );
}
