import React, { useState } from 'react';
import apiReport from 'services/apis/report';
import { groupBy } from 'v3/utils/array';
import moment from 'moment';
import { formatDate, formatTime } from 'utils/formatter';
import { formatAddress } from 'v3/utils/formatter';
import { FaExclamationCircle, FaCheckCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { Row, Col } from 'react-bootstrap';

import theme from 'v3/theme';
import Text from 'v3/components/Text';
import { useSnackbar } from 'v3/components/Snackbar';
import Accordion from 'v3/components/HeaderAccordion';

function Card({ travel }) {
  const [loading, setLoading] = useState(false);
  const [grouped, setGrouped] = useState({});
  const snackbar = useSnackbar();

  async function fetchAlerts() {
    try {
      setLoading(true);
      const response = await apiReport.get(`travel/alerts`, {
        params: {
          travel_id: travel.travel_id,
        },
      });

      const groupedByDate = groupBy(response.data, item =>
        formatDate(item.captured_at)
      );

      setGrouped(
        Object.fromEntries(
          Object.entries(groupedByDate).map(([x, v]) => {
            const groupedValues = groupBy(v, item => item.slug);

            return [x, groupedValues];
          })
        )
      );
    } catch (error) {
      snackbar.show('Erro ao buscar alertas da viagem', { type: 'error' });
    } finally {
      setLoading(false);
    }
  }

  function SpeedAlert({ alerts }) {
    return (
      <>
        {alerts.map(alert => (
          <>
            <Col md={12}>
              <FaExclamationCircle
                color={theme.colors.warning}
                className="mr-2"
              />
              <Text color="dark" type="regular">
                Às {formatTime(alert.captured_at)}h, o motorista ficou acima de 80km/h
              </Text>
            </Col>
          </>
        ))}
      </>
    );
  }

  function TravelTimeAlert({ alerts }) {
    return (
      <>
        {alerts.map(alert => {
          const metadata = JSON.parse(alert.metadata);
          const start = moment(metadata.startedDrivingAt);
          const end = moment(alert.captured_at);
          const duration = moment.duration(end.diff(start)).asMilliseconds();
          const format =
            duration * 1000 * 60 >= 60
              ? 'h [horas e] m [minutos]'
              : 'h [horas]';

          const travelTime = moment.utc(duration).format(format);

          return (
            <Col md={12}>
              <FaExclamationCircle
                color={theme.colors.error}
                className="mr-2"
              />
              <Text color="gray" type="regular">
                Motorista dirigiu {travelTime} sem fazer parada para descanso.
              </Text>
            </Col>
          );
        })}
      </>
    );
  }

  function LocationNotAllowed() {
    return (
      <Col md={12}>
        <FaExclamationCircle color={theme.colors.info} className="mr-2" />
        <Text color="gray" type="regular">
          Motorista com posicionamento desligado.
        </Text>
      </Col>
    );
  }

  function Header() {
    return (
      <Row>
        <Col md={6}>
          <Text type="label" color="dark">
            Origem
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-1">
            {formatAddress({
              address: travel.origin_address,
              complement: travel.origin_complement,
              neighborhood: travel.origin_neighborhood,
              city: travel.origin_city,
              province: travel.origin_province,
            }) || 'Não informado'}
          </Text>
        </Col>
        <Col md={6}>
          <Text type="label" color="dark">
            Destino
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-1">
            {formatAddress({
              address: travel.destination_address,
              complement: travel.destination_complement,
              neighborhood: travel.destination_neighborhood,
              city: travel.destination_city,
              province: travel.destination_province,
            }) || 'Não informado'}
          </Text>
        </Col>
        <Col md={4} xs={12}>
          <Text type="label" color="dark">
            Motorista
          </Text>
          <Text as="p" type="regular" color="gray" className="mt-1">
            {travel.driver}
          </Text>
        </Col>
        <Col md={4} xs={12}>
          <Text type="label" color="dark">
            Data
          </Text>
          <Text as="p" type="regular" color="gray" className="mt-1">
            {formatDate(travel.travel_created_at)}
          </Text>
        </Col>
        <Col md={4} xs={12}>
          <Text type="label" color="dark">
            Placa
          </Text>
          <Text as="p" type="regular" color="gray" className="mt-1">
            {travel.plate}
          </Text>
        </Col>
      </Row>
    );
  }

  return (
    <Col xs={12}>
      <Accordion
        title={
          <div>
            <Link to={`/viagens/${travel.travel_id}`}>
              <Text weight={700} color="primary" type="subtitle">
                Viagem {travel?.travel_number || ''}
              </Text>
            </Link>
          </div>
        }
        closedText="Visualizar alertas"
        trigger={fetchAlerts}
        buttonLoading={loading}
        header={<Header />}
      >
        {Object.values(grouped).length > 0 ? (
          <>
            {Object.values(grouped).length > 0 && (
              <Row md={12}>
                {Object.entries(grouped).map(([day, slugs]) => (
                  <>
                    <Col md={12} className="mt-3">
                      <Text color="dark" type="body">
                        {day}
                      </Text>
                    </Col>
                    {Object.entries(slugs).map(([slug, values]) => (
                      <>
                        {slug === 'SPEED_GAP_EXCEEDED' && (
                          <SpeedAlert alerts={values} />
                        )}
                        {slug === 'TRAVEL_TIME_EXCEEDED' && (
                          <TravelTimeAlert alerts={values} />
                        )}
                        {slug === 'LOCATION_NOT_ALLOWED' && (
                          <LocationNotAllowed alerts={values} />
                        )}
                      </>
                    ))}
                  </>
                ))}
              </Row>
            )}
          </>
        ) : (
          <Col className="d-flex align-items-center">
            <FaCheckCircle color={theme.colors.success} className="mr-2" />
            <Text type="regular" color="dark">
              Viagem sem alertas
            </Text>
          </Col>
        )}
      </Accordion>
    </Col>
  );
}

export default Card;
