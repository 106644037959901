/* eslint-disable import/no-unresolved */
import React, { useState, useCallback, useEffect } from 'react';

import api from 'services/api';

import { Col, Row } from 'react-bootstrap';

import Card from 'v3/components/Card';
import Text from 'v3/components/Text';
import Select from 'v3/components/Select';
import Input from 'v3/components/Input';
import Radio from 'v3/components/Radio';
import IconButton from 'v3/components/Button/Icon';
import Button from 'v3/components/Button';

const PaymentCards = ({ setPaymentsCard, errors }) => {
  const [paymentsCard, setPaymentsCards] = useState();
  const [quantity, setQuantity] = useState([]);

  useEffect(() => {
    async function fetchPaymentsCard() {
      try {
        const response = await api.get('driver/payment/cards');
        setPaymentsCards(response.data);
        return response.data;
      } catch (ex) {
        return [];
      }
    }

    fetchPaymentsCard();
  }, []);

  function handleChangeValue(index, key, value) {
    setQuantity(old =>
      old.map((item, idx) => {
        if (index === idx) {
          return { ...item, [key]: value };
        }

        if (key === 'standard_card') {
          return { ...item, standard_card: false };
        }
        return item;
      })
    );
  }

  function handleDeleteCard(idx) {
    setQuantity(old => {
      return old.filter((_, index) => index !== idx);
    });
  }

  useEffect(() => {
    setPaymentsCard(quantity);
  }, [quantity]);

  const renderItems = useCallback(() => {
    return (
      <>
        {quantity.map((item, index) => (
          <>
            <Row className="form align-items-center">
              <Col md={8}>
                <Select
                  label="Empresa do cartão"
                  value={quantity[index].corporation}
                  options={paymentsCard}
                  onChange={value => {
                    handleChangeValue(index, 'corporation', value);
                  }}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  error={errors[`[${index}].corporation`]}
                />
              </Col>
              <Col md={4} className="mt-2 text-right">
                <IconButton
                  style={{ marginTop: 3 }}
                  icon="FaTrash"
                  variant="error"
                  onClick={() => handleDeleteCard(index)}
                />
              </Col>

              <Col md={6}>
                <Input
                  label="Número do cartão"
                  value={item.card_number}
                  onChange={event => {
                    handleChangeValue(index, 'card_number', event.target.value);
                  }}
                  error={errors[`[${index}].card_number`]}
                  masked
                  mask={[
                    /[0-9]/,
                    /\d/,
                    /\d/,
                    /\d/,
                    ' ',
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    ' ',
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    ' ',
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                />
              </Col>

              <Col md={6}>
                <Input
                  label="Vencimento"
                  value={quantity[index].due_date}
                  onChange={event => {
                    handleChangeValue(index, 'due_date', event.target.value);
                  }}
                  masked
                  mask={[
                    /[0-1]/,
                    /[0-9]/,
                    '/',
                    /[2]/,
                    /[0-0]/,
                    /[0-2]/,
                    /[0-9]/,
                  ]}
                  error={errors[`[${index}].due_date`]}
                />
              </Col>

              <Col md={6} className="mt-1">
                <Radio.Group
                  label="Ativo"
                  value={quantity[index].active}
                  horizontal
                  onChange={event => {
                    handleChangeValue(index, 'active', event.target.value);
                  }}
                >
                  <Radio value>
                    <Text type="label">Sim</Text>
                  </Radio>
                  <Radio value={false}>
                    <Text color="dark" type="label">
                      Não
                    </Text>
                  </Radio>
                </Radio.Group>
              </Col>
              <Col md={6} className="mt-1">
                <Radio.Group
                  label="Padrão"
                  onChange={event => {
                    handleChangeValue(
                      index,
                      'standard_card',
                      event.target.value
                    );
                  }}
                  value={quantity[index].standard_card}
                  horizontal
                >
                  <Radio value>
                    <Text type="label">Sim</Text>
                  </Radio>
                  <Radio value={false}>
                    <Text color="dark" type="label">
                      Não
                    </Text>
                  </Radio>
                </Radio.Group>
              </Col>
            </Row>
            <hr style={{ marginTop: 30, marginBottom: 30 }} />
          </>
        ))}
        <div md={3} className="mb-4">
          <Button
            variant="success"
            onClick={() => {
              if (quantity.length >= 5) {
                return;
              }
              setQuantity(old => [
                ...old,
                {
                  corporation: '',
                  card_number: '',
                  active: true,
                  standard_card: false,
                  due_date: '',
                  index: quantity?.length + 1,
                },
              ]);
            }}
          >
            <Text weight={600} type="regular">
              Adicionar
            </Text>
          </Button>
        </div>
      </>
    );
  }, [quantity, paymentsCard, errors]);
  return (
    <Card
      header={
        <Text color="#464E5F" type="header">
          Cartões
        </Text>
      }
    >
      {renderItems()}
    </Card>
  );
};

export default PaymentCards;
