import moment from 'moment';
import { plateValidator } from 'utils/vehicle';

const Yup = require('yup');

const schema = Yup.object().shape({
  loadId: Yup.number()
    .nullable()
    .required('Informe número da carga')
    .typeError('Informe número da carga'),
  shippingCompanyId: Yup.number().required('Transportadora obrigatória'),
  clientId: Yup.number().required('Adicione um cliente'),
  costCenter: Yup.object()
    .required('Centro de custo obrigatório')
    .typeError('Centro de custo obrigatório'),
  riskManagerSearch: Yup.object()
    .shape({
      id: Yup.number().nullable(),
    })
    .nullable()
    .when('$shouldInformRiskManagerSearch', {
      is: true,
      then: Yup.object()
        .shape({
          id: Yup.number()
            .required('Gerenciadora para pesquisa/consulta obrigatória')
            .typeError('Gerenciadora para pesquisa/consulta obrigatória'),
        })
        .nullable()
        .required('Gerenciadora para pesquisa/consulta obrigatória')
        .typeError('Gerenciadora para pesquisa/consulta obrigatória'),
      otherwise: Yup.object()
        .shape({
          id: Yup.number().nullable().optional(),
        })
        .nullable(),
    }),
  riskManager: Yup.object()
    .shape({
      id: Yup.number().nullable(),
    })
    .nullable(),
  driver: Yup.object()
    .shape({
      id: Yup.number().nullable(),
    })
    .nullable()
    .when('$shouldInformDriver', {
      is: true,
      then: Yup.object()
        .shape({
          id: Yup.number()
            .required('Motorista obrigatória')
            .typeError('Motorista obrigatória'),
        })
        .nullable()
        .required('Motorista obrigatória')
        .typeError('Motorista obrigatória'),
      otherwise: Yup.object()
        .shape({
          id: Yup.number().nullable().optional(),
        })
        .nullable(),
    }),
  monitoringGroups: Yup.object()
    .shape({
      id: Yup.number().nullable(),
    })
    .nullable()
    .when('$shouldInformMonitoringGroup', {
      is: true,
      then: Yup.object()
        .shape({
          id: Yup.number()
            .required('Grupo de monitoramento obrigatória')
            .typeError('Grupo de monitoramento obrigatória'),
        })
        .nullable()
        .required('Grupo de monitoramento obrigatória')
        .typeError('Grupo de monitoramento obrigatória'),
      otherwise: Yup.object()
        .shape({
          id: Yup.number().nullable().optional(),
        })
        .nullable(),
    }),
  techTracker: Yup.object()
    .shape({
      id: Yup.number().nullable(),
    })
    .nullable()
    .when('$shouldInformTrackerTech', {
      is: true,
      then: Yup.object()
        .shape({
          id: Yup.number()
            .required('Tecnologia rastreador obrigatória')
            .typeError('Tecnologia rastreador obrigatória'),
        })
        .nullable()
        .required('Tecnologia rastreador obrigatória')
        .typeError('Tecnologia rastreador obrigatória'),
      otherwise: Yup.object()
        .shape({
          id: Yup.number().nullable().optional(),
        })
        .nullable(),
    }),
  modelTracker: Yup.object()
    .shape({
      id: Yup.number().nullable(),
    })
    .nullable(),
  trackerNumber: Yup.string()
    .nullable(),
  locator: Yup.object()
    .shape({
      id: Yup.number().nullable(),
    })
    .nullable()
    .when('$shouldInformLocator', {
      is: true,
      then: Yup.object()
        .shape({
          id: Yup.number()
            .required('Localizador obrigatório')
            .typeError('Localizador obrigatório'),
        })
        .nullable()
        .required('Localizador obrigatório')
        .typeError('Localizador obrigatório'),
      otherwise: Yup.object()
        .shape({
          id: Yup.number().nullable().optional(),
        })
        .nullable(),
    }),
  locatorNumber: Yup.string()
    .nullable(),
  immobilizer: Yup.object()
    .shape({
      id: Yup.number().nullable(),
    })
    .nullable()
    .when('$shouldInformImmobilizer', {
      is: true,
      then: Yup.object()
        .shape({
          id: Yup.number()
            .required('Imobilizador obrigatório')
            .typeError('Imobilizador obrigatório'),
        })
        .nullable()
        .required('Imobilizador obrigatório')
        .typeError('Imobilizador obrigatório'),
      otherwise: Yup.object()
        .shape({
          id: Yup.number().nullable().optional(),
        })
        .nullable(),
    }),
  immobilizerNumber: Yup.string()
    .nullable(),
  baitsAmount: Yup.number().nullable().optional(),
  baits: Yup.array()
    .optional()
    .nullable()
    .when(['$shouldInformBaits', 'baitsAmount'], {
      is: ($shouldInformBaits, baitsAmount) =>
        $shouldInformBaits && baitsAmount > 0,
      then: Yup.array()
        .of(
          Yup.object().shape({
            id: Yup.number()
              .required('Informe uma isca')
              .typeError('Informe uma isca'),
          })
        )
        .required('Informe uma isca')
        .min(Yup.ref('baitsAmount'), 'Informe todas as iscas')
        .typeError('Informe uma isca'),
    }),
  baitsNumber: Yup.array()
    .of(Yup.string().nullable())
    .nullable(),
  currency: Yup.object()
    .required('Moeda obrigatória')
    .typeError('Moeda obrigatória'),
  origin: Yup.string().required('Adicione um endereço de origem'),
  destination: Yup.string().required('Adicione um endereço de destino'),
  vehicleId: Yup.number().required('Insira uma placa'),
  balance: Yup.number().when(['fleetVehicleType'], {
    is: fleetType => fleetType === 'Próprio',
    then: Yup.number().notRequired(),
    otherwise: Yup.number()
      .moreThan(0, 'Frete negociado obrigatório')
      .required('Frete negociado obrigatório'),
  }),
  advance_money_percentage_external: Yup.number(),
  advance_money_percentage: Yup.number().when(
    'advance_money_percentage_external',
    advance_money_percentage_external => {
      if (advance_money_percentage_external) {
        return Yup.number()
          .lessThan(
            advance_money_percentage_external + 1,
            'Valor deve ser menor que porcentagem do benner'
          )
          .typeError('Valor deve ser menor que porcentagem do benner');
      }
    }
  ),
  negotiatorObs: Yup.string().max(1000, 'Máximo de 1000 caracteres'),
});

export const driverSchema = Yup.object().shape({
  name: Yup.string().required('Nome obrigatório'),
  cpf_unmasked: Yup.string()
    .length(11, 'CPF deve ter 11 dígitos')
    .required('CPF obrigatório'),
  phone: Yup.string()
    .nullable()
    .min(13, 'Telefone deve ter pelo menos 10 dígitos')
    .max(14, 'Telefone não pode ter mais de 11 dígitos')
    .required('Telefone obrigatório'),
  payment: Yup.object().required('Método de pagamento obrigatório'),
  cnh_exp_date: Yup.string()
    .test('cnhDateValidation', 'Data inválida', value =>
      value
        ? moment(value, 'DD/MM/YYYY', true).isValid() &&
          moment(value, 'DD/MM/YYYY', true).isAfter(moment())
        : true
    )
    .nullable(),
});

export const updateDriverSchema = Yup.object().shape({
  social_name: Yup.string().required('Nome obrigatório'),
  cpf_unmasked: Yup.string()
    .length(11, 'CPF deve ter 11 dígitos')
    .required('CPF obrigatório'),
  phone_cooperplace: Yup.string()
    .nullable()
    .min(13, 'Telefone deve ter pelo menos 10 dígitos')
    .max(14, 'Telefone não pode ter mais de 11 dígitos')
    .required('Telefone obrigatório'),
  payment_method: Yup.number()
    .required('Método de pagamento obrigatório')
    .nullable(),
});

export const vehicleRegistrationSchema = Yup.object().shape({
  plate: Yup.string()
    .required('Placa obrigatória')
    .nullable()
    .when('country', {
      is: value => ['ar', 'br', 'py', 'uy'].includes(value?.abbreviation),
      then: Yup.string().test('plateValidation', 'Placa inválida', value =>
        plateValidator(value)
      ),
    })
    .typeError('Placa obrigatória'),
  year_manufacture: Yup.number()
    .nullable()
    .transform(original => {
      if (!original) {
        return undefined;
      }
      return original;
    })
    .required('Informe ano de fabricação'),
  year_model: Yup.number()
    .nullable()
    .transform(original => {
      if (!original) {
        return undefined;
      }
      return original;
    })
    .required('Informe ano do modelo'),
  chassi: Yup.string()
    .nullable()
    .required('Informe o chassi')
    .max(200, 'Máximo de 200 caracteres'),
  vehicle_type_id: Yup.number()
    .required('Tipo de veículo obrigatório')
    .nullable(),
  vehicle_type_name: Yup.string()
    .nullable()
    .required('Tipo de veículo obrigatório'),
  vehicle_body_type_id: Yup.number()
    .nullable()
    .required('Tipo de carroceria obrigatória'),
  vehicle_implement_type: Yup.number()
    .nullable()
    .when('vehicle_type_name', {
      is: vehicle_type_name => vehicle_type_name === 'Cavalo Mecânico',
      then: Yup.number().nullable().required('Tipo de implemento obrigatório'),
    }),

  renavam: Yup.string().nullable().required('Informe o renavam'),
  antt_type: Yup.number().nullable().required('Tipo de RNTRC obrigatório'),
  antt: Yup.number()
    .nullable()
    .transform(original => {
      if (!original) {
        return undefined;
      }
      return original;
    })
    .required('Informe número RNTRC'),
  antt_adherence: Yup.string()
    .nullable()
    .test('not-empty', 'Informe uma data', value => {
      if (!value) {
        return false;
      }
      return true;
    })
    .test('valid-date', 'Formato inválido', value => {
      return /^\d{4}\-\d{2}\-\d{2}$/.test(value);
    })
    .test(
      'valid-past-or-present-date',
      ({ originalValue }) => {
        return originalValue !== undefined &&
          originalValue !== '' &&
          originalValue !== null
          ? 'Data não pode ser posterior à data de hoje'
          : 'Informe uma data';
      },
      value => {
        if (!value) {
          return false;
        }

        const inputDate = new Date(value);
        const currentDate = new Date();
        return inputDate <= currentDate;
      }
    ),
  tracked: Yup.boolean().required('Campo obrigatório'),
  tracker_id: Yup.number()
    .nullable()
    .when('tracked', {
      is: value => value,
      then: Yup.number().nullable().required('Rastreador obrigatório'),
    }),
  tracker_type_id: Yup.number().nullable(),
  tracker_code: Yup.string().nullable(),
  plate_city_id: Yup.number().nullable().required('Cidade obrigatória'),
  owner_id: Yup.number().required('Proprietário obrigatório'),
  beneficiary_id: Yup.number().required('Beneficiário do obrigatório'),
});

export const updateVehicleSchema = Yup.object().shape({
  plate: Yup.string()
    .required('Placa obrigatória')
    .nullable()
    .when('country', {
      is: value => ['ar', 'br', 'py', 'uy'].includes(value?.abbreviation),
      then: Yup.string().test('plateValidation', 'Placa inválida', value =>
        plateValidator(value)
      ),
    })
    .typeError('Placa obrigatória'),
  year_manufacture: Yup.number()
    .nullable()
    .transform(original => {
      if (!original) {
        return undefined;
      }
      return original;
    })
    .required('Informe ano de fabricação'),
  year_model: Yup.number()
    .nullable()
    .transform(original => {
      if (!original) {
        return undefined;
      }
      return original;
    })
    .required('Informe ano do modelo'),
  chassi: Yup.string()
    .nullable()
    .required('Informe o chassi')
    .max(200, 'Máximo de 200 caracteres'),
  vehicleType: Yup.object()
    .shape({
      id: Yup.number().required(),
      name: Yup.string().required(),
    })
    .nullable()
    .required('Tipo de veículo obrigatório'),
  vehicleBodyType: Yup.object()
    .shape({
      id: Yup.number().required(),
    })
    .nullable()
    .required('Tipo de carroceria obrigatória'),
  vehicle_implement_type: Yup.number()
    .nullable()
    .when('vehicleType', {
      is: vehicleType => vehicleType?.name === 'Cavalo Mecânico',
      then: Yup.number().nullable().required('Tipo de implemento obrigatório'),
    }),

  renavam: Yup.string().nullable().required('Informe o renavam'),
  anttType: Yup.object()
    .shape({
      id: Yup.number().required(),
    })
    .nullable()
    .required('Tipo de RNTRC obrigatório'),
  antt: Yup.number()
    .nullable()
    .transform(original => {
      if (!original) {
        return undefined;
      }
      return original;
    })
    .required('Informe número RNTRC'),
  antt_adherence: Yup.string()
    .nullable()
    .test('not-empty', 'Informe uma data', value => {
      if (!value) {
        return false;
      }
      return true;
    })
    .test('valid-date', 'Formato inválido', value => {
      return /^\d{4}\-\d{2}\-\d{2}$/.test(value);
    })
    .test(
      'valid-past-or-present-date',
      ({ originalValue }) => {
        return originalValue !== undefined &&
          originalValue !== '' &&
          originalValue !== null
          ? 'Data não pode ser posterior à data de hoje'
          : 'Informe uma data';
      },
      value => {
        if (!value) {
          return false;
        }

        const inputDate = new Date(value);
        const currentDate = new Date();
        return inputDate <= currentDate;
      }
    ),
  tracked: Yup.boolean().nullable().required('Campo obrigatório'),
  tracker: Yup.object()
    .nullable()
    .shape({
      id: Yup.number(),
    })

    .when('tracked', {
      is: value => value,
      then: Yup.object()
        .shape({
          id: Yup.number(),
        })
        .nullable()
        .required('Rastreador obrigatório'),
    }),
  tracker_type: Yup.object().nullable().shape({
    id: Yup.number(),
  }),
  tracker_code: Yup.string().nullable(),
  city: Yup.object()
    .shape({
      id: Yup.number().required('Cidade obrigatória'),
    })
    .nullable()
    .required('Cidade obrigatória'),
  ownerData: Yup.object()
    .nullable()
    .shape({
      id: Yup.number().required('Proprietário obrigatório'),
    })
    .required('Proprietário obrigatório'),
  beneficiary: Yup.object()
    .nullable()
    .shape({
      id: Yup.number().required('Beneficiário obrigatório'),
    })
    .required('Beneficiário obrigatório'),
});

export const wagonRegistrationSchema = Yup.object().shape({
  plate: Yup.string()
    .required('Placa obrigatória')
    .nullable()
    .when('country', {
      is: value => ['ar', 'br', 'py', 'uy'].includes(value?.abbreviation),
      then: Yup.string().test('plateValidation', 'Placa inválida', value =>
        plateValidator(value)
      ),
    })
    .typeError('Placa obrigatória'),
  yearManufacture: Yup.number()
    .nullable()
    .transform(original => {
      if (!original) {
        return undefined;
      }
      return original;
    })
    .required('Informe ano de fabricação'),
  yearModel: Yup.number()
    .nullable()
    .transform(original => {
      if (!original) {
        return undefined;
      }
      return original;
    })
    .required('Informe ano do modelo'),
  chassi: Yup.string()
    .nullable()
    .required('Informe o chassi')
    .max(200, 'Máximo de 200 caracteres'),
  vehicleType: Yup.object()
    .shape({
      id: Yup.number().required(),
    })
    .nullable()
    .required('Tipo de veículo obrigatório'),
  vehicleImplementType: Yup.object()
    .shape({
      id: Yup.number().required(),
      name: Yup.string().required(),
    })
    .nullable()
    .required('Tipo de implemento obrigatório'),
  vehicleBodyType: Yup.object()
    .shape({
      id: Yup.number().required(),
    })
    .when('vehicleImplementType', {
      is: vehicleImplementType => vehicleImplementType?.name === 'Dolly',
      then: Yup.object()
        .shape({
          id: Yup.number(),
        })
        .nullable()
        .optional(),
      otherwise: Yup.object()
        .shape({
          id: Yup.number().required(),
        })
        .nullable()
        .required('Tipo de carroceria obrigatória'),
    }),
  renavam: Yup.string().nullable().required('Informe o renavam'),
  anttType: Yup.object()
    .shape({
      id: Yup.number().required(),
    })
    .nullable()
    .required('Tipo de RNTRC obrigatório'),
  antt: Yup.number()
    .nullable()
    .transform(original => {
      if (!original) {
        return undefined;
      }
      return original;
    })
    .required('Informe número RNTRC'),
  anttAdherence: Yup.string()
    .nullable()
    .test('not-empty', 'Informe uma data', value => {
      if (!value) {
        return false;
      }
      return true;
    })
    .test('valid-date', 'Formato inválido', value => {
      return /^\d{4}\-\d{2}\-\d{2}$/.test(value);
    })
    .test(
      'valid-past-or-present-date',
      ({ originalValue }) => {
        return originalValue !== undefined &&
          originalValue !== '' &&
          originalValue !== null
          ? 'Data não pode ser posterior à data de hoje'
          : 'Informe uma data';
      },
      value => {
        if (!value) {
          return false;
        }

        const inputDate = new Date(value);
        const currentDate = new Date();
        return inputDate <= currentDate;
      }
    ),
  plateCityId: Yup.number()
    .required('Cidade obrigatória')
    .nullable()
    .required('Cidade obrigatória'),
  idOwner: Yup.number().required('Proprietário obrigatório'),
  beneficiaryId: Yup.number().required('Beneficiário do obrigatório'),
});

export const updateWagonSchema = Yup.object().shape({
  plate: Yup.string()
    .required('Placa obrigatória')
    .nullable()
    .when('country', {
      is: value => ['ar', 'br', 'py', 'uy'].includes(value?.abbreviation),
      then: Yup.string().test('plateValidation', 'Placa inválida', value =>
        plateValidator(value)
      ),
    })
    .typeError('Placa obrigatória'),
  year_manufacture: Yup.number()
    .nullable()
    .transform(original => {
      if (!original) {
        return undefined;
      }
      return original;
    })
    .required('Informe ano de fabricação'),
  year_model: Yup.number()
    .nullable()
    .transform(original => {
      if (!original) {
        return undefined;
      }
      return original;
    })
    .required('Informe ano do modelo'),
  chassi: Yup.string()
    .nullable()
    .required('Informe o chassi')
    .max(200, 'Máximo de 200 caracteres'),
  vehicleType: Yup.object()
    .shape({
      id: Yup.number().required(),
    })
    .nullable()
    .required('Tipo de veículo obrigatório'),
  implementType: Yup.object()
    .shape({
      id: Yup.number().required(),
      name: Yup.string().required(),
    })
    .nullable()
    .required('Tipo de implemento obrigatório'),
  bodyType: Yup.object()
    .shape({
      id: Yup.number().required(),
    })
    .when('implementType', {
      is: implementType => implementType?.name === 'Dolly',
      then: Yup.object()
        .shape({
          id: Yup.number(),
        })
        .nullable()
        .optional(),
      otherwise: Yup.object()
        .shape({
          id: Yup.number().required(),
        })
        .nullable()
        .required('Tipo de carroceria obrigatória'),
    }),
  renavam: Yup.string().nullable().required('Informe o renavam'),
  anttType: Yup.object()
    .shape({
      id: Yup.number().required(),
    })
    .nullable()
    .required('Tipo de RNTRC obrigatório'),
  antt: Yup.number()
    .nullable()
    .transform(original => {
      if (!original) {
        return undefined;
      }
      return original;
    })
    .required('Informe número RNTRC'),
  antt_adherence: Yup.string()
    .nullable()
    .test('not-empty', 'Informe uma data', value => {
      if (!value) {
        return false;
      }
      return true;
    })
    .test('valid-date', 'Formato inválido', value => {
      return /^\d{4}\-\d{2}\-\d{2}$/.test(value);
    })
    .test(
      'valid-past-or-present-date',
      ({ originalValue }) => {
        return originalValue !== undefined &&
          originalValue !== '' &&
          originalValue !== null
          ? 'Data não pode ser posterior à data de hoje'
          : 'Informe uma data';
      },
      value => {
        if (!value) {
          return false;
        }

        const inputDate = new Date(value);
        const currentDate = new Date();
        return inputDate <= currentDate;
      }
    ),
  city: Yup.object()
    .shape({
      id: Yup.number().required('Cidade obrigatória'),
    })
    .nullable()
    .required('Cidade obrigatória'),
  owner: Yup.object()
    .nullable()
    .shape({
      id: Yup.number().required('Proprietário obrigatório'),
    })
    .required('Proprietário obrigatório'),
  beneficiary: Yup.object()
    .nullable()
    .shape({
      id: Yup.number().required('Beneficiário obrigatório'),
    })
    .required('Beneficiário obrigatório'),
});

export default schema;
