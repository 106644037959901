import React from 'react';

import Input from 'v3/components/Input';
import Select from 'v3/components/Select';

export default function Edit ({editing, newYear, YEARS_OPTIONS, setNewYear, newLoadType, loadTypeOptions, setNewLoadType, newValue, setNewValue, newChargePrice, setNewChargePrice, newQtyAxys, setNewQtyAxys}) { 
  return(
        <>
        {editing && (
            <>
              <Select
                label="Ano Vigente"
                value={newYear}
                options={YEARS_OPTIONS}
                onChange={value => setNewYear(value)}
              />
              <Select
                label="Tipo de Carga"
                value={newLoadType}
                options={loadTypeOptions}
                getOptionLabel={option => option.nome}
                getOptionValue={option => option.id}
                onChange={value => setNewLoadType(value)}
              />
              <Input
                label="Valor de Deslocamento"
                value={newValue}
                onChange={e => setNewValue(Number(e.target.value))}
                type="number"
                min="0"
                
              />
              <Input
                label="Valor de Carga e Descarga"
                value={newChargePrice}
                onChange={e => setNewChargePrice(Number(e.target.value))}
                type="number"
                min="0"
              />
              <Input
                label="Número de Eixos"
                value={newQtyAxys}
                onChange={e => setNewQtyAxys(Number(e.target.value))}
                type="number"
                min="2"
                max="9"
              />
            </>
          )}
          </>
    )
}