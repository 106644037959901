import React, { useCallback, useMemo } from 'react';

import { useParams, Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { FaUnlink } from 'react-icons/fa';

import { useModal, usePagination, useMemoThrottled } from 'hooks';

import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import Fallback from 'v3/components/Fallback';
import Card from 'v3/components/Card';
import DataTable from 'v3/components/DataTable';
import { useSnackbar } from 'v3/components/Snackbar';

import api from 'services/api';

import theme from 'v3/theme';
import Modal from '../Modals/AttachedVehicles';

import { useShippingCompany } from '../context';

export default function AttachedVehicles() {
  const shippingCompany = useShippingCompany();
  const modal = useModal();

  const onManualSearch = useCallback(
    (search, item) =>
      item?.plate?.includes(search || '') ||
      item?.plate?.toLowerCase()?.includes(search?.toLowerCase() || ''),
    []
  );

  const vehicles = useMemo(() => {
    if (shippingCompany.data?.vehicles) {
      return shippingCompany.data.vehicles;
    }

    return [];
  }, [shippingCompany.data?.vehicles]);

  const pagination = usePagination({
    data: vehicles,
    manual: true,
    onManualSearch,
  });

  const fallback = useMemoThrottled(
    () => {
      const defaultCondition =
        pagination.data.length === 0 &&
        pagination.currentPage === 1 &&
        !pagination.search;

      return defaultCondition;
    },
    [pagination.data, pagination.currentPage, pagination.search],
    false
  );

  return (
    <>
      <Modal
        isOpen={modal.isOpen}
        onClose={() => {
          modal.close();
        }}
      />

      <Card
        loading={shippingCompany.isFetching}
        header={
          <Text type="header" color="dark">
            Veículos vinculados
          </Text>
        }
        HeaderRightComponent={
          !fallback && (
            <Button
              onClick={() => {
                modal.open();
              }}
              className="py-2"
            >
              <Text type="regular" weight="500" color="white">
                Adicionar
              </Text>
            </Button>
          )
        }
      >
        <Fallback
          fall={fallback}
          component={
            <Col xs={12} className="align-items-center d-flex flex-column my-2">
              <Text color={theme.colors.dark} type="regular">
                Não há veículos vinculados nessa transportadora.
              </Text>
              <Button
                className="mt-4 py-2"
                onClick={() => {
                  modal.open();
                }}
              >
                <Text type="regular" weight="500" color="white">
                  Adicionar
                </Text>
              </Button>
            </Col>
          }
        >
          <Row className="form">
            <Col md={12}>
              <DataTable
                data={pagination.data}
                total={pagination.total}
                pages={pagination.lastPage}
                onPageChanged={pagination.setCurrentPage}
                onSearchChanged={pagination.setSearch}
                onPerPageChanged={pagination.setPerPage}
                loading={shippingCompany.isFetching}
                searchPlaceholder="Placa"
                columns={[
                  {
                    name: 'Placa',
                    selector: row =>
                      <Link to={`/veiculos/${row.id}`}>{row?.plate}</Link> ||
                      'Não informado',
                  },
                  {
                    name: 'Tipo do veículo',
                    selector: row => row?.vehicleType?.name || 'Não informado',
                  },
                ]}
              />
            </Col>
          </Row>
        </Fallback>
      </Card>
    </>
  );
}
