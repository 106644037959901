import * as Yup from 'yup';

const BusinessSchema = Yup.object({
  business: Yup.object()
    .transform(function (value, originalValue) {
      if (value === null || typeof value !== 'object') {
        return undefined;
      } else return value;
    })
    .required('Nome do negócio obrigatório'),
  serviceLevels: Yup.object({
    ouro: Yup.object({
      margin: Yup.string()
        .transform(function (value, originalValue) {
          if (value === null) {
            return undefined;
          } else return value;
        })
        .required('Informe um valor'),
      advance: Yup.string()
        .transform(function (value, originalValue) {
          if (value === null) {
            return undefined;
          } else return value;
        })
        .required('Informe um valor'),
    }),
    agregado: Yup.object({
      margin: Yup.string()
        .transform(function (value, originalValue) {
          if (value === null) {
            return undefined;
          } else return value;
        })
        .required('Informe um valor'),
      advance: Yup.string()
        .transform(function (value, originalValue) {
          if (value === null) {
            return undefined;
          } else return value;
        })
        .required('Informe um valor'),
    }),
    terceiro: Yup.object({
      margin: Yup.string()
        .transform(function (value, originalValue) {
          if (value === null) {
            return undefined;
          } else return value;
        })
        .required('Informe um valor'),
      advance: Yup.string()
        .transform(function (value, originalValue) {
          if (value === null) {
            return undefined;
          } else return value;
        })
        .required('Informe um valor'),
    }),
  }),
});

export async function validateBusinessData(data, setErrors) {
  const errorList = {};
  try {
    await BusinessSchema.validate(data, {
      abortEarly: false,
    });

    setErrors({ ...errorList });

    return true;
  } catch (err) {
    if (err.inner) {
      err.inner.forEach(error => {
        errorList[error.path] = error?.message;
      });
    }
    setErrors({ ...errorList });

    return false;
  }
}
