import React, { useContext } from 'react';
import { DatePicker, Modal, Text } from 'v3/components';
import { Col, Row } from 'react-bootstrap';
import { capitalizeFullname, formatDateTime } from 'v3/utils/formatter';
import { BsPencil } from 'react-icons/bs';
import {
  Body as BodyAditionalFieldModal,
  Footer as FooterAditionalFieldModal,
  Heading as HeadingAditionalFieldModal,
} from 'components/ModalOccurenceAditionalFields';
import { OccurrencesAditionalFieldsContext } from 'contexts/OccurrencesAditionalFields';
import InputCurrency from 'v3/components/Input/Currency/index';
import { formatCurrency } from 'utils/formatter';
import { SectionContainer } from '../../styles';
import {
  DetailsContainer,
  HeaderContainer,
  PendencyLabel,
  StyledDetailCol,
  TitleContainer,
} from '../styles';
import OccurencesAditionalFieldsContainer from './OccurencesAditionalFieldsContainer';
import { AditionalFIeldsContainer, StyledButton } from './style';

export function OccurrenceGeneralInfo({
  selectedOccurrence,
  show,
  startDate,
  setStartDate,
  setEndDate,
  endDate,
  errors,
  loadingUpdate,
  savingNewOccurrence,
  occurrenceValue,
  setOccurrenceValue,
}) {
  const { aditionalItensModal, handleCloseModal } = useContext(
    OccurrencesAditionalFieldsContext
  );

  return (
    <>
      <SectionContainer>
        <HeaderContainer>
          <TitleContainer>
            <Text weight={500} type="header" color="dark">
              Informações da Ocorrência
            </Text>
            {selectedOccurrence?.pendency &&
              !selectedOccurrence?.fullUserConclusion?.username && (
                <PendencyLabel>Pendente</PendencyLabel>
              )}
          </TitleContainer>
          {selectedOccurrence?.pendency &&
            !selectedOccurrence?.fullUserConclusion?.username &&
            !selectedOccurrence?.fullUserConclusion?.id && (
              <StyledButton
                $backgroundColor="transparent"
                $color="#464e5f"
                onClick={aditionalItensModal.open}
                style={{ display: 'flex', gap: 8, alignItems: 'center' }}
              >
                <BsPencil /> Gestão de itens
              </StyledButton>
            )}
        </HeaderContainer>
        <DetailsContainer>
          <Row>
            <Col xxl={8} xl={7} lg={7} md={7} sm={12} xs={12}>
              <Row>
                <Col xxl={6} lg={6} md={6} sm={12} xs={12}>
                  <Text type="regular" color="dark" weight={500}>
                    Data inclusão:{' '}
                  </Text>
                  <Text type="regular" color="dark" weight={400}>
                    {formatDateTime(selectedOccurrence.createdAt)}
                  </Text>
                </Col>
                <Col xxl={6} lg={6} md={6} sm={12} xs={12}>
                  <Text type="regular" color="dark" weight={500}>
                    Tipo:{' '}
                  </Text>
                  <Text type="regular" color="dark" weight={400}>
                    {selectedOccurrence.fullOccurrenceType.name}
                  </Text>
                </Col>
                <StyledDetailCol xxl={12} lg={12} md={12} sm={12} xs={12}>
                  <Text type="regular" color="dark" weight={500}>
                    Incluido por:{' '}
                  </Text>
                  <Text type="regular" color="dark" weight={400}>
                    {capitalizeFullname(
                      selectedOccurrence.fullUserCreated.username
                    )}
                  </Text>
                </StyledDetailCol>
                <Col xxl={6} lg={6} md={6} sm={12} xs={12}>
                  <Text type="regular" color="dark" weight={500}>
                    Grupo Responsável:{' '}
                  </Text>
                  <Text type="regular" color="dark" weight={400}>
                    {selectedOccurrence?.groups.length > 0
                      ? selectedOccurrence?.groups
                          .map(g => capitalizeFullname(g.name))
                          .join(', ')
                      : capitalizeFullname(selectedOccurrence?.group?.name)}
                  </Text>
                </Col>
                <Col xxl={6} lg={6} md={6} sm={12} xs={12}>
                  <Text type="regular" color="dark" weight={500}>
                    Cliente Visualiza:{' '}
                  </Text>
                  <Text type="regular" color="dark" weight={400}>
                    {selectedOccurrence.displayClient ? 'Sim' : 'Não'}
                  </Text>
                </Col>
              </Row>
            </Col>
            <Col xxl={4} xl={5} lg={5} md={5} sm={12} xs={12}>
              <Row>
                <Col xxl={6} lg={6} md={6} sm={12} xs={12}>
                  {show.addDescriptionToOccurrence || show?.endOccurrence ? (
                    <DatePicker
                      label="Início ocorrência *"
                      value={startDate}
                      onChange={e => setStartDate(e.target.value)}
                      withTime
                      error={errors?.startDate}
                      disabled={loadingUpdate || savingNewOccurrence}
                    />
                  ) : (
                    <DatePicker
                      disabled
                      value={formatDateTime(selectedOccurrence.startDate)}
                      label="Início Ocorrência"
                      withTime
                    />
                  )}
                </Col>
                <Col xxl={6} lg={6} md={6} sm={12} xs={12}>
                  {show.addDescriptionToOccurrence || show.endOccurrence ? (
                    <DatePicker
                      label="Término ocorrência"
                      value={endDate || ''}
                      onChange={e => setEndDate(e.target.value)}
                      withTime
                      error={errors?.endDate}
                      disabled={loadingUpdate || savingNewOccurrence}
                    />
                  ) : (
                    <DatePicker
                      placeholder=""
                      disabled
                      value={formatDateTime(selectedOccurrence.endDate)}
                      label="Término Ocorrência"
                      withTime
                    />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <AditionalFIeldsContainer>
            <Row>
              <Col lg={3} md={6} sm={6} xs={12} className="mt-6">
                {show?.addDescriptionToOccurrence || show?.endOccurrence ? (
                  <InputCurrency
                    label="Valor"
                    placeholder="Adicionar valor da ocorrência"
                    value={occurrenceValue}
                    onChange={(_, value) => setOccurrenceValue(value)}
                  />
                ) : (
                  <InputCurrency
                    label="Valor"
                    value={formatCurrency(selectedOccurrence.value)}
                    disabled
                  />
                )}
              </Col>
              <OccurencesAditionalFieldsContainer />
            </Row>
          </AditionalFIeldsContainer>
        </DetailsContainer>
      </SectionContainer>
      <Modal
        backdropClassName="backdrop-occurrences-aditional-field"
        show={aditionalItensModal.isOpen}
        handleClose={handleCloseModal}
        heading={<HeadingAditionalFieldModal />}
        body={<BodyAditionalFieldModal />}
        footer={<FooterAditionalFieldModal />}
      />
    </>
  );
}
