const Yup = require('yup');

const schema = Yup.object().shape({
  origin: Yup.object()
    .required('Estado de origem obrigatório')
    .typeError('Estado de origem obrigatório'),
  destination: Yup.object()
    .required('Estado de destino obrigatório')
    .typeError('Estado de destino obrigatório'),
  rate: Yup.string()
    .required('Valor obrigatório')
    .typeError('Valor obrigatório'),
});

export default schema;
