import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Row, Col } from 'react-bootstrap';
import { cooperplaceApi } from 'services/api';

import { Input, Select, TreeSelectComponent } from 'v3/components';

import { usePermission } from 'hooks';
import { measureOptions } from 'v3/utils/products';
import { useProduct } from './context';
import { fetchSelectOptions } from './controller';

export default function ProductData() {
  const product = useProduct();
  const params = useParams();
  const hasPermissionToEditPoints = usePermission('EDITAR_PONTOS_PRODUTO');

  useEffect(() => {
    if (!params.id)
      product.setData({
        name: null,
        productNcm: null,
        unit_measure: null,
        points: null,
        productType: null,
      });
  }, []);

  async function fetchNcmProducts(value) {
    try {
      const { data } = await cooperplaceApi.get('/ncm', {
        params: {
          search: value,
        },
      });

      return data?.data || [];
    } catch (err) {
      return [];
    }
  }

  const [ncmOptions, setNcmOptions] = useState([]);
  const [loadingNcm, setLoadingNcm] = useState(false);
  async function handleSearch(value) {
    try {
      setLoadingNcm(true);
      const response = await fetchNcmProducts(value);
      const processedResponse = response.map(convertToTreeData);
      setNcmOptions(processedResponse);
    } catch (error) {
    } finally {
      setLoadingNcm(false);
    }
  }

  const filterTreeNode = (input, treeNode) => {
    return treeNode;
  };

  const convertToTreeData = node => {
    return {
      value: node.id,
      code: node?.code,
      title: `${node?.code} - ${node?.name}`,
      name: node?.name,
      chapterName: node?.chapterName,
      chapterCode: node?.chapterCode,
      children: node.children ? node.children.map(convertToTreeData) : [],
    };
  };

  return (
    <Row>
      <Col xs={12} className="mb-3">
        <Input
          label="Nome *"
          value={product.data?.name}
          onChange={e => product.setData({ name: e.target.value })}
          error={product.errors?.['data.name']}
        />
      </Col>
      <Col xs={6} className="mb-3">
        <Select
          label="Unidade de Medida *"
          value={product.data.unit_measure}
          placeholder="Selecione"
          onChange={value =>
            product.setData({
              unit_measure: value,
            })
          }
          options={measureOptions}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.value}
          error={
            product.errors?.['data.unit_measure'] ||
            product.errors?.['data.unit_measure.value']
          }
        />
      </Col>
      <Col xs={6} className="mb-3">
        <Input
          label="Pontos *"
          type="number"
          min="0"
          value={product.data?.points}
          onChange={e =>
            product.setData({
              points: e.target.value,
            })
          }
          error={product.errors?.['data.points']}
          disabled={!hasPermissionToEditPoints}
        />
      </Col>
      <Col xs={12} className="mb-3">
        <Select.Async
          label="Categoria do produto *"
          placeholder="Digite a categoria"
          onSearch={search => fetchSelectOptions('product-types', { search })}
          value={product.data.productType}
          onChange={value =>
            product.setData({
              productType: value,
            })
          }
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          error={
            product.errors?.['data.productType'] ||
            product.errors?.['data.productType.id']
          }
        />
      </Col>

      <Col xs={12} className="mb-3">
        <TreeSelectComponent
          label="Digite o código ou nome ncm *"
          labelInValue
          showSearch
          dropdownStyle={{
            maxHeight: 400,
            overflow: 'auto',
            border: '1px solid #e5eaee',
          }}
          onSearch={inputValue => handleSearch(inputValue)}
          filterTreeNode={filterTreeNode}
          treeData={ncmOptions}
          placeholder="Digite para buscar"
          allowClear
          loading={loadingNcm}
          onBlur={() => setNcmOptions([])}
          value={
            product?.data?.productNcm?.id &&
            product?.data?.productNcm?.chapterCode
              ? {
                  label: (
                    <span>
                      <i>
                        {product?.data?.productNcm?.chapterCode}
                        {' - '}
                        {product?.data?.productNcm?.chapterName}{' '}
                      </i>
                      <br />

                      <span>
                        {product?.data?.productNcm?.code}
                        {' - '}
                        {product?.data?.productNcm?.name}
                      </span>
                    </span>
                  ),
                  value: product?.data?.productNcm?.id,
                }
              : product?.data?.productNcm?.chapter
              ? {
                  label: (
                    <span>
                      <i>
                        {product?.data?.productNcm?.chapter?.code}
                        {' - '}
                        {product?.data?.productNcm?.chapter?.name}{' '}
                      </i>
                      <br />

                      <span>
                        {product?.data?.productNcm?.code}
                        {' - '}
                        {product?.data?.productNcm?.name}
                      </span>
                    </span>
                  ),
                  value: product?.data?.productNcm?.id,
                }
              : product?.data?.productNcm?.id
              ? {
                  label: (
                    <span>
                      <span>
                        {product?.data?.productNcm?.code} {' - '}
                        {product?.data?.productNcm?.name}
                      </span>
                    </span>
                  ),
                  value: product?.data?.productNcm?.id,
                }
              : null
          }
          onChange={value => {
            if (value === null || value === undefined) {
              product.setData({
                productNcm: { label: '', name: '', id: null, code: '' },
              });
            }
          }}
          onSelect={(value, node) => {
            product.setData({
              ncm: node?.code,
              productNcm: {
                label: `${node?.code} - ${node?.name}`,
                id: node?.value,
                name: node?.name,
                code: node?.code,
                chapterCode: node?.chapterCode,
                chapterName: node?.chapterName,
              },
            });
          }}
          error={product.errors?.['data.productNcm']}
        />
      </Col>
    </Row>
  );
}
