import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

import { FaTrash } from 'react-icons/fa';

import api from 'services/api';

import Modal from 'v3/components/Modal';
import Select from 'v3/components/Select';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import Table from 'v3/components/Table';
import { useSnackbar } from 'v3/components/Snackbar';

import { useCompany } from '../context';

function Trigger({ isOpen, onClose, setShowModal }) {
  const company = useCompany();
  const params = useParams();
  const snackbar = useSnackbar();

  const [errors, setErrors] = useState({});
  const [triggerOptions, setTriggerOptions] = useState([]);
  const [automationOptions, setAutomationsOptions] = useState([]);
  const [trigger, setTrigger] = useState();
  const [automation, setAutomation] = useState();
  const [automations, setAutomations] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (company.data?.editingCurrentTrigger) {
      setTrigger(company.data.editingCurrentTrigger);
      setAutomations(company.data.editingCurrentTrigger.automations);
    } else {
      setTrigger(null);
      setAutomations([]);
      setAutomation(null);
    }
  }, [company.data.editingCurrentTrigger]);

  async function fetchTriggers(search) {
    try {
      const { data: response } = await api.get('v3/triggers', {
        params: {
          search,
        },
      });

      return response.filter(
        item =>
          company.data?.triggers.filter(trigg => trigg.id === item.id)
            .length === 0
      );
    } catch (error) {
      return [];
    }
  }

  function handleAddTrigger() {
    if (company.data.triggers.map(item => item?.id).includes(trigger?.id)) {
      setErrors(old => ({
        ...old,
        trigger: 'Evento já adicionado',
      }));
      return;
    }

    company.setData({
      triggers: [
        ...company.data?.triggers,
        {
          ...trigger,
          automations,
        },
      ],
    });
    setShowModal(false);
    company.setData({ editingCurrentTrigger: null });
    setTrigger(null);
    setAutomations([]);
    setAutomation(null);
    setErrors(null);
    company.setPrevCompany(company.data);
  }

  async function handleAddWhenEditing() {
    try {
      setLoading(true);
      await api.put(`v3/company/${company.data.id}/trigger/${trigger.id}`, {
        automations: automations?.map(item => item.automation_id || item.id),
      });

      company.setData({
        triggers: [
          ...company.data?.triggers,
          {
            ...trigger,
            automations,
          },
        ],
      });
    } catch (err) {
      snackbar.show(<Text>Erro ao salvar evento</Text>, {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }

    if (company.data.triggers.map(item => item?.id).includes(trigger?.id)) {
      setErrors(old => ({
        ...old,
        trigger: 'Evento já adicionado',
      }));
      return;
    }

    company.setData({
      triggers: [
        ...company.data?.triggers,
        {
          ...trigger,
          automations,
        },
      ],
      editingCurrentTrigger: null,
    });
    setShowModal(false);
    setTrigger(null);
    setAutomations([]);
    setAutomation(null);
    setErrors(null);
    company.setPrevCompany(company.data);
  }

  async function handleAddAutomation() {
    if (
      automations
        .map(item => item?.automation_id)
        .includes(automation?.automation_id) ||
      automations.map(item => item?.id).includes(automation?.automation_id)
    ) {
      setErrors(old => ({
        ...old,
        select: 'Essa automação já está vinculada',
      }));
      return;
    }
    setErrors(null);
    setAutomations([...automations, automation]);
    setAutomation(null);
  }

  useEffect(() => {
    async function fetchTriggersOptions() {
      try {
        const response = await api.get('v3/triggers/select');
        setTriggerOptions(response.data);
      } catch (error) {
        //
      }
    }

    fetchTriggersOptions();
  }, []);

  useEffect(() => {
    async function fetchAutomationOptions() {
      const response = await api.get('v3/trigger/automations');
      setAutomationsOptions(response.data);
    }

    fetchAutomationOptions();
  }, [trigger]);

  const memoeizedTriggerOption = useMemo(() => {
    return triggerOptions.filter(
      item =>
        company.data.triggers?.filter(trigg => trigg.id === item.id).length ===
        0
    );
  }, [triggerOptions, company.data?.triggers]);

  const memoeizedAutomationsOptions = useMemo(() => {
    return automationOptions
      .filter(item => item.trigger_id === trigger?.id)
      .filter(
        autoOption =>
          automations.filter(
            auto => (auto.automation_id || auto.id) === autoOption.automation_id
          ).length === 0
      );
  }, [automationOptions, automations]);

  return (
    <>
      <Modal
        size="lg"
        show={isOpen}
        onHide={onClose}
        heading={
          <Text color="dark" type="header">
            Eventos
          </Text>
        }
        body={
          <Row>
            <Col xs={12}>
              <Select.Async
                removeInputMin
                options={memoeizedTriggerOption}
                label="Evento"
                value={trigger}
                onSearch={fetchTriggers}
                horizontal
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                onChange={value => setTrigger(value)}
                error={errors?.trigger}
                isDisabled={automations.length > 0}
              />
              <Text as="p" className="my-4" type="header" color="dark">
                Automações
              </Text>
              <div className="mb-2">
                <Text type="label" color="dark">
                  Automação
                </Text>
              </div>
              <div className="d-flex align-items-center mb-3">
                <Select
                  options={memoeizedAutomationsOptions}
                  value={automation}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.automation_id}
                  onChange={value => setAutomation(value)}
                  style={{
                    control: styles => ({
                      ...styles,
                      borderRightColor: 'transparent',
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                    }),
                  }}
                  error={errors?.select}
                  components={{ IndicatorsContainer: () => null }}
                />
                <Button
                  disabled={!automation}
                  size="md"
                  onClick={() => {
                    handleAddAutomation();
                  }}
                  style={{
                    border: errors?.select ? '1px solid red' : 'transparent',
                    marginBottom: errors?.select ? 23 : null,
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  }}
                >
                  <Text type="regular" weight="500" color="white">
                    Adicionar
                  </Text>
                </Button>
              </div>
              {automations?.length > 0 && (
                <Table striped>
                  <thead>
                    <tr>
                      <th>
                        <Text weight={500} type="regular" color="dark">
                          Nome
                        </Text>
                      </th>
                      <th>
                        <Text weight={500} type="regular" color="dark">
                          Ação
                        </Text>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {automations.map(item => (
                      <tr>
                        <td>
                          <Text type="regular" color="dark">
                            {item.name}
                          </Text>
                        </td>
                        <td width="120">
                          <Button
                            variant="error"
                            size="sm"
                            onClick={() =>
                              setAutomations(
                                automations.filter(auto => auto !== item)
                              )
                            }
                          >
                            <FaTrash size={16} />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
              <div className="text-center">
                <Button
                  disabled={automations?.length === 0}
                  onClick={() => {
                    if (params.id) handleAddWhenEditing();
                    else handleAddTrigger();
                  }}
                  variant="success"
                  loading={loading}
                  className="my-4 py-2"
                >
                  <Text type="regular" weight="500" color="white">
                    Salvar
                  </Text>
                </Button>
              </div>
            </Col>
          </Row>
        }
      />
    </>
  );
}

export default Trigger;
