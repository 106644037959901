import CheckBox from 'components/CheckBox';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { cooperplaceApi } from 'services/api';
import { fetchSelectOptions } from 'utils/fetches';
import { Button, Input, Modal, Select, Text } from 'v3/components';
import { DefaultLoadingComponent } from 'v3/components/List';
import { Label } from 'v3/components/Select/styles';
import {
  fetchBodies,
  fetchVehicles,
} from 'v3/pages/Load/Register/Vehicle/controller';

export function Filter({
  onFilterChange,
  typeOptions,
  showAdvancedFilter,
  onCloseAdvanceFilterModal,
  loading,
  setFilter,
  setAdvancedFiltersQtd,
}) {
  const [name, setName] = useState('');
  const [checklistType, setChecklistType] = useState([]);
  const [vehiclesOptions, setVehicleOptions] = useState([]);
  const [bodyOptions, setBodyOptions] = useState([]);
  const [advancedFilters, setAdvancedFilters] = useState({
    vehicleTypes: [],
    bodyTypes: [],
    clients: [],
    negotiations: [],
    questions: [],
    activeFilter: {
      actives: false,
      inactives: false,
    },
    branchOffices: [],
  });
  const [loadingAdvancedFilterOptions, setLoadingAdvancedFilterOptions] =
    useState(false);

  function handleCloseModal() {
    setFilter(old => ({
      ...old,
      ...advancedFilters,
    }));
    onCloseAdvanceFilterModal();
  }

  async function getVehicleTypes() {
    const vehicle = await fetchVehicles();
    setVehicleOptions(vehicle);
  }
  async function getVehicleBodyTypes() {
    const bodies = await fetchBodies();
    setBodyOptions(bodies);
  }

  function handleSearchType(search) {
    if (!search) {
      return typeOptions;
    }
    const parsed = search.toLowerCase();
    return typeOptions.filter(
      option => option.name.toLowerCase().search(parsed) >= 0
    );
  }

  function onApplyFilters() {
    onFilterChange({
      name,
      checklistType,
    });
  }

  function onApplyMultFilters() {
    onFilterChange({
      name,
      checklistType,
      ...advancedFilters,
    });
  }

  async function fetchInitalOptions() {
    try {
      setLoadingAdvancedFilterOptions(true);
      await Promise.all([getVehicleTypes(), getVehicleBodyTypes()]);
    } catch (error) {
    } finally {
      setLoadingAdvancedFilterOptions(false);
    }
  }

  useEffect(() => {
    if (showAdvancedFilter) {
      fetchInitalOptions();
    }
  }, [showAdvancedFilter]);

  useEffect(() => {
    function onPressedKey(e) {
      if (e.key === 'Enter') {
        onApplyFilters();
      }
    }
    window.addEventListener('keyup', onPressedKey);
    return () => {
      window.removeEventListener('keyup', onPressedKey);
    };
  });
  async function handleSearchQuestion(search) {
    try {
      const { data } = await cooperplaceApi.get(
        '/checklists-questions/search',
        {
          params: { question: search },
        }
      );
      return data || [];
    } catch (error) {
      return [];
    }
  }
  async function handleSearchNegotiation(search) {
    try {
      const { data } = await cooperplaceApi.get('negotiation/search', {
        params: { search },
      });
      return data || [];
    } catch (error) {
      return [];
    }
  }

  async function handleSearchBranchOffices(search) {
    try {
      const {
        data: { branchOffices },
      } = await cooperplaceApi.get('branch-offices/by-user', {
        params: { search },
      });

      return branchOffices || [];
    } catch (error) {
      return [];
    }
  }

  useEffect(() => {
    const isNotEmpty = value => value !== null && value?.length > 0;

    const advancedFiltersObj = {
      vehicleTypes: advancedFilters?.vehicleTypes || [],
      bodyTypes: advancedFilters?.bodyTypes || [],
      clients: advancedFilters?.clients || [],
      negotiations: advancedFilters?.negotiations || [],
      questions: advancedFilters?.questions || [],
      activesOrInactives:
        advancedFilters.activeFilter.actives ||
        advancedFilters.activeFilter.inactives
          ? [true]
          : [],
      branchOffices: advancedFilters.branchOffices || [],
    };

    setAdvancedFiltersQtd(
      Object.values(advancedFiltersObj).filter(isNotEmpty).length
    );
  }, [advancedFilters]);

  return (
    <>
      <Modal
        show={showAdvancedFilter}
        handleClose={handleCloseModal}
        heading="Filtro Avançado"
        body={
          loadingAdvancedFilterOptions ? (
            <DefaultLoadingComponent />
          ) : (
            <Row>
              <Col md={6} sm={12} className="my-1">
                <Select.Async
                  label="CNPJ"
                  placeholder="CNPJ"
                  multiple
                  horizontal
                  modalHeading="CNPJs"
                  modalBodyTitle="Selecione um cnpj"
                  onSearch={search =>
                    fetchSelectOptions('persons/customers', {
                      search,
                    })
                  }
                  getOptionLabel={option => {
                    if (option?.label) {
                      return option?.label;
                    }
                    return `${option?.socialName} - ${option?.cpfCnpj}`;
                  }}
                  getOptionValue={option => option.id}
                  value={advancedFilters.clients}
                  onChange={value =>
                    setAdvancedFilters(old => ({
                      ...old,
                      clients: value,
                    }))
                  }
                />
              </Col>
              <Col md={6} sm={12} className="my-1">
                <Select.Async
                  label="Negociação"
                  placeholder="Negociação"
                  multiple
                  horizontal
                  modalHeading="Negociações"
                  modalBodyTitle="Selecione uma negociação"
                  onSearch={search => handleSearchNegotiation(search)}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  value={advancedFilters.negotiations}
                  onChange={value =>
                    setAdvancedFilters(old => ({
                      ...old,
                      negotiations: value,
                    }))
                  }
                />
              </Col>
              <Col md={6} sm={12} className="my-1">
                <Select
                  label="Tipo de veículo"
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  options={vehiclesOptions}
                  multiple
                  value={advancedFilters.vehicleTypes}
                  onChange={value =>
                    setAdvancedFilters(old => ({
                      ...old,
                      vehicleTypes: value,
                    }))
                  }
                />
              </Col>
              <Col md={6} sm={12} className="my-1">
                <Select
                  label="Tipo de carroceria"
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  multiple
                  options={bodyOptions}
                  value={advancedFilters.bodyTypes}
                  onChange={value =>
                    setAdvancedFilters(old => ({
                      ...old,
                      bodyTypes: value,
                    }))
                  }
                />
              </Col>
              <Col md={6} sm={12} className="my-1">
                <Select.Async
                  label="Pergunta"
                  placeholder="Pergunta"
                  multiple
                  horizontal
                  modalHeading="Perguntas"
                  modalBodyTitle="Selecione uma pergunta"
                  onSearch={search => handleSearchQuestion(search)}
                  getOptionLabel={option => option.question}
                  getOptionValue={option => option.id}
                  value={advancedFilters.questions}
                  onChange={value =>
                    setAdvancedFilters(old => ({
                      ...old,
                      questions: value,
                    }))
                  }
                />
              </Col>
              <Col md={6} sm={12} className="my-1">
                <Label
                  color="#464e5f"
                  type="label"
                  weight="500"
                  labelWhiteSpace="normal"
                >
                  Status checklist
                </Label>
                <CheckBox
                  onChange={(_e, isActive) => {
                    setAdvancedFilters(old => ({
                      ...old,
                      activeFilter: { ...old.activeFilter, actives: isActive },
                    }));
                  }}
                  checked={advancedFilters.activeFilter.actives}
                  label="Ativos"
                  labelStyle={{ textTransform: 'none' }}
                />
                <CheckBox
                  onChange={(_e, isActive) => {
                    setAdvancedFilters(old => ({
                      ...old,
                      activeFilter: {
                        ...old.activeFilter,
                        inactives: isActive,
                      },
                    }));
                  }}
                  checked={advancedFilters.activeFilter.inactives}
                  label="Inativos"
                  labelStyle={{ textTransform: 'none' }}
                />
              </Col>
              <Col md={6} sm={12} className="my-1">
                <Select.Async
                  label="Filiais"
                  placeholder="Filiais"
                  multiple
                  horizontal
                  modalHeading="Filiais"
                  modalBodyTitle="Selecione uma filial"
                  onSearch={handleSearchBranchOffices}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  value={advancedFilters.branchOffices}
                  onChange={value => {
                    setAdvancedFilters(old => ({
                      ...old,
                      branchOffices: value || [],
                    }));
                  }}
                />
              </Col>
            </Row>
          )
        }
        footer={
          <Row style={{ justifyContent: 'flex-end' }}>
            <Button
              variant="primary"
              onClick={() => onApplyMultFilters()}
              loading={loading}
            >
              <Text color="white" weight={500}>
                Filtrar
              </Text>
            </Button>
          </Row>
        }
      />
      <Row className="my-4">
        <Col md={5} sm={12}>
          <Input
            label="Nome"
            labelColor="#fff"
            value={name}
            onChange={e => {
              setName(e.target.value);
            }}
          />
        </Col>
        <Col md={5} sm={12}>
          <Select.Async
            labelWhiteSpace="nowrap"
            label="Tipo de checklist"
            labelColor="#fff"
            modalHeading="Selecione um tipo de checklist"
            modalBodyTitle="Checklists:"
            multiple
            horizontal
            onSearch={search => handleSearchType(search)}
            value={checklistType}
            onChange={value => {
              if (value) {
                setChecklistType(value);
              } else {
                setChecklistType([]);
              }
            }}
            getOptionLabel={option => option?.name}
            getOptionValue={option => option?.id}
            options={typeOptions}
          />
        </Col>
        <Col md={2} sm={12} style={{ display: 'flex', alignItems: 'end' }}>
          <Button
            style={{ flex: 1 }}
            variant="success"
            onClick={() => onApplyFilters()}
          >
            <Text type="regular" weight={500}>
              Filtrar
            </Text>
          </Button>
        </Col>
      </Row>
    </>
  );
}
