import {
  checkIfCNPJIsValid,
  checkIfCPFIsValid,
} from 'v3/utils/documentValidator';
import * as Yup from 'yup';

function valueSize(value) {
  if (
    value.replace(/[^0-9]+/g, '').length === 4 ||
    value.replace(/[^0-9]+/g, '').length === 0
  )
    return true;
  return false;
}

export const settingsSchema = Yup.array().of(
  Yup.object().shape({
    person_id: Yup.number().nullable(),
    type: Yup.string(),
    value: Yup.string().when('type', {
      is: 'PRIORITY_FLEET_LOAD_DURATION',
      then: Yup.string().test(
        'value size',
        'Preencha o horário completo',
        value => valueSize(value)
      ),
    }),
  })
);

export const dataSchema = Yup.object().shape({
  personType: Yup.object().shape({
    name: Yup.string().required('Erro no tipo de pessoa!'),
  }),
  is_international: Yup.bool().typeError('Erro no campo de estrangeiro'),
  ie: Yup.string()
    .nullable()
    .when('personType', {
      is: value => value.name === 'Jurídica',
      then: Yup.string().required('Inscrição estadual obrigatória').nullable(),
    }),

  fantasy_name: Yup.string()
    .nullable()
    .when('personType', {
      is: value => value.name === 'Jurídica',
      then: Yup.string()
        .required('Nome fantasia obrigatório')
        .typeError('Nome fantasia obrigatório'),
    }),
  cgccpf: Yup.string()
    .required('Documento obrigatório')
    .typeError('Documento obrigatório')
    .when(['is_international', 'personType'], {
      is: (is_international, personType) =>
        !is_international && personType.name === 'Jurídica',
      then: Yup.string().test('invalid-document', 'CNPJ inválido', value =>
        checkIfCNPJIsValid(value)
      ),
    })
    .when(['is_international', 'personType'], {
      is: (is_international, personType) =>
        !is_international && personType.name !== 'Jurídica',
      then: Yup.string().test('invalid-document', 'CPF inválido', value =>
        checkIfCPFIsValid(value)
      ),
    }),
  social_name: Yup.string()
    .required('Nome obrigatório')
    .typeError('Nome obrigatório'),
  phone: Yup.string()
    .required('Telefone obrigatório')
    .nullable()
    .min(10, 'Minimo de caracteres não atingido'),
  email: Yup.string().required('E-mail obrigatório').nullable(),
  takerRole: Yup.object()
    .shape({
      name: Yup.string().required('Informação de contribuinte obrigatória!'),
    })
    .typeError('Informação de contribuinte obrigatória!'),
});

export const AddressesSchema = Yup.array()
  .of(
    Yup.object().shape({
      address: Yup.string().required('Erro na rua!'),
      city: Yup.string().required('Erro na cidade!'),
      city_name: Yup.string().required('Erro na cidade!'),
      current: Yup.string().required('Erro no endereço atual!'),
      province: Yup.string().required('Erro no estado!'),
      province_name: Yup.string().required('Erro no estado!'),
      lat: Yup.number().required(
        'Selecione um município utilizando o seletor do google'
      ),
      lng: Yup.number().required(
        'Selecione um município utilizando o seletor do google'
      ),
    })
  )
  .required('Endereço é obrigatório');

export const TrackingSchema = Yup.object({
  sending_tracking: Yup.boolean(),
  emails_tracking: Yup.string().when('sending_tracking', {
    is: value => value === true,
    then: Yup.string()
      .required('Informe um email para receber tracking')
      .typeError('Informe um email para receber tracking'),
  }),
});

export const clientSchema = Yup.object().shape({
  settings: settingsSchema,
  data: dataSchema,
  addresses: AddressesSchema,
  tracking: TrackingSchema,
});

export const updateAddressSchema = Yup.object().shape({
  formatted_address: Yup.string().nullable().required('Endereço obrigatório!'),
  address: Yup.string().nullable().required('Rua obrigatória!'),
  neighborhood: Yup.string().nullable().required('Bairro obrigatório!'),
  city_name: Yup.string().nullable().required('Cidade obrigatória!'),
  city_id: Yup.number().nullable().required('Cidade não cadastrada!'),
  country_id: Yup.number().nullable().required('País obrigatório!'),
  province: Yup.string().nullable().required('UF obrigatória!'),
  lat: Yup.number()
    .nullable()
    .required('Selecione um endereço utilizando o seletor do google'),
  lng: Yup.number()
    .nullable()
    .required('Selecione um endereço utilizando o seletor do google'),
});

export async function validate(field, data, setErrors) {
  const errorList = {};
  try {
    if (field === 'allSchema')
      await clientSchema.validate(data, {
        abortEarly: false,
      });
    else {
      await clientSchema.validateAt(field, data, {
        abortEarly: false,
      });
    }

    return true;
  } catch (err) {
    if (err.inner) {
      err.inner.forEach(error => {
        errorList[error.path] = error?.message;
      });
    }
    return false;
  } finally {
    setErrors(errorList);
  }
}
