import React from 'react';
import Text from 'v3/components/Text';
import * as Styled from './styles';

export default function BulletList({ items }) {
  return (
    <Styled.UnorderedList>
      {items.map(item => (
        <Styled.ListItem filledCircle={item.filledCircle} link={item.link}>
          {item.header && <Text>{item.header}</Text>}
          {item.body && item.body}
        </Styled.ListItem>
      ))}
    </Styled.UnorderedList>
  );
}
