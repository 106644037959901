import { format } from 'date-fns';
import moment from 'moment';
import api, { cooperplaceApi } from 'services/api';
import {
  trackedVehicleRequiredFiels,
  validAnttRegex,
  vehicleRequiredFields,
  wagonRequiredFields,
} from 'v3/utils/constants';

export function checkVehicleData(data) {
  const validation = vehicleRequiredFields.filter(field => !data[field]);

  const anttNumberValidation = validAnttRegex.test(data?.antt);

  let trackValidation = null;
  const shouldValidatedTracker = data?.tracked;
  if (shouldValidatedTracker) {
    trackValidation = trackedVehicleRequiredFiels.filter(field => !data[field]);

    return (
      validation?.length > 0 ||
      !anttNumberValidation ||
      trackValidation?.length > 0
    );
  }
  return validation?.length > 0 || !anttNumberValidation;
}

export function wagonDataMapper(data) {
  return {
    id: data?.id,
    plate: data?.plate,
    year_manufacture: data?.year_manufacture || data?.yearManufacture,
    year_model: data?.year_model || data?.yearModel,
    chassi: data?.chassi,
    vehicleType: data?.vehicleType,
    vehicle_type_id: data?.vehicle_type_id || data?.vehicleTypeId,
    bodyType: data?.bodyType,
    vehicle_body_type_id: data?.vehicle_body_type_id || data?.vehicleBodyTypeId,
    vehicle_implement_type_id:
      data?.vehicle_implement_type_id || data?.vehicleImplementTypeId,
    implementType: data?.implementType,
    renavam: data?.renavam,
    antt_type: data?.antt_type || data?.anttType,
    anttType: data?.anttTypes || data?.anttType,
    antt: data?.antt,
    antt_adherence:
      data?.antt_adherence || data?.anttAdherence
        ? format(
            new Date(
              moment(data?.antt_adherence || data?.anttAdherence).add(1, 'day')
            ),
            'yyyy-MM-dd'
          )
        : null,
    city: data?.city
      ? {
          id: data?.city?.id,
          name: data?.city?.name,
          uf: data?.city?.province?.uf,
        }
      : null,
    id_owner: data?.id_owner || data?.idOwner,
    owner: data?.owner || data?.ownerWagon,
  };
}
export function wagonDataToValidate(data) {
  return {
    plate: data?.plate,
    chassi: data?.chassi,
    renavam: data?.renavam,
    year_model: data?.year_model || data?.yearModel,
    year_manufacture: data?.year_manufacture || data?.yearManufacture,
    plate_city_id: data?.city?.id,
    vehicle_type_id: data?.vehicle_type_id || data?.vehicleTypeId,
    vehicle_body_type_id: data?.vehicle_body_type_id || data?.vehicleBodyTypeId,
    vehicle_implement_type_id:
      data?.vehicle_implement_type_id || data?.vehicleImplementTypeId,
    vehicle_implement_type_name: data?.implementType?.name,
    antt_type: data?.antt_type || data?.anttType,
    id_owner: data?.id_owner || data?.idOwner,
    antt_adherence: data?.antt_adherence || data?.anttAdherence,
    antt: data?.antt,
  };
}

export function checkWagonData(data) {
  const anttNumberValidation = validAnttRegex.test(data?.antt);
  if (data?.vehicle_implement_type_name === 'Dolly') {
    const validation = wagonRequiredFields
      .filter(field => field !== 'vehicle_body_type_id')
      .filter(field => !data[field]);
    return validation?.length > 0 || !anttNumberValidation;
  }
  const validation = wagonRequiredFields.filter(field => !data[field]);

  return validation?.length > 0 || !anttNumberValidation;
}

export async function getUpdatedWagon(wagonId) {
  const getVehicleUrl = `/wagons/${wagonId}`;
  const getVehicle = await cooperplaceApi.get(getVehicleUrl);
  return getVehicle.data;
}

export async function getUpdatedVehicle(vehicleId) {
  const getVehicleUrl = `/vehicles/${vehicleId}`;
  const getVehicle = await api.get(getVehicleUrl);
  return getVehicle.data;
}

export const importWagonFromBenner = async plate => {
  const url = `wagons/importFromBenner?plate=${plate}`;
  const { data } = await cooperplaceApi.put(url);
  return data?.id;
};
