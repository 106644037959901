import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import api from 'services/api';
import {Modal, Button, Text, Select, Input} from 'v3/components';
import { useSnackbar } from 'v3/components/Snackbar';
import { fetchBranchOffices } from 'v3/pages/User/shared/controller';
import * as Controller from './renewPreLoadController';
import { validateDateTime } from 'v3/utils/functions';


export default function RenewPreLoad({ preLoad, show, handleClose, fetchPreLoads }) {
  const history = useHistory();
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [renewedLoadId, setRenewedLoadId] = useState(0);
  const [successfullyRenovated, setSuccessfullyRenovated] = useState(false);
  const [newDueDate, setNewDueDate] = useState(
    moment().add(1, 'day').format('YYYY-MM-DDTHH:mm')
  );
  const [reasonSelected, setReasonSelected] = useState({
    label: 'Ajuste de programação',
    value: '11 - Ajuste de programação',
  });
  const [reasonList, setReasonList] = useState([]);
  const [isConfirmButtonDisabled, setConfirmButtonDisabled] = useState(false);
  const [isBranchActive, setIsBranchActive] = useState(true);
  const [selectedNewBranch, setSelectedNewBranch] = useState(null);
  const [newBranchError, setNewBranchError] = useState('');
  const [errors, setErrors] = useState({});

  function close() {
    setNewDueDate(moment().add(1, 'day').format('YYYY-MM-DDTHH:mm'));
    setSuccessfullyRenovated(false);
    handleClose();
  }

  async function handleRenewLoad() {
    const renewLoadData = await Controller.renewLoad({
      setConfirmButtonDisabled,
      setSuccessfullyRenovated,
      newDueDate,
      reasonSelected,
      snackbar,
      preLoad,
      setLoading,
      selectedNewBranch,
      setNewBranchError,
      isBranchActive,
    });

    if (!renewLoadData) {
      return;
    }

    setRenewedLoadId(renewLoadData?.id);
  }

  useEffect(() => {
    async function fetchReasons() {
      try {
        const response = await api.get('load/cancel/reasons');
        setReasonList(response.data);
      } catch (error) {
        //
      }
    }
    if (show) {
      fetchReasons();
    }
  }, [show]);

  useEffect(() => {
    async function fetchActualBranchOffice() {
      try {
        setLoading(true);
        const { data } = await api.get(
          `branch-offices/${preLoad?.branchOfficeId}`
        );
        setIsBranchActive(data?.active);
      } catch {
        // catcher
      } finally {
        setLoading(false);
      }
    }
    if (show && preLoad?.branchOfficeId) {
      fetchActualBranchOffice();
    }
  }, [preLoad, show]);

  return (
    <Modal
      size="md"
      backdrop="static"
      closeButton={false}
      show={show}
      onHide={() => close}
      heading={
        <Text type="subheader" color="dark" weight="500">
          Renovação da pré carga - {preLoad?.id}
        </Text>
      }
      body={
        successfullyRenovated ? (
          <Text color="dark" type="regular" weight="500">
            Pré Carga renovada com sucesso!
          </Text>
        ) : (
          <>
            <Row>
              <Col xs={12} md={6}>
                <Text type="label" color="dark">
                  Data de vencimento atual
                </Text>
                <Text type="regular" color="gray" as="p" className="mt-3">
                  {moment(preLoad?.dueDate).format('DD/MM/YYYY HH:mm')}
                </Text>
              </Col>

              <Col xs={12} md={6} style={{ marginTop: 2 }}>
                <Input
                  type="datetime-local"
                  label="Nova data de vencimento *"
                  value={newDueDate}
                  onChange={event => {
                    let isValid = validateDateTime(event?.target?.value);
                    if (isValid) {
                      setNewDueDate(event.target.value);
                      setErrors(old => ({ ...old, dueDate: null }));
                    } else {
                      setErrors(old => ({ ...old, dueDate: 'Data inválida' }));
                      setNewDueDate(event.target.value);
                    }
                  }}
                  error={errors.dueDate}
                />
              </Col>

              <Col md={12} className="mt-3 mb-2">
                <Select
                  label="Motivo do cancelamento *"
                  options={reasonList}
                  value={reasonSelected}
                  onChange={value => setReasonSelected(value)}
                  getOptionLabel={option => option.label}
                  getOptionValue={option => option.value}
                />
              </Col>
              <Col md={12} className="mt-2">
                <Text type="label" color="dark">
                  Essa pré carga irá ser cancelada e uma nova será criada.
                </Text>
              </Col>
              <Col md={12} className="mt-2">
                {!isBranchActive && (
                  <>
                    <Text
                      type="label"
                      color="dark"
                      style={{ fontStyle: 'italic' }}
                    >
                      A filial associada a esta pré carga está atualmente
                      inativa. Por favor, escolha outra filial para continuar
                      com o processo de renovação.
                    </Text>
                    <Select.Async
                      label="Filial *"
                      onSearch={search => {
                        setNewBranchError('');
                        return fetchBranchOffices({
                          search,
                          userCompanyId: preLoad?.companyId,
                        });
                      }}
                      value={selectedNewBranch}
                      onChange={value => {
                        setNewBranchError('');
                        setSelectedNewBranch(value);
                      }}
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.id}
                      error={newBranchError}
                    />
                  </>
                )}
              </Col>
            </Row>
          </>
        )
      }
      footer={
        successfullyRenovated ? (
          <Col className="p-0">
            <Button
              className="mr-2 py-2"
              variant="white"
              outlined
              onClick={() => {
                fetchPreLoads();
                close();
              }}
            >
              <Text type="regular" color="dark" weight="500">
                Fechar
              </Text>
            </Button>

            <Button
              className="mr-2 py-2"
              variant="primary"
              onClick={() => {
                close();
                history.push(`/pre-cargas/${renewedLoadId}`);
              }}
            >
              <Text type="regular" color="white" weight="500">
                Visualizar pré carga renovada
              </Text>
            </Button>
          </Col>
        ) : (
          <>
            <Button
              className="py-2"
              variant="white"
              outlined
              onClick={() => close()}
            >
              <Text type="regular" color="dark" weight="500">
                Fechar
              </Text>
            </Button>
            <Button
              className="py-2"
              variant="primary"
              disabled={!reasonSelected || isConfirmButtonDisabled}
              onClick={() => handleRenewLoad()}
              loading={loading}
            >
              <Text type="regular" color="white" weight="500">
                Renovar
              </Text>
            </Button>
          </>
        )
      }
    />
  );
}
