import styled from 'styled-components';

export const StyledCell = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  color: gray;
  font-size: 0.75rem;
  white-space: pre-wrap;
`;
