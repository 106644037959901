import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export default function useQuery() {
  const { search } = useLocation();

  const query = useMemo(() => new URLSearchParams(search), [search]);

  return query;
}
