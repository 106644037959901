import React, { useState } from 'react';

import { Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { validatePermission } from 'actions/index';

import Modal from 'v3/components/Modal';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';

import { useCompany } from '../context';

function CreatedCompany({ isOpen, onClose }) {
  const company = useCompany();
  const history = useHistory();

  const [hasPermissionToCreate, setPermissionToCreate] = useState();

  useState(() => {
    setPermissionToCreate(validatePermission('CADASTRAR_EMPRESAS'));
  }, []);

  return (
    <>
      <Modal
        show={isOpen}
        onHide={onClose}
        heading={
          <Text type="subheader" color="dark">
            Cadastro de empresa
          </Text>
        }
        body={
          <Row className="mx-0 mt-1">
            <Text weight="400" color="dark">
              Empresa {company.data.name} cadastrada com sucesso!
            </Text>
          </Row>
        }
        footer={
          <Row md={12} className=" mt-1">
            <Button
              variant="white"
              className="mr-2 mt-1 py-2"
              outlined
              size="sm"
              onClick={() => history.push('/empresas')}
            >
              <Text weight="500">Ver listagem</Text>
            </Button>

            <Button
              className="mr-2 mt-1 py-2"
              variant="primary"
              size="sm"
              onClick={() => history.push(`/empresas/${company.data.id}`)}
            >
              <Text weight="500">Ver empresa</Text>
            </Button>

            {hasPermissionToCreate && (
              <Button
                variant="success"
                className="mr-2 mt-1"
                size="sm"
                onClick={() => {
                  window.location.reload(false);
                }}
              >
                <Text weight="500">Nova empresa</Text>
              </Button>
            )}
          </Row>
        }
      />
    </>
  );
}

export default CreatedCompany;
