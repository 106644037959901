import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Text } from 'v3/components';
import { useTravel } from 'v3/pages/Travel';
import { formatCurrency } from 'v3/utils/formatter';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { ToggleDiv } from '../styles';

export function HeaderDetails() {
  const travel = useTravel();

  const [toggleObs, setToggleObs] = useState({
    pcp: false,
    negotiator: false,
  });
  return (
    <Col md={12} className="my-2">
      <Row md={12}>
        <Col md={3} sm={12}>
          <Text type="label" color="dark">
            Cliente Tomador
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {travel?.data?.loads?.taker?.label
              ? travel?.data?.loads?.taker?.label
              : travel?.data?.loads?.taker?.socialName &&
                travel?.data?.loads?.taker?.cpfCnpj
              ? `${travel?.data?.loads?.taker?.socialName} - ${travel?.data?.loads?.taker?.cpfCnpj}`
              : 'Não informado'}
          </Text>
        </Col>
        <Col md={3} sm={12}>
          <Text type="label" color="dark">
            Produto
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {travel?.data?.loads?.product?.name || 'Não informado'}
          </Text>
        </Col>
        <Col md={3} sm={12}>
          <Text type="label" color="dark">
            Valor da Carga
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {travel?.data?.loads?.cargoValue
              ? formatCurrency(
                  travel?.data?.loads?.cargoValue,
                  travel?.data?.loads?.loadCurrency
                )
              : 'Não informado'}
          </Text>
        </Col>
        <Col md={3} sm={12}>
          <Text type="label" color="dark">
            Centro de Custo
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {travel?.data?.loads?.costCenter?.name || 'Não informado'}
          </Text>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row>
            <Col md={12}>
              <ToggleDiv
                onClick={() =>
                  setToggleObs(old => ({ ...old, pcp: !old?.pcp }))
                }
              >
                {!toggleObs?.pcp ? <FaArrowUp /> : <FaArrowDown />}
                <Text color="dark" weight={500}>
                  Observações PCP
                </Text>
              </ToggleDiv>
              {!toggleObs?.pcp ? (
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {travel?.data?.loads?.pcpObservation || 'Não informado'}
                </Text>
              ) : null}
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col md={12}>
              <ToggleDiv
                onClick={() =>
                  setToggleObs(old => ({
                    ...old,
                    negotiator: !old?.negotiator,
                  }))
                }
              >
                {!toggleObs?.negotiator ? <FaArrowUp /> : <FaArrowDown />}
                <Text color="dark" weight={500}>
                  Observações Negociador
                </Text>
              </ToggleDiv>
              {!toggleObs?.negotiator ? (
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {travel?.data?.negotiatorObservation || 'Não informado'}
                </Text>
              ) : null}
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
}
