import api from 'services/api';

export async function fetchShippingCompanies({ search, companyId }) {
  try {
    const response = await api.get(`persons/shipping-company`, {
      params: { search, company_id: companyId },
    });

    return response.data;
  } catch (ex) {
    return [];
  }
}

export async function fetchTags(search) {
  try {
    const response = await api.get('tags', {
      params: { search, not_paginated: true },
    });

    return response.data;
  } catch (err) {
    return [];
  }
}
