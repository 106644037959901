import React, { useState } from 'react';

import { Row, Col } from 'react-bootstrap';

import api from 'services/apis/report';
import FileSaver from 'file-saver';

import Input from 'v3/components/Input';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';

import { useSnackbar } from 'v3/components/Snackbar';

function Drivers() {
  const snackbar = useSnackbar();

  const [drivers, setDrivers] = useState();
  const [loading, setLoading] = useState(false);

  async function handleSubmit() {
    setLoading(true);
    try {
      const response = await api.get('driver', {
        params: { name: drivers },
        responseType: 'blob',
      });

      FileSaver.saveAs(response.data, response.headers['x-file-name']);
    } catch (error) {
      snackbar.show(
        <Text>
          {error?.response?.status === 404
            ? 'Nenhum dado encontrado com esse filtro.'
            : 'Algo deu errado, tente novamente mais tarde'}
        </Text>,
        {
          type: 'error',
        }
      );
    } finally {
      setLoading(false);
      setDrivers('');
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col xs={12}>
          <Input
            value={drivers}
            label="Motoristas"
            onChange={event => setDrivers(event.target.value)}
            placeholder="Nome"
          />
        </Col>
        <Col
          xs={12}
          className="d-flex justify-content-center align-items-center mt-4"
        >
          <Button onClick={() => handleSubmit()} loading={loading}>
            <Text type="regular" weight="semiBold">
              Gerar Planilha Excel
            </Text>
          </Button>
        </Col>
      </Row>
    </form>
  );
}

export default Drivers;
