/* eslint-disable import/no-unresolved */
import cargonApi from 'services/apis/cargon';

export async function send(id) {
  try {
    await cargonApi.post('load/send', { load_id: id });

    return {
      error: false,
      message: 'Carga enviada para Cargon com sucesso.',
    };
  } catch (err) {
    return {
      error: true,
      message: err.response.data.error
        ? err.response.data.error
        : 'Erro ao enviar carga para Cargon.',
    };
  }
}

export async function cancel(id) {
  try {
    await cargonApi.post('load/cancel', { load_id: id });

    return {
      error: false,
      message: 'Carga cancelada na Cargon com sucesso.',
    };
  } catch (err) {
    return {
      error: true,
      message:
        err?.response?.data?.details.mesage ||
        'Erro ao cancelar carga na Cargon.',
    };
  }
}
