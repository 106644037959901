import { Row } from 'react-bootstrap';
import styled from 'styled-components';
import Space from 'v3/components/Space';
import theme from 'v3/theme';

export const HeaderSpace = styled(Space)`
  > *:not(:last-child) {
    margin-right: 0.5rem;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    > * {
      margin-right: 0.5rem;
    }
  }
`;

export const FilterRow = styled(Row)`
  @media (max-width: 767px) {
    background-color: ${theme.colors.dark_blue};
    padding: 0.5rem;
    border-radius: 6px;
  }
`;
