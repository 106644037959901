/* eslint-disable import/no-unresolved */
import styled from 'styled-components';
import theme from 'v3/theme';

export const Container = styled.div`
  position: absolute;
  top: 41px;
  overflow: scroll;
  width: 300px;
  transform: translateX(-160px);
  max-height: 80vh;
`;

export const notificationContainer = styled.div`
  position: absolute;
  top: 41px;
  overflow-y: scroll;
  width: 300px;
  transform: translateX(-160px);
  max-height: 80vh;
`;

export const Notification = styled.div`
  cursor: pointer;
  transition: opacity 0.1s ease-in;
  border-bottom: 1px solid ${theme.colors.gray};
  background-color: ${theme.colors.light};
  padding: 15px;

  &:hover {
    opacity: 0.8;
  }

  &.is-read {
    background-color: #fff;

    &:hover {
      #text-hover {
        color: ${theme.colors.dark} !important;
      }
    }
  }
`;
