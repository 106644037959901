import api, { cooperplaceApi } from 'services/api';
import { capitalize } from 'v3/utils/formatter';

export async function fetchCostCenter(params) {
  try {
    const response = await api.get(`v3/cost-center`, {
      params,
    });

    return response.data;
  } catch (ex) {
    return [];
  }
}

export async function fetchUsers(search) {
  try {
    const response = await cooperplaceApi.get('users', {
      params: { search },
    });
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function fetchCustomers(params) {
  try {
    const response = await api.get('persons/customers', { params });
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function fetchCustomer(customerId) {
  try {
    const response = await api.get(`persons/customer/${customerId}`);
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function fetchClients(search) {
  try {
    const response = await api.get(`v3/company/1/client`, {
      params: { search },
    });

    return response.data;
  } catch (ex) {
    return [];
  }
}

export async function fetchVehiclesTypes() {
  try {
    const response = await api.get('vehicle-types?app=true', {
      params: { app: true },
    });

    return response.data;
  } catch (ex) {
    return [];
  }
}

export async function fetchTags(search) {
  try {
    const response = await api.get('tags', {
      params: { search, not_paginated: true },
    });

    return response.data;
  } catch (err) {
    return [];
  }
}

export async function fetchProducts(search) {
  try {
    const response = await api.get('product-types?', { params: { search } });
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function fetchProvinces(search) {
  try {
    const response = await api.get('provinces', { params: { search } });

    return response.data;
  } catch (ex) {
    return [];
  }
}

export async function fetchCities(search) {
  try {
    const response = await api.get('cities', { params: { search } });
    const provinces = await fetchProvinces(search);

    return [...provinces, ...response.data];
  } catch (ex) {
    return [];
  }
}

export async function fetchByCityAndProvince(city, province) {
  try {
    const response = await api.get('cities', {
      params: { search: city, uf: province },
    });
    return [...response.data];
  } catch (error) {
    return [];
  }
}

export async function fetchProductAndCategorie(search) {
  try {
    const response = await api.get('product', { params: { search } });
    return response?.data?.data ? response?.data?.data : response?.data;
  } catch (error) {
    return [];
  }
}

export async function fetchBranchOffices(search, isRouteStation = '') {
  try {
    const response = await api.get('branch-offices', {
      params: {
        search,
        isRouteStation,
      },
    });
    return response.data;
  } catch (error) {
    return [];
  }
}
export async function fetchUserCostCenters() {
  try {
    const userJson = JSON.parse(localStorage.getItem('usuario'));
    const response = await api.get(`cost-centers/user/${userJson.id}`);
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function fetchUser(userId) {
  try {
    const userJson = JSON.parse(localStorage.getItem('usuario'));
    const response = await api.get(`users/${userId || userJson.id}`);
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function fetchAnttTypes() {
  try {
    const response = await api.get('antt-types');
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function fetchPaymentMethods() {
  try {
    const response = await api.get('payment/methods?initialFetch=true');
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function fetchTrackers() {
  try {
    const response = await api.get('tracker/app');
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function fetchBodies() {
  try {
    const response = await api.get(`body-types?app=${true}`);
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function fetchAxis() {
  try {
    const response = await api.get('vehicle-axe', {
      params: { app: true },
    });
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function fetchFuel() {
  try {
    const response = await api.get('fuel');
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function fetchImplements() {
  try {
    const response = await api.get(`vehicle-implement-types?app=${true}`);
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function fetchAllProvinces() {
  try {
    const response = await api.get('provinces');
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function fetchOnlyCities(search) {
  try {
    const response = await api.get('cities', { params: { search } });
    return response.data;
  } catch (ex) {
    return [];
  }
}

export async function fetchBrands(search) {
  try {
    const response = await api.get(`manufacturers/select?search=${search}`);
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function fetchNegotiations(params) {
  try {
    const response = await cooperplaceApi.get(
      `negotiation?personId=${params.personId}&costCenterExternalId=${params.costCenterExternalId}&search=${params.search}`
    );
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function fetchAllNegotiationsByPersonAndCostCenter(params) {
  try {
    const response = await cooperplaceApi.get(
      `negotiation?personId=${params.personId}&costCenterExternalId=${params.costCenterExternalId}`
    );
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function fetchAllNegotiationsByPersonId(params) {
  try {
    const response = await cooperplaceApi.get(
      `negotiation/byPersonId?personId=${params.personId}`
    );
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function fetchGroupVehicles(search) {
  try {
    const response = await cooperplaceApi.get(`group-vehicles`, {
      params: { description: search },
    });

    return response.data;
  } catch (ex) {
    return [];
  }
}
export async function fetchAllDisponiblesOccurencesAditionalField() {
  try {
    const response = await cooperplaceApi.get(`occurrencesAditionalItens`);
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function fetchAditionalFieldsByOccurrence(occurrenceId) {
  const response = await cooperplaceApi.get(
    `occurrencesAditionalFields/${occurrenceId}`
  );
  return response.data;
}

export async function postAditionalFieldsInOccurrence(occurrenceId, params) {
  const response = await cooperplaceApi.post(
    `occurrencesAditionalFields/${occurrenceId}`,
    params
  );
  return response.data;
}

export async function fetchOccurencesLogs(occurrenceId) {
  const response = await cooperplaceApi.get(`occurrences/${occurrenceId}`);
  return response.data;
}

export async function fetchTravelStatus() {
  try {
    const response = await api.get('travel-status');
    return response.data;
  } catch (error) {
    return [];
  }
}
export async function fetchSelectOptions(url, params = {}) {
  try {
    const response = await api.get(url, { params });
    return response.data;
  } catch (err) {
    return [];
  }
}

export async function onSearchGroups(search) {
  try {
    const response = await api.get('groups', {
      params: { search, onlyActive: true },
    });
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function fetchOccurrencesTypesOptions() {
  try {
    const response = await cooperplaceApi.get('occurrences/types');
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function fetchTravelStatusOptions() {
  try {
    const response = await api.get('travel-status');
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function fetchUserCompaniesSelectOptions() {
  try {
    const { data } = await cooperplaceApi.get('user/companies');
    return data?.companies || [];
  } catch (error) {
    return [];
  }
}

export async function fetchUserCostCentersByCompany(search) {
  try {
    const response = await cooperplaceApi.get(
      `/cost-center/by-user-companies`,
      {
        params: { search },
      }
    );
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function fetchShippingCompanies({ search, companyId }) {
  try {
    const response = await api.get(`persons/shipping-company`, {
      params: { search, company_id: companyId },
    });

    return response.data;
  } catch (ex) {
    return [];
  }
}

export async function fetchVehicle(search) {
  try {
    const response = await api.get('vehicle/license_plate', {
      params: { search },
    });
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function fetchWagons(search) {
  try {
    const response = await api.get('plate/wagons', {
      params: { search },
    });
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function fetchDriver(search) {
  try {
    const response = await api.get('drivers/select', { params: { search } });
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function fetchRiskManagers(search) {
  try {
    const response = await api.get('risk-manager-for-select', {
      params: { search },
    });

    return response.data.map(riskManager => ({
      ...riskManager,
      name: capitalize(riskManager.name),
    }));
  } catch (ex) {
    return [];
  }
}

export async function fetchOwners(search) {
  try {
    const response = await api.get(`v3/persons/owner?search=${search}`);
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function fetchDispositionData(url = 'load-disposition') {
  try {
    const response = await api.get(url, { params: { app: true } });

    return response.data;
  } catch (ex) {
    return [];
  }
}

export async function fetchCountries() {
  try {
    const { data } = await api.get('countries');
    return data;
  } catch (err) {
    return [];
  }
}

export async function cooperplaceFetchSelectOptions(url, params = {}) {
  try {
    const response = await cooperplaceApi.get(url, { params });
    return response.data;
  } catch (err) {
    return [];
  }
}

export async function fetchTrackerTypes(search, tracker) {
  try {
    const response = await api.get(
      `filter-tracker-types?${
        tracker ? `tracker=${tracker.id}&` : ''
      }search=${search}`
    );
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function fetchNcmProducts(search) {
  try {
    const { data } = await cooperplaceApi.get(`/ncm`, {
      params: {
        search,
      },
    });
    return data?.data;
  } catch (error) {
    return [];
  }
}

export async function fetchProductsByPolicy(search, policyId, operationId) {
  try {
    const { data } = await cooperplaceApi.get(`/products/forSelect`, {
      params: {
        search,
        policyId,
        operationId,
      },
    });
    return data;
  } catch (error) {
    return [];
  }
}

export async function importFromBenner(document) {
  try {
    const url = `negotioation/importFromBenner?cpfCnpj=${document}`;
    const urlClient = `clients/importFromBenner?cpfCnpj=${document}`;
    await Promise.All([cooperplaceApi.get(url), cooperplaceApi.get(urlClient)]);
  } catch (error) {
    //
  }
}
