/* eslint-disable no-extra-boolean-cast */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import { useQuery, usePermission } from 'hooks';

import { validatePermission } from 'actions/index';
import api from 'services/api';

import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import ApplicationLayout from 'v3/layouts/Application';
import InfiniteScroll from 'v3/components/InfiniteScroll';
import { useSnackbar } from 'v3/components/Snackbar';
import BennerButton from 'v3/components/BennerButton';

import Filter from './Filter';
import Card from './Card';

function List() {
  const snackbar = useSnackbar();
  const query = useQuery();

  usePermission('VISUALIZAR_TRANSPORTADORAS', { redirect: true });

  const [loading, setLoading] = useState(false);
  const [shippingCompanies, setShippingCompanies] = useState([]);
  const [pagination, setPagination] = useState({
    lastPage: 1,
    page: 1,
    total: 0,
  });

  const hasPermissionToCreate = validatePermission('CADASTRAR_TRANSPORTADORA');
  const hasPermissionToImportFromBenner = validatePermission(
    'IMPORTAR_TRANSPORTADORA_BENNER'
  );

  async function fetchShippingCompanies(page = 1) {
    if (page === 1) {
      setLoading(true);
    }

    try {
      const response = await api.get(
        `v3/shipping-company?${query.toString()}`,
        {
          params: {
            page,
          },
        }
      );

      const { data, ...rest } = response.data;

      setPagination(rest);

      setShippingCompanies(oldShippingCompanies => {
        if (page === 1) {
          return data;
        }

        return [...oldShippingCompanies, ...data];
      });
    } catch (error) {
      snackbar.show(<Text>Algo deu errado, tente novamente mais tarde</Text>, {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }

  function renderItem(shippingCompany) {
    return <Card shippingCompany={shippingCompany} />;
  }

  return (
    <ApplicationLayout
      title="Transportadoras"
      RightComponent={
        <>
          {hasPermissionToImportFromBenner ? (
            <BennerButton
              endpoint={'shipping-company/importFromBenner'}
              title={'Importar Transportadora'}
              label={'CPF/CNPJ'}
              field={'cpfCnpj'}
              redirectTo={'/transportadoras'}
              itemToImport={null}
              items={null}
            />
          ) : null}

          {hasPermissionToCreate && (
            <>
              <Link to="/transportadoras/cadastrar">
                <Button variant="primary" className="mr-2">
                  <Text weight={500} color="white" type="regular">
                    Nova transportadora
                  </Text>
                </Button>
              </Link>
            </>
          )}
        </>
      }
    >
      <Filter fetchShippingCompanies={fetchShippingCompanies} />
      <InfiniteScroll
        loading={loading}
        data={shippingCompanies}
        renderItem={renderItem}
        onEndReach={() => {
          fetchShippingCompanies(pagination.page + 1);
        }}
        hasMore={pagination.page < pagination.lastPage}
        scrollThreshold={0.7}
      />
    </ApplicationLayout>
  );
}

export default List;
