import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom/';
import { cooperplaceApi } from 'services/api';
import { useSnackbar } from 'v3/components/Snackbar';
import ApplicationLayout from 'v3/layouts/Application';
import { BadgeActive, Button, DataTable, Fallback, Modal, Text } from 'v3/components';
import { DefaultLoadingComponent } from 'v3/components/List';
import { validatePermission } from 'actions';
import { Filter } from './Filter';
import { Link } from 'react-router-dom';
import { useModal } from 'hooks';
import { StyledFilesCol } from 'v3/pages/Travel/Register/Modals/UpdateDriver/styles';
import { Attachment } from 'v3/components/Attachment';
import { Tooltip } from 'v3/components';
import { BsFillQuestionCircleFill } from 'react-icons/bs';
import { formatDateTime } from 'v3/utils/formatter';

export function SystemLogsList() {
  const history = useHistory();
  const snackbar = useSnackbar();
  const hasPermissionToView = validatePermission('VISUALIZAR_LOGS');
  const [systemLogsData, setSystemLogsData] = useState([]);
  const [systemLogsFilter, setSystemLogsFilter] = useState([]);
  const [loading, setLoading] = useState(false);
  const [statusOptions, setStatusOptions] = useState([]);

  const [filter, setFilter] = useState({
    id: '' ,
    name: '',
    cpf: '',
    email: '',
    phone: '',
    status: ''
  });

  const [showModalDocuments, setShowModalDocuments] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState([]);
  const modalDocuments = useModal();

  async function filterData(logData, logFilter) {
    const logDataFiltered = logData.filter(item => {
      let locateId = true;
      const id = logFilter.id.replace(/[^0-9]/g, '').toString();
      if (item.user.id && id !== '') {
        locateId = item.user.id.toString().indexOf(id) !== -1;
      } else if (!item.user.id && id !== '') {
        locateId = false;
      }

      let locateName = true;
      const name = logFilter.name.toUpperCase();
      if (item.user.username && logFilter.name !== '') {
        locateName = item.user.username.toUpperCase().indexOf(name) !== -1;
      } else if (!item.user.username && name !== '') {
        locateName = false;
      }

      let locateCpf = true;
      const cpf = logFilter.cpf.replace(/[^0-9]/g, '').toString();
      if (item.user.cpf && cpf !== '') {
        const userCpf = item.user.cpf.replace(/[^0-9]/g, '').toString();
        locateCpf = userCpf.indexOf(cpf) !== -1;
      } else if (!item.user.cpf && cpf !== '') {
        locateCpf = false;
      }

      let locateEmail = true;
      const email = logFilter.email.toLowerCase();
      if (item.user.email && logFilter.email !== '') {
        locateEmail = item.user.email.toLowerCase().indexOf(email) !== -1;
      } else if (!item.user.email && email !== '') {
        locateEmail = false;
      }

      let locatePhone = true;
      const phone = logFilter.phone.replace(/[^0-9]/g, '').toString();
      if (item.user.phoneNumberUnmasked && phone !== '') {
        locatePhone = item.user.phoneNumberUnmasked.toString().indexOf(phone) !== -1;
      } else if (!item.user.phoneNumberUnmasked && phone !== '') {
        locatePhone = false;
      }

      let locateStatus = true;
      const status = logFilter.status.name;
      if (status !== '' && status === 'Sucesso') {
        locateStatus = item?.success;
      } else if (status !== '' && status === 'Erro') {
        locateStatus = !item?.success;
      } else if (status !== '' && (status === 'Sucesso' || status === 'Erro')) {
        locateStatus = false;
      }

      setLoading(false);
      return locateId && locateName && locateCpf && locateEmail && locatePhone && locateStatus;
    });

    setSystemLogsFilter(logDataFiltered);
  }

  async function fetchData() {
    try {
      setLoading(true);
      const { data } = await cooperplaceApi.get('systemlogs', {});
      setSystemLogsData(data);
      filterData(data, filter);
    } catch (error) {
      const message =
        error?.response?.data?.error ||
        'Não foi possível recuperar os registros';
      snackbar.show(<Text>{message}</Text>, {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }

  async function fetchSystemLogStatus() {
      const data = [
        {
          id:0,
          name:'Todos'
        },
        {
          id:1,
          name:'Sucesso'
        },
        {
          id:2,
          name:'Erro'
        }
      ]
      setStatusOptions(data);
  }

  function onFilterChange(params) {
    setFilter(params);
    filterData(systemLogsData, params);
  }

  useEffect(() => {
    if (hasPermissionToView) {
      fetchSystemLogStatus();
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (!hasPermissionToView) {
      snackbar.show(
        <Text>Você não tem permissão para visualizar os registros</Text>,
        { type: 'error' }
      );
      history.push('/');
    }
  }, [hasPermissionToView]);

  function onModalDocumentsOpen(item) {
    let itemParse = item;
    itemParse.input.selfie.data.full_path = `${process.env.REACT_APP_BASE_STORAGE_URL}${itemParse?.input?.selfie?.data?.container}/${itemParse?.input?.selfie?.data?.path}/${itemParse?.input?.selfie?.data?.name}`;
    itemParse.input.driverLicense.data.full_path = `${process.env.REACT_APP_BASE_STORAGE_URL}${itemParse?.input?.driverLicense?.data?.container}/${itemParse?.input?.driverLicense?.data?.path}/${itemParse?.input?.driverLicense?.data?.name}`;
    setSelectedDocument(itemParse);
    setShowModalDocuments(true);
    modalDocuments.open();
  }

  function onModalDocumentsClose() {
    setShowModalDocuments(false);
    modalDocuments.close();
    setSelectedDocument([]);
  }
  return (
    <ApplicationLayout title="Logs do Sistema" contentFluid>
      <Modal
        show={showModalDocuments}
        handleClose={() => onModalDocumentsClose()}
        heading="Documentos do Motorista"
        body={
          <Row>
              <Row>
                <StyledFilesCol>
                  <Text type="label" color="dark" weight={500}>
                    Documentos do Motorista {selectedDocument?.user?.username}
                  </Text>
                  <div>
                    {selectedDocument?.input?.driverLicense?.data?.name.indexOf('pdf') > 0 ? (
                      <Attachment
                      deleteOption={false}
                      attachment={{
                        id: selectedDocument?.input?.driverLicense?.data?.id,
                        full_path: `${process.env.REACT_APP_BASE_STORAGE_URL}${selectedDocument?.input?.driverLicense?.data?.container}/${selectedDocument?.input?.driverLicense?.data?.path}/${selectedDocument?.input?.driverLicense?.data?.name}`
                        ,
                        due_date: selectedDocument?.input?.driverLicense?.data?.createdAt,
                      }}
                      attachments={[selectedDocument?.input?.driverLicense?.data]}
                      loading={false}
                      />
                      ) : <Attachment
                      deleteOption={false}
                      attachment={{
                        id: selectedDocument?.input?.driverLicense?.data?.id,
                        full_path: selectedDocument?.input?.driverLicense?.data?.full_path,
                        due_date: selectedDocument?.input?.driverLicense?.data?.createdAt,
                      }}
                      attachments={[selectedDocument?.input?.driverLicense?.data]}
                      loading={false}
                      />
                    }
                    <Attachment
                    deleteOption={false}
                    attachment={{
                      id: selectedDocument?.input?.selfie?.data?.id,
                      full_path: selectedDocument?.input?.selfie?.data?.full_path,
                      due_date: selectedDocument?.input?.selfie?.data?.createdAt,
                    }}
                    attachments={[selectedDocument?.input?.selfie?.data]}
                    loading={false}
                    />
                  </div>
                </StyledFilesCol>
              </Row>
            </Row>
        }
        footer={
          <Row style={{ justifyContent: 'flex-end' }}>
            <Button
              variant="primary"
              onClick={() => onModalDocumentsClose()}
              loading={loading}
            >
              <Text color="white" weight={500}>
                Fechar
              </Text>
            </Button>
          </Row>
        }
      />
      <Filter
        onRefresh={fetchData}
        onFilterChange={onFilterChange}
        statusOptions={statusOptions}
        loading={loading}
        setLoading={setLoading}
      />
      <Row>
        <Col className="my-5">
          <Fallback
            fall={systemLogsFilter?.length === 0}
            component={
              loading ? (
                <DefaultLoadingComponent />
              ) : (
                <Col xs={12} className="d-flex justify-content-center my-5">
                  <Button disabled>
                    <Text>Nenhum registro encontrado.</Text>
                  </Button>
                </Col>
              )
            }
          >
            <DataTable
              data={systemLogsFilter}
              loading={loading}
              withFilterOption={false}
              withPagination={false}
              columns={[
                {
                  name: 'ID Log',
                  selector: row => row?.id,
                },
                {
                  name: 'Status',
                  selector: row =>{
                    if (row?.success) {
                      return (
                        <BadgeActive pill light type="success" className="mr-2">
                          <Text type="label" weight={500}>
                            SUCESSO
                          </Text>
                        </BadgeActive>
                      )
                    } else {
                      return (
                        <Tooltip
                          content={
                            <Text type="label">
                              {row?.output?.error?.error?.message ? row?.output?.error?.error?.message : row?.output?.error}
                            </Text>
                          }
                          placement="top"
                        >
                          <BadgeActive pill light type="error" className="mr-2">
                            <Text weight={500} type="label">
                              ERRO <BsFillQuestionCircleFill />
                            </Text>
                          </BadgeActive>
                        </Tooltip>
                      )
                    }
                  },
                },
                {
                  name: 'Usuario',
                  selector: row => (
                    <Link to={`/usuarios/${row.user_id}`}>
                      {row?.user?.id}
                    </Link>
                  )
                },
                {
                  name: 'Motorista',
                  selector: row => (
                    <Link to={`/motoristas/${row?.user?.person.id}`}>
                      {row?.user?.username}
                    </Link>
                  )
                },
                {
                  name: 'CPF',
                  selector: row => row?.user?.cpf,
                },
                {
                  name: 'E-mail',
                  selector: row => row?.user?.email,
                },
                {
                  name: 'Telefone',
                  selector: row => row?.user?.phonenumber,
                },
                {
                  name: 'Data',
                  selector: row => formatDateTime(row.createdAt),
                },
                {
                  selector: row => (
                    <Button onClick={() => onModalDocumentsOpen(row)}>
                      Documentos
                    </Button>
                  ),
                },
              ]}
            />
          </Fallback>
        </Col>
      </Row>
    </ApplicationLayout>
  );
}
