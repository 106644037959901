import api, { cooperplaceApi } from 'services/api';
import {
  clientAddressRequiredFields,
  driverRequiredFields,
  shippingCompanyRequiredData,
  trackedVehicleRequiredFiels,
  validAnttRegex,
  vehicleRequiredFields,
  wagonRequiredFields,
} from 'v3/utils/constants';

export async function checkIfBlacklisted(
  cgccpf,
  onBlacklistedCallback,
  onApiErrorCallback
) {
  let res;
  try {
    res = await api.get(`v3/blacklist-person?value=${cgccpf}`);
  } catch {
    onApiErrorCallback();
  } finally {
    const blacklistInfo = res?.data?.data?.[0];
    const isBlacklisted = blacklistInfo != null;
    if (isBlacklisted) {
      onBlacklistedCallback(blacklistInfo);
    }
    return isBlacklisted;
  }
}

export function checkShippingCompanyData(data) {
  if (data) {
    const check = shippingCompanyRequiredData.filter(field => !data[field]);
    return !!check[0];
  }
  return false;
}

export function checkDriver(data) {
  if (data) {
    const tested = driverRequiredFields.filter(field => !data[field]);
    if (
      !data?.driver?.payment_method ||
      !!tested[0] ||
      !data?.driver?.validated
    ) {
      return true;
    }
  }
  return false;
}

export function checkVehicleData(data) {
  const validation = vehicleRequiredFields.filter(field => !data[field]);

  const anttNumberValidation = validAnttRegex.test(data?.antt);

  let trackValidation = null;
  const shouldValidatedTracker = data?.tracked;
  if (shouldValidatedTracker) {
    trackValidation = trackedVehicleRequiredFiels.filter(field => !data[field]);

    return (
      validation?.length > 0 ||
      !anttNumberValidation ||
      trackValidation?.length > 0
    );
  }
  return validation?.length > 0 || !anttNumberValidation;
}

export function checkWagonData(data) {
  const anttNumberValidation = validAnttRegex.test(data?.antt);
  if (data?.vehicle_implement_type_name === 'Dolly') {
    const validation = wagonRequiredFields
      .filter(field => field !== 'vehicle_body_type_id')
      .filter(field => !data[field]);
    return validation?.length > 0 || !anttNumberValidation;
  }
  const validation = wagonRequiredFields.filter(field => !data[field]);

  return validation?.length > 0 || !anttNumberValidation;
}

export function checkClientAddressData(data) {
  if (data) {
    const tested = clientAddressRequiredFields.filter(field => !data[field]);
    if (!!tested[0]) {
      return true;
    }
  }
  return false;
}

export async function importFromBenner(url) {
  try {
    const { data } = await cooperplaceApi.get(url);
    return data?.id ?? null;
  } catch (error) {
    throw error;
  }
}
