import React from 'react';
import { Link } from 'react-router-dom';

import { Row, Col } from 'react-bootstrap';

import Text from 'v3/components/Text';
import Card from 'v3/components/Card';

function RecordCard({ user }) {
  function Header() {
    return (
      <Col xs={6} className="d-flex flex-column justify-content-around">
        <div className="mb-2">
          <Text type="regular" color="dark">
            Cadastros aprovados: {user.approved_records}
          </Text>
        </div>
        <div>
          <Text type="regular" color="dark">
            Cadastros reprovados: {user.reproved_records}
          </Text>
        </div>
        <div>
          <Text type="regular" color="dark">
            Total de cadastros: {user.total_records}
          </Text>
        </div>
      </Col>
    );
  }

  return (
    <Col xs={6}>
      <Card>
        <Row>
          <Col xs={6} className="mb-3">
            <div className="mb-2">
              <Link to={`/usuarios/${user.id}`}>
                <Text weight={600} color="primary" type="subtitle">
                  {user?.username || ''}
                </Text>
              </Link>
            </div>
            <div style={{ wordBreak: 'break-all' }}>
              <Text weight={400} color="secundary" type="regular">
                {user?.email}
              </Text>
            </div>
          </Col>
          <Header />
        </Row>
      </Card>
    </Col>
  );
}

export default RecordCard;
