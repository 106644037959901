import api from 'services/api';
import { validate } from '../shared/validator';

export async function handleCreation(
  data,
  minimumFreightTableItem,
  setData,
  setErrors,
  showSnack,
  setLoading,
  setShowModal
) {
  const fixedRate = data?.fixedRate;
  delete data?.fixedRate;
  const isValid = await validate(
    'allSchema',
    {
      data,
      charge: {
        charge_type: data?.charge_type?.value,
        charge_price: data?.charge_price || data.priceRanges[0].value,
        priceRanges: data?.priceRanges,
      },
      route: data,
      taxes: data,
    },
    setErrors
  );

  if (!isValid) {
    showSnack('Verifique os campos obrigatórios!', { type: 'error' });
    return false;
  }

  try {
    setLoading(true);
    let destinations = data.destinations.map(d => ({
      ...d,
      country_slug: d.country_abbreviation,
    }));
    const payload = {
      data: {
        person_id: data.person?.id,
        antt_id: minimumFreightTableItem.id,
        qty_axis: minimumFreightTableItem.qty_axys,
        is_client: data.product_type === 1,
        product_id: data.product?.id,
        issuer_id: data.issuer?.id,
        charge_type: data.charge_type?.value,
        charge_price: data.charge_price || data.priceRanges[0].value,
        external_value:
          data.charge_type?.value === 2
            ? data.priceRanges[0].external_value
            : data.charge_type?.value === 5
            ? data.priceRanges[0].external_value
            : data.external_value,
        insurance_value: data.insurance_value,
        toll_value: data.toll_value,
        is_toll_payer_client: data?.is_toll_payer_client,
        loading_charge: data.loading_charge,
        discharge_price: data.discharge_price,
        gris: data.gris,
        ad_valorem: data.ad_valorem,
        load_type: data.loadType?.id,
        polyline: data.polyline,
        id_rota_raster: data?.id_rota_raster,
        currency: data.currency.value,
        exchange: fixedRate ? data?.exchange : null,
      },
      parameters: data.parameters,
      priceRanges: data?.priceRanges,
      origin: {
        ...data.origin,
        country_slug: data.origin.country_abbreviation,
      },
      destinations: destinations,
      tags: data.tags?.map(tag => tag.id) || [],
      vehicles: data.vehicles?.map(vehicle => vehicle.id) || [],
      kilometers: data?.distance,
    };
    const response = await api.post('v3/route', payload);

    setData({ id: response.data.id });
    setLoading(false);
    setShowModal(true);
    return true;
  } catch (err) {
    setLoading(false);

    const errors = err?.response?.data;
    if (errors && Array.isArray(errors)) {
      setErrors(old => ({
        ...old,
        ...Object.fromEntries(
          errors?.map(error => [error?.field, error?.message])
        ),
      }));
    }

    showSnack(err.response?.data?.message || 'Erro ao salvar rota!', {
      type: 'error',
    });
    return false;
  }
}
