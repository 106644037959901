import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import { validatePermission } from 'actions/index';

import Modal from 'v3/components/Modal';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';

import { useShippingCompany } from '../../shared/context';

function Registered({ isOpen, onClose }) {
  const shippingCompany = useShippingCompany();

  const [hasPermissionToCreate] = useState(
    validatePermission('CADASTRAR_TRANSPORTADORAS')
  );

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      heading="Cadastro de transportadora"
      size="lg"
      body={
        <Row className="mx-0 mt-1">
          <Text weight="400" color="dark">
            Transportadora {shippingCompany.data.social_name} cadastrada com
            sucesso!
          </Text>
        </Row>
      }
      footer={
        <Row xs={12} className="mr-3 mt-1">
          <Link to="/transportadoras">
            <Button variant="white" className="mr-2 mt-1" outlined size="sm">
              <Text weight="500">Ver listagem</Text>
            </Button>
          </Link>

          <Link to={`/transportadoras/${shippingCompany?.data.id}`}>
            <Button className="mr-2 mt-1" variant="primary" size="sm">
              <Text weight="500">Ver transportadora</Text>
            </Button>
          </Link>

          {hasPermissionToCreate && (
            <Button
              variant="success"
              className="mr-2 mt-1"
              size="sm"
              onClick={() => {
                window.location.reload(false);
              }}
            >
              <Text weight="500">Nova transportadora</Text>
            </Button>
          )}
        </Row>
      }
    />
  );
}

export default Registered;
