import React, { useCallback, useState } from 'react';
import {v4} from 'uuid'
import {
  AddAttachmentHeaderContainer,
  AttachmentItemContainer,
  AttachmentItemsContainer,
  SectionContainer,
} from '../styles';
import Text from 'v3/components/Text';
import { OccurrenceDescriptionsContainer } from '../OccurrenceInfo/styles';
import { FaTrash } from 'react-icons/fa';
import Button from 'v3/components/Button';
import Fallback from 'components/Fallback';
import theme from 'v3/theme';
import { OccurrenceAttachmentModal } from '../AttachmentsModal';

export function AddAttachment({ files, setFiles, loading }) {
  const [showModal, setShowModal] = useState(false);
  const handleRemove = useCallback(
    (index, innerIndex = null) => {
      if(innerIndex !== null){
        const updated = files.map((file,idx) => {
          if(idx === index){
            const filtered = file?.file?.filter((_, innexIdx) => {
              return innexIdx !== innerIndex;
            });
            return {...file, file: [...filtered]}
          }
          return file
        })
        setFiles(updated)
      } else {
        const updated = files.filter((_, idx) => {
          return idx !== index;
        });
        setFiles(updated);
      }
    },
    [files]
  );
  return (
    <>
      <OccurrenceAttachmentModal
        isOpen={showModal}
        handleClose={() => setShowModal(false)}
        setFiles={setFiles}
      />
      <SectionContainer>
        <AddAttachmentHeaderContainer>
          <Text type="header" color="dark" weight={500}>
            Anexos
          </Text>
          <Button
            disabled={loading || files?.length === 20}
            size="sm"
            onClick={() => setShowModal(true)}
          >
            Adicionar anexo
          </Button>
        </AddAttachmentHeaderContainer>
        <Fallback
          fall={files?.length === 0}
          component={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Text color={theme.colors.gray}>Nenhum anexo selecionado.</Text>
            </div>
          }
        >
          <OccurrenceDescriptionsContainer>
            <AttachmentItemsContainer>
              {files?.map((file, index) => {
                return (
                  <AttachmentItem
                    index={index}
                    key={file?.file?.name}
                    handleRemove={handleRemove}
                    file={file}
                    loading={loading}
                  />
                );
              })}
            </AttachmentItemsContainer>
          </OccurrenceDescriptionsContainer>
        </Fallback>
      </SectionContainer>
    </>
  );
}

function AttachmentItem({ file, handleRemove, index, loading }) {
  if(file?.file?.length > 1){
    return file?.file?.map((item, idx) => {
      let itemKey = v4()
      return (
        <AttachmentItemContainer key={itemKey}>
          <Text type="regular" color="dark">
            {`${file?.description} - ${item?.name}`}
          </Text>
          <button
            disabled={loading}
            onClick={() => handleRemove(index,idx)}
            title="remover anexo"
          >
            <FaTrash />
          </button>
        </AttachmentItemContainer>
      );
    })
  } else
  return (
    <AttachmentItemContainer>
      <Text type="regular" color="dark">
        {`${file?.description} - ${file?.file?.[0]?.name}`}
      </Text>
      <button
        disabled={loading}
        onClick={() => handleRemove(index)}
        title="remover anexo"
      >
        <FaTrash />
      </button>
    </AttachmentItemContainer>
  );
}
