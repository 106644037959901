import styled, { css } from 'styled-components';
import theme from '../../theme';

export const UnorderedList = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: -5px;
`;

export const ListItem = styled.li`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;

  border-left: 1px ${theme.colors.medium_gray} dashed;
  position: relative;
  padding-left: 15px;

  & > span {
    &:first-child {
      margin-top: -6px;
    }
  }

  &::before {
    content: ' ';
    background-size: cover;
    position: absolute;
    top: 0px;

    ${({ filledCircle }) =>
      filledCircle
        ? css`
            background: ${theme.colors.medium_gray};
            width: 10px;
            height: 10px;
            left: -6px;
            border-radius: 5px;
          `
        : css`
            background: white;
            border-style: solid;
            border-color: ${theme.colors.medium_gray};
            border-width: 1px;
            width: 10px;
            height: 10px;
            left: -6px;
            border-radius: 5px;
          `}

    ${({ link }) =>
      link &&
      css`
        background: ${theme.colors.light_blue};
        width: 6px;
        height: 6px;
        left: -3.5px;
        border-radius: 3px;
        top: 1px;
      `}
  }

  &:last-child {
    border-left: 0px gray solid;
    padding-left: 18px;

    &::before {
      left: -4px;
    }
  }
`;
