import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { useModal } from 'hooks';
import { cooperplaceApi } from 'services/api';
import {
  fetchAllNegotiationsByPersonId,
  fetchImplements,
  fetchSelectOptions,
} from 'utils/fetches';
import {
  fetchBodies,
  fetchVehicles,
} from 'v3/pages/Load/Register/Vehicle/controller';
import CheckBox from 'components/CheckBox';
import { Button, Card, Input, Select, Text } from 'v3/components';
import Space from 'v3/components/Space';
import {
  StyledCheckboxOptionsCol,
  StyledChecklistInfoRow,
  StyledFooterRow,
  StyledTextArea,
  StyledTextAreaCol,
} from '../../components/RegisterComponents/styles';
import { SortableSection } from '../../components/RegisterComponents/SortableSection';
import { QuestionsModal } from '../../components/RegisterComponents/QuestionsModal';
import { useChecklist } from '../context';

export function ChecklistData({ handleSave, isLoading, handleCancel }) {
  const checklist = useChecklist();
  const params = useParams();
  const location = useLocation();
  const questionModal = useModal();
  const [questions, setQuestions] = useState([]);
  const [negotiationOptions, setNegotiationOptions] = useState([]);
  const [loadingNegotiations, setLoadingNegotiations] = useState(false);
  const [vehiclesOptions, setVehiclesOptions] = useState([]);
  const [bodyOptions, setBodyOptions] = useState([]);
  const [implementsTypes, setImplementsTypes] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [branchOfficesOptions, setBranchOfficesOptions] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [renderImplement, setRenderImplement] = useState(false);

  useEffect(() => {
    if (!params?.id) {
      checklist.setData({
        required: false,
        default: false,
        active: true,
        name: '',
        description: '',
        checklistType: null,
        clients: [],
        negotiation: null,
        vehicleType: [],
        vehicleBodyType: [],
        branchOffices: [],
        implementType: [],
      });
    } else {
      setQuestions(checklist?.data?.questions);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  async function fetchAllNegotiationsByClient(clientId) {
    try {
      setLoadingNegotiations(true);
      const response = await fetchAllNegotiationsByPersonId({
        personId: clientId,
      });
      setNegotiationOptions(response);
    } catch (error) {
      setNegotiationOptions([]);
    } finally {
      setLoadingNegotiations(false);
    }
  }

  async function getVehicleTypes() {
    const vehicles = await fetchVehicles();
    setVehiclesOptions(vehicles);
  }

  async function getImplementTypes() {
    const response = await fetchImplements();
    setImplementsTypes(response);
  }

  async function getVehicleBodyTypes() {
    const bodies = await fetchBodies();
    setBodyOptions(bodies);
  }

  async function getChecklistTypes() {
    const response = await cooperplaceApi.get('checklists-types');
    setTypeOptions(response.data);
  }

  async function getUserBranchOffices() {
    const {
      data: { branchOffices },
    } = await cooperplaceApi.get('branch-offices/by-user');
    setBranchOfficesOptions(branchOffices);
  }

  async function fetchInitalOptions() {
    try {
      await Promise.all([
        getVehicleTypes(),
        getVehicleBodyTypes(),
        getChecklistTypes(),
        getUserBranchOffices(),
        getImplementTypes(),
      ]);
    } catch (error) {
      checklist.showSnackbar(
        'Não foi possível carregar todas as opções de seleções. Recarregue a página',
        {
          type: 'error',
          showClose: true,
        }
      );
    }
  }

  useEffect(() => {
    fetchInitalOptions();
  }, []);

  useEffect(() => {
    if (checklist?.data?.clients?.length === 1) {
      fetchAllNegotiationsByClient(checklist?.data?.clients[0]?.id);
    }
  }, [checklist?.data?.clients]);

  function handleSearchType(search) {
    if (!search) {
      return typeOptions;
    }
    const parsed = search.toLowerCase();
    return typeOptions.filter(
      option => option.name.toLowerCase().search(parsed) >= 0
    );
  }

  function handleChangeCnpjs(value) {
    if (value === null || value?.length > 1) {
      checklist.setData(old => ({ ...old, clients: value, negotiation: null }));
      setNegotiationOptions([]);
    } else
      checklist.setData(old => ({
        ...old,
        clients: value,
      }));
    if (Object.keys(checklist.errors).length > 0) {
      checklist.setErrors({});
    }
  }

  function handleChangeVehicleType(value) {
    setRenderImplement(false);
    if (value === null) {
      checklist.setData(old => ({
        ...old,
        vehicleType: [],
        vehicleBodyType: [],
        implementType: [],
      }));
      return;
    }

    const mechanicalHorse = value.find(
      ({ name }) => name === 'Cavalo Mecânico'
    );

    if (mechanicalHorse) {
      setRenderImplement(
        checklist?.data?.checklistType?.name === 'Checklist veículos'
      );
      checklist.setData(old => ({
        ...old,
        vehicleType: [mechanicalHorse],
        implementType: [],
      }));
    } else {
      checklist.setData(old => ({
        ...old,
        vehicleType: value,
        implementType: [],
      }));
    }

    if (Object.keys(checklist.errors).length > 0) {
      checklist.setErrors({});
    }
  }

  function handleOnChangeDefaultChecklist() {
    if (checklist?.data?.default === false) {
      checklist.setData(old => ({
        ...old,
        default: !old?.default,
        clients: [],
        negotiation: null,
      }));
    } else checklist.setData(old => ({ ...old, default: !old?.default }));
    if (Object.keys(checklist.errors).length > 0) {
      checklist.setErrors({});
    }
  }

  function handleChangeBranchOffices(value) {
    checklist.setData(old => ({ ...old, branchOffices: value || [] }));
    if (Object.keys(checklist.errors).length > 0) {
      checklist.setErrors({});
    }
  }
  const defaultChecklistBlocks = checklist?.data?.default;
  const shouldDisableNegotiation = checklist?.data?.clients?.length > 1;

  useEffect(() => {
    const mechanicalHorse = (checklist?.data?.vehicleType || []).find(
      ({ name }) => name === 'Cavalo Mecânico'
    );

    setRenderImplement(
      checklist?.data?.checklistType?.name === 'Checklist veículos' &&
        !!mechanicalHorse
    );
  }, [checklist?.data?.checklistType]);

  return (
    <>
      <Card
        header={<Text type="header">Checklist</Text>}
        HeaderRightComponent={
          <Button onClick={() => questionModal?.open()}>
            <Text type="regular" weigth={500}>
              Incluir pergunta
            </Text>
          </Button>
        }
        loading={isLoading || checklist.loading}
      >
        <Row className="mb-3">
          <StyledCheckboxOptionsCol>
            <CheckBox
              label="Obrigatório"
              checked={checklist?.data?.required}
              value={checklist?.data?.required}
              onChange={() => {
                checklist.setData(old => ({
                  ...old,
                  required: !old?.required,
                }));
                if (Object.keys(checklist.errors).length > 0) {
                  checklist.setErrors({});
                }
              }}
              labelStyle={{
                textTransform: 'capitalize',
                color: '#464E5F',
                width: 'auto',
                fontFamily: 'Poppins',
              }}
              labelWeight="500"
            />

            <CheckBox
              label="Checklist padrão filial"
              checked={checklist?.data?.default}
              value={checklist?.data?.default}
              onChange={() => handleOnChangeDefaultChecklist()}
              labelStyle={{
                textTransform: 'capitalize',
                color: '#464E5F',
                width: 'auto',
                fontFamily: 'Poppins',
              }}
              labelWeight="500"
            />
            {!location.pathname.includes('cadastrar') && (
              <CheckBox
                label="Ativo"
                checked={checklist?.data?.active}
                value={checklist?.data?.active}
                onChange={() => {
                  checklist.setData(old => ({ ...old, active: !old?.active }));
                  if (Object.keys(checklist.errors).length > 0) {
                    checklist.setErrors({});
                  }
                }}
                labelStyle={{
                  textTransform: 'capitalize',
                  color: '#464E5F',
                  width: 'auto',
                  fontFamily: 'Poppins',
                }}
                labelWeight="500"
              />
            )}
            <CheckBox
              label="Motorista responde pelo APP"
              checked={checklist?.data?.availableForDriver}
              value={checklist?.data?.availableForDriver}
              onChange={() => {
                checklist.setData(old => ({
                  ...old,
                  availableForDriver: !old?.availableForDriver,
                  requiredDriverSign: !old?.availableForDriver
                    ? old.requiredDriverSign
                    : false,
                }));
                if (Object.keys(checklist.errors).length > 0) {
                  checklist.setErrors({});
                }
              }}
              labelStyle={{
                textTransform: 'capitalize',
                color: '#464E5F',
                width: 'auto',
                fontFamily: 'Poppins',
              }}
              labelWeight="500"
            />
            <CheckBox
              label="Disponibilizar no Painel Logístico"
              checked={checklist?.data?.availableLogisticPanel}
              value={checklist?.data?.availableLogisticPanel}
              onChange={() => {
                checklist.setData(old => ({
                  ...old,
                  availableLogisticPanel: !old?.availableLogisticPanel,
                }));
                if (Object.keys(checklist.errors).length > 0) {
                  checklist.setErrors({});
                }
              }}
              labelStyle={{
                textTransform: 'capitalize',
                color: '#464E5F',
                width: 'auto',
                fontFamily: 'Poppins',
              }}
              labelWeight="500"
            />
          </StyledCheckboxOptionsCol>
          <StyledCheckboxOptionsCol style={{ alignItems: 'start' }}>
            {checklist?.data?.availableForDriver && (
              <CheckBox
                label="Requer assinatura do motorista"
                checked={checklist?.data?.requiredDriverSign}
                value={checklist?.data?.requiredDriverSign}
                onChange={() => {
                  checklist.setData(old => ({
                    ...old,
                    requiredDriverSign: !old?.requiredDriverSign,
                  }));
                  if (Object.keys(checklist.errors).length > 0) {
                    checklist.setErrors({});
                  }
                }}
                labelStyle={{
                  textTransform: 'capitalize',
                  color: '#464E5F',
                  width: 'auto',
                  fontFamily: 'Poppins',
                }}
                labelWeight="500"
              />
            )}
            <CheckBox
              label="Requer assinatura do inspetor"
              checked={checklist?.data?.requiredInspectSign}
              value={checklist?.data?.requiredInspectSign}
              onChange={() => {
                checklist.setData(old => ({
                  ...old,
                  requiredInspectSign: !old?.requiredInspectSign,
                }));
                if (Object.keys(checklist.errors).length > 0) {
                  checklist.setErrors({});
                }
              }}
              labelStyle={{
                textTransform: 'capitalize',
                color: '#464E5F',
                width: 'auto',
                fontFamily: 'Poppins',
              }}
              labelWeight="500"
            />
          </StyledCheckboxOptionsCol>
        </Row>
        <StyledChecklistInfoRow>
          <Col md={6} sm={12}>
            <Input
              label="Título *"
              value={checklist?.data?.name}
              onChange={e => {
                checklist.setData(old => ({ ...old, name: e?.target?.value }));
                if (Object.keys(checklist.errors).length > 0) {
                  checklist.setErrors({});
                }
              }}
              error={checklist?.errors?.name}
            />
            <Select.Async
              label="Tipo de checklist *"
              value={checklist?.data?.checklistType}
              onChange={value => {
                checklist.setData(old => {
                  const isVehicleChecklistSelected =
                    value?.name === 'Checklist veículos';
                  return {
                    ...old,
                    checklistType: value,
                    implementType: isVehicleChecklistSelected
                      ? old.implementType
                      : [],
                  };
                });
                if (Object.keys(checklist.errors).length > 0) {
                  checklist.setErrors({});
                }
              }}
              onSearch={search => handleSearchType(search)}
              getOptionLabel={option => option?.name}
              getOptionValue={option => option?.id}
              options={typeOptions}
              error={checklist?.errors?.type}
            />
          </Col>
          <StyledTextAreaCol>
            <StyledTextArea
              label="Descrição do checklist *"
              value={checklist?.data?.description}
              onChange={e => {
                const content = e?.target?.value;
                checklist.setData(old => ({
                  ...old,
                  description: content,
                }));
                if (Object.keys(checklist.errors).length > 0) {
                  checklist.setErrors({});
                }
              }}
              containerStyle={{ display: 'flex', flexDirection: 'column' }}
              containerInsideStyle={{ flex: 1 }}
              error={checklist?.errors?.description}
            />
          </StyledTextAreaCol>
        </StyledChecklistInfoRow>
        <Row className="my-3">
          <Col md={6} sm={12}>
            <Select.Async
              label="CNPJ"
              value={checklist?.data?.clients}
              placeholder="CNPJ"
              multiple
              horizontal={windowWidth <= 992}
              modalHeading="CNPJs"
              modalBodyTitle="Selecione um cnpj"
              onSearch={search =>
                fetchSelectOptions('persons/customers', {
                  search,
                })
              }
              onChange={value => handleChangeCnpjs(value)}
              getOptionLabel={option => {
                if (option?.label) {
                  return option?.label;
                }
                return `${option?.socialName} - ${option?.cpfCnpj}`;
              }}
              getOptionValue={option => option.id}
              selectValuesContainerMaxHeight="128px"
              selectValuesContainerOverflowY="scroll"
              error=""
              isDisabled={defaultChecklistBlocks}
              tooltip={
                defaultChecklistBlocks &&
                'Checklist padrão não aceita cadastro de cnpjs'
              }
            />
          </Col>
          <Col md={6} sm={12}>
            <Select
              label="Negociação"
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              value={checklist?.data.negotiation}
              options={negotiationOptions}
              onChange={value => {
                checklist.setData(old => ({ ...old, negotiation: value }));
                if (Object.keys(checklist.errors).length > 0) {
                  checklist.setErrors({});
                }
              }}
              noOptionsMessageText="Nenhuma negociação disponível"
              loading={loadingNegotiations}
              isDisabled={shouldDisableNegotiation || defaultChecklistBlocks}
              tooltip={
                defaultChecklistBlocks
                  ? 'Checklist padrão não aceita cadastro de negociação'
                  : shouldDisableNegotiation
                  ? 'Grupo de CNPJ não aceita cadastro de negociação'
                  : ''
              }
              error={checklist?.errors?.negotiation}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={12}>
            <Select
              label="Tipo de veículo"
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              options={vehiclesOptions}
              multiple
              value={checklist?.data?.vehicleType}
              onChange={value => handleChangeVehicleType(value)}
            />
            {renderImplement && (
              <Select
                label="Tipo de implemento"
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                options={implementsTypes}
                multiple
                value={checklist?.data?.implementType}
                error={checklist?.errors?.implementType}
                onChange={value => {
                  checklist.setData(old => ({
                    ...old,
                    implementType: value || [],
                  }));
                  if (Object.keys(checklist.errors).length > 0) {
                    checklist.setErrors({});
                  }
                }}
              />
            )}
            <Select
              label={`Tipo de carroceria${
                renderImplement ? ' do implemento' : ''
              }`}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              multiple
              options={bodyOptions}
              value={checklist?.data?.vehicleBodyType}
              onChange={value => {
                checklist.setData(old => ({
                  ...old,
                  vehicleBodyType: value || [],
                }));
                if (Object.keys(checklist.errors).length > 0) {
                  checklist.setErrors({});
                }
              }}
              error={checklist?.errors?.vehicleBodyType}
            />
          </Col>
          <Col md={6} sm={12}>
            <Select
              label="Filiais *"
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              options={branchOfficesOptions}
              multiple
              value={checklist?.data?.branchOffices}
              onChange={handleChangeBranchOffices}
              error={checklist?.errors?.branchOffices}
            />
          </Col>
        </Row>
        <SortableSection
          questions={questions || []}
          setQuestions={setQuestions}
        />
        <StyledFooterRow>
          <Space x="0.5rem">
            <Button
              variant="secondary"
              disabled={isLoading}
              onClick={handleCancel}
            >
              <Text weight={500}>CANCELAR</Text>
            </Button>

            <Button
              disabled={questions?.length === 0}
              onClick={() => handleSave(questions)}
              loading={isLoading}
              title={
                questions?.length === 0 &&
                'Preencha campos obrigatórios e selecione perguntas para cadastrar checklist'
              }
            >
              <Text weight={500}>SALVAR</Text>
            </Button>
          </Space>
        </StyledFooterRow>
      </Card>
      <QuestionsModal
        isOpen={questionModal?.isOpen}
        onClose={() => questionModal.close()}
        setQuestions={setQuestions}
        questions={questions}
      />
    </>
  );
}
