import Text from 'v3/components/Text';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { formatDateTime } from 'v3/utils/formatter';
import { useClient } from '../../shared/context';
import SharedData from '../../shared/Section';
import { ImCross } from 'react-icons/im';
import theme from 'v3/theme';
import { BennerValidationDescriptionsContainer } from './style';

export default function BennerStatus() {
  const client = useClient();
  const clientProblems =
    client.data.client?.description_status_benner?.split(',') || [];

  return (
    <SharedData Title="Validação Benner" loading={client.isFetching}>
      <Row>
        {client?.data?.client?.has_problems_benner !== undefined ? (
          <>
            <Col xs={6}>
              <Text type="label" color="dark">
                Status da Importação
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {client?.data?.client?.has_problems_benner
                  ? 'Com problemas'
                  : 'Cadastro sem problemas'}
              </Text>
            </Col>
            <Col xs={6}>
              <Text type="label" color="dark">
                Data da última importação
              </Text>

              <Text type="regular" color="gray" as="p" className="mt-2">
                {' '}
                {client?.data?.client?.updated_at_status_benner
                  ? formatDateTime(
                      client?.data?.client?.updated_at_status_benner
                    )
                  : 'Não informado'}
              </Text>
            </Col>
            {client?.data?.client?.has_problems_benner && (
              <Col xs={12}>
                <Text type="label" color="dark">
                  Descrição
                </Text>
                <BennerValidationDescriptionsContainer
                  type="regular"
                  color="gray"
                  as="p"
                >
                  {clientProblems.map(problem => {
                    return (
                      <div>
                        <ImCross color={theme.colors.danger} size={12} />
                        <Text
                          type="regular"
                          color="gray"
                          key={problem}
                          as="span"
                        >
                          {problem}
                        </Text>
                      </div>
                    );
                  })}
                </BennerValidationDescriptionsContainer>
              </Col>
            )}
          </>
        ) : (
          <Col xs={12}>
            <Text type="regular" color="gray" as="p" className="mt-2">
              Cliente não importado do Benner
            </Text>
          </Col>
        )}
      </Row>
    </SharedData>
  );
}
