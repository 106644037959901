import React, { useState, useEffect } from 'react';

import moment from 'moment';

import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useModal } from 'hooks';
import { Col, Row } from 'react-bootstrap';
import { Button, DataTable, Modal, Text } from 'v3/components';
import Card from 'components/Card';
import Link from '@material-ui/core/Link';
import api from 'services/api';
import { FaMapMarkedAlt } from 'react-icons/fa';

function CellSpeed({ travel, onLocationsFetched }) {
  const [maxSpeed, setMaxSpeed] = useState('…');
  const [qtySpeed, setQtySpeed] = useState('…');
  const [qtyAbove, setQtyAbove] = useState(0);
  const [speedAbove, setSpeedAbove] = useState(0);
  const [finished, setFinished] = useState(false);
  const [withoutLocation, setWithoutLocation] = useState(false);

  const [showModalAlerts, setShowModalAlerts] = useState(false);
  const [selectedAlerts, setSelectedAlerts] = useState([]);
  const modalAlerts = useModal();
  const [loading, setLoading] = useState(false);

  async function getSpeeds() {
    try {
      setFinished(true);

      setQtyAbove(travel.qtyAbove); // qtd de velocidades acima de 80
      setSpeedAbove(travel.qtyAbove); // mema coisa do de cima
      setWithoutLocation(false); // tem loc ou não
      setMaxSpeed(travel.maxSpeed); // velocidade max
      setQtySpeed(
        `${travel.qtyAbove} ${travel.qtyAbove === 1 ? 'vez' : 'vezes'}`
      ); // qtyAbove vez ou vezes
    } catch (e) {
      setWithoutLocation(true); // tem loc ou não
      setMaxSpeed(''); // velocidade max
      setQtySpeed(''); // qtyAbove vez ou vezes
    }
    //   if (travel.travelLocations) {
    //     const locs = travel.travelLocations;
    //     const locsFiltered = locs.filter((el, index) => {
    //       // elementos com variancia maior que 50km por hr não serão exibidos no gráfico de velocidade
    //       if (el.speed > 200) {
    //         return false;
    //       }
    //       if (index > 0) {
    //         let variance = el.speed - locs[index - 1].speed;

    //         let variance_after = locs[index + 1]
    //           ? el.speed - locs[index + 1].speed
    //           : 0;
    //         if (variance < 0) {
    //           variance *= -1;
    //         }
    //         if (variance_after < 0) {
    //           variance_after *= -1;
    //         }
    //         if (variance > 50 || variance_after > 50) {
    //           return false;
    //         }
    //       }
    //       return true;
    //     });
    //     const qtySpeedArr = locsFiltered.filter(item => item.speed > 80);

    //     setQtyAbove(qtySpeedArr.length);

    //     if (qtySpeedArr.length === 0) onLocationsFetched();

    //     setQtySpeed(
    //       `${qtySpeedArr.length} ${qtySpeedArr.length === 1 ? 'vez' : 'vezes'}`
    //     );

    //     setSpeedAbove(qtySpeedArr.length);

    //     let maior = 0;
    //     for (let i = 0; i < locsFiltered.length; i++) {
    //       if (locsFiltered[i].speed > maior) {
    //         maior = locsFiltered[i].speed;
    //       }
    //     }
    //     setMaxSpeed(`${maior} km/h`);
    //   } else {
    //     setWithoutLocation(true);
    //     setMaxSpeed('');
    //     setQtySpeed('');
    //   }
    // } catch (e) {
    //   setWithoutLocation(true);
    //   setMaxSpeed('');
    //   setQtySpeed('');
    // }
  }

  useEffect(() => {
    getSpeeds();
  }, []);

  function setBorderColor() {
    if (withoutLocation) return '#FFBA00';
    if (!finished) return '#999999';
    if (speedAbove) return '#F44336';
    return '#6EC05D';
  }

  async function onModalAlertsOpen(travelId) {
    setLoading(true);
    try {
      const res = await api.get(`travel-speed/${travelId}/alerts`);
      res.data.map(el => {
        el.metadata = JSON.parse(el.metadata);
      })
      setSelectedAlerts(res.data);
    } catch (ex) {
      setSelectedAlerts([]);
    } finally {
      setLoading(false);
    }

    setShowModalAlerts(true);
    modalAlerts.open();
  }

  function onModalAlertsClose() {
    setShowModalAlerts(false);
    modalAlerts.close();
    setSelectedAlerts([]);
  }

  if (qtyAbove === 0) return null;

  return (
    <Card
      style={{ marginBottom: '10px' }}
      bordered
      borderhexcolor={setBorderColor()}
    >
      <Modal
        show={showModalAlerts}
        handleClose={() => onModalAlertsClose()}
        heading="Alertas da Viagem"
        body={
          <Grid>
            <DataTable
              data={selectedAlerts}
              loading={loading}
              withFilterOption={false}
              withPagination={false}
              columns={[
                {
                  name: 'Data',
                  selector: row => moment(row?.captured_at).format('DD/MM/YYYY HH:mm'),
                },
                {
                  name: 'Velocidade',
                  selector: row => parseFloat(row?.metadata?.params?.speed).toFixed(0)+' km/h',
                },
                {
                  selector: row => (
                    <a href={`https://maps.google.com/maps/@${parseFloat(row?.metadata?.params?.latitude)},${parseFloat(row?.metadata?.params?.longitude)},20z?entry=ttu`} target="_blank">
                      <Button><FaMapMarkedAlt size='22'/></Button>
                    </a>
                  ),
                },
              ]}
            />
          </Grid>
        }
        footer={
          <Row style={{ justifyContent: 'flex-end' }}>
            <Button
              variant="primary"
              onClick={() => onModalAlertsClose()}
              loading={loading}
            >
              <Text color="white" weight={500}>
                Fechar
              </Text>
            </Button>
          </Row>
        }
      />
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="caption" component="p" gutterBottom>
            <strong>Número da viagem: </strong>
            <Link
              onClick={() => window.open(`/viagens/${travel.id}`, '_blank')}
              style={{color: '#1C4B75',cursor: 'pointer'}}
            >
              {travel.travel_number || travel.id}
            </Link>
          </Typography>
          <Typography variant="caption" component="p">
            <strong>Número do Pedido Cliente: </strong>
            {travel.load.client_order_number ? travel.load.client_order_number : 'Não Informado'}
          </Typography>
          <Typography variant="caption" component="p">
            <strong>Centro de Custo: </strong>
            {travel.load.costCenter ? travel.load.costCenter.name : 'Não Informado'}
          </Typography>
          <Typography variant="caption" component="p" gutterBottom>
            <strong>Data Criação Viagem: </strong>
            {travel.created_at
              ? moment(travel.created_at).format('DD/MM/YYYY')
              : ''}
          </Typography>
          <Typography variant="caption" component="p" gutterBottom>
            <strong>Origem: </strong>
            {travel.origins.length > 0 ? travel.origins[0].formatted : ''}
          </Typography>
          <Typography variant="caption" component="p" gutterBottom>
            <strong>Destino: </strong>
            {travel.destinations.length > 0
              ? travel.destinations[travel.destinations.length - 1].formatted
              : ''}
          </Typography>
          <Typography variant="caption" component="p">
            <strong>Acima de 80 km/h: </strong>
            {qtySpeed}
          </Typography>
          <Typography variant="caption" component="p">
            <strong>Velocidade máxima: </strong>
            {maxSpeed}
          </Typography>
          <Typography variant="caption" component="p">
            <Button
              onClick={() => onModalAlertsOpen(travel.id)}
              style={{ marginTop: '25px' }}
            >
              VER TODOS OS ALERTAS
            </Button>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="caption" component="p" gutterBottom>
            <strong>Cliente Tomador: </strong>
            <Link
              onClick={() => window.open(`/clientes/${travel.load.client.id}`, '_blank')}
              style={{color: '#1C4B75',cursor: 'pointer'}}
            >
              {travel.load.client ? travel.load.client.label : 'Nenhuma'}
            </Link>
          </Typography>
          <Typography variant="caption" component="p" gutterBottom>
            <strong>Transportadora: </strong>
            <Link
              onClick={() => window.open(`/transportadoras/${travel.shipper.id}`, '_blank')}
              style={{color: '#1C4B75',cursor: 'pointer'}}
            >
              {travel.shipper ? travel.shipper.label : 'Nenhuma'}
            </Link>
          </Typography>
          <Typography variant="caption" component="p" gutterBottom>
            <strong>Motorista: </strong>
            <Link
              onClick={() => window.open(`/motoristas/${travel.driver.id}`, '_blank')}
              style={{color: '#1C4B75',cursor: 'pointer'}}
            >
              {travel.driver.name || ''}
            </Link>
          </Typography>
          <Typography variant="caption" component="p" gutterBottom>
            <strong>CPF: </strong> {travel.driver.cgccpf || ''}
          </Typography>
          <Typography variant="caption" component="p" gutterBottom>
            <strong>Placa: </strong>
            <Link
              onClick={() => window.open(`/veiculos/${travel.vehicleData.id}`, '_blank')}
              style={{color: '#1C4B75',cursor: 'pointer'}}
            >
              {travel.vehicleData.plate || ''}
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
}
export default CellSpeed;
