import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Card, Text } from 'v3/components';
import theme from 'v3/theme';

import { useClientTravelDetail } from './context';
import { formatCurrency } from 'v3/utils/formatter';

export function LoadInfoCard() {
  const { data, isFetching } = useClientTravelDetail();
  const loadCurrencySymbol = data?.loads?.loadCurrency === 'USD' ? '$' : 'R$';
  return (
    <Card
      header={
        <Text type="header" color={theme.colors.dark}>
          Informações da carga
        </Text>
      }
      loading={isFetching}
    >
      <Row>
        <Col md={6} xs={12}>
          <Text type="label" color="#464E5F">
            Produto
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {data?.loads?.product?.name?.toUpperCase() || 'Não informado'}
          </Text>
        </Col>
        <Col md={6} xs={12}>
          <Text type="label" color="#464E5F">
            Pedido
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {data?.loads?.clientOrderNumber || 'Não informado'}
          </Text>
        </Col>
        <Col md={6} xs={12}>
          <Text type="label" color="#464E5F">
            Valor da mercadoria
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {formatCurrency(
              data?.loads?.cargoValue,
              data?.loads?.loadCurrency,
              loadCurrencySymbol
            )}
          </Text>
        </Col>
        <Col md={6} xs={12}>
          <Text type="label" color="#464E5F">
            Invoice
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {data?.loads?.externalInvoiceNumber || 'Não informado'}
          </Text>
        </Col>
      </Row>
    </Card>
  );
}
