import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { validatePermission } from 'actions/index';
import useQuery from 'hooks/useQuery';
import InfiniteScroll from 'v3/components/InfiniteScroll';
import ApplicationLayout from 'v3/layouts/Application';
import { useSnackbar } from 'v3/components/Snackbar';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import BennerButton from 'v3/components/BennerButton';
import { cooperplaceApi } from 'services/api';
import Space from 'v3/components/Space';
import { ImplementCard } from './Card';
import { ImplementFilter } from './Filter';
import { usePermission } from 'hooks';

export function ImplementsList() {
  const history = useHistory();
  const snackbar = useSnackbar();
  const hasPermissionToCreate = validatePermission('CADASTRAR_IMPLEMENTO');
  const hasPermissionToImportFromBenner = validatePermission(
    'IMPORTAR_IMPLEMENTO_BENNER'
  );
  const [loading, setLoading] = useState(false);
  const [allImplements, setAllImplements] = useState([]);
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
  const [qtdAdvancedFilters, setQtdAdvancedFilters] = useState(0);

  const [pagination, setPagination] = useState({
    last_page: 1,
    current_page: 1,
    total: 0,
  });

  const queryParams = useQuery();

  const handleClose = () => setShowAdvancedFilters(false);
  const handleShow = () => setShowAdvancedFilters(true);
  function handleTitleAdvancedFilters() {
    let title = 'Filtro avançado';
    if (qtdAdvancedFilters) {
      title += ` (${qtdAdvancedFilters})`;
    }
    return title;
  }

  usePermission('VISUALIZAR_IMPLEMENTO', {
    redirect: true,
  });

  async function fetchImplements(page = 1) {
    if (page === 1) {
      setLoading(true);
    }
    try {
      const response = await cooperplaceApi.get(
        `wagons?${queryParams}&page=${page}`
      );
      if (response?.data?.data) {
        const { data, meta } = response.data;
        setAllImplements(oldImplements => {
          if (page === 1) {
            return data;
          }
          return [...oldImplements, ...data];
        });
        setPagination(meta);
      } else {
        setAllImplements([]);
        setPagination({
          last_page: 1,
          current_page: 1,
          total: 0,
        });
      }
    } catch (error) {
      if (error?.response?.status !== 401) {
        snackbar.show(
          <Text>
            Não foi possível recuperar os implementos. Tente novamente mais
            tarde
          </Text>,
          {
            type: 'error',
          }
        );
      }
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchImplements();
  }, []);

  function renderItem(item) {
    return <ImplementCard implement={item} key={item.id} />;
  }

  return (
    <ApplicationLayout
      title="Implementos"
      RightComponent={
        <Space x={'0.5rem'}>
          <Button variant="secondary" onClick={() => handleShow()}>
            <Text weight={500} color="dark" type="regular">
              {handleTitleAdvancedFilters()}
            </Text>
          </Button>
          {hasPermissionToImportFromBenner ? (
            <BennerButton
              endpoint={'wagons/importFromBenner'}
              title="Importar implemento"
              label={'Placa'}
              field={'plate'}
              redirectTo={'/implementos'}
              httpMethod="put"
            />
          ) : null}
          {hasPermissionToCreate ? (
            <Button onClick={() => history.push('/implementos/cadastrar')}>
              <Text weight={500} color="white">
                Cadastrar
              </Text>
            </Button>
          ) : null}
        </Space>
      }
    >
      <ImplementFilter
        handleClose={handleClose}
        setQtdAdvancedFilters={setQtdAdvancedFilters}
        showAdvancedFilters={showAdvancedFilters}
        fetchImplements={fetchImplements}
      />
      <InfiniteScroll
        data={allImplements}
        onEndReach={() => fetchImplements(pagination?.current_page + 1)}
        hasMore={pagination?.current_page < pagination?.last_page}
        scrollThreshold={0.7}
        renderItem={renderItem}
        loading={loading}
      />
    </ApplicationLayout>
  );
}
