import React from 'react'
import {Row} from 'react-bootstrap'
import { DriverDetailsCard } from '../../List/Modals/LoadMatchDetails';
import { DefaultEmptyComponent } from 'v3/components/List';
import { Card, Text } from 'v3/components';

export function DriversMatch({drivers, loading}){
    return (
      <Card
        header={
          <Text color="#464E5F" type="header">
            Detalhes Negociações
          </Text>
        }
        loading={loading}
      >
        <Row>
          {drivers?.length > 0 ? (
            drivers?.map(driver => {
              return <DriverDetailsCard driver={driver} key={driver.userId} />;
            })
          ) : (
            <DefaultEmptyComponent />
          )}
        </Row>
      </Card>
    );
}