import React from 'react';

import { Button, Text } from 'v3/components';
import theme from 'v3/theme';

import { Container, ContractContainer, ContractsListContainer } from './styles';

export function ContractsList({ freightContracts, handleOpenModal }) {
  return (
    <Container>
      <Text color={theme.colors.dark} weight={500}>
        Contratos disponíveis
      </Text>
      <ContractsListContainer>
        {freightContracts.map(contract => {
          return (
            <ContractContainer key={contract.id}>
              <Text color={theme.colors.dark}>{contract.contract}</Text>
              <Text color={theme.colors.dark}>
                {contract.statusDetails?.name}
              </Text>

              <Button
                variant="secondary"
                onClick={() => handleOpenModal(contract.id)}
              >
                <Text color={theme.colors.dark} weight={500}>
                  Detalhes
                </Text>
              </Button>
            </ContractContainer>
          );
        })}
      </ContractsListContainer>
    </Container>
  );
}
