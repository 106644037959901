// eslint-disable-next-line no-unused-vars

import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import api from 'services/api';
import { useParams } from 'react-router-dom';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import Fallback from 'v3/components/Fallback';
import Card from 'v3/components/Card';
import Table from 'v3/components/Table';
import { useSnackbar } from 'v3/components/Snackbar';
import { formatCurrency } from 'v3/utils/formatter';
import theme from 'v3/theme';
import moment from 'moment';

import { FaPencilAlt, FaTrash } from 'react-icons/fa';
import GoalModal from '../Modals/GoalModal';
import { useCompany } from '../context';

export default function Goal() {
  const company = useCompany();
  const params = useParams();
  const snackbar = useSnackbar();
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (!params.id)
      company.setData({
        goals: [],
      });
  }, []);

  const [currency, setCurrency] = useState({
    currency: 'BRL',
    currency_symbol: 'R$',
  });

  async function handleRemoveGoal(goal) {
    if (params.id) {
      try {
        setLoading(true);

        await api.delete(`v3/company/${company.data.id}/goal/${goal.id}`);
      } catch (error) {
        snackbar.show(<Text>Erro ao excluir meta</Text>, {
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    }
    company.setData({
      goals: company.data.goals.filter(item => item !== goal),
    });
  }

  return (
    <>
      <GoalModal
        isOpen={showModal}
        onClose={() => {
          company.setData({ editingCurrentGoal: null });
          setShowModal(false);
        }}
        setShowModal={setShowModal}
      />
      <Card
        loading={company.isFetching}
        header={
          <Text type="header" color="dark">
            Metas
          </Text>
        }
        HeaderRightComponent={
          company.data?.goals?.length > 0 && (
            <Button
              className="py-2"
              onClick={() => {
                setShowModal(true);
                company.setPrevCompany(company.data);
              }}
              disabled={!company.hasPermissionToEdit}
            >
              <Text type="regular" weight="500" color="white">
                Adicionar
              </Text>
            </Button>
          )
        }
      >
        <Row>
          <Fallback
            fall={!company.data?.goals?.length}
            component={
              <Col xs={12} className="d-flex flex-column align-items-center">
                <Text color={theme.colors.dark} type="regular">
                  Não há metas cadastradas para essa empresa.
                </Text>
                <Button
                  className="mt-3 py-2"
                  onClick={() => {
                    setShowModal(true);
                    company.setPrevCompany(company.data);
                  }}
                  disabled={!company.hasPermissionToEdit}
                >
                  <Text type="regular" weight="500" color="white">
                    Adicionar
                  </Text>
                </Button>
              </Col>
            }
          >
            <Table striped>
              <thead>
                <tr>
                  <th>
                    <Text weight={500} type="regular" color="dark">
                      Mês/Ano
                    </Text>
                  </th>
                  <th>
                    <Text weight={500} type="regular" color={theme.colors.dark}>
                      Meta
                    </Text>
                  </th>
                  <th>
                    <Text weight={500} type="regular" color={theme.colors.dark}>
                      Super Meta
                    </Text>
                  </th>
                  <th className="text-center">
                    <Text type="regular" weight={500} color="dark">
                      Ação
                    </Text>
                  </th>
                </tr>
              </thead>
              <tbody>
                {company.data?.goals
                  ?.sort((a, b) => {
                    return a.year - b.year || a.month - b.month;
                  })
                  .map(item => (
                    <tr>
                      <td>
                        <Text
                          type="regular"
                          color="dark"
                          transform="capitalize"
                        >
                          {`${moment()
                            .set('month', item.month)
                            .format('MMMM ')} /  ${item.year}`}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="dark">
                          {formatCurrency(
                            item.goal,
                            currency?.currency,
                            currency?.currency_symbol
                          )}
                        </Text>
                      </td>
                      <td>
                        <Text className="m-5" type="regular" color="gray">
                          {formatCurrency(
                            item.over_goal,
                            currency?.currency,
                            currency?.currency_symbol
                          )}
                        </Text>
                      </td>
                      <td width="120" className="text-center">
                        <Button
                          variant="secondary"
                          size="sm"
                          className="mr-1"
                          onClick={() => {
                            company.setData({
                              goals: company.data.goals.filter(
                                goal => goal !== item
                              ),
                              editingCurrentGoal: item,
                            });
                            company.setPrevCompany(company.data);
                            setShowModal(true);
                          }}
                          disabled={!company.hasPermissionToEdit}
                        >
                          <FaPencilAlt size={16} color="#464E5F" />
                        </Button>
                        <Button
                          variant="error"
                          size="sm"
                          onClick={() => handleRemoveGoal(item)}
                          disabled={
                            item.current || !company.hasPermissionToEdit
                          }
                          loading={isLoading}
                        >
                          <FaTrash size={16} />
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Fallback>
        </Row>
      </Card>
    </>
  );
}
