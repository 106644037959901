import { Col, Row } from 'react-bootstrap';
import Button from 'v3/components/Button';
import styled from 'styled-components';
import { Switch } from '@material-ui/core';
import Label from 'components/Label';

export const RowStyled = styled(Row)`
  margin-top: 3%;
`;

export const ColStyled = styled(Col)`
  display: flex;
  justify-content: end;
  margin-top: 2%;
`;

export const ButtonCancel = styled(Button)`
  margin-right: 2%;
`;

export const StyledSwitch = styled(Switch)`
  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: #099b94;
  }
  .MuiSwitch-colorSecondary.Mui-checked {
    color: #ffffff;
  }
`;

export const StyledLabel = styled(Label)`
  margin-left: 10%;
`;

export const GridStyled = styled.div`
  padding: 1rem;
`;
