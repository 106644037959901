import { CNPJ_MASK, CPF_MASK } from 'utils/masks';

export function getPercentageFromValue(value, baseValue, decimalDigits = 2) {
  let result = (value / baseValue) * 100;
  if (isNaN(result)) {
    return 0;
  }
  return ((value / baseValue) * 100).toFixed(decimalDigits);
}

export function ceilDecimals(number, decimalPlaces) {
  const factor = 10 ** decimalPlaces;
  return Math.ceil(number * factor) / factor;
}

export function applyMonetaryMask(value) {
  let stringValue = typeof value === 'number' ? value.toFixed(2) : value;

  let processedValue = stringValue;
  processedValue = processedValue?.replace(/\D/g, '');

  processedValue = processedValue?.replace(/(\d+)(\d{2})$/, '$1,$2');
  processedValue = processedValue?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

  return processedValue;
}

export function applyDocumentMask(rawValue) {
  if (rawValue.length > CPF_MASK.length) {
    return CNPJ_MASK;
  }

  return CPF_MASK;
}

export function validateDateTime(value) {
  const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/;

  if (!regex.test(value)) {
    return false
  } else {
    return true
  }
}

export const phoneNumberFormater = telNumber => {
  if (telNumber.length === 11 || telNumber.length === 10) {
    const cleaned = `${telNumber}`.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{2})(\d{4}|\d{5})(\d{4})$/);
    if (match) {
      return ['(', match[1], ')', match[2], '-', match[3]].join('');
    }
  }
  return telNumber;
};