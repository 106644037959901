/* eslint-disable import/no-unresolved */
import api from 'services/api';

export async function fetchVehicleData(url) {
  try {
    const response = await api.get(url, { params: { app: true } });

    return response.data;
  } catch (ex) {
    return [];
  }
}

export async function fetchVehicles() {
  return fetchVehicleData('vehicle-types');
}

export async function fetchBodies() {
  return fetchVehicleData('body-types');
}

export async function fetchImplements() {
  return fetchVehicleData('vehicle-implement-types');
}

async function fetchDispositionData(url) {
  try {
    const response = await api.get(url, { params: { app: true } });

    return response.data;
  } catch (ex) {
    return [];
  }
}

export async function fetchDispositions() {
  return fetchDispositionData('load-disposition');
}
