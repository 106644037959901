import React from 'react';
import { Row } from 'react-bootstrap';
import { Button, Text } from 'v3/components';
import { useRiskManager } from '../../context';
import { HeaderDetails } from '../../components/HeaderDetails';
import { FooterButtonsContainer } from '../../styles';
import { RiskManagerEditing } from './riskManagerEditing';

export function RiskManagerEditingComponent() {
  const { hasEditPermissionRiskManager, feedModal, isEditing } =
    useRiskManager();
  return (
    <Row>
      <HeaderDetails />
      <RiskManagerEditing />
      {hasEditPermissionRiskManager ? (
        <FooterButtonsContainer>
          <Button
            variant="secondary"
            onClick={() => feedModal.open()}
            disabled={isEditing}
          >
            <Text type="regular" weight="500">
              Incluir Feed
            </Text>
          </Button>
        </FooterButtonsContainer>
      ) : null}
    </Row>
  );
}
