/* eslint-disable import/no-unresolved */
import api from 'services/api';

import { useSnackbar } from 'v3/components/Snackbar';
import schema from './validator';

export async function validate(data, setErrors) {
  const errorList = {};
  try {
    await schema.validate(data, { abortEarly: false });
    setErrors({ ...errorList });
    return true;
  } catch (err) {
    /* This loop creates a property with the path
    of the error and assign its error message to the value
    Example:
    err: {
      inner: {
        0: {path: 'client', message: 'Cliente obrigatório'}
        1: {path: 'product', message: 'Produto obrigatório'}
      }
    }
    */
    err.inner.forEach(error => {
      errorList[error.path] = error.message;
    });
    setErrors({ ...errorList });
    return false;
  }
}

export async function save({ id, ...rest }) {
  try {
    const { logo } = rest;
    delete rest.logo;

    const response = await api.put(`issuers/general/${id}`, { ...rest });

    if (logo) {
      const logoData = new FormData();
      logoData.append('file', logo[0]);
      await api.post(`issuers/media/${id}/LOGO`, logoData);
    }

    return response.data;
  } catch (err) {
    const message = err.response.data[0]
      ? err.response.data[0].message
      : err.response.data.message;
    return { error: message || 'Não foi possível atualizar dados do emissor' };
  }
}

export async function excludeLogo({ id, currentLogo }) {
  try {
    const logoData = new FormData();
    logoData.append('file', currentLogo[0]);
    const response = await api.delete(
      `issuers/media/delete/${id}/LOGO`,
      logoData
    );
    return response.data;
  } catch (err) {
    const message = err.response.logoData[0]
      ? err.response.logoData[0].message
      : err.response.logoData[0].message;
    return { error: message || 'Não foi possível excluir a logo' };
  }
}
