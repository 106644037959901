import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { usePermission } from 'hooks';
import { useSnackbar } from 'v3/components/Snackbar';
import Text from 'v3/components/Text';
import api from 'services/api';
import Details from '../Details';
import Register from '../Register';
import { UserProvider } from './context';

export default function UserShared() {
  const snackbar = useSnackbar();
  const params = useParams();
  const dispatch = useDispatch();

  const [user, setUser] = useState({ clients: [], costCentersManaged: [] });
  const [prevUser, setPrevUser] = useState(user);
  const [isFetching, setFetching] = useState(false);
  const [errors, setErrors] = useState({});
  const hasPermissionToEdit = usePermission('ALTERAR_USUARIO');
  const hasPermissionToCreate = usePermission('CADASTRAR_USUARIO');
  usePermission('VISUALIZAR_USUARIOS', { redirect: true });

  function showSnackbar(text, options) {
    snackbar.show(<Text> {text} </Text>, options);
  }

  async function fetchUser() {
    try {
      setFetching(true);
      const response = await api.get(`users/${params.id}`);
      setUser(response.data);
      setPrevUser(response.data);
    } catch (ex) {
      showSnackbar('Não foi possível carregar esse usuário', {
        type: 'error',
      });
    } finally {
      setFetching(false);
    }
  }

  useEffect(() => {
    async function cloneUser() {
      try {
        setFetching(true);
        const response = await api.get(`users/${params.clone_id}`);
        setUser({
          clients: response.data.clients,
          companies: response.data.companies,
          establishments: response.data.establishments,
          groups: response.data.groups,
          profile: response.data.profile,
          shipping_companies: response.data.shipping_companies,
          sites: response.data.sites,
          costCenters: response.data.costCenters,
          costCentersManaged: response.data.costCentersManaged,
          branchOffices: response.data.branchOffices,
          beneficiary:response.data.beneficiary
        });
        setPrevUser(response.data);
      } catch (ex) {
        showSnackbar('Não foi possível carregar esse usuário', {
          type: 'error',
        });
      } finally {
        setFetching(false);
      }
    }

    if (params.clone_id) {
      cloneUser();
    }

    if (params.id) {
      fetchUser();
    }
  }, [params.id]);

  function setData(data) {
    setUser(prev => ({ ...prev, ...data }));
  }

  function updatePrevUser() {
    setPrevUser(user);
  }

  function handleCancel() {
    setUser(prevUser);
  }

  useEffect(() => {
    if (params.id) {
      dispatch({
        type: 'VIEW_INSTANCE',
        instance: user?.username,
      });
    }
  }, [user]);

  return (
    <UserProvider
      value={{
        data: user,
        setData,
        isFetching,
        errors,
        setErrors,
        showSnackbar,
        handleCancel,
        updatePrevUser,
        prevUser,
        fetchUser,
        hasPermissionToEdit,
        hasPermissionToCreate,
      }}
    >
      {params.id ? <Details /> : <Register />}
    </UserProvider>
  );
}
