import styled from 'styled-components';

import Button from 'v3/components/Button';

export const Line = styled.hr`
  border: 1px solid #f2f2f2;
  width: 100%;
  margin-top: 0px !important;
  margin-bottom: 35px;
`;

export const StyledButton = styled(Button)`
  width: 108px;
  height: 28px;
  padding: 2px;
`;
