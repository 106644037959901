const Yup = require('yup');

export class ValidationError extends Error {
  constructor(message, errors) {
    super(message);
    this.validationErrors = errors;
  }
}

const rasterBranchschema = Yup.object().shape({
  code: Yup.object()
    .typeError('Código filial raster obrigatório')
    .required('Código filial raster obrigatório'),
  document: Yup.string()
    .typeError('CNPJ obrigatório')
    .required('CNPJ obrigatório'),
  name: Yup.string()
    .typeError('Razão obrigatório')
    .required('Razão obrigatório'),
  costCenters: Yup.array()
    .min(1, 'Selecione pelo menos um centro de custo')
    .typeError('Centro de custo obrigatório')
    .required('Centro de custo obrigatório'),
});

export async function validate(data, schema) {
  const errorList = {};
  try {
    await schema.validate(data, {
      abortEarly: false,
    });
    return true;
  } catch (err) {
    err.inner.forEach(error => {
      errorList[error.path] = error?.message;
    });
    throw new ValidationError('Error na validação de campos', errorList);
  }
}

export { rasterBranchschema };
