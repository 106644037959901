import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  fetchBodies,
  fetchCountries,
  fetchDispositionData,
  fetchImplements,
  fetchVehiclesTypes,
} from 'utils/fetches';
import { Input, Select } from 'v3/components';
import { DefaultLoadingComponent } from 'v3/components/List';

export function Inputs({ data, setData, preLoad, errors }) {
  const [loading, setLoading] = useState(false);
  const [loadDispositionOptions, setLoadDispositionOptions] = useState([]);
  const [vehicleTypesOptions, setVehicleTypesOptions] = useState([]);
  const [vehicleBodyTypesOptions, setVehicleBodyTypesOptions] = useState([]);
  const [vehicleImplementTypesOptions, setVehicleImplementTypesOptions] =
    useState([]);
  const [countryOptions, setCountryOptions] = useState([]);

  async function fetchOptions() {
    try {
      setLoading(true);
      let [
        vehicleTypesData,
        vehiclesBodyTypesData,
        implementsData,
        dispositionData,
        countriesData,
      ] = await Promise.all([
        fetchVehiclesTypes(),
        fetchBodies(),
        fetchImplements(),
        fetchDispositionData(),
        fetchCountries(),
      ]);
      vehicleTypesData = vehicleTypesData.filter(
        vtype => vtype.name !== 'Dolly' && vtype.name !== 'Semi Reboque'
      );
      setVehicleTypesOptions(vehicleTypesData);
      setVehicleBodyTypesOptions(vehiclesBodyTypesData);
      implementsData = implementsData.filter(vtype => vtype.name !== 'Dolly');
      setVehicleImplementTypesOptions(implementsData);
      setLoadDispositionOptions(dispositionData);
      setCountryOptions(countriesData);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchOptions();
  }, []);

  function setPreLoadData() {
    setData({
      id: preLoad?.id,
      disposition: preLoad?.disposition,
      weight: preLoad?.loadWeight,
      vehicleTypes: preLoad?.vehicleTypes,
      vehicleImplementTypes: preLoad?.vehicleImplementTypes,
      vehicleBodyTypes: preLoad?.vehicleBodyTypes,
    });
  }

  useEffect(() => {
    if (preLoad) {
      setPreLoadData();
    }
  }, [preLoad]);

  const shouldDisplayImplement = data?.vehicleTypes?.some(
    selected => selected.qtyPlates > 1 || selected.qty_plates > 1
  );

  return loading ? (
    <DefaultLoadingComponent />
  ) : (
    <Row>
      <Col md={12} xs={12} className="mb-3">
        <Select
          label="Tipo de Veículo *"
          options={vehicleTypesOptions}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          value={data?.vehicleTypes}
          onChange={value => {
            if (value && value.filter(item => item.qtyPlates > 1 || item.qty_plates > 1)?.length > 0) {
              setData(old => ({
                ...old,
                vehicleTypes: value,
              }));
            } else
            setData(old => ({
              ...old,
              vehicleTypes: value,
              vehicleImplementTypes: null,
            }));
          }}
          multiple
          error={errors.vehicleTypes}
        />
      </Col>

      <Col md={12} xs={12} className="mb-3">
        <Select
          label="Tipo de carroceria *"
          options={vehicleBodyTypesOptions}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          value={data?.vehicleBodyTypes}
          onChange={value =>
            setData(old => ({ ...old, vehicleBodyTypes: value }))
          }
          multiple
          error={errors.vehicleBodies}
        />
      </Col>

      {shouldDisplayImplement && (
        <Col md={12} xs={12} className="mb-3">
          <Select
            label="Tipo de implemento *"
            options={vehicleImplementTypesOptions}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            value={data?.vehicleImplementTypes}
            onChange={value =>
              setData(old => ({ ...old, vehicleImplementTypes: value }))
            }
            multiple
            error={errors.vehicleImplements}
          />
        </Col>
      )}

      <Col xs={6} className="mb-3">
        <Select
          label="Disposição da carga *"
          options={loadDispositionOptions}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          value={data?.disposition}
          onChange={value => setData(old => ({ ...old, disposition: value }))}
          error={errors.disposition}
        />
      </Col>
      <Col xs={6} className="mb-3">
        <Input
          label="Peso bruto (em kg) *"
          type="number"
          min={1}
          value={data?.weight}
          onChange={({ target }) => {
            setData(old => ({ ...old, weight: target.value }));
          }}
          error={errors.weight}
        />
      </Col>
    </Row>
  );
}
