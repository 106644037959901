import React, { useState, useCallback, useEffect } from 'react';

import dashboardApi from 'services/apis/dashboard';
import { Table, Text, Link, Button } from 'v3/components';

import { formatDateTime, formatPlate, formatNumber } from 'v3/utils/formatter';

import moment from 'moment';
import { useQuery } from 'hooks';

import Card, { NumericList, SeeAll } from './Card';

export default function TravelTransportedWeight({ dashboard, name, id }) {
  const [filter, setFilter] = useState({});
  const [currentPage, setCurrentPage] = useState({});
  const [pagination, setPagination] = useState({});
  const [data, setData] = useState({});
  const [formattedData, setFormattedData] = useState([]);
  const [isFetching, setFetching] = useState(false);
  const [loading, setLoading] = useState(false);
  const queryParams = useQuery();

  function setQueryParams(filters) {
    const { tags = [] } = filters;
    const start_date = moment().subtract(3, 'days').format('YYYY-MM-DD');

    queryParams.delete('tags');

    if (tags) {
      tags.forEach(tag => {
        queryParams.append('tags', tag.id);
      });
    }

    queryParams.set('start_date', start_date);

    if (tags?.length === 0) {
      queryParams.set('no-tags', true);
    } else {
      queryParams.delete('no-tags');
    }
  }

  const fetchAvailablesLoads = useCallback(async (filters = {}) => {
    try {
      setLoading(true);

      const {
        tags = [],
        clients = [],
        page = 1,
        negotiators = [],
        costCenters = [],
        pcps = [],
        companies = [],
      } = filters;

      if (page === 1) {
        setFetching(true);
      }

      setQueryParams(filters);
      setFilter(filters);
      setCurrentPage(+page);

      const response = await dashboardApi.get('/travels-transported-weight', {
        params: {
          tags: tags?.map(tag => tag.id),
          clients: clients?.map(client => client.id),
          page,
          negotiators: negotiators?.map(negotiator => negotiator?.id),
          costCenters: costCenters?.map(costCenter => costCenter?.id),
          pcps: pcps?.map(pcp => pcp?.id),
          companies: companies?.map(company => company?.id),
        },
      });

      setPagination(response.data?.last?.pagination);

      if (page === 1) {
        setData(response.data);
      } else {
        setData(oldData => ({
          ...oldData,
          last: {
            ...oldData.last,
            data: [...oldData.last.data, ...response.data.last.data],
          },
        }));
      }
    } catch (error) {
      // comment
    } finally {
      setFetching(false);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const totalTonne = Math.round(data.total_load_weight / 1000);

    const totalLoads = Math.round(data.total_carg_loads);

    const tonnePerLoads =
      Math.round(data.total_load_weight / 1000 / totalLoads) || 0;

    setFormattedData([
      {
        total: totalLoads,
        color: 'success',
        description: totalLoads === 1 ? 'Carga' : 'Cargas',
      },
      {
        total: totalTonne,
        color: 'primary',
        description: totalTonne === 1 ? 'Tonelada' : 'Toneladas',
      },
      {
        total: tonnePerLoads ?? 0,
        color: 'primary',
        description:
          tonnePerLoads === 1
            ? 'Média de tonelada por carga'
            : 'Média de toneladas por carga',
      },
    ]);
  }, [data]);

  return (
    <>
      <Card
        title="Cargas e peso transportados"
        isLoading={isFetching}
        fetchFn={fetchAvailablesLoads}
        onFiltersChanged={fetchAvailablesLoads}
        dashboard={dashboard}
        name={name}
        id={id}
        excludeFilterByBranchOffice={true}
        excludeFilterByDate
        ListComponent={
          <>
            <Table striped>
              <thead>
                <tr>
                  <th>
                    <Text weight="500" color="dark">
                      Centro de custo
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Empresa
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Tags da carga
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Viagem
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Carregamento
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Peso
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Origem
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Destino
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Cliente
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Negociador
                    </Text>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.last?.data?.map(travel => {
                  const title = [
                    `Cliente ${travel.social_name} - ${travel.cgccpf}`,
                    `Motorista ${travel.driver_name}`,
                    `Placa veículo tração ${formatPlate(travel.plates)}`,
                  ].join('\n');

                  return (
                    <tr>
                      <td>
                        <Text type="regular" color="gray">
                          {travel.cost_center || '-'}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="gray">
                          {travel.company_name || '-'}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="gray">
                          {travel.load_tags || '-'}
                        </Text>
                      </td>
                      <td>
                        <Link
                          to={`/viagens/${travel.attended_id}`}
                          title={title}
                          target="_blank"
                        >
                          {travel.attended_id}
                        </Link>
                      </td>
                      <td>
                        <Text type="regular" color="gray">
                          {formatDateTime(travel.scheduled_origin)}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="gray">
                          {travel.load_weight !== null
                            ? `${formatNumber(travel.load_weight)} kg`
                            : '-'}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="dark" truncate>
                          {`${travel?.city_origin} - ${travel.uf_origin}`}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="dark" truncate>
                          {`${travel?.city_destination} - ${travel.uf_destination}`}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="gray" truncate>
                          {travel.social_name}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="gray" truncate>
                          {travel.user_created}
                        </Text>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>

            {pagination?.pagination?.lastPage > 1 &&
              pagination?.pagination?.to < pagination?.pagination?.total && (
                <div className="text-center mt-3 col-12">
                  <Button
                    className="mt-2 ml-auto align-item-center"
                    onClick={() => {
                      fetchAvailablesLoads({
                        page: currentPage + 1,
                        tags: filter?.tags,
                        clients: filter?.clients,
                        costCenters: filter?.costCenters,
                        pcps: filter?.pcps,
                        negotiators: filter?.negotiators,
                        companies: filter?.companies,
                      });
                    }}
                    loading={loading}
                  >
                    <Text type="regular" color="white" truncate>
                      Ver mais
                    </Text>
                  </Button>
                </div>
              )}
            {!!pagination?.pagination?.total && (
              <SeeAll
                to={{
                  pathname: '/viagens',
                  search: queryParams.toString(),
                }}
              >
                ver todas as {pagination?.pagination?.total} viagens
              </SeeAll>
            )}
          </>
        }
        NumericComponent={
          <NumericList data={formattedData} description="Nos últimos 30 dias" />
        }
      />
    </>
  );
}
