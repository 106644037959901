import React, { useCallback, useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { usePermission, useQuery } from 'hooks';

import ApplicationLayout from 'v3/layouts/Application';
import { cooperplaceApi } from 'services/api';
import apiReport from 'services/apis/report';
import Space from 'v3/components/Space';
import { Button, InfiniteScroll, Text } from 'v3/components';
import moment from 'moment';
import { useSnackbar } from 'v3/components/Snackbar';

import FileSaver from 'file-saver';
import { Filter } from './Filter';
import { OccurrenceCard } from './Card';

export function OccurrencesReport() {
  usePermission('VISUALIZAR_RELATORIOS_DE_OCORRENCIAS', { redirect: true });
  const query = useQuery();
  const snackbar = useSnackbar();
  const [occurrences, setOccurrences] = useState([]);
  const [pagination, setPagination] = useState({
    lastPage: 1,
    currentPage: 1,
    total: 0,
  });
  const [errors, setErrors] = useState({});
  const [qtdAdvancedFilters, setQtdAdvancedFilters] = useState(2);
  const [showAdvancedFiltersModal, setShowAdvancedFiltersModal] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [loadingInitialParams, setLoadingInitialParams] = useState(true);
  const [occurrencesOptions, setOccurrencesOptions] = useState([]);

  async function getOccurrencesTypesOptions() {
    try {
      setLoadingInitialParams(true);
      const response = await cooperplaceApi.get('occurrences/types');
      setOccurrencesOptions(response.data.message);
    } catch (error) {
      setOccurrencesOptions([]);
      setLoadingInitialParams(false);
    } finally {
      setLoadingInitialParams(false);
    }
  }
  useEffect(() => {
    getOccurrencesTypesOptions();
  }, []);

  function validateDate() {
    const startDate = query.get('startDate');
    const startMaxDate = query.get('startMaxDate');
    const validStartDate = moment(startDate).isValid();
    let validStartMaxDate = true;
    let differenceInDays = 0;

    if (startMaxDate) {
      validStartMaxDate = moment(startMaxDate).isValid();
      differenceInDays = moment(startMaxDate).diff(moment(startDate), 'days');
    }

    if (startMaxDate && differenceInDays > 30) {
      snackbar.show(<Text weight={500}>Período máximo de 30 dias</Text>, {
        type: 'warning',
      });
      return false;
    }

    if (!startDate || !validStartMaxDate) {
      setErrors({
        startDate:
          !startDate || !validStartDate ? 'Informe data de início válida' : '',
        startMaxDate: !validStartMaxDate ? 'Informe data de término válida' : '',
      });
      return false;
    }

    if (
      startMaxDate &&
      validStartDate &&
      validStartMaxDate &&
      moment(startDate).isAfter(moment(startMaxDate))
    ) {
      setErrors({
        startDate: 'Data de início não pode ser superior ao fim',
        endDate: null,
      });
      return false;
    }
    if (
      startMaxDate &&
      validStartDate &&
      validStartMaxDate &&
      moment(startMaxDate).isBefore(moment(startDate))
    ) {
      setErrors({
        startDate: null,
        startMaxDate: 'Data de fim não pode ser inferior ao início',
      });
      return false;
    }
    return true;
  }
  const handleFilter = useCallback(
    async (page = 1) => {
      try {
        if (page === 1) {
          setLoading(true);
        }
        const validDates = validateDate();
        if (!validDates) {
          setLoading(false);
          return;
        }
        const {
          data: { data, ...rest },
        } = await apiReport(`occurrences?page=${page}&${query}`);
        setPagination(rest);
        setOccurrences(old => {
          if (page === 1) {
            return data;
          }
          return [...old, ...data];
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setOccurrences([]);
        snackbar.show(
          <Text type="body" weight={500}>
            Erro ao recuperar ocorrências
          </Text>,
          {
            type: 'error',
          }
        );
      }
    },
    [query]
  );

  function renderItem(occurrence) {
    return <OccurrenceCard key={occurrence?.id} occurrence={occurrence} />;
  }

  async function handleGenerateReport() {
    try {
      setLoadingExport(true);
      const validDates = validateDate();
      if (!validDates) {
        setLoading(false);
        return;
      }
      const response = await apiReport.get(`occurrences-report?${query}`, {
        responseType: 'blob',
      });
      FileSaver.saveAs(response.data, response.headers['x-file-name']);
      setLoadingExport(false);
    } catch (error) {
      snackbar.show(
        <Text type="body" weight={500}>
          Erro ao gerar relatatório
        </Text>,
        {
          type: 'error',
        }
      );
      setLoadingExport(false);
    }
  }

  return (
    <ApplicationLayout
      title="Ocorrências"
      headerMarginTop={-160}
      RightComponent={
        <Space x="0.25rem" y="0.25rem">
          <Button
            variant="secondary"
            onClick={() => setShowAdvancedFiltersModal(true)}
            disabled={loading || loadingInitialParams || loadingExport}
          >
            <Text
              weight={500}
            >{`Filtro Avançado (${qtdAdvancedFilters})`}</Text>
          </Button>

          <Button
            disabled={loadingInitialParams || loadingExport}
            onClick={() => handleFilter()}
            loading={loading}
          >
            <Text weight={500}>Filtrar</Text>
          </Button>
          <Button
            variant="success"
            disabled={loading || loadingInitialParams}
            loading={loadingExport}
            onClick={() => handleGenerateReport()}
          >
            <Text weight={500}>Exportar</Text>
          </Button>
        </Space>
      }
    >
      <Row className="d-flex flex-column justify-content-center space-between">
        <Filter
          showAdvancedFiltersModal={showAdvancedFiltersModal}
          setShowAdvancedFiltersModal={setShowAdvancedFiltersModal}
          occurrencesOptions={occurrencesOptions}
          loadingInitialParams={loadingInitialParams}
          query={query}
          setQtdAdvancedFilters={setQtdAdvancedFilters}
          handleFilter={handleFilter}
          errors={errors}
          setErrors={setErrors}
        />
        <InfiniteScroll
          data={occurrences}
          onEndReach={() => handleFilter(pagination.currentPage + 1)}
          hasMore={pagination?.currentPage < pagination?.lastPage}
          scrollThreshold={0.7}
          loading={loading}
          renderItem={renderItem}
        />
      </Row>
    </ApplicationLayout>
  );
}
