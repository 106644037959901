import styled from 'styled-components';

import BootstrapModal from 'react-bootstrap/Modal';

export const StyledModal = styled(BootstrapModal)`
  background-color: rgba(51, 51, 51, 0.6);
  z-index: 999 !important;
`;

export const StyledModalBody = styled(BootstrapModal.Body)``;
