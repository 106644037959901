import React, {
  useMemo,
  useState,
  useImperativeHandle,
  useEffect,
  forwardRef,
} from 'react';

import { useIsInitialRender } from 'hooks';

import Text from '../../Text';
import * as Styled from './styles';

function Pagination(
  {
    pages = 1,
    maxDisplayedPages = 6,
    currentPage: propsCurrentPage = 1,
    onPageChanged,
  },
  ref
) {
  const [currentPage, setCurrentPage] = useState(propsCurrentPage);
  const [offset, setOffset] = useState(0);

  const isInitialRender = useIsInitialRender();

  const array = useMemo(
    () => new Array(pages).fill(0).map((_, index) => index),
    [pages]
  );

  function handlePageChange(page) {
    if (page < 1 || page > pages || page === currentPage) {
      return;
    }

    setCurrentPage(page);

    const nextOffset = page - currentPage;
    setOffset(prevOffset => prevOffset + nextOffset);
  }

  useEffect(() => {
    if (!isInitialRender) {
      if (onPageChanged) {
        onPageChanged(currentPage);
      }
    }
  }, [currentPage]);

  useEffect(() => {
    if (propsCurrentPage) {
      handlePageChange(propsCurrentPage);
    }
  }, [propsCurrentPage]);

  function resetPage() {
    setCurrentPage(1);
    setOffset(0);
  }

  useImperativeHandle(ref, () => ({
    resetPage,
  }));

  const start = useMemo(
    () => Math.min(offset, Math.max(pages - maxDisplayedPages, 0)),
    [offset, pages, maxDisplayedPages]
  );
  const end = useMemo(() => Math.min(start + maxDisplayedPages, pages), [
    start,
    maxDisplayedPages,
    pages,
  ]);

  if (pages <= 1) {
    return null;
  }

  return (
    <div className="d-flex align-items-center">
      <Styled.SideButton onClick={() => handlePageChange(currentPage - 1)}>
        <Text type="label" color="dark">
          Anterior
        </Text>
      </Styled.SideButton>
      <div>
        {array.slice(start, end).map(index => (
          <Styled.Page
            onClick={() => handlePageChange(index + 1)}
            selected={index + 1 === currentPage}
          >
            <Text type="label">{index + 1}</Text>
          </Styled.Page>
        ))}
      </div>
      <Styled.SideButton onClick={() => handlePageChange(currentPage + 1)}>
        <Text type="label" color="dark">
          Avançar
        </Text>
      </Styled.SideButton>
    </div>
  );
}

export default forwardRef(Pagination);
