import React, { useEffect, useMemo, useState } from 'react';
import { Card, Text } from 'v3/components';
import theme from 'v3/theme';
import * as Styled from './styles';
import { Col, Row } from 'react-bootstrap';
import {
  formatAddress,
  formatDateCustom,
  formatTime,
  pluralize,
} from 'v3/utils/formatter';
import moment from 'moment';
import { FaArrowRight, FaCheck, FaClock } from 'react-icons/fa';
import { useClientTravelDetail } from './context';

export function TravelRouteCard() {
  const { data, isFetching } = useClientTravelDetail();
  let origin = useMemo(
    () => data?.loads?.loadOrigins?.[0],
    [data?.loads?.loadOrigins]
  );

  const shouldReturnTravelRouteCard =
    data?.loads?.loadOrigins?.some(
      item => item.arrivalTime || item.effectiveTime
    ) ||
    data?.loads?.loadDestinations?.some(
      item => item.arrivalTime || item.effectiveTime
    );

  const [waitingHours, setWaitingHours] = useState();
  const [waitingMinutes, setWaitingMinutes] = useState();

  const datetimeFinishedLoading = data?.loads?.logs?.filter(
    log => log.statusTo === 11
  )[0]?.createdAt;

  if (datetimeFinishedLoading && !origin?.effectiveTime) {
    origin = {
      ...origin,
      effectiveTime: datetimeFinishedLoading,
    };
  }

  const destinations = useMemo(
    () => data?.loads?.loadDestinations,
    [data?.loads?.loadDestinations]
  );

  const isOriginCompleted = useMemo(
    () => origin?.arrivalTime,
    [data?.travelStatus?.id, origin]
  );

  useEffect(() => {
    let diff = moment(origin?.effectiveTime).diff(
      moment(origin?.arrivalTime),
      'minutes'
    );

    for (let i = 0; i < destinations?.length; i++) {
      diff += moment(destinations[i]?.effectiveTime).diff(
        moment(destinations[i]?.arrivalTime),
        'minutes'
      );
    }
    const hours = parseInt(diff / 60, 10);
    const minutes = diff % 60;

    if (typeof diff === 'number') {
      if (hours < 10) {
        setWaitingHours(`0${hours}`);
      } else {
        setWaitingHours(hours);
      }
      setWaitingMinutes(minutes);
    }
  }, [origin, destinations]);
  const hasStartSchedule = !!(
    data?.loads?.loadOrigins?.[0]?.startSchedule ||
    data?.loads?.loadDestinations?.map(item => item.startSchedule)[0] !== null
  );

  return (
    <Card
      header={
        <Text type="header" color={theme.colors.dark}>
          Rota da viagem
        </Text>
      }
      loading={isFetching}
    >
      <Row>
        <Col md={hasStartSchedule ? 4 : 6} xs={12}>
          <div className="mb-3">
            <Text type="body" color="dark">
              Origem
            </Text>
          </div>
        </Col>
        <Col
          md={!shouldReturnTravelRouteCard ? 8 : hasStartSchedule ? 3 : 2}
          className="text-center d-none d-sm-block"
        >
          <Text type="body" color="dark">
            Agendado
          </Text>
        </Col>
        {shouldReturnTravelRouteCard && (
          <>
            <Col md={2} className="text-center d-none d-sm-block">
              <Text type="body" color="dark">
                Chegada
              </Text>
            </Col>
            <Col
              md={2}
              className={hasStartSchedule ? ' text-right ml-2' : 'text-center'}
            >
              <Text type="body" color="dark">
                Efetivado
              </Text>
            </Col>
          </>
        )}
      </Row>
      <Row className="mb-3 d-flex align-items-center">
        <LocationItem
          id={origin?.id}
          isOrigin
          action="Coleta"
          isCompleted={isOriginCompleted}
          address={origin ? formatAddress(origin) : ''}
          rawAddress={origin}
          scheduledTime={origin?.scheduledTime}
          arrivalTime={origin?.arrivalTime}
          startSchedule={origin?.startSchedule}
          hasStartSchedule={hasStartSchedule}
          effectiveTime={origin?.effectiveTime}
          client={origin?.client}
          shouldReturnTravelRouteCard={shouldReturnTravelRouteCard}
        />
      </Row>
      <div className="mb-3">
        <Text type="body" color="dark">
          {pluralize('Destino', destinations?.length)}
        </Text>
      </div>
      {destinations?.map(destination => {
        const isCompleted =
          destination.arrivalTime && destination.effectiveTime;

        const action = actions[destination?.action?.toLowerCase()] || 'Coleta';

        return (
          <Row key={destination.id}>
            <LocationItem
              key={destination.id}
              id={destination.id}
              action={action}
              isCompleted={isCompleted}
              address={destination ? formatAddress(destination) : ''}
              rawAddress={destination}
              scheduledTime={destination?.scheduledTime}
              arrivalTime={destination?.arrivalTime}
              startSchedule={destination?.startSchedule}
              hasStartSchedule={hasStartSchedule}
              effectiveTime={destination?.effectiveTime}
              client={destination?.client}
              shouldReturnTravelRouteCard={shouldReturnTravelRouteCard}
            />
          </Row>
        );
      })}
      {!!waitingHours && !!waitingMinutes && (
        <Col className="text-center">
          <Text type="regular" color="gray">
            Tempo de espera total -
          </Text>
          <Text
            weight={500}
            type="regular"
          >{` ${waitingHours}:${waitingMinutes}hrs`}</Text>
        </Col>
      )}
    </Card>
  );
}
const actions = {
  delivery: 'Entrega',
  customhouse: 'Aduaneiro',
  collect: 'Coleta',
};
function LocationItem({
  isCompleted,
  action,
  address,
  scheduledTime,
  arrivalTime,
  effectiveTime,
  startSchedule,
  hasStartSchedule,
  client,
  shouldReturnTravelRouteCard,
}) {
  const [waitingTime, setWaitingTime] = useState();

  useEffect(() => {
    const diff = moment(effectiveTime).diff(moment(arrivalTime), 'minutes');
    if (diff < 0) {
      return;
    }
    const hours = parseInt(diff / 60, 10);
    const minutes = diff % 60;

    if (typeof diff === 'number') {
      if (hours < 10) {
        if (minutes < 10) setWaitingTime(`0${hours}:0${minutes}`);
        else setWaitingTime(`0${hours}:${minutes}`);
      } else if (minutes < 10) setWaitingTime(`${hours}:0${minutes}`);
      else setWaitingTime(`${hours}:${minutes}`);
    }
  }, [arrivalTime, effectiveTime]);

  return (
    <>
      <Col md={hasStartSchedule ? 4 : 6} xs={12}>
        <div className="d-flex pl-2">
          <div className="pt-2">
            <Styled.Marker.Tray
              completed={effectiveTime}
              progress={arrivalTime}
            />
          </div>
          <div className="position-relative pl-4">
            <Styled.Marker completed={effectiveTime} progress={arrivalTime}>
              {isCompleted && <FaCheck color="#fff" size={9} />}
            </Styled.Marker>
            <Text as="div" color="dark">
              {action}
            </Text>
            <Text as="div" type="regular" color="gray">
              {address}
            </Text>
            {client ? (
              <Text as="div" type="regular" color="gray" className="my-2">
                {`Cliente: ${client?.socialName} - ${client?.cpfCnpj}`}
              </Text>
            ) : null}
          </div>
        </div>
      </Col>
      <Styled.Schedules
        xs={12}
        md={!shouldReturnTravelRouteCard ? 8 : hasStartSchedule ? 8 : 6}
        completed={effectiveTime}
        progress={arrivalTime}
      >
        <Row>
          <Col>
            <div className="text-center">
              <Text as="div" color="dark" className="d-block d-sm-none mb-3">
                Agendado
              </Text>
              <div
                className={`d-flex flex-row ${
                  !shouldReturnTravelRouteCard && 'justify-content-center'
                }`}
              >
                {!!startSchedule && (
                  <>
                    <div>
                      <Text as="div" type="regular" color="dark">
                        {formatDateCustom(startSchedule, 'DD, MMMM [de] YYYY')}
                      </Text>
                      <FaClock color={theme.colors.dark} className="mr-1" />
                      <Text type="label" color="dark">
                        {formatTime(startSchedule)}
                      </Text>
                    </div>
                    <div className="mt-3">
                      <FaArrowRight color={theme.colors.dark} />
                    </div>
                  </>
                )}
                <div className={!hasStartSchedule && 'ml-4'}>
                  <Text as="div" type="regular" color="dark">
                    {formatDateCustom(scheduledTime, 'DD, MMMM [de] YYYY')}
                  </Text>
                  <FaClock color={theme.colors.dark} className="mr-1" />
                  <Text type="label" color="dark">
                    {formatTime(scheduledTime)}
                  </Text>
                </div>
              </div>
            </div>
          </Col>
          {shouldReturnTravelRouteCard && (
            <>
              <Col>
                <div className="text-center">
                  {
                    <>
                      <Text
                        as="div"
                        color="dark"
                        className="d-block d-sm-none mb-3"
                      >
                        Chegada
                      </Text>
                      <Text as="div" type="regular" color="dark">
                        {formatDateCustom(arrivalTime, 'DD, MMMM [de] YYYY')}
                      </Text>
                      <FaClock color={theme.colors.dark} className="mr-1" />
                      <Text type="label" color="dark">
                        {formatTime(arrivalTime)}
                      </Text>
                    </>
                  }
                </div>
              </Col>
              {arrivalTime && effectiveTime && waitingTime && (
                <span title="Tempo de espera">
                  <FaClock color={theme.colors.dark} className="mr-1" />
                  <Text type="label" color="dark">
                    {waitingTime}
                  </Text>
                </span>
              )}
              <Col>
                <div className="text-center">
                  {
                    <>
                      <Text
                        as="div"
                        color="dark"
                        className="d-block d-sm-none mb-3"
                      >
                        Efetivado
                      </Text>
                      <Text as="div" type="regular" color="dark">
                        {formatDateCustom(effectiveTime, 'DD, MMMM [de] YYYY')}
                      </Text>
                      <FaClock color={theme.colors.dark} className="mr-1" />
                      <Text type="label" color="dark">
                        {formatTime(effectiveTime)}
                      </Text>
                    </>
                  }
                </div>
              </Col>
            </>
          )}
        </Row>
      </Styled.Schedules>
    </>
  );
}
