import styled, { css } from 'styled-components';

import {
  Grid,
  Tabs,
  Tab,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core';

import Button from 'components/Button/styles';

export const ContentInvoice = styled(Grid)`
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      color: ${props => props.theme.colors.secondary};
      text-transform: uppercase;
      margin: 8px 0;
      label {
        font-weight: bold;
        margin-right: 4px;
      }
    }
  }
`;

export const StyledTabs = styled(Tabs)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  border-bottom: 1px solid ${({ theme }) => theme.colors.background};

  > div {
    overflow: auto !important;

    > span {
      background-color: ${({ theme }) => theme.colors.primary};

      width: 120px;
      height: 3px;
    }
  }
`;

export const StyledTab = styled(Tab).attrs({ disableRipple: true })`
  && {
    min-width: 120px !important;
  }

  > span {
    font-size: 1rem;
    font-weight: 600;

    ${({ active, theme }) =>
      active &&
      css`
        color: ${theme.colors.primary};
      `}
  }
`;

export const StyledTableBody = styled(TableBody)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.background};
`;

export const StyledTableRow = styled(TableRow)`
  &:nth-child(even) {
    background-color: ${({ theme }) => theme.colors.background};
  }

  > th,
  td {
    border-bottom: none;

    color: ${({ theme }) => theme.colors.secondary};
    font-weight: 300;

    padding-bottom: 10px;
    padding-top: 10px;
  }
`;

export const StyledTableCell = styled(TableCell)``;

export const CloseButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
`;

export const CloseButton = styled.button`
  display: flex;
  align-items: center;

  background-color: transparent;
  color: ${({ theme }) => theme.colors.secondary};

  svg {
    color: ${({ theme }) => theme.colors.secondary};

    width: 21px;
    height: 21px;
  }

  span {
    text-transform: uppercase;
  }
`;

export const DocumentsContainer = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.background};

  color: ${({ theme }) => theme.colors.secondary};
  font-size: 1rem;
  font-weight: lighter;

  ${Button} {
    font-size: 0.9rem;

    min-width: 95px;

    &:first-child {
      margin-right: 5px;
    }
  }
`;

export const DocumentCTE = styled.span`
  font-weight: 600;
`;

export const DocumentType = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 1rem;
  font-weight: 400;
  line-height: 18px;

  margin: 10px 0px 10px 5px;
`;
