import React from 'react';
import { Col } from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa';
import { Button, Fallback, Input, Text } from 'v3/components';
import Tab from 'v3/components/Tab';
import Tabs from 'v3/components/Tabs';
import { SectionContentHeader, SectionHeader } from 'v3/pages/Policy/styles';
import { applyMonetaryMask } from 'v3/utils/functions';
import { usePolicyRegister } from '../../context';
import { RiskManagerSection } from './RiskManagerSection';
import { GeneralRulesSection } from './GeneralRulesSection';
import { ManagersSection } from './ManagersSection';
import { MonitoringEquipamentsSection } from './MonitoringEquipamentsSection';
import { SearchConsultationSection } from './SearchConsultationSection';
import { InsertButton } from './InsertButton';
import { MAX_RANGES } from '../../utils';

export function Ranges({ vehicle, opIndex, productIndex, vehicleIdx }) {
  const {
    filterPolicyInUse,
    operations,
    setCurrentOpIndex,
    setCurrentPdIndex,
    setCurrentVehicleIndex,
    setCurrentRangeIndex,
    setDialogStates,
    setDialogPolicyInUse,
    errors,
    setErrors,
    setOperations,
    setHasRangeValueError,
    handleOpenRangeModal,
    hasRangeValueError,
    policyCurrency,
    rangesInUse,
  } = usePolicyRegister();

  const currencySymbol = policyCurrency?.value === 'BRL' ? 'R$' : '$';

  const hasMinRangeAsZero =
    operations[opIndex]?.products[productIndex]?.vehicles[vehicleIdx]?.ranges
      ?.map(range => {
        const policyRangeMin = range?.policy_range_min;
        if (typeof policyRangeMin === 'string') {
          return policyRangeMin.replace(/[^\d,]/g, '').replace(/,/g, '.');
        }
        return policyRangeMin;
      })
      ?.findIndex(element => Number(element) === 0) !== -1;

  const canAddBelowRange =
    operations[opIndex].products[productIndex].vehicles[vehicleIdx].ranges
      ?.length >= 1 &&
    operations[opIndex].products[productIndex].vehicles[vehicleIdx].ranges
      ?.length < MAX_RANGES &&
    hasMinRangeAsZero === false;

  function handleAddMaxValue(key, value, index) {
    const numberValue = value.replace(/[^\d,]/g, '').replace(/,/g, '.');
    const canUpdateNextMin = operations[opIndex].products[productIndex]
      .vehicles[vehicleIdx].ranges[index + 1]
      ? Number(numberValue) + 0.01 <
        operations[opIndex].products[productIndex].vehicles[vehicleIdx].ranges[
          index + 1
        ]?.policy_range_max
          .replace(/[^\d,]/g, '')
          .replace(/,/g, '.')
      : true;
    const isLessOrEqualToOwnMin =
      Number(numberValue) <=
      Number(
        operations[opIndex].products[productIndex].vehicles[vehicleIdx].ranges[
          index
        ]?.policy_range_min
          .replace(/[^\d,]/g, '')
          .replace(/,/g, '.')
      );
    const updatedRange = operations[opIndex].products[productIndex].vehicles[
      vehicleIdx
    ].ranges.map((item, idx) => {
      if (idx === index) {
        return {
          ...item,
          [key]: value,
        };
      }
      if (idx === index + 1 && canUpdateNextMin) {
        return {
          ...item,
          policy_range_min: applyMonetaryMask(Number(numberValue) + 0.01),
        };
      }
      return item;
    });
    const newState = [...operations];
    newState[opIndex].products[productIndex].vehicles[vehicleIdx].ranges =
      updatedRange;
    setOperations(newState);

    if (canUpdateNextMin === false || isLessOrEqualToOwnMin) {
      setErrors(old => ({
        ...old,
        [`operations[${opIndex}].products[${productIndex}].vehicles[${vehicleIdx}].ranges[${index}].policy_range_max`]:
          isLessOrEqualToOwnMin
            ? 'Valor não pode ser igual/menor que início da faixa'
            : canUpdateNextMin === false
            ? 'Valor não pode ser igual/maior do que máximo da faixa posterior'
            : '',
      }));
      setHasRangeValueError(true);
      return;
    }
    setErrors(old => ({
      ...old,
      [`operations[${opIndex}].products[${productIndex}].vehicles[${vehicleIdx}].ranges[${index}].policy_range_max`]:
        '',
    }));
    setHasRangeValueError(false);
  }
  function handleAddBelowRange(opIndex, productIndex, vehicleIdx) {
    const masked = applyMonetaryMask(
      Number(
        operations[opIndex].products[productIndex].vehicles[
          vehicleIdx
        ].ranges[0]?.policy_range_min
          .replace(/[^\d,]/g, '')
          .replace(/,/g, '.')
      ) - 0.01
    );
    const belowRange = {
      monitoringEquipaments: [
        {
          bait_quantity: 0,
          equipament: [],
          locator: [],
          immobilizer: [],
          bait: [],
        },
      ],
      riskAreas: [],
      showSearchConsultation: true,
      showMonitoringEquipaments: true,
      showManagers: true,
      showGeneralRules: true,
      showRiskManager: true,
      policy_range_max: masked,
      policy_range_min: `0`,
      starting_schedule: '',
      checklist_validity: '',
      manager_contact: '',
      finishing_schedule: '',
      active: true,
      rdo: [],
      search_consulting: [],
      manager_mirror: [],
      manager_tracker: [],
      check_manager: [],
      validity_check_manager: [],
      manager_query: [],
      sensors: [],
      min_time_antt: null,
      driver_guide: '',
      driver_travel_min: '',
      obs: '',
      obs_company: '',
    };
    const newState = [...operations];
    newState[opIndex].products[productIndex].vehicles[vehicleIdx].ranges = [
      belowRange,
      ...newState[opIndex].products[productIndex].vehicles[vehicleIdx].ranges,
    ];
    setOperations(newState);
  }
  function handleAddMinValue(key, value, index) {
    const numberValue = value.replace(/[^\d,]/g, '').replace(/,/g, '.');
    const updatedRange = operations[opIndex].products[productIndex].vehicles[
      vehicleIdx
    ].ranges.map((item, idx) => {
      if (idx === index) {
        return {
          ...item,
          [key]: value,
        };
      }
      return item;
    });
    const newState = [...operations];
    newState[opIndex].products[productIndex].vehicles[vehicleIdx].ranges =
      updatedRange;
    setOperations(newState);
    const isMoreOrEqualToOwnMax =
      Number(numberValue) >=
      Number(
        operations[opIndex].products[productIndex].vehicles[vehicleIdx].ranges[
          index
        ]?.policy_range_max
          .replace(/[^\d,]/g, '')
          .replace(/,/g, '.')
      );
    if (isMoreOrEqualToOwnMax) {
      setErrors(old => ({
        ...old,
        [`operations[${opIndex}].products[${productIndex}].vehicles[${vehicleIdx}].ranges[${index}].policy_range_min`]:
          isMoreOrEqualToOwnMax
            ? 'Valor não pode ser igual/maior do que máximo da faixa'
            : '',
      }));
      setHasRangeValueError(true);
      return;
    }
    setErrors(old => ({
      ...old,
      [`operations[${opIndex}].products[${productIndex}].vehicles[${vehicleIdx}].ranges[${index}].policy_range_min`]:
        '',
    }));
    setHasRangeValueError(false);
  }

  function handleRemoveRange(opIndex, productIndex, vehicleIdx, rangeIdx) {
    const range = vehicle?.ranges[rangeIdx];
    const rangeInUse = filterPolicyInUse('policy_range_id', range.id);
    if (rangeInUse.length > 0) {
      setDialogPolicyInUse({
        open: true,
        label: 'faixa de valor',
        loads: rangeInUse,
      });
      return;
    }

    setCurrentOpIndex(opIndex);
    setCurrentPdIndex(productIndex);
    setCurrentVehicleIndex(vehicleIdx);
    setCurrentRangeIndex(rangeIdx);
    setDialogStates(old => ({
      ...old,
      range: true,
    }));
  }
  return (
    <>
      <SectionHeader>
        <Text weight={500} color="dark" type="regular">
          Faixas de Valores
        </Text>
        <div>
          {canAddBelowRange ? (
            <InsertButton
              onClick={() =>
                handleAddBelowRange(opIndex, productIndex, vehicleIdx)
              }
              title={`${
                hasRangeValueError
                  ? 'Verifique valores das faixas'
                  : 'Inserir faixa de valor inferior'
              }`}
              disabled={
                hasRangeValueError ||
                operations[opIndex].products[productIndex].vehicles[vehicleIdx]
                  .ranges?.length === MAX_RANGES
              }
              label="Inserir faixa de valor inferior"
            />
          ) : null}
          <InsertButton
            onClick={() =>
              handleOpenRangeModal(opIndex, productIndex, vehicleIdx)
            }
            title={`${
              hasRangeValueError
                ? 'Verifique valores das faixas'
                : 'Inserir nova faixa de valor'
            }`}
            disabled={
              hasRangeValueError ||
              operations[opIndex].products[productIndex].vehicles[vehicleIdx]
                .ranges?.length === MAX_RANGES
            }
            label="Inserir faixa de valor"
          />
        </div>
      </SectionHeader>
      <Fallback
        fall={
          vehicle?.ranges?.length === 0 ||
          errors[
            `operations[${opIndex}].products[${productIndex}].vehicles[${vehicleIdx}].ranges`
          ]
        }
        component={
          <Col
            xs={12}
            className="pt-5 d-flex justify-content-center align-items-center"
          >
            {errors[
              `operations[${opIndex}].products[${productIndex}].vehicles[${vehicleIdx}].ranges`
            ] ? (
              <Text color="danger" weight={500}>
                Informe no mínimo uma faixa
              </Text>
            ) : (
              <Button size="md" disabled>
                Não há faixas cadastradas
              </Button>
            )}
          </Col>
        }
      >
        <Tabs
          defaultActiveKey={0}
          style={{
            display: 'flex',
            flexWrap: 'nowrap',
            overflowX: 'auto',
            overflowY: 'hidden',
            fontSize: '0.75rem',
          }}
          linkActiveColor="dark"
          linkActiveBackgroundColor="grayish_blue"
          linkWeight="500"
          linkPadding="0.5rem 1rem"
        >
          {vehicle?.ranges?.map((range, rangeIdx) => {
            const hasPredecessor =
              operations[opIndex].products[productIndex].vehicles[vehicleIdx]
                .ranges[rangeIdx - 1] !== undefined;
            const rangeInUse = rangesInUse.includes(range?.id);
            const rangeInUseLabel = `Não é possível editar pois está sendo utilizada nas cargas ${rangesInUse?.join(
              ','
            )}`;
            return (
              <Tab
                title={`${currencySymbol} ${range?.policy_range_min} até ${currencySymbol} ${range?.policy_range_max}`}
                eventKey={rangeIdx}
                key={rangeIdx}
                style={{
                  padding: '1rem 0.5rem',
                }}
              >
                <>
                  <SectionContentHeader>
                    <div>
                      <button
                        type="button"
                        className="removeBtn"
                        onClick={() =>
                          handleRemoveRange(
                            opIndex,
                            productIndex,
                            vehicleIdx,
                            rangeIdx
                          )
                        }
                      >
                        <FaTrash />
                        <Text type="medium" weight={500}>
                          remover faixa
                        </Text>
                      </button>
                    </div>
                  </SectionContentHeader>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      gap: '0.25rem',
                    }}
                  >
                    <Input
                      label={`Valor inicial (${currencySymbol})`}
                      value={range?.policy_range_min || '0'}
                      onChange={e => {
                        if (String(e?.target?.value)?.length > 16) {
                          return;
                        }
                        const masked = applyMonetaryMask(e?.target?.value);
                        handleAddMinValue('policy_range_min', masked, rangeIdx);
                      }}
                      placeholder={currencySymbol}
                      error={
                        errors[
                          `operations[${opIndex}].products[${productIndex}].vehicles[${vehicleIdx}].ranges[${rangeIdx}].policy_range_min`
                        ]
                      }
                      title={rangeInUse ? rangeInUseLabel : ''}
                      disabled={hasPredecessor || rangeInUse}
                    />

                    <Input
                      label={`Valor final (${currencySymbol})`}
                      value={range?.policy_range_max}
                      onChange={e => {
                        if (String(e?.target?.value)?.length > 16) {
                          return;
                        }
                        const masked = applyMonetaryMask(e?.target?.value);

                        handleAddMaxValue('policy_range_max', masked, rangeIdx);
                      }}
                      placeholder={currencySymbol}
                      error={
                        errors[
                          `operations[${opIndex}].products[${productIndex}].vehicles[${vehicleIdx}].ranges[${rangeIdx}].policy_range_max`
                        ]
                      }
                      disabled={rangeInUse}
                      title={rangeInUse ? rangeInUseLabel : ''}
                    />
                  </div>
                </>
                <SearchConsultationSection
                  opIndex={opIndex}
                  productIndex={productIndex}
                  vehicleIdx={vehicleIdx}
                  rangeIdx={rangeIdx}
                  range={range}
                />

                <MonitoringEquipamentsSection
                  opIndex={opIndex}
                  productIndex={productIndex}
                  vehicleIdx={vehicleIdx}
                  rangeIdx={rangeIdx}
                  range={range}
                />
                <ManagersSection
                  opIndex={opIndex}
                  productIndex={productIndex}
                  vehicleIdx={vehicleIdx}
                  rangeIdx={rangeIdx}
                  range={range}
                />

                <GeneralRulesSection
                  opIndex={opIndex}
                  productIndex={productIndex}
                  vehicleIdx={vehicleIdx}
                  rangeIdx={rangeIdx}
                  range={range}
                />
                <RiskManagerSection
                  opIndex={opIndex}
                  productIndex={productIndex}
                  vehicleIdx={vehicleIdx}
                  rangeIdx={rangeIdx}
                  range={range}
                />
              </Tab>
            );
          })}
        </Tabs>
      </Fallback>
    </>
  );
}
