import React, { useMemo } from 'react';
import { Col } from 'react-bootstrap';
import { useModal } from 'hooks';
import { makeConversion } from 'v3/utils/formatter';
import { Card, Text } from 'v3/components';
import { LoadContext } from '../controller';
import { shipmentTransferToShow } from './utils';
import ExtraFieldsModal from '../Modals/ExtraFields';
import {
  CardHeaderComponent,
  ExtraFieldsRow,
  ExtraItemRow,
  FreightItemRow,
  HeaderTotalSummary,
  LabelsRow,
  ObservationsContainer,
  SummaryRow,
} from './components';
import { ExtraFieldsContainer } from './styles';

export function ShipperTransfer({ isBRLCurrency, dollarToUse }) {
  const [, useLoad] = LoadContext;
  const load = useLoad();
  const extraFieldsModal = useModal();
  const shouldUseToll =
    shipmentTransferToShow?.includes(load?.data?.action_toll?.code) &&
    load?.data?.toll_value > 0;
  const shouldUseDischarge =
    shipmentTransferToShow?.includes(load?.data?.action_discharge?.code) &&
    load?.data?.discharge_value > 0;
  const shouldUseGris =
    shipmentTransferToShow?.includes(
      load?.data?.action_griss_advalorem?.code
    ) && load?.data?.gris > 0;
  const shouldUseAd =
    shipmentTransferToShow?.includes(
      load?.data?.action_griss_advalorem?.code
    ) && load?.data?.ad_valorem > 0;
  const shouldUseCharge =
    shipmentTransferToShow?.includes(load?.data?.action_charge?.code) &&
    load?.data?.charge_value > 0;
  const shouldUseFerry =
    shipmentTransferToShow?.includes(load?.data?.action_ferry?.code) &&
    load?.data?.ferry > 0;
  const totalComputed = useMemo(() => {
    let total = 0;
    if (load?.transferExtraFields?.length) {
      total = load?.transferExtraFields.reduce((acc, curr) => {
        return acc + Number(curr?.value);
      }, 0);
    }
    if (
      shouldUseToll &&
      load?.data?.action_toll?.code !== 'client_provides_card'
    ) {
      total += Number(load?.data?.toll_value);
    }
    if (shouldUseDischarge) {
      total += Number(load?.data?.discharge_value);
    }
    if (shouldUseGris) {
      total += Number(load?.data?.gris);
    }
    if (shouldUseAd) {
      total += Number(load?.data?.ad_valorem);
    }
    if (shouldUseCharge) {
      total += Number(load?.data?.charge_value);
    }
    if (shouldUseFerry) {
      total += Number(load?.data?.ferry);
    }
    load.setData(old => ({
      ...old,
      total_shipment_transfer: total,
    }));
    return total;
  }, [
    load?.data?.toll_value,
    load?.data?.discharge_value,
    load?.data?.charge_value,
    load?.data?.gris,
    load?.data?.ad_valorem,
    load?.data?.ferry,
    load?.transferExtraFields,
    load?.data?.action_toll,
    shouldUseToll,
    shouldUseDischarge,
    shouldUseGris,
    shouldUseCharge,
    shouldUseFerry,
    shouldUseAd,
  ]);
  function convertValue(rawValue) {
    const operation = isBRLCurrency ? '/' : '*';
    const processedValue = +makeConversion(rawValue, operation, dollarToUse);
    return operation === '/'
      ? processedValue.toFixed(4)
      : processedValue.toFixed(2);
  }
  const transferTags = useMemo(() => {
    let tags = '';

    if (
      shouldUseToll &&
      load?.data?.action_toll?.code !== 'client_provides_card'
    ) {
      tags += 'Pedágio,';
    }
    if (shouldUseDischarge) {
      tags += 'Descarga,';
    }
    if (shouldUseCharge) {
      tags += 'Taxa de Carregamento,';
    }
    if (shouldUseGris) {
      tags += 'Gris,';
    }
    if (shouldUseAd) {
      tags += 'Ad Valorem,';
    }
    if (shouldUseFerry) {
      tags += 'Balsa,';
    }
    if (load?.transferExtraFields?.length > 0) {
      tags += 'Valores extras,';
    }
    return tags;
  }, [
    load?.transferExtraFields,
    load?.data?.action_toll,
    shouldUseToll,
    shouldUseDischarge,
    shouldUseGris,
    shouldUseCharge,
    shouldUseFerry,
    shouldUseAd,
  ]);
  return (
    <Col xs={12}>
      <ExtraFieldsModal
        isOpen={extraFieldsModal.isOpen}
        onClose={extraFieldsModal.close}
        setExtraFields={load.setTransferExtraFields}
        errors={load.errors}
        extraFields={load?.transferExtraFields}
        dollarToUse={dollarToUse}
        isBRLCurrency={isBRLCurrency}
        loadCurrency={load?.data?.currency?.value === 'BRL' ? 'R$' : '$'}
      />
      <Card containerCardBoxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)">
        <CardHeaderComponent
          label="Repasse ao Transportador"
          tooltipContent="Valores a serem repassados ao transportador conforme itens definidos, não contempla taxa administrativa sobre o valor."
          isOpen={load?.transferCardToggle}
          setIsOpen={() =>
            load.setTransferCardToggle(!load?.transferCardToggle)
          }
          totalsSection={
            <HeaderTotalSummary
              label="Crédito Transportador"
              totalInReal={
                isBRLCurrency ? totalComputed : convertValue(totalComputed)
              }
              totalInDollar={
                !isBRLCurrency ? totalComputed : convertValue(totalComputed)
              }
            />
          }
          hasTagsRow
          tagsContent={transferTags}
        />
        {load?.transferCardToggle && (
          <>
            <LabelsRow />
            {shouldUseToll && (
              <FreightItemRow
                isBRLCurrency={isBRLCurrency}
                dollarToUse={dollarToUse}
                label="Pedágio"
                value={load?.data?.toll_value}
                shouldDisableField
                id="credito-pedagio"
              />
            )}
            {shouldUseDischarge && (
              <FreightItemRow
                isBRLCurrency={isBRLCurrency}
                dollarToUse={dollarToUse}
                label="Descarga"
                value={load?.data?.discharge_value}
                shouldDisableField
                id="credito-descarga"
              />
            )}
            {shouldUseGris && (
              <FreightItemRow
                isBRLCurrency={isBRLCurrency}
                dollarToUse={dollarToUse}
                percentageValueReference={load?.data?.cargoValue}
                label="Griss"
                value={load?.data?.gris}
                percentageValue={load?.data?.gris_percentage || '0'}
                hasTooltip
                tooltipContent="Valor aproximado, pode variar de acordo com o valor do produto na Nota Fiscal"
                shouldDisableField
                id="credito-gris"
              />
            )}
            {shouldUseAd && (
              <FreightItemRow
                isBRLCurrency={isBRLCurrency}
                dollarToUse={dollarToUse}
                label="AD Valorem"
                percentageValueReference={load?.data?.cargoValue}
                value={load?.data?.ad_valorem}
                percentageValue={load?.data?.ad_valorem_percentage || '0'}
                shouldDisableField
                id="credito-ad-valorem"
              />
            )}
            {shouldUseCharge && (
              <FreightItemRow
                isBRLCurrency={isBRLCurrency}
                dollarToUse={dollarToUse}
                label="Taxa de carregamento"
                value={load?.data?.charge_value}
                shouldDisableField
                id="credito-taxa-carregamento"
              />
            )}
            {shouldUseFerry && (
              <FreightItemRow
                isBRLCurrency={isBRLCurrency}
                dollarToUse={dollarToUse}
                label="Balsa"
                value={load?.data?.ferry}
                shouldDisableField
                id="credito-balsa"
              />
            )}
            <>
              {load?.transferExtraFields?.length > 0 ? (
                <Text type="regular" weight={500}>
                  Valores extras
                </Text>
              ) : null}
              <ExtraFieldsContainer>
                {load?.transferExtraFields?.map((item, index) => {
                  return (
                    <ExtraItemRow
                      key={`${index}_${new Date().getTime()}`}
                      label={item?.label}
                      value={item?.value}
                      dollarToUse={dollarToUse}
                      isBRLCurrency={isBRLCurrency}
                      onSetExtraFields={() =>
                        load.setTransferExtraFields(
                          load?.transferExtraFields?.filter(
                            (_, idx) => idx !== index
                          )
                        )
                      }
                      showToDriver={item?.show_to_driver}
                      id={`credito-extra-${index}`}
                    />
                  );
                })}
              </ExtraFieldsContainer>
            </>
            <SummaryRow
              label="Valor Crédito"
              totalInBRL={
                isBRLCurrency ? totalComputed : convertValue(totalComputed)
              }
              totalInUSD={
                !isBRLCurrency ? totalComputed : convertValue(totalComputed)
              }
              id="valor-crédito"
            />
            <ExtraFieldsRow
              id="button-abrir-valores-extras-valor-credito"
              openModal={() => extraFieldsModal.open()}
            />
            <ObservationsContainer
              pcpLabel="Observações PCP"
              pcpValue={load?.data?.pcp_observation}
              onChangePcpValue={event =>
                load.setData(old => ({
                  ...old,
                  pcp_observation: event.target.value,
                }))
              }
              pcpError={load?.errors?.pcp_observation}
              id="observacoes"
            />
          </>
        )}
      </Card>
    </Col>
  );
}
