import styled from 'styled-components';
import theme from 'v3/theme';

export const VerticalRule = styled.div`
  border: 0.5px solid ${theme.colors.secondary};
  height: 470px;
  width: 1px;
  margin-left: 50px;
  margin-right: 50px;
`;
