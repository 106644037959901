import React from 'react';
import moment from 'moment';

import { isNotEmptyArray } from '../../../utils';

import Loading from '../../../components/Loading';

import './style.css';

function Message({
  data,
  isMine,
  startsSequence,
  endsSequence,
  showTimestamp,
  onOptionClick,
}) {
  const friendlyTimestamp = moment(data.created_at).format('LLLL');

  return (
    <div
      className={[
        'message',
        `${isMine ? 'mine' : ''}`,
        `${startsSequence ? 'start' : ''}`,
        `${endsSequence ? 'end' : ''}`,
      ].join(' ')}
    >
      {showTimestamp && <div className="timestamp">{friendlyTimestamp}</div>}
      <div className="bubble-container">
        <div className="bubble" title={friendlyTimestamp}>
          {data.message}
          {!!isNotEmptyArray(data.options) && (
            <div className="option-container">
              {data.options.map(option => (
                <button
                  type="button"
                  className={[
                    'option',
                    option.disabled || option.loading ? 'disabled' : '',
                  ].join(' ')}
                  onClick={() => onOptionClick(option)}
                  disabled={option.disabled || option.loading}
                >
                  {option.loading ? <Loading /> : option.text}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Message;
