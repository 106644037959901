// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import api from 'services/api';
import { usePermission } from 'hooks';

import ApplicationLayout from 'v3/layouts/Application';

import { Button, Text, InfiniteScroll } from 'v3/components';
import { useSnackbar } from 'v3/components/Snackbar';
import { formatCurrency, formatCustomCurrency } from 'v3/utils/formatter';

import { Container } from './style';

import Filter from './Filter';

import { currentYear } from '../shared/utils';

export default function MinimunFreightTable() {
  const snackbar = useSnackbar();
  const hasPermissionToCreate = usePermission('CADASTRAR_TABELA_FRETE_MINIMO');
  usePermission('VISUALIZAR_TABELA_FRETE_MINIMO', { redirect: true });

  const [item, setItem] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    label: String(currentYear),
    value: currentYear,
  });

  async function fetchData() {
    try {
      setLoading(true);
      const response = await api.get(`v3/antt-data/list/${filter?.value}`);
      const { data } = response;
      setItem(data);
    } catch (error) {
      setItem([]);
      snackbar.show(<Text>Algo deu errado, tente novamente mais tarde</Text>, {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchData();
  }, []);

  function renderItem(item) {
    return (
      <Container key={item?.id}>
        <div className="col-3" my-2 mb-3>
          <Text weight={500} type="regular" color="dark">
            {item?.nome}
          </Text>
        </div>
        <div className="col-2" my-2 mb-3>
          <Text weight={500} type="regular" color="dark">
            {formatCustomCurrency(Number(item?.value), 'BRL', 'R$')}
          </Text>
        </div>
        <div className="col-2" my-2 mb-3>
          <Text weight={500} type="regular" color="dark">
            {formatCurrency(Number(item?.charge_price), 'BRL', 'R$')}
          </Text>
        </div>
        <div className="col-1" my-2 mb-3>
          <Text weight={500} type="regular" color="dark">
            {item?.qty_axys}
          </Text>
        </div>
        <div className="col-2" my-2 mb-3>
          <Text weight={500} type="regular" color="dark">
            {item?.year}
          </Text>
        </div>
        <div className="col-2" my-2 mb-3>
          <Link to={`/tabela-frete-minimo-antt/${item?.id}`}>
            <Button weight={500} type="regular" color="white">
              Detalhes
            </Button>
          </Link>
        </div>
      </Container>
    );
  }

  return (
    <>
      <ApplicationLayout
        title="Tabela Frete Mínimo (ANTT)"
        RightComponent={
          <Link to="/tabela-frete-minimo-antt/cadastrar">
            <Button variant="primary" disabled={!hasPermissionToCreate}>
              <Text weight={500} color="white" type="regular">
                Cadastrar
              </Text>
            </Button>
          </Link>
        }
      >
        <Filter fetchData={fetchData} filter={filter} setFilter={setFilter} />
      </ApplicationLayout>
      <div className="mt-5">
        {item.length > 0 && (
          <div className="d-flex text-center">
            <div className="col-3 my-2 mb-3">
              <Text weight={500} type="header" color="dark">
                Tipo de Carga
              </Text>
            </div>

            <div className="col-2 my-2 mb-3">
              <Text weight={500} type="header" color="dark">
                Coeficiente de Custo de Deslocamento (CCD) em R$/km
              </Text>
            </div>

            <div className="col-2 my-2 mb-3">
              <Text weight={500} type="header" color="dark">
                Coeficiente de Custo de Carga e descarga (CC) em R$
              </Text>
            </div>

            <div className="col-1 my-2 mb-3">
              <Text weight={500} type="header" color="gray">
                Número de Eixos
              </Text>
            </div>

            <div className="col-2 my-2 mb-3">
              <Text weight={500} type="header" color="gray">
                Ano Base
              </Text>
            </div>
            <div className="col-2 my-2 mb-3"></div>
          </div>
        )}
      </div>
      <div className="col-12">
        <InfiniteScroll
          data={item}
          hasMore={false}
          scrollThreshold={0.6}
          renderItem={renderItem}
          loading={loading}
        />
      </div>
    </>
  );
}
