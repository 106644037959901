import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import api from 'services/api';

import { Modal, Text, Select, Input, Button } from 'v3/components';
import InputAddress from 'v3/components/InputAddress';
import { updateAddressSchema } from '../validator';
import { useSnackbar } from 'v3/components/Snackbar';

export function UpdateClientAddressData({
  show,
  onClose,
  currentAddressData,
  countryOptions,
  onSubmit = ()=>{}
}) {
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [disableNumber, setDisableNumber] = useState(false);
  const [disableZipCode, setDisableZipCode] = useState(false);
  const [disableCity, setDisableCity] = useState(false);
  const [disableProvince, setDisableProvince] = useState(false);
  const [disableNeighborhood, setDisableNeighborhood] = useState(false);
  const [addressDisabled, setAddressDisabled] = useState(false);
  const [fullAddress, setFullAddress] = useState({});
  const [country, setCountry] = useState({
    id: 1,
    name: 'Brasil',
    abbreviation: 'br',
    ddi: '55',
    currency: 'BRL',
    currency_symbol: 'R$',
  });
  useEffect(() => {
    if (show && currentAddressData) {
      handleInitializeData();
    }
  }, [show, currentAddressData]);
  function handleInitializeData() {
    setFullAddress({
      city: currentAddressData?.city_id
        ? {
            name: currentAddressData?.city_name,
            id: currentAddressData?.city_id,
          }
        : null,
      address: currentAddressData?.address,
      formatted_address: currentAddressData?.formatted_address,
      number: currentAddressData?.number,
      complement: currentAddressData?.complement,
      neighborhood: currentAddressData?.neighborhood,
      zip_code: currentAddressData?.zip_code,
      lat: currentAddressData?.lat,
      lng: currentAddressData?.lng,
      province: currentAddressData?.uf_name,
    });
  }
  function handleResetStates() {
    setAddressDisabled(false);
    setDisableCity(false);
    setDisableNeighborhood(false);
    setDisableProvince(false);
    setDisableNumber(false);
    setDisableZipCode(false);
    setFullAddress({
      city: null,
      formatted_address: '',
      address: '',
      zip_code: '',
      number: '',
      neighborhood: '',
      province: '',
      complement: '',
      lat: '',
      lng: '',
    });
  }
  async function verifySchema(payload) {
    try {
      await updateAddressSchema.validate(payload, {
        abortEarly: false,
      });
      return true;
    } catch (error) {
      const errorResponse = {};
      if (error.inner) {
        error.inner.forEach(error => {
          errorResponse[error.path] = error.message;
        });
        setErrors(errorResponse);
      }
      return false;
    }
  }
  async function handleUpdateAddress() {
    try {
      setLoading(true);
      const isValid = await verifySchema({
        formatted_address: fullAddress?.formatted_address,
        address: fullAddress?.address,
        neighborhood: fullAddress?.neighborhood,
        city_name: fullAddress?.city?.name,
        city_id: fullAddress?.city?.id,
        country_id: country?.id,
        province: fullAddress?.province,
        lat: fullAddress?.lat,
        lng: fullAddress?.lng,
      });

      if (isValid === false) {
        setLoading(false);
        return;
      }
      const response = await api.put(
        `v3/client/${currentAddressData.person_id}/address`,
        {
          data: {
            id: currentAddressData?.id,
            city_name: fullAddress.city?.name,
            current: true,
            address: fullAddress.address,
            person_id: currentAddressData.person_id,
            zip_code: fullAddress.zip_code,
            number: fullAddress.number,
            complement: fullAddress.complement,
            neighborhood: fullAddress.neighborhood,
            uf_name: fullAddress.province,
            lat: fullAddress?.lat,
            lng: fullAddress?.lng,
            city_id: fullAddress.city?.id,
            province_id: fullAddress?.city?.province_id,
            country_id: country?.id,
            formatted_address: fullAddress?.formatted_address,
          },
        }
      );
      setLoading(false);
      setErrors({});
      onSubmit(response?.data[0]);
      handleResetStates();
      onClose();
    } catch (err) {
      setLoading(false);
      snackbar.show(<Text>Erro ao atualizar endereço</Text>, {
        type: 'error',
      });
    }
  }

  function handleClose() {
    if (loading) {
      return;
    }
    handleResetStates();
    onClose();
  }
  async function fetchProvinces(search, uf, country) {
    try {
      const response = await api.get('cities', {
        params: { search, uf, country },
      });
      return response.data;
    } catch (ex) {
      return [];
    }
  }
  function disableAddress(address, city) {
    setAddressDisabled(address?.address ? true : false);
    setDisableNumber(address.number ? true : false);
    setDisableZipCode(address.zip_code ? true : false);
    setDisableCity(city ? true : false);
    setDisableProvince(city?.province ? true : false);
    setDisableNeighborhood(address.neighborhood ? true : false);
  }
  return (
    <Modal
      show={show}
      handleClose={() => handleClose()}
      heading={
        <Text type="header" color="dark">
          Atualizar endereço
        </Text>
      }
      body={
        <Row className="px-2 form">
          <Col xs={12}>
            <Select
              label="País"
              options={countryOptions}
              value={country}
              onChange={value => setCountry(value)}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              disableClear
              error={errors?.country_id}
            />
          </Col>
          <Col xs={12}>
            <InputAddress
              label="Procure seu endereço *"
              placeholder="Digite seu endereço"
              country={country.abbreviation}
              types={[]}
              onChange={value => {
                setFullAddress(prev => ({
                  ...prev,
                  formatted_address: value.target.value,
                }));
              }}
              onSelectedAddress={async place => {
                const [city] = await fetchProvinces(
                  place.city,
                  place.province,
                  place.country_abbreviation
                );
                disableAddress(place, city);

                setFullAddress(prev => ({
                  ...prev,
                  address: place.address ? place.address : '',
                  number: place?.number ? place.number : '',
                  zip_code: place.zip_code ? place.zip_code : '',
                  city: city,
                  complement: place.name ? place.name : '',
                  neighborhood: place.neighborhood ? place.neighborhood : '',
                  province: city?.province?.uf || '',
                  lat: place.lat,
                  lng: place.lng,
                  formatted_address: place.formatted_address,
                }));
              }}
              value={fullAddress?.formatted_address}
              error={errors?.formatted_address}
            />
          </Col>

          <>
            <Col xs={12}>
              <Input
                label="Rua *"
                placeholder="Digite sua rua"
                onChange={value => {
                  setFullAddress(prev => ({
                    ...prev,
                    address: value.target.value,
                  }));
                }}
                value={fullAddress?.address}
                disabled={addressDisabled}
                error={errors?.address}
              />
            </Col>
            <Col md={6} xs={12}>
              <Input
                label="Número"
                value={fullAddress?.number}
                onChange={event =>
                  setFullAddress({
                    ...fullAddress,
                    number: event.target.value,
                  })
                }
                disabled={disableNumber}
              />
            </Col>
            <Col md={6} xs={12}>
              <Input
                label="Complemento"
                value={fullAddress?.complement}
                onChange={event =>
                  setFullAddress({
                    ...fullAddress,
                    complement: event.target.value,
                  })
                }
              />
            </Col>
            <Col md={6} xs={12}>
              <Input
                label="Bairro *"
                value={fullAddress?.neighborhood}
                onChange={event =>
                  setFullAddress({
                    ...fullAddress,
                    neighborhood: event.target.value,
                  })
                }
                disabled={disableNeighborhood}
              />
            </Col>
            <Col md={6} xs={12}>
              <Input
                label="CEP"
                value={fullAddress?.zip_code}
                onChange={event =>
                  setFullAddress({
                    ...fullAddress,
                    zip_code: event.target.value,
                  })
                }
                disabled={disableZipCode}
              />
            </Col>
            <Col md={8} xs={12}>
              <Select.Async
                label="Cidade *"
                onSearch={city =>
                  fetchProvinces(city, '', country?.abbreviation)
                }
                value={fullAddress?.city}
                horizontal
                onChange={value =>
                  setFullAddress(old => ({
                    ...old,
                    city: value,
                    province: value?.province?.uf || '',
                  }))
                }
                getOptionLabel={option =>
                  `${`${option.name} ${
                    option.province?.uf ? `- ${option.province?.uf}` : ''
                  }`}`
                }
                getOptionValue={option =>
                  `${`${option.name} ${
                    option.province?.uf ? `- ${option.province?.uf}` : ''
                  }`}`
                }
                isDisabled={disableCity}
                error={errors?.city_name || errors?.city_id}
              />
            </Col>
            <Col md={4} xs={12}>
              <Input
                label="UF *"
                value={fullAddress?.province}
                onChange={event =>
                  setFullAddress({
                    ...fullAddress,
                    province: event.target.value,
                  })
                }
                disabled={disableProvince}
              />
            </Col>
            <Col md={6}>
              <Input
                label="Latitude"
                disabled={true}
                value={fullAddress?.lat}
                tooltip="Selecione um endereço sugerido para obter dados de latitude e longitude"
                error={errors?.lat}
              />
            </Col>
            <Col md={6}>
              <Input
                label="Longitude"
                disabled={true}
                value={fullAddress?.lng}
                tooltip="Selecione um endereço sugerido para obter dados de latitude e longitude"
                error={errors?.lng}
              />
            </Col>
          </>
          <div style={{ flex: 1, display: 'flex', justifyContent: 'end' }}>
            <Button
              loading={loading}
              disabled={
                !fullAddress.city ||
                !fullAddress.formatted_address ||
                !fullAddress.address ||
                !fullAddress.lat ||
                !fullAddress.lng
              }
              onClick={() => handleUpdateAddress()}
            >
              Salvar
            </Button>
          </div>
        </Row>
      }
    />
  );
}
