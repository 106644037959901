import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import Sidebar from 'components/Sidebar';
import Input from 'components/Input';
import Snackbar from 'components/Snackbar';
import Button from 'components/Button';
import Fab from 'components/Fab';
import config from 'config';
import { usePermission } from 'hooks';
import CardGruposUsuario from './CardGruposUsuario';

axiosRetry(axios, { retries: 10 });

export default function ListaUsuarios() {
  const inputEl = useRef(null);
  const [snackbar, setSnackbar] = useState({
    display: false,
    type: 'info',
    message: '',
  });
  const [loading, setLoading] = useState(true);
  const [groupName, setGroupName] = useState('');
  const [groupId, setGroupId] = useState('');
  const [userGroups, setUserGroups] = useState([]);
  usePermission('VISUALIZAR_GRUPOS_USUARIOS', { redirect: true });

  useEffect(() => {
    getGroups();
  }, []);

  async function getGroups() {
    setLoading(true);
    const response = await axios.get(`${config.server}groups`);

    const arrGroups = [];
    if (response.status === 200) {
      for (const groupObj of response.data) {
        const group = {
          id: groupObj.id,
          name: groupObj.name,
          status: groupObj.status,
        };

        arrGroups.push(group);
        setLoading(false);
      }

      setUserGroups(arrGroups);
    }
  }

  async function onSubmit(e) {
    e.preventDefault();
    const name = groupName;
    const id = groupId;

    try {
      if (!id) {
        const response = await axios.post(`${config.server}groups`, {
          name,
        });

        if (response.status === 200) {
          setSnackbar({
            display: true,
            type: 'success',
            message: `Grupo ${name} adicionado`,
          });
          getGroups();
        }
      } else {
        const response = await axios.put(`${config.server}groups/${id}`, {
          name,
        });
        if (response.status === 200) {
          setSnackbar({
            display: true,
            type: 'success',
            message: 'Grupo salvo',
          });
          getGroups();
        }
      }
    } catch (e) {
      const responses = e.response.data;

      if (responses.error === 'Invalid name')
        setSnackbar({
          display: true,
          type: 'error',
          message: 'Nome inválido',
        });

      return true;
    }
  }

  function handleOpened(group) {
    setGroupName(group.name);
    setGroupId(group.id);

    inputEl.current.toggle();
  }

  return (
    <>
      <Snackbar
        open={snackbar.display}
        type={snackbar.type}
        message={snackbar.message}
        onClose={() =>
          setSnackbar({
            display: false,
            type: '',
            message: '',
          })
        }
      />
      <Fab
        aria-label="Adicionar usuário"
        style={{
          position: 'fixed',
          bottom: '20px',
          right: '120px',
          zIndex: '1',
          border: '2px solid rgb(33, 150, 243)',
          paddingRight: '20px',
          paddingLeft: '20px',
          height: '36px',

          borderRadius: '18px',
        }}
        onClick={() => {
          setGroupName('');
          setGroupId(null);
          inputEl.current.toggle();
        }}
      >
        Adicionar grupo
      </Fab>
      {loading && (
        <>
          <Skeleton variant="rect" width="100%" height={118} />
          <br />
          <Skeleton variant="rect" width="100%" height={118} />
          <br />
          <Skeleton variant="rect" width="100%" height={118} />
          <br />
          <Skeleton variant="rect" width="100%" height={118} />
          <br />
          <Skeleton variant="rect" width="100%" height={118} />
          <br />
        </>
      )}

      <Grid container>
        {userGroups &&
          userGroups.map((group, index) => {
            return (
              <CardGruposUsuario
                handleOpened={handleOpened}
                group={group}
                key={index}
              />
            );
          })}
      </Grid>
      <Sidebar
        ref={inputEl}
        closeButton
        iconInit={false}
        title={groupId ? 'Editar grupo' : 'Novo grupo'}
      >
        <form onSubmit={onSubmit}>
          <Input
            label="Nome do grupo"
            value={groupName}
            onChange={e => {
              setGroupName(e.target.value);
            }}
          />
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '15px',
            }}
          >
            <Button type="submit">{groupId ? 'Salvar' : 'Adicionar'}</Button>
          </div>
        </form>
      </Sidebar>
    </>
  );
}
