export const validIds = [
  {
    Veículos: {
      'Bi-truck': 1,
      Toco: 3,
      Truck: 4,
      'VUC ou 3/4': 5,
      Van: 11,
      Utilitário: 12,
      'Cavalo Mecânico': 18,
    },
  },
  {
    'Disposição da carga': {
      Granel: 1,
      Paletizada: 2,
      Estufada: 3,
    },
  },
  {
    Carrocerias: {
      'Aberto/ Grade baixa': 1,
      'Graneleiro/ Grade alta': 2,
      Baú: 3,
      Frigorífico: 4,
      Sider: 5,
      'Caçamba/ Basculante traseiro': 6,
      Prancha: 8,
      Plataforma: 9,
      Cegonha: 13,
      Tanque: 14,
      'Caçamba/ Basculante lateral': 16,
      Silo: 18,
      Florestal: 20,
      Bobineira: 24,
      'Sider rebaixado': 28,
      'Porta container 20': 29,
      'Porta container 20/40': 30,
      'Porta Container 40': 31,
      'Sider Asa delta': 32,
    },
  },
  {
    'Tipo do valor da carga': {
      'Por viagem': 'CARGA_FECHADA',
      'Por tonelada': 'POR_TONELADA',
    },
  },
];
