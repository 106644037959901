import React, { useEffect, useState } from 'react';
import { Button, Modal, Select, Text } from 'v3/components';
import { Col, Row } from 'react-bootstrap';
import Space from 'v3/components/Space';
import { useTravel } from 'v3/pages/Travel';
import { useRiskManager } from '../context';

const groStatusOptions = [
  {
    slug: 'GR_ANALYSIS',
    name: 'Iniciado análise GR',
  },
  {
    slug: 'GR_APPROVAL',
    name: 'Aprovado GR',
  },
  {
    slug: 'GR_DECLINE',
    name: 'Reprovado GR',
  },
];

export function GROAnalysisModal() {
  const travel = useTravel();
  const { groAnalysisModal, handleInitGROAnalysis, isInFlight } =
    useRiskManager();
  const [status, setStatus] = useState(groStatusOptions[0]);
  useEffect(() => {
    if (groAnalysisModal.isOpen && travel.data.riskApproved) {
      setStatus(
        groStatusOptions.find(item => item?.slug === travel?.data?.riskApproved)
      );
    }
  }, [groAnalysisModal.isOpen, travel.data.riskApproved]);
  return (
    <Modal
      backdrop="static"
      closeButton={false}
      size="md"
      heading={
        <Text color="dark" type="header">
          Aprovação GRO
        </Text>
      }
      show={groAnalysisModal.isOpen}
      body={
        <Row style={{ paddingBottom: '6rem' }}>
          <Col>
            <Select
              label="Aprovação GR"
              options={groStatusOptions}
              getOptionLabel={option => option?.name}
              getOptionValue={option => option.slug}
              value={status}
              onChange={value => setStatus(value)}
              isDisabled={isInFlight}
            />
          </Col>
        </Row>
      }
      footer={
        <Space x="0.5rem">
          <Button
            size="sm"
            variant="secondary"
            onClick={() => groAnalysisModal.close()}
            disabled={isInFlight}
          >
            <Text>Cancelar</Text>
          </Button>
          <Button
            size="sm"
            onClick={() => {
              //   setStatus(null);
              handleInitGROAnalysis(status?.slug);
            }}
            loading={isInFlight}
          >
            <Text>Salvar</Text>
          </Button>
        </Space>
      }
    />
  );
}
