import styled from 'styled-components';

import { getScrollbarWidth } from 'services/utils';

import { Drawer as MaterialDrawer } from '@material-ui/core';

export const Drawer = styled(MaterialDrawer).attrs({
  variant: 'persistent',
  anchor: 'right',
})`
  z-index: 2;
  > div {
    flex-shrink: 0;

    padding-top: 80px;

    overflow: initial;

    width: 330px;
    z-index: 2;
  }
`;

export const Container = styled.div`
  padding: 25px 15px;
  width: 100%;
  max-width: auto;
  position: relative;
  overflow: auto;
  max-height: auto;
  min-height: 100%;
`;

export const IconWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};

  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;

  color: #fff;
  line-height: 40px;
  text-align: center;

  margin-left: ${({ opened }) => (opened ? -40 : -40 - getScrollbarWidth())}px;

  height: 40px;
  width: 40px;

  position: absolute;

  visibility: visible;
  cursor: pointer;
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.secondary};
  font-weight: 600;
  font-size: 18px;

  margin-bottom: 30px;
  padding: 0;
`;
