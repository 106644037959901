/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Grid from '@material-ui/core/Grid';

import dealAPI from 'services/apis/deal';

import './style.css';

function TextComposer({ deal, setMessages, isDisabled,iniciateButton }) {
  const [newMessage, setNewMessage] = useState('');

  async function sendMessage(e) {
    e.preventDefault();

    if (deal?.closed) return;

    if (newMessage.trim() !== '') {
      const usuario = localStorage.getItem('usuario');
      const jsonU = JSON.parse(usuario);

      const data = {
        sender_id: jsonU.id,
        receiver_id: deal?.driver_id,
        deal_id: deal?.id,
        message: newMessage,
      };

      setNewMessage('');
      setMessages(prev => [
        ...prev,
        {
          ...data,
          created_at: new Date(),
        },
      ]);

      try {
        await dealAPI.post('messages', data);
      } catch (ex) {
        // Handle exception
      }
    }
  }
  return (
    <form onSubmit={sendMessage}>
      <Grid className="compose">
        <Input
          className="compose-input"
          placeholder="Digite a mensagem"
          id="composer"
          value={newMessage}
          onChange={e => setNewMessage(e.target.value)}
          disabled={deal?.closed || isDisabled || iniciateButton}
        />
        <Button type="submit">enviar</Button>
      </Grid>
    </form>
  );
}

export default TextComposer;
