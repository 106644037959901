import React, { useState } from 'react';
import api from 'services/api';

import { fetchCostCenter } from 'utils/fetches';

import { useSnackbar } from 'v3/components/Snackbar';
import { Modal as ModalRaw, Text, Button, Select } from 'v3/components';
import { Col, Row } from 'react-bootstrap';

function Modal({ isOpen, onClose }) {
  const snackbar = useSnackbar();
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const user = JSON.parse(localStorage.getItem('usuario'));

  async function handleSave() {
    setLoading(true);

    if (!data) {
      setError('Selecione um centro de custo');
      return;
    }
    try {
      const payload = {
        cost_center: data,
      };

      await api.put(`v3/user/${user.id}/cost-center`, payload);

      snackbar.show(<Text>Centro de custo selecionado!</Text>, {
        type: 'success',
      });
    } catch (errors) {
      snackbar.show(<Text>Verifique os campos obrigatórios</Text>, {
        type: 'error',
      });
    } finally {
      onClose();
      setLoading(false);
      setError('');
      window.location.reload();
    }
  }

  return (
    <ModalRaw
      show={isOpen}
      onHide={onClose}
      closeButton={false}
      backdrop="static"
      size="lg"
      heading={
        <Text type="subheader" weight="500" color="dark">
          Centro de custo
        </Text>
      }
      body={
        <Row>
          <Col md={12}>
            <Text type="label" color="dark">
              Você não possui nenhum centro de custo em seu usuário, por favor
              selecione um!
            </Text>
          </Col>

          <Col xs={12} className="mt-2">
            <Select.Async
              label="Centro de custo"
              onSearch={search => fetchCostCenter({ search, listAll: true })}
              value={data}
              onChange={value => setData(value)}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              error={error}
              multiple
            />
          </Col>
        </Row>
      }
      footer={
        <div className="mt-1">
          <Button
            className="py-2"
            variant="primary"
            loading={loading}
            onClick={() => handleSave()}
          >
            <Text weight="500" type="regular">
              Salvar
            </Text>
          </Button>
        </div>
      }
    />
  );
}

export default Modal;
