import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'v3/components/Snackbar';
import Text from 'v3/components/Text';
import api from 'services/apis/cooperplace';
import { usePermission } from 'hooks';
import Details from '../Details';
import Register from '../Register';
import { CostCenterProvider } from './context';

export default function CostCenterShared() {
  const snackbar = useSnackbar();
  const dispatch = useDispatch();

  const [costCenter, setCostCenter] = useState({});
  const [isFetching, setFetching] = useState(false);
  const [errors, setErrors] = useState({});
  const [prevCostCenter, setPrevCostCenter] = useState({});
  const hasPermissionToSeeCostCenter = usePermission(
    'VISUALIZAR_CENTRO_CUSTO',
    { redirect: true }
  );

  const params = useParams();

  function showSnackbar(text, options) {
    snackbar.show(<Text> {text} </Text>, options);
  }

  function setData(data) {
    setCostCenter(prev => ({ ...prev, ...data }));
  }

  useEffect(() => {
    async function fetchCostCenter() {
      try {
        if (!hasPermissionToSeeCostCenter) return;
        setFetching(true);
        const response = await api.get(`/cost-center/${params.id}`);

        setData({
          ...response.data,
        });

        setPrevCostCenter(prev => ({
          ...prev,
          ...response.data,
        }));
      } catch (ex) {
        snackbar.show(
          <Text>Não foi possível carregar esse centro de custo</Text>,
          {
            type: 'error',
          }
        );
      } finally {
        setFetching(false);
      }
    }

    if (params.id) {
      fetchCostCenter();
    }
  }, [params.id]);

  useEffect(() => {
    if (params.id) {
      dispatch({
        type: 'VIEW_INSTANCE',
        instance: costCenter.name,
      });
    }
  }, [costCenter]);

  return (
    <CostCenterProvider
      value={{
        data: costCenter,
        setData,
        isFetching,
        errors,
        setErrors,
        showSnackbar,
        prevCostCenter,
        setPrevCostCenter,
      }}
    >
      {params.id ? <Details /> : <Register />}
    </CostCenterProvider>
  );
}
