import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Input, Radio, Text } from 'v3/components';

import { useCompany } from '../context';

const SLUG = 'FRETEBRAS';

export default function IntegrationFretebras() {
  const company = useCompany();

  const fretebras = company.data?.integrations?.find(
    integration => integration.slug === SLUG
  );

  const fretebrasIndex = company.data?.integrations?.findIndex(
    integration => integration === fretebras
  );

  function toggleIntegration(value) {
    let { integrations } = company.data;

    if (value) {
      integrations = [...integrations, { slug: SLUG }];
    } else {
      integrations = integrations.filter(
        integration => integration.slug !== SLUG
      );
    }

    company.setData({ integrations });
  }

  function handleChange(key, value) {
    company.setData({
      integrations: company.data.integrations.map(integration => {
        if (integration === fretebras) {
          return { ...fretebras, [key]: value };
        }

        return integration;
      }),
    });
  }

  return (
    <>
      <Row>
        <Col xs={12} md={6} className="mb-3">
          <Radio.Group
            label="Integração Fretebras"
            horizontal
            value={!!fretebras}
            onChange={({ target }) => toggleIntegration(target.value)}
          >
            <Radio value>
              <Text color="dark" type="label">
                Sim
              </Text>
            </Radio>
            <Radio value={false}>
              <Text color="dark" type="label">
                Não
              </Text>
            </Radio>
          </Radio.Group>
        </Col>
      </Row>
      {!!fretebras && (
        <Row>
          <Col xs={12} md={6} className="mb-3">
            <Input
              label="ID Cliente *"
              value={fretebras.client_id}
              onChange={({ target }) => handleChange('client_id', target.value)}
              error={
                company.errors[`integrations[${fretebrasIndex}].client_id`]
              }
            />
          </Col>
          <Col xs={12} className="mb-3">
            <Input
              label="Token *"
              value={fretebras.client_secret}
              onChange={({ target }) =>
                handleChange('client_secret', target.value)
              }
              error={
                company.errors[`integrations[${fretebrasIndex}].client_secret`]
              }
            />
          </Col>
          <Col xs={12} className="mb-3">
            <Input
              label="Usuário *"
              value={fretebras.username}
              onChange={({ target }) => handleChange('username', target.value)}
              error={company.errors[`integrations[${fretebrasIndex}].username`]}
            />
          </Col>
          <Col xs={12} className="mb-3">
            <Input
              label="Senha *"
              type="password"
              value={fretebras.password}
              onChange={({ target }) => handleChange('password', target.value)}
              error={
                company.errors['integrations[0].password'] ||
                company.errors['integrations[1].password']
              }
            />
          </Col>
        </Row>
      )}
    </>
  );
}
