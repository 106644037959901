import React from 'react';

import InfiniteScroll from 'react-infinite-scroll-component';
import ConversationItem from '../conversationItem/index';

function ConversationList({
  deals,
  click,
  filterNegotiations,
  pagination,
  selected,
}) {
  return (
    <div style={{ height: 600, overflow: 'auto' }}>
      <InfiniteScroll
        dataLength={deals ? deals.length : 0}
        next={() => filterNegotiations(pagination.currentPage + 1)}
        hasMore={
          pagination ? pagination.currentPage < pagination.lastPage : false
        }
      >
        {deals
          ? deals.map(deal => (
              <ConversationItem
                deal={deal}
                click={click}
                key={deal.id}
                active={selected.id === deal.id}
              />
            ))
          : ''}
      </InfiniteScroll>
    </div>
  );
}

export default ConversationList;
