import {
  checkIfCNPJIsValid,
  checkIfCPFIsValid,
} from 'v3/utils/documentValidator';
import * as Yup from 'yup';

export const dataRegistrationSchema = Yup.object().shape({
  personType: Yup.object().shape({
    name: Yup.string().required('Tipo de pessoa obrigatório!'),
  }),
  cgccpf: Yup.string()
    .required('CPF/CNPJ obrigatório')
    .nullable()
    .when('personType', {
      is: value => value.name === 'Jurídica',
      then: Yup.string().test('invalid-document', 'CNPJ inválido', value =>
        checkIfCNPJIsValid(value)
      ),
      otherwise: Yup.string().test('invalid-document', 'CPF inválido', value =>
        checkIfCPFIsValid(value)
      ),
    }),
  payment_method: Yup.string().when('is_driver', {
    is: value => value,
    then: Yup.string().required('Método de pagamento obrigatório'),
  }),
  social_name: Yup.string().nullable().required('Nome obrigatório!'),
  phone: Yup.string()
    .nullable()
    .min(13, 'Telefone deve ter pelo menos 10 dígitos')
    .max(14, 'Telefone não pode ter mais de 11 dígitos')
    .required('Telefone obrigatório'),
});

export const travelShippingCompanyRegistrationSchema = Yup.object().shape({
  type_id: Yup.number().required('Tipo de pessoa obrigatório'),
  cgccpf_unmasked: Yup.string()
    .required('CPF/CNPJ obrigatório')
    .nullable()
    .when('type_id', {
      is: value => value === 3,
      then: Yup.string().test('invalid-document', 'CNPJ inválido', value =>
        checkIfCNPJIsValid(value)
      ),
      otherwise: Yup.string().test('invalid-document', 'CPF inválido', value =>
        checkIfCPFIsValid(value)
      ),
    }),
  social_name: Yup.string().nullable().required('Nome obrigatório!'),
  phone_unmasked: Yup.string()
    .nullable()
    .min(10, 'Telefone deve ter pelo menos 10 dígitos')
    .max(11, 'Telefone não pode ter mais de 11 dígitos')
    .required('Telefone obrigatório'),
  is_driver: Yup.boolean().required('Motorista obrigatório'),
});

export const travelShippingCompanyDetailsSchema = Yup.object().shape({
  type_id: Yup.number().required('Tipo de pessoa obrigatório'),
  cgccpf_unmasked: Yup.string()
    .required('CPF/CNPJ obrigatório')
    .nullable()
    .when('type_id', {
      is: value => value === 3,
      then: Yup.string().test('invalid-document', 'CNPJ inválido', value =>
        checkIfCNPJIsValid(value)
      ),
      otherwise: Yup.string().test('invalid-document', 'CPF inválido', value =>
        checkIfCPFIsValid(value)
      ),
    }),
  social_name: Yup.string().nullable().required('Nome obrigatório!'),
  phone_unmasked: Yup.string()
    .nullable()
    .min(10, 'Telefone deve ter pelo menos 10 dígitos')
    .max(11, 'Telefone não pode ter mais de 11 dígitos')
    .required('Telefone obrigatório'),
});

export const dataSchema = Yup.object().shape({
  personType: Yup.object().shape({
    name: Yup.string().required('Tipo de pessoa obrigatório!'),
  }),
  cgccpf: Yup.string()
    .required('CPF/CNPJ obrigatório')
    .nullable()
    .when('personType', {
      is: value => value.name === 'Jurídica',
      then: Yup.string().test('invalid-document', 'CNPJ inválido', value =>
        checkIfCNPJIsValid(value)
      ),
      otherwise: Yup.string().test('invalid-document', 'CPF inválido', value =>
        checkIfCPFIsValid(value)
      ),
    }),
  ie: Yup.string()
    .nullable()
    .when('personType', {
      is: value => value.name === 'Jurídica',
      then: Yup.string()
        .required('Inscrição estadual obrigatória')
        .transform((currentValue, originalValue) => {
          return currentValue === null ? undefined : currentValue;
        }),
    }),
  fantasy_name: Yup.string()
    .nullable()
    .when('personType', {
      is: value => value.name === 'Jurídica',
      then: Yup.string()
        .required('Nome fantasia obrigatório')
        .transform((currentValue, originalValue) => {
          return currentValue === null ? undefined : currentValue;
        }),
    }),
  social_name: Yup.string().nullable().required('Nome obrigatório!'),
  phone: Yup.string()
    .nullable()
    .min(13, 'Telefone deve ter pelo menos 10 dígitos')
    .max(14, 'Telefone não pode ter mais de 11 dígitos')
    .required('Telefone obrigatório'),
});

export const addressSchema = Yup.object().shape({
  address: Yup.string()
    .required('Endereço obrigatório')
    .typeError('Endereço obrigatório'),

  city_name: Yup.string()
    .required('Cidade obrigatória')
    .typeError('Cidade obrigatória'),

  uf_name: Yup.string()
    .required('Estado obrigatório')
    .typeError('Estado obrigatório'),

  neighborhood: Yup.string()
    .required('Bairro obrigatório')
    .typeError('Bairro obrigatório'),
});

export const shippingCompanyRegistrationSchema = Yup.object().shape({
  data: dataRegistrationSchema,
});

export const shippingCompanySchema = Yup.object().shape({
  data: dataSchema,
  address: addressSchema,
});
