function formatGroupData(item) {
  const groupName = item.name;
  const technologies = item?.tecnologies
    ?.map(tech => tech?.name?.toUpperCase())
    ?.join(', ');
  const equipments = item?.equipments
    ?.map(tech => tech?.name?.toUpperCase())
    ?.join(', ');
  const immobilizers = item?.immobilizers
    ?.map(immob => immob?.name?.toUpperCase())
    ?.join(', ');
  const locators = item?.locators
    ?.map(loc => loc?.name?.toUpperCase())
    ?.join(', ');
  const baits = item?.baits?.map(bait => bait?.name?.toUpperCase())?.join(', ');

  return `${groupName}:\nTecnologia (rastreador): ${
    technologies?.length > 1 ? technologies : 'Não informado'
  }\nModelo (rastreador): ${
    equipments?.length > 1 ? equipments : 'Não informado'
  }\nImobilizador: ${
    immobilizers?.length > 1 ? immobilizers : 'Não informado'
  }\nLocalizador: ${
    locators?.length > 1 ? locators : 'Não informado'
  }\nIscas: ${baits?.length > 1 ? baits : 'Não informado'}\n`;
}

export function processGroupOptionsData(rawData) {
  let data = JSON.parse(JSON.stringify(rawData));
  if (!Array.isArray(data)) {
    data = [data];
  }
  return data.map(formatGroupData).join('\n');
}

export function extractGroupOptions(data, key) {
  if (data?.length === 0) {
    return [];
  }
  const hasGroup = data?.find(group => group.code === key);
  if (!hasGroup) {
    return [];
  }
  return hasGroup?.items;
}
