/* eslint-disable import/no-unresolved */
import styled from 'styled-components';

import theme from 'v3/theme';

export const Timeline = styled.div`
  width: 100%;
  height: 0;
  border: 1px dashed ${theme.colors.medium_gray};
  margin: 0 5px;
  position: relative;
  display: flex;
  justify-content: flex-end;
`;

Timeline.Name = styled.div`
  visibility: hidden;
  display: flex;
  align-items: center;
  background-color: #f2f2f2;
  border-radius: 20px;
  padding: 5px 10px;
  margin-top: 10px;
`;

Timeline.Marker = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${theme.colors.medium_gray};
  position: absolute;
  top: -5px;
  cursor: pointer;

  &:hover + ${Timeline.Name} {
    visibility: visible;
  }
`;

Timeline.City = styled.div`
  display: inline-block;

  &:nth-child(2n + 1) ${Timeline.Name} {
    margin-top: -35px;
  }
`;

Timeline.Last = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${theme.colors.medium_gray};
`;

export const RemainingDistanceWarning = styled.span`
  border: 3px solid red;
  padding: 0.5rem 1.5rem;
  align-self: center;
  margin: 0.5rem 0 0;
  color: red;
  position: relative;
  top: -1.5rem;
  left: -1rem;
  font-size: 0.85rem;
`;
