import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Text } from 'v3/components';
import { useTravel } from 'v3/pages/Travel';
import { GRAttachments } from '../../Attachments';
import { RISK_MANAGER_STATUSES_OBJECT } from '../../controller';
import { useRiskManager } from '../../context';

export function RiskManagerDetails() {
  const travel = useTravel();
  const { hasEditPermissionRiskManager } = useRiskManager();
  const [grAttachments, setGrAttachments] = useState([]);

  const GR_APPROVAL_STATUSES = {
    GR_ANALYSIS: 'Iniciado análise',
    GR_APPROVAL: 'Aprovado GR',
    GR_DECLINE: 'Reprovado GR',
    TRAVEL_ALTER_DATA_GR: 'Alteração de dados da viagem GR',
  };

  return (
    <Col md={12} className="my-2">
      <Row>
        <Col md={6} sm={12}>
          <Row>
            <Col md={12} className="my-2">
              <Text type="body" color="dark" weight={500}>
                Equipamentos de monitoramento
              </Text>
            </Col>
            <Col md={6} sm={12} className="my-2">
              <Text type="label" color="dark">
                Rastreado
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {travel?.data?.loads?.crawledLoad ? 'Sim' : 'Não'}
              </Text>
            </Col>
            <Col md={6} sm={12} className="my-2">
              <Text type="label" color="dark">
                Escoltado
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {travel?.data?.loads?.isEscorted ? 'Sim' : 'Não'}
              </Text>
            </Col>
            <Col md={12} sm={12} className="my-2">
              <Text type="label" color="dark">
                Tecnologia rastreador
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {travel?.data?.tracker
                  ? travel?.data?.tracker?.name
                  : 'Não Informado'}
              </Text>
            </Col>
            <Col md={6} sm={12}>
              <Text type="label" color="dark">
                Possui Isca
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {travel?.data?.loads && travel?.data?.loads?.withBait
                  ? 'Sim'
                  : 'Não'}
              </Text>
            </Col>
            <Col md={6} sm={12}>
              <Text type="label" color="dark">
                Número Isca
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {travel?.data?.loads?.baitNumber
                  ? travel?.data?.loads?.baitNumber
                  : 'Não Informado'}
              </Text>
            </Col>
            <Col md={6} sm={12}>
              <Text type="label" color="dark">
                Rastreador do veículo
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {travel?.data?.vehicle?.vehicles?.trackerCode ||
                  'Não informado'}
              </Text>
            </Col>
          </Row>
        </Col>
        <Col md={6} sm={12}>
          <Row>
            <Col md={12} className="my-2">
              <Text type="body" color="dark" weight={500}>
                Gerenciamento de risco
              </Text>
            </Col>
            <Col md={6} sm={12} className="my-2">
              <Text type="label" color="dark">
                Perfil de Segurança
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {travel?.data?.securityProfile
                  ? `${travel?.data?.securityProfile?.code} - ${travel?.data?.securityProfile?.name}`
                  : 'Não Informado'}
              </Text>
            </Col>
            <Col md={6} sm={12} className="my-2">
              <Text type="label" color="dark">
                Gerenciamento de risco rastreamento
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {travel?.data?.riskManager
                  ? travel?.data?.riskManager?.name
                  : 'Não Informado'}
              </Text>
            </Col>
            <Col md={6} sm={12} className="my-2">
              <Text type="label" color="dark">
                SM de Risco
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {travel?.data?.riskManagerApprovalCode || 'Não Informado'}
              </Text>
            </Col>
            <Col md={6} sm={12} className="my-2">
              <Text type="label" color="dark">
                Pesquisa/Consulta
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {travel?.data?.riskManagerMonitoringRequest || 'Não Informado'}
              </Text>
            </Col>

            {/* ver como vai ficar esse botão */}
            <Col md={6} sm={12} className="my-2">
              <Text type="label" color="dark">
                Aprovação GR
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {travel?.data?.riskApproved
                  ? GR_APPROVAL_STATUSES[travel?.data?.riskApproved]
                  : 'Não Informado'}
              </Text>
            </Col>
            <Col md={6} sm={12} className="my-2">
              <Text type="label" color="dark">
                Status checklist
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {travel.data?.riskManagerStatus
                  ? RISK_MANAGER_STATUSES_OBJECT[travel.data.riskManagerStatus]
                      ?.label
                  : 'Não Informado'}
              </Text>
            </Col>
            <Col md={12} sm={12} className="my-2">
              <Text type="label" color="dark">
                Averbação
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {travel.data?.endorsement || 'Não Informado'}
              </Text>
            </Col>
            <Col md={12}>
              <Text type="label" color="dark">
                Rotograma
              </Text>
              <GRAttachments
                attachments={grAttachments}
                setAttachments={setGrAttachments}
                permissionToUploadGRAttachment={hasEditPermissionRiskManager}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
}
