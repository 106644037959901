import React, { useState, useEffect } from 'react';
import moment from 'moment';
import api from 'services/api';
import apiReport from 'services/apis/report';
import { Row, Col } from 'react-bootstrap';
import Toggle from 'v3/components/Toggle';
import ApplicationLayout from 'v3/layouts/Application';
import Select from 'v3/components/Select';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import { useSnackbar } from 'v3/components/Snackbar';
import { InfiniteScroll } from 'v3/components';
import FileSaver from 'file-saver';
import { usePermission } from 'hooks';
import Card from './Card';

export default function TravelAlert() {
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [travels, setTravels] = useState([]);
  const [pagination, setPagination] = useState({
    lastPage: 1,
    currentPage: 1,
    total: 0,
  });

  const [date, setDate] = useState(
    moment().subtract(7, 'days').format('YYYY-MM-DD')
  );

  const [client, setClient] = useState();
  const [clientOptions, setClientOptions] = useState([]);
  usePermission('VISUALIZAR_RELATORIOS_ALERTAS_VIAGEM', { redirect: true });

  useEffect(() => {
    async function fetchClientOptions() {
      const response = await api.get('v3/clients');
      setClientOptions(response.data);
    }
    fetchClientOptions();
  }, []);

  const DATE_OPTIONS = [
    {
      label: '7 dias',
      value: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    },
    {
      label: '15 dias',
      value: moment().subtract(15, 'days').format('YYYY-MM-DD'),
    },
    {
      label: '1 mês',
      value: moment().subtract(1, 'months').format('YYYY-MM-DD'),
    },
  ];

  async function fetchSelectOptions(url, params = {}) {
    try {
      const { data } = await api.get(url, { params });

      return data;
    } catch (err) {
      return [];
    }
  }

  function renderItem(travel) {
    return <Card travel={travel} />;
  }

  async function fetchTravels(page) {
    setLoading(true);
    try {
      const response = await apiReport.get(`travels`, {
        params: {
          page,
          date,
          client_id: client.id,
        },
      });

      const { data, ...rest } = response.data;
      setPagination(rest?.pagination);

      const nonDuplicateTravels = data.filter(
        (item, index, self) =>
          index === self.findIndex(t => t.travel_id === item.travel_id)
      );

      setTravels(nonDuplicateTravels);
    } catch (error) {
      snackbar.show('Erro ao buscar relatórios', { type: 'error' });
    } finally {
      setLoading(false);
    }
  }

  async function exportReport() {
    setExportLoading(true);
    try {
      const response = await apiReport.get(`travels/alerts`, {
        params: {
          date,
          client_id: client.id,
        },
        responseType: 'blob',
      });
      if (response.status === 204) {
        snackbar.show(
          <Text>Nenhum alerta durante o período selecionado</Text>,
          { type: 'success' }
        );
      } else FileSaver.saveAs(response.data, response.headers['x-file-name']);
    } catch (error) {
      snackbar.show('Erro ao buscar relatórios', { type: 'error' });
    } finally {
      setExportLoading(false);
    }
  }

  useEffect(() => {
    function onPressedKey(e) {
      if (e.key === 'Enter') {
        fetchTravels(pagination.currentPage);
      }
    }

    window.addEventListener('keyup', onPressedKey);

    return () => {
      window.removeEventListener('keyup', onPressedKey);
    };
  });

  return (
    <ApplicationLayout title="Alertas de viagem">
      <Row className="filter d-flex justify-content-center space-between">
        <Col md={3} xs={12}>
          <Toggle
            label="Criado em até"
            labelColor="#fff"
            value={date}
            defaultValue={date}
            options={DATE_OPTIONS}
            onSelected={value => {
              setDate(value);
            }}
          />
        </Col>
        <Col md={4} xs={12}>
          <Select.Async
            label="Cliente *"
            onSearch={search =>
              fetchSelectOptions('persons/customers', { search })
            }
            value={client}
            onChange={value => setClient(value)}
            getOptionLabel={option => option.label}
            getOptionValue={option => option.id}
            labelColor="#fff"
            options={clientOptions}
          />
        </Col>
        <Col lg={2} xs={12} className="vertical bottom">
          <Button
            disabled={!client}
            variant="success"
            loading={loading}
            onClick={() => fetchTravels(pagination.currentPage)}
            className="w-100"
          >
            <Text type="regular" weight={500}>
              Filtrar
            </Text>
          </Button>
        </Col>
        <Col lg={2} xs={12} className="vertical bottom">
          <Button
            disabled={!client}
            variant="primary"
            loading={exportLoading}
            onClick={exportReport}
            className="w-100"
          >
            <Text type="regular" weight={500}>
              Exportar
            </Text>
          </Button>
        </Col>
        <Col md={10} className="mt-3">
          <InfiniteScroll
            data={travels}
            onEndReach={() => {
              fetchTravels(pagination.currentPage + 1);
            }}
            hasMore={pagination.currentPage < pagination.lastPage}
            scrollThreshold={0.7}
            renderItem={renderItem}
            loading={loading}
          />
        </Col>
      </Row>
    </ApplicationLayout>
  );
}
