import styled, { css } from 'styled-components';

import Badge from 'react-bootstrap/Badge';
import theme from '../../theme';

export const StyledBadge = styled(Badge)`
  background-color: ${({ type = 'primary' }) =>
    theme.colors.badge[type]?.background} !important;
  color: ${({ type = 'primary' }) => theme.colors.badge[type]?.text} !important;
  padding: ${({ size }) => theme.spacing.buttonY[size]};
  ${({ size }) => theme.spacing.buttonX[size]};
  border: 1px solid
    ${({ type = 'primary' }) => theme.colors.badge[type]?.background};

  ${({ outlined }) =>
    outlined &&
    css`
      background-color: ${({ type = 'primary' }) =>
        theme.colors.badge[type].outline.background} !important;
      border: 1px solid
        ${({ type = 'primary' }) => theme.colors.badge[type].outline.text} !important;
      color: ${({ type = 'primary' }) =>
        theme.colors.badge[type].outline.text} !important;
    `};

  ${({ light }) =>
    light &&
    css`
      background-color: ${({ type = 'primary' }) =>
        theme.colors.badge[type].light.background} !important;
      border: 1px solid
        ${({ type = 'primary' }) => theme.colors.badge[type].light.background} !important;
      color: ${({ type = 'primary' }) =>
        theme.colors.badge[type].light.text} !important;
    `};
`;
