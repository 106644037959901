import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Link from '@material-ui/core/Link';

import Card from 'components/Card';

function CardVehicle(props) {
  return (
    <Grid container>
      <Grid xs={6}>
        <Card
          style={{
            marginTop: '10px',
          }}
          bordered
        >
          <Grid container>
            <Grid item xs={6}>
              {props.vehicle.vehicle.lastDriver && (
                <>
                  <Typography variant="body" content="p" gutterBottom>
                    <strong>Motorista</strong>
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {props.vehicle.vehicle.lastDriver &&
                      props.vehicle.vehicle.lastDriver.person &&
                      props.vehicle.vehicle.lastDriver.person.social_name}
                  </Typography>

                  <Typography variant="body2" gutterBottom>
                    {props.vehicle.vehicle.lastDriver &&
                      props.vehicle.vehicle.lastDriver.person &&
                      props.vehicle.vehicle.lastDriver.person.cgccpf}
                  </Typography>

                  <Typography variant="body2" gutterBottom>
                    {props.vehicle.vehicle.lastDriver &&
                      props.vehicle.vehicle.lastDriver.person &&
                      props.vehicle.vehicle.lastDriver.person.phone}
                  </Typography>
                </>
              )}
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body" component="p" gutterBottom>
                <strong>Veículo</strong>
              </Typography>
              {props.vehicle.vehicle.plate === null ? (
                ''
              ) : (
                <Chip
                  label={props.vehicle.vehicle.plate}
                  variant="outlined"
                  style={{ margin: '2px' }}
                />
              )}
              {props.vehicle.vehicle.vehicleType === null ? (
                ''
              ) : (
                <Chip
                  label={
                    props.vehicle.vehicle.vehicleType &&
                    props.vehicle.vehicle.vehicleType.name
                  }
                  variant="outlined"
                  style={{ margin: '2px' }}
                />
              )}

              {props.vehicle.vehicle.implementType === null ? (
                ''
              ) : (
                <Chip
                  label={
                    props.vehicle.vehicle.implementType &&
                    props.vehicle.vehicle.implementType.name
                  }
                  variant="outlined"
                  style={{ margin: '2px' }}
                />
              )}
            </Grid>

            <Grid xs={12}>
              <hr />
              <Link
                style={{
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  cursor: 'pointer',
                }}
                onClick={() => props.removeVehicleDialog(props.vehicle.id)}
              >
                Remover da frota
              </Link>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}
export default CardVehicle;
