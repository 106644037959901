/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState, useEffect } from 'react';
import Text from 'v3/components/Text';
import Tooltip from 'v3/components/Tooltip';

import * as Styled from './styles';

export default function Anchor({
  map,
  travelDetails,
  loadData,
  travelDocuments,
  values,
  benner,
  mercosul,
}) {
  const [visibleMap, setVisibleMap] = useState(false);
  const [visibleDetails, setVisibleDetails] = useState(false);
  const [visibleLoad, setVisibleLoad] = useState(false);
  const [visibleDocuments, setVisibleDocuments] = useState(false);
  const [visibleValues, setVisibleValues] = useState(false);
  const [visibleBenner, setVisibleBenner] = useState(false);
  const [visibleMercosulStatus, setVisibleMercosulStatus] = useState(false);

  const [mapRef] = useState({
    text: 'Mapa',
    ref: map,
  });
  const [travelDetailsRef] = useState({
    text: 'Detalhes',
    ref: travelDetails,
  });

  const [loadDataRef] = useState({
    text: 'Dados da carga',
    ref: loadData,
  });
  const [travelDocumentsRef] = useState({
    text: 'Documentos',
    ref: travelDocuments,
  });
  const [valuesRef] = useState({
    text: 'Valores',
    ref: values,
  });

  const [bennerRef] = useState({
    text: 'Validação Benner',
    ref: benner,
  });
  const [mercosulStatusRef] = useState({
    text: 'Status do Mercosul',
    ref: mercosul,
  });

  function scrollTo(cardToScroll) {
    cardToScroll.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });
  }

  function useOnScreen(refCard, options) {
    useEffect(() => {
      const observer = new IntersectionObserver(([entry]) => {
        if (refCard === mapRef) setVisibleMap(entry.isIntersecting);
        if (refCard === travelDetailsRef)
          setVisibleDetails(entry.isIntersecting);
        if (refCard === loadDataRef) setVisibleLoad(entry.isIntersecting);
        if (refCard === travelDocumentsRef)
          setVisibleDocuments(entry.isIntersecting);
        if (refCard === valuesRef) setVisibleValues(entry.isIntersecting);
        if (refCard === bennerRef) setVisibleBenner(entry.isIntersecting);
        if (refCard === mercosulStatusRef)
          setVisibleMercosulStatus(entry.isIntersecting);
      }, options);

      if (refCard.ref.current) {
        observer.observe(refCard.ref.current);
      }
      return () => {
        if (refCard.ref.current) {
          observer.unobserve(mapRef.ref.current);
        }
      };
    }, [refCard.ref, options]);
  }
  const options = { threshold: 0.8 };
  useOnScreen(mapRef, options);
  useOnScreen(travelDetailsRef, options);
  useOnScreen(loadDataRef, options);
  useOnScreen(travelDocumentsRef, options);
  useOnScreen(valuesRef, options);
  useOnScreen(bennerRef, options);

  return (
    <Styled.AnchorComponent>
      <Styled.IconBox>
        <Tooltip
          placement="left"
          content={<Text type="label">{mapRef.text}</Text>}
        >
          <Styled.MapIcon
            visible={visibleMap}
            clickable
            onClick={() => scrollTo(mapRef.ref)}
          />
        </Tooltip>
      </Styled.IconBox>
      <Styled.IconBox>
        <Tooltip
          placement="left"
          content={<Text type="label">{travelDetailsRef.text}</Text>}
        >
          <Styled.DetailsIcon
            visible={visibleDetails}
            clickable
            onClick={() => scrollTo(travelDetailsRef.ref)}
          />
        </Tooltip>
      </Styled.IconBox>
      <Styled.IconBox>
        <Tooltip
          placement="left"
          content={<Text type="label">{loadDataRef.text}</Text>}
        >
          <Styled.LoadIcon
            visible={visibleLoad}
            clickable
            onClick={() => scrollTo(loadDataRef.ref)}
          />
        </Tooltip>
      </Styled.IconBox>
      <Styled.IconBox>
        <Tooltip
          placement="left"
          content={<Text type="label">{mercosulStatusRef.text}</Text>}
        >
          <Styled.MercosulIcon
            clickable
            visible={visibleMercosulStatus}
            onClick={() => scrollTo(mercosulStatusRef.ref)}
          />
        </Tooltip>
      </Styled.IconBox>
      <Styled.IconBox>
        <Tooltip
          placement="left"
          content={<Text type="label">{bennerRef.text}</Text>}
        >
          <Styled.BennerIcon
            clickable
            visible={visibleBenner}
            onClick={() => scrollTo(bennerRef.ref)}
            content={<Text type="label">{mercosulStatusRef.text}</Text>}
          />
        </Tooltip>
      </Styled.IconBox>
      <Styled.IconBox>
        <Tooltip
          placement="left"
          content={<Text type="label">{valuesRef.text}</Text>}
        >
          <Styled.ValueIcon
            clickable
            visible={visibleValues}
            onClick={() => scrollTo(valuesRef.ref)}
          />
        </Tooltip>
      </Styled.IconBox>

      <Styled.IconBox>
        <Tooltip
          placement="left"
          content={<Text type="label">{travelDocumentsRef.text}</Text>}
        >
          <Styled.DocumentsIcon
            visible={visibleDocuments}
            clickable
            onClick={() => scrollTo(travelDocumentsRef.ref)}
          />
        </Tooltip>
      </Styled.IconBox>
    </Styled.AnchorComponent>
  );
}
