import styled from 'styled-components';
import { Row } from 'react-bootstrap';

export const StyledDescription = styled(Row)`
  margin: 1.2rem 1rem 0 1rem;
  padding: 1rem;
  border-top: 1px solid #f2f2f2;
`;

export const StyledNumeric = styled(Row)`
  div {
    &:not(:first-child) {
      border-top: 1px solid #f2f2f2;
    }

    @media (min-width: 768px) {
      &:not(:first-child) {
        border-left: 1px solid #f2f2f2;
        border-top: none;
      }
    }
  }
`;
