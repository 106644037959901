import styled, { css } from 'styled-components';
import Col from 'react-bootstrap/Col';
import theme from '../../../../theme';
import BadgeComponent from '../../../../components/Badge';

export const ModalLauncher = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  &:focus {
    outline: none;
  }
`;

export const DealSpan = styled.span`
  border-left: 1px gray solid;
  position: relative;
  padding-right: 10px;
  padding-left: 10px;
`;

export const DriversSpan = styled.span`
  border-left: 1px gray solid;
  position: relative;
  padding-left: 10px;
`;

export const VisualizationSpan = styled.span`
  padding-right: 10px;
`;

export const UnorderedList = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: -5px;
`;

export const ListItem = styled.li`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;

  border-left: 1px ${theme.colors.gray} dashed;
  position: relative;
  padding-left: 15px;

  & > span {
    &:first-child {
    }
  }

  &::before {
    content: ' ';
    background-size: cover;
    position: absolute;
    top: 0px;

    ${({ origin }) =>
      origin &&
      css`
        background: white;
        border-style: solid;
        border-color: ${theme.colors.gray};
        border-width: 1px;
        width: 10px;
        height: 10px;
        left: -6px;
        border-radius: 5px;
      `}

    .origin {
      content: ' ';
      background: ${theme.colors.gray};
      background-size: cover;
      position: absolute;
      width: 10px;
      height: 10px;
      left: -6px;
      top: 0px;
      border-radius: 5px;
    }

    ${({ destinations }) =>
      destinations &&
      css`
        background: ${theme.colors.light_blue};
        width: 6px;
        height: 6px;
        left: -3.5px;
        border-radius: 3px;
        top: 1px;
      `}

    ${({ destination }) =>
      destination &&
      css`
        background: ${theme.colors.gray};
        width: 10px;
        height: 10px;
        left: -6px;
        border-radius: 5px;
      `}
  }

  &:last-child {
    border-left: 0px gray solid;
    padding-left: 18px;

    &::before {
      left: -4px;
    }
  }
`;

export const BorderedCol = styled(Col)`
  border-left: 1px solid #f2f2f2;
  position: relative;
  padding-left: 40px;
`;

export const Badge = styled(BadgeComponent)`
  padding: 5px 17px !important;
`;
