import React, { useContext } from 'react';
import { BlockedTravelContext } from 'contexts/BlockedTravelProvider';
import { Col, Row } from 'react-bootstrap';
import { AiOutlineWarning } from 'react-icons/ai';
import Modal from '../Modal';
import Text from '../Text';
import Tooltip from '../Tooltip';
import Button from '../Button';

const maskedOptionsVehicles = {
  main: 'Veículo principal',
  implement: 'Implemento 1',
  implement2: 'Implemento 2',
  implement3: 'Implemento 3',
};

function HeadingPendingModal() {
  return (
    <Text type="regular" color="dark" weight="500">
      Tipo cooperado de veículos
    </Text>
  );
}

function BodyPendingModal({ vehicles }) {
  let vehicleTypeIdHighlight;
  const propertiesToCheck = Object.keys(maskedOptionsVehicles);

  propertiesToCheck.forEach(property => {
    if (vehicleTypeIdHighlight) return;

    if (vehicles?.[property]?.cooperative_vehicle_type_id) {
      vehicleTypeIdHighlight = vehicles[property].cooperative_vehicle_type_id;
    }
  });

  return (
    <div>
      <div>
        <Text>
          A viagem não pode ser criada devido à inconsistência nos tipos de
          cooperado dos veículos cadastrados. Para concluir o processo, é
          necessário garantir que todos os veículos tenham o mesmo tipo de
          cooperado.
        </Text>
      </div>
      <Row style={{ marginTop: 24 }}>
        <Col>
          <Text weight={500}>Veículo</Text>
        </Col>
        <Col>
          <Text weight={500}>Tipo cooperado</Text>
        </Col>
      </Row>
      {Object.entries(vehicles).map(vehicle => {
        const vehicleType = vehicle[0];
        const vehicleDetails = vehicle[1];

        if (!Object.keys(maskedOptionsVehicles).includes(vehicleType))
          return null;

        return (
          <Row
            style={{
              backgroundColor:
                vehicleTypeIdHighlight ===
                vehicleDetails.cooperative_vehicle_type_id
                  ? '#FFF2CC'
                  : '',
            }}
          >
            <Col>
              <Text>{vehicleDetails.plate}</Text>
              <Text> - {maskedOptionsVehicles[vehicleType]}</Text>
            </Col>
            <Col>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Text>
                  {vehicleDetails?.cooperativeVehicleType?.cooperative_type ??
                    `Não importado do ERP`}
                  {!vehicleDetails?.cooperativeVehicleType
                    ?.cooperative_type && (
                    <Tooltip
                      placement="bottom"
                      content={
                        <Text>
                          Não foi possível encontrar esse veículo na base de
                          dados do ERP.
                        </Text>
                      }
                    >
                      <AiOutlineWarning className="mx-3" />
                    </Tooltip>
                  )}
                </Text>
              </div>
            </Col>
          </Row>
        );
      })}
    </div>
  );
}

function FooterPendingModal({ handleClose }) {
  return <Button onClick={handleClose}>Ok</Button>;
}

export function validateVehiclesCooperativeTypes(
  verifyVehiclesModal,
  vehicles
) {
  const vehiclesCooperativeTypesIds = [];

  Object.entries(vehicles).forEach(vehicle => {
    const cooperativeTypeId = vehicle[1]?.cooperative_vehicle_type_id;
    if (cooperativeTypeId) {
      if (!vehiclesCooperativeTypesIds.includes(cooperativeTypeId)) {
        vehiclesCooperativeTypesIds.push(cooperativeTypeId);
      }
    }
  });

  // Verifica se existe mais de um tipo cooperado
  if (vehiclesCooperativeTypesIds.length > 1) {
    verifyVehiclesModal.open();
    return false;
  }
  return true;
}

export default function VerifyVehiclesModal({ vehicles }) {
  const { verifyVehiclesModal } = useContext(BlockedTravelContext);
  return (
    <Modal
      show={verifyVehiclesModal.isOpen}
      handleClose={verifyVehiclesModal.close}
      heading={<HeadingPendingModal />}
      body={<BodyPendingModal vehicles={vehicles} />}
      footer={<FooterPendingModal handleClose={verifyVehiclesModal.close} />}
    />
  );
}
