import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Input, Radio, Text } from 'v3/components';

import { useCompany } from '../context';

const SLUG = 'TRUCKPAD';

export default function IntegrationTruckpad() {
  const company = useCompany();

  const truckpad = company.data?.integrations?.find(
    integration => integration.slug === SLUG
  );

  const truckpadIndex = company.data?.integrations?.findIndex(
    integration => integration === truckpad
  );
  function toggleIntegration(value) {
    let { integrations } = company.data;
    if (value) {
      integrations = [...integrations, { slug: SLUG }];
    } else {
      integrations = integrations.filter(
        integration => integration.slug !== SLUG
      );
    }

    company.setData({ integrations });
  }

  function handleChange(key, value) {
    company.setData({
      integrations: company.data.integrations.map(integration => {
        if (integration === truckpad) {
          return { ...truckpad, [key]: value };
        }

        return integration;
      }),
    });
  }

  return (
    <>
      <Row>
        <Col xs={12} md={6} className="mb-3">
          <Radio.Group
            label="Integração Truckpad"
            horizontal
            value={!!truckpad}
            onChange={({ target }) => toggleIntegration(target.value)}
          >
            <Radio value>
              <Text color="dark" type="label">
                Sim
              </Text>
            </Radio>
            <Radio value={false}>
              <Text color="dark" type="label">
                Não
              </Text>
            </Radio>
          </Radio.Group>
        </Col>
      </Row>
      {!!truckpad && (
        <Row>
          <Col xs={12} className="mb-3">
            <Input
              label="Usuário *"
              value={truckpad.username}
              onChange={({ target }) => handleChange('username', target.value)}
              error={company.errors[`integrations[${truckpadIndex}].username`]}
            />
          </Col>
          <Col xs={12} className="mb-3">
            <Input
              label="Token *"
              value={truckpad.client_secret}
              onChange={({ target }) =>
                handleChange('client_secret', target.value)
              }
              error={
                company.errors[`integrations[${truckpadIndex}].client_secret`]
              }
            />
          </Col>
        </Row>
      )}
    </>
  );
}
