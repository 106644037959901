import React, { useCallback } from 'react';
import { FaArrowUp } from 'react-icons/fa';
import { StyledFab } from './styles';

function UpAnchor({ reachedEnd = true, onClickHandler }) {
  const handleUpClick = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return reachedEnd ? (
    <StyledFab
      onClick={() => (onClickHandler ? onClickHandler() : handleUpClick())}
      className={reachedEnd ? 'end' : ''}
    >
      <FaArrowUp />
    </StyledFab>
  ) : null;
}

export default UpAnchor;
