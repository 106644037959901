import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import React from 'react';
import { Button } from 'v3/components';

export function SectionDialog({ open, label, handleClose, confirAction }) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle id="alert-dialog-title" style={{ color: 'black' }}>
        Atenção!
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ color: '#262525' }}>
          Deseja remover {label}? Essa ação não poderá ser desfeita!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button outline={1} onClick={handleClose} variant="secondary">
          Cancelar
        </Button>
        <Button outline={1} onClick={confirAction} variant="danger" autoFocus>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
