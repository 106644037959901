import * as Yup from 'yup';
import { cpf, cnpj } from 'cpf-cnpj-validator';

function validateDocument(document) {
  if (document) {
    const valid = cpf.isValid(document);
    return valid;
  } 
  return true;
}

function validateCnpjDocument(document) {
  if (document) {
    const valid = cnpj.isValid(document);
    return valid;
  } 
  return true;
}

export const validator = Yup.object().shape({
  cpfCnpj: Yup.string()
    .required('Campo obrigatório')
    .test('invalid-document', 'Documento inválido', value =>
      validateDocument(value)
    ).typeError('Campo obrigatório'),
});

export const cnpjValidator = Yup.object().shape({
  cpfCnpj: Yup.string()
    .required('Campo obrigatório')
    .test('invalid-document', 'Documento inválido', value =>
    validateCnpjDocument(value)
    ).typeError('Campo obrigatório'),
});
