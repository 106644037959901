/* eslint-disable import/no-unresolved */
import React, { useEffect, useState, useContext } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { validatePermission } from 'actions/index';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import ApplicationLayout from 'v3/layouts/Application';
import InfiniteScroll from 'v3/components/InfiniteScroll';
import { useModal, usePermission } from 'hooks';
import { UserContext } from 'contexts/UserContext';
import CardLoad from './Card';
import LoadFilter from '../Filter';
import * as Controller from './controller';
import { AttemptRegisterLoad } from '../Modals/attemptRegisterLoad';

export default function ListLoads() {
  const attemptRegisterLoadModal = useModal();
  const { changedCompany, setChangedCompany } = useContext(UserContext);
  const [finalFilter, setFinalFilter] = useState('');
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
  const [qtdAdvancedFilters, setQtdAdvancedFilters] = useState(0);
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [loads, setLoads] = useState([]);
  const [pagination, setPagination] = useState({
    last_page: 1,
    current_page: 1,
    total: 0,
  });
  usePermission('VISUALIZAR_CARGAS', { redirect: true });

  const [hasPermissionToCreate, setPermissionToCreate] = useState();
  useState(() => {
    setPermissionToCreate(validatePermission('CADASTRAR_CARGAS'));
  }, []);

  function fetchLoads(filters = {}, page = 0) {
    Controller.getLoads(filters, page || pagination.current_page, {
      setLoads,
      setLoading,
      setPagination,
    });
  }

  const handleClose = () => setShowAdvancedFilters(false);
  const handleShow = () => setShowAdvancedFilters(true);

  function handleTitleAdvancedFilters() {
    let title = 'Filtro avançado';
    if (qtdAdvancedFilters) {
      title += ` (${qtdAdvancedFilters})`;
    }
    return title;
  }

  useEffect(() => {
    if (location?.state?.openNewLoadModal) {
      attemptRegisterLoadModal.open();
    }
  }, [location]);
  // Ao atualizar o filtro e "filtrar"
  useEffect(() => {
    if (finalFilter) {
      fetchLoads(finalFilter, 1);
    }
  }, [finalFilter]);

  function renderItem(items, index) {
    return <CardLoad key={items.id} index={index} load={items} />;
  }

  useEffect(() => {
    if (changedCompany) {
      fetchLoads(finalFilter, 1);
      setChangedCompany(false);
    }
  }, [changedCompany]);

  return (
    <ApplicationLayout
      title="Cargas"
      RightComponent={
        <>
          <Button
            id="button-filtro-avancado"
            variant="secondary"
            onClick={() => handleShow()}
          >
            <Text weight={500} color="black" type="regular">
              {handleTitleAdvancedFilters()}
            </Text>
          </Button>
          {hasPermissionToCreate && (
            <>
              <Button
                id="button-nova-carga"
                variant="primary"
                onClick={() => attemptRegisterLoadModal.open()}
                style={{ marginLeft: '10px' }}
              >
                <Text weight={500} color="white" type="regular">
                  Nova carga
                </Text>
              </Button>
            </>
          )}
        </>
      }
    >
      <AttemptRegisterLoad
        isOpen={attemptRegisterLoadModal.isOpen}
        onClose={() => attemptRegisterLoadModal.close()}
      />

      <LoadFilter
        setFinalFilter={setFinalFilter}
        showAdvancedFilters={showAdvancedFilters}
        handleClose={handleClose}
        handleShow={handleShow}
        setQtdAdvancedFilters={setQtdAdvancedFilters}
        resultsTotal={pagination.total}
        isFetching={loading && loads.length === 0}
      />
      <InfiniteScroll
        loading={loading}
        data={loads}
        renderItem={renderItem}
        onEndReach={() => {
          Controller.getLoads(finalFilter, pagination.current_page + 1, {
            setLoads,
            setLoading,
            setPagination,
          });
        }}
        hasMore={pagination.current_page < pagination.last_page}
        scrollThreshold={0.7}
      />
    </ApplicationLayout>
  );
}
