import styled, { css } from 'styled-components';

import theme from 'v3/theme';

export const Container = styled.div`
  padding: 15px 0 0 0;
  position: relative;
`;

export const AttachmentButton = styled.button`
  background-color: transparent;
`;

export const Tray = styled.div`
  margin-top: 15px !important;
  border-left: 2px dashed ${theme.colors.medium_gray};
  height: 90%;
  width: 100%;
  margin-left: 7px;
`;

export const Marker = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${theme.colors.medium_gray};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;

  ${({ isLast }) =>
    isLast &&
    css`
      margin-top: -6px;
      margin-bottom: 20px;
    `};
`;
