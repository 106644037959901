import axios from 'axios';

const serverStorageUrl = 'https://stcoopertech.blob.core.windows.net/';
const versionNumber = `${process.env.REACT_APP_BUILD_VERSION || '1.36'}`;
const versionTime = `${process.env.REACT_APP_BUILD_NUMBER || 'dev'}`;

const dev = {
  server: process.env.REACT_APP_API_URL,
  // server: 'http://10.151.52.15:3333/'
  baseUrlDocuments: `${serverStorageUrl}coopertech/`,
  baseUrlBillingDocuments: `${serverStorageUrl}coopertech-billings/`,
  versionNumber,
  versionTime,
};
const homol = {
  server: process.env.REACT_APP_API_URL,
  // server: 'http://10.151.52.15:3333/'
  baseUrlDocuments: `${serverStorageUrl}coopertech/`,
  baseUrlBillingDocuments: `${serverStorageUrl}coopertech-billings/`,
  versionNumber,
  versionTime,
};

const prod = {
  server: process.env.REACT_APP_API_URL,
  baseUrlDocuments: `${serverStorageUrl}coopertech/`,
  baseUrlBillingDocuments: `${serverStorageUrl}coopertech-billings/`,
  versionNumber,
  versionTime,
};

axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
  'token'
)}`;

const config =
  process.env.REACT_APP_STAGE === 'dev'
    ? dev
    : process.env.REACT_APP_STAGE === 'homol'
    ? homol
    : prod;

export default config;
