import React from 'react';
import api from 'services/api';
import * as Styled from './styles';

function InputAddress(
  {
    label,
    error,
    country = 'br',
    name,
    onSelectedAddress,
    value,
    onChange,
    typesRestriction = [],
    countryRestriction = null,
    removeCountryRestriction = false,
    id,
    ...props
  },
  ref
) {
  async function handleSelected(data) {
    const locale = {};

    async function fetchProvinces(search, uf, country) {
      try {
        const response = await api.get('cities', {
          params: { search, uf, country },
        });
        return response.data;
      } catch (ex) {
        return null;
      }
    }

    if (data.address_components) {
      data.address_components.forEach(address => {
        const [addressType] = address.types;
        if (addressType === 'administrative_area_level_1') {
          locale.province = address.short_name;
          locale.province_name = address.long_name;
        }

        if (addressType === 'locality') {
          locale.locality = address.long_name;
        }

        if (addressType === 'administrative_area_level_2') {
          locale.administrative_area_level_2 = address.long_name;
        }

        if (addressType === 'postal_code') {
          locale.zip_code = address.long_name;
        }

        if (addressType === 'sublocality_level_1') {
          locale.neighborhood = address.long_name;
        }

        if (addressType === 'street_number') {
          locale.number = address.long_name;
        }

        if (addressType === 'country') {
          locale.country_abbreviation = address.short_name.toLowerCase();
        }

        if (addressType === 'route') {
          locale.address = address.long_name;
        }
      });

      locale.lat = data.geometry.location.lat();
      locale.lng = data.geometry.location.lng();
      locale.formatted_address = data.formatted_address;
      locale.name = data.name;
      locale.isEstablishment = data.types.includes('establishment');
      if (locale.country_abbreviation !== 'br') {
        locale.city = locale.locality;
        locale.province = locale.province_name
      } else {
        locale.city = locale.administrative_area_level_2;
      }

      if (data.name && locale.isEstablishment) {
        locale.formatted_address = `${data.name} - ${locale.formatted_address}`;
      }
    }

    if (locale.city !== undefined && locale.province !== undefined) {
      const city = await fetchProvinces(locale.city, locale.province, locale.country_abbreviation);

      if (city.length === 0 || city.length > 0) {
        if (locale.city === city[0]?.name) {
          locale.city_id = city[0].id;
        } else {
          locale.city_id = null;
        }
      } else {
        if (locale.city == city.name) {
          locale.city_id = city.id;
        } else {
          locale.city_id = null;
        }
      }
    }

    onSelectedAddress(locale);
  }

  return (
    <Styled.Container>
      {label && (
        <Styled.Label color="#464e5f" type="label" weight="500">
          {label}
        </Styled.Label>
      )}
      <Styled.AutocompleteStyled
        ref={ref}
        {...props}
        name={name}
        id={id}
        onSelectedAddress={onSelectedAddress}
        onPlaceSelected={handleSelected}
        componentRestrictions={
          removeCountryRestriction ? [] : { country: [country] }
        }
        key={country}
        value={value}
        onChange={onChange}
        fields={[
          'name',
          'types',
          'address_components',
          'geometry.location',
          'formatted_address',
        ]}
        types={typesRestriction}
        hasError={!!error}
      />

      {error && (
        <Styled.ErrorText color="danger" type="little">
          {error}
        </Styled.ErrorText>
      )}
    </Styled.Container>
  );
}

export default React.forwardRef(InputAddress);
