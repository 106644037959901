import React, { useState, useMemo, useEffect } from 'react';

import { Row, Col } from 'react-bootstrap';
import riskManagerAPI from 'services/apis/risk_manager';
import moment from 'moment';
import { FaQuestionCircle } from 'react-icons/fa';

import api from 'services/api';

import ModalRaw from 'v3/components/Modal';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import Select from 'v3/components/Select';
import Tooltip from 'v3/components/Tooltip';
import theme from 'v3/theme';

import { useSnackbar } from 'v3/components/Snackbar';

function Modal({ isOpen, onClose, driver, riskManagers, fetchRiskManagers }) {
  const snackbar = useSnackbar();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const [selectedRiskManager, setSelectedRiskManager] = useState();
  const [isAlreadyConsulted, setIsAlreadyConsulted] = useState(false);
  const [consultedMessage, setConsultedMessage] = useState({
    text: '',
    type: '',
  });

  async function fetchRiskManager(search) {
    try {
      const response = await api.get('risk-manager-for-select?', {
        params: { search },
      });

      return response.data.filter(r => r.query_automation_id);
    } catch (error) {
      return [];
    }
  }

  async function fetchVehicles(search) {
    try {
      const response = await api.get('vehicle/license_plate', {
        params: { search },
      });
      return response.data;
    } catch {
      return [];
    }
  }

  async function fetchLoad(id) {
    try {
      const response = await api.get(`v3/loads/${id}`);
      return response.data;
    } catch {
      //
    }
    return false;
  }

  async function consult() {
    setLoading(true);
    try {
      const response = await riskManagerAPI.post('/query', {
        risk_manager_id: selectedRiskManager.id,
        cpf: driver.cgccpf,
        load_id: data?.Load?.id,
        vehicle: data?.Vehicle?.id,
        noNeedVehicle: !data?.Vehicle?.id,
      });

      const { status, message } = response.data;
      if (status === 'COVERED') {
        snackbar.show(<Text>{message}</Text>, {
          type: 'success',
        });
      }

      setConsultedMessage({ text: message, type: 'success' });

      await fetchRiskManagers();
      return response.data;
    } catch (error) {
      if (error?.message) {
        snackbar.show(<Text>{'Gerenciadora de Risco não possui consulta'}</Text>, {
          type: 'error',
        });

        setConsultedMessage({ text: 'Gerenciadora de Risco não possui consulta', type: 'error' });
      }

      if (error.response?.data?.errors) {
        setConsultedMessage({
          text: error.response?.data?.errors[0]?.message,
          type: 'error',
        });
      }

      if (error.response?.data?.status === 'NO_COVER') {
        snackbar.show(<Text>{error?.response?.data?.message}</Text>, {
          type: 'error',
        });

        const textMessage = error.response?.data?.received_response ? 
              `${error.response?.data?.received_response?.code} - ${error.response?.data?.received_response?.error}` 
              : error?.response?.data?.message;

        setConsultedMessage({
          text: textMessage,
          type: 'error',
        });
      }

      return [];
    } finally {
      setLoading(false);
    }
  }

  const isAbleToConsult = useMemo(() => {
    if (!selectedRiskManager) {
      return false;
    }

    if (
      selectedRiskManager &&
      JSON.parse(selectedRiskManager?.query_required_fields)?.find(
        riskManager => riskManager?.name === 'Load' && !data?.Load
      )
    ) {
      return false;
    }

    if (
      selectedRiskManager &&
      JSON.parse(selectedRiskManager?.query_required_fields)?.find(
        riskManager => riskManager?.name === 'Vehicle' && !data?.Vehicle
      )
    ) {
      return false;
    }

    if (
      riskManagers.some(
        manager =>
          moment(manager.due_date).isAfter(moment()) &&
          manager.risk_manager_id === selectedRiskManager.id &&
          +manager.status
      )
    ) {
      setIsAlreadyConsulted(true);
      return false;
    }

    return true;
  }, [selectedRiskManager, data]);

  useEffect(() => {
    if (!data.Vehicle || !data.Load || !data.riskManager) {
      setConsultedMessage({});
    }
  }, [data]);

  useEffect(() => {
    if (!data?.riskManager) {
      setIsAlreadyConsulted(false);
    }
  }, [data]);

  return (
    <>
      <ModalRaw
        show={isOpen}
        onHide={() => {
          onClose();
          setData({});
        }}
        heading={
          <>
            <Text color="dark" weight="500" type="header">
              Consultar motorista
            </Text>

            <Tooltip
              placement="right"
              content={
                <Text type="label">
                  Dependendo da gerenciadora de risco, alguns campos não serão
                  necessários!
                </Text>
              }
            >
              <FaQuestionCircle
                className="ml-2"
                style={{ color: `${theme.colors.primary}` }}
              />
            </Tooltip>
          </>
        }
        body={
          <Row>
            <Col xs={12} className="mb-3">
              <Select.Async
                label="Gerenciadora de risco"
                value={data?.riskManager}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                onSearch={search => fetchRiskManager(search)}
                onChange={value => {
                  setData(old => ({ ...old, riskManager: value }));
                  setSelectedRiskManager(value);
                }}
              />
            </Col>

            <Col xs={6} className="mb-3">
              <Select.Async
                label="N° da carga"
                placeholder="Selecione a carga"
                onSearch={fetchLoad}
                getOptionLabel={option => option.label}
                getOptionValue={option => option.id}
                value={data?.Load}
                onChange={value => setData(old => ({ ...old, Load: value }))}
                isDisabled={
                  selectedRiskManager?.name &&
                  !JSON.parse(selectedRiskManager?.query_required_fields)?.find(
                    riskManager => riskManager?.name === 'Load'
                  )
                }
              />
            </Col>
            <Col xs={6} className="mb-3">
              <Select.Async
                label="Placa do veículo"
                placeholder="Selecione a placa"
                onSearch={fetchVehicles}
                value={data?.Vehicle}
                onChange={value => setData(old => ({ ...old, Vehicle: value }))}
                getOptionLabel={option => option.plate}
                getOptionValue={option => option.id}
                isDisabled={
                  selectedRiskManager?.name &&
                  !JSON.parse(selectedRiskManager?.query_required_fields)?.find(
                    riskManager => riskManager?.name === 'Vehicle'
                  )
                }
              />
            </Col>
          </Row>
        }
        footer={
          <Row className="">
            <Col md={12} xs={12} className="mb-3 text-left">
              {selectedRiskManager?.query_obs && (
                <Text type="label" color="dark">
                  {selectedRiskManager?.query_obs}
                </Text>
              )}
              {isAlreadyConsulted && (
                <Text type="label" color="dark">
                  {' '}
                  Esse motorista já foi consultado com esses parâmetros e está
                  dentro da validade.
                </Text>
              )}
            </Col>
            <Col md={12} xs={12} className="mb-3 text-left">
              {consultedMessage?.text && (
                <Text
                  type="regular"
                  color={
                    consultedMessage.type === 'success' ? 'primary' : 'error'
                  }
                >
                  {consultedMessage.text}
                </Text>
              )}
            </Col>
            <Col md={12} xs={12} className="text-right">
              <Button
                variant="success"
                className="py-2 "
                onClick={() => consult()}
                disabled={!isAbleToConsult}
                loading={loading}
              >
                <Text weight="500" type="regular">
                  Consultar
                </Text>
              </Button>
            </Col>
          </Row>
        }
      />
    </>
  );
}

export default Modal;
