import React from 'react';
import moment from 'moment';
import { Box, Chip, Grid, Link, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Card from 'components/Card';
import { number_format } from 'functions';

function CellAdherence(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const adherence = props.adherence;

  function viewInterestedDrivers(load_id) {
    if (!load_id) return;
    dispatch({
      type: 'VIEW_DRIVERS',
      title: 'Motoristas Interessados',
      currentLoadId: load_id,
      url: `/relatorios/aderencia-motoristas/${load_id}`,
    });

    history.push(`/relatorios/aderencia-motoristas/${load_id}`);
  }

  return (
    <Card
      style={{ marginBottom: '20px' }}
      bordered={1}
      borderhexcolor={adherence.atendida === 'S' ? '#00bc97' : '#f44336'}
    >
      <Grid container spacing={1}>
        <Grid container xs={12}>
          <Grid item xs={2}>
            <Typography variant="caption" content="p" gutterBottom>
              CARGA
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong
                style={{
                  color: adherence.atendida === 'S' ? '#00bc97' : '#f44336',
                }}
              >
                {adherence.id}
              </strong>
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="caption" content="p" gutterBottom>
              EMPRESA
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>{adherence.company_name}</strong>
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="caption" content="p" gutterBottom>
              CLIENTE
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>
                {adherence.client_social_name} - {adherence.client_cgccpf}
              </strong>
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <strong
              style={{
                color: adherence.atendida === 'S' ? '#00bc97' : '#f44336',
              }}
            >
              {adherence.atendida === 'S'
                ? 'Carga Atendida'
                : 'Carga não Atendida'}
            </strong>
          </Grid>
        </Grid>

        <Grid container xs={12}>
          <Grid item xs={2}>
            <Typography variant="caption" content="p" gutterBottom>
              ORIGEM
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>
                {adherence.origin_city && adherence.origin_city.toUpperCase()} -{' '}
                {adherence.origin_province &&
                  adherence.origin_province.toUpperCase()}
              </strong>
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="caption" content="p" gutterBottom>
              DESTINO
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>
                {adherence.destination_city &&
                  adherence.destination_city.toUpperCase()}{' '}
                -{' '}
                {adherence.destination_province &&
                  adherence.destination_province.toUpperCase()}
              </strong>
            </Typography>
          </Grid>

          <Grid item xs={2}>
            <Typography variant="caption" content="p" gutterBottom>
              VISUALIZAÇÕES
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>{adherence.qtdVisualizations}</strong>
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="caption" content="p" gutterBottom>
              INTERESSES
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>{adherence.qtdInterested}</strong>
            </Typography>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={2}>
            <Box mt={2}>
              <Link
                href=""
                style={{
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  cursor: 'pointer',
                }}
                onClick={() => viewInterestedDrivers(adherence.id)}
              >
                Ver motoristas <i class="fas fa-chevron-right"></i>
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}

export default CellAdherence;
