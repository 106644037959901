/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { Col, Row } from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa';

import api from 'services/api';

import Card, { DefaultLoadingBodyComponent } from 'v3/components/Card';
import Text from 'v3/components/Text';
import Select from 'v3/components/Select';
import Radio from 'v3/components/Radio';
import Button from 'v3/components/Button';
import { useSnackbar } from 'v3/components/Snackbar';
import Input from 'v3/components/Input';
import { useVehicle } from '../shared/context';

import { validateTollTags } from '../shared/controller';

import { validatePermission } from 'actions/index';

const TollTags = () => {
  const vehicle = useVehicle();
  const params = useParams();
  const snackbar = useSnackbar();

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);

  const [tollTags, setTollTags] = useState();
  const [quantity, setQuantity] = useState([
    {
      tollTag: null,
      number: null,
      current: true,
      index: 0,
    },
  ]);

  useEffect(() => {
    async function fetchTollTags() {
      try {
        const response = await api.get('vehicle/toll-tags');
        setTollTags(response.data?.data);
        return response.data?.data;
      } catch (ex) {
        return [];
      }
    }

    fetchTollTags();
  }, []);

  function handleChangeValue(index, key, value) {
    setQuantity(old =>
      old.map((item, idx) => {
        if (index === idx) {
          return { ...item, [key]: value };
        }
        if (key === 'current') {
          return { ...item, current: false };
        }
        return item;
      })
    );
  }

  function fillTollTagsCards() {
    setQuantity(
      vehicle?.data?.tollTags?.map((card, idx) => ({
        tollTag: card.tollTag,
        number: card.number,
        current: card.current,
        index: idx,
      }))
    );
  }
  useEffect(() => {
    fillTollTagsCards();
  }, [vehicle.data]);

  function handleDeleteTollTag(idx) {
    setQuantity(old => {
      return old.filter((_, index) => index !== idx);
    });
  }

  async function handleSubmit() {
    setLoading(true);
    setErrors({});

    try {
      const data = {
        vehicle_id: params.id,
        data: quantity,
      };
      if (quantity.length > 0) {
        const [isValid] = await validateTollTags(quantity, setErrors);
        if (!isValid) {
          snackbar.show(<Text>Tag inválida, verifique os campos</Text>, {
            type: 'error',
          });
          setLoading(false);
          return;
        }
      }

      await api.put('vehicle/toll-tags/update', data);

      snackbar.show(<Text> Dados alterados com sucesso! </Text>, {
        type: 'success',
      });
      vehicle.setData({ tollTags: quantity });
      setEditing(false);
    } catch (error) {
      snackbar.show(
        <Text>{error?.response?.data?.error || 'Erro ao salvar'}</Text>,
        {
          type: 'error',
        }
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <Card
      loading={vehicle.isFetching}
      LoadingBodyComponent={() => (
        <DefaultLoadingBodyComponent linesCount={1} perLine={1} />
      )}
      header={
        <>
          <Text color="#464E5F" type="header">
            Tags de pedágios
          </Text>
        </>
      }
      HeaderRightComponent={
        validatePermission('ALTERAR_VEICULOS')&&
        <>
          {editing ? (
            <div>
              <Button
                onClick={() => {
                  setEditing(false);
                  fillTollTagsCards();
                }}
                variant="secondary"
                loading={loading}
                className="mr-2"
              >
                <Text color="dark" type="regular">
                  Cancelar
                </Text>
              </Button>
              <Button
                variant="success"
                loading={loading}
                onClick={handleSubmit}
              >
                <Text weight={500} type="regular">
                  Salvar
                </Text>
              </Button>
            </div>
          ) : (
            <Button variant="secondary" onClick={() => setEditing(true)}>
              <Text weight={500} type="regular">
                Editar
              </Text>
            </Button>
          )}
        </>
      }
    >
      <>
        {editing ? (
          <>
            {quantity.map((item, index) => (
              <>
                <Row className="form">
                  <div className="d-flex justify-content-between w-100">
                    <div className="col-md-8">
                      <Select
                        label="Empresa de pedágio"
                        value={quantity[index]?.tollTag}
                        options={tollTags}
                        onChange={value => {
                          handleChangeValue(index, 'tollTag', value);
                        }}
                        getOptionLabel={option => option.name}
                        getOptionValue={option => option.id}
                        error={errors[`[${index}].tollTag`]}
                      />
                    </div>

                    <div className="col-md-4" style={{ textAlign: 'right' }}>
                      <Button
                        variant="error"
                        size="sm"
                        className="mx-1"
                        onClick={() => handleDeleteTollTag(index)}
                      >
                        <Text>
                          <FaTrash />
                        </Text>
                      </Button>
                    </div>
                  </div>

                  <Col md={6}>
                    <Input
                      label="Número da tag"
                      value={item.number}
                      onChange={event => {
                        handleChangeValue(index, 'number', event.target.value);
                      }}
                      error={errors[`[${index}].number`]}
                    />
                  </Col>

                  <Col md={6} className="mt-1">
                    <Radio.Group
                      label="Atual"
                      onChange={event => {
                        handleChangeValue(index, 'current', event.target.value);
                      }}
                      value={quantity[index].current}
                      horizontal
                    >
                      <Radio value>
                        <Text type="label">Sim</Text>
                      </Radio>
                      <Radio value={false}>
                        <Text color="dark" type="label">
                          Não
                        </Text>
                      </Radio>
                    </Radio.Group>
                  </Col>
                </Row>
                <hr style={{ marginTop: 30, marginBottom: 30 }} />
              </>
            ))}
            <div md={3} className="mb-4">
              <Button
                loading={loading}
                onClick={() => {
                  if (quantity.length >= 5) {
                    return;
                  }
                  setQuantity(old => [
                    ...old,
                    {
                      tollTag: '',
                      number: '',
                      current: false,
                      index: quantity?.length + 1,
                    },
                  ]);
                }}
              >
                <Text type="regular" weight="500" color="white">
                  Adicionar
                </Text>
              </Button>
            </div>
          </>
        ) : (
          <>
            {vehicle?.data?.tollTags?.length > 0 ? (
              <>
                {vehicle?.data?.tollTags?.map(item => (
                  <>
                    <Row>
                      <Col md={8}>
                        <Text type="label">Tag de pedágio</Text>
                        <Text
                          type="regular"
                          color="gray"
                          as="p"
                          className="mt-2"
                        >
                          {item?.tollTag?.name || 'Não informada'}
                        </Text>
                      </Col>

                      <Col md={6}>
                        <Text type="label">Número da tag</Text>
                        <Text
                          type="regular"
                          color="gray"
                          as="p"
                          className="mt-2"
                        >
                          {item?.number || 'Não informado'}
                        </Text>
                      </Col>

                      <Col md={6}>
                        <Text type="label">Atual</Text>
                        <Text
                          type="regular"
                          color="gray"
                          as="p"
                          className="mt-2"
                        >
                          {item?.current ? 'Sim' : 'Não'}
                        </Text>
                      </Col>
                    </Row>
                    <hr style={{ marginTop: 30, marginBottom: 30 }} />
                  </>
                ))}
              </>
            ) : (
              <Text type="regular" color="#494950" as="p" className="mt-2">
                Não possui tag de pedágio
              </Text>
            )}
          </>
        )}
      </>
    </Card>
  );
};

export default TollTags;
