import { OccurrencesAditionalFieldsContext } from 'contexts/OccurrencesAditionalFields';
import React, { useContext, useMemo } from 'react';
import { Col } from 'react-bootstrap';
import DatePicker from 'v3/components/DatePicker';
import Input from 'v3/components/Input';

export default function OccurencesAditionalFieldsContainer() {
  const { fields } = useContext(OccurrencesAditionalFieldsContext);

  const normalizedAditionalFields = useMemo(() => {
    if (fields?.aditionalFields?.length && fields?.allAditionalFields?.length) {
      return fields.aditionalFields
        .map(aditionalField => {
          const findAditionalItem = fields.allAditionalFields.find(
            field => field.id === aditionalField.item_id
          );
          return {
            label: findAditionalItem.name,
            value: aditionalField.value,
            type: findAditionalItem.type,
            order: findAditionalItem.order,
          };
        })
        .sort((a, b) => a.order - b.order);
    }
    return [];
  }, [fields?.aditionalFields]);

  return normalizedAditionalFields.length > 0 ? (
    <>
      {normalizedAditionalFields.map(({ type, label, value }) => {
        if (type === 'datetime') {
          return (
            <Col key={label} lg={3} md={6} sm={6} xs={12}>
              <DatePicker withTime disabled value={value} label={label} />
            </Col>
          );
        }
        return (
          <Col key={label} lg={3} md={6} sm={6} xs={12}>
            <Input disabled value={value} label={label} />
          </Col>
        );
      })}
    </>
  ) : null;
}
