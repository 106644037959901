import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import theme from 'v3/theme';
import Text from 'v3/components/Text';
import MapRaw from 'v3/components/Map';
import MarkerLetter from 'v3/components/Map/MarkerLetter';
import { useSnackbar } from 'v3/components/Snackbar';
import { useParams } from 'react-router-dom';
import { formatAddress } from 'v3/utils/formatter';
import { formatNumber } from 'utils/formatter';
import api from 'services/api';
import Button from 'v3/components/Button';
import { usePermission } from 'hooks';
import { formatDateTime } from '../../../../utils/formatter';

import { useRoute } from '../shared/context';
import { validate } from '../shared/validator';
import { fetchDistanceInKilometers } from '../shared/utils';

import Polyline from '../shared/Polyline';
import SharedData from '../shared/Section';
import Inputs from '../shared/Route';
import Editable from '../shared/Editable';

import StatusColor from '../shared/RouteStatus';

export default function Route({ hasPermissionToEditRoute }) {
  const route = useRoute();
  const snackbar = useSnackbar();
  const [isEditing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [origin, setOrigin] = useState([]);
  const [middleDestinations, setMiddleDestinations] = useState([]);
  const [lastDestination, setLastDestination] = useState([]);
  const params = useParams();
  const [map, setMap] = useState();
  const [maps, setMaps] = useState();
  const [polyline, setPolyline] = useState([]);
  const hasPermission = usePermission('USUARIO_GR');

  function onMapLoaded(_map, _maps) {
    setMap(_map);
    setMaps(_maps);
  }

  let geodesicPolyline;

  function afterMapLoadChanges(locs) {
    geodesicPolyline = new maps.Polyline({
      path: polyline,
      geodesic: true,
      strokeColor: '#1c4b75',
      strokeOpacity: 0.7,
      strokeWeight: 2,
    });

    return (
      <Polyline
        map={map}
        maps={maps}
        markers={locs}
        pathEnabled={true}
        geodesicPolyline={geodesicPolyline}
        pathColor="#FFDD4A"
      />
    );
  }

  useEffect(() => {
    if (!params.id)
      route.setData({
        destinations: [{ action: true }],
        origin: {},
      });

    if (route?.data?.destinations?.length > 0) {
      let middle = route?.data?.destinations.filter(
        (d, index) => index < route?.data?.destinations.length - 1
      );
      setMiddleDestinations(middle);
      setLastDestination(
        route?.data?.destinations[route?.data?.destinations.length - 1]
      );
    }
  }, [route.data.destinations]);

  useEffect(() => {
    if (route?.data?.origin?.lat) {
      setOrigin(route.data.origin);
    }
  }, [route.data.origin]);

  useEffect(() => {
    if (!route.data.distance) {
      fetchDistanceInKilometers(route);
    }
  }, [route?.data.destinations]);

  useEffect(() => {
    if (route?.data?.polyline) {
      let rota = maps?.geometry?.encoding?.decodePath(route?.data?.polyline);
      setPolyline(rota);
    }
  }, [maps?.geometry]);

  useEffect(() => {
    if (maps?.geometry) {
      let rota = maps?.geometry?.encoding?.decodePath(route?.data?.polyline);
      setPolyline(rota);
    }
  }, [route?.data?.polyline]);

  async function saveData() {
    setLoading(true);
    try {
      const isValid = await validate(
        'route',
        { route: route.data },
        route.setErrors,
        {
          origin: route.data.origin,
          destinations: route.data.destinations,
        }
      );

      if (!isValid) {
        snackbar.show(<Text>Verifique os campos obrigatórios</Text>, {
          type: 'error',
        });
        setLoading(false);
        return;
      }

      const payload = {
        origin: route.data?.origin,
        destinations: route.data?.destinations,
        polyline: route.data?.polyline,
        distance: route.data?.distance,
      };

      await api.put(`v3/route/${params.id}/routes`, payload);

      route.updatePrevRoute();
      snackbar.show(<Text>Salvo com sucesso</Text>, { type: 'success' });
      setEditing(false);
    } catch (error) {
      snackbar.show(
        <Text>
          {error.response?.data[0]?.message || 'Erro ao salvar dados'}
        </Text>,
        { type: 'error' }
      );
    } finally {
      setLoading(false);
    }
  }

  async function updateApprovalGR() {
    try {
      const user = JSON.parse(localStorage.getItem('usuario'));
      const payload = {
        user_gr_id: user.id,
      };

      const { data } = await api.put(`v3/route/${params.id}/approval`, payload);
      snackbar.show(<Text>Aprovado com sucesso!</Text>, { type: 'success' });

      route.data.userGr = {
        id: data.approval.user.user_gr_id,
        username: data.approval.user.username,
      };
      route.data.date_approved_gr = data.approval.date_approved_gr;

      route.updatePrevRoute();
    } catch (error) {
      snackbar.show(<Text>{'Erro ao atualizar aprovação'},</Text>, {
        type: 'error',
      });
    }
  }

  return (
    <>
      <SharedData
        Title="Rota"
        HeaderRightComponent={
          hasPermissionToEditRoute ? (
            <Editable
              isEditing={isEditing}
              setEditing={setEditing}
              onSave={saveData}
              isLoading={loading}
            />
          ) : null
        }
        loading={route.isFetching}
      >
        {!isEditing && hasPermission ? (
          <Row className="approval-gr">
            <Button
              variant="success"
              onClick={() => {
                updateApprovalGR();
              }}
            >
              <Text weight={500} type="regular">
                Aprovado GR
              </Text>
            </Button>
          </Row>
        ) : null}

        {isEditing ? (
          <Inputs />
        ) : (
          <Row>
            <Col xs={12}>
              <Text type="label" color="#464E5F">
                Origem
              </Text>

              <Text type="regular" color="gray" as="p" className="mt-2">
                Cliente:{' '}
                {route.data?.origin?.client?.label
                  ? route.data?.origin?.client?.label
                  : 'Não informado'}
              </Text>

              <Text type="regular" color="gray" as="p" className="mt-2">
                {route.data?.origin
                  ? formatAddress(route.data?.origin)
                  : 'Não informado'}
              </Text>
            </Col>
            {route.data?.destinations?.map((_, index) => (
              <Col xs={12}>
                <Text type="label" color="#464E5F">
                  Destino{' '}
                  {route.data.destinations.length - 1 !== index
                    ? index + 1
                    : 'Final'}
                </Text>

                <Text type="regular" color="gray" as="p" className="mt-2">
                  Cliente:{' '}
                  {route.data?.destinations[index]?.client?.label ||
                    'Não informado'}
                </Text>

                <Text type="regular" color="gray" as="p" className="mt-2">
                  {formatAddress(route.data?.destinations[index]) ||
                    'Não informado'}
                </Text>

                <Text type="medium" color="gray" as="p" className="mt-0">
                  Ação:{' '}
                  {route.data?.destinations[index].action
                    ? 'Descarga'
                    : 'Carga' || 'Não informado'}
                </Text>
              </Col>
            ))}

            <Col xs={6}>
              <Text type="label" color="#464E5F">
                Distância (Km)
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {`${formatNumber(route?.data?.distance)} Km` || 'Não informado'}
              </Text>
            </Col>

            <Col xs={6}>
              <Text type="label" color="#464E5F">
                Aprovado por
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {route.data?.userGr?.username &&
                route.data?.date_approved_gr ? (
                  <>
                    {route.data?.userGr?.username} em{' '}
                    {formatDateTime(route.data?.date_approved_gr)}
                  </>
                ) : (
                  'Não aprovado'
                )}
              </Text>
            </Col>

            <Col xs={12}>
              <Text type="label" color="#464E5F">
                Mapa
              </Text>
              <MapRaw
                zoom={10}
                height={530}
                width="100%"
                position={
                  route?.data?.origin?.lat
                    ? {
                        lat: route?.data?.origin?.lat,
                        lng: route?.data?.origin?.lng,
                      }
                    : { lat: -25.453785, lng: -49.2529808 }
                }
                onGoogleApiLoaded={({ map, maps }) => onMapLoaded(map, maps)}
              >
                {maps && afterMapLoadChanges(polyline)}
                {route?.data?.origin?.lat && route?.data?.origin?.lng && (
                  <MarkerLetter
                    background="#0bb7af"
                    lat={origin.lat}
                    lng={origin.lng}
                    color="#fff"
                  />
                )}
                {middleDestinations &&
                  middleDestinations.map(destinations => (
                    <MarkerLetter
                      background={theme.colors.light_blue}
                      lat={destinations?.lat}
                      lng={destinations?.lng}
                      color="#fff"
                    />
                  ))}
                {lastDestination?.lat && lastDestination?.lng && (
                  <MarkerLetter
                    background="#F64E60"
                    lat={lastDestination.lat}
                    lng={lastDestination.lng}
                    color="#fff"
                  />
                )}
              </MapRaw>
              <StatusColor />
            </Col>
          </Row>
        )}
      </SharedData>
    </>
  );
}
