import React, { useMemo, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import StringBuilder from 'utils/StringBuilder';
import { formatDateTime } from 'v3/utils/formatter';
import PDFView from 'v3/components/PDFView';
import { Button, Modal, Text } from 'v3/components';

import { FaSearch, FaTrash } from 'react-icons/fa';
import { Backdrop, DateTime } from '../../styles';

export function Attachment({ attachment, files, setFile }) {
  const [isHovering, setHovering] = useState(false);
  const [isShowingModal, setShowingModal] = useState(false);
  const attachmentsWithoutPDF = useMemo(() => {
    return files?.filter(item => {
      const filterPDF = item.type?.split('/');
      if (filterPDF[filterPDF?.length - 1] === 'pdf') return null;
      return item;
    });
  }, [files]);
  const [lightbox, setLightbox] = useState({
    isOpen: false,
    attachments: attachmentsWithoutPDF,
    index: 0,
  });

  function removeAttachment() {
    setFile([]);
  }

  const lightboxIndexes = useMemo(() => {
    const next = (lightbox.index + 1) % lightbox.attachments?.length;
    const previous =
      (lightbox.index + lightbox.attachments.length - 1) %
      lightbox.attachments.length;
    return { next, previous };
  }, [lightbox.index, lightbox.attachments]);

  function onClickPreview(file) {
    const index = file ? attachmentsWithoutPDF.findIndex(a => a === file) : 0;

    setLightbox(prev => ({
      ...prev,
      index,
      attachments: attachmentsWithoutPDF,
      isOpen: true,
    }));
  }

  function handleClick() {
    const fullPathArray = attachment.type?.split('/');

    if (fullPathArray?.[fullPathArray?.length - 1] === 'pdf') {
      setShowingModal(true);
    } else {
      onClickPreview(attachment);
    }
  }

  function getImagePath(index, alwaysDisplay) {
    const isSameAsIndex =
      lightbox.index === lightboxIndexes.next &&
      lightbox.index === lightboxIndexes.previous;
    if (!alwaysDisplay && isSameAsIndex && lightbox.attachments.length === 1) {
      return null;
    }
    return lightbox.attachments[index]?.tempPath;
  }

  function getDocumentMetadata() {
    const document = lightbox.attachments[lightbox.index];
    const type = '';
    const createdAt = formatDateTime(document?.created_at);

    return { type, createdAt };
  }

  const lightboxTitle = useMemo(() => {
    const builder = new StringBuilder(' ');
    const documentMetadata = getDocumentMetadata();
    builder
      .append(`${lightbox.index + 1}/${lightbox.attachments?.length}`)
      .append('|')
      .append(documentMetadata.type)
      .append(`(${documentMetadata.createdAt})`);

    return builder.build();
  }, [lightbox.index, lightbox.attachments]);

  const customReactModalStyle = {
    overlay: {
      zIndex: 2000,
    },
  };
  return (
    <div>
      <Modal
        show={isShowingModal}
        dialogClassName="modal-90w"
        onHide={() => setShowingModal(false)}
        heading="Visualizar PDF"
        body={<PDFView file={attachment.tempPath} />}
      />
      <div
        className="position-relative"
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        style={{
          width: 240,
          height: 130,
          background: `url(${
            attachment.type?.split('/')?.pop() === 'pdf'
              ? '/images/default-doc.png'
              : attachment.tempPath
          }) center center`,
          backgroundSize: '100%',
        }}
      >
        {lightbox.isOpen && (
          <Lightbox
            mainSrc={getImagePath(lightbox.index, true)}
            nextSrc={getImagePath(lightboxIndexes.next)}
            prevSrc={getImagePath(lightboxIndexes.previous)}
            onCloseRequest={() =>
              setLightbox(prev => ({ ...prev, isOpen: false }))
            }
            onMovePrevRequest={() =>
              setLightbox(prev => ({
                ...prev,
                index: lightboxIndexes.previous,
              }))
            }
            onMoveNextRequest={() =>
              setLightbox(prev => ({
                ...prev,
                index: lightboxIndexes.next,
              }))
            }
            imageTitle={lightboxTitle}
            reactModalStyle={customReactModalStyle}
          />
        )}
        {isHovering && (
          <Backdrop>
            <button type="button" onClick={handleClick}>
              <FaSearch color="#fff" size={28} />
            </button>

            <Button
              variant="transparent"
              size={0}
              onClick={() => removeAttachment(attachment)}
            >
              <FaTrash size={28} />
            </Button>
          </Backdrop>
        )}

        <DateTime>
          <Text type="label" color="white">
            {formatDateTime(attachment.created_at)}
          </Text>
        </DateTime>
      </div>
    </div>
  );
}
