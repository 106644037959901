/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';

import api from 'services/api';
import Text from 'v3/components/Text';
import Modal from 'v3/components/Modal';
import Upload from 'v3/components/Upload';
import Button from 'v3/components/Button';
import Tooltip from 'v3/components/Tooltip';
import Select from 'v3/components/Select';
import { useSnackbar } from 'v3/components/Snackbar';

import { Row, Col } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import ProgressBar from 'react-bootstrap/ProgressBar';

import { BsQuestionCircle } from 'react-icons/bs';
import { fetchProductAndCategorie, fetchSelectOptions } from 'utils/fetches'
import * as Styled from './styles';
import * as Controller from './controller';
import { validIds } from './types/validIds';

async function fetchVehicleData(url) {
  try {
    const response = await api.get(url, { params: { app: true } });

    return response.data;
  } catch (ex) {
    return [];
  }
}

function CSVImporter({ closeModal, showModal }) {
  const snackbar = useSnackbar();
  const [error, setError] = useState([]);
  const [loadFile, setLoadFile] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(1);
  const [isProgressBarVisible, setProgressBarVisible] = useState(false);
  const [ids, setIds] = useState(() => validIds);
  const [product, setProduct] = useState('');
  const [riskManager, setRiskManager] = useState('');
  useEffect(() => {
    async function fetchImplementTypes() {
      const implementTypes = await fetchVehicleData('vehicle-implement-types');

      const hasImplements = ids.some(item =>
        Object.prototype.hasOwnProperty.call(item, 'Tipos de Implemento')
      );

      if (!hasImplements) {
        setIds(old => [
          ...old,
          {
            'Tipos de Implemento': implementTypes.reduce(
              (obj, item) => Object.assign(obj, { [item.name]: item.id }),
              {}
            ),
          },
        ]);
      }
    }

    fetchImplementTypes();
  }, []);

  return (
    <Modal
      scrollable
      size="lg"
      show={showModal}
      onHide={() =>
        Controller.handleCloseModal({
          setLoadFile,
          closeModal,
          setError,
        })
      }
      heading={<Text type="subtitle">Importar CSV de cargas</Text>}
      body={
        <div
          style={{
            'max-height': 'calc(100vh - 100px)',
          }}
        >
          <Accordion defaultActiveKey="1">
            <Text color="light_blue" type="regular" className="mr-3">
              <Button onClick={() => Controller.importTemplate()} link>
                <Text>Baixar modelo</Text>
              </Button>
              <Tooltip
                content={
                  <Text type="label">
                    USE VÍRGULA COMO SEPARADOR NO CAMPO TAG
                  </Text>
                }
              >
                <BsQuestionCircle />
              </Tooltip>
            </Text>
            |
            <Accordion.Toggle as={Button} link eventKey="0" className="mx-3">
              <Text>Ver ids disponíveis para importação</Text>
            </Accordion.Toggle>
            {error.length > 0 && (
              <>
                |
                <Accordion.Toggle
                  as={Button}
                  link
                  eventKey="1"
                  className="mx-3"
                >
                  <Text>Ver erros da importação</Text>
                </Accordion.Toggle>
              </>
            )}
            <Accordion.Collapse eventKey="0">
              <Row>
                {ids.map(category => (
                  <Col md={6} className="my-3">
                    <Text>{Object.keys(category)}:</Text>
                    {Object.values(category).map(objectItems =>
                      Object.keys(objectItems).map(item => (
                        <li>
                          <Text transform="capitalize">
                            {item}: {objectItems[item]}
                          </Text>
                        </li>
                      ))
                    )}
                  </Col>
                ))}
                <Col md={6} className="my-3">
                  <Select.Async
                    label="Consulta de código de produto:"
                    onSearch={search => fetchProductAndCategorie(search)}
                    value={product}
                    getOptionLabel={option =>
                      `${`Cod: ${option.id} - ${option.name} - ${option.product_type_name}`}`
                    }
                    getOptionValue={option =>
                      `${`${option?.id}-${option?.product_type_id}`}`
                    }
                    onChange={event => setProduct(event)}
                  />
                </Col>
                <Col md={6} className="my-3">
                  <Select.Async
                    label="Consulta de Código de GR"
                    onSearch={search =>
                      fetchSelectOptions('risk-manager-for-select', {
                        search,
                      })
                    }
                    value={riskManager}
                    getOptionLabel={option => `${`Cod: ${option.id} - ${option.name}`}`}
                    getOptionValue={option => option.id}
                    onChange={event => setRiskManager(event)}
                  />
                </Col>
              </Row>
            </Accordion.Collapse>
            <Accordion.Collapse eventKey="1">
              <ul>
                {error.map(item => (
                  <li>
                    <Text>{item}</Text>
                  </li>
                ))}
              </ul>
            </Accordion.Collapse>
          </Accordion>

          {isProgressBarVisible && <ProgressBar now={completed} />}

          <Upload
            onChange={event => setLoadFile(event.target.files)}
            icon={<Styled.CsvFileIcon size={40} className="icon" />}
            label="Upload da planilha"
            value={loadFile}
            name="loadCsv"
            id="loadCsv"
          />
        </div>
      }
      footer={
        <>
          <Button
            variant="secondary"
            onClick={() =>
              Controller.handleCloseModal({
                setLoadFile,
                closeModal,
                setError,
              })
            }
          >
            <Text>Cancelar</Text>
          </Button>
          <Button
            onClick={() =>
              Controller.importLoad({
                setProgressBarVisible,
                setCompleted,
                setLoadFile,
                setLoading,
                closeModal,
                loadFile,
                setError,
                snackbar,
              })
            }
            loading={isLoading}
          >
            <Text>Importar</Text>
          </Button>
        </>
      }
    />
  );
}

export default CSVImporter;
