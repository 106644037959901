import React from 'react';
import { BadgeActive, Fallback, Text } from 'v3/components';
import { capitalizeFullname, formatDate } from 'v3/utils/formatter';
import {
  StyledRow,
  StyledBodyContainer,
  StyledTableHeader,
  StyledThead,
  StyledTable,
} from './styles';
import { Col } from 'react-bootstrap';

export function AuditList({ liberations, setSelectedAudit, selectedAudit }) {
  function handleSelected(item) {
    if (selectedAudit?.id === item?.id) {
      setSelectedAudit();
    } else {
      setSelectedAudit(item);
    }
  }
  return (
    <Fallback
      fall={liberations?.length === 0}
      component={
        <Col xs={12} className="align-items-center d-flex flex-column my-2 p-4">
          <Text color={'gray'}>
            Não há auditorias cadastradas para essa viagem.
          </Text>
        </Col>
      }
    >
      <StyledBodyContainer>
        <StyledTable className="table">
          <StyledThead>
            <tr>
              <StyledTableHeader className="header" scope="col">
                Liberação
              </StyledTableHeader>
              <StyledTableHeader className="header" scope="col">
                Usuário
              </StyledTableHeader>
              <StyledTableHeader className="header" scope="col">
                Data
              </StyledTableHeader>
            </tr>
          </StyledThead>
          <tbody>
            {liberations?.map(item => {
              const statusMessage = item?.approval ? 'Aprovado' : 'Reprovado';
              return (
                <StyledRow
                  isSelected={selectedAudit?.id === item?.id}
                  key={item?.id}
                  onClick={() => handleSelected(item)}
                >
                  <td>
                    <BadgeActive
                      pill
                      type={item?.approval ? 'success' : 'error'}
                    >
                      <Text>{statusMessage}</Text>
                    </BadgeActive>
                  </td>
                  <td>
                    <Text color="dark">
                      {capitalizeFullname(item?.user?.username)}
                    </Text>
                  </td>
                  <td>
                    <Text color="dark">{formatDate(item?.approval_date)}</Text>
                  </td>
                </StyledRow>
              );
            })}
          </tbody>
        </StyledTable>
      </StyledBodyContainer>
    </Fallback>
  );
}
