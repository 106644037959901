import React, { useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useParams } from 'react-router-dom';

import Input from 'v3/components/Input';

import { Select } from 'v3/components';
import { fetchCostCenter } from 'utils/fetches';
import { useRasterBranches } from '../context';

export default function RasterBranchData() {
  const rasterBranches = useRasterBranches();
  const params = useParams();

  useEffect(() => {
    if (!params.id) {
      rasterBranches.setData({
        name: '',
        document: '',
        code: '',
        costCenters: [],
      });
    }
  }, []);

  return (
    <Row className="form">
      <Col xs={12} md={6}>
        <Input
          label="Razão"
          value={rasterBranches?.data?.name}
          onChange={e => rasterBranches.setData({ name: e.target.value })}
          error={rasterBranches.errors.name}
        />
      </Col>
      <Col xs={12} md={6}>
        <Input
          label="Cnpj"
          value={rasterBranches?.data?.document}
          onChange={e => rasterBranches.setData({ document: e.target.value })}
          error={rasterBranches.errors.document}
        />
      </Col>
      <Col xs={12} md={6}>
        <Select
          labelWhiteSpace="nowrap"
          label="Código filial raster"
          horizontal
          options={rasterBranches.rasterBranchesExternal}
          value={rasterBranches?.data?.code}
          onChange={value => {
            if (value) {
              rasterBranches.setData({ code: value });
            } else {
              rasterBranches.setData({ code: undefined });
            }
          }}
          getOptionLabel={option => option?.description}
          getOptionValue={option => option?.code}
          error={rasterBranches.errors.code}
        />
      </Col>
      <Col xs={12} md={6}>
        <Select.Async
          labelWhiteSpace="nowrap"
          label="Centro de custo"
          modalHeading="Selecione uma Centro de custo"
          modalBodyTitle="Centro de custo:"
          multiple
          horizontal
          onSearch={search => fetchCostCenter({ search, listAll: true })}
          value={rasterBranches?.data?.costCenters}
          onChange={value => {
            if (value) {
              rasterBranches.setData({ costCenters: value });
            } else {
              rasterBranches.setData({ costCenters: [] });
            }
          }}
          getOptionLabel={option => option?.name}
          getOptionValue={option => option?.id}
          error={rasterBranches.errors.costCenters}
        />
      </Col>
    </Row>
  );
}
