/* eslint-disable import/export */
import axios from 'axios';
import { getToken } from '../auth';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(config => {
  const outputConfig = config;

  const token = getToken();
  if (token) {
    outputConfig.headers.Authorization = `Bearer ${token}`;
  }

  return outputConfig;
});

export { default as Driver } from './Driver';

export default api;

export { default as cooperplaceApi } from '../apis/cooperplace';
