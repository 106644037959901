import React, { useEffect, useMemo } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useParams } from 'react-router-dom';

import DatePicker from 'v3/components/DatePicker';
import Input from 'v3/components/Input';
import Text from 'v3/components/Text';
import Radio from 'v3/components/Radio';

// import api from 'services/api';

import { useShippingCompany } from '../context';

export default function ShippingCompanyData() {
  const shippingCompany = useShippingCompany();
  const params = useParams();

  useEffect(() => {
    if (!params.id)
      shippingCompany.setData({
        personType: { name: 'Jurídica' },
        cgccpf: null,
        ie: null,
        social_name: null,
        fantasy_name: null,
        rg: null,
        is_driver: false,
        email: null,
        phone: null,
        rntrc_number: null,
        rntrc_exp_date: null,
      });
  }, []);

  const documentInput = useMemo(() => {
    if (shippingCompany?.data?.personType?.name === 'Jurídica') {
      return {
        label: 'CNPJ *',
        mask: [
          /[0-9]/,
          /\d/,

          '.',
          /\d/,
          /\d/,
          /\d/,
          '.',
          /\d/,
          /\d/,
          /\d/,
          '/',

          /\d/,
          /\d/,
          /\d/,
          /\d/,
          '-',
          /\d/,
          /\d/,
        ],
      };
    }
    return {
      label: 'CPF *',
      mask: [
        /[0-9]/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ],
    };
  }, [shippingCompany?.data?.personType]);

  useEffect(() => {
    if (shippingCompany?.data?.personType?.name === 'Física') {
      shippingCompany.setData({ ie: null });
    }
  }, [shippingCompany?.data?.personType]);

  return (
    <Row className="form">
      <Col md={12} className="mb-3">
        <Radio.Group
          label="Tipo de pessoa *"
          onChange={({ target }) => {
            if (target.value === 'Jurídica') {
              shippingCompany.setData({
                is_driver: false,
              });
            }
            shippingCompany.setData({
              personType: {
                ...shippingCompany?.data?.personType,
                name: target.value,
              },
              cgccpf: '',
            });
          }}
          value={shippingCompany.data?.personType?.name}
          horizontal
        >
          <Radio value="Jurídica">
            <Text color="dark" type="label">
              Jurídica
            </Text>
          </Radio>
          <Radio value="Física">
            <Text color="dark" type="label">
              Física
            </Text>
          </Radio>
        </Radio.Group>
      </Col>
      <Col xs={12} md={6}>
        <Input
          label={documentInput.label}
          value={shippingCompany?.data?.cgccpf}
          onChange={e => shippingCompany.setData({ cgccpf: e.target.value })}
          masked
          mask={documentInput.mask}
          error={shippingCompany.errors?.['data.cgccpf']}
          guide={false}
        />
      </Col>
      {shippingCompany?.data?.personType?.name === 'Jurídica' && (
        <Col xs={12} md={6}>
          <Input
            label={params.id ? 'Inscrição estadual *' : 'Inscrição estadual'}
            value={shippingCompany?.data?.ie}
            onChange={e => shippingCompany.setData({ ie: e.target.value })}
            error={shippingCompany.errors?.['data.ie']}
          />
        </Col>
      )}
      <Col xs={12}>
        <Input
          label={
            shippingCompany?.data?.personType?.name === 'Jurídica'
              ? 'Razão social *'
              : 'Nome *'
          }
          value={shippingCompany?.data?.social_name}
          onChange={e =>
            shippingCompany.setData({ social_name: e.target.value })
          }
          error={shippingCompany.errors?.['data.social_name']}
        />
      </Col>
      {shippingCompany?.data?.personType?.name === 'Jurídica' && (
        <Col xs={12}>
          <Input
            label={params.id ? 'Nome fantasia *' : 'Nome fantasia'}
            value={shippingCompany?.data?.fantasy_name}
            onChange={e =>
              shippingCompany.setData({ fantasy_name: e.target.value })
            }
            error={shippingCompany?.errors?.['data.fantasy_name']}
          />
        </Col>
      )}
      <Col xs={12} md={6}>
        <Input
          label="RNTRC"
          value={shippingCompany?.data?.rntrc_number}
          onChange={e =>
            shippingCompany.setData({ rntrc_number: e.target.value })
          }
        />
      </Col>
      <Col xs={12} md={6}>
        <DatePicker
          guide
          label="Validade RNTRC"
          value={shippingCompany?.data?.rntrc_exp_date}
          onChange={e =>
            shippingCompany.setData({ rntrc_exp_date: e.target.value })
          }
        />
      </Col>
      <Col md={12}>
        <Radio.Group
          label="É motorista?"
          value={!!shippingCompany?.data?.is_driver}
          onChange={({ target }) => {
            shippingCompany.setData({
              is_driver: target.value,
            });
          }}
          disabled={shippingCompany?.data?.personType?.name === 'Jurídica'}
          horizontal
        >
          <Radio value>
            <Text color="dark" type="label">
              Sim
            </Text>
          </Radio>
          <Radio value={false}>
            <Text color="dark" type="label">
              Não
            </Text>
          </Radio>
        </Radio.Group>
      </Col>
      <Col xs={12} md={6} className="mt-3">
        <Input
          label="Telefone *"
          value={shippingCompany.data?.phone}
          onChange={e => shippingCompany.setData({ phone: e.target.value })}
          masked
          mask={[
            '(',
            /[0-9]/,
            /\d/,
            ')',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            '-',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
          ]}
          error={shippingCompany?.errors?.['data.phone']}
          guide={false}
        />
      </Col>
      <Col xs={12}>
        <Input
          label="E-mail"
          value={shippingCompany?.data?.email}
          onChange={e => shippingCompany.setData({ email: e.target.value })}
          error={shippingCompany.errors?.['data.email']}
        />
      </Col>
    </Row>
  );
}
