import React from 'react';

import Grid from '@material-ui/core/Grid';

import CardItem from 'components/CardItem';
import Card from 'components/Card';

export default function CardMotorista(props) {
  console.log(props);
  return (
    <Grid container>
      <Grid item lg={6} md={6} xs={12}>
        <Card
          bordered
          style={{
            marginTop: '10px',
          }}
        >
          <Grid container spacing={2} style={{ padding: '0px' }}>
            <Grid item xs={8}>
              <CardItem className="nameClients" style={{ margin: '15px 0px' }}>
                {props.driver.social_name}{' '}
              </CardItem>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}
