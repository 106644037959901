import styled from 'styled-components';

export const OccurrenceDetailsContainer = styled.div`
  width: 100%;
  border: 1px solid #d1d7dc;
  padding: 0.5rem;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  background-color: ${({ theme }) => theme.newTheme.colors.white};
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.newTheme.colors.medium_gray};
`;

export const GeneralInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
`;

export const OccurrenceContentContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
