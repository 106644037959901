const Yup = require('yup');

export const editFreightSchema = Yup.object().shape({
  loadType: Yup.object().required()
  .shape({
    label: Yup.string().required(),
    value: Yup.string().required(),
  })
  .default('null')
  .required('Selecione um Tipo')
  .typeError('Tipo obrigatório'),
  negotiationType: Yup.object().required()
  .shape({
    label: Yup.string().required(),
    value: Yup.string().required(),
  })
  .default('null')
  .required('Selecione uma Negociação')
  .typeError('Negociação obrigatória'),
  pcpObs: Yup.string().max(1000, 'Máximo 1000 caracteres').nullable(),
  takerValue: Yup.string().required(' ').typeError(' '),
  exchange: Yup.number()
    .transform((value, originalValue) => {
      return Number(originalValue);
    }, 'Informe cotação válida')
    .typeError('Informe cotação válida'),
});
