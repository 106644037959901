import CheckBox from 'components/CheckBox';
import { Label } from 'v3/components/Select/styles';
import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Button, Input, Select, Text } from 'v3/components';

export function Filter({ onFilterChange, loading, questionTypes }) {
  const [question, setQuestion] = useState('');
  const [questionType, setQuestionType] = useState([]);
  const [activeFilter, setActiveFilter] = useState({
    actives: false,
    inactives: false,
  });

  function onApplyFilters() {
    onFilterChange({
      question,
      typeId: questionType,
      activeFilter,
    });
  }
  useEffect(() => {
    function onPressedKey(e) {
      if (e.key === 'Enter') {
        onApplyFilters();
      }
    }
    window.addEventListener('keyup', onPressedKey);
    return () => {
      window.removeEventListener('keyup', onPressedKey);
    };
  });

  return (
    <Row className="my-4">
      <Col md={4} sm={12}>
        <Input
          label="Pergunta"
          labelColor="#fff"
          placeholder="Informe"
          value={question}
          maxLength={250}
          onChange={e => {
            setQuestion(e?.target?.value);
          }}
        />
      </Col>
      <Col md={3} sm={12}>
        <Select.Async
          label="Tipo de pergunta"
          modalHeading="Selecione um tipo"
          modalBodyTitle="Tipos:"
          labelColor="#fff"
          multiple
          horizontal
          options={questionTypes}
          value={questionType}
          onChange={value => {
            setQuestionType(value);
          }}
          getOptionLabel={option => option?.name}
          getOptionValue={option => option.id}
        />
      </Col>
      <Col md={3} sm={12}>
        <Label color="#fff" type="label" weight="500" labelWhiteSpace="normal">
          Status da pergunta
        </Label>
        <div style={{ display: 'flex', maxWidth: '100px' }}>
          <CheckBox
            onChange={(_e, isActive) => {
              setActiveFilter(old => ({
                ...old,
                actives: isActive,
              }));
            }}
            checked={activeFilter.actives}
            label="Ativos"
            labelColor="#fff"
            checkedColor="#fff"
            labelStyle={{ textTransform: 'none' }}
          />
          <CheckBox
            onChange={(_e, isActive) => {
              setActiveFilter(old => ({
                ...old,
                inactives: isActive,
              }));
            }}
            checked={activeFilter.inactives}
            label="Inativos"
            labelColor="#fff"
            checkedColor="#fff"
            labelStyle={{ textTransform: 'none' }}
          />
        </div>
      </Col>
      <Col md={2} sm={12} style={{ display: 'flex', alignItems: 'end' }}>
        <Button
          variant="success"
          style={{ flex: 1 }}
          disabled={loading}
          onClick={() => onApplyFilters()}
        >
          <Text weight={500}>Filtrar</Text>
        </Button>
      </Col>
    </Row>
  );
}
