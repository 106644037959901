import React, { useMemo, useState } from 'react';
import { Button, Text } from 'v3/components';
import InputCurrency from 'v3/components/Input/Currency';
import { makeConversion } from 'v3/utils/formatter';
import theme from 'v3/theme';
import { FaCalculator } from 'react-icons/fa';
import { StyledCalculador, StyledCalculadorMenu } from './styles';

export function CalculatorButton({
  disabled,
  id,
  isBRLCurrency,
  dollarToUse,
  onChange,
}) {
  const [isOpenCalculator, setIsOpenCalculator] = useState(false);
  return (
    <StyledCalculadorMenu
      show={isOpenCalculator}
      onToggle={(isOpen, event, metadata) => {
        if (metadata?.source === 'rootClose') {
          setIsOpenCalculator(false);
        }
      }}
      drop="right"
      title={
        <Button
          variant="secondary"
          size="sm"
          className="mt-1"
          disabled={disabled}
          onClick={() => setIsOpenCalculator(true)}
          id={id}
        >
          <FaCalculator
            color={theme.colors.dark_blue}
            title="Conversor de moeda"
          />
        </Button>
      }
    >
      {isOpenCalculator ? (
        <Calculator
          isBRLCurrency={isBRLCurrency}
          dollarToUse={dollarToUse}
          onSubmit={(event, newValue) => {
            setIsOpenCalculator(false);
            onChange(event, newValue);
          }}
          id={id}
        />
      ) : null}
    </StyledCalculadorMenu>
  );
}

export default function Calculator({
  isBRLCurrency,
  dollarToUse,
  onSubmit,
  id,
}) {
  const [value, setValue] = useState(0);

  const convertedValue = useMemo(() => {
    const operation = isBRLCurrency ? '*' : '/';
    const processedValue = +makeConversion(value, operation, dollarToUse);
    return operation === '/'
      ? processedValue.toFixed(4)
      : processedValue.toFixed(2);
  }, [isBRLCurrency, value, dollarToUse]);

  function onSubmitHandler(event) {
    event.preventDefault();
    onSubmit(event, convertedValue);
  }

  return (
    <StyledCalculador onSubmit={onSubmitHandler}>
      <Text fontSize="12px" weight={500}>
        Valor em {isBRLCurrency ? '$' : 'R$'}
      </Text>
      <InputCurrency
        value={value}
        className="mb-1"
        onChange={(_event, newValue) => setValue(newValue)}
        id={id && `input-${id}-calculadora-valor`}
      />
      <Text fontSize="12px" weight={500}>
        Valor convertido em {isBRLCurrency ? 'R$' : '$'}
      </Text>
      <Text as="p" fontSize="14px" weight="500">
        {convertedValue}
      </Text>
      <Button
        id={id && `button-${id}-calculadora-calcular`}
        type="submit"
        size="sm"
        className="w-100"
      >
        Aplicar
      </Button>
    </StyledCalculador>
  );
}
