import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import moment from 'moment';
import { Grid } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import FileSaver from 'file-saver';

import apiReport from 'services/apis/report';
import InfiniteScroll from 'v3/components/InfiniteScroll';
import { usePermission } from 'hooks';
import { validatePermission } from '../../actions/index';
import Snackbar from '../../components/Snackbar';
import Button from '../../components/Button';
import Card from '../../components/Card';
import CellAtendida from './CellAtendida';

import ClientSelector from './client-selector';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

let token = 0;
function ReportCargasAtendidas() {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState([]);
  const [completed, setCompleted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [pagination, setPagination] = useState({
    lastPage: 1,
    page: 1,
    total: 0,
  });
  const [snackBar, setSnackBar] = useState({
    display: false,
    type: 'error',
    message: '',
  });
  const query = useQuery();
  const hasPermissionToAcess = usePermission(
    'VISUALIZAR_RELATORIO_CARGAS_ATENDIDAS'
  );

  useEffect(() => {
    if (!hasPermissionToAcess) window.location.href = '/';
  }, []);

  // Use API to donwload direct XLSX file, from ReportAPI
  async function getDownload() {
    try {
      setLoadingDownload(true);
      const tags = query.getAll('tags');
      const user_id = query.get('user_id');

      const response = await apiReport.get('attended-loads-report', {
        params: {
          companies: query.get('companies'),
          start: query.get('start_date') || moment().format('YYYY-MM-DD'),
          end: query.get('end_date') || moment().format('YYYY-MM-DD'),
          tags: tags.join(','),
          user_id,
          cost_center_id: query.get('cost_center_id') || ''
        },
        responseType: 'blob',
      });
      FileSaver.saveAs(response.data, response.headers['x-file-name']);
      setCompleted(true);
    } catch (error) {
      setSnackBar({
        display: true,
        type: 'error',
        message: 'Não foi possivel gerar o arquivo',
      });
    } finally {
      setLoadingDownload(false);
    }
  }

  // Fetch data & pagination from ReportAPI
  async function fetchData(filters, page) {
    if (page === 1) {
      setLoading(true);
    }
    try {
      const response = await apiReport.get(
        `attended-loads-data?${filters}&page=${page}`
      );
      const { items, ...rest } = response.data;
      const pagObj = {
        page: rest.page,
        currentPage: rest.currentPage,
        perPage: rest.perPage,
        from: rest.from,
        to: rest.to,
        lastPage: rest.lastPage ? rest.lastPage : pagination.lastPage,
        total: rest.total !== undefined ? rest.total : pagination.total,
      };
      setTotal(pagObj.total !== undefined ? rest.total : pagObj.total);
      setPagination(pagObj);
      setData(oldData => {
        if (page === 1) {
          return items || [];
        }
        return [...oldData, ...items];
      });
    } catch (error) {
      // snackbar
    } finally {
      setLoading(false);
    }
  }

  // Build filter & send to ReportAPI
  async function getData(page = 1) {
    const companies = query.get('companies');
    const start = query.get('start_date') || moment().format('YYYY-MM-DD');
    const end = query.get('end_date') || moment().format('YYYY-MM-DD');
    const tags = query.getAll('tags');
    const user = query.get('user_id');
    const cost_center_id = query.get('cost_center_id') || '';
    const tags_id = tags?.length && `tags=${tags}`;
    const user_id = user?.length && `user_id=${user}`;

    const buildFilter = `${tags_id}&companies=${companies}&cost_center_id=${cost_center_id}&start=${start}&end=${end}&${user_id}&token=${token++}`;

    await fetchData(buildFilter, page);
  }

  function renderItem(item) {
    return <CellAtendida carga={item} />;
  }

  return (
    <div>
      <Snackbar
        open={snackBar.display}
        type={snackBar.type}
        message={snackBar.message}
        onClose={() => setSnackBar(false)}
      />
      <Dialog
        open={loadingDownload}
        maxWidth="sm"
        fullWidth
        minWidth="sm"
        aria-labelledby="Relatório de viagens"
      >
        <DialogTitle>Relatório</DialogTitle>
        <DialogContent>
          Gerando relatório
          <LinearProgress
            variant="determinate"
            value={completed}
            color="primary"
          />
          <br />
        </DialogContent>
      </Dialog>
      <Card style={{ marginBottom: '10px', overflow: 'visible' }}>
        <Grid container spacing={2}>
          <ClientSelector
            url="/relatorios/viagens"
            onClientSelected={() => {}}
            onListReports={() => getData()}
            pathname="viagens"
            setSnackBar={setSnackBar}
            hasTags
            hasCostCenter
            hasUser
          />
          <Grid item xs={10}>
            {total > 0
              ? [
                  <Typography
                    variant="subtitle1"
                    style={{ fontSize: '18px', marginBottom: '10px' }}
                  >
                    Cargas atendidas{' '}
                    <strong style={{ color: '#1C4B75' }}>{total}</strong>
                  </Typography>,
                ]
              : ''}
          </Grid>
          <Grid item xs={2}>
            {total > 0
              ? [
                  validatePermission('EXPORTAR_RELATORIO_CARGAS_ATENDIDAS') && (
                    <Button
                      color="success"
                      disabled={loading}
                      onClick={() => getDownload()}
                    >
                      Gerar Planilha
                    </Button>
                  ),
                ]
              : ''}
          </Grid>
        </Grid>
      </Card>

      <div
        aria-label="Relatório de cargas atendidas"
        style={{ height: '60vh' }}
      >
        <InfiniteScroll
          loading={loading}
          data={data}
          renderItem={renderItem}
          onEndReach={() => {
            getData(pagination.page + 1);
          }}
          hasMore={pagination.page < pagination.lastPage}
          scrollThreshold={0.7}
        />
      </div>
    </div>
  );
}

export default ReportCargasAtendidas;
