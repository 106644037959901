import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FaTrash } from 'react-icons/fa';
import { Col, Row } from 'react-bootstrap';

import api from 'services/api';

import Modal from 'v3/components/Modal';
import Input from 'v3/components/Input';
import SwitchOption from 'v3/components/SwitchOption';
import Select from 'v3/components/Select';
import Text from 'v3/components/Text';
import DataTable from 'v3/components/DataTable';
import Button from 'v3/components/Button';
import { useSnackbar } from 'v3/components/Snackbar';

import { useCompany } from '../context';

function PriceOrder({ isOpen, onClose, setShowModal }) {
  const company = useCompany();
  const params = useParams();
  const snackbar = useSnackbar();

  const user = localStorage.getItem('usuario');
  const jsonU = JSON.parse(user);

  const [active, setActive] = useState(true);
  const [name, setName] = useState('');
  const [search, setSearch] = useState('');
  const [shippingCompanies, setShippingCompanies] = useState([]);
  const [errors, setErrors] = useState({});
  const [shippingCompany, setShippingCompany] = useState(null);
  const [perPage, setPerPage] = useState(5);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  async function fetchShippingCompanies(inputValue) {
    if (inputValue.length > 3) {
      const response = await api.get(
        `persons/shipping-companies?company_id=${jsonU.company_id}&search=${inputValue}`
      );
      return response.data;
    }
    return false;
  }

  useEffect(() => {
    if (company.data?.editingCurrentPriceOrder) {
      setName(company.data.editingCurrentPriceOrder.name);
      setShippingCompanies(
        company.data.editingCurrentPriceOrder?.order_shipping_companies?.map(
          item => item?.shipping_companies || item
        )
      );
      setActive(company.data.editingCurrentPriceOrder.active);
    } else {
      setName(null);
      setShippingCompanies([]);
      setActive(true);
      setShippingCompany(null);
    }
  }, [company.data.editingCurrentPriceOrder]);

  function checkShippingCompanies() {
    if (shippingCompanies.length === 0) {
      setErrors(old => ({
        ...old,
        select:
          'É necessário vincular ao menos uma transportadora na ordem de preço',
      }));
      return false;
    }
    return true;
  }

  function checkName(nameVerified) {
    if (!nameVerified) {
      setErrors(old => ({ ...old, name: 'Nome é obrigatório' }));
      return false;
    }
    if (company.data.pricingOrder.map(item => item.name).includes(name)) {
      setErrors(old => ({ ...old, name: 'Nome já existente' }));
      return false;
    }
    return true;
  }

  function handleAddPriceOrder() {
    company.setData({
      pricingOrder: [
        ...company.data?.pricingOrder,
        {
          active,
          company_id: company.data.id,
          order_shipping_companies: shippingCompanies,
          name,
          order: company.data?.pricingOrder?.length + 1,
        },
      ],
    });
    setShowModal(false);
    company.setData({ editingCurrentPriceOrder: null });
    setShippingCompanies([]);
    setShippingCompany(null);
    setName('');
    setErrors(old => ({ ...old, name: '', select: '' }));
    company.setPrevCompany(company.data);
  }

  async function handleAddWhenEditing() {
    setLoading(true);

    try {
      const response = await api.put(
        `v3/company/${company.data.id}/price-order`,
        {
          data: {
            id: company.data?.editingCurrentPriceOrder?.id,
            name,
            active,
            company_id: company.data.id,
            order:
              company.data?.editingCurrentPriceOrder?.order ||
              company.data?.pricingOrder?.length + 1,
          },
          shipping_companies: shippingCompanies.map(item => item.id),
        }
      );

      company.setData({
        pricingOrder: [
          ...company.data?.pricingOrder,
          {
            id: response.data.id,
            active,
            company_id: company.data.id,
            order_shipping_companies: shippingCompanies,
            name,
            order: company.data?.pricingOrder?.length + 1,
          },
        ],
      });
      company.setData({ editingCurrentPriceOrder: null });
    } catch (err) {
      snackbar.show(<Text>Erro ao salvar ordem de preço</Text>, {
        type: 'error',
      });
    }
    company.setPrevCompany(company.data);
    setShippingCompanies([]);
    setShippingCompany(null);
    setName('');
    setErrors(old => ({ ...old, name: '', select: '' }));
    setShowModal(false);
    setLoading(false);
  }

  async function handleAddShipper() {
    if (shippingCompanies.map(item => item?.id).includes(shippingCompany?.id)) {
      setErrors(old => ({
        ...old,
        select: 'Essa transportadora já está vinculada',
      }));
      return;
    }
    setShippingCompanies(old => [...old, shippingCompany]);
    setShippingCompany(null);
  }

  const shippingCompaniesDataTable = useMemo(() => {
    const offset = (page - 1) * perPage;
    const shipping_company = shippingCompanies
      ?.filter(
        item =>
          item.social_name.toLowerCase().includes(search.toLowerCase()) ||
          item.cgccpf.includes(search)
      )
      .slice(offset, perPage + offset);
    if (shipping_company?.length === 0 && page > 1) {
      setPage(page - 1);
    }
    return shipping_company;
  }, [shippingCompanies, page, perPage, search]);

  return (
    <>
      <Modal
        size="lg"
        show={isOpen}
        onHide={onClose}
        heading={
          <Text color="dark" type="header">
            Ordem de preços
          </Text>
        }
        body={
          <Row>
            <Col xs={12}>
              <Input
                error={errors?.name}
                label="Nome"
                value={name}
                onChange={e => setName(e.target.value)}
                className="mb-3"
              />
              <SwitchOption
                className="mb-4"
                value={active}
                onClick={async () => {
                  setActive(old => !old);
                }}
              />
              <Text as="p" className="my-4" type="header" color="dark">
                Transportadoras vinculadas
              </Text>
              <div className="mb-2">
                <Text type="label" color="dark">
                  Transportadora
                </Text>
              </div>
              <div className="d-flex align-items-center mb-3">
                <Select.Async
                  onSearch={s => fetchShippingCompanies(s)}
                  value={shippingCompany}
                  getOptionLabel={option => {
                    const label = `${option.social_name} - ${option.cgccpf}`;
                    return label;
                  }}
                  getOptionValue={option => option.value}
                  onChange={value => {
                    setShippingCompany(value);
                  }}
                  style={{
                    control: styles => ({
                      ...styles,
                      borderRightColor: 'transparent',
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                    }),
                  }}
                  error={errors?.select}
                  components={{ IndicatorsContainer: () => null }}
                />
                <Button
                  disabled={!shippingCompany}
                  size="md"
                  onClick={() => {
                    handleAddShipper();
                  }}
                  style={{
                    border: errors?.select ? '1px solid red' : 'transparent',
                    marginBottom: errors?.select ? 23 : null,
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  }}
                >
                  <Text type="regular" weight="500" color="white">
                    Adicionar
                  </Text>
                </Button>
              </div>
              {shippingCompanies?.length > 0 && (
                <div className="text-center">
                  <DataTable
                    data={shippingCompaniesDataTable}
                    total={shippingCompanies.length}
                    pages={Math.ceil(shippingCompanies.length / perPage)}
                    currentPage={page}
                    onPageChanged={setPage}
                    onSearchChanged={setSearch}
                    onPerPageChanged={v => setPerPage(+v)}
                    loading={false}
                    searchPlaceholder="CNPJ ou Razão social"
                    columns={[
                      {
                        name: 'Transportadoras',
                        selector: row => row?.social_name,
                      },

                      {
                        name: 'Ação',
                        render: row => (
                          <Button
                            variant="error"
                            size="sm"
                            className="mx-1"
                            onClick={() =>
                              setShippingCompanies(
                                shippingCompanies.filter(
                                  item => item?.id !== row?.id
                                )
                              )
                            }
                          >
                            <FaTrash />
                          </Button>
                        ),
                      },
                    ]}
                  />
                </div>
              )}
              <div className="text-center">
                <Button
                  loading={loading}
                  disabled={shippingCompany}
                  onClick={() => {
                    if (checkName(name) && checkShippingCompanies())
                      if (params.id) handleAddWhenEditing();
                      else handleAddPriceOrder();
                  }}
                  variant="success"
                  className="my-4 py-2"
                >
                  <Text type="regular" weight="500" color="white">
                    Salvar
                  </Text>
                </Button>
              </div>
            </Col>
          </Row>
        }
      />
    </>
  );
}

export default PriceOrder;
