export const latam = {
  type: 'Topology',
  objects: {
    continent_South_America_subunits: {
      type: 'GeometryCollection',
      geometries: [
        {
          arcs: [[[2, 3]], [[4, 5, 6, 7, 8, 9]]],
          type: 'MultiPolygon',
          properties: {
            type: 'Sovereign country',
            geounit: 'Argentina',
            color: '#B8D9FF',
            bgColor: '#93BCE8',
          },
        },
        {
          arcs: [
            [[25]],
            [[-3, 26, -1, 27]],
            [[28]],
            [[29]],
            [[30]],
            [[-9, 31, 32, -12]],
          ],
          type: 'MultiPolygon',
          properties: {
            type: 'Geo subunit',
            geounit: 'Chile',
            color: '#FF8B8B',
            bgColor: '#FF6E6E',
          },
        },

        {
          arcs: [[-21, -5, -11]],
          type: 'Polygon',
          properties: {
            type: 'Sovereign country',
            geounit: 'Paraguai',
            color: '#C3D285',
            bgColor: '#A6B66D',
          },
        },

        {
          arcs: [[49, -7, -20]],
          type: 'Polygon',
          properties: {
            type: 'Sovereign country',
            geounit: 'Uruguai',
            color: '#A0FF7E',
            bgColor: '#76E85E',
          },
        },
      ],
    },
  },
  arcs: [
    [
      [3997, 171],
      [2, 14],
    ],
    [
      [3999, 185],
      [-2, -14],
    ],
    [
      [3999, 188],
      [3, 355],
    ],
    [
      [4002, 543],
      [58, -50],
      [-40, -29],
      [92, -80],
      [257, -144],
      [109, -30],
      [-21, -42],
      [-98, -25],
      [-84, 24],
      [-276, 21],
    ],
    [
      [5025, 5590],
      [62, -41],
      [57, -92],
      [161, -126],
      [118, -37],
      [50, 2],
      [210, -128],
      [100, -27],
      [80, -40],
      [34, -46],
      [-90, -111],
      [-28, -105],
      [-69, -66],
      [24, -20],
      [97, 1],
      [131, -36],
      [108, 6],
      [72, 26],
      [64, -25],
      [55, 75],
      [78, 34],
      [46, 58],
      [18, 144],
    ],
    [
      [6403, 5036],
      [119, -9],
      [41, -98],
      [-7, -109],
      [-21, -47],
      [-183, -77],
      [-92, -76],
      [-57, -25],
      [-110, -136],
      [-156, -155],
      [-49, -33],
    ],
    [
      [5888, 4271],
      [-41, -50],
      [7, -70],
      [-30, -108],
      [-32, -48],
      [-5, -100],
    ],
    [
      [5787, 3895],
      [9, -100],
      [-40, -4],
      [-29, -101],
      [30, -84],
      [-23, -41],
      [61, -52],
      [108, -44],
      [65, -78],
      [-36, -40],
      [18, -74],
      [94, -37],
      [6, -76],
      [-67, -93],
      [-78, -76],
      [-14, -47],
      [-96, -46],
      [-156, -44],
      [-308, -47],
      [-199, 7],
      [-57, -28],
      [52, -58],
      [-74, -142],
      [44, -58],
      [-18, -35],
      [-131, -47],
      [-116, -1],
      [-200, 74],
      [-39, -49],
      [31, -90],
      [-13, -72],
      [86, -75],
      [130, 61],
      [37, -45],
      [-5, -68],
      [-83, -22],
      [-37, 56],
      [-50, 5],
      [-68, -43],
      [42, -62],
      [-97, -87],
      [20, -115],
      [-81, -58],
      [15, -52],
      [-103, 6],
      [-114, -41],
      [-79, -62],
      [-50, -92],
      [34, -67],
      [109, -73],
      [138, -9],
      [42, -74],
      [-39, -91],
      [-87, -57],
      [-110, -45],
      [-89, -76],
      [-17, -108],
      [-59, -51],
      [-137, -40],
      [-50, -108],
      [40, -111],
      [83, -116],
    ],
    [
      [4032, 592],
      [-257, 56],
      [-337, 3],
      [-62, 48],
      [-17, 90],
      [13, 85],
      [-135, -20],
      [-75, 104],
      [13, 138],
      [148, 86],
      [46, 84],
      [-38, 62],
      [114, 115],
      [-9, 70],
      [42, 18],
      [-19, 158],
      [83, 73],
      [-47, 53],
      [80, 74],
      [-120, 22],
      [28, 79],
      [-48, 78],
      [29, 49],
      [-65, 27],
      [4, 122],
      [66, 29],
      [-32, 77],
      [13, 114],
      [-18, 36],
      [95, 246],
      [-3, 50],
      [93, 41],
      [-53, 163],
      [-1, 141],
      [25, 62],
      [112, 71],
      [1, 148],
      [27, 96],
      [67, 57],
      [-10, 96],
      [18, 47],
      [-54, 32],
      [18, 52],
      [-55, 99],
      [13, 53],
      [-60, 68],
      [44, 84],
      [21, 88],
      [52, 30],
      [-3, 102],
      [-20, 59],
      [38, 40],
      [29, 120],
      [40, 31],
      [106, 182],
      [81, 12],
      [-41, 82],
      [25, 61],
      [-32, 124],
      [41, 55],
      [-35, 55],
      [56, 64],
      [151, 59],
      [60, 171],
      [-31, 30],
    ],
    [
      [4247, 5493],
      [79, 99],
      [61, 16],
      [24, 56],
      [84, -54],
      [124, 3],
      [75, -19],
      [39, -90],
      [70, 124],
      [196, 0],
      [26, -38],
    ],
    [
      [5794, 5934],
      [-3, 57],
      [-156, 88],
      [-157, -1],
      [-301, -60],
      [-31, -74],
      [-56, -75],
      [-1, -85],
      [-64, -194],
    ],
    [
      [4247, 5493],
      [-117, -2],
      [3, 44],
      [-40, 102],
      [-19, 111],
      [-47, 106],
      [-35, 21],
      [-16, 71],
      [48, 105],
      [-84, 85],
      [-26, 152],
      [-28, 9],
      [-35, 78],
    ],
    [
      [3851, 6375],
      [14, 70],
      [73, 110],
      [-73, 127],
      [48, 70],
      [-42, 45],
      [25, 61],
      [62, 64],
      [-23, 76],
      [6, 147],
      [51, 60],
      [-153, 258],
    ],
    [
      [3839, 7463],
      [165, -27],
      [55, 22],
      [33, 47],
      [59, -3],
      [72, 65],
      [46, 11],
      [74, 59],
      [149, 22],
      [79, -9],
      [2, -65],
      [-27, -42],
      [25, -87],
      [-9, -66],
      [61, -100],
      [87, -41],
      [20, -37],
      [98, 1],
      [170, -88],
      [101, -22],
      [64, -68],
      [162, -4],
      [74, -44],
      [-1, -50],
      [31, -85],
      [28, -272],
      [285, -10],
      [14, -155],
      [101, -48],
      [41, -104],
      [-40, -146],
      [-58, -111],
      [45, -42],
      [-51, -30],
    ],
    [
      [6982, 9188],
      [-9, -76],
      [-88, -72],
      [19, 100],
      [78, 48],
    ],
    [
      [7175, 9259],
      [75, -18],
      [75, 16],
      [135, -19],
      [-18, -106],
      [-54, -50],
      [2, -41],
      [-169, -62],
      [-39, 19],
      [-60, -21],
      [-69, 67],
      [5, 127],
      [33, 78],
      [84, 10],
    ],
    [
      [6081, 9603],
      [79, -18],
      [18, 34],
      [-34, 49],
      [188, 43],
      [69, -44],
    ],
    [
      [6401, 9667],
      [82, -36],
      [46, 32],
      [90, 8],
      [62, -30],
      [47, 31],
      [123, 224],
      [52, 55],
    ],
    [
      [6903, 9951],
      [28, 67],
      [75, -93],
      [-2, -84],
      [25, -92],
      [78, -164],
      [100, -28],
      [3, -83],
      [-72, -58],
      [-82, -107],
      [-71, -36],
      [-124, -188],
      [2, -65],
      [187, 90],
      [1, -68],
      [43, -75],
      [93, 11],
      [136, -21],
      [45, 58],
      [107, 47],
      [32, 89],
      [98, 34],
      [189, -74],
      [225, -76],
      [48, -4],
      [62, -57],
      [8, -92],
      [-36, -85],
      [43, -6],
      [11, 66],
      [61, -14],
      [120, 48],
      [103, -35],
      [44, -32],
      [190, -28],
      [145, 22],
      [88, -10],
      [107, -56],
      [120, -82],
      [34, -7],
      [60, -72],
      [159, -125],
      [101, -32],
      [102, 9],
      [83, -16],
      [42, -56],
      [80, -279],
      [-2, -124],
      [-58, -167],
      [-74, -103],
      [-47, -33],
      [-95, -127],
      [-84, -45],
      [-91, -133],
      [-38, -79],
      [-114, -147],
      [-72, 33],
      [-17, -71],
      [-38, -39],
      [24, -101],
      [-25, -111],
      [35, -194],
      [-59, -219],
      [-12, -117],
      [-38, -23],
      [-39, -85],
      [3, -149],
      [-54, -58],
      [-55, -122],
      [-66, -57],
      [-58, -113],
      [18, -70],
      [-120, -56],
      [-57, -54],
      [-10, -57],
      [-167, -4],
      [-87, -12],
      [-55, 25],
      [-98, -16],
      [-109, -86],
      [-136, -40],
      [-188, -91],
      [-37, -38],
      [-111, -60],
      [-21, -51],
      [-83, -70],
      [-52, -116],
      [30, -256],
      [-43, -124],
      [-90, -52],
      [-81, -86],
      [-86, -169],
      [-38, 23],
      [-100, 11],
      [-45, -67],
      [-10, -70],
      [-82, -38],
      [-55, -88],
      [20, -56],
      [-43, -54],
      [-42, -100],
      [-129, -106],
    ],
    [
      [6613, 3680],
      [-28, 16],
      [5, 91],
      [66, 57],
      [-90, 69],
      [-21, 48],
      [-62, 23],
      [-78, 73],
      [-112, 38],
      [-59, 65],
      [-72, 9],
      [-141, 115],
      [-65, -30],
      [-68, 17],
    ],
    [
      [6403, 5036],
      [59, 206],
      [2, 47],
      [-63, 39],
      [-56, -26],
      [-78, -1],
      [-41, 162],
      [1, 62],
      [-45, 53],
      [-90, 39],
      [-72, -36],
      [-196, 36],
      [22, 123],
      [-1, 100],
      [-51, 94],
    ],
    [
      [3839, 7463],
      [-65, 5],
      [-57, -24],
      [-60, 9],
      [-7, 246],
      [-121, -79],
      [-138, 0],
      [-19, 77],
      [-156, 22],
      [41, 70],
      [-97, 104],
      [-29, 95],
      [-44, 34],
      [35, 113],
      [109, 78],
      [-18, 59],
      [40, 61],
      [14, 96],
      [196, 108],
      [138, 16],
      [23, 34],
      [69, 7],
      [80, -17],
    ],
    [
      [3773, 8577],
      [43, 220],
      [54, 287],
      [-40, 113],
      [-76, 63],
      [3, 118],
      [157, 11],
      [-40, 68],
      [-82, 0],
      [1, 101],
      [85, 8],
      [203, 0],
      [64, 16],
      [77, 46],
      [38, -64],
      [4, -75],
      [38, -5],
    ],
    [
      [4302, 9484],
      [90, -77],
      [103, 35],
      [51, -47],
      [53, 73],
      [70, 20],
      [106, 58],
      [10, 49],
      [110, 42],
      [4, 45],
      [-113, 9],
      [8, 39],
      [-38, 69],
      [3, 78],
      [-78, 67],
      [51, 26],
      [51, -40],
      [109, 9],
      [74, -61],
      [42, 10],
      [-3, 60],
      [206, 38],
      [46, 46],
      [49, -1],
      [68, 63],
      [-22, 50],
    ],
    [
      [5352, 10144],
      [90, 11],
      [40, -30],
      [-29, -95],
      [61, -11],
      [46, -86],
      [-53, -53],
      [-28, -152],
      [44, -72],
      [-1, -68],
      [87, -79],
      [59, -26],
      [64, 8],
      [38, 51],
      [47, -10],
      [40, 32],
      [118, 52],
      [51, -24],
      [55, 11],
    ],
    [
      [4050, 152],
      [16, -87],
      [-232, 26],
      [-48, 50],
      [264, 11],
    ],
    [
      [3999, 188],
      [0, -3],
    ],
    [
      [3997, 171],
      [-71, -11],
      [-124, 27],
      [-76, -12],
      [-248, 42],
      [141, 48],
      [135, 11],
      [-11, 85],
      [-57, 71],
      [152, 133],
      [76, -29],
      [88, 7],
    ],
    [
      [2965, 1193],
      [27, 1],
      [9, -203],
      [-113, 14],
      [-57, 87],
      [92, 3],
      [42, 98],
    ],
    [
      [3264, 1903],
      [17, -43],
      [-53, -34],
      [-55, 45],
      [91, 32],
    ],
    [
      [3090, 2341],
      [71, -5],
      [8, -51],
      [-56, -73],
      [55, -46],
      [-65, -93],
      [-90, 27],
      [47, 103],
      [-11, 35],
      [41, 103],
    ],
    [
      [4032, 592],
      [-134, 24],
      [-270, -103],
      [-28, -155],
      [-44, -20],
      [-141, 28],
      [-68, 50],
      [191, 50],
      [15, 53],
      [-209, -82],
      [-125, 7],
      [-12, 94],
      [-45, 36],
      [-1, 66],
      [-123, 186],
      [22, 29],
      [-76, 110],
      [55, 3],
      [-30, 118],
      [6, 128],
      [33, 21],
      [-55, 85],
      [108, -10],
      [31, 41],
      [-176, 12],
      [70, 80],
      [63, 27],
      [-53, 46],
      [-140, 23],
      [-106, 0],
      [170, 109],
      [89, 32],
      [72, -73],
      [76, 148],
      [-4, 63],
      [40, 2],
      [67, 32],
      [7, 44],
      [-101, 63],
      [44, 50],
      [-16, 58],
      [60, 75],
      [-22, 181],
      [-132, 27],
      [-49, 130],
      [40, 165],
      [81, 81],
      [-7, 69],
      [-45, 109],
      [14, 54],
      [-35, 62],
      [-1, 59],
      [67, 25],
      [50, 85],
      [61, 191],
      [72, 78],
      [32, 152],
      [66, 110],
      [39, 188],
      [-44, 202],
      [-1, 134],
      [66, 72],
      [-10, 99],
      [-30, 62],
      [60, 96],
      [6, 69],
      [38, 54],
      [-8, 76],
      [30, 28],
      [27, 164],
      [-4, 77],
      [36, 33],
      [-23, 102],
      [13, 157],
      [-11, 120],
      [39, 10],
      [32, 222],
      [16, 38],
      [-26, 150],
      [15, 76],
      [-29, 128],
      [-18, 160],
    ],
    [
      [3699, 6237],
      [73, 14],
      [32, 45],
      [-13, 41],
      [60, 38],
    ],
    [
      [3539, 11248],
      [-110, -32],
      [-49, -82],
      [-38, -5],
      [-73, -113],
      [-18, -192],
      [39, -40],
      [19, -67],
      [49, -52],
      [-17, -65],
      [6, -84],
      [42, -10],
      [21, -52],
      [154, -18],
      [82, 23],
      [104, -26],
      [112, -136],
      [65, 16],
      [73, -14],
      [182, 22],
      [25, -38],
      [-37, -92],
      [-34, -31],
      [-4, -130],
      [40, -125],
      [56, -56],
      [-91, -89],
      [88, -69],
      [59, -134],
      [18, -73],
    ],
    [
      [3773, 8577],
      [-60, 68],
      [-72, 8],
      [117, 177],
      [-52, 38],
      [-109, 48],
      [-39, -24],
      [-94, 33],
      [-34, -31],
      [-74, -19],
      [-93, 4],
      [-44, 34],
      [2, 69],
      [-59, 21],
      [-18, 69],
      [-112, 52],
      [-21, 62],
      [-125, 87],
      [-24, -10],
    ],
    [
      [2862, 9263],
      [-87, 32],
      [-45, 46],
      [-144, -20],
      [-90, 27],
      [-52, 73],
      [-68, 13],
      [-121, 85],
    ],
    [
      [2255, 9519],
      [-3, 64],
      [71, 127],
      [59, -13],
      [58, 20],
      [17, 74],
      [76, 91],
      [-38, 63],
      [18, 90],
      [-6, 125],
      [-23, 49],
      [42, 32],
      [-29, 75],
      [12, 51],
      [-94, 115],
      [13, 40],
      [82, 38],
      [24, 46],
      [-48, 90],
      [18, 21],
      [93, -86],
      [27, 90],
      [72, 55],
      [102, 116],
      [25, 146],
      [114, 87],
      [59, -23],
      [54, 57],
      [153, -4],
      [91, 67],
      [85, 32],
      [22, 36],
      [82, 60],
      [71, -23],
      [22, -39],
      [-37, -40],
    ],
    [
      [2862, 9263],
      [2, -70],
      [-49, -162],
      [-91, -104],
      [-102, -73],
      [-199, -68],
      [-88, -84],
      [-23, -77],
      [-88, -168],
      [-50, 0],
      [-35, 72],
      [-98, 36],
      [-56, -27],
      [-13, 79],
      [54, 20],
      [-30, 80],
    ],
    [
      [1996, 8717],
      [67, 33],
      [39, 104],
      [-102, -23],
      [-58, 49],
      [-33, 133],
      [18, 45],
      [-29, 46],
      [51, 20],
      [33, 57],
      [-12, 36],
      [73, 72],
      [-1, 127],
      [202, 71],
      [11, 32],
    ],
    [
      [113, 9306],
      [27, -60],
      [66, -91],
      [-23, -35],
      [-80, -8],
      [-6, 39],
      [47, 40],
      [-33, 39],
      [2, 76],
    ],
    [
      [5588, 757],
      [-87, -99],
      [-100, 25],
      [73, 67],
      [114, 7],
    ],
    [
      [5696, 761],
      [141, -8],
      [8, -58],
      [-127, -46],
      [-134, -18],
      [-33, 33],
      [145, 97],
    ],
    [
      [6401, 9667],
      [44, 30],
      [63, 183],
      [-63, 76],
      [-21, 140],
      [53, 72],
    ],
    [
      [6477, 10168],
      [39, 66],
      [162, -47],
      [182, -144],
      [43, -92],
    ],
    [
      [5950, 10191],
      [-14, -76],
      [-67, -6],
      [-59, -139],
      [41, -81],
      [45, -52],
      [48, -1],
      [14, -83],
      [85, -135],
      [38, -15],
    ],
    [
      [5352, 10144],
      [-109, 117],
      [44, 47],
      [-14, 67],
      [134, 58],
      [-53, 97],
      [39, 49],
      [83, 40],
      [34, 37],
      [-35, 45],
    ],
    [
      [5475, 10701],
      [147, -83],
      [120, -124],
      [11, -71],
      [57, -10],
      [154, -122],
      [-14, -100],
    ],
    [
      [3699, 6237],
      [-165, 117],
      [-27, 58],
      [-50, 15],
      [-151, 88],
      [-173, 73],
      [-59, 44],
      [-191, 94],
      [-67, 84],
      [-65, 39],
      [-60, 85],
      [11, 130],
      [-102, 145],
      [-62, 68],
      [-6, 71],
      [-75, 58],
      [-4, 61],
      [-66, 96],
      [-75, 192],
      [-76, 126],
      [-8, 36],
      [-82, 83],
      [-25, 75],
      [-60, 79],
      [-195, 119],
      [42, 70],
      [-59, 73],
      [24, 31],
      [-47, 57],
      [14, 73],
      [82, 88],
      [74, 52],
    ],
    [
      [5950, 10191],
      [50, 88],
      [164, -34],
      [26, 28],
      [184, 1],
      [126, -24],
      [-23, -82],
    ],
    [
      [6613, 3680],
      [-67, -108],
      [-65, -46],
      [-136, -50],
      [-146, 33],
      [-88, -22],
      [-139, 73],
      [-120, -2],
      [-97, 89],
      [4, 131],
      [48, 17],
      [-20, 100],
    ],
    [
      [3539, 11248],
      [-108, -43],
      [4, -35],
      [62, -110],
      [-9, -44],
      [-85, -104],
      [62, -77],
      [13, -50],
      [71, 12],
      [37, 38],
      [2, 66],
      [-74, 108],
      [-9, 56],
      [113, 74],
      [118, 30],
      [-20, 82],
      [48, 54],
      [31, -30],
      [36, -91],
      [61, 11],
      [69, -13],
      [77, -46],
      [30, -99],
      [51, -19],
      [240, 28],
      [71, -6],
      [44, -48],
      [120, -35],
      [72, 1],
      [103, 62],
      [56, -5],
      [49, 30],
      [139, 22],
      [-23, -38],
      [76, -101],
      [37, 16],
      [55, -33],
      [74, -1],
      [113, -79],
      [-35, -29],
      [0, -99],
      [105, 11],
      [60, -13],
    ],
  ],
  transform: {
    scale: [0.005840514774017326, 0.006024734904741892],
    translate: [-91.99970238120387, -55.91368171695247],
  },
};
