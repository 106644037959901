import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Text from '../Text';
import { FaRegLightbulb } from 'react-icons/fa';
import CarouselInformation from '../Carousel';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function TravelInformationCarousel() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div>
            <Text className="text-information" onClick={handleOpen}>
                <FaRegLightbulb className="icon-light"></FaRegLightbulb>
                Informações acompanhamento de viagens
            </Text>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <CarouselInformation></CarouselInformation>
                </Box>
            </Modal>
        </div >
    );
}