import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import api from 'services/api';
import { cooperplaceApi } from 'services/api';

import Modal from 'v3/components/Modal';
import Select from 'v3/components/Select';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import { useSnackbar } from 'v3/components/Snackbar';
import { Row, Col } from 'react-bootstrap';

export function VehicleModal({
  aggregationVehicles,
  isOpen,
  onClose,
  setShow,
  getAggregatedVehicles,
}) {
  const [vehicle, setVehicle] = useState();
  const [error, setError] = useState('');
  const snackbar = useSnackbar();
  const params = useParams();
  const [loading, setLoading] = useState(false);

  async function fetchVehicles(search) {
    try {
      const response = await api.get('vehicle/license_plate', {
        params: { search },
      });
      return response.data;
    } catch {
      snackbar.show(<Text> Erro ao pesquisar veículo! </Text>, {
        type: 'error',
      });
    }
    return false;
  }

  async function AddVehicle(event) {
    event.preventDefault();
    setLoading(true);
    if (aggregationVehicles.find(item => item.vehicle_id === vehicle.id)) {
      setError('Veículo já adicionado');
      setLoading(false);

      return;
    }
    const data = {
      vehicle_id: vehicle ? vehicle.id : null,
      aggregation_offer_id: params.id,
    };
    try {
      setLoading(true);
      await cooperplaceApi.post(`aggregation/`, data);
      getAggregatedVehicles();
    } catch (error) {
      const { data } = error.response;
      if (data?.message) {
        snackbar.show(
          <Text>
            {`Não foi possível adicionar o veículo selecionado.${data.message}`}{' '}
          </Text>,
          { type: 'error' }
        );
      } else {
        snackbar.show(
          <Text>Algo deu errado. Tente novamente mais tarde.</Text>,
          { type: 'error' }
        );
      }
    } finally {
      setLoading(false);
    }

    setVehicle(null);
    setError('');
    setShow(false);
  }

  return (
    <>
      <Modal
        show={isOpen}
        onHide={onClose}
        heading={
          <Text color="dark" type="header">
            Veículo
          </Text>
        }
        body={
          <form onSubmit={AddVehicle}>
            <Row>
              <Col xs={12}>
                <Select.Async
                  label="Veículo"
                  onSearch={fetchVehicles}
                  value={vehicle}
                  onChange={value => {
                    setVehicle(value);
                  }}
                  getOptionLabel={option => option.plate}
                  getOptionValue={option => option.id}
                  placeholder="Selecione a placa..."
                  error={error}
                />
              </Col>
              <Col
                xs={12}
                className="align-items-center d-flex justify-content-center my-4"
              >
                <Button type="submit" disabled={!vehicle} loading={loading}>
                  <Text weigth={500} type="regular">
                    Adicionar
                  </Text>
                </Button>
              </Col>
            </Row>
          </form>
        }
      />
    </>
  );
}
