import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import useWindowResize from 'hooks/useWindowResize';
import ApplicationLayout from 'v3/layouts/Application';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import InfiniteScroll from 'v3/components/InfiniteScroll';
import BennerButton from 'v3/components/BennerButton';
import { validatePermission } from 'actions/index';
import { usePermission } from 'hooks';
import Filter from './Filter';
import CardDriver from './Card';
import * as Controller from './controller';

function ListDrivers() {
  const [drivers, setDrivers] = useState([]);
  const [params, setParams] = useState({
    name: '',
    document: '',
    tags: '',
  });
  const [loading, setLoading] = useState(false);
  const [saveContainerOffset, setSaveContainerOffset] = useState(0);
  const [pagination, setPagination] = useState({
    lastPage: 1,
    page: 1,
    total: 0,
  });

  const hasPermissionToCreate = validatePermission('CADASTRAR_MOTORISTAS');
  const hasPermissionToImportFromBenner = validatePermission(
    'IMPORTAR_MOTORISTA_BENNER'
  );

  usePermission('VISUALIZAR_MOTORISTAS', { redirect: true });

  function fetchDriver(filter) {
    Controller.getDrivers(filter, pagination.page, {
      setDrivers,
      setLoading,
      setPagination,
    });
  }

  useEffect(() => {
    fetchDriver(params);
  }, []);

  useWindowResize(() => {
    const header = document.getElementById('header-container');
    const originDistanceToRight =
      window.pageXOffset + header.getBoundingClientRect().right;
    const headerWidth = header.getBoundingClientRect().width;

    const distanceToRight = originDistanceToRight - headerWidth;
    setSaveContainerOffset(distanceToRight);
  }, []);

  function renderItem(drivers) {
    return <CardDriver driver={drivers} />;
  }

  return (
    <>
      <ApplicationLayout
        title="Motorista"
        RightComponent={
          <>
            {hasPermissionToImportFromBenner ? (
              <BennerButton
                endpoint="drivers/importFromBenner"
                title="Importar Motorista"
                label="CPF"
                field="cpfCnpj"
                redirectTo="/motoristas"
                itemToImport={null}
                items={null}
              />
            ) : null}

            {hasPermissionToCreate && (
              <>
                <Link to="/motoristas/cadastrar">
                  <Button variant="primary" className="py-2 mr-2">
                    <Text weight={500} color="white" type="regular">
                      Novo Motorista
                    </Text>
                  </Button>
                </Link>
              </>
            )}
          </>
        }
      />
      <div style={{ marginTop: 20, paddingLeft: '50px' }}>
        <Container>
          <Filter
            fetch={filter => {
              setParams(filter);
              Controller.getDrivers(filter, 1, {
                setDrivers,
                setLoading,
                setPagination,
              });
            }}
          />
        </Container>

        <Container>
          <InfiniteScroll
            loading={loading}
            data={drivers}
            renderItem={renderItem}
            onEndReach={() => {
              Controller.getDrivers(params, pagination.page + 1, {
                setDrivers,
                setLoading,
                setPagination,
              });
            }}
            hasMore={pagination.page < pagination.lastPage}
            scrollThreshold={0.7}
          />
        </Container>
      </div>
    </>
  );
}

export default ListDrivers;
