import React, { useState, useEffect } from 'react';

import { Row, Col } from 'react-bootstrap';
import FileSaver from 'file-saver';

import api from 'services/api';
import apiReport from 'services/apis/report';
import { usePermission, useValidation } from 'hooks';

import ApplicationLayout from 'v3/layouts/Application';
import { Text, Button, Select, DatePicker } from 'v3/components';
import { useSnackbar } from 'v3/components/Snackbar';
import { formatDate, parseDate } from 'v3/utils/formatter';

import schema from './schema';

export default function DriverJourney() {
  const snackbar = useSnackbar();
  const [isInFlight, setInFlight] = useState(false);

  const [dates, setDates] = useState({
    from: formatDate(new Date()),
    to: formatDate(new Date()),
  });
  const [clients, setClients] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);

  usePermission('VISUALIZAR_RELATORIO_JORNADA_MOTORISTA', { redirect: true });

  const [errors, { validate }] = useValidation(schema);

  useEffect(() => {
    const [firstError] = Object.values(errors);

    if (firstError) {
      snackbar.show(<Text>{firstError}</Text>, { type: 'error' });
    }
  }, [errors]);

  useEffect(() => {
    async function fetchClientOptions() {
      const response = await api.get('v3/related-clients');
      setClientOptions(response.data.clients);
    }

    fetchClientOptions();
  }, []);

  async function fetchSelectOptions(url, params = {}) {
    try {
      const { data } = await api.get(url, { params });

      return data;
    } catch (err) {
      return [];
    }
  }

  async function exportReport() {
    try {
      const data = {
        from: parseDate(dates.from),
        to: parseDate(dates.to),
        clients: clients?.map(client => client.id),
      };

      const [isValid, params] = await validate(data);

      if (isValid) {
        setInFlight(true);

        const response = await apiReport.get(`journey`, {
          params,
          responseType: 'blob',
        });

        if (response.status === 204) {
          snackbar.show(<Text>Nenhum registro encontrado</Text>, {
            type: 'warning',
          });
        } else {
          FileSaver.saveAs(response.data, response.headers['x-file-name']);
        }
      }
    } catch (error) {
      snackbar.show(<Text>Erro ao gerar relatório</Text>, { type: 'error' });
    } finally {
      setInFlight(false);
    }
  }

  function setDate(key, value) {
    setDates(old => {
      return {
        ...old,
        [key]: value,
      };
    });
  }

  return (
    <ApplicationLayout title="Relatório de tempos de condução">
      <Row className="filter d-flex justify-content-center space-between">
        <Col md={2} xs={12}>
          <DatePicker
            guide
            label="De *"
            value={dates.from}
            onChange={({ target }) => setDate('from', target.value)}
            labelColor="#fff"
          />
        </Col>
        <Col md={2} xs={12}>
          <DatePicker
            guide
            label="Até *"
            value={dates.to}
            onChange={({ target }) => setDate('to', target.value)}
            labelColor="#fff"
          />
        </Col>
        <Col md={6} xs={12}>
          <Select.Async
            multiple
            horizontal
            label="Cliente *"
            onSearch={search =>
              fetchSelectOptions('persons/customers', { search })
            }
            value={clients}
            onChange={value => setClients(value || [])}
            getOptionValue={option => option.id}
            labelColor="#fff"
            options={clientOptions}
          />
        </Col>
        <Col md={2} xs={12} className="vertical bottom">
          <Button variant="success" loading={isInFlight} onClick={exportReport}>
            <Text type="regular" weight={500}>
              Exportar
            </Text>
          </Button>
        </Col>
      </Row>
    </ApplicationLayout>
  );
}
