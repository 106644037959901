import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';
import { usePermission } from 'hooks';
import ApplicationLayout from 'v3/layouts/Application';
import Space from 'v3/components/Space';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';

import Checklist from './Checklist';
import RiskManager from './RiskManager';
import Client from '../shared/Client';
import Data from './Data';
import Goal from '../shared/Goal';
import Integration from './Integration';
import Triggers from '../shared/Triggers';
import PriorityFleet from '../shared/PriorityFleet';
import PriceOrder from '../shared/PriceOrder';

export default function CompanyDetails() {
  const history = useHistory();
  usePermission('VISUALIZAR_EMPRESAS', { redirect: true });
  const hasPermissionToCreateCompany = usePermission('CADASTRAR_EMPRESAS');

  return (
    <ApplicationLayout
      title={`Empresa `}
      RightComponent={
        <Space x="4px">
          <Button variant="secondary" onClick={() => history.goBack()}>
            <Text color="dark" type="regular" weight={500}>
              Voltar
            </Text>
          </Button>
          {hasPermissionToCreateCompany && (
            <Link to="cadastrar">
              <Button>
                <Text color="white" type="regular" weight={500}>
                  Nova empresa
                </Text>
              </Button>
            </Link>
          )}
        </Space>
      }
    >
      <Row>
        <Col md={6} xs={12}>
          <Data />
        </Col>
        <Col md={6} xs={12}>
          <PriceOrder />
          <Checklist />
        </Col>
        <Col xs={12}>
          <Client />
          <PriorityFleet />
        </Col>
        <Col md={6} xs={12}>
          <Integration />
        </Col>
        <Col md={6}>
          <RiskManager />
          <Triggers />
        </Col>
        <Col md={12} xs={12}>
          <Goal />
        </Col>
      </Row>
    </ApplicationLayout>
  );
}
