import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';

import Text from 'v3/components/Text';
import { useSnackbar } from 'v3/components/Snackbar';
import api from 'services/api';
import { useCurrentUser } from 'hooks';
import { useParams } from 'react-router-dom';
import { useRoute } from '../shared/context';
import { validate } from '../shared/validator';

import SharedData from '../shared/Section';
import Inputs from '../shared/Data';
import Editable from '../shared/Editable';
import { formatCurrency } from 'v3/utils/formatter';

export default function RouteData() {
  const route = useRoute();
  const snackbar = useSnackbar();
  const [isEditing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const user = useCurrentUser();

  useEffect(() => {
    if (!params.id)
      route.setData({
        product: null,
        person_id: null,
        vehicles: null,
        tags: null,
        loadType: null,
      });
  }, []);

  async function saveData() {
    setLoading(true);
    try {
      const fixedRate = route?.data?.fixedRate;
      delete route?.data?.fixedRate;
      const isValid = await validate(
        'data',
        { data: route.data },
        route.setErrors
      );

      if (!isValid) {
        snackbar.show(<Text>Verifique os campos obrigatórios</Text>, {
          type: 'error',
        });
        setLoading(false);
        return;
      }

      const payload = {
        data: {
          person_id: route.data.person?.id,
          product_id: route.data.product?.value || route.data.product_id,
          load_type: route.data.loadType?.id,
          currency: route.data?.currency?.value,
          exchange: fixedRate ? route?.data?.exchange : null,
        },
        vehicles: route.data.vehicles
          ? route.data.vehicles.map(tag => tag.id)
          : [],
        tags: route.data.tags ? route.data.tags.map(tag => tag.id) : [],
      };

      await api.put(`v3/route/${params.id}/data`, payload);

      snackbar.show(<Text>Salvo com sucesso</Text>, { type: 'success' });

      route.updatePrevRoute();

      setEditing(false);
    } catch (error) {
      snackbar.show(
        <Text>
          {error.response?.data[0]?.message || 'Erro ao salvar dados'}
        </Text>,
        { type: 'error' }
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <SharedData
      Title="Informações básicas"
      HeaderRightComponent={
        <Editable
          isEditing={isEditing}
          setEditing={setEditing}
          onSave={saveData}
          isLoading={loading}
        />
      }
      loading={route.isFetching}
    >
      {isEditing ? (
        <Inputs />
      ) : (
        <Row>
          <Col xs={12}>
            <Text type="label" color="#464E5F">
              {user.company.product_type === 1 ? 'Cliente' : 'Transportadora'}
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {route.data?.person?.social_name || 'Não informado'}
            </Text>
          </Col>
          <Col xs={12}>
            <Text type="label" color="#464E5F">
              Tipo de veículo
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {route.data?.vehicles?.map(tag => tag.name).join(', ') ||
                'Não informado'}
            </Text>
          </Col>
          <Col xs={12}>
            <Text type="label" color="#464E5F">
              Produto
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {route.data?.product?.name
                ? `${route.data?.product?.name}`
                : 'Não informado'}
            </Text>
          </Col>
          <Col xs={12}>
            <Text type="label" color="#464E5F">
              Filial emissora
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {route.data?.issuer
                ? `${route.data.issuer.name} - ${route.data.issuer.document}`
                : 'Não informado'}
            </Text>
          </Col>
          {!!route.data?.tags?.[0] && (
            <Col xs={12}>
              <Text type="label" color="#464E5F">
                Tags
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {route.data.tags.map(tag => tag.name).join(', ')}
              </Text>
            </Col>
          )}
          <Col xs={12}>
            <Text type="label" color="#464E5F">
              Tipo de Carga
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {route.data?.loadType?.nome
                ? `${route.data?.loadType?.nome}`
                : 'Não informado'}
            </Text>
          </Col>
          <Col xs={12}>
            <Text type="label" color="#464E5F">
              Moeda
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {route?.data?.currency
                ? route.data.currency === 'BRL' ||
                  route.data.currency?.value === 'BRL'
                  ? `Real Brasileiro`
                  : `Dólar Americano`
                : 'Não informado'}
            </Text>
          </Col>
          {route?.data?.exchange ? (
            <Col xs={12}>
              <Text type="label" color="#464E5F">
                Valor do Dólar
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {formatCurrency(route?.data?.exchange)}
              </Text>
            </Col>
          ) : null}
        </Row>
      )}
    </SharedData>
  );
}
