import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Col, Row } from 'react-bootstrap';

import api from 'services/api';

import Modal from 'v3/components/Modal';
import Select from 'v3/components/Select';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import { useSnackbar } from 'v3/components/Snackbar';

import { useClient } from '../context';

function PriorityModal({ isOpen, onClose, fetchFleet }) {
  const client = useClient();
  const params = useParams();
  const snackbar = useSnackbar();
  const [errors, setErrors] = useState('');
  const [vehicle, setVehicle] = useState(null);

  async function fetchVehicles(search) {
    try {
      const response = await api.get('vehicle/license_plate', {
        params: { search },
      });
      return response.data;
    } catch {
      snackbar.show(<Text> Erro ao pesquisar veículo! </Text>, {
        type: 'error',
      });
    }
    return false;
  }
  async function attachVehicle(event) {
    event.preventDefault();

    if (
      client.data?.priorityFleetVehicles
        ?.map(item => item.plate)
        .includes(vehicle.plate)
    ) {
      setErrors('Esse veículo já foi selecionado');
      return;
    }
    try {
      if (params.id) {
        await api.post(`v3/client/${client.data.id}/vehicle/${vehicle.id}`);
        fetchFleet();
      } else
        client.setData({
          priorityFleetVehicles: [
            ...client.data.priorityFleetVehicles,
            vehicle,
          ],
        });
    } catch (error) {
      //
    } finally {
      setVehicle('');
      onClose(true);
      setErrors();
    }
  }
  return (
    <>
      <Modal
        show={isOpen}
        onHide={onClose}
        heading={
          <Text color="dark" type="header">
            Frota Prioritária
          </Text>
        }
        body={
          <form onSubmit={attachVehicle}>
            <Row>
              <Col xs={12}>
                <Select.Async
                  label="Veículos"
                  placeholder="Selecione a placa..."
                  onSearch={fetchVehicles}
                  value={vehicle}
                  onChange={e => setVehicle(e)}
                  getOptionLabel={option => option.plate}
                  getOptionValue={option => option.id}
                  error={errors}
                />
              </Col>
              <Col
                xs={12}
                className="align-items-center d-flex justify-content-center my-4"
              >
                <Button type="submit" disabled={!vehicle} className="py-2">
                  <Text type="regular" weight="500" color="white">
                    Adicionar
                  </Text>
                </Button>
              </Col>
            </Row>
          </form>
        }
      />
    </>
  );
}

export default PriorityModal;
