import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Input, Select } from 'v3/components';
import { currencyOptions } from 'v3/utils/formatter';
import { LoadContext } from '../controller';
import { LoadValueTypeOptions, NegotiationTypeOptions } from './utils';
import { StyledCurrencyCol } from './styles';

const [, useLoad] = LoadContext;

export function PreFreightValues() {
  const load = useLoad();
  const currencyType =
    load?.data?.currency?.value === 'BRL'
      ? '(Compra)'
      : load?.data?.currency?.value === 'USD'
      ? '(Venda)'
      : '';
  const showDailyRate = load?.data?.showDailyRate;

  return (
    <Row className="my-2">
      <StyledCurrencyCol sm={12} md={showDailyRate ? 4 : 3}>
        <Input
          label="Moeda"
          value={load?.data?.freight_exchange}
          onChange={e =>
            load.setData(old => ({
              ...old,
              freight_exchange: e.target.value,
            }))
          }
          masked
          mask={[/\d/, '.', /\d/, /\d/, /\d/, /\d/]}
          error={load?.errors?.freight_exchange}
          id="input-moeda"
        />

        {showDailyRate ? (
          <Input
            label="Moeda dia"
            value={load?.data?.exchange_day}
            id="input-moeda-dia"
            disabled
          />
        ) : null}
      </StyledCurrencyCol>
      <Col sm={12} md={showDailyRate ? 2 : 3}>
        <Select
          label={`Câmbio ${currencyType}`}
          disableClear
          value={load?.data?.currency}
          onChange={value => {
            load.setData(old => ({
              ...old,
              currency: value,
              exchange_day:
                value?.value === 'BRL'
                  ? load?.rateResponse?.buy
                  : value?.value === 'USD'
                  ? load?.rateResponse?.sell
                  : 0,
            }));
          }}
          options={currencyOptions}
          id="select-cambio"
        />
      </Col>
      <Col sm={12} md={showDailyRate ? 2 : 3}>
        <Select
          label="Tipo valor carga *"
          value={load?.data?.load_value_type}
          onChange={value => {
            load.setData(old => ({
              ...old,
              load_value_type: value,
            }));
          }}
          options={LoadValueTypeOptions}
          error={load?.errors?.load_value_type}
          id="select-tipo-valor-carga"
        />
      </Col>
      <Col sm={12} md={showDailyRate ? 4 : 3}>
        <Select
          label="Negociação *"
          value={load?.data?.negotiation_type}
          onChange={value => {
            load.setData(old => ({
              ...old,
              negotiation_type: value,
            }));
          }}
          options={NegotiationTypeOptions}
          error={load?.errors?.negotiation_type}
          id="select-negociacao-frete"
        />
      </Col>
    </Row>
  );
}
