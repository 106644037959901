import React, { useState, useCallback } from 'react';
import dashboardApi from 'services/apis/dashboard';
import { Table, Text, Link } from 'v3/components';
import { formatDateTime, formatCurrency } from 'v3/utils/formatter';
import { useQuery } from 'hooks';
import api from 'services/api';
import {
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  ResponsiveContainer,
} from 'recharts';
import Card, { SeeAll } from './Card';
import { DateOptions } from './Card/utils';

const months = [
  'Jan.',
  'Fev.',
  'Mar.',
  'Abr.',
  'Mai.',
  'Jun.',
  'Jul.',
  'Ago.',
  'Set.',
  'Out.',
  'Nov.',
  'Dez.',
];

export default function MonthlyRevenue({ dashboard, name, id }) {
  const [isFetching, setFetching] = useState(false);
  const [data, setData] = useState({});

  const queryParams = useQuery();

  function setQueryParams(filters) {
    const { tags = [] } = filters;
    queryParams.delete('tags');

    if (tags) {
      tags.forEach(tag => {
        queryParams.append('tags', tag.id);
      });
    }

    if (tags?.length === 0) {
      queryParams.set('no-tags', true);
    } else {
      queryParams.delete('no-tags');
    }
  }

  async function handleChangeDate(value, setSelectedDate, setValues, values) {
    setSelectedDate(value);
    setValues(old => ({ ...old, date: value }));

    const payload = {
      tags: values?.tags,
      clients: values?.clients,
      sizes: values.size,
      component: name,
      date: value,
      card_id: id,
    };

    await api.put(`v3/dashboard/${dashboard.id}/parameters`, payload);
  }

  const fetchMonthlyRevenue = useCallback(async (filters = {}) => {
    try {
      setFetching(true);
      const {
        tags = [],
        clients = [],
        date = '',
        negotiators = [],
        costCenters = [],
        pcps = [],
        companies = {},
      } = filters;
      setQueryParams(filters);

      const response = await dashboardApi.get('company-monthly-revenues', {
        params: {
          tags: tags?.map(tag => tag.id),
          clients: clients?.map(client => client.id),
          start_date: date.value.start_date,
          end_date: date.value.end_date,
          negotiators: negotiators?.map(negotiator => negotiator?.id),
          costCenters: costCenters?.map(costCenter => costCenter?.id),
          pcps: pcps?.map(pcp => pcp?.id),
          companies: companies?.id ? [companies.id] : null,
        },
      });

      const formattedResponse = response.data.map((item, index) => ({
        month: months[index],
        sum: item.sum,
      }));

      setData(formattedResponse);
    } catch (ex) {
      // Handle exception
    } finally {
      setFetching(false);
    }
  }, []);

  const numberFormatter = value => {
    return formatCurrency(value);
  };

  return (
    <Card
      title="Faturamento mensal da Empresa"
      isLoading={isFetching}
      onFiltersChanged={fetchMonthlyRevenue}
      dashboard={dashboard}
      name={name}
      dateFilter
      excludeFilterByDate
      excludeFilterByBranchOffice={true}
      dates={DateOptions.yearly}
      handleChangeDate={handleChangeDate}
      dateFilterOptionLabel={option => option.label}
      dateFilterOptionValue={option => option.value}
      id={id}
      filterByCompanyMultiple={false}
      ListComponent={
        <>
          <Table striped>
            <thead>
              <tr>
                <th>
                  <Text weight="500" color="dark">
                    Centro de custo
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Tags da carga
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Carga
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Criado em
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Origem
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Destino
                  </Text>
                </th>
              </tr>
            </thead>
            <tbody>
              {data.last?.map(load => {
                const title = `Cliente ${load.social_name} - ${load.cgccpf}`;

                return (
                  <tr>
                    <td>
                      <Text type="regular" color="gray">
                        {load.cost_center || '-'}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray">
                        {load.load_tags || '-'}
                      </Text>
                    </td>
                    <td>
                      <Link
                        to={`/cargas/${load.id}`}
                        title={title}
                        target="_blank"
                      >
                        {load.id}
                      </Link>
                    </td>
                    <td>
                      <Text type="regular" color="gray">
                        {formatDateTime(load.created_at)}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray">
                        {load.city_origin} - {load.uf_origin}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray">
                        {load.city_destination} - {load.uf_destination}
                      </Text>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {!!data?.total && (
            <SeeAll
              to={{ pathname: '/cargas', search: queryParams.toString() }}
            >
              ver todas as {data?.total} cargas
            </SeeAll>
          )}
        </>
      }
      NumericComponent={
        <div className="d-flex justify-content-center text-center flex-column">
          <ResponsiveContainer width="100%" height={380}>
            <BarChart
              data={data}
              margin={{ top: 20, right: 20, left: 65, bottom: 0 }}
            >
              <XAxis dataKey="month" />
              <YAxis tickFormatter={numberFormatter} />
              <Tooltip formatter={numberFormatter} />
              <Bar dataKey="sum" fill="#0BB7AF" name="Total" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      }
    />
  );
}
