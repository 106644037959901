import React, { useEffect, useState } from 'react';
import { AuditList } from './AuditList';
import { AuditDetail } from './AuditDetail';

export function AuditSection({ liberations }) {
  const [selectedAudit, setSelectedAudit] = useState([]);
  return (
    <div>
      <AuditList
        liberations={liberations}
        setSelectedAudit={setSelectedAudit}
        selectedAudit={selectedAudit}
      />
      <AuditDetail selected={selectedAudit} />
    </div>
  );
}
