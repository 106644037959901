/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import api from 'services/api';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ApplicationLayout from 'v3/layouts/Application';
import { useSnackbar } from 'v3/components/Snackbar';
import Input from 'v3/components/Input';
import Modal from 'v3/components/Modal';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import { usePermission } from 'hooks';
import Address from './Address';
import General from './General';
import Series from './Series';
import Icms from './Icms';
import Mdfe from './Mdfe';
import { getIssuer, fetchTaxSituations } from './controller';

export default function IssuerDetails() {
  const snackbar = useSnackbar();

  const params = useParams();

  const [issuer, setIssuer] = useState();
  const [taxSituations, setTaxSituations] = useState([]);
  const [addProductModal, setAddProductModal] = useState(false);
  const [addingNcm, setAddingNcm] = useState();
  const [product, setProduct] = useState();
  const [ncm, setNcm] = useState();
  usePermission('VISUALIZAR_EMISSORES', { redirect: true });
  const canEditIssuers = usePermission('EDITAR_EMISSORES');

  async function handleAddProduct() {
    try {
      const response = await api.post(`product-types/`, {
        name: product,
        ncm,
      });
      snackbar.show(<Text>Produto cadastrado com sucesso</Text>, {
        type: 'success',
        duration: 1000,
      });
      return response;
    } catch (err) {
      snackbar.show(<Text>Erro ao cadastrar produto</Text>, {
        type: 'error',
        duration: 1000,
      });
      return err;
    }
  }

  async function handleAddNcm() {
    try {
      const response = await api.put(`product-types/${addingNcm.id}`, {
        ncm,
      });
      snackbar.show(
        <Text>Produto atualizado com sucesso. Tente adicionar novamente.</Text>,
        {
          type: 'success',
          duration: 1000,
        }
      );
      setNcm(null);
      setAddingNcm(null);
      return response;
    } catch (err) {
      snackbar.show(<Text>Erro ao atualizar produto</Text>, {
        type: 'error',
        duration: 1000,
      });
      return err;
    }
  }

  async function handleGetIssuer() {
    const response = await getIssuer(params.id);
    setIssuer(response);
  }

  async function getTaxSituations() {
    const response = await fetchTaxSituations();
    setTaxSituations(response);
  }

  useEffect(() => {
    getTaxSituations();
    handleGetIssuer();
  }, [params.id]);

  return (
    <>
      <Modal
        size="md"
        show={addingNcm}
        onHide={() => setAddingNcm(false)}
        heading="Atualização de produto - Produto precisa ter NCM"
        body={
          <>
            <Row>
              <Col>
                <Input
                  label="Nome do Produto *"
                  value={addingNcm?.name}
                  disabled
                />
              </Col>
              <Col>
                <Input
                  type="number"
                  label="NCM *"
                  value={ncm}
                  onChange={event => setNcm(event.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: '15px',
                }}
              >
                <Button
                  onClick={() => {
                    handleAddNcm(addingNcm.id);
                  }}
                >
                  Atualizar
                </Button>
              </div>
            </Row>
          </>
        }
      />
      <Modal
        size="md"
        show={addProductModal}
        onHide={() => setAddProductModal(false)}
        heading="Cadastro de produto"
        body={
          <>
            <Row>
              <Col>
                <Input
                  label="Nome do Produto *"
                  value={product}
                  onChange={event => setProduct(event.target.value)}
                />
              </Col>
              <Col>
                <Input
                  type="number"
                  label="NCM *"
                  value={ncm}
                  onChange={event => setNcm(event.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: '15px',
                }}
              >
                <Button
                  onClick={() => {
                    handleAddProduct();
                    setAddProductModal(false);
                  }}
                >
                  Cadastrar
                </Button>
              </div>
            </Row>
          </>
        }
      />
      <ApplicationLayout title="Detalhes do Emissor">
        <Row>
          <Col xs={6}>
            <Col>
              {issuer && (
                <General
                  issuer={issuer}
                  handleGetIssuer={handleGetIssuer}
                  canEditIssuers={canEditIssuers}
                />
              )}
            </Col>
            <Col>
              {issuer && (
                <Series
                  issuer={issuer}
                  fetchIssuers={handleGetIssuer}
                  snackbar={snackbar}
                  canEditIssuers={canEditIssuers}
                />
              )}
            </Col>
            <Col>
              {issuer && (
                <Mdfe
                  issuer={issuer}
                  fetchIssuers={handleGetIssuer}
                  snackbar={snackbar}
                  canEditIssuers={canEditIssuers}
                />
              )}
            </Col>
          </Col>
          <Col xs={6}>
            <Col>
              {issuer && (
                <Address
                  issuer={issuer}
                  handleGetIssuer={handleGetIssuer}
                  canEditIssuers={canEditIssuers}
                />
              )}
            </Col>
            <Col>
              {issuer && (
                <Icms
                  issuer={issuer}
                  handleGetIssuer={handleGetIssuer}
                  taxSituations={taxSituations}
                  addProductModal={addProductModal}
                  setAddProductModal={setAddProductModal}
                  setAddingNcm={setAddingNcm}
                  canEditIssuers={canEditIssuers}
                />
              )}
            </Col>
          </Col>
        </Row>
      </ApplicationLayout>
    </>
  );
}
