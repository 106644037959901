/* eslint-disable no-restricted-globals */
/* eslint-disable consistent-return */
// eslint-disable-next-line import/no-cycle

import React from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch, Link, useLocation } from 'react-router-dom';

import { Container, Row, Col } from 'react-bootstrap';
import api from 'services/api';
import { useEffectThrottled } from 'hooks';
import Sidebar from 'v3/components/Menu/Sidebar';
import routes from '../../../../config/MenuNavConstants';
// portal-web\src\config\MenuNavConstants.js
import Text from '../../../components/Text';
import Menu from '../../../components/Menu';
import * as Styled from './styles';
import '../styles.css';

export default function HeaderPowerBiLayout({
  menuOnly,
  onlyCenterComponent,
  contentFluid,
  children,
  isOld = false,
  title,
  fontSize,
}) {
  const location = useLocation();
  const match = useRouteMatch(location.pathname);

  const instance = useSelector(store => store.instance);

  const breadcrumbs = routes
    .filter(({ path }) => match.path.includes(path))
    .map(({ path, ...rest }) => ({
      path: Object.keys(match.params).length
        ? Object.keys(match.params).reduce(
            (acc, param) => acc.replace(`:${param}`, match.params[param]),
            path
          )
        : path,
      ...rest,
    }))
    .sort((a, b) => a.path.length - b.path.length);

  async function handleLogs() {
    try {
      const data = {
        name: typeof title !== 'object' ? title : 'dashboard',
        path: location.pathname,
        description: 'navigation',
        instance: instance || null,
      };

      await api.post('v3/user/logs', data);
    } catch (error) {
      return [];
    }
  }

  useEffectThrottled(
    () => {
      handleLogs();
    },
    [location.pathname, instance],
    1500
  );

  const { length, [length - 1]: last, 1: second } = breadcrumbs;
  return (
    <div>
      <Sidebar />
      <div>
        <Menu path={second} />
        <Styled.Header isOld={isOld} className="header-main">
          {!menuOnly && onlyCenterComponent ? (
            <Container id="header-container">
              <Row md={12} xs={12} className="justify-content-center">
                <Col md={4} xs={4} className="mx-auto">
                  <Text
                    type="subtitle"
                    color="white"
                    className="text-title"
                    fontSize={fontSize}
                  >
                    {title || last.title}
                  </Text>
                  <div>
                    <Styled.Breadcrumb listProps={{ className: 'p-0' }}>
                      {breadcrumbs.map(bread => (
                        <Styled.BreadcrumbItem
                          key={bread.path}
                          linkAs={Link}
                          linkProps={{ to: bread.path }}
                        >
                          <Text type="label" color="white">
                            {bread.title}
                          </Text>
                        </Styled.BreadcrumbItem>
                      ))}
                    </Styled.Breadcrumb>
                  </div>
                </Col>
              </Row>
            </Container>
          ) : null}
        </Styled.Header>
        <Styled.Main isOld={isOld} fluid={contentFluid}>
          {children}
        </Styled.Main>
      </div>
    </div>
  );
}
