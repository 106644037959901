import React, { useEffect, useState } from 'react';
import { cooperplaceApi } from 'services/api';
import HeaderPowerBiLayout from 'v3/layouts/Application/HeaderPowerBiLayout';
import { Row, Col } from 'react-bootstrap';
import Text from 'v3/components/Text';
import { Button, Input, Select } from 'v3/components';
import { useHistory } from 'react-router-dom';
import CheckBox from 'components/CheckBox';
import { useSnackbar } from 'v3/components/Snackbar';
import { usePermission } from 'hooks';
import {
  ColStyled,
  ButtonCancel,
  RowStyled,
  StyledLabel,
  StyledSwitch,
} from './styles';

export default function PanelRegister(props) {
  const [panelData, setPanelData] = useState(null);
  const snackbar = useSnackbar();
  const history = useHistory();
  const usuario = JSON.parse(localStorage.getItem('usuario'));
  const [workspaces, setWorkspaces] = useState([]);
  const [selectedWorkspace, setSelectedWorkspace] = useState({});
  const [selectedDepartment, setSelectedDepartment] = useState({});
  const [departments, setDepartments] = useState([]);
  const [selectedPanel, setSelectedPanel] = useState('');
  const [selectedUrlPanel, setUrlPanel] = useState('');
  const [active, setActive] = useState(false);
  const [newWorkspace, setNewWorkspace] = useState(false);
  const [newDepartament, setNewDepartament] = useState(false);
  const [rls, setRls] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [errors, setErrors] = useState({
    workspace: '',
    department: '',
    panel: '',
    urlPanel: '',
  });
  usePermission('GERENCIAMENTO_PAINEIS_BI', { redirect: true });
  let hasErrors = false;

  useEffect(() => {
    if (props && props.location && props.location.state) {
      const panelData = props.location.state.state;
      setPanelData(panelData);
    }
  }, [props]);

  useEffect(() => {
    if (panelData) {
      setIsEditing(true);
      setSelectedWorkspace({
        workspaceName: panelData.workspace_name,
        id: panelData.workspace_id,
      });
      setSelectedDepartment({
        departamentName: panelData.departament_name,
        id: panelData.departament_id,
      });
      setSelectedPanel(panelData.name);
      setUrlPanel(panelData.url);
      setActive(panelData.active);
      setRls(panelData.rls);
    }
  }, [panelData]);

  async function createPanel(departamentId) {
    const panel = {
      name: selectedPanel,
      url: selectedUrlPanel,
      userUpdated: usuario.id,
      userCreated: usuario.id,
      active,
      rls,
      departamentId,
    };

    let response;
    if (isEditing) {
      response = await cooperplaceApi.put(`carg-panel/${panelData.id}`, panel);
      if (response) {
        history.push('/PowerBi/PainelUser');
      }
      setIsEditing(false);
    } else {
      response = await cooperplaceApi.post('carg-panel/', panel);
    }

    if (response.data.code === 409) {
      setErrors(prevState => ({
        ...prevState,
        panel: response.data.message,
      }));
      hasErrors = true;
    }
    return response;
  }

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    const workspacesResponse = await cooperplaceApi.get('workspaces/');
    setWorkspaces(workspacesResponse.data);
  }

  async function handleCancel() {
    // Reset the form fields
    setSelectedWorkspace('');
    setSelectedDepartment('');
    setSelectedPanel('');
    setUrlPanel('');
    setActive(false);

    // Reset the editing state
    setIsEditing(false);
  }

  useEffect(() => {
    if (selectedWorkspace) {
      fetchDepartments(selectedWorkspace.id);
    } else {
      setDepartments([]);
    }
  }, [selectedWorkspace]);

  async function fetchDepartments(workspaceId) {
    const response = await cooperplaceApi.get(
      `departaments/byWorkspaceId/${workspaceId}`
    );
    setDepartments(response.data);
  }

  const handleActiveChange = event => {
    setActive(event.target.checked);
  };

  const handleRlsChange = event => {
    setRls(event.target.checked);
  };

  async function handleSubmit() {
    try {
      const formHasErrors = validateForm();
      if (formHasErrors) {
        return;
      }
      let workspaceId = '';
      let departamentId = '';
      if (newWorkspace === false && newDepartament === false) {
        workspaceId = selectedWorkspace.id;
        departamentId = selectedDepartment.id;
      }
      if (newWorkspace === true && newDepartament === true) {
        workspaceId = await createtWorkspace();
        departamentId = await createDepartament(workspaceId);
      }
      if (newWorkspace === false && newDepartament === true) {
        workspaceId = selectedWorkspace.id;
        departamentId = await createDepartament(workspaceId);
      }
      if (newWorkspace === true && newDepartament === false) {
        workspaceId = await createtWorkspace();
        departamentId = selectedDepartment.id;
      }

      const response = await createPanel(departamentId);
      if (!hasErrors) {
        if (response.status === 200) {
          if (isEditing) {
            snackbar.show(<Text>Painel alterado com successo!</Text>, {
              type: 'success',
            });
          } else {
            snackbar.show(<Text>Painel cadastrado com successo!</Text>, {
              type: 'success',
            });
          }
          fetchData();
          if (selectedWorkspace) {
            fetchDepartments(selectedWorkspace.id);
          } else {
            setDepartments([]);
          }
          cleanPartialForm();
          setErrors({
            workspace: '',
            department: '',
            panel: '',
            urlPanel: '',
          });
        }
        cleanPartialForm();
      } else {
        snackbar.show(<Text>Erro ao cadastrar painel</Text>, {
          type: 'error',
        });
      }
    } catch (err) {
      snackbar.show(<Text>Erro ao cadastrar painel</Text>, {
        type: 'error',
      });
      console.error('Erro ao inserir os dados:', err.message);
    }
  }

  function cleanPartialForm() {
    setSelectedPanel('');
    setUrlPanel('');
    setNewWorkspace(false);
    setNewDepartament(false);
  }

  function cleanForm() {
    if (!isEditing) {
      setSelectedDepartment('');
      setSelectedPanel('');
      setUrlPanel('');
      setRls(false);
      setActive(false);
      setNewWorkspace(false);
      setNewDepartament(false);
    } else {
      setSelectedDepartment('');
    }
  }

  function cleanFormOnNewWorkspace() {
    setSelectedDepartment('');
    setSelectedPanel('');
    setUrlPanel('');
    setRls(false);
    setActive(false);
    setNewDepartament(true);
  }

  function validateForm() {
    if (!selectedWorkspace) {
      setErrors(prevState => ({
        ...prevState,
        workspace: 'Campo Workspace obrigatório!',
      }));
      hasErrors = true;
    }
    if (!selectedDepartment) {
      setErrors(prevState => ({
        ...prevState,
        department: 'Campo Departamento obrigatório',
      }));
      hasErrors = true;
    }
    if (!selectedPanel) {
      setErrors(prevState => ({
        ...prevState,
        panel: 'Campo Painel obrigatório',
      }));
      hasErrors = true;
    }
    if (!selectedUrlPanel) {
      setErrors(prevState => ({
        ...prevState,
        urlPanel: 'Campo URL do Painel obrigatório',
      }));
      hasErrors = true;
    } else if (!selectedUrlPanel.includes('app.powerbi.com')) {
      setErrors(prevState => ({
        ...prevState,
        urlPanel: 'Formato de URL inválido',
      }));
      hasErrors = true;
    }

    return hasErrors;
  }

  async function createtWorkspace() {
    try {
      const workspace = {
        workspaceName: selectedWorkspace,
        userUpdated: usuario.id,
        userCreated: usuario.id,
      };
      const response = await cooperplaceApi.post('workspaces/', workspace);
      if (response.data.code === 409) {
        setErrors(prevState => ({
          ...prevState,
          workspace: response.data.message,
        }));
        hasErrors = true;
      }
      return response.data.id;
    } catch (error) {
      console.log('Erro ao criar workspace:', error.message);
    }
  }

  async function createDepartament(workspaceId) {
    try {
      const departament = {
        departamentName: selectedDepartment,
        userUpdated: usuario.id,
        userCreated: usuario.id,
        workspaceId,
      };
      const response = await cooperplaceApi.post('departaments/', departament);
      if (response.data.code === 409) {
        setErrors(prevState => ({
          ...prevState,
          department: response.data.message,
        }));
        hasErrors = true;
      }
      return response.data.id;
    } catch (error) {
      console.log('Erro ao criar departamento:', error.message);
    }
  }

  return (
    <>
      {!isEditing ? (
        <HeaderPowerBiLayout
          title="Cadastro de painéis"
          contentFluid
          isOld
          onlyCenterComponent
          fontSize="20px"
        />
      ) : (
        <HeaderPowerBiLayout
          title="Editar painel"
          contentFluid
          isOld
          onlyCenterComponent
          fontSize="20px"
        />
      )}
      <RowStyled md={12}>
        <Col md={2} />
        <Col md={8}>
          {!isEditing ? (
            <Text
              color="#969494"
              weight="400"
              fontSize="25px"
              className="d-flex text-center justify-content-center"
            >
              Cadastro de painéis
            </Text>
          ) : (
            <Text
              color="#969494"
              weight="400"
              fontSize="25px"
              className="d-flex text-center justify-content-center"
            >
              Editar painel
            </Text>
          )}

          <RowStyled lg={12} md={12} xs={12}>
            <Col md={6}>
              {!isEditing ? (
                <CheckBox
                  label="Workspace Novo?"
                  checked={newWorkspace}
                  onClick={() => {
                    handleCancel();
                  }}
                  onChange={() => {
                    setNewWorkspace(!newWorkspace);
                    setSelectedWorkspace('');
                    if (!newWorkspace) {
                      cleanFormOnNewWorkspace();
                    }
                  }}
                />
              ) : (
                <></>
              )}
              {newWorkspace ? (
                <Input
                  placeholder="Digite o nome do Workspace..."
                  label="WORKSPACE"
                  type="text"
                  value={selectedWorkspace.workspaceName}
                  error={errors.workspace}
                  onChange={({ target }) => {
                    setSelectedWorkspace(target.value);
                    setErrors(prevState => ({
                      ...prevState,
                      workspace: '',
                    }));
                  }}
                />
              ) : (
                <Select
                  modalHeading="Selecione um Workspace"
                  modalBodyTitle="Workspace:"
                  label="WORKSPACE"
                  value={selectedWorkspace}
                  options={workspaces}
                  getOptionLabel={option => option.workspaceName}
                  getOptionValue={option => option.id}
                  onChange={value => {
                    setSelectedWorkspace(value);
                    cleanForm();
                    setErrors(prevState => ({
                      ...prevState,
                      workspace: '',
                    }));
                  }}
                  horizontal
                  error={errors.workspace}
                />
              )}
            </Col>

            <Col md={6}>
              {!isEditing ? (
                <CheckBox
                  label="Departamento Novo?"
                  checked={newDepartament}
                  onClick={() => {
                    handleCancel();
                  }}
                  onChange={() => {
                    setNewDepartament(!newDepartament);
                    setSelectedDepartment('');
                  }}
                />
              ) : (
                <></>
              )}
              {newDepartament ? (
                <Input
                  placeholder="Digite o nome do Departamento..."
                  label="DEPARTAMENTO"
                  type="text"
                  value={selectedDepartment.departamentName}
                  options={departments}
                  getOptionLabel={option => option.departamentName}
                  getOptionValue={option => option.id}
                  onChange={({ target }) => {
                    setSelectedDepartment(target.value);
                    setErrors(prevState => ({
                      ...prevState,
                      department: '',
                    }));
                  }}
                />
              ) : (
                <Select
                  modalHeading="Selecione um Departamento"
                  modalBodyTitle="Departamento:"
                  label="DEPARTAMENTO"
                  value={selectedDepartment}
                  options={departments}
                  error={errors.department}
                  getOptionLabel={option => option.departamentName}
                  getOptionValue={option => option.id}
                  onChange={value => {
                    setSelectedDepartment(value);
                    setErrors(prevState => ({
                      ...prevState,
                      department: '',
                    }));
                  }}
                  horizontal
                />
              )}
            </Col>
          </RowStyled>

          <Row lg={12} md={12} xs={12}>
            <Col md={6}>
              <Input
                label="PAINEL"
                type="text"
                value={selectedPanel}
                error={errors.panel}
                onChange={({ target }) => {
                  setSelectedPanel(target.value);
                  setErrors(prevState => ({
                    ...prevState,
                    panel: '',
                  }));
                }}
              />
            </Col>

            <Col md={6}>
              <Input
                label="URL PAINEL"
                type="text"
                error={errors.urlPanel}
                value={selectedUrlPanel}
                onChange={({ target }) => setUrlPanel(target.value)}
              />
            </Col>

            <Col md={6}>
              <Row lg={12} md={12} xs={12}>
                <Col md={1}>
                  <StyledLabel>RLS</StyledLabel>
                  <StyledSwitch
                    checked={rls}
                    onChange={handleRlsChange}
                    name="rls"
                  />
                </Col>
                <Col md={1}>
                  <StyledLabel>Ativo</StyledLabel>
                  <StyledSwitch
                    checked={active}
                    onChange={handleActiveChange}
                    name="ativo"
                  />
                </Col>
              </Row>
            </Col>

            <ColStyled md={6}>
              <ButtonCancel
                variant="secondary"
                onClick={() => history.push('/PowerBi/PainelUser')}
              >
                <Text weight={500} color="gray" type="regular">
                  Cancelar
                </Text>
              </ButtonCancel>
              <Button variant="success" onClick={handleSubmit}>
                <Text weight={500} color="white" type="regular">
                  Salvar
                </Text>
              </Button>
            </ColStyled>
          </Row>
        </Col>
      </RowStyled>
    </>
  );
}
