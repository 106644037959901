import React from 'react';
import Tab from 'v3/components/Tab';
import Tabs from 'v3/components/Tabs';
import { Text } from 'v3/components';
import { Products } from './Products';
import { usePolicyRegister } from '../../Register/context';

export function Operations() {
  const { policy, fetchPolicy } = usePolicyRegister();
  return (
    <>
      <Text as="p" weight={500} color={'dark'} type="regular">
        Operações
      </Text>
      <Tabs
        defaultActiveKey={0}
        style={{
          display: 'flex',
          flexWrap: 'nowrap',
          overflowX: 'auto',
          overflowY: 'hidden',
          fontSize: '0.75rem',
        }}
        linkActiveColor="dark"
        linkActiveBackgroundColor="grayish_blue"
        linkWeight="500"
        linkPadding="0.5rem 1rem"
        linkBorder=" #dee2e6 #dee2e6 #fff"
      >
        {policy?.operations?.map((op, opIndex) => {
          return (
            <Tab
              title={op.name}
              eventKey={opIndex}
              key={opIndex}
              style={{ padding: '1rem 0.5rem' }}
            >
              <Text as="p" weight={500} color={'dark'} type="regular">
                Produtos
              </Text>
              <Products
                products={op?.products}
                policyId={policy?.id}
                refetchPolicy={fetchPolicy}
              />
            </Tab>
          );
        })}
      </Tabs>
    </>
  );
}
