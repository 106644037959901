import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { useSnackbar } from 'v3/components/Snackbar';
import Text from 'v3/components/Text';

import api from 'services/api';

import Details from '../Details';
import Register from '../Register';

import { ProductProvider } from './context';

export default function ProductShared() {
  const snackbar = useSnackbar();
  const params = useParams();
  const dispatch = useDispatch();

  const [product, setProduct] = useState({});
  const [prevProduct, setPrevProduct] = useState(product);
  const [isFetching, setFetching] = useState(false);
  const [errors, setErrors] = useState({});

  function showSnackbar(text, options) {
    snackbar.show(<Text> {text} </Text>, options);
  }

  useEffect(() => {
    async function fetchProduct() {
      try {
        setFetching(true);
        const response = await api.get(`product/${params.id}`);
        setProduct(response.data);
        setPrevProduct(response.data);
      } catch (ex) {
        showSnackbar('Não foi possível carregar esse produto', {
          type: 'error',
        });
      } finally {
        setFetching(false);
      }
    }

    if (params.id) {
      fetchProduct();
    }
  }, [params.id]);

  function setData(data) {
    setProduct(prev => ({ ...prev, ...data }));
  }

  function updatePrevProduct() {
    setPrevProduct(product);
  }

  function handleCancel() {
    setProduct(prevProduct);
    setErrors({});
  }

  useEffect(() => {
    if (params.id) {
      dispatch({
        type: 'VIEW_INSTANCE',
        instance: product?.name,
      });
    }
  }, [product]);

  return (
    <ProductProvider
      value={{
        data: product,
        setData,
        isFetching,
        errors,
        setErrors,
        showSnackbar,
        handleCancel,
        updatePrevProduct,
        prevProduct,
      }}
    >
      {params.id ? <Details /> : <Register />}
    </ProductProvider>
  );
}
