/* eslint-disable import/no-unresolved */
import React, { useMemo, useState, useEffect } from 'react';
import { FaTruck } from 'react-icons/fa';

import { useTravel } from 'v3/pages/Travel';
import { formatDateTime } from 'utils/formatter';
import theme from 'v3/theme';

import Text from 'v3/components/Text';

// eslint-disable-next-line import/first
import moment from 'moment';
import * as Styled from './styles';

export default function Header() {
  const travel = useTravel();
  const [hasLocationInfo, setHasLocationInfo] = useState(false);
  const [km, setKm] = useState(0);
  const [estimatedArrivalDateTime, setEstimatedArrivalDateTime] = useState('');

  useEffect(() => {
    if (travel.data.tracking) {
      setKm(travel?.data?.tracking?.remainingDistanceInMeters / 1000);
      const time = moment(travel?.data?.tracking?.updatedAt).add(
        'seconds',
        travel?.data?.tracking?.approximateArrivalTime
      );
      setEstimatedArrivalDateTime(time);
    }
  }, []);

  const estimatedArrivalMessage = useMemo(() => {
    let message = '';
    if (km && estimatedArrivalDateTime) {
      message = `Faltam ${km} km e a previsão estimada de chegada, baseada na última localização, está para às ${formatDateTime(
        estimatedArrivalDateTime
      )}`;
      setHasLocationInfo(true);
    }
    return message;
  }, [estimatedArrivalDateTime, km]);

  const { origin, destinations } = useMemo(
    () => ({
      origin: travel.data?.loads?.loadOrigins?.[0],
      destinations: travel.data?.loads?.loadDestinations,
    }),
    [travel.data?.loads]
  );

  const middleDestinations = useMemo(() => {
    const filtered = destinations?.filter(
      (_, index) => index < destinations?.length - 1
    );

    function groupBy(arr, cep, number) {
      return (arr || []).reduce((acc, x = {}) => {
        const finded = acc?.values?.find(
          value => value?.[cep] === x[cep] && value?.[number] === x[number]
        );

        return {
          ...acc,
          values: finded ? acc?.values : [...(acc?.values || []), x],
        };
      }, {});
    }

    const groupedAddresses = groupBy(filtered, 'zip_code', 'number');

    return groupedAddresses.values || [];
  }, [destinations]);

  const lastDestination = useMemo(
    () => destinations?.[destinations?.length - 1],
    [destinations]
  );

  if (travel.isLoading) {
    return null;
  }

  return (
    <div className="d-flex flex-column">
      <div className="d-flex justify-content-center align-items-center mt-2 mb-4 px-4">
        <div className="d-flex flex-column">
          <Text type="header" color="dark" weight={500} className="mb-1">
            {origin?.city} - {origin?.province}
          </Text>
          <Text type="label" color="gray">
            {formatDateTime(origin?.scheduledTime)}
          </Text>
        </div>
        <div className="d-flex flex-fill align-items-center mx-4">
          <FaTruck color={theme.colors.dark} size={24} />
          <Styled.Timeline>
            {middleDestinations?.map(destination => (
              <Styled.Timeline.City>
                <div className="d-flex flex-column align-items-center">
                  <Styled.Timeline.Marker />
                  <Styled.Timeline.Name>
                    <Text type="little" color="dark">
                      {destination?.city} - {destination?.province}
                    </Text>
                  </Styled.Timeline.Name>
                </div>
              </Styled.Timeline.City>
            ))}
          </Styled.Timeline>
          <Styled.Timeline.Last />
        </div>
        <div className="d-flex flex-column">
          <Text type="header" color="dark" weight={500} className="mb-1">
            {lastDestination?.city} - {lastDestination?.province}
          </Text>
          <Text type="label" color="gray">
            {formatDateTime(lastDestination?.scheduledTime)}
          </Text>
        </div>
      </div>
      {hasLocationInfo && km ? (
        <Styled.RemainingDistanceWarning>
          {estimatedArrivalMessage}
        </Styled.RemainingDistanceWarning>
      ) : null}
    </div>
  );
}
