/* eslint-disable import/no-unresolved */
/* eslint-disable no-shadow */
import React, { useContext, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import Card, { DefaultLoadingBodyComponent } from 'v3/components/Card';
import { useSnackbar } from 'v3/components/Snackbar';
import { useTravel } from 'v3/pages/Travel';
import { cooperplaceApi } from 'services/api';
import { validatePermission } from 'actions';
import { formatDateTime } from 'v3/utils/formatter';
import { BlockedTravelContext } from 'contexts/BlockedTravelProvider';

export default function SalesApproval() {
  const { isTravelBlocked } = useContext(BlockedTravelContext);

  const travel = useTravel();
  const hasSalesApprovalPermission = validatePermission('APROVACAO_POS_VENDA');
  const snackbar = useSnackbar();

  const [loadingApprove, setLoadingApprove] = useState(false);

  if (travel.isLoading) {
    return null;
  }

  async function handleApprove() {
    try {
      setLoadingApprove(true);
      await cooperplaceApi.put(`travels/${travel?.data?.id}/approveAfterSale`);
      snackbar.show(
        <Text weight={500} color="dark">
          Viagem aprovada com sucesso.
        </Text>,
        {
          type: 'success',
        }
      );
      setLoadingApprove(false);
    } catch (error) {
      snackbar.show(
        <Text weight={500} color="dark">
          {error?.response?.data?.message ||
            'Erro ao notificar aprovação ao motorista'}
        </Text>,
        {
          type: error?.response?.status === 404 ? 'warning' : 'error',
        }
      );
      setLoadingApprove(false);
    } finally {
      travel.fetchTravel();
    }
  }
  return (
    <Card
      loading={travel.isLoading}
      LoadingBodyComponent={() => (
        <DefaultLoadingBodyComponent linesCount={3} perLine={2} />
      )}
      header={
        <Row>
          <Col xs={12} className="mb-2">
            <Text color="dark" type="header">
              Pós Venda
            </Text>
          </Col>
        </Row>
      }
      HeaderRightComponent={
        <div>
          {hasSalesApprovalPermission && (
            <Button
              variant="primary"
              loading={loadingApprove}
              className="mr-2"
              onClick={() => handleApprove()}
              disabled={travel?.data?.posVendaApproved || isTravelBlocked}
              title={
                !travel?.data?.posVendaApproved
                  ? 'Aprovar'
                  : 'Viagem já aprovada'
              }
            >
              <Text color="white" type="regular" weight="500">
                Aprovar
              </Text>
            </Button>
          )}
        </div>
      }
    >
      <Row>
        {travel?.data?.posVendaApproved ? (
          <>
            <Col xs={12} md={6}>
              <Text type="label" color="dark">
                Aprovado em
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {formatDateTime(travel?.data?.posVendaApprovedDate)}
              </Text>
            </Col>
            <Col xs={12} md={6}>
              <Text type="label" color="dark">
                Aprovado por
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {travel?.data?.posVendaApprovedUser?.name}
              </Text>
            </Col>
          </>
        ) : (
          <Col xs={12} md={12}>
            <Text type="regular" color="gray" as="p" className="mt-2">
              Viagem ainda não foi aprovada.
            </Text>
          </Col>
        )}
      </Row>
    </Card>
  );
}
