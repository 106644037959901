/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import Card from 'v3/components/Card';
import Text from 'v3/components/Text';
import Modal from 'v3/components/Modal';
import Button from 'v3/components/Button';
import List from 'v3/components/BulletList';
import Tooltip from 'v3/components/Tooltip';
import { FaWhatsapp, FaExternalLinkAlt } from 'react-icons/fa';
import {
  formatCurrency,
  makeConversion,
  currencyWithSymbol,
  conversionParams,
} from 'v3/utils/formatter';
import { usePermission } from 'hooks';
import * as Controller from './controller';
import * as Styled from './styles';

export default function TravelCard({ travel }) {
  const [show, setShow] = useState(false);
  const [showCtes, setShowCtes] = useState(false);

  const handleCloseAllDestinations = () => setShow(false);
  const handleShowAllDestinations = () => setShow(true);

  const handleCloseCtes = () => setShowCtes(false);
  const handleShowCtes = () => setShowCtes(true);
  const [travelCurrency, setTravelCurrency] = useState('');
  const [travelCurrencySymbol, setTravelCurrencySymbol] = useState('');
  const [conversion, setConversion] = useState({});
  const hasPermissionToSeeTravelDetails = usePermission(
    'VISUALIZAR_VIAGEM_DETALHES'
  );

  useEffect(() => {
    if (travel.currency) {
      setTravelCurrency(travel.currency);
    }
  }, [travel?.currency]);

  useEffect(() => {
    setTravelCurrencySymbol(
      currencyWithSymbol.find(currency => currency.currency === travelCurrency)
        ?.symbol
    );
  }, [travelCurrency]);

  useEffect(() => {
    setConversion(conversionParams[travelCurrencySymbol]);
  }, [travelCurrencySymbol]);

  const ModalDestinations = () => (
    <Modal
      scrollable
      show={show}
      onHide={handleCloseAllDestinations}
      heading={
        <Text type="header" className="ml-3">
          Todos os destinos
        </Text>
      }
      body={
        <Row md={12}>
          <Col md={6} className="pl-5">
            <List items={Controller.locationsObject(true, travel)} />
          </Col>
        </Row>
      }
    />
  );

  const ModalCtes = () => (
    <Modal
      scrollable
      show={showCtes}
      onHide={handleCloseCtes}
      heading={
        <Text type="header" className="ml-3">
          Viagem - {travel.travel_number}
        </Text>
      }
      body={<>{Controller.ctesObject(travel)}</>}
    />
  );

  const Header = () => (
    <div>
      <Text type="regular" color="gray" className="mr-2">
        Viagem {travel.id}{' '}
        {travel.travel_number &&
          travel.travel_number !== travel.id &&
          `- ${travel.travel_number}`}
      </Text>
      <Styled.Badge pill variant={travel.status.name} className="mr-2">
        <Text type="label">{travel.status.name}</Text>
      </Styled.Badge>
      {travel.firstEmitted_data_emissao && (
        <Styled.Badge pill outlined type="dark" className="mr-2">
          <Text type="label">
            Emitida{' '}
            {moment.utc(travel.firstEmitted_data_emissao).format('DD/MM/YYYY')}
          </Text>
        </Styled.Badge>
      )}
      <Styled.Badge pill outlined type="dark">
        <Text type="label">
          Lançada {moment.utc(travel.created_at).format('DD/MM/YYYY')}
        </Text>
      </Styled.Badge>
    </div>
  );
  return (
    <Col xs={12}>
      <ModalCtes />
      <Card
        header={<Header />}
        HeaderRightComponent={
          <div>
            <Tooltip
              content={
                <Text type="label">{travel?.user_created_username}</Text>
              }
              placement="top"
            >
              <Text type="label" color="gray" className="mr-2">
                Criado por {travel?.user_created_username?.split(' ')[0]}{' '}
                {travel?.user_created_username?.split(' ')[1]}
                {' - '}{moment.utc(travel.created_at).format('DD/MM/YYYY')}
              </Text>
            </Tooltip>
            <Link to={`/viagens/${travel.id}`}>
              <Button
                className="mr-2 py-2"
                disabled={!hasPermissionToSeeTravelDetails}
              >
                <Text type="regular" weight="500">
                  Detalhes
                </Text>
              </Button>
            </Link>
          </div>
        }
      >
        <ModalDestinations />
        <Row>
          <Col lg={6} md={6} xs={12}>
            <Row>
              <Col xs={12} className="travel-list-align mb-2">
                <Text className="d-block travel-label-align mb-2">Cliente Tomador</Text>
                <Text color="gray" type="regular" className="d-block mb-1">
                  {travel.load?.client?.social_name}
                </Text>
                <Text color="gray" type="regular" className="d-block mb-1">
                  {travel.load?.client?.cgccpf}
                </Text>
              </Col>
              <Col xs={12}>
                <List
                  items={Controller.locationsObject(
                    false,
                    travel,
                    handleShowAllDestinations
                  )}
                />
              </Col>
            </Row>
          </Col>
          <Col lg={6} md={6} xs={12}>
            <Row>
              <Col lg={6} md={6} xs={12} className="mb-3">
                <Text className="d-block travel-label-align mb-2">Veículo</Text>
                {travel.vehicleAttended && travel.vehicleAttended.vehicle && (
                  <>
                    <Text color="gray" type="regular" className="d-block mb-1">
                      {travel.vehicleAttended.vehicle.type
                        ? travel.vehicleAttended.vehicle.type.name
                        : 'Não Informado'}
                    </Text>
                    <Text color="gray" type="regular" className="d-block mb-1">
                      {travel.vehicleAttended.vehicle.bodyType
                        ? travel.vehicleAttended.vehicle.bodyType.name
                        : 'Não Informado'}
                    </Text>
                    {travel.vehicleAttended.vehicle.axistype && (
                      <Text
                        color="gray"
                        type="regular"
                        className="d-block mb-1"
                      >
                        {travel.vehicleAttended.vehicle.axistype.name}
                      </Text>
                    )}
                  </>
                )}
                <Text color="gray" type="regular" className="d-block mb-1">
                  {travel.plate || 'Não Informado'}
                </Text>
              </Col>
              <Col lg={6} md={6} xs={12} className="mb-3">
                <Text className="d-block travel-label-align mb-2">
                  Motorista
                </Text>
                <Text color="gray" type="regular" className="d-block mb-1">
                  {travel.driver_name}
                </Text>
                <Col className="d-flex d-row m-0 p-0 align-items-center">
                    <FaWhatsapp color="#00E676" className="mb-2 mr-1" />
                  {travel.driver_phone_cooperplace ? (
                    <a
                      href={
                        travel.driver_phone_cooperplace
                          ? `https://web.whatsapp.com/send?phone=55${travel.driver_phone_cooperplace.replace(/[^0-9]+/g,'')}`
                          : ``
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Text type="regular" color="gray" as="p" className="mt-2">
                        {travel.driver_phone_cooperplace}
                      </Text>
                    </a>
                  ) : (
                    <Text type="regular" color="gray" as="p" className="mt-2">Não informado</Text>
                  )}
                </Col>
              </Col>
              <Col lg={6} md={6} xs={12} className="mb-3">
                <Text className="d-block travel-label-align mb-2">CT-e</Text>
                <Text color="gray" type="regular" className="d-block mb-1">
                  {travel.emitteds && travel.emitteds.length > 0
                    ? travel.emitteds.map(x => x.cte_number).join(', ')
                    : 'Não Informado'}
                  {travel.emitteds && travel.emitteds.length > 0 && (
                    <>
                      <Button onClick={handleShowCtes} link>
                        <FaExternalLinkAlt
                          color="#B5B5C3"
                          className="mb-1 ml-2"
                          size={12}
                        />
                      </Button>
                    </>
                  )}
                </Text>
              </Col>
              <Col lg={6} md={6} xs={12} className="mb-3">
                <Text className="d-block travel-label-align mb-2">Valor</Text>
                <Text color="gray" type="regular" className="d-block mb-1">
                  {travel.balance === null
                    ? 'Não Informado'
                    : travelCurrency && travelCurrencySymbol
                    ? formatCurrency(
                        travel.balance,
                        travelCurrency,
                        travelCurrencySymbol
                      )
                    : 'Não informado'}
                </Text>
                {travel?.exchange && (
                  <Text
                    type="label"
                    color="black"
                    weight="bold"
                    className="mt-2"
                  >
                    {conversion && (
                      <>
                        Convertido:{' '}
                        {formatCurrency(
                          makeConversion(
                            travel.balance,
                            conversion?.operation,
                            travel.exchange
                          ),
                          conversion?.currencyName,
                          conversion?.symbol
                        )}
                      </>
                    )}
                  </Text>
                )}
              </Col>
              <Col xs={12}>
                {travel.tags && travel.tags.length > 0 && (
                  <div>
                    <Text className="d-block travel-label-align mb-2">
                      Tags
                    </Text>
                    {travel.tags.map(tag => (
                      <div className="mr-2">
                        <Styled.Badge pill variant="secondary">
                          <Text type="label">{tag.name}</Text>
                        </Styled.Badge>
                      </div>
                    ))}
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </Col>
  );
}
