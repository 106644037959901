import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import api from 'services/api';
import { useParams } from 'react-router-dom';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import Fallback from 'v3/components/Fallback';
import Card from 'v3/components/Card';
import Table from 'v3/components/Table';
import { useSnackbar } from 'v3/components/Snackbar';
import theme from 'v3/theme';
import { FaPencilAlt, FaTrash } from 'react-icons/fa';
import TriggerModal from '../Modals/Trigger';
import { useCompany } from '../context';

export default function Triggers() {
  const company = useCompany();
  const params = useParams();
  const snackbar = useSnackbar();

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    if (!params.id)
      company.setData({
        triggers: [],
      });
  }, []);

  async function handleRemoveTrigger(trigger) {
    if (params.id) {
      try {
        setLoading(true);
        await api.delete(`v3/company/${company.data.id}/trigger/${trigger.id}`);
        company.setData({
          triggers: company.data.triggers.filter(item => item !== trigger),
        });
      } catch (error) {
        snackbar.show(<Text>Erro ao excluir evento</Text>, {
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <>
      <TriggerModal
        isOpen={showModal}
        onClose={() => {
          company.cancelEditing();
          company.setData({ editingCurrentTrigger: null });
          setShowModal(false);
        }}
        setShowModal={setShowModal}
      />
      <Card
        loading={company.isFetching}
        header={
          <Text type="header" color="dark">
            Eventos
          </Text>
        }
        HeaderRightComponent={
          company.data?.triggers?.length > 0 && (
            <Button
              className="py-2"
              onClick={() => {
                setShowModal(true);
                company.setPrevCompany(company.data);
              }}
              disabled={!company.hasPermissionToEdit}
            >
              <Text type="regular" weight="500" color="white">
                Adicionar
              </Text>
            </Button>
          )
        }
      >
        <Row>
          <Fallback
            fall={!company.data?.triggers?.length}
            component={
              <Col xs={12} className="d-flex flex-column align-items-center">
                <Text color={theme.colors.dark} type="regular">
                  Não há eventos cadastrados para essa empresa.
                </Text>
                <Button
                  className="mt-3 py-2"
                  onClick={() => {
                    setShowModal(true);
                    company.setPrevCompany(company.data);
                  }}
                  disabled={!company.hasPermissionToEdit}
                >
                  <Text type="regular" weight="500" color="white">
                    Adicionar
                  </Text>
                </Button>
              </Col>
            }
          >
            <Table striped>
              <thead>
                <tr>
                  <th>
                    <Text weight={500} type="regular" color="dark">
                      Acionado
                    </Text>
                  </th>
                  <th>
                    <Text weight={500} type="regular" color={theme.colors.dark}>
                      Automações
                    </Text>
                  </th>
                  <th className="text-center">
                    <Text type="regular" weight={500} color="dark">
                      Ação
                    </Text>
                  </th>
                </tr>
              </thead>
              <tbody>
                {company.data?.triggers?.map(item => (
                  <tr>
                    <td>
                      <Text type="regular" color="dark">
                        {item.name}
                      </Text>
                    </td>
                    <td>
                      <Text className="m-5" type="regular" color="gray">
                        {item?.automations?.length}
                      </Text>
                    </td>
                    <td width="120" className="text-center">
                      <Button
                        variant="secondary"
                        size="sm"
                        className="mr-1"
                        onClick={() => {
                          company.setData({
                            triggers: company.data.triggers.filter(
                              trigger => trigger !== item
                            ),
                            editingCurrentTrigger: item,
                          });
                          company.setPrevCompany(company.data);
                          setShowModal(true);
                        }}
                        disabled={!company.hasPermissionToEdit}
                      >
                        <FaPencilAlt size={16} color="#464E5F" />
                      </Button>
                      <Button
                        variant="error"
                        size="sm"
                        onClick={() => handleRemoveTrigger(item)}
                        disabled={item.current || !company.hasPermissionToEdit}
                        loading={isLoading}
                      >
                        <FaTrash size={16} />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Fallback>
        </Row>
      </Card>
    </>
  );
}
