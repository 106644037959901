import React, { useState, useCallback, useEffect } from 'react';

import dashboardApi from 'services/apis/dashboard';
import { Table, Text, Link, Button, Modal } from 'v3/components';
import { useSnackbar } from 'v3/components/Snackbar';
import { formatDateTime, formatPlate } from 'v3/utils/formatter';
import moment from 'moment';
import { useQuery } from 'hooks';
import { CgArrowsExchange } from 'react-icons/cg';
import api from 'services/api';
import Card, { Number, Description, SeeAll } from './Card';

export default function TravelsAvailable({ dashboard, name, id }) {
  const [showModal, setShowModal] = useState(false);
  const [filter, setFilter] = useState({});
  const [currentPage, setCurrentPage] = useState({});
  const [pagination, setPagination] = useState({});
  const [data, setData] = useState({});
  const [currentTravel, setCurrentTravel] = useState();
  const [isFetching, setFetching] = useState(false);
  const [daysFromNow, setDaysFromNow] = useState();
  const [loading, setLoading] = useState(false);
  const snackbar = useSnackbar();

  const queryParams = useQuery();

  useEffect(() => {
    setDaysFromNow(moment(new Date()).diff(filter.start_date, 'days'));
  }, [filter.start_date]);

  function setQueryParams(filters) {
    const { tags = [], start_date, end_date } = filters;

    queryParams.delete('tags');

    if (tags) {
      tags.forEach(tag => {
        queryParams.append('tags', tag.id);
      });
    }

    if (start_date) {
      queryParams.set('start_date', start_date);
    }

    if (end_date) {
      queryParams.set('end_date', end_date);
    }

    if (tags?.length === 0) {
      queryParams.set('no-tags', true);
    } else {
      queryParams.delete('no-tags');
    }
  }

  async function handleStatusChange(travel_id) {
    try {
      await api.put(`travel/${travel_id}/change-status`);
      snackbar.show(<Text>Status atualizado</Text>, { type: 'success' });
      setShowModal(false);
    } catch (error) {
      snackbar.show('Erro ao atualizar status', { type: 'error' });
    }
  }
  function getModalBody() {
    return (
      <>
        <div className="text-center pt-4">
          <Text color="gray">
            {`Você tem certeza que gostaria de mudar o status da viagem ${currentTravel?.attended_id} para aguardando
            carregamento?`}
          </Text>
        </div>
        <div className="text-center pt-4">
          <Button
            className="mr-1"
            variant="error"
            onClick={() => {
              setCurrentTravel(null);
              setShowModal(false);
            }}
          >
            Não
          </Button>
          <Button
            className="ml-1"
            variant="success"
            onClick={() => handleStatusChange(currentTravel.attended_id)}
          >
            Sim
          </Button>
        </div>
      </>
    );
  }

  const fetchAvailablesLoads = useCallback(async (filters = {}) => {
    try {
      setLoading(true);

      const {
        tags = [],
        clients = [],
        negotiators = [],
        costCenters = [],
        pcps = [],
        companies = [],
        page = 1,
        start_date,
        end_date,
      } = filters;

      if (page === 1) {
        setFetching(true);
      }

      setQueryParams(filters);
      setFilter(filters);
      setCurrentPage(+page);

      const response = await dashboardApi.get('/loads-attendeds-free-to-load', {
        params: {
          tags: tags?.map(tag => tag.id),
          clients: clients?.map(client => client.id),
          start_date,
          negotiators: negotiators?.map(negotiator => negotiator?.id),
          costCenters: costCenters?.map(costCenter => costCenter?.id),
          pcps: pcps?.map(pcp => pcp?.id),
          companies: companies?.map(company => company?.id),
          end_date: end_date || undefined,
          page,
        },
      });

      setPagination(response.data?.last?.pagination);

      if (page === 1) {
        setData(response.data);
      } else {
        setData(oldData => ({
          ...oldData,
          last: {
            ...oldData.last,
            data: [...oldData.last.data, ...response.data.last.data],
          },
        }));
      }
    } catch (error) {
      // comment
    } finally {
      setFetching(false);
      setLoading(false);
    }
  }, []);

  return (
    <>
      <Modal
        show={showModal}
        handleClose={() => setShowModal(false)}
        heading={
          <Text type="header" color="dark" weight="500">
            Alterar status
          </Text>
        }
        body={getModalBody()}
      />
      <Card
        title="Viagens liberadas PCP"
        isLoading={isFetching}
        fetchFn={fetchAvailablesLoads}
        onFiltersChanged={fetchAvailablesLoads}
        dashboard={dashboard}
        name={name}
        excludeFilterByBranchOffice={true}
        id={id}
        ListComponent={
          <>
            <Table striped>
              <thead>
                <tr>
                  <th>
                    <Text weight="500" color="dark">
                      Centro de custo
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Empresa
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Tags da carga
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Nº Pedido Cliente
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Viagem
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Data máxima agendada
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Negociador
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Origem
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Destino
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Cliente
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Motorista
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Veículo
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Alterar status
                    </Text>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.last?.data?.map(travel => {
                  const title = [
                    `Cliente ${travel.social_name} - ${travel.cgccpf}`,
                    `Motorista ${travel.driver_name}`,
                    `Placa veículo tração ${formatPlate(travel.plate)}`,
                  ].join('\n');

                  return (
                    <tr>
                      <td>
                        <Text type="regular" color="gray">
                          {travel?.cost_center || '-'}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="gray">
                          {travel?.company_name || '-'}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="gray">
                          {travel?.load_tags || '-'}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="gray">
                          {travel?.client_order_number || '-'}
                        </Text>
                      </td>
                      <td>
                        <Link
                          to={`/viagens/${travel.attended_id}`}
                          title={title}
                          target="_blank"
                        >
                          {travel.attended_id}
                        </Link>
                      </td>
                      <td>
                        <Text type="regular" color="gray">
                          {formatDateTime(travel.scheduled_origin)}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="gray">
                          {travel.user_created}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="dark" truncate>
                          {`${travel?.city_origin} - ${travel.uf_origin}`}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="dark" truncate>
                          {`${travel?.city_destination} - ${travel.uf_destination}`}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="gray" truncate>
                          {travel.social_name}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="gray" truncate>
                          {travel.driver_name}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="gray">
                          {travel.plate}
                        </Text>
                      </td>
                      <td className="text-center">
                        <Button
                          variant="secondary"
                          size="sm"
                          onClick={() => {
                            setCurrentTravel(travel);
                            setShowModal(true);
                          }}
                        >
                          <CgArrowsExchange size={26} />
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {pagination?.lastPage > 1 && pagination?.to < pagination?.total && (
              <div className="text-center mt-3 col-12">
                <Button
                  className="mt-2 ml-auto align-item-center"
                  onClick={() => {
                    fetchAvailablesLoads({
                      page: currentPage + 1,
                      tags: filter?.tags,
                      clients: filter?.clients,
                      start_date: filter?.start_date,
                      end_date: filter?.end_date,
                      costCenters: filter?.costCenters,
                      pcps: filter?.pcps,
                      negotiators: filter?.negotiators,
                      companies: filter?.companies,
                    });
                  }}
                  loading={loading}
                >
                  <Text type="regular" color="white" truncate>
                    Ver mais
                  </Text>
                </Button>
              </div>
            )}
            {!!data?.total && (
              <SeeAll
                to={{
                  pathname: '/viagens',
                  search: queryParams.toString(),
                }}
              >
                ver todas as {data?.total} viagens
              </SeeAll>
            )}
          </>
        }
        NumericComponent={
          <div className="d-flex justify-content-center text-center flex-column">
            <Number color="success">{data?.total}</Number>
            <Description>
              viagens liberadas para carregamento
              {daysFromNow > 1
                ? ` nos últimos ${daysFromNow} dias`
                : ` no último dia`}
            </Description>
          </div>
        }
      />
    </>
  );
}
