import React, { useMemo } from 'react';

import { Card, Input, Select, Text } from 'v3/components';
import { usePreLoad } from '../context';
import { Col, Row } from 'react-bootstrap';

export function VehicleData() {
  const {
    data,
    setData,
    errors,
    vehicleTypesOptions,
    vehicleBodyTypesOptions,
    vehicleImplementTypesOptions,
    loadDispositionOptions,
  } = usePreLoad();

  const shouldDisplayImplement = useMemo(
    () => data?.vehicleTypes?.some(selected => selected.qty_plates > 1),
    [data?.vehicleTypes]
  );

  return (
    <Card
      header={
        <Text color="#464E5F" type="header">
          Dados do Veículo
        </Text>
      }
      bodyCardPadding="1rem 3rem"
    >
      <Row className="form">
        <Col md={6} xs={12} className="mb-3">
          <Select
            label="Tipo de Veículo *"
            options={vehicleTypesOptions}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            value={data?.vehicleTypes}
            onChange={value =>
              setData(old => ({
                ...old,
                vehicleTypes: value,
                vehicleImplements:null,
              }))
            }
            multiple
            error={errors.vehicleTypes}
          />
        </Col>

        <Col md={6} xs={12} className="mb-3">
          <Select
            label="Tipo de carroceria *"
            options={vehicleBodyTypesOptions}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            value={data?.vehicleBodies}
            onChange={value =>
              setData(old => ({ ...old, vehicleBodies: value }))
            }
            multiple
            error={errors.vehicleBodies}
          />
        </Col>

        {shouldDisplayImplement && (
          <Col md={6} xs={12} className="mb-3">
            <Select
              label="Tipo de implemento *"
              options={vehicleImplementTypesOptions}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              value={data?.vehicleImplements}
              onChange={value =>
                setData(old => ({ ...old, vehicleImplements: value }))
              }
              multiple
              error={errors.vehicleImplements}
            />
          </Col>
        )}

        <Col xs={6} className="mb-3">
          <Select
            label="Disposição da carga *"
            options={loadDispositionOptions}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            value={data?.disposition}
            onChange={value => setData(old => ({ ...old, disposition: value }))}
            error={errors.disposition}
          />
        </Col>
        <Col xs={6} className="mb-3">
          <Input
            label="Peso bruto (em kg) *"
            type="number"
            min={1}
            value={data?.weight}
            onChange={({ target }) => {
              if(target?.value?.length > 10){
                return
              }
              setData(old => ({ ...old, weight: target.value }));
            }}
            error={errors.weight}
          />
        </Col>
      </Row>
    </Card>
  );
}
