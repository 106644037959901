import React, { useEffect, useState } from 'react';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

function FilterSelector({ handleFilterOptionSelected, ...props }) {
  const [selected, setSelected] = useState();

  useEffect(() => {
    handleFilterOptionSelected(selected);
  }, [selected]);

  function handleSelected(event, value) {
    setSelected(value);
  }

  return (
    <ToggleButtonGroup
      value={selected}
      onChange={handleSelected}
      size="large"
      exclusive
    >
      <ToggleButton value={1}>todos os clientes</ToggleButton>
      <ToggleButton value={2}>por cliente</ToggleButton>
      <ToggleButton value={3}>por tag</ToggleButton>
    </ToggleButtonGroup>
  );
}

export default FilterSelector;
