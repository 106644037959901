import React, { useState, useMemo } from 'react';

import api from 'services/api';
import { Row, Col } from 'react-bootstrap';
import { Modal, Text, Select, Button } from 'v3/components';
import { useSnackbar } from 'v3/components/Snackbar';

import { useCompany } from '../context';

export default function AddClientModal({ isOpen, onClose, fetchClients }) {
  const company = useCompany();
  const snackbar = useSnackbar();
  const [errors, setErrors] = useState({});
  const [client, setClient] = useState();
  const [isInFlight, setInFlight] = useState(false);

  const isEditing = useMemo(() => !!company.data.id, [company.data.id]);

  async function fetchClientsSearch(search) {
    try {
      const response = await api.get(`v3/company/${company.data.id}/client`, {
        params: { search },
      });

      return response.data;
    } catch (ex) {
      return [];
    }
  }

  function handleClose() {
    setClient(null);

    onClose();
  }

  async function attachClient() {
    try {
      setInFlight(true);

      await api.post(`v3/company/${company.data.id}/client`, {
        clients: client.map(clientAttach => clientAttach.id),
      });

      company.setData({
        clients: [...company.data.clients, ...client],
      });

      fetchClients();

      handleClose();
    } catch (ex) {
      snackbar.show(<Text>Não foi possível vincular esse cliente.</Text>, {
        type: 'error',
      });
    } finally {
      setInFlight(false);
    }
  }

  function addClient() {
    company.setData({
      clients: [...company.data.clients, ...client],
    });

    handleClose(old => ({ ...old, client: '' }));
  }

  function handleAddClient() {
    let hasError = false;
    client.forEach(clientValidate => {
      if (company.data.clients.some(item => item.id === clientValidate.id)) {
        setErrors(old => ({ ...old, client: 'Cliente já cadastrado' }));
        hasError = true;
      }
    });

    if (hasError) return;

    setErrors();
    if (isEditing) {
      attachClient();
    } else {
      addClient();
    }
  }

  return (
    <Modal
      show={isOpen}
      handleClose={handleClose}
      heading={
        <Text type="header" color="dark">
          Vincular cliente
        </Text>
      }
      body={
        <Row>
          <Col className="align-items-center d-flex flex-column justify-content-center">
            <Select.Async
              label="Cliente"
              placeholder="CPF / CNPJ / Razão social"
              onSearch={fetchClientsSearch}
              value={client}
              onChange={value => setClient(value)}
              getOptionLabel={option =>
                `${option.social_name} - ${option.cgccpf}`
              }
              getOptionValue={option => option.id}
              error={errors?.client}
              multiple
            />

            <Button
              loading={isInFlight}
              disabled={!client}
              onClick={handleAddClient}
              className="my-4 py-2"
            >
              <Text type="regular" weight="500" color="white">
                Adicionar
              </Text>
            </Button>
          </Col>
        </Row>
      }
    />
  );
}
