import api from 'services/api';

export async function isAvailable(loadId) {
  try {
    const response = await api.get(
      `integrations/truckpad/load/${loadId}/available`
    );

    return response.data.isAvailable;
  } catch (ex) {
    return false;
  }
}

export async function send(id) {
  try {
    await api.post(`integrations/truckpad/load/${id}`);
    return {
      error: false,
      message: 'Carga enviada para Truckpad com sucesso.',
    };
  } catch (err) {
    return {
      error: true,
      message: 'Erro ao enviar carga para Truckpad.',
    };
  }
}

export async function cancel(id) {
  try {
    await api.post(`integrations/truckpad/load/${id}/cancel`);

    return {
      error: false,
      message: 'Carga cancelada na Truckpad com sucesso.',
    };
  } catch (err) {
    let message = 'Não foi possível cancelar a carga na Truckpad.';
    if (err.response) {
      const { data } = err.response;
      if (data.exception) {
        message = data.exception.message;
      } else if (data.error) {
        message = data.error;
      }
    }

    return { error: true, message };
  }
}
