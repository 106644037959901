import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Select from 'react-select';
import FileSaver from 'file-saver';
import { validatePermission } from 'actions/index';
import { usePermission } from 'hooks';
import api from '../../../services/api';
import Card from '../../../components/Card';
import Label from '../../../components/Label';
import Button from '../../../components/Button';

const months = [
  { name: 'Janeiro', value: 1 },
  { name: 'Fevereiro', value: 2 },
  { name: 'Março', value: 3 },
  { name: 'Abril', value: 4 },
  { name: 'Maio', value: 5 },
  { name: 'Junho', value: 6 },
  { name: 'Julho', value: 7 },
  { name: 'Agosto', value: 8 },
  { name: 'Setembro', value: 9 },
  { name: 'Outubro', value: 10 },
  { name: 'Novembro', value: 11 },
  { name: 'Dezembro', value: 12 },
];

export default function DriverJourney() {
  const today = new Date();

  const [companies, setCompanies] = useState({ loading: true, data: [] });

  const [company, setCompany] = useState(null);
  const [year, setYear] = useState(today.getFullYear());
  const [month, setMonth] = useState(today.getMonth() + 1);

  const [generating, setGenerating] = useState(false);

  const [
    hasPermissionToViewAllCompanies,
    setPermissionToViewAllCompanies,
  ] = useState();

  usePermission('VISUALIZAR_RELATORIO_JORNADA_TRABALHO', { redirect: true });

  useState(() => {
    setPermissionToViewAllCompanies(
      validatePermission('VISUALIZAR_TODAS_AS_EMPRESAS')
    );
  }, []);

  async function getUserCompany() {
    const { company_id } = JSON.parse(localStorage.getItem('usuario'));
    const response = await api.get(`company/${company_id}`);
    setCompany(response.data);
  }

  useState(() => {
    if (!hasPermissionToViewAllCompanies) {
      getUserCompany();
    }
  }, []);

  async function fetchCompanies() {
    setCompanies(oldCompanies => ({ ...oldCompanies, loading: true }));
    try {
      const response = await api.get('company');
      setCompanies(oldCompanies => ({ ...oldCompanies, data: response.data }));
    } catch (ex) {
      // Handle exception
    } finally {
      setCompanies(oldCompanies => ({ ...oldCompanies, loading: false }));
    }
  }

  useEffect(() => {
    fetchCompanies();
  }, []);

  async function exportReportAsTxt() {
    try {
      const response = await api.get('driver/journey/report/txt', {
        params: { company_id: company.id, month, year },
        responseType: 'blob',
      });

      const fileName = 'jornada-de-trabalho.txt';
      FileSaver.saveAs(response.data, fileName);
    } catch (ex) {
      // Handle exception
    }
  }

  async function exportReportAsCSV() {
    setGenerating(true);

    try {
      const response = await api.get('driver/journey/report/csv', {
        params: { company_id: company.id, month, year },
        responseType: 'blob',
      });

      const fileName = 'jornada-de-trabalho.csv';
      FileSaver.saveAs(response.data, fileName);
    } catch (ex) {
      // Handle exception
    } finally {
      setGenerating(false);
    }
  }

  return (
    <Grid>
      <Card
        style={{ marginTop: '10px', marginBottom: '10px', overflow: 'visible' }}
      >
        <Grid container spacing={2}>
          {hasPermissionToViewAllCompanies && (
            <Grid item lg={10} md={10} xs={12}>
              <Label className="textLabel">Selecione uma empresa</Label>
              <Select
                options={companies.data}
                placeholder="Selecione"
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                value={company}
                onChange={value => setCompany(value)}
                loading={companies.loading}
              />
            </Grid>
          )}

          <Grid item lg={5} md={5} xs={12}>
            <Label className="textLabel">Selecione um ano</Label>

            <ToggleButtonGroup
              value={year}
              exclusive
              onChange={(_, value) => setYear(value)}
            >
              <ToggleButton value={2020}>2020</ToggleButton>
              <ToggleButton value={2021}>2021</ToggleButton>
            </ToggleButtonGroup>
          </Grid>

          <Grid item lg={10} md={10} xs={12}>
            <Label className="textLabel">Selecione um mês</Label>

            <ToggleButtonGroup
              value={month}
              exclusive
              onChange={(_, value) => setMonth(value)}
              className="toogleExtratoCobrancas"
            >
              {months.map(_month => (
                <ToggleButton key={_month.name} value={_month.value}>
                  {_month.name}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={12} style={{ marginTop: '15px' }}>
            <Button
              onClick={exportReportAsCSV}
              loading={generating}
              style={{ marginRight: '5px' }}
            >
              Gerar
            </Button>
            <Button onClick={exportReportAsTxt} style={{ marginRight: '5px' }}>
              Exportar
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
}
