/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import {
  fetchClients,
  fetchVehiclesTypes,
  fetchProductAndCategorie,
  fetchTags,
  fetchCities,
} from 'utils/fetches';

import { Text, Button, Select } from 'v3/components';
import ModalRaw from 'v3/components/Modal';

import theme from 'v3/theme';
import { useRoute } from '../../context';

export default function Modal({ isOpen, onClose }) {
  const route = useRoute();

  const [open, setOpen] = useState(false);
  const [vehicleTypes, setVehicleTypes] = useState([]);

  const handleClose = () => setOpen(false);

  useEffect(() => {
    async function fetchInitialData() {
      const response = await fetchVehiclesTypes();
      setVehicleTypes(response);
    }

    fetchInitialData();
  }, []);

  return (
    <>
      <ModalRaw
        size="lg"
        show={isOpen || open}
        handleClose={onClose || handleClose}
        heading={
          <Text color="dark" type="header" weight="semiBold">
            Filtro avançado
          </Text>
        }
        body={
          <>
            <div className="row">
              <div className="col-6 mb-3">
                <Select.Async
                  label="Cliente"
                  placeholder=""
                  onSearch={fetchClients}
                  value={route.filter.clients}
                  onChange={value =>
                    route.setFilter(old => ({ ...old, clients: value }))
                  }
                  getOptionLabel={option =>
                    `${option.social_name} - ${option.cgccpf}`
                  }
                  getOptionValue={option => option.id}
                  multiple
                />
              </div>
              <div className="col-6 mb-3">
                <Select
                  label="Tipo de veículo"
                  multiple
                  placeholder=""
                  options={vehicleTypes}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  value={route.filter.vehicles}
                  onChange={value =>
                    route.setFilter(old => ({ ...old, vehicles: value }))
                  }
                />
              </div>
              <div className="col-6 mb-3">
                <Select.Async
                  label="Origem"
                  placeholder=""
                  onSearch={fetchCities}
                  value={route.filter.origin}
                  onChange={value =>
                    route.setFilter(old => ({ ...old, origin: value }))
                  }
                  getOptionLabel={option =>
                    `${option.name}, ${option.province?.uf}`
                  }
                  getOptionValue={option => option.id}
                />
              </div>
              <div className="col-6 mb-3">
                <Select.Async
                  label="Destino"
                  placeholder=""
                  onSearch={fetchCities}
                  value={route.filter.destination}
                  onChange={value =>
                    route.setFilter(old => ({ ...old, destination: value }))
                  }
                  getOptionLabel={option =>
                    `${option.name}, ${option.province?.uf}`
                  }
                  getOptionValue={option => option.id}
                />
              </div>
              <div className="col-6 mb-3">
                <Select.Async
                  label="Produto"
                  placeholder=""
                  onSearch={fetchProductAndCategorie}
                  value={route.filter.products}
                  onChange={value =>
                    route.setFilter(old => ({ ...old, products: value }))
                  }
                  getOptionLabel={option => `${option.name}`}
                  getOptionValue={option => option.id}
                  multiple
                />
              </div>
              <div className="col-6 mb-3">
                <Select.Async
                  label="Tags"
                  placeholder=""
                  onSearch={fetchTags}
                  value={route.filter.tags}
                  onChange={value =>
                    route.setFilter(old => ({ ...old, tags: value }))
                  }
                  getOptionLabel={option => `${option.name}`}
                  getOptionValue={option => option.id}
                  multiple
                />
              </div>
            </div>
            <div className="mt-3 text-center">
              <Text weight="500" type="regular" color="dark">
                Valor até
              </Text>
            </div>

            <div className="mb-5 row align-items-center justify-content-center">
              <Slider
                dotStyle={{
                  borderColor: theme.colors.primary,
                  background: theme.colors.primary,
                  borderRadius: '0%',
                  width: '1px',
                  height: '8px',
                }}
                trackStyle={{
                  background: theme.colors.secondary,
                }}
                activeDotStyle={{
                  borderColor: theme.colors.secondary,
                  background: theme.colors.secondary,
                }}
                value={route.filter.range}
                onChange={value => {
                  route.setFilter(old => ({ ...old, range: value }));
                }}
                min={0}
                defaultValue={5}
                marks={{
                  0: 'R$500',
                  10: 'R$1000',
                  20: 'R$2000',
                  30: 'R$3000',
                  40: 'R$4000',
                  50: 'R$5000',
                  60: 'R$6000',
                  70: 'R$7000',
                  80: 'R$8000',
                  90: 'R$9000',
                  100: `R$9000+`,
                }}
                step={null}
              />
            </div>
          </>
        }
        footer={
          <div>
            <Button
              variant="white"
              className="py-2 mr-2 mt-1"
              outlined
              onClick={() => {
                route.setFilter({});
              }}
            >
              <Text weight="500" type="label" color="dark">
                Limpar filtros
              </Text>
            </Button>

            <Button
              className="py-2 mr-n2 mt-1"
              variant="primary"
              onClick={() => {
                route.fetchData();
                onClose();
              }}
            >
              <Text weight="500" type="label" color="white">
                Filtrar
              </Text>
            </Button>
          </div>
        }
      />
    </>
  );
}
