import styled from 'styled-components';

import theme from 'v3/theme';

export const Container = styled.div`
  display: flex;
  text-align: center;
  border-top: 1px solid ${theme.colors.secondary};
  padding-top: 1rem;
  padding-bottom: 1rem;
  cursor: pointer;
  transition: 0.5s ease;
  background-attachment: #f9f9f9;
  width: 100%;
  &:hover {
    background-color: #f7f7f7;
  }
`;