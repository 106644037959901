import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { cooperplaceApi } from 'services/api';
import { Col } from 'react-bootstrap';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import CardRaw from 'v3/components/Card';
import { useSnackbar } from 'v3/components/Snackbar';
import { Tooltip } from 'v3/components';
import { AggregatedVehiclesCard } from './AggregatedVehiclesCard';
import { VehicleModal } from './VehicleModal';
import Space from 'v3/components/Space';
import { validatePermission } from 'actions';

export function ListAggregatedVehicles() {
  const params = useParams();
  const history = useHistory();
  const snackbar = useSnackbar();
  const [aggregationVehicles, setAggregationVehicles] = useState([]);
  const [show, setShow] = useState(false);
  const [currentVehicle, setCurrentVehicle] = useState();
  const hasPermissionToVisualize = validatePermission(
    'VISUALIZAR_AGREGAMENTOS'
  );

  useEffect(() => {
    if (!hasPermissionToVisualize) {
      snackbar.show('Você não tem permissão para visualizar veículos', {
        type: 'error',
      });
      history.push('/');
    }
  }, [hasPermissionToVisualize]);

  const [hasPermissionToEdit, setHasPermissionToEdit] = useState(
    validatePermission('EDITAR_AGREGAMENTOS')
  );

  async function getAggregatedVehicles() {
    try {
      const response = await cooperplaceApi.get(
        `aggregation?aggregation_offer_id=${params.id}`
      );
      setAggregationVehicles(response.data);
    } catch (error) {
      snackbar.show(
        <Text>
          Não foi possível recuperar veículos. Tente novamente mais tarde.
        </Text>,
        { type: 'error' }
      );
    }
  }
  useEffect(() => {
    getAggregatedVehicles();
  }, []);
  async function toggleStatus(vehicle) {
    const data = {
      active: !vehicle.active,
    };
    try {
      await cooperplaceApi.put(`aggregation/${vehicle.id}`, data);
      getAggregatedVehicles();
    } catch (error) {
      snackbar.show(
        <Text>
          Não foi possível editar veículo. Tente novamente mais tarde.
        </Text>,
        { type: 'error' }
      );
    }
  }
  return (
    <>
      <Col xs={12}>
        <CardRaw
          header={
            <div>
              <Tooltip
                content={
                  <Text type="label">
                    {aggregationVehicles?.[0]?.aggregation_offer_id
                      ? `Veículos do  agregamento  ${aggregationVehicles?.[0]?.aggregation_offer_id}`
                      : 'Agregamento não possui veículos vinculados'}
                  </Text>
                }
                placement="top"
              >
                <Text transform="uppercase" color="dark" type="regular">
                  {' '}
                  {aggregationVehicles?.[0]?.aggregation_offer_id
                    ? `Veículos do  agregamento  ${aggregationVehicles?.[0]?.aggregation_offer_id}`
                    : 'Agregamento não possui veículos vinculados'}
                </Text>
              </Tooltip>
            </div>
          }
          HeaderRightComponent={
            <Space x="5px">
              <Button
                className="py-2"
                onClick={() => history.goBack()}
                variant="secondary"
              >
                <Text weight={500} type="regular">
                  Voltar
                </Text>
              </Button>
              {hasPermissionToEdit && (
                <Button
                  className="py-2"
                  onClick={() => setShow(true)}
                  variant="secondary"
                >
                  <Text weight={500} type="regular">
                    Adicionar veículo
                  </Text>
                </Button>
              )}
            </Space>
          }
        >
          {aggregationVehicles &&
            aggregationVehicles.map(aggregation => {
              return (
                <AggregatedVehiclesCard
                  key={aggregation?.id}
                  aggregation={aggregation}
                  onChangeStatus={toggleStatus}
                />
              );
            })}
        </CardRaw>
      </Col>
      <VehicleModal
        currentVehicle={currentVehicle}
        setCurrentVehicle={setCurrentVehicle}
        aggregationVehicles={aggregationVehicles}
        getAggregatedVehicles={getAggregatedVehicles}
        isOpen={show}
        onClose={() => {
          if (currentVehicle) {
            setAggregationVehicles([...aggregationVehicles, currentVehicle]);
            setCurrentVehicle(null);
          }

          setShow(false);
        }}
        setShow={setShow}
      />
    </>
  );
}
