import React from 'react';

import Space from 'v3/components/Space';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import { useRoute } from './context';

export default function Editable({ isEditing, setEditing, onSave, isLoading }) {
  const route = useRoute();

  function handleCancel() {
    setEditing(false);
    route.setErrors({});
    route.handleCancel();
  }

  if (isEditing) {
    return (
      <Space x="5px">
        <Button variant="secondary" onClick={handleCancel}>
          <Text weight={500} type="regular">
            Cancelar
          </Text>
        </Button>
        <Button variant="success" onClick={onSave} loading={isLoading}>
          <Text weight={500} type="regular">
            Salvar
          </Text>
        </Button>
      </Space>
    );
  }

  return (
    <Button
      variant="secondary"
      onClick={() => {
        route.updatePrevRoute();
        setEditing(true);
      }}
    >
      <Text weight={500} type="regular">
        Editar
      </Text>
    </Button>
  );
}
