/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';

import api from 'services/api';
import { FaTrash } from 'react-icons/fa';
import { useSnackbar } from 'v3/components/Snackbar';
import Text from 'v3/components/Text';
import Table from 'v3/components/Table';
import Button from 'v3/components/Button';
import Tooltip from 'v3/components/Tooltip';

import { formatDate, formatDateTime } from 'utils/formatter';

import * as Styled from './styles';

const headers = [
  'Gerenciadora de risco',
  'Origem / Destino',
  'Código',
  'Data da consulta',
  'Validade',
  'Ação',
];

export default function RiskManager({ riskManagers, setRiskManagers }) {
  const [loading, setLoading] = useState(false);

  const snackbar = useSnackbar();

  async function handleDeleteRiskManager(riskManager) {
    if (riskManager.payload_received !== (null || undefined)) {
      snackbar.show(<Text>Não é possível deletar consultas</Text>, {
        type: 'error',
      });
      return;
    }
    try {
      setLoading(true);
      await api.delete(`driver/risk-manager/${riskManager.id}`);
      setRiskManagers(riskManagers.filter(rm => rm.id !== riskManager.id));
    } catch (error) {
      snackbar.show(<Text>Erro ao deletar gerenciadora de risco</Text>, {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Table striped>
      <thead>
        <tr>
          {headers.map(header => (
            <th key={header}>
              <Text type="label" color="#464E5F">
                {header}
              </Text>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {riskManagers.map(riskManager => (
          <tr>
            <td>
              {riskManager.status === '1' ? (
                <Tooltip content={<Text>Aprovado</Text>}>
                  <Styled.ShieldIcon />
                </Tooltip>
              ) : (
                <Tooltip content={<Text>Reprovado</Text>}>
                  <Styled.BanIcon />
                </Tooltip>
              )}
              <Text weight={500} type="regular" color="#494950">
                {riskManager.name.label}
              </Text>
            </td>
            <td>
              <Text weight={500} type="regular" color="#494950">
                -
              </Text>
            </td>
            <td>
              <Text weight={500} type="regular" color="#494950">
                {riskManager.risk_manager_code
                  ? riskManager.risk_manager_code
                  : '-'}
              </Text>
            </td>
            <td>
              <Text weight={500} type="regular" color="#494950">
                {formatDateTime(riskManager.created_at)}
              </Text>
            </td>
            <td>
              <Text weight={500} type="regular" color="#494950">
                {formatDate(riskManager.due_date)}
              </Text>
            </td>
            <td>
              <Button
                loading={loading}
                variant="error"
                size="sm"
                onClick={() => handleDeleteRiskManager(riskManager)}
              >
                <FaTrash />
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
