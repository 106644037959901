export function plateValidator(plate) {
  const regexOldPlate = /^[a-zA-Z]{3}[0-9]{3,4}$/;
  const regexNewPlate = /^[a-zA-Z]{3}[0-9]{1}[a-jA-J]{1}[0-9]{2}$/;
  const regexChileOldPlate = /^[a-zA-Z]{2}[0-9]{4}$/;
  const regexChileNewPlate = /^[a-zA-Z]{4}[0-9]{2}/;
  if (
    regexOldPlate.test(plate) ||
    regexNewPlate.test(plate) ||
    regexChileNewPlate.test(plate) ||
    regexChileOldPlate.test(plate)
  ) {
    return true;
  }
  return false;
}
