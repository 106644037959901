import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { validatePermission } from 'actions/index';

import ApplicationLayout from 'v3/layouts/Application';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import Space from 'v3/components/Space';

import { Section } from '../shared/Section';
import { Data } from '../shared/Data';

import { useImplement } from '../shared/context';

import { handleCreation } from './controller';
import { OwnerData } from '../shared/Owner';
import { usePermission } from 'hooks';

export function ImplementRegister() {
  const history = useHistory();
  const implement = useImplement();

  const [loading, setLoading] = useState(false);

  usePermission('CADASTRAR_IMPLEMENTO', {
    redirect: true,
  });

  return (
    <ApplicationLayout
      title="Cadastrar implemento"
      RightComponent={
        <Space x={'0.5rem'}>
          <Button
            variant="secondary"
            onClick={() => history.push('/implementos')}
          >
            <Text color="dark" weight={500}>
              Voltar
            </Text>
          </Button>
          <Button
            variant="primary"
            loading={loading}
            onClick={() =>
              handleCreation(
                implement.data,
                implement.setData,
                implement.setErrors,
                implement.showSnackbar,
                setLoading,
                history
              )
            }
          >
            <Text color="white" weight={500}>
              Salvar
            </Text>
          </Button>
        </Space>
      }
    >
      <Row>
        <Col xs={12} md={6}>
          <Section Title="Dados do implemento">
            <Data />
          </Section>
        </Col>
        <Col xs={12} md={6}>
          <Section Title="Proprietário">
            <OwnerData />
          </Section>
        </Col>
      </Row>
    </ApplicationLayout>
  );
}
