/* eslint-disable import/no-cycle */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import Map from 'v3/components/Map';
import Text from 'v3/components/Text';
import Card from 'v3/components/Card';
import SwitchOption from 'v3/components/SwitchOption';

import Modal from 'v3/components/Modal';
import Button from 'v3/components/Button';
import Select from 'v3/components/Select';
import Toggle from 'v3/components/Toggle';
import Prompt from 'v3/components/Prompt';
import Marker from 'v3/components/Map/Marker';
import DatePicker from 'v3/components/DatePicker';
import { useSnackbar } from 'v3/components/Snackbar';
import ApplicationLayout from 'v3/layouts/Application';
import CardChecklist from 'components/CardChecklist';
import Radio from 'v3/components/Radio';
import TextArea from 'v3/components/TextArea';
import Accordion from 'v3/components/Accordion';
import DataTable from 'v3/components/DataTable';

import api from '../../../services/api';

import Tabs from './Tabs';
import Table from './Table';
import Badges from './Badges';
import Buttons from './Buttons';
import BulletList from './BulletList';

function Dev() {
  const history = useHistory();
  const [select, setSelect] = useState();
  const [selectII, setSelectII] = useState();
  const [selectAsync, setSelectAsync] = useState();
  const [switchActive, setSwitchActive] = useState(true);
  const [checklist, setChecklist] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const snackbar = useSnackbar();

  const [showPrompt, setShowPrompt] = useState(false);
  const handleClosePrompt = () => setShowPrompt(false);
  const handleShowPrompt = () => setShowPrompt(true);

  async function checklistPrint() {
    const res = await api.get('load/checklist/97281');
    setChecklist(res.data);
  }

  useEffect(() => {
    checklistPrint();
  }, []);
  return (
    <>
      <ApplicationLayout
        title="Título customizável"
        RightComponent={
          <Button variant="info" onClick={handleShowPrompt}>
            <Text>Salvar</Text>
          </Button>
        }
      />
      <Container style={{ display: 'block' }}>
        <Card
          header="Card"
          HeaderRightComponent={
            <Button
              onClick={() =>
                snackbar.show(<Text>Snackbar top demais</Text>, {
                  type: 'success',
                  // tipos: success, error, warning e o default é primary
                })
              }
            >
              <Text>Mostrar Snackbar!</Text>
            </Button>
          }
        >
          <Text color="green">exemplo de tabela</Text>
          <Table />
          <Button>
            <Text>Editar</Text>
          </Button>
        </Card>
        <DatePicker onChange={({ target }) => console.log(target.value)} />
        <Row md={12} className="d-flex flex-column align-items-start ml-1">
          <Text color="green">Exemplos de como usar o component Text</Text>
          <Text color="#752196c1">Coopertech</Text>
          <Text color="primary">Coopertech</Text>
          <Text color="primary">Coopertech</Text>
          <Text color="primary">Coopertech</Text>
          <Text color="secondary">Coopertech</Text>
          <Text weight="200">Coopertech</Text>
          <Text weight="900">Coopertech</Text>
          <Text type="subtitle" transform="uppercase">
            Coopertech
          </Text>
          <Text type="title" transform="lowercase">
            Coopertech
          </Text>
          <Text type="little">Coopertech</Text>
        </Row>
        <div style={{ width: 600 }}>
          <Card
            header="Exemplo de bullet list"
            HeaderRightComponent={
              <Button>
                <Text>Editar</Text>
              </Button>
            }
          >
            <BulletList />
          </Card>
        </div>
        <Text type="little">Componente Map com marker</Text>
        <Map
          height={300}
          width={600}
          position={{ lat: -25.4815598, lng: -49.2516467 }}
        >
          <Marker
            background="#0bb7af"
            lat={-25.482025}
            lng={-49.250595}
            color="#fff"
            text="1 de junho de 2020 às 16:00hrs"
          />
        </Map>
        <Text type="little">Componente Map sem marker</Text>
        <Map
          height={300}
          width={600}
          position={{ lat: -25.4815598, lng: -49.2516467 }}
        />
        <Text as="h1" color="#752196c1" type="title" transform="lowercase">
          Coopertech
        </Text>
        <Text type="little">Coopertech</Text>
        <Text as="h1" color="#752196c1" type="title" transform="lowercase">
          Coopertech
        </Text>
        <Text type="little">Coopertech</Text>
        <Text as="h1" color="#752196c1" type="body" transform="lowercase">
          props `as` juntamente com uma tag html para deixar o codigo mais
          semantico
        </Text>

        <Text as="h1" color="#752196c1" type="body" transform="lowercase">
          props `as` juntamente com uma tag html para deixar o codigo mais
          semantico
        </Text>
        <Row>
          <Col>
            <Select
              label="Jogador"
              value={select}
              onChange={value => setSelect(value)}
              options={[
                { label: 'Ronaldo' },
                { label: 'Cafu' },
                { label: 'Roberto Carlos' },
              ]}
              getOptionValue={option => option.label}
            />
          </Col>
          <Col>
            <Select
              label="Jogador"
              value={selectII}
              onChange={value => setSelectII(value)}
              multiple
              options={[
                { name: 'Ronaldo', number: 9 },
                { name: 'Cafu', number: 2 },
                { name: 'Roberto Carlos', number: 3 },
              ]}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.number}
            />
          </Col>
          <Col>
            <Select.Async
              label="Tracker"
              value={selectAsync}
              onChange={value => setSelectAsync(value)}
              onSearch={async input => {
                const response = await api.get('select/trackers', {
                  params: { search: input },
                });
                return response.data;
              }}
            />
          </Col>
        </Row>
        <Tabs />
        <Buttons />
        <Badges />
        <Toggle
          buttonSize="sm"
          defaultValue={1}
          options={[
            { label: '10 dias', value: 1 },
            { label: '15 dias', value: 2 },
            { label: '30 dias', value: 3 },
          ]}
        />

        <Accordion
          title="Automações"
          closedText="Ver automações"
          closeButtonText="Fechar"
        >
          <Text>Teste</Text>
        </Accordion>
        <Button onClick={handleShow} className="mb-1">
          launch modalzera
        </Button>
        <Modal
          show={show}
          onHide={handleClose}
          heading="Modal modalzera"
          body="Woohoo, you're reading this text in a modal!"
          footer={
            <>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleClose}>
                Save Changes
              </Button>
            </>
          }
        />

        <SwitchOption
          value={switchActive}
          onClick={() => setSwitchActive(old => !old)}
        />

        <Prompt
          show={showPrompt}
          onHide={handleClosePrompt}
          title="Carga(s) cadastrada(s) com sucesso!"
          actions={[
            {
              label: 'Ver cargas',
              action: () => history.push('/cargas?0'),
              type: 'success',
            },
            {
              label: 'Adicionar Carga',
              action: () => {
                window.location.reload();
              },
              type: 'primary',
            },
          ]}
          body={
            <Text>
              E agora? Deseja ir para listagem de cargas ou cadastrar um nova
              carga?
            </Text>
          }
        />
        <TextArea label="Teste do Otávio" resizable={false} />
        <CardChecklist
          title="Checklist"
          closedText="Ver Checklist"
          closeButtonText="Fechar"
        >
          <Row>
            <Col xs={12}>
              {checklist &&
                checklist.map(item => {
                  const itens = JSON.parse(item.answers);
                  return Object.entries(itens).map(([key]) => (
                    <Radio isChecked>
                      <Text>{key}</Text>
                    </Radio>
                  ));
                })}
            </Col>
            <Col xs={12}>
              <Text>Observação: {checklist && checklist[0]?.observation}</Text>
            </Col>
            <Col>
              <Text> Criado em: {checklist && checklist[0]?.created_at} </Text>
            </Col>
          </Row>
        </CardChecklist>
        <DataTable
          data={[
            {
              social_name: 'Francisco e Thomas Transportes ME',
              cgccpf: '11.684.996/0001-18',
            },
            {
              social_name: 'Bryan e Tiago Marketing ME',
              cgccpf: '67.865.155/0001-61',
            },
            {
              social_name: 'Francisco e Thomas Transportes ME',
              cgccpf: '11.684.996/0001-18',
            },
            {
              social_name: 'Bryan e Tiago Marketing ME',
              cgccpf: '67.865.155/0001-61',
            },
            {
              social_name: 'Francisco e Thomas Transportes ME',
              cgccpf: '11.684.996/0001-18',
            },
            {
              social_name: 'Bryan e Tiago Marketing ME',
              cgccpf: '67.865.155/0001-61',
            },
          ]}
          total={6}
          pages={2}
          onPageChanged={page => console.log(page)}
          onSearchChanged={search => console.log(search)}
          onPerPageChanged={perPage => console.log(perPage)}
          loading={false}
          searchPlaceholder="CNPJ ou Razão social"
          columns={[
            { name: 'Cliente', selector: row => row.social_name },
            { name: 'CNPJ', selector: row => row.cgccpf },
            {
              name: 'Ação',
              render: row => (
                <Button
                  variant="error"
                  size="sm"
                  className="mx-1"
                  onClick={() => console.log(row)}
                >
                  <Text>O</Text>
                </Button>
              ),
            },
          ]}
        />
      </Container>
    </>
  );
}

export default Dev;
