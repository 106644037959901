import { cpf, cnpj } from 'cpf-cnpj-validator';

export function checkIfCPFIsValid(document) {
  const valid = cpf.isValid(document);
  return valid;
}

export function checkIfCNPJIsValid(document) {
  const valid = cnpj.isValid(document);
  return valid;
}
