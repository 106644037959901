/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */

import React, { useState } from 'react';

import { Col, Row } from 'react-bootstrap';

import { cooperplaceApi } from 'services/api';

import Modal from 'v3/components/Modal';
import Select from 'v3/components/Select';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';

import { useUser } from '../context';

function AddBeneficiary({ isOpen, onClose }) {
  const user = useUser();
  const [beneficiary, setBeneficiary] = useState();
  const [loading, setLoading] = useState(false);

  const fetchBeneficiary = async (search) => {
    try {
      const response = await cooperplaceApi.get('persons/select/beneficiary', {
        params: {
          search: search
        }
      })
      return response.data
    } catch (err) {
      return []
    }
  }

  const submit = async () => {
    try {
      setLoading(true)
      await cooperplaceApi.put(`user/beneficiary/${user.data.id}`, {
        beneficiaryId: beneficiary.id,
      });
      user.setData({
        beneficiary: [
          ...user.data.beneficiary,
          {
            id: beneficiary.id,
            social_name: beneficiary.socialName,
            cgccpf: beneficiary.cpfCnpj
          }],
      });
      onClose();

    } catch (error) {
      const { data } = error.response;
      let message = data?.error || 'Ocorreu um erro ao cadastrar o beneficiário.';

      user.showSnackbar(message, { type: 'error' })
    } finally {
      setLoading(false)
    }
  }

  const handleAdd = () => {
    if (user.data.id) {
      submit()
    } else {
      user.setData({
        beneficiary: [
          ...user.data.beneficiary,
          {
            id: beneficiary.id,
            social_name: beneficiary.socialName,
            cgccpf: beneficiary.cpfCnpj
          }],
      });
      setBeneficiary(null)
      onClose();
    }
  }

  return (
    <>
      <Modal
        show={isOpen}
        onHide={onClose}
        size='lg'
        heading={
          <Text color="dark" type="header">
            Beneficiário
          </Text>
        }
        body={
          <Row>
            <Col className="d-flex flex-column align-items-center">
              <Select.Async
                className="mt-3"
                label={
                  <Text type="label" weight="500">
                    Beneficiário
                  </Text>
                }
                onSearch={search => fetchBeneficiary(search)}
                value={beneficiary}
                getOptionLabel={option => option.label}
                getOptionValue={option => option.id}
                onChange={value => {
                  if (value) {
                    setBeneficiary(value)
                  } else {
                    setBeneficiary(null)
                  }
                }}
              />
              <Button
                onClick={handleAdd}
                className="my-2"
                disabled={!beneficiary}
                loading={loading}
              >
                <Text type="regular" weight="500" color="white">
                  Adicionar
                </Text>
              </Button>
            </Col>
          </Row>
        }
      />
    </>
  );
}

export default AddBeneficiary;
