import React from 'react';

import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Text from 'v3/components/Text';
import Card from 'v3/components/Card';
import { formatDateTime } from 'v3/utils/formatter';

function LoadsAttendedCard({ loadAttended }) {
  return (
    <Col xs={6}>
      <Card>
        <Row>
          <Col xs={7} sm={7} className="load-list-align mb-2 mt-2">
            <Link to={`/cargas/${loadAttended?.load}`}>
              <Text type="regular">
                Carga: {loadAttended?.load || ''}
              </Text>
            </Link>
          </Col>
          <Col xs={5} sm={5} className="load-list-align mb-2 mt-2">
            <Link to={`/viagens/${loadAttended?.travel}`}>
              <Text type="regular">
                Viagem: {loadAttended?.travel}
              </Text>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} className="load-list-align mb-2 mt-2">
            <Text type="regular">
              Data: {formatDateTime(loadAttended.created_at)}
            </Text>
          </Col >
          <Col xs={12} sm={12} className="load-list-align mb-2 mt-2">
            <Text type="regular">
              ID Externo: {loadAttended?.id_externo}
            </Text>
          </Col >
          <Col xs={12} sm={12} className="load-list-align mb-2 mt-2">
            <Text type="regular">
              Atendente: {loadAttended?.attendant}
            </Text>
          </Col>
          <Col xs={12} sm={12} className="load-list-align mb-2 mt-2">
            <Text type="regular">
              Cliente: {loadAttended?.client_cnpj} - {loadAttended?.client}
            </Text>
          </Col >
        </Row >
      </Card >
    </Col >
  );
}

export default LoadsAttendedCard;