import api, { cooperplaceApi } from 'services/api';
import moment from 'moment';

export async function save(location) {
  try {
    const scheduled_time = moment(location.scheduledTime, 'DD/MM/YYYY HH:mm');
    const start_schedule = moment(location.startSchedule, 'DD/MM/YYYY HH:mm');
    const clientId = location?.client?.id || location?.clientId || null;
    delete location?.client;

    const reassign_location = {
      ...location,
      city: location.cityModel ? location.cityModel.name : location.cityModel,
      cityId: location.cityModel?.id || null,
      province: location.province,
      clientId,
      action: location.action.id,
      country_slug: location.country.country_slug,
      scheduledTime: moment.utc(scheduled_time),
      startSchedule: moment.utc(start_schedule),
    };

    if (reassign_location.type === 'origin') delete reassign_location.action;

    delete reassign_location.status;

    delete reassign_location.formatted;
    delete reassign_location.country;
    delete reassign_location.translatedAction;
    delete reassign_location.cityModel;

    let response;
    if (reassign_location.type === 'origin') {
      delete reassign_location.type;
      response = await cooperplaceApi.put(
        `loads/${location?.loadId}/origin`,
        reassign_location
      );
    } else {
      delete reassign_location.type;
      response = await cooperplaceApi.put(
        `loads/${location?.loadId}/destinations/${location.id}`,
        reassign_location
      );
    }

    return {
      success: `local de ${
        location.type === 'origin' ? 'Origem' : 'Destino'
      } atualizado`,
      data: response.data,
    };
  } catch (err) {
    return { error: 'Não foi possível atualizar local' };
  }
}

export function deleteDestination(destination_id) {
  return api.delete(`v3/load/removeDestination/${destination_id}`);
}

function validateOriginDate(scheduled_time, destinations) {
  const invalid_date = destinations.some(item => {
    const date = moment(item.scheduledTime, 'DD/MM/YYYY HH:mm');

    return moment(date).isBefore(scheduled_time);
  });
  const is_retroactive_date = moment(scheduled_time).isBefore(
    moment(new Date(), 'DD/MM/YYYY HH:mm')
  );

  return is_retroactive_date
    ? {
        dateError:
          'Data máxima da coleta informada não pode ser anterior ao dia/horário atual.',
      }
    : invalid_date
    ? {
        dateError: 'Data de origem da coleta maior que alguma data de destino.',
      }
    : {};
}

function validateDestinationDate(scheduled_time, origins) {
  const invalid_date = origins.some(item => {
    const date = moment(item.scheduled_time, 'DD/MM/YYYY HH:mm');
    return moment(scheduled_time).isBefore(date);
  });
  const is_retroactive_date = moment(scheduled_time).isBefore(
    moment(new Date(), 'DD/MM/YYYY HH:mm')
  );

  return is_retroactive_date
    ? {
        dateError:
          'Data máxima do destino informada não pode ser anterior ao dia/horário atual.',
      }
    : invalid_date
    ? {
        dateError: 'Data do destino menor que a data de origem.',
      }
    : {};
}

export function validate(location, origins, destinations) {
  let errors = { hasError: false };

  if (!location.country) errors.country = 'País obrigatório.';
  if (!location.address) errors.address = 'Endereço Obrigatório.';
  if (!location.cityModel) errors.cityModel = 'Cidade obrigatória.';
  if (!location.province) errors.province = 'UF obrigatório.';
  if (!location.scheduledTime)
    errors.scheduledTime = 'Data Máxima obrigatória.';
  if (!location.action) errors.action = 'Campo obrigatório.';
  if (!location.startSchedule)
    errors.startSchedule = 'Data Início obrigatória.';
  if (!location.lat)
    errors.lat = 'Selecione um logradouro utilizando o seletor do google';
  if (!location.lng)
    errors.lng = 'Selecione um logradouro utilizando o seletor do google';

  const scheduled_time = moment(location.scheduledTime, 'DD/MM/YYYY HH:mm');
  const start_schedule = moment(location.startSchedule, 'DD/MM/YYYY HH:mm');

  if (scheduled_time.isBefore(start_schedule)) {
    errors.startSchedule =
      'A data de início de coleta deve ser anterior à data máxima de coleta.';
  }

  const date_error =
    location.type === 'origin'
      ? validateOriginDate(scheduled_time, destinations)
      : validateDestinationDate(scheduled_time, origins);

  errors = { ...errors, ...date_error };

  errors.hasError = Object.keys(errors).length > 1;

  return errors;
}
