import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ApplicationLayout from 'v3/layouts/Application';

import { FaArrowDown, FaArrowUp, FaExclamationCircle } from 'react-icons/fa';
import { Button, Card, Fallback, SwitchOption, Text } from 'v3/components';
import Space from 'v3/components/Space';
import { cooperplaceApi } from 'services/api';
import { useSnackbar } from 'v3/components/Snackbar';
import { Col } from 'react-bootstrap';
import { formatDateTime } from 'v3/utils/formatter';
import theme from 'v3/theme';
import { usePermission } from 'hooks';
import { validatePermission } from 'actions';
import {
  LogItemContainer,
  LogItemContent,
  LogItemHeader,
  LogsContainer,
} from './styles';
import { EditPolicy } from '../Edit';
import { usePolicyRegister } from '../Register/context';
import { Operations } from './components/Operation';
import { Header } from './components/Header';

export function PolicyDetails() {
  const {
    active,
    policy,
    policyFetchError,
    fetchPolicy,
    hasRangeValueError,
    handleSubmitEdit,
    loading,
    setLoading,
    editing,
    setEditing,
    setHasRangeValueError,
    setErrors,
    handleRestorePolicyValues,
  } = usePolicyRegister();
  usePermission('VISUALIZAR_APOLICE', { redirect: true });
  const hasPermissionToCreatePolicy = validatePermission('SALVAR_APOLICE');
  const history = useHistory();
  const snackbar = useSnackbar();
  const [toggleLogs, setToggleLogs] = useState({});

  if (policyFetchError?.status) {
    return (
      <ApplicationLayout>
        <Card>
          <div className="text-center">
            <FaExclamationCircle
              size={26}
              color="#F64E60"
              className="mr-2 mb-1"
            />
            <Text type="header" color="error">
              {policyFetchError?.message}
            </Text>
          </div>
        </Card>
      </ApplicationLayout>
    );
  }
  function handleCancelEdit() {
    handleRestorePolicyValues();
    setEditing(false);
    setHasRangeValueError(false);
    setErrors({});
  }

  async function toggleActive(actionStatus) {
    const urlAction = actionStatus ? 'activate' : 'deactivate';
    try {
      setLoading(true);
      await cooperplaceApi.put(`/policies/${policy?.id}/${urlAction}`);
      await fetchPolicy();
      setLoading(false);
    } catch {
      snackbar.show(<Text>Não foi possível alterar status da apólice</Text>, {
        type: 'error',
      });
      setLoading(false);
    }
  }

  return (
    <ApplicationLayout
      title="Apólice"
      RightComponent={
        <Space x="0.5rem">
          <Button
            variant="secondary"
            onClick={() => {
              editing ? handleCancelEdit() : history.push('/apolices');
            }}
            disabled={loading}
          >
            <Text weight={500} color="black" type="regular">
              {editing ? 'Cancelar' : 'Voltar'}
            </Text>
          </Button>
          {hasPermissionToCreatePolicy && (
            <Button
              variant={editing ? 'success' : 'primary'}
              onClick={() => {
                editing ? handleSubmitEdit() : setEditing(true);
              }}
              loading={loading}
              disabled={hasRangeValueError}
            >
              <Text weight={500} color="white" type="regular">
                {editing ? 'Salvar' : 'Editar'}
              </Text>
            </Button>
          )}
        </Space>
      }
    >
      {editing ? (
        <EditPolicy />
      ) : (
        <>
          <Card
            loading={loading}
            header={
              <Text type="header" color="dark" weight={500}>
                Composição Apólice
              </Text>
            }
            HeaderRightComponent={
              editing === false &&
              hasPermissionToCreatePolicy && (
                <SwitchOption
                  value={active}
                  onClick={() => toggleActive(!active)}
                />
              )
            }
          >
            <>
              <Header />
              <Operations />
            </>
          </Card>
          <Card
            loading={loading}
            header={
              <Text color="dark" type="header">
                Auditoria
              </Text>
            }
          >
            <Fallback
              component={
                <Col
                  xs={12}
                  className="align-items-center d-flex flex-column my-2"
                >
                  <Text color={theme.colors.gray}>
                    Não há registros de alterações para essa apólice.
                  </Text>
                </Col>
              }
              fall={policy?.logs?.length === 0}
            >
              <LogsContainer>
                {policy?.logs?.map((item, index) => {
                  return (
                    <LogItemContainer key={item.id}>
                      <LogItemHeader
                        onClick={() =>
                          setToggleLogs(old => ({
                            ...old,
                            [index]: !toggleLogs[index],
                          }))
                        }
                        title={
                          toggleLogs[index]
                            ? ''
                            : 'Clique para ver mais detalhes'
                        }
                      >
                        {toggleLogs[index] ? <FaArrowUp /> : <FaArrowDown />}
                        <Text>{formatDateTime(item?.created_at)}</Text>
                        {' - '}
                        <Text>{`${item?.title} `}</Text>
                      </LogItemHeader>

                      {toggleLogs[index] &&
                        item?.description?.split('\n')?.map((desc, idx) => {
                          return (
                            <LogItemContent key={idx} color="dark" as="p">
                              {desc}
                            </LogItemContent>
                          );
                        })}
                    </LogItemContainer>
                  );
                })}
              </LogsContainer>
            </Fallback>
          </Card>
        </>
      )}
    </ApplicationLayout>
  );
}
