import React, { useState } from 'react';

import Modal from 'v3/components/Modal';
import TextArea from 'v3/components/TextArea';
import Button from 'v3/components/Button';
import Upload from 'v3/components/Upload';
import Text from 'v3/components/Text';

import { TravelContext } from '../../controller';

const [, useTravel] = TravelContext;

function Attachment({ isOpen, onClose }) {
  const travel = useTravel();

  const [files, setFiles] = useState([]);
  const [description, setDescription] = useState('');
  const [errors, setErrors] = useState({});

  function handleClose() {
    setFiles([]);
    setDescription('');
    setErrors({});
    onClose();
  }

  function addAttachment() {
    travel.setData(old => ({
      ...old,
      attachments: [...travel.data?.attachments, ...files],
    }));
    handleClose();
  }

  function onFilesChanged({ target }, hasError) {
    setFiles([]);
    setErrors({});

    if (hasError) {
      setErrors({ file: 'Algum arquivo selecionado é maior que 1MB.' });
      return;
    }

    setFiles(
      [...target.files].map(file => {
        return {
          file: {
            blob: file,
            size: file?.size,
            name: file?.name,
            type: file?.type,
            tempPath: URL.createObjectURL(file),
          },
          description: description || file.name,
        };
      })
    );
  }

  return (
    <Modal
      show={isOpen}
      handleClose={handleClose}
      heading={
        <Text type="subtitle" color="dark" weight="400">
          Anexos
        </Text>
      }
      body={
        <div>
          <div className="mb-3">
            <Text>Adicione um arquivo</Text>
            <Upload
              name="file"
              label="Arquivo máximo de 1MB"
              accept=".jpg,.jpeg,.png,.pdf"
              value={files}
              multiple
              onChange={onFilesChanged}
              error={errors.file}
              ignoreValidation
            />
          </div>
          <TextArea
            label="Descrição"
            resizable={false}
            value={description}
            onChange={event => setDescription(event.target.value)}
          />
          <div className="d-flex flex-column align-items-center mt-3">
            <Button disabled={!files.length} onClick={addAttachment}>
              <Text type="regular" weight={500}>
                Adicionar
              </Text>
            </Button>
          </div>
        </div>
      }
    />
  );
}

export default Attachment;
