import styled, { css } from 'styled-components';

import Text from 'v3/components/Text';
import theme from '../../theme';

export const Container = styled.div`
  width: 100%;
`;

export const Label = styled(Text)`
  margin-bottom: 10px;
  margin-top: 3px;
  display: flex;
`;

export const ButtonGroup = styled.div`
  display: flex;
  vertical-align: middle;

  & > button {
    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
`;

export const ErrorText = styled(Text)`
  margin-bottom: 5px;
  ${props =>
    props.color === 'error' &&
    css`
      color: #f64e60 !important;
    `};
`;

export const Button = styled.button`
  padding: ${({ size }) => theme.spacing.buttonY[size]} 15px;
  outline: none !important;
  transition: all ease-in 0.15s;

  color: ${({ type = 'primary' }) => theme.colors.button[type].text};
  flex: 1 1 auto;

  &.selected {
    background-color: ${theme.colors.primary};
    border-color: ${theme.colors.primary};
    color: ${theme.colors.white};
  }

  ${({ outlined }) =>
    outlined &&
    css`
      background-color: ${({ type = 'primary' }) =>
        theme.colors.button[type].outline.background} !important;
      border: 1px solid
        ${({ type = 'primary' }) => theme.colors.button[type].outline.text} !important;
      color: ${({ type = 'primary' }) =>
        theme.colors.button[type].outline.text} !important;
    `}

  ${({ disabled }) =>
    disabled
      ? css`
          cursor: default;
          opacity: 0.5;
        `
      : css`
          &:active {
            background-color: ${theme.colors.primary};
            border-color: ${theme.colors.primary};
            color: ${theme.colors.white};
          }
          &:focus {
            background-color: ${theme.colors.primary};
            color: ${theme.colors.white};
          }
          &:hover {
            background-color: ${theme.colors.primary};
            border-color: ${theme.colors.primary};
            color: ${theme.colors.white};
          }
        `}
`;
