import { DndContext, closestCenter } from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import React from 'react';
import { SortableItem } from './SortableItem';
import { StyledSortableRow } from './styles';

export function SortableSection({ questions, setQuestions }) {
  const onDragEnd = event => {
    const { active, over } = event;
    if (active.id === over.id) {
      return;
    }
    setQuestions(oldQuestions => {
      const oldIndex = oldQuestions.findIndex(
        question => question.id === active.id
      );
      const newIndex = oldQuestions.findIndex(
        question => question.id === over.id
      );
      const newOrder = arrayMove(oldQuestions, oldIndex, newIndex);
      return newOrder.map((item, index) => {
        return {
          ...item,
          order: index + 1,
        };
      });
    });
  };
  return (
    <StyledSortableRow>
      <DndContext collisionDetection={closestCenter} onDragEnd={onDragEnd}>
        <SortableContext
          items={questions}
          strategy={verticalListSortingStrategy}
        >
          {questions?.map(item => {
            return (
              <SortableItem
                key={item?.id}
                question={item}
                questions={questions}
                setQuestions={setQuestions}
              />
            );
          })}
        </SortableContext>
      </DndContext>
    </StyledSortableRow>
  );
}
