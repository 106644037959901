import * as Yup from 'yup';

export const FeedbackSchema = Yup.object().shape({
  newFeedback: Yup.string()
    .required('Informe um feedback válido')
    .min(50, 'Informe um feedback com no mínimo 50 caracteres')
    .max(1200, 'Máximo de 1200 caracteres'),
});

export const ObsSchema = Yup.object().shape({
  obs: Yup.string()
    .required('Informe uma observação válida')
    .min(50, 'Informe um observação com no mínimo 50 caracteres')
    .max(1200, 'Máximo de 1200 caracteres'),
});
