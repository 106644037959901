import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import classNames from 'classnames';

import Loading from 'components/Loading';

import moment from 'moment';

import api from 'services/api';

import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import SearchIcon from '@material-ui/icons/Search';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { ContentInvoice } from './styles';

const useStyles = makeStyles(theme => ({
  auto: {
    width: '210px',
    height: '165px',
    position: 'relative',
    display: 'inline-block',
  },
  imgCover: {
    objectFit: 'cover',
    width: '210px',
    height: '165px',
  },
  absolute: {
    position: 'absolute',
    top: '0',
    left: '0',
    height: '100%',
    width: '100%',
    zIndex: '2',
  },
  left: {
    width: '60px',
    height: '60px',
    position: 'absolute',
    top: '67px',
    left: '43px',
    transform: 'translateY(-50%)',
  },

  right: {
    width: '60px',
    height: '60px',
    position: 'absolute',
    top: '67px',
    left: '102px',
    transform: 'translateY(-50%)',
  },

  bottom: {
    width: '210px',
    position: 'absolute',
    top: '155px',
    left: '0px',
    transform: 'translateY(-50%)',
    backgroundColor: '#111',
    opacity: 0.7,
    paddingLeft: '3px',
  },

  labelBottom: {
    color: '#fff',
    fontSize: '9pt',
    lineHeight: 1,
  },

  iconSize: {
    fontSize: '1.6em',
    color: '#000000',
  },

  tileSize: {
    maxWidth: '210px',
    // maxHeight: '135px',
    margin: '5px',
    marginBottom: '25px',
  },

  opacity: {
    opacity: 0.3,
  },

  titleStyle: {
    marginBottom: '5px',
    textAlign: 'center',
  },

  chip: {
    color: '#ffffff',
    fontWeight: 'bold',
    height: '22px',
    fontSize: '10pt',
    backgroundColor: '#5FB1F3',
  },

  chipOrange: {
    backgroundColor: '#FF9800',
  },

  chipPosition: {
    transform: 'translateY(-10px)',
  },

  opcoesStatus: {
    paddingTop: '15px',
    marginRight: '0px !important',
    cursor: 'pointer',
    textDecoration: 'underline',
    fontSize: '9.5pt',
    fontWeight: 'bold',
  },

  colorRed: {
    color: '#F44336',
  },

  colorGreen: {
    color: '#00BC97',
  },

  colorYellow: {
    color: '#FF9800',
  },
}));

export default function AttachmentTile(props) {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(true);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [validationOptions, setValidationOptions] = useState(false);
  const [status, setStatus] = useState(props.status);
  const [id, setId] = useState(0);

  useEffect(() => {
    setTitle(props.title ? props.title : ' ');
    setValidationOptions(props.validationOptions);

    setId(props.id);
  }, [
    props.imgObj,
    props.indexId,
    props.title,
    props.validationOptions,
    props.status,
    props.id,
  ]);

  async function updateReview(newStatus) {
    setLoading(true);
    if (!id || !newStatus) return;

    if (newStatus === 'resend' && status === 'resend') return;
    const response = await api.post(`review-attachments/${id}`, {
      status: newStatus,
      load_id: props.load_id,
      settings: props.settings,
    });

    if (response.status === 200) {
      let msg = '';
      switch (newStatus) {
        case 'accept':
          msg = 'Anexo aceito com sucesso!';
          break;
        case 'reject':
          msg = 'Anexo rejeitado com sucesso!';
          break;
        case 'resend':
          msg = 'Solicitação de reenvio realizada com sucesso!';
          break;
        default:
          msg = 'Ação inesperada.';
      }
      try {
        setStatus(newStatus);

        props.setSnackBar({
          type: 'success',
          message: msg,
          display: true,
        });
      } catch (ex) {
        console.log(
          'A ação foi executada, porém não foi possível exibir o snackbar.',
          ex
        );
      }
      if (response.data.attach_send) {
        if (response.data.attach_send.Code === 200) {
          props.setSnackBar({
            type: 'success',
            message: 'Anexo enviado para Truckweb!',
            display: true,
          });
        }
      }
    }
    setLoading(false);
  }

  return (
    <>
      {status !== 'reject' && (
        <Grid item xs={6} md={4} lg={3} className={classes.tileSize}>
          <ContentInvoice>
            <Box my={0}>
              <Typography
                variant="body1"
                style={{ textAlign: 'center' }}
                gutterBottom
              >
                &nbsp;{title}&nbsp;
              </Typography>
            </Box>
            <Grid
              item
              className={classes.auto}
              onMouseEnter={(event, newHover) => {
                setExpanded(false);
              }}
              onMouseLeave={(event, newHover) => {
                setExpanded(true);
              }}
            >
              <img
                className={classNames({
                  [classes.imgCover]: true,
                  [classes.opacity]: !expanded,
                })}
                src={props.src}
                alt={props.title}
              />

              {status === 'pending' && validationOptions && (
                <div
                  style={{
                    width: '100%',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    textAlign: 'center',
                  }}
                >
                  {/* Label superior STATUS */}
                  <Chip
                    label={loading ? <Loading size={20} /> : 'PENDENTE'}
                    className={classNames({
                      [classes.chip]: true,
                      [classes.chipPosition]: true,
                      [classes.chipOrange]: status === 'pending',
                    })}
                  />
                </div>
              )}

              <div className={classes.absolute}>
                {/* Icone esquerdo LUPA */}
                <div hidden={expanded}>
                  <IconButton
                    aria-label={`info about ${props.title}`}
                    className={classes.left}
                    onClick={() => {
                      if (!props.isDocument) {
                        props.openLightBox();
                      } else {
                        //  console.log(props);
                        props.openPdfPreview(props.path);
                      }
                    }}
                  >
                    <SearchIcon className={classes.iconSize} />
                  </IconButton>
                </div>
                {/* Icone direito DOWNLOAD */}
                <div hidden={expanded}>
                  <IconButton
                    aria-label={`info about ${props.title}`}
                    className={classes.right}
                  >
                    <a
                      href={props.isDocument ? props.path : props.src}
                      download
                    >
                      <GetAppIcon className={classes.iconSize} />
                    </a>
                  </IconButton>
                </div>
                <div
                  hidden={!props.created_time}
                  aria-label={`info about ${props.title}`}
                  className={classes.bottom}
                >
                  <span className={classes.labelBottom}>
                    {moment(props.created_time).format('DD-MM-YYYY HH:mm')}
                  </span>
                </div>
              </div>

              {validationOptions && (
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div
                    className={classNames({
                      [classes.opcoesStatus]: true,
                      [classes.colorGreen]: status === 'accept',
                    })}
                    onClick={() => updateReview('accept')}
                  >
                    {' '}
                    ACEITAR
                  </div>
                  <div
                    className={classNames({
                      [classes.opcoesStatus]: true,
                      [classes.colorYellow]: status === 'resend',
                    })}
                    onClick={() => updateReview('resend')}
                  >
                    {' '}
                    REENVIAR
                  </div>
                  <div
                    className={classNames({
                      [classes.opcoesStatus]: true,
                      [classes.colorRed]: status === 'reject',
                    })}
                    onClick={() => updateReview('reject')}
                  >
                    {' '}
                    EXCLUIR
                  </div>
                </div>
              )}
            </Grid>
          </ContentInvoice>
        </Grid>
      )}
    </>
  );
}
