import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;

  border: 1px dashed ${({ theme }) => theme.colors.secondary};
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
  border-radius: 5px;

  cursor: pointer;

  min-width: 260px;

  padding: 10px 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.75;
  }

  img {
    margin-bottom: 5px;
  }

  p {
    color: ${({ theme }) => theme.colors.secondary};
    font-size: 0.9rem;
    font-weight: normal;
    margin-top: 5px;
    margin-bottom: 0;
  }
`;
