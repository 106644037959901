/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';

import api from 'services/api';
import { usePermission } from 'hooks';
import { formatCurrency } from 'v3/utils/formatter';
import { CgChevronDoubleRight } from 'react-icons/cg';

import { Text, Button, InfiniteScroll, Tooltip } from 'v3/components';
import { useSnackbar } from 'v3/components/Snackbar';

import ApplicationLayout from 'v3/layouts/Application';
import theme from 'v3/theme';

import { currency } from '../shared/utils';
import { RANGES, GROUP } from './utils';

import { RouteFilterProvider } from './context';
import { Container } from './styles';

import Filter from './Filter';

function List() {
  const snackbar = useSnackbar();
  usePermission('VISUALIZAR_ROTA', { redirect: true });

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({});
  const [pagination, setPagination] = useState({
    lastPage: 1,
    page: 1,
    total: 0,
  });

  async function fetchData(page = 1) {
    if (page === 1) setLoading(true);

    try {
      const response = await api.get('v3/route', {
        params: {
          page,
          vehicles: filter?.vehicles?.map(vehicle => vehicle.id),
          clients: filter?.clients?.map(client => client.id),
          product: filter?.products?.map(prod => prod.id),
          tags: filter?.tags?.map(tag => tag.id),
          origin: filter?.origin?.id,
          destination: filter?.destination?.id,
          main: filter?.main,
          order: filter.order ? 'asc' : 'desc',
          group: GROUP.find(g => g.label === filter?.group?.label)?.value,
          range: RANGES.find(r => r.label === filter.range)?.value,
        },
      });

      const { data, ...rest } = response.data;

      setPagination(rest);
      setData(old => {
        if (page === 1) {
          return data;
        }

        return [...old, ...data];
      });
    } catch (error) {
      snackbar.show(<Text>Algo deu errado, tente novamente mais tarde</Text>, {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }
  const handlingDestinations = useCallback(item => {
    const destinations = item?.split(',');

    if (destinations) {
      return (
        <>
          {destinations?.map(d => d).length > 1 && (
            <>
              <Text type="regular" color="gray">
                [{destinations.map(d => d).length - 1} PARADA]{' '}
              </Text>
              <CgChevronDoubleRight color={theme.colors.primary} size={20} />
            </>
          )}
          <Text type="regular" color="gray">
            {destinations[0]}
          </Text>
        </>
      );
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  function renderItem(item) {
    return (
      <Container>
        <Link
          to={`/rotas/${item.id}`}
          style={{ display: 'flex', width: '100%' }}
        >
          <div className="col-1">
            <Text weight={500} type="regular" color="dark">
              #{item?.id}
            </Text>
          </div>

          <div className="col-4">
            <Text type="regular" color="gray">
              {item?.origin_address},{' '}
              {item.origin_number && `${item.origin_number},  `}
              {item.origin_city}/{item.origin_province}
            </Text>
            <CgChevronDoubleRight color={theme.colors.primary} size={20} />

            {handlingDestinations(item?.destinations)}
          </div>

          <div className="col-4">
            <Text type="regular" color="gray">
              {item?.social_name} - {item?.cgccpf}
            </Text>
          </div>

          <div className="col-1">
            <Text type="regular" color="gray">
              {formatCurrency(
                +item?.charge_price,
                currency?.currency,
                currency?.currency_symbol
              ) || 'Não informado'}{' '}
            </Text>
          </div>

          <div className="col-1">
            <Text type="regular" color="gray">
              {item?.product_name || 'Não informado'}
            </Text>
          </div>

          <div className="col-1">
            <Tooltip content={<Text type="label">{item?.vehicle_types}</Text>}>
              <Text type="regular" color="gray" truncate>
                {item?.vehicle_types}
              </Text>
            </Tooltip>
          </div>
        </Link>
      </Container>
    );
  }

  return (
    <RouteFilterProvider
      value={{
        data,
        loading,
        filter,
        setFilter,
        fetchData,
      }}
    >
      <ApplicationLayout
        title="Rotas"
        RightComponent={
          usePermission('CADASTRAR_ROTA') && (
            <Link to="/rotas/cadastrar">
              <Button variant="primary" className="py-2 mr-2">
                <Text weight={500} color="white" type="regular">
                  Cadastrar uma Rota
                </Text>
              </Button>
            </Link>
          )
        }
      >
        <Filter />
      </ApplicationLayout>
      <div style={{ paddingLeft: '50px' }}>
        <div className="mt-5 ">
          {!loading && (
            <div className="d-flex text-center">
              <div className="col-1 my-2 mb-3">
                <Text weight={500} type="header" color="dark">
                  Nº
                </Text>
              </div>

              <div className="col-4 my-2 mb-3">
                <Text weight={500} type="header" color={theme.colors.dark}>
                  Rota
                </Text>
              </div>

              <div className="col-4 my-2 mb-3">
                <Text weight={500} type="header" color={theme.colors.dark}>
                  Cliente
                </Text>
              </div>

              <div className="col-1 my-2 mb-3">
                <Text weight={500} type="header" color="gray">
                  Valor da Rota
                </Text>
              </div>

              <div className="col-1 my-2 mb-3">
                <Text weight={500} type="header" color="gray">
                  Produto
                </Text>
              </div>

              <div className="col-1 my-2 mb-3">
                <Text weight={500} type="header" color="gray">
                  Veículo
                </Text>
              </div>
            </div>
          )}
        </div>
        <div className="col-12">
          <InfiniteScroll
            data={data}
            onEndReach={() => {
              fetchData(pagination.page + 1);
            }}
            hasMore={pagination.page < pagination.lastPage}
            scrollThreshold={0.7}
            renderItem={renderItem}
            loading={loading}
          />
        </div>
      </div>
    </RouteFilterProvider>
  );
}

export default List;
