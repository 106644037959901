import { object, date, array } from 'yup';
import { differenceInDays } from 'date-fns';

export default object({
  from: date().typeError('Você deve informar a data inicial'),
  to: date()
    .typeError('Você deve informar a data final')
    .when('from', (from, yup) => {
      return yup
        .min(from, 'A data final deve ser maior que a data de início')
        .test(
          'isValidRange',
          'O período não pode ser maior que 31 dias',
          to => {
            return differenceInDays(to, from) <= 31;
          }
        );
    }),
  clients: array().min(1, 'Você deve informar pelo menos um cliente'),
});
