import React, { useRef, useMemo } from 'react';
import Text from '../Text';

import * as Styled from './styles';

export default function TreeSelectComponent({
  label = '',
  labelTextColor = '#464e5f',
  onSearch,
  showSearch,
  placeholder,
  treeData = [],
  selectValuesContainerMaxHeight = 'auto',
  selectValuesContainerOverflowY = 'hidden',
  loading = false,
  multiple = false,
  error = null,
  ...rest
}) {
  const timeout = useRef(null);

  const TreeSelectToRender = useMemo(() => {
    if (multiple) {
      return Styled.MultiValueTreeSelect;
    }
    return Styled.SingleValueTreeSelect;
  }, [multiple]);

  function debounceValue(input) {
    if (input.length > 2) {
      return new Promise(resolve => {
        if (timeout.current) {
          clearTimeout(timeout.current);
        }

        timeout.current = setTimeout(async () => {
          const searched = await Promise.resolve(onSearch(input.trim()));

          resolve(searched);
        }, 1000);
      });
    }

    return new Promise(resolve => resolve());
  }
  return (
    <Styled.Container>
      {label && (
        <Styled.Label color={labelTextColor} type="label" weight="500">
          {label}
        </Styled.Label>
      )}
      <TreeSelectToRender
        treeData={treeData}
        onSearch={debounceValue}
        showSearch={showSearch}
        allowClear
        notFoundContent={
          <Styled.NotFoundContainer>
            <Text>
              {loading ? 'Carregando...' : 'Nenhuma opção encontrada'}
            </Text>
          </Styled.NotFoundContainer>
        }
        placeholder={placeholder}
        selectValuesContainerOverflowY={selectValuesContainerOverflowY}
        selectValuesContainerMaxHeight={selectValuesContainerMaxHeight}
        multiple={multiple}
        bordered={false}
        error={error}
        {...rest}
      />
      {error && (
        <Text type="little" color="danger">
          {error}
        </Text>
      )}
    </Styled.Container>
  );
}
