export const GROUP = [
  {
    label: 'Número',
    value: 'id',
  },
  {
    label: 'Cliente',
    value: 'social_name',
  },
  {
    label: 'Valor da Rota',
    value: 'charge_price',
  },
  {
    label: 'Produto',
    value: 'product_name',
  },
  {
    label: 'Veículo',
    value: 'vehicle_types',
  },
];

export const RANGES = [
  {
    value: 500,
    label: 0,
  },
  {
    value: 1000,
    label: 10,
  },

  {
    value: 2000,
    label: 20,
  },
  {
    value: 3000,
    label: 30,
  },
  {
    value: 4000,
    label: 40,
  },
  {
    value: 5000,
    label: 50,
  },
  {
    value: 6000,
    label: 60,
  },
  {
    value: 7000,
    label: 70,
  },
  {
    value: 8000,
    label: 80,
  },
  {
    value: 9000,
    label: 70,
  },
  {
    value: 10000,
    label: 100,
  },
];
