import React, { useState } from 'react';

import { Col, Row } from 'react-bootstrap';

import api from 'services/api';

import Modal from 'v3/components/Modal';
import Select from 'v3/components/Select';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';

import { useUser } from '../context';

function AddEstablishment({ isOpen, onClose }) {
  const user = useUser();
  const [establishment, setEstablishment] = useState();
  const [error, setError] = useState('');

  async function fetchEstablishments(inputValue) {
    if (inputValue.length > 3) {
      const response = await api.get(
        `client/reward-members?search=${inputValue}`
      );

      return response.data;
    }
    return false;
  }

  function handleAddEstablishment() {
    user.setData({
      establishments: [...user.data.establishments, establishment],
    });

    setError('');
    setEstablishment('');
    onClose();
  }

  async function handleAttachEstablishment() {
    try {
      await api.put(`v3/user/${user.data.id}/establishment`, {
        establishmentId: establishment.id,
      });
      user.setData({
        establishments: [...user.data.establishments, establishment],
      });
      setError('');
      setEstablishment('');
      onClose();
    } catch (error) {
      user.showSnackbar('Erro ao adicionar estabelecimentos', {
        type: 'error',
      });
    }
  }

  function handleAdd() {
    if (user.data.establishments?.find(item => item.id === establishment.id)) {
      setError('Esse estabelecimento já está cadastrado');
      return;
    }
    if (user.data.id) handleAttachEstablishment();
    else handleAddEstablishment();
  }

  return (
    <>
      <Modal
        show={isOpen}
        onHide={onClose}
        heading={
          <Text color="dark" type="header">
            Estabelecimento
          </Text>
        }
        body={
          <Row>
            <Col xs={12} className="d-flex flex-column align-items-center">
              <Select.Async
                className="mt-3"
                label={
                  <Text type="label" weight="500">
                    Estabelecimento
                  </Text>
                }
                onSearch={search => fetchEstablishments(search)}
                value={establishment}
                getOptionLabel={option => {
                  const label = `${option.social_name} - ${option.cgccpf}`;
                  return label;
                }}
                getOptionValue={option => option.id}
                onChange={value => {
                  setEstablishment(value);
                }}
                error={error}
              />
              <Button
                onClick={handleAdd}
                className="my-2"
                disabled={!establishment}
              >
                <Text type="regular" weight="500" color="white">
                  Adicionar
                </Text>
              </Button>
            </Col>
          </Row>
        }
      />
    </>
  );
}

export default AddEstablishment;
