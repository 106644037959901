import React, { useContext } from 'react';
import { Row } from 'react-bootstrap';
import { Button, Text } from 'v3/components';
import { useTravel } from 'v3/pages/Travel';
import { validatePermission } from 'actions';
import { BlockedTravelContext } from 'contexts/BlockedTravelProvider';
import { useRiskManager } from '../../context';
import { HeaderDetails } from '../../components/HeaderDetails';
import { FooterButtonsContainer } from '../../styles';
import { RiskManagerDetails } from './riskManagerDetails';

export function RiskManagerDetailsCard() {
  const { isTravelBlocked } = useContext(BlockedTravelContext);
  const travel = useTravel();
  const { feedModal, hasEditPermissionRiskManager } = useRiskManager();
  const hasPermissionToEdit = validatePermission('CADASTRAR_VIAGEM_LOG');

  return (
    <>
      <Row>
        <HeaderDetails />
        <RiskManagerDetails />
        {hasEditPermissionRiskManager ? (
          <FooterButtonsContainer>
            <Button
              variant="secondary"
              onClick={() => feedModal.open()}
              disabled={
                !travel.data?.driver ||
                !travel.data?.vehicle?.[0] ||
                !hasPermissionToEdit ||
                isTravelBlocked
              }
            >
              <Text type="regular" weight="500">
                Incluir Feed
              </Text>
            </Button>
          </FooterButtonsContainer>
        ) : null}
      </Row>
    </>
  );
}
