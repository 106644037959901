import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { Fallback, Text } from 'v3/components';
import {
  LogItemContainer,
  LogItemContent,
  LogItemHeader,
  LogsContainer,
} from 'v3/pages/Policy/Details/styles';
import theme from 'v3/theme';
import { formatDateTime } from 'v3/utils/formatter';

export function ManualValidationsAudict({ logs }) {
  const [toggleLogs, setToggleLogs] = useState({});
  return (
    <Fallback
      component={
        <Col xs={12} className="align-items-center d-flex flex-column my-2">
          <Text color={theme.colors.gray}>
            Não há registros de alterações para essa validação.
          </Text>
        </Col>
      }
      fall={logs?.length === 0}
    >
      <LogsContainer>
        {logs?.map((item, index) => {
          return (
            <LogItemContainer key={item?.id}>
              <LogItemHeader
                onClick={() =>
                  setToggleLogs(old => ({
                    ...old,
                    [index]: !old[index],
                  }))
                }
                title={toggleLogs[index] ? '' : 'Clique para ver mais detalhes'}
              >
                {toggleLogs[index] ? <FaArrowUp /> : <FaArrowDown />}
                <Text>{formatDateTime(item?.createdAt)}</Text>
                {' - '}
                <Text>{`${item?.title} `}</Text>
              </LogItemHeader>
              {toggleLogs[index]
                ? item?.obs?.split('\n')?.map(desc => {
                    if (desc) {
                      return (
                        <LogItemContent key={desc} color="dark" as="p">
                          {desc}
                        </LogItemContent>
                      );
                    }
                    return null;
                  })
                : null}
            </LogItemContainer>
          );
        })}
      </LogsContainer>
    </Fallback>
  );
}
