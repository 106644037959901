/* eslint-disable no-await-in-loop */
import React, { useState, useEffect, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import api, { cooperplaceApi } from 'services/api';
import useModal from 'hooks/useModal';
import Text from 'v3/components/Text';
import Select from 'v3/components/Select';
import Modal from 'v3/components/Modal';
import Loader from 'v3/components/Loader';
import { useSnackbar } from 'v3/components/Snackbar';
import { BlockedTravelContext } from 'contexts/BlockedTravelProvider';
import {
  trackedVehicleRequiredFiels,
  validAnttRegex,
  vehicleRequiredFields,
  wagonRequiredFields,
} from 'v3/utils/constants';
import { format } from 'date-fns';
import moment from 'moment';
import { Line, StyledButton } from './styles';
import { TravelContext } from '../../controller';
import { UpdateVehicleData } from '../../Modals/UpdateVehicle';
import { UpdateWagonData } from '../../Modals/UpdateWagon';
import { updateVehicleSchema, updateWagonSchema } from '../../validator';

const [, useTravel] = TravelContext;

const formatVehicle = vehicle => ({
  id: vehicle?.id,
  plate: vehicle?.plate,
  year_manufacture: vehicle?.year_manufacture,
  year_model: vehicle?.year_model,
  chassi: vehicle?.chassi,
  vehicleType: vehicle?.vehicleType,
  vehicle_type_id: vehicle?.vehicle_type_id,
  vehicle_implement_type: vehicle?.vehicle_implement_type,
  implementType: vehicle?.implementType,
  vehicleBodyType: vehicle?.vehicleBodyType,
  vehicle_body_type_id: vehicle?.vehicle_body_type_id,
  renavam: vehicle?.renavam,
  antt_type: vehicle?.antt_type,
  anttType: vehicle?.anttType,
  antt: vehicle?.antt,
  antt_adherence: vehicle?.antt_adherence
    ? format(
        new Date(moment(vehicle?.antt_adherence).add(1, 'day')),
        'yyyy-MM-dd'
      )
    : null,
  tracked: vehicle?.tracked,
  tracker_code: vehicle?.tracker_code,
  tracker: vehicle?.tracker,
  tracker_type: vehicle?.trackerType,
  city: vehicle?.city
    ? {
        id: vehicle?.city?.id,
        name: vehicle?.city?.name,
        uf: vehicle?.city?.province?.uf,
      }
    : null,
  owner_id: vehicle?.owner_id,
  ownerData: vehicle?.ownerData,
  tags: vehicle?.tags || [],
});

const formatImplement = implement => ({
  id: implement?.id,
  plate: implement?.plate,
  year_manufacture: implement?.year_manufacture || implement?.yearManufacture,
  year_model: implement?.year_model || implement?.yearModel,
  chassi: implement?.chassi,
  vehicleType: implement?.vehicleType,
  vehicle_type_id: implement?.vehicle_type_id || implement?.vehicleTypeId,
  bodyType: implement?.bodyType,
  vehicle_body_type_id:
    implement?.vehicle_body_type_id || implement?.vehicleBodyTypeId,
  vehicle_implement_type_id:
    implement?.vehicle_implement_type_id || implement?.vehicleImplementTypeId,
  implementType: implement?.implementType,
  renavam: implement?.renavam,
  antt_type: implement?.antt_type || implement?.anttType,
  anttType: implement?.anttTypes || implement?.anttType,
  antt: implement?.antt,
  antt_adherence:
    implement?.antt_adherence || implement?.anttAdherence
      ? format(
          new Date(
            moment(implement?.antt_adherence || implement?.anttAdherence).add(
              1,
              'day'
            )
          ),
          'yyyy-MM-dd'
        )
      : null,
  city: implement?.city
    ? {
        id: implement?.city?.id,
        name: implement?.city?.name,
        uf: implement?.city?.province?.uf,
      }
    : null,
  id_owner: implement?.id_owner || implement?.idOwner,
  owner: implement?.owner || implement?.ownerWagon,
});

function Vehicle({ vehicles, isOpen, onClose }) {
  const { setItensToFetchMargin, setIsFetchingBenner } =
    useContext(BlockedTravelContext);
  const snackbar = useSnackbar();
  const travel = useTravel();
  const checkListModal = useModal();
  const updateVehicleModal = useModal();
  const updateWagonModal = useModal();
  const updateWagonModal2 = useModal();
  const updateWagonModal3 = useModal();
  const [vehiclesToUpdate, setVehiclesToUpdate] = useState({});

  const [loading, setLoading] = useState(false);

  async function fetchVehicle(search) {
    try {
      const response = await api.get('vehicle/license_plate', {
        params: { search },
      });
      return response.data;
    } catch (error) {
      return [];
    }
  }

  async function getUpdatedVehicle(vehicleId) {
    const getVehicleUrl = `/vehicles/${vehicleId}`;
    const getVehicle = await api.get(getVehicleUrl);
    return getVehicle.data;
  }

  async function getUpdatedWagon(wagonId) {
    const getVehicleUrl = `/wagons/${wagonId}`;
    const getVehicle = await cooperplaceApi.get(getVehicleUrl);
    return getVehicle.data;
  }

  const importWagonFromBenner = async plate => {
    const url = `wagons/importFromBenner?plate=${plate}`;
    const { data } = await cooperplaceApi.put(url);
    const updatedWagon = await getUpdatedWagon(data.id);
    return updatedWagon;
  };

  const importVehicleFromBenner = async plate => {
    const url = `vehicles/importFromBenner?plate=${plate}`;
    const { data } = await cooperplaceApi.get(url);
    const updatedVehicle = await getUpdatedVehicle(data.id);

    setItensToFetchMargin(prevState => ({
      ...prevState,
      mainVehicle: updatedVehicle,
    }));

    snackbar.show(<Text>{data?.message}</Text>, {
      type: 'success',
    });
    return updatedVehicle;
  };

  function checkVehicleData(data) {
    const validation = vehicleRequiredFields.filter(field => !data[field]);

    const anttNumberValidation = validAnttRegex.test(data?.antt);

    let trackValidation = null;
    const shouldValidatedTracker = data?.tracked;
    if (shouldValidatedTracker) {
      trackValidation = trackedVehicleRequiredFiels.filter(
        field => !data[field]
      );

      return (
        validation?.length > 0 ||
        !anttNumberValidation ||
        trackValidation?.length > 0
      );
    }
    return validation?.length > 0 || !anttNumberValidation;
  }

  function wagonDataToValidate(data) {
    return {
      plate: data?.plate,
      chassi: data?.chassi,
      renavam: data?.renavam,
      year_model: data?.year_model || data?.yearModel,
      year_manufacture: data?.year_manufacture || data?.yearManufacture,
      plate_city_id: data?.city?.id,
      vehicle_type_id: data?.vehicle_type_id || data?.vehicleTypeId,
      vehicle_body_type_id:
        data?.vehicle_body_type_id || data?.vehicleBodyTypeId,
      vehicle_implement_type_id:
        data?.vehicle_implement_type_id || data?.vehicleImplementTypeId,
      vehicle_implement_type_name: data?.implementType?.name,
      antt_type: data?.antt_type || data?.anttType,
      id_owner: data?.id_owner || data?.idOwner,
      antt_adherence: data?.antt_adherence || data?.anttAdherence,
      antt: data?.antt,
    };
  }

  function checkWagonData(data) {
    const wagonFieldsToValidate = wagonDataToValidate(data);
    const anttNumberValidation = validAnttRegex.test(
      wagonFieldsToValidate?.antt
    );
    if (wagonFieldsToValidate?.vehicle_implement_type_name === 'Dolly') {
      const validation = wagonRequiredFields
        .filter(field => field !== 'vehicle_body_type_id')
        .filter(field => !wagonFieldsToValidate[field]);
      return validation?.length > 0 || !anttNumberValidation;
    }
    const validation = wagonRequiredFields.filter(
      field => !wagonFieldsToValidate[field]
    );

    return validation?.length > 0 || !anttNumberValidation;
  }

  async function handleSubmit(vehicle) {
    setLoading(true);
    const data = {
      main: vehicle.vehicle,
      implement: vehicle.vehicle?.wagons[0] || {},
      implement2: vehicle.vehicle?.wagons[1] || {},
      implement3: vehicle.vehicle?.wagons[2] || {},
    };

    setIsFetchingBenner(true);
    if (data?.main?.plate) {
      try {
        const updatedVehicle = await importVehicleFromBenner(data.main.plate);
        data.main = updatedVehicle;
      } catch (error) {
        console.error('Erro ao buscar veículo no benner');
        try {
          const getVehicle = await getUpdatedVehicle(data.main.id);
          data.main = getVehicle;
        } catch (er) {
          console.error('Erro ao buscar veículo');
        }
      }
    }

    const keys = Object.keys(data);

    for (let index = 0; index < keys.length; index += 1) {
      const key = keys[index];
      if (key.includes('implement') && data[key]?.plate) {
        try {
          const updatedWagon = await importWagonFromBenner(data[key].plate);
          data[key] = updatedWagon;
        } catch (error) {
          console.error(`Erro ao atualizar ${key} do benner`);
          try {
            const getImplement = await getUpdatedWagon(data[key].id);
            data[key] = getImplement;
          } catch (er) {
            console.error(`Erro ao atualizar ${key}`);
          }
        }
      }
    }

    travel.setData(old => ({
      ...old,
      vehicle: data,
    }));

    snackbar.show(<Text>Veículo selecionado! </Text>, {
      type: 'success',
    });

    const newVehiclesToUpdate = {};

    keys.forEach(key => {
      const value = data[key];
      const isMainVehicle = key === 'main';
      const isImplement = key.includes('implement');
      if (
        value?.plate &&
        ((isMainVehicle && checkVehicleData(value)) ||
          (isImplement && checkWagonData(value)))
      ) {
        if (isMainVehicle) {
          newVehiclesToUpdate[key] = formatVehicle(value);
        }

        if (isImplement) {
          newVehiclesToUpdate[key] = formatImplement(value);
        }
      }
    });

    setVehiclesToUpdate(newVehiclesToUpdate);

    setLoading(false);
    setIsFetchingBenner(false);
    onClose(true);
  }

  async function handleSubmitVehicle() {
    try {
      snackbar.show(<Text className="text-center">Veículo alterado!</Text>, {
        type: 'success',
        duration: 5000,
      });
    } catch (err) {
      snackbar.show(
        <Text className="text-center">Não foi possível alterar o veículo</Text>,
        {
          type: 'error',
          duration: 5000,
        }
      );
    } finally {
      onClose(true);
    }
  }

  function handleUpdateVehicle(data) {
    travel.setData(old => ({
      ...old,
      vehicle: { ...old.vehicle, main: data },
    }));
  }

  function handleUpdateWagon(wagonKey, wagonData) {
    travel.setData(old => ({
      ...old,
      vehicle: { ...old.vehicle, [wagonKey]: wagonData },
    }));
  }

  async function verifyVehicleSchema(payload) {
    try {
      await updateVehicleSchema.validate(payload, {
        abortEarly: false,
      });
      return true;
    } catch (error) {
      return false;
    }
  }

  async function verifyWagonSchema(payload) {
    try {
      await updateWagonSchema.validate(payload, {
        abortEarly: false,
      });
      return true;
    } catch (error) {
      return false;
    }
  }

  const handleCloseVehicleModal = async () => {
    const validVehicleData = await verifyVehicleSchema(vehiclesToUpdate.main);
    if (!validVehicleData) {
      travel.setData(old => ({
        ...old,
        vehicle: {
          ...old.vehicle,
          main: null,
        },
      }));
    }
    updateVehicleModal.close();
  };

  const handleCloseWagonModal = async (wagonKey, closeModal) => {
    const validWagonData = await verifyWagonSchema(vehiclesToUpdate[wagonKey]);
    if (!validWagonData) {
      travel.setData(old => ({
        ...old,
        vehicle: {
          ...old.vehicle,
          [wagonKey]: null,
        },
      }));
    }
    closeModal();
  };

  useEffect(() => {
    if (travel.data.client && travel.data.loadId && travel.data.vehicle) {
      checkListModal.open();
    }
  }, [travel.data.vehicle]);

  useEffect(() => {
    if (vehiclesToUpdate?.main) {
      updateVehicleModal.open();
    }
    if (vehiclesToUpdate?.implement) {
      updateWagonModal.open();
    }
    if (vehiclesToUpdate?.implement2) {
      updateWagonModal2.open();
    }
    if (vehiclesToUpdate?.implement3) {
      updateWagonModal3.open();
    }
  }, [
    vehiclesToUpdate?.main?.id,
    vehiclesToUpdate?.implement?.id,
    vehiclesToUpdate?.implement2?.id,
    vehiclesToUpdate?.implement3?.id,
  ]);

  return (
    <>
      <UpdateVehicleData
        isOpen={updateVehicleModal.isOpen}
        onClose={handleCloseVehicleModal}
        vehicle={vehiclesToUpdate?.main}
        setVehicle={value => {
          if (value) {
            setVehiclesToUpdate(old => ({
              ...old,
              main: { ...old.main, ...value() },
            }));
          }
        }}
        onSubmit={handleUpdateVehicle}
      />
      <UpdateWagonData
        isOpen={updateWagonModal.isOpen}
        onClose={() =>
          handleCloseWagonModal('implement', updateWagonModal.close)
        }
        onSubmit={value => handleUpdateWagon('implement', value)}
        wagon={{ wagonData: vehiclesToUpdate?.implement }}
        setWagon={value => {
          if (value) {
            setVehiclesToUpdate(old => ({
              ...old,
              implement: {
                ...old.implement,
                ...value().wagonData,
              },
            }));
          }
        }}
      />
      <UpdateWagonData
        isOpen={updateWagonModal2.isOpen}
        onClose={() =>
          handleCloseWagonModal('implement2', updateWagonModal2.close)
        }
        onSubmit={value => handleUpdateWagon('implement2', value)}
        wagon={{ wagonData: vehiclesToUpdate?.implement2 }}
        setWagon={value => {
          if (value) {
            setVehiclesToUpdate(old => ({
              ...old,
              implement2: {
                ...old.implement2,
                ...value().wagonData,
              },
            }));
          }
        }}
      />
      <UpdateWagonData
        isOpen={updateWagonModal3.isOpen}
        onClose={() =>
          handleCloseWagonModal('implement3', updateWagonModal3.close)
        }
        onSubmit={value => handleUpdateWagon('implement3', value)}
        wagon={{ wagonData: vehiclesToUpdate?.implement3 }}
        setWagon={value => {
          if (value) {
            setVehiclesToUpdate(old => ({
              ...old,
              implement3: {
                ...old.implement3,
                ...value().wagonData,
              },
            }));
          }
        }}
      />

      <Modal
        show={isOpen}
        handleClose={onClose}
        heading={
          <Text type="header" color="dark" weight="500">
            Veículos
          </Text>
        }
        body={
          <>
            {!vehicles ? (
              <div className="px-5 my-5 align-items-center d-flex justify-content-center">
                <Loader variant="primary" loading={!vehicles} />
              </div>
            ) : (
              <>
                {vehicles?.length > 0 ? (
                  <>
                    <Row md={12} className="px-5 align-items-center">
                      <Col md={12}>
                        <Text type="regular" color="dark" weight="400">
                          Qual desses veículos realizará a viagem?
                        </Text>
                      </Col>

                      {vehicles?.map(vehicle => (
                        <>
                          <Col md={12} className="mt-2">
                            <Row
                              md={12}
                              className="d-flex justify-content-space-between"
                            >
                              <Col md={6} className="mt-2">
                                <Text type="regular" color="dark">
                                  {vehicle.vehicle.plate}
                                </Text>
                              </Col>

                              <Col
                                md={6}
                                className="mt-1 d-flex justify-content-end"
                              >
                                <StyledButton
                                  variant="success"
                                  type="regular"
                                  loading={loading}
                                  onClick={() => handleSubmit(vehicle)}
                                >
                                  <Text color="white" weight="500">
                                    Escolher
                                  </Text>
                                </StyledButton>
                              </Col>
                            </Row>

                            <Row md={12} className="mb-2">
                              <Col md={12}>
                                <Text type="regular" color="gray">
                                  {vehicle.vehicle?.vehicleType?.name}
                                </Text>
                              </Col>
                              <Col md={12}>
                                <Text type="regular" color="gray">
                                  {vehicle.vehicle?.axisType?.name}
                                </Text>
                              </Col>
                            </Row>
                            {vehicle.vehicle.wagons.length > 0 && (
                              <Row md={12} className="mb-2">
                                <Col md={12}>
                                  <Text type="regular" color="dark">
                                    Implementos
                                  </Text>
                                </Col>
                                {vehicle.vehicle.wagons?.map(wagon => (
                                  <Col md={12}>
                                    <Text type="regular" color="gray">
                                      {wagon.plate}
                                    </Text>
                                  </Col>
                                ))}
                              </Row>
                            )}
                          </Col>
                          <Line />
                        </>
                      ))}
                    </Row>
                  </>
                ) : (
                  <>
                    <Row md={12} className="px-5 align-items-center mb-5 form">
                      <Col md={12}>
                        <Text type="regular" color="dark" weight="400">
                          Esse motorista não possui veículo vinculado, adicione
                          um!
                        </Text>
                      </Col>

                      <Col md={12} className="mt-2">
                        <Select.Async
                          onSearch={fetchVehicle}
                          placeholder=""
                          value={travel.data.vehicle?.main}
                          label="Placa principal"
                          onChange={value => {
                            travel.setData(old => ({
                              ...old,
                              vehicle: {
                                main: value,
                              },
                            }));
                            handleSubmitVehicle();
                          }}
                          getOptionLabel={option => option.plate}
                          getOptionValue={option => option.id}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </>
            )}
          </>
        }
      />
    </>
  );
}

export default Vehicle;
