import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { cooperplaceApi } from 'services/api';
import { PreLoadProvider } from './context';
import ApplicationLayout from 'v3/layouts/Application';
import { Button, Card, Input, Text, Tooltip } from 'v3/components';
import { useSnackbar } from 'v3/components/Snackbar';
import { convertNumberToDatabase, currencyOptions } from 'v3/utils/formatter';
import {
  fetchBodies,
  fetchCountries,
  fetchDispositionData,
  fetchImplements,
  fetchVehiclesTypes,
} from 'utils/fetches';
import { QuestionIcon } from 'v3/pages/Load/Register/LoadData/styled';
import { DELIVERY_TYPE_OPTIONS } from 'v3/utils/constants';
import { useMultistepForm } from 'hooks/useMultiStepForm';
import {
  DataSchema,
  DestinationsSchema,
  OriginSchema,
  PreLoadDataSchema,
  VehicleDataSchema,
} from '../validator';
import { LoadData } from './components/LoadData';
import { VehicleData } from './components/VehicleData';
import { OptionalData } from './components/OptionalData';
import { OriginData } from './components/OriginData';
import { DestinationsData } from './components/DestinationsData';
import { StyledFooter } from './styles';
import { checkClientCreditLimit } from 'v3/pages/Load/Register/controller';

export function RegisterPreLoad() {
  const history = useHistory();
  const snackbar = useSnackbar();
  const { steps, currentStepIndex, step, isFirstStep, isLastStep, back, next } =
    useMultistepForm([
      <LoadData />,
      <VehicleData />,
      <OriginData />,
      <DestinationsData />,
      <OptionalData />,
    ]);
  const [data, setData] = useState({
    loadCreationNumber: 1,
    dueDate: null,
    currency: currencyOptions[0],
    searchDriver: true,
    searchDriverRadius: 500,
    vehicleTypes: [],
    vehicleBodies: [],
    origin: {
      address: '',
      date: '',
      complement: '',
      lat: '',
      lng: '',
      formattedAddress: '',
      neighborhood: '',
      city: '',
      province: '',
      zipCode: '',
      country: {
        id: 1,
        name: 'Brasil',
        abbreviation: 'br',
        ddi: 55,
      },
      number: '',
      startSchedule: '',
    },
    destinations: [
      {
        address: '',
        type: DELIVERY_TYPE_OPTIONS[1],
        date: '',
        complement: '',
        lat: '',
        lng: '',
        formattedAddress: '',
        neighborhood: '',
        city: '',
        province: '',
        zipCode: '',
        country: {
          id: 1,
          name: 'Brasil',
          abbreviation: 'br',
          ddi: 55,
        },
        number: '',
        startSchedule: '',
        index: 0,
      },
    ],
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [destinationsError, setDestinationsError] = useState({});
  const [loadDispositionOptions, setLoadDispositionOptions] = useState([]);
  const [vehicleTypesOptions, setVehicleTypesOptions] = useState([]);
  const [vehicleBodyTypesOptions, setVehicleBodyTypesOptions] = useState([]);
  const [vehicleImplementTypesOptions, setVehicleImplementTypesOptions] =
    useState([]);
  const [countryOptions, setCountryOptions] = useState([]);

  async function fetchOptions() {
    try {
      setLoading(true);
      let [
        vehicleTypesData,
        vehiclesBodyTypesData,
        implementsData,
        dispositionData,
        countriesData,
      ] = await Promise.all([
        fetchVehiclesTypes(),
        fetchBodies(),
        fetchImplements(),
        fetchDispositionData(),
        fetchCountries(),
      ]);
      vehicleTypesData = vehicleTypesData.filter(
        vtype => vtype.name !== 'Dolly' && vtype.name !== 'Semi Reboque'
      );
      setVehicleTypesOptions(vehicleTypesData);
      setVehicleBodyTypesOptions(vehiclesBodyTypesData);
      implementsData = implementsData.filter(vtype => vtype.name !== 'Dolly');
      setVehicleImplementTypesOptions(implementsData);
      setLoadDispositionOptions(dispositionData);
      setCountryOptions(countriesData);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchOptions();
  }, []);

  function validateDestinationsStartDate(destinations) {
    if (!destinations || destinations.length <= 1) return true;
    let invalidsDates = [];

    for (let i = 0; i < destinations.length - 1; i++) {
      const nextStartScheduleTime = moment(
        destinations[i + 1].startSchedule,
        'YYYY-MM-DDTHH:mm'
      );
      const currentScheduledTime = moment(
        destinations[i].startSchedule,
        'YYYY-MM-DDTHH:mm'
      );

      if (currentScheduledTime >= nextStartScheduleTime) {
        invalidsDates.push(i);
      }
    }

    return invalidsDates;
  }

  function validateDestinationsEndDate(destinations) {
    if (!destinations || destinations.length <= 1) return true;
    let invalidsDates = [];

    for (let i = 1; i < destinations.length; i++) {
      const previousScheduledTime = moment(
        destinations[i - 1].date,
        'YYYY-MM-DDTHH:mm'
      );
      const currentScheduledTime = moment(
        destinations[i].date,
        'YYYY-MM-DDTHH:mm'
      );
      if (currentScheduledTime <= previousScheduledTime) {
        invalidsDates.push(i);
      }
    }

    return invalidsDates;
  }

  function validateDestinationDate(destinations) {
    if (!destinations || destinations.length <= 1) return true;
    let invalidsDates = [];
    let destArray = destinations.slice(0, destinations?.length - 1);

    for (let i = destinations.length - 1; i > 0; i--) {
      const invalidDate = destArray.some(item => {
        const previousScheduledTime = moment(item.date, 'YYYY-MM-DDTHH:mm');
        const currentScheduledTime = moment(
          destinations[i].startSchedule,
          'YYYY-MM-DDTHH:mm'
        );
        return previousScheduledTime > currentScheduledTime;
      });
      if (invalidDate) {
        invalidsDates.push(i);
      }
      destArray = destArray.slice(0, destArray?.length - 1);
    }
    return invalidsDates;
  }

  function validateDestinationDateWithOrigin(origin, destinations) {
    let invalidsEndDates = [];
    let invalidsStartDates = [];
    const originStart = moment(origin.startSchedule, 'YYYY-MM-DDTHH:mm');
    const originEnd = moment(origin.date, 'YYYY-MM-DDTHH:mm');
    destinations.forEach((dest, idx) => {
      const currentStartScheduledTime = moment(
        dest.startSchedule,
        'YYYY-MM-DDTHH:mm'
      );
      const currentEndTime = moment(dest.date, 'YYYY-MM-DDTHH:mm');
      if (currentStartScheduledTime < originStart) {
        invalidsStartDates.push(idx);
      }
      if (currentEndTime < originEnd) {
        invalidsEndDates.push(idx);
      }
    });
    return { start: invalidsStartDates, end: invalidsEndDates };
  }

  function validateAllDates(dataToValidate) {
    setDestinationsError({});
    const invalidsEndDate = validateDestinationsEndDate(
      dataToValidate?.destinations
    );
    const invalidsStartScheduleDate = validateDestinationsStartDate(
      dataToValidate?.destinations
    );
    const invalidEndStartDate = validateDestinationDate(
      dataToValidate?.destinations
    );
    const { start: invalidStart, end: invalidEnd } =
      validateDestinationDateWithOrigin(
        dataToValidate.origin,
        dataToValidate.destinations
      );

    let invalidsMessages = {};
    if (invalidsEndDate.length > 0) {
      invalidsEndDate.forEach(invalidIdx => {
        invalidsMessages[`${invalidIdx}.date`] =
          'Data máxima não pode ser inferior/igual ao destino anterior';
      });

      setDestinationsError(invalidsMessages);
    }
    if (invalidsStartScheduleDate.length > 0) {
      invalidsStartScheduleDate.forEach(invalidIdx => {
        invalidsMessages[`${invalidIdx}.startSchedule`] =
          'Data início não pode ser superior/igual ao destino posterior';
      });

      setDestinationsError(old => ({ ...old, ...invalidsMessages }));
    }
    if (invalidEndStartDate.length > 0) {
      invalidEndStartDate.forEach(invalidIdx => {
        invalidsMessages[`${invalidIdx}.startSchedule`] =
          'Data início entrega não pode ser menor que data máxima destino anterior';
      });
      setDestinationsError(old => ({ ...old, ...invalidsMessages }));
    }
    if (invalidStart?.length > 0) {
      invalidStart.forEach(invalidIdx => {
        invalidsMessages[`${invalidIdx}.startSchedule`] =
          'Data início entrega não pode ser menor que data da origem';
      });
      setDestinationsError(old => ({ ...old, ...invalidsMessages }));
    }
    if (invalidEnd?.length > 0) {
      invalidEnd.forEach(invalidIdx => {
        invalidsMessages[`${invalidIdx}.date`] =
          'Data máxima entrega não pode ser menor que data da origem';
      });
      setDestinationsError(old => ({ ...old, ...invalidsMessages }));
    }

    if (Object.keys(invalidsMessages)?.length > 0) {
      return false;
    }
    return true;
  }

  async function validate(dataToValidate, setErrors) {
    const errorList = {};
    setDestinationsError({});
    try {
      const areLocationsDatesValid = validateAllDates(dataToValidate);

      await DataSchema.validate(dataToValidate, { abortEarly: false });

      setErrors({ ...errorList });
      if (!areLocationsDatesValid) {
        return false;
      }
      return true;
    } catch (err) {
      if (err.inner) {
        err.inner.forEach(error => {
          errorList[error.path] = error.message;
        });
        setErrors({ ...errorList });
        return false;
      }
    }
  }

  async function handleCreation() {
    setLoading(true);
    if (!(await validate(data, setErrors))) {
      snackbar.show(
        <Text type="body" weight={500}>
          Verifique por campos obrigatórios em todos os passos!
        </Text>,
        {
          type: 'error',
        }
      );
      setLoading(false);
      return false;
    }
    if (
      !(await checkClientCreditLimit(
        data.taker.id,
        +data?.takerValue,
        setErrors
      ))
    ) {
      snackbar.show(
        <Text type="body" weight={500}>
          Cliente sem limite de crédito! Não é possível cadastrar pre carga para
          tomadores sem limite de crédito!
        </Text>,
        { type: 'error' }
      );
      setLoading(false);
      return false;
    }
    const originList = {
      address: data.origin?.address,
      number: data.origin?.number,
      complement: data.origin?.complement,
      zipCode: data.origin?.zipCode,
      province: data?.origin?.province,
      lat: data.origin?.lat,
      lng: data.origin?.lng,
      scheduledTime: moment(data.origin?.date, 'YYYY-MM-DDTHH:mm'),

      cityId: data?.origin?.cityModel?.id || null,
      city: data?.origin?.cityModel?.name,
      neighborhood: data.origin?.neighborhood,
      countrySlug:
        data?.origin?.countrySlug || data?.origin?.country?.abbreviation,
      formattedAddress:
        data?.origin?.formattedAddress ??
        `${data?.origin?.complement} - ${data?.origin?.address}, ${data?.origin?.number} - ${data?.origin?.neighborhood}, ${data?.origin?.cityModel?.name} - ${data?.origin?.province}, ${data?.origin?.zipCode}, ${data?.origin?.country?.name}`,
      startSchedule: moment(data?.origin.startSchedule, 'YYYY-MM-DDTHH:mm'),
      clientId: data?.origin?.client?.id || null,
    };

    const destinationList = data.destinations.map(dest => ({
      address: dest?.address,
      number: dest?.number,
      complement: dest?.complement,
      zipCode: dest?.zipCode,
      province: dest?.province,
      lat: dest?.lat,
      lng: dest?.lng,
      scheduledTime: moment(dest?.date, 'YYYY-MM-DDTHH:mm'),
      cityId: dest?.cityModel?.id,
      city: dest?.cityModel?.name,
      neighborhood: dest?.neighborhood,
      formattedAddress:
        dest?.formattedAddress ??
        `${dest?.complement} - ${dest?.address}, ${dest?.number} - ${dest?.neighborhood}, ${dest?.cityModel?.name} - ${dest?.province}, ${dest?.zipCode}, ${dest?.country?.name}`,
      countrySlug: dest?.countrySlug || dest?.country?.abbreviation,
      action: dest?.type?.id,
      startSchedule: moment(dest?.startSchedule, 'YYYY-MM-DDTHH:mm'),
      clientId: dest.client?.id || null,
    }));
    const payload = {
      personClientId: data.taker.id,
      productId: data.product.id,
      branchOfficeId: data.branchOffice?.id,
      costCenterId: data.costCenter?.id,
      takerValue: +data.takerValue,
      currency: data.currency?.value || 'BRL',
      dispositionId: data.disposition.id,
      loadWeight: +convertNumberToDatabase(data.weight),
      dueDate: data.dueDate,
      searchDriver: data?.searchDriver ? 1 : 0,
      searchDriverRadius: data?.searchDriver
        ? data?.searchDriverRadius
          ? Number(data?.searchDriverRadius)
          : 500
        : 0,
      vehicleTypeIds: data.vehicleTypes.map(type => type.id),
      vehicleImplementTypeIds: data.vehicleImplements
        ? data.vehicleImplements.map(implement => implement.id)
        : [],
      vehicleBodyTypeIds: data.vehicleBodies.map(body => body.id),
      loadOrigins: originList,
      loadDestinations: destinationList,
      clientOrderNumber: data?.clientOrderNumber || null,
      comments: data.observations,
      externalInvoiceNumber: data?.externalInvoiceNumber || null,
    };
    try {
      for (let i = 0; i < data?.loadCreationNumber; i++) {
        await cooperplaceApi.post('pre-loads', payload);
      }
      snackbar.show(<Text>Pré carga cadastrada com sucesso!</Text>, {
        type: 'success',
      });

      setLoading(false);
      history.push(`/pre-cargas`);
    } catch (error) {
      let message =
        error?.response?.data?.message ?? 'Erro ao cadastrar pré carga!';
      snackbar.show(<Text>{message}</Text>, {
        type: 'error',
      });
      setLoading(false);
    }
  }
  function handleCancel() {
    setErrors({});
    setData({
      loadCreationNumber: 1,
      currency: currencyOptions[0],
      searchDriver: true,
      searchDriverRadius: 500,
      vehicleTypes: [],
      vehicleBodies: [],
      destinations: [
        {
          address: '',
          type: DELIVERY_TYPE_OPTIONS[1],
          date: '',
          complement: '',
          lat: '',
          lng: '',
          formattedAddress: '',
          neighborhood: '',
          city: '',
          province: '',
          zipCode: '',
          country: {
            id: 1,
            name: 'Brasil',
            abbreviation: 'br',
            ddi: 55,
          },
          number: '',
          startSchedule: '',
          index: 0,
        },
      ],
    });
    history.push(`/pre-cargas`);
  }

  async function handleGoNext() {
    try {
      switch (currentStepIndex) {
        case 0:
          await PreLoadDataSchema.validate(
            {
              taker: data?.taker,
              costCenter: data?.costCenter,
              product: data?.product,
              branchOffice: data?.branchOffice,
              takerValue: data?.takerValue,
              currency: data?.currency,
              dueDate: data?.dueDate,
            },
            { abortEarly: false }
          );
          break;
        case 1:
          await VehicleDataSchema.validate(
            {
              vehicleTypes: data?.vehicleTypes,
              vehicleBodies: data?.vehicleBodies,
              vehicleImplements: data?.vehicleImplements,
              disposition: data?.disposition,
              weight: data?.weight,
            },
            { abortEarly: false }
          );
          break;
        case 2:
          await OriginSchema.validate(
            { origin: data.origin },
            { abortEarly: false }
          );
          break;
        case 3:
          let check = validateAllDates({origin:data.origin, destinations:data.destinations})
          await DestinationsSchema.validate(
            { destinations: data.destinations },
            { abortEarly: false }
          );
          if(check === false){
            snackbar.show(
              <Text type="body" weight={500}>
                Verifique dados dos destinos!
              </Text>,
              {
                type: 'error',
              }
            );
            throw new Error
          }
          break;
        default:
          break;
      }
      setErrors({});
      next();
    } catch (err) {
      let errorList = {};
      if (err.inner) {
        err.inner.forEach(error => {
          errorList[error.path] = error.message;
        });
        setErrors({ ...errorList });
        return false;
      }
    }
  }

  return (
    <PreLoadProvider
      value={{
        data,
        setData,
        loading,
        setLoading,
        errors,
        setErrors,
        snackbar,
        vehicleTypesOptions,
        vehicleBodyTypesOptions,
        vehicleImplementTypesOptions,
        loadDispositionOptions,
        countryOptions,
        destinationsError,
      }}
    >
      <ApplicationLayout
        title={'Cadastro de pré carga'}
        RightComponent={
          <Button
            variant={'secondary'}
            onClick={() => handleCancel()}
            disabled={loading}
          >
            <Text weight={500}>Cancelar</Text>
          </Button>
        }
      >
        <Card
          header={
            <Text color="#464E5F" type="header">{`Passo ${
              currentStepIndex + 1
            }/${steps?.length}`}</Text>
          }
          HeaderRightComponent={
            <div className="position-relative w-25">
              <Input
                type="number"
                step={1}
                onKeyDown={evt =>
                  ['e', 'E', '+', '-', '.'].includes(evt.key) &&
                  evt.preventDefault()
                }
                placeholder="Quantidade"
                hideAppearance
                value={data?.loadCreationNumber}
                onChange={event => {
                  if (Number(event.target.value) > 100) {
                    return;
                  } else
                    setData(old => ({
                      ...old,
                      loadCreationNumber: event.target.value,
                    }));
                }}
                error={errors.loadCreationNumber}
                containerStyle={{ marginBottom: 0 }}
                disabled={loading}
              />
              <Tooltip
                content={
                  <Text type="label">
                    Quantidade de pré cargas a serem criadas *
                  </Text>
                }
              >
                <QuestionIcon />
              </Tooltip>
            </div>
          }
          bodyCardPadding="1rem"
        >
          {step}
          <StyledFooter>
            {!isFirstStep && (
              <Button
                type="button"
                onClick={back}
                variant={'secondary'}
                disabled={loading}
              >
                <Text type="regular">Anterior</Text>
              </Button>
            )}
            {isLastStep ? (
              <Button
                type="button"
                onClick={handleCreation}
                variant={'success'}
                loading={loading}
              >
                <Text>Salvar</Text>
              </Button>
            ) : (
              <Button type="button" onClick={() => handleGoNext()}>
                <Text color="white" type="regular">
                  Próximo
                </Text>
              </Button>
            )}
          </StyledFooter>
        </Card>
      </ApplicationLayout>
    </PreLoadProvider>
  );
}
