import api from '../../../../services/api';

export async function fetchSelectOptions(url, params = {}) {
  try {
    const { data } = await api.get(url, { params });

    return data;
  } catch (err) {
    return [];
  }
}
