import React, { useState } from 'react';
import { useHistory } from 'react-router-dom/';
import { cooperplaceApi } from 'services/api';
import ApplicationLayout from 'v3/layouts/Application';

import { useSnackbar } from 'v3/components/Snackbar';
import { Text } from 'v3/components';

import { ChecklistData } from '../shared/Data';
import { useChecklist } from '../shared/context';
import { CheckListSchema } from '../components/RegisterComponents/validator';

export function Register() {
  const checklist = useChecklist();
  const history = useHistory();
  const snackbar = useSnackbar();
  const [loadingSave, setLoadingSave] = useState(false);

  async function validate(data, setErrors) {
    const errorList = {};
    const dataObject = {
      name: data?.checklistInfo?.name,
      description: data?.checklistInfo?.description,
      type: data?.checklistInfo?.checklistType?.id,
      questions: data?.questions?.map(item => {
        return {
          checklist_id: item?.id,
          order: item?.order,
          required: item?.required,
        };
      }),
      clients: data?.checklistInfo?.clients,
      negotiation: data?.checklistInfo?.negotiation,
      vehicleType: data?.checklistInfo?.vehicleType,
      implementType: data?.checklistInfo?.implementType,
      vehicleBodyType: data?.checklistInfo?.vehicleBodyType,
      branchOffices: data?.checklistInfo?.branchOffices,
      hasDollyImplement: data?.checklistInfo?.hasDollyImplement,
      availableLogisticPanel: data?.checklistInfo?.availableLogisticPanel,
    };
    try {
      await CheckListSchema.validate(dataObject, {
        abortEarly: false,
      });
      setErrors({ ...errorList });

      return true;
    } catch (err) {
      err.inner.forEach(error => {
        errorList[error.path] = error.message;
      });
      setErrors({ ...errorList });

      return false;
    }
  }

  async function handleRegisterChecklist(questions) {
    if (loadingSave) {
      return;
    }
    try {
      setLoadingSave(true);
      const hasDollyImplement = !!checklist?.data?.implementType.find(
        ({ name }) => name === 'Dolly'
      );
      const dataToValidate = { ...checklist?.data, hasDollyImplement };

      const isDataValid = await validate(
        { checklistInfo: dataToValidate, questions },
        checklist.setErrors
      );

      if (!isDataValid) {
        setLoadingSave(false);
        return;
      }
      const payload = {
        require: checklist?.data?.required || false,
        default: checklist?.data?.default || false,
        active: checklist?.data?.active || false,
        name: checklist?.data?.name,
        description: checklist?.data?.description,
        checkTypeId: checklist?.data?.checklistType?.id,
        cnpjClientsId: checklist?.data?.clients?.map(item => item?.id),
        negotiationId: checklist?.data?.negotiation?.id,
        vehiclesTypesId: checklist?.data?.vehicleType?.map(item => item?.id),
        vehiclesBodyId: checklist?.data?.vehicleBodyType?.map(item => item?.id),
        vehiclesImplementsId: checklist?.data?.implementType?.map(
          item => item?.id
        ),
        questions: questions?.map(question => {
          return {
            questionId: question?.id,
            required: question?.required || false,
            order: question?.order,
          };
        }),
        availableForDriver: !!checklist?.data?.availableForDriver,
        branchOffices: checklist.data.branchOffices.map(item => item?.id),
        requiredInspectSign: !!checklist?.data?.requiredInspectSign,
        requiredDriverSign: !!checklist?.data?.requiredDriverSign,
        availableLogisticPanel: !!checklist?.data?.availableLogisticPanel,
      };
      const { data } = await cooperplaceApi.post('checklists', payload);
      snackbar.show(<Text>Checklist cadastrado com sucesso!</Text>, {
        type: 'success',
      });
      setTimeout(() => {
        setLoadingSave(false);
        history.push(`/checklist/checklists/${data?.id}`);
      }, 500);

      return;
    } catch (error) {
      const message =
        error?.response?.data?.error ||
        'Não foi possível cadastrar o checklist';
      setLoadingSave(false);

      snackbar.show(<Text>{message}</Text>, {
        type: 'error',
      });
    }
  }

  function handleCancel() {
    history.push('/checklist/checklists');
  }

  return (
    <ApplicationLayout title="Cadastro checklist">
      <ChecklistData
        handleSave={handleRegisterChecklist}
        isLoading={loadingSave}
        handleCancel={handleCancel}
      />
    </ApplicationLayout>
  );
}
