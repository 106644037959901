import React from 'react';

import { IconButton } from '@material-ui/core';
import { Report as ReportIcon } from '@material-ui/icons';

import { Message, StyledInfoMessage } from './styles';
import { FindIcon } from './icons';

/**
 *
 * @param {message} string - @default 'Mensagem não definida'
 * Adiciona uma mensagem ao InfoMessage | Default é 'Mensagem não definida'
 *
 * @param {snackColor} string - @default '#fff'
 * HEX ou nome da cor, muda a cor do InfoMessage | Default é '#'
 *
 * @param {width} string - @default '100%'
 * Tamanho do InfoMessage | Default é '100%'
 *
 * @param {messageAlign} string - @default 'center'
 * Alinhamento da mensagem | Default é 'center'
 *
 * @param {icon} string - @default 'ReportIcon'
 * Icone referente ao MateriaUI Icons, icones novos precisam ser adicionados
 * no arquivo ./icons.js. Lá também contém os icones existentes |
 *
 */
function InfoMessage({
  message = 'Mensagem não definida',
  snackColor = '#fff',
  align = 'center',
  fontSize = '16px',
  icon,
}) {
  function renderIcon() {
    let materialUi_Icon = FindIcon(icon);
    return (
      <IconButton style={{ color: '#fff' }}>
        {materialUi_Icon !== '' ? materialUi_Icon : <ReportIcon />}{' '}
      </IconButton>
    );
  }

  return (
    <StyledInfoMessage
      style={{
        borderRadius: '50px',
        boxShadow: 'none',
        minWidth: '360px',
        padding: '0px 35px',
      }}
      snackColor={snackColor}
      align={align}
      message={
        <>
          {renderIcon()}
          <Message style={{ color: '#fff', fontSize: fontSize }}>
            {message}
          </Message>
        </>
      }
    />
  );
}

export default InfoMessage;
