import styled from 'styled-components';
import theme from 'v3/theme';

export const StyledTable = styled.table`
  border: 1px solid transparent;
  border-bottom: 1px solid rgb(232, 232, 232);
`;

export const StyledThead = styled.thead`
  position: sticky;
  top: 0;
  background-color: ${theme.colors.light_gray};
`;

export const StyledTableHeader = styled.th`
  color: ${theme.colors.dark};
  font-weight: 500;
`;

export const StyledBodyContainer = styled.div`
  overflow-y: scroll;
  max-height: 16rem;
  scroll-behavior: smooth;
  :not(:hover)::-webkit-scrollbar {
    width: 0.5rem;
  }
  :not(:hover)::-webkit-scrollbar-track {
    opacity: 0;
  }
  :not(:hover)::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
  }
  :hover::-webkit-scrollbar {
    width: 0.5rem;
  }
  :hover::-webkit-scrollbar-track {
    background: ${theme.colors.light_gray};
    opacity: 0.5;
  }
  :hover::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border-radius: 10px;
  }
`;

export const StyledRow = styled.tr`
  background-color: ${props =>
    props.isSelected ? 'rgba(232, 232, 232, 0.7)' : 'transparent'};
  cursor: pointer;
  &:hover {
    background-color: rgba(232, 232, 232, 0.7) !important;
  }
`;

export const AuditDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  margin-top: 1rem;
`;

export const MainSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const SummarySection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1.5rem;
`;
