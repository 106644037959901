import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import api from 'services/api';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import SwitchOption from 'v3/components/SwitchOption';
import Input from 'v3/components/Input';
import Select from 'v3/components/Select';
import Radio from 'v3/components/Radio';
import Text from 'v3/components/Text';
import { usePermission } from 'hooks';
import { useCompany } from '../context';

export default function Data() {
  const company = useCompany();
  const params = useParams();
  const hasPermission = usePermission('SUPER_ADM');
  const hasPermissionToDisable = usePermission('ATIVAR_EMPRESAS');

  useEffect(() => {
    if (!company.data.can_issue && params.id) {
      company.setData({ can_issue: false });
    }
  }, []);

  useEffect(() => {
    if (!params.id)
      company.setData({
        name: null,
        client_type: 1,
        product_type: 2,
        priorityFleetDuration: null,
        can_issue: false,
        issuers: [],
      });
  }, []);

  async function searchIssuers(search) {
    try {
      const response = await api.get('issuers', { params: { name: search } });
      return response.data.data;
    } catch (ex) {
      return [];
    }
  }

  return (
    <Row>
      <Col xs={12} className="mb-3">
        <Input
          label="Nome *"
          type="text"
          value={company.data.name}
          onChange={e => company.setData({ name: e.target.value })}
          error={company.errors?.['data.name']}
        />
      </Col>
      <Col md={6} xs={12} className="mb-3">
        <Input
          label="Duração da frota proritária"
          type="text"
          masked
          mask={[/^([0-9])/, /([0-9])/, ':', /[0-5]/, /[0-9]/]}
          onChange={e =>
            company.setData({
              priorityFleetDuration: {
                ...company.data.priorityFleetDuration,
                minutes_formatted: e.target.value,
              },
            })
          }
          value={company.data.priorityFleetDuration?.minutes_formatted}
        />
      </Col>
      <Col xs={12} className="mb-3">
        <Radio.Group
          label="Emite CT-e"
          onChange={e => {
            company.setData({ can_issue: e.target.value });
          }}
          value={company.data.can_issue}
          horizontal
        >
          <Radio value>
            <Text color="dark" type="label">
              Sim
            </Text>
          </Radio>
          <Radio value={false}>
            <Text color="dark" type="label">
              Não
            </Text>
          </Radio>
        </Radio.Group>
      </Col>
      {company.data.can_issue && (
        <Col xs={12} className="mb-3">
          <Select.Async
            label="Emissores *"
            onSearch={searchIssuers}
            multiple
            value={company.data.issuers}
            onChange={value => {
              company.setData({ issuers: value || [] });
            }}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            error={company.errors?.['data.issuers']}
          />
        </Col>
      )}
      {hasPermission && (
        <>
          <Col xs={6} className="mb-3">
            <Radio.Group
              label="Tipo do Cliente *"
              onChange={e => {
                if (e.target.value === 1) {
                  company.setData({ product_type: 2 });
                }
                company.setData({
                  client_type: e.target.value,
                });
              }}
              value={company.data.client_type}
              horizontal
            >
              <Radio value={1}>
                <Text color="dark" type="label">
                  Transportadora
                </Text>
              </Radio>
              <Radio value={2}>
                <Text color="dark" type="label">
                  Indústria
                </Text>
              </Radio>
            </Radio.Group>
          </Col>
          <Col xs={6} className="mb-3">
            <Radio.Group
              label="Tipo do Produto *"
              onChange={e => {
                company.setData({ product_type: e.target.value });
              }}
              value={company.data.product_type}
              horizontal
              disabled={company.data.client_type === 1}
            >
              <Radio value={1}>
                <Text color="dark" type="label">
                  Transporte
                </Text>
              </Radio>
              <Radio value={2}>
                <Text color="dark" type="label">
                  Plataforma
                </Text>
              </Radio>
            </Radio.Group>
          </Col>
        </>
      )}
      <Col md={6} className="mt-2">
        <SwitchOption
          value={company.data.status}
          onClick={() => company.setData({ status: !company.data.status })}
          disabled={!hasPermissionToDisable}
        />
      </Col>
    </Row>
  );
}
