/* eslint-disable import/no-unresolved */
import styled from 'styled-components';
import Text from 'v3/components/Text';

export const Wrapper = styled.div`
  margin-bottom: 15px;
  flex-flow: row wrap;
  flex-wrap: wrap;
  flex-direction: row;
  display: flex;
  padding: 15px;
  max-width: 232px;
  height: 95px;
  border: 2.5px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 5px;
  transition: 0.5s;
  &:hover {
    background-color: #e1e1e1;
  }
`;

export const Title = styled(Text)`
  font-weight: bold;
  width: 240px;
  word-wrap: break-word;
  margin-bottom: -5px;
`;

export const AddingWrapper = styled.div`
  align-items: center;
  margin-bottom: 15px;
  flex-flow: row wrap;
  flex-wrap: wrap;
  flex-direction: row;
  display: flex;
  padding: 30px;
  width: 232px;
  height: 95px;
  border: 2.5px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 5px;
  transition: 0.5s;
  &:hover {
    background-color: #e1e1e1;
  }
`;

export const RecentsWrapper = styled.div`
  align-items: center;
  margin-bottom: 15px;
  flex-flow: row wrap;
  flex-wrap: wrap;
  flex-direction: row;
  display: flex;
  padding: 25px;
  width: 232px;
  height: 95px;
  border: 2.5px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 5px;
`;
