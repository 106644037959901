const Yup = require('yup');

export const CheckListSchema = Yup.object().shape({
  name: Yup.string()
    .required('Nome obrigatório')
    .max(100, 'Máximo de 100 caracteres'),
  description: Yup.string()
    .required('Descrição obrigatória')
    .max(255, 'Máximo de 255 caracteres'),
  type: Yup.number().required('Tipo obrigatório'),
  questions: Yup.array().of(
    Yup.object().shape({
      checklist_id: Yup.number(),
      order: Yup.number(),
      required: Yup.boolean(),
    })
  ),
  clients: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number(),
        label: Yup.string(),
      })
    )
    .nullable(),
  negotiation: Yup.object().nullable(),
  vehicleType: Yup.array().of(
    Yup.object().shape({
      id: Yup.number(),
      name: Yup.string(),
    })
  ),
  hasDollyImplement: Yup.boolean(),
  implementType: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number(),
        name: Yup.string(),
      })
    )
    .when(['vehicleType', 'type'], {
      is: (vehicleType, type) => {
        return (
          !!vehicleType.find(({ name }) => name === 'Cavalo Mecânico') &&
          type === 1
        );
      },
      then: Yup.array()
        .of(
          Yup.object().shape({
            id: Yup.number(),
            name: Yup.string(),
          })
        )
        .when('hasDollyImplement', {
          is: true,
          then: Yup.array()
            .min(
              2,
              'Ao selecionar Dolly como implemento será necessário dois tipos de implementos'
            )
            .required(
              'Ao selecionar Dolly como implemento será necessário dois tipos de implementos'
            ),
          otherwise: Yup.array()
            .min(1, 'Selecione pelo menos um tipo de implemento')
            .required('Implemento obrigatório'),
        }),
      otherwise: Yup.array().nullable(),
    }),
  vehicleBodyType: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number(),
        name: Yup.string(),
      })
    )
    .when('vehicleType', {
      is: vehicleType => vehicleType.length > 0,
      then: Yup.array()
        .of(
          Yup.object().shape({
            id: Yup.number(),
            name: Yup.string(),
          })
        )
        .min(1, 'Informe uma carroceria')
        .required('Carroceria obrigatória'),
      otherwise: Yup.array().nullable(),
    }),
  branchOffices: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number(),
        name: Yup.string(),
      })
    )
    .min(1, 'Informe ao menos uma filial')
    .required('Informe ao menos uma filial'),
});
