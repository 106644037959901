import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';

import ApplicationLayout from 'v3/layouts/Application';
import { Col, Row } from 'react-bootstrap';
import { cooperplaceApi } from 'services/api';
import { Button, Card, Text } from 'v3/components';
import { FaExclamationCircle } from 'react-icons/fa';
import { groupBy } from 'v3/utils/array';
import { formatDate } from 'utils/formatter';
import { ClientTravelDetailProvider } from './context';
import { TravelInfoCard } from './TravelInfoCard';
import { LoadInfoCard } from './LoadInfoCard';
import { ObsCard } from './ObsCard';
import { HeaderCard } from './HeaderCard';
import { TravelRouteCard } from './TravelRouteCard';
import { TrackingCard } from './TrackingCard';
import { OccurrencesCard } from './OccurrencesCard';
import { TravelDocsCard } from './TravelDocsCard';
import { FeedbackCard } from './FeedbackCard';
import ChecklistCard from './ChecklistCard';

export function ClientTravelDetail() {
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const { state } = location;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorFetchingTravel, setErrorFetchingTravel] = useState(false);
  const [feedbacks, setFeedbacks] = useState([]);

  async function fetchClientTravel() {
    try {
      setLoading(true);
      const response = await cooperplaceApi.get(
        `clients/travels/${params?.id}`
      );
      setData(response?.data);
      const grouped = groupBy(response?.data?.clientFeedback, item =>
        formatDate(item?.createdAt)
      );
      setFeedbacks(grouped);
    } catch (error) {
      setErrorFetchingTravel(true);
    } finally {
      setLoading(false);
    }
  }

  const refetchTravel = useCallback(async () => {
    await fetchClientTravel();
  }, []);

  useEffect(() => {
    if (params.id) {
      fetchClientTravel();
    }
  }, [params]);

  if (errorFetchingTravel) {
    return (
      <ApplicationLayout>
        <Card>
          <div className="text-center">
            <FaExclamationCircle
              size={26}
              color="#F64E60"
              className="mr-2 mb-1"
            />
            <Text type="header" color="error">
              Erro ao carregar viagem.
            </Text>
          </div>
        </Card>
      </ApplicationLayout>
    );
  }

  return (
    <ClientTravelDetailProvider
      value={{ data, refetchTravel, isFetching: loading, feedbacks }}
    >
      <ApplicationLayout
        title="Detalhamento viagem"
        RightComponent={
          <Button
            variant="secondary"
            onClick={() => history.push(`/painel-logistico?${state?.search}`)}
          >
            <Text weight={500}>Voltar</Text>
          </Button>
        }
      >
        <Row className="my-2">
          <Col xs={12}>
            <HeaderCard />
            <TrackingCard />
            <TravelRouteCard />
          </Col>
          <Col md={6} xs={12}>
            <TravelInfoCard />
          </Col>
          <Col
            md={6}
            xs={12}
            style={{ display: 'flex', alignItems: 'stretch' }}
          >
            <LoadInfoCard />
          </Col>

          <Col md={6} xs={12}>
            <OccurrencesCard />
          </Col>
          <Col md={6} xs={12}>
            <ChecklistCard />
          </Col>

          <Col xs={12}>
            <TravelDocsCard />
            <ObsCard />
            <FeedbackCard />
          </Col>
        </Row>
      </ApplicationLayout>
    </ClientTravelDetailProvider>
  );
}
