/* eslint-disable import/no-unresolved */
import React from 'react';

import Loader from 'v3/components/Loader';

export default function Fallback({ fall, component = <Loader />, children }) {
  if (fall) {
    return component;
  }

  return children || null;
}
