import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { usePermission } from 'hooks';
import Text from 'v3/components/Text';
import ApplicationLayout from 'v3/layouts/Application';
import { useSnackbar } from 'v3/components/Snackbar';
import Button from 'v3/components/Button';
import Card from 'v3/components/Card';
import Input from 'v3/components/Input';
import Select from 'v3/components/Select';
import api from 'services/api';
import { validatePermission } from 'actions';
import { validate } from './validator';
import { YEARS_OPTIONS } from '../shared/utils';
import { ButtonItem, Styled } from './style';

function Register() {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const snackbar = useSnackbar();

  const [loadTypeOptions, setLoadTypeOptions] = useState([]);
  const [newLoadType, setNewLoadType] = useState();
  const [newYear, setNewYear] = useState();
  const [newChargePrice, setNewChargePrice] = useState();
  const [newValue, setNewValue] = useState();
  const [newQtyAxys, setNewQtyAxys] = useState();
  const [errors, setErrors] = useState({});
  const hasPermissionToCreate = validatePermission(
    'CADASTRAR_TABELA_FRETE_MINIMO'
  );
  usePermission('CADASTRAR_TABELA_FRETE_MINIMO', { redirect: true });

  async function handleRegister() {
    try {
      setLoading(true);
      const data = {
        year: newYear,
        chargePrice: newChargePrice,
        value: newValue,
        qtyAxys: newQtyAxys,
        loadType: newLoadType,
      };
      const isDataValid = await validate(data, setErrors);
      if (!isDataValid) {
        snackbar.show(<Text>Verifique os campos obrigatórios</Text>, {
          type: 'error',
        });
        setLoading(false);
        return;
      }
      await api.post('v3/antt-data/', {
        year: newYear.value,
        load_type: newLoadType.id,
        qty_axys: newQtyAxys,
        value: newValue,
        charge_price: newChargePrice,
      });
      snackbar.show(<Text>Sucesso ao cadastrar</Text>, { type: 'success' });
      setLoading(false);

      history.push('/tabela-frete-minimo-antt');
    } catch (ex) {
      snackbar.show(<Text>Algo deu errado ao cadastrar item.</Text>, {
        type: 'error',
      });
      setLoading(false);
    }
  }

  async function fetchLoadTypeOptions() {
    try {
      const { data } = await api.get('v3/load-types');
      setLoadTypeOptions(data);
      return;
    } catch (err) {
      return;
    }
  }

  useEffect(() => {
    fetchLoadTypeOptions();
  }, []);

  return (
    <>
      <ApplicationLayout
        title="Tabela de Frete Mínimo (ANTT)"
        RightComponent={
          <ButtonItem>
            <Button
              variant="secondary"
              onClick={() => {
                history.push('/tabela-frete-minimo-antt');
              }}
            >
              <Text weight={500} color="dark" type="regular">
                Cancelar
              </Text>
            </Button>
            <Button
              variant="success"
              onClick={() => handleRegister()}
              loading={isLoading}
              disabled={!hasPermissionToCreate}
            >
              <Text weight={500} color="white" type="regular">
                Salvar
              </Text>
            </Button>
          </ButtonItem>
        }
      >
        <Row>
          <Col lg={6} md={6} xs={6}>
            <Card
              header={
                <Text color="#464E5F" type="header">
                  Cadastro de Item
                </Text>
              }
            >
              <div className="form-group">
                <Styled>
                  <Select
                    name="year"
                    label="Ano Vigente *"
                    value={newYear}
                    options={YEARS_OPTIONS}
                    onChange={value => {
                      setNewYear(value);
                    }}
                    error={errors['year.label']}
                  />
                </Styled>
              </div>
              <div className="form-group">
                <Styled>
                  <Select
                    label="Tipo de Carga *"
                    value={newLoadType}
                    options={loadTypeOptions}
                    getOptionLabel={option => option.nome}
                    getOptionValue={option => option.id}
                    onChange={value => {
                      setNewLoadType(value);
                    }}
                    error={errors['loadType.nome']}
                  />
                </Styled>
              </div>
              <div className="form-group">
                <Styled>
                  <Input
                    name="qty_axys"
                    label="Número de Eixos *"
                    value={newQtyAxys}
                    onChange={e => setNewQtyAxys(Number(e.target.value))}
                    error={errors.qtyAxys}
                    type="number"
                    min="2"
                    max="9"
                  />
                </Styled>
              </div>
              <div className="form-group">
                <Styled>
                  <Input
                    name="value"
                    onChange={e => setNewValue(Number(e.target.value))}
                    label="Valor de Deslocamento *"
                    value={newValue}
                    error={errors.value}
                    placeholder="BRL $"
                  />
                </Styled>
              </div>
              <div className="form-group">
                <Styled>
                  <Input
                    name="value"
                    onChange={e => setNewChargePrice(Number(e.target.value))}
                    label="Valor de Carga e Descarga *"
                    value={newChargePrice}
                    error={errors.chargePrice}
                    placeholder="BRL $"
                  />
                </Styled>
              </div>
            </Card>
          </Col>
        </Row>
      </ApplicationLayout>
    </>
  );
}
export default Register;
