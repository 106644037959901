import styled from 'styled-components';
import { Button } from 'v3/components';

export const StyledFooter = styled.footer`
    display:flex;
    justify-content:flex-end;
    gap:0.5rem;
    flex-wrap:wrap;

`

export const StyledDestinationNavButton = styled(Button)`
    &:disabled {
        cursor: not-allowed;
    }

`