import React, { useState, useEffect, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import InfiniteScroll from 'components/InfiniteScroll';
import Sidebar from 'components/Sidebar';
import FilterContato from './FilterContato';

import Skeleton from '@material-ui/lab/Skeleton';
import CardContato from './CardContato';
import InfoMessage from 'components/InfoMessage';

import api from 'services/api';

export default function Contato() {
  const [contactsList, setContactsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('');

  const [state, setState] = useState({
    page: 1,
    fimResultados: false,
  });

  useEffect(() => {
    // console.log('UseEffect');
    setState({
      page: 1,
      fimResultados: false,
    });
    setLoading(true);
    getContatos();
  }, []);

  useEffect(() => {
    // console.log('Filters -> ', filter);
    setLoading(true);
    setState({
      page: 1,
      fimResultados: false,
    });
    setContactsList([]);
    fetchMoreData(1, true, filter);
  }, [filter]);

  let getContatos = useCallback(async (filtro = '') => {
    setFilter(filtro);
  }, []);

  async function fetchMoreData(page = 1, reload = false, filtro = '') {
    try {
      let response = await api.get(
        `user-notifications?type=contact&page=${page}${filter}`
      );

      if (response.data.data.length === 0) {
        // console.log('FIM ');
        setState({ ...state, fimResultados: false });
        setContactsList(reload ? response.data.data : [...contactsList]);
      } else {
        setContactsList(
          reload ? response.data.data : [...contactsList, ...response.data.data]
        );

        // Se a resposta vier MENOR do que 9, então quer dizer que esse é o total de dados da empresa
        if (response.data.data.length <= 9) {
          setState({
            ...state,
            page,
            fimResultados: false,
          });
        } else {
          setState({
            ...state,
            page,
            fimResultados: true,
          });
        }
      }
    } catch (ex) {
      console.log('Exception ', ex);
      setState({ ...state, fimResultados: false });
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Grid item xs={12} lg={6}>
        {loading && (
          <>
            <Skeleton variant="rect" width={'100%'} height={95} />
            <br />
            <Skeleton variant="rect" width={'100%'} height={95} />
            <br />
            <Skeleton variant="rect" width={'100%'} height={95} />
            <br />
            <Skeleton variant="rect" width={'100%'} height={95} />
            <br />
            <Skeleton variant="rect" width={'100%'} height={95} />
            <br />
          </>
        )}

        {!loading && (
          <InfiniteScroll
            dataLength={contactsList.length}
            next={() => fetchMoreData(state.page + 1)}
            hasMore={state.fimResultados}
            initialMessage={
              <Grid
                container
                item
                xs={12}
                justify="center"
                style={{ marginTop: '20px' }}
              >
                <InfoMessage
                  icon="arrow-right"
                  width="auto"
                  snackColor="#8c8c8c"
                  message="Preencha os filtros para visualizar as informações."
                />
              </Grid>
            }
            endMessage={
              <>
                {(state.page > 1 || contactsList.length === 0) && (
                  <Grid
                    container
                    lg={6}
                    xs={12}
                    justify="center"
                    style={{ paddingTop: '50px' }}
                  >
                    <InfoMessage
                      icon="doneall"
                      width="auto"
                      snackColor="#8c8c8c"
                      message="Não há mais itens a serem mostrados."
                    />
                  </Grid>
                )}
              </>
            }
            loader={
              <>
                <Skeleton variant="rect" width={'100%'} height={95} />
                <br />
                <Skeleton variant="rect" width={'100%'} height={95} />
                <br />
                <Skeleton variant="rect" width={'100%'} height={95} />
                <br />
                <Skeleton variant="rect" width={'100%'} height={95} />
                <br />
                <Skeleton variant="rect" width={'100%'} height={95} />
                <br />
              </>
            }
          >
            {contactsList &&
              contactsList.map((element, index) => (
                <CardContato key={index} contato={element} />
              ))}
          </InfiniteScroll>
        )}
      </Grid>

      <Sidebar title="Filtros" init>
        <FilterContato getContatos={getContatos} />
      </Sidebar>
    </>
  );
}
