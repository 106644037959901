import api, { cooperplaceApi } from 'services/api';

export async function getLoads(
  filter,
  page = 1,
  { setLoads, setLoading, setPagination }
) {
  try {
    if (page === 1) setLoading(true);
    const response = await cooperplaceApi.get(`loads?${filter}&page=${page}`);
    const { data, meta } = response.data;

    setPagination(meta);
    setLoads(oldLoads => {
      if (page === 1) {
        return data;
      }
      return [...oldLoads, ...data];
    });
    setLoading(false);
  } catch (error) {
    // snackbar
    setLoading(false);
  }
}
