import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import api from 'services/api';

import Select from 'v3/components/Select';

import { useClient } from '../context';

export default function ClientAutomations() {
  const client = useClient();

  const [automationsList, setAutomationsList] = useState();

  async function fetchAutomations() {
    try {
      const response = await api.get('automations');
      return setAutomationsList(response.data);
    } catch (error) {
      return [];
    }
  }

  useEffect(() => {
    fetchAutomations();
  }, []);

  return (
    <Row className="form">
      <Col md={12}>
        <Select
          label="Automações"
          options={automationsList}
          value={client.data.clientAutomations}
          onChange={value =>
            client.setData({
              clientAutomations: value,
            })
          }
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          multiple
        />
      </Col>
    </Row>
  );
}
