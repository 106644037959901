import styled, { css } from 'styled-components';

import Input from 'v3/components/Input';

export const StyledInput = styled(Input)`
  min-height: ${props => props.minHeight};
  resize: ${({ resizable }) => (resizable ? 'both' : 'none')};

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #e5eaee !important;
    `};
`;
