/* eslint-disable import/no-unresolved */
import React, { useState, useMemo, useCallback, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import api from 'services/api';
import issuerApi from 'services/issuerApi';
import { formatDateTime } from 'v3/utils/formatter';
import StringBuilder from 'utils/StringBuilder';
import { useTravel } from 'v3/pages/Travel';
import Modal from 'v3/components/Modal';
import Card, { DefaultLoadingBodyComponent } from 'v3/components/Card';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import { useSnackbar } from 'v3/components/Snackbar';
import { validatePermission } from 'actions';
import { usePermission } from 'hooks';
import { BlockedTravelContext } from 'contexts/BlockedTravelProvider';
import { Mdfe } from './components/mdfe';
import { Emitted } from './components/emitted';

export default function Documents({ travelDocumentsRef, setCanUpdate }) {
  const { isTravelBlocked } = useContext(BlockedTravelContext);
  const travel = useTravel();
  const snackbar = useSnackbar();
  const { handleIssueCte, cteModal } = travel;
  const canIssue = usePermission('EMITIR_CTE');
  const [documents, setDocuments] = useState({});
  const [opened, setOpened] = useState();
  const [lightbox, setLightbox] = useState({
    isOpen: false,
    documents: [],
    index: 0,
  });
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [cancelingCte, setCancelingCte] = useState(null);
  const [cancelingMdfe, setCancelingMdfe] = useState(null);
  const [closingMdfe, setClosingMdfe] = useState(null);
  const hasPermissionToAddCTEAfterST3 = useMemo(
    () => validatePermission('INCLUIR_CTES_STATUS_EM_VIAGEM'),
    []
  );
  const isAllowedToSeeDocuments = usePermission('VISUALIZAR_DOCUMENTOS');

  const validatePermissionToAddCTE = useCallback(() => {
    if (Number(travel?.data?.travelStatus?.order || 0) >= 7) {
      if (hasPermissionToAddCTEAfterST3) {
        return false;
      }
      return true;
    }
    return false;
  }, [travel]);

  const fetchDocuments = useCallback(
    async (emittedId, options = {}) => {
      const { refetch = false, setLoading } = options;
      if (!refetch && opened === emittedId) {
        setOpened(null);
        return;
      }
      try {
        setLoading(true);
        const response = await api.get(`travel-attachment/${emittedId}`);
        const filtered = response.data.filter(data => {
          const [attachment] = data.attachments;
          return !!attachment;
        });
        const mapped = filtered.map(data => {
          const [attachment] = data.attachments;

          let isDocument = true;
          if (attachment.doc_filename) {
            const [, extension] = attachment.doc_filename.split('.');
            if (
              ['jpg', 'png', 'jpeg', 'gif'].includes(extension.toLowerCase())
            ) {
              isDocument = false;
            }
          }

          return {
            ...data,
            ...attachment,
            isDocument,
          };
        });

        setDocuments(prevDocuments => ({
          ...prevDocuments,
          [emittedId]: mapped,
        }));
        setOpened(emittedId);
      } catch (error) {
        // Handle exception
      } finally {
        setLoading(false);
      }
    },
    [opened, setOpened, setDocuments]
  );

  async function handleCancelCte(id) {
    try {
      await issuerApi.put(`/cte/cancel/${id}`);
      snackbar.show('Processo de cancelamento do CT-e iniciado com sucesso.', {
        type: 'success',
      });
    } catch (err) {
      snackbar.show('Erro ao cancelar CT-e', {
        type: 'error',
      });
    } finally {
      setCancelingCte(null);
      setShowConfirmationModal(false);
      setCanUpdate(true);
    }
  }
  async function handleCancelMdfe(id) {
    try {
      await issuerApi.put(`/mdfe/cancel/${id}`);
    } catch (err) {
      snackbar.show('Erro ao cancelar MDF-e', {
        type: 'error',
      });
    } finally {
      setCancelingMdfe(null);
      setShowConfirmationModal(false);
      setCanUpdate(true);
    }
  }

  async function handleCloseMdfe(id) {
    try {
      await issuerApi.put(`/mdfe/close/${id}`);
    } catch (err) {
      snackbar.show('Erro ao encerrar MDF-e', {
        type: 'error',
      });
    } finally {
      setClosingMdfe(null);
      setShowConfirmationModal(false);
      setCanUpdate(true);
    }
  }

  function getDocumentMetadata() {
    const map = {
      cte: 'CT-e',
      canhoto: 'Canhoto',
    };

    const document = lightbox.documents[lightbox.index];
    const type = map[document?.type_obs?.toLowerCase()] || 'Outros';
    const createdAt = formatDateTime(document?.created_at);

    return { type, createdAt };
  }

  const lightboxTitle = useMemo(() => {
    const builder = new StringBuilder(' ');

    const documentMetadata = getDocumentMetadata();

    builder
      .append(`${lightbox.index + 1}/${lightbox.documents.length}`)
      .append('|')
      .append(documentMetadata.type)
      .append(`(${documentMetadata.createdAt})`);

    return builder.build();
  }, [lightbox.index, lightbox.documents]);

  const lightboxIndexes = useMemo(() => {
    const next = (lightbox.index + 1) % lightbox.documents.length;
    const previous =
      (lightbox.index + lightbox.documents.length - 1) %
      lightbox.documents.length;
    return { next, previous };
  }, [lightbox.index, lightbox.documents]);

  return (
    <>
      <Modal
        show={showConfirmationModal}
        body={
          <div style={{ textAlign: 'center' }}>
            <Row>
              <Text style={{ textAlign: 'center' }}>
                Deseja realmente{' '}
                {cancelingMdfe
                  ? `cancelar o MDFe?`
                  : closingMdfe
                  ? `encerrar o MDFe?`
                  : `cancelar o CTe?`}
              </Text>
            </Row>
            <Row>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: '10px',
                  marginTop: '20px',
                }}
              >
                <Button
                  type="primary"
                  onClick={() => {
                    cancelingMdfe
                      ? handleCancelMdfe(cancelingMdfe)
                      : closingMdfe
                      ? handleCloseMdfe(closingMdfe)
                      : handleCancelCte(cancelingCte);
                  }}
                  style={{ marginRight: '10px' }}
                >
                  Sim
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    setShowConfirmationModal(false);
                    setCancelingMdfe(null);
                    setClosingMdfe(null);
                  }}
                >
                  Não
                </Button>
              </div>
            </Row>
          </div>
        }
        centered
      />

      {isAllowedToSeeDocuments && (
        <Card
          ref={travelDocumentsRef}
          loading={travel.isLoading}
          LoadingBodyComponent={() => (
            <DefaultLoadingBodyComponent linesCount={5} perLine={2} />
          )}
          header={
            <Text type="header" color="dark">
              Documentos da viagem
            </Text>
          }
          HeaderRightComponent={
            <>
              <Row style={{ width: '35%' }}>
                <Col>
                  {canIssue && (
                    <Button
                      disabled={
                        !travel.data?.driver ||
                        !travel.data?.vehicle?.[0] ||
                        isTravelBlocked
                      }
                      onClick={() => handleIssueCte()}
                      className="py-2 w-100"
                    >
                      <Text type="regular" color="white" font-weight="600">
                        Emitir CT-e
                      </Text>
                    </Button>
                  )}
                </Col>
                <Col>
                  <Button
                    disabled={
                      !travel.data?.driver ||
                      !travel.data?.vehicle?.[0] ||
                      isTravelBlocked ||
                      validatePermissionToAddCTE()
                    }
                    outlined
                    onClick={() => cteModal.open()}
                    className="py-2 w-100"
                  >
                    <Text type="regular" color="primary" font-weight="600">
                      Adicionar CT-e
                    </Text>
                  </Button>
                </Col>
              </Row>
            </>
          }
        >
          {travel.data?.mdfes?.length > 0 &&
            travel.data?.mdfes?.map(mdfe => (
              <>
                <Mdfe
                  mdfe={mdfe}
                  setCancelingMdfe={setCancelingMdfe}
                  setClosingMdfe={setClosingMdfe}
                  setShowConfirmationModal={setShowConfirmationModal}
                />
                <hr />
              </>
            ))}
          {travel.data?.emitteds?.length > 0 ? (
            travel.data?.emitteds?.map(emitted => (
              <>
                <Emitted
                  emitted={emitted}
                  setLightbox={setLightbox}
                  fetchDocuments={fetchDocuments}
                  documents={documents}
                  opened={opened}
                  lightbox={lightbox}
                  lightboxTitle={lightboxTitle}
                  lightboxIndexes={lightboxIndexes}
                  setCancelingCte={setCancelingCte}
                  setShowConfirmationModal={setShowConfirmationModal}
                />
                <hr />
              </>
            ))
          ) : (
            <Text color="gray" type="regular">
              Esta viagem ainda não possui documentação emitida!
            </Text>
          )}
        </Card>
      )}
    </>
  );
}
