/* eslint-disable consistent-return */
import React, { useState, useLayoutEffect } from 'react';
import axios from 'axios';
import { Grid, IconButton } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';

import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import Stepper from '../../components/Stepper';
import Input from '../../components/Input';
import Snackbar from '../../components/Snackbar';

import api from '../../services/api';
import { isAuthenticated } from '../../services/auth';
import config from '../../config';

import {
  Container,
  Card,
  BackWrapper,
  Logo,
  Line,
  ForgotPassword,
  Version,
  GoBack,
} from './styles';

const Steps = {
  EMAIL: 0,
  PASSWORD: 1,
  FORGOT_PASSWORD: 2,
};

function Login({ history }) {
  const [data, setData] = useState({
    email: '',
    password: '',
    error: false,
  });
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    display: false,
    type: 'error',
    message: '',
  });

  const initialStep = Steps.EMAIL;
  const [currentStep, setCurrentStep] = useState(initialStep);

  useLayoutEffect(() => {
    if (isAuthenticated()) {
      history.push('/');
    }
  }, []);

  function handleException(ex) {
    const { response } = ex;

    const message = response
      ? response.data.message
      : 'Algo deu errado, tente novamente mais tarde.';

    setData({
      ...data,
      error: true,
    });

    setSnackbar({
      type: 'error',
      display: true,
      message,
    });
  }

  async function validateEmail() {
    const { email } = data;

    setLoading(true);

    try {
      if (!email) {
        setSnackbar({
          type: 'error',
          display: true,
          message: 'Por favor, digite seu e-mail',
        });
        return;
      }

      await api.post('sessions/email', { email });

      setCurrentStep(Steps.PASSWORD);
    } catch (ex) {
      handleException(ex);
    } finally {
      setLoading(false);
    }
  }

  async function handleLogsLogin() {
    try {
      const log = {
        name: 'login',
        path: null,
        description: 'login',
      };

      await api.post('v3/user/logs', log);
    } catch (error) {
      return [];
    }
  }

  async function submitLogin() {
    setLoading(true);

    try {
      const response = await api.post(`sessions`, {
        email: data.email,
        password: data.password,
      });

      if (response.status === 200) {
        const user_data = response.data.user;
        localStorage.setItem('token', response.data.token.token);
        localStorage.setItem('usuario', JSON.stringify(user_data));
        localStorage.setItem('updatePushUserId', true);

        axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
          'token'
        )}`;

        const profile = await api.get(
          `users-profiles/${response.data.user.profile_id}`
        );
        const permissions_data = profile.data.permissions;
        localStorage.setItem('permissions', JSON.stringify(permissions_data));
        localStorage.setItem('bot', JSON.stringify(response.data.bot));

        const issue_permission =
          permissions_data.some(
            permission => permission.slug === 'EMITIR_CTE' && permission.active
          ) && user_data.company.can_issue;

        localStorage.setItem('issue_permission', issue_permission);
        window.location.href = '/';
        const isPushNotificationsEnabled = localStorage.getItem(
          'isPushNotificationsEnabled'
        );
        if (isPushNotificationsEnabled) {
          const oneSignalPlayerId = localStorage.getItem('oneSignalPlayerId');
          if (oneSignalPlayerId !== user_data.push_user_id) {
            if (oneSignalPlayerId) {
              await api.put('user/add-user-id', {
                push_user_id: oneSignalPlayerId,
              });
              localStorage.setItem('updatePushUserId', false);
            }
          }
        }
        handleLogsLogin();
      }
    } catch (ex) {
      handleException(ex);
    } finally {
      setLoading(false);
    }
  }

  async function submitPasswordRecovery() {
    setLoading(true);
    try {
      const response = await api.post('forgot-password', {
        email: data.email,
      });

      if (response.status === 200) {
        setSnackbar({
          type: 'success',
          message: 'Enviamos o link para alteração de senha para seu e-mail.',
          display: true,
        });
        setData({ ...data, email: '' });
        setCurrentStep(Steps.EMAIL);
      }
    } catch (ex) {
      handleException(ex);
    } finally {
      setLoading(false);
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();

    if (currentStep === Steps.EMAIL) {
      await validateEmail();
    } else if (currentStep === Steps.PASSWORD) {
      await submitLogin();
    } else {
      await submitPasswordRecovery();
    }
  }

  const steps = [
    {
      step: Steps.EMAIL,
      component: (
        <>
          <Input
            name="email"
            label={
              <div className="mb-2">
                <Text color="primary" weight="500">
                  Login
                </Text>
              </div>
            }
            placeholder="Para começar insira seu e-mail"
            value={data.email}
            type="email"
            error={data.error}
            onChange={event =>
              setData({
                ...data,
                email: event.target.value,
                error: false,
              })
            }
          />
          <Grid
            container
            direction="column"
            alignItems="center"
            className="mt-4"
          >
            <Button type="submit" loading={loading}>
              Próximo
            </Button>
          </Grid>
        </>
      ),
    },
    {
      step: Steps.PASSWORD,
      component: (
        <>
          <Input
            name="password"
            label={
              <div className="mb-2">
                <Text color="primary" weight="500">
                  {data.email}
                </Text>
              </div>
            }
            placeholder="Insira sua senha"
            value={data.password}
            type="password"
            error={data.error}
            autoFocus
            onChange={event =>
              setData({
                ...data,
                password: event.target.value,
                error: false,
              })
            }
          />
          <Grid
            container
            direction="column"
            alignItems="center"
            className="mt-4"
          >
            <Button type="submit" loading={loading}>
              <Text color="white" weight="500" type="regular">
                Entrar
              </Text>
            </Button>
            <ForgotPassword
              onClick={() => setCurrentStep(Steps.FORGOT_PASSWORD)}
            >
              <Text color="dark" weight="500">
                Esqueci minha senha
              </Text>
            </ForgotPassword>

            <GoBack
              onClick={() => {
                setCurrentStep(Steps.EMAIL);
                setData({ ...data, password: '' });
              }}
            >
              <Text color="dark" weight="500">
                Voltar
              </Text>
            </GoBack>
          </Grid>
        </>
      ),
    },
    {
      step: Steps.FORGOT_PASSWORD,
      component: (
        <>
          <BackWrapper>
            <IconButton
              key="back"
              aria-label="Voltar"
              onClick={() => {
                setCurrentStep(Steps.EMAIL);
                setData({ ...data, email: '', password: '' });
              }}
            >
              <ArrowBack />
            </IconButton>
          </BackWrapper>
          <Input
            name="email"
            label={
              <div className="mb-2">
                <Text color="primary" weight="500">
                  Recuperar senha
                </Text>
              </div>
            }
            placeholder="Insira o e-mail de login para recuperar a senha"
            value={data.email}
            type="email"
            error={data.error}
            onChange={event =>
              setData({
                ...data,
                email: event.target.value,
                error: false,
              })
            }
          />
          <Grid
            container
            direction="column"
            alignItems="center"
            className="mt-3"
          >
            <Button type="submit" loading={loading}>
              <Text color="white" weight="500" type="regular">
                Enviar
              </Text>
            </Button>

            <GoBack
              onClick={() => {
                setCurrentStep(Steps.EMAIL);
                setData({ ...data, email: '', password: '' });
              }}
            >
              <Text color="dark" weight="500">
                Voltar
              </Text>
            </GoBack>
          </Grid>
        </>
      ),
    },
  ];

  return (
    <Container>
      <Snackbar
        open={snackbar.display}
        type={snackbar.type}
        message={snackbar.message}
        onClose={() => setSnackbar(false)}
      />
      <Card>
        <Grid container direction="column" alignItems="center">
          <Logo src="/images/Logo-Cooperplace.png" alt="Cooperplace" />
          <Line />
        </Grid>
        <form onSubmit={handleSubmit}>
          <Stepper steps={steps} currentStep={currentStep} />
        </form>
      </Card>
      <Version>
        Versão {config.versionNumber}
      </Version>
    </Container>
  );
}

export default Login;
