import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import Text from 'v3/components/Text';
import { formatDateTime } from 'v3/utils/formatter';

import { useShippingCompany } from '../shared/context';
import SharedData from '../shared/Section';

export default function BennerStatus() {
  const shippingCompany = useShippingCompany();

  return (
    <SharedData Title="Validação Benner" loading={shippingCompany.isFetching}>
      <Row>
        {shippingCompany?.data?.shippingCompanies?.has_problems_benner !==
        null ? (
          <>
            <Col xs={6}>
              <Text type="label" color="dark">
                Status da Importação
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {shippingCompany?.data?.shippingCompanies?.has_problems_benner
                  ? 'Com problemas'
                  : 'Cadastro sem problemas'}
              </Text>
            </Col>
            <Col xs={6}>
              <Text type="label" color="dark">
                Data da última importação
              </Text>

              <Text type="regular" color="gray" as="p" className="mt-2">
                {' '}
                {formatDateTime(
                  shippingCompany?.data?.shippingCompanies
                    ?.updated_at_status_benner
                ) || 'Não informado'}
              </Text>
            </Col>
            {shippingCompany?.data?.shippingCompanies?.has_problems_benner && (
              <Col xs={12}>
                <Text type="label" color="dark">
                  Descrição
                </Text>

                <Text type="regular" color="gray" as="p" className="mt-2">
                  {' '}
                  {
                    shippingCompany?.data?.shippingCompanies
                      ?.description_status_benner
                  }
                </Text>
              </Col>
            )}
          </>
        ) : (
          <Col xs={12}>
            <Text type="regular" color="gray" as="p" className="mt-2">
              Transportadora não importada do Benner
            </Text>
          </Col>
        )}
      </Row>
    </SharedData>
  );
}
