import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { validatePermission } from 'actions';

import ApplicationLayout from 'v3/layouts/Application';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';

import Section from '../shared/Section';
import Data from '../shared/Data';
import * as Styled from './styles';
import { handleCreation } from './controller';
import { useAggregations } from '../shared/context';

export function RegisterAggregationOffer() {
  const history = useHistory();
  const aggregation = useAggregations();
  const [saveContainerOffset, setSaveContainerOffset] = useState(0);
  const [isLoading, setLoading] = useState(false);

  const [hasPermissionToCreate, sethasPermissionToCreate] = useState(
    validatePermission('EDITAR_OFERTAS_AGREGAMENTOS')
  );

  useEffect(() => {
    if (!hasPermissionToCreate) {
      aggregation.showSnackbar(
        'Você não tem permissão para cadastrar ofertas',
        {
          type: 'error',
        }
      );
      history.push('/');
    }
  }, [hasPermissionToCreate]);

  return (
    <>
      <ApplicationLayout
        title="Cadastrar agregamento"
        RightComponent={
          <Styled.SaveContainer style={{ right: saveContainerOffset }}>
            <Button
              variant="secondary"
              onClick={() => history.push('/agregamentos')}
            >
              <Text weight={500} color="black" type="regular">
                Voltar
              </Text>
            </Button>

            <Button
              variant="success"
              onClick={() => {
                handleCreation(
                  aggregation.data,
                  aggregation.setErrors,
                  aggregation.showSnackbar,
                  setLoading,
                  history
                );
              }}
              loading={isLoading}
            >
              <Text weight={500} color="white" type="regular">
                Salvar
              </Text>
            </Button>
          </Styled.SaveContainer>
        }
      >
        <Row style={{ display: 'flex', justifyContent: 'center' }}>
          <Col md={6}>
            <Section Title="Dados do agregamento">
              <Data />
            </Section>
          </Col>
        </Row>
      </ApplicationLayout>
    </>
  );
}
