import React, { useState, useCallback } from 'react';
import dashboardApi from 'services/apis/dashboard';
import { Col } from 'react-bootstrap';
import Toggle from 'v3/components/Toggle';
import { FaMapMarkedAlt } from 'react-icons/fa';
import { BsFillPieChartFill } from 'react-icons/bs';
import { useModal } from 'hooks';
import api from 'services/api';
import CityModal from '../Modals/City';
import { StyledDescription } from './Card/styles';
import Card, { Description, CityPieChart, CityMap } from './Card';
import { DateOptions } from './Card/utils';

const TOGGLE_OPTIONS = [
  {
    label: <FaMapMarkedAlt />,
    value: 'MAP',
  },
  {
    label: <BsFillPieChartFill />,
    value: 'CHART',
  },
];

export default function OriginsWithMoreRequests({ dashboard, name, id }) {
  const [data, setData] = useState([]);
  const [visualizationMode, setVisualizationMode] = useState(
    TOGGLE_OPTIONS[0].value
  );
  const modal = useModal();
  const [loading, setLoading] = useState(false);

  async function handleChangeDate(value, setSelectedDate, setValues, values) {
    setSelectedDate(value);
    setValues(old => ({ ...old, date: value }));

    const payload = {
      tags: values.tags,
      clients: values.clients,
      sizes: values.size,
      component: name,
      date: value,
      card_id: id,
    };

    await api.put(`v3/dashboard/${dashboard.id}/parameters`, payload);
  }

  const fetchOriginsWithMoreRequests = useCallback(async (filters = {}) => {
    try {
      setLoading(true);
      const { date } = filters;

      const response = await dashboardApi.get('origins-with-more-requests', {
        params: {
          start_date: date.value.start_date,
          end_date: date.value.end_date,
        },
      });

      setData(response.data);
    } catch (error) {
      // adding a comment because will is gonna kill me if i dont
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <>
      <CityModal
        isOpen={modal.isOpen}
        onClose={modal.close}
        name={name}
        dashboard={dashboard}
        data={data}
        cityKey="origin_city"
        mapKey="origin"
      />
      <Card
        title="Origens com maior demanda"
        isLoading={loading}
        fetchFn={fetchOriginsWithMoreRequests}
        onFiltersChanged={fetchOriginsWithMoreRequests}
        hideFilter
        dashboard={dashboard}
        name={name}
        id={id}
        dateFilter
        dates={DateOptions.monthly}
        handleChangeDate={handleChangeDate}
        dateFilterOptionLabel={option => option.label}
        dateFilterOptionValue={option => option.value}
        excludeFilterByBranchOffice={true}
        NumericComponent={
          <>
            {visualizationMode === 'MAP' ? (
              <CityMap
                data={data}
                height={450}
                latKey="origin_latitude"
                lonKey="origin_longitude"
              />
            ) : (
              <CityPieChart
                data={data}
                valueKey="origin_value"
                cityKey="origin_city"
              />
            )}
            <StyledDescription>
              <Col xs={12} md={9}>
                <Description openModal modal={modal} clickable>
                  ver destinos frequentes para destas origens
                </Description>
              </Col>
              <Col
                xs={12}
                md={3}
                className="d-flex align-items-center justify-content"
              >
                <Toggle
                  label=""
                  value={visualizationMode}
                  defaultValue={visualizationMode}
                  options={TOGGLE_OPTIONS}
                  onSelected={value => setVisualizationMode(value)}
                />
              </Col>
            </StyledDescription>
          </>
        }
      />
    </>
  );
}
