import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Text from 'v3/components/Text';
import Card from 'v3/components/Card';
import { Link } from 'react-router-dom';
import { formatDate } from 'v3/utils/formatter';
function TravelCard({ travel }) {
  const Header = () => (
    <>
      <Col xs={12}>
        <Row className="d-flex">
          <Col xs={12} sm={3} className="text-center">
            <Text type="regular" color="gray" as="p">
              <Link to={`/cargas/${travel?.load_id}`}>
                Carga - {travel?.load_id || 'Não informado'}
              </Link>
            </Text>
          </Col>
          <Col xs={12} sm={3} className="text-center">
            <Text type="regular" color="gray" as="p">
              Cliente - {travel?.client_name || 'Não informado'}
            </Text>
          </Col>
          <Col xs={12} sm={3} className="text-center">
            <Text type="regular" color="gray" as="p">
              Criador da Viagem -{' '}
              {travel?.user_created_username || 'Não informado'}
            </Text>
          </Col>
          <Col xs={12} sm={3} className="text-center">
            <Text type="regular" color="gray" as="p">
              Centro de Custo - {travel?.cost_center_name || 'Não informado'}
            </Text>
          </Col>
        </Row>
      </Col>
    </>
  );
  return (
    <Card header={<Header />}>
      <Row>
        <Col md={3}>
          <Text type="label" color="dark">
            Origem do Carregamento
          </Text>
          <Text type="regular" color="gray" as="p">
            {travel?.origin_city} - {travel?.origin_province}
          </Text>
          <Text type="label" color="dark">
            Data Início Coleta
          </Text>
          <Text type="regular" color="gray" as="p">
            {formatDate(travel?.origin_start_scheduled) || 'Não informado'}
          </Text>
          <Text type="label" color="dark">
            Data Fim Coleta
          </Text>
          <Text type="regular" color="gray" as="p">
            {formatDate(travel?.origin_scheduled_time) || 'Não informado'}
          </Text>
          <Text type="label" color="dark">
            Destino da Carga
          </Text>
          <Text type="regular" color="gray" as="p">
            {travel?.destination_city} - {travel?.destination_province}
          </Text>
        </Col>
        <Col md={3}>
          <Text type="label" color="dark">
            Previsão Início Descarga
          </Text>
          <Text type="regular" color="gray" as="p">
            {formatDate(travel?.destination_start_scheduled) || 'Não informado'}
          </Text>
          <Text type="label" color="dark">
            Previsão Fim Descarga
          </Text>
          <Text type="regular" color="gray" as="p">
            {formatDate(travel?.destination_scheduled_time) || 'Não informado'}
          </Text>
          <Text type="label" color="dark">
            Transportadora
          </Text>
          <Text type="regular" color="gray" as="p">
            {travel?.shipper_name || 'Não informado'}
          </Text>
          <Text type="label" color="dark">
            Contato Transportador
          </Text>
          <Text type="regular" color="gray" as="p">
            {travel?.shipper_phone || 'Não informado'}
          </Text>
        </Col>
        <Col md={3}>
          <Text type="label" color="dark">
            Motorista
          </Text>
          <Text type="regular" color="gray" as="p">
            {travel?.driver_name || 'Não informado'}
          </Text>
          <Text type="label" color="dark">
            Contato Motorista
          </Text>
          <Text type="regular" color="gray" as="p">
            {travel?.driver_phone || 'Não informado'}
          </Text>
          <Text type="label" color="dark">
            Placa do Veículo Principal
          </Text>
          <Text type="regular" color="gray" as="p">
            {travel?.vehicle_plate || 'Não informado'}
          </Text>
          <Text type="label" color="dark">
            Tipo do Veículo Principal
          </Text>
          <Text type="regular" color="gray" as="p">
            {travel?.vehicle_type || 'Não informado'}
          </Text>
        </Col>
        <Col md={3}>
          <Text type="label" color="dark">
            Tipo de Carroceria Veículo Principal
          </Text>
          <Text type="regular" color="gray" as="p">
            {travel?.vehicle_body_type || 'Não informado'}
          </Text>
          {travel?.vehicle_implement_name ? (
            <>
              <Text type="label" color="dark">
                Implemento do Veículo Principal
              </Text>
              <Text type="regular" color="gray" as="p">
                {travel?.vehicle_implement_name || 'Não informado'}
              </Text>
            </>
          ) : null}
          <Text type="label" color="dark">
            Placa dos Implementos
          </Text>
          <Text type="regular" color="gray" as="p">
            {travel?.wagons_plate || 'Não informado'}
          </Text>
          <Text type="label" color="dark">
            Tipo de Carroceria dos Implementos
          </Text>
          <Text type="regular" color="gray" as="p">
            {travel?.wagons_body_names || 'Não informado'}
          </Text>
          <Text type="label" color="dark">
            Implementos
          </Text>
          <Text type="regular" color="gray" as="p">
            {travel?.wagons_implement_names || 'Não informado'}
          </Text>
        </Col>
      </Row>
    </Card>
  );
}

export default TravelCard;
