import React from 'react';
import { formatCurrency } from 'v3/utils/formatter';
import { Link } from 'react-router-dom';

import { Row, Col } from 'react-bootstrap';

import Text from 'v3/components/Text';
import Card from 'v3/components/Card';

function RevenueCard({ user }) {
  function Header() {
    return (
      <Col xs={6}>
        <div className="mb-2">
          <Text type="regular" color="dark">
            Qtde de viagens: {user.total}
          </Text>
        </div>
        <div>
          <Text type="regular" color="dark">
            Total viagens: {formatCurrency(user.sum)}
          </Text>
        </div>
      </Col>
    );
  }
  function SubHeader() {
    return (
      <Col xs={6}>
        <div className="mb-2">
          <Text type="regular" color="dark">
            Qtde de cargas: {user.total_loads}
          </Text>
        </div>
        <div>
          <Text type="regular" color="dark">
            Total cargas: {formatCurrency(user.sum_loads)}
          </Text>
        </div>
      </Col>
    );
  }

  return (
    <Col xs={6}>
      <Card>
        <Row>
          <Col xs={12} className="mb-3">
            <div className="mb-2">
              <Link to={`/usuarios/${user.user_id}`}>
                <Text weight={600} color="primary" type="subtitle">
                  {user?.username || ''}
                </Text>
              </Link>
            </div>
            <div style={{ wordBreak: 'break-all' }}>
              <Text weight={400} color="secundary" type="regular">
                {user?.email}
              </Text>
            </div>
          </Col>
          <Header />
          <SubHeader/>
        </Row>
      </Card>
    </Col>
  );
}

export default RevenueCard;
