import styled from 'styled-components';
import Button from '../Button';

export const StyledButton = styled(Button)`
  margin-top: 440%;
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
`;
