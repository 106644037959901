import React, { useState, useEffect, useMemo } from 'react';

import { Row, Col } from 'react-bootstrap';

import {
  CNPJ_DIGITS_LENGTH,
  CNPJ_MASK,
  CPF_DIGITS_LENGTH,
  CPF_MASK,
  onlyDigits,
} from 'utils/masks';

import useQuery from 'hooks/useQuery';
import { useHistory } from 'react-router-dom';

import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import Input from 'v3/components/Input';

export default function Filter({onChange}) {

  const [filter, setFilter] = useState({});
  const query = useQuery();
  const history = useHistory();

  function applyMask(value) {
    if (onlyDigits(value).length === CNPJ_DIGITS_LENGTH) {
      return CNPJ_MASK;
    }

    if (onlyDigits(value).length === CPF_DIGITS_LENGTH) {
      return CPF_MASK;
    }

    return false;
  }

  function initFilter() {
    setFilter({
      description: query.get('description'),
      value: query.get('value')
    })    
  }

  useEffect(() => {
    initFilter();
  }, []);


  useEffect(() => {
    const timeout = setTimeout(() => {
      history.replace({ search: query.toString() });
    }, 150);

    return () => {
      clearTimeout(timeout);
    };
  }, [filter]);

  return (
    <Row
      className="mb-4 ml-3 d-flex align-items-center"
      style={{ width: '97%' }}
    >
      <Col md={6} xs={12} style={{ marginLeft: 4 }}>
        <Input
          label="CPF, CNPJ ou Documento (Estrangeiros)"
          labelColor="#fff"
          value={filter.value}
          placeholder="CPF, CNPJ ou Documento (Estrangeiros)"
          onChange={({ target }) => {
            if (target.value === null || target.value === '') {
              query.delete('value');
            } else {
              query.set('value', target.value);
            }
            setFilter(old => ({ ...old, value: target.value }));
          }}
          masked
          mask={applyMask}
          guide={false}
        />
      </Col>
      <Col md={5} xs={12} style={{ marginLeft: -2 }}>
        <Input
          label="Descrição"
          labelColor="#fff"
          value={filter.description}
          placeholder="Descrição"
          onChange={({ target }) => {
            const KEY = 'description'
            if (target.value === null || target.value === '') {
              query.delete(KEY);
            } else {
              query.set(KEY, target.value);
            }
            setFilter(old => ({ ...old, description: target.value }));
          }}
          masked
          mask={applyMask}
          guide={false}
        />
      </Col>

      <Col md={1} style={{ marginTop: 30, marginRight: -6 }}>
        <Button
          variant="success"
          onClick={onChange}
          className="py-2 p-0"
          style={{ width: 75 }}
        >
          <Text type="regular" weight={500}>
            Filtrar
          </Text>
        </Button>
      </Col>
    </Row>
  );
}
