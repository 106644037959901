/* eslint-disable import/no-unresolved */
import React from 'react';

import { Row, Col } from 'react-bootstrap';

import Text from 'v3/components/Text';

import Card, { DefaultLoadingBodyComponent } from 'v3/components/Card';

import { useImplement } from '../shared/context';
import { formatDateTime } from 'v3/utils/formatter';

export function BennerStatus() {
  const implement = useImplement();
  return (
    <Card
      loading={implement.loading}
      LoadingBodyComponent={() => (
        <DefaultLoadingBodyComponent linesCount={4} perLine={2} />
      )}
      header={
        <Text color="dark" type="header">
          Validação Benner
        </Text>
      }
    >
      <Row>
        <Col xs={12}>
          <div className="container-fluid">
            <Row className="form">
              {implement?.data?.hasProblemsBenner !== null ? (
                <>
                  <Col md={6} xs={12}>
                    <Text type="label" color="dark">
                      Status da Importação
                    </Text>

                    <Text type="regular" color="gray" as="p" className="mt-2">
                      {' '}
                      {implement?.data?.hasProblemsBenner
                        ? 'Com problemas'
                        : 'Cadastro sem problemas'}
                    </Text>
                  </Col>
                  <Col md={6} xs={12}>
                    <Text type="label" color="dark">
                      Data da última importação
                    </Text>

                    <Text type="regular" color="gray" as="p" className="mt-2">
                      {implement?.data?.updatedAtStatusBenner
                        ? formatDateTime(
                            implement?.data?.updatedAtStatusBenner,
                            { isUTC: true }
                          )
                        : 'Não informado'}
                    </Text>
                  </Col>
                  {implement?.data?.hasProblemsBenner && (
                    <Col xs={12}>
                      <Text type="label" color="dark">
                        Descrição
                      </Text>

                      <Text type="regular" color="gray" as="p" className="mt-2">
                        {' '}
                        {implement?.data?.descriptionStatusBenner ||
                          'Não informado'}
                      </Text>
                    </Col>
                  )}
                </>
              ) : (
                <Col xs={12}>
                  <Text type="regular" color="gray" as="p" className="mt-2">
                    Implemento não importado do Benner
                  </Text>
                </Col>
              )}
            </Row>
          </div>
        </Col>
      </Row>
    </Card>
  );
}
