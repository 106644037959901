import api from 'services/api';

export async function handleDelete(id,snackbar){
    try{
        await api.delete(`v3/blacklist-person/${id}`);
        snackbar.show('Item da blacklist removido com sucesso!');
    }catch(err){
        snackbar.show(err.response?.data?.message || 'Erro ao remover item!', {
            type: 'error',
          });
    }
   
}