import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { cooperplaceApi } from 'services/api';
import { useSnackbar } from 'v3/components/Snackbar';
import { Text } from 'v3/components';
import { ChecklistProvider } from './context';
import { Details } from '../Details';
import { Register } from '../Register';

export function ChecklistShared() {
  const params = useParams();
  const snackbar = useSnackbar();

  const [checklist, setChecklist] = useState({});
  const [prevChecklist, setPrevChecklist] = useState({ ...checklist });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  function showSnackbar(text, options) {
    snackbar.show(<Text> {text} </Text>, options);
  }

  async function fetchChecklist(id) {
    try {
      setLoading(true);
      const { data } = await cooperplaceApi.get(`checklists/${id}`);
      const checklistData = {
        ...data,
        checklistType: data?.checkListType,
        clients: data?.checkListClient?.map(client => {
          return {
            personId: client?.personId,
            ...client?.client,
          };
        }),
        vehicleType: data?.checkListsVehicleTypes
          ?.map(vehicle => {
            return vehicle?.vehicleType
              ? {
                  ...vehicle.vehicleType,
                }
              : null;
          })
          .filter(Boolean),
        vehicleBodyType: data?.checkListsVehicleBody
          ?.map(body => {
            return body?.vehicleBodyType
              ? {
                  ...body.vehicleBodyType,
                }
              : null;
          })
          .filter(Boolean),
        implementType: data?.checklistImplements
          ?.map(implement => {
            return implement?.implement
              ? {
                  ...implement.implement,
                }
              : null;
          })
          .filter(Boolean),
        questions: data?.checklistsQuestions?.map(q => {
          return {
            required: !!q?.required,
            order: q?.order,
            ...q?.question,
          };
        }),
        branchOffices: data?.branchOffices?.map(({ branchOffice }) => ({
          ...branchOffice,
        })),
      };
      setChecklist(checklistData);
      setPrevChecklist(checklistData);
    } catch (error) {
      showSnackbar('Não foi possível carregar esse checklist', {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (params.id) {
      fetchChecklist(params.id);
    }
  }, [params?.id]);

  function updatePrevChecklist() {
    setPrevChecklist(checklist);
  }

  function handleCancel() {
    setChecklist(prevChecklist);
  }

  return (
    <ChecklistProvider
      value={{
        data: checklist,
        setData: setChecklist,
        loading,
        setLoading,
        errors,
        setErrors,
        showSnackbar,
        handleCancel,
        updatePrevChecklist,
        prevChecklist,
        fetchChecklist,
      }}
    >
      {params?.id ? <Details /> : <Register />}
    </ChecklistProvider>
  );
}
