import React from 'react';
import { Button, Card, Fallback, Text } from 'v3/components';
import { Col } from 'react-bootstrap';
import { usePermission } from 'hooks';
import { usePolicyRegister } from '../Register/context';
import { Header } from '../Register/components/Header';
import { PolicyRangesContainer, SectionHeader } from '../styles';
import { MAX_OP } from '../Register/utils';
import { RegisterSection } from '../Register/components/ranges/Register';
import { InsertButton } from '../Register/components/ranges/InsertButton';

export function EditPolicy() {
  const { loading, operationModal, operations, errors, policyClients } =
    usePolicyRegister();
  usePermission('SALVAR_APOLICE', { redirect: true });

  return (
    <Card
      loading={loading}
      header={
        <div style={{ width: '100%', textAlign: 'center' }}>
          <Text type="header" color="dark">
            Composição Apólice
          </Text>
        </div>
      }
    >
      <Header />

      <PolicyRangesContainer>
        <SectionHeader>
          <Text weight={500} color="dark" type="regular">
            Operações
          </Text>

          <InsertButton
            title={`${
              operations?.length === MAX_OP
                ? 'Limite máximo de operações atingido'
                : policyClients?.length === 0 || !policyClients
                ? 'Informe primeiro dados dos tomadores'
                : 'Clique para inserir uma nova operação'
            }`}
            label=" Inserir operação"
            onClick={() => operationModal.open()}
            disabled={
              operations?.length === MAX_OP ||
              !policyClients ||
              policyClients?.length === 0
            }
          />
        </SectionHeader>

        <Fallback
          fall={operations?.length === 0 || errors?.operations}
          component={
            <Col
              xs={12}
              className="pt-5 d-flex justify-content-center align-items-center"
            >
              {errors?.operations ? (
                <Text weight={500} color="danger">
                  Informe no mínimo uma operação
                </Text>
              ) : (
                <Button size="md" disabled>
                  Não há operações cadastradas
                </Button>
              )}
            </Col>
          }
        >
          <RegisterSection />
        </Fallback>
      </PolicyRangesContainer>
    </Card>
  );
}
