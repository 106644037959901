/* eslint-disable import/no-unresolved */
import React, { useState, useMemo } from 'react';
import moment from 'moment';

import api from 'services/api';
import { useTravel } from 'v3/pages/Travel';
import { useSnackbar } from 'v3/components/Snackbar';

import Modal from 'v3/components/Modal';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import DatePicker from 'v3/components/DatePicker';

const TYPES = {
  arrival_time: 'chegada',
  effective_time: 'efetivo',
};

export default function Inform({ id, isOpen, onClose, isOrigin, type }) {
  const [datetime, setDatetime] = useState('');
  const [errors, setErrors] = useState({ datetime: '' });

  const travel = useTravel();
  const snackbar = useSnackbar();

  function setError(key, message) {
    setErrors(prev => ({ ...prev, [key]: message }));
  }

  function clearError(key) {
    setError(key, '');
  }

  async function handleInsertTime() {
    const endpoint = isOrigin ? 'origin' : 'destination';

    const momentDate = moment(datetime, 'DD/MM/YYYY HH:mm');

    if (!momentDate.isValid()) {
      setError('datetime', 'Data informada é inválida');

      return;
    }

    try {
      const data = {
        title: TYPES[type],
        [type]: momentDate.toDate(),
      };

      await api.put(`/travel-data/${endpoint}/${id}`, data);

      setDatetime('');
      travel.fetchTravel();
      snackbar.show(<Text>Horário informado com sucesso</Text>, {
        type: 'success',
      });
    } catch (ex) {
      let message = 'Não foi possível informar o horário';
      if (ex.response && ex.response.data && ex.response.data.error) {
        message = ex.response.data.error;
      }
      travel.fetchTravel();
      snackbar.show(<Text>{message}</Text>, {
        type: 'error',
      });
    } finally {
      onClose();
    }
  }

  const informModalTitle = useMemo(
    () => (
      <Text color="dark" weight="500" type="header">
        Informar {TYPES[type]}
      </Text>
    ),
    [type]
  );

  return (
    <Modal
      show={isOpen}
      heading={informModalTitle}
      handleClose={onClose}
      body={
        <div className="d-flex flex-column align-items-center">
          <DatePicker
            guide
            withTime
            label="Efetivado em *"
            value={datetime}
            onChange={({ target }) => setDatetime(target.value)}
            onBlur={() => clearError('datetime')}
            error={errors.datetime}
          />
          <Button onClick={handleInsertTime} className="py-2 mr-3 mt-3">
            <Text type="regular" weight="500">
              Confirmar
            </Text>
          </Button>
        </div>
      }
    />
  );
}
