import styled from 'styled-components';
import theme from 'v3/theme';

import { FaQuestionCircle } from 'react-icons/fa';

export const QuestionIcon = styled(FaQuestionCircle)`
  position: absolute;
  right: 10px;
  top: 15px;
  color: ${theme.colors.light_blue};
  z-index: 5;
`;

export const DeleteIcon = styled.button`
  border-radius: 50px;
  background: ${theme.colors.error};
  padding: 0 8px 8px;
`;
