import React from 'react';
import { Row, Col } from 'react-bootstrap';

import Card from 'v3/components/Card';
import Text from 'v3/components/Text';

export function RegisterInfo({ loading = false }) {
  return (
    <Card
      loading={loading}
      header={
        <Text type="header" color="dark">
          Novo fluxo de alteração de cadastro
        </Text>
      }
    >
      <Row>
        <Col xs={12}>
          <Text type="regular" color="#494950" as="p" className="mt-2">
            Alterações de dados cadastrais devem ser solicitadas na viagem no
            card "Solicitação de Ajuste de Cadastro".
          </Text>
        </Col>
      </Row>
    </Card>
  );
}
