/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/no-unresolved */
import React from 'react';
import moment from 'moment';

import Text from 'v3/components/Text';
import { convertToDatabase } from 'v3/utils/formatter';
import { downloadCSV } from 'functions';
import loadAPI from 'services/apis/load';
import { content } from './types/example';
import { schema } from './types/csvValidator';
import { csvColumns } from './types/csvColumns';

export function showSnackbar(snackbar, text, options) {
  snackbar.show(<Text> {text} </Text>, options);
}

export function importTemplate() {
  const header = Object.values(csvColumns).join(';');
  downloadCSV(header, content, `template.csv`, 'text/csv;encoding:utf-8');
}

export function handleCloseModal({ setLoadFile, closeModal, setError }) {
  setLoadFile(null);
  setError([]);
  closeModal();
}

async function resolveSequentially(array, setProgressBarVisible, setCompleted) {
  const result = [];
  let index = 0;

  for (const item of array) {
    result.push(await loadAPI.post('create', item));

    setProgressBarVisible(true);
    setCompleted(((index + 1) / array.length) * 100);

    index += 1;
  }

  return result;
}

async function createLoads({
  setProgressBarVisible,
  setCompleted,
  setLoadFile,
  setLoading,
  closeModal,
  snackbar,
  setError,
  loads,
}) {
  try {
    const loadsPayload = loads.map(load => {
      let advanceMoneyPercentage = +convertToDatabase(
        load.advanceMoneyPercentage
      );

      if (advanceMoneyPercentage > 1) {
        advanceMoneyPercentage /= 100;
      }

      const data = {
        load: {
          pre_load: true,
          crawled_load: load.crawledLoad === '1',
          tolled_load: load.tolledLoad === '1',
          mopp: load.mopp === '1',
          type_value: load.typeValue,
          contact_info: load.contactInfo,
          comments: load.comments,
          load_value: +convertToDatabase(load.loadValue),
          cargo_value: +convertToDatabase(load.loadValue),
          taker_value: +convertToDatabase(load.takerValue),
          fare_company: +convertToDatabase(load.fareCompany),
          toll_value: +convertToDatabase(load.tollValue),
          charge_value: +convertToDatabase(load.chargeValue),
          charge_included: load.chargeIncluded,
          discharge_value: +convertToDatabase(load.dischargeValue),
          unload_included: load.dischargeIncluded,
          insurance_value: +convertToDatabase(load.insuranceValue),
          insurance_included: load.insuranceIncluded,
          gris: +convertToDatabase(load.grisValue),
          gris_included: load.grisIncluded,
          ad_valorem: +convertToDatabase(load.adValorem),
          ad_valorem_included: load.adValoremIncluded,
          advance_money_percentage: advanceMoneyPercentage,
          product_id: Number(load.product),
          load_weight: Number(load.loadWeight),
          net_weight: Number(load.loadLiquidWeight),
          load_volume: Number(load.loadVolume),
          load_disposition_id: Number(load.loadDisposition),
          pallet_number: Number(load.palletNumber),
          due_date: moment(
            `${load.dueDate} ${load.dueTime}`,
            'DD-MM-YYYY hh:mm:ss'
          )
            .utc()
            .format(),
          id_externo: load.idExterno
            ? load.idExterno.toString().trim()
            : undefined,
          cost_center_id: load.costCenterId,
        },
        taker: {
          name: load.takerName,
          cnpj: load.takerCNPJ.replace(/\D/g, ''),
          is_international: load.is_international === '1',
        },
        negotiator: {
          name: load.negotiatorName,
          email: load.negotiatorEmail,
        },
        tags:
          load.tags !== '' ? load.tags.split(',').map(tag => tag.trim()) : [],
        vehicle_types: load.vehicleTypes.split(',').map(el => Number(el)),
        body_types: load.bodyTypes.split(',').map(el => Number(el)),
        risk_managers_cleareances: load.riskManagersCleareances.split(',').map(el => Number(el)),
        locations: {
          origins: [
            {
              address: load.originsAddress,
              neighborhood: load.originsNeighborhood,
              number: Number(load.originsNumber),
              complement: load.originsComplement,
              zip_code: load.originsZipCode,
              city: load.originsCity,
              province: load.originsProvince,
              scheduled_time: moment(
                `${load.originsScheduledDate} ${load.originsScheduledTime}`,
                'DD-MM-YYYY hh:mm:ss'
              )
                .utc()
                .format(),
              start_schedule: moment(
                `${load.startOriginScheduledDate} ${load.startOriginScheduledTime}`,
                'DD-MM-YYYY hh:mm:ss'
              )
                .utc()
                .toDate(),
            },
          ],
          destinations: [
            {
              address: load.destinationsAddress,
              neighborhood: load.destinationsNeighborhood,
              number: Number(load.destinationsNumber),
              complement: load.destinationsComplement,
              zip_code: load.destinationsZipCode,
              city: load.destinationsCity,
              province: load.destinationsProvince,
              scheduled_time: moment(
                `${load.destinationsScheduledDate} ${load.destinationsScheduledTime}`,
                'DD-MM-YYYY hh:mm:ss'
              )
                .utc()
                .format(),
              start_schedule: moment(
                `${load.startDestinationScheduledDate} ${load.startDestinationScheduledTime}`,
                'DD-MM-YYYY hh:mm:ss'
              )
                .utc()
                .toDate(),
            },
          ],
        },
      };

      return data;
    });

    await resolveSequentially(
      loadsPayload,
      setProgressBarVisible,
      setCompleted
    );

    handleCloseModal({ setLoadFile, closeModal, setError });
    showSnackbar(
      snackbar,
      `${loads.length} ${loads.length > 1 ? 'cargas criadas' : 'carga criada'
      } com sucesso!`,
      { type: 'success' }
    );
  } catch (err) {
    if (err.response && err.response.data) {
      setError([err.response.data.message]);
    } else {
      showSnackbar(
        snackbar,
        `Erro ao cadastrar carga${loads.length > 1 ? 's' : ''}`,
        { type: 'error' }
      );
    }
  } finally {
    setProgressBarVisible(false);
    setLoading(false);
    setLoadFile(null);
    setCompleted(1);
  }
}

async function validateImportedLoads({
  setProgressBarVisible,
  setCompleted,
  setLoadFile,
  setLoading,
  closeModal,
  setError,
  snackbar,
  loads,
}) {
  setCompleted(1);
  const message = [];

  try {
    await schema.validate(loads, { abortEarly: false });
  } catch (err) {
    err.inner.forEach(error =>
      message.push(
        `Linha ${Number(error.path.split('[')[1].split(']')[0]) + 1}: ${error.message
        }`
      )
    );
  }

  if (message.length > 0) {
    setError(message);
    setLoading(false);
    setLoadFile({});
  } else {
    createLoads({
      setProgressBarVisible,
      setCompleted,
      setLoadFile,
      setLoading,
      closeModal,
      snackbar,
      setError,
      loads,
    });
  }
}

export function importLoad({
  setProgressBarVisible,
  setCompleted,
  setLoadFile,
  setLoading,
  closeModal,
  loadFile,
  setError,
  snackbar,
}) {
  if (loadFile && loadFile[0] && loadFile[0].lastModified) {
    setError([]);
    setLoading(true);
    const reader = new FileReader();
    reader.readAsText(loadFile[0], 'UTF-8');
    reader.onload = function (evt) {
      const arrayCSV = evt.target.result.split(`\n`);

      const loads = arrayCSV
        .slice(1)
        .filter(load => load.replace(/[\r\n\t]/g, ''))
        .map(load => {
          const formattedLoad = load
            .replace(/[\r\n\t]/g, '')
            .split(';')
            .flatMap((field, index) => {
              if (field.charAt(0) === '"')
                return {
                  [Object.keys(csvColumns)[index]]: field.substr(
                    1,
                    field.length - 2
                  ),
                };
              return {
                [Object.keys(csvColumns)[index]]: field,
              };
            })
            .reduce((acc, value) => {
              return { ...acc, ...value };
            }, {});
          return { ...formattedLoad };
        });

      validateImportedLoads({
        setProgressBarVisible,
        setCompleted,
        setLoadFile,
        setLoading,
        closeModal,
        setError,
        snackbar,
        loads,
      });
    };
    reader.onerror = function () {
      // handle error
    };
  }
}
