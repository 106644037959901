import React from 'react';
import { Link } from 'react-router-dom';

import { Row, Col } from 'react-bootstrap';

import Text from 'v3/components/Text';
import Card from 'v3/components/Card';
import { HiOutlineExternalLink } from 'react-icons/hi';

function GrRecordCard({ user }) {
  function CardBody() {
    return (
      <Row>
        <Col xs={12}>
          <Text type="regular" color="black" weight={600}>
            Validação checklist:{' '}
            <Text color="dark_blue" weight={600}>
              {user.total_checklist_records || 0}
            </Text>
          </Text>
        </Col>
        <Row className="flex-grow-1 mb-4 ml-1">
          <Col xs={6}>
            <Text type="medium" color="dark" weight={500}>
              Aprovados:{' '}
              <Text color="success" weight={600}>
                {user.approved_checklist_records || 0}
              </Text>
            </Text>
          </Col>
          <Col xs={6}>
            <Text type="medium" color="dark" weight={500}>
              Reprovados:{' '}
              <Text color="error" weight={600}>
                {user.reproved_checklist_records || 0}
              </Text>
            </Text>
          </Col>
        </Row>
        <Col xs={12}>
          <Text type="regular" color="black" weight={600}>
            Validação GR:{' '}
            <Text color="dark_blue" weight={600}>
              {user.total_gr_records || 0}
            </Text>
          </Text>
        </Col>
        <Row className="flex-grow-1 mb-4 ml-1">
          <Col xs={6}>
            <Text type="medium" color="dark" weight={500}>
              Aprovados:{' '}
              <Text color="success" weight={600}>
                {user.approved_gr_records || 0}
              </Text>
            </Text>
          </Col>
          <Col xs={6}>
            <Text type="medium" color="dark" weight={500}>
              Reprovados:{' '}
              <Text color="error" weight={600}>
                {user.reproved_gr_records || 0}
              </Text>
            </Text>
          </Col>
        </Row>
        <Col xs={12} className="mb-3">
          <Text type="regular" color="black" weight={600}>
            Total de cadastros:{' '}
            <Text color="dark_blue" weight={600}>
              {user.total_records || 0}
            </Text>
          </Text>
        </Col>
      </Row>
    );
  }

  return (
    <Col xs={4}>
      <Card
        header={
          <Col xs={12}>
            <div className="mb-1">
              <Link to={`/usuarios/${user.id}`}>
                <Text weight={600} color="primary" type="subtitle">
                  {user?.username || ''}
                  <HiOutlineExternalLink size={24} className="ml-2" />
                </Text>
              </Link>
            </div>
            <div style={{ wordBreak: 'break-all' }}>
              <Text weight={500} color="gray" type="label">
                {user?.email}
              </Text>
            </div>
          </Col>
        }
      >
        <CardBody />
      </Card>
    </Col>
  );
}

export default GrRecordCard;
