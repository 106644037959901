/* eslint-disable import/no-unresolved */
import React, { useState, useEffect, useRef, useMemo } from 'react';
import moment from 'moment';
import { Row, Col } from 'react-bootstrap';
import { FaTrash, FaPencilAlt } from 'react-icons/fa';
import theme from 'v3/theme';
import { Button, Text, Card, Input, Select, Modal, Badge } from 'v3/components';
import Map from 'v3/components/Map';
import List from 'v3/components/BulletList';
import InputAddress from 'v3/components/InputAddress';
import { useSnackbar } from 'v3/components/Snackbar';
import MarkerLetter from 'v3/components/Map/MarkerLetter';
import { formatAddress } from 'v3/utils/formatter';
import { DELIVERY_TYPE_OPTIONS } from 'v3/utils/constants';
import { fetchCountries, fetchSelectOptions } from 'utils/fetches';
import { ModalLauncher } from 'v3/pages/Load/Details/Locale/styles';
import {
  deleteDestination,
  fetchProvinces,
  getDestinations,
  getOrigins,
  save,
  validate,
} from './utils';
import { validateDateTime } from 'v3/utils/functions';
import { useModal } from 'hooks';
import { checkClientAddressData } from '../../functions';
import { UpdateClientAddressData } from 'v3/pages/Client/shared/Modals/UpdateAddressData';

export default function LocaleData({
  preLoad,
  hasPermissionToEdit,
  loadId,
  loading,
  shouldDisableEditing,
  disabledTitle,
  getPreLoad,
}) {
  const snackbar = useSnackbar();
  const mapsRef = useRef(null);
  const mapRef = useRef(null);
  const initial_data = {
    country: {},
    address: '',
    formattedAddress: '',
    neighborhood: '',
    city: '',
    province: '',
    number: '',
    zipCode: '',
    complement: '',
    lat: '',
    lng: '',
    scheduledTime: '',
    startSchedule: '',
    loadId: loadId,
    type: 'destination',
    countrySlug: '',
    action: '',
  };

  const [origins, setOrigins] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const [location, setLocation] = useState(initial_data);
  const [errors, setErrors] = useState({});
  const [countryList, setCountryList] = useState([]);
  const [show, setShow] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [disable, setDisable] = useState(true);

  const [disableNumber, setDisableNumber] = useState(false);
  const [disableZipCode, setDisableZipCode] = useState(false);
  const [disableCity, setDisableCity] = useState(false);
  const [disableProvince, setDisableProvince] = useState(false);
  const [disableNeighborhood, setDisableNeighborhood] = useState(false);
  const updateClientAddressModal = useModal();
  const [clientCurrentAddress, setClientCurrentAddress] = useState(null);
  const [client, setClient] = useState(null);
  const [loadingLocale, setLoadingLocale] = useState(false);

  const locations = useMemo(
    () => [
      ...origins.map(origin => ({
        latitude: origin.lat,
        longitude: origin.lng,
      })),
      ...destinations.map(destination => ({
        latitude: destination.lat,
        longitude: destination.lng,
      })),
    ],
    [destinations, origins]
  );

  const getMapBounds = () => {
    const maps = mapsRef.current;
    if (maps) {
      const bounds = new maps.LatLngBounds();
      if (locations[0].latitude && locations[0].longitude) {
        locations.forEach(item => {
          bounds.extend(new maps.LatLng(item.latitude, item.longitude));
        });
      }

      return bounds;
    }

    return null;
  };

  async function fetchOptions() {
    try {
      const response = await fetchCountries();
      setCountryList(response);
    } catch (error) {
      setCountryList([]);
    }
  }

  async function setLocaleData() {
    setLoadingLocale(true);
    const processedOrigins = getOrigins(preLoad?.loadOrigins, countryList);
    setOrigins(processedOrigins);
    const processedDestinations = getDestinations(
      preLoad?.loadDestinations,
      countryList
    );
    setDestinations(processedDestinations);
    setLoadingLocale(false);
  }

  useEffect(() => {
    if (preLoad) {
      setLocaleData();
    }
  }, [preLoad]);

  useEffect(() => {
    fetchOptions();
  }, []);

  useEffect(() => {
    if (loadingDelete || loadingSave) {
      setDisable(loadingSave || loadingDelete);
    }
  }, [loadingSave, loadingDelete]);

  useEffect(() => {
    const bounds = getMapBounds();
    if (mapRef.current) {
      mapRef.current.fitBounds(bounds);
    }
  }, [destinations, origins]);

  const ordered = useMemo(() => {
    return {
      origins: origins.sort(
        (a, b) =>
          moment(a.scheduledTime, 'DD/MM/YYYY HH:mm').toDate().valueOf() -
          moment(b.scheduledTime, 'DD/MM/YYYY HH:mm').toDate().valueOf()
      ),
      destinations: destinations.sort(
        (a, b) =>
          moment(a.destinationDate, 'DD/MM/YYYY HH:mm').toDate().valueOf() -
          moment(b.destinationDate, 'DD/MM/YYYY HH:mm').toDate().valueOf()
      ),
    };
  }, [origins, destinations]);

  function disableAddress(address, city) {
    if (disable) {
      setDisableNumber(true);
      setDisableZipCode(true);
      setDisableCity(true);
      setDisableProvince(true);
      setDisableNeighborhood(true);
    } else {
      setDisableNumber(address?.number ? true : false);
      setDisableZipCode(address?.zip_code || address?.zipCode ? true : false);
      setDisableCity(city ? true : false);
      setDisableProvince(city?.province ? true : false);
      setDisableNeighborhood(address?.neighborhood ? true : false);
    }
  }

  function handleChange(value, key) {
    setLocation({ ...location, [key]: value });
  }

  function setDestinationCity(city, options) {
    let cityModel = city;
    if (options.action === 'create-option') {
      cityModel = {
        name: city.id,
        province: null,
      };
    }

    if (cityModel) {
      setLocation({
        ...location,
        cityModel,
        province: cityModel?.province?.uf,
      });
    } else {
      setLocation({
        ...location,
        cityModel: null,
        province: '',
      });
    }
  }

  async function handleLocationAddress(place) {
    const { address } = place;

    const [city] = await fetchProvinces(
      place.city,
      place.province,
      place.country_abbreviation
    );
    setLocation({
      ...location,
      address: address || '',
      formattedAddress: place.formatted_address,
      neighborhood: place.neighborhood || '',
      cityModel: city,
      province: city?.province?.uf || '',
      number: place.number || '',
      complement: place.name || '',
      zipCode: place.zip_code || '',
      lat: place.lat,
      lng: place.lng,
    });
    disableAddress(place, city);
  }

  function handleEditLocation(selected_location) {
    setShow(false);
    setLocation({
      ...selected_location,
      cityModel: selected_location.cityModel || {
        name: selected_location.city,
      },
    });
    if (!shouldDisableEditing) {
      setDisable(false);
      disableAddress(selected_location);
    }
  }

  function warning() {
    const scheduled_time = moment(location.scheduledTime, 'DD/MM/YYYY HH:mm');

    if (scheduled_time.isBefore(moment())) {
      snackbar.show(
        <Text>
          Esta carga não aparecerá no aplicativo pois a hora da coleta já
          passou.
        </Text>,
        { type: 'error', duration: 8000 }
      );
    }
  }

  async function handleRemoveDestination(destination) {
    if (destinations.length < 2) {
      snackbar.show(<Text>Carga deve ter pelo menos um destino</Text>, {
        type: 'error',
      });
      return;
    }

    setLoadingDelete(true);
    const status = await deleteDestination(
      destination?.id,
      destination?.loadId
    );
    if (status) {
      setDestinations(list => list.filter(item => destination.id !== item.id));
    } else {
      snackbar.show(<Text>Não foi possível excluir o destino</Text>, {
        type: 'error',
      });
    }
    setLoadingDelete(false);
  }

  function onGoogleAPILoaded(map, maps) {
    if (map) {
      mapRef.current = map;
      mapsRef.current = maps;

      setTimeout(() => {
        const bounds = getMapBounds();

        map.fitBounds(bounds);
      }, 100);
    }
  }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function handleSaveLocation() {
    const valid = validate(location, origins, destinations);
    if (valid?.hasError) {
      setErrors(valid);
      return;
    }
    setLoadingSave(true);
    const result = await save(location);
    setLoadingSave(false);
    if (result.success) {
      setErrors({});
      snackbar.show(<Text>{result.success}</Text>, {
        type: 'success',
      });
      if (location.type === 'origin') {
        const edited_origins = origins.map(item =>
          location.id === item.id ? { ...item, ...location } : item
        );
        warning();
        setOrigins(edited_origins);
      } else if (!location.id) {
        destinations.push({ ...location, id: result.data.id });
        setDestinations(destinations);
      } else {
        const edited_destinations = destinations.map(item =>
          location.id === item.id ? { ...item, ...location } : item
        );
        setDestinations(edited_destinations);
      }
      setLocation(initial_data);
      await getPreLoad();
    } else {
      snackbar.show(<Text>{result.error}</Text>, {
        type: 'error',
      });
    }
  }
  function locationsObject(showAllDestinations = true) {
    const destinationsToDisplay = showAllDestinations
      ? ordered.destinations
      : ordered.destinations.slice(-1);

    const items = [
      ...ordered.origins.map(item => ({
        header: (
          <div className="d-flex flex-row justify-content-between">
            <Text>{item.action.name}</Text>
            <div className="mb-1">
              <Button
                disabled={shouldDisableEditing}
                size="sm"
                onClick={() => handleEditLocation(item)}
                title={shouldDisableEditing ? disabledTitle : ''}
              >
                <FaPencilAlt />
              </Button>
            </div>
          </div>
        ),
        body: (
          <>
            {item?.client ? (
              <Text color="gray" type="regular">
                {`Cliente: ${item?.client?.socialName} - ${item?.client?.cpfCnpj}`}
              </Text>
            ) : null}
            <Text className="my-2" color="gray" type="regular">
              {formatAddress(item)}
            </Text>
            <Text color="gray" type="regular">
              {item.startSchedule &&
                `${moment(item.startSchedule).format('DD/MM/YYYY HH:mm')} até `}
              {moment(item.scheduledTime).format('DD/MM/YYYY HH:mm')}
            </Text>
          </>
        ),
        filledCircle: false,
      })),
    ];

    if (!showAllDestinations && ordered.destinations.length > 1)
      items.push({
        header: (
          <Text color="light_blue" type="regular">
            <ModalLauncher onClick={handleShow} className="">
              {ordered.destinations.length - 1} destinos
            </ModalLauncher>
          </Text>
        ),
        link: true,
      });

    items.push(
      ...destinationsToDisplay.map(item => ({
        header: (
          <div className="d-flex flex-row justify-content-between">
            <Text>{item.action.name}</Text>
            <div className="mb-1">
              <Button
                variant="error"
                size="sm"
                loading={loadingDelete}
                disabled={!hasPermissionToEdit || shouldDisableEditing}
                onClick={() => handleRemoveDestination(item)}
                className="mr-1"
                title={shouldDisableEditing ? disabledTitle : ''}
              >
                <FaTrash />
              </Button>
              <Button
                size="sm"
                onClick={() => handleEditLocation(item)}
                disabled={shouldDisableEditing || !hasPermissionToEdit}
                title={shouldDisableEditing ? disabledTitle : ''}
              >
                <FaPencilAlt />
              </Button>
            </div>
          </div>
        ),
        body: (
          <>
            {item?.client ? (
              <Text color="gray" type="regular">
                {`Cliente: ${item?.client?.socialName} - ${item?.client?.cpfCnpj}`}
              </Text>
            ) : null}
            <Text className="my-2" color="gray" type="regular">
              {formatAddress(item)}
            </Text>
            <Text color="gray" type="regular">
              {item.startSchedule &&
                `${moment(item.startSchedule).format('DD/MM/YYYY HH:mm')} até `}
              {moment(item.scheduledTime).format('DD/MM/YYYY HH:mm')}
            </Text>
          </>
        ),
        filledCircle: true,
      }))
    );

    return items;
  }

  function handleUpdateAddress(data){
    delete data.id;
    setLocation({ ...location, ...data, client:client });
  }

  function handleClientChange(event) {
    disableAddress({}, null);
    if (event !== null) {
      const currentAddress = event?.addresses.filter(
        address => address.current
      )[0];
      if (checkClientAddressData(currentAddress)) {
        snackbar.show(
          <Text>Endereço do cliente desatualizado! Complete o cadastro</Text>,
          { type: 'error' }
        );
        setClientCurrentAddress(currentAddress);
        setClient(event);
        return updateClientAddressModal.open();
      } else {
         setLocation({ ...location, 'client': event });
      }
    } else {
       setLocation({ ...location, 'client': event });
    }
  }

  return (
    <>
      <Modal
        scrollable
        show={show}
        onHide={handleClose}
        heading={<Text>Origem / Destinos</Text>}
        body={
          <Row>
            <Col xs={12} className="mt-4 pl-4">
              <List items={locationsObject()} />
            </Col>
          </Row>
        }
      />
      <Col xs={12}>
        <UpdateClientAddressData
          show={updateClientAddressModal.isOpen}
          onSubmit={handleUpdateAddress}
          onClose={() => updateClientAddressModal.close()}
          currentAddressData={clientCurrentAddress}
          countryOptions={countryList}
        />
        <Card
          loading={loadingLocale || loading}
          header={
            <Text color="#464E5F" type="header">
              Origem / Destino
            </Text>
          }
        >
          <Row>
            <Col lg={6} md={6} xs={12}>
              <Row className="form">
                <Col xs={12} className="mb-3">
                  <Select
                    label="País *"
                    options={countryList}
                    value={location.country}
                    onChange={country => {
                      handleChange(country, 'country');
                    }}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                    error={errors.country}
                    isDisabled={disable}
                  />
                </Col>
                <Col xs={12} className="mb-3">
                  <Select.Async
                    label="Cliente Tomador"
                    onSearch={search =>
                      fetchSelectOptions('persons/customers', { search })
                    }
                    value={location?.client}
                    getOptionLabel={option => {
                      let label = `${
                        option?.social_name || option?.socialName
                      } - ${option?.cgccpf || option?.cpfCnpj}`;

                      if (option.city) {
                        label += ` - ${option?.city}`;
                      }
                      return label;
                    }}
                    getOptionValue={option => option?.id}
                    onChange={value => {
                      handleClientChange(value);
                    }}
                    isDisabled={disable}
                  />
                </Col>
                <Col xs={8} className="mb-3">
                  <InputAddress
                    label="Logradouro *"
                    placeholder=""
                    value={location.address}
                    onSelectedAddress={handleLocationAddress}
                    onChange={event => {
                      setLocation(old => ({
                        ...old,
                        address: event?.target?.value,
                        number: '',
                        complement: '',
                        neighborhood: '',
                        zipCode: '',
                        lat: '',
                        lng: '',
                        cityModel: '',
                        province: '',
                      }));
                    }}
                    types={[]}
                    error={errors.address}
                    country={
                      location.country ? location.country.abbreviation : null
                    }
                    disabled={disable}
                  />
                </Col>
                <Col xs={4} className="mb-3">
                  <Input
                    label="Número"
                    value={location.number}
                    onChange={event =>
                      handleChange(event.target.value, 'number')
                    }
                    disabled={disableNumber || disable}
                  />
                </Col>
                <Col xs={4} className="mb-3">
                  <Input
                    label="Complemento"
                    value={location.complement}
                    onChange={event =>
                      handleChange(event.target.value, 'complement')
                    }
                    disabled={disable}
                  />
                </Col>
                <Col xs={4} className="mb-3">
                  <Input
                    label="Bairro"
                    value={location.neighborhood}
                    onChange={event =>
                      handleChange(event.target.value, 'neighborhood')
                    }
                    disabled={disableNeighborhood || disable}
                  />
                </Col>
                <Col xs={4} className="mb-3">
                  <Input
                    label="CEP"
                    value={location.zipCode}
                    onChange={event =>
                      handleChange(event.target.value, 'zipCode')
                    }
                    error={errors.zipCode}
                    disabled={disableZipCode || disable}
                  />
                </Col>
                <Col xs={8} className="mb-3">
                  <Select.Async
                    label="Cidade *"
                    onSearch={city =>
                      fetchProvinces(city, '', location.country?.abbreviation)
                    }
                    value={location.cityModel}
                    horizontal
                    creatable
                    onChange={setDestinationCity}
                    getOptionLabel={option =>
                      `${`${option.name} ${
                        option.province?.uf ? `- ${option.province?.uf}` : ''
                      }`}`
                    }
                    getOptionValue={option =>
                      `${`${option.name} ${
                        option.province?.uf ? `- ${option.province?.uf}` : ''
                      }`}`
                    }
                    error={errors.cityModel}
                    isDisabled={disableCity || disable}
                  />
                </Col>
                <Col xs={4} className="mb-3">
                  <Input
                    label="UF *"
                    value={location.province}
                    onChange={event =>
                      handleChange(event.target.value, 'province')
                    }
                    error={errors.province}
                    disabled={disableProvince || disable}
                  />
                </Col>
                <Col md={6} className="mt-2">
                  <Input
                    label="Latitude"
                    disabled={true}
                    value={location?.lat}
                    error={errors.lat}
                    tooltip="Selecione um endereço sugerido para obter dados de latitude e longitude"
                  />
                </Col>
                <Col md={6} className="mt-2">
                  <Input
                    label="Longitude"
                    disabled={true}
                    value={location?.lng}
                    error={errors.lng}
                    tooltip="Selecione um endereço sugerido para obter dados de latitude e longitude"
                  />
                </Col>
                <Col xs={6} className="mb-3">
                  <Input
                    placeholder="Digite uma data"
                    label={
                      location.type === 'destination'
                        ? 'Data Início da entrega *'
                        : 'Data Início da coleta *'
                    }
                    value={location.startSchedule}
                    onChange={event => {
                      let isValid = validateDateTime(event?.target?.value);
                      if (isValid) {
                        handleChange(event.target.value, 'startSchedule');
                        setErrors(old => ({ ...old, startSchedule: null }));
                      } else {
                        setErrors(old => ({
                          ...old,
                          startSchedule: 'Data inválida',
                        }));
                        handleChange(event.target.value, 'startSchedule');
                      }
                    }}
                    type="datetime-local"
                    error={errors.startSchedule}
                    disabled={disable}
                  />
                </Col>
                <Col xs={6} className="mb-3">
                  <Input
                    type="datetime-local"
                    placeholder="Digite uma data"
                    label={
                      location.type === 'destination'
                        ? 'Data máxima da entrega *'
                        : 'Data máxima da coleta *'
                    }
                    value={location.scheduledTime}
                    onChange={event => {
                      let isValid = validateDateTime(event?.target?.value);
                      if (isValid) {
                        handleChange(event.target.value, 'scheduledTime');
                        setErrors(old => ({ ...old, scheduledTime: null }));
                      } else {
                        setErrors(old => ({
                          ...old,
                          scheduledTime: 'Data inválida',
                        }));
                        handleChange(event.target.value, 'scheduledTime');
                      }
                    }}
                    error={errors.scheduledTime}
                    disabled={disable}
                  />
                </Col>
                <Col xs={6} className="mb-3">
                  <Select
                    label="Ação *"
                    value={location.action}
                    onChange={event => handleChange(event, 'action')}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                    error={errors.action}
                    options={DELIVERY_TYPE_OPTIONS}
                    isDisabled={location.type === 'origin' || disable}
                  />
                </Col>
                <Col
                  xs={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '0.5rem',
                    flexWrap: 'wrap',
                  }}
                >
                  <Button
                    className="mt-3"
                    variant={'secondary'}
                    onClick={() => {
                      setLocation(initial_data);
                      setDisable(true);
                    }}
                    loading={loadingSave}
                    disabled={
                      disable || !hasPermissionToEdit || shouldDisableEditing
                    }
                  >
                    <Text weight={500} type="regular">
                      Cancelar
                    </Text>
                  </Button>
                  <Button
                    className="mt-3"
                    onClick={handleSaveLocation}
                    loading={loadingSave}
                    disabled={
                      disable || !hasPermissionToEdit || shouldDisableEditing
                    }
                  >
                    <Text weight={500} type="regular">
                      Salvar
                    </Text>
                  </Button>
                </Col>
                {errors.dateError && (
                  <Col xs={12} className="text-center">
                    <Text type="label" color="#f64e60">
                      {errors.dateError}
                    </Text>
                  </Col>
                )}
              </Row>
            </Col>

            <Col lg={6} md={6} xs={12}>
              <div className="container-fluid load-adresses list-maps pt-3 pb-5">
                <Row className="form">
                  <Col xs={12}>
                    {locations.length > 0 && (
                      <Map
                        height={200}
                        yesIWantToUseGoogleMapApiInternals
                        position={{
                          lat: locations[0].latitude,
                          lng: locations[0].longitude,
                        }}
                        onGoogleApiLoaded={({ map, maps }) =>
                          onGoogleAPILoaded(map, maps)
                        }
                      >
                        {origins.length > 0 &&
                          origins.map(origin => (
                            <MarkerLetter
                              background={theme.colors.light_blue}
                              lat={origin.lat}
                              lng={origin.lng}
                              color="#fff"
                              letter="C"
                            />
                          ))}
                        {destinations.length > 0 &&
                          destinations.map(destination => (
                            <MarkerLetter
                              background={theme.colors.light_blue}
                              lat={destination.lat}
                              lng={destination.lng}
                              color="#fff"
                              letter={
                                destination.action.id === 'delivery' ? 'E' : 'A'
                              }
                            />
                          ))}
                      </Map>
                    )}
                  </Col>
                  {origins.length === 0 && destinations.length === 0 && (
                    <Col xs={12} className="text-center">
                      <Badge type="warning" outlined className="rounded">
                        Essa carga ainda nao possui origem e nem destino.
                      </Badge>
                    </Col>
                  )}
                  <Col xs={12} className="pt-3">
                    <List items={locationsObject(false)} />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Card>
      </Col>
    </>
  );
}
