import React, { useState, useEffect, useMemo } from 'react';

import { cooperplaceApi } from 'services/api';
import { usePermission, useQuery } from 'hooks';
import theme from 'v3/theme';

import ApplicationLayout from 'v3/layouts/Application';
import { DataTable, Fallback } from 'v3/components';

import { Text, Button } from 'v3/components';
import { useSnackbar } from 'v3/components/Snackbar';

import SecurityProfileFilter from './Filter';
import { validatePermission } from 'actions';
import { Col } from 'react-bootstrap';
import Space from 'v3/components/Space';

export default function SecurityProfileList() {
  usePermission('VISUALIZAR_PERFIL_SEGURANCA', {
    redirect: true,
  });
  const snackbar = useSnackbar();
  const hasPermissionToImportFromBenner = validatePermission(
    'IMPORTAR_PERFIL_SEGURANCA'
  );

  const queryParams = useQuery();
  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingImport, setLoadingImport] = useState(false);
  const [loadingImportRaster, setLoadingImportRaster] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);

  async function handleImport(font = 'benner') {
    try {
      if (font === 'benner') {
        setLoadingImport(true);
        await cooperplaceApi.get('security-profiles/importFromBenner');
      } else {
        setLoadingImportRaster(true);
        await cooperplaceApi.get('security-profiles/raster');
      }
      snackbar.show(<Text>Perfis importados com sucesso</Text>, {
        type: 'success',
      });
      await fetchData();
    } catch (error) {
      snackbar.show(
        <Text>Não foi possível importar os perfis de segurança</Text>,
        {
          type: 'error',
        }
      );
    } finally {
      setLoadingImport(false);
      setLoadingImportRaster(false);
    }
  }

  async function fetchData() {
    try {
      setLoading(true);
      const response = await cooperplaceApi.get(
        `security-profiles/securityProfilesByCodeNumberOrName?${queryParams}`
      );
      const { data } = response;
      setProfiles(data);
    } catch (error) {
      setProfiles([]);
      snackbar.show(
        <Text>Não foi possível recuperar os perfis de segurança.</Text>,
        {
          type: 'error',
        }
      );
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchData();
  }, []);

  const profilesData = useMemo(() => {
    const offset = (page - 1) * perPage;
    let profilesToDisplay = profiles.slice(offset, perPage + offset);
    if (profilesToDisplay === 0 && page > 1) setPage(page - 1);
    return profilesToDisplay;
  }, [profiles, page, perPage]);

  return (
    <>
      <ApplicationLayout
        title="Perfis de Segurança"
        RightComponent={
          hasPermissionToImportFromBenner ? (
            <Space x={'0.5rem'}>
              <Button
                variant="secondary"
                onClick={() => {
                  handleImport();
                }}
                className="ml-2 mr-2"
                loading={loadingImport}
                disabled={loadingImportRaster}
              >
                <Text color="dark" weight={500} type="regular">
                  Importar do Benner
                </Text>
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  handleImport('raster');
                }}
                className="ml-2 mr-2"
                loading={loadingImportRaster}
                disabled={loadingImport}
              >
                <Text color="dark" weight={500} type="regular">
                  Importar da Raster
                </Text>
              </Button>
            </Space>
          ) : null
        }
      >
        <SecurityProfileFilter
          fetchData={fetchData}
          loadingImport={loadingImport}
          loadingImportRaster={loadingImportRaster}
        />
        <Fallback
          fall={profiles?.length === 0}
          component={
            <Col
              xs={12}
              className="pt-5 d-flex justify-content-center align-items-center"
            >
              <Button colors={theme.colors.primary} size="md" disabled>
                Não há itens a serem exibidos
              </Button>
            </Col>
          }
        >
          <Col className="my-4">
            <DataTable
              data={profilesData}
              loading={loading || loadingImport}
              total={profiles?.length}
              pages={Math.ceil(profiles?.length / perPage)}
              currentPage={page}
              onPageChanged={setPage}
              onPerPageChanged={v => setPerPage(+v)}
              columns={[
                {
                  name: 'Código',
                  selector: row => row?.code,
                },
                { name: 'Nome', selector: row => row?.name },
              ]}
              withFilterOption={false}
            />
          </Col>
        </Fallback>
      </ApplicationLayout>
    </>
  );
}
