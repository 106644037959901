import React from 'react';

import Label from 'components/Label';

import { InputMask } from './styles';

function Input({ name, label, error, ...props }) {
  return (
    <>
      <Label htmlFor={name} className="textLabel">
        {label}
      </Label>
      <InputMask id={name} name={name} error={error} {...props} />
    </>
  );
}

export { default as InputMaskCurrency } from './Currency';

export default Input;
