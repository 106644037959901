import React, { useState, useEffect } from 'react';

import api from 'services/api';
import { validatePermission } from 'actions/index';
import useQuery from 'hooks/useQuery';

import ApplicationLayout from 'v3/layouts/Application';
import { useSnackbar } from 'v3/components/Snackbar';
import InfiniteScroll from 'v3/components/InfiniteScroll';
import Text from 'v3/components/Text';
import Link from 'v3/components/Link';
import Button from 'v3/components/Button';

import Card from '../../Card';
import Filter from '../../Components/Filter';

export default function BlacklistList() {

  const snackbar = useSnackbar();
  const query = useQuery();
  const [loading, setLoading] = useState(true);
  const [blacklistItems, setBlacklistItems] = useState([]);
  const [pagination, setPagination] = useState({
    lastPage: 1,
    page: 1,
    total: 0,
  });
  const [hasPermissionToCreate, setHasPermissionToCreate] = useState(false);


  async function loadBlacklistItems(page = 1) {
    try {
      const response = await api.get(`v3/blacklist-person?${query}&page=${page}`);
      const { data, ...paginationInfo } = response.data;

      setBlacklistItems(oldData => {
        if(page === 1) return data;
        return [...oldData, ...data]
      });
      setPagination(paginationInfo);

    } catch {
      snackbar.show(
        <Text>Algo deu errado, tentar novamente mais tarde</Text>,
        { type: 'error' }
      );
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadBlacklistItems();
    setHasPermissionToCreate(validatePermission('CADASTRAR_BLACKLIST'));
  }, []);

  function renderItem(item) {
    return <Card blacklistItem={item} />;
  }

  return (
    <ApplicationLayout title="Blacklist"
      RightComponent={
          hasPermissionToCreate && (
              <Link withoutIcon="true" to="/blacklist/cadastrar">
                <Button variant="primary">
                  <Text weight={500} color="white" type="regular">
                    Cadastrar
                  </Text>
                </Button>
              </Link>
          )
      }
    >
      <Filter onChange={() => loadBlacklistItems()} />
      <InfiniteScroll
        loading={loading}
        data={blacklistItems}
        renderItem={renderItem}
        onEndReach={() => loadBlacklistItems(pagination.page + 1)}
        hasMore={pagination.page < pagination.lastPage}
        scrollThreshold={0.7}
      />
    </ApplicationLayout>
  );
}
