import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import axiosRetry from 'axios-retry';
import axios from 'axios';

import InfoMessage from 'components/InfoMessage';
import Sidebar from 'components/Sidebar';
import InfiniteScroll from 'components/InfiniteScroll';
import Button from 'components/Button';
import Input from 'components/Input';
import Snackbar from 'components/Snackbar';
import api from 'services/api';
import { validatePermission } from 'actions/index';
import { usePermission } from 'hooks';
import CardFinanceiro from './CardFinanceiro';
import FilterFinanceiro from './FilterFinanceiro';

axiosRetry(axios, { retries: 10 });

export default function Financeiro() {
  const location = useLocation();
  const history = useHistory();

  const [filter, setFilter] = useState('');
  const [lastFilter, setLastFilter] = useState('');
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [loadingImport, setLoadingImport] = useState(false);
  const [errorInputInvoice, setErrorInputInvoice] = useState(false);
  const [snackbar, setSnackbar] = useState({
    display: false,
    type: 'info',
    message: '',
  });
  const sidebarImport = useRef(null);
  const scrollRef = useRef();
  usePermission('VISUALIZAR_FINANCEIRO', { redirect: true });

  const OpenImport = () => {
    setInvoiceNumber('');
    setErrorInputInvoice(false);
    sidebarImport.current.toggle();
  };

  useEffect(() => {
    setLastFilter(filter);
  }, [filter]);

  const openAdicionarFatura = () => {
    history.push(`/faturas/cadastrar`, true);
  };

  useEffect(() => {
    location.search && setFilter(location.search.substr(1));
  }, [location.search]);

  async function onSubmitImport(e) {
    e.preventDefault();
    try {
      if (invoiceNumber === '') {
        setErrorInputInvoice(true);
        return;
      }
      setLoadingImport(true);
      const res = await api.get(
        `benner-invoices?invoice_number=${invoiceNumber}`
      );

      if (res.data && res.data.success) {
        setSnackbar({
          display: true,
          type: res.data.qty === 0 ? 'warning' : 'success',
          message:
            res.data.qty === 0
              ? `Não foi encontrado Ctes referente a fatura: ${invoiceNumber}`
              : `Fatura ${invoiceNumber} importada!`,
        });
        sidebarImport.current.toggle();
      } else if (res.data && res.data.updates) {
        setSnackbar({
          display: true,
          type: 'success',
          message: `A fatura: ${invoiceNumber} foi atualizada!`,
        });
        sidebarImport.current.toggle();
      } else {
        setSnackbar({
          display: true,
          type: 'warning',
          message: `Não foi encontrado Ctes referente a fatura: ${invoiceNumber}`,
        });
      }
    } catch (e) {
      setSnackbar({
        display: true,
        type: 'error',
        message: 'Erro ao importar Fatura',
      });
    }
    setLoadingImport(false);
  }

  function updateFinancial(updated) {
    scrollRef.current.setData(oldData =>
      oldData.map(item => {
        if (item.id === updated.id) {
          return { ...item, approved_at: updated.approved_at };
        }
        return item;
      })
    );
  }

  return (
    <>
      <Snackbar
        open={snackbar.display}
        type={snackbar.type}
        message={snackbar.message}
        onClose={() =>
          setSnackbar({
            display: false,
            type: '',
            message: '',
          })
        }
      />
      <Grid item xs={12}>
        <InfiniteScroll
          ref={scrollRef}
          endpoint="billing-travel-data/invoices"
          nested="financial"
          filter={lastFilter}
          placeholderHeight={165}
          renderItem={(item, index) => (
            <CardFinanceiro
              key={index}
              financial={item}
              updateFinancial={updateFinancial}
            />
          )}
          initialMessage={
            <Grid
              container
              item
              xs={12}
              justify="center"
              style={{ marginTop: '20px' }}
            >
              <InfoMessage
                icon="arrow-right"
                width="auto"
                snackColor="#8c8c8c"
                message="Preencha os filtros para visualizar as informações."
              />
            </Grid>
          }
          endMessage={
            <Grid
              container
              item
              xs={12}
              justify="center"
              style={{ marginTop: '20px' }}
            >
              <InfoMessage
                icon="doneall"
                width="auto"
                snackColor="#8c8c8c"
                message="Não há mais itens a serem exibidos."
              />
            </Grid>
          }
        />
      </Grid>
      <Sidebar
        title="Filtro"
        init
        extraButtons={
          validatePermission('IMPORTAR_FATURA_BENNER') && [
            {
              icon: 'fa-file-invoice-dollar',
              action: OpenImport,
              title: 'Importar fatura',
            },
            validatePermission('CADASTRAR_FATURA') && {
              icon: 'fas fa-plus',
              action: openAdicionarFatura,
              title: 'Cadastrar fatura',
            },
          ]
        }
      >
        <FilterFinanceiro getFinancas={value => setFilter(value)} />
      </Sidebar>

      <Sidebar
        ref={sidebarImport}
        closeButton
        iconInit={false}
        title="Importar Fatura do benner"
      >
        <form onSubmit={onSubmitImport}>
          <Input
            label="Número Fatura"
            value={invoiceNumber}
            error={errorInputInvoice}
            placeholder="Digite o número da fatura"
            onChange={e => {
              setErrorInputInvoice(false);
              setInvoiceNumber(e.target.value);
            }}
          />
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '15px',
            }}
          >
            <Button type="submit" loading={loadingImport}>
              Importar
            </Button>
          </div>
        </form>
      </Sidebar>
    </>
  );
}
