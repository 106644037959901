import api from '../..';

export async function fetchEventsByDriver({
  document,
  start,
  end,
  perPage = 10,
}) {
  const response = await api.get('driver/journey', {
    params: { document, start, end, perPage },
  });

  return response.data;
}
