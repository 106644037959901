import styled from 'styled-components';
import theme from 'v3/theme';
import { FaQuestionCircle } from 'react-icons/fa';
export const QuestionIcon = styled(FaQuestionCircle)`
  position: absolute;
  right: 10px;
  top: 15px;
  color: ${theme.colors.light_blue};
  z-index: 5;
  cursor: help;
`;

export const ButtonItem = styled.div`
 padding: 20px;
 z-index: 998;
 position: fixed;
 top: 60px;
 text-align: right;
 right: 170px;
 border-bottom-left-radius: 5px;
 border-bottom-right-radius: 5px;
 > button:first-of-type {
 margin-right: 10px;  }
`;

export const Styled = styled.div`
  margin-top: 20px;
  cursor: pointer;
`;
