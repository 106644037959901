import styled from 'styled-components';
import Select from 'v3/components/Select';

export const TransparentSelect = styled(Select)`
  && {
    max-width: 200px;
    margin: 0px 5px;
  }

  .select-control {
    background-color: transparent;
  }
`;
