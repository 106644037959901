import { cnpj } from 'cpf-cnpj-validator';

const Yup = require('yup');

const schema = Yup.object().shape({
  name: Yup.string().required('Nome obrigatório'),
  document: Yup.string()
    .test('invalid-document', 'Documento inválido', document =>
      document ? cnpj.isValid(document) : false
    )
    .required('Documento obrigatório'),
  ie: Yup.string().required('Inscrição estadual obrigatória'),
  trading_name: Yup.string().required('Nome fantasia obrigatório.'),
  rntrc: Yup.string()
    .matches(/[0-9]{8}/, {
      message: 'RNTRC precisa ser numérico e no minimo 8 dígitos.',
    })
    .required('RNTRC obrigatório.'),
  phone: Yup.string().required('Telefone obrigatório'),
});

export default schema;
