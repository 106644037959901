/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import api from 'services/api';
import Fallback from 'v3/components/Fallback';
import Text from 'v3/components/Text';
import Card from 'v3/components/Card';

import theme from 'v3/theme';
import { useSnackbar } from 'v3/components/Snackbar';
import { useTravel } from 'v3/pages/Travel';
import { shippingCompanyDocumentTypesInBrazilianPortuguese } from 'v3/utils/documentTypes';
import * as Styled from './styles';
import { Attachment } from './Attachment';

export function TravelShippingCompanyAttachments() {
  const travel = useTravel();
  const snackbar = useSnackbar();
  const [attachments, setAttachments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [shippingCompanyId, setShippingCompanyId] = useState(0);
  const [isMissingAttachment, setIsMissingAttachment] = useState(false);
  const [attachmentsTypes, setAttachmentsTypes] = useState([]);

  async function fetchAttachments() {
    try {
      setLoading(true);
      const response = await api.get(
        `v3/shipping-company/${shippingCompanyId}/attachments`
      );
      setAttachments(response.data);
    } catch (error) {
      snackbar.show(
        'Não foi possível recuperar documentos anexados para transportadora',
        { type: 'error' }
      );
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    if (attachments) {
      attachments.forEach(document => {
        setAttachmentsTypes(old => [...old, document.type]);
      });
    }
  }, [attachments]);

  useEffect(() => {
    if (attachmentsTypes.length > 0) {
      const aux = new Set(attachmentsTypes);
      if (aux.size < 3) {
        setIsMissingAttachment(true);
      }
    }
  }, [attachmentsTypes]);

  useEffect(() => {
    if (travel?.data?.shippingCompany) {
      setShippingCompanyId(travel.data.shippingCompany);
    }
  }, [travel]);

  useEffect(() => {
    if (shippingCompanyId !== 0) {
      fetchAttachments();
    }
  }, [shippingCompanyId]);

  return (
    <>
      <Card
        header={
          <Text color="#464E5F" type="header">
            Anexos da Transportadora
          </Text>
        }
        loading={loading || travel.isLoading}
      >
        <>
          <Fallback
            component={
              <Col
                xs={12}
                className="align-items-center d-flex flex-column my-2"
              >
                <Text color={theme.colors.gray}>
                  Não há anexos cadastrados para a transportadora.
                </Text>
              </Col>
            }
            fall={!attachments?.length}
          >
            <Row
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              <>
                <Styled.UlItem>
                  {attachments?.map(attachment => {
                    return (
                      <Col
                        xs={12}
                        className="d-flex flex-column"
                        key={attachment?.created_at}
                      >
                        <Attachment
                          attachment={attachment}
                          attachments={attachments}
                        />
                      </Col>
                    );
                  })}
                  {isMissingAttachment ? (
                    <Text type="regular" className="mt-4 mb-4" id="text">
                      Atenção, há documentos faltando! Os seguintes documentos
                      são necessários para a transportadora:
                      {Object.values(
                        shippingCompanyDocumentTypesInBrazilianPortuguese
                      ).map((document, index) => {
                        return (
                          <span key={document}>
                            {' '}
                            {document}
                            {index <
                            Object.values(
                              shippingCompanyDocumentTypesInBrazilianPortuguese
                            ).length -
                              1
                              ? ','
                              : ''}
                          </span>
                        );
                      })}
                    </Text>
                  ) : null}
                </Styled.UlItem>
              </>
            </Row>
          </Fallback>
        </>
      </Card>
    </>
  );
}
