import React from 'react';

import StyledFab from './styles';

function Button({ color = 'primary', children, loading, ...rest }) {
  return (
    <StyledFab color={color} {...rest}>
      {children}
    </StyledFab>
  );
}

export default Button;
