import React, { useEffect, useState } from 'react';

import { useLocation, useHistory } from 'react-router-dom';

import Select from 'react-select';
import AsyncSelect from 'react-select/async';

import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';

import Label from 'components/Label';
import DatePicker from 'components/DatePicker';
import Button from 'components/Button';
import api from 'services/api';
import moment from 'moment';

import { validatePermission } from 'actions/index';
import { useEffectThrottled } from 'hooks';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ClientSelector({
  onClientSelected,
  onListReports,
  pathname,
  buttonName = 'Filtrar Viagens',
  loading: dataLoading,
  hideDatePicker,
  hasClient = true,
  hasCostCenter = false,
  hasUser = false,
  hasTags = false,
  ...props
}) {
  const hasAllCompanies = validatePermission('VISUALIZAR_TODAS_AS_EMPRESAS');

  const history = useHistory();
  const query = useQuery();
  const [clients, setClients] = useState([]);
  const [startDate, setStartDate] = useState(
    moment(query.get('start_date')).isValid()
      ? query.get('start_date')
      : moment().startOf('month').format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState(
    moment(query.get('end_date')).isValid()
      ? query.get('end_date')
      : moment().endOf('month').format('YYYY-MM-DD')
  );
  const [selectedCompany, setSelectedCompany] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [selectedTags, setSelectedTags] = useState();
  const [companies, setCompanies] = useState([]);
  const [checked, setChecked] = useState([]);
  const [values, setValues] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (query.get('companies'))
      setChecked(
        query
          .get('companies')
          .split(',')
          .map(item => Number(item))
      );
  }, [query.get('companies')]);

  useEffect(() => {
    getClients();
    getCompanies();
  }, []);

  useEffect(() => {
    if (clients) {
      setValues(clients.filter(client => clients.includes(client.id)));
    }
  }, [clients]);

  useEffect(() => {
    if (clients.length && checked.length) {
      setValues(clients.filter(client => checked.includes(client.id)));
    }
  }, [checked, clients]);

  useEffect(() => {
    onClientSelected(clients.filter(client => checked.includes(client.id)));
  }, [values]);

  function handleSelectedCostCenters(selected) {

    if (selected) {
      const mapped = selected.map(item => item.id);
      query.set('cost_center_id', mapped.join(','));
    } else {
      query.set('cost_center_id', '');
    }
    setupQueryParams();
  }

  function handleSelect(selected) {
    setValues(selected);

    if (selected) {
      const mapped = selected.map(item => item.id);
      setChecked(mapped);
      query.set('companies', mapped.join(','));
    } else {
      query.set('companies', '');
      setChecked([]);
    }
    setupQueryParams();
  }

  useEffectThrottled(
    () => {
      query.delete('tags');

      if (selectedTags?.length) {
        selectedTags.forEach(tag => {
          query.append('tags', tag.id);
        });

        setupQueryParams();
      }
    },

    [selectedTags],
    1000
  );

  useEffectThrottled(
    () => {
      if (selectedUser?.id) {
        query.set('user_id', selectedUser?.id);

        setupQueryParams();
      }
    },
    [selectedUser?.id],
    1000
  );

  useEffect(() => {
    if (!startDate) query.delete('start_date');
    else query.set('start_date', moment(startDate).format('YYYY-MM-DD'));
    setupQueryParams();
  }, [startDate]);

  useEffect(() => {
    if (!endDate) query.delete('end_date');
    else query.set('end_date', moment(endDate).format('YYYY-MM-DD'));
    setupQueryParams();
  }, [endDate]);

  useEffect(() => {
    if (!selectedCompany || !hasAllCompanies) {
      query.delete('company_id');
    } else {
      query.set('company_id', selectedCompany.id);
    }
    setupQueryParams();
  }, [selectedCompany]);

  function setupQueryParams() {
    history.push({ pathname: props.url, search: query.toString() });
  }

  async function getClients() {
    setLoading(true);
    const usuario = localStorage.getItem('usuario');
    const jsonU = JSON.parse(usuario);
    const response = await api.get(`clients/user/${jsonU.id}`);
    if (response.status === 200) {
      const clientsAux = response.data.clients;
      const clitentsIds = clientsAux.map(cli => cli.id);

      setClients(clientsAux);
      setChecked(clitentsIds);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }

  async function getCompanies() {
    try {
      setLoading(true);
      const response = await api.get('company');
      setCompanies(response.data);
      setSelectedCompany(
        hasAllCompanies
          ? response.data[0]
          : JSON.parse(localStorage.getItem('usuario')).company
      );
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  function handleSelectCompany(selected) {
    setSelectedCompany(selected);
  }

  useEffect(() => {
    if (clients) {
      const mapped = clients.map(item => item.id);
      setChecked(mapped);
      query.set('companies', mapped.join(','));
      setupQueryParams();
    } else {
      query.set('companies', '');
      setChecked([]);
      setupQueryParams();
    }
  }, [clients]);

  useEffect(() => {
    if (selectedUser) {
      query.set('user_id', selectedUser.id);
      setupQueryParams();
    } else {
      query.set('user_id', '');
      setupQueryParams();
    }
  }, [selectedUser]);

  useEffect(() => {
    if (selectedTags?.length) {
      const mapped = selectedTags?.map(item => item.id);
      query.set('tags', mapped?.join(','));
      setupQueryParams();
    } else {
      query.set('tags', '');
      setupQueryParams();
    }
  }, [selectedTags]);

  function validateDate() {
    if (!moment(startDate).isValid()) {
      props.setSnackBar({
        display: true,
        type: 'error',
        message: 'Período "de" inválido.',
      });
      return;
    }
    if (!moment(endDate).isValid()) {
      props.setSnackBar({
        display: true,
        type: 'error',
        message: 'Período "até" inválido.',
      });
      return;
    }
    setupQueryParams();

    onListReports(
      values?.map(v => v.id),
      startDate,
      endDate,
      1
    );
  }

  async function fetchCostCenters(search) {
    try {
      const response = await api.get(`v3/cost-center`, {
        params: { search },
      });

      return response.data;
    } catch (ex) {
      return [];
    }
  }

  async function fetchTags(search) {
    if (search.length >= 2) {
      const response = await api.get('tags', {
        params: { search, not_paginated: true },
      });

      return response.data;
    }

    return [];
  }

  async function fetchUsers(search) {
    if (search.length >= 3) {
      const response = await api.get('user/select', {
        params: { search },
      });

      return response.data;
    }

    return [];
  }

  return (
    <>
      {loading && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Skeleton
              variant="rect"
              width="100%"
              height={80}
              style={{ marginBottom: '10px', marginLeft: '10px' }}
            />
          </Grid>
          <Grid item xs={6}>
            <Skeleton
              variant="rect"
              width="100%"
              height={80}
              style={{ marginBottom: '10px', marginLeft: '10px' }}
            />
          </Grid>
        </Grid>
      )}

      {!loading && (
        <>
          <div hidden={hideDatePicker}>
            <Grid item xs={12}>
              <Label htmlFor="from" className="textLabel">
                Período de / até
              </Label>
              <DatePicker
                name="from"
                direction="left"
                format="DD/MM/YYYY"
                style={{ width: '50%', padding: 0 }}
                value={startDate}
                onChange={(date, rawValue) => {
                  if (rawValue) {
                    if (rawValue.replace(/\D/g, '').length === 8) {
                      if (date.isValid())
                        setStartDate(date.format('YYYY-MM-DD'));
                      else {
                        setStartDate('');
                        props.setSnackBar({
                          display: true,
                          type: 'error',
                          message: 'Período "de" inválido.',
                        });
                      }
                    }
                  }
                }}
              />
              <DatePicker
                direction="right"
                format="DD/MM/YYYY"
                style={{ width: '50%', padding: 0 }}
                value={endDate}
                onChange={(date, rawValue) => {
                  if (rawValue) {
                    if (rawValue.replace(/\D/g, '').length === 8) {
                      if (date.isValid()) setEndDate(date.format('YYYY-MM-DD'));
                      else {
                        setEndDate('');
                        props.setSnackBar({
                          display: true,
                          type: 'error',
                          message: 'Período "até" inválido.',
                        });
                      }
                    }
                  }
                }}
              />
            </Grid>
          </div>
          <Grid container xs={12}>
            {hasClient && hasAllCompanies && (
              <Grid xs={3}>
                <Label className="textLabel">Empresa</Label>
                <Select
                  options={companies}
                  placeholder="Selecione"
                  getOptionLabel={option => `${option.name}`}
                  getOptionValue={option => option.id}
                  value={selectedCompany}
                  onChange={e => handleSelectCompany(e)}
                  loading={loading}
                />
              </Grid>
            )}
            {hasUser && (
              <Grid xs={3} className="mx-1">
                <Label className="textLabel">Usuário</Label>

                <AsyncSelect
                  noOptionsMessage={() => <span>Digite um usuário</span>}
                  placeholder="Selecione"
                  getOptionLabel={option => option.username}
                  getOptionValue={option => option.id}
                  loadOptions={fetchUsers}
                  loadingMessage={() => <span>Carregando...</span>}
                  value={selectedUser}
                  onChange={e => setSelectedUser(e)}
                />
              </Grid>
            )}
            {hasTags && (
              <Grid xs={3}>
                <Label className="textLabel">Tags</Label>

                <AsyncSelect
                  isMulti
                  noOptionsMessage={() => <span>Digite uma tag</span>}
                  placeholder="Selecione"
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  loadOptions={fetchTags}
                  loadingMessage={() => <span>Carregando...</span>}
                  value={selectedTags}
                  onChange={e => setSelectedTags(e)}
                />
              </Grid>
            )}
          </Grid>
          {hasCostCenter && (
              <Grid item xs={10}>
                <Label className="textLabel">Centro de Custo</Label>
                <AsyncSelect
                  noOptionsMessage={() => <span>Digite um Centro de Custo</span>}
                  placeholder="Selecione"
                  isMulti
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  loadOptions={fetchCostCenters}
                  loadingMessage={() => <span>Carregando...</span>}
                  onChange={handleSelectedCostCenters}
                />
              </Grid>
            )}
          <Grid item xs={10}>
            <Label className="textLabel">
              {clients.length && checked.length === 0
                ? 'Selecione um Cliente'
                : 'Clientes Selecionados'}
            </Label>
            <Select
              options={clients}
              isMulti
              placeholder="Selecione"
              getOptionLabel={option =>
                `${option.social_name} - ${option.cgccpf}`
              }
              getOptionValue={option => option.id}
              value={values}
              onChange={handleSelect}
            />
          </Grid>

          <Grid item xs={2}>
            {(checked.length >= 1 ||
              selectedUser?.id) && (
              <Button
                onClick={validateDate}
                loading={(loading, dataLoading)}
                style={{ marginTop: '32px' }}
                disabled={dataLoading}
              >
                {buttonName}
              </Button>
            )}
          </Grid>
        </>
      )}
    </>
  );
}

export default ClientSelector;
