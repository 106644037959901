/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FaPlus, FaTrash } from 'react-icons/fa';
import Accordion from 'v3/components/Accordion';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import Input from 'v3/components/Input';
import ApplicationLayout from 'v3/layouts/Application';

import { Row, Col } from 'react-bootstrap';

import * as Controller from './controller';

export default function Checklist() {
  const params = useParams();
  const [isEditing, setEditing] = useState(false);
  const [isInFlight, setInFlight] = useState(false);
  const [items, setItems] = useState([]);

  async function saveItemsLocally() {
    const data = await Controller.fetchItems(params.company_id);
    setItems([...data, { id: data.length + 1, title: '' }]);
  }
  useEffect(() => {
    saveItemsLocally();
  }, []);

  async function handleEditOrSave() {
    if (isEditing) {
      const valid = items.filter(item => item.title);
      await Controller.syncItems(
        { items: valid, id: params.company_id },
        { setInFlight }
      );
      saveItemsLocally();
      setEditing(false);
    } else {
      setEditing(true);
    }
  }

  function addItem() {
    setItems(prev => [...prev, { id: prev.length + 1, title: '' }]);
  }

  function removeItem(item) {
    setItems(prev => prev.filter(i => i.id !== item.id));
    if (item.created_at) {
      Controller.deleteItem(item.id);
    }
  }

  return (
    <ApplicationLayout title="Checklist">
      <Row>
        <Col xs={6} className="mx-auto">
          <Accordion title="Checklist">
            {items.map((item, index) => {
              if (!isEditing && !item.title) {
                return null;
              }
              return (
                <div className="d-flex align-items-start" key={item.id}>
                  <Input
                    type="text"
                    value={item.title}
                    onChange={({ target }) =>
                      setItems(prev =>
                        prev.map(i =>
                          i.id === item.id
                            ? { ...i, title: target.value, dirty: true }
                            : i
                        )
                      )
                    }
                    disabled={!isEditing}
                  />
                  {isEditing && (
                    <>
                      {index === items.length - 1 ? (
                        <Button
                          variant="success"
                          className="mx-1"
                          onClick={addItem}
                        >
                          <FaPlus />
                        </Button>
                      ) : (
                        <Button
                          variant="error"
                          className="mx-1"
                          onClick={() => removeItem(item)}
                        >
                          <FaTrash />
                        </Button>
                      )}
                    </>
                  )}
                </div>
              );
            })}
            <div className="text-right mt-2">
              {isEditing && (
                <Button
                  onClick={() => setEditing(false)}
                  variant="secondary"
                  className="mr-1"
                >
                  <Text color="dark" type="regular" weight={500}>
                    Cancelar
                  </Text>
                </Button>
              )}
              {!items[1] && (
                <Button
                  onClick={handleEditOrSave}
                  loading={isInFlight}
                  disabled={isInFlight}
                  variant={isEditing ? 'success' : 'secondary'}
                >
                  <Text color="dark" type="regular" weight={500}>
                    Incluir checklist
                  </Text>
                </Button>
              )}
              {items[1] && (
                <Button
                  onClick={handleEditOrSave}
                  loading={isInFlight}
                  disabled={isInFlight}
                  variant={isEditing ? 'success' : 'secondary'}
                  className="py-2"
                >
                  <Text color="dark" type="regular" weight={500}>
                    {isEditing ? 'Salvar' : 'Editar'} checklist
                  </Text>
                </Button>
              )}
            </div>
          </Accordion>
        </Col>
      </Row>
    </ApplicationLayout>
  );
}
