/* eslint-disable import/no-unresolved */
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { groupBy } from 'v3/utils/array';

import api from 'services/api';
import {
  formatTime,
  formatDateTime,
  capitalizeFullname,
} from 'v3/utils/formatter';

import { formatDate } from 'utils/formatter';

import Card, { DefaultLoadingBodyComponent } from 'v3/components/Card';
import Text from 'v3/components/Text';
import Fallback from 'v3/components/Fallback';

import * as Styled from './styles';

export default function Feed({ isLoading }) {
  const params = useParams();

  const [feed, setFeed] = useState([]);

  async function fetchFeed() {
    try {
      const response = await api.get(`v3/route/log/${params.id}`);
      const grouped = groupBy(response.data, item => formatDate(item.created_at));
      setFeed(grouped);
    } catch (ex) {
      // Handle exception
    }
  }
  useEffect(() => {
    fetchFeed();
  }, [params.id, isLoading]);

  return (
    <>
      <Card
        loading={isLoading}
        LoadingBodyComponent={() => (
          <DefaultLoadingBodyComponent linesCount={2} perLine={2} />
        )}
        header={
          <>
            <Text type="header" color="dark">
              Feed da rota
            </Text>
          </>
        }
      >
        <Fallback
          fall={feed.length === 0}
          component={<Text>Nenhum evento enviado</Text>}
        >
          {Object.entries(feed).map(([created_at, item]) => (
            <Styled.Container>
              <div className="mb-1">
                <Text weight={500} color="dark">
                  {created_at}
                </Text>
              </div>
              <Styled.Marker style={{ marginBottom: 5 }} />
              <Styled.Tray>
                {item.map(feedItem => (
                  <div className="mt-1 pl-4">
                    <div className="mb-2">
                      <Text weight={500} color="dark">
                        {formatTime(feedItem.created_at)}
                      </Text>
                    </div>
                    <Text
                      as="div"
                      type="regular"
                      color="gray"
                      className="mt-1"
                      wordwrap
                    >
                      {feedItem.description}
                    </Text>
                    <Text as="div" type="label" color="gray">
                      Criado por {capitalizeFullname(feedItem.user[0].username)}
                    </Text>
                    <hr />
                  </div>
                ))}
              </Styled.Tray>
              <div className="mb-3">
                <Styled.Marker isLast />
              </div>
            </Styled.Container>
          ))}
        </Fallback>
      </Card>
    </>
  );
}
