import React, { useState, useEffect } from 'react';

import { Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { validatePermission } from 'actions/index';

import Modal from 'v3/components/Modal';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';

import { TravelContext } from '../../controller';
import moment from 'moment';

const [, useTravel] = TravelContext;

function CreatedTravel({ isOpen, onClose, notClosed }) {
  const travel = useTravel();
  const history = useHistory();
  const [driverProblems, setDriverProblems] = useState([]);
  const [vehicleProblems, setVehicleProblems] = useState([]);
  const [hasPermissionToCreate, setPermissionToCreate] = useState();

  function validateDriver(driver, destinations) {
    const { cnh_exp_date, currentDriverPayment, social_name } = driver;
    const listOfProblems = [];
    let lastDestinationDate = false;
    if (destinations.length > 0) {
      lastDestinationDate = moment(destinations[destinations.length - 1].scheduled_time).set({ hour: 0, minute: 0, seconds: 0 })
    }

    const cnhExp = moment(cnh_exp_date);
    const verifyCnhDriver = cnhExp.isBefore(lastDestinationDate);
    if (verifyCnhDriver) listOfProblems.push(`A CNH de ${social_name} está expirada.`);

    const driverPaymentExpired = moment(currentDriverPayment?.due_date, 'MM/YYYY').isBefore(moment(), 'month');
    if (driverPaymentExpired) listOfProblems.push('O cartão atual está expirado.')
    return listOfProblems;
  }

  function getVehicleLabel(key) {
    const map = {
      main: 'veículo tração',
      implement: 'primeiro implemento',
      implement2: 'segundo implemento',
      implement3: 'terceiro implemento',
    };
    return map[key];
  }

  function validateVehicle(vehicle) {
    const listOfProblems = [];
    const rntrcExpired = Object.entries(vehicle || {}).map(
      ([key, value]) => {
        return { key, value: moment(value?.rntrc_exp_date).isBefore(moment(), 'day') };
      }
    );

    if (rntrcExpired.length > 0) {
      rntrcExpired.forEach((vehicle) => {
        if (vehicle.value) {
          listOfProblems.push(`A RNTRC do ${getVehicleLabel(vehicle.key)} está expirada.`)
        }
      })
    };
    if (rntrcExpired.length > 0) {
      listOfProblems.push('RNTRC não informado.')
    }
    return listOfProblems;
  }

  function checkErrors() {
    if (travel) {
      const { data: { driver, destinations, vehicle } } = travel;
      if (driver && destinations.length > 0) {
        setDriverProblems(validateDriver(driver, destinations))
      }

      if (vehicle) {
        setVehicleProblems(validateVehicle(vehicle))
      }
    }
  }
  useEffect(() => {
    checkErrors();
  }, [travel])

  useState(() => {
    setPermissionToCreate(validatePermission('CADASTRAR_VIAGENS'));
  }, []);

  return (
    <>
      <Modal
        show={isOpen}
        onHide={notClosed && onClose}
        notClosed={notClosed}
        heading={
          <Text type="header" color="dark" weight="500">
            Cadastro de viagens
          </Text>
        }
        body={
          <>
            <Row className="mx-0 mt-1">
              <Text weight="400" color="dark">
                Viagem {travel.data.registeredTravel} cadastrada com sucesso!
              </Text>
            </Row>
            <hr />
            {
              driverProblems.length > 0 && (
                <>
                  <Text weight={600}>
                    Motorista
                  </Text>
                  <br />
                  {
                    driverProblems.map((problem) => <Text>{`${' '}${problem}`}</Text>)
                  }
                </>
              )
            }
            <br />
            {
              vehicleProblems.length > 0 && (
                <>
                  <Text weight={600}>
                    Veículo
                  </Text>
                  <br />
                  {
                    vehicleProblems.map((problem) => <Text>{`${' '}${problem}`}</Text>)
                  }
                </>
              )
            }
          </>
        }
        footer={
          < Row md={12} xs={12} className="mr-3 mt-1" >
            <Button
              variant="white"
              className="mr-2 mt-1 py-2"
              outlined
              size="sm"
              onClick={() => history.push('/viagens')}
            >
              <Text weight="500" type="regular">
                Ver listagem
              </Text>
            </Button>

            <Button
              className="mr-2 mt-1 py-2"
              variant="primary"
              size="sm"
              onClick={() =>
                history.push(`/viagens/${travel.data.registeredTravel}`)
              }
            >
              <Text weight="500" type="regular">
                Ver viagem
              </Text>
            </Button>

            {
              hasPermissionToCreate && (
                <Button
                  variant="success"
                  className="mr-2 mt-1 py-2"
                  size="sm"
                  onClick={() => {
                    window.location.reload(false);
                  }}
                >
                  <Text weight="500" type="regular">
                    Nova viagem
                  </Text>
                </Button>
              )
            }
          </Row >
        }
      />
    </>
  );
}

export default CreatedTravel;
