import React from 'react';
import moment from 'moment';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import Card from 'components/Card';
import { number_format } from 'functions';

function CellFatura(props) {
  const fatura = props.fatura;

  return (
    <Card
      style={{ marginBottom: '10px' }}
      bordered
      borderhexcolor={props.status_color}
    >
      <Grid container>
        <Grid item xs={8}>
          <Typography variant="body2" gutterBottom>
            <strong>{fatura.client.social_name}</strong> -{' '}
            {fatura.client.cgccpf}
          </Typography>
          <Typography variant="caption" component="p" gutterBottom>
            <strong>Fatura: </strong> {fatura.invoice_number || ''}
          </Typography>

          <Typography variant="caption" component="p" gutterBottom>
            <strong>CTE/NFS:</strong> {fatura.cte_number || fatura.nfs || ''}
            {fatura.emitteds.length > 0 &&
              ',' +
              fatura.emitteds
                .map(el => {
                  return el.cte_number;
                })
                .join(',')}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="caption" component="p" gutterBottom>
            <strong>Status: </strong>
            {fatura.status}
          </Typography>
          <Typography variant="body2" gutterBottom>
            <strong>Valor: </strong>
            R$ {number_format(fatura.invoice_amount, 2, ',', '.')}
          </Typography>
          <Typography variant="body2" gutterBottom>
            <strong>Vencimento: </strong>
            {fatura.due_date
              ? moment(fatura.due_date).format('DD/MM/YYYY')
              : ''}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
}

export default CellFatura;
