/* eslint-disable import/no-unresolved */
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';

import Modal from 'v3/components/Modal';
import Select from 'v3/components/Select';
import InputAddress from 'v3/components/InputAddress';
import Input from 'v3/components/Input';
import DatePicker from 'v3/components/DatePicker';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import { useSnackbar } from 'v3/components/Snackbar';
import { fetchCountries } from 'services/api/Locations';
import { formatDateCustom } from 'utils/formatter';
import api from 'services/api';

import schema from './schema';
import { DESTINATION_ACTIONS } from './controller';

import { TravelContext, fetchSelectOptions } from '../controller';

const [, useTravel] = TravelContext;

const [DEFAULT_DESTINATION_ACTION] = DESTINATION_ACTIONS;

export default function LocaleModal(props) {
  const { isOpen, onClose, updateData, updateIndex, locationType } = props;

  const snackbar = useSnackbar();
  const travel = useTravel();

  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState({
    id: 1,
    name: 'Brasil',
    abbreviation: 'br',
    ddi: '55',
    currency: 'BRL',
    currency_symbol: 'R$',
  });
  const [fullAddress, setFullAddress] = useState({});
  const [action, setAction] = useState(DEFAULT_DESTINATION_ACTION);
  const [scheduledTimeRaw, setScheduledTimeRaw] = useState();
  const [startSchedule, setStartSchedule] = useState();
  const [errors, setErrors] = useState({});
  const inputRef = useRef();

  const [disableNumber, setDisableNumber] = useState(false);
  const [disableZipCode, setDisableZipCode] = useState(false);
  const [disableCity, setDisableCity] = useState(false);
  const [disableProvince, setDisableProvince] = useState(false);
  const [disableNeighborhood, setDisableNeighborhood] = useState(false);

  useEffect(() => {
    fetchCountries().then(setCountries);
  }, []);

  useEffect(() => {
    setFullAddress(updateData || {});
    if (updateData?.scheduled_time) {
      setScheduledTimeRaw(
        formatDateCustom(updateData.scheduled_time, 'DD/MM/YYYY HH:mm')
      );
    } else {
      setScheduledTimeRaw('');
    }

    if (updateData?.start_schedule) {
      setStartSchedule(
        formatDateCustom(updateData.start_schedule, 'DD/MM/YYYY HH:mm')
      );
    } else {
      setStartSchedule('');
    }

    if (updateData?.action) {
      const actionFound = DESTINATION_ACTIONS.find(
        item => item.id === updateData.action
      );
      setAction(actionFound);
    }
    disableAddress(updateData);
  }, [updateData, isOpen]);

  function disableAddress(address, city) {
    setDisableNumber(!!address?.number);
    setDisableZipCode(!!address?.zip_code);
    setDisableCity(!!city);
    setDisableProvince(!!city?.province);
    setDisableNeighborhood(!!address?.neighborhood);
  }

  function handleFullAddressChange(key, value) {
    setFullAddress(previous => ({ ...previous, [key]: value }));
  }
  useEffect(() => {
    if (isOpen && locationType === 'destinations') {
      handleFullAddressChange('action', DEFAULT_DESTINATION_ACTION.id);
    }
  }, [isOpen, locationType]);

  function handleClose() {
    setErrors({});
    setScheduledTimeRaw('');
    setStartSchedule('');

    if (!updateData) {
      setFullAddress({});
    }

    onClose();
  }

  function setFieldError(field, error) {
    setErrors(prev => ({ ...prev, [field]: error }));
  }

  async function validateField(path, value = null) {
    try {
      if (value) {
        await schema.validateAt(path, { ...fullAddress, [path]: value });
      } else await schema.validateAt(path, fullAddress);

      setFieldError(path, '');
    } catch (ex) {
      setFieldError(ex.path, ex.message);
    }
  }

  useEffect(() => {
    const parsed = moment(scheduledTimeRaw, 'DD/MM/YYYY HH:mm', true).isValid()
      ? moment(scheduledTimeRaw, 'DD/MM/YYYY HH:mm', true).toDate()
      : '';
    handleFullAddressChange('scheduled_time', parsed);
  }, [scheduledTimeRaw]);

  useEffect(() => {
    const parsed = moment(startSchedule, 'DD/MM/YYYY HH:mm', true).isValid()
      ? moment(startSchedule, 'DD/MM/YYYY HH:mm', true).toDate()
      : '';
    handleFullAddressChange('start_schedule', parsed);
  }, [startSchedule]);

  const disableSubmit = useMemo(() => {
    try {
      schema.validateSync(fullAddress);
      return false;
    } catch (ex) {
      if (isOpen) {
        setFieldError(ex.path, ex.message);
      }

      return true;
    }
  }, [fullAddress]);

  function setOrigin() {
    travel.setData(prev => ({
      ...prev,
      origin: {
        ...fullAddress,
        client_id: fullAddress?.client?.id || null,
      },
    }));
  }

  function addDestination() {
    travel.setData(prev => ({
      ...prev,
      destinations: [
        ...prev.destinations,
        {
          ...fullAddress,
          action: action.id,
          client_id: fullAddress?.client?.id || null,
        },
      ],
    }));
  }

  function updateDestination() {
    travel.setData(prev => ({
      ...prev,
      destinations: prev.destinations.map((destination, index) => {
        if (index === updateIndex) {
          return {
            ...fullAddress,
            client_id: fullAddress?.client?.id || null,
          };
        }

        return destination;
      }),
    }));
  }
  function onSave(event) {
    event.preventDefault();

    if (
      startSchedule &&
      moment(scheduledTimeRaw, 'DD/MM/YYYY HH:mm').isBefore(
        moment(startSchedule, 'DD/MM/YYYY HH:mm')
      )
    ) {
      snackbar.show(<Text>Data máxima menor que data inicial</Text>, {
        type: 'error',
      });
      return;
    }

    if (locationType === 'origin') {
      setOrigin();
    } else if (updateIndex !== null && updateIndex !== undefined) {
      updateDestination();
    } else {
      addDestination();
    }

    handleClose();
  }

  useEffect(() => {
    if (isOpen) {
      inputRef.current.refs.input.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    if (!updateData) {
      setFullAddress({
        address: '',
        formatted_address: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        province: '',
        lat: null,
        lng: null,
      });
      setScheduledTimeRaw({});
      setStartSchedule({});
    }
  }, [updateData, onClose]);

  async function fetchProvinces(search, uf, country) {
    try {
      const response = await api.get('cities', {
        params: { search, uf, country },
      });
      return response.data;
    } catch (ex) {
      return [];
    }
  }

  async function onPlaceSelected(place) {
    const { name, ...rest } = place;
    let { complement } = place;
    const [city] = await fetchProvinces(
      place.city,
      place.province,
      place.country_abbreviation
    );
    if (place.name && place.isEstablishment) {
      complement = place.name;
    }
    setFullAddress({
      ...fullAddress,
      address: rest.address || '',
      formatted_address: rest.formatted_address || '',
      number: rest.number || '',
      complement: complement || '',
      neighborhood: rest.neighborhood || '',
      zip_code: rest.zip_code || '',
      cityModel: city,
      city: city?.name || '',
      province: city?.province?.uf || '',
      lat: rest?.lat,
      lng: rest?.lng,
    });
    disableAddress(place, city);
  }

  function setDestinationCity(city, options) {
    let cityModel = city;
    if (options.action === 'create-option') {
      cityModel = {
        name: city.id,
        province: null,
      };
    }
    if (cityModel) {
      setFullAddress(previous => ({
        ...previous,
        cityModel,
        province: cityModel?.province?.uf || '',
      }));
    } else {
      setFullAddress(previous => ({
        ...previous,
        cityModel: null,
        province: '',
      }));
    }
  }

  function onChangeCountry(value) {
    setCountry(value);
    if (value) {
      handleFullAddressChange('country_slug', value.abbreviation);
    }
  }

  return (
    <Modal
      heading={
        <Text color="dark" type="header">
          {updateData ? 'Alterar endereço' : 'Adicionar endereço'}
        </Text>
      }
      size="lg"
      show={isOpen}
      handleClose={handleClose}
      body={
        <form onSubmit={onSave}>
          <Row className="px-2 form">
            <Col xs={12}>
              <Select
                label="País"
                options={countries}
                value={country}
                onChange={onChangeCountry}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                disableClear
                id="select-modal-endereco-pais"
              />
            </Col>
            <Col xs={12}>
              <Select.Async
                label="Cliente Tomador"
                onSearch={search =>
                  fetchSelectOptions('persons/customers', { search })
                }
                value={fullAddress?.client}
                getOptionLabel={option => {
                  let label = `${option?.social_name} - ${option?.cgccpf}`;

                  if (option.city) {
                    label += ` - ${option?.city}`;
                  }
                  return label;
                }}
                getOptionValue={option => option?.id}
                onChange={event => {
                  handleFullAddressChange('client', event);
                }}
                id="select-modal-endereco-cliente"
              />
            </Col>
            <Col xs={8}>
              <InputAddress
                ref={inputRef}
                label="Logradouro *"
                placeholder="Digite seu endereço"
                country={country.abbreviation}
                types={[]}
                onChange={event =>
                  setFullAddress(old => ({
                    ...old,
                    address: event?.target?.value,
                    number: '',
                    complement: '',
                    neighborhood: '',
                    zip_code: '',
                    lat: null,
                    lng: null,
                    cityModel: '',
                    province: '',
                  }))
                }
                onSelectedAddress={onPlaceSelected}
                value={fullAddress?.address}
                onBlur={() => validateField('address')}
                error={errors.address}
                id="input-modal-endereco-logradouro"
              />
            </Col>
            <Col md={4} xs={6}>
              <Input
                label="Número"
                disabled={disableNumber}
                value={fullAddress?.number}
                onChange={event =>
                  handleFullAddressChange('number', event.target.value)
                }
                id="input-modal-endereco-numero"
              />
            </Col>
            <Col md={4} xs={6}>
              <Input
                label="Complemento"
                value={fullAddress?.complement}
                onChange={event =>
                  handleFullAddressChange('complement', event.target.value)
                }
                id="input-modal-endereco-complemento"
              />
            </Col>
            <Col md={4} xs={12}>
              <Input
                label="Bairro"
                disabled={disableNeighborhood}
                value={fullAddress?.neighborhood}
                onChange={event =>
                  handleFullAddressChange('neighborhood', event.target.value)
                }
                id="input-modal-endereco-bairro"
              />
            </Col>
            <Col md={4} xs={12}>
              <Input
                label="CEP"
                disabled={disableZipCode}
                value={fullAddress?.zip_code}
                onChange={event =>
                  handleFullAddressChange('zip_code', event.target.value)
                }
                id="input-modal-endereco-cep"
              />
            </Col>
            <Col xs={8}>
              <Select.Async
                label="Cidade *"
                onSearch={city =>
                  fetchProvinces(city, '', country?.abbreviation)
                }
                value={fullAddress?.cityModel}
                horizontal
                creatable
                onChange={setDestinationCity}
                getOptionLabel={option =>
                  `${`${option.name} ${
                    option.province?.uf ? `- ${option.province?.uf}` : ''
                  }`}`
                }
                getOptionValue={option =>
                  `${`${option.name} ${
                    option.province?.uf ? `- ${option.province?.uf}` : ''
                  }`}`
                }
                error={errors.cityModel}
                isDisabled={disableCity}
                id="select-modal-endereco-cidade"
              />
            </Col>
            <Col md={4} xs={12}>
              <Input
                label="UF *"
                disabled={disableProvince}
                value={fullAddress?.province}
                onChange={event =>
                  handleFullAddressChange('province', event.target.value)
                }
                onBlur={() => validateField('province')}
                error={errors.province}
                id="input-modal-endereco-uf"
              />
            </Col>
            <Col md={6}>
              <Input
                label="Latitude"
                disabled={true}
                value={fullAddress?.lat}
                error={errors?.lat}
                tooltip="Selecione um endereço sugerido para obter dados de latitude e longitude"
                id="input-modal-endereco-latitude"
              />
            </Col>
            <Col md={6}>
              <Input
                label="Longitude"
                disabled={true}
                value={fullAddress?.lng}
                error={errors?.lng}
                tooltip="Selecione um endereço sugerido para obter dados de latitude e longitude"
                id="input-modal-endereco-longitude"
              />
            </Col>
            <Col md={6} xs={12}>
              <DatePicker
                guide
                label={
                  locationType === 'origin'
                    ? 'Data Início da coleta *'
                    : 'Data Início da entrega *'
                }
                withTime
                value={startSchedule}
                onChange={event => setStartSchedule(event.target.value)}
                onBlur={event =>
                  locationType === 'origin'
                    ? validateField('start_schedule', event.target.value)
                    : ''
                }
                id="input-modal-endereco-data-inicio"
              />
            </Col>
            <Col md={6} xs={12}>
              <DatePicker
                guide
                label={
                  locationType === 'origin'
                    ? 'Data máxima da coleta *'
                    : 'Data máxima da entrega *'
                }
                withTime
                value={scheduledTimeRaw}
                onChange={event => {
                  setScheduledTimeRaw(event.target.value);
                }}
                onBlur={event =>
                  validateField('scheduledTimeRaw', event.target.value)
                }
                id="input-modal-endereco-data-fim"
              />
            </Col>
            {locationType !== 'origin' && (
              <Col md={6} xs={12}>
                <Select
                  label="Ação"
                  options={DESTINATION_ACTIONS}
                  value={action}
                  onChange={value => {
                    setAction(value);
                    handleFullAddressChange('action', value.id);
                  }}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  disableClear
                  id="select-modal-endereco-acao"
                />
              </Col>
            )}
          </Row>
          <div className="text-center">
            <Button
              id="button-modal-endereco-salvar"
              type="submit"
              disabled={disableSubmit}
              className="py-2"
            >
              <Text type="regular" weight="500">
                Salvar
              </Text>
            </Button>
          </div>
        </form>
      }
    />
  );
}
