import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import api, { cooperplaceApi } from 'services/api';
import { fetchSelectOptions } from 'utils/fetches';

import { Modal, Select, Text } from 'v3/components';
import { useSnackbar } from 'v3/components/Snackbar';

export function AttemptRegisterLoad({ isOpen, onClose }) {
  const snackbar = useSnackbar();
  const history = useHistory();
  const [selectedClient, setSelectedClient] = useState(null);
  const [loading, setLoading] = useState(false);
  const [validatingClient, setValidatingClient] = useState(false);

  async function handleValidateBlacklist(cpfCnpj) {
    try {
      setValidatingClient(true);
      const response = await api.get(`v3/blacklist-person/?value=${cpfCnpj}`);
      if (response?.data?.total > 0) {
        snackbar.show(
          <>
            <Text>
              Não é possivel selecionar o Tomador{' '}
              {response.data.data[0].description} - {cpfCnpj} pois ele está
              banido!{' '}
            </Text>
            <Text>Motivo: {response.data.data[0].reason}</Text>
          </>,
          {
            type: 'error',
          }
        );
        setValidatingClient(false);

        return false;
      }
      setValidatingClient(false);

      return true;
    } catch (error) {
      snackbar.show(
        <Text>
          Error ao validar o Tomador de CNPJ: {cpfCnpj} na lista de banidos!
        </Text>,
        {
          type: 'error',
        }
      );
      setValidatingClient(false);
      return false;
    }
  }
  async function handleClientChange(value) {
    try {
      setLoading(true);
      if (value) {
        const isValid = await handleValidateBlacklist(value?.cgccpf);
        if (isValid) {
          setSelectedClient(value);
        } else {
          setSelectedClient(null);
        }
      } else {
        setSelectedClient(null);
      }
    } catch (error) {
      setSelectedClient(null);
    } finally {
      setLoading(false);
    }
  }
  function handleRedirectToRegularLoad(message) {
    snackbar.show(<Text>{message}</Text>, {
      type: 'warning',
    });
    setLoading(false);
    history.push({
      pathname: '/cargas/cadastrar',
      state: { clientData: selectedClient },
    });
  }

  async function handleCheckIfClientHasPolicies() {
    try {
      setLoading(true);
      const { data } = await cooperplaceApi.get(
        `clients/${selectedClient?.id}/policies`
      );
      if (data?.length === 0) {
        return handleRedirectToRegularLoad(
          'Cliente sem apólices vinculadas! Você será redirecionado para cadastro regular de cargas'
        );
      }
      const { hasActive, activePolicies } =
        handleCheckIfClientHasActivePolicies(data);

      if (!hasActive) {
        return handleRedirectToRegularLoad(
          'Cliente sem apólices ativas! Você será redirecionado para cadastro regular de cargas'
        );
      }
      const hasNotExpiredPolicies =
        handleCheckIfClientHasActiveAndNotExpired(activePolicies);

      if (!hasNotExpiredPolicies) {
        snackbar.show(
          <Text>
            Necessário entrar em contato com setor de GRO pois as apólices para
            o cliente {selectedClient?.social_name} estão vencidas!
          </Text>,
          {
            type: 'error',
          }
        );
        setLoading(false);
        setSelectedClient(null);
      } else {
        snackbar.show(
          <Text>
            Cliente com apólices vigentes! Você será redirecionado para criação
            de carga com apólices
          </Text>,
          {
            type: 'success',
          }
        );
        setLoading(false);
        history.push({
          pathname: '/cargas/cadastrar/gro',
          state: {
            clientData: selectedClient,
            clientPolicies: activePolicies?.filter(
              policy => !policy.expired && policy.active
            ),
          },
        });
      }
    } catch (err) {
      snackbar.show(<Text>Falha ao consultar apólices do cliente!</Text>, {
        type: 'error',
      });
      setLoading(false);
    }
  }

  function handleCheckIfClientHasActiveAndNotExpired(activePolicies) {
    const notExpiredPolicies = activePolicies.some(policy => {
      return !policy.expired;
    });
    return notExpiredPolicies;
  }

  function handleCheckIfClientHasActivePolicies(policies) {
    const activePolicies = policies?.filter(policy => policy.active === true);
    return {
      hasActive: activePolicies?.length > 0,
      activePolicies,
    };
  }

  useEffect(() => {
    if (selectedClient) {
      handleCheckIfClientHasPolicies();
    }
  }, [selectedClient]);

  return (
    <Modal
      backdrop="static"
      show={isOpen}
      handleClose={() => {
        if (loading) {
        } else return onClose();
      }}
      heading={
        <Text type="subheader" color="dark">
          Criar carga
        </Text>
      }
      body={
        <Row>
          <Col>
            <Select.Async
              label="Digite o nome e/ou CNPJ do cliente tomador"
              placeholder="Digite..."
              onSearch={search =>
                fetchSelectOptions('persons/customers', { search })
              }
              getOptionLabel={option => {
                let label = `${option?.social_name} - ${option?.cgccpf}`;

                if (option.city) {
                  label += ` - ${option?.city}`;
                }

                return label;
              }}
              getOptionValue={option => option.id}
              onChange={handleClientChange}
              loading={loading || validatingClient}
              value={selectedClient}
            />
          </Col>
        </Row>
      }
    />
  );
}
