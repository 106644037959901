import React, { useEffect, useState, useRef } from 'react';
import { cooperplaceApi } from 'services/api';
import * as pbi from 'powerbi-client';

const isValidURL = (string) => {
  try {
    const url = new URL(string);
    return url;
  } catch (_) {
    return false;
  }
};

const PowerBiRlsAuth = ({ powerBiUrl, onLoad,iframeKey }) => {
  const [error, setError] = useState(null);
  const urlObj = isValidURL(powerBiUrl);
  const reportId = urlObj ? urlObj.searchParams.get('reportId') : null;
  const loadingReport = useRef(false);

  useEffect(() => {
    if (powerBiUrl && !loadingReport.current) {
      fetchEmbedToken();
    }
  }, [powerBiUrl]);

  const fetchEmbedToken = async () => {
    loadingReport.current = true;
    try {
      const { data } = await cooperplaceApi.post(`workspaces/report/${reportId}`);
      const { embedUrl, embedToken } = data;
      if (embedToken) {
        const reportContainer = document.getElementById('reportContainer');
        const embedConfig = {
          type: 'report',
          id: reportId,
          embedUrl,
          accessToken: embedToken,
          tokenType: pbi.models.TokenType.Embed,
          permissions: pbi.models.Permissions.Read,
          settings: {
            panes: {
              filters: {
                visible: true,
              },
              pageNavigation: {
                visible: true,
              },
            },
          },
        };
        if (reportContainer) {
          const powerbi = new pbi.service.Service(
            pbi.factories.hpmFactory,
            pbi.factories.wpmpFactory,
            pbi.factories.routerFactory
          );

          powerbi.reset(reportContainer);

          const report = powerbi.embed(reportContainer, embedConfig);

          report.on('loaded', () => {
            if (onLoad) {
              onLoad(false);
              loadingReport.current = false;
            }
          });

          report.on('error', (event) => {
            setError(event.detail); // Set the error so you can display it if needed
            loadingReport.current = false;
          });
        }
      }
    } catch (err) {
      onLoad(false);
      setError(err.message);
      loadingReport.current = false;
    }
  };

  return error ? (
    <div>{error}</div>
  ) : (
    <div key={iframeKey} id='reportContainer' style={{ width: '100%', height: '800px' }} />
  );
};

export default PowerBiRlsAuth;
