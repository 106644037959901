import React, { useState, useRef } from 'react';
import FormFile from 'react-bootstrap/FormFile';
import { FaCamera } from 'react-icons/fa';

import Text from '../Text';

import { ContainerFile } from './styles';

const MAX_FILE_SIZE = 100000000;

function Upload({
  name,
  id,
  onChange,
  accept = '*',
  label,
  icon,
  error,
  multiple,
  ignoreValidation,
}) {
  const inputRef = useRef();

  const [sizeFileGreater, setSizeFileGreater] = useState(false);
  const [fileName, setFileName] = useState('');

  function handleFileName(files) {
    if (files?.length > 1) {
      setFileName(`${files?.length} arquivos selecionados`);
    } else {
      const [file] = files;
      setFileName(file?.name);
    }
  }

  function handleChange(event) {
    const files = [...event.target.files];

    const hasFileGreater = files.some(file => file.size > MAX_FILE_SIZE);
    if (!ignoreValidation && hasFileGreater) {
      setSizeFileGreater(true);
      return;
    }

    onChange(event, hasFileGreater);
    handleFileName(files);
    setSizeFileGreater(false);
  }

  return (
    <ContainerFile display={12} onClick={() => inputRef.current.click()}>
      <FormFile.Input
        ref={inputRef}
        name={name}
        id={id}
        onChange={handleChange}
        accept={accept}
        multiple={multiple}
      />
      {icon || <FaCamera size={30} color="#494950" className="icon" />}
      {error ? (
        <Text color="error" type="label">
          {error}
        </Text>
      ) : sizeFileGreater ? (
        <Text color="error" type="label">
          Limite de tamanho ultrapassado.
        </Text>
      ) : (
        <Text color="#494950" type="label">
          {fileName}
        </Text>
      )}
      <Text color="#494950" type="label">
        {label}
      </Text>
    </ContainerFile>
  );
}

export default Upload;
