import styled from 'styled-components';

import theme from 'v3/theme';

export const DeleteIcon = styled.button`
  border-radius: 50px;
  background: ${theme.colors.error};
  padding: 0px 7px 6px;
`;

export const SaveContainer = styled.div`
  background-color: ${theme.colors.dark_blue};
  z-index: 998;
  position: fixed;
  top: 50px;
  padding: 15px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  > button:nth-of-type(2) {
    margin: 0 5px;
  }
`;

export const StyledButton = styled.button`
  color: #00ffffff;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  margin-left: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: none !important;
  }
`;
