import React from 'react';
import {
  Annotation,
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
} from 'react-simple-maps';
import { Col, Row } from 'react-bootstrap';
import { Button, Text, Tooltip } from 'v3/components';
import { geoData } from './vehicleMapFeaturesBrazil';
import { latam } from './vehicleMapFeaturesLatam';
import theme from 'v3/theme';
import {
  StyledCol,
  StyledTextTitle,
  SVGMapsContainer,
  MapContainer,
  WithoutTrackerContainer,
  StyledColorBoxSpan,
  LatamLabelContainer,
  BrazilLabelContainer,
  StyledMarker,
  CurrentFilter,
} from './styles';

function SVGMaps({
  toggleSVGMaps,
  setToggleSVGMaps,
  loading,
  setLocation,
  location,
  svgVehiclesData,
  isTracked,
  setIsTracked,
  setChecked,
}) {
  const smallProvinces = {
    DF: { uf: 'DF', dx: 22, dy: 0 },
    SE: { uf: 'SE', dx: 18, dy: 4.5 },
    AL: { uf: 'AL', dx: 22, dy: 0 },
    PE: { uf: 'PE', dx: 20, dy: -4 },
    PB: { uf: 'PB', dx: 20, dy: -8 },
    RN: { uf: 'RN', dx: 8, dy: -10 },
  };

  function toggleFilter(clickedLocation) {
    setIsTracked(null);
    setChecked(false);

    if (
      location.length &&
      location?.toLowerCase() === clickedLocation?.toLowerCase()
    ) {
      setLocation('');
    } else setLocation(clickedLocation);
    return;
  }
  function toggleTracked() {
    setLocation('');
    setChecked(false);

    if (isTracked === null) {
      setIsTracked(false);
    } else setIsTracked(null);
  }
  return (
    <SVGMapsContainer>
      <Row className="d-flex align-items-center">
        <StyledCol xs={12} lg={12}>
          <Button onClick={() => setToggleSVGMaps(!toggleSVGMaps)}>
            <Text type="regular" weight={500} fontSize="0.75rem">
              {toggleSVGMaps ? 'Ver mapa' : 'Minimizar'}
            </Text>
          </Button>
          <StyledTextTitle type="regular" weight={500} fontSize="0.75rem">
            Mapa de veículos por países Latam e estados do Brasil
          </StyledTextTitle>
        </StyledCol>
      </Row>
      {toggleSVGMaps === false && loading === false ? (
        <>
          <Row>
            <Col className="d-flex">
              <MapContainer md={6} lg={6} xs={12} sm={12}>
                <ComposableMap
                  projection="geoAzimuthalEqualArea"
                  projectionConfig={{
                    rotate: [62, 37, 0],
                    scale: 140,
                  }}
                  width="96"
                  height="96"
                  style={{
                    WebkitBoxShadow: theme.shadow,
                    MozBoxShadow: theme.shadow,
                    boxShadow: theme.shadow,
                  }}
                >
                  <ZoomableGroup zoom={1} maxZoom={2}>
                    <Geographies geography={latam}>
                      {({ geographies }) =>
                        geographies.map(geo => {
                          let geoData = svgVehiclesData?.Latam?.filter(
                            item =>
                              item?.country?.toLowerCase() ===
                              geo.properties.geounit.toLowerCase()
                          )[0];
                          return (
                            <Tooltip
                              content={`${geo.properties.geounit}: ${
                                geoData?.total ?? '0'
                              }`}
                              style={{
                                backgroundColor: '#FFF',
                                color: '#3c3c3c',
                              }}
                              key={geo.rsmKey}
                            >
                              <Geography
                                geography={geo}
                                onClick={() =>
                                  toggleFilter(geo.properties.geounit)
                                }
                                fill={
                                  location?.toLowerCase() ===
                                  geo.properties.geounit?.toLowerCase()
                                    ? geo.properties.bgColor
                                    : geo.properties.color
                                }
                                stroke="#FFF"
                                strokeWidth={0.25}
                                style={{
                                  hover: {
                                    opacity: 0.7,
                                    stroke: `${geo.properties.color}`,
                                    strokeWidth: '1',
                                    cursor: 'pointer',
                                  },
                                }}
                              />
                            </Tooltip>
                          );
                        })
                      }
                    </Geographies>
                    {svgVehiclesData?.Latam?.map(data => {
                      return (
                        <StyledMarker coordinates={[data?.lng, data?.lat]}>
                          <title>{`${data?.country} - ${data?.total}`}</title>
                          <text textAnchor="middle">{data?.total}</text>
                        </StyledMarker>
                      );
                    })}
                  </ZoomableGroup>
                </ComposableMap>
                <LatamLabelContainer>
                  <div>
                    <StyledColorBoxSpan color="#B8D9FF" />
                    <span>Argentina</span>
                  </div>
                  <div>
                    <StyledColorBoxSpan color="#FF8B8B" />
                    <span>Chile</span>
                  </div>
                  <div>
                    <StyledColorBoxSpan color="#C3D285" />
                    <span>Paraguai</span>
                  </div>
                  <div>
                    <StyledColorBoxSpan color="#A0FF7E" />
                    <span>Uruguai</span>
                  </div>
                </LatamLabelContainer>
              </MapContainer>
              <MapContainer md={6} lg={6} xs={12} sm={12}>
                <ComposableMap
                  projection="geoAzimuthalEqualArea"
                  projectionConfig={{
                    rotate: [45, 15, 0],
                    scale: 90,
                  }}
                  width="96"
                  height="96"
                  style={{
                    WebkitBoxShadow: theme.shadow,
                    MozBoxShadow: theme.shadow,
                    boxShadow: theme.shadow,
                  }}
                >
                  <ZoomableGroup zoom={1} maxZoom={3}>
                    <Geographies geography={geoData}>
                      {({ geographies }) =>
                        geographies.map(geo => {
                          let geoData = svgVehiclesData?.Brazil?.filter(
                            item =>
                              item?.region?.toLowerCase() ===
                              geo.properties.uf.toLowerCase()
                          )[0];
                          return (
                            <Tooltip
                              content={`${geo.properties.uf}: ${
                                geoData?.total ?? '0'
                              }`}
                              style={{
                                backgroundColor: '#FFF',
                                color: '#3c3c3c',
                              }}
                              key={geo.rsmKey}
                            >
                              <Geography
                                geography={geo}
                                onClick={() => toggleFilter(geo.properties.uf)}
                                fill={
                                  location?.toLowerCase() ===
                                  geo.properties.uf?.toLowerCase()
                                    ? geo.properties.bgColor
                                    : geo.properties.color
                                }
                                stroke="#FFF"
                                strokeWidth={0.25}
                                style={{
                                  hover: {
                                    opacity: 0.7,
                                    stroke: `${geo.properties.color}`,
                                    strokeWidth: '1',
                                    cursor: 'pointer',
                                  },
                                }}
                              />
                            </Tooltip>
                          );
                        })
                      }
                    </Geographies>
                    {svgVehiclesData?.Brazil?.map(data => {
                      return Object.keys(smallProvinces).includes(
                        data?.region
                      ) ? (
                        <Annotation
                          subject={[data?.lng, data?.lat]}
                          dx={smallProvinces[data?.region].dx}
                          dy={smallProvinces[data?.region].dy}
                          connectorProps={{
                            stroke: '#000',
                            strokeWidth: 0.25,
                          }}
                        >
                          <text
                            textAnchor="start"
                            alignmentBaseline="middle"
                            onClick={
                              Object.keys(smallProvinces).includes(data?.region)
                                ? () => toggleFilter(data?.region)
                                : ''
                            }
                            style={{
                              cursor: Object.keys(smallProvinces).includes(
                                data?.region
                              )
                                ? 'pointer'
                                : 'default',
                              fontSize: '0.25rem',
                              fill: theme.colors.gray,
                            }}
                          >
                            {`${data?.region}-${data?.total}`}
                          </text>
                        </Annotation>
                      ) : (
                        <StyledMarker coordinates={[data?.lng, data?.lat]}>
                          <title>{`${data?.region} - ${data?.total}`}</title>
                          <text
                            textAnchor="middle"
                            onClick={() => toggleFilter(data?.region)}
                            style={{
                              cursor: 'pointer',
                            }}
                          >
                            {data?.total}
                          </text>
                        </StyledMarker>
                      );
                    })}
                    ;
                  </ZoomableGroup>
                </ComposableMap>
                <BrazilLabelContainer>
                  <div>
                    <StyledColorBoxSpan color="#ACC995" />
                    <span>Norte</span>
                  </div>
                  <div>
                    <StyledColorBoxSpan color="#C9B495" />
                    <span>Nordeste</span>
                  </div>
                  <div>
                    <StyledColorBoxSpan color="#B8BA66" />
                    <span>Centro-Oeste</span>
                  </div>
                  <div>
                    <StyledColorBoxSpan color="#C99598" />
                    <span>Sudeste</span>
                  </div>
                  <div>
                    <StyledColorBoxSpan color="#959DC9" />
                    <span>Sul</span>
                  </div>
                </BrazilLabelContainer>
              </MapContainer>
            </Col>
          </Row>
          <Row>
            <Col md={6} lg={6} xs={12} sm={12}>
              <WithoutTrackerContainer as={'p'} type="regular">
                Veículos sem rastreamento:{' '}
                <span onClick={() => toggleTracked()}>
                  {svgVehiclesData?.totalWithoutTracking}
                </span>
              </WithoutTrackerContainer>
            </Col>
            {!!location && (
              <Col
                md={6}
                lg={6}
                xs={12}
                sm={12}
                title={`Clique no local (${location}) no mapa para remover o filtro`}
              >
                <span>Filtro:</span>
                <CurrentFilter> {location}</CurrentFilter>
              </Col>
            )}
          </Row>
        </>
      ) : null}
    </SVGMapsContainer>
  );
}
export default SVGMaps;
