import React, { useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useParams } from 'react-router-dom';
import Select from 'v3/components/Select';
import { useVehicle } from '../context';
import { fetchOwners } from 'utils/fetches';

export default function OwnerData() {
  const vehicle = useVehicle();
  const params = useParams();

  useEffect(() => {
    if (!params.id) {
      vehicle.setData({
        owner: null,
        owneOntt: null,
        beneficiary:null
      });
    }
  }, []);

  return (
    <Row className="form">
      <Col xs={12}>
        <Select.Async
          label={'Proprietário do documento *'}
          onSearch={fetchOwners}
          onChange={value => vehicle.setData({ owner: value })}
          value={vehicle.data.owner}
          getOptionLabel={option => option.label}
          getOptionValue={option => option.id}
          error={vehicle.errors?.owner}
        />
      </Col>
      <Col xs={12}>
        <Select.Async
          label={'Beneficiário do veiculo*'}
          onSearch={fetchOwners}
          onChange={value => vehicle.setData({ beneficiary: value })}
          value={vehicle.data.beneficiary}
          getOptionLabel={option => option.label}
          getOptionValue={option => option.id}
          error={vehicle.errors?.beneficiary}
        />
      </Col>
      <Col xs={12}>
        <Select.Async
          label={params.id ? 'Proprietário RNTRC*' : 'Proprietário RNTRC'}
          onSearch={fetchOwners}
          onChange={value => vehicle.setData({ anttOwner: value })}
          value={vehicle.data.anttOwner}
          getOptionLabel={option => option.label}
          getOptionValue={option => option.id}
          error={vehicle.errors?.anttOwner}
        />
      </Col>
    </Row>
  );
}
