import moment from 'moment';

export const DATE_OPTIONS = [
  {
    label: '3 dias',
    value: moment().subtract(3, 'days').format('YYYY-MM-DD 00:00:00'),
  },
  {
    label: '10 dias',
    value: moment().subtract(10, 'days').format('YYYY-MM-DD 00:00:00'),
  },
  {
    label: '30 dias',
    value: moment().subtract(30, 'days').format('YYYY-MM-DD 00:00:00'),
  },
];

export function validateIntervalOfDate(startDate, endDate) {
  if (
    moment(startDate, 'YYYY-MM-DD HH:mm:ss').isAfter(
      endDate,
      'YYYY-MM-DD HH:mm:ss'
    )
  ) {
    return {
      valid: false,
      message: 'Data inicial não pode ser maior que data final',
    };
  }

  if (moment(endDate).diff(startDate, 'days') > 90) {
    return {
      valid: false,
      message: 'Período máximo de 90 dias',
    };
  }

  return {
    valid: true,
  };
}

export const forbiddenTravelStatusCode = ['ST2', 'ST7'];
