import React, { useState, useEffect } from 'react';

import dashboardApi from 'services/apis/dashboard';

import { useSnackbar } from 'v3/components/Snackbar';
import { Text } from 'v3/components';
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import moment from 'moment';
import Card from './Card';

const monthsArray = new Array(6)
  .fill(0)
  .map((_, index) => moment().subtract(index, 'months').format('MMMM'));

const months = [
  'janeiro',
  'fevereiro',
  'março',
  'abril',
  'maio',
  'junho',
  'julho',
  'agosto',
  'setembro',
  'outubro',
  'novembro',
  'dezembro',
];

export default function CargonAdherence({ dashboard, name, id }) {
  const [isFetching, setFetching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState();
  const snackbar = useSnackbar();

  function handleResponse({ cargonAdherence, externalAdherence }) {
    const externalAdherenceName = externalAdherence?.map(item => {
      return { ...item, month: months[item.month - 1] };
    });

    const cargonAdherenceName = cargonAdherence?.map(item => {
      return { ...item, month: months[item.month - 1] };
    });

    return {
      cargonAdherence: monthsArray
        .map(
          month =>
            cargonAdherenceName?.find(item => item?.month === month) || {
              month,
              total: 0,
            }
        )
        .reverse(),
      externalAdherence: monthsArray
        .map(
          month =>
            externalAdherenceName?.find(item => item?.month === month) || {
              month,
              total: 0,
            }
        )
        .reverse(),
    };
  }

  async function fetchAdherenceInfo() {
    try {
      setLoading(true);
      setFetching(true);
      const response = await dashboardApi.get(`/cargon-adherence`);

      setChartData(handleResponse(response.data));
      return response.data;
    } catch (ex) {
      snackbar.show(<Text>Erro ao carregar informações de aderência</Text>, {
        type: 'error',
      });
    } finally {
      setLoading(false);
      setFetching(false);
    }
  }

  useEffect(() => {
    fetchAdherenceInfo();
  }, []);

  return (
    <Card
      title="Aderência Cooperplace"
      isLoading={isFetching}
      fetchFn={fetchAdherenceInfo}
      dashboard={dashboard}
      loading={loading}
      name={name}
      excludeFilterByBranchOffice={true}
      id={id}
      NumericComponent={
        <div className="d-flex justify-content-center">
          <ResponsiveContainer width="100%" height={400}>
            <ScatterChart
              width={600}
              height={300}
              margin={{
                top: 10,
                right: 10,
                bottom: 20,
                left: 10,
              }}
            >
              <CartesianGrid />
              <XAxis
                dataKey="month"
                name="mês"
                allowDuplicatedCategory={false}
              />
              <YAxis type="number" dataKey="total" />
              <ZAxis type="number" range={[100]} />
              <Tooltip cursor={{ strokeDasharray: '3 3' }} />
              <Legend />
              <Scatter
                name="Motoristas Cooperplace"
                data={chartData?.cargonAdherence}
                fill="#82ca9d"
                line
                shape="ball"
              />
              <Scatter
                name="Motoristas externos"
                data={chartData?.externalAdherence}
                fill="#8884d8"
                line
                shape="ball"
              />
            </ScatterChart>
          </ResponsiveContainer>
        </div>
      }
    />
  );
}
