import React from 'react';

import Modal from 'v3/components/Modal';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';

import { Col } from 'react-bootstrap';

export default function IssuingModal({
  open,
  handleHide,
  handleFillCte,
  handleIssueCte,
}) {
  return (
    <Modal
      show={open}
      handleClose={handleHide}
      size="lg"
      heading={
        <Col md={12}>
          <Text type="header" color="dark">
            Os documentos desta viagem já foram emitidos?
          </Text>
        </Col>
      }
      footer={
        <div className="d-flex flex-row align-items-center">
          <Col>
            <Button variant="success" onClick={handleIssueCte} className="py-2">
              <Text type="regular" weight="500">
                Não, emitir agora
              </Text>
            </Button>
          </Col>
          <Col>
            <Button variant="primary" className="py-2" onClick={handleFillCte}>
              <Text type="regular" weight="500">
                Sim, preencher dados
              </Text>
            </Button>
          </Col>
        </div>
      }
    />
  );
}
