import styled from 'styled-components';

export const ContractDetailsModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

export const SectionTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  th,
  td {
    border: 1px solid gray;
    padding: 0.5rem;
  }

  td:nth-child(2) {
    width: 10vw;
  }
`;

export const TotalComputedContainer = styled.tr`
  background: ${props => props.theme.colors.secondary_light};
`;

export const TotalsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const TotalItemContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > span:last-child {
    background: ${props => props.theme.colors.secondary_light};
    padding: 0.5rem;
    margin: 5px;
    text-align: center;
    border-radius: 6px;
  }
`;
