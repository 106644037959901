import React from 'react';
import { Col } from 'react-bootstrap';
import { FaArrowDown, FaArrowUp, FaPlus } from 'react-icons/fa';
import { Text } from 'v3/components';
import { BsArrowReturnRight, BsUpload } from 'react-icons/bs';
import { RiEditBoxLine } from 'react-icons/ri';
import { QuestionItem } from './QuestionItem';
import { AvailableQuestionsContainer } from './styles';
import { QuestionOptionItemComponent } from '../QuestionOptionItem';

export function AvailableQuestions({
  available,
  setAvailableQuestions,
  index,
  setSelectedQuestions,
}) {
  function handleSelectQuestion() {
    setSelectedQuestions(old => [...old, available]);
    setAvailableQuestions(old =>
      old.map((item, idx) => {
        if (idx === index) {
          return {
            ...available,
            disabled: true,
          };
        }
        return item;
      })
    );
  }
  return (
    <Col xs={12}>
      <AvailableQuestionsContainer>
        <header>
          <div>
            <button
              type="button"
              title={available?.show ? 'Ocultar detalhes' : 'Ver detalhes'}
              onClick={() =>
                setAvailableQuestions(old => {
                  const updated = old?.map((item, idx) => {
                    if (idx === index) {
                      return { ...item, show: !old[index]?.show };
                    }
                    return item;
                  });
                  return updated;
                })
              }
            >
              {available?.show ? <FaArrowUp /> : <FaArrowDown />}
            </button>
            <Text color="dark" weight={500}>
              {available?.show ? 'Dados da pergunta' : available?.question}
            </Text>
          </div>
          <button
            type="button"
            title={available?.disabled && 'Pergunta já foi selecionada'}
            onClick={handleSelectQuestion}
            disabled={available?.disabled}
          >
            <FaPlus />
            <Text>Selecionar</Text>
          </button>
        </header>
        {available?.show && (
          <div style={{ padding: '0.25rem 1rem' }}>
            <div style={{ display: 'flex', marginTop: 8 }}>
              <Col xs={10}>
                <Text color="dark" weight={500}>
                  Detalhes pergunta:
                </Text>
                <QuestionItem question={available} />
              </Col>
              <Col xs={2}>
                <div
                  style={{ display: 'flex', flexDirection: 'column', gap: 8 }}
                >
                  {available?.needAttachment && (
                    <QuestionOptionItemComponent
                      icon={BsUpload}
                      content="Necessário anexo"
                    />
                  )}
                  {available?.subQuestion?.id && (
                    <QuestionOptionItemComponent
                      content="Subpergunta"
                      icon={BsArrowReturnRight}
                    />
                  )}
                  {available?.createOccurrence && (
                    <QuestionOptionItemComponent
                      content="Gera ocorrência"
                      icon={RiEditBoxLine}
                    />
                  )}
                </div>
              </Col>
            </div>
          </div>
        )}
      </AvailableQuestionsContainer>
    </Col>
  );
}
