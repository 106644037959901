import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { cooperplaceApi } from 'services/api';
import { Button, Card, Fallback, Text, Tooltip } from 'v3/components';
import { useSnackbar } from 'v3/components/Snackbar';
import {
  DefaultEmptyComponent,
  DefaultLoadingComponent,
} from 'v3/components/List';
import theme from 'v3/theme';
import { FaCheck } from 'react-icons/fa';
import CheckListDetailModal from 'v3/pages/Travel/Details/Checklist/CheckListDetailModal';
import { useModal } from 'hooks';
import { ChecklistContainer, ChecklistItem } from './styles';

function ChecklistCard() {
  const params = useParams();
  const snackbar = useSnackbar();
  const [isFetching, setIsFetching] = useState(false);
  const [checklists, setChecklists] = useState([]);
  const [selectedChecklist, setSelectedChecklist] = useState(null);
  const checklistDetailModal = useModal();

  const fetchChecklists = async () => {
    try {
      setIsFetching(true);
      const response = await cooperplaceApi.get(
        `travel-checklist/logistic-panel/${params.id}`
      );
      if (response?.data?.data) {
        setChecklists(response.data.data);
      }
    } catch (error) {
      setChecklists([]);
      snackbar.show(<Text>Erro ao buscar checklists da viagem</Text>, {
        type: 'error',
      });
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    fetchChecklists();
  }, [params?.id]);

  useEffect(() => {
    if (selectedChecklist) {
      checklistDetailModal.open();
    }
  }, [selectedChecklist]);

  return (
    <>
      <Card
        header={
          <Text type="header" color={theme.colors.dark}>
            Checklists
          </Text>
        }
        loading={isFetching}
      >
        <Fallback
          fall={isFetching || checklists?.length === 0}
          component={
            isFetching ? (
              <DefaultLoadingComponent />
            ) : !isFetching && checklists?.length === 0 ? (
              <DefaultEmptyComponent />
            ) : null
          }
        >
          <ChecklistContainer>
            {checklists.map(checklist => (
              <ChecklistItem key={checklist.name}>
                <div>
                  <>
                    <Tooltip
                      placement="bottom"
                      content={<Text>Checklist respondido.</Text>}
                    >
                      <FaCheck color={theme.colors.success} />
                    </Tooltip>
                  </>
                  <Text>{checklist.id}</Text>
                  <Text>{checklist.checklistType.name}</Text>
                </div>
                <div>
                  <Button onClick={() => setSelectedChecklist(checklist)}>
                    Visualizar
                  </Button>
                </div>
              </ChecklistItem>
            ))}
          </ChecklistContainer>
        </Fallback>
      </Card>
      <CheckListDetailModal
        checklist={selectedChecklist}
        modalHook={checklistDetailModal}
        hideFooter
        handleClose={() => setSelectedChecklist(null)}
        travelData={{ id: params.id }}
      />
    </>
  );
}

export default ChecklistCard;
