import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import CheckBox from 'components/CheckBox';
import { QuestionItem } from './QuestionItem';
import { BsArrowReturnRight, BsTrash, BsUpload } from 'react-icons/bs';
import { RiEditBoxLine } from 'react-icons/ri';
import { SortableItemContainer } from './styles';
import { QuestionOptionItemComponent } from '../QuestionOptionItem';

export function SortableItem({ question, questions, setQuestions }) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: question?.id });
  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
  };
  function handleRemoveQuestion(id) {
    const updated = questions
      ?.filter(item => item?.id !== id)
      ?.map((item, idx) => {
        return { ...item, order: idx + 1 };
      });
    setQuestions(updated);
  }
  return (
    <SortableItemContainer style={style} ref={setNodeRef} {...attributes}>
      <button {...listeners} title="Clique e arraste para ordenar">
        <svg viewBox="0 0 20 20" width="22" transform="rotate(90)">
          <path d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z"></path>
        </svg>
      </button>
      <div className="contentContainer">
        <QuestionItem question={question} />
        <div className="questionOptions">
          <header>
            <CheckBox
              label={'Obrigatória'}
              checked={question?.required}
              value={question?.required}
              onChange={() => {
                const updated = questions?.map(q => {
                  if (q?.id === question?.id) {
                    return {
                      ...q,
                      required: !q.required,
                    };
                  } else return q;
                });
                setQuestions(updated);
              }}
              labelStyle={{
                textTransform: 'capitalize',
                color: '#464E5F',
                width: 'auto',
                fontFamily: 'Poppins',
              }}
              labelWeight="500"
            />
            <button
              title="Remover pergunta"
              onClick={() => handleRemoveQuestion(question?.id)}
            >
              <BsTrash />
            </button>
          </header>
          {question?.needAttachment && (
            <QuestionOptionItemComponent
              icon={BsUpload}
              content={'Necessário anexo'}
            />
          )}
          {question?.subQuestion && (
            <QuestionOptionItemComponent
              icon={BsArrowReturnRight}
              content={' Subpergunta'}
            />
          )}
          {question?.createOccurrence && (
            <QuestionOptionItemComponent
              content={'Gera ocorrência'}
              icon={RiEditBoxLine}
            />
          )}
        </div>
      </div>
    </SortableItemContainer>
  );
}
