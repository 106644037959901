export const QUESTION_TYPES_ALLOWED_TO_HAVE_CHILD_QUESTION = [
  'unique',
  'multiple',
  'logic',
];

export const QUESTION_TYPES_ALLOWED_TO_CREATE_OCCURRENCE = [
  'logic',
  'unique',
  'multiple',
];

export const QUESTION_TYPES_ALLOWED_TO_REQUEST_ATTACHMENT = [
  'logic',
  'unique',
  'multiple',
  'descriptive',
];

export const QUESTION_TYPES_ALLOWED_TO_REGISTER_ANSWER = ['unique', 'multiple'];

export const QUESTION_TYPES_ALLOWED_TO_HAVE_ANSWER_OPTION = [
  'unique',
  'multiple',
  'logic',
];

export const LOGICAL_QUESTION_SLUG = 'logic';

export const UNIQUE_QUESTION_SLUG = 'unique';

export const IMAGE_QUESTION_SLUG = 'image';

export const VIDEO_QUESTION_SLUG = 'video';

export const DESCRIPTIVE_QUESTION_SLUG = 'descriptive';

export const LOGICAL_QUESTION_ANSWERS = [
  {
    id: 1,
    order: 1,
    description: 'Não',
  },
  {
    id: 2,
    order: 2,
    description: 'Sim',
  },
];
