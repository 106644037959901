import { createContext } from 'utils/react-helpers';
import api from 'services/api';
import issuerSchema from './validator';

export const IssuerContext = createContext({
  name: 'Add Issuer',
  strict: false,
});

async function validate(data, setErrors) {
  const errorList = {};
  try {
    await issuerSchema.validate(data, { abortEarly: false });
    setErrors({ ...errorList });
    return true;
  } catch (err) {
    /* This loop creates a property with the path
    of the error and assign its error message to the value
    Example:
    err: {
      inner: {
        0: {path: 'client', message: 'Cliente obrigatório'}
        1: {path: 'product', message: 'Produto obrigatório'}
      }
    }
    */

    err.inner.forEach(error => {
      errorList[error.path] = error.message;
    });

    setErrors({ ...errorList });
    return false;
  }
}

export async function saveIssuer(
  data,
  setErrors,
  showSnack,
  setLoading,
  setShowModal,
  setRegisteredIssuer
) {
  setLoading(true);
  setErrors({});
  const { logo } = data;
  delete data.logo;
  if (!(await validate(data, setErrors))) {
    showSnack('Verifique os campos obrigatórios!', { type: 'error' });
    setLoading(false);
    return false;
  }

  try {
    const response = await api.post('issuers', data);

    if (logo) {
      const logoData = new FormData();
      logoData.append('file', logo[0]);
      await api.post(`issuers/media/${response.data.id}/LOGO`, logoData);
    }

    setRegisteredIssuer(response.data);

    setShowModal(true);
  } catch (err) {
    const message = err.response?.data[0]
      ? err.response?.data[0].message
      : err.response?.data.message
      ? err.response?.data.message
      : 'Erro ao criar emissor';
    showSnack(message, { type: 'error' });
  }

  setLoading(false);
  return true;
}

export async function fetchTaxSituations() {
  try {
    const { data: response } = await api.get('issuer-tax-situations');
    return response;
  } catch (err) {
    return [];
  }
}
