import React from 'react';

import { FaArrowRight, FaArrowDown, FaChevronRight } from 'react-icons/fa';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { formatDate, formatDateTime } from 'utils/formatter';
import CardRaw from 'v3/components/Card';
import Text from 'v3/components/Text';
import BulletList from 'v3/components/BulletList';
import Tooltip from 'v3/components/Tooltip';

function Card({ nfs }) {
  return (
    <Col md={10}>
      <CardRaw
        header={
          <Text color="gray" weight="500" type="header">
            Nota fiscal - {nfs.nf_number}
          </Text>
        }
      >
        <div className="d-flex justify-content-between align-items-center">
          <Tooltip
            content={<Text type="label">Remetente</Text>}
            placement="left"
          >
            <div>
              {nfs.sender?.social_name ? (
                <Text color="gray">
                  <FaArrowRight className="mr-1" /> {nfs.sender.social_name} -{' '}
                  {nfs.sender.cgccpf}
                </Text>
              ) : (
                <Text color="gray">Remetente - Não informado</Text>
              )}
            </div>
          </Tooltip>

          <Tooltip
            content={<Text type="label">Destinatário</Text>}
            placement="left"
          >
            <div>
              {nfs.receiver?.social_name ? (
                <Text color="gray">
                  <FaArrowDown className="mr-1" /> {nfs.receiver.social_name} -{' '}
                  {nfs.receiver.cgccpf}
                </Text>
              ) : (
                <Text color="gray">Destinatário - Não informado</Text>
              )}
            </div>
          </Tooltip>
        </div>

        <Col md={8} className="mt-3">
          <BulletList
            items={
              nfs?.travel?.map(travel => ({
                header: (
                  <Row md={12} className="align-items-center">
                    <Col md={12}>
                      <Text color="success" weight="500">
                        {formatDate(travel.created_at)}
                      </Text>
                    </Col>
                    <Col md={12}>
                      <Text color="gray" type="regular" weight="bold">
                        {travel.origin.city}, {travel.origin.province}
                      </Text>

                      <FaChevronRight
                        size={12}
                        color="#B5B5C3"
                        className="mr-1 ml-1"
                      />
                      <Text color="gray" type="regular" weight="bold">
                        {travel.destination.city}, {travel.destination.province}
                      </Text>
                    </Col>
                  </Row>
                ),
                filledCircle: true,

                body: (
                  <Row md={12} className="mt-2">
                    <Col md={12}>
                      <Link to={`/viagens/${travel.id}`}>
                        <Text color="light_blue" type="regular">
                          Viagem -{' '}
                          {travel.travel_number
                            ? travel.travel_number
                            : 'Não informado'}
                        </Text>
                      </Link>
                    </Col>
                    <Col md={12}>
                      <Text color="gray" type="regular">
                        Status {travel.status.toLowerCase()}
                      </Text>
                    </Col>

                    <Col md={12} className="mt-2">
                      <Text color="gray" type="label">
                        Ultima atualização em{' '}
                        {formatDateTime(travel.last_update)}
                      </Text>
                    </Col>
                  </Row>
                ),
              })) || []
            }
          />
        </Col>
      </CardRaw>
    </Col>
  );
}

export default Card;
