/* eslint-disable import/no-unresolved */
import React, { useEffect, useMemo, useState } from 'react';
import { FaSearch, FaDownload } from 'react-icons/fa';

import { capitalize, formatDateTime } from 'v3/utils/formatter';
import { useTravel } from 'v3/pages/Travel';
import { useSnackbar } from 'v3/components/Snackbar';
import api from 'services/api';

import Text from 'v3/components/Text';
import Radio from 'v3/components/Radio';
import Modal from 'v3/components/Modal';
import PDFView from 'v3/components/PDFView';
import Loader from 'v3/components/Loader';
import { validatePermission } from 'actions/index';
import Loading from 'components/Loading';
import * as Styled from './styles';

const AUTOMATIONS = {
  SEND_ATTACHMENT_KEPYA: 'Kepya',
  SEND_ATTACHMENT_TRUCKWEB: 'Truckweb',
  SEND_ATTACHMENT_OK_ENTREGA: 'Ok Entrega',
};

const AttachmentLabel = {
  accept: 'Aceito por: ',
  reject: 'Rejeitado por: ',
  resend: 'Solicitado reenvio por: ',
};

export default function Attachment({
  document,
  onClickPreview,
  fetchDocuments,
  emittedId,
  setLoading,
}) {
  const [isHovering, setHovering] = useState(false);
  const [isShowingModal, setShowingModal] = useState(false);
  const [isInFlight, setInFlight] = useState(false);
  const [status, setStatus] = useState();

  const travel = useTravel();

  const automations = useMemo(() => {
    const mapped = travel?.data?.settings
      ?.flatMap(automation => automation?.automations)
      .map(auto => AUTOMATIONS[auto?.slug]);

    return mapped.join(', ');
  }, [travel.data.settings]);

  const snackbar = useSnackbar();

  const attachment = useMemo(
    () => ({
      ...document.attachments[0],
      obs: document.obs_data,
      nf_number: document.nf_number,
    }),
    [document]
  );

  async function updateReview(newStatus) {
    try {
      setStatus(newStatus);
      setInFlight(true);
      const response = await api.post(`review-attachments/${attachment.id}`, {
        status: newStatus,
        load_id: travel.data.loadId,
        settings: travel.data.settings,
      });

      if (attachment.travel_doc_type === 'canhoto' && newStatus === 'accept') {
        if (response.data?.attach_send?.some(auto => auto?.code === 401)) {
          const errors = response.data.attach_send
            .map(auto => AUTOMATIONS[auto.automations])
            .filter(automation => automation !== undefined);

          throw new Error(errors.join(', '));
        } else {
          snackbar.show(
            <Text>{`Anexo aceito com sucesso${
              automations && `, enviado para ${automations}`
            } `}</Text>,
            { type: 'success' }
          );
          return;
        }
      }

      let message;
      switch (newStatus) {
        case 'accept':
          message = 'Anexo aceito com sucesso';
          break;
        case 'reject':
          message = 'Anexo rejeitado';
          break;
        case 'resend':
          message = 'Solicitação de reenvio realizada';
          break;
        default:
          message = 'Ação inesperada';
      }
      await fetchDocuments(emittedId, { setLoading, refetch: true });
      snackbar.show(<Text>{message}</Text>, { type: 'success' });
    } catch (ex) {
      snackbar.show(
        <Text>
          {ex?.message
            ? `Status alterado, mas houve erro nas seguintes automações: ${ex.message}`
            : 'Não foi possível alterar o status desse anexo'}
        </Text>,
        {
          type: 'warning',
        }
      );
    } finally {
      setInFlight(false);
    }
  }

  useEffect(() => {
    if (!travel.isLoading) {
      setStatus(attachment.status);
    }
  }, [attachment]);

  function handleClick() {
    if (document.isDocument) {
      setShowingModal(true);
    } else {
      onClickPreview();
    }
  }

  return (
    <div>
      <Modal
        show={isShowingModal}
        onHide={() => setShowingModal(false)}
        dialogClassName="modal-90w"
        heading={
          <Text color="dark" type="header" weight="500">
            Visualizar PDF
          </Text>
        }
        body={<PDFView file={attachment.path} />}
      />
      {!!attachment.nf_number && (
        <Text type="label" color="dark">
          Nota fiscal {attachment.nf_number}
        </Text>
      )}
      <div
        className="position-relative"
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        style={{
          width: 270,
          height: 160,
          background: `url(${
            document.isDocument ? '/images/default-doc.png' : attachment.path
          }) center center`,
          backgroundSize: '100%',
        }}
      >
        {isHovering && (
          <Styled.Backdrop>
            <button type="button" onClick={handleClick}>
              <FaSearch color="#fff" size={32} />
            </button>
            <a href={attachment.path} download>
              <FaDownload color="#fff" size={32} />
            </a>
          </Styled.Backdrop>
        )}
        {isInFlight && (
          <Styled.Backdrop>
            <Loader size="md" loading={isInFlight} variant="light" />
          </Styled.Backdrop>
        )}
        <Styled.DateTime>
          <Text type="label" color="white">
            {formatDateTime(document.created_at)}
          </Text>
        </Styled.DateTime>
      </div>
      <div className="d-flex justify-content-center mt-1">
        {validatePermission('APROVACAO_POS_VENDA') ? (
          <Radio.Group
            value={status}
            horizontal
            onChange={({ target }) => updateReview(target.value)}
            disabled={isInFlight}
          >
            <Radio value="accept">
              <Text type="regular" color="dark">
                Aceitar
              </Text>
            </Radio>
            <Radio value="resend">
              <Text type="regular" color="dark">
                Reenviar
              </Text>
            </Radio>
            <Radio value="reject">
              <Text type="regular" color="dark">
                Rejeitar
              </Text>
            </Radio>
          </Radio.Group>
        ) : null}
      </div>
      <Styled.AttachmentDetailsContainer>
        <Text type="label" color="dark">
          Incluído por: {attachment?.userCreated?.username}
        </Text>
        {attachment?.userUpdated && attachment?.status !== 'pending' ? (
          isInFlight ? (
            <Loading />
          ) : (
            <Text type="label" color="dark">
              {AttachmentLabel[attachment.status]}
              {capitalize(attachment?.userUpdated?.username)} em{' '}
              {formatDateTime(attachment?.updated_at)}
            </Text>
          )
        ) : null}
      </Styled.AttachmentDetailsContainer>
    </div>
  );
}
