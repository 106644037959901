import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import ApplicationLayout from 'v3/layouts/Application';
import Space from 'v3/components/Space';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import { usePermission } from 'hooks';
import Data from './Data';

export default function CostCenterDetails() {
  const history = useHistory();
  const hasPermissionToCreateCostCenter = usePermission(
    'CADASTRAR_CENTRO_CUSTO'
  );

  return (
    <ApplicationLayout
      title="Centro de custo"
      RightComponent={
        <Space x="4px">
          <Button variant="secondary" onClick={() => history.goBack()}>
            <Text color="dark" weight={500} type="regular">
              Voltar
            </Text>
          </Button>
          {hasPermissionToCreateCostCenter && (
            <Link to="cadastrar">
              <Button>
                <Text color="white" weight={500} type="regular">
                  Novo centro de custo
                </Text>
              </Button>
            </Link>
          )}
        </Space>
      }
    >
      <Row>
        <Col xs={12} md={6}>
          <Data />
        </Col>
      </Row>
    </ApplicationLayout>
  );
}
