import React from 'react';
import { Row } from 'react-bootstrap';
import { Button, Text } from 'v3/components';
import { useRiskManager } from '../../context';
import { HeaderDetails } from '../../components/HeaderDetails';
import { FooterDetails } from '../../components/FooterDetails';
import { FooterButtonsContainer } from '../../styles';
import { PolicyRiskManagerEditing } from './policyRiskManagerEditing';

export function PolicyRiskManagerEditingComponent() {
  const {
    feedModal,
    manualValidationModal,
    hasEditPermissionRiskManager,
    isEditing,
  } = useRiskManager();
  return (
    <Row>
      <HeaderDetails />
      <PolicyRiskManagerEditing />
      <FooterDetails />
      <FooterButtonsContainer>
        {hasEditPermissionRiskManager ? (
          <>
            <Button
              variant="secondary"
              onClick={() => feedModal.open()}
              disabled={isEditing}
            >
              <Text type="regular" weight="500">
                Incluir Feed
              </Text>
            </Button>

            <Button
              variant="secondary"
              onClick={() => manualValidationModal.open()}
              disabled={isEditing}
            >
              <Text type="regular" weight="500">
                Validações manuais
              </Text>
            </Button>
          </>
        ) : null}
      </FooterButtonsContainer>
    </Row>
  );
}
