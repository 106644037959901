import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, Text } from 'v3/components';

import { StyledModal, StyledSpace } from './styles';

import { QuestionSchema } from '../../shared/validator';
import { cooperplaceApi } from 'services/api';
import { useSnackbar } from 'v3/components/Snackbar';
import { RegisterComponent } from '../../components/RegisterComponents';

export function RegisterModal({ isOpen, onClose }) {
  const snackbar = useSnackbar();
  const history = useHistory();
  const [questionType, setQuestionType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [question, setQuestion] = useState({
    needAttachment: false,
    fatherQuestion: null,
    hasSubQuestion: false,
    createOccurrence: false,
    active: true,
    answers: [],
    questionDescription: '',
  });

  const [childQuestion, setChildQuestion] = useState({
    hasSubQuestion: false,
    answers: [],
    active: true,
    questionDescription: '',
  });
  const [childQuestionType, setChildQuestionType] = useState(null);
  function handleCloseModal() {
    setQuestion({
      needAttachment: false,
      fatherQuestion: null,
      hasSubQuestion: false,
      createOccurrence: false,
      active: true,
      answers: [],
      questionDescription: '',
    });
    setChildQuestion({ hasSubQuestion: false, answers: [], active: true });
    setErrors({});
    onClose();
  }

  async function handleCreateQuestion() {
    const response = await handleCreate({
      question,
      questionType,
      childQuestion,
      childQuestionType,
    });
    if (response === false) {
      return;
    } else handleCloseModal();
  }

  async function validate(data, setErrors) {
    setErrors({});
    const errorList = {};

    try {
      await QuestionSchema.validate(data, {
        abortEarly: false,
      });
      setErrors({ ...errorList });

      return true;
    } catch (err) {
      err.inner.forEach(error => {
        errorList[error.path] = error.message;
      });

      setErrors({ ...errorList });

      return false;
    }
  }

  async function handleCreate(data) {
    try {
      setLoading(true);
      const dataToValidate = {
        question: {
          ...data?.question,
          occurrenceSector: data?.question?.occurrenceSector?.id || null,
          occurrenceTrigger:
            data?.question?.createOccurrence &&
            data?.question?.answers?.filter(item => item.triggerOccurrence)
              ?.length > 0
              ? true
              : data?.question?.createOccurrence &&
                data?.question?.answers?.filter(item => item.triggerOccurrence)
                  ?.length <= 0
              ? null
              : false,
          subQuestionTrigger:
            data?.question?.hasSubQuestion &&
            data?.question?.answers?.filter(item => item.triggerSubQuestion)
              ?.length > 0
              ? true
              : data?.question?.hasSubQuestion &&
                data?.question?.answers?.filter(item => item.triggerSubQuestion)
                  ?.length <= 0
              ? null
              : false,
          typeSlug: data?.questionType?.slug,
        },
        questionType: data?.questionType,
        childQuestion: {
          ...data?.childQuestion,
          occurrenceSector: data?.childQuestion?.occurrenceSector?.id || null,
          occurrenceTrigger:
            data?.childQuestion?.createOccurrence &&
            data?.childQuestion?.answers?.filter(item => item.triggerOccurrence)
              ?.length > 0
              ? true
              : data?.childQuestion?.createOccurrence &&
                data?.childQuestion?.answers?.filter(
                  item => item.triggerOccurrence
                )?.length <= 0
              ? null
              : false,
        },
        childQuestionType: data?.childQuestionType,
      };
      const isDataValid = await validate(dataToValidate, setErrors);
      if (isDataValid === false) {
        setLoading(false);
        return false;
      }
      const payload = {
        typeId: data?.questionType?.id,
        active: data?.question?.active,
        question: data?.question?.questionDescription,
        occurrenceSectorId: data?.question?.occurrenceSector?.id || null,
        needAttachment: data?.question?.needAttachment,
        values:
          data?.question?.answers?.length > 0
            ? data?.question?.answers?.map(item => {
                let triggerSubQuestion = item?.triggerSubQuestion;
                delete item.triggerSubQuestion;
                return { ...item, subQuestion: triggerSubQuestion };
              })
            : [],
        subQuestion: data?.question?.hasSubQuestion
          ? {
              typeId: data?.childQuestionType?.id,
              question: data?.childQuestion?.questionDescription,
              occurrenceSectorId:
                data?.childQuestion?.occurrenceSector?.id || null,
              values:
                data?.childQuestion?.answers?.length > 0
                  ? data?.childQuestion?.answers?.map(item => {
                      return { ...item };
                    })
                  : [],
            }
          : null,
      };
      const response = await cooperplaceApi.post('questions', payload);
      snackbar.show(<Text>Pergunta cadastrada com sucesso</Text>, {
        type: 'success',
      });
      history.push(`/checklist/perguntas/${response?.data?.id}`);
    } catch (error) {
      const message =
        error?.response?.data?.error || 'Erro ao tentar cadastrar pergunta.';
      snackbar.show(<Text>{message}</Text>, {
        type: 'error',
      });
      return false;
    } finally {
      setLoading(false);
    }
  }

  return (
    <StyledModal
      show={isOpen}
      handleClose={handleCloseModal}
      heading={
        <Text type="regular" color="dark" weight="500">
          Criar nova pergunta
        </Text>
      }
      body={
        <RegisterComponent
          isRegister={true}
          question={question}
          setQuestion={setQuestion}
          questionType={questionType}
          setQuestionType={setQuestionType}
          childQuestion={childQuestion}
          setChildQuestion={setChildQuestion}
          childQuestionType={childQuestionType}
          setChildQuestionType={setChildQuestionType}
          errors={errors}
          setErrors={setErrors}
        />
      }
      footer={
        <StyledSpace x="0.5rem">
          <Button
            variant="secondary"
            loading={loading}
            disabled={loading}
            onClick={() => handleCloseModal()}
          >
            <Text weight={500}>Cancelar</Text>
          </Button>
          <Button
            onClick={() => handleCreateQuestion()}
            loading={loading}
            disabled={loading}
          >
            <Text weight={500}>Salvar</Text>
          </Button>
        </StyledSpace>
      }
    />
  );
}
