/* eslint-disable import/no-unresolved */
import styled, { css } from 'styled-components';
import { Col as BootstrapCol } from 'react-bootstrap';
import Button from 'v3/components/Button';

import theme from 'v3/theme';

export const Marker = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${theme.colors.medium_gray};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 4px;
  left: -9px;


  ${({ progress, completed }) =>
    completed && progress &&
    css`
    border-color: ${theme.colors.success};
    background-color: ${theme.colors.success};
  `}

  ${({ progress, completed }) =>
    progress && !completed &&
    css`
      border-color: ${theme.colors.orange};
      background-color: ${theme.colors.orange};
  `}

  ${({ progress, completed }) =>
    !progress && !completed &&
    css`
    border-color: ${theme.colors.medium_gray};
    background-color: ${theme.colors.medium_gray};
  `}
`;

export const EditButton = styled(Button)`
  margin-left: 40%;
  margin-top: 5%;
`;

Marker.Tray = styled.div`
  border-left: 2px dashed ${theme.colors.medium_gray};
  height: 100px;
  width: 0;
  margin: 0 auto;

  ${({ progress, completed }) =>
    completed && progress &&
    css`
  border-color: ${theme.colors.success};
  background-color: ${theme.colors.white};
`}

${({ progress, completed }) =>
    progress && !completed &&
    css`
    border-color: ${theme.colors.orange};
    background-color: ${theme.colors.white};
  `}

  ${({ progress, completed }) =>
    !progress && !completed &&
    css`
    border-color: ${theme.colors.medium_gray};
    background-color: ${theme.colors.white};
  `}
`;

export const Schedules = styled(BootstrapCol)`
  padding: 15px;
  border-radius: 5px;
  border: 1px solid ${theme.colors.secondary};
  margin-bottom: 35px;

  ${({ progress, completed }) =>
    completed && progress &&
    css`
    border-color: ${theme.colors.light_green};
    background-color: ${theme.colors.light_green};
  `}

  ${({ progress, completed }) =>
    progress && !completed &&
    css`
      border-color: ${theme.colors.yellow_indicator};
      background-color: ${theme.colors.yellow_indicator};
    `}

    ${({ progress, completed }) =>
    !progress && !completed &&
    css`
      border-color: ${theme.colors.white};
      background-color: ${theme.colors.white};
    `}
`;