import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import InfiniteScroll from 'components/InfiniteScroll';
import InfoMessage from 'components/InfoMessage';
import Snackbar from 'components/Snackbar';
import Card from 'components/Card';
import Button from 'components/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { downloadCSV } from 'functions';
import api from 'services/api';
import filterLocations from 'utils/filterLocations';
import { validatePermission } from 'actions/index';
import CellSpeed from './CellSpeed';
import ClientSelector from '../client-selector';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

let token = 0;
function SpeedReport() {
  const query = useQuery();
  const [filter, setFilter] = useState('');
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [isSearching, setIsSearching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cardLoading, setCardLoading] = useState(false);
  const [completed, setCompleted] = useState(0);
  const [loadingLabel, setLoadingLabel] = useState('');
  const [isFetched, setIsFetched] = useState(false);
  // This state tells if there was an errror on InfiniteScroll or not
  const [error, setError] = useState(false);
  const hasPermissionToAcess = validatePermission(
    'VISUALIZAR_RELATORIO_VELOCIDADES'
  );

  useEffect(() => {
    if (!hasPermissionToAcess) window.location.href = '/';
  }, []);

  const [snackBar, setSnackBar] = useState({
    display: false,
    type: 'error',
    message: '',
  });

  function checkDate(
    start = query.get('start_date'),
    end = query.get('end_date')
  ) {
    if (!moment(start).isValid()) {
      setSnackBar({
        display: true,
        type: 'error',
        message: 'Período "de" inválido.',
      });
      return false;
    }
    if (!moment(end).isValid()) {
      setSnackBar({
        display: true,
        type: 'error',
        message: 'Período "até" inválido.',
      });
      return false;
    }
    if (moment(end).diff(start, 'days') > 30) {
      setSnackBar({
        display: true,
        type: 'error',
        message: 'Diferença das datas não pode ser maior que 30 dias!',
      });

      return false;
    }

    return true;
  }

  async function loadData() {
    const companies = query.get('companies');
    const start = query.get('start_date') || moment().format('YYYY-MM-DD');
    const end = query.get('end_date') || moment().format('YYYY-MM-DD');
    const tags = query.getAll('tags');
    const cost_center_id = query.get('cost_center_id') || '';

    if (!checkDate(start, end)) {
      return;
    }

    setIsSearching(true);

    const new_filter = `companies=${companies}&tags=${tags}&cost_center_id=${cost_center_id}&start_date=${start}&end_date=${end}&token=${token - 1
      }`;
    // Only allows user to search if search is different from the last one OR
    // the last one returned an error.
    if (new_filter !== filter || error) {
      setFilter(
        `companies=${companies}&tags=${tags}&cost_center_id=${cost_center_id}&start_date=${start}&end_date=${end}&token=${token++}`
      );
      setError(false);
    } else {
      setIsSearching(false);

      setSnackBar({
        display: true,
        type: 'error',
        message: 'Os resultados para este filtro já estão sendo mostrados!  ',
      });
    }
  }

  async function nextPage(page = 1) {
    const response = await api.get(`travel-speed/export?companies=${query.get('companies')
      }&start_date=${query.get('start_date')
      }&end_date=${query.get('end_date')
      }&tags=${query.get('tags')
      }&cost_center_id=${query.get('cost_center_id')
      }&page=${page
      }`);
    if (response.status === 200) {
      setData(oldData => [...oldData, ...response.data.data]);
      setCompleted(((response.data.page / response.data.lastPage) * 100) / 2);
    }
  }

  async function loadExportData(page = 1) {
    if (!checkDate()) return;

    setCompleted(0);
    setData([]);

    if (
      query.get('companies') === undefined ||
      query.get('companies') === null ||
      !query.get('companies')
    ) {
      setSnackBar({
        display: true,
        type: 'error',
        message: 'Selecione um cliente',
      });
      setLoading(false);
      return;
    }

    const response = await api.get(`travel-speed/export?companies=${query.get('companies')
      }&start_date=${query.get('start_date')
      }&end_date=${query.get('end_date')
      }&tags=${query.get('tags')
      }&cost_center_id=${query.get('cost_center_id')
      }&page=${page
      }`);

    if (response.status === 200) {
      if (response.data.total === 0) {
        setSnackBar({
          display: true,
          type: 'error',
          message: 'Sem resultados para o período selecionado',
        });

        return;
      }

      setLoading(true);
      setLoadingLabel('Buscando as viagens...');
      const ids = Array.from(
        { length: response.data.lastPage },
        (v, k) => k + 1
      );
      await ids.reduce((p, x) => p.then(_ => nextPage(x)), Promise.resolve());
      setLoading(false);
    }
  }

  useEffect(() => {
    if (error) {
      setTotal(0);
    }
  }, [error]);

  useEffect(() => {
    async function process(data) {
      await processDataCSV(data);
    }

    if (data.length > 0 && !loading) {
      process(data);
    }
  }, [loading, data]);

  async function processDataCSV(dataLoaded = []) {
    let csvData = [];
    let count = 1;
    setCardLoading(true);
    setLoadingLabel('Buscando as velocidades...');
    const csv = [];
    await Promise.all(
      dataLoaded.map(async el => {
        let maxSpeed;
        let qtySpeed;
        try {
          const data = filterLocations(el);
          maxSpeed = data.maxSpeed;
          qtySpeed = data.qtySpeed;
        } catch (e) {
          maxSpeed = qtySpeed = '';
        }

        setCompleted(completed + ((count / dataLoaded.length) * 100) / 2);
        count++;

        if (!qtySpeed || el.userAlerts.length < 1) return { no_travels: true };
        await Promise.all(
          el.userAlerts.map(async el2 => {
            const metadata = JSON.parse(el2.metadata);
            const item = {
              'N Pedido Cliente': el.load.client_order_number || '',
              'Centro de Custo': el.load.costCenter.name || '',
              'TAGS': '',
              'N viagem Cooperplace': el.id || '',
              'N viagem Benner': el.travel_number || '',
              'Data Criação Viagem': el.created_at || '',
              'Origem': `"${el.origins.length > 0 ? el.origins[0].formatted : ''}"`,
              'Destino': `"${el.destinations.length > 0
                ? el.destinations[el.destinations.length - 1].formatted
                : ''
                }"`,
              'Cliente Tomador': el.load.client ? el.load.client.label : '',
              'Transportador': el.shipper ? el.shipper.label : '',
              'Motorista': el.driver.name || '',
              'CPF': el.driver.cgccpf || '',
              'Placa': el.vehicleData.plate || '',
              'Data/Hora': moment(el2.captured_at).format('DD/MM/YYYY HH:mm') || '',
              'Velocidade km/h': parseFloat(metadata.params.speed).toFixed(0) || '',
            };
            csv.push(item)
          })
        )
      })
    );

    setLoadingLabel('Fazendo o download...');
    const filtered = csv.filter(e => !e.no_travels);
    csvData = filtered.map(el => {
      return Object.values(el);
    });

    const header =
      'N Pedido Cliente;Centro de Custo;TAGS;N viagem Cooperplace;N viagem Benner;Data Criação Viagem;Origem;Destino;Cliente Tomador;Transportador;Motorista;CPF;Placa;Data/Hora;Velocidade km/h;';
    downloadCSV(
      header,
      csvData,
      `relatorio-velocidades${moment().format('DD-MM-YYYY')}.csv`,
      'text/csv;encoding:utf-8'
    );
    setCardLoading(false);
  }

  async function onDataFetched(d) {
    let total = 0;
    await d.data.forEach(item => {
      if (item.qtyAbove > 0) {
        total=total+1;
      }
    })
    setIsFetched(true);
    setIsSearching(false);
    setTotal(total);
  }

  function onDataFailure({ error: err }) {
    setError(err);
    setIsSearching(false);
  }

  return (
    <div>
      <Snackbar
        open={snackBar.display}
        type={snackBar.type}
        message={snackBar.message}
        onClose={() => setSnackBar(false)}
      />
      <Dialog
        open={loading || cardLoading}
        maxWidth="sm"
        fullWidth
        minWidth="sm"
        aria-labelledby="Relatório de viagens"
      >
        <DialogTitle>Relatório</DialogTitle>
        <DialogContent>
          {loadingLabel}
          <LinearProgress
            variant="determinate"
            value={completed}
            color="primary"
          />
          {completed.toFixed(0)}%
          <br />
        </DialogContent>
      </Dialog>
      <Card style={{ marginBottom: '10px', overflow: 'visible' }}>
        <Grid container spacing={2}>
          <ClientSelector
            url="/relatorios/velocidades"
            buttonName="Listar Viagens"
            onClientSelected={() => { }}
            onListReports={loadData}
            pathname="velocidades"
            loading={isSearching}
            setSnackBar={setSnackBar}
            hasClient={false}
            hasTags
            hasCostCenter
          />
          <Grid item xs={10}>
            {/* só mostrar o total quando a quantidade estiver vindo certa do backend!! */}
            {isFetched
              ? [
                <Typography
                  variant="subtitle1"
                  style={{ fontSize: '18px', marginBottom: '10px' }}
                >
                  Viagens:
                  <strong style={{ color: '#1C4B75' }}> {total}</strong>
                </Typography>,
              ]
              : ''}
          </Grid>
        </Grid>
      </Card>
      {validatePermission('EXPORTAR_RELATORIO_VELOCIDADES') && (
        <Card style={{ marginBottom: '10px' }}>
          <Button
            style={{ marginTop: '25px' }}
            disabled={loading}
            onClick={() => loadExportData()}
          >
            Exportar
          </Button>
        </Card>
      )}
      <div aria-label="Relatório de velocidades" style={{ height: '60vh' }}>
        <InfiniteScroll
          endpoint="travel-speed"
          filter={filter}
          initialFetch={false}
          onDataFetched={onDataFetched}
          onDataFailure={onDataFailure}
          placeholderHeight={145}
          renderItem={(item, index) => <CellSpeed key={index} travel={item} />}
          endMessage={
            filter !== '' ? (
              total > 0 && (
                <Grid
                  container
                  item
                  xs={12}
                  justify="center"
                  style={{ marginTop: '20px' }}
                >
                  <InfoMessage
                    icon="arrow-right"
                    width="auto"
                    snackColor="#8c8c8c"
                    message="Sem mais itens para exibição."
                  />
                </Grid>
              )
            ) : (
              <Grid
                container
                item
                xs={12}
                justify="center"
                style={{ marginTop: '20px' }}
              >
                <InfoMessage
                  icon="arrow-right"
                  width="auto"
                  snackColor="#8c8c8c"
                  message="Preencha os filtros para visualizar o relatório"
                />
              </Grid>
            )
          }
        />
        {total === 0 && filter !== null && (
          <Grid
            container
            item
            xs={12}
            justify="center"
            style={{ marginTop: '20px' }}
          >
            <InfoMessage
              icon="arrow-right"
              width="auto"
              snackColor="#8c8c8c"
              message="A busca não retornou nenhum resultado, revise os filtros"
            />
          </Grid>
        )}
      </div>
    </div>
  );
}

export default SpeedReport;
