import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import api from 'services/api';
import { useHistory } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UpdateToDriverDialogComponent(props) {
  let history = useHistory();
  const [open, setOpen] = React.useState(true);

  const handleUpdate = async () => {
    await api.put(`driver/${props.cpfID}/turn-on`);
    setOpen(false);
    history.push(`/motoristas/${props.cpfID}`);
  };

  const handleClose = () => {
    setOpen(false);
    history.push(`/motoristas`);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        <DialogTitle>O Usuário já Existe!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            O Cpf {props.cpf} já está cadastrado como transportador, deseja
            utilizar os mesmos dados para atualizá-lo como motorista?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Não</Button>
          <Button onClick={handleUpdate}>Sim</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
