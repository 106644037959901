import React from 'react';
import {
  Close as CloseIcon,
  Info as InfoIcon,
  Error as ErrorIcon,
  Warning as WarningIcon,
  CheckCircle as CheckCircleIcon,
  Help as HelpIcon,
  Report as ReportIcon,
  DoneAll as DoneAllIcon,
  ArrowRightAlt as ArrowRightAltIcon,
} from '@material-ui/icons';

/**
 *          @Material_UI___Icons___React
 * https://material-ui.com/components/material-icons/
 *
 * Utilizar o link ACIMA para pesquisar novos icones!
 * Importe junto com os outros na linha 2 e coloque no switch abaixo
 *
 * @param iconName {string} - Nome do icone a ser procurado
 *
 */

export function FindIcon(iconName) {
  try {
    switch (iconName.toLowerCase().trim()) {
      case 'info':
        return <InfoIcon />;
      case 'help':
        return <HelpIcon />;
      case 'report':
        return <ReportIcon />;
      case 'close':
        return <CloseIcon />;
      case 'error':
        return <ErrorIcon />;
      case 'warning':
        return <WarningIcon />;
      case 'checkcircleicon':
        return <CheckCircleIcon />;
      case 'doneall':
        return <DoneAllIcon />;
      case 'arrow-right':
        return <ArrowRightAltIcon />;
      // Exemplo de um novo icone
      // case '[NOME NOVO ICONE]':
      // return <[CLASSE NOVO ICONE] />;

      default:
        return <ReportIcon />;
    }
  } catch (ex) {
    return <ReportIcon />;
  }
}
