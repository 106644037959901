import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { Row, Col } from 'react-bootstrap';
import api, { cooperplaceApi } from 'services/api';
import Text from 'v3/components/Text';
import Input from 'v3/components/Input';
import Button from 'v3/components/Button';
import DatePicker from 'v3/components/DatePicker';
import InputAddress from 'v3/components/InputAddress';
import Select from 'v3/components/Select';
import { useSnackbar } from 'v3/components/Snackbar';
import { useTravel } from 'v3/pages/Travel';
import { useModal } from 'hooks';
import MinimumMarginModal from '../../Modal/MinimumMargin';
import ConflictDriverModal from '../../Modal/ConflictDriver';

function Edit({ isOrigin, address, client, loadId, action }) {
  const snackbar = useSnackbar();
  const travel = useTravel();
  const [clientData, setClientData] = useState(client);
  const [provinces, setProvinces] = useState();
  const [errors, setErrors] = useState();
  const [loading, setLoading] = useState(false);
  const lowerMarginModal = useModal();
  const driverAvailableModal = useModal();
  const [disableNumber, setDisableNumber] = useState(false);
  const [disableZipCode, setDisableZipCode] = useState(false);
  const [disableCity, setDisableCity] = useState(false);
  const [disableProvince, setDisableProvince] = useState(false);
  const [disableNeighborhood, setDisableNeighborhood] = useState(false);
  const [validateDriverProps, setValidateDriverProps] = useState({
    conflictTravels: [],
    driver: {},
  });

  const [place, setPlace] = useState({
    id: address.id,
    action: address.action,
    address: address.address,
    number: address.number,
    complement: address.complement,
    neighborhood: address.neighborhood,
    city: {
      name: address.city,
      id: address.cityId,
    },
    province: {
      name: address.province,
      id: provinces?.find(p => p.uf === address.province)?.id,
    },
    country: {
      abbreviation: address.abbreviation,
      country_slug: address.country_slug || address.countrySlug,
      currency: address.currency,
      currency_symbol: address.currency_symbol || address?.currencySymbol,
      ddi: address.ddi,
      id: address.id,
      name: address.name,
    },
    scheduled_time:
      address.scheduled_time || address.scheduledTime
        ? moment(address.scheduled_time || address.scheduledTime).format(
            'DD/MM/YYYY HH:mm'
          )
        : '',
    lat: address.lat,
    lng: address.lng,
    formatted_address: address.formatted_address || address.formattedAddress,
    zip_code: address.zip_code || address.zipCode,
    start_schedule:
      address.start_schedule || address.startSchedule
        ? moment(address.start_schedule || address.startSchedule).format(
            'DD/MM/YYYY HH:mm'
          )
        : '',
  });

  useEffect(() => {
    disableAddress(place);
  }, [place]);

  function completAddress(newAddress) {
    if (newAddress) {
      setPlace({
        ...place,
        address: newAddress.address || '',
        neighborhood: newAddress.neighborhood || '',
        number: newAddress.number || '',
        complement: newAddress.name || '',
        province: { name: newAddress.province || '' },
        city: {
          id: newAddress?.city_id,
          name: newAddress.city || '',
        },
        formatted_address: newAddress.formatted_address,
        zip_code: newAddress.zip_code || '',
        lat: newAddress.lat,
        lng: newAddress.lng,
      });
    }
  }

  function disableAddress(address) {
    setDisableNumber(!!address.number);
    setDisableZipCode(!!address.zip_code);
    setDisableCity(!!address.city);
    setDisableProvince(!!address.province);
    setDisableNeighborhood(!!address.neighborhood);
  }

  async function fetchCities(search) {
    try {
      const response = await api.get('cities', { params: { search } });
      return response.data;
    } catch (ex) {
      return [];
    }
  }

  function validate() {
    const errors = { hasErrors: false };
    try {
      if (!place.address) {
        errors.address = 'Informe o endereço';
        errors.hasErrors = true;
      }

      if (!place.city) {
        errors.city = 'Informe a cidade';
        errors.hasErrors = true;
      }

      if (!place.province) {
        errors.province = 'Informe o estado';
        errors.hasErrors = true;
      }

      if (!place.scheduled_time) {
        errors.scheduled_time = 'Informe uma data';
        errors.hasErrors = true;
      }

      if (!place.start_schedule) {
        errors.start_schedule = 'Informe uma data';
        errors.hasErrors = true;
      }
      if (!place.lat) {
        errors.lat = 'Selecione um logradouro utilizando o seletor do google';
        errors.hasErrors = true;
      }
      if (!place.lng) {
        errors.lng = 'Selecione um logradouro utilizando o seletor do google';
        errors.hasErrors = true;
      }
    } catch (ex) {
      //
    }

    return errors;
  }

  async function validateDriver({
    originScheduledTime,
    destinationScheduledTime,
    driverId,
    travelId,
  }) {
    const validation = {
      travels: [],
      isOnAnotherTravel: false,
    };

    const response = await cooperplaceApi.get('travels/validateDriver', {
      params: {
        originScheduledTime,
        destinationScheduledTime,
        driverId,
        travelId,
      },
    });

    const { travels } = response.data;
    validation.travels = travels;
    validation.isOnAnotherTravel = !!travels.length;
    return validation;
  }

  async function handleSubmit({
    confirmMinimiumMargin = travel?.data?.confirmMinimiumMargin,
    passDriverValidation = travel?.data?.passDriverValidation,
  } = {}) {
    const err = validate();
    setErrors(err);

    if (err.hasErrors) {
      return;
    }

    if (
      place.start_schedule &&
      moment(place.scheduled_time, 'DD/MM/YYYY HH:mm').isBefore(
        moment(place.start_schedule, 'DD/MM/YYYY HH:mm')
      )
    ) {
      snackbar.show(<Text>Data máxima menor que data inicial</Text>, {
        type: 'error',
      });
      return;
    }
    if (!moment(place.scheduled_time, 'DD/MM/YYYY HH:mm').isValid()) {
      snackbar.show(<Text>Data inválida</Text>, {
        type: 'error',
      });
      return;
    }

    if (
      !isOrigin &&
      moment(place.scheduled_time, 'DD/MM/YYYY HH:mm').isBefore(
        travel.data.loads?.loadOrigins[0].scheduledTime
      )
    ) {
      snackbar.show(<Text>Data anterior ao da coleta agendada</Text>, {
        type: 'error',
      });
      return;
    }

    if (moment(place.scheduled_time, 'DD/MM/YYYY HH:mm').isBefore(new Date())) {
      snackbar.show(<Text>Data anterior a data atual</Text>, {
        type: 'error',
      });
      return;
    }

    try {
      setLoading(true);
      if (!passDriverValidation) {
        const validateDriverParams = {
          originScheduledTime: isOrigin
            ? moment(place.start_schedule, 'DD/MM/YYYY HH:mm').toISOString()
            : travel?.data?.loads?.loadOrigins[0].startSchedule,
          destinationScheduledTime: isOrigin
            ? travel?.data?.loads?.loadDestinations[0].scheduledTime
            : moment(place.scheduled_time, 'DD/MM/YYYY HH:mm').toISOString(),
          driverId: travel?.data?.driverData?.id,
          travelId: travel?.data?.id,
        };

        const { isOnAnotherTravel, travels } = await validateDriver(
          validateDriverParams
        );

        if (isOnAnotherTravel) {
          if (travels.length >= 2) {
            snackbar.show(
              <Text>
                {`O motorista está vinculado nas seguintes viagens nesse período: "${travels.join(
                  ', '
                )}"`}
              </Text>,
              {
                type: 'error',
              }
            );
            return;
          }

          if (travels.length === 1) {
            setValidateDriverProps(old => ({
              ...old,
              conflictTravels: travels,
              driver: travel?.data?.driverData,
            }));
            driverAvailableModal.open();
            return;
          }
        }
      }

      const data = {
        id: place.id,
        address: place.address,
        number: place.number,
        complement: place.complement,
        zipCode: place.zip_code,
        lat: place.lat,
        lng: place.lng,
        cityId: place.city.id,
        neighborhood: place.neighborhood,
        formattedAddress: place.formatted_address,
        scheduledTime: moment(place.scheduled_time, 'DD/MM/YYYY HH:mm'),
        startSchedule: moment(place.start_schedule, 'DD/MM/YYYY HH:mm'),
        province: place.province.name,
        clientId: clientData?.id,
        city: place.city.name,
        action: action === 'Coleta' ? 'collect' : 'delivery',
        country: place.country,
        load_id: loadId,
      };

      if (!confirmMinimiumMargin) {
        const newPlaceParams = isOrigin
          ? {
              origin: {
                ...data,
                countrySlug: data.country.country_slug,
              },
            }
          : {
              destinations: [
                {
                  ...data,
                  countrySlug: data.country.country_slug,
                },
              ],
            };

        const isMarginAbove = await travel.isMarginAbove(newPlaceParams);

        if (isMarginAbove) {
          lowerMarginModal.open();
          return;
        }
      }

      if (isOrigin) {
        await cooperplaceApi.put(`/loads/${loadId}/origin`, data);
      } else {
        await cooperplaceApi.put(
          `loads/${loadId}/destinations/${data.id}`,
          data
        );
      }
      snackbar.show(<Text>Endereço alterado com sucesso! </Text>, {
        type: 'success',
      });
      travel.fetchTravel();
    } catch (error) {
      snackbar.show(<Text> Erro ao alterar endereço</Text>, {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }

  async function fetchSelectOptions(url, params = {}) {
    try {
      const { data } = await api.get(url, { params });

      return data;
    } catch (err) {
      return [];
    }
  }

  async function handleConfirmLowerMargin({ confirmMinimiumMargin }) {
    travel.setData(old => ({ ...old, confirmMinimiumMargin }));
    lowerMarginModal.close();
    handleSubmit({ confirmMinimiumMargin });
  }

  useEffect(() => {
    async function fetchProvinces() {
      try {
        const response = await api.get('provinces');
        setProvinces(response.data);
      } catch (ex) {
        // Handle exeception
      }
    }

    fetchProvinces();
  }, []);

  return (
    <>
      <Row md={12} className="px-5 form">
        <Col md={12} xs={12} className="mb-2">
          <Select.Async
            label="Cliente Tomador"
            onSearch={search =>
              fetchSelectOptions('persons/customers', { search })
            }
            value={clientData}
            onChange={value => {
              setClientData(value);
            }}
            getOptionLabel={option => option.label}
            getOptionValue={option => option.id}
          />
        </Col>
        <Col md={8} xs={12} className="mb-2">
          <InputAddress
            label="Logradouro *"
            value={place.address}
            onSelectedAddress={address => {
              disableAddress(address);
              completAddress(address);
            }}
            onChange={event => {
              setPlace({
                ...place,
                address: event.target.value,
                number: '',
                complement: '',
                neighborhood: '',
                zip_code: '',
                lat: '',
                lng: '',
                cityModel: '',
                province: '',
              });
            }}
            types={[]}
            placeholder=""
            error={errors?.address}
          />
        </Col>
        <Col md={4} xs={12} className="mb-2">
          <Input
            label="Número"
            disabled={disableNumber}
            value={place.number}
            onChange={event =>
              setPlace({
                ...place,
                number: event.target.value,
              })
            }
          />
        </Col>
        <Col md={4} className="mb-2">
          <Input
            label="Complemento"
            value={place.complement}
            onChange={event =>
              setPlace({
                ...place,
                complement: event.target.value,
              })
            }
          />
        </Col>
        <Col md={4} className="mb-2">
          <Input
            label="Bairro"
            disabled={disableNeighborhood}
            value={place.neighborhood}
            onChange={event =>
              setPlace({
                ...place,
                neighborhood: event.target.value,
              })
            }
          />
        </Col>
        <Col md={4} className="mb-2">
          <Input
            label="CEP"
            disabled={disableZipCode}
            value={place.zip_code}
            onChange={event =>
              setPlace({
                ...place,
                zip_code: event.target.value,
              })
            }
          />
        </Col>
        <Col md={8} className="mb-2">
          <Select.Async
            label="Cidade *"
            isDisabled={disableCity}
            onSearch={fetchCities}
            value={place.city}
            onChange={value => {
              setPlace({
                ...place,
                city: value,
                province: value?.province?.uf,
              });
            }}
            getOptionLabel={option => `${option.name}`}
            getOptionValue={option => `${option.name}`}
            error={errors?.city}
          />
        </Col>
        <Col md={4} className="mb-2">
          <Select
            label="UF *"
            value={place.province}
            isDisabled={disableProvince}
            options={provinces}
            onChange={event => setPlace({ ...place, province: event })}
            getOptionLabel={option => `${option.name}`}
            getOptionValue={option => option.id}
            error={errors?.province}
          />
        </Col>
        <Col md={6}>
          <Input
            label="Latitude"
            disabled
            value={place?.lat}
            error={errors?.lat}
            tooltip="Selecione um endereço sugerido para obter dados de latitude e longitude"
          />
        </Col>
        <Col md={6}>
          <Input
            label="Longitude"
            disabled
            value={place?.lng}
            error={errors?.lng}
            tooltip="Selecione um endereço sugerido para obter dados de latitude e longitude"
          />
        </Col>

        <Col md={6} className="mb-3 mt-2">
          <DatePicker
            label={
              isOrigin === true
                ? 'Data início da coleta *'
                : ' Data ínicio da entrega *'
            }
            value={place.start_schedule}
            onChange={event => {
              setPlace({
                ...place,
                start_schedule: event.target.value,
              });
            }}
            withTime
            error={errors?.start_schedule}
          />
        </Col>

        <Col md={6} className="mb-3 mt-2">
          <DatePicker
            label={
              isOrigin === true
                ? 'Data máxima da coleta *'
                : ' Data máxima da entrega *'
            }
            value={place.scheduled_time}
            onChange={event =>
              setPlace({
                ...place,
                scheduled_time: event.target.value,
              })
            }
            withTime
            error={errors?.scheduled_time}
          />
        </Col>

        <Col
          className="d-flex justify-content-center align-items-center"
          md={12}
        >
          <Button
            onClick={() => handleSubmit()}
            className="py-2"
            loading={loading}
          >
            <Text type="regular" weight="500">
              Salvar
            </Text>
          </Button>
        </Col>
      </Row>
      <MinimumMarginModal
        isOpen={lowerMarginModal.isOpen}
        onClose={() => {
          travel.setData(old => ({ ...old, confirmMinimiumMargin: false }));
          lowerMarginModal.close();
        }}
        confirm={handleConfirmLowerMargin}
      />
      <ConflictDriverModal
        isOpen={driverAvailableModal.isOpen}
        onClose={() => {
          travel.setData(old => ({ ...old, passDriverValidation: false }));
          driverAvailableModal.close();
        }}
        onConfirm={({ passDriverValidation }) => {
          travel.setData(old => ({ ...old, passDriverValidation }));
          driverAvailableModal.close();
          handleSubmit({ passDriverValidation });
        }}
        travels={validateDriverProps?.conflictTravels}
        travelData={{ driver: validateDriverProps.driver }}
      />
    </>
  );
}

export default Edit;
