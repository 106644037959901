import React, { useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useParams } from 'react-router-dom';
import Select from 'v3/components/Select';
import { useImplement } from './context';
import { fetchOwners } from 'utils/fetches';

export function OwnerData() {
  const implement = useImplement();
  const params = useParams();

  useEffect(() => {
    if (!params.id) {
      implement.setData({
        owner: null,
        anttOwner: null,
        beneficiary: null
      });
    }
  }, []);

  return (
    <Row className="form">
      <Col xs={12}>
        <Select.Async
          label={'Proprietário do documento *'}
          onSearch={fetchOwners}
          onChange={value => implement.setData({ owner: value })}
          value={implement.data?.owner}
          getOptionLabel={option => option.label}
          getOptionValue={option => option.id}
          error={implement.errors?.owner}
        />
      </Col>
      <Col xs={12}>
        <Select.Async
          label='Beneficiário do implemento *'
          onSearch={fetchOwners}
          value={implement?.data?.beneficiary}
          horizontal
          onChange={value => { implement.setData({ beneficiary: value }) }}
          getOptionLabel={option => option.label}
          getOptionValue={option => option.id}
          error={implement.errors?.beneficiary}
        />
      </Col>
      <Col xs={12}>
        <Select.Async
          label={'Proprietário RNTRC'}
          onSearch={fetchOwners}
          onChange={value => implement.setData({ anttOwner: value })}
          value={implement.data?.anttOwner}
          getOptionLabel={option => option.label}
          getOptionValue={option => option.id}
          error={implement.errors.anttOwner}
        />
      </Col>
    </Row>
  );
}
