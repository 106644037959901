import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import theme from 'v3/theme';

export const useTableStyles = makeStyles({
  root: {
    width: '100%',
    boxShadow: '0px 0px transparent !important',
  },
  container: {
    maxHeight: 600,
    overflowX: 'auto',
    scrollbarWidth: 'thin',
    '&:hover::-webkit-scrollbar': {
      width: '0.25rem',
      height: '0.25rem',

      backgroundColor: theme.colors.light_gray,
    },
    '&:not(:hover)::-webkit-scrollbar': {
      width: '0.25rem',
      height: '0.25rem',

      opacity: 0,
    },
    '&:hover::-webkit-scrollbar-thumb': {
      backgroundColor: theme.colors.medium_gray,
      height: '0.25rem',
    },
    '&:not(:hover)::-webkit-scrollbar-thumb': {
      opacity: 0,
    },
  },
  pagination: {
    '& .MuiTablePagination-displayedRows': {
      marginTop: '1rem',
    },
    '& .MuiTablePagination-selectLabel': {
      marginTop: 'auto',
    },
  },
});

export const StyledSpan = styled.span`
  &::after {
    content: '';
    display: inline-block;
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    background-color: ${props => props.color};
  }
`;

export const StyledCellSpan = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  color: gray;
  font-size: 0.80rem;
  white-space: pre-wrap;
  cursor: ${props => (props.clickable ? 'pointer' : 'text')};
`;

export const StyledOccurrenceCel = styled.span`
  font-weight: bold;
  color: ${props => props.color};
`;
