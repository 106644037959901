import React from 'react';

import { useTravel } from '../..';

import { PolicyRiskManagerEditingComponent } from './withPolicyRiskManager/edit';
import { RiskManagerEditingComponent } from './withoutPolicyRiskManager/edit';

export function RiskManagerEditingCard() {
  const travel = useTravel();
  if (travel?.data?.policy?.id) {
    return <PolicyRiskManagerEditingComponent />;
  }
  return <RiskManagerEditingComponent />;
}
