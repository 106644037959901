import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';

import { Card, Text, Select, Input, Radio, Tooltip, Link } from 'v3/components';
import {
  currencyOptions,
  currencyWithSymbol,
  formatCurrency,
} from 'v3/utils/formatter';
import CurrencyInput from 'v3/components/Input/Currency';
import { useSnackbar } from 'v3/components/Snackbar';
import { FaQuestionCircle } from 'react-icons/fa';
import theme from 'v3/theme';
import { LoadContext } from 'v3/pages/Load/Register/controller';
import Loader from 'v3/components/Loader';
import { useLoadGro } from '../context';

const [, useLoad] = LoadContext;

export function VehiclesAndTracking() {
  const {
    selectedPolicy,
    errorsGro,
    vehicleTypes,
    bodiesTypes,
    implementTypes,
    loadDispositionOptions,
    dailyCotation,
    handleChangeCargoValue,
    selectedRange,
    loadingRange,
    managerTrackerOptions,
  } = useLoadGro();
  const { data, setData } = useLoad();

  const snackbar = useSnackbar();
  const shouldDisplayImplement = useMemo(
    () => data?.vehicleTypes?.some(selected => selected.qtdPlates > 1),
    [data?.vehicleTypes]
  );
  const visiblePallet = useMemo(
    () =>
      data?.disposition &&
      data?.disposition?.name?.toLowerCase() === 'paletizada',
    [data?.disposition]
  );
  function handleSearchRiskManagers(search) {
    if (!search) {
      return managerTrackerOptions;
    }
    const parsed = search?.toLocaleLowerCase();
    return managerTrackerOptions?.filter(
      option => option?.name?.toLocaleLowerCase().search(parsed) >= 0
    );
  }

  return (
    <Card>
      <div>
        <Row>
          <Col
            md={12}
            className="mb-2"
            style={{ paddingLeft: 0, paddingRight: 0 }}
          >
            <Text color="dark" type="header">
              Veículos e Monitoramento
              {selectedPolicy?.id ? (
                <Link to={`/apolices/${selectedPolicy?.id}`} target="_blank">
                  <Text className="ml-2" type="label" color="gray">
                    ver apólice
                  </Text>
                </Link>
              ) : null}
            </Text>
          </Col>
          <Row>
            <Col md={6} className="mb-3">
              <Select
                label="Tipo de Veículo *"
                options={vehicleTypes}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                value={data?.vehicleTypes}
                onChange={value => {
                  setData(old => ({
                    ...old,
                    vehicleTypes: value
                      ? value?.filter(
                          item =>
                            item.groupId === value[value?.length - 1].groupId
                        )
                      : [],
                    vehicleImplements: [],
                    vehicleBodies: [],
                    cargoValue: '0',
                  }));
                }}
                multiple
                error={errorsGro.vehicleTypes}
                id="select-gro-veiculos"
              />
            </Col>
            <Col md={4} sm={12} className="mb-3">
              <Select
                label="Disposição da carga *"
                options={loadDispositionOptions}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                value={data?.disposition}
                onChange={value =>
                  setData(old => ({ ...old, disposition: value }))
                }
                error={errorsGro.disposition}
                id="select-gro-disposicao-carga"
              />
            </Col>
            <Col md={2} sm={12} className="mb-3">
              {' '}
              <Input
                label={`Pallets${visiblePallet ? ' *' : ''}`}
                type="number"
                value={data?.pallets}
                onChange={({ target }) => {
                  target.value >= 0
                    ? setData(old => ({ ...old, pallets: target.value }))
                    : snackbar.show(
                        <Text>
                          Não utilize valores negativos para os pallets
                        </Text>,
                        {
                          type: 'error',
                        }
                      );
                }}
                error={errorsGro.pallets}
                disabled={!visiblePallet}
                id="input-gro-pallets"
              />
            </Col>
            <Col md={6} sm={12} className="mb-3">
              <Select
                label="Tipo de carroceria *"
                options={bodiesTypes?.filter(op =>
                  data?.vehicleTypes
                    ?.map(vt => vt.groupId)
                    ?.includes(op.groupId)
                )}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                value={data?.vehicleBodies}
                onChange={value =>
                  setData(old => ({
                    ...old,
                    vehicleBodies: value || [],
                    cargoValue: '0',
                  }))
                }
                multiple
                error={errorsGro.vehicleBodies}
                isDisabled={!data?.vehicleTypes?.length}
                id="select-gro-carroceria"
              />
            </Col>
            <Col md={3} sm={12} className="mb-3">
              <Input
                label="Peso bruto (em kg) *"
                type="number"
                min={1}
                value={data?.weight}
                onChange={({ target }) => {
                  if (target?.value?.length > 10) {
                    return;
                  }
                  setData(old => ({ ...old, weight: target.value }));
                }}
                error={errorsGro.weight}
                id="input-gro-peso-bruto"
              />
            </Col>
            <Col md={3} sm={12} className="mb-3">
              <Input
                label="Peso líquido (em kg)"
                type="number"
                value={data?.netWeight}
                onChange={({ target }) => {
                  target.value >= 0
                    ? setData(old => ({ ...old, netWeight: target.value }))
                    : snackbar.show(
                        <Text>
                          Não utilize valores negativos para o peso líquido!
                        </Text>,
                        {
                          type: 'error',
                        }
                      );
                }}
                error={errorsGro.netWeight}
                id="input-gro-peso-liquido"
              />
            </Col>
            <Col md={6} className="mb-3">
              {shouldDisplayImplement && (
                <Select
                  label="Tipo de implemento *"
                  options={implementTypes?.filter(op =>
                    data?.vehicleTypes
                      ?.map(vt => vt.groupId)
                      ?.includes(op.groupId)
                  )}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  value={data?.vehicleImplements}
                  onChange={value =>
                    setData(old => ({
                      ...old,
                      vehicleImplements: value || [],
                      cargoValue: '0',
                    }))
                  }
                  multiple
                  error={errorsGro.vehicleImplements}
                  id="select-gro-implemento"
                />
              )}
            </Col>
            <Col md={3} sm={12} className="mb-3">
              <Input
                label="Volume (em m³)"
                type="number"
                value={data?.volume}
                onChange={({ target }) => {
                  target.value >= 0
                    ? setData(old => ({ ...old, volume: target.value }))
                    : snackbar.show(
                        <Text>
                          Não utilize valores negativos para o volume!
                        </Text>,
                        {
                          type: 'error',
                        }
                      );
                }}
                id="input-gro-volume"
              />
            </Col>
            <Col md={3} sm={12} className="mb-3">
              <Radio.Group
                label="Necessita de MOPP?"
                onChange={({ target }) =>
                  setData(old => ({ ...old, mopp: target.value }))
                }
                value={data?.mopp}
                horizontal
              >
                <Radio value>
                  <Text color="dark" type="label">
                    Sim
                  </Text>
                </Radio>
                <Radio value={false}>
                  <Text color="dark" type="label">
                    Não
                  </Text>
                </Radio>
              </Radio.Group>
            </Col>
          </Row>
        </Row>
        <Row>
          <Col md={12} className="mb-2">
            <Text color="dark" type="header" as="p">
              Composição valor mercadoria
            </Text>
          </Col>
          <Col md={4} className="mb-3">
            <Select
              label="Tipo de moeda"
              getOptionLabel={option => option.label}
              getOptionValue={option => option.value}
              value={data?.load_currency || currencyOptions[0]}
              isDisabled
              id="select-gro-moeda-carga"
            />
          </Col>
          <Col md={4} className="mb-3">
            {dailyCotation[data.load_currency.value]?.value ? (
              <>
                <Text
                  type="label"
                  color="dark"
                  weight="500"
                  style={{ textAlign: 'center' }}
                >
                  {`Cotação ${
                    data?.load_currency
                      ? `(Valor de ${
                          dailyCotation[data.load_currency.value]?.label
                        })`
                      : ''
                  }`}
                </Text>
                <Text
                  type="regular"
                  color="gray"
                  as="p"
                  fontSize="16px"
                  style={{ marginTop: '1rem' }}
                  id="label-gro-cotacao-valor"
                >
                  {formatCurrency(
                    dailyCotation[data.load_currency.value]?.value,
                    'USD',
                    'R$'
                  )}
                </Text>
              </>
            ) : null}
          </Col>
          <Col md={4}>
            <CurrencyInput
              label={`Valor da carga *${
                data.load_currency
                  ? `(${
                      currencyWithSymbol.find(
                        option => option.currency === data.load_currency.value
                      )?.symbol
                    })`
                  : ''
              }`}
              value={data?.cargoValue}
              onChange={(_event, value) => handleChangeCargoValue(value)}
              error={errorsGro.cargoValue}
              disabled={
                !data?.vehicleTypes ||
                data?.vehicleTypes?.length === 0 ||
                !data?.vehicleBodies ||
                data?.vehicleBodies?.length === 0 ||
                (shouldDisplayImplement &&
                  (!data?.vehicleImplements ||
                    data?.vehicleImplements?.length === 0))
              }
              tooltip={
                !data?.vehicleTypes ||
                !data?.vehicleBodies ||
                (shouldDisplayImplement &&
                  (!data?.vehicleImplements ||
                    data?.vehicleImplements?.length === 0))
                  ? 'Informe os dados de veículos primeiro'
                  : ''
              }
              id="input-gro-valor-carga"
            />
          </Col>
        </Row>

        <Row>
          <Col
            md={12}
            style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}
            className="mb-2"
          >
            <Text color="dark" type="header">
              Rastreamento de carga
            </Text>
            {!selectedRange?.id ? (
              <Tooltip
                content={
                  <Text type="label">Informe o valor da carga primeiro</Text>
                }
              >
                <FaQuestionCircle style={{ color: theme.colors.light_blue }} />
              </Tooltip>
            ) : null}
          </Col>
          <Col md={4} className="mb-3">
            {loadingRange ? (
              <Loader size="md" loading={loadingRange} />
            ) : (
              <Radio.Group
                label="Rastreado"
                onChange={({ target }) => {
                  setData(old => ({ ...old, tracked: target.value }));
                  if (!target.value) {
                    setData(old => ({ ...old, tracked: '' }));
                  }
                }}
                value={data?.tracked}
                horizontal
                disabled
              >
                <Radio value>
                  <Text color="dark" type="label">
                    Sim
                  </Text>
                </Radio>
                <Radio value={false}>
                  <Text color="dark" type="label">
                    Não
                  </Text>
                </Radio>
              </Radio.Group>
            )}
          </Col>
          <Col md={4} className="mb-3">
            {loadingRange ? (
              <Loader size="md" loading={loadingRange} />
            ) : (
              <Radio.Group
                label="Carga escoltada"
                onChange={({ target }) =>
                  setData(old => ({ ...old, escorted: target.value }))
                }
                value={data?.escorted}
                horizontal
                disabled
              >
                <Radio value>
                  <Text color="dark" type="label">
                    Sim
                  </Text>
                </Radio>
                <Radio value={false}>
                  <Text color="dark" type="label">
                    Não
                  </Text>
                </Radio>
              </Radio.Group>
            )}
          </Col>
          <Col md={4}>
            {loadingRange ? (
              <Loader size="md" loading={loadingRange} />
            ) : (
              <Select.Async
                labelColor="dark"
                label={
                  managerTrackerOptions?.length > 0
                    ? 'GR para rastreamento *'
                    : 'GR para rastreamento'
                }
                value={data?.manager_tracker}
                placeholder="Selecione"
                options={managerTrackerOptions}
                onChange={value =>
                  setData(old => ({ ...old, manager_tracker: value }))
                }
                getOptionLabel={option => option.name ?? option?.label}
                getOptionValue={option => option.id}
                onSearch={search => handleSearchRiskManagers(search)}
                isDisabled={
                  !selectedRange?.id ||
                  managerTrackerOptions?.length === 0 ||
                  !managerTrackerOptions
                }
                error={errorsGro.managerTracker}
                multiple
                id="select-gro-liberacoes-gr"
              />
            )}
          </Col>
        </Row>
      </div>
    </Card>
  );
}
