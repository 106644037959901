/* eslint-disable import/no-unresolved */
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { validatePermission } from 'actions/index';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import ApplicationLayout from 'v3/layouts/Application';
import InfiniteScroll from 'v3/components/InfiniteScroll';
import { UserContext } from 'contexts/UserContext';
import { usePermission } from 'hooks';
import CardTravel from './Card';
import TravelFilter from '../Filter';
import * as Controller from './controller';

export default function ListTravels() {
  const { changedCompany, setChangedCompany } = useContext(UserContext);
  const history = useHistory();
  const [finalFilter, setFinalFilter] = useState('');
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
  const [qtdAdvancedFilters, setQtdAdvancedFilters] = useState(0);

  const [loading, setLoading] = useState(false);
  const [travels, setTravels] = useState([]);
  const [pagination, setPagination] = useState({
    lastPage: 1,
    page: 1,
    total: 0,
  });
  const hasPermission = usePermission('VISUALIZAR_VIAGENS', {
    redirect: true,
  });

  useEffect(() => {
    if (!hasPermission) {
      history.replace('/');
    }
  }, []);

  const [hasPermissionToCreate, setPermissionToCreate] = useState();
  useState(() => {
    setPermissionToCreate(validatePermission('CADASTRAR_VIAGENS'));
  }, []);

  function fetchTravels(filters = {}, page = 0) {
    Controller.getTravels(filters, page || pagination.page, {
      setTravels,
      setLoading,
      setPagination,
    });
  }

  const handleClose = () => setShowAdvancedFilters(false);
  const handleShow = () => setShowAdvancedFilters(true);

  function handleTitleAdvancedFilters() {
    let title = 'Filtro avançado';
    if (qtdAdvancedFilters) {
      title += ` (${qtdAdvancedFilters})`;
    }
    return title;
  }

  // Ao atualizar o filtro e "filtrar"
  useEffect(() => {
    if (finalFilter) {
      fetchTravels(finalFilter, 1);
    }
  }, [finalFilter]);

  function renderItem(items) {
    return <CardTravel travel={items} />;
  }

  useEffect(() => {
    if (changedCompany) {
      fetchTravels(finalFilter, 1);
      setChangedCompany(false);
    }
  }, [changedCompany]);

  return (
    <ApplicationLayout
      title="Viagens"
      RightComponent={
        <>
          <Button
            variant="secondary"
            onClick={() => handleShow()}
            className="py-2"
          >
            <Text weight={500} color="dark" type="regular">
              {handleTitleAdvancedFilters()}
            </Text>
          </Button>
          {hasPermissionToCreate && (
            <>
              <Button
                variant="primary"
                onClick={() => {
                  history.push('/viagens/cadastrar');
                }}
                className="py-2 ml-2"
              >
                <Text weight={500} color="white" type="regular">
                  Nova viagem
                </Text>
              </Button>
            </>
          )}
        </>
      }
    >
      <TravelFilter
        setFinalFilter={setFinalFilter}
        showAdvancedFilters={showAdvancedFilters}
        handleClose={handleClose}
        handleShow={handleShow}
        setQtdAdvancedFilters={setQtdAdvancedFilters}
      />

      <InfiniteScroll
        loading={loading}
        data={travels}
        renderItem={renderItem}
        onEndReach={() => {
          Controller.getTravels(finalFilter, pagination.page + 1, {
            setTravels,
            setLoading,
            setPagination,
          });
        }}
        hasMore={pagination.page < pagination.lastPage}
        scrollThreshold={0.7}
      />
    </ApplicationLayout>
  );
}
