import React, { useEffect, useMemo, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import Text from 'v3/components/Text';
import Select from 'v3/components/Select';
import Input from 'v3/components/Input';
import Button from 'v3/components/Button';
import Toggle from 'v3/components/Toggle';
import Modal from 'v3/components/Modal';

import moment from 'moment';

import api from 'services/api';
import useQuery from 'hooks/useQuery';

import { removeDuplicates } from 'v3/utils/array';
import { validatePermission } from 'actions/index';

export default function VehicleFilter({
  setFinalFilter,
  showAdvancedFilters,
  handleClose,
  setQtdAdvancedFilters,
}) {
  const history = useHistory();
  const query = useQuery();
  const [loadingTagsFromParam, setLoadingTagsFromParam] = useState(false);
  const [loading] = useState(false);
  const [, setErrors] = useState({});
  const [inputTag, setInputTag] = useState('');

  // Filtros simples
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState([]);
  const [plate, setPlate] = useState('');

  // Filtros avançados
  const [advancedFilters, setAdvancedFilters] = useState({
    showInactive: 0,
    onlyExclusives: 0,
  });

  let isFirstRender = true;

  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [bodyTypes, setBodyTypes] = useState([]);
  const [axisTypes, setAxisTypes] = useState([]);

  const hasPermissionToSelectTags = useMemo(
    () => validatePermission('SELECIONAR_TAGS'),
    []
  );

  function syncTags(tags) {
    query.delete('tags');
    if (tags) {
      tags.forEach(tag => {
        query.append('tags', tag.id);
      });
    }
    setSelectedTags(tags);
  }

  async function fetchTagsById() {
    const tagsId = query.getAll('tags');
    const tags = await Promise.all(
      tagsId
        .filter(tagId => !selectedTags?.find(tag => tag.id === tagId))
        .map(async tagId => {
          const responseTag = await api.get(`tags/${tagId}`);
          return responseTag.data;
        })
    );
    return tags;
  }

  async function fetchDriverById(id) {
    const response = await api.get(`persons/${id}`);
    return response.data;
  }

  async function fetchUserTags() {
    try {
      const response = await api.get('user-tags');
      const tags = response.data.map(tag => ({
        id: tag.tag_id,
        name: tag.name.name,
      }));

      return tags;
    } catch (ex) {
      return [];
    } finally {
      setLoadingTagsFromParam(false);
    }
  }

  async function fetchAllTags() {
    const userTags = await fetchUserTags();
    const tags = await fetchTagsById();

    syncTags(removeDuplicates([...userTags, ...tags]));
  }

  async function fetchTags(search) {
    try {
      const response = await api.get('tags', { params: { search } });
      return response.data.data;
    } catch (ex) {
      return [];
    }
  }

  async function fetchVehicleTypes(fillList = true) {
    try {
      const response = await api.get(`vehicle-types?app=true`);
      if (fillList) setVehicleTypes(response.data);
      return response.data;
    } catch (ex) {
      return [];
    }
  }
  async function fetchBodyTypes(fillList = true) {
    try {
      const response = await api.get(`body-types?app=true`);
      if (fillList) setBodyTypes(response.data);
      return response.data;
    } catch (ex) {
      return [];
    }
  }
  async function fetchAxisTypes(fillList = true) {
    try {
      const response = await api.get(`vehicle-axe?app=true`);
      if (fillList) setAxisTypes(response.data);
      return response.data;
    } catch (ex) {
      return [];
    }
  }

  async function fetchDrivers(search) {
    try {
      const response = await api.get(`drivers/select?search=${search}`);
      return response.data;
    } catch (ex) {
      return [];
    }
  }

  function applyQueryParameters() {
    let queryParams = query.toString();
    if (queryParams.trim() === '') queryParams = '?';
    setFinalFilter(queryParams);
    history.replace({ search: queryParams });
  }

  async function initializeDefaultQuery() {
    fetchAllTags().then(applyQueryParameters);
    fetchVehicleTypes();
    fetchBodyTypes();
    fetchAxisTypes();
  }

  function handleAdvancedFilter(key, value, process = v => v) {
    setAdvancedFilters(previous => ({ ...previous, [key]: value }));

    const processedValue = process(value);
    if (processedValue) {
      query.set(key, processedValue);
    } else {
      query.delete(key);
    }
  }

  async function getQueryParams() {
    if (query.has('driver_id')) {
      const driverByQuery = await fetchDriverById(query.get('driver_id'));
      setSelectedDriver(driverByQuery);
    }

    if (query.has('plate')) {
      setPlate(query.get('plate'));
    }

    if (query.has('showInactive')) {
      handleAdvancedFilter('showInactive', query.get('showInactive'));
    }
    if (query.has('onlyExclusives')) {
      handleAdvancedFilter('onlyExclusives', query.get('onlyExclusives'));
    }

    if (query.has('vehicleTypeId')) {
      const vehicleTypesList = await fetchVehicleTypes(false);
      const vehicleTypeSelected = vehicleTypesList.find(
        x => x.id === Number(query.get('vehicleTypeId'))
      );
      handleAdvancedFilter('vehicleTypeId', vehicleTypeSelected, v => v?.id);
    } else query.delete('vehicleTypeId');

    if (query.has('vehicleBodyTypeId')) {
      const vehicleBodyTypesList = await fetchBodyTypes(false);
      const vehicleBodyTypeSelected = vehicleBodyTypesList.find(
        x => x.id === Number(query.get('vehicleBodyTypeId'))
      );
      handleAdvancedFilter(
        'vehicleBodyTypeId',
        vehicleBodyTypeSelected,
        v => v?.id
      );
    } else query.delete('vehicleBodyTypeId');

    if (query.has('vehicleAxesId')) {
      const vehicleAxesList = await fetchAxisTypes(false);
      const vehicleAxisSelected = vehicleAxesList.find(
        x => x.id === Number(query.get('vehicleAxesId'))
      );
      handleAdvancedFilter('vehicleAxesId', vehicleAxisSelected, v => v?.id);
    } else query.delete('vehicleAxesId');
  }

  useEffect(() => {
    if (isFirstRender) {
      initializeDefaultQuery();
      isFirstRender = false;
    } else if (!hasPermissionToSelectTags) {
      fetchAllTags().then(applyQueryParameters);
    } else {
      fetchTagsById().then(tags => {
        syncTags(tags);
        applyQueryParameters();
      });
    }

    getQueryParams();
  }, []);

  useEffect(() => {
    const isNotEmpty = value =>
      value !== null && value !== undefined && value !== '';

    setQtdAdvancedFilters(
      Object.values(advancedFilters).filter(isNotEmpty).length
    );
  }, [advancedFilters]);

  function tagTreatment(input) {
    if (input) {
      const inputFiltered = input.trimStart().replace(/[^a-zA-Z0-9 ]/g, '');
      setInputTag(inputFiltered);
    } else {
      setInputTag('');
    }
  }

  function setError(field, error) {
    setErrors(prev => ({ ...prev, [field]: error }));
  }

  useEffect(() => {
    if (advancedFilters.startDate && advancedFilters.endDate) {
      const startDate = moment(advancedFilters.startDate, 'DD/MM/YYYY', true);
      const endDate = moment(advancedFilters.endDate, 'DD/MM/YYYY', true);

      if (startDate.isAfter(endDate)) {
        setError(
          'startDate',
          'A data de ínicio precisa ser menor que a data final.'
        );
        setError(
          'endDate',
          'A data de fim deve ser maior que a data de início.'
        );
      }
    }
  }, [advancedFilters.startDate, advancedFilters.endDate]);

  function onSubmit(event) {
    event.preventDefault();

    applyQueryParameters();
  }

  return (
    <>
      <Modal
        size="lg"
        show={showAdvancedFilters}
        backdrop="static"
        heading="Filtro Avançado"
        animation
        onHide={handleClose}
        style={{ height: '100%', overflow: 'visible' }}
        body={
          <Row className="p-1 form" style={{ overflow: 'visible' }}>
            <Col md={6} xs={12}>
              <Toggle
                buttonSize="sm"
                defaultValue={advancedFilters.showInactive}
                label="Inativos"
                value={advancedFilters.showInactive}
                options={[
                  { label: 'Ocultar', value: 0 },
                  { label: 'Mostrar', value: 1 },
                ]}
                onSelected={value =>
                  handleAdvancedFilter('showInactive', value)
                }
              />
            </Col>
            <Col md={6} xs={12}>
              <Toggle
                buttonSize="sm"
                defaultValue={advancedFilters.onlyExclusives}
                label="Exibição"
                value={advancedFilters.onlyExclusives}
                options={[
                  { label: 'Todos', value: 0 },
                  { label: 'Exclusivos', value: 1 },
                ]}
                onSelected={value =>
                  handleAdvancedFilter('onlyExclusives', value)
                }
              />
            </Col>
            <Col md={6} xs={12}>
              <Select
                label="Tipo de Veículo"
                value={advancedFilters.vehicleTypeId}
                options={vehicleTypes}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                onChange={value =>
                  handleAdvancedFilter('vehicleTypeId', value, v => v?.id)
                }
              />
            </Col>
            <Col md={6} xs={12}>
              <Select
                label="Tipo de Carroceria"
                value={advancedFilters.vehicleBodyTypeId}
                options={bodyTypes}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                onChange={value =>
                  handleAdvancedFilter('vehicleBodyTypeId', value, v => v?.id)
                }
              />
            </Col>
            <Col md={6} xs={12}>
              <Select
                label="Tipo de Eixo"
                value={advancedFilters.vehicleAxesId}
                options={axisTypes}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                onChange={value =>
                  handleAdvancedFilter('vehicleAxesId', value, v => v?.id)
                }
              />
            </Col>
          </Row>
        }
        footer={
          <Row style={{ justifyContent: 'flex-end' }}>
            <Button
              variant="primary"
              loading={loading}
              onClick={() => {
                applyQueryParameters();
                handleClose();
              }}
            >
              <Text color="white" weight={500}>
                Filtrar
              </Text>
            </Button>
          </Row>
        }
      />
      <form onSubmit={onSubmit}>
        <Row className="filter">
          <Col lg={2} md={2} xs={12}>
            <Input
              label="Placa"
              labelColor="#fff"
              type="text"
              placeholder="Digite..."
              value={plate}
              onChange={e => {
                if (e.target.value.trim()) {
                  setPlate(e.target.value);
                  query.set('plate', e.target.value);
                } else {
                  setPlate('');
                  query.delete('plate');
                }
              }}
            />
          </Col>
          <Col lg={4} md={4} xs={12}>
            <Select.Async
              label="Motorista"
              onSearch={fetchDrivers}
              value={selectedDriver}
              horizontal
              onChange={value => {
                if (value) {
                  setSelectedDriver(value);
                  query.set('driver_id', value.id);
                } else {
                  setSelectedDriver([]);
                  query.delete('driver_id');
                }
              }}
              getOptionLabel={option => option.label}
              getOptionValue={option => option.id}
              labelColor="#fff"
            />
          </Col>
          <Col lg={4} md={4} xs={12}>
            <Select.Async
              multiple
              label="Tags"
              modalHeading="Adicione uma tag"
              modalBodyTitle="Tags:"
              onSearch={fetchTags}
              value={selectedTags}
              horizontal
              onChange={value => syncTags(value)}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              isDisabled={
                hasPermissionToSelectTags
                  ? loadingTagsFromParam
                  : !hasPermissionToSelectTags
              }
              onInputChange={e => tagTreatment(e)}
              inputValue={inputTag}
              labelColor="#fff"
            />
          </Col>
          <Col md={2} xs={12} className="vertical bottom">
            <Button
              variant="success"
              loading={loading}
              style={{ marginTop: 12 }}
              type="submit"
            >
              <Text type="regular" weight={500}>
                Filtrar
              </Text>
            </Button>
          </Col>
        </Row>
      </form>
    </>
  );
}
