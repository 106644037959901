import React, { useEffect, useState } from 'react';
import Modal from 'v3/components/Modal';
import Text from 'v3/components/Text';
import Space from 'v3/components/Space';
import { Input } from 'v3/components';
import { applyMonetaryMask } from 'v3/utils/functions';
import { usePolicyRegister } from '../../context';
import { RangeModalContainer, StyledModalButton } from '../../../styles';

export function RangeModal() {
  const {
    rangesModal,
    operations,
    setOperations,
    currentOpIndex,
    currentPdIndex,
    currentVehicleIndex,
    setCurrentOpIndex,
    setCurrentPdIndex,
    setCurrentVehicleIndex,
    setErrors,
    policyCurrency,
  } = usePolicyRegister();

  const currencySymbol = policyCurrency?.value === 'BRL' ? 'R$' : '$';

  const rangesLength =
    operations[currentOpIndex]?.products[currentPdIndex]?.vehicles[
      currentVehicleIndex
    ]?.ranges?.length;

  const shouldBlockMin = rangesLength >= 1;
  const [min, setMin] = useState(null);
  const [max, setMax] = useState(null);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      operations[currentOpIndex]?.products[currentPdIndex]?.vehicles[
        currentVehicleIndex
      ]?.ranges[rangesLength - 1]
    ) {
      const masked = applyMonetaryMask(
        Number(
          operations[currentOpIndex]?.products[currentPdIndex]?.vehicles[
            currentVehicleIndex
          ]?.ranges[rangesLength - 1]?.policy_range_max
            .replace(/[^\d,]/g, '')
            .replace(/,/g, '.')
        ) + 0.01
      );
      setMin(masked);
    } else {
      setMin(null);
    }
  }, [rangesModal?.isOpen]);

  function handleClose() {
    setMin(null);
    setMax(null);
    setError({});
    setCurrentOpIndex(null);
    setCurrentPdIndex(null);
    setCurrentVehicleIndex(null);
    setLoading(false);
    rangesModal.close();
  }

  function handleAdd() {
    setLoading(true);
    if (!min || !max) {
      setError({
        min: !min ? 'Informe um valor!' : '',
        max: !max ? 'Informe um valor!' : '',
      });
      setLoading(false);
      return;
    }

    const minWithoutMask = min.replace(/[^\d,]/g, '').replace(/,/g, '.');
    const maxWithoutMask = max.replace(/[^\d,]/g, '').replace(/,/g, '.');

    if (Number(minWithoutMask) > Number(maxWithoutMask)) {
      setError({
        max: 'Valor não pode ser inferior ao mínimo da faixa',
      });
      setLoading(false);
      return;
    }

    const newState = [...operations];
    const lastRangeIdx =
      newState[currentOpIndex]?.products[currentPdIndex]?.vehicles[
        currentVehicleIndex
      ]?.ranges?.length - 1;

    if (lastRangeIdx >= 0) {
      const lastRange =
        newState[currentOpIndex]?.products[currentPdIndex]?.vehicles[
          currentVehicleIndex
        ]?.ranges[lastRangeIdx];
      newState[currentOpIndex].products[currentPdIndex].vehicles[
        currentVehicleIndex
      ] = {
        ...newState[currentOpIndex]?.products[currentPdIndex]?.vehicles[
          currentVehicleIndex
        ],
        ranges: [
          ...newState[currentOpIndex]?.products[currentPdIndex]?.vehicles[
            currentVehicleIndex
          ]?.ranges,
          {
            ...lastRange,
            id: undefined,
            policy_range_min: min,
            policy_range_max: max,
            attachments: [],
            monitoringEquipaments: lastRange.monitoringEquipaments?.map(
              monitoringEquipament => ({
                ...monitoringEquipament,
                id: undefined,
                tecnologies: monitoringEquipament.tecnologies?.map(
                  tecnology => ({
                    id: tecnology.id,
                    name: tecnology.name,
                  })
                ),
                equipments: monitoringEquipament.equipments?.map(equipment => ({
                  id: equipment.id,
                  name: equipment.name,
                })),
                locators: monitoringEquipament.locators?.map(locator => ({
                  id: locator.id,
                  name: locator.name,
                })),
                immobilizers: monitoringEquipament.immobilizers?.map(
                  immobilizer => ({
                    id: immobilizer.id,
                    name: immobilizer.name,
                  })
                ),
                baits: monitoringEquipament.baits?.map(bait => ({
                  id: bait.id,
                  name: bait.name,
                })),
              })
            ),
            search_consulting: lastRange.search_consulting?.map(value => ({
              id: value.id,
              name: value.name,
            })),
            rdo: lastRange.rdo?.map(value => ({
              id: value.id,
              name: value.name,
            })),
            manager_query: lastRange.manager_query?.map(value => ({
              id: value.id,
              name: value.name,
            })),
            security_profile: lastRange.security_profile?.map(value => ({
              id: value.id,
              name: value.name,
            })),
            manager_tracker: lastRange.manager_tracker?.map(value => ({
              id: value.id,
              name: value.name,
            })),
            manager_mirror: lastRange.manager_mirror?.map(value => ({
              id: value.id,
              name: value.name,
            })),
            check_manager: lastRange.check_manager?.map(value => ({
              id: value.id,
              name: value.name,
            })),
            validity_check_manager: lastRange.validity_check_manager?.map(
              value => ({
                id: value.id,
                name: value.name,
              })
            ),
            sensors: lastRange.sensors?.map(value => ({
              id: value.id,
              name: value.name,
            })),
            riskAreas: lastRange.riskAreas?.map(value => ({
              city: { ...value.city },
              radius: value.radius,
            })),
          },
        ],
      };
    } else {
      newState[currentOpIndex].products[currentPdIndex].vehicles[
        currentVehicleIndex
      ] = {
        ...newState[currentOpIndex]?.products[currentPdIndex]?.vehicles[
          currentVehicleIndex
        ],
        ranges: [
          ...newState[currentOpIndex]?.products[currentPdIndex]?.vehicles[
            currentVehicleIndex
          ]?.ranges,
          {
            monitoringEquipaments: [
              {
                bait_quantity: 0,
                tecnologies: [],
                equipments: [],
                locators: [],
                immobilizers: [],
                baits: [],
              },
            ],
            riskAreas: [],
            showSearchConsultation: true,
            showMonitoringEquipaments: true,
            showManagers: true,
            showGeneralRules: true,
            showRiskManager: true,
            policy_range_min: min,
            policy_range_max: max,
            active: true,
            rdo: [],
            search_consulting: [],
          },
        ],
      };
    }

    setOperations(newState);
    setError({});
    setErrors({});
    handleClose();
  }

  return (
    <Modal
      size="lg"
      show={rangesModal.isOpen}
      handleClose={handleClose}
      heading={
        <Text type="subtitle" color="dark" weight="400">
          Nova faixa de valor
        </Text>
      }
      body={
        <div>
          <RangeModalContainer>
            <Input
              label={`Valor inicial (${currencySymbol})`}
              value={min}
              onChange={e => {
                if (String(e?.target?.value)?.length > 16) {
                  return;
                }
                const masked = applyMonetaryMask(e?.target?.value);
                setMin(masked);
              }}
              placeholder={currencySymbol}
              disabled={shouldBlockMin}
              error={error?.min}
            />
            <Input
              label={`Valor final (${currencySymbol})`}
              value={max}
              onChange={e => {
                if (String(e?.target?.value)?.length > 16) {
                  return;
                }
                const masked = applyMonetaryMask(e?.target?.value);
                setMax(masked);
              }}
              placeholder={currencySymbol}
              error={error?.max}
            />
          </RangeModalContainer>
        </div>
      }
      footer={
        <Space>
          <StyledModalButton
            size="sm"
            onClick={() => handleAdd()}
            loading={loading}
            disabled={!min || !max}
          >
            <Text type="regular" weight={500}>
              Adicionar
            </Text>
          </StyledModalButton>
        </Space>
      }
    />
  );
}
