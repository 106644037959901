/* eslint-disable import/no-unresolved */
import React, { useMemo, useState, useEffect, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import { FaCheck, FaClock, FaArrowRight } from 'react-icons/fa';

import { useTravel } from 'v3/pages/Travel';
import useModal from 'hooks/useModal';
import {
  pluralize,
  formatTime,
  formatDateCustom,
  formatAddress,
} from 'v3/utils/formatter';
import theme from 'v3/theme';
import { validatePermission } from 'actions/index';
import Card, { DefaultLoadingBodyComponent } from 'v3/components/Card';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import IconButton from 'v3/components/Button/Icon';
import Modal from 'v3/components/Modal';
import { BlockedTravelContext } from 'contexts/BlockedTravelProvider';
import EditAddress from './Edit';
import InformModal from './Inform';
import EffectOrigin from './EffectOrigin';
import * as Styled from './styles';

const actions = {
  delivery: 'Entrega',
  customhouse: 'Aduaneiro',
  collect: 'Coleta',
};

function LocationItem({
  id,
  isOrigin,
  isCompleted,
  action,
  address,
  scheduledTime,
  arrivalTime,
  effectiveTime,
  startSchedule,
  hasStartSchedule,
  client,
  rawAddress,
}) {
  const { isTravelBlocked } = useContext(BlockedTravelContext);
  const [hasPermissionToEdit, setPermissionToEdit] = useState();
  const [informType, setInformType] = useState();
  const [waitingTime, setWaitingTime] = useState();
  const travel = useTravel();
  const informModal = useModal();
  const addressModal = useModal();
  const effectOriginModal = useModal();

  useEffect(() => {
    setPermissionToEdit(validatePermission('ALTERAR_VIAGEM'));
  }, []);

  useEffect(() => {
    const diff = moment(effectiveTime).diff(moment(arrivalTime), 'minutes');
    if (diff < 0) {
      return;
    }
    const hours = parseInt(diff / 60, 10);
    const minutes = diff % 60;

    if (typeof diff === 'number') {
      if (hours < 10) {
        if (minutes < 10) setWaitingTime(`0${hours}:0${minutes}`);
        else setWaitingTime(`0${hours}:${minutes}`);
      } else if (minutes < 10) setWaitingTime(`${hours}:0${minutes}`);
      else setWaitingTime(`${hours}:${minutes}`);
    }
  }, [arrivalTime, effectiveTime]);

  if (travel.isLoading) {
    return null;
  }
  return (
    <>
      <Modal
        size="lg"
        heading={
          <Text color="dark" weight="500" type="header">
            Alterar endereço
          </Text>
        }
        centered
        scrollable
        show={addressModal.isOpen}
        body={
          <EditAddress
            isOrigin={isOrigin}
            address={rawAddress}
            client={client}
            loadId={travel.data.loadId}
            action={action}
          />
        }
        handleClose={addressModal.close}
      />
      <InformModal
        id={id}
        isOpen={informModal.isOpen}
        onClose={informModal.close}
        type={informType}
        isOrigin={isOrigin}
      />
      <EffectOrigin
        isOpen={effectOriginModal.isOpen}
        onClose={effectOriginModal.close}
        id={id}
      />
      <Col md={hasStartSchedule ? 4 : 6} xs={12}>
        <div className="d-flex pl-2">
          <div className="pt-2">
            <Styled.Marker.Tray
              completed={effectiveTime}
              progress={arrivalTime}
            />
          </div>
          <div className="position-relative pl-4">
            <Styled.Marker completed={effectiveTime} progress={arrivalTime}>
              {isCompleted && <FaCheck color="#fff" size={9} />}
            </Styled.Marker>
            <Text as="div" color="dark">
              {action}
            </Text>
            <Text as="div" type="regular" color="gray">
              {address}
            </Text>
            {client ? (
              <Text as="div" type="regular" color="gray" className="my-2">
                {`Cliente: ${client?.socialName} - ${client?.cpfCnpj}`}
              </Text>
            ) : null}
            {hasPermissionToEdit &&
              [2, 8, 11, 9].includes(travel.data.statusId) &&
              travel.data?.driver &&
              travel.data?.vehicle?.[0] &&
              !arrivalTime && (
                <div className="mt-2">
                  <IconButton
                    variant="secondary"
                    icon="FaPencilAlt"
                    color={theme.colors.dark}
                    size={14}
                    onClick={addressModal.open}
                    disabled={travel?.isDisabledGROAnalysis}
                    title={
                      travel?.isDisabledGROAnalysis &&
                      'Não é possível edição durante período de análise GRO'
                    }
                  />
                </div>
              )}
          </div>
        </div>
      </Col>
      <Styled.Schedules
        xs={12}
        md={hasStartSchedule ? 8 : 6}
        completed={effectiveTime}
        progress={arrivalTime}
      >
        <Row>
          <Col>
            <div className="text-center">
              <Text as="div" color="dark" className="d-block d-sm-none mb-3">
                Agendado
              </Text>
              <div className="d-flex flex-row">
                {!!startSchedule && (
                  <>
                    <div>
                      <Text as="div" type="regular" color="dark">
                        {formatDateCustom(startSchedule, 'DD, MMMM [de] YYYY')}
                      </Text>
                      <FaClock color={theme.colors.dark} className="mr-1" />
                      <Text type="label" color="dark">
                        {formatTime(startSchedule)}
                      </Text>
                    </div>
                    <div className="mt-3">
                      <FaArrowRight color={theme.colors.dark} />
                    </div>
                  </>
                )}
                <div className={!hasStartSchedule && 'ml-4'}>
                  <Text as="div" type="regular" color="dark">
                    {formatDateCustom(scheduledTime, 'DD, MMMM [de] YYYY')}
                  </Text>
                  <FaClock color={theme.colors.dark} className="mr-1" />
                  <Text type="label" color="dark">
                    {formatTime(scheduledTime)}
                  </Text>
                </div>
              </div>
            </div>
          </Col>
          <Col>
            <div className="text-center">
              {arrivalTime ? (
                <>
                  <Text
                    as="div"
                    color="dark"
                    className="d-block d-sm-none mb-3"
                  >
                    Chegada
                  </Text>
                  <Text as="div" type="regular" color="dark">
                    {formatDateCustom(arrivalTime, 'DD, MMMM [de] YYYY')}
                  </Text>
                  <FaClock color={theme.colors.dark} className="mr-1" />
                  <Text type="label" color="dark">
                    {formatTime(arrivalTime)}
                  </Text>
                  {validatePermission('ALTERAR_HORARIO_ROTAS') && (
                    <Row>
                      <Styled.EditButton
                        className="text-center"
                        variant="secondary"
                        size="sm"
                        disabled={
                          isTravelBlocked || travel?.isDisabledGROAnalysis
                        }
                        onClick={() => {
                          informModal.open();
                          setInformType('arrival_time');
                        }}
                      >
                        <Text type="regular" weight="500">
                          Editar
                        </Text>
                      </Styled.EditButton>
                    </Row>
                  )}
                </>
              ) : (
                hasPermissionToEdit && (
                  <>
                    <Text
                      as="div"
                      color="dark"
                      className="d-block d-sm-none mb-3"
                    >
                      Chegada
                    </Text>
                    <Button
                      disabled={
                        !travel.data?.driver ||
                        !travel.data?.vehicle?.[0]?.vehicles ||
                        isTravelBlocked ||
                        travel?.isDisabledGROAnalysis
                      }
                      variant="secondary"
                      size="sm"
                      onClick={() => {
                        informModal.open();
                        setInformType('arrival_time');
                      }}
                      title={
                        travel?.isDisabledGROAnalysis &&
                        'Não é possível edição durante período de análise GRO'
                      }
                    >
                      <Text type="regular" weight="500">
                        Informar
                      </Text>
                    </Button>
                  </>
                )
              )}
            </div>
          </Col>
          {arrivalTime && effectiveTime && waitingTime && (
            <>
              <FaClock color={theme.colors.dark} className="mr-1" />
              <Text type="label" color="dark">
                {waitingTime}
              </Text>
            </>
          )}
          <Col>
            <div className="text-center">
              {effectiveTime ? (
                <>
                  <Text
                    as="div"
                    color="dark"
                    className="d-block d-sm-none mb-3"
                  >
                    Efetivado
                  </Text>
                  <Text as="div" type="regular" color="dark">
                    {formatDateCustom(effectiveTime, 'DD, MMMM [de] YYYY')}
                  </Text>
                  <FaClock color={theme.colors.dark} className="mr-1" />
                  <Text type="label" color="dark">
                    {formatTime(effectiveTime)}
                  </Text>
                  {validatePermission('ALTERAR_HORARIO_ROTAS') && (
                    <Row>
                      <Styled.EditButton
                        className="text-center"
                        variant="secondary"
                        size="sm"
                        disabled={
                          isTravelBlocked || travel?.isDisabledGROAnalysis
                        }
                        onClick={() => {
                          informModal.open();
                          setInformType('effective_time');
                        }}
                        title={
                          travel?.isDisabledGROAnalysis &&
                          'Não é possível edição durante período de análise GRO'
                        }
                      >
                        <Text type="regular" weight="500">
                          Editar
                        </Text>
                      </Styled.EditButton>
                    </Row>
                  )}
                </>
              ) : (
                hasPermissionToEdit && (
                  <>
                    <Text
                      as="div"
                      color="dark"
                      className="d-block d-sm-none mb-3"
                    >
                      Efetivado
                    </Text>
                    <Button
                      disabled={
                        !travel.data?.driver ||
                        !travel.data?.vehicle?.[0] ||
                        isTravelBlocked ||
                        travel?.isDisabledGROAnalysis
                      }
                      variant="secondary"
                      size="sm"
                      onClick={() => {
                        if (!isOrigin) {
                          informModal.open();
                          setInformType('effective_time');
                        } else {
                          effectOriginModal.open();
                        }
                      }}
                      title={
                        travel?.isDisabledGROAnalysis &&
                        'Não é possível edição durante período de análise GRO'
                      }
                    >
                      <Text type="regular" weight="500">
                        Informar
                      </Text>
                    </Button>
                  </>
                )
              )}
            </div>
          </Col>
        </Row>
      </Styled.Schedules>
    </>
  );
}

export default function Locations({ travelDetailsRef }) {
  const travel = useTravel();
  let origin = useMemo(
    () => travel.data?.loads?.loadOrigins?.[0],
    [travel.data?.loads?.loadOrigins]
  );

  const [waitingHours, setWaitingHours] = useState();
  const [waitingMinutes, setWaitingMinutes] = useState();

  const datetimeFinishedLoading = travel.data?.logs?.filter(
    log => log.statusTo === 11
  )[0]?.createdAt;

  if (datetimeFinishedLoading && !origin?.effectiveTime) {
    origin = {
      ...origin,
      effectiveTime: datetimeFinishedLoading,
    };
  }

  const destinations = useMemo(
    () => travel.data?.loads?.loadDestinations,
    [travel.data?.loads?.loadDestinations]
  );

  const isOriginCompleted = useMemo(
    () => origin?.arrivalTime,
    [travel.data.statusId, origin]
  );

  useEffect(() => {
    let diff = moment(origin?.effectiveTime).diff(
      moment(origin?.arrivalTime),
      'minutes'
    );

    for (let i = 0; i < destinations?.length; i++) {
      diff += moment(destinations[i]?.effectiveTime).diff(
        moment(destinations[i]?.arrivalTime),
        'minutes'
      );
    }
    const hours = parseInt(diff / 60, 10);
    const minutes = diff % 60;

    if (typeof diff === 'number') {
      if (hours < 10) {
        setWaitingHours(`0${hours}`);
      } else {
        setWaitingHours(hours);
      }
      setWaitingMinutes(minutes);
    }
  }, [origin, destinations]);

  if (!travel.data.id) {
    return null;
  }
  const hasStartSchedule = !!(
    travel.data?.loads?.loadOrigins?.[0]?.startSchedule ||
    travel.data?.loads?.loadDestinations?.map(item => item.startSchedule)[0] !==
      null
  );

  return (
    <Card
      ref={travelDetailsRef}
      loading={travel.isLoading}
      LoadingBodyComponent={() => (
        <DefaultLoadingBodyComponent linesCount={4} perLine={2} />
      )}
      header={
        <Text type="header" color="dark">
          Rota da viagem
        </Text>
      }
    >
      <Row>
        <Col md={hasStartSchedule ? 4 : 6} xs={12}>
          <div className="mb-3">
            <Text type="body" color="dark">
              Origem
            </Text>
          </div>
        </Col>
        <Col
          md={hasStartSchedule ? 3 : 2}
          className="text-center d-none d-sm-block"
        >
          <Text type="body" color="dark">
            Agendado
          </Text>
        </Col>
        <Col md={2} className="text-center d-none d-sm-block">
          <Text type="body" color="dark">
            Chegada
          </Text>
        </Col>
        <Col
          md={2}
          className={hasStartSchedule ? ' text-right ml-2' : 'text-center'}
        >
          <Text type="body" color="dark">
            Efetivado
          </Text>
        </Col>
      </Row>
      <Row className="mb-3 d-flex align-items-center">
        <LocationItem
          id={origin?.id}
          isOrigin
          action="Coleta"
          isCompleted={isOriginCompleted}
          address={formatAddress(origin)}
          rawAddress={origin}
          scheduledTime={origin?.scheduledTime}
          arrivalTime={origin?.arrivalTime}
          startSchedule={origin?.startSchedule}
          hasStartSchedule={hasStartSchedule}
          effectiveTime={origin?.effectiveTime}
          client={origin?.client}
        />
      </Row>
      <div className="mb-3">
        <Text type="body" color="dark">
          {pluralize('Destino', destinations.length)}
        </Text>
      </div>
      {destinations.map(destination => {
        const isCompleted =
          destination.arrivalTime && destination.effectiveTime;

        const action = actions[destination.action.toLowerCase()] || 'Coleta';

        return (
          <Row key={destination.id}>
            <LocationItem
              key={destination.id}
              id={destination.id}
              action={action}
              isCompleted={isCompleted}
              address={formatAddress(destination)}
              rawAddress={destination}
              scheduledTime={destination.scheduledTime}
              arrivalTime={destination.arrivalTime}
              effectiveTime={destination.effectiveTime}
              startSchedule={destination?.startSchedule}
              hasStartSchedule={hasStartSchedule}
              client={destination?.client}
            />
          </Row>
        );
      })}
      {!!waitingHours && !!waitingMinutes && (
        <Col className="text-center">
          <Text type="regular" color="gray">
            Tempo de espera total -
          </Text>
          <Text
            weight={500}
            type="regular"
          >{` ${waitingHours}:${waitingMinutes}hrs`}</Text>
        </Col>
      )}
    </Card>
  );
}
