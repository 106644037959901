import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from 'components/TextField';
import Card from 'components/Card';
import Input from 'components/Input';
import Button from 'components/Button';
import Snackbar from 'components/Snackbar';
import InputMask from 'components/InputMask';
import api from 'services/api';
import { usePermission } from 'hooks';

function DocumentoFisico() {
  const [nfList, setNfList] = useState('');
  const [issueDate, setIssueDate] = useState('');
  const [taker, setTaker] = useState('');
  const [recipient, setRecipient] = useState('');
  const [deliveryAddress, setDeliveryAddress] = useState('');
  const [obs, setObs] = useState('');
  const [snackBar, setSnackBar] = useState({
    display: false,
    type: 'error',
    message: '',
  });
  usePermission('VISUALIZAR_FINANCEIRO_DOCUMENTO_FISICO', { redirect: true });

  async function requestDocuments(e) {
    e.preventDefault();
    if (nfList.length < 2 && issueDate.length < 2 && recipient.length < 2) {
      setSnackBar({
        type: 'error',
        message: 'Favor preencha todos os campos',
        display: true,
      });
      return;
    }

    if (!taker) {
      setSnackBar({
        type: 'error',
        message: 'Favor preencher tomador',
        display: true,
      });
      return;
    }

    try {
      await api.post('solicitar-documento-fisico', {
        nfList,
        issueDate,
        taker,
        recipient,
        deliveryAddress,
        obs,
      });

      setSnackBar({
        type: 'success',
        message: 'Requisição enviada com sucesso!',
        display: true,
      });

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (ex) {
      setSnackBar({
        type: 'error',
        message: 'Algo deu errado',
        display: true,
      });
    }
  }

  return (
    <>
      <Snackbar
        open={snackBar.display}
        type={snackBar.type}
        message={snackBar.message}
        onClose={() => setSnackBar(false)}
      />
      <Grid container lg={6}>
        <Card>
          <form>
            <h1>Solicitar documento físico</h1>
            <Input
              label="Informe as NFs"
              onChange={e => setNfList(e.target.value)}
            />
            <InputMask
              label="Data de emissão"
              onChange={e => setIssueDate(e.target.value)}
              mask={[
                /[0-9]/,
                /[0-9]/,
                '/',
                /[0-9]/,
                /[0-9]/,
                '/',
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
              ]}
            />

            <Input label="Tomador" onChange={e => setTaker(e.target.value)} />
            <Input
              label="Destinatário"
              onChange={e => setRecipient(e.target.value)}
            />
            <Input
              label="Endereço de entrega"
              onChange={e => setDeliveryAddress(e.target.value)}
            />
            <TextField
              label="Observações"
              multiline
              rows={4}
              style={{ resize: 'none' }}
              onChange={e => setObs(e.target.value)}
              rowsMax={4}
            />

            <Grid
              container
              style={{ padding: '15px 0' }}
              direction="row"
              justify="flex-end"
              alignItems="flex-start"
            >
              <Button color="success" type="submit" onClick={requestDocuments}>
                Solicitar
              </Button>
            </Grid>
          </form>
        </Card>
      </Grid>
    </>
  );
}

export default DocumentoFisico;
