import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Row, Col } from 'react-bootstrap';
import Text from 'v3/components/Text';

import { useSnackbar } from 'v3/components/Snackbar';

import api from 'services/apis/cooperplace';

import { SwitchOption } from 'v3/components';
import {
  ValidationError,
  rasterBranchschema,
  validate,
} from '../shared/validator';

import SharedData from '../shared/Section';
import Inputs from '../shared/Data';
import Editable from '../shared/Editable';
import { useRasterBranches } from '../shared/context';

export default function Data() {
  const rasterBranch = useRasterBranches();
  const params = useParams();
  const snackbar = useSnackbar();

  const [isEditing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(!rasterBranch.deleted_at);

  useEffect(() => {
    setActive(!rasterBranch.data?.deleted_at);
  }, [rasterBranch.data]);

  async function saveData() {
    rasterBranch.setErrors({});
    setLoading(true);
    try {
      await validate(rasterBranch.data, rasterBranchschema);
      await api.put(`/raster/branches/${params.id}`, {
        code: rasterBranch.data.code.code,
        document: rasterBranch.data.document,
        name: rasterBranch.data.name,
        cost_centers: rasterBranch.data.costCenters?.map(c => c.id),
      });
      snackbar.show(<Text>Salvo com sucesso</Text>, { type: 'success' });
      setEditing(false);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      if (err instanceof ValidationError) {
        snackbar.show(<Text>Verifique os campos obrigatórios</Text>, {
          type: 'error',
        });
        rasterBranch.setErrors(err.validationErrors);
        return;
      }

      let error;
      if (err.response) {
        error = err.response.data.error || err.response.data.errors;
        if (Array.isArray(error)) {
          [error] = error;
        }
      }

      snackbar.show(<Text>{error || 'Erro ao atualizar filial raster'}</Text>, {
        type: 'error',
      });
    }
  }

  async function toggleActive() {
    try {
      await api.put(
        `/raster/branches/${rasterBranch.data?.id}/${
          active ? 'deactivate' : 'activate'
        }`
      );
      setActive(old => !old);
      rasterBranch.setPrevBranch({
        ...rasterBranch.data,
        deleted_at: new Date().toDateString(),
      });
      rasterBranch.setPrevBranch({
        ...rasterBranch.data,
        deleted_at: new Date().toDateString(),
      });
      snackbar.show(
        <Text> Filial {active ? 'desativada' : 'ativada'} com sucesso </Text>,
        {
          type: 'success',
        }
      );
    } catch (err) {
      let error = err.response?.data?.error || err.response?.data?.errors;
      if (Array.isArray(error)) {
        [error] = error;
      }
      snackbar.show(<Text> {error || 'Erro ao alterar filial'} </Text>, {
        type: 'error',
      });
    }
  }

  return (
    <SharedData
      Title="Dados gerais"
      HeaderRightComponent={
        <Editable
          isEditing={isEditing}
          setEditing={setEditing}
          onSave={saveData}
          isLoading={loading}
        />
      }
      loading={rasterBranch.isFetching}
    >
      {isEditing ? (
        <Inputs />
      ) : (
        <Row>
          <Col xs={12} md={12} as="p">
            <SwitchOption value={active} onClick={() => toggleActive()} />
          </Col>
          <Col xs={12} md={6}>
            <Text type="label" color="dark">
              Razão
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {rasterBranch.data?.name || 'Não informado'}
            </Text>
          </Col>
          <Col xs={12} md={6}>
            <Text type="label" color="dark">
              Cnpj
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {rasterBranch.data?.document || 'Não informado'}
            </Text>
          </Col>
          <Col xs={12} md={6}>
            <Text type="label" color="dark">
              Código filial raster
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {rasterBranch.data?.code?.code || 'Não informado'}
            </Text>
          </Col>
          <Col xs={12} md={6}>
            <Text type="label" color="dark">
              Centro de custos
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {rasterBranch.data?.costCenters
                ? rasterBranch.data?.costCenters.map(cost => (
                    <>
                      {cost.name}
                      <br />
                    </>
                  ))
                : 'Não informado'}
            </Text>
          </Col>
        </Row>
      )}
    </SharedData>
  );
}
