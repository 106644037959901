import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

import dashboardApi from 'services/apis/dashboard';
import { cooperplaceApi } from 'services/api';

import { Modal, Text, Button } from 'v3/components';

export function TravelsChangesRequestsModal({
  isOpen,
  onClose,
  requestId,
  requestType,
  requestIdentifier,
  approveRequest,
  openReproveRequestModal,
  loading,
}) {
  const [requestDetails, setRequestDetails] = useState({});
  const [allFields, setAllFields] = useState([]);
  const requestTypeFields = allFields?.filter(
    item => item?.type?.toLowerCase() === requestType?.toLowerCase()
  )[0]?.fields;
  const requestFields = requestDetails?.fields?.map(fieldInRequest => {
    return {
      field: requestTypeFields?.find(
        field => field?.column_name === fieldInRequest?.field
      )?.label,
      value: fieldInRequest?.value,
    };
  });

  useEffect(() => {
    async function getFields() {
      try {
        const response = await cooperplaceApi.get('load-request-change-fields');
        setAllFields(response.data);
      } catch (error) {
        setAllFields([]);
      }
    }
    getFields();
  }, []);

  useEffect(() => {
    async function getRequestDetails() {
      try {
        const response = await dashboardApi.get(
          `travels-request-change/${requestId}/fields`
        );
        setRequestDetails(response.data);
      } catch (error) {
        setRequestDetails([]);
      }
    }
    if (requestId) {
      getRequestDetails();
    }
  }, [requestId]);
  return (
    <Modal
      show={isOpen}
      handleClose={onClose}
      heading={
        <Text type="subtitle" color="dark" weight={400}>
          Solicitação de alteração de cadastro de {requestType}
        </Text>
      }
      body={
        <>
          <Row>
            <Col xs={12} md={6}>
              <Text type="label" color="dark">
                Criada por
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {requestDetails?.user_created}
              </Text>
            </Col>
            <Col xs={12} md={6}>
              <Text type="label" color="dark">
                Identificador
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {requestIdentifier}
              </Text>
            </Col>
          </Row>
          {requestFields?.map(field => {
            return (
              <Row key={field?.field + field?.value} className="mt-2">
                <Col xs={12} md={6}>
                  <Text type="label" color="dark">
                    Campo
                  </Text>
                  <Text type="regular" color="gray" as="p" className="mt-2">
                    {field?.field}
                  </Text>
                </Col>
                <Col xs={12} md={6}>
                  <Text type="label" color="dark">
                    Valor
                  </Text>
                  <Text type="regular" color="gray" as="p" className="mt-2">
                    {field?.value}
                  </Text>
                </Col>
              </Row>
            );
          })}
          <Row className="d-flex justify-content-start mt-4">
            <Button
              variant="primary"
              onClick={() => approveRequest(requestId)}
              loading={loading}
              className="mr-3"
            >
              <Text weight={500}>Aprovar</Text>
            </Button>
            <Button variant="danger" onClick={openReproveRequestModal}>
              <Text weight={500}>Reprovar</Text>
            </Button>
          </Row>
        </>
      }
    />
  );
}
