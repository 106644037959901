import React, { useRef, useEffect, useState } from 'react';

import { Col, Row } from 'react-bootstrap';
import { FaPhoneAlt } from 'react-icons/fa';

import api from 'services/api';

import ModalRaw from 'v3/components/Modal';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import Input from 'v3/components/Input';
import { useSnackbar } from 'v3/components/Snackbar';

function Modal({ isOpen, onClose, driver, getDriver }) {
  const inputRef = useRef();
  const snackbar = useSnackbar();

  const [data, setData] = useState({});
  const [error, setError] = useState('');

  useEffect(() => {
    if (driver?.driverEmergencyContact) {
      setData(driver?.driverEmergencyContact);
    }
  }, [driver]);

  useEffect(() => {
    if (isOpen) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  async function handleForm() {
    if (
      (data.name && !data.phone) ||
      (data.phone && !data.name) ||
      (data.email && !data.name)
    ) {
      setError('Preencha os campos necessários!');
      return;
    }

    try {
      await api.put(`v3/driver/emergency-contact/${driver?.id}`, {
        emergencyContact: data,
      });

      getDriver();

      snackbar.show(<Text>Dados alterados com sucesso!</Text>, {
        type: 'success',
      });
    } catch (ex) {
      snackbar.show(
        <Text>
          Não foi possível alterar os dados, tente novamente mais tarde!
        </Text>,
        { type: 'error' }
      );
    } finally {
      onClose();
    }
  }

  return (
    <>
      <ModalRaw
        show={isOpen}
        onHide={onClose}
        heading={
          <Text type="header" color="dark">
            Edição de contato de emergência
          </Text>
        }
        body={
          <Row className="mx-0 mt-1">
            <Col md={12} className="mb-3">
              <Input
                ref={inputRef}
                label="Nome *"
                value={data?.name}
                onChange={({ target }) =>
                  setData({
                    ...data,
                    name: target.value,
                  })
                }
                error={!!error && !data.name}
              />
            </Col>
            <Col md={6} className="mb-3">
              <Input
                icon={<FaPhoneAlt color="#464E5F" />}
                value={data?.phone}
                onChange={({ target }) =>
                  setData({
                    ...data,
                    phone: target.value,
                  })
                }
                guide={false}
                masked
                mask={[
                  '(',
                  /[0-9]/,
                  /\d/,
                  ')',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                label="Telefone *"
                error={!!error && !data.phone}
              />
            </Col>
            <Col md={6} className="mb-3">
              <Input
                label="Email"
                value={data?.email}
                onChange={({ target }) =>
                  setData({
                    ...data,
                    email: target.value,
                  })
                }
              />
            </Col>

            <Col md={12} xs={12}>
              <Text color="error" type="label">
                {error}
              </Text>
            </Col>
          </Row>
        }
        footer={
          <Row>
            <Col md={6} xs={12}>
              <Button className="py-2" onClick={() => handleForm()}>
                <Text weight="500" type="regular">
                  Salvar
                </Text>
              </Button>
            </Col>
          </Row>
        }
      />
    </>
  );
}

export default Modal;
