const Yup = require('yup');

export const goalSchema = Yup.object().shape({
  month: Yup.number()
    .typeError('Mês é obrigatório')
    .required('Mês é obrigatório'),

  year: Yup.number()
    .typeError('Ano é obrigatório')
    .required('Ano é obrigatório')
    .min(
      new Date().getFullYear() - 1,
      `O ano deve ser maior ou igual a ${new Date().getFullYear() - 1}`
    )
    .max(
      new Date().getFullYear() + 5,
      `O ano deve ser menor ou igual a ${new Date().getFullYear() + 5}`
    ),

  goal: Yup.number()
    .typeError('Meta é obrigatório')
    .required('Meta é obrigatório')
    .min(0.01, 'Meta precisa ser maior ou igual a 0,01')
    .max(999999999.99, 'Defina uma meta menor'),

  over_goal: Yup.number()
    .typeError('Super meta é obrigatório')
    .required('Super meta é obrigatório')
    .min(0.01, 'Super meta precisa ser maior ou igual a 0,01')
    .max(999999999.99, 'Defina uma super meta menor'),
});
