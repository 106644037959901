import styled, { css } from 'styled-components';

import { KeyboardDatePicker } from '@material-ui/pickers';

import { withoutProps } from 'services/utils';

export const StyledDatepicker = styled(
  withoutProps('borderColor', 'direction')(KeyboardDatePicker)
).attrs({
  inputVariant: 'outlined',
  variant: 'inline',
})`
  > div {
    border: 1px solid #e2e5ec;
    border-radius: ${({ theme }) => theme.borderRadius.input};
    width: 100%;

    ${({ direction }) =>
      direction === 'right' &&
      css`
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      `}

    ${({ direction }) =>
      direction === 'left' &&
      css`
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0;
      `}
      ${({ disabled }) =>
        disabled &&
        css`
          background-color: #f2f2f2;
        `}
  }

  > p {
    display: none;
  }

  input {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.secondary};
    font-size: 1rem;
    padding: 10px 5px;
  }

  .MuiOutlinedInput-adornedEnd {
    padding-right: 0 !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
`;
