import React, { createContext, useEffect, useState } from 'react';
import OneSignal from 'react-onesignal';

export const OneSignalContext = createContext({});

export function OneSignalProvider({ children }) {
  const [initialized, setInitialized] = useState(false);
  function setOneSignalUserIdLocalStorage(userId, permission) {
    const user = localStorage.getItem('usuario');
    if (userId && user) {
      localStorage.setItem('oneSignalPlayerId', userId);
      localStorage.setItem('isPushNotificationsEnabled', permission);
    }
  }

  async function runOneSignal() {
    if (initialized) return;
    try {
      const isLoginPage = window.location.pathname === '/login';
      await OneSignal.init({
        appId: process.env.REACT_APP_ONE_SIGNAL_APP_ID,
        safari_web_id:
          'web.onesignal.auto.4715199e-f86d-44ca-99ff-a9854a17e932',
        // allowLocalhostAsSecureOrigin: true,
        notifyButton: {
          enable: !isLoginPage,
          size: 'small',
          theme: 'default',
          position: 'bottom-left',
          offset: {
            bottom: '10px',
            left: '10px',
            right: '10px',
          },
          prenotify: true,
          showCredit: false,
          text: {
            'tip.state.unsubscribed': 'Clique para receber notificações',
            'tip.state.subscribed': 'Você está recebendo notificações',
            'tip.state.blocked': 'Você bloqueou as notificações',
            'message.prenotify': 'Clique para receber notificações',
            'message.action.subscribed':
              'Obrigado por aceitar receber notificações!',
            'message.action.resubscribed': 'Você está recebendo notificaçõe',
            'message.action.unsubscribed':
              'Você não receberá mais notificações',
            'dialog.main.title': 'Gerenciar Notificações',
            'dialog.main.button.subscribe': 'Aceitar',
            'dialog.main.button.unsubscribe': 'Recusar',
            'dialog.blocked.title': 'Desbloqueie as Notificações',
            'dialog.blocked.message':
              'Siga essas instruções para assinar as notificações:',
          },
        },
        welcomeNotification: {
          title: 'Cooperplace',
          message: 'Agora você será notificado, sempre que houver novidades',
        },
        // subdomainName: 'cooperplace',
        autoResubscribe: true,
        persistNotification: false,
        promptOptions: {
          slidedown: {
            enabled: true,
            autoPrompt: true,
            timeDelay: 20,
            pageViews: 1,
            actionMessage:
              'Gostaríamos de mostrar notificações sobre as últimas notícias e atualizações.',
            acceptButtonText: 'PERMITIR',
            cancelButtonText: 'NÃO OBRIGADO',
          },
        },
      }).then(() => {
        OneSignal.Slidedown.promptPush();
        setInitialized(true);
      });
    } catch (error) {
      console.error(error);
      if (!process.env.REACT_APP_ONE_SIGNAL_APP_ID) {
        console.error('REACT_APP_ONE_SIGNAL_APP_ID is not defined.');
      }
    }
  }

  useEffect(() => {
    runOneSignal();
  }, []);

  useEffect(() => {
    if (initialized) {
      const oneSignalUserId = OneSignal.User.PushSubscription.id;
      const isPushNotificationsEnabled =
        OneSignal.User.PushSubscription.optedIn;
      setOneSignalUserIdLocalStorage(
        oneSignalUserId,
        isPushNotificationsEnabled
      );
    }
  }, [initialized]);

  return (
    <OneSignalContext.Provider value={{}}>{children}</OneSignalContext.Provider>
  );
}
