import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { validatePermission } from 'actions/index';
import useWindowResize from 'hooks/useWindowResize';

import ApplicationLayout from 'v3/layouts/Application';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';

import Section from '../shared/Section';
import Data from '../shared/Data';
import RegisteredModal from './RegisteredModal';

import { useProduct } from '../shared/context';
import { handleCreation } from './controller';

import * as Styled from './styles';

export default function ProductRegister() {
  const history = useHistory();
  const product = useProduct();

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [hasPermissionToCreate, setPermissionToCreate] = useState(true);
  const [saveContainerOffset, setSaveContainerOffset] = useState(0);

  useWindowResize(() => {
    const header = document.getElementById('header-container');
    const originDistanceToRight =
      window.pageXOffset + header.getBoundingClientRect().right;
    const headerWidth = header.getBoundingClientRect().width;

    const SAVE_CONTAINER_PADDING = 0;
    const distanceToRight =
      originDistanceToRight - headerWidth + SAVE_CONTAINER_PADDING;
    setSaveContainerOffset(distanceToRight);
  }, []);

  useEffect(() => {
    function validateProductPermission() {
      setPermissionToCreate(validatePermission('CADASTRAR_PRODUTOS'));
    }
    validateProductPermission();
  }, []);

  useEffect(() => {
    if (!hasPermissionToCreate) {
      product.showSnackbar('Você não tem permissão para criar produto', {
        type: 'error',
      });
      history.push('/');
    }
  }, [hasPermissionToCreate]);

  return (
    <>
      <ApplicationLayout
        title="Cadastrar produto"
        RightComponent={
          <Styled.SaveContainer style={{ right: saveContainerOffset }}>
            <Button
              variant="secondary"
              onClick={() => history.push('/produtos')}
            >
              <Text weight={500} color="black" type="regular">
                Voltar
              </Text>
            </Button>

            <Button
              variant="success"
              onClick={() => {
                handleCreation(
                  product.data,
                  product.setData,
                  product.setErrors,
                  product.showSnackbar,
                  setLoading,
                  setShowModal
                );
              }}
              loading={isLoading}
            >
              <Text weight={500} color="white" type="regular">
                Salvar
              </Text>
            </Button>
          </Styled.SaveContainer>
        }
      >
        <RegisteredModal show={showModal} />
        <Row>
          <Col xs={12} md={6}>
            <Section Title="Dados do produto">
              <Data />
            </Section>
          </Col>
        </Row>
      </ApplicationLayout>
    </>
  );
}
