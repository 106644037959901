function isNullOrEmpty(value) {
  if (value === null || value === undefined) {
    return 'Não informado';
  } else return value;
}

function isNumberValid(numberValue) {
  if (numberValue === undefined || numberValue === null) {
    return Infinity;
  } else return numberValue;
}

function isValidCostCenter(value) {
  if (value === null || value === undefined) {
    return Infinity;
  } else return value.split(' ')[0];
}

export const getCustomSortFunction = (rowA, rowB, sortColumn) => {
  switch (sortColumn) {
    case 'lastRasterPosition':
    case 'currentProvinceName':
    case 'serviceLevel':
    case 'driveName':
    case 'tractionPlate':
    case 'wagonslate':
    case 'tractionVehicleType':
    case 'truck_body':
    case 'shippingCompanyName':
    case 'lastCitieName':
    case 'lastTravelProvinceName':
    case 'pcpLastTravel':
    case 'negociatorLastTravel':
    case 'statusLastTravel':
    case 'lastExternalTravelNumber':
    case 'companyName':
      return (a = rowA, b = rowB) => {
        return isNullOrEmpty(a.original[sortColumn])
          .trim()
          .localeCompare(isNullOrEmpty(b.original[sortColumn]).trim());
      };
    case 'lastLoadId':
    case 'lastTravelNumber':
    case 'totalTravelFiltered':
    case 'margin':
    case 'profitability':
      return (a = rowA, b = rowB) => {
        return (
          isNumberValid(a.original[sortColumn]) -
          isNumberValid(b.original[sortColumn])
        );
      };
    case 'costCenterLasttravel':
      return (a = rowA, b = rowB) => {
        return (
          isValidCostCenter(a.original[sortColumn]) -
          isValidCostCenter(b.original[sortColumn])
        );
      };
    case 'lastTravelDate':
    case 'lastTimeCoperCargaLoad':
      return (a = rowA, b = rowB) => {
        return (
          new Date(a.original[sortColumn]) - new Date(b.original[sortColumn])
        );
      };
    default:
      return 'text';
  }
};
