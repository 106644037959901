const Yup = require('yup');

export const dataSchema = Yup.object().shape({
  person_type: Yup.string()
    .required('Tipo de Cadastro obrigatório')
    .typeError('Tipo de Cadastro obrigatório'),
  contact_email: Yup.string()
    .required('E-mail de contato obrigatório')
    .typeError('Email obrigatório'),
  contact_phone: Yup.string()
    .required('Telefone de contato obrigatório')
    .min(10, 'Minimo de caracteres não atingido')
    .typeError('Telefone obrigatório'),
  province_id: Yup.number()
    .required('Estado obrigatório')
    .typeError('Estado obrigatório'),
  city_id: Yup.number()
    .required('Cidade obrigatória')
    .typeError('Cidade obrigatória'),
  minimum_age: Yup.number()
    .required('Ano mínimo do veículo obrigatório')
    .typeError('Ano mínimo obrigatório'),
  vehicle_type_id: Yup.number()
    .required('Tipo de Veículo é obrigatório')
    .typeError('Tipo de veículo obrigatório'),
  vehicle_axi_id: Yup.number()
    .required('Tipo de Eixo é obrigatório')
    .typeError('Tipo de eixo obrigatório'),
  quantity: Yup.number()
    .required('Quantidade de vagas é obrigatória')
    .typeError('Quantidade de vagas obrigatória'),
});

export const aggregationSchema = Yup.object().shape({
  data: dataSchema,
});

export async function validate(field, data, setErrors, context) {
  const errorList = {};
  try {
    if (field === 'allSchema')
      await aggregationSchema.validate(data, {
        abortEarly: false,
        context: { profile: data.data.profile },
      });
    else
      await aggregationSchema.validateAt(field, data, {
        abortEarly: false,
        context: { profile: data[field].profile, ...context },
      });
    return true;
  } catch (err) {
    err.inner.forEach(error => {
      errorList[error.path] = error?.message;
    });
    return false;
  } finally {
    setErrors(errorList);
  }
}
