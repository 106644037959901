import api from 'services/api';
import validator from './validator';

export async function validate(data, setErrors) {
  const errorList = {};
  try {
    await validator.validate(data, { abortEarly: false });
    setErrors({ ...errorList });
    return true;
  } catch (err) {
    err.inner.forEach(error => {
      errorList[error.path] = error.message;
    });

    setErrors({ ...errorList });
    return false;
  }
}

export async function getProvinces(setProvinces) {
  const response = await api.get('provinces');
  const provinceData = response.data.map(el => ({
    ...el,
    label: el.name,
    value: el.id,
  }));
  setProvinces(provinceData);
}
