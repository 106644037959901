import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { cooperplaceApi } from 'services/api';
import { Modal, Text } from 'v3/components';
import Tab from 'v3/components/Tab';
import Tabs from 'v3/components/Tabs';
import { useSnackbar } from 'v3/components/Snackbar';
import { DefaultLoadingComponent } from 'v3/components/List';
import { useTravel } from 'v3/pages/Travel';
import { validatePermission } from 'actions';
import { BlockedTravelContext } from 'contexts/BlockedTravelProvider';
import { validateMarginSchema } from './validator';
import { AuditSection } from './Audit';
import { Liberation } from './Liberation';

export function MarginLiberationModal({ isOpen, onClose }) {
  const { isTravelReproved } = useContext(BlockedTravelContext);
  const travel = useTravel();
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [loadingJustifications, setLoadingJustifications] = useState(false);
  const [loadingCheckUserCC, setLoadingCheckUserCC] = useState(false);
  const [justificationOptions, setJustificationOptions] = useState([]);
  const [file, setFile] = useState([]);
  const [hasPermissionToApproveMargin, setHasPermissionApproveMargin] =
    useState(false);
  const [isCCManagedByUser, setIsCCManagedByUser] = useState(false);
  const [obs, setObs] = useState('');
  const [justification, setJustification] = useState(null);
  const [status, setStatus] = useState('Aprovado');
  const [errors, setErrors] = useState({});

  async function getJustificationOptions() {
    try {
      setLoadingJustifications(true);
      const { data } = await cooperplaceApi.get('travels/marginJustifications');
      setJustificationOptions(data?.data);
    } catch (error) {
      snackbar.show(<Text>Erro ao recuperar opções de justificativa</Text>, {
        type: 'error',
      });
    } finally {
      setLoadingJustifications(false);
    }
  }
  async function checkIfUserHasCC() {
    try {
      setLoadingCheckUserCC(true);
      const response = await cooperplaceApi.get(
        `users/checkCostCenter/${travel?.data?.loads?.costCenter?.id}`
      );
      setIsCCManagedByUser(response?.data?.data);
    } catch (error) {
      //
    } finally {
      setLoadingCheckUserCC(false);
    }
  }

  useEffect(() => {
    if (isOpen) {
      setFile([]);
      getJustificationOptions();
      checkIfUserHasCC();
      setHasPermissionApproveMargin(
        validatePermission('APROVAR_MARGEM_DA_VIAGEM')
      );
    }
  }, [isOpen]);

  const shouldDisplayApprovalCard = useMemo(() => {
    const allLiberationStatus =
      travel?.data?.travelMarginLiberation?.length > 0 &&
      travel?.data?.travelMarginLiberation
        .map(item => item.approval)
        ?.every(e => e === false);
    const hasPending = travel?.data?.travelMarginLiberation?.some(
      e => e.userApprovalId === null && e.approval === false
    );
    return (
      (allLiberationStatus || hasPending) &&
      !isTravelReproved &&
      hasPermissionToApproveMargin &&
      isCCManagedByUser
    );
  }, [
    travel?.data?.travelMarginLiberation,
    hasPermissionToApproveMargin,
    isCCManagedByUser,
  ]);

  const mostRecentLiberationPending = useMemo(() => {
    const mostRecent = travel?.data?.travelMarginLiberation?.find(item => {
      return item.approval === false && item.userApprovalId === null;
    });
    return mostRecent;
  }, [travel?.data?.travelMarginLiberation]);

  const marginLiberationsWithValidation = useMemo(() => {
    const onlyWithValidation = travel?.data?.travelMarginLiberation?.filter(
      item => item.userApprovalId !== null
    );
    return onlyWithValidation;
  }, [travel?.data?.travelMarginLiberation]);

  const statusMapper = {
    Aprovado: true,
    Reprovado: false,
  };

  const handleCreation = useCallback(async () => {
    try {
      setErrors({});
      setLoading(true);
      const data = {
        justificationId: justification?.id,
        description: obs,
        approval: statusMapper[status],
        liberationId: mostRecentLiberationPending?.id,
        dateApproval: new Date(),
      };

      const isValid = await validateMarginSchema(
        { obs, justification },
        setErrors
      );
      if (!isValid) {
        snackbar.show(<Text>Verifique os campos obrigatórios!</Text>, {
          type: 'error',
        });
        setLoading(false);

        return;
      }
      const response = await cooperplaceApi.post(
        `travels/marginApproval/${travel?.data?.id}`,
        data
      );
      if (file?.length && response?.data?.data?.travelId) {
        const attachment = await processAttachment(file);
        await cooperplaceApi.post(
          `travels/marginApprovalAttachment/${response?.data?.data?.travelId}`,
          attachment
        );
      }
      setLoading(false);
      setJustification(null);
      setObs('');
      setStatus('Aprovado');
      setFile([]);
      travel.fetchTravel();
    } catch (error) {
      snackbar.show(<Text>Erro ao liberar margem</Text>, { type: 'error' });
      setLoading(false);
      setJustification(null);
      setObs('');
      setStatus('Aprovado');
      setFile([]);
    }
  }, [
    status,
    obs,
    file,
    justification,
    errors,
    loading,
    mostRecentLiberationPending,
    travel,
  ]);

  const handleCancel = useCallback(() => {
    setStatus('Aprovado');
    setJustification(null);
    setObs('');
    setFile([]);
    setErrors({});
    onClose();
  }, []);

  async function processAttachment(file) {
    const processed = new FormData();
    file.forEach((file, index) => {
      const fileName = file.name
        .replaceAll('_', '-')
        .split('.')
        .slice(0, -1)
        .join('.');

      const isXLSXSheet =
        file.type.split('.').pop() === 'sheet' ? 'xlsx' : null;
      const isXLSSheet =
        file.type.split('.').pop() === 'ms-excel' ? 'xls' : null;
      let fileNameFormatted = null;
      if (isXLSXSheet) {
        fileNameFormatted = `${Date.now()}${index}.${isXLSXSheet}`;
      } else if (isXLSSheet) {
        fileNameFormatted = `${Date.now()}${index}.${isXLSSheet}`;
      } else {
        fileNameFormatted = `${Date.now()}${index}.${file.type.split('/')[1]}`;
      }
      const name = fileName
        ? `${fileName}-${fileNameFormatted}`
        : fileNameFormatted;
      const newFile = new File([file.blob], name, {
        type: file.type,
      });
      processed.append('file', newFile);
      processed.append('liberationId', mostRecentLiberationPending?.id);
    });
    return processed;
  }

  function handleModalClose() {
    if (loading) {
      return;
    }
    onClose();
  }

  return (
    <Modal
      size="lg"
      show={isOpen}
      handleClose={handleModalClose}
      heading={
        <Text type="regular" color="dark" weight="500">
          Liberação de Margem
        </Text>
      }
      body={
        <>
          {loadingJustifications || loadingCheckUserCC || travel?.isLoading ? (
            <DefaultLoadingComponent />
          ) : (
            <Tabs defaultActiveKey={0}>
              {shouldDisplayApprovalCard && (
                <Tab title="Aprovação" eventKey={0}>
                  <Liberation
                    status={status}
                    setStatus={setStatus}
                    file={file}
                    setFile={setFile}
                    obs={obs}
                    setObs={setObs}
                    justification={justification}
                    setJustification={setJustification}
                    justificationOptions={justificationOptions}
                    loading={loading}
                    handleCreation={handleCreation}
                    handleCancel={handleCancel}
                    errors={errors}
                    mostRecentLiberationPending={mostRecentLiberationPending}
                    isFetchingTravel={travel.isLoading}
                  />
                </Tab>
              )}
              <Tab
                title="Auditoria"
                eventKey={shouldDisplayApprovalCard ? 1 : 0}
              >
                <AuditSection liberations={marginLiberationsWithValidation} />
              </Tab>
            </Tabs>
          )}
        </>
      }
    />
  );
}
