import Carousel from 'react-bootstrap/Carousel';
import React from 'react';
import { StyledButton } from './styles';

function CarouselInformation() {
  return (
    <Carousel
      fade
      prevIcon={<StyledButton>Anterior</StyledButton>}
      nextIcon={<StyledButton>Próximo</StyledButton>}
      interval={30000}
    >
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="/images/carousel/img1.jpg"
          alt="1 slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="/images/carousel/img2.jpg"
          alt="2 slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="/images/carousel/img3.jpg"
          alt="3 slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="/images/carousel/img4.jpg"
          alt="4 slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="/images/carousel/img5.jpg"
          alt="5 slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="/images/carousel/img6.jpg"
          alt="6 slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="/images/carousel/img7.jpg"
          alt="7 slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="/images/carousel/img8.jpg"
          alt="8 slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="/images/carousel/img9.jpg"
          alt="9 slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="/images/carousel/img10.jpg"
          alt="10 slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="/images/carousel/img11.jpg"
          alt="11 slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="/images/carousel/img12.jpg"
          alt="12 slide"
        />
      </Carousel.Item>
    </Carousel>
  );
}

export default CarouselInformation;
