import React, { useState, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FaRegCalendar } from 'react-icons/fa';
import {
  Card,
  Text,
  Input,
  DatePicker,
  Button,
  Select,
  InputAddress,
} from 'v3/components';
import IconButton from 'v3/components/Button/Icon';
import { useSnackbar } from 'v3/components/Snackbar';
import api from 'services/api';
import { DELIVERY_TYPE_OPTIONS } from 'v3/utils/constants';
import { checkClientAddressData } from 'v3/pages/PreLoad/functions';
import { useModal } from 'hooks';
import { UpdateClientAddressData } from 'v3/pages/Client/shared/Modals/UpdateAddressData';
import { LoadContext, fetchSelectOptions } from '../controller';

const [, useLoad] = LoadContext;

function Destination() {
  const load = useLoad();

  const snackbar = useSnackbar();
  const updateClientAddressModal = useModal();
  const [client, setClient] = useState(null);
  const [clientCurrentAddressIndex, setClientCurrentAddressIndex] =
    useState(null);
  const [clientCurrentAddress, setClientCurrentAddress] = useState(null);
  const [disableNumber, setDisableNumber] = useState(false);
  const [disableZipCode, setDisableZipCode] = useState(false);
  const [disableCity, setDisableCity] = useState(false);
  const [disableProvince, setDisableProvince] = useState(false);
  const [disableNeighborhood, setDisableNeighborhood] = useState(false);

  function handleChangeValue(index, key, value) {
    load.setData(old => ({
      ...old,
      destinations: old.destinations?.map((item, idx) => {
        if (index === idx) {
          return { ...item, [key]: value };
        }

        return item;
      }),
    }));
  }

  function handleDeleteCard(idx) {
    load.setData(old => ({
      ...old,
      destinations: old.destinations.filter((_, index) => index !== idx),
    }));
  }

  function handleDestinationClientSelection(event, index) {
    disableAddress({}, null);
    if (event !== null) {
      const currentAddress = event?.addresses.filter(
        address => address.current
      )[0];
      if (checkClientAddressData(currentAddress)) {
        snackbar.show(
          <Text>Endereço do cliente desatualizado! Complete o cadastro</Text>,
          { type: 'error' }
        );
        setClientCurrentAddressIndex(index);
        setClientCurrentAddress(currentAddress);
        setClient(event);
        return updateClientAddressModal.open();
      } else {
        load.setData(old => ({
          ...old,
          destinations: old.destinations?.map((item, idx) => {
            if (index === idx) {
              return {
                ...item,
                client: event,
                address: currentAddress?.address,
                number: currentAddress?.number,
                neighborhood: currentAddress?.neighborhood,
                complement: currentAddress?.complement,
                zip_code: currentAddress?.zip_code,
                lat: currentAddress?.lat,
                lng: currentAddress?.lng,
                city: currentAddress?.city_name,
                cityModel: currentAddress?.city_name
                  ? {
                      id: currentAddress?.city_id,
                      name: currentAddress?.city_name,
                      province: {
                        uf: currentAddress?.uf_name,
                      },
                    }
                  : undefined,
                province: currentAddress?.uf_name,
                formatted_address: currentAddress?.formatted_address,
              };
            }

            return item;
          }),
        }));
      }
    } else {
      load.setData(old => ({
        ...old,
        destinations: old.destinations?.map((item, idx) => {
          if (index === idx) {
            return {
              ...item,
              client: null,
              address: '',
              number: '',
              neighborhood: '',
              complement: '',
              zip_code: '',
              lat: '',
              lng: '',
              city: '',
              cityModel: null,
              province: '',
              formatted_address: '',
            };
          }

          return item;
        }),
      }));
    }
  }

  function disableAddress(address, city) {
    setDisableNumber(!!address.number);
    setDisableZipCode(!!address.zip_code);
    setDisableCity(!!city);
    setDisableProvince(!!city?.province);
    setDisableNeighborhood(!!address.neighborhood);
  }

  async function fetchProvinces(search, uf, country) {
    try {
      const response = await api.get('cities', {
        params: { search, uf, country },
      });
      return response.data;
    } catch (ex) {
      return [];
    }
  }

  function setDestinationCity(index, city, options) {
    let cityModel = city;
    if (options.action === 'create-option') {
      cityModel = {
        name: city.id,
        province: null,
      };
    }

    if (cityModel) {
      handleChangeValue(index, 'cityModel', cityModel);
      handleChangeValue(index, 'province', cityModel?.province?.uf || '');
    } else {
      handleChangeValue(index, 'cityModel', null);
      handleChangeValue(index, 'province', '');
    }
  }

  function handleUpdateAddress(data){
    load.setData(old => ({
      ...old,
      destinations: old.destinations?.map((item, idx) => {
        if (clientCurrentAddressIndex === idx) {
          return {
            ...item,
            client: client,
            address: data?.address,
            number: data?.number,
            neighborhood: data?.neighborhood,
            complement: data?.complement,
            zip_code: data?.zip_code,
            lat: data?.lat,
            lng: data?.lng,
            city: data?.city_name,
            cityModel: data?.city_name
              ? {
                  id: data?.city_id,
                  name: data?.city_name,
                  province: {
                    uf: data?.uf_name,
                  },
                }
              : undefined,
            province: data?.uf_name,
            formatted_address: data?.formatted_address,
          };
        }

        return item;
      }),
    }));
  }

  const renderItems = useCallback(() => {
    return (
      <>
        {load.data?.destinations?.map((item, index) => (
          <>
            <Col md={12} xs={12}>
              {index !== 0 && <hr className="mb-4" />}
              <div className="mb-4">
                <Text type="header" color="dark">
                  Destino {index + 1}
                </Text>
              </div>
              <Row className="form">
                <Col
                  md={10}
                  xs={12}
                  className="d-flex flex-row align-items-center"
                >
                  <Select
                    label="País *"
                    value={load.data.destinations[index]?.country}
                    options={load.countryList}
                    onChange={event => {
                      handleChangeValue(index, 'country', event);
                    }}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                    error={load.errors[`destinations[${index}].country`]}
                    id={`select-pais-destino-${index}`}
                  />
                </Col>
                <Col md={2} xs={12} className="text-right mt-4">
                  <IconButton
                    style={{ marginTop: 12 }}
                    icon="FaTrash"
                    variant="error"
                    disabled={index === 0}
                    onClick={() => handleDeleteCard(index)}
                    id={`button-deletar-destino-${index}`}
                  />
                </Col>
                <Col md={12} xs={12}>
                  <Select.Async
                    label="Cliente"
                    onSearch={search =>
                      fetchSelectOptions('persons/customers', { search })
                    }
                    value={load.data.destinations[index]?.client}
                    getOptionLabel={option => {
                      let label = `${option?.social_name} - ${option?.cgccpf}`;

                      if (option.city) {
                        label += ` - ${option?.city}`;
                      }

                      return label;
                    }}
                    getOptionValue={option => option?.id}
                    onChange={event => {
                      handleDestinationClientSelection(event, index);
                    }}
                    id={`select-cliente-destino-${index}`}
                  />
                </Col>
                <Col md={8} xs={12}>
                  <InputAddress
                    label="Logradouro *"
                    placeholder=""
                    value={load.data.destinations[index]?.address}
                    onSelectedAddress={async place => {
                      handleChangeValue(index, 'address', place.address || '');
                      const [city] = await fetchProvinces(
                        place.city,
                        place.province,
                        place.country_abbreviation
                      );
                      disableAddress(place, city);

                      handleChangeValue(
                        index,
                        'number',
                        place.number ? place.number : ''
                      );
                      handleChangeValue(
                        index,
                        'zip_code',
                        place.zip_code ? place.zip_code : ''
                      );
                      handleChangeValue(index, 'cityModel', city);
                      handleChangeValue(
                        index,
                        'lat',
                        place.lat ? place.lat : ''
                      );
                      handleChangeValue(
                        index,
                        'lng',
                        place.lng ? place.lng : ''
                      );
                      handleChangeValue(
                        index,
                        'complement',
                        place.name ? place.name : ''
                      );
                      handleChangeValue(
                        index,
                        'province',
                        city?.province?.uf || ''
                      );
                      handleChangeValue(
                        index,
                        'formatted_address',
                        place.formatted_address ? place.formatted_address : ''
                      );
                      handleChangeValue(
                        index,
                        'neighborhood',
                        place.neighborhood ? place.neighborhood : ''
                      );
                    }}
                    onChange={({ target }) => {
                      handleChangeValue(index, 'address', target.value);
                    }}
                    types={[]}
                    error={load.errors[`destinations[${index}].address`]}
                    country={
                      load.data?.destinations[index]?.country
                        ? load.data?.destinations[index]?.country.abbreviation
                        : null
                    }
                    id={`select-logradouro-destino-${index}`}
                  />
                </Col>
                <Col md={4} xs={12} className="mb-3">
                  <Input
                    label="Número"
                    disabled={disableNumber}
                    value={load.data.destinations[index]?.number}
                    onChange={({ target }) => {
                      handleChangeValue(index, 'number', target.value);
                    }}
                    id={`input-numero-destino-${index}`}
                  />
                </Col>
                <Col md={4} xs={12}>
                  <Input
                    label="Complemento"
                    value={load.data.destinations[index]?.complement}
                    onChange={({ target }) => {
                      handleChangeValue(index, 'complement', target.value);
                    }}
                    id={`input-complemento-destino-${index}`}
                  />
                </Col>
                <Col md={4} xs={12}>
                  <Input
                    label="Bairro"
                    disabled={disableNeighborhood}
                    value={load.data.destinations[index]?.neighborhood}
                    onChange={({ target }) => {
                      handleChangeValue(index, 'neighborhood', target.value);
                    }}
                    id={`input-bairro-destino-${index}`}
                  />
                </Col>
                <Col md={4} xs={12}>
                  <Input
                    label="CEP"
                    disabled={disableZipCode}
                    value={load.data.destinations[index]?.zip_code}
                    onChange={({ target }) => {
                      handleChangeValue(index, 'zip_code', target.value);
                    }}
                    id={`input-cep-destino-${index}`}
                  />
                </Col>
                <Col md={8} xs={12}>
                  <Select.Async
                    label="Cidade *"
                    onSearch={city =>
                      fetchProvinces(
                        city,
                        '',
                        load.data.destinations[index]?.country?.abbreviation
                      )
                    }
                    value={load.data.destinations[index]?.cityModel}
                    horizontal
                    creatable
                    onChange={(value, options) =>
                      setDestinationCity(index, value, options)
                    }
                    getOptionLabel={option =>
                      `${`${option.name} ${
                        option.province?.uf ? ` - ${option.province?.uf}` : ''
                      }`}`
                    }
                    getOptionValue={option =>
                      `${`${option.name} ${
                        option.province?.uf ? `- ${option.province?.uf}` : null
                      }`}`
                    }
                    error={load.errors[`destinations[${index}].cityModel`]}
                    isDisabled={disableCity}
                    id={`select-cidade-destino-${index}`}
                  />
                </Col>
                <Col md={4} xs={12}>
                  <Input
                    label="UF *"
                    disabled={disableProvince}
                    value={load.data.destinations[index]?.province}
                    onChange={({ target }) => {
                      handleChangeValue(index, 'province', target.value);
                    }}
                    error={load.errors[`destinations[${index}].province`]}
                    id={`input-uf-destino-${index}`}
                  />
                </Col>
                <Col md={6} className="mt-2">
                  <Input
                    label="Latitude"
                    disabled
                    value={load.data.destinations[index]?.lat}
                    error={load.errors[`destinations[${index}].lat`]}
                    tooltip="Selecione um endereço sugerido para obter dados de latitude e longitude"
                    id={`input-latitude-destino-${index}`}
                  />
                </Col>
                <Col md={6} className="mt-2">
                  <Input
                    label="Longitude"
                    disabled
                    value={load.data.destinations[index]?.lng}
                    error={load.errors[`destinations[${index}].lng`]}
                    tooltip="Selecione um endereço sugerido para obter dados de latitude e longitude"
                    id={`input-longitude-destino-${index}`}
                  />
                </Col>
                <Col md={6} xs={12}>
                  <DatePicker
                    guide
                    value={load.data.destinations[index]?.start_schedule}
                    label="Início da entrega *"
                    onChange={({ target }) => {
                      handleChangeValue(index, 'start_schedule', target.value);
                    }}
                    withTime
                    error={load.errors[`destinations[${index}].start_schedule`]}
                    id={`input-data-inicio-destino-${index}`}
                  />
                </Col>
                <Col md={6} xs={12}>
                  <Input
                    placeholder="Digite uma data"
                    icon={<FaRegCalendar color="#494950" />}
                    label={
                      load.data?.destinations[index]?.type?.id === 'collect'
                        ? 'Data máxima da coleta *'
                        : 'Data máxima da entrega *'
                    }
                    value={load.data?.destinations[index]?.date}
                    onChange={({ target }) => {
                      handleChangeValue(index, 'date', target.value);
                    }}
                    masked
                    mask={[
                      /[0-9]/,
                      /[0-9]/,
                      '/',
                      /[0-9]/,
                      /[0-9]/,
                      '/',
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      ' ',
                      /[0-9]/,
                      /[0-9]/,
                      ':',
                      /[0-9]/,
                      /[0-9]/,
                    ]}
                    error={load.errors[`destinations[${index}].date`]}
                    id={`input-data-maxima-destino-${index}`}
                  />
                </Col>

                <div className="mx-auto mb-2">
                  <Text type="label" color="red">
                    {load.errors[`destinations[${index}]`]}
                  </Text>
                </div>
                <Col md={6} xs={12}>
                  <Select
                    label="Ação *"
                    value={load.data.destinations[index]?.type}
                    onChange={value => {
                      handleChangeValue(index, 'type', value);
                    }}
                    getOptionLabel={option => option?.name}
                    getOptionValue={option => option?.id}
                    options={DELIVERY_TYPE_OPTIONS}
                    error={load.errors[`destinations[${index}].type`]}
                    id={`select-acao-destino-${index}`}
                  />
                </Col>
              </Row>
            </Col>
          </>
        ))}

        <Col xs={12} className="text-left">
          <Button
            className="py-2"
            onClick={() => {
              load.setData(old => ({
                ...old,
                destinations: [
                  ...load.data.destinations,
                  {
                    address: '',
                    type: DELIVERY_TYPE_OPTIONS[1],
                    date: '',
                    complement: '',
                    lat: '',
                    lng: '',
                    formatted_address: '',
                    neighborhood: '',
                    city: '',
                    province: '',
                    zip_code: '',
                    country: {
                      id: 1,
                      name: 'Brasil',
                      abbreviation: 'br',
                      ddi: 55,
                    },
                    number: '',
                    start_schedule: '',
                    index: load.data?.destinations?.length + 1,
                  },
                ],
              }));
            }}
            id="button-adicionar-destino"
          >
            <Text weight={500} type="regular">
              Adicionar mais 1 destino
            </Text>
          </Button>
        </Col>
      </>
    );
  }, [load.data?.destinations, load.errors]);

  return (
    <Card
      header={
        <Text color="#464E5F" type="header">
          {load.data?.destinations?.length > 1 ? 'Destinos' : 'Destino'}
        </Text>
      }
    >
      <>
        <UpdateClientAddressData
          show={updateClientAddressModal.isOpen}
          onSubmit={handleUpdateAddress}
          onClose={() => {
            setClient(null);
            setClientCurrentAddress(null);
            setClientCurrentAddressIndex(null);
            updateClientAddressModal.close();
          }}
          currentAddressData={clientCurrentAddress}
          countryOptions={load?.countryList}
        />
        <Row>{load.data.destinations?.length && renderItems()}</Row>
      </>
    </Card>
  );
}

export default Destination;
