/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

import Accordion from 'v3/components/Accordion';
import Select from 'v3/components/Select';

import * as Controller from './controller';
import { LoadContext } from '../controller';

const [, useLoad] = LoadContext;

export default function Automations({ clientAutomations }) {
  const load = useLoad();
  const [automations, setAutomations] = useState([]);
  const [selectedAutomations, setSelectedAutomations] = useState([]);

  async function fetchAutomations() {
    const data = await Controller.fetchAutomations();
    setAutomations(data);
  }

  useEffect(() => {
    fetchAutomations();
  }, []);

  useEffect(() => {
    setSelectedAutomations(clientAutomations);
  }, [clientAutomations]);

  useEffect(() => {
    load.setData(old => ({ ...old, selectedAutomations }));
  }, [selectedAutomations]);

  return (
    <Col xs={12}>
      <Accordion
        title="Automações"
        closedText="Ver automações"
        closeButtonText="Fechar"
      >
        <Row className="form">
          <Col lg={6} md={6} xs={12}>
            <Select
              label="Automações"
              options={automations}
              value={selectedAutomations}
              onChange={value => setSelectedAutomations(value || [])}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              multiple
            />
          </Col>
        </Row>
      </Accordion>
    </Col>
  );
}
