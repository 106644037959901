import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import ApplicationLayout from 'v3/layouts/Application';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import useWindowResize from 'hooks/useWindowResize';
import useModal from 'hooks/useModal';
import api from 'services/apis/cooperplace';
import { usePermission } from 'hooks';
import CreatedCostCenterModal from './Modal/index';
import Data from '../shared/Data/index';
import Section from '../shared/Section';
import { validate } from '../shared/validator';
import { useCostCenter } from '../shared/context';
import * as Styled from './styles';

export default function CostCenterRegister() {
  const history = useHistory();
  const costCenter = useCostCenter();
  const createdCostCenterModal = useModal();
  const [isLoading, setLoading] = useState(false);
  const [saveContainerOffset, setSaveContainerOffset] = useState(0);
  usePermission('CADASTRAR_CENTRO_CUSTO', { redirect: true });

  async function handleCreation(setData, setErrors) {
    setLoading(true);
    const isValid = await validate(
      'allSchema',
      {
        data: costCenter.data,
      },
      costCenter.setErrors
    );
    if (!isValid) {
      costCenter.showSnackbar('Verifique os campos obrigatórios', {
        type: 'error',
      });
      setLoading(false);
      return;
    }
    try {
      setLoading(true);
      const response = await api.post('/cost-center', {
        name: costCenter.data.name,
        externalId: costCenter.data.externalId || null,
        identifier: costCenter.data.identifier || null,
      });

      setLoading(false);
      costCenter.setData({ id: response.data.id });
      createdCostCenterModal.open();
    } catch (err) {
      setLoading(false);
      if (err.response) {
        const errors = err?.response?.data;

        if (Array.isArray(errors)) {
          setErrors(old => ({
            ...old,
            ...Object.fromEntries(
              errors?.map(error => [error.field, error.message])
            ),
          }));
        } else {
          setErrors(errors.error);
        }
        costCenter.showSnackbar(
          errors.error || 'Erro ao cadastrar centro de custo!',
          {
            type: 'error',
          }
        );
      }
    }
  }

  useWindowResize(() => {
    const header = document.getElementById('header-container');
    const originDistanceToRight =
      window.pageXOffset + header.getBoundingClientRect().right;
    const headerWidth = header.getBoundingClientRect().width;

    const SAVE_CONTAINER_PADDING = 0;
    const distanceToRight =
      originDistanceToRight - headerWidth + SAVE_CONTAINER_PADDING;
    setSaveContainerOffset(distanceToRight);
  }, []);

  return (
    <ApplicationLayout
      title="Centro de custo"
      RightComponent={
        <Styled.SaveContainer style={{ right: saveContainerOffset }}>
          <Button
            variant="secondary"
            onClick={() => history.push('/centro-de-custo')}
          >
            <Text weight={500} color="black" type="regular">
              Voltar
            </Text>
          </Button>

          <Button
            variant="success"
            onClick={() => {
              handleCreation(costCenter.setData, costCenter.setErrors);
            }}
            loading={isLoading}
          >
            <Text weight={500} color="white" type="regular">
              Salvar
            </Text>
          </Button>
        </Styled.SaveContainer>
      }
    >
      <CreatedCostCenterModal
        isOpen={createdCostCenterModal.isOpen}
        onClose={createdCostCenterModal.close}
      />
      <Row>
        <Col xs={12} md={6}>
          <Section Title="Dados gerais">
            <Data />
          </Section>
        </Col>
      </Row>
    </ApplicationLayout>
  );
}
