import React from 'react';
import theme from 'v3/theme';
import { InfoContainerVehicle, StyledSpanVehicle } from './styles';

export default function CardSubtitleVehicleTower() {
  return (
    <InfoContainerVehicle>
      <StyledSpanVehicle color={theme.colors.success}>Vazio</StyledSpanVehicle>
      <StyledSpanVehicle color={theme.colors.light_blue}>
        Viagem
      </StyledSpanVehicle>
      <StyledSpanVehicle
        color={theme.colors.yellow}
        title="Última localização enviada ultrapassou 24 horas"
      >
        Viagem sem posição atual
      </StyledSpanVehicle>
      <StyledSpanVehicle
        color={theme.colors.orange}
        title="Última localização enviada ultrapassou 24 horas"
      >
        Vazio sem posição atual
      </StyledSpanVehicle>
    </InfoContainerVehicle>
  );
}
