import api from 'services/api';
import { fetchMinimumFreightTableItem } from '../shared/controller';

export const currency = {
  currency: 'BRL',
  currency_symbol: 'R$',
};

export async function fetchDistanceInKilometers(route) {
  try {
    const { destinations } = route.data;
    const originCoordinates = `${route.data.origin.lat},${route.data.origin.lng}`;
    const destinationsCoordinates = destinations
      .filter(item => item.lat && item.lng)
      .map(item => `${item.lat},${item.lng}`);

    const coordinates = [originCoordinates, ...destinationsCoordinates];

    const response = await api.get('load/distance', {
      params: {
        coordinates,
      },
    });

    route.setData({
      distance: (response.data.lengthInMeters / 1000).toFixed(1),
    });
  } catch (ex) {
    route.setData({ distance: 0 });
  }
}

export function findBelowPriceItems(route) {
  let aux = [];
  if (
    route.data?.loading_charge < route.minimumFreightTableItem?.charge_price
  ) {
    aux.push('Taxa de Carregamento');
  }
  if (
    route.data?.discharge_price < route.minimumFreightTableItem?.charge_price
  ) {
    aux.push('Descarga');
  }
  if (route.data?.charge_type?.value === 1) {
    if (route.data?.charge_price < route.minimumFreightTableItem?.value) {
      aux.push('Valor por R$/km');
    }
  } else if (route.data?.charge_type?.value === 3) {
    if (
      route.data?.charge_price <
      route.minimumFreightTableItem?.value * Number(route?.data?.distance)
    ) {
      aux.push('Valor fixo por rota');
    }
  }
  let items = aux.map(item => item).join(', ');
  return items;
}

export async function handleChange(route) {
  if (route.data.vehicles && route.data.loadType) {
    let hasMoreAxys = { qty_axes: 0 };
    route.data.vehicles.forEach(item => {
      if (item.qty_axes > hasMoreAxys.qty_axes) {
        hasMoreAxys = { ...item };
      }
    });
    await fetchMinimumFreightTableItem({
      load_type: route.data?.loadType?.id,
      qty_axys: hasMoreAxys.qty_axes,
    }).then(data => route.setMinimumFreightTableItem(data[0]));
    return;
  } else {
    route.setMinimumFreightTableItem({});
    return;
  }
}
