import api from 'services/api';

export async function fetchSelectOptions(url, params = {}) {
  try {
    const response = await api.get(url, { params });
    return response?.data?.data ? response?.data?.data : response?.data;
  } catch (err) {
    return [];
  }
}
