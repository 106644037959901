import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Select, Text } from 'v3/components';
import CheckBox from 'components/CheckBox';
import TextArea from 'v3/components/TextArea';
import {
  FaAdjust,
  FaBars,
  FaCamera,
  FaCheckCircle,
  FaCheckSquare,
  FaImage,
  FaPlus,
} from 'react-icons/fa';
import { DndContext, closestCenter } from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import api, { cooperplaceApi } from 'services/api';
import {
  AddAnswerButton,
  AddAnswerButtonContainer,
  QuestionGeneralInfoContainer,
} from './styles';
import { SortableAnswer } from './SortableAnswerComponent';
import {
  DESCRIPTIVE_QUESTION_SLUG,
  IMAGE_QUESTION_SLUG,
  LOGICAL_QUESTION_ANSWERS,
  LOGICAL_QUESTION_SLUG,
  VIDEO_QUESTION_SLUG,
  QUESTION_TYPES_ALLOWED_TO_CREATE_OCCURRENCE,
  QUESTION_TYPES_ALLOWED_TO_HAVE_CHILD_QUESTION,
  QUESTION_TYPES_ALLOWED_TO_REGISTER_ANSWER,
  QUESTION_TYPES_ALLOWED_TO_REQUEST_ATTACHMENT,
  UNIQUE_QUESTION_SLUG,
} from '../../shared/utils';

export function RegisterComponent({
  isRegister,
  question,
  setQuestion,
  questionType,
  setQuestionType,
  childQuestion,
  setChildQuestion,
  childQuestionType,
  setChildQuestionType,
  errors,
  setErrors,
}) {
  const [groupOptions, setGroupOptions] = useState([]);
  const [questionTypes, setQuestionTypes] = useState([]);
  const questionTypeOptions = [
    {
      slug: 'descriptive',
      emoji: <FaBars />,
    },
    {
      slug: 'unique',
      emoji: <FaCheckCircle />,
    },
    {
      slug: 'multiple',
      emoji: <FaCheckSquare />,
    },
    {
      slug: 'image',
      emoji: <FaImage />,
    },
    {
      slug: 'logic',
      emoji: <FaAdjust />,
    },
    {
      slug: 'video',
      emoji: <FaCamera />,
    },
  ];
  const mapEmojisToData = (data, options) => {
    return data.map(item => {
      const option = options.find(option => option.slug === item.slug);

      if (option) {
        return {
          ...item,
          emoji: option.emoji,
        };
      }

      return item;
    });
  };

  async function fetchQuestionTypes() {
    try {
      const response = await cooperplaceApi.get('questions-types');
      const responseWithEmojis = mapEmojisToData(
        response.data,
        questionTypeOptions
      );
      setQuestionTypes(responseWithEmojis);
    } catch (error) {}
  }

  useEffect(() => {
    fetchQuestionTypes();
  }, []);

  useEffect(() => {
    if (questionTypes?.length > 0) {
      setQuestionType(questionType ?? questionTypes[0]);
      setChildQuestionType(childQuestionType ?? questionTypes[0]);
    }
  }, [questionTypes]);

  async function fetchGroupsOptions() {
    try {
      const response = await api.get('groups', {
        params: {
          onlyActive: true,
        },
      });
      setGroupOptions(response.data);
      return;
    } catch (error) {
      setGroupOptions([]);
    }
  }

  useEffect(() => {
    fetchGroupsOptions();
  }, []);
  function handleAddAnswer(setter) {
    setter(old => ({
      ...old,
      answers: [
        ...old?.answers,
        {
          id: old?.answers?.length + 1,
          order: old?.answers?.length + 1,
          description: '',
          triggerOccurrence: false,
          triggerSubQuestion: false,
        },
      ],
    }));
  }
  function handleRemoveParentAnswer(index) {
    const updatedAnswers = handleRemoveAnswer(index, [...question?.answers]);
    setQuestion(old => ({ ...old, answers: [...updatedAnswers] }));
  }

  function handleRemoveChildAnswer(index) {
    const updatedAnswers = handleRemoveAnswer(index, [
      ...childQuestion?.answers,
    ]);
    setChildQuestion(old => ({ ...old, answers: [...updatedAnswers] }));
  }

  function handleRemoveAnswer(indexToRemove, answersList) {
    const updatedAnswers = answersList?.filter(
      (_, idx) => idx !== indexToRemove
    );
    return updatedAnswers;
  }

  function handleOnDragEnd(event, answersList) {
    const { active, over } = event;
    if (active.id === over.id) {
      return;
    }
    const previousAnswersList = [...answersList];
    const oldIndex = previousAnswersList.findIndex(
      item => item.id === active.id
    );
    const newIndex = previousAnswersList.findIndex(item => item.id === over.id);
    const newAnswerListOrder = arrayMove(
      previousAnswersList,
      oldIndex,
      newIndex
    );
    const newAnswerListOrderWithOrderProp = newAnswerListOrder.map(
      (item, index) => {
        return {
          ...item,
          order: index + 1,
        };
      }
    );
    return newAnswerListOrderWithOrderProp;
  }
  function handleParentOnDragEnd(event) {
    const newOrder = handleOnDragEnd(event, question?.answers);
    setQuestion(old => ({
      ...old,
      answers: newOrder?.length ? [...newOrder] : old?.answers,
    }));
  }

  function handleChildOnDragEnd(event) {
    const newOrder = handleOnDragEnd(event, childQuestion?.answers);
    setChildQuestion(old => ({
      ...old,
      answers: newOrder?.length ? [...newOrder] : old?.answers,
    }));
  }

  const canAddNewAnswerOption = useMemo(() => {
    return (
      QUESTION_TYPES_ALLOWED_TO_REGISTER_ANSWER.includes(questionType?.slug) &&
      question?.questionDescription?.length > 0 &&
      (question?.answers?.length === 0 ||
        (question?.answers?.length >= 1 &&
          question?.answers?.every(item => item?.description?.length >= 1)))
    );
  }, [question?.answers, question?.questionDescription, questionType]);

  const addNewAnswerBlockedMessage = useMemo(() => {
    if (
      !QUESTION_TYPES_ALLOWED_TO_REGISTER_ANSWER.includes(questionType?.slug)
    ) {
      return 'Tipo de pergunta não permite inserir opções de resposta';
    }
    if (question?.questionDescription?.length === 0) {
      return 'Informe uma pergunta para cadastrar opções de resposta';
    }
    if (
      question?.answers?.length >= 1 &&
      question?.answers?.some(item => item?.description?.length < 3)
    ) {
      return 'Termine de preencher alternativas anteriores antes de cadastrar uma nova';
    }
  }, [question?.answers, question?.questionDescription, questionType]);

  const canAddNewAnswerChildOption = useMemo(() => {
    return (
      QUESTION_TYPES_ALLOWED_TO_REGISTER_ANSWER.includes(
        childQuestionType?.slug
      ) &&
      childQuestion?.questionDescription?.length > 0 &&
      (childQuestion?.answers?.length === 0 ||
        (childQuestion?.answers?.length >= 1 &&
          childQuestion?.answers?.every(
            item => item?.description?.length >= 1
          )))
    );
  }, [
    childQuestion?.answers,
    childQuestion?.questionDescription,
    childQuestionType,
  ]);

  const addNewChildAnswerBlockedMessage = useMemo(() => {
    if (
      !QUESTION_TYPES_ALLOWED_TO_REGISTER_ANSWER.includes(
        childQuestionType?.slug
      )
    ) {
      return 'Tipo de pergunta não permite inserir opções de resposta';
    }
    if (childQuestion?.questionDescription?.length === 0) {
      return 'Informe uma pergunta para cadastrar opções de resposta';
    }
    if (
      childQuestion?.answers?.length >= 1 &&
      childQuestion?.answers?.some(item => item?.description?.length < 3)
    ) {
      return 'Termine de preencher alternativas anteriores antes de cadastrar uma nova';
    }
  }, [
    childQuestion?.answers,
    childQuestion?.questionDescription,
    childQuestionType,
  ]);

  const canGenerateOccurrence =
    QUESTION_TYPES_ALLOWED_TO_CREATE_OCCURRENCE.includes(questionType?.slug);

  const canChildGenerateOccurrence =
    QUESTION_TYPES_ALLOWED_TO_CREATE_OCCURRENCE.includes(
      childQuestionType?.slug
    );

  const canAddChildQuestion =
    QUESTION_TYPES_ALLOWED_TO_HAVE_CHILD_QUESTION.includes(
      questionType?.slug
    ) &&
    question?.questionDescription?.length >= 1 &&
    question.answers?.length > 0 &&
    question.answers.some(item => item?.description?.length >= 1);

  const occurrencesTriggerOptions = question?.answers?.filter(
    item => item.description?.length >= 1
  );

  const subQuestionTriggerOptions = question?.answers?.filter(
    item => item.description?.length >= 1
  );

  const subQuestionOccurrencesTriggerOptions = childQuestion?.answers?.filter(
    item => item.description?.length >= 1
  );

  const canRequestAttachment =
    QUESTION_TYPES_ALLOWED_TO_REQUEST_ATTACHMENT.includes(questionType?.slug);

  const canTriggersBeMultiple =
    questionType?.slug !== UNIQUE_QUESTION_SLUG &&
    questionType?.slug !== LOGICAL_QUESTION_SLUG;

  const canChildTriggersBeMultiple =
    childQuestionType?.slug !== UNIQUE_QUESTION_SLUG &&
    childQuestionType?.slug !== LOGICAL_QUESTION_SLUG;

  function handleQuestionTypeChange(value) {
    if (
      question?.createOccurrence &&
      value?.slug?.toLocaleLowerCase() ===
        QUESTION_TYPES_ALLOWED_TO_REGISTER_ANSWER
    ) {
      const updatedAnswers = question.answers.map(item => {
        return { ...item, triggerOccurrence: false };
      });
      setQuestion(old => ({
        ...old,
        createOccurrence: false,
        answers: updatedAnswers,
      }));
    } else if (value?.slug?.toLocaleLowerCase() === LOGICAL_QUESTION_SLUG) {
      setQuestion(old => ({
        ...old,
        answers: [...LOGICAL_QUESTION_ANSWERS],
      }));
    } else if (value?.slug?.toLocaleLowerCase() === IMAGE_QUESTION_SLUG) {
      setQuestion(old => {
        return {
          ...old,
          answers: [],
          createOccurrence: false,
          hasSubQuestion: false,
          needAttachment: false,
          occurrenceSector: null,
        };
      });
    } else {
      setQuestion(old => ({ ...old, answers: [] }));
    }
    setErrors({});
    setQuestionType(value);
  }

  function handleChildQuestionTypeChange(value) {
    if (childQuestion?.createOccurrence) {
      const updatedAnswers = childQuestion.answers.map(item => {
        return { ...item, triggerOccurrence: false };
      });
      setChildQuestion(old => ({
        ...old,
        createOccurrence: false,
        answers: updatedAnswers,
      }));
    }
    if (value?.slug?.toLocaleLowerCase() === LOGICAL_QUESTION_SLUG) {
      setChildQuestion(old => ({
        ...old,
        answers: [...LOGICAL_QUESTION_ANSWERS],
      }));
    }
    if (
      value?.slug?.toLocaleLowerCase() === IMAGE_QUESTION_SLUG ||
      value?.slug?.toLocaleLowerCase() === DESCRIPTIVE_QUESTION_SLUG
    ) {
      setChildQuestion(old => ({
        ...old,
        answers: [],
      }));
    }
    setChildQuestionType(value);
  }

  const isParentOccurrenceBlocked = childQuestion?.createOccurrence;
  const isChildOccurrenceBlocked = question?.createOccurrence;

  function handleOnSearchGroup(search) {
    if (!search) {
      return groupOptions;
    }
    const parsed = search?.toLocaleLowerCase();
    return groupOptions?.filter(
      option => option?.name?.toLocaleLowerCase().search(parsed) >= 0
    );
  }
  return (
    <>
      <Row className="my-2 pb-4">
        <Col md={6} sm={12}>
          <Select.Async
            label="Tipo de pergunta *"
            options={questionTypes}
            onChange={value => {
              handleQuestionTypeChange(value);
            }}
            getOptionLabel={option => (
              <span>
                {option?.emoji} {option?.name}
              </span>
            )}
            getOptionValue={option => option.id}
            value={questionType}
            disableClear
            onSearch={() => {}}
          />
        </Col>
        <QuestionGeneralInfoContainer>
          {canRequestAttachment && (
            <div>
              <Text as="span" color="dark" type="medium" weight={500}>
                Exige anexo de imagem
              </Text>
              <CheckBox
                label="Sim"
                labelStyle={{
                  textTransform: 'capitalize',
                  color: '#464E5F',
                }}
                value={question?.needAttachment}
                checked={question?.needAttachment}
                onChange={() =>
                  setQuestion(old => ({
                    ...old,
                    needAttachment: !old?.needAttachment,
                  }))
                }
              />
            </div>
          )}
          {!isRegister &&
          <div>
            <Text
              title="Pergunta está ativa ou inativa"
              as="span"
              color="dark"
              type="medium"
              weight={500}
            >
              Status
            </Text>
            <CheckBox
              label="Ativa"
              labelStyle={{
                textTransform: 'capitalize',
                color: '#464E5F',
              }}
              checked={question?.active}
              value={question?.active}
              onChange={() =>
                setQuestion(old => ({
                  ...old,
                  active: !old?.active,
                }))
              }
            />
          </div>
          }
          {canGenerateOccurrence && (
            <div>
              <Text as="span" color="dark" type="medium" weight={500}>
                Gera ocorrência
              </Text>
              <CheckBox
                label="Sim"
                labelStyle={{
                  textTransform: 'capitalize',
                  color: '#464E5F',
                }}
                checked={question?.createOccurrence}
                value={question?.createOccurrence}
                onChange={() => {
                  if (question?.createOccurrence) {
                    const updatedAnswers = question?.answers?.map(item => {
                      return { ...item, triggerOccurrence: false };
                    });
                    setQuestion(old => ({
                      ...old,
                      createOccurrence: !old?.createOccurrence,
                      answers: updatedAnswers,
                    }));
                  } else {
                    setQuestion(old => ({
                      ...old,
                      createOccurrence: !old?.createOccurrence,
                    }));
                  }
                }}
                disabled={isParentOccurrenceBlocked}
              />
            </div>
          )}
        </QuestionGeneralInfoContainer>
      </Row>
      <Row className="my-2">
        <Col md={10} sm={12}>
          <TextArea
            label="Informe a pergunta *"
            value={question?.questionDescription}
            onChange={e => {
              const content = e.target.value;
              setQuestion(old => ({
                ...old,
                questionDescription: content,
              }));
            }}
            error={
              errors?.['question.questionDescription'] ||
              errors?.['question.answers']
            }
          />
        </Col>
        <AddAnswerButtonContainer md={2} sm={12}>
          <AddAnswerButton
            onClick={() => handleAddAnswer(setQuestion)}
            disabled={!canAddNewAnswerOption}
            title={!canAddNewAnswerOption && addNewAnswerBlockedMessage}
          >
            <FaPlus />
            <Text type="body" color="dark" weight="500">
              Inserir resposta
            </Text>
          </AddAnswerButton>
        </AddAnswerButtonContainer>
      </Row>
      <DndContext
        collisionDetection={closestCenter}
        onDragEnd={event => handleParentOnDragEnd(event)}
      >
        <SortableContext
          items={question?.answers}
          strategy={verticalListSortingStrategy}
        >
          {question?.answers?.map((item, index) => {
            return (
              <SortableAnswer
                key={item?.id}
                answer={item}
                index={index}
                question={question}
                setQuestion={setQuestion}
                handleRemoveAnswer={handleRemoveParentAnswer}
                questionType={questionType}
                errors={errors}
              >
                {JSON.stringify(item)}
              </SortableAnswer>
            );
          })}
        </SortableContext>
      </DndContext>
      {question?.createOccurrence && (
        <Row className="my-2">
          <Col md={6} sm={12}>
            <Select
              label="Gatilho ocorrência *"
              tooltip="Resposta que dispara ocorrência"
              options={occurrencesTriggerOptions}
              getOptionLabel={option => option.description}
              getOptionValue={option => option.id}
              multiple={canTriggersBeMultiple}
              onChange={value => {
                if (canTriggersBeMultiple) {
                  if (value?.length > 0) {
                    const updated = question?.answers?.map(item => ({
                      ...item,
                      triggerOccurrence: value?.some(
                        option => option.id === item.id
                      ),
                    }));
                    setQuestion(old => ({ ...old, answers: updated }));
                  } else {
                    setQuestion(old => ({
                      ...old,
                      answers: old?.answers?.map(item => {
                        return { ...item, triggerOccurrence: false };
                      }),
                    }));
                  }
                } else if (value) {
                  const updated = question?.answers?.map(item => ({
                    ...item,
                    triggerOccurrence: value?.id === item?.id,
                  }));
                  setQuestion(old => ({ ...old, answers: updated }));
                } else {
                  setQuestion(old => ({
                    ...old,
                    answers: old?.answers?.map(item => {
                      return { ...item, triggerOccurrence: false };
                    }),
                  }));
                }
              }}
              value={question?.answers?.filter(item => item?.triggerOccurrence)}
              noOptionsMessageText={
                question?.answers?.length === 0 &&
                'Insira respostas para gerar opções'
              }
              error={errors?.['question.occurrenceTrigger']}
            />
          </Col>
          <Col md={6} sm={12}>
            <Select.Async
              label="Setor responsável *"
              onSearch={search => handleOnSearchGroup(search)}
              tooltip="Para qual setor a ocorrência será encaminhada"
              value={question?.occurrenceSector}
              onChange={value =>
                setQuestion(old => ({ ...old, occurrenceSector: value }))
              }
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              options={groupOptions}
              error={errors?.['question.occurrenceSector']}
            />
          </Col>
        </Row>
      )}
      {canAddChildQuestion && (
        <>
          <Row className="my-2">
            <Col md={6} sm={12}>
              <div>
                <Text as="span" color="dark" type="medium" weight={500}>
                  Incluir subpergunta?
                </Text>
                <CheckBox
                  label="Sim"
                  labelStyle={{
                    textTransform: 'capitalize',
                    color: '#464E5F',
                  }}
                  checked={question?.hasSubQuestion}
                  value={question?.hasSubQuestion}
                  onChange={() =>
                    setQuestion(old => ({
                      ...old,
                      hasSubQuestion: !old?.hasSubQuestion,
                    }))
                  }
                />
              </div>
            </Col>
            {question?.hasSubQuestion && (
              <Col md={6} sm={12}>
                <Select
                  label="Gatilho para subpergunta"
                  tooltip="Respostas vinculadas à subpergunta"
                  options={subQuestionTriggerOptions}
                  getOptionLabel={option => option.description}
                  getOptionValue={option => option.id}
                  multiple={canTriggersBeMultiple}
                  onChange={value => {
                    if (canTriggersBeMultiple) {
                      if (value?.length > 0) {
                        const updated = question?.answers?.map(item => ({
                          ...item,
                          triggerSubQuestion: value?.some(
                            option => option.id === item.id
                          ),
                        }));
                        setQuestion(old => ({
                          ...old,
                          answers: updated,
                        }));
                      } else {
                        setQuestion(old => ({
                          ...old,
                          answers: old?.answers?.map(item => {
                            return { ...item, triggerSubQuestion: false };
                          }),
                        }));
                      }
                    } else if (value) {
                      const updated = question?.answers?.map(item => ({
                        ...item,
                        triggerSubQuestion: value?.id === item?.id,
                      }));
                      setQuestion(old => ({
                        ...old,
                        answers: updated,
                      }));
                    } else {
                      setQuestion(old => ({
                        ...old,
                        answers: old?.answers?.map(item => {
                          return { ...item, triggerSubQuestion: false };
                        }),
                      }));
                    }
                  }}
                  value={question?.answers?.filter(
                    item => item?.triggerSubQuestion
                  )}
                  noOptionsMessageText={
                    question?.answers?.length === 0 &&
                    'Insira respostas para gerar opções'
                  }
                  error={errors?.['question.subQuestionTrigger']}
                />
              </Col>
            )}
          </Row>
          {question?.hasSubQuestion &&
            question?.answers?.filter(item => item?.triggerSubQuestion)
              ?.length > 0 && (
              <>
                <Row>
                  <Col md={12}>
                    <Text type="regular" weight={500} color="dark">
                      Dados subpergunta
                    </Text>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={12}>
                    <Select.Async
                      label="Tipo de pergunta *"
                      options={questionTypes}
                      onChange={value => {
                        handleChildQuestionTypeChange(value);
                      }}
                      getOptionLabel={option => (
                        <span>
                          {option?.emoji} {option?.name}
                        </span>
                      )}
                      getOptionValue={option => option.id}
                      value={childQuestionType || questionTypes[0]}
                      disableClear
                      error={errors.childQuestionType}
                    />
                  </Col>
                  <QuestionGeneralInfoContainer>
                    {canChildGenerateOccurrence && (
                      <div>
                        <Text as="span" color="dark" type="medium" weight={500}>
                          Gera ocorrência
                        </Text>
                        <CheckBox
                          label="Sim"
                          labelStyle={{
                            textTransform: 'capitalize',
                            color: '#464E5F',
                          }}
                          checked={childQuestion?.createOccurrence}
                          value={childQuestion?.createOccurrence}
                          onChange={() =>
                            setChildQuestion(old => ({
                              ...old,
                              createOccurrence: !old?.createOccurrence,
                            }))
                          }
                          disabled={isChildOccurrenceBlocked}
                        />
                      </div>
                    )}
                  </QuestionGeneralInfoContainer>
                </Row>
                <Row className="my-2">
                  <Col md={10} sm={12}>
                    <TextArea
                      label="Informe a pergunta"
                      value={childQuestion?.questionDescription}
                      onChange={e => {
                        const content = e.target.value;
                        setChildQuestion(old => ({
                          ...old,
                          questionDescription: content,
                        }));
                      }}
                      error={errors?.['childQuestion.questionDescription']}
                    />
                  </Col>
                  <AddAnswerButtonContainer md={2} sm={12}>
                    <AddAnswerButton
                      onClick={() => handleAddAnswer(setChildQuestion)}
                      disabled={!canAddNewAnswerChildOption}
                      title={
                        !canAddNewAnswerChildOption &&
                        addNewChildAnswerBlockedMessage
                      }
                    >
                      <FaPlus />
                      <Text type="body" color="dark" weight="500">
                        Inserir resposta
                      </Text>
                    </AddAnswerButton>
                  </AddAnswerButtonContainer>
                </Row>
                <DndContext
                  collisionDetection={closestCenter}
                  onDragEnd={event => handleChildOnDragEnd(event)}
                >
                  <SortableContext
                    items={childQuestion?.answers}
                    strategy={verticalListSortingStrategy}
                  >
                    {childQuestion?.answers?.map((item, index) => {
                      return (
                        <SortableAnswer
                          key={item?.id}
                          answer={item}
                          index={index}
                          question={childQuestion}
                          setQuestion={setChildQuestion}
                          handleRemoveAnswer={handleRemoveChildAnswer}
                          questionType={childQuestionType}
                        >
                          {JSON.stringify(item)}
                        </SortableAnswer>
                      );
                    })}
                  </SortableContext>
                </DndContext>
                {childQuestion?.createOccurrence && (
                  <Row className="my-2">
                    <Col md={6} sm={12}>
                      <Select
                        label="Gatilho ocorrência"
                        tooltip="Resposta que dispara ocorrência"
                        options={subQuestionOccurrencesTriggerOptions}
                        getOptionLabel={option => option.description}
                        getOptionValue={option => option.id}
                        multiple={canChildTriggersBeMultiple}
                        onChange={value => {
                          if (canChildTriggersBeMultiple) {
                            if (value?.length > 0) {
                              const updated = childQuestion?.answers?.map(
                                item => ({
                                  ...item,
                                  triggerOccurrence: value?.some(
                                    option => option.id === item.id
                                  ),
                                })
                              );
                              setChildQuestion(old => ({
                                ...old,
                                answers: updated,
                              }));
                            } else {
                              setChildQuestion(old => ({
                                ...old,
                                answers: old?.answers?.map(item => {
                                  return {
                                    ...item,
                                    triggerOccurrence: false,
                                  };
                                }),
                              }));
                            }
                          } else if (value) {
                            const updated = childQuestion?.answers?.map(
                              item => ({
                                ...item,
                                triggerOccurrence: value?.id === item?.id,
                              })
                            );
                            setChildQuestion(old => ({
                              ...old,
                              answers: updated,
                            }));
                          } else {
                            setChildQuestion(old => ({
                              ...old,
                              answers: old?.answers?.map(item => {
                                return {
                                  ...item,
                                  triggerOccurrence: false,
                                };
                              }),
                            }));
                          }
                        }}
                        value={childQuestion?.answers?.filter(
                          item => item?.triggerOccurrence
                        )}
                        noOptionsMessageText={
                          childQuestion?.answers?.length === 0 &&
                          'Insira respostas para gerar opções'
                        }
                        error={errors?.['childQuestion.occurrenceTrigger']}
                      />
                    </Col>
                    <Col md={6} sm={12}>
                      <Select.Async
                        label="Setor responsável"
                        onSearch={search => handleOnSearchGroup(search)}
                        tooltip="Para qual setor a ocorrência será encaminhada"
                        value={childQuestion?.occurrenceSector}
                        onChange={value =>
                          setChildQuestion(old => ({
                            ...old,
                            occurrenceSector: value,
                          }))
                        }
                        getOptionLabel={option => option?.name}
                        getOptionValue={option => option?.id}
                        error={errors?.['childQuestion.occurrenceSector']}
                        options={groupOptions}
                      />
                    </Col>
                  </Row>
                )}
              </>
            )}
        </>
      )}
    </>
  );
}
