import moment from 'moment';

export function validateIntervalOfDate(startDate, endDate) {
  if (!moment(startDate).isValid() || !moment(endDate).isValid()) {
    return {
      valid: false,
      message: 'Preencha os campos de data',
    };
  }

  if (moment(startDate, 'DD/MM/YYYY').isAfter(endDate, 'DD/MM/YYYY')) {
    return {
      valid: false,
      message: 'Data inicial não pode ser maior que data final',
    };
  }

  if (moment(endDate).diff(startDate, 'days') > 60) {
    return {
      valid: false,
      message: 'Período máximo de 60 dias',
    };
  }

  return {
    valid: true,
  };
}
