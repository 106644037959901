/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import {
  CNPJ_MASK,
  CPF_MASK,
} from 'utils/masks';

import Card from 'v3/components/Card';
import Text from 'v3/components/Text';
import Input from 'v3/components/Input';
import Button from 'v3/components/Button';
import TextArea from 'v3/components/TextArea';
import Radio from 'v3/components/Radio';
import { useSnackbar } from 'v3/components/Snackbar';
import ApplicationLayout from 'v3/layouts/Application';

import {Row, Col} from 'react-bootstrap/';

import api from 'services/api';

import { validator, cnpjValidator } from '../../utils';

function BlacklistRegister() {
  const snackbar = useSnackbar();
  const history = useHistory();
  const [isForeign, setIsForeign] = useState(false);
  const [isCnpj, setIsCnpj] = useState(false);

  const [cpfCnpj, setCpfCnpj] = useState('');
  const [description, setDescription] = useState('');
  const [reason, setReason] = useState('');
  const [loading, setLoading] = useState(false);
  const [maskFunction, setMaskFunction] = useState(() => false);


  useEffect(() => {
    if (isCnpj) {
      setMaskFunction(() => CNPJ_MASK);
    } else if(!isCnpj && !isForeign) {
      setMaskFunction(() => CPF_MASK);
    }
  }, [isCnpj]);

  useEffect(() => {
    if (isForeign) {
      setIsCnpj(false);
      setCpfCnpj('');
      setMaskFunction(() => false);
    } else {
      isCnpj
        ? setMaskFunction(() => CNPJ_MASK)
        : setMaskFunction(() => CPF_MASK);
    }
  }, [isForeign]);

  async function handleRegister() {
    try {
      setLoading(true);
      isCnpj ? await cnpjValidator.validate({cpfCnpj}, {abortEarly:false}) :
      await validator.validate(
        { cpfCnpj },
        {
          abortEarly: false,
        }
      );
      const data = {
        value: cpfCnpj,
        description,
        reason,
      };
      await api.post('v3/blacklist-person', data);
      snackbar.show(<Text>Item cadastro na blacklist com sucesso!</Text>)
    } catch (ex) {
      const message = ex.response?.data?.message
        ? ex.response?.data?.message
        : ex.inner
        ? 'Verifique os campos obrigatórios'
        : 'Ocorreu algum erro durante o cadastro';
      snackbar.show(<Text>{message}</Text>, {
        type: 'error',
      });
      return;
    } finally {
      setCpfCnpj('');
      setDescription('');
      setReason('');
      setLoading(false);
      history.push('/blacklist');
    }
  }

  return (
    <ApplicationLayout
      title="Blacklist"
      RightComponent={
        <>
          <Link to="/blacklist">
            <Button variant="secondary">
              <Text weight={500} color="dark" type="regular">
                Cancelar
              </Text>
            </Button>
          </Link>
          <Button
            variant="success"
            loading={loading}
            onClick={() => handleRegister()}
            style={{ marginLeft: '10px' }}
          >
            <Text weight={500} color="white" type="regular">
              Salvar
            </Text>
          </Button>
        </>
      }
    >
      <Row>
        <Col lg={12} md={12} xs={12}>
          <Card
            header={
              <Text color="#464E5F" type="header">
                Cadastro de blacklist
              </Text>
            }
          >
            <Row>
            <Col xs={3} className="mb-3">
                  <Radio.Group
                    label="Estrangeiro"
                    onChange={({ target }) => setIsForeign(target.value)}
                    value={isForeign}
                    horizontal
                  >
                    <Radio value>
                      <Text color="dark" type="label">
                        Sim
                      </Text>
                    </Radio>
                    <Radio value={false}>
                      <Text color="dark" type="label">
                        Não
                      </Text>
                    </Radio>
                  </Radio.Group>
                </Col>
                <Col xs={3}>
                <Radio.Group
                 disabled={isForeign}
                  label="CNPJ"
                  onChange={({ target }) => setIsCnpj(target.value)}
                  value={isCnpj}
                  horizontal
                >
                  <Radio value>
                    <Text color="dark" type="label">
                      Sim
                    </Text>
                  </Radio>
                  <Radio value={false}>
                    <Text color="dark" type="label">
                      Não
                    </Text>
                  </Radio>
                </Radio.Group>
              </Col>
              <Col xs={6}>
                <Input
                   label={isForeign ? 'Documento *' : 'CPF/CNPJ *'}
                   value={cpfCnpj}
                   onChange={event => {
                       setCpfCnpj(event.target.value)
                     }
                   }
                   masked
                   mask={maskFunction}
                   error={!cpfCnpj ? 'campo obrigatório' : ''}
                />
              </Col>
              <Col xs={12}>
                <Input
                  label="Descrição"
                  value={description}
                  onChange={event => setDescription(event.target.value)}
                />
              </Col>
              <Col xs={12} className="mt-3">
                <TextArea
                  label="Motivo"
                  resizable={false}
                  value={reason}
                  onChange={event => setReason(event.target.value)}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </ApplicationLayout>
  );
}

export default BlacklistRegister;
