import React, { useState, useEffect } from 'react';

import { Col, Row } from 'react-bootstrap';

import api from 'services/api';

import ModalRaw from 'v3/components/Modal';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import Input from 'v3/components/Input';
import { useSnackbar } from 'v3/components/Snackbar';
import Indicators from '../IndicatorsBody';
import { PrePreparedCard } from '../styles';
import { options } from '../../utils';

const STEPS = {
  TYPE: 1,
  CUSTOMIZE: 2,
  TITLE: 3,
};

function Modal({ isOpen, onClose, fetchDashboards, dashboardCount }) {
  const snackbar = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [focused, setFocused] = useState();
  const [step, setStep] = useState(STEPS.TYPE);
  const [dashboard, setDashboard] = useState({
    option: '',
    title: '',
  });
  const [personalizedDashboardOptions, setPersonalizedDashboardOptions] =
    useState(
      options
        .flatMap(component => component.parameters.items)
        ?.map(item => ({ ...item, value: 0 }))
        .reduce((acc, current) => {
          const x = acc.find(item => item.component === current.component);
          if (!x) {
            return acc.concat([current]);
          }
          return acc;
        }, [])
    );

  useEffect(() => {
    if (dashboardCount)
      setDashboard(old => ({ ...old, title: `Dashboard #${dashboardCount}` }));
  }, [dashboardCount]);

  function renderSection() {
    return (
      <>
        <Col xs={12} className="m-2">
          <Text color="dark" type="regular">
            Dashboards pré-prontos
          </Text>
        </Col>

        <Row className="p-4 row-eq-height h-100">
          {options.map(option => (
            <Col sm={12} md={6} lg={3} className="mb-3 h-50">
              <PrePreparedCard
                focused={focused === option.title}
                onClick={() => {
                  setFocused(option.title);
                  setDashboard(old => ({
                    ...old,
                    option,
                  }));
                }}
              >
                <div className="p-3">
                  <div className="mb-1">
                    <Text weight="600" color="#494747">
                      {option.title}
                    </Text>
                  </div>
                  <Text color="#9C9C9C" type="label">
                    {option.groups}
                  </Text>
                </div>
              </PrePreparedCard>
            </Col>
          ))}
        </Row>
      </>
    );
  }

  function renderNameInput() {
    return (
      <>
        <Row className="mx-4 mt-1">
          <Input
            placeholder="Digite um nome para essa dashboard"
            value={dashboard.title}
            onChange={({ target }) =>
              setDashboard(old => ({ ...old, title: target.value }))
            }
            autoFocus
            label={
              <Text color="#1c4b75" type="regular" weight="600">
                Título:
              </Text>
            }
          />
        </Row>
      </>
    );
  }
  async function handleSave() {
    try {
      setLoading(true);
      await api.post('v3/dashboard', { dashboard });

      snackbar.show(<Text>Nova dashboard criada com sucesso!</Text>, {
        type: 'success',
      });
    } catch (ex) {
      snackbar.show(
        <Text>
          Não foi possível criar a dashboard, tente novamente mais tarde.
        </Text>,
        { type: 'error' }
      );
    } finally {
      setPersonalizedDashboardOptions(
        options
          .flatMap(component => component.parameters.items)
          ?.map(item => ({ ...item, value: 0 }))
          .reduce((acc, current) => {
            const x = acc.find(item => item.component === current.component);
            if (!x) {
              return acc.concat([current]);
            }
            return acc;
          }, [])
      );
      fetchDashboards();
      setStep(1);
      setLoading(false);
      setFocused();
      setDashboard({
        title: '',
      });
      onClose();
    }
  }

  function handleAdjustDashboard(persoDash) {
    const itemsDashboardArray = [];

    persoDash.forEach(component => {
      for (let index = 0; index < component.value; index++) {
        itemsDashboardArray.push({
          component: component.component,
          title: component.title,
        });
      }
    });

    return itemsDashboardArray;
  }

  return (
    <>
      <ModalRaw
        size="xl"
        scrollable
        show={isOpen}
        onHide={() => {
          onClose();
          setDashboard({});
          setStep(1);
        }}
        heading={
          step !== 2 ? (
            <Text type="Title" color="dark" weight={500}>
              Personalizar Dashboard
            </Text>
          ) : (
            <Text type="Title" color="dark" weight={500}>
              Criar Dashboard Personalizada
            </Text>
          )
        }
        body={
          step === 1 ? (
            renderSection()
          ) : step === 2 ? (
            <Indicators
              personalizedDashboardOptions={personalizedDashboardOptions}
              setPersonalizedDashboardOptions={setPersonalizedDashboardOptions}
            />
          ) : (
            renderNameInput()
          )
        }
        footer={
          step === 1 ? (
            <Button
              className="py-2"
              onClick={() =>
                dashboard.option.title === 'Personalizar'
                  ? setStep(2)
                  : setStep(3)
              }
              disabled={!dashboard.option}
            >
              <Text weight="500" type="regular">
                Continuar
              </Text>
            </Button>
          ) : step === 2 ? (
            <>
              <Button
                className="py-2"
                onClick={() => setStep(step - 1)}
                variant="secondary"
              >
                <Text weight="500" type="regular">
                  Voltar
                </Text>
              </Button>
              <Button
                disabled={
                  !personalizedDashboardOptions.find(item => item.value > 0)
                }
                className="py-2"
                onClick={() => {
                  setStep(step + 1);
                  setDashboard({
                    ...dashboard,
                    option: {
                      ...dashboard.option,
                      parameters: {
                        items: handleAdjustDashboard(
                          personalizedDashboardOptions
                        ),
                      },
                    },
                  });
                }}
              >
                <Text weight="500" type="regular">
                  Continuar
                </Text>
              </Button>
            </>
          ) : (
            <>
              <Button
                className="py-2"
                onClick={() =>
                  dashboard.option.title === 'Personalizar'
                    ? setStep(step - 1)
                    : setStep(1)
                }
                variant="secondary"
              >
                <Text weight="500" type="regular">
                  Voltar
                </Text>
              </Button>
              <Button
                className="py-2"
                onClick={handleSave}
                disabled={!dashboard.title?.trim()}
                loading={loading}
                variant="success"
              >
                <Text weight="500" type="regular">
                  Salvar
                </Text>
              </Button>
            </>
          )
        }
      />
    </>
  );
}

export default Modal;
