/* eslint-disable import/no-unresolved */
import React from 'react';

import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaExternalLinkAlt } from 'react-icons/fa';

import Text from 'v3/components/Text';
import Card, { DefaultLoadingBodyComponent } from 'v3/components/Card';

import { useTravel } from 'v3/pages/Travel';

function Negotiator() {
  const travel = useTravel();
  if (travel.isLoading) {
    return null;
  }

  return (
    <Card
      loading={travel.isLoading}
      LoadingBodyComponent={() => (
        <DefaultLoadingBodyComponent linesCount={3} perLine={2} />
      )}
      header={
        <Row>
          <Col xs={12} className="mb-2">
            <Text color="dark" type="header">
              Negociação
            </Text>
          </Col>
        </Row>
      }
    >
      <Row>
        <Col md={12} className="mb-3">
          <Text type="label" color="dark">
            Negociador
          </Text>
          <div className="d-flex flex-column">
            {travel.data.loads?.dueDate && travel?.data?.loads?.negotiator ? (
              <Link
                target="_blank"
                to={`/usuarios/${travel.data.loads?.negotiator?.id}`}
              >
                <Text type="regular" color="gray">
                  {travel.data.loads?.negotiator?.username}
                </Text>
                <FaExternalLinkAlt
                  color="#B5B5C3"
                  size={12}
                  className="ml-2 mb-1"
                />
              </Link>
            ) : (
              <Text type="regular" color="gray" className="mt-2">
                {travel.data?.loads?.negotiator
                  ? `${travel.data.loads?.negotiator?.username}`
                  : 'Não informado'}
              </Text>
            )}
          </div>
        </Col>

        <Col md={12}>
          <Text type="label" color="dark">
            Observações
          </Text>

          <Text type="regular" color="gray" as="p" className="mt-2">
            {travel.data?.negotiatorComments
              ? travel.data.negotiatorComments
              : 'Não informado'}
          </Text>
        </Col>
      </Row>
    </Card>
  );
}

export default Negotiator;
