import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { validatePermission } from 'actions/index';

import InfiniteScroll from 'v3/components/InfiniteScroll';
import ApplicationLayout from 'v3/layouts/Application';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import BennerButton from 'v3/components/BennerButton';
import VehicleFilter from './Filter';

import CardVehicle from './Card';
import * as Controller from './controller';
import { usePermission } from 'hooks';

export default function VehicleList() {
  const history = useHistory();

  const [finalFilter, setFinalFilter] = useState('');
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
  const [qtdAdvancedFilters, setQtdAdvancedFilters] = useState(0);

  const [loading, setLoading] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [pagination, setPagination] = useState({
    lastPage: 1,
    page: 1,
    total: 0,
  });

  const hasPermissionToCreate = validatePermission('CADASTRAR_VEICULOS');
  const hasPermissionToImportFromBenner = validatePermission(
    'IMPORTAR_VEICULO_BENNER'
  );

  usePermission('VISUALIZAR_VEICULOS', { redirect: true });

  function fetchVehicles(filters = {}, page = 0) {
    Controller.getVehicles(filters, page || pagination.page, {
      setVehicles,
      setLoading,
      setPagination,
    });
  }

  const handleClose = () => setShowAdvancedFilters(false);
  const handleShow = () => setShowAdvancedFilters(true);

  function handleTitleAdvancedFilters() {
    let title = 'Filtro avançado';
    if (qtdAdvancedFilters) {
      title += ` (${qtdAdvancedFilters})`;
    }
    return title;
  }

  // Ao atualizar o filtro e "filtrar"
  useEffect(() => {
    if (finalFilter) {
      fetchVehicles(finalFilter, 1);
    }
  }, [finalFilter]);

  function renderItem(items) {
    return <CardVehicle vehicle={items} />;
  }

  return (
    <ApplicationLayout
      title="Veículos"
      RightComponent={
        <>
          <Button variant="secondary" onClick={() => handleShow()}>
            <Text weight={500} color="black" type="regular">
              {handleTitleAdvancedFilters()}
            </Text>
          </Button>
          {hasPermissionToImportFromBenner ? (
            <BennerButton
              endpoint={'vehicles/importFromBenner'}
              title={'Importar Veículo'}
              label={'Placa'}
              field={'plate'}
              redirectTo={'/veiculos'}
              itemToImport={'veículo'}
              items={[
                'Tipos de veículo - Truck / Cavalo Mecânico',
                'Ensaio Cronotacógrafo',
                'Beneficiário',
                'Proprietário',
                'Modelo',
                'Tipo',
                'Marca',
                'Veículo ativo',
              ]}
            />
          ) : null}

          {hasPermissionToCreate && (
            <>
              <Button
                variant="primary"
                onClick={() => {
                  history.push('/veiculos/cadastrar');
                }}
                style={{ marginLeft: '10px' }}
              >
                <Text weight={500} color="white" type="regular">
                  Novo veículo
                </Text>
              </Button>
            </>
          )}
        </>
      }
    >
      <VehicleFilter
        setFinalFilter={setFinalFilter}
        showAdvancedFilters={showAdvancedFilters}
        handleClose={handleClose}
        handleShow={handleShow}
        setQtdAdvancedFilters={setQtdAdvancedFilters}
        resultsTotal={pagination.total}
        isFetching={loading && vehicles.length === 0}
      />
      <InfiniteScroll
        loading={loading}
        data={vehicles}
        renderItem={renderItem}
        onEndReach={() => {
          Controller.getVehicles(finalFilter, pagination.page + 1, {
            setVehicles,
            setLoading,
            setPagination,
          });
        }}
        hasMore={pagination.page < pagination.lastPage}
        scrollThreshold={0.7}
      />
    </ApplicationLayout>
  );
}
