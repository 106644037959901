import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Col, Row } from 'react-bootstrap';

import api from 'services/api';

import Modal from 'v3/components/Modal';
import Select from 'v3/components/Select';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import { useSnackbar } from 'v3/components/Snackbar';

import { useShippingCompany } from '../../context';

function AttachedVehicles({ isOpen, onClose, fetchShippingCompanyVehicles }) {
  const shippingCompany = useShippingCompany();
  const params = useParams();
  const snackbar = useSnackbar();

  const [errors, setErrors] = useState('');
  const [vehicle, setVehicle] = useState(null);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    shippingCompany.setData({ vehicles: [] });
  }, []);

  async function fetchVehicles(search) {
    try {
      const response = await api.get('vehicle/license_plate', {
        params: { search },
      });
      return response.data;
    } catch (error) {
      return [];
    }
  }

  async function attachVehicle(event) {
    event.preventDefault();

    if (
      shippingCompany.data?.vehicles?.map(item => item?.id).includes(vehicle.id)
    ) {
      setErrors('Esse veículo já foi selecionado');
      return;
    }

    try {
      setLoading(true)
      if (params.id) {
        await api.post(`v3/shipping-company/${params.id}/vehicle`, {
          vehicle,
        });

        snackbar.show(<Text>Veículo vinculado com sucesso!</Text>, {
          type: 'success',
        });
      }
      shippingCompany.setData({
        vehicles: [...shippingCompany.data.vehicles, vehicle],
      });
    } catch (error) {
      snackbar.show(<Text>Algo deu errado, tente novamente mais tarde!</Text>, {
        type: 'error',
      });

      //
    } finally {
      setLoading(false)
      setVehicle('');
      onClose(true);
      setErrors();
    }
  }

  useEffect(() => {
    if (!vehicle) {
      setErrors('');
    }
  }, [vehicle]);

  return (
    <>
      <Modal
        show={isOpen}
        onHide={onClose}
        size='lg'
        heading={
          <Text color="dark" type="header">
            Vincular veículo
          </Text>
        }
        body={
          <form onSubmit={attachVehicle}>
            <Row>
              <Col>
                <Select.Async
                  label="Veículo"
                  placeholder="Selecione o veículo..."
                  onSearch={fetchVehicles}
                  value={vehicle}
                  onChange={e => setVehicle(e)}
                  getOptionLabel={option => option.plate}
                  getOptionValue={option => option.id}
                  error={errors}
                />
              </Col>
              <Col
                xs={12}
                className="align-items-center d-flex justify-content-center my-4"
              >
                <Button type="submit" disabled={!vehicle} className="py-2" loading={loading}>
                  <Text type="regular" weight="500" color="white">
                    Adicionar
                  </Text>
                </Button>
              </Col>
            </Row>
          </form>
        }
      />
    </>
  );
}

export default AttachedVehicles;
