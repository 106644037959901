const Yup = require('yup');

export const itemSchema = Yup.object().shape({
  year: Yup.object()
    .shape({
      label: Yup.string().required('Ano obrigatório'),
      value: Yup.number(),
    })
    .typeError('Ano obrigatório'),

  chargePrice: Yup.string()
    .required('Valor de Carga e Descarga obrigatório')
    .typeError('Valor de Carga e Descarga obrigatório'),
  qtyAxys: Yup.string()
    .required('Quantidade de eixos obrigatório')
    .typeError('Quantidade de eixos obrigatório'),
  value: Yup.string()
    .required('Valor de Deslocamento obrigatório')
    .typeError('Valor de Deslocamento obrigatório'),
  loadType: Yup.object()
    .shape({
      id: Yup.number(),
      nome: Yup.string().required('Tipo de carga obrigatória!'),
      created_at: Yup.date(),
      updated_at: Yup.date(),
    })
    .typeError('Tipo de carga obrigatória'),
});

export async function validate(data, setErrors, context) {
  const errorList = {};
  try {
    await itemSchema.validate(data, {
      abortEarly: false,
      context,
    });
    setErrors(errorList);
    return true;
  } catch (err) {
    err.inner.forEach(error => {
      errorList[error.path] = error?.message;
    });
    setErrors(errorList);
    return false;
  }
}
