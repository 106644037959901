/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';

import Text from 'v3/components/Text';
import Input from 'v3/components/Input';
import Button from 'v3/components/Button';
import { Row, Col } from 'react-bootstrap';

const mask = [
  /[0-9]/,
  /[0-9]/,
  '.',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  '.',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  '/',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  '-',
  /[0-9]/,
  /[0-9]/,
];

function Filter({ fetch }) {
  const [name, setName] = useState('');
  const [documentNumber, setDocumentNumber] = useState('');
  return (
    <form
      onSubmit={event => {
        event.preventDefault();
        fetch(name, documentNumber);
      }}
    >
      <Row className="filter">
        <Col lg={4} xs={12}>
          <Input
            label="Nome"
            labelColor="#fff"
            value={name}
            onChange={event => setName(event.target.value)}
          />
        </Col>
        <Col lg={2} xs={12}>
          <Input
            label="CNPJ"
            labelColor="#fff"
            value={documentNumber}
            onChange={event => setDocumentNumber(event.target.value)}
            masked
            guide={false}
            mask={mask}
          />
        </Col>
        <Col lg={2} xs={12} className="vertical bottom">
          <Button type="submit" variant="success">
            <Text type="regular">Filtrar</Text>
          </Button>
        </Col>
      </Row>
    </form>
  );
}

export default Filter;
