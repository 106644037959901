/* eslint-disable import/no-unresolved */
import styled from 'styled-components';

import theme from 'v3/theme';

export const Bullet = styled.div`
  margin-right: 8px;
  border-radius: 50%;
  border: 2px solid ${theme.colors.medium_gray};
  background-color: ${props =>
    props.filled ? theme.colors.medium_gray : 'transparent'};
  width: 12px;
  height: 12px;
`;

export const Dashed = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 2px dashed ${theme.colors.medium_gray};
  margin-left: 5px;
  padding-left: 14px;
  padding-top: 5px;
  margin-top: -3px;
  padding-bottom: 20px;
`;

export const IconsContainer = styled.div`
  > * {
    margin-right: 5px;
  }
`;
