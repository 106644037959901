import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import { cooperplaceApi } from 'services/api';
import { Card, Text } from 'v3/components';
import { currencyOptions } from 'v3/utils/formatter';
import { LoadContext } from '../controller';

import { PreFreightValues } from './PreFreightValues';

import { ReceiveClientCard } from './ReceiveClientCard';
import { InputsCard } from './InputsCard';
import { NegotiatedValues } from './NegotiatedValues';
import { ShipperDiscounts } from './ShipperDiscounts';
import { ShipperTransfer } from './ShipperTransfer';

export function FreightValues() {
  const [, useLoad] = LoadContext;
  const load = useLoad();

  const isBRLCurrency =
    load?.data?.currency?.value === 'BRL'
      ? true
      : load?.data?.currency?.value === 'USD'
      ? false
      : '';
  const dollarToUse = load?.data?.showDailyRate
    ? load?.data?.exchange_day
    : load?.data?.freight_exchange;
  const [freightFieldsOptions, setFreightFieldsOptions] = useState(null);

  useEffect(() => {
    async function fetchFreightFieldsOptions() {
      try {
        const { data } = await cooperplaceApi.get('/load-values-observations');
        setFreightFieldsOptions(data);
      } catch (error) {
        setFreightFieldsOptions([]);
      }
    }
    fetchFreightFieldsOptions();
  }, []);

  function setDefaultValue(key, field) {
    return freightFieldsOptions[key]?.find(item => item.code === field);
  }

  useEffect(() => {
    if (freightFieldsOptions) {
      const defaultToll = setDefaultValue('toll', 'not_selected');
      const defaultDischarge = setDefaultValue(
        'discharge',
        'not_selected_discharge'
      );
      const defaultGrissAdValorem = setDefaultValue(
        'griss_ad_valorem',
        'not_selected_griss'
      );
      const defaultCharge = setDefaultValue('loading_fee', 'not_selected_fee');
      const defaultFerry = setDefaultValue('ferry', 'not_selected_ferry');
      const defaultICMS = setDefaultValue('icms', 'not_selected_icms');

      load.setData(old => ({
        ...old,
        action_toll: defaultToll,
        action_discharge: defaultDischarge,
        action_griss_advalorem: defaultGrissAdValorem,
        action_charge: defaultCharge,
        action_icms: defaultICMS,
        action_ferry: defaultFerry,
      }));
    }
  }, [freightFieldsOptions]);

  useEffect(() => {
    if (load?.rateResponse) {
      load.setData(old => ({
        ...old,
        currency: currencyOptions[0],
        exchange_day: String(load?.rateResponse?.buy).padEnd(6, '0'),
        freight_exchange: String(load?.rateResponse?.buy).padEnd(6, '0'),
      }));
    }
  }, [load?.rateResponse]);

  useEffect(() => {
    if (!load?.data?.cargoValue || load?.data?.cargoValue == '0.00') {
      load.setData(old => ({
        ...old,
        gris: '0',
        gris_percentage: '0',
        ad_valorem: '0',
        ad_valorem_percentage: '0',
        coopercarga_insurance: '0',
        coopercarga_insurance_percentage: '0',
      }));
    } else if (load?.data?.cargoValue) {
      load.setData(old => ({
        ...old,
        gris: (
          (Number(load?.data?.gris_percentage) / 100) *
          load?.data?.cargoValue
        )?.toFixed(2),
        ad_valorem: (
          (Number(load?.data?.ad_valorem_percentage) / 100) *
          load?.data?.cargoValue
        )?.toFixed(2),
        coopercarga_insurance: (
          (Number(load?.data?.coopercarga_insurance_percentage) / 100) *
          load?.data?.taker_value
        )?.toFixed(2),
      }));
    }
  }, [load?.data?.cargoValue]);

  const totalItems = useMemo(() => {
    let total = 0;
    if (load?.extraFields?.length) {
      total = load?.extraFields.reduce((acc, curr) => {
        return acc + Number(curr?.value);
      }, 0);
    }
    if (load?.data?.taker_value) {
      total += Number(load?.data?.taker_value);
    }
    if (load?.data?.charge_value) {
      total += Number(load?.data?.charge_value);
    }
    if (load?.data?.discharge_value) {
      total += Number(load?.data?.discharge_value);
    }
    if (
      load?.data?.toll_value &&
      load?.data?.action_toll?.code !== 'client_provides_card'
    ) {
      total += Number(load?.data?.toll_value);
    }
    if (load?.data?.gris) {
      total += Number(load?.data?.gris);
    }

    if (load?.data?.ad_valorem) {
      total += Number(load?.data?.ad_valorem);
    }

    if (load?.data?.ferry) {
      total += Number(load?.data?.ferry);
    }

    return total;
  }, [
    load?.data?.taker_value,
    load?.data?.toll_value,
    load?.data?.gris,
    load?.data?.ad_valorem,
    load?.data?.charge_value,
    load?.data?.discharge_value,
    load?.data?.ferry,
    load?.extraFields,
    load?.data?.action_toll?.code,
  ]);

  useEffect(() => {
    if (!totalItems || totalItems == '0.00') {
      load.setData(old => ({
        ...old,
        ICMS: '0',
        icms_percentage: '0',
      }));
    } else if (totalItems && load?.data?.icms_percentage) {
      const computedValue = Number(
        totalItems / (1 - load?.data?.icms_percentage / 100) - totalItems
      )?.toFixed(2);
      load.setData(old => ({
        ...old,
        ICMS: computedValue,
      }));
    }
  }, [totalItems]);

  return (
    <Col xs={12}>
      <Card
        header={<Text type="header">Composição de valores de frete</Text>}
        bodyCardPadding="2rem"
        loading={load?.loadingRate}
      >
        <PreFreightValues />
        <Row>
          <ReceiveClientCard
            isBRLCurrency={isBRLCurrency}
            dollarToUse={dollarToUse}
            freightFieldsOptions={freightFieldsOptions}
            totalItems={totalItems}
          />
        </Row>
        <Row>
          <InputsCard isBRLCurrency={isBRLCurrency} dollarToUse={dollarToUse} />
        </Row>
        <Row>
          <NegotiatedValues
            isBRLCurrency={isBRLCurrency}
            dollarToUse={dollarToUse}
          />
        </Row>
        <Row>
          <ShipperDiscounts
            isBRLCurrency={isBRLCurrency}
            dollarToUse={dollarToUse}
          />
        </Row>
        <Row>
          <ShipperTransfer
            isBRLCurrency={isBRLCurrency}
            dollarToUse={dollarToUse}
          />
        </Row>
      </Card>
    </Col>
  );
}
