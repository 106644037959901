export const csvColumns = {
  takerName: 'Nome tomador',
  takerCNPJ: 'CNPJ tomador',
  takerIsInternational: 'Internacional (usar 1 para sim e 0 para nao)',
  negotiatorName: 'Nome do negociador',
  negotiatorEmail: 'Email do negociador',
  product: 'ID produto',
  crawledLoad: 'Monitoramento (usar 1 para sim e 0 para nao)',
  tolledLoad: 'Pedagio (usar 1 para sim e 0 para nao)',
  vehicleTypes: 'Veiculo',
  bodyTypes: 'Tipo de carroceria',
  loadDisposition: 'Disposição da carga',
  palletNumber: 'Pallets (unidades)',
  loadWeight: 'Peso bruto da carga (Kg)',
  loadLiquidWeight: 'Peso líquido da carga (Kg)',
  loadVolume: 'Volume da carga (m3)',
  loadValue: 'Valor da carga',
  fareCompany: 'Valor do frete',
  dueDate: 'Data de vencimento da carga',
  dueTime: 'Hora de vencimento da carga',
  contactInfo: 'Contato (whatsapp)',
  tags: 'Tags',
  comments: 'Observacoes',
  idExterno: 'ID externo',
  originsAddress: 'Logradouro origem',
  originsNeighborhood: 'Bairro origem',
  originsNumber: 'Numero origem',
  originsComplement: 'Complemento origem',
  originsZipCode: 'CEP origem',
  originsCity: 'Cidade origem',
  originsProvince: 'UF origem',
  originsScheduledDate: 'Data máxima da coleta',
  originsScheduledTime: 'Hora máxima da coleta',
  destinationsAddress: 'Logradouro destino',
  destinationsNeighborhood: 'Bairro destino',
  destinationsNumber: 'Numero destino',
  destinationsComplement: 'Complemento destino',
  destinationsZipCode: 'CEP destino',
  destinationsCity: 'Cidade destino',
  destinationsProvince: 'UF destino',
  destinationsScheduledDate: 'Data máxima da entrega',
  destinationsScheduledTime: 'Hora máxima da entrega',
  startOriginScheduledDate: 'Data de início da coleta',
  startOriginScheduledTime: 'Hora de início da coleta',
  startDestinationScheduledDate: 'Data de início da entrega',
  startdestinationScheduledTime: 'Hora de início da entrega',
  riskManagersCleareances: 'Liberações nas gerenciadoras de risco',
  vehicleImplementTypes: 'Tipo de implemento',
  mopp: 'Precisa de MOPP (usar 1 para sim e 0 para nao)',
  typeValue: 'Tipo do valor da carga',
  takerValue: 'Valor do tomador',
  advanceMoneyPercentage: 'Percentual de adiantamento',
  tollValue: 'Valor do pedágio',
  chargeValue: 'Valor da taxa de carregamento',
  chargeIncluded:
    'Taxa de carregamento incluso no valor do frete (usar 1 para sim e 0 para nao)',
  dischargeValue: 'Valor da descarga',
  dischargeIncluded:
    'Valor da descarga incluso no valor do frete (usar 1 para sim e 0 para nao)',
  insuranceValue: 'Valor do seguro',
  insuranceIncluded:
    'Valor do seguro incluso no valor do frete (usar 1 para sim e 0 para nao)',
  grisValue: 'Valor do Gris',
  grisIncluded:
    'Valor do Gris incluso no valor do frete (usar 1 para sim e 0 para nao)',
  adValorem: 'Imposto ad valorem',
  adValoremIncluded:
    'Imposto ad valorem incluso no valor do frete (usar 1 para sim e 0 para nao)',
  costCenterId: 'Centro de Custo',
};
