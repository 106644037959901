import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { useQuery } from 'hooks';
import { sanitize } from 'dompurify';
import { fetchSelectOptions } from 'utils/fetches';
import { Button, Text, Input, Select } from 'v3/components';

export function Filter({ onChange, name, setName, personId, setPersonId }) {
  const query = useQuery();
  const history = useHistory();

  function applyFilters() {
    onChange({
      name,
      person_id: personId?.id,
    });
  }

  useEffect(() => {
    function onPressedKey(e) {
      if (e.key === 'Enter') {
        applyFilters();
      }
    }
    window.addEventListener('keyup', onPressedKey);
    return () => {
      window.removeEventListener('keyup', onPressedKey);
    };
  });

  useEffect(() => {
    if (query.has('person_id')) {
      setPersonId(query.get('person_id'));
    }
    if (query.has('name')) {
      setName(query.get('name'));
    }
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      history.replace({ search: query.toString() });
    }, 150);

    return () => {
      clearTimeout(timeout);
    };
  }, [name, personId]);

  return (
    <Row className="my-4 d-flex align-items-center">
      <Col xs={12} md={5}>
        <Input
          labelColor="#fff"
          label="Nome"
          placeholder="Apólice"
          value={name}
          onChange={event => {
            let clean = sanitize(event?.target?.value);
            if (clean === null || clean === '') {
              query.delete('name');
            }
            query.set('name', clean);

            setName(clean);
          }}
        />
      </Col>

      <Col xs={12} md={5}>
        <Select.Async
          labelColor="#fff"
          label="CNPJ Cliente"
          value={personId}
          placeholder="CNPJ"
          horizontal
          onSearch={search =>
            fetchSelectOptions('persons/customers', {
              search: sanitize(search),
            })
          }
          onChange={value => {
            if (value) {
              query.set('person_id', value?.id);
            } else {
              query.delete('person_id');
            }
            setPersonId(value);
          }}
          getOptionLabel={option => option.label}
          getOptionValue={option => option.id}
        />
      </Col>

      <Col
        xs={12}
        md={2}
        style={{
          display: 'flex',
          justifyContent: 'start',
          alignSelf: 'end',
        }}
      >
        <Button
          variant="success"
          onClick={() => applyFilters()}
          style={{ width: '100%', marginTop: '1rem' }}
        >
          <Text type="regular" weight={500}>
            Filtrar
          </Text>
        </Button>
      </Col>
    </Row>
  );
}
