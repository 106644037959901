import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Button, Card, Text } from 'v3/components';
import theme from 'v3/theme';
import Space from 'v3/components/Space';
import TextArea from 'v3/components/TextArea';
import { cooperplaceApi } from 'services/api';
import { useClientTravelDetail } from './context';
import { useSnackbar } from 'v3/components/Snackbar';
import { ObsSchema } from './validator';

export function ObsCard() {
  const snackbar = useSnackbar();
  const [newObs, setNewObs] = useState('');
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const { data, isFetching, refetchTravel } = useClientTravelDetail();
  const [error, setError] = useState([]);

  async function validateData(data, setError) {
    const errorList = {};
    const dataObject = {
      obs: data,
    };
    try {
      await ObsSchema.validate(dataObject, {
        abortEarly: false,
      });
      setError({ ...errorList });

      return true;
    } catch (err) {
      err.inner.forEach(error => {
        errorList[error.path] = error.message;
      });

      setError({ ...errorList });

      return false;
    }
  }

  useEffect(() => {
    setNewObs(data?.observationClient);
  }, [data, editing]);
  async function submitObs() {
    try {
      setLoading(true);
      const isValid = await validateData(newObs, setError);
      if (!isValid) {
        snackbar.show(
          <Text type="regular">{'Informe uma observação válido'}</Text>,
          {
            type: 'error',
          }
        );
        return;
      }
      const response = await cooperplaceApi.post('travels/clientObs', {
        attendedId: data?.id,
        obs: newObs,
      });
      snackbar.show(<Text type="regular">{response?.data?.message}</Text>, {
        type: response?.data?.success ? 'success' : 'warning',
      });
      await refetchTravel();
      setEditing(false);
    } catch (error) {
      snackbar.show(
        <Text type="regular">{error?.response?.data?.message}</Text>,
        {
          type: 'error',
        }
      );
    } finally {
      setLoading(false);
    }
  }
  const buttonLabel = data?.observationClient ? 'Editar' : 'Adicionar';
  return (
    <Card
      header={
        <Text type="header" color={theme.colors.dark}>
          Observações
        </Text>
      }
      loading={loading || isFetching}
      HeaderRightComponent={
        editing ? (
          <Space x={'0.5rem'}>
            <Button
              variant={'secondary'}
              onClick={() => {
                setEditing(false);
                setError({});
              }}
              disabled={loading || isFetching}
            >
              <Text type="regular">Cancelar</Text>
            </Button>
            <Button
              onClick={submitObs}
              loading={loading}
              disabled={loading || isFetching}
            >
              <Text type="regular">Salvar</Text>
            </Button>
          </Space>
        ) : (
          <Button variant={'secondary'} onClick={() => setEditing(true)}>
            <Text type="regular" weight={500}>
              {buttonLabel}
            </Text>
          </Button>
        )
      }
    >
      <Row>
        <Col xs={12}>
          {editing ? (
            <TextArea
              label="Observação"
              value={newObs}
              onChange={e => setNewObs(e.target.value)}
              error={error?.obs}
            />
          ) : (
            <Text type="regular" color="gray" as="p" className="mt-2">
              {data?.observationClient || 'Não informado'}
            </Text>
          )}
        </Col>
      </Row>
    </Card>
  );
}
