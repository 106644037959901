import React from 'react';
import { Text } from 'v3/components';
import { QuestionOptionItem } from '../../shared/styles';

export function QuestionOptionItemComponent({ icon, content }) {
  const IconComponent = icon;
  return (
    <QuestionOptionItem>
      <IconComponent />
      <Text type="body" color={'dark'}>
        {content}
      </Text>
    </QuestionOptionItem>
  );
}
