import React, { useEffect, useState, createContext } from 'react';
import api, { cooperplaceApi } from 'services/api';

export const UserContext = createContext({
  isUserActive: false,
  isLoading: true,
});
export function UserContextProvider({ children }) {
  const [checkUser, setCheckUser] = useState(false);
  const [loading, setLoading] = useState(true);
  const [shouldUserChangePassword, setShouldUserChangePassword] =
    useState(false);
  const [lastPasswordUpdate, setLastPasswordUpdate] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState({});
  const [enabledToSelect, setEnabledToSelect] = useState(false);
  const [changedCompany, setChangedCompany] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  async function checkUserStatus() {
    try {
      setLoading(true);
      const response = await api.get('v3/user/active');
      setCheckUser(response?.data?.isUserActive);
      localStorage.setItem(
        'permissions',
        JSON.stringify(response.data.user[0]?.permissions)
      );
      const user = response.data.user[0];
      setCurrentUser(user);
      const hasShouldChangePasswordInfo =
        user?.change_password === true || user?.change_password === false
          ? user?.change_password
          : true;
      setShouldUserChangePassword(hasShouldChangePasswordInfo);
      setLastPasswordUpdate(user?.last_update_password);
      delete user.permissions;
      delete user.change_password;
      delete user.last_update_password;

      localStorage.setItem('usuario', JSON.stringify(user));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  async function getCompaniesUser() {
    try {
      const { data } = await cooperplaceApi.get('user/companies');
      setCompanies(data.companies);
      setSelectedCompany(data.currentCompany);
    } catch (error) {
      setCompanies([]);
    }
  }

  useEffect(() => {
    checkUserStatus();
    getCompaniesUser();
  }, [selectedCompany?.id]);

  return (
    <UserContext.Provider
      value={{
        isLoading: loading,
        isUserActive: checkUser,
        shouldUserChangePassword,
        lastPasswordUpdate,
        enabledToSelect,
        setEnabledToSelect,
        companies,
        selectedCompany,
        setSelectedCompany,
        changedCompany,
        setChangedCompany,
        currentUser,
        getCompaniesUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
