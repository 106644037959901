import React, { useEffect, useState } from 'react';
import { sanitize } from 'dompurify';

import { Modal, Text, Input } from 'v3/components';
import Space from 'v3/components/Space';
import { usePolicyRegister } from '../../context';
import { StyledModalButton } from 'v3/pages/Policy/styles';
import { validateOpName } from '../../utils';
import { cooperplaceApi } from 'services/api';
import { useSnackbar } from 'v3/components/Snackbar';

export function OperationsModal() {
  const {
    operationModal,
    setOperations,
    operations,
    setErrors,
    currentOpIndex,
    setCurrentOpIndex,
    policyClients,
  } = usePolicyRegister();

  const [newOperation, setNewOperation] = useState('');
  const [error, setError] = useState({});
  const [validatingOperation, setValidatingOperation] = useState(false);
  const snackbar = useSnackbar();

  async function handleAdd() {
    try {
      setValidatingOperation(true);
      const oldOpNames = operations?.map(op => op?.name?.toLowerCase());
      const isValid = validateOpName(oldOpNames, newOperation);

      const { data } = await cooperplaceApi.post(
        'policies/validate/operation',
        {
          operationsNames: [newOperation],
          customers: policyClients?.map(client => client.id),
          type:'operation'
        }
      );
      if (!isValid || !data?.success) {
        setError({
          name: !data?.success
            ? data?.message
            : 'Nome de operação já está em uso!',
        });
        setValidatingOperation(false);
        return;
      }
      setOperations(old => [...old, { name: newOperation, products: [] }]);
      setNewOperation('');
      setCurrentOpIndex(null);
      setError({});
      setErrors({});
      setValidatingOperation(false);
      operationModal.close();
    } catch (error) {
      snackbar.show(<Text>Erro ao validar operação!</Text>, { type: 'error' });
      setValidatingOperation(false);
    }
  }

  async function handleEdit() {
    try {
      setValidatingOperation(true);
      if (newOperation?.trim() === operations[currentOpIndex]?.name?.trim()) {
        setError({ name: 'Informe um nome diferente!' });
        setValidatingOperation(false);

        return;
      }
      const oldOpNames = operations?.map(op => op?.name?.toLowerCase());
      const isValid = validateOpName(oldOpNames, newOperation);

      const { data } = await cooperplaceApi.post(
        'policies/validate/operation',
        {
          operationsNames: [newOperation],
          customers: policyClients?.map(client => client.id),
          type: 'operation',
        }
      );
      if (!isValid || !data?.success) {
        setError({
          name:
            !data?.success ? data?.message : 'Nome de operação já está em uso!',
        });
        setValidatingOperation(false);
        return;
      }
      let newState = [...operations];
      newState[currentOpIndex].name = newOperation;
      setOperations(newState);
      setNewOperation('');
      setError({});
      setCurrentOpIndex(null);
      setValidatingOperation(false);
      operationModal.close();
    } catch (error) {
      snackbar.show(<Text>Erro ao validar operação!</Text>, { type: 'error' });
      setValidatingOperation(false);
    }
  }

  function handleCloseModal() {
    if(validatingOperation){
      return
    }
    setNewOperation('');
    setError({});
    setCurrentOpIndex(null);
    operationModal.close();
  }
  useEffect(() => {
    if (currentOpIndex !== null && operationModal.isOpen) {
      setNewOperation(operations[currentOpIndex].name);
    }
  }, [currentOpIndex, operationModal]);
  return (
    <Modal
      size="lg"
      show={operationModal.isOpen}
      handleClose={handleCloseModal}
      heading={
        <Text type="body" color="dark" weight="400">
          {currentOpIndex !== null ? 'Editar operação' : 'Nova operação'}
        </Text>
      }
      body={
        <div>
          <Input
            label="Nome da operação *"
            value={newOperation}
            onChange={e => {
              if (e?.target?.value?.length > 255) {
                return;
              }
              setNewOperation(sanitize(e.target.value));
            }}
            error={error?.name}
          />
        </div>
      }
      footer={
        <Space>
          <StyledModalButton
            size="sm"
            onClick={currentOpIndex !== null ? handleEdit : handleAdd}
            disabled={newOperation?.length === 0}
            loading={validatingOperation}
          >
            <Text>{currentOpIndex !== null ? 'Editar' : 'Adicionar'}</Text>
          </StyledModalButton>
        </Space>
      }
    />
  );
}
