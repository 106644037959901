import React, { useState, useCallback, useEffect } from 'react';

import dashboardApi from 'services/apis/dashboard';
import { Table, Text, Link, Button } from 'v3/components';
import { formatDateTime } from 'v3/utils/formatter';
import moment from 'moment';
import { useQuery } from 'hooks';
import { RISK_MANAGER_STATUSES_OBJECT } from 'v3/pages/Travel/shared/RiskManager/controller';
import Card, { Number, Description, SeeAll } from './Card';

export default function TravelsUntrackedPendingRiskManagers({
  dashboard,
  name,
  id,
}) {
  const [isFetching, setFetching] = useState(false);
  const [data, setData] = useState({});
  const [filter, setFilter] = useState({});
  const [currentPage, setCurrentPage] = useState({});
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [daysFromNow, setDaysFromNow] = useState(false);
  const queryParams = useQuery();

  useEffect(() => {
    setDaysFromNow(moment(new Date()).diff(filter.start_date, 'days'));
  }, [filter.start_date]);

  function setQueryParams(filters) {
    const { tags = [], start_date } = filters;

    queryParams.delete('tags');

    if (tags) {
      tags.forEach(tag => {
        queryParams.append('tags', tag.id);
      });
    }

    queryParams.set('start_date', start_date);
    queryParams.set('pending_gr', 1);
    queryParams.set('status', 2);

    if (tags?.length === 0) {
      queryParams.set('no-tags', true);
    } else {
      queryParams.delete('no-tags');
    }
  }
  const fetchTravelsUntrackedPendingRiskManager = useCallback(
    async (filters = {}) => {
      try {
        setLoading(true);
        const {
          tags = [],
          clients = [],
          negotiators = [],
          costCenters = [],
          pcps = [],
          companies = [],
          page = 1,
          start_date,
        } = filters;

        if (page === 1) {
          setFetching(true);
        }

        setQueryParams(filters);
        setFilter(filters);
        setCurrentPage(+page);

        const response = await dashboardApi.get(
          'travels-untracked-pending-risk-managers',
          {
            params: {
              tags: tags?.map(tag => tag.id),
              clients: clients?.map(client => client.id),
              start_date,
              page,
              negotiators: negotiators?.map(negotiator => negotiator?.id),
              costCenters: costCenters?.map(costCenter => costCenter?.id),
              pcps: pcps?.map(pcp => pcp?.id),
              companies: companies?.map(company => company?.id),
            },
          }
        );

        setPagination(response.data?.last?.pagination);

        if (page === 1) {
          setData(response.data);
        } else {
          setData(oldData => ({
            ...oldData,
            last: {
              ...oldData.last,
              data: [...oldData.last.data, ...response.data.last.data],
            },
          }));
        }
      } catch (ex) {
        // Handle exception
      } finally {
        setFetching(false);
        setLoading(false);
      }
    },
    []
  );

  return (
    <Card
      title="Viagens atrasadas pendentes GR"
      isLoading={isFetching}
      fetchFn={fetchTravelsUntrackedPendingRiskManager}
      onFiltersChanged={fetchTravelsUntrackedPendingRiskManager}
      dashboard={dashboard}
      name={name}
      excludeFilterByBranchOffice={true}
      id={id}
      excludeFilterByDate
      ListComponent={
        <>
          <Table striped>
            <thead>
              <tr>
                <th>
                  <Text weight="500" color="dark">
                    Centro de custo
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Empresa
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Tags da carga
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Viagem
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Carregamento
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Negociador
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Responsável GR
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Status GR
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Origem
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Destino
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Cliente
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Motorista
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Tags do motorista
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Veículo
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Tags do veículo
                  </Text>
                </th>
              </tr>
            </thead>
            <tbody>
              {data.last?.data?.map(load => {
                const title = [
                  `Gerenciadora de risco ${load.tracker}`,
                  `Motorista ${load.driver_name} - ${load.driver_document}`,
                  `Placa veículo tração ${load.plate}`,
                  `Cliente ${load.social_name} - ${load.cgccpf}`,
                ].join('\n');

                return (
                  <tr>
                    <td>
                      <Text type="regular" color="gray">
                        {load.cost_center || '-'}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray">
                        {load?.company_name || '-'}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray">
                        {load.load_tags || '-'}
                      </Text>
                    </td>
                    <td>
                      <Link
                        to={`/viagens/${load.attended_id}`}
                        title={title}
                        target="_blank"
                      >
                        {load.attended_id}
                      </Link>
                    </td>
                    <td>
                      <Text type="regular" color="gray">
                        {formatDateTime(load.scheduled_origin)}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray">
                        {load.user_created}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray">
                        {load.risk_manager_user}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray">
                        {
                          RISK_MANAGER_STATUSES_OBJECT[load.risk_manager_status]
                            .label
                        }
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray" truncate>
                        {load.city_origin} - {load.uf_origin}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray" truncate>
                        {load.city_destination} - {load.uf_destination}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray" truncate>
                        {load.social_name}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray" truncate>
                        {load.driver_name}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray" truncate>
                        {load.driver_tags || '-'}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray">
                        {load.plate}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray">
                        {load.vehicle_tags || '-'}
                      </Text>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {pagination?.lastPage > 1 && pagination?.to < pagination?.total && (
            <div className="text-center mt-3 col-12">
              <Button
                className="mt-2 ml-auto align-item-center"
                onClick={() => {
                  fetchTravelsUntrackedPendingRiskManager({
                    page: currentPage + 1,
                    tags: filter?.tags,
                    clients: filter?.clients,
                    start_date: filter?.start_date,
                    costCenters: filter?.costCenters,
                    pcps: filter?.pcps,
                    negotiators: filter?.negotiators,
                    companies: filter?.companies,
                  });
                }}
                loading={loading}
              >
                <Text type="regular" color="white" truncate>
                  Ver mais
                </Text>
              </Button>
            </div>
          )}
          {!!data?.total && (
            <SeeAll
              to={{
                pathname: '/viagens',
                search: queryParams.toString(),
              }}
            >
              ver todas as {data?.total} viagens
            </SeeAll>
          )}
        </>
      }
      NumericComponent={
        <div className="d-flex justify-content-center text-center flex-column">
          <Number color="error">{data?.total}</Number>
          <Description>
            viagens atrasadas sem código de liberação
            {daysFromNow > 1
              ? ` nos últimos ${daysFromNow} dias`
              : ` no último dia`}
          </Description>
        </div>
      }
    />
  );
}
