import styled, { css } from 'styled-components';

import { OutlinedInput as MaterialOutlinedInput } from '@material-ui/core';

export const StyledInput = styled.input`
  background-color: transparent;

  border: 1px solid ${({ theme }) => theme.colors.secondary_light};
  border-radius: ${({ theme }) => theme.borderRadius.input};

  color: ${({ theme }) => theme.colors.secondary};
  font-size: 1rem;

  padding: 10px 5px;

  width: 100%;

  transition: all 0.3s ease;

  &:focus {
    outline: none;
  }

  ${({ error }) =>
    error &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.error};
      background-color: ${({ theme }) => theme.colors.error_light} !important;
    `}
  ${({ success }) =>
    success &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.success};
      background-color: ${({ theme }) => theme.colors.success_light} !important;
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #f2f2f2;
    `}
`;

export const StyledOutlinedInput = styled(MaterialOutlinedInput)`
  background-color: transparent;

  border: 1px solid ${({ theme }) => theme.colors.secondary_light};
  border-radius: ${({ theme }) => theme.borderRadius.input};

  color: ${({ theme }) => theme.colors.secondary};
  font-size: 12px;

  padding: 5px 15px;

  &::placeholder {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;
