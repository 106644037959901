import React, { useState } from 'react';

import InfiniteScroll from 'components/InfiniteScroll';
import Grid from '@material-ui/core/Grid';

import InfoMessage from 'components/InfoMessage';
import CardManufacturers from './Card.js';

import Snackbar from 'components/Snackbar';
import api from '../../../services/apis/cooperplace';

import { usePermission } from 'hooks';

export default function ManufacturersList() {
  usePermission('VISUALIZAR_FABRICANTES_VEICULOS', { redirect: true });
  const [snackBar, setSnackBar] = useState({
    display: false,
    type: 'info',
    message: '',
  });

  return (
    <>
      <Snackbar
        open={snackBar.display}
        type={snackBar.type}
        message={snackBar.message}
        onClose={() =>
          setSnackBar({
            display: false,
            type: '',
            message: '',
          })
        }
      />

      <InfiniteScroll
        endpoint="vehicles/manufacturers"
        initialFetch={true}
        placeholderHeight={145}
        renderItem={item => <CardManufacturers manufacturers={item} />}
        endMessage={
          <Grid
            container
            item
            xs={12}
            justify="center"
            style={{ marginTop: '20px' }}
          >
            <InfoMessage
              icon="doneall"
              width="auto"
              snackColor="#8c8c8c"
              message="Não há mais itens a serem exibidos."
            />
          </Grid>
        }
        service={api}
      />
    </>
  );
}
