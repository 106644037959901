import { BlockedTravelContext } from 'contexts/BlockedTravelProvider';
import { format } from 'date-fns';
import { useModal, usePermission } from 'hooks';
import moment from 'moment';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { cooperplaceApi } from 'services/api';
import { fetchDriver, fetchVehicle, fetchWagons } from 'utils/fetches';

import { Button, Modal, Select, Text } from 'v3/components';
import { DefaultLoadingComponent } from 'v3/components/List';
import { useSnackbar } from 'v3/components/Snackbar';
import Space from 'v3/components/Space';
import { processGroupOptionsData } from 'v3/pages/Travel/Register/GRO/utils';
import { UpdateVehicleData } from 'v3/pages/Travel/Register/Modals/UpdateVehicle';
import { UpdateWagonData } from 'v3/pages/Travel/Register/Modals/UpdateWagon';

import {
  checkVehicleData,
  checkWagonData,
  getUpdatedVehicle,
  getUpdatedWagon,
  importWagonFromBenner,
  wagonDataMapper,
  wagonDataToValidate,
} from './utils';
import { schema } from './validator';

export function GroAttended({
  loading,
  isOpen,
  onClose,
  groAttendedData,
  setGroAttendedData,
  newRange,
  handleCancel,
  setUpdatedTravelData,
  modalHook,
}) {
  const { setItensToFetchMargin, setIsFetchingBenner } =
    useContext(BlockedTravelContext);
  const hasPermissionToEditVehicle = usePermission('ALTERAR_VEICULOS');
  const hasPermissionToEditWagon = usePermission('EDITAR_IMPLEMENTO');
  const updateVehicleData = useModal();
  const updateWagonData = useModal();
  const snackbar = useSnackbar();
  const [errors, setErrors] = useState({});
  const [validatingVehicle, setValidatingVehicle] = useState(false);
  const [validatingWagon, setValidatingWagon] = useState({
    0: false,
    1: false,
    2: false,
    3: false,
  });

  const [validating, setValidating] = useState(false);
  const [vehicle, setVehicle] = useState(null);
  const [wagon, setWagon] = useState({
    index: null,
    wagonData: null,
  });

  const hasntMonitoringGroups =
    groAttendedData?.equipmentsGroupsOptions?.length === 0;
  const monitoringGroupOptionsTooltip = useMemo(() => {
    if (groAttendedData?.equipmentsGroupsOptions?.length > 0) {
      return processGroupOptionsData(groAttendedData?.equipmentsGroupsOptions);
    }
    if (hasntMonitoringGroups)
      return 'Apólice não possui informações de grupo de monitoramento';
    return '';
  }, [groAttendedData, hasntMonitoringGroups]);

  const monitoringGroupFields = useMemo(() => {
    let isTrackerRequired = false;
    let isTrackerModelRequired = false;
    let isImmobilizerRequired = false;
    let isLocatorRequired = false;
    let isBaitRequired = false;
    const baitAmount =
      groAttendedData?.selectedMonitoringGroup?.value?.bait_quantity;

    if (groAttendedData?.selectedMonitoringGroup?.value?.baits?.length > 0) {
      isBaitRequired = true;
    }
    if (
      groAttendedData?.selectedMonitoringGroup?.value?.tecnologies?.length > 0
    ) {
      isTrackerRequired = true;
    }
    if (
      groAttendedData?.selectedMonitoringGroup?.value?.equipments?.length > 0
    ) {
      isTrackerModelRequired = true;
    }
    if (groAttendedData?.selectedMonitoringGroup?.value?.locators?.length > 0) {
      isLocatorRequired = true;
    }
    if (
      groAttendedData?.selectedMonitoringGroup?.value?.immobilizers?.length > 0
    ) {
      isImmobilizerRequired = true;
    }
    return {
      isTrackerRequired,
      isTrackerModelRequired,
      isImmobilizerRequired,
      isLocatorRequired,
      isBaitRequired,
      baitAmount,
    };
  }, [groAttendedData?.selectedMonitoringGroup?.value]);

  const wagonsRequired = useMemo(() => {
    if (groAttendedData?.wagons?.length > 0) {
      return groAttendedData?.wagons?.some(item => !item.status);
    }
    return false;
  }, [groAttendedData?.wagons]);

  function handleChangeMonitoringGroup(value) {
    setGroAttendedData(old => ({
      ...old,
      selectedMonitoringGroup: {
        ...old.selectedMonitoringGroup,
        value,
      },
      selectedMonitoringGroupData: {
        ...old.selectedMonitoringGroupData,
        techTracker: null,
        modelTracker: null,
        immobilizer: null,
        locator: null,
        bait1: null,
        bait2: null,
        bait3: null,
        bait4: null,
        groupEquipment: value,
      },
    }));
  }

  const handleChangeVehicle = async value => {
    setValidatingVehicle(true);
    if (value) {
      let updatedVehicle = value;
      try {
        setIsFetchingBenner(true);
        updatedVehicle = await importVehicleFromBenner(value.plate);
      } catch (error) {
        snackbar.show(<Text>Veículo não encontrado no Benner!</Text>, {
          type: 'error',
        });
      } finally {
        setIsFetchingBenner(false);
      }
      setVehicle({
        id: updatedVehicle?.id,
        plate: updatedVehicle?.plate,
        year_manufacture: updatedVehicle?.year_manufacture,
        year_model: updatedVehicle?.year_model,
        chassi: updatedVehicle?.chassi,
        vehicleType: updatedVehicle?.vehicleType,
        vehicle_type_id: updatedVehicle?.vehicle_type_id,
        vehicle_implement_type: updatedVehicle?.vehicle_implement_type,
        implementType: updatedVehicle?.implementType,
        vehicleBodyType: updatedVehicle?.vehicleBodyType,
        vehicle_body_type_id: updatedVehicle?.vehicle_body_type_id,
        renavam: updatedVehicle?.renavam,
        antt_type: updatedVehicle?.antt_type,
        anttType: updatedVehicle?.anttType,
        antt: updatedVehicle?.antt,
        antt_adherence: updatedVehicle?.antt_adherence
          ? format(
              new Date(moment(updatedVehicle?.antt_adherence).add(1, 'day')),
              'yyyy-MM-dd'
            )
          : null,
        tracked: updatedVehicle?.tracked,
        tracker_code: updatedVehicle?.tracker_code,
        tracker: updatedVehicle?.tracker,
        tracker_type: updatedVehicle?.trackerType,
        city: updatedVehicle?.city
          ? {
              id: updatedVehicle?.city?.id,
              name: updatedVehicle?.city?.name,
              uf: updatedVehicle?.city?.province?.uf,
            }
          : null,
        owner_id: updatedVehicle?.owner_id,
        ownerData: updatedVehicle?.ownerData,
        tags: updatedVehicle?.tags || [],
      });
      if (checkVehicleData(updatedVehicle)) {
        const message = hasPermissionToEditVehicle
          ? 'Cadastro de veículo incompleto'
          : 'Solicite atualização do cadastro do veículo para utilizá-lo na viagem';
        snackbar.show(<Text>{message}</Text>, {
          type: 'warning',
        });
        if (hasPermissionToEditVehicle) {
          return updateVehicleData.open();
        }
        setGroAttendedData(old => ({
          ...old,
          vehicle: null,
        }));
        setValidatingVehicle(false);
        return;
      }
      setValidatingVehicle(false);
      setGroAttendedData(old => ({
        ...old,
        vehicle: updatedVehicle,
      }));
    } else setValidatingVehicle(false);
    setGroAttendedData(old => ({
      ...old,
      vehicle: value,
    }));
  };

  const handleChangeWagon = async (value, wgIdx) => {
    let loadingWg = { ...validatingWagon };
    loadingWg[wgIdx] = true;
    setValidatingWagon(loadingWg);
    if (value) {
      let updatedWagon = value;
      try {
        setIsFetchingBenner(true);
        const plateId = await importWagonFromBenner(value?.plate);
        updatedWagon = await getUpdatedWagon(plateId);
      } catch (error) {
        snackbar.show(<Text>Veículo não encontrado no Benner!</Text>, {
          type: 'error',
        });
      } finally {
        setIsFetchingBenner(false);
      }
      const wagonMapped = wagonDataMapper(updatedWagon);
      setWagon({
        index: wgIdx,
        wagonData: wagonMapped,
      });
      const wagonFieldsToValidate = wagonDataToValidate(updatedWagon);

      if (checkWagonData(wagonFieldsToValidate)) {
        const message = hasPermissionToEditWagon
          ? 'Cadastro de implemento incompleto'
          : 'Solicite atualização do cadastro do implemento para utilizá-lo na viagem';
        snackbar.show(<Text>{message}</Text>, {
          type: 'warning',
        });
        if (hasPermissionToEditWagon) {
          return updateWagonData.open();
        }
        loadingWg = { ...validatingWagon };
        loadingWg[wgIdx] = false;
        setValidatingWagon(loadingWg);
        setGroAttendedData(old => ({
          ...old,
          wagons: old.wagons.map((wg, idx) => {
            if (idx === wgIdx) {
              return { status: old.wagons[idx].status, wagon: null };
            }
            return wg;
          }),
        }));
        return;
      }
      loadingWg = { ...validatingWagon };
      loadingWg[wgIdx] = false;
      setValidatingWagon(loadingWg);
      setGroAttendedData(old => ({
        ...old,
        wagons: old.wagons.map((wg, idx) => {
          if (idx === wgIdx) {
            return { status: old.wagons[idx].status, wagon: updatedWagon };
          }
          return wg;
        }),
      }));
    } else {
      loadingWg = { ...validatingWagon };
      loadingWg[wgIdx] = false;
      setValidatingWagon(loadingWg);
      setGroAttendedData(old => ({
        ...old,
        wagons: old.wagons.map((wg, idx) => {
          if (idx === wgIdx) {
            return { status: old.wagons[idx].status, wagon: value };
          }
          return wg;
        }),
      }));
    }
  };

  const importVehicleFromBenner = async plate => {
    const url = `vehicles/importFromBenner?plate=${plate}`;
    const { data } = await cooperplaceApi.get(url);
    const updatedVehicle = await getUpdatedVehicle(data.id);
    setItensToFetchMargin(prevState => ({
      ...prevState,
      mainVehicle: updatedVehicle,
    }));

    snackbar.show(<Text>{data?.message}</Text>, {
      type: 'success',
    });
    return updatedVehicle;
  };

  function handleUpdateVehicle(data) {
    setGroAttendedData(old => ({
      ...old,
      vehicle: data,
    }));
  }

  function handleUpdateWagon() {
    const loadingWg = { ...validatingWagon };
    loadingWg[wagon.index] = false;
    setValidatingWagon(loadingWg);
    setGroAttendedData(old => ({
      ...old,
      wagons: old.wagons.map((wg, idx) => {
        if (idx === wagon.index) {
          return { status: old.wagons[idx].status, wagon: wagon?.wagonData };
        }
        return wg;
      }),
    }));
  }

  async function validate(setter) {
    const errorList = {};
    const dataObject = {
      responsibilitySecurity: groAttendedData?.responsibilitySecurity?.value,
      securityProfile: groAttendedData?.securityProfile?.value,
      riskManager: groAttendedData?.riskManagerTracker?.value,
      riskManagerSearch: groAttendedData?.riskManagerSearch?.value,
      riskManagerLogistic: groAttendedData?.riskManagerLogistic?.value,
      riskManagerMirror: groAttendedData?.riskManagerMirror?.value,
      driverMinTravels: newRange?.driver_travel_min,
      driver: groAttendedData?.driver?.driver,
      tractionMaxAge: newRange?.age_traction_max,
      vehicle: groAttendedData?.vehicle,
      wagons: groAttendedData?.wagons?.map(wg => wg.wagon),
      wagonMaxAge: newRange?.age_implement_max,
      monitoringGroups:
        groAttendedData?.selectedMonitoringGroupData?.groupEquipment,
      techTracker: groAttendedData?.selectedMonitoringGroupData?.techTracker,
      modelTracker: groAttendedData?.selectedMonitoringGroupData?.modelTracker,
      immobilizer: groAttendedData?.selectedMonitoringGroupData?.immobilizer,
      locator: groAttendedData?.selectedMonitoringGroupData?.locator,
      baits: [
        groAttendedData?.selectedMonitoringGroupData?.bait1,
        groAttendedData?.selectedMonitoringGroupData?.bait2,
        groAttendedData?.selectedMonitoringGroupData?.bait3,
        groAttendedData?.selectedMonitoringGroupData?.bait4,
      ].filter(Boolean),
    };

    try {
      await schema.validate(dataObject, {
        abortEarly: false,
        context: {
          shouldInformDriver: !groAttendedData?.sameDriver,
          shouldInformVehicle: !groAttendedData?.sameVehicle,
          shouldInformResponsibilitySecurity:
            !!groAttendedData?.responsibilitySecurity?.required,
          shouldInformSecurityProfile:
            !!groAttendedData?.securityProfile?.required,
          shouldInformRiskManager:
            !!groAttendedData?.riskManagerTracker?.required,
          shouldInformRiskManagerSearch:
            !!groAttendedData?.riskManagerSearch?.required,
          shouldInformRiskManagerLogistic:
            !!groAttendedData?.riskManagerLogistic?.required,
          shouldInformRiskManagerMirror:
            !!groAttendedData?.riskManagerMirror?.required,
          shouldInformMonitoringGroup:
            groAttendedData?.equipmentsGroupsOptions?.length > 0,
          shouldInformTrackerTech:
            groAttendedData?.selectedMonitoringGroup?.value?.tecnologies
              ?.length > 0,
          shouldInformTrackerModel:
            groAttendedData?.selectedMonitoringGroup?.value?.equipments
              ?.length > 0,
          shouldInformImmobilizer:
            groAttendedData?.selectedMonitoringGroup?.value?.immobilizers
              ?.length > 0,
          shouldInformLocator:
            groAttendedData?.selectedMonitoringGroup?.value?.locators?.length >
            0,
          shouldInformBaits:
            groAttendedData?.selectedMonitoringGroup?.value?.baits?.length > 0,
          baitsAmount:
            groAttendedData?.selectedMonitoringGroup?.value?.bait_quantity,
          shouldInformWagons: groAttendedData?.wagons?.some(wg => !wg.status),
          wagonsAmount: groAttendedData?.wagons?.length,
        },
      });
      setter({ ...errorList });
      return true;
    } catch (err) {
      err.inner.forEach(error => {
        errorList[error.path] = error.message;
      });
      setter({ ...errorList });
      return false;
    }
  }

  async function handleSave() {
    try {
      setValidating(true);
      const isValid = await validate(setErrors);
      if (!isValid) {
        setValidating(false);
        return;
      }
      const payload = {
        shouldUpdatePolicyData: true,
        checklistType: groAttendedData?.checklistType || null,
        driverId: groAttendedData?.driver?.id,
        vehicleId: groAttendedData?.vehicle?.id,
        wagons: groAttendedData?.wagons?.map(wg => wg?.wagon),
        policyGroupEquipamentId: groAttendedData?.selectedMonitoringGroup
          ?.required
          ? groAttendedData?.selectedMonitoringGroup?.value?.id
          : null,
        policyRangeId: newRange.id,
        riskManager: groAttendedData?.riskManagerTracker?.value
          ? [groAttendedData?.riskManagerTracker?.value]
          : null,
        riskManagerId: groAttendedData?.riskManagerTracker?.required
          ? groAttendedData?.riskManagerTracker?.value?.id
          : null,
        riskManagerSearchId: groAttendedData?.riskManagerSearch?.required
          ? groAttendedData?.riskManagerSearch?.value?.id
          : null,
        riskManagerLogisticMirroringId: groAttendedData?.riskManagerMirror
          ?.required
          ? groAttendedData?.riskManagerMirror?.value?.id
          : null,
        riskManagerLogisticSmId: groAttendedData?.riskManagerLogistic?.required
          ? groAttendedData?.riskManagerLogistic?.value?.id
          : null,
        riskCodeLogisticSM: groAttendedData?.riskManagerLogistic?.required
          ? null
          : undefined,
        securityProfileId: groAttendedData?.securityProfile?.required
          ? groAttendedData?.securityProfile?.value?.id
          : null,
        monitoringGroupRequired:
          groAttendedData?.equipmentsGroupsOptions?.length > 0,
        trackerId:
          groAttendedData?.selectedMonitoringGroupData?.techTracker?.id || null,
        trackerTypeId:
          groAttendedData?.selectedMonitoringGroupData?.modelTracker?.id ||
          null,
        trackerLocatorId:
          groAttendedData?.selectedMonitoringGroupData?.locator?.id || null,
        trackerImmobilizerId:
          groAttendedData?.selectedMonitoringGroupData?.immobilizer?.id || null,
        baits: [
          groAttendedData?.selectedMonitoringGroupData?.bait1,
          groAttendedData?.selectedMonitoringGroupData?.bait2,
          groAttendedData?.selectedMonitoringGroupData?.bait3,
          groAttendedData?.selectedMonitoringGroupData?.bait4,
        ]?.filter(Boolean),
        trackerNumber: null,
        locatorNumber: null,
        immobilizerNumber: null,
      };
      setUpdatedTravelData(old => ({
        ...old,
        ...payload,
      }));
      setValidating(false);
      modalHook.close();
    } catch (error) {
      setValidating(false);
    }
  }

  return (
    <Modal
      scrollable
      show={isOpen}
      backdrop="static"
      handleClose={() => onClose()}
      heading={
        <Text type="header" color="dark">
          Dados obrigatórios devido alteração de valor de mercadoria
        </Text>
      }
      body={
        loading ? (
          <DefaultLoadingComponent />
        ) : (
          <>
            <UpdateVehicleData
              isOpen={updateVehicleData.isOpen}
              onClose={() => {
                setValidatingVehicle(false);
                updateVehicleData.close();
              }}
              onSubmit={handleUpdateVehicle}
              vehicle={vehicle}
              setVehicle={setVehicle}
            />
            <UpdateWagonData
              isOpen={updateWagonData.isOpen}
              onClose={() => updateWagonData.close()}
              onSubmit={handleUpdateWagon}
              wagon={wagon}
              setWagon={setWagon}
            />
            <Row style={{ marginBottom: '4rem' }}>
              <Col xs={12}>
                <Text
                  style={{ whiteSpace: 'pre-wrap' }}
                  type="regular"
                  color="dark"
                >
                  Alteração no valor da mercadoria pode exigir atualização de
                  dados conforme apólice vinculada à carga!
                  <strong>
                    {' '}
                    Para cancelar a atualização basta fechar o modal ou clicar
                    em cancelar.
                  </strong>
                  {`\nOs seguintes campos precisam ser informados: ${groAttendedData?.message} `}
                </Text>
              </Col>
              {!groAttendedData?.sameDriver ? (
                <Col xs={12} md={6} className="my-2">
                  <Select.Async
                    onSearch={fetchDriver}
                    value={groAttendedData?.driver}
                    label="Motorista *"
                    onChange={value =>
                      setGroAttendedData(old => ({ ...old, driver: value }))
                    }
                    getOptionLabel={option =>
                      option?.social_name ?? option?.socialName
                    }
                    getOptionValue={option => option?.id}
                    isDisabled={groAttendedData?.sameDriver}
                    error={errors?.driver}
                  />
                </Col>
              ) : null}
              {!groAttendedData?.sameVehicle ? (
                <Col xs={12} md={6}>
                  <Select.Async
                    onSearch={fetchVehicle}
                    placeholder=""
                    value={groAttendedData?.vehicle}
                    label="Placa principal *"
                    onChange={value => handleChangeVehicle(value)}
                    getOptionLabel={option => option.plate}
                    getOptionValue={option => option.id}
                    isDisabled={groAttendedData?.sameVehicle}
                    loading={validatingVehicle}
                    error={errors?.vehicle}
                  />
                </Col>
              ) : null}
              {wagonsRequired
                ? Array.from({
                    length: groAttendedData?.wagons?.length,
                  }).map((_, index) => (
                    <Col md={6} sm={12}>
                      <Select.Async
                        label={`Implemento ${index + 1} *`}
                        onSearch={fetchWagons}
                        value={groAttendedData?.wagons?.[index]?.wagon}
                        getOptionLabel={option => option.plate}
                        getOptionValue={option => option.id}
                        onChange={value => handleChangeWagon(value, index)}
                        isDisabled={groAttendedData?.wagons?.[index]?.status}
                        loading={validatingWagon[index]}
                        error={errors[`wagons[${index}]`]}
                      />
                    </Col>
                  ))
                : null}
              <Col xs={12} className="mt-2">
                <Text type="regular" color="dark">
                  Gerenciamento de risco
                </Text>
              </Col>
              {groAttendedData?.securityProfile?.required ? (
                <Col xs={12} md={6}>
                  <Select
                    label="Perfil de segurança"
                    options={groAttendedData?.securityProfile?.options}
                    value={groAttendedData?.securityProfile?.value}
                    onChange={value => {
                      setGroAttendedData(old => ({
                        ...old,
                        securityProfile: {
                          ...old.securityProfile,
                          value,
                        },
                      }));
                    }}
                    isDisabled={!groAttendedData?.securityProfile?.required}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                    error={errors?.securityProfile}
                  />
                </Col>
              ) : null}
              {groAttendedData?.riskManagerTracker?.required ? (
                <Col xs={12} md={6}>
                  <Select
                    label="Gerenciadora de risco rastreamento"
                    options={groAttendedData?.riskManagerTracker?.options}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                    value={groAttendedData?.riskManagerTracker?.value}
                    onChange={value => {
                      setGroAttendedData(old => ({
                        ...old,
                        riskManagerTracker: {
                          ...old.riskManagerTracker,
                          value,
                        },
                      }));
                    }}
                    isDisabled={!groAttendedData?.riskManagerTracker?.required}
                    error={errors?.riskManager}
                  />
                </Col>
              ) : null}
              {groAttendedData?.riskManagerSearch?.required ? (
                <Col xs={12} md={6}>
                  <Select
                    label="Gerenciadora Cadastro/Consulta"
                    options={groAttendedData?.riskManagerSearch?.options}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                    value={groAttendedData?.riskManagerSearch?.value}
                    onChange={value => {
                      setGroAttendedData(old => ({
                        ...old,
                        riskManagerSearch: {
                          ...old.riskManagerSearch,
                          value,
                        },
                      }));
                    }}
                    isDisabled={!groAttendedData?.riskManagerSearch?.required}
                    error={errors?.riskManagerSearch}
                  />
                </Col>
              ) : null}
              {groAttendedData?.riskManagerLogistic?.required ? (
                <Col xs={12} md={6}>
                  <Select
                    label="Gerenciadora de risco para SM Logística"
                    options={groAttendedData?.riskManagerLogistic?.options}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                    value={groAttendedData?.riskManagerLogistic?.value}
                    onChange={value => {
                      setGroAttendedData(old => ({
                        ...old,
                        riskManagerLogistic: {
                          ...old.riskManagerLogistic,
                          value,
                        },
                      }));
                    }}
                    isDisabled={!groAttendedData?.riskManagerLogistic?.required}
                    error={errors?.riskManagerLogistic}
                  />
                </Col>
              ) : null}
              {groAttendedData?.riskManagerMirror?.required ? (
                <Col xs={12} md={6}>
                  <Select
                    label="Espelhamento logístico"
                    options={groAttendedData?.riskManagerMirror?.options}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                    value={groAttendedData?.riskManagerMirror?.value}
                    onChange={value => {
                      setGroAttendedData(old => ({
                        ...old,
                        riskManagerMirror: {
                          ...old.riskManagerMirror,
                          value,
                        },
                      }));
                    }}
                    isDisabled={!groAttendedData?.riskManagerMirror?.required}
                    error={errors?.riskManagerMirror}
                  />
                </Col>
              ) : null}
              {!hasntMonitoringGroups ? (
                <>
                  <Col xs={12} className="mt-2">
                    <Text type="regular" color="dark">
                      Equipamentos de monitoramento
                    </Text>
                  </Col>
                  <Col md={12} className="my-2">
                    <Select
                      label="Grupo de monitoramento"
                      tooltip={monitoringGroupOptionsTooltip}
                      tooltipPlacement="auto"
                      toolTipWhiteSpace="pre-wrap"
                      value={groAttendedData?.selectedMonitoringGroup?.value}
                      options={groAttendedData?.equipmentsGroupsOptions}
                      onChange={value => handleChangeMonitoringGroup(value)}
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.id}
                      isDisabled={
                        hasntMonitoringGroups ||
                        !groAttendedData?.selectedMonitoringGroup?.required
                      }
                      error={
                        errors.monitoringGroups ||
                        errors?.['monitoringGroups.id']
                      }
                    />
                  </Col>
                  <Col xs={12}>
                    <Select
                      label="Tecnologia rastreador"
                      options={
                        groAttendedData?.selectedMonitoringGroup?.value
                          ?.tecnologies
                      }
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.id}
                      value={
                        groAttendedData?.selectedMonitoringGroupData
                          ?.techTracker
                      }
                      onChange={value => {
                        setGroAttendedData(old => ({
                          ...old,
                          selectedMonitoringGroupData: {
                            ...old.selectedMonitoringGroupData,
                            techTracker: value,
                            modelTracker: null,
                            trackerNumber: '',
                          },
                        }));
                      }}
                      isDisabled={!monitoringGroupFields.isTrackerRequired}
                      error={errors?.techTracker}
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <Select
                      label="Modelo rastreador"
                      options={groAttendedData?.selectedMonitoringGroup?.value?.equipments?.filter(
                        option =>
                          option?.tracker_id ===
                          groAttendedData?.selectedMonitoringGroupData
                            ?.techTracker?.id
                      )}
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.id}
                      value={
                        groAttendedData?.selectedMonitoringGroupData
                          ?.modelTracker
                      }
                      onChange={value => {
                        setGroAttendedData(old => ({
                          ...old,
                          selectedMonitoringGroupData: {
                            ...old.selectedMonitoringGroupData,
                            modelTracker: value,
                            trackerNumber: '',
                          },
                        }));
                      }}
                      isDisabled={
                        !monitoringGroupFields.isTrackerRequired ||
                        !groAttendedData?.selectedMonitoringGroupData
                          ?.techTracker
                      }
                      error={errors?.modelTracker}
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <Select
                      label="Modelo localizador"
                      options={
                        groAttendedData?.selectedMonitoringGroup?.value
                          ?.locators
                      }
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.id}
                      value={
                        groAttendedData?.selectedMonitoringGroupData?.locator
                      }
                      onChange={value => {
                        setGroAttendedData(old => ({
                          ...old,
                          selectedMonitoringGroupData: {
                            ...old.selectedMonitoringGroupData,
                            locator: value,
                          },
                        }));
                      }}
                      isDisabled={!monitoringGroupFields.isLocatorRequired}
                      error={errors?.locator}
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <Select
                      label="Modelo imobilizador"
                      options={
                        groAttendedData?.selectedMonitoringGroup?.value
                          ?.immobilizers
                      }
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.id}
                      value={
                        groAttendedData?.selectedMonitoringGroupData
                          ?.immobilizer
                      }
                      onChange={value => {
                        setGroAttendedData(old => ({
                          ...old,
                          selectedMonitoringGroupData: {
                            ...old.selectedMonitoringGroupData,
                            immobilizer: value,
                          },
                        }));
                      }}
                      isDisabled={!monitoringGroupFields.isImmobilizerRequired}
                      error={errors?.immobilizer}
                    />
                  </Col>
                  {Array.from({
                    length: monitoringGroupFields?.baitAmount,
                  }).map((_, index) => (
                    <Col md={6} sm={12}>
                      <Select
                        label={`Isca ${index + 1} *`}
                        tooltip={
                          monitoringGroupFields?.isBaitRequired
                            ? 'Selecione um grupo de monitoramento para liberar opções'
                            : 'Obrigatoriedade depende do grupo de monitoramento'
                        }
                        value={
                          groAttendedData?.selectedMonitoringGroupData?.[
                            `bait${index + 1}`
                          ]
                        }
                        onChange={value => {
                          setGroAttendedData(old => ({
                            ...old,
                            selectedMonitoringGroupData: {
                              ...old.selectedMonitoringGroupData,
                              [`bait${index + 1}`]: value,
                            },
                          }));
                        }}
                        options={
                          groAttendedData?.selectedMonitoringGroup?.value?.baits
                        }
                        getOptionLabel={option => option.name}
                        getOptionValue={option => option.id}
                        isDisabled={!monitoringGroupFields?.isBaitRequired}
                        error={errors[`baits[${index}]`]}
                      />
                    </Col>
                  ))}
                </>
              ) : null}
            </Row>
          </>
        )
      }
      footer={
        <Space x="0.5rem">
          <Button
            variant="secondary"
            size="sm"
            onClick={() => handleCancel()}
            disabled={validating}
          >
            <Text weight={500}>Cancelar</Text>
          </Button>
          <Button
            variant="primary"
            size="sm"
            onClick={() => handleSave()}
            loading={validating}
          >
            <Text weight={500}>Salvar</Text>
          </Button>
        </Space>
      }
    />
  );
}
