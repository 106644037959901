import { validatePermission } from 'actions/index';

export function canEditTravel(travel) {
  const travelStatus = [9, 12, 3, 4, 5];
  const logs = travel.logs;
  const hasPermission = validatePermission('ALTERAR_VIAGEM');

  if (hasPermission) return true;

  if (travelStatus.includes(travel?.status?.id)) return false;

  if (logs?.length > 0) {
    const isApproval = logs.filter(log => {
      return (
        log.obs.toUpperCase() == 'APROVADO CADASTRO' ||
        log.obs.toUpperCase() == 'APROVADO GR'
      );
    });

    if (isApproval.length > 0) return false;
  }

  return true;
}
