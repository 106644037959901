import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';

import { Drawer, Container, IconWrapper, Title } from './styles';

function Sidebar(
  {
    title,
    icon = 'fa-filter',
    iconInit = true,
    children,
    closeButton = true,
    init = false,
    extraButtons = [],
  },
  ref
) {
  const initialTop = 80;
  const distanceSideButtons = 50;
  const top = initialTop + distanceSideButtons;
  const [opened, setOpened] = useState(init);

  const inputRef = useRef();
  useImperativeHandle(ref, () => ({
    toggle: () => {
      // inputRef.current.focus();
      setOpened(!opened);
    },
    open: value => {
      setOpened(value);
    },
  }));

  return (
    <Drawer ref={inputRef} open={opened}>
      {closeButton &&
        (iconInit ? (
          <IconWrapper opened={opened} onClick={() => setOpened(!opened)}>
            {opened ? (
              <i className="fas fa-arrow-right" />
            ) : (
              <i className={`fas ${icon}`} />
            )}
          </IconWrapper>
        ) : (
          opened && (
            <IconWrapper opened={opened} onClick={() => setOpened(!opened)}>
              <i className="fas fa-arrow-right" />
            </IconWrapper>
          )
        ))}
      {extraButtons &&
        extraButtons.map((item, i) => {
          if (item)
            return (
              <IconWrapper
                style={{ top: `${top + distanceSideButtons * i}px` }}
                onClick={item.action}
                alt={item.title}
                title={item.title}
                key={i}
              >
                <i className={`fas ${item.icon}`} />
              </IconWrapper>
            );
          return false;
        })}

      <Container>
        {title && <Title>{title}</Title>}
        {children}
      </Container>
    </Drawer>
  );
}
// Sidebar = forwardRef(Sidebar);

export default forwardRef(Sidebar);
