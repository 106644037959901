import React, { useState, useRef } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import InfiniteScroll from 'components/InfiniteScroll';
import Fab from 'components/Fab';
import InfoMessage from 'components/InfoMessage';
import Input from 'components/Input';
import Button from 'components/Button';
import Sidebar from 'components/Sidebar';
import Snackbar from 'components/Snackbar';

import CardModels from './Card.js';
import api from '../../../services/apis/cooperplace';

export default function ModelsManufacturersList(props) {
  const toogleRef = useRef(null);
  const scrollRef = useRef(null);

  const [name, setName] = useState('');
  const [id, setId] = useState('');
  const [manufacturer, setManufacturer] = useState();
  const [snackBar, setSnackBar] = useState({
    display: false,
    type: 'info',
    message: '',
  });

  function handleOpened(model) {
    setName(model.name);
    setId(model.id);

    toogleRef.current.toggle();
  }

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      if (!id) {
        let response = await api.post(
          `vehicles/manufacturers/${props.match.params.id}/models`,
          {
            name,
          }
        );

        if (response.status === 200) {
          if (response.data.error) {
            showErrorMessage(response.data.error);
          } else {
            showSuccessMessage(`Modelo ${name} adicionado`);
          }
        }
      } else {
        let response = await api.put(
          `vehicles/manufacturers/${props.match.params.id}/models/${id}`,
          {
            name,
          }
        );

        if (response.status === 200) {
          showSuccessMessage(`Modelo ${name} atualizado`);
        }
      }
    } catch (e) {
      let responses = e.response.error;
      showErrorMessage(responses);
    }

    scrollRef.current.refresh();
  }

  function handleRender(item) {
    setManufacturer(item.manufacturers.name);
    return <CardModels handleOpened={handleOpened} models={item} />;
  }

  function showSuccessMessage(message) {
    setSnackBar({
      display: true,
      type: 'success',
      message: message,
    });
  }

  function showErrorMessage(message) {
    setSnackBar({
      display: true,
      type: 'error',
      message: message,
    });
  }

  return (
    <>
      <Snackbar
        open={snackBar.display}
        type={snackBar.type}
        message={snackBar.message}
        onClose={() =>
          setSnackBar({
            display: false,
            type: '',
            message: '',
          })
        }
      />
      <Fab
        aria-label="Adicionar modelo"
        style={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: '1',
          border: '2px solid rgb(33, 150, 243)',
          paddingRight: '20px',
          paddingLeft: '20px',
          color: 'rgb(255, 255, 255)',
          height: '36px',
          cursor: 'pointer',
          borderRadius: '18px',
        }}
        onClick={() => {
          setName('');
          setId(null);
          toogleRef.current.toggle();
        }}
      >
        Adicionar modelo
      </Fab>
      <Typography
        variant="body2"
        gutterBottom
        style={{ textTransform: 'uppercase', marginLeft: 5 }}
      >
        {manufacturer}
      </Typography>
      <InfiniteScroll
        ref={scrollRef}
        endpoint={`vehicles/manufacturers/${props.match.params.id}/models`}
        initialFetch={true}
        placeholderHeight={145}
        renderItem={handleRender}
        endMessage={
          <Grid
            container
            item
            xs={12}
            justify="center"
            style={{ marginTop: '20px' }}
          >
            <InfoMessage
              icon="doneall"
              width="auto"
              snackColor="#8c8c8c"
              message="Não há mais itens a serem exibidos."
            />
          </Grid>
        }
        service={api}
      />
      <Sidebar
        ref={toogleRef}
        closeButton={true}
        iconInit={false}
        title={id ? 'Editar Modelo' : 'Novo modelo'}
      >
        <form onSubmit={handleSubmit}>
          <Input
            label="Nome do modelo"
            value={name}
            onChange={e => {
              setName(e.target.value);
            }}
          />
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '15px',
            }}
          >
            <Button type="submit">{id ? 'Salvar' : 'Adicionar'}</Button>
          </div>
        </form>
      </Sidebar>
    </>
  );
}
