import React from 'react';

import { Link } from 'react-router-dom';
import { Col } from 'react-bootstrap';

import CardRaw from 'v3/components/Card';
import Text from 'v3/components/Text';
import Tooltip from 'v3/components/Tooltip';
import Button from 'v3/components/Button';
import { BadgeActive } from 'v3/components';

function Card({ shippingCompany }) {
  return (
    <Col md={6}>
      <CardRaw
        header={
          <>
            <Tooltip
              content={<Text type="body">{shippingCompany?.label}</Text>}
              placement="top"
            >
              <Text
                className="w-50"
                truncate
                transform="uppercase"
                color="dark"
                type="regular"
              >
                {shippingCompany?.label}
              </Text>
            </Tooltip>
            {!shippingCompany?.deleted_at ? (
              <BadgeActive pill light type="success" className="mr-2">
                <Text type="label">Ativo</Text>
              </BadgeActive>
            ) : (
              <BadgeActive pill light type="error" className="mr-2">
                <Text type="label">Inativo</Text>
              </BadgeActive>
            )}
          </>
        }
        HeaderRightComponent={
          <div>
            <Link to={`/transportadoras/${shippingCompany?.id}`}>
              <Button className="py-2">
                <Text color="white" type="regular" weight="500">
                  Detalhes
                </Text>
              </Button>
            </Link>
          </div>
        }
      />
    </Col>
  );
}

export default Card;
