import CheckBox from 'components/CheckBox';
import React from 'react';
import { Button, Text } from 'v3/components';
import TextArea from 'v3/components/TextArea';
import { ButtonsContainer, SectionContainer } from '../styles';
import { AddAttachment } from '../AddAttachments';

export function EndOccurrence({
  description,
  setDescription,
  setShow,
  errors,
  setStartDate,
  setEndDate,
  handleAddEndOccurrenceInfo,
  loadingUpdate,
  setErrors,
  files,
  setFiles,
}) {
  return (
    <>
      <AddAttachment
        files={files}
        setFiles={setFiles}
        loading={loadingUpdate}
      />
      <SectionContainer>
        <Text type="header" color="dark" weight={500}>
          Concluir Ocorrência
        </Text>
        <TextArea
          placeholder="Adicionar descrição *"
          value={description}
          onChange={e => setDescription(e.target.value)}
          resizable={false}
          error={errors?.description}
          disabled={loadingUpdate}
        />
        <CheckBox
          label={'Ocorrência resolvida'}
          defaultChecked
          disabled
          labelStyle={{
            textTransform: 'capitalize',
          }}
        />
        <ButtonsContainer>
          <Button
            variant={'secondary'}
            onClick={() => {
              setDescription('');
              setStartDate(null);
              setEndDate(null);
              setErrors({});
              setShow(old => ({ ...old, endOccurrence: false }));
            }}
            disabled={loadingUpdate}
          >
            <Text weight={500} color="dark" type="regular">
              CANCELAR
            </Text>
          </Button>
          <Button
            onClick={() => {
              handleAddEndOccurrenceInfo();
            }}
            loading={loadingUpdate}
          >
            <Text weight={500} color="white" type="regular">
              SALVAR
            </Text>
          </Button>
        </ButtonsContainer>
      </SectionContainer>
    </>
  );
}
