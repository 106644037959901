import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
  useImperativeHandle,
  forwardRef,
  memo,
} from 'react';

import RawInfiniteScroll from 'react-infinite-scroll-component';
import Skeleton from '@material-ui/lab/Skeleton';

import api from 'services/api';

function InfiniteScroll(
  {
    endpoint,
    nested,
    filter,
    renderItem,
    placeholderHeight,
    placeholderWidth,
    endMessage,
    initialMessage,
    initialFetch,
    onDataFetched,
    onDataFailure,
    onLoading,
    body,
    service = api, // se o service nao for a api padrao, passar o um service que obrigatoriamente deve possuir uma funcao get e outra post. Ex.: VeichleManufacturesService
  },
  ref
) {

  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [fetched, setFetched] = useState(false);

  const rendered = useRef(false);

  const fetchData = useCallback(
    async (page = 1) => {
      try {
        setLoading(true);
        setFetched(false);

        if (page === 1) {
          setData([]);
          setPagination({});
        }

        const response = await fetchRequest(service, page);
        const { data: items, ...rest } = nested
          ? response.data[nested]
          : response.data;

        setPagination(rest);
        if (page === 1) {
          setData(items);
        } else {
          setData(oldData => [...oldData, ...items]);
        }

        setFetched(true);
      } catch (ex) {
        console.log(ex);

        if (onDataFailure) {
          onDataFailure({
            error: true,
            message: ex,
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [endpoint, filter, body]
  );

  const fetchRequest = async (service, page) => {
    return body
      ? await service.post(`${endpoint}`, { ...body, page })
      : await service.get(
          `${endpoint}${filter ? `?${filter}&` : '?'}page=${page}`
        );
  }
  
  useImperativeHandle(
    ref,
    () => ({
      setData,
      refresh: fetchData,
    }),
    [fetchData]
  );

  useEffect(() => {
    if (!initialFetch && !rendered.current) {
      rendered.current = true;
      return;
    }
    window.scrollTo(0, 0);
    fetchData();
  }, [filter, endpoint, body]);

  useEffect(() => {
    if (fetched && onDataFetched) onDataFetched({ data, pagination });
  }, [fetched]);

  useEffect(() => {
    if (onLoading) {
      onLoading(loading);
    }
  }, [loading]);

  const hasMore = useMemo(() => {
    const { page, lastPage } = pagination;

    if (page < lastPage) {
      return true;
    }

    return false;
  }, [pagination]);

  const fetchMore = useCallback(() => {
    const { page } = pagination;

    if (!hasMore) {
      return;
    }

    const nextPage = page + 1;
    fetchData(nextPage);
  }, [fetchData, hasMore, pagination]);

  const renderLoading = useMemo(() => {
    if (loading) {
      return [...Array(pagination.perPage || 5).keys()].map(item => (
        <Skeleton
          key={item}
          width={placeholderWidth}
          height={placeholderHeight}
        />
      ));
    }

    return null;
  }, [pagination, loading]);

  const renderInitialMessage = useMemo(() => {
    if (!initialFetch && !rendered.current) {
      return initialMessage;
    }

    return null;
  }, [initialMessage]);

  const renderEndMessage = useMemo(() => {
    if (!initialFetch && !rendered.current) {
      return renderInitialMessage;
    }

    if (pagination.page > 1 || data.length === 0) {
      return endMessage;
    }

    return null;
  }, [endMessage, renderInitialMessage, pagination, data]);

  return (
    <RawInfiniteScroll
      dataLength={data.length}
      next={fetchMore}
      hasMore={hasMore}
      endMessage={renderEndMessage}
    >
      {data.map((item, index) => renderItem(item, index))}
      {renderLoading}
    </RawInfiniteScroll>
  );
}

export default memo(forwardRef(InfiniteScroll));
