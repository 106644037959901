import React, { useState, useEffect } from 'react';

import { Row, Col } from 'react-bootstrap';
import moment from 'moment';

import apiReport from 'services/apis/report';
import api from 'services/api';
import FileSaver from 'file-saver';

import DatePicker from 'v3/components/DatePicker';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import Select from 'v3/components/Select';

import { useSnackbar } from 'v3/components/Snackbar';

function Travels() {
  const snackbar = useSnackbar();

  const [companies, setCompanies] = useState('');
  const [company, setCompany] = useState();
  const [start, setStart] = useState('');
  const [diff, setDiff] = useState();
  const [end, setEnd] = useState('');
  const [loading, setLoading] = useState(false);

  async function fetchCompany() {
    try {
      const response = await api.get('company');
      setCompanies(response.data);
    } catch (ex) {
      // Handle exception
    }
  }

  useEffect(() => {
    fetchCompany();
  }, []);

  useEffect(() => {
    setDiff(
      moment(end, 'DD/MM/YYYY').diff(moment(start, 'DD/MM/YYYY'), 'days')
    );
  }, [start, end]);

  async function handleSubmit(event) {
    event.preventDefault();

    if (
      !moment(start, 'DD/MM/YYYY').isValid() ||
      !moment(end, 'DD/MM/YYYY').isValid()
    ) {
      snackbar.show(<Text>Data inválida</Text>, {
        type: 'error',
      });
      return;
    }

    if (!start || !end) {
      snackbar.show(<Text>Preencha o filtro</Text>, {
        type: 'error',
      });
      return;
    }

    if (diff > 90) {
      snackbar.show(<Text>Filtro de no máximo 90 dias</Text>, {
        type: 'error',
      });
      return;
    }

    try {
      setLoading(true);
      const response = await apiReport.get('load-attendances', {
        params: {
          company_id: company?.id,
          start: moment(start, 'DD/MM/YYYY').utc().format(),
          end: moment(end, 'DD/MM/YYYY').utc().format(),
        },
        responseType: 'blob',
      });

      FileSaver.saveAs(response.data, response.headers['x-file-name']);
    } catch (error) {
      snackbar.show(<Text>Nenhum dado encontrado com esse filtro</Text>, {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col xs={12}>
          <Select
            onSearch={fetchCompany}
            value={company}
            options={companies}
            label="Empresa"
            onChange={value => setCompany(value)}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
          />
        </Col>
      </Row>

      <Row className="mt-3">
        <Col xs={12} md={6}>
          <DatePicker
            guide
            value={start}
            label="Início *"
            onChange={event => setStart(event.target.value)}
          />
        </Col>

        <Col xs={12} md={6}>
          <DatePicker
            guide
            value={end}
            label="Final *"
            onChange={event => setEnd(event.target.value)}
          />
        </Col>
      </Row>

      <Col
        xs={12}
        className="d-flex justify-content-center align-items-center mt-4"
      >
        <Button onClick={e => handleSubmit(e)} loading={loading}>
          <Text type="regular" weight="semiBold">
            Gerar Planilha Excel
          </Text>
        </Button>
      </Col>
    </form>
  );
}

export default Travels;
