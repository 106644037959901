import React, { useMemo, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import { Text } from 'v3/components';
import { usePermission } from 'hooks';
import Input from 'v3/components/Input';
import PlusMinus from 'v3/components/PlusMinus';

const restrictedViewCards = ['CargonRevenue'];

export default function Indicators({
  personalizedDashboardOptions,
  setPersonalizedDashboardOptions,
}) {
  const [indicatorFilter, setIndicatorFilter] = useState('');
  const hasPermission = usePermission('SUPER_ADM');
  const options = useMemo(() => {
    let mappedOptions = personalizedDashboardOptions
      .sort((a, b) => (a.title > b.title ? 1 : -1))
      .map((item, idx) => ({ ...item, id: idx }));

    if (indicatorFilter) {
      mappedOptions = mappedOptions.filter(item =>
        item.title.toLowerCase().includes(indicatorFilter.toLowerCase())
      );
    }

    if (!hasPermission) {
      mappedOptions = mappedOptions.filter(
        item => !restrictedViewCards.includes(item.component)
      );
    }

    return {
      first: mappedOptions.filter((_, idx) => idx < mappedOptions.length / 2),
      last: mappedOptions,
    };
  }, [personalizedDashboardOptions, indicatorFilter]);

  return (
    <Row>
      <Col xs={6} className="mb-3">
        <Text color="dark_blue" type="subtitle" weight={600}>
          Indicadores
        </Text>
      </Col>
      <Col xs={6}>
        <Input
          placeholder="Procure um indicador"
          value={indicatorFilter}
          onChange={({ target }) => setIndicatorFilter(target.value)}
          autoFocus
        />
      </Col>
      <Col xs={6}>
        {options.first.map((item, idx) => (
          <Col xs={12} className="mb-3 p-0">
            <PlusMinus
              title={item.title}
              value={item.value}
              onChange={value => {
                setPersonalizedDashboardOptions(
                  personalizedDashboardOptions.map((component, index) => {
                    if (item.title === component.title)
                      return { ...component, value };
                    return component;
                  })
                );
              }}
            />
          </Col>
        ))}
      </Col>
      <Col xs={6}>
        {options.last.map((item, idx) => {
          if (idx >= options.first.length)
            return (
              <Col xs={12} className="mb-3 p-0">
                <PlusMinus
                  title={item.title}
                  value={item.value}
                  onChange={value => {
                    setPersonalizedDashboardOptions(
                      personalizedDashboardOptions.map(component => {
                        if (item.title === component.title)
                          return { ...component, value };
                        return component;
                      })
                    );
                  }}
                />
              </Col>
            );
        })}
      </Col>
    </Row>
  );
}
