import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import Text from 'v3/components/Text';
import Select from 'v3/components/Select';
import Input from 'v3/components/Input';
import Button from 'v3/components/Button';
import Toggle from 'v3/components/Toggle';
import Modal from 'v3/components/Modal';
import DatePicker from 'v3/components/DatePicker';

import moment from 'moment';

import api from 'services/api';
import useQuery from 'hooks/useQuery';
import { validatePermission } from 'actions/index';
import { fetchCostCenter } from 'utils/fetches';

const DATE_OPTIONS = [
  {
    label: '3 dias',
    value: moment().subtract(3, 'days').format('DD/MM/YYYY'),
  },
  {
    label: '10 dias',
    value: moment().subtract(10, 'days').format('DD/MM/YYYY'),
  },
  {
    label: '30 dias',
    value: moment().subtract(30, 'days').format('DD/MM/YYYY'),
  },
];

const REFUSED_OPTIONS = [
  {
    label: 'Todas',
    value: -1,
  },
  {
    label: 'Somente recusadas',
    value: 1,
  },
  {
    label: 'Não recusadas',
    value: 0,
  },
];

export default function TravelFilter({
  setFinalFilter,
  showAdvancedFilters,
  handleClose,
  setQtdAdvancedFilters,
}) {
  const history = useHistory();
  const queryParams = useQuery();
  const [countRender, setCountRender] = useState(0);
  const [loadingTagsFromParam, setLoadingTagsFromParam] = useState(false);
  const [error, setError] = useState({});
  const [inputTag, setInputTag] = useState('');

  // Filtros simples
  const [selectedTags, setSelectedTags] = useState('');
  const [selectedTakers, setSelectedTakers] = useState('');
  const [cgccpf] = useState('');

  // Filtros avançados

  const [originCity, setOriginCity] = useState('');
  const [destinationCity, setDestinationCity] = useState('');
  const [startDate, setStartDate] = useState(DATE_OPTIONS[0].value);
  const [endDate, setEndDate] = useState(null);
  const [status, setStatus] = useState({ label: 'Todos', value: -1 });
  const [statusOptions, setStatusOptions] = useState([]);
  const [cteNumber, setCteNumber] = useState('');
  const [billNumber, setBillNumber] = useState('');
  const [travelNumber, setTravelNumber] = useState('');
  const [loadId, setLoadId] = useState('');
  const [plate, setPlate] = useState('');
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [externalId, setExternalId] = useState('');
  const [travelRefused, setTravelRefused] = useState(REFUSED_OPTIONS[0]);
  const [costCenter, setCostCenter] = useState([]);
  const [travelId, setTravelId] = useState('');

  let isFirstRender = true;

  const [hasPermissionToSelectTags, setPermissionToSelectTags] = useState();
  useState(() => {
    setPermissionToSelectTags(validatePermission('SELECIONAR_TAGS'));
  }, []);

  function refreshAdvancedFiltersCount() {
    let advancedFiltersObj = {
      originCity,
      destinationCity,
      startDate,
      endDate,
      status,
      cteNumber,
      billNumber,
      travelNumber,
      loadId,
      plate,
      selectedDriver,
      travelRefused,
      costCenter,
      travelId,
    };
    const isNotEmpty = value =>
      value !== null &&
      value !== undefined &&
      value !== '' &&
      value !== '0' &&
      value?.length > 0;

    setQtdAdvancedFilters(
      Object.values(advancedFiltersObj).filter(isNotEmpty).length
    );
  }
  function handleFilterStart() {
    if (isFirstRender) {
      isFirstRender = false;
      // >>> Filtros simples <<<
      if (cgccpf.length > 0) queryParams.set('cgccpf', cgccpf);
      else queryParams.delete('cgccpf');

      if (selectedTags && selectedTags.length > 0) {
        queryParams.delete('tags');
        selectedTags.forEach(tag => {
          queryParams.append('tags', tag.id);
        });
      } else queryParams.delete('tags');

      if (!queryParams.has('start_date')) {
        queryParams.set(
          'start_date',
          moment(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
        );
      }

      refreshAdvancedFiltersCount();
      handleClose();
      history.push({ pathname: '/viagens', search: queryParams.toString() });
    }
  }

  function handleFilter() {
    let hasError = false;

    // >>> Filtros simples <<<
    if (selectedTags && selectedTags.length > 0) {
      queryParams.delete('tags');
      selectedTags.forEach(tag => {
        queryParams.append('tags', tag.id);
      });
    } else queryParams.delete('tags');

    if (selectedTakers && selectedTakers.length > 0) {
      queryParams.delete('companies');
      selectedTakers.forEach(taker => {
        queryParams.append('companies', taker.id);
      });
    } else queryParams.delete('companies');

    // >>> Filtros avançados <<<
    if (originCity) {
      queryParams.set('origin_city', originCity.name);
      queryParams.set('origin_province', originCity.province?.uf);
    } else {
      queryParams.delete('origin_city');
      queryParams.delete('origin_province');
    }

    if (travelId) {
      queryParams.set('attended_id', travelId);
    } else {
      queryParams.delete('attended_id');
    }

    if (destinationCity) {
      queryParams.set('destination_city', destinationCity.name);
      queryParams.set('destination_province', destinationCity.province?.uf);
    } else {
      queryParams.delete('destination_city');
      queryParams.delete('destination_province');
    }

    if (startDate) {
      try {
        if (!/^\d\d[./-]\d\d[./-]\d\d\d\d$/.test(startDate)) {
          throw new Error('');
        }
        if (!moment(startDate, 'DD/MM/YYYY').isValid()) {
          throw new Error('');
        }

        queryParams.set(
          'start_date',
          moment(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
        );
      } catch (ex) {
        queryParams.delete('start_date');
        setError({ ...error, start_date: 'Data inválida' });
        hasError = true;
      }
    } else queryParams.delete('start_date');

    if (endDate) {
      try {
        if (!/^\d\d[./-]\d\d[./-]\d\d\d\d$/.test(endDate)) {
          throw '';
        }
        if (!moment(endDate, 'DD/MM/YYYY').isValid()) {
          throw '';
        }
        queryParams.set(
          'end_date',
          moment(endDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
        );
      } catch (ex) {
        queryParams.delete('end_date');
        setError({ ...error, end_date: 'Data inválida' });
        hasError = true;
      }
    } else queryParams.delete('end_date');

    if (status && status.value !== -1 && status.value <= 13)
      queryParams.set('status', status.value);
    else queryParams.delete('status');

    if (cteNumber && cteNumber.trim().length > 0)
      queryParams.set('cte_number', cteNumber);
    else queryParams.delete('cte_number');

    if (billNumber && billNumber.trim().length > 0)
      queryParams.set('bill_number', billNumber);
    else queryParams.delete('bill_number');

    if (travelNumber && travelNumber.trim().length > 0)
      queryParams.set('travel_number', travelNumber);
    else queryParams.delete('travel_number');

    if (loadId && loadId.trim().length > 0) queryParams.set('load_id', loadId);
    else queryParams.delete('load_id');

    if (plate && plate.trim().length > 0)
      queryParams.set('license_plate', plate);
    else queryParams.delete('license_plate');

    if (selectedDriver?.social_name?.length > 0)
      queryParams.set('driver_name', selectedDriver?.social_name);
    else queryParams.delete('driver_name');
    if (selectedDriver?.id) queryParams.set('driver_id', selectedDriver?.id);
    else queryParams.delete('driver_id');

    if (externalId) {
      queryParams.set('external_id', externalId);
    } else {
      queryParams.delete('external_id');
    }

    if (travelRefused && travelRefused?.value !== -1) {
      queryParams.set('refused', travelRefused.value);
    } else {
      queryParams.delete('refused');
    }

    if (costCenter && costCenter?.value !== -1) {
      queryParams.delete('cost_center');
      costCenter.forEach(item => {
        queryParams.append('cost_center', item.id);
      });
    } else queryParams.delete('cost_center');

    // Se não houver erros (em datas), a modal fecha (se tiver aberta) e segue a pesquisa
    if (!hasError) {
      refreshAdvancedFiltersCount();
      handleClose();
      history.push({ pathname: '/viagens', search: queryParams.toString() });
      setFinalFilter(queryParams.toString()); // Envia o filtro completo pra tela principal
    }
  }

  async function handleParamTags(tags) {
    try {
      setLoadingTagsFromParam(true);
      if (tags && tags.length > 0) {
        const finalTagsData = [];
        for (const tag in tags) {
          const responseTag = await api.get(`tags/${tags[tag]}`);
          finalTagsData.push(responseTag.data);
        }
        setSelectedTags(finalTagsData);
        setLoadingTagsFromParam(false);
      } else {
        const responseUserTags = await api.get('user-tags');
        if (responseUserTags.status === 200) {
          const finalTagsDataUser = [];
          for (const tag in responseUserTags.data) {
            finalTagsDataUser.push({
              id: responseUserTags.data[tag].tag_id,
              name: responseUserTags.data[tag].name.name,
            });
          }
          setSelectedTags(finalTagsDataUser);
          setLoadingTagsFromParam(false);
        }
      }
    } catch (e) {
      setLoadingTagsFromParam(false);
    }
  }

  async function handleParamCostCenter(costCenters) {
    if (costCenters && costCenters.length > 0) {
      const finalCostCenterData = [];
      costCenters.forEach(async item => {
        const responseCostCenter = await api.get(`v3/cost-center/${item}`);
        if (responseCostCenter.status === 200) {
          finalCostCenterData.push(responseCostCenter.data);
        }
      });

      setCostCenter(finalCostCenterData);
    }
  }

  async function handleParamTakers() {
    try {
      const { id } = JSON.parse(localStorage.getItem('usuario'));
      const clients = await api.get(`clients/user/${id}`);

      setSelectedTakers(clients.data?.clients?.map(client => client) || []);
      selectedTakers.forEach(taker => {
        queryParams.append('companies', taker?.id);
      });
      setFinalFilter(queryParams.toString());
    } catch (error) {
      //
    }
  }

  useEffect(() => {
    if (selectedTakers) {
      selectedTakers.forEach(taker => {
        queryParams.append('companies', taker.id);
      });
    }

    setFinalFilter(queryParams?.toString());
  }, [selectedTakers]);

  async function handleParamOriginCity(city, province) {
    try {
      if (city && province) {
        setOriginCity({ name: city, province: { uf: province } });
      }
    } catch (e) {
      setOriginCity(null);
    }
  }

  async function handleParamDestinationCity(city, province) {
    try {
      if (city && province) {
        setDestinationCity({ name: city, province: { uf: province } });
      }
    } catch (e) {
      setDestinationCity(null);
    }
  }

  async function fetchDriver(search) {
    try {
      const response = await api.get('drivers/select', { params: { search } });
      return response.data;
    } catch (ex) {
      return [];
    }
  }

  async function fetchTags(search) {
    try {
      const response = await api.get('tags', { params: { search } });
      return response.data.data;
    } catch (ex) {
      return [];
    }
  }

  async function fetchTakers(search) {
    try {
      let resp = null;

      const searchAllTakers = await api.get(
        `persons/customers?&search=${search}`
      );
      resp = searchAllTakers.data;

      return resp;
    } catch (ex) {
      return [];
    }
  }

  async function fetchStatus(handleParam = 0) {
    let arrayStatus = [];
    try {
      const response = await api.get(`travel-status`);
      arrayStatus = [
        { label: 'Todos', value: -1 },
        ...response.data?.map(status => {
          return { label: status.name, value: status.id, color: status.color };
        }),
      ];
      setStatusOptions(arrayStatus);
    } catch (ex) {
      // Handle exception
    } finally {
      if (handleParam) {
        let findStatus = arrayStatus.find(
          status => status.value === Number(handleParam)
        );
        if (!findStatus) findStatus = { label: 'Todos', value: -1 };
        setStatus(findStatus);
      }
    }
  }

  async function fetchProvinces(search) {
    try {
      const response = await api.get('cities', {
        params: { search },
      });
      return response.data;
    } catch (ex) {
      return [];
    }
  }

  useEffect(() => {
    handleParamTakers(queryParams.getAll('companies'));
  }, []);

  useEffect(() => {
    // Filtros simples
    if (!queryParams.has('no-tags')) {
      handleParamTags(queryParams.getAll('tags'));
    }

    // Filtros avançados
    if (queryParams.get('origin_city') && queryParams.get('origin_province'))
      handleParamOriginCity(
        queryParams.get('origin_city'),
        queryParams.get('origin_province')
      );

    if (
      queryParams.get('destination_city') &&
      queryParams.get('destination_province')
    )
      handleParamDestinationCity(
        queryParams.get('destination_city'),
        queryParams.get('destination_province')
      );

    if (queryParams.get('start_date'))
      setStartDate(
        moment(queryParams.get('start_date'), 'YYYY-MM-DD').format('DD/MM/YYYY')
      );

    if (queryParams.get('refused')) {
      setTravelRefused({ refused: queryParams.get('refused') });
    }

    if (queryParams.get('end_date'))
      setEndDate(
        moment(queryParams.get('end_date'), 'YYYY-MM-DD').format('DD/MM/YYYY')
      );

    if (queryParams.get('status')) {
      const findValidStatus = fetchStatus(queryParams.get('status'));
      if (findValidStatus) {
        setStatus({
          label: findValidStatus.label,
          value: findValidStatus.value,
        });
      }
      if (findValidStatus.value === -1) {
        queryParams.delete('status');
      }
    } else {
      fetchStatus();
    }

    if (queryParams.get('attended_id')) {
      setTravelId(queryParams.get('attended_id'));
    }

    if (queryParams.get('cte_number'))
      setCteNumber(queryParams.get('cte_number'));

    if (queryParams.get('bill_number'))
      setBillNumber(queryParams.get('bill_number'));

    if (queryParams.get('travel_number'))
      setTravelNumber(queryParams.get('travel_number'));

    if (queryParams.get('load_id')) setLoadId(queryParams.get('load_id'));

    if (queryParams.get('license_plate'))
      setPlate(queryParams.get('license_plate'));

    if (queryParams.get('driver_name') && queryParams.get('driver_id')) {
      setSelectedDriver({
        social_name: queryParams.get('driver_name'),
        id: queryParams.get('driver_id'),
      });
    }

    if (queryParams.get('cost_center')) {
      handleParamCostCenter(queryParams.getAll('cost_center'));
    } else queryParams.delete('cost_center');

    if (queryParams.get('refused')) {
      if (queryParams.get('refused') === '0') {
        setTravelRefused(REFUSED_OPTIONS[2]);
      } else {
        setTravelRefused(REFUSED_OPTIONS[1]);
      }
    } else {
      setTravelRefused(REFUSED_OPTIONS[0]);
    }

    handleFilterStart();
  }, []);

  useEffect(() => {
    refreshAdvancedFiltersCount();
  }, [
    cgccpf,
    selectedTags,
    originCity,
    destinationCity,
    startDate,
    endDate,
    status,
    cteNumber,
    billNumber,
    travelNumber,
    loadId,
    plate,
    selectedDriver,
    costCenter,
    travelId,
  ]);

  // Limpa todos os filtros avançados
  function handleHide() {
    refreshAdvancedFiltersCount();
    handleClose();
  }
  useEffect(() => {
    setCountRender(item => item + 1);
    if (countRender < 2) handleFilterStart();
  }, [selectedTags]);

  function tagTreatment(input) {
    if (input) {
      const inputFiltered = input.trimStart().replace(/[^a-zA-Z0-9 ]/g, '');
      setInputTag(inputFiltered);
    } else {
      setInputTag('');
    }
  }

  async function handleTagCreate(tagValue) {
    if (tagValue) {
      tagValue = tagValue.trim();
      if (tagValue.length >= 3) {
        try {
          const response = await api.post('tags', { name: tagValue });
          if (response.status === 200) {
            const newTag = {
              id: response.data.id,
              name: tagValue,
            };
            setInputTag('');
            setSelectedTags(prev => (prev ? [...prev, newTag] : [newTag]));
          }
        } catch (err) {
          // Handle exception
        }
      }
    }
  }

  return (
    <>
      <Modal
        size="lg"
        show={showAdvancedFilters}
        backdrop="static"
        heading={
          <Text type="header" color="dark" weight="500">
            Filtro Avançado
          </Text>
        }
        animation
        onHide={() => handleHide()}
        style={{ height: '100%' }}
        body={
          <Row className="p-1 form" md={12}>
            <Col md={6} xs={12}>
              <Select.Async
                label="Origem"
                onSearch={fetchProvinces}
                value={originCity}
                horizontal
                onChange={value => setOriginCity(value)}
                getOptionLabel={option =>
                  `${`${option.name} - ${option.province?.uf}`}`
                }
                getOptionValue={option =>
                  `${`${option.name}-${option.province?.uf}`}`
                }
              />
            </Col>
            <Col md={6} xs={12}>
              <Select.Async
                label="Destino"
                onSearch={fetchProvinces}
                value={destinationCity}
                horizontal
                onChange={value => setDestinationCity(value)}
                getOptionLabel={option =>
                  `${`${option.name} - ${option.province?.uf}`}`
                }
                getOptionValue={option =>
                  `${`${option.name}-${option.province?.uf}`}`
                }
              />
            </Col>
            <Col md={4} xs={12}>
              <DatePicker
                value={startDate}
                onChange={e => {
                  setStartDate(e.target.value);
                  setError({ ...error, start_date: '' });
                }}
                label="Período de"
                error={error.start_date}
              />
            </Col>
            <Col md={4} xs={12}>
              <DatePicker
                value={endDate}
                onChange={e => {
                  setEndDate(e.target.value);
                  setError({ ...error, end_date: '' });
                }}
                label="Até"
                error={error.end_date}
              />
            </Col>
            <Col md={4} xs={12}>
              <Select
                label="Status"
                value={status}
                onChange={event => {
                  setStatus(event);
                }}
                options={statusOptions}
                getOptionLabel={option => option.label}
                getOptionValue={option => option.value}
                disableClear
              />
            </Col>
            <Col md={4} xs={12}>
              <Input
                label="Número da viagem cooperplace"
                value={travelId}
                onChange={e => {
                  if (e.target.value.match('^[0-9]*$')) {
                    setTravelId(e.target.value);
                  }
                }}
              />
            </Col>
            {/*
            ocultado conforme solicitação task 1264
            <Col md={4} xs={12}>
              <Input
                label="Número da viagem"
                type="text"
                value={travelNumber}
                onChange={e => setTravelNumber(e.target.value)}
              />
            </Col> */}
            <Col md={4} xs={12}>
              <Input
                label="ID carga"
                value={loadId}
                onChange={e => setLoadId(e.target.value)}
                guide={false}
                masked
                mask={[
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                ]}
              />
            </Col>
            <Col md={4} xs={12}>
              <Input
                label="ID externo"
                type="text"
                value={externalId}
                onChange={e => setExternalId(e.target.value)}
              />
            </Col>
            <Col md={4} xs={12}>
              <Input
                label="Número CT-e"
                type="text"
                value={cteNumber}
                onChange={e => setCteNumber(e.target.value)}
              />
            </Col>
            <Col md={4} xs={12}>
              <Input
                label="Nota fiscal"
                type="text"
                value={billNumber}
                onChange={e => setBillNumber(e.target.value)}
                guide={false}
                masked
                mask={[
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                ]}
              />
            </Col>
            <Col md={4} xs={12}>
              <Input
                label="Placa"
                type="text"
                value={plate}
                onChange={e => setPlate(e.target.value)}
              />
            </Col>
            <Col md={4} xs={12}>
              <Select.Async
                label="Motorista"
                onSearch={fetchDriver}
                value={selectedDriver}
                onChange={value => setSelectedDriver(value)}
                getOptionLabel={option => option.social_name}
                getOptionValue={option => option.id}
              />
            </Col>
            <Col md={4} xs={12}>
              <Select
                label="Viagens Recusadas"
                value={travelRefused}
                onChange={value => setTravelRefused(value)}
                options={REFUSED_OPTIONS}
                getOptionLabel={option => option.label}
                getOptionValue={option => option.value}
              />
            </Col>
            <Col md={4} xs={12}>
              <Select.Async
                label="Centro de custo da carga"
                multiple
                onSearch={search => fetchCostCenter({ search })}
                value={costCenter}
                onChange={value => setCostCenter(value)}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
              />
            </Col>
          </Row>
        }
        footer={
          <Row style={{ justifyContent: 'flex-end' }}>
            <Button
              variant="primary"
              onClick={() => handleFilter()}
              className="py-2"
            >
              <Text type="regular" weight="500">
                Filtrar
              </Text>
            </Button>
          </Row>
        }
      />

      <Row className="filter">
        <Col lg={3} md={3} xs={12}>
          <Toggle
            label="Criado em até"
            labelColor="#fff"
            value={startDate}
            defaultValue={startDate}
            options={DATE_OPTIONS}
            onSelected={value => {
              setStartDate(value);
              setError({ ...error, start_date: '' });
              setEndDate(null);
            }}
          />
        </Col>
        <Col lg={3} md={3} xs={12}>
          <Select.Async
            multiple
            label="Tags"
            onSearch={fetchTags}
            value={selectedTags}
            horizontal
            onChange={value => setSelectedTags(value)}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            isDisabled={
              hasPermissionToSelectTags
                ? loadingTagsFromParam
                : !hasPermissionToSelectTags
            }
            onInputChange={e => tagTreatment(e)}
            inputValue={inputTag}
            onCreateOption={handleTagCreate}
            labelColor="#fff"
            creatable
          />
        </Col>
        <Col lg={4} md={4} xs={12}>
          <Select.Async
            multiple
            label="Cliente Tomador"
            onSearch={fetchTakers}
            value={selectedTakers}
            horizontal
            onChange={value => setSelectedTakers(value)}
            getOptionLabel={option => option.label}
            getOptionValue={option => option.id}
            labelColor="#fff"
          />
        </Col>
        <Col lg={2} xs={12} className="vertical bottom">
          <Button
            variant="success"
            onClick={() => handleFilter()}
            className="mt-2"
          >
            <Text type="regular" weight={500}>
              Filtrar
            </Text>
          </Button>
        </Col>
      </Row>
    </>
  );
}
