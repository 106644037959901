import React from 'react';

import { OutlinedInput, MenuItem } from '@material-ui/core';
import Label from 'components/Label';

import { StyledSelect, MenuContainer, ItemTitle, ItemSubtitle } from './styles';

function Select({
  label,
  name,
  placeholder,
  options = [],
  itemTitle = 'label',
  itemValue = 'value',
  itemSubtitle,
  companySelector,
  value,
  testId,
  ...rest
}) {
  const handledValue = value || 'placeholder';

  return (
    <>
      {label && (
        <Label htmlFor={name} className="textLabel" uppercase>
          {label}
        </Label>
      )}
      <StyledSelect
        input={<OutlinedInput name={name} id={name} />}
        value={handledValue}
        {...rest}        
      >
        {placeholder && (
          <MenuItem disabled value="placeholder">
            <MenuContainer>
              <ItemTitle noUnderline companySelector={companySelector}>
                {placeholder}
              </ItemTitle>
            </MenuContainer>
          </MenuItem>
        )}
        {options.map((option, index) => (
          <MenuItem key={index} value={option[itemValue]} id={testId}>
            <MenuContainer>
              <ItemTitle companySelector={companySelector}>
                {option[itemTitle]}
              </ItemTitle>
              {itemSubtitle && (
                <ItemSubtitle>{option[itemSubtitle]}</ItemSubtitle>
              )}
            </MenuContainer>
          </MenuItem>
        ))}
      </StyledSelect>
    </>
  );
}

export default Select;
