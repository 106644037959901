import React from 'react';
import GoogleMapReact from 'google-map-react';

function SimpleMap({
  height = '100%',
  width = '100%',
  zoom = 13,
  position,
  children,
  ...rest
}) {
  const mapStyles = {
    mapStyles: [
      {
        featureType: 'all',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#1c4b75' }],
      },
      {
        featureType: 'all',
        elementType: 'labels.text.stroke',
        stylers: [{ color: 'rgba(70, 78, 95, 0.15)' }, { lightness: 13 }],
      },
      {
        featureType: 'administrative',
        elementType: 'geometry.fill',
        stylers: [{ color: 'rgba(70, 78, 95, 0.15)' }],
      },
      {
        featureType: 'administrative',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#1c4b75' }, { lightness: 14 }, { weight: 1.4 }],
      },
      {
        featureType: 'landscape',
        elementType: 'all',
        stylers: [{ color: 'rgba(70, 78, 95, 0.15)' }],
      },
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [{ color: 'rgba(70, 78, 95, 0.15)' }, { lightness: 5 }],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [{ color: 'rgba(70, 78, 95, 0.15)' }],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#1c4b75' }, { lightness: 25 }],
      },
      {
        featureType: 'road.arterial',
        elementType: 'geometry.fill',
        stylers: [{ color: 'rgba(70, 78, 95, 0.15)' }],
      },
      {
        featureType: 'road.arterial',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#1c4b75' }, { lightness: 16 }],
      },
      {
        featureType: 'road.local',
        elementType: 'geometry',
        stylers: [{ color: 'rgba(70, 78, 95, 0.15)' }],
      },
      {
        featureType: 'transit',
        elementType: 'all',
        stylers: [{ color: '#1c4b75' }],
      },
      {
        featureType: 'water',
        elementType: 'all',
        stylers: [{ color: 'rgba(70, 78, 95, 0.15)' }],
      },

      {
        featureType: 'poi',
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
    ],
  };

  const defaultOptions = {
    styles: mapStyles.mapStyles,
    mapTypeControl: false,
    zoomControl: true,
    streetViewControl: false,
    draggableCursor: 'default',
    draggingCursor: 'move',
    fullscreenControl: true,
    clickableIcons: true,
    draggable: true,
  };

  const styles = {
    height,
    width,
    overflow: 'hidden',
  };

  return (
    <div style={styles}>
      <GoogleMapReact
        options={{
          ...defaultOptions,
        }}
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_MAPS_KEY,
          libraries: ['places', 'geometry'],
        }}
        defaultCenter={position}
        defaultZoom={zoom}
        {...rest}
      >
        {children}
      </GoogleMapReact>
    </div>
  );
}

export default SimpleMap;
