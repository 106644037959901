import styled, { css } from 'styled-components';

import theme from 'v3/theme';

export const Page = styled.button`
  border-radius: 2px;
  background-color: ${({ selected }) =>
    selected ? theme.colors.primary : theme.colors.secondary};
  line-height: 100%;
  width: auto;
  height: 20px;
  text-align: center;

  &:focus {
    outline: none;
  }

  &:not(:last-child) {
    margin-right: 5px;
  }

  ${({ selected }) =>
    selected &&
    css`
      span {
        color: #fff;
      }
    `}
`;

export const SideButton = styled.button`
  background-color: transparent;

  &:focus {
    outline: none;
  }
`;
