import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

import { Card, Select, Text, Input, Tooltip, Link } from 'v3/components';
import {
  fetchAllNegotiationsByPersonAndCostCenter,
  fetchBranchOffices,
  fetchCostCenter,
  fetchSelectOptions,
  importFromBenner,
} from 'utils/fetches';
import { phoneNumberFormater, validateDateTime } from 'v3/utils/functions';
import { FaPhoneAlt } from 'react-icons/fa';
import { validatePermission } from 'actions';
import api from 'services/api';
import TextArea from 'v3/components/TextArea';
import { QuestionIcon } from 'v3/pages/Load/Register/LoadData/styled';
import { DELIVERY_TYPE_OPTIONS } from 'v3/utils/constants';
import { LoadContext } from 'v3/pages/Load/Register/controller';
import { useLoadGro } from '../context';

const [, useLoad] = LoadContext;

export function LoadDataGro() {
  const {
    selectedPolicy,
    operations,
    handleGetSelectedPolicy,
    disableProduct,
    products,
    setProducts,
    errorsGro,
    setErrorsGro,
    setSelectedPolicyProduct,
    handleSelectProduct,
  } = useLoadGro();
  const { data, setData, countryList } = useLoad();

  const [availableNegotiations, setAvailableNegotiations] = useState([]);
  const [loadingNegotiations, setLoadingNegotiations] = useState(false);
  const hasPermissionToSelectTags = validatePermission('SELECIONAR_TAGS');
  const hasPermissionToCreateTags = validatePermission('CRIAR_TAGS');

  async function fetchAllNegotiations(clientId, costCenterExternalId) {
    try {
      setLoadingNegotiations(true);
      const response = await fetchAllNegotiationsByPersonAndCostCenter({
        personId: clientId,
        costCenterExternalId,
      });
      setAvailableNegotiations(response);
    } catch (error) {
      setAvailableNegotiations([]);
    } finally {
      setLoadingNegotiations(false);
    }
  }

  useEffect(() => {
    if (data?.client && data?.costCenter?.external_id) {
      fetchAllNegotiations(data?.client?.id, data?.costCenter?.external_id);
    }
  }, [data?.client, data?.costCenter?.external_id]);

  useEffect(() => {
    if (data?.client?.cgccpf) {
      importFromBenner(data?.client?.cgccpf);
    }
  }, [data?.client?.cgccpf]);

  async function handleChangeOperation(value) {
    setData(old => ({
      ...old,
      operation: value,
      product: null,
      origin: {
        address: '',
        date: '',
        complement: '',
        lat: '',
        lng: '',
        formattedAddress: '',
        neighborhood: '',
        city: '',
        province: '',
        zipCode: '',
        country: null,
        number: '',
        startSchedule: '',
      },
      destinations: [
        {
          address: '',
          type: DELIVERY_TYPE_OPTIONS[1],
          date: '',
          complement: '',
          lat: '',
          lng: '',
          formattedAddress: '',
          neighborhood: '',
          city: '',
          province: '',
          zipCode: '',
          country: null,
          number: '',
          startSchedule: '',
          index: 0,
        },
      ],
      vehicleTypes: null,
      bodyTypes: null,
      implementTypes: null,
      load_currency: null,
      cargoValue: null,
      tracked: null,
      escorted: null,
      managerTracker: null,
    }));
    setProducts([]);
    setSelectedPolicyProduct(null);
    if (value) {
      await handleGetSelectedPolicy(value?.policy?.id);
    }
  }

  function handleSearchOperation(value) {
    if (!value) {
      return operations;
    }
    const parsedSearch = value.toLowerCase();
    return operations.filter(
      operation =>
        operation?.name?.toLowerCase().search(parsedSearch) >= 0 ||
        operation?.policy?.name?.toLowerCase().search(parsedSearch) >= 0
    );
  }
  function handleSearchProducts(value) {
    if (!value) {
      return products;
    }
    const parsedSearch = value.toLowerCase();
    return products.filter(
      product =>
        product?.name?.toLowerCase().search(parsedSearch) >= 0 ||
        product?.productNcm?.name?.toLowerCase().search(parsedSearch) >= 0 ||
        product?.ncm?.toLowerCase().search(parsedSearch) >= 0
    );
  }

  const [inputTag, setInputTag] = useState('');

  function tagTreatment(input) {
    if (input) {
      const inputFiltered = input.trimStart().replace(/[^a-zA-Z0-9 ]/g, '');
      setInputTag(inputFiltered);
    } else {
      setInputTag('');
    }
  }

  async function handleTagCreate(tagValue) {
    const trimmedTagValue = tagValue.trim();
    if (trimmedTagValue) {
      if (trimmedTagValue.length >= 3) {
        try {
          const response = await api.post('tags', { name: trimmedTagValue });
          if (response.status === 200) {
            const newTag = {
              id: response.data.id,
              name: trimmedTagValue,
            };
            setInputTag('');
            setData(old => ({
              ...old,
              tags: data?.tags ? [...data?.tags, newTag] : [newTag],
            }));
          }
        } catch (err) {
          //
        }
      }
    }
  }

  function handleChangePolicyProduct(value) {
    handleSelectProduct(value);
    setData(old => ({
      ...old,
      product: value,
      origin: {
        address: '',
        date: '',
        complement: '',
        lat: '',
        lng: '',
        formattedAddress: '',
        neighborhood: '',
        city: '',
        province: '',
        zipCode: '',
        country: null,
        number: '',
        startSchedule: '',
      },
      destinations: [
        {
          address: '',
          type: DELIVERY_TYPE_OPTIONS[1],
          date: '',
          complement: '',
          lat: '',
          lng: '',
          formattedAddress: '',
          neighborhood: '',
          city: '',
          province: '',
          zipCode: '',
          country: null,
          number: '',
          startSchedule: '',
          index: 0,
        },
      ],
    }));
  }

  return (
    <Card
      header={
        <>
          <Text color="#464E5F" type="header">
            Dados da Carga
            {selectedPolicy?.id ? (
              <Link to={`/apolices/${selectedPolicy?.id}`} target="_blank">
                <Text className="ml-2" type="label" color="gray">
                  ver apólice
                </Text>
              </Link>
            ) : null}
          </Text>
        </>
      }
      HeaderRightComponent={
        <div className="position-relative w-25">
          <Input
            type="number"
            step={1}
            onKeyDown={evt =>
              ['e', 'E', '+', '-', '.'].includes(evt.key) &&
              evt.preventDefault()
            }
            placeholder="Quantidade"
            hideAppearance
            value={data?.loadCreationNumber}
            onChange={event => {
              if (Number(event.target.value) > 100) {
                return null;
              }
              setData(old => ({
                ...old,
                loadCreationNumber: event.target.value,
              }));
            }}
            error={errorsGro.loadCreationNumber}
            disabled={false}
          />
          <Tooltip
            content={
              <Text type="label">Quantidade de cargas a serem criadas *</Text>
            }
          >
            <QuestionIcon />
          </Tooltip>
        </div>
      }
      bodyCardPadding="1rem 3rem"
    >
      <Row className="form">
        <Col md={6} sm={12}>
          <Select.Async
            label="Tomador *"
            value={data?.client}
            isDisabled
            getOptionLabel={option => {
              let label = `${option?.social_name} - ${option?.cgccpf}`;

              if (option.city) {
                label += ` - ${option?.city}`;
              }

              return label;
            }}
            getOptionValue={option => option.id}
            id="select-gro-tomador"
          />
        </Col>
        <Col md={3} sm={12}>
          <Input
            label="Temperatura mínima da carga"
            type="number"
            value={data?.temperatureMin}
            onChange={({ target }) => {
              setData(old => ({ ...old, temperatureMin: target.value }));
            }}
            id="input-gro-temperatura-minima-carga"
          />
        </Col>
        <Col md={3} sm={12}>
          <Input
            label="Temperatura máxima da carga"
            type="number"
            value={data?.temperature}
            onChange={({ target }) => {
              setData(old => ({ ...old, temperature: target.value }));
            }}
            id="input-gro-temperatura-maxima-carga"
          />
        </Col>
        <Col md={6}>
          <Select.Async
            label="Operação *"
            onSearch={search => handleSearchOperation(search)}
            options={operations}
            value={data?.operation}
            onChange={value => {
              handleChangeOperation(value);
            }}
            getOptionLabel={option =>
              `${option?.policy?.name} - ${option.name}`
            }
            getOptionValue={option => option.id}
            error={errorsGro.operation}
            id="select-gro-operacao"
          />
        </Col>
        <Col md={3} sm={12}>
          <Input
            label="Número do pedido do cliente"
            value={data?.clientOrderNumber}
            onChange={event =>
              setData(old => ({
                ...old,
                clientOrderNumber: event.target.value,
              }))
            }
            error={errorsGro.clientOrderNumber}
            loading={false}
            id="input-gro-numero-pedido-cliente"
          />
        </Col>
        <Col sm={12} md={3}>
          <Input
            label="Invoice"
            value={data?.externalInvoiceNumber}
            type="text"
            onChange={({ target }) => {
              setData(old => ({
                ...old,
                externalInvoiceNumber: target.value,
              }));
            }}
            loading={false}
            id="input-gro-invoice"
          />
        </Col>
        <Col md={6} sm={12}>
          <Select.Async
            label="Produto *"
            onSearch={search => handleSearchProducts(search)}
            options={products}
            value={data?.product}
            getOptionLabel={option => {
              if (option?.productType?.name) {
                return `${`${option.name} - ${option.productType?.name}`}`;
              }
              return option.name;
            }}
            getOptionValue={option =>
              `${`${option?.id}-${option?.productType?.id}`}`
            }
            onChange={value => handleChangePolicyProduct(value)}
            error={errorsGro.product}
            tooltip={
              data?.operation
                ? ''
                : 'Selecione uma operação para habilitar campo dos produtos'
            }
            isDisabled={disableProduct}
            id="select-gro-produto"
          />
        </Col>
        <Col sm={12} md={3}>
          <Input
            label="CRT"
            value={data?.crt}
            onChange={event =>
              setData(old => ({ ...old, crt: event.target.value }))
            }
            error={errorsGro.crt}
            id="input-gro-crt"
          />
        </Col>
        <Col md={3} sm={12}>
          <Input
            type="datetime-local"
            value={data?.dueDate}
            label="Data de vencimento *"
            onChange={event => {
              const isValid = validateDateTime(event?.target?.value);
              if (isValid) {
                setData(old => ({ ...old, dueDate: event?.target?.value }));
                setErrorsGro(old => ({ ...old, dueDate: null }));
              } else {
                setErrorsGro(old => ({ ...old, dueDate: 'Data inválida' }));
              }
            }}
            error={errorsGro.dueDate}
            id="input-gro-data-vencimento"
          />
        </Col>
        <Col md={6} sm={12}>
          <Select.Async
            label="Filial *"
            onSearch={search => fetchBranchOffices(search)}
            value={data?.branchOffice}
            getOptionLabel={option => `${`${option.id} - ${option.name}`}`}
            getOptionValue={option => option?.id}
            onChange={value =>
              setData(old => ({ ...old, branchOffice: value }))
            }
            error={errorsGro.branchOffice}
          />
        </Col>
        <Col md={6} sm={12}>
          <Select.Async
            multiple
            horizontal
            label="Negociador *"
            onSearch={search =>
              fetchSelectOptions('user/name', {
                negotiator: search,
              })
            }
            modalHeading="Adicione um negociador"
            modalBodyTitle="Negociador:"
            value={data?.negotiator}
            getOptionLabel={option => option.username}
            getOptionValue={option => option.id}
            onChange={value => {
              setData(old => ({
                ...old,
                negotiator: value,
                phone: value?.[0]?.phonenumber
                  ? phoneNumberFormater(value?.[0]?.phonenumber)
                  : '',
              }));
            }}
            error={errorsGro.negotiator}
            id="select-gro-negociador"
          />
        </Col>
        <Col md={6}>
          <Select.Async
            label="Centro de custo *"
            onSearch={search => fetchCostCenter({ search })}
            value={data?.costCenter}
            onChange={value => {
              setData(old => ({
                ...old,
                costCenter: value,
                negotiation: null,
              }));
            }}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            error={errorsGro.costCenter}
            id="select-gro-centro-custo"
          />
        </Col>
        <Col md={3} sm={12}>
          <Select
            label="País *"
            value={data?.country}
            options={countryList}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            onChange={event => setData(old => ({ ...old, country: event }))}
            error={errorsGro.country}
            id="select-gro-pais"
          />
        </Col>
        <Col md={3} sm={12}>
          <Input
            label="Whatsapp *"
            icon={<FaPhoneAlt color="#494950" />}
            masked={data?.country ? data?.country.abbreviation === 'br' : false}
            mask={[
              '(',
              /[0-9]/,
              /\d/,
              ')',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              '-',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
            ]}
            value={data?.phone}
            onChange={event =>
              setData(old => ({ ...old, phone: event.target.value }))
            }
            error={errorsGro.phone}
            id="input-gro-whatsapp"
          />
        </Col>
        <Col md={6}>
          <Select
            label="Negociação *"
            tooltip="Negociação depende do tomador e centro de custo"
            options={availableNegotiations}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            value={data?.negotiation}
            onChange={value => setData(old => ({ ...old, negotiation: value }))}
            isDisabled={!data?.costCenter || !data?.client}
            error={errorsGro.negotiation}
            noOptionsMessageText="Nenhuma negociação disponível"
            loading={loadingNegotiations && !data?.negotiation}
            disableClear={false}
            id="select-gro-negociacao"
          />
        </Col>

        <Col md={6} sm={12}>
          <Select.Async
            isDisabled={!hasPermissionToSelectTags}
            label="Tags"
            onSearch={search =>
              fetchSelectOptions('tags', {
                search,
                not_paginated: true,
              })
            }
            multiple
            value={data?.tags}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            onChange={event => setData(old => ({ ...old, tags: event }))}
            onInputChange={e => tagTreatment(e)}
            inputValue={inputTag}
            onCreateOption={handleTagCreate}
            creatable={!!hasPermissionToCreateTags}
            id="select-gro-tags"
          />
        </Col>
        <Col xs={12}>
          <TextArea
            label="Observações"
            resizable={false}
            value={data?.observations}
            onChange={event =>
              setData(old => ({
                ...old,
                observations: event.target.value,
              }))
            }
            loading={false}
            id="textarea-gro-observacoes"
          />
        </Col>
      </Row>
    </Card>
  );
}
