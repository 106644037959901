import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import api from 'services/api';

import Text from 'v3/components/Text';
import { useSnackbar } from 'v3/components/Snackbar';
import { useClient } from '../shared/context';
import SharedData from '../shared/Section';
import Inputs from '../shared/Activities/index';
import Editable from '../shared/Editable';

export default function Automations() {
  const client = useClient();
  const snackbar = useSnackbar();
  const params = useParams();

  const [isEditing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  async function saveData() {
    try {
      await api.put(`v3/client/${params.id}/type`, {
        client_type_id: client.data.clientType?.id || null,
      });
      client.setPrevClient(client.data);
      snackbar.show(<Text>Salvo com sucesso</Text>, { type: 'success' });
    } catch (error) {
      snackbar.show(<Text>Algo deu errado, tente novamente mais tarde!</Text>, {
        type: 'error',
      });
    } finally {
      setEditing(false);
      setLoading(false);
    }
  }

  return (
    <SharedData
      Title="Tipo da atividade"
      HeaderRightComponent={
        client?.canEditData && (
          <Editable
            isEditing={isEditing}
            setEditing={setEditing}
            onSave={saveData}
            isLoading={loading}
          />
        )
      }
      loading={client.isFetching}
    >
      {isEditing ? (
        <Inputs />
      ) : (
        <Row>
          <Col md={12}>
            <Text type="label" color="#464E5F">
              Tipo
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {client.data?.clientType?.name || 'Não informado'}
            </Text>
          </Col>
        </Row>
      )}
    </SharedData>
  );
}
