/* eslint-disable consistent-return */
/* eslint-disable import/no-unresolved */
import React, { useMemo } from 'react';

import { Row, Col } from 'react-bootstrap';

import Card, { DefaultLoadingBodyComponent } from 'v3/components/Card';
import Text from 'v3/components/Text';
import Truckpad from './Truckpad';
import Fretebras from './Fretebras';
import Cargon from './Cargon';

export default function Integrations({
  load,
  loadId,
  loadingCard,
  onGoingTravelStatusId,
}) {
  const integrationsToRender = useMemo(() => {
    const fretebras = load?.company?.credentials.find(
      integration => integration.slug === 'FRETEBRAS'
    ) && (
      <Col md={4} xs={12}>
        <Fretebras
          onGoingTravelStatusId={onGoingTravelStatusId}
          load={load}
          loadId={loadId}
        />
      </Col>
    );

    const truckPad = load?.company?.credentials.find(
      integration => integration.slug === 'TRUCKPAD'
    ) && (
      <Col md={4} xs={12}>
        <Truckpad
          onGoingTravelStatusId={onGoingTravelStatusId}
          load={load}
          loadId={loadId}
        />
      </Col>
    );

    const cargon = load?.company?.credentials.find(
      integration => integration.slug === 'CARGON'
    ) && (
      <Col md={4} xs={12}>
        <Cargon
          onGoingTravelStatusId={onGoingTravelStatusId}
          load={load}
          loadId={loadId}
        />
      </Col>
    );

    if (!fretebras && !truckPad && !cargon) {
      return (
        <Text color="#464E5F" type="regular">
          Você não possui nenhum integração habilitada
        </Text>
      );
    }

    return [truckPad, fretebras, cargon];
  }, [load]);

  return (
    <Col xs={12}>
      <Card
        loading={loadingCard}
        LoadingBodyComponent={() => (
          <DefaultLoadingBodyComponent linesCount={1} perLine={3} />
        )}
        className="mb-4"
        header={
          <Text color="#464E5F" type="header">
            Integrações
          </Text>
        }
      >
        <Row>{integrationsToRender}</Row>
      </Card>
    </Col>
  );
}
