/* eslint-disable import/no-unresolved */
import React, { useState, useEffect, useMemo, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { formatDateTime } from 'v3/utils/formatter';
import Lightbox from 'react-image-lightbox';
import { FaSearch, FaDownload, FaTrash } from 'react-icons/fa';
import api from 'services/api';
import Fallback from 'v3/components/Fallback';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import Card from 'v3/components/Card';
import Modal from 'v3/components/Modal';
import PDFView from 'v3/components/PDFView';
import StringBuilder from 'utils/StringBuilder';
import theme from 'v3/theme';
import { useSnackbar } from 'v3/components/Snackbar';
import { useTravel } from 'v3/pages/Travel';
import { usePermission } from 'hooks';
import { BlockedTravelContext } from 'contexts/BlockedTravelProvider';
import AttachmentModal from '../AttachmentModal';
import * as Styled from './styles';

export default function Attachments() {
  const { isTravelBlocked } = useContext(BlockedTravelContext);
  const params = useParams();
  const travel = useTravel();
  const [showModal, setShowModal] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [loading, setLoading] = useState(false);
  const hasPermissionToAdd = usePermission('UPLOAD_DOCUMENTOS_VIAGEM');

  async function fetchAttachments() {
    try {
      setLoading(true);
      const response = await api.get(`travel/${params.id}/attachments`);
      const travelAttachments = response.data.filter(
        attachment => attachment.type !== 'rotograma'
      );
      setAttachments(travelAttachments);
    } catch (error) {
      //
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchAttachments();
  }, []);

  return (
    <>
      <AttachmentModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        fetchAttachments={fetchAttachments}
      />
      <Card
        header={
          <Text color="#464E5F" type="header">
            Anexos
          </Text>
        }
        HeaderRightComponent={
          !!attachments?.length && (
            <Button
              onClick={() => setShowModal(true)}
              disabled={
                !travel.data?.driver ||
                !travel.data?.vehicle?.[0] ||
                !hasPermissionToAdd ||
                isTravelBlocked
              }
            >
              Adicionar
            </Button>
          )
        }
        loading={loading || travel.isLoading}
      >
        <>
          <Fallback
            component={
              <Col
                xs={12}
                className="align-items-center d-flex flex-column my-2"
              >
                <Text color={theme.colors.gray}>
                  Não há anexos cadastrados para essa viagem.
                </Text>
                <Button
                  disabled={
                    !travel.data?.driver ||
                    !travel.data?.vehicle?.[0] ||
                    !hasPermissionToAdd ||
                    isTravelBlocked
                  }
                  onClick={() => setShowModal(true)}
                  className="mt-3"
                >
                  Adicionar
                </Button>
              </Col>
            }
            fall={!attachments?.length}
          >
            <Row>
              {attachments?.map(attachment => {
                return (
                  <Col md={4} className="d-flex flex-column align-items-center">
                    <Attachment
                      attachment={attachment}
                      attachments={attachments}
                      fetchAttachments={fetchAttachments}
                    />
                    <Text
                      type="regular"
                      className="mt-2"
                      style={{ 'max-width': '75%', 'word-wrap': 'break-word' }}
                    >
                      {attachment.description}
                    </Text>
                  </Col>
                );
              })}
            </Row>
          </Fallback>
        </>
      </Card>
    </>
  );
}

function Attachment({ attachment, attachments, fetchAttachments }) {
  const [isHovering, setHovering] = useState(false);
  const [isShowingModal, setShowingModal] = useState(false);
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);

  const attachmentsWithoutPDF = useMemo(() => {
    return attachments.filter(item => {
      const filterPDF = item.full_path.split('.');
      if (filterPDF[filterPDF?.length - 1] === 'pdf') return null;
      return item;
    });
  }, [attachments]);

  const [lightbox, setLightbox] = useState({
    isOpen: false,
    attachments: attachmentsWithoutPDF,
    index: 0,
  });

  async function removeAttachment(document) {
    try {
      setLoading(true);
      await api.delete(`travel/attachment/${document.id}`);
      fetchAttachments();
    } catch (error) {
      snackbar.show('Não foi possível excluir o anexo', { type: 'error' });
    } finally {
      setLoading(false);
    }
  }

  const lightboxIndexes = useMemo(() => {
    const next = (lightbox.index + 1) % lightbox.attachments?.length;
    const previous =
      (lightbox.index + lightbox.attachments.length - 1) %
      lightbox.attachments.length;
    return { next, previous };
  }, [lightbox.index, lightbox.attachments]);

  function onClickPreview(id) {
    const index = id ? attachmentsWithoutPDF.findIndex(a => a.id === id) : 0;

    setLightbox(prev => ({
      ...prev,
      index,
      attachments: attachmentsWithoutPDF,
      isOpen: true,
    }));
  }

  function handleClick() {
    const fullPathArray = attachment.full_path.split('.');

    if (fullPathArray[fullPathArray?.length - 1] === 'pdf') {
      setShowingModal(true);
    } else {
      onClickPreview(attachment.id);
    }
  }

  function getImagePath(index, alwaysDisplay) {
    const isSameAsIndex =
      lightbox.index === lightboxIndexes.next &&
      lightbox.index === lightboxIndexes.previous;

    if (!alwaysDisplay && isSameAsIndex && lightbox.attachments.length === 1) {
      return null;
    }
    return lightbox.attachments[index]?.full_path;
  }

  function getDocumentMetadata() {
    const document = lightbox.attachments[lightbox.index];
    const type = document?.description || 'Sem descrição';
    const createdAt = formatDateTime(document?.created_at);

    return { type, createdAt };
  }

  const lightboxTitle = useMemo(() => {
    const builder = new StringBuilder(' ');
    const documentMetadata = getDocumentMetadata();
    builder
      .append(`${lightbox.index + 1}/${lightbox.attachments?.length}`)
      .append('|')
      .append(documentMetadata.type)
      .append(`(${documentMetadata.createdAt})`);

    return builder.build();
  }, [lightbox.index, lightbox.attachments]);

  return (
    <div>
      <Modal
        show={isShowingModal}
        dialogClassName="modal-90w"
        onHide={() => setShowingModal(false)}
        heading="Visualizar PDF"
        body={<PDFView file={attachment.full_path} />}
      />
      <div
        className="position-relative"
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        style={{
          width: 270,
          height: 160,
          background: `url(${
            attachment.full_path.split('.').pop() === 'pdf'
              ? '/images/default-doc.png'
              : attachment.full_path.split('.').pop() === 'xlsx'
              ? '/images/default-excel.png'
              : attachment.full_path.split('.').pop() === 'xls'
              ? '/images/default-excel.png'
              : attachment.full_path
          }) center center`,
          backgroundSize: '100%',
        }}
      >
        {lightbox.isOpen && (
          <Lightbox
            mainSrc={getImagePath(lightbox.index, true)}
            nextSrc={getImagePath(lightboxIndexes.next)}
            prevSrc={getImagePath(lightboxIndexes.previous)}
            onCloseRequest={() =>
              setLightbox(prev => ({ ...prev, isOpen: false }))
            }
            onMovePrevRequest={() =>
              setLightbox(prev => ({
                ...prev,
                index: lightboxIndexes.previous,
              }))
            }
            onMoveNextRequest={() =>
              setLightbox(prev => ({
                ...prev,
                index: lightboxIndexes.next,
              }))
            }
            imageTitle={lightboxTitle}
          />
        )}
        {isHovering && (
          <Styled.Backdrop>
            {attachment.full_path.split('.').pop() !== 'xlsx' &&
              attachment.full_path.split('.').pop() !== 'xls' && (
                <button type="button" onClick={handleClick}>
                  <FaSearch color="#fff" size={32} />
                </button>
              )}
            <a href={attachment.full_path} download>
              <FaDownload color="#fff" size={32} />
            </a>
            <Button
              variant="transparent"
              loading={loading}
              size={0}
              onClick={() => removeAttachment(attachment)}
            >
              <FaTrash size={32} />
            </Button>
          </Styled.Backdrop>
        )}

        <Styled.DateTime>
          <Text type="label" color="white">
            {formatDateTime(attachment.created_at)}
          </Text>
        </Styled.DateTime>
      </div>
    </div>
  );
}
