import React, { useState, useRef, useEffect } from 'react';

import { useHistory, Link } from 'react-router-dom';

import Truck from '@material-ui/icons/LocalShippingTwoTone';
import Close from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import theme from 'v3/theme';
import { Col } from 'react-bootstrap';
import useCurrentUser from '../../../hooks/useCurrentUser';
import useBot from '../../../hooks/useBot';
import api from '../../../services/api';
import dealAPI from '../../../services/apis/deal';

import Card from '../../../components/Card';
import Snackbar from '../../../components/Snackbar';
import Message from '../messages';
import TextComposer from '../textComposer';

import './style.css';

function Messenger({
  messages,
  setMessages,
  deal,
  setDeal,
  iniciateButton,
  setIniciateButton,
  getNegotiationMessages,
  requestPreLoad,
  setRequestPreLoad,
  requestPreLoadModal,
}) {
  const scrollRef = useRef(null);
  const history = useHistory();

  const [dialogInfo, setDialogInfo] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({
    display: false,
    type: 'error',
    message: '',
  });

  const shouldBlockActionsButton = deal?.pre_travel_id || deal?.travel_id;
  const shouldBlockActionsButtonMessage =
    deal?.pre_travel_id && !deal?.travel_id
      ? 'Não é possível negociar pré carga que já foi solicitada'
      : deal?.travel_id
      ? 'Não é possível negociar carga/pré carga que já está em viagem'
      : '';

  const user = useCurrentUser();
  const bot = useBot();

  useEffect(() => {
    if (scrollRef.current)
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
  }, [messages]);

  async function setDealNegotiator() {
    const userd = JSON.parse(localStorage.getItem('usuario'));
    const user_id = userd.id;
    const { driver_id, load_id, } = deal;

    try {
      await dealAPI.post(`deals/closeAll/${load_id}/${user_id}/${driver_id}`);
      setDeal({ ...deal, load_negotiator_id: user_id });
      setIniciateButton(false);
    } catch (err) {
      if (err.response) {
        const { data } = err.response;
        setSnackbar({
          type: 'error',
          message: data.message || 'Algo deu errado.',
          display: true,
        });
      } else if (err.message) {
        setSnackbar({
          type: 'error',
          message: err.message || 'Algo deu errado.',
          display: true,
        });
      }
    }
  }
  const renderMessages = () => {
    if (iniciateButton === true && deal.closed === null) {
      return (
        <Col className="text-center">
          <Button variant="outlined" onClick={() => setDealNegotiator()}>
            Iniciar Negociação
          </Button>
        </Col>
      );
    }
    if (messages) {
      let i = 0;
      const tempMessages = [];

      while (i < messages.length) {
        const previous = messages[i - 1];
        const current = messages[i];
        const next = messages[i + 1];
        const isMine =
          current.sender_id === JSON.parse(localStorage.getItem('usuario')).id;
        const currentMoment = moment(current.created_at);
        let prevBySameAuthor = false;
        let nextBySameAuthor = false;
        let startsSequence = true;
        let endsSequence = true;
        let showTimestamp = true;

        if (previous) {
          const previousMoment = moment(previous.created_at);
          const previousDuration = moment.duration(
            currentMoment.diff(previousMoment)
          );
          prevBySameAuthor = previous.sender_id === current.sender_id;

          if (prevBySameAuthor && previousDuration.as('hours') < 1) {
            startsSequence = false;
          }

          if (previousDuration.as('hours') < 1) {
            showTimestamp = false;
          }
        }

        if (next) {
          const nextMoment = moment(next.created_at);
          const nextDuration = moment.duration(nextMoment.diff(currentMoment));
          nextBySameAuthor = next.sender_id === current.sender_id;

          if (nextBySameAuthor && nextDuration.as('hours') < 1) {
            endsSequence = false;
          }
        }

        tempMessages.push(
          <Message
            key={i}
            isMine={isMine}
            startsSequence={startsSequence}
            endsSequence={endsSequence}
            showTimestamp={showTimestamp}
            data={current}
          />
        );

        i += 1;
      }
      return tempMessages;
    }
    return '';
  };

  const handleClick = event => {
    if (deal.closed) return;
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    let timeout = null;
    if (isDialogOpen === false && requestPreLoad?.id) {
      timeout = setTimeout(() => {
        requestPreLoadModal.open();
      }, 500);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isDialogOpen, requestPreLoad]);

  function attendLoad() {
    if (deal.pre_load) {
      setRequestPreLoad({
        id: deal.load_id,
        currency:deal.currency
      });
      setDialogOpen(false);
    } else {
      history.push(`/viagens/cadastrar/${deal.load_id}/${deal.driver_id}`);
    }
  }

  async function closeNegotiation() {
    try {
      await dealAPI.post(`deals/close/${deal.id}`);
      setSnackbar({
        display: true,
        type: 'success',
        message: `Negociação encerrada com sucesso!`,
      });
      // TODO: Refactor it to a callback instead a reassign
      // eslint-disable-next-line no-param-reassign
      deal.closed = 1;
      setDialogOpen(false);
    } catch (error) {
      setSnackbar({
        display: true,
        type: 'error',
        message: 'Houve um erro ao encerrar negociação.',
      });
    }
  }

  function executeMenuAction(type) {
    switch (type) {
      case 'attendLoad':
        attendLoad();
        break;
      case 'closeNegotiation':
        closeNegotiation();
        break;
      default:
        break;
    }
  }

  async function fetchAutomatedRiskManagers() {
    try {
      const response = await api.get('risk-manager/automated', {
        params: { has: 'queryAutomation' },
      });

      return response.data;
    } catch (ex) {
      return [];
    }
  }

  async function displayRiskManagers() {
    try {
      if (!bot) {
        throw Error('Não conseguimos identificar o BOT.');
      }

      const riskManagers = await fetchAutomatedRiskManagers();

      const message =
        'Selecione a gerenciadora de risco que deseja realizar a consulta.';

      const options = riskManagers.map(riskManager => {
        const metadata = JSON.stringify({
          risk_manager_id: riskManager.id,
          risk_manager_name: riskManager.name,
          driver_id: deal.driver_id,
          load_id: deal.load_id,
        });

        return {
          text: riskManager.name,
          action: 'QUERY_RISK_MANAGER',
          metadata,
        };
      });
      //await sendAutomatedMessage(message, options);
      getNegotiationMessages(deal);
    } catch (ex) {
      setSnackbar({
        display: true,
        type: 'error',
        message: ex.message,
      });
    }
  }

  return (
    <>
      <Snackbar
        open={snackbar.display}
        type={snackbar.type}
        message={snackbar.message}
        onClose={() => setSnackbar(false)}
      />
      <Dialog
        open={isDialogOpen}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogInfo.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogInfo.content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            outline={1}
            color="primary"
            onClick={() => {
              setDialogOpen(false);
              setRequestPreLoad(null);
            }}
          >
            Não
          </Button>
          <Button
            onClick={() => executeMenuAction(dialogInfo.type)}
            color="primary"
          >
            Sim
          </Button>
        </DialogActions>
      </Dialog>
      <Card style={{ padding: '0px' }}>
        {messages && (
          <Grid
            style={{ padding: '0px' }}
            container
            className={`message-list ${deal?.closed ? 'closed' : ''}`}
          >
            <Grid item md={12} xs={12} className="toolbar">
              <Typography>
                Negociação com{' '}
                {deal?.person_id ? (
                  <Link
                    to={`/motoristas/${deal?.person_id}`}
                    key={deal?.person_id}
                    target="_blank"
                  >
                    {deal?.driver_name}
                  </Link>
                ) : (
                  deal?.driver_name
                )}{' '}
                {`${deal?.driver_phone ? deal?.driver_phone : ''}`}
              </Typography>
              <Typography style={{ color: theme.colors.error }}>
                {` ${deal?.closed ? 'ENCERRADA' : ''}`}
              </Typography>
              <div title={shouldBlockActionsButtonMessage}>
                <Button
                  aria-controls="actions"
                  onClick={handleClick}
                  disabled={shouldBlockActionsButton}
                >
                  Ações
                </Button>
                <Menu
                  id="actions"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={() => {
                      if (deal?.closed) return;
                      setDialogOpen(true);
                      setDialogInfo({
                        title: 'Atender carga',
                        content: deal?.pre_load
                          ? 'Prosseguir para solicitação da pré carga?'
                          : 'Deseja realmente atender essa carga?',
                        type: 'attendLoad',
                      });
                      handleClose();
                    }}
                  >
                    <Truck />
                    &nbsp; Atender carga
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setDialogOpen(true);
                      setDialogInfo({
                        title: 'Encerramento de negociação',
                        content: 'Deseja realmente encerrar essa negociação?',
                        type: 'closeNegotiation',
                      });
                      handleClose();
                    }}
                  >
                    <Close />
                    &nbsp;Encerrar negociação
                  </MenuItem>
                  <MenuItem onClick={() => displayRiskManagers()}>
                    &nbsp;Consultar GR
                  </MenuItem>
                </Menu>
              </div>
            </Grid>
            <Grid item md={12} xs={12}>
              <Box px={2}>
                <div className="message-list-container" ref={scrollRef}>
                  {renderMessages()}
                </div>
              </Box>
            </Grid>
            <Grid container style={{ padding: '15px 50px 30px 50px' }}>
              <Grid item md={12} xs={12}>
                <TextComposer deal={deal} setMessages={setMessages} iniciateButton={iniciateButton}/>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Card>
    </>
  );
}

export default Messenger;
