import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { InfiniteScroll } from 'v3/components';
import TextArea from 'v3/components/TextArea';
import theme from 'v3/theme';

export const StyledCheckboxOptionsCol = styled(Col)`
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
`;

export const StyledChecklistInfoRow = styled(Row)`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;

  & > div:first-child {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;

export const StyledTextAreaCol = styled(Col)`
  display: flex;
  align-items: stretch;
`;

export const StyledTextArea = styled(TextArea)`
  height: inherit;
`;

export const StyledSortableRow = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  max-height: 500px;
  overflow-y: auto;
  scroll-behavior: smooth;
  :not(:hover)::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
  :not(:hover)::-webkit-scrollbar-track {
    opacity: 0;
  }
  :not(:hover)::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
  }
  :hover::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
  :hover::-webkit-scrollbar-track {
    background: ${theme.colors.light_gray};
    opacity: 0.5;
  }
  :hover::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border-radius: 10px;
  }
`;

export const SortableItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  cursor: default;
  border-top: 1px solid #f2f2f2;

  padding: 0.5rem 0;
  margin: 1rem 0;

  & > button:first-child {
    background: transparent;
    cursor: grab;
  }

  & > div.contentContainer {
    width: 100%;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;

    & > div:first-child {
      flex: 1;
    }

    & > div.questionOptions {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      min-width: 175px;

      & > header {
        display: flex;
        justify-content: space-between;

        & > button {
          background: transparent;
          border: 1px solid transparent;
          display: flex;
          align-items: center;

          & > svg {
            width: 1.5rem;
            height: 1.5rem;
            color: ${theme.colors.checklist_gray};
          }
        }

        & > button:focus {
          border: 1px solid gray;
        }
      }
    }
  }
`;

export const QuestionItemHeader = styled.header`
  display: flex;
  align-items: start;
  gap: 0.5rem;

  & > span:first-child {
    font-weight: 500;
    color: ${theme.colors.dark};
  }
`;

export const AnswersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const AnswerContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border: 1px solid ${theme.colors.secondary};
  border-radius: 6px;
`;

export const SubQuestionContainer = styled.div`
  margin-left: 1rem;
`;

export const StyledInfinityScroll = styled(InfiniteScroll)`
  margin-top: 1rem;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  scroll-behavior: smooth;
  :not(:hover)::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
  :not(:hover)::-webkit-scrollbar-track {
    opacity: 0;
  }
  :not(:hover)::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
  }
  :hover::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
  :hover::-webkit-scrollbar-track {
    background: ${theme.colors.light_gray};
    opacity: 0.5;
  }
  :hover::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border-radius: 10px;
  }
`;

export const AvailableQuestionsContainer = styled.div`
  border-bottom: 1px solid #f2f2f2;
  padding: 0.5rem;

  & > header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div {
      display: flex;
      align-items: center;
    }

    & > div > button:first-child {
      background: transparent;
      display: flex;
      align-items: center;
      & > svg {
        color: ${theme.colors.dark};
      }
    }

    & > button:last-child {
      border: 1px solid ${theme.colors.success};
      border-radius: 6px;
      background: transparent;
      display: flex;
      align-items: center;
      gap: 0.25rem;
      transition: background-color 0.7s;
      transition: color 0.7s;

      & > span {
        color: ${theme.colors.dark};
      }

      & > svg {
        color: ${theme.colors.success};
      }

      &:hover:not(:disabled) {
        background: ${theme.colors.success};
        & > span {
          color: ${theme.colors.white};
        }

        & > svg {
          color: ${theme.colors.white};
        }
      }

      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }
`;

export const StyledAvailableQuestionsDetailsCol = styled(Col)`
  display: flex;
  align-items: flex-start;
  gap: 1rem;
`;

export const SelectedContainer = styled.div`
  border-top: 1px solid #f2f2f2;
  padding: 0.5rem 0;
  margin: 1.5rem 0 1rem;
`;

export const SelectedQuestionsContainer = styled.div`
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-height: 200px;
  overflow-y: auto;
  scroll-behavior: smooth;
  :not(:hover)::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
  :not(:hover)::-webkit-scrollbar-track {
    opacity: 0;
  }
  :not(:hover)::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
  }
  :hover::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
  :hover::-webkit-scrollbar-track {
    background: ${theme.colors.light_gray};
    opacity: 0.5;
  }
  :hover::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border-radius: 10px;
  }
  & > div {
    padding: 0.5rem;

    display: flex;
    border-bottom: 1px solid #f2f2f2;
    justify-content: space-between;
    align-items: center;

    & > button:last-child {
      border: 1px solid ${theme.colors.danger};
      border-radius: 6px;
      background: transparent;
      display: flex;
      align-items: center;
      gap: 0.25rem;
      transition: background-color 0.7s;
      transition: color 0.7s;

      & > span {
        color: ${theme.colors.danger};
      }

      & > svg {
        color: ${theme.colors.danger};
      }

      &:hover:not(:disabled) {
        background: ${theme.colors.danger};
        & > span {
          color: ${theme.colors.white};
        }

        & > svg {
          color: ${theme.colors.white};
        }
      }

      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }
`;

export const StyledFooterRow = styled.footer`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
