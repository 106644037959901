import React, { useState, useMemo } from 'react';
import { FaSearch, FaDownload } from 'react-icons/fa';
import moment from 'moment';
import { formatDateTime, formatDate } from 'v3/utils/formatter';
import Modal from 'v3/components/Modal';
import PDFView from 'v3/components/PDFView';
import StringBuilder from 'utils/StringBuilder';
import Lightbox from 'react-image-lightbox';
import * as Styled from './styles';
import { driverDocumentTypesConst } from 'v3/utils/documentTypes';

export function Attachment({ attachment, attachments }) {
  const [isShowingModal, setShowingModal] = useState(false);

  const attachmentsWithoutPDF = useMemo(() => {
    return attachments.filter(item => {
      const filterPDF = item.full_path.split('.');
      if (filterPDF[filterPDF?.length - 1] === 'pdf') return null;
      return item;
    });
  }, [attachments]);

  const [lightbox, setLightbox] = useState({
    isOpen: false,
    attachments: attachmentsWithoutPDF,
    index: 0,
  });

  const lightboxIndexes = useMemo(() => {
    const next = (lightbox.index + 1) % lightbox.attachments?.length;
    const previous =
      (lightbox.index + lightbox.attachments.length - 1) %
      lightbox.attachments.length;
    return { next, previous };
  }, [lightbox.index, lightbox.attachments]);

  function onClickPreview(id) {
    const index = id ? attachmentsWithoutPDF.findIndex(a => a.id === id) : 0;

    setLightbox(prev => ({
      ...prev,
      index,
      attachments: attachmentsWithoutPDF,
      isOpen: true,
    }));
  }

  function handleClick() {
    const fullPathArray = attachment.full_path.split('.');

    if (fullPathArray[fullPathArray?.length - 1] === 'pdf') {
      setShowingModal(true);
    } else {
      onClickPreview(attachment.id);
    }
  }

  function getImagePath(index, alwaysDisplay) {
    const isSameAsIndex =
      lightbox.index === lightboxIndexes.next &&
      lightbox.index === lightboxIndexes.previous;

    if (!alwaysDisplay && isSameAsIndex && lightbox.attachments.length === 1) {
      return null;
    }
    return lightbox.attachments[index]?.full_path;
  }

  function getDocumentMetadata() {
    const document = lightbox.attachments[lightbox.index];
    const type = document?.description || 'Sem descrição';
    const createdAt = formatDateTime(document?.created_at);

    return { type, createdAt };
  }

  const lightboxTitle = useMemo(() => {
    const builder = new StringBuilder(' ');
    const documentMetadata = getDocumentMetadata();
    builder
      .append(`${lightbox.index + 1}/${lightbox.attachments?.length}`)
      .append('|')
      .append(documentMetadata.type)
      .append(`(${documentMetadata.createdAt})`);

    return builder.build();
  }, [lightbox.index, lightbox.attachments]);

  function isDateValid(value) {
    value = value?.split(/\D/)?.splice(0, 3)?.reverse();
    const date = moment(value?.join('/'), 'DD/MM/YYYY');
    return value?.length === 3 && date?.isValid() && date?.isAfter(moment());
  }

  return (
    <div>
      <Modal
        show={isShowingModal}
        size="lg"
        onHide={() => setShowingModal(false)}
        heading="Visualizar PDF"
        body={<PDFView file={attachment.full_path} />}
      />
      {lightbox.isOpen && (
        <Lightbox
          mainSrc={getImagePath(lightbox.index, true)}
          nextSrc={getImagePath(lightboxIndexes.next)}
          prevSrc={getImagePath(lightboxIndexes.previous)}
          onCloseRequest={() =>
            setLightbox(prev => ({ ...prev, isOpen: false }))
          }
          onMovePrevRequest={() =>
            setLightbox(prev => ({
              ...prev,
              index: lightboxIndexes.previous,
            }))
          }
          onMoveNextRequest={() =>
            setLightbox(prev => ({
              ...prev,
              index: lightboxIndexes.next,
            }))
          }
          imageTitle={lightboxTitle}
        />
      )}
      <Styled.LiItem hasExpiredAttachment={isDateValid(attachment?.due_date)}>
        <span>{driverDocumentTypesConst[attachment?.type]}</span>
        <span>Data de inserção: {formatDate(attachment?.created_at)}</span>
        <span>
          {isDateValid(attachment?.due_date)
            ? `Vencimento: ${formatDate(attachment?.due_date)}`
            : 'Documento vencido!'}
        </span>
        <span>
          <button type="button" onClick={handleClick}>
            <FaSearch color="#000" size={24} />
          </button>
          <a href={attachment?.full_path} download>
            <FaDownload color="#000" size={24} />
          </a>
        </span>
      </Styled.LiItem>
    </div>
  );
}
