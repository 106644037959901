/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { Col, Row } from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa';

import api from 'services/api';

import Card, { DefaultLoadingBodyComponent } from 'v3/components/Card';
import Text from 'v3/components/Text';
import Select from 'v3/components/Select';
import Radio from 'v3/components/Radio';
import Button from 'v3/components/Button';
import { useSnackbar } from 'v3/components/Snackbar';
import Input from 'v3/components/Input';
import IconButton from 'v3/components/Button/Icon';

import { ValidatorPaymentsCard } from '../../Register/validator';

const PaymentCards = ({ driver, getDriver, loadingCard }) => {
  const params = useParams();
  const snackbar = useSnackbar();

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);

  const [paymentsCard, setPaymentsCards] = useState();
  const [quantity, setQuantity] = useState([
    {
      corporation: null,
      card_number: null,
      active: true,
      standard_card: false,
      due_date: null,
      index: 0,
    },
  ]);

  useEffect(() => {
    async function fetchPaymentsCard() {
      try {
        const response = await api.get('driver/payment/cards');
        setPaymentsCards(response.data);
        return response.data;
      } catch (ex) {
        return [];
      }
    }

    fetchPaymentsCard();
  }, []);

  function handleChangeValue(index, key, value) {
    setQuantity(old =>
      old.map((item, idx) => {
        if (index === idx) {
          return { ...item, [key]: value };
        }

        if (key === 'standard_card') {
          return { ...item, standard_card: false };
        }

        return item;
      })
    );
  }

  function fillPaymentCards() {
    setQuantity(
      driver?.driverPayments?.map((card, idx) => ({
        corporation: card.corporation,
        card_number: card.card_number,
        active: card.active,
        standard_card: card.standard_card,
        due_date: card.due_date,
        index: idx,
      }))
    );
  }
  useEffect(() => {
    fillPaymentCards();
  }, [driver]);

  function handleDeleteCard(idx) {
    setQuantity(old => {
      return old.filter((_, index) => index !== idx);
    });
  }

  async function handleSubmit() {
    setLoading(true);
    setErrors({});

    try {
      const data = {
        id: params.id,
        data: quantity,
      };

      if (quantity.length > 0) {
        await ValidatorPaymentsCard.validate(quantity, {
          abortEarly: false,
        });
      }

      await api.put('driver/payment/cards/update', data);
      getDriver();

      snackbar.show(<Text> Dados alterados com sucesso! </Text>, {
        type: 'success',
      });
      setEditing(false);
    } catch (error) {
      if (error.inner) {
        error.inner.forEach(value =>
          setErrors(old => ({ ...old, [value.path]: value.message }))
        );
        snackbar.show(<Text> Preencha os campos restantes </Text>, {
          type: 'error',
        });
        return;
      }

      snackbar.show(
        <Text> Erro ao alterar o motorista, tente novamente mais tarde </Text>,
        {
          type: 'error',
        }
      );
    } finally {
      setLoading(false);
    }
  }
  return (
    <Card
      loading={loadingCard}
      LoadingBodyComponent={() => (
        <DefaultLoadingBodyComponent linesCount={1} perLine={1} />
      )}
      header={
        <>
          <Text color="#464E5F" type="header">
            Cartões
          </Text>
        </>
      }
      HeaderRightComponent={
        <>
          {editing ? (
            <div>
              <Button
                onClick={() => {
                  setEditing(false);
                  fillPaymentCards();
                }}
                variant="secondary"
                loading={loading}
                className="mr-2"
              >
                <Text color="dark" type="regular">
                  Cancelar
                </Text>
              </Button>
              <Button
                variant="success"
                loading={loading}
                onClick={handleSubmit}
              >
                <Text weight={500} type="regular">
                  Salvar
                </Text>
              </Button>
            </div>
          ) : (
            <Button variant="secondary" onClick={() => setEditing(true)}>
              <Text weight={500} type="regular">
                Editar
              </Text>
            </Button>
          )}
        </>
      }
    >
      <>
        {editing ? (
          <>
            {quantity.map((item, index) => (
              <>
                <Row className="form">
                  <Col md={8}>
                    <Select
                      label="Empresa do cartão"
                      value={quantity[index].corporation}
                      options={paymentsCard}
                      onChange={value => {
                        handleChangeValue(index, 'corporation', value);
                      }}
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.id}
                      error={errors[`[${index}].corporation`]}
                    />
                  </Col>

                  <Col md={4} className="mt-2 text-right">
                    <IconButton
                      style={{ marginTop: 3 }}
                      icon="FaTrash"
                      variant="error"
                      onClick={() => handleDeleteCard(index)}
                    />
                  </Col>

                  <Col md={6}>
                    <Input
                      label="Número do cartão"
                      value={item.card_number}
                      onChange={event => {
                        handleChangeValue(
                          index,
                          'card_number',
                          event.target.value
                        );
                      }}
                      masked
                      mask={[
                        /[0-9]/,
                        /\d/,
                        /\d/,
                        /\d/,
                        ' ',
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        ' ',
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        ' ',
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      error={errors[`[${index}].card_number`]}
                    />
                  </Col>

                  <Col md={6}>
                    <Input
                      label="Vencimento"
                      value={quantity[index].due_date}
                      onChange={event => {
                        handleChangeValue(
                          index,
                          'due_date',
                          event.target.value
                        );
                      }}
                      placeholder="MM/AAAA"
                      masked
                      guide={false}
                      mask={[
                        /[0-1]/,
                        /[0-9]/,
                        '/',
                        /[2]/,
                        /[0-0]/,
                        /[2-2]/,
                        /[1-9]/,
                      ]}
                      error={errors[`[${index}].due_date`]}
                    />
                  </Col>

                  <Col md={6}>
                    <Radio.Group
                      label="Ativo"
                      value={quantity[index].active}
                      horizontal
                      onChange={event => {
                        handleChangeValue(index, 'active', event.target.value);
                      }}
                    >
                      <Radio value>
                        <Text type="label">Sim</Text>
                      </Radio>
                      <Radio value={false}>
                        <Text color="dark" type="label">
                          Não
                        </Text>
                      </Radio>
                    </Radio.Group>
                  </Col>
                  <Col md={6} className="mt-1">
                    <Radio.Group
                      label="Padrão"
                      onChange={event => {
                        handleChangeValue(
                          index,
                          'standard_card',
                          event.target.value
                        );
                      }}
                      value={quantity[index].standard_card}
                      horizontal
                    >
                      <Radio value>
                        <Text type="label">Sim</Text>
                      </Radio>
                      <Radio value={false}>
                        <Text color="dark" type="label">
                          Não
                        </Text>
                      </Radio>
                    </Radio.Group>
                  </Col>
                </Row>
                <hr style={{ marginTop: 30, marginBottom: 30 }} />
              </>
            ))}
            <div md={3} className="mb-4">
              <Button
                variant="success"
                loading={loading}
                onClick={() => {
                  if (quantity.length >= 5) {
                    return;
                  }
                  setQuantity(old => [
                    ...old,
                    {
                      corporation: '',
                      card_number: '',
                      active: true,
                      standard_card: false,
                      due_date: '',
                      index: quantity?.length + 1,
                    },
                  ]);
                }}
              >
                <Text weight={600} color="white" type="regular">
                  Adicionar
                </Text>
              </Button>
            </div>
          </>
        ) : (
          <>
            {driver?.driverPayments?.length > 0 ? (
              <>
                {driver?.driverPayments?.map((item, index) => (
                  <>
                    <Row>
                      <Col md={8}>
                        <Text type="label">Empresa do cartão</Text>
                        <Text
                          type="regular"
                          color="gray"
                          as="p"
                          className="mt-2"
                        >
                          {item?.corporation?.name || 'Não informado'}
                        </Text>
                      </Col>

                      <Col md={6}>
                        <Text type="label">Número do cartão</Text>
                        <Text
                          type="regular"
                          color="gray"
                          as="p"
                          className="mt-2"
                        >
                          {item?.card_number || 'Não informado'}
                        </Text>
                      </Col>

                      <Col md={6}>
                        <Text type="label">Vencimento</Text>
                        <Text
                          type="regular"
                          color="gray"
                          as="p"
                          className="mt-2"
                        >
                          {item?.due_date || 'Não informado'}
                        </Text>
                      </Col>

                      <Col md={6}>
                        <Text type="label">Ativo</Text>
                        <Text
                          type="regular"
                          color="gray"
                          as="p"
                          className="mt-2"
                        >
                          {item?.active ? 'Sim' : 'Não'}
                        </Text>
                      </Col>

                      <Col md={6}>
                        <Text type="label">Padrão</Text>
                        <Text
                          type="regular"
                          color="gray"
                          as="p"
                          className="mt-2"
                        >
                          {item?.standard_card ? 'Sim' : 'Não'}
                        </Text>
                      </Col>
                    </Row>
                    <hr style={{ marginTop: 30, marginBottom: 30 }} />
                  </>
                ))}
              </>
            ) : (
              <Text type="regular" color="#494950" as="p" className="mt-2">
                Não informado
              </Text>
            )}
          </>
        )}
      </>
    </Card>
  );
};

export default PaymentCards;
