import React, { useState } from 'react';

import { Col, Row } from 'react-bootstrap';

import api from 'services/api';
import ModalRaw from 'v3/components/Modal';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import { useSnackbar } from 'v3/components/Snackbar';

import Inputs from '../../PaymentMethod';
import { useShippingCompany } from '../../context';

function Modal({ isOpen, onClose, saveData, setUpperLoading }) {
  const shippingCompany = useShippingCompany();
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('Método de pagamento obrigatório');

  function handleClose() {
    setUpperLoading(false);
    onClose();
  }

  async function handleSubmit() {
    setLoading(true);

    if (!shippingCompany?.data?.shippingCompanies?.payment?.id) {
      setError('Método de pagamento obrigatório');
      snackbar.show(<Text>Adicione um método de pagamento</Text>, {
        type: 'error',
      });
      setLoading(false);
      return;
    }

    try {
      await api.put(
        `v3/shipping-company/payment/${shippingCompany?.data?.id}`,
        {
          paymentMethod: shippingCompany.data?.shippingCompanies?.payment?.id,
        }
      );
      shippingCompany.setPrevShippingCompany(shippingCompany?.data);
      snackbar.show(<Text>Salvo com sucesso</Text>, { type: 'success' });
      saveData();
      setError('');
      setLoading(false);
      handleClose();
    } catch (err) {
      snackbar.show(<Text>Erro ao salvar</Text>, { type: 'error' });
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <ModalRaw
        show={isOpen}
        onHide={handleClose}
        heading={
          <Text type="header" color="dark">
            Métodos de pagamento
          </Text>
        }
        body={<Inputs error={error} />}
        footer={
          <Row>
            <Col md={6} xs={12}>
              <Button
                className="py-2"
                onClick={() => handleSubmit()}
                loading={loading}
              >
                <Text weight="500" type="regular">
                  Salvar
                </Text>
              </Button>
            </Col>
          </Row>
        }
      />
    </>
  );
}

export default Modal;
