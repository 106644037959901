import { Col } from 'react-bootstrap';
import styled from 'styled-components';

export const ButtonsColContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  gap: 8px;
  padding: 1rem 0;

  & > button {
    max-width: 12rem;
    width: inherit;
  }
`;
