import styled from 'styled-components';

import BadgeComponent from '../../../../components/Badge';
import ButtonComponent from '../../../../components/Button';

export const BadgeTag = styled(BadgeComponent)`
  padding: 5px 17px !important;
  background-color: #e3e4e7 !important;
  border-color: #e3e4e7 !important;
`;

export const Badge = styled(BadgeComponent)`
  padding: 5px 17px !important;
`;

export const Button = styled(ButtonComponent)`
  background-color: #e5eaee !important;
  border-color: #e5eaee !important;
`;
