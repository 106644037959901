/* eslint-disable import/no-unresolved */
import styled from 'styled-components';
import Text from 'v3/components/Text';
import Link from 'v3/components/Link';
import Badge from 'v3/components/Badge';

export const LinkTo = styled(Link)`
  overflow: auto;
  white-space: nowrap;
`;

export const Title = styled(Text)`
  font-weight: bold;
  width: 240px;
  word-wrap: break-word;
  margin-bottom: -5px;
`;

export const BadgeLink = styled(Badge)`
  transition: 0.5s;
  &:hover {
    background-color: #e1e1e1 !important;
  }
`;
