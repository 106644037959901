import React, { useState, useEffect, useMemo } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useParams } from 'react-router-dom';

import { useSnackbar } from 'v3/components/Snackbar';
import { validatePermission } from 'actions/index';

import Input from 'v3/components/Input';
import InputCurrency from 'v3/components/Input/Currency';
import Text from 'v3/components/Text';
import Radio from 'v3/components/Radio';
import Select from 'v3/components/Select';

import api from 'services/api';

import { useClient } from '../context';
import { FaInfoCircle } from 'react-icons/fa';

export default function ClientData() {
  const client = useClient();
  const params = useParams();
  const snackbar = useSnackbar();

  const [tagRegisterPermitted, setTagRegisterPermitted] = useState(
    validatePermission('CRIAR_TAGS')
  );
  const [takerRoles, setTakerRoles] = useState([]);

  useEffect(() => {
    if (!params.id)
      client.setData({
        personType: { name: 'Jurídica' },
        is_international: false,
        is_rewards_member: false,
        cgccpf: null,
        ie: null,
        social_number: null,
        fantasy_name: null,
        phone: null,
        email: null,
        takerRole: null,
        clientTags: [],
        credit_limit: null,
      });
    else if (client.data.is_international === null)
      client.setData({ is_international: false });
  }, []);

  async function handleTagCreate(value) {
    let tagValue = value;

    if (tagRegisterPermitted) {
      if (tagValue) {
        tagValue = tagValue.trim();
        if (tagValue.length >= 3) {
          try {
            const response = await api.post('tags', { name: tagValue });
            if (response.status === 200) {
              const newTag = {
                id: response.data.id,
                name: tagValue,
              };
              if (client.data.tags !== null)
                client.setData({ tags: [...client.data.tags, newTag] });
              else {
                client.setData({ tags: [newTag] });
              }
            }
          } catch (err) {
            // Handle exception
          }
        }
      }
    } else {
      snackbar.show(<Text>Usuário sem permissão para criar tags</Text>, {
        type: 'error',
      });
    }
  }

  const documentInput = useMemo(() => {
    if (client.data.is_international)
      return { label: 'Documento *', mask: false };
    if (client?.data?.personType?.name === 'Jurídica') {
      return {
        label: 'CNPJ *',
        mask: [
          /[0-9]/,
          /\d/,

          '.',
          /\d/,
          /\d/,
          /\d/,
          '.',
          /\d/,
          /\d/,
          /\d/,
          '/',

          /\d/,
          /\d/,
          /\d/,
          /\d/,
          '-',
          /\d/,
          /\d/,
        ],
      };
    }
    return {
      label: 'CPF *',
      mask: [
        /[0-9]/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ],
    };
  }, [client?.data?.personType, client?.data?.is_international]);

  useEffect(() => {
    async function getTakerRoles() {
      const response = await api.get('taker-roles');
      const roles = response.data.map(role => ({
        name: role.name,
        code: role.id,
      }));
      setTakerRoles(roles);
    }
    getTakerRoles();
  }, []);

  useEffect(() => {
    if (client?.data?.personType?.name === 'Física') {
      client.setData({ ie: null });
    }
  }, [client.data.personType]);

  async function fetchSelectOptions(url, params = {}) {
    try {
      const { data } = await api.get(url, { params });

      return data;
    } catch (err) {
      return [];
    }
  }

  return (
    <Row className="form">
      <Col xs={12} md={6}>
        <Radio.Group
          label="Tipo de pessoa *"
          onChange={({ target }) => {
            client.setData({
              personType: { ...client.data.personType, name: target.value },
              cgccpf: '',
            });
          }}
          value={client.data?.personType?.name}
          horizontal
        >
          <Radio value="Jurídica">
            <Text color="dark" type="label">
              Jurídica
            </Text>
          </Radio>
          <Radio value="Física">
            <Text color="dark" type="label">
              Física
            </Text>
          </Radio>
        </Radio.Group>
      </Col>

      <Col xs={12} md={6}>
        <Radio.Group
          label="Estrangeiro"
          onChange={({ target }) => {
            client.setData({
              is_international: target.value,
            });
          }}
          value={client.data?.is_international}
          horizontal
        >
          <Radio value>
            <Text color="dark" type="label">
              Sim
            </Text>
          </Radio>
          <Radio value={false}>
            <Text color="dark" type="label">
              Não
            </Text>
          </Radio>
        </Radio.Group>
      </Col>

      <Col xs={12}>
        <Radio.Group
          label="Estabelecimento"
          onChange={({ target }) => {
            client.setData({
              is_rewards_member: target.value,
            });
          }}
          value={client.data?.is_rewards_member}
          horizontal
        >
          <Radio value>
            <Text color="dark" type="label">
              Sim
            </Text>
          </Radio>
          <Radio value={false}>
            <Text color="dark" type="label">
              Não
            </Text>
          </Radio>
        </Radio.Group>
      </Col>

      <Col xs={12} md={6}>
        <Input
          label={documentInput.label}
          value={client?.data?.cgccpf}
          onChange={e => client.setData({ cgccpf: e.target.value })}
          masked
          mask={documentInput.mask}
          error={client.errors['data.cgccpf']}
          guide={false}
        />
      </Col>
      {client?.data?.personType?.name === 'Jurídica' && (
        <Col xs={12} md={6}>
          <Input
            label={
              <div className="d-flex align-items-center">
                <FaInfoCircle
                  title="Caso não possua colocar como ISENTO"
                  className="mr-2"
                  size={14}
                />
                Inscrição estadual *
              </div>
            }
            value={client?.data?.ie}
            onChange={e => client.setData({ ie: e.target.value })}
            error={client.errors['data.ie']}
            placeholder="ISENTO"
          />
        </Col>
      )}
      <Col xs={12}>
        <Input
          label={
            client?.data?.personType?.name === 'Jurídica'
              ? 'Razão social *'
              : 'Nome *'
          }
          value={client?.data?.social_name}
          onChange={e => client.setData({ social_name: e.target.value })}
          error={client.errors['data.social_name']}
        />
      </Col>
      {client?.data?.personType?.name === 'Jurídica' && (
        <Col xs={12} md={12}>
          <Input
            label="Nome fantasia *"
            value={client?.data?.fantasy_name}
            onChange={e => client.setData({ fantasy_name: e.target.value })}
            error={client.errors['data.fantasy_name']}
          />
        </Col>
      )}
      <Col xs={12} md={6}>
        <Input
          label="Telefone *"
          value={client.data?.phone}
          onChange={e => client.setData({ phone: e.target.value })}
          masked
          mask={
            !client.data.is_international
              ? [
                  '(',
                  /[0-9]/,
                  /\d/,
                  ')',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  '-',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]
              : false
          }
          error={client.errors['data.phone']}
          guide={false}
        />
      </Col>
      <Col xs={12} md={12}>
        <Input
          label="E-mail *"
          value={client?.data?.email}
          onChange={e => client.setData({ email: e.target.value })}
          error={client.errors['data.email']}
        />
      </Col>
      <Col xs={12} className="mb-3">
        <Select
          label="Contribuinte ICMS *"
          horizontal
          value={client.data?.takerRole}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.code}
          onChange={event => client.setData({ takerRole: event })}
          options={takerRoles}
          error={
            client.errors['data.takerRole.name'] ||
            client.errors['data.takerRole']
          }
        />
      </Col>
      <Col xs={12} className="mb-3">
        <Select.Async
          label="Tags"
          creatable
          onCreateOption={handleTagCreate}
          multiple
          value={client.data?.clientTags}
          horizontal
          modalHeading="Adicione uma tag"
          modalBodyTitle="Tags:"
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          onChange={event => client.setData({ clientTags: event })}
          onSearch={search =>
            fetchSelectOptions('tags', {
              search,
              not_paginated: true,
            })
          }
        />
      </Col>
      <Col xs={12}>
        <InputCurrency
          label={`Limite de Crédito Disponível`}
          value={client.data?.credit_limit}
          onChange={(_, value) => client.setData({ credit_limit: value })}
          placeholder="R$"
        />
      </Col>
    </Row>
  );
}
