import React, { useState } from 'react';

import { Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import api, { cooperplaceApi } from 'services/api';
import issuerApi from 'services/issuerApi';
import moment from 'moment';

import Text from 'v3/components/Text';
import Select from 'v3/components/Select';
import TextArea from 'v3/components/TextArea';
import Modal from 'v3/components/Modal';
import Button from 'v3/components/Button';
import Radio from 'v3/components/Radio';
import Input from 'v3/components/Input';

import { useSnackbar } from 'v3/components/Snackbar';
import { useTravel } from 'v3/pages/Travel';
import { DatePicker } from 'v3/components';

function Cancelled({ isOpen, onClose, setDuplicatedID }) {
  const snackbar = useSnackbar();
  const travel = useTravel();
  const params = useParams();

  const [errors, setErrors] = useState();
  const [loading, setLoading] = useState(false);

  const [cancelling, setCancelling] = useState();
  const [duplicate, setDuplicate] = useState(false);
  const [comment, setComment] = useState();
  const [new_due_date, setNewDueDate] = useState();

  const [selectedEmittedOptions, setSelectedEmittedOptions] = useState();
  const [targetTravel, setTargetTravel] = useState();

  const cancelOptions = [
    {
      label: 'Cliente sem limite de crédito',
      value: '2 - Cliente sem limite de crédito',
    },
    {
      label: 'Cliente recusou o veículo',
      value: '3 - Cliente recusou o veículo',
    },
    { label: 'Exigências de PGR', value: '4 - Exigências de PGR' },
    {
      label: 'Repassado para outra transportadora',
      value: '9 - Repassado para outra transportadora',
    },
    {
      label: 'Veículo reprovado no check list',
      value: '10 - Veículo reprovado no check list',
    },
    { label: 'Ajuste de programação', value: '11 - Ajuste de programação' },
    {
      label: 'Especificação do veículo',
      value: '12 - Especificação do veículo',
    },
    {
      label: 'Problemas de qualidade ou Falta de produto',
      value: '13 - Problemas de qualidade ou Falta de produto',
    },
    {
      label: 'Veículo reprovado no check list do cliente',
      value: '14 - Veículo reprovado no check list do cliente',
    },
    { label: 'Carga duplicada', value: '15 - Carga duplicada' },
    { label: 'Carga para teste', value: '16 - Carga para teste' },
    { label: 'Erro de inclusão', value: '17 - Erro de inclusão' },
    { label: 'Cancelada pelo cliente tomador', value: '18 - Cancelada pelo cliente tomador' },
  ];

  const emittedOptions = [
    {
      label: 'Nada',
      value: 1,
    },
    {
      label: 'Cancelar documentos relacionados a viagem',
      value: 2,
    },
    {
      label: 'Transferir documentos para outra viagem',
      value: 3,
    },
  ];

  async function handleEmitteds() {
    const cteArray = travel?.data?.emitteds;
    const mdfeArray = travel?.data?.mdfes;

    if (selectedEmittedOptions.value === 2) {
      try {
        cteArray.map(async res => {
          await issuerApi.put(`/cte/cancel/${res.id}`);
        });

        mdfeArray.map(async res => {
          await issuerApi.put(`/mdfe/cancel/${res.id}`);
        });
      } catch (err) {
        snackbar.show('Erro ao cancelar a documentação', {
          type: 'error',
        });
      }
    } else if (selectedEmittedOptions.value === 3) {
      try {
        cteArray.map(async res => {
          await api.post(`/issuer-documents/travel`, {
            attended_id: targetTravel,
            emitted_id: res.id,
            old_attended_id: travel.data.id,
          });
        });
      } catch (err) {
        snackbar.show('Erro ao cancelar a documentação', {
          type: 'error',
        });
      }
    }
  }

  async function handleSubmit() {
    if (travel.data?.emitteds?.length > 0) {
      if (!selectedEmittedOptions) {
        setErrors({ selectedEmittedOptions: 'Selecione uma das opções' });
        return;
      }

      if (selectedEmittedOptions.value === 3 && !targetTravel) {
        setErrors({ targetTravel: 'Adicione uma viagem válida' });
        return;
      }
    }

    if (!cancelling) {
      setErrors({ cancelling: 'Selecione uma das opções' });
      return;
    }

    if (duplicate && !new_due_date) {
      setErrors({ new_due_date: 'Adicione a nova data de vencimento' });
      return;
    }

    if (!comment || comment?.length < 30 || comment?.length > 255) {
      if (comment?.length > 255)
        setErrors({ comment: 'No máximo 255 caracteres' });
      else
        setErrors({
          comment: 'No mínimo 30 caracteres',
        });
      return;
    }

    setLoading(true);
    try {
      if (travel.data?.emitteds?.length > 0) {
        await handleEmitteds();
      }

      await api.put(`travel-data/updateStatus/${params.id}`, {
        value: 7,
        deleted_reason: cancelling?.value,
        deleted_comment: comment,
      });

      if (duplicate) {
        const response = await cooperplaceApi.post(`loads/${travel?.data?.load_id}/renew`, {
          dueDate: moment(new_due_date, 'DD/MM/YYYY HH:mm'),
        });
    

        setDuplicatedID(response.data.id);
      }

      if (duplicate) {
        snackbar.show(
          <Text className="text-center">
            Cancelamento e duplicação de carga realizados com sucesso!
          </Text>,
          {
            type: 'success',
            duration: 5000,
          }
        );
        return;
      }

      snackbar.show(
        <Text className="text-center">
          Cancelamento realizado com sucesso!
        </Text>,
        {
          type: 'success',
          duration: 5000,
        }
      );
    } catch (err) {
      let message = 'Não foi possível renovar a carga.'
      if (err.response?.status === 400 && err.response?.data?.message) {
        message = err.response?.data?.message;
      }
  
      snackbar.show(<Text>{message}</Text>, {
        type: 'error',
      });
    } finally {
      setLoading(false);
      onClose();
      travel.fetchTravel();
    }
  }

  return (
    <>
      <Modal
        show={isOpen}
        handleClose={() => onClose(true)}
        heading={
          <Text type="subheader" color="dark">
            Cancelamento de Viagem
          </Text>
        }
        body={
          <>
            <Row md={12} className="px-5 align-items-center">
              <Col md={12}>
                <Text type="regular" color="dark" weight="400" />
              </Col>

              <Col md={12} className="mb-2">
                <Select
                  options={cancelOptions}
                  placeholder=""
                  value={cancelling}
                  label="Motivo do cancelamento"
                  onChange={value => setCancelling(value)}
                  getOptionLabel={option => option.label}
                  getOptionValue={option => option.value}
                  error={errors?.cancelling}
                />
              </Col>
              <Col md={12} className="mb-2">
                <TextArea
                  label="Motivo"
                  placeholder="Mínimo 30 caracteres"
                  value={comment}
                  onChange={e => setComment(e.target.value)}
                  error={errors?.comment}
                />
              </Col>
            </Row>
            {travel.data.load?.due_date && (
              <>
                <Col md={12} className="mt-2 px-5 mb-3">
                  <Radio.Group
                    label={
                      <Text color="dark" type="label">
                        Renovar carga?
                      </Text>
                    }
                    onChange={({ target }) => {
                      setDuplicate(target.value);
                    }}
                    value={duplicate}
                    horizontal
                  >
                    <Radio value>
                      <Text color="dark" type="label">
                        Sim
                      </Text>
                    </Radio>
                    <Radio value={false}>
                      <Text color="dark" type="label">
                        Não
                      </Text>
                    </Radio>
                  </Radio.Group>
                </Col>

                <Col md={12} className="px-5 mb-5">
                  <DatePicker
                    disabled={!duplicate}
                    label="Data de vencimento da nova carga"
                    value={new_due_date}
                    onChange={event => setNewDueDate(event.target.value)}
                    error={errors?.new_due_date}
                    withTime
                  />
                </Col>
              </>
            )}
            {travel.data?.emitteds?.length > 0 && travel?.data?.mdfes && (
              <>
                <Col className="mb-5">
                  <Col md={12} className="px-5 mb-2">
                    <Select
                      label="Tratativa para os documentos emitidos"
                      value={selectedEmittedOptions}
                      options={emittedOptions}
                      getOptionLabel={option => option.label}
                      getOptionValue={option => option.value}
                      onChange={value => setSelectedEmittedOptions(value)}
                      error={errors?.selectedEmittedOptions}
                    />
                  </Col>
                  {selectedEmittedOptions?.value === 1 && (
                    <Col md={12} className="px-3 mb-5">
                      <Text type="label" color="red">
                        A documentação emitida será desvinculada da viagem.{' '}
                        <br />
                        Esta ação poderá ter efeitos colaterais.
                      </Text>
                    </Col>
                  )}
                  {selectedEmittedOptions?.value === 3 && (
                    <Col md={12} className="px-5 mb-5">
                      <Input
                        label="Viagem *"
                        value={targetTravel}
                        onChange={event => setTargetTravel(event.target.value)}
                        error={errors?.targetTravel}
                      />
                    </Col>
                  )}
                </Col>
              </>
            )}
            <Col md={12} className="mt-1 d-flex justify-content-center mb-2">
              <Button
                variant="success"
                loading={loading}
                onClick={() => handleSubmit()}
                className="py-2"
              >
                <Text color="white" weight="600">
                  Escolher
                </Text>
              </Button>
            </Col>
          </>
        }
      />
    </>
  );
}

export default Cancelled;
