import React, { useState } from 'react';
import { Button } from 'v3/components';
import { MarginLiberationAttachmentModal } from './modal';
import { Attachment } from './Attachment';

export function MarginLiberationAttachement({ file, setFile }) {
  const [openAttachmentModal, setOpenAttachmentModal] = useState(false);
  function handleCancel() {
    setOpenAttachmentModal(false);
  }
  return (
    <>
      <MarginLiberationAttachmentModal
        isOpen={openAttachmentModal}
        handleClose={handleCancel}
        file={file}
        setFile={setFile}
      />

      <div>
        {file?.length === 0 ? (
          <Button onClick={() => setOpenAttachmentModal(true)} size="sm">
            Incluir
          </Button>
        ) : (
          <Attachment attachment={file[0]} setFile={setFile} files={file} />
        )}
      </div>
    </>
  );
}
