import React, {useEffect} from 'react';
import { Card, Link, Text } from 'v3/components';
import { Origin } from './Origin';
import { Destinations } from './Destinations';
import { Divider } from 'antd';
import { useLoadGro } from '../../context';

export function LocationCard(){
  const {selectedPolicy} = useLoadGro()
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[]);
    return (
      <Card
        header={
          <Text color="#464E5F" type="header">
            Percurso
            {selectedPolicy?.id ? (
              <Link to={`/apolices/${selectedPolicy?.id}`} target="_blank">
                <Text className="ml-2" type="label" color="gray">
                  ver apólice
                </Text>
              </Link>
            ) : null}
          </Text>
        }
      >
        <Origin />
        <Divider />
        <Destinations />
      </Card>
    );
}