import styled from 'styled-components';

export const StyledCardItem = styled.p`
  margin: 10px 0px;
  color: #7a8994;

  &.nameClients {
    text-transform: uppercase;
    margin-top: 0px;
  }

  &.nameTitle {
    text-transform: uppercase;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 0px;
  }
  &.nameText {
    margin-top: 0px;
    margin-bottom: 10px;
  }
  &.itemGroup {
    span.itemLabel {
      font-weight: 700;
      margin-bottom: 5px;
      margin-right: 5px;
    }
  }
`;
