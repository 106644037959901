import moment from 'moment';
import * as formatter from './formatter';

function toRadius(degrees) {
  return degrees * (Math.PI / 180);
}
// It uses Haversine formula
export function getDistanceInKm(pointA = {}, pointB = {}) {
  if (
    !pointA.latitude ||
    !pointA.longitude ||
    !pointB.latitude ||
    !pointB.longitude
  ) {
    return -1;
  }
  // Radius of the earth in km
  const earthRadius = 6371;

  const latitudeDiff = toRadius(pointB.latitude - pointA.latitude);
  const longitudeDiff = toRadius(pointB.longitude - pointA.longitude);

  const a =
    Math.sin(latitudeDiff / 2) * Math.sin(latitudeDiff / 2) +
    Math.cos(toRadius(pointA.latitude)) *
      Math.cos(toRadius(pointB.latitude)) *
      Math.sin(longitudeDiff / 2) *
      Math.sin(longitudeDiff / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  // Distance in km
  const distance = earthRadius * c;

  return distance;
}

export function getDistanceInMeters(pointA = {}, pointB = {}) {
  const distanceInKm = getDistanceInKm(pointA, pointB);
  if (distanceInKm === -1) {
    return distanceInKm;
  }

  return distanceInKm * 1000;
}

export function isNotEmptyArray(array) {
  return array && array.length;
}

export { default as number } from './number';
export default { formatter };

export const pallets = new Array(24)
  .fill(0)
  .map((_item, index) => ({ id: index * 2, name: index * 2 }));

export const verifyDateExpired = (startDate, endDate) => {
  const normalizedStartDate = moment(startDate);
  const normalizedEndDate = moment(endDate);

  return normalizedStartDate.isBefore(normalizedEndDate);
};
