function PolylineItem({ markers, map, maps, pathEnabled, pathColor }) {
    const lineSymbol = {
      path: maps.SymbolPath.FORWARD_OPEN_ARROW,
      fillColor: '#103900',
      strokeColor: '#103900',
      strokeWeight: 1,
      scale: 2,
    };
    const geodesicPolyline = new maps.Polyline({
      path: markers,
      geodesic: true,
      strokeColor: pathColor,
      strokeWeight: 6,
      scale: 2,
  
      icons: [
        {
          fillColor: '#333',
          strokeColor: '#333',
          icon: lineSymbol,
          offset: '100%',
          repeat: '100px',
        },
      ],
    });
  
    if (pathEnabled) {
      geodesicPolyline.setMap(map);
    }
    if (!pathEnabled) {
      geodesicPolyline.setMap(null);
    }
  
    return null;
  }
  
  export default PolylineItem;
  