import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import Text from 'v3/components/Text';
import Select from 'v3/components/Select';
import Input from 'v3/components/Input';
import Button from 'v3/components/Button';
import Toggle from 'v3/components/Toggle';
import Modal from 'v3/components/Modal';

import api from 'services/api';
import useQuery from 'hooks/useQuery';

import { fetchWagonsBrands } from '../../shared/utils';
import { useSnackbar } from 'v3/components/Snackbar';
import { FaInfoCircle } from 'react-icons/fa';
import { fetchOwners } from 'utils/fetches';

export function ImplementFilter({
  handleClose,
  setQtdAdvancedFilters,
  showAdvancedFilters,
  fetchImplements,
}) {
  const history = useHistory();
  const snackbar = useSnackbar();
  const query = useQuery();
  const [loading] = useState(false);

  const [towingVehicle, setTowingVehicle] = useState('');
  const [plate, setPlate] = useState('');

  // const [status, setStatus] = useState(0);
  const [implementOptions, setImplementOptions] = useState([]);
  const [brand, setBrand] = useState(null);
  const [modelsOptions, setModelsOptions] = useState([]);
  const [implementType, setImplementType] = useState(null);
  const [ownerIdWagon, setOwnerIdWagon] = useState(null);
  const [model, setModel] = useState(null);

  async function handleChangeBrand(value) {
    try {
      if (value?.id) {
        const response = await api.get(`v3/models/${value.id}`);
        setModelsOptions(response.data);
        query.set('brandId', value?.id);
        query.set('brandName', value?.name);
      }
    } catch (error) {
      if (value?.id) snackbar.show('Erro ao buscar modelos', { type: 'error' });
    }
    if (value === null) {
      setModelsOptions([]);
      query.delete('brandId');
      query.delete('brandName');
      query.delete('model');
      setModel(null);
    }

    setBrand(value);
  }

  useEffect(() => {
    getQueryParams();
  }, []);

  useEffect(() => {
    async function fetchImplementTypes() {
      const response = await api.get(`vehicle-implement-types?app=${true}`);
      setImplementOptions(response.data);
    }
    fetchImplementTypes();
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      history.replace({ search: query.toString() });
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [towingVehicle, plate, brand, model, implementType, ownerIdWagon]);

  useEffect(() => {
    const isNotEmpty = value =>
      value !== null && value !== undefined && value !== '';

    const advancedFilters = {
      implementType,
      model,
      ownerIdWagon,
    };

    setQtdAdvancedFilters(
      Object.values(advancedFilters).filter(isNotEmpty).length
    );
  }, [implementType, model, ownerIdWagon]);

  function onSubmit(event) {
    event.preventDefault();
    fetchImplements();
  }

  async function getQueryParams() {
    // if (query.has('status')) {
    //   setStatus(Number(query.get('status')));
    // }

    if (query.has('plateVehicle')) {
      setTowingVehicle(query.get('plateVehicle'));
    }

    if (query.has('plateWagon')) {
      setPlate(query.get('plateWagon'));
    }

    if (query.has('implementType')) {
      const implementsTypes = await api.get(
        `vehicle-implement-types?app=${true}`
      );
      const vehicleTypeSelected = implementsTypes?.data?.find(
        item => item.id === Number(query.get('implementType'))
      );
      setImplementType(vehicleTypeSelected);
    }

    if (query.has('ownerDocumentWagon')) {
      const selectedOwner = await fetchOwners(query.get('ownerDocumentWagon'));
      setOwnerIdWagon(selectedOwner[0]);
    }

    if (query.has('brandId')) {
      const id = Number(query.get('brandId'));
      const modelOptionsForSelectedBrand = await api.get(`v3/models/${id}`);
      setBrand({ id: id, name: query.get('brandName') });
      setModelsOptions(modelOptionsForSelectedBrand.data);
    }

    if (query.has('model')) {
      setModel({ label: query.get('model'), id: Number(query.get('brandId')) });
    }
  }

  return (
    <>
      <Modal
        show={showAdvancedFilters}
        heading="Filtro Avançado"
        onHide={handleClose}
        body={
          <Row className="p-1 form" style={{ overflow: 'visible' }}>
            {/* <Col md={6} xs={12}>
              <Toggle
                buttonSize="sm"
                label="Inativos"
                defaultValue={status}
                value={status}
                options={[
                  { label: 'Ocultar', value: 0 },
                  { label: 'Mostrar', value: 1 },
                ]}
                onSelected={value => {
                  query.set('status', value);
                  setStatus(Number(value));
                }}
              />
            </Col> */}
            <Col md={6} xs={12}>
              <Select
                label="Tipo de implemento"
                value={implementType}
                options={implementOptions}
                getOptionLabel={option =>
                  `${option.name} - ${option.qty_axis} eixos`
                }
                getOptionValue={option => option.id}
                onChange={value => {
                  if (value) {
                    query.set('implementType', value?.id);
                    setImplementType(value);
                  } else {
                    query.delete('implementType');
                    setImplementType(value);
                  }
                }}
              />
            </Col>
            <Col md={6} xs={12}>
              <Select.Async
                label={'Proprietário do documento'}
                onSearch={fetchOwners}
                value={ownerIdWagon}
                getOptionLabel={option => option.label}
                getOptionValue={option => option.id}
                onChange={value => {
                  if (value) {
                    query.set('ownerIdWagon', value?.id);
                    query.set('ownerDocumentWagon', value?.cgccpf);
                    setOwnerIdWagon(value);
                  } else {
                    query.delete('ownerIdWagon');
                    query.delete('ownerDocumentWagon');
                    setOwnerIdWagon(value);
                  }
                }}
              />
            </Col>
            <Col md={6} xs={12}>
              <Select.Async
                label={
                  <div className="d-flex align-items-center">
                    <FaInfoCircle
                      title="Selecione uma marca para filtrar por modelo"
                      className="mr-2"
                      size={14}
                    />
                    Marca
                  </div>
                }
                onSearch={fetchWagonsBrands}
                value={brand}
                onChange={value => {
                  handleChangeBrand(value);
                }}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
              />
            </Col>
            <Col md={6} xs={12}>
              <Select
                label="Modelo"
                value={model}
                options={modelsOptions}
                getOptionLabel={option => option.label}
                getOptionValue={option => option.label}
                noOptionsMessageText="Selecione uma marca primeiro"
                onChange={value => {
                  if (value) {
                    query.set('model', value?.label);
                    setModel(value);
                  } else {
                    query.delete('model');
                    setModel(value);
                  }
                }}
              />
            </Col>
          </Row>
        }
        footer={
          <Row style={{ justifyContent: 'flex-end' }}>
            <Button
              variant="primary"
              loading={loading}
              onClick={() => {
                fetchImplements();
                handleClose();
              }}
            >
              <Text color="white" weight={500}>
                Filtrar
              </Text>
            </Button>
          </Row>
        }
      />
      <form onSubmit={onSubmit}>
        <Row className="filter">
          <Col lg={3} md={2} xs={12}>
            <Input
              label="Placa do implemento"
              labelColor="#fff"
              type="text"
              placeholder="Digite a placa..."
              value={plate}
              onChange={e => {
                if (e.target.value.trim()) {
                  query.set('plateWagon', e.target.value);
                  setPlate(e.target.value);
                } else {
                  query.delete('plateWagon');
                  setPlate('');
                }
              }}
            />
          </Col>
          <Col lg={3} md={4} xs={12}>
            <Input
              label="Placa do veículo puxador"
              labelColor="#fff"
              type="text"
              placeholder="Digite a placa..."
              value={towingVehicle}
              onChange={e => {
                if (e.target.value.trim()) {
                  query.set('plateVehicle', e.target.value);
                  setTowingVehicle(e.target.value);
                } else {
                  query.delete('plateVehicle');
                  setTowingVehicle('');
                }
              }}
            />
          </Col>
          <Col md={2} xs={12} className="vertical bottom">
            <Button
              variant="success"
              loading={loading}
              style={{ marginTop: 12 }}
              type="submit"
            >
              <Text type="regular" weight={500}>
                Filtrar
              </Text>
            </Button>
          </Col>
        </Row>
      </form>
    </>
  );
}
