import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import debounce from 'lodash.debounce';
import {
  Button,
  Select,
  Text,
  Tooltip,
  Input,
  DatePicker,
} from 'v3/components';
import { BsQuestionCircleFill } from 'react-icons/bs';
import { createNumberMask } from 'text-mask-addons';
import CurrencyInput from 'v3/components/CurrencyInput';
import { OccurrencesAditionalFieldsContext } from 'contexts/OccurrencesAditionalFields';
import IconButton from 'v3/components/Button/Icon';
import {
  BodyContainer,
  ButtonContainer,
  CenteredItem,
  HeadingContainer,
  StyledButon,
} from './styles';
import { CF_MASK, CF_SLUGS } from './utils';

const ExtraFieldComponent = React.memo(function ExtraFieldComponent({ index }) {
  const { availableFields, setExtraFields, extraFields } = useContext(
    OccurrencesAditionalFieldsContext
  );
  const currentField = useMemo(() => extraFields[index], [index, extraFields]);
  const [inputValue, setInputvalue] = useState(currentField.value);

  const debouncedSave = useCallback(
    debounce(nextValue => {
      setExtraFields(prevState => {
        const newExtraFields = [...prevState];
        newExtraFields[index] = {
          ...newExtraFields[index],
          value: nextValue || '',
          error: '',
        };
        return newExtraFields;
      });
    }, 100),
    []
  );

  const handleChangeInput = e => {
    const { value: nextValue } = e.target;
    if (nextValue.length < 50) {
      setInputvalue(nextValue);
      debouncedSave(nextValue);
    }
  };

  const handleDeleteField = useCallback(() => {
    setExtraFields(prevState => {
      const newExtraFields = [...prevState];
      newExtraFields.splice(index, 1);
      return newExtraFields;
    });
  }, [setExtraFields]);

  const handleChangeSelect = selected => {
    setExtraFields(prevState => {
      const newExtraFields = [...prevState];
      newExtraFields[index] = {
        field: {
          ...selected,
          error: '',
        },
        value: '',
      };
      return newExtraFields;
    });
  };

  const renderSpecificInput = () => {
    const isMasked = CF_SLUGS.includes(currentField?.field?.value)
    if (currentField.field.fieldType === 'datetime') {
      return (
        <Col xs={12}>
          <div style={{ marginTop: 20 }}>
            <DatePicker
              value={inputValue}
              onChange={handleChangeInput}
              label="Conteúdo"
              error={currentField?.error}
              withTime
            />
          </div>
        </Col>
      );
    }
    if (currentField.field.fieldType === 'decimal (10,2)') {
      const currencyMask = createNumberMask({
        prefix: 'R$',
        suffix: '',
        includeThousandsSeparator: true,
        thousandsSeparatorSymbol: '.',
        allowDecimal: true,
        decimalSymbol: ',',
        decimalLimit: 2,
        integerLimit: 10,
        allowNegative: false,
        allowLeadingZeroes: true,
      });
      return (
        <Col xs={12}>
          <div style={{ marginTop: 20 }}>
            <CurrencyInput
              value={inputValue}
              onChange={handleChangeInput}
              label="Conteúdo"
              mask={currencyMask}
              error={currentField?.error}
            />
          </div>
        </Col>
      );
    }
    return (
      <Col xs={12}>
        <div style={{ marginTop: 20 }}>
          <Input
            type={currentField.field.fieldType === 'int' ? 'number' : 'text'}
            value={inputValue}
            onKeyDown={e => {
              const invalidKeys = ['e', 'E', '+', '-'];
              if (
                currentField.field.fieldType === 'int' &&
                invalidKeys.includes(e.key)
              ) {
                e.preventDefault();
              }
            }}
            onChange={handleChangeInput}
            label="Conteúdo"
            error={currentField?.error}
            masked={isMasked}
            mask={isMasked ? CF_MASK : ''}
            guide={false}
          />
        </div>
      </Col>
    );
  };

  useEffect(() => {
    setInputvalue(currentField.value);
  }, [currentField]);

  return (
    <Row>
      <Col lg={5} md={5} sm={5} xs={12}>
        <CenteredItem>
          <Select
            label="Nome do item"
            onChange={handleChangeSelect}
            options={availableFields}
            value={currentField?.field}
            error={currentField?.field?.error}
          />
        </CenteredItem>
      </Col>
      <Col lg={6} md={5} sm={5} xs={10}>
        <Row>{renderSpecificInput()}</Row>
      </Col>
      <Col lg={1} md={2} sm={2} xs={2}>
        <CenteredItem>
          <IconButton
            style={{ marginTop: 20 }}
            onClick={handleDeleteField}
            variant="error"
            icon="FaTrash"
            color="white"
          />
        </CenteredItem>
      </Col>
    </Row>
  );
});

export function Body() {
  const { fields, extraFields, setExtraFields } = useContext(
    OccurrencesAditionalFieldsContext
  );

  const handleAddField = () => {
    setExtraFields(prevState => [
      ...prevState,
      { field: { label: '', value: '', fieldType: '', id: '' }, value: '' },
    ]);
  };

  return (
    <BodyContainer>
      {extraFields.map((field, index) => (
        <ExtraFieldComponent key={field.id} index={index} />
      ))}
      <ButtonContainer>
        <StyledButon
          disabled={fields.allAditionalFields.length <= extraFields.length}
          $color="#099B94"
          onClick={handleAddField}
        >
          Adicionar
        </StyledButon>
      </ButtonContainer>
    </BodyContainer>
  );
}

export function Heading() {
  return (
    <HeadingContainer>
      <Text type="regular" color="dark" weight="500">
        Campos extras da ocorrência
      </Text>
      <Tooltip
        placement="bottom"
        content={
          <Text>
            Campos adicionais que podem ser incluídos em ocorrências, além dos
            campos pré definidos.
          </Text>
        }
      >
        <BsQuestionCircleFill />
      </Tooltip>
    </HeadingContainer>
  );
}

export function Footer() {
  const { handleSubmitAditionalFields, isFetching } = useContext(
    OccurrencesAditionalFieldsContext
  );

  return (
    <Button loading={isFetching} onClick={handleSubmitAditionalFields}>
      Salvar
    </Button>
  );
}
