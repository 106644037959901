import debounce from 'lodash.debounce';
import { useCallback, useEffect, useState } from 'react';

export default function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    rootComponent: { height: 0, width: 0 },
    userScroll: { x: 0, y: 0 },
  });

  const debouncedSave = useCallback(
    debounce(() => {
      const rootElement = document.querySelector('body');
      setWindowSize(prevState => ({
        ...prevState,
        rootComponent: {
          height: rootElement?.offsetHeight || 0,
          width: rootElement?.offsetWidth || 0,
        },
      }));
    }, 300),
    []
  );

  function getSizeElement(mutationList) {
    mutationList.forEach(debouncedSave);
  }

  useEffect(() => {
    const handleResize = () => {
      const observer = new MutationObserver(getSizeElement);
      const targetNode = document.querySelector('body');
      const config = {
        attributes: true,
        subtree: true,
        childList: true,
      };
      observer.observe(targetNode, config);
    };

    const handleScroll = () => {
      setWindowSize(prevState => ({
        ...prevState,
        userScroll: {
          x: window.scrollX,
          y: window.scrollY,
        },
      }));
    };
    handleResize();
    handleScroll();

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return [windowSize, setWindowSize];
}
