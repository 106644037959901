import api from 'services/api';

async function fetchRouteData(url) {
  try {
    const { data } = await api.get(url);
    return data;
  } catch (ex) {
    return [];
  }
}

export async function fetchVehicles() {
  return fetchRouteData('vehicle-types');
}

export async function fetchLoadType() {
  return fetchRouteData('v3/load-types');
}

export async function fetchSelectOptions(url, params = {}) {
  try {
    const { data } = await api.get(url, { params });

    return data;
  } catch (err) {
    return [];
  }
}

export async function fetchMinimumFreightTableItem(params = {}) {
  try {
    const { data } = await api.get('v3/antt-data', { params });
    return data;
  } catch (err){
    return {};
  }
}