import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import api from 'services/api';
import Text from 'v3/components/Text';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'v3/components/Snackbar';
import { useClient } from '../shared/context';
import { validate } from '../shared/validator';
import SharedData from '../shared/Section';
import Inputs from '../shared/Settings/index';
import Editable from '../shared/Editable';

const SETTINGS_SLUGS = ['OBSDEFAULT', 'PRIORITY_FLEET_LOAD_DURATION'];

export default function Settings() {
  const client = useClient();
  const snackbar = useSnackbar();
  const params = useParams();
  const [isEditing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  async function saveData() {
    setLoading(true);

    const isValid = await validate('settings', client.data, client.setErrors);

    if (!isValid) {
      snackbar.show(<Text>Verifique os campos</Text>, {
        type: 'error',
      });
      setLoading(false);
      return;
    }

    try {
      const response = await api.put(`v3/client/${params.id}/settings`, {
        settings: client.data.settings,
      });

      const { settings } = response.data;

      SETTINGS_SLUGS.forEach(slug => {
        if (!settings?.find(item => item.type === slug)) {
          return settings?.push({
            person_id: response.data.id,
            type: slug,
            value: '',
            company_id: response.data.company_id,
          });
        }
      });

      client.setData({ settings });
      client.setPrevClient({
        ...client.data,
        settings,
      });
      snackbar.show(<Text>Salvo com sucesso</Text>, { type: 'success' });
    } catch (error) {
      snackbar.show(<Text>Erro ao salvar</Text>, { type: 'error' });
    } finally {
      setEditing(false);
      setLoading(false);
    }
  }

  return (
    <SharedData
      Title="Configuração"
      HeaderRightComponent={
        client?.canEditData && (
          <Editable
            isEditing={isEditing}
            setEditing={setEditing}
            onSave={saveData}
            isLoading={loading}
          />
        )
      }
      loading={client.isFetching}
    >
      {isEditing ? (
        <Inputs />
      ) : (
        <Row>
          <Col xs={12}>
            <Text type="label" color="dark">
              Observações da carga
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {client.data.settings?.find(item => item?.type === 'OBSDEFAULT')
                ?.value || '-'}
            </Text>
          </Col>
          <Col md={6}>
            <Text type="label" color="dark">
              Duração da frota prioritária
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {client.data.settings?.find(
                item => item?.type === 'PRIORITY_FLEET_LOAD_DURATION'
              )?.value || '-'}
            </Text>
          </Col>
        </Row>
      )}
    </SharedData>
  );
}
