import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Grid, Collapse } from '@material-ui/core/';

import Card from 'components/Card';

import axiosRetry from 'axios-retry';
import classNames from 'classnames';
import moment from 'moment';
import config from 'config';
import axios from 'axios';

import api from 'services/api';

import { ContentContacts } from './styles';

axiosRetry(axios, { retries: 10 });

const useStyles = makeStyles(theme => ({
  negrito: {
    fontWeight: 'bold',
  },

  sublinhado: {
    textDecoration: 'underline',
  },

  texto: {
    lineHeight: 1.4,
  },

  anchorSemCor: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 13.5,
  },

  anchorDDD: {
    fontSize: 12,
  },

  cursorPointer: {
    cursor: 'pointer',
  },
}));

export default function CardContato(props) {
  const classes = useStyles();
  const [read, setRead] = useState(props.contato.read);
  const [expanded, setExpanded] = React.useState(props.contato.read);

  function handleExpandCardContato() {
    // console.log('Abrindo: ' + expanded);

    if (!props.contato.read) {
      //  console.log('Lendo mensagem id:' + props.contato.id);
      updateStatus(props.contato);
      setExpanded(!expanded);
      setRead(!read);
      props.contato.read = !props.contato.read;
    }
  }

  // Regex
  function getJustNumbers(phoneNumber) {
    return phoneNumber.replace(/^(\+)|\D/g, '$1');
  }

  // Apenas DDD sem Número
  function getDDD(phoneNumber) {
    return getJustNumbers(phoneNumber).substring(0, 2);
  }

  // Apenas Número sem DDD
  function getNumber(phoneNumber) {
    return getJustNumbers(phoneNumber).substring(
      2,
      getJustNumbers(phoneNumber).lenght
    );
  }

  // Apenas DDD sem Número e marcarado
  function getDDDMasked(phoneNumberWithChars) {
    return '(' + getDDD(phoneNumberWithChars) + ')';
  }

  // Apenas Número sem DDD e mascarado
  function getNumberMasked(phoneNumberWithChars) {
    let phoneNumber = getNumber(phoneNumberWithChars);
    return phoneNumber.substring(0, 5) + '-' + phoneNumber.substring(5, 9);
  }

  async function updateStatus(contato) {
    try {
      let response = await axios.put(
        config.server + 'user-notifications/' + contato.id,
        { read: true }
      );

      if (response.status === 200) {
        //  console.log('contato id ' + contato.id + ' marcado como lido !');
      }
    } catch (ex) {
      console.log('Exception:' + ex);
    }
  }
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Card
            bordered
            bordercolor={!read ? 'success' : 'secondary_light'}
            style={{ marginBottom: '10px' }}
          >
            <Grid container justify={'space-between'}>
              <ContentContacts item>
                <ul>
                  <li>
                    {props.contato.title
                      ? props.contato.title
                      : 'Outras mensagens'}
                  </li>
                  <li
                    className={classNames({
                      [classes.negrito]: true,
                      [classes.sublinhado]: !expanded,
                    })}
                  >
                    {props.contato.name
                      ? props.contato.name
                      : 'Não identificado'}
                  </li>
                </ul>
              </ContentContacts>
              <ContentContacts item right>
                <ul>
                  <li>
                    {' '}
                    {props.contato.created_at
                      ? moment(props.contato.created_at).format(
                          'DD/MM/YYYY - HH:mm'
                        )
                      : ''}
                  </li>
                  <li hidden={!read}>&nbsp;</li>
                  <li hidden={read}>
                    <span
                      onClick={() => handleExpandCardContato()}
                      className={classNames({
                        [classes.sublinhado]: true,
                        [classes.anchorSemCor]: true,
                        [classes.cursorPointer]: true,
                      })}
                    >
                      Visualizar Mensagem
                    </span>
                  </li>
                </ul>
              </ContentContacts>
            </Grid>
            <Collapse
              in={expanded}
              timeout="auto"
              style={{ paddingBottom: '5px' }}
            >
              <p className={classes.texto}>{props.contato.message}</p>
              <br />
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={
                  'https://api.whatsapp.com/send?phone=55' +
                  getJustNumbers(props.contato.phonenumber)
                }
                className={classNames({
                  [classes.sublinhado]: true,
                  [classes.anchorSemCor]: true,
                })}
              >
                <span className={classes.anchorDDD}>
                  {getDDDMasked(props.contato.phonenumber)}
                </span>{' '}
                {getNumberMasked(props.contato.phonenumber)}
              </a>
              <br />
            </Collapse>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
