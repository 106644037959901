import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import api, { cooperplaceApi } from 'services/api';
import { Select } from 'v3/components';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import HeaderPowerBiLayout from 'v3/layouts/Application/HeaderPowerBiLayout';
import { useSnackbar } from 'v3/components/Snackbar';
import { usePermission } from 'hooks';
import { ButtonCancel, ColStyled, RowStyled } from './styles';

export default function UsersPanelManeger() {
  const snackbar = useSnackbar();
  const [workspaces, setWorkspaces] = useState([]);
  const [selectedWorkspace, setSelectedWorkspace] = useState('');
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [panels, setPanels] = useState([]);
  const [selectedPanel, setSelectedPanel] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [errors, setErrors] = useState({
    workspace: '',
    department: '',
    panel: '',
  });
  usePermission('GERENCIAMENTO_DE_USUARIOS_PAINEIS_BI', {
    redirect: true,
  });

  useEffect(() => {
    async function fetchData() {
      const workspacesResponse = await cooperplaceApi.get('workspaces/');
      setWorkspaces(workspacesResponse.data);
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedWorkspace) {
      fetchDepartments(selectedWorkspace.id);
    } else {
      setDepartments([]);
    }
  }, [selectedWorkspace]);

  async function fetchDepartments(workspaceId) {
    const response = await cooperplaceApi.get(
      `departaments/byWorkspaceId/${workspaceId}`
    );
    setDepartments(response.data);
  }

  useEffect(() => {
    if (selectedDepartment) {
      fetchPanels(selectedDepartment.id);
    } else {
      setPanels([]);
    }
  }, [selectedDepartment]);

  async function fetchPanels(departmentId) {
    const response = await cooperplaceApi.get(
      `carg-panel/byDepartamentId/${departmentId}`
    );
    setPanels(response.data);
    setSelectedPanel('');
  }

  async function getUsers(workspaceId, departmentId, panelId) {
    try {
      const usersPanelResponse = await cooperplaceApi.put(
        `carg-panel-users/users-bi/`,
        {
          workspaceId,
          departmentId,
          panelId,
        }
      );
      setSelectedUsers(
        usersPanelResponse.data.map(user => ({ ...user, id: user.user_id }))
      );
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (selectedPanel) {
      getUsers(
        selectedWorkspace?.id,
        selectedDepartment?.id,
        selectedPanel?.id
      );
    } else {
      setSelectedUsers([]);
    }
  }, [selectedPanel]);

  async function onSearchUser(search) {
    try {
      const response = await api.get('user/name', {
        params: { user: search, isControlTower: true },
      });

      return response.data;
    } catch (error) {
      return [];
    }
  }

  async function handleSubmit() {
    try {
      const formHasErrors = validateForm();
      if (formHasErrors) {
        return;
      }
      let payload = [];
      let userIds = [];
      let idPanel = null;
      if (selectedUsers == null) {
        idPanel = selectedPanel.id;
      } else {
        userIds = selectedUsers?.map(user => user.id);
        payload =
          userIds?.map(userId => ({
            userId,
            panelId: selectedPanel.id,
          })) || [];
      }
      let response = '';
      if (idPanel) {
        response = await cooperplaceApi.post(
          `/carg-panel-users/insert-panel-users/`,
          { id: idPanel }
        );
      } else {
        response = await cooperplaceApi.post(
          `/carg-panel-users/insert-panel-users/`,
          payload
        );
      }
      if (response.status === 201) {
        snackbar.show(<Text>Successo ao cadastrar usuário do painel</Text>, {
          type: 'success',
        });
        cleanForm();
      }
    } catch (error) {
      snackbar.show(<Text>Erro ao cadastrar usuário do painel</Text>, {
        type: 'error',
      });
      console.error('Erro ao inserir os dados:', error.message);
    }
  }

  function cleanForm() {
    setSelectedWorkspace('');
    setSelectedDepartment('');
    setSelectedPanel('');
    setSelectedUsers([]);
  }

  function cleanPartialForm() {
    setSelectedDepartment('');
    setSelectedPanel('');
    setSelectedUsers([]);
  }

  function validateForm() {
    let hasErrors = false;

    if (!selectedWorkspace) {
      setErrors(prevState => ({
        ...prevState,
        workspace: 'Campo Workspace obrigatório',
      }));
      hasErrors = true;
    }
    if (!selectedDepartment) {
      setErrors(prevState => ({
        ...prevState,
        department: 'Campo Departamento obrigatório',
      }));
      hasErrors = true;
    }
    if (!selectedPanel) {
      setErrors(prevState => ({
        ...prevState,
        panel: 'Campo Painel obrigatório',
      }));
      hasErrors = true;
    }
    return hasErrors;
  }

  return (
    <>
      <HeaderPowerBiLayout
        title="Gerenciamento de Usuários por painél"
        contentFluid
        isOld
        onlyCenterComponent
        fontSize="20px"
      />
      <RowStyled style={{ margin: 0, marginTop: 20 }} md={12}>
        <Col md={2} />
        <Col md={8}>
          <Text
            color="#969494"
            weight="400"
            fontSize="25px"
            className="d-flex text-center justify-content-center"
          >
            Gerenciamento de usuários por painél
          </Text>
          <RowStyled lg={12} md={12} xs={12}>
            <Col md={4}>
              <Select
                modalHeading="Selecione um Workspace"
                modalBodyTitle="Workspace:"
                label="WORKSPACE"
                value={selectedWorkspace}
                options={workspaces}
                error={errors.workspace}
                getOptionLabel={option => option.workspaceName}
                getOptionValue={option => option.id}
                onChange={value => {
                  setSelectedWorkspace(value);
                  cleanPartialForm();
                }}
                horizontal
              />
            </Col>
            <Col md={4}>
              <Select
                modalHeading="Selecione um Departamento"
                modalBodyTitle="Departamento:"
                label="DEPARTAMENTO"
                error={errors.department}
                value={selectedDepartment}
                options={departments}
                getOptionLabel={option => option.departamentName}
                getOptionValue={option => option.id}
                onChange={value => setSelectedDepartment(value)}
                horizontal
              />
            </Col>
            <Col md={4}>
              <Select
                modalHeading="Selecione um Painel"
                modalBodyTitle="Painel:"
                label="PAINEL"
                error={errors.panel}
                value={selectedPanel}
                options={panels}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                onChange={value => setSelectedPanel(value)}
                horizontal
              />
            </Col>
          </RowStyled>
          <Row lg={12} md={12} xs={12}>
            <Col md={12}>
              <Select.Async
                modalHeading="Usuários selecionados"
                modalBodyTitle="Usuários selecionados:"
                label="USUÁRIOS SELECIONADOS"
                value={selectedUsers}
                getOptionLabel={option => option.username}
                getOptionValue={option => option.id}
                onChange={value => setSelectedUsers(value)}
                onSearch={search => onSearchUser(search)}
                multiple
                horizontal
              />
            </Col>
          </Row>
          <Row md={12}>
            <Col md={6} />
            <ColStyled md={6}>
              <ButtonCancel variant="secondary" onClick={() => cleanForm()}>
                <Text weight={500} color="gray" type="regular">
                  Cancelar
                </Text>
              </ButtonCancel>
              <Button variant="success" onClick={handleSubmit}>
                <Text weight={500} color="white" type="regular">
                  Salvar
                </Text>
              </Button>
            </ColStyled>
          </Row>
        </Col>
        <Col md={2} />
      </RowStyled>
    </>
  );
}
