import React from 'react';

import { Link } from 'react-router-dom';

import { Col } from 'react-bootstrap';

import CardRaw from 'v3/components/Card';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import Badge from 'v3/components/Badge';
import Tooltip from 'v3/components/Tooltip';

function Card({ product }) {
  return (
    <Col xs={12} md={6}>
      <CardRaw
        header={
          <div>
            <Tooltip
              content={<Text type="label">{product?.name}</Text>}
              placement="top"
            >
              <Text transform="uppercase" color="dark" type="regular">
                {product?.name.split(' ')[0]} {product?.name.split(' ')[1]}{' '}
                {product?.name.split(' ')[2]}{' '}
                {product?.name.split(' ')[3] && '...'}{' '}
              </Text>
            </Tooltip>
            {product?.ncm ? (
              <Badge pill className="ml-3 py-1 px-3" variant="secondary">
                <Text type="label" color="dark">
                  {product?.ncm}
                </Text>
              </Badge>
            ) : null}
          </div>
        }
        HeaderRightComponent={
          <Link to={`/produtos/${product.id}`}>
            <Button className="py-2">
              <Text color="white" type="regular" weight="500">
                Detalhes
              </Text>
            </Button>
          </Link>
        }
      >
        <Text color="dark" type="regular">
          {product?.unit_measure === 'U' && 'em unidades'}
          {product?.unit_measure === 'M' && 'em metros'}
          {product?.unit_measure === 'L' && 'em litros'}
          {product?.unit_measure === 'K' && 'em quilos'}
        </Text>
      </CardRaw>
    </Col>
  );
}

export default Card;
