import cooperplaceApi from 'services/apis/cooperplace';

export async function getRate(currency) {
  try {
    const response = await cooperplaceApi.get('/getRate', {
      params: {
        date: new Date(),
        currency: currency,
      },
    });
    return response.data;
  } catch (error) {
    return {
      error: true,
      message:
        'Não foi possível obter a cotação atual. Tente novamente mais tarde.',
    };
  }
}
