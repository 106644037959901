import React, { useMemo } from 'react';

import Text from 'v3/components/Text';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import moment from 'moment';
import { Attachment } from 'v3/components/Attachment';

export default function CnhData({ driver }) {
  function formatDate(date) {
    if (date) return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
    return 'Sem data';
  }

  const hasMopp = useMemo(() => {
    const { mopp, mopp_date_init, mopp_description, mopp_expiration } = driver;

    if (mopp || mopp_date_init || mopp_description || mopp_expiration) {
      return true;
    }

    return false;
  }, [driver]);

  return (
    driver && (
      <Row>
        <Col xs={12}>
          <Text type="label">CNH estrangeira</Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {driver.cnh_is_foreign ? 'Sim' : 'Não'}
          </Text>
        </Col>
        <Col lg={6} md={6} xs={12}>
          <Text type="label">CNH</Text>

          <Text type="regular" color="gray" as="p" className="mt-2">
            {driver.cnh_number ? driver.cnh_number : 'Sem número'}
          </Text>
        </Col>

        <Col lg={6} md={6} xs={12}>
          <Text type="label">Categoria</Text>

          <Text type="regular" color="gray" as="p" className="mt-2">
            {driver.cnh_type ? driver.cnh_type : 'Sem categoria'}
          </Text>
        </Col>

        <Col xs={12}>
          <Text type="label">Cidade emissão CNH</Text>

          <Text type="regular" color="gray" as="p" className="mt-2">
            {driver.cnh_city
              ? `${driver.cnh_city.name} - ${driver.cnh_city.province.uf}`
              : 'Sem cidade'}
          </Text>
        </Col>
        <Col xs={12}>
          <Text type="label">Chave da CNH</Text>

          <Text type="regular" color="gray" as="p" className="mt-2">
            {driver && driver.cnh_key
              ? `${driver.cnh_key}`
              : 'Nenhuma chave de CNH cadastrada'}
          </Text>
        </Col>
        <Col lg={6} md={6} xs={12}>
          <Text type="label">1º Habilitação</Text>

          <Text type="regular" color="gray" as="p" className="mt-2">
            {formatDate(driver.first_cnh_date)}
          </Text>
        </Col>
        <Col md={6} xs={12}>
          <Text type="label">Data de emissão CNH</Text>

          <Text type="regular" color="gray" as="p" className="mt-2">
            {formatDate(driver.cnh_dispatch_date)}
          </Text>
        </Col>
        <Col lg={6} md={6} xs={12}>
          <Text type="label">Validade da habilitação</Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {moment(driver.cnh_exp_date, 'YYYY-MM-DD').format('DD/MM/YYYY')}
          </Text>
        </Col>
        <Col xs={12}>
          <Row>
            {hasMopp && (
              <>
                <Col lg={6} md={6} xs={12}>
                  <Text type="label">Descrição do Mopp</Text>

                  <Text type="regular" color="gray" as="p" className="mt-2">
                    {driver.mopp_description}
                  </Text>
                </Col>

                <Col lg={6} md={6} xs={12}>
                  <Text type="label">Validade do Mopp</Text>

                  <Text type="regular" color="gray" as="p" className="mt-2">
                    {formatDate(driver.mopp_expiration)}
                  </Text>
                </Col>

                <Col lg={6} md={6} xs={12}>
                  <Text type="label">Validade do Mopp</Text>

                  <Text type="regular" color="gray" as="p" className="mt-2">
                    {formatDate(driver?.mopp_date_init)}
                  </Text>
                </Col>
              </>
            )}
          </Row>
        </Col>
        <Col lg={4} md={4} xs={12}>
          <Text type="label">Exclusivo</Text>

          <Text type="regular" color="gray" as="p" className="mt-2">
            {driver && driver.exclusive ? 'Sim' : 'Não'}
          </Text>
        </Col>
        <Col lg={4} md={4} xs={12}>
          <Text type="label">Autônomo</Text>

          <Text type="regular" color="gray" as="p" className="mt-2">
            {driver && driver.is_autonomous ? 'Sim' : 'Não'}
          </Text>
        </Col>
        <Col lg={4} md={4} xs={12}>
          <Text type="label">Proprietário</Text>

          <Text type="regular" color="gray" as="p" className="mt-2">
            {driver && driver.is_owner ? 'Sim' : 'Não'}
          </Text>
        </Col>

        <Col xs={12} className="mb-3">
          <Text type="label">Upload de CNH</Text>
          <Row>
            <Col lg={6} md={6} xs={12} className="text-center mt-3">
              {driver.frontCnh ? (
                <Attachment
                attachment={{
                  id: driver.frontCnh.id,
                  full_path: driver.frontCnh.full_path,
                  due_date: driver.created_at,
                }}
                attachments={[driver.frontCnh]}
                loading={false}
              />
              ) : (
                <Text type="label" color="gray">
                  Sem foto da CNH
                </Text>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    )
  );
}
