import styled, { css } from 'styled-components';

const StyledFab = styled.button`
  transition: opacity 0.2s ease;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1;
  border: 2px solid rgb(33, 150, 243);
  color: rgb(33, 150, 243);
  height: 36px;
  border-radius: 18px;
  /*background-color: #fff;*/
  background-color: rgb(33, 150, 243);
  color: #fff;

  /*
  position: 'fixed',
  bottom: '20px',
  right: '20px',
  zIndex: '1',
  border: '2px solid rgb(33, 150, 243)',
  paddingRight: '20px',
  paddingLeft: '20px',
  color: '#FFF',
  height: '36px',
  borderRadius: '18px',
  backgroundColor: 'rgb(33, 150, 243)'*/

  &:hover {
    /*opacity: 0.8;*/
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;

      opacity: 0.7;

      &:hover {
        opacity: 0.7;
      }
    `}

  ${({ outline }) =>
    outline &&
    css`
      /* background-color: transparent;*/

      color: ${({ theme }) => theme.colors.secondary};
      border: 2px solid ${({ theme, color }) => theme.colors.secondary};
      transition: background-color 0.3s ease;

      &:hover {
        background-color: ${({ theme, color }) => theme.colors[color]}11;
      }
    `}
`;

export default StyledFab;
