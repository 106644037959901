/* eslint-disable import/no-unresolved */
/* eslint-disable consistent-return */
import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import api from 'services/api';
import { validatePermission } from 'actions/index';
import { Row, Col } from 'react-bootstrap';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import Input from 'v3/components/Input';
import Select from 'v3/components/Select';
import Radio from 'v3/components/Radio';
import { useSnackbar } from 'v3/components/Snackbar';
import Card, { DefaultLoadingBodyComponent } from 'v3/components/Card';
import { BlockedTravelContext } from 'contexts/BlockedTravelProvider';

function Monitoring({
  load,
  getLoad,
  loadingCard,
  onGoingTravelStatusId,
  editingMonitoringData,
  setEditingMonitoringData,
  errors,
  setErrors,
  isDisabledGROAnalysis,
  updatedTravelData,
}) {
  const { isTravelBlocked } = useContext(BlockedTravelContext);
  const snackbar = useSnackbar();
  const params = useParams();

  const [loading, setLoading] = useState(false);
  const [baitNumber, setBaitNumber] = useState('');
  const [withBait, setWithBait] = useState();
  const [isEscorted, setIsEscorted] = useState();
  const [tracked, setTracked] = useState();
  const [riskManager, setRiskManager] = useState([]);

  const [hasPermissionToEdit, setPermissionToEdit] = useState();
  useState(() => {
    setPermissionToEdit(validatePermission('EDITAR_CARGAS'));
  }, []);

  async function setLoadData() {
    try {
      setLoading(true);
      setTracked(load.crawledLoad);
      setBaitNumber(load.baitNumber);
      setWithBait(load.withBait);
      setIsEscorted(load.isEscorted);
      setRiskManager(load?.riskManagers?.length > 0 ? load?.riskManagers : []);
    } catch (error) {
      snackbar.show(<Text>Algo deu errado, tente novamente.</Text>, {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (load) setLoadData();
  }, [load]);

  function validate() {
    const errors = { hasErrors: false };
    try {
      if (tracked && !riskManager.length) {
        errors.riskManager = 'Informe o GR para rastreamento';
        errors.hasErrors = true;
      }
    } catch (error) {
      return errors;
    }

    return errors;
  }

  async function handleSubmit() {
    const err = validate();
    setErrors(err);

    if (err.hasErrors) {
      snackbar.show(<Text>Verifique os campos obrigatórios</Text>, {
        type: 'error',
      });
      return;
    }

    try {
      setLoading(true);

      const data = {
        crawled_load: tracked,
        risk_manager_ids:
          tracked && riskManager ? riskManager.map(risk => risk.id) : null,
        is_escorted: isEscorted,
        with_bait: withBait,
        bait_number: withBait ? baitNumber : null,
      };
      await api.put(`v3/load/monitoring/${params.id}`, data);

      setEditingMonitoringData(false);
      snackbar.show(<Text>Carga editada com sucesso!</Text>, {
        type: 'success',
      });
    } catch (error) {
      if (error) {
        snackbar.show(<Text>{error.message}</Text>, {
          type: 'error',
        });
        setEditingMonitoringData(true);
        return;
      }
    } finally {
      getLoad();
      setLoading(false);
    }
  }

  const trueVar = true;

  async function fetchRiskManager(search) {
    try {
      const response = await api.get('risk-manager-for-select?', {
        params: { search },
      });
      return response.data;
    } catch (error) {
      return [];
    }
  }

  return (
    <Card
      loading={loadingCard}
      LoadingBodyComponent={() => (
        <DefaultLoadingBodyComponent linesCount={7} perLine={2} />
      )}
      header={
        <Text color="#464E5F" type="header">
          Monitoramento
        </Text>
      }
      HeaderRightComponent={
        hasPermissionToEdit && (
          <div>
            {editingMonitoringData && (
              <Button
                variant="secondary"
                loading={loading}
                className="mr-2"
                onClick={() => setEditingMonitoringData(false)}
                id="button-cancelar-card-monitoramento"
              >
                <Text color="dark" type="regular">
                  Cancelar
                </Text>
              </Button>
            )}
            <Button
              onClick={
                editingMonitoringData
                  ? () => handleSubmit()
                  : () => setEditingMonitoringData(true)
              }
              variant={editingMonitoringData ? 'primary' : 'secondary'}
              disabled={
                (load && load.emitteds?.length > 0) ||
                onGoingTravelStatusId(load?.travel?.statusId) ||
                isTravelBlocked ||
                load?.loadPolicy?.id
              }
              loading={loading}
              title={
                load?.loadPolicy?.id && load?.travel?.id
                  ? 'Não é possível editar informações de monitoramento de carga com apólice. Acesse a viagem caso precise modificar alguma informação.'
                  : load?.loadPolicy?.id
                  ? 'Não é possível editar informações de monitoramento de carga com apólice.'
                  : ''
              }
              id={`button-${
                editingMonitoringData ? 'salvar' : 'editar'
              }-card-monitoramento`}
            >
              <Text
                color={editingMonitoringData ? 'white' : 'dark'}
                type="regular"
              >
                {editingMonitoringData ? 'Salvar' : 'Editar'}
              </Text>
            </Button>
          </div>
        )
      }
    >
      {editingMonitoringData ? (
        <Row className="form">
          <Col md={6} xs={12} className="mb-3">
            <Radio.Group
              label="Rastreado *"
              onChange={({ target }) => {
                setTracked(target.value);
              }}
              value={tracked}
              horizontal
              disabled={load?.loadPolicy?.id || isDisabledGROAnalysis}
            >
              <Radio value={trueVar} id="radio-rastreado-sim">
                <Text color="dark" type="label">
                  Sim
                </Text>
              </Radio>
              <Radio value={false} id="radio-rastreado-nao">
                <Text color="dark" type="label">
                  Não
                </Text>
              </Radio>
            </Radio.Group>
          </Col>
          {tracked && (
            <Col xs={6}>
              <Select.Async
                onSearch={fetchRiskManager}
                label="GR para rastreamento *"
                value={riskManager || updatedTravelData?.riskManager}
                onChange={value => {
                  if (Array.isArray(value)) {
                    setRiskManager(value);
                  } else {
                    setRiskManager(value ? [value] : []);
                  }
                }}
                getOptionLabel={option => option?.name}
                getOptionValue={option => option?.id}
                error={errors?.riskManager}
                multiple
                id="select-liberacoes-gr"
              />
            </Col>
          )}
          <Col md={12} xs={12} className="mb-3">
            <Radio.Group
              label="Carga escoltada"
              onChange={({ target }) => setIsEscorted(target.value)}
              value={isEscorted}
              horizontal
              disabled={load?.loadPolicy?.id}
            >
              <Radio value={trueVar} id="radio-carga-escoltada-sim">
                <Text color="dark" type="label">
                  Sim
                </Text>
              </Radio>
              <Radio value={false} id="radio-carga-escoltada-nao">
                <Text color="dark" type="label">
                  Não
                </Text>
              </Radio>
            </Radio.Group>
          </Col>
          {!load?.loadPolicy?.id ? (
            <>
              <Col md={6} xs={12} className="mb-3">
                <Radio.Group
                  label="Possui isca"
                  onChange={({ target }) => {
                    setWithBait(target.value);
                  }}
                  value={withBait}
                  horizontal
                >
                  <Radio value={trueVar}>
                    <Text color="dark" type="label">
                      Sim
                    </Text>
                  </Radio>
                  <Radio value={false}>
                    <Text color="dark" type="label">
                      Não
                    </Text>
                  </Radio>
                </Radio.Group>
              </Col>

              {withBait === true && (
                <Col md={6} xs={12}>
                  <Input
                    label="Número da isca"
                    value={baitNumber}
                    onChange={e => setBaitNumber(e.target.value)}
                    error={errors.baitNumber}
                    id="input-numero-isca"
                  />
                </Col>
              )}
            </>
          ) : null}
        </Row>
      ) : (
        <Row>
          <Col md={6} xs={12}>
            <Text type="label" color="#464E5F">
              Rastreado
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {load?.crawledLoad ? 'Sim' : 'Não'}
            </Text>
          </Col>
          <Col md={6} xs={12}>
            {load?.crawledLoad ? (
              <>
                <Text type="label" color="#464E5F">
                  GR para rastreamento
                </Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {load
                    ? load.riskManagers.length > 0
                      ? load.riskManagers
                          .map(clearance => clearance.name)
                          .join(', ')
                      : 'Não informado'
                    : null}
                </Text>
              </>
            ) : null}
          </Col>

          <Col md={12} xs={12}>
            <Text type="label" color="#464E5F">
              Escoltado
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {load?.isEscorted ? 'Sim' : 'Não'}
            </Text>
          </Col>
          {!load?.loadPolicy?.id ? (
            <>
              <Col md={6} xs={12}>
                <Text type="label" color="#464E5F">
                  Possui isca
                </Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {load?.withBait ? 'Sim' : 'Não'}
                </Text>
              </Col>
              {load?.withBait ? (
                <Col md={6} xs={12}>
                  <Text type="label" color="#464E5F">
                    Número da isca
                  </Text>
                  <Text type="regular" color="gray" as="p" className="mt-2">
                    {baitNumber}
                  </Text>
                </Col>
              ) : null}
            </>
          ) : null}
        </Row>
      )}
    </Card>
  );
}

export default Monitoring;
