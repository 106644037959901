/* eslint-disable import/no-unresolved */
import React from 'react';
import { Col, Image } from 'react-bootstrap';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import moment from 'moment';

import CardNf from './CardNf';

function formatCityProvince(obj) {
  return `${
    obj.city && obj.province
      ? `${obj.city} - ${obj.province}`
      : obj.city
      ? obj.city
      : obj.province
      ? obj.province
      : 'Sem localização'
  }`;
}

export function locationsObject(
  showAllDestinations = true,
  travel,
  handleShow
) {
  const destinationsToDisplay = showAllDestinations
    ? travel.destinations
    : travel.destinations.slice(-1);

  const items = [
    ...travel.origins.map(item => ({
      header: (
        <div className="d-flex flex-row justify-content-between">
          <Text>Origem</Text>
        </div>
      ),
      body: (
        <>
          <Text className="my-2" color="gray" type="regular">
            {formatCityProvince(item)}
          </Text>
          <Text color="gray" type="regular">
            {moment(item.scheduled_time).format('DD/MM/YYYY HH:mm')}
          </Text>
        </>
      ),
      filledCircle: false,
    })),
  ];

  if (!showAllDestinations && travel.destinations.length > 1)
    items.push({
      header: (
        <Button onClick={handleShow} link>
          <Text type="regular">{travel.destinations.length - 1} destinos</Text>
        </Button>
      ),
      link: true,
    });

  items.push(
    ...destinationsToDisplay.map(item => ({
      header: (
        <div className="d-flex flex-row justify-content-between">
          <Text>{item?.action?.toLowerCase() === 'customhouse'
            ? 'Aduaneiro'
            : 'Destino'}</Text>
        </div>
      ),
      body: (
        <>
          <Text className="my-2" color="gray" type="regular">
            {formatCityProvince(item)}
          </Text>
          <Text color="gray" type="regular">
            {moment(item.scheduled_time).format('DD/MM/YYYY HH:mm')}
          </Text>
        </>
      ),
      filledCircle: true,
    }))
  );
  return items;
}

export function ctesObject(travel) {
  const { emitteds } = travel;
  const ctes = new Array();
  if (emitteds.length > 0) {
    emitteds.forEach(cte => {
      const newCte = (
        <CardNf md={12}>
          <Col md={12}>
            <Image width="28px" src="/images/cte_icon.png" />{' '}
            <Text>{cte.cte_number}</Text>
          </Col>
          <Col md={12} style={{ paddingLeft: '0.9rem' }}>
            <Text type="label">Notas Fiscais</Text>
          </Col>
          <Col md={12} style={{ paddingLeft: '0.9rem' }}>
            <Text type="label" color="#b8b8b8">
              {cte.nfsByCte
                ? cte.nfsByCte.map(nf => nf.nf_number).join(', ')
                : 'Nenhuma NF encontrada.'}
            </Text>
          </Col>
        </CardNf>
      );
      ctes.push(newCte);
    });
  }

  return ctes;
}
