import React from 'react';
import { RangeSectionToggleHeader } from './RangeSectionToggleHeader';
import { RiskManagementInfo } from 'v3/pages/Policy/styles';
import TextArea from 'v3/components/TextArea';
import { usePolicyRegister } from '../../context';

export function RiskManagerSection({
  opIndex,
  productIndex,
  vehicleIdx,
  rangeIdx,
  range,
}) {
  const { handleAddRangeItem, errors } = usePolicyRegister();
  return (
    <>
      <RangeSectionToggleHeader
        opIndex={opIndex}
        productIndex={productIndex}
        vehicleIdx={vehicleIdx}
        rangeIdx={rangeIdx}
        keyName={'showRiskManager'}
        keyValue={range?.showRiskManager}
        label={'Gerenciamento de risco'}
      />

      {range.showRiskManager ? (
        <RiskManagementInfo>
          <TextArea
            label="Orientações ao motorista"
            value={range?.driver_guide}
            onChange={e =>
              handleAddRangeItem(
                opIndex,
                productIndex,
                vehicleIdx,
                rangeIdx,
                'driver_guide',
                e?.target?.value
              )
            }
            error={
              errors[
                `operations[${opIndex}].products[${productIndex}].vehicles[${vehicleIdx}].ranges[${rangeIdx}].driver_guide`
              ]
            }
          />

          <TextArea
            label="Contato gerenciadora / P.A / GC"
            value={range?.manager_contact}
            onChange={e =>
              handleAddRangeItem(
                opIndex,
                productIndex,
                vehicleIdx,
                rangeIdx,
                'manager_contact',
                e?.target?.value
              )
            }
            error={
              errors[
                `operations[${opIndex}].products[${productIndex}].vehicles[${vehicleIdx}].ranges[${rangeIdx}].manager_contact`
              ]
            }
          />
        </RiskManagementInfo>
      ) : null}
    </>
  );
}
