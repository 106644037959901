/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';

import api from 'services/api';

import Card from 'v3/components/Card';
import Text from 'v3/components/Text';
import Table from 'v3/components/Table';
import Radio from 'v3/components/Radio';
import Button from 'v3/components/Button';
import Select from 'v3/components/Select';
import TextArea from 'v3/components/TextArea';
import InputCurrency from 'v3/components/Input/Currency';

import { Row, Col } from 'react-bootstrap';
import { FaTrash, FaPencilAlt, FaPercentage } from 'react-icons/fa';

import { IssuerContext } from '../controller';
import { validate, getProvinces } from './controller';

const [, useIssuer] = IssuerContext;

export default function Icms({ taxSituations, setAddProductModal }) {
  const issuer = useIssuer();
  const [icmsExceptionsList, setIcmsExceptionsList] = useState([]);

  const [client, setClient] = useState('');
  const [product, setProduct] = useState('');
  const [rate, setRate] = useState();
  const [origin, setOrigin] = useState();
  const [destination, setDestination] = useState();
  const [legalText, setLegalText] = useState();
  const [taxBaseReduction, setTaxBaseReduction] = useState('0');
  const [isExempt, setExempt] = useState(false);
  const [taxSituation, setTaxSituation] = useState();

  const [errors, setErrors] = useState({});
  const [provinces, setProvinces] = useState();

  useEffect(() => {
    issuer.setData(old => ({ ...old, icmsExceptions: icmsExceptionsList }));
  }, [icmsExceptionsList]);

  async function addException() {
    if (!(await validate({ origin, destination, rate }, setErrors))) {
      return false;
    }

    setIcmsExceptionsList(old => [
      ...old,
      {
        client,
        product,
        origin,
        destination,
        value: Number(rate.replace(',', '.')),
        taxBaseReduction: Number(taxBaseReduction.replace(',', '.')) || 0,
        legalText,
        isExempt,
        taxSituation,
      },
    ]);

    setClient('');
    setProduct('');
    setOrigin('');
    setDestination('');
    setRate('0.00');
    setTaxBaseReduction('0');
    setLegalText('');
    setExempt(false);
    setTaxSituation(null);

    return true;
  }

  function handleRemoveException(index) {
    setIcmsExceptionsList(icmsExceptionsList.filter((_, idx) => idx !== index));
  }

  function handleEditException(index) {
    const item = icmsExceptionsList[index];

    setClient(item.client);
    setOrigin(item.origin);
    setDestination(item.destination);
    setRate(item.value.toString());
    setTaxBaseReduction(item.taxBaseReduction.toString());
    setLegalText(item.legalText);
    setExempt(item.isExempt);
    setTaxSituation(item.taxSituation);

    handleRemoveException(index);
  }

  async function fetchCustomers(search) {
    try {
      const response = await api.get('persons/customers', {
        params: { search },
      });
      return response.data;
    } catch (err) {
      throw new Error(err.message);
    }
  }

  async function fetchProducts(search) {
    try {
      const response = await api.get('product-types?', { params: { search } });
      return response.data;
    } catch (error) {
      return [];
    }
  }

  useEffect(() => {
    getProvinces(setProvinces);
  }, []);

  return (
    <Card
      header={
        <Text color="#464E5F" type="header">
          Exceções de ICMS
        </Text>
      }
    >
      <Col>
        <Row>
          <Col xs={12} className="mb-3">
            <Select.Async
              label="Tomador"
              onSearch={search => fetchCustomers(search)}
              value={client}
              getOptionLabel={option => {
                let label = `${option.social_name} - ${option.cgccpf}`;

                if (option.city) {
                  label += ` - ${option.city}`;
                }

                return label;
              }}
              getOptionValue={option => option.id}
              onChange={event => setClient(event)}
            />
          </Col>
          <Col xs={8}>
            <Select.Async
              label="Produto"
              onSearch={search => fetchProducts(search)}
              value={product}
              getOptionLabel={option => {
                const label = `${option.ncm} - ${option.name}`;
                return label;
              }}
              getOptionValue={option => option.id}
              onChange={event => setProduct(event)}
            />
          </Col>
          <Col xs={4}>
            <Button
              style={{ marginTop: '35px' }}
              onClick={() => setAddProductModal(true)}
              size="sm"
              variant="primary"
            >
              Cadastrar produto
            </Button>
          </Col>
          <Col xs={6} className="mb-3">
            <Select
              label="Origem *"
              options={provinces}
              placeholder="Selecione um estado"
              value={origin}
              onChange={selectedValue => setOrigin(selectedValue)}
              error={errors.origin}
            />
          </Col>
          <Col xs={6} className="mb-3">
            <Select
              label="Destino *"
              options={provinces}
              placeholder="Selecione um estado"
              value={destination}
              onChange={selectedValue => setDestination(selectedValue)}
              error={errors.destination}
            />
          </Col>
          <Col xs={6} className="mb-3">
            <InputCurrency
              rightIcon={<FaPercentage color="#464E5F" />}
              label="Alíquota *"
              value={rate}
              onChange={(_event, value) => setRate(value)}
              guide={false}
              error={errors.rate}
            />
          </Col>
          <Col xs={6} className="mb-3">
            <InputCurrency
              rightIcon={<FaPercentage color="#464E5F" />}
              label="Redução da BC"
              value={taxBaseReduction}
              onChange={(_event, value) => setTaxBaseReduction(value)}
              guide={false}
            />
          </Col>
          <Col xs={12} className="mb-3">
            <TextArea
              resizable={false}
              label="Texto legal"
              value={legalText}
              onChange={event => setLegalText(event.target.value)}
            />
          </Col>
          <Col xs={12} className="mb-3">
            <Select
              label="Situação tributária"
              options={taxSituations}
              value={taxSituation}
              onChange={value => setTaxSituation(value)}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
            />
          </Col>
          <Col xs={6} className="mb-3">
            <Radio.Group
              label="Isento"
              onChange={({ target }) => setExempt(target.value)}
              value={isExempt}
              horizontal
            >
              <Radio value>
                <Text color="dark" type="label">
                  Sim
                </Text>
              </Radio>
              <Radio value={false}>
                <Text color="dark" type="label">
                  Não
                </Text>
              </Radio>
            </Radio.Group>
          </Col>
          <Col xs={6} className="d-flex justify-content-end mb-3">
            <Button
              className="mt-4"
              variant="primary"
              onClick={async () => addException()}
            >
              <Text weight={600} type="regular">
                Adicionar
              </Text>
            </Button>
          </Col>
        </Row>
        <Row>
          {icmsExceptionsList.length > 0 && (
            <Table responsive>
              <thead>
                <tr>
                  <th>
                    <Text type="label" weight="500">
                      Cliente
                    </Text>
                  </th>
                  <th>
                    <Text type="label" weight="500">
                      Produto
                    </Text>
                  </th>
                  <th>
                    <Text type="label" weight="500">
                      Origem
                    </Text>
                  </th>
                  <th>
                    <Text type="label" weight="500">
                      Destino
                    </Text>
                  </th>
                  <th>
                    <Text type="label" weight="500">
                      Alíquota
                    </Text>
                  </th>
                  <th>
                    <Text type="label" weight="500">
                      Redução da BC
                    </Text>
                  </th>
                  <th>
                    <Text type="label" weight="500">
                      Isento
                    </Text>
                  </th>
                  <th>
                    <Text type="label" weight="500">
                      Ação
                    </Text>
                  </th>
                </tr>
              </thead>
              <tbody>
                {icmsExceptionsList.map((exception, index) => (
                  <tr>
                    <td>
                      <Text type="regular">{exception.client.social_name}</Text>
                    </td>
                    <td>
                      <Text type="regular">
                        {exception.product.name}-{exception.product.ncm}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular">{exception.origin.name}</Text>
                    </td>
                    <td>
                      <Text type="regular">{exception.destination.name}</Text>
                    </td>
                    <td>
                      <Text type="regular">
                        {exception.value.toString().replace('.', ',')}%
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" weight="400">
                        {exception.taxBaseReduction
                          .toString()
                          .replace('.', ',')}
                        %
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" weight="400">
                        {exception.isExempt ? 'Sim' : 'Não'}
                      </Text>
                    </td>
                    <td width="120" className="text-center">
                      <Button
                        variant="primary"
                        size="sm"
                        className="mr-1"
                        onClick={() => handleEditException(index)}
                      >
                        <FaPencilAlt />
                      </Button>
                      <Button
                        variant="error"
                        size="sm"
                        onClick={() => handleRemoveException(index)}
                      >
                        <FaTrash />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Row>
      </Col>
    </Card>
  );
}
