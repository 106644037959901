/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import Text from 'v3/components/Text';
import Input from 'v3/components/Input';
import Card from 'v3/components/Card';
import Button from 'v3/components/Button';
import { useSnackbar } from 'v3/components/Snackbar';
import ApplicationLayout from 'v3/layouts/Application';
import api from 'services/api';
import { validatePermission } from 'actions/index';
import { usePermission } from 'hooks';

function Details() {
  const snackbar = useSnackbar();
  const history = useHistory();
  const params = useParams();

  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState();

  const [hasPermissionToEdit, setPermissionToEdit] = useState();
  const [hasPermissionToCreate, setPermissionToCreate] = useState();
  usePermission('VISUALIZAR_FORMAS_DE_PAGAMENTO', { redirect: true });

  useState(() => {
    setPermissionToEdit(validatePermission('EDITAR_FORMAS_DE_PAGAMENTO'));
    setPermissionToCreate(validatePermission('CADASTRAR_FORMAS_DE_PAGAMENTO'));
  }, []);

  async function getPaymentMethod() {
    try {
      const response = await api.get(`payment/methods/${params.id}`);
      setPaymentMethod(response.data.name);
    } catch (error) {
      // Handle exception
    }
  }

  useEffect(() => {
    getPaymentMethod();
  }, []);

  async function handleSubmit() {
    if (paymentMethod === '') {
      snackbar.show(<Text>O nome da forma de pagamento é obrigatório</Text>, {
        type: 'error',
      });
      return;
    }

    try {
      setLoading(true);

      await api.put(`payment/methods/${params.id}`, {
        name: paymentMethod,
      });
      snackbar.show(<Text>Forma de pagamento editada com sucesso!</Text>, {
        type: 'success',
      });
    } catch (error) {
      if (error) {
        snackbar.show(<Text>{error.message}</Text>, {
          type: 'error',
        });
      }
    } finally {
      setLoading(false);
      setEditing(false);
    }
  }

  return (
    <ApplicationLayout
      title="Detalhes da forma de pagamento"
      RightComponent={
        <div className="text-right">
          <Button
            variant="secondary"
            onClick={() => history.push(`/formas-de-pagamento`)}
          >
            <Text weight={500} type="regular">
              Voltar
            </Text>
          </Button>

          {hasPermissionToCreate && (
            <Button
              className="ml-2"
              onClick={() => history.push('/formas-de-pagamento/cadastrar')}
            >
              <Text weight={500} type="regular">
                Nova forma de pagamento
              </Text>
            </Button>
          )}
        </div>
      }
    >
      <Row>
        <Card
          header={
            <Text color="#464E5F" type="header">
              {paymentMethod}
            </Text>
          }
          HeaderRightComponent={
            hasPermissionToEdit && (
              <div>
                {editing && (
                  <Button
                    onClick={() => setEditing(false)}
                    variant="secondary"
                    loading={loading}
                    className="mr-2"
                  >
                    <Text color="dark" type="regular">
                      Cancelar
                    </Text>
                  </Button>
                )}
                <Button
                  onClick={
                    editing ? () => handleSubmit() : () => setEditing(true)
                  }
                  variant={editing ? 'primary' : 'secondary'}
                  loading={loading}
                >
                  <Text
                    color={editing ? 'white' : 'dark'}
                    weight={500}
                    type="regular"
                  >
                    {editing ? 'Salvar' : 'Editar'}
                  </Text>
                </Button>
              </div>
            )
          }
        >
          <Input
            label="Nome"
            disabled={!editing}
            value={paymentMethod}
            onChange={event => setPaymentMethod(event.target.value)}
          />
        </Card>
      </Row>
    </ApplicationLayout>
  );
}

export default Details;
