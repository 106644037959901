import React, { useState, useMemo, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import NativeAsyncSelectCreatable from 'react-select/async-creatable';

import api from 'services/api';

/*
O parametro 'search' da URl deve ser o último na URL
exemplo de envio:
route={localhost:8080/?paramatro1=1&search=}
label='Busque um produto'
*/

export default function ComponentAsyncSelect({
  name = 'select-creatable',
  creatable,
  defaultValue,
  isDisabled,
  route,
  label = 'Digite para procurar',
  onChange,
  onCreateOption,
  isMulti,
  placeholder,
  optionLabel = 'label',
  optionValue = 'value',
  getOptionLabel,
  nested,
  ...props
}) {
  const [dataList, setDataList] = useState([]);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setData(defaultValue);
  }, [defaultValue]);

  let timeOut;
  const promiseFilter = inputValue => {
    if (inputValue.length > 2) {
      return new Promise(resolve => {
        clearTimeout(timeOut);
        timeOut = setTimeout(() => {
          resolve(search(inputValue));
        }, 1000);
      });
    }
  };

  async function search(inputValue) {
    setLoading(true);
    try {
      const res = await api.get(`${route}${inputValue}`);

      if (nested) {
        setDataList(res.data[nested]);
        return res.data[nested];
      }

      return res.data;
    } catch (ex) {
      return [];
    } finally {
      setLoading(false);
    }
  }

  const isClearable = useMemo(() => !!data, [data]);

  function isValidNewOption(inputValue) {
    if (!Array.isArray(dataList) || !inputValue || inputValue.length < 3) {
      return false;
    }

    return !dataList.some(
      item => item[optionLabel].toLowerCase() === inputValue.toLowerCase()
    );
  }

  const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),

    input: styles => ({ ...styles, color: '#76838F' }),
    placeholder: styles => ({
      ...styles,
      color: '#76838F',
    }),
    singleValue: styles => ({ ...styles, color: '#76838F' }),
  };

  if (creatable) {
    return (
      <NativeAsyncSelectCreatable
        {...props}
        styles={colourStyles}
        noOptionsMessage={() => (
          <label style={{ padding: 0, margin: 0 }}>{label}</label>
        )}
        placeholder={placeholder}
        isClearable={isClearable}
        loadOptions={promiseFilter}
        loadingMessage={() => (
          <label style={{ padding: 0, margin: 0 }}>Carregando...</label>
        )}
        name={name}
        value={data}
        options={dataList}
        onChange={event => {
          setData(event);
          onChange(event);
        }}
        isDisabled={isDisabled}
        isLoading={loading}
        formatCreateLabel={label => (
          <label style={{ padding: 0, margin: 0 }}>Criar {label}</label>
        )}
        isMulti={isMulti}
        onCreateOption={onCreateOption}
        isValidNewOption={isValidNewOption}
        getOptionLabel={option =>
          getOptionLabel ? getOptionLabel(option) : option[optionLabel]
        }
        getOptionValue={option => option[optionValue]}
        getNewOptionData={(inputValue, optionLabel) => ({
          id: optionLabel,
          name: `Criar ${inputValue}`,
        })}
      />
    );
  }

  return (
    <AsyncSelect
      styles={colourStyles}
      id={props.id}
      noOptionsMessage={() => (
        <label style={{ padding: 0, margin: 0 }}>{label}</label>
      )}
      placeholder={placeholder}
      isClearable={isClearable}
      loadOptions={promiseFilter}
      loadingMessage={() => (
        <label style={{ padding: 0, margin: 0 }}>Carregando...</label>
      )}
      name={name}
      value={data}
      options={dataList}
      onChange={event => {
        setData(event);
        onChange(event);
      }}
      isDisabled={isDisabled}
      isMulti={isMulti}
      getOptionLabel={option =>
        getOptionLabel ? getOptionLabel(option) : option[optionLabel]
      }
      getOptionValue={option => option[optionValue]}
    />
  );
}
