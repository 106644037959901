import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';

export default function LinearProgressBar({
  color = 'primary',
  variant = 'determinate',
  value = 0,
  ...rest
}) {
  return (
    <LinearProgress variant={variant} value={value} color={color} {...rest} />
  );
}
