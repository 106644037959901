/* eslint-disable import/no-unresolved */
import api from 'services/api';

export async function isAvailable(loadId) {
  try {
    const response = await api.get(
      `integrations/fretebras/load/${loadId}/available`
    );

    return response.data.isAvailable;
  } catch (ex) {
    return false;
  }
}

export async function send(id) {
  try {
    const response = await api.post(`integrations/fretebras/load/${id}`);
    if (
      response.data &&
      response.data.erros &&
      response.data.erros.length > 0
    ) {
      return { error: true, message: response.data.erros[0].descricao };
    }
    return {
      error: false,
      message: 'Carga enviada para Fretebras com sucesso.',
    };
  } catch (err) {
    return {
      error: true,
      message: err.response.data.error
        ? err.response.data.error
        : 'Erro ao enviar carga para Fretebras.',
    };
  }
}

export async function cancel(id) {
  try {
    await api.post(`integrations/fretebras/load/${id}/cancel`);

    return {
      error: false,
      message: 'Carga cancelada na Fretebras com sucesso.',
    };
  } catch (err) {
    let message = 'Não foi possível cancelar a carga na Fretebras.';
    if (err.response) {
      const { data } = err.response;
      if (data.exception) {
        if (data.exception.message) {
          message = data.exception.message;
        }
      } else if (data.error) {
        message = data.error;
      }
    }

    return { error: true, message };
  }
}
