/* eslint-disable import/no-unresolved */
import React, { useState, useEffect, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import Card from 'v3/components/Card';
import Text from 'v3/components/Text';
import Select from 'v3/components/Select';
import Input from 'v3/components/Input';
import Radio from 'v3/components/Radio';
import { useSnackbar } from 'v3/components/Snackbar';
import * as Controller from './controller';
import { LoadContext } from '../controller';

const [, useLoad] = LoadContext;

export default function Vehicle() {
  const load = useLoad();
  const snackbar = useSnackbar();
  const [vehicles, setVehicles] = useState([]);
  const [bodies, setBodies] = useState([]);
  const [_implements, setImplements] = useState([]);
  const [dispositions, setDispositions] = useState([]);

  const [pallets, setPallets] = useState(0);
  const [net_weight, setNet_weight] = useState(0);
  const [volume, setVolume] = useState(0);
  const [mopp, setMOPP] = useState(false);
  const [filteredGROVehicleOptions, setFilteredGROVehicleOptions] = useState(
    []
  );
  const [filteredGROBodiesOptions, setFilteredGROBodiesOptions] = useState([]);
  const [filteredImplementOptions, setFilteredImplementOptions] = useState([]);

  const shouldDisplayImplement = useMemo(
    () =>
      load?.data?.selectedVehicles?.some(selected => selected.qty_plates > 1),
    [load?.data?.selectedVehicles]
  );

  const visiblePallet = useMemo(
    () =>
      load?.data?.selectedDisposition &&
      load?.data?.selectedDisposition?.name?.toLowerCase() === 'paletizada',
    [load?.data?.selectedDisposition]
  );

  const handleChangeGROVehicle = value => {
    const newVehicleList = value
      ? value.filter(vehicle =>
          load?.data?.preLoadPolicy?.vehiclesOptionsId.includes(vehicle.id)
        )
      : [];

    let rangesOptions = [];

    if (value?.length) {
      const { vehicles: vehiclesRange } =
        load?.data?.preLoadPolicy?.products.find(({ products }) =>
          products.find(
            ({ code }) => code === load?.data?.preLoadPolicy.selectedProduct.ncm
          )
        );

      const findRange = vehiclesRange.find(({ vehicle_types }) => {
        return vehicle_types.find(
          vehicle =>
            newVehicleList[0].id === vehicle.id && vehicle.type === 'vehicle'
        );
      });

      const availableBodies = findRange.vehicle_types
        .filter(({ type }) => type === 'body')
        .map(({ id }) => id);

      const availableImplements = findRange.vehicle_types
        .filter(({ type }) => type === 'implement')
        .map(({ id }) => id);

      setFilteredGROBodiesOptions(
        bodies.filter(body => availableBodies.includes(body.id))
      );
      setFilteredImplementOptions(
        _implements.filter(_implement =>
          availableImplements.includes(_implement.id)
        )
      );
      rangesOptions = findRange.ranges.map(
        ({ policy_range_min, policy_range_max }) => ({
          min: policy_range_min,
          max: policy_range_max,
        })
      );
    }

    load.setData(old => ({
      ...old,
      selectedVehicles: newVehicleList,
      preLoadPolicy: {
        ...old.preLoadPolicy,
        rangesOptions,
      },
    }));
  };

  const handleChangeGROBodies = value => {
    const newBodiesList = value
      ? value.filter(body =>
          filteredGROBodiesOptions
            .map(filtered => filtered.id)
            .includes(body.id)
        )
      : [];

    load.setData(old => ({ ...old, selectedBodies: newBodiesList }));
  };

  const handleChangeGROImplements = value => {
    const newImplementsList = value
      ? value.filter(_implement =>
          filteredImplementOptions
            .map(filtered => filtered.id)
            .includes(_implement.id)
        )
      : [];

    load.setData(old => ({ ...old, selectedImplements: newImplementsList }));
  };

  useEffect(() => {
    async function fetchInitialData() {
      const vehiclesData = await Controller.fetchVehicles();
      setVehicles(vehiclesData);

      const bodiesData = await Controller.fetchBodies();
      setBodies(bodiesData);

      const implementsData = await Controller.fetchImplements();
      setImplements(implementsData);

      const dispositionsData = await Controller.fetchDispositions();
      setDispositions(dispositionsData);
    }

    fetchInitialData();
  }, []);

  useEffect(() => {
    if (
      load?.data?.selectedDisposition &&
      load?.data?.selectedDisposition?.name?.toLowerCase() !== 'paletizada'
    ) {
      setPallets('');
    }
  }, [load?.data?.selectedDisposition]);

  useEffect(() => {
    load.setData(old => ({
      ...old,
      pallets,
      net_weight,
      volume,
      mopp,
    }));
  }, [pallets, net_weight, volume, mopp]);

  useEffect(() => {
    if (
      load?.isPreloadWithGRO &&
      load?.data?.preLoadPolicy?.vehiclesOptionsId.length > 0
    ) {
      setFilteredGROVehicleOptions(
        vehicles.filter(vehicle =>
          load?.data?.preLoadPolicy?.vehiclesOptionsId.includes(vehicle.id)
        )
      );
    }
  }, [load?.data?.preLoadPolicy?.vehiclesOptionsId]);

  return (
    <Card
      className="full-height-card"
      header={
        <Text color="#464E5F" type="header">
          Veículo
        </Text>
      }
    >
      <Row className="form">
        <Col xs={12} className="mb-3">
          <Select
            tooltip={
              load?.isPreLoadWithGROVehicleDisabled &&
              'Selecione um produto válido para a apólice'
            }
            label="Veículos *"
            options={
              load?.isPreloadWithGRO ? filteredGROVehicleOptions : vehicles
            }
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            value={load?.data?.selectedVehicles}
            onChange={value => {
              if (load?.isPreloadWithGRO) {
                handleChangeGROVehicle(value);
                return;
              }
              load.setData(old => ({ ...old, selectedVehicles: value || [] }));
            }}
            multiple
            error={load.errors.selectedVehicles}
            loading={load.retrievingLoadData}
            id="select-veiculos"
            isDisabled={load?.isPreLoadWithGROVehicleDisabled}
          />
        </Col>

        <Col xs={12} className="mb-3">
          <Select
            tooltip={
              load?.isPreLoadWithGROVehicleDisabled &&
              'Selecione um produto válido para a apólice'
            }
            label="Tipo de carroceria *"
            options={load?.isPreloadWithGRO ? filteredGROBodiesOptions : bodies}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            value={load?.data?.selectedBodies}
            onChange={value => {
              if (load?.isPreloadWithGRO) {
                handleChangeGROBodies(value);
                return;
              }
              load.setData(old => ({ ...old, selectedBodies: value || [] }));
            }}
            multiple
            error={load.errors.selectedBodies}
            loading={load.retrievingLoadData}
            id="select-carroceria"
            isDisabled={load?.isPreLoadWithGROVehicleDisabled}
          />
        </Col>

        {shouldDisplayImplement && (
          <Col xs={12} className="mb-3">
            <Select
              tooltip={
                load?.isPreLoadWithGROVehicleDisabled &&
                'Selecione um produto válido para a apólice'
              }
              label="Tipo de implemento *"
              options={
                load?.isPreloadWithGRO ? filteredImplementOptions : _implements
              }
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              value={load?.data?.selectedImplements}
              onChange={value => {
                if (load?.isPreloadWithGRO) {
                  handleChangeGROImplements(value);
                  return;
                }
                load.setData(old => ({
                  ...old,
                  selectedImplements: value || [],
                }));
              }}
              multiple
              error={load.errors.selectedImplements}
              loading={load.retrievingLoadData}
              id="select-implemento"
              isDisabled={load?.isPreLoadWithGROVehicleDisabled}
            />
          </Col>
        )}

        <Col xs={6} className="mb-3">
          <Select
            label="Disposição da carga *"
            options={dispositions}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            value={load?.data?.selectedDisposition}
            onChange={value =>
              load.setData(old => ({ ...old, selectedDisposition: value }))
            }
            error={
              load.errors.selectedDisposition ||
              load?.errors['selectedDisposition.id']
            }
            id="select-disposicao-carga"
          />
        </Col>

        <Col xs={6} className="mb-3">
          <Input
            label={`Pallets${visiblePallet ? ' *' : ''}`}
            type="number"
            value={pallets}
            onChange={({ target }) => {
              return target.value >= 0
                ? setPallets(target.value)
                : snackbar.show(
                    <Text>Não utilize valores negativos para os pallets</Text>,
                    {
                      type: 'error',
                    }
                  );
            }}
            error={load.errors.pallets}
            disabled={!visiblePallet}
            id="input-pallets"
          />
        </Col>

        <Col xs={6} className="mb-3">
          <Input
            label="Peso bruto (em kg) *"
            type="number"
            value={load?.data?.weight}
            onChange={({ target }) => {
              return target.value >= 0
                ? load.setData(old => ({ ...old, weight: target.value }))
                : snackbar.show(
                    <Text>Não utilize valores negativos para o peso!</Text>,
                    {
                      type: 'error',
                    }
                  );
            }}
            error={load.errors.weight}
            id="input-peso-bruto"
          />
        </Col>

        <Col xs={6} className="mb-3">
          <Input
            label="Peso líquido (em kg)"
            type="number"
            value={net_weight}
            onChange={({ target }) => {
              return target.value >= 0
                ? setNet_weight(target.value)
                : snackbar.show(
                    <Text>
                      Não utilize valores negativos para o peso líquido!
                    </Text>,
                    {
                      type: 'error',
                    }
                  );
            }}
            error={load.errors.net_weight}
            id="input-peso-liquido"
          />
        </Col>

        <Col xs={6} className="mb-3">
          <Input
            label="Volume (em m³)"
            type="number"
            value={volume}
            onChange={({ target }) => {
              return target.value >= 0
                ? setVolume(target.value)
                : snackbar.show(
                    <Text>Não utilize valores negativos para o volume!</Text>,
                    {
                      type: 'error',
                    }
                  );
            }}
            id="input-volume"
          />
        </Col>
        <Col lg={6} md={6} xs={12} className="mb-3">
          <Radio.Group
            label="Necessita de MOPP?"
            onChange={({ target }) => setMOPP(target.value)}
            value={mopp}
            horizontal
          >
            <Radio value id="radio-necessita-mopp-sim">
              <Text color="dark" type="label">
                Sim
              </Text>
            </Radio>
            <Radio value={false} id="radio-necessita-mopp-nao">
              <Text color="dark" type="label">
                Não
              </Text>
            </Radio>
          </Radio.Group>
        </Col>
      </Row>
    </Card>
  );
}
