import styled from 'styled-components';
import Button from 'v3/components/Button';

export const ImageRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;

  > * {
    margin-bottom: 15px;

    &:not(:last-child) {
      margin-right: 15px;
    }
  }
`;

export const Image = styled.img`
  width: 270px;
  height: 160px;
  background-color: #f2f2f2;
`;

export const Backdrop = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;

  > * {
    background-color: transparent;
    transition: opacity 0.3s ease-in;

    &:hover {
      opacity: 0.8;
    }
  }
`;

export const DateTime = styled.div`
  background-color: rgba(0, 0, 0, 0.35);
  padding: 5px;
  position: absolute;
  left: 0;
  bottom: 0;
`;

export const StyledButton = styled(Button)`
  padding: 10px 17px;
`;

export const StyledButtonEdit = styled(Button)`
  padding: 6px 16px;
`;
