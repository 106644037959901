import styled, { css } from 'styled-components';

import theme from 'v3/theme';

export const Container = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 0;
  padding-right: 0;
  cursor: pointer;
  transition: 0.3s ease;
  background-attachment: #f9f9f9;
  width: 100%;
  border-radius: 3px;

  ${({ selected }) =>
    selected &&
    css`
      background-color: ${theme.colors.light};
    `};
`;

export const StyledRow = styled.div(() => ({
  width: '100%',
  maxHeight: '60vh',
  overflowY: 'scroll',
  direction: 'rtr',
  margin: 2,
  padding: '0px 8px 0px 8px !important',

  '::-webkit-scrollbar': {
    width: '8px',
  },
  '::-webkit-scrollbar-track': {
    marginTop: '15vh',
    boxShadow: 'nset 0 0 6px grey',
    borderRadius: '5px',
  },
  '::-webkit-scrollbar-thumb': {
    background: `${theme.colors.primary}`,
    borderRadius: '15px',
    height: '2px',
  },
  '::-webkit-scrollbar-thumb:hover': {
    background: `${theme.colors.primary}`,
    maxHeight: '15px',
  },
}));
