/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import api from 'services/api';

import { Modal, Button, Text, Input, Select, DatePicker } from 'v3/components';
import { useSnackbar } from 'v3/components/Snackbar';

import { CPF_MASK, BR_PHONE_MASK } from 'utils/masks';
import { TravelContext } from '../../controller';
import { driverSchema } from '../../validator';

import UpdateToDriverDialogComponent from './../UpdateToDriverDialog';

const [, useTravel] = TravelContext;

function CreatedTravel({ isOpen, onClose }) {
  const travel = useTravel();
  const snackbar = useSnackbar();

  const [props, setProps] = useState({});

  async function fetchPaymentMethods(search) {
    try {
      const response = await api.get('payment/methods', { params: { search } });
      const {
        data: { data: result },
      } = response;
      const filteredPaymentList = result.filter(payment => payment.active);

      setProps(prev => ({ ...prev, paymentList: filteredPaymentList }));
    } catch (ex) {
      //
    }
  }

  const [cpfFound, setCpfFound] = useState(false);
  const [cpfID, setCpfID] = useState(0);

  async function verifyCpf(cpf) {
    try {
      const response = await api.get(`driver/check-existent/${cpf}`);
      if (response.status === 200) {
        setCpfID(response.data.id);
        setCpfFound(true);
      } else if (response.status === 204) {
        setCpfFound(false);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function handleSubmit() {
    const errors = {};

    try {
      setProps(prev => ({ ...prev, loading: true }));
      const data = {
        driver: {
          ...props,
          cpf_unmasked: props?.cpf?.replace(/\D/g, '').trim(),
        },
      };

      await driverSchema.validate(data.driver, { abortEarly: false });
      data.driver.cnh_exp_date = moment(data.driver.cnh_exp_date, 'DD-MM-YYYY');
      const response = await api.post('v3/travel/driver', data);

      travel.setData(prev => ({
        ...prev,
        driver: {
          id: response.data.id,
          social_name: response.data.social_name,
          cnh_exp_date: response.data.cnh_exp_date,
          cnh_number: response.data.cnh_number,
        },
      }));

      snackbar.show(<Text>Motorista cadastrado com sucesso.</Text>, {
        type: 'success',
      });
      setProps({ paymentList: props.paymentList });
      onClose();
    } catch (error) {
      setProps(prev => ({ ...prev, loading: false }));

      if (error.inner) {
        error.inner.forEach(error => {
          errors[error.path] = error.message;
        });
        setProps(prev => ({ ...prev, errors }));
        return;
      }

      snackbar.show(
        <Text>
          {error.response?.data?.message
            ? error.response?.data?.message
            : 'Algo deu errado, tente novamente mais tarde!'}
        </Text>,
        {
          type: 'error',
        }
      );
      return [];
    }
  }

  useEffect(() => {
    fetchPaymentMethods();
  }, []);

  return (
    <>
      <Modal
        show={isOpen}
        onHide={onClose}
        heading={
          <Text type="header" color="dark" weight="500">
            Cadastrar motorista
          </Text>
        }
        body={
          <>
            <Row className="mx-0 mt-1">
              <Col md={12} className="mb-3">
                <Input
                  label="Nome *"
                  value={props.name}
                  onChange={({ target }) =>
                    setProps(prev => ({ ...prev, name: target.value }))
                  }
                  error={props?.errors?.name}
                  id="input-modal-cadastro-motorista-nome"
                />
              </Col>

              <Col md={12} className="mb-3">
                <Input
                  value={props.cpf}
                  onChange={({ target }) => {
                    setProps(prev => ({ ...prev, cpf: target.value }));
                    verifyCpf(target.value);
                  }}
                  label="CPF *"
                  masked
                  mask={CPF_MASK}
                  error={props?.errors?.cpf_unmasked}
                  id="input-modal-cadastro-motorista-cpf"
                />
              </Col>

              {cpfFound ? (
                <UpdateToDriverDialogComponent
                  cpfID={cpfID}
                  cpf={props.cpf}
                  setProps={setProps}
                  setCpfFound={setCpfFound}
                  setCpfID={setCpfID}
                />
              ) : null}

              <Col xs={12} md={6} className="mb-3">
                <Input
                  label="CNH"
                  value={props?.cnh}
                  onChange={({ target }) =>
                    setProps(prev => ({ ...prev, cnh: target.value }))
                  }
                  id="input-modal-cadastro-motorista-cnh"
                />
              </Col>

              <Col xs={12} md={6} className="mb-3">
                <DatePicker
                  guide
                  label="Data de expiração da CNH "
                  placeholder=""
                  value={props?.cnh_exp_date}
                  onChange={({ target }) => {
                    setProps(prev => ({
                      ...prev,
                      cnh_exp_date: target.value,
                    }));
                    if (!moment(target.value).isValid()) {
                      setProps(prev => ({ ...prev, isAbleToSave: false }));
                    }
                  }}
                  error={props?.errors?.cnh_exp_date}
                  id="input-modal-cadastro-motorista-expedicao-cnh"
                />
              </Col>

              <Col xs={12} md={6} className="mb-3">
                <Input
                  label="Telefone principal(cooperplace) *"
                  value={props.phone}
                  onChange={({ target }) =>
                    setProps(prev => ({ ...prev, phone: target.value }))
                  }
                  masked
                  mask={BR_PHONE_MASK}
                  error={props?.errors?.phone}
                  guide={false}
                  id="input-modal-cadastro-motorista-telefone-principal"
                />
              </Col>

              <Col md={12} className="mb-3">
                <Select
                  label="Método de pagamento *"
                  onSearch={fetchPaymentMethods}
                  value={props.payment}
                  options={props?.paymentList}
                  onChange={value =>
                    setProps(prev => ({ ...prev, payment: value }))
                  }
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  error={props?.errors?.payment}
                  id="select-modal-cadastro-motorista-metodo-pagamento"
                />
              </Col>
            </Row>
            <Col
              md={12}
              className="mb-3 d-flex align-items-center justify-content-center"
            >
              <Button
                className="mt-1 py-2"
                onClick={() => handleSubmit()}
                loading={props.loading}
                id="button-modal-cadastro-motorista-salvar"
              >
                <Text weight="500" type="regular">
                  Salvar
                </Text>
              </Button>
            </Col>
          </>
        }
      />
    </>
  );
}

export default CreatedTravel;
