import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import moment from 'moment';

import api from 'services/api';

import InfiniteScroll from 'components/InfiniteScroll';
import InfoMessage from 'components/InfoMessage';

import CardExtrato from './CardExtrato';

const months = [
  { label: 'Janeiro', value: 0 },
  { label: 'Fevereiro', value: 1 },
  { label: 'Março', value: 2 },
  { label: 'Abril', value: 3 },
  { label: 'Maio', value: 4 },
  { label: 'Junho', value: 5 },
  { label: 'Julho', value: 6 },
  { label: 'Agosto', value: 7 },
  { label: 'Setembro', value: 8 },
  { label: 'Outubro', value: 9 },
  { label: 'Novembro', value: 10 },
  { label: 'Dezembro', value: 11 },
];

export default function ExtratoDetalhado() {
  const params = useParams();

  const [filter, setFilter] = useState('');
  const [total, setTotal] = useState();

  const [selectedCompany, setSelectedCompany] = useState('');

  useEffect(() => {
    getExtract();
    getSelectedCompany(params.company);
  }, []);

  async function getExtract() {
    const start_date = moment(`${params.month}/${params.year}`, 'MM/YYYY')
      .startOf('month')
      .toISOString();
    const end_date = moment(`${params.month}/${params.year}`, 'MM/YYYY')
      .endOf('month')
      .toISOString();

    const query = new URLSearchParams();
    query.set('start_date', start_date);
    query.set('end_date', end_date);
    query.set('client_id', params.client);

    setFilter(query.toString());
  }

  async function getSelectedCompany(id) {
    const response = await api.get(`company/${id}`);
    setSelectedCompany(response.data);
  }

  return (
    <>
      <Grid item xs={12}>
        <h3 style={{ color: '#627182' }}>{`Viagens realizadas por ${
          selectedCompany?.name
        } em ${months[params.month - 1].label} de ${params.year}`}</h3>
      </Grid>

      <InfiniteScroll
        endpoint={`billing/${params.company}`}
        filter={filter}
        initialFetch={false}
        onDataFetched={data => setTotal(data.pagination.total)}
        placeholderHeight={145}
        renderItem={item => <CardExtrato travel={item} />}
        endMessage={
          <>
            {total === 0 && (
              <Grid
                container
                item
                xs={12}
                justify="center"
                style={{ marginTop: '20px' }}
              >
                <InfoMessage
                  icon="arrow-right"
                  width="auto"
                  snackColor="#8c8c8c"
                  message="A busca não retornou nenhum resultado, revise os filtros"
                />
              </Grid>
            )}

            {total === undefined && (
              <Grid
                container
                item
                xs={12}
                justify="center"
                style={{ marginTop: '20px' }}
              >
                <InfoMessage
                  icon="arrow-right"
                  width="auto"
                  snackColor="#8c8c8c"
                  message="Preencha os filtros para visualizar o relatório"
                />
              </Grid>
            )}
          </>
        }
      />
      {total > 0 && (
        <Grid
          container
          item
          xs={12}
          justify="center"
          style={{ marginTop: '20px' }}
        >
          <InfoMessage
            icon="arrow-right"
            width="auto"
            snackColor="#8c8c8c"
            message="Sem mais itens para exibição."
          />
        </Grid>
      )}
    </>
  );
}
