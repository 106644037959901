import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import { Input, Select } from 'v3/components';
import { fetchCostCenter } from 'utils/fetches';

export default function Filter({ onFilterChange }) {
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [costCenters, setCostCenters] = useState([]);

  function onApplyFilters() {
    onFilterChange({
      name,
      code,
      costCenters,
    });
  }

  useEffect(() => {
    function onPressedKey(e) {
      if (e.key === 'Enter') {
        onApplyFilters();
      }
    }
    window.addEventListener('keyup', onPressedKey);
    return () => {
      window.removeEventListener('keyup', onPressedKey);
    };
  });

  return (
    <Row className="form d-flex align-items-center my-4">
      <Col>
        <Input
          label="Razão"
          labelColor="#fff"
          value={name}
          onChange={e => {
            setName(e.target.value);
          }}
        />
      </Col>
      <Col>
        <Input
          label="Código filial Raster"
          labelColor="#fff"
          value={code}
          onChange={e => {
            setCode(e.target.value);
          }}
        />
      </Col>
      <Col>
        <Select.Async
          labelWhiteSpace="nowrap"
          label="Centro de custo"
          labelColor="#fff"
          modalHeading="Selecione uma Centro de custo"
          modalBodyTitle="Centro de custo:"
          multiple
          horizontal
          onSearch={search => fetchCostCenter({ search, listAll: true })}
          value={costCenters}
          onChange={value => {
            if (value) {
              setCostCenters(value);
            } else {
              setCostCenters([]);
            }
          }}
          getOptionLabel={option => option?.name}
          getOptionValue={option => option?.id}
        />
      </Col>
      <Col style={{ marginTop: '28px' }}>
        <Button variant="success" onClick={() => onApplyFilters()}>
          <Text type="regular" weight={500}>
            Filtrar
          </Text>
        </Button>
      </Col>
    </Row>
  );
}
