/* eslint-disable import/no-unresolved */
import React, { useEffect, memo, forwardRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import Card from 'v3/components/Card';
import Text from 'v3/components/Text';
import Loader from 'v3/components/Loader';
import theme from 'v3/theme';

import Skeleton from '@material-ui/lab/Skeleton';
import Badge from 'v3/components/Badge';
import useController from './useController';
import * as Styled from './styles';

function Notifications({ className, 'aria-labelledby': labeledBy }, ref) {
  const {
    loading,
    notifications,
    pagination,
    fetchNotifications,
    handleNotificationClick,
  } = useController();

  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <Styled.notificationContainer
      ref={ref}
      aria-labelledby={labeledBy}
      id="notify-scroll"
      className={className}
    >
      {!notifications.length && (
        <div>
          <Skeleton variant="rect" width="100%" height={95} />
        </div>
      )}
      <InfiniteScroll
        dataLength={notifications.length}
        next={() => fetchNotifications(pagination.page + 1)}
        hasMore={pagination.page < pagination.lastPage}
        loader={<Loader />}
        scrollableTarget="notify-scroll"
      >
        {notifications.map(notification => (
          <Styled.Notification
            onClick={() => handleNotificationClick(notification)}
            className={notification.read ? 'is-read' : undefined}
          >
            {notification?.company?.name && (
              <Badge outlined={false} size="xs" className="px-2 mb-2" pill>
                {notification.company.name}
              </Badge>
            )}
            <Text
              as="h2"
              type="regular"
              color={
                notification.read ? theme.colors.gray : theme.colors.primary
              }
              id="text-hover"
              weight={notification.read ? 400 : 700}
            >
              {notification.title}
            </Text>
            <Text
              id="text-hover"
              type="label"
              color={notification.read ? theme.colors.gray : undefined}
            >
              {notification.text}
            </Text>
          </Styled.Notification>
        ))}
      </InfiniteScroll>

      {!loading && notifications.length === 0 && (
        <Card>Sem notificações não lidas</Card>
      )}
    </Styled.notificationContainer>
  );
}

export default memo(forwardRef(Notifications));

export { default as useNotificationsController } from './useController';
export { NotificationContextProvider } from './context';
