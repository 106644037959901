import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import ApplicationLayout from 'v3/layouts/Application';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';

import useModal from 'hooks/useModal';

import { useSnackbar } from 'v3/components/Snackbar';
import { cooperplaceApi } from 'services/api';
import Modal from './Modal/index';
import Data from '../shared/Data';
import Section from '../shared/Section';
import {
  ValidationError,
  rasterBranchschema,
  validate,
} from '../shared/validator';

import * as Styled from './styles';
import { useRasterBranches } from '../shared/context';

export default function RasterBranchesRegister() {
  const snackbar = useSnackbar();
  const history = useHistory();
  const rasterBranches = useRasterBranches();
  const modal = useModal();

  const [isLoading, setLoading] = useState(false);

  async function handleCreation() {
    setLoading(true);
    rasterBranches.setErrors({});
    try {
      setLoading(true);
      await validate(rasterBranches.data, rasterBranchschema);
      const response = await cooperplaceApi.post('/raster/branches', {
        code: rasterBranches?.data?.code.code,
        name: rasterBranches?.data?.name,
        document: rasterBranches?.data?.document,
        cost_centers:
          rasterBranches?.data?.costCenters?.map(cost => cost.id) || [],
      });

      setLoading(false);
      rasterBranches.setData({ id: response.data?.branch?.id });
      modal.open();
    } catch (err) {
      setLoading(false);
      if (err instanceof ValidationError) {
        snackbar.show('Verifique os campos obrigatórios', {
          type: 'error',
        });
        rasterBranches.setErrors(err.validationErrors);
        return;
      }

      let error;
      if (err.response) {
        error = err?.response?.data?.errors || err?.response?.data?.error;
        if (Array.isArray(error)) {
          [error] = error;
        }
      }
      snackbar.show(error || 'Erro ao cadastrar filial raster', {
        type: 'error',
      });
    }
  }

  return (
    <ApplicationLayout
      title="Filial Raster"
      RightComponent={
        <Styled.SaveContainer>
          <Button
            variant="secondary"
            onClick={() => history.push('/filiais-raster')}
          >
            <Text weight={500} color="black" type="regular">
              Voltar
            </Text>
          </Button>

          <Button
            variant="success"
            onClick={() => {
              handleCreation();
            }}
            loading={isLoading}
          >
            <Text weight={500} color="white" type="regular">
              Salvar
            </Text>
          </Button>
        </Styled.SaveContainer>
      }
    >
      <Modal isOpen={modal.isOpen} onClose={modal.close} />
      <Row>
        <Col xs={12} md={7}>
          <Section Title="Dados gerais">
            <Data />
          </Section>
        </Col>
      </Row>
    </ApplicationLayout>
  );
}
