import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useSnackbar } from 'v3/components/Snackbar';
import Text from 'v3/components/Text';
import { formatDate } from 'v3/utils/formatter';

import api from 'services/api';

import { useDispatch } from 'react-redux';
import Details from '../Details';
import Register from '../Register';

import { ShippingCompanyProvider } from './context';

export default function ShippingCompanyShared() {
  const [shippingCompany, setShippingCompany] = useState({});
  const [isFetching, setFetching] = useState(false);
  const [errors, setErrors] = useState({});
  const [prevShippingCompany, setPrevShippingCompany] = useState({});
  const snackbar = useSnackbar();
  const dispatch = useDispatch();

  const params = useParams();

  function showSnackbar(text, options) {
    snackbar.show(<Text> {text} </Text>, options);
  }

  function setData(data) {
    setShippingCompany(prev => ({ ...prev, ...data }));
  }

  useEffect(() => {
    async function fetchShippingCompany() {
      try {
        setFetching(true);
        const response = await api.get(`v3/shipping-company/${params.id}`);
        const [firstShippingCompany] = response.data;

        setData({
          ...firstShippingCompany,
          rntrc_exp_date: formatDate(firstShippingCompany?.rntrc_exp_date, {
            isUTC: true,
          }),
          vehicles: firstShippingCompany.shippingCompanyVehicles,
        });

        setPrevShippingCompany(prev => ({
          ...firstShippingCompany,
          rntrc_exp_date: formatDate(firstShippingCompany?.rntrc_exp_date, {
            isUTC: true,
          }),
          vehicles: firstShippingCompany.shippingCompanyVehicles,
        }));
      } catch (ex) {
        snackbar.show(
          <Text>Não foi possível carregar essa transportadora</Text>,
          {
            type: 'error',
          }
        );
      } finally {
        setFetching(false);
      }
    }

    if (params.id) {
      fetchShippingCompany();
    }
  }, [params.id]);

  useEffect(() => {
    if (params.id) {
      dispatch({
        type: 'VIEW_INSTANCE',
        instance: shippingCompany?.social_name,
      });
    }
  }, [shippingCompany]);

  return (
    <ShippingCompanyProvider
      value={{
        data: shippingCompany,
        setData,
        isFetching,
        errors,
        setErrors,
        showSnackbar,
        prevShippingCompany,
        setPrevShippingCompany,
      }}
    >
      {params.id ? <Details /> : <Register />}
    </ShippingCompanyProvider>
  );
}
