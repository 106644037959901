import styled from 'styled-components';
import { Text } from 'v3/components';
import theme from 'v3/theme';

export const DetailsHeader = styled.header`
  width: 100%;
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  grid-column-gap: 1rem;
  margin-bottom: 0.5rem;

  .customersContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0.25rem;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    .customersContainer {
      grid-template-columns: 1fr;
    }
  }
`;

export const DetailsRange = styled.div`
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 1rem;

  .generalInfo {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
`;

export const LogsContainer = styled.div`
  max-height: 400px;
  overflow-y: scroll;
  scroll-behavior: smooth;
  :not(:hover)::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
  :not(:hover)::-webkit-scrollbar-track {
    opacity: 0;
  }
  :not(:hover)::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
  }
  :hover::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
  :hover::-webkit-scrollbar-track {
    background: ${theme.colors.light_gray};
    opacity: 0.5;
  }
  :hover::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border-radius: 10px;
  }
`;

export const LogItemContainer = styled.div`
  margin: 1rem 0;
  border-bottom: 1px solid #f2f2f2;
`;
export const LogItemHeader = styled.header`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 0.5rem;

  cursor: pointer;
`;
export const LogItemContent = styled(Text)`
  padding: 0.5rem;
`;
