import React, { useRef, useState, useMemo } from 'react';
import GoogleMapReact from 'google-map-react';
import useSupercluster from 'use-supercluster';
import { Row, Col } from 'react-bootstrap';
import { MarkerLetter, Text, Button } from 'v3/components';
import theme from 'v3/theme';
import { MapsContainer, StyledClusterMarker, MapButtonsCol } from './styles';
import { DefaultLoadingComponent } from 'v3/components/List';
import DetailsCard from './DetailCard';
const ClusterMarker = ({ children }) => children;

export default function MapCluster({
  travelsMapStatus,
  toggleMaps,
  branchOffices,
  queryParams,
  setToggleMaps,
  loading,
  filterTravelVehicle,
  setFilterTravelVehicle,
  checked,
  setChecked,
}) {
  const [detail, setDetail] = useState([]);
  const mapRef = useRef();
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(0);
  const points = useMemo(() => {
    if (travelsMapStatus && branchOffices) {
      const ids = queryParams == null ? queryParams :
        queryParams.includes(';')
          ? queryParams.split(';')
          : [queryParams];

      const pTravels = travelsMapStatus
        ?.filter(t => t.latitude && t.longitude)
        ?.map(travel => {
          return ({
            type: 'Feature',
            properties: {
              cluster: false,
              id: travel.travelId,
              category: travel.travelStatus,
            },
            geometry: {
              type: 'Point',
              coordinates: [
                parseFloat(travel?.longitude),
                parseFloat(travel?.latitude),
              ],
            },
          })
        });

      const pBranchOffices = branchOffices
        ?.filter(t => t.latitude && t.longitude && (ids == null || (ids != null && ids.some(id => id == t.id))))
        ?.map(branchOffice => {
          return ({
            type: 'Feature',
            properties: {
              cluster: false,
              id: branchOffice.id,
              category: 'station',
            },
            geometry: {
              type: 'Point',
              coordinates: [
                parseFloat(branchOffice?.longitude),
                parseFloat(branchOffice?.latitude),
              ],
            },
          })
        });

      return [
        ...pTravels,
        ...pBranchOffices
      ];
    } else return [];
  }, [travelsMapStatus, branchOffices]);
  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 75, minZoom: 0, maxZoom: 20, minPoints: 2 },
  });

  function retrieveDetail(id, type) {
    let data;
    if (type === 'travel') {
      data = travelsMapStatus.filter(t => t.travelId === id);
    } else {
      data = branchOffices.filter(t => t.id === id);
    }
    data[0].type = type;
    setChecked(true);
    setDetail(data[0]);
  }

  return (
    <MapsContainer>
      <Row className="d-flex align-items-center">
        <MapButtonsCol>
          <Button onClick={() => setToggleMaps(!toggleMaps)}>
            <Text type="regular" weight={500} fontSize="0.75rem">
              {toggleMaps ? 'Ver mapa' : 'Minimizar'}
            </Text>
          </Button>
          {filterTravelVehicle ? (
            <Button
              onClick={() => setFilterTravelVehicle(0)}
              variant="secondary"
            >
              <Text type="regular" weight={500} fontSize="0.75rem">
                {'Limpar seleção'}
              </Text>
            </Button>
          ) : null}
        </MapButtonsCol>
      </Row>
      <>
        {toggleMaps === false && loading === false && points ? (
          <Row>
            <Col>
              <div
                style={{ height: '50vh', width: '150%', position: 'relative' }}
              >
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: process.env.REACT_APP_GOOGLE_MAPS_KEY,
                  }}
                  defaultCenter={{ lat: -14.235, lng: -51.9253 }}
                  defaultZoom={0}
                  yesIWantToUseGoogleMapApiInternals
                  onGoogleApiLoaded={({ map }) => {
                    mapRef.current = map;
                  }}
                  onClick={() => setChecked(false)}
                  onChange={({ zoom, bounds }) => {
                    setZoom(zoom);
                    setBounds([
                      bounds.nw.lng,
                      bounds.se.lat,
                      bounds.se.lng,
                      bounds.nw.lat,
                    ]);
                  }}
                >
                  {clusters.map(cluster => {
                    const [longitude, latitude] = cluster.geometry.coordinates;
                    const {
                      cluster: isCluster,
                      point_count: pointCount,
                      category,
                    } = cluster.properties;
                    if (isCluster) {
                      return (
                        <ClusterMarker
                          key={`cluster-${cluster.id}`}
                          lat={latitude}
                          lng={longitude}
                        >
                          <StyledClusterMarker
                            style={{
                              width: `${10 + (pointCount / points?.length) * 20
                                }px`,
                              height: `${10 + (pointCount / points?.length) * 20
                                }px`,
                            }}
                            onClick={() => {
                              const expansionZoom = Math.min(
                                supercluster.getClusterExpansionZoom(
                                  cluster.id
                                ),
                                20
                              );
                              mapRef.current.setZoom(expansionZoom);
                              mapRef.current.panTo({
                                lat: latitude,
                                lng: longitude,
                              });
                            }}
                          >
                            {pointCount}
                          </StyledClusterMarker>
                        </ClusterMarker>
                      );
                    }
                    if (!latitude || !longitude) {
                      return false;
                    }
                    return (
                      <MarkerLetter
                        key={cluster.properties.id}
                        background={
                          category === 'station'
                            ? 'posto-pen.svg'
                            : category === 'onTime'
                              ? theme.colors.success
                              : category === 'delayed'
                                ? theme.colors.danger
                                : theme.colors.orange
                        }
                        sizeIcon={category === 'station' ? 30 : 22}
                        lat={latitude}
                        lng={longitude}
                        color="#fff"
                        onClick={() => {
                          if (category === 'station') {
                            retrieveDetail(cluster.properties.id, 'station');
                          } else {
                            retrieveDetail(cluster.properties.id, 'travel');
                          }
                        }}
                      />
                    );
                  })}
                </GoogleMapReact>
                <Col>
                  {checked ? (
                    <>
                      <DetailsCard
                        load={detail}
                        checked={checked}
                      ></DetailsCard>
                    </>
                  ) : null}
                </Col>
              </div>
            </Col>
          </Row>
        ) : toggleMaps === true ? null : (
          <DefaultLoadingComponent />
        )}
      </>
    </MapsContainer>
  );
}
