import React, { useState, useEffect, useCallback } from 'react';

import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import Container from '@material-ui/core/Container';

import Card from 'components/Card';
import Select from 'components/Select';
import Button from 'components/Button';
import Fab from 'components/Fab';
import Snackbar from 'components/Snackbar';
import Label from 'components/Label';
import { Text } from 'v3/components';
import Tooltip from 'v3/components/Tooltip';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import config from '../../../config';
import Checkbox from '../../../components/CheckBox';
import FormCadastroPerfil from './FormCadastroPerfil';
import { useHistory } from 'react-router-dom';
import { usePermission } from 'hooks';
import { FaCircle, FaExclamationCircle } from 'react-icons/fa';

const drawerWidth = 330;

const useStyles = makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: '0',
    paddingTop: '80px',
    overflow: 'initial',
  },
  drawerPaper: {
    width: drawerWidth,
    paddingTop: '80px',
    zIndex: '0',
    overflow: 'initial',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    width: `calc(100% - ${0}px)`,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
}));

export default function PermissoesUsuarios() {
  const classes = useStyles();
  const history = useHistory();
  usePermission('VISUALIZAR_PERMISSOES_USUARIOS', { redirect: true });
  const [state, setState] = useState({
    permissionsList: [],
    checkedPermissions: [],
  });
  const [profileList, setProfileList] = useState([]);

  const [values, setValues] = React.useState({
    selectedProfile: '',
  });
  const [snackbar, setSnackbar] = useState({
    display: false,
    type: 'error',
    message: '',
  });

  const [formOpened, setFormOpened] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getProfileList();
  }, []);
  async function getProfileList() {
    const response = await axios.get(`${config.server}users-profiles`);
    if (response.status === 200) {
      const arrData = response.data;
      const arrFinal = [];
      for (let x = 0; x < arrData.length; x++) {
        arrFinal.push(arrData[x]);
      }

      setProfileList(arrFinal);
    }

    getPermissionsList();
  }

  async function getPermissionsList() {
    const response = await axios.get(`${config.server}permissions`);
    if (response.status === 200) {
      const arrData = [];
      let group = '';

      let itemAtual = { title: '', itens: [] };
      for (let i = 0; i < response.data.length; i++) {
        if (response.data[i].group !== group) {
          if (i > 0) {
            arrData.push(itemAtual);
          }
          group = response.data[i].group;

          itemAtual = { title: group, itens: [] };
        }
        itemAtual.itens.push(response.data[i]);
      }
      arrData.push(itemAtual);
      setState({ ...state, permissionsList: arrData });
    }
  }

  async function changeProfile(event) {
    const profile_id = event;

    try {
      const response = await axios.get(
        `${config.server}users-profiles/${profile_id}`
      );

      const profile = response.data;

      const newState = { ...state, profileSelected: profile };

      const allChecks = document.getElementsByClassName('check-permission');

      for (let x = 0; x < allChecks.length; x++) {
        allChecks[x].checked = false;
      }
      if (profile.permissions) {
        newState.checkedPermissions = [];

        for (let i = 0; i < state.permissionsList.length; i++) {
          const group = state.permissionsList[i];
          for (let x = 0; x < group.itens.length; x++) {
            newState.checkedPermissions.push({
              slug: group.itens[x].slug,
              active: profile.permissions.some(
                perm => perm.slug === group.itens[x].slug && perm.active
              ),
            });
          }
        }
      }
      setState(newState);
    } catch (e) { }
  }
  const saveProfile = async () => {
    await profilePermissionSave();

    const profileId = JSON.parse(localStorage.getItem('usuario'))?.[
      'profile_id'
    ];
    const {
      data: { permissions },
    } = await axios.get(`${config.server}users-profiles/${profileId}`);
    if (permissions) {
      localStorage.setItem('permissions', JSON.stringify(permissions));
      history.push('/permissoes-usuario');
    }
  };

  const profilePermissionSave = async () => {
    const { checkedPermissions } = state;
    const { selectedProfile } = values;
    if (checkedPermissions !== null && selectedProfile) {
      try {
        setLoading(true);
        const response = await axios.post(
          `${config.server}users-profiles/${selectedProfile}/permissions`,
          { permissions: checkedPermissions }
        );
        if (response.status === 200) {
          setValues({ selectedProfile: null });
          setSnackbar({
            type: 'success',
            message: 'Perfil salvo com sucesso!',
            display: true,
          });
        }
      } catch (e) {
        setSnackbar({
          type: 'danger',
          message: e.response.data[0].message,
          display: true,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleChangeCheckBoxPermission = slug => {
    const newPermissions = state.checkedPermissions.map(item =>
      item.slug === slug ? { ...item, active: !item.active } : item
    );

    setState({ ...state, checkedPermissions: newPermissions });
  };

  async function handleChangeSelect(event) {
    const profileId = await event.target.value;

    await setValues(oldValues => ({
      ...oldValues,
      [event.target.name]: event.target.value,
    }));

    changeProfile(profileId);
  }
  const toggleDrawer = useCallback(async (refresh = false) => {
    setFormOpened(!formOpened);
    if (refresh) {
      getProfileList();
      setValues({ selectedProfile: null });
    }
  });

  return (
    <>
      <Fab
        variant="extended"
        aria-label="Remover selecionados"
        outline
        style={{
          position: 'fixed',
          bottom: '20px',
          right: '120px',
          zIndex: '1',
          border: '2px solid rgb(33, 150, 243)',
          color: '#FFF',
          paddingLeft: '20px',
          paddingRight: '20px',
          height: '36px',
          borderRadius: '18px',
          backgroundColor: 'rgb(33, 150, 243)',
        }}
        onClick={() => toggleDrawer()}
      >
        Adicionar perfil de usuário
      </Fab>
      <Snackbar
        open={snackbar.display}
        type={snackbar.type}
        message={snackbar.message}
        onClose={() => setSnackbar(false)}
      />
      <form autoComplete="off">
        <Grid xs={12} md={6} lg={4}>
          <Select
            name="selectedProfile"
            label={
              values.selectedProfile
                ? 'Perfil selecionado'
                : 'Selecione um perfil'
            }
            placeholder="Selecione"
            value={values.selectedProfile}
            defaultValue={values.selectedProfile}
            onChange={handleChangeSelect}
            options={profileList}
            itemValue="id"
            itemTitle="name"
            noUnderline
          />
        </Grid>
      </form>

      {values.selectedProfile && (
        <Grid container spacing={2} style={{ paddingTop: 30 }}>
          {state.permissionsList.map((group, index) => {
            return (
              <Grid key={index} item md={6} lg={6} xl={6} sm={12} xs={12}>
                <Card form={1} style={{ minHeight: 220 }}>
                  <Label uppercase={1} nomargin={1} pb={1}>
                    {group.title}
                  </Label>

                  {group.itens.map(item => {
                    return (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Checkbox
                          key={item.id}
                          checked={state.checkedPermissions.some(
                            perm =>
                              perm.slug && perm.slug === item.slug && perm.active
                          )}
                          onChange={() =>
                            handleChangeCheckBoxPermission(item.slug)
                          }
                          slug={item.slug}
                          id={item.slug}
                          label={item.name}
                          labelStyle={{ width: 'auto' }}
                        />
                        <Tooltip
                          content={<Text>{item.description}</Text>}
                        >
                          <FaExclamationCircle />
                        </Tooltip>
                      </div>
                    );
                  })}
                </Card>
              </Grid>
            );
          })}

          <Container
            style={{
              padding: '15px 0',
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
            }}
            container="true"
          >
            <Button
              color="success"
              loading={loading}
              onClick={() => saveProfile()}
            >
              Salvar
            </Button>
          </Container>
        </Grid>
      )}

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={formOpened}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <FormCadastroPerfil
          open={formOpened}
          setSnackbar={setSnackbar}
          toggleDrawer={toggleDrawer}
        />
      </Drawer>
    </>
  );
}
