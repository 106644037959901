/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';

import { validatePermission } from 'actions/index';

import {
  FaPhoneAlt,
  FaAddressCard,
  FaTrash,
  FaPencilAlt,
} from 'react-icons/fa';

import { BsCalendar, BsQuestion } from 'react-icons/bs';

import moment from 'moment';
import { useHistory, Link } from 'react-router-dom';

import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';

import api from 'services/api';
import driverApi from 'services/apis/driver';
import useWindowResize from 'hooks/useWindowResize';

import Card from 'v3/components/Card';
import Input from 'v3/components/Input';
import Radio from 'v3/components/Radio';
import Upload from 'v3/components/Upload';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import ApplicationLayout from 'v3/layouts/Application';
import InputAddress from 'v3/components/InputAddress';
import Select from 'v3/components/Select';
import Table from 'v3/components/Table';
import Tooltip from 'v3/components/Tooltip';
import { useSnackbar } from 'v3/components/Snackbar';
import { fetchCountries } from 'services/api/Locations';
import { DatePicker } from 'v3/components';
import { validator, validatorPhones, ValidatorPaymentsCard } from './validator';

import PaymentCard from './PaymentCards';
import Vehicles from './Vehicles';
import EmergencyContact from './EmergencyContact';

import * as Styled from './styles';
import UpdateToDriverDialogComponent from './UpdateToDriverDialog';

const cnhTypes = [
  { value: 'A', label: 'A' },
  { value: 'AB', label: 'AB' },
  { value: 'AC', label: 'AC' },
  { value: 'AD', label: 'AD' },
  { value: 'AE', label: 'AE' },
  { value: 'B', label: 'B' },
  { value: 'C', label: 'C' },
  { value: 'D', label: 'D' },
  { value: 'E', label: 'E' },
];

function Register() {
  const history = useHistory();
  const snackbar = useSnackbar();

  const [idNewRegister, setIdNewRegister] = useState(0);
  const [name, setName] = useState();
  const [isInternational, setIsInternational] = useState(false);
  const [maskFunction, setMaskFunction] = useState(() => false);
  const [cpf, setCpf] = useState();
  const [birthdate, setBirthdate] = useState();
  const [birthCity, setBirthCity] = useState();
  const [rg, setRg] = useState();
  const [rgEmissor, setRgEmissor] = useState();
  const [rgDispatchProvince, setRGDispatchProvince] = useState();
  const [dateEmission, setDateEmission] = useState();
  const [civilStatus, setCivilState] = useState([]);
  const [mothersName, setMothersName] = useState();
  const [fatherName, setFatherName] = useState();
  const [pis, setPis] = useState();

  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [contactNumber, setContactNumber] = useState({
    name: '',
    phone: '',
  });
  const [contacts, setContacts] = useState([]);
  const [contactNameError, setContactNameError] = useState();
  const [contactPhoneError, setContactPhoneError] = useState();

  const [isInternationalCnh, setIsInternationalCnh] = useState(false);
  const [cnh, setCnh] = useState();
  const [cnhType, setCnhType] = useState([]);
  const [cnhEmissorCity, setCnhEmissorCity] = useState();
  const [cnhFirstDate, setCnhFirstDate] = useState();
  const [cnhDispatchDate, setCnhDispatchDate] = useState();
  const [cnhExpDate, setCnhExpDate] = useState();
  const [cnhKey, setCnhKey] = useState();
  const [mopp, setMopp] = useState({
    hasMopp: false,
    expirationDate: '',
    description: '',
    init: '',
  });

  const [frontCnh, setFrontCnh] = useState();
  const [backCnh, setBackCnh] = useState();

  const [isExclusive, setIsExclusive] = useState(false);
  const [isAutonomous, setIsAutonomous] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [countries, setCountries] = useState();
  const [country, setCountry] = useState({
    name: 'Brasil',
    id: 1,
    abbreviation: 'br',
  });

  const [address, setAddress] = useState();
  const [addressNumber, setAddressNumber] = useState();
  const [addressComplement, setAddressComplement] = useState();
  const [addressNeighborhood, setAddressNeighborhood] = useState();
  const [addressCep, setAddressCep] = useState();
  const [addressCity, setAddressCity] = useState();
  const [addressProvince, setAddressProvince] = useState();

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [show, setShow] = useState(false);

  const [propertyType, setPropertyType] = useState();
  const [propertyStatus, setPropertyStatus] = useState();
  const [tags, setTags] = useState([]);
  const [tagRegisterPermitted] = useState(validatePermission('CRIAR_TAGS'));
  const [provinces, setProvinces] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState();
  const [vehicles, setVehicles] = useState([]);

  const [EmergencyContactData, setEmergencyContactData] = useState();

  const [verified, setVerified] = useState(false);
  const [password, setPassword] = useState();

  const [paymentsCards, setPaymentsCard] = useState();
  const [paymentMethodsOptions, setPaymentMethodsOptions] = useState([]);

  useEffect(() => {
    if (!mopp.hasMopp) {
      setMopp(mopps => ({
        ...mopps,
        expirationDate: '',
        description: '',
        init: '',
      }));
    }
  }, [mopp.hasMopp]);

  useEffect(() => {
    if (isInternational) {
      setMaskFunction(() => false);
    } else {
      setMaskFunction(() => {
        return [
          /[0-9]/,
          /\d/,
          /\d/,
          '.',
          /\d/,
          /\d/,
          /\d/,
          '.',
          /\d/,
          /\d/,
          /\d/,
          '-',
          /\d/,
          /\d/,
        ];
      });
    }
  }, [isInternational]);

  useEffect(() => {
    async function initialFetchPaymentMethods() {
      try {
        const response = await api.get('payment/methods', {
          params: { initialFetch: true },
        });

        setPaymentMethodsOptions(response.data);
      } catch (ex) {
        // Handle exception
      }
    }

    initialFetchPaymentMethods();
  }, []);

  useEffect(() => {
    fetchCountries().then(setCountries);
  }, []);

  useEffect(() => {
    if (isInternationalCnh) {
      setMaskFunction(() => false);
    } else {
      setMaskFunction(() => {
        return [
          /[0-9]/,
          /\d/,
          /\d/,
          '.',
          /\d/,
          /\d/,
          /\d/,
          '.',
          /\d/,
          /\d/,
          /\d/,
          '-',
          /\d/,
          /\d/,
        ];
      });
    }
  }, [isInternationalCnh]);

  async function uploadFile(cnhFile, driverId, side) {
    const data = new FormData();
    data.append('image_cnh', cnhFile);

    try {
      const response = await driverApi.post(
        `/driver/${driverId}/${side}`,
        data
      );

      return response.data;
    } catch (err) {
      if (cnhFile.size > 1090000) {
      }
    } finally {
      // setSending(false);
    }
  }

  async function handleRegister() {
    setErrors({});

    try {
      const data = {
        cgccpf: cpf,
        email,
        rg_emissor: rgEmissor,
        rg,
        mothers_name: mothersName,
        father_name: fatherName,
        pispasep: pis,
        social_name: name?.replace(/\s+/g, ' ').trim(),
        first_cnh_date: cnhFirstDate,
        cnh_dispatch_date: cnhDispatchDate,
        birthdate,
        cnh_exp_date: cnhExpDate,
        rg_dispatch_date: dateEmission,
        cnh_number: cnh,
        cnh_type: cnhType?.value,
        cnh_key: cnhKey,
        born_city_id: birthCity ? birthCity.ibge_code : null,
        cnh_dispatch_city: cnhEmissorCity?.ibge_code,
        cnh_dispatch_state: cnhEmissorCity?.province.ibge_code,
        rg_dispatch_province: rgDispatchProvince
          ? rgDispatchProvince.ibge_code
          : null,
        mopp: mopp.hasMopp,
        mopp_description: mopp.description,
        mopp_expiration: mopp.expirationDate,
        mopp_date_init: mopp.init,
        complement: addressComplement || null,
        exclusive: isExclusive,
        is_owner: isOwner,
        is_autonomous: isAutonomous,
        neighborhood: addressNeighborhood,
        phone,
        phones: contacts,
        is_driver: 1,
        is_international: isInternational,
        cnh_is_foreign: isInternationalCnh,
        property_type: propertyType?.value,
        property_status: propertyStatus ? propertyStatus.value : null,
        civil_state_id: civilStatus ? civilStatus.value : null,
        uf: address ? address.province : null,
        user_password: password,
        city_name: addressCity?.name,
        user_verified: verified,
        paymentMethod,
        vehicles:
          vehicles.length > 0
            ? vehicles.map(vehicle => ({
                id: vehicle.id,
                current: vehicle.current,
              }))
            : [],
        EmergencyContactData,
      };

      data.addressObject = addressProvince?.uf
        ? {
            address,
            number: addressNumber,
            complement: addressComplement,
            neighborhood: addressNeighborhood,
            city_name: addressCity?.name,
            uf: addressProvince?.uf,
            uf_name: addressProvince?.name,
            zip_code: addressCep,
            country_id: country.id,
          }
        : null;
      data.selectedTagList = tags.map(({ id }) => ({ value: id }));

      if (paymentsCards.length > 0) {
        await ValidatorPaymentsCard.validate(paymentsCards, {
          abortEarly: false,
        });
      }

      const isValid = await validator.validate(
        { ...data, hasMopp: mopp.hasMopp },
        { abortEarly: false, context: { data } }
      );

      if (!isValid) {
        return false;
      }

      data.birthdate = moment(birthdate, 'DD/MM/YYYY').toDate();
      data.rg_dispatch_date = moment(dateEmission, 'DD/MM/YYYY').toDate();
      data.first_cnh_date = moment(cnhFirstDate, 'DD/MM/YYYY').toDate();
      data.cnh_dispatch_date = moment(cnhDispatchDate, 'DD/MM/YYYY').toDate();
      data.cnh_exp_date = moment(cnhExpDate, 'DD/MM/YYYY').toDate();
      const hasUnderscore = phone.includes('_');
      data.phone = hasUnderscore ? phone?.replace(/_/g, '') : phone;
      data.mopp_expiration =
        moment(mopp.expirationDate, 'DD/MM/YYYY').toDate() || null;
      data.mopp_date_init = moment(mopp.init, 'DD/MM/YYYY').toDate() || null;

      setLoading(true);

      const request = await api.post('persons/add', data);

      const cardData = {
        id: request.data.id,
        data: paymentsCards,
      };

      await api.post('driver/payment/cards', cardData);

      if (frontCnh && backCnh) {
        await uploadFile(frontCnh[0], request.data.id, 'front_cnh');

        await uploadFile(backCnh[0], request.data.id, 'back_cnh');
      }

      setIdNewRegister(request.data?.id);

      setShow(true);
    } catch (ex) {
      if (ex.inner) {
        ex.inner.forEach(value =>
          setErrors(old => ({ ...old, [value.path]: value.message }))
        );
      }

      if (ex.response && Array.isArray(ex.response.data)) {
        const [error] = ex.response.data;
        setErrors(old => ({ ...old, phone: error.message }));
      }

      const message = ex.response?.data?.message
        ? ex.response?.data?.message
        : ex.inner
        ? 'Verifique os campos obrigatórios'
        : 'Ocorreu algum erro durante o cadastro';
      snackbar.show(<Text>{message}</Text>, {
        type: 'error',
      });
      return;
    } finally {
      setLoading(false);
    }
  }
  const civilStatusList = [
    {
      value: 3,
      label: 'Solteiro',
    },
    {
      value: 2,
      label: 'Casado',
    },
    {
      value: 1003,
      label: 'Divorciado',
    },

    {
      value: 1,
      label: 'Viúvo',
    },
  ];

  const propertyTypeList = [
    { value: 'casa', label: 'Casa' },
    { value: 'apartamento', label: 'Apartamento' },
    { value: 'zona rural', label: 'Zona rural' },
  ];

  const propertyStatusList = [
    { value: 'proprio', label: 'Próprio' },
    { value: 'alugado', label: 'Alugado' },
    { value: 'financiado', label: 'Financiado' },
    { value: 'outros', label: 'Outros' },
  ];
  async function fetchCities(search) {
    try {
      const response = await api.get('cities', { params: { search } });
      return response.data;
    } catch (ex) {
      return [];
    }
  }

  async function fetchTags(search) {
    try {
      const response = await api.get('tags', { params: { search } });
      return response.data.data;
    } catch (ex) {
      return [];
    }
  }

  async function fetchPaymentMethods(search) {
    try {
      const response = await api.get('payment/methods', { params: { search } });
      return response.data;
    } catch (ex) {
      return [];
    }
  }

  useEffect(() => {
    async function fetchProvinces() {
      try {
        const response = await api.get('provinces');
        setProvinces(response.data);
      } catch (ex) {
        // Handle exeception
      }
    }

    fetchProvinces();
  }, []);
  const handleClose = () => setShow(false);

  function HandleRedirectNewDriver() {
    history.push('/motoristas');
  }

  function HandleRedirectDriver() {
    window.location.reload();
  }

  async function handleContact() {
    setContactPhoneError(null);
    setContactNameError(null);
    try {
      await validatorPhones.validate(
        { ...contactNumber },
        { abortEarly: false }
      );

      setContacts([
        ...contacts,
        { name: contactNumber.name, phone: contactNumber.phone },
      ]);

      setContactNumber({ name: '', phone: '' });
    } catch (err) {
      if (err.inner) {
        err.inner.forEach(value => {
          if (value.path === 'name') {
            setContactNameError(value.message);
          } else {
            setContactPhoneError(value.message);
          }
        });
      }
    }
  }

  function handleEditContact(id) {
    if (contactNumber.name || contactNumber.phone) {
      return;
    }

    const { name, phone } = contacts[id];
    setContactNumber({ name, phone });

    setContacts(oldContacts => oldContacts.filter((_, index) => index !== id));
  }

  function handleRemoveContact(id) {
    setContacts(oldContacts => oldContacts.filter((_, index) => index !== id));
  }

  const [saveContainerOffset, setSaveContainerOffset] = useState(0);

  useWindowResize(() => {
    const header = document.getElementById('header-container');
    const originDistanceToRight =
      window.pageXOffset + header.getBoundingClientRect().right;
    const headerWidth = header.getBoundingClientRect().width;

    const SAVE_CONTAINER_PADDING = 0;
    const distanceToRight =
      originDistanceToRight - headerWidth + SAVE_CONTAINER_PADDING;
    setSaveContainerOffset(distanceToRight);
  }, []);

  const [isValidNewOption, setIsValidNewOption] = useState(false);
  const [inputTag, setInputTag] = useState('');

  function tagTreatment(input) {
    if (input) {
      const inputFiltered = input?.trimStart()?.replace(/[^a-zA-Z0-9 ]/g, '');
      setInputTag(inputFiltered);
      setIsValidNewOption(inputFiltered.trim().length >= 3);
    } else {
      setInputTag('');
      setIsValidNewOption(false);
    }
  }

  const [cpfFound, setCpfFound] = React.useState(false);
  const [cpfID, setCpfID] = React.useState(0);

  async function verifyCpf(cpf) {
    try {
      const response = await api.get(`driver/check-existent/${cpf}`);
      if (response.status === 200) {
        setCpfID(response.data.id);
        setCpfFound(true);
      } else if (response.status === 204) {
        setCpfFound(false);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function handleTagCreate(tagValue) {
    if (tagRegisterPermitted) {
      if (tagValue) {
        tagValue = tagValue.trim();
        if (tagValue.length >= 3) {
          try {
            const response = await api.post('tags', { name: tagValue });
            if (response.status === 200) {
              const newTag = {
                id: response.data.id,
                name: tagValue,
              };
              setInputTag('');
              setTags(tags ? [...tags, newTag] : [newTag]);
            }
          } catch (err) {}
        }
      }
    } else {
      snackbar.show(<Text>Notificação sem link</Text>, {
        type: 'error',
      });
    }
  }

  useEffect(() => {
    if (!password) {
      setVerified(false);
    }
  }, [password]);

  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg" backdrop="static">
        <Modal.Header closeButton onClick={handleClose}>
          <Modal.Title>
            <Text type="subtitle" color="dark">
              Motorista cadastrado!
            </Text>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Text type="body" color="dark">
            Motorista <b>{name}</b> cadastrado com sucesso!
          </Text>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={HandleRedirectNewDriver}>
            <Text>Ver Motoristas</Text>
          </Button>
          <Link to={`/motoristas/${idNewRegister}`}>
            <Button variant="primary">
              <Text>Visualizar Motorista</Text>
            </Button>
          </Link>
          <Button variant="success" onClick={HandleRedirectDriver}>
            <Text>Adicionar Motorista</Text>
          </Button>
        </Modal.Footer>
      </Modal>
      <ApplicationLayout
        title="Motorista"
        RightComponent={
          <Styled.SaveContainer style={{ right: saveContainerOffset }}>
            <Button variant="secondary" onClick={() => history.goBack()}>
              <Text color="dark" type="regular">
                Voltar
              </Text>
            </Button>
            <Button
              variant="success"
              loading={loading}
              onClick={() => handleRegister()}
            >
              <Text weight={500} color="white" type="regular">
                Salvar motorista
              </Text>
            </Button>
          </Styled.SaveContainer>
        }
      >
        <Row>
          <Col lg={6} md={6} xs={12}>
            <Card
              header={
                <Text color="#464E5F" type="header">
                  Dados pessoais
                </Text>
              }
            >
              <Row className="form">
                <Col xs={12} className="mb-3">
                  <Radio.Group
                    label="Estrangeiro"
                    onChange={({ target }) => setIsInternational(target.value)}
                    value={isInternational}
                    horizontal
                  >
                    <Radio value>
                      <Text color="dark" type="label">
                        Sim
                      </Text>
                    </Radio>
                    <Radio value={false}>
                      <Text color="dark" type="label">
                        Não
                      </Text>
                    </Radio>
                  </Radio.Group>
                </Col>

                <Col xs={12}>
                  <Input
                    label="Nome *"
                    value={name}
                    onChange={event => setName(event.target.value)}
                    error={errors.social_name}
                  />
                </Col>

                <Col lg={6} md={6} xs={12}>
                  <Input
                    label={isInternational ? 'Documento *' : 'CPF *'}
                    value={cpf}
                    onChange={event => {
                      setCpf(event.target.value);
                      verifyCpf(event.target.value);
                    }}
                    masked
                    mask={maskFunction}
                    error={errors.cgccpf}
                  />
                </Col>

                {cpfFound ? (
                  <UpdateToDriverDialogComponent cpfID={cpfID} cpf={cpf} />
                ) : null}

                <Col lg={6} md={6} xs={12}>
                  <DatePicker
                    guide
                    icon={<BsCalendar color="#464E5F" />}
                    label="Data de nascimento"
                    value={birthdate}
                    onChange={event => setBirthdate(event.target.value)}
                    error={errors.birthdate}
                  />
                </Col>
                <Col xs={12}>
                  <Select.Async
                    label="Cidade natal"
                    onSearch={fetchCities}
                    value={birthCity}
                    onChange={value => setBirthCity(value)}
                    getOptionLabel={option =>
                      `${option.name}, ${option.province.uf}`
                    }
                    getOptionValue={option => option.id}
                  />
                </Col>
                {!isInternational && (
                  <>
                    <Col lg={6} md={6} xs={12}>
                      <Input
                        label="RG"
                        value={rg}
                        onChange={event => setRg(event.target.value)}
                        error={errors.rg}
                      />
                    </Col>

                    <Col lg={6} md={6} xs={12}>
                      <Input
                        label="Orgão emissor"
                        value={rgEmissor}
                        onChange={event => setRgEmissor(event.target.value)}
                        error={errors.rg_emissor}
                      />
                    </Col>
                  </>
                )}
                {!isInternational && (
                  <>
                    <Col lg={6} md={6} xs={12}>
                      <Select
                        label="UF emissor"
                        options={provinces}
                        value={rgDispatchProvince}
                        onChange={value => setRGDispatchProvince(value)}
                        getOptionLabel={option => `${option.name}`}
                        getOptionValue={option => option.id}
                      />
                    </Col>
                  </>
                )}

                {!isInternational && (
                  <>
                    <Col lg={6} md={6} xs={12}>
                      <DatePicker
                        guide
                        icon={<BsCalendar color="#464E5F" />}
                        label="Data de emissão RG"
                        value={dateEmission}
                        onChange={event => setDateEmission(event.target.value)}
                        error={errors.rg_dispatch_date}
                      />
                    </Col>
                  </>
                )}

                <Col xs={12}>
                  <Input
                    label="Mãe"
                    value={mothersName}
                    onChange={event => setMothersName(event.target.value)}
                    error={errors.mothers_name}
                  />
                </Col>
                <Col xs={12}>
                  <Input
                    label="Pai"
                    value={fatherName}
                    onChange={event => setFatherName(event.target.value)}
                    error={errors.father_name}
                  />
                </Col>
                <Col lg={6} md={6} xs={12}>
                  <Select
                    label="Estado Civil"
                    value={civilStatus}
                    onChange={value => setCivilState(value)}
                    options={civilStatusList}
                    error={errors.rg_emissor}
                  />
                </Col>
                <Col lg={6} md={6} xs={12}>
                  <Input
                    label="PIS"
                    value={pis}
                    onChange={event => setPis(event.target.value)}
                    error={errors.pispasep}
                  />
                </Col>
                <Col xs={12}>
                  <Select.Async
                    label="Tags"
                    onSearch={fetchTags}
                    multiple
                    value={tags}
                    onChange={value => setTags(value)}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                    onInputChange={e => tagTreatment(e)}
                    inputValue={inputTag}
                    onCreateOption={handleTagCreate}
                    creatable
                  />
                </Col>
              </Row>
            </Card>
            <Card
              header={
                <Text color="#464E5F" type="header">
                  Dados do usuário
                </Text>
              }
            >
              <Row className="form">
                <Col lg={12} md={12} xs={12}>
                  <Input
                    label="Senha"
                    type="password"
                    name="senha"
                    password
                    onChange={event => setPassword(event.target.value)}
                  />
                </Col>
                <Col lg={12} md={12} xs={12} className="mb-3">
                  <Radio.Group
                    disabled={!password}
                    label="Verificado"
                    onChange={({ target }) => setVerified(target.value)}
                    value={verified}
                    horizontal
                  >
                    <Radio value>
                      <Text type="label">Sim</Text>
                    </Radio>
                    <Radio value={false}>
                      <Text color="dark" type="label">
                        Não
                      </Text>
                    </Radio>
                  </Radio.Group>
                </Col>
              </Row>
            </Card>
            <Card
              header={
                <Text color="#464E5F" type="header">
                  Contatos
                </Text>
              }
            >
              <Row className="form">
                <Col lg={6} md={6} xs={12}>
                  <Input
                    icon={<FaPhoneAlt color="#464E5F" />}
                    value={phone}
                    onChange={event => setPhone(event.target.value)}
                    masked
                    mask={[
                      '(',
                      /[0-9]/,
                      /\d/,
                      ')',
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      '-',
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                    ]}
                    label="Telefone principal (cooperplace) *"
                    error={errors.phone}
                  />
                </Col>
                <Col xs={12}>
                  <Input
                    label="Email"
                    value={email}
                    onChange={event => setEmail(event.target.value)}
                  />
                </Col>
                <Col xs={12} className="my-3">
                  <Text type="body" color="#464E5F">
                    Outros contatos
                  </Text>
                </Col>
                <Col xs={12}>
                  <Input
                    label="Nome"
                    value={contactNumber.name}
                    onChange={({ target }) =>
                      setContactNumber({
                        ...contactNumber,
                        name: target.value,
                      })
                    }
                    error={contactNameError}
                  />
                </Col>
                <Col lg={6} md={6} xs={12}>
                  <Input
                    icon={<FaPhoneAlt color="#464E5F" />}
                    value={contactNumber.phone}
                    onChange={({ target }) =>
                      setContactNumber({
                        ...contactNumber,
                        phone: target.value,
                      })
                    }
                    masked
                    mask={[
                      '(',
                      /[0-9]/,
                      /\d/,
                      ')',
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                    ]}
                    label="Telefone"
                    error={contactPhoneError}
                  />
                </Col>
                <Col lg={6} md={6} xs={12}>
                  <Button
                    variant="success"
                    onClick={() => handleContact()}
                    style={{ marginTop: '32px' }}
                  >
                    <Text weight={600} type="regular">
                      Adicionar
                    </Text>
                  </Button>
                </Col>
                <Col xs={12} className="mt-3">
                  {contacts.length > 0 && (
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>
                            <Text type="label" weight="500">
                              Nome
                            </Text>
                          </th>
                          <th className="text-center">
                            <Text type="label" weight="500">
                              Telefone
                            </Text>
                          </th>
                          <th className="text-center">
                            <Text type="label" weight="500">
                              Ação
                            </Text>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {contacts
                          .sort((a, b) => a.name - b.name)
                          .map((contact, index) => (
                            <tr key={index}>
                              <td>
                                <Text type="regular" weight="400">
                                  {contact.name}
                                </Text>
                              </td>
                              <td width="240" className="text-center">
                                <Text type="regular">{contact.phone}</Text>
                              </td>
                              <td width="120" className="text-center">
                                <Button
                                  variant="primary"
                                  size="sm"
                                  className="mr-1"
                                  onClick={() => handleEditContact(index)}
                                >
                                  <FaPencilAlt />
                                </Button>
                                <Button
                                  variant="error"
                                  size="sm"
                                  onClick={() => handleRemoveContact(index)}
                                >
                                  <FaTrash />
                                </Button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  )}
                </Col>
              </Row>
            </Card>
          </Col>

          <Col lg={6} md={6} xs={12}>
            <Card
              header={
                <Text color="#464E5F" type="header">
                  CNH
                </Text>
              }
            >
              <Row className="form">
                <Col xs={12} className="mb-3">
                  <Radio.Group
                    label="CNH Estrangeira"
                    onChange={({ target }) =>
                      setIsInternationalCnh(target.value)
                    }
                    value={isInternationalCnh}
                    horizontal
                  >
                    <Radio value>
                      <Text color="dark" type="label">
                        Sim
                      </Text>
                    </Radio>
                    <Radio value={false}>
                      <Text color="dark" type="label">
                        Não
                      </Text>
                    </Radio>
                  </Radio.Group>
                </Col>
                <Col lg={6} md={6} xs={12}>
                  <Input
                    label="CNH"
                    value={cnh}
                    onChange={event => setCnh(event.target.value)}
                    error={errors.cnh_number}
                    guide={false}
                    masked
                    mask={[
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                    ]}
                  />
                </Col>
                {!isInternational && !isInternationalCnh && (
                  <>
                    <Col lg={6} md={6} xs={12}>
                      <Select
                        label="Categoria"
                        getOptionValue={value => value.value}
                        getOptionLabel={value => value.value}
                        value={cnhType}
                        options={cnhTypes}
                        error={errors.cnhType}
                        onChange={value => setCnhType(value)}
                      />
                    </Col>
                  </>
                )}

                {!isInternational && !isInternationalCnh && (
                  <>
                    <Col xs={12}>
                      <Input
                        label="Chave CNH"
                        value={cnhKey}
                        onChange={event => setCnhKey(event.target.value)}
                        error={errors.cnhKey}
                      />
                    </Col>
                    <Col xs={12}>
                      <Select.Async
                        label="Cidade emissão CNH"
                        onSearch={fetchCities}
                        value={cnhEmissorCity}
                        onChange={value => setCnhEmissorCity(value)}
                        getOptionLabel={option =>
                          `${option.name}, ${option.province.uf}`
                        }
                        getOptionValue={option => option.id}
                        error={errors.cnhEmissorCity}
                      />
                    </Col>
                  </>
                )}

                <Col lg={6} md={6} xs={12}>
                  <Input
                    icon={<BsCalendar color="#464E5F" />}
                    label="1° habilitação"
                    value={cnhFirstDate}
                    onChange={event => setCnhFirstDate(event.target.value)}
                    masked
                    mask={[
                      /[0-9]/,
                      /[0-9]/,
                      '/',
                      /[0-9]/,
                      /[0-9]/,
                      '/',
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                    ]}
                    error={errors.first_cnh_date}
                  />
                </Col>
                <Col lg={6} md={6} xs={12}>
                  <Input
                    icon={<BsCalendar color="#464E5F" />}
                    label="Data de emissão CNH"
                    value={cnhDispatchDate}
                    onChange={event => setCnhDispatchDate(event.target.value)}
                    masked
                    mask={[
                      /[0-9]/,
                      /[0-9]/,
                      '/',
                      /[0-9]/,
                      /[0-9]/,
                      '/',
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                    ]}
                    error={errors.cnh_dispatch_date}
                  />
                </Col>
                <Col lg={6} md={6} xs={12}>
                  <Input
                    icon={<BsCalendar color="#464E5F" />}
                    label="Validade habilitação"
                    value={cnhExpDate}
                    onChange={event => setCnhExpDate(event.target.value)}
                    masked
                    mask={[
                      /[0-9]/,
                      /[0-9]/,
                      '/',
                      /[0-9]/,
                      /[0-9]/,
                      '/',
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                    ]}
                    error={errors.cnh_exp_date}
                  />
                </Col>

                <Col xs={12} className="mb-3">
                  <Radio.Group
                    label="Possui MOPP"
                    onChange={({ target }) =>
                      setMopp(mopp => ({
                        ...mopp,
                        hasMopp: target.value,
                      }))
                    }
                    value={mopp.hasMopp}
                    horizontal
                  >
                    <Radio value>
                      <Text color="dark" type="label">
                        Sim
                      </Text>
                    </Radio>
                    <Radio value={false}>
                      <Text color="dark" type="label">
                        Não
                      </Text>
                    </Radio>
                  </Radio.Group>
                </Col>
                {mopp.hasMopp && (
                  <>
                    <Col xs={12} className="mt-3">
                      <Input
                        label={
                          <>
                            <>Descrição Mopp</>
                            <Tooltip
                              content={
                                <Text>
                                  Se preencher qualquer campo mopp, todos se
                                  tornarão obrigatórios
                                </Text>
                              }
                            >
                              <Text>
                                <BsQuestion color="#464E5F" />
                              </Text>
                            </Tooltip>
                          </>
                        }
                        value={mopp.description}
                        onChange={({ target }) =>
                          setMopp(mopp => ({
                            ...mopp,
                            description: target.value,
                          }))
                        }
                        error={errors.mopp_description}
                      />
                    </Col>
                    <Col lg={6} md={6} xs={12}>
                      <Input
                        icon={<BsCalendar color="#464E5F" />}
                        label="Primeiro Mopp"
                        value={mopp.init}
                        onChange={({ target }) =>
                          setMopp(mopp => ({
                            ...mopp,
                            init: target.value,
                          }))
                        }
                        masked
                        mask={[
                          /[0-9]/,
                          /[0-9]/,
                          '/',
                          /[0-9]/,
                          /[0-9]/,
                          '/',
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                        ]}
                        error={errors.mopp_date_init}
                      />
                    </Col>
                    <Col lg={6} md={6} xs={12}>
                      <Input
                        icon={<BsCalendar color="#464E5F" />}
                        label="Validade do Mopp"
                        value={mopp.expirationDate}
                        onChange={({ target }) =>
                          setMopp(mopp => ({
                            ...mopp,
                            expirationDate: target.value,
                          }))
                        }
                        masked
                        mask={[
                          /[0-9]/,
                          /[0-9]/,
                          '/',
                          /[0-9]/,
                          /[0-9]/,
                          '/',
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                        ]}
                        error={errors.mopp_expiration}
                      />
                    </Col>
                  </>
                )}
              </Row>
              <Row className="form">
                <Col lg={4} md={4} xs={12} className="mb-3">
                  <Radio.Group
                    label="Exclusivo"
                    onChange={({ target }) => setIsExclusive(target.value)}
                    value={isExclusive}
                    horizontal
                  >
                    <Radio value>
                      <Text color="dark" type="label">
                        Sim
                      </Text>
                    </Radio>
                    <Radio value={false}>
                      <Text color="dark" type="label">
                        Não
                      </Text>
                    </Radio>
                  </Radio.Group>
                </Col>

                <Col lg={4} md={4} xs={12} className="mb-3">
                  <Radio.Group
                    label="Autônomo"
                    onChange={({ target }) => setIsAutonomous(target.value)}
                    value={isAutonomous}
                    horizontal
                  >
                    <Radio value>
                      <Text color="dark" type="label">
                        Sim
                      </Text>
                    </Radio>
                    <Radio value={false}>
                      <Text color="dark" type="label">
                        Não
                      </Text>
                    </Radio>
                  </Radio.Group>
                </Col>
                <Col lg={4} md={4} xs={12} className="mb-3">
                  <Radio.Group
                    label="Proprietário"
                    onChange={({ target }) => setIsOwner(target.value)}
                    value={isOwner}
                    horizontal
                  >
                    <Radio value>
                      <Text color="dark" type="label">
                        Sim
                      </Text>
                    </Radio>
                    <Radio value={false}>
                      <Text color="dark" type="label">
                        Não
                      </Text>
                    </Radio>
                  </Radio.Group>
                </Col>

                <Col xs={12} className="mb-3">
                  <Text type="label" color="#464E5F">
                    Upload de CNH
                  </Text>

                  <Row className="form">
                    <Col lg={6} md={6} xs={12} className="text-center mt-3">
                      <Text type="label" color="#464E5F">
                        Frente
                      </Text>
                      <Upload
                        icon={<FaAddressCard size={24} color="#464E5F" />}
                        label="Arquivo máximo de 1MB"
                        accept={['.png', '.jpg']}
                        onChange={event => setFrontCnh(event.target.files)}
                        value={frontCnh}
                        name="front_cnh"
                      />
                    </Col>
                    <Col lg={6} md={6} xs={12} className="text-center mt-3">
                      <Text type="label" color="#464E5F">
                        Verso
                      </Text>
                      <Upload
                        icon={<FaAddressCard size={24} color="#464E5F" />}
                        label="Arquivo máximo de 1MB"
                        accept={['.png', '.jpg']}
                        onChange={event => setBackCnh(event.target.files)}
                        value={backCnh}
                        name="back_cnh"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
            <Card
              header={
                <Text color="#464E5F" type="header">
                  Endereço
                </Text>
              }
            >
              <Row className="form">
                <Col xs={12}>
                  <Select
                    label="País"
                    options={countries}
                    value={country}
                    onChange={value => setCountry(value)}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                    disableClear
                  />
                </Col>
                <Col xs={12}>
                  <InputAddress
                    label="Endereço"
                    value={address}
                    onSelectedAddress={place => {
                      setAddress(place.address);
                      setAddressNeighborhood(place.neighborhood);

                      const province = {
                        name: place.province_name,
                        uf: place.province,
                      };
                      setAddressCity({ name: place.city, province });
                      setAddressProvince(province);

                      setAddressNumber(place.number);
                      setAddressCep(place.zip_code);
                      setAddressComplement('');
                    }}
                    types={[]}
                    country={country?.abbreviation}
                    onChange={e => setAddress(e.target.value)}
                    placeholder="Procurar endereço"
                    error={errors['addressObject.address']}
                  />
                  {errors.address && (
                    <Text type="little" color="error">
                      {errors.address}
                    </Text>
                  )}
                </Col>
                <Col lg={6} md={6} xs={12}>
                  <Input
                    label="Número"
                    value={addressNumber}
                    onChange={event => setAddressNumber(event.target.value)}
                  />
                </Col>
                <Col lg={6} md={6} xs={12}>
                  <Input
                    label="Complemento"
                    value={addressComplement}
                    onChange={event => setAddressComplement(event.target.value)}
                  />
                </Col>
                <Col lg={6} md={6} xs={12}>
                  <Input
                    label="Bairro"
                    value={addressNeighborhood}
                    onChange={event =>
                      setAddressNeighborhood(event.target.value)
                    }
                    disabled
                  />
                </Col>
                <Col lg={6} md={6} xs={12}>
                  <Input
                    label="CEP"
                    value={addressCep}
                    onChange={event => setAddressCep(event.target.value)}
                    error={errors.addressCep}
                    disabled
                  />
                </Col>

                <Col xs={12}>
                  <Select.Async
                    label="Cidade"
                    onSearch={fetchCities}
                    value={addressCity}
                    onChange={value => {
                      setAddressCity(value);
                      setAddressProvince(value ? value.province : null);
                    }}
                    getOptionLabel={option =>
                      `${option.name}, ${option.province.uf}`
                    }
                    getOptionValue={option =>
                      `${option.name}, ${option.province.uf}`
                    }
                    error={errors.addressCity}
                    isDisabled
                  />
                </Col>
                <Col lg={6} md={6} xs={12} className="mb-3">
                  <Select
                    label="Tipo de endereço"
                    value={propertyStatus}
                    onChange={value => setPropertyStatus(value)}
                    options={propertyStatusList}
                  />
                </Col>
                <Col lg={6} md={6} xs={12} className="mb-3">
                  <Select
                    label="Tipo de moradia"
                    value={propertyType}
                    onChange={value => setPropertyType(value)}
                    options={propertyTypeList}
                  />
                </Col>
              </Row>
            </Card>
            <Card
              header={
                <Text color="#464E5F" type="header">
                  Pagamento
                </Text>
              }
            >
              <Select.Async
                label="Método de pagamento *"
                onSearch={fetchPaymentMethods}
                options={paymentMethodsOptions}
                value={paymentMethod}
                onChange={value => setPaymentMethod(value)}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                error={errors.paymentMethod || errors['paymentMethod.id']}
              />
            </Card>
            <PaymentCard setPaymentsCard={setPaymentsCard} errors={errors} />
            <EmergencyContact
              setEmergencyContactData={setEmergencyContactData}
              errors={errors}
            />
          </Col>
          <Col xs={12}>
            <Vehicles vehicles={vehicles} setVehicles={setVehicles} />
          </Col>
        </Row>
      </ApplicationLayout>
    </>
  );
}

export default Register;
