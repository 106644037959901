import React, { useState, useEffect, useRef } from 'react';

import Card from 'v3/components/Card';
import Text from 'v3/components/Text';
import Input from 'v3/components/Input';
import Button from 'v3/components/Button';
import Table from 'v3/components/Table';

import { FaTrash, FaPencilAlt } from 'react-icons/fa';

import { Row, Col } from 'react-bootstrap';

import { IssuerContext } from '../controller';
import { validate } from './controller';

const [, useIssuer] = IssuerContext;

export default function Series() {
  const issuer = useIssuer();

  const [serie, setSerie] = useState();
  const [number, setNumber] = useState();

  const [seriesList, setSeriesList] = useState([]);

  const [errors, setErrors] = useState({});

  useEffect(() => {
    issuer.setData(old => ({ ...old, series: seriesList }));
  }, [seriesList]);

  async function addSerie() {
    issuer.setErrors(old => ({ ...old, series: null }));
    if (!(await validate({ serie, number }, setErrors))) {
      return false;
    }

    setSeriesList(old => [...old, { serie, number }]);
    setSerie('');
    setNumber('');

    return true;
  }

  function handleRemoveSerie(index) {
    const aux = [...seriesList];
    aux.splice(index, 1);

    setSeriesList(aux);
  }

  function handleEditSerie(index) {
    const item = seriesList[index];
    setSerie(item.serie);
    setNumber(item.number);

    handleRemoveSerie(index);
  }

  return (
    <Card
      header={
        <Text color="#464E5F" type="header">
          Séries
        </Text>
      }
    >
      <Col>
        <Row className="d-flex align-items-start">
          <Col xs={4}>
            <Input
              label="Série *"
              value={serie}
              onChange={event => setSerie(event.target.value)}
              error={issuer.errors.series || errors.serie}
            />
          </Col>
          <Col xs={4}>
            <Input
              label="Número *"
              value={number}
              onChange={event => setNumber(event.target.value)}
              error={errors.number}
            />
          </Col>
          <Col xs={4}>
            <Button
              variant="primary"
              className="w-100 mb-4"
              onClick={async () => addSerie()}
              style={{ marginTop: '35px' }}
            >
              <Text weight={600} type="regular">
                Adicionar
              </Text>
            </Button>
          </Col>
        </Row>
        <Row>
          {seriesList.length > 0 && (
            <Table responsive>
              <thead>
                <tr>
                  <th>
                    <Text type="label" weight="500">
                      Série
                    </Text>
                  </th>
                  <th className="text-center">
                    <Text type="label" weight="500">
                      Número
                    </Text>
                  </th>
                  <th className="text-center">
                    <Text type="label" weight="500">
                      Ação
                    </Text>
                  </th>
                </tr>
              </thead>
              <tbody>
                {seriesList.map((serie, index) => (
                  <tr key={index}>
                    <td>
                      <Text type="regular" weight="400">
                        {serie.serie}
                      </Text>
                    </td>
                    <td width="240" className="text-center">
                      <Text type="regular">{serie.number}</Text>
                    </td>
                    <td width="120" className="text-center">
                      <Button
                        variant="primary"
                        size="sm"
                        className="mr-1"
                        onClick={() => handleEditSerie(index)}
                      >
                        <FaPencilAlt />
                      </Button>
                      <Button
                        variant="error"
                        size="sm"
                        onClick={() => handleRemoveSerie(index)}
                      >
                        <FaTrash />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Row>
      </Col>
    </Card>
  );
}
