import React, { useState, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import InfiniteScroll from 'components/InfiniteScroll';

import config from 'config';
import Lightbox from 'react-image-lightbox-rotate';
import Input from 'components/Input';
import Button from 'components/Button';
import InfoMessage from 'components/InfoMessage';
import Fab from 'components/Fab';
import moment from 'moment';
import Snackbar from 'components/Snackbar';

import axios from 'axios';
import axiosRetry from 'axios-retry';
import { makeStyles } from '@material-ui/styles';
import Sidebar from 'components/Sidebar';
import CardEmpresa from './CardEmpresa';
import TabsEmpresa from './TabsEmpresa';
import { usePermission } from 'hooks';

axiosRetry(axios, { retries: 10 });

const useStyles = makeStyles(themes => ({
  cardEmpresa: {
    paddingBottom: '15px',
  },
}));

export default function RevisarDocumentos(props) {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState('');
  const [images, setImages] = useState([]);

  const [cteNumber, setCteNumber] = useState('');
  const [driverName, setDriverName] = useState('');
  const [allImagesObj, setAllImagesObj] = useState('');

  const [photoIndex, setPhotoIndex] = useState(-1);

  const [snackbar, setSnackbar] = useState({
    display: false,
    type: 'info',
    message: '',
  });

  usePermission('REVISAR_DOCUMENTOS', { redirect: true });

  async function fetchPhotosToLightbox(reviewData, page = 1) {
    try {
      const imagesList = [];
      const allImagesObj = [];

      for (let i = 0; i < reviewData.length; i++) {
        for (let j = 0; j < reviewData[i].travelData.length; j++) {
          if (reviewData[i].travelData[j].attachmentsData.length > 0) {
            const file = {
              ...reviewData[i].travelData[j].attachmentsData[0],
              cte_number: reviewData[i].cte_number,
              phone: reviewData[i].loadData.attendedLoad
                ? reviewData[i].loadData.attendedLoad.driverData.phone
                : '',
              driver_name: reviewData[i].loadData.attendedLoad
                ? reviewData[i].loadData.attendedLoad.driverData.social_name
                : '',
              img_url: `${
                config.baseUrlDocuments +
                reviewData[i].travelData[j].attachmentsData[0].doc_path
              }/${reviewData[i].travelData[j].attachmentsData[0].doc_filename}`,
            };

            imagesList.push(file.img_url);
            allImagesObj.push(file);
          }
        }
      }

      setAllImagesObj([...allImagesObj]);
      setImages([...images, ...imagesList]);
    } catch (ex) {
      console.error(ex);
    }
  }

  const openLightbox = useCallback(
    async src => {
      const pos = images.indexOf(src);
      setPhotoIndex(pos);
      setIsOpen(true);
    },
    [images]
  );

  function filterReview(e) {
    e.preventDefault();
    let filters = '';
    filters += cteNumber ? `cte_number=${cteNumber}` : '';
    filters += driverName ? `&driver_name=${driverName}` : '';
    setFilter(filters);
  }

  function handleTitle(imgSrc) {
    try {
      const objBySrc = allImagesObj.find(x => x.img_url === imgSrc);

      return (
        ` | CT-e: ${objBySrc.cte_number} | Motorista: ${objBySrc.driver_name} | Telefone/WhatsApp: ${objBySrc.phone} |` +
        ` (${moment(objBySrc.created_time).format('DD-MM-YYYY HH:mm')}) `
      );
    } catch (ex) {
      return '';
    }
  }

  return (
    <>
      <Snackbar
        open={snackbar.display}
        type={snackbar.type}
        message={snackbar.message}
        onClose={() =>
          setSnackbar({
            display: false,
            type: '',
            message: '',
          })
        }
      />
      <Fab
        variant="extended"
        aria-label="Limpar validados"
        outline
        style={{
          position: 'fixed',
          bottom: '20px',
          right: '150px',
          zIndex: '1',
          border: '2px solid rgb(33, 150, 243)',
          paddingRight: '20px',
          paddingLeft: '20px',
          color: '#FFF',
          height: '36px',
          borderRadius: '18px',
          cursor: 'pointer',
          backgroundColor: 'rgb(33, 150, 243)',
        }}
        onClick={() => {
          document.location.href = document.location.href;
        }}
      >
        Limpar validados
      </Fab>
      <Grid container>
        <Grid item lg={10} md={12} sm={12}>
          <InfiniteScroll
            endpoint="review-attachments"
            initialFetch
            onDataFetched={obj => {
              if (obj.data.length) {
                fetchPhotosToLightbox(obj.data, obj.pagination.page);
              }
            }}
            filter={filter}
            placeholderHeight={190}
            renderItem={(item, index) => {
              return (
                <div className={classes.cardEmpresa} key={index}>
                  <CardEmpresa company={item} />
                  <TabsEmpresa
                    openLightbox={openLightbox}
                    company={item}
                    setSnackbar={setSnackbar}
                  />
                </div>
              );
            }}
            endMessage={
              <Grid
                container
                item
                xs={12}
                justify="center"
                style={{ marginTop: '20px' }}
              >
                <InfoMessage
                  icon="doneall"
                  width="auto"
                  snackColor="#8c8c8c"
                  message="Não há mais itens a serem exibidos."
                />
              </Grid>
            }
          />
        </Grid>
      </Grid>
      <Sidebar title="Filtros">
        <form onSubmit={filterReview}>
          <Input
            value={driverName}
            onChange={e => setDriverName(e.target.value)}
            label="Nome do motorista"
          />
          <Input
            value={cteNumber}
            onChange={e => setCteNumber(e.target.value)}
            label="Número do CT-e"
          />

          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '15px',
            }}
          >
            <Button style={{ marginTop: 10 }} type="submit">
              Filtrar
            </Button>
          </div>
        </form>
      </Sidebar>

      {isOpen && (
        <>
          <Button
            style={{
              position: 'fixed',
              zIndex: '10000',
              bottom: '20px',
              right: '20px',
            }}
          >
            <a
              style={{ color: '#FFF', display: 'block' }}
              href={images[photoIndex]}
              without
              rel="noopener noreferrer"
              target="_blank"
              download
            >
              Baixar
            </a>
          </Button>
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            imageTitle={`${photoIndex + 1}/${images.length}${handleTitle(
              images[photoIndex]
            )}`}
            imageLoadErrorMessage="Visualização não disponível para este documento"
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
          />
        </>
      )}
    </>
  );
}
