import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery, usePermission } from 'hooks';
import { InfiniteScroll, Text, Button } from 'v3/components';
import { useSnackbar } from 'v3/components/Snackbar';
import ApplicationLayout from 'v3/layouts/Application';
import { cooperplaceApi } from 'services/api';
import { validatePermission } from 'actions';
import Card from './OffersCard';
import Filter from './Filter';

export function Aggregations() {
  const snackbar = useSnackbar();
  const query = useQuery();
  const history = useHistory();
  const [timeValue, setTimeValue] = useState(500);
  usePermission('VISUALIZAR_OFERTAS_AGREGAMENTOS', { redirect: true });
  const [loading, setLoading] = useState(false);
  const [aggregations, setAggregations] = useState([]);
  const [pagination, setPagination] = useState({
    last_page: 1,
    current_page: 1,
    total: 0,
  });
  const hasPermissionToCreate = validatePermission(
    'EDITAR_OFERTAS_AGREGAMENTOS'
  );

  async function fetchAggregations(page = 1) {
    if (page === 1) {
      setLoading(true);
    }
    try {
      const { data } = await cooperplaceApi.get(
        `aggregation-offer?${query}&page=${page}`
      );
      const response = data.data;
      setPagination(data.meta);
      setAggregations(oldAggregations => {
        if (page === 1) {
          return response;
        }
        return [...oldAggregations, ...response];
      });
    } catch (error) {
      snackbar.show(
        <Text>Erro ao buscar agregamentos. Tente novamente mais tarde.</Text>,
        {
          type: 'error',
        }
      );
    } finally {
      setTimeout(() => {
        setLoading(false);
        setTimeValue(0);
      }, timeValue);
    }
  }

  useEffect(() => {
    fetchAggregations();
  }, []);

  function renderItem(aggregation) {
    return <Card aggregation={aggregation} key={aggregation.id} />;
  }

  return (
    <ApplicationLayout
      title="Ofertas de Agregamentos"
      RightComponent={
        <>
          {hasPermissionToCreate && (
            <Button
              variant="primary"
              onClick={() => {
                history.push('/agregamentos/cadastrar');
              }}
            >
              <Text weight={500} color="white" type="regular">
                Novo agregamento
              </Text>
            </Button>
          )}
        </>
      }
    >
      <Filter onChange={fetchAggregations} />
      <InfiniteScroll
        data={aggregations}
        onEndReach={() => {
          fetchAggregations(pagination.current_page + 1);
        }}
        hasMore={pagination.current_page < pagination.last_page}
        scrollThreshold={0.7}
        renderItem={renderItem}
        loading={loading}
      />
    </ApplicationLayout>
  );
}
