const Yup = require('yup');

export const updateTemperature = Yup.object().shape({
  maxTemperature: Yup.number()
    .nullable()
    .test('both-or-neither', 'Ambas as temperaturas devem ser preenchidas', function(value) {
      const { minTemperature } = this.parent;
      return (value == null && minTemperature == null) || (value != null && minTemperature != null);
    }),
  minTemperature: Yup.number()
    .nullable()
    .test('both-or-neither', 'Ambas as temperaturas devem ser preenchidas', function(value) {
      const { maxTemperature } = this.parent;
      return (value == null && maxTemperature == null) || (value != null && maxTemperature != null);
    })
    .test('min-less-than-max', 'A temperatura mínima não pode ser maior que a máxima', function(value) {
      const { maxTemperature } = this.parent;
      return value == null || maxTemperature == null || value <= maxTemperature;
    }),
});
