import React, { useEffect, useState, useMemo } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Text from 'v3/components/Text';
import { useSnackbar } from 'v3/components/Snackbar';
import { formatPlate, formatNumber } from 'v3/utils/formatter';
import { useValidation } from 'hooks';
import api from 'services/api';
import moment from 'moment';
import { useVehicle } from '../shared/context';
import SharedImplements from '../shared/Section';
import Inputs from '../shared/Implements/Inputs';
import Editable from '../shared/Editable';
import { implementsSchema } from '../shared/validator';

import { validatePermission } from 'actions/index';

function formatDate(date) {
  if (date) return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
  return 'Sem data';
}

export default function Main() {
  const vehicle = useVehicle();
  const snackbar = useSnackbar();
  const [errors, { validate }] = useValidation(implementsSchema);

  const [isEditing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    vehicle.setErrors(errors);
  }, [errors]);

  async function saveImplements() {
    setLoading(true);

    const [isValid, validData] = await validate(vehicle.data, {
      context: {
        vehicleType: vehicle.data.vehicleType || vehicle.data.type || {},
      },
    });

    if (!isValid) {
      snackbar.show(<Text>Verifique os campos obrigatórios</Text>, {
        type: 'error',
      });

      setLoading(false);

      return;
    }

    const payload = {
      vehicle: {
        vehicle_implement_type: validData?.implement?.id || null,
        vehicle_body_type_id: validData.body.id,
      },
      wagons: [],
    };

    if (
      vehicle.data.vehicleType?.qty_plates === 4 ||
      vehicle.data.type?.qty_plates === 4
    ) {
      const vehicleImplements = validData.implements || [];
      payload.wagons = vehicleImplements.map(wagon => ({
        id: wagon.identifier?.id,
      }));
    }

    try {
      await api.put(`v3/vehicle/implement/${validData.id}`, payload);
      vehicle.setPrevVehicle(validData);
      snackbar.show(<Text>Salvo com sucesso</Text>, { type: 'success' });
    } catch (error) {
      snackbar.show(<Text>Erro ao salvar</Text>, { type: 'error' });
    } finally {
      setEditing(false);
      setLoading(false);
      vehicle.fetchVehicle();
    }
  }

  const implementAndAxis = useMemo(() => {
    if (vehicle.data?.implement?.name) {
      return `${vehicle.data.implement.name} - ${vehicle.data.implement.qty_axis} eixos`;
    }

    return '-';
  }, [vehicle.data?.implement]);

  return (
    <SharedImplements
      Title="Implementos"
      HeaderRightComponent={
        validatePermission('ALTERAR_VEICULOS')&&
        <Editable
          isEditing={isEditing}
          setEditing={setEditing}
          onSave={saveImplements}
          isLoading={loading}
        />
      }
      loading={vehicle.isFetching}
    >
      {isEditing ? (
        <Inputs />
      ) : (
        <>
          {vehicle.data.type?.qty_plates === 4 ||
          vehicle.data.vehicleType?.qty_plates === 4 ? (
            <Row>
              <Col md={6} xs={12}>
                <Text type="label" color="#464E5F">
                  Tipo de implemento
                </Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {implementAndAxis || 'Não informado'}
                </Text>
              </Col>
              <Col md={6} xs={12}>
                <Text type="label" color="#464E5F">
                  Tipo de carroceria
                </Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {vehicle.data?.body?.name || 'Não informado'}
                </Text>
              </Col>
              {vehicle.data.implement &&
                vehicle.data.implements.map((_, index) => (
                  <>
                    <Col className="mt-4" md={6} xs={12}>
                      <Text type="label" color="#464E5F">
                        {index + 1}º implemento
                      </Text>
                      <Link
                        to={`/implementos/${vehicle.data?.implements[index]?.id}`}
                      >
                        <Text
                          type="regular"
                          color="gray"
                          as="p"
                          className="mt-2"
                        >
                          {formatPlate(vehicle.data?.implements[index]?.plate)}
                        </Text>
                      </Link>
                    </Col>
                  </>
                ))}
            </Row>
          ) : (
            <Row>
              <Col xs={12}>
                <Text type="label" color="#464E5F">
                  Tipo de carroceria
                </Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {vehicle.data?.body?.name || 'Não informado'}
                </Text>
              </Col>
            </Row>
          )}
        </>
      )}
    </SharedImplements>
  );
}
