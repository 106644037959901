import React, { useState, useEffect, useMemo } from 'react';

import { FaUnlink } from 'react-icons/fa';
import { Col, Row } from 'react-bootstrap';

import api from 'services/api';

import Card from 'v3/components/Card';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import Fallback from 'v3/components/Fallback';
import DataTable from 'v3/components/DataTable';

import theme from 'v3/theme';
import AddShippingCompany from './Modals/AddShippingCompany';
import { useUser } from './context';

export default function UserShippingCompanies() {
  const user = useUser();
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!user.id) user.setData({ shipping_companies: [] });
  }, []);

  const isEditing = useMemo(() => {
    return !!user.data?.id;
  }, [user.data?.id]);

  function handleDeleteShipper(shippingCompanyId) {
    user.setData({
      shipping_companies: user.data?.shipping_companies.filter(
        shipper => shipper.id !== shippingCompanyId
      ),
    });
  }

  async function handleDettachShipper(shippingCompanyId) {
    try {
      setLoading(true);
      await api.delete(
        `v3/user/${user.data.id}/shipping-company/${shippingCompanyId}`
      );

      user.setData({
        shipping_companies: user.data?.shipping_companies.filter(
          shipper => shipper.id !== shippingCompanyId
        ),
      });
    } catch (error) {
      user.showSnackbar('Erro ao excluir transportadora', { type: 'error' });
    } finally {
      setLoading(false);
    }
  }

  function handleRemoveShipper(shippingCompanyId) {
    if (isEditing) handleDettachShipper(shippingCompanyId);
    else handleDeleteShipper(shippingCompanyId);
  }

  const shippingCompaniesDataTable = useMemo(() => {
    const offset = (page - 1) * perPage;
    const shipper = user?.data?.shipping_companies
      ?.filter(
        item =>
          item.social_name.toLowerCase().includes(search.toLowerCase()) ||
          item.cgccpf.includes(search)
      )
      .slice(offset, perPage + offset);
    if (shipper?.length === 0 && page > 1) setPage(page - 1);
    return shipper;
  }, [user.data?.shipping_companies, page, perPage, search]);

  return (
    <>
      <AddShippingCompany isOpen={show} onClose={() => setShow(false)} />
      <Card
        loading={user.isFetching}
        header={
          <Text type="header" color="dark">
            Transportadoras
          </Text>
        }
        HeaderRightComponent={
          <>
            {!!user.data?.shipping_companies?.length && (
              <Button
                disabled={!user.hasPermissionToEdit}
                onClick={() => {
                  user.updatePrevUser();
                  setShow(true);
                }}
              >
                <Text type="regular" weight="500" color="white">
                  Adicionar
                </Text>
              </Button>
            )}
          </>
        }
      >
        <Row>
          <Fallback
            fall={!user.data?.shipping_companies?.length}
            component={
              <Col
                xs={12}
                className="align-items-center d-flex flex-column my-2"
              >
                <Text color={theme.colors.dark}>
                  Não há transportadoras cadastradas nesse usuário.
                </Text>
                <Button
                  className="mt-4"
                  onClick={() => {
                    user.updatePrevUser();
                    setShow(true);
                  }}
                  disabled={!user.hasPermissionToEdit}
                >
                  <Text type="regular" weight="500" color="white">
                    Adicionar
                  </Text>
                </Button>
              </Col>
            }
          >
            <Col>
              <DataTable
                data={shippingCompaniesDataTable}
                total={user.data?.shipping_companies?.length}
                pages={Math.ceil(
                  user.data?.shipping_companies?.length / perPage
                )}
                currentPage={page}
                onPageChanged={setPage}
                onSearchChanged={setSearch}
                onPerPageChanged={v => setPerPage(+v)}
                loading={loading}
                searchPlaceholder="CNPJ ou Razão social"
                columns={[
                  {
                    name: 'Transportadoras',
                    selector: row => row?.social_name,
                  },
                  { name: 'CPF/CNPJ', selector: row => row?.cgccpf },
                  {
                    name: 'Ação',
                    render: row => (
                      <Button
                        variant="error"
                        size="sm"
                        className="mx-1"
                        onClick={() => handleRemoveShipper(row.id)}
                        disabled={!user.hasPermissionToEdit}
                      >
                        <FaUnlink />
                      </Button>
                    ),
                  },
                ]}
              />
            </Col>
          </Fallback>
        </Row>
      </Card>
    </>
  );
}
