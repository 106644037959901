import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import api from 'services/api';
import dashboardApi from 'services/apis/dashboard';
import { Table, Text, Link, Button } from 'v3/components';
import { useSnackbar } from 'v3/components/Snackbar';
import { formatDateTime, formatPlate, pluralize } from 'v3/utils/formatter';
import Card, { Number, Description } from './Card';
import { MercosulDeallocateTravelModal } from '../Modals/MercosulScheduleDeallocateTravel';

export default function MercosulSchedule({ dashboard, name, id }) {
  const snackbar = useSnackbar();
  const [isFetching, setFetching] = useState(false);
  const [data, setData] = useState({});
  const [filter, setFilter] = useState({});
  const [currentPage, setCurrentPage] = useState({});
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [showDeallocateModal, setShowDeallocateModal] = useState(false);
  const [travelID, setTravelID] = useState(null);
  const [daysFromNow, setDaysFromNow] = useState(false);

  useEffect(() => {
    setDaysFromNow(
      moment(filter.start_date).add(1, 'days').diff(new Date(), 'days')
    );
  }, [filter.start_date]);

  const fetchMercosulTravels = useCallback(async (filters = {}) => {
    try {
      setLoading(true);
      const {
        tags = [],
        clients = [],
        negotiators = [],
        costCenters = [],
        pcps = [],
        companies = [],
        page = 1,
        start_date,
        end_date,
      } = filters;

      if (page === 1) {
        setFetching(true);
      }

      setFilter(filters);
      setCurrentPage(+page);
      const response = await dashboardApi.get('loads-attendeds-collect-order', {
        params: {
          tags: tags?.map(tag => tag.id),
          clients: clients?.map(client => client.id),
          start_date,
          negotiators: negotiators?.map(negotiator => negotiator?.id),
          costCenters: costCenters?.map(costCenter => costCenter?.id),
          pcps: pcps?.map(pcp => pcp?.id),
          companies: companies?.map(company => company?.id),
          end_date: end_date || undefined,
          page,
          mercosul: true,
          destination_time: true,
        },
      });
      setPagination(response.data?.last?.pagination);

      if (page === 1) {
        setData(response?.data);
      } else {
        setData(oldData => ({
          ...oldData,
          last: {
            ...oldData.last,
            data: [...oldData.last.data, ...response.data.last.data],
          },
        }));
      }
    } catch (error) {
      //
    } finally {
      setFetching(false);
      setLoading(false);
    }
  }, []);
  const handleCloseDeallocateModal = () => {
    setShowDeallocateModal(false);
    setTravelID(null);
  };

  async function handleDeallocateTravel() {
    try {
      setFetching(true);
      const response = await api.put(`travel-data/deallocate/${travelID}`);
      snackbar.show(<Text>{response?.data?.message}</Text>, {
        type: 'success',
      });
      fetchMercosulTravels();
    } catch (error) {
      const message =
        error?.response?.data?.error ||
        'Não foi possível desalocar viagem. Tente novamente mais tarde';
      snackbar.show(<Text>{message}</Text>, {
        type: 'danger',
      });
    } finally {
      setShowDeallocateModal(false);
      setFetching(false);
    }
  }
  return (
    <>
      <Card
        title="Previsão de agendamento (Mercosul)"
        isLoading={isFetching}
        fetchFn={fetchMercosulTravels}
        onFiltersChanged={fetchMercosulTravels}
        dashboard={dashboard}
        name={name}
        id={id}
        mercosulDashboard
        excludeFilterByBranchOffice={true}
        ListComponent={
          <>
            <Table striped>
              <thead>
                <tr>
                  <th>
                    <Text weight="500" color="dark">
                      Empresa
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Placa principal
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Placas implementos
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Nome motorista
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Status mercosul
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Previsão de finalização (data da última entrega)
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      CRT (cte)
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Última log inserida na viagem
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Link para viagem
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Desalocar viagem
                    </Text>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.last?.data.map(item => {
                  const title = `Viagem ${item?.attended_id} - Motorista ${
                    item?.driver_name || 'Não informado'
                  }`;
                  return (
                    <>
                      <tr key={item?.attended_id}>
                        <td>
                          <Text type="regular" color="gray">
                            {item?.company_name || 'Não informado'}
                          </Text>
                        </td>
                        <td>
                          <Text type="regular" color="gray">
                            {formatPlate(item?.plate) || 'Não informado'}
                          </Text>
                        </td>
                        <td>
                          <Text type="regular" color="gray">
                            {item?.plates || 'Não informado'}
                          </Text>
                        </td>
                        <td>
                          <Text type="regular" color="gray">
                            {item?.driver_name || 'Não informado'}
                          </Text>
                        </td>
                        <td>
                          <Text type="regular" color="gray">
                            {item?.status_mercosul || 'Não informado'}
                          </Text>
                        </td>
                        <td>
                          <Text type="regular" color="gray">
                            {formatDateTime(item.scheduled_time) ||
                              formatDateTime(item.scheduled_time) ||
                              'Não informado'}
                          </Text>
                        </td>
                        <td>
                          <Text type="regular" color="gray">
                            {item?.cte_number || 'Não informado'}
                          </Text>
                        </td>
                        <td>
                          <Text type="regular" color="gray">
                            {item?.obs || 'Não informado'}
                          </Text>
                        </td>
                        <td>
                          <Link
                            to={`/viagens/${item?.attended_id}`}
                            title={title}
                            target="_blank"
                          >
                            {item?.attended_id}
                          </Link>
                        </td>
                        <td>
                          <Button
                            className=""
                            color="dark"
                            variant="primary"
                            onClick={() => {
                              setTravelID(item?.attended_id);
                              setShowDeallocateModal(true);
                            }}
                            style={{
                              padding: '0.3rem 0.5rem',
                              marginTop: '-4px',
                            }}
                            disabled={!item?.driver_id && !item?.vehicle_id}
                            title={
                              item.driver_id || item.vehicle_id
                                ? 'Desalocar viagem'
                                : 'Viagem já está desalocada'
                            }
                          >
                            Desalocar viagem
                          </Button>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </Table>
            {pagination?.lastPage > 1 && pagination?.to < pagination?.total && (
              <div className="text-center mt-3 col-12">
                <Button
                  className="mt-2 ml-auto align-item-center"
                  onClick={() => {
                    fetchMercosulTravels({
                      page: currentPage + 1,
                      tags: filter.tags,
                      clients: filter.clients,
                      start_date: filter?.start_date,
                      end_date: filter?.end_date,
                      costCenters: filter?.costCenters,
                      pcps: filter?.pcps,
                      negotiators: filter?.negotiators,
                      companies: filter?.companies,
                    });
                  }}
                  loading={loading}
                >
                  <Text type="regular" color="white" truncate>
                    Ver mais
                  </Text>
                </Button>
              </div>
            )}
          </>
        }
        NumericComponent={
          <div className="d-flex justify-content-center text-center flex-column">
            <Number color="warning">{data?.total}</Number>
            <Description>
              {`${pluralize('viagem ', data?.total, 'viagens ')} ${pluralize(
                'prevista ',
                data?.total,
                'previstas '
              )} para iniciar no Mercosul após ${
                daysFromNow > 0 ? daysFromNow : 1 + daysFromNow * -1
              } dias`}
            </Description>
          </div>
        }
      />
      <MercosulDeallocateTravelModal
        showDeallocateModal={showDeallocateModal}
        handleCloseDeallocateModal={handleCloseDeallocateModal}
        handleDeallocateTravel={handleDeallocateTravel}
      />
    </>
  );
}
