import React from 'react';

import Space from 'v3/components/Space';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';

import { useVehicle } from '../context';

export default function Editable({ isEditing, setEditing, onSave, isLoading }) {
  const vehicle = useVehicle();

  function handleCancel() {
    setEditing(false);
    vehicle.setData(vehicle.prevVehicle);
    vehicle.setErrors({});
  }

  if (isEditing) {
    return (
      <Space x="5px">
        <Button variant="secondary" onClick={handleCancel}>
          <Text color="dark" weight="500" type="regular">
            Cancelar
          </Text>
        </Button>
        <Button variant="success" onClick={onSave} loading={isLoading}>
          <Text color="white" weight={500}>
            Salvar
          </Text>
        </Button>
      </Space>
    );
  }

  return (
    <Button variant="secondary" onClick={() => setEditing(true)}>
      <Text color="dark" weight="500" type="regular">
        Editar
      </Text>
    </Button>
  );
}
