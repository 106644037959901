import styled from 'styled-components';
import { Button } from 'v3/components';

export const BodyContainer = styled.div``;

export const HeadingContainer = styled.div`
  display: flex;
  gap: 8px;

  svg {
    color: #266ca8;
  }
`;

export const CenteredItem = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
  margin: 8px 0;
  height: 100%;
`;

export const ButtonContainer = styled.div`
  margin-top: 24px;
`;

export const StyledButon = styled(Button)`
  && {
    ${({ $color }) => `background-color: ${$color} !important;`}
    ${({ $width }) => `width: ${$width};`}
  }
`;
