import React, { useState } from 'react';

import { Grid } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';

import Snackbar from 'components/Snackbar';
import Input from 'components/Input';
import Button from 'components/Button';
import api from '../../services/api';
import config from '../../config';

import { Container, Card, Logo, Line, Version } from './styles';

export default function ForgotPassword(props) {
  const params = useParams();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [snackBar, setSnackBar] = useState({
    display: false,
    type: 'error',
    message: '',
  });
  const passwordValidationRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!\"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~])[A-Za-z0-9!\"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]{8,}$/;

  async function handleChangePassword() {
    if (!password) {
      setSnackBar({
        type: 'error',
        message: 'Por favor, digite uma senha',
        display: true,
      });
      return;
    }

    if (!confirmedPassword) {
      setSnackBar({
        type: 'error',
        message: 'Por favor, confirme sua senha',
        display: true,
      });
      return;
    }
    if (password !== confirmedPassword) {
      setSnackBar({
        type: 'error',
        message: 'Senhas não conferem',
        display: true,
      });
      return;
    }

    const isValid = passwordValidationRegex.test(password);
    if (!isValid) {
      setSnackBar({
        type: 'error',
        message:
          'A senha precisa ter no mínimo 8 caracteres, 1 letra maiúscula, 1 letra minúscula, e um caracter especial',
        display: true,
      });
      return;
    }

    const data = {
      password,
      confirmedPassword,
    };
    try {
      const response = await api.put(`forgot-password/${params.token}`, data);
      setLoading(true);

      if (response.status === 200) {
        setSnackBar({
          type: 'success',
          message: 'Senha alterada com sucesso!',
          display: true,
        });
      }

      setTimeout(() => {
        history.push('/login');
      }, 2000);

      return;
    } catch (ex) {
      setSnackBar({
        type: 'error',
        message: ex.response.data.error.message,
        display: true,
      });

      return;
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container>
      <Snackbar
        open={snackBar.display}
        type={snackBar.type}
        message={snackBar.message}
        onClose={() => setSnackBar(false)}
      />
      <Card>
        <Grid container direction="column" alignItems="center">
          <Grid container direction="row" justifyContent="space-between">
            <Logo
              src="/images/grupo_coopercarga.png"
              alt="Logo Grupo Coopercarga"
            />
            <Logo src="/images/Logo-CooperPlace.png" alt="Logo Cooperplace" />
          </Grid>
          <Line />
          <p>Alteração de senha</p>

          <Input
            name="password"
            value={password}
            placeholder="Sua nova senha"
            type="password"
            autoFocus
            onChange={event => {
              setPassword(event.target.value);
            }}
          />
          <Input
            name="confirmedPassword"
            value={confirmedPassword}
            placeholder="Confirme sua nova senha"
            type="password"
            autoFocus
            onChange={event => {
              setConfirmedPassword(event.target.value);
            }}
          />
        </Grid>
        <Grid container direction="column" alignItems="center">
          <Button loading={loading} onClick={() => handleChangePassword()}>
            Enviar
          </Button>
        </Grid>
      </Card>

      <Version>
        Versão {config.versionNumber}
      </Version>
    </Container>
  );
}
