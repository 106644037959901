import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Grid, Typography } from '@material-ui/core';
import api from 'services/api';
import Skeleton from '@material-ui/lab/Skeleton';

import Snackbar from 'components/Snackbar';
import Card from 'components/Card';

function AdherenceDriverReport() {
  const params = useParams();
  const [loadingInterestedDrivers, setLoadingInterestedDrivers] = useState([]);
  const [interestedDrivers, setInterestedDrivers] = useState(false);
  const [textNotFound, setTextNotFound] = useState('');
  const [snackBar, setSnackBar] = useState({
    display: false,
    type: 'error',
    message: '',
    duration: 5000,
  });

  async function getInterestedDrivers() {
    setLoadingInterestedDrivers(true);
    try {
      const response = await api.get(
        `adherence/visualizations/${params.load_id}`
      );
      if (response.status === 200) {
        if (response.data.length === 0) {
          setTextNotFound('Nenhum motorista encontrado');
          setInterestedDrivers([]);
          return;
        }
        setInterestedDrivers(response.data);
      }
    } catch (error) {
      setTextNotFound('Não foi possível obter os motoristas.');
      setSnackBar({
        display: true,
        type: 'error',
        message: 'Não foi possível obter os motoristas interessados.',
        duration: 10000,
      });
    } finally {
      setLoadingInterestedDrivers(false);
    }
  }

  useEffect(() => {
    getInterestedDrivers();
  }, []);

  return (
    <>
      <Snackbar
        open={snackBar.display}
        type={snackBar.type}
        message={snackBar.message}
        duration={snackBar.duration}
        onClose={() => setSnackBar(false)}
      />

      <p
        style={{
          fontSize: '18px',
          color: '#76838F',
          margin: '0px',
          marginBottom: '5px',
        }}
      >
        Dados da carga: {params.load_id}
      </p>
      <Grid container spacing={2} lg={8} md={8} sm={10} xs={12}>
        {interestedDrivers.length ? (
          interestedDrivers.map(driver => (
            <Card
              style={{
                marginTop: '25px',
                overflow: 'visible',
              }}
              bordered={1}
              borderhexcolor={driver.interested ? '#00bc97' : '#f44336'}
            >
              <Grid container spacing={1}>
                <Grid container xs={12}>
                  <Grid item xs={4}>
                    <Typography variant="caption" content="p" gutterBottom>
                      NOME
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      <strong>{driver.username}</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="caption" content="p" gutterBottom>
                      TELEFONE
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      <strong>{driver.phonenumber}</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="caption" content="p" gutterBottom>
                      VISUALIZAÇÕES
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      <strong>{driver.quantity}</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="caption" content="p" gutterBottom>
                      INTERESSOU ?
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      <strong
                        style={{
                          color: driver.interested ? '#00bc97' : '#f44336',
                        }}
                      >
                        {driver.interested ? 'Sim' : 'Não'}
                      </strong>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          ))
        ) : loadingInterestedDrivers ? (
          <Grid container xs={12} spacing={1}>
            <Grid item xs={12}>
              <Skeleton height={120} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton height={120} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton height={120} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton height={120} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton height={120} />
            </Grid>
          </Grid>
        ) : (
          <Card
            style={{
              marginTop: '40px',
              overflow: 'visible',
            }}
            bordered={1}
            borderhexcolor="#f44336"
          >
            <Grid
              container
              spacing={1}
              style={{ marginTop: '10px', marginBottom: '10px' }}
            >
              <Grid container xs={12}>
                <strong style={{ fontSize: '14pt' }}>{textNotFound}</strong>
              </Grid>
            </Grid>
          </Card>
        )}
      </Grid>
    </>
  );
}

export default AdherenceDriverReport;
