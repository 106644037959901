import React from 'react';
import { Text } from 'v3/components';
import Tab from 'v3/components/Tab';
import Tabs from 'v3/components/Tabs';
import { Vehicles } from './Vehicles';
import { Col, Row } from 'react-bootstrap';

export function Products({ products, policyId, refetchPolicy }) {
  return (
    <Tabs
      defaultActiveKey={0}
      style={{
        display: 'flex',
        flexWrap: 'nowrap',
        overflowX: 'auto',
        overflowY: 'hidden',
        fontSize: '0.75rem',
      }}
      linkActiveColor="dark"
      linkActiveBackgroundColor="grayish_blue"
      linkWeight="500"
      linkPadding="0.5rem 1rem"
      linkBorder=" #dee2e6 #dee2e6 #fff"
    >
      {products?.map((pd, pdIndex) => {
        let label = pd?.products
          ?.map(item => item?.label ?? `${item?.code} - ${item?.name}`)
          ?.join(' | ');
        return (
          <Tab
            key={pdIndex}
            title={label}
            eventKey={pdIndex}
            style={{ padding: '1rem 0.5rem' }}
          >
            <Row>
              <Col xs={12} md={6}>
                <Text type="label" weight={500} color="dark">
                  Rotas
                </Text>
                <Text
                  color={'dark'}
                  type="regular"
                  as={'p'}
                  style={{ whiteSpace: 'pre-wrap' }}
                >
                  {pd?.routes?.map(route => route?.name)?.join('\n')}
                </Text>
              </Col>
              <Col xs={12} md={6}>
                <Text type="label" weight={500} color="dark">
                  Responsabilidade Seguro
                </Text>
                <Text
                  color={'dark'}
                  type="regular"
                  as={'p'}
                  style={{ whiteSpace: 'pre-wrap' }}
                >
                  {pd?.responsibility_security?.map(rs => rs?.name)?.join('\n')}
                </Text>
              </Col>
            </Row>
            <Text as="p" weight={500} color={'dark'} type="regular">
              Veículos
            </Text>

            <Vehicles
              vehicles={pd?.vehicles}
              policyId={policyId}
              refetchPolicy={refetchPolicy}
            />
          </Tab>
        );
      })}
    </Tabs>
  );
}
