/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { validatePermission } from 'actions/index';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import { useSnackbar } from 'v3/components/Snackbar';
import ApplicationLayout from 'v3/layouts/Application';
import InfiniteScroll from 'v3/components/InfiniteScroll';
import { usePermission } from 'hooks';
import Card from './Card';
import Filter from './Filter';
import * as Controller from './controller';

export default function Issuers() {
  const snackbar = useSnackbar();
  const [issuers, setIssuers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    lastPage: 1,
    page: 1,
    total: 0,
  });
  const hasPermissionToSee = usePermission('VISUALIZAR_EMISSORES', {
    redirect: true,
  });

  const [hasPermissionToCreate, setPermissionToCreate] = useState();
  useState(() => {
    setPermissionToCreate(validatePermission('CADASTRAR_EMISSORES'));
  }, []);

  function fetchIssuers() {
    Controller.getIssuers({
      page: 1,
      setIssuers,
      setLoading,
      setPagination,
      snackbar,
    });
  }

  useEffect(() => {
    if (!hasPermissionToSee) return;
    fetchIssuers();
  }, []);

  return (
    <ApplicationLayout
      title="Emissores"
      RightComponent={
        <>
          {hasPermissionToCreate && (
            <Link to="/emissores/cadastrar">
              <Button variant="primary">
                <Text weight={500} color="white" type="regular">
                  Novo emissor
                </Text>
              </Button>
            </Link>
          )}
        </>
      }
    >
      <Filter
        fetch={(name, document) => {
          Controller.getIssuers({
            name,
            document,
            page: 1,
            setIssuers,
            setLoading,
            setPagination,
            snackbar,
          });
        }}
      />
      <InfiniteScroll
        loading={loading}
        data={issuers}
        renderItem={item => <Card issuer={item} snackbar={snackbar} />}
        onEndReach={() => {
          Controller.getIssuers({
            page: pagination.page + 1,
            setIssuers,
            setLoading,
            setPagination,
            snackbar,
          });
        }}
        hasMore={pagination.page < pagination.lastPage}
        scrollThreshold={0.7}
      />
    </ApplicationLayout>
  );
}
