import React, { useState, useMemo } from 'react';
import LightboxRaw from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

export default function Lightbox({
  images,
  isOpen,
  onClose,
  getImageSrc = image => image.path,
  getImageTitle = index => `${index + 1}/${images.length}`,
}) {
  const [index, setIndex] = useState(0);

  const nextIndex = useMemo(() => (index + 1) % images.length, [index]);
  const previousIndex = useMemo(
    () => (index + images.length - 1) % images.length,
    [index]
  );

  if (!isOpen) {
    return null;
  }

  return (
    <LightboxRaw
      mainSrc={getImageSrc(images[index])}
      nextSrc={getImageSrc(images[nextIndex])}
      prevSrc={getImageSrc(images[previousIndex])}
      onCloseRequest={onClose}
      onMovePrevRequest={() => setIndex(previousIndex)}
      onMoveNextRequest={() => setIndex(nextIndex)}
      imageTitle={getImageTitle(index)}
    />
  );
}
