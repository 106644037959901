import styled from 'styled-components';

import { Grid, Collapse } from '@material-ui/core';

export const ContentContacts = styled(Grid)`
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      color: ${props => props.theme.colors.secondary};
      text-transform: none;
      margin: 8px 0;
      label {
        font-weight: bold;
        margin-right: 4px;
      }
    }
  }
`;

export const StyledCollapse = styled(Collapse)`
  a {
    color: rgba(0, 0, 0, 0.87);
    text-decoration: underline;
    font-size: 11.5pt;
  }
`;
