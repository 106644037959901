/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';

import { useParams } from 'react-router-dom';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import api from 'services/api';

import Select from 'v3/components/Select';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import Modal from 'v3/components/Modal';

import { useSnackbar } from 'v3/components/Snackbar';

import { useCompany } from '../context';

export default function RiskManagerModal({ isOpen, onClose, isLoading }) {
  const params = useParams();
  const snackbar = useSnackbar();
  const [riskManagers, setRiskManagers] = useState([]);
  const [fetching, setFetching] = useState(false);
  const company = useCompany();

  const [open, setOpen] = useState(false);

  async function fetchRiskManagers(search) {
    try {
      setFetching(true);
      const endpoint = params.id
        ? `v3/company/${params.id}/riskManager`
        : `risk-manager-for-select`;
      const response = await api.get(endpoint, {
        params: { search },
      });
      return response.data;
    } catch {
      snackbar.show(<Text> Erro ao pesquisar gerenciadora de risco! </Text>, {
        type: 'error',
      });
    } finally {
      setFetching(false);
    }
    return false;
  }

  async function handleSave(event) {
    event.preventDefault();
    if (!riskManagers) {
      snackbar.show(<Text>Preencha o campo!</Text>, { type: 'error' });
      return;
    }
    if (
      company.data?.riskManagers
        ?.map(same => same.name)
        .includes(riskManagers.name)
    ) {
      snackbar.show(<Text>Gerenciadora de risco já selecionada!</Text>, {
        type: 'error',
      });
      return;
    }

    try {
      if (params.id) {
        setFetching(true);
        await api.post(
          `v3/company/${params.id}/riskManager/${riskManagers.id}`
        );
        company.setData({
          riskManagers: [...company.data.riskManagers, riskManagers],
        });
        snackbar.show(
          <Text>Gerenciadora de risco adicionada com sucesso!</Text>,
          {
            type: 'success',
          }
        );
        company.setPrevCompany(company.data);
        setRiskManagers('');
        onClose(true);
      } else {
        company.setData({
          riskManagers: [...company.data.riskManagers, riskManagers],
        });
        snackbar.show(
          <Text>Gerenciadora de risco adicionada com sucesso!</Text>,
          {
            type: 'success',
          }
        );
        company.setPrevCompany(company.data);
        setRiskManagers('');
        onClose(true);
      }
    } catch (error) {
      snackbar.show(<Text>Algo deu errado, tente novamente!</Text>, {
        type: 'error',
      });
    } finally {
      setFetching(false);
    }
  }
  const handleClose = () => setOpen(false);

  return (
    <Modal
      show={isOpen || open}
      handleClose={onClose || handleClose}
      heading="Gerenciadoras de risco"
      centered
      body={
        <Row>
          <Col className="align-items-center d-flex flex-column justify-content-center">
            <Select.Async
              label="Gerenciadora"
              placeholder="Selecione a gerenciadora..."
              onSearch={fetchRiskManagers}
              value={riskManagers}
              onChange={event => setRiskManagers(event)}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              // error={errors}
            />
            <Button
              type="submit"
              onClick={event => handleSave(event)}
              className="my-4 py-2"
              loading={isLoading || fetching}
            >
              <Text type="regular" weight="500" color="white">
                Adicionar
              </Text>
            </Button>
          </Col>
        </Row>
      }
    />
  );
}
