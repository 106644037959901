import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';

import { useValidation } from 'hooks';
import { useSnackbar } from 'v3/components/Snackbar';
import Text from 'v3/components/Text';
import api from 'services/api';
import { formatPlate } from 'v3/utils/formatter';
import moment from 'moment';
import { validatePermission } from 'actions/index';
import { useVehicle } from '../shared/context';
import SharedMain from '../shared/Section';
import Inputs from '../shared/Main/Inputs';
import Editable from '../shared/Editable';
import { mainSchema } from '../shared/validator';

function formatDate(date) {
  if (date) return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
  return 'Sem data';
}

export default function Main() {
  const vehicle = useVehicle();
  const snackbar = useSnackbar();
  const [errors, { validate }] = useValidation(mainSchema);

  const [isEditing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    vehicle.setErrors(errors);
  }, [errors]);

  async function saveMain() {
    setLoading(true);

    const [isValid, validData] = await validate(vehicle.data);
    if (!isValid) {
      snackbar.show(<Text>Verifique os campos obrigatórios</Text>, {
        type: 'error',
      });
      setLoading(false);
      return;
    }

    const rntrc_exp_date = moment(
      vehicle.data?.rntrc_exp_date,
      'DD/MM/YYYY'
    ).toDate();
    const chronotachograph_exp_date = moment(
      vehicle.data?.chronotachograph_exp_date,
      'DD/MM/YYYY'
    ).toDate();

    vehicle.setData({
      rntrc_exp_date,
      chronotachograph_exp_date,
    });

    const payload = {
      plate: validData.plate,
      chassi: validData?.chassi,
      vehicle_type_id: validData.type.id,
      vehicle_axis_id: validData.axis.id,
      antt: validData.antt,
      renavam: validData?.renavam,
      plate_city_id: validData.city?.id,
      plate_city: validData.city.name,
      plate_state: validData?.province?.uf,
      model: validData.model?.label,
      brand: validData?.brand?.name,
      year_model: validData?.year_model,
      year_manufacture: validData?.year_manufacture,
      color: validData.color?.label || null,
      fuel_id: validData?.fuel?.id || null,
      tracked: validData?.isTracked,
      tracker_id: validData?.tracker?.id,
      tracker_type_id: validData?.tracker_type?.id,
      tracker_code: validData?.tracker_code,
      tracker_login: validData?.tracker_login,
      tracker_password: validData?.tracker_password,
      antt_type: validData?.anttType?.id,
      country_id: validData.country.id,
      tags: validData.tags ? validData.tags.map(tag => tag.id) : [],
      crlv: vehicle.data?.crlv,
      rntrc_exp_date,
      chronotachograph_number: vehicle.data?.chronotachograph_number,
      chronotachograph_exp_date,
      manufacturer_id: validData?.brand?.id,
      antt_adherence: vehicle?.data?.antt_adherence,
      RNTRC_validated: vehicle?.data?.RNTRC_validated,
    };

    try {
      const { data } = await api.put(
        `v3/vehicle/main/${validData.id}`,
        payload
      );
      snackbar.show(<Text>Salvo com sucesso</Text>, { type: 'success' });
      setEditing(false);
      vehicle.setData({ RNTRC_validated: data?.RNTRC_validated });
      vehicle.setPrevVehicle(vehicle.data);
      if (vehicle.data.vehicle_type_id !== 18) {
        vehicle.setData({ implements: null, implement: null });
      }
    } catch (err) {
      if (err.response && err.response.data.field) {
        vehicle.setErrors({
          [err.response.data.field]: err.response.data.message,
        });
      }

      snackbar.show(<Text>Erro ao salvar</Text>, {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <SharedMain
      Title="Veículo tração"
      HeaderRightComponent={
        validatePermission('ALTERAR_VEICULOS') && (
          <Editable
            isEditing={isEditing}
            setEditing={setEditing}
            onSave={saveMain}
            isLoading={loading}
          />
        )
      }
      loading={vehicle.isFetching}
    >
      {isEditing ? (
        <Inputs />
      ) : (
        <Row>
          <Col xs={12} md={6}>
            <Text type="label" color="#464E5F">
              País
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle.data?.country?.name || 'Não informado'}
            </Text>
          </Col>
          <Col xs={12} md={12}>
            <Text type="label" color="#464E5F">
              Cidade
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {(vehicle.data?.city?.name &&
                vehicle.data?.province?.uf &&
                `${vehicle.data?.city?.name} - ${vehicle.data.city?.province?.uf}`) ||
                'Não informado'}
            </Text>
          </Col>

          <Col xs={12} md={6}>
            <Text type="label" color="#464E5F">
              Tipo de veículo
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle.data?.type?.name || 'Não informado'}
            </Text>
          </Col>
          <Col xs={12} md={6}>
            <Text type="label" color="#464E5F">
              Tração
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle.data?.axis?.name || 'Não informado'}
            </Text>
          </Col>
          <Col xs={12} md={6}>
            <Text type="label" color="#464E5F">
              Placa
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {formatPlate(vehicle.data?.plate)}
            </Text>
          </Col>
          <Col xs={12} md={6}>
            <Text type="label" color="#464E5F">
              Renavam
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle.data?.renavam || 'Não informado'}
            </Text>
          </Col>
          <Col xs={12} md={6}>
            <Text type="label" color="#464E5F">
              Tipo de RNTRC
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle.data?.anttType?.name || 'Não informado'}
            </Text>
          </Col>
          <Col xs={12} md={6}>
            <Text type="label" color="#464E5F">
              RNTRC
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle.data?.antt || 'Não informado'}
            </Text>
          </Col>
          <Col xs={12} md={6}>
            <Text type="label" color="#464E5F">
              Data de emissão RNTRC
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {formatDate(vehicle?.data?.antt_adherence, { isUTC: true }) ||
                'Não informado'}
            </Text>
          </Col>
          <Col xs={12} md={6}>
            <Text type="label" color="#464E5F">
              Validade RNTRC
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {(vehicle && formatDate(vehicle.data?.rntrc_exp_date)) ||
                'Não informado'}
            </Text>
          </Col>
          <Col xs={12} md={6}>
            <Text type="label" color="#464E5F">
              RNTRC validada pelo GR
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle?.data?.RNTRC_validated ? 'Sim' : 'Não'}
            </Text>
          </Col>
          <Col xs={12} md={6}>
            <Text type="label" color="#464E5F">
              CRLV
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle.data?.crlv || 'Não informado'}
            </Text>
          </Col>
          <Col xs={12} md={6}>
            <Text type="label" color="#464E5F">
              Cronotacógrafo
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle.data?.chronotachograph_number || 'Não informado'}
            </Text>
          </Col>
          <Col xs={12} md={6}>
            <Text type="label" color="#464E5F">
              Validade cronotacógrafo
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {(vehicle &&
                formatDate(vehicle.data?.chronotachograph_exp_date)) ||
                'Não informado'}
            </Text>
          </Col>
          <Col xs={12} md={6}>
            <Text type="label" color="#464E5F">
              Chassi
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle.data?.chassi || 'Não informado'}
            </Text>
          </Col>
          <Col xs={12} md={6}>
            <Text type="label" color="#464E5F">
              Marca
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle.data?.brand?.name || 'Não informada'}
            </Text>
          </Col>

          <Col xs={12} md={6}>
            <Text type="label" color="#464E5F">
              Modelo
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle.data?.model?.label || 'Não infomado'}
            </Text>
          </Col>
          <Col xs={12} md={6}>
            <Text type="label" color="#464E5F">
              Ano do modelo
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle.data?.year_model || 'Não informado'}
            </Text>
          </Col>
          <Col xs={12} md={6}>
            <Text type="label" color="#464E5F">
              Ano de fabricação
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle.data?.year_manufacture || 'Não informado'}
            </Text>
          </Col>
          <Col xs={12} md={6}>
            <Text type="label" color="#464E5F">
              Cor
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle.data?.color?.label || 'Não informada'}
            </Text>
          </Col>

          <Col xs={12} md={6}>
            <Text type="label" color="#464E5F">
              Rastreado
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle.data?.isTracked ? 'Sim' : 'Não'}
            </Text>
          </Col>
          <Col xs={12} md={6}>
            <Text type="label" color="#464E5F">
              Marca rastreador
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle.data.isTracked
                ? vehicle.data?.tracker?.name || 'Não informado'
                : 'Não rastreado'}
            </Text>
          </Col>
          <Col>
            <Text type="label" color="#464E5F">
              Tipo de rastreador
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle.data?.tracker_type?.name || 'Não informado'}
            </Text>
          </Col>

          <Col md={6} xs={12}>
            <Text type="label" color="#464E5F">
              ID do rastreador
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle.data?.tracker_code || 'Não informado'}
            </Text>
          </Col>

          <Col md={6} xs={12}>
            <Text type="label" color="#464E5F">
              Login do rastreador
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle.data?.tracker_login || 'Não informado'}
            </Text>
          </Col>

          <Col md={6} xs={12}>
            <Text type="label" color="#464E5F">
              Senha do rastreador
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle.data?.tracker_password || 'Não informado'}
            </Text>
          </Col>
          <Col xs={12} md={6}>
            <Text type="label" color="#464E5F">
              Número Rastreador/MCT
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle.data?.tracker_number || 'Não informado'}
            </Text>
          </Col>

          <Col md={6} xs={12}>
            <Text type="label" color="#464E5F">
              Combustível
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle.data?.fuel?.name || 'Não informado'}
            </Text>
          </Col>
          <Col md={6} xs={12}>
            <Text type="label" color="#464E5F">
              Nível de Serviço
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle.data?.description_service_level || 'Não informado'}
            </Text>
          </Col>
          <Col md={6} xs={12}>
            <Text type="label" color="#464E5F">
              Tags
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle.data.tags?.length > 0
                ? vehicle.data.tags.map(item => item.name).join(', ')
                : 'Nenhum'}
            </Text>
          </Col>
          {vehicle?.data?.cooperative_type && (
            <Col md={6} xs={12}>
              <Text type="label" color="#464E5F">
                Tipo cooperado
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {vehicle.data.cooperative_type}
              </Text>
            </Col>
          )}
          <Col md={6} xs={12}>
            <Text type="label" color="#464E5F">
              Veículo frota
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle?.data?.fleetType?.name || 'Não informado'}
            </Text>
          </Col>
        </Row>
      )}
    </SharedMain>
  );
}
