import React from 'react';
import { RouteStationCheckout, RouteStationText } from './styles';
import moment from 'moment';

export default function RouteStation({ station, column }) {
  return (
    <RouteStationText
      title={station.checkout && `checkout realizado às ${moment(station.checkout).format('DD/MM/YYYY HH:mm')}`}
      style={column?.componentStyles}
    >
     { station.checkout && <RouteStationCheckout/> }
     {station.name}
    </RouteStationText>
  );
}
