import { TreeItem, TreeView } from '@material-ui/lab';
import { ExpandMoreOutlined, ChevronRight } from '@material-ui/icons';
import FullscreenSharpIcon from '@mui/icons-material/FullscreenSharp';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import ApplicationLayout from 'v3/layouts/Application';
import { cooperplaceApi } from 'services/api';
import { usePermission } from 'hooks';
import Button from '@mui/material/Button';
import { StyledImg, TreeViewStyle, LoadingSpinner } from './style';
import PowerBiRlsAuth from './PowerBiRlsAuth';

function groupBy(data) {
  const grouped = {};

  data.forEach(item => {
    if (!grouped[item.workspace_name]) {
      grouped[item.workspace_name] = { [item.departament_name]: [item] };
    } else if (!grouped[item.workspace_name][item.departament_name]) {
      grouped[item.workspace_name][item.departament_name] = [item];
    } else {
      grouped[item.workspace_name][item.departament_name].push(item);
    }
  });

  return grouped;
}

export default function LoadPanelOfBi() {
  const classes = TreeViewStyle();
  const [biUrl, setBiUrl] = useState(null);
  const [biRls, setRls] = useState(null);
  const [groupedData, setGroupedData] = useState({});
  const [loading, setLoading] = useState(false);

  const [fullScreen, setFullScreen] = useState(false);
  const [iframeKey, setIframeKey] = useState(0);

  usePermission('VISUALIZA_DASHBOARD_TORRE_DE_CONTROLE_BI', { redirect: true });

  useEffect(() => {
    async function fetchData() {
      const usuario = JSON.parse(localStorage.getItem('usuario'));
      const { data } = await cooperplaceApi.get(
        `carg-panel-users/byuser/${usuario.id}`
      );
      setGroupedData(groupBy(data));
    }

    fetchData();
  }, []);

  let nodeId = 1;

  const handlePanelClick = panel => {
    setBiUrl(panel.url);
    setRls(panel.rls);
    setLoading(true);

    setIframeKey(prevKey => prevKey + 1);
  };

  const toggleIframeFullScreen = () => {
    const iframe = document.querySelector('iframe');

    if (!fullScreen) {
      if (iframe.requestFullscreen) {
        iframe.requestFullscreen();
      } else if (iframe.mozRequestFullScreen) {
        iframe.mozRequestFullScreen();
      } else if (iframe.webkitRequestFullscreen) {
        iframe.webkitRequestFullscreen();
      }
    } else if (iframe.exitFullscreen) {
      iframe.exitFullscreen();
    } else if (iframe.mozCancelFullScreen) {
      iframe.mozCancelFullScreen();
    } else if (iframe.webkitExitFullscreen) {
      iframe.webkitExitFullscreen();
    }

    if (fullScreen) {
      setFullScreen(!fullScreen);
    }
  };

  return (
    <>
      <ApplicationLayout
        title="Cooperplace Analytics"
        isOld
        onlyCenterComponent={false}
        fontSize="20px"
      />
      <Row style={{ paddingLeft: '50px', margin: 0 }} md={12}>
        <Col md={3}>
          <Col>
            {loading && (
              <div className={classes.overlay}>
                {' '}
                <LoadingSpinner />
              </div>
            )}
            <TreeView
              aria-label="file system navigator"
              defaultCollapseIcon={<ExpandMoreOutlined />}
              defaultExpandIcon={<ChevronRight />}
              className={classes.treeView}
            >
              {Object.entries(groupedData).map(([workspace, departments]) => {
                const workspaceNodeId = String(nodeId++);
                return (
                  <TreeItem
                    key={workspaceNodeId}
                    nodeId={workspaceNodeId}
                    label={workspace}
                  >
                    {Object.entries(departments).map(([department, panels]) => {
                      const departmentNodeId = String(nodeId++);
                      return (
                        <TreeItem
                          key={departmentNodeId}
                          nodeId={departmentNodeId}
                          label={department}
                        >
                          {panels.map(panel => {
                            const panelNodeId = String(nodeId++);
                            return (
                              <TreeItem
                                key={panelNodeId}
                                nodeId={panelNodeId}
                                label={panel.name}
                                onClick={e => {
                                  e.stopPropagation();
                                  handlePanelClick(panel);
                                }}
                              />
                            );
                          })}
                        </TreeItem>
                      );
                    })}
                  </TreeItem>
                );
              })}
            </TreeView>
          </Col>
        </Col>

        {biUrl === null ? (
          <Col md={9}>
            <Col md={3} lg={3} />
            <Col md={3} lg={3}>
              <StyledImg
                src="/images/PoweBiWelcome/Microsoft-Power-BI-Symbol.png"
                alt="Welcome"
                className="welcome-image"
              />
            </Col>
            <Col md={3} lg={3} />
          </Col>
        ) : (
          <Col md={9}>
            {!loading && (
              <Col
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  paddingRight: '2rem',
                }}
              >
                <Button
                  variant="contained"
                  size="small"
                  onClick={toggleIframeFullScreen}
                >
                  <FullscreenSharpIcon />
                </Button>
              </Col>
            )}

            {groupedData &&
            Object.values(groupedData)
              .flatMap(workspace => Object.values(workspace))
              .flatMap(department => department)
              .find(item => item.url === biUrl) &&
            biRls === false ? (
              <PowerBiRlsAuth
                powerBiUrl={biUrl}
                onLoad={() => setLoading(false)}
                key={iframeKey}
              />
            ) : (
              <iframe
                key={iframeKey}
                title="power-bi"
                src={biUrl}
                width="100%"
                height="800px"
                onLoad={() => setLoading(false)}
              />
            )}
          </Col>
        )}
      </Row>
    </>
  );
}
