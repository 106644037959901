import React from 'react';
import { Link } from 'react-router-dom';
import { Col } from 'react-bootstrap';

import { Card, Text, Button } from 'v3/components';

export default function CompanyListCard({ company }) {
  return (
    <Col md={6}>
      <Card
        header={
          <>
            <Text type="body" color="dark">
              {company.name}
            </Text>
            <Link to={`/empresas/${company.id}`}>
              <Button className="py-2">
                <Text type="regular" weight="500">
                  Detalhes
                </Text>
              </Button>
            </Link>
          </>
        }
      />
    </Col>
  );
}
