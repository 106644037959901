import React, { useState, useCallback, useEffect } from 'react';

import { Row, Col } from 'react-bootstrap';
import { FaQuestionCircle } from 'react-icons/fa';

import ModalRaw from 'v3/components/Modal';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import InputCurrency from 'v3/components/Input/Currency';
import Input from 'v3/components/Input';
import Radio from 'v3/components/Radio';
import Tooltip from 'v3/components/Tooltip';
import IconButton from 'v3/components/Button/Icon';

import theme from 'v3/theme';
import { ValidatorExtraFields } from '../../validator';
import { CalculatorButton } from 'v3/components/Calculator';

function Modal({
  extraFields,
  setExtraFields,
  isOpen,
  onClose,
  isReceiveClientExtra = false,
  isBRLCurrency,
  dollarToUse,
  loadCurrency,
}) {
  const [errors, setErrors] = useState([]);
  const [quantity, setQuantity] = useState([
    {
      label: '',
      value: '0',
      show_to_driver: false,
    },
  ]);
  const [repeatedValuesOnSave, setRepeatedValuesOnSave] = useState(false);

  useEffect(() => {
    if (isOpen && extraFields?.length > 0) {
      if (isReceiveClientExtra) {
        setQuantity(
          extraFields?.map(item => {
            return {
              label: item.label,
              value: item.value,
              show_to_driver: item.show_to_drivers,
              type: item.type,
            };
          })
        );
      } else
        setQuantity(
          extraFields?.map(item => {
            return {
              label: item.label,
              value: item.value,
              show_to_driver: item.show_to_driver,
            };
          })
        );
    }
  }, [isOpen]);

  function handleClose() {
    setRepeatedValuesOnSave(false);
    setQuantity([
      {
        label: '',
        value: '',
        show_to_driver: false,
      },
    ]);
    setErrors({});
    onClose();
  }
  function handleDeleteCard(idx) {
    const updated = quantity.filter((_, index) => index !== idx);
    setQuantity(updated);
  }

  function handleChangeValue(index, key, value) {
    setQuantity(old =>
      old.map((item, idx) => {
        if (index === idx) {
          return { ...item, [key]: value };
        }
        return item;
      })
    );
  }

  function hasDuplicates() {
    const auxArray = [...quantity?.map(item => item.label)];
    const status = new Set(auxArray).size !== auxArray.length;
    setRepeatedValuesOnSave(status);

    return status;
  }

  async function handleSave() {
    try {
      if (quantity.length > 0) {
        await ValidatorExtraFields.validate(quantity, {
          abortEarly: false,
        });
      }
      const checkForDuplicates = hasDuplicates();
      if (checkForDuplicates) {
        return;
      }
      if (isReceiveClientExtra) {
        setExtraFields(
          quantity.map(item => {
            return {
              label: item.label,
              value: item.value,
              show_to_drivers: item.show_to_driver,
              type: false,
            };
          })
        );
      } else {
        setExtraFields(
          quantity.map(item => {
            return {
              ...item,
              manual: true,
            };
          })
        );
      }
      setErrors({});
      handleClose();
    } catch (error) {
      if (error.inner) {
        error.inner.forEach(value =>
          setErrors(old => ({ ...old, [value.path]: value.message }))
        );
      }
    }
  }

  function labelAlreadyExists(label, index) {
    const exists = quantity.find(
      (item, i) => item.label === label && i !== index
    );
    return exists;
  }
  function handleAddField() {
    setErrors({});
    if (quantity?.length === 0) {
      setQuantity(old => [
        ...old,
        {
          label: '',
          value: '0',
          show_to_driver: false,
        },
      ]);
      return;
    }
    const mostRecent = quantity[quantity?.length - 1];
    const alreadyAdded = labelAlreadyExists(
      mostRecent?.label,
      quantity?.length - 1
    );
    if (alreadyAdded) {
      setErrors(old => ({
        ...old,
        [`[${quantity?.length - 1}].label`]: 'Nome repetido!',
      }));
    }
    if (!mostRecent?.value) {
      setErrors(old => ({
        ...old,
        [`[${quantity?.length - 1}].value`]: 'Valor obrigatório!',
      }));
      return;
    }
    if (!alreadyAdded) {
      setQuantity(old => [
        ...old,
        {
          label: '',
          value: '0',
          show_to_driver: false,
        },
      ]);
    }
  }

  const renderItems = useCallback(() => {
    return (
      <>
        {quantity.map((item, index) => (
          <>
            <Row className="form ">
              <Col md={6}>
                <Input
                  label="Nome do valor"
                  error={errors[`[${index}].label`]}
                  value={item.label}
                  onChange={event => {
                    handleChangeValue(index, 'label', event.target.value);
                  }}
                  id={`input-nome-valor-${index}`}
                />
              </Col>
              <Col md={3}>
                <InputCurrency
                  label={`Valor (${loadCurrency})`}
                  error={errors[`[${index}].value`]}
                  value={item.value}
                  onChange={(_event, value) => {
                    handleChangeValue(index, 'value', value);
                  }}
                  id={`input-valor-${index}`}
                />
              </Col>
              <Col md={3}
                className="d-flex align-items-baseline mt-4 text-right"
              >
                <CalculatorButton
                  dollarToUse={dollarToUse}
                  id={`button-calculadora-valor-extra-carga-${index}`}
                  isBRLCurrency={isBRLCurrency}
                  onChange={(event, value) => {
                    handleChangeValue(index, 'value', value);
                  }}
                />
                <IconButton
                  className="ml-2"
                  style={{ marginTop: 13 }}
                  variant="error"
                  icon="FaTrash"
                  color="white"
                  onClick={() => handleDeleteCard(index)}
                  id={`button-limpar-valor-extra-${index}`}
                />
              </Col>

              <Col md={4} className="mt-3">
                <Radio.Group
                  label="Mostrar campo para motoristas"
                  onChange={event => {
                    handleChangeValue(
                      index,
                      'show_to_driver',
                      event.target.value
                    );
                  }}
                  value={quantity[index].show_to_driver}
                  horizontal
                >
                  <Radio id={`radio-mostrar-motorista-sim-${index}`} value>
                    <Text type="label">Sim</Text>
                  </Radio>
                  <Radio
                    id={`radio-mostrar-motorista-nao-${index}`}
                    value={false}
                  >
                    <Text color="dark" type="label">
                      Não
                    </Text>
                  </Radio>
                </Radio.Group>
              </Col>
            </Row>
            <hr style={{ marginTop: 15, marginBottom: 30 }} />
          </>
        ))}
        <div md={3} className="mb-4">
          <Button
            variant="success"
            className="py-2"
            onClick={() => {
              handleAddField();
            }}
            disabled={quantity.length >= 15}
            title={quantity.length >= 15 ? 'Máximo de 15 valores' : ''}
            id="button-adicionar-valor-extra"
          >
            <Text weight={500} type="regular">
              Adicionar
            </Text>
          </Button>
        </div>
        {repeatedValuesOnSave && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Text weight="500" type="regular" color="danger">
              Algum nome informado está repetido!
            </Text>
          </div>
        )}
      </>
    );
  }, [quantity, errors, repeatedValuesOnSave, loadCurrency]);

  return (
    <>
      <ModalRaw
        show={isOpen}
        onHide={handleClose}
        size="lg"
        heading={
          <Text type="subheader" color="dark">
            Campos extras da carga
            <Tooltip
              placement="right"
              content={
                <Text type="label">
                  Valor extra que será adicionado à carga.
                </Text>
              }
            >
              <FaQuestionCircle
                color={theme.colors.light_blue}
                className="ml-2"
              />
            </Tooltip>
          </Text>
        }
        body={renderItems()}
        footer={
          <Row md={12} className="mt-1">
            <Button
              className="py-2"
              variant="primary"
              onClick={() => handleSave()}
            >
              <Text weight="500" type="regular">
                Salvar
              </Text>
            </Button>
          </Row>
        }
      />
    </>
  );
}

export default Modal;
