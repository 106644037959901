import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

export const Container = styled(Grid)`
  @media (min-width: 708px) {
    button:first-child {
      margin-right: 10px;
    }
  }
  @media (max-width: 708px) {
    button:first-child {
      margin-right: 4px;
    }
  }
  @media (max-width: 697px) {
    button:first-child {
      margin-bottom: 5px;
    }
  }
  table {
    width: 100%;
  }
  tr {
    width: 50px;
  }
  td {
    width: 100%;
    height: 30px;
    span {
      color: #000;
    }
  }
`;
