import React, { useEffect, useState } from 'react';

import api from 'services/api';
import { useModal } from 'hooks';

import HasCostCenter from './HasCostCenter';

function Alerts() {
  const hasConstCenterModal = useModal();

  const [shouldModalOpen, setShouldModalOpen] = useState(false);

  async function fetchUser() {
    try {
      const userJson = JSON.parse(localStorage.getItem('usuario'));
      const response = await api.get(`cost-centers/user/${userJson.id}`);
      localStorage.setItem('version',JSON.stringify(response.data.read_version));
      if (!response.data?.costCenters.length) setShouldModalOpen(true);
    } catch (error) {
      //
    }
  }

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <HasCostCenter
      isOpen={shouldModalOpen}
      onClose={() => {
        hasConstCenterModal.close();
      }}
    />
  );
}

export default Alerts;
