import api from 'services/api';
import moment from 'moment';
import { validate } from '../shared/validator';

export async function handleCreation(
  data,
  setData,
  setErrors,
  showSnack,
  setLoading
) {
  const isValid = await validate(
    'allSchema',
    {
      checklist: {
        item: data.item,
        required_checklist: data.required_checklist,
      },
      data: {
        name: data.name,
        priorityFleetDuration: data.priorityFleetDuration,
        can_issue: data.can_issue,
        client_type: data.client_type,
        issuers: data.issuers,
        product_type: data.product_type,
      },
      integrations: data.integrations,
      pricingOrder: data.pricingOrder,
      priorityFleetVehicles: data.priorityFleetVehicles,
      clients: data.clients,
      riskManagers: data.riskManagers,
    },
    setErrors
  );

  if (!isValid) {
    showSnack('Verifique os campos obrigatórios!', { type: 'error' });
    return false;
  }

  try {
    setLoading(true);
    const payload = {
      name: data.name,
      settings: [
        {
          key: 'PRIORITY_FLEET_LOAD_DURATION',
          value: data.priorityFleetDuration
            ? moment
                .duration(data.priorityFleetDuration.minutes_formatted)
                .asMinutes()
            : 0,
        },
        {
          key: 'REQUIRED_CHECKLIST',
          value: data.required_checklist ? 1 : 0,
        },
      ],
      can_issue: data.can_issue,
      client_type: data.client_type,
      product_type: data.product_type,
      issuers: data.issuers,
      checklist: data.checkList,
      pricingOrder: data.pricingOrder,
      clients: data.clients,
      priorityFleet: data.priorityFleetVehicles.data?.map(vehicle => ({
        id: vehicle.id || vehicle,
      })),
      integration: !!data.integrations,
      integrations: data.integrations,
      riskManagers: data.riskManagers.map(
        riskManager => riskManager.id || riskManager
      ),
      triggers: data.triggers,
      goals: data.goals,
    };
    const response = await api.post('v3/company', payload);
    setData({ id: response.data.id, modalName: 'CreatedCompany' });

    showSnack('Empresa Cadastrada', { type: 'success' });
    setLoading(false);
    return true;
  } catch (err) {
    setLoading(false);
    if (err.response.data.length > 0) {
      const errors = err.response.data;
      setErrors(old => ({
        ...old,
        ...Object.fromEntries(
          errors.map(error => [error.field, error.message])
        ),
      }));
      showSnack(err.response?.data?.[0]?.message, { type: 'error' });
      return false;
    }

    showSnack('Erro ao cadastrar empresa!', { type: 'error' });
    return false;
  }
}
