import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import Text from 'v3/components/Text';

import Button from 'v3/components/Button';
import Attachment from './Attachment';

export default function DriverAttachments({ driverAttachments: { data } }) {
  const [showAllDriverAttachments, setShowAllDriverAttachments] =
    useState(false);

  if (!data || !data.length) {
    return <></>;
  }
  const renderDriverAttachments = [...data].splice(0, 3);
  const hasAttachments = () => {
    return renderDriverAttachments.length > 0;
  };

  if (!hasAttachments()) {
    return <></>;
  }

  if (showAllDriverAttachments) {
    return (
      <>
        <Row>
          <Col md={12} className="mb-3">
            <Text type="regular" color="dark">
              Anexos
            </Text>
          </Col>

          {data.map(attachment => (
            <Col md={4} className="d-flex flex-column align-items-center mb-3">
              <Attachment attachment={attachment} attachments={data} />
              <Text type="regular" className="mt-2" color="dark">
                {attachment?.description}
              </Text>
            </Col>
          ))}

          <Col md={12} className="align-items-center d-flex flex-column my-2">
            <Button variant="primary" className="ml-2 my-2">
              <Text
                weight={500}
                type="regular"
                onClick={() => setShowAllDriverAttachments(false)}
              >
                Esconder os anexos
              </Text>
            </Button>
          </Col>
        </Row>
      </>
    );
  }

  function showDescription(desctription, qtd) {
    if (desctription?.length >= qtd) {
      return `${desctription?.substring(0, qtd)}...`;
    }
    return desctription;
  }

  return (
    <>
      <Row>
        <Col md={12} className="mb-3">
          <Text type="regular" color="dark">
            Anexos
          </Text>
        </Col>

        {renderDriverAttachments.map(attachment => (
          <Col md={4} className="d-flex flex-column align-items-center mb-3">
            <Attachment attachment={attachment} attachments={data} />
            <Text type="regular" className="mt-2" color="dark">
              {showDescription(attachment.description, 18)}
            </Text>
          </Col>
        ))}

        {data.length > 3 && (
          <Col md={12} className="align-items-center d-flex flex-column my-2">
            <Button variant="primary" className="ml-2 my-2">
              <Text
                weight={500}
                type="regular"
                onClick={() => setShowAllDriverAttachments(true)}
              >
                Ver todos os anexos
              </Text>
            </Button>
          </Col>
        )}
      </Row>
    </>
  );
}
