/* eslint-disable import/no-unresolved */
import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import { formatPlate } from 'v3/utils/formatter';
import Card from 'v3/components/Card';
import Text from 'v3/components/Text';

import { HiOutlineExternalLink } from 'react-icons/hi';

import * as Styled from './styles';

export default function VehicleCard({ vehicle }) {
  const currentDriver = vehicle.drivers.find(x => x.current);

  return (
    <Col md={6}>
      <Card
        header={
          <div>
            <Text className="mr-2" color="dark">
              {formatPlate(vehicle.plate)}
            </Text>
            {vehicle.status && vehicle.status !== 0 ? (
              <Styled.Badge pill light type="success" className="mr-2">
                <Text type="label">Ativo</Text>
              </Styled.Badge>
            ) : (
              <Styled.Badge pill light type="error" className="mr-2">
                <Text type="label">Inativo</Text>
              </Styled.Badge>
            )}
          </div>
        }
        HeaderRightComponent={
          <div>
            <Link to={`/veiculos/${vehicle.id}`}>
              <Styled.Button className="mr-2">
                <Text color="dark">Detalhes</Text>
              </Styled.Button>
            </Link>
          </div>
        }
      >
        <Row>
          <div>
            <Text type="regular" color="dark">
              {vehicle.vehicleType?.name} {vehicle.axisType?.name}
            </Text>
          </div>
        </Row>
        <Row>
          {vehicle.implementType && vehicle.implementType.length > 0 ? (
            <div>
              <Text> {vehicle?.implementType?.name}</Text>
            </div>
          ) : null}
          <div>
            <Text type="regular" color="dark">
              {vehicle.vehicleBodyType?.name}
            </Text>
          </div>
        </Row>
        <p />

        {currentDriver?.social_name ? (
          <Row>
            <Link to={`/motoristas/${currentDriver.id}`}>
              <Text type="regular" color="light_blue">
                {currentDriver.social_name}
              </Text>
              <HiOutlineExternalLink size={12} />
            </Link>
          </Row>
        ) : vehicle.drivers && vehicle.drivers.length > 0 ? (
          <div>
            <Row>
              <Link to={`/motoristas/${vehicle.drivers[0].id}`}>
                <Text type="regular" color="light_blue">
                  {vehicle.drivers[0].social_name}
                </Text>
                <HiOutlineExternalLink size={12} />
              </Link>
            </Row>
            <Row>
              <Text color="dark" type="regular">
                {vehicle.drivers[0].cgccpf}
              </Text>
            </Row>
          </div>
        ) : (
          <Row>
            <Text color="dark" type="label">
              Sem motorista
            </Text>
          </Row>
        )}
        {vehicle.tags && vehicle.tags.length > 0 ? (
          <>
            <p />
            <Row>
              {vehicle.tags.map(x => (
                <Styled.BadgeTag pill className="mr-2">
                  <Text type="label" color="black">
                    {x.name}
                  </Text>
                </Styled.BadgeTag>
              ))}
            </Row>
          </>
        ) : (
          <Row>
            <Text type="label" color="dark" className="mr-2">
              Não possui tags cadastradas
            </Text>
          </Row>
        )}
      </Card>
    </Col>
  );
}
