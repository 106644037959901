import styled, { css } from 'styled-components';
import MaterialCard from '@material-ui/core/Card';

export const StyledCard = styled(MaterialCard)`
  && {
    padding: 15px;
    width: 100%;
    border-radius: 0px;
    box-shadow: ${props => props.theme.shadow};

    ${({
      bordered,
      theme,
      bordercolor = 'secondary_light',
      borderhexcolor,
      backgroundcolor,
    }) =>
      bordered &&
      css`
        border-left: 4px solid
          ${borderhexcolor ? borderhexcolor : theme.colors[bordercolor]};

        -webkit-transition: border-color 0.3s;

        background-color: backgroundcolor;
      `}

    ${({ form }) =>
      form &&
      css`
        padding: 30px;
        @media (max-width: 768px) {
          padding: 15px;
        }
      `}

    h1 {
      font-size: 12px;
    }

    h5 {
      font-size: 12px;
      font-weight: 500;
    }
  }
`;
