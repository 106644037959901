import React, { useState, useRef, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';

import InfiniteScroll from 'components/InfiniteScroll';
import Snackbar from 'components/Snackbar';
import Card from 'components/Card';
import ComponentAsyncSelect from 'components/ComponentAsyncSelect';
import CardVehicle from './CardVehicle';
import InfoMessage from 'components/InfoMessage';
import Sidebar from 'components/Sidebar';
import Button from 'components/Button';

import api from 'services/api';

function FrotaPrioritaria() {
  const params = useParams();
  const scrollRef = useRef();

  const [client, setClient] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [vehicleId, setVehicleId] = useState('');
  const [filter, setFilter] = useState('');
  const [selectedVehicle, setSelectedVehicle] = useState();
  const [snackBar, setSnackBar] = useState({
    display: false,
    type: 'error',
    message: '',
  });

  const handleClose = () => {
    setOpen(false);
  };

  async function getClients() {
    try {
      let response = await api.get(`persons/${params.id}`);
      setClient(response.data);
    } catch (e) {
      const { response } = e;
      setSnackBar({
        display: true,
        type: 'error',
        message: response.data.error,
      });
    }
  }

  useEffect(() => {
    getClients();
  }, []);

  async function addVehicle(event) {
    event.preventDefault();
    try {
      await api.post(`priority-vehicles`, {
        client_id: Number(params.id),
        vehicle_id: selectedVehicle.value,
      });

      setSnackBar({
        display: true,
        type: 'success',
        message: 'Veículo adicionado',
      });

      scrollRef.current.refresh();
    } catch (e) {
      const { response } = e;

      setSnackBar({
        display: true,
        type: 'error',
        message: response.data.error,
      });
    }
  }

  async function removeVehicleDialog(id) {
    setVehicleId(id);

    setOpen(true);
  }

  async function removeVehicle() {
    try {
      await api.delete(`priority-vehicles/${vehicleId}`);

      setSnackBar({
        display: true,
        type: 'success',
        message: 'Veículo removido',
      });

      scrollRef.current.refresh(1);
    } catch (e) {
      const { response } = e;

      setSnackBar({
        display: true,
        type: 'error',
        message: response.data.error,
      });
    }
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Card>
            <Typography variant="subtitle1" gutterBottom>
              <strong>{client.social_name && client.social_name}</strong>
            </Typography>
            <Typography variant="body2" gutterBottom>
              {client.cgccpf && client.cgccpf}
            </Typography>
          </Card>
        </Grid>
      </Grid>

      <Snackbar
        open={snackBar.display}
        type={snackBar.type}
        message={snackBar.message}
        onClose={() => setSnackBar(false)}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deseja remover o veículo?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" outline>
            Cancelar
          </Button>
          <Button
            onClick={() => {
              removeVehicle();
              setOpen(false);
            }}
            color="primary"
            autoFocus
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      <InfiniteScroll
        ref={scrollRef}
        endpoint={`clientes/${params.id}/priority-vehicles`}
        filter={filter}
        initialFetch={true}
        placeholderHeight={145}
        renderItem={(item, index) => (
          <CardVehicle
            key={index}
            vehicle={item}
            removeVehicleDialog={removeVehicleDialog}
          />
        )}
        initialMessage={
          <Grid container style={{ marginTop: '20px' }}>
            <InfoMessage
              icon="arrow-right"
              width="auto"
              snackColor="#8c8c8c"
              message="Preencha os filtros para visualizar as informações."
            />
          </Grid>
        }
        endMessage={
          <Grid container style={{ marginTop: '20px' }}>
            <InfoMessage
              icon="doneall"
              width="auto"
              snackColor="#8c8c8c"
              message="Não há mais itens a serem exibidos."
            />
          </Grid>
        }
      />
      <Sidebar
        title="Adicionar veículo cadastrado"
        icon="fa-plus"
        style={{
          position: 'absolute',
          height: 'calc(100% - 62px)',
        }}
      >
        {/* <Link
          style={{ marginBottom: '20px', display: 'inherit' }}
          to={'/veiculos/cadastrar'}
        >
          Cadastrar novo veículo
      </Link>*/}

        <form onSubmit={addVehicle}>
          <ComponentAsyncSelect
            route={`priority-vehicles?plate=`}
            label="Digite a placa"
            defaultValue={selectedVehicle}
            noOptionsMessage="Sem resultados"
            onChange={event => {
              setSelectedVehicle(event);
            }}
            style={{ overflow: 'visible' }}
          />

          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '10px',
            }}
          >
            <Button type="submit" style={{ marginTop: '' }}>
              Adicionar
            </Button>
          </div>
        </form>
      </Sidebar>
    </>
  );
}

export default FrotaPrioritaria;
