import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { startOfDay, endOfDay } from 'date-fns';
import api, { cooperplaceApi } from 'services/api';
import { Row, Col } from 'react-bootstrap';
import { useSnackbar } from 'v3/components/Snackbar';

import {
  Text,
  Select,
  Button,
  Modal,
  DatePicker,
  Input,
  Radio,
} from 'v3/components';

import { useQuery } from 'hooks';

import {
  fetchGroupVehicles,  fetchOccurrencesTypesOptions,  fetchImplements, fetchBodies, fetchUserCompaniesSelectOptions,  fetchVehiclesTypes,  fetchTravelStatus,  fetchUserCostCentersByCompany,  fetchBranchOffices,  fetchProductAndCategorie, fetchVehicle, fetchWagons} from 'utils/fetches';

import {
  fetchCities,
  fetchCountries,
  fetchDriver,
  getProvinces,
  fetchShippingCompanies,
  deliveryTimeStatusOptions,
  fleetTypeOptions,
  onSearchNegotiator,
  onSearchUser,
  fetchSelectTags,
  aggregationOptions,
} from '../utils';

import { ButtonsColContainer } from './styles';

export function Filter({
  handleTitleAdvancedFilters,
  handleCloseAdvancedFilters,
  handleShowAdvancedFilters,
  showAdvancedFilters,
  loading,
  setLoading,
  fetchData,
  setQtdAdvancedFilters,
  toggleFilters,
  setToggleFilters,
  filters,
  setFilters,
}) {
  const history = useHistory();
  const query = useQuery();

  const [provincesOptions, setProvincesOptions] = useState([]);
  const [countriesOptions, setCountriesOptions] = useState([]);
  const [vehicleTypesOptions, setVehiclesTypes] = useState([]);
  const [vehicleBodysOptions, setVehiclesBodys] = useState([]);
  const [vehicleSegmentsOptions, setVehiclesSegments] = useState([]);
  const [travelStatusOptions, setTravelStatusOptions] = useState([]);
  const [typesOccurrencesOptions, setTypesOccurrences] = useState([]);
  const [companiesOptions, setCompaniesOptions] = useState([]);

  const snackbar = useSnackbar();

  useEffect(() => {
    const timeout = setTimeout(() => {
      history.replace({ search: query.toString() });
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [filters]);

  useEffect(() => {
    const isNotEmpty = value =>
      value !== null &&
      value !== undefined &&
      value !== '' &&
      value !== '0' &&
      value?.length > 0;

    const advancedFilters = {
      pcp: filters?.pcps || '',
      negotiator: filters?.negotiators || '',
      driverId: filters?.driver || '',
      originClient: filters?.originClient || '',
      destinationClient: filters?.destinationClient || '',
      shippingCompany: filters?.shippingCompany || '',
      bennerTravelId: filters?.bennerTravelId || '',
      tags: filters?.tags || '',
      travelStatus: String(filters?.travelStatus?.id || ''),
      cteCrtNumber: filters?.cteCrtNumber || '',
      nfeNumber: filters?.nfeNumber || '',
      isTracked:
        filters?.isTracked === 1 || filters?.isTracked === 0 ? 'tracked' : '',
      clientOrderNumber: filters?.clientOrderNumber,
      vehicleType: filters?.vehicleType,
      vehicleBody: filters?.vehicleBody,
      vehicleSegment: filters?.vehicleSegment,
      typeOccurrences: filters.typeOccurrences,
      product: filters?.product || ''
    };
    setQtdAdvancedFilters(
      Object.values(advancedFilters).filter(isNotEmpty).length
    );
  }, [filters]);

  async function fetchProvinces() {
    const provinces = await getProvinces();
    setProvincesOptions(provinces);
  }

  async function fetchSelectOptions(url, params = {}) {
    try {
      const response = await api.get(url, { params });
      return response.data;
    } catch (err) {
      return [];
    }
  }

  function getDestinationComboBoxLabel(option) {
    if (option.province?.uf) {
      return `${`${option.name} - ${option.province?.uf}`}`;
    }
    return option.name;
  }

  function searchCountry(value) {
    if (!value) {
      return countriesOptions;
    }

    const countrySearch = value.toLowerCase();
    return countriesOptions.filter(
      country => country.name.toLowerCase().search(countrySearch) >= 0
    );
  }

  async function updateUserFilterPreferences() {
    try {
      setLoading(true);
      const {
        pcps,
        negotiators,
        costCenter,
        firstPeriod,
        secondPeriod,
        tags,
        client,
        initialProvince,
        lastProvince,
        loadId,
        cooperplaceTravelId,
        originCity,
        destinationCity,
        originCountrySlug,
        destinationCountrySlug,
        travelStatus,
        vehicle,
        driver,
        originClient,
        destinationClient,
        shippingCompany,
        bennerTravelId,
        cteCrtNumber,
        nfeNumber,
        fleetType,
        isTracked,
        deliveryTimeStatus,
        plate,
        wagonPlate,
        fleetGroup,
        aggregation,
        firstArrivalPeriod,
        secondArrivalPeriod,
        clientOrderNumber,
        vehicleType,
        vehicleBody,
        vehicleSegment,
        typeOccurrences,
        companies,
        routeStations,
        product,
      } = filters;
      const data = {
        pcps: pcps || [],
        negotiators: negotiators || [],
        costCenter: costCenter || [],
        firstPeriod:
          firstPeriod || moment().subtract(60, 'days').format('DD/MM/YYYY'),
        secondPeriod: secondPeriod || moment().format('DD/MM/YYYY'),
        tags: tags || [],
        client: client || [],
        initialProvince: initialProvince || [],
        lastProvince: lastProvince || [],
        loadId: loadId || '',
        cooperplaceTravelId: cooperplaceTravelId || '',
        originCity: originCity || [],
        destinationCity: destinationCity || [],
        originCountrySlug: originCountrySlug || [],
        destinationCountrySlug: destinationCountrySlug || [],
        travelStatus: travelStatus || [],
        driver: driver || [],
        vehicle: vehicle || [],
        originClient: originClient || [],
        destinationClient: destinationClient || [],
        shippingCompany: shippingCompany || [],
        bennerTravelId: bennerTravelId || '',
        cteCrtNumber: cteCrtNumber || '',
        nfeNumber: nfeNumber || '',
        fleetType: fleetType || [],
        isTracked: isTracked || 0,
        plate: plate || '',
        wagonPlate: wagonPlate || '',
        deliveryTimeStatus: deliveryTimeStatus || [],
        fleetGroup: fleetGroup || [],
        aggregation: aggregation || '',
        firstArrivalPeriod: firstArrivalPeriod || '',
        secondArrivalPeriod: secondArrivalPeriod || '',
        clientOrderNumber: clientOrderNumber || [],
        vehicleType: vehicleType || [],
        vehicleBody: vehicleBody || [],
        vehicleSegment: vehicleSegment || [],
        typeOccurrences: typeOccurrences || [],
        companies: companies || [],
        routeStations: routeStations || '',
        product: product || '',
      };
      await cooperplaceApi.post(`users/definitions`, {
        type: 'tower_control_filters',
        definition: JSON.stringify(data),
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  const requestTravelStatus = useCallback(async () => {
    const allStatus = (await fetchTravelStatus()) || [];
    const formatedStatus = allStatus
      .map(status => ({
        label: status.name,
        id: status.id,
      }))
      .filter(
        item => item.label !== 'Transbordado' && item.label !== 'Cancelado'
      )
      .sort((a, b) => a.id - b.id);
    setTravelStatusOptions(formatedStatus);
  }, [fetchTravelStatus]);

  useEffect(() => {
    fetchInitalOptions();
    requestTravelStatus();
  }, []);

  async function fetchInitalOptions() {
    try {
      await Promise.all([
        getImplements(),
        getVehicleTypes(),
        getVehicleBodys(),
        fetchProvinces(),
        fetchCountries(setCountriesOptions),
        getTypesOccurrences(),
        getCompaniesOptions(),
      ]);
    } catch (error) {
      snackbar.show(
        <Text>
          Não foi possível carregar todas as opções de filtro. Recarregue a
          página.
        </Text>,
        {
          type: 'error',
          showClose: true,
        }
      );
    }
  }

  function handleCompanySearch(search) {
    if (!search) {
      return companiesOptions;
    }
    const parsed = search.toLowerCase();
    return companiesOptions.filter(
      option => option?.name?.toLowerCase().search(parsed) >= 0
    );
  }

  async function getCompaniesOptions() {
    try {
      const response = await fetchUserCompaniesSelectOptions();
      setCompaniesOptions(response);
    } catch (error) {
      console.error(error);
    }
  }
  async function getTypesOccurrences() {
    const occurrences = await fetchOccurrencesTypesOptions();

    setTypesOccurrences(occurrences.message);
  }

  async function getImplements() {
    try {
      const vehicleSegmentsResponse = await fetchImplements();
      setVehiclesSegments(vehicleSegmentsResponse);
    } catch (error) {
      console.error(error);
    }
  }

  async function getVehicleTypes() {
    try {
      const vehicleTypesResponse = await fetchVehiclesTypes();
      setVehiclesTypes(vehicleTypesResponse);
    } catch (error) {
      console.error(error);
    }
  }

  async function getVehicleBodys() {
    try {
      const vehicleBodysResponse = await fetchBodies();
      setVehiclesBodys(vehicleBodysResponse);
    } catch (error) {
      console.error(error);
    }
  }

  async function onAdvancedFilter() {
    await updateUserFilterPreferences();
    fetchData();
    handleCloseAdvancedFilters();
  }

  async function onFilter() {
    await updateUserFilterPreferences();
    fetchData();
  }

  function onSearchOccurrency(value) {
    return typesOccurrencesOptions.filter(type =>
      type.name.toLowerCase().includes(value));
  }

  function onSearchVehicleSegment(value) {
    return vehicleSegmentsOptions.filter(vehicle =>
      vehicle.name.toLowerCase().includes(value)
    );
  }

  function onSearchVehicleType(value) {
    return vehicleTypesOptions.filter(vehicle =>
      vehicle.name.toLowerCase().includes(value)
    );
  }

  function onSearchVehicleBody(value) {
    return vehicleBodysOptions.filter(vehicle =>
      vehicle.name.toLowerCase().includes(value));
  }

  function onSearchTravelStatus(value) {
    return travelStatusOptions.filter(status =>
      status.label.toLowerCase().includes(value)
    );
  }

  return (
    <>
      <Modal
        show={showAdvancedFilters}
        heading="Filtro Avançado"
        onHide={handleCloseAdvancedFilters}
        body={
          <Row className="p-1">
            <Col md={6} xs={12}>
              <Select.Async
                label="Negociador"
                modalHeading="Selecione um negociador"
                modalBodyTitle="Negociador:"
                value={filters?.negotiators}
                onSearch={onSearchNegotiator}
                onChange={value => {
                  if (value) {
                    query.set(
                      'negotiators',
                      value.map(value => value.id).join(',')
                    );
                  } else {
                    query.delete('negotiators');
                  }
                  setFilters(old => ({
                    ...old,
                    negotiators: value,
                  }));
                }}
                multiple
                horizontal
                getOptionLabel={option => {
                  let label = `${option.username}`;
                  if (option?.cpf) {
                    label += ` - ${option?.cpf}`;
                  }
                  return label;
                }}
                getOptionValue={option => option.id}
              />
            </Col>
            <Col md={6} xs={12}>
              <Select.Async
                label="PCP"
                modalHeading="Selecione um pcp"
                modalBodyTitle="Pcp:"
                horizontal
                value={filters?.pcps}
                onSearch={onSearchUser}
                onChange={value => {
                  if (value) {
                    query.set('pcps', value.map(value => value.id).join(','));
                  } else {
                    query.delete('pcps');
                  }
                  setFilters(old => ({ ...old, pcps: value }));
                }}
                multiple
                getOptionLabel={option => {
                  let label = `${option.username}`;
                  if (option?.cpf) {
                    label += ` - ${option?.cpf}`;
                  }
                  return label;
                }}
                getOptionValue={option => option.id}
              />
            </Col>

            <Col lg={4} md={4} className="my-1">
              <Select.Async
                modalHeading="Selecione um motorista"
                modalBodyTitle="Motoristas:"
                multiple
                horizontal
                label="Motorista"
                onSearch={fetchDriver}
                value={filters?.driver}
                onChange={value => {
                  if (value) {
                    query.set(
                      'driverId',
                      value.map(i => i.id)
                    );
                    query.set(
                      'driverName',
                      `('${value.map(i => i.social_name).join(`','`)}')`
                    );
                  } else {
                    query.delete('driverId');
                    query.delete('driverName');
                  }
                  setFilters(old => ({
                    ...old,
                    driver: value,
                  }));
                }}
                getOptionLabel={option => option.social_name}
                getOptionValue={option => option.id}
              />
            </Col>
            <Col lg={4} md={4} className="my-1">
              <Select.Async
                label="Cliente Origem"
                value={filters?.originClient}
                multiple
                horizontal
                onSearch={search =>
                  fetchSelectOptions('persons/customers', { search })
                }
                onChange={value => {
                  if (value) {
                    query.set(
                      'originClient',
                      value.map(v => v.id)
                    );
                  } else {
                    query.delete('originClient');
                  }
                  setFilters(old => ({ ...old, originClient: value }));
                }}
                getOptionLabel={option => option.label}
                getOptionValue={option => option.id}
                modalHeading="Selecione um cliente de origem"
                modalBodyTitle="Clientes:"
              />
            </Col>
            <Col lg={4} md={4} className="my-1">
              <Select.Async
                label="Cliente Destino"
                value={filters?.destinationClient}
                onSearch={search =>
                  fetchSelectOptions('persons/customers', { search })
                }
                onChange={value => {
                  if (value) {
                    query.set(
                      'destinationClient',
                      value.map(i => i.id)
                    );
                  } else {
                    query.delete('destinationClient');
                  }
                  setFilters(old => ({
                    ...old,
                    destinationClient: value,
                  }));
                }}
                multiple
                horizontal
                getOptionLabel={option => option.label}
                getOptionValue={option => option.id}
                modalHeading="Selecione um cliente de destino"
                modalBodyTitle="Clientes:"
              />
            </Col>

            <Col lg={4} md={4} className="my-1">
              <Select.Async
                label="Transportador"
                multiple
                horizontal
                value={filters?.shippingCompany}
                onSearch={value => fetchShippingCompanies(value)}
                onChange={value => {
                  if (value) {
                    query.set(
                      'shippingCompany',
                      value.map(i => i.id)
                    );
                    query.set(
                      'shippingCompanyName',
                      `('${value.map(i => i.label).join(`','`)}')`
                    );
                  } else {
                    query.delete('shippingCompany');
                    query.delete('shippingCompanyName');
                  }
                  setFilters(old => ({
                    ...old,
                    shippingCompany: value,
                  }));
                }}
                getOptionLabel={option => option.label}
                getOptionValue={option => option.id}
              />
            </Col>
            <Col lg={4} md={4} className="my-1">
              <Input
                label="Nº Viagem Benner"
                value={filters?.bennerTravelId}
                onChange={e => {
                  if (e.target.value) {
                    query.set('bennerTravelId', e.target.value);
                  } else {
                    query.delete('bennerTravelId');
                  }
                  setFilters(old => ({
                    ...old,
                    bennerTravelId: e.target.value,
                  }));
                }}
              />
            </Col>
            <Col lg={4} md={4} className="my-1">
              <Select.Async
                label="Tags"
                modalHeading="Selecione uma tag"
                modalBodyTitle="Tags:"
                value={filters?.tags}
                onSearch={search =>
                  fetchSelectTags({ search, not_paginated: true })
                }
                onChange={value => {
                  if (value) {
                    query.set('tags', value.map(value => value.id).join(';'));
                  } else {
                    query.delete('tags');
                  }
                  setFilters(old => ({ ...old, tags: value }));
                }}
                multiple
                horizontal
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
              />
            </Col>

            <Col lg={4} md={4} className="my-1">
              <Select.Async
                label="Status da Viagem"
                multiple
                horizontal
                value={filters?.travelStatus}
                onSearch={value => onSearchTravelStatus(value)}
                onChange={value => {
                  if (value) {
                    query.set(
                      'travelStatus',
                      value.map(i => i.id)
                    );
                  } else {
                    query.delete('travelStatus');
                  }
                  setFilters(old => ({
                    ...old,
                    travelStatus: value,
                  }));
                }}
                getOptionLabel={option => option.label}
                getOptionValue={option => option.id}
                options={travelStatusOptions}
              />
            </Col>
            <Col lg={4} md={4} className="my-1">
              <Input
                label="Nº CT-e/ CRT"
                value={filters?.cteCrtNumber}
                onChange={e => {
                  if (e.target.value) {
                    query.set('cteCrtNumber', e.target.value);
                  } else {
                    query.delete('cteCrtNumber');
                  }
                  setFilters(old => ({
                    ...old,
                    cteCrtNumber: e.target.value,
                  }));
                }}
              />
            </Col>
            <Col lg={4} md={4} className="my-1">
              <Input
                label="Nº NF-e"
                value={filters?.nfeNumber}
                onChange={e => {
                  if (e.target.value) {
                    query.set('nfeNumber', e.target.value);
                  } else {
                    query.delete('nfeNumber');
                  }
                  setFilters(old => ({
                    ...old,
                    nfeNumber: e.target.value,
                  }));
                }}
              />
            </Col>
            <Col lg={4} md={4} className="my-1">
              <Input
                label="Número Pedido"
                value={filters?.clientOrderNumber}
                onChange={e => {
                  if (e.target.value) {
                    query.set('clientOrderNumber', e.target.value);
                  } else {
                    query.delete('clientOrderNumber');
                  }
                  setFilters(old => ({
                    ...old,
                    clientOrderNumber: e.target.value,
                  }));
                }}
              />
            </Col>
            <Col lg={4} md={4} className="my-1">
              <Select.Async
                label="Tipo Veículo de Tração"
                multiple
                horizontal
                modalHeading="Selecione um veículo de tração"
                modalBodyTitle="Veículos de tração:"
                value={filters?.vehicleType}
                onSearch={value => onSearchVehicleType(value)}
                onChange={value => {
                  if (value) {
                    query.set(
                      'vehicleType',
                      value.map(i => i.id)
                    );
                  } else {
                    query.delete('vehicleType');
                  }
                  setFilters(old => ({
                    ...old,
                    vehicleType: value,
                  }));
                }}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                options={vehicleTypesOptions}
              />
            </Col>
            <Col lg={4} md={4} className="my-1">
              <Select.Async
                label="Tipo de carroceria"
                multiple
                horizontal
                modalHeading="Selecione um tipo de carroceria"
                modalBodyTitle="Tipos de carroceria:"
                value={filters?.vehicleBody}
                onSearch={value => onSearchVehicleBody(value)}
                onChange={value => {
                  if (value) {
                    query.set(
                      'vehicleBody',
                      value.map(i => i.id)
                    );
                  } else {
                    query.delete('vehicleBody');
                  }
                  setFilters(old => ({
                    ...old,
                    vehicleBody: value,
                  }));
                }}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                options={vehicleBodysOptions}
              />
            </Col>
            <Col lg={4} md={4} className="my-1">
              <Select.Async
                label="Segmento Implemento"
                multiple
                horizontal
                modalHeading="Selecione um implemento"
                modalBodyTitle="Implementos:"
                value={filters?.vehicleSegment}
                onSearch={value => onSearchVehicleSegment(value)}
                onChange={value => {
                  if (value) {
                    query.set(
                      'vehicleSegment',
                      value.map(i => i.id)
                    );
                  } else {
                    query.delete('vehicleSegment');
                  }
                  setFilters(old => ({
                    ...old,
                    vehicleSegment: value,
                  }));
                }}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                options={vehicleSegmentsOptions}
              />
            </Col>
            <Col lg={4} md={4} className="my-1">
              <Select.Async
                label="Tipos de ocorrências"
                multiple
                horizontal
                modalHeading="Selecione um Tipo de ocorrência"
                modalBodyTitle="Tipo de ocorrência:"
                value={filters?.typeOccurrences}
                onSearch={value => onSearchOccurrency(value)}
                onChange={value => {
                  if (value) {
                    query.set(
                      'typeOccurrences',
                      value.map(i => i.id)
                    );
                  } else {
                    query.delete('typeOccurrences');
                  }
                  setFilters(old => ({
                    ...old,
                    typeOccurrences: value,
                  }));
                }}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                options={typesOccurrencesOptions}
              />
            </Col>
            <Col lg={4} md={4} className="my-1">
              <Select.Async
                labelWhiteSpace="nowrap"
                label="Postos Coopercarga"
                modalHeading="Selecione um Posto Coopercarga"
                modalBodyTitle="Posto Coopercarga:"
                multiple
                horizontal
                onSearch={search => fetchBranchOffices(search, true)}
                value={filters?.routeStations}
                onChange={value => {
                  if (value) {
                    query.set(
                      'routeStations',
                      value.map(value => value.id).join(';')
                    );
                  } else {
                    query.delete('routeStations');
                  }
                  setFilters(old => ({ ...old, routeStations: value }));
                }}
                getOptionLabel={option => option?.name}
                getOptionValue={option => option?.id}
              />
            </Col>
            <Col lg={4} md={4} className="my-1">
              <Select.Async
                labelWhiteSpace="nowrap"
                label="Produto"
                modalHeading="Selecione um Produto"
                modalBodyTitle="Produto:"
                multiple
                horizontal
                onSearch={search => fetchProductAndCategorie(search)}
                value={filters?.product}
                onChange={value => {
                  if (value) {
                    query.set(
                      'product',
                      value.map(value => value.id).join(',')
                    );
                  } else {
                    query.delete('product');
                  }
                  setFilters(old => ({ ...old, product: value }));
                }}
                getOptionLabel={option => option?.name}
                getOptionValue={option => option?.id}
              />
            </Col>
            <Col lg={4} md={4} className="my-1 mt-10">
              <Text type="label" color="dark" weight={500} as="p">
                Rastreado?
              </Text>
              <Radio.Group
                onChange={({ target }) => {
                  if (target.value === -1) {
                    query.delete('isTracked');
                  } else {
                    query.set('isTracked', target.value);
                  }
                  setFilters(old => ({
                    ...old,
                    isTracked: target.value,
                  }));
                }}
                value={filters?.isTracked}
                horizontal
              >
                <Radio value={1}>
                  <Text color="dark" type="label">
                    Sim
                  </Text>
                </Radio>
                <Radio value={0}>
                  <Text color="dark" type="label">
                    Não
                  </Text>
                </Radio>
                <Radio value={-1}>
                  <Text color="dark" type="label">
                    Todos
                  </Text>
                </Radio>
              </Radio.Group>
            </Col>
          </Row>
        }
        footer={
          <Row style={{ justifyContent: 'flex-end' }}>
            <Button
              variant="primary"
              loading={loading}
              onClick={() => {
                onAdvancedFilter();
              }}
            >
              <Text color="white" weight={500}>
                Filtrar
              </Text>
            </Button>
          </Row>
        }
      />
      <ButtonsColContainer>
        <Button onClick={() => setToggleFilters(!toggleFilters)}>
          <Text type="regular" weight={500} fontSize="0.75rem">
            {toggleFilters ? 'Maximizar Filtros' : 'Minimizar Filtros'}
          </Text>
        </Button>
        <Button
          style={{ 'margin-top': '10px', width: '100%' }}
          variant="secondary"
          onClick={() => handleShowAdvancedFilters()}
        >
          <Text weight={500} color="dark" type="regular" fontSize="0.75rem">
            {handleTitleAdvancedFilters()}
          </Text>
        </Button>
        <Button
          style={{ 'margin-top': '10px', width: '100%' }}
          variant="success"
          loading={loading}
          type="button"
          onClick={() => onFilter()}
        >
          <Text type="regular" weight={500} fontSize="0.75rem">
            Filtrar
          </Text>
        </Button>
      </ButtonsColContainer>
      {toggleFilters === false ? (
        <>
          <Row>
            <Col md={4} className="my-1">
              <Input
                label="Nº da Carga"
                type="number"
                value={filters?.loadId}
                onChange={e => {
                  if (e.target.value) {
                    query.set('loadId', e.target.value);
                  } else {
                    query.delete('loadId');
                  }
                  setFilters(old => ({ ...old, loadId: e.target.value }));
                }}
              />
            </Col>
            <Col md={4} className="my-1">
              <Input
                label="Nº Viagem Cooperplace"
                value={filters?.cooperplaceTravelId}
                onChange={e => {
                  if (e.target.value) {
                    query.set('cooperplaceTravelId', e.target.value);
                  } else {
                    query.delete('cooperplaceTravelId');
                  }
                  setFilters(old => ({
                    ...old,
                    cooperplaceTravelId: e.target.value,
                  }));
                }}
              />
            </Col>
            <Col md={4} className="my-1">
              <Select.Async
                label="Tomador"
                multiple
                horizontal
                modalHeading="Selecione um Tomador"
                modalBodyTitle="Tomadores:"
                onSearch={search =>
                  fetchSelectOptions('persons/customers', { search })
                }
                value={filters?.client}
                onChange={value => {
                  if (value) {
                    query.set(
                      'client',
                      value.map(i => i.id)
                    );
                  } else {
                    query.delete('client');
                  }
                  setFilters(old => ({ ...old, client: value }));
                }}
                getOptionLabel={option => option.label}
                getOptionValue={option => option.id}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4} className="my-1">
              <Select.Async
                label="Estado de Origem"
                options={provincesOptions}
                multiple
                horizontal
                modalHeading="Selecione um estado de origem"
                modalBodyTitle="Estados de origem:"
                placeholder="Selecione..."
                getOptionLabel={option => `${option?.label}`}
                getOptionValue={option => option?.value}
                value={filters?.initialProvince}
                onChange={value => {
                  if (value) {
                    query.set(
                      'initialProvince',
                      `('${value.map(i => i.uf).join(`','`)}')`
                    );
                  } else {
                    query.delete('initialProvince');
                  }
                  setFilters(old => ({
                    ...old,
                    initialProvince: value,
                  }));
                }}
              />
            </Col>
            <Col md={4} className="my-1">
              <Select.Async
                label="Estado de Destino"
                options={provincesOptions}
                multiple
                horizontal
                modalHeading="Selecione um estado destino"
                modalBodyTitle="Estados destino:"
                placeholder="Selecione..."
                getOptionLabel={option => `${option?.label}`}
                getOptionValue={option => option?.value}
                value={filters?.lastProvince}
                onChange={value => {
                  if (value) {
                    query.set(
                      'lastProvince',
                      `('${value.map(i => i.uf).join(`','`)}')`
                    );
                  } else {
                    query.delete('lastProvince');
                  }
                  setFilters(old => ({ ...old, lastProvince: value }));
                }}
              />
            </Col>
            <Col md={4} className="my-1">
              <Select
                label="Tipo Frota"
                value={filters?.fleetType}
                onChange={value => {
                  if (value) {
                    query.set('fleetType', value?.id);
                  } else {
                    query.delete('fleetType');
                  }
                  setFilters(old => ({
                    ...old,
                    fleetType: value,
                  }));
                }}
                getOptionLabel={option => option.label}
                getOptionValue={option => option.id}
                options={fleetTypeOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4} className="my-1">
              <Select.Async
                label="Cidade de Origem"
                modalHeading="Selecione uma cidade"
                modalBodyTitle="Cidades:"
                onSearch={fetchCities}
                value={filters?.originCity}
                multiple
                horizontal
                onChange={value => {
                  if (value) {
                    query.set(
                      'originCityId',
                      value.map(i => i.id)
                    );
                    query.set(
                      'originCityName',
                      `('${value.map(i => i.name).join(`','`)}')`
                    );
                  } else {
                    query.delete('originCityId');
                    query.delete('originCityName');
                  }
                  setFilters(old => ({
                    ...old,
                    originCity: value,
                  }));
                }}
                getOptionLabel={option => getDestinationComboBoxLabel(option)}
                getOptionValue={option =>
                  `${`${option.name}-${option.province?.uf}`}`
                }
              />
            </Col>
            <Col md={4} className="my-1">
              <Select.Async
                label="Cidade de Destino"
                modalHeading="Selecione uma cidade"
                modalBodyTitle="Cidades:"
                onSearch={fetchCities}
                multiple
                horizontal
                value={filters?.destinationCity}
                onChange={value => {
                  if (value) {
                    query.set(
                      'destinationCityId',
                      value.map(i => i.id)
                    );
                    query.set(
                      'destinationCityName',
                      `('${value.map(i => i.name).join(`','`)}')`
                    );
                  } else {
                    query.delete('destinationCityId');
                    query.delete('destinationCityName');
                  }
                  setFilters(old => ({
                    ...old,
                    destinationCity: value,
                  }));
                }}
                getOptionLabel={option => getDestinationComboBoxLabel(option)}
                getOptionValue={option =>
                  `${`${option.name}-${option.province?.uf}`}`
                }
              />
            </Col>
            <Col md={4} className="my-1">
              <Select.Async
                labelWhiteSpace="nowrap"
                label="Grupo Frota"
                modalHeading="Selecione um Grupo Frota"
                modalBodyTitle="Grupo Frota:"
                multiple
                horizontal
                onSearch={search => fetchGroupVehicles(search)}
                value={filters?.fleetGroup}
                onChange={value => {
                  if (value) {
                    query.set(
                      'fleetGroup',
                      value.map(value => value.id).join(';')
                    );
                  } else {
                    query.delete('fleetGroup');
                  }
                  setFilters(old => ({ ...old, fleetGroup: value }));
                }}
                getOptionLabel={option => option?.description}
                getOptionValue={option => option?.id}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4} className="my-1">
              <Select.Async
                label="País de Origem"
                modalHeading="Selecione um país"
                modalBodyTitle="Países:"
                options={countriesOptions}
                value={filters?.originCountrySlug}
                multiple
                horizontal
                onSearch={search => searchCountry(search)}
                onChange={event => {
                  if (event) {
                    query.set(
                      'originCountrySlug',
                      event.map(value => value.country_slug).join(';')
                    );
                  } else {
                    query.delete('originCountrySlug');
                  }
                  setFilters(old => ({
                    ...old,
                    originCountrySlug: event,
                  }));
                }}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
              />
            </Col>
            <Col md={4} className="my-1">
              <Select.Async
                label="País de Destino"
                modalHeading="Selecione um país"
                modalBodyTitle="Países:"
                options={countriesOptions}
                value={filters?.destinationCountrySlug}
                multiple
                horizontal
                onSearch={search => searchCountry(search)}
                onChange={event => {
                  if (event) {
                    query.set(
                      'destinationCountrySlug',
                      event.map(value => value.country_slug).join(';')
                    );
                  } else {
                    query.delete('destinationCountrySlug');
                  }
                  setFilters(old => ({
                    ...old,
                    destinationCountrySlug: event,
                  }));
                }}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
              />
            </Col>
            <Col md={4} className="my-1">
              <Select.Async
                label="Placa"
                modalHeading="Selecione uma Placa"
                modalBodyTitle="Placa:"
                multiple
                onSearch={search => fetchVehicle(search)}
                value={filters?.plate}
                onChange={value => {
                  if (value) {
                    query.set(
                      'plate',
                      value.map(value => value.plate).join(';')
                    );
                  } else {
                    query.delete('plate');
                  }
                  setFilters(old => ({ ...old, plate: value }));
                }}
                getOptionLabel={option => option?.plate}
                getOptionValue={option => option?.id}
              />
            </Col>
            <Col md={4} className="my-1">
              <Select.Async
                labelWhiteSpace="nowrap"
                label="Centro de custo da carga"
                modalHeading="Selecione um Centro de custo"
                modalBodyTitle="Centro de custo:"
                multiple
                horizontal
                onSearch={search => fetchUserCostCentersByCompany(search)}
                value={filters?.costCenter}
                onChange={value => {
                  if (value) {
                    query.set(
                      'costCenter',
                      value.map(value => value.id).join(';')
                    );
                  } else {
                    query.delete('costCenter');
                  }
                  setFilters(old => ({ ...old, costCenter: value }));
                }}
                getOptionLabel={option => option?.name}
                getOptionValue={option => option?.id}
              />
            </Col>
            <Col md={4} className="my-1">
              <Select.Async
                label="Empresa"
                onSearch={search => handleCompanySearch(search)}
                options={companiesOptions}
                labelWhiteSpace="nowrap"
                modalHeading="Selecione uma Empresa"
                modalBodyTitle="Empresa:"
                multiple
                horizontal
                value={filters?.companies}
                onChange={value => {
                  if (value) {
                    query.set(
                      'companies',
                      value.map(value => value.id).join(';')
                    );
                  } else {
                    query.delete('companies');
                  }
                  setFilters(old => ({ ...old, companies: value }));
                }}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
              />
            </Col>
            <Col md={4} className="my-1">
              <Select.Async
                label="Placa do Implemento"
                modalHeading="Selecione a placa do implemento"
                modalBodyTitle="Placa do Implemento:"
                multiple
                onSearch={search => fetchWagons(search)}
                value={filters?.wagonPlate}
                onChange={value => {
                  if (value) {
                    query.set(
                      'wagonPlate',
                      value.map(value => value.plate).join(';')
                    );
                  } else {
                    query.delete('wagonPlate');
                  }
                  setFilters(old => ({ ...old, wagonPlate: value }));
                }}
                getOptionLabel={option => option?.plate}
                getOptionValue={option => option?.id}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4} className="my-1">
              <DatePicker
                guide
                label="Viagem criada de *"
                value={filters?.firstPeriod}
                format="DD/MM/YYYY"
                onChange={e => {
                  if (e.target.value) {
                    query.set(
                      'firstPeriod',
                      moment(e.target.value, 'DD/MM/YYYY').isValid()
                        ? startOfDay(
                            new Date(moment(e.target.value, 'DD/MM/YYYY'))
                          ).toISOString()
                        : ''
                    );
                  } else {
                    query.delete('firstPeriod');
                  }
                  setFilters(old => ({
                    ...old,
                    firstPeriod: e.target.value,
                  }));
                }}
              />
            </Col>
            <Col md={4} className="my-1">
              <DatePicker
                guide
                label="Até *"
                value={filters?.secondPeriod}
                format="DD/MM/YYYY"
                onChange={e => {
                  if (e.target.value) {
                    query.set(
                      'secondPeriod',
                      moment(e.target.value, 'DD/MM/YYYY').isValid()
                        ? endOfDay(
                            new Date(moment(e.target.value, 'DD/MM/YYYY'))
                          ).toISOString()
                        : ''
                    );
                  } else {
                    query.delete('secondPeriod');
                  }
                  setFilters(old => ({
                    ...old,
                    secondPeriod: e.target.value,
                  }));
                }}
              />
            </Col>
            <Col md={4} className="my-1">
              <Select
                label="Agregamento"
                value={filters?.aggregation}
                onChange={value => {
                  if (value) {
                    query.set('aggregation', value?.id);
                  } else {
                    query.delete('aggregation');
                  }
                  setFilters(old => ({
                    ...old,
                    aggregation: value,
                  }));
                }}
                getOptionLabel={option => option.label}
                getOptionValue={option => option.id}
                options={aggregationOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4} className="my-1">
              <DatePicker
                guide
                label="Período de Chegada de *"
                value={filters?.firstArrivalPeriod}
                format="DD/MM/YYYY"
                onChange={e => {
                  if (e.target.value) {
                    query.set(
                      'firstArrivalPeriod',
                      moment(e.target.value, 'DD/MM/YYYY').isValid()
                        ? startOfDay(
                            new Date(moment(e.target.value, 'DD/MM/YYYY'))
                          ).toISOString()
                        : ''
                    );
                  } else {
                    query.delete('firstArrivalPeriod');
                  }
                  setFilters(old => ({
                    ...old,
                    firstArrivalPeriod: e.target.value,
                  }));
                }}
              />
            </Col>
            <Col md={4} className="my-1">
              <DatePicker
                guide
                label="Até *"
                value={filters?.secondArrivalPeriod}
                format="DD/MM/YYYY"
                onChange={e => {
                  if (e.target.value) {
                    query.set(
                      'secondArrivalPeriod',
                      moment(e.target.value, 'DD/MM/YYYY').isValid()
                        ? endOfDay(
                            new Date(moment(e.target.value, 'DD/MM/YYYY'))
                          ).toISOString()
                        : ''
                    );
                  } else {
                    query.delete('secondArrivalPeriod');
                  }
                  setFilters(old => ({
                    ...old,
                    secondArrivalPeriod: e.target.value,
                  }));
                }}
              />
            </Col>
            <Col md={4} className="my-1">
              <Select
                label="Prazos"
                value={filters?.deliveryTimeStatus}
                options={deliveryTimeStatusOptions}
                getOptionLabel={option => option.label}
                getOptionValue={option => option.id}
                multiple
                horizontal
                onChange={value => {
                  if (value) {
                    const stats = value.map(i =>
                      i.slug.replace(')', '').replace('(', ' ').replace(' ', '')
                    );
                    query.set('deliveryTimeStatus', `(${stats})`);
                  } else {
                    query.delete('deliveryTimeStatus');
                  }
                  setFilters(old => ({
                    ...old,
                    deliveryTimeStatus: value,
                  }));
                }}
              />
            </Col>
          </Row>
        </>
      ) : null}
    </>
  );
}
