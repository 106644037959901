/* eslint-disable no-restricted-globals */
/* eslint-disable consistent-return */
import React, { useState, useEffect, useMemo } from 'react';

import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import api from 'services/api';
import useQuery from 'hooks/useQuery';
import { validatePermission } from 'actions/index';

import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import Input from 'v3/components/Input';

function Filter({ onChange, loading }) {
  const query = useQuery();
  const history = useHistory();

  const [filter, setFilter] = useState({});
  const [syncedTags, setSyncedTags] = useState(false);
  const hasPermissionToSelectTags = useMemo(
    () => validatePermission('SELECIONAR_TAGS'),
    []
  );

  function syncTags(tags) {
    query.delete('tag_name');
    query.delete('tag_id');

    if (tags) {
      tags.forEach(tag => {
        query.append('tag_id', tag.id);
        query.append('tag_name', tag.name);
      });
    }

    setFilter(old => ({ ...old, tags }));
  }

  async function getQuery() {
    const response = await api.get('user-tags');

    if (response.data) {
      const mappedTags = response.data.map(tag => ({
        id: tag.tag_id,
        name: tag.name?.name,
      }));

      syncTags(mappedTags);
    }

    if (query.has('tag_id') && query.has('tag_name')) {
      const tag_id = query.getAll('tag_id');
      const tag_name = query.getAll('tag_name');

      const mappedTags = tag_id.map((x, i) => {
        return {
          id: x,
          name: tag_name[i],
        };
      });

      setFilter(old => ({
        ...old,
        tags: mappedTags,
      }));
    }

    if (query.has('search')) {
      const search = query.get('search');
      setFilter(old => ({ ...old, search }));
    }
    setTimeout(() => {
      setSyncedTags(true);
    }, 300);
  }

  useEffect(() => {
    getQuery();
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      history.replace({ search: query.toString() });
    }, 150);

    return () => {
      clearTimeout(timeout);
    };
  }, [filter]);

  return (
    <Row className=" d-flex align-items-center my-4" style={{ width: '97%' }}>
      <Col md={6} xs={12} style={{ marginLeft: 4 }}>
        <Input
          label="Nome"
          labelColor="#fff"
          value={filter.search}
          placeholder="Nome do centro de custo"
          onChange={({ target }) => {
            if (target.value === null || target.value === '') {
              query.delete('search');
            } else {
              query.set('search', target.value);
            }
            setFilter(old => ({ ...old, search: target.value }));
          }}
          disabled={loading}
        />
      </Col>

      <Col md={1} style={{ marginTop: 30, marginRight: -6 }}>
        <Button
          variant="success"
          onClick={() => {
            onChange();
          }}
          className="py-2 p-0"
          style={{ width: 75 }}
          disabled={loading}
        >
          <Text type="regular" weight={500}>
            Filtrar
          </Text>
        </Button>
      </Col>
    </Row>
  );
}

export default Filter;
