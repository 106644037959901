import React from 'react';
import { Row } from 'react-bootstrap';
import {
  CardDetails,
  InputsCardDetails,
  NegotiatedCard,
  PreFreightValues,
  ReceiveClientCardDetails,
} from './FreightDetailsComponents';
import {
  inputsLoadConditionsToShow,
  shipmentDiscountsToShow,
  shipmentTransferToShow,
} from '../../Register/PriceData/utils';

export function FreightDetails({
  load,
  isBRLCurrency,
  dollarToUse,
  showDailyRate,
  setShowDailyRate,
  clientCardToggle,
  setClientCardToggle,
  inputsCardToggle,
  setInputsCardToggle,
  negotiatedCardToggle,
  setNegotiatedCardToggle,
  discountsCardToggle,
  setDiscountsCardToggle,
  transferCardToggle,
  setTransferCardToggle,
}) {
  const cooperInsurance = load?.cargInputs?.find(
    item => item.label.toLowerCase() === 'seguro coopercarga'
  );
  const hasCooperInsurance = !!cooperInsurance;
  const issueRate = load?.shipmentDiscounts?.find(
    item => item.label.toLowerCase() === 'taxa de emissão'
  );
  const hasIssueRate = !!issueRate;
  return (
    <>
      <PreFreightValues load={load} />
      <Row>
        <ReceiveClientCardDetails
          load={load}
          isBRLCurrency={isBRLCurrency}
          dollarToUse={dollarToUse}
          clientCardToggle={clientCardToggle}
          setClientCardToggle={setClientCardToggle}
          showDailyRate={showDailyRate}
          setShowDailyRate={setShowDailyRate}
        />
        <InputsCardDetails
          load={load}
          extraFieldsFieldName="cargInputs"
          totalComputed={load?.totalInputs}
          showDailyRate={showDailyRate}
          cardTitle="Descontos e Insumos Pré-Taxa"
          cardTotalTitle="Valor insumos"
          tooltipContent="Todo valor que precisa descontar antes de tirar a taxa (margem) é alocado neste campo, assim, é chegado no percentual correto para desconto de taxa administrativa (Margem)"
          shouldShowConditions={inputsLoadConditionsToShow}
          hasCooperInsurance={hasCooperInsurance}
          cooperInsurance={cooperInsurance}
          isBRLCurrency={isBRLCurrency}
          dollarToUse={dollarToUse}
          cardToggle={inputsCardToggle}
          setCardToggle={setInputsCardToggle}
          currentShippingLevel={
            load?.travel?.shipper?.shippingCompany?.descriptionServiceLevel?.toLowerCase() ===
            'agregado ouro'
          }
        />
        <NegotiatedCard
          cardTitle="Valores negociados"
          cardToggle={negotiatedCardToggle}
          setCardToggle={setNegotiatedCardToggle}
          tooltipContent="Valores negociados com transportador e cliente a partir dos valores principais"
          load={load}
          isBRLCurrency={isBRLCurrency}
          dollarToUse={dollarToUse}
          balanceValue={load?.travel?.balance}
          marginValue={load?.travel?.margin}
        />
        <CardDetails
          load={load}
          extraFieldsFieldName="shipmentDiscounts"
          totalComputed={load?.totalShipmentDiscounts}
          showDailyRate={showDailyRate}
          cardTitle="Descontos ao transportador"
          cardTotalTitle="Valor Descontos"
          tooltipContent="Se caracteriza todo desconto que será feito depois de tirar a margem (Taxa administrativa)."
          shouldShowConditions={shipmentDiscountsToShow}
          hasIssueRate={hasIssueRate}
          issueRate={issueRate}
          isBRLCurrency={isBRLCurrency}
          dollarToUse={dollarToUse}
          cardToggle={discountsCardToggle}
          setCardToggle={setDiscountsCardToggle}
          balanceValue={load?.travel?.balance}
          marginValue={load?.travel?.margin}
        />
        <CardDetails
          load={load}
          extraFieldsFieldName="shipmentTransfers"
          totalComputed={load?.totalShipmentTransfer}
          showDailyRate={showDailyRate}
          cardTitle="Repasse ao transportador"
          cardTotalTitle="Valor Crédito"
          tooltipContent="Valores a serem repassados ao transportador conforme itens definidos, não contempla taxa administrativa sobre o valor."
          shouldShowConditions={shipmentTransferToShow}
          isBRLCurrency={isBRLCurrency}
          dollarToUse={dollarToUse}
          cardToggle={transferCardToggle}
          setCardToggle={setTransferCardToggle}
          hasObservationFields
        />
      </Row>
    </>
  );
}
