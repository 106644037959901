import React from 'react';

import { StyledBadge } from './styles';

/**
 * @param bordered {bool} - Adiciona uma borda na esquerda do componente
 * @param bordercolor - {string} - Adiciona uma cor a borda da esquerda [success, danger, warning, primary, gray, orange]
 * @param form - {bool} - adiciona padding quando o card esta em um formulário
 *
 * @visibleName Card
 */

export default function Card({ form, bordered, bordercolor, ...rest }) {
  return (
    <StyledBadge
      form={form}
      bordered={bordered}
      bordercolor={bordercolor}
      {...rest}
    />
  );
}
