/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Card from 'v3/components/Card';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import Input from 'v3/components/Input';
import Select from 'v3/components/Select';
import InputAddress from 'v3/components/InputAddress';
import { useSnackbar } from 'v3/components/Snackbar';
import { fetchCities, save } from './controller';
import DetailsText from './Text';

export default function Address({ issuer, handleGetIssuer, canEditIssuers }) {
  const snackbar = useSnackbar();

  const [address, setAddress] = useState();
  const [number, setNumber] = useState();
  const [complement, setComplement] = useState();
  const [neighborhood, setNeighborhood] = useState();
  const [zipCode, setZipCode] = useState();
  const [city, setCity] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [errors, setErrors] = useState({});

  async function getCities(search) {
    const cities = await fetchCities(search);
    return cities;
  }

  function validator() {
    const error = {};
    const message = 'Campo obrigatório';
    if (!address) {
      error.address = message;
    }

    if (!number) {
      error.number = message;
    }

    if (!neighborhood) {
      error.neighborhood = message;
    }

    if (!zipCode) {
      error.zipCode = message;
    }

    if (!city) {
      error.city = message;
    }

    const hasError = Object.keys(error).find(value => value);
    error.hasError = !!hasError;

    return error;
  }

  useEffect(() => {
    if (issuer) {
      setAddress(issuer.address);
      setNumber(issuer.number);
      setComplement(issuer.complement);
      setNeighborhood(issuer.neighborhood);
      setZipCode(issuer.postal_code);
      setCity(issuer.city);
    }
  }, [issuer]);

  async function handleSave() {
    const error = validator();
    setErrors(error);
    if (error.hasError) {
      return;
    }

    const response = await save({
      id: issuer.id,
      address,
      number,
      complement,
      neighborhood,
      postal_code: zipCode,
      city_id: city.value || city.id,
    });

    if (!response.error) {
      snackbar.show(<Text>Dados atualizados</Text>, {
        type: 'success',
      });
      handleGetIssuer();
      setIsEdit(!isEdit);
    } else {
      snackbar.show(<Text>{response.error}</Text>, {
        type: 'error',
      });
    }
  }

  async function handleAddress(values) {
    setAddress(values.address);
    setNeighborhood(values.neighborhood);
    setZipCode(values.zip_code);
    setNumber(values.number);
    const cities = await fetchCities(values.city);
    const filteredCity = cities.find(
      cityItem =>
        cityItem.name === values.city &&
        cityItem.province.uf === values.province
    );
    setCity(filteredCity);
    setComplement(prevComplement =>
      [prevComplement, values.name].filter(Boolean).join(', ')
    );
  }

  return (
    <Card
      header={
        <Text color="#464E5F" type="header">
          Endereço
        </Text>
      }
      HeaderRightComponent={
        <div>
          {isEdit && (
            <Button
              variant="secondary"
              className="mr-2"
              onClick={() => setIsEdit(!isEdit)}
            >
              {' '}
              <Text weight={500} type="regular">
                Cancelar
              </Text>
            </Button>
          )}
          <Button
            variant={isEdit ? 'success' : 'secondary'}
            onClick={() => (isEdit ? handleSave() : setIsEdit(!isEdit))}
            disabled={!canEditIssuers}
          >
            {' '}
            <Text weight={500} type="regular">
              {isEdit ? 'Salvar' : 'Editar'}
            </Text>
          </Button>
        </div>
      }
    >
      {isEdit ? (
        <Row>
          <Col xs={12}>
            <InputAddress
              label="Endereço *"
              placeholder=""
              value={address}
              onSelectedAddress={handleAddress}
              onChange={e => setAddress(e.target.value)}
              types={[]}
              error={errors && errors.addres}
              country="br"
            />
          </Col>
          <Col xs={6}>
            <Input
              label="Número *"
              value={number}
              onChange={event => setNumber(event.target.value)}
              error={errors && errors.number}
            />
          </Col>
          <Col xs={6}>
            <Input
              label="Complemento"
              value={complement}
              onChange={event => setComplement(event.target.value)}
              error={errors && errors.complement}
            />
          </Col>
          <Col xs={6}>
            <Input
              label="Bairro *"
              value={neighborhood}
              onChange={event => setNeighborhood(event.target.value)}
              error={errors && errors.neighborhood}
              disabled
            />
          </Col>
          <Col xs={6}>
            <Input
              label="CEP *"
              value={zipCode}
              onChange={event => setZipCode(event.target.value)}
              error={errors && errors.zipCode}
            />
          </Col>
          <Col xs={12}>
            <Select.Async
              label="Cidade *"
              onSearch={getCities}
              value={city}
              onChange={value => setCity(value)}
              getOptionLabel={option => `${option.name}, ${option.province.uf}`}
              getOptionValue={option => option.id}
              error={errors && errors.city}
              isDisabled
            />
          </Col>
        </Row>
      ) : (
        <DetailsText issuer={issuer} />
      )}
    </Card>
  );
}
