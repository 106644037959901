import React, { useState, useEffect, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { FaCheck, FaClock } from 'react-icons/fa';
import { ImCross } from 'react-icons/im';
import { cooperplaceApi } from 'services/api';

import theme from 'v3/theme';
import Fallback from 'v3/components/Fallback';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import Card from 'v3/components/Card';

import { useTravel } from 'v3/pages/Travel';
import { BlockedTravelContext } from 'contexts/BlockedTravelProvider';
import { RequestModal } from './ChangeRequestModal';
import { ChangeRequestDetailsModal } from './ChangeRequestDetailsModal';

export function ChangeRequest() {
  const { isTravelBlocked } = useContext(BlockedTravelContext);
  const params = useParams();
  const travel = useTravel();
  const [showRequestsModal, setShowRequestsModal] = useState(false);
  const [showRequestDetailsModal, setShowRequestDetailsModal] = useState(false);
  const [currentRequest, setCurrentRequest] = useState();
  const [requests, setRequests] = useState([]);
  let displayRequests = [];
  if (requests?.length)
    displayRequests = requests?.map(request => {
      return {
        id: request?.id,
        status: request.status,
        userReviewed: request.userReviewed,
        userCreated: request.userCreated,
        observation: request.observation,
        fields: [...request.fields],
        type:
          request.type === 2
            ? 'motorista'
            : request.type === 3
            ? 'transportadora'
            : 'veículo',
      };
    });
  const [fields, setFields] = useState([]);
  const driverFields = fields?.filter(field => field.type === 'Motorista')[0]
    ?.fields;
  const shippingCompanyFields = fields?.filter(
    field => field.type === 'Transportadora'
  )[0]?.fields;
  const vehicleFields = fields?.filter(field => field.type === 'Veículo')[0]
    ?.fields;
  const wagonsAttendedPlates =
    travel?.data?.travel?.data?.vehicle?.[0]?.wagons.map(item => {
      return item?.plate;
    });
  let plates = [];
  if (wagonsAttendedPlates) {
    plates = [
      travel?.data?.vehicle?.[0]?.vehicles?.plate,
      ...wagonsAttendedPlates,
    ];
  } else {
    plates = [travel?.data?.vehicle?.[0]?.vehicles?.plate];
  }
  const platesOptions = plates?.map((plate, index) => {
    return {
      label: plate,
      value: index,
    };
  });

  async function fetchFields() {
    try {
      const response = await cooperplaceApi.get('load-request-change-fields');
      setFields(response.data);
    } catch (error) {
      //
    }
  }

  async function fetchRequests() {
    try {
      const response = await cooperplaceApi.get(
        `load-request-change/${params.id}`
      );
      setRequests(response.data);
    } catch (error) {
      //
    }
  }

  useEffect(() => {
    fetchRequests();
    fetchFields();
  }, []);

  function handleRequestDetails(request) {
    setCurrentRequest(request);
    setShowRequestDetailsModal(true);
  }

  return (
    <>
      <RequestModal
        fetchRequests={fetchRequests}
        isOpen={showRequestsModal}
        onClose={() => setShowRequestsModal(false)}
        platesAvailable={platesOptions}
        driverFields={driverFields}
        shippingCompanyFields={shippingCompanyFields}
        vehicleFields={vehicleFields}
      />
      <ChangeRequestDetailsModal
        isOpen={showRequestDetailsModal}
        onClose={() => setShowRequestDetailsModal(false)}
        request={currentRequest}
        fields={
          currentRequest?.type === 'veículo'
            ? vehicleFields
            : currentRequest?.type === 'motorista'
            ? driverFields
            : shippingCompanyFields
        }
        driverFields={driverFields}
        shippingCompanyFields={shippingCompanyFields}
        vehicleFields={vehicleFields}
      />
      <Card
        loading={travel.isLoading}
        header={
          <Text color={theme.colors.dark} type="header">
            Solicitar ajuste de cadastro
          </Text>
        }
        HeaderRightComponent={
          <Button
            onClick={() => setShowRequestsModal(true)}
            disabled={
              !travel?.data?.vehicle?.[0] ||
              !travel?.data?.driver ||
              isTravelBlocked
            }
            title={
              !travel?.data?.vehicle?.[0] || !travel?.data?.driver
                ? 'Adicione motorista/veículo na viagem'
                : ''
            }
          >
            <Text color="white">Solicitar</Text>
          </Button>
        }
      >
        <>
          <Fallback
            component={
              <Col
                xs={12}
                className="align-items-center d-flex flex-column my-2"
              >
                <Text color={theme.colors.gray}>
                  Não há solicitações cadastradas para essa viagem.
                </Text>
              </Col>
            }
            fall={!requests?.length}
          >
            {displayRequests?.map(request => {
              const IconProps =
                request.status.toLowerCase() === 'pendente'
                  ? { icon: FaClock, color: theme.colors.warning }
                  : request.status.toLowerCase() === 'aprovado'
                  ? { icon: FaCheck, color: theme.colors.success }
                  : { icon: ImCross, color: theme.colors.danger };
              return (
                <Row key={request.id} className="p-2">
                  <Col
                    md={12}
                    className="d-flex align-items-center justify-content-between"
                  >
                    <div className="d-flex align-items-center">
                      <IconProps.icon
                        title={request.status}
                        color={IconProps.color}
                        className="mr-2"
                      />
                      <Text>
                        Solicitação de ajuste de {request?.type}, feita por{' '}
                        {request?.userCreated?.username}, possui status de{' '}
                        {request.status}
                      </Text>
                    </div>
                    <Button
                      onClick={() => handleRequestDetails(request)}
                      variant="transparent"
                    >
                      <Text color="dark" weight={500}>
                        Ver detalhes
                      </Text>
                    </Button>
                  </Col>
                </Row>
              );
            })}
          </Fallback>
        </>
      </Card>
    </>
  );
}
