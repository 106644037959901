import React, { useState } from 'react';
import { Row } from 'react-bootstrap';
import { cooperplaceApi } from 'services/api';
import Button from 'v3/components/Button';
import Modal from 'v3/components/Modal';
import Text from 'v3/components/Text';
import TextArea from 'v3/components/TextArea';
import { useTravel } from 'v3/pages/Travel';
import { useSnackbar } from 'v3/components/Snackbar';

export default function SolicitationModal({ isOpen, onClose, emitted }) {
  const [description, setDescription] = useState('');
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);
  const travel = useTravel();
  const destinationList = travel.data?.loads?.loadDestinations;
  const destination = destinationList[destinationList.length - 1].city;
  const origin = travel.data.loadOrigins?.[0].city;
  function handleClose() {
    onClose();
    setDescription('');
  }

  async function getUserIdByCgcCpf() {
    try {
      const cgcCpf = travel.data?.driver?.cpfCnpj;
      const user = await cooperplaceApi.get(`users/users/${cgcCpf}`);
      const idUser = user.data.id;
      return idUser;
    } catch (error) {
      snackbar.show('Usuário não encontrado', { type: 'error' });
    }
  }

  async function sendNotificationToDriver() {
    try {
      setLoading(true);
      const userId = await getUserIdByCgcCpf();
      if (!userId) {
        setLoading(false);
        return;
      }
      const data = {
        title: 'Solicitação de Documento',
        text: `Olá houve uma solicitação de documento da viagem ${origin} X ${destination}: ${description} do ${emitted.doc_type} ${emitted.cte_number}`,
        type: 'APP_NOTIFICATION',
        userId: `${userId}`,
        url: `{"action":"OPEN_ACTIVE_TRAVEL"}`,
      };
      await cooperplaceApi.post(`notifications/`, data);
      snackbar.show('Solicitação de documento enviada com successo!', {
        type: 'success',
      });
      onClose();
    } catch (error) {
      snackbar.show('Erro ao solicitar documento', { type: 'error' });
    } finally {
      setLoading(false);
    }
  }
  return (
    <Modal
      show={isOpen}
      handleClose={handleClose}
      heading={
        <Text color="dark" type="header" weight="500">
          Solicitação de Documento
        </Text>
      }
      body={
        <>
          <Text color="dark" type="header" fontSize="15px" weight="300">
            Descrição
          </Text>
          <TextArea
            resizable={false}
            value={description}
            onChange={event => setDescription(event.target.value)}
          />
          <Row xs={12} className="d-flex justify-content-center mt-3">
            <Button
              onClick={() => sendNotificationToDriver()}
              loading={loading}
            >
              <Text type="regular" weight="600">
                Solicitar
              </Text>
            </Button>
          </Row>
        </>
      }
    />
  );
}
