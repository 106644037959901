import styled, { css } from 'styled-components';

const Marker = styled.div`
  ${({ background, sizeIcon }) =>
    (background.indexOf('.svg') > -1) ?
      css`
      width: ${sizeIcon}px;
      height: ${sizeIcon}px;
      border-radius: 0% 50% 50% 50%;
      background-image: url(/images/${background});
      background-size: 100% 100%;
      transform: rotate(-135deg);
      transform-origin: top left;
    `
      :
      css`
      width: ${sizeIcon}px;
      height: ${sizeIcon}px;
      border-radius: 0% 50% 50% 50%;
      background: ${background};
      transform: rotate(-135deg);
      transform-origin: top left;
    `}
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 0;

  :after {
    ${({ letter }) =>
    letter &&
    css`
        content: '${letter}';
      `}
    width: 14px;
    height: 14px;
    margin: 10px 0 0 10px;
    position: absolute;
    transform: rotate(135deg);
    border-radius: 50%;
    ${({ color }) =>
    color &&
    css`
        color: ${color};
      `}
    font-size: 14px;
  }

  :hover {
    cursor: pointer;
  }
`;

export default Marker;
