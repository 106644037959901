import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FaCheck, FaClock } from 'react-icons/fa';
import { useSnackbar } from 'v3/components/Snackbar';
import { Modal, Text, Button } from 'v3/components/';
import theme from 'v3/theme';
import apiReport from 'services/apis/report';

import Loading from 'components/Loading';
import { formatDateCustom, formatTime } from 'utils/formatter';
import moment from 'moment';
import * as Styled from './styles';

export function Destination({ destination }) {
  const type = useMemo(() => {
    if (!destination?.action) {
      return 'Origem';
    }

    if (destination.action === 'delivery') {
      return 'Entrega';
    }

    return 'Aduana';
  }, [destination]);

  const waitingTime = useMemo(() => {
    if (!destination?.effective_time || !destination?.arrival_time) {
      return '';
    }

    const diff = moment(destination.effective_time).diff(
      moment(destination.arrival_time),
      'minutes'
    );

    if (diff < 0) {
      return '';
    }

    const hours = parseInt(diff / 60, 10);
    const minutes = diff % 60;

    if (typeof diff === 'number') {
      if (hours < 10) {
        if (minutes < 10) {
          return `0${hours}:0${minutes}`;
        }

        return `0${hours}:${minutes}`;
      }

      if (minutes < 10) {
        return `${hours}:0${minutes}`;
      }

      return `${hours}:${minutes}`;
    }

    return '';
  }, [destination?.arrival_time, destination?.effective_time]);

  const status = useMemo(() => {
    if (destination?.arrival_time && destination?.effective_time) {
      return 'completed';
    }
    if (destination?.arrival_time) {
      return 'progress';
    }

    return 'pending';
  }, [destination?.arrival_time, destination?.effective_time]);

  return (
    <Styled.DestinationWrap
      className="position-relative"
      completed={status === 'completed'}
      progress={status === 'progress'}
    >
      <Styled.TimeLine>
        <Styled.Check>
          <FaCheck color="#fff" size={9} className="position-relative" />
        </Styled.Check>
      </Styled.TimeLine>
      <Col xs="4">
        <Text type="body" color="dark" className="d-block">
          {type}
        </Text>
        <Text type="body" color="dark" className="d-block mb-3">
          {destination?.formatted_address}
        </Text>
      </Col>
      <Col xs="8">
        <Styled.DestinationTimes className="flex-nowrap">
          <Col className="text-center">
            {destination?.start_schedule ? (
              <>
                <Text type="body" color="dark" className="d-block">
                  {destination?.start_schedule
                    ? formatDateCustom(
                        destination?.start_schedule,
                        'DD, MMMM [de] YYYY'
                      )
                    : 'Não informado'}
                </Text>
                <FaClock color={theme.colors.dark} className="mr-1" />
                <Text type="label" color="dark">
                  {destination?.start_schedule
                    ? formatTime(destination?.start_schedule)
                    : 'Não informado'}
                </Text>
              </>
            ) : (
              'Não informado'
            )}
          </Col>
          <Col className="text-center">
            {destination?.arrival_time ? (
              <>
                <Text type="body" color="dark" className="d-block">
                  {destination?.arrival_time
                    ? formatDateCustom(
                        destination?.arrival_time,
                        'DD, MMMM [de] YYYY'
                      )
                    : 'Não informado'}
                </Text>
                <FaClock color={theme.colors.dark} className="mr-1" />
                <Text type="label" color="dark">
                  {formatTime(destination?.arrival_time)}
                </Text>
              </>
            ) : (
              'Não informado'
            )}
          </Col>
          <Col xs="1" className="text-center">
            {waitingTime ? (
              <>
                <FaClock color={theme.colors.dark} className="mr-1" />
                <Text type="label" color="dark">
                  {waitingTime}
                </Text>
              </>
            ) : null}
          </Col>
          <Col className="text-center">
            {destination?.effective_time ? (
              <>
                <Text type="body" color="dark" className="d-block">
                  {formatDateCustom(
                    destination?.effective_time,
                    'DD, MMMM [de] YYYY'
                  )}
                </Text>
                <FaClock color={theme.colors.dark} className="mr-1" />
                <Text type="label" color="dark">
                  {formatTime(destination?.effective_time)}
                </Text>
              </>
            ) : (
              'Não informado'
            )}
          </Col>
        </Styled.DestinationTimes>
      </Col>
    </Styled.DestinationWrap>
  );
}

export function DestinationsModal({ isOpen, onClose, destination, travelId }) {
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [origin, setOrigin] = useState(null);
  const [destinations, setDestinations] = useState([]);

  async function getLoadDestionations(id) {
    setLoading(true);
    try {
      const { data } = await apiReport.get(`travels/${id}/destinations`);
      const [originData, ...destinationsData] = data;
      setOrigin(originData);
      setDestinations(destinationsData);
    } catch (err) {
      if (err.data?.response?.message) {
        snackbar.show(<Text>{err.data.response.message}</Text>, {
          type: 'error',
        });
      }

      snackbar.show(<Text>Erro ao buscar as origens e destinos</Text>, {
        type: 'error',
      });
    }

    setLoading(false);
  }

  useEffect(() => {
    if (isOpen && destination?.loadId) {
      getLoadDestionations(destination?.loadId);
    }
  }, [destination?.loadId, isOpen]);

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      heading={
        <Text type="header" color="dark" weight={500} className="d-flex align-items-center">
          Origem e Destinos
          <Styled.TravelLink to={`/viagens/${destination?.travelId}`} target="_blank" className="ml-3">
            Viagem {destination?.travelId}{destination?.externalId ? ` - ${destination?.externalId}` : ''}
          </Styled.TravelLink>
        </Text>
      }
      body={
        <>
          {loading ? (
            <div className="text-center">
              <Loading />
            </div>
          ) : (
            <>
              <Row>
                <Col xs="4">
                  <Text type="body" color="dark" className="d-block mb-3">
                    Origem
                  </Text>
                </Col>
                <Col xs="8">
                  <Row className="flex-nowrap">
                    <Col className="text-center">
                      <Text type="body" color="dark" className="d-block mb-3">
                        Agendado
                      </Text>
                    </Col>
                    <Col className="text-center">
                      <Text type="body" color="dark" className="d-block mb-3">
                        Chegada
                      </Text>
                    </Col>
                    <Col xs="1" className="text-center">
                      &nbsp;
                    </Col>
                    <Col className="text-center">
                      <Text type="body" color="dark" className="d-block mb-3">
                        Efetivado
                      </Text>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Destination destination={origin} />
              <Text type="body" color="dark" className="d-block mb-3 mt-4">
                Destino
              </Text>
              {destinations.map(destination => (
                <Destination key={destination.id} destination={destination} />
              ))}
            </>
          )}
        </>
      }
      footer={
        <Button onClick={onClose} variant="primary">
          Fechar
        </Button>
      }
    />
  );
}
