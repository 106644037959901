import React from 'react';
import { Button, Radio, Select, Text } from 'v3/components';
import TextArea from 'v3/components/TextArea';
import Space from 'v3/components/Space';
import { MarginLiberationAttachement } from '../Attachment';
import {
  AttachmentContainer,
  ButtonsContainer,
  InfoContainer,
  ModalContentContainer,
  StyledMarginContainer,
  StyledMarginText,
} from '../styles';

export function Liberation({
  status,
  setStatus,
  file,
  setFile,
  obs,
  setObs,
  justification,
  setJustification,
  justificationOptions,
  loading,
  handleCreation,
  handleCancel,
  errors,
  mostRecentLiberationPending,
  isFetchingTravel,
}) {
  return (
    <>
      <ModalContentContainer>
        <div>
          <Text type="label" color="dark" weight="500" as="p">
            Comparativo de Margem
          </Text>
          <StyledMarginContainer>
            <StyledMarginText
              type="regular"
              color="dark"
              title="Porcentagem de margem mínima ERP"
            >
              Mínima <span>{mostRecentLiberationPending?.importedMargin}%</span>
            </StyledMarginText>

            <StyledMarginText
              type="regular"
              color="dark"
              title="Porcentagem de margem viagem"
            >
              Viagem{' '}
              <span>{mostRecentLiberationPending?.calculatedMargin}%</span>
            </StyledMarginText>
          </StyledMarginContainer>
        </div>
        <InfoContainer>
          <Select
            label="Justificativa Liberação Margem *"
            options={justificationOptions}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            value={justification}
            onChange={e => setJustification(e)}
            error={errors.justification}
          />
        </InfoContainer>
        <div>
          <TextArea
            label="Observação *"
            resizable={false}
            value={obs}
            onChange={e => setObs(e?.target?.value)}
            error={errors?.obs}
          />
        </div>
        <div>
          <Radio.Group
            horizontal
            label="Status *"
            value={status}
            onChange={({ target }) => {
              setStatus(target.value);
            }}
          >
            <Radio value="Aprovado">
              <Text color="dark" type="label">
                Aprovado
              </Text>
            </Radio>
            <Radio value="Reprovado">
              <Text color="dark" type="label">
                Reprovado
              </Text>
            </Radio>
          </Radio.Group>
        </div>
        <AttachmentContainer>
          <Text type="label" weight={500} color="dark" as="p">
            Anexo
          </Text>
          <MarginLiberationAttachement file={file} setFile={setFile} />
        </AttachmentContainer>
      </ModalContentContainer>
      <ButtonsContainer>
        <Space x="0.5rem">
          <Button
            disabled={loading}
            loading={loading || isFetchingTravel}
            variant="secondary"
            onClick={handleCancel}
          >
            Cancelar
          </Button>
          <Button
            disabled={loading}
            onClick={handleCreation}
            loading={loading || isFetchingTravel}
          >
            Salvar
          </Button>
        </Space>
      </ButtonsContainer>
    </>
  );
}
