import React from 'react';
import { useTravel } from '../..';
import { RiskManagerDetailsCard } from './withoutPolicyRiskManager/details';
import { GRORiskManagerDetailsCard } from './withPolicyRiskManager/details';

export function RiskManagerCard() {
  const travel = useTravel();
  if (travel?.data?.policy?.id) {
    return <GRORiskManagerDetailsCard />;
  }
  return <RiskManagerDetailsCard />;
}
