import styled from 'styled-components';
import { Modal } from 'v3/components';
import Space from 'v3/components/Space';

export const StyledModal = styled(Modal)`
  .modal-content {
    height: 85vh;
    overflow-y: scroll;
  }
`;

export const StyledSpace = styled(Space)`
  padding: 1rem;
`;
