import styled from 'styled-components';

export const Button = styled.button`
  background-color: transparent;
  padding: 0;
  margin-left: 5px;

  &:active,
  &:focus {
    outline: none;
  }
`;
