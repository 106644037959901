import React from 'react';
import { Button, Select, Text } from 'v3/components';
import TextArea from 'v3/components/TextArea';
import { Col, Row } from 'react-bootstrap';
import { ButtonsContainer, SectionContainer } from '../styles';
import { onSearchGroups } from '../utils';
import { useSnackbar } from 'v3/components/Snackbar';

export function ChangePersonResponsible({
  responsible,
  setResponsible,
  description,
  setDescription,
  setShow,
  errors,
  setErrors,
  handleChangeResponsibleData,
  loadingUpdate,
  groupOptions,
}) {
  const snackbar = useSnackbar();
  function changeResponsible(value, event) {
    if (event.action === 'select-option' && value && value.length > 5) {
      snackbar.show(<Text>Máximo de 5 grupos</Text>, {
        type: 'error',
      });
      return;
    }
    
    setResponsible(value)
  }
  return (
    <SectionContainer>
      <Text type="header" color="dark" weight={500}>
        Alterar Responsável
      </Text>
      <Row>
        <Col lg={3} md={3} sm={6} xs={12}>
          <Select.Async
            label="Grupo Responsável *"
            onSearch={onSearchGroups}
            value={responsible}
            onChange={changeResponsible}
            getOptionLabel={option => option?.name}
            getOptionValue={option => option.id}
            error={errors?.responsible}
            isDisabled={loadingUpdate}
            options={groupOptions}
            multiple
          />
        </Col>
      </Row>
      <TextArea
        placeholder="Motivo alteração *"
        value={description}
        onChange={e => setDescription(e.target.value)}
        resizable={false}
        error={errors?.description}
        disabled={loadingUpdate}
      />
      <ButtonsContainer>
        <Button
          variant="secondary"
          onClick={() => {
            setDescription('');
            setErrors({});
            setShow(old => ({ ...old, changeResponsible: false }));
          }}
          disabled={loadingUpdate}
        >
          <Text weight={500} color="dark" type="regular">
            CANCELAR
          </Text>
        </Button>
        <Button
          onClick={() => {
            handleChangeResponsibleData();
          }}
          loading={loadingUpdate}
        >
          <Text weight={500} color="white" type="regular">
            SALVAR
          </Text>
        </Button>
      </ButtonsContainer>
    </SectionContainer>
  );
}
