/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */

import React, { useState, useEffect } from 'react';

import { Col, Row } from 'react-bootstrap';

import api from 'services/api';

import Modal from 'v3/components/Modal';
import Select from 'v3/components/Select';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';

import { useUser } from '../context';

function AddShippingCompany({ isOpen, onClose }) {
  const user = useUser();
  const [shippingCompany, setShippingCompany] = useState();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  async function fetchShippingCompanies(inputValue) {
    if (inputValue.length > 3) {
      const response = await api.get(
        `persons/shipping-company?search=${inputValue}`
      );
      return response.data;
    }
    return false;
  }

  function handleAddShipper() {
    user.setData({
      shipping_companies: [...user.data.shipping_companies, shippingCompany],
    });

    setError('');
    setShippingCompany('');
    onClose();
  }

  async function handleAttachShipper() {
    try {
      setLoading(true)
      await api.put(`v3/user/${user.data.id}/shipping-company`, {
        shippingCompanyId: shippingCompany.id,
      });
      user.setData({
        shipping_companies: [...user.data.shipping_companies, shippingCompany],
      });
      setError('');
      setShippingCompany('');
      onClose();
    } catch (error) {
      user.showSnackbar('Erro ao adicionar transportadora', { type: 'error' });
    }finally{
      setLoading(false)
    }
  }

  function handleAdd() {
    if (
      user.data.shipping_companies?.find(item => item.id === shippingCompany.id)
    ) {
      setError('Essa transportadora já está cadastrada');
      return;
    }
    if (user.data.id) handleAttachShipper();
    else handleAddShipper();
  }

  return (
    <>
      <Modal
        show={isOpen}
        onHide={onClose}
        size='lg'
        heading={
          <Text color="dark" type="header">
            Transportadora
          </Text>
        }
        body={
          <Row>
            <Col className="d-flex flex-column align-items-center">
              <Select.Async
                className="mt-3"
                label={
                  <Text type="label" weight="500">
                    Transportadora
                  </Text>
                }
                onSearch={search => fetchShippingCompanies(search)}
                value={shippingCompany}
                getOptionLabel={option => {
                  const label = `${option.social_name} - ${option.cgccpf}`;
                  return label;
                }}
                getOptionValue={option => option.id}
                onChange={value => {
                  setShippingCompany(value);
                }}
                error={error}
              />
              <Button
                onClick={handleAdd}
                className="my-2"
                disabled={!shippingCompany}
                loading={loading}
              >
                <Text type="regular" weight="500" color="white">
                  Adicionar
                </Text>
              </Button>
            </Col>
          </Row>
        }
      />
    </>
  );
}

export default AddShippingCompany;
