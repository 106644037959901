import React, { useState } from 'react';

import { Text, Button, Modal, Input } from 'v3/components';
import Upload from 'v3/components/Upload';
import { validateAttachmentDescription } from '../utils';

export function OccurrenceAttachmentModal({
  setFiles = () => {},
  isOpen,
  handleClose,
  sendDirectly = false,
  onSendDirectly = async () => {},
}) {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState([]);
  const [fileDescription, setFileDescription] = useState('');
  const [errors, setErrors] = useState({});

  async function addAttachment() {
    try {
      const isValid = await validateAttachmentDescription(
        fileDescription,
        setErrors
      );
      if (isValid === false) {
        setLoading(false);
        return false;
      }
      setLoading(true);
      setFiles(old => [
        ...old,
        {
          description: fileDescription || '',
          file: [...file],
        },
      ]);
      setFile([]);
      setErrors({});
      setFileDescription('');
      setLoading(false);
      handleClose();
    } catch (error) {
      setFile([]);
      setErrors({});
      setFileDescription('');
      setLoading(false);
    }
  }

  async function handleSendDirectly() {
    try {
      const isValid = await validateAttachmentDescription(
        fileDescription,
        setErrors
      );
      if (isValid === false) {
        setLoading(false);
        return false;
      }
      setLoading(true);
      await onSendDirectly([
        {
          description: fileDescription || '',
          file: [...file],
        },
      ]);
      setFile([]);
      setErrors({});
      setFileDescription('');
      setLoading(false);
      handleClose();
    } catch (error) {
      setFile([]);
      setErrors({});
      setFileDescription('');
      setLoading(false);
    }
  }

  function onFileChanged({ target }, hasError) {
    setFile([]);
    setErrors({});
    if (hasError) {
      setErrors({ file: 'O arquivo selecionado é maior que 1MB' });
      return;
    }
    setFile(
      [...target.files].map(file => {
        return {
          blob: file,
          size: file?.size,
          name: file?.name,
          type: file?.type,
          tempPath: URL.createObjectURL(file),
        };
      })
    );
  }
  return (
    <Modal
      show={isOpen}
      handleClose={handleClose}
      heading={
        <Text type="subtitle" color="dark" weight="400">
          Anexo
        </Text>
      }
      body={
        <div>
          <div className="mb-3">
            <Text>Adicione um arquivo</Text>
            <Upload
              name="file"
              label="Arquivo máximo de 1MB"
              accept=".jpg,.jpeg,.png,.pdf, .xlsx, .xls"
              multiple
              ignoreValidation
              value={file}
              onChange={onFileChanged}
              error={errors.file}
            />
          </div>

          <Input
            label="Descrição *"
            value={fileDescription}
            onChange={event => setFileDescription(event.target.value)}
            error={errors.description}
          />
          <div className="d-flex flex-column align-items-center mt-3">
            <Button
              loading={loading}
              onClick={sendDirectly ? handleSendDirectly : addAttachment}
              disabled={!file.length}
            >
              Adicionar
            </Button>
          </div>
        </div>
      }
    />
  );
}
