import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import theme from 'v3/theme';

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

export const PendencyLabel = styled.span`
  padding: 0.125rem 0.25rem;
  border-radius: 6px;
  background: ${props => props.theme.colors.orange};
  font-weight: bold;
  text-transform: capitalize;
  color: white;
`;

export const DetailsContainer = styled.div`
  border: 1px solid #d1d7dc;
  padding: 0.5rem;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
`;

export const StyledDetailCol = styled(Col)`
  @media (max-width: 576px) {
    margin: 'auto';
  }
  @media (min-width: 577px) {
    margin-top: 0.25rem;
  }
`;

export const OccurrenceDescriptionsContainer = styled.div`
  border: 1px solid #d1d7dc;
  border-radius: 6px;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: 10rem;
  overflow: scroll;
  scroll-behavior: smooth;
  :not(:hover)::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
  :not(:hover)::-webkit-scrollbar-track {
    opacity: 0;
  }
  :not(:hover)::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
  }
  :hover::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
  :hover::-webkit-scrollbar-track {
    background: ${theme.colors.light_gray};
    opacity: 0.5;
  }
  :hover::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border-radius: 10px;
  }
`;

export const DescriptionItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
