/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Text from 'v3/components/Text';
import Card, { DefaultLoadingBodyComponent } from 'v3/components/Card';
import { useTravel } from 'v3/pages/Travel';
import { formatDateTime } from 'v3/utils/formatter';
import moment from 'moment';
import { verifyDateExpired } from 'utils';

function BennerStatus({ bennerRef }) {
  const travel = useTravel();
  const [errorMessages, setErrorMessages] = useState({
    shippingCompany: '',
    driver: '',
    vehicle: '',
  });

  const isShippingCompanyRntrcValid = () => {
    const validationErrorMessages = [];

    const shipperRntrc = travel.data.shipper?.rntrcNumber;

    if (!shipperRntrc) {
      return validationErrorMessages;
    }

    const vehicle = travel.data.vehicle?.[0]?.vehicles;
    const wagons = travel.data.vehicle?.[0]?.wagons || [];
    if (vehicle.antt === shipperRntrc) {
      return validationErrorMessages;
    }

    const someVehicleHaveTheShipperRNTRC = wagons.some(
      wagon => wagon.antt === shipperRntrc
    );

    if (someVehicleHaveTheShipperRNTRC === false) {
      validationErrorMessages.push(
        'A RNTRC do transportador não contempla nenhum veiculo'
      );
    }

    return validationErrorMessages;
  };

  const isVehicleRntrcValid = () => {
    const validationErrorMessages = [];
    const rntrc = travel?.data?.vehicle?.[0]?.vehicles?.rntrcExpDate;

    const lastDestinationDate = moment(
      travel?.data?.loads?.loadDestinations[
        travel?.data?.loads?.loadDestinations.length - 1
      ]?.scheduledTime
    ).set({ hour: 0, minute: 0, seconds: 0 });

    if (moment(rntrc).isBefore(lastDestinationDate)) {
      validationErrorMessages.push('RNTRC vencido');
    }

    return validationErrorMessages;
  };

  const isChronotacographValid = () => {
    const validationErrorMessages = [];

    const lastDestinationDate = moment(
      travel?.data?.loads?.loadDestinations[
        travel?.data?.loads?.loadDestinations.length - 1
      ]?.scheduledTime
    ).set({ hour: 0, minute: 0, seconds: 0 });
    const chronotacographNotExpired = moment(
      travel?.data?.vehicle?.[0]?.vehicles.chronotachographExpDate
    ).isBefore(lastDestinationDate, 'day');

    if (chronotacographNotExpired) {
      validationErrorMessages.push('Cronotacógrafo vencido');
    }
    return validationErrorMessages;
  };

  const isInsuranceValid = () => {
    const validationErrorMessages = [];

    const insuranceValidity =
      travel.data.vehicle?.[0]?.vehicles.insuranceValidity;

    if (!insuranceValidity) return validationErrorMessages;

    const lastDestinationDate = moment(
      travel?.data?.loads?.loadDestinations[
        travel.data?.loads?.loadDestinations.length - 1
      ]?.scheduledTime
    ).set({ hour: 0, minute: 0, seconds: 0 });

    const insuranceExpDate = moment();

    if (verifyDateExpired(lastDestinationDate, insuranceExpDate) === false) {
      validationErrorMessages.push('Seguro contra terceiros vencido');
    }
    return validationErrorMessages;
  };

  const isDriverLicenseValid = () => {
    const lastDestinationDate = moment(
      travel.data?.loads?.loadDestinations[
        travel?.data?.loads?.loadDestinations.length - 1
      ]?.scheduledTime
    ).set({ hour: 0, minute: 0, seconds: 0 });
    const cnhExpDate = moment(travel.data.driver?.cnhExpDate);

    return verifyDateExpired(lastDestinationDate, cnhExpDate);
  };

  const validateShippingCompany = () => {
    if (!travel?.data?.shipperProps) {
      return [];
    }
    const bennerProblems =
      travel?.data?.shipperProps?.descriptionStatusBenner || [];
    const rntrcMessages = isShippingCompanyRntrcValid();
    return [bennerProblems, ...rntrcMessages];
  };

  const validateDriver = () => {
    const validationErrorMessages = [];

    if (!travel?.data?.driverData) {
      return validationErrorMessages;
    }

    const { hasProblemsBenner, descriptionStatusBenner } =
      travel.data.driverData;

    if (hasProblemsBenner && descriptionStatusBenner.length) {
      validationErrorMessages.push(descriptionStatusBenner);
    }

    const isLicenseValid = isDriverLicenseValid();

    if (isLicenseValid === false) {
      validationErrorMessages.push('CNH vencida');
    }

    return validationErrorMessages.filter(item => item?.length > 0).join(', ');
  };

  const validateVehicle = () => {
    if (!travel?.data?.vehicle?.[0]?.vehicles) {
      return [];
    }

    const bennerProblems =
      travel?.data?.vehicle?.[0]?.vehicles?.descriptionStatusBenner || [];
    const insuranceErrorMessages = isInsuranceValid();
    const chronotacographErrorMessages = isChronotacographValid();
    const rntrcErrorMessages = isVehicleRntrcValid();

    return [
      bennerProblems,
      ...insuranceErrorMessages,
      ...chronotacographErrorMessages,
      ...rntrcErrorMessages,
    ]
      .filter(item => item?.length > 0)
      .join(', ');
  };

  const handleValidations = () => {
    const validationMessages = {
      shippingCompany: validateShippingCompany(),
      driver: validateDriver(),
      vehicle: validateVehicle(),
    };

    setErrorMessages(validationMessages);
  };

  useEffect(() => {
    if (travel.isLoading === false) {
      handleValidations();
    }
  }, [travel?.isLoading]);

  return (
    <Card
      ref={bennerRef}
      loading={travel.isLoading}
      LoadingBodyComponent={() => (
        <DefaultLoadingBodyComponent linesCount={4} perLine={2} />
      )}
      header={
        <Text color="dark" type="header">
          Validação Benner
        </Text>
      }
    >
      <Row>
        <Col xs={12}>
          <div className="container-fluid">
            <Row className="form">
              <Col xs={12}>
                <Text type="regular" color="dark" weight={500}>
                  Validação do Benner para Transportadora:
                </Text>
              </Col>
              {travel?.data?.shipperProps?.hasOwnProperty(
                'hasProblemsBenner'
              ) ? (
                <>
                  <Col md={6} xs={12}>
                    <Text type="label" color="dark">
                      Status da Importação
                    </Text>

                    <Text type="regular" color="gray" as="p" className="mt-2">
                      {' '}
                      {travel?.data?.shipperProps?.hasProblemsBenner
                        ? 'Com problemas'
                        : 'Cadastro sem problemas'}
                    </Text>
                  </Col>
                  <Col md={6} xs={12}>
                    <Text type="label" color="dark">
                      Data da última importação
                    </Text>

                    <Text type="regular" color="gray" as="p" className="mt-2">
                      {' '}
                      {formatDateTime(
                        travel?.data?.shipperProps?.updatedAtStatusBenner
                      ) || 'Não informado'}
                    </Text>
                  </Col>
                  {errorMessages.shippingCompany ? (
                    <Col xs={12}>
                      <Text type="label" color="dark">
                        Descrição
                      </Text>

                      <Text type="regular" color="gray" as="p" className="mt-2">
                        {errorMessages.shippingCompany}
                      </Text>
                    </Col>
                  ) : null}
                </>
              ) : (
                <Col xs={12}>
                  <Text type="regular" color="gray" as="p" className="mt-2">
                    Transportadora não importada do Benner
                  </Text>
                </Col>
              )}

              <Col xs={12}>
                <Text type="regular" color="dark" weight={500}>
                  Validação do Benner para Motorista:
                </Text>
              </Col>
              {travel?.data?.driverData?.hasOwnProperty('hasProblemsBenner') ? (
                <>
                  <Col md={6} xs={12}>
                    <Text type="label" color="dark">
                      Status da Importação
                    </Text>

                    <Text type="regular" color="gray" as="p" className="mt-2">
                      {' '}
                      {travel?.data?.driverData?.hasProblemsBenner
                        ? 'Com problemas'
                        : 'Cadastro sem problemas'}
                    </Text>
                  </Col>
                  <Col md={6}>
                    <Text type="label" color="dark">
                      Data da última importação
                    </Text>

                    <Text type="regular" color="gray" as="p" className="mt-2">
                      {' '}
                      {formatDateTime(
                        travel?.data?.driverData?.updatedAtStatusBenner
                      ) || 'Não informado'}
                    </Text>
                  </Col>
                  {errorMessages.driver ? (
                    <Col xs={12}>
                      <Text type="label" color="dark">
                        Descrição
                      </Text>

                      <Text type="regular" color="gray" as="p" className="mt-2">
                        {errorMessages.driver}
                      </Text>
                    </Col>
                  ) : null}
                </>
              ) : (
                <Col xs={12}>
                  <Text type="regular" color="gray" as="p" className="mt-2">
                    Motorista não importado do Benner
                  </Text>
                </Col>
              )}

              <Col xs={12}>
                <Text type="regular" color="dark" weight={500}>
                  Validação do Benner para Veículo:
                </Text>
              </Col>
              {travel?.data?.vehicle?.[0]?.hasOwnProperty('vehicles') &&
              travel?.data?.vehicle?.[0]?.vehicles.hasOwnProperty(
                'hasProblemsBenner'
              ) ? (
                <>
                  <Col md={6} xs={12}>
                    <Text type="label" color="dark">
                      Status da Importação
                    </Text>

                    <Text type="regular" color="gray" as="p" className="mt-2">
                      {' '}
                      {travel?.data?.vehicle?.[0]?.vehicles?.hasProblemsBenner
                        ? 'Com problemas'
                        : 'Cadastro sem problemas'}
                    </Text>
                  </Col>
                  <Col md={6} xs={12}>
                    <Text type="label" color="dark">
                      Data da última importação
                    </Text>

                    <Text type="regular" color="gray" as="p" className="mt-2">
                      {' '}
                      {formatDateTime(
                        travel?.data?.vehicle?.[0]?.vehicles
                          ?.updatedAtStatusBenner
                      ) || 'Não informado'}
                    </Text>
                  </Col>
                  {errorMessages.vehicle && (
                    <Col xs={12}>
                      <Text type="label" color="dark">
                        Descrição
                      </Text>

                      <Text type="regular" color="gray" as="p" className="mt-2">
                        {errorMessages.vehicle}
                      </Text>
                    </Col>
                  )}
                </>
              ) : (
                <Col xs={12}>
                  <Text type="regular" color="gray" as="p" className="mt-2">
                    Veículo não importado do Benner
                  </Text>
                </Col>
              )}
            </Row>
          </div>
        </Col>
      </Row>
    </Card>
  );
}

export default BennerStatus;
