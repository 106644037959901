/* eslint-disable import/no-unresolved */

import React from 'react';
import { useHistory } from 'react-router-dom';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Card from 'v3/components/Card';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';

function CardIssuer({ issuer }) {
  const history = useHistory();

  function formatAddress(obj) {
    const number = obj.number ? `, ${obj.number}` : '';
    const neighborhood = obj.neighborhood ? ` - ${obj.neighborhood}` : '';
    const city = obj.city ? `, ${obj.city.name} - ${obj.city.province.uf}` : '';

    return `${obj.address}${number}${neighborhood}${city}`;
  }

  return (
    <Col lg={6} md={6} xs={12}>
      <Card
        className="full-height-card"
        header={
          <>
            <Text color="#464E5F" className="col-9">
              {issuer.trading_name}{' '}
              <Text type="label" color="gray">
                {issuer.name}
              </Text>
            </Text>
          </>
        }
        HeaderRightComponent={
          <Button
            className="col-3"
            variant="secondary"
            onClick={() => history.push(`/emissores/${issuer.id}`)}
          >
            <Text color="dark">Detalhes</Text>
          </Button>
        }
      >
        <Row>
          <Col lg={4} md={4} xs={6} className="mb-3">
            <Text className="d-block load-label-align mb-2">Telefone</Text>
            <Text color="gray" type="regular" className="d-block mb-1">
              {issuer.phone || '-'}
            </Text>
          </Col>
          <Col lg={4} md={4} xs={6} className="mb-3">
            <Text className="d-block load-label-align mb-2">Documento</Text>
            <Text type="regular" color="gray">
              {issuer.document || '-'}
            </Text>
          </Col>
          <Col lg={4} md={4} xs={6} className="mb-3">
            <Text className="d-block load-label-align mb-2">
              Inscrição Estadual
            </Text>
            <Text type="regular" color="gray">
              {issuer.ie || '-'}
            </Text>
          </Col>
          <Col lg={12} md={12} xs={12} className="mb-3">
            <Text className="d-block load-label-align mb-2">Endereço</Text>
            <Text type="regular" color="gray">
              {formatAddress(issuer)}
            </Text>
          </Col>
        </Row>
      </Card>
    </Col>
  );
}

export default CardIssuer;
