import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import api from 'services/api';
import Modal from 'v3/components/Modal';
import Button from 'v3/components/Button';
import Datepicker from 'v3/components/DatePicker';
import Text from 'v3/components/Text';
import Select from 'v3/components/Select';
import { useSnackbar } from 'v3/components/Snackbar';
import { fetchBranchOffices } from 'v3/pages/User/shared/controller';
import * as Controller from './controller';

export default function ModalRenewLoad({ load, dueDate, show, handleClose }) {
  const history = useHistory();
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [renewedLoadId, setRenewedLoadId] = useState(0);
  const [successfullyRenovated, setSuccessfullyRenovated] = useState(false);
  const [newDueDate, setNewDueDate] = useState(
    moment().add(1, 'day').format('DD/MM/YYYY HH:mm')
  );
  const [reasonSelected, setReasonSelected] = useState({
    label: 'Ajuste de programação',
    value: '11 - Ajuste de programação',
  });
  const [reasonList, setReasonList] = useState([]);
  const [isConfirmButtonDisabled, setConfirmButtonDisabled] = useState(false);
  const [isBranchActive, setIsBranchActive] = useState(true);
  const [selectedNewBranch, setSelectedNewBranch] = useState(null);
  const [newBranchError, setNewBranchError] = useState('');

  function close(backToList = false) {
    setNewDueDate(moment().add(1, 'day').format('DD/MM/YYYY HH:mm'));
    setSuccessfullyRenovated(false);
    handleClose(backToList);
  }

  async function handleRenewLoad() {
    const auxRenewedLoadId = await Controller.renewLoad({
      setConfirmButtonDisabled,
      setSuccessfullyRenovated,
      newDueDate,
      reasonSelected,
      snackbar,
      load,
      setLoading,
      selectedNewBranch,
      setNewBranchError,
      isBranchActive,
    });

    if (!auxRenewedLoadId) {
      return;
    }

    setRenewedLoadId(auxRenewedLoadId.id);
  }

  useEffect(() => {
    async function fetchReasons() {
      try {
        const response = await api.get('load/cancel/reasons');
        setReasonList(response.data);
      } catch (error) {
        //
      }
    }
    if (show) {
      fetchReasons();
    }
  }, [show]);

  useEffect(() => {
    async function fetchActualBranchOffice() {
      try {
        setLoading(true);
        const { data } = await api.get(
          `branch-offices/${load.branch_office_id}`
        );
        setIsBranchActive(data?.active);
      } catch {
        // catcher
      } finally {
        setLoading(false);
      }
    }
    if (show && load.branch_office_id) {
      fetchActualBranchOffice();
    }
  }, [load, show]);

  return (
    <Modal
      size="md"
      backdrop="static"
      closeButton={false}
      show={show}
      onHide={() => close}
      heading={
        <Text type="subheader" color="dark" weight="500">
          Renovação da carga - {load?.id}
        </Text>
      }
      body={
        successfullyRenovated ? (
          <Text color="dark" type="regular" weight="500">
            Carga renovada com sucesso!
          </Text>
        ) : (
          <>
            <Row>
              <Col xs={12} md={6}>
                <Text type="label" color="dark">
                  Data de vencimento atual
                </Text>
                <Text
                  id="renovacao-carga-data-vencimento-atual"
                  type="regular"
                  color="gray"
                  as="p"
                  className="mt-3"
                >
                  {moment(dueDate).format('DD/MM/YYYY HH:mm')}
                </Text>
              </Col>

              <Col xs={12} md={6} style={{ marginTop: 2 }}>
                <Datepicker
                  label="Nova data de vencimento *"
                  value={newDueDate}
                  onChange={event => setNewDueDate(event.target.value)}
                  withTime
                  id="input-renovacao-carga-data-vencimento-nova"
                />
              </Col>

              <Col md={12} className="mt-3 mb-2">
                <Select
                  label="Motivo do cancelamento *"
                  options={reasonList}
                  value={reasonSelected}
                  onChange={value => setReasonSelected(value)}
                  getOptionLabel={option => option.label}
                  getOptionValue={option => option.value}
                  id="select-renovacao-carga-motivo-cancelamento"
                />
              </Col>
              <Col md={12} className="mt-2">
                <Text type="label" color="dark">
                  Essa carga irá ser cancelada e uma nova será criada.
                </Text>
              </Col>
              <Col md={12} className="mt-2">
                <Text type="label" color="dark" style={{ fontStyle: 'italic' }}>
                  O id externo e documentos da viagem serão excluidos após a
                  renovação.
                </Text>
              </Col>
              <Col md={12} className="mt-2">
                {!isBranchActive && (
                  <>
                    <Text
                      type="label"
                      color="dark"
                      style={{ fontStyle: 'italic' }}
                    >
                      A filial associada a esta carga está atualmente inativa.
                      Por favor, escolha outra filial para continuar com o
                      processo de renovação.
                    </Text>
                    <Select.Async
                      label="Filial *"
                      onSearch={search => {
                        setNewBranchError('');
                        return fetchBranchOffices({
                          search,
                          userCompanyId: load?.company_id,
                        });
                      }}
                      value={selectedNewBranch}
                      onChange={value => {
                        setNewBranchError('');
                        setSelectedNewBranch(value);
                      }}
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.id}
                      error={newBranchError}
                    />
                  </>
                )}
              </Col>
            </Row>
          </>
        )
      }
      footer={
        successfullyRenovated ? (
          <Col className="p-0">
            <Button
              className="mr-2 py-2"
              variant="white"
              outlined
              onClick={() => close()}
              id="button-renovacao-carga-cancelar"
            >
              <Text type="regular" color="dark" weight="500">
                Fechar
              </Text>
            </Button>

            <Button
              className="mr-2 py-2"
              variant="primary"
              onClick={() => {
                close();
                history.push(`/cargas/${renewedLoadId}`);
              }}
              id="button-renovacao-carga-visualizar-carga-renovada"
            >
              <Text type="regular" color="white" weight="500">
                Visualizar carga renovada
              </Text>
            </Button>
          </Col>
        ) : (
          <>
            <Button
              className="py-2"
              variant="white"
              outlined
              onClick={() => close()}
              id="button-renovacao-carga-cancelar"
            >
              <Text type="regular" color="dark" weight="500">
                Fechar
              </Text>
            </Button>
            <Button
              className="py-2"
              variant="primary"
              disabled={!reasonSelected || isConfirmButtonDisabled}
              onClick={() => handleRenewLoad()}
              loading={loading}
              id="button-renovacao-carga-renovar"
            >
              <Text type="regular" color="white" weight="500">
                Renovar
              </Text>
            </Button>
          </>
        )
      }
    />
  );
}
