import React from 'react';
import { Button, Text } from 'v3/components';
import TextArea from 'v3/components/TextArea';
import { ButtonsContainer, SectionContainer } from '../styles';
import { AddAttachment } from '../AddAttachments';

export function AddDescriptionToOccurrence({
  description,
  setDescription,
  setShow,
  errors,
  setErrors,
  setStartDate,
  setEndDate,
  handleAddNewDescription,
  loadingUpdate,
  files,
  setFiles,
}) {
  return (
    <>
      <AddAttachment
        files={files}
        setFiles={setFiles}
        loading={loadingUpdate}
      />
      <SectionContainer>
        <Text type="header" color="dark" weight={500}>
          Adicionar Descrição
        </Text>
        <TextArea
          placeholder="Adicionar descrição *"
          value={description}
          onChange={e => setDescription(e.target.value)}
          resizable={false}
          error={errors?.description}
          disabled={loadingUpdate}
        />

        <ButtonsContainer>
          <Button
            variant={'secondary'}
            onClick={() => {
              setDescription('');
              setStartDate(null);
              setEndDate(null);
              setErrors({});
              setShow(old => ({ ...old, addDescriptionToOccurrence: false }));
            }}
            disabled={loadingUpdate}
          >
            <Text weight={500} color="dark" type="regular">
              CANCELAR
            </Text>
          </Button>
          <Button
            onClick={() => {
              handleAddNewDescription();
            }}
            loading={loadingUpdate}
          >
            <Text weight={500} color="white" type="regular">
              SALVAR
            </Text>
          </Button>
        </ButtonsContainer>
      </SectionContainer>
    </>
  );
}
