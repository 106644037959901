import React, { useState } from 'react';
import { number_format } from '../../functions';
import { ContentInvoice } from './styles';

import { useHistory } from 'react-router-dom';

import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

import Button from 'components/Button';
import Card from 'components/Card';
import Label from 'components/Label';
import Snackbar from 'components/Snackbar';

import moment from 'moment';

import api from 'services/api';

import TabsDetalhesFinanceiro from './TabsDetalhesFinanceiro';

import { validatePermission } from 'actions/index';
import { formatDateTime } from 'utils/formatter';

export default function CardFinanceiro(financial) {
  const [expanded, setExpanded] = React.useState(false);

  const [faturasList, setFaturasList] = useState([]);
  const [boletosList, setBoletosList] = useState([]);
  const [cteList, setCteList] = useState([]);
  const [ediList, setEdiList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [snackBar, setSnackBar] = useState({
    display: false,
    type: 'error',
    message: '',
  });

  const history = useHistory();

  function handleExpandClick() {
    setExpanded(!expanded);
    getFaturas(financial);
    getBoletos(financial);
    getCTe(financial);
    getEdi(financial);
  }

  async function getFaturas(financial) {
    const cteNf = financial.financial.nfs
      ? financial.financial.nfs
      : financial.financial.cte_number === null
      ? ''
      : financial.financial.cte_number[0].trim();
    let responseFaturas = await api.get(
      `invoice-billing-travel/${financial.financial.attended_id}/${cteNf}`
    );

    if (responseFaturas.status === 200) {
      setFaturasList(responseFaturas.data);
    }
  }

  async function getEdi(financial) {
    let responseEdi = await api.get(
      `edi-billing-travel/${financial.financial.attended_id}`
    );

    if (responseEdi.status === 200) {
      setEdiList(responseEdi.data);
    }
  }

  async function getBoletos(financial) {
    let responseBoletos = await api.get(
      `billet-billing-travel/${financial.financial.attended_id}?cte_number=${
        financial.financial.nfs || financial.financial.cte_number
      }`
    );

    if (responseBoletos.status === 200) {
      setBoletosList(responseBoletos.data);
    }
  }

  async function getCTe(financial) {
    let responseCTE = await api.get(
      `cte-travel-data/${financial.financial.id}`
    );

    if (responseCTE.status === 200) {
      setCteList(responseCTE.data);
    }
  }

  function pickBorderColor(financial) {
    let status = financial.financial.status.toLowerCase();
    if (status === undefined) return '';
    switch (status) {
      case 'pago no prazo':
        return 'success';
      case 'cobrando':
        return 'danger';
      case 'pago em atraso':
        return 'warning';
      case 'cancelada':
        return 'secondary_light';
      default:
        return '';
    }
  }

  function editInvoice(invoiceNfs) {
    history.push(`/faturas/${invoiceNfs}`);
  }

  async function approve() {
    try {
      const res = await api.put(
        `billing-travel-data/${financial.financial.id}`,
        {
          approved_at: new Date(),
        }
      );

      setSnackBar({
        type: 'success',
        message: 'Fatura aprovada com sucesso.',
        display: true,
      });

      financial.updateFinancial(res.data);
    } catch (ex) {
      setSnackBar({
        type: 'error',
        message: 'Não foi possível aprovar essa fatura.',
        display: true,
      });
    }
  }

  return (
    <Grid container>
      <Snackbar
        open={snackBar.display}
        type={snackBar.type}
        message={snackBar.message}
        onClose={() => setSnackBar(false)}
      />
      <Grid item xs={12}>
        <Card
          bordered
          bordercolor={pickBorderColor(financial)}
          style={{ marginBottom: '15px' }}
        >
          <Grid container justify="space-between">
            <Grid item xs={8}>
              <label>
                <strong>{financial.financial.social_name}</strong> -
                {financial.financial.cgccpf}
              </label>
            </Grid>
            <Grid right item xs={4}>
              {validatePermission('APROVAR_FATURA') ? (
                <>
                  {!loading && (
                    <div form item right>
                      {financial.financial.approved_at === null ? (
                        <Button
                          style={{
                            width: '165px',
                            display: 'inline-block',
                            float: 'right',
                            marginRight: '15px',
                          }}
                          color="success"
                          onClick={approve}
                          disabled={financial.financial.approved_at}
                        >
                          Aprovar
                        </Button>
                      ) : (
                        <Label
                          style={{
                            width: '165px',
                            display: 'inline-block',
                            float: 'right',
                            marginRight: '15px',
                          }}
                        >
                          Aprovada em{' '}
                          {formatDateTime(financial.financial.approved_at)}
                        </Label>
                      )}
                    </div>
                  )}
                  <br />
                </>
              ) : (
                financial.financial.approved_at && (
                  <>
                    <Grid container lg={6} md={12}>
                      {!loading && (
                        <div form item right>
                          {financial.financial.approved_at && (
                            <Label>
                              Aprovada em{' '}
                              {formatDateTime(financial.financial.approved_at)}
                            </Label>
                          )}
                        </div>
                      )}
                    </Grid>
                    <br />
                  </>
                )
              )}
            </Grid>
            <ContentInvoice item>
              <ul>
                <li>
                  <label>FATURA:</label>
                  {financial.financial.numero_fatura &&
                  financial.financial.numero_fatura === null
                    ? 'Não cadastrada'
                    : financial.financial.numero_fatura}
                </li>
                <li>
                  <label espaco={1}>NF:</label>
                  {financial.financial.numero_nota === null
                    ? 'Não cadastrada'
                    : financial.financial.numero_nota.trim()}
                </li>
                <li>
                  <label espaco={1}>CTe/NFS:</label>
                  {financial.financial.nfs
                    ? financial.financial.nfs
                    : financial.financial.cte_number === null
                    ? 'Não cadastrada'
                    : Array.isArray(financial.financial.cte_number)
                    ? financial.financial.cte_number.join(', ')
                    : financial.financial.cte_number.trim()}
                </li>
                <li>
                  {(validatePermission('APROVAR_FATURA') ||
                    validatePermission('EDITAR_FATURA') ||
                    validatePermission('ADICIONAR_COMENTARIO_FATURA')) && (
                    <Link
                      style={{
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                        float: 'left',
                        marginTop: '10px',
                        cursor: 'pointer',
                        pointerEvents:
                          validatePermission('APROVAR_FATURA') ||
                          validatePermission('EDITAR_FATURA') ||
                          validatePermission('ADICIONAR_COMENTARIO_FATURA')
                            ? 'auto'
                            : 'none',
                      }}
                      onClick={() => editInvoice(financial.financial.id)}
                    >
                      Ver / Editar
                    </Link>
                  )}
                </li>
              </ul>
            </ContentInvoice>
            <ContentInvoice item right>
              <ul>
                <li>
                  <label>STATUS:</label>
                  {financial.financial.status === null
                    ? 'Não informado'
                    : `${financial.financial.status}`}
                </li>
                <li>
                  <label>VALOR:</label>
                  {financial.invoice_amount === null
                    ? 'Não possui valor cadastrado'
                    : 'R$ ' +
                      number_format(
                        financial.financial.valor_nota,
                        2,
                        ',',
                        '.'
                      )}
                </li>
                <li>
                  <label>VENCIMENTO:</label>
                  {financial.financial.vencimento
                    ? moment(financial.financial.vencimento).format(
                        'DD/MM/YYYY'
                      )
                    : ''}
                </li>
                <li>
                  <Button
                    variant="contained"
                    onClick={() => handleExpandClick()}
                    aria-expanded={expanded}
                  >
                    {!expanded ? 'Mais detalhes' : 'Menos Detalhes'}
                  </Button>
                </li>
              </ul>
            </ContentInvoice>
          </Grid>
        </Card>
        <div
          hidden={!expanded}
          style={{
            position: 'relative',
            marginBottom: '30px',
            backgroundColor: '#FFF',
            '@media (min-width: 768px)': {
              padding: '15px',
            },
            '@media (max-width: 768px)': {
              padding: '0',
            },
          }}
        >
          <Collapse in={expanded} timeout="auto" unmountOnExit />
          <TabsDetalhesFinanceiro
            style={{
              position: 'relative',
              '@media (min-width: 768px)': {
                padding: '15px',
              },
              '@media (max-width: 768px)': {
                padding: '0',
              },
            }}
            faturasList={faturasList}
            boletosList={boletosList}
            cteList={cteList}
            ediList={ediList}
            cteNumber={
              financial.financial.nfs
                ? financial.financial.nfs
                : financial.financial.cte_number === null
                ? '0'
                : Array.isArray(financial.financial.cte_number)
                ? financial.financial.cte_number.join(', ')
                : financial.financial.cte_number.trim()
            }
            attendedId={financial.financial.attended_id}
            handleClose={handleExpandClick}
          />
        </div>
      </Grid>
    </Grid>
  );
}
