import React from 'react';

import { useHistory, Link } from 'react-router-dom';

import { Row, Col } from 'react-bootstrap';

import ApplicationLayout from 'v3/layouts/Application';
import Space from 'v3/components/Space';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';

import Data from './Data';

export default function RasterBranchesDetails() {
  const history = useHistory();

  return (
    <ApplicationLayout
      title="Filial Raster"
      RightComponent={
        <Space x="4px">
          <Button variant="secondary" onClick={() => history.goBack()}>
            <Text color="dark" weight={500} type="regular">
              Voltar
            </Text>
          </Button>
          <Link to="cadastrar">
            <Button>
              <Text color="white" weight={500} type="regular">
                Nova filial
              </Text>
            </Button>
          </Link>
        </Space>
      }
    >
      <Row>
        <Col xs={12} md={6}>
          <Data />
        </Col>
      </Row>
    </ApplicationLayout>
  );
}
