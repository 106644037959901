import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useHistory, Link, useParams } from 'react-router-dom';

import ApplicationLayout from 'v3/layouts/Application';
import { useSnackbar } from 'v3/components/Snackbar';
import Space from 'v3/components/Space';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';

import { RegisterInfo } from 'v3/components/RegisterInfo';
import { validatePermission } from 'actions/index';
import { usePermission } from 'hooks';
import Summary from './Summary';
import Drivers from '../shared/Drivers';
import { useVehicle } from '../shared/context';
import Main from './Main';
import Implements from './Implements';
import Data from './Data';
import Owner from './Owner';
import TollTags from './TollTags';
import Attachments from './Attachments';
import BennerStatus from './BennerStatus';
import { Maintenances } from './Maintenances';

export default function VehicleDetails() {
  const history = useHistory();
  const vehicle = useVehicle();
  const params = useParams();
  const [attachments, setAttachments] = useState([]);
  const snackbar = useSnackbar();
  usePermission('VISUALIZAR_VEICULOS', { redirect: true });

  useEffect(() => {
    snackbar.show(
      <Text>
        Alterações de dados cadastrais devem ser solicitadas na viagem no card
        "Solicitação de Ajuste de Cadastro".
      </Text>,
      { type: 'warning' }
    );
  }, []);

  useEffect(() => {
    if (params.id) {
      vehicle.setPrevVehicle(vehicle.data);
    }
  }, [params.id]);

  return (
    <ApplicationLayout
      title={`Veículo ${vehicle.data.id || ''}`}
      RightComponent={
        <Space x="4px">
          <Button variant="secondary" onClick={() => history.goBack()}>
            <Text color="dark" weight={500}>
              Voltar
            </Text>
          </Button>
          {validatePermission('CADASTRAR_VEICULOS') && (
            <Link to="cadastrar">
              <Button>
                <Text color="white" weight={500}>
                  Novo veículo
                </Text>
              </Button>
            </Link>
          )}
        </Space>
      }
    >
      <Row>
        <Col>
          <Summary />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <RegisterInfo loading={vehicle.isFetching} />
        </Col>

        <Col xs={12} md={6}>
          <Main />
          <Drivers />
        </Col>
        <Col xs={12} md={6}>
          <Implements />
          <Data />
          <Owner />
          <TollTags />
          <BennerStatus />
        </Col>
      </Row>
      <Row>
        <Maintenances />
      </Row>
      <Row>
        <Attachments
          attachments={attachments}
          setAttachments={setAttachments}
        />
      </Row>
    </ApplicationLayout>
  );
}
