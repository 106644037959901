import React, { useState, useEffect } from 'react';
import {
  fetchCities,
  getProvinces,
  fetchCountries,
  onSearchNegotiator,
  fetchDriver,
  onSearchUser,
  fetchShippingCompanies,
} from 'pages/relatorios/acompanhamento-viagem/utils';
import { Col, Row } from 'react-bootstrap';
import { endOfDay, startOfDay } from 'date-fns';
import { DatePicker, Input, Select, Button, Text } from 'v3/components';
import theme from 'v3/theme';
import api, { cooperplaceApi } from 'services/api';
import {
  fetchGroupVehicles,
  fetchUserCompaniesSelectOptions,
  fetchUserCostCentersByCompany,
} from 'utils/fetches';
import { usePermission, useQuery } from 'hooks';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { ButtonsColContainer } from './style';
import {
  currentRegionOptions,
  serviceLevelOptions,
  vehiclesAvailability,
  fleetTypeOptions,
  availableCountries,
  isTrackedOptions,
  aggregationOptions,
} from './utils';

export function Filter({
  filters,
  setFilters,
  toggleFilters,
  setToggleFilters,
  loading,
  setLoading,
  fetchData,
  loadingDownload,
  getDownload,
}) {
  const query = useQuery();
  const history = useHistory();
  const [provincesOptions, setProvincesOptions] = useState([]);
  const [allCountries, setAllCountries] = useState([]);
  const [companiesOptions, setCompaniesOptions] = useState([]);

  usePermission('VISUALIZAR_ACOMPANHAMENTO_DE_VEICULOS', { redirect: true });

  const countriesOptions = allCountries?.filter(country =>
    availableCountries.includes(country.name.toLowerCase())
  );

  async function fetchSelectOptions(url, params = {}) {
    try {
      const { data } = await api.get(url, { params });
      return data;
    } catch (err) {
      return [];
    }
  }
  async function fetchVehicle(search) {
    try {
      const response = await api.get('vehicle/license_plate', {
        params: { search },
      });
      return response.data;
    } catch (error) {
      return [];
    }
  }
  async function fetchProvinces() {
    const provinces = await getProvinces();
    setProvincesOptions(provinces);
  }

  function handleCompanySearch(search) {
    if (!search) {
      return companiesOptions;
    }
    const parsed = search.toLowerCase();
    return companiesOptions.filter(
      option => option?.name?.toLowerCase().search(parsed) >= 0
    );
  }

  async function getCompaniesOptions() {
    try {
      const response = await fetchUserCompaniesSelectOptions();
      setCompaniesOptions(response);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchProvinces();
    getCompaniesOptions();
    fetchCountries(setAllCountries);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      history.replace({ search: query.toString() });
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [filters]);

  async function updateUserFilterPreferences() {
    try {
      setLoading(true);
      const {
        currentRegion,
        currentProvince,
        lastLoad,
        lastCity,
        lastProvince,
        serviceLevel,
        plate,
        currentCountry,
        currentCity,
        lastTravel,
        lastNegotiator,
        lastTaker,
        lastTravelCostCenter,
        driver,
        lastExternalId,
        pcp,
        vehicleAvailability,
        isTracked,
        shippingCompany,
        fleetType,
        lastTravelDateBegin,
        lastTravelDateEnd,
        fleetGroup,
        companies,
      } = filters;
      const data = {
        currentRegion: currentRegion || [],
        currentProvince: currentProvince || [],
        lastLoad: lastLoad || [],
        lastTravelDateBegin:
          lastTravelDateBegin ||
          moment().subtract(30, 'days').format('DD/MM/YYYY'),
        lastTravelDateEnd: lastTravelDateEnd || moment().format('DD/MM/YYYY'),
        lastCity: lastCity || [],
        lastProvince: lastProvince || [],

        serviceLevel: serviceLevel || [],
        currentCountry: currentCountry || [],
        currentCity: currentCity || [],

        lastTravel: lastTravel || [],
        lastNegotiator: lastNegotiator || [],
        lastTaker: lastTaker || [],
        lastTravelCostCenter: lastTravelCostCenter || [],
        driver: driver || [],

        lastExternalId: lastExternalId || [],
        pcp: pcp || [],
        vehicleAvailability: vehicleAvailability || [],
        isTracked: isTracked || [],
        shippingCompany: shippingCompany || [],
        fleetType: fleetType || [],
        plate: plate || [],
        fleetGroup: fleetGroup || [],
        companies: companies || [],
      };
      await cooperplaceApi.post(`users/definitions`, {
        type: 'vehicle_tower_control_filters',
        definition: JSON.stringify(data),
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  async function handleFilter() {
    await updateUserFilterPreferences();
    fetchData();
  }
  return (
    <>
      <ButtonsColContainer>
        <Button onClick={() => setToggleFilters(!toggleFilters)}>
          <Text type="regular" weight={500} fontSize="0.75rem">
            {toggleFilters ? 'Maximizar Filtros' : 'Minimizar Filtros'}
          </Text>
        </Button>
        <Button
          variant="success"
          loading={loading}
          type="button"
          onClick={handleFilter}
        >
          <Text type="regular" weight={500} fontSize="0.75rem">
            Filtrar
          </Text>
        </Button>
        <Button
          variant="secondary"
          onClick={getDownload}
          loading={loadingDownload}
        >
          <Text weight={500} color="dark" type="regular" fontSize="0.75rem">
            Exportar
          </Text>
        </Button>
      </ButtonsColContainer>
      {!toggleFilters ? (
        <>
          <Row>
            <Col sm={12} md={3} lg={2}>
              <Select.Async
                label="Município atual"
                onSearch={fetchCities}
                value={filters?.currentCity}
                onChange={value => {
                  if (value) {
                    query.set('currentCity', value.map(v => v.id).join(','));
                  } else {
                    query.delete('currentCity');
                  }
                  setFilters(old => ({ ...old, currentCity: value }));
                }}
                getOptionLabel={option => {
                  if (option.province?.uf) {
                    return `${`${option.name} - ${option.province?.uf}`}`;
                  }
                  return option.name;
                }}
                getOptionValue={option =>
                  `${`${option.name}-${option.province?.uf}`}`
                }
                modalHeading="Selecione um município"
                modalBodyTitle="Municípios:"
                multiple
                horizontal
              />
            </Col>
            <Col sm={12} md={3} lg={2}>
              <Select.Async
                value={filters?.currentProvince}
                onChange={value => {
                  if (value) {
                    query.set(
                      'currentProvince',
                      value.map(value => value.id).join(',')
                    );
                  } else {
                    query.delete('currentProvince');
                  }
                  setFilters(old => ({ ...old, currentProvince: value }));
                }}
                placeholder="Selecione..."
                label="Estado Atual"
                labelTextColor={theme.colors.dark}
                options={provincesOptions}
                getOptionLabel={option => `${option.label}`}
                getOptionValue={option => option.label}
                modalHeading="Selecione um estado"
                modalBodyTitle="Estados:"
                multiple
                horizontal
              />
            </Col>
            <Col sm={12} md={3} lg={2}>
              <Select.Async
                label="País atual"
                modalHeading="Selecione um país"
                modalBodyTitle="Países:"
                options={countriesOptions}
                value={filters?.currentCountry}
                onChange={value => {
                  if (value) {
                    query.set('currentCountry', value.map(v => v.id).join(','));
                  } else {
                    query.delete('currentCountry');
                  }
                  setFilters(old => ({ ...old, currentCountry: value }));
                }}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                multiple
                horizontal
              />
            </Col>
            <Col sm={12} md={3} lg={2}>
              <Select.Async
                value={filters?.currentRegion}
                onChange={value => {
                  if (value) {
                    query.set(
                      'currentRegion',
                      `('${value.map(value => value.value).join(`','`)}')`
                    );
                  } else {
                    query.delete('currentRegion');
                  }
                  setFilters(old => ({ ...old, currentRegion: value }));
                }}
                placeholder="Selecione..."
                label="Região Atual"
                labelTextColor={theme.colors.dark}
                options={currentRegionOptions}
                getOptionLabel={option => `${option.label}`}
                getOptionValue={option => option.value}
                modalHeading="Selecione uma região"
                modalBodyTitle="Regiões:"
                multiple
                horizontal
              />
            </Col>
            <Col sm={12} md={3} lg={2}>
              <Select.Async
                label="Município última viagem"
                onSearch={fetchCities}
                value={filters?.lastCity}
                onChange={value => {
                  if (value) {
                    query.set('lastCity', value.map(v => v.id).join(','));
                  } else {
                    query.delete('lastCity');
                  }
                  setFilters(old => ({ ...old, lastCity: value }));
                }}
                getOptionLabel={option => {
                  if (option.province?.uf) {
                    return `${`${option.name} - ${option.province?.uf}`}`;
                  }
                  return option.name;
                }}
                getOptionValue={option =>
                  `${`${option.name}-${option.province?.uf}`}`
                }
                modalHeading="Selecione um município"
                modalBodyTitle="Municípios:"
                multiple
                horizontal
              />
            </Col>
            <Col sm={12} md={3} lg={2}>
              <Select.Async
                label="Estado última viagem"
                options={provincesOptions}
                placeholder="Selecione..."
                getOptionLabel={option => `${option?.label}`}
                getOptionValue={option => option?.value}
                value={filters?.lastProvince}
                onChange={value => {
                  if (value) {
                    query.set(
                      'lastProvince',
                      `('${value.map(value => value.uf).join(`','`)}')`
                    );
                  } else {
                    query.delete('lastProvince');
                  }
                  setFilters(old => ({ ...old, lastProvince: value }));
                }}
                modalHeading="Selecione um estado"
                modalBodyTitle="Estados:"
                multiple
                horizontal
              />
            </Col>

            <Col sm={12} md={3} lg={2}>
              <Input
                label="Nº última carga"
                value={filters?.lastLoad}
                onChange={e => {
                  if (e.target.value) {
                    query.set('lastLoad', e.target.value);
                  } else {
                    query.delete('lastLoad');
                  }
                  setFilters(old => ({ ...old, lastLoad: e.target.value }));
                }}
              />
            </Col>
            <Col sm={12} md={3} lg={2}>
              <Input
                label="Nº última viagem"
                value={filters?.lastTravel}
                onChange={e => {
                  if (e.target.value) {
                    query.set('lastTravel', e.target.value);
                  } else {
                    query.delete('lastTravel');
                  }
                  setFilters(old => ({ ...old, lastTravel: e.target.value }));
                }}
              />
            </Col>
            <Col sm={12} md={3} lg={2}>
              <Input
                label="Nº última viagem externa"
                value={filters?.lastExternalId}
                onChange={e => {
                  if (e.target.value) {
                    query.set('lastExternalId', e.target.value);
                  } else {
                    query.delete('lastExternalId');
                  }
                  setFilters(old => ({
                    ...old,
                    lastExternalId: e.target.value,
                  }));
                }}
              />
            </Col>
            <Col sm={12} md={3} lg={2}>
              <Select.Async
                label="Centro de custo última viagem"
                labelWhiteSpace="nowrap"
                modalHeading="Selecione uma Centro de custo"
                modalBodyTitle="Centros de custo:"
                multiple
                horizontal
                onSearch={search => fetchUserCostCentersByCompany(search)}
                value={filters?.lastTravelCostCenter}
                onChange={value => {
                  {
                    if (value) {
                      query.set(
                        'lastTravelCostCenter',
                        value.map(v => v.id).join(',')
                      );
                    } else {
                      query.delete('lastTravelCostCenter');
                    }
                    setFilters(old => ({
                      ...old,
                      lastTravelCostCenter: value,
                    }));
                  }
                }}
                getOptionLabel={option => option?.name}
                getOptionValue={option => option?.id}
              />
            </Col>
            <Col sm={12} md={3} lg={2}>
              <Select.Async
                label="Tomador última viagem"
                onSearch={search =>
                  fetchSelectOptions('persons/customers', { search })
                }
                value={filters?.lastTaker}
                onChange={value => {
                  if (value) {
                    query.set('lastTaker', value.map(v => v.id).join(','));
                  } else {
                    query.delete('lastTaker');
                  }
                  setFilters(old => ({ ...old, lastTaker: value }));
                }}
                getOptionLabel={option => option.label}
                getOptionValue={option => option.id}
                modalHeading="Selecione uma tomador"
                modalBodyTitle="Tomadores:"
                multiple
                horizontal
              />
            </Col>
            <Col sm={12} md={3} lg={2}>
              <Select.Async
                label="Negociador última viagem"
                modalHeading="Selecione um negociador"
                modalBodyTitle="Negociadores:"
                value={filters?.lastNegotiator}
                onSearch={onSearchNegotiator}
                onChange={value => {
                  if (value) {
                    query.set('lastNegotiator', value.map(v => v.id).join(','));
                  } else {
                    query.delete('lastNegotiator');
                  }
                  setFilters(old => ({ ...old, lastNegotiator: value }));
                }}
                multiple
                horizontal
                getOptionLabel={option => {
                  let label = `${option.username}`;
                  if (option?.cpf) {
                    label += ` - ${option?.cpf}`;
                  }
                  return label;
                }}
                getOptionValue={option => option.id}
              />
            </Col>
            <Col sm={12} md={3} lg={2}>
              <Select.Async
                label="PCP última viagem"
                modalHeading="Selecione um pcp"
                modalBodyTitle="Pcp:"
                horizontal
                value={filters?.pcp}
                onSearch={onSearchUser}
                onChange={value => {
                  if (value) {
                    query.set('pcp', value.map(v => v.id).join(','));
                  } else {
                    query.delete('pcp');
                  }
                  setFilters(old => ({ ...old, pcp: value }));
                }}
                multiple
                getOptionLabel={option => {
                  let label = `${option.username}`;
                  if (option?.cpf) {
                    label += ` - ${option?.cpf}`;
                  }
                  return label;
                }}
                getOptionValue={option => option.id}
              />
            </Col>
            <Col sm={12} md={3} lg={2}>
              <DatePicker
                guide
                label="Data finalização última viagem "
                labelWhiteSpace="nowrap"
                value={filters?.lastTravelDateBegin}
                format="DD/MM/YYYY"
                onChange={e => {
                  if (e.target.value) {
                    query.set(
                      'lastTravelDateBegin',
                      moment(e.target.value, 'DD/MM/YYYY').isValid()
                        ? startOfDay(
                            new Date(moment(e.target.value, 'DD/MM/YYYY'))
                          ).toISOString()
                        : ''
                    );
                  } else {
                    query.delete('lastTravelDateBegin');
                  }
                  setFilters(old => ({
                    ...old,
                    lastTravelDateBegin: e.target.value,
                  }));
                }}
              />
            </Col>
            <Col sm={12} md={3} lg={2}>
              <DatePicker
                guide
                label="Até"
                labelWhiteSpace="nowrap"
                value={filters?.lastTravelDateEnd}
                format="DD/MM/YYYY"
                onChange={e => {
                  if (e.target.value) {
                    query.set(
                      'lastTravelDateEnd',
                      moment(e.target.value, 'DD/MM/YYYY').isValid()
                        ? endOfDay(
                            new Date(moment(e.target.value, 'DD/MM/YYYY'))
                          ).toISOString()
                        : ''
                    );
                  } else {
                    query.delete('lastTravelDateEnd');
                  }
                  setFilters(old => ({
                    ...old,
                    lastTravelDateEnd: e.target.value,
                  }));
                }}
              />
            </Col>
            <Col sm={12} md={3} lg={2}>
              <Select.Async
                label="Empresa"
                onSearch={search => handleCompanySearch(search)}
                options={companiesOptions}
                labelWhiteSpace="nowrap"
                modalHeading="Selecione uma Empresa"
                modalBodyTitle="Empresa:"
                multiple
                horizontal
                value={filters?.companies}
                onChange={value => {
                  if (value) {
                    query.set(
                      'companies',
                      value.map(value => value.id).join(',')
                    );
                  } else {
                    query.delete('companies');
                  }
                  setFilters(old => ({ ...old, companies: value }));
                }}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
              />
            </Col>

            <Col sm={12} md={3} lg={2}>
              <Select.Async
                label="Nível de serviço"
                options={serviceLevelOptions}
                value={filters?.serviceLevel}
                getOptionLabel={option => `${option?.label}`}
                getOptionValue={option => option?.value}
                onChange={value => {
                  if (value) {
                    query.set(
                      'serviceLevel',
                      `('${value.map(value => value.value).join(`','`)}')`
                    );
                  } else {
                    query.delete('serviceLevel');
                  }
                  setFilters(old => ({ ...old, serviceLevel: value }));
                }}
                modalHeading="Selecione um nível de serviço"
                modalBodyTitle="Níveis de serviço:"
                multiple
                horizontal
              />
            </Col>
            <Col sm={12} md={3} lg={2}>
              <Select.Async
                onSearch={fetchVehicle}
                placeholder="Selecione..."
                label="Placa principal"
                value={filters?.plate}
                onChange={value => {
                  if (value) {
                    query.set('plate', value.map(v => v.id).join(','));
                  } else {
                    query.delete('plate');
                  }
                  setFilters(old => ({ ...old, plate: value }));
                }}
                getOptionLabel={option => option.plate}
                getOptionValue={option => option.id}
                modalHeading="Selecione uma placa"
                modalBodyTitle="Placas:"
                multiple
                horizontal
              />
            </Col>

            <Col sm={12} md={3} lg={2}>
              <Select.Async
                label="Motorista"
                onSearch={fetchDriver}
                value={filters?.driver}
                onChange={value => {
                  {
                    if (value) {
                      query.set('driver', value.map(v => v.id).join(','));
                    } else {
                      query.delete('driver');
                    }
                    setFilters(old => ({ ...old, driver: value }));
                  }
                }}
                getOptionLabel={option => option.social_name}
                getOptionValue={option => option.id}
                modalHeading="Selecione um motorista"
                modalBodyTitle="Motoristas:"
                multiple
                horizontal
              />
            </Col>

            <Col sm={12} md={3} lg={2}>
              <Select
                options={vehiclesAvailability}
                label="Disponibilidade veículo"
                value={filters?.vehicleAvailability}
                onChange={value => {
                  if (value) {
                    query.set('vehicleAvailability', value.value);
                  } else {
                    query.delete('vehicleAvailability');
                  }
                  setFilters(old => ({ ...old, vehicleAvailability: value }));
                }}
                getOptionLabel={option => `${option?.label}`}
                getOptionValue={option => option?.value}
              />
            </Col>
            <Col sm={12} md={3} lg={2}>
              <Select
                options={isTrackedOptions}
                label="Rastreamento"
                value={filters?.isTracked}
                onChange={value => {
                  if (value) {
                    query.set('isTracked', value.value);
                  } else {
                    query.delete('isTracked');
                  }
                  setFilters(old => ({ ...old, isTracked: value }));
                }}
                getOptionLabel={option => `${option?.label}`}
                getOptionValue={option => option?.value}
              />
            </Col>
            <Col sm={12} md={3} lg={2}>
              <Select.Async
                label="Transportador veículo"
                value={filters?.shippingCompany}
                onSearch={value => fetchShippingCompanies(value)}
                onChange={value => {
                  if (value) {
                    query.set(
                      'shippingCompany',
                      value.map(v => v.id).join(',')
                    );
                  } else {
                    query.delete('shippingCompany');
                  }
                  setFilters(old => ({
                    ...old,
                    shippingCompany: value,
                  }));
                }}
                getOptionLabel={option => option.label}
                getOptionValue={option => option.id}
                multiple
                horizontal
              />
            </Col>
            <Col sm={12} md={3} lg={2}>
              <Select.Async
                label="Tipo Frota"
                value={filters?.fleetType}
                onChange={value => {
                  if (value) {
                    query.set(
                      'fleetType',
                      `('${value.map(value => value.value).join(`','`)}')`
                    );
                  } else {
                    query.delete('fleetType');
                  }
                  setFilters(old => ({ ...old, fleetType: value }));
                }}
                getOptionLabel={option => option.label}
                getOptionValue={option => option.value}
                options={fleetTypeOptions}
                multiple
                horizontal
              />
            </Col>
            <Col sm={12} md={3} lg={2}>
              <Select.Async
                label="Grupo Frota"
                labelWhiteSpace="nowrap"
                modalHeading="Selecione um Grupo de Frota"
                modalBodyTitle="Grupo de Frota:"
                multiple
                horizontal
                onSearch={search => fetchGroupVehicles(search)}
                value={filters?.fleetGroup}
                onChange={value => {
                  {
                    if (value) {
                      query.set('fleetGroup', value.map(v => v.id).join(','));
                    } else {
                      query.delete('fleetGroup');
                    }
                    setFilters(old => ({
                      ...old,
                      fleetGroup: value,
                    }));
                  }
                }}
                getOptionLabel={option => option?.description}
                getOptionValue={option => option?.id}
              />
            </Col>
            <Col sm={12} md={3} lg={2}>
              <Select
                label="Agregamento"
                value={filters?.aggregation}
                onChange={value => {
                  if (value) {
                    query.set('aggregation', value?.id);
                  } else {
                    query.delete('aggregation');
                  }
                  setFilters(old => ({ ...old, aggregation: value }));
                }}
                getOptionLabel={option => option.label}
                getOptionValue={option => option.id}
                options={aggregationOptions}
                horizontal
              />
            </Col>
          </Row>
        </>
      ) : null}
    </>
  );
}
