import axios from 'axios';

const issuerApi = axios.create({
  baseURL: process.env.REACT_APP_ISSUER_API_URL,
});

issuerApi.interceptors.request.use((config) => {
  const outputConfig = config;

  const token = localStorage.getItem("token");
  if (token) {
    outputConfig.headers.Authorization = `Bearer ${token}`;
  }

  return outputConfig;
});

export default issuerApi;