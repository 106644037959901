import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { FaTrash, FaPencilAlt } from 'react-icons/fa';
import { Col, Row } from 'react-bootstrap';

import api from 'services/api';

import Card from 'v3/components/Card';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import Fallback from 'v3/components/Fallback';
import Table from 'v3/components/Table';
import Badge from 'v3/components/Badge';

import useModal from 'hooks/useModal';
import theme from '../../../../theme';
import DriverModal from '../Modals/Drivers';

import { useVehicle } from '../context';

import { validatePermission } from 'actions/index';

export default function VehicleDrivers() {
  const vehicle = useVehicle();
  const driverModal = useModal();
  const params = useParams();

  const [modalName, setModalName] = useState('');

  const vehiclePermission = validatePermission('ALTERAR_VEICULOS');

  function openModal() {
    setModalName('Driver');
  }

  async function handleRemove(index, item) {
    const deleting = vehicle.data.drivers.filter((_, idx) => idx !== index);
    vehicle.setData({
      drivers: deleting,
    });

    vehicle.setPrevVehicle(old => ({
      ...old,
      drivers: deleting,
    }));

    await api.delete(`v3/vehicle/driver/${params.id}/${item.value}`);
  }

  useEffect(() => {
    if (modalName === 'Driver') {
      driverModal.open();
    }
  }, [modalName]);

  return (
    <>
      <DriverModal
        isOpen={driverModal.isOpen}
        onClose={() => {
          driverModal.close();
          setModalName('');
        }}
      />
      <Card
        loading={vehicle.isFetching}
        header={
          <Text type="header" color="dark">
            Motoristas
          </Text>
        }
        HeaderRightComponent={
          <>
            {!vehicle.data?.drivers?.length ||
              (vehiclePermission && (
                <Button
                  onClick={() => {
                    openModal();
                    vehicle.setData({
                      currentDriverEditing: null,
                    });
                  }}
                >
                  <Text type="regular" weight="500" color="white">
                    Adicionar
                  </Text>
                </Button>
              ))}
          </>
        }
      >
        <Row className="form">
          <Fallback
            fall={!vehicle.data?.drivers?.length}
            component={
              <Col
                xs={12}
                className="align-items-center d-flex justify-content-center my-2 text-center"
              >
                <Text color={theme.colors.gray}>
                  Não há motoristas cadastrados para esse veículo.
                </Text>
              </Col>
            }
          >
            <Table>
              <thead>
                <tr>
                  <th>
                    <Text weight="500" color="dark">
                      Nome
                    </Text>
                  </th>
                  <th className="text-center">
                    {vehiclePermission && (
                      <Text weight="500" color="dark">
                        Ação
                      </Text>
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                {vehicle.data?.drivers
                  ?.sort((x, y) =>
                    x.current === y.current ? 0 : x.current ? -1 : 1
                  )
                  .map((item, index) => (
                    <tr>
                      <td>
                        <Text type="regular" color="dark">
                          {item.social_name}
                        </Text>
                        {item.current && (
                          <Badge
                            pill
                            light
                            variant="success"
                            className="ml-2"
                            size="sm"
                          >
                            <Text color="success" type="label">
                              Atual
                            </Text>
                          </Badge>
                        )}
                      </td>
                      <td width="120" className="text-center">
                        {vehiclePermission && (
                          <Button
                            variant="secondary"
                            size="sm"
                            className="mr-1"
                            onClick={() => {
                              openModal(item);
                              vehicle.setData({
                                currentDriverEditing: item,
                              });
                            }}
                          >
                            <FaPencilAlt size={16} color="#464E5F" />
                          </Button>
                        )}
                        {vehiclePermission && (
                          <Button
                            variant="error"
                            size="sm"
                            onClick={() => handleRemove(index, item)}
                            disabled={item.current}
                          >
                            <FaTrash size={16} />
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Fallback>
          <Text
            label="Vehicle"
            value={vehicle.data.driver}
            onChange={event => vehicle.setData({ driver: event.target.value })}
          />
          {!vehicle.data?.drivers?.length && (
            <Col
              xs={12}
              className="align-items-center d-flex justify-content-center my-2"
            >
              {vehiclePermission && (
                <Button
                  onClick={() => {
                    openModal(null);
                    vehicle.setData({
                      currentDriverEditing: null,
                    });
                  }}
                >
                  <Text type="regular" weight="500" color="white">
                    Adicionar
                  </Text>
                </Button>
              )}
            </Col>
          )}
        </Row>
      </Card>
    </>
  );
}
