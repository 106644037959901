import React, { useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Button, Signature, Text } from 'v3/components';
import { cooperplaceApi } from 'services/api';
import { StyledModal, StyledText } from './styles';

function DoesNotHaveSignature({ signatureRef }) {
  return <Signature ref={signatureRef} />;
}

function Body({
  signChecklist,
  snackbar,
  handleClose,
  setIsFetching,
  isFetching,
}) {
  const signatureRef = useRef(null);

  const toSignChecklist = async () => {
    try {
      setIsFetching(true);
      const arrayPoints = signatureRef.current.toData();
      if (!arrayPoints.length) {
        snackbar.show(<Text>Assinaturas vazias não são permitidas</Text>, {
          type: 'error',
        });
        return;
      }

      const base64 = signatureRef.current.toDataURL();
      await cooperplaceApi.post(`users/signature`, {
        base64,
      });
      await signChecklist();
      handleClose();
    } catch (error) {
      snackbar.show(<Text>Erro ao vincular assinatura ao usuário</Text>, {
        type: 'error',
      });
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <Row>
      <Col xs={12}>
        <div
          style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
        >
          <DoesNotHaveSignature signatureRef={signatureRef} />
        </div>
      </Col>
      <Col xs={12}>
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}
        >
          <Button loading={isFetching} onClick={toSignChecklist}>
            Assinar checklist
          </Button>
        </div>
      </Col>
    </Row>
  );
}

export default function SignatureModal({
  signatureModal,
  signChecklist,
  snackbar,
  setIsFetching,
  isFetching,
}) {
  return (
    <StyledModal
      centered
      show={signatureModal.isOpen}
      heading={<StyledText>Assinatura</StyledText>}
      body={
        <Body
          setIsFetching={setIsFetching}
          snackbar={snackbar}
          handleClose={signatureModal.close}
          signChecklist={signChecklist}
          isFetching={isFetching}
        />
      }
      handleClose={signatureModal.close}
      backdropClassName="backdrop-occurrences-aditional-field"
    />
  );
}
