import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Button, Card, Text } from 'v3/components';
import { useSnackbar } from 'v3/components/Snackbar';
import { useMultistepForm } from 'hooks/useMultiStepForm';
import { cooperplaceApi } from 'services/api';
import {
  LoadContext,
  checkClientCreditLimit,
} from 'v3/pages/Load/Register/controller';
import { DELIVERY_TYPE_OPTIONS } from 'v3/utils/constants';
import { fetchDispositionData } from 'utils/fetches';
import { currencyOptions } from 'v3/utils/formatter';
import { FreightValues } from 'v3/pages/Load/Register/PriceData/FreightValues';
import { VehiclesAndTracking } from './components/VehiclesAndTracking';
import { LocationCard } from './components/Locations/Locations';
import {
  FreightValuesSchema,
  LoadDataGroSchema,
  LocationsGroSchema,
  VehicleDataSchema,
} from './validators';
import { LoadGroProvider } from './context';
import { LoadDataGro } from './components/LoadData';
import {
  handleCreate,
  handleInitializeSingleOption,
  validateDestinationDate,
  validateDestinationDateWithOrigin,
  validateDestinationsEndDate,
  validateDestinationsStartDate,
} from './utils';
import {
  Line,
  ProgressBarContainer,
  Step,
  StepContainer,
  StepsContainer,
} from './styles';

const [, useLoad] = LoadContext;

export function RegisterLoadGRO() {
  const snackbar = useSnackbar();
  const location = useLocation();
  const history = useHistory();
  const {
    data,
    setData,
    setErrors,
    extraFields,
    inputsExtraFields,
    transferExtraFields,
    discountsExtraFields,
    loadingGro,
    setLoadingGro,
    setShowModal,
    setRegisteredLoad,
    rateResponse,
  } = useLoad();

  const { step, steps, back, next, currentStepIndex, isFirstStep, isLastStep } =
    useMultistepForm([
      <LoadDataGro />,
      <LocationCard />,
      <VehiclesAndTracking />,
      <FreightValues />,
    ]);

  const [errorsGro, setErrorsGro] = useState({});
  const [destinationsError, setDestinationsError] = useState({});
  const [operations, setOperations] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedPolicyProduct, setSelectedPolicyProduct] = useState(null);
  const [countryOptions, setCountriesOptions] = useState({
    origin: [],
    destination: [],
  });
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [bodiesTypes, setBodiesTypes] = useState([]);
  const [implementTypes, setImplementTypes] = useState([]);
  const [loadDispositionOptions, setLoadDispositionOptions] = useState([]);
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const [selectedRange, setSelectedRange] = useState(null);
  const [dailyCotation, setDailyCotation] = useState([]);
  const [debouncedCargo, setDebouncedCargo] = useState('');
  const [loadingRange, setLoadingRange] = useState(false);
  const [managerTrackerOptions, setManagerTrackerOptions] = useState([]);
  const disableProduct = !data?.operation && !products?.length;

  const handleGetSelectedPolicy = useCallback(async id => {
    try {
      const response = await cooperplaceApi.get(`policies/${id}`);
      const policyCurrency = currencyOptions.find(
        currency => currency.value === response?.data?.currency
      );
      setData(old => ({ ...old, load_currency: policyCurrency }));
      setSelectedPolicy(response.data);
    } catch (error) {
      snackbar.show(
        <Text>Falha ao recuperar dados da apólice do cliente!</Text>,
        {
          type: 'error',
        }
      );
      history.push('/cargas');
    }
  }, []);

  async function handleGetClientOperations(policies) {
    try {
      const response = await cooperplaceApi.get(`policies/operations`, {
        params: {
          policyIds: policies?.map(item => item?.id),
        },
      });
      if (response?.data?.length === 1) {
        handleInitializeSingleOption(response?.data, 'operation', setData);
        await handleGetSelectedPolicy(response?.data?.[0]?.policy?.id);
      }
      setOperations(response.data);
    } catch (error) {
      snackbar.show(
        <Text>Falha ao consultar operações disponíveis para o cliente!</Text>,
        {
          type: 'error',
        }
      );
      history.push('/cargas');
    }
  }

  function handleSelectProduct(value) {
    if (value) {
      const selectedNcmId = value?.productNcmId;
      let selectedPolicyPd = null;
      selectedPolicy.operations
        .filter(operation => operation?.id === data?.operation?.id)
        .forEach(operation => {
          operation.products.forEach(product => {
            product.products.forEach(pd => {
              if (selectedNcmId === pd?.product_id) {
                selectedPolicyPd = {
                  id: product?.id,
                  product: { ...pd },
                  vehicles: [...product?.vehicles],
                };
              }
            });
          });
        });
      setSelectedPolicyProduct(selectedPolicyPd);
    } else {
      setSelectedPolicyProduct(null);
    }
  }

  async function handleGetProducts(operationId, policyId) {
    try {
      const { data } = await cooperplaceApi.get(
        `products/forSelect?policyId=${policyId}&operationId=${operationId}`
      );
      if (data?.length === 1) {
        handleInitializeSingleOption(data, 'product', setData);
        handleSelectProduct(data[0]);
      }
      setProducts(data);
    } catch (error) {
      snackbar.show(<Text>Falha ao consultar produtos disponíveis!</Text>, {
        type: 'error',
      });
      history.push('/cargas');
    }
  }

  function handleChangeCargoValue(value) {
    setData(old => ({ ...old, cargoValue: value }));
  }
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedCargo(data?.cargoValue);
    }, 1000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [data?.cargoValue]);

  useEffect(() => {
    if (Number(debouncedCargo) > 0) {
      handleSearchRange();
    } else {
      setSelectedRange(null);
      setManagerTrackerOptions([]);
      setData(old => ({
        ...old,
        cargoValue: '0',
        manager_tracker: [],
        tracked: null,
        escorted: null,
      }));
    }
  }, [debouncedCargo]);

  async function handleSearchRange() {
    try {
      setData(old => ({
        ...old,
        manager_tracker: [],
        tracked: null,
        escorted: null,
      }));
      setLoadingRange(true);
      const response = await cooperplaceApi.get(
        `policies/${selectedPolicy?.id}/ranges`,
        {
          params: {
            value: data?.cargoValue,
            policyProductId: selectedPolicyProduct?.id,
            vehicleTypeIds: data?.vehicleTypes?.map(vt => vt.id),
            vehicleBodyTypeIds: data?.vehicleBodies?.map(vb => vb.id),
            vehicleImplementIds: data?.vehicleImplements?.map(vi => vi.id),
          },
        }
      );
      if (response?.data?.length === 0) {
        snackbar.show(
          <Text>
            O valor da mercadoria informada não se encaixa em nenhuma faixa de
            valores cadastradas na apólice selecionada!
          </Text>,
          { type: 'warning' }
        );
        setData(old => ({
          ...old,
          cargoValue: '0',
          manager_tracker: [],
          tracked: null,
          escorted: null,
        }));
        setLoadingRange(false);
        return;
      }
      const managerTrackers = response?.data[0]?.groups?.find(
        item => item?.code === 'manager_tracker'
      )?.items;
      setManagerTrackerOptions(managerTrackers);
      setSelectedRange(response.data[0]);
      setData(old => ({
        ...old,
        tracked: response.data[0].tracked,
        escorted: response.data[0].equipments_groups?.every(
          item => item.escorted
        ),
      }));
    } catch (error) {
      //
    } finally {
      setLoadingRange(false);
    }
  }

  async function getDispositions() {
    try {
      const response = await fetchDispositionData();
      setLoadDispositionOptions(response);
    } catch (error) {
      setLoadDispositionOptions([]);
    }
  }

  async function getCountriesOptions(type, policyProductId) {
    try {
      const response = await cooperplaceApi.get(
        `countries?policyProductId=${policyProductId}&policyRouteType=${type}`
      );
      return response.data;
    } catch (error) {
      return [];
    }
  }

  async function getLocationsOptions(policyProductId) {
    try {
      const [originCountry, destinationsCountries] = await Promise.all([
        getCountriesOptions('origin', policyProductId),
        getCountriesOptions('destination', policyProductId),
      ]);
      if (originCountry?.length === 1) {
        setData(old => ({
          ...old,
          origin: {
            ...old?.origin,
            country: originCountry[0],
          },
        }));
      }
      if (destinationsCountries?.length === 1) {
        setData(old => ({
          ...old,
          destinations: [
            {
              address: '',
              type: DELIVERY_TYPE_OPTIONS[1],
              date: '',
              complement: '',
              lat: '',
              lng: '',
              formattedAddress: '',
              neighborhood: '',
              city: '',
              province: '',
              zipCode: '',
              country: destinationsCountries[0],
              number: '',
              startSchedule: '',
              index: 0,
            },
          ],
        }));
      }
      setCountriesOptions({
        origin: originCountry,
        destination: destinationsCountries,
      });
    } catch (error) {
      //
    }
  }

  useEffect(() => {
    if (rateResponse) {
      setDailyCotation({
        BRL: {
          value: rateResponse?.buy,
          label: 'Compra',
        },
        USD: {
          value: rateResponse?.sell,
          label: 'Venda',
        },
      });
    }
  }, [rateResponse]);

  useEffect(() => {
    getDispositions();
    setData(old => ({
      ...old,
      loadCreationNumber: 1,
      operation: null,
      vehicleTypes: [],
      vehicleBodies: [],
      origin: {
        address: '',
        date: '',
        complement: '',
        lat: '',
        lng: '',
        formattedAddress: '',
        neighborhood: '',
        city: '',
        province: '',
        zipCode: '',
        country: null,
        number: '',
        startSchedule: '',
      },
      destinations: [
        {
          address: '',
          type: DELIVERY_TYPE_OPTIONS[1],
          date: '',
          complement: '',
          lat: '',
          lng: '',
          formattedAddress: '',
          neighborhood: '',
          city: '',
          province: '',
          zipCode: '',
          country: null,
          number: '',
          startSchedule: '',
          index: 0,
        },
      ],
    }));
  }, []);

  useEffect(() => {
    if (location?.state?.clientData && location?.state?.clientPolicies) {
      setData(old => ({ ...old, client: location?.state?.clientData }));
      handleGetClientOperations(location?.state?.clientPolicies);
    } else {
      snackbar.show(<Text>Acesso inválido!</Text>, { type: 'error' });
      history.push('/cargas');
    }
  }, [location]);

  useEffect(() => {
    if (data?.operation?.id && selectedPolicy?.id) {
      handleGetProducts(data?.operation?.id, selectedPolicy?.id);
    }
  }, [data?.operation?.id, selectedPolicy]);

  function handleExtractProductVehiclesOptions(product) {
    const types = [];
    const bodies = [];
    const implement = [];
    product.vehicles.forEach(vehicleGroup => {
      vehicleGroup.vehicle_types.forEach(vehicle => {
        if (vehicle.type === 'vehicle') {
          types.push({
            ...vehicle,
            groupId: vehicleGroup.id,
          });
        }
        if (vehicle.type === 'body') {
          bodies.push({
            ...vehicle,
            groupId: vehicleGroup.id,
          });
        }
        if (vehicle.type === 'implement') {
          implement.push({
            ...vehicle,
            groupId: vehicleGroup.id,
          });
        }
      });
    });
    if (types?.length === 1) {
      handleInitializeSingleOption(types, 'vehicleTypes', setData, true);
    }
    if (bodies?.length === 1) {
      handleInitializeSingleOption(bodies, 'vehicleBodies', setData, true);
    }
    if (implement?.length === 1) {
      handleInitializeSingleOption(
        implement,
        'vehicleImplements',
        setData,
        true
      );
    }
    setVehicleTypes(types);
    setBodiesTypes(bodies);
    setImplementTypes(implement);
  }

  useEffect(() => {
    if (selectedPolicy?.id && selectedPolicyProduct?.id) {
      handleExtractProductVehiclesOptions(selectedPolicyProduct);
      getLocationsOptions(selectedPolicyProduct?.id);
    }
  }, [selectedPolicy?.id, selectedPolicyProduct]);

  function validateAllDates(dataToValidate) {
    setDestinationsError({});
    const invalidsEndDate = validateDestinationsEndDate(
      dataToValidate?.destinations
    );
    const invalidsStartScheduleDate = validateDestinationsStartDate(
      dataToValidate?.destinations
    );
    const invalidEndStartDate = validateDestinationDate(
      dataToValidate?.destinations
    );
    const { start: invalidStart, end: invalidEnd } =
      validateDestinationDateWithOrigin(
        dataToValidate.origin,
        dataToValidate.destinations
      );

    const invalidsMessages = {};
    if (invalidsEndDate.length > 0) {
      invalidsEndDate.forEach(invalidIdx => {
        invalidsMessages[`${invalidIdx}.date`] =
          'Data máxima não pode ser inferior/igual ao destino anterior';
      });

      setDestinationsError(invalidsMessages);
    }
    if (invalidsStartScheduleDate.length > 0) {
      invalidsStartScheduleDate.forEach(invalidIdx => {
        invalidsMessages[`${invalidIdx}.startSchedule`] =
          'Data início não pode ser superior/igual ao destino posterior';
      });

      setDestinationsError(old => ({ ...old, ...invalidsMessages }));
    }
    if (invalidEndStartDate.length > 0) {
      invalidEndStartDate.forEach(invalidIdx => {
        invalidsMessages[`${invalidIdx}.startSchedule`] =
          'Data início entrega não pode ser menor que data máxima destino anterior';
      });
      setDestinationsError(old => ({ ...old, ...invalidsMessages }));
    }
    if (invalidStart?.length > 0) {
      invalidStart.forEach(invalidIdx => {
        invalidsMessages[`${invalidIdx}.startSchedule`] =
          'Data início entrega não pode ser menor que data da origem';
      });
      setDestinationsError(old => ({ ...old, ...invalidsMessages }));
    }
    if (invalidEnd?.length > 0) {
      invalidEnd.forEach(invalidIdx => {
        invalidsMessages[`${invalidIdx}.date`] =
          'Data máxima entrega não pode ser menor que data da origem';
      });
      setDestinationsError(old => ({ ...old, ...invalidsMessages }));
    }

    if (Object.keys(invalidsMessages)?.length > 0) {
      return false;
    }
    return true;
  }

  async function handleGoNext() {
    try {
      switch (currentStepIndex) {
        case 0:
          await LoadDataGroSchema.validate(
            {
              loadCreationNumber: data?.loadCreationNumber,
              client: data?.client,
              costCenter: data?.costCenter,
              product: data?.product,
              branchOffice: data?.branchOffice,
              dueDate: data?.dueDate,
              operation: data?.operation,
              negotiation: data?.negotiation,
              negotiator: data?.negotiator,
              country: data?.country,
              phone: data?.phone,
            },
            { abortEarly: false }
          );
          break;
        case 1:
          const check = validateAllDates({
            origin: data.origin,
            destinations: data.destinations,
          });
          await LocationsGroSchema.validate(
            {
              origin: data?.origin,
              destinations: data.destinations,
            },
            { abortEarly: false }
          );
          if (check === false) {
            snackbar.show(
              <Text type="body" weight={500}>
                Verifique dados dos destinos!
              </Text>,
              {
                type: 'error',
              }
            );
            throw new Error();
          }
          try {
            await cooperplaceApi.post(
              `policies/${selectedPolicy.id}/validator`,
              {
                type: ['route'],
                operationId: data?.operation?.id,
                load: {
                  productId: data?.product?.id,
                  loadOrigins: data?.origin,
                  loadDestinations: data?.destinations,
                },
              }
            );
          } catch (error) {
            const message =
              error?.response?.data?.error || 'Erro ao validar rota!';
            snackbar.show(
              <Text type="body" weight={500}>
                {message}
              </Text>,
              {
                type: 'error',
              }
            );
            throw new Error();
          }
          break;
        case 2:
          await VehicleDataSchema.validate(
            {
              vehicleTypes: data?.vehicleTypes,
              vehicleBodies: data?.vehicleBodies,
              vehicleImplements: data?.vehicleImplements,
              disposition: data?.disposition,
              pallets: data?.pallets,
              weight: data?.weight,
              cargoValue: data?.cargoValue,
              managerTrackerOptions,
              managerTracker: data?.manager_tracker,
            },
            {
              abortEarly: false,
            }
          );
          break;
        default:
          break;
      }
      setErrorsGro({});
      setErrors({});
      return next();
    } catch (err) {
      const errorList = {};
      if (err.inner) {
        err.inner.forEach(error => {
          errorList[error.path] = error.message;
        });
        if (currentStepIndex === 3) {
          setErrors({ ...errorList });
        } else setErrorsGro({ ...errorList });
        return false;
      }
    }
  }

  async function validateFreightValues() {
    try {
      await FreightValuesSchema.validate(
        {
          taker_value: data?.taker_value,
          load_value_type: data?.load_value_type,
          negotiation_type: data?.negotiation_type,
        },
        {
          abortEarly: false,
        }
      );
      return true;
    } catch (err) {
      snackbar.show(
        <Text type="body" weight={500}>
          Verifique campos obrigatórios!
        </Text>,
        {
          type: 'error',
        }
      );
      const errorList = {};
      if (err.inner) {
        err.inner.forEach(error => {
          errorList[error.path] = error.message;
        });
        setErrors({ ...errorList });
        return false;
      }
    }
  }
  async function handleAttemptToSave() {
    try {
      setLoadingGro(true);
      const isValid = await validateFreightValues();
      if (!isValid) {
        setLoadingGro(false);
        return;
      }
      if (
        !(await checkClientCreditLimit(
          data?.client?.id,
          +data?.total_taker_value,
          setErrors
        ))
      ) {
        snackbar.show(
          <Text>
            Cliente sem limite de crédito! Não é possível cadastrar carga para
            tomadores sem limite de crédito!
          </Text>,
          { type: 'error' }
        );
        setLoadingGro(false);
        return;
      }

      await handleCreate(
        {
          ...data,
          dailyCotation: dailyCotation[data?.currency?.value]?.value,
          policyId: selectedPolicy?.id,
          policyRangeId: selectedRange?.id,
          policyOperationId: data?.operation?.id,
          policyVehicleId: data?.vehicleTypes[0]?.groupId,
          extraFields,
          inputsExtraFields,
          transferExtraFields,
          discountsExtraFields,
        },
        setLoadingGro,
        setShowModal,
        setRegisteredLoad
      );
    } catch (error) {
      const message =
        error?.response?.data?.message ?? 'Erro ao cadastrar carga!';
      snackbar.show(<Text>{message}</Text>, { type: 'error' });
      setLoadingGro(false);
    }
  }

  return (
    <LoadGroProvider
      value={{
        data,
        setData,
        selectedPolicy,
        setSelectedPolicy,
        operations,
        handleGetSelectedPolicy,
        disableProduct,
        products,
        setProducts,
        errorsGro,
        setErrorsGro,
        countryOptions,
        destinationsError,
        selectedPolicyProduct,
        setSelectedPolicyProduct,
        vehicleTypes,
        bodiesTypes,
        implementTypes,
        loadDispositionOptions,
        dailyCotation,
        handleChangeCargoValue,
        handleSelectProduct,
        handleSearchRange,
        selectedRange,
        loadingRange,
        managerTrackerOptions,
      }}
    >
      <Card>
        {step}
        <div
          style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            type="button"
            onClick={back}
            variant="secondary"
            disabled={isFirstStep || loadingGro}
            id="button-gro-passo-anterior"
          >
            <Text type="regular">Anterior</Text>
          </Button>

          <StepsContainer>
            <Text color="dark">
              Etapa {currentStepIndex + 1}/{steps?.length}{' '}
            </Text>
            <ProgressBarContainer>
              {Array.from({ length: steps?.length }).map((_, index) => (
                <StepContainer key={index}>
                  <Step completed={index <= currentStepIndex} />
                  {index !== steps?.length - 1 ? (
                    <Line completed={index < currentStepIndex} />
                  ) : null}
                </StepContainer>
              ))}
            </ProgressBarContainer>
          </StepsContainer>

          {isLastStep ? (
            <Button
              type="button"
              onClick={() => {
                handleAttemptToSave();
              }}
              variant="success"
              loading={loadingGro}
              id="button-gro-salvar"
            >
              <Text>Salvar</Text>
            </Button>
          ) : (
            <Button
              onClick={() => handleGoNext()}
              id="button-gro-passo-posterior"
            >
              <Text color="white" type="regular">
                Próximo
              </Text>
            </Button>
          )}
        </div>
      </Card>
    </LoadGroProvider>
  );
}
