import React from 'react';
import Tab from 'v3/components/Tab';
import Tabs from 'v3/components/Tabs';
import { Text } from 'v3/components';
import { Ranges } from './Ranges';
import { usePolicyRegister } from '../../Register/context';

export function Vehicles({ vehicles, policyId, refetchPolicy }) {
  const { policy } = usePolicyRegister();
  const currencySymbol = policy?.currency?.value === 'BRL' ? 'R$' : '$';

  return (
    <Tabs
      defaultActiveKey={0}
      style={{
        display: 'flex',
        flexWrap: 'nowrap',
        overflowX: 'auto',
        overflowY: 'hidden',
        fontSize: '0.75rem',
        WebkitOverflowScrolling: 'touch',
      }}
      linkActiveColor="dark"
      linkActiveBackgroundColor="grayish_blue"
      linkWeight="500"
      linkPadding="0.5rem 1rem"
      linkBorder=" #dee2e6 #dee2e6 #fff"
    >
      {vehicles?.map((vh, vhIndex) => {
        let label = vh?.vehicles?.map(item => item?.name)?.join('-');
        return (
          <Tab
            title={label}
            eventKey={vhIndex}
            key={label}
            style={{ padding: '1rem 0.5rem' }}
          >
            <Text as="p" weight={500} color={'dark'} type="regular">
              Faixas
            </Text>
            <Ranges
              ranges={vh?.ranges}
              policyId={policyId}
              refetchPolicy={refetchPolicy}
              currencySymbol={currencySymbol}
            />
          </Tab>
        );
      })}
    </Tabs>
  );
}
