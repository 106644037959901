import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useSnackbar } from 'v3/components/Snackbar';
import Text from 'v3/components/Text';

import api from 'services/api';

import { useCurrentUser } from 'hooks';
import Details from '../Details';
import Register from '../Register';

import { RouteProvider } from './context';
import { chargingMethods } from '../../../utils/chargingMethods';

export default function RouteShared() {
  const [route, setRoute] = useState({
    product_type: useCurrentUser()?.company.product_type,
  });
  const [prevRoute, setPrevRoute] = useState(route);
  const [isFetching, setFetching] = useState(false);
  const [errors, setErrors] = useState({});
  const [minimumFreightTableItem, setMinimumFreightTableItem] = useState({});
  const snackbar = useSnackbar();

  const params = useParams();

  function showSnackbar(text, options) {
    snackbar.show(<Text>{text}</Text>, options);
  }

  useEffect(() => {
    async function fetchRoute() {
      try {
        setFetching(true);
        const response = await api.get(`v3/route/${params.id}`);
        setRoute({
          ...response.data,
          origin: response.data.origin,
          product: {
            name: `${response.data.product?.name} - ${response.data.product?.productType?.name}`,
            value: response.data.product?.id,
          },
          charge_type: chargingMethods[response.data?.charge_type - 1],
          parameters: JSON.parse(response.data.parameters),
        });
        setPrevRoute(response.data);
      } catch (ex) {
        showSnackbar('Não foi possível carregar essa rota', {
          type: 'error',
        });
      } finally {
        setFetching(false);
      }
    }

    if (params.id) {
      fetchRoute();
    }
  }, [params.id]);

  function setData(data) {
    setRoute(prev => ({ ...prev, ...data }));
  }

  function updatePrevRoute() {
    setPrevRoute(route);
  }

  function handleCancel() {
    setRoute(prevRoute);
  }

  return (
    <RouteProvider
      value={{
        data: route,
        setData,
        isFetching,
        errors,
        setErrors,
        showSnackbar,
        handleCancel,
        updatePrevRoute,
        prevRoute,
        minimumFreightTableItem,
        setMinimumFreightTableItem,
      }}
    >
      {params.id ? <Details /> : <Register />}
    </RouteProvider>
  );
}
