import React, { useEffect, useMemo } from 'react';
import { Col } from 'react-bootstrap';
import { useModal } from 'hooks';
import { makeConversion } from 'v3/utils/formatter';
import { Card, Text } from 'v3/components';
import { LoadContext } from '../controller';
import { inputsLoadConditionsToShow } from './utils';
import ExtraFieldsModal from '../Modals/ExtraFields';
import {
  CardHeaderComponent,
  ExtraFieldsRow,
  ExtraItemRow,
  FreightItemRow,
  HeaderTotalSummary,
  LabelsRow,
  SummaryRow,
} from './components';
import { ExtraFieldsContainer } from './styles';

export function InputsCard({ isBRLCurrency, dollarToUse }) {
  const [, useLoad] = LoadContext;
  const load = useLoad();
  const extraFieldsModal = useModal();
  const shouldUseToll =
    inputsLoadConditionsToShow?.includes(load?.data?.action_toll?.code) &&
    load?.data?.toll_value > 0;
  const shouldUseDischarge =
    inputsLoadConditionsToShow?.includes(load?.data?.action_discharge?.code) &&
    load?.data?.discharge_value > 0;
  const shouldUseGris =
    inputsLoadConditionsToShow?.includes(
      load?.data?.action_griss_advalorem?.code
    ) && load?.data?.gris > 0;
  const shouldUseAd =
    inputsLoadConditionsToShow?.includes(
      load?.data?.action_griss_advalorem?.code
    ) && load?.data?.ad_valorem > 0;
  const shouldUseCharge =
    inputsLoadConditionsToShow?.includes(load?.data?.action_charge?.code) &&
    load?.data?.charge_value > 0;
  const shouldUseFerry =
    inputsLoadConditionsToShow?.includes(load?.data?.action_ferry?.code) &&
    load?.data?.ferry > 0;
  const shouldUseICMS =
    inputsLoadConditionsToShow?.includes(load?.data?.action_icms?.code) &&
    load?.data?.ICMS > 0;
  const totalComputed = useMemo(() => {
    let total = 0;
    if (load?.inputsExtraFields?.length) {
      total = load?.inputsExtraFields.reduce((acc, curr) => {
        return acc + Number(curr?.value);
      }, 0);
    }

    if (shouldUseToll) {
      total += Number(load?.data?.toll_value);
    }
    if (shouldUseDischarge) {
      total += Number(load?.data?.discharge_value);
    }
    if (shouldUseGris) {
      total += Number(load?.data?.gris);
    }
    if (shouldUseAd) {
      total += Number(load?.data?.ad_valorem);
    }
    if (shouldUseCharge) {
      total += Number(load?.data?.charge_value);
    }
    if (shouldUseFerry) {
      total += Number(load?.data?.ferry);
    }
    if (shouldUseICMS) {
      total += Number(load?.data?.ICMS);
    }
    if (load?.data?.coopercarga_insurance) {
      total += Number(load?.data?.coopercarga_insurance);
    }

    load.setData(old => ({
      ...old,
      total_inputs: total,
    }));

    return total;
  }, [
    load?.data?.toll_value,
    load?.data?.discharge_value,
    load?.data?.charge_value,
    load?.data?.gris,
    load?.data?.ad_valorem,
    load?.data?.ferry,
    load?.data?.ICMS,
    load?.data?.coopercarga_insurance,
    load?.inputsExtraFields,
    shouldUseToll,
    shouldUseDischarge,
    shouldUseGris,
    shouldUseCharge,
    shouldUseFerry,
    shouldUseICMS,
    shouldUseAd,
  ]);
  useEffect(() => {
    load.setData(old => ({
      ...old,
      coopercarga_insurance: 0,
    }));
  }, []);
  function convertValue(rawValue) {
    const operation = isBRLCurrency ? '/' : '*';
    const processedValue = +makeConversion(rawValue, operation, dollarToUse);
    return operation === '/'
      ? processedValue.toFixed(4)
      : processedValue.toFixed(2);
  }
  const inputsTag = useMemo(() => {
    let tags = '';
    if (Number(load?.data?.coopercarga_insurance) > 0) {
      tags += 'Seguro Coopercarga,';
    }
    if (shouldUseToll) {
      tags += 'Pedágio,';
    }
    if (shouldUseDischarge) {
      tags += 'Descarga,';
    }
    if (shouldUseCharge) {
      tags += 'Taxa de Carregamento,';
    }
    if (shouldUseGris) {
      tags += 'Gris,';
    }
    if (shouldUseAd) {
      tags += 'Ad Valorem,';
    }
    if (shouldUseFerry) {
      tags += 'Balsa,';
    }
    if (shouldUseICMS) {
      tags += 'ICMS,';
    }
    if (load?.inputsExtraFields?.length > 0) {
      tags += 'Valores extras,';
    }
    return tags;
  }, [
    load?.data?.coopercarga_insurance,
    load?.inputsExtraFields,
    shouldUseToll,
    shouldUseDischarge,
    shouldUseGris,
    shouldUseCharge,
    shouldUseFerry,
    shouldUseICMS,
    shouldUseAd,
  ]);
  return (
    <Col xs={12}>
      <ExtraFieldsModal
        isOpen={extraFieldsModal.isOpen}
        onClose={extraFieldsModal.close}
        setExtraFields={load.setInputsExtraFields}
        errors={load.errors}
        extraFields={load?.inputsExtraFields}
        isBRLCurrency={isBRLCurrency}
        dollarToUse={dollarToUse}
        loadCurrency={load?.data?.currency?.value === 'BRL' ? 'R$' : '$'}
      />
      <Card containerCardBoxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)">
        <CardHeaderComponent
          label="Descontos e Insumos pré taxa"
          isOpen={load?.inputsCardToggle}
          setIsOpen={() => load.setInputsCardToggle(!load?.inputsCardToggle)}
          tooltipContent="Todo valor que precisa descontar antes de tirar a taxa (margem) é alocado neste campo, assim, é chegado no percentual correto para desconto de taxa administrativa (Margem)"
          totalsSection={
            <HeaderTotalSummary
              label="Valor Insumos"
              totalInReal={
                isBRLCurrency ? totalComputed : convertValue(totalComputed)
              }
              totalInDollar={
                !isBRLCurrency ? totalComputed : convertValue(totalComputed)
              }
            />
          }
          hasTagsRow
          tagsContent={inputsTag}
        />
        {load?.inputsCardToggle && (
          <>
            <LabelsRow />
            <FreightItemRow
              label="Seguro Coopercarga"
              percentageValueReference={load?.data?.cargoValue}
              percentageValue={
                load?.data?.coopercarga_insurance_percentage || '0'
              }
              onChangePercentageValue={(_event, value) => {
                if (load?.data?.cargoValue) {
                  const computedValue = (
                    (Number(value) / 100) *
                    load?.data?.cargoValue
                  ).toFixed(2);
                  load.setData(old => ({
                    ...old,
                    coopercarga_insurance_percentage: value,
                    coopercarga_insurance: computedValue,
                  }));
                }
              }}
              value={load?.data?.coopercarga_insurance}
              onChangeValue={(_event, value) => {
                if (load?.data?.cargoValue) {
                  const computedValue = (
                    (Number(value) / load?.data?.cargoValue) *
                    100
                  ).toFixed(2);
                  load.setData(old => ({
                    ...old,
                    coopercarga_insurance: value,
                    coopercarga_insurance_percentage: computedValue,
                  }));
                }
              }}
              isBRLCurrency={isBRLCurrency}
              dollarToUse={dollarToUse}
              hasTooltip
              tooltipContent="Valor aproximado, pode variar de acordo com o valor do produto na Nota Fiscal"
              showCalculator
              id="desconto-seguro-coopercarga"
            />
            {shouldUseToll && (
              <FreightItemRow
                isBRLCurrency={isBRLCurrency}
                dollarToUse={dollarToUse}
                label="Pedágio"
                value={load?.data?.toll_value}
                shouldDisableField
                id="desconto-pedagio"
              />
            )}
            {shouldUseDischarge && (
              <FreightItemRow
                isBRLCurrency={isBRLCurrency}
                dollarToUse={dollarToUse}
                label="Descarga"
                value={load?.data?.discharge_value}
                shouldDisableField
                id="desconto-descarga"
              />
            )}
            {shouldUseGris && (
              <FreightItemRow
                isBRLCurrency={isBRLCurrency}
                dollarToUse={dollarToUse}
                percentageValueReference={load?.data?.cargoValue}
                label="Griss"
                value={load?.data?.gris}
                percentageValue={load?.data?.gris_percentage || '0'}
                hasTooltip
                tooltipContent="Valor aproximado, pode variar de acordo com o valor do produto na Nota Fiscal"
                shouldDisableField
                id="desconto-gris"
              />
            )}
            {shouldUseAd && (
              <FreightItemRow
                isBRLCurrency={isBRLCurrency}
                dollarToUse={dollarToUse}
                label="AD Valorem"
                percentageValueReference={load?.data?.cargoValue}
                value={load?.data?.ad_valorem}
                percentageValue={load?.data?.ad_valorem_percentage || '0'}
                shouldDisableField
                id="desconto-ad-valorem"
              />
            )}
            {shouldUseCharge && (
              <FreightItemRow
                isBRLCurrency={isBRLCurrency}
                dollarToUse={dollarToUse}
                label="Taxa de carregamento"
                value={load?.data?.charge_value}
                shouldDisableField
                id="desconto-taxa-carregamento"
              />
            )}
            {shouldUseFerry && (
              <FreightItemRow
                isBRLCurrency={isBRLCurrency}
                dollarToUse={dollarToUse}
                label="Balsa"
                value={load?.data?.ferry}
                shouldDisableField
                id="desconto-balsa"
              />
            )}
            {shouldUseICMS && (
              <FreightItemRow
                isBRLCurrency={isBRLCurrency}
                dollarToUse={dollarToUse}
                percentageValueReference={load?.data?.taker_value}
                label="ICMS"
                value={load?.data?.ICMS}
                percentageValue={load?.data?.icms_percentage || '0'}
                shouldDisableField
                id="desconto-icms"
              />
            )}
            {load?.inputsExtraFields?.length > 0 ? (
              <Text type="regular" weight={500}>
                Valores extras
              </Text>
            ) : null}
            <ExtraFieldsContainer>
              {load?.inputsExtraFields?.map((item, index) => {
                return (
                  <ExtraItemRow
                    key={`${index}_${new Date().getTime()}`}
                    label={item?.label}
                    value={item?.value}
                    dollarToUse={dollarToUse}
                    isBRLCurrency={isBRLCurrency}
                    onSetExtraFields={() =>
                      load.setInputsExtraFields(
                        load?.inputsExtraFields?.filter(
                          (_, idx) => idx !== index
                        )
                      )
                    }
                    showToDriver={item?.show_to_driver}
                    id={`valor-extra-descontos-${index}`}
                  />
                );
              })}
            </ExtraFieldsContainer>
            <SummaryRow
              label="Valor Insumos"
              totalInBRL={
                isBRLCurrency ? totalComputed : convertValue(totalComputed)
              }
              totalInUSD={
                !isBRLCurrency ? totalComputed : convertValue(totalComputed)
              }
              id="valor-insumo"
            />
            <ExtraFieldsRow
              id="button-abrir-valores-extras-valor-insumos"
              openModal={() => extraFieldsModal.open()}
            />
          </>
        )}
      </Card>
    </Col>
  );
}
