import React from 'react';
import { Button, Link, Modal, Text } from 'v3/components';

function ConflictDriverModal({
  isOpen,
  onClose,
  onConfirm,
  travels = [],
  travelData,
}) {
  return (
    <Modal
      show={isOpen}
      centered
      heading={
        <Text type="header" color="dark" weight="400">
          Motorista já alocado em alguma viagem
        </Text>
      }
      handleClose={onClose}
      body={
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
          }}
        >
          <Text type="regular" color="dark" weight="400">
            {`o motorista ${travelData?.driver?.label} está vinculado nas seguintes viagens nesse período:`}
          </Text>
          <div>
            {travels.map(travel => (
              <Link
                target="_blank"
                to={`/viagens/${travel}`}
              >{`${travel}`}</Link>
            ))}
          </div>
          <Text type="regular" color="dark" weight="400">
            Deseja continuar mesmo assim?
          </Text>
        </div>
      }
      footer={
        <div style={{ display: 'flex', gap: 8 }}>
          <Button onClick={onClose} variant="secondary">
            Cancelar
          </Button>
          <Button onClick={() => onConfirm({ passDriverValidation: true })}>
            Confirmar
          </Button>
        </div>
      }
    />
  );
}

export default ConflictDriverModal;
