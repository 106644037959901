import React from 'react';

import Fretebras from './Fretebras';
import Truckpad from './Truckpad';

export default function Integration() {
  return (
    <>
      <Fretebras />
      <hr />
      <Truckpad />
      <hr />
    </>
  );
}
