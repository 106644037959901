import React from 'react';
import { FaMinus } from 'react-icons/fa';
import { Col } from 'react-bootstrap';
import { Button, Fallback, Text } from 'v3/components';
import { SelectedContainer, SelectedQuestionsContainer } from './styles';

export function SelectedQuestions({
  selectedQuestions,
  setSelectedQuestions,
  setAvailableQuestions,
}) {
  function handleRemove(index) {
    const questionToRemove = selectedQuestions?.find((_, idx) => idx === index);

    setAvailableQuestions(old =>
      old?.map(item => {
        if (item?.id === questionToRemove?.id) {
          return {
            ...item,
            disabled: false,
          };
        }
        return item;
      })
    );
    setSelectedQuestions(old => old.filter((_, idx) => idx !== index));
  }
  return (
    <SelectedContainer>
      <header>
        <Text type="header" color="dark" weight={500}>
          Perguntas Selecionadas
        </Text>
      </header>
      <Fallback
        fall={selectedQuestions?.length === 0}
        component={
          <Col xs={12} className="d-flex justify-content-center my-5">
            <Button disabled>
              <Text>Nenhuma pergunta selecionada.</Text>
            </Button>
          </Col>
        }
      >
        <SelectedQuestionsContainer>
          {selectedQuestions?.map((item, index) => {
            return (
              <div key={item?.id}>
                <Text color="dark">{item?.question}</Text>
                <button type="button" onClick={() => handleRemove(index)}>
                  <FaMinus />
                  <Text>Remover</Text>
                </button>
              </div>
            );
          })}
        </SelectedQuestionsContainer>
      </Fallback>
    </SelectedContainer>
  );
}
