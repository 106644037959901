import { cooperplaceApi } from 'services/api';
import StringBuilder from 'utils/StringBuilder';
import { formatDateTime } from 'v3/utils/formatter';

export const validateChecklist = (questions, setQuestions) => {
  const requiredSubQuestions = questions.filter(question => {
    if (!question?.fatherQuestionId) return false;

    const findFatherQuestion = questions.find(
      fatherQuestion => fatherQuestion.id === question?.fatherQuestionId
    );

    if (
      findFatherQuestion?.questionTrigger.length &&
      findFatherQuestion?.responses?.length
    ) {
      const findResponse = findFatherQuestion.responses.find(
        response => response.id === findFatherQuestion.questionTrigger[0].id
      );
      if (findResponse?.selected) return true;
    }
    return false;
  });

  const requiredQuestions = questions.filter(
    question => question?.required && !question?.fatherQuestionId
  );

  if (requiredQuestions.length === 0 && requiredSubQuestions.length === 0)
    return true;

  const missingQuestionIds = [];
  const missingMediaQuestionIds = [];

  [...requiredQuestions, ...requiredSubQuestions].forEach(question => {
    if (
      question.type === 'logic' ||
      question.type === 'multiple' ||
      question.type === 'unique'
    ) {
      if (!question.responses.some(response => response.selected === true)) {
        missingQuestionIds.push(question.id);
      }
    }

    if (question.type === 'descriptive') {
      if (
        !question.descriptiveResponse ||
        question.descriptiveResponse?.length <= 0
      ) {
        missingQuestionIds.push(question.id);
      }
    }

    if (question.type === 'image' || question.needAttachment) {
      if (
        question?.attachment?.files.length <= 0 ||
        !question?.attachment?.questionId
      ) {
        missingQuestionIds.push(question.id);
        if (question.needAttachment) {
          missingMediaQuestionIds.push(question.id);
        }
      }
    }

    if (question.type === 'video') {
      if (
        question?.attachment?.files.length <= 0 ||
        !question?.attachment?.questionId
      ) {
        missingQuestionIds.push(question.id);
        if (question.needAttachment) {
          missingMediaQuestionIds.push(question.id);
        }
      }
    }
  });

  if (missingQuestionIds.length === 0) {
    return true;
  }

  setQuestions(prevState => {
    const newQuestions = [...prevState];

    missingQuestionIds.forEach(id => {
      const indexselectedQuestion = newQuestions.findIndex(
        question => question.id === id
      );
      newQuestions[indexselectedQuestion].error = 'Campo obrigatório!';
    });
    missingMediaQuestionIds.forEach(id => {
      const indexselectedQuestion = newQuestions.findIndex(
        question => question.id === id
      );
      newQuestions[indexselectedQuestion].mediaError = 'Imagem obrigatória';
    });
    return newQuestions;
  });

  return false;
};

function onClickPreview({
  id,
  setLightbox,
  attachmentsWithoutPDF: attachments,
}) {
  const index = id ? attachments.findIndex(a => a?.attachment?.id === id) : 0;

  setLightbox(prev => ({
    ...prev,
    index,
    attachments,
    isOpen: true,
  }));
}

export function handleClickOpenAttachment({
  attachment,
  setShowingModal,
  setLightbox,
  attachmentsWithoutPDF: attachments,
}) {
  const fullPathArray = attachment.name.split('.');

  if (fullPathArray[fullPathArray?.length - 1] === 'pdf') {
    setShowingModal(true);
  } else {
    onClickPreview({
      id: attachment?.id,
      setLightbox,
      attachmentsWithoutPDF: attachments,
    });
  }
}

export function getImagePath({
  index,
  alwaysDisplay = false,
  lightbox,
  lightboxIndexes: indexesLightbox,
}) {
  const isSameAsIndex =
    lightbox.index === indexesLightbox.next &&
    lightbox.index === indexesLightbox.previous;

  if (!alwaysDisplay && isSameAsIndex && lightbox.attachments.length === 1) {
    return null;
  }

  const processedStartPath = process.env.REACT_APP_BASE_STORAGE_URL;
  const processedPath = `${processedStartPath}${lightbox.attachments[index]?.attachment?.container}/${lightbox.attachments[index]?.attachment?.path}/${lightbox.attachments[index]?.attachment?.name}`;
  return processedPath;
}

function getDocumentMetadata(lightbox) {
  const document = lightbox.attachments[lightbox.index];
  const type = document?.description || 'Sem descrição';
  const createdAt = formatDateTime(document?.created_at);

  return { type, createdAt };
}

export function fullPath(attachment) {
  return encodeURI(
    `${process.env.REACT_APP_BASE_STORAGE_URL}${attachment?.container}/${attachment?.path}/${attachment?.name}`
  );
}

export function lightboxTitle(lightbox) {
  const builder = new StringBuilder(' ');
  const documentMetadata = getDocumentMetadata(lightbox);
  builder
    .append(`${lightbox.index + 1}/${lightbox.attachments?.length}`)
    .append('|')
    .append(documentMetadata.type)
    .append(`(${documentMetadata.createdAt})`);

  return builder.build();
}

export function lightboxIndexes(lightbox) {
  const next = (lightbox.index + 1) % lightbox.attachments?.length;
  const previous =
    (lightbox.index + lightbox.attachments.length - 1) %
    lightbox.attachments.length;
  return { next, previous };
}

export function attachmentsWithoutPDF(attachments) {
  return attachments.filter(item => {
    const filterPDF = item?.attachment?.name.split('.');
    if (filterPDF[filterPDF?.length - 1] === 'pdf') return null;
    return item;
  });
}

export const handleTextArea = (e, setQuestions, question) => {
  const {
    target: { value },
  } = e;

  setQuestions(prevState => {
    const newQuestions = [...prevState];
    const index = newQuestions.findIndex(({ id }) => id === question?.id);
    newQuestions[index].descriptiveResponse = value;
    newQuestions[index].error = null;
    return newQuestions;
  });
};

export const handleOptions = ({
  isChecked,
  responseIndex,
  setQuestions,
  question,
}) => {
  setQuestions(prevState => {
    const newQuestions = [...prevState];
    const index = newQuestions.findIndex(({ id }) => id === question?.id);
    if (question.type === 'unique' || question.type === 'logic') {
      question.responses.forEach((_response, i) => {
        newQuestions[index].responses[i].selected = false;
      });
    }
    newQuestions[index].responses[responseIndex].selected = isChecked;
    newQuestions[index].error = null;
    return newQuestions;
  });
};

export const sendMedias = async medias => {
  const formData = new FormData();
  medias.map((media, index) => {
    media.attachment.files.forEach(file => {
      formData.append(`file${index}@questionId=${media.id}`, file);
    });
    return formData;
  });

  const { data } = await cooperplaceApi.post(
    'travel-checklist-response/media',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );

  return data;
};

export const answerChecklist = async (answers, checklistId) => {
  const answerPayload = {
    travelChecklistId: checklistId,
    questions: answers.map(answer => ({
      id: answer.id,
      question: answer.question,
      needAttachment: answer.needAttachment,
      sentAttachment: answer.sentAttachment,
      createOccurrence: answer.createOccurrence,
      questionDescriptiveResponse: answer.descriptiveResponse,
      required: answer.required,
      checklistTravelId: checklistId,
      occurrenceGroupId: answer.occurrenceGroupId,
      type: answer.originalType,
      responses: [...answer.responses].filter(response => response.selected),
    })),
  };

  const { data } = await cooperplaceApi.post(
    'travel-checklist-response',
    answerPayload
  );

  return data;
};

export const normalizeQuestions = question => ({
  id: question.id,
  question: question.question,
  questionTrigger: question?.questionSubResponses?.map(subResponse => ({
    id: subResponse?.questionValue.id,
    response: subResponse?.questionValue.description,
  })),
  required: !!question.required,
  type: question.type.slug,
  fatherQuestionId: question.fatherQuestionId,
  hasSubQuestion: question.hasSubQuestion,
  needAttachment: question.needAttachment,
  attachments: question?.attachments,
  isSubQuestion: !!question.fatherQuestionId,
  error: null,
  mediaError: null,
  createOccurrence: question.createOccurrence,
  occurrenceGroupId: question.occurrenceGroupId,
  originalType: question.type,
  responses: question.responses.map(response => ({
    id: response.id,
    order: response.order,
    selected: !!response.selected,
    description: response.description,
  })),
  descriptiveResponse: question.questionDescriptiveResponse,
  order: question.order,
});
