import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback,
} from 'react';
import { useSnackbar } from 'v3/components/Snackbar';
import CurrencyInput from 'v3/components/Input/Currency';
import Datepicker from 'v3/components/DatePicker';
import TextArea from 'v3/components/TextArea';
import { Row, Col } from 'react-bootstrap';
import { FaInfoCircle, FaPhoneAlt } from 'react-icons/fa';
import { Text, Card, Tooltip, Select, Input } from 'v3/components';

import {
  fetchProductAndCategorie,
  fetchBranchOffices,
  fetchCostCenter,
  fetchUserCostCenters,
  fetchAllNegotiationsByPersonAndCostCenter,
  importFromBenner,
} from 'utils/fetches';
import { validatePermission } from 'actions/index';
import api, { cooperplaceApi } from 'services/api';
import {
  currencyOptions,
  currencyWithSymbol,
  formatCurrency,
  makeConversion,
  conversionParams,
} from 'v3/utils/formatter';
import { UserContext } from 'contexts/UserContext';
import CheckBox from 'components/CheckBox';
import debounce from 'lodash.debounce';
import { fetchSelectOptions, LoadContext } from '../controller';
import * as Controller from './controller';
import * as Styled from './styled';

const [, useLoad] = LoadContext;

export default function LoadData({ onClientChange, operationOptions }) {
  const load = useLoad();
  const snackbar = useSnackbar();
  const { selectedCompany } = useContext(UserContext);
  const [validatingClient, setValidatingClient] = useState(false);
  const [riskManager, setRiskManager] = useState('');
  const [country, setCountry] = useState('');
  const [tags, setTags] = useState('');
  const [crt, setCrt] = useState('');
  const [maxTemperature, setMaxTemperature] = useState();
  const [minTemperature, setMinTemperature] = useState();
  const [loadCreationNumber, setLoadCreationNumber] = useState(1);
  const [cargoValue, setCargoValue] = useState();
  const [loadSymbol, setLoadSymbol] = useState('R$');
  const [loadCurrency, setLoadCurrency] = useState(currencyOptions[0]);
  const [loadCurrencyStatusExchange, setLoadCurrencyStatusExchange] = useState({
    status: 'Compra',
    exchange: 0,
  });
  const [routeCurrency, setRouteCurrency] = useState(null);
  const [conversion, setConversion] = useState();
  const [negotiation, setNegotiation] = useState();
  const [negotiationOptions, setNegotiationOptions] = useState([]);
  const [loadingNegotiations, setLoadingNegotiations] = useState(false);
  const [loadingClient, setLoadingClient] = useState(false);
  const hasPermissionToSelectTags = useMemo(
    () => validatePermission('SELECIONAR_TAGS'),
    []
  );
  const tagRegisterPermitted = useMemo(
    () => validatePermission('CRIAR_TAGS'),
    []
  );

  async function fetchAllNegotiations(clientId) {
    try {
      setLoadingNegotiations(true);
      const response = await fetchAllNegotiationsByPersonAndCostCenter({
        personId: clientId,
        costCenterExternalId: load?.data?.costCenter?.external_id,
      });
      setNegotiationOptions(response);
    } catch (error) {
      setNegotiationOptions([]);
    } finally {
      setLoadingNegotiations(false);
    }
  }

  async function updateClientNegotiations(cpfCnpj, clientId) {
    try {
      setLoadingClient(true);
      await importFromBenner(cpfCnpj);
      if (load?.data?.costCenter) {
        await fetchAllNegotiations(clientId);
      }
    } catch (error) {
      //
    } finally {
      setLoadingClient(false);
    }
  }

  async function validateClientBlacklist(cpfCnpj) {
    try {
      const response = await api.get(`v3/blacklist-person/?value=${cpfCnpj}`);
      if (response.data.total > 0) {
        snackbar.show(
          <>
            <Text>
              Não é possivel selecionar o Tomador{' '}
              {response.data.data[0].description} - {cpfCnpj} pois ele está
              banido!{' '}
            </Text>
            <Text>Motivo: {response.data.data[0].reason}</Text>
          </>,
          {
            type: 'error',
          }
        );
        load.setData(old => ({ ...old, client: null }));
      }
      return true;
    } catch (error) {
      snackbar.show(
        <Text>
          Error ao validar o Tomador de CNPJ: {cpfCnpj} na lista de banidos!
        </Text>,
        {
          type: 'error',
        }
      );
      load.setData(old => ({ ...old, client: null }));
      return false;
    }
  }

  async function validateAndImportNegotiations(client) {
    const isClientValid = await validateClientBlacklist(client?.cgccpf);
    if (isClientValid) {
      setNegotiation({});
      setNegotiationOptions([]);
      updateClientNegotiations(client?.cgccpf, client?.id);
    }
  }

  async function handleClientChange(value) {
    try {
      setValidatingClient(true);
      load.setData(old => ({ ...old, client: value }));
      setNegotiation({});
      setNegotiationOptions([]);
      if (onClientChange) {
        onClientChange(value);
      }
    } catch (error) {
      //
    } finally {
      setValidatingClient(false);
    }
  }

  useEffect(() => {
    if (load?.data?.client?.id) {
      validateAndImportNegotiations(load?.data?.client);
    }
  }, [load?.data?.client?.id]);

  const [inputTag, setInputTag] = useState('');

  function tagTreatment(input) {
    if (input) {
      const inputFiltered = input.trimStart().replace(/[^a-zA-Z0-9 ]/g, '');
      setInputTag(inputFiltered);
    } else {
      setInputTag('');
    }
  }

  async function handleTagCreate(tagValue) {
    const newTagValue = tagValue.trim();
    if (tagRegisterPermitted) {
      if (newTagValue) {
        if (newTagValue.length >= 3) {
          try {
            const response = await api.post('tags', { name: newTagValue });
            if (response.status === 200) {
              const newTag = {
                id: response.data.id,
                name: newTagValue,
              };
              setInputTag('');
              setTags(tags ? [...tags, newTag] : [newTag]);
            }
          } catch (err) {
            //
          }
        }
      }
    } else {
      snackbar.show(<Text>Usuário sem permissão para criar tags</Text>, {
        type: 'error',
      });
    }
  }

  async function handleCostCenter() {
    const user = await fetchUserCostCenters();

    if (user.costCenters) {
      load.setData(old => ({ ...old, costCenter: user?.costCenters[0] }));
    }
  }

  const phoneNumberFormater = telNumber => {
    if (telNumber.length === 11 || telNumber.length === 10) {
      const cleaned = `${telNumber}`.replace(/\D/g, '');
      const match = cleaned.match(/^(\d{2})(\d{4}|\d{5})(\d{4})$/);
      if (match) {
        return ['(', match[1], ')', match[2], '-', match[3]].join('');
      }
    }
    return telNumber;
  };

  const handleChangeLoadCreateNumber = ({ target: { value } }) => {
    const numberValue = Number(value);
    if (numberValue <= 1 || !numberValue) {
      setLoadCreationNumber(1);
      return;
    }
    if (numberValue >= 50) {
      setLoadCreationNumber(50);
      return;
    }
    setLoadCreationNumber(numberValue);
  };

  const handleOperationChange = async selectedOperation => {
    try {
      if (selectedOperation?.data?.operation?.policy?.id) {
        const {
          data: { operations },
        } = await cooperplaceApi.get(
          `policies/${selectedOperation?.data.operation.policy.id}`
        );

        const policyData = operations.find(
          operation => operation.id === selectedOperation?.data.operation.id
        );

        if (!policyData) {
          load.setData(old => ({
            ...old,
            operationPreLoad: null,
          }));
          throw new Error('Operação não encontrada');
        }

        load.setData(old => ({
          ...old,
          operationPreLoad: selectedOperation,
          preLoadPolicy: {
            ...old.preLoadPolicy,
            ...policyData,
          },
        }));

        snackbar.show(
          <Text>
            Operação selecionada com sucesso! Por favor atualize o Produto!
          </Text>,
          {
            type: 'warning',
          }
        );
      } else {
        load.setData(old => ({
          ...old,
          operationPreLoad: null,
          preLoadPolicy: null,
        }));
      }
    } catch (error) {
      snackbar.show(
        <Text>
          {error?.message ||
            'Erro ao tentar selecionar a operação, por favor tente novamente!'}
        </Text>,
        {
          type: 'error',
        }
      );
    }
  };

  const handleChangeGROProduct = selectedProduct => {
    if (!selectedProduct) {
      load.setData(old => ({
        ...old,
        preLoadPolicy: {
          ...old.preLoadPolicy,
          selectedProduct: null,
          vehiclesOptionsId: [],
        },
      }));
      return;
    }
    const findProductReference = load?.data?.preLoadPolicy?.products.find(
      ({ products }) =>
        products.find(({ code }) => code === selectedProduct.ncm)
    );

    load.setData(old => ({
      ...old,
      preLoadPolicy: {
        ...old.preLoadPolicy,
        selectedProduct,
        vehiclesOptionsId: [
          ...new Set(
            findProductReference?.vehicles
              ?.flatMap(curr => curr.vehicle_types)
              .filter(({ type }) => type === 'vehicle')
              .map(({ id }) => id)
          ),
        ],
      },
    }));

    snackbar.show(
      <Text>
        Produto selecionado com sucesso! Por favor atualize o Veículo!
      </Text>,
      {
        type: 'warning',
      }
    );
  };

  const debouncedGROSearchRange = useCallback(
    debounce(value => {
      const rangesOptions = load?.data?.preLoadPolicy?.rangesOptions || [];
      if (!rangesOptions.length) {
        setCargoValue('0.00');
        snackbar.show(
          <Text>
            Não foi possível encontrar faixas de valores, por favor selecione um
            produto com os tipos de veículos válidos
          </Text>,
          {
            type: 'error',
          }
        );
        return;
      }

      const isValidRange = rangesOptions.find(
        ({ min, max }) => Number(value) >= min && Number(value) <= max
      );

      if (!isValidRange) {
        setCargoValue('0.00');
        snackbar.show(
          <Text>
            Valor fora da faixa permitida, por favor selecione um valor entre as
            faixas disponíveis
          </Text>,
          {
            type: 'error',
          }
        );
      }
    }, 600),
    [load?.data?.preLoadPolicy?.rangesOptions]
  );

  const debouncedUpdateLoad = useCallback(
    debounce(() => {
      load.setData(old => ({
        ...old,
        cargoValue,
        riskManager,
        country,
        tags,
        loadCreationNumber,
        load_currency: loadCurrency?.value || routeCurrency?.value,
        exchange: loadCurrencyStatusExchange?.exchange,
        negotiation,
        crt,
        maxTemperature,
        minTemperature,
      }));
    }, 600)
  );

  useEffect(() => {
    Controller.fetchTags(setTags);
    handleCostCenter();
  }, []);

  useEffect(() => {
    debouncedUpdateLoad();
  }, [
    cargoValue,
    riskManager,
    country,
    tags,
    loadCreationNumber,
    loadCurrency,
    routeCurrency,
    loadCurrencyStatusExchange?.exchange,
    negotiation,
    crt,
    maxTemperature,
    minTemperature,
  ]);

  useEffect(() => {
    if (load.data.routes) {
      setRouteCurrency(
        currencyOptions.find(item => item.value === load.data.routes?.currency)
      );
    }
    if (load.data.routes?.exchange) {
      setLoadCurrencyStatusExchange({
        ...loadCurrencyStatusExchange,
        status: load.data.routes?.currency === 'BRL' ? 'Compra' : 'Venda',
        exchange: load.data.routes?.exchange,
      });
    }
  }, [load?.data?.routes]);

  useEffect(() => {
    if (routeCurrency) {
      const aux = currencyWithSymbol.find(
        item => item.currency === routeCurrency?.value
      );
      setLoadSymbol(aux?.symbol);
    }
  }, [routeCurrency]);

  useEffect(() => {
    if (loadCurrency) {
      setLoadCurrencyStatusExchange({
        ...loadCurrencyStatusExchange,
        status: loadCurrency?.value === 'BRL' ? 'Compra' : 'Venda',
        exchange:
          loadCurrency?.value === 'BRL'
            ? load?.rateResponse?.buy
            : load?.rateResponse?.sell,
      });

      const aux = currencyWithSymbol.find(
        item => item.currency === loadCurrency?.value
      );
      setLoadSymbol(aux?.symbol);
    }
  }, [loadCurrency]);

  useEffect(() => {
    setConversion(conversionParams[loadSymbol]);
  }, [loadSymbol]);

  useEffect(() => {
    if (load?.rateResponse) {
      setLoadCurrencyStatusExchange({
        ...loadCurrencyStatusExchange,
        exchange:
          loadCurrencyStatusExchange?.status === 'Compra'
            ? load?.rateResponse.buy
            : load?.rateResponse.sell,
      });
    }
  }, [load.rateResponse]);

  useEffect(() => {
    async function fetchClientChecklist() {
      try {
        const response = await api.get(
          `client/checklist?client_id=${load?.data?.client.id}`
        );
        if (response.data.length > 0)
          load.setData(old => ({
            ...old,
            observations: `Checklist do cliente: \n${response.data
              .map(item => item.name)
              .join('\n')}`,
          }));
      } catch (error) {
        // handle exception
      }
    }
    if (load?.data?.client?.id) fetchClientChecklist();
    else {
      load.setData(old => ({ ...old, observations: '' }));
    }
  }, [load?.data?.client]);

  useEffect(() => {
    if (!!load?.data?.client && !!load?.data?.costCenter) {
      fetchAllNegotiations(load?.data?.client?.id);
    }
  }, [load?.data?.costCenter]);

  useEffect(() => {
    setCountry(load.countryList.find(element => element.abbreviation === 'br'));
  }, [load?.countryList]);

  useEffect(() => {
    if (load.data?.tags) {
      setTags(load.data?.tags);
    }
  }, [load.data?.tags]);

  return (
    <Card
      header={
        <Text color="#464E5F" type="header">
          Dados da carga
        </Text>
      }
      HeaderRightComponent={
        <div className="position-relative w-25">
          <Input
            type="number"
            hideAppearance
            value={loadCreationNumber}
            onChange={handleChangeLoadCreateNumber}
            error={load.errors.loadCreationNumber}
            containerStyle={{ marginBottom: 0 }}
            disabled={load?.isPreLoad}
            id="input-qtd-cargas"
          />
          <Tooltip
            content={
              <Text type="label">{`${
                load?.isPreLoad
                  ? 'Não é possível alterar quantidade para pré carga'
                  : 'Quantidade de cargas a serem criadas *'
              }`}</Text>
            }
          >
            <Styled.QuestionIcon />
          </Tooltip>
        </div>
      }
    >
      <Row className="form">
        <Col xs={12} className="mb-3">
          <Select.Async
            label="Cliente Tomador *"
            onSearch={search =>
              fetchSelectOptions('persons/customers', { search })
            }
            value={load?.data?.client}
            getOptionLabel={option => {
              let label = `${option?.social_name} - ${option?.cgccpf}`;

              if (option.city) {
                label += ` - ${option?.city}`;
              }

              return label;
            }}
            getOptionValue={option => option.id}
            error={load.errors[`client.id`] || load.errors.client}
            onChange={handleClientChange}
            loading={load.retrievingLoadData || validatingClient}
            isDisabled
            id="select-tomador"
          />
        </Col>
        {load?.isPreloadWithGRO && operationOptions?.length && (
          <Col xs={12}>
            <Select
              label="Operação *"
              options={operationOptions}
              getOptionLabel={option => option.label}
              getOptionValue={option => option.value}
              value={load?.data?.operationPreLoad}
              onChange={handleOperationChange}
              error={load.errors['operationPreLoad.value']}
            />
          </Col>
        )}
        <Col xs={12} className="mb-3">
          <Select.Async
            label="Centro de custo *"
            onSearch={search => fetchCostCenter({ search })}
            value={load?.data?.costCenter}
            onChange={value => {
              load.setData(old => ({ ...old, costCenter: value }));
              setNegotiation({});
              setNegotiationOptions([]);
            }}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            error={load.errors.costCenter}
            loading={load.retrievingLoadData}
            id="select-centro-custo"
          />
        </Col>
        <Col xs={12} className="mb-3">
          <Select
            label={
              <div className="d-flex align-items-center">
                <FaInfoCircle
                  title="Negociação depende do tomador e centro de custo "
                  className="mr-2"
                  size={14}
                />
                Negociação *
              </div>
            }
            options={negotiationOptions}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            value={negotiation}
            onChange={value => {
              setNegotiation(value);
            }}
            isDisabled={!load?.data?.costCenter || !load?.data?.client}
            error={load.errors['negotiation.id']}
            noOptionsMessageText="Nenhuma negociação disponível"
            loading={
              (loadingClient && load?.data?.client && load?.data?.costCenter) ||
              (loadingNegotiations &&
                load?.data?.client &&
                load?.data?.costCenter)
            }
            id="select-negociacao"
          />
        </Col>
        <Col xs={12} className="mb-3">
          {load?.data?.preLoadPolicy?.productsOptions?.length > 0 ? (
            <Select
              label="Produto *"
              options={load?.data?.preLoadPolicy?.productsOptions}
              getOptionLabel={option =>
                `${`${option.name} ${
                  option?.productType?.name
                    ? `- ${option?.productType?.name}`
                    : ''
                } ${option.ncm ? `- ${option.ncm}` : ''}`}`
              }
              getOptionValue={option =>
                `${`${option?.id}-${option?.productType?.id}`}`
              }
              value={load?.data?.preLoadPolicy?.selectedProduct}
              onChange={handleChangeGROProduct}
            />
          ) : (
            <Select.Async
              tooltip={
                load?.isPreLoadWithGROProductDisabled &&
                'Selecione uma operação válida para habilitar a seleção de produtos'
              }
              label="Produto *"
              onSearch={search => fetchProductAndCategorie(search)}
              value={load?.data?.product}
              getOptionLabel={option =>
                `${`${option.name} ${
                  option.product_type_name
                    ? `- ${option.product_type_name}`
                    : ''
                } ${option.ncm ? `- ${option.ncm}` : ''}`}`
              }
              getOptionValue={option =>
                `${`${option?.id}-${option?.product_type_id}`}`
              }
              onChange={event =>
                load.setData(old => ({ ...old, product: event }))
              }
              error={load.errors.product}
              loading={load.retrievingLoadData}
              id="select-produto"
              isDisabled={load?.isPreLoadWithGROProductDisabled}
            />
          )}
        </Col>
        <Col xs={6} className="mb-3">
          <Input
            label="Temperatura mínima da carga"
            type="number"
            value={minTemperature}
            onChange={({ target }) => {
              return target.value.length > 6
                ? snackbar.show(
                    <Text>Limite de dígitos para temperatura</Text>,
                    {
                      type: 'error',
                    }
                  )
                : setMinTemperature(target.value ? Number(target.value) : null);
            }}
            error={load.errors.minTemperature}
            id="input-temperatura-carga-min"
          />
        </Col>
        <Col xs={6} className="mb-3">
          <Input
            label="Temperatura máxima da carga"
            type="number"
            value={maxTemperature}
            onChange={({ target }) => {
              return target.value.length > 6
                ? snackbar.show(
                    <Text>Limite de dígitos para temperatura</Text>,
                    {
                      type: 'error',
                    }
                  )
                : setMaxTemperature(target.value ? Number(target.value) : null);
            }}
            error={load.errors.maxTemperature}
            id="input-temperatura-carga-max"
          />
        </Col>
        <Col xs={6} className="mb-3">
          <Input label="Empresa" disabled value={selectedCompany?.name} />
        </Col>
        <Col xs={6} className="mb-3">
          <Select.Async
            label="Filial *"
            onSearch={search => fetchBranchOffices(search)}
            value={load?.data?.branchOffice}
            getOptionLabel={option => `${`${option.id} - ${option.name}`}`}
            getOptionValue={option => option?.id}
            onChange={event =>
              load.setData(old => ({ ...old, branchOffice: event }))
            }
            error={load.errors.branchOffice}
            loading={load.retrievingLoadData}
            id="select-filial"
          />
        </Col>
        <Col xs={6} className="mb-3">
          <Select
            label="Moeda valor da carga"
            options={currencyOptions}
            getOptionLabel={option => option.label}
            getOptionValue={option => option.value}
            value={loadCurrency || routeCurrency || currencyOptions[0]}
            onChange={value => {
              setLoadCurrency(value);
            }}
            id="select-moeda-carga"
          />
        </Col>
        <Col md={6}>
          {loadCurrencyStatusExchange?.exchange ? (
            <>
              <Text
                type="label"
                color="dark"
                weight="500"
                style={{ textAlign: 'center' }}
              >
                {`Cotação ${
                  loadCurrencyStatusExchange?.status
                    ? `(Valor de ${loadCurrencyStatusExchange?.status})`
                    : ''
                }`}
              </Text>

              <Text
                type="regular"
                color="gray"
                as="p"
                fontSize="16px"
                style={{ marginTop: '1rem' }}
                id="label-cotacao-valor"
              >
                {formatCurrency(
                  loadCurrencyStatusExchange?.exchange,
                  'USD',
                  'R$'
                )}
              </Text>
            </>
          ) : null}
        </Col>
        <Col xs={6} className="mb-3">
          <CurrencyInput
            tooltip={
              load?.isPreLoadWithGROCargoValueDisabled &&
              'É necessário selecionar o tipo de veículo e produto válido na apólice para liberar esse campo'
            }
            label={`Valor da carga (${loadSymbol || 'R$'}) *`}
            value={cargoValue}
            onChange={(_event, value) => {
              setCargoValue(value);
              if (load?.isPreloadWithGRO) {
                debouncedGROSearchRange(value);
              }
            }}
            disabled={load?.isPreLoadWithGROCargoValueDisabled}
            error={load.errors.cargoValue}
            id="input-valor-carga"
          />
          {cargoValue && conversion && (
            <Text
              type="label"
              color="black"
              weight="bold"
              className="mt-2"
              id="label-valor-carga-convertido"
            >
              <>
                Convertido:{' '}
                {formatCurrency(
                  makeConversion(
                    cargoValue,
                    conversion.operation,
                    loadCurrencyStatusExchange?.exchange
                  ),
                  conversion.currencyName,
                  conversion.symbol
                )}
              </>
            </Text>
          )}
        </Col>

        <Col md={6} xs={12} className="mb-3">
          <Datepicker
            value={load.dueDate}
            label="Data de vencimento *"
            onChange={event => {
              load.setData(old => ({
                ...old,
                dueDate: event.target.value,
              }));
              load.setDueDate(event.target.value);
            }}
            withTime
            error={load.errors.dueDate}
            id="input-data-vencimento"

          />
        </Col>
        <Col xs={12} className="mb-3">
          <Select.Async
            multiple
            horizontal
            label="Negociador *"
            onSearch={search =>
              fetchSelectOptions('user/name', {
                negotiator: search,
              })
            }
            modalHeading="Adicione um negociador"
            modalBodyTitle="Negociador:"
            value={load?.data?.negotiator}
            getOptionLabel={option => option.username}
            getOptionValue={option => option.id}
            onChange={value => {
              load.setData(old => ({
                ...old,
                negotiator: value,
                phone: value?.[0]?.phonenumber
                  ? phoneNumberFormater(value?.[0]?.phonenumber)
                  : '',
              }));
            }}
            error={load.errors.negotiator}
            id="select-negociador"
          />
        </Col>
        <Col md={6} xs={12} className="mb-3">
          <Select
            label="País *"
            value={country}
            options={load?.countryList}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            onChange={event => setCountry(event)}
            error={load.errors.country}
            id="select-pais"
          />
        </Col>
        <Col md={6} xs={12} className="mb-3">
          <Input
            label="Whatsapp *"
            icon={<FaPhoneAlt color="#494950" />}
            masked={country ? country.abbreviation === 'br' : false}
            mask={[
              '(',
              /[0-9]/,
              /\d/,
              ')',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              '-',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
            ]}
            value={load?.data?.phone}
            onChange={event =>
              load.setData(old => ({ ...old, phone: event.target.value }))
            }
            error={load.errors.phone}
            id="input-whatsapp"
          />
        </Col>

        <Col xs={12} className="mb-3">
          <TextArea
            label="Observações"
            resizable={false}
            value={load?.data?.observations}
            onChange={event =>
              load.setData(old => ({
                ...old,
                observations: event.target.value,
              }))
            }
            loading={load.retrievingLoadData}
            id="textarea-observacoes"
          />
        </Col>
        <Col xs={12} className="mb-3">
          <Select.Async
            isDisabled={!hasPermissionToSelectTags}
            label="Tags"
            onSearch={search =>
              fetchSelectOptions('tags', {
                search,
                not_paginated: true,
              })
            }
            multiple
            value={tags}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            onChange={event => setTags(event)}
            onInputChange={e => tagTreatment(e)}
            inputValue={inputTag}
            onCreateOption={handleTagCreate}
            creatable
            id="select-tags"
          />
        </Col>
        <Col xs={12} md={6} className="mb-3">
          <Input
            label="Invoice"
            value={load?.data?.externalInvoiceNumber}
            type="text"
            onChange={({ target }) => {
              load.setData(old => ({
                ...old,
                externalInvoiceNumber: target.value,
              }));
            }}
            loading={load.retrievingLoadData}
            id="input-invoice"
          />
        </Col>

        <Col xs={12} className="mb-3">
          <Input
            label="CRT"
            value={crt}
            onChange={event => setCrt(event.target.value)}
            error={load.errors.crt}
            id="input-crt"
          />
        </Col>
        <Col md={6} xs={12} className="mb-3">
          <Input
            label="Número do pedido do cliente"
            value={load?.data?.clientOrderNumber}
            onChange={event =>
              load.setData(old => ({
                ...old,
                clientOrderNumber: event.target.value,
              }))
            }
            error={load.errors.clientOrderNumber}
            loading={load.retrievingLoadData}
            id="input-numero-pedido-cliente"
          />
        </Col>
        <Col md={6} xs={12} className="mb-3">
          <Text as="span" color="dark" type="medium" weight={500}>
            Buscar motoristas no APP
          </Text>
          <CheckBox
            label="Sim"
            labelStyle={{
              textTransform: 'capitalize',
              color: '#464E5F',
            }}
            value={load?.searchDriver}
            checked={load?.searchDriver}
            onChange={(e, checked) => {
              if (checked === false) {
                load.setData(old => ({
                  ...old,
                  searchDriverRadius: '0',
                }));
                load.setErrors(old => ({ ...old, searchDriverRadius: null }));
              } else {
                load.setData(old => ({
                  ...old,
                  searchDriverRadius: 500,
                }));
              }
              load.setSearchDriver(!load.searchDriver);
              load.setData(old => ({
                ...old,
                searchDriver: !old.searchDriver,
              }));
            }}
            id="checkbox-busca-motorista"
          />
        </Col>
        <Col md={6} xs={12} className="mb-3">
          <Input
            label={
              load?.data?.searchDriver
                ? 'Raio de busca de motorista (Km)*'
                : 'Raio de busca de motorista (Km)'
            }
            tooltip="Máximo de 500km"
            value={load?.data?.searchDriverRadius}
            onKeyDown={evt =>
              ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
            }
            onChange={event => {
              if (
                Number(event?.target?.value) > 500 ||
                Number(event?.target?.value) < 0 ||
                event?.target?.value.length > 6
              ) {
                return;
              }
              load.setData(old => ({
                ...old,
                searchDriverRadius: event.target.value,
              }));
            }}
            error={load.errors.searchDriverRadius}
            type="number"
            min="1"
            max="500"
            disabled={
              load?.data?.searchDriver === false ||
              load?.data?.searchDriver === null
            }
            id="input-raio-busca-motorista"
          />
        </Col>
      </Row>
    </Card>
  );
}
