export const chargingMethods = [
  { label: 'Por quilômetro rodado', value: 1 },
  { label: 'Por peso bruto transportado', value: 2 },
  { label: 'Valor fixo por rota', value: 3 },
  { label: 'Por metro cúbico', value: 4 },
  { label: 'Por peso líquido', value: 5 },
  { label: 'Por valor de mercadoria', value: 6 },
  { label: 'Por entrega', value: 7 },
  { label: 'Por quantidade de pallets', value: 8 },
];

export function getChargeMethodById(methodId) {
  return chargingMethods.find(cM => cM.value === methodId);
}
