import React, { useEffect, useState } from 'react';
import HeaderPowerBiLayout from 'v3/layouts/Application/HeaderPowerBiLayout';
import { Row, Col, Modal, Button as BootstrapButton } from 'react-bootstrap';
import { Button, Select, Text, BadgeActive } from 'v3/components';
import { cooperplaceApi } from 'services/api';
import { useHistory } from 'react-router-dom';
import { Divider, Grid, List, ListItem } from '@material-ui/core';
import { useSnackbar } from 'v3/components/Snackbar';
import { usePermission } from 'hooks';
import { ButtonNewPanel, ButtonStyled, RowStyled, ScrollDiv } from './styles';

export default function PanelManager() {
  const [selectedWorkspace, setSelectedWorkspace] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [selectedPanel, setSelectedPanel] = useState('');
  const [workspaces, setWorkspaces] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [panels, setPanels] = useState([]);
  const [panelsList, setPanelsList] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [panelToDelete, setPanelToDelete] = useState(null);
  const snackbar = useSnackbar();
  const history = useHistory();
  usePermission('GERENCIAMENTO_PAINEIS_BI', { redirect: true });
  const handleSubmit = () => {
    history.push('/panel-register-edit');
  };

  const handleEditClick = panel => {
    history.push('/panel-register-edit', { state: panel });
  };

  useEffect(() => {
    fetchPanelsData();
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedWorkspace) {
      fetchDepartments(selectedWorkspace.id);
    } else {
      setDepartments([]);
    }
  }, [selectedWorkspace]);

  useEffect(() => {
    if (selectedDepartment) {
      fetchPanels(selectedDepartment.id);
    } else {
      setPanels([]);
    }
  }, [selectedDepartment]);

  async function fetchData() {
    const workspacesResponse = await cooperplaceApi.get('workspaces/');
    setWorkspaces(workspacesResponse.data);
  }

  async function fetchDepartments(workspaceId) {
    const response = await cooperplaceApi.get(
      `departaments/byWorkspaceId/${workspaceId}`
    );
    setDepartments(response.data);
  }

  async function fetchPanels(departmentId) {
    const panelsResponse = await cooperplaceApi.get(
      `carg-panel/byDepartamentId/${departmentId}`
    );
    setPanels(panelsResponse.data);
  }

  async function fetchPanelsData() {
    const response = await cooperplaceApi.get('carg-panel/showPanels');
    setPanelsList(response.data);
  }

  function applyFilters() {
    let filteredPanels = panelsList;

    if (selectedWorkspace) {
      filteredPanels = filteredPanels.filter(
        panel => panel.workspace_id === selectedWorkspace.id
      );
    }
    if (selectedWorkspace && selectedDepartment) {
      filteredPanels = filteredPanels.filter(
        panel =>
          panel.workspace_id === selectedWorkspace.id &&
          panel.departament_id === selectedDepartment.id
      );
    }
    if (selectedWorkspace && selectedDepartment && selectedPanel) {
      filteredPanels = filteredPanels.filter(
        panel =>
          panel.workspace_id === selectedWorkspace.id &&
          panel.departament_id === selectedDepartment.id &&
          panel.id === selectedPanel.id
      );
    }

    return filteredPanels;
  }

  async function cleanPanel() {
    setSelectedPanel('');
  }

  async function cleanDepartment() {
    setSelectedDepartment('');
    cleanPanel();
  }

  async function handleDelete(panelId) {
    try {
      await cooperplaceApi.delete(`carg-panel/${panelId}`);
      snackbar.show(<Text>Excluído com sucesso!</Text>, {
        type: 'success',
      });
      fetchPanelsData();
    } catch (error) {
      console.error('Falha ao deletar painel', error);
    }
  }

  function confirmDelete(panelId) {
    setPanelToDelete(panelId);
    setShowConfirmationModal(true);
  }

  function cancelDelete() {
    setPanelToDelete(null);
    setShowConfirmationModal(false);
  }

  function proceedDelete() {
    if (panelToDelete) {
      handleDelete(panelToDelete);
      setShowConfirmationModal(false);
    }
  }

  return (
    <>
      <HeaderPowerBiLayout
        title="Gerenciamento de painéis"
        contentFluid
        isOld
        onlyCenterComponent
        fontSize="20px"
      />
      <RowStyled style={{ margin: 0, marginTop: 20 }} md={12}>
        <Col md={2} />
        <Col md={8}>
          <Row md={12}>
            <Col md={3}>
              <Select
                modalHeading="Selecione um Workspace"
                modalBodyTitle="Workspace:"
                label="WORKSPACE"
                value={selectedWorkspace}
                options={workspaces}
                getOptionLabel={option => option.workspaceName}
                getOptionValue={option => option.id}
                onChange={value => {
                  setSelectedWorkspace(value);
                  cleanDepartment();
                }}
                horizontal
              />
            </Col>
            <Col md={3}>
              <Select
                modalHeading="Selecione um Departamento"
                modalBodyTitle="Departamento:"
                label="DEPARTAMENTO"
                value={selectedDepartment}
                options={departments}
                getOptionLabel={option => option.departamentName}
                getOptionValue={option => option.id}
                onChange={value => {
                  setSelectedDepartment(value);
                  cleanPanel();
                }}
                horizontal
              />
            </Col>
            <Col md={3}>
              <Select
                modalHeading="Selecione um Painel"
                modalBodyTitle="Painel:"
                label="Painel"
                value={selectedPanel}
                options={panels}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                onChange={value => {
                  setSelectedPanel(value);
                }}
                horizontal
              />
            </Col>
            <Col md={3}>
              <ButtonNewPanel color="blue" onClick={handleSubmit}>
                <Text weight={500} color="white" type="regular">
                  Novo Painel
                </Text>
              </ButtonNewPanel>
            </Col>
          </Row>
          <RowStyled md={12}>
            <ScrollDiv>
              <List component="nav">
                {applyFilters().length > 0 ? (
                  applyFilters().map(panel => (
                    <React.Fragment key={panel.id}>
                      <ListItem
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Grid container alignItems="center">
                          <Grid item xs={5}>
                            <Text>
                              {panel.workspace_name} / {panel.departament_name}{' '}
                              / {panel.name}
                            </Text>
                          </Grid>
                          <Grid item xs={4} container justifyContent="center">
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              {panel.active === true ? (
                                <BadgeActive
                                  pill
                                  light
                                  type="success"
                                  className="mr-2"
                                >
                                  <Text type="label">Ativo</Text>
                                </BadgeActive>
                              ) : (
                                <BadgeActive
                                  pill
                                  light
                                  type="error"
                                  className="mr-2"
                                >
                                  <Text type="label">Inativo</Text>
                                </BadgeActive>
                              )}
                            </div>
                          </Grid>
                          <Grid item xs={3} container justifyContent="flex-end">
                            <ButtonStyled color="blue" size="sm">
                              <Text
                                weight={500}
                                color="white"
                                type="regular"
                                onClick={() => handleEditClick(panel)}
                              >
                                Editar
                              </Text>
                            </ButtonStyled>
                            <Button
                              variant="secondary"
                              size="sm"
                              onClick={() => confirmDelete(panel.id)}
                            >
                              <Text weight={500} color="gray" type="regular">
                                Excluir
                              </Text>
                            </Button>
                          </Grid>
                        </Grid>
                      </ListItem>

                      <Divider />
                    </React.Fragment>
                  ))
                ) : (
                  <ListItem>
                    <Text>Nenhum painel encontrado.</Text>
                  </ListItem>
                )}
              </List>
            </ScrollDiv>
          </RowStyled>
        </Col>
        <Col md={2} />
      </RowStyled>

      <Modal show={showConfirmationModal} onHide={cancelDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Excluir painel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Text>
            Ao confirmar a exclusão, será também deletado todos os usuários
            pertencentes a este painel, deseja excluir?
          </Text>
        </Modal.Body>
        <Modal.Footer>
          <BootstrapButton variant="secondary" onClick={cancelDelete}>
            Cancelar
          </BootstrapButton>
          <BootstrapButton variant="danger" onClick={proceedDelete}>
            Excluir
          </BootstrapButton>
        </Modal.Footer>
      </Modal>
    </>
  );
}
