import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const ContractsListContainer = styled.div`
  overflow: scroll;
  scroll-behavior: smooth;
  max-height: 40vh;
  ::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
  ::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.light_gray};
    opacity: 0.5;
  }
  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.secondary_light};
    border-radius: 10px;
  }
`;

export const ContractContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  align-items: center;
  grid-column-gap: 0.5rem;
  padding: 0.5rem;

  @media (max-width: 480px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
`;
