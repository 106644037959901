/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import theme from 'v3/theme';
import { Link } from 'react-router-dom';
import { FaPencilAlt, FaTrash } from 'react-icons/fa';
import { Col } from 'react-bootstrap';

import Card from 'v3/components/Card';
import Text from 'v3/components/Text';
import Table from 'v3/components/Table';
import Button from 'v3/components/Button';
import Badge from 'v3/components/Badge';
import Fallback from 'v3/components/Fallback';
import { formatPlate } from '../../../../utils/formatter';

import VehicleModal from '../Modals/Vehicle';

const Vehicles = ({ vehicles, setVehicles }) => {
  const [show, setShow] = useState(false);
  const [currentVehicle, setCurrentVehicle] = useState();
  return (
    <>
      <VehicleModal
        currentVehicle={currentVehicle}
        setCurrentVehicle={setCurrentVehicle}
        vehicles={vehicles}
        setVehicles={setVehicles}
        isOpen={show}
        onClose={() => {
          if (currentVehicle) {
            setVehicles([...vehicles, currentVehicle]);
            setCurrentVehicle(null);
          }

          setShow(false);
        }}
        setShow={setShow}
      />
      <Card
        header={
          <Text type="header" color="dark">
            Veículos
          </Text>
        }
        HeaderRightComponent={
          <>
            {!!vehicles.length && (
              <Button
                onClick={() => {
                  setShow(true);
                }}
              >
                <Text type="regular" weight="500" color="white">
                  Adicionar
                </Text>
              </Button>
            )}
          </>
        }
      >
        <Fallback
          fall={!vehicles.length}
          component={
            <Col xs={12} className="d-flex flex-column align-items-center">
              <Text color={theme.colors.gray}>
                Não há motoristas cadastrados para esse veículo.
              </Text>
              <Button onClick={() => setShow(true)} className="mt-4">
                <Text weigth={500} type="regular">
                  Adicionar
                </Text>
              </Button>
            </Col>
          }
        >
          <Table>
            <thead>
              <tr>
                <th>
                  <Text weight="500" color="dark">
                    Placa
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Tipo do veículo
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Tipo de carroceria
                  </Text>
                </th>
                <th className="text-center">
                  <Text weight="500" color="dark">
                    Ação
                  </Text>
                </th>
              </tr>
            </thead>
            <tbody>
              {vehicles
                ?.sort((x, y) =>
                  x.current === y.current ? 0 : x.current ? -1 : 1
                )
                .map(item => (
                  <tr>
                    <td>
                      <Link to={`/veiculos/${item.id}`}>
                        <Text type="regular" color="light_blue">
                          {formatPlate(item.plate)}
                        </Text>
                      </Link>
                      {item.current && (
                        <Badge
                          pill
                          light
                          variant="success"
                          className="ml-2"
                          size="sm"
                        >
                          <Text color="success" type="label">
                            Atual
                          </Text>
                        </Badge>
                      )}
                    </td>
                    <td>
                      <Text type="regular" color="dark">
                        {item.vehicleType?.name}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="dark">
                        {item.vehicleBodyType?.name}
                      </Text>
                    </td>
                    <td className="text-center">
                      <Button
                        variant="secondary"
                        size="sm"
                        className="mr-1"
                        onClick={() => {
                          setCurrentVehicle(item);
                          setVehicles(
                            vehicles.filter(element => element !== item)
                          );
                          setShow(true);
                        }}
                      >
                        <FaPencilAlt size={16} color="#464E5F" />
                      </Button>
                      <Button
                        variant="error"
                        size="sm"
                        onClick={() =>
                          setVehicles(
                            vehicles.filter(vehicle => vehicle !== item)
                          )
                        }
                      >
                        <FaTrash size={16} />
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Fallback>
      </Card>
    </>
  );
};

export default Vehicles;
