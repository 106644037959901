import React, { useEffect, useState } from 'react';
import { useTravel } from 'v3/pages/Travel';
import { Col, Row } from 'react-bootstrap';
import { Input, Select, Text } from 'v3/components';
import TextArea from 'v3/components/TextArea';
import { validatePermission } from 'actions';
import { cooperplaceApi } from 'services/api';
import { useRiskManager } from '../../context';
import {
  RISK_MANAGER_STATUSES,
  RISK_MANAGER_STATUSES_OBJECT,
  fetchRiskManagers,
  fetchTrackers,
} from '../../controller';

export function RiskManagerEditing() {
  const travel = useTravel();
  const { hasEditPermissionRiskManager, errors } = useRiskManager();
  const [securityProfilesOptions, setSecurityProfilesOptions] = useState([]);
  const hasPermissionToFetchSecurityProfiles = validatePermission(
    'VISUALIZAR_PERFIL_SEGURANCA'
  );

  useEffect(() => {
    async function fetchSecurityProfiles() {
      try {
        const response = await cooperplaceApi.get(
          `security-profiles/securityProfilesByCodeNumberOrName`
        );
        setSecurityProfilesOptions(response?.data);
      } catch (error) {
        setSecurityProfilesOptions([]);
      }
    }
    if (hasPermissionToFetchSecurityProfiles) {
      fetchSecurityProfiles();
    }
  }, [hasPermissionToFetchSecurityProfiles]);
  async function handleSecurityProfileSearch(search) {
    if (securityProfilesOptions) {
      return securityProfilesOptions.filter(
        profile =>
          profile?.name.toLowerCase().includes(search.toLowerCase()) ||
          String(profile?.code).includes(search.toLowerCase())
      );
    }
    return [];
  }
  async function handleRiskManagerSearch(search) {
    if (travel?.data?.loads?.riskManagers?.length) {
      return travel.data.loads.riskManagers.filter(clearance =>
        clearance.name.toLowerCase().includes(search.toLowerCase())
      );
    }

    return fetchRiskManagers(search);
  }

  return (
    <Col md={12} className="my-2">
      <Row>
        <Col md={6} sm={12}>
          <Row>
            <Col md={12} className="my-2">
              <Text type="body" color="dark" weight={500}>
                Equipamentos de monitoramento
              </Text>
            </Col>
            <Col md={6} sm={12} className="mt-2 mb-4">
              <Text type="label" color="dark" weight={500}>
                Rastreado
              </Text>
              <Text type="regular" color="gray" as="p">
                {travel?.data?.loads?.crawledLoad ? 'Sim' : 'Não'}
              </Text>
            </Col>
            <Col md={6} sm={12} className="mt-2 mb-4">
              <Text type="label" color="dark" weight={500}>
                Escoltado
              </Text>
              <Text type="regular" color="gray" as="p">
                {travel?.data?.loads?.isEscorted ? 'Sim' : 'Não'}
              </Text>
            </Col>
            <Col md={6} sm={12} className="my-2">
              <Select.Async
                label="Tecnologia rastreador"
                placeholder="Busque uma tecnologia"
                value={travel.data.tracker}
                onSearch={fetchTrackers}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                onChange={value =>
                  travel.setData(old => ({
                    ...old,
                    tracker: value,
                  }))
                }
                isDisabled={!travel?.data?.loads?.crawledLoad}
              />
            </Col>
            {travel?.data?.loads?.withBait ? (
              <Col md={6} sm={12} className="my-2">
                <Input
                  label="Número isca"
                  value={travel.data?.loads?.baitNumber}
                  onChange={({ target }) =>
                    travel.setData(old => ({
                      ...old,
                      loads: {
                        ...travel.data.loads,
                        baitNumber: target.value,
                      },
                    }))
                  }
                  disabled={!hasEditPermissionRiskManager}
                />
              </Col>
            ) : null}
          </Row>
        </Col>
        <Col md={6} sm={12}>
          <Row>
            <Col md={12} className="my-2">
              <Text type="body" color="dark" weight={500}>
                Gerenciamento de risco
              </Text>
            </Col>
            <Col md={6} sm={12} className="my-2">
              <Select.Async
                label="Perfil de segurança"
                placeholder="Busque um perfil"
                value={travel.data?.securityProfile}
                onSearch={handleSecurityProfileSearch}
                getOptionLabel={option => `${option?.code} - ${option?.name}`}
                getOptionValue={option => option?.id}
                onChange={value => {
                  travel.setData(old => ({
                    ...old,
                    securityProfile: value,
                  }));
                }}
                NoOptionsComponent={() => (
                  <Text>Nenhum perfil de segurança encontrado</Text>
                )}
              />
            </Col>
            <Col md={6} sm={12} className="my-2">
              <Select.Async
                label="Gerenciadora de risco rastreamento"
                placeholder="Busque uma gerenciadora"
                value={travel.data.riskManager}
                options={travel.data.loads.riskManagers}
                onSearch={handleRiskManagerSearch}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                onChange={value =>
                  travel.setData(old => ({
                    ...old,
                    old_risk_manager: travel.data?.riskManager,
                    riskManager: value,
                  }))
                }
                error={errors?.riskManager}
              />
            </Col>
            <Col md={6} sm={12} className="my-2">
              <Input
                label="SM de risco"
                tooltip="Código de solicitacão de rastreamento"
                disabled={!hasEditPermissionRiskManager}
                value={travel.data.riskManagerApprovalCode}
                onChange={({ target }) =>
                  travel.setData(old => ({
                    ...old,
                    riskManagerApprovalCode: target.value,
                  }))
                }
                error={errors?.riskManagerApprovalCode}
              />
            </Col>
            <Col md={6} sm={12} className="my-2">
              <Input
                label="Pesquisa/ Consulta"
                tooltip="O código retornado pela consulta na gerenciadora de risco."
                disabled={!hasEditPermissionRiskManager}
                value={travel.data.riskManagerMonitoringRequest}
                onChange={({ target }) =>
                  travel.setData(old => ({
                    ...old,
                    riskManagerMonitoringRequest: target.value,
                  }))
                }
              />
            </Col>
            <Col md={6} sm={12} className="my-2">
              <Select
                label="Status Checklist *"
                options={RISK_MANAGER_STATUSES}
                value={
                  RISK_MANAGER_STATUSES_OBJECT[travel.data.riskManagerStatus]
                }
                onChange={value => {
                  travel.setData(old => ({
                    ...old,
                    riskManagerStatus: value?.value,
                  }));
                }}
                error={errors?.riskManagerStatus}
                isDisabled={!hasEditPermissionRiskManager}
              />
            </Col>
            {travel.data.riskManagerStatus === 'WAIT_SCHEDULE' ? (
              <Col md={12} sm={12} className="my-2">
                <Input
                  label="Observação GR"
                  tooltip="Observações"
                  value={travel.data.riskManagerObservation}
                  maxLength={1000}
                  onChange={({ target }) =>
                    travel.setData(old => ({
                      ...old,
                      riskManagerObservation: target.value,
                    }))
                  }
                  errors={errors?.riskManagerObservation}
                />
              </Col>
            ) : null}
            <Col md={12} sm={12} className="my-2">
              <TextArea
                label="Averbação"
                tooltip="Dados de averbação da viagem (Data do envio, Data da averbação,Número da averbação, Protocolo, Valor averbado, Placa, Condutor)"
                value={travel.data.endorsement}
                onChange={e =>
                  travel.setData(old => ({
                    ...old,
                    endorsement: e.target.value,
                  }))
                }
                error={errors?.endorsement}
                disabled={!hasEditPermissionRiskManager}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
}
