import React, { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import moment from 'moment';

import api from 'services/api';
import Text from 'v3/components/Text';

import { useSnackbar } from 'v3/components/Snackbar';
import Badge from 'v3/components/Badge';
import Table from 'v3/components/Table';
import Tooltip from 'v3/components/Tooltip';
import Button from 'v3/components/Button';
import { useShippingCompany } from '../shared/context';

import SharedData from '../shared/Section';

export default function LastTravels() {
  const shippingCompany = useShippingCompany();
  const snackbar = useSnackbar();
  const params = useParams();

  async function getTravels() {
    if (params.id) {
      try {
        const response = await api.get(
          `v3/shipping-company/${params.id}/travels`
        );
        shippingCompany.setData({ lastTravels: response?.data });
      } catch (error) {
        snackbar.show(<Text>Erro ao carregar últimas viagens</Text>, {
          type: 'error',
        });
      }
    }
  }

  useEffect(() => {
    getTravels();
  }, []);

  function formatCityProvince(obj) {
    if (obj) {
      return `${
        obj.city && obj.province
          ? `${obj.city} - ${obj.province}`
          : obj.city
          ? obj.city
          : obj.province
          ? obj.province
          : ''
      }`;
    }

    return '';
  }
  function formatDate(date) {
    const isValid = moment(date).isValid();
    return isValid ? moment(date).format('DD/MM/YYYY') : '';
  }

  return (
    <SharedData
      Title="Últimas Viagens"
      HeaderRightComponent={
        shippingCompany?.data?.lastTravels &&
        shippingCompany?.data?.lastTravels.length > 0 && (
          <Link
            to={{
              pathname: '/viagens',
              search: `?shipping_company=${shippingCompany?.data?.id}&start_date=2020-01-01&tags=null`,
            }}
          >
            <Tooltip
              content={
                <Text type="label">
                  Ver todas as viagens dessa transportadora com a Coopertech
                </Text>
              }
            >
              <Button className="mr-2" href="">
                <Text color="white" type="regular" weight="500">
                  Ver todas as viagens
                </Text>
              </Button>
            </Tooltip>
          </Link>
        )
      }
      loading={shippingCompany.isFetching}
    >
      <Row>
        {shippingCompany?.data?.lastTravels &&
        shippingCompany?.data?.lastTravels.length > 0 ? (
          <Col xs={12}>
            <Table>
              <thead>
                <tr>
                  <th className="text-center">
                    <Text type="label" weight="500">
                      N° da viagem
                    </Text>
                  </th>
                  <th>
                    <Text type="label" weight="500">
                      Origem
                    </Text>
                  </th>
                  <th>
                    <Text type="label" weight="500">
                      Destino
                    </Text>
                  </th>
                  <th className="text-center">
                    <Text type="label" weight="500">
                      Status
                    </Text>
                  </th>
                </tr>
              </thead>
              <tbody>
                {shippingCompany?.data?.lastTravels?.map(travel => {
                  const lastDestination =
                    travel?.destinations[travel?.destinations?.length - 1];

                  return (
                    <tr>
                      <td className="text-center">
                        <Link to={`/viagens/${travel.id}`} key={travel.id}>
                          <Text type="regular" color="primary">
                            {travel.travel_number || travel.id}
                          </Text>
                        </Link>
                      </td>
                      <td>
                        {origin && (
                          <>
                            <Text type="regular">
                              {formatCityProvince(travel.origin)}
                            </Text>
                            <br />
                            <Text type="regular" color="gray">
                              {formatDate(travel.origin.scheduled_time)}
                            </Text>
                          </>
                        )}
                      </td>
                      <td>
                        {lastDestination && (
                          <>
                            <Text type="regular">
                              {formatCityProvince(lastDestination)}
                            </Text>
                            <br />
                            <Text type="regular" color="gray">
                              {formatDate(lastDestination.scheduled_time)}
                            </Text>
                          </>
                        )}
                      </td>
                      <td width="200" className="text-center">
                        <Badge variant={travel.status?.name}>
                          <Text type="regular" color="#fff">
                            {travel.status?.name}
                          </Text>
                        </Badge>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        ) : (
          <Col xs={12}>
            <Text type="regular" color="#494950" as="p" className="mt-2">
              Nenhuma viagem
            </Text>
          </Col>
        )}
      </Row>
    </SharedData>
  );
}
