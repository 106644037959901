import React, { useEffect, useState, useRef } from 'react';

import { Col, Row } from 'react-bootstrap';

import api from 'services/api';

import ModalRaw from 'v3/components/Modal';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import Input from 'v3/components/Input';
import { useSnackbar } from 'v3/components/Snackbar';

function Modal({
  isOpen,
  onClose,
  dashboardSelected,
  setSelected,
  setOptions,
}) {
  const snackbar = useSnackbar();
  const inputRef = useRef();

  const [loading, setLoading] = useState(false);
  const [dashboard, setDashboard] = useState({
    title: '',
  });

  async function handleSave() {
    try {
      setLoading(true);

      const data = {
        title: dashboard.title,
      };

      const response = await api.put(
        `v3/dashboard/${dashboardSelected.id}/name`,
        data
      );

      setSelected(response.data.dashboardsToUpdate);
      setOptions(response.data.userDashboards);
      snackbar.show(<Text>Dashboard editada com sucesso!</Text>, {
        type: 'success',
      });
    } catch (ex) {
      snackbar.show(
        <Text>
          Não foi possível alterar os dados, tente novamente mais tarde!
        </Text>,
        { type: 'error' }
      );
    } finally {
      setLoading(false);
      onClose();
    }
  }

  useEffect(() => {
    if (isOpen) {
      inputRef.current.focus();
    }

    setDashboard(old => ({ ...old, title: `${dashboardSelected?.title}` }));
  }, [isOpen]);

  return (
    <>
      <ModalRaw
        show={isOpen}
        onHide={onClose}
        size="lg"
        heading={
          <Text type="header" color="dark">
            Editar Título
          </Text>
        }
        body={
          <>
            <Row className="mx-4 my-3">
              <Input
                ref={inputRef}
                value={dashboard?.title}
                onChange={({ target }) =>
                  setDashboard(old => ({ ...old, title: target.value }))
                }
                label={
                  <Text color="#1c4b75" type="regular" weight="600">
                    Novo título:
                  </Text>
                }
              />
            </Row>
          </>
        }
        footer={
          <>
            <Button
              loading={loading}
              className="py-2"
              variant="white"
              onClick={() => onClose()}
            >
              <Text weight="500" type="regular">
                Voltar
              </Text>
            </Button>
            <Button
              loading={loading}
              className="py-2"
              variant="success"
              onClick={() => handleSave()}
            >
              <Text weight="500" type="regular">
                Salvar
              </Text>
            </Button>
          </>
        }
      />
    </>
  );
}

export default Modal;
