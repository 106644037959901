import ReactDOMServer from 'react-dom/server';
import moment from 'moment';
import React from 'react';
import QRCode from 'qrcode.react';

function spacify(str, after, c) {
  if (!str) {
    return false;
  }
  after = after || 4;
  c = c || ' ';
  const v = str.replace(/[^\dA-Z]/g, '');
  const reg = new RegExp(`.{${after}}`, 'g');
  return v
    .replace(reg, function (a) {
      return a + c;
    })
    .replace(/[^0-9]+$/, '');
}

function parserDacte(xml) {
  const obj = {};

  // Emissor

  obj.emitCnpj = xml.querySelector(' CTe infCte emit CNPJ')
    ? xml.querySelector(' CTe infCte emit CNPJ').textContent
    : '';
  obj.emiXnome = xml.querySelector(' CTe infCte emit xNome')
    ? xml.querySelector(' CTe infCte emit xNome').textContent
    : '';
  obj.emitLogr = xml.querySelector(' CTe infCte emit enderEmit xLgr')
    ? xml.querySelector(' CTe infCte emit enderEmit xLgr').textContent
    : '';
  obj.emitIe = xml.querySelector(' CTe infCte emit enderEmit IE')
    ? xml.querySelector(' CTe infCte emit enderEmit IE').textContent
    : '';
  obj.emitNo = xml.querySelector(' CTe infCte emit enderEmit nro')
    ? xml.querySelector(' CTe infCte  emit enderEmit nro').textContent
    : '';
  obj.emitXbairro = xml.querySelector(' CTe infCte emit enderEmit xBairro')
    ? xml.querySelector(' CTe infCte  emit enderEmit xBairro').textContent
    : '';
  obj.emitXMun = xml.querySelector(' CTe infCte emit enderEmit xMun')
    ? xml.querySelector(' CTe infCte emit enderEmit xMun').textContent
    : '';
  obj.emitCep = xml.querySelector(' CTe infCte emit enderEmit CEP')
    ? xml.querySelector(' CTe infCte emit enderEmit CEP').textContent
    : '';
  obj.emitUf = xml.querySelector(' CTe infCte emit enderEmit UF')
    ? xml.querySelector(' CTe infCte emit enderEmit UF').textContent
    : '';
  obj.emitFone = xml.querySelector(' CTe infCte emit enderEmit fone')
    ? xml.querySelector(' CTe infCte emit enderEmit fone').textContent
    : '';

  //
  obj.modelo = xml.querySelector(' CTe infCte ide mod')
    ? xml.querySelector(' CTe infCte ide mod').textContent
    : '';
  obj.serie = xml.querySelector(' CTe infCte ide serie')
    ? xml.querySelector(' CTe infCte ide serie').textContent
    : '';
  obj.nCT = xml.querySelector(' CTe infCte ide nCT')
    ? xml.querySelector(' CTe infCte ide nCT').textContent
    : '';
  obj.dhEmi = xml.querySelector(' CTe infCte ide dhEmi')
    ? moment(xml.querySelector(' CTe infCte ide dhEmi').textContent).format(
        'DD/MM/YYYY HH:mm:ss'
      )
    : '';

  obj.numModal = xml.querySelector(' CTe infCte ide modal')
    ? xml.querySelector(' CTe infCte ide modal').textContent
    : '';

  switch (obj.numModal) {
    case '01':
      obj.modal = 'Rodoviário';
      break;
    case '02':
      obj.modal = 'Aéreo';
      break;
    case '03':
      obj.modal = 'Aquaviário';
      break;
    case '04':
      obj.modal = 'Ferroviário';
      break;
    case '05':
      obj.modal = 'Dutoviário';
      break;
    case '06':
      obj.modal = 'Multimodal';
      break;
    default:
  }

  obj.numtipoServico = xml.querySelector(' CTe infCte ide tpServ')
    ? xml.querySelector(' CTe infCte ide tpServ').textContent
    : '';

  switch (obj.numtipoServico) {
    case '0':
      obj.tipoServico = 'Normal';
      break;
    case '1':
      obj.tipoServico = 'Subcontratação';
      break;
    case '2':
      obj.tipoServico = 'Redespacho';
      break;
    case '3':
      obj.tipoServico = 'Redespacho Intermediário';
      break;
    case '4':
      obj.tipoServico = 'Serviço Vinculado a Multimodal';
      break;
    default:
  }

  // INSC SUFRAMA DESTINATÁRIO
  obj.inscSuframaDestinatario = '';
  obj.infCTEGlobalizado = '';
  obj.outrasCaracteristicas = '';
  obj.indicadorCTEGlobalizado = '';
  obj.numTpCTe = xml.querySelector(' CTe infCte ide tpCTe')
    ? xml.querySelector(' CTe infCte ide tpCTe').textContent
    : '';

  switch (obj.numTpCTe) {
    case '0':
      obj.tpCTe = 'CT-e Normal';
      break;
    case '1':
      obj.tpCTe = 'CT-e de Complemento de Valores';
      break;
    case '2':
      obj.tpCTe = 'CT-e de Anulação';
      break;
    case '3':
      obj.tpCTe = 'CT-e Substituto';
      break;
    default:
  }

  obj.numChCTe = xml.querySelector(' protCTe infProt chCTe')
    ? xml.querySelector(' protCTe infProt chCTe').textContent
    : '';
  obj.chCTe = xml.querySelector(' protCTe infProt chCTe')
    ? spacify(xml.querySelector(' protCTe infProt chCTe').textContent, 4, ' ')
    : '';

  obj.numQrCode = xml.querySelector(' CTe infCTeSupl qrCodCTe')
    ? xml
        .querySelector(' CTe infCTeSupl qrCodCTe')
        .textContent.replace('&amp;', '&')
    : '';

  const codigo = `${obj.numQrCode}`;
  if (obj.numQrCode !== '')
    obj.qrCTe = ReactDOMServer.renderToStaticMarkup(
      <QRCode value={codigo} renderAs="svg" />
    );
  else {
    obj.qrCTe = '';
  }

  const [cteKey] = obj.numQrCode.split('chCTe=')[1].split('&tpAmb');

  obj.barcode = ReactDOMServer.renderToStaticMarkup(
    <img
      style={{
        width: '94%',
        height: '40px',
        marginLeft: '3%',
        marginTop: '4px',
      }}
      src={`https://bwipjs-api.metafloor.com/?bcid=code128&text=${
        obj.numChCTe || cteKey
      }`}
      alt="barcode"
    />
  );

  obj.nProt = xml.querySelector(' protCTe infProt nProt')
    ? xml.querySelector(' protCTe infProt nProt').textContent
    : '';
  obj.cfop = xml.querySelector(' CTe infCte ide CFOP')
    ? xml.querySelector(' CTe infCte ide CFOP').textContent
    : '';
  obj.dhRecbto = xml.querySelector(' protCTe infProt dhRecbto')
    ? xml.querySelector(' protCTe infProt dhRecbto').textContent
    : '';
  obj.natOp = xml.querySelector(' CTe infCte ide natOp')
    ? xml.querySelector(' CTe infCte ide natOp').textContent
    : '';

  // Inicio das prestção
  obj.cMunIni = xml.querySelector(' CTe infCte ide cMunIni')
    ? xml.querySelector(' CTe infCte ide cMunIni').textContent
    : '';
  obj.xMunIni = xml.querySelector(' CTe infCte ide xMunIni')
    ? xml.querySelector(' CTe infCte ide xMunIni').textContent
    : '';
  obj.UFIni = xml.querySelector(' CTe infCte ide UFIni')
    ? xml.querySelector(' CTe infCte ide UFIni').textContent
    : '';

  // Término da prestação
  obj.cMunFim = xml.querySelector(' CTe infCte ide cMunFim')
    ? xml.querySelector(' CTe infCte ide cMunFim').textContent
    : '';
  obj.xMunFim = xml.querySelector(' CTe infCte ide xMunFim')
    ? xml.querySelector(' CTe infCte ide xMunFim').textContent
    : '';
  obj.UFFim = xml.querySelector(' CTe infCte ide UFFim')
    ? xml.querySelector(' CTe infCte ide UFFim').textContent
    : '';

  // remetente
  obj.xNomeRem = xml.querySelector(' CTe infCte rem xNome')
    ? xml.querySelector(' CTe infCte rem xNome').textContent
    : '';
  obj.CNPJRem = xml.querySelector(' CTe infCte rem CNPJ')
    ? xml.querySelector(' CTe infCte rem CNPJ').textContent
    : '';
  obj.IERem = xml.querySelector(' CTe infCte rem IE')
    ? xml.querySelector(' CTe infCte rem IE').textContent
    : '';
  obj.xLgrRem = xml.querySelector(' CTe infCte rem enderReme xLgr')
    ? xml.querySelector(' CTe infCte rem enderReme xLgr').textContent
    : '';
  obj.nroRem = xml.querySelector(' CTe infCte rem enderReme nro')
    ? xml.querySelector(' CTe infCte rem enderReme nro').textContent
    : '';
  obj.xBairroRem = xml.querySelector(' CTe infCte rem enderReme xBairro')
    ? xml.querySelector(' CTe infCte rem enderReme xBairro').textContent
    : '';
  obj.cMunRem = xml.querySelector(' CTe infCte rem enderReme cMun')
    ? xml.querySelector(' CTe infCte rem enderReme cMun').textContent
    : '';
  obj.xMunRem = xml.querySelector(' CTe infCte rem enderReme xMun')
    ? xml.querySelector(' CTe infCte rem enderReme xMun').textContent
    : '';
  obj.CEPRem = xml.querySelector(' CTe infCte rem enderReme CEP')
    ? xml.querySelector(' CTe infCte rem enderReme CEP').textContent
    : '';
  obj.UFRem = xml.querySelector(' CTe infCte rem enderReme UF')
    ? xml.querySelector(' CTe infCte rem enderReme UF').textContent
    : '';
  obj.cPaisRem = xml.querySelector(' CTe infCte rem enderReme cPais')
    ? xml.querySelector(' CTe infCte rem enderReme cPais').textContent
    : '';
  obj.xPaisRem = xml.querySelector(' CTe infCte rem enderReme xPais')
    ? xml.querySelector(' CTe infCte rem enderReme xPais').textContent
    : '';
  obj.foneRem = '';

  // destinatario
  obj.xNomeDest = xml.querySelector(' CTe infCte dest xNome')
    ? xml.querySelector(' CTe infCte dest xNome').textContent
    : '';
  obj.CNPJDest = xml.querySelector(' CTe infCte dest CNPJ')
    ? xml.querySelector(' CTe infCte dest CNPJ').textContent
    : '';
  obj.IEDest = xml.querySelector(' CTe infCte dest IE')
    ? xml.querySelector(' CTe infCte dest IE').textContent
    : '';
  obj.xLgrDest = xml.querySelector(' CTe infCte dest enderDest xLgr')
    ? xml.querySelector(' CTe infCte dest enderDest xLgr').textContent
    : '';
  obj.nroDest = xml.querySelector(' CTe infCte dest enderDest nro')
    ? xml.querySelector(' CTe infCte dest enderDest nro').textContent
    : '';
  obj.xBairroDest = xml.querySelector(' CTe infCte dest enderDest xBairro')
    ? xml.querySelector(' CTe infCte dest enderDest xBairro').textContent
    : '';
  obj.cMunDest = xml.querySelector(' CTe infCte dest enderDest cMun')
    ? xml.querySelector(' CTe infCte dest enderDest cMun').textContent
    : '';
  obj.xMunDest = xml.querySelector(' CTe infCte dest enderDest xMun')
    ? xml.querySelector(' CTe infCte dest enderDest xMun').textContent
    : '';
  obj.CEPDest = xml.querySelector(' CTe infCte dest enderDest CEP')
    ? xml.querySelector(' CTe infCte dest enderDest CEP').textContent
    : '';
  obj.UFDest = xml.querySelector(' CTe infCte dest enderDest UF')
    ? xml.querySelector(' CTe infCte dest enderDest UF').textContent
    : '';
  obj.cPaisDest = xml.querySelector(' CTe infCte dest enderDest cPais')
    ? xml.querySelector(' CTe infCte dest enderDest cPais').textContent
    : '';
  obj.xPaisDest = xml.querySelector(' CTe infCte dest enderDest xPais')
    ? xml.querySelector(' CTe infCte dest enderDest xPais').textContent
    : '';
  obj.foneDest = '';

  // expedidor
  obj.CNPJExped = xml.querySelector(' CTe infCte exped CNPJ')
    ? xml.querySelector(' CTe infCte exped CNPJ').textContent
    : '';
  obj.IeExped = xml.querySelector(' CTe infCte exped IE')
    ? xml.querySelector(' CTe infCte exped IE').textContent
    : '';
  obj.xNomeExped = xml.querySelector(' CTe infCte exped xNome')
    ? xml.querySelector(' CTe infCte exped xNome').textContent
    : '';
  obj.xLgrExped = xml.querySelector(' CTe infCte exped enderExped xLgrExped')
    ? xml.querySelector(' CTe infCte exped enderExped xLgrExped').textContent
    : '';

  obj.xLgrExped = xml.querySelector(' CTe infCte exped enderExped xLgr')
    ? xml.querySelector(' CTe infCte exped enderExped xLgr').textContent
    : '';
  // xLgr
  obj.nroExped = xml.querySelector(' CTe infCte exped enderExped nro')
    ? xml.querySelector(' CTe infCte exped enderExped nro').textContent
    : '';
  obj.xMunExped = xml.querySelector(' CTe infCte exped enderExped xMun')
    ? xml.querySelector(' CTe infCte exped enderExped xMun').textContent
    : '';
  obj.CEPExped = xml.querySelector(' CTe infCte exped enderExped CEP')
    ? xml.querySelector(' CTe infCte exped enderExped CEP').textContent
    : '';
  obj.UFExped = xml.querySelector(' CTe infCte exped enderExped UF')
    ? xml.querySelector(' CTe infCte exped enderExped UF').textContent
    : '';

  obj.foneExped = '';
  /* obj.foneExped = xml.querySelector(' CTe infCte exped enderExped UF')
    ? xml.querySelector(' CTe infCte exped enderExped fone').textContent
    : ''; */

  // recebedor
  obj.CNPJReceb = xml.querySelector(' CTe infCte receb CNPJ')
    ? xml.querySelector(' CTe infCte receb CNPJ').textContent
    : '';
  obj.IEReceb = xml.querySelector(' CTe infCte receb IE')
    ? xml.querySelector(' CTe infCte receb IE').textContent
    : '';
  obj.xNomeReceb = xml.querySelector(' CTe infCte receb xNome')
    ? xml.querySelector(' CTe infCte receb xNome').textContent
    : '';
  obj.xLgrReceb = xml.querySelector(' CTe infCte receb xLgr')
    ? xml.querySelector(' CTe infCte receb xLgr').textContent
    : '';
  obj.nroReceb = xml.querySelector(' CTe infCte receb nro')
    ? xml.querySelector(' CTe infCte receb nro').textContent
    : '';
  obj.xMunReceb = xml.querySelector(' CTe infCte receb xMun')
    ? xml.querySelector(' CTe infCte receb xMun').textContent
    : '';
  obj.CEPReceb = xml.querySelector(' CTe infCte receb CEP')
    ? xml.querySelector(' CTe infCte receb CEP').textContent
    : '';
  obj.UFReceb = xml.querySelector(' CTe infCte receb UF')
    ? xml.querySelector(' CTe infCte receb UF').textContent
    : '';
  obj.foneReceb = '';

  // Tomador do serviço
  obj.toma3 = xml.querySelector(' CTe infCte ide toma3 toma')
    ? xml.querySelector(' CTe infCte ide toma3 toma').textContent
    : '';
  obj.toma4 = xml.querySelector(' CTe infCte ide toma4 toma')
    ? xml.querySelector(' CTe infCte ide toma4 toma').textContent
    : '';

  // Produto predominante
  obj.proPred = xml.querySelector(' CTe infCte infCTeNorm infCarga proPred')
    ? xml.querySelector(' CTe infCte infCTeNorm infCarga proPred').textContent
    : '';
  obj.vCarga = xml.querySelector(' CTe infCte infCTeNorm infCarga vCarga')
    ? xml.querySelector(' CTe infCte infCTeNorm infCarga vCarga').textContent
    : '';

  // QDT Carga
  obj.qCargaBaseCalculo = xml.querySelectorAll(
    ' CTe infCte infCTeNorm infCarga infQ qCarga'
  )[0]
    ? xml.querySelectorAll(' CTe infCte infCTeNorm infCarga infQ qCarga')[0]
        .textContent
    : '';
  obj.qCargaQtdVolumes = xml.querySelectorAll(
    ' CTe infCte infCTeNorm infCarga infQ qCarga'
  )[1]
    ? xml.querySelectorAll(' CTe infCte infCTeNorm infCarga infQ qCarga')[1]
        .textContent
    : '';
  obj.qCargaPesoBruto = xml.querySelectorAll(
    ' CTe infCte infCTeNorm infCarga infQ qCarga'
  )[2]
    ? xml.querySelectorAll(' CTe infCte infCTeNorm infCarga infQ qCarga')[2]
        .textContent
    : '';

  // Componentes  do valor da prestação de serviço
  obj.vTPrest = xml.querySelector(' CTe infCte vPrest vTPrest')
    ? xml.querySelector(' CTe infCte vPrest vTPrest').textContent
    : '';
  obj.vRec = xml.querySelector(' CTe infCte vPrest vRec')
    ? xml.querySelector(' CTe infCte vPrest vRec').textContent
    : '';
  // comp0
  obj.xNomePrest0 = xml.querySelectorAll(' CTe infCte vPrest Comp xNome')[0]
    ? xml.querySelectorAll(' CTe infCte vPrest Comp xNome')[0].textContent
    : '';
  obj.vComp0 = xml.querySelectorAll(' CTe infCte vPrest Comp vComp')[0]
    ? xml.querySelectorAll(' CTe infCte vPrest Comp vComp')[0].textContent
    : '';
  // comp1
  obj.xNomePrest1 = xml.querySelectorAll(' CTe infCte vPrest Comp xNome')[1]
    ? xml.querySelectorAll(' CTe infCte vPrest Comp xNome')[1].textContent
    : '';
  obj.vComp1 = xml.querySelectorAll(' CTe infCte vPrest Comp vComp')[1]
    ? xml.querySelectorAll(' CTe infCte vPrest Comp vComp')[1].textContent
    : '';
  // comp2
  obj.xNomePrest2 = xml.querySelectorAll(' CTe infCte vPrest Comp xNome')[2]
    ? xml.querySelectorAll(' CTe infCte vPrest Comp xNome')[2].textContent
    : '';
  obj.vComp2 = xml.querySelectorAll(' CTe infCte vPrest Comp vComp')[2]
    ? xml.querySelectorAll(' CTe infCte vPrest Comp vComp')[2].textContent
    : '';
  // comp3
  obj.xNomePrest3 = xml.querySelectorAll(' CTe infCte vPrest Comp xNome')[3]
    ? xml.querySelectorAll(' CTe infCte vPrest Comp xNome')[3].textContent
    : '';
  obj.vComp3 = xml.querySelectorAll(' CTe infCte vPrest Comp vComp')[3]
    ? xml.querySelectorAll(' CTe infCte vPrest Comp vComp')[3].textContent
    : '';

  // INformações relativas ao imposto

  obj.prevFluxo = '';

  // Documentos originários
  obj.chave0 = xml.querySelectorAll(
    ' CTe infCte infCTeNorm infDoc infNFe chave'
  )[0]
    ? xml.querySelectorAll(' CTe infCte infCTeNorm infDoc infNFe chave')[0]
        .textContent
    : '';

  // obj.chave0 = spacify(obj.chave0, 4, ' ');

  obj.dPrev0 = xml.querySelectorAll(
    ' CTe infCte infCTeNorm infDoc infNFe dPrev'
  )[0]
    ? xml.querySelectorAll(' CTe infCte infCTeNorm infDoc infNFe dPrev')[0]
        .textContent
    : '';
  obj.chave1 = xml.querySelectorAll(
    ' CTe infCte infCTeNorm infDoc infNFe chave'
  )[1]
    ? xml.querySelectorAll(' CTe infCte infCTeNorm infDoc infNFe chave')[1]
        .textContent
    : '';
  // obj.chave1 = spacify(obj.chave1, 4, ' ');

  obj.dPrev1 = xml.querySelectorAll(
    ' CTe infCte infCTeNorm infDoc infNFe dPrev'
  )[1]
    ? xml.querySelectorAll(' CTe infCte infCTeNorm infDoc infNFe dPrev')[1]
        .textContent
    : '';

  // Observações gerais
  obj.xObs = xml.querySelector(' CTe infCte compl xObs')
    ? xml.querySelector(' CTe infCte compl xObs').textContent
    : '';

  // Informações específicas do modal rodoviário
  obj.Rntrc = xml.querySelector(' CTe infCte infCTeNorm infModal rodo RNTRC')
    ? xml.querySelector(' CTe infCte infCTeNorm infModal rodo RNTRC')
        .textContent
    : '';

  obj.aliqIcms = '';
  obj.vlrIcms = '';

  return obj;
}

export default parserDacte;
