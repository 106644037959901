import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { formatCurrency } from 'v3/utils/formatter';
import Text from 'v3/components/Text';
import { useSnackbar } from 'v3/components/Snackbar';
import api from 'services/api';
import { formatNumber } from 'utils/formatter';
import { useRoute } from '../shared/context';
import { validate } from '../shared/validator';

import { findBelowPriceItems } from '../shared/utils';
import SharedData from '../shared/Section';
import Inputs from '../shared/Charge';
import Editable from '../shared/Editable';
import ConfirmBelowPrice from '../shared/ConfirmBelowPrice';

import { handleEdit } from './utils';
import { currencyWithSymbol } from 'v3/utils/formatter';

export default function RouteCharge() {
  const route = useRoute();
  const params = useParams();
  const snackbar = useSnackbar();
  const [isEditing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  const [belowMinimumFreightTablePrice, setBelowMinimumFreightTablePrice] =
    useState(false);
  const [belowPriceItems, setBelowPriceItems] = useState('');

  const [routeCurrency, setRouteCurrency] = useState('');
  const [routeCurrencySymbol, setRouteCurrencySymbol] = useState('');
  useEffect(() => {
    if (route.data.currency) {
      setRouteCurrency(route.data.currency.value || route.data.currency);
      let tempCurrency = route.data.currency.value || route.data.currency;
      setRouteCurrencySymbol(
        currencyWithSymbol.find(currency => currency.currency === tempCurrency)
          ?.symbol
      );
    }
  }, [route.data?.currency]);

  async function saveData() {
    setLoading(true);
    route.setErrors({});
    const charge = {
      charge_type: route.data?.charge_type?.value,
      charge_price:
        route.data?.charge_price || route.data?.priceRanges[0].value,
      external_value:
        route.data?.charge_type?.value === 2
          ? route.data?.priceRanges[0].external_value
          : route.data?.charge_type?.value === 5
          ? route.data?.priceRanges[0].external_value
          : route.data?.external_value,
      priceRanges: route.data?.priceRanges,
    };

    const priceRanges = route.data?.priceRanges;
    const isValid = await validate(
      'charge',
      {
        charge,
      },
      route.setErrors,
      {
        hasParams: params.id,
        charge_type: route.data?.charge_type?.value,
      }
    );
    if (!isValid) {
      snackbar.show(<Text>Verifique os campos obrigatórios</Text>, {
        type: 'error',
      });
      setLoading(false);
      return;
    }

    try {
      await api.put(`v3/route/${params.id}/charge`, {
        data: {
          charge_type: route.data?.charge_type?.value,
          charge_price:
            route.data?.charge_price || route.data?.priceRanges[0].value,
          external_value:
            route.data?.charge_type?.value === 2
              ? route.data?.priceRanges[0].external_value
              : route.data?.charge_type?.value === 5
              ? route.data?.priceRanges[0].external_value
              : route.data?.external_value,
        },
        priceRanges,
      });
      route.updatePrevRoute();
      snackbar.show(<Text>Salvo com sucesso</Text>, { type: 'success' });
      setEditing(false);
    } catch (error) {
      if (error.response)
        snackbar.show(
          <Text>{error.response.data?.[0]?.message || 'Erro ao salvar'}</Text>,
          { type: 'error' }
        );
      else {
        snackbar.show(<Text>Erro ao salvar</Text>, { type: 'error' });
      }
    } finally {
      setLoading(false);
    }
  }

  function manageEdit() {
    setBelowPriceItems('');
    let items = findBelowPriceItems(route);
    setBelowPriceItems(items);
    handleEdit(route, setBelowMinimumFreightTablePrice, saveData);
  }

  useEffect(() => {
    if (route?.data?.priceRanges && route?.data?.priceRanges?.length === 0) {
      route.setData({
        priceRanges: [
          {
            inicial_amount: null,
            final_amount: null,
            value: null,
            external_value: null,
          },
        ],
      });
    }
  }, [route?.data?.charge_type]);

  return (
    <SharedData
      Title="Cobrança"
      HeaderRightComponent={
        <Editable
          isEditing={isEditing}
          setEditing={setEditing}
          onSave={manageEdit}
          isLoading={loading}
        />
      }
      loading={route.isFetching}
    >
      {belowMinimumFreightTablePrice ? (
        <ConfirmBelowPrice
          setBelowMinimumFreightTablePrice={setBelowMinimumFreightTablePrice}
          charge={true}
          taxes={false}
          saveData={saveData}
          belowPriceItems={belowPriceItems}
        />
      ) : null}
      {isEditing ? (
        <Inputs />
      ) : (
        <Row>
          <Col xs={12}>
            <Text type="label" color="#464E5F">
              Método de cobrança
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {route?.data?.charge_type?.label || 'Não informado'}
            </Text>
          </Col>
          {route.data?.charge_type?.value === 2 &&
            route?.data?.priceRanges.map(price => {
              return (
                <>
                  <Col xs={4}>
                    <Text type="label" color="#464E5F">
                      De (ton)
                    </Text>
                    <Text type="regular" color="gray" as="p" className="mt-2">
                      {price?.inicial_amount || 'Não informado'}
                    </Text>
                  </Col>

                  <Col xs={4}>
                    <Text type="label" color="#464E5F">
                      Até (ton)
                    </Text>
                    <Text type="regular" color="gray" as="p" className="mt-2">
                      {price?.final_amount || 'Não informado'}
                    </Text>
                  </Col>

                  <Col xs={2}>
                    <Text type="label" color="#464E5F">
                      Valor (
                      {route?.data?.currency === 'BRL' ||
                      route?.data?.currency?.value === 'BRL'
                        ? 'R$'
                        : '$'}
                      /ton)
                    </Text>
                    <Text type="regular" color="gray" as="p" className="mt-2">
                      {routeCurrency
                        ? formatCurrency(
                            +route?.data?.charge_price,
                            routeCurrency,
                            routeCurrencySymbol
                          )
                        : 'Não informado'}
                    </Text>
                  </Col>
                  <Col xs={2}>
                    <Text type="label" color="#464E5F">
                      Valor terceiro({' '}
                      {route?.data?.currency === 'BRL' ||
                      route?.data?.currency?.value === 'BRL'
                        ? 'R$'
                        : '$'}
                      /ton)
                    </Text>
                    <Text type="regular" color="gray" as="p" className="mt-2">
                      {routeCurrency
                        ? formatCurrency(
                            +route?.data?.charge_price,
                            routeCurrency,
                            routeCurrencySymbol
                          )
                        : 'Não informado'}
                    </Text>
                  </Col>
                </>
              );
            })}

          {route.data?.charge_type?.value === 1 && (
            <>
              <Col xs={4}>
                <Text type="label" color="#464E5F">
                  Distância (Km)
                </Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {`${formatNumber(route?.data?.distance)} Km` ||
                    'Não informado'}
                </Text>
              </Col>
              <Col xs={4}>
                <Text type="label" color="#464E5F">
                  Valor tomador (p/Km)
                </Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {routeCurrency
                    ? formatCurrency(
                        +route?.data?.charge_price,
                        routeCurrency,
                        routeCurrencySymbol
                      )
                    : 'Não informado'}
                </Text>
              </Col>
              <Col xs={4}>
                <Text type="label" color="#464E5F">
                  Valor terceiro
                </Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {route?.data?.external_value && routeCurrency
                    ? formatCurrency(
                        +route?.data?.external_value,
                        routeCurrency,
                        routeCurrencySymbol
                      )
                    : 'Não informado'}
                </Text>
              </Col>
            </>
          )}

          {route.data?.charge_type?.value === 3 && (
            <>
              <Col md={12}>
                <Text type="label" color="#464E5F">
                  Valor tomador
                </Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {routeCurrency
                    ? formatCurrency(
                        +route?.data?.charge_price,
                        routeCurrency,
                        routeCurrencySymbol
                      )
                    : 'Não informado'}
                </Text>
              </Col>
              <Col md={12}>
                <Text type="label" color="#464E5F">
                  Valor terceiro
                </Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {route?.data?.external_value && routeCurrency
                    ? formatCurrency(
                        +route?.data?.external_value,
                        routeCurrency,
                        routeCurrencySymbol
                      )
                    : 'Não informado'}
                </Text>
              </Col>
            </>
          )}
        </Row>
      )}
    </SharedData>
  );
}
