/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';

import { Row, Col } from 'react-bootstrap';

import Card from 'v3/components/Card';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import Input from 'v3/components/Input';
import Upload from 'v3/components/Upload';
import { FaImage, FaTrash } from 'react-icons/fa';
import { useSnackbar } from 'v3/components/Snackbar';

import DetailsText from './Text';
import { save, excludeLogo, validate } from './controller';

export default function General({ issuer, handleGetIssuer, canEditIssuers }) {
  const snackbar = useSnackbar();

  const [name, setName] = useState();
  const [tradingName, setTradingName] = useState();
  const [document, setDocument] = useState();
  const [ie, setIe] = useState();
  const [phone, setPhone] = useState();
  const [rntrc, setRntrc] = useState();
  const [logo, setLogo] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [errors, setErrors] = useState({});
  const [currentLogo, setCurrentLogo] = useState();

  function handleEdit() {
    setCurrentLogo(logo);
    setLogo(null);
    setIsEdit(!isEdit);
    setErrors({});
  }

  function handleCancelEdit() {
    setLogo(currentLogo);
    setIsEdit(!isEdit);
    setErrors({});
  }

  function handleSetIssuer() {
    setName(issuer.name);
    setRntrc(issuer.rntrc);
    setTradingName(issuer.trading_name);
    setDocument(issuer.document);
    setIe(issuer.ie);
    setPhone(issuer.phone);
    setLogo(issuer.logo);
    setCurrentLogo(issuer.logo);
  }

  async function handleSave() {
    const data = {
      id: issuer.id,
      name,
      document,
      trading_name: tradingName,
      ie,
      phone,
      rntrc,
      logo,
    };
    if (!(await validate(data, setErrors))) return false;

    const response = await save(data);

    if (!response.error) {
      snackbar.show(<Text>Dados atualizados</Text>, {
        type: 'success',
      });

      handleGetIssuer();
      handleSetIssuer();
      handleEdit();
    } else {
      snackbar.show(<Text>{response.error}</Text>, {
        type: 'error',
      });
    }
    return Promise.resolve();
  }

  async function handleRemoveLogo() {
    const logoData = {
      id: issuer.id,
      currentLogo,
    };
    const response = await excludeLogo(logoData);
    if (!response.error) {
      snackbar.show(<Text>Logo excluída com sucesso</Text>, {
        type: 'success',
      });

      handleGetIssuer();
      handleSetIssuer();
      setLogo(null);
    } else {
      snackbar.show(<Text>{response.error}</Text>, {
        type: 'error',
      });
    }
    return Promise.resolve();
  }

  useEffect(() => {
    if (issuer) {
      handleSetIssuer();
    }
  }, [issuer]);

  return (
    <Card
      header={
        <Text color="#464E5F" type="header">
          Dados do Emissor
        </Text>
      }
      HeaderRightComponent={
        <div>
          {isEdit && (
            <Button
              variant="secondary"
              onClick={() => handleCancelEdit()}
              className="mr-2"
            >
              Cancelar
            </Button>
          )}
          <Button
            variant={isEdit ? 'success' : 'secondary'}
            disabled={!canEditIssuers}
            onClick={() => (isEdit ? handleSave() : handleEdit())}
          >
            {' '}
            <Text weight={500} type="regular">
              {isEdit ? 'Salvar' : 'Editar'}
            </Text>
          </Button>
        </div>
      }
    >
      {isEdit ? (
        <Row>
          <Col xs={12}>
            <Input
              label="Nome *"
              value={name}
              onChange={event => setName(event.target.value)}
              error={errors && errors.name}
            />
          </Col>
          <Col xs={12}>
            <Input
              label="Nome fantasia *"
              value={tradingName}
              onChange={event => setTradingName(event.target.value)}
              error={errors && errors.trading_name}
            />
          </Col>
          <Col xs={6}>
            <Input
              label="CNPJ *"
              value={document}
              onChange={event => setDocument(event.target.value)}
              masked
              mask={[
                /[0-9]/,
                /[0-9]/,
                '.',
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                '.',
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                '/',
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                '-',
                /[0-9]/,
                /[0-9]/,
              ]}
              error={errors && errors.document}
            />
          </Col>
          <Col xs={6}>
            <Input
              label="Inscrição estadual *"
              value={ie}
              maxlength="14"
              onChange={event => setIe(event.target.value)}
              error={errors && errors.ie}
            />
          </Col>
          <Col xs={6}>
            <Input
              label="RNTRC *"
              value={rntrc}
              maxlength="8"
              onChange={event => setRntrc(event.target.value)}
              error={errors && errors.rntrc}
            />
          </Col>
          <Col xs={6}>
            <Input
              label="Telefone *"
              value={phone}
              onChange={event => setPhone(event.target.value)}
              masked
              mask={[
                '(',
                /[0-9]/,
                /[0-9]/,
                ')',
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
              ]}
              error={errors && errors.phone}
            />
          </Col>
          <Col xs={10}>
            <Text type="label" color="#464E5F">
              Alterar Logo
            </Text>
            <Upload
              sizeIcon={50}
              icon={<FaImage size={50} />}
              onChange={event => setLogo(event.target.files)}
              value={logo}
              id="imgLogo"
              name="logo"
              accept={['.png', '.jpg']}
              label="Arquivo máximo de 1MB"
            />
          </Col>
          <Col xs={2}>
            <Col xs={12} className="text-center mt-5">
              <Button
                variant="error"
                size="sm"
                onClick={() => handleRemoveLogo()}
                disabled={currentLogo === null}
              >
                <FaTrash />
              </Button>
            </Col>
            <Col xs={12} className="text-center">
              <Text color="#464E5F" type="little">
                Remover
              </Text>
            </Col>
          </Col>
          <Col xs={12} className="text-center mt-3">
            <Text color="#464E5F" type="little">
              Para manter a foto atual, deixe o campo em branco{' '}
            </Text>
          </Col>
        </Row>
      ) : (
        <DetailsText issuer={issuer} />
      )}
    </Card>
  );
}
