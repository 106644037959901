import React from 'react';

import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import moment from 'moment';
import Text from 'v3/components/Text';
import Modal from 'v3/components/Modal';
import { FaExternalLinkAlt, FaChevronRight } from 'react-icons/fa';

import { TravelContext } from '../../controller';
import { StyledButton } from '../../Driver/Vehicle/styles';

const [, useTravel] = TravelContext;

function getVehicleLabel(key) {
  const map = {
    main: 'veículo tração',
    implement: 'primeiro implemento',
    implement2: 'segundo implemento',
    implement3: 'terceiro implemento',
  };
  return map[key];
}

function HasTravel({ isOpen, onClose }) {
  const travel = useTravel();

  async function handleDriverRemove() {
    travel.setData(old => ({
      ...old,
      driver: '',
    }));
    onClose(true);
  }

  async function handleDriverAccepted() {
    travel.setData(old => ({
      ...old,
      acceptedDriver: true,
    }));
    onClose(true);
  }

  const now = moment();

  const rntrcExpired = Object.entries(travel?.data?.vehicle || {}).map(
    ([key, value]) => {
      return { key, value: moment(value?.rntrc_exp_date).isBefore(now, 'day') };
    }
  );

  /// card 1022 - sprint 26
  // deixar comentado para não perder o código, para retomar no futuro

  // const chronotacographExpired = moment(
  //   travel?.data?.vehicle?.main?.chronotachograph_exp_date
  // ).isBefore(moment(), 'day');

  // const cnhExpired = moment(travel?.data?.driver?.cnh_exp_date).isBefore(
  //   moment(),
  //   'day'
  // );

  const driverPaymentExpired = moment(
    travel?.data?.driver?.currentDriverPayment?.due_date,
    'MM/YYYY'
  ).isBefore(moment(), 'month');

  return (
    <>
      <Modal
        show={isOpen}
        handleClose={onClose}
        centered
        heading={
          <Text className="p-3 mx-2" type="header" color="dark" weight="500">
            Aviso
          </Text>
        }
        body={
          <>
            {/*  //Comentado pois essas validações impediam o cadastro da viagem (Task 1071).
            {rntrcExpired 
              .filter(item => item.value)
              .map(item => (
                <>
                  <Row md={12} className="px-4 align-items-center">
                    <Col md={12}>
                      <Text type="body" color="dark" weight="400">
                        A RNTRC do {getVehicleLabel(item.key)} está expirada.
                      </Text>
                    </Col>
                  </Row>
                  <hr />
                </>
              ))} */}
            {/* {chronotacographExpired && (
              <>
                <Row md={12} className="px-4 align-items-center">
                  <Col md={12}>
                    <Text type="body" color="dark" weight="400">
                      O cronotacógrafo está expirado.
                    </Text>
                  </Col>
                </Row>
                <hr />
              </>
            )} */}
            {/* {cnhExpired && (
              <>
                <Row md={12} className="px-4 align-items-center">
                  <Col md={12}>
                    <Text type="body" color="dark" weight="400">
                      A CNH de {travel.data?.driver?.social_name} está expirada.
                    </Text>
                  </Col>
                </Row>
                <hr />
              </>
            )} */}
            {driverPaymentExpired && (
              <>
                <Row md={12} className="px-4 align-items-center">
                  <Col md={12}>
                    <Text type="body" color="dark" weight="400">
                      O cartão atual está expirado.
                    </Text>
                  </Col>
                </Row>
                <hr />
              </>
            )}
            {travel.data.travels?.length < 1 && (
              <>
                <Col
                  md={12}
                  className="mt-3 mb-3 d-flex justify-content-center"
                >
                  <Text type="regular" color="dark" weight="400">
                    Não vai ser possível continuar.
                  </Text>
                </Col>
              </>
            )}

            {travel.data.travels?.length === 1 && (
              <Row md={12} className="px-4 align-items-center">
                <Col md={12}>
                  <Text type="body" color="dark" weight="400">
                    Motorista {travel.data?.driver?.social_name} já possuí uma
                    viagem.
                  </Text>
                </Col>
                {travel.data?.travels?.map(driverTravel => {
                  const lastDestination =
                    driverTravel.destinations[
                    driverTravel.destinations?.length - 1
                    ];

                  return (
                    <>
                      <Col md={12} className="mt-2">
                        <Link
                          to={`/viagens/${driverTravel.id}`}
                          target="_blank"
                        >
                          <Text type="regular" color="dark" weight="400">
                            Viagem - {driverTravel.id}
                          </Text>
                          <FaExternalLinkAlt
                            color="#464E5F"
                            className="mb-1 ml-2"
                            size={12}
                          />
                        </Link>
                      </Col>
                      <Col md={12}>
                        <Text
                          type="regular"
                          color="dark"
                          weight="400"
                          className="ml-3"
                        >
                          {driverTravel.origin?.city},{' '}
                          {driverTravel.origin?.province}
                          <FaChevronRight
                            size={12}
                            style={{ marginRight: 5, marginLeft: 5 }}
                          />
                          {lastDestination?.city}, {lastDestination?.province}
                        </Text>
                        <hr />
                      </Col>
                    </>
                  );
                })}

                {rntrcExpired.find(item => item.value) ||
                  // chronotacographExpired ||
                  // cnhExpired ||
                  driverPaymentExpired ? (
                  <>
                    <Col
                      md={12}
                      className="mt-3 mb-3 d-flex justify-content-center"
                    >
                      <Text type="regular" color="dark" weight="400">
                        Não vai ser possível continuar.
                      </Text>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col
                      md={12}
                      className="mt-3 mb-3 d-flex justify-content-center"
                    >
                      <Text type="regular" color="dark" weight="400">
                        Tem certeza que deseja continuar?
                      </Text>
                    </Col>
                    <Col
                      md={12}
                      className="mt-2 mb-3 d-flex justify-content-center"
                    >
                      <StyledButton
                        variant="error"
                        className="mr-3"
                        onClick={() => handleDriverRemove()}
                      >
                        <Text type="regular" color="white" weight="500">
                          Não
                        </Text>
                      </StyledButton>

                      <StyledButton
                        variant="success"
                        onClick={() => handleDriverAccepted(true)}
                      >
                        <Text type="regular" color="white" weight="500">
                          Sim
                        </Text>
                      </StyledButton>
                    </Col>
                  </>
                )}
              </Row>
            )}

            {travel.data.travels?.length > 1 && (
              <Row md={12} className="px-4 align-items-center">
                <Col md={12}>
                  <Text type="body" color="dark" weight="400">
                    Motorista {travel.data?.driver?.social_name} já possui duas
                    ou mais viagens.
                  </Text>
                </Col>

                {travel.data?.travels?.map(driverTravel => {
                  const lastDestination =
                    driverTravel.destinations[
                    driverTravel.destinations.length - 1
                    ];

                  return (
                    <>
                      <Col md={12} className="mt-2">
                        <Link
                          to={`/viagens/${driverTravel.id}`}
                          target="_blank"
                        >
                          <Text type="regular" color="dark" weight="400">
                            Viagem - {driverTravel.id}
                          </Text>
                          <FaExternalLinkAlt
                            color="#464E5F"
                            className="mb-1 ml-2"
                            size={12}
                          />
                        </Link>
                      </Col>

                      <Col md={12}>
                        <Text
                          type="regular"
                          color="dark"
                          weight="400"
                          className="ml-3"
                        >
                          {driverTravel.origin?.city},{' '}
                          {driverTravel.origin?.province}
                          <FaChevronRight
                            size={12}
                            style={{ marginRight: 5, marginLeft: 5 }}
                          />
                          {lastDestination?.city}, {lastDestination?.province}
                        </Text>
                        <hr />
                      </Col>
                    </>
                  );
                })}
                <Col
                  md={12}
                  className="mt-3 mb-3 d-flex justify-content-center"
                >
                  <Text type="regular" color="dark" weight="400">
                    Não vai ser possível continuar.
                  </Text>
                </Col>
              </Row>
            )}
          </>
        }
      />
    </>
  );
}

export default HasTravel;
