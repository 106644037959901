import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import { cooperplaceApi } from 'services/api';

import Modal from 'v3/components/Modal';
import Select from 'v3/components/Select';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import { useSnackbar } from 'v3/components/Snackbar';

import { useVehicle } from '../../context';

export const BennerMaintenancesModal = ({ isOpen, onClose, plates }) => {
  const vehicle = useVehicle();
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [selectedPlate, setSelectedPlate] = useState(null);

  const platesOptions = plates?.map((plate, index) => {
    return {
      label: plate,
      id: index,
    };
  });

  async function handleImportMaintenances(event) {
    event.preventDefault();
    try {
      setLoading(true);
      const response = await cooperplaceApi.get(
        `/vehicles/importMaintenancesFromBenner?plate=${selectedPlate.label}`
      );
      if (response.data.error) {
        snackbar.show(
          <Text>
            Não foi possível importar manutenções do Benner. Tente novamente
            mais tarde.
          </Text>,
          {
            type: 'warning',
          }
        );
      } else {
        const message = response?.data?.message;
        snackbar.show(<Text>{message}</Text>, {
          type: 'success',
        });
      }
    } catch (error) {
      snackbar.show(<Text>Ocorreu um erro. Tente novamente mais tarde.</Text>, {
        type: 'error',
      });
    } finally {
      setSelectedPlate('');
      setLoading(false);
      onClose(true);
      vehicle.fetchVehicle();
    }
  }
  return (
    <>
      <Modal
        show={isOpen}
        onHide={onClose}
        heading={
          <Text color="dark" type="header">
            Importar manutenções do Benner
          </Text>
        }
        body={
          <form onSubmit={handleImportMaintenances}>
            <Row>
              <Col xs={12} md={12}>
                <Select
                  label="Placa *"
                  options={platesOptions}
                  value={selectedPlate}
                  getOptionLabel={option => option.label}
                  getOptionValue={option => option.id}
                  onChange={value => {
                    setSelectedPlate(value);
                  }}
                />
              </Col>
              <Col className="d-flex justify-content-center mt-4">
                <Button
                  variant="primary"
                  disabled={!selectedPlate}
                  loading={loading}
                >
                  <Text color="white" weight={500} type="regular">
                    Importar
                  </Text>
                </Button>
              </Col>
            </Row>
          </form>
        }
      ></Modal>
    </>
  );
};
