import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import ApplicationLayout from 'v3/layouts/Application';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import useWindowResize from 'hooks/useWindowResize';
import useModal from 'hooks/useModal';
import { usePermission } from 'hooks';
import Checklist from '../Details/Checklist';
import RiskManager from '../Details/RiskManager';
import PriorityFleet from '../shared/PriorityFleet';
import Triggers from '../shared/Triggers';
import Section from '../shared/Section';
import Data from '../shared/Data';
import Client from '../shared/Client';
import Integration from '../shared/Integration';
import PriceOrder from '../shared/PriceOrder';
import CreatedCompanyModal from '../shared/Modals/CreatedCompany';
import { useCompany } from '../shared/context';
import Goal from '../shared/Goal';
import { handleCreation } from './controller';
import * as Styled from './styles';

export default function CompanyRegister() {
  const history = useHistory();
  const company = useCompany();
  const createdCompanyModal = useModal();
  const [isLoading, setLoading] = useState(false);
  const [saveContainerOffset, setSaveContainerOffset] = useState(0);
  usePermission('CADASTRAR_EMPRESAS', { redirect: true });

  useWindowResize(() => {
    const header = document.getElementById('header-container');
    const originDistanceToRight =
      window.pageXOffset + header.getBoundingClientRect().right;
    const headerWidth = header.getBoundingClientRect().width;

    const SAVE_CONTAINER_PADDING = 0;
    const distanceToRight =
      originDistanceToRight - headerWidth + SAVE_CONTAINER_PADDING;
    setSaveContainerOffset(distanceToRight);
  }, []);

  useEffect(() => {
    if (company.data.modalName === 'CreatedCompany') {
      createdCompanyModal.open();
    }
  }, [company.data.modalName]);

  return (
    <ApplicationLayout
      title="Cadastrar empresa"
      RightComponent={
        <Styled.SaveContainer style={{ right: saveContainerOffset }}>
          <Button variant="secondary" onClick={() => history.push('/empresas')}>
            <Text weight={500} color="black" type="regular">
              Voltar
            </Text>
          </Button>

          <Button
            variant="success"
            onClick={() => {
              handleCreation(
                company.data,
                company.setData,
                company.setErrors,
                company.showSnackbar,
                setLoading
              );
            }}
            loading={isLoading}
          >
            <Text weight={500} color="white" type="regular">
              Salvar
            </Text>
          </Button>
        </Styled.SaveContainer>
      }
    >
      <CreatedCompanyModal
        isOpen={createdCompanyModal.isOpen}
        onClose={createdCompanyModal.close}
      />
      <Row>
        <Col md={6} xs={12}>
          <Section Title="Dados da empresa">
            <Data />
          </Section>
        </Col>
        <Col md={6} xs={12}>
          <Row className="row-eq-height h-100">
            <Checklist />
            <PriceOrder />
          </Row>
        </Col>
        <Col xs={12}>
          <Client />
          <PriorityFleet />
        </Col>
        <Col md={6} xs={12}>
          <Section Title="Integração">
            <Integration />
          </Section>
        </Col>
        <Col md={6}>
          <RiskManager />
          <Triggers />
        </Col>

        <Col md={12}>
          <Goal />
        </Col>
      </Row>
    </ApplicationLayout>
  );
}
