import styled from 'styled-components';
import theme from 'v3/theme';

export const InfoContainerVehicle = styled.div`
  background: ${theme.colors.dark_blue};
  color: ${theme.colors.white};
  flex: 1;
  margin-top: 5px;
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

export const StyledSpanVehicle = styled.span`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: help;
  &::before {
    content: '';
    display: inline-block;
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    background-color: ${props => props.color};
  }
`;