import React, { useState, useCallback, useEffect } from 'react';

import dashboardApi from 'services/apis/dashboard';

import { useSnackbar } from 'v3/components/Snackbar';
import moment from 'moment';
import { useQuery } from 'hooks';
import { Table, Text, Link, Button } from 'v3/components';
import { formatDateTime } from 'v3/utils/formatter';
import { Pie, PieChart, Cell, ResponsiveContainer } from 'recharts';
import Card, { NumericList, SeeAll } from './Card';

const COLORS = ['#1C4B75', '#0BB7AF', '#FFC107'];

const renderCustomizedLabel = ({
  value,
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  startAngle,
  endAngle,
  column,
}) => {
  const RADIAN = Math.PI / 180;
  const diffAngle = endAngle - startAngle;
  const delta = (360 - diffAngle) / 25 - 1;
  const radius = innerRadius + (outerRadius - innerRadius);
  const x = cx + (radius + delta) * Math.cos(-midAngle * RADIAN);
  const y = cy + (radius + delta * delta) * Math.sin(-midAngle * RADIAN);
  if (value > 0) {
    return (
      <text
        x={x}
        y={y}
        fill={COLORS}
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
        fontWeight="normal"
        fontSize="14"
      >
        {column}
      </text>
    );
  }
};
const renderCustomizedLabelLine = props => {
  const {
    value,
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
  } = props;
  const RADIAN = Math.PI / 180;
  const diffAngle = endAngle - startAngle;
  const radius = innerRadius + (outerRadius - innerRadius);
  let path = '';
  for (let i = 0; i < (360 - diffAngle) / 25; i++) {
    path += `${cx + (radius + i) * Math.cos(-midAngle * RADIAN)},${
      cy + (radius + i * i) * Math.sin(-midAngle * RADIAN)
    } `;
  }
  if (value > 0) {
    return (
      <polyline points={path} stroke={COLORS} strokeWidth={1} fill={COLORS} />
    );
  }
  return null;
};

export default function LoadInteractions({ dashboard, name, id }) {
  const [isFetching, setFetching] = useState(false);
  const [data, setData] = useState({});
  const [filter, setFilter] = useState({});
  const [currentPage, setCurrentPage] = useState({});
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [formattedData, setFormattedData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const snackbar = useSnackbar();
  const queryParams = useQuery();

  function setQueryParams(filters) {
    const { tags = [] } = filters;
    const start_date = moment().subtract(3, 'days').format('YYYY-MM-DD');

    queryParams.delete('tags');

    if (tags) {
      tags.forEach(tag => {
        queryParams.append('tags', tag.id);
      });
    }

    queryParams.set('start_date', start_date);

    queryParams.set('status', 2);

    if (tags?.length === 0) {
      queryParams.set('no-tags', true);
    } else {
      queryParams.delete('no-tags');
    }
  }

  const fetchTravels = useCallback(async (filters = {}) => {
    try {
      setLoading(true);
      const {
        tags = [],
        clients = [],
        negotiators = [],
        costCenters = [],
        pcps = [],
        companies = [],
        page = 1,
      } = filters;

      if (page === 1) {
        setFetching(true);
      }

      setQueryParams(filters);
      setFilter(filters);
      setCurrentPage(+page);

      const response = await dashboardApi.get(`/loads-interactions`, {
        params: {
          tags: tags?.map(tag => tag.id),
          clients: clients?.map(client => client.id),
          negotiators: negotiators?.map(negotiator => negotiator?.id),
          costCenters: costCenters?.map(costCenter => costCenter?.id),
          pcps: pcps?.map(pcp => pcp?.id),
          companies: companies?.map(company => company?.id),
          page,
        },
      });

      setPagination(response?.data?.last?.pagination);

      if (page === 1) {
        setData(response?.data);
      } else {
        setData(oldData => ({
          ...oldData,
          last: {
            ...oldData.last,
            data: [...oldData.last.data, ...response.data.last.data],
          },
        }));
      }
    } catch (ex) {
      snackbar.show(<Text>Erro ao carregar últimas viagens</Text>, {
        type: 'error',
      });
    } finally {
      setFetching(false);
      setLoading(false);
    }
  }, []);

  function kFormatter(num) {
    return Math.abs(num) > 999
      ? `${Math.sign(num) * (Math.abs(num) / 1000).toFixed(1)}k`
      : Math.sign(num) * Math.abs(num);
  }

  useEffect(() => {
    const { total, visualizations, interested } = data;

    setFormattedData([
      {
        total: kFormatter(visualizations) || 0,
        tooltip: visualizations,
        color: 'primary',
        description: 'visualizações',
      },
      {
        total: kFormatter(total) || 0,
        tooltip: total,
        color: 'success',
        description: 'cargas',
      },
      {
        total: kFormatter(interested) || 0,
        tooltip: interested,
        color: 'warning',
        description: 'registros de interesse',
      },
    ]);
  }, [data]);

  useEffect(() => {
    const { total, visualizations, interested } = data;
    setChartData([
      {
        total: visualizations || 0,
        column: 'Visualizações',
      },
      {
        total: total || 0,
        column: 'Cargas',
      },
      {
        total: interested || 0,
        column: 'Registros de interesse',
      },
    ]);
  }, [data]);

  return (
    <Card
      title="Taxa de interação com as cargas"
      isLoading={isFetching}
      fetchFn={fetchTravels}
      onFiltersChanged={fetchTravels}
      dashboard={dashboard}
      name={name}
      excludeFilterByBranchOffice={true}
      id={id}
      excludeFilterByDate
      ListComponent={
        <>
          <Table striped>
            <thead>
              <tr>
                <th>
                  <Text weight="500" color="dark">
                    Centro de custo
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Empresa
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Tags da carga
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Carga
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Carregamento
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Origem
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Destino
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Cliente
                  </Text>
                </th>
              </tr>
            </thead>
            <tbody>
              {data.last?.data?.map(load => {
                const title = `Cliente ${load.social_name} - ${load.cgccpf}`;

                return (
                  <tr>
                    <td>
                      <Text type="regular" color="gray">
                        {load.cost_center || '-'}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray">
                        {load.company_name || '-'}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray">
                        {load.load_tags || '-'}
                      </Text>
                    </td>
                    <td>
                      <Link
                        to={`/cargas/${load.id}`}
                        title={title}
                        target="_blank"
                      >
                        {load.id}
                      </Link>
                    </td>

                    <td>
                      <Text type="regular" color="gray">
                        {formatDateTime(load.scheduled_origin)}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray" truncate>
                        {load.city_origin.toString('utf8')} - {load.uf_origin}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray" truncate>
                        {load.city_destination} - {load.uf_destination}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray" truncate>
                        {load.social_name}
                      </Text>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {pagination?.lastPage > 1 && pagination?.to < pagination?.total && (
            <div className="text-center mt-3 col-12">
              <Button
                className="mt-2 ml-auto align-item-center"
                onClick={() => {
                  fetchTravels({
                    page: currentPage + 1,
                    tags: filter?.tags,
                    clients: filter?.clients,
                    costCenters: filter?.costCenters,
                    pcps: filter?.pcps,
                    companies: filter?.companies,
                    negotiators: filter?.negotiators,
                  });
                }}
                loading={loading}
              >
                <Text type="regular" color="white" truncate>
                  Ver mais
                </Text>
              </Button>
            </div>
          )}
          {!!data?.total && (
            <SeeAll
              to={{
                pathname: '/cargas',
                search: queryParams.toString(),
              }}
            >
              ver todas as {data?.total} cargas
            </SeeAll>
          )}
        </>
      }
      NumericComponent={
        <div>
          <NumericList data={formattedData} />
          <div className="display-flex align-items-center text-center mt-4">
            <Text color="gray">nos últimos 30 dias</Text>
          </div>
          <div className="d-flex justify-content-center text-center flex-row mt-4">
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={chartData}
                  cx="50%"
                  cy="50%"
                  labelLine={renderCustomizedLabelLine}
                  label={renderCustomizedLabel}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="total"
                >
                  {chartData.map((_, index) => (
                    <Cell
                      // eslint-disable-next-line
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      }
    />
  );
}
