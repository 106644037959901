import { createStore } from 'redux';

export const INITIAL_STATE = {
  data: {},
  currentCompany: '',
  path: '/',
  refreshCompanies: false,
  towerEnableWithoutLoad: true,
  towerEnableDestinations: true,
  towerEnableOrigins: true,
  towerEnableCurrentPositions: true,
  checkCompanyJoyride: false,
  towerEnableFullTravel: false,
  dataTravel: [],
};

export function appState(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'ADD_USER':
      return { ...state, data: [...state.data, action.title, action.func] };
    case 'ADD_BUTTON':
      return {
        ...state,
        data: { ...state.data, ...action },
      };
    case 'EDIT_USER':
      return { ...state, currentUserId: action.currentUserId };
    case 'EDIT_CLIENT':
      return { ...state, currentClientEditId: action.currentClientEditId };
    case 'EDIT_LOAD':
      return { ...state, currentLoadId: action.currentLoadId };
    case 'EDIT_DRIVER':
      return { ...state, currentUserId: action.currentUserId };
    case 'EDIT_VEHICLE':
      return { ...state, currentVehicleId: action.currentVehicleId };
    case 'EDIT_TRAVEL':
      return {
        ...state,
        currentTravelId: action.currentTravelId,
        currentEmittedId: action.currentEmittedId,
      };
    case 'ADD_VEHICLE':
      return { ...state, currentVehicleId: null };
    case 'CLEAN_USER':
      return { ...state, currentUserId: '' };
    case 'CLEAN_STATE':
      return { ...state, data: [] };
    case 'SET_CURRENT_COMPANY':
      return { ...state, currentCompany: action.currentCompany };
    case 'REFRESH_COMPANIES':
      return { ...state, refreshCompanies: action.refreshCompanies };
    case 'POSITION_LOAD_MAP':
      return {
        ...state,
        currentPositionLoadMap: action.currentPositionLoadMap,
      };
    case 'TOWER_ENABLE_ORIGINS':
      return { ...state, towerEnableOrigins: action.towerEnableOrigins };
    case 'TOWER_ENABLE_DESTINATIONS':
      return {
        ...state,
        towerEnableDestinations: action.towerEnableDestinations,
      };
    case 'TOWER_ENABLE_CURRENT_POSITIONS':
      return {
        ...state,
        towerEnableCurrentPositions: action.towerEnableCurrentPositions,
      };
    case 'TOWER_ENABLE_WITH_LOAD':
      return { ...state, towerEnableOrigins: action.enableOrigin };
    case 'TOWER_ENABLE_WITHOUT_LOAD':
      return {
        ...state,
        towerEnableWithoutLoad: action.towerEnableWithoutLoad,
      };
    case 'TOWER_ENABLE_FULL_TRAVEL':
      return {
        ...state,
        towerEnableFullTravel: action.towerEnableFullTravel,
      };
    case 'TOWER_MAP_FULLSCREEN':
      return {
        ...state,
        towerEnableFullscreen: action.towerEnableFullscreen,
      };
    case 'TOWER_MAP_SHOW_TRAVEL_PATH':
      return {
        ...state,
        towerShowTravelPath: action.towerShowTravelPath,
      };
    case 'TOWER_MAP_HEAT_ORIGIN':
      return {
        ...state,
        towerEnableHeatOrigin: action.towerEnableHeatOrigin,
      };

    case 'TOWER_MAP_HEAT_DESTINATION':
      return {
        ...state,
        towerEnableHeatDestination: action.towerEnableHeatDestination,
      };
    case 'TOWER_MAP_HEAT_CURRENT_POSITIONS':
      return {
        ...state,
        towerEnableHeatCurrentPositions: action.towerEnableHeatCurrentPositions,
      };
    case 'ACTIVE_JOY_RIDE':
      return {
        ...state,
        checkCompanyJoyride: action.checkCompanyJoyride,
      };
    case 'CURRENT_PATH':
      return {
        ...state,
        path: action.path,
      };

    case 'CURRENT_PIN_CONTROL_TOWER':
      return {
        ...state,
        currentPinControlTower: action.currentPinControlTower,
      };

    case 'VIEW_DRIVERS':
      return {
        ...state,
        currentLoadId: action.currentLoadId,
      };

    case 'VIEW_INSTANCE':
      return {
        ...state,
        instance: action.instance,
      };
    case 'DATA_TRAVEL':
      return {
        ...state,
        dataTravel: [...action.payload],
      };
    default:
      return state;
  }
}

// const store = createStore(Reducers);

const store = createStore(appState);

export default store;
