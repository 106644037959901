import React, { useState, useEffect } from 'react';

import Card from 'v3/components/Card';
import Text from 'v3/components/Text';
import Table from 'v3/components/Table';
import Input from 'v3/components/Input';
import Button from 'v3/components/Button';

import { FaTrash, FaPencilAlt } from 'react-icons/fa';

import { Row, Col } from 'react-bootstrap';

import { handleSave, validateAdd } from './controller';

export default function Series({
  issuer,
  fetchIssuers,
  snackbar,
  canEditIssuers,
}) {
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [seriesList, setSeriesList] = useState([]);
  const [removedSeries, setRemovedSeries] = useState([]);
  const [unchangedSeriesList, setUnchangedSeriesList] = useState([]);

  const [serie, setSerie] = useState();
  const [number, setNumber] = useState();

  const [errors, setErrors] = useState({});

  function showSnackbar(text, options) {
    snackbar.show(<Text> {text} </Text>, options);
  }

  useEffect(() => {
    setSeriesList(issuer.series);
  }, [issuer]);

  async function addSerie() {
    if (!(await validateAdd({ serie, number }, setErrors))) {
      showSnackbar('Verifique os campos obrigatórios!', { type: 'error' });
      return false;
    }

    setSeriesList(old => [...old, { serie, number }]);
    setSerie('');
    setNumber('');

    return true;
  }

  function handleRemoveSerie(index) {
    const aux = [...seriesList];
    const [removed] = aux.splice(index, 1);

    if (removed.id) setRemovedSeries(old => [...old, removed.id]);

    setSeriesList(aux);
  }

  function handleEditSerie(index) {
    const item = seriesList[index];
    setSerie(item.serie);
    setNumber(item.number);

    handleRemoveSerie(index);
  }

  function handleEditSerieList() {
    setUnchangedSeriesList(seriesList);
    setIsEdit(!isEdit);
  }

  function handleCancelEditSerieList() {
    setSeriesList(unchangedSeriesList);
    setRemovedSeries([]);
    setIsEdit(!isEdit);
  }

  return (
    <Card
      header={
        <Text color="#464E5F" type="header">
          Séries
        </Text>
      }
      HeaderRightComponent={
        <div>
          {isEdit && (
            <Button
              variant="secondary"
              onClick={() => handleCancelEditSerieList()}
              className="mr-2"
            >
              <Text weight={500} type="regular">
                Cancelar
              </Text>
            </Button>
          )}
          <Button
            variant={isEdit ? 'success' : 'secondary'}
            disabled={!canEditIssuers}
            onClick={() =>
              isEdit
                ? handleSave(
                    { list: seriesList, removed: removedSeries },
                    issuer.id,
                    fetchIssuers,
                    setIsEdit,
                    showSnackbar,
                    setLoading,
                    setErrors,
                    setUnchangedSeriesList
                  )
                : handleEditSerieList()
            }
          >
            <Text weight={500} type="regular">
              {isEdit ? 'Salvar' : 'Editar'}
            </Text>
          </Button>
        </div>
      }
    >
      {isEdit && (
        <Col>
          <Row className="d-flex align-items-end">
            <Col xs={4}>
              <Input
                label="Série *"
                value={serie}
                onChange={event => setSerie(event.target.value)}
                error={errors.serie}
              />
            </Col>
            <Col xs={4}>
              <Input
                label="Número *"
                value={number}
                onChange={event => setNumber(event.target.value)}
                error={errors.number}
              />
            </Col>
            <Col xs={4}>
              <Button
                variant="primary"
                onClick={() => addSerie()}
                className="w-100"
              >
                <Text weight={600} type="regular">
                  Adicionar
                </Text>
              </Button>
            </Col>
          </Row>
        </Col>
      )}
      <Col>
        <Row>
          {seriesList?.length > 0 && (
            <Table responsive>
              <thead>
                <tr>
                  <th>
                    <Text type="label" weight="500">
                      Série
                    </Text>
                  </th>
                  <th className="text-center">
                    <Text type="label" weight="500">
                      Número
                    </Text>
                  </th>
                  <th className="text-center">
                    <Text type="label" weight="500">
                      Ação
                    </Text>
                  </th>
                </tr>
              </thead>
              <tbody>
                {seriesList.map((_serie, index) => (
                  <tr key={index}>
                    <td>
                      <Text type="regular" weight="400">
                        {_serie.serie}
                      </Text>
                    </td>
                    <td width="240" className="text-center">
                      <Text type="regular">{_serie.number}</Text>
                    </td>
                    <td width="120" className="text-center">
                      <Button
                        variant="primary"
                        size="sm"
                        className="mr-1"
                        onClick={() => handleEditSerie(index, _serie)}
                        disabled={!isEdit}
                      >
                        <FaPencilAlt />
                      </Button>
                      <Button
                        variant="error"
                        size="sm"
                        onClick={() => handleRemoveSerie(index)}
                        disabled={!isEdit}
                      >
                        <FaTrash />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Row>
      </Col>
    </Card>
  );
}
