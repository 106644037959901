import navRoutes from 'config/MenuNavConstants';

const getUserPermissions = () => {
  const userPermissions = JSON.parse(localStorage.getItem('permissions'))
  .reduce((acc, { active, slug }) => {
    if(active === true) {
      acc.push(slug)
    }
    return acc
  }, [])

  return userPermissions;
};

const flatRoutesPermissions = () => {
  const result = [];
  navRoutes.forEach(({ path, permissionRequired, children }) => {
    if (children) {
      children.forEach(item =>
        result.push({
          path: item.path,
          permissionRequired: item.permissionRequired,
        })
      );
    } else {
      result.push({
        path,
        permissionRequired,
      });
    }
  });
  return result;
};

export const verifyMostAcessedPermissions = mostAcessedRoutes => {
  const userPermissions = getUserPermissions();
  const routesPermissions = flatRoutesPermissions();
  const result = [];

  mostAcessedRoutes.forEach(route => {
    const validRoute = routesPermissions.some(item => {
      return (
        item.path === route.path &&
        userPermissions.includes(item.permissionRequired)
      );
    });
    if (validRoute) {
      result.push(route);
    }
  });

  return result;
};

