import React from 'react';
import { DetailsHeader } from '../styles';
import { formatDate, formatDateTime } from 'v3/utils/formatter';
import { Text } from 'v3/components';
import { usePolicyRegister } from '../../Register/context';

export function Header() {
  const { policy } = usePolicyRegister();
  return (
    <DetailsHeader>
      <div>
        <Text type="label" color="dark" weight={500}>
          {'Apólice'}
        </Text>
        <Text type="regular" color="dark" as="p">
          {policy?.name}
        </Text>
        <Text type="label" color="dark" weight={500}>
          {'Vencimento apólice'}
        </Text>
        <Text type="regular" color="dark" as="p">
          {formatDate(policy?.due_date, { isUTC: true })}
        </Text>
        <Text type="label" color="dark" weight={500}>
          {'Data última validação da apólice'}
        </Text>
        <Text type="regular" color="dark" as="p">
          {formatDateTime(policy?.last_revision_date)}
        </Text>
        <Text type="label" color="dark" weight={500}>
          {'Moeda'}
        </Text>
        <Text type="regular" color="dark" as="p">
          {policy?.currency?.label}
        </Text>
      </div>
      <div>
        <Text type="label" color="dark" weight={500}>
          {'CNPJs'}
        </Text>
        <div className="customersContainer">
          {policy?.customers?.map(customer => {
            return (
              <Text key={customer.id} type="regular" color="dark">
                <Text as="span" weight={500}>
                  {customer?.social_name?.toUpperCase()}
                </Text>{' '}
                - {customer?.document}
              </Text>
            );
          })}
        </div>
      </div>
    </DetailsHeader>
  );
}
