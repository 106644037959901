import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useParams } from 'react-router-dom';

import { useSnackbar } from 'v3/components/Snackbar';

import Input from 'v3/components/Input';
import Text from 'v3/components/Text';
import Radio from 'v3/components/Radio';

import { useClient } from '../context';
import { FaPencilAlt, FaTrash } from 'react-icons/fa';
import { Button } from 'v3/components';
import { isEmailValidRegex } from 'v3/utils/constants';

export default function ClientTracking() {
  const client = useClient();
  const params = useParams();
  const snackbar = useSnackbar();
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (!params.id) {
      client.setData({
        client: {
          ...client?.data?.client,
          sending_tracking: false,
          emails_tracking: '',
        },
      });
    } else {
      client.setTrackingEmailsList(
        client?.data?.client?.emails_tracking
          ? client?.data?.client?.emails_tracking?.split(',')
          : []
      );
    }
  }, []);

  function emailAlreadyInList(cEmail) {
    const isEmailAlreadyInList = client?.trackingEmailsList?.some(
      item => item === cEmail
    );
    return isEmailAlreadyInList;
  }

  function handleAddEmail() {
    if (client?.trackingEmailsList?.length === 10) {
      snackbar.show(
        <Text type="body" color="dark">
          Máximo de 10 emails por cliente!
        </Text>,
        {
          type: 'warning',
        }
      );
      setEmail('');
      return;
    }
    const checkIfEmailIsAlreadyInList = emailAlreadyInList(email);
    if (checkIfEmailIsAlreadyInList) {
      snackbar.show(
        <Text type="body" color="dark">
          Email já cadastrado!
        </Text>,
        {
          type: 'warning',
        }
      );
      setEmail('');
      return;
    }
    const emailContent = email.match(isEmailValidRegex);
    if (emailContent !== null) {
      client.setErrors(old => ({
        ...old,
        email: '',
      }));
      client.setTrackingEmailsList([...client?.trackingEmailsList, email]);
      client.setData({
        client: {
          ...client.data.client,
          emails_tracking: [...client?.trackingEmailsList, email].join(','),
        },
      });
      setEmail('');
    } else {
      client.setErrors(old => ({
        ...old,
        email: 'Formato de email inválido!',
      }));
    }
  }

  function handleDelete(item) {
    client.setTrackingEmailsList(
      client?.trackingEmailsList.filter(email => email !== item)
    );
    client.setData({
      client: {
        ...client.data.client,
        emails_tracking: [
          ...client?.trackingEmailsList.filter(email => email !== item),
        ].join(','),
      },
    });
  }

  function handleEditEmail(item) {
    handleDelete(item);
    setEmail(item);
  }

  return (
    <Row className="form">
      <Col xs={12} md={6}>
        <Radio.Group
          label="Recebe Tracking"
          onChange={({ target }) => {
            client.setData({
              client: { ...client.data.client, sending_tracking: target.value },
            });
          }}
          value={client.data?.client?.sending_tracking}
          horizontal
        >
          <Radio value>
            <Text color="dark" type="label">
              Sim
            </Text>
          </Radio>
          <Radio value={false}>
            <Text color="dark" type="label">
              Não
            </Text>
          </Radio>
        </Radio.Group>
      </Col>

      <Col xs={12} md={12}>
        <Input
          type="text"
          onChange={e => setEmail(e.target.value)}
          label="Email"
          error={
            client.errors.email || client.errors['tracking.emails_tracking']
          }
          value={email}
        />
        <Button
          type="primary"
          className="mt-2"
          onClick={handleAddEmail}
          disabled={!email.length}
        >
          <Text color="white" type="label">
            Confirmar
          </Text>
        </Button>
      </Col>
      <Col xs={12}>
        {client?.trackingEmailsList?.map(currentEmail => {
          return (
            <Row key={currentEmail}>
              <Col md={6}>{`Email: ${currentEmail}`}</Col>
              <Col className="d-flex justify-content-end align-items-center mb-3">
                {/* <Button
                  variant="secondary"
                  size="sm"
                  onClick={() => handleEditEmail(currentEmail)}
                >
                  <FaPencilAlt />
                </Button> */}
                <Button
                  variant="error"
                  size="sm"
                  className="mx-1"
                  onClick={() => handleDelete(currentEmail)}
                >
                  <FaTrash />
                </Button>
              </Col>
            </Row>
          );
        })}
      </Col>
    </Row>
  );
}
