import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Button, Input, Select, Text } from 'v3/components';

export function RegisterFilter({ onFilterChange, typeOptions }) {
  const [name, setName] = useState('');
  const [questionType, setQuestionType] = useState([]);

  function handleSearchType(search) {
    if (!search) {
      return typeOptions;
    }
    const parsed = search.toLowerCase();
    return typeOptions.filter(
      option => option.name.toLowerCase().search(parsed) >= 0
    );
  }

  function onApplyFilters() {
    onFilterChange({
      name,
      questionType,
    });
  }

  useEffect(() => {
    function onPressedKey(e) {
      if (e.key === 'Enter') {
        onApplyFilters();
      }
    }
    window.addEventListener('keyup', onPressedKey);
    return () => {
      window.removeEventListener('keyup', onPressedKey);
    };
  });
  return (
    <Row className="my-4">
      <Col md={5} sm={12}>
        <Input
          label="Titulo da Pergunta"
          value={name}
          maxLength={250}
          onChange={e => {
            setName(e.target.value);
          }}
        />
      </Col>
      <Col md={5} sm={12}>
        <Select.Async
          labelWhiteSpace="nowrap"
          label="Tipo de pergunta"
          modalHeading="Selecione um tipo de pergunta"
          modalBodyTitle="Tipos:"
          multiple
          horizontal
          onSearch={search => handleSearchType(search)}
          value={questionType}
          onChange={value => {
            if (value) {
              setQuestionType(value);
            } else {
              setQuestionType([]);
            }
          }}
          getOptionLabel={option => option?.name}
          getOptionValue={option => option?.id}
          options={typeOptions}
        />
      </Col>
      <Col md={2} sm={12} style={{ display: 'flex', alignItems: 'end' }}>
        <Button
          style={{ flex: 1 }}
          variant="success"
          onClick={() => onApplyFilters()}
        >
          <Text type="regular" weight={500}>
            Filtrar
          </Text>
        </Button>
      </Col>
    </Row>
  );
}
