import api from 'services/api';
import { validate } from '../shared/validator';

export async function handleCreation(
  data,
  setData,
  setErrors,
  showSnack,
  setLoading,
  setShowModal
) {
  const isValid = await validate(
    'allSchema',
    {
      data: {
        name: data.name,
        unit_measure: data.unit_measure,
        productNcm: data.productNcm,
        points: data.points,
        productType: data?.productType,
      },
    },
    setErrors
  );

  if (!isValid) {
    showSnack('Verifique os campos obrigatórios!', { type: 'error' });
    return false;
  }

  try {
    setLoading(true);
    const payload = {
      name: data.name,
      unit_measure: data.unit_measure.value,
      product_ncm_id: data.productNcm.id,
      ncm: data.ncm,
      points: data.points,
      product_type_id: data?.productType?.id,
    };

    const response = await api.post('v3/product/', {
      data: payload,
    });

    setData({ id: response.data.id });
    showSnack('Produto cadastrado', { type: 'success' });
    setLoading(false);
    setShowModal(true);
    return true;
  } catch (err) {
    setLoading(false);
    if (err.response) {
      const errors = err.response.data;
      setErrors(old => ({
        ...old,
        ...Object.fromEntries(
          errors?.map(error => [error?.field, error?.message])
        ),
      }));
    }

    showSnack('Erro ao cadastrar produto!', { type: 'error' });
    return false;
  }
}
