import { validatePermission } from 'actions';
import React, { useState, useEffect, useMemo, useContext } from 'react';
import { Button, Text } from 'v3/components';
import Card, { DefaultLoadingBodyComponent } from 'v3/components/Card';
import { useTravel } from 'v3/pages/Travel';
import { useSnackbar } from 'v3/components/Snackbar';
import api, { cooperplaceApi } from 'services/api';
import { ceilDecimals } from 'v3/utils/functions';
import { percentage } from 'utils/number';
import { BlockedTravelContext } from 'contexts/BlockedTravelProvider';
import { useModal } from 'hooks';
import { editLoadFreightSchema } from './validator';
import { LoadFreight } from './LoadFreight';
import { MarginLiberationModal } from './MarginLiberation';
import { HeaderButtonsContainer } from './styles';
import { MinimumMarginModal } from '..';

export default function LoadFreightValues({ valuesRef }) {
  const { isTravelBlocked, isTravelReproved, margins } =
    useContext(BlockedTravelContext);
  const snackbar = useSnackbar();
  const [hasPermissionToEdit, setPermissionToEdit] = useState();
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [balance, setBalance] = useState(null);
  const [advanceMoneyPercentage, setAdvanceMoneyPercentage] = useState(null);
  const [advanceMoneyValue, setAdvanceMoneyValue] = useState(null);
  const [shipperDiscountsExtraFields, setShipperDiscountsExtraFields] =
    useState(null);
  const [totalDiscounts, setTotalDiscounts] = useState(null);
  const [issueRate, setIssueRate] = useState(null);
  const [shipperTransferExtraFields, setShipperTransferExtraFields] =
    useState(null);
  const [totalShipperTransfer, setTotalShipperTransfer] = useState(null);
  const [negotiatorObs, setNegotiatorObs] = useState(null);
  const lowerMarginModal = useModal();

  const travel = useTravel();

  const editableTravelStatus = useMemo(() => {
    const editableStatus = [
      'ST2',
      'ST8',
      'ENDED_LOADING',
      'RELEASED_COLLECTION',
      'BLOCKED',
    ];
    const editable = travel?.statuses
      ?.filter(item => editableStatus.includes(item?.code))
      ?.map(status => status.id);
    return editable;
  }, [travel?.statuses]);

  function setDefaultValues() {
    const issue = travel?.data?.loads?.shipmentDiscounts.find(
      item => item.label.toLowerCase() === 'taxa de emissão'
    );
    setIssueRate(issue || null);
    setBalance(travel?.data?.balance?.toFixed(2));
    setAdvanceMoneyValue(
      percentage(
        travel?.data?.loads?.advanceMoneyPercentage?.toFixed(2),
        travel?.data?.balance
      )
    );
    setAdvanceMoneyPercentage(
      travel?.data?.loads?.advanceMoneyPercentage?.toFixed(2)
    );
    setShipperDiscountsExtraFields(
      travel?.data?.loads?.shipmentDiscounts
        .filter(item => item.label.toLowerCase() !== 'taxa de emissão')
        ?.map(item => {
          return { ...item, value: item?.value?.toFixed(2) };
        })
    );
    setTotalDiscounts(travel?.data?.loads?.totalShipmentDiscounts);
    setShipperTransferExtraFields(
      travel?.data?.loads?.shipmentTransfer?.map(item => {
        return { ...item, value: item?.value?.toFixed(2) };
      })
    );
    setTotalShipperTransfer(travel?.data?.loads?.totalShipmentTransfer);

    setNegotiatorObs(travel?.data?.negotiatorObservation);
  }
  useEffect(() => {
    if (travel) {
      setDefaultValues();
    }
  }, [
    travel?.data?.loads?.shipmentDiscounts,
    travel?.data?.balance,
    travel?.data?.loads?.totalShipmentDiscounts,
    travel?.data?.loads?.shipmentTransfer,
    travel?.data?.loads?.totalShipmentTransfer,
    travel?.data?.negotiatorObservation,
  ]);

  const margin = useMemo(() => {
    if (balance && travel?.data?.loads?.fareCompany) {
      return ceilDecimals(
        ((Number(travel?.data?.loads?.fareCompany) - Number(balance)) /
          Number(travel?.data?.loads?.fareCompany)) *
          100,
        2
      );
    }
    return 0;
  }, [
    balance,
    travel?.data?.loads?.fareCompany,
    travel?.margin,
    travel?.data?.loads?.advanceMoneyPercentage,
  ]);

  useEffect(() => {
    setPermissionToEdit(validatePermission('ALTERAR_VIAGEM'));
  }, []);

  async function validate(data, setErrors) {
    const errorList = {};
    try {
      await editLoadFreightSchema.validate(data, { abortEarly: false });
      setErrors({ ...errorList });
      return true;
    } catch (err) {
      if (err.inner) {
        err.inner.forEach(error => {
          errorList[error.path] = error.message;
        });
        setErrors({ ...errorList });
        return false;
      }
    }
    return false;
  }

  async function handleSubmit(submitOptions) {
    try {
      setLoading(true);
      if (
        !(await validate(
          {
            balance,
            negotiatorObs,
            advanceMoneyPercentage,
            advanceMoneyPercentageExteral:
              travel?.data?.loads?.advanceMoneyPercentageExteral,
            fleetVehicleType:
              travel?.data?.vehicle?.[0]?.vehicles?.fleetType?.name,
          },
          setErrors
        ))
      ) {
        snackbar.show(<Text>Verifique os campos obrigatórios.</Text>, {
          type: 'error',
        });
        return false;
      }
      const editedData = {
        balance: +balance,
        negotiator_observation: negotiatorObs,
        total_shipment_discounts: +totalDiscounts,
        total_shipment_transfer: +totalShipperTransfer,
        advance_money_percentage: +advanceMoneyPercentage,
        margin: +margin,
        benner_margin: margins?.benner_margin,
      };

      if (!submitOptions?.confirmMinimiumMargin) {
        const isMarginAbove = await travel.isMarginAbove({
          actualMargin: +margin,
          isFreightValues: travel?.data?.margin !== +margin,
        });

        if (isMarginAbove) {
          lowerMarginModal.open();
          return;
        }
      }

      await api.put(`travel-data/${travel.data.id}/freight-values`, editedData);
      let processedShipperTransferExtraFields = [];
      if (shipperTransferExtraFields) {
        const filtered = [];
        shipperTransferExtraFields.forEach(item => {
          if (item.delete && item.id) {
            filtered.push(item);
          } else if (!item.delete) {
            filtered.push(item);
          }
        });
        processedShipperTransferExtraFields = [...filtered]?.map(item => {
          return {
            ...item,
            label: item.label.trim(),
            load_id: travel?.data?.loads?.id,
          };
        });
      }
      let processedShipperDiscounts = [];
      if (shipperDiscountsExtraFields) {
        const filtered = [];
        shipperDiscountsExtraFields.forEach(item => {
          if (item.delete && item.id) {
            filtered.push(item);
          } else if (!item.delete) {
            filtered.push(item);
          }
        });
        processedShipperDiscounts = [...filtered]?.map(item => {
          return {
            ...item,
            label: item.label.trim(),
            load_id: travel?.data?.loads?.id,
          };
        });
        if (issueRate) {
          processedShipperDiscounts.push(issueRate);
        }
      }
      try {
        await cooperplaceApi.put(`/carg-shipment-discounts`, {
          discountsExtraFields: processedShipperDiscounts,
        });
        await cooperplaceApi.put('carg-shipment-transfers', {
          transferExtraFields: processedShipperTransferExtraFields,
        });
      } catch (error) {}
      await travel.fetchTravel();
      setEditing(false);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }
  const [openLiberationModal, setOpenLiberationModal] = useState(false);

  const shouldBeAbleToEditValues = useMemo(() => {
    let travelStatus = false;
    let withoutPending = false;
    if (editableTravelStatus?.includes(travel?.data?.statusId)) {
      travelStatus = true;
    }
    if (
      (travel?.data?.travelMarginLiberation?.length > 0 &&
        travel?.data?.travelMarginLiberation
          .map(item => {
            return {
              approval: item.approval,
              user_approval_id: item.user_approval_id,
            };
          })
          ?.every(item => item?.user_approval_id !== null)) ||
      travel?.data?.travelMarginLiberation?.length === 0
    ) {
      withoutPending = true;
    }
    return travelStatus && withoutPending;
  }, [travel?.data, editableTravelStatus]);

  function handleConfirmLowerMargin(value) {
    handleSubmit(value);
  }

  return (
    <>
      <MarginLiberationModal
        isOpen={openLiberationModal}
        onClose={() => setOpenLiberationModal(false)}
      />
      <MinimumMarginModal
        isOpen={lowerMarginModal.isOpen}
        onClose={lowerMarginModal.close}
        confirm={handleConfirmLowerMargin}
      />
      <Card
        ref={valuesRef}
        loading={travel.isLoading}
        LoadingBodyComponent={() => (
          <DefaultLoadingBodyComponent linesCount={4} perLine={2} />
        )}
        header={
          <Text color="dark" type="header">
            Composição de valores de frete
          </Text>
        }
        HeaderRightComponent={
          <HeaderButtonsContainer>
            {editing === false &&
              travel?.data?.travelStatus?.code !== 'ST7' && (
                <Button
                  onClick={() => setOpenLiberationModal(true)}
                  className="py-2"
                >
                  Liberação
                </Button>
              )}
            {(isTravelReproved || shouldBeAbleToEditValues) &&
              hasPermissionToEdit && (
                <div>
                  {editing && (
                    <Button
                      variant="secondary"
                      loading={loading}
                      className="mr-2"
                      onClick={() => {
                        setDefaultValues();
                        setEditing(false);
                      }}
                    >
                      <Text color="dark" type="regular" weight="500">
                        Cancelar
                      </Text>
                    </Button>
                  )}
                  <Button
                    disabled={
                      !travel.data?.driver ||
                      !travel.data?.vehicle?.[0] ||
                      (isTravelBlocked && !isTravelReproved) ||
                      (travel?.data?.policy?.id &&
                        travel.isDisabledGROAnalysis &&
                        !isTravelReproved) ||
                      advanceMoneyPercentage >
                        travel?.data?.loads?.advanceMoneyPercentageExternal
                    }
                    onClick={
                      editing ? () => handleSubmit() : () => setEditing(true)
                    }
                    variant={editing ? 'primary' : 'secondary'}
                    loading={loading}
                    className="py-2"
                  >
                    <Text
                      color={editing ? 'white' : 'dark'}
                      weight="500"
                      type="regular"
                    >
                      {editing ? 'Salvar' : 'Editar'}
                    </Text>
                  </Button>
                </div>
              )}
          </HeaderButtonsContainer>
        }
      >
        <LoadFreight
          load={travel?.data?.loads}
          travel={travel}
          editing={editing}
          errors={errors}
          setErrors={setErrors}
          balance={balance}
          setBalance={setBalance}
          advanceMoneyPercentage={advanceMoneyPercentage}
          setAdvanceMoneyPercentage={setAdvanceMoneyPercentage}
          advanceMoneyValue={advanceMoneyValue}
          setAdvanceMoneyValue={setAdvanceMoneyValue}
          margin={margin}
          totalDiscounts={totalDiscounts}
          setTotalDiscounts={setTotalDiscounts}
          setShipperDiscountsExtraFields={setShipperDiscountsExtraFields}
          shipperDiscountsExtraFields={shipperDiscountsExtraFields}
          issueRate={issueRate}
          totalShipperTransfer={totalShipperTransfer}
          setTotalShipperTransfer={setTotalShipperTransfer}
          shipperTransferExtraFields={shipperTransferExtraFields}
          setShipperTransferExtraFields={setShipperTransferExtraFields}
          negotiatorObs={negotiatorObs}
          setNegotiatorObs={setNegotiatorObs}
        />
      </Card>
    </>
  );
}
