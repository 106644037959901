import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { cooperplaceApi } from 'services/api';

import { Button, Modal, Text } from 'v3/components';
import { useSnackbar } from 'v3/components/Snackbar';

export function RequestLoad({
  loadId,
  show,
  onClose,
  fetchPreLoads = null,
}) {
  const [loading, setLoading] = useState(false);
  const snackbar = useSnackbar();

  function handleClose() {
    onClose();
  }

  async function handleSubmit() {
    try {
      setLoading(true);
      await cooperplaceApi.post(`loads/${loadId}/pre-travel`);
      snackbar.show(
        <Text>
          Pré carga solicitada com sucesso! Você será notificado quando o cadastro da pré
          carga for completado.
        </Text>,
        {
          type: 'success',
        }
      );
      if (fetchPreLoads !== null) {
        await fetchPreLoads();
      }
      setLoading(false);
      handleClose();
    } catch (error) {
      setLoading(false);
      snackbar.show(<Text>Erro ao solicitar pré carga</Text>, {
        type: 'error',
      });
      handleClose();
      return;
    }
  }

  return (
    <>
      <Modal
        size="lg"
        backdrop="static"
        show={show}
        closeButton={false}
        handleClose={handleClose}
        heading={
          <Text type="subheader" color="dark" weight="500">
            Solicitação da pré carga: {loadId}
          </Text>
        }
        body={
          <Row>
            <Col>
              <Text type="body" color="dark" weight="400" as={'p'}>
                Ao clicar em solicitar carga, ela será enviada para o PCP
                completar o cadastro. Deseja continuar?
              </Text>
              <Text type="body" color="dark" weight="400">
                Após o PCP completar a carga, você será notificado para que
                possa realizar o atendimento da mesma.
              </Text>
            </Col>
            <Col
              md={12}
              className="my-3 d-flex align-items-center justify-content-end"
              style={{ gap: '0.5rem' }}
            >
              <Button
                onClick={handleClose}
                loading={loading}
                variant={'secondary'}
              >
                <Text weight="500" type="regular">
                  Cancelar
                </Text>
              </Button>
              <Button
                onClick={() => {
                  handleSubmit();
                }}
                loading={loading}
                variant={'success'}
              >
                <Text weight="500" type="regular">
                  Solicitar pré carga
                </Text>
              </Button>
            </Col>
          </Row>
        }
      />
    </>
  );
}
