import React, { useState } from 'react';

import { Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { validatePermission } from 'actions/index';

import Modal from 'v3/components/Modal';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';

import { useCostCenter } from '../../shared/context';

function Created({ isOpen, onClose }) {
  const costCenter = useCostCenter();
  const history = useHistory();

  const [hasPermissionToCreate, setPermissionToCreate] = useState();

  useState(() => {
    setPermissionToCreate(validatePermission('CADASTRAR_CENTRO_CUSTO'));
  }, []);

  return (
    <>
      <Modal
        size="lg"
        show={isOpen}
        onHide={onClose}
        heading="Cadastro de centro de custo"
        body={
          <Row className="mx-0 mt-1">
            <Text weight="400" color="dark">
              Centro de custo {costCenter.data.name} cadastrado com sucesso!
            </Text>
          </Row>
        }
        footer={
          <Row md={12} xs={12} className="mr-3 mt-1">
            <Button
              variant="white"
              className="mr-2 mt-1"
              outlined
              size="sm"
              onClick={() => history.push('/centro-de-custo')}
            >
              <Text weight="500">Ver listagem</Text>
            </Button>

            <Button
              className="mr-2 mt-1"
              variant="primary"
              size="sm"
              onClick={() =>
                history.push(`/centro-de-custo/${costCenter?.data?.id}`)
              }
            >
              <Text weight="500">Ver centro de custo</Text>
            </Button>

            {hasPermissionToCreate && (
              <Button
                variant="success"
                className="mr-2 mt-1"
                size="sm"
                onClick={() => {
                  window.location.reload(false);
                }}
              >
                <Text weight="500">Novo centro de custo</Text>
              </Button>
            )}
          </Row>
        }
      />
    </>
  );
}

export default Created;
