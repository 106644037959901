import * as Yup from 'yup';
const moment = require('moment');
export function isBeforeThanStartOrigin(value) {
  if (value.startSchedule && value.date) {
    const startDate = moment(value.startSchedule, 'YYYY-MM-DDTHH:mm');
    const endDate = moment(value.date, 'YYYY-MM-DDTHH:mm');
    return startDate.isBefore(endDate);
  }
  return true;
}

export function isStartOriginAValidDate(value) {
  if (value && !moment(value, 'YYYY-MM-DDTHH:mm', true).isValid()) {
    return false;
  }
  return true;
}

export function isEndOriginAValidDate(value) {
  if (value && !moment(value, 'YYYY-MM-DDTHH:mm', true).isValid()) {
    return false;
  }
  return true;
}

// Destinations

export function isBeforeThanStartDest(value) {
  if (value.startSchedule && value.date) {
    const startDate = moment(value.startSchedule, 'YYYY-MM-DDTHH:mm');
    const endDate = moment(value.date, 'YYYY-MM-DDTHH:mm');
    return startDate.isBefore(endDate);
  }
  return true;
}

export function isStartDestAValidDate(value) {
  if (value && !moment(value, 'YYYY-MM-DDTHH:mm', true).isValid()) {
    return false;
  }
  return true;
}

export function isEndDestAValidDate(value) {
  if (value && !moment(value, 'YYYY-MM-DDTHH:mm', true).isValid()) {
    return false;
  }
  return true;
}

export function isDateInMoreThanMinutesFromNow(minutes, date) {
  const dateInformed = moment(date, 'YYYY-MM-DDTHH:mm');
  const minimumDate = moment().add(minutes, 'minutes');
  return !date || dateInformed.diff(minimumDate) > 0;
}

export const RequestLoadSchema = Yup.object().shape({
  shippingCompanyId: Yup.number()
    .nullable()
    .required('Informe uma transportadora'),
  riskManagerId: Yup.number().required('Informe uma gerenciadora de risco'),
  driverId: Yup.number().required('Informe um motorista'),
  mainId: Yup.number().required('Informe um veículo'),
  freight: Yup.string().nullable().required('Informe valor de frete'),
  firstId: Yup.number()
    .nullable()
    .when(['secondId', 'thirdId'], {
      is: (secondId, thirdId) => secondId || thirdId,
      then: Yup.number().required('Informe primeiro implemento'),
      otherwise: Yup.number().nullable().optional(),
    }),
  secondId: Yup.number()
    .nullable()
    .when('thirdId', {
      is: val => val,
      then: Yup.number().required('Informe segundo implemento'),
      otherwise: Yup.number().nullable().optional(),
    }),
  thirdId: Yup.number().nullable().optional(),
});

export const DataSchema = Yup.object().shape({
  loadCreationNumber: Yup.number().required('Campo obrigatório'),
  taker: Yup.object()
    .required('Cliente Tomador obrigatório')
    .typeError('Cliente Tomador obrigatório'),
  costCenter: Yup.object()
    .required('Centro de custo obrigatório')
    .typeError('Centro de custo inválido'),
  product: Yup.object()
    .required('Produto obrigatório')
    .typeError('Produto obrigatório'),
  branchOffice: Yup.object()
    .required('Filial é obrigatória')
    .typeError('Filial é obrigatória'),
  takerValue: Yup.string().required('Valor de frete obrigatório'),
  currency: Yup.object().required('Moeda obrigatória'),
  dueDate: Yup.string()
    .test('not-empty', 'Informe uma data', value => {
      if (!value) {
        return false;
      }
      return true;
    })
    .test('valid-date', 'Formato inválido', value => {
      return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/.test(value);
    })
    .test(
      'valid-future-date',
      'Data não pode ser anterior/igual à data de hoje',
      value => {
        if (!value) {
          return false;
        }

        const inputDate = new Date(value);
        const currentDate = new Date();
        return inputDate > currentDate;
      }
    ),

  vehicleTypes: Yup.array()
    .of(Yup.object())
    .min(1, 'Selecione pelo menos um veículo!'),
  vehicleBodies: Yup.array()
    .of(Yup.object())
    .min(1, 'Selecione pelo menos um tipo de carroceria!'),
  vehicleImplements: Yup.mixed().when('vehicleTypes', {
    is: value => value.filter(e => e.id === 18).length > 0,
    then: Yup.array()
      .min(1, 'Selecione pelo menos um implemento!')
      .typeError('Selecione pelo menos um implemento!'),
    otherwise: Yup.mixed(),
  }),
  disposition: Yup.object().required('Disposição da carga obrigatório'),
  weight: Yup.number()
    .positive('Peso precisa ser um valor positivo')
    .required('Peso obrigatório'),

  searchDriverRadius: Yup.string().when('searchDriver', {
    is: true,
    then: Yup.string()
      .required('Informe um valor de raio')
      .max(500)
      .transform(value => {
        if (!value) {
          return undefined;
        }
        return value;
      }),
    otherwise: Yup.string().nullable(),
  }),
  origin: Yup.object()
    .shape({
      address: Yup.string()
        .required('Endereço obrigatório')
        .typeError('Endereço obrigatório'),
      cityModel: Yup.object()
        .required('Cidade obrigatória')
        .typeError('Cidade obrigatória'),
      province: Yup.string()
        .required('UF obrigatório')
        .typeError('UF obrigatório'),
      date: Yup.string()
        .required('Data máxima obrigatória')
        .typeError('Data máxima obrigatória')
        .test('is-end-a-valid-date', 'Data máxima da coleta inválida', value =>
          isEndOriginAValidDate(value)
        )
        .test(
          'is-end-date-more-than-30-mins-from-now',
          'Data máxima da coleta precisa ser no mínimo daqui a 30 minutos',
          value => isDateInMoreThanMinutesFromNow(30, value)
        ),
      country: Yup.object()
        .required('País obrigatório')
        .typeError('País inválido'),
      zipCode: Yup.string(),
      startSchedule: Yup.string()
        .required('Data início obrigatória')
        .typeError('Data início inválida')
        .test('is-start-a-valid-date', 'Data de início inválida', value =>
          isStartOriginAValidDate(value)
        )
        .test(
          'is-end-date-more-than-30-mins-from-now',
          'Data máxima da coleta precisa ser no mínimo daqui a 30 minutos',
          value => isDateInMoreThanMinutesFromNow(30, value)
        ),
      lat: Yup.number()
        .required('Selecione um logradouro utilizando o seletor do google')
        .transform((currentValue, originalValue) => {
          return originalValue === '' ? undefined : currentValue;
        }),

      lng: Yup.number()
        .required('Selecione um logradouro utilizando o seletor do google')
        .transform((currentValue, originalValue) => {
          return originalValue === '' ? undefined : currentValue;
        }),
    })
    .test(
      'is-before-than-start-schedule',
      'Data início da coleta não pode ser maior que Data máxima da coleta',
      value => isBeforeThanStartOrigin(value)
    ),

  destinations: Yup.array().of(
    Yup.object()
      .shape({
        address: Yup.string()
          .required('Endereço obrigatório')
          .typeError('Endereço inválido'),
        cityModel: Yup.object()
          .required('Cidade obrigatória')
          .typeError('Cidade inválida'),
        province: Yup.string()
          .required('Estado obrigatório')
          .typeError('Estado inválido'),
        date: Yup.string()
          .required('Data máxima obrigatória')
          .typeError('Data máxima inválida')
          .test('is-end-a-valid-date', 'Data máxima inválida', value =>
            isEndDestAValidDate(value)
          )
          .test(
            'is-end-date-more-than-30-mins-from-now',
            'Data máxima precisa ser no mínimo daqui a 30 minutos',
            value => isDateInMoreThanMinutesFromNow(30, value)
          ),
        type: Yup.object()
          .required('Ação obrigatório')
          .typeError('Ação inválida'),
        country: Yup.object()
          .required('País obrigatório')
          .typeError('País inválido'),
        startSchedule: Yup.string()
          .required('Data início obrigatória')
          .typeError('Data início inválida')
          .test(
            'is-start-a-valid-date',
            'Data de início inválida',
            isStartDestAValidDate
          )
          .test(
            'is-start-schedule-more-than-30-mins-from-now',
            'Data início precisa ser no mínimo daqui a 30 minutos',
            value => isDateInMoreThanMinutesFromNow(30, value)
          ),
        lat: Yup.number()
          .required('Selecione um logradouro utilizando o seletor do google')
          .transform((currentValue, originalValue) => {
            return originalValue === '' ? undefined : currentValue;
          }),

        lng: Yup.number()
          .required('Selecione um logradouro utilizando o seletor do google')
          .transform((currentValue, originalValue) => {
            return originalValue === '' ? undefined : currentValue;
          }),
      })
      .test(
        'is-before-than-start-schedule',
        'Data início não pode ser maior que Data máxima',
        value => isBeforeThanStartDest(value)
      )
  ),
});

export const OriginSchema = Yup.object().shape({
  origin: Yup.object()
    .shape({
      address: Yup.string()
        .required('Endereço obrigatório')
        .typeError('Endereço obrigatório'),
      cityModel: Yup.object()
        .required('Cidade obrigatória')
        .typeError('Cidade obrigatória'),
      province: Yup.string()
        .required('UF obrigatório')
        .typeError('UF obrigatório'),
      date: Yup.string()
        .required('Data máxima obrigatória')
        .typeError('Data máxima obrigatória')
        .test('is-end-a-valid-date', 'Data máxima da coleta inválida', value =>
          isEndOriginAValidDate(value)
        )
        .test(
          'is-end-date-more-than-30-mins-from-now',
          'Data máxima da coleta precisa ser no mínimo daqui a 30 minutos',
          value => isDateInMoreThanMinutesFromNow(30, value)
        ),
      country: Yup.object()
        .shape({
          id: Yup.number(),
        })
        .required('País obrigatório')
        .typeError('País inválido'),
      zipCode: Yup.string(),
      startSchedule: Yup.string()
        .required('Data início obrigatória')
        .typeError('Data início inválida')
        .test('is-start-a-valid-date', 'Data de início inválida', value =>
          isStartOriginAValidDate(value)
        )
        .test(
          'is-end-date-more-than-30-mins-from-now',
          'Data máxima da coleta precisa ser no mínimo daqui a 30 minutos',
          value => isDateInMoreThanMinutesFromNow(30, value)
        ),
      lat: Yup.number()
        .required('Selecione um logradouro utilizando o seletor do google')
        .transform((currentValue, originalValue) => {
          return originalValue === '' ? undefined : currentValue;
        }),

      lng: Yup.number()
        .required('Selecione um logradouro utilizando o seletor do google')
        .transform((currentValue, originalValue) => {
          return originalValue === '' ? undefined : currentValue;
        }),
    })
    .test(
      'is-before-than-start-schedule',
      'Data início da coleta não pode ser maior que Data máxima da coleta',
      value => isBeforeThanStartOrigin(value)
    ),
});

export const DestinationsSchema = Yup.object().shape({
  destinations: Yup.array().of(
    Yup.object()
      .shape({
        address: Yup.string()
          .required('Endereço obrigatório')
          .typeError('Endereço inválido'),
        cityModel: Yup.object()
          .required('Cidade obrigatória')
          .typeError('Cidade inválida'),
        province: Yup.string()
          .required('Estado obrigatório')
          .typeError('Estado inválido'),
        date: Yup.string()
          .required('Data máxima obrigatória')
          .typeError('Data máxima inválida')
          .test('is-end-a-valid-date', 'Data máxima inválida', value =>
            isEndDestAValidDate(value)
          )
          .test(
            'is-end-date-more-than-30-mins-from-now',
            'Data máxima precisa ser no mínimo daqui a 30 minutos',
            value => isDateInMoreThanMinutesFromNow(30, value)
          ),
        type: Yup.object()
          .required('Ação obrigatório')
          .typeError('Ação inválida'),
        country: Yup.object()
          .required('País obrigatório')
          .typeError('País inválido'),
        startSchedule: Yup.string()
          .required('Data início obrigatória')
          .typeError('Data início inválida')
          .test(
            'is-start-a-valid-date',
            'Data de início inválida',
            isStartDestAValidDate
          )
          .test(
            'is-start-schedule-more-than-30-mins-from-now',
            'Data início precisa ser no mínimo daqui a 30 minutos',
            value => isDateInMoreThanMinutesFromNow(30, value)
          ),
        lat: Yup.number()
          .required('Selecione um logradouro utilizando o seletor do google')
          .transform((currentValue, originalValue) => {
            return originalValue === '' ? undefined : currentValue;
          }),

        lng: Yup.number()
          .required('Selecione um logradouro utilizando o seletor do google')
          .transform((currentValue, originalValue) => {
            return originalValue === '' ? undefined : currentValue;
          }),
      })
      .test(
        'is-before-than-start-schedule',
        'Data início não pode ser maior que Data máxima',
        value => isBeforeThanStartDest(value)
      )
  ),
});

export const PreLoadDataSchema = Yup.object().shape({
  taker: Yup.object()
    .required('Cliente Tomador obrigatório')
    .typeError('Cliente Tomador obrigatório'),
  costCenter: Yup.object()
    .required('Centro de custo obrigatório')
    .typeError('Centro de custo inválido'),
  product: Yup.object()
    .required('Produto obrigatório')
    .typeError('Produto obrigatório'),
  branchOffice: Yup.object()
    .required('Filial é obrigatória')
    .typeError('Filial é obrigatória'),
  takerValue: Yup.string().required('Valor de frete obrigatório'),
  currency: Yup.object().required('Moeda obrigatória'),
  dueDate: Yup.string()
    .nullable()
    .required('Informe uma data')
    .transform(original => {
      if (!original) {
        return undefined;
      }
      return original;
    })
    .test({
      name: 'dueDate',
      test(value, ctx) {
        if (!value) {
          return ctx.createError({ message: 'Informe uma data!' });
        }
        let isValidDate =
          /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/.test(value) ||
          /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/.test(value);
        if (!isValidDate) {
          return ctx.createError({ message: 'Formato inválido' });
        }
        const inputDate = moment(value, 'YYYY-MM-DDTHH:mm');
        const currentDate = moment(new Date(), 'YYYY-MM-DDTHH:mm');
        if (inputDate.isBefore(currentDate)) {
          return ctx.createError({
            message: 'Data não pode ser anterior/igual à data de hoje',
          });
        }
        return true;
      },
    }),
});

export const VehicleDataSchema = Yup.object().shape({
  vehicleTypes: Yup.array()
    .of(Yup.object())
    .min(1, 'Selecione pelo menos um veículo!'),
  vehicleBodies: Yup.array()
    .nullable()
    .required('Selecione pelo menos um tipo de carroceria!')
    .of(Yup.object())
    .min(1, 'Selecione pelo menos um tipo de carroceria!'),
  vehicleImplements: Yup.mixed().when('vehicleTypes', {
    is: value => value.filter(e => e.id === 18).length > 0,
    then: Yup.array()
      .min(1, 'Selecione pelo menos um implemento!')
      .typeError('Selecione pelo menos um implemento!'),
    otherwise: Yup.mixed(),
  }),
  disposition: Yup.object().required('Disposição da carga obrigatório'),
  weight: Yup.number()
    .positive('Peso precisa ser um valor positivo')
    .required('Peso obrigatório'),
});
