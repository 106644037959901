import { cnpj } from 'cpf-cnpj-validator';

const Yup = require('yup');

const schema = Yup.object().shape({
  name: Yup.string().required('Nome obrigatório'),
  document: Yup.string()
    .test('invalid-document', 'Documento inválido', document =>
      document ? cnpj.isValid(document) : false
    )
    .required('Documento obrigatório'),
  ie: Yup.string().required('Inscrição estadual obrigatória'),
  trading_name: Yup.string().required('Nome fantasia obrigatório.'),
  rntrc: Yup.string()
    .matches(/[0-9]{8}/, {
      message: 'RNTRC precisa ser numérico e no minimo 8 dígitos.',
    })
    .required('RNTRC obrigatório.'),
  phone: Yup.string().required('Telefone obrigatório'),
  city: Yup.string().required('Cidade obrigatória').nullable(),
  postal_code: Yup.string().required('CEP obrigatório'),
  address: Yup.string().required('Endereço obrigatório'),
  neighborhood: Yup.string(),
  complement: Yup.string(),
  series: Yup.array()
    .of(
      Yup.object().shape({
        serie: Yup.number(),
        number: Yup.number(),
      })
    )
    .min(1, 'Criei pelo menos uma série de CTe para o seu emissor.'),
  mdfe_series: Yup.array()
    .of(
      Yup.object().shape({
        series: Yup.number(),
        number: Yup.number(),
      })
    )
    .min(1, 'Criei pelo menos uma série de MDFe para o seu emissor.'),
});

export default schema;
