import React, { useState, useEffect, Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { validatePermission } from 'actions/index';
import useWindowResize from 'hooks/useWindowResize';

import ApplicationLayout from 'v3/layouts/Application';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';

import Section from '../shared/Section';
import Route from '../shared/Route';
import Charge from '../shared/Charge';
import Data from '../shared/Data';
import Taxes from '../shared/Taxes';

import { useRoute } from '../shared/context';
import { findBelowPriceItems } from '../shared/utils';
import RegisteredRouteModal from './RegisteredRouteModal';
import { handleCreation } from './controller';

import { Map, GoogleApiWrapper } from 'google-maps-react';

import * as Styled from './styles';

import ConfirmBelowPrice from '../shared/ConfirmBelowPrice';

export default function RouteRegister() {
  const history = useHistory();
  const route = useRoute();

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [hasPermissionToCreate, setPermissionToCreate] = useState(true);
  const [saveContainerOffset, setSaveContainerOffset] = useState(0);

  const [belowMinimumFreightTablePrice, setBelowMinimumFreightTablePrice] =
    useState(false);
  const [belowPriceItems, setBelowPriceItems] = useState('');

  function handleRegister() {
    setBelowPriceItems('');
    let items = findBelowPriceItems(route);
    setBelowPriceItems(items);

    if (route?.data?.charge_type?.value === 3) {
      if (
        route?.data?.loading_charge <
          route?.minimumFreightTableItem?.charge_price ||
        route?.data?.discharge_price <
          route?.minimumFreightTableItem?.charge_price ||
        route?.data?.charge_price <
          Number(route?.data?.distance * route?.minimumFreightTableItem?.value)
      ) {
        setBelowMinimumFreightTablePrice(true);
      } else {
        setBelowMinimumFreightTablePrice(false);
        handleCreation(
          route.data,
          route.minimumFreightTableItem,
          route.setData,
          route.setErrors,
          route.showSnackbar,
          setLoading,
          setShowModal
        );
      }
    } else if (route?.data?.charge_type?.value === 1) {
      if (
        route?.data?.loading_charge <
          route?.minimumFreightTableItem?.charge_price ||
        route?.data?.discharge_price <
          route?.minimumFreightTableItem?.charge_price ||
        Number(route?.data?.charge_price) <
          route?.minimumFreightTableItem?.value
      ) {
        setBelowMinimumFreightTablePrice(true);
      } else {
        setBelowMinimumFreightTablePrice(false);
        handleCreation(
          route.data,
          route.minimumFreightTableItem,
          route.setData,
          route.setErrors,
          route.showSnackbar,
          setLoading,
          setShowModal
        );
      }
    } else {
      handleCreation(
        route.data,
        route.minimumFreightTableItem,
        route.setData,
        route.setErrors,
        route.showSnackbar,
        setLoading,
        setShowModal,
      );
    }
  }

  useWindowResize(() => {
    const header = document.getElementById('header-container');
    const originDistanceToRight =
      window.pageXOffset + header.getBoundingClientRect().right;
    const headerWidth = header.getBoundingClientRect().width;

    const SAVE_CONTAINER_PADDING = 0;
    const distanceToRight =
      originDistanceToRight - headerWidth + SAVE_CONTAINER_PADDING;
    setSaveContainerOffset(distanceToRight);
  }, []);

  useEffect(() => {
    function validateUserPermission() {
      setPermissionToCreate(validatePermission('CADASTRAR_ROTA'));
    }
    validateUserPermission();
  }, []);

  useEffect(() => {
    if (!hasPermissionToCreate) {
      route.showSnackbar('Você não tem permissão para criar rota', {
        type: 'error',
      });
      history.push('/');
    }
  }, [hasPermissionToCreate]);
  return (
    <>
      <RegisteredRouteModal show={showModal} />
      <ApplicationLayout
        title="Cadastrar rota"
        RightComponent={
          <Styled.SaveContainer style={{ right: saveContainerOffset }}>
            <Button variant="secondary" onClick={() => history.push('/rotas')}>
              <Text weight={500} color="black" type="regular">
                Voltar
              </Text>
            </Button>

            <Button
              variant="success"
              onClick={() => {
                handleRegister();
              }}
              loading={isLoading}
            >
              <Text weight={500} color="white" type="regular">
                Salvar
              </Text>
            </Button>
          </Styled.SaveContainer>
        }
      >
        <Row>
          {belowMinimumFreightTablePrice ? (
            <ConfirmBelowPrice
              data={route.data}
              setData={route.setData}
              setErrors={route.setErrors}
              showSnackbar={route.showSnackbar}
              setLoading={setLoading}
              setShowModal={setShowModal}
              setBelowMinimumFreightTablePrice={
                setBelowMinimumFreightTablePrice
              }
              minimumFreightTableItem={route?.minimumFreightTableItem}
              belowPriceItems={belowPriceItems}
              taxes={false}
              charge={false}
            />
          ) : null}
          <Col xs={12} md={6}>
            <Section Title="Informações básicas">
              <Data />
            </Section>
            <Section Title="Cobrança">
              <Charge />
            </Section>
          </Col>
          <Col xs={12} md={6}>
            <Section Title="Rota">
              <Route />
            </Section>
            <Section Title="Taxas e encargos">
              <Taxes />
            </Section>
          </Col>
        </Row>
      </ApplicationLayout>
    </>
  );
}
