import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Text from 'v3/components/Text';
import { useSnackbar } from 'v3/components/Snackbar';
import api from 'services/api';
import { formatCurrency } from 'v3/utils/formatter';
import { useRoute } from '../shared/context';
import { validate } from '../shared/validator';
import SharedData from '../shared/Section';
import Inputs from '../shared/Taxes';
import Editable from '../shared/Editable';

import { currency, findBelowPriceItems } from '../shared/utils';
import ConfirmBelowPrice from '../shared/ConfirmBelowPrice';

import { handleEdit } from './utils';
import { currencyWithSymbol } from 'v3/utils/formatter';

export default function RouteTaxes() {
  const route = useRoute();
  const params = useParams();
  const snackbar = useSnackbar();
  const [isEditing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  const [belowMinimumFreightTablePrice, setBelowMinimumFreightTablePrice] =
    useState(false);
  const [belowPriceItems, setBelowPriceItems] = useState('');

  const [routeCurrency, setRouteCurrency] = useState('');
  const [routeCurrencySymbol, setRouteCurrencySymbol] = useState('');
  useEffect(() => {
    if (route.data.currency) {
      setRouteCurrency(route.data.currency.value || route.data.currency);
      let tempCurrency = route.data.currency.value || route.data.currency;
      setRouteCurrencySymbol(
        currencyWithSymbol.find(currency => currency.currency === tempCurrency)
          ?.symbol
      );
    }
  }, [route.data?.currency]);

  useEffect(() => {
    if (!params.id)
      route.setData({
        gris: null,
        ad_valorem: null,
        discharge_price: null,
        toll_value: null,
        loading_charge: null,
      });
  }, []);

  async function saveData() {
    try {
      setLoading(true);

      const isValid = await validate(
        'taxes',
        { taxes: route.data },
        route.setErrors
      );

      if (!isValid) {
        snackbar.show(<Text>Verifique os campos obrigatórios</Text>, {
          type: 'error',
        });
        setLoading(false);
        return;
      }

      const payload = {
        insurance_value: +route.data.insurance_value,
        gris: +route.data.gris,
        ad_valorem: +route.data.ad_valorem,
        discharge_price: +route.data.discharge_price,
        toll_value: +route.data.toll_value,
        is_toll_payer_client: route.data?.is_toll_payer_client,
        loading_charge: +route?.data?.loading_charge,
        parameters: route.data?.parameters,
      };

      api.put(`v3/route/${params.id}/taxes`, payload);

      snackbar.show(<Text>Salvo com sucesso</Text>, { type: 'success' });
      route.updatePrevRoute();

      setEditing(false);
    } catch (error) {
      snackbar.show(<Text>Erro ao atualizar valores.</Text>, { type: 'error' });
    } finally {
      setLoading(false);
    }
  }

  function manageEdit() {
    setBelowPriceItems('');
    let items = findBelowPriceItems(route);
    setBelowPriceItems(items);
    handleEdit(route, setBelowMinimumFreightTablePrice, saveData);
  }

  return (
    <SharedData
      Title="Taxas e encargos"
      HeaderRightComponent={
        <Editable
          isEditing={isEditing}
          setEditing={setEditing}
          onSave={manageEdit}
          isLoading={loading}
        />
      }
      loading={route.isFetching}
    >
      {belowMinimumFreightTablePrice ? (
        <ConfirmBelowPrice
          setBelowMinimumFreightTablePrice={setBelowMinimumFreightTablePrice}
          charge={false}
          taxes={true}
          saveData={saveData}
          belowPriceItems={belowPriceItems}
        />
      ) : null}
      {isEditing ? (
        <Inputs />
      ) : (
        <Row>
          <Col md={6} xs={12}>
            <Text type="label" color="#464E5F">
              Seguro
            </Text>
            {route.data?.parameters?.insurance_type === 0 ? (
              <>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {route?.data?.insurance_value >= 0
                    ? route?.data?.insurance_value
                    : 'Não informado'}
                  {route?.data?.insurance_value >= 0 && '%'}
                </Text>
              </>
            ) : (
              <Text type="regular" color="gray" as="p" className="mt-2">
                {routeCurrency
                  ? formatCurrency(
                      +route?.data?.insurance_value,
                      routeCurrency,
                      routeCurrencySymbol
                    )
                  : 'Não informado'}
              </Text>
            )}
          </Col>
          <Col md={6} xs={12}>
            <Text type="label" color="#464E5F">
              GRIS
            </Text>
            {route.data?.parameters?.gris_type === 0 ? (
              <>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {route?.data?.gris >= 0 ? route?.data?.gris : 'Não informado'}
                  {route?.data?.gris >= 0 && '%'}
                </Text>
              </>
            ) : (
              <Text type="regular" color="gray" as="p" className="mt-2">
                {routeCurrency
                  ? formatCurrency(
                      +route?.data?.gris,
                      routeCurrency,
                      routeCurrencySymbol
                    )
                  : 'Não informado'}
              </Text>
            )}
          </Col>

          <Col md={6} xs={12}>
            <Text type="label" color="#464E5F">
              ADVALOREM
            </Text>
            {route.data?.parameters?.advalorem_type === 0 ? (
              <>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {route?.data?.ad_valorem >= 0
                    ? route?.data?.ad_valorem
                    : 'Não informado'}
                  {route?.data?.ad_valorem >= 0 && '%'}
                </Text>
              </>
            ) : (
              <Text type="regular" color="gray" as="p" className="mt-2">
                {routeCurrency
                  ? formatCurrency(
                      +route?.data?.ad_valorem,
                      routeCurrency,
                      routeCurrencySymbol
                    )
                  : 'Não informado'}
              </Text>
            )}
          </Col>

          <Col md={6} xs={12}>
            <Text type="label" color="#464E5F">
              Descarga
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {routeCurrency
                ? formatCurrency(
                    +route?.data?.discharge_price,
                    routeCurrency,
                    routeCurrencySymbol
                  )
                : 'Não informado'}
            </Text>
          </Col>

          <Col md={6} xs={12}>
            <Text type="label" color="#464E5F">
              Pedágios
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {formatCurrency(
                +route?.data?.toll_value,
                currency?.currency,
                currency?.currency_symbol
              ) || 'Não informado'}
            </Text>
          </Col>

          <Col md={6} xs={12}>
            <Text type="label" color="#464E5F">
              Taxa de carregamento
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {routeCurrency
                ? formatCurrency(
                    +route?.data?.loading_charge,
                    routeCurrency,
                    routeCurrencySymbol
                  )
                : 'Não informado'}
            </Text>
          </Col>
          <Col md={6} xs={12}>
            <Text type="label" color="#464E5F">
              Cliente paga pedágio
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {route?.data?.is_toll_payer_client ? 'Sim' : 'Não'}
            </Text>
          </Col>
        </Row>
      )}
    </SharedData>
  );
}
