import React, { useCallback, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { FaUnlink } from 'react-icons/fa';
import api from 'services/api';
import {
  useEffectThrottled,
  useModal,
  usePagination,
  useMemoThrottled,
} from 'hooks';
import { Text, DataTable, Button, Fallback } from 'v3/components';
import { useSnackbar } from 'v3/components/Snackbar';
import AttachClientModal from '../Modals/AttachClientModal';
import theme from '../../../../theme';
import { useCompany } from '../context';
import Section from '../Section';

export default function Client() {
  const company = useCompany();
  const params = useParams();
  const snackbar = useSnackbar();

  const isEditing = !!params.id;

  const onManualSearch = useCallback(
    (search, item) =>
      item?.cgccpf.includes(search || '') ||
      item?.social_name?.toLowerCase().includes(search?.toLowerCase() || ''),
    []
  );

  const pagination = usePagination({
    data: company.data.clients,
    manual: !isEditing,
    onManualSearch,
  });
  const modal = useModal();

  const [isFetching, setFetching] = useState(false);
  const [isFetched, setFetched] = useState(false);

  async function fetchClients(currentPage = pagination.currentPage) {
    try {
      setFetching(true);

      const { data: response } = await api.get(
        `v3/company/${params.id}/clients`,
        {
          params: {
            search: pagination.search,
            perPage: pagination.perPage,
            page: currentPage,
          },
        }
      );

      pagination.setPerPage(response.perPage);
      pagination.setLastPage(response.lastPage);
      pagination.setTotal(response.total);

      const assertCurrentPage =
        response.lastPage && response.lastPage < currentPage
          ? response.lastPage
          : currentPage;
      pagination.setCurrentPage(assertCurrentPage);

      company.setData({
        clients: response.data,
      });
      company.setPrevCompany(prev => ({ ...prev, clients: response.data }));
    } catch (error) {
      snackbar.show(
        <Text>Algo deu errado ao listar os clientes vinculados.</Text>,
        { type: 'error' }
      );
    } finally {
      setFetching(false);
      setFetched(true);
    }
  }

  useEffectThrottled(
    () => {
      if (isEditing) {
        fetchClients();
      }
    },
    [isEditing, pagination.perPage, pagination.search, pagination.currentPage],
    300
  );

  async function dettachClient(clientId) {
    try {
      await api.delete(`v3/company/${params.id}/client/${clientId}`);
      snackbar.show(<Text>Cliente desvinculado com sucesso.</Text>, {
        type: 'success',
      });

      const isLastItem = pagination.data.length === 1;

      fetchClients(
        isLastItem ? pagination.currentPage - 1 : pagination.currentPage
      );
    } catch (ex) {
      snackbar.show(<Text>Não foi possível desvincular esse cliente.</Text>, {
        type: 'error',
      });
    }
  }

  function removeClient(clientId) {
    company.setData({
      clients: company.data.clients.filter(client => client.id !== clientId),
    });
  }

  function handleDettachClients(clientId) {
    if (isEditing) {
      dettachClient(clientId);
    } else {
      removeClient(clientId);
    }
  }

  const isEmpty = useMemoThrottled(
    () => {
      const defaultCondition =
        pagination.data.length === 0 &&
        pagination.currentPage === 1 &&
        !pagination.search;

      if (isEditing) {
        return isFetched && defaultCondition;
      }

      return defaultCondition;
    },
    [pagination.data, pagination.currentPage, pagination.search, isFetched],
    false
  );

  return (
    <Section
      Title="Clientes"
      HeaderRightComponent={
        <>
          {!isEmpty && (
            <Button
              onClick={modal.open}
              className="py-2"
              disabled={!company.hasPermissionToEdit}
            >
              <Text type="regular" weight="500" color="white">
                Adicionar
              </Text>
            </Button>
          )}
          <AttachClientModal
            isOpen={modal.isOpen}
            onClose={modal.close}
            fetchClients={fetchClients}
          />
        </>
      }
    >
      <Row>
        <Col>
          <Fallback
            fall={isEmpty}
            component={
              <Col
                xs={12}
                className="flex-column align-items-center d-flex justify-content-center my-2"
              >
                <Text color={theme.colors.gray} type="regular">
                  Não há clientes cadastrados para essa empresa.
                </Text>
                <Button
                  className="mt-3 py-2"
                  onClick={() => {
                    modal.open();
                  }}
                  disabled={!company.hasPermissionToEdit}
                >
                  <Text type="regular" weight="500" color="white">
                    Adicionar
                  </Text>
                </Button>
              </Col>
            }
          >
            <DataTable
              data={pagination.data}
              total={pagination.total}
              pages={pagination.lastPage}
              currentPage={pagination.currentPage}
              onPageChanged={pagination.setCurrentPage}
              onSearchChanged={pagination.setSearch}
              onPerPageChanged={pagination.setPerPage}
              loading={isFetching}
              searchPlaceholder="CNPJ ou Razão social"
              columns={[
                { name: 'Cliente', selector: row => row.social_name },
                { name: 'CPF/CNPJ', selector: row => row.cgccpf },
                {
                  name: 'Ação',
                  render: row => (
                    <Button
                      variant="error"
                      size="sm"
                      className="mx-1"
                      onClick={() => handleDettachClients(row.id)}
                      disabled={!company.hasPermissionToEdit}
                    >
                      <FaUnlink />
                    </Button>
                  ),
                },
              ]}
            />
          </Fallback>
        </Col>
      </Row>
    </Section>
  );
}
