import React, { useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useParams } from 'react-router-dom';

import Input from 'v3/components/Input';
import Radio from 'v3/components/Radio';
import Text from 'v3/components/Text';
import { useSnackbar } from 'v3/components/Snackbar';

import { useVehicle } from '../context';
import isDefined from '../../../../utils/isDefined';

export default function VehicleData() {
  const vehicle = useVehicle();
  const snackbar = useSnackbar();
  const params = useParams();

  useEffect(() => {
    if (!params.id)
      vehicle.setData({
        total_gross_weight: null,
        capacity: null,
        cubage: null,
        tare_mass: null,
        exclusive: false,
      });
    else if (!isDefined(vehicle.data.exclusive)) {
      vehicle.setData({
        exclusive: false,
      });
    }
  }, []);

  return (
    <Row className="form">
      <Col xs={12} md={6}>
        <Input
          label={
            params.id
              ? 'PBT (Peso bruto total) em kg *'
              : 'PBT (Peso bruto total) em kg'
          }
          type="number"
          value={vehicle.data.total_gross_weight}
          onChange={event =>
            event.target.value < 0
              ? snackbar.show(
                  <Text> Campo não aceita números negativos </Text>,
                  {
                    type: 'error',
                  }
                )
              : vehicle.setData({ total_gross_weight: event.target.value })
          }
          error={vehicle.errors.total_gross_weight}
          placeholder="25500"
        />
      </Col>
      <Col xs={12} md={6}>
        <Input
          label={params.id ? 'Tara em kg *' : 'Tara em kg'}
          type="number"
          value={vehicle.data.tare_mass}
          onChange={event =>
            event.target.value < 0
              ? snackbar.show(
                  <Text> Campo não aceita números negativos </Text>,
                  {
                    type: 'error',
                  }
                )
              : vehicle.setData({ tare_mass: event.target.value })
          }
          error={vehicle.errors.tare_mass}
          placeholder="17440"
        />
      </Col>
      <Col xs={12} md={6}>
        <Input
          label="Pallets"
          type="number"
          value={vehicle.data.capacity}
          onChange={event =>
            event.target.value < 0
              ? snackbar.show(
                  <Text> Campo não aceita números negativos </Text>,
                  {
                    type: 'error',
                  }
                )
              : vehicle.setData({ capacity: event.target.value })
          }
          error={vehicle.errors?.capacity}
          placeholder="32"
        />
      </Col>

      <Col xs={12} md={6}>
        <Input
          label="Cubagem em m³"
          type="number"
          value={vehicle.data.cubage}
          onChange={event =>
            event.target.value < 0
              ? snackbar.show(
                  <Text> Campo não aceita números negativos </Text>,
                  {
                    type: 'error',
                  }
                )
              : vehicle.setData({ cubage: event.target.value })
          }
          error={vehicle.errors?.cubage}
          placeholder="90"
        />
      </Col>

      <Col xs={12}>
        <Radio.Group
          label="Exclusivo"
          onChange={({ target }) =>
            vehicle.setData({ exclusive: target.value })
          }
          value={vehicle.data.exclusive}
          horizontal
        >
          <Radio value>
            <Text color="dark" type="label">
              Sim
            </Text>
          </Radio>
          <Radio value={false}>
            <Text color="dark" type="label">
              Não
            </Text>
          </Radio>
        </Radio.Group>
      </Col>
    </Row>
  );
}
