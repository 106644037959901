import React, { forwardRef } from 'react';

import Grid from '@material-ui/core/Grid';

import Label from 'components/Label';

import { StyledInput } from './styles';

function Input({ name, label, md, xs, item, error, disabled, ...props }, ref) {
  return (
    <>
      {item ? (
        <Grid md={md} xs={xs} item>
          <Label htmlFor={name} className="textLabel">
            {label}
          </Label>
          <StyledInput
            ref={ref}
            id={name}
            name={name}
            error={error}
            disabled={disabled}
            {...props}
          />
        </Grid>
      ) : (
        <>
          <Label htmlFor={name} className="textLabel">
            {label}
          </Label>
          <StyledInput
            ref={ref}
            id={name}
            name={name}
            error={error}
            disabled={disabled}
            {...props}
          />
        </>
      )}
    </>
  );
}

export default forwardRef(Input);
