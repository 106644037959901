import React, { useEffect } from 'react';

import {
  BsArrowUp,
  BsArrowDown,
  BsSearch,
  BsArrowUpDown,
} from 'react-icons/bs';

import { Input, Text, Button, Select } from 'v3/components';
import useModal from 'hooks/useModal';

import Modal from './Modal';

import { useRoute } from '../context';
import { GROUP } from '../utils';

function Filter() {
  const modal = useModal();
  const route = useRoute();

  useEffect(() => {
    route.setFilter(old => ({ ...old, group: GROUP[0], order: false }));
  }, []);

  useEffect(() => {
    function onPressedKey(e) {
      if (e.key === 'Enter') {
        route.fetchData();
      }
    }

    window.addEventListener('keyup', onPressedKey);

    return () => {
      window.removeEventListener('keyup', onPressedKey);
    };
  });

  return (
    <>
      <Modal isOpen={modal.isOpen} onClose={modal.close} />
      <div className="mt-5 row align-items-center justify-content-between">
        <div className="col-5 d-flex">
          <Input
            placeholder="Digite cliente/CNPJ ou cidade"
            icon={<BsSearch />}
            IconSpaceColor="#fff"
            value={route.filter.main}
            onChange={e =>
              route.setFilter(old => ({ ...old, main: e.target.value }))
            }
          />
          <Button
            className="ml-3 px-5"
            variant="success"
            onClick={() => route.fetchData()}
          >
            <Text type="regular" color="white" weight="500">
              Filtrar
            </Text>
          </Button>
        </div>
        <div className="col-4 d-flex align-items-center ml-2">
          <BsArrowUpDown color="white" size={40} className="mr-1" />

          <Text
            type="regular"
            color="white"
            weight="semiBold"
            className="w-90 mr-2"
          >
            ordenar por:
          </Text>

          <div className="col-8">
            <Select
              options={GROUP}
              value={route.filter.group}
              onChange={value => {
                route.setFilter(old => ({ ...old, group: value }));
                route.fetchData();
              }}
              getOptionLabel={option => option?.label}
              getOptionValue={option => option?.value}
            />
          </div>

          <Button
            style={{ height: 44, width: 60 }}
            className="p-0"
            variant="secondary"
            onClick={() => {
              route.setFilter(old => ({
                ...old,
                order: !route.filter.order,
              }));
              route.fetchData();
            }}
          >
            {route.filter.order ? (
              <BsArrowUp size={20} />
            ) : (
              <BsArrowDown size={20} />
            )}
          </Button>
        </div>
        <div className="mr-n2 col-2 float-right">
          <Button variant="secondary" onClick={() => modal.open()}>
            <Text type="regular" color="dark" weight="500">
              Filtro avançado
            </Text>
          </Button>
        </div>
      </div>
    </>
  );
}

export default Filter;
