import React, { useEffect } from 'react';

import { Row, Col } from 'react-bootstrap';

import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import Select from 'v3/components/Select';

import { YEARS_OPTIONS } from '../../shared/utils';

export default function Filter({fetchData, filter, setFilter}) {
  useEffect(() => {
    function onPressedKey(e) {
      if (e.key === 'Enter') {
        fetchData();
      }
    }

    window.addEventListener('keyup', onPressedKey);

    return () => {
      window.removeEventListener('keyup', onPressedKey);
    };
  });
  return (
    <Row className="d-flex align-items-center my-4">
      <Col>
        <Select
          label="Ano Base"
          labelTextColor="#fff"
          options={YEARS_OPTIONS}
          value={filter}
          onChange={(value)=>setFilter(value)}
        />
      </Col>
      <Col style={{ marginTop: '28px' }}>
        <Button variant="success" onClick={()=>fetchData(filter?.value)}>
          <Text type="regular" weight={500}>
            Filtrar
          </Text>
        </Button>
      </Col>
    </Row>
  );
}
