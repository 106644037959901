import styled from 'styled-components';
import { Divider } from '@mui/material';
import theme from 'v3/theme';
import { Carousel } from 'react-bootstrap';
import Tab from 'v3/components/Tab';
import { Button } from 'v3/components';

export const StyledModalButton = styled(Button)`
  border: 1px solid transparent !important;
  &:focus {
    border: 1px solid white !important;
    outline: 2px solid lightblue !important;
  }
`;

export const StyledTab = styled(Tab)`
  padding: 1rem 0.5rem;
`;

export const PolicyHeader = styled.header`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  & > div:not(.info) {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr minmax(272px, auto);
    gap: 0.5rem;
  }

  & > div.info {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr minmax(272px, auto) minmax(272px, auto);
    grid-column-gap: 0.5rem;
  }

  @media (max-width: 768px) {
    & > div:not(.info), & > div.info {
      display: grid;
      grid-template-columns: 1fr;
    }
  }
`;

export const RangeModalContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  margin: 1rem auto;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const StyledDivider = styled(Divider)`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 60%;
  margin: 0 auto !important;
`;

export const PolicyRangesContainer = styled.div`
  margin-top: 1rem;
`;

export const SectionHeader = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;

  /* & > div {
    display: flex;
    align-items: center;
    & > button {
      cursor: pointer;
      border: none;
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.25rem;
      & > svg {
        color: ${theme.colors.success};
      }
    }
    & > button:disabled {
      cursor: not-allowed;
      background: ${theme.colors.light_gray};
      opacity: 0.7;
    }
  } */
`;

export const InsertNewButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  cursor: pointer;
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  & > svg {
    color: ${theme.colors.success};
  }

  &:disabled {
    cursor: not-allowed;
    background: ${theme.colors.light_gray};
    opacity: 0.7;
  }
`;

export const SectionContentHeader = styled.header`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  & > div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.5rem;

    & .removeBtn,
    .editBtn {
      cursor: pointer;
      border: none;
      border-radius: 6px;
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.25rem;
      padding: 0.5rem;

      &:hover {
        background: ${theme.colors.light_gray};
      }
      &:disabled {
        cursor:not-allowed;
      }
      & > svg {
        width: 0.8rem;
        height: 0.8rem;
      }
    }

    & .removeBtn {
      & > svg {
        color: ${theme.colors.danger};
      }
    }
    & .editBtn {
      & > svg {
        color: ${theme.colors.info};
      }
    }
  }
`;

export const RangeDataContainer = styled.div`
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 0 0.5rem 0.5rem;
  & > header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .removeContainer {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      & > button {
        cursor: pointer;
        border: none;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.25rem;
        padding: 0.5rem;

        & > svg {
          color: ${theme.colors.danger};
        }
        &:hover {
          background: ${theme.colors.light_gray};
        }
      }
    }
  }

  & > div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const SecurityGeneralInfo = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  margin: 1rem 0;
  padding: 0.5rem;

  /* & > div:not(:last-child) {
    width: 288px;
  }

  & > div:last-child {
    flex: 1;
  } */
  .routeSection {
    display: flex;
    flex-direction: column;
    gap: 0.125rem;
    width: 100%;
    & > div > button {
      background: transparent;
      border: 0;
      & > span {
        text-decoration: underline;
      }
    }
  }

  @media (max-width: 992px) {
    flex-direction: column;
  }
`;

export const SectionContainer = styled.div`
  & > header {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    gap: 0.25rem;

    & > span {
      display: inline-block;
      width: 100%;
      border-bottom: 1px solid #494950;
    }

    & > button {
      border: 0;
      background: transparent;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const ToggleHeader = styled.header`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  gap: 0.25rem;

  & > span {
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid #494950;
  }

  & > button {
    border: 0;
    background: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const MonitoringEquipmentToggle = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  & > span {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #494950;
  }

  & > button {
    border: 0;
    background: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const RulesContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

export const SublimitRules = styled.div`
  flex: 1;
  padding: 0.5rem;
  border-radius: 6px;

  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  & > div {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 0.25rem;
  }
  @media (max-width: 768px) {
    & > div {
      grid-template-columns: 1fr;
    }
  }
`;

export const Restriction = styled.div`
  min-width: 260px;
  padding: 0.5rem;
  border-radius: 6px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;

export const MonitoringEquipmentContainer = styled.div`
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  gap: 0.5rem;

  /* & > div {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 0.25rem;

    & > div {
      max-width: 268px;
    }
  }
  @media (max-width: 992px) {
    & > div {
      grid-template-columns: repeat(2, 1fr);
      & > div {
        max-width: none;
      }
    }
  }
  @media (max-width: 768px) {
    & > div {
      grid-template-columns: 1fr;
      & > div {
        max-width: none;
      }
    }
  } */
`;

export const StyledCarousel = styled(Carousel)`
  .carousel-inner {
    padding: 0.5rem 1rem 0.5rem 1.5rem;
    overflow: visible;
  }
  .carousel-control-prev,
  .carousel-control-next {
    width: 0;
    & > button {
      background: transparent;
      &:hover:not(:disabled) {
        color: ${theme?.colors?.dark};
      }
      &:disabled {
        cursor: not-allowed;
      }
    }
  }
  .carousel-control-prev {
    margin-left: 0.5rem;
  }
  .carousel-control-next {
    margin-right: 0.5rem;
  }
`;

export const StyledCarouselItem = styled(Carousel.Item)`
  & > div > .groupContent {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 0.25rem;
    grid-row-gap: 0.25rem;
  }
  & > div > header {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    & > button {
      cursor: pointer;
      border: none;
      border-radius: 6px;
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.25rem;
      padding: 0.5rem;

      &:hover {
        background: ${theme.colors.light_gray};
      }
      & > svg {
        color: ${theme.colors.danger};
        width: 0.8rem;
        height: 0.8rem;
      }
    }
  }
  @media (max-width: 992px) {
    & > div > .groupContent {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (max-width: 768px) {
    & > div > .groupContent {
      grid-template-columns: 1fr;
    }
  }
`;

export const StyledTrackedContainer = styled.div`
  display: flex;
  & > label {
    width: fit-content !important;
  }
`;

export const ManagersContainer = styled.div`
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;

  & > div {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 0.5rem;
    grid-row-gap: 0.5rem;
  }
  @media (max-width: 992px) {
    & > div {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (max-width: 768px) {
    & > div {
      grid-template-columns: 1fr;
    }
  }
`;

export const GeneralItemsContainer = styled.div`
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  margin-top: 1rem;
  padding: 0.5rem 1rem;

  .textObs {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0.5rem;
    grid-row-gap: 1rem;
  }
`;

export const GeneralItems = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr minmax(320px, auto);
  grid-column-gap: 0.25rem;
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

export const GeneralItemsTextInfo = styled.div`
  display: flex;
  flex-direction: column;
  & > div:first-child {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0.5rem;
    grid-row-gap: 0.5rem;
  }
  .timeContainer {
    display: flex;
    gap: 0.25rem;
  }
  @media (max-width: 768px) {
    & > div:first-child {
      display: grid;
      grid-template-columns: 1fr;
      grid-column-gap: 0.125rem;
    }
    .timeContainer {
      flex-wrap: wrap;
    }
  }
`;

export const GeneralItemsSelectInfo = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 0.25rem;
  & > div {
    max-width: 320px;
  }
  @media (max-width: 992px) {
    & > div {
      max-width: none;
    }
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    & > div {
      max-width: none;
    }
  }
`;

export const RiskManagementInfo = styled.div`
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 0.5rem 1rem;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
`;

export const StyledFab = styled.button`
  display: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 999px;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 1;
  height: 3rem;
  width: 3rem;
  background-color: ${theme.colors.light_gray};
  border: none;
  & > svg {
    color: ${theme.colors.gray};
  }
  transition: background-color 0.4s ease;
  transition: color 0.4s ease;
  &:hover {
    background-color: ${theme.colors.gray};
    & > svg {
      color: ${theme.colors.light_gray};
    }
  }

  &.end {
    display: flex;
  }
`;

export const ExpandableButton = styled.button`
  all: unset;
  cursor: pointer;
  margin-right: 0.5rem;
`;

export const StyledSelectItem = styled.div`
  &:hover {
    background-color: ${theme.colors.light};
  }
`;

export const StyledOption = styled.div`
  margin: 0.25rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: ${props => `${props.depth * 10}'px'` ?? 'auto'};
`;

export const SearchConsultationContainer = styled.div`
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 0.5rem 1rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
  margin-top: 1rem;

  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const RiskAreaSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: stretch;

  & > header {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    & > button {
      border: 0;
      background: transparent;
      display: flex;
      align-items: center;
      gap: 0.25rem;

      & > svg {
        width: 0.875rem;
        height: 0.875rem;
        color: ${theme.colors.success};
      }
    }
  }
`;

export const RiskAreaItemsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  flex-wrap: wrap;
  max-height: 100px;
  overflow-y: auto;
  border: 1px solid #e5eaee;
  border-radius: 5px;
  flex: 1;
  padding: 0.25rem;
`;

export const RiskAreaItem = styled.div`
  display: flex;
  align-items: center;
  background: ${theme.colors.light_gray};
  padding: 0.5rem;
  border-radius: 5px;

  & > button {
    background: transparent;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    & > svg {
      width: 0.85rem;
      height: 0.85rem;
    }
    &:hover {
      & > svg {
        color: ${theme.colors.danger};
      }
    }
  }
`;
