import moment from 'moment';
import { cooperplaceApi } from 'services/api';
import { convertNumberToDatabase } from 'v3/utils/formatter';

export async function handleCreate(
  data,
  setLoading,
  setShowModal,
  setRegisteredLoad
) {
  try {
    let originList = {};
    let destinationList = {};
    let payload = {};

    originList = {
      address: data.origin?.address,
      number: data.origin?.number,
      complement: data.origin?.complement,
      zipCode: data.origin?.zipCode,
      lat: data.origin?.lat,
      lng: data.origin?.lng,
      cityId: data?.origin?.cityModel?.id || null,
      city: data?.origin?.cityModel?.name || null,
      province: data?.origin?.province,
      neighborhood: data.origin?.neighborhood,
      formattedAddress: data.origin?.formattedAddress,
      startSchedule: moment(data?.origin.startSchedule),
      scheduledTime: moment(data.origin?.date),
      clientId: data?.origin?.client?.id || null,
      countrySlug:
        data?.origin?.country_slug || data?.origin?.country?.abbreviation,
    };
    destinationList = data.destinations.map(dest => ({
      address: dest?.address,
      number: dest?.number,
      complement: dest?.complement,
      zipCode: dest?.zipCode,
      province: dest?.province,
      lat: dest?.lat,
      lng: dest?.lng,
      scheduledTime: moment(dest?.date),
      cityId: dest?.cityModel?.id,
      city: dest?.cityModel?.name,
      neighborhood: dest?.neighborhood,
      formattedAddress: dest?.formattedAddress,
      countrySlug: dest?.country_slug || dest?.country?.abbreviation,
      action: dest?.type?.id,
      startSchedule: moment(dest?.startSchedule),
      clientId: dest.client?.id || null,
    }));
    payload = {
      policyId: data?.policyId,
      negotiationType: data?.negotiation_type?.value,
      loadValueType: data?.load_value_type?.value,
      policyRangeId: data?.policyRangeId,
      policyOperationId: data?.policyOperationId,
      policyVehicleId: data?.policyVehicleId,
      negotiatorId: data.negotiator[0]?.id,
      personClientId: data.client.id,
      productId: data.product.id,
      branchOfficeId: data.branchOffice?.id,
      costCenterId: data.costCenter?.id,
      takerValue: +data.taker_value,
      negotiationId: data?.negotiation?.id || null,
      dispositionId: data?.disposition.id,
      contactInfo: `+${data.country.ddi} ${data.phone}`,
      currency: data.currency?.value || 'BRL',
      loadWeight: +convertNumberToDatabase(data.weight),
      dueDate: data.dueDate ? moment(data.dueDate) : null,
      clientOrderNumber: data?.clientOrderNumber || null,
      externalInvoiceNumber: data?.externalInvoiceNumber || null,
      comments: data.observations,
      crawledLoad: data.tracked,
      tolledLoad: data?.isTollIncluded || null,
      tollValue: +data.toll_value,
      netWeight: +convertNumberToDatabase(data.netWeight),
      dischargeValue: +data.discharge_value,
      insuranceValue: +data.insurance || 0,
      mopp: data.mopp ? 1 : 0,
      gris: data.gris,
      adValorem: data.ad_valorem,
      advaloremIncluded: data.isAdValoremIncluded | null,
      ferry: +data?.ferry,
      icms: +data?.ICMS,
      chargeValue: +data.charge_value,
      palletNumber: data.pallets || null,
      isEscorted: data?.escorted,
      pcpObservation: data?.pcp_observation,
      totalShipmentTransfer: +data?.total_shipment_transfer,
      totalInputs: +data?.total_inputs,
      totalShipmentDiscounts: +data?.total_shipment_discounts,
      icmsPercentage: data?.icms_percentage || null,
      grisPercentage: data?.gris_percentage || null,
      adValoremPercentage: data?.ad_valorem_percentage || null,
      trackerId: data.tracker ? data.tracker.id : null,
      withBait: data.withBait,
      baitNumber: data.baitNumber ? data.baitNumber : null,
      isTollPayerClient: data?.isTollPayerClient || 0,
      unloadIncluded: data?.isDischargeIncluded || null,
      chargeIncluded: data?.isChargeIncluded || null,
      fareCompany: +data?.fare_company,
      productTypeId: data.product.product_type_id,
      cargoValue: +data?.cargoValue,
      loadVolume: String(data.volume),
      insuranceIncluded: data.isInsuranceIncluded || null,
      typeValue: data?.freightType?.value,
      grisIncluded: data.isGrisIncluded || null,
      adValoremIncluded: data.isAdValoremIncluded | null,
      exchange: +data?.dailyCotation || 0,
      loadCurrency: data?.load_currency?.value,
      routeId: data?.routes?.id || null,
      crt: data?.crt,
      temperature: data?.temperature || null,
      temperatureMin: data?.temperatureMin || null,
      freightExchange: +data?.freight_exchange || 0,
      exchangeDay: +data?.exchange_day,
      totalTakerValue: +data?.total_taker_value,
      loadValueType: data?.load_value_type?.value,
      actionTollId: data?.action_toll?.id || null,
      actionDischargeId: data?.action_discharge?.id || null,
      actionChargeId: data?.action_charge?.id || null,
      actionGrissAdvaloremId: data?.action_griss_advalorem?.id || null,
      actionIcmsId: data?.action_icms?.id || null,
      actionFerryId: data?.action_ferry?.id || null,
      negotiatorIds: data.negotiator.map(item => item.id),
      extraFields:
        data?.extraFields?.length > 0
          ? data?.extraFields?.map(extra => {
              return {
                label: extra?.label,
                type: extra?.type ? 1 : 0,
                value: Number(extra?.value),
              };
            })
          : null,
      vehicleTypeIds: data?.vehicleTypes?.map(type => type.id),
      vehicleImplementTypeIds: data?.vehicleImplements
        ? data?.vehicleImplements.map(implement => implement.id)
        : [],
      vehicleBodyTypeIds: data?.vehicleBodies?.map(body => body.id),
      tagIds: data?.tags ? data?.tags?.map(tag => tag.id) : null,
      automationIds: data.selectedAutomations.map(automation => automation.id),
      riskManagerIds: data.manager_tracker
        ? data.manager_tracker.map(item => item.id)
        : [],
      loadOrigins: originList,
      loadDestinations: destinationList,
    };
    let load;

    for (let i = 0; i < data.loadCreationNumber; i++) {
      let response;
      response = await cooperplaceApi.post(`loads`, payload);

      load = response.data.id;

      let inputsExtraFields = [...data?.inputsExtraFields];
      let transferExtraFields = [...data?.transferExtraFields];
      let discountsExtraFields = [...data?.discountsExtraFields];

      transferExtraFields = transferExtraFields.map(item => {
        return {
          ...item,
          load_id: load,
        };
      });

      const issueRateAsExtraField = {
        label: 'Taxa de emissão',
        manual: true,
        value: Number(data?.issueRate),
        showToDriver: false,
      };
      discountsExtraFields.push(issueRateAsExtraField);
      discountsExtraFields = discountsExtraFields.map(item => {
        return {
          ...item,
          load_id: load,
        };
      });
      const hasCoopercargaInsurance = !!data?.coopercarga_insurance;
      if (hasCoopercargaInsurance) {
        const newInputsExtraField = {
          label: 'Seguro Coopercarga',
          manual: true,
          value: Number(data.coopercarga_insurance),
          showToDriver: false,
        };
        inputsExtraFields.push(newInputsExtraField);
      }
      inputsExtraFields = inputsExtraFields?.map(item => {
        return { ...item, load_id: load };
      });
      try {
        await cooperplaceApi.put('/carg-inputs', { inputsExtraFields });
        await cooperplaceApi.put('carg-shipment-transfers/', {
          transferExtraFields,
        });
        await cooperplaceApi.put('carg-shipment-discounts/', {
          discountsExtraFields,
        });
      } catch (err) {}
    }
    setShowModal(true);
    setRegisteredLoad(load);
    setLoading(false);
  } catch (error) {
    setLoading(false);
    throw error;
  }
}

export function validateDestinationsStartDate(destinations) {
  if (!destinations || destinations.length <= 1) return true;
  const invalidsDates = [];

  for (let i = 0; i < destinations.length - 1; i++) {
    const nextStartScheduleTime = moment(
      destinations[i + 1].startSchedule,
      'YYYY-MM-DDTHH:mm'
    );
    const currentScheduledTime = moment(
      destinations[i].startSchedule,
      'YYYY-MM-DDTHH:mm'
    );

    if (currentScheduledTime >= nextStartScheduleTime) {
      invalidsDates.push(i);
    }
  }

  return invalidsDates;
}

export function validateDestinationsEndDate(destinations) {
  if (!destinations || destinations.length <= 1) return true;
  const invalidsDates = [];

  for (let i = 1; i < destinations.length; i++) {
    const previousScheduledTime = moment(
      destinations[i - 1].date,
      'YYYY-MM-DDTHH:mm'
    );
    const currentScheduledTime = moment(
      destinations[i].date,
      'YYYY-MM-DDTHH:mm'
    );
    if (currentScheduledTime <= previousScheduledTime) {
      invalidsDates.push(i);
    }
  }

  return invalidsDates;
}

export function validateDestinationDate(destinations) {
  if (!destinations || destinations.length <= 1) return true;
  const invalidsDates = [];
  let destArray = destinations.slice(0, destinations?.length - 1);

  for (let i = destinations.length - 1; i > 0; i--) {
    const invalidDate = destArray.some(item => {
      const previousScheduledTime = moment(item.date, 'YYYY-MM-DDTHH:mm');
      const currentScheduledTime = moment(
        destinations[i].startSchedule,
        'YYYY-MM-DDTHH:mm'
      );
      return previousScheduledTime > currentScheduledTime;
    });
    if (invalidDate) {
      invalidsDates.push(i);
    }
    destArray = destArray.slice(0, destArray?.length - 1);
  }
  return invalidsDates;
}

export function validateDestinationDateWithOrigin(origin, destinations) {
  const invalidsEndDates = [];
  const invalidsStartDates = [];
  const originStart = moment(origin.startSchedule, 'YYYY-MM-DDTHH:mm');
  const originEnd = moment(origin.date, 'YYYY-MM-DDTHH:mm');
  destinations.forEach((dest, idx) => {
    const currentStartScheduledTime = moment(
      dest.startSchedule,
      'YYYY-MM-DDTHH:mm'
    );
    const currentEndTime = moment(dest.date, 'YYYY-MM-DDTHH:mm');
    if (currentStartScheduledTime < originStart) {
      invalidsStartDates.push(idx);
    }
    if (currentEndTime < originEnd) {
      invalidsEndDates.push(idx);
    }
  });
  return { start: invalidsStartDates, end: invalidsEndDates };
}

export function handleInitializeSingleOption(
  optionsArray,
  field,
  setter,
  isMultipleField = false
) {
  if (optionsArray?.length === 1) {
    setter(old => ({
      ...old,
      [field]: isMultipleField ? optionsArray : optionsArray[0],
    }));
  }
}
