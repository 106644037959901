/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import moment from 'moment';

import api from 'services/api';
import { useTravel } from 'v3/pages/Travel';
import { useSnackbar } from 'v3/components/Snackbar';

import Modal from 'v3/components/Modal';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import DatePicker from 'v3/components/DatePicker';

export default function EffectOrigin({ id, isOpen, onClose }) {
  const travel = useTravel();
  const snackbar = useSnackbar();
  const [datetime, setDatetime] = useState('');

  async function handleModal() {
    const momentDate = moment(datetime, 'DD/MM/YYYY HH:mm');

    if (!momentDate.isValid()) {
      snackbar.show(<Text>Data informada é inválida</Text>, { type: 'error' });
      return;
    }

    try {
      await api.put(`/travel-data/origin/${id}`, {
        title: 'efetivo',
        effective_time: momentDate.toDate(),
      });

      travel.fetchTravel();
      snackbar.show(<Text>Origem efetivada com sucesso</Text>, {
        type: 'success',
      });
    } catch (ex) {
      let message = 'Não foi possível efetivar a origem';
      if (ex.response && ex.response.data && ex.response.data.error) {
        message = ex.response.data.error;
      }
      snackbar.show(<Text>{message}</Text>, {
        type: 'error',
      });
    } finally {
      setDatetime('');
      onClose();
    }
  }

  const validStatusCodes = [
    'ST9',
    'DOCS_ISSUED',
    'ST3',
    'ST5',
    'ST6',
    'ST7',
    'ST10',
  ];
  const shouldDisplayText = validStatusCodes.includes(
    travel.data?.travelStatus?.code
  );

  return (
    <Modal
      size="lg"
      show={isOpen}
      heading={
        <Text type="header" color="dark" weight="500">
          Efetivar origem
        </Text>
      }
      handleClose={onClose}
      body={
        <div className="d-flex flex-column align-items-center">
          {!shouldDisplayText && (
            <Text className="d-block load-label-align mb-4 mt-2">
              Ao efetivar a Origem, o status da viagem será alterada para
              Carregamento Finalizado
            </Text>
          )}
          <DatePicker
            guide
            withTime
            label="Efetivado em *"
            value={datetime}
            onChange={({ target }) => setDatetime(target.value)}
          />
          <Button
            disabled={!travel.data?.driver || !travel.data?.vehicle?.[0]}
            onClick={handleModal}
            className="mt-2"
          >
            <Text type="regular" weight="500">
              Informar
            </Text>
          </Button>
        </div>
      }
    />
  );
}
