const Yup = require('yup');

export const dataSchema = Yup.object().shape({
  name: Yup.string()
    .required('Nome obrigatório')
    .typeError('Nome obrigatório')
    .min(3, 'Minimo de 3 caracteres'),
  productNcm: Yup.object()
    .typeError('Categoria obrigatória')
    .shape({
      id: Yup.number()
        .required('Categoria obrigatória')
        .typeError('Categoria obrigatória'),
    }),
  unit_measure: Yup.object()
    .typeError('Unidade de medida obrigatória')
    .shape({
      value: Yup.string()
        .required('Unidade de medida obrigatória')
        .typeError('Unidade de medida obrigatória'),
    }),
  points: Yup.number()
    .required('Pontos é obrigatório')
    .typeError('Pontos é obrigatório')
    .min(0, 'Pontos deve ser maior que 0'),
  productType: Yup.object()
    .required('Categoria obrigatória')
    .typeError('Categoria obrigatória')
    .shape({
      id: Yup.number()
        .required('Categoria obrigatória')
        .typeError('Categoria obrigatória'),
    }),
});

export const productSchema = Yup.object().shape({
  data: dataSchema,
});

export async function validate(field, data, setErrors, context) {
  const errorList = {};
  try {
    if (field === 'allSchema')
      await productSchema.validate(data, {
        abortEarly: false,
        context: { profile: data.data.profile },
      });
    else
      await productSchema.validateAt(field, data, {
        abortEarly: false,
        context: { profile: data[field].profile, ...context },
      });
    return true;
  } catch (err) {
    err.inner.forEach(error => {
      errorList[error.path] = error?.message;
    });

    return false;
  } finally {
    setErrors(errorList);
  }
}
