/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Card from 'v3/components/Card';
import Text from 'v3/components/Text';
import Input from 'v3/components/Input';
import Button from 'v3/components/Button';
import { useSnackbar } from 'v3/components/Snackbar';
import ApplicationLayout from 'v3/layouts/Application';
import { SaveContainer } from 'v3/layouts/Application/styles';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import api from 'services/api';
import useWindowResize from 'hooks/useWindowResize';
import { usePermission } from 'hooks';

function Register() {
  const snackbar = useSnackbar();
  const history = useHistory();
  const [paymentMethod, setPaymentMethod] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  usePermission('CADASTRAR_FORMAS_DE_PAGAMENTO', { redirect: true });

  async function handleRegister() {
    if (paymentMethod !== '')
      try {
        setLoading(true);
        await api.post('payment/methods', { name: paymentMethod });
        setPaymentMethod('');
        setShowModal(true);
      } catch (ex) {
        snackbar.show(
          <Text>Algo deu errado ao cadastrar a forma de pagamento.</Text>,
          { type: 'error' }
        );
      } finally {
        setLoading(false);
      }
  }

  const handleCloseModal = () => setShowModal(false);

  const [saveContainerOffset, setSaveContainerOffset] = useState(0);

  useWindowResize(() => {
    const header = document.getElementById('header-container');
    const originDistanceToRight =
      window.pageXOffset + header.getBoundingClientRect().right;
    const headerWidth = header.getBoundingClientRect().width;
    const SAVE_CONTAINER_PADDING = 30;
    const distanceToRight =
      originDistanceToRight - headerWidth + SAVE_CONTAINER_PADDING;
    setSaveContainerOffset(distanceToRight);
  }, []);

  return (
    <ApplicationLayout
      title="Forma de pagamento"
      RightComponent={
        <SaveContainer style={{ right: saveContainerOffset }}>
          <Button variant="secondary" onClick={() => history.goBack()}>
            <Text weight={500} color="dark" type="regular">
              Cancelar
            </Text>
          </Button>
          <Button
            variant="success"
            loading={loading}
            onClick={() => handleRegister()}
          >
            <Text weight={500} color="white" type="regular">
              Salvar
            </Text>
          </Button>
        </SaveContainer>
      }
    >
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton onClick={handleCloseModal}>
          <Modal.Title>
            <Text type="subtitle" color="dark">
              Método de pagamento cadastrado!
            </Text>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Text type="body" color="dark">
            Deseja ir para listagem de métodos ou cadastrar um novo?
          </Text>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => history.push('/formas-de-pagamento')}
          >
            <Text weight={500} type="regular">
              Ver listagem
            </Text>
          </Button>
          <Button variant="primary" onClick={() => window.location.reload()}>
            <Text weight={500} type="regular">
              Adicionar novo
            </Text>
          </Button>
        </Modal.Footer>
      </Modal>
      <Row>
        <Col lg={12} md={12} xs={12}>
          <Card
            header={
              <Text color="#464E5F" type="header">
                Cadastro de formas de pagamento
              </Text>
            }
          >
            <Row>
              <Col xs={12}>
                <Input
                  label="Meio de pagamento"
                  value={paymentMethod}
                  onChange={event => setPaymentMethod(event.target.value)}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </ApplicationLayout>
  );
}

export default Register;
