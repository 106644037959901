import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { cooperplaceApi } from 'services/api';
import {
  fetchAnttTypes,
  fetchBodies,
  fetchOnlyCities,
  fetchImplements,
  fetchOwners,
  fetchVehiclesTypes,
} from 'utils/fetches';

import { Button, Input, Modal, Select, Text } from 'v3/components';
import { useSnackbar } from 'v3/components/Snackbar';
import { updateWagonSchema } from '../../validator';

export function UpdateWagonData({
  isOpen,
  onClose,
  onSubmit,
  wagon,
  setWagon,
}) {
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [vehicleImplementTypes, setVehicleImplementTypes] = useState([]);
  const [vehicleBodyTypes, setVehicleBodyTypes] = useState([]);
  const [anttTypes, setAnttTypes] = useState([]);

  const snackbar = useSnackbar();

  useEffect(() => {
    if (isOpen && wagon?.wagonData?.id) {
      verifySchema(wagon?.wagonData);
    }
    if (isOpen) {
      fetchOptions();
    }
  }, [isOpen]);

  const hasSelectDollyImplementType =
    wagon?.wagonData?.implementType?.name === 'Dolly';

  async function fetchOptions() {
    try {
      setLoading(true);
      let [
        vehicleTypesData,
        vehiclesBodyTypesData,
        anttTypesData,
        implementsData,
      ] = await Promise.all([
        fetchVehiclesTypes(),
        fetchBodies(),
        fetchAnttTypes(),
        fetchImplements(),
      ]);
      vehicleTypesData = vehicleTypesData.filter(
        vtype => vtype?.name?.toLowerCase() === 'semi reboque'
      );
      setVehicleTypes(vehicleTypesData);
      setVehicleBodyTypes(vehiclesBodyTypesData);
      setAnttTypes(anttTypesData);
      setVehicleImplementTypes(implementsData);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  function handleCloseModal() {
    setWagon(null);
    onClose();
  }
  async function verifySchema(payload) {
    try {
      await updateWagonSchema.validate(payload, {
        abortEarly: false,
      });
      return true;
    } catch (error) {
      const errorResponse = {};
      if (error.inner) {
        error.inner.forEach(error => {
          errorResponse[error.path] = error.message;
        });
        setErrors(errorResponse);
      }
      return false;
    }
  }
  async function handleSubmit() {
    try {
      setLoading(true);
      const isValid = await verifySchema(wagon?.wagonData);
      if (isValid === false) {
        setLoading(false);
        return;
      }
      const payload = {
        plate: wagon?.wagonData?.plate,
        yearManufacture: wagon?.wagonData?.year_manufacture,
        chassi: wagon?.wagonData?.chassi,
        renavam: wagon?.wagonData?.renavam,
        yearModel: wagon?.wagonData?.year_model,
        vehicleTypeId: wagon?.wagonData?.vehicle_type_id,
        vehicleImplementTypeId: wagon?.wagonData?.vehicle_implement_type_id,
        vehicleBodyTypeId: wagon?.wagonData?.vehicle_body_type_id,
        anttType: wagon?.wagonData?.antt_type,
        antt: wagon?.wagonData?.antt,
        anttAdherence: wagon?.wagonData?.antt_adherence,
        idOwner: wagon?.wagonData?.id_owner,
        beneficiaryId: wagon?.wagonData?.beneficiary?.id,
        plateCityId: wagon?.wagonData?.city?.id,
        plateCity: wagon?.wagonData?.city?.name,
        plateState:
          wagon?.wagonData?.city?.uf || wagon?.wagonData?.city?.province?.uf,
      };

      const response = await cooperplaceApi.put(
        `wagons/${wagon?.wagonData?.id}`,
        payload
      );

      snackbar.show(<Text>Salvo com sucesso</Text>, { type: 'success' });

      setLoading(false);
      setErrors({});
      onSubmit(response.data);
      onClose();
    } catch (error) {
      setWagon(old => ({ ...old, wagonData: null }));
      setLoading(false);
      snackbar.show(<Text>Erro ao editar implemento</Text>, {
        type: 'error',
      });
      return;
    }
  }

  return (
    <Modal
      size={'md'}
      show={isOpen}
      onHide={handleCloseModal}
      heading={
        <Text type="header" color="dark" weight="500">
          Atualizar dados do implemento
        </Text>
      }
      body={
        <Row className="mx-0 mt-1">
          <Col md={4} xs={12}>
            <Input
              label="Placa *"
              value={wagon?.wagonData?.plate}
              onChange={event =>
                event.target.value.length > 7
                  ? snackbar.show(<Text>Limite de dígitos para placa</Text>, {
                      type: 'error',
                    })
                  : setWagon(old => ({
                      ...old,
                      wagonData: {
                        ...old?.wagonData,
                        plate: event.target.value?.toUpperCase(),
                      },
                    }))
              }
              error={errors?.plate}
              id="input-atualizar-implemento-modal-placa"
            />
          </Col>
          <Col md={4} xs={12}>
            <Select.Async
              label={'Cidade *'}
              onSearch={fetchOnlyCities}
              value={wagon?.wagonData?.city}
              getOptionLabel={option =>
                `${option?.name}, ${option?.province?.uf ?? option?.uf}`
              }
              getOptionValue={option => option.id}
              onChange={value => {
                setWagon(old => ({
                  ...old,
                  wagonData: {
                    ...old?.wagonData,
                    city: value,
                  },
                }));
              }}
              error={errors?.city}
              id="select-atualizar-implemento-modal-cidade"
            />
          </Col>
          <Col md={4} xs={12}>
            <Input
              label="Ano de fabricação *"
              type="number"
              value={wagon?.wagonData?.year_manufacture}
              onChange={event =>
                event.target.value.length > 4
                  ? snackbar.show(<Text> Limite de dígitos para Ano </Text>, {
                      type: 'error',
                    })
                  : setWagon(old => ({
                      ...old,
                      wagonData: {
                        ...old?.wagonData,
                        year_manufacture: event.target.value,
                      },
                    }))
              }
              error={errors?.year_manufacture}
              id="input-atualizar-implemento-modal-ano-fabricacao"
            />
          </Col>
          <Col md={4} xs={12}>
            <Input
              label="Ano modelo *"
              type="number"
              value={wagon?.wagonData?.year_model}
              onChange={event =>
                event.target.value.length > 4
                  ? snackbar.show(<Text> Limite de dígitos para Ano </Text>, {
                      type: 'error',
                    })
                  : setWagon(old => ({
                      ...old,
                      wagonData: {
                        ...old?.wagonData,
                        year_model: event.target.value,
                      },
                    }))
              }
              error={errors?.year_model}
              id="input-atualizar-implemento-modal-ano-modelo"
            />
          </Col>
          <Col md={4} xs={12}>
            <Input
              label="Chassi *"
              value={wagon?.wagonData?.chassi}
              onChange={event =>
                event.target.value.length > 20
                  ? snackbar.show(
                      <Text> Limite de dígitos para Chassi </Text>,
                      {
                        type: 'error',
                      }
                    )
                  : setWagon(old => ({
                      ...old,
                      wagonData: {
                        ...old?.wagonData,
                        chassi: event.target.value,
                      },
                    }))
              }
              error={errors?.chassi}
              id="input-atualizar-implemento-modal-chassi"
            />
          </Col>
          <Col md={4} xs={12}>
            <Select
              label={'Tipo do veículo *'}
              options={vehicleTypes}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              value={wagon?.wagonData?.vehicleType}
              onChange={value =>
                setWagon(old => ({
                  ...old,
                  wagonData: {
                    ...old?.wagonData,
                    vehicleType: value,
                    vehicle_type_id: value?.id,
                  },
                }))
              }
              error={errors?.vehicleType}
              id="select-atualizar-implemento-modal-tipo-veiculo"
            />
          </Col>

          <Col md={4} xs={12}>
            <Select
              label="Tipo de implemento *"
              options={vehicleImplementTypes}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              value={wagon?.wagonData?.implementType}
              onChange={value => {
                if (value?.name === 'Dolly') {
                  setWagon(old => ({
                    ...old,
                    wagonData: {
                      ...old?.wagonData,
                      implementType: value,
                      vehicle_implement_type_id: value?.id,
                      bodyType: null,
                      vehicle_body_type_id: null,
                    },
                  }));
                  setErrors(old => ({ ...old, bodyType: null }));
                } else
                  setWagon(old => ({
                    ...old,
                    wagonData: {
                      ...old?.wagonData,
                      implementType: value,
                      vehicle_implement_type_id: value?.id,
                    },
                  }));
              }}
              error={errors?.implementType}
              id="select-atualizar-implemento-modal-tipo-implemento"
            />
          </Col>

          <Col md={4} xs={12}>
            <Select
              label={'Tipo do carroceria *'}
              options={vehicleBodyTypes}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              value={wagon?.wagonData?.bodyType}
              onChange={value =>
                setWagon(old => ({
                  ...old,
                  wagonData: {
                    ...old?.wagonData,
                    bodyType: value,
                    vehicle_body_type_id: value?.id,
                  },
                }))
              }
              error={errors?.bodyType}
              isDisabled={hasSelectDollyImplementType}
              id="select-atualizar-implemento-modal-tipo-carroceria"
            />
          </Col>
          <Col md={4} xs={12}>
            <Input
              label="Renavam *"
              type="number"
              value={wagon?.wagonData?.renavam}
              onChange={event =>
                event.target.value.length > 11
                  ? snackbar.show(
                      <Text> Limite de dígitos para Renavam </Text>,
                      {
                        type: 'error',
                      }
                    )
                  : setWagon(old => ({
                      ...old,
                      wagonData: {
                        ...old?.wagonData,
                        renavam: event.target.value,
                      },
                    }))
              }
              error={errors?.renavam}
              id="input-atualizar-implemento-modal-renavam"
            />
          </Col>
          <Col md={4} xs={12}>
            <Select
              label={'Tipo de RNTRC *'}
              options={anttTypes}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              value={wagon?.wagonData?.anttType}
              onChange={value =>
                setWagon(old => ({
                  ...old,
                  wagonData: {
                    ...old?.wagonData,
                    anttType: value,
                    antt_type: value?.id,
                  },
                }))
              }
              error={errors?.anttType}
              id="select-atualizar-implemento-modal-tipo-rntrc"
            />
          </Col>
          <Col md={4} xs={12}>
            <Input
              label={'RNTRC *'}
              type="number"
              value={wagon?.wagonData?.antt}
              onChange={event =>
                setWagon(old => ({
                  ...old,
                  wagonData: {
                    ...old?.wagonData,
                    antt: event.target.value,
                  },
                }))
              }
              error={errors?.antt}
              id="input-atualizar-implemento-modal-rntrc"
            />
          </Col>
          <Col md={4} xs={12}>
            <Input
              label={'Data de emissão RNTRC *'}
              type="date"
              value={wagon?.wagonData?.antt_adherence}
              onChange={event =>
                setWagon(old => ({
                  ...old,
                  wagonData: {
                    ...old?.wagonData,
                    antt_adherence: event.target.value,
                  },
                }))
              }
              error={errors?.antt_adherence}
              id="input-atualizar-implemento-modal-data-emissao-rntrc"
            />
          </Col>
          <Col md={4} xs={12}>
            <Select.Async
              label={'Proprietário do documento *'}
              onSearch={fetchOwners}
              onChange={value =>
                setWagon(old => ({
                  ...old,
                  wagonData: {
                    ...old?.wagonData,
                    id_owner: value?.id,
                    owner: value,
                  },
                }))
              }
              value={wagon?.wagonData?.owner}
              getOptionLabel={option => option.label || option.socialName}
              getOptionValue={option => option.id}
              error={errors?.owner || errors['owner.id']}
              id="select-atualizar-implemento-modal-proprietario-documento"
            />
          </Col>
          <Col md={4} xs={12}>
            <Select.Async
              label={'Beneficiário do implemento *'}
              onSearch={fetchOwners}
              onChange={value =>
                setWagon(old => ({
                  ...old,
                  wagonData: {
                    ...old?.wagonData,
                    beneficiary_id: value?.id,
                    beneficiary: value,
                  },
                }))
              }
              value={wagon?.wagonData?.beneficiary}
              getOptionLabel={option => option.label || option.socialName}
              getOptionValue={option => option.id}
              error={errors?.beneficiary || errors['beneficiary.id']}
            />
          </Col>

          <Col
            md={12}
            className="mb-3 d-flex align-items-center justify-content-center"
          >
            <Button
              className="mt-1 py-2"
              onClick={handleSubmit}
              loading={loading}
              id="button-atualizar-implemento-modal-salvar"
            >
              <Text weight="500" type="regular">
                Salvar
              </Text>
            </Button>
          </Col>
        </Row>
      }
    />
  );
}
