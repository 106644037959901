import styled from 'styled-components';

export const StyledFab = styled.button`
  display: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 999px;
  position: fixed;
  bottom: 1rem;
  left: 60px;
  z-index: 10;
  height: 3rem;
  width: 3rem;
  background-color: ${({ theme }) => theme.newTheme.colors.light_gray};
  border: none;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
  & > svg {
    color: ${({ theme }) => theme.newTheme.colors.gray};
  }
  transition: background-color 0.4s ease;
  transition: color 0.4s ease;
  &:hover {
    background-color: ${({ theme }) => theme.newTheme.colors.gray};
    & > svg {
      color: ${({ theme }) => theme.newTheme.colors.light_gray};
    }
  }

  &.end {
    display: flex;
  }
`;
