/* eslint-disable no-extra-boolean-cast */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useQuery from 'hooks/useQuery';
import { validatePermission } from 'actions/index';
import api from 'services/api';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import ApplicationLayout from 'v3/layouts/Application';
import InfiniteScroll from 'v3/components/InfiniteScroll';
import { useSnackbar } from 'v3/components/Snackbar';
import { usePermission } from 'hooks';
import Filter from './Filter';
import Card from './Card';

function List() {
  const snackbar = useSnackbar();
  const query = useQuery();

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [pagination, setPagination] = useState({
    lastPage: 1,
    page: 1,
    total: 0,
  });

  const [hasPermissionToCreate, setPermissionToCreate] = useState();
  useState(() => {
    setPermissionToCreate(validatePermission('CADASTRAR_USUARIO'));
  }, []);

  usePermission('VISUALIZAR_USUARIOS', { redirect: true });

  async function fetchUsers(page) {
    if (page === 1) {
      setLoading(true);
    }
    try {
      const response = await api.get(`v3/user?${query}&page=${page}`);

      const { data, ...rest } = response.data;

      setPagination(rest);
      setUsers(oldUsers => {
        if (page === 1) {
          return data;
        }

        return [...oldUsers, ...data];
      });
    } catch (error) {
      snackbar.show(<Text>Algo deu errado, tentar novamente mais tarde</Text>, {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }

  function renderItem(user) {
    return <Card user={user} />;
  }

  useEffect(() => {
    function onPressedKey(e) {
      if (e.key === 'Enter') {
        fetchUsers(1);
      }
    }

    window.addEventListener('keyup', onPressedKey);

    return () => {
      window.removeEventListener('keyup', onPressedKey);
    };
  });

  return (
    <ApplicationLayout
      title="Usuários"
      RightComponent={
        hasPermissionToCreate && (
          <Link to="/usuarios/cadastrar">
            <Button variant="primary" className="py-2 ml-1">
              <Text weight={500} color="white" type="regular">
                Novo usuário
              </Text>
            </Button>
          </Link>
        )
      }
    >
      <Filter onChange={() => fetchUsers(1)} />

      <InfiniteScroll
        loading={loading}
        data={users}
        renderItem={renderItem}
        onEndReach={() => fetchUsers(pagination.page + 1)}
        hasMore={pagination.page < pagination.lastPage}
        scrollThreshold={0.7}
      />
    </ApplicationLayout>
  );
}

export default List;
