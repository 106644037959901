import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import api from 'services/api';
import Fallback from 'v3/components/Fallback';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import Card from 'v3/components/Card';

import theme from 'v3/theme';
import { useSnackbar } from 'v3/components/Snackbar';
import { AttachmentsModal } from 'v3/components/AttachmentsModalComponent';
import { Attachment } from 'v3/components/Attachment';

import { vehicleDocumentTypes } from 'v3/utils/documentTypes';

import { validatePermission } from 'actions/index';

export default function Attachments({ attachments, setAttachments }) {
  const params = useParams();
  const snackbar = useSnackbar();

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const vehiclePermission = validatePermission('ALTERAR_VEICULOS')


  async function fetchAttachments() {
    try {
      setLoading(true);
      const response = await api.get(`v3/vehicles/${params.id}/attachments`);
      setAttachments(response.data);
    } catch (error) {
      //
    } finally {
      setLoading(false);
    }
  }

  async function removeAttachment(attachmentId) {
    try {
      setLoading(true);
      await api.delete(`v3/vehicles/${params.id}/attachments/${attachmentId}`);
      fetchAttachments();
    } catch (error) {
      snackbar.show('Não foi possível excluir o anexo', { type: 'error' });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchAttachments();
  }, []);

  return (
    <>
      <AttachmentsModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        fetchAttachments={fetchAttachments}
        setAttachments={setAttachments}
        attachments={attachments}
        documentOptions={vehicleDocumentTypes}
        url={'v3/vehicles'}
        api={api}
      />
      <Card
        header={
          <Text color="#464E5F" type="header">
            Anexos
          </Text>
        }
        HeaderRightComponent={
          !!attachments?.length && (
            <Button onClick={() => setShowModal(true)}> Adicionar </Button>
          )
        }
        loading={loading}
      >
        <>
          <Fallback
            component={
              <Col
                xs={12}
                className="align-items-center d-flex flex-column my-2"
              >
                <Text color={theme.colors.gray}>
                  Não há anexos cadastrados para esse veículo.
                </Text>
                { vehiclePermission &&
                  <Button onClick={() => setShowModal(true)} className="mt-3">
                    Adicionar
                  </Button>
                }
              </Col>
            }
            fall={!attachments?.length}
          >
            <Row>
              {attachments?.map(attachment => {
                return (
                  <Col
                    md={4}
                    className="d-flex flex-column align-items-center"
                    key={attachment.id}
                  >
                    <Attachment
                      attachment={attachment}
                      attachments={attachments}
                      removeAttachment={removeAttachment}
                      loading={loading}
                    />
                    <Text type="regular" className="mt-2">
                      {attachment?.type?.replace('_', ' ')}
                    </Text>
                    <Text type="regular" className="mt-2">
                      {attachment.description}
                    </Text>
                  </Col>
                );
              })}
            </Row>
          </Fallback>
        </>
      </Card>
    </>
  );
}
