import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { Text } from 'v3/components';
import { formatBoolean } from 'v3/utils/formatter';

import { useCompany } from '../../shared/context';

export default function IntegrationFretebras() {
  const company = useCompany();

  const fretebras = company.data?.integrations?.find(
    integration => integration.slug === 'FRETEBRAS'
  );

  return (
    <>
      <Row>
        <Col xs={12} md={6}>
          <Text type="label" color="dark">
            Integração Fretebras
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {formatBoolean(fretebras)}
          </Text>
        </Col>
      </Row>
      {!!fretebras && (
        <Row>
          <Col xs={12} md={6}>
            <Text type="label" color="dark">
              ID Cliente
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {fretebras?.client_id || '-'}
            </Text>
          </Col>
          <Col xs={12}>
            <Text type="label" color="dark">
              Token
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {fretebras?.client_secret || '-'}
            </Text>
          </Col>
          <Col xs={12}>
            <Text type="label" color="dark">
              Usuário
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {fretebras?.username || '-'}
            </Text>
          </Col>
          <Col xs={12}>
            <Text type="label" color="dark">
              Senha
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {fretebras?.password ? '*****' : '-'}
            </Text>
          </Col>
        </Row>
      )}
    </>
  );
}
