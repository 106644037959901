import styled, { keyframes, css } from 'styled-components';

const transition = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const PrePreparedCard = styled.div`
  animation: ${transition} 0.5s linear;
  line-height: 1 !important;
  border-radius: 5%;
  cursor: pointer;
  height: 100%;

  border: 1px solid rgba(216, 235, 255, 0.7);

  &:hover {
    background-color: #eff7ff;
  }

  ${({ focused }) =>
    focused &&
    css`
      :first-child {
        background-color: #e6f2ff;
      }
    `};
`;
