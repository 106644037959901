import React, { useState } from 'react';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import Label from 'components/Label';
import 'moment/locale/pt-br';

import { StyledDatepicker } from './styles';

/**
 * @param bordered {bool} - Adiciona uma borda na esquerda do componente
 * @param borderColor - {string} - Adiciona uma cor a borda da esquerda [success, danger, warning, primary, gray, orange]
 * @param form - {bool} - adiciona padding quando o card esta em um formulário
 *
 * @visibleName DatePicker
 */

export default function DatePicker({
  form,
  label,
  name,
  direction = 'normal',
  bordered,
  borderColor,
  cleareable,
  ...rest
}) {
  const [opened, setOpened] = useState(false);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale="pt-br">
      {label && <Label htmlFor={name} className="textLabel">{label}</Label>}
      <StyledDatepicker
        form={form}
        bordered={bordered}
        direction={direction}
        borderColor={borderColor}
        id={name}
        clearable={cleareable}
        onOpen={() => setOpened(true)}
        onAccept={() => setOpened(false)}
        onClose={() => setOpened(false)}
        open={opened}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
}
