import React, { useEffect, useRef, useState } from 'react';
import theme from 'v3/theme';
import api from 'services/apis/raster';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputAddress from 'v3/components/InputAddress';
import Button from 'v3/components/Button';
import MapRaw from 'v3/components/Map';
import MarkerLetter from 'v3/components/Map/MarkerLetter';
import { useSnackbar } from 'v3/components/Snackbar';
import Text from 'v3/components/Text';
import Radio from 'v3/components/Radio';
import { FaTrash } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { fetchCountries } from 'services/api/country';
import { Select } from 'v3/components';
import { useRoute } from './context';
import { formatCurrency } from '../../../utils/formatter';
import { fetchSelectOptions } from './controller';
import StatusColor from './RouteStatus';
import { formatAddress } from 'v3/utils/formatter';

export default function Route() {
  const route = useRoute();
  const snackbar = useSnackbar();
  const inputsRef = useRef([]);
  const params = useParams();
  const [origin, setOrigin] = useState([]);
  const [middleDestinations, setMiddleDestinations] = useState([]);
  const [lastDestination, setLastDestination] = useState([]);
  const [waypoint, setWaypoint] = useState([]);
  const [map, setMap] = useState();
  const [maps, setMaps] = useState();
  const [directionsService, setDirectionsService] = useState(null);
  const [directionsRenderer, setDirectionsRenderer] = useState(null);
  const [requestRasterRoute, setRequestRasterRoute] = useState(false);
  const [loadingRaster, setLoadingRaster] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [originCountrySelected, setOriginCountrySelected] = useState(null);
  const [destinationCountrySelected, setDestinationCountrySelected] = useState(
    []
  );
  const [kmlLayer, setKmlLayer] = useState(null);
  const [originClient, setOriginClient] = useState(null);
  const [destinationClient, setDestinationClient] = useState([]);
  const [formattedOriginAddress, setFormattedOriginAddress] = useState('');
  const [formattedDestinationAddress, setFormattedDestinationAddress] = useState([]);

  const defaultAddress = {
    formatted_address: null,
    address: null,
    lat: null,
    lng: null,
    province: '',
    city: '',
    city_id: null,
    province_id: null,
    complement: '',
  };

  function objIsEmpty(obj){
    if (!obj) {
      return obj
    }

    const valid = Object.keys(obj).length == 0

    if (valid) {
      return false
    }

    return obj
  }

  function onMapLoaded(_map, _maps) {
    setMap(_map);
    setMaps(_maps);
  }

  useEffect(() => {
    if (!params.id) {
      route.setData({
        destinations: [{ action: true }],
        origin: {},
        distance: 0,
      });
    }

    const destinations = []

    if(route.data.destinations){
      route.data.destinations.map(destination => {
        destinations.push(formatAddress(destination))
      })
    }

    if(route.data.origin){
      setFormattedOriginAddress(formatAddress(route.data?.origin))
    }

    setFormattedDestinationAddress(destinations)
    fetchCountries(setCountryList);

  }, []);

  useEffect(() => {
    if (countryList.length > 0) {
      let destinations = [];
      setOriginCountrySelected(
        countryList.find(
          country => country?.abbreviation == route.data.origin?.country_slug
        )
      );
      route.data.destinations.map(destination => {
        // isso vai manter a ordem correta dos destinos
        destinations.push(
          countryList.find(
            country => country?.abbreviation == destination?.country_slug
          )
        );
      });

      setDestinationCountrySelected(destinations);
    }
  }, [countryList]);

  useEffect(() => {
    setOriginClient(route?.data?.origin?.client);
  }, [route?.data?.origin?.client]);

  useEffect(() => {
    if (params.id) {
      setDestinationClient(
        route?.data?.destinations?.map(destination => {
          return destination?.client;
        })
      );
    }
  }, [route?.data?.destinations]);

  function handleAddDestination() {
    route.setData({
      destinations: [...route.data?.destinations, { action: true }],
    });
  }

  function handleRemoveDestination(destinationIndex) {
    const filteredDestinations = route.data.destinations?.filter(
      (_, index) => index !== destinationIndex
    );

    route.setData({
      destinations: filteredDestinations,
    });

    filteredDestinations.forEach((item, idx) => {
      if (!item?.address) {
        inputsRef.current[idx].refs.input.value = '';
      }
    });
  }

  useEffect(() => {
    kmlLayer &&
      kmlLayer.setOptions({
        url: '',
      });
  }, [route?.data?.origin, route?.data?.destinations]);

  function turnIntoWaypoint(directions) {
    const temp = directions.map(destination => {
      return {
        location: {
          lat: destination.lat,
          lng: destination.lng,
        },
        stopover: false,
      };
    });
    return temp;
  }

  useEffect(() => {
    setRequestRasterRoute(false);
    setLoadingRaster(false);
    directionsRenderer && directionsRenderer.setMap(map);

    if (route?.data?.origin?.lat) {
      setOrigin(route.data.origin);
    }
    if (route?.data?.destinations?.length > 0) {
      let middle = route?.data?.destinations.filter(
        (d, index) => index < route?.data?.destinations.length - 1
      );
      setMiddleDestinations(middle);
      if (middle.length > 0) {
        const tempWaypoint = turnIntoWaypoint(middleDestinations);
        setWaypoint(tempWaypoint);
      } else {
        setWaypoint([]);
      }
      setLastDestination(
        route?.data?.destinations[route?.data?.destinations.length - 1]
      );
    }
  }, [route?.data?.origin, route?.data?.destinations]);

  useEffect(() => {
    if (maps?.DirectionsService && maps?.DirectionsRenderer) {
      setDirectionsService(new maps.DirectionsService());
      setDirectionsRenderer(
        new maps.DirectionsRenderer({
          draggable: true,
          map,
          markerOptions: {
            draggable: false,
            visible: false,
          },
        })
      );
    }
    if (maps?.KmlLayer) {
      setKmlLayer(new maps.KmlLayer({ map, suppressInfoWindows: true }));
    }
  }, [maps]);

  function displayRoute(origin, destination, path, service, display) {

    if(destination){
      service
        .route({
          origin: String(origin.lat) + ',' + String(origin.lng),
          destination: String(destination.lat) + ',' + String(destination.lng),
          travelMode: maps?.TravelMode?.DRIVING,
          waypoints: path,
        })
        .then(result => {
          route.setData({
            polyline: result.routes[0]?.overview_polyline,
            distance: (result.routes[0]?.legs[0]?.distance.value / 1000).toFixed(
              1
            ),
          });
          display.setDirections(result);
        })
        .catch(e => {
          snackbar.show(
            <Text>
              Não foi possível exibir a rota. Tente novamente mais tarde.
            </Text>,
            {
              type: 'error',
            }
          );
        });
    }
  }

  useEffect(() => {
    if (
      directionsRenderer != null &&
      origin?.lat &&
      lastDestination?.lat &&
      !requestRasterRoute
    ) {
      if (
        directionsRenderer &&
        route?.data?.origin &&
        route?.data?.destinations
      ) {
        displayRoute(
          origin,
          lastDestination,
          waypoint,
          directionsService,
          directionsRenderer
        );
      }
    }
  }, [origin, middleDestinations, lastDestination]);

  function getNewPolyline() {
    const dir = directionsRenderer.getDirections();
    route.setData({
      polyline: dir.routes[0].overview_polyline,
      distance: (dir.routes[0].legs[0].distance.value / 1000).toFixed(1),
    });
  }

  useEffect(() => {
    if (directionsRenderer) {
      directionsRenderer.addListener('directions_changed', getNewPolyline);
    }
    if (directionsRenderer && route?.data?.polyline) {
      let rota = JSON.stringify(
        maps?.geometry?.encoding?.decodePath(route?.data?.polyline)
      );
      let fullPath = JSON.parse(rota);
      let pathOrigin = fullPath.shift();
      let pathLastDestination = fullPath.pop();

      const tempWaypoint = turnIntoWaypoint(route?.data?.destinations);
      displayRoute(
        pathOrigin,
        pathLastDestination,
        tempWaypoint,
        directionsService,
        directionsRenderer
      );
    }
  }, [directionsRenderer]);

  function handleChangeDestinationCountry(destinationIndex, country) {
    const destinations = [...route.data.destinations];
    destinations[destinationIndex] = {
      ...destinations[destinationIndex],
      country_slug: country?.country_slug,
      ...defaultAddress,
    };

    route.setData({
      destinations,
    });
  }

  function handleChangeDestinationAddress(destinationIndex, address) {
    const destinationAddress = []
    const destinations = [...route.data.destinations];
    destinations[destinationIndex] = {
      ...destinations[destinationIndex],
      formatted_address: address?.formatted_address,
      ...defaultAddress,
    };
    destinationAddress.push(destinations[destinationIndex].formatted_address)
    setFormattedDestinationAddress(destinationAddress)

    route.setData({
      destinations,
    });
  }

  function setKMLPolyline(KML) {
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(KML, 'application/xml');
    let coordinates = [...xmlDoc.getElementsByTagName('coordinates')];
    let allCoordinates = [];
    let chunk = [];
    coordinates.map(item => {
      let aux = item.textContent.replace(/0\s/g, ' ');
      aux
        .split(',')
        .map(item => item.trim())
        .filter(item => item !== '0')
        .map(item => allCoordinates.push(Number(item)));
    });
    for (let i = 0; i < allCoordinates.length; i += 2) {
      chunk.push(allCoordinates.slice(i, i + 2));
    }
    let processedChunk = chunk.map(item => {
      return {
        lat: item[0],
        lng: item[1],
      };
    });
    let kmlPolyline = maps?.geometry?.encoding?.encodePath(processedChunk);

    route.setData({ polyline: kmlPolyline });
    return;
  }

  async function handleRasterCall() {
    if (!route?.data?.origin?.lat || !route?.data?.destinations[0]?.lat) {
      snackbar.show(
        <Text>Informe origem e destino para solicitar rota à RASTER!</Text>,
        {
          type: 'error',
        }
      );
      return;
    }
    setRequestRasterRoute(true);
    setLoadingRaster(true);
    let tempLocations = [route?.data?.origin, ...route?.data?.destinations];
    let locations = tempLocations.map((location, index) => {
      if (index === 0 || index === tempLocations.length - 1) {
        return location.city;
      } else {
        return {
          Latitude: location.lat,
          Longitude: location.lng,
        };
      }
    });
    try {
      directionsRenderer.setMap(null);
      const response = await api.post('/routes', { locations });
      route.setData({ id_rota_raster: response?.data[0]?.rasterId });
      const KMLUrl = response?.data[0]?.linkKml;
      const KMLContent = response?.data[0]?.kml;
      setKMLPolyline(KMLContent);
      kmlLayer.setOptions({
        url: KMLUrl,
      });
      const bennerResponse = response?.data[0]?.benner;

      snackbar.show(
        <Text>{`Rota atualizada de acordo com a definição da RASTER. ${
          !bennerResponse
            ? 'Rota não encontrada no Benner! Necessário incluir rota no Benner'
            : ''
        }`}</Text>,
        {
          type: 'warning',
        }
      );
    } catch (error) {
      setRequestRasterRoute(false);
      directionsRenderer.setMap(map);

      snackbar.show(<Text>Não foi possível puxar rota da RASTER. </Text>, {
        type: 'error',
      });
    } finally {
      setLoadingRaster(false);
    }
    return;
  }

  function hanldeDestinationClient(client, index) {
    const destinations = [...route.data.destinations];
    destinations[index] = {
      ...destinations[index],
      client_id: client?.id,
      client,
    };

    route.setData({
      destinations,
    });
  }
  return (
    <Row>
      <Col xs={12} className="mb-3">
        <Select.Async
          label="Cliente de Origem"
          value={objIsEmpty(originClient)}
          onSearch={search =>
            fetchSelectOptions('persons/customers', { search })
          }
          getOptionLabel={option => `${option.social_name} - ${option.cgccpf}`}
          getOptionValue={option => option.id}
          onChange={event => {
            route.setData({
              origin: {
                ...route.data.origin,
                client_id: event?.id,
                client: event,
              },
            });

            setOriginClient(event);
          }}
          error={route.errors?.['route.origin.client_id']}
        />
      </Col>

      <Col xs={12} className="mb-3">
        <Select
          label="País de Origem *"
          options={countryList}
          value={originCountrySelected}
          onChange={country => {
            setOriginCountrySelected(country);

            route.setData({
              origin: {
                ...route.data.origin,
                country_slug: country?.country_slug,
                ...defaultAddress,
              },
            });
          }}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          error={route.errors[`route.origin.country_slug`]}
        />
      </Col>

      <Col xs={12} className="mb-3">
        <InputAddress
          label="Origem *"
          type="text"
          placeholder=""
          value={formattedOriginAddress}
          types={[]}
          onChange={event => {
            route.setData({
              origin: {
                ...route.data.origin,
                formatted_address: event.target.value,
                country_slug: originCountrySelected?.abbreviation,
              },
            });

            setFormattedOriginAddress(event.target.value);
          }}
          country={originCountrySelected?.abbreviation?.toLowerCase() || `br`}
          onSelectedAddress={address => {
            setFormattedOriginAddress(address.formatted_address)

            route.setData({
              origin: {
                ...address,
                country_slug: originCountrySelected?.abbreviation,
                client_id: originClient?.id,
                client: originClient || {},
              },
            });
          }}
          error={route.errors['route.origin.city']}
          disabled={!originCountrySelected}
        />
      </Col>

      {route.data?.destinations?.map((_, index) => {
        return (
          <>
            <Col xs={12} className="mb-3">
              <Select.Async
                label={
                  route.data.destinations.length - 1 === index
                    ? 'Cliente de Destino'
                    : `Cliente de Destino ${index + 1}`
                }
                value={destinationClient[index]}
                onSearch={search =>
                  fetchSelectOptions('persons/customers', { search })
                }
                getOptionLabel={option =>
                  `${option.social_name} - ${option.cgccpf}`
                }
                getOptionValue={option => option.id}
                onChange={event => {
                  hanldeDestinationClient(event, index);
                  setDestinationClient([
                    ...destinationClient.slice(0, index),
                    event,
                    ...destinationClient.slice(index + 1),
                  ]);
                }}
                error={route.errors?.[`route.destinations[${index}].client_id`]}
              />
            </Col>

            <Col
              xs={route.data.destinations.length - 1 !== index ? 10 : 12}
              className="mb-3"
            >
              <Select
                label={
                  route.data.destinations.length - 1 === index
                    ? 'País de Destino *'
                    : `País de Destino ${index + 1} *`
                }
                options={countryList}
                value={destinationCountrySelected[index]}
                onChange={country => {
                  setDestinationCountrySelected({
                    ...destinationCountrySelected,
                    [index]: country,
                  });
                  handleChangeDestinationCountry(index, country);
                }}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                error={
                  route.errors[`route.destinations[${index}].country_slug`]
                }
              />
            </Col>

            <Col
              xs={route.data.destinations.length - 1 !== index ? 10 : 12}
              className="mb-3"
            >
              <InputAddress
                ref={ref => {
                  inputsRef.current[index] = ref;
                }}
                label={
                  route.data.destinations.length - 1 === index ? (
                    <Row className="d-flex justify-content-between px-2">
                      <Text color="#464e5f" type="label" weight="500">
                        Destino {route.data.destinations.length > 1 && 'final'}{' '}
                        *
                      </Text>
                      <Text
                        type="label"
                        clickable
                        color="light_blue"
                        onClick={handleAddDestination}
                      >
                        Adicionar um destino
                      </Text>
                    </Row>
                  ) : (
                    `Destino ${index + 1} *`
                  )
                }
                type="text"
                placeholder=""
                value={formattedDestinationAddress[index]}
                types={[]}
                country={
                  destinationCountrySelected[
                    index
                  ]?.abbreviation?.toLowerCase() || `br`
                }
                onChange={event => {
                  handleChangeDestinationAddress(index, event.target.value);
                }}
                onSelectedAddress={address => {
                  route.setData({
                    destinations: route?.data?.destinations.map((item, idx) => {
                      handleChangeDestinationAddress(index, address)
                      if (idx === index)
                        return {
                          ...item,
                          ...address,
                        };
                      return item;
                    }),
                  });
                }}
                error={
                  route.errors[`route.destinations[${index}].city`] ||
                  route.errors[`route.destinations[${index}].action`] ||
                  route.errors[`route.destinations[${index}].country`]
                }
                disabled={!destinationCountrySelected[index]}
              />

              {route.data.destinations.length - 1 !== index && (
                <Radio.Group
                  onChange={event =>
                    route.setData({
                      destinations: route.data.destinations.map((item, idx) => {
                        if (idx === index)
                          return {
                            ...item,
                            action: event.target.value,
                          };
                        return item;
                      }),
                    })
                  }
                  value={route.data.destinations?.[index]?.action}
                  horizontal
                >
                  <Radio value={false}>
                    <Text color="dark" type="label">
                      Carga
                    </Text>
                  </Radio>
                  <Radio value>
                    <Text color="dark" type="label">
                      Descarga
                    </Text>
                  </Radio>
                </Radio.Group>
              )}
            </Col>
            {route.data.destinations.length - 1 !== index && (
              <Col xs={2} className="mb-3">
                <Button
                  variant="error"
                  size="sm"
                  onClick={() => handleRemoveDestination(index)}
                  className="mt-4"
                >
                  <FaTrash />
                </Button>
              </Col>
            )}
          </>
        );
      })}
      <Col className="d-flex justify-content-between">
        {route?.data?.origin && route?.data?.destinations && (
          <span>
            <Text type="label" color="#464E5F">
              Distância (Km)
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {`${route?.data?.distance} Km` || 'Não informado'}
            </Text>
          </span>
        )}
        <Button
          onClick={handleRasterCall}
          variant="raster"
          style={{ border: '1px solid red' }}
          loading={loadingRaster}
        >
          Puxar rota da Raster
        </Button>
      </Col>
      {route.minimumFreightTableItem.value && route.data.distance > 0 && (
        <Col xs={12}>
          <Text type="label" color="#464E5F">
            Sugestão de valor de cobrança baseado na distância
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {formatCurrency(
              route?.minimumFreightTableItem?.value *
                Number(route?.data?.distance)
            )}
          </Text>
        </Col>
      )}

      <Col xs={12}>
        <Text type="label" color="#464E5F">
          Mapa
        </Text>
        <MapRaw
          draggable={true}
          zoom={10}
          height={530}
          width="100%"
          position={
            route?.data?.origin?.lat
              ? {
                  lat: route.data?.origin.lat,
                  lng: route.data?.origin.lng,
                }
              : { lat: -25.453785, lng: -49.2529808 }
          }
          onGoogleApiLoaded={({ map, maps }) =>  onMapLoaded(map, maps)}
        >
          {route?.data?.origin?.lat && route?.data?.origin?.lng && (
            <MarkerLetter
              background="#0bb7af"
              lat={origin.lat}
              lng={origin.lng}
              color="#fff"
            />
          )}
          {middleDestinations &&
            middleDestinations.map((destinations, index) => (
              <MarkerLetter
                background={theme.colors.light_blue}
                lat={destinations?.lat}
                lng={destinations?.lng}
                color="#fff"
                key={index}
              />
            ))}
          {lastDestination?.lat && lastDestination?.lng && (
            <MarkerLetter
              background="#F64E60"
              lat={lastDestination.lat}
              lng={lastDestination.lng}
              color="#fff"
            />
          )}
        </MapRaw>
        <StatusColor />
      </Col>
    </Row>
  );
}
