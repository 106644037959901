/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import { Row, Col } from 'react-bootstrap';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import Text from 'v3/components/Text';
import Card, { DefaultLoadingBodyComponent } from 'v3/components/Card';
import Table from 'v3/components/Table';
import Button from 'v3/components/Button';

import * as Styled from './styles';
import * as Controller from './controller';

export default function Visualizations({ loadId, isAttended, loadingCard }) {
  const history = useHistory();

  const [visualizations, setVisualizations] = useState([]);

  useEffect(() => {
    async function fetchVisualizations() {
      try {
        setVisualizations(await Controller.fetchVisualizations(loadId));
      } catch (ex) {
        // Handle exception
      }
    }
    fetchVisualizations();
  }, []);

  return (
    <Col xs={12}>
      <Card
        loading={loadingCard}
        LoadingBodyComponent={() => (
          <DefaultLoadingBodyComponent linesCount={1} perLine={3} />
        )}
        header={
          <Text color="#464E5F" type="header">
            Visualizações
          </Text>
        }
      >
        <Row>
          <Col>
            {visualizations && visualizations.length > 0 ? (
              <Table>
                <thead>
                  <tr>
                    <th>
                      <Text type="label" weight="500">
                        Motorista
                      </Text>
                    </th>
                    <th />
                    <th>
                      <Text type="label" weight="500">
                        Veículo
                      </Text>
                    </th>
                    <th className="text-center">
                      <Text type="label" weight="500">
                        Distância
                      </Text>
                    </th>
                    <th className="text-center">
                      <Text type="label" weight="500">
                        Visualizações
                      </Text>
                    </th>
                    <th className="text-center">
                      <Text type="label" weight="500">
                        Interesse
                      </Text>
                    </th>
                    {!isAttended && (
                      <th className="text-center">
                        <Text type="label" weight="500">
                          Negociação
                        </Text>
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {visualizations.map(visualization => (
                    <tr>
                      <td>
                        <Text
                          weight={400}
                          type="regular"
                          as="p"
                          className="mb-2"
                        >
                          {visualization.name}
                          {visualization.verified && (
                            <OverlayTrigger
                              placement="right"
                              delay={{ show: 250, hide: 400 }}
                              overlay={<Tooltip>Motorista verificado!</Tooltip>}
                            >
                              <Styled.VerifiedIcon />
                            </OverlayTrigger>
                          )}
                        </Text>

                        <Text weight={400} type="regular" color="gray">
                          {visualization.phone}
                        </Text>
                      </td>
                      <td>
                        <Text weight={400} type="regular">
                          {visualization.rating && (
                            <>
                              {visualization.rating}
                              <Styled.StarIcon />
                            </>
                          )}
                        </Text>
                      </td>
                      <td>
                        <Text
                          weight={400}
                          type="regular"
                          as="p"
                          className="mb-2"
                        >
                          {visualization.plate}
                        </Text>
                        <Text weight={400} type="regular" color="gray">
                          {visualization.vehicleType}
                        </Text>
                      </td>
                      <Styled.CenteredTd>
                        <Text weight={400} type="regular">
                          {visualization.distance}
                        </Text>
                      </Styled.CenteredTd>
                      <Styled.CenteredTd>
                        <Text
                          weight={400}
                          type="regular"
                          as="p"
                          className="mb-2"
                        >
                          {visualization.quantity}
                        </Text>
                        <Text weight={400} type="regular" color="gray">
                          {visualization.visualized_at}
                        </Text>
                      </Styled.CenteredTd>
                      <Styled.CenteredTd>
                        <Text weight={400} type="regular">
                          {!!visualization.interested && <Styled.CheckIcon />}
                        </Text>
                      </Styled.CenteredTd>
                      {!isAttended && (
                        <Styled.CenteredTd>
                          <Button
                            onClick={() =>
                              Controller.goToNegotiation(
                                visualization.driverId,
                                visualization.loadId,
                                history
                              )
                            }
                            size="sm"
                          >
                            <Text type="regular">Negociar</Text>
                          </Button>
                        </Styled.CenteredTd>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <Text>Nenhuma visualização.</Text>
            )}
          </Col>
        </Row>
      </Card>
    </Col>
  );
}
