import Badge from '@material-ui/core/Badge';
import styled, { css } from 'styled-components';

export const StyledBadge = styled(Badge)`
  && {
    padding: 15px;
    width: 100%;
    border-radius: 0px;
    box-shadow: ${props => props.theme.shadow};
    ${({ bordered, theme, bordercolor = 'secondary_light' }) =>
      bordered &&
      css`
        border-left: 4px solid ${theme.colors[bordercolor]};

        -webkit-transition: border-color 0.3s;
      `}

    ${({ form }) =>
      form &&
      css`
        padding: 30px;
      `}

    h1 {
      font-size: 12px;
    }

    h5 {
      font-size: 12px;
      font-weight: 500;
    }
  }
`;
