import React from 'react';
import { ToggleHeader } from 'v3/pages/Policy/styles';
import { usePolicyRegister } from '../../context';
import { Text } from 'v3/components';
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';

export function RangeSectionToggleHeader({
  opIndex,
  productIndex,
  vehicleIdx,
  rangeIdx,
  keyName,
  keyValue,
  label,
}) {
  const { handleAddRangeItem } = usePolicyRegister();
  return (
    <ToggleHeader>
      <button
        onClick={() =>
          handleAddRangeItem(
            opIndex,
            productIndex,
            vehicleIdx,
            rangeIdx,
            keyName,
            keyValue ? false : true
          )
        }
      >
        {keyValue ? <AiOutlineArrowUp /> : <AiOutlineArrowDown />}
      </button>

      <Text type="body" color="dark" weight={500}>
        {label}
      </Text>
    </ToggleHeader>
  );
}
