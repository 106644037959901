import React, { useState, useCallback, useEffect } from 'react';
import moment from 'moment';
import dashboardApi from 'services/apis/dashboard';
import { Table, Text, Link } from 'v3/components';
import { formatDateTime, formatCurrency } from 'v3/utils/formatter';
import { useQuery } from 'hooks';
import Card, { Number, Description, SeeAll } from './Card';

export default function LoadBallance({ dashboard, name, id }) {
  const [isFetching, setFetching] = useState(false);
  const [data, setData] = useState({});

  const queryParams = useQuery();

  function setQueryParams(filters) {
    const { tags = [] } = filters;
    queryParams.delete('tags');

    if (tags) {
      tags.forEach(tag => {
        queryParams.append('tags', tag.id);
      });
    }

    if (tags?.length === 0) {
      queryParams.set('no-tags', true);
    } else {
      queryParams.delete('no-tags');
    }
  }

  const fetchLoadsNegotiatorBallance = useCallback(async (filters = {}) => {
    try {
      setFetching(true);

      const {
        tags = [],
        clients = [],
        costCenters = [],
        pcps = [],
        companies = [],
      } = filters;

      setQueryParams(filters);

      const response = await dashboardApi.get(
        'loads-attendeds-by-user-ballance',
        {
          params: {
            tags: tags?.map(tag => tag.id),
            clients: clients?.map(client => client.id),
            costCenters: costCenters?.map(costCenter => costCenter?.id),
            pcps: pcps?.map(pcp => pcp?.id),
            companies: companies?.map(company => company?.id),
          },
        }
      );

      setData(response.data);
    } catch (ex) {
      // Handle exception
    } finally {
      setFetching(false);
    }
  }, []);

  useEffect(() => {
    const startDate = moment().subtract(1, 'month').format('YYYY-MM-DD');
    queryParams.set('startDate', startDate);
  }, []);

  return (
    <Card
      title="Total faturado por você"
      isLoading={isFetching}
      fetchFn={fetchLoadsNegotiatorBallance}
      onFiltersChanged={fetchLoadsNegotiatorBallance}
      dashboard={dashboard}
      name={name}
      excludeFilterByBranchOffice={true}
      id={id}
      excludeFilterByDate
      excludeFilterByNegotiator
      ListComponent={
        <>
          <Table striped>
            <thead>
              <tr>
                <th>
                  <Text weight="500" color="dark">
                    Centro de custo
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Empresa
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Tags da carga
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Carga
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Criado em
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Origem
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Destino
                  </Text>
                </th>
              </tr>
            </thead>
            <tbody>
              {data.last?.map(load => {
                const title = `Cliente ${load.social_name} - ${load.cgccpf}`;

                return (
                  <tr>
                    <td>
                      <Text type="regular" color="gray">
                        {load.cost_center || '-'}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray">
                        {load.company_name || '-'}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray">
                        {load.load_tags || '-'}
                      </Text>
                    </td>
                    <td>
                      <Link
                        to={`/cargas/${load.id}`}
                        title={title}
                        target="_blank"
                      >
                        {load.id}
                      </Link>
                    </td>
                    <td>
                      <Text type="regular" color="gray">
                        {formatDateTime(load.created_at)}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray">
                        {load.city_origin} - {load.uf_origin}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray">
                        {load.city_destination} - {load.uf_destination}
                      </Text>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {!!data?.total && (
            <SeeAll
              to={{ pathname: '/cargas', search: queryParams.toString() }}
            >
              ver todas as {data?.total} cargas
            </SeeAll>
          )}
        </>
      }
      NumericComponent={
        <div className="d-flex justify-content-center text-center flex-column">
          <Number>{formatCurrency(data?.sum)}</Number>
          <Description>neste mês</Description>
        </div>
      }
    />
  );
}
