/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Col, Row } from 'react-bootstrap';

import api from 'services/api';

import Modal from 'v3/components/Modal';
import Radio from 'v3/components/Radio';
import Select from 'v3/components/Select';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import { useSnackbar } from 'v3/components/Snackbar';

function Vehicle({
  currentVehicle,
  setCurrentVehicle,
  vehicles,
  setVehicles,
  isOpen,
  onClose,
  setShow,
}) {
  const [vehicle, setVehicle] = useState();
  const [error, setError] = useState('');
  const [mainVehicle, setMainVehicle] = useState(false);
  const snackbar = useSnackbar();

  useEffect(() => {
    setVehicle(currentVehicle);
  }, [currentVehicle]);

  async function fetchVehicles(search) {
    try {
      const response = await api.get('vehicle/license_plate', {
        params: { search },
      });
      return response.data;
    } catch {
      snackbar.show(<Text> Erro ao pesquisar veículo! </Text>, {
        type: 'error',
      });
    }
    return false;
  }

  function AddVehicle(event) {
    event.preventDefault();

    if (vehicles.find(element => element.id === vehicle.id)) {
      setError('Veículo já adicionado');
      return;
    }

    if (vehicle) {
      setVehicles([
        ...vehicles.map(item =>
          mainVehicle ? { ...item, current: false } : item
        ),
        { ...vehicle, current: vehicles?.length ? mainVehicle : true },
      ]);

      setCurrentVehicle(null);
      setVehicle(null);
      setError('');
      setMainVehicle(false);
      setShow(false);
    }
  }

  return (
    <>
      <Modal
        show={isOpen}
        onHide={onClose}
        heading={
          <Text color="dark" type="header">
            Veículo
          </Text>
        }
        body={
          <form onSubmit={AddVehicle}>
            <Row>
              <Col xs={12}>
                <Select.Async
                  label="Veículo"
                  onSearch={fetchVehicles}
                  value={vehicle}
                  onChange={value => {
                    setVehicle(value);
                  }}
                  getOptionLabel={option => option.plate}
                  getOptionValue={option => option.id}
                  placeholder="Selecione a placa..."
                  error={error}
                  isDisabled={currentVehicle}
                />
              </Col>
              {!!vehicles?.length && (
                <Col xs={12} className="mt-3">
                  <Radio.Group
                    label="Tornar esse motorista atual"
                    onChange={({ target }) => setMainVehicle(target.value)}
                    value={mainVehicle}
                    horizontal
                  >
                    <Radio value>
                      <Text color="dark" type="label">
                        Sim
                      </Text>
                    </Radio>
                    <Radio value={false}>
                      <Text color="dark" type="label">
                        Não
                      </Text>
                    </Radio>
                  </Radio.Group>
                </Col>
              )}
              <Col
                xs={12}
                className="align-items-center d-flex justify-content-center my-4"
              >
                <Button type="submit" disabled={!vehicle}>
                  <Text weigth={500} type="regular">
                    Adicionar
                  </Text>
                </Button>
              </Col>
            </Row>
          </form>
        }
      />
    </>
  );
}

export default Vehicle;
