import React, { useState, useEffect } from 'react';

import { Row, Col } from 'react-bootstrap';
import moment from 'moment';

import api from 'services/api';

import ModalRaw from 'v3/components/Modal';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import Select from 'v3/components/Select';
import Input from 'v3/components/Input';
import DatePicker from 'v3/components/DatePicker';

import { useSnackbar } from 'v3/components/Snackbar';

function Modal({ isOpen, onClose, driver, riskManagers, setRiskManagers }) {
  const snackbar = useSnackbar();

  const [selectedRiskManager, setSelectedRiskManager] = useState();
  const [riskManagerCode, setRiskManagerCode] = useState('');
  const [dueDate, setDueDate] = useState();
  const [loading, setLoading] = useState(false);

  async function fetchRiskManager(search) {
    try {
      const response = await api.get('risk-manager-for-select?', {
        params: { search },
      });

      return response.data;
    } catch (error) {
      return [];
    }
  }

  async function saveLiberation() {
    try {
      setLoading(true);
      const due_date = moment(dueDate, 'DD/MM/YYYY').toDate();
      const data = {
        due_date,
        risk_manager_code: riskManagerCode,
        risk_manager_id: selectedRiskManager.id,
        person_id: driver.id,
        status: '1',
      };

      if (moment(due_date).isBefore(new Date())) {
        snackbar.show(<Text>Data inválida</Text>, { type: 'error' });
        setLoading(false);
        return;
      }

      const response = await api.post('driver/risk-manager', data);

      setRiskManagers([response.data, ...riskManagers]);

      setSelectedRiskManager(null);
      setDueDate(null);
      setRiskManagerCode(null);
      onClose();
    } catch (error) {
      snackbar.show(<Text>Erro ao salvar liberação</Text>, { type: 'error' });
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <ModalRaw
        show={isOpen}
        onHide={() => {
          onClose();
          setSelectedRiskManager(null);
          setDueDate(null);
          setRiskManagerCode(null);
        }}
        size="lg"
        heading={
          <>
            <Text color="dark" weight="500" type="header">
              Liberação gerenciadora de risco
            </Text>
          </>
        }
        body={
          <Row>
            <Col xs={12} className="mb-3">
              <Select.Async
                label="Gerenciadora de risco"
                value={selectedRiskManager}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                onSearch={search => fetchRiskManager(search)}
                onChange={value => {
                  setSelectedRiskManager(value);
                }}
              />
            </Col>
            <Col md={6} className="mb-3">
              <Input
                label="Código da gerenciadora de risco"
                value={riskManagerCode}
                onChange={event => {
                  setRiskManagerCode(event.target.value);
                }}
              />
            </Col>
            <Col md={6} className="mb-3">
              <DatePicker
                guide
                value={dueDate}
                label="Data de validade"
                onChange={event => setDueDate(event.target.value)}
              />
            </Col>
          </Row>
        }
        footer={
          <Col md={12} xs={12} className="text-right">
            <Button
              variant="success"
              className="py-2 "
              onClick={() => saveLiberation()}
              disabled={!dueDate || !riskManagerCode || !selectedRiskManager}
              loading={loading}
            >
              <Text weight="500" type="regular">
                Salvar
              </Text>
            </Button>
          </Col>
        }
      />
    </>
  );
}

export default Modal;
