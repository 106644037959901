import styled from 'styled-components';
import { Text } from 'v3/components';
import theme from 'v3/theme';

export const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  gap: 1rem;
`;

export const StyledMarginContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 50%;
`;

export const StyledMarginText = styled(Text)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  & > span {
    color: ${theme.colors.gray};
    font-weight: 500;
  }
`;

export const InfoContainer = styled.div`
  width: 50%;
  @media (max-width: 1200px) {
    width: 100%;
  }
`;

export const AttachmentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  padding: 0.5rem;
  justify-content: flex-end;
`;
