import React from 'react';
import { FaPlus, FaCheck, FaPen, FaFileSignature } from 'react-icons/fa';
import theme from 'v3/theme';
import { formatDateTime } from 'v3/utils/formatter';
import { Text } from 'v3/components';
import { IconButton } from '../styles';
import { ActionButtonsContainer } from './styles';

export function ActionButtons({
  show,
  setShow,
  selectedOccurrence,
  setSelectedOccurrence,
  setStartDate,
  setEndDate,
  loading,
  setOccurrenceValue,
}) {
  return (
    <ActionButtonsContainer>
      <IconButton
        iconColor={theme.colors.info}
        onClick={() => {
          setSelectedOccurrence({});
          setShow(old => ({ ...old, addNewOccurrence: true }));
        }}
        disabled={show.addNewOccurrence || loading}
      >
        <FaPlus />
        <Text type="body" color="dark" weight="500">
          Inserir
        </Text>
      </IconButton>
      <IconButton
        iconColor={theme.colors.success}
        onClick={() => {
          setStartDate(formatDateTime(selectedOccurrence.startDate));
          setEndDate(
            selectedOccurrence.endDate
              ? formatDateTime(selectedOccurrence.endDate)
              : null
          );
          setShow(old => ({ ...old, endOccurrence: true }));
          setOccurrenceValue(selectedOccurrence?.value);
        }}
        disabled={
          (selectedOccurrence?.id && selectedOccurrence?.pendency === false) ||
          (selectedOccurrence?.id &&
            selectedOccurrence?.pendency === true &&
            selectedOccurrence?.userConclusion) ||
          Object.keys(selectedOccurrence).length === 0
        }
      >
        <FaCheck />
        <Text type="body" color="dark" weight="500">
          Concluir
        </Text>
      </IconButton>
      <IconButton
        iconColor={theme.colors.gray}
        disabled={
          (selectedOccurrence?.id && selectedOccurrence?.pendency === false) ||
          (selectedOccurrence?.id &&
            selectedOccurrence?.pendency === true &&
            selectedOccurrence?.userConclusion) ||
          Object.keys(selectedOccurrence).length === 0
        }
        onClick={() => {
          setShow(old => ({ ...old, changeResponsible: true }));
        }}
      >
        <FaPen />
        <Text type="body" color="dark" weight="500">
          Alterar responsabilidade
        </Text>
      </IconButton>
      <IconButton
        iconColor={theme.colors.yellow_rating_star}
        disabled={
          (selectedOccurrence?.id && selectedOccurrence?.pendency === false) ||
          (selectedOccurrence?.id &&
            selectedOccurrence?.pendency === true &&
            selectedOccurrence?.userConclusion) ||
          Object.keys(selectedOccurrence).length === 0
        }
        onClick={() => {
          setStartDate(formatDateTime(selectedOccurrence.startDate));
          setEndDate(
            selectedOccurrence.endDate
              ? formatDateTime(selectedOccurrence.endDate)
              : null
          );
          setShow(old => ({
            ...old,
            addDescriptionToOccurrence: true,
          }));

          setOccurrenceValue(selectedOccurrence?.value);
        }}
      >
        <FaFileSignature />
        <Text type="body" color="dark" weight="500">
          Adicionar descrição
        </Text>
      </IconButton>
    </ActionButtonsContainer>
  );
}
