import React, { useState, useEffect } from 'react';
import moment from 'moment';

import api from 'services/api';
import apiReport from 'services/apis/report';
import { Row, Col } from 'react-bootstrap';
import ApplicationLayout from 'v3/layouts/Application';
import Select from 'v3/components/Select';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import { DatePicker, InfiniteScroll } from 'v3/components';
import { useSnackbar } from 'v3/components/Snackbar';
import { formatDate } from 'v3/utils/formatter';
import { usePermission } from 'hooks';

import FileSaver from 'file-saver';
import Card from './Card';

export default function RecordsPerUser() {
  const snackbar = useSnackbar();

  usePermission('VISUALIZAR_RELATORIO_CADASTROS_POR_USUARIO', {
    redirect: true,
  });

  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [usersRevenues, setUserRevenues] = useState([]);
  const [pagination, setPagination] = useState({
    lastPage: 1,
    currentPage: 1,
    total: 0,
  });
  const [dates, setDates] = useState({
    from: formatDate(moment(new Date()).subtract(1, 'month')),
    to: formatDate(new Date()),
  });

  const [users, setUsers] = useState([]);

  async function fetchSelectOptions(params) {
    try {
      const { data } = await api.get('user/select', { params });

      return data;
    } catch (err) {
      return [];
    }
  }

  function renderItem(user) {
    return <Card user={user} />;
  }

  function dateFormatter(date, end) {
    const formattedDate = moment(date.split('/').reverse().join('-')).add(
      3,
      'h'
    );
    if (end) {
      formattedDate.add(1, 'd');
    }
    return formattedDate.format('YYYY-MM-DD hh:mm:ss');
  }

  async function fetchRecords(page) {
    setLoading(true);
    try {
      const start_date = dateFormatter(dates.from);
      const end_date = dateFormatter(dates.to, true);

      const response = await apiReport.get(`records-per-user`, {
        params: {
          page,
          start_date,
          end_date,
          users: users.map(user => user.id),
        },
      });

      const { data, ...rest } = response.data;

      setUserRevenues(data);
      setPagination(rest?.pagination);
    } catch (error) {
      snackbar.show(
        error.response?.data?.error ||
          'Algo deu errado, tente novamente mais tarde',
        { type: 'error' }
      );
    } finally {
      setLoading(false);
    }
  }

  function setDate(key, value) {
    setDates(old => {
      return {
        ...old,
        [key]: value,
      };
    });
  }

  async function exportReport() {
    setExportLoading(true);
    try {
      const start_date = dateFormatter(dates.from);
      const end_date = dateFormatter(dates.to, true);

      const response = await apiReport.get(`records-per-user`, {
        params: {
          start_date,
          end_date,
          export: 'true',
          users: users.map(user => user.id),
        },
        responseType: 'blob',
      });

      FileSaver.saveAs(response.data, response.headers['x-file-name']);
    } catch (error) {
      snackbar.show('Erro ao buscar relatórios', { type: 'error' });
    } finally {
      setExportLoading(false);
    }
  }

  useEffect(() => {
    function onPressedKey(e) {
      if (e.key === 'Enter') {
        fetchRecords(pagination.currentPage);
      }
    }

    window.addEventListener('keyup', onPressedKey);

    return () => {
      window.removeEventListener('keyup', onPressedKey);
    };
  });

  return (
    <ApplicationLayout title="Validações de cadastros por usuário">
      <Row className="filter d-flex justify-content-center space-between">
        <Col md={2} xs={12}>
          <DatePicker
            label="Período de *"
            value={dates.from}
            onChange={({ target }) => setDate('from', target.value)}
            labelColor="#fff"
          />
        </Col>
        <Col md={2} xs={12}>
          <DatePicker
            label="Até *"
            value={dates.to}
            onChange={({ target }) => setDate('to', target.value)}
            labelColor="#fff"
          />
        </Col>
        <Col md={4} xs={12}>
          <Select.Async
            label="Usuários *"
            modalHeading="Adicione um usuário"
            modalBodyTitle="Usuários:"
            labelColor="#fff"
            horizontal
            value={users}
            onSearch={search => fetchSelectOptions({ search })}
            onChange={value => (value ? setUsers(value) : setUsers([]))}
            multiple
            getOptionLabel={option => {
              let label = `${option?.username}`;

              if (option?.cpf) {
                label += ` - ${option?.cpf}`;
              }

              return label;
            }}
            getOptionValue={option => option.id}
          />
        </Col>
        <Col lg={2} xs={12} className="vertical bottom">
          <Button
            disabled={!users[0]}
            variant="success"
            loading={loading}
            onClick={() => fetchRecords(pagination.currentPage)}
            className="w-100"
          >
            <Text type="regular" weight={500}>
              Filtrar
            </Text>
          </Button>
        </Col>
        <Col lg={2} xs={12} className="vertical bottom">
          <Button
            disabled={!users[0]}
            variant="primary"
            loading={exportLoading}
            onClick={exportReport}
            className="w-100"
          >
            <Text type="regular" weight={500}>
              Exportar
            </Text>
          </Button>
        </Col>
        <Col md={10} className="mt-3">
          <InfiniteScroll
            data={usersRevenues}
            onEndReach={() => {
              fetchRecords(pagination.currentPage + 1);
            }}
            hasMore={pagination.currentPage < pagination.lastPage}
            scrollThreshold={0.7}
            renderItem={renderItem}
            loading={loading}
          />
        </Col>
      </Row>
    </ApplicationLayout>
  );
}
