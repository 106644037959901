import React from 'react';
import { RangeSectionToggleHeader } from './RangeSectionToggleHeader';
import {
  GeneralItems,
  GeneralItemsContainer,
  GeneralItemsSelectInfo,
  GeneralItemsTextInfo,
  RiskAreaItem,
  RiskAreaItemsContainer,
  RiskAreaSectionContainer,
} from 'v3/pages/Policy/styles';
import { Input, Select, Text } from 'v3/components';
import { usePolicyRegister } from '../../context';
import { AiOutlineClose } from 'react-icons/ai';
import TextArea from 'v3/components/TextArea';
import { FaPlus } from 'react-icons/fa';

export function GeneralRulesSection({
  opIndex,
  productIndex,
  vehicleIdx,
  rangeIdx,
  range,
}) {
  const {
    handleAddRangeItem,
    handleOpenRiskAreaModal,
    handleRemoveRiskAreaItem,
    windowWidth,
    sensorsOptions,
    errors,
  } = usePolicyRegister();

  function handleSearchSensors(search) {
    if (!search) {
      return sensorsOptions;
    }
    const parsed = search?.toLocaleLowerCase();
    return sensorsOptions?.filter(
      option => option?.name?.toLocaleLowerCase().search(parsed) >= 0
    );
  }

  return (
    <>
      <RangeSectionToggleHeader
        opIndex={opIndex}
        productIndex={productIndex}
        vehicleIdx={vehicleIdx}
        rangeIdx={rangeIdx}
        keyName={'showGeneralRules'}
        keyValue={range?.showGeneralRules}
        label={'Regras Gerais'}
      />

      {range?.showGeneralRules && (
        <GeneralItemsContainer>
          <GeneralItems>
            <GeneralItemsTextInfo>
              <div>
                <div className="timeContainer">
                  <Input
                    label={'Horário rodagem'}
                    labelWhiteSpace="nowrap"
                    value={range?.starting_schedule ?? ''}
                    onChange={e =>
                      handleAddRangeItem(
                        opIndex,
                        productIndex,
                        vehicleIdx,
                        rangeIdx,
                        'starting_schedule',
                        e?.target?.value
                      )
                    }
                    masked
                    mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                    type="time"
                  />

                  <Input
                    label={'Horário'}
                    labelColor={'#fff'}
                    value={range?.finishing_schedule ?? ''}
                    onChange={e =>
                      handleAddRangeItem(
                        opIndex,
                        productIndex,
                        vehicleIdx,
                        rangeIdx,
                        'finishing_schedule',
                        e?.target?.value
                      )
                    }
                    masked
                    mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                    type="time"
                  />
                </div>
                <Input
                  label={'Idade (anos) máxima veículo tração'}
                  type="number"
                  onKeyDown={evt =>
                    ['e', 'E', '+', '-'].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  value={range?.age_traction_max ?? 0}
                  onChange={e => {
                    if (e?.target?.value < 0 || e?.target?.value?.length > 3) {
                      return;
                    }
                    handleAddRangeItem(
                      opIndex,
                      productIndex,
                      vehicleIdx,
                      rangeIdx,
                      'age_traction_max',
                      e?.target?.value
                    );
                  }}
                />

                <Input
                  label={'Tempo (dias) mínimo antt'}
                  type="number"
                  onKeyDown={evt =>
                    ['e', 'E', '+', '-'].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  value={range?.min_time_antt ?? 0}
                  onChange={e => {
                    if (e?.target?.value < 0 || e?.target?.value?.length > 9) {
                      return;
                    }
                    handleAddRangeItem(
                      opIndex,
                      productIndex,
                      vehicleIdx,
                      rangeIdx,
                      'min_time_antt',
                      e?.target?.value
                    );
                  }}
                />

                <Input
                  label={'Idade (anos) máxima implemento'}
                  type="number"
                  onKeyDown={evt =>
                    ['e', 'E', '+', '-'].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  value={range?.age_implement_max ?? 0}
                  onChange={e => {
                    if (e?.target?.value < 0 || e?.target?.value?.length > 3) {
                      return;
                    }
                    handleAddRangeItem(
                      opIndex,
                      productIndex,
                      vehicleIdx,
                      rangeIdx,
                      'age_implement_max',
                      e?.target?.value
                    );
                  }}
                />
                <Input
                  label={'Quantidade mínima de viagens motorista'}
                  type="number"
                  value={range?.driver_travel_min ?? 0}
                  onKeyDown={evt =>
                    ['e', 'E', '+', '-'].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  onChange={e => {
                    if (e?.target?.value < 0 || e?.target?.value?.length > 9) {
                      return;
                    }
                    handleAddRangeItem(
                      opIndex,
                      productIndex,
                      vehicleIdx,
                      rangeIdx,
                      'driver_travel_min',
                      e?.target?.value
                    );
                  }}
                />
              </div>
            </GeneralItemsTextInfo>

            <GeneralItemsSelectInfo>
              <Select.Async
                closeMenuOnSelect={false}
                labelColor="dark"
                label="Sensores / Atuadores"
                value={range?.sensors}
                placeholder="Selecione"
                multiple
                horizontal={windowWidth <= 992}
                modalHeading={'GRs'}
                modalBodyTitle={'Selecione um sensor/atuador'}
                options={sensorsOptions}
                onSearch={search => handleSearchSensors(search)}
                onChange={value =>
                  handleAddRangeItem(
                    opIndex,
                    productIndex,
                    vehicleIdx,
                    rangeIdx,
                    'sensors',
                    value
                  )
                }
                getOptionLabel={option => option.name ?? option?.label}
                getOptionValue={option => option.id}
                selectValuesContainerMaxHeight="200px"
                selectValuesContainerOverflowY="scroll"
              />
            </GeneralItemsSelectInfo>
          </GeneralItems>
          <div className="textObs">
            <RiskAreaSectionContainer>
              <header>
                <Text type="regular" color="dark">
                  Áreas de Risco
                </Text>
                <button
                  onClick={() =>
                    handleOpenRiskAreaModal(
                      opIndex,
                      productIndex,
                      vehicleIdx,
                      rangeIdx
                    )
                  }
                >
                  <FaPlus />
                  <Text color="dark" type="medium" weight={500}>
                    Incluir área de risco
                  </Text>
                </button>
              </header>
              <RiskAreaItemsContainer>
                {range?.riskAreas?.map((item, itemIdx) => {
                  return (
                    <RiskAreaItem>
                      <Text type="medium" color="dark">
                        {item?.city?.name}
                        {' - '}
                        {item?.radius}
                        {'km'}
                      </Text>
                      <button
                        onClick={() =>
                          handleRemoveRiskAreaItem(
                            opIndex,
                            productIndex,
                            vehicleIdx,
                            rangeIdx,
                            itemIdx
                          )
                        }
                      >
                        <AiOutlineClose />
                      </button>
                    </RiskAreaItem>
                  );
                })}
              </RiskAreaItemsContainer>
            </RiskAreaSectionContainer>

            <TextArea
              label="Validade Checklist"
              value={range?.checklist_validity}
              onChange={e =>
                handleAddRangeItem(
                  opIndex,
                  productIndex,
                  vehicleIdx,
                  rangeIdx,
                  'checklist_validity',
                  e?.target?.value
                )
              }
              error={
                errors[
                  `operations[${opIndex}].products[${productIndex}].vehicles[${vehicleIdx}].ranges[${rangeIdx}].checklist_validity`
                ]
              }
            />

            <TextArea
              label="Observações"
              value={range?.obs}
              onChange={e =>
                handleAddRangeItem(
                  opIndex,
                  productIndex,
                  vehicleIdx,
                  rangeIdx,
                  'obs',
                  e?.target?.value
                )
              }
            />
            <TextArea
              label="Observações Coopercarga"
              value={range?.obs_company}
              onChange={e =>
                handleAddRangeItem(
                  opIndex,
                  productIndex,
                  vehicleIdx,
                  rangeIdx,
                  'obs_company',
                  e?.target?.value
                )
              }
            />
          </div>
        </GeneralItemsContainer>
      )}
    </>
  );
}
