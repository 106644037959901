import styled from 'styled-components';
import Accordions from 'react-bootstrap/Accordion';
import Buttons from '../Button';
import Texts from '../Text';

export const Button = styled(Buttons)``;
export const Accordion = styled(Accordions)``;
export const Line = styled.hr`
  border: 1px solid #f2f2f2;
  width: 50%;
  margin-top: 0px !important;
  margin-bottom: 35px;
`;

export const Text = styled(Texts)``;
