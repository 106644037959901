import React, { useEffect, useState, useMemo } from 'react';

import { useParams } from 'react-router-dom';

import { Row, Col } from 'react-bootstrap';
import { Input, Text, Select } from 'v3/components';
import Radio from 'v3/components/Radio';
import api from 'services/api';

import { FaEyeSlash, FaEye } from 'react-icons/fa';

import { useUser } from './context';
import CheckBox from 'components/CheckBox';
import theme from 'v3/theme';

export default function UserData() {
  const user = useUser();
  const params = useParams();

  const [verifyPassword, setVerifyPassword] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmedShown, setConfirmedShown] = useState(false);
  const [errors, setErrors] = useState({});
  const [sitesOptions, setSitesOptions] = useState([]);

  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };

  const toggleShowConfirmed = () => {
    setConfirmedShown(!confirmedShown);
  };

  useEffect(() => {
    if (!params.id)
      user.setData({ email: '', verified: false, change_password: true });
    user.setData({ verifyEmail: '' });
  }, []);

  useEffect(() => {
    if (!params.id) user.setData({ change_password: true });
    user.setData({ verifyEmail: '' });
  }, []);

  function handleVerifyPassword() {
    if (user.data.password !== user.data.verifyPassword) {
      setErrors('As senhas não conferem');
    }
  }

  const availableSiteOptions = useMemo(
    () =>
      sitesOptions.filter(
        site => !user.data.sites?.some(userSite => userSite.id === site.id)
      ),
    [sitesOptions, user.data.sites]
  );

  useEffect(() => {
    async function fetchSites() {
      try {
        const { data: response } = await api.get('sites');
        setSitesOptions(response);
      } catch (err) {
        // handle exception
      }
    }
    fetchSites();
  }, []);

  return (
    <Row>
      <Col xs={12} className="mb-3">
        <Input
          label={`E-mail ${user.data.profile?.name !== 'Motorista' ? '*' : ''}`}
          type="text"
          value={user?.data?.email}
          onChange={e => {
            user.setData({ email: e.target.value });
          }}
          error={
            user.errors?.['data.email'] ||
            user.errors?.email ||
            user.errors?.['access.email']
          }
        />
      </Col>
      <Col xs={12} className="mb-3">
        <Input
          label="Confirmar e-mail"
          type="text"
          value={user.data.verifyEmail}
          onChange={event => {
            user.setData({ verifyEmail: event.target.value });
          }}
        />
      </Col>
      <Col xs={12} className="mb-3">
        <Input
          label="Senha *"
          type={passwordShown ? 'text' : 'password'}
          value={user?.data?.password}
          onChange={e => user.setData({ password: e.target.value })}
          error={
            user.errors?.['data.password'] ||
            user.errors?.password ||
            user.errors?.['access.password']
          }
          rightIcon={
            passwordShown ? (
              <FaEyeSlash color="#464E5F" />
            ) : (
              <FaEye color="#464E5F" />
            )
          }
          rightIconAction={togglePasswordVisiblity}
        />
      </Col>
      <Col xs={12} className="mb-3">
        <Input
          label="Confirmar senha"
          type={confirmedShown ? 'text' : 'password'}
          value={verifyPassword}
          onBlur={handleVerifyPassword}
          onChange={event => {
            user.setData({ verifyPassword: event.target.value });
            setVerifyPassword(event.target.value);
          }}
          rightIcon={
            confirmedShown ? (
              <FaEyeSlash color="#464E5F" />
            ) : (
              <FaEye color="#464E5F" />
            )
          }
          rightIconAction={toggleShowConfirmed}
          errors={errors}
        />
      </Col>
      <Col xs={12} className="mb-3">
        <CheckBox
          label={'Alterar senha'}
          checked={user?.data?.change_password === true}
          onChange={() => {
            user.setData({
              change_password: !user?.data?.change_password,
            });
          }}
          labelStyle={{
            textTransform: 'capitalize',
            color: theme.colors.dark,
          }}
        />
      </Col>
      {params.id && (
        <Col xs={12} className="mb-3">
          <Radio.Group
            label="Verificado por SMS"
            onChange={e => {
              user.setData({ verified: e.target.value });
            }}
            value={user?.data?.verified}
            horizontal
          >
            <Radio value>
              <Text color="dark" type="label">
                Sim
              </Text>
            </Radio>
            <Radio value={false}>
              <Text color="dark" type="label">
                Não
              </Text>
            </Radio>
          </Radio.Group>
        </Col>
      )}
      {user?.data?.profile?.name !== 'Motorista' && (
        <>
          <Col xs={12} className="mb-3">
            <Select.Async
              label="Portais com acesso"
              modalHeading="Adicione um portal"
              modalBodyTitle="Portais"
              horizontal
              onSearch={search =>
                availableSiteOptions.filter(item =>
                  item.name.toLowerCase().includes(search)
                )
              }
              options={availableSiteOptions}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              value={user.data?.sites}
              onChange={value => user.setData({ sites: value })}
              error={user.errors?.sites}
              multiple
            />
          </Col>
        </>
      )}
    </Row>
  );
}
