/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { validatePermission } from 'actions/index';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import { useSnackbar } from 'v3/components/Snackbar';
import ApplicationLayout from 'v3/layouts/Application';
import InfiniteScroll from 'v3/components/InfiniteScroll';
import { usePermission } from 'hooks';
import Card from './Card';
import * as Controller from './controller';

export default function List() {
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [pagination, setPagination] = useState({
    lastPage: 1,
    page: 1,
    total: 0,
  });
  const [hasPermissionToCreate, setPermissionToCreate] = useState();
  usePermission('VISUALIZAR_FORMAS_DE_PAGAMENTO', { redirect: true });

  useState(() => {
    setPermissionToCreate(validatePermission('CADASTRAR_FORMAS_DE_PAGAMENTO'));
  }, []);

  function fetchPaymentMethods() {
    Controller.getPaymentMethods(pagination.page, {
      setPaymentMethods,
      setLoading,
      setPagination,
      snackbar,
    });
  }

  useEffect(() => {
    fetchPaymentMethods();
  }, []);

  return (
    <ApplicationLayout
      title="Formas de pagamento"
      RightComponent={
        <>
          {hasPermissionToCreate && (
            <Link to="/formas-de-pagamento/cadastrar">
              <Button variant="primary" style={{ marginLeft: '10px' }}>
                <Text weight={500} color="white" type="regular">
                  Nova forma de pagamento
                </Text>
              </Button>
            </Link>
          )}
        </>
      }
    >
      <Container>
        <InfiniteScroll
          loading={loading}
          data={paymentMethods}
          renderItem={item => <Card paymentMethod={item} snackbar={snackbar} />}
          onEndReach={() => {
            Controller.getPaymentMethods(pagination.page + 1, {
              setPaymentMethods,
              setLoading,
              setPagination,
            });
          }}
          hasMore={pagination.page < pagination.lastPage}
          scrollThreshold={0.7}
        />
      </Container>
    </ApplicationLayout>
  );
}
