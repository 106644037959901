import api from 'services/api';
import schema, { lengthSchema } from './validator';

export async function validateAdd(data, setErrors) {
  const errorList = {};
  try {
    await schema.validate(data, { abortEarly: false });
    setErrors({ ...errorList });
    return true;
  } catch (err) {
    err.inner.forEach(error => {
      errorList[error.path] = error.message;
    });

    setErrors({ ...errorList });
    return false;
  }
}

export async function validateSave(data, setErrors) {
  const errorList = {};
  try {
    await lengthSchema.validate(data, { abortEarly: false });
    setErrors({ ...errorList });
    return true;
  } catch (err) {
    err.inner.forEach(error => {
      errorList[error.path] = error.message;
    });

    setErrors({ ...errorList });
    return false;
  }
}

export async function handleSave(
  data,
  issuer_id,
  fetchIssuers,
  setIsEdit,
  showSnackbar,
  setLoading,
  setErrors,
  setUnchangedSeriesList
) {
  setLoading(true);
  if (!(await validateSave(data, setErrors))) {
    showSnackbar('Adicione pelo menos uma série de MDFe!', { type: 'error' });
    return false;
  }
  try {
    await api.put(`issuer-series/update-mdfe-series/${issuer_id}`, data);

    await fetchIssuers();
    setIsEdit(false);
    showSnackbar('Séries editadas com sucesso', { type: 'success' });
  } catch (err) {
    showSnackbar(err.response.data.error || 'Erro ao salvar!', {
      type: 'error',
    });
  }

  setLoading(false);
  setUnchangedSeriesList([]);
  return true;
}
