import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import Tooltip from 'v3/components/Tooltip';

import { ensureURL } from 'v3/utils/string';

function PDF() {
  const params = useParams();

  const [loading, setLoading] = useState(false);

  async function handleFetchPDF() {
    setLoading(true);
    try {
      window
        .open(
          `${ensureURL(process.env.REACT_APP_API_URL)}/travel/pdf/${params.id}`,
          '_blank'
        )
        .focus();
    } catch (ex) {
      //
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {loading ? (
        <Tooltip
          placement="left"
          content={
            <Text type="label">Gerando ordem de carregamento da viagem...</Text>
          }
        >
          <Button variant="primary" className="ml-2 my-2" disabled>
            <Text weight={500} type="regular">
              Ordem de carregamento
            </Text>
          </Button>
        </Tooltip>
      ) : (
        <Tooltip
          placement="left"
          content={
            <Text type="label">Ordem de carregamento em formato PDF.</Text>
          }
        >
          <Button
            variant="primary"
            className="ml-2 py-2"
            onClick={() => handleFetchPDF()}
          >
            <Text weight={500} type="regular">
              Ordem de carregamento
            </Text>
          </Button>
        </Tooltip>
      )}
    </>
  );
}

export default PDF;
