import styled from 'styled-components';

import { Grid } from '@material-ui/core';

export const ContentInvoice = styled(Grid)`
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      color: ${props => props.theme.colors.secondary};
      text-transform: uppercase;
      margin: 8px 0;
      label {
        font-weight: bold;
        margin-right: 4px;
      }
    }
  }
  .accept {
    i {
      color: green;
    }
  }

  .reject {
    i {
      color: orangered;
    }
  }

  .resend {
    i {
      color: orange;
    }
  }
`;
