import React, { useState, useEffect } from 'react';
import { useTravel } from 'v3/pages/Travel';
import {
  Area,
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend
} from 'recharts';

import { Text } from 'v3/components';

function Temperature({ handleClickTemperature, setLatLngTemperature }) {
  const travel = useTravel();

  const [data, setData] = useState([]);

  useEffect(() => {
    const mergedData = travel?.data?.loadTemperature.map(temp => {
      return {
        captureData: temp.datetimeTemperature,
        Temperatura1: temp.temperature1 !== 'N' ? temp.temperature1 : null,
        Temperatura2: temp.temperature2 !== 'N' ? temp.temperature2 : null,
        Temperatura3: temp.temperature3 !== 'N' ? temp.temperature3 : null,
        Temperatura4: temp.temperature4 !== 'N' ? temp.temperature4 : null,
        datetimeTemperature1: temp.datetimeTemperature1,
        datetimeTemperature2: temp.datetimeTemperature2,
        datetimeTemperature3: temp.datetimeTemperature3,
        datetimeTemperature4: temp.datetimeTemperature4,
        latitude: temp.latitudeTemperature,
        longitude: temp.longitudeTemperature,
        point: temp.id
      };
    });

    setData(mergedData);
  }, [travel?.data?.loadTemperature]);

  function onClick(data) {
    setLatLngTemperature(data?.activePayload[0]?.payload);
    handleClickTemperature(data?.activePayload[0]?.payload);
  }

  return (
    <>
      <Text type="medium" style={{ fontStyle: 'italic' }}>
        {"Temperatura obtida via Raster"}
      </Text>
      <ResponsiveContainer width="101%" height={200}>
        <AreaChart
          width={730}
          height={160}
          onClick={onClick}
          data={data}
          margin={{ top: 10, right: 20, left: 20 }}
        >
          <XAxis dataKey="captureData" />
          <YAxis unit="°C" />
          <CartesianGrid strokeDasharray="2 2" />
          <Tooltip />
          <Legend />
          <Area
            type="monotone"
            dataKey="Temperatura1"
            stroke="#1C4B75"
            fill="#1C4B75"
            stackId="1"
            fillOpacity={0.3}
            style={{ cursor: 'pointer' }}
            unit="°C"
          />
          <Area
            type="monotone"
            dataKey="Temperatura2"
            stroke="#8511F2"
            fill="#8511F2"
            fillOpacity={0.3}
            stackId="1"
            style={{ cursor: 'pointer' }}
            unit="°C"
          />
          <Area
            type="monotone"
            dataKey="Temperatura3"
            stroke="#FF0345"
            fill="#FF0345"
            fillOpacity={0.3}
            stackId="1"
            style={{ cursor: 'pointer' }}
            unit="°C"
          />
          <Area
            type="monotone"
            dataKey="Temperatura4"
            stroke="#000000"
            fill="#000000"
            fillOpacity={0.3}
            stackId="1"
            style={{ cursor: 'pointer' }}
            unit="°C"
          />
        </AreaChart>
      </ResponsiveContainer>
    </>
  );
}

export default Temperature;
