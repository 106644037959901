import React, { useState, useEffect } from 'react';

import { Row, Col } from 'react-bootstrap';
import { PHONE_MASK, CPF_MASK, CNPJ_MASK } from 'utils/masks';
import api from 'services/api';
import { useValidation } from 'hooks';
import Radio from 'v3/components/Radio';
import { useSnackbar } from 'v3/components/Snackbar';
import Modal from 'v3/components/Modal';
import Button from 'v3/components/Button';
import Input from 'v3/components/Input';
import Text from 'v3/components/Text';
import { TravelContext } from '../../controller';

import { travelShippingCompanyRegistrationSchema } from '../../../../ShippingCompany/shared/validator';

const [, useTravel] = TravelContext;

function RegisterShippingCompany({ isOpen, onClose }) {
  const travel = useTravel();

  const [personType, setPersonType] = useState(true);
  const [isDriver, setIsDriver] = useState(false);
  const [name, setName] = useState('');
  const [cgccpf, setCgccpf] = useState('');
  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState(false);
  const snackbar = useSnackbar();
  const [errors, setErrors] = useState({});

  const [validationErrors, { validate }] = useValidation(
    travelShippingCompanyRegistrationSchema
  );

  useEffect(() => {
    setErrors(validationErrors);
  }, [validationErrors]);

  async function handleCreation() {
    const payload = {
      type_id: personType ? 3 : 2,
      cgccpf,
      social_name: name,
      phone,
      phone_unmasked: phone.replace(/[^0-9]+/g, ''),
      is_driver: !!isDriver,
    };

    const [isValid] = await validate({
      cgccpf_unmasked: cgccpf.replace(/[^0-9]+/g, ''),
      ...payload,
    });

    if (!isValid) {
      snackbar.show(<Text>Erro ao cadastrar transportadora</Text>, {
        type: 'error',
      });
      return false;
    }
    try {
      setLoading(true);
      const response = await api.post('v3/shipping-company', {
        data: payload,
      });

      snackbar.show(<Text>Cadastro realizado com sucesso</Text>, {
        type: 'success',
      });

      setName('');
      setPhone('');
      setCgccpf('');

      travel.setData(old => ({
        ...old,
        shippingCompany: response.data,
      }));
      onClose();
    } catch (error) {
      snackbar.show(<Text>Erro ao cadastrar transportadora</Text>, {
        type: 'error',
      });
      return false;
    } finally {
      setLoading(false);
    }
    return true;
  }

  return (
    <>
      <Modal
        size="lg"
        show={isOpen}
        onHide={onClose}
        heading={
          <Text type="header" color="dark" weight="500">
            Cadastrar transportadora
          </Text>
        }
        body={
          <Row>
            <Col md={6} xs={12} className="mb-3">
              <Radio.Group
                label={
                  <Text color="dark" type="label">
                    Tipo de pessoa
                  </Text>
                }
                onChange={({ target }) => {
                  setPersonType(target.value);
                }}
                value={personType}
                horizontal
              >
                <Radio id="radio-modal-novo-transportador-tipo-juridica" value>
                  <Text color="dark" type="label">
                    Jurídica
                  </Text>
                </Radio>
                <Radio
                  id="radio-modal-novo-transportador-tipo-fisica"
                  value={false}
                >
                  <Text color="dark" type="label">
                    Física
                  </Text>
                </Radio>
              </Radio.Group>
            </Col>
            <Col md={6} xs={12} className="mb-3">
              <Radio.Group
                label={
                  <Text color="dark" type="label">
                    É motorista?
                  </Text>
                }
                onChange={({ target }) => {
                  setIsDriver(target.value);
                }}
                value={isDriver}
                horizontal
              >
                <Radio id="radio-modal-novo-transportador-motorista-sim" value>
                  <Text color="dark" type="label">
                    Sim
                  </Text>
                </Radio>
                <Radio
                  id="radio-modal-novo-transportador-motorista-nao"
                  value={false}
                >
                  <Text color="dark" type="label">
                    Não
                  </Text>
                </Radio>
              </Radio.Group>
            </Col>
            <Col xs={12} className="mb-3">
              <Input
                label={personType ? 'Razão social' : 'Nome'}
                value={name}
                onChange={event => setName(event.target.value)}
                error={errors.name}
                id={`input-modal-novo-transportador-${
                  personType ? 'razao-social' : 'nome'
                }`}
              />
            </Col>
            <Col md={6} xs={12} className="mb-3">
              <Input
                label={personType ? 'CNPJ' : 'CPF'}
                value={cgccpf}
                onChange={event => setCgccpf(event.target.value)}
                masked
                mask={personType ? CNPJ_MASK : CPF_MASK}
                error={errors.cgccpf_unmasked}
                id={`input-modal-novo-transportador-${
                  personType ? 'cnpj' : 'cpf'
                }`}
              />
            </Col>
            <Col md={6} xs={12}>
              <Input
                label="Telefone"
                value={phone}
                onChange={event => setPhone(event.target.value)}
                masked
                mask={PHONE_MASK}
                error={errors.phone_unmasked}
                id="input-modal-novo-transportador-telefone"
              />
            </Col>
            <Col className="text-center my-3">
              <Button
                className="py-2"
                disabled={!name || !phone || !cgccpf}
                onClick={() => handleCreation()}
                loading={loading}
                id="button-modal-novo-transportador-salvar"
              >
                <Text type="regular" weight={500}>
                  Salvar
                </Text>
              </Button>
            </Col>
          </Row>
        }
      />
    </>
  );
}

export default RegisterShippingCompany;
