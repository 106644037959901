import React, { useEffect, useState } from 'react';

import { GiBackwardTime } from 'react-icons/gi';

import { Row, Col } from 'react-bootstrap';

import Text from 'v3/components/Text';
import Loader from 'v3/components/Loader';
import Link from 'v3/components/Link';
import Tooltip from 'v3/components/Tooltip';

import { useSnackbar } from 'v3/components/Snackbar';

import { verifyMostAcessedPermissions } from '../MostAccessed/validator';

import api from 'services/api';

import * as Styled from '../Favorites/styles';

function Recents() {
  const snackbar = useSnackbar();

  const [recents, setRecents] = useState([]);
  const [loading, setLoading] = useState(false);

  async function getRecents() {
    setLoading(true);
    try {
      const reponse = await api.get('v3/user/logs');
      validateRecentPermission(reponse.data);
    } catch (err) {
      snackbar.show(<Text>Erro ao buscar favoritos disponíveis</Text>, {
        type: 'error',
      });
      return null;
    } finally {
      setLoading(false);
    }
  }

  const validateRecentPermission = recent => {
    const validRecentRoutes = verifyMostAcessedPermissions(recent);
    setRecents(validRecentRoutes);
  };

  useEffect(() => {
    getRecents();
  }, []);

  return (
    <>
      <Row className="mt-5 mb-5 ml-1">
        <Col className="d-flex align-center">
          <GiBackwardTime size={40} color="#73C164" className="mt-2 mr-2" />
          <Text color="primary" weight="700" fontSize="36px">
            Recentes
          </Text>
        </Col>
      </Row>

      {!loading ? (
        <Row className="ml-1">
          {!recents && (
            <Col>
              <Styled.RecentsWrapper>
                <Styled.Title color="dark" weight="700">
                  Ainda não há recentes!
                </Styled.Title>
              </Styled.RecentsWrapper>
            </Col>
          )}

          {recents.map(rec => {
            return (
              <Col xs={6} key={rec.path}>
                <Link
                  withoutIcon
                  className="mt-2"
                  to={`${rec.path}`}
                  target="_blank"
                >
                  {rec?.instance ? (
                    <Tooltip
                      content={<Text type="body">{rec?.instance}</Text>}
                      placement="top"
                    >
                      <Styled.Wrapper>
                        <Styled.Title color="dark" weight="700" truncate>
                          {rec.name} {rec?.instance && ` -  ${rec?.instance}`}
                        </Styled.Title>
                        <Text type="label">{rec.path}</Text>
                      </Styled.Wrapper>
                    </Tooltip>
                  ) : (
                    <Styled.Wrapper>
                      <Styled.Title color="dark" weight="700" truncate>
                        {rec.name}
                      </Styled.Title>
                      <Text type="label">{rec.path}</Text>
                    </Styled.Wrapper>
                  )}
                </Link>
              </Col>
            );
          })}
        </Row>
      ) : (
        <div className="d-flex justify-content-center mt-5">
          <Loader size="md" loading={loading} />
        </div>
      )}
    </>
  );
}

export default Recents;
