import React, { useState, useEffect, useCallback } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useParams } from 'react-router-dom';

import { useEffectThrottled, useModal, usePagination } from 'hooks';

import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import Fallback from 'v3/components/Fallback';
import Card from 'v3/components/Card';
import DataTable from 'v3/components/DataTable/index';
import { useSnackbar } from 'v3/components/Snackbar';

import api from 'services/api';

import { FaUnlink } from 'react-icons/fa';

import theme from 'v3/theme';
import PriorityModal from '../Modals/PriorityModal';

import { useClient } from '../context';

export default function ClientPriorityFleet() {
  const client = useClient();
  const params = useParams();
  const snackbar = useSnackbar();
  const priorityModal = useModal();
  const [isFetching, setFetching] = useState(false);
  const [modalName, setModalName] = useState('');
  const [fallback, setFallback] = useState(false);
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    if (modalName === 'PriorityModal') {
      priorityModal.open();
    }
  }, [modalName]);

  const isEditing = !!params.id;

  const onManualSearch = useCallback(
    (search, item) =>
      item?.cgccpf.includes(search || '') ||
      item?.social_name?.toLowerCase().includes(search?.toLowerCase() || ''),
    []
  );

  const pagination = usePagination({
    data: client.data?.priorityFleetVehicles || [],
    manual: !isEditing,
    onManualSearch,
  });

  async function fetchFleet(currentPage = pagination.currentPage) {
    try {
      setFetching(true);
      const response = await api.get(`v3/client/${params.id}/priority-fleet`, {
        params: {
          search: pagination.search,
          perPage: pagination.perPage,
          page: currentPage,
        },
      });

      pagination.setPerPage(response.data.perPage);
      pagination.setLastPage(response.data.lastPage);
      pagination.setTotal(response.data.total);

      const assertCurrentPage =
        response.lastPage && response.lastPage < currentPage
          ? response.lastPage
          : currentPage;
      pagination.setCurrentPage(assertCurrentPage);
      client.setData({ priorityFleetVehicles: response.data.data });

      setFetched(true);
    } catch (error) {
      snackbar.show(
        <Text> Não foi possível listar os veículos vinculados.</Text>,
        { type: 'error' }
      );
    } finally {
      setFetching(false);
    }
  }

  async function dettachFleet(vehicle) {
    if (params.id) {
      try {
        await api.delete(`v3/client/${client.data.id}/vehicle/${vehicle}`);

        const isLastItem = pagination.data.length === 1;

        fetchFleet(
          isLastItem ? pagination.currentPage - 1 : pagination.currentPage
        );
      } catch (ex) {
        snackbar.show(<Text>Não foi possível desvincular esse veículo.</Text>, {
          type: 'error',
        });
      }
    } else
      client.setData({
        priorityFleetVehicles: client.data?.priorityFleetVehicles?.filter(
          item => item.id !== vehicle
        ),
      });
  }

  useEffectThrottled(
    () => {
      if (isEditing) {
        fetchFleet(1);
      }
    },
    [isEditing, pagination.perPage, pagination.search, pagination.currentPage],
    300
  );

  function openModal() {
    setModalName('PriorityModal');
  }

  useEffect(() => {
    if (!client.data.priorityFleetVehicles[0]) {
      setFallback(true);
    } else {
      setFallback(false);
    }
  }, [pagination.data, pagination.currentPage, pagination.search]);

  return (
    <>
      <PriorityModal
        fetchFleet={fetchFleet}
        isOpen={priorityModal.isOpen}
        onClose={() => {
          priorityModal.close();
          setModalName('');
        }}
      />

      <Card
        loading={isFetching && !fetched}
        header={
          <Text type="header" color="dark">
            Frota prioritária
          </Text>
        }
        HeaderRightComponent={
          !fallback &&
          client?.canEditData && (
            <Button
              onClick={() => {
                openModal();
              }}
              className="py-2"
            >
              <Text type="regular" weight="500" color="white">
                Adicionar
              </Text>
            </Button>
          )
        }
      >
        <Fallback
          fall={fallback}
          component={
            <Col xs={12} className="align-items-center d-flex flex-column my-2">
              <Text color={theme.colors.dark} type="regular">
                Não há frotas prioritárias cadastradas neste cliente.
              </Text>
              {client?.canEditData && (
                <Button className="mt-4 py-2" onClick={openModal}>
                  <Text type="regular" weight="500" color="white">
                    Adicionar
                  </Text>
                </Button>
              )}
            </Col>
          }
        >
          <Row className="form">
            <Col md={12}>
              <DataTable
                data={pagination.data}
                total={pagination.total}
                pages={pagination.lastPage}
                onPageChanged={pagination.setCurrentPage}
                onSearchChanged={pagination.setSearch}
                onPerPageChanged={pagination.setPerPage}
                loading={isFetching}
                searchPlaceholder="CNPJ ou Razão social"
                columns={[
                  { name: 'Placa', selector: row => row.plate },
                  {
                    name: 'Motorista',
                    selector: row => row.driverList?.social_name || '-',
                  },
                  {
                    name: 'Telefone',
                    selector: row => row.driverList?.phone || '-',
                  },
                  {
                    name: 'Dono',
                    selector: row => row.owner?.social_name || '-',
                  },
                  {
                    name: 'Ação',
                    render: row => (
                      <Button
                        variant="error"
                        size="sm"
                        className="mx-1"
                        onClick={() => dettachFleet(row.id)}
                        disabled={client?.canEditData === false}
                      >
                        <Text>
                          <FaUnlink />
                        </Text>
                      </Button>
                    ),
                  },
                ]}
              />
            </Col>
          </Row>
        </Fallback>
      </Card>
    </>
  );
}
