import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { Button, Input, Modal, Select, Text } from 'v3/components';
import Space from 'v3/components/Space';

export function RegisterModal({
  showModal,
  handleCloseModal,
  businessValues,
  setBusinessValues,
  availableBusiness,
  errors,
  setErrors,
  handleCreate,
  loading,
  editing,
  setEditing,
  hasPermissionToManipulateValues,
}) {
  function handleChangeValue(levelLabel, valueLabel, value) {
    if (value?.length <= 6) {
      setBusinessValues(old => ({
        ...old,
        serviceLevels: {
          ...old?.serviceLevels,
          [levelLabel]: {
            ...old?.serviceLevels[levelLabel],
            [valueLabel]: value,
          },
        },
      }));
      return;
    }
    return;
  }

  function onClose() {
    setErrors({});
    setBusinessValues({
      business: null,
      serviceLevels: {
        ouro: {
          margin: '0',
          advance: '0',
        },
        agregado: {
          margin: '0',
          advance: '0',
        },
        terceiro: {
          margin: '0',
          advance: '0',
        },
      },
    });
    if (editing) {
      setEditing(false);
    }
    handleCloseModal();
  }

  function searchBusiness(search) {
    if (!search) {
      return availableBusiness;
    }

    const business = search.toLowerCase();
    return availableBusiness.filter(
      s => s.name.toLowerCase().search(business) >= 0
    );
  }

  return (
    <Modal
      heading={
        <Text color={'dark'} weight={500}>
          Negócio
        </Text>
      }
      show={showModal}
      handleClose={onClose}
      body={
        <>
          <Row>
            <Col md={12}>
              <Select.Async
                label="Nome *"
                value={businessValues?.business}
                options={availableBusiness}
                getOptionLabel={option => option?.name}
                getOptionValue={option => option?.id}
                onChange={value =>
                  setBusinessValues(old => ({ ...old, business: value }))
                }
                onSearch={search => searchBusiness(search)}
                error={errors?.business}
                isDisabled={
                  !hasPermissionToManipulateValues || loading || editing
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} className="my-3">
              <Text color="dark" type="regular" weight={500}>
                Valores por nível de serviço
              </Text>
            </Col>
            <Col md={12} className="mb-3">
              <Text type="medium" color="dark" weight={500}>
                Nível Ouro
              </Text>
              <Row>
                <Col md={6}>
                  <Input
                    label="Margem mínima (%) *"
                    value={businessValues?.serviceLevels?.ouro?.margin}
                    onChange={e =>
                      handleChangeValue('ouro', 'margin', e?.target?.value)
                    }
                    type="number"
                    step={0.01}
                    error={errors?.['serviceLevels.ouro.margin']}
                    disabled={!hasPermissionToManipulateValues || loading}
                  />
                </Col>
                <Col md={6}>
                  <Input
                    label="Adiantamento máximo (%) *"
                    value={businessValues?.serviceLevels?.ouro?.advance}
                    onChange={e =>
                      handleChangeValue('ouro', 'advance', e?.target?.value)
                    }
                    type="number"
                    step={0.01}
                    error={errors?.['serviceLevels.ouro.advance']}
                    disabled={!hasPermissionToManipulateValues || loading}
                  />
                </Col>
              </Row>
            </Col>
            <Col md={12} className="mb-3">
              <Text type="medium" color="dark" weight={500}>
                Nível Agregado
              </Text>
              <Row>
                <Col md={6}>
                  <Input
                    label="Margem mínima (%) *"
                    value={businessValues?.serviceLevels?.agregado?.margin}
                    onChange={e =>
                      handleChangeValue('agregado', 'margin', e?.target?.value)
                    }
                    type="number"
                    step={0.01}
                    error={errors?.['serviceLevels.agregado.margin']}
                    disabled={!hasPermissionToManipulateValues || loading}
                  />
                </Col>
                <Col md={6}>
                  <Input
                    label="Adiantamento máximo (%) *"
                    value={businessValues?.serviceLevels?.agregado?.advance}
                    onChange={e =>
                      handleChangeValue('agregado', 'advance', e?.target?.value)
                    }
                    type="number"
                    step={0.01}
                    error={errors?.['serviceLevels.agregado.advance']}
                    disabled={!hasPermissionToManipulateValues || loading}
                  />
                </Col>
              </Row>
            </Col>
            <Col md={12} className="mb-3">
              <Text type="medium" color="dark" weight={500}>
                Nível Terceiro
              </Text>
              <Row>
                <Col md={6}>
                  <Input
                    label="Margem mínima (%) *"
                    value={businessValues?.serviceLevels?.terceiro?.margin}
                    onChange={e =>
                      handleChangeValue('terceiro', 'margin', e?.target?.value)
                    }
                    type="number"
                    step={0.01}
                    error={errors?.['serviceLevels.terceiro.margin']}
                    disabled={!hasPermissionToManipulateValues || loading}
                  />
                </Col>
                <Col md={6}>
                  <Input
                    label="Adiantamento máximo (%) *"
                    value={businessValues?.serviceLevels?.terceiro?.advance}
                    onChange={e =>
                      handleChangeValue('terceiro', 'advance', e?.target?.value)
                    }
                    type="number"
                    step={0.01}
                    error={errors?.['serviceLevels.terceiro.advance']}
                    disabled={!hasPermissionToManipulateValues || loading}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      }
      footer={
        hasPermissionToManipulateValues && (
          <Space x="0.5rem">
            <Button variant={'secondary'} onClick={onClose} disabled={loading}>
              <Text weight={500}>Cancelar</Text>
            </Button>
            <Button
              onClick={() =>
                editing
                  ? handleCreate(
                      'put',
                      `businesses/${businessValues?.business?.id}`,
                      'Valores do negócio atualizado com sucesso!',
                      'Erro ao tentar atualizar valores do negócio!'
                    )
                  : handleCreate()
              }
              disabled={loading}
              loading={loading}
            >
              <Text weight={500}>Salvar</Text>
            </Button>
          </Space>
        )
      }
    />
  );
}
