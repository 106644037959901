import React from 'react';
import { usePolicyRegister } from '../../context';
import { RangeSectionToggleHeader } from './RangeSectionToggleHeader';
import { SearchConsultationContainer } from 'v3/pages/Policy/styles';
import { Select, Text } from 'v3/components';
import CheckBox from 'components/CheckBox';

export function SearchConsultationSection({
  opIndex,
  productIndex,
  vehicleIdx,
  rangeIdx,
  range,
}) {
  const {
    handleAddRangeItem,
    windowWidth,
    errors,
    rdoOptions,
    searchConsultationOptions,
    riskManagersOptions,
  } = usePolicyRegister();

  function handleSearchRDO(search) {
    if (!search) {
      return rdoOptions;
    }
    const parsed = search?.toLocaleLowerCase();
    return rdoOptions?.filter(
      option => option?.name?.toLocaleLowerCase().search(parsed) >= 0
    );
  }
  function handleSearchSearchConsultation(search) {
    if (!search) {
      return searchConsultationOptions;
    }
    const parsed = search?.toLocaleLowerCase();
    return searchConsultationOptions?.filter(
      option => option?.name?.toLocaleLowerCase().search(parsed) >= 0
    );
  }
  function handleSearchRiskManager(search) {
    if (!search) {
      return riskManagersOptions;
    }
    const parsed = search?.toLocaleLowerCase();
    return riskManagersOptions?.filter(
      option => option?.name?.toLocaleLowerCase().search(parsed) >= 0
    );
  }
  return (
    <>
      <RangeSectionToggleHeader
        opIndex={opIndex}
        productIndex={productIndex}
        vehicleIdx={vehicleIdx}
        rangeIdx={rangeIdx}
        keyName={'showSearchConsultation'}
        keyValue={range?.showSearchConsultation}
        label={'Pesquisa e Consulta'}
      />
      {range?.showSearchConsultation && (
        <SearchConsultationContainer>
          <Select.Async
            closeMenuOnSelect={false}
            labelColor="dark"
            label="RDO"
            value={range?.rdo}
            placeholder="Selecione"
            options={rdoOptions}
            multiple
            horizontal={windowWidth <= 992}
            modalHeading={'RDOs'}
            modalBodyTitle={'Selecione uma opção'}
            onChange={value =>
              handleAddRangeItem(
                opIndex,
                productIndex,
                vehicleIdx,
                rangeIdx,
                'rdo',
                value
              )
            }
            getOptionLabel={option => option.name ?? option?.label}
            getOptionValue={option => option.id}
            selectValuesContainerMaxHeight="200px"
            selectValuesContainerOverflowY="scroll"
            onSearch={search => handleSearchRDO(search)}
          />
          <Select.Async
            closeMenuOnSelect={false}
            labelColor="dark"
            label="Pesquisa e Consulta"
            value={range?.search_consulting}
            placeholder="Selecione"
            options={searchConsultationOptions}
            multiple
            horizontal={windowWidth <= 992}
            modalHeading={'Pesquisa e Consulta'}
            modalBodyTitle={'Selecione uma opção'}
            onChange={value =>
              handleAddRangeItem(
                opIndex,
                productIndex,
                vehicleIdx,
                rangeIdx,
                'search_consulting',
                value
              )
            }
            getOptionLabel={option => option.name ?? option?.label}
            getOptionValue={option => option.id}
            selectValuesContainerMaxHeight="200px"
            selectValuesContainerOverflowY="scroll"
            onSearch={search => handleSearchSearchConsultation(search)}
          />
          <Select.Async
            closeMenuOnSelect={false}
            labelColor="dark"
            label="Gerenciadora para cadastro/consulta"
            value={range?.manager_query}
            placeholder="Selecione"
            multiple
            horizontal={windowWidth <= 992}
            modalHeading={'Gerenciadoras'}
            modalBodyTitle={'Informe uma gerenciadora'}
            options={riskManagersOptions}
            onChange={value =>
              handleAddRangeItem(
                opIndex,
                productIndex,
                vehicleIdx,
                rangeIdx,
                'manager_query',
                value
              )
            }
            getOptionLabel={option => option.name ?? option?.label}
            getOptionValue={option => option.id}
            selectValuesContainerMaxHeight="200px"
            selectValuesContainerOverflowY="scroll"
            onSearch={search => handleSearchRiskManager(search)}
          />
          <Select.Async
            closeMenuOnSelect={false}
            labelColor="dark"
            label="Gerenciadora de risco para SM logística"
            value={range?.logistic_sm}
            placeholder="Busque uma gerenciadora"
            multiple
            horizontal={windowWidth <= 992}
            modalHeading={'Gerenciadoras'}
            modalBodyTitle={'Busque uma gerenciadora'}
            onSearch={search => handleSearchRiskManager(search)}
            options={riskManagersOptions}
            onChange={value =>
              handleAddRangeItem(
                opIndex,
                productIndex,
                vehicleIdx,
                rangeIdx,
                'logistic_sm',
                value
              )
            }
            getOptionLabel={option => option.name ?? option?.label}
            getOptionValue={option => option.id}
            selectValuesContainerMaxHeight="200px"
            selectValuesContainerOverflowY="scroll"
          />
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Text as="span" color="dark" type="label" weight={500}>
                Restrição Terceiro *
              </Text>
              {errors[
                `operations[${opIndex}].products[${productIndex}].vehicles[${vehicleIdx}].ranges[${rangeIdx}].restrition_third`
              ] && (
                <Text type="label" color="danger">
                  {
                    errors[
                      `operations[${opIndex}].products[${productIndex}].vehicles[${vehicleIdx}].ranges[${rangeIdx}].restrition_third`
                    ]
                  }
                </Text>
              )}
            </div>
            <div>
              <CheckBox
                checked={range.restrition_third === true}
                onChange={() =>
                  handleAddRangeItem(
                    opIndex,
                    productIndex,
                    vehicleIdx,
                    rangeIdx,
                    'restrition_third',
                    range?.restrition_third ? null : true
                  )
                }
                label={'Sim'}
              />
            </div>

            <div>
              <CheckBox
                checked={range?.restrition_third === false}
                onChange={() =>
                  handleAddRangeItem(
                    opIndex,
                    productIndex,
                    vehicleIdx,
                    rangeIdx,
                    'restrition_third',
                    range?.restrition_third === false ? null : false
                  )
                }
                label={'Não'}
              />
            </div>
          </div>
        </SearchConsultationContainer>
      )}
    </>
  );
}
