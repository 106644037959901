import React, { useState, useEffect } from 'react';

import Card from 'v3/components/Card';
import Text from 'v3/components/Text';
import Input from 'v3/components/Input';
import Select from 'v3/components/Select';
import InputAddress from 'v3/components/InputAddress';

import { Row, Col } from 'react-bootstrap';

import { IssuerContext } from '../controller';
import { fetchCities } from './controller';

const [, useIssuer] = IssuerContext;

export default function Address() {
  const issuer = useIssuer();

  const [postalCode, setPostalCode] = useState();
  const [address, setAddress] = useState();
  const [neighborhood, setNeighborhood] = useState();
  const [number, setNumber] = useState();
  const [complement, setComplement] = useState();
  const [addressCity, setAddressCity] = useState();
  const [addressProvince, setAddressProvince] = useState();

  useEffect(() => {
    issuer.setData(old => ({
      ...old,
      postal_code: postalCode,
      address,
      neighborhood,
      number,
      complement,
      city: addressCity ? addressCity.name : null,
    }));
  }, [
    postalCode,
    address,
    neighborhood,
    number,
    complement,
    addressCity,
    addressProvince,
  ]);

  return (
    <Card
      header={
        <Text color="#464E5F" type="header">
          Endereço
        </Text>
      }
    >
      <Row>
        <Col className="mb-3">
          <InputAddress
            label="Endereço *"
            value={address}
            onSelectedAddress={place => {
              setAddress(place.address);
              setNeighborhood(place.neighborhood);

              const province = {
                name: place.province_name,
                uf: place.province,
              };
              setAddressCity({ name: place.city, province });
              setAddressProvince(province);

              setNumber(place.number);
              setPostalCode(place.zip_code);
              setComplement(prevComplement =>
                [prevComplement, place.name].filter(Boolean).join(', ')
              );
            }}
            types={[]}
            error={issuer.errors.address}
            onChange={e => setAddress(e.target.value)}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6} className="mb-3">
          <Input
            label="Número"
            value={number}
            onChange={event => setNumber(event.target.value)}
            error={issuer.errors.number}
          />
        </Col>
        <Col xs={6} className="mb-3">
          <Input
            label="Complemento"
            value={complement}
            onChange={event => setComplement(event.target.value)}
            error={issuer.errors.complement}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6} className="mb-3">
          <Input
            label="Bairro"
            value={neighborhood}
            onChange={event => setNeighborhood(event.target.value)}
            error={issuer.errors.neighborhood}
          />
        </Col>
        <Col xs={6} className="mb-3">
          <Input
            label="CEP *"
            value={postalCode}
            onChange={event => setPostalCode(event.target.value)}
            error={issuer.errors.postal_code}
            masked
            mask={[
              /[0-9]/,
              /[0-9]/,
              /[0-9]/,
              /[0-9]/,
              /[0-9]/,
              '-',
              /[0-9]/,
              /[0-9]/,
              /[0-9]/,
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-3">
          <Select.Async
            label="Cidade *"
            onSearch={fetchCities}
            value={addressCity}
            onChange={value => {
              setAddressCity(value);
              setAddressProvince(value ? value.province : null);
            }}
            getOptionLabel={option => `${option.name}, ${option.province.uf}`}
            getOptionValue={option => `${option.name}, ${option.province.uf}`}
            error={issuer.errors.city}
          />
        </Col>
      </Row>
    </Card>
  );
}
