import api from 'services/api';

export async function getDrivers(
  filter,
  page,
  { setDrivers, setLoading, setPagination }
) {
  if (page === 1) {
    setLoading(true);
  }

  try {
    const { name, document, tags } = filter;
    const response = await api.get(
      `v3/drivers?name=${name}&document=${document}&tags=${tags}&page=${page}`
    );

    const { drivers, ...rest } = response.data;
    setPagination(rest);

    setDrivers(oldDrivers => {
      if (page === 1) {
        return drivers;
      }
      return [...oldDrivers, ...drivers];
    });
  } catch (error) {
    // snackbar
  } finally {
    setLoading(false);
  }
}
