import { fetchSelectOptions } from '../controller';

export async function fetchTags() {
  try {
    const response = await fetchSelectOptions('user-tags');

    return response.map(tag => ({ id: tag.tag_id, name: tag.name.name }));
  } catch (err) {
    return [];
  }
}
