import React, { useState } from 'react';
import { Modal, Text } from 'v3/components';
import { useTravel } from 'v3/pages/Travel';
import ModalAdd from '../../../Details/Feed/Add/index';
import { useRiskManager } from '../context';

export function FeedModal() {
  const travel = useTravel();
  const { feedModal } = useRiskManager();
  const [loading, setLoading] = useState();
  return (
    <Modal
      size="md"
      heading={
        <Text color="dark" type="header">
          Adicionar um Feed
        </Text>
      }
      centered
      scrollable
      show={feedModal.isOpen}
      body={
        <ModalAdd
          fetchTravel={travel.fetchTravel}
          loadCostCenter={0}
          loading={loading}
          setLoading={setLoading}
        />
      }
      handleClose={loading ? () => {} : () => feedModal.close()}
    />
  );
}
