/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import {
  CNPJ_DIGITS_LENGTH,
  CNPJ_MASK,
  CPF_MASK,
  onlyDigits,
} from 'utils/masks';

import Text from 'v3/components/Text';
import TextArea from 'v3/components/TextArea';
import Input from 'v3/components/Input';
import Card from 'v3/components/Card';
import Button from 'v3/components/Button';
import { useSnackbar } from 'v3/components/Snackbar';
import ApplicationLayout from 'v3/layouts/Application';

import api from 'services/api';

import { validatePermission } from 'actions/index';
import { Radio } from 'v3/components';

import { validator, cnpjValidator } from '../../utils';

import ConfirmDelete from './ConfirmDelete';

function BlacklistItemDetails() {
  const snackbar = useSnackbar();
  const params = useParams();
  const history = useHistory();

  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [maskFunction, setMaskFunction] = useState(false);
  const [cpfCnpj, setCpfCnpj] = useState('');
  const [description, setDescription] = useState('');
  const [reason, setReason] = useState('');
  const [isForeign, setIsForeign] = useState('');
  const [isCnpj, setIsCnpj] = useState(false);
  const [removeItem, setRemoveItem] = useState(false);
  const [id, setId] = useState();

  const [hasPermissionToEdit, setPermissionToEdit] = useState(false);
  const [hasPermissionToCreate, setPermissionToCreate] = useState(false);
  const [hasPermissionToDelete, setHasPermissionToDelete] = useState(false);
  useEffect(() => {
    setPermissionToEdit(validatePermission('ALTERAR_BLACKLIST'));
    setPermissionToCreate(validatePermission('CADASTRAR_BLACKLIST'));
    setHasPermissionToDelete(validatePermission('REMOVER_BLACKLIST'));
  }, []);

  async function getBlackListItem() {
    try {
      setId(params.id);
      const response = await api.get(`v3/blacklist-person/${params.id}`);
      setCpfCnpj(response.data.value);
      setDescription(response.data.description);
      setReason(response.data.reason);
      if (onlyDigits(response.data.value).length === CNPJ_DIGITS_LENGTH) {
        setIsCnpj(true);
      } else {
        setIsCnpj(false);
      }
      setLoading(false);
    } catch (err) {
      snackbar.show(<Text>Algo deu errado. Tente novamente mais tarde.</Text>, {
        type: 'error',
      });
      setLoading(false);
      history.push('/blacklist');
    }
  }

  useEffect(() => {
    getBlackListItem();
  }, []);

  useEffect(() => {
    if (isCnpj) {
      setMaskFunction(() => CNPJ_MASK);
    } else if (!isCnpj && !isForeign) {
      setMaskFunction(() => CPF_MASK);
    }
  }, [isCnpj]);

  useEffect(() => {
    if (isForeign) {
      setIsCnpj(false);
      setCpfCnpj('');
      setMaskFunction(() => false);
    } else {
      isCnpj
        ? setMaskFunction(() => CNPJ_MASK)
        : setMaskFunction(() => CPF_MASK);
    }
  }, [isForeign]);

  async function handleSubmit() {
    try {
      setLoading(true);
      isCnpj
        ? await cnpjValidator.validate({ cpfCnpj }, { abortEarly: false })
        : await validator.validate(
            { cpfCnpj },
            {
              abortEarly: false,
            }
          );
      await api.put(`v3/blacklist-person/${params.id}`, {
        value: cpfCnpj,
        description,
        reason,
      });
      snackbar.show(<Text>Item da blacklist editado com sucesso!</Text>, {
        type: 'success',
      });
      setEditing(false);
    } catch (ex) {
      const message = ex.response?.data?.message
        ? ex.response?.data?.message
        : ex.inner
        ? 'Verifique os campos obrigatórios'
        : 'Ocorreu algum erro durante o cadastro';
      snackbar.show(<Text>{message}</Text>, {
        type: 'error',
      });
      ex.inner ? setEditing(true) : setEditing(false);
    } finally {
      setLoading(false);
    }
  }

  function manageDelete() {
    setRemoveItem(true);
  }

  return (
    <ApplicationLayout
      title="Detalhes de item da blacklist"
      RightComponent={
        <>
          <Link to="/blacklist">
            <Button variant="secondary">
              <Text weight={500} type="regular">
                Voltar
              </Text>
            </Button>
          </Link>

          {hasPermissionToCreate && (
            <Link to="/blacklist/cadastrar">
              <Button className="ml-2">
                <Text weight={500} type="regular">
                  Novo item de blacklist
                </Text>
              </Button>
            </Link>
          )}
        </>
      }
    >
      <Row>
        <Col lg={12} md={12} xs={12}>
          <Card
            header={
              <Text color="#464E5F" type="header">
                {!editing && cpfCnpj}
              </Text>
            }
            HeaderRightComponent={
              hasPermissionToEdit && (
                <div>
                  {editing && (
                    <Button
                      onClick={() => {
                        setEditing(false);
                        getBlackListItem();
                      }}
                      variant="secondary"
                      loading={loading}
                      className="mr-2"
                    >
                      <Text color="dark" type="regular">
                        Cancelar
                      </Text>
                    </Button>
                  )}
                  <Button
                    onClick={
                      editing ? () => handleSubmit() : () => setEditing(true)
                    }
                    variant={editing ? 'success' : 'secondary'}
                    loading={loading}
                  >
                    <Text
                      color={editing ? 'white' : 'dark'}
                      weight={500}
                      type="regular"
                    >
                      {editing ? 'Salvar' : 'Editar'}
                    </Text>
                  </Button>
                  {hasPermissionToDelete && (
                    <Button
                      className="ml-2"
                      variant="danger"
                      onClick={() => manageDelete()}
                    >
                      <Text weight={500} type="regular">
                        Remover
                      </Text>
                    </Button>
                  )}
                </div>
              )
            }
          >
            <Row>
              {removeItem ? (
                <ConfirmDelete
                  id={id}
                  setRemoveItem={setRemoveItem}
                  snackbar={snackbar}
                />
              ) : null}
              <Col xs={3}>
                <Radio.Group
                  disabled={!editing}
                  label="Estrangeiro"
                  onChange={({ target }) => setIsForeign(target.value)}
                  value={isForeign}
                  horizontal
                >
                  <Radio value>
                    <Text color="dark" type="label">
                      Sim
                    </Text>
                  </Radio>
                  <Radio value={false}>
                    <Text color="dark" type="label">
                      Não
                    </Text>
                  </Radio>
                </Radio.Group>
              </Col>
              <Col xs={3}>
                <Radio.Group
                  disabled={!editing || isForeign}
                  label="CNPJ"
                  onChange={({ target }) => setIsCnpj(target.value)}
                  value={isCnpj}
                  horizontal
                >
                  <Radio value>
                    <Text color="dark" type="label">
                      Sim
                    </Text>
                  </Radio>
                  <Radio value={false}>
                    <Text color="dark" type="label">
                      Não
                    </Text>
                  </Radio>
                </Radio.Group>
              </Col>
              <Col xs={6}>
                <Input
                  disabled={!editing}
                  label={isForeign ? 'Documento *' : 'CPF/CNPJ *'}
                  value={cpfCnpj}
                  onChange={event => {
                    setCpfCnpj(event.target.value);
                  }}
                  masked
                  mask={maskFunction}
                  error={editing && !cpfCnpj ? 'campo obrigatório' : ''}
                />
              </Col>
              <Col xs={12}>
                <Input
                  disabled={!editing}
                  label="Descrição"
                  value={description}
                  onChange={event => setDescription(event.target.value)}
                />
              </Col>
              <Col xs={12} className="mt-3">
                <TextArea
                  label="Motivo"
                  disabled={!editing}
                  resizable={false}
                  value={reason}
                  onChange={event => setReason(event.target.value)}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </ApplicationLayout>
  );
}
export default BlacklistItemDetails;
