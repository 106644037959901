function createYearsRange() {
    let currentYear = new Date().getFullYear();
    let auxYear = currentYear;
    const lastFiveYears = [];
    for (let i = 0; i < 5; i += 1) {
      lastFiveYears.push(auxYear);
      auxYear -= 1;
    }
    const yearsObject = lastFiveYears.map(year => {
      return { label: String(year), value: year };
    });
    return [yearsObject, currentYear];
  }
  export const [YEARS_OPTIONS, currentYear] = createYearsRange();
   
  export const AXYS_RANGE = [2, 3, 4, 5, 6, 7, 9];
  