import React from 'react';

import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Text from 'v3/components/Text';
import theme from 'v3/theme';

function Device({ device }) {
  return (
    <Row>
      <Col lg={6} md={6} xs={12}>
        <Text type="label" color="#464E5F">
          Modelo
        </Text>
        <Text type="regular" color="#494950" as="p" className="mt-2">
          {device.model || 'Não informado'}
        </Text>
      </Col>
      <Col lg={6} md={6} xs={12}>
        <Text type="label" color="#464E5F">
          Permissão Localização
        </Text>
        <Text type="regular" color="#494950" as="p" className="mt-2">
          {device.location ?
          <FaCheckCircle size={15} color={theme.colors.success} />
          :
          <FaTimesCircle size={15} color={theme.colors.danger} />
          }
          {device.location ? ' AUTORIZADO' : ' NÃO AUTORIZADO'}
        </Text>
      </Col>
      <Col lg={6} md={6} xs={12}>
        <Text type="label" color="#464E5F">
          Sistema operacional
        </Text>
        <Text type="regular" color="#494950" as="p" className="mt-2">
          {device.system || 'Não informado'}
        </Text>
      </Col>
      <Col lg={6} md={6} xs={12}>
        <Text type="label" color="#464E5F">
          Permissão Background
        </Text>
        <Text type="regular" color="#494950" as="p" className="mt-2">
          {device.background ?
          <FaCheckCircle size={15} color={theme.colors.success} />
          :
          <FaTimesCircle size={15} color={theme.colors.danger} />
          }
          {device.background ? ' AUTORIZADO' : ' NÃO AUTORIZADO'}
        </Text>
      </Col>
      <Col lg={6} md={6} xs={12}>
        <Text type="label" color="#464E5F">
          Versão do Coopertech
        </Text>
        <Text type="regular" color="#494950" as="p" className="mt-2">
          {device.version || 'Não informado'}
        </Text>
      </Col>
      <Col lg={6} md={6} xs={12}>
        <Text type="label" color="#464E5F">
          Economia de Energia
        </Text>
        <Text type="regular" color="#494950" as="p" className="mt-2">
          {device.energy ?
          <FaCheckCircle size={15} color={theme.colors.success} />
          :
          <FaTimesCircle size={15} color={theme.colors.danger} />
          }
          {device.energy ? ' AUTORIZADO' : ' NÃO AUTORIZADO'}
        </Text>
      </Col>
    </Row>
  );
}

export default Device;
