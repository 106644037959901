import React, { useState, useEffect, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useHistory, Link, useParams } from 'react-router-dom';
import ApplicationLayout from 'v3/layouts/Application';
import Space from 'v3/components/Space';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import Tooltip from 'v3/components/Tooltip';
import { UserContext } from 'contexts/UserContext';
import { validatePermission } from 'actions';
import { useUser } from '../shared/context';
import ShippingCompanies from '../shared/ShippingCompanies';
import Clients from '../shared/Clients';
import Establishments from '../shared/Establishments';
import Data from './Data';
import Access from './Access';
import { UserCostCentersManaged } from '../shared/CostCentersManaged';
import { Signature } from './Signature';
import Beneficiary from '../shared/Beneficiary';

export default function UserDetails() {
  const history = useHistory();
  const user = useUser();
  const params = useParams();
  const { currentUser } = useContext(UserContext);
  const [hasPermissionToEdit, setPermissionToEdit] = useState(true);

  useEffect(() => {
    function validateUserPermission() {
      setPermissionToEdit(validatePermission('ALTERAR_USUARIO'));
    }
    validateUserPermission();
  }, []);

  useEffect(() => {
    if (!hasPermissionToEdit) {
      user.showSnackbar('Você não tem permissão para editar usuário', {
        type: 'error',
      });
      history.push('/');
    }
  }, [hasPermissionToEdit]);

  useEffect(() => {
    if (user?.data?.change_password === null) {
      user.setData({ change_password: true });
    }
  }, [user]);

  return (
    <ApplicationLayout
      title="Usuários"
      RightComponent={
        <Space x="8px">
          <Button variant="secondary" onClick={() => history.goBack()}>
            <Text type="regular" color="dark" weight="semiBold">
              Voltar
            </Text>
          </Button>
          {user.hasPermissionToCreate && (
            <Link to="cadastrar">
              <Button>
                <Text type="regular" color="white" weight="semiBold">
                  Novo usuário
                </Text>
              </Button>
            </Link>
          )}
          {user.data?.profile?.id !== 2 && user.hasPermissionToCreate && (
            <Link to={`cadastrar/${params.id}`}>
              <Tooltip
                content={
                  <Text type="regular">
                    Criar um novo usuário baseado no atual
                  </Text>
                }
              >
                <Button variant="info">
                  <Text type="regular" color="white" weight="semiBold">
                    Clonar usuário
                  </Text>
                </Button>
              </Tooltip>
            </Link>
          )}
        </Space>
      }
    >
      <Row>
        <Col md={6} xs={12}>
          <Data />
        </Col>
        <Col md={6} xs={12}>
          <Access />
        </Col>
        {currentUser.id && Number(params?.id) === Number(currentUser.id) && (
          <Col xs={12}>
            <Signature />
          </Col>
        )}
        {user.data?.profile?.id !== 2 && (
          <Col xs={12}>
            <ShippingCompanies />
            <Beneficiary/>
            <Clients />
            <UserCostCentersManaged />
            <Establishments />
          </Col>
        )}
      </Row>
    </ApplicationLayout>
  );
}
