import { fetchSelectOptions } from '../controller';

export async function fetchTags(setTags) {
  try {
    const response = await fetchSelectOptions('user-tags');
    setTags(response.map(tag => ({ id: tag.tag_id, name: tag.name.name })));
  } catch (err) {
    setTags([]);
  }
}
